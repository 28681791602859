import { createSelector } from '@reduxjs/toolkit';
import { getSessionAdomOid } from '../../session/adom/selectors';

const myState = (state) => state.soc.currentDash;

export const get = ({ dashId }) =>
  createSelector(myState, getSessionAdomOid, (state, adomOid) => {
    let dash = state.data[dashId] || {};
    if (dash._currentAdomOid === adomOid) {
      return dash;
    }
    return {};
  });

export const getWidgetByName = (dashId, widgetName) =>
  createSelector(get({ dashId }), (data) =>
    (data.widgets || []).find((widget) => widget.name === widgetName)
  );

export const getSaveData = ({ dashId }) =>
  createSelector(get({ dashId }), (data) => {
    let saveData = {
      uuid: data.uuid,
      'dash-data': data['dash-data'],
      widgets: data.widgets.map((widget) => ({
        name: widget.name,
        grid: widget.grid,
        data: widget.data,
        props: widget.props?.isDataset ? widget.props : {},
      })),
    };
    return saveData;
  });
