import { isFunction, isNil, merge } from 'lodash';
import $ from 'jquery';
import {
  KeyFunction,
  ValueFunction,
  SearchResult,
  ArrayDiffResult,
  AsyncForEachConfig,
  AsyncForEachResult,
} from './types';

export {
  // general helper
  fromArray,
  toArray,
  arrToMap,
  splitArray,
  areArraysEqual,

  // nature sort an array by attr
  // a1, a2, ...,a10, a11, ..., a20
  natureSort,
  natureSortBy,
  makeStringArray,
  findElementInArrayByKey,
  findElementsInArrayByKey,
  findElementInNestedArrayByKey,
  findIndexInArrayByKey,
  findElementsInArrayByKeyContainsValue,
  removeElementsInArrayByIndexes,
  objectArrayToMap,
  sortBy,
  toSortedBy,
  sortByCopy,
  binaryInsert,
  //used in saving a table via json api
  diffTwoArrays,
  rotateArray,
  // nature sort comparator
  natureComparator,
  isValueSameInArray,
  rmDuplicate,
  rmObjDuplicate,
  collapseDuplicates,
  asyncForEach,
  getFirstArrayItemIfArray,
};

// Extract single value from datasource
function fromArray<T>(arr: T | T[]): T {
  return Array.isArray(arr) ? arr[0] : arr;
}

const toArray = <T>(val: T | T[]): T[] => (Array.isArray(val) ? val : [val]);

// Remove duplicated entries
const rmDuplicate = <T>(arr: T[]): T[] => Array.from(new Set(arr));

const rmObjDuplicate = <T>(
  arr: T[],
  keyGetter: ((obj: T) => any) | keyof T
): T[] => {
  const keySet = new Set<any>();
  const results: T[] = [];
  for (const obj of arr) {
    const keyValue =
      typeof keyGetter === 'function' ? keyGetter(obj) : obj[keyGetter];
    if (!keySet.has(keyValue)) {
      results.push(obj);
      keySet.add(keyValue);
    }
  }

  return results;
};

const arrToMap = <T, U = T>(
  keyOrGetKeyFn: string | KeyFunction<T>,
  arr: T[] | undefined,
  valueOrGetValueFn?: U | ValueFunction<T, U>
): Record<string, U> => {
  const getKey: KeyFunction<T> =
    typeof keyOrGetKeyFn === 'function'
      ? keyOrGetKeyFn
      : (data: T) => (data as any)[keyOrGetKeyFn];

  return (arr || []).reduce<Record<string, U>>((map, el, i) => {
    let value: U;
    if (valueOrGetValueFn !== undefined) {
      if (typeof valueOrGetValueFn === 'function') {
        value = (valueOrGetValueFn as ValueFunction<T, U>)(el, i);
      } else {
        value = valueOrGetValueFn;
      }
    } else {
      value = el as unknown as U;
    }

    map[getKey(el, i, arr || [])] = value;
    return map;
  }, {});
};

/**
 * Splits an array into multiple subarrays based on provided check functions.
 *
 * @template T The type of elements in the input array.
 * @param {T[]} arr The input array to be split.
 * @param {...((el: T, index: number) => boolean)} checkFns Functions to determine which subarray an element belongs to.
 * @returns {T[][]} An array of subarrays. Elements matching the first check function are in the first subarray,
 *                  those matching the second are in the second, and so on. Elements matching no check functions
 *                  are placed in the last subarray.
 */
function splitArray<T>(
  arr: T[] | undefined,
  ...checkFns: ((el: T, index: number) => boolean)[]
): T[][] {
  return (arr || []).reduce<T[][]>(
    (result, el, i) => {
      const bucketIdx = checkFns.findIndex((check) => check(el, i));
      result[bucketIdx === -1 ? checkFns.length : bucketIdx].push(el);
      return result;
    },
    Array.from({ length: checkFns.length + 1 }, () => [])
  );
}

/**
 * Creates a sorting function for natural order sorting.
 *
 * @template T Type of the elements in the array to be sorted
 * @param {keyof T | undefined} attr Optional attribute to sort by if T is an object
 * @param {boolean} [isReverseOrder=false] Whether to sort in reverse order
 * @returns {(array: T[]) => T[]} A function that sorts the given array
 */
function natureSort<T extends Record<string, any>>(
  attr?: keyof T,
  isReverseOrder: boolean = false
): (array: T[]) => T[] {
  return (array: T[]) => {
    natureSortBy(array, attr, false, isReverseOrder);
    return array;
  };
}

/**
 * Sorts an array in natural order.
 *
 * @template T Type of the elements in the array to be sorted
 * @param {T[]} arr The array to be sorted
 * @param {keyof T | undefined} attr Optional attribute to sort by if T is an object
 * @param {boolean} [iscase=false] Whether the sort should be case-sensitive
 * @param {boolean} [reverse_order=false] Whether to sort in reverse order
 */
function natureSortBy<T>(
  arr: T[],
  attr?: keyof T | ((v: T) => string | number),
  iscase: boolean = false,
  reverse_order: boolean = false
): void {
  arr.sort((a: T, b: T) => {
    const ret = sort_function(a, b, attr, iscase);
    return reverse_order ? -ret : ret;
  });
}

/**
 * Creates an array of specified length filled with a given string.
 *
 * @param {number} len The length of the array to create
 * @param {string} str The string to fill the array with
 * @returns {string[]} An array of strings
 */
function makeStringArray(len: number, str: string): string[] {
  return Array.from({ length: len }, () => str);
}

/**
 * Converts a string to uppercase if case-insensitive comparison is needed.
 *
 * @param {string} str The string to convert
 * @param {boolean} isCaseSensitive Whether the comparison should be case-sensitive
 * @returns {string} The converted string
 */
const convertCase = (str: string, isCaseSensitive: boolean): string =>
  isCaseSensitive ? str : str.toUpperCase();

/**
 * Finds an element in an array by a specified key or nested keys.
 *
 * @template T Type of elements in the array
 * @param {T[]} arr The array to search
 * @param {keyof T | (keyof T)[]} keyName The key or array of nested keys to compare
 * @param {any} keyValue The value to match or a function to test each element
 * @param {boolean} [isCaseSensitive=false] Whether the comparison should be case-sensitive
 * @returns {T | null} The found element or null if not found
 */
function findElementInArrayByKey<T>(
  arr: T[],
  keyName: keyof T | (keyof T)[],
  keyValue: any | ((value: any) => boolean),
  isCaseSensitive: boolean = false
): T | null {
  for (let i = 0; i < arr.length; i++) {
    const _val = Array.isArray(keyName)
      ? keyName.reduce((acc: any, cur) => (acc ? acc[cur] : null), arr[i])
      : arr[i][keyName];

    if (typeof keyValue === 'function') {
      if (keyValue(_val)) return arr[i];
    } else {
      if (
        convertCase(String(_val), isCaseSensitive) ===
        convertCase(String(keyValue), isCaseSensitive)
      )
        return arr[i];
    }
  }
  return null;
}

/**
 * Finds all elements in an array that match a specified key-value pair.
 *
 * @template T Type of elements in the array
 * @param {T[]} arr The array to search
 * @param {keyof T} keyName The key to compare
 * @param {any} keyValue The value to match
 * @returns {Array<SearchResult<T>>} An array of objects containing the index and data of matching elements
 */
function findElementsInArrayByKey<T>(
  arr: T[],
  keyName: keyof T,
  keyValue: any
): Array<SearchResult<T>> {
  const result: Array<{ index: number; data: T }> = [];

  for (let i = 0; i < arr.length; i++) {
    if (
      String(arr[i][keyName]).toUpperCase() === String(keyValue).toUpperCase()
    ) {
      result.push({ index: i, data: arr[i] });
    }
  }

  return result;
}

/**
 * Searches for an element in a nested array structure based on a key-value pair.
 *
 * @template T Type of elements in the array
 * @param {T[]} arr The array to search in
 * @param {keyof T} keyName The name of the key to match
 * @param {any} keyValue The value to match against the key
 * @param {keyof T} childAttrName The name of the attribute containing child elements
 * @returns {T | null} The matching element if found, otherwise null
 */
function findElementInNestedArrayByKey<T>(
  arr: T[],
  keyName: keyof T,
  keyValue: any,
  childAttrName: keyof T
): T | null {
  for (const item of arr) {
    if (!Object.prototype.hasOwnProperty.call(item, keyName)) {
      continue;
    }

    if (
      String(item[keyName]).toUpperCase() === String(keyValue).toUpperCase()
    ) {
      return item;
    }

    const childArr = item[childAttrName];
    if (Array.isArray(childArr)) {
      const result = findElementInNestedArrayByKey(
        childArr as T[],
        keyName,
        keyValue,
        childAttrName
      );
      if (result !== null) {
        return result;
      }
    }
  }

  return null;
}

/**
 * Finds the index of an element in an array based on a key-value pair.
 *
 * @template T Type of elements in the array
 * @param {T[]} arr The array to search in
 * @param {keyof T} keyName The name of the key to match
 * @param {any} keyValue The value to match against the key
 * @returns {number} The index of the matching element if found, otherwise -1
 */
function findIndexInArrayByKey<T>(
  arr: T[],
  keyName: keyof T,
  keyValue: any
): number {
  return arr.findIndex(
    (item) =>
      String(item[keyName]).toUpperCase() === String(keyValue).toUpperCase()
  );
}

/**
 * Searches for elements in an array where a specified key's value contains a given substring.
 *
 * @template T Type of elements in the array
 * @param {T[]} arr The array to search in
 * @param {keyof T} keyName The name of the key to search in
 * @param {any} keyValue The substring to search for
 * @returns {SearchResult<T>[]} An array of SearchResult objects containing the index and data of matching elements
 */
function findElementsInArrayByKeyContainsValue<T>(
  arr: T[],
  keyName: keyof T,
  keyValue: any
): SearchResult<T>[] {
  return arr.reduce((result: SearchResult<T>[], item, index) => {
    if (
      String(item[keyName])
        .toUpperCase()
        .includes(String(keyValue).toUpperCase())
    ) {
      result.push({ index, data: item });
    }
    return result;
  }, []);
}

/**
 * Removes elements from an array based on specified indexes.
 *
 * @template T Type of elements in the array
 * @param {T[]} arr The input array
 * @param {number | number[]} indexes A single index or an array of indexes to remove
 * @returns {T[]} A new array with the specified elements removed
 */
function removeElementsInArrayByIndexes<T>(
  arr: T[],
  indexes: number | number[]
): T[] {
  const removeSet = new Set(Array.isArray(indexes) ? indexes : [indexes]);
  return arr.filter((_, index) => !removeSet.has(index));
}

/**
 * Converts an array of objects to a map using a specified key.
 *
 * @template T Type of elements in the array
 * @param {T[]} arr The input array of objects
 * @param {keyof T} key The key to use as the map key
 * @returns {Record<string, T>} A map of the objects with the specified key as the map key
 */
function objectArrayToMap<T>(arr: T[], key: keyof T): Record<string, T> {
  const map: Record<string, T> = {};
  if (arr && arr.length > 0) {
    arr.forEach((item) => {
      const itemKey = item[key];
      if (!isNil(itemKey)) {
        map[String(itemKey)] = item;
      }
    });
  }
  return map;
}

/**
 * Sorts an array of objects in place by a specified key.
 *
 * @template T Type of elements in the array
 * @param {T[]} items The array to sort
 * @param {keyof T} key The key to sort by
 * @param {boolean} [reverse=false] Whether to sort in reverse order
 */
function sortBy<T>(items: T[], key: keyof T, reverse: boolean = false): void {
  if (Array.isArray(items)) {
    items.sort((a, b) => {
      let x: string | T[keyof T] = a[key];
      let y: string | T[keyof T] = b[key];
      if (typeof x === 'string') {
        x = x.toLowerCase();
      }
      if (typeof y === 'string') {
        y = y.toLowerCase();
      }
      const ret = x < y ? -1 : x > y ? 1 : 0;
      return reverse ? -1 * ret : ret;
    });
  }
}

/**
 * Sorts an array of objects in place by a specified key and returns the sorted array.
 *
 * @template T Type of elements in the array
 * @param {T[]} items The array to sort
 * @param {keyof T} key The key to sort by
 * @param {boolean} [reverse=false] Whether to sort in reverse order
 * @returns {T[]} The sorted array (same instance as input)
 */
function toSortedBy<T>(
  items: T[],
  key: keyof T,
  reverse: boolean = false
): T[] {
  if (Array.isArray(items)) {
    return items.sort((a, b) => {
      let x: string | T[keyof T] = a[key];
      let y: string | T[keyof T] = b[key];
      if (typeof x === 'string') {
        x = x.toLowerCase();
      }
      if (typeof y === 'string') {
        y = y.toLowerCase();
      }
      const ret = x < y ? -1 : x > y ? 1 : 0;
      return reverse ? -1 * ret : ret;
    });
  }
  return items;
}

/**
 * Returns a new sorted array of objects by a specified key.
 *
 * @template T Type of elements in the array
 * @param {T[]} items The array to sort
 * @param {keyof T} key The key to sort by
 * @param {boolean} [reverse=false] Whether to sort in reverse order
 * @returns {T[]} A new sorted array
 */
function sortByCopy<T>(
  items: T[],
  key: keyof T,
  reverse: boolean = false
): T[] {
  if (Array.isArray(items)) {
    return [...items].sort((a, b) => {
      let x: string | T[keyof T] = a[key];
      let y: string | T[keyof T] = b[key];
      if (typeof x === 'string') {
        x = x.toLowerCase();
      }
      if (typeof y === 'string') {
        y = y.toLowerCase();
      }
      const ret = x < y ? -1 : x > y ? 1 : 0;
      return reverse ? -1 * ret : ret;
    });
  }
  return [];
}

/**
 * Inserts an item into a sorted array using binary search to determine the insertion point.
 *
 * @template T Type of elements in the array
 * @param {T} item The item to insert
 * @param {T[]} items The sorted array to insert into
 * @param {(item: T) => string | number} valFn Function to extract the comparison value from an item
 */
function binaryInsert<T>(
  item: T,
  items: T[],
  valFn: (item: T) => string | number
): void {
  if (!items.length) {
    items.push(item);
    return;
  }

  let begin = 0;
  let end = items.length - 1;
  let mid = Math.floor((begin + end) / 2);
  let midVal = String(valFn(items[mid]));
  const nodeVal = String(valFn(item));

  while (midVal !== nodeVal && begin < end) {
    if (nodeVal < midVal) {
      end = mid - 1;
    } else if (nodeVal > midVal) {
      begin = mid + 1;
    }

    mid = Math.floor((begin + end) / 2);
    if (mid < 0) {
      break;
    }
    midVal = String(valFn(items[mid]));
  }

  if (mid < 0) {
    items.splice(0, 0, item);
  } else if (begin === end && nodeVal < midVal) {
    items.splice(mid, 0, item);
  } else {
    items.splice(mid + 1, 0, item);
  }
}

/**
 * Compares two arrays and returns the differences (additions, updates, and deletions).
 *
 * @template T Type of elements in the arrays
 * @param {T[]} newArr The new array
 * @param {T[]} oldArr The old array to compare against
 * @param {keyof T} key The key to use for comparison
 * @returns {{ masterKey: keyof T, lists: ArrayDiffResult<T> }}
 */
function diffTwoArrays<T extends Record<any, string>>(
  newArr: T[],
  oldArr: T[],
  key: keyof T
): {
  masterKey: keyof T;
  lists: ArrayDiffResult<T>;
} {
  const result: ArrayDiffResult<T> = { add: [], update: [], delete: [] };
  const oldSet = new Set(
    oldArr.map((ee) => (Array.isArray(ee[key]) ? ee[key].toString() : ee[key]))
  );

  newArr.forEach((ee) => {
    const eekey = Array.isArray(ee[key]) ? ee[key].toString() : ee[key];
    if (oldSet.has(eekey)) {
      result.update.push(ee);
      oldSet.delete(eekey);
    } else {
      result.add.push(ee);
    }
  });

  result.delete = Array.from(oldSet);
  return { masterKey: key, lists: result };
}

/**
 * Rotates the elements in an array by a specified count.
 *
 * @template T Type of elements in the array
 * @param {T[]} arr The array to rotate
 * @param {number} count The number of positions to rotate (negative for left rotation)
 */
function rotateArray<T>(arr: T[], count: number): void {
  Array.prototype.unshift.apply(
    arr,
    arr.splice((-1 * count) % arr.length, arr.length)
  );
}

// nature sort comparator
function natureComparator<T extends Record<string, any>>(
  a: T | string,
  b: T | string,
  attr?: keyof T,
  iscase: boolean = false
): number {
  return sort_function(a, b, attr, iscase);
}

/**
 * Checks if all elements in the array are the same according to the provided comparison function.
 * An empty array or an array with a single element is considered to have all elements the same.
 *
 * @template T Type of elements in the array
 * @param {T[]} array The array to check
 * @param {(curr: T, base: T) => boolean} [isDiff] Optional function to determine if two elements are different.
 *        Defaults to strict inequality (!==).
 * @returns {boolean} True if all elements are the same, false otherwise
 */
function isValueSameInArray<T>(
  array: T[],
  isDiff: (curr: T, base: T) => boolean = (curr, base) => curr !== base
): boolean {
  if (array.length <= 1) {
    return true;
  }

  const base = array[0];
  return !array.slice(1).some((elm) => isDiff(elm, base));
}

/**
 * Splits a string into chunks based on transitions between numeric and non-numeric characters.
 *
 * This function breaks down a string into an array of substrings (chunks) where each chunk
 * consists of either:
 * 1. A sequence of numeric characters (including decimal points), or
 * 2. A sequence of non-numeric characters
 *
 * The function considers the decimal point (.) as part of a numeric chunk.
 *
 * @param {string} t The input string to be chunked
 * @returns {string[]} An array of chunked substrings
 *
 * @example
 * chunkify("foo100bar") // returns ["foo", "100", "bar"]
 * chunkify("10.5abc20.7") // returns ["10.5", "abc", "20.7"]
 * chunkify("abc") // returns ["abc"]
 * chunkify("123") // returns ["123"]
 */
function chunkify(input: string): string[] {
  const chunks: string[] = [];
  let currentIndex = 0;
  let currentChunkIndex = -1;
  let isCurrentChunkNumeric = null;
  let charCode: number;
  let currentChar: string;

  while (
    (charCode = (currentChar = input.charAt(currentIndex++)).charCodeAt(0))
  ) {
    const isCharNumeric = charCode === 46 || (charCode >= 48 && charCode <= 57);
    if (isCharNumeric !== isCurrentChunkNumeric) {
      chunks[++currentChunkIndex] = '';
      isCurrentChunkNumeric = isCharNumeric;
    }
    chunks[currentChunkIndex] += currentChar;
  }
  return chunks;
}

/**
 * Performs a natural sort comparison between two values.
 *
 * @template T Type of the objects being compared (if attr is provided)
 * @param {T | string} a First value to compare
 * @param {T | string} b Second value to compare
 * @param {keyof T} [attr] Optional attribute to compare if a and b are objects
 * @param {boolean} [iscase=false] Whether the comparison should be case-sensitive
 * @returns {number} Negative if a < b, positive if a > b, zero if equal
 */
function sort_function<T>(
  a: T | string,
  b: T | string,
  attr?: keyof T | ((v: T) => string | number),
  iscase: boolean = false
): number {
  const prop =
    typeof attr === 'function' ? attr : attr ? (v: T) => v[attr] : undefined;
  const theVal1 = prop && typeof a !== 'string' ? prop(a) : a;
  const theVal2 = prop && typeof b !== 'string' ? prop(b) : b;

  const aa = chunkify(iscase ? String(theVal1) : String(theVal1).toLowerCase());
  const bb = chunkify(iscase ? String(theVal2) : String(theVal2).toLowerCase());

  for (let x = 0; x < aa.length && x < bb.length; x++) {
    if (aa[x] !== bb[x]) {
      const c = Number(aa[x]);
      const d = Number(bb[x]);
      if (!isNaN(c) && !isNaN(d)) {
        return c - d;
      } else {
        return aa[x] > bb[x] ? 1 : -1;
      }
    }
  }
  return aa.length - bb.length;
}

/**
 * Merges adjacent duplicate elements in an array into a single element.
 *
 *
 * @template T The type of elements in the array
 * @param {(a: T, b: T) => boolean} equalFn A function that determines if two elements are considered equal
 * @param {T[]} arr The input array
 * @returns {T[]} A new array with adjacent duplicates collapsed
 *
 */
function collapseDuplicates<T>(
  equalFn: (a: T, b: T) => boolean,
  arr: T[]
): T[] {
  return arr.reduce((acc: T[], item: T) => {
    if (acc.length === 0 || !equalFn(acc[acc.length - 1], item)) {
      acc.push(item);
    }
    return acc;
  }, []);
}

/**
 * Asynchronously iterates over an array, processing items in chunks.
 *
 * @template T The type of elements in the array
 * @template R The type of the result (default to T)
 * @param {T[]} oArr The input array to process
 * @param {(item: T, index: number, params: any) => R | R[] | false | void} oCallback The callback function to process each item
 * @param {AsyncForEachConfig} [oCfg] Configuration options
 * @returns {AsyncForEachResult<R>} An object containing a promise and a cancel function
 *
 */
function asyncForEach<T, R = T>(
  oArr: T[],
  oCallback: (item: T, index: number, params: any) => R | R[] | false | void,
  oCfg?: AsyncForEachConfig
): AsyncForEachResult<R> {
  let arr = oArr || [];
  const deferred = $.Deferred();
  let cancelled = false;

  if (!isFunction(oCallback) || !arr.length) {
    return {
      promise: $.when(arr) as unknown as JQuery.Promise<R[]>,
      cancel: () => {},
    };
  }

  arr = [...arr];

  // generate cfg for chunking array
  const cfg = merge(
    {
      chunksz: 200,
      delay: 0,
      params: null,
    },
    oCfg
  );

  let results: R[] = [];
  const len = arr.length;
  let idx = 0;

  function processer() {
    let cnt = cfg.chunksz;
    const chunked: R[] = [];
    let going = true;

    if (cancelled) {
      deferred.reject({ cancelled: true });
      arr = null as any;
      results = null as any;
      return;
    }

    while (--cnt && idx < len) {
      if (cancelled) break;

      const data = oCallback(arr[idx], idx, cfg.params);
      idx += 1;
      if (data === false) {
        going = false;
        break;
      }

      if (data) {
        Array.isArray(data) ? chunked.push(...data) : chunked.push(data);
      }
    }

    if (!cancelled) {
      deferred.notify(chunked);
      results.push(...chunked);

      if (going && idx < len) {
        setTimeout(processer, cfg.delay);
      } else {
        deferred.resolve(results);
        arr = null as any;
        results = null as any;
      }
    } else {
      deferred.reject({ cancelled: true });
      arr = null as any;
      results = null as any;
    }
  }

  function cancel() {
    cancelled = true;
  }

  // start processer
  setTimeout(processer, cfg.delay);

  return {
    promise: deferred.promise(),
    cancel: cancel,
  };
}

/**
 * Checks if two arrays are equal after sorting.
 *
 * @template T The type of elements in the arrays
 * @param {T[]} arrayA The first array to compare
 * @param {T[]} arrayB The second array to compare
 * @param {((a: T, b: T) => number) | undefined} sortFn Optional custom sorting function
 * @returns {boolean} True if the arrays are equal after sorting, false otherwise
 *
 */
function areArraysEqual<T>(
  arrayA: T[],
  arrayB: T[],
  sortFn?: (a: T, b: T) => number
): boolean {
  if (arrayA.length !== arrayB.length) return false;

  const sortedA = sortFn ? [...arrayA].sort(sortFn) : [...arrayA].sort();
  const sortedB = sortFn ? [...arrayB].sort(sortFn) : [...arrayB].sort();

  return sortedA.every((el, idx) => el === sortedB[idx]);
}

/**
 * Retrieves the first item of an array or returns the value if it's not an array.
 *
 * @template T The type of the value or array elements
 * @param {T | T[]} val The value or array to process
 * @returns {T | undefined} The first item of the array if input is an array,
 *                          or the input value itself if it's not an array,
 *
 */
function getFirstArrayItemIfArray<T>(val: T | T[]): T {
  if (Array.isArray(val)) {
    return val[0];
  }

  return val;
}
