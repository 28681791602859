export enum Commands {
  config = 'config',
  edit = 'edit',

  set = 'set',
  unset = 'unset',

  get = 'get',
  //in table, no arguments will show basic table entries by name/id
  //with only one argument, will show full config for the given entry id

  show = 'show',
  //in table, show with no arguments will show the basic configs of all entries
  //in table, SHOW full-configuration shows full configs of all entries

  //in obj, show config of current obj

  delete = 'delete',
  //need ONLY one entry to delete from a table, no more than one

  purge = 'purge',
  //no further arguments, clear all table values
  //cannot be undone, maybe show a warning in the editor
  //can only be undone by restoring a backup

  abort = 'abort', //exits latest config scope, skipping edit scope
  end = 'end', //exits latest config scope, skipping edit scope
  next = 'next', //exits latest edit scope

  select = 'select',
  //in obj scope, used to select one or more options for a multi-option setting, like system interface allowaccess, discard the rest
  //seems to be the same as "SET"
  //in table scope, used to select one or more entries and discard the rest (couldn't find this usage in practice)

  unselect = 'unselect',
  //in obj scope, used to deselect one or more options for a multi-option setting
  //in table scope, used to discard one or more entries and keep the rest (couldn't find this usage in practice)

  append = 'append',
  //in obj scope, used to add one or more options to multi-option setting

  clear = 'clear',
  //in obj scope, used to clear a multi-option setting

  //clone/rename/move not available in all tables, ignore for now
  clone = 'clone',
  //clone a table entry "CLONE <name> to <new cloned name>"

  rename = 'rename',
  //rename a table entry, "RENAME <name> to <new name>"

  move = 'move',
  //move a table entry, "MOVE <seqnum> to <other seqnum>"
  //only available in tables where order matters like policy routes

  //other commands
  diagnose = 'diagnose',
  execute = 'execute',
  alias = 'alias',
  exit = 'exit',
  sudo = 'sudo',
}

export enum SyntaxCategoryType {
  table = 'table',
  obj = 'obj',
  edit = 'edit',
}

export enum ContextType {
  table = 'table',
  obj = 'obj',
  edit = 'edit',
}

export enum ErrorSeverity {
  warning = 'Warning',
  error = 'error',
}

export type SyntaxContext = {
  type: ContextType;
  name: string | number; // Name of the entry being edited
  object: SyntaxCategory | Record<string, SyntaxCategory>; // A clone of the current syntax obj
  lineNumber: number; // Storing lineNumber might help with error messages or debugging
  isVdom?: boolean;
};

export type ErrorObj = {
  message: string;
  line: number;
  error?: string;
  reason?: string;
  err?: string;

  optMsg?: string;
  helpMsg?: string;
  typeMsg?: string;
  refMsg?: string;

  code?: ErrorSeverity; //needed for codemirror native gutter error icon style, check autocomplete_codemirror_util.js
  correction?: string;
  correctionDisplayText?: string;
  originalLine?: string;
  start?: number;
  end?: number;
};

export type SyntaxCategory = {
  attr?: Record<string, SyntaxAttribute>;
  subobj?: Record<string, SyntaxCategory>;
  category: number;
  domain: number;
  help: string;
  support_mode?: number;
  type: ContextType;
  static?: number;
  mkey?: string;
};

export enum SyntaxAttributeTypes {
  'multi_str' = 'multi_str', //can't validate, this varies across all categories
  'user' = 'user', //can't validate, varies too much
  'startend_datetime' = 'startend_datetime', //too COMPLICATED and there is only one usage (router key-chain "accept-lifetime")
  'datasrc' = 'datasrc', //can't validate
  'array' = 'array', //can't validate
  'hbdev' = 'hbdev', //unsure
  'multi_ipmaskv4' = 'multi_ipmaskv4', //skip for now, only one instance and too ambiguous

  'port_range' = 'port_range', //0-65535 either single port number or range with form "<start>-<end>"

  'multi_dstsrc_port_range' = 'multi_dstsrc_port_range', //<dstport_low>[-<dstport_high>:<srcport_low>-<srcport_high>]
  //e.g. can be 1, 1-5:5, 5:1-10. only validate on each half, first number lower than second. no need to relate src to dst

  'string' = 'string', //handle string length with "max" attr

  'ip6prefix' = 'ip6prefix', //only one ip6/prefix (e.g. ::/128) (or only ::)

  'ip4' = 'ip4', //only ipv4 address (0.0.0.0)

  'ip4addrmask2' = 'ip4addrmask2', //can be 1.1.1.1/32 or 1.1.1.1 255.255.255.255 NOT x.x.x.x/y.y.y.y
  'ip4mask' = 'ip4mask', //only netmask e.g. 255.255.255.255

  'ipv4_mask' = 'ipv4_mask', //ip address and subnet mask
  //in form x.x.x.x x.x.x.x or x.x.x.x/32 or x.x.x.x/x.x.x.x
  //somehow different than ip4mask????

  'ip6addr' = 'ip6addr', //only ipv6 address, no subnet e.g. 1::3

  'uint8' = 'uint8', //could be number or choices (enable/disable)
  //if number ,should have min, max
  //if has "opts" then it is a choice
  'uint64' = 'uint64', //treat the same
  'uint16' = 'uint16', //same
  'uint32' = 'uint32', //same
  'int8' = 'int8', //same

  'ipv4range' = 'ipv4range', //single ipv4 or ipv4 range <start>-<end>
  //end must be greater than start
  'ipv6range' = 'ipv6range', //similar as ipv4range but with ipv6 addresses

  'multi_ipv6' = 'multi_ipv6', //one or more ipv6 addresses like 1::1, up to max_argv
  //e.g. set ip6multi 1::1 2::2 if max_argv == 2
  //max_argv=-1 means 1
  'multi_ipv4' = 'multi_ipv4', //similar to multi_ipv6 but for ipv4

  'seqnum' = 'seqnum', //number from 0 or min up to max

  'macaddr' = 'macaddr', //mac address

  'time' = 'time', //format hh:mm

  'uint16_range' = 'uint16_range', //we can try to validate, just a number range <start>-<end>, start less than end
  //min 1, up to 65535, uint16

  'timedate' = 'timedate', //we can try to validate
  //hh:mm yyyy/mm/dd

  'date_time' = 'date_time', //yyyy/mm/dd hh:mm:ss
  //not to be confused with timedate! haha!

  'password' = 'password', //any string idk

  'opt_array' = 'opt_array', //one or multiple of the opts, up to max_argv number or infinite if no max_argv

  'multi_int' = 'multi_int', //one or more integers, up to max_argv, or infinite if max_argv == -1 (or no max_argv i guess)
}

type RefObj = {
  category: string;
  mkey: string;
};

export type SyntaxAttribute = {
  default?: string | number;
  excluded?: boolean;
  opts?: Record<string, number>;
  sz?: number;
  help?: string;
  min?: number;
  max?: number;
  max_argv?: number;
  ref?: RefObj[];
  read_only?: number;
  type: SyntaxAttributeTypes;
  must?: number;
  flags?: string[];
};

export type TypeValidationFunction = (
  attribute: SyntaxAttribute,
  values: string[]
) => string | void;

export type HintsObj = {
  hint: string;
  required: boolean;
};
