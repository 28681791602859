import {
  cloneDeep,
  forEach,
  get,
  isEmpty,
  isFunction,
  isString,
  isUndefined,
  transform,
} from 'lodash';

import { fiFmgHttp } from 'fi-http';
import { getDevTypeNameByOsType } from './os_type_related';
import { fiCache } from 'kit-cache';
import { findElementInArrayByKey } from 'kit-array';
import { fiAdom, fiSysConfig } from 'fi-session';
import { createDeferred } from 'kit-promise';
import { fiStore, fiDevicesSelector, dispatch } from 'fistore';
import $ from 'jquery';
import {
  getSaseDevicesLoaded,
  getSaseDevicesMap,
} from 'fistore/devices/sase/selector';
import { fetchSaseDevices } from 'fistore/devices/sase/slice';

import { fiDeviceRedux } from './device_redux';
import { fiDeviceListLoader } from './device_list_loader';
import { getDevGrpId } from 'fi-apps/fi-dvm';

export const fiDeviceDataLoader = {
  isReservedDevGroup,
  getGroupMemberKeys,
  getGroupMemberList,
  getGroupMembersFlat,
  getNonFGTGroupMemberList,
  getDevicesByKeys,
  getDeviceIDs,
  getDevicesFromCache,
  getDevices,
  getDevicesAsScopeMembers,
  getDevice,
  getManagedDevices,
  getUnregDevices,
  getDeviceGroups,
  getFazDeviceGroups,
  getGroupMap,
  getGroupMapByName,
  isReady,
  getFromScopeMember,
  numOfManagedDevices,
  getDeviceBySn,
  getDeviceByName,
  getAllDevsLocationByAdom,
  getGroupMapCached,
  getGroupById,
  getGroupByName,
  getDevAndDevGrpMap,
  findVdomInDevMap,
  isVdomInAdom,
  getScopeMembersByState,
  isVdomInAdomByDevName,
  getSaseDevicesById,
  getSaseDevices,
};

/**
 * This Service is used to get devices from client-side dvm database
 */

const DEVICES_ID_ATTR = '_fiDeviceId';

const _reservedDevGroupOid = [
  MACROS.DVM.DVM_GRP_UNREG_OID,
  MACROS.DVM.DVM_GRP_LOGGING_OID,
  MACROS.DVM.DVM_GRP_MANAGED_OID,
  MACROS.DVM.DVM_GRP_REMOTE_FAZ_OID,
];

const ROOT_VDOM_ID = 3;

// Map to device by serial number
let _snToDevMap = {},
  _nameToDevMap = {};

init();

function init() {
  fiAdom.adomSubscriber.switched(() => {
    _clearDeviceMaps();
  });

  fiDeviceRedux.bindDeviceUpdate(() => {
    _clearDeviceMaps();
  });
}

/**
 * Returns promise which resolves when all devices are loaded
 * @param {oid} adomOid, (optional) default it is current
 * @return {promise}
 */
function isReady(adomOid) {
  return fiDeviceRedux.loadDevices(adomOid);
}

/**
 * To get device list by given device keys, this will use arrived chunks
 * @param {array} keys, array of primary keys (did-vid),
 * @param {Promise} promCancel promise to cancel search
 * @return {Promise} Notifies when each chunk is ready. Resolves with full list.
 */
function getDevicesByKeys(keys, promCancel) {
  const defer = $.Deferred();
  const allFiltered = [];
  const keyData = (keys || []).reduce(
    (acc, cur) => {
      if (Array.isArray(cur)) {
        const devkey = cur[0].split('-')[0];
        acc.devkeys.push(devkey); //only get the device id
        acc.keymap[devkey] = cur;
      } else {
        acc.devkeys.push(cur);
      }
      return acc;
    },
    { devkeys: [], keymap: {} }
  );

  if (keyData.devkeys.length === 0) {
    defer.resolve(allFiltered);
    return defer.promise();
  }

  fiDeviceRedux.getDevicesByIds(null, keyData.devkeys, { promCancel }).then(
    () => {
      defer.resolve(allFiltered);
    },
    (err) => {
      defer.reject(err);
    },
    (chunk) => {
      const devicesToNotify = [];
      chunk.forEach((device) => {
        // Filter VDOM keys
        const deviceid = device[DEVICES_ID_ATTR];
        if (keyData.keymap[deviceid]) {
          const vdomIdsMap = keyData.keymap[deviceid].reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
          }, {});
          device['vdoms'] = device['vdoms'].filter(
            (vdom) => vdomIdsMap[vdom[DEVICES_ID_ATTR]]
          );
        } else {
          device['vdoms'] = null;
        }
        devicesToNotify.push(device);
        allFiltered.push(device);
      });
      defer.notify(devicesToNotify);
    }
  );

  return defer.promise();
}

/**
 * Check if the given device group oid is a reserved device group
 * @param {Number|String} groupOid - Group's OID
 */
function isReservedDevGroup(groupOid) {
  const grpid = parseInt(groupOid) || 0;
  return _reservedDevGroupOid.indexOf(grpid) !== -1;
}

/**
 * Gets members of a device group
 * @param {Number|String} groupOid - Group's OID
 */
function getGroupMemberList(groupOid, promCancel) {
  groupOid = parseInt(groupOid);
  if (isReservedDevGroup(groupOid)) {
    // Just sets filters for the database if the group is one of Unregistered, Logging or Managed group
    return _getReservedGroupMemberList(null, groupOid, promCancel);
  }

  // For any other groups, send requests to fetch the member list
  return _getSingleGroupMemberList(groupOid, promCancel);
}

/**
 * Gets members of Unregistered, Managed or Logging Devices Group
 */
function _getReservedGroupMemberList(adomOid, groupOid, promCancel) {
  if (groupOid === MACROS.DVM.DVM_GRP_UNREG_OID) {
    // Unregistered Group is not stored in Database. Just send request to fetch
    return getUnregDevices();
  }

  let filterDeviceFn = null;
  const currentAdom = fiAdom.current();
  const is_fabric_adom = currentAdom.is_fabric;

  if (fiSysConfig.hasFmgFeature()) {
    if (groupOid === MACROS.DVM.DVM_GRP_LOGGING_OID) {
      // Logging Devices
      filterDeviceFn = (device) => {
        if (device.model_dev) return true; //model_dev should always be in logging group #627729
        //filter out managed FAZ from logging device list
        return (
          !(
            device['manage_mode'] & MACROS.DVM.DVM_MGMT_MODE_FMG &&
            device['os_type'] === MACROS.DVM.DVM_OS_TYPE_FAZ
          ) && device['manage_mode'] & MACROS.DVM.DVM_MGMT_MODE_FAZ
        );
      };
    } else if (groupOid === MACROS.DVM.DVM_GRP_MANAGED_OID) {
      // Managed Devices
      filterDeviceFn = (device) => {
        if (device.model_dev) return true; //model_dev should always be in managed group #627729
        const cnd = device['manage_mode'] === MACROS.DVM.DVM_MGMT_MODE_FMG_FAZ;
        if (!is_fabric_adom)
          return cnd || device['manage_mode'] === MACROS.DVM.DVM_MGMT_MODE_FMG;
        return cnd && device['os_type'] !== MACROS.DVM.DVM_OS_TYPE_FAZ;
      };
    }
  }

  return fiDeviceRedux.getDevicesByChunks(adomOid, {
    promCancel,
    filterFn: filterDeviceFn,
  });
}

function getUnregDevices() {
  return fiDeviceListLoader.loadUnregDeviceList();
}

/**
 * Get members of remote faz device group
 */
function getNonFGTGroupMemberList(grpOid) {
  return fiDeviceListLoader.loadNonFGTGroupMemberList(grpOid);
}

/**
 * Gets members of customer defined devcie group
 */
function _getSingleGroupMemberList(groupOid, promCancel) {
  return getGroupMemberKeys(groupOid, true).then(function (resp) {
    return getDevicesByKeys(resp.devs, promCancel);
  });
}

/**
 * // Deprecated:
 * Some places use this function and rely on directly modifying the data in cache.
 * Since the cache is in Redux, direct modification no longer works.
 * Any code calling getDevicesFromCache should be updated
 */
function getDevicesFromCache(adomOid) {
  return fiDeviceRedux.getDevicesAsArray(adomOid);
}

/**
 *
 * @param {oid} adomOid adom oid, default is current
 * @param {bool} noVDom true value is for no need vdom
 * @param {bool} allDevs true value is include logging device and managed device
 *                       fales value is only managed device
 */
function getDeviceIDs(adomOid, noVDom, allDevs) {
  const devs = getDevicesFromCache(adomOid);
  let list = [];

  devs.forEach(function (aDev) {
    if (
      allDevs &&
      (aDev['manage_mode'] === MACROS.DVM.DVM_MGMT_MODE_FMG_FAZ ||
        aDev['manage_mode'] === MACROS.DVM.DVM_MGMT_MODE_FMG)
    ) {
      return;
    }

    if (!noVDom && aDev.vdom_status && aDev.vdoms && aDev.vdoms.length) {
      list = list.concat(
        aDev.vdoms.map(function (vd) {
          return {
            name: aDev.name,
            oid: aDev.oid,
            vdom: vd.name,
            vdom_oid: vd.oid,
            vdom_status: 1, // vdom is enabled
          };
        })
      );
    } else {
      list.push({
        name: aDev.name,
        oid: aDev.oid,
        vdom_status: 0, // vdom is disabled
      });
    }
  });

  return list;
}

/**
 * get devices from specific ADOM
 * @param {integer} adomOid
 * @param {object} opts See DevicesObserver > getDevicesByChunks()
 * @return {Promise}
 */
function getDevices(adomOid, opts) {
  return fiDeviceRedux.getDevicesByChunks(adomOid, opts);
}

async function getDevicesAsScopeMembers(adomOid = fiAdom.current().oid) {
  const scopeMembers = [];

  const addMember = (name, vdom) => scopeMembers.push({ name, vdom });
  const addDevice = (device) => {
    if (!device) return;
    if (device.vdoms) {
      device.vdoms.forEach((vdom) => {
        addMember(device.name, vdom.name);
      });
    } else {
      addMember(device.name, 'root');
    }
  };

  const devices = await fiDeviceDataLoader.getDevices(adomOid);

  devices.forEach(addDevice);

  return scopeMembers;
}

/**
 * get device or VDOM obj by given device oid or did+'-'+vid
 * @return {object|null} Device if already loaded
 */
function getDevice(key) {
  const idPair = key?.toString().split('-') ?? [];
  const did = idPair[0];
  const vid = idPair[1];

  const device = fiDeviceRedux.getDevice(null, did);
  if (!vid) {
    return device;
  }

  return Array.isArray(device.vdoms)
    ? device.vdoms.filter((vdom) => vdom[DEVICES_ID_ATTR] === key)[0]
    : null;
}

/**
 * get managed devices, filtered out loging-only devices
 * @return {Promise} Notifies when each chunk is ready. Resolves with full list.
 */
function getManagedDevices(adomOid) {
  return _getReservedGroupMemberList(adomOid, MACROS.DVM.DVM_GRP_MANAGED_OID);
}

function getAllDevsLocationByAdom(adomOid, filterDevs) {
  return _getReservedGroupMemberList(
    adomOid,
    MACROS.DVM.DVM_GRP_MANAGED_OID
  ).then(
    (resp) => {
      const ret = {};
      resp.forEach((dev) => {
        //if filterDevs is undefined then it means all the devices.
        if (!filterDevs || filterDevs.includes(dev['_deviceName'])) {
          ret[dev['_deviceName']] = {
            address: dev['meta_Address'] || '',
            conn_status: dev.connection.conn,
            oid: parseInt(dev.oid),
            sn: dev.sn || '',
            lat: dev.geo.lat,
            lng: dev.geo.lng,
            latlng: dev.geo.latlng,
          };
        }
      });
      return ret;
    },
    () => ({})
  );
}

/**
 * Fetches members of a device group
 * NOT APPLICABLE FOR MANAGED GROUP, LOGGING GROUP AND UNREGISTERED GROUP
 * @param {Number|String} groupOid - a group's OID
 * @return {Promise}
 */
function getGroupMemberKeys(groupOid, groupVdom, includeGrpDev) {
  groupOid = parseInt(groupOid);
  const defer = createDeferred();
  const grpvdom = groupVdom ? 1 : 0;
  const includegrpdev = includeGrpDev ? 1 : 0;
  const cacheId = 'devgrp:%s:%s:%s'.printf([groupOid, grpvdom, includeGrpDev]);
  const cache = fiCache.cache(cacheId);

  if (cache) {
    defer.resolve(cache);
    return defer.promise;
  }

  fiFmgHttp
    .post({
      url: '/gui/adom/dvm/group/members',
      method: 'get',
      params: {
        oid: groupOid,
        grpvdom: grpvdom,
        includegrpdev: includegrpdev,
      },
    })
    .then(
      function (resp) {
        if (!resp) {
          defer.reject('Error');
        } else {
          const respData = resp[0].data || [];
          defer.resolve(respData);
          fiCache.cache(cacheId, respData);
        }
      },
      function (err) {
        defer.reject(err);
      }
    );
  return defer.promise;
}

/**
 * Fetches members of a device group including devices nested in subgroups
 * @param {Number|String} groupOid - a group's OID
 * @return {Promise}
 */
async function getGroupMembersFlat(groupOid) {
  const groupMemberKeys = await getGroupMemberKeys(groupOid, false, true);
  const deviceOids = (groupMemberKeys?.devs || []).map((dev) => {
    if (Array.isArray(dev)) {
      return dev[0].split('-')[0];
    }
    return dev;
  });
  return Promise.all(deviceOids.map((oid) => getDevice(oid)));
}

/**
 * Gets device groups of the current Adom
 * @return {Promise}
 */
function getDeviceGroups(useCache = true, abortCtrl) {
  const defer = createDeferred();

  const cacheId = 'devgrp:data';
  const cache = fiCache.cache(cacheId);

  if (cache && useCache) {
    defer.resolve(cache);
    return defer.promise;
  }

  fiFmgHttp
    .post(
      {
        method: 'get',
        url: '/gui/adom/dvm/groups',
      },
      abortCtrl
    )
    .then(
      function (resp) {
        const grps = resp[0].data || [];
        // Some reserved groups don't have name initially.
        // Gives them name based on group ID.
        grps.forEach(function (grp) {
          grp._fiDeviceId = '' + grp.oid;
          switch (grp.oid) {
            case MACROS.DVM.DVM_GRP_MANAGED_OID:
              grp.name =
                gettext('Managed') + ' ' + getDevTypeNameByOsType(grp.ostype);
              break;
            case MACROS.DVM.DVM_GRP_LOGGING_OID:
              grp.name =
                gettext('Logging') + ' ' + getDevTypeNameByOsType(grp.ostype);
              break;
            case MACROS.DVM.DVM_GRP_UNREG_OID:
              // This is Unregistered Devices group.
              // Because right now this group has name already, no need to assign new name
              break;
            default:
              break;
          }
        });

        defer.resolve(grps);
        fiCache.cache(cacheId, grps);
      },
      function (errResp) {
        defer.reject(errResp);
      }
    );

  return defer.promise;
}

function getFazDeviceGroups(adomOid) {
  const _getDevs = (groups) => {
    const devsIdx = groups.findIndex(
      (grp) => grp.oid === MACROS.DVM.DVM_GRP_MANAGED_OID
    );
    return devsIdx >= 0 ? getDevices(adomOid) : Promise.resolve([]);
  };
  const _getUnregs = (groups) => {
    const unregsIdx = groups.findIndex(
      (grp) => grp.oid === MACROS.DVM.DVM_GRP_UNREG_OID
    );
    return unregsIdx >= 0 ? getUnregDevices() : Promise.resolve([]);
  };
  const _getDevMemberList = (list) => {
    const devMemberList = [];
    list.forEach((device) => {
      if (device.vdoms)
        devMemberList.concat(
          device.vdoms.map((vdom) => ({
            ...vdom,
            oid: parseInt(device.oid),
            vdom_oid: vdom.oid,
            devname: device.name,
          }))
        );
      else
        devMemberList.push({
          ...device,
          oid: parseInt(device.oid),
          vdom_oid: MACROS.DVM.CDB_DEFAULT_ROOT_OID,
          devname: device.name,
        });
    });
    return devMemberList;
  };

  return getDeviceGroups().then((groups) => {
    const _map = {};
    groups.forEach((group) => {
      _map[group.oid] = group;
    });
    return Promise.all([_getDevs(groups), _getUnregs(groups)]).then(
      ([devs, unregs]) => {
        const ret = [];
        groups.forEach((group) => {
          const tmp = { ...group };
          if (group.grpMemberList) {
            tmp.grpMemberList = group.grpMemberList.map((mem) => ({
              ...mem,
              name: _map[mem.oid].name,
            }));
          }
          if (group.oid === MACROS.DVM.DVM_GRP_MANAGED_OID) {
            if (devs?.length) tmp.devMemberList = _getDevMemberList(devs);
          } else if (group.oid === MACROS.DVM.DVM_GRP_UNREG_OID) {
            if (unregs?.length) tmp.devMemberList = _getDevMemberList(unregs);
          } else if (group.devMemberList) {
            tmp.devMemberList = group.devMemberList.map((mem) => {
              const key = mem.oid + '-' + mem.vdom_oid;
              const vdom = getDevice(key);
              if (vdom) {
                return { ...vdom, ...mem, devname: vdom._deviceName };
              }
              const device = getDevice(mem.oid);
              if (device) return { ...device, ...mem, devname: device.name };
            });
          }
          ret.push(tmp);
        });
        return ret;
      }
    );
  });
}

async function getGroupMap(useCache, abortCtrl) {
  const groups = await getDeviceGroups(useCache, abortCtrl);
  return transform(
    groups,
    (result, group) => {
      result[group.oid] = group;
    },
    {}
  );
}

async function getGroupMapByName(useCache, abortCtrl) {
  const groups = await getDeviceGroups(useCache, abortCtrl);
  return transform(
    groups,
    (result, group) => {
      result[group.name] = group;
    },
    {}
  );
}

async function getGroupById(groupId) {
  let id = groupId;
  if (isString(groupId) && groupId.includes(':')) {
    id = getDevGrpId(groupId);
  }
  const map = await getGroupMap();
  return map[id];
}

async function getGroupByName(groupName) {
  const map = await getGroupMapByName();
  return map[groupName];
}

function getGroupMapCached() {
  const cacheId = 'devgrp:data';
  const cache = fiCache.cache(cacheId);

  if (!cache) {
    return null;
  }

  return cache.reduce((map, group) => {
    map[group.oid] = group;
    return map;
  }, {});
}

/**
 * Gets devices and groups records from a given scope member (typically from a policy package)
 * @param {Array} scopeMember - scope members from a policy package
 * @return {object} a map with devices and groups from the scope members
 */
function getFromScopeMember(scopeMember) {
  const devices = [];
  const groups = [];
  const tempDeviceMap = {};
  const saseDevicesMap = cloneDeep(
    fiDevicesSelector.getSaseDevicesMap(fiStore.getState())
  );

  for (let ii = 0; ii < scopeMember.length; ii++) {
    const member = scopeMember[ii];
    const memberOid = member.oid;
    let isSase = false;

    if (isUndefined(member.vdom_oid) && isUndefined(member.vdom)) {
      // member is a device group
      groups.push({ ...member, isGrp: true });
      continue;
    }

    // member is a device
    let device = tempDeviceMap[memberOid];
    if (!device) {
      device = getDevice(memberOid);

      // FortiSASE device
      if (isEmpty(device)) {
        device = saseDevicesMap[memberOid];
        if (device) isSase = true;
      }

      if (!device) continue;

      // Clear VDOMs field, since VDOMS are specified as separate entries
      if (device['vdoms']) {
        delete device['vdoms'];
      }

      tempDeviceMap[memberOid] = device;
      devices.push(device);
    }

    // has vdom
    if (member.vdom_oid) {
      if (isUndefined(device.vdoms)) {
        device.vdoms = [];
      }

      if (isSase) {
        continue;
      }

      const vdomid = memberOid + '-' + member.vdom_oid;
      const vdom = getDevice(vdomid);
      if (!vdom) continue;

      device.vdoms.push(vdom);
    }
  }

  return {
    devices,
    groups,
  };
}

function numOfManagedDevices() {
  return getManagedDevices().then(
    function (resp) {
      let amount = 0;
      const devices = resp || [];
      forEach(devices, function (dev) {
        if (dev.vdom_status === 1 && Array.isArray(dev.vdoms)) {
          // VDOM mode is enabled on this device. Calculates VDOMs
          amount += dev.vdoms.length;
        } else {
          amount++;
        }
      });
      return amount;
    },
    function () {
      return 0;
    }
  );
}

function getDeviceBySn(sn) {
  const currentAdom = fiAdom.current();
  if (_snToDevMap.adomName !== currentAdom.name) {
    _snToDevMap.map = _createDevMap('sn');
    _snToDevMap.adomName = currentAdom.name;
    if (!_snToDevMap.map) {
      return null;
    }
  }
  if (!_snToDevMap.map) return null;

  return _snToDevMap.map[sn];
}

function getDeviceByName(name) {
  const currentAdom = fiAdom.current();
  if (_nameToDevMap.adomName !== currentAdom.name) {
    _nameToDevMap.map = _createDevMap('name');
    _nameToDevMap.adomName = currentAdom.name;
    if (!_nameToDevMap.map) {
      return null;
    }
  }
  if (!_nameToDevMap.map) return null;

  return _nameToDevMap.map[name];
}

function _createDevMap(attr) {
  if (!fiDeviceRedux.isLoaded()) {
    return null;
  }

  return fiDeviceRedux.getDevicesAsArray().reduce((acc, cur) => {
    if (cur && cur[attr]) {
      acc[cur[attr]] = cur;
    }
    return acc;
  }, {});
}

//note: should export this and similar functions in other modules
//call from a useeffect on adom switch
function _clearDeviceMaps() {
  _nameToDevMap = {};
  _snToDevMap = {};
}

async function getDevAndDevGrpMap(params = {}) {
  const { deviceOids = [], devGrpOids = [], split = false } = params;

  let [devices, devGrpMap] = await Promise.all([
    getManagedDevices(),
    getGroupMap(),
  ]);

  // devices
  const devMap = {};
  if (Array.isArray(deviceOids) && deviceOids.length > 0) {
    devices = devices.filter((dev) => {
      const devOid = dev.oid;
      return deviceOids.includes(isString(devOid) ? parseInt(devOid) : devOid);
    });
  }
  devices.forEach((dev) => (devMap[dev.oid] = dev));

  // device groups
  if (Array.isArray(devGrpOids) && devGrpOids.length > 0) {
    devGrpMap = Object.entries(devGrpMap).reduce(
      (acc, [devGrpOid, devGrpObj]) => {
        if (devGrpOids.includes(devGrpOid)) {
          acc[devGrpOid] = devGrpObj;
        }
        return acc;
      },
      {}
    );
  }

  const byId = {
    ...devGrpMap,
    ...devMap,
  };

  if (split) {
    return {
      devMap,
      devGrpMap,
      byId,
    };
  }

  return byId;
}

function findVdomInDevMap(devMap, key) {
  const [did, vid] = key.toString().split('-');
  const oDevice = devMap[did];
  if (!oDevice) return;

  // vdom disabled device
  if (!oDevice.vdom_status && parseInt(vid) === ROOT_VDOM_ID) {
    return oDevice;
  }

  const vdoms = oDevice.vdoms || [];
  if (vdoms.length > 0) {
    const vdom = findElementInArrayByKey(vdoms, 'vid', vid);
    // add connection info from parent device to vdoms
    return { ...vdom, connection: oDevice.connection };
  }
}

function isVdomInAdom(devOid, vdomName, deviceLoader) {
  const devInfo = isFunction(deviceLoader) ? deviceLoader() : getDevice(devOid);
  if (!devInfo) return false; // If cannot fetch device, then does not exist
  if (!devInfo.vdom_status)
    return true; // If not vdom mode enabled, then device must exist if we're able to get data
  else if (devInfo.vdom_status && Array.isArray(devInfo.vdoms)) {
    // vdom mode and vdoms array exists, find vdom in array
    return devInfo.vdoms.find((vdom) => vdom?.name === vdomName) ? true : false;
  }

  return false;
}

export async function getScopeMembersByState({ isCentral, device }) {
  const {
    name: deviceName,
    vdom: vdomName,
    devgrp: grp,
    deviceGroupPath,
    _hasOrigVdomName,
  } = device;
  const devgrp = grp || getDevGrpId(deviceGroupPath);

  if (!deviceName && !devgrp) {
    return [{ name: MACROS.USER.DVM.DEFAULT_ALL_FGT_SCOPE_NAME }];
  }

  let scopeMembers = [];

  const addMember = (name, vdom) => scopeMembers.push({ name, vdom });
  const addDevice = (device) => {
    if (!device) return;
    if (device.vdoms) {
      device.vdoms.forEach((vdom) => {
        addMember(device.name, vdom.name);
      });
    } else {
      addMember(device.name, 'root');
    }
  };

  if (vdomName && _hasOrigVdomName && vdomName !== 'global') {
    // Add scopeMember for one VDOM
    addMember(deviceName, vdomName);
  } else if (deviceName) {
    // Add all scopeMembers for device (all VDOMs)
    await isReady();
    const device = getDeviceByName(deviceName);
    addDevice(device);
  } else if (devgrp) {
    if (isCentral) {
      // In central mode, scope member can be just group name
      const group = await getGroupById(devgrp);
      group &&
        scopeMembers.push({
          name: group.r_name || group.name,
          _isGroup: true,
        });
    } else {
      // Per-device mode should not have devgrp selected
      // Pick first device instead
      const groupMemberList = await getGroupMemberList(deviceGroupPath);
      groupMemberList[0] && addDevice(groupMemberList[0]);
    }
  } else {
    scopeMembers = null;
  }

  return scopeMembers;
}

function isVdomInAdomByDevName(devName, vdomName) {
  const devInfo = getDeviceByName(devName);
  if (!devInfo) return false; // If cannot fetch device, then does not exist
  if (!devInfo.vdom_status)
    return vdomName === 'root'; // VDOM mode disabled = only root vdom exists
  else if (devInfo.vdom_status && Array.isArray(devInfo.vdoms)) {
    // vdom mode and vdoms array exists, find vdom in array
    return devInfo.vdoms.find((vdom) => vdom?.name === vdomName) ? true : false;
  }

  return false;
}

async function getSaseDevicesById() {
  const state = fiStore.getState();
  const isLoaded = getSaseDevicesLoaded(state);
  if (isLoaded) {
    const saseDevicesMap = getSaseDevicesMap(state) || {};
    return cloneDeep(saseDevicesMap);
  }

  // sase devices not loaded to fistore yet
  const saseDevResp = await dispatch(fetchSaseDevices()).unwrap();
  return get(cloneDeep(saseDevResp), 'data.byId', {});
}

async function getSaseDevices() {
  const byId = await getSaseDevicesById();
  return Object.values(byId || {});
}
