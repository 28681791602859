import { useCallback } from 'react';
import { useField } from 'formik';
import _, { isEmpty } from 'lodash';

import { FmkCheckboxArray } from './FmkCheckboxArray';
import { validateAny, validators } from './util/util';

// Checkbox array that set fmk value on the same field, e.g. { fieldToSet: [1, 2, 3, 4, 5] },
// instead of setting different field for each item which is the default behavior for FmkCheckboxArray, e.g. { field1: true, field2: true, field3: true, field4: true, field5: true }
export const FmkFieldCheckboxArray = ({
  name,
  items = [],
  customOnClick: _customOnClick,
  customGetCheckboxValue: _customGetCheckboxValue,
  required,
  ...rest
}) => {
  const [, { value: checkboxArrVal }, { setValue: setCheckboxArrVal }] =
    useField({
      name,
      validate: validateAny(
        validators.required(required, (fieldValue) => {
          if (isEmpty(fieldValue)) {
            return true;
          }
        })
      ),
    });

  const customGetCheckboxValue = useCallback(
    (formValues, itemId, index, itemVal) => {
      if (_customGetCheckboxValue) {
        return _customGetCheckboxValue(formValues, itemId, index, itemVal);
      }

      // default get checkbox value
      const currVal = checkboxArrVal || [];
      return _.isArray(currVal) && currVal.includes(itemId);
    },
    [_customGetCheckboxValue, checkboxArrVal]
  );

  const customOnClick = useCallback(
    (evt, formValues, setFieldValue, index, item) => {
      if (_customOnClick) {
        return _customOnClick(evt, formValues, setFieldValue, index, item);
      }

      // default onClick
      evt.preventDefault();
      evt.stopPropagation();
      const checked = evt.target.checked;
      const itemObj = findItemObjByIdxOrId(items, index, item);
      if (_.isUndefined(itemObj)) return;

      const val = getItemVal(itemObj);

      const selected = new Set(checkboxArrVal || []);

      if (checked) {
        selected.add(val);
      } else {
        selected.delete(val);
      }

      const newVal = Array.from(selected);
      setCheckboxArrVal(newVal);
    },
    [checkboxArrVal, _customOnClick, items]
  );

  const comptProps = {
    name,
    items,
    customGetCheckboxValue,
    customOnClick,
    ...rest,
  };
  return <FmkCheckboxArray {...comptProps} />;
};
FmkFieldCheckboxArray.displayName = 'FmkFieldCheckboxArray';

FmkCheckboxArray.propTypes = {
  ...FmkCheckboxArray.propTypes,
};

function findItemObjByIdxOrId(items, index, item) {
  return item || items[index];
}

function getItemVal(item) {
  return item.name || item.id;
}
