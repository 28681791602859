import { ProToolkit } from '@fafm/neowise-pro';
import { isArray } from 'lodash';
import { isTaskDone, isTaskUnSucess } from './task_util';
import { ViewTaskHistory } from './ViewTaskHistory';
import { NwAlert } from '@fafm/neowise-core';

// https://pmdb.fortinet.com/ProjectManagement/viewDocument.php?id=23047&doc_type=spec
export const TaskStateLink = ({ taskData, className }) => {
  const taskFinishedWithError =
    taskData && isTaskDone(taskData) && isTaskUnSucess(taskData);
  const $opener = ProToolkit.useOpener();

  const handleClickErrors = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { history, start_tm } =
      (taskData?.line || []).find(isTaskUnSucess) || {};
    if (!isArray(history)) return;

    $opener.open(
      <ViewTaskHistory
        history={history}
        startTm={start_tm}
        jumpBy={isTaskUnSucess}
      />
    );
  };

  return taskFinishedWithError ? (
    <NwAlert type='danger' open class={className}>
      <span>
        {/* Installation ? */}
        {gettext('Task finished with ')}
        <span
          role='button'
          tabIndex={0}
          onClick={handleClickErrors}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleClickErrors(e);
            }
          }}
          className='tw-underline'
          title={gettext('Click to view progress report of the error line.')}
        >
          {gettext('errors')}
        </span>
        .
      </span>
    </NwAlert>
  ) : null;
};
