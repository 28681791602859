import { serverNowMoment, unixMsToLocalMoment } from './timezone';

const WEEKDAYS = {
  0: gettext('Sunday'),
  1: gettext('Monday'),
  2: gettext('Tuesday'),
  3: gettext('Wednesday'),
  4: gettext('Thursday'),
  5: gettext('Friday'),
  6: gettext('Sunday'),
};

export const ONE_SECOND = 1;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;
export const ONE_MONTH = ONE_DAY * 30;

/**
 * Format a the date from a given time relative to the current time.
 * Follow FOS formatting function.
 * @param {number} timeDifferenceFromNow, time difference relative to the current time in seconds (i.e. when the object has been created)
 * @param {number} now, the current time in milliseconds. By default use the current time provided by the Date object
 * @param {boolean} truncateNow, if true and time is less than one minute, show "Now"
 * @returns {string} the formatted date
 */
export const formatDateFromNow = (
  timeDifferenceFromNow,
  now,
  truncateNow = false
) => {
  now = now ?? serverNowMoment().unix() * 1000; // in ms

  const creationDate = unixMsToLocalMoment(
    now - timeDifferenceFromNow * 1000 // in ms
  ).toDate();
  const currentDate = unixMsToLocalMoment(now).toDate();

  if (timeDifferenceFromNow <= 0) {
    return creationDate.toDateString();
  } else if (timeDifferenceFromNow < ONE_MINUTE) {
    if (truncateNow) return gettext('Now');
    return gettext('%s seconds ago').printf([
      Math.floor(timeDifferenceFromNow / ONE_SECOND),
    ]);
  } else if (timeDifferenceFromNow < ONE_MINUTE * 2) {
    return gettext('Minute ago');
  } else if (timeDifferenceFromNow < ONE_HOUR) {
    return gettext('%s minutes ago').printf([
      Math.floor(timeDifferenceFromNow / ONE_MINUTE),
    ]);
  } else if (timeDifferenceFromNow < ONE_HOUR * 2) {
    return gettext('Hour ago');
  } else if (timeDifferenceFromNow < ONE_DAY) {
    return gettext('%s hours ago').printf([
      Math.floor(timeDifferenceFromNow / ONE_HOUR),
    ]);
  } else if (
    timeDifferenceFromNow < ONE_DAY * 2 &&
    (creationDate.getDay() + 1) % 7 === currentDate.getDay()
  ) {
    return gettext('Yesterday');
  } else if (timeDifferenceFromNow < ONE_WEEK) {
    return WEEKDAYS[creationDate.getDay()];
  }

  return creationDate.toDateString();
};
