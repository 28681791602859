import { toUnicode, toASCII } from 'punycode/';

type UrlComponents = {
  success: boolean;
  fullSchema?: string;
  schema?: string;
  authority?: string;
  path?: string;
  fullQuery?: string;
  query?: string;
  fullFragment?: string;
  fragment?: string;
  hasSchema: boolean;
  hasAuthority: boolean;
  hasPath: boolean;
};

// From FGT gui logic
export function getUrlComponents(url: string): UrlComponents {
  // parsing a URI Reference with a modified Regular Expression
  // from rfc2396 and rfc3986
  // breaking-down a URI into its components.
  // so that test.com:8080 is parsed as authority
  // fullSchema = $1
  // schema     = $2
  // authority  = $4 -> $3
  // path       = $5 -> $4
  // fullQuery  = $6 -> $5
  // query      = $7 -> $6
  // fullFragment  = $8 -> $7
  // fragment  = $9 -> $8
  const COMPONENT_REGEX =
    /^(([^:/?#]*):\/\/)?([^/?#]*)?([^?#]*)(\?([^#]*))?(#(.*))?/;
  let fullSchema,
    schema,
    authority,
    path,
    fullQuery,
    query,
    fullFragment,
    fragment;
  let success = true;
  try {
    [
      ,
      fullSchema,
      schema,
      authority,
      path,
      fullQuery,
      query,
      fullFragment,
      fragment,
    ] = url.split(COMPONENT_REGEX);
  } catch (e) {
    success = false;
  }
  return {
    success,
    fullSchema,
    schema,
    authority,
    path,
    fullQuery,
    query,
    fullFragment,
    fragment,
    hasSchema: schema !== undefined,
    hasAuthority: authority !== undefined,
    hasPath: path !== undefined,
  };
}

// From FGT gui logic
export function urlPunyCode(url: string | undefined, decode: boolean) {
  const { success, fullSchema, authority, path, query, fragment } =
    getUrlComponents(url || '');

  if (!success) {
    return url;
  }

  let result = fullSchema ? fullSchema : '';
  if (authority) {
    if (decode) {
      result = `${result}${toUnicode(authority)}`;
    } else {
      result = `${result}${toASCII(authority)}`;
    }
  }

  if (path) {
    result = `${result}${path}`;
  }
  if (query) {
    result = `${result}?${query}`;
  }
  if (fragment) {
    result = `${result}#${fragment}`;
  }
  return result;
}
