export const WizardButtonRenderer = ({ btns = [] }) => {
  return btns.map((btn) => {
    if (!btn.hidden || (btn.hidden && !btn.hidden())) {
      return (
        <btn.Component key={btn.id} {...btn.props}>
          {btn.text}
        </btn.Component>
      );
    }
  });
};
