import { lazy } from 'react';

const DvmModuleToolbar = lazy(async () => {
  const { DvmModuleToolbar } = await import(
    'react_apps/ra_dvm/module_toolbar/dvm/DvmModuleToolbar'
  );
  return { default: DvmModuleToolbar };
});

const TemplateModuleToolbar = lazy(async () => {
  const { TemplateModuleToolbar } = await import(
    'react_apps/ra_dvm/module_toolbar/provisioning_template/TemplateModuleToolbar'
  );
  return { default: TemplateModuleToolbar };
});

const PnoModuleToolbar = lazy(async () => {
  const { PnoModuleToolbar } = await import(
    'react_apps/ra_pno_services/PnoModuleToolbar'
  );
  return { default: PnoModuleToolbar };
});

const FabricModuleToolbar = lazy(async () => {
  const { FabricModuleToolbar } = await import(
    'react_apps/ra_fabric/components/module_toolbar/FabricModuleToolbar'
  );
  return { default: FabricModuleToolbar };
});

const FextModuleToolbar = lazy(async () => {
  const { ModuleToolbar } = await import(
    'react_apps/ra_fext/ModuleToolbar/FextModuleToolbar'
  );
  return { default: ModuleToolbar };
});

const FapModuleToolbar = lazy(async () => {
  const { FapModuleToolbar } = await import(
    'react_apps/ra_fortiap/common/ModuleToolbar/FapModuleToolbar'
  );
  return { default: FapModuleToolbar };
});

const FswModuleToolbar = lazy(async () => {
  const { ModuleToolbar } = await import(
    'react_apps/ra_fortiswitch/module_pages/SwitchModuleToolbar'
  );
  return { default: ModuleToolbar };
});

const IPsecVPNModuleToolbar = lazy(async () => {
  const { IPsecVPNModuleToolbar } = await import(
    'react_apps/ra_vpn/ModuleToolbar/IPsecVPNModuleToolbar'
  );
  return { default: IPsecVPNModuleToolbar };
});

const SSLVPNModuleToolbar = lazy(async () => {
  const { SSLVPNModuleToolbar } = await import(
    'react_apps/ra_vpn/ModuleToolbar/SSLVPNModuleToolbar'
  );
  return { default: SSLVPNModuleToolbar };
});

const RestrictAdminModuleToolbar = lazy(async () => {
  const { ModuleToolbar } = await import(
    'react_apps/ra_restrict_admin/ModuleToolbar/RestrictAdminModuleToolbar'
  );
  return { default: ModuleToolbar };
});

const toolbarConfigs = {
  dvmModuleToolbar: [
    {
      include: ['/dvm/main', '/dvm/chassis', '/sdwan/network'],
      component: DvmModuleToolbar,
      //exclude: ['/dvm/main/exampleexcluded']
    },
    //example for different toolbar under same root route:
    /* first add exclude: '/dvm/main/exampleexcluded' to above
    {
      include: ['/dvm/main/exampleexcluded']
      component: ExampleToolbar
      // we don't need to exclude the above routes here
      // since these configs will be processed in order
    }
    */
  ],

  templateModuleToolbar: [
    {
      include: [
        '/dvm/prvtmpl',
        '/dvm/firmwaretemplate',
        '/dvm/monitors',
        '/sdwan/prvtmpl',
        '/sdwan/rules',
        '/sdwan/overlay',
      ],
      component: TemplateModuleToolbar,
    },
  ],

  pnoModuleToolbar: [{ include: ['/pno'], component: PnoModuleToolbar }],

  fabricModuleToolbar: [
    { include: ['/fabric'], component: FabricModuleToolbar },
  ],

  fextModuleToolbar: [{ include: ['/fext'], component: FextModuleToolbar }],

  fapModuleToolbar: [{ include: ['/fortiap'], component: FapModuleToolbar }],

  fswModuleToolbar: [
    { include: ['/fortiswitch'], component: FswModuleToolbar },
  ],

  IPsecVPNModuleToolbar: [
    {
      include: ['/vpn/ipsec'],
      component: IPsecVPNModuleToolbar,
    },
  ],

  SSLVPNModuleToolbar: [
    {
      include: ['/vpn/ssl_list', '/vpn/ssl_portals', '/vpn/ssl_monitor'],
      component: SSLVPNModuleToolbar,
    },
  ],

  restrictAdminModuleToolbar: [
    {
      include: ['/rstadm'],
      component: RestrictAdminModuleToolbar,
    },
  ],
};

const NoToolbar = () => <></>;

/**
 *
 * choose module toolbar based on route pathname
 * use exclude: [...] to specify some possibly nested routes to not use the toolbar on
 * @returns toolbar component
 */
export const getToolbarComponent = (location) => {
  const pathname = location.pathname;

  for (const toolbarName in toolbarConfigs) {
    for (const config of toolbarConfigs[toolbarName]) {
      const { include, component, exclude } = config;
      if (
        include.some((path) => pathname.startsWith(path)) &&
        !exclude?.some((path) => pathname.startsWith(path))
      )
        return component;
    }
  }

  return NoToolbar;
};
