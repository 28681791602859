import { WizardContext } from './WizardContext';
import { useContext } from 'react';

export const useWizard = () => {
  const context = useContext(WizardContext);
  if (!context) {
    return undefined;
  } else {
    return context;
  }
};
