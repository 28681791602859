import { useEffect, useState } from 'react';
import { fiHttp } from 'fi-http';

export const useFiUserDisplayOptionService = () => {
  const [preference, setPreference] = useState(null);
  const arrayToObject = (array) =>
    array.reduce((obj, item) => {
      obj[item.userattr] = item;
      return obj;
    }, {});

  async function getData() {
    if (!preference) {
      return fiHttp
        .get('/p/util/get_user_display_preference/')
        .then(({ data }) => {
          // pm7457 - backend returns array format, but GUI prefers objects
          const preference = arrayToObject(data[0].data);
          setPreference(preference);
          return preference;
        });
    }
    return Promise.resolve(preference);
  }

  useEffect(() => {
    getData();
  }, []);

  function updateData(data) {
    // pm7457 - backend stores data in array format for performance concern. GUI use Object
    setPreference(data);
    let dataArray = Object.keys(data).map((k) => data[k]);
    return fiHttp.post('/p/util/update_user_display_preference/', dataArray);
  }

  function getFormattedData(
    displayOption,
    currentUser = {
      name: 'Sample Name',
      email: 'sample-email@example.com',
      phoneno: '01-234-7890',
      picture: '/static/images/noc/avatar_placeholder.png',
      social: {
        // facebook: {
        //   name: 'Sample Name',
        //   email: 'sample-email@example.com',
        //   phoneno: '01-234-7890',
        // },
        linkedin: {
          name: 'Sample Name',
          email: 'sample-email@example.com',
          phoneno: '01-234-7890',
        },
        google: {
          name: 'Sample Name',
          email: 'sample-email@example.com',
          phoneno: '01-234-7890',
        },
        // backend not supported
        salesforce: {
          name: 'Sample Name',
          email: 'sample-email@example.com',
          phoneno: '01-234-7890',
        },
      },
    },
    cb
  ) {
    const APPID_MAPPING = {
      forticlient: 'app17678',
      linkedin: 'app16331',
      google: 'app23094',
      facebook: 'app15832',
      salesforce: 'app16920',
      wechat: 'app33760',
      twitter: 'app16001',
      skype: 'app43540',
      fortinet: 'app43273',
      windows: 'app41469',
    };
    const CHANNELS = {
      FortiClient: {
        guiKey: 'FortiClient',
        label: gettext('FortiClient'),
        icon: APPID_MAPPING.forticlient,
      },
      FortiClient_Localhost: {
        guiKey: 'FortiClient_Localhost',
        label: gettext('FortiClient'),
        icon: APPID_MAPPING.forticlient,
      },
      FortiClient_User_Upload: {
        guiKey: 'FortiClient_User_Upload',
        label: gettext('FortiClient User Upload'),
        icon: APPID_MAPPING.forticlient,
      },
      LinkedIn: {
        guiKey: 'LinkedIn',
        label: gettext('LinkedIn'),
        icon: APPID_MAPPING.linkedin,
      },
      Google: {
        guiKey: 'Google',
        label: gettext('Google'),
        icon: APPID_MAPPING.google,
      },
      FSSO: {
        guiKey: 'FSSO',
        label: gettext('FSSO'),
        icon: APPID_MAPPING.fortinet,
      },
      Facebook: {
        guiKey: 'Facebook',
        label: gettext('Facebook'),
        icon: APPID_MAPPING.facebook,
      },
      Salesforce: {
        guiKey: 'Salesforce',
        label: gettext('Salesforce'),
        icon: APPID_MAPPING.salesforce,
      },
      Windows: {
        guiKey: 'Windows',
        label: gettext('Windows'),
        icon: APPID_MAPPING.windows,
      },
      Twitter: {
        guiKey: 'Twitter',
        label: gettext('Twitter'),
        icon: APPID_MAPPING.twitter,
      },
    };

    const listData = {
      email: displayOption.email.preference
        .split(',')
        .map((option) => CHANNELS[option])
        .filter((a) => !!a),
      name: displayOption.name.preference
        .split(',')
        .map((option) => CHANNELS[option])
        .filter((a) => !!a),
      picture: displayOption.picture.preference
        .split(',')
        .map((option) => CHANNELS[option])
        .filter((a) => !!a),
      phoneno: displayOption.phoneno.preference
        .split(',')
        .map((option) => CHANNELS[option])
        .filter((a) => !!a),
      social: displayOption.social.preference
        .split(',')
        .map((option) => CHANNELS[option])
        .filter((a) => !!a),
    };

    const displayStatus = {
      name: {
        status: true,
      },
      picture: {
        status: !!displayOption.picture.status,
      },
      phoneno: {
        status: !!displayOption.phoneno.status,
      },
      email: {
        status: !!displayOption.email.status,
      },
      social: {
        status: !!displayOption.social.status,
      },
    };

    ['name', 'email', 'phoneno'].forEach((cat) => {
      for (let i = 0; i < listData[cat].length; i++) {
        const value = currentUser.social[listData[cat][i].guiKey];
        if (value && value[cat]) {
          currentUser[cat] = value[cat];

          // swap with the first listData to show the icon
          //fix bug 540184
          // const tmp = listData[cat][0];
          // listData[cat][0] = listData[cat][i];
          // listData[cat][i] = tmp;

          if (cb) {
            cb(cat, value);
          }
          break;
        }
      }
    });

    // Add available social info to listData from currentUser
    // O(mn) => bad
    const newSocialData = [];
    Object.keys(currentUser.social).forEach((key) => {
      const existed = listData.social.find((s) => {
        return key === s.guiKey;
      });
      if (!existed) {
        newSocialData.push({
          guiKey: key,
          icon: APPID_MAPPING[key],
        });
      }
    });
    return {
      currentUser,
      listData,
      displayStatus,
      newSocialData,
    };
  }

  return {
    getData,
    updateData,
    getFormattedData,
  };
};
