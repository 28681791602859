import { createReducer } from '@reduxjs/toolkit';

import { devicesSyntaxAvailabilityAction } from './actions';

const initialState = {
  loaded: false,
  loading: false,
  syntaxMap: {},
};

/*
syntaxMap: {
  'global/system/lte-modem': {
    hasAvailable: true | false,
    availablePlatforms: {
      'FortiGate-40D+++700+++4': true,
      ...
    }
      -> map of platforms by platformName+++version+++mr
  }
}
*/

export const devicesSyntaxAvailabilityReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(devicesSyntaxAvailabilityAction.fetch.START, (state) => {
        state.loaded = false;
        state.loading = true;
      })
      .addCase(
        devicesSyntaxAvailabilityAction.fetch.SUCCESS,
        (state, action) => {
          state.loaded = true;
          state.loading = false;
          state.syntaxMap = action.payload.syntaxMap;
        }
      );
  }
);
