import {
  useFieldError,
  useFieldTouched,
  useFormikContextSelector,
} from 'formik';
import { NwAlert, NwIcon } from '@fafm/neowise-core';
import { isString } from 'fiutil';

export const FmkErrorSpan = ({ name, alertStyle = false }) => {
  const [err] = useFieldError(name);
  const [touch] = useFieldTouched(name);
  const submitCount = useFormikContextSelector((val) => val.submitCount);
  return isString(err) && (touch || submitCount) ? (
    alertStyle ? (
      <div className='tw-my-2'>
        <NwAlert
          type='danger'
          open
          customIcon={
            <NwIcon
              library='fa-solid'
              name='exclamation-circle'
              className='color-red'
            />
          }
        >
          {err}
        </NwAlert>
      </div>
    ) : (
      <span className='color-red' fi-form-field-error-for={name}>
        {err}
      </span>
    )
  ) : null;
};
