import { fiFmgHttp } from 'fi-http/fmg_json_api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { flatui_proxy_get } from 'fistore/utils/jsonapi';
import { apiResultFirst } from 'fistore/utils/api';
import { getUsername } from './selectors';

export const fetchSysConfig = createAsyncThunk(
  'sysConfig/fetchSysConfig',
  () => {
    return apiResultFirst(flatui_proxy_get, '/gui/sys/config');
  }
);

export const saveAvatar = createAsyncThunk(
  'sysConfig/saveAvatar',
  async ({ avatar, username }, { getState, rejectWithValue }) => {
    const realUsername = username ?? getUsername(getState());

    try {
      await fiFmgHttp.post({
        url: `/gui/sys/admin/users/${realUsername}/avatar`,
        method: 'post',
        params: {
          avatar,
        },
      });
      return avatar;
    } catch (err) {
      return rejectWithValue(err?.result[0]?.status || {});
    }
  }
);

const _slice = createSlice({
  name: 'sysConfig',
  initialState: {
    loading: false,
    data: null,
    status: 'init',
  },
  reducers: {
    setHostname(state, { payload }) {
      state.data.hostname = payload;
    },
    setSysTimestamp(state, { payload }) {
      state.data.sysTimestamp = payload;
    },
    setAiUser(state, { payload }) {
      state.data.fortiai = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSysConfig.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchSysConfig.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
        state.status = 'loaded';
      })
      .addCase(fetchSysConfig.rejected, (state) => {
        state.loading = false;
        state.data = null;
        state.status = 'failed';
      });
  },
});

export const { setHostname, setSysTimestamp, setAiUser } = _slice.actions;
export default _slice.reducer;
