import { fiFmgHttp } from 'fi-web/fi-http';
import { isNil, get } from 'lodash';
import { fiAdom } from 'fi-web/fi-session';

const _PREFIX = 'fi_meta_';
const _DELIMITER = 'o_0';

// Cache of meta fields full set.
// You will need to call `clean()` manually if there's update of meta fields
export default {
  //get: get,
  clean: clean,
  convertName: convertMetaFieldName,
  revertName: revertName,
  convertMetaFieldName,
  getAdminMetaFields,
  getAdomMetaFields,
  getDeviceMetaFields,
  getDevGroupMetaFields,
  getVdomMetaFields,
  getFwObjMetaFields,
};

function query(req) {
  return fiFmgHttp.forward(req).then((resp) => {
    const data = get(resp, '0.data', []);
    return data.filter(function (it) {
      return isNil(it.status) ? true : it.status;
    });
  });
}
export function getAdminMetaFields() {
  const req = {
    method: 'get',
    params: [
      {
        url: '/cli/global/_meta_fields/system/admin/user',
      },
    ],
  };
  return query(req).then((resp) => {
    return (resp || []).map((it) => {
      return {
        fieldname: it.name,
        importance: it.importance,
        fieldlength: it.length,
        status: it.status,
      };
    });
  });
}

export function getAdomMetaFields() {
  const req = {
    method: 'get',
    params: [
      {
        url: '/dvmdb/_meta_fields/adom',
      },
    ],
  };
  return query(req);
}

export function getDeviceMetaFields() {
  const req = {
    method: 'get',
    params: [
      {
        url: '/dvmdb/_meta_fields/device',
      },
    ],
  };
  return query(req);
}

export function getDevGroupMetaFields() {
  const req = {
    method: 'get',
    params: [
      {
        url: '/dvmdb/_meta_fields/group',
      },
    ],
  };
  return query(req);
}

export function getVdomMetaFields() {
  const req = {
    method: 'get',
    params: [
      {
        url: '/dvmdb/_meta_fields/vdom',
      },
    ],
  };
  return query(req);
}

export function getFwObjMetaFields(cateStr) {
  const adom = fiAdom.current();

  const url = adom.globaldb
    ? '/pm/config/global/_meta_fields/'
    : `/pm/config/adom/${adom.name}/_meta_fields/`;

  const req = {
    method: 'get',
    params: [
      {
        url: url + cateStr.replace(/ +/, '/'),
      },
    ],
  };
  return query(req).then(asStatusEnabled);
}

export function getFwPolicyMetaFields() {
  const adom = fiAdom.current();

  const url = adom.globaldb
    ? '/pm/config/global/_meta_fields/firewall/policy'
    : `/pm/config/adom/${adom.name}/_meta_fields/firewall/policy`;

  const req = {
    method: 'get',
    params: [
      {
        url,
      },
    ],
  };
  return query(req).then(asStatusEnabled);
}

const asStatusEnabled = (result) => {
  return (result || []).map(({ status, ...meta }) => ({
    ...meta,
    status: status === undefined ? 1 : status,
  }));
};

function clean() {
  //_metaFields = null;
}

/**
 * Since the meta field could contain characters which cannot be parsed by Angular's expression, e.g. space
 * This function replace those characters with the pre-defined delimiter.
 */
export function convertMetaFieldName(metaFieldName) {
  return _PREFIX + metaFieldName.replace(/\s/g, _DELIMITER);
}

/**
 * Reverts the name which has been converted back to its original meta field name
 * THIS MAY NOT BE ACCURATE. ALWAYS CHECK THE RESULT !
 */
function revertName(converted) {
  const name = converted.match(new RegExp('^' + _PREFIX + '(.+)'))[1];
  return name.replace(new RegExp(_DELIMITER, 'g'), ' ');
}
