import { curry } from '@fafm/fp';

/**
 * Wrapper for store.subscribe(). Use a selector function to watch only a
 * specific part of the state. onChange() will be called with the new and old values of
 * the selected slice when there is a change.
 *
 * @param {object} store The store object to observe
 * @param {Function} select Selector callback that returns the slice of state state to observe
 * @param {Function} onChange A callback to be invoked on every dispatch.
 * @returns {Function} A function to remove this change listener.
 */
export const observeStore = curry((store, select, onChange) => {
  let lastState;

  function handleChange() {
    let currentState = select(store.getState());
    if (currentState !== lastState) {
      let prev = lastState;
      lastState = currentState;
      onChange(currentState, prev);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
});

let _store_dispatch;
export const store_set_dispatch = (dispatch) => {
  _store_dispatch = dispatch;
};

export const store_get_dispatch = () => _store_dispatch;

// nested object update
const getUpdateValue = (type) => {
  let updateCase = {
    equal: (acc, index, payload) => payload,
    insert: insertItem,
    remove: removeItem,
    update: updateArray,
  };

  return type && updateCase[type] ? updateCase[type] : updateCase.equal;
};

const nestObjectAssign = (acc, keys, payload, type, index) => {
  if (keys.length === 1) {
    return Object.assign({}, acc, {
      [keys[0]]: getUpdateValue(type)(acc[keys[0]], index, payload),
    });
  } else {
    return Object.assign({}, acc, {
      [keys[0]]: nestObjectAssign(
        acc[keys[0]] || acc,
        keys.slice(1),
        payload,
        type,
        index
      ),
    });
  }
};

const insertItem = (array, index, payload) => {
  return [...array, payload];
};

const removeItem = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

const updateArray = (array, index, payload) => {
  return array.map((item, idx) => {
    if (idx !== index) {
      return item;
    }
    return typeof payload === 'string'
      ? payload
      : {
          ...item,
          ...payload,
        };
  });
};

const updateObject = (state, pathStr, payload) => (type) => (index) => {
  let keys = pathStr.split('.');
  return nestObjectAssign(state, keys, payload, type, index);
};

// addToArray(state, "soc.ftv.myarray", "test") => state.soc.noc.myarray.push("test")
export const addToArray = (state, pathStr, payload, index) =>
  updateObject(state, pathStr, payload)('insert')(index);
// removeFromArray(state, "soc.ftv.myarray", 0) => state.soc.noc.myarray.slice(0)
export const removeFromArray = (state, pathStr, index) =>
  updateObject(state, pathStr, {})('remove')(index);
// update(state, "soc.noc.archive", "test") => state.soc.noc.archive = "test"
// update(state, "soc.ftv.myarray", "test", 2) => state.soc.ftv.myarray[2] = "test"
export const update = (state, pathStr, payload, index) =>
  index !== undefined
    ? updateObject(state, pathStr, payload)('update')(index)
    : updateObject(state, pathStr, payload)('equal')(0);
