import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSessionAdomOid } from 'fistore/session/adom/selectors';

import {
  getDvmConfigGlobalDisplayOptionsByAdomOid,
  getDvmConfigMenuLayout,
} from './selectors';
import { dvmConfigDisplayOptsApi } from '../dvm_customize/api';
import { listenerMiddleware } from 'fistore/middlewares';
import { setLocalStorage } from 'fiutil/storage';

/** ----------------------------------------------------------------------------
 * Types
 * -------------------------------------------------------------------------- */
export enum DvmConfigMenuLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

type DvmConfigSliceState = {
  globalDisplayOptions: Record<string | number, string[]>;
  dvmConfigMenuLayout:
    | DvmConfigMenuLayout.Horizontal
    | DvmConfigMenuLayout.Vertical;
};

/** ----------------------------------------------------------------------------
 * Constants
 * -------------------------------------------------------------------------- */
const DVM_CONFIG_MENU_LAYOUT_KEY = 'dvmConfigMenuLayout';

const initialState: DvmConfigSliceState = {
  globalDisplayOptions: {},
  dvmConfigMenuLayout: DvmConfigMenuLayout.Horizontal,
};

/** ----------------------------------------------------------------------------
 * Slice
 * -------------------------------------------------------------------------- */
const _slice = createSlice({
  name: 'dvm/dvmConfig',
  initialState,
  reducers: {
    setDvmConfigGlobalDisplayOptions(state, { payload }) {
      const { adomOid, displayOptions } = payload;
      state.globalDisplayOptions[adomOid] = displayOptions || [];
    },
    setDvmConfigMenuLayout(state, { payload: layout }) {
      if (!Object.values(DvmConfigMenuLayout).includes(layout)) {
        return state;
      }

      state.dvmConfigMenuLayout = layout;
    },
  },
});

/** ----------------------------------------------------------------------------
 * Actions
 * -------------------------------------------------------------------------- */
export const { setDvmConfigGlobalDisplayOptions, setDvmConfigMenuLayout } =
  _slice.actions;

/** ----------------------------------------------------------------------------
 * Reducers
 * -------------------------------------------------------------------------- */
export default _slice.reducer;

/** ----------------------------------------------------------------------------
 * Thunks
 * -------------------------------------------------------------------------- */
export const fetchDvmConfigGlobalDisplayOptions = createAsyncThunk(
  'dvm/fetchDvmConfigGlobalDisplayOptions',
  async (payload: { adomOid?: number }, { getState, dispatch }) => {
    const state = getState() as any;
    const adomOid = payload?.adomOid || getSessionAdomOid(state);

    const existingDisplayOpts =
      getDvmConfigGlobalDisplayOptionsByAdomOid(adomOid)(state);
    if (existingDisplayOpts && existingDisplayOpts.length) {
      return existingDisplayOpts;
    }

    const displayOpts = await dvmConfigDisplayOptsApi(adomOid);

    dispatch(
      setDvmConfigGlobalDisplayOptions({
        adomOid,
        displayOptions: displayOpts,
      })
    );

    return displayOpts;
  }
);

export const updateDvmConfigGlobalDisplayOptions = createAsyncThunk(
  'dvm/updateDvmConfigGlobalDisplayOptions',
  async (
    payload: { displayOpts: any; adomOid?: number },
    { getState, dispatch }
  ) => {
    const { displayOpts, adomOid: _adomOid } = payload || {};
    if (!displayOpts || !displayOpts.length) return;

    const state = getState() as any;
    const adomOid = _adomOid || getSessionAdomOid(state);

    // save to db
    await dvmConfigDisplayOptsApi(adomOid, displayOpts);

    // save to store
    dispatch(
      setDvmConfigGlobalDisplayOptions({
        adomOid,
        displayOptions: displayOpts,
      })
    );
  }
);

/** ----------------------------------------------------------------------------
 * Listeners
 * -------------------------------------------------------------------------- */
// Save the current menu layout to local storage, if it's changed.
listenerMiddleware.startListening({
  predicate: (action, currState: any, prevState: any) => {
    return (
      setDvmConfigMenuLayout.match(action) &&
      getDvmConfigMenuLayout(currState) !== getDvmConfigMenuLayout(prevState)
    );
  },
  effect: (action, { getState }) => {
    setLocalStorage(
      DVM_CONFIG_MENU_LAYOUT_KEY,
      getDvmConfigMenuLayout(getState() as any)
    );
  },
});
