import { flatui_proxy } from '../../utils';
import { fetch_device } from '../devices_vdoms/api';

export const fetch_unreg_devices = (adomOid) => {
  const url = `/gui/adoms/${adomOid}/unreg-devices`;
  return flatui_proxy({
    url,
    method: 'get',
    params: {
      fields: ['all'],
    },
  });
};

export const fetch_unreg_device = (adomOid, deviceOid) => {
  return fetch_device(adomOid, deviceOid, false);
};
