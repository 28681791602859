import React from 'react';
import cn from 'classnames';

export const formatFabricApprovalElement = (mainContent, footerContent) => {
  return (
    <div
      className='tw-w-full tw-h-full tw-flex tw-flex-col tw-bg-neutral-0'
      id='fabric-approval-root'
    >
      <div className='tw-flex-grow tw-overflow-auto '>
        <FabricLogo />
        {mainContent}
      </div>
      <div className='tw-flex tw-justify-center tw-space-x-4 tw-p-4'>
        {footerContent}
      </div>
      <div className='tw-h-4'></div>
    </div>
  );
};

export function FabricLogo({ className }) {
  return (
    <img
      src='/static/images/shared/security-fabric.svg'
      className={cn('center mb-md', className)}
      style={{ width: '321px' }}
    ></img>
  );
}
