import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  tryLogin,
  pushFortitokenNotification,
  setLoggedIn,
} from 'fistore/auth/slice';

import { DefaultInputs } from './DefaultInputs';
import { InputGroup } from './InputGroup';
import {
  useRegularSSOLogin,
  useFortiCloudSSOLogin,
} from 'react_apps/ra-auth/hooks/useSSOLogin';
import { useFabricSSOLogin } from 'react_apps/ra-auth/hooks/useFabSSOLogin';
import { LoginFormBase } from './LoginFormBase';
import { OrLine } from 'react_apps/ra-auth/widgets/OrLine';
import { isEmpty } from 'lodash';

const CODE_SUCCESS = 0;

const LOGIN_ERR_MSGS = {
  [MACROS.ERR.ERR_CODE_NO_PERM_PROFILE]: gettext('No access privilege.'),
  [MACROS.ERR.ERR_CODE_MAX_FAILED_LOGIN]: gettext(
    'Too many login failures. Please try again in a few minutes...'
  ),
};

const TIMER_EXCEED_MAX_TRY = 60000;

const useChallengeLogin = ({ onLoginSuccess }) => {
  return {
    async onProcessLogin({ retCode, retData }) {
      if (retCode === CODE_SUCCESS && retData?.challenge === 1) {
        if (retData.ftk_push) {
          // sets isPushFtk to true
          onLoginSuccess();
        }
        return {
          failureMessage: retData.msg,
          showChallenge: true,
        };
      }
    },
  };
};

// this should be the last one
const useRegularLogin = ({ dispatch, onLoginSuccess }) => {
  return {
    async onProcessLogin({ retCode }) {
      const isSuccess = retCode === CODE_SUCCESS;
      if (isSuccess) {
        dispatch(setLoggedIn());
        onLoginSuccess && onLoginSuccess();
      }
      return {
        failureMessage: isSuccess
          ? null
          : // numbers are coverted to string automatically
            LOGIN_ERR_MSGS[retCode] ??
            'Authentication failure. Please try again...',
      };
    },
  };
};

export function DefaultLoginForm({
  loginEnv,
  customHeader = null,
  onLoginSuccess = null,
}) {
  const dispatch = useDispatch();
  const [failureMessage, setFailureMessage] = useState(null);
  const [showChallenge, setShowChallenge] = useState(false);
  const [disableLogin, setDisableLogin] = useState(false);
  const [params] = useSearchParams();
  const hookProps = { onLoginSuccess, loginEnv, dispatch };
  const pkiUser = loginEnv.pki_user;
  const isPKILogin = !isEmpty(pkiUser);
  const [isPushFtk, setIsPushFtk] = useState(false);
  const setPushFtk = useCallback(() => setIsPushFtk(true), []);

  // hooks are run on click login button
  const loginHooks = [
    useChallengeLogin({ onLoginSuccess: setPushFtk }),
    useRegularSSOLogin(hookProps),
    useFortiCloudSSOLogin(hookProps),
    useFabricSSOLogin(hookProps),
    // this must be the last one
    useRegularLogin(hookProps),
  ];

  const onLogin = useCallback(
    async ({ username, password, challengeInput }) => {
      try {
        const result = await dispatch(
          tryLogin({
            username,
            password,
            challengeInput,
            pkiUser,
          })
        ).unwrap();

        const retCode = result?.status?.code;
        const retMessage = result?.status?.message;
        const retData = result?.data;

        for (const hook of loginHooks) {
          const ret = await hook.onProcessLogin?.({
            retCode,
            retData,
            retMessage,
          });
          if (ret) {
            setFailureMessage(ret.failureMessage);
            setShowChallenge(ret.showChallenge ?? false);
            break;
          }
        }
      } catch (err) {
        if (err?.code === MACROS.ERR.ERR_CODE_MAX_FAILED_LOGIN) {
          setFailureMessage(LOGIN_ERR_MSGS[err.code]);
          setDisableLogin(true);
          setTimeout(() => {
            setDisableLogin(false);
          }, TIMER_EXCEED_MAX_TRY);
          return;
        }
        setFailureMessage(err?.message ?? 'Unknown error');
      }
    },
    [dispatch]
  );
  // for push notification
  useEffect(() => {
    if (isPushFtk) {
      dispatch(pushFortitokenNotification())
        .unwrap()
        .then((resp) => {
          const status = resp?.status;
          if (status?.code === 0) {
            onLoginSuccess && onLoginSuccess();
          }
        });
    }
  }, [isPushFtk, onLoginSuccess, dispatch]);

  const extraButtons = loginHooks
    .map((hook, index) => hook.getExtraButton?.({ key: index, params }))
    .filter(Boolean);

  // with pki login, username is preloaded
  return (
    <LoginFormBase customHeader={customHeader}>
      <DefaultInputs
        failureMessage={failureMessage}
        onLogin={onLogin}
        showChallenge={showChallenge}
        disableLogin={disableLogin}
        disableUsername={isPKILogin}
        initUsername={isPKILogin ? pkiUser : ''}
      />

      {extraButtons.length ? (
        <>
          <OrLine />
          <InputGroup>{extraButtons}</InputGroup>
        </>
      ) : null}
    </LoginFormBase>
  );
}
