export const PTYPE_ID = {
  HDR_FW640: 'hdrfw640',
  FTR_FW640: 'ftrfw640',
  HDR_FW760: 'hdrfw760',
  FTR_FW760: 'ftrfw760',
  HDR_SHAPING: 'hdrshaping',
  FTR_SHAPING: 'ftrshaping',
  FW640: 'fw640',
  FW760: 'fw760',
  FWS: 'fws',
  NAT46: 'nat46',
  NAT64: 'nat64',
  PROXY: 'proxy',
  EXPLICIT_PROXY: 'explicitProxy',
  SNAT: 'snat',
  CSNAT: 'csnat',
  DNAT: 'dnat',
  DNAT6: 'dnat6',
  DOS: 'dos',
  DOS6: 'dos6',
  INTF: 'intf-policy',
  INTF6: 'intf-policy6',
  MCAST: 'mcast',
  MCAST6: 'mcast6',
  LOCAL_IN: 'localin',
  LOCAL_IN6: 'localin6',
  SHAPING: 'shaping',
  FW640_VWP: 'fw640_vwp',
  FW760_VWP: 'fw760_vwp',
  FWS_VWP: 'fws_vwp',
  AUTH_RULE: 'authrule',
  AUTH_SETTING: 'authsetting',
  ZTNA_RULE: 'ztnarule',
  HP: 'hp',
  HP6: 'hp6',
  HP640: 'hp640',
  HP64: 'hp64',
  HP46: 'hp46',
  FPX_FW: 'fpx_fw',
  FPX_PAC: 'fpx_pac',
  FPX_SHAPING: 'fpx_shaping',
  FPX_CSNAT: 'fpx_csnat',
  NAC: 'nac',
  ACL: 'acl',
  ACL6: 'acl6',

  /** @deprecated do not use and clean up */
  HDR_FW: 'hdrfw',
  /** @deprecated do not use and clean up */
  FTR_FW: 'ftrfw',
  /** @deprecated do not use and clean up */
  HDR_FW6: 'hdrfw6',
  /** @deprecated do not use and clean up */
  FTR_FW6: 'ftrfw6',
  /** @deprecated do not use and clean up */
  FW: 'fw',
  /** @deprecated do not use and clean up */
  FW6: 'fw6',
  /** @deprecated do not use and clean up */
  FWC: 'fwc',
  /** @deprecated do not use and clean up */
  VWP: 'vwp',
  /** @deprecated do not use and clean up */
  VWP6: 'vwp6',
  /** @deprecated do not use and clean up */
  FWC_VWP: 'fwc_vwp',
};
