import { fiAdminProfile } from 'fi-session';
import { fiWorkspaceRequest, fiWorkspaceDataSelector } from 'fistore';

import * as common_fns from './common';
import {
  _currentAdom,
  _isLockedByMe,
  _isLockedByOther,
  _isLocked,
  _isUnlock,
  _isDirtyByMe,
  _isDirty,
  _getLockMsg,
} from './util';

export const adomMapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      return fiWorkspaceRequest.load_workspace_adoms(dispatch);
    },
    lock: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.lock_workspace_adom(dispatch, adom.oid || adom);
    },
    unlock: (
      adom = _currentAdom(),
      unlock_dirty = false,
      unlock_other = false
    ) => {
      return fiWorkspaceRequest.unlock_workspace_adom(
        dispatch,
        adom.oid || adom,
        unlock_dirty,
        unlock_other
      );
    },
    save: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.save_workspace_adom(dispatch, adom.oid || adom);
    },
    get_state: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.get_lockers_in_workspace_adom(
        dispatch,
        adom.oid || adom
      );
    },
  };
};

export const adomMapStateToProps = (state) => {
  // do not put inline functions, if may cause shadow compare fail?
  return {
    adoms: fiWorkspaceDataSelector.get_workspace_adoms(state),

    // helper methods
    get: getAdomLockInfo,
  };
};

export function getAdomLockInfo(adom = _currentAdom()) {
  let val = this.adoms;
  let lockedIds = val && val.byId ? val.byId : {};
  let info = lockedIds[adom.oid] || {
    lock: MACROS.SYS.LOCK_STATE_UNLOCKED,
  };
  return {
    allAdomLocks: JSON.parse(JSON.stringify(lockedIds)), // prevent modify after this fun called.
    adom: {
      oid: adom.oid,
      name: adom.name,
      ...info,
    },
    ...common_fns,
    isIPSTypeLock: _isIPSTypeLock,
    isLockedByMe: _isAdomLockedByMe,
    isLockedByOther: _isAdomLockedByOther,
    isLocked: _isAdomLocked,
    isUnlock: _isAdomUnlocked,
    isDirty: _isAdomDirty,
    hasDirty: _hasAdomDirty, // as long as adom is dirty no matter by me or other
    workflowSession: _workflow_session,
    lockMessage: _getLockMsg,
  };
}

function _isIPSTypeLock() {
  return !!this.adom.isIpsType;
}
function _isAdomLockedByMe(adom) {
  if (!fiAdminProfile.isRestrictedAdmin() && this.adom.isIpsType) {
    return false;
  }
  return _isLockedByMe(this.adom, adom);
}
function _isAdomLockedByOther(adom) {
  if (this.adom.isIpsType) {
    return false;
  }
  return _isLockedByOther(adom || this.adom);
}
function _isAdomLocked(adom) {
  if (this.adom.isIpsType) {
    return fiAdminProfile.isRestrictedAdmin()
      ? this.adom.lock == MACROS.SYS.LOCK_STATE_LOCKED
      : false;
  }

  return _isLocked(this.adom, adom);
}

function _isAdomUnlocked(adom) {
  if (this.adom.isIpsType) {
    return fiAdminProfile.isRestrictedAdmin()
      ? this.adom.lock != MACROS.SYS.LOCK_STATE_LOCKED
      : true;
  }

  return _isUnlock(adom || this.adom);
}
function _isAdomDirty() {
  return _isDirtyByMe(this.adom);
}
function _hasAdomDirty() {
  return _isDirty(this.adom);
}
function _workflow_session() {
  return this.adom.wfsid;
}
