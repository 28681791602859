import { fiFmgHttp } from 'fi-http';
import { fiAdminProfile } from 'fi-session';
import { getSchedule } from '../../../../ra_dashboard/widgets/system_widgets/systemInfo/ScheduleBackup';

export const getStatus = async () => {
  // if (process.env.NODE_ENV === 'development') {
  //   window.fiSetBackupStrategyStatus = setStatus;
  // }

  const result = {
    hasStep: false,
    changed: false,
    scheduleBackup: null,
  };
  try {
    await getStatusImpl(result);
  } catch {
    result.hasStep = false;
  }
  return result;

  async function getStatusImpl() {
    // check permission
    if (
      !(result.hasStep = fiAdminProfile.hasRDWRPermitOnSysSettingConfig({
        haCheck: false,
        fmgFabricCheck: false,
      }))
    ) {
      return;
    }

    // check change
    const req = {
      method: 'get',
      url: '/gui/backup_strategy_status',
    };
    if (
      (result.changed = await fiFmgHttp
        .post(req)
        .then((resp) => resp?.[0]?.data?.changed))
    ) {
      return;
    }

    // query data
    const scheduleQuery = getSchedule();
    result.scheduleBackup = await scheduleQuery;
  }
};

export const setStatus = async (status) => {
  const req = {
    method: 'post',
    url: '/gui/backup_strategy_status',
    params: {
      changed: !!status,
    },
  };
  await fiFmgHttp.post(req);
};
