import { isNil } from 'lodash';
import { fiLocalStorageService } from 'fi-localstorage';

export {
  getKey,
  getCustomColdefs,
  saveColdefOrder,
  saveColdefDiffs,
  resetColdefCache,
};

const orderAttr = '_coldef-orders';

const getKey = (col) => col.field || col.key;

const toColdefMap = (coldefs) => {
  return coldefs.reduce((map, cur) => {
    const key = getKey(cur);
    key && (map[key] = cur);
    return map;
  }, {});
};

const getColdefCache = (cacheName) =>
  fiLocalStorageService.getCatedef(cacheName) || {};

/**
 * @param {string} cacheName
 * @param {object[]} originalColdefs js defiend coldefs
 * @returns {object[]} a new coldefs with correct order, width, hidden, etc
 */
const getCustomColdefs = (
  cacheName,
  originalColdefs,
  { discardWidth } = {}
) => {
  const cached = getColdefCache(cacheName);
  // get saved field order, {field: index}
  const keyIdxMap = (cached[orderAttr] || []).reduce((map, key, i) => {
    map[key] = i;
    return map;
  }, {});

  const sortedColdefs = originalColdefs.slice();
  sortedColdefs.sort((a, b) => {
    // hidden cols or new columns will be put at the end
    const aIdx = keyIdxMap[getKey(a)] ?? Number.MAX_SAFE_INTEGER;
    const bIdx = keyIdxMap[getKey(b)] ?? Number.MAX_SAFE_INTEGER;
    return aIdx - bIdx;
  });

  return sortedColdefs.map((coldef) => {
    const cachedCol = cached[getKey(coldef)];
    const merged = { ...coldef };

    if (cachedCol) {
      Object.assign(merged, cachedCol);
      if (discardWidth) {
        merged.width = coldef.width;
      }
    }

    return merged;
  });
};

/**
 * write ordered fields to local storage
 * @param {string} cacheName
 * @param {string[]|object[]} orderedArr array of field or column defintion in the desired order
 */
const saveColdefOrder = (cacheName, orderedArr) => {
  if (typeof orderedArr[0] !== 'string') {
    orderedArr = orderedArr.map(getKey);
  }
  fiLocalStorageService.updateCatedef(cacheName, orderAttr, orderedArr);
};

/**
 * @param {object} o1
 * @param {object} o2
 * @param {string[]} attrTobeCheck
 * @returns {null|object}
 */
const objDiff = (o1, o2, attrTobeCheck = []) => {
  const set = new Set(attrTobeCheck);

  return Object.keys(o2).reduce((map, attr) => {
    if (set.has(attr) && o1?.[attr] != o2?.[attr]) {
      (map || (map = {}))[attr] = o2[attr];
    }
    return map;
  }, null);
};

/**
 * @param {string} cacheName
 * @param {object[]} originalColdefs column defintiion to be compared with
 * @param {object[]} newColdefs col defs with different settings, does not care about if the col def order matches originalColdefs
 * @param {string[]} attrTobeSaved settings to be saved if tehre is any diff
 */
const saveColdefDiffs = (
  cacheName,
  originalColdefs,
  newColdefs,
  attrTobeSaved
) => {
  const cached = getColdefCache(cacheName);
  // console.log('prevSaved', JSON.parse(JSON.stringify(cached)));

  const originalMap = toColdefMap(originalColdefs);

  const mergedDiffs = newColdefs.reduce((tobeSaved, newColdef) => {
    const key = getKey(newColdef);
    //generate to be saved: null | {width: xxx, hidden: xxx}
    const newDiff = objDiff(originalMap[key], newColdef, attrTobeSaved);

    let savedDiff = tobeSaved[key];
    if (newDiff) {
      // merge saved and tobe saved
      Object.assign(savedDiff || (tobeSaved[key] = {}), newDiff);
    } else if (savedDiff) {
      // remove the saved diff if there is no difference now
      attrTobeSaved.forEach(
        (attr) => !isNil(savedDiff[attr]) && delete savedDiff[attr]
      );
      !Object.keys(savedDiff).length && delete tobeSaved[key];
    }
    return tobeSaved;
  }, cached);
  // console.log('mergedDiffs', mergedDiffs);

  //save to local storage
  if (Object.keys(mergedDiffs).length) {
    fiLocalStorageService.setCatedef(cacheName, mergedDiffs);
  } else {
    resetColdefCache(cacheName);
  }
};
/* eslint-disable no-unused-vars */
const resetColdefCache = (cacheName) => {
  fiLocalStorageService.delCatedef(cacheName);
};
