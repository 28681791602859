import { logfetchSessionState } from './logfetchSessionState';
import * as d3 from 'd3';

export const logfetchColFormatter = (function () {
  function _requestTimeFormatter(col, row) {
    return _timeFormatter(new Date(row[col.field] * 1000));
  }
  function _timeFormatter(dt) {
    var //dt = new Date(row[col.field]*1000),
      now = new Date(),
      format;

    if (now.getDate() === dt.getDate()) {
      // chang to on same day!
      format = '%H:%M:%S';
    } else {
      if (dt.getFullYear() === now.getFullYear()) {
        format = '%m-%d %H:%M:%S';
      } else {
        format = '%Y-%m-%d %H:%M';
      }
    }

    return d3.timeFormat(format)(dt);
  }
  function _statusFormatter(col, row) {
    var statusFn = logfetchSessionState.statusFn;
    if (statusFn[row._parent.id]) {
      return statusFn[row._parent.id](col, row);
    }
    return '';
  }

  function _actionsFormatter(col, row) {
    var actionFn = logfetchSessionState.actionFn;
    if (actionFn[row._parent.id]) {
      return actionFn[row._parent.id](col, row);
    }
    return '';
  }
  function _hostFormatter(col, row) {
    if (row._parent.id === logfetchSessionState.SERVICE_ID) {
      return [row['clt.hostname'], '(', row['clt.sn'], ')'].join('');
    }

    return row['server-ip'];
  }

  return {
    requestTimeFormatter: _requestTimeFormatter,
    statusFormatter: _statusFormatter,
    actionsFormatter: _actionsFormatter,
    hostFormatter: _hostFormatter,
    timeFormatter: _timeFormatter,
  };
})();
