import $ from 'jquery';
import { fiLogout } from 'fi-session/logout';

// Global function to reset idle timer
// it is debounce in 5 seconds by executing on leading edge.
export const fiIdleTimer = (function () {
  let idle_seconds = 0;
  let idle_cntr = null;
  let msgRemover = null;

  // system setting idle timeout in minutes
  // eslint-disable-next-line
  let ss_idle_time_sec = 15 * 60;

  // when time left is '<=' this point, gui will show extend session message
  let thredhold = 30; // seconds

  // keep is same as frequency of heartbeat (5 seconds).
  let deviation = 5; // seconds

  // to skip user activities, move, key event
  let skip_tracking = false;

  let TXT_HDR = gettext('Session Timeout');
  let TXT_BTN_EXT = gettext('Extend Session');
  let TXT_BTN_END = gettext('Log Out');
  let TXT_MSG = gettext(
    'The session is about to expire. If you need more time, please click "Extend Session". Otherwise you will be automatically logged out.'
  );

  let idleTimerOps = {
    start: (session_time_sec = 15 * 60) => {
      idleTimerOps.stop();
      ss_idle_time_sec = session_time_sec;
      idle_seconds = 0;
      idle_cntr = setInterval(() => idle_seconds++, 1000);
      skip_tracking = false;
    },
    stop: () => {
      skip_tracking = true;

      if (idle_cntr) {
        clearInterval(idle_cntr);
      }

      // make sure 'check' would not send out rest
      idle_seconds = thredhold + 1;
    },
    active: () => {
      if (!skip_tracking) {
        idle_seconds = 0;
      }
    },
    reset: () => {
      removeMessage();

      // to prevent reset again in next heartbeat (time_left>thredhold)
      idle_seconds = thredhold;

      return $.ajax({
        method: 'POST',
        contentType: 'application/json;charset=UTF-8',
        url: '/cgi-bin/module/flatui_proxy',
        cache: false,
        data: JSON.stringify({
          url: '/gui/session-timer',
          method: 'update',
        }),
        dataType: 'json',
      });
    },
    check: (time_left) => {
      // for test only
      // eslint-disable-next-line
      // console.log({time_left, idle_seconds});

      // when session timer nearly out,
      if (time_left <= thredhold) {
        // and user is activating, then need to reset
        if (idle_seconds < thredhold) {
          idleTimerOps.reset();
        } else {
          // show timer left if there is no active and with 30 seconds.
          showMessage(time_left);
        }
      } else {
        // make sure last active can trigger reset
        if (idle_seconds >= deviation && idle_seconds < thredhold) {
          idleTimerOps.reset();
        }
      }
    },
  };

  function showMessage(time_left) {
    skip_tracking = true;
    idle_seconds = thredhold + 1;

    if (!msgRemover) {
      let cnt = time_left;
      let tmpl = [];
      tmpl.push('<div class="modal-backdrop in idle-timeout-backdrop"></div>');
      tmpl.push('<div class="modal-dialog idle-timeout-msg">');
      tmpl.push('<div class="modal-content">');
      tmpl.push('<div class="modal-header"><h3>' + TXT_HDR + '</h3></div>');
      tmpl.push('<div class="modal-body">');
      tmpl.push('<div><span class="message">' + TXT_MSG + '</span></div>');
      tmpl.push(
        '<div><h1 class="count-down">' + genCountDown(cnt) + '</h1></div>'
      );
      tmpl.push('</div>');
      tmpl.push('<div class="modal-footer action-btn"></div>');
      tmpl.push('</div>');
      tmpl.push('</div>');

      let msgEl = $(tmpl.join(''));
      let extBtn = $(
        '<button class="btn btn-primary">' + TXT_BTN_EXT + '</button>'
      );
      let logoutBtn = $(
        '<button class="btn btn-warning">' + TXT_BTN_END + '</button>'
      );

      msgEl.appendTo('body');
      msgEl.find('.action-btn').append(extBtn).append(logoutBtn);

      extBtn.on('click', () => {
        idleTimerOps.reset();
      });

      logoutBtn.on('click', () => {
        logout();
      });

      let t = setInterval(() => {
        cnt--;
        msgEl.find('.count-down').html(genCountDown(cnt));
        if (cnt === 0) {
          logout();
        }
        if (cnt < 0) {
          cnt = 0;
          removeMessage();
        }
      }, 1000);

      msgRemover = function () {
        msgEl.remove();
        t && clearInterval(t);
        msgRemover = null;
      };
    }
  }

  function genCountDown(time_left) {
    let sec = time_left % 60;
    let min = Math.floor(time_left / 60);
    min = min && min < 10 ? '0' + min : min;
    sec = sec < 10 ? '0' + sec : sec;
    return min + ':' + sec;
  }

  function removeMessage() {
    msgRemover && msgRemover();
    skip_tracking = false;
  }

  function logout() {
    fiLogout();
  }
  return idleTimerOps;
})();
