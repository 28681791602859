import { createSelector } from '@reduxjs/toolkit';

const myState = (state) => state.soc.dashEditing;

export const getPresent = createSelector(myState, (state) =>
  state.present ? state.present.data : null
);

export const getPast = createSelector(myState, (state) =>
  state.past ? state.past : []
);

export const getFuture = createSelector(myState, (state) =>
  state.future ? state.future : []
);
