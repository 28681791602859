import { fiCookies } from './cookies';

export function fiCsrfToken(nonEncode = false) {
  let csrf = fiCookies.get('HTTP_CSRF_TOKEN');
  if (csrf) {
    return nonEncode ? csrf : encodeURIComponent(csrf);
  }
  return '';
}

export function fiCSRFHeaders() {
  return {
    'XSRF-TOKEN': fiCsrfToken(true),
  };
}
