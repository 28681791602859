import { isFunction, isArray, isObject, isNil } from 'lodash';
import { fiStoreConnector } from 'fistore';
import { fiDeviceDataLoader } from 'ra_device_util';
import { deviceStatus, fiDvmTableCellParser } from 'fi-dvm';

let _tree_cache = {};
const _cleanupFns = [];

// invalidate cache when devices or device groups are updated
_observeStoreUpdates();

/** ----------------------------------------------------------------------------
 * Exported functions
 * -------------------------------------------------------------------------- */
export const fiDeviceTreeService = {
  createDeviceTreeNodes,
  makeDeviceNode,
  countDevicesInDeviceGroupNode,
  getTotalNumOfAssignedDevices,
  isDeviceGroup,
  getDeviceIcon,
  getDeviceDetails,
  getDeviceId,
  sortAssignedDevices,
  cleanupDeviceTreeCache,
};

function _observeStoreUpdates() {
  const cleanups = [
    bindDeviceGrpsUpdate(onDeviceGrpUpdate),
    bindDeviceUpdate(onDeviceUpdate),
  ];
  _cleanupFns.concat(cleanups);
}

function bindDeviceGrpsUpdate(fn) {
  return fiStoreConnector((state) => ({
    deviceGroups: state.adom.deviceGroups,
    deviceGroupsMemb: state.adom.deviceGroupsMemb,
  }))(fn);
}

function bindDeviceUpdate(fn) {
  return fiStoreConnector((state) => ({
    devices: state.dvm.devices,
    vdoms: state.dvm.vdoms,
  }))(fn);
}

// eslint-disable-next-line
function onDeviceGrpUpdate(curr, dispatch, prev) {
  cleanupDeviceTreeCache();
}

// eslint-disable-next-line
function onDeviceUpdate(curr, dispatch, prev) {
  cleanupDeviceTreeCache();
}

function cleanupDeviceTreeCache() {
  _cleanupFns.forEach((cb) => isFunction(cb) && cb());
  _tree_cache = {};
}

function createCacheKey(devices, devNDevGrpMap) {
  const devOids = [];
  const devGrpOids = [];
  for (const devOrDevGrp of devices) {
    const isGroup = isDeviceGroup(devOrDevGrp, devNDevGrpMap);
    if (isGroup) {
      devGrpOids.push(devOrDevGrp.oid);
      continue;
    }

    let devOid = `${devOrDevGrp.oid}`;
    if (Array.isArray(devOrDevGrp.vdoms)) {
      devOrDevGrp.vdoms.forEach((vdom) => {
        devOid += `-${vdom.oid}`;
      });
    }
    if (devOrDevGrp.vdom_oid) {
      devOid += `-${devOrDevGrp.vdom_oid}`;
    }
    devOids.push(devOid);
  }
  const sortedDevGrpOids = devGrpOids.filter((dev) => !isNil(dev)).sort();
  const sortedDevOids = devOids
    .filter((dev) => !isNil(dev))
    .sort((a, b) => a.localeCompare(b));
  const cacheKey = `devgrp-${sortedDevGrpOids.join(
    ','
  )}:dev-${sortedDevOids.join(',')}`;

  return {
    devOids: sortedDevOids,
    devGrpOids: sortedDevGrpOids,
    cacheKey,
  };
}

function getCache(cacheKey) {
  return _tree_cache[cacheKey];
}

function setCache(cacheKey, tree) {
  _tree_cache[cacheKey] = tree;
  return _tree_cache;
}

function createDeviceTreeNodes(
  devices,
  devNDevGrpMap,
  showDuplicateDev = false
) {
  if (!devices || devices.length === 0 || !devNDevGrpMap) return devices;

  // use cached device tree if given the same devices and device groups to prevent unnecessary tree generation
  const { cacheKey } = createCacheKey(devices);
  const cache = getCache(cacheKey);
  if (cache) {
    // console.log('using cache', cacheKey, cache);
    return cache;
  }

  // console.log('no cache found, regenerating device tree', cacheKey);

  // need to process device group first, so sort device group to the start of the array
  const sorted = sortAssignedDevices(devices, devNDevGrpMap, true);

  const inUsedDevs = [];
  const devicesCopy = [...sorted];
  const filtered = devicesCopy.reduce((acc, dev) => {
    const oid = dev?.oid;
    const isGroup = isDeviceGroup(dev, devNDevGrpMap);

    // for group
    if (isGroup) {
      const oDevGrp = devNDevGrpMap?.[oid];
      if (!oDevGrp) return [...acc];
      const devGrpNode = _processGroup(oDevGrp, devNDevGrpMap, inUsedDevs);
      return [...acc, devGrpNode];
    }

    // for device
    const deviceId = getDeviceId(dev.oid, dev.vdom_oid);
    if (showDuplicateDev || !inUsedDevs.includes(deviceId)) {
      const devNode = makeDeviceNode(devNDevGrpMap, dev, deviceId);
      return [...acc, devNode];
    }

    return acc;
  }, []);

  if (filtered && filtered.length) {
    setCache(cacheKey, filtered);
  }

  return filtered || [];
}

function makeDeviceNode(devNDevGrpMap, dev, id, _deviceName) {
  if (dev.vdoms && devNDevGrpMap) {
    const devOid = dev.oid;
    dev.children = dev.vdoms.map((vdom) => {
      const { name, id, oid } = vdom;
      const key = `${devOid}-${oid ?? id}`;
      return {
        id: key,
        name,
        iconProps: { name: 'vdom', className: 'color-grey ' },
        iconTitle: name,
      };
    });
  }
  let vdom = dev.vdom;
  let vdom_oid = dev.vdom_oid;
  if (!vdom || !vdom_oid) {
    const info = getVdomInfo(dev);
    vdom = info.vdom;
    vdom_oid = info.vdom_oid;
  }
  const {
    iconProps,
    title,
    fullDeviceName,
    _oData,
    originalDev,
    deviceName,
    vdomName,
  } = getDeviceDetails(dev, devNDevGrpMap, _deviceName);
  const node = {
    ...dev,
    id,
    name: fullDeviceName,
    deviceName,
    vdomName: vdomName || vdom,
    vdom,
    vdom_oid,
    iconProps,
    iconTitle: title,
    _oData,
    originalDev,
  };
  return node;
}

function getVdomInfo(dev) {
  if (dev.vdom_status === 0 && dev._isDevice) {
    return {
      vdom: 'root',
      vdom_oid: MACROS.DVM.CDB_DEFAULT_ROOT_OID,
    };
  }
  return {
    vdom: dev.name,
    vdom_oid: dev.oid,
  };
}

/**
 * Only vdom is counted
 */
function countDevicesInDeviceGroupNode(devGrp, res = { count: 0 }) {
  const children = devGrp?.children;
  if (isArray(children) && children.length === 0) return res;

  for (const child of children) {
    if (!child) continue;

    // device
    const { _isDevice, vdom_mode } = child;
    const vdomOrVdomDisabledDevice =
      !_isDevice ||
      (_isDevice && vdom_mode === MACROS.PM2CAT.PM2_VDOM_MODE_NO_VDOM);
    if (!child.isGrp && vdomOrVdomDisabledDevice) {
      res.count += 1;
      continue;
    }

    // group
    countDevicesInDeviceGroupNode(child, res);
  }

  return res;
}

function getTotalNumOfAssignedDevices(
  assignedDevices,
  ignoreDevGrpChildren = false,
  devIdsSet = new Set(),
  devGrpIdsSet = new Set()
) {
  let total = 0;

  for (const dev of assignedDevices) {
    const currentId = dev.id;

    if (isArray(dev.children)) {
      const devGrp = dev;

      // skip if counted before
      if (devGrpIdsSet.has(currentId)) {
        continue;
      }

      devGrpIdsSet.add(currentId);
      if (ignoreDevGrpChildren) {
        total += 1;
      } else {
        total += getTotalNumOfAssignedDevices(
          devGrp.children,
          ignoreDevGrpChildren,
          devIdsSet,
          devGrpIdsSet
        );
      }
      continue;
    }

    // skip if counted before
    if (devIdsSet.has(currentId)) {
      continue;
    }

    devIdsSet.add(currentId);
    total += 1;
  }

  return total;
}

function getDeviceIcon(dev, devNDevGrpMap, oid) {
  let deviceData = devNDevGrpMap[oid] || dev;
  // vdom will use the same icon as device
  if (!deviceData)
    return {
      iconProps: { name: 'device', className: 'color-grey ' },
      ititle: gettext('Model Device'),
    };
  const { ititle, css } = fiDvmTableCellParser.getData(
    deviceData,
    deviceStatus.field.deviceName
  );
  return {
    ititle,
    iconProps: css,
    _oData: deviceData,
  };
}

function getDeviceDetails(dev, devNDevGrpMap, _deviceName) {
  if (!devNDevGrpMap) return {};
  const { name, vdom, oid, devName, devVdom, did, rtype } = dev;
  // deviceName is from full dev obj, and name is from each device obj (e.g. in cli template scope member)
  const actualDeviceName = _deviceName ? _deviceName : devName || name;
  const vdomText = devVdom || vdom ? ` [${devVdom || vdom}]` : '';
  // Use device oid (ie. did) to get icon for vdoms,
  // otherwise icon will most likely be default (ie. model device)
  // because vdom oid cannot be found in devNDevGrpMap
  const { iconProps, ititle, _oData } = getDeviceIcon(
    dev,
    devNDevGrpMap,
    deviceStatus.isVdom(rtype) ? did ?? oid : oid
  );
  return {
    deviceName: actualDeviceName,
    vdomName: vdom,
    iconProps: iconProps || { name: 'device', className: 'color-grey ' },
    title: ititle || '',
    vdomText: vdomText || '',
    fullDeviceName: `${actualDeviceName}${vdomText}`,
    _oData,
    originalDev: dev,
  };
}

function getDeviceId(oid, vdom_oid) {
  return vdom_oid ? `${oid}-${vdom_oid || ''}` : oid;
}

function isDeviceGroup(dev, devNDevGrpMap) {
  const oDeviceGroup = devNDevGrpMap?.[dev?.oid];
  if (!isObject(oDeviceGroup)) return false;
  return !!oDeviceGroup.isGrp;
}

function sortAssignedDevices(devs, devNDevGrpMap, devGrpFirst = true) {
  if (!devNDevGrpMap || !devs) return devs;

  const deviceGroups = [];
  const devices = [];

  // separate device groups from devices
  if (devs) {
    for (const dev of devs) {
      if (isDeviceGroup(dev, devNDevGrpMap)) deviceGroups.push(dev);
      else devices.push(dev);
    }
  }

  // sort device groups and devices
  if (deviceGroups.length > 1) deviceGroups.sort(_compareDevices);
  if (devices.length > 1) devices.sort(_compareDevices);

  if (devGrpFirst) return [...deviceGroups, ...devices];
  return [...devices, ...deviceGroups];
}

/** ----------------------------------------------------------------------------
 * Helper functions
 * -------------------------------------------------------------------------- */
function _processDevMemberList(oDevGrp, devNDevGrpMap, inUsedDevs) {
  // device members
  const devMemberIds = oDevGrp.devMemberList
    ? oDevGrp.devMemberList.map(({ oid, vdom_oid }) =>
        getDeviceId(oid, vdom_oid)
      )
    : [];
  if (devMemberIds.length > 0) {
    const devChildren = [];
    for (const devId of devMemberIds) {
      const member = fiDeviceDataLoader.findVdomInDevMap(devNDevGrpMap, devId);
      if (!member) continue;
      const devNode = makeDeviceNode(
        devNDevGrpMap,
        member,
        devId,
        member._deviceName
      );
      devChildren.push(devNode);
      inUsedDevs.push(devId);
    }
    if (oDevGrp.children.length === 0) {
      oDevGrp.children = devChildren;
    } else {
      oDevGrp.children = [...oDevGrp.children, ...devChildren];
    }
  }
}

function _processGroup(oDevGrp, devNDevGrpMap, inUsedDevs) {
  const devGrpNode = makeDeviceNode(devNDevGrpMap, oDevGrp, oDevGrp.oid);
  devGrpNode.children = [];

  // get children
  // device members
  _processDevMemberList(devGrpNode, devNDevGrpMap, inUsedDevs);

  // group members
  const grpMemberIds = devGrpNode.grpMemberList
    ? devGrpNode.grpMemberList.map((obj) => obj?.oid)
    : [];
  for (const oid of grpMemberIds) {
    if (!oid) continue;
    const subGrp = devNDevGrpMap?.[oid];
    const subGrpNode = _processGroup(subGrp, devNDevGrpMap, inUsedDevs);
    devGrpNode.children.push(subGrpNode);
  }

  const devGrpChildrenCount = { count: 0 };
  countDevicesInDeviceGroupNode(devGrpNode, devGrpChildrenCount);
  const childrenCount = devGrpChildrenCount?.count || 0;
  devGrpNode.childrenCount = childrenCount;
  // include number of children in device group name by default
  devGrpNode.name = `${oDevGrp.name} (${childrenCount})`;
  return devGrpNode;
}

function _compareDevices(oDev1, oDev2) {
  return (oDev1?.name || '').localeCompare(oDev2?.name || '');
}
