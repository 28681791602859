import { sum, map, get } from 'lodash';
export const ROOT = 'tasks';

export const getGroups = (state) => {
  return get(state, [ROOT, 'groups']);
};

export const getTotalNum = (state) => {
  return sum(map(getGroups(state), 'num'));
};

export const getNumByGroup = (group) => (state) => {
  return get(state, [ROOT, 'groups', group, 'num']) || 0;
};

export const getFetcherStarted = (state) => {
  return get(state, [ROOT, 'fetcherStarted']);
};
