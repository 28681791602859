import { printfd } from 'kit-string';

export const colorName = {
  // color css name and it's color name (help text/tooltip display)
  // Translators: the code is -100, -500, etc.
  'bg-clr-opt-1': printfd(gettext('neutral%(code)s'), { code: '-1000' }),
  'bg-clr-opt-2': printfd(gettext('blue%(code)s'), { code: '-500' }),
  'bg-clr-opt-3': printfd(gettext('emerald%(code)s'), { code: '-500' }),
  'bg-clr-opt-4': printfd(gettext('red%(code)s'), { code: '-300' }),
  'bg-clr-opt-5': printfd(gettext('red%(code)s'), { code: '-200' }),
  'bg-clr-opt-6': printfd(gettext('tomato%(code)s'), { code: '-500' }),
  'bg-clr-opt-7': printfd(gettext('tomato%(code)s'), { code: '-600' }),
  'bg-clr-opt-8': printfd(gettext('orange%(code)s'), { code: '-800' }),
  'bg-clr-opt-9': printfd(gettext('yellow%(code)s'), { code: '-900' }),
  'bg-clr-opt-10': printfd(gettext('amber%(code)s'), { code: '-400' }),
  'bg-clr-opt-11': printfd(gettext('amber%(code)s'), { code: '-600' }),
  'bg-clr-opt-12': printfd(gettext('emerald%(code)s'), { code: '-700' }),
  'bg-clr-opt-13': printfd(gettext('green%(code)s'), { code: '-600' }),
  'bg-clr-opt-14': printfd(gettext('emerald%(code)s'), { code: '-300' }),
  'bg-clr-opt-15': printfd(gettext('emerald%(code)s'), { code: '-600' }),
  'bg-clr-opt-16': printfd(gettext('green%(code)s'), { code: '-700' }),
  'bg-clr-opt-17': printfd(gettext('sky%(code)s'), { code: '-600' }),
  'bg-clr-opt-18': printfd(gettext('sky%(code)s'), { code: '-500' }),
  'bg-clr-opt-19': printfd(gettext('blue%(code)s'), { code: '-500' }),
  'bg-clr-opt-20': printfd(gettext('indigo%(code)s'), { code: '-300' }),
  'bg-clr-opt-21': printfd(gettext('violet%(code)s'), { code: '-200' }),
  'bg-clr-opt-22': printfd(gettext('purple%(code)s'), { code: '-200' }),
  'bg-clr-opt-23': printfd(gettext('pink%(code)s'), { code: '-200' }),
  'bg-clr-opt-24': printfd(gettext('pink%(code)s'), { code: '-300' }),
  'bg-clr-opt-25': printfd(gettext('red%(code)s'), { code: '-400' }),
  'bg-clr-opt-26': printfd(gettext('gray%(code)s'), { code: '-400' }),
  'bg-clr-opt-27': printfd(gettext('gray%(code)s'), { code: '-500' }),
  'bg-clr-opt-28': printfd(gettext('orange%(code)s'), { code: '-400' }),
  'bg-clr-opt-29': printfd(gettext('lime%(code)s'), { code: '-800' }),
  'bg-clr-opt-30': printfd(gettext('indigo%(code)s'), { code: '-200' }),
  'bg-clr-opt-31': printfd(gettext('purple%(code)s'), { code: '-100' }),
  'bg-clr-opt-32': printfd(gettext('brown%(code)s'), { code: '-300' }),
};
