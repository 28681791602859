import { isArray } from 'lodash';
import { fiAdom, fiSysConfig, fiAdminProfile } from 'fi-session';
import { fiDvmScriptsType } from '../constants';

const INHERITANCE_TYPES = {
  NEITHER_DEV_VDOM: 0,
  DEV_ONLY: 1,
  VDOM_ONLY: 2,
  BOTH_DEV_VDOM: 3,
};

function getMissingFmgVariables(fmgVariablesArray) {
  if (!isArray(fmgVariablesArray)) return [];
  let missingVariables = [];
  for (const variable of fmgVariablesArray) {
    const [key, value] = Object.entries(variable)[0];
    if (value === INHERITANCE_TYPES.NEITHER_DEV_VDOM) {
      missingVariables.push(key);
    }
  }
  return missingVariables;
}

function hasEditPermission() {
  return fiAdminProfile.hasRDWRPermitOn(
    MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS
  );
}

function hasRunPermission(type) {
  let isRwUser = false;
  if (type === fiDvmScriptsType.ScriptRunningTarget.POLICY_PKG) {
    isRwUser = fiAdminProfile.hasRDWRPermitOn('adom_policy_pack');
  } else if (type === fiDvmScriptsType.ScriptRunningTarget.DEVICE_DB) {
    isRwUser = fiAdminProfile.hasRDWRPermitOn('dev_cfg');
  } else if (type === fiDvmScriptsType.ScriptRunningTarget.REMOTE_CLI) {
    isRwUser = fiAdminProfile.hasRDWRPermitOn('deploy_management');
  }
  return isRwUser;
}

function getDefaultExecScriptObj() {
  return {
    admin: fiSysConfig.current().admin_user_name,
    adom: fiAdom.current().oid,
    // specific ip of device, default 0.0.0.0
    host: '0.0.0.0',
    // if the script is tcl
    iftcl: 0,
    /* if script running, it is device oid list,
     * if script group running, it is group oid */
    scope: [],
    ondb: 0,
    // policy package oid
    pkg: 0,
    // script id
    script: 0,
    // if server create task, keep this field 0
    task: 0,
    /* type: "device": install 1 device, type "group": install device group,
     * type: "multi-device": install selected devices more than 1.
     * type: oids.length === 1 ? devices[0].isGrp ? "group" : "device" : "multi-device",
     * type: "device" used for pkg install also */
    type: 'device',
  };
}

function getMissingVariableFromErrMsg(errMsg) {
  // e.g. errMsg = Error: [-9001] cli/template/cliprof_jinja/ : invalid value - parse cli template fail: variable 'testing' not exist.
  const txt = 'parse cli %s fail';
  const isCorrectErrMsg =
    (errMsg.includes(txt.printf(['cmd'])) ||
      errMsg.includes(txt.printf(['template']))) &&
    errMsg.includes('not exist');

  // get missing variable name in single quotes (including the quotes), then remove the single quotes
  return isCorrectErrMsg
    ? errMsg.match(/'(.*)'/g)[0].replaceAll("'", '')
    : null;
}

function showScript() {
  return (
    fiSysConfig.current().show_script &&
    !fiAdminProfile.noPermitOn(MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS)
  );
}

export const fiDvmScriptUtils = {
  getDefaultExecScriptObj,
  hasEditPermission,
  hasRunPermission,
  showScript,
  getMissingFmgVariables,
  getMissingVariableFromErrMsg,
};
