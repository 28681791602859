import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  assign,
  mapValues,
  isObject,
  isFunction,
  last,
  cloneDeep,
  set,
  every,
  isEqual,
} from 'lodash';
import { heartbeat, initCSRF, fetchLoginEnv } from 'fistore/auth/slice';
import { getLoginEnv } from 'fistore/auth/selectors';
import { fetchSysConfig } from 'fistore/session/sysConfig/slice';
import {
  getIsAdomEnabled,
  getIsRestrictedAdmin,
} from 'fistore/session/sysConfig/selectors';
import {
  getAppByKey,
  getAllAppTree,
  getAllApps,
  getAppStateCache,
  getContentMenuLayout,
  getSideMenuSelected,
  getCurrentState,
  getAppTreeMap,
  getAppAvailable,
  getSideMenuByKey,
  getAppInit,
  getIsAppInited,
  findNextApp,
} from './selectors';
import {
  appTreeReduce,
  getAppKey,
  getAppChildren,
  genAppTree,
  MENU_DIVIDER,
  genAppNode,
  getAppUniKey,
  findInMatches,
} from './utils';
import { listenerMiddleware } from 'fistore/middlewares';
import {
  fetchSessionAdom,
  switchSessionAdom,
} from 'fistore/session/adom/slice';
import { setLocalStorage, getLocalStorage } from 'fiutil/storage';
import { getPropAsFn } from 'fiutil/selector';
import { treeInsert } from 'fiutil/tree';
import { mergePayload } from 'fistore/utils/reducer';
// content menu local storage key
const CM_LAYOUT_KEY = 'contentMenuLayout';
const APP_STATE_KEY = 'appStateCache';
const assertObject = (val) => (isObject(val) ? val : null);

const initialState = {
  allAppTrees: null,
  allApps: null,
  allAppTree: null,
  appPathMap: null,
  sideMenuSelected: null,
  sideMenuTree: null,
  contentMenuTree: null,
  contentMenuLayout: {},
  isLoadingApp: false,
  rootAppKey: null,
  appTree: null,

  currentState: null,
  appStateCache: {},
  init: 'no', // 'doing' || 'done'
  appTreeMap: null,
};

function normApps(apps) {
  return mapValues(apps, (app, key) => ({ ...app, key }));
}

function _deleteTree(stateProxy, key) {
  const { appTreeMap } = stateProxy;
  const oldTree = appTreeMap[key];
  if (oldTree) {
    appTreeReduce({
      items: oldTree,
      init: null,
      fn: (accu, node) => {
        const key = getAppKey(node);
        delete stateProxy.allApps[key];
        delete stateProxy.appTreeMap[key];
        delete stateProxy.appPathMap[key];
        delete stateProxy.sideMenuMap[key];
      },
    });
  }
}

function mergeState(state, { payload }) {
  assign(state, payload);
}

const _slice = createSlice({
  name: 'routing',
  initialState,
  reducers: {
    // generate app tree, app path map.
    switchAppTreeByKey(state, { payload: key }) {
      const { allAppTrees } = state;
      state.allAppTree = allAppTrees[key];
      // No need to keep all the trees after switch, switching requires re-login.
      delete state['allAppTrees'];
    },
    loadAppInfo(state, { payload }) {
      state.allApps = normApps(payload.allApps);
      state.allAppTrees = payload.allAppTrees;
    },
    // Generate app tree, app path map.
    setContentMenuLayout(state, { payload: layout }) {
      const { sideMenuSelected } = state;
      set(state, ['contentMenuLayout', sideMenuSelected], layout);
    },
    setSideMenuSelected(state, { payload }) {
      state.sideMenuSelected = payload;
      state.contentMenuTree = state.appTreeMap?.[payload] ?? null;
    },
    startLoadingApp(state) {
      state.isLoadingApp = true;
    },
    endLoadingApp(state) {
      state.isLoadingApp = false;
    },
    setAppMenus: mergeState,
    setAppCache: mergeState,
    appInitDone(state) {
      state.init = 'done';
    },
    setCurrentState: mergeState,
    _insertAppTree(state, { payload }) {
      const { key, subTree, apps } = payload;
      _deleteTree(state, key);

      const newTree = treeInsert(getAppChildren)({
        items: state.allAppTree,
        subTree,
        matchFn: (node) => key === getAppKey(node),
        postFn: (node, children) => [getAppKey(node), children],
      });
      state.allAppTree = newTree;
      assign(state.allApps, normApps(apps));
    },
    _setCurrentMacthes: mergePayload('currentMatches'),
  },
  extraReducers: (builder) => {
    builder.addCase(initApp.pending, (state) => {
      state.init = 'pending';
    });
  },
});

export default _slice.reducer;

export const {
  setContentMenuLayout,
  startLoadingApp,
  endLoadingApp,
  appInitDone,
  setAppMenus,
  setCurrentState,
} = _slice.actions;

// do not export
const {
  loadAppInfo,
  setAppCache,
  switchAppTreeByKey,
  setSideMenuSelected,
  _insertAppTree,
  _setCurrentMacthes,
} = _slice.actions;

// thunks
export const go = createAsyncThunk(
  'routing/go',
  async ({ to, opts }, { getState }) => {
    if (!window.__router) {
      console.error('No router instance, cannot navigate');
      return;
    }
    const currentState = getCurrentState(getState());
    // ReactRouter API
    return await window.__router.navigate(to, {
      fromRouteId: currentState?.id,
      ...opts,
    });
  }
);

export const SELECT_ADOM_REQUIRED_KEY = 'SELECT_ADOM_REQUIRED';

export const initApp = createAsyncThunk(
  'routing/initApp',
  async (payload, { dispatch, getState }) => {
    // Must fetch loginEnv before initalizing CSRF token. The CSRF, theme and
    // language settings may need to get something from loginEnv.
    const loginEnv = getLoginEnv(getState());
    if (!loginEnv) {
      await dispatch(fetchLoginEnv()).unwrap();
    }
    dispatch(initCSRF());
    // set up keep alive
    dispatch(heartbeat());

    // sysConfig must before adom fetch because some adom listeners require it
    // TODO: stop rest if it fails
    await dispatch(fetchSysConfig());
    // set up app tree
    const { allAppTrees, allApps } = payload;
    dispatch(loadAppInfo({ allAppTrees, allApps }));
    await dispatch(_switchAppTree());

    if (
      getIsAdomEnabled(getState()) &&
      getLocalStorage(SELECT_ADOM_REQUIRED_KEY, true)
    ) {
      return;
    }
    await dispatch(fetchSessionAdom());
    await dispatch(refreshAppTree());
    dispatch(_finishInit());
  },
  {
    // prevent running twice
    condition: (_, { getState }) => {
      return getAppInit(getState()) === 'no';
    },
  }
);

const _finishInit = createAsyncThunk(
  'routing/finishInit',
  (_, { dispatch }) => {
    dispatch(_slice.actions.appInitDone());
    const appStateCache = assertObject(getLocalStorage(APP_STATE_KEY));
    const contentMenuLayout = assertObject(getLocalStorage(CM_LAYOUT_KEY));
    dispatch(setAppCache({ appStateCache, contentMenuLayout }));
  }
);

/**
 * Go to the specified app or auto jump to the first available one with best
 * guess as follows:
 *
 * 1. App is found and has no sub tree, go to app directly
 * 2. App is found and has sub tree, regard it as parent, go to 6.
 * 3. App is not found, search its ancestors for the siblings
 * 4. Cannot find the parent from defined hierachy, find it from the route matches
 * 5. Still cannot find the parent, use the top menus
 * 6. Jump to the cached child of the parent or its first leaf
 */
export const goApp = createAsyncThunk(
  'routing/goApp',
  async (payload = {}, { getState, dispatch }) => {
    // dispatch(startLoadingApp());
    const { key, params, opts } = payload;
    const app = findNextApp(key)(getState());
    if (app) {
      if (!app.path) {
        // cannot allow jump to any app without path
        throw new Error('App has no path!');
      }
      const to = params
        ? app.path.replace(/:\(\w+\)/g, (match) => {
            return params[match.slice(2, -2)];
          })
        : app.path;
      const ret = await dispatch(go({ to, opts }));
      // dispatch(endLoadingApp());
      return ret;
    }
    throw new Error('cannot find app.');
  }
);

async function _tryLoadSubTree(app, dispatch) {
  if (app.loadSubTree) {
    const ret = await app.loadSubTree();
    if (ret) {
      const { subTree, apps } = ret;
      await dispatch(insertAppTree({ key: app.key, subTree, apps }));
    }
  }
}

export const loadSubTree = createAsyncThunk(
  'routing/loadSubTree',
  async (appKey, { getState, dispatch }) => {
    const app = getAppByKey(appKey)(getState());
    await _tryLoadSubTree(app, dispatch);
  }
);

/** Refreshes the current app tree.
 * Should be async to allow merging.
 */
export const refreshAppTree = createAsyncThunk(
  'routing/refreshAppTree',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const allAppTree = getAllAppTree(state);
    const allApps = getAllApps(state);

    const appTreeMap = {};
    const appPathMap = {};
    // A tree with only permitted nodes.
    const appTree = genAppTree(getAppChildren)({
      items: allAppTree,
      fn: (node, path) => {
        const key = getAppKey(node);
        if (key === MENU_DIVIDER) return key;
        const app = allApps[key];
        if (!app) return null;
        const showCheckers = getPropAsFn(app.showCheckers, []);
        if (showCheckers.every((ck) => ck(state))) {
          // Run through all the checkers
          appPathMap[key] = path.map(getAppKey);
          return key;
        }
        return null;
      },
      postFn: (key, children) => {
        const app = allApps[key];
        // put in post fn to respect checker results
        // remove parent with no children and hideIfNoChildren is true
        // if app is undefined, need to throw out to check what's wrong
        const appNode =
          app.hideIfNoChildren &&
          every(children, (child) => isEqual(child, [MENU_DIVIDER, null])) // `_.every` will return true for empty children
            ? null
            : genAppNode(key, children);
        // Put children into the tree map after they're handled
        appTreeMap[key] = appNode?.[1] ?? null;
        return appNode;
      },
    });

    // Generate sideMenu
    const sideMenuMap = {};
    const sideMenuTree = genAppTree(getAppChildren)({
      items: appTree,
      fn: (node) => {
        const key = getAppKey(node);
        const app = allApps[key];
        if (app?.isHidden) return null;
        return key;
      },
      postFn: (key, children) => {
        sideMenuMap[key] = allApps[key];
        return genAppNode(key, children);
      },
      stopFn: sideMenuCondition(allApps, state),
    });

    // set menu states
    dispatch(
      setAppMenus({
        appTree,
        appTreeMap,
        appPathMap,
        sideMenuMap,
        sideMenuTree,
      })
    );
    // set content menu
    dispatch(setSideMenuSelected(getSideMenuSelected(getState())));
  }
);

function normalizeMatch(match) {
  const key = match?.handle?.appUniKey;
  const appUniKey = isFunction(key) ? key(match?.params) : key;
  let newMatch = cloneDeep(match);
  return set(newMatch, 'handle.appUniKey', appUniKey);
}

export const trySetCurrentState = createAsyncThunk(
  'routing/trySetCurrentState',
  async ({ matches }, { getState, dispatch }) => {
    const normMatches = matches.map(normalizeMatch);
    const currentState = last(normMatches);
    const appUniKey = getAppUniKey(currentState);
    if (!appUniKey) {
      throw Error('route has no AppUniKey defined');
    }

    // load app's loadSubTree so it can have correct children
    const parentWithLoader = findInMatches(
      normMatches,
      (key) => getAppByKey(key)(getState()),
      (app) => app?.loadSubTree
    );
    if (parentWithLoader) {
      await _tryLoadSubTree(parentWithLoader, dispatch);
    }

    const state = getState();
    const sideMenuKey = getSideMenuByKey(appUniKey)(state);
    const appTreeMap = getAppTreeMap(state);
    dispatch(setSideMenuSelected(sideMenuKey));
    dispatch(_setCurrentMacthes(normMatches));
    if (
      !sideMenuKey ||
      !getAppAvailable(appUniKey)(state) ||
      // if has children, go to the first child
      appTreeMap[appUniKey]?.length
    ) {
      return dispatch(goApp({ key: appUniKey }));
    }
    // cache
    const selApp = getAppByKey(sideMenuKey)(state);
    const appStateCache = getAppStateCache(state);
    const rootAppKey = getAppUniKey(normMatches.find(getAppUniKey));
    dispatch(
      setCurrentState({
        rootAppKey,
        appStateCache: appTreeMap[sideMenuKey]
          ? {
              ...appStateCache,
              [sideMenuKey]: selApp.noStateCache ? null : currentState,
            }
          : appStateCache,
        currentState,
      })
    );
  }
);

// This thunk is sent at init time, not for external use
const _switchAppTree = createAsyncThunk(
  'routing/switchAppTree',
  (payload, { getState, dispatch }) => {
    const isRestrictedAdmin = getIsRestrictedAdmin(getState());
    const treeKey = isRestrictedAdmin ? 'restrictedAdmin' : 'default';
    dispatch(switchAppTreeByKey(treeKey));
  }
);

export const insertAppTree = createAsyncThunk(
  'routing/insertAppTree',
  async (payload, { dispatch }) => {
    dispatch(_insertAppTree(payload));
    await dispatch(refreshAppTree());
  }
);

const callOrReturn = (fn, state) => {
  return isFunction(fn) ? fn(state) : fn;
};

const sideMenuCondition = (allApps, state) => (node, level) => {
  const key = getAppKey(node);
  const app = allApps[key];
  return (
    key === MENU_DIVIDER ||
    callOrReturn(app?.hideSubItems, state) ||
    app?.isHidden ||
    level >= 2
  );
};

// listeners
// save the previous router state to local storage, e.g. automatically select the previously selected content menu item
listenerMiddleware.startListening({
  predicate: (action, currState, prevState) => {
    return (
      setCurrentState.match(action) &&
      getAppStateCache(currState) !== getAppStateCache(prevState)
    );
  },
  effect: (action, { getState }) => {
    setLocalStorage('appStateCache', getAppStateCache(getState()));
  },
});

// Save the current content menu layout to local storage, if it's changed.
listenerMiddleware.startListening({
  predicate: (action, currState, prevState) => {
    return (
      setContentMenuLayout.match(action) &&
      getContentMenuLayout(currState) !== getContentMenuLayout(prevState)
    );
  },
  effect: (action, { getState }) => {
    setLocalStorage(CM_LAYOUT_KEY, getContentMenuLayout(getState()));
  },
});

// If switch session adom, jump to the first available app.
listenerMiddleware.startListening({
  actionCreator: switchSessionAdom.fulfilled,
  effect: async (action, { dispatch, getState }) => {
    // after switch, adom is ready, so refresh the app tree now
    await dispatch(refreshAppTree());
    const state = getState();
    // finishes the app init
    if (!getIsAppInited(state)) {
      dispatch(_finishInit());
    }
  },
});
