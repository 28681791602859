import { useSelector } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';
import { NwIcon } from '@fafm/neowise-core';
import { ProLayout } from '@fafm/neowise-pro';
import { AdomSelect } from './AdomSelect';
import { useHelpItems } from './hooks/useHelpItems';
import { useUserItems } from './hooks/useUserItems';
import { TaskDropdown } from './TaskDropdown';
import { SystemMode } from './SystemMode';
import { NotifDropdown } from './NotifDropdown';
import LogdbRebuildingProgress, {
  useLogdbRebuidingProgess,
} from './LogdbRebuildingProgress';
import { ExtensionDebugger } from './extension_debugger/ExtensionDebugger';
// CHECK: in trunk seems there is only FazFabric.jsx now, need to double check and confirm which file to use
import { Fabric } from './Fabric';
import { UrlService } from 'fi-url';
import { getIsSuperAdmin, noPermitOn } from 'fistore/session/profile/selectors';
import {
  getIsFazSupervisor,
  getIsRestrictedAdmin,
} from 'fistore/session/sysConfig/selectors';
import { getRootApp } from 'fistore/routing/selectors';
import { fiStore, fiStoreTheme } from 'fistore';
import { copilotActions } from 'fistore/copilot';

const { HeaderAction, HeaderActionDropdown } = ProLayout;
const { getIsDarkTheme } = fiStoreTheme;

export default ({
  startGDPRPicker,
  openViewerModal,
  externalActions: {
    getProduct,

    canChangeProfile,
    changeProfile,

    canUpgradeFirmware,
    upgradeFirmware,

    canChangePassword,
    openChangePwd,

    logout,

    toggleCLIConsole,
    openProcessMonitor,

    getLogdbBuilding,
    // getLogSustainRate,
    isDataMasked,
    isSso,
  } = {},
}) => {
  const _isDataMasked = isDataMasked();
  const _isSso = isSso();
  const isRestrictedAdmin = useSelector(getIsRestrictedAdmin);
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const isFazSupv = useSelector(getIsFazSupervisor);
  // readonly & read/write permssion are okay to show cli console from mantis: 1024426
  const noSysWritePerm = useSelector(noPermitOn('system_setting'));
  const js_console_permission = !isRestrictedAdmin && !noSysWritePerm;
  const isDarkTheme = useSelector(getIsDarkTheme);

  const [quickAccessList, setQuickAccessList] = useState([]);
  const hasQuickAccess = isSuperAdmin && quickAccessList.length > 0;

  useEffect(() => {
    if (_isSso) {
      fetch(UrlService.UTIL.GET_QUICK_ACCESS)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.length > 0) {
            setQuickAccessList(data);
          }
        });
    }
  }, []);

  const onQuickAccessSelect = useCallback(
    (item) => {
      const quickAccessItem = quickAccessList.find((i) => i.name === item.key);
      if (quickAccessItem.url) {
        window.open(quickAccessItem.url, '_blank');
      }
    },
    [quickAccessList]
  );

  const { getHelpItems, onSelectHelpItems } = useHelpItems();
  const { getUserItems, onSelectUserItems, getAvatarLabel, username } =
    useUserItems({
      getProduct,
      canUpgradeFirmware,
      canChangePassword,
      canChangeProfile,
      changeProfile,
      upgradeFirmware,
      openChangePwd,
      openProcessMonitor,
      logout,
    });

  const onClickCLI = useCallback(() => {
    toggleCLIConsole();
  }, [toggleCLIConsole]);

  const onGdprSelect = useCallback((item) => {
    const fn = {
      screenPicker: startGDPRPicker,
      manualInput: openViewerModal,
    }[item.key];
    if (fn) {
      fn();
    }
  }, []);

  const openFortiAI = useCallback(() => {
    fiStore.dispatch(copilotActions.setIsOpen(true));
  }, []);

  const [logdb] = useLogdbRebuidingProgess(getLogdbBuilding);
  const rootApp = useSelector(getRootApp);
  // rootApp sometimes will be undefined because loadSubTree refresh is not done yet.
  const hasGdprTool = rootApp?.gdprTool;
  return (
    <div className='tw-flex tw-items-center'>
      <ExtensionDebugger />
      <Fabric />
      <SystemMode />
      {hasQuickAccess ? (
        // TODO: add fabric items once 793590 is fixed
        <HeaderActionDropdown
          label={gettext('Quick Access')}
          onSelect={onQuickAccessSelect}
          items={quickAccessList.map((item) => ({
            key: item.name,
            iconLibrary: 'fafm',
            icon: 'launch-portal',
            label: item.name,
          }))}
        />
      ) : null}
      <AdomSelect />
      <TaskDropdown />
      {hasGdprTool && _isDataMasked ? (
        <HeaderActionDropdown
          iconLibrary='fafm'
          icon='view-detail'
          onSelect={onGdprSelect}
          automationId='view-details-dropdown'
          items={[
            {
              key: 'screenPicker',
              iconLibrary: 'fafm',
              icon: 'cursor',
              label: gettext('Screen Picker'),
            },
            {
              key: 'manualInput',
              iconLibrary: 'fafm',
              icon: 'edit',
              label: gettext('Manual Input'),
            },
          ]}
        />
      ) : null}
      {js_console_permission && (
        <HeaderAction
          iconLibrary='fa-solid'
          icon='terminal'
          onClick={onClickCLI}
          automationId='terminal'
        />
      )}
      <HeaderActionDropdown
        iconLibrary='fa-solid'
        icon='question-circle'
        onSelect={onSelectHelpItems}
        items={getHelpItems()}
        automationId='online-help-dropdown'
      />
      <NotifDropdown />
      {isFazSupv ? (
        ''
      ) : (
        <HeaderAction onClick={openFortiAI}>
          <NwIcon
            library='fafm-48px'
            name='fortiai-bot'
            style={{
              fontSize: '32px',
              color: isDarkTheme
                ? 'rgb(var(--nw-color-cyan-700))'
                : 'rgb(var(--nw-color-cyan-300))',
            }}
          />
        </HeaderAction>
      )}
      <HeaderActionDropdown
        label={getAvatarLabel(true)}
        onSelect={onSelectUserItems}
        items={getUserItems()}
        automationId='profile-dropdown'
        title={username}
      />
      {logdb?.rebuilding && logdb?.curPhaseText && (
        <LogdbRebuildingProgress logdb={logdb} getDetails={getLogdbBuilding} />
      )}
    </div>
  );
};
