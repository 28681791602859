import { useField } from 'formik';
import { NwCheckbox } from '@fafm/neowise-core';

export const FmkXorCheckbox = ({
  name,
  choices,
  'automation-id': autoId,
  numColumns,
  required,
}) => {
  const validate =
    required && ((val) => !val && gettext('Please select at least one value.'));

  const [, { value: curVal }, { setValue, setTouched }] = useField({
    name,
    validate,
  });

  const onChange = (evt) => {
    evt.preventDefault();
    const newVal = curVal ^ parseInt(evt.target.value);
    setTouched(true, false);
    setValue(newVal);
  };

  return (
    <div
      style={
        numColumns
          ? {
              display: 'grid',
              gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
            }
          : undefined
      }
    >
      {choices.map(({ id, text }) => {
        return (
          <NwCheckbox
            key={id}
            value={id}
            checked={!!(curVal & id)}
            automation-id={autoId + ':' + text}
            onChange={onChange}
          >
            {text}
          </NwCheckbox>
        );
      })}
    </div>
  );
};
