export enum CaptureStatus {
  NotRegistered = 'notRegistered',
  Registered = 'registered',
  Capturing = 'capturing',
}

export enum Context {
  Fafm = 'fafm',
  Content = 'content',
  Worker = 'worker',
  Popup = 'popup',
  Offscreen = 'offscreen',
}

export enum Severity {
  Info = 'info',
  Warning = 'warn',
  Error = 'error',
  Critical = 'critical',
}

export enum ResourceType {
  Bytes = 'bytes',
  Percentage = 'percentage',
}

export type UnknownRecord = Record<keyof any, unknown>;

export type Ack<T = UnknownRecord> = { ack: boolean } & T;

export type Timestamped<T = UnknownRecord> = {
  timestamp: number;
} & T;

export type DebugLog = Timestamped<{
  from: Context;
  message: string;
  severity: Severity;
}>;

export type ResourceUsage = Timestamped<{
  value: number[];
}>;

export enum FafmType {
  RequestExtensionID = 'fafm/debugger/RequestExtensionID',
  ResponseExtensionID = 'fafm/debugger/ResponseExtensionID',
  Register = 'fafm/debugger/Register',
  InitCapture = 'fafm/debugger/InitCapture',
  StartCapture = 'fafm/debugger/StartCapture',
  OpenFile = 'fafm/debugger/OpenFile',
  WriteFile = 'fafm/debugger/WriteFile',
  CloseFile = 'fafm/debugger/CloseFile',
  StopCapture = 'fafm/debugger/StopCapture',
  Log = 'fafm/debugger/Log',
}

export type FafmRequestExtensionID = {
  messageID: FafmType.RequestExtensionID;
  messageContent: unknown;
};

export type FafmResponseExtensionID = {
  messageID: FafmType.ResponseExtensionID;
  messageContent: {
    id: string;
  };
};

export interface Platform {
  hostname?: string;
  modelName: string;
  major: number;
  minor: number;
  patch: number;
  build: number;
  version: string;
}

export type RegisterMessageContent = {
  platform: Platform;
  debugSupportedDaemons: string[];
  supportPageReload?: boolean;
};

export type FafmRegister = {
  messageID: FafmType.Register;
  messageContent: RegisterMessageContent;
};

export type InitCaptureMessageContent = {
  fileName: string;
  debugDaemons: string[];
  pageReload: boolean;
};

export type FafmInitCapture = {
  messageID: FafmType.InitCapture;
  messageContent: InitCaptureMessageContent;
};

interface Configuration {
  name: string;
  data: UnknownRecord;
}

export interface DeviceInfo {
  platform: Platform;
  configurations: Configuration[];
}

export type FafmStartCapture = {
  messageID: FafmType.StartCapture;
  messageContent: {
    ack: boolean;
    fileName?: string;
    // @deprecated
    deviceInfo?: DeviceInfo;
    _deprecated70Capture?: boolean;
  };
};

/**
 * if no metadata:
 * result file content = []
 *
 * if have metadata:
 * result file content = {
 *  metadata: {},
 *  data: []
 * }
 *
 * if have metadata & spreadMetadata:
 * result file content = {
 *  ...metadata,
 *  data: []
 * }
 */
export type FafmOpenFile = {
  messageID: FafmType.OpenFile;
  messageContent: {
    fileName: string;
    /**
     * data property path array like ['timestamp']
     */
    sortBy?: string[] | string;
    /**
     * data property path array like ['id']
     */
    groupBy?: string[] | string;
    /**
     * spread the metadata object?
     */
    spreadMetadata?: boolean;
    /**
     * metadata for the capture file
     */
    metadata?: unknown;
    /**
     * Merge received data array to one object using https://lodash.com/docs/4.17.15#mergeWith
     * and the example array customizer
     */
    mergeData?: boolean;
    /**
     * After receiving the stop capture message.
     * the debugger will do a `await Promise.race([timeout ms, file close message])`
     * before zip and download the captured files.
     *
     * defaults to 5000
     */
    completeTimeoutMs?: number;
  };
};

export type FileMessageContent<T = unknown> = {
  /**
   * unique key
   */
  fileName: string;
  /**
   * append one entry
   */
  data?: T;
  /**
   * push multiple entries
   */
  batch?: T[];
};

export type FafmWriteFile<T = unknown> = {
  messageID: FafmType.WriteFile;
  messageContent: FileMessageContent<T>;
};

export type FafmCloseFile<T = unknown> = {
  messageID: FafmType.CloseFile;
  messageContent: FileMessageContent<T>;
};

export type FafmStopCapture = {
  messageID: FafmType.StopCapture;
  messageContent: unknown;
};

export type FafmLog = {
  messageID: FafmType.Log;
  messageContent: DebugLog;
};

export type FafmMessage =
  | FafmRequestExtensionID
  | FafmResponseExtensionID
  | FafmRegister
  | FafmInitCapture
  | FafmStartCapture
  | FafmOpenFile
  | FafmWriteFile
  | FafmCloseFile
  | FafmStopCapture
  | FafmLog;
