import { ProLayout } from '@fafm/neowise-pro';
import { NwBadge } from '@fafm/neowise/react';

export const NavDropdown = ({
  icon,
  title,
  items,
  totalNum,
  automationId,
  onSelect,
  onShow,
  onHide,
}) => {
  return (
    <div>
      <ProLayout.HeaderActionDropdown
        icon={icon}
        title={title}
        label={
          <NwBadge pill type='danger'>
            {totalNum}
          </NwBadge>
        }
        items={items}
        placement='bottom-end'
        automationId={automationId}
        onSelect={onSelect}
        onShow={onShow}
        onHide={onHide}
      ></ProLayout.HeaderActionDropdown>
    </div>
  );
};
