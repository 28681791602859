import { NwIconButton } from '@fafm/neowise-core';
import { copyToClipboard } from 'fiutil/copy';
import { FC } from 'react';
import { isFunction } from 'lodash';
import { showSuccessMessage } from 'fi_widgets/fi-messagebox';

type CopyBtnProps = {
  copyText?: string;
  getCopyText?: () => Promise<string>;
  text?: string;
  successMessage?: string;
  label: string;
};

export const CopyBtn: FC<CopyBtnProps> = (props) => {
  const {
    copyText = '',
    label = gettext('Copy'),
    getCopyText,
    text,
    successMessage,
  } = props;

  return (
    <NwIconButton
      name={'copy'}
      label={label}
      onClick={async () => {
        const _textToCopy = isFunction(getCopyText)
          ? await getCopyText()
          : copyText;
        copyToClipboard(_textToCopy);
        if (successMessage) {
          showSuccessMessage(successMessage);
        }
      }}
    >
      {text}
    </NwIconButton>
  );
};

export const CopySnBtn: FC<CopyBtnProps> = (props) => {
  const snProps = {
    label: gettext('Copy Serial Number'),
    successMessage: gettext('Copied Serial Number'),
  };

  return <CopyBtn {...snProps} {...props} />;
};
