import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import { getSessionAdomOid } from 'fistore/session/adom/selectors';
import { callPromiseAction } from '../../utils';
import { runIfAvailable } from '../../routing/saga';
import { isFmg } from '../../session/sysConfig/selectors';

export function* watchDevicesAssignedPkg() {
  yield takeLatest(
    [actions.fetchDevicesAssignedPkgAction.type],
    runIfAvailable(isFmg, fetchAssignedPkg)
  );
}

function* fetchAssignedPkg(action) {
  yield callPromiseAction(action, function* () {
    const adomOid =
      action.payload?.adomOid || (yield select(getSessionAdomOid));

    yield put(actions.devicesAssignedPkgAction.fetch.start({ path: adomOid }));

    try {
      const { pkgData, resps } = yield call(
        api.fetch_assignpkg_and_preruncli,
        adomOid
      );

      yield put(
        actions.devicesAssignedPkgAction.fetch.success({
          path: adomOid,
          data: pkgData,
        })
      );

      return yield resps;
    } catch (e) {
      yield put(actions.devicesAssignedPkgAction.fetch.failure(e));
      return yield [];
    }
  });
}
