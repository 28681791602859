import { isNil } from 'lodash';
import { useApiRequest } from './useApiRequest';

/**
 * For dynamic importing modules.
 * @returns
 */
export function useImportModuleAsync(options) {
  return useApiRequest({
    defaultValue: null,
    ...options,
  });
}

/**
 * Component for rendering dynamically imported module
 */
export const LazyComponent = ({ load, ...rest }) => {
  const {
    state: { EditComponent },
  } = useImportModuleAsync({
    defaultValue: { EditComponent: null },
    loader: async () => {
      let Component = await load();
      return { EditComponent: Component };
    },
    dependencies: [],
  });

  return !isNil(EditComponent) ? <EditComponent {...rest} /> : null;
};
