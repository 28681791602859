import { ProForm, ProToolkit } from '@fafm/neowise-pro';
import { identity, isFunction } from 'lodash';

import { OkBtn, CancelBtn } from './form';

const { Header, Body, Footer } = ProForm;

export {
  ConfirmModal,
  SimpleConfirmModal,
  openConfirmModal,
  makeConfirmModalRenderFn,
};

const DEFAULT_CONFIRM_MODAL_CONFIG = {
  className: 'np-toolkit-modal fi-pro-confirm-modal',
  size: 'md',
  height: 'fit-content',
  minimizable: false,
};

const ConfirmModal = ({
  $opener,
  renderer,
  title,
  content,
  okLabel = gettext('OK'),
  cancelLabel = gettext('Cancel'),
  buttons = ['ok', 'cancel'],
}) => {
  if (isFunction(renderer)) {
    return renderer($opener);
  }

  return (
    <SimpleConfirmModal
      title={title}
      content={content}
      $opener={$opener}
      okLabel={okLabel}
      cancelLabel={cancelLabel}
      buttons={buttons}
    />
  );
};
ConfirmModal.displayName = 'ConfirmModal';

const SimpleConfirmModal = ({
  $opener,
  title,
  content,
  getAutoId = identity,
  okLabel,
  cancelLabel,
  buttons,
}) => {
  const onSubmit = (event) => {
    event.preventDefault();
    $opener.resolve();
  };

  const onCancel = (event) => {
    event.preventDefault();
    $opener.reject();
  };

  return (
    <>
      <Header>{title}</Header>
      <Body>{isFunction(content) ? content() : content}</Body>
      <Footer>
        {buttons.includes('ok') ? (
          <OkBtn
            onClick={onSubmit}
            automation-id={getAutoId('confirm-modal-ok-btn')}
          >
            {okLabel}
          </OkBtn>
        ) : null}
        {buttons.includes('cancel') ? (
          <CancelBtn
            onClick={onCancel}
            automation-id={getAutoId('confirm-modal-cancel-btn')}
          >
            {cancelLabel}
          </CancelBtn>
        ) : null}
        {buttons.length
          ? buttons.map((btnConfig) => {
              if (btnConfig === 'ok' || btnConfig === 'cancel') return;
              const { label, type, click, automationId, title } = btnConfig;
              const BtnComp = type === 'primary' ? OkBtn : CancelBtn;
              return (
                <BtnComp
                  key={label}
                  title={title}
                  onClick={() => click($opener)}
                  automation-id={getAutoId(`confirm-modal:${automationId}`)}
                >
                  {label}
                </BtnComp>
              );
            })
          : null}
      </Footer>
    </>
  );
};
SimpleConfirmModal.displayName = 'SimpleConfirmModal';

const openConfirmModal = async (
  modalProps,
  modalOptions = DEFAULT_CONFIRM_MODAL_CONFIG
) => {
  return ProToolkit.openModal(<ConfirmModal {...modalProps} />, modalOptions)
    .result;
};
openConfirmModal.defaultOptions = DEFAULT_CONFIRM_MODAL_CONFIG;

const alertContent = (type, icon = type) => {
  return ({ content, ...rest }) => {
    return {
      content: (
        <nw-alert type={type} open>
          <nw-icon slot='icon' name={icon}></nw-icon>
          {content}
        </nw-alert>
      ),
      ...rest,
    };
  };
};

openConfirmModal.warning = (props, ...others) =>
  openConfirmModal(alertContent('warning')(props), ...others);

const makeConfirmModalRenderFn = ({
  title,
  content = <></>,
  okLabel = gettext('OK'),
  cancelLabel = gettext('Cancel'),
  getAutoId = identity,
  onOkClick = ($opener) => $opener.resolve(),
  onCancelClick = ($opener) => $opener.reject(),
}) => {
  return ($opener) => {
    return (
      <>
        <Header>{title}</Header>
        <Body>{isFunction(content) ? content() : content}</Body>
        <Footer>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              onOkClick($opener, e);
            }}
            automation-id={getAutoId('confirm-modal-ok-btn')}
          >
            {okLabel}
          </OkBtn>
          <CancelBtn
            onClick={(e) => {
              e.preventDefault();
              onCancelClick($opener, e);
            }}
            automation-id={getAutoId('confirm-modal-cancel-btn')}
          >
            {cancelLabel}
          </CancelBtn>
        </Footer>
      </>
    );
  };
};
