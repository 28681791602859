import { NwIcon } from '@fafm/neowise-core';
import cn from 'classnames';

export const SecurityStatusInfo = ({ status }) => {
  const MSG_WARNING =
    status.level == MACROS.SYS.IMG_SIG_LEVEL_2
      ? gettext('Secure Boot Message: This is Non-GA Certified Firmware!')
      : gettext('Warning: Image is not Certified');

  return (
    <>
      <div
        id='demo-status'
        className={cn(
          'tw-flex',
          'tw-items-center',
          'tw-h-8',
          'tw-w-full',
          'tw-justify-center',
          'tw-overflow-x-clip',
          'tw-transition-width',
          'tw-whitespace-nowrap'
        )}
        style={{
          color: 'rgb(var(--nw-color-neutral-0))',
          backgroundColor: 'rgb(var(--nw-color-danger-500))',
        }}
      >
        <NwIcon name='warning' library='fafm' className='tw-mr-1'></NwIcon>
        <div className={cn('tw-px-4')}>{MSG_WARNING}</div>
      </div>
    </>
  );
};
