import { createSelector } from '@reduxjs/toolkit';
import { propOr } from '../utils/prop-or';
import { initialState as init } from './slice';
import { hasFmgFeature } from 'fistore/session/sysConfig/selectors';
import { getIsGlobalAdom } from 'fistore/session/adom/selectors';
import {
  getCurrentState,
  getAppPathMap,
  getAllApps,
} from 'fistore/routing/selectors';
import { printfd } from 'kit/kit-string/printfd';

export const getMainFrame = propOr('mainFrame');

const _select = (key, dft) => createSelector(getMainFrame, propOr(key, dft));

export const getSidebarWidth = _select('sidebarWidth', init.sidebarWidth);
export const getIsSidebarOpened = _select(
  'isSidebarOpened',
  init.isSidebarOpened
);

export const getMessageBox = _select('messageBox');

export const getOlhConfig = createSelector(
  getMainFrame,
  (main) => main?.olhConfig
);

export const getOlhTag = createSelector(
  getCurrentState,
  getAppPathMap,
  getAllApps,
  hasFmgFeature,
  getIsGlobalAdom,
  (currState, pathMap, allApps, hasFmg, isGlbAdom) => {
    const currKey = currState?.handle?.appUniKey;
    if (!currKey || !pathMap) return '';
    // need to add own appKey to the search list
    const appPath = [currKey].concat(pathMap[currKey]);
    const taggedApp = allApps[appPath.find((key) => allApps[key]?.olhTag)];
    const { olhTag, olhGlbAdom, olhFmg, olhFaz } = taggedApp || {};
    if (olhGlbAdom && isGlbAdom) {
      return 'glb_' + olhTag;
    }
    if (olhFmg && olhFaz) {
      return (hasFmg ? 'fmg_' : 'faz_') + olhTag;
    }
    return olhTag;
  }
);

export const getHelpUrl = createSelector(
  getOlhTag,
  getOlhConfig,
  (tag, olhConfig) => {
    if (!olhConfig) return '';
    const { help, topic } = olhConfig || {};
    return tag ? printfd(topic, { help, tag }) : help || '';
  }
);
