import { get_ws_proxy } from './socket/ws_proxy';
import { isString } from 'lodash';
import $ from 'jquery';

const DEBUG_FLAG = false;

// eslint-disable-next-line
const logTimestamp = function (label, obj) {
  //keep these codes for debug websocket request/response.
  // console.log('websocket [' + new Date().toUTCString() + '] ' +label,obj);
};

const requestMng = {
  send: function (request) {
    let defer = new $.Deferred();

    let chunkedResult = false;
    const is_chunked = (data) => {
      return data.chunked !== 'undefined' && data.chunked;
    };

    const obsever = get_ws_proxy().sendRequest(request);

    obsever.subscribe({
      next: (data) => {
        let result = data.result;
        if (is_chunked(data)) {
          chunkedResult = true;
          defer.notify({ id: data.id, chunk: result.chunk || result });
        } else {
          defer.resolve(result);
        }
      },
      complete: () => {
        if (chunkedResult) defer.resolve();
      },
      error: (error) => {
        defer.reject(error);
      },
    });

    return defer.promise();
  },
  cancelRequest: function (request) {
    let requestId = null;
    if (typeof request === 'string' || typeof request === 'number') {
      requestId = request;
    } else if (typeof request === 'object') {
      if (request.id) requestId = request.id;
      else {
        DEBUG('has not include callbackId');
        return;
      }
    } else {
      DEBUG('has not include callbackId');
      return;
    }
    get_ws_proxy().cancelRequest(requestId);
  },
};

// function definitions
function init() {
  connect();
}

function genWSRequest(request, target, method) {
  var url = target || '/cgi-bin/module/flatui_proxy';
  var req = isString(request) ? request : JSON.stringify(request);

  var cmd = {
    method: 'proxy',
    params: {
      url: url,
      params: req,
      method: method || 'POST',
    },
  };

  return cmd;
}

function connect() {
  get_ws_proxy();
}

function sendQuest(request) {
  return requestMng.send(request);
}
function cancelRequest(request) {
  return requestMng.cancelRequest(request);
}
function addListener(eventName, fn) {
  return get_ws_proxy().addListener(eventName, fn);
}
function removeListener(eventName, fn) {
  get_ws_proxy().removeListener(eventName, fn);
}
function disconnect() {
  get_ws_proxy().disconnect();
}

function DEBUG(data) {
  if (!DEBUG_FLAG) {
    return;
  }
  // eslint-disable-next-line
  console.log(data);
}

export const fiWebSocket = {
  init: init,
  connect: connect,
  disconnect: disconnect,

  //request/response
  send: sendQuest,
  cancelRequest: cancelRequest,

  //publish/subscriber
  addListener: addListener,
  removeListener: removeListener,

  genWSRequest: genWSRequest,
};
