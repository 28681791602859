import { Terminal } from 'xterm';
import './terminal.less';
import { isFunction } from 'fiutil';

export default function createTerminmal(perentEl, addons, options) {
  const xterm = new Terminal({
    letterSpacing: 0,
    cursorBlink: true,
    // #0783588: TAC report has more than 10k lines
    scrollback: 99999,
    fontSize: 14,
    theme: {
      foreground: 'rgb(230, 230, 230)',
    },
    ...options,
  });

  addons.forEach((addon) => {
    xterm.loadAddon(isFunction(addon) ? new addon() : addon);
  });

  xterm.open(perentEl);

  return xterm;
}
