import { get } from 'lodash';

import { getSessionAdomOid } from '../../session/adom/selectors';

const get_dvm_state = (state) => state.dvm;

const nullObject = null;

const wrap_catch_error = (fn, defaultValue = nullObject) => {
  try {
    return fn();
  } catch (err) {
    return defaultValue;
  }
};

export const get_devices = (state, adomOid) => {
  return wrap_catch_error(() => get_dvm_state(state).devices[adomOid]);
};

export const get_device = (state, adomOid, deviceOid) => {
  return wrap_catch_error(() => get_devices(state, adomOid).byId[deviceOid]);
};

export const get_device_vdoms = (state, adomOid, deviceOid) => {
  return wrap_catch_error(
    () => get_dvm_state(state).vdoms[adomOid + '-' + deviceOid]
  );
};

export const get_device_vdoms_byId = (state, adomOid, deviceOid) => {
  return get(get_device_vdoms(state, adomOid, deviceOid), 'byId', {});
};

export const get_device_vdom_by_vid = (state, adomOid, deviceOid, vdomOid) => {
  const byId = get_device_vdoms_byId(state, adomOid, deviceOid);
  return byId[vdomOid];
};

export const get_device_vdom_by_name = (
  state,
  adomOid,
  deviceOid,
  vdomName
) => {
  const byId = get_device_vdoms_byId(state, adomOid, deviceOid);
  const vdoms = Object.values(byId);
  return vdoms.find((vdom) => vdom.name === vdomName);
};

export const get_device_licenses = (state) => {
  return state.deviceLicenses;
};

export const get_assigned_pkgs = (state, adomOid) => {
  return wrap_catch_error(get_dvm_state(state).assignedpkg[adomOid]);
};

export const get_all_device_vdom_list = (state, adomOid) => {
  const devices = get_devices(state, adomOid);
  const list = [];
  try {
    devices.allIds.forEach((id) => {
      const device = devices.byId[id];
      const vdoms = get_device_vdoms(state, adomOid, id);
      list.push(device);
      if (typeof vdoms !== 'undefined') {
        vdoms.allIds.forEach((vid) => {
          list.push(vdoms.byId[vid]);
        });
      }
    });
    // eslint-disable-next-line
  } catch (err) {}

  return list;
};

export const find_paths_included_device = (state, deviceOid) => {
  return wrap_catch_error(() => {
    const findedPaths = [];
    const devices = get_dvm_state(state).devices;
    const paths = Object.keys(devices);

    paths.forEach((path) => {
      const devObjects = devices[path].byId;
      const devOids = Object.keys(devObjects);

      if (devOids.includes(deviceOid.toString())) {
        findedPaths.push(path);
      }
    });

    return findedPaths;
  }, []);
};

export const find_paths_included_vdom = (state, deviceOid, vdomOid) => {
  return wrap_catch_error(() => {
    const deviceFindedPaths = find_paths_included_device(state, deviceOid);
    for (let i = 0; i < deviceFindedPaths.length; i++) {
      const path = deviceFindedPaths[i];
      const vdoms = get_device_vdoms(state, path, deviceOid).byId;

      if (Object.keys(vdoms).includes(vdomOid.toString())) return [path];
    }
    return [];
  }, []);
};

export const get_all_vdoms = (state) => {
  return wrap_catch_error(() => get_dvm_state(state).vdoms);
};

const LTE_PLATFORM = '3G4G';
export const hasLTEDevices = (state) => {
  const adomOid = getSessionAdomOid(state);
  const devices = get_devices(state, adomOid);
  const hasLTE = Object.values(devices?.byId || {}).some((dev) => {
    return !dev.model_dev && dev.platform.includes(LTE_PLATFORM);
  });

  return hasLTE;
};
