// import { fiSysConfig } from 'fi-session'; // would cause circular import.  // don't use
//
// function isFazSupervisor() {
//   return fiSysConfig.isFazSupervisor();
// }

function getParams(oldParams = {}, isFazSupervisor = false) {
  if (isFazSupervisor) {
    return { ...oldParams, supervisor: true };
  }
  return oldParams;
}
function updateParams(params, isFazSupervisor = false) {
  if (params && isFazSupervisor) {
    params.supervisor = true;
  }
  return params;
}

export const fazSupervisorUtil = {
  getParams,
  updateParams,
};
