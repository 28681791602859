import { fiStore, observeStore, fiSession } from 'fistore';
import { useEffect, useState } from 'react';
import * as selectors from './selectors';
import { cloneDeep } from 'lodash';

const ADMINPRIV_DISABLED = 0;
const ADMINPRIV_ENABLED = 1;

const getSysCfg = () => {
  return fiSession.getSysConfig(fiStore.getState()) ?? {};
};

const getAdom = () => {
  return fiSession.getSessionAdom(fiStore.getState())?.data ?? {};
};

export function current() {
  let prof = fiSession.getAdminProfile(fiStore.getState())?.data ?? {};

  return override(prof);
}

export function profileID() {
  const prof = current();

  return prof.profileid;
}

export function isSuperAdmin() {
  const prof = current();

  return !!prof.super_user_profile;
}

export function isAdomScopedAdmin() {
  const prof = current();

  return !!prof.adom_admin;
}

export function isRestrictedAdmin() {
  const prof = current();

  return prof.type === MACROS.USER.SYS.ADMIN_PROFILE_TYPE_RESTRICTED;
}

export function isRegularAdmin() {
  const prof = current();

  return prof.type === MACROS.USER.SYS.ADMIN_PROFILE_TYPE_REGULAR;
}

export function isMaskedAdmin() {
  const prof = current();
  return !!prof.datamask?.status;
}

const RESTRICTED_ADMIN_TYPE_ATTR_MAP = {
  ips: 'ips_filter',
  app: 'app_filter',
  web: 'web_filter',
};
export function attr(prop) {
  return current()?.[prop];
}

export function attrs(arr = []) {
  const cfg = current();
  return arr.reduce((acc, cur) => {
    acc[cur] = cfg[cur];
    return acc;
  }, {});
}

export function hasRestrictedAdminPermitOn(permit) {
  let prop = RESTRICTED_ADMIN_TYPE_ATTR_MAP[permit];
  return attr(prop) === 1 ? true : false;
}

export function isDisabledOn(attr) {
  const prof = current();
  return prof[attr] == ADMINPRIV_DISABLED;
}

export function isEnabledOn(attr) {
  const prof = current();
  return prof[attr] == ADMINPRIV_ENABLED;
}

export function getPermitOf(attr) {
  if (!attr) return MACROS.SYS.ADMINPRIV_PERM_NONE;

  const prof = current();

  return prof[attr] ?? MACROS.SYS.ADMINPRIV_PERM_NONE;
}

/**
 *@description Use function hasRDWRPermitOnSysSettingConfig instead for checking RDWR
 * permission for system_setting (trunk) or system_configuration (cloud)
 * to avoid conflicts
 */
export function hasRDWRPermitOn(
  attr,
  { haCheck = true, fmgFabricCheck = true } = {}
) {
  if (!attr) return false;

  /** ----------------------------------------------------------------------------
   * haCheck:
   * set to false when HA Slave can save its own configs, for example, FortiGuard -> Advanced Settings
   *
   * fmgFabricCheck:
   * set to false when FMG Fabric Member can save its own configs
   * mantis #1051492: only allow some actions for fmg fabric member, e.g.
   * dashboard > system info widget > edit hostname / system time
   * system settings > network
   * -------------------------------------------------------------------------- */
  if (
    (haCheck && selectors.isHaSlave(getSysCfg())) ||
    (fmgFabricCheck && fiSession.getIsFmgMember(fiStore.getState()))
  ) {
    return false;
  }

  const prof = current();

  return prof[attr] == MACROS.SYS.ADMINPRIV_PERM_RDWR;
}

export function hasRDWRPermitOnSysSettingConfig(checks) {
  return hasRDWRPermitOn('system_setting', checks);
}

export function hasRDPermitOn(attr) {
  if (!attr) return false;

  const prof = current();

  return (
    prof[attr] == MACROS.SYS.ADMINPRIV_PERM_READ ||
    prof[attr] == MACROS.SYS.ADMINPRIV_PERM_RDWR
  );
}

export function readOnlyOn(attr) {
  if (!attr) return true;

  const prof = current();

  return (
    prof[attr] == MACROS.SYS.ADMINPRIV_PERM_READ ||
    selectors.isHaSlave(getSysCfg())
  );
}

export function noPermitOn(attr) {
  return selectors.noPermitOn(attr)(current());
}

// ============= React Hooks ==========================
export function useCurrent() {
  const [adminProfile, setAdminProfile] = useState(current);

  useEffect(() => {
    return observeStore(fiSession.getAdminProfile, (nprof) =>
      setAdminProfile(nprof?.data ?? {})
    );
  }, []);

  return adminProfile;
}

// ============ internal function =============

function override(prof) {
  if (
    !selectors.hasFmgFeature(getSysCfg()) &&
    selectors.isFazAdomManagedByFmg(getAdom())
  ) {
    const clonedProf = cloneDeep(prof);
    ['device_profile', 'dev_cfg', 'dev_manager', 'device_op', 'wllb'].forEach(
      (attr) => {
        if (prof[attr] === MACROS.SYS.ADMINPRIV_PERM_RDWR) {
          clonedProf[attr] = MACROS.SYS.ADMINPRIV_PERM_READ;
        }
      }
    );
    return clonedProf;
  }

  return prof;
}
