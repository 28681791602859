import React, {
  Dispatch,
  Key,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { VariableSizeList as List } from 'react-window';
import AutoSizer from '@fafm/react-virtualized-auto-sizer';
import { isNumber } from 'lodash';
import { NwButton, NwCheckbox, NwIcon, NwInput } from '@fafm/neowise-core';
import './LRSelectReact.less';
import cn from 'classnames';

type LRSelectReactOneListProp<T extends object> = {
  items: T[];
  itemHeight: number | ((item: T) => number);
  itemRenderer: (item: T, searchString: string) => ReactNode;
  selected: T[];
  setSelected: Dispatch<SetStateAction<T[]>>;
  idAttr: keyof T;
  textAttr: keyof T;
  title: string;
};

export const LRSelectReactOneList = <T extends object>({
  items,
  itemHeight,
  itemRenderer,
  selected,
  setSelected,
  idAttr,
  textAttr,
  title,
}: LRSelectReactOneListProp<T>) => {
  const calculateItemHeight = useCallback(
    (index: number) => {
      return isNumber(itemHeight) ? itemHeight : itemHeight(items[index]);
    },
    [items]
  );
  const [searchString, setSearchString] = useState('');

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      const data: string = item[textAttr] as string;
      return data.toLowerCase().includes(searchString.toLowerCase());
    });
  }, [items, searchString]);

  const renderItem = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      const item = filteredItems[index];
      return (
        <div style={style}>
          <div
            style={{
              padding: 16,
            }}
          >
            <NwCheckbox
              checked={selected
                .map((item) => item[idAttr])
                .includes(item[idAttr])}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setSelected((prev) => [...prev, item]);
                } else {
                  setSelected((prev) =>
                    prev.filter((v) => v[idAttr] !== item[idAttr])
                  );
                }
              }}
            >
              {itemRenderer(item, searchString)}
            </NwCheckbox>
          </div>
        </div>
      );
    },
    [filteredItems, idAttr, selected]
  );

  return (
    <div
      className='tw-h-full fi-input-border tw-flex-1 tw-flex tw-flex-col'
      style={{
        minHeight: 400,
      }}
    >
      <span className='fi-lrs-title'>
        {title}({filteredItems.length})
      </span>
      <NwInput
        placeholder={gettext('Search...')}
        value={searchString}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchString(e.target.value);
        }}
      ></NwInput>
      <div className={'tw-flex-1'}>
        <AutoSizer>
          {(size) => (
            <List
              height={size.height}
              itemCount={filteredItems.length}
              itemSize={calculateItemHeight}
              width={size.width}
            >
              {renderItem}
            </List>
          )}
        </AutoSizer>
      </div>
      <div
        className='tw-flex tw-gap-2 tw-p-1'
        style={{
          borderTop: '1px solid rgb(var(--nw-input-border-color))',
        }}
      >
        <NwButton
          type='default'
          size='medium'
          onClick={() => {
            setSelected([...filteredItems]);
          }}
        >
          <NwIcon name={'select-all'} />
        </NwButton>
        <NwButton type='default' size='medium' onClick={() => setSelected([])}>
          <NwIcon name='deselect-all' />
        </NwButton>
      </div>
    </div>
  );
};

type LRSelectReactProp<T extends object> = {
  source: Promise<T[]>;
  idAttr: keyof T;
  textAttr: keyof T;
  itemHeight: number | ((item: T) => number);
  className?: string;
  itemRenderer: (item: T, searchString: string) => ReactNode;
  selected: Key[];
  onChange: (selected: T[]) => void;
};

export const LRSelectReact = <T extends object>({
  source,
  idAttr,
  textAttr,
  itemHeight,
  className,
  itemRenderer,
  selected,
  onChange,
}: LRSelectReactProp<T>) => {
  const [loadedSource, setLoadedSource] = useState<T[]>([]);
  const [leftSelected, setLeftSelected] = useState<T[]>([]);
  const [rightSelected, setRightSelected] = useState<T[]>([]);
  const leftItems = useMemo(() => {
    return loadedSource.filter(
      (item) => !selected.includes(item[idAttr] as Key)
    );
  }, [loadedSource, selected]);
  const rightItems = useMemo(() => {
    return loadedSource.filter((item) =>
      selected.includes(item[idAttr] as Key)
    );
  }, [loadedSource, selected]);

  useEffect(() => {
    source.then((result) => {
      setLoadedSource(result);
    });
  }, [source]);

  return (
    <div className={cn('tw-flex', 'tw-gap-2', className)}>
      <LRSelectReactOneList
        items={leftItems}
        itemHeight={itemHeight}
        itemRenderer={itemRenderer}
        selected={leftSelected}
        setSelected={setLeftSelected}
        idAttr={idAttr}
        textAttr={textAttr}
        title={gettext('Available Entries')}
      />
      <div className='tw-flex tw-flex-col tw-justify-center'>
        <NwButton
          className='tw-w-10'
          disabled={leftSelected.length === 0}
          onClick={() => {
            const newSelected = loadedSource.filter((item) =>
              selected.includes(item[idAttr] as Key)
            );
            newSelected.push(...leftSelected);
            setLeftSelected([]);
            onChange(newSelected);
          }}
        >
          {' '}
          {'>'}{' '}
        </NwButton>
        <NwButton
          className='tw-w-10'
          disabled={rightSelected.length === 0}
          onClick={() => {
            const rightSelectedId = rightSelected.map(
              (item) => item[idAttr] as Key
            );
            const newSelected = loadedSource.filter(
              (item) =>
                selected.includes(item[idAttr] as Key) &&
                !rightSelectedId.includes(item[idAttr] as Key)
            );
            setRightSelected([]);
            onChange(newSelected);
          }}
        >
          {' '}
          {'<'}{' '}
        </NwButton>
      </div>
      <LRSelectReactOneList
        items={rightItems}
        itemHeight={itemHeight}
        itemRenderer={itemRenderer}
        selected={rightSelected}
        setSelected={setRightSelected}
        idAttr={idAttr}
        textAttr={textAttr}
        title={gettext('Selected Entries')}
      />
    </div>
  );
};
