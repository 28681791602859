import { fiFmgHttp } from 'fi-http';

function _reqSet(data) {
  return {
    id: 1,
    method: 'set',
    params: [
      {
        url: '/cli/global/system/docker',
        data: data,
      },
    ],
  };
}

function _reqUpgrade(appName) {
  return {
    id: 1,
    method: 'exec',
    params: [
      {
        url: '/sys/api/docker/upgrade',
        data: [{ docker: appName }],
      },
    ],
  };
}

export const fetchDockerExtStatus = async () => {
  const req = {
    id: 2,
    method: 'get',
    params: [
      {
        url: '/sys/api/docker/status',
      },
    ],
  };

  const resp = await fiFmgHttp.query(req);

  return resp?.[0]?.data;
};

// 0808226: get basic docker status from cli
export async function fetchCliDockerStatus() {
  const req = {
    id: 1,
    method: 'get',
    params: [
      {
        url: '/cli/global/system/docker',
      },
    ],
  };
  const resp = await fiFmgHttp.query(req);

  return resp?.[0]?.data;
}

export const getAppExtensionList = async () => {
  const req = {
    id: 1,
    method: 'get',
    params: [
      {
        url: '/cli/global/system/docker',
      },
    ],
  };
  return fiFmgHttp.query(req).then((resp) => {
    return resp[0].data;
  });
};

export async function enableDockerAndExtension(appName) {
  let req = _reqSet({
    status: MACROS.SYS.DOCKER_CFG_ENABLE,
    [appName]: MACROS.SYS.DOCKER_CFG_ENABLE,
  });
  return fiFmgHttp.query(req);
}

export async function enableExtension(appName, hasFailed) {
  let req = hasFailed
    ? _reqUpgrade(appName)
    : _reqSet({ [appName]: MACROS.SYS.DOCKER_CFG_ENABLE });
  return fiFmgHttp.query(req);
}
