import {
  Observable,
  ObservableInf,
  Observer,
  SharedObservable,
} from './observable';

export default class WSRequest implements ObservableInf {
  private _subscribed: boolean;
  private sharedObservable: SharedObservable;
  constructor(observable: Observable, private _cancel: () => void) {
    this._subscribed = false;
    this.sharedObservable = new SharedObservable(observable);
  }
  subscribe(observer: Observer) {
    this._subscribed = true;
    return this.sharedObservable.subscribe(observer);
  }
  cancel() {
    if (!this._subscribed) return;

    this.sharedObservable.clear();
    this._cancel();
  }
}
