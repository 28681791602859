import { isEmpty, isNil, keyBy, negate, transform } from 'lodash';
import { fiSyntaxUtil } from 'ra_pno_util';

export const peerTypeTextMap = {
  any: gettext('Any Peer ID'),
  dialup: gettext('Dialup'),
  one: gettext('Specify Peer ID'),
  peer: gettext('Peer Certificate'),
  peergrp: gettext('Peer Certificate Group'),
};

// Refer to FOS code: migadmin/ng/vpn/ipsec/edit/models.js > $peertype_opts
export function getPeerTypeChoicesOld({
  // syntax attr
  syntaxAttrs,

  // form values
  authMethodVal, // authmethod
  typeVal, // type
  modeVal, // mode
  remoteAuthVal, // authmethod-remote
  ikeVersionVal, // ike-version

  // optional
  textMap = peerTypeTextMap,
}) {
  // syntax opts
  const remoteGatewaySyntaxOpts = fiSyntaxUtil.getOpts(syntaxAttrs, 'type');
  const modeSyntaxOpts = fiSyntaxUtil.getOpts(syntaxAttrs, 'mode');
  const authMethodSyntaxOpts = fiSyntaxUtil.getOpts(syntaxAttrs, 'authmethod');
  const remoteAuthSyntaxOpts = fiSyntaxUtil.getOpts(
    syntaxAttrs,
    'authmethod-remote'
  );

  const isTypeDynamic = typeVal === remoteGatewaySyntaxOpts.dynamic;
  const isAuthMethodPsk = authMethodVal === authMethodSyntaxOpts.psk;
  const isAuthMethodSignature =
    authMethodVal === authMethodSyntaxOpts.signature;
  const isIKeVersion1 = ikeVersionVal === MACROS.PM2CAT.PM2_IKE_VERSION_1;
  const isIKeVersion2 = ikeVersionVal === MACROS.PM2CAT.PM2_IKE_VERSION_2;

  const isRemoteAuthPsk = remoteAuthVal === remoteAuthSyntaxOpts.psk;
  const isRemoteAuthSignature =
    remoteAuthVal === remoteAuthSyntaxOpts.signature;

  const notRemoteAuth = isNil(remoteAuthVal) || isEmpty(remoteAuthVal);
  const aggressive = modeVal === modeSyntaxOpts.aggressive && !isIKeVersion2;
  const is_not_dialup = !isTypeDynamic;
  const psk_auth = isAuthMethodPsk && notRemoteAuth;
  const signature_auth = isAuthMethodSignature && notRemoteAuth;

  // The following boolean expressions should match the backend restrictions used
  // in ipsec_peertype_state() in vpn_common.c
  const skip_peertype_one =
    is_not_dialup && isAuthMethodPsk && (!aggressive || isIKeVersion2);

  const skip_peertype_dialup =
    is_not_dialup ||
    signature_auth ||
    isRemoteAuthSignature ||
    (!aggressive && isIKeVersion1);

  const skip_peertype_peer = psk_auth || isRemoteAuthPsk;

  function isVisible(peertype) {
    switch (peertype) {
      case 'any':
        return true;
      case 'one':
        return !skip_peertype_one;
      case 'dialup':
        return !skip_peertype_dialup;
      case 'peer':
      case 'peergrp':
        return !skip_peertype_peer;
    }
  }

  // generate peer option choices based on filtering logic from FOS code
  const peerOptionsChoices = fiSyntaxUtil.createSyntaxOpts(
    syntaxAttrs,
    'peertype',
    textMap
  );
  const peerOptionsChoicesMap = keyBy(peerOptionsChoices, 'key');

  const choices = transform(
    peerOptionsChoicesMap,
    (result, val, key) => {
      if (isVisible(key)) {
        result.push(val);
      }
    },
    []
  );

  return choices
    .sort((a, b) => (a && b ? a.text.localeCompare(b.text) : 0))
    .filter(negate(isNil));
}

// Refer to FOS code: migadmin/pkg/angular/src/app/vpn/ipsec/ipsec-dialog/ipsec-authentication-section/ipsec-authentication-section.component.ts > togglePeerOptionsObservable
export function getPeerTypeChoices({
  // syntax attr
  syntaxAttrs,

  // form values
  authMethodVal, // authmethod
  typeVal, // type
  modeVal, // mode
  ikeVersionVal, // ike-version
}) {
  // syntax opts
  const typeSyntaxOpts = fiSyntaxUtil.getOpts(syntaxAttrs, 'type');
  const modeSyntaxOpts = fiSyntaxUtil.getOpts(syntaxAttrs, 'mode');
  const authMethodSyntaxOpts = fiSyntaxUtil.getOpts(syntaxAttrs, 'authmethod');

  const isTypeDynamic = typeVal === typeSyntaxOpts.dynamic; // dialup
  const isAuthMethodSignature =
    authMethodVal === authMethodSyntaxOpts.signature;
  const isIKeVersion2 = ikeVersionVal === MACROS.PM2CAT.PM2_IKE_VERSION_2;

  const aggressive = modeVal === modeSyntaxOpts.aggressive && !isIKeVersion2;

  // generate peer option choices based on filtering logic from FOS code
  const peerOptionsChoices = fiSyntaxUtil.createSyntaxOpts(
    syntaxAttrs,
    'peertype',
    peerTypeTextMap
  );

  const peerOptionsChoicesMap = keyBy(peerOptionsChoices, 'key');

  const noPeerOptions = [peerOptionsChoicesMap.any];
  const defaultPeerOptions = [
    peerOptionsChoicesMap.any,
    peerOptionsChoicesMap.one,
  ];
  const additionalAuthPeerOptions = [
    peerOptionsChoicesMap.peer,
    peerOptionsChoicesMap.peergrp,
  ];
  const additionalDialupPeerOptions = [peerOptionsChoicesMap.dialup];

  let showPeerOptions = false;

  let peerOptions = [...defaultPeerOptions];
  if (isAuthMethodSignature) {
    showPeerOptions = true;
    peerOptions.push(...additionalAuthPeerOptions);
  } else if (isTypeDynamic) {
    showPeerOptions = true;
    if (aggressive || isIKeVersion2) {
      peerOptions.push(...additionalDialupPeerOptions);
    }
  } else if (aggressive) {
    showPeerOptions = true;
  } else {
    peerOptions = [...noPeerOptions];
  }

  if (!showPeerOptions) {
    return [];
  }

  // mantis #1058479: some peertype opt doesnt exist in some adoms
  // e.g. in FPX adom, only one peertype exists: any
  return peerOptions.filter(negate(isNil));
}
