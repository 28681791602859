//import "./regen";
import {
  select,
  put,
  //call,
  takeEvery,
} from 'redux-saga/effects';

import {
  workspaceAdomsAction,
  workspaceDevicesAction,
  workspacePkgsAction,
  workspacePkgChildrenStatusAction,
  workspacePoliciesAction,
  workspaceObjectsAction,
  SET_WORKSPACE_ADOM,
} from './action';
import { workspace_adom_fetch_list } from './api';
import { SocketActions } from 'fi-websocket';
import { session_get_adom } from '../utils/session';

import {
  has_loaded,
  filter_workspace_adom_policies_categories,
  get_workspace_adoms,
  get_workspace_adom,
  get_workspace_adom_policies,
} from './selector';

import { load_workspace_policies, load_workspace_pkg } from './request';
import { store_get_dispatch } from '../utils';
import { compareObjects } from '../utils/util';
import { debounceBatch } from '../batch';
const { NOTIFY_CHANGED_ACTION, NOTIFY_REMOVED_ACTION } = SocketActions;

const WORKSPACE_ADOM = MACROS.SYS.WORKSPACE_ADOM;
const WORKSPACE_DEVICE = MACROS.SYS.WORKSPACE_DEVICE;
const WORKSPACE_PKG = MACROS.SYS.WORKSPACE_PKG;
const WORKSPACE_PKG_CATE = MACROS.SYS.WORKSPACE_PKG_CATE;
const WORKSPACE_PKG_CATE_SEQUENCE = MACROS.SYS.WORKSPACE_PKG_CATE_SEQUENCE;
const WORKSPACE_PKG_CATE_RECORD = MACROS.SYS.WORKSPACE_PKG_CATE_RECORD;
const WORKSPACE_CATE_OBJECT = MACROS.SYS.WORKSPACE_CATE_OBJECT;
const GUI_SESSION = 'gui_session';

export function* watchWorkspace() {
  yield takeEvery(NOTIFY_CHANGED_ACTION, workspaceChangedNotify);
  yield takeEvery(NOTIFY_REMOVED_ACTION, workspaceRemovedNotify);
  yield takeEvery(SET_WORKSPACE_ADOM, setWorkspaceAdom);
}
/*******************NOTIFY**************************** */
function* workspaceChangedNotify(action) {
  yield workspaceAdomsChangeNotify(action.payload);
  yield workspaceDevicesChangeNotify(action.payload);
  yield workspacePkgsChangeNotify(action.payload);
  yield workspacePoliciesChangeNotify(action.payload);
  yield workspaceObjectsChangeNotify(action.payload);
}

function* workspaceRemovedNotify(action) {
  yield workspaceAdomsRemoveNotify(action.payload);
  yield workspaceDevicesRemoveNotify(action.payload);
  yield workspacePkgsRemoveNotify(action.payload);
  yield workspaceObjectsRemoveNotify(action.payload);
  yield workspacePkgCateRemoveNotify(action.payload);

  yield sessionRemoveNotify(action.payload);
}
function* sessionRemoveNotify(payload) {
  if (payload.collection !== GUI_SESSION) return;

  function getDataFromResponse(r) {
    let list = r.result[0] || [];
    return list.data || {};
  }

  const json = yield workspace_adom_fetch_list();
  const data = getDataFromResponse(json);
  yield put(debounceBatch(workspaceAdomsAction.fetch.success(data)));
}
function* workspaceAdomsChangeNotify(payload) {
  if (payload.collection !== WORKSPACE_ADOM) return;

  const id = payload.id;
  const fields = payload.fields;

  yield put(
    debounceBatch(
      workspaceAdomsAction.record.change({
        id,
        fields,
      })
    )
  );
}

function* workspaceAdomsRemoveNotify(payload) {
  if (payload.collection !== WORKSPACE_ADOM) return;
  const id = payload.id.toString();
  yield put(
    debounceBatch(
      workspaceAdomsAction.record.delete({
        id,
      })
    )
  );
}
function* getCurrentAdomOid() {
  const state = yield select();
  return session_get_adom(state).oid;
}
function* workspaceDevicesChangeNotify(payload) {
  if (payload.collection !== WORKSPACE_DEVICE) return;

  const content = {
    path: payload.meta.adom.toString(),
    data: {
      id: payload.id,
      fields: payload.fields,
    },
  };

  yield put(debounceBatch(workspaceDevicesAction.record.change(content)));
}

function* workspaceDevicesRemoveNotify(payload) {
  if (payload.collection !== WORKSPACE_DEVICE) return;

  const adom =
    payload.meta.adom > 0 ? payload.meta.adom : yield getCurrentAdomOid();

  const content = {
    path: adom.toString(),
    data: {
      id: payload.id,
    },
  };

  yield put(debounceBatch(workspaceDevicesAction.record.delete(content)));
}

function* workspacePkgsChangeNotify(payload) {
  if (payload.collection !== WORKSPACE_PKG) return;

  const content = {
    path: payload.meta.adom.toString(),
    data: {
      id: payload.id,
      fields: payload.fields,
    },
  };
  yield put(debounceBatch(workspacePkgsAction.record.change(content)));
}

function* workspacePkgsRemoveNotify(payload) {
  //unlock

  const state = yield select();

  if (payload.collection !== WORKSPACE_PKG) return;

  const content = {
    path: payload.meta.adom.toString(),
    data: {
      id: payload.id,
    },
  };

  if (payload.meta.is_children_scope) {
    if (payload.meta.action_by_self) {
      yield put(debounceBatch(workspacePkgsAction.record.delete(content)));
      yield put(
        debounceBatch(workspacePkgChildrenStatusAction.record.delete(content))
      );
    }

    if (payload.meta.src_action === 'unlock') {
      reloadWorkspacePolicies(state, payload.meta.adom, payload.id);
    }
  } else {
    yield put(debounceBatch(workspacePkgsAction.record.delete(content)));
    yield put(
      debounceBatch(workspacePkgChildrenStatusAction.record.delete(content))
    );
  }
}

function* changeWorkspacePkgChildrenStatusLocked(adomOid, pkgOid) {
  yield put(
    debounceBatch(
      workspacePkgChildrenStatusAction.record.change({
        path: adomOid.toString(),
        data: {
          id: pkgOid,
          fields: {
            locked: true,
            dirty: true,
          },
        },
      })
    )
  );
}

function* workspacePkgCateRemoveNotify(payload) {
  if (payload.collection !== WORKSPACE_PKG_CATE) return;

  const state = yield select();
  const category = get_workspace_adom_policies(
    state,
    payload.meta.adom,
    payload.meta.pkgOid,
    payload.id
  );
  if (!has_loaded(category)) return;

  load_workspace_policies(
    store_get_dispatch(),
    payload.meta.adom,
    payload.meta.pkgOid,
    payload.id
  );

  if (payload.meta.action_by_self) {
    load_workspace_pkg(
      store_get_dispatch(),
      payload.meta.adom,
      payload.meta.pkgOid
    );
  }
}

function reloadWorkspacePolicies(state, adomOid, pkgOid) {
  const categories = filter_workspace_adom_policies_categories(
    state,
    adomOid,
    pkgOid
  );
  if (!categories) return;

  for (let i = 0; i < categories.length; i++) {
    load_workspace_policies(
      store_get_dispatch(),
      adomOid,
      pkgOid,
      categories[i]
    );
  }
}

function* workspacePoliciesChangeNotify(payload) {
  const collection = payload.collection;
  const meta = payload.meta;

  if (
    collection !== WORKSPACE_PKG_CATE_RECORD &&
    collection !== WORKSPACE_PKG_CATE_SEQUENCE
  )
    return;

  const changedData = {
    id: payload.id,
    fields: payload.fields,
  };

  const path = meta.adom
    .toString()
    .concat(':', meta.pkgOid.toString(), ':', meta.categoryOid.toString());

  if (collection === WORKSPACE_PKG_CATE_RECORD) {
    const content = {
      path,
      data: {
        records: changedData,
      },
    };

    yield put(debounceBatch(workspacePoliciesAction.record.change(content)));
  }

  if (collection === WORKSPACE_PKG_CATE_SEQUENCE) {
    const content = {
      path,
      data: {
        sequence: changedData,
      },
    };

    yield put(debounceBatch(workspacePoliciesAction.record.change(content)));
  }

  if ((meta.src_action === 'lock') & meta.action_by_self) {
    yield changeWorkspacePkgChildrenStatusLocked(meta.adom, meta.pkgOid);
  }
}
function* workspaceObjectsChangeNotify(payload) {
  if (payload.collection !== WORKSPACE_CATE_OBJECT) return;

  const content = {
    path: payload.meta.adom
      .toString()
      .concat(':', payload.meta.categoryOid.toString()),
    data: {
      id: payload.id,
      fields: payload.fields,
    },
  };
  yield put(debounceBatch(workspaceObjectsAction.record.change(content)));
}

function* workspaceObjectsRemoveNotify(payload) {
  if (payload.collection !== WORKSPACE_CATE_OBJECT) return;

  const content = {
    path: payload.meta.adom
      .toString()
      .concat(':', payload.meta.categoryOid.toString()),
    data: {
      id: payload.id,
    },
  };

  yield put(debounceBatch(workspaceObjectsAction.record.delete(content)));
}

function* setWorkspaceAdom(action) {
  const payload = action.payload;
  const state = yield select();

  const adomOid = payload.adomOid;
  const data = payload.data;

  if (!has_loaded(get_workspace_adoms(state))) return;

  const adom = get_workspace_adom(state, adomOid);

  //remove
  if (typeof data.lock === 'undefined' || data.lock === 0) {
    if (!adom) return;

    const id = adomOid.toString();

    yield put(
      debounceBatch(
        workspaceAdomsAction.record.delete({
          id,
        })
      )
    );

    return;
  }

  //change
  if (adom && compareObjects(adom, data)) return;

  yield put(
    debounceBatch(
      workspaceAdomsAction.record.change({
        id: adomOid.toString(),
        fields: data,
      })
    )
  );
}
