import { openConfirmModal } from 'rc_layout';

import React from 'react';
import { ProToolkit } from '@fafm/neowise-pro';
import { fiStore, fiStoreSoc, fiStoreRouting as fr } from 'fistore';
import { CreateDashForm } from './CreateDashForm';
import { RenameDashForm } from './RenameDashForm';
import { genUserDashPath } from './helpers';

export const dashActions = {
  create: async () => {
    try {
      let res = await ProToolkit.openModal(<CreateDashForm />, {
        height: '400px',
      }).result;
      fiStore.dispatch(fr.startLoadingApp());
      await fiStore.dispatch(
        fr.go({
          to: genUserDashPath(res.dashId),
          opts: { state: { isEdit: true } },
        })
      );
    } catch (ex) {
      //
    } finally {
      fiStore.dispatch(fr.endLoadingApp());
    }
  },
  remove: async (dashId) => {
    const modalTitle = gettext('Confirm Deletion');
    const msg = gettext('Are you sure you want to delete this dashboard?');

    await openConfirmModal({
      title: modalTitle,
      content: msg,
    });

    try {
      const resp = await fiStore
        .dispatch(fiStoreSoc.customView.actions.deleteCustomView(dashId))
        .unwrap();
      return resp;
    } catch (error) {
      //
    }
  },
  rename: async (dashId, name) => {
    try {
      let res = await ProToolkit.openModal(
        <RenameDashForm dashId={dashId} name={name} />,
        {
          height: '400px',
        }
      ).result;
      return res;
    } catch (ex) {
      //
    }
  },
};
