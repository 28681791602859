import { createSelector } from '@reduxjs/toolkit';

const make_device_group_cache = (fn) => {
  let cache = new Map();

  return (pId, cId) => {
    if (cache.has(cId)) return cache.get(cId);
    const ret = fn(pId, cId);
    cache.set(cId, ret);
    return ret;
  };
};

export function get_device_groups(state) {
  return state.adom.deviceGroups;
}

export function get_device_group_members(state) {
  return state.adom.deviceGroupsMemb;
}

export function get_device_group_base(state, groupId) {
  return get_device_groups(state)?.list?.byId?.[groupId];
}
/*
{oid:89}
*/
const get_device_group_member_grp = (state, groupId) => {
  const g_state = get_device_group_members(state);
  if (!g_state.loaded) return [];
  if (g_state.list.byId[groupId])
    return g_state.list.byId[groupId].membGrps.map((x) => x.oid);
  return [];
};

const make_device_group_finder = (getDeviceGroupMember) => {
  return function find(pId, cId) {
    if (cId == pId) return true;

    const member = getDeviceGroupMember(cId);
    for (let i = 0; i < member.length; i++) {
      const id = member[i];
      if (find(pId, id)) return true;
    }
    return false;
  };
};

/*
input params:
  pId:'101'
  cId:['202','204',...]
  getMemberGrpFn:(id)=>['209','303']

return:
  [true,false,...]
  true - Adding cId will cause circular reference
  false - cId is valid
*/
export function device_group_check_valid_member(pId, cId, getMemberGrpFn) {
  const fn = make_device_group_finder(getMemberGrpFn);
  const finder = make_device_group_cache(fn);
  if (Array.isArray(cId)) {
    return cId.map((x) => finder(pId, x));
  }
  return finder(pId, cId);
}

export function device_group_check_valid_member_by_state(state, pId, cId) {
  const getMemberGrpFn = (id) => get_device_group_member_grp(state, id);
  return device_group_check_valid_member(pId, cId, getMemberGrpFn);
}
/*

input params:
  pId:'101'
  cId:['202','204',...]
  getMemberGrpFn:(id)=>['209','303']
return:
  ['204']
*/
export function device_group_filter_valid_member(pId, cId, getMemberGrpFn) {
  const fn = make_device_group_finder(getMemberGrpFn);
  const finder = make_device_group_cache(fn);
  const data = Array.isArray(cId) ? cId : [cId];
  return data.filter((x) => !finder(pId, x));
}

export function device_group_filter_valid_member_by_state(state, pId, cId) {
  const getMemberGrpFn = (id) => get_device_group_member_grp(state, id);
  return device_group_filter_valid_member(pId, cId, getMemberGrpFn);
}

export function getDefaultDeviceGroups(state) {
  return state.adom?.defaultGroups;
}

export function getRemoteFAZ(state) {
  return state.adom?.remoteFAZ;
}

/**
 * { allIds: [], byId: {} }
 */
export const getDefaultDeviceGroupsList = createSelector(
  getDefaultDeviceGroups,
  (defaultGroupsState) => {
    return defaultGroupsState?.list;
  }
);

export const getDefaultDeviceGroupsAllIds = createSelector(
  getDefaultDeviceGroupsList,
  (list) => {
    return list?.allIds || [];
  }
);
