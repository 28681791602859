import { getEffect, initSupportedVersions } from './utils';
import { useEffect, useState } from 'react';
import { UserService } from 'fi-user';
import { fiWebSocket } from 'fi-web/fi-websocket/services';
import { useValidEffect } from 'rh_util_hooks/useEffects';
import { isTaskDone } from 'rc_task_monitor/task_util';
import { getAdomUpgradingInfos } from '../adom_edit/api';

// keep default avatar empty, so neowise avatar will load initials as a fallback
// eslint-disable-next-line
export const getAdminDefAvatar = (adminName) => '';

export const getAdminAvatarFn = (avatarMap) => (name) =>
  avatarMap[name]
    ? 'data:image/png;base64, ' + avatarMap[name]
    : getAdminDefAvatar(name);

export const useAdminAvatar = () => {
  const [getAvatarImg, setGetFn] = useState(() => getAdminDefAvatar);

  useEffect(() => {
    const effect = getEffect((avatarMap) => {
      setGetFn(() => {
        return (name) => {
          return avatarMap[name]
            ? 'data:image/png;base64, ' + avatarMap[name]
            : getAdminDefAvatar(name);
        };
      });
    });
    UserService.adminAvatarList().then((data) => {
      const avatarMap = (data || []).reduce((map, cur) => {
        map[cur.name] = cur.avatar;
        return map;
      }, {});

      effect.apply(avatarMap);
    });
    return () => effect.cancel();
  }, []);

  return getAvatarImg;
};

export const useSupportedAdomVersions = ({
  sysConfig,
  adomVersion,
  parser = undefined,
  dependencies = [],
}) => {
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    let _versions = initSupportedVersions(sysConfig, adomVersion);
    _versions = parser ? parser(_versions) : _versions;
    setVersions(_versions);
  }, dependencies);

  return [versions, setVersions];
};

export const useUpgradingAdomInfos = ({ adoms, isGrouped, onTaskDone }) => {
  const [upgradingAdomInfos, setUpgradingAdomInfos] = useState({});

  // get the upgrading info from json api at the start
  useValidEffect(
    async (getIsValid) => {
      if (!adoms?.length) return;

      const data = isGrouped ? adoms.map((grp) => grp.children).flat() : adoms;
      const infos = await getAdomUpgradingInfos(data);

      if (getIsValid()) {
        setUpgradingAdomInfos(
          infos.reduce((map, info) => {
            if (info.status === 1) {
              // is in upgrading status
              map[info.oid] = {
                adomOid: info.oid,
                taskId: info.task,
                percent: info.percent ?? 0,
              };
            }
            return map;
          }, {})
        );
      }
    },
    [adoms]
  );

  // update the upgrading info when receive notification from web socket
  useEffect(() => {
    const checkUpgrade = (data) => {
      if (data?.collection === 'adom_upgrade') {
        if (isTaskDone(data.fields)) {
          setUpgradingAdomInfos((prev) => {
            const infos = { ...prev };
            delete infos[data.id];
            return infos;
          });
          onTaskDone?.();
        } else {
          setUpgradingAdomInfos((prev) => ({
            ...prev,
            [data.id]: {
              adomOid: data.id,
              taskId: data.fields.taskId,
              percent: data.fields.percent,
            },
          }));
        }
      }
    };

    fiWebSocket.addListener('notify', checkUpgrade);

    return () => {
      fiWebSocket.removeListener('notify', checkUpgrade);
    };
  }, []);

  return upgradingAdomInfos;
};
