import { fiWorkspaceRequest } from 'fistore';
import { _currentAdom } from './util';

export const isWorkspaceDisabled = (adom = _currentAdom()) =>
  adom.workspace_mode === 'disabled';
export const isWorkspaceEnabled = (adom = _currentAdom()) =>
  adom.workspace_mode !== 'disabled';
export const isNormalMode = (adom = _currentAdom()) =>
  adom.workspace_mode === 'workspace';
export const isWorkflow = (adom = _currentAdom()) =>
  adom.workspace_mode === 'workflow';
export const highlightSaveBtn = (tof) => {
  return fiWorkspaceRequest.highlight_save_btn(tof);
};
export const lockOverride = (adom = _currentAdom()) => !!adom.lock_override;
