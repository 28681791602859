import { isNil, isString } from 'lodash';

export const isGlobalAdom = (adom) => {
  if (!adom) return false;
  if (!isNil(adom?.globaldb)) return Boolean(adom.globaldb);
  const name = isString(adom) ? adom : adom.name;
  return (
    name === MACROS.ADOM.GLB_ADOM_NAME ||
    name === MACROS.DVM.PM2_GUI_GLOBAL_ADOM_NAME
  );
};
