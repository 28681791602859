import { CancelBtn, NwProBody, NwProFooter, NwProHeader } from 'rc_layout';

type Shortcut = {
  keys: string;
  description: string;
  readonly?: number;
};

const DEFAULT_SHORTCUTS: Shortcut[] = [
  {
    keys: '$',
    description: gettext('Use a metadata variable when in CLI script mode.'),
  },
  {
    keys: '{{',
    description: gettext('Use a metadata variable when in Jinja script mode.'),
  },
  {
    keys: 'Ctrl + A',
    description: gettext('Selects the whole content of the editor.'),
    readonly: 1,
  },
  {
    keys: 'Ctrl + C',
    description: gettext('Copies the selected text to the clipboard.'),
    readonly: 1,
  },
  {
    keys: 'Ctrl + X',
    description: gettext(
      'Cut the selected text (or the entire line if no selected text) to the clipboard. Copies in read-only mode.'
    ),
    readonly: 1,
  },
  {
    keys: 'Ctrl + V',
    description: gettext('Pastes the current clipboard content.'),
  },
  {
    keys: 'Ctrl + Z',
    description: gettext('Undoes the last action.'),
  },
  {
    keys: 'Ctrl + Y',
    description: gettext('Redoes the last undone action.'),
  },
  {
    keys: 'Ctrl + D',
    description: gettext(
      'Deletes the whole line under the cursor, including newline at the end.'
    ),
  },
  {
    keys: 'Ctrl + F',
    description: gettext('Search.'),
    readonly: 1,
  },
  {
    keys: 'Ctrl + G',
    description: gettext('Go to next found result.'),
    readonly: 1,
  },
  {
    keys: 'Shift + Ctrl + G',
    description: gettext('Go to previous found result.'),
    readonly: 1,
  },
  {
    keys: 'Shift + Ctrl + F',
    description: gettext('Find and replace.'),
  },
  {
    keys: 'Shift + Ctrl + R',
    description: gettext('Find and replace all.'),
  },
  {
    keys: 'Tab',
    description: gettext('Indent the current line.'),
  },
  {
    keys: 'Shift + Tab',
    description: gettext('Delete indents from the current line.'),
  },
  {
    keys: 'Alt + Right',
    description: gettext('Move the cursor to the end of the line.'),
    readonly: 1,
  },
  {
    keys: 'Alt + Left',
    description: gettext('Move the cursor to the start of the line.'),
    readonly: 1,
  },
  {
    keys: 'Home',
    description: gettext('Move the cursor to the start of the line.'),
    readonly: 1,
  },
  {
    keys: 'End',
    description: gettext('Move the cursor to the end of the line.'),
    readonly: 1,
  },
  {
    keys: 'Ctrl + Home',
    description: gettext('Move the cursor to the start of the editor.'),
    readonly: 1,
  },
  {
    keys: 'Ctrl + End',
    description: gettext('Move the cursor to the end of the editor.'),
    readonly: 1,
  },
  {
    keys: 'Insert',
    description: gettext('Toggle overwriting text to the right of the cursor.'),
  },
];

export const ShortcutsModal = ({
  shortcuts = [],
  isReadonly = false,
  $opener,
}: {
  shortcuts: Shortcut[];
  isReadonly: boolean;
  $opener: any;
}) => {
  return (
    <>
      <NwProHeader>{gettext('Shortcuts and key commands')}</NwProHeader>
      <NwProBody>
        <Shortcuts shortcuts={shortcuts} isReadonly={isReadonly} />
      </NwProBody>
      <NwProFooter>
        <CancelBtn
          onClick={() => {
            $opener.resolve();
          }}
          automation-id={'codemirror_editor_shortcut_cancel'}
        >
          {gettext('Close')}
        </CancelBtn>
      </NwProFooter>
    </>
  );
};

const Shortcuts = ({
  shortcuts = [],
  isReadonly,
}: {
  shortcuts: Shortcut[];
  isReadonly: boolean;
}) => {
  let allShortcuts = [...shortcuts, ...DEFAULT_SHORTCUTS];

  if (isReadonly)
    allShortcuts = allShortcuts.filter((shortcut) => shortcut.readonly);

  return (
    <table id='codemirror_shortcuts_panel_table'>
      <thead>
        <tr>
          <th className='keys'>{gettext('Shortcut key')}</th>
          <th className='action'>{gettext('Action')}</th>
        </tr>
      </thead>
      <tbody>
        {allShortcuts.map(({ keys, description }) => {
          return (
            <tr key={keys}>
              <td>
                <b>{keys}</b>
              </td>
              <td>{description}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
