import {
  forwardRef,
  useCallback,
  useState,
  useRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { isFunction } from 'lodash';
import { NwUploader } from '@fafm/neowise/react';

export const DropUploader = forwardRef(
  (
    {
      onProcess,
      onNwUpdate,
      accept = null,
      allowMultiple = false,
      disabled = false,
      labelText = DEFAULT_LABEL,
      storeAsFile = true,
      allowProcess = false,
      messageTimeout = 3000,
      removeFileAfterUpload = true,
      'automation-id': propAutoId,
      automationId,
      ...rest
    },
    ref
  ) => {
    // If using onProcess, then instantUpload must be true to trigger the event
    const instantUpload = isFunction(onProcess) ? true : false;

    /** ----------------------------------------------------------------------------
     * States
     * -------------------------------------------------------------------------- */
    const [isUploading, setIsUploading] = useState(false);

    /** ----------------------------------------------------------------------------
     * Refs
     * -------------------------------------------------------------------------- */
    const uploadRef = useRef(null);

    useImperativeHandle(ref, () => {
      return {
        getInstance: () => uploadRef.current,
        getIsUploading: () => isUploading,
      };
    });

    /** ----------------------------------------------------------------------------
     * Hooks
     * -------------------------------------------------------------------------- */
    const cleanupSuccessMessages = useCallback(() => {
      if (!uploadRef || !uploadRef?.current) return;
      setTimeout(() => {
        if (removeFileAfterUpload)
          removeFileAfterComplete(uploadRef?.current?.pond);
      }, messageTimeout);
    }, [messageTimeout]);

    const onProcessCallback = useCallback(
      (func) => async (event) => {
        if (!removeFileAfterUpload)
          removeFileAfterComplete(uploadRef?.current?.pond);
        setIsUploading(true);
        try {
          if (isFunction(func)) {
            await func(event);
          }
        } finally {
          // finish loading the file, so it wont load infinitely
          if (event.detail && isFunction(event.detail.load)) {
            await event.detail.load();
          }
          cleanupSuccessMessages();
          setIsUploading(false);
        }
      },
      [onProcess, onNwUpdate]
    );

    return (
      <div className='tw-overflow-hidden drop_uploader'>
        <NwUploader
          ref={uploadRef}
          accept={accept}
          instantUpload={instantUpload}
          labelIdle={labelText}
          storeAsFile={storeAsFile}
          allowProcess={allowProcess}
          disabled={disabled}
          allowMultiple={allowMultiple}
          onNwProcess={onProcessCallback(onProcess)}
          onNwUpdate={onProcessCallback(onNwUpdate)}
          automation-id={propAutoId || automationId}
          {...rest}
        />
      </div>
    );
  }
);

/** ----------------------------------------------------------------------------
 * Constants
 * -------------------------------------------------------------------------- */
// From FilePondFile.FileStatus interface
const FileStatus = {
  // INIT: 1,
  // IDLE: 2,
  // PROCESSING_QUEUED: 9,
  // PROCESSING: 3,
  PROCESSING_COMPLETE: 5,
  // PROCESSING_ERROR: 6,
  // PROCESSING_REVERT_ERROR: 10,
  // LOADING: 7,
  // LOAD_ERROR: 8,
};

const DEFAULT_LABEL =
  "Add files by drag & drop here or <span class='filepond--label-action'> Add Files </span>";

/** ----------------------------------------------------------------------------
 * Helper functions
 * -------------------------------------------------------------------------- */
function removeFileAfterComplete(filePondInstance) {
  if (!filePondInstance) return;

  const files = filePondInstance.getFiles() || [];
  if (files.length === 0) return;
  files.forEach((file, index) => {
    if (file.status === FileStatus.PROCESSING_COMPLETE) {
      filePondInstance.removeFile(index);
    }
  });
}

// Props mostly from nw-uploader, see neowise doc for more details
DropUploader.propTypes = {
  /**
   * From neowise: advanced callback event to start an upload, only emitted when url is NOT set.
   * This event's "detail" attribute contains advanced data and functions to facilitate a request.
   */
  onNwUpdate: PropTypes.func, // onNwUpdate handler for nw-uploader
  onProcess: PropTypes.func, // onNwProcess for nw-uploader
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  labelText: PropTypes.string, // labelIdle for nw-uploader
  storeAsFile: PropTypes.bool,
  allowProcess: PropTypes.bool,
  messageTimeout: PropTypes.number,
};
