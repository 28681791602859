import { isString } from 'fiutil';

export const FDSM_ERR = {
  [MACROS.ERR.FDSM_ER_PARAM]: gettext('Invalid parameter.'),
  [MACROS.ERR.FDSM_ER_INTERNAL]: gettext('Unknown internal error.'),
  [MACROS.ERR.FDSM_ER_TIMEOUT]: gettext('Connection timeout.'),
  [MACROS.ERR.FDSM_ER_FMG_ENABLED]: gettext(
    'FMG is managing FAZ, so cannot enable/disable cloud management.'
  ),
  [MACROS.ERR.FDSM_ER_NO_REGISTER]: gettext(
    'Not register in cloud management server.'
  ),
  [MACROS.ERR.FDSM_ER_NETWORK]: gettext(
    'Fail to communicate with cloud management server.'
  ),
  [MACROS.ERR.FDSM_ER_INVD_PKT]: gettext('Received packet is invalid.'),
  [MACROS.ERR.FDSM_ER_FAIL_AUTH]: gettext('Account or password is wrong.'),
};

export function getFDSMErrorMsg(err) {
  const dfltErrMsg = gettext('Failed to register with FortiCare');
  if (!err) return dfltErrMsg;
  let result = err?.result?.[0] || {};
  let msg = '';
  let code = result.data?.code;
  if (code) {
    msg = `(Code: ${code}) ${FDSM_ERR[code] || gettext('Undefined error')}`;
  } else {
    msg = result.data?.message || result?.status?.message || err.message || err;
  }

  msg = isString(msg) ? msg : dfltErrMsg;
  return msg;
}
