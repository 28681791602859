import { neowiseFormikField } from '../formik_field/neowiseFormikField';
import { NwInput } from '@fafm/neowise-core';
import { validateAny, validators } from './util/util';
import { useMemo } from 'react';
import { isString } from 'lodash';

export const FmkInput = neowiseFormikField(
  NwInput,
  {
    onlyReflectOnChange: true,
  },
  ({ required, min, max, maxLength, minLength, type, pattern }, validate) => {
    const staticValidators = useMemo(() => {
      return validateAny(
        validators.required(required, (v) => {
          return v == null || (isString(v) && v.trim() === '');
        }),
        validators.min(min),
        validators.max(max),
        validators.maxLength(maxLength),
        validators.minLength(minLength),
        validators.inputType(type)
      );
    }, [required, min, max, maxLength, minLength, type]);

    return validateAny(validate, staticValidators, validators.pattern(pattern));
  }
);
FmkInput.displayName = 'FmkInput';
