const SUPPORT_CONTRACT_ITEMS = {
  hardware: gettext('Hardware'),
  firmware: gettext('Firmware'),
  enhanced: gettext('Enhanced Support'),
  comprehensive: gettext('Comprehensive Support'),
};
const FORTIGUARD_CONTRACT_ITEMS = {
  ips: gettext('IPS & Application Control'),
  antivirus: gettext('AntiVirus'),
  webfiltering: gettext('Web Filtering'),
  antispam: gettext('Email Filtering'),
  valscan: gettext('Vulnerability Scan'),
  outbreak: gettext('Outbreak Protection'),
  industraldb: gettext('Industrial DB'),
};

export function contractItems(attr) {
  switch (attr) {
    case 'support_contract':
      return SUPPORT_CONTRACT_ITEMS;
    case 'fortiguard_utm':
      return FORTIGUARD_CONTRACT_ITEMS;
    default:
      return [];
  }
}

export function firmwareUpgradeHistoryDefs() {
  return [
    { field: 'rec_num', label: '#', visible: true, defaultSort: true },
    { field: 'date', label: gettext('Date'), visible: true },
    {
      field: 'upgrade_ver',
      label: gettext('Upgrade Image Version'),
      visible: true,
    },
    {
      field: 'status',
      label: gettext('Status'),
      visible: true,
      getCellHtml: function (rowData, colDef) {
        let status = rowData._oData[colDef.field];
        if (status === 'succeeded') {
          return `<span class="ffg ffg-enable color-green" title="${gettext(
            'Succeeded'
          )}"></span>`;
        }
        if (status === 'failed') {
          return `<span class="ffg ffg-no color-red" title="${gettext(
            'Failed'
          )}"></span>`;
        }
        return '';
      },
    },
  ];
}
