export const fiDvmInterfaceSSIDTrafficMode = {
  TUNNEL: 0,
  BRIDGE: 1,
  MESH: 2,
};

export const fiDvmInterfaceAddressMode = {
  MANUAL: MACROS.PM2CAT.PM2_ADDRESSING_MODE_MANUAL,
  DHCP: MACROS.PM2CAT.PM2_ADDRESSING_MODE_DHCP,
  PPPOE: MACROS.PM2CAT.PM2_ADDRESSING_MODE_PPPOE,
  PPPOA: MACROS.PM2CAT.PM2_ADDRESSING_MODE_PPPOATM,
  DELEGATED: MACROS.PM2CAT.PM2_ADDRESSING_MODE_DELEGATED,
  IPS: 10001, //GUI only values
  DEDICATEDTO: 10002, //hardcoded large values in case MACROS gets more addressing mode which will conflict
  IPAM: 10003,
};

export const DvmInterfaceGroupTypes = {
  physical: {
    value: MACROS.PM2CAT.PM2_INTF_T_PHYSICAL,
    text: gettext('Physical'),
  },
  vlan: { value: MACROS.PM2CAT.PM2_INTF_T_VLAN, text: 'VLAN' },
  aggregate: {
    value: MACROS.PM2CAT.PM2_INTF_T_AGGREGATE,
    text: gettext('Aggregate'),
  },
  redundant: {
    value: MACROS.PM2CAT.PM2_INTF_T_REDUNDANT,
    text: gettext('Redundant'),
  },
  tunnel: {
    value: MACROS.PM2CAT.PM2_INTF_T_TUNNEL,
    text: gettext('Tunnel'),
  },
  wireless: {
    value: MACROS.PM2CAT.PM2_INTF_T_WIRELESS,
    text: gettext('Wireless'),
  },
  'vdom-link': {
    value: MACROS.PM2CAT.PM2_INTF_T_PAIR,
    text: gettext('VDOM Link'),
  },
  loopback: {
    value: MACROS.PM2CAT.PM2_INTF_T_LOOPBACK,
    text: gettext('Loopback'),
  },
  switch: {
    value: MACROS.PM2CAT.PM2_INTF_T_SWITCH,
    text: gettext('Software Switch'),
  },
  'hard-switch': {
    value: MACROS.PM2CAT.PM2_INTF_T_HARD_SWITCH,
    text: gettext('Hardware Switch'),
  },
  hdlc: { value: MACROS.PM2CAT.PM2_INTF_T_HDLC, text: 'HDLC' },
  'vap-switch': {
    value: MACROS.PM2CAT.PM2_INTF_T_VAP_SWITCH,
    text: 'WiFi SSID',
  },
  'wl-mesh': { value: MACROS.PM2CAT.PM2_INTF_T_WL_MESH, text: 'WL-Mesh' },
  fortilink: {
    value: MACROS.PM2CAT.PM2_INTF_T_FORTILINK,
    text: 'FortiLink',
  },
  'switch-vlan': {
    value: MACROS.PM2CAT.PM2_INTF_T_SWITCH_VLAN,
    text: 'Switch VLAN',
  },
  'fctrl-trunk': {
    value: MACROS.PM2CAT.PM2_INTF_T_FCTRL_TRUNK,
    text: gettext('FortiController Trunk'),
  },
  tdm: { value: MACROS.PM2CAT.PM2_INTF_T_TDM, text: 'TDM' },
  'fext-wan': {
    value: MACROS.PM2CAT.PM2_INTF_T_FEXT_WAN,
    text: 'FortiExtender WAN',
  },
  vxlan: { value: MACROS.PM2CAT.PM2_INTF_T_VXLAN, text: 'VXLAN' },
  'emac-vlan': {
    value: MACROS.PM2CAT.PM2_INTF_T_EMAC_VLAN,
    text: 'EMAC VLAN',
  },
  ssl: {
    value: MACROS.PM2CAT.PM2_INTF_T_SSL,
    text: gettext('SSL-VPN Tunnel'),
  },
  'ui-zone': { value: 1001, text: gettext('Zone') },
  'ui-wanlink': { value: 1002, text: 'SD-WAN' },
  'ui-virwire': { value: 1003, text: gettext('Virtual Wire Pair') },
  'ui-sdwan-zone': { value: 1004, text: gettext('SD-WAN Zone') },
};

export const DEFAULT_INTF = {
  text: '0.0.0.0/0.0.0.0',
  value: ['0.0.0.0', '0.0.0.0'],
};
