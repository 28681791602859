import { Suspense, useState, useRef, useEffect } from 'react';

import { NwSpinner, NwCheckbox } from '@fafm/neowise-core';
import { ProForm } from '@fafm/neowise-pro';
import { OkBtn, CancelBtn } from 'react_components/rc_layout/form';

export function EulaModal({ $opener, EulaHtml, header }) {
  const [eulaAgreed, setEulaAgreed] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      const threshold = 50;
      const distance =
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop;
      if (distance <= threshold) {
        setScrolled(true);
      }
    };
    scrollRef.current.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      <ProForm.Header>{header}</ProForm.Header>
      <ProForm.Body ref={scrollRef}>
        <Suspense fallback={<NwSpinner />}>
          <EulaHtml />
        </Suspense>
      </ProForm.Body>
      <ProForm.Footer>
        <NwCheckbox
          className='tw-mr-auto'
          onChange={(e) => {
            setEulaAgreed(e.target.checked);
          }}
        >
          {gettext(
            'I have read and accept the terms in the License Agreement.'
          )}
        </NwCheckbox>
        <OkBtn
          disabled={!scrolled || !eulaAgreed}
          onClick={() => {
            $opener.resolve();
          }}
          title={
            !scrolled
              ? gettext('Scroll to the bottom and accept the agreement.')
              : gettext('Accept')
          }
        >
          {gettext('Accept')}
        </OkBtn>
        <CancelBtn
          onClick={() => {
            $opener.reject();
          }}
        >
          {gettext('Cancel')}
        </CancelBtn>
      </ProForm.Footer>
    </>
  );
}
