import { createRecordAction, createAsyncAction } from '../utils/action';

/**
 * {
 *   fetch: {
 *    REQUEST: `FETCH_SDWAN-MONITOR-HEALTH-CHECK_REQUEST`,
      START: `FETCH_SDWAN-MONITOR-HEALTH-CHECK_START`,
      SUCCESS: `FETCH_SDWAN-MONITOR-HEALTH-CHECK_SUCCESS`,
      FAILURE: `FETCH_SDWAN-MONITOR-HEALTH-CHECK_FAILURE`,
      request: () => ()
      start: () => ()
      failure: () => ()
      success: () => ()
 *   },
     record: {
      CHANGE: `RECORD_sdwan-monitor-health-check_CHANGE`,
      DELETE: `RECORD_sdwan-monitor-health-check_DELETE`,
      ADD:    `RECORD_sdwan-monitor-health-check_ADD`,
      APPEND: `RECORD_sdwan-monitor-health-check_APPEND`,
      SET: `RECORD_sdwan-monitor-health-check_SET`,
      change: () => {}
      delete: () => {}
      add: () => {}
      append: () => {}
      set: () => {}
     }
 * }
 */
export const sdwanMonitorHealthCheckActions = {
  ...createAsyncAction('sdwan-monitor-health-check', 'FETCH'),
  ...createRecordAction('sdwan-monitor-health-check'),
};

export const sdwanMonitorMembersActions = {
  ...createAsyncAction('sdwan-monitor-members', 'FETCH'),
  ...createRecordAction('sdwan-monitor-members'),
};
export const sdwanAvailableInterfacesActions = {
  ...createAsyncAction('sdwan-monitor-available-interfaces', 'FETCH'),
  ...createRecordAction('sdwan-monitor-available-interfaces'),
};

export const sdwanComputedPhase1Actions = {
  ...createAsyncAction('sdwan-monitor-computed-phase1', 'FETCH'),
  ...createRecordAction('sdwan-monitor-computed-phase1'),
};

export const sdwanComputedPhase2Actions = {
  ...createAsyncAction('sdwan-monitor-computed-phase2', 'FETCH'),
  ...createRecordAction('sdwan-monitor-computed-phase2'),
};
