import { formatTimesByLocale } from 'fi-datetime';
import { fiDvmLogDataService } from 'fi-dvm';

// eslint-disable-next-line no-unused-vars
export function getLogStatusObj(logStatus, isVdom = false) {
  let icon = 'down';
  let color = 'color-red';
  let logStatusText = gettext('Down');

  if (logStatus === MACROS.DVM.LOGSTAT_DEVST_UP) {
    color = 'color-green';
    icon = 'up';
    logStatusText = gettext('Up');
  } else if (logStatus === MACROS.DVM.LOGSTAT_DEVST_FWD) {
    color = 'color-grey';
    icon = 'share';
    logStatusText = gettext('No Connection (Logs Forwarded)');
  } else if (logStatus === MACROS.DVM.LOGSTAT_DEVST_HASYNC) {
    color = 'color-grey';
    icon = 'refresh';
    logStatusText = gettext('No Connection (Synced from Primary)');
  } else if (logStatus === MACROS.DVM.LOGSTAT_DEVST_UNKNOWN) {
    color = 'color-grey';
    icon = 'unregistered';
    logStatusText = gettext('Unknown');
  }

  return {
    color,
    css: {
      name: icon,
      className: color,
    },
    logStatusText,
  };
}

export function getLastLogReceivedFormatters(logStats) {
  if (!logStats)
    return {
      lastLogReceivedComp: 'N/A',
      dateText: '',
      durationText: '',
    };

  const lastLogTimestamp = getLogTimestampFromLogStats(logStats);

  const dateText = formatTimesByLocale(lastLogTimestamp);
  const durationText = getDurationReceived(lastLogTimestamp);

  const lastLogReceivedComp = lastLogTimestamp ? (
    <>
      {gettext('Last log received at')}
      <br />
      {dateText}
      <br />
      {durationText}
    </>
  ) : (
    'N/A'
  );

  return {
    lastLogReceivedComp,
    dateText,
    durationText,
  };
}

/** utils */
// move to web_new/static/js/fi-web/fi-datetime/formatZonedTime.jsx
// export function formatTimesByLocale(lastLogTimestamp) {
//   if (!lastLogTimestamp) return '';

//   const adom = fiAdom.current();
//   const sysConfig = fiSysConfig.current();

//   let timeZone;
//   if (sysConfig.timezone.gmtoff !== 0) {
//     timeZone = sysConfig.timezone.timezonename;
//   }
//   if (adom.tz >= 0) {
//     timeZone = adom.timezone.file;
//   }

//   const lastLogDate = new Date(lastLogTimestamp * 1000);

//   const options = {
//     timeZone,
//   };

//   return lastLogDate.toLocaleString(navigator.language, options) + ' ';
// }

export const getLogTimestampFromLogStats = (logStats) => {
  let lastLogTimestamp;
  if (logStats) {
    let logsTimes = logStats?.['logstat-info']?.split(',') || [0];
    //refer to logstat_def.h > logstat_dev_info_string()
    if (logsTimes.length && logsTimes.length > 4) {
      logsTimes = logsTimes.slice(0, 5);
    }
    lastLogTimestamp = Math.max(...logsTimes);
  }
  return lastLogTimestamp || 0;
};

//compares two timestamps in seconds since epoch
export const getTimeIntervalFromTimestamps = (
  lastLogTimestamp,
  timestampToCompare = Math.floor(Date.now() / 1000)
) => {
  if (!lastLogTimestamp) return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  const lastTime = new Date(lastLogTimestamp);

  let delta = Math.max(timestampToCompare - lastTime, 0);
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  const seconds = Math.floor(delta);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const daysString = gettext('%s days');
const dayString = gettext('%s day');

const hoursString = gettext('%s hours');
const hourString = gettext('%s hour');

const minutesString = gettext('%s minutes');
const minuteString = gettext('%s minute');

const secondsString = gettext('%s seconds');
const secondString = gettext('%s second');

export function getTimeStrings(
  { days, hours, minutes, seconds },
  includeSeconds = false
) {
  const res = [];

  if (days > 0) {
    if (days === 1) {
      res.push(dayString.printf([days]));
    } else {
      res.push(daysString.printf([days]));
    }
  }
  if (hours > 0) {
    if (hours === 1) {
      res.push(hourString.printf([hours]));
    } else {
      res.push(hoursString.printf([hours]));
    }
  }
  if (minutes > 0) {
    if (minutes === 1) {
      res.push(minuteString.printf([minutes]));
    } else {
      res.push(minutesString.printf([minutes]));
    }
  }
  if (seconds > 0 && includeSeconds) {
    if (seconds === 1) {
      res.push(secondString.printf([seconds]));
    } else {
      res.push(secondsString.printf([seconds]));
    }
  }

  if (!res.length) {
    if (includeSeconds) res.push(secondsString.printf([seconds]));
    else res.push(minutesString.printf(['0']));
  }

  return res;
}

const getDurationReceived = (lastLogTimestamp) => {
  if (!lastLogTimestamp) return '';
  const remoteFazTimestamp = fiDvmLogDataService.getRemoteFazSystemTimestamp();
  const { days, hours, minutes } = getTimeIntervalFromTimestamps(
    lastLogTimestamp,
    remoteFazTimestamp
  );

  const timeStrings = getTimeStrings({ days, hours, minutes });
  const resString = timeStrings.join(', ');

  return gettext('%s ago').printf([resString]);
};

export const isTimestampWithinLastXYSeconds = (
  logStats,
  secondsLowerBound,
  secondsHigherBound
) => {
  let timestamp = 0;
  if (logStats?.vdom) {
    //vdom use parent timestamp
    logStats = logStats.parentLogStatsArray?.[0];
    timestamp = getLogTimestampFromLogStats(logStats);
  } else {
    timestamp = logStats?.logsInfo?.lastLogTimestamp ?? 0;
  }

  const remoteFazTimestamp = fiDvmLogDataService.getRemoteFazSystemTimestamp();
  const diff = Math.max(remoteFazTimestamp - timestamp, 0);
  return diff >= secondsLowerBound && diff < secondsHigherBound;
};
