import { useState, useEffect, useRef } from 'react';

import { isFunction } from 'lodash';
import $ from 'jquery';

/**
 * Get and observe current dimension of an element
 * @param {{
 *  el: HTMLElement, element to be watched
 *  onResize: function, callback function to be executed when resizing the element
 *  observerResize: boolean, enable resize observer or not
 *  debug: boolean, show debug message
 * }} param0 params
 * @returns current element dimension
 */
export const useDimension = ({
  el,
  onResize,
  observerResize = true,
  debug = false,
}) => {
  const [element, setElement] = useState(el);
  const [mounted, setMounted] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const observerRef = useRef(null);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  // set initial element and its dimension
  useEffect(() => {
    if (!mounted || !(el instanceof HTMLElement)) {
      return;
    }

    setElement(el);
    const initElDimension = getElementDimension(el);
    setWidth(initElDimension.width);
    setHeight(initElDimension.height);
  }, [mounted, el]);

  // update dimension on resize
  useEffect(() => {
    if (!(element instanceof HTMLElement) && observerResize) {
      return;
    }

    observerRef.current = new ResizeObserver((entries) => {
      const entry = entries[0];
      const dimension = entry?.contentRect;

      // for debugging
      if (debug) {
        // eslint-disable-next-line
        console.log('Resizing, entries =', entries);
        // eslint-disable-next-line
        console.log(
          'current element (width, height) =',
          `${element} (${dimension.width}, ${dimension.height})`
        );
      }

      setWidth(dimension?.width || 0);
      setHeight(dimension?.height || 0);
      if (isFunction(onResize)) {
        onResize({ element, entries });
      }
    });
    observerRef.current.observe(element);

    return () => {
      observerRef.current.disconnect();
      observerRef.current = null;
    };
  }, [element]);

  return { width, height };
};

function getElementDimension(element) {
  return {
    width: $(element)?.width() || 0,
    height: $(element)?.height() || 0,
  };
}
