import { useField, useFormikContextSelector } from 'formik';
import { validIp4 } from 'fiutil';
import { callAll, validateAny } from './util/util';
import { useValidateFn } from './util/hook';
import { NwIpV4Input } from '@fafm/neowise-core';

export const neowiseIpInputField = (
  NwIpComp,
  {
    ipValidate = () => undefined,
    toDisplayStr = doNothing,
    toFormValue = doNothing,
  }
) => {
  return ({
    name,
    'automation-id': propAutoId,
    automationId,
    validate,
    children,
    autoFillEmptyValue = true, // auto set field value if input box is empty and user unfocus this input
    autoFillIpValue = ['0.0.0.0', '0.0.0.0'],
    wildcard, // wildcard mode enabled if true
    max_argv = undefined,
    strictPrefix,
    onNwChange,
    onNwBlur,
    ...rest
  }) => {
    const ipValidateProps = {
      wildcard,
      max_argv,
      strictPrefix,
    };
    const ipFnProps = {
      autoFillEmptyValue,
      autoFillIpValue,
    };

    const generalValidate = useValidateFn(rest);

    const submitCount = useFormikContextSelector((val) => val.submitCount);
    const [{ onChange }, { value, error, touched }, { setValue, setTouched }] =
      useField({
        name,
        validate: validateAny(
          validate,
          ipValidate(ipValidateProps),
          generalValidate
        ),
      });

    const onBlur = () => {
      setTouched(true, false);
      setValue(toFormValue(value, ipFnProps));
    };

    return (
      <NwIpComp
        name={name}
        value={toDisplayStr(value, ipFnProps)}
        invalid={error && (touched || !!submitCount)}
        automation-id={propAutoId || automationId}
        {...rest}
        onChange={callAll(onChange, onNwChange)}
        onBlur={callAll(onBlur, onNwBlur)}
        onlyReflectOnChange
      >
        {children}
      </NwIpComp>
    );
  };
};

function doNothing(val) {
  return val || '';
}

export const FmkIpV4Input = neowiseIpInputField(NwIpV4Input, {
  ipValidate: () => {
    return (value) => {
      if (!value || validIp4(value)) return;
      return gettext('Invalid IP address.');
    };
  },
});
FmkIpV4Input.displayName = 'FmkIpV4Input';
