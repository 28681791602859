/*eslint-disable*/
import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { SelectPane } from '@fafm/filego2';
import { fiAlgorithm } from 'fiutil';
import { formatSelectedWithIcon } from 'fi-dev-format';
import { NwButton } from '@fafm/neowise-core';

const filterSelected = (selected) => {
  return (selected || [])
    .filter((x) => x)
    .map((x) => x?.value || x)
    .sort();
};

const arrayEqual = (a, b) => {
  if (a === b) {
    return true;
  }
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }
  if (a.length == b.length) {
    for (let i = a.length; i >= 0; --i) {
      if (a[i] != b[i]) {
        return false;
      }
    }
    return true;
  }
  return false;
};

function selectAllChildren(tree, selectedIds = []) {
  const selectedSet = new Set(selectedIds);
  fiAlgorithm.defaultDfs(tree, (item, parent) => {
    if (
      item?.value &&
      parent?.value &&
      !selectedSet.has(item.value) &&
      selectedSet.has(parent.value)
    ) {
      selectedSet.add(item.value);
    }
  });
  return Array.from(selectedSet);
}

export const useDeviceSelectPane = (props) => {
  const treePaneRef = useRef(null);
  const treeViewRef = useRef(null);
  const btnRef = useRef(null);
  const dispatchRef = useRef(null); // have to use a ref because onChange cannot be changed later
  dispatchRef.current = props.dispatch;
  let showSelector = useCallback(() => {
    let treePane = treePaneRef.current;
    if (treePane) {
      treePane.toggleOpen(!treePane.getIsOpen());
    }
  }, [treePaneRef?.current]);
  const [labelLookup, setLabelLookup] = useState({});

  const [previousSelection, setPreviousSelection] = useState();
  useEffect(() => {
    // For backward compatibility, we should only update "selected" live if
    // explicitly requested by "shouldUpdateSelected".
    if (props.shouldUpdateSelected && treePaneRef.current) {
      const selected = filterSelected(props.selected);
      if (
        !treePaneRef.current.getValue()?.length ||
        !arrayEqual(selected, previousSelection)
      ) {
        setPreviousSelection(selected);
        treePaneRef.current.setValue(selectAllChildren(props.tree, selected));
      }
    }
  }, [
    props.shouldUpdateSelected,
    props.selected,
    treePaneRef.current,
    props.tree,
  ]);

  const formatChoiceFn = useMemo(
    () => props?.formatChoice || formatSelectedWithIcon,
    [props.formatChoice]
  );
  const currentDiv = treeViewRef.current;
  useEffect(() => {
    if (!currentDiv) return;
    let opts = {
      refElement: btnRef.current,
      source: props.tree /*[{
        "label": "All Devices",
        "value": "All_Device",
        "type": 0,
        "logviewType": 2,
        "sn": -1,
        "children": []
      }], */, // promise function or source data(array or obj)
      width: '400px', // tree pane width
      resultStyle: props.resultStyle || 'prefer_parents', // prefer_parents|prefer_leaves|default
      position: props.position || 'bottom', // tree pane position
      itemHeight: 20, // tree pane item height
      indentWidth: 15, // level indent width
      idAttr: 'value', // Define id key
      textAttr: 'label', // Define text  key
      childrenAttr: 'children', // Define child node key
      closedAttr: 'closed', // Define closed attribute key
      disabledAttr: 'disabled', // Define disabled attribute key
      maxHeight: 400, // tree pane max height
      value: filterSelected(props.selected),
      flip: false, // Allow covering of the trigger button.
      // props.isCsf || props.isSingle
      //   ? props.preSelected[0]
      //   : props.preSelected, // pre-selected items.
      zIndex: 7,
      onChange: (selected) => {
        dispatchRef.current((selected || []).filter(Boolean));
      }, // when selected confirm, tree pane call back with selected items
      multipleSelect: true, //!(props.isCsf || props.isSingle), //Allows selecting multiple treeNodes.
      noDataText: gettext('No Device'), // A tip without data.
      // excludedIds:[-1], // exclued items, here is all device group parents
      // Custom node content , using virtual render function
      formatChoiceHTML: formatChoiceFn,
    };
    props.searchFn && (opts.searchFn = props.searchFn);
    treePaneRef.current = new SelectPane(currentDiv, opts);
  }, [currentDiv, formatChoiceFn]);

  useEffect(() => {
    let processTree = (tree) => {
      let lookup = {};
      fiAlgorithm.defaultDfs(tree, (item) => {
        lookup[item.value] = item.label;
      });
      setLabelLookup(lookup);
    };
    if (props.tree?.then) {
      props.tree.then(processTree);
    } else if (props.tree?.length) {
      processTree(props.tree);
    }
  }, [props.tree]);

  const renderRow = useCallback(
    (row) => {
      if (typeof props.renderRow === 'function') {
        return props.renderRow(row);
      }
      return labelLookup?.[row] || row?.label || row || '\u00A0';
    },
    [labelLookup, props.renderRow]
  );

  return {
    showSelector,
    treeViewRef,
    renderRow,
    btnRef,
  };
};

export const DeviceFilters = (props) => {
  const { showSelector, treeViewRef, renderRow, btnRef } =
    useDeviceSelectPane(props);

  return (
    <div className='treeview-select-widget'>
      <div className='selected-list-container'>
        {(props.selected || []).map((row, idx) => (
          <div className='list-item' key={row?.value || row || idx}>
            <span>{renderRow(row)}</span>
            <span
              className='ffg ffg-close list-remove pull-right'
              title={gettext('Delete')}
              onClick={() => {
                props.dispatch(props.selected.filter((i) => i !== row));
              }}
            ></span>
          </div>
        ))}
      </div>
      <NwButton onClick={showSelector} ref={btnRef}>
        {gettext('Select Device')}
      </NwButton>
      <div ref={treeViewRef} style={{ zIndex: 1 }}></div>
    </div>
  );
};
