import { useRouteError } from 'react-router-dom';
import { ProdLogo } from 'react_apps/ra_main/common/ProdLogo';
import { NwButton } from '@fafm/neowise-core';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id='error-page'
      className='tw-text-neutral-0 tw-p-4 tw-w-4/5 tw-mx-auto tw-min-w-[40rem]'
    >
      <h1>
        <ProdLogo className='tw-text-5xl'></ProdLogo>
      </h1>
      <p>{gettext('An unexpected error has occurred.')}</p>
      <p className='tw-font-bold'>
        {gettext('Please try the recommended action below.')}
      </p>
      <p>
        <NwButton
          onClick={() => {
            location.reload();
          }}
        >
          {gettext('Refresh the application')}
        </NwButton>
      </p>

      {process.env.NODE_ENV === 'development' && (
        <pre
          className='tw-overflow-auto tw-border-none tw-text-white'
          style={{ backgroundColor: 'rgb(0 0 0 / 0.2)' }}
        >
          Error Stack(debug build only):
          <br />
          {error.stack}
        </pre>
      )}
    </div>
  );
}
