import { createSelector } from '@reduxjs/toolkit';

export const getDevicesAssets = (state) => state.dvm.assets;

export const getDevicesAssetsData = createSelector(
  getDevicesAssets,
  (assets) => {
    return assets?.data;
  }
);

export const getAssetsByDeviceId = createSelector(
  getDevicesAssetsData,
  (assetsData) => {
    return assetsData?.byId;
  }
);

export const getDeviceAssets = (deviceOid) =>
  createSelector(getAssetsByDeviceId, (byId) => {
    return byId?.[deviceOid];
  });
