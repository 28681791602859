import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { forEach, isNil, isUndefined } from 'lodash';
import { fiDeviceDataLoader } from 'ra_device_util';
import { fiMessageBox } from 'fi-messagebox';
import { fiDvmActionsId } from 'fi-actions';

export const fiDvmFolderService = {
  loadAllFolders,
  getServerFolderTemplate,
  createFolderVMFactory,
  createFolderUIComponentsFactory,
  getUnAssingedDevsFolderId,
  getUnAssingedDevsFolderName,
  dbDecorator,
};

const GUI_UNASSIGNED_DEVICES_FOLDER_ID = -7;
const GUI_UNASSIGNED_DEVICES_NAME = gettext('Unassigned Devices');

class ViewModelDataParserBase {
  /**
   * Refactor me to use general children key
   * /**
   * parse an array of folders to tree structure
   * @param {*} allFolders
   */
  parseArrayDataToTree(allFolders) {
    let foldersRelationshipMap = {},
      normalFolders = [],
      tvDataNodeMap = {};
    let folderSet = new Set(allFolders.map((f) => f.oid.toString()));
    allFolders.forEach((folder) => {
      let subFolders = folder.folderMemberList || [];
      if (!foldersRelationshipMap[folder.oid.toString()]) {
        foldersRelationshipMap[folder.oid.toString()] = {
          parentKeys: [],
          childrenKeys: [],
        };
      }

      let folderTVNode = {
        isFolder: true,
        type: 'folder',
        oid: folder.oid.toString(),
        name: folder.name,
        _children: null,
        _oData: { ...folder },
      };

      subFolders.forEach((subFolderMember) => {
        if (folderSet.has(subFolderMember.oid.toString())) {
          //add child to parent
          foldersRelationshipMap[folder.oid.toString()].childrenKeys.push(
            subFolderMember.oid.toString()
          );
          //add parent to child
          if (!foldersRelationshipMap[subFolderMember.oid.toString()]) {
            foldersRelationshipMap[subFolderMember.oid.toString()] = {
              parentKeys: [folder.oid.toString()],
              childrenKeys: [],
            };
          } else {
            foldersRelationshipMap[
              subFolderMember.oid.toString()
            ].parentKeys.push(folder.oid.toString());
          }
        }
      });

      normalFolders.push(folderTVNode);
    });

    let nodesMap = {}; // map to all folders nodes
    forEach(normalFolders, (n) => {
      nodesMap[n.oid.toString()] = n;
    });

    //now we build the relationship
    forEach(foldersRelationshipMap, (relation, oid) => {
      let node = nodesMap[oid];
      relation.childrenKeys.forEach((childOid) => {
        nodesMap[childOid].isSubFolder = true;
        if (!node._children) node._children = [];
        node._children.push(nodesMap[childOid]);
      });
    });

    //first level nodes
    normalFolders = normalFolders.filter((n) => !n.isSubFolder);

    let treeData = [...normalFolders];
    treeData.forEach((tf) => {
      if (tf.isSubFolder) return;
      tf._path_id = tf.oid.toString();
      tf._name_path = tf.name;
      tvDataNodeMap[tf._name_path] = tf;
      tvDataNodeMap[tf._path_id] = tf;
      tf._children = this.setPathId(
        tf._children,
        tf._path_id,
        tf._name_path,
        tvDataNodeMap
      );
    });

    return [treeData, tvDataNodeMap];
  }

  setPathId(children, parentPathId, parentNamePath, nodeMap) {
    if (!children) return null;
    let ret = [];
    children.forEach((child) => {
      let dummyChild = { ...child };

      dummyChild._path_id = parentPathId + '->' + dummyChild.oid;
      dummyChild._name_path =
        parentNamePath === '/'
          ? `${parentNamePath}${dummyChild?.name}`
          : `${parentNamePath}/${dummyChild?.name}`;
      nodeMap[dummyChild._path_id] = dummyChild;
      nodeMap[dummyChild._name_path] = dummyChild;

      dummyChild._children = this.setPathId(
        dummyChild._children,
        dummyChild._path_id,
        dummyChild._name_path,
        nodeMap
      );
      ret.push(dummyChild);
    });
    return ret;
  }
}

class ViewModelAdapterBase {
  constructor() {}
  remoteToViewData() {}
  viewToRemoteData() {}
}

class ViewModelBase {
  constructor(adapter) {
    this.remoteData = null;
    this.dataHash = {};
    this.vmAdapter = adapter;
    this.vmDataParser = new ViewModelDataParserBase();
  }

  updateVMData() {}

  setRemoteData(rmtData) {
    this.remoteData = rmtData;
    this.updateVMData(JSON.parse(JSON.stringify(this.remoteData)));
  }

  /**
   * set view data
   * @param {Object} data {key:string : data:Any}
   */
  setViewData(data) {
    this.dataHash = { ...this.dataHash, ...data };
  }

  deleteData(key) {
    if (this.dataHash[key]) {
      delete this.dataHash[key];
    }
  }

  getRemoteData() {
    //always return a copy
    if (!this.remoteData) return null;
    return JSON.parse(JSON.stringify(this.remoteData));
  }

  getViewData(key) {
    return key ? this.dataHash[key] : this.dataHash;
  }
}

class DvmFolderViewModelAdapter extends ViewModelAdapterBase {
  constructor() {
    super();
    this.delimiter = '->';
  }

  //override
  remoteToViewData(rmtData) {
    let vmData = {
      parsedFolders: [],
      parsedFoldersWithoutRoot: [],
      folderIdMap: {},
      devToFolderMap: {},
    };
    let rootFolder = rmtData;
    rootFolder.desc = '';
    rootFolder.parent = -1;
    rootFolder.name = '/'; //UI only
    this._findSubFolders(rootFolder, vmData, '');
    return vmData;
  }

  _getFolderObject(folder, path) {
    let guiFolderStruct = {
      css: 'ffg-folder',
      oid: folder.oid,
      name: folder.name,
      desc: folder.desc || '',
      devMemberList: [],
      folderMemberList: [],
      path,
    };

    //folder has devs
    if (folder['object member'])
      guiFolderStruct.devMemberList = [...folder['object member']];

    //folder has sub folders
    if (folder['subobj']) {
      guiFolderStruct.folderMemberList = folder['subobj'].map(
        ({ name, oid, parent, desc = '' }) => ({
          name,
          oid,
          parent,
          desc,
        })
      );
    }

    return guiFolderStruct;
  }

  _getFolderWithoutRoot(parsedFolder, vmData, parentPath) {
    if (parentPath) {
      //regular folder add to the array
      vmData.parsedFoldersWithoutRoot.push(
        JSON.parse(JSON.stringify(parsedFolder))
      );
    } else {
      //add unassigned folder as Linda's suggestion
      const guiFolder = JSON.parse(JSON.stringify(parsedFolder));
      guiFolder.folderMemberList = [];
      guiFolder.oid = GUI_UNASSIGNED_DEVICES_FOLDER_ID;
      guiFolder.name = GUI_UNASSIGNED_DEVICES_NAME;
      guiFolder.path = '/' + this.delimiter + guiFolder.name;
      vmData.parsedFoldersWithoutRoot.push(guiFolder);
    }
  }

  _getDevToFolderMap(parsedFolder, vmData) {
    parsedFolder.devMemberList.forEach((dev) => {
      const fpath = parsedFolder.path.split(this.delimiter);
      vmData.devToFolderMap[dev.oid + ''] = {
        fpath: fpath.length > 1 ? '/' + fpath.slice(1).join('/') : '/',
        fname: parsedFolder.name,
      };
    });
  }

  _findSubFolders(folder, vmData, parentPath) {
    let path =
      parentPath === ''
        ? folder.name
        : parentPath + this.delimiter + folder.name; //update current folder path
    let parsedFolder = this._getFolderObject(folder, path);
    vmData.parsedFolders.push(parsedFolder);
    vmData.folderIdMap[parsedFolder.oid] = parsedFolder;
    this._getDevToFolderMap(parsedFolder, vmData);
    this._getFolderWithoutRoot(parsedFolder, vmData, parentPath);
    //recursive call
    if (folder['subobj']) {
      for (let i = 0; i < folder['subobj'].length; i++) {
        let childFolder = folder['subobj'][i];
        this._findSubFolders(childFolder, vmData, path);
      }
    }
  }
}

/**
 * public interface class
 */
class DvmFolderViewModel extends ViewModelBase {
  constructor(adapter) {
    super(adapter);
  }

  //called from parents
  //override
  updateVMData(rmtData) {
    //tree folder UI data
    const viewData = this.vmAdapter.remoteToViewData(rmtData);
    this.setViewData(viewData);
  }
}

class FolderViewComponents {
  constructor() {
    this.viewModel = {};
  }

  //for tree UI component
  // getTreeUIConfiguration(fiVtreeSearchHighlight) {
  getTreeUIConfiguration() {
    const that = this;
    // const { h } = require('@fafm/filego');
    // const getNodeClass = () => {
    //   return 'ffg ffg-folder folder-color';
    // };
    //Controllers can override this as per thier needs
    // function formatParent(entry, length, term) {
    //   let iconClass = getNodeClass(entry._oData);
    //   let statistic = '';
    //   let displayName = fiVtreeSearchHighlight.getHighlightedName(
    //     term,
    //     entry._oData.name
    //   );
    //   displayName.push(statistic);
    //   return h('span', [
    //     h('span.' + iconClass.replace(/ /g, '.')),
    //     h('span.icon-text', displayName),
    //   ]);
    // }

    // function formatLeaf(entry, term) {
    //   let iconClass = getNodeClass(entry._oData);
    //   let displayName = fiVtreeSearchHighlight.getHighlightedName(
    //     term,
    //     entry._oData.name
    //   );
    //   return h('span', [
    //     h('span.' + iconClass.replace(/ /g, '.')),
    //     h('span.icon-text', displayName),
    //   ]);
    // }

    return {
      source: async () => {
        //load tree data
        const foldersApi = dbDecorator();
        try {
          const resp = await foldersApi.get();
          if (resp.err) {
            resp.messageHander.errHandler({
              err: resp.err,
              msg: gettext('Can not get folders data.'),
            });
            return [];
          }
          that.viewModel = createFolderVMFactory(resp.payload);
          const { parsedFolders } = that.viewModel.getViewData();
          const [treeData] =
            that.viewModel.vmDataParser.parseArrayDataToTree(parsedFolders);
          return treeData;
        } catch (e) {
          return [];
        }
      },
      searchFn: function (term, data) {
        return data.name.toLowerCase().indexOf(term.toLowerCase()) >= 0;
      },
      onSelect: () => {}, //override,
      onContextMenu: () => {},
      onExpandedChange: () => {},
      // formatParentFn: formatParent,
      // formatLeafFn: formatLeaf,
    };
  }
}

function createFolderUIComponentsFactory() {
  const folderViewCompoent = new FolderViewComponents();
  return folderViewCompoent;
}

function createFolderVMFactory(remoteData) {
  //create VM adapter instance
  const vmAdapter = new DvmFolderViewModelAdapter();
  const vm = new DvmFolderViewModel(vmAdapter);
  vm.setRemoteData(remoteData);
  return vm;
}

function getServerFolderTemplate({ name, desc = '', members = [], oid }) {
  let f = {
    name,
    desc,
    oid,
    'object member': members,
  };

  return f;
}

const getManagedDevs = async () => {
  try {
    return await fiDeviceDataLoader.getManagedDevices();
  } catch (e) {
    return [];
  }
};

function dbDecorator() {
  const add = (folder, args) =>
    updateFolders({ folder, method: 'set', ...args });
  const update = (folder, args) =>
    updateFolders({ folder, method: 'update', ...args });
  const move = (folder, args) =>
    moveFolders({ folder, method: 'move', ...args });
  const get = () => loadAllFolders();
  const _delete = (path) => deleteFolder({ path });

  return {
    add,
    update,
    get,
    move,
    delete: _delete,
  };
}

function loadAllFolders() {
  const adom = fiAdom.current();
  const req = {
    method: 'get',
    params: [
      {
        url: `/dvmdb/adom/${adom.name}/folder`,
      },
    ],
  };
  const resp = {
    payload: [],
    messageHander: _defaultMessageHandler,
  };
  const getAllFolders = async () => {
    try {
      const fresp = await fiFmgHttp.forward(req);
      resp.payload = fresp[0].data;
      return resp;
    } catch (err) {
      resp.err = err;
      return resp;
    }
  };
  return Promise.all([getManagedDevs(), getAllFolders()]).then(
    ([allManagedDevs, allFolders]) => {
      if (!allFolders.err && allFolders.payload['object member']) {
        //filter out unauthorized device in folder view.
        const managedDevsIds = allManagedDevs.map((d) =>
          parseInt(d['_fiDeviceId'])
        );
        let numOfUnAssignedDevs = allFolders.payload['object member'].length;
        while (--numOfUnAssignedDevs >= 0) {
          if (
            !managedDevsIds.includes(
              parseInt(
                allFolders.payload['object member'][numOfUnAssignedDevs].oid
              )
            )
          ) {
            allFolders.payload['object member'].splice(numOfUnAssignedDevs, 1);
          }
        }
      }
      return allFolders;
    }
  );
}

function deleteFolder({ path }) {
  const adom = fiAdom.current();
  const req = {
    method: 'delete',
    params: [
      {
        url: `/dvmdb/adom/${adom.name}/folder/${path}`, //always starts with root folder
      },
    ],
  };
  return fiFmgHttp.query(req);
}

//for add/edit/ folders
function updateFolders({
  folder,
  parentFolder,
  folderIdMap,
  rawFolders,
  action,
  method = 'set',
}) {
  const adom = fiAdom.current();
  const req = {
    method: method,
    params: [
      {
        url: `/dvmdb/adom/${adom.name}/folder`, //always starts with root folder
        data: _processFoldersServerData(
          folder,
          parentFolder,
          folderIdMap,
          rawFolders,
          action
        ),
      },
    ],
  };

  let queryFn = req.method == 'get' ? fiFmgHttp.forward : fiFmgHttp.query;
  return queryFn(req).then(
    function (fresp) {
      return fresp;
    },
    function (err) {
      return {
        err,
        messageHander: _defaultMessageHandler,
      };
    }
  );
}

function moveFolders({
  folder,
  parentFolder,
  //folderIdMap,
  //rawFolders,
  //action,
  method = 'move',
}) {
  const adom = fiAdom.current();
  const parentPath = _getFolderPath(parentFolder._oData.path);
  const branchUrl = _getFolderPath(folder._oData.path);
  const req = {
    method: method,
    params: [
      {
        url: `/dvmdb/adom/${adom.name}/folder` + '/' + branchUrl, //always starts with root folder
        'new parent': parentPath,
      },
    ],
  };

  return fiFmgHttp.query(req).then(
    function (fresp) {
      return fresp;
    },
    function (err) {
      return {
        err,
        messageHander: _defaultMessageHandler,
      };
    }
  );
}

function _getFolderPath(path) {
  const childPath = path.split('->');
  return childPath.length >= 2 ? childPath.slice(1).join('/') : '/';
}

function getUnAssingedDevsFolderId() {
  return GUI_UNASSIGNED_DEVICES_FOLDER_ID;
}

function getUnAssingedDevsFolderName() {
  return GUI_UNASSIGNED_DEVICES_NAME;
}

function _defaultMessageHandler() {
  return {
    errHandler: ({ err, msg }) => {
      if (!isUndefined(err.data.result[0].status !== 0)) {
        fiMessageBox.show(err.data.result[0].status.message, 'danger');
      } else if (!isUndefined(err.message)) {
        fiMessageBox.show(err.message, 'danger');
      } else {
        fiMessageBox.show(msg || gettext('Response with errors'), 'danger');
      }
    },
  };
}

function _processFoldersServerData(
  folder,
  parentFolder,
  folderIdMap,
  rawFolders,
  action
) {
  let data = JSON.parse(JSON.stringify(rawFolders));
  let cacheData = {};
  _parseRequestData(data, folder, parentFolder, action, cacheData);
  if (action === fiDvmActionsId.folder.move) {
    cacheData.moveFolderParent['subobj'].push(cacheData.branchToBeMoved[0]);
  }
  return data;
}

function _parseRequestData(data, folder, parentFolder, action, cacheData = {}) {
  _cleanRequestProps(data, folder, action);

  if (action === fiDvmActionsId.folder.edit) {
    if (data.oid === folder.oid) {
      Object.assign(data, folder);
    }
  }

  if (data['subobj']) {
    let i = data['subobj'].length;
    while (i--) {
      let childFolder = data['subobj'][i];
      if (action === fiDvmActionsId.folder.move && !cacheData.branchToBeMoved) {
        if (parseInt(childFolder.oid) === parseInt(folder.oid)) {
          cacheData.branchToBeMoved = data['subobj'].splice(i, 1);
          //do not skip, continue to clean subfolders
        }
      }
      _parseRequestData(childFolder, folder, parentFolder, action, cacheData);
    }
  }

  if (
    action === fiDvmActionsId.folder.create ||
    action === fiDvmActionsId.folder.move
  ) {
    //find parent level
    if (parseInt(data.oid) === parseInt(parentFolder.oid)) {
      if (isNil(data['subobj'])) data['subobj'] = [];
      if (action === fiDvmActionsId.folder.move) {
        cacheData.moveFolderParent = data; //cache this parent piece of data as the branch might not be found yet
      }
      if (action === fiDvmActionsId.folder.create) {
        data['subobj'].push(folder);
      }
    }
  }

  //done with this folder
  if (data['oid']) {
    delete data['oid'];
  }
}
function _cleanRequestProps(data, folder, action) {
  //Only add & edit will change folder devs members
  //If any members in data also exist in folder, we need to delete them
  if (
    action === fiDvmActionsId.folder.edit ||
    action === fiDvmActionsId.folder.create
  ) {
    const newMembers = folder['object member'];
    if (data['object member']) {
      data['object member'] = data['object member'].filter(
        (dev) => !newMembers.find((ndev) => ndev.name === dev.name)
      );
    }
  }

  if (data['parent']) {
    delete data['parent'];
  }
}
