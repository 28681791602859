import { useField } from 'formik';
import { stubFalse, transform } from 'lodash';

import { fiAdom } from 'fi-session';
import { useReload, useApiRequest } from 'rh_util_hooks';
import { fiErrorMessage } from 'fi-messagebox';
import { getResponseData, getDetailErrorMsg } from 'fi-http';
import { ProToolkit } from '@fafm/neowise-pro';
import {
  forceReload,
  getPPkgList,
  getListWithPPkgOnly,
  findPolicyPackage,
  editPackage,
  createPolicyPackage,
} from 'ra_pno_pkg_util';
import { ObjSSelect2 } from 'rc_select';

export const FmkPolicyPackageSSelect = ({
  name,
  required = false,
  validate,
  onChange,
  filter,
  ...rest
}) => {
  const defaultValidate = (newVal) => {
    if (!newVal && required) {
      return gettext('Please select a policy package');
    }
  };

  const [, { value }, { setValue, setTouched }] = useField({
    name,
    validate: validate || defaultValidate,
  });

  const _onChange = (val, item) => {
    if (onChange) onChange(val, item);
    setTouched(true, false);
    setValue(val);
  };

  return (
    <PolicyPackageSSelect
      value={value}
      required={required}
      onChange={_onChange}
      filter={filter}
      {...rest}
    />
  );
};

export const PolicyPackageSSelect = ({
  disabled,
  $opener,
  value = null,
  automationId,
  idAttr = 'oid',
  openFn,
  onChange,
  // use custom or shared data source, so no need to load data source here
  source,
  ppkgOnly,
  onLoaded,
  onCreateEditPpkg,
  filter,
  folderFilter,
  ...rest
}) => {
  const { data, setForceReload } = usePpkgData({
    idAttr,
    ppkgOnly,
    onLoaded,
    breakEffect: () => !!source,
    filter,
    folderFilter,
  });

  // if provided your own source, you should control how it is reloaded/updated
  const ppkgSSource = source || data;

  const newPackage = async (selectedPpkg, isFolder) => {
    const { PolicyPkgEdit } = await import(
      'react_apps/ra_pno_services/policy_pkg/policy_pkg_edit'
    );
    const { ACTIONS, TYPES } = PolicyPkgEdit;
    const adom = fiAdom.current();

    const isEdit = selectedPpkg ? true : false;
    const _openFn = openFn || $opener?.open || ProToolkit.openDrawer;

    return _openFn(
      <PolicyPkgEdit
        action={isEdit ? ACTIONS.EDIT : ACTIONS.CREATE}
        type={isFolder ? TYPES.FOLDER : TYPES.PKG}
        initialValues={selectedPpkg?._oData}
      />
    ).result.then((ppkg) => {
      let newPath = ppkg.fullPath;
      if (isEdit) {
        const pathParts =
          ppkg.path?.split('/') || ppkg.fullPath?.split('/') || [];
        if (pathParts.length) pathParts[pathParts.length - 1] = ppkg.name;
        newPath = pathParts.join('/');
      }

      if (isEdit) {
        return editPackage({ adom, pkg: ppkg }).then(
          (resp) => {
            let respData = getResponseData(resp, {});
            if (Array.isArray(respData)) {
              respData = respData[0] || {};
            }
            return {
              ...ppkg,
              ...respData,
              path: newPath,
            };
          },
          (err) => fiErrorMessage.show(getDetailErrorMsg(err))
        );
      } else {
        const duplicatePkgOrFolder = findPolicyPackage({ path: newPath });
        if (duplicatePkgOrFolder) {
          fiErrorMessage.show(gettext('Duplicated package/folder.'));
          return;
        }

        return createPolicyPackage(adom, ppkg).then(
          (resp) => {
            let respData = getResponseData(resp, {});
            if (Array.isArray(respData)) {
              respData = respData[0] || {};
            }
            return {
              ...ppkg,
              ...respData,
              path: newPath,
            };
          },
          (err) => fiErrorMessage.show(getDetailErrorMsg(err))
        );
      }
    });
  };

  const commands = {
    create: [
      {
        icon: 'add',
        text: gettext('Create New'),
        exec: async () => {
          try {
            const resp = await newPackage();
            const choice = getPpkgChoice({ ppkg: resp, idAttr });
            if (onCreateEditPpkg) {
              await onCreateEditPpkg({ resp, choice, isCreate: true });
            }
            setForceReload();
          } catch (err) {
            // close drawer
          }
        },
      },
    ],
    edit: [
      {
        icon: 'edit',
        text: gettext('Edit'),
        exec: async (_, choice) => {
          try {
            const resp = await newPackage(
              choice,
              choice._oData.type === 'folder'
            );
            const newChoice = getPpkgChoice({ ppkg: resp, idAttr });
            if (onCreateEditPpkg) {
              await onCreateEditPpkg({
                resp,
                choice,
                newChoice,
                isCreate: false,
              });
            }
            setForceReload();
          } catch (err) {
            // close drawer
          }
        },
      },
    ],
  };

  const _onChange = (val, item) => {
    if (onChange) onChange(val, item);
  };

  return (
    <ObjSSelect2
      value={value}
      source={ppkgSSource}
      disabled={disabled}
      automationId={automationId}
      createNewCommands={commands['create']}
      editCommands={commands['edit']}
      allowClear={!disabled}
      onChange={_onChange}
      {...rest}
    />
  );
};

export function usePpkgData({
  idAttr = 'oid',
  ppkgOnly,
  breakEffect,
  filter,
  folderFilter,
} = {}) {
  const [forceReload, setForceReload] = useReload();
  const { state: data } = useApiRequest({
    loader: async () => {
      const data = await getPpkgSource({
        idAttr,
        reload: forceReload,
        ppkgOnly,
        filter,
        folderFilter,
      });
      return data;
    },
    breakEffect,
    dependencies: [forceReload],
  });

  return {
    data,
    setForceReload,
  };
}

export async function getPpkgSource({
  idAttr = 'oid',
  reload,
  ppkgOnly,
  filter,
  folderFilter,
} = {}) {
  const queryFn = ppkgOnly ? getListWithPPkgOnly : getPPkgList;
  const promise = (reload ? forceReload(queryFn) : queryFn)();
  const ppkgs = await promise;
  return parsePpkgs({ data: ppkgs, idAttr, filter, folderFilter });
}

function parsePpkgs({
  data,
  idAttr,
  filter = () => true,
  folderFilter = stubFalse,
}) {
  if (!data) return [];
  return transform(data, (result, ppkg) => {
    if (ppkg.type === 'pkg') {
      if (!filter(ppkg)) return;
      result.push(getPpkgChoice({ idAttr, ppkg: ppkg }));
    } else if (ppkg.type === 'folder') {
      if (folderFilter(ppkg)) {
        result.push(getFolderChoice({ idAttr, folder: ppkg }));
      }

      const folderPpkgs = parsePpkgs({
        data: ppkg.subobj,
        idAttr,
        filter,
        folderFilter,
      });
      result.push(...folderPpkgs);
    }
  });
}

function getFolderChoice({ idAttr, folder }) {
  return {
    id: folder[idAttr] || folder.oid,
    text: folder.path,
    icon: {
      name: 'folder',
      title: gettext('Policy Folder'),
    },
    _oData: folder,
  };
}

export function getPpkgChoice({ idAttr, ppkg }) {
  return {
    id: ppkg[idAttr] || ppkg.oid,
    text: ppkg.path,
    icon: {
      name: 'policy-objects',
      title: gettext('Policy Package'),
    },
    _oData: ppkg,
  };
}
