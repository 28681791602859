/** Hooks */
import { useDevicePsirtData } from './hooks';
import { useState } from 'react';

/** Util */
import ReactDOMServer from 'react-dom/server';
import { fiAdom } from 'fi-session';
import { fiDeviceRedux } from 'ra_device_util';
import {
  SEVERITY,
  SeverityDiv,
  getDeviceFirmwareVersion,
} from '../common/util';
import { makeUpgradeItem } from '../common/toolbar';
import { fiMessageBox } from 'fi-messagebox';
import { OSTYPES } from '../common/constants';
import { PsirtDeviceParser } from '../common/parsers/parser';

/** Components */
import { NwProHeader, NwProBody, NwProFooter, CancelBtn } from 'rc_layout';
import { ProTable, ProToolkit } from '@fafm/neowise-pro';
import { MultiRecordCell } from 'rc_multi_record_cell';
import { irNumberCellRenderer } from '../common/render';

import './PsirtTable.less';

export const PsirtTable = ({ $opener, device, platformKey }) => {
  const { tableData, psirtData } = useDevicePsirtData(platformKey);
  const [loadingFwTemplate, setLoadingFwTemplate] = useState(false);

  const numberOfVulnsForEachRisk = getNumberOfVulnsForEachRisk(tableData);

  const getToolbarItems =
    ({ device, $opener, psirtData }) =>
    () => {
      const adomOid = fiAdom.current().oid;
      const dvmDevices = fiDeviceRedux.getDevicesAsArray(adomOid);

      return [
        makeUpgradeItem({
          getSelectedRows: () => {
            const parser = new PsirtDeviceParser({
              device,
              osType: device._gui_os_type || OSTYPES.FGT,
              allPsirtData: psirtData,
            });
            const parsed = parser.parse();
            return [parsed];
          },
          $opener,
          extraData: {
            dvmDevices,
          },
          isLoading: loadingFwTemplate,
          onSuccess: () => {
            fiMessageBox.show(gettext('Firmware template created.', 'success'));
            setLoadingFwTemplate(false);
          },
          onError: (e) => {
            if (e) fiMessageBox.show(e.message, 'danger');
            else setLoadingFwTemplate(false);
          },
        }),
      ];
    };

  return (
    <ProToolkit.ErrorBoundary>
      <NwProHeader>{gettext('Vulnerabilities')}</NwProHeader>
      <NwProBody className='tw-h-full tw-w-full tw-flex tw-flex-col'>
        <DeviceRow device={device} />
        <VulnerabilityStats
          numberOfVulnsForEachRisk={numberOfVulnsForEachRisk}
          psirts={tableData}
        />
        <ProTable.TableView
          ckColumn={false}
          tableId={'psirt_dev_vulns_table'}
          data={tableData}
          rowKey={'ir_number'}
          columns={columns}
          expandColumnKey={'title'}
          getToolbarItems={getToolbarItems({ device, $opener, psirtData })}
        />
      </NwProBody>
      <NwProFooter>
        <CancelBtn onClick={() => $opener.reject()}>
          {gettext('Close')}
        </CancelBtn>
      </NwProFooter>
    </ProToolkit.ErrorBoundary>
  );
};

const DeviceRow = ({ device }) => {
  const name = device.name;
  const firmware = getDeviceFirmwareVersion(device, device.model_dev);

  return (
    <div className='tw-w-full tw-flex tw-flex-wrap tw-justify-between'>
      <span className='tw-flex'>
        {gettext('Device') + ': '}
        {name}
      </span>
      <span className='tw-flex'>
        {gettext('Current Firmware Version') + ': '}
        {firmware}
      </span>
    </div>
  );
};

const VulnerabilityStats = ({ numberOfVulnsForEachRisk, psirts }) => {
  const renderVulnNumbers = () => {
    return numberOfVulnsForEachRisk.map(({ key, value, text }) => {
      return (
        <span key={key} className='tw-flex tw-gap-2 tw-items-center '>
          <SeverityDiv
            risk={key}
            className='tw-p-1 tw-px-2 tw-rounded-full tw-text-xs'
            style={{ minWidth: '2rem' }}
          >
            {value}
          </SeverityDiv>
          {text}
        </span>
      );
    });
  };

  return (
    <div className='tw-w-full tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-pb-2'>
      <NumberOfVulns psirts={psirts} />
      {renderVulnNumbers()}
    </div>
  );
};

const NumberOfVulns = ({ psirts }) => (
  <span className='tw-wrap'>
    {gettext('%s Vulnerabilities detected in total').printf([psirts.length]) +
      ': '}
  </span>
);

const SEVERITY_STRINGS = {
  5: gettext('Critical'),
  4: gettext('High'),
  3: gettext('Medium'),
  2: gettext('Low'),
  1: gettext('Informational'),
};

function getNumberOfVulnsForEachRisk(psirts) {
  const riskMap = {};

  const add = (risk) => {
    if (!riskMap[risk]) riskMap[risk] = 0;
    riskMap[risk]++;
  };

  psirts.forEach((psirt) => add(psirt.risk));

  return Object.entries(riskMap)
    .sort(([key1], [key2]) => key2 - key1)
    .map(([key, value]) => ({
      key,
      value,
      text: SEVERITY_STRINGS[key],
    }));
}

const entryFormatFn = (highlighter) => {
  return (cve) => {
    return ReactDOMServer.renderToString(
      <a
        key={cve}
        title={gettext('Details')}
        href={`https://nvd.nist.gov/vuln/detail/${cve}`}
        target='_blank'
        rel='noreferrer'
      >
        {highlighter(cve)}
      </a>
    );
  };
};

const cellEntryFormatFn = (highlighter) => {
  return (cve) => {
    return (
      <div key={cve}>
        <a
          title={gettext('Details')}
          href={`https://nvd.nist.gov/vuln/detail/${cve}`}
          target='_blank'
          rel='noreferrer'
        >
          {highlighter(cve)}
        </a>
      </div>
    );
  };
};

const popoverEntryFormatter =
  (highlighter) =>
  ({ name: cve }) => {
    return entryFormatFn(highlighter)(cve);
  };

const columns = [
  {
    key: 'title',
    title: gettext('Title'),
    width: 400,
    cellRenderer: ({ rowData }, highlighter) => {
      const title = rowData.title;
      return <span title={title}>{highlighter(title)}</span>;
    },
  },
  {
    key: 'threat_severity',
    title: gettext('Severity'),
    width: 100,
    initialSort: 'desc',
    dataGetter: ({ rowData }) => SEVERITY[rowData.risk],
    cellRenderer: ({ rowData, cellData }, highlighter) => {
      return (
        <SeverityDiv risk={rowData.risk}>{highlighter(cellData)}</SeverityDiv>
      );
    },
    toSortValue: ({ rowData }) => {
      return rowData.risk;
    },
  },
  {
    key: 'ir_number',
    title: gettext('IR Number'),
    width: 120,
    cellRenderer: ({ rowData, cellData }, highlighter) =>
      irNumberCellRenderer(
        {
          irNumber: cellData,
          risk: rowData.risk,
        },
        highlighter
      ),
  },
  {
    key: 'cve',
    title: gettext('CVE'),
    width: 150,
    cellHeight: ({ cellData }) =>
      MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_DEFAULT * cellData.length + 4,
    cellRenderer: ({ cellData }, highlighter) => {
      const getAutomationId = (attr) => `${attr}`;
      if (!cellData) return;
      return (
        <MultiRecordCell
          dataSource={cellData}
          highlighter={highlighter}
          maxRowNum={5}
          getAutoId={getAutomationId}
          popoverEntryFormatter={popoverEntryFormatter}
          cellEntryFormatFn={cellEntryFormatFn}
        />
      );
    },
  },
];
