import { fazDelNotificationAction } from './actions';

const initialState = {
  timestamp: 0,
  dellist: [],
  adomName: '',
};

export function fazDelNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case fazDelNotificationAction.record.CHANGE:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
}
