import React, { useRef } from 'react';
import { Formik } from 'formik';
import { getTabAutoId } from '../tab_utils';
import { useWizard } from 'rc_wizard';
import { UserService } from 'fi-user';

import { fiMessageBox } from 'fi-messagebox';
import { FmkPassword, FmkErrorSpan } from 'rc_form';
import {
  ConditionalComponent,
  NwProBody,
  NwProFooter,
  NwProInputRow,
  NwProSection,
} from 'rc_layout';
import { NwButton } from '@fafm/neowise-core';
import { setSkippedStep } from '../../modal/utils';

export const ChangePasswordTab = ({ sysConfig, tab }) => {
  const getAutoId = getTabAutoId('password');
  const helpText = sysConfig.need_change_password
    ? gettext(
        'This account is using the default password. You are required to change your password.'
      )
    : gettext(
        'This account is using the default password. It is strongly recommended that you change your password.'
      );

  const { goToNext } = useWizard();
  const formikRef = useRef(undefined);

  function submit(values, { setSubmitting }) {
    const errors = formikRef.current.errors;
    if (Object.keys(errors).length > 0) return Promise.reject();

    const { password } = values;

    return UserService.changePassword(sysConfig.username, '', password).then(
      function () {
        goToNext();
      },
      function (resp) {
        if (resp?.errors) {
          const errMsg =
            resp.errors.newpasswd ?? resp.errors.oldpasswd ?? resp.errors.user;
          fiMessageBox.show(errMsg, 'danger');
        }
        setSubmitting(false);
      }
    );
  }

  return (
    <Formik
      initialValues={{
        password: '',
        confirm_password: '',
      }}
      innerRef={formikRef}
      onSubmit={submit}
    >
      {({ values, submitForm, isSubmitting }) => (
        <>
          <NwProBody>
            <NwProSection title={gettext('Change Your Password')}>
              <p>{helpText}</p>

              <NwProInputRow label={gettext('New Password')}>
                <FmkPassword
                  name={'password'}
                  automationId={getAutoId('password')}
                  validate={(val) => {
                    if (!val) return gettext('This field is required.');
                  }}
                />

                <FmkErrorSpan name={'password'} />
              </NwProInputRow>

              <NwProInputRow label={gettext('Confirm Password')}>
                <FmkPassword
                  name={'confirm_password'}
                  automationId={getAutoId('confirm_password')}
                  validate={(val) => {
                    if (!val) return gettext('This field is required.');
                    if (val !== values.password)
                      return gettext('Passwords do not match.');
                  }}
                />

                <FmkErrorSpan name={'confirm_password'} />
              </NwProInputRow>
            </NwProSection>
          </NwProBody>
          <NwProFooter>
            <NwButton
              onClick={submitForm}
              disabled={isSubmitting}
              automation-id={getAutoId('next-btn')}
              className={'tw-min-w-32'}
              type={'primary'}
            >
              {gettext('Next') + ' >'}
            </NwButton>
            <ConditionalComponent condition={!tab.isRequired}>
              <NwButton
                onClick={() => {
                  setSkippedStep();
                  goToNext();
                }}
                automation-id={getAutoId('later-btn')}
                className={'tw-min-w-32'}
                type={'default'}
              >
                {gettext('Later')}
              </NwButton>
            </ConditionalComponent>
          </NwProFooter>
        </>
      )}
    </Formik>
  );
};
