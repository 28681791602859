import { useEffect } from 'react';
import { get } from 'lodash';

import { fiWorkspace } from 'fi-workspace';

const getAdomWsAttr = (wsState) => (adomOid, attr) => {
  return get(wsState, ['adoms', 'byId', adomOid, attr]);
};

export const useWsUnlocked = (callback, adomOid) => {
  useEffect(() => {
    return fiWorkspace.bindAdomUpdateFn((curr, _, prev) => {
      const isCurrLocked = getAdomWsAttr(curr)(adomOid, 'lock');
      const isPrevLocked = getAdomWsAttr(prev)(adomOid, 'lock');
      if (!isCurrLocked && isPrevLocked) {
        // callback function if dispatch actions to fiStore, need to be wrapped in setTimeout
        // otherwise it will create infinite loop due to workspace listener being a fistore connector
        callback();
      }
    });
  }, [adomOid]);
};

export const useWsNotDirty = (callback, adomOid) => {
  useEffect(() => {
    return fiWorkspace.bindAdomUpdateFn((curr, _, prev) => {
      const isCurrDirty = getAdomWsAttr(curr)(adomOid, 'dirty');
      const isPrevDirty = getAdomWsAttr(prev)(adomOid, 'dirty');
      if (!isCurrDirty && isPrevDirty) {
        // callback function if dispatch actions to fiStore, need to be wrapped in setTimeout
        // otherwise it will create infinite loop due to workspace listener being a fistore connector
        callback();
      }
    });
  }, [adomOid]);
};
