import { forwardRef, useMemo, isValidElement } from 'react';
import { FmkSSelect2 } from './FmkSSelect2';
import cn from 'classnames';
import { escapeHtml } from 'kit-escape';
import { identity, isNil } from 'lodash';
import { renderToString } from 'react-dom/server';

export const FmkObjSSelect2 = forwardRef((props, ref) => {
  const { textAttr = 'text', multipleSelect, textNoWrap = false } = props;
  const detailAttr = 'detail';

  const formatSelectedHTML = useMemo(
    () => makeFormatSelectedHTML(props),
    [textAttr, textNoWrap]
  );

  const formatChoiceHTML = useMemo(
    () => makeFormatChoiceHTML(props),
    [formatSelectedHTML, detailAttr]
  );

  const searchFn = useMemo(() => makeSearchFn(props), [textAttr, detailAttr]);

  return (
    <FmkSSelect2
      formatChoiceHTML={formatChoiceHTML}
      formatSelectedHTML={
        multipleSelect ? formatChoiceHTML : formatSelectedHTML
      }
      searchFn={searchFn}
      {...props}
      ref={ref}
    />
  );
});
FmkObjSSelect2.displayName = 'FmkObjSSelect2';
FmkObjSSelect2.makeFormatSelectedHTML = makeFormatSelectedHTML;
FmkObjSSelect2.makeFormatChoiceHTML = makeFormatChoiceHTML;
FmkObjSSelect2.makeSearchFn = makeSearchFn;

function makeFormatSelectedHTML({ textAttr = 'text', textNoWrap = false }) {
  return (item, hiliter = identity) => {
    if (!item) return null;

    const text = escapeHtml(item[textAttr]);
    if (item.children) {
      return `<div class="obj-name">${hiliter(text)} (${
        item.children.length
      })</div>`;
    }

    if (item.formatter) {
      return (
        '<div class="obj-name tw-flex tw-items-center">' +
        item.formatter(item, hiliter) +
        '</div>'
      );
    }

    let iconString = '';
    if (item.icon) {
      if (typeof item.icon === 'function') {
        iconString = item.icon(item, hiliter);
      } else if (isValidElement(item.icon)) {
        iconString = renderToString(item.icon);
      } else {
        iconString = ((iconProps) =>
          iconProps ? `<nw-icon ${iconProps}></nw-icon>` : '')(
          makeNwIconPropString(item.icon?.props || item.icon)
        );
      }
    }
    const iconTextCn = iconString
      ? `class="${cn('icon-text', { 'tw-whitespace-nowrap': textNoWrap })}" `
      : '';

    return (
      '<div class="obj-name tw-flex tw-items-center">' +
      iconString +
      `<span ${iconTextCn} title="${text}">${hiliter(text)}</span>` +
      '</div>'
    );
  };
}

function makeFormatChoiceHTML(props) {
  const { detailAttr = 'detail' } = props;
  const formatSelectedHTML = makeFormatSelectedHTML(props);

  return (item, hiliter = identity) => {
    if (!item) return null;

    const detailText = escapeHtml(item[detailAttr]);
    return (
      '<div class="obj-box">' +
      formatSelectedHTML(item, hiliter) +
      ((item?.[detailAttr] &&
        `<div class="obj-detail" title="${detailText}">${
          hiliter?.(detailText) ?? detailText
        }</div>`) ||
        '') +
      '</div>'
    );
  };
}

function makeSearchFn({ textAttr = 'text', detailAttr = 'detail' }) {
  return (data, search) => {
    return (
      search(data[textAttr]) ||
      search(data[detailAttr]) ||
      search(data.searchString)
    );
  };
}

function makeNwIconPropString(prop) {
  if (!prop) return;
  const { classes, className, ...others } = prop;

  return Object.keys(others).reduce((result, propKey) => {
    switch (propKey) {
      case 'name':
      case 'library':
      case 'style':
      case 'title':
        return `${result} ${propKey}="${
          isNil(others[propKey]) ? '' : others[propKey]
        }"`;
    }
    return result;
  }, `class="${cn('tw-mr-1', classes, className)}"`);
}
