import { useCallback, useMemo } from 'react';

import { isFunction } from 'lodash';
import { AutocompleteCodeMirror } from 'rc_autocomplete_codemirror';

export const ContentViewCodeMirror = (props) => {
  const {
    codemirrorId,
    value = '', // help refresh codemirror content after initialization
    mode = 'text',
    onChange = () => {},
    dependencies = [],
    'automation-id': autoId,
    automationId,
    errors = [],
    originalScript,
    collapseIdentical,
    isDiffView,
    showDiffView,
    setCmAccessors,
    ...rest
  } = props;

  const change = useCallback(
    (newVal) => {
      if (isFunction(onChange)) onChange(newVal);
    },
    [onChange]
  );

  const initialValue = useMemo(() => {
    if (isJson(value)) {
      return JSON.stringify(JSON.parse(value), null, 2);
    }
    return value;
  }, [value]);

  return (
    <AutocompleteCodeMirror
      id={codemirrorId}
      lintTooltipId='rc_content_view-codemirror-lint-tooltip'
      initialValue={initialValue}
      originalScript={originalScript}
      dependencies={[value, ...dependencies]}
      codeMirrorOptions={{
        mode,
        showResetBtn: false,
        readOnly: true, // disable editing
        gutters: ['CodeMirror-lint-markers'],
        lint: {
          lintOnChange: false,
        },
        collapseIdentical,
      }}
      onChange={change}
      automation-id={autoId || automationId}
      errors={errors}
      isDiffView={isDiffView}
      showDiffBtns={true}
      showDiffView={showDiffView}
      setCmAccessors={setCmAccessors}
      {...rest}
    />
  );
};

function isJson(text) {
  try {
    JSON.parse(text);
    return true;
  } catch (err) {
    return false;
  }
}
