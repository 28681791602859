import * as actions from './actions';
import { switchSessionAdom } from 'fistore/session/adom/slice';

const INVALID_COLLID = -1;

const mergeObj = (obj1) => (obj2) => ({ ...obj1, ...obj2 });

const defaultStateColl = {
  status: 'NOT_LOADED',
  meta: {
    idAttr: 'id',
  },
  items: {},
  itemids: [], // itemids in array form, since cannot guarantee order in an object
  groups: {},
  error: null,
};

// ADOM changed, clear items in all collections
const onSetAdomReducer = (state) => {
  let newState = {};
  Object.keys(state).forEach((collid) => {
    newState[collid] = {
      ...defaultStateColl,
      meta: state[collid].meta, // keep same meta?
    };
  });
  return newState;
};

// state: single collection state
const dataReducer = (state, action) => {
  const mergeState = mergeObj(state);
  let payloadData = action.payload.items;
  let items = payloadData;

  let newCollectionState = {};

  // Convert array to object
  if (Array.isArray(items)) {
    newCollectionState.items = items.reduce(
      (output, item) => {
        output[item[state.meta.idAttr]] = item;
        return output;
      },
      { ...state.items }
    );

    let newItemIds = items.map((item) => item[state.meta.idAttr]);
    // If updating, only add new ids
    if (action.type === actions.UPDATE_ITEMS) {
      newItemIds = newItemIds.filter((itemid) => !state.items[itemid]);
    }
    newCollectionState.itemids = state.itemids.concat(newItemIds);
  }

  switch (action.type) {
    case actions.ADD_ITEMS:
    case actions.UPDATE_ITEMS:
      return mergeState(newCollectionState);
    default:
      return state;
  }
};

// state: single collection state
// return: state of single group
const groupReducer = (state, action) => {
  let groupid = action.payload.groupid;
  if (!groupid) {
    return null;
  }

  let groupState = state.groups[groupid] || {};

  // Remove item ids first
  let itemidsToRemove = action.payload.itemidsToRemove || [];
  if (itemidsToRemove.length > 0) {
    groupState = { ...groupState };
    itemidsToRemove.forEach((itemid) => {
      delete groupState[itemid];
    });
  }

  const mergeState = mergeObj(groupState);

  // Merge new group item ids
  // we expect itemids to be an array of ids that reference items in current collection
  let itemids = action.payload.itemids || [];
  let groupItems = itemids.reduce((output, itemid) => {
    if (state.items[itemid]) {
      output[itemid] = 1;
    }
    return output;
  }, {});

  switch (action.type) {
    case actions.UPDATE_GROUP:
      return mergeState(groupItems);
    default:
      return groupState;
  }
};

/*
NOTE: References are not implemented

state: all collections state
action.payload: {
  collection: 'devices',
  itemid: '123',
  refs: {
    'ppkgs': {
      'pkg-1': true,
      ...
    },
    ...
  },
  ref_by: {
    'vdoms': {
      '123-root': true,
      '123-vdom2': true,
    }
  }
}
*/
// const setRefsReducer = (state, action) => {
//   // Update current collection

//   // For refs, update the ref_by of the foreign collection

//   // For ref_by, update the refs of the foreign collection

//   return state;
// };

// const deleteDataReducer = (state, action) => {
//   // Delete specified IDs

//   return state;
// };

const collectionReducer = (state = defaultStateColl, action) => {
  const mergeState = mergeObj(state);

  let groupid = action.payload.groupid;

  switch (action.type) {
    case actions.SET_META:
      return mergeState({ meta: action.payload.meta }); // merge with last meta? what will meta be used for. Possibly collection's "last update timestamp"
    case actions.START_LOAD:
      return mergeState({
        status: 'LOADING',
        items: {},
        itemids: [],
        groups: {},
        error: null,
      });
    case actions.CANCEL_LOAD:
      return mergeState({ status: 'CANCELED' });
    case actions.END_LOAD:
      return mergeState({ status: 'READY' });
    case actions.SET_ERROR:
      return mergeState({ status: 'ERROR', error: action.payload.error });
    case actions.ADD_ITEMS:
    case actions.UPDATE_ITEMS: // ??
      return dataReducer(state, action);
    case actions.UPDATE_GROUP:
      return mergeState({
        groups: {
          ...state.groups,
          [groupid]: groupReducer(state, action),
        },
      });
    default:
      return state;
  }
};

function adomCollectionsReducer(state = {}, action) {
  const mergeState = mergeObj(state);
  switch (action.type) {
    case switchSessionAdom.fulfilled.type:
      return onSetAdomReducer(state, action);

    // case actions.DELETE_ITEMS:
    //   return deleteDataReducer(state, action);

    default:
    // other actions
  }

  let collid = INVALID_COLLID;
  let collState = {};
  if (action.payload && action.payload.collid) {
    collid = action.payload.collid;
    collState = state[collid];
    return mergeState({
      [collid]: collectionReducer(collState, action),
    });
  }

  return state;
}

export default function reducer(state = {}, action) {
  if (action.payload && action.payload.adomoid) {
    let adomoid = action.payload.adomoid;
    return mergeObj(state)({
      [adomoid]: adomCollectionsReducer(state[adomoid], action),
    });
  }

  return state;
}
