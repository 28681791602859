import { useMemo, useState } from 'react';

import { useField, useFieldValue } from 'formik';
import { NwInput } from '@fafm/neowise-core';
import { ProLego } from '@fafm/neowise-pro';
import { ConditionalComponent } from 'rc_layout';
import { isString } from 'lodash';

const AM_PM_OPTS = {
  am: { id: 0, text: gettext('AM') },
  pm: { id: 1, text: gettext('PM') },
};

const MAX_HOUR = 12;

export const FmkHourRange = ({
  start: { name: startName, default: startDefault },
  end: { name: endName, default: endDefault },
  useDropdown = true,
  getAutoId,
}) => {
  const [start] = useFieldValue(startName);
  const [end] = useFieldValue(endName);

  const showError = useMemo(() => {
    if (!start || !end) return false;

    const startVal = isString(start) ? parseInt(start) : start;
    const endVal = isString(end) ? parseInt(end) : end;
    if (isNaN(startVal) || isNaN(endVal)) return false;

    return endVal < startVal;
  }, [start, end]);

  const Comp = useDropdown ? FmkHourSelect : FmkHourInput;

  return (
    <div className='tw-flex tw-flex-col'>
      <div className='tw-flex tw-gap-x-4'>
        <Comp
          name={startName}
          value={start || startDefault}
          automation-id={getAutoId(startName)}
        />
        <div>{'-'}</div>
        <Comp
          name={endName}
          value={end || endDefault}
          automation-id={getAutoId(endName)}
        />
      </div>
      <ConditionalComponent condition={showError}>
        <span className='color-red'>
          {gettext('Start hour should be less than end hour.')}
        </span>
      </ConditionalComponent>
    </div>
  );
};

const FmkHourSelect = ({ name, validate, ...rest }) => {
  const [, { value }, { setValue, setTouched }] = useField({ name, validate });

  return (
    <HourSelect
      value={value}
      onChange={(_value) => {
        setTouched(true, false);
        setValue(_value);
      }}
      {...rest}
    />
  );
};

const FmkHourInput = ({ name, validate, ...rest }) => {
  const [, { value }, { setValue, setTouched }] = useField({ name, validate });

  return (
    <HourInput
      value={value}
      onChange={(_value) => {
        setTouched(true, false);
        setValue(_value);
      }}
      {...rest}
    />
  );
};

const HourSelect = ({
  value,
  onChange,
  'automation-id': autoId,
  automationId,
  ...rest
}) => {
  const opts = useMemo(() => {
    return getHourSelectOpts();
  }, []);

  return (
    <div className='tw-flex-1 tw-min-w-32'>
      <ProLego.SSelect
        value={`${value}`}
        source={opts}
        onChange={(id) => {
          onChange && onChange(id);
        }}
        automationId={autoId || automationId}
        automation-id={autoId || automationId}
        {...rest}
      />
    </div>
  );
};

const HourInput = ({
  value,
  onChange,
  twelveHour = true,
  'automation-id': autoId,
  automationId,
  ...rest
}) => {
  const [hour, setHour] = useState(value > MAX_HOUR ? value - MAX_HOUR : value);
  const [amOrPm, setAmOrPm] = useState(
    value > MAX_HOUR ? AM_PM_OPTS.pm.id : AM_PM_OPTS.am.id
  );

  // For 12 hour: 0 - 12 (am), 1 - 11 (pm) -> 0 - 23 hour
  const hourMin = useMemo(() => {
    if (twelveHour) {
      return amOrPm === AM_PM_OPTS.am.id ? 0 : 1;
    }
    return 0;
  }, []);

  const hourMax = useMemo(() => {
    if (twelveHour) {
      return amOrPm === AM_PM_OPTS.am.id ? MAX_HOUR : 11;
    }
    return 23;
  }, []);

  const onHourChange = (hour, _amOrPm) => {
    let val = hour;
    if (twelveHour && _amOrPm === AM_PM_OPTS.pm.id) {
      val = changeToPmTime(val);
    }
    setHour(hour);
    onChange && onChange(val);
  };

  return (
    <div className={'tw-flex tw-gap-x-1'}>
      <div className={'tw-w-24 tw-flex-1'}>
        <NwInput
          value={hour}
          type='number'
          min={hourMin}
          max={hourMax}
          onChange={(event) => {
            const hour = parseInt(event.target.value, 10);
            onHourChange(hour, amOrPm);
          }}
          automation-id={autoId || automationId}
          {...rest}
        ></NwInput>
      </div>
      <ConditionalComponent condition={twelveHour}>
        <div className={'tw-w-24 tw-flex-1'}>
          <ProLego.SSelect
            value={amOrPm}
            source={Object.values(AM_PM_OPTS)}
            onChange={(id) => {
              setAmOrPm(id);
              onHourChange(hour, id);
            }}
            automationId={`${autoId || automationId}-am-pm-select`}
            automation-id={`${autoId || automationId}-am-pm-select`}
          ></ProLego.SSelect>
        </div>
      </ConditionalComponent>
    </div>
  );
};

function getHourSelectOpts() {
  const opts = [];
  for (let i = 0; i < 24; i++) {
    const num = i < 10 ? `0${i}` : i;
    opts.push({ id: `${i}`, text: `${num}:00` });
  }
  return opts;
}

function changeToPmTime(hour) {
  return (hour % MAX_HOUR) + MAX_HOUR;
}
