import { concat, head } from 'lodash';

import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';
import {
  sdwan_pt_apps,
  sdwan_overlay_orchestration_apps,
  sdwan_rules_apps,
  sdwan_devices_apps,
  sdwan_monitor_apps,
} from 'fi-web/fi-routing/all-apps/sdwan';
import { getRouteObjectsFromApps } from 'fi-web/fi-routing/util';

import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';

export default {
  path: 'sdwan',
  RenderErrorBoundary,
  handle: {
    appUniKey: 'sdwan',
  },
  children: [
    getRedirectIndexRoute(),
    getNetworkRoute(),
    getPtRoutes(),
    getOverlayRoute(),
    getRulesRoute(),
  ],
};

/**
 * /sdwan/network
 */
function getNetworkRoute() {
  return {
    path: 'network',
    handle: {
      appUniKey: 'sdwan_network',
    },
    lazy: () => import('react_apps/ra_sdwan/SdwanNetworkMain'),
    children: [
      getRedirectIndexRoute(),
      ...getDevicesRoute(),
      ...getMonitorRoute(),
    ],
  };
}

/**
 * /sdwan/network/devices
 */
function getDevicesRoute() {
  const routes = getRouteObjectsFromApps({ apps: sdwan_devices_apps });
  return routes;
}

/**
 * /sdwan/network/monitor
 */
function getMonitorRoute() {
  const routes = getRouteObjectsFromApps({ apps: sdwan_monitor_apps });
  return routes;
}

/**
 * /sdwan/prvtmpl
 */
function getPtRoutes() {
  return {
    path: 'prvtmpl',
    handle: {
      appUniKey: 'sdwan_pt',
    },
    lazy: () => import('react_apps/ra_pt/PTMain'),
    children: concat(
      getRedirectIndexRoute(),
      getRouteObjectsFromApps({ apps: sdwan_pt_apps })
    ),
  };
}

/**
 * /sdwan/overlay
 */
function getOverlayRoute() {
  const routes = getRouteObjectsFromApps({
    apps: sdwan_overlay_orchestration_apps,
  });
  return head(routes);
}

/**
 * /sdwan/rules
 */
function getRulesRoute() {
  const routes = getRouteObjectsFromApps({ apps: sdwan_rules_apps });
  return head(routes);
}
