export const logfetchTransHelper = {
  // buttons
  review: gettext('Review'),
  pause: gettext('Pause'),
  resume: gettext('Resume'),
  cancel: gettext('Cancel'),
  waitAppr: gettext('Waiting for approval'),
  appr: gettext('Approved'),
  paused: gettext('Paused'),
  rejected: gettext('Rejected'),
  done: gettext('Done'),
  delete: gettext('Delete'),

  // columns
  adom: 'ADOM',
  vdom: 'VDOM',
  device: gettext('Device'),
  field: gettext('Log Field'),
  oper: gettext('Match Criteria'),
  value: gettext('Value'),
  'create-time': gettext('Request Time'),
  hostname: gettext('Host/Server IP'),
  user: gettext('User'),
  state: gettext('Status'),
  action: gettext('Action'),
  name: gettext('Name'),
  'server-ip': gettext('Server IP'),
  'server-adom': gettext('Server ADOM'),
  'client-adom': gettext('Local ADOM'),

  create_new: gettext('Create New Profile'),
  edit: gettext('Edit Profile'),
  del_profile: gettext('Are you sure you want to delete the selected profile?'),
  fetch: gettext('Fetch Logs'),
  req_fetch: gettext('Request Fetch'),
  sync_dev: gettext('Sync Devices'),
  save_fail: gettext('fail to save the profile.'),
  recv_req: gettext('Received Request'),
  fetch_req: gettext('Fetch Request'),
  review_req: gettext('Review Request'),

  no_data: gettext('Please input data.'),
  invalid_name: gettext('Please input a valid name.'),
  empty_name: gettext('Name is required.'),
  invalid_ipv4: gettext('Please input a valid IPv4 address.'),
  invalid_char: gettext('invalid character.'),
  empty_user_name: gettext('Please input user name.'),
  passwd_not_matched: gettext('Passwords do not matched.'),
  no_passwd: gettext('Please input password.'),
};
