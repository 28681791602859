import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';

// reducers
import { devicesReducer, vdomsReducer } from './devices_vdoms/reducer';
import { assignedPkgReducer } from './assigned_pkg/reducer';
import { checksumReducer } from './checksums/reducer';
import devicesAssetsReducer from './assets/slice';
import { fazDelNotificationReducer } from './faz_del/reducers';
import ptReducer from './pt/slice';
import saseReducer from './sase/slice';
import dvmConfigReducer from './dvm_config/slice';

// sagas
import { watchDeviceAction } from './devices_vdoms/saga';
import { watchDvmChecksum } from './checksums/saga';
import { watchDevicesFirmware } from './firmware/saga';
import { watchDevicesLicense } from './license/saga';
import { watchDevicesPsirt } from './psirt/saga';
import { watchDevicesAssignedPkg } from './assigned_pkg/saga';
import { watchRemoteFAZ } from './remote_faz/saga';
import { watchUnregDeviceAction } from './unreg_device/saga';
import { watchDeviceAuthNeededAction } from './auth_needed/saga';

export * from './selectors';
export * from './util';

export const dvmReducer = combineReducers({
  devices: devicesReducer,
  vdoms: vdomsReducer,
  assignedpkg: assignedPkgReducer,
  fazDel: fazDelNotificationReducer,
  assets: devicesAssetsReducer,
  checksums: checksumReducer,
  dvmConfig: dvmConfigReducer,
  pt: ptReducer,
  sase: saseReducer,
});

/**
 * Root saga. This module should have no knowledge about the sagaMiddleware and fiStore
 */
const sagaWatchers = [
  watchDeviceAction,
  watchDvmChecksum,
  watchDevicesFirmware,
  watchDevicesLicense,
  watchDevicesPsirt,
  watchDevicesAssignedPkg,
  watchRemoteFAZ,
  watchUnregDeviceAction,
  watchDeviceAuthNeededAction,
];
export function* deviceRootSaga() {
  yield all(sagaWatchers.map(fork));
}
