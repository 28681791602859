import { FitAddon } from 'xterm-addon-fit';
import { debounce } from 'fiutil';
import { callIfMeets, Disposable } from './util';

export default class FitToContainerAddon extends Disposable {
  constructor({ observeEl, shouldFit = Boolean, debounceMs = 300 } = {}) {
    super();

    this.fitAndFocus = this.fitAndFocus.bind(this);
    this.afterXtermReady = this.afterXtermReady.bind(this);
    this.activate = this.activate.bind(this);

    this._xterm_DOM_ready = false;
    this._resizeListeners = new Set();
    this.addDisposable(() => this._resizeListeners.clear());

    this.addDisposable(
      function _observeResize() {
        let observer = new ResizeObserver(
          debounce(
            callIfMeets(
              () => this._xterm_DOM_ready,
              shouldFit
            )(this.fitAndFocus),
            debounceMs
          )
        );

        observer.observe(observeEl);

        return () => {
          observer.unobserve(observeEl);
          observer = null;
        };
      }.call(this)
    );
  }

  activate(xterm) {
    this.xterm = xterm;

    const fitAddon = new FitAddon();
    xterm.loadAddon(fitAddon);

    this.fitAddon = fitAddon;
  }

  addResizeListener(callback) {
    this._resizeListeners.add(callback);
    return this.removeResizeListener.bind(this, callback);
  }

  removeResizeListener(callback) {
    this._resizeListeners.delete(callback);
  }

  fitAndFocus() {
    this.xterm.resize(this.xterm.cols, 1);
    this.fitAddon.fit();
    this.xterm.focus();

    const dimension = {
      cols: this.xterm.cols,
      rows: this.xterm.rows,
    };
    this._resizeListeners.forEach((fn) => fn(dimension));

    return dimension;
  }

  afterXtermReady() {
    this._xterm_DOM_ready = true;
    return this.fitAndFocus();
  }
}
