import { createSelector } from '@reduxjs/toolkit';
import { getSessionAdomOid } from '../../session/adom/selectors';
import { get } from 'lodash';

export const getSaseDevicesState = (state) => get(state, 'dvm.sase');

export const getSaseDevicesInAdom = createSelector(
  getSaseDevicesState,
  getSessionAdomOid,
  (saseState, adomOid) => {
    return get(saseState, [adomOid], {});
  }
);

export const getSaseDevicesData = createSelector(
  getSaseDevicesInAdom,
  (saseDevicesInAdom) => {
    return get(saseDevicesInAdom, 'data', {});
  }
);

export const getSaseDeviceStatus = createSelector(
  getSaseDevicesInAdom,
  (saseDevicesInAdom) => {
    return get(saseDevicesInAdom, 'status');
  }
);

export const getSaseDevicesLoaded = createSelector(
  getSaseDevicesData,
  (data) => {
    return data.loaded && !data.loading;
  }
);

export const getSaseDevicesMap = createSelector(getSaseDevicesData, (data) => {
  return get(data, 'byId', {});
});

export const getSaseDevicesList = createSelector(getSaseDevicesMap, (byId) => {
  return Object.values(byId || {});
});
