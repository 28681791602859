import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'rh_util_hooks';
import { mibToBytes, kbToBytes } from './utils';
import { fiFmgHttp } from 'fi-http';

const topURL = '/cli/global/exec/top';
const ioTopURL = '/cli/global/exec/iotop';

const getData = (isIoTop) => {
  const req = {
    method: 'exec',
    params: [
      {
        url: isIoTop ? ioTopURL : topURL,
        data: {
          'top-n': 50,
          ...(isIoTop ? {} : { 'order-by': 'cpu-usage' }),
        },
      },
    ],
  };
  return fiFmgHttp.query(req).then((resp) => {
    return resp?.[0]?.data || {};
  });
};

const formatData = (topData, ioTopData, rowKey) => {
  let dataMap = {};
  ioTopData?.lists?.forEach((x) => {
    dataMap[x.pid] = {
      ...x,
      [rowKey]: x.pid,
      disk_read: x.disk_read,
      disk_write: x.disk_write,
      _disk_read_bytes: kbToBytes(x.disk_read),
      _disk_write_bytes: kbToBytes(x.disk_write),
    };
  });
  topData?.lists?.forEach((x) => {
    let existingData = dataMap[x.pid] || {};
    dataMap[x.pid] = {
      ...existingData,
      ...x,
      [rowKey]: x.pid,
      _res_bytes: mibToBytes(x.res),
      _virt_bytes: mibToBytes(x.virt),
    };
  });
  let list = Object.values(dataMap);
  return {
    list,
    summary: topData.summary || {},
    ioSummary: ioTopData.summary || {},
  };
};

export const useDataSource = ({
  rowKey,
  NumLoadsToKeep,
  DataInterval,
  isPaused,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState({});
  const [data, setData] = useState([]);
  const [ioSummaryArr, setIoSummaryArr] = useState([]);

  const loadData = useCallback(async () => {
    try {
      let topPromise = getData();
      let ioTopPromise = getData(true);
      const [topData, ioTopData] = await Promise.all([
        topPromise,
        ioTopPromise,
      ]);
      let { list, summary, ioSummary } = formatData(topData, ioTopData, rowKey);
      setData((oldData) => {
        let newData = [...oldData];
        if (newData.length === NumLoadsToKeep) {
          newData.shift();
        }
        newData.push(list);
        return newData;
      });
      setSummary(summary);
      setIoSummaryArr((oldData) => {
        let newData = [...oldData];
        if (newData.length === NumLoadsToKeep) {
          newData.shift();
        }
        newData.push(ioSummary);
        return newData;
      });
    } catch (err) {
      // eslint-disable-next-line
      console.error('EXTERNAL DATA ERR', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  useInterval(loadData, isPaused ? null : DataInterval * 1000);

  return [isLoading, summary, data, ioSummaryArr];
};
