import { useField } from 'formik';
import { FmkObjSSelect2 } from './FmkObjSSelect2';
import React, { forwardRef } from 'react';
import { NwIcon, NwButton } from '@fafm/neowise-core';
import { useValidateFn } from './util/hook';
import { callAll } from './util/util';
import './FmkSelectAdd.less';
import { isNil } from 'lodash';

export const FmkSelectAdd = forwardRef(
  ({ name, validate, onSelect, onAdd, noAdd, ...rest }, ref) => {
    validate = useValidateFn(rest, validate);
    const [, { value }, { setValue, setTouched }] = useField({
      name,
      validate,
    });

    const setFieldValOnSelect = ({ $val, $selected }) => {
      setTouched(true, false);
      setValue(isNil($val) ? $selected?.id : $val);
    };
    const select = callAll(setFieldValOnSelect, onSelect);

    return (
      <div className='select-add'>
        <FmkObjSSelect2
          name={name}
          value={value}
          onSelect={select}
          {...rest}
          ref={ref}
        />
        {(!noAdd && (
          <NwButton
            name='add'
            className='tw-w-7 tw-ml-1'
            onClick={onAdd}
            title={gettext('Add')}
            aria-label={gettext('Add')}
          >
            <NwIcon name={'add'} />
          </NwButton>
        )) ||
          ''}
      </div>
    );
  }
);
FmkSelectAdd.displayName = 'FmkSelectAdd';
