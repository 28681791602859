import { useSelector } from 'react-redux';
import { fiFmgHttp } from 'fi-web/fi-http';
import { NwButton, NwIconButton, NwIcon } from '@fafm/neowise-core';
import { ProForm, ProAI } from '@fafm/neowise-pro';
import ReactMarkdown from 'react-markdown';
import { fiStore, fiSession, fiStoreTheme } from 'fistore';
import { copilotActions } from 'fistore/copilot';
const { Header, HeaderActions, Body, Footer } = ProForm;
const { MessageBox, MessageRoles, useTypewriter } = ProAI;
const { getIsDarkTheme } = fiStoreTheme;

const FORTIAI_ENABLE = 1;
const EnableFortiAiRoleButton = ({ userName }) => {
  const onClick = async () => {
    await fiFmgHttp.query({
      method: 'update',
      params: [
        {
          url: `/cli/global/system/admin/user/${userName}`,
          data: {
            fortiai: FORTIAI_ENABLE,
            userid: userName,
          },
        },
      ],
    });

    fiStore.dispatch(fiSession.setAiUser(FORTIAI_ENABLE));
  };

  return (
    <NwButton type='primary' onClick={onClick}>
      <div>{gettext('Enable FortiAI for Myself')}</div>
    </NwButton>
  );
};

const PurchaseButton = () => {
  const onClickHandler = () => {
    window.open('https://support.fortinet.com/welcome/#/', '_blank');
  };
  return (
    <NwButton
      type='default'
      onClick={onClickHandler}
      prefix={<NwIcon name='shopping-cart'></NwIcon>}
    >
      {gettext('Purchase FortiAI License')}
    </NwButton>
  );
};
export const FortiAIErrorMessages = ({ userName, canEnable, message }) => {
  const hasAiLicense = useSelector(fiSession.getHasAiLicense);
  const isDarkTheme = useSelector(getIsDarkTheme);
  const { text: displayText } = useTypewriter(message, 10);
  return (
    <div className='tw-flex tw-flex-col tw-h-full ai-advisor tw-text-neutral-1000'>
      <Header
        actionSlot={
          <HeaderActions>
            <NwIconButton
              name='close'
              label={gettext('Close')}
              onClick={() => {
                fiStore.dispatch(copilotActions.setIsOpen(false));
              }}
            ></NwIconButton>
          </HeaderActions>
        }
      >
        <span className='tw-text-neutral-1000'>
          {gettext('FortiAI Assistant')}
        </span>
      </Header>
      <Body>
        <MessageBox
          role={MessageRoles.SYSTEM}
          avatar={
            <NwIcon
              library='fafm-48px'
              name='fortiai-bot'
              style={{
                fontSize: '48px',
                color: isDarkTheme
                  ? 'rgb(var(--nw-color-cyan-700))'
                  : 'rgb(var(--nw-color-cyan-300))',
              }}
            />
          }
        >
          <ReactMarkdown className='markdown prose tw-w-full tw-break-words tw-leading-tight'>
            {displayText}
          </ReactMarkdown>
          {canEnable ? <EnableFortiAiRoleButton userName={userName} /> : null}
          {!hasAiLicense ? <PurchaseButton /> : null}
        </MessageBox>
      </Body>
      <Footer></Footer>
    </div>
  );
};
