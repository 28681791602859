import React from 'react';
import AdomWorkspaceEdit from './AdomWorkspaceEdit';
import { FmkSwitch, FmkCheckbox, FmkRadioBtnGroup } from 'rc_form';
import { NwProInputRow, NwProLine } from 'rc_layout';
import { getSysConfig, autoId } from './util';
import { useFormikContext } from 'formik';

const AdomFmgEdit = ({ isView = false }) => {
  const { values } = useFormikContext();
  const sysConfig = getSysConfig();
  const showWorkspaceEdit =
    sysConfig.workspace_mode === MACROS.SYS.WORKSPACE_M_PERADOM &&
    sysConfig.hasFmgFeature &&
    values.name !== 'Chassis';
  const isRootAdom = values.name === MACROS.DVM.DVM_ADOM_ROOT_NAME;
  return (
    <>
      {!isRootAdom && (
        <NwProInputRow label={gettext('Mode')}>
          <FmkRadioBtnGroup
            name={'backup'}
            automationId={autoId('backup')}
            choices={[
              { text: gettext('Normal'), id: 0 },
              { text: gettext('Backup'), id: 1 },
            ]}
            disabled={isView}
          />
        </NwProInputRow>
      )}
      {(values.backup === 0 || isRootAdom) && (
        <>
          <NwProInputRow label={gettext('Central Management')}>
            <NwProLine>
              {[
                { key: 'vpn', text: 'VPN', has: true },
                { key: 'fap', text: 'FortiAP', has: !values.is_fpx },
                { key: 'fsw', text: 'FortiSwitch', has: !values.is_fpx },
              ].map((p) => (
                <React.Fragment key={p.key}>
                  {p.has && (
                    <div className='fi-col-4'>
                      <FmkCheckbox
                        name={p.key}
                        automation-id={autoId(`${p.key}`)}
                        disabled={isView}
                      >
                        {p.text}
                      </FmkCheckbox>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </NwProLine>
          </NwProInputRow>
        </>
      )}
      {!!showWorkspaceEdit && <AdomWorkspaceEdit />}
      {(values.backup === 0 || isRootAdom) && (
        <>
          <NwProInputRow
            label={gettext('Default Device Selection for Install')}
          >
            <FmkRadioBtnGroup
              name={'install_scope'}
              automationId={autoId('install_scope')}
              choices={[
                { id: false, text: gettext('Select All') },
                { id: true, text: gettext('Deselect All') },
              ]}
              disabled={isView}
            />
          </NwProInputRow>

          <NwProInputRow
            label={gettext('Perform Policy Check Before Every Install')}
          >
            <FmkSwitch
              name={'policy_check'}
              automation-id={autoId('policy_check')}
              disabled={isView}
            />
          </NwProInputRow>

          {!!values.policy_check && (
            <NwProInputRow
              label={gettext('Action When Conflicts Occur During Policy Check')}
            >
              <FmkRadioBtnGroup
                name={'policy_check_stop'}
                automationId={autoId('policy_check_stop')}
                choices={[
                  { id: false, text: gettext('Continue Installation') },
                  { id: true, text: gettext('Stop Installation') },
                ]}
                disabled={isView}
              />
            </NwProInputRow>
          )}

          <NwProInputRow
            label={gettext('Auto-Push Policy Packages When Device Back Online')}
          >
            <FmkSwitch name={'auto_push'} automationId={autoId('auto_push')} />
          </NwProInputRow>
        </>
      )}
    </>
  );
};

AdomFmgEdit.displayName = 'AdomFmgEdit';
export default AdomFmgEdit;
