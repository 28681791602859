import { useField, useFormikContextSelector } from 'formik';
import React, { forwardRef } from 'react';
import neowiseWrapper from '@fafm/neowise-wrapper';
import { useValidateFn } from './util/hook';
import { callAll } from './util/util';

export const neowiseFormikField = (
  tagName,
  tagProps,
  useValidate = useValidateFn
) => {
  let NwComp = tagName;
  // TODO: to remove all wrapper in future
  if (typeof tagName === 'string' && tagName.includes('-')) {
    NwComp = neowiseWrapper(tagName);
    NwComp.displayName = `Fmk${tagName}`;
  }

  return forwardRef(
    (
      {
        name,
        'automation-id': propAutoId,
        automationId,
        validate,
        children,
        onChange,
        onBlur,
        onNwBlur,
        onNwChange,
        ...rest
      },
      ref
    ) => {
      validate = useValidate(rest, validate);
      const submitCount = useFormikContextSelector((val) => val.submitCount);
      const [
        { onBlur: onFmkBlur, onChange: onFmkChange, value, ...restFiledProps },
        { error, touched },
      ] = useField({ name, validate, type: rest.type });
      return (
        <NwComp
          invalid={error && (touched || !!submitCount)}
          automation-id={propAutoId || automationId}
          value={value ?? ''}
          {...restFiledProps}
          {...rest}
          ref={ref}
          {...tagProps}
          onChange={callAll(onFmkChange, onChange, onNwChange)}
          //can remove onNwChange once we stop using old Nw-components
          onNwChange={callAll(onFmkChange, onChange, onNwChange)}
          onBlur={callAll(onFmkBlur, onBlur, onNwBlur)}
        >
          {children}
        </NwComp>
      );
    }
  );
};
