import EventSubject from './event_subject';

import Socket from './socket';
import { Queue } from './queue';
import ReConnect from './reconnect';
import { Observable, UnSubCode } from './observable';
import { MethodCollection } from './method';

import { RequestFormat } from './request_format';
import WSRequest from './request';
import type { LogWS } from 'chrome_extension_debugger/captures/ws_request_log';

const MessageType = [
  'added',
  'changed',
  'removed',
  'result',
  'update',
  'error',
  'notify',
];

export default class WSClient {
  // eslint-disable-next-line
  private _eventSubject: EventSubject;
  private methods: MethodCollection;
  private socket: Socket;
  private queue: Queue;
  private wslog: LogWS;

  constructor(socket: Socket, wslog: LogWS) {
    this.wslog = wslog;

    this.socket = socket;

    this.methods = new MethodCollection(wslog);

    this._eventSubject = new EventSubject();

    this._eventSubject.addListener('result', (data: any) =>
      this.methods.process(data)
    );
    this._eventSubject.on('connect:error', () => {
      this.methods.error('connect error');
      this.methods.clear();
    });

    this.queue = client_init(this.socket, (msg: string, args?: any) => {
      this.eventSubject.publish(msg, args);
    });
  }
  get eventSubject() {
    return this._eventSubject;
  }
  connect() {
    this.socket.open();
  }
  disconnect() {
    this.socket.close();
  }
  createRequest(request: RequestFormat): WSRequest {
    if (this.socket.disconnected()) {
      const ob = new Observable((observer) => {
        observer.error('disconnected');
        return () => {};
      });

      return new WSRequest(ob, () => {});
    }

    const ob = this.methods.createObserver(
      request.id,
      () => {
        this.wslog.send(request.id, request);
        this.pushQueue(request);
      },
      (code) => {
        if (code !== UnSubCode.Complete) {
          this.cancelRequest(request.id);
        }
      }
    );

    return new WSRequest(ob, () => this.cancelRequest(request.id));
  }
  cancelRequest(requestId: string) {
    this.wslog.cancel(requestId);

    this.pushQueue({
      msg: 'cancel',
      id: requestId,
      method: '',
      params: {},
    });

    this.methods.remove(requestId);
  }

  private pushQueue(request: RequestFormat) {
    this.queue.push(request);
  }
}

function client_init(
  socket: Socket,
  publish: (msg: string, args?: any) => void
) {
  let status = '';
  const queue = new Queue((message: any) => {
    if (status === 'connected') {
      socket.send(message);
      return true;
    }
    return false;
  });

  socket.onOpen(() => {
    status = 'connected';
    queue.process();
    publish('connected');
  });

  const reconnect = new ReConnect(
    () => socket.open(),
    (msg: string) => publish(msg)
  );

  socket.onOpen(() => {
    reconnect.clear();
  });
  socket.onConnectError(() => {
    reconnect.retry();
  });
  socket.onClose(() => {
    if (socket.closed) {
      status = 'disconnected';
      queue.empty();
      publish('disconnect');
    } else {
      reconnect.retry();
    }
  });
  socket.onMessage((message: any) => {
    if (MessageType.indexOf(message.msg) < 0) return;

    publish(message.msg, message);
  });

  return queue;
}
