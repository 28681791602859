import { getZonePath } from 'fi-datetime';
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import _ from 'lodash';
import { NwInput } from '@fafm/neowise-core';
import { TimePeriods } from './TimePeriods';
import { useCustomTimeDialog } from './CustomTimeDialog';
import { getTimeRange, formatDate } from './helpers';
import './TimeSelector.less';

/*
  - onTimePeriodChange (required): triggered when timePeriod or lastN changed.
                                  (timePeriod, timeRange) => *
  - initialTimePeriod (required): the initial selected timePeriod object.
                                  see ./TimePeriods.js for formats.
  - timeFormat (optional): for formatting time string, default 'YYYY-MM-DD HH:mm:ss'
  - hideTimeRange (optional): whether or not to hide time range display, default false
  - dispTimeRange (optional): use when you want to display a different time range.
                            { start: String, end: String }
  - hideTimeSelection (optional): whether to hide the time selection
  - customizeTimePeriods (optional): add or remove time periods from the default time periods
  - timeRangeDependencies (optional): dependencies to retrigger time range calculation
*/
export const TimeSelector = ({
  onTimePeriodChange,
  initialTimePeriod,
  timeFormat,
  hideTimeRange,
  dispTimeRange,
  customRangeFields,
  hideTimeSelection,
  customizeTimePeriods,
  timeRangeDependencies = [],
  showBorder,
  useSysTz = false, // should use tz but don't want to break old behaviour.
}) => {
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    initialTimePeriod || {}
  );
  const [timeRange, setTimeRange] = useState({ start: '', end: '' });
  const timePeriodOpts = useMemo(() => {
    if (_.isFunction(customizeTimePeriods))
      return customizeTimePeriods(TimePeriods);
    return TimePeriods;
  }, [customizeTimePeriods]);

  const isInitialChange = useRef(true);
  useEffect(() => {
    if (initialTimePeriod?.key) {
      isInitialChange.current = true;
      setSelectedTimePeriod(initialTimePeriod);
    }
  }, [initialTimePeriod]);

  useEffect(() => {
    if (!selectedTimePeriod?.key) {
      return;
    }
    const timeZone = useSysTz ? getZonePath() : null;
    let timeRange = getTimeRange(selectedTimePeriod, timeFormat, timeZone);
    setTimeRange(timeRange);
    if (isInitialChange.current) {
      isInitialChange.current = false;
    } else {
      onTimePeriodChange &&
        onTimePeriodChange({ ...selectedTimePeriod }, timeRange);
    }
  }, [selectedTimePeriod, useSysTz, ...timeRangeDependencies]);

  const onLastNChange = useCallback(
    _.debounce((evt) => {
      evt.preventDefault();
      let val = evt.target.value - 0;
      if (selectedTimePeriod.lastN !== val) {
        setSelectedTimePeriod((prevState) => ({
          ...prevState,
          lastN: val,
        }));
      }
    }, 500),
    [selectedTimePeriod]
  );

  const dispTimeRangeObj = useMemo(() => {
    return (
      dispTimeRange || {
        start: timeRange.start,
        end: timeRange.end,
      }
    );
  }, [timeRange, dispTimeRange, timeFormat]);

  const customTimeDialog = useCustomTimeDialog(
    {
      customPeriod: dispTimeRangeObj,
      onAfterSubmit: ({ start, end }) => {
        setSelectedTimePeriod({
          ...timePeriodOpts.find((x) => x.key === 'custom'),
          start: formatDate(start, timeFormat),
          end: formatDate(end, timeFormat),
        });
      },
      fields: customRangeFields,
      useSysTz,
    },
    [dispTimeRangeObj, timeFormat, useSysTz]
  );

  const btnType = showBorder ? 'default' : 'text';

  return (
    <div className='rc-time-selection'>
      {!hideTimeSelection && (
        <>
          <nw-dropdown class='time-selection'>
            <nw-button
              class='selection-btn'
              type={btnType}
              size='medium'
              slot='trigger'
              caret
            >
              <nw-icon slot='prefix' name='schedule'></nw-icon>
              {selectedTimePeriod.label}
            </nw-button>
            <nw-menu>
              {timePeriodOpts.map((tp) => {
                return (
                  <nw-menu-item
                    key={tp.key}
                    onClick={() => {
                      if (tp.key === 'custom') {
                        customTimeDialog.ref.current.show();
                      } else {
                        setSelectedTimePeriod({ ...tp });
                      }
                    }}
                  >
                    {tp.label}
                  </nw-menu-item>
                );
              })}
            </nw-menu>
          </nw-dropdown>
          {selectedTimePeriod.key?.startsWith('n') && (
            <>
              <span className='last-n-Text'>&nbsp;{'N ='}</span>
              <NwInput
                className='last-n'
                value={selectedTimePeriod.lastN}
                type='number'
                min={1}
                onChange={onLastNChange}
              />
            </>
          )}
        </>
      )}
      {!hideTimeRange && (
        <span className='time-range'>{`${dispTimeRangeObj.start} - ${dispTimeRangeObj.end}`}</span>
      )}
      {customTimeDialog.portal}
    </div>
  );
};
