import { useCallback, useEffect, useMemo, useState } from 'react';
import { fiAdom } from 'fi-session';
import { commandItemType } from '../device_commands/commandType';
import { fiDvmListViewType, fiFoldersRedux } from 'fi-dvm';
import { FolderFirmwareTemplate } from './modals/FolderFirmwareTemplate';
import {
  getDeviceIcon,
  handleFortiAP,
  handleFortiExt,
  handleFortiSwitch,
  ROWTYPEDEVICE,
  ROWTYPEFOLDER,
  ROWTYPEFORTIAP,
  ROWTYPEFORTIEXTENDER,
  ROWTYPEFORTISWITCH,
} from './util';

import { ProTable, ProToolkit } from '@fafm/neowise-pro';
import { NwIcon } from '@fafm/neowise-core';
import { viewCommands } from '../device_commands/viewCommands';
import { fiMessageBox } from 'fi-messagebox';
import { fiDeviceDataLoader } from 'ra_device_util';
import { getFwmProf, setFwmProf } from 'fi-dvm-fwm-prof';
import { fiStoreAdomLocalConfig, fiStore, dispatch } from 'fistore';
import { fiDvmActionsId } from 'fi-actions';
import { get, isArray, isFunction } from 'lodash';
import cn from 'classnames';
import { PageLoading } from 'ra-shared-components';
import { go } from 'fistore/routing/slice';
import { useReload } from 'react_hooks/rh_util_hooks';
import { useSelector } from 'react-redux';
import { getSessionAdomData } from 'fistore/session/adom/selectors';

const VIEWTYPE_FABRIC = 'fabric';
const VIEWTYPE_FLAT = 'flat';
const VIEWTYPE_DEVICETYPE = 'device';

const NONE = 'None';

const getIcon = (data) => {
  const type = data.rowData.rowType;
  if (type === ROWTYPEDEVICE) {
    return getDeviceIcon(data.rowData);
  }
  if (type === ROWTYPEFOLDER) {
    return { icon: 'folder' };
  }
  if (type === ROWTYPEFORTIEXTENDER) {
    if (data.rowData._is_model)
      return {
        icon: 'fortiextender',
        lib: 'fgt-products',
        iconColor: 'color-grey',
      };
    const status = get(data, 'rowData.status');
    const iconColor = status ? 'color-green' : 'color-orange';
    return {
      icon: 'fortiextender',
      lib: 'fgt-products',
      iconColor: iconColor,
    };
  }
  if (type === ROWTYPEFORTIAP) {
    const iconProps = get(data, 'rowData.state.iconProps', {});
    return {
      icon: iconProps.name || 'wifi',
      lib: iconProps.library || 'fafm',
      iconColor: iconProps.className,
    };
  }
  if (type === ROWTYPEFORTISWITCH) {
    const iconProps = get(data, 'rowData.status.fswIcon', {});
    return {
      icon: iconProps.name || 'fortiswitch',
      lib: iconProps.library || 'fafm',
      iconColor: iconProps.classes,
    };
  }
  return {};
};

const isCnfDevice = (device) => device?.iscnf;

const filterByFortigates = (list, mapFn) => {
  const filtered = list.filter(
    (obj) => obj?.rowType === ROWTYPEDEVICE && !isCnfDevice(obj)
  );

  if (isFunction(mapFn)) {
    return filtered.map(mapFn);
  }

  return filtered;
};

const getFirmwareTargets = (selectedFortiGates) => {
  return selectedFortiGates.map((target) => {
    if (target._pData) {
      const pData = target._pData;
      return {
        oid: pData.did,
        name: pData.name,
        vdom_oid: target.vid,
        vdom: target.name,
      };
    } else {
      return {
        oid: target.did,
        name: target.name,
        vdom_oid: MACROS.DVM.CDB_DEFAULT_ROOT_OID,
        vdom: MACROS.DVM.DVM_ADOM_FOS_ROOT_NAME,
      };
    }
  });
};

const devStr = (fgts) =>
  fgts.reduce((acc, curr) => {
    return `${acc} ${curr?.name}`;
  }, '');

async function assignDevsToFirmware(template, devices) {
  const selectedFortiGates = getFirmwareTargets(devices);
  try {
    const scopeMembers = [
      ...(template['scope member'] || []),
      ...selectedFortiGates,
    ];
    await setFwmProf(
      fiAdom.current(),
      { ...template, 'scope member': scopeMembers },
      template.name
    );
    fiMessageBox.show(
      gettext('Successfully assigned %s template to %s').printf([
        template.name,
        devStr(selectedFortiGates),
      ]),
      'success',
      0
    );
  } catch {
    fiMessageBox.show(
      gettext('Failed to assign the template to the devices'),
      'danger',
      0
    );
  }
}

export const FolderTable = ({
  reload,
  folderTree: allFolders,
  folderIdMap,
  selectedFolderPath: stateFolder,
  setSelectedFolderPath,
}) => {
  const [allDisplayData, setAllDisplayData] = useState(null);
  const [allDevices, setAllDevices] = useState([]);
  const [fullDeviceSet, setFullDeviceSet] = useState([]);
  const [otherDevices, setOtherDevices] = useState({});
  const [firmwareTemplates, setFirmwareTemplates] = useState([]);
  const [viewType, setViewType] = useState(VIEWTYPE_FABRIC);
  const adom = useSelector(getSessionAdomData);

  const [reloadFirmwareTemplate, setReloadFirmwareTemplate] = useReload();
  // Unassign Firmware
  const unassignDevsFromFirmware = useCallback(
    async (devices) => {
      try {
        // Remove devices from template;
        const promises = [];
        const templatesToRemoveDevs = {};
        const devNameMap = {};
        for (let dev of allDevices) {
          devNameMap[dev.name] = dev;
        }
        // Create a mapping to see which templates should be updated and what scope members they include
        for (const dev of devices) {
          const tmpl = devNameMap[dev?.name]?.firmwareTemplate;
          if (tmpl) {
            let scopeMembers = [];
            if (templatesToRemoveDevs[tmpl?.name])
              scopeMembers = templatesToRemoveDevs[tmpl?.name].scopeMembers;
            else {
              scopeMembers = tmpl['scope member'] || [];
              templatesToRemoveDevs[tmpl?.name] = {
                scopeMembers,
                data: tmpl,
                removed: [],
              };
            }

            templatesToRemoveDevs[tmpl?.name].scopeMembers =
              scopeMembers.filter((mem) => mem?.name !== dev?.name);
            templatesToRemoveDevs[tmpl?.name].removed.push({ name: dev.name });
          }
        }

        // For each template, update with the new scope members
        for (const value of Object.values(templatesToRemoveDevs)) {
          const data = value?.data;
          const prom = setFwmProf(
            adom,
            { ...data, 'scope member': value.scopeMembers },
            data.name
          );
          promises.push(prom);
        }

        await Promise.all(promises);
        for (const [k, v] of Object.entries(templatesToRemoveDevs)) {
          fiMessageBox.show(
            gettext('Successfully unassigned %s from template %s').printf([
              devStr(v?.removed),
              k,
            ]),
            'success',
            0
          );
        }
      } catch {
        fiMessageBox.show(
          gettext('Failed to unassign the devices from template'),
          'danger',
          0
        );
      }
    },
    [allDevices, adom]
  );

  // Get Folder selected from left Panel
  const getSelectedFolder = useCallback(() => {
    const folder_path = stateFolder;
    if (allFolders.length === 0) return null; //guard (at least one default folder will exist)
    let selectedPathId;
    if (!folder_path || folder_path.length === 0) {
      return allFolders[0];
    } else {
      //grab the last folder
      selectedPathId = folder_path[folder_path.length - 1];
      let folder = folderIdMap[selectedPathId];
      if (folder) return folder;
      return allFolders[0];
    }
  }, [stateFolder, allFolders, folderIdMap]);

  // Fetch Devices from remote
  useEffect(() => {
    async function load() {
      const { fiDvmAssetsService } = await import(
        'react_apps/ra_fap_fsw_fext/dvm_assets_service'
      );
      const [lst, fullset = [], allAssets = {}] = await Promise.all([
        getFwmProf(adom),
        fiDeviceDataLoader.getManagedDevices(),
        fiDvmAssetsService.loadAllAssets(),
      ]);
      setOtherDevices(allAssets);
      setFirmwareTemplates(lst);
      setFullDeviceSet(fullset);
    }

    load();
  }, [reloadFirmwareTemplate, adom]);

  // Get Display Data
  useEffect(() => {
    const devToTemplate = {};
    for (let template of firmwareTemplates) {
      template?.['scope member']?.forEach(
        (dev) => (devToTemplate[dev.oid] = template)
      );
    }
    let currentFolder = getSelectedFolder();
    if (!currentFolder) {
      setAllDevices([]);
      return;
    }
    //get device members
    let devSubsetIds = currentFolder.devMemberList.map((dev) => dev.oid);
    let devSubset = fullDeviceSet
      .filter((dev) => devSubsetIds.includes(parseInt(dev._fiDeviceId)))
      .map((dev) => ({
        ...dev,
        rowType: ROWTYPEDEVICE,
        firmwareTemplate: devToTemplate[dev.oid],
        subDev: otherDevices?.byId[dev.oid],
      })); //TODO: this might be slow big O(mn)
    setAllDevices(devSubset);
  }, [otherDevices, firmwareTemplates, fullDeviceSet, getSelectedFolder]);

  useEffect(() => {
    let currentFolder = getSelectedFolder();
    if (!currentFolder) {
      setAllDisplayData([]);
      return;
    }
    //get folder members
    let subFolderIds = currentFolder.folderMemberList.map((fd) => fd.oid);
    let folderSubset = subFolderIds
      .map((id) => folderIdMap[id])
      .map((fol) => ({ ...fol, rowType: ROWTYPEFOLDER, children: undefined })); //TODO: this might be slow big O(mn)
    if (viewType === VIEWTYPE_FABRIC) {
      let displayDevice = [];
      for (let dev of allDevices) {
        const dev_data = {
          ...dev,
          id: dev.oid,
          serialnum: dev.sn,
          firmware_os_version: dev.firmware,
        };
        if (dev.subDev) {
          const allFap = Object.values(dev.subDev).reduce((prev, curr) => {
            prev.push(
              ...Object.values(curr.fap || {}).map((ap) =>
                handleFortiAP(ap, dev)
              )
            );
            return prev;
          }, []);
          const allFsw = Object.values(dev.subDev).reduce((prev, curr) => {
            prev.push(
              ...Object.values(curr.fsw || {}).map((sw) =>
                handleFortiSwitch(sw, dev)
              )
            );
            return prev;
          }, []);
          const allFext = Object.values(dev.subDev).reduce((prev, curr) => {
            prev.push(
              ...Object.values(curr.fext || {}).map((ext) =>
                handleFortiExt(ext, dev)
              )
            );
            return prev;
          }, []);
          dev_data.children = [...allFap, ...allFsw, ...allFext];
        }
        displayDevice.push(dev_data);
      }

      setAllDisplayData([...displayDevice, ...folderSubset]);
      return;
    }

    const deviceSubset = [];
    const fortiAPSubset = [];
    const fortiSwitchSubset = [];
    const fortiExtSubset = [];
    for (let dev of allDevices) {
      deviceSubset.push({ ...dev, id: dev.oid, serialnum: dev.sn });
      if (dev.subDev) {
        Object.values(dev.subDev).forEach((curr) => {
          fortiAPSubset.push(
            ...Object.values(curr.fap || {}).map((ap) => handleFortiAP(ap, dev))
          );
        });
        Object.values(dev.subDev).forEach((curr) => {
          fortiSwitchSubset.push(
            ...Object.values(curr.fsw || {}).map(handleFortiSwitch)
          );
        });
        Object.values(dev.subDev).forEach((curr) => {
          fortiExtSubset.push(
            ...Object.values(curr.fext || {}).map(handleFortiExt)
          );
        });
      }
    }

    if (viewType === VIEWTYPE_FLAT) {
      setAllDisplayData([
        ...deviceSubset,
        ...fortiAPSubset,
        ...fortiSwitchSubset,
        ...fortiExtSubset,
        ...folderSubset,
      ]);
    } else if (viewType === VIEWTYPE_DEVICETYPE) {
      let displayDevice = [
        {
          id: 'devices',
          isGroup: true,
          groupName: gettext('Devices'),
          children: deviceSubset,
        },
        {
          id: 'fortiap',
          isGroup: true,
          groupName: gettext('FortiAP'),
          children: fortiAPSubset,
        },
        {
          id: 'fortiswitch',
          isGroup: true,
          groupName: gettext('FortiSwitch'),
          children: fortiSwitchSubset,
        },
        {
          id: 'fortiext',
          isGroup: true,
          groupName: gettext('FortiExtender'),
          children: fortiExtSubset,
        },
        {
          id: 'folders',
          isGroup: true,
          groupName: gettext('Folders'),
          children: folderSubset,
        },
      ];
      setAllDisplayData(
        displayDevice.filter((data) => data.children.length !== 0)
      );
    }
  }, [allDevices, getSelectedFolder, viewType, otherDevices]);

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        dataKey: 'name',
        title: gettext('Name'),
        cellRenderer: (data) => {
          const { lib = 'fafm', icon, iconColor } = getIcon(data);
          return (
            <>
              {data.rowData.indent && viewType === VIEWTYPE_FABRIC && (
                <span className={'tw-p-2'}></span>
              )}
              <NwIcon
                className={cn('tw-mr-1', iconColor)}
                library={lib}
                name={icon}
              />
              {data.cellData}
            </>
          );
        },
      },
      {
        key: 'serialnum',
        dataKey: 'serialnum',
        title: gettext('Serial Number'),
        hidden: false,
      },
      {
        key: 'ip',
        dataKey: 'ip',
        title: gettext('IP Address'),
        hidden: true,
      },
      {
        key: 'platform',
        dataKey: 'platform',
        title: gettext('Platform'),
        hidden: false,
      },
      {
        key: 'rowType',
        dataKey: 'rowType',
        title: gettext('Device Type'),
        hidden: false,
        dataGetter: (data) => {
          if (data.rowData.rowType === ROWTYPEDEVICE) {
            return gettext('FortiGate');
          }
          if (data.rowData.rowType === ROWTYPEFORTIAP) {
            return gettext('FortiAP');
          }
          if (data.rowData.rowType === ROWTYPEFORTISWITCH) {
            return gettext('FortiSwitch');
          }
          if (data.rowData.rowType === ROWTYPEFORTIEXTENDER) {
            return gettext('FortiExtender');
          }
          return '';
        },
        filterOptions: [],
      },
      {
        key: 'firmwareTemplate',
        dataKey: 'firmwareTemplate',
        title: gettext('Firmware Template'),
        hidden: false,
        cellRenderer: (data) => {
          if (data.rowData.firmwareTemplate?.name) {
            return (
              <>
                <NwIcon library='fafm' name='cpu' className={'tw-mr-1'} />
                {data.rowData.firmwareTemplate?.name || ''}
              </>
            );
          }
          return <></>;
        },
      },
      {
        key: 'firmware_os_version',
        dataKey: 'firmware_os_version',
        title: gettext('Firmware / OS Version'),
        hidden: false,
      },
      {
        dataKey: 'desc',
        key: 'desc',
        title: gettext('Description'),
        hidden: false,
      },
    ];
  }, [firmwareTemplates, viewType]);

  const getToolbarItems = useCallback(
    (selectedRows) => {
      const view = {
        type: commandItemType.menu,
        key: 'viewMenu',
        label: gettext('Folder View'),
        icon: 'folder',
        items: [
          fiDvmActionsId.views.table,
          fiDvmActionsId.views.map,
          fiDvmActionsId.views.ring,
          fiDvmActionsId.views.folder,
        ],
      };
      view.items = view.items.map((elem) => viewCommands(fiDvmActionsId)[elem]);

      return [
        {
          key: 'refresh',
          label: gettext('Refresh'),
          icon: 'refresh',
          exec: function () {
            reload();
          },
        },
        {
          key: 'export',
          label: gettext('Export to CSV'),
          icon: 'csv-format',
          exec: async function () {
            const { exportDeviceListCSV } = await import(
              'react_apps/ra_dvm/actions/dvm_actions'
            );
            let allCols = [];
            columns.forEach(function (def) {
              if (def && def.dataKey) {
                allCols.push(def);
              }
            });
            let data = allDisplayData.reduce((prev, curr) => {
              prev.push(curr);
              if (isArray(curr.children)) prev = prev.concat(curr.children);
              return prev;
            }, []);
            exportDeviceListCSV(
              fiDvmListViewType.MANAGED_FOLDER_VIEW,
              data,
              allCols
            );
          },
        },
        {
          key: 'assign_firmware',
          label: gettext('Assign Firmware Template'),
          icon: 'cpu',
          disabled: (() => {
            const devices = filterByFortigates(selectedRows);
            return devices.length === 0;
          })(),
          exec: async function () {
            try {
              const { template, devices } = await ProToolkit.openModal(
                <FolderFirmwareTemplate
                  rSelectedDevices={filterByFortigates(selectedRows, (obj) => ({
                    _oData: obj,
                  }))}
                  rDevices={filterByFortigates(allDevices)}
                />,
                { size: 'lg' }
              ).result;
              try {
                if (template && template?.id !== NONE) {
                  await assignDevsToFirmware(template, devices);
                } else if (
                  (!template || template?.id === NONE) &&
                  devices.length > 0
                ) {
                  await unassignDevsFromFirmware(devices);
                }
              } catch {
                // Failed to unassign/assign devs to template
              }

              setReloadFirmwareTemplate();
            } catch (err) {
              // Error - either modal dismiss or failed to assign firmware
              if (err.templateCreated) reload();
            }
          },
        },
        view,
      ];
    },
    [allDisplayData, allDevices]
  );

  const getToolbarRightItems = useCallback(() => {
    return [
      {
        key: 'display',
        label: gettext('Display Options'),
        icon: { name: 'top-task', label: gettext('Display Options') },
        type: 'dropdown',
        items: [
          {
            key: 'fabric_view',
            label: gettext('Fabric view'),
            icon: {
              name: viewType === VIEWTYPE_FABRIC ? 'check' : '',
              label: gettext('Fabric view'),
            },
            exec: () => {
              setViewType(VIEWTYPE_FABRIC);
            },
            disabled: viewType === VIEWTYPE_FABRIC,
          },
          {
            key: 'flat_view',
            label: gettext('Flat View'),
            icon: {
              name: viewType === VIEWTYPE_FLAT ? 'check' : '',
              label: gettext('Flat View'),
            },
            exec: () => {
              setViewType(VIEWTYPE_FLAT);
            },
            disabled: viewType === VIEWTYPE_FLAT,
          },
          {
            key: 'group_by_device_type',
            label: gettext('Device Type View'),
            icon: {
              name: viewType === VIEWTYPE_DEVICETYPE ? 'check' : '',
              label: gettext('Device Type View'),
            },
            exec: () => {
              setViewType(VIEWTYPE_DEVICETYPE);
            },
            disabled: viewType === VIEWTYPE_DEVICETYPE,
          },
        ],
      },
    ];
  }, [viewType]);

  const getMenuOpts = useCallback(
    (selectedRows) => {
      return [
        {
          key: 'assign_firmware',
          label: gettext('Assign Firmware Template'),
          icon: 'cpu',
          disabled: (() => {
            const devices = filterByFortigates(selectedRows);
            return devices.length === 0;
          })(),
          exec: async function () {
            try {
              const { template, devices } = await ProToolkit.openModal(
                <FolderFirmwareTemplate
                  rSelectedDevices={filterByFortigates(selectedRows, (obj) => ({
                    _oData: obj,
                  }))}
                  rDevices={filterByFortigates(allDevices)}
                />,
                { size: 'lg' }
              ).result;
              try {
                if (template && template?.id !== NONE) {
                  await assignDevsToFirmware(template, devices);
                } else if (
                  (!template || template?.id === NONE) &&
                  devices.length > 0
                ) {
                  await unassignDevsFromFirmware(devices);
                }
              } catch {
                // Failed to unassign/assign devs to template
              }

              setReloadFirmwareTemplate();
            } catch (err) {
              // Error - either modal dismiss or failed to assign firmware
              if (err.templateCreated) reload();
            }
          },
        },
      ];
    },
    [allDisplayData, allDevices]
  );

  if (!allDisplayData) {
    return <PageLoading />;
  }
  return (
    <ProTable.TableView
      tableId='dvmMapViewDevAddressTable'
      rowKey={'id'}
      data={allDisplayData}
      columns={columns}
      rowHeight={MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_DEFAULT}
      getToolbarItems={getToolbarItems}
      getToolbarRightItems={getToolbarRightItems}
      getContextMenuItems={getMenuOpts}
      isGroupRow={({ rowData }) => rowData.isGroup}
      groupRowRenderer={({ rowData }) => rowData.groupName}
      onDoubleClickRow={({ rowData }) => {
        if (rowData.rowType === ROWTYPEDEVICE) {
          const deviceTreeState = { deviceOid: rowData.oid };
          fiStore.dispatch(
            fiStoreAdomLocalConfig.setDeviceTree(deviceTreeState)
          );
          dispatch(
            go({
              to: `/dvm/main/cfg/${rowData.oid}/root`,
              opts: {
                state: {
                  folder: stateFolder,
                },
              },
            })
          );
        } else if (rowData.rowType === ROWTYPEFOLDER) {
          // FOLDER
          setSelectedFolderPath(rowData.path);
          // dispatch(
          //   go({
          //     to: '/dvm/main/folder',
          //     opts: { state: { folder: rowData.path } },
          //   })
          // );
          // frGo('adom.default.dvm.groups.folder.assests', {folder: rowData.path, allFolders, folderIdMap}, { reload: 'adom.default.dvm.groups.folder.assests' });
        } else if (rowData.rowType === ROWTYPEFORTIAP) {
          dispatch(go({ to: '/fortiap/devices/list' }));
          let currentAssetPayload = {}; //always reset
          currentAssetPayload[MACROS.USER.DVM.ASSETS_FORTIAP] = {
            'wtp-id': rowData._oData['wtp-id'],
            'scope member': rowData._oData['scope member'],
          };
          //we just modify the state here, the fi_store reducers will be responsible for creating new data object
          fiFoldersRedux.dispatchActionAssest(currentAssetPayload);
        } else if (rowData.rowType === ROWTYPEFORTISWITCH) {
          dispatch(go({ to: '/fortiswitch/devices/list' }));
          let currentAssetPayload = {}; //always reset
          currentAssetPayload[MACROS.USER.DVM.ASSETS_FORTISWITCH] = {
            'switch-id': rowData._oData['switch-id'],
          };
          fiFoldersRedux.dispatchActionAssest(currentAssetPayload);
        } else if (rowData.rowType === ROWTYPEFORTIEXTENDER) {
          dispatch(go({ to: '/fext/view/list' }));
          let currentAssetPayload = {}; //always reset
          currentAssetPayload[MACROS.USER.DVM.ASSETS_FORTIEXTENDER] = {
            sn: rowData.sn,
          };
          fiFoldersRedux.dispatchActionAssest(currentAssetPayload);
        }
      }}
    />
  );
};
