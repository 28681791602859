import { noop } from 'lodash';
import { createCaptureFile, debuggerDidStart } from '../debugger_utils';
import { Severity } from '../types';

const logNoop = {
  info: noop,
  warn: noop,
  error: noop,
  critical: noop,
};

type LogJS = (message: string, stack?: unknown) => void;
export const jslog: {
  info: LogJS;
  warn: LogJS;
  error: LogJS;
  critical: LogJS;
} = { ...logNoop };

export const register = () => {
  return debuggerDidStart(async () => {
    const { send, complete } = await createCaptureFile('javascript.log.json');

    const logWith = (severity: Severity) => {
      const log: LogJS = (message, stack) => {
        const data = {
          timestamp: Date.now(),
          severity,
          message,
          stack,
        };
        send(data);
      };
      return log;
    };

    Object.assign(jslog, {
      info: logWith(Severity.Info),
      warn: logWith(Severity.Warning),
      error: logWith(Severity.Error),
      critical: logWith(Severity.Critical),
    });
    window.addEventListener('unhandledrejection', onRejectEvent);
    window.addEventListener('error', onErrorEvent);
    return stopCapture;

    function stopCapture() {
      Object.assign(jslog, logNoop);
      window.removeEventListener('unhandledrejection', onRejectEvent);
      window.removeEventListener('error', onErrorEvent);
      complete();
    }

    function onRejectEvent(event: PromiseRejectionEvent) {
      jslog.error(
        'Uncaught (in promise) ' + (event.reason?.message || event.reason),
        event.reason?.stack
      );
    }

    function onErrorEvent(event: ErrorEvent) {
      jslog.critical(event.message, event.error?.stack);
    }
  });
};
