import { checkDevicePlatform } from 'ra_device_util';

export const checkIs6K7KDevice = (deviceInfo) => {
  return [
    {
      os: MACROS.DVM.DVM_OS_TYPE_FOS,
      platform: MACROS.DVM.DVM_PLATFORM_FGT_7000E,
    },
    {
      os: MACROS.DVM.DVM_OS_TYPE_FOC,
      platform: MACROS.DVM.DVM_PLATFORM_FGC_7000E,
    },
    {
      os: MACROS.DVM.DVM_OS_TYPE_FOS,
      platform: MACROS.DVM.DVM_PLATFORM_FGT_6000F,
    },
    {
      os: MACROS.DVM.DVM_OS_TYPE_FOC,
      platform: MACROS.DVM.DVM_PLATFORM_FGC_6000F,
    },
  ].reduce((acu, cur) => {
    acu =
      acu ||
      (deviceInfo.os_type === cur.os &&
        checkDevicePlatform(deviceInfo, cur.platform));
    return acu;
  }, false);
};
