import { useCallback } from 'react';
import { NwAvatar } from '@fafm/neowise/react';
import cn from 'classnames';
import { useCloudNav } from './useCloudNav';
import { useSelector } from 'react-redux';
import {
  getSysConfig,
  getAvatarSrc,
  getUsername,
  getEmail,
  getPhone,
} from 'fistore/session/sysConfig/selectors';

export const useUserItems = ({
  getProduct,
  canUpgradeFirmware,
  canChangePassword,
  canChangeProfile,
  changeProfile,
  upgradeFirmware,
  openChangePwd,
  openProcessMonitor,
  logout,
}) => {
  const sysConfig = useSelector(getSysConfig);
  const haveFtntCloud = sysConfig.have_ftnt_cloud;
  const _product = getProduct();
  const _canUpgradeFirmware = canUpgradeFirmware();
  const _canChangePassword = canChangePassword();
  const _canChangeProfile = canChangeProfile();
  const { sections, getCloudNav } = useCloudNav();
  const avatarSrc = useSelector(getAvatarSrc);
  const username = useSelector(getUsername);
  const email = useSelector(getEmail);
  const phone = useSelector(getPhone);

  const renderExtraInfo = useCallback(
    (email, phone) => (
      <>
        {email && (
          <div className='tw-text-sm tw-truncate tw-text-neutral'>{email}</div>
        )}
        {phone && (
          <div className='tw-text-sm tw-truncate tw-text-neutral'>{phone}</div>
        )}
      </>
    ),
    []
  );

  const getAvatarLabel = useCallback(
    (isNavbar) => {
      const initials = username
        .split(' ')
        .map((name) => name[0]?.toUpperCase())
        .join();

      return (
        <span
          className={cn('tw-flex tw-items-center', {
            'tw-max-w-xs': isNavbar,
          })}
        >
          <NwAvatar
            initials={initials}
            image={avatarSrc}
            className={cn(
              'fi-user-avabar',
              isNavbar ? 'normal tw-mr-1' : 'large tw-mr-2'
            )}
          />
          <span className='tw-truncate tw-grow'>
            {username}
            {!isNavbar && renderExtraInfo(email, phone)}
          </span>
        </span>
      );
    },
    [avatarSrc, username, email, phone]
  );

  const getUserItems = useCallback(() => {
    const haMode = sysConfig?.ha_mode;
    const itemsMap = {
      'user-info': {
        key: 'user-info',
        label: getAvatarLabel(false),
      },
      'system-info': {
        key: 'system-info',
        label: (
          <div className='tw-cursor-default'>
            {_product.name}
            <br />
            {_product.build}
          </div>
        ),
      },
      upgrade: {
        key: 'upgrade',
        iconLibrary: 'fafm',
        icon: 'upgrade',
        label: gettext('Upgrade Firmware'),
      },
      'process-monitor': {
        key: 'process-monitor',
        icon: 'line-graph',
        label: gettext('Process Monitor'),
      },
      password: {
        key: 'password',
        iconLibrary: 'fa-solid',
        icon: 'lock',
        label: gettext('Change Password'),
        disabled: MACROS.SYS.HA_MODE_SLAVE === haMode,
      },
      profile: {
        key: 'profile',
        iconLibrary: 'fa-solid',
        icon: 'cog',
        label: gettext('Change Profile'),
      },
      logout: {
        key: 'logout',
        iconLibrary: 'fa-solid',
        icon: 'sign-out-alt',
        label: gettext('Log Out'),
      },
      'return-to-portal': {
        key: 'return-to-portal',
        iconLibrary: 'fa-solid',
        icon: 'sign-out-alt',
        label: gettext('Return To Portal'),
      },
      'forticloud-nav-menu': {
        key: 'forticloud-nav-menu',
        label: getCloudNav(),
        disabled: true,
      },
      divider: {
        isDivider: true,
      },
    };

    const cloudHasContent = sections.length > 0;
    const items = [
      itemsMap['user-info'],
      itemsMap['divider'],
      ...(haveFtntCloud
        ? [
            !cloudHasContent && itemsMap['return-to-portal'],
            cloudHasContent && itemsMap['forticloud-nav-menu'],
          ]
        : [
            itemsMap['system-info'],
            _canUpgradeFirmware && itemsMap['upgrade'],
            itemsMap['divider'],
            itemsMap['process-monitor'],
            _canChangePassword && itemsMap['password'],
          ]),
      _canChangeProfile && itemsMap['profile'],
      itemsMap['logout'],
    ].filter(Boolean);

    return items;
  }, [
    _product,
    _canUpgradeFirmware,
    _canChangePassword,
    _canChangeProfile,
    getAvatarLabel,
    sections,
    getCloudNav,
  ]);

  const onSelectUserItems = useCallback((item) => {
    const fn = {
      profile: changeProfile,
      upgrade: upgradeFirmware,
      password: openChangePwd,
      'process-monitor': openProcessMonitor,
      logout: logout,
      'return-to-portal': logout,
    }[item.key];
    if (fn) {
      fn();
    }
  }, []);

  return { getUserItems, onSelectUserItems, getAvatarLabel, username };
};
