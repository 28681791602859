import { fiAdom, fiSysConfig } from 'fi-session';

import { fiDeviceDataLoader } from './device_data_loader';

export function getDevTreeLocalStorageKey(adomOid) {
  return `${adomOid}:dvmtree:single:tree`;
}

export function getSelectedDevFromLS() {
  const defaultRes = { device: null, vdom: null };

  try {
    const key = getDevTreeLocalStorageKey(fiAdom.current().oid);
    const fromLs = window.localStorage.getItem(key);
    if (!fromLs) return defaultRes;

    const val = JSON.parse(fromLs);
    const selected = val?.selected;
    if (!selected) return defaultRes;

    const [, deviceOid, vdomOid] = selected.split(':');

    let device = {};
    let vdom = {};
    if (deviceOid) {
      device = fiDeviceDataLoader.getDevice(`${deviceOid}`);
    }
    if (device && vdomOid) {
      vdom = device.vdoms.find(
        (vdom) => vdom['_fiDeviceId'] === `${deviceOid}-${vdomOid}`
      );
    }

    return {
      device,
      vdom,
    };
  } catch (err) {
    return defaultRes;
  }
}

export const shouldCallMonitorApi = (device) => {
  const isOfflineMode = fiSysConfig.isOfflineModeEnabled();
  const isModelDevice = device?.model_dev;
  if (!isOfflineMode && !isModelDevice) {
    return true;
  }

  return false;
};

export const isVdomDomain = (domain) => {
  return domain === MACROS.DVM.CDB_OBJ_DOM_VDOM;
};
export const isGlobalDomain = (domain) => {
  return domain === MACROS.DVM.CDB_OBJ_DOM_GLOBAL;
};

export const isFPX = ({ device }) => {
  return device.os_type === MACROS.DVM.DVM_OS_TYPE_FPX;
};
