import { setCurrentCommunity } from './actions';

function reducer(
  state = {
    currentCommunity: { topology: -1 },
  },
  { type, payload }
) {
  const mergeState = (partial) => ({ ...state, ...partial });

  switch (type) {
    case setCurrentCommunity.type:
      return mergeState({ currentCommunity: payload });
    default:
      return state;
  }
}

export { reducer };
