import { createReducer } from '@reduxjs/toolkit';

import {
  devicesFirmwareAction,
  setFirmwareManualUpgradeAction,
} from './actions';
import { castArray, set } from 'lodash';

const initialState = {
  loaded: false,
  loading: false,
  matrix: [],
  fwById: {},
  manualUpgradesMap: {}, // { [deviceName]: array of schedule profiles }
};

export const deviceFirmwareReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(devicesFirmwareAction.fetch.START, (state) => {
      state.loaded = false;
      state.loading = true;
    })
    .addCase(devicesFirmwareAction.fetch.SUCCESS, (state, action) => {
      state.loaded = true;
      state.loading = false;
      state.fwById = action.payload.fwById;
      state.matrix = action.payload.matrix;
    })
    .addCase(setFirmwareManualUpgradeAction.type, (state, action) => {
      const { payload = {} } = action;
      const { devices, manualUpgradesMap } = payload || {};

      // No devices array > fetch all schedules > override the current manualUpgradesMap
      const devicesArray = castArray(devices).filter(Boolean);
      if (!devicesArray || !devicesArray.length) {
        state.manualUpgradesMap = manualUpgradesMap || {};
        return;
      }

      // update schedules per device
      for (const dev of devicesArray) {
        const devName = dev?.name;
        if (devName) {
          set(state.manualUpgradesMap, [devName], manualUpgradesMap[devName]);
        }
      }
    });
});
