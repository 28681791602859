import { fiSession } from 'fistore';
import { cloneDeep, last, negate, overSome, transform } from 'lodash';
import { dvm_pt_apps, getDspOptChecker } from 'fi-web/fi-routing/all-apps/dvm';

const {
  inBackupAdom,
  inOthersAdom,
  inGlobalAdom,
  inFpxAdom,
  hasRDPermitOn,
  isFmg,
  hasFmgFeature,
} = fiSession;

const mrPRIV = MACROS.USER.DVM;

const TMPL_APP_UNIKEY = 'sdwan_pt';

/** ----------------------------------------------------------------------------
 * Network Section
 * -------------------------------------------------------------------------- */
export const sdwan_network = {
  // new sdwan devices + monitor page
  sdwan_network: {
    label: gettext('Network'),
    path: '/dvm/network',
    showCheckers: [negate(inOthersAdom), hasRDPermitOn(mrPRIV.ADMINPRIV_WLLB)],
  },
};

export const sdwan_devices_apps = {
  // sdwan devices table
  sdwan_network_devices: {
    label: gettext('Devices'),
    path: '/sdwan/network/devices',
    showCheckers: [negate(inOthersAdom), hasRDPermitOn(mrPRIV.ADMINPRIV_WLLB)],
    routerConfig: {
      lazy: async () => {
        const { SdwanManagedDeviceListPage } = await import(
          'react_apps/ra_dvm/dvm_table/device_group/device_group'
        );
        return { Component: SdwanManagedDeviceListPage };
      },
    },
  },
};

export const sdwan_monitor_apps = {
  // consist of sdwan monitor related dashboard widgets
  sdwan_network_monitor: {
    label: gettext('Monitor'),
    path: '/sdwan/network/monitor',
    showCheckers: [negate(inOthersAdom), hasRDPermitOn(mrPRIV.ADMINPRIV_WLLB)],
    routerConfig: {
      lazy: async () => {
        const { SdwanNetworkDashboardConnector } = await import(
          'react_apps/ra_sdwan/sdwan_dashboard/SdwanNetworkDashboard'
        );
        return { Component: SdwanNetworkDashboardConnector };
      },
    },
  },
};

/** ----------------------------------------------------------------------------
 * Templates Section
 * -------------------------------------------------------------------------- */
// Reuse the apps from DVM PT, but modify the appUniKey and path
const getTemplateApps = () => {
  const appUniKeys = [
    'dvm_pt_tmplgrp',
    'dvm_pt_ipsectmpl',
    'dvm_pt_bgptmpl',
    'dvm_pt_routetmpl',
    'dvm_pt_clitmpl',
  ];
  return transform(
    appUniKeys,
    (result, appUniKey) => {
      const app = dvm_pt_apps[appUniKey];
      if (!app) return;

      const updatedApp = cloneDeep(app);
      const { path, ...rest } = updatedApp;

      // modify path
      let pathSuffix = last(path.split('/'));
      const newPath = `/sdwan/prvtmpl/${pathSuffix}`;

      // modify appUniKey
      const appUniKeySuffix = last(appUniKey.split('dvm_pt_'));
      const newAppUniKey = `${TMPL_APP_UNIKEY}_${appUniKeySuffix}`;

      const showCheckers = app.showCheckers || [];
      const mergedShowCheckers = [...showCheckers];
      if (app.dspOptChecker) {
        mergedShowCheckers.push(app.dspOptChecker);
      }
      result[newAppUniKey] = {
        path: newPath,
        showCheckers: mergedShowCheckers,
        ...rest,
      };
    },
    {}
  );
};

export const sdwan_pt_apps = {
  [TMPL_APP_UNIKEY]: {
    label: gettext('Templates'),
    path: '/sdwan/templates',
    showCheckers: [
      hasFmgFeature,
      negate(inBackupAdom),
      overSome([hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)]),
      negate(inOthersAdom),
    ],
  },
  ...getTemplateApps(),
};

/** ----------------------------------------------------------------------------
 * Overlay Orchestration Section
 * -------------------------------------------------------------------------- */
export const sdwan_overlay_orchestration_apps = {
  sdwan_overlay_orchestration: {
    label: gettext('Overlay Orchestration'),
    // icon: 'vpn-manager',
    path: '/sdwan/overlay',
    tmplkey: 'overlaytmpl',
    dspOptChecker: getDspOptChecker('dvm_pt_overlaytmpl'),
    routerConfig: {
      lazy: () =>
        import('react_apps/ra_pt/sdwan_overlay_template/SDWANOverlayTemplate'),
    },
    showCheckers: [
      negate(inFpxAdom),
      negate(inOthersAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_WLLB),
    ],
  },
};

/** ----------------------------------------------------------------------------
 * Rules Section
 * -------------------------------------------------------------------------- */
export const sdwan_rules_apps = {
  sdwan_rules: {
    label: gettext('Rules'),
    // icon: 'sd-wan',
    path: '/sdwan/rules',
    tmplkey: 'sdwan',
    dspOptChecker: getDspOptChecker('dvm_pt_sdwantmpl'),
    routerConfig: {
      lazy: () => import('react_apps/ra_pt/sdwan_template/sdwan_template'),
    },
    showCheckers: [
      negate(inOthersAdom),
      negate(inFpxAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_WLLB),
    ],
  },
};

export default {
  sdwan: {
    label: 'SD-WAN Manager',
    icon: 'sd-wan',
    path: 'sdwan',
    showCheckers: [
      isFmg,
      negate(inGlobalAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_MANAGER),
    ],
  },

  // network
  ...sdwan_network,
  ...sdwan_devices_apps,
  ...sdwan_monitor_apps,

  // templates
  ...sdwan_pt_apps,

  // overlay
  ...sdwan_overlay_orchestration_apps,

  // rules
  ...sdwan_rules_apps,
};
