import { FormikConsumer } from 'formik';
import { OkBtn, CancelBtn } from 'rc_layout';
import { ProForm } from '@fafm/neowise-pro';

export { FmkFooter, fmkFooterButtons };

const FmkFooter = ({
  canWrite,
  canCancel = true,
  getAutoId,
  onCancel,
  prefix,
  suffix,
  renderApiPreviewButton,
}) => {
  return (
    <ProForm.Footer>
      {prefix}
      {fmkFooterButtons({
        canWrite,
        canCancel,
        getAutoId,
        onCancel,
        renderApiPreviewButton,
      })}
      {suffix}
    </ProForm.Footer>
  );
};

const fmkFooterButtons = ({
  canWrite,
  canCancel = true,
  getAutoId,
  onCancel,
  okBtnText,
  renderApiPreviewButton,
  isApiPreviewing,
}) => {
  return (
    <>
      {renderApiPreviewButton?.()}
      <FormikConsumer>
        {({ submitForm, isSubmitting }) => {
          return (
            <>
              {canWrite && (
                <OkBtn
                  automation-id={getAutoId('ok')}
                  onClick={submitForm}
                  loading={isSubmitting && !isApiPreviewing}
                >
                  {okBtnText ?? gettext('OK')}
                </OkBtn>
              )}
            </>
          );
        }}
      </FormikConsumer>
      {canCancel && (
        <CancelBtn automation-id={getAutoId('cancel')} onClick={onCancel}>
          {canWrite ? gettext('Cancel') : gettext('Close')}
        </CancelBtn>
      )}
    </>
  );
};
