import { roundToPlaces } from 'kit-number';

export function getCustomviewMenu(adminUserName = '', customview) {
  if (!customview?.children?.length) {
    return [];
  }

  // escape '*' or other special char in regular expression
  const adminName = adminUserName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const checkRegex = new RegExp(`^${adminName}\\|`);
  const checkRegexEnd = new RegExp('\\|public$');
  const customviewList = customview.children
    .filter((child) => {
      return (
        child.owner === '' ||
        checkRegex.test(child.owner) ||
        checkRegexEnd.test(child.owner)
      );
    })
    .map((item) => {
      const key = `logs_customview_${item.uuid}`;
      const appUniKey = `logview_${key}`;
      const { viewName, devType, id } = item.data.stateParams;
      const pathPrefix = item.data.path;
      const parts = [pathPrefix, appUniKey, devType, viewName];
      return {
        key,
        label: item.label,
        icon: item.owner.includes('|private')
          ? 'administrator'
          : 'administrators',
        path: parts.join('/'),
        stateParams: {
          appUniKey,
          name: item.name,
          id,
          owner: item.owner,
          cvDevType: item['dev-type'],
        },
      };
    });
  return customviewList;
}

export const LOG_SUSTAIN_RATE_INIT = {
  dbLastMinuteDropped: 0, // dropped in the last minute
  dbTotalDropped: 0, // total dropped since system online

  notifLastMinuteDropped: 0, // whether display alert on the upper right corner
  prevDbLastMinuteDropped: null,
  checked: 0, // whether user popups the alert window to see the omitted logs message
};

export function getLogSustainRateNotify(resp, logSustainRate) {
  if (!resp) {
    return;
  }
  if (logSustainRate.prevDbLastMinuteDropped === null) {
    // system just start
    if (resp.lastMinuteDropped > 0) {
      logSustainRate.notifLastMinuteDropped = 1;
      logSustainRate.prevDbLastMinuteDropped = resp.lastMinuteDropped;
      logSustainRate.dbLastMinuteDropped = resp.lastMinuteDropped;
    } else {
      logSustainRate.notifLastMinuteDropped = 0;
    }
  } else if (
    logSustainRate.prevDbLastMinuteDropped === 0 &&
    logSustainRate.prevDbLastMinuteDropped === resp.lastMinuteDropped
  ) {
    logSustainRate.notifLastMinuteDropped = 0;
  } else if (
    logSustainRate.prevDbLastMinuteDropped > 0 &&
    resp.lastMinuteDropped === 0 &&
    logSustainRate.checked
  ) {
    // user did sth to lower logs rate below sustain rate
    logSustainRate.notifLastMinuteDropped = 0;
    logSustainRate.checked = 0; // reset the checked status
    logSustainRate.prevDbLastMinuteDropped = 0;
  } else {
    logSustainRate.notifLastMinuteDropped = 1;
    if (logSustainRate.dbLastMinuteDropped > 0) {
      logSustainRate.prevDbLastMinuteDropped =
        logSustainRate.dbLastMinuteDropped;
    }

    if (resp.lastMinuteDropped > 0) {
      logSustainRate.dbLastMinuteDropped = resp.lastMinuteDropped;
    }
  }

  logSustainRate.dbTotalDropped = resp.totalDropped > 0 ? resp.totalDropped : 0;
  // logSustainRate.notifLastMinuteDropped = 1;
}

export function getLogStorageStats(resp /*, logStorageStats*/) {
  return [
    {
      key: 'db' /*, tmpl: gettext('Analytic is using %s% of allocate disk space.')*/,
    },
    {
      key: 'file' /*, tmpl: gettext('Archive is using %s% of allocate disk space.')*/,
    },
  ].reduce((accu, curr) => {
    if (resp[curr.key + '_quota_warning']) {
      const key = curr.key;
      accu[key + '_used'] = resp[key + '_used'] - 0;
      accu[key + '_quota'] = resp[key + '_quota'] * 1048576; // 1048576=1024*1024
      accu[key + '_percent'] = roundToPlaces(
        (accu[key + '_used'] * 100) / (accu[key + '_quota'] || 1),
        0
      );
      // accu[key + 'QuotaWarningText'] = curr.tmpl.printf([
      //   $ng('$filter')('number')(
      //     (accu[key+'_used'] * 100) / (accu[key + '_quota'] || 1),
      //     0
      //   )
      // ]);
    }
    return accu;
  }, {});
}

export const getLogviewPageMap = (data) => {
  // FGT and siem logs for now
  const FgtMenu =
    data.find((x) => x.dvm_os_type === MACROS.DVM.DVM_OS_TYPE_FOS)?.children ||
    [];
  const SiemMenu =
    data.find((x) => x.dvm_os_type === MACROS.DVM.DVM_OS_TYPE_SIM)?.children ||
    [];
  if (!FgtMenu.length && !!SiemMenu.length) return {};

  const walk = (node, res) => {
    // only include Event -> All Type
    if (node.cat === MACROS.FAZ_SYNTAX.LOGTYPE_EVENT) {
      const eventAll = node.children?.find(
        (x) => x.id === MACROS.FAZ_SYNTAX.SUBTYPE_ANY
      );
      if (eventAll) {
        const devType = eventAll.data.stateParams.devType;
        const _logtype = eventAll.data.stateParams._logtype;
        res[`Logview->${node.label}`] = {
          ...eventAll.data.stateParams,
          path: `${eventAll.data.path}/logview_${devType}-${_logtype}/${devType}/${_logtype}`,
          logtype: parseInt(node.cat),
          subtype: eventAll.id,
          devType: parseInt(devType),
        };
      }
    } else if (node.children?.length) {
      node.children.forEach((item) => {
        walk(item, res);
      });
    } else {
      // leaf
      if (node.data.stateParams && typeof node.cat !== 'undefined') {
        const devType = node.data.stateParams.devType;
        const _logtype = node.data.stateParams._logtype;
        res[`Logview->${node.label}`] = {
          ...node.data.stateParams,
          path: `${node.data.path}/logview_${devType}-${_logtype}/${devType}/${_logtype}`,
          logtype: parseInt(node.cat),
          subtype: isNaN(parseInt(node.subcat))
            ? undefined
            : parseInt(node.subcat),
          devType: parseInt(devType),
        };
      }
    }
  };

  const res = {};
  FgtMenu.forEach((item) => walk(item, res));
  // siem
  if (SiemMenu.length) {
    const devType = SiemMenu[0].data.stateParams.devType;
    const _logtype = SiemMenu[0].data.stateParams._logtype;
    res['Logview->SIEM'] = {
      ...SiemMenu[0].data.stateParams,
      path: `${SiemMenu[0].data.path}/logview_all/${devType}/${_logtype}`,
      logtype: parseInt(_logtype),
      devType: parseInt(devType),
    };
  }
  return res;
};
