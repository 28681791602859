import { useState, useRef } from 'react';
import { isFunction, isString } from 'lodash';

import { useValidEffect } from 'rh_util_hooks';

const INIT_CHUNK_REF = { chunkQueue: [], hasTail: false, interval: null };

export const useLoadContentDS = ({
  func,
  parameter,
  getParam,
  currentDevice,
  originalContent,
  parseError,
  parseContent,
  getDefaultContent,
  isMultipleDevice,
  idAttr,
}) => {
  const getKey = (dev) => dev[idAttr];

  /** ----------------------------------------------------------------------------
   * States
   * -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [displayData, setDisplayData] = useState({});
  const [defaultContent, setDefaultContent] = useState(originalContent);

  const getDisplayDataByKey = (key) => {
    return displayData?.[key];
  };

  /** ----------------------------------------------------------------------------
   * Hooks
   * -------------------------------------------------------------------------- */
  // Content data/error ref for caching
  const contentRef = useRef({});

  // Chunk data ref for data loading
  const chunkRef = useRef(INIT_CHUNK_REF);
  const getChunkRef = () => chunkRef.current;
  const updateChunkRef = (payload) => {
    const ref = getChunkRef();
    chunkRef.current = {
      ...ref,
      ...payload,
    };
  };

  useValidEffect(() => {
    if (skipLoading()) return;

    loadDS();
    loadContent();

    return () => {
      const { interval } = getChunkRef();
      if (interval) {
        clearInterval(interval);
        chunkRef.current = INIT_CHUNK_REF;
      }
    };
  }, [func, parameter, getParam, currentDevice, getDefaultContent]);

  function skipLoading() {
    const { hasTail } = getChunkRef();

    const cacheKey = getKey(currentDevice);
    const contentCache = getDisplayDataByKey(cacheKey);

    // skip data loading if data is already in cache for a device
    if (contentCache || !isFunction(func)) {
      updateChunkRef({ hasTail: true });
      setIsLoading(false);
      return true;
    }

    // skip data loading if it's for multiple devices and it's already in loading state or reaches tail
    if (isMultipleDevice && (isLoading || hasTail)) {
      return true;
    }

    return false;
  }

  async function loadDS() {
    updateChunkRef({ chunkQueue: [], hasTail: false });
    setIsLoading(true);

    const param = (parameter ? parameter : getParam(currentDevice)) || [];
    func(...param).then(
      () => {
        updateChunkRef({ hasTail: true });
        const { chunkQueue, interval } = getChunkRef();
        // if no data in chunkQueue, no more action needed
        if (chunkQueue.length === 0) {
          clearInterval(interval);
          setIsLoading(false);
          setDisplayData(contentRef.current);
        }
      },
      (error) => {
        handleError({ error });
      },
      (notify) => {
        const { chunkQueue } = getChunkRef();
        if (!notify.tail) {
          chunkQueue.push(notify);
        } else {
          setDisplayData(contentRef.current);
        }
      }
    );
  }

  function loadContent() {
    function parser(data) {
      let newData = data;
      let error = null;
      // parse content in the data and return { displayData, error }
      if (isFunction(parseContent)) {
        const { displayData: parsedData, error: parsedError } =
          parseContent(data) || {};
        if (parsedData) {
          newData = parsedData;
        }

        if (parsedError) {
          error = parsedError;
        }
      }

      return {
        data: newData,
        error,
      };
    }

    // notify chunks at least delayed by 50 ms
    const _interval = setInterval(() => {
      updateChunkRef({ interval: _interval });

      const { chunkQueue, hasTail } = getChunkRef();
      if (chunkQueue.length > 0) {
        const chunk = chunkQueue.shift();
        updateChunkRef({ chunkQueue });
        if (!chunk.error) {
          contentRef.current = parseMultipleDeviceData({
            displayData: contentRef.current,
            chunk,
            parser,
            currentDevice,
            isMultipleDevice,
            getKey,
          });
          setDisplayData(contentRef.current);
        } else {
          handleError(chunk);
        }
      } else if (hasTail) {
        setDisplayData(contentRef.current);

        // finished receiving chunk data and no more data in _chunkQueue
        clearInterval(_interval);
        setIsLoading(false);
      }
    }, 250);
  }

  async function handleError(chunk) {
    const error = chunk.error;
    let cacheKey = getKey(currentDevice);

    if (isMultipleDevice) {
      const meta = chunk.meta;
      if (meta) {
        cacheKey = getMetaKey(meta);
      }
    }

    let parsedError = error;
    if (isFunction(parseError)) {
      const parsed = parseError(error);
      parsedError = parsed.error;
      const content = await getDefaultContent(parsed);
      setDefaultContent(content);
    }

    const data = {
      // dont cache display data for erroneous request
      data: null,
      meta: currentDevice,
      error: parsedError,
      loaded: true,
    };

    contentRef.current = {
      ...contentRef.current,
      [cacheKey]: data,
    };

    // cancel request when there is error and its for single device
    if (!isMultipleDevice) {
      updateChunkRef({ hasTail: true, chunkQueue: [] });
      setIsLoading(false);
      const { interval } = getChunkRef();
      clearInterval(interval);
    }
  }

  /** ----------------------------------------------------------------------------
   * Helper functions
   * -------------------------------------------------------------------------- */
  function getFinalData() {
    const cacheKey = getKey(currentDevice);
    const finalData = getDisplayDataByKey(cacheKey);
    if (!finalData?.error) {
      if (finalData?.data) {
        // console.log('read data from cache');
        return finalData;
      }
      return finalData;
    }

    return isString(defaultContent)
      ? {
          data: parseSingleDeviceData(defaultContent.split('\n')),
          loaded: true,
          meta: currentDevice,
          error: finalData?.error,
        }
      : getDisplayDataByKey(cacheKey);
  }

  function getError() {
    const cacheKey = getKey(currentDevice);
    const devData = getDisplayDataByKey(cacheKey);
    if (devData?.error) {
      // console.log('read error from cache');
      return devData.error;
    }

    return null;
  }

  return {
    isLoading,
    displayData: getFinalData(),
    error: getError(),
    fullDisplayData: displayData,
  };
};

/** ----------------------------------------------------------------------------
 * Helper functions
 * -------------------------------------------------------------------------- */
function parseSingleDeviceData(chunkData) {
  let data = chunkData.data || chunkData;
  if (isString(data)) {
    data = data.split('\n');
  }
  if (!Array.isArray(data)) return;
  return data.map((txt, index) => {
    return { id: index + 1, txt: txt };
  });
}

function parseMultipleDeviceData({
  displayData,
  chunk,
  parser,
  currentDevice,
  isMultipleDevice,
  getKey,
}) {
  const newDisplayData = { ...displayData };
  const key = isMultipleDevice
    ? getMetaKey(chunk.meta, getKey(currentDevice))
    : getKey(currentDevice);
  if (key) {
    const _devData = newDisplayData[key] || {};
    newDisplayData[key] = _devData;
    let newData = _devData.data
      ? _devData.data.concat(parseSingleDeviceData(chunk))
      : parseSingleDeviceData(chunk);

    if (isFunction(parser)) {
      const { data, error } = parser(newData);
      newData = data;
      _devData.error = error;
    }

    _devData.data = newData;
    _devData.loaded = true; // one device install preview is loaded
    _devData.meta = chunk.meta;
  }

  return newDisplayData;
}

function getMetaKey(meta, fallback) {
  if (!meta) return fallback;
  return meta.deviceOid && meta.vdomOid
    ? `${meta.deviceOid}-${meta.vdomOid}`
    : meta.name;
}
