import { observeStore, fiSession } from 'fistore';
import { isNil, isFunction, isUndefined } from 'lodash';
import { fiSocket } from 'fi-web/fi-websocket';

var _cache = {};
var _cfgTmpl = {
  adomChange: true, // when adomSwitch data will be invalid and cleaned up.
  modChange: false,
};

function init() {
  return fiSocket.get_ws_proxy().addListener(
    'changed',
    (data) => {
      ['user adgrp', 'firewall address'].forEach((it) => {
        if (data?.collection === it) {
          let adom = data?.meta?.adom;
          if (adom) {
            let key = adom + ':pno:map:' + it;
            clean(key);
          }
        }
      });
    },
    false
  );
}

/**
 * config cache when it become invalid
 * @param {string} cid, the id of cached data
 * @param {boolean} createNew, if true, then create new cache object whne not found
 * @return {object or null} the cache objecte
 */
function get(cid, createNew) {
  var obj = _cache[cid] || null;
  if (!obj && createNew === true) {
    obj = _cache[cid] = {
      id: cid,
      _cfg: null,
      _events: {},
      data: null,
    };
  }

  return obj;
}

/**
 * clean cache events and remove cache
 * @param {string} cid, the id of cached data
 */
function clean(cid) {
  var obj = _cache[cid];
  if (!obj) return;

  // remove all events
  for (const evt in obj._events) {
    if (isFunction(evt)) {
      evt();
    }
  }

  _cache[cid] = null;
  delete _cache[cid];
}

/**
 * @deprecated Avoid to call this function unless for debug purpose
 */
function _cleanAll() {
  for (const cid in _cache) {
    clean(cid);
  }

  _cache = {};
}

/**
 * clean certain cache
 * @param {function} fn, a function to detemine which cache could be clean,
 *                       require function return true or false
 */
function cleanSpec(fn) {
  if (!isFunction(fn)) return;

  for (const cid in _cache) {
    if (fn(cid)) {
      clean(cid);
    }
  }
}
/**
 * config cache when it become invalid
 * @param {string} cid, the id of cached data
 * @param {object} cfg,
 *     {
 *       adomChanged: true, // invalid when adom changed, by default
 *       modChanged: false, // invalid when module changed or ui-router state changed
 *     }
 * @return {any} the cached data
 */
function config(cid, cfg) {
  var obj = get(cid, true);
  _config(cid, obj, cfg);
}

/**
 * get/set cache
 * @param {string} cid , the id of cached data
 * @param {any} data , the data need to be cached
 * @returns shedow copied data
 */
function cache(cid, data) {
  var objdata = _fnCache(cid, data);

  return objdata ? _dup(objdata) : null;
}
/**
 * get/set cache
 * @param {string} cid , the id of cached data
 * @param {any} data , the data need to be cached
 * @returns direct data reference, make sure do not change anything.
 */
function performance_cache(cid, data) {
  return _fnCache(cid, data);
}

//============== utility functions =========
function clearDeviceGroupCache() {
  return cleanSpec((cid) => {
    return cid.indexOf('devgrp:') > -1;
  });
}

function cleanObjMapCache() {
  return cleanSpec((cid) => cid.match(/:pno:map:/));
}

function cleanApCache(adomName) {
  const regex = new RegExp(adomName ? `${adomName}:ap:` : /:ap:/);
  return cleanSpec((cid) => regex.test(cid));
}

//============== internal functions ======================
/**
 * getter/setter of cache
 * @param {string} cid, the id of cached data
 * @param {any} data, the data need to be cached
 * @return {any} the cached data
 */
function _fnCache(cid, data) {
  var obj;

  // if has data parameter, then update cache with given data
  if (!isUndefined(data)) {
    obj = get(cid, true);

    // set default config & events
    if (!obj._cfg) {
      _config(cid, obj);
    }

    // add data to cache
    obj.data = data;
  } else {
    obj = get(cid);
  }

  return obj ? obj.data : null;
}

// here did not used JSON.stringfy & parse
// to reduce memory usage and speed, number of objects could be huge.
function _dup(data) {
  if (isNil(data)) return null;

  if (Array.isArray(data)) {
    return [...data];
  }

  let type = typeof data;
  if (type === 'string' || type === 'number' || type === 'boolean') {
    return data;
  }

  return { ...data };
}

function _config(cid, obj, cfg) {
  obj._cfg = Object.assign({}, _cfgTmpl, obj._cfg, cfg);

  // clean up cache when adom is changed
  if (obj._cfg.adomChange) {
    // unregister old one if there is
    if (obj._events['adomChanged']) {
      obj._events['adomChanged']();
    }

    // handle adom changes
    obj._events['adomChanged'] = observeStore(
      fiSession.getSessionAdomName,
      (nadom, oadom) => {
        if (oadom && nadom && oadom != nadom) {
          clean(cid);
        }
      }
    );
  }

  // clean up when module changed / ui-router changed
  if (obj._cfg.modChanged) {
    if (obj._events['modChanged']) {
      obj._events['modChanged']();
    }
  }

  return obj;
}

export default {
  init,
  clean,
  _cleanAll,
  cleanSpec,
  config,
  cache,
  performance_cache,
  clearDeviceGroupCache,
  cleanObjMapCache,
  cleanApCache,
};
