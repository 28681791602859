import { fiAdom } from 'fi-session';
import { isDevSdwanCate } from './interface_util';
import _, { castArray, isNil } from 'lodash';
import { fiFmgHttp } from 'fi-http';
import { escapeSlash } from 'kit/kit-regexp';

export {
  getInterfaceFlags,
  getIntfSecurityModeFlag,
  getCachedDevicesInterfaces,
  getCachedDevicesInterfacesMap,
  getDeviceZoneMap,
  getDeviceSdwanZoneMap,
  getDeviceSdwanZoneMapWithMembers,
  getCachedDeviceInterfacesAndZoneMap,
  getSupportAttrs,
  getSnifferData,
  updateSnifferData,
  updateIntfIPV6Vdom,
};

const getDeviceName = (device) => {
  if (device && !isNil(device._isDevice) && device._isDevice == false) {
    //means device data is vdom data
    return device._deviceName;
  }

  return device?.name ?? device;
};

const getInterfaceFlags = async (deviceid, vdom) => {
  return fiFmgHttp
    .post({
      url: '/gui/adom/dvm/device/interface',
      method: 'getflags',
      params: {
        devID: parseInt(deviceid),
        vdom: vdom === 'global' ? '' : vdom,
      },
    })
    .then(function (resp) {
      let data = [];
      try {
        data = resp[0].data;
        // eslint-disable-next-line
      } catch (err) {}
      return data;
    });
};

const getIntfSecurityModeFlag = (device, vdom) => {
  return fiFmgHttp
    .post({
      url: '/gui/adom/dvm/device/interface',
      method: 'getsecurityMode',
      params: {
        devID: device.did ? parseInt(device.did) : parseInt(device.oid),
        vdom: vdom,
      },
    })
    .then(function (resp) {
      let data = [];
      try {
        data = resp[0].data;
        // eslint-disable-next-line
      } catch (err) {}
      return data;
    });
};

/**
 * Get all interfaces by given device list. The results are from db cache.
 * suit for a large number of device query. Not all fields are required in the request.
 * @param {Set|String []} devices a set or array of device names or device objects
 * @return {promise} the response data will be object if input devices is an object,
 *                   the response data will be array if input devices is an array
 * NOTE: NOW IT WILL NOT FILTER BY VDOM, IT WILL RETURN ALL INTERFACES OF DEVICES
 */
const getCachedDevicesInterfaces = async (devices, overrideParams = {}) => {
  const deviceArray = Array.from(devices);
  const params = deviceArray.reduce((acc, device) => {
    acc.push({
      name: getDeviceName(device),
      vdom: 'global',
    });
    return acc;
  }, []);

  const query = {
    id: 1,
    method: 'get',
    params: [
      {
        url: '/dbcache/system/interface',
        option: ['scope member'],
        'scope member': params,
        fields: [
          'name',
          'alias',
          'ip',
          'ipv6',
          'mode',
          'type',
          'vdom',
          'status',
          'estimated-upstream-bandwidth',
          'estimated-downstream-bandwidth',
          'fortilink',
          'role',
          'dhcp-relay-service',
          'dhcp-relay-type',
          'dhcp-relay-ip',
          'ingress-shaping-profile',
          'egress-shaping-profile',
          'member',
        ],
        current_adom: fiAdom.current().name,
        ...overrideParams,
      },
    ],
  };

  const resp = await fiFmgHttp.forward(query);
  let result;
  if (!resp || !resp[0].data) {
    result = [];
  } else {
    result = resp[0].data;
  }

  return result;
};

const getCachedDevicesInterfacesMap = async (devices) => {
  devices = castArray(devices);
  if (devices.length === 0) {
    return {};
  }

  const resp = await getCachedDevicesInterfaces(devices);
  return resp.reduce((intfMap, currIntf) => {
    if (!_.get(currIntf, 'scope member.0')) {
      return intfMap;
    }
    const devName = currIntf['scope member'][0].name;
    if (isNil(intfMap?.[devName])) {
      intfMap[devName] = {};
    }

    intfMap[devName][currIntf.name] = currIntf;
    return intfMap;
  }, {});
};

/*
 * Get zones of ONE device, response with map
 * @param {Object} scope - {name: device_name, vdom: [device_vdom_name]}
 */

/**
 * Get Device Zone Map for a single device
 * @param {{
 *  device: Device Object or Device Name,
 *  vdom: String []
 * }} device device and vdom of a single device
 * @returns {object} mapping of all device zones for a single device
 */
const getDeviceZoneMap = async ({ device, vdom }) => {
  if (isNil(device)) return {};
  const url = 'pm/config/device/%(deviceName)s/vdom/%(vdomName)s/system/zone';
  const _vdoms = Array.isArray(vdom) ? vdom : [vdom || 'root'];
  const req = {
    id: 1,
    method: 'get',
    params: _vdoms.reduce((params, vdom) => {
      params.push({
        url: url.printfd({ deviceName: getDeviceName(device), vdomName: vdom }),
      });
      return params;
    }, []),
  };

  const resp = await fiFmgHttp.forward(req);
  return resp.reduce((zoneMap, data, index) => {
    const currentVdom = _vdoms[index];
    const _data = data.data;
    let _zoneMap = zoneMap;
    // 0944069: It's possible to have different zones with same name
    // in different vdoms, so need to group by vdom to be processed correctly
    if (_vdoms.length > 1) {
      zoneMap[currentVdom] = {};
      _zoneMap = zoneMap[currentVdom];
    }
    for (const zone of _data) {
      zone.vdom = [currentVdom];
      zone._detail = gettext('Zone');
      zone.type = 'ui-zone';
      _zoneMap[zone.name] = zone;
    }

    return zoneMap;
  }, {});
};

/**
 * Get SDWAN Zone Map for a single device
 * @param {{
 *  device: Device Name String,
 *  vdom: String []
 * }} device device and vdom of a single device
 * @returns {object} mapping of all sdwan zones for a single device
 */
const getDeviceSdwanZoneMap = async ({ device, vdom }) => {
  if (!device || !isDevSdwanCate(device)) return {};
  const url =
    'pm/config/device/%(deviceName)s/vdom/%(vdomName)s/system/sdwan/zone';
  const _vdoms = Array.isArray(vdom) ? vdom : [vdom || 'root'];
  const req = {
    id: 1,
    method: 'get',
    params: _vdoms.reduce((params, vdom) => {
      params.push({
        url: url.printfd({ deviceName: getDeviceName(device), vdomName: vdom }),
      });
      return params;
    }, []),
  };

  const resp = await fiFmgHttp.forward(req);
  return resp.reduce((sdwanZoneMap, data, index) => {
    const currentVdom = _vdoms[index];
    const _data = data.data;
    let _sdwanZoneMap = sdwanZoneMap;
    // 0944069: It's possible to have different zones with same name
    // in different vdoms, so need to group by vdom to be processed correctly
    if (_vdoms.length > 1) {
      sdwanZoneMap[currentVdom] = {};
      _sdwanZoneMap = sdwanZoneMap[currentVdom];
    }
    for (const zone of _data) {
      zone.vdom = [currentVdom];
      zone._detail = gettext('SD-WAN Zone');
      zone.type = 'ui-sdwan-zone';
      _sdwanZoneMap[zone.name] = zone;
    }

    return sdwanZoneMap;
  }, {});
};

/**
 * Get SDWAN Zone Map with Members for a single device
 * @param {{
 *  device: Device,
 *  vdom: String []
 * }} device device and vdom of a single device
 * @returns {object} mapping of all sdwan zones for a single device
 */
const getDeviceSdwanZoneMapWithMembers = async ({ device, vdom }) => {
  const url = 'pm/config/device/%(deviceName)s/vdom/%(vdomName)s/system/sdwan';
  if (!device || !isDevSdwanCate(device)) return {};
  const _vdoms = Array.isArray(vdom) ? vdom : [vdom || 'root'];
  const req = {
    id: 1,
    method: 'get',
    params: _vdoms.reduce((params, vdom) => {
      params.push({
        url: url.printfd({ deviceName: device.name, vdomName: vdom }),
      });
      return params;
    }, []),
  };

  const resp = await fiFmgHttp.forward(req);
  return resp.reduce((zoneMap, obj, index) => {
    const currentVdom = _vdoms[index];
    const zones = obj.data ? obj.data.zone : [];
    const members = obj.data ? obj.data.members : [];

    for (const zone of zones) {
      zone.vdom = [currentVdom];
      zone._detail = gettext('SD-WAN Zone');
      zone.type = 'ui-sdwan-zone';
      zoneMap[zone.name] = zone;
    }

    for (const member of members || []) {
      const parentZoneName = member.zone[0];
      if (zoneMap[parentZoneName]) {
        const currentZone = zoneMap[parentZoneName];
        currentZone.interface = (currentZone.interface || []).concat(
          member.interface
        );
      }
    }

    return zoneMap;
  }, {});
};

/**
 * Get Device Zones for a single device
 * @param {{
 *  device: Device,
 *  vdoms: String []
 * }} device device and vdom of a single device
 * @param {Boolean} filterMember flag to indicate whether to filter out zone member and aggregate interface member
 * @returns {Object} mapping of all device zones for a single device if there is only one vdom,
 * or mapping of device zones for each vdom
 */
const getCachedDeviceInterfacesAndZoneMap = async (
  { device, vdoms },
  filterMember = false
) => {
  let [intfMapResp, zoneMap, sdwanZoneMap] = await Promise.all([
    getCachedDevicesInterfacesMap(device),
    getDeviceZoneMap({ device, vdom: vdoms }),
    getDeviceSdwanZoneMap({ device, vdom: vdoms }),
  ]);

  const deviceName = getDeviceName(device);
  let intfMap = intfMapResp[deviceName] || {};
  if (filterMember) {
    const zones = Object.values(zoneMap);
    for (const zone of zones) {
      {
        if (zone.interface && zone.interface.length) {
          for (const zoneMember of zone.interface) {
            delete intfMap[zoneMember];
          }
        }
      }
    }

    const aggregateIntfs = Object.values(intfMap).filter((intf) => {
      return intf.type === MACROS.PM2CAT.PM2_INTF_T_AGGREGATE;
    });

    for (const intf of aggregateIntfs) {
      if (intf.member && intf.member.length) {
        for (const member of intf.member) {
          delete intfMap[member];
        }
      }
    }
  }

  // 0944069: It's possible to have different zones with same name
  // in different vdoms, so need to group by vdom to be processed correctly
  if (Array.isArray(vdoms) && vdoms.length > 1) {
    return vdoms.reduce((map, vdom) => {
      map[vdom] = {
        // filter vdom intfMap to only include ones for current vdom
        ...Object.fromEntries(
          Object.entries(intfMap).filter(([, value]) =>
            (value.vdom || ['root']).includes(vdom)
          )
        ),
        ...(zoneMap[vdom] || zoneMap),
        ...(sdwanZoneMap[vdom] || sdwanZoneMap),
      };
      return map;
    }, {});
  }

  return {
    ...intfMap,
    ...zoneMap,
    ...sdwanZoneMap,
  };
};

const getSupportAttrs = async (fields = [], device, vdom) => {
  return fiFmgHttp
    .post({
      url: '/gui/adom/dvm/device/interface',
      method: 'attrSupport',
      params: {
        devID: device.did ? parseInt(device.did) : parseInt(device.oid),
        vdom: vdom && vdom !== 'global' ? vdom : 'root',
        fields: fields.reduce((acc, cur) => {
          acc[cur] = true;
          return acc;
        }, {}),
      },
    })
    .then(function (resp) {
      return resp[0]?.data ?? {};
    });
};

const getSnifferData = async (device, vdom) => {
  return fiFmgHttp
    .forward({
      method: 'get',
      params: [
        {
          url:
            'pm/config/device/' +
            escapeSlash(device.name) +
            '/vdom/' +
            vdom +
            '/firewall/sniffer',
        },
      ],
    })
    .then(function (resp) {
      let data = [];
      try {
        data = resp[0].data;
        // eslint-disable-next-line
      } catch (err) {}
      return data;
    });
};

const updateSnifferData = async (data, deviceName, vdom = 'root') => {
  return fiFmgHttp
    .query({
      method: 'set',
      params: [
        {
          url: `pm/config/device/${escapeSlash(
            deviceName
          )}/vdom/${vdom}/firewall/sniffer`,
          data: data,
        },
      ],
    })
    .then(function (resp) {
      let data = [];
      try {
        data = resp[0].data;
        // eslint-disable-next-line
      } catch (err) {}
      return data;
    });
};

const updateIntfIPV6 = async (obj, device) => {
  if (!obj) {
    return;
  }
  const url = `pm/config/device/${
    device.name
  }/global/system/interface/${escapeSlash(obj.name)}/ipv6`;
  const req = {
    method: 'set',
    params: [
      {
        url: url,
        data: obj.ipv6,
      },
    ],
  };

  return fiFmgHttp.query(req);
};

const updateIntfIPV6Vdom = async (obj, device, vdomName) => {
  if (!obj) {
    return;
  }
  if (!vdomName) {
    return updateIntfIPV6(obj, device);
  }
  const adomName = fiAdom.current().name;
  const url = `/gui/pm/config/global/adom/${adomName}/device/${
    device.name
  }/vdom/${vdomName}/system/interface/${escapeSlash(obj.name)}/ipv6`;
  const req = {
    method: 'set',
    url,
    params: {
      data: obj.ipv6,
    },
  };

  return fiFmgHttp.post(req);
};
