import { omit } from 'lodash';

import { workspacePoliciesAction } from './action';
/*
{
    //adomOid:pkgOid:categoryOid
    [path]:{
        loaded:true,
        "sequence":{
        },
        "records":{
            "202":{

            }
        }
    }
}
*/
function workspace_adom_policies_records_reducer(state, action) {
  const { id, fields } = action.payload.data.records;

  switch (action.type) {
    case workspacePoliciesAction.record.CHANGE: {
      return {
        ...state,
        [id]: fields,
      };
    }
    case workspacePoliciesAction.record.DELETE: {
      const newState = omit(state, id);
      return newState;
    }
    default: {
      return state;
    }
  }
}

function workspace_adom_policies_sequence_reducer(state, action) {
  const { fields } = action.payload.data.sequence;

  switch (action.type) {
    case workspacePoliciesAction.record.CHANGE: {
      return {
        ...state,
        ...fields,
      };
    }
    case workspacePoliciesAction.record.DELETE: {
      return {};
    }
    default: {
      return state;
    }
  }
}

function workspace_adom_policies_reducer(state, action) {
  const { sequence, records } = action.payload.data;

  let newState = {};

  if (records) {
    const curRecordsState = state['records'] || {};
    newState['records'] = workspace_adom_policies_records_reducer(
      curRecordsState,
      action
    );
  }
  if (sequence) {
    const curRecordsState = state['sequence'] || {};
    newState['sequence'] = workspace_adom_policies_sequence_reducer(
      curRecordsState,
      action
    );
  }
  return {
    ...state,
    ...newState,
  };
}

export function workspace_policies_reducer(state = {}, action) {
  switch (action.type) {
    case workspacePoliciesAction.fetch.SUCCESS: {
      const { path, data } = action.payload;
      return {
        ...state,
        [path]: {
          loaded: true,
          ...data,
        },
      };
    }
    case workspacePoliciesAction.record.CHANGE:
    case workspacePoliciesAction.record.DELETE: {
      const { path } = action.payload;
      const curState = state[path] || {};
      const newState = workspace_adom_policies_reducer(curState, action);
      return {
        ...state,
        [path]: newState,
      };
    }

    default: {
      return state;
    }
  }
}
