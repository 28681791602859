import { showAlertPanel } from 'react_apps/ra-auth/modals/alert';
import { dispatch } from 'fistore';
import { tryLogin } from 'fistore/auth/slice';
import { getLocalStorage, setLocalStorage } from 'fiutil/storage';
// pki status
// const LOGIN_PASS = 0;
// const LOGIN_BANNER = 1;
const LOGIN_OK_SSL_CERT = 2;
const LOGIN_OK_SSL_CERT_REQ_PSWD = 3;
const LOGIN_FAILED_HTTPS = 4;
const LOGIN_FAILED_CERT = 5;
// const LOGIN_FAILED_CERT_PSWD = 6;
// const LOGIN_FAILED_CERT_USER = 7;
// const LOGIN_FAILED_CERT_DN = 8;
// const LOGIN_FAILED_CERT_SERIAL = 9;
// const LOGIN_FAILED_CERT_VERIFY = 10;
// const LOGIN_FAILED_CERT_MISSING = 11;
// const LOGIN_FAILED_CERT_INVALID = 12;
// const LOGIN_FAILED_CERT_REVOKED = 13;
// const LOGIN_FAILED_CERT_EXPIRED = 14;

export const genPkiLogin = (onSuccess, pendingRef) => async (loginEnv) => {
  const pkiUser = loginEnv.pki_user;
  const pkiStatus = loginEnv.pki_status;
  // usually pkiStaus is LOGIN_FAILED_CERT_INVALID
  if (pkiUser) {
    if (pendingRef.current) {
      return true;
    }
    // 2factor login does not need to show this
    if (
      getLocalStorage('justLogout') &&
      pkiStatus !== LOGIN_OK_SSL_CERT_REQ_PSWD
    ) {
      pendingRef.current = true;
      setLocalStorage('justLogout', false);
      await showAlertPanel(
        gettext(
          'You have just logged out successfully. It is recommended to close the window before login again.'
        ),
        // no "OK" button
        []
      );
      return true;
    }

    if (pkiStatus === LOGIN_OK_SSL_CERT) {
      //pki login successful
      await dispatch(tryLogin({ pkiUser }));
      onSuccess && onSuccess();
      return true;
    }

    if (pkiStatus === LOGIN_OK_SSL_CERT_REQ_PSWD) {
      // pki two factor login, still require username and password
      return false;
    }

    if (pkiStatus === LOGIN_FAILED_HTTPS) {
      // HTTPS required
      await showAlertPanel('Please use HTTPS to access this device.');
      return true;
    }

    if (pkiStatus === LOGIN_FAILED_CERT) {
      await showAlertPanel('User certificate is invalid.');
      return true;
    }
  }

  // for other cases, move on to the login form.
  return false;
};
