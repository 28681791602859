import { validIP } from 'fiutil';
import { neowiseIpInputField } from './FmkIpV4Input';
import { NwIpV4V6Input } from '@fafm/neowise-core';

export const FmkIpV4V6Input = neowiseIpInputField(NwIpV4V6Input, {
  ipValidate: () => {
    return (value) => {
      if (!value || value === '::/0' || validIP(value)) return;
      return gettext('Invalid IP address.');
    };
  },
});
FmkIpV4V6Input.displayName = 'FmkIpV4V6Input';
