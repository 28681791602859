import { createSelector } from '@reduxjs/toolkit';

const myState = (state) => state.soc.viewLog;

export const getAvailableLogs = createSelector(
  myState,
  (state) => state.availableLogs
);

export const getViewnameLogtypes = createSelector(
  myState,
  (state) => state.viewnameLogtypes
);

export const getDisabledViews = createSelector(
  myState,
  (state) => state.disabledViews
);

export const getDisabledDashes = createSelector(
  myState,
  (state) => state.disabledDashes
);
