import { logdevWidgets } from 'kit-viewdata';
import { fiHttp } from 'fi-http';

const FETCH_TIMEOUT = 2000; // 2 SEC

export default class CoreViewDataService {
  constructor() {
    this.requestIds = {};
    this.sessionIds = {}; // for fortiview ha loading balance
    const getServerTimeUrl = '/p/fortiview/get_server_time/';
    //public methods
    // combine run and fetch
    /**
     * params:
     * api(required)
     * serverTime(required)
     * sort_fields(array, optional eg: [{field: "", order ""}])
     * adom(optional)
     * count(optional default: 50)
     * device(optional)
     * csfname(optional)
     */
    this.getDataByParams = (params) => {
      let runParams = this.getRunParams(params);
      return this._run(runParams).then(async (res) => {
        if (params.api && res.data && res.data.tid) {
          this.requestIds[runParams.runId] = res.data.tid;
          this.sessionIds[runParams.runId] = res.data['session-id'];
          return await this._fetch({
            runId: runParams.runId,
            apiName: runParams.apiName,
          });
        } else {
          console.error('getDataByParams, not calling fetch.');
        }
        return res;
      });
    };
    /**
     *
     * @param {
     * adom: string,
     * api: string,
     * runId: string, (if provided, will use as requestIds key instead of apiName)
     * sort_fields: array,
     * serverTime :{
     *   startTime: string,
     *    endTime: string
     * },
     * device: obj | string,
     * count: int,
     * filter(ddfltr and search): obj, ep : "{epid: 1, logtype: 2}"
     * } params
     */
    this.getRunParams = (params) => {
      if (params.api && params.serverTime && params.device) {
        return {
          runId: params.runId || params.api,
          adom: params.adom,
          apiName: params.api,
          startTime: params.serverTime.startTime,
          endTime: params.serverTime.endTime,
          count: params.count || 50,
          device: params.device.value || params.device,
          csfname: params.device.isCsf ? params.device.name : '',
          ddfltr: params.ddfltr || {},
          search: params.search || {},
          sort_fields:
            params.sort_fields && params.sort_fields.length
              ? params.sort_fields.reduce(
                  (acc, obj) => ({ ...acc, [obj.field]: obj.order }),
                  {}
                )
              : {},
          // custom Widget fortiview only
          grpby_fields: params['grpby_fields'] || '',
          agg_fields: params['agg_fields'] || '',
          proxiedServer: params['proxiedServer'],
          proxiedAdom: params['proxiedAdom'],
          isLoggingDev: logdevWidgets.includes(params.widgetName),
        };
      }
      return params;
    };
    // cancel
    this.cancel = (runId, api) => {
      let apiName = api || runId;
      this._cancel(runId, apiName);
    };
    this.formatDataResponse = (res) => {
      if (
        (res.meta && res.meta.status < 0) ||
        (res.data && res.data.code && res.data.code < 0)
      ) {
        return {
          isFinished: true,
          data: {},
          message:
            res.meta && res.meta.message
              ? res.meta.message
              : res.data && res.data.message
              ? res.data.message
              : 'get chart data error',
        };
      }
      if (!res.data || !res.data.length) {
        return {
          isFinished: true,
          data: {},
          message: '',
        };
      }
      return {
        isFinished: true,
        data: { ...res },
        message: '',
      };
    };
    this.getTableData = (oData, colDefs) => {
      let data = oData && oData.data ? oData.data : [];
      if (!colDefs) return data;
      let maxObj =
        oData && oData.meta && oData.meta['max-value']
          ? oData.meta['max-value']
          : null;
      return data.map((row) => {
        let colDatas = colDefs.reduce((acc, col) => {
          let colData = { [col.field]: row[col.field] };
          maxObj = maxObj || row.maxObj || {};
          if (col.max && maxObj && maxObj[col.max]) {
            colData.maxValue = maxObj[col.max];
          }
          let parsedData = col.parserFn
            ? col.parserFn(row, colData.maxValue)
            : {};
          return { ...acc, [col.field]: { ...colData, ...parsedData } };
        }, {});
        colDatas._oData = { ...row, maxObj: maxObj };
        return colDatas;
      });
    };
    this.getServerTimeByTimePeriod = (timePeriod) => {
      return fiHttp.get(getServerTimeUrl, {
        params: {
          unit: timePeriod.unit,
          n: timePeriod.num || timePeriod.lastN,
          startTime:
            timePeriod.end && timePeriod.end.length
              ? timePeriod.end.slice(0, 6).join(',')
              : '',
          endTime:
            timePeriod.start && timePeriod.start.length
              ? timePeriod.start.slice(0, 6).join(',')
              : '',
        },
      });
    };
  }
  // private methods
  _run(params) {
    if (params.apiName === 'assets') {
      let newParams = {
        count: params.count,
        search: 'epid=' + params.search.epid,
        serverTime: {
          startTime: params.startTime,
          endTime: params.endTime,
        },
        proxiedServer: params.proxiedServer,
        proxiedAdom: params.proxiedAdom,
      };
      return fiHttp.get('/p/util/endpoints/', { params: newParams });
    } else if (params.apiName === 'identity-center') {
      let newParams = {
        count: params.count,
        search: 'euid=' + params.search.euid,
        serverTime: {
          startTime: params.startTime,
          endTime: params.endTime,
        },
        proxiedServer: params.proxiedServer,
        proxiedAdom: params.proxiedAdom,
      };
      return fiHttp.get('/p/util/endusers/', { params: newParams });
    }
    return fiHttp.post('/p/fortiview/all/run/ajax/', params);
  }
  _fetch(params) {
    let runId = params.runId;
    let apiName = params.apiName;
    let requestId = this.requestIds[runId];
    let sessionId = this.sessionIds[runId];
    if (requestId) {
      return fiHttp
        .get('/p/fortiview/all/fetch/ajax/', {
          params: {
            id: requestId,
            view: apiName,
            sessionid: sessionId,
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.meta && data.meta && data.meta.status === 0) {
            if (data.meta.percentage === 100) {
              // success
              this._cancel(runId, apiName);
              return data;
            } else if (data.data && data.data.length) {
              // promise progress
              // return cached data
              return {
                ...data,
                continueFetch: setTimeout(() => {
                  return this._fetch({
                    runId: runId,
                    apiName: apiName,
                  });
                }, FETCH_TIMEOUT),
              };
            }
            // continue
            return setTimeout(() => {
              return this._fetch({
                runId: runId,
                apiName: apiName,
              });
            }, FETCH_TIMEOUT);
          } else {
            // error
            if (this.requestIds[runId]) {
              this._cancel(runId, apiName);
              return {
                data: {
                  code: data.meta.code || data.meta.status || -1,
                  message:
                    data.meta && data.meta.message
                      ? data.meta.message
                      : `"fetch data error " ${requestId} ${apiName}`,
                },
              };
            }
          }
        });
    }
  }
  _cancel(runId, apiName) {
    let requestId = this.requestIds[runId];
    let sessionId = this.sessionIds[runId];
    if (requestId) {
      this.requestIds[runId] = undefined;
      this.sessionIds[runId] = undefined;
      return fiHttp
        .get('/p/fortiview/all/cancel/ajax/', {
          params: {
            id: requestId,
            view: apiName,
            sessionid: sessionId,
          },
        })
        .then((resp) => resp);
    }
    return;
  }
}
