import { mapKeys, isNil, negate } from 'lodash';
import {
  take,
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  putResolve,
} from 'redux-saga/effects';
import { setMessageBox } from '../main-frame/slice';

import {
  setDockerStatus,
  setCliDockerStatus,
  startLoadingExtension,
  fetchCliDockerStatus,
  fetchDockerStatus,
  enableDockerExtension,
} from './slice';
import { getCliDockerStatus, getDockerStatus } from './selectors';
import { runIfAvailable } from '../routing/saga';
import {
  getIsDockerHost,
  getIsRestrictedAdmin,
} from 'fistore/session/sysConfig/selectors';
import { appInitDone, refreshAppTree } from 'fistore/routing/slice';
import { callPromiseAction } from '../utils';
import * as api from './api';

const mrSys = MACROS.SYS;

// Map the docker api status to the appUniKey.
const DOCKER_PREFIX = 'docker_';

function* fetchDockerStatusSaga() {
  try {
    // request fails for restricted admin
    const resp = yield call(api.fetchDockerExtStatus);
    const mapped = mapKeys(resp, (_, key) => DOCKER_PREFIX + key);
    yield put(setDockerStatus(mapped));
    const cliStatusResp = yield putResolve(fetchCliDockerStatus());
    const cliStatus = cliStatusResp['status'];
    yield put(setCliDockerStatus(cliStatus));
    yield put(refreshAppTree());
  } catch (ex) {
    console.error(ex);
  }
}

function getDockerMessages({ app }) {
  return {
    in_progress: gettext(
      'The system is downloading %(product)s. Please wait for a few moments.'
    ).printfd({
      product: app ? app.label : gettext('the extension'),
    }),
    product: app ? app.label : gettext('the extension'),
    no_perm: gettext(
      'You do not have permission to enable %(product)s'
    ).printfd({}),
    pull: {
      [mrSys.CFG_ER_FORTICLOUD_UNREGISTERED]: gettext(
        'You need to register this FortiManager on FortiCloud to use docker features.'
      ),
      [mrSys.CFG_ER_DOCKER_NET_CONFLICT]: gettext(
        'Docker network conflicts with an interface.'
      ),
      [mrSys.CFG_ER_DOCKER_MISSING]: gettext('Missing docker.'),
      [mrSys.CFG_ER_DOCKER_INVALID]: gettext('Invalid docker.'),
      [mrSys.CFG_ER_DOCKER_DISABLED]: gettext('Docker is not enabled.'),
      [mrSys.CFG_ER_DOCKER_INTERNAL]: gettext('Internal error.'),
      [mrSys.CFG_ER_DOCKER_PULL]: gettext('Cannot pull new image.'),
      [mrSys.CFG_ER_DOCKER_START]: gettext('Cannot start new image.'),
    },
  };
}

const getDockerKey = (appKey) => {
  return appKey.slice(DOCKER_PREFIX.length);
};

function* enableDockerExtensionSaga(action) {
  yield call(fetchDockerStatusSaga);
  const app = action.payload;
  const dockerStatus = yield select(getCliDockerStatus);
  const appStatus = (yield select(getDockerStatus))[app.key].status;
  const dockerKey = getDockerKey(app.key);
  yield put(startLoadingExtension(app.key));
  yield put(refreshAppTree());
  try {
    yield dockerStatus === mrSys.DOCKER_ST_DISABLED
      ? call(api.enableDockerAndExtension, dockerKey)
      : call(
          api.enableExtension,
          dockerKey,
          appStatus === mrSys.DOCKER_ST_FAILED
        );
  } catch (e) {
    console.error(e);
    const errorCode = e.code ? 60000 + e.code : 0;
    if (isNil(e.closed)) {
      const msg =
        getDockerMessages(app).pull[errorCode] ||
        gettext(
          'Failed to enable extension, please make sure the system is accessible to the internet.'
        );
      yield put(setMessageBox(`${app.label}: ${msg}`, 'danger', 0));
    }
  }
  yield call(fetchDockerStatusSaga);
}

function* watchDockerStatus() {
  yield takeLatest(fetchDockerStatus.type, fetchDockerStatusSaga);
  yield takeEvery(enableDockerExtension.type, enableDockerExtensionSaga);
  yield takeLatest(fetchCliDockerStatus.type, function* (action) {
    yield callPromiseAction(
      action,
      runIfAvailable(negate(getIsRestrictedAdmin), function* () {
        return yield call(api.fetchCliDockerStatus);
      })
    );
  });
  // fetch docker status if "docker" app is available
  // runs after system intialized
  yield take(appInitDone);
  yield call(
    runIfAvailable(negate(getIsRestrictedAdmin), fetchDockerStatusSaga)
  );
}

export function* watchDocker() {
  yield call(runIfAvailable(getIsDockerHost, watchDockerStatus));
}
