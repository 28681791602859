// don't define `children` in child routes
import { useLocation } from 'react-router-dom';
import { RenderErrorBoundary } from 'react_apps/components/main/RenderErrorBoundary';
import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';

const ipsec_children = [
  {
    path: 'ipsec_central',
    handle: {
      appUniKey: 'vpn_ipsec_commcentral',
    },
    lazy: () =>
      import(
        /* webpackChunkName: "mod-vpn-ipsec-central" */ './enableCentralVPN'
      ).then(({ EnableCentralVPNPage }) => ({
        Component: EnableCentralVPNPage,
      })),
  },
  {
    path: 'ipsec_list',
    handle: {
      appUniKey: 'vpn_ipsec_commlist',
    },
    lazy: () =>
      import(
        /* webpackChunkName: "mod-vpn-ipsec-list" */ './ipsec_vpn/ipsec_community/VPNCommunityTable'
      ).then(({ VPNCommunityTable }) => ({ Component: VPNCommunityTable })),
  },
  {
    path: 'ipsec_map',
    handle: {
      appUniKey: 'vpn_ipsec_map',
    },
    lazy: () =>
      import(
        /* webpackChunkName: "mod-vpn-ipsec-monitor" */ './ipsec_vpn/ipsec_map_monitor/VPNMonitorMap'
      ).then(({ VPNMonitorMap }) => ({
        Component: () => {
          const state = useLocation().state;
          return (
            <VPNMonitorMap
              rInDVM={false}
              rIsMonitor={state?.type === 'monitor'}
            />
          );
        },
      })),
  },
];

const ssl_children = [
  {
    path: 'ssl_list',
    handle: {
      appUniKey: 'vpn_ssl_setting',
    },
    lazy: () =>
      import(
        /* webpackChunkName: "mod-vpn-ssl-list" */ './ssl_vpn/ssl_setting/SSLSettingView'
      ).then(({ SSLSettingView }) => ({ Component: SSLSettingView })),
  },
  {
    path: 'ssl_portals',
    handle: {
      appUniKey: 'vpn_ssl_ptprof',
    },
    lazy: () =>
      import(
        /* webpackChunkName: "mod-vpn-ssl-portal" */ './ssl_vpn/portal_profile/PortalProfileView'
      ).then(({ PortalProfileView }) => ({ Component: PortalProfileView })),
  },
  {
    path: 'ssl_monitor',
    handle: {
      appUniKey: 'vpn_ssl_monitor',
    },
    lazy: () =>
      import(
        /* webpackChunkName: "mod-vpn-ssl-monitor" */ './ssl_vpn/ssl_monitor/SSLVPNConnectionTable'
      ).then(({ SSLVPNConnectionTable }) => ({
        Component: SSLVPNConnectionTable,
      })),
  },
];

export default {
  path: 'vpn',
  id: 'vpn',
  handle: {
    appUniKey: 'vpn',
  },
  RenderErrorBoundary,
  children: [getRedirectIndexRoute(), ...ipsec_children, ...ssl_children],
};
