import $ from 'jquery';
import { fiHttpGet, fiFmgHttp } from 'fi-http';
import { fiDeviceDataLoader } from './device_data_loader';
import { resProdToDisp } from 'fi-dev-conversion';
import { fiDeviceDataFetcher } from './device_data_fetcher';
import { csfReg } from 'fi-dev-format';
import { get, isEmpty } from 'lodash';

export {
  isHA,
  getDevGroups,
  getQueryDeviceGroups,
  getAllDeviceGroupName,
  deviceChoicesWithCsf,
  devicesAndGroups,
  deviceChoices,
  csfRoots,
  getDevCsf,
  getFabricMemberDevs,
  getLogGroups,
  fromBkndLogGroup,
  getSnToNameMap,
  globalDevices,
  globalDevicesFromFmg,
};

const isHA = (dev) => dev.connection && dev.connection.ha_mode;

const getQueryDeviceGroups = async (devicesArr) => {
  if (!devicesArr) {
    return Promise.resolve(devicesArr);
  }

  return getDevGroups().then((devGrps) => {
    return devicesArr.reduce((accu, curr) => {
      let dev = devGrps.find((d) => d.name === curr);
      if (dev) {
        accu.push(`${MACROS.DVM.DEV_GRP_PREFIX}${curr}`);
      } else {
        accu.push(curr);
      }
      return accu;
    }, []);
  });
};

const getDevGroups = async () => {
  await fiDeviceDataLoader.isReady();
  return fiDeviceDataLoader
    .getFazDeviceGroups()
    .then((groups) => groups.filter((g) => g.oid >= 0))
    .then((groups) => {
      const groupsMap = groups.reduce((accu, curr) => {
        // handle devices in devMemberList first
        // curr._os_types = getOsTypes(curr.devMemberList || []);
        curr._isDgp = true;
        accu[curr.oid] = curr;
        return accu;
      }, {});

      // loop again to handle group members in grpMemberList
      groups.forEach((g) => {
        g._os_types = getNestedDevGroupOsTypes(g, groupsMap);
      });
      return groups;
    });
};

const getAllDeviceGroupName = (resProd) => {
  const name = resProdToDisp(resProd);
  return name ? `All_${name}` : null;
};

// CSF devices are grouped into CSF group. Individual
// root and members do not show up in the returned devices list.
//
// If all CSF root and children are unwanted, pass in
// {'csf': false}.
//
// If you want CSF devices to be prefixed with "csf:", pass in
// {'addPrefix': true}.
//
// If you want to disable the dev type like |0| after the value, pass in
// {'addTypeSuffix': false}. This does not affect Vdoms in .chlidren
// because they always need the suffix for their vdom name.
//
// If you want to have CSF members as sub-options, pass in
// {'csfDetails': true}
//
// If ADOMs should not be attached as children of every device, pass in
// {'noAdom': true}
const deviceChoicesWithCsf = async (opts) => {
  opts = Object.assign(
    {
      csf: true,
      addPrefix: false,
      addDgpPrefix: true, // event handler does not need the "devgrp:" prefix!
      addTypeSuffix: true,
      extendVdomLabel: false,
    },
    opts
  );
  const fromBkndDevGroup = fromBkndDevGroupFn(opts);
  function labelVdom(vdom, dev) {
    if (opts.extendVdomLabel) {
      return dev['name'] + '[' + vdom['name'] + ']';
    }
    return vdom['name'];
  }
  return Promise.all([
    getDevCsf({ csfDetails: opts.csfDetails }).then(function (devs) {
      return devs
        .filter((d) => d.sn !== '')
        .reduce(function (result, dev) {
          if (dev.isCsf && opts.csf === false) {
            // skip csf if unwanted.
            return result;
          }
          let name = dev['name'];
          if (dev.isCsf && opts.addPrefix && name && !csfReg.test(name)) {
            name = MACROS.DVM.DEV_CSF_PREFIX + name;
          }
          let choice = {
            ha_mode: isHA(dev) || null,
            label: dev['name'],
            value: opts.addTypeSuffix
              ? normalDeviceId(name, MACROS.FAZ_DVM.DEVICE_TYPE_DEV, '')
              : name,
            ip: dev['ip'],
            platform_str: dev['platform'],
            os_type: dev.os_type,
            isCsf: dev.isCsf,
            sn: dev.sn,
          };
          if (dev.vdom_status === 1 && !opts.noAdom) {
            choice['children'] = (dev['vdoms'] || []).map(function (vdom) {
              return {
                platform_str: 'VDOM',
                label: labelVdom(vdom, dev),
                value: normalDeviceId(
                  dev['name'],
                  MACROS.FAZ_DVM.DEVICE_TYPE_DEV,
                  vdom['name']
                ),
                os_type: dev.os_type,
              };
            });
          }
          if (dev.isCsf && opts.csfDetails) {
            choice['children'] = ((dev && dev.memberDetails) || []).map(
              (member) => {
                return {
                  platform_str: member.platform || gettext('Fabric Member'),
                  label: member.name,
                  value: normalDeviceId(
                    member.name,
                    MACROS.FAZ_DVM.DEVICE_TYPE_DEV,
                    ''
                  ),
                  ip: member.ip || '',
                  os_type: member.os_type,
                  sn: member.sn,
                };
              }
            );
          }
          result.push(choice);
          return result;
        }, []);
    }),
    getLogGroups().then((grps) => grps.map(fromBkndLogGroup)),
    getDevGroups().then((grps) => grps.map(fromBkndDevGroup)),
  ]).then(function (resp) {
    return {
      devices: resp[0],
      groups: resp[1],
      devGroups: resp[2],
    };
  });
};

const devicesAndGroups = async (opts) => {
  return Promise.all([getDevCsf(opts), getLogGroups(), getDevGroups()]).then(
    function (resp) {
      return {
        devices: resp[0],
        groups: resp[1],
        devGroups: resp[2],
      };
    }
  );
};
// Old device choices API, you get all devices.
// Even if a device is CSF root or member,
// it is treated as regular device.
// For new development we'll prefer fiDeviceDataLoader.getDevices()
const deviceChoices = async () => {
  const { DEVICE_TYPE_DEV, DEVICE_TYPE_GRP } = MACROS.FAZ_DVM;

  return Promise.all([
    fiDeviceDataLoader.getDevicesFromCache(),
    getLogGroups(),
  ]).then((resp) => {
    let [devices, groups] = resp;
    return {
      devices: devices.map((dev) => {
        let ret = {
          ha_mode: dev.connection.ha_mode,
          ip: dev.ip,
          label: dev.name,
          os_type: dev.os_type,
          platform_str: dev.platform,
          value: normalDeviceId(dev.name, DEVICE_TYPE_DEV, ''),
        };
        dev.vdoms &&
          (dev.children = dev.vdoms.map((vdom) => ({
            platform_str: 'VDOM',
            label: vdom.name,
            value: normalDeviceId(dev.name, DEVICE_TYPE_DEV, vdom.name),
          })));
        return ret;
      }),
      groups: groups.map((grp) => ({
        value: normalDeviceId(grp.uuid, DEVICE_TYPE_GRP, ''),
        label: grp.name,
        platfrom_str: gettext('Log Group'),
      })),
    };
  });
};

const csfRoots = async () => {
  return getDevCsf().then(function (devs) {
    return devs.filter(function (dev) {
      return dev.isCsf;
    });
  });
};

const getDevCsf = async (opts) => {
  let devByName = {};
  const collator = Intl.Collator({ sensitivity: 'base' });
  return fiDeviceDataLoader.isReady().then(function () {
    return Promise.all([
      fiDeviceDataLoader.getDevicesFromCache(),
      fiDeviceDataFetcher.getCsfGroups(),
    ])
      .then(function (resps) {
        let devices = resps[0];
        let csfs = resps[1];

        let result = [];
        let memberById = {};
        devices.forEach(function (dev) {
          dev.connection._cid && (dev._sn = dev.connection._cid);
          if (opts && opts.csfDetails) {
            devByName[dev && dev.name] = dev;
          }
          if (csfs.memberIds.has(dev._fiDeviceId)) {
            memberById[dev._fiDeviceId] = dev;
          } else {
            dev.isCsf = 0;
            result.push(dev);
          }
        });

        (
          (csfs._groups &&
            !isEmpty(csfs._groups) &&
            Object.values(csfs._groups)) ||
          []
        ).forEach((grp) => {
          let rootDev = memberById[grp.rootId];
          // add this since bug#516716
          if (!rootDev) {
            return;
          }

          let csf = {
            isCsf: 1,
            name: grp.name,
            root: grp.root.split('::')[0],
            sn: rootDev.sn,
            members: grp.members.map(function (member) {
              return member.split('::')[0];
            }),
            os_type: rootDev.os_type,
          };
          if (opts && opts.csfDetails) {
            csf.memberDetails = [];
            let knownNames = new Set();
            csf.members.forEach((devName) => {
              let devObj = devByName[devName];
              if (devObj && !knownNames.has(devName)) {
                csf.memberDetails.push(devObj);
                knownNames.add(devName);
              }
            });
          }
          // keep the member devices' detail
          if (opts && opts.csfHA) {
            csf._members = [];
            // remove duplicated
            let memberIds = grp.memberIds.filter(function (item, idx, arr) {
              return arr.indexOf(item) == idx;
            });
            $.each(devices, function (idx, dev) {
              if (csf._members.length >= memberIds.length) {
                return false;
              }

              if (memberIds.indexOf(dev._fiDeviceId) !== -1) {
                csf._members.push(dev);
              }
            });
          }
          result.push(csf);
        });
        return result;
      })
      .then(function (resp) {
        if (resp) {
          let retData = resp;
          retData.forEach(function (dev) {
            dev['type'] = MACROS.FAZ_DVM.DEVICE_TYPE_DEV; // device
            if (dev.isCsf) {
              if (!dev['platform_str']) {
                dev['platform_str'] = gettext('Security Fabric');
              }
            } else {
              dev['platform_str'] = dev.platform;
            }
          });
          retData.sort(function compare(a, b) {
            return collator.compare(a.name, b.name);
          });
          return retData;
        } else {
          console.error('unable to load devices');
          throw resp;
        }
      });
  });
};

const getFabricMemberDevs = () => {
  return fiHttpGet('/p/fabric/socfabric/dvm/');
};

const getLogGroups = () => {
  return fiHttpGet('/p/util/log_groups/');
};

const fromBkndLogGroup = (grp) => {
  return {
    name: grp.name,
    value: normalDeviceId(grp.uuid, MACROS.FAZ_DVM.DEVICE_TYPE_GRP, ''),
    label: grp['name'],
    platform_str: gettext('Log Group'),
    os_type: grp['_os_type'],
  };
};

const getSnToNameMap = (params) => {
  const { haCid } = params;
  // if haCid= true, HA cid is the key instead of sn
  const keyFn = haCid ? (d) => d.connection._cid || d.sn : (d) => d.sn;
  return fiDeviceDataLoader
    .getDevicesFromCache()
    .reduce(function (map, device) {
      map[keyFn(device)] = device.name;
      return map;
    }, {});
};

const globalDevices = async () => {
  return fiHttpGet('/p/logforwarding/device/all/get/');
};

const globalDevicesFromFmg = async () => {
  let req = {
    method: 'get',
    url: '/gui/alladom/list',
    params: {
      includeMembers: true,
    },
  };
  return fiFmgHttp.post(req).then((resp) => get(resp, '0.data', []));
};

/** Helpers **/
const getOsTypes = (devs = []) => {
  return devs.reduce((accu, curr) => (accu.add(curr.os_type), accu), new Set());
};
const getNestedDevGroupOsTypes = (devGroup, groupsMap) => {
  let grpOsTypes, devOsTypes;
  if (devGroup.grpMemberList) {
    grpOsTypes = devGroup.grpMemberList.reduce((accu, m) => {
      const mOsTypes = getNestedDevGroupOsTypes(groupsMap[m.oid], groupsMap);
      mOsTypes.forEach(accu.add, accu);
      return accu;
    }, new Set());
  }
  if (devGroup.devMemberList) {
    devOsTypes = getOsTypes(devGroup.devMemberList);
  }

  if (grpOsTypes || devOsTypes) {
    return new Set([...(grpOsTypes || []), ...(devOsTypes || [])]);
  }

  // no devMemberList, neither grpMemberList
  return new Set([devGroup.ostype]);
};

const fromBkndDevGroupFn = (opts) => {
  const { addDgpPrefix } = opts;
  return (grp) => {
    let name = addDgpPrefix
      ? `${MACROS.DVM.DEV_GRP_PREFIX}${grp.name}`
      : grp.name;
    return {
      name: grp.name,
      value: name + '|' + MACROS.FAZ_DVM.DEVICE_TYPE_DGP + '|',
      label: grp.name,
      platform_str: gettext('Device Group'),
      _os_types: new Set([grp.ostype]),
      _isDgp: grp._isDgp,
    };
  };
};

const normalDeviceId = (name, devtype, vdom) => {
  return `${name}|${devtype}|${vdom}`;
};
