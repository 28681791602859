import { negate } from 'lodash';
import { fiStoreRouting as fr, fiSession, fiStoreDocker } from 'fistore';

const { hasDockerEnabledAndDownloaded } = fiStoreDocker;
const {
  inGlobalAdom,
  hasRDPermitOn,
  getIsFazSupervisor,
  getIsRestrictedAdmin,
} = fiSession;
const mrPRIV = MACROS.USER.DVM;
const mrSys = MACROS.SYS;

export const checkDisabled = (app, state) => {
  // Gets the docker key with status key.
  const status = fr.getDockerStatus(state)?.[app.key]?.status;
  return [mrSys.DOCKER_ST_DISABLED, mrSys.DOCKER_ST_FAILED].includes(status);
};

export default {
  docker: {
    label: gettext('Management Extensions'),
    icon: 'device-manager',
    color: '',
    state: 'adom.default.extension.dashboard',
    reloadState: 'adom.default.extension.dashboard',
    path: '/extension/dashboard',
    showCheckers: [
      negate(inGlobalAdom),
      negate(getIsFazSupervisor),
      hasRDPermitOn(mrPRIV.ADMINPRIV_DOCKER_EXTENSION),
      hasDockerEnabledAndDownloaded, //per mantis 0903927
      negate(getIsRestrictedAdmin),
    ],
  },
};
