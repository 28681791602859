import EventSubject from './event_subject';
import Heartbeat from './heart_beat';
import { AsyncMsgQueue } from './queue';
import SocketIntf from './socket_intf';

export const conn_state = {
  CONNECTING: 1,
  CONNECTED: 2,
  DISCONNECTED: 3,
};

export default class Socket {
  rawSocket: SocketIntf | null;
  heartbeat: any;
  closed: any;
  state: any;
  msgQue: AsyncMsgQueue;
  _eventSubject: EventSubject;
  constructor(private SocketConstructor: () => SocketIntf | null) {
    this.rawSocket = null;
    this.heartbeat = null;
    this.closed = false;
    this.state = conn_state.DISCONNECTED;
    this._eventSubject = new EventSubject();

    this.msgQue = new AsyncMsgQueue((message: any) => {
      try {
        const msg = JSON.parse(message.data);

        this.eventSubject.publish('message:in', msg);
      } catch (e) {
        // eslint-disable-next-line
      }
    });
  }
  get eventSubject() {
    return this._eventSubject;
  }
  disconnected() {
    return this.state === conn_state.DISCONNECTED;
  }
  open() {
    if (this.rawSocket) return;

    try {
      this.rawSocket = this.SocketConstructor();
    } catch (e) {
      this.eventSubject.publish('connect:error');
      return;
    }

    this.state = conn_state.CONNECTING;
    if (!this.rawSocket) return;

    this.rawSocket.onopen = () => {
      this.state = conn_state.CONNECTED;

      if (this.heartbeat) this.heartbeat.clear();
      this.heartbeat = new Heartbeat();
      this.heartbeat.eventSubject.on('maxMissed', () => {
        if (!this.rawSocket) return;

        this.rawSocket.close();
        this.rawSocket = null;
      });

      this.heartbeat.start(() => {
        if (!this.rawSocket) return;
        try {
          this.rawSocket.send(this.heartbeat.msg());
        } catch (e) {
          //pass here
        }
      });

      this.eventSubject.publish('open');
    };

    this.rawSocket.onclose = () => {
      this.state = conn_state.DISCONNECTED;

      this.rawSocket = null;
      this.eventSubject.publish('close');
      //this.emit('close');
    };
    this.rawSocket.onerror = () => {
      this.rawSocket?.close();
      this.rawSocket = null;
    };
    this.rawSocket.onmessage = (message) => {
      // reset the counter for missed heartbeats
      if (this.heartbeat) this.heartbeat.resetCount();
      if (this.heartbeat.match(message.data)) {
        return;
      }

      this.msgQue.push(message);
    };
  }
  onOpen(callback: any) {
    this.eventSubject.on('open', callback);
  }
  send(object: any) {
    try {
      if (typeof object === 'string') {
        this.rawSocket?.send(object);
      } else {
        const message = JSON.stringify(object);
        this.rawSocket?.send(message);
      }

      this.eventSubject.publish('message:out', object);
    } catch (e) {
      // pass here
    }
  }
  close() {
    this.closed = true;

    if (!this.rawSocket) return;

    this.rawSocket.close();
    this.rawSocket = null;
  }
  onClose(callback: any) {
    this.eventSubject.on('close', callback);
  }
  onMessage(callback: any) {
    this.eventSubject.on('message:in', callback);
  }
  onConnectError(callback: any) {
    this.eventSubject.on('connect:error', callback);
  }
}
