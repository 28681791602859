import React from 'react';
import './toolbar.less';

const LogListToolbar = (props) => {
  return (
    <div
      className='log-list-toolbar-container nwp-toolbar'
      style={{ padding: '0 0.25rem' }}
    >
      <div className='toolbar-top tw-flex tw-justify-between'>
        <div className='toolbar-top-left tw-flex'>{props.topLeft}</div>
        <div className='toolbar-top-right tw-flex tw-items-center tw-px-5px tw-py-0'>
          {props.topRight}
        </div>
      </div>
      <div className='toolbar-bottom tw-flex tw-justify-between'>
        {props.bottom || ''}
      </div>
    </div>
  );
};

export default LogListToolbar;
