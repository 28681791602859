import { castArray, isObject } from 'lodash';
import { fiAdom } from 'fi-session';
import { fiDeviceRedux } from './device_redux';
import { dispatch, fiDevicesAction, fiDevicesSelector, fiStore } from 'fistore';

export const fiDeviceDBFetcher = {
  fetchFullDeviceList,
  reloadDevices,
  reloadOneDevice,
  reloadMultipleDevices,
  loadExtraDeviceData,
  reloadDevicesByCond,
};

let _currentAdom = null;

/**
 * reload local device db data from service side by websocket
 */
async function reloadDevices() {
  // no adom is needed, it will get current adom from store.
  await dispatch(fiDevicesAction.fetchDeviceChecksum());
  const checksum = fiDevicesSelector.getDeviceChecksum(fiStore.getState());
  if (
    !checksum ||
    checksum.dvmChecksum !== checksum.oldDvmChecksum ||
    checksum.adomChecksum !== checksum.oldAdomChecksum
  ) {
    fetchFullDeviceList(fiAdom.current().oid, true);
    return true;
  }
  // console.log('No need to reload');
  return false;
}

function reloadOneDevice(devOid, adomOid) {
  adomOid = adomOid || currentAdom().oid;
  return fiDeviceRedux.loadDevice(adomOid, devOid);
}

function reloadMultipleDevices(devOids, adomOid) {
  if (!devOids || devOids?.length === 0) return;
  adomOid = adomOid || currentAdom().oid;
  return fiDeviceRedux.loadMultipleDevices(adomOid, devOids);
}

/**
 * Fetches all devices of the current ADOM and puts them into database
 * @param {oid} integer adom oid
 * @return {Promise}
 */
function fetchFullDeviceList(adomOid, forceReload = false) {
  return fiDeviceRedux.loadDevices(adomOid, forceReload);
}

function loadExtraDeviceData(adomOid, forceReload = false) {
  return Promise.all([
    fiDeviceRedux.loadAssignedPkgs(adomOid, forceReload),
    fiDeviceRedux.loadLicenses(adomOid, forceReload),
    fiDeviceRedux.loadFirmwares(forceReload),
  ]);
}

function currentAdom(adomUpdated) {
  if (!_currentAdom || adomUpdated) {
    _currentAdom = fiAdom.current();
  }
  return _currentAdom;
}

async function reloadDevicesByCond({
  devices,
  adomOid,
  reloadAll = false,
  reloadPkgs = true,
} = {}) {
  adomOid = adomOid || fiAdom.current().oid;

  const fns = {
    reloadPkgs: {
      fn: () => {
        return loadExtraDeviceData(adomOid, true);
      },
      match: () => reloadPkgs,
    },
    reloadSome: {
      fn: () => {
        const oids = castArray(devices).map((item) => {
          if (isObject(item)) {
            return item._oData ? item._oData._oid : item.oid;
          }
          return item;
        });
        const filtered = oids.filter(Boolean);
        return reloadMultipleDevices(filtered, adomOid);
      },
      match: () => !reloadAll && devices,
    },
    reloadAll: {
      fn: () => {
        return reloadDevices();
      },
      match: () => reloadAll,
    },
  };

  const promises = Object.values(fns).reduce((acc, { fn, match }) => {
    if (match && match()) return [...acc, fn()];
    return acc;
  }, []);

  const resps = await Promise.all(promises);
  return resps;
}
