import { getAssetGroups, OSTYPES } from '../constants';
import { getUpgradeToString } from './parse_util';
import { getIconColor } from 'ra_dvm_util';

const localWifiPattern = '-WIFI0';

export class AssetDeviceParser {
  constructor({ device, allPsirtData }) {
    this.device = device;
    this.allPsirtData = allPsirtData;
    this.deviceGroup = this._getDeviceGroup();
  }

  _getDeviceGroup() {
    const deviceGroup = getAssetGroups()[this.device._gui_os_type];
    return deviceGroup;
  }

  _isFap() {
    return [OSTYPES.FAP, OSTYPES.FAPC, OSTYPES.FAPS, OSTYPES.FAPU].includes(
      this.device._gui_os_type
    );
  }

  _isLocalWifi() {
    const wtpId = this.device['wtp-id'];
    if (!wtpId) return false;
    const len = wtpId.length;
    if (len < localWifiPattern.length) return false;
    const idStr = wtpId.substring(len - localWifiPattern.length);

    return idStr === localWifiPattern;
  }

  _getFapPlatform() {
    const faps = [
      { prefix: 'fp', platform: 'FortiAP' },
      { prefix: 'fap', platform: 'FortiAP' },
      { prefix: 'pu', platform: 'FortiAP-U' },
      { prefix: 'ps', platform: 'FortiAP-S' },
      { prefix: 'fc', platform: 'FortiAP-C' },
      { prefix: 'p', platform: 'FortiAP' }, //PxxxCR
      { prefix: 'f', platform: 'FortiAP' },
    ];

    const serial = this.device['wtp-id']?.toLowerCase();
    if (!serial) return;

    for (const pair of faps) {
      if (serial.startsWith(pair.prefix)) return pair.platform;
    }
    return;
  }

  _shouldParseAssetDevice() {
    if (
      !this.deviceGroup ||
      (!this.device[this.deviceGroup.firmwareKey] &&
        !this.device[this.deviceGroup.enforceFirmwareKey])
    )
      return false;
    if (this._isFap()) {
      if (this._isLocalWifi()) return false;
      const fapPlatformName = this._getFapPlatform();
      if (fapPlatformName !== this.deviceGroup?.platformName) return false;
    }

    const { vulnsArr } = this._getMetadata();
    return Array.isArray(vulnsArr) && vulnsArr.length > 0;
  }

  _getAssetVersion() {
    if (this.deviceGroup.enforceFirmwareKey) {
      const firmwareStr = this.device[this.deviceGroup.enforceFirmwareKey];
      if (firmwareStr) return firmwareStr.split('-')[0];
    }
    const firmwareStr = this.device[this.deviceGroup.firmwareKey];
    return firmwareStr.split('-')[1].slice(1);
  }

  _getMetadata() {
    const { byPlatform: irNumsByPlatform } = this.allPsirtData;
    const currentVersion = this._getAssetVersion();
    const platformName = this.deviceGroup.platformName;
    const platformKey = platformName + '_' + currentVersion;
    const vulnsArr = irNumsByPlatform[platformKey];

    return {
      vulnsArr,
      currentVersion,
      platformKey,
    };
  }

  parse() {
    if (!this._shouldParseAssetDevice()) return;
    const parsedDev = { ...this.device };
    const { vulnsArr, currentVersion, platformKey } = this._getMetadata();
    const { platformName, name: deviceTypeName } = this.deviceGroup;

    if (platformName === 'FortiSwitch' && !parsedDev.name) {
      //handle fsw
      parsedDev.name = parsedDev['switch-id'];
    }

    parsedDev.vulns = vulnsArr;
    parsedDev.key = parsedDev.name + currentVersion;
    parsedDev.platformKey = platformKey;
    parsedDev.deviceType = getAssetGroups()[parsedDev._gui_os_type].name;
    parsedDev.osType = getAssetGroups()[parsedDev._gui_os_type].osType;
    parsedDev.indent = true;

    if (parsedDev.osType === OSTYPES.FAP) {
      parsedDev.iconColor = getIconColor(parsedDev['_conn-state']);
    }

    parsedDev.gui_upgrade_to = getUpgradeToString({
      currentVersion,
      platformName,
      vulnsArr,
      deviceTypeName,
      allPsirtData: this.allPsirtData,
    });

    return parsedDev;
  }
}
