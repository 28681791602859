import {
  fiDevicesAction,
  fiStoreObserverBase,
  fiStoreConnector,
  fiStoreClassConnector,
} from 'fistore';
import { fiAdom } from 'fi-session';
import $ from 'jquery';

const { devicesAssetsAction } = fiDevicesAction;

// Helper function to cache current adom object, since this may be used many times
function currentAdom() {
  return fiAdom.current();
}

// ========================
// All Assets class
// ========================
class BaseAssetObserver extends fiStoreObserverBase {
  constructor(props) {
    super(props);
    this.asyncQueue = [];
  }

  //@Override
  update() {
    //the redux connector use shallow equal, the state reference will be
    //updated each time, meaning this method will be called on each state change
    if (this.asyncQueue.length > 0) {
      let readCallbacks = [...this.asyncQueue];
      this.asyncQueue = [];
      readCallbacks.forEach((cb) => {
        typeof cb === 'function' && cb(this.props.state);
      });
    }
  }

  getState(adomOid) {
    const state = this.props.state || {};
    return state[adomOid] || {};
  }

  // Sends request to load data
  load(adomOid, forceReload = false, loader) {
    let state = this.getState(adomOid);
    if (forceReload || (!state.loading && !state.loaded)) {
      this.props.load(adomOid, loader);
    }

    return this.isReady(adomOid);
  }

  //delete this resource
  clear(adomOid) {
    this.props.clear(adomOid);
  }

  observeUpdate(fn) {
    return fiStoreConnector(this.constructor.slice)((...args) => fn(...args));
  }

  // Returns promise that resolves when data is loaded
  isReady(adomOid) {
    let _this = this;
    let state = this.getState(adomOid);
    if (state.loaded) {
      return Promise.resolve();
    }
    let defer = $.Deferred();
    this.addToAsyncQueue(checkReady);
    return defer.promise();

    function checkReady(state) {
      if ((state[adomOid] || state).loaded) {
        defer.resolve();
      } else {
        _this.addToAsyncQueue(checkReady);
      }
    }
  }

  addToAsyncQueue(cb) {
    this.asyncQueue.push(cb);
  }
}

class AllAssetDataObserver extends BaseAssetObserver {
  static slice(state) {
    return {
      state: state.dvm.assets,
    };
  }
}
const AllAssetDataConnector = fiStoreClassConnector(
  AllAssetDataObserver.slice,
  (dispatch) => {
    return {
      load: async (adomOid, loader) => {
        //dispatch a fetch
        dispatch(devicesAssetsAction.fetch.start({ adomOid }));
        const assets = await loader();
        const { byId, allIds } = assets;
        dispatch(
          devicesAssetsAction.record.change({
            adomOid,
            data: { byId, allIds },
          })
        );
      },
      clear: (adomOid) => {
        dispatch(
          devicesAssetsAction.record.delete({
            adomOid,
          })
        );
      },
    };
  }
)(AllAssetDataObserver);

// ========================
// Public interface
// ========================
function bindAssetsUpdate(fn) {
  return fiStoreConnector((state) => ({
    assets: state.dvm.assets,
  }))(fn);
}
function areAllAssetsLoaded() {
  const adomOid = currentAdom().oid;
  const state = AllAssetDataConnector.getState(adomOid);
  return state.loaded;
}
function readAllAssetsPerDev(oid) {
  if (areAllAssetsLoaded()) {
    const adomOid = currentAdom().oid;
    return AllAssetDataConnector.getState(adomOid).byId[oid];
  }
  return null;
}
function readOneAsset(oid, name, key) {
  const devAssets = readAllAssetsPerDev(oid);
  if (devAssets) {
    const vdomsIdArray = Object.keys(devAssets);
    for (let vid of vdomsIdArray) {
      const assetsObjOnVdom = devAssets[vid];
      const ret = assetsObjOnVdom[name].find((item) => item.name === key);
      if (ret) return ret;
    }
  }
  return null;
}

export const fiAssetsRedux = {
  loadAllAssetData: ({ reload = false, loader = () => {} } = {}) => {
    const adomOid = currentAdom().oid;
    return AllAssetDataConnector.load(adomOid, reload, loader);
  },
  clearState: () => {
    const adomOid = currentAdom().oid;
    AllAssetDataConnector.clear(adomOid);
  },
  areAllAssetsLoaded,
  readAllAssetsPerDev,
  readOneAsset,
  bindAssetsUpdate,
};
