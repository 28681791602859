import { flatui_forward } from 'fistore/utils/jsonapi';

export const syntaxRequest = (adom) => {
  var req = {
    method: 'get',
    params: [
      {
        url: `pm/config/${adom.globaldb ? 'global' : 'adom/' + adom.name}/obj`,
        option: 'syntax',
      },
    ],
  };
  return flatui_forward(req).then((resp) => {
    return resp.data.result[0].data;
  });
};
