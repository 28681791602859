import { fetchAdminProfile } from 'fistore/session/profile/slice';
import { apiResultFirst } from 'fistore/utils/api';

import { flatui_proxy_get, flatui_proxy } from 'fistore/utils/jsonapi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loaded: false,
  loading: false,
  status: 0,
  availableVersions: [],
};

const _slice = createSlice({
  name: 'session/adom',
  initialState,
  reducers: {
    setAvailableVersions(state, { payload }) {
      state.availableVersions = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessionAdom.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSessionAdom.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        state.loaded = true;
      })
      .addCase(fetchSessionAdom.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = payload;
        state.loaded = false;
      });
  },
});

// exports
export const { setAvailableVersions } = _slice.actions;
export default _slice.reducer;

// thunks
// IMPORTANT!: Do not do/import anything from other modules here, add listener
// to fetchSessionAdom.fulfilled instead
export const fetchSessionAdom = createAsyncThunk(
  'session/adom/fetchSessionAdom',
  async (_, { dispatch }) => {
    const adom = await apiResultFirst(flatui_proxy_get, '/gui/session/adom');
    // must retrive admin profile to ensure it is available and synced
    await dispatch(fetchAdminProfile(adom.oid));
    return adom;
  }
);

/**
 * This triggers after user switches adom from adom select.
 */
export const switchSessionAdom = createAsyncThunk(
  'session/adom/switchSessionAdom',
  async (adomOid, { dispatch }) => {
    const resp = await flatui_proxy({
      url: '/gui/session/adom',
      method: 'change',
      params: {
        oid: adomOid,
      },
    });
    await dispatch(fetchSessionAdom());
    return resp;
  }
);

export const fetchAdomVersions = createAsyncThunk(
  'session/adom/fetchAdomVersions',
  async (_, { dispatch }) => {
    const response = await flatui_proxy({
      method: 'get',
      url: '/gui/alladom/versions',
      params: {},
    });
    const resp = response?.result?.[0]?.data || [];

    dispatch(setAvailableVersions(resp));
    return resp;
  }
);
