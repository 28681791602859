export const LOG_FETCH = {
  CONSTS: '/p/logfetcher/consts/',
  CONFIGS: '/p/logfetcher/client/configs/',
  SAVE_CONFIG: '/p/logfetcher/client/config/save/',
  DEL_CONFIG: '/p/logfetcher/client/config/del/',
  CLIENT_SESSIONS: '/p/logfetcher/client/sessions/',
  CLIENT_FILTERS: '/p/logfetcher/client/config/filters/',
  SAVE_CLIENT_FILTERS: '/p/logfetcher/client/config/filters/save/',

  SERVICE_SESSION_APPROVE: '/p/logfetcher/service/session/approve/',
  SERVICE_SESSION_CANCEL: '/p/logfetcher/service/session/cancel/',
  SERVICE_SESSION_DENY: '/p/logfetcher/service/session/deny/',
  SERVICE_SESSION_PAUSE: '/p/logfetcher/service/session/pause/',
  SERVICE_SESSION_RESUME: '/p/logfetcher/service/session/resume/',
  SERVICE_SESSION_REVIEW: '/p/logfetcher/service/session/view/',

  CLIENT_SESSION_CANCEL: '/p/logfetcher/client/session/cancel/',
  CLIENT_SESSION_PAUSE: '/p/logfetcher/client/session/pause/',
  CLIENT_SESSION_RESUME: '/p/logfetcher/client/session/resume/',
  CLIENT_SESSION_RUN: '/p/logfetcher/client/session/run/',

  CLIENT_SYNC_CONF: '/p/logfetcher/client/sync/conf/',

  SERVER_ADOMS: '/p/logfetcher/server/adoms/',
  SERVER_ADOM_DEVICES: '/p/logfetcher/server/adom/devices/',
};
