type Listener = Record<string, unknown>;

interface ListenerWrap {
  listener: Listener;
  once: boolean;
  remove: () => void;
}

function indexOfElement(array: Array<ListenerWrap>, elem: Listener) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].listener === elem) return i;
  }
  return -1;
}

function removeElement(array: Array<ListenerWrap>, elem: Listener) {
  const index = indexOfElement(array, elem);
  if (index > -1) {
    array.splice(index, 1);
  }
}

export default class EventSubject {
  eventListeners: Map<string, Array<ListenerWrap>>;
  constructor() {
    this.eventListeners = new Map();
  }
  addListener(
    event: string,
    listener: Listener,
    once = false,
    remove?: () => void
  ) {
    const listenerWrap: ListenerWrap = {
      listener: listener,
      once: once,
      remove: remove
        ? remove
        : () => {
            this.removeListener(event, listener);
          },
    };
    const listeners = this.eventListeners.get(event);
    if (!listeners) {
      this.eventListeners.set(event, [listenerWrap]);
    } else {
      listeners.push(listenerWrap);
    }

    return listenerWrap.remove;
  }

  on(event: string, listener: Listener) {
    return this.addListener(event, listener, false);
  }
  once(event: string, listener: Listener) {
    return this.addListener(event, listener, true);
  }
  /*
  onceOr() {
    const eventListeners:any[] = [];
    for (let i = 0; i < arguments.length; i = i + 2) {
      const event = arguments[i];
      const listener = arguments[i + 1];
      eventListeners.push({ event, listener });
    }

    const remove:any = () => {
      eventListeners.forEach((event, listener) => {
        this.removeListener(event, listener);
      });
    };
    eventListeners.forEach((event, listener) => {
      this.addListener(event, listener, true, remove);
    });

    return remove;
  }
  */

  removeListener(event: string, listener: Listener) {
    const listeners = this.eventListeners.get(event);
    if (listeners) {
      removeElement(listeners, listener);
    }
  }
  removeEvent(event: string) {
    this.eventListeners.delete(event);
  }
  publish(event: string, args?: any) {
    const listeners = this.eventListeners.get(event);
    if (!listeners) return;

    const onceList: any[] = [];
    listeners.forEach((listener: any) => {
      listener.listener(args);
      if (listener.once) onceList.push(listener);
    });
    onceList.forEach((listener) => listener.remove());
  }
}
