export const VIEWTYPE_FABRIC = 'fabric';
export const VIEWTYPE_DEVICETYPE = 'device';

export const OSTYPES = {
  FAP: 'FAP',
  FAPU: 'FAPU',
  FAPC: 'FAPC',
  FAPS: 'FAPS',
  FSW: 'FSW',
  FEXT: 'FXT',
  FGT: 'FGT',
};

const {
  DVM_OS_TYPE_FOS,
  DVM_OS_TYPE_FAZ,
  DVM_OS_TYPE_FOC,
  DVM_OS_TYPE_FFW,
  DVM_OS_TYPE_FWC,
  DVM_OS_TYPE_FPX,
  DVM_OS_TYPE_FMG,
} = MACROS.DVM;

export const getAssetGroups = () => {
  const ASSET_GROUPS = {
    [OSTYPES.FAP]: {
      name: gettext('FortiAP'),
      platformName: 'FortiAP',
      key: 'FortiAP',
      firmwareKey: '_software-version',
      enforceFirmwareKey: '_prefer-img-ver',
      osType: OSTYPES.FAP,
      fwProduct: MACROS.PM2CAT.PM2_FWMPROF_PRODUCT_FAP,
      children: [],
    },
    [OSTYPES.FAPU]: {
      name: gettext('FortiAP-U'),
      platformName: 'FortiAP-U',
      key: 'FortiAP-U',
      firmwareKey: '_software-version',
      enforceFirmwareKey: '_prefer-img-ver',
      osType: OSTYPES.FAPU,
      fwProduct: MACROS.PM2CAT.PM2_FWMPROF_PRODUCT_FAP,
      children: [],
    },
    [OSTYPES.FAPC]: {
      name: gettext('FortiAP-C'),
      platformName: 'FortiAP-C',
      key: 'FortiAP-C',
      firmwareKey: '_software-version',
      enforceFirmwareKey: '_prefer-img-ver',
      osType: OSTYPES.FAPC,
      fwProduct: MACROS.PM2CAT.PM2_FWMPROF_PRODUCT_FAP,
      children: [],
    },
    [OSTYPES.FAPS]: {
      name: gettext('FortiAP-S'),
      platformName: 'FortiAP-S',
      key: 'FortiAP-S',
      firmwareKey: '_software-version',
      enforceFirmwareKey: '_prefer-img-ver',
      osType: OSTYPES.FAPS,
      fwProduct: MACROS.PM2CAT.PM2_FWMPROF_PRODUCT_FAP,
      children: [],
    },
    [OSTYPES.FSW]: {
      name: gettext('FortiSwitch'),
      platformName: 'FortiSwitch',
      key: 'FortiSwitch',
      firmwareKey: 'os_version',
      enforceFirmwareKey: 'prefer-img-ver',
      osType: OSTYPES.FSW,
      fwProduct: MACROS.PM2CAT.PM2_FWMPROF_PRODUCT_FSW,
      children: [],
    },
    [OSTYPES.FEXT]: {
      name: gettext('FortiExtender'),
      platformName: 'FortiExtender',
      key: 'FortiExtender',
      firmwareKey: '_sw_version',
      osType: OSTYPES.FEXT,
      fwProduct: MACROS.PM2CAT.PM2_FWMPROF_PRODUCT_FEX,
      children: [],
    },
    [OSTYPES.FGT]: {
      name: gettext('FortiGate'),
      platformName: 'FortiOS',
      key: 'FortiOS',
      firmwareKey: 'frmversion',
      osType: OSTYPES.FGT,
      fwProduct: MACROS.PM2CAT.PM2_FWMPROF_PRODUCT_FGT,
      children: [],
    },
  };

  return ASSET_GROUPS;
};

export const getDvmGroups = () => {
  const DVMGROUPS = {
    [DVM_OS_TYPE_FOS]: {
      name: gettext('FortiGate'),
      platformName: 'FortiOS',
      children: [],
    },
    [DVM_OS_TYPE_FAZ]: {
      name: gettext('FortiAnalyzer'),
      platformName: 'FortiAnalyzer',
      children: [],
    },
    [DVM_OS_TYPE_FOC]: {
      name: gettext('FortiCarrier'),
      platformName: 'FortiCarrier',
      children: [],
    },
    [DVM_OS_TYPE_FFW]: {
      name: gettext('FortiFirewall'),
      platformName: 'FortiFirewall',
      children: [],
    },
    [DVM_OS_TYPE_FWC]: {
      name: gettext('FortiFirewallCarrier'),
      platformName: 'FortiFirewallCarrier',
      children: [],
    },
    [DVM_OS_TYPE_FPX]: {
      name: gettext('FortiProxy'),
      platformName: 'FortiProxy',
      children: [],
    },
    [DVM_OS_TYPE_FMG]: {
      name: gettext('FortiManager'),
      platformName: 'FortiManager',
      children: [],
    },
  };

  return DVMGROUPS;
};

export const DVMGROUPS = {
  [DVM_OS_TYPE_FOS]: {
    name: gettext('FortiGate'),
    platformName: 'FortiOS',
    children: [],
  },
  [DVM_OS_TYPE_FAZ]: {
    name: gettext('FortiAnalyzer'),
    platformName: 'FortiAnalyzer',
    children: [],
  },
  [DVM_OS_TYPE_FOC]: {
    name: gettext('FortiCarrier'),
    platformName: 'FortiCarrier',
    children: [],
  },
  [DVM_OS_TYPE_FFW]: {
    name: gettext('FortiFirewall'),
    platformName: 'FortiFirewall',
    children: [],
  },
  [DVM_OS_TYPE_FWC]: {
    name: gettext('FortiFirewallCarrier'),
    platformName: 'FortiFirewallCarrier',
    children: [],
  },
  [DVM_OS_TYPE_FPX]: {
    name: gettext('FortiProxy'),
    platformName: 'FortiProxy',
    children: [],
  },
  [DVM_OS_TYPE_FMG]: {
    name: gettext('FortiManager'),
    platformName: 'FortiManager',
    children: [],
  },
};
