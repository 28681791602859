import { fiForticareRegistrationService } from 'ra_forticare_util';

export const getCountryChoices = async () => {
  const choices = await fiForticareRegistrationService.fetchCountryChoices();
  return choices;
};

export const getResellerChoices = async (country) => {
  const resp = fiForticareRegistrationService.fetchResellerChoices(country);
  return resp;
};
