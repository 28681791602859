import { fiFmgHttp } from 'fi-http';
import { get } from 'lodash';
import { saveBlob } from 'fiutil';

export const sysCertAttrMap = {
  ca: 'ca',
  local: 'certificate',
  remote: 'cert',
  crl: 'crl',
};

export function getSysCertList(mode) {
  let req = {
    method: 'get',
    params: [
      {
        url: `/cli/global/system/certificate/${mode}`,
        option: ['get certinfo'],
      },
    ],
  };

  return fiFmgHttp.forward(req).then((resp) => {
    let data = get(resp, '[0].data', []);
    return data || [];
  });
}
export function loadSysCert(mode) {
  return getSysCertList(mode).then((resp) => {
    return resp.map((it) => ({ ...it, mode }));
  });
}

export function getSysCertItems(mode) {
  return getSysCertList(mode).then((resp) => {
    return resp.map((it) => ({ id: it.name, text: it.name }));
  });
}

export function getSysCertInfo(mode, key) {
  let req = {
    method: 'get',
    params: [
      {
        url: `/cli/global/system/certificate/${mode}/${key}`,
        option: ['get certinfo'],
      },
    ],
  };

  return fiFmgHttp.forward(req).then((resp) => {
    let data = get(resp, '[0].data', {});
    return data;
  });
}
export async function downloadSysCert(mode, key) {
  const reqObj = {
    method: 'download',
    url: `/gui/sys/certificate/${mode}/entry`,
    params: { key: key },
  };

  const resp = await fiFmgHttp.download('/cgi-bin/module/flatui_proxy', reqObj);
  if (resp) {
    let filename = resp.filename ? resp.filename : key;
    let blob = new Blob([resp.data], { type: 'text/plain' });
    saveBlob(blob, filename);
  }
}

export function getFipsStatus() {
  let req = {
    method: 'get',
    url: '/gui/sys/fips-mode/status',
  };

  return fiFmgHttp.post(req).then((resp) => {
    return get(resp, '[0].data') || {};
  });
}
