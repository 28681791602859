import { useField } from 'formik';
import { Select2 } from 'rc_select';
import React, { forwardRef } from 'react';
import { useValidateFn } from './util/hook';
import { callAll } from './util/util';

export const FmkSelect2 = forwardRef(
  ({ name, validate, onChange, ...rest }, ref) => {
    validate = useValidateFn(rest, validate);
    const [, { value }, { setValue, setTouched }] = useField({
      name,
      validate,
    });

    const setFieldValOnChange = (e) => {
      setTouched(true, false);
      if (!Array.isArray(e.val)) {
        setValue(null);
      } else {
        setValue(e.val);
      }
    };
    const _onChange = callAll(setFieldValOnChange, onChange);

    return (
      <Select2
        name={name}
        defaultValue={value}
        onChange={_onChange}
        {...rest}
        ref={ref}
      ></Select2>
    );
  }
);
