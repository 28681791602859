import { fiFmgHttp } from 'fi-http';
import { fiDeviceDataLoader } from 'ra_device_util';

let _cache = {};

export const fiDevSyntax = {
  get,
  defaultValues,
  getByPlatform, //has cache
  defaultValuesByPlatform,
  clean,
};

/**
 * @param {sting} devName device name string
 * @param {string} vdom query device global or vdom
 * @param {string} uri category name, e.g. 'firewall address'
 * @param {AbortController} abortCtrl
 * @returns {Promise} if uri exist then return relate syntax, otherwise return all syntax for given device.
 */
function get(devName, vdom = 'global', uri = '', abortCtrl = undefined) {
  const _vdom = vdom || 'root';
  uri = Array.isArray(uri) ? uri[0] : uri;

  return new Promise((resolve, reject) => {
    let response = (syntax) => {
      if (uri) {
        if (!syntax[uri]) {
          reject({
            message: gettext('Can not find syntax of given resource %s').printf(
              [uri]
            ),
          });
        } else {
          resolve(syntax[uri]);
        }
      }
      return resolve(syntax);
    };

    let req = {};
    let url =
      vdom === 'global'
        ? `/pm/config/device/${devName}/global`
        : `/pm/config/device/${devName}/vdom/${_vdom}`;

    url = uri ? url + '/' + uri.replace(/ +/g, '/') : url;
    req = {
      method: 'get',
      params: [
        {
          url: url,
          option: 'syntax',
          'opts help': 1,
        },
      ],
    };
    fiFmgHttp.forward(req, abortCtrl).then(
      function (resp) {
        response(resp[0].data);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

function getByPlatform(devName, vdom, uri) {
  let key = _genKey(devName, vdom);

  return new Promise((resolve, reject) => {
    let response = (syntax) => {
      if (uri) {
        if (!syntax[uri]) {
          reject({
            message: gettext('Can not find syntax of given resource %s').printf(
              [uri]
            ),
          });
        } else {
          resolve(syntax[uri]);
        }
      }
      return resolve(syntax);
    };

    if (!key) {
      reject({
        message: gettext('Device is not found.'),
      });
    }

    if (_cache[key]) {
      return response(_cache[key]);
    }

    let req = {
      method: 'get',
      params: [
        {
          url: 'pm/config/devicetemplate/' + key,
          option: 'syntax',
        },
      ],
    };

    fiFmgHttp.query(req).then(
      function (resp) {
        _cache[key] = resp[0].data;
        response(_cache[key]);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

function clean(devName, vdom) {
  let key = _genKey(devName, vdom);

  if (!key) {
    return null;
  }

  if (_cache[key]) {
    delete _cache[key];
  }
}

function defaultValues(devName, vdom = '', uri) {
  return new Promise((resolve, reject) => {
    if (!uri) {
      reject({
        message: gettext('Missing category name in the request'),
      });
    }

    const _vdom = vdom || 'root';
    // handle normal vdom
    let url =
      vdom === 'global'
        ? `/pm/config/device/${devName}/global`
        : `/pm/config/device/${devName}/vdom/${_vdom}`;

    url = uri ? url + '/' + uri.replace(/ +/g, '/') : url;
    let req = {
      method: 'get',
      params: [
        {
          url: url,
          'sub fetch': 1,
          loadsub: 1,
          'object template': 1,
        },
      ],
    };

    fiFmgHttp.forward(req).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

function defaultValuesByPlatform(devName, vdom, uri) {
  let key = _genKey(devName, vdom);

  return new Promise((resolve, reject) => {
    if (!key) {
      reject({
        message: gettext('Device is not found.'),
      });
    }

    if (!uri) {
      reject({
        message: gettext('Missing category name in the request'),
      });
    }

    let req = {
      method: 'get',
      params: [
        {
          url: 'pm/config/devicetemplate/' + key + '/' + uri,
          'sub fetch': 1,
          loadsub: 1,
          'object template': 1,
        },
      ],
    };

    fiFmgHttp.query(req).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

function _genKey(devName, vdom) {
  let dev = fiDeviceDataLoader.getDeviceByName(devName);
  let isGlobal = vdom === 'global';

  if (!dev) {
    return null;
  }

  let key = '%(platform)s/version/%(ver)s00/mr/%(mr)s/%(vdom)s'.printfd({
    platform: dev.platform,
    ver: dev.ver,
    mr: dev.mr,
    vdom: isGlobal ? 'global' : 'vdom/root',
  });

  return key;
}
