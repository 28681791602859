import { fiStoreConnector, fiWorkspaceRequest } from 'fistore';

import {
  wsMapDispatchToProps,
  wsMapStateToProps,
  wsConnector,
} from './wsconnector';
import * as common_fns from './common';
import {
  _currentAdom,
  _getLockMsg,
  _isDirtyByMe,
  _isLockedByMe,
  dataWillbeDifferent,
} from './util';
import { adomMapStateToProps, adomMapDispatchToProps } from './adomlock';

import { pkgMapStateToProps, pkgMapDispatchToProps } from './pkglock';

import { plyMapStateToProps, plyMapDispatchToProps } from './policylock';

import { objMapStateToProps, objMapDispatchToProps } from './objectlock';

import { devMapStateToProps, devMapDispatchToProps } from './devicelock';

import {
  _doAdomLock,
  _doAdomUnlock,
  _doPkgLock,
  _doPkgUnlock,
  _doDevLock,
  _doDevUnlock,
} from './confirm';

export { getLockTimeStr } from './util';
export const fiWorkspace = {
  ...common_fns,
  lockMessage: _getLockMsg,
  isDirtyByMe: _isDirtyByMe,
  isLockedByMe: _isLockedByMe,
  // full workspace
  bindWorkspaceFn: (fn) => {
    return fiStoreConnector(wsMapStateToProps, wsMapDispatchToProps)(fn);
  },
  state: () => {
    return wsConnector.props;
  },
  checkWorkspaceDirty: (adom) => {
    return new Promise((resolve, reject) => {
      wsConnector.check(adom).then(
        (resp) => {
          // check the dirty flag
          if (!resp?.result?.[0]) {
            resolve({ data: [], status: { code: 0, message: '' } });
          } else {
            resolve(resp.result[0]);
          }
        },
        (err) => reject(err)
      );
    });
  },
  adomInfo: (adom) => {
    return wsConnector.props.adomInfo(adom);
  },
  allPkgInfo: (adom) => {
    return wsConnector.props.allPkgInfo(adom);
  },
  pkgInfo: (pkg, adom) => {
    return wsConnector.props.pkgInfo(pkg, adom);
  },
  policyInfo: (cate, pkg, adom) => {
    return wsConnector.props.policyInfo(cate, pkg, adom);
  },
  allPolicyInfo: (adom) => {
    return wsConnector.props.allPolicyInfo(adom);
  },
  allDevInfo: (adom) => {
    return wsConnector.props.allDevInfo(adom);
  },
  devInfo: (dev, adom) => {
    return wsConnector.props.devInfo(dev, adom);
  },
  // adom level
  bindAdomUpdateFn: (fn) => {
    return fiStoreConnector(adomMapStateToProps, adomMapDispatchToProps)(fn);
  },
  adomStateInit: () => {
    return wsConnector.initAdom();
  },
  setAdomState: (data, adom = _currentAdom()) => {
    return fiWorkspaceRequest.set_workspace_adom(adom.oid || adom, data);
  },
  adomLock: _doAdomLock,
  adomUnlock: _doAdomUnlock,
  adomSave: (adomObj) => {
    return wsConnector.adomSave(adomObj);
  },
  saveAll: (save_n_unlock) => {
    return wsConnector.saveAll(save_n_unlock);
  },
  unlockAll: (unlock_dirty, unlock_other) => {
    return wsConnector.unlockAll(unlock_dirty, unlock_other);
  },
  // policy package related
  bindPPkgUpdateFn: (fn) => {
    return fiStoreConnector(pkgMapStateToProps, pkgMapDispatchToProps)(fn);
  },
  pkgStateInit: (adomObj) => {
    return wsConnector.initPackage(adomObj);
  },
  pkgLock: _doPkgLock,
  pkgUnlock: _doPkgUnlock,
  pkgSave: (pkgObj, adomObj) => {
    return wsConnector.pkgSave(pkgObj, adomObj);
  },
  policyUnlock: (cate, pkgObj, adomObj) => {
    return wsConnector.policyUnlock(cate, pkgObj, adomObj);
  },
  // policy lock related
  bindPolicyUpdateFn: (fn) => {
    return fiStoreConnector(plyMapStateToProps, plyMapDispatchToProps)(fn);
  },
  plyStateInit: (cate, pkgObj, adomObj) => {
    if (!cate || !pkgObj) return;
    return wsConnector.initPolicy(cate, pkgObj, adomObj);
  },
  // policy lock related
  bindObjectUpdateFn: (cates, fn) => {
    return fiStoreConnector(
      objMapStateToProps(cates),
      objMapDispatchToProps
    )(fn);
  },
  objStateInit: (cates, adomObj) => {
    if (!cates || !cates.length) return;
    return wsConnector.initObject(cates, adomObj);
  },
  objLock: (objsByCate, adom) => {
    return wsConnector.objLock(objsByCate, adom);
  },
  objUnlock: (objsByCate, adom) => {
    return wsConnector.objUnlock(objsByCate, adom);
  },
  objInfo: (key, cate, adom) => {
    return wsConnector.props.objInfo(key, cate, adom);
  },
  // device lock related
  bindDevUpdateFn: (fn) => {
    return fiStoreConnector(devMapStateToProps, devMapDispatchToProps)(fn);
  },
  devStateInit: (adomObj) => {
    return wsConnector.initDevice(adomObj);
  },
  devLock: _doDevLock,
  devUnlock: _doDevUnlock,
  devUnlockMultiple: (dev, adomObj) => {
    return wsConnector.devUnlockMultiple(dev, adomObj);
  },
  devSave: (dev, save_n_unlock, adomObj) => {
    return wsConnector.devSave(dev, save_n_unlock, adomObj);
  },
  devSaveMultiple: (dev, adomObj) => {
    return wsConnector.devSaveMultiple(dev, adomObj);
  },
  dataWillbeDifferent,
};
