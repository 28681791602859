import { combineReducers } from 'redux';
import {
  sdwanMonitorHealthCheckActions,
  sdwanMonitorMembersActions,
  sdwanAvailableInterfacesActions,
  sdwanComputedPhase1Actions,
  sdwanComputedPhase2Actions,
} from './actions';
import { createReducer } from '../utils/reducer_util';
import { omit } from 'lodash';

const monitorDataInitialState = {
  loaded: false,
  loading: false,
  //estimateTotal: 0,
  byKey: {},
  allKeys: [],
  lastBatchKeys: [], //use this to batchly process GUI data
};

/**
 * Wrapper reducer for monitor data (health-check, member, available-interface etc.)
 * @param {*} entityReducer specific data reducer
 * @returns
 */
const sectorReducer =
  (entityReducer) =>
  (state = {}, action = {}) => {
    const reducer = entityReducer(action.type);
    if (typeof reducer === 'undefined') return state;

    if (typeof action.payload === 'undefined' || action.payload === null)
      return state;

    const payloads = Array.isArray(action.payload)
      ? action.payload
      : [action.payload];
    const newState = payloads.reduce(
      (_newState, payload) => {
        const { path } = payload;
        if (!path) return _newState; //path won't be 0

        const entityState = _newState[path];
        const entityAction = {
          ...action,
          payload: payload.data,
        };

        const newEntity = reducer(entityState, entityAction);
        if (newEntity === null) {
          return omit(_newState, path);
        }

        _newState[path] = newEntity;
        return _newState;
      },
      { ...state }
    );
    return newState;
  };

const createMonitorDataActionsReducer = (dataAPIProxyAction) => ({
  [dataAPIProxyAction.fetch.START]: () => {
    return {
      ...monitorDataInitialState,
      loaded: false,
      loading: true,
    };
  },
  [dataAPIProxyAction.fetch.SUCCESS]: (state) => {
    return {
      ...state,
      lastBatchKeys: [],
      loaded: true,
      loading: false,
    };
  },
  [dataAPIProxyAction.record.APPEND]: (state, action) => {
    if (!state.loading && !state.loaded) return null;
    //const payloads = Array.isArray(action.payload) ? action.payload : [action.payload];
    let payload = action.payload || [];
    let lastBatchKeys = [];
    const data = Array.isArray(payload) ? payload : [payload];
    const { byKey, allKeys } = state;
    const newByKey = data.reduce(
      (pre, cur) => {
        pre[cur.target] = cur;
        return pre;
      },
      { ...byKey }
    );
    const newAllkeys = data.reduce(
      (pre, cur) => {
        pre.push(cur.target);
        lastBatchKeys.push(cur.target);
        return pre;
      },
      [...allKeys]
    );
    return {
      ...state,
      byKey: newByKey,
      allKeys: newAllkeys,
      lastBatchKeys,
    };
  },
  [dataAPIProxyAction.record.DELETE]: () => {
    return {};
  },
});

const createComputedDataActionsReducer = (dataAPIProxyAction) => {
  return {
    [dataAPIProxyAction.fetch.START]: () => {
      return {
        ...monitorDataInitialState,
        loaded: false,
        loading: true,
      };
    },
    [dataAPIProxyAction.fetch.SUCCESS]: (state) => {
      return {
        ...state,
        lastBatchKeys: [],
        loaded: true,
        loading: false,
      };
    },
    [dataAPIProxyAction.record.APPEND]: (state, action) => {
      if (!state.loading && !state.loaded) return null;
      let payload = action.payload || [];
      let lastBatchKeys = [];
      const data = Array.isArray(payload) ? payload : [payload];
      const { byKey, allKeys } = state;
      const { byKey: newByKey, allKeys: newAllkeys } = data.reduce(
        (pre, cur) => {
          pre.byKey[cur.name] = cur;
          pre.allKeys.push(cur.name);
          lastBatchKeys.push(cur.name);
          return pre;
        },
        { byKey: { ...byKey }, allKeys: [...allKeys] }
      );
      return {
        ...state,
        byKey: newByKey,
        allKeys: newAllkeys,
        lastBatchKeys,
      };
    },
    [dataAPIProxyAction.record.DELETE]: () => {
      return {};
    },
  };
};

const healthCheckReducer = sectorReducer(
  createReducer(createMonitorDataActionsReducer(sdwanMonitorHealthCheckActions))
);
const membersReducer = sectorReducer(
  createReducer(createMonitorDataActionsReducer(sdwanMonitorMembersActions))
);
const availableInterfacesReducer = sectorReducer(
  createReducer(
    createMonitorDataActionsReducer(sdwanAvailableInterfacesActions)
  )
);
const computedPhase1DataReducer = sectorReducer(
  createReducer(createComputedDataActionsReducer(sdwanComputedPhase1Actions))
);
const computedPhase2DataReducer = sectorReducer(
  createReducer(createComputedDataActionsReducer(sdwanComputedPhase2Actions))
);

export const sdwanReducer = combineReducers({
  monitor: combineReducers({
    healthCheck: healthCheckReducer,
    members: membersReducer,
    availableInterfaces: availableInterfacesReducer,
    computedPhase1Data: computedPhase1DataReducer,
    computedPhase2Data: computedPhase2DataReducer,
  }),
  //...
});
