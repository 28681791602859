import { fiSession } from 'fistore';
import { negate } from 'lodash';

const { inGlobalAdom, hasRDPermitOn, isFmg } = fiSession;
const mrPRIV = MACROS.USER.DVM;

export default {
  fgd: {
    label: gettext('FortiGuard'),
    icon: 'fortiguard',
    color: '#f89b47',
    showCheckers: [
      isFmg,
      negate(inGlobalAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_FGD_CENTER),
    ],
  },
  fgd_license: {
    label: gettext('Device Licenses'),
    // icon: 'fortiguard-dashboard',
    path: '/fortiguard/license',
    showCheckers: [hasRDPermitOn('fgd_center_licensing')],
    olhTag: 'fgd_license',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> Licensing Status',
  },
  fgd_pkg: {
    label: gettext('Packages'),
    // icon: 'administrator',
    showCheckers: [hasRDPermitOn('fgd_center_licensing')],
    olhTag: 'fgd_pkg',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> Packages',
  },
  fgd_pkg_receive: {
    label: gettext('Receive Status'),
    path: '/fortiguard/pkgreceive',
    olhTag: 'fgd_pkg_receive',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> Packages -> Receive Status',
  },
  fgd_pkg_service: {
    label: gettext('Service Status'),
    path: '/fortiguard/pkgservice',
    olhTag: 'fgd_pkg_service',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> Packages -> Service Status',
  },
  fgd_query: {
    label: gettext('Query Services'),
    // icon: 'certificate',
    showCheckers: [hasRDPermitOn('fgd_center_licensing')],
    olhTag: 'fgd_query_receive',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> Query Services',
  },
  fgd_query_receive: {
    label: gettext('Receive Status'),
    path: '/fortiguard/queryreceive',
    olhTag: 'fgd_query_receive',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> Query Services -> Receive Status',
  },
  fgd_query_status: {
    label: gettext('Query Status'),
    path: '/fortiguard/querystatus',
    olhTag: 'fgd_query_status',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> Query Services -> Query Status',
  },
  fgd_firmware: {
    label: gettext('Firmware Images'),
    // icon: 'adom-folder',
    path: '/fortiguard/firmwares',
    showCheckers: [hasRDPermitOn('fgd_center_fmw_mgmt')],
    olhTag: 'fgd_firmware',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> Firmware Images',
  },
  fgd_external_resource: {
    label: gettext('External Resource'),
    path: '/fortiguard/externalresource',
    olhTag: 'fgd_ext_resource',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> External Resource',
  },
  fgd_setting: {
    label: gettext('Settings'),
    // icon: 'advanced',
    path: '/fortiguard/advanced',
    showCheckers: [hasRDPermitOn('fgd_center_advanced')],
    olhTag: 'fgd_settings',
    olhCategory: 'fgd',
    olhText: 'FortiGuard -> Advanced Settings',
  },
};
