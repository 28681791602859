import { combineReducers } from 'redux';
import * as viewLogSlice from './viewLog/slice';
import * as viewLogSelectors from './viewLog/selectors';
import * as customWidgetSlice from './customWidget/slice';
import * as customWidgetSelectors from './customWidget/selectors';
import * as dashEditingSlice from './dashEditing/slice';
import * as dashEditingSelectors from './dashEditing/selectors';
import * as currentDashSlice from './currentDash/slice';
import * as currentDashSelectors from './currentDash/selectors';
import * as customViewSlice from './customView/slice';
import * as customViewSelectors from './customView/selectors';
import * as device from './device-reducer';

export const socReducer = combineReducers({
  currentDash: currentDashSlice.default,
  customView: customViewSlice.default,
  viewLog: viewLogSlice.default,
  customWidget: customWidgetSlice.default,
  dashEditing: dashEditingSlice.default,
  device: device.reducer,
});

export const duck = {
  currentDash: {
    actions: currentDashSlice,
    selectors: currentDashSelectors,
  },
  customView: {
    actions: customViewSlice,
    selectors: customViewSelectors,
  },
  viewLog: {
    actions: viewLogSlice,
    selectors: viewLogSelectors,
  },
  device,
  customWidget: {
    actions: customWidgetSlice,
    selectors: customWidgetSelectors,
  },
  dashEditing: {
    actions: dashEditingSlice,
    selectors: dashEditingSelectors,
  },
};
