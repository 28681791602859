import { isFunction } from 'fiutil';

export { callIfMeets, Disposable };

const callIfMeets =
  (...filters) =>
  (onMessage) =>
  (message) => {
    if (filters.every((fn) => fn(message))) {
      onMessage(message);
    }
  };

class Disposable {
  constructor() {
    this._disposables = [];
    this.addDisposable = this.addDisposable.bind(this);
    this.dispose = this.dispose.bind(this);
    this.disposed = false;
  }

  addDisposable(disposableOrFn) {
    const disposable = isFunction(disposableOrFn)
      ? {
          // eslint-disable-next-line
          dispose: disposableOrFn,
          // eslint-disable-next-line
        }
      : disposableOrFn;

    this._disposables.push(disposable);
  }

  dispose() {
    this.disposed = true;
    for (const d of this._disposables) {
      d.dispose();
    }
    this._disposables = [];
  }
}
