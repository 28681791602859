import { fiFmgHttp } from 'fi-web/fi-http';
import { get } from 'lodash';

const getVpnConfigs = async (deviceOid: number) => {
  try {
    const resp = await fiFmgHttp.post(
      {
        url: '/gui/device/vpn/configs',
        method: 'get',
        params: {
          deviceOid,
        },
      },
      undefined
    );
    const results = get(resp, '0.data');
    return results;
  } catch {
    return {};
  }
};

/* 
If getting a table category without specific key, e.g. system interface
 - T should be an array of objects 
e.g. categories = ['system interface', 'vpn ipsec phase1-interface'] => 
  returns [[{name: intf1}, {name: intf2}], [{name: vpn1}, {name: vpn2}]]

If getting specific keys, T should be an object
e.g. categories = ['system interface port1', 'system interface port2'] => 
  returns [{name: port1, 'cli config': '...'}, {name: port2, 'cli config': '...'}]
*/
const getCategoryConfigCLI = async <T>({
  device,
  categories,
  fields = [],
  option = [],
}: {
  device: {
    name: string;
    vdom: string;
  };
  categories: string[];
  fields?: string[];
  option?: string[];
}): Promise<T> => {
  if (!categories.length) return [] as T;

  const params = categories.map((category) => {
    const categoryPath = category.split(' ');
    const url = `pm/config/device/${device.name}/${
      category.includes('system interface') ? 'global' : `vdom/${device.vdom}`
    }/${categoryPath.join('/')}`;
    return {
      url,
      option: ['cli config', ...option],
      fields: fields,
    };
  });

  const req = {
    method: 'get',
    params,
  };

  const allResps = await fiFmgHttp.query(req);
  const respData = allResps.map((resp?: { data?: T }) => {
    return resp?.data ?? null;
  }) as T;
  return respData;
};

export const fiDeviceConfigUtil = {
  getVpnConfigs,
  getCategoryConfigCLI,
};
