import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginButton } from 'react_apps/ra-auth/widgets/LoginButton';
import { ProToolkit, ProForm } from '@fafm/neowise-pro';
import AutoSizer from '@fafm/react-virtualized-auto-sizer';
import { noop } from 'lodash';
import { FixedSizeGrid } from 'react-window';
import { NwInput, NwIcon, NwTooltip } from '@fafm/neowise-core';

const { Header, Body } = ProForm;

const SAML_ROLE_FAB_SP = 2;

const IdpCell = ({ rowIndex, columnIndex, data, style }) => {
  const { idpList, onIdpCellClick, numCols } = data;
  const index = rowIndex * numCols + columnIndex;
  const idp = idpList[index];

  if (idp) {
    const adomText =
      idp.adom_name +
      (idp.csf_num_devices > 0 ? ` (${idp.csf_num_devices})` : '');

    const newStyle = {
      ...style,
      left: style.left + 1,
      top: style.top + 2,
      width: style.width - 1,
      height: style.height - 2,
    };

    return (
      <NwTooltip
        placement='top'
        content={
          <div>
            <div>{idp.csf_name}</div>
            <div>{adomText}</div>
            <div>{idp.adom_desc}</div>
          </div>
        }
      >
        <div
          className='tw-flex tw-items-center tw-group tw-cursor-pointerv tw-bg-neutral-200 hover:tw-bg-primary-100'
          style={newStyle}
          onClick={() => onIdpCellClick(idp)}
        >
          <div className='tw-inline-block tw-w-8 tw-h-full tw-text-center tw-bg-primary tw-text-neutral-0'>
            <NwIcon
              library='fafm'
              name='security-fabric'
              className='tw-h-full'
            />
          </div>
          <div className='tw-py-1.5 tw-px-2 tw-font-bold'>
            <div className='tw-mb-1 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>
              {idp.csf_name}
            </div>
            <div className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-neutral-500 group-hover:tw-text-neutral-1000'>
              {adomText}
            </div>
          </div>
        </div>
      </NwTooltip>
    );
  }
  return null;
};

export const IdpList = ({ idpList, $opener }) => {
  // const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [filteredIdpList, setFilteredIdpList] = useState([]);

  const onIdpCellClick = useCallback((idp) => {
    location.assign(`/p/sso_sp/fab/${idp.name}/`);
    $opener.resolve();
  }, []);

  const filterIdpList = () => {
    const searchTerm = search.toLowerCase();

    let filtered =
      idpList?.filter((idp) => {
        const searchFields = ['name', 'csf_name', 'adom_name', 'adom_desc'];
        return searchFields.some(
          (field) =>
            idp[field] && idp[field].toLowerCase().indexOf(searchTerm) !== -1
        );
      }) ?? [];

    setFilteredIdpList(filtered);
  };

  useEffect(() => {
    filterIdpList();
  }, [idpList, search]);

  return (
    <>
      <Header>{gettext('Select Fabric IdP')}</Header>
      <Body className='tw-flex tw-flex-col tw-w-full tw-h-full'>
        <div className='tw-pb-2.5'>
          <NwInput
            value={search}
            clearable
            onChange={(e) => setSearch(e.target.value)}
            prefix={<NwIcon name='search' />}
          />
        </div>
        {filteredIdpList.length > 0 ? (
          <div className='tw-w-full tw-h-full'>
            <AutoSizer>
              {({ width, height }) => {
                const windowWidth =
                  width > 0 && Number.isInteger(width) ? width : 200;
                const windowHeight =
                  height > 0 && Number.isInteger(height) ? height : 200;
                const numCols = Math.floor(windowWidth / (270 + 5));
                const numRows = Math.ceil(filteredIdpList.length / numCols);
                const cellWidth = windowWidth / numCols;

                return (
                  <FixedSizeGrid
                    overscanRowCount={30}
                    width={windowWidth}
                    height={windowHeight}
                    columnCount={numCols}
                    rowCount={numRows}
                    columnWidth={cellWidth}
                    rowHeight={57}
                    itemData={{
                      idpList: filteredIdpList,
                      numCols,
                      onIdpCellClick,
                    }}
                    style={{ overflowX: 'hidden' }}
                  >
                    {IdpCell}
                  </FixedSizeGrid>
                );
              }}
            </AutoSizer>
          </div>
        ) : (
          <div>{gettext('No entry found.')}</div>
        )}
      </Body>
    </>
  );
};

export const useFabricSSOLogin = ({ loginEnv }) => {
  const navigate = useNavigate();

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      ProToolkit.openModal(
        <IdpList idpList={loginEnv.fab_idp_list} navigate={navigate} />,
        {
          maximizable: false,
        }
      ).result.catch(noop);
    },
    [loginEnv.fab_idp_list]
  );

  return {
    getExtraButton({ key }) {
      if (loginEnv?.sso_role === SAML_ROLE_FAB_SP) {
        return (
          <LoginButton
            key={key}
            automation-id='login-fabric-sso'
            text={gettext('Login with Fabric Single Sign-On')}
            onClick={onClick}
          />
        );
      }
    },
  };
};
