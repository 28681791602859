import React from 'react';
import { useField } from 'formik';
import { callAll } from './util/util';
import { isBoolean, isUndefined } from 'lodash';
import { NwCheckbox } from '@fafm/neowise-core';

export const neowiseToggleField = (NwComp) => {
  const Component = ({
    name,
    trueValue,
    falseValue,
    children,
    'automation-id': propAutoId,
    automationId,
    onChange,
    onNwChange,
    validate,
    ...rest
  }) => {
    const [, { value }, { setValue, setTouched }] = useField({
      name,
      validate,
    });

    const getToggleVal = (evt) => {
      const { value, checked } = evt.target;
      // custom true, false value
      if (!isUndefined(trueValue)) {
        return checked ? trueValue : falseValue;
      }
      // 0 or 1
      if (value) {
        const intVal = parseInt(value);
        return 1 - intVal;
      }
      // boolean value
      return checked;
    };

    const setFieldValueOnNwChange = (evt) => {
      evt.preventDefault();
      const newVal = getToggleVal(evt);
      setTouched(true, false);
      setValue(newVal);
    };

    return (
      <NwComp
        onChange={
          rest.disabled
            ? undefined
            : callAll(setFieldValueOnNwChange, onChange, onNwChange)
        }
        name={name}
        value={isBoolean(value) ? undefined : value}
        checked={isUndefined(trueValue) ? !!value : value === trueValue}
        automation-id={propAutoId || automationId}
        {...rest}
      >
        {children}
      </NwComp>
    );
  };

  return Component;
};

export const FmkCheckbox = neowiseToggleField(NwCheckbox);
FmkCheckbox.displayName = 'FmkCheckbox';
