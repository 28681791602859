import { getValidNetMask } from 'fiutil';
import { neowiseIpInputField } from './FmkIpV4Input';
import { NwNetmaskInput } from '@fafm/neowise-core';

export const FmkNetmaskInput = neowiseIpInputField(NwNetmaskInput, {
  ipValidate: () => {
    return (value) => {
      if (!getValidNetMask(value)) return gettext('Invalid IP netmask.');
    };
  },
  toFormValue: (displayStr, { autoFillEmptyValue, autoFillIpValue }) => {
    if (typeof displayStr === 'string') {
      let trimStr = displayStr.trim();
      if (autoFillEmptyValue && !trimStr) {
        return autoFillIpValue;
      }
      const validNetmaskValue = getValidNetMask(trimStr);
      return validNetmaskValue || displayStr;
    }
    return displayStr;
  },
});
FmkNetmaskInput.displayName = 'FmkNetmaskInput';
