import { omit } from 'lodash';

export const pathReducer =
  (entityReducer) =>
  (state = {}, action = {}) => {
    const reducer = entityReducer(action.type);
    if (typeof reducer === 'undefined') return state;

    if (typeof action.payload === 'undefined' || action.payload === null)
      return state;

    const payloads = Array.isArray(action.payload)
      ? action.payload
      : [action.payload];
    const newState = payloads.reduce(
      (_newState, payload) => {
        const { path } = payload;
        if (!path) return _newState;

        const entityState = _newState[path];
        const entityAction = {
          ...action,
          payload: payload.data,
        };

        const newEntity = reducer(entityState, entityAction);
        if (newEntity === null) {
          return omit(_newState, path);
        }

        _newState[path] = newEntity;
        return _newState;
      },
      { ...state }
    );

    return newState;
  };

/**
 * Create a entity reducer
 * @param {Object} actions
 * @param {Object} initState
 * @returns
 */
export const createReducer =
  (actions, initState = {}) =>
  (type) => {
    const actionReducer = actions[type];
    if (typeof actionReducer === 'undefined') return actionReducer;

    return (state = initState, action) => actionReducer(state, action);
  };
