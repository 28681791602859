import { createSelector } from '@reduxjs/toolkit';
import { getAllApps } from '../../routing/selectors';

const myState = (state) => state.soc.customView;

const findApp = (allApps, uuid, isNoc) => {
  if (!uuid) {
    return;
  }
  return Object.values(allApps).find(
    (x) => x.isNoc === isNoc && x.stateParams?.dashId === uuid
  );
};

export const getCustomViews = createSelector(myState, (state) => state.data);

const genUniKey = (id) => `fortiview_custom_view_${id}`;

const subTypeMap = {
  [MACROS.FAZ_SYNTAX.DASHBOARD_SUB_TYPE_SDWAN]: gettext('SD-WAN'),
  [MACROS.FAZ_SYNTAX.DASHBOARD_SUB_TYPE_SECURITY_FABRIC]:
    gettext('Security Fabric'),
};

export const getCustomViewMenu = createSelector(
  getAllApps,
  myState,
  (allApps, state) => {
    return state.data.map((dash) => {
      const isFtv = dash['is-fv'];
      const originalApp = findApp(allApps, dash['created-from'], !isFtv);
      const label = dash.name;
      let labelSuffix = '';
      if (originalApp?.label) {
        labelSuffix = `[${originalApp.label}]`;
      } else if (dash['sub-type']) {
        labelSuffix = `[${subTypeMap[dash['sub-type']]}]`;
      }
      return {
        label: [label, labelSuffix].join(' '),
        state: `adom.default.soc.${isFtv ? 'fortiview' : 'noc'}.dash`,
        path: `/fortiview/${isFtv ? 'ftv' : 'noc'}/custom_view/${dash.uuid}`,
        stateParams: {
          appUniKey: genUniKey(dash.uuid),
          dashId: dash.uuid,
          name: dash.name,
          isUser: true,
          isFtv,
        },
        showCheckers: originalApp?.showCheckers || [],
      };
    });
  }
);
