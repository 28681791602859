import { useEffect, useState } from 'react';
import { getTaskLineDetail } from './task_util';
import { isArray } from 'lodash';

export const LastLineDetail = ({ taskData, ...rest }) => {
  const [lastline, setLastline] = useState(gettext('Please wait...'));

  useEffect(() => {
    if (isArray(taskData?.line) && taskData.line.length) {
      const detail = getTaskLineDetail(taskData.line[taskData.line.length - 1]);
      detail && setLastline(detail);
    }
  }, [taskData]);

  return (
    <div {...rest}>
      <b>{lastline}</b>
    </div>
  );
};
