import { logfetchTransHelper } from './logfetchTransHelper';

export const logfetchViewSettings = {
  // review request: display device-filter
  deviceFilterList: [
    {
      field: 'adom',
      txt: logfetchTransHelper.adom,
      template: 'column/devdefault',
      show: 0,
      width: '25%', // used by review request popup
    },
    {
      field: 'device',
      txt: logfetchTransHelper.device,
      template: 'column/devdefault',
      show: 1,
      width: '50%',
    },
    {
      field: 'vdom',
      txt: logfetchTransHelper.vdom,
      template: 'column/devdefault',
      show: 1,
      width: '25%',
    },
  ],
  // review request: display log-filter
  logFilterList: [
    {
      field: 'field',
      txt: logfetchTransHelper.field,
      template: 'column/default',
      show: 1,
    },
    {
      field: 'oper',
      txt: logfetchTransHelper.oper,
      template: 'column/default',
      show: 1,
    },
    {
      field: 'value',
      txt: logfetchTransHelper.value,
      template: 'column/default',
      show: 1,
    },
  ],
  sessionsColList: [
    {
      field: 'create-time',
      txt: logfetchTransHelper['create-time'],
      template: 'column/create-time',
      attr: {
        'st-column-resizeable': '',
        //'st-sort': 'create-time',
        //'st-sort-default': 'reverse'
      },
    } /*
        {
            field: 'request',
            txt: gettext('Request'),
            template: 'column/request',
            attr: {
                'st-column-resizeable': '',
            }
        },*/,
    {
      field: 'ctrl.hostname',
      txt: logfetchTransHelper.hostname,
      template: 'column/host-serverip',
      attr: {
        'st-column-resizeable': '',
        //'st-sort': 'sever-ip',
      },
    },
    {
      field: 'clt.user',
      txt: logfetchTransHelper.user,
      template: 'column/default',
      attr: {
        'st-column-resizeable': '',
        //'st-sort': 'user',
      },
    },
    {
      _field: 'state',
      key: 'status',
      txt: logfetchTransHelper.state,
      template: 'column/status',
      attr: {
        'st-column-resizeable': '',
      },
    },
    {
      _field: 'state',
      key: 'actions',
      txt: logfetchTransHelper.action,
      template: 'column/actions',
      attr: {
        'st-column-resizeable': '',
      },
    },
  ],
  profilesColList: [
    {
      field: 'name',
      txt: logfetchTransHelper.name,
      template: 'column/default',
      //width: '20%',
      attr: {
        //'style': 'width:25%;',
        'st-column-resizeable': '',
        'st-sort': 'name',
        'st-sort-default': 'ascending',
      },
    },
    {
      field: 'server-ip',
      txt: logfetchTransHelper['server-ip'],
      template: 'column/default',
      //width: '25%',
      attr: {
        //'style': 'width:25%;',
        'st-column-resizeable': '',
        'st-sort': 'server-ip',
      },
    },
    {
      field: 'server-adom',
      txt: logfetchTransHelper['server-adom'],
      template: 'column/default',
      attr: {
        'st-column-resizeable': '',
        'st-sort': 'server-adom',
      },
    },
    {
      field: 'user',
      txt: logfetchTransHelper.user,
      template: 'column/default',
      //width: '25%',
      attr: {
        //'style': 'width:25%;',
        'st-column-resizeable': '',
        'st-sort': 'user',
      },
    },
    {
      field: 'client-adom',
      txt: logfetchTransHelper['client-adom'],
      template: 'column/default',
      attr: {
        'st-column-resizeable': '',
        'st-sort': 'client-adom',
      },
    } /*,
        {
            field: 'fetch',
            txt: gettext('Fetch Logs'),
            template: 'column/fetch',
            //width: '20%',
            attr: {
                //'style': 'width:20%;',
                'st-column-resizeable': '',
            }
        },*/,
  ],
};
