import { ProForm, ProToolkit } from '@fafm/neowise-pro';
import { OkBtn, CancelBtn } from 'rc_layout';
import { useCallback, useState } from 'react';
import { isFunction } from 'lodash';
import { TaskMonitor } from './TaskMonitor';

const { Header, Body, Footer } = ProForm;

export { TaskModal, SimpleTaskModal, openTaskModal };

const DEFAULT_TASK_MODAL_CONFIG = {
  className: 'np-toolkit-modal fi-pro-confirm-modal',
  size: 'lg',
  height: '500px',
  minimizable: false,
  closable: true,
};

const defaultFn = (val) => val;

const TaskModal = ({
  $opener,
  renderer,
  title,
  taskid,
  closable = true,
  autoCloseOnSuccess = false,
  onTaskDone,
  renderExtraButtons,
  resolveOnClose = true,
}) => {
  if (isFunction(renderer)) {
    return renderer($opener);
  }

  return (
    <SimpleTaskModal
      title={title}
      taskid={taskid}
      $opener={$opener}
      closable={closable}
      autoCloseOnSuccess={autoCloseOnSuccess}
      onTaskDone={onTaskDone}
      renderExtraButtons={renderExtraButtons}
      resolveOnClose={resolveOnClose}
    />
  );
};
TaskModal.displayName = 'TaskModal';

const SimpleTaskModal = ({
  $opener,
  title,
  taskid,
  getAutoId = defaultFn,
  closable,
  autoCloseOnSuccess,
  onTaskDone: _onTaskDone,
  renderExtraButtons,
  resolveOnClose,
}) => {
  const [finished, setFinished] = useState(false);
  const [taskData, setTaskData] = useState(null);
  const [hasError, setHasError] = useState(false);
  const onTaskDone = (taskData) => {
    setFinished(true);
    setTaskData(taskData);
    if (_onTaskDone) {
      _onTaskDone(taskData);
    }
    if (autoCloseOnSuccess) {
      setTimeout(() => {
        $opener.resolve(taskData);
      }, 1000);
    }
  };

  const onClose = useCallback(() => {
    if (taskData && resolveOnClose) {
      return $opener.resolve(taskData);
    }
    $opener.reject();
  }, [taskData]);

  const onTaskError = (taskData) => {
    setFinished(true);
    setHasError(true);
    setTaskData(taskData);
  };

  const renderFixedButtons = () => {
    const showFinishButton = finished && !hasError;
    const disabled = !closable && !finished;

    if (showFinishButton) {
      return (
        <OkBtn
          automation-id={getAutoId('task-modal-finish-btn')}
          onClick={() => $opener.resolve(taskData)}
          disabled={disabled}
        >
          {gettext('Finish')}
        </OkBtn>
      );
    }

    return (
      <CancelBtn
        onClick={onClose}
        automation-id={getAutoId('task-modal-cancel-btn')}
        disabled={disabled}
      >
        {gettext('Close')}
      </CancelBtn>
    );
  };

  const renderFooterButtons = () => {
    return (
      <>
        {renderExtraButtons && renderExtraButtons($opener, taskData)}
        {renderFixedButtons()}
      </>
    );
  };

  return (
    <>
      <Header>{title}</Header>
      <Body className='tw-h-full'>
        <TaskMonitor
          taskId={taskid}
          onTaskDone={onTaskDone}
          onTaskError={onTaskError}
        />
      </Body>
      <Footer>{renderFooterButtons()}</Footer>
    </>
  );
};
SimpleTaskModal.displayName = 'SimpleTaskModal';

const openTaskModal = async (
  modalProps,
  modalOptions = DEFAULT_TASK_MODAL_CONFIG
) => {
  return ProToolkit.openModal(
    <TaskModal {...modalProps} closable={modalOptions.closable} />,
    modalOptions
  ).result;
};
openTaskModal.defaultOptions = DEFAULT_TASK_MODAL_CONFIG;
