import React from 'react';
import { FieldArray, useField } from 'formik';
import { isFunction, promiseWhen } from 'fiutil';
import { ProLego } from '@fafm/neowise-pro';

import { FmkErrorSpan } from '../formik_layout/FmkErrorSpan';

export const FmkMutableList = ({
  name,
  validate,
  validateOnChange = false,
  header = [],
  initValue,
  maxLength,
  allowAddCondition,
  allowRemoveCondition,
  onAdd,
  onRemove,
  automationId,
  ['automation-id']: autoId,
  children,
  ...rest
}) => {
  // eslint-disable-next-line
  const [, { value }, { setValue, setTouched }] = useField({ name, validate });

  const makeOnAddCallback = (arrayHelpers) => async (index) => {
    // update field array in formik context
    arrayHelpers.insert(index + 1, initValue);

    // callback
    if (onAdd && isFunction(onAdd)) {
      await promiseWhen(onAdd({ arrayHelpers, index }));
    }
  };

  const makeOnRemoveCallback = (arrayHelpers) => async (index) => {
    // update field array in formik context
    arrayHelpers.remove(index);

    // callback
    if (onRemove && isFunction(onRemove)) {
      await promiseWhen(onRemove({ arrayHelpers, index }));
    }
  };

  return (
    <FieldArray name={name} validateOnChange={validateOnChange}>
      {(arrayHelpers) => (
        <ProLego.MutableList
          header={header}
          items={value}
          initValue={initValue}
          maxLength={maxLength}
          allowAddCondition={allowAddCondition}
          allowRemoveCondition={allowRemoveCondition}
          onAddCallback={makeOnAddCallback(arrayHelpers)}
          onRemoveCallback={makeOnRemoveCallback(arrayHelpers)}
          automationId={automationId || autoId}
          {...rest}
        >
          {children}
        </ProLego.MutableList>
      )}
    </FieldArray>
  );
};

export const SimpleMutableRow = ({
  // MutableList provided props
  index,
  getAutoId,

  // user passed in props
  Component,
  name,
  showError = true,
  ...rest
}) => {
  const field = `${name}.${index}`;

  return (
    <>
      <Component name={field} automationId={getAutoId(name)} {...rest} />
      {showError && <FmkErrorSpan name={field} />}
    </>
  );
};
