import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getIsAppInited } from 'fistore/routing/selectors';
import { useAuth } from 'react_apps/ra-auth/useAuth';
import { goApp } from 'fistore/routing/slice';

// This page is used as the first page before dynamic loading any modules
// including the dashboard, it is also used to fill in the first <Outlet>
export function IndexPage() {
  const inited = useSelector(getIsAppInited);
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (inited) {
      try {
        dispatch(goApp());
      } catch {
        auth.logout();
      }
    }
  }, [inited]);

  return null;
}
