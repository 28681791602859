import { NwProSection, NwProInputRow } from 'rc_layout';
import { NwIcon } from '@fafm/neowise-core';

const errorColor = 'rgb(var(--nw-color-danger))';

export const WizardError = ({ className, errorText }) => {
  return (
    <NwProSection>
      <NwProInputRow className={className}>
        <div style={{ color: errorColor }}>
          <span className='tw-mr-1'>
            <NwIcon name='search'></NwIcon>
          </span>
          {errorText}
        </div>
      </NwProInputRow>
    </NwProSection>
  );
};
