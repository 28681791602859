import { fiFmgHttp } from 'fi-web/fi-http';
import { get, isString } from 'lodash';
import { genQueryTableFn } from './query_table';
import { fiDeviceDataLoader } from './device_data_loader';

const runCliCommands =
  (commands) =>
  async ({ deviceOid = 0, deviceName = '' }) => {
    try {
      let oid;
      if (deviceOid) oid = deviceOid;
      else {
        const device = await fiDeviceDataLoader.getDeviceByName(deviceName);
        oid = device.oid || 0;
      }
      const params = commands.map((command) => ({
        data: {
          device: isString(oid) ? parseInt(oid) : oid,
          command: command,
          vdom: 'root',
        },
        url: '/deployment/run/cmd',
      }));

      const resp = await fiFmgHttp.forward({
        method: 'exec',
        params,
      });
      const results = get(resp, '0.data');
      return results;
    } catch {
      return [];
    }
  };

//const diagnoseVPN = (phase1Name) =>
//  runCliCommands([
//    `diagnose vpn ike gateway list name ${phase1Name}`, // HQ_to_Branch for
//    `diagnose vpn tunnel list name ${phase1Name}`,
//  ]);
//
//const diagnoseAllVPN = () =>
//  runCliCommands(['diagnose vpn ike gateway list', 'diagnose vpn tunnel list']);

const diagnoseVpnIkeConfig = runCliCommands([
  'diagnose vpn ike config list summary',
]);

const diagnoseWanInfo = runCliCommands(['diagnose sys waninfo']);

//for phase1 connection status
const diagnoseVpnGatewayStatus = (tunnelName) =>
  runCliCommands([`diagnose vpn ike gateway list name ${tunnelName}`]);

//for phase2 connection status
const diagnoseVpnTunnelList = (tunnelName) =>
  runCliCommands([`diagnose vpn tunnel list name ${tunnelName}`]);

const getIpsecVpnStatus = async ({ deviceName, vdomName }) => {
  //const ipsecTunnels = mock_vpn_data;
  const ipsecTunnels = await genQueryTableFn({
    path: 'vpn',
    name: 'ipsec',
    deviceName,
    vdomName,
  });

  // return {
  //   summary,
  //   result: ipsecTunnels
  // };
  return ipsecTunnels;
};

export const fiDiagnoseUtil = {
  getIpsecVpnStatus,
  diagnoseVpnIkeConfig,
  diagnoseVpnGatewayStatus,
  diagnoseVpnTunnelList,
  diagnoseWanInfo,
  //diagnoseAllVPN,
};

window.fiDiagnoseUtil = fiDiagnoseUtil;

// const mock_vpn_data = [
//                   {
//                     "comments": "VPN: Cloud-VPN1 [Created by IPSEC Template]",
//                     "connection_count": 0,
//                     "creation_time": 13,
//                     "incoming_bytes": 0,
//                     "name": "Cloud-VPN1",
//                     "outgoing_bytes": 0,
//                     "proxyid": [
//                       {
//                         "p2name": "Cloud-VPN1",
//                         "p2serial": 2,
//                         "proxy_dst": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "proxy_src": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "status": "down"
//                       }
//                     ],
//                     "rgwy": "10.198.9.2",
//                     "tun_id": "10.198.9.2",
//                     "tun_id6": "::10.198.9.2",
//                     "type": "automatic",
//                     "wizard-type": "custom"
//                   },
//                   {
//                     "comments": "VPN: Cloud-VPN2 [Created by IPSEC Template]",
//                     "connection_count": 0,
//                     "creation_time": 13,
//                     "incoming_bytes": 0,
//                     "name": "Cloud-VPN2",
//                     "outgoing_bytes": 0,
//                     "proxyid": [
//                       {
//                         "p2name": "Cloud-VPN2",
//                         "p2serial": 2,
//                         "proxy_dst": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "proxy_src": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "status": "down"
//                       }
//                     ],
//                     "rgwy": "10.198.9.2",
//                     "tun_id": "10.0.0.1",
//                     "tun_id6": "::10.0.0.1",
//                     "type": "automatic",
//                     "wizard-type": "custom"
//                   },
//                   {
//                     "comments": "",
//                     "connection_count": 30,
//                     "creation_time": 2575831,
//                     "incoming_bytes": 168,
//                     "name": "VPN_Server",
//                     "outgoing_bytes": 168,
//                     "proxyid": [
//                       {
//                         "expire": 41900,
//                         "incoming_bytes": 0,
//                         "outgoing_bytes": 0,
//                         "p2name": "VPN_Server",
//                         "p2serial": 1,
//                         "proxy_dst": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "10.1.3.0/255.255.255.0"
//                           }
//                         ],
//                         "proxy_src": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "10.1.2.0/255.255.255.0"
//                           }
//                         ],
//                         "status": "up"
//                       }
//                     ],
//                     "rgwy": "10.198.3.3",
//                     "tun_id": "10.198.3.3",
//                     "tun_id6": "::10.198.3.3",
//                     "type": "automatic",
//                     "username": "VPN_Server",
//                     "wizard-type": "custom"
//                   },
//                   {
//                     "comments": "VPN: HUB1-VPN1 [Created by IPSEC Template]",
//                     "connection_count": 30,
//                     "creation_time": 2575831,
//                     "incoming_bytes": 211903886,
//                     "name": "HUB1-VPN1",
//                     "outgoing_bytes": 211903449,
//                     "proxyid": [
//                       {
//                         "expire": 41176,
//                         "incoming_bytes": 141859,
//                         "outgoing_bytes": 380772,
//                         "p2name": "HUB1-VPN1",
//                         "p2serial": 2,
//                         "proxy_dst": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "proxy_src": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "status": "up"
//                       }
//                     ],
//                     "rgwy": "10.198.5.2",
//                     "tun_id": "10.198.5.2",
//                     "tun_id6": "::10.198.5.2",
//                     "type": "automatic",
//                     "username": "10.198.5.2",
//                     "wizard-type": "custom"
//                   },
//                   {
//                     "comments": "VPN: HUB1-VPN2 [Created by IPSEC Template]",
//                     "connection_count": 30,
//                     "creation_time": 2575831,
//                     "incoming_bytes": 211906500,
//                     "name": "HUB1-VPN2",
//                     "outgoing_bytes": 211906256,
//                     "proxyid": [
//                       {
//                         "expire": 41180,
//                         "incoming_bytes": 141419,
//                         "outgoing_bytes": 379584,
//                         "p2name": "HUB1-VPN2",
//                         "p2serial": 2,
//                         "proxy_dst": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "proxy_src": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "status": "up"
//                       }
//                     ],
//                     "rgwy": "10.198.6.2",
//                     "tun_id": "10.198.6.2",
//                     "tun_id6": "::10.198.6.2",
//                     "type": "automatic",
//                     "username": "10.198.6.2",
//                     "wizard-type": "custom"
//                   },
//                   {
//                     "comments": "VPN: HUB2-VPN1 [Created by IPSEC Template]",
//                     "connection_count": 30,
//                     "creation_time": 2575831,
//                     "incoming_bytes": 211907482,
//                     "name": "HUB2-VPN1",
//                     "outgoing_bytes": 211907311,
//                     "proxyid": [
//                       {
//                         "expire": 41176,
//                         "incoming_bytes": 141868,
//                         "outgoing_bytes": 380864,
//                         "p2name": "HUB2-VPN1",
//                         "p2serial": 2,
//                         "proxy_dst": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "proxy_src": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "status": "up"
//                       }
//                     ],
//                     "rgwy": "10.198.7.2",
//                     "tun_id": "10.198.7.2",
//                     "tun_id6": "::10.198.7.2",
//                     "type": "automatic",
//                     "username": "10.198.7.2",
//                     "wizard-type": "custom"
//                   },
//                   {
//                     "comments": "VPN: HUB2-VPN2 [Created by IPSEC Template]",
//                     "connection_count": 30,
//                     "creation_time": 2575831,
//                     "incoming_bytes": 211905860,
//                     "name": "HUB2-VPN2",
//                     "outgoing_bytes": 211905243,
//                     "proxyid": [
//                       {
//                         "expire": 41171,
//                         "incoming_bytes": 142268,
//                         "outgoing_bytes": 381944,
//                         "p2name": "HUB2-VPN2",
//                         "p2serial": 2,
//                         "proxy_dst": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "proxy_src": [
//                           {
//                             "port": 0,
//                             "protocol": 0,
//                             "protocol_name": "",
//                             "subnet": "0.0.0.0/0.0.0.0"
//                           }
//                         ],
//                         "status": "up"
//                       }
//                     ],
//                     "rgwy": "10.198.8.2",
//                     "tun_id": "10.198.8.2",
//                     "tun_id6": "::10.198.8.2",
//                     "type": "automatic",
//                     "username": "10.198.8.2",
//                     "wizard-type": "custom"
//                   }
//                 ]
