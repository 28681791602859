import { fiAdom, fiSysConfig } from 'fi-session';
import { isFunction, isNil, isUndefined } from 'lodash';

const boundListeners = {};

// Create IE + others compatible event handler
const eventMethod = window.addEventListener
  ? 'addEventListener'
  : 'attachEvent';
const rmeventMethod = window.addEventListener
  ? 'removeEventListener'
  : 'detachEvent';
const eventer = window[eventMethod];
const rmeventer = window[rmeventMethod];
const messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

const send = (type, message, targetId) => {
  const childId = targetId || 'map_iframe';
  const child = document.getElementById(childId)
    ? document.getElementById(childId).contentWindow
    : null;
  if (child) {
    child.postMessage(
      JSON.stringify({
        type: type,
        adom: fiAdom.current().name,
        message: message,
      }),
      fiSysConfig.current().mapserver_url || MACROS.USER.SYS.MAP_SERVER_HOST
    );
  }
};

const decorateListener = (listener) => {
  return function (e) {
    listener(e);
  };
};

const attach = (featureName, listener) => {
  if (isUndefined(boundListeners[featureName])) {
    boundListeners[featureName] = { handler: null, count: 0 };
  }
  if (
    boundListeners[featureName].count === 0 &&
    !boundListeners[featureName].handler
  ) {
    boundListeners[featureName].handler = decorateListener(listener); //save the even handler reference
    eventer(messageEvent, boundListeners[featureName].handler);
  }
  //save the current handler counts. If count == -1, just increment the count but not bind the handler
  //This happends when changing states under Map but set {reload: false}. At this time if going to an
  //other state, the map state url will be reloaded by ui-router before entering the target state.
  //See example : mapTreeNodeClick() in ap_view.js, and fiFortiAPGoogleMapCtrl
  boundListeners[featureName].count++;
};

function detach(featureName) {
  if (!isNil(boundListeners[featureName])) {
    boundListeners[featureName].count--;
    if (isFunction(boundListeners[featureName].handler)) {
      rmeventer(messageEvent, boundListeners[featureName].handler);
      boundListeners[featureName].handler = null;
    }
  }
}

function getBoundStatusByFeature(featureName) {
  return boundListeners[featureName];
}

export const postMessageService = {
  send: send,
  attach: attach,
  detach: detach,
  getBoundStatusByFeature: getBoundStatusByFeature,
};
