export const dashboards = [
  {
    uuid: 'threat',
    name: gettext('Top Threats'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_threat',
        grid: {
          id: 'top_threat',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'threat_map',
    name: gettext('Threat Map'),
    timePeriodType: 'threat_map',
    widgets: [
      {
        name: 'threatmap',
        grid: {
          id: 'threatmap',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'threat_detect_summary',
    name: gettext('Indicator of Compromise'),
    timePeriodType: 'ioc',
    widgets: [
      {
        name: 'ioc_user',
        grid: {
          id: 'ioc_user',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'fsa_detection_view',
    name: gettext('FortiSandbox Detection'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'fsa_detection',
        grid: {
          id: 'fsa_detection',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'source_hosts',
    name: gettext('Top Sources'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_source',
        grid: {
          id: 'top_source',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'source_objects',
    name: gettext('Top Source Addresses'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_source_objects',
        grid: {
          id: 'top_source_objects',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'destination_ips',
    name: gettext('Top Destinations'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_destination',
        grid: {
          id: 'top_destination',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'destination_objects',
    name: gettext('Top Destination Addresses'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_destination_objects',
        grid: {
          id: 'top_destination_objects',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'country',
    name: gettext('Top Country/Region'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_country',
        grid: {
          id: 'top_country',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'policy_hits',
    name: gettext('Policy Hits'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'policy_hits',
        grid: {
          id: 'policy_hits',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'create_policy_by_hits',
    name: gettext('Create Policy by Hits'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'create_policy_by_hits',
        grid: {
          id: 'create_policy_by_hits',
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'dns_log_view',
    name: gettext('DNS Logs'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'dns_logs',
        grid: {
          id: 'dns_logs',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'ztna_servers',
    name: gettext('ZTNA Servers'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'ztna_servers_by_bytes',
        grid: {
          id: 'ztna_servers_by_bytes',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'app',
    name: gettext('Top Applications'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_application',
        grid: {
          id: 'top_application',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'cloud_application',
    name: gettext('Top Cloud Applications'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_cloud_application',
        grid: {
          id: 'top_cloud_application',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'cloud_user',
    name: gettext('Top Cloud Users'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_cloud_user',
        grid: {
          id: 'top_cloud_user',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'website_domain',
    name: gettext('Top Website Domains'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_website',
        grid: {
          id: 'top_website',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'website_category',
    name: gettext('Top Website Categories'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_website_category',
        grid: {
          id: 'top_website_category',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'browsing_users',
    name: gettext('Top Browsing Users'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_browsing_user',
        grid: {
          id: 'top_browsing_user',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'ssl',
    name: gettext('SSL and Dialup IPsec'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_vpn',
        grid: {
          id: 'top_vpn',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'site',
    name: gettext('Site-to-Site IPsec'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'vpn_site2site',
        grid: {
          id: 'vpn_site2site',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'admin',
    name: gettext('Admin Logins'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'admin',
        grid: {
          id: 'admin',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'system_events',
    name: gettext('System Events'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'system_events',
        grid: {
          id: 'system_events',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'resource',
    name: gettext('Resource Usage'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'resource_average',
        grid: {
          id: 'resource_average',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
      },
      {
        type: 'table',
        name: 'resource_peak',
        grid: {
          id: 'resource_peak',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 12,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  {
    uuid: 'unauthorized_connections',
    name: gettext('Failed Authentication Attempts'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'unauthorized_connections',
        grid: {
          id: 'unauthorized_connections',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FOS],
  },
  /* FortiClient related dashboards */
  {
    uuid: 'fct_threat',
    name: gettext('Top Threats (FortiClient)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_threat_fct',
        grid: {
          id: 'top_threat_fct',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FCT],
  },
  {
    uuid: 'fct_app',
    name: gettext('Top Applications (FortiClient)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_app_fct',
        grid: {
          id: 'top_app_fct',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FCT],
  },
  {
    uuid: 'fct_website',
    name: gettext('Top Web Sites (FortiClient)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_website_fct',
        grid: {
          id: 'top_website_fct',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FCT],
  },
  /* FortiDDoS related dashboards */
  {
    uuid: 'fddos_source_view',
    name: gettext('Top Sources (FortiDDoS)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_source_fddos',
        grid: {
          id: 'top_source_fddos',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FDD],
  },
  {
    uuid: 'fddos_destination_view',
    name: gettext('Top Destinations (FortiDDoS)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_destination_fddos',
        grid: {
          id: 'top_destination_fddos',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FDD],
  },
  {
    uuid: 'fddos_type_view',
    name: gettext('Top Type (FortiDDoS)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_type_fddos',
        grid: {
          id: 'top_type_fddos',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FDD],
  },
  // for FortiFirewall
  {
    uuid: 'ffw_source_hosts',
    name: gettext('Top Sources'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_source_ffw',
        grid: {
          id: 'top_source_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_source_objects',
    name: gettext('Top Source Addresses'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_source_objects_ffw',
        grid: {
          id: 'top_source_objects_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_destination_ips',
    name: gettext('Top Destinations'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_destination_ffw',
        grid: {
          id: 'top_destination_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_destination_objects',
    name: gettext('Top Destination Addresses'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_destination_objects_ffw',
        grid: {
          id: 'top_destination_objects_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_country',
    name: gettext('Top Country/Region'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_country_ffw',
        grid: {
          id: 'top_country_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_policy_hits',
    name: gettext('Policy Hits'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'policy_hits_ffw',
        grid: {
          id: 'policy_hits_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_ssl',
    name: gettext('SSL and Dialup IPsec'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'top_vpn_ffw',
        grid: {
          id: 'top_vpn_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_site',
    name: gettext('Site-to-Site IPsec'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'vpn_site2site_ffw',
        grid: {
          id: 'vpn_site2site_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_admin',
    name: gettext('Admin Logins'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'admin_ffw',
        grid: {
          id: 'admin_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_system_events',
    name: gettext('System Events'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'system_events_ffw',
        grid: {
          id: 'system_events_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_resource',
    name: gettext('Resource Usage'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'resource_average_ffw',
        grid: {
          id: 'resource_average_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
      },
      {
        type: 'table',
        name: 'resource_peak_ffw',
        grid: {
          id: 'resource_peak_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 12,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw_unauthorized_connections',
    name: gettext('Failed Authentication Attempts'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'unauthorized_connections_ffw',
        grid: {
          id: 'unauthorized_connections_ffw',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  // for FortiWeb
  {
    uuid: 'fwb_security_country',
    name: gettext('Countries (FortiWeb)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'fwb_security_country',
        grid: {
          id: 'fwb_security_country',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FWB],
  },
  {
    uuid: 'fwb_security_threat',
    name: gettext('Threats (FortiWeb)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'fwb_security_threat',
        grid: {
          id: 'fwb_security_threat',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FWB],
  },
  {
    uuid: 'fwb_security_policy',
    name: gettext('Server Policies (FortiWeb)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'fwb_security_policy',
        grid: {
          id: 'fwb_security_policy',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FWB],
  },
  {
    uuid: 'fwb_traffic_source',
    name: gettext('Sources (FortiWeb)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'fwb_traffic_source',
        grid: {
          id: 'fwb_traffic_source',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FWB],
  },
  {
    uuid: 'fwb_traffic_country',
    name: gettext('Countries (FortiWeb)'),
    timePeriodType: 'normal',
    widgets: [
      {
        type: 'table',
        name: 'fwb_traffic_country',
        grid: {
          id: 'fwb_traffic_country',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FWB],
  },
];
