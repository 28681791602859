import { isMVCModeHACluster } from './ha_related';

const _DVM_M = MACROS.DVM;
const _PO_M = MACROS.PO;

export const deviceStatus = {
  // rtype device record type
  rtype: {
    device: 0,
    deviceHasVdom: 1,
    deviceWithoutMgt: 2,
    vdom: 3,
    vdomMgt: 4,
    vdomWithoutMgt: 5,
  },
  isVdom: function (recordRtype) {
    return (
      recordRtype === this.rtype.vdom ||
      recordRtype === this.rtype.vdomMgt ||
      recordRtype === this.rtype.vdomWithoutMgt
    );
  },
  isMgtVdom: function (recordRtype) {
    return recordRtype === this.rtype.vdomMgt;
  },
  isDevice: function (recordRtype) {
    return (
      recordRtype === this.rtype.device ||
      recordRtype === this.rtype.deviceHasVdom ||
      recordRtype === this.rtype.deviceWithoutMgt
    );
  },
  isDeviceOnline: function (device) {
    return device?.connection?.conn === this.conn.connected;
  },
  isDeviceNoVdom: function (recordRtype) {
    return recordRtype === this.rtype.device;
  },
  isDeviceHasVdom: function (recordRtype) {
    return recordRtype === this.rtype.deviceHasVdom;
  },
  isHACluster: (device) => {
    return device?.connection?.ha_mode !== MACROS.DVM.DVM_HA_MODE_STANDALONE;
  },
  isModelHACluster: function (device) {
    // test if this device is a ha cluster and before autolink
    return device.model_dev && deviceStatus.isHACluster(device);
  },
  isMVCModeHACluster: (device) => {
    return (
      deviceStatus.isHACluster(device) &&
      isMVCModeHACluster(device?.connection?.ha_upgrade_mode)
    );
  },
  isHASecondaryDown: function (device) {
    const haMembers = device.connection?.ha;
    const isHa = Array.isArray(haMembers) && haMembers.length > 0;
    if (!isHa) return false;

    for (const member of haMembers) {
      const isSecondary = !member.role;
      const isDown = member.conn === deviceStatus.conn.down;
      if (isSecondary && isDown) return true;
    }
  },
  isSdwanDevice: function (device) {
    return device && !!device.sdwan_management;
  },
  field: {
    name: 'name',
    deviceName: 'name',
    cfgStatus: 'sync',
    pkgStatus: 'pp_sync',
    fwmprofStatus: 'fwmprof_sync',
    profStatus: 'prof_sync',
    firmwareUpgrade: 'firmware_upgrade',
    sdwanStatus: MACROS.USER.DVM.PT_TYPE.SDWAN_TEMPLATE,
    //'wifiStatus': 'wifi_prof',
    //'fswStatus': 'fsw_prof',
    devprofStatus: MACROS.USER.DVM.PT_TYPE.SYSTEM_TEMPLATE,
    cliStatus: MACROS.USER.DVM.PT_TYPE.CLI_TEMPLATE,
    tmplGrpStatus: MACROS.USER.DVM.PT_TYPE.TEMPLATE_GROUP,
    prerunCliStatus: 'prerun_cliprof',
    hostName: 'hostname',
    ip: 'ip',
    platform: 'platform',
    quota: 'quota',
    connectivity: 'connection',
    license: 'license',
    fwVersion: 'ver',
    logs: 'logs',
    logRate: 'log_ave_rate',
    description: 'desc',
    manage_mode: 'manage_mode',
    controller_counter: 'controller_counter',
    policy_diff: 'policy_diff',
    private_key_verify: 'private_key_verify', //ui-only,
    private_key_verify_status: 'private_key_verify_status', //ui-only
    serial_number: 'sn',
    staticRouteStatus: MACROS.USER.DVM.PT_TYPE.ROUTE_TEMPLATE,
    ipsecStatus: MACROS.USER.DVM.PT_TYPE.IPSEC_TEMPLATE,
    fextStatus: 'fext_prof',
    bgpStatus: 'router_bgp',
  },
  pp_sync: {
    synchronized: _PO_M.PM3_PKG_STATUS_INSTALLED,
    modified: _PO_M.PM3_PKG_STATUS_MODIFIED,
    imported: _PO_M.PM3_PKG_STATUS_IMPORTED,
    unknown: _PO_M.PM3_PKG_STATUS_UNKNOWN,
    pend_auto_push: _PO_M.PM3_PKG_STATUS_SIGNED,
    pend_gen: _PO_M.PM3_PKG_STATUS_PEND_GEN,
    autopush: _PO_M.PM3_PKG_STATUS_PEND_SIGN,
    fail_gen: _PO_M.PM3_PKG_STATUS_FAIL_GEN,
    fail_sign: _PO_M.PM3_PKG_STATUS_FAIL_SIGN,
    outofsync: _PO_M.PM3_PKG_STATUS_OUTOFSYNC,
    conflict: _PO_M.PM3_PKG_STATUS_CONFLICT,
    neverInstalled: -1,
  },
  // from cgi_bin/module/common/GUIGlobalVariables.h
  license: {
    unknown: 0,
    valid: 1,
    expire_soon: 2,
    expired: 3,
  },
  conn: {
    connected: _DVM_M.DVM_CONN_STATUS_UP,
    down: _DVM_M.DVM_CONN_STATUS_DOWN,
    unknown: _DVM_M.DVM_CONN_STATUS_UNKNOWN,
  },
  auto_link: {
    enabled: _DVM_M.DVM_CONN_STATUS_UP,
    disabled: _DVM_M.DVM_CONN_STATUS_DOWN,
    autolink_failed: _DVM_M.DVM_CONN_STATUS_UNKNOWN,
  },
  ha_mode: {
    standalone: _DVM_M.DVM_HA_MODE_STANDALONE,
  },
  dev_diff_flag: {
    equals: 0,
    FMGOnly: 1,
    different: 2,
    FAZOnly: 3,
  },
  getContractSupportTypeText: function (type, abbr) {
    switch (type) {
      case MACROS.FGD.SUPPORT_LEVEL_DEFAULT:
        return gettext('Default');
      case MACROS.FGD.SUPPORT_LEVEL_UPGRADE_24X7:
        if (abbr) {
          return '24x7';
        }
        return gettext('Upgrade 24x7 Support');
      case MACROS.FGD.SUPPORT_LEVEL_RETURN_TO_FACTORY:
        if (abbr) {
          return 'Return';
        }
        return gettext('Return to factory');
      case MACROS.FGD.SUPPORT_LEVEL_ADVANCED_HW:
        if (abbr) {
          return 'Advanced HW';
        }
        return gettext('Advanced Hardware');
      case MACROS.FGD.SUPPORT_LEVEL_WEB_ONLINE:
        if (abbr) {
          return 'Online';
        }
        return gettext('Web/Online');
      case MACROS.FGD.SUPPORT_LEVEL_24X7_ND_HW:
        if (abbr) {
          return '24x7';
        }
        return gettext('24x7 ND Hardware');
      case MACROS.FGD.SUPPORT_LEVEL_24X7X4_COURIER_HW:
        if (abbr) {
          return '24x7x4';
        }
        return gettext('24x7x4 Courier Hardware');
      case MACROS.FGD.SUPPORT_LEVEL_24X7X4_ON_SITE_HW:
        if (abbr) {
          return '24x7x4';
        }
        return gettext('24x7x4 On Site Hardware');
      case MACROS.FGD.SUPPORT_LEVEL_8X5:
        return '8x5';
      case MACROS.FGD.SUPPORT_LEVEL_ACTEL_8X5:
        if (abbr) {
          return '8x5';
        }
        return 'Actel 8x5';
      case MACROS.FGD.SUPPORT_LEVEL_L3_8X5:
        if (abbr) {
          return '8x5';
        }
        return 'L3 8x5';
      case MACROS.FGD.SUPPORT_LEVEL_NO_RETURN:
        if (abbr) {
          return 'NoReturn';
        }
        return gettext('No Return');
      case MACROS.FGD.SUPPORT_LEVEL_24X7:
        if (abbr) {
          return '24x7';
        }
        return '24x7';
      case MACROS.FGD.SUPPORT_LEVEL_ACTEL_24X7:
        if (abbr) {
          return '24x7';
        }
        return 'Actel 24x7';
      case MACROS.FGD.SUPPORT_LEVEL_L3_24X7:
        if (abbr) {
          return '24x7';
        }
        return 'L3 24x7';
      case MACROS.FGD.SUPPORT_LEVEL_TECHDIRECT_1:
        if (abbr) {
          return 'Tech';
        }
        return 'TechDirect';
      case MACROS.FGD.SUPPORT_LEVEL_TECHDIRECT_2:
        if (abbr) {
          return 'Tech';
        }
        return 'TechDirect';
      case MACROS.FGD.SUPPORT_LEVEL_PREFERRED:
        return gettext('Preferred');
      case MACROS.FGD.SUPPORT_LEVEL_TAM_GOLD:
        if (abbr) {
          return 'TAM';
        }
        return 'TAM Gold';
      case MACROS.FGD.SUPPORT_LEVEL_TAM_GLOBAL_GOLD:
        if (abbr) {
          return 'TAM';
        }
        return 'TAM Global Gold';
      case MACROS.FGD.SUPPORT_LEVEL_RESIDENT_ENGINEER:
        if (abbr) {
          return 'Resident';
        }
        return gettext('Resident Engineer');
      case MACROS.FGD.SUPPORT_LEVEL_PLATINUM:
        return gettext('Platinum');
      case MACROS.FGD.SUPPORT_LEVEL_30_DAY_SETUP_INCL_ADV_HW:
        if (abbr) {
          return '30Day';
        }
        return gettext('30-day setup including advanced hardware');
      case MACROS.FGD.SUPPORT_LEVEL_RESIDENT_ARCHITECT:
        if (abbr) {
          return 'Resident';
        }
        return gettext('Resident Architect');
      case MACROS.FGD.SUPPORT_LEVEL_RESIDENT_TEAM_LEAD:
        if (abbr) {
          return 'Resident';
        }
        return gettext('Resident Team Lead');
      case MACROS.FGD.SUPPORT_LEVEL_RESIDENT_ENGINEER_TE:
        if (abbr) {
          return 'Resident';
        }
        return gettext('Resident Engineer + TE');
      case MACROS.FGD.SUPPORT_LEVEL_ONSITE:
        if (abbr) {
          return 'OnSite';
        }
        return gettext('On site');
      case MACROS.FGD.SUPPORT_LEVEL_REMOTE:
        return 'Remote';
      case MACROS.FGD.SUPPORT_LEVEL_100GB:
        return '100GB';
      case MACROS.FGD.SUPPORT_LEVEL_10GB_25MB:
        return '10G/25MB';
      case MACROS.FGD.SUPPORT_LEVEL_25GB_50MB:
        return '25GB/50MB';
      case MACROS.FGD.SUPPORT_LEVEL_50GB:
        return '50G';
      case MACROS.FGD.SUPPORT_LEVEL_ADVANCED_COORDINATOR:
        if (abbr) {
          return 'Coordinator';
        }
        return gettext('Advanced Coordinator');
      case MACROS.FGD.SUPPORT_LEVEL_ENTERPRISE_PREMIUM:
        if (abbr) {
          return 'Enterprise';
        }
        return gettext('Enterprise Premium');
      case MACROS.FGD.SUPPORT_LEVEL_ENTERPRISE_BUSINESS:
        if (abbr) {
          return 'Enterprise';
        }
        return gettext('Enterprise Business');
      case MACROS.FGD.SUPPORT_LEVEL_ENTERPRISE_FIRST:
        if (abbr) {
          return 'Enterprise';
        }
        return gettext('Enterprise First');
      case MACROS.FGD.SUPPORT_LEVEL_GLOBAL_FIRST:
        if (abbr) {
          return 'Global';
        }
        return gettext('Global First');
      case MACROS.FGD.SUPPORT_LEVEL_SELECT:
        return gettext('Select');
      case MACROS.FGD.SUPPORT_LEVEL_ELITE:
        return gettext('Elite');
      case MACROS.FGD.SUPPORT_LEVEL_40GB:
        return '40GB';
      case MACROS.FGD.SUPPORT_LEVEL_80GB:
        return '80GB';
      case MACROS.FGD.SUPPORT_LEVEL_120GB:
        return '120GB';
      case MACROS.FGD.SUPPORT_LEVEL_UP_TO_200G_STORAGE:
        if (abbr) {
          return 'Up 200GB';
        }
        return gettext('Up to 200G storage');
      case MACROS.FGD.SUPPORT_LEVEL_PREMIUM_SERVICE:
        return gettext('Premium');
      case MACROS.FGD.SUPPORT_LEVEL_STANDARD:
        return gettext('Standard');
      case MACROS.FGD.SUPPORT_LEVEL_DESIGNATED_DELIVERY_MANAGER:
        if (abbr) {
          return 'DDM';
        }
        return gettext('Designated delivery manager');
      case MACROS.FGD.SUPPORT_LEVEL_TRIAL:
        return gettext('Trial');
      default:
        return gettext('N/A');
    }
  },
};
