import { get } from 'lodash';
import { fiFmgHttp } from 'fi-http';
import $ from 'jquery';
import { createDeferred } from 'kit-promise';
import { fiWebSocket } from 'fi-websocket';

export const fiDeviceListLoader = {
  loadDevice,
  loadFullDeviceList,
  getLoadFullDeviceListStatus,
  loadUnregDeviceList,
  loadNonFGTGroupMemberList,
};

const _fdl_status = {};

function getLoadFullDeviceListStatus() {
  return _fdl_status;
}

/**
 * load given device info
 * @param {integer} devOid
 * @return {Promise}
 */
function loadDevice(devOid) {
  const req = {
    url: '/gui/adom/dvm/device',
    method: 'get',
    params: {
      deviceId: parseInt(devOid, 10),
      deviceAttrs: ['all'],
      vdomAttrs: ['all'],
    },
  };
  return fiFmgHttp.post(req).then(function (resp) {
    return resp[0] ? resp[0].data : {};
  });
}
/**
 * Loads all devices of current ADOM from server
 * For Database Data Fetcher Use Only. Public should load the list from Database Data Fetcher directly.
 */
function loadFullDeviceList(adomOid) {
  const defer = new $.Deferred();
  const wsReq = {
    url: '/gui/adom/dvm/devices',
    method: 'get',
    dataChunked: {
      firstRecords: 200,
      maxRecords: 2000,
    },
    params: {
      deviceAttrs: ['all'],
      vdomAttrs: ['all'],
    },
  };
  if (adomOid) {
    if (typeof adomOid === 'string') wsReq.params.adom = parseInt(adomOid, 10);
    else wsReq.params.adom = adomOid;
  }
  let data = [];
  fiWebSocket.send(fiWebSocket.genWSRequest(wsReq)).then(
    function () {
      _fdl_status.total = Array.isArray(data) ? data.length : 0;
      defer.resolve(data);
    },
    function (err) {
      defer.reject(err);
    },
    function ({ chunk }) {
      if (chunk) {
        if (chunk.header) {
          // update predicate
          _fdl_status.total = chunk.header.total || 0;
          defer.notify(chunk);
        } else if (chunk.tail) {
          // update real total
          _fdl_status.total = _fdl_status.had = data.length;
          defer.notify(chunk);
        } else {
          // From Jin Fang: some models can have data in `chunk` instead of `chunk.data`
          const chunkData = chunk.data || chunk || [];

          // add to data array
          data = data.concat(chunkData);

          // update status
          _fdl_status.had = data.length;

          // notify chunk
          defer.notify({ data: chunk });
        }
      }
    }
  );
  return defer.promise();
}

/**
 * Loads Unregistered devices from server
 * For *fiDeviceDataLoader* Use Only. Public should load the list from fiDeviceDataLoader
 */
function loadUnregDeviceList() {
  return _loadDeviceGroupMembers(MACROS.DVM.DVM_GRP_UNREG_OID);
}

/**
 * Loads non-fgt device group member from server
 * For *fiDeviceDataLoader* Use Only. Public should load the list from fiDeviceDataLoader
 */
function loadNonFGTGroupMemberList(grpOid) {
  return _loadDeviceGroupMembers(grpOid);
}

/**
 * Get device group members
 * @param {*oid} grpOid
 */
function _loadDeviceGroupMembers(grpOid) {
  const defer = createDeferred();

  fiFmgHttp
    .post({
      url: '/gui/adom/dvm/group/devices',
      method: 'get',
      params: {
        deviceAttrs: ['all'],
        vdomAttrs: ['all'],
        oid: parseInt(grpOid, 10),
      },
    })
    .then(
      function (resp) {
        if (!resp) {
          defer.reject('Error');
        } else {
          const respData = get(resp, '0.data', []);
          defer.resolve(respData);
        }
      },
      function (err) {
        defer.reject(err);
      }
    );

  return defer.promise;
}
