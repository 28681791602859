import './Toolbar.less';
import { TextSearch } from '../TextSearch';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { renderIcon, renderLabel } from 'ra-render-util';
import { debounce } from 'fiutil';
import { NwMenu } from '@fafm/neowise/react';
import { ProToolkit } from '@fafm/neowise-pro';
const { useResizeObserver } = ProToolkit;

export { Toolbar };

/* Item structures:

button: {
  icon: string | react component,
  disabled: boolean,
  label: string,
  key: string,
  exec: function,
}

dropdown: {
  ...button props,
  noclose: boolean,
  items: [
    '-' divider,
    button props,
  ]
}

*/
const Toolbar = ({
  items = [],
  rightItems = [],
  noHoist = false,

  autoIdPrefix,
  /**
   * undefined | {
   *  onChange: (string) => {}
   * }
   */
  textSearch,
}) => {
  const toolbarRef = useRef(null);
  useKeyboardingBindings(toolbarRef);

  const { numLeftLabelsDisplayed } = useToolbarResizeData(toolbarRef, items);

  return (
    <div
      className='react-toolbar tw-whitespace-nowrap tw-flex tw-justify-between'
      ref={toolbarRef}
    >
      <div
        className='left-items tw-whitespace-nowrap tw-flex'
        data-focus-type='toolbar-item-container'
      >
        {items.map((item, i) => {
          const hideLabel = i >= numLeftLabelsDisplayed;
          return renderToolbarItem(autoIdPrefix, hideLabel, item, noHoist);
        })}
      </div>

      {/* align right */}
      <div
        className='right-items tw-whitespace-nowrap tw-flex'
        data-focus-type='toolbar-item-container'
      >
        {rightItems.map((item) =>
          renderToolbarItem(autoIdPrefix, item.hideLabel, item, noHoist)
        )}
        {textSearch && (
          <TextSearch
            autoIdPrefix={autoIdPrefix}
            {...textSearch}
            class='tw-inline-block'
          />
        )}
      </div>
    </div>
  );
};
Toolbar.displayName = 'Toolbar';

function renderToolbarItem(autoIdPrefix, hideLabel = false, item) {
  let { render, Component, renderFn, items } = item;
  Component = Component || render;

  const props = { autoIdPrefix, hideLabel, ...item.props, item };

  let itemKey = item.key;
  if (typeof itemKey === 'undefined') {
    itemKey = 'random-key-' + Math.random();
  }

  return (
    <React.Fragment key={itemKey}>
      {
        // NOTE: add keyboard support when add new type!
        (renderFn && renderFn(props)) ||
          (Array.isArray(items) && <DropDown {...props} />) ||
          (Component && <Component {...props} />) || <CmdBtn {...props} />
      }
    </React.Fragment>
  );
}

/**
 * @param {{
 *  key: string,
 *  icon: string | react component,
 *  label: string,
 *  disabled: boolean,
 *  exec: () => any
 * }} prop
 */
function CmdBtn({ autoIdPrefix, item, children, hideLabel = false, ...rest }) {
  const { icon, disabled, label, title, key, exec } = item;
  return (
    <nw-button
      title={title || label}
      disabled={!!disabled || undefined}
      onClick={exec}
      automation-id={autoIdPrefix + '-' + key}
      type='text'
      {...rest}
    >
      {renderIcon(icon, { slot: 'prefix' })}
      {!hideLabel && <span className='btn-label'>{label}</span>}
      {children}
    </nw-button>
  );
}
CmdBtn.displayName = 'ToolbarCmdBtn';

/**
 * @param {{
 * ... CmdBtn props
 * noclose: boolean
 * open: boolean
 * items: [
 * '-', //divider
 * ...]
 * }} param0
 */
function DropDown({ autoIdPrefix, hideLabel, item }) {
  const { noclose, open = false, items, ...rest } = item;

  return (
    <nw-dropdown stay-open-on-select={noclose} hoist open={open}>
      <CmdBtn
        autoIdPrefix={autoIdPrefix}
        hideLabel={hideLabel}
        item={rest}
        caret
        slot='trigger'
      />
      <NwMenu
        onNwSelect={debounce((evt) => {
          const index = parseInt(evt.detail.item.getAttribute('data-seq-idx'));
          const { disabled, exec } = items[index] || {};
          if (!disabled && typeof exec === 'function') {
            exec(evt);
          }
        }, 60)}
      >
        {items.map(
          renderDropdownItem.bind(null, autoIdPrefix + '-' + rest.key)
        )}
      </NwMenu>
    </nw-dropdown>
  );
}

DropDown.displayName = 'ToolbarDropDown';

function renderDropdownItem(autoIdPrefix, item, i) {
  if (!item) {
    return null;
  } else if (isDivider(item)) {
    return <nw-menu-divider key={'divider-' + i}></nw-menu-divider>;
  } else {
    const { icon, disabled, label, key } = item;
    return (
      <nw-menu-item
        key={item.key}
        title={label}
        disabled={!!disabled || undefined}
        //nw-menu has keyboard support by default; however, 'Enter' key will not trigger
        //onClick event in nw-menu-item, and it trigggers nw-menu container's onNwSelect
        //event, so need data-seq-idx to get the correct index of the clicked item
        data-seq-idx={i}
        // onClick={exec} // onClick event is handled in parent level ('nw-menu')
        automation-id={autoIdPrefix + '-' + key}
      >
        {renderIcon(icon, { slot: 'prefix' })}
        {renderLabel(label)}
      </nw-menu-item>
    );
  }
}

function isDivider(item) {
  return !item.label;
}

function useKeyboardingBindings(containerRef) {
  useEffect(() => {
    const keydownHandlers = {
      Enter: (evt) => {
        evt.target.click();
      },
      ArrowLeft: moveFocusHorizontally(getNextLeftToolbarSibling),
      ArrowRight: moveFocusHorizontally(getNexRightToolbarSibling),
    };

    function handler(event) {
      if (event.target.getAttribute('data-focus-type') === 'text-search')
        return;

      let handleFn;
      handleFn = keydownHandlers[event.key];

      if (!handleFn) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      handleFn(event);
    }

    let el = containerRef.current;

    el.addEventListener('keydown', handler);

    return () => {
      el.removeEventListener('keydown', handler);
    };
  }, []);
}

function moveFocusHorizontally(getSibling) {
  return () => {
    let focusEl = document.activeElement;

    if (isDropdownMenu(focusEl)) {
      // submenu expand collapse ?
      const maybeToggleBtn = focusEl.previousElementSibling;
      if (isDropdownToggleBtn(maybeToggleBtn)) {
        maybeToggleBtn.click();
        focusElement((focusEl = maybeToggleBtn));
      }
    }

    // in toolbar. move to enabled sibling and toggle if it is dropdown
    if (isToobarItem(focusEl)) {
      // move to enabled sibling
      const nextEl = getNextFocusableToolbarItem(focusEl, getSibling);
      focusElement(nextEl);

      // toggle if it is dropdown
      if (isDropdownToggleBtn(nextEl)) {
        nextEl.click();
      }
    }
  };
}

function getNextLeftToolbarSibling(el) {
  let leftEl = el.previousElementSibling;
  if (!leftEl) {
    //reach the end
    const containerEl = el.parentElement;
    return containerEl.lastElementChild;
  }
  return leftEl;
}

function getNexRightToolbarSibling(el) {
  let rightEl = el.nextElementSibling;
  if (!rightEl) {
    //reach the end
    const containerEl = el.parentElement;
    return containerEl.firstElementChild;
  }
  return rightEl;
}

function isToobarItem(el) {
  if (el) {
    if (isDropdownToggleBtn(el)) {
      el = el.parentElement;
    }
    return (
      el.parentElement.getAttribute('data-focus-type') ===
      'toolbar-item-container'
    );
  }
}

function isDropdown(el) {
  return el && el.tagName === 'NW-DROPDOWN';
}

function isDropdownToggleBtn(el) {
  return el && el.tagName === 'NW-BUTTON' && isDropdown(el.parentElement);
}

function isDropdownMenu(el) {
  return el && el.tagName === 'NW-MENU' && isDropdown(el.parentElement);
}

function getNextFocusableToolbarItem(el, getSibling) {
  do {
    if (!el) return;

    if (isDropdownToggleBtn(el)) {
      el = el.parentElement;
    }

    el = getSibling(el);

    if (isDropdown(el)) {
      el = el.firstElementChild;
    }
  } while (el && !canFocus(el));
  return el;
}

function canFocus(el) {
  return el && !el.disabled;
}

function focusElement(el) {
  el &&
    ((el.setFocus && el.setFocus()) || //nw-component
      (el.focus && el.focus()));
}

function getContentWidth(el) {
  const { paddingLeft, paddingRight } = getComputedStyle(el);

  return Math.ceil(
    el.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight)
  );
}

function getElementTotalWidth(el) {
  if (!(el.offsetWidth && el.offsetHeight)) {
    // element is not shown in document
    return 0;
  }

  const { marginLeft, marginRight } = getComputedStyle(el);
  return el.offsetWidth + parseFloat(marginLeft) + parseFloat(marginRight);
}

function useContentWidth(targetRef) {
  const [width, setWidth] = React.useState();

  const updateWidth = () => {
    setWidth(getContentWidth(targetRef.current));
  };

  useLayoutEffect(updateWidth, []);

  useResizeObserver(targetRef, updateWidth);

  return width;
}

function useToolbarResizeData(toolbarRef, items) {
  const [widthData, setWidthData] = useState(null);
  const [numLeftLabelsDisplayed, setNumLeftLabelsDisplayed] = useState(0);

  // Calculate full widths used by toolbar items and labels
  const parseToolbarElements = () => {
    // Already parsed toolbar
    if (widthData) return;

    let _widthData = {
      buttonsStaticWidth: 0, // Width of margins + icon for each button
      childrenWidth: 0, // Width of other children on the toolbar
      buttons: null,
    };

    Array.from(toolbarRef.current.children).forEach((toolbarEl) => {
      if (toolbarEl.className.includes('left-items')) {
        _widthData.buttons = Array.from(toolbarEl.children).map((item) => {
          const totalWidth = getElementTotalWidth(item);
          const textWidth =
            item.getElementsByClassName('btn-label')[0]?.offsetWidth || 0;
          _widthData.buttonsStaticWidth += totalWidth - textWidth;
          return { totalWidth, textWidth };
        });
      } else {
        _widthData.childrenWidth += getElementTotalWidth(toolbarEl);
      }
    });

    // Do not accept data if button elements do not have widths yet
    if (_widthData.buttonsStaticWidth > 0) {
      setWidthData(_widthData);
    }
  };

  useLayoutEffect(() => {
    parseToolbarElements();
  }, [items]);

  // On toolbar resize, calculate how many labels should be displayed in available toolbar space
  const toolbarContentWidth = useContentWidth(toolbarRef);
  useLayoutEffect(() => {
    if (!widthData) {
      parseToolbarElements();
      setNumLeftLabelsDisplayed(Number.MAX_VALUE);
      return;
    }

    let availableTextWidth =
      toolbarContentWidth -
      widthData.childrenWidth -
      widthData.buttonsStaticWidth;
    let currentTextWidth = 0;
    let numLabels = 0;

    // Include labels from left to right, as long as there is enough space remaining
    for (let i = 0; i < widthData.buttons.length; i++) {
      currentTextWidth += widthData.buttons[i].textWidth;
      if (currentTextWidth < availableTextWidth) {
        numLabels += 1;
      } else {
        break;
      }
    }

    setNumLeftLabelsDisplayed(numLabels);
  }, [toolbarContentWidth, widthData]);

  return {
    numLeftLabelsDisplayed,
  };
}
