/**
 * concat string using %s as placeholders
 * @function printf
 * @param {array} arr - values to interpolate into the string.
 * @return {string} - The string after interpolation.
 * @deprecated since version 7.4. use the `lodash.template` instead.
 */
export function printf(arr) {
  var n = 0;
  // eslint-disable-next-line
  return this.replace(/%s/g, function (match) {
    return String(arr[n++]);
  });
}

/**
 * compatible with python syntax: %(foo)s so translator can use one syntax for all string place holder.
 * @deprecated since version 7.4. use the `lodash.template` instead.
 */
export function printfd(obj) {
  return this.replace(/%\(\w+\)s/g, function (match) {
    return String(obj[match.slice(2, -2)]);
  });
}

String.prototype.printf = printf;
String.prototype.printfd = printfd;
