import { useRef, useMemo, useCallback } from 'react';
import { getValidateFn, validateAny } from './util';
import { isNil, isFunction } from 'lodash';

// don't import it from rh_util_hooks, that will drag the whole fistore in!
export function useRefCallback(callback) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  return useCallback((...args) => {
    if (isFunction(callbackRef.current)) {
      return callbackRef.current.apply(null, args);
    }
  }, []);
}

export const useUpdateRef = (val) => {
  const ref = useRef(val);
  ref.current = val;
  return ref;
};

export const useValidateFn = (
  { required, min, max, maxLength, minLength, type, pattern },
  validate
) => {
  validate = useRefCallback(isFunction(validate) ? validate : () => {});

  return useMemo(() => {
    const validateProps = {
      required,
      min,
      max,
      maxLength,
      minLength,
      type,
      pattern,
    };
    const fns = Object.entries(validateProps)
      .map(([key, value]) => !isNil(value) && getValidateFn(key, value))
      .filter(isFunction);

    return validateAny(validate, ...fns);
  }, [required, min, max, maxLength, minLength, type, pattern]);
};
