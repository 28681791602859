import { has, castArray } from 'lodash';
import { getPnoDisplayOpts } from 'fistore/pno/selectors';
import { getSyntax } from 'fistore/adomSyntax/selectors';

export const isVwpInDisplayOpts = (dspOpts) => {
  return dspOpts?.includes('all_vwp_policy');
};

export const vwpChecker = (state) => {
  const dspOpts = getPnoDisplayOpts(state) || [];
  return isVwpInDisplayOpts(dspOpts);
};

export const syntaxChecker = (cates) => (state) => {
  const syntax = getSyntax(state);
  return cates.some((cate) => syntax?.[cate]);
};

export const makeDisplayOptChecker = (appkey, checkerDef) => (state) => {
  if (checkerDef.mustShow) {
    return true;
  }
  const dspOpts = getPnoDisplayOpts(state);
  if (!dspOpts?.length) {
    return checkerDef.defaultShow;
  }
  return has(checkerDef, 'showIfDisplayOpts')
    ? checkerDef.showIfDisplayOpts(dspOpts)
    : dspOpts.includes(appkey);
};

export const callCheckers = (store, checkers) =>
  _callCheckers(checkers, { store });

export const callCheckersWith = (store, checkers) => (extraCheckerParams) =>
  _callCheckers(checkers, { store, ...extraCheckerParams });

const _callCheckers = (checkers, { store, ...extraCheckerParams }) => {
  return castArray(checkers).every((ck) => ck(store, extraCheckerParams));
};
