import { concat } from 'lodash';

import { dvm_pt_apps } from 'fi-web/fi-routing/all-apps/dvm';
import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';
import { getRouteObjectsFromApps } from 'fi-web/fi-routing/util';

export const ptRoute = {
  path: 'prvtmpl',
  id: 'prvtmpl', // e.g. for useRouteLoaderData hook
  handle: {
    appUniKey: 'dvm_pt',
  },
  lazy: () => import('react_apps/ra_pt/PTMain'),
  children: concat(
    getRedirectIndexRoute(),
    getRouteObjectsFromApps({ apps: dvm_pt_apps })
  ),
};
