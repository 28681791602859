export const BATCH_ACTIONS = 'BATCH_ACTIONS';

export function batchActions(actions, type = BATCH_ACTIONS) {
  return { type, meta: { batch: true }, payload: actions };
}

export function createBatchReducer(reduce) {
  return function batchReducer(state, action) {
    if (action && action.meta && action.meta.batch) {
      return action.payload.reduce(batchReducer, state);
    }
    return reduce(state, action);
  };
}
/*********************************/
let debounce_batch_pending = [];
let debounce_batch_timeout = null;

export function debounceBatch(action) {
  const meta = action.meta || {};
  meta.debounce_batch = true;

  return {
    ...action,
    meta,
  };
}

export const debounceBatchMiddleware = () => (next) => (action) => {
  const debounce = 50;

  const { debounce_batch } = action.meta || {};

  if (!debounce_batch) {
    return next(action);
  }
  delete action.meta.debounce_batch;

  debounce_batch_pending.push(action);
  if (debounce_batch_timeout === null) {
    debounce_batch_timeout = setTimeout(() => {
      next(batchActions(debounce_batch_pending));

      debounce_batch_pending = [];
      debounce_batch_timeout = null;
    }, debounce);
  }
};
