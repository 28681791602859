import * as actions from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { set, setWith, unset } from 'lodash';

const initState = {
  groups: null,
  fetcherStarted: false,
};

/**
 * payload.id must be string, otherwise the order will be wrong.
 */
export const reducer = createReducer(initState, (builder) => {
  builder
    .addCase(actions.setTask, (state, { payload }) => {
      if (payload.percent === 100) {
        unset(state, ['groups', payload.group, 'tasks', payload.id]);
      } else {
        // make sure set as an object, not array
        setWith(
          state,
          ['groups', payload.group, 'tasks', payload.id],
          payload,
          Object
        );
      }
    })
    .addCase(actions.setNum, (state, { payload }) => {
      set(state, ['groups', payload.group, 'num'], payload.num);
      // if num is 0, remove the tasks.
      if (payload.num === 0) {
        set(state, ['groups', payload.group, 'tasks'], null);
      }
    })
    .addCase(actions.startFetcher, (state) => {
      state.fetcherStarted = true;
    })
    .addCase(actions.stopFetcher, (state) => {
      state.fetcherStarted = false;
    });
});
