import { fiFmgHttp } from 'fi-http';
import { Provider } from 'react-redux';
import { fiAdom, fiAdminProfile, fiSysConfig } from 'fi-session';
import { AdomEdit } from '../adom_edit/AdomEdit';
import { TaskMonitor, TaskProgressBar } from 'rc_task_monitor';
import { ProForm } from '@fafm/neowise-pro';
import { fiMessageBox } from 'fi-messagebox';
import { fiWorkspace, getLockTimeStr } from 'fi-apps/fi-workspace';
import { fiStore } from 'fistore';
import { openConfirmModal } from 'react_components/rc_layout';
import { displayAdomRef } from '../adom_edit/util';

const { Header, Body } = ProForm;

export function switchToAdom(adom, currentAdom, $opener) {
  if (adom.expired) {
    return;
  } else if (
    fiSysConfig.isFmg() &&
    (adom.is_fosfoc ||
      adom.is_ffw ||
      adom.is_fwc ||
      adom.is_fpx ||
      adom.globaldb) &&
    !fiAdom.isSupportedVersion(adom)
  ) {
    fiMessageBox.show(
      gettext('ADOM of version "%s" is no longer supported.').printf([
        adom.version.ver + '.' + adom.version.mr,
      ]),
      'danger'
    );
    // removed because fiAppService is broken
    // } else if (!canAccessAdom(adom)) {
    //   fiMessageBox.show(
    //     gettext('You have no permission to access ADOM "%s".').printf([
    //       adom.name,
    //     ]),
    //     'danger'
    //   );
  } else {
    //M744973: After switching Adom, navigating to dvm should open default page
    // if (currentAdom && currentAdom.oid !== adom.oid) {
    //   fiAppService.setStateName('dvm', 'adom.default.dvm.main');
    //   fiAppService.deleteState('fext');
    // }
    $opener.resolve(adom);
  }
}

// export function canAccessAdom(adom) {
//   const fiAppService = $ng('fiAppService');
//
//   return (
//     fiAppService.getApps(adom).apps.length || fiAdminProfile.isRestrictedAdmin()
//   );
// }

export function _getAdomWhereUsed(adom) {
  var url = 'dvmdb/adom/%s/where used';
  var params = [
    {
      url: url.printf([adom['name']]),
    },
  ];
  var req = {
    id: 1,
    method: 'get',
    params: params,
  };
  return fiFmgHttp.query(req);
}

export function _checkAdomRef(resp) {
  var hasRef = false,
    i,
    data;
  if (Array.isArray(resp)) {
    for (i = 0; i < resp.length; i++) {
      data = resp[i].data;
      if (data) {
        if (
          (data.cmdb && data.cmdb.length > 0) ||
          (data.dvmdb &&
            (data.dvmdb.length > 1 ||
              (data.dvmdb.length === 1 &&
                (data.dvmdb[0].mapping_name !=
                  MACROS.USER.DVM.DVM_MAPPING_NAME ||
                  data.dvmdb[0].mkey !=
                    MACROS.USER.DVM.DEFAULT_ALL_FGT_SCOPE_NAME)))) ||
          (data.provider && data.provider.length > 0)
        ) {
          hasRef = true;
          break;
        }
      }
    }
  }
  return hasRef;
}

export function _deleteAdom(adom, force) {
  var url = 'dvmdb/adom/%s';
  var params = [];
  var p = {
    url: url.printf([adom['name']]),
  };
  if (force) {
    p.confirm = 1;
    p.option = 'force';
  }
  params.push(p);
  var req = {
    'create task': {
      adom: adom['name'],
    },
    id: 1,
    method: 'delete',
    params: params,
  };
  return fiFmgHttp.query(req).then((resp) => {
    // need to refresh redux store for workspace.
    fiWorkspace.adomStateInit();
    return resp;
  });
}

const TaskModal = (props) => {
  const { $opener, title, refreshAdomList } = props;
  return (
    <>
      <Header>{title}</Header>
      <Body>
        <TaskMonitor
          onTaskDone={() => {
            $opener.resolve();
            refreshAdomList();
          }}
          {...props}
        />
      </Body>
    </>
  );
};

export function _openTaskModal(resp, title, refreshAdomList, $opener) {
  const props = {
    title: title || '',
    taskId: resp.data ? resp.data.task : resp[0].data.task,
    taskAccessor: {},
    refreshAdomList,
    lineTableProps: {
      maxHeight: 350,
    },
  };
  return $opener.open(<TaskModal {...props} />, {
    minimizable: false,
    maximizable: false,
    size: 'md',
    height: '400px',
    closable: false,
  }).result;
}

export function openEditAdomModal(adomId, callback, $opener) {
  $opener
    .open(<FiStoreAdomEdit adomId={adomId} />, {
      size: 'lg',
    })
    .result.then(() => {
      callback();
    });
}

const FiStoreAdomEdit = ({ $opener, adomId }) => {
  const isRwUser = fiAdminProfile.hasRDWRPermitOn('adom_switch');

  return (
    <Provider store={fiStore}>
      <AdomEdit stateParams={{ adomId }} isView={!isRwUser} $opener={$opener} />
    </Provider>
  );
};

export function _isInCannotDelList(adom, cannotDeleteList) {
  return adom && cannotDeleteList.indexOf(adom['name']) >= 0;
}

export function getIcon(lockStyle) {
  if (fiSysConfig.isFaz() || !fiSysConfig.isWorkspaceEnabled()) {
    return '';
  }
  return (
    <div className='adom-content adom-icon' title={lockStyle.text}>
      <span
        className={`${lockStyle.itemClass} ${lockStyle.iconClass} ${
          lockStyle.selected ? 'selected' : ''
        }`}
      ></span>
    </div>
  );
}

export function getText(adom, upgradingInfo) {
  // fix bug 397574
  let backupText = adom.backup === 1 ? gettext('Backup') : '';
  let _expired = adom.expired ? (
    <span className='ffg ffg-warning expired-adom-icon'></span>
  ) : (
    ''
  );
  let _count = adom.device_counts ? (
    <div className='adom-devcounts with-brackets'>{adom.device_counts}</div>
  ) : (
    ''
  );
  let _backupText = backupText ? (
    <div className='adom-backup with-brackets'>{backupText}</div>
  ) : (
    ''
  );
  let _el = (
    <div
      className={'adom-content adom-text'}
      data-toggle='tooltip'
      id={adom.oid}
    >
      <div className='adom-title'>
        {_expired}
        <div className='adom-name'>{adom.displayName || adom.name}</div>
        {_count}
        {!!upgradingInfo && (
          <TaskProgressBar
            taskData={upgradingInfo}
            className='tw-ml-2 tw-w-24'
          />
        )}
      </div>
      <div className='adom-subtitle'>
        <div className='adom-type'>
          {adom.type_name === MACROS.DVM.DVM_UNMANAGED_DEVICES_NAME
            ? adom.name
            : adom.type_name}
        </div>
        {_backupText}
      </div>
    </div>
  );
  return _el;
}

const ms = MACROS.SYS;

const lockMap = {
  [ms.LOCK_STATE_LOCKED]: {
    itemClass: 'locked',
    style: 'tw-bg-success',
    icon: 'lock',
    text: gettext('This ADOM is locked by %s.'),
    btnType: 'success',
  },
  [ms.LOCK_STATE_UNLOCKED]: {
    itemClass: 'unlocked',
    style: 'tw-bg-primary',
    icon: 'unlocked',
    text: gettext('This ADOM is unlocked.'),
    btnType: 'primary',
  },
  [ms.LOCK_STATE_BY_OTHER]: {
    itemClass: 'locked-other',
    style: 'tw-bg-danger-600',
    icon: 'lock-by-user',
    text: gettext('This ADOM is locked by %s since %s.'),
    btnType: 'danger',
  },
};

export const getLockStyle = ({
  adomLock,
  lockBy,
  lockTime,
  otherLocks,
  mode,
  additionalClassname,
}) => {
  const noLock = {
    style: '',
    icon: '',
    text: '',
    itemClass: '',
    iconClass: '',
    btnType: 'primary',
  };

  if (mode && mode === 'disabled') {
    return noLock;
  }

  if (adomLock in lockMap) {
    const ret = JSON.parse(JSON.stringify(lockMap[adomLock]));
    let txt = ret.text.printf([
      lockBy,
      getLockTimeStr({ lock_time: lockTime }),
    ]);
    if (otherLocks && Array.isArray(otherLocks) && otherLocks.length > 0) {
      const byOthers = JSON.parse(
        JSON.stringify(lockMap[ms.LOCK_STATE_BY_OTHER])
      );
      otherLocks.forEach((lock) => {
        txt +=
          '\n' + byOthers.text.printf([lock.lock_user, getLockTimeStr(lock)]);
      });
    }
    ret.iconClass = `ffg ffg-${ret.icon}`;
    ret.style = `${ret.style} ${additionalClassname || ''}`;
    ret.text = txt;
    return ret;
  }

  return noLock;
};

export function deleteAdom(selectedAdom) {
  let modalTitle, msg;
  modalTitle = gettext('Delete Empty ADOM');
  msg = gettext(
    'Are you sure you want to delete the selected entry (%s)?'
  ).printf([selectedAdom.name]);

  const confirmModalProps = {
    title: modalTitle,
    content: msg,
  };
  const modalOptions = {
    height: '210px',
    size: 'md',
    minimizable: false,
    maximizable: false,
    closable: false,
    className: 'np-toolkit-modal',
  };
  // We need widget module to use task monitor
  return openConfirmModal(confirmModalProps, modalOptions)
    .then(function () {
      return _getAdomWhereUsed(selectedAdom);
    })
    .then(function (resp) {
      if (_checkAdomRef(resp)) {
        return displayAdomRef(resp).then(function () {
          return _deleteAdom(selectedAdom, true);
        });
      } else {
        return _deleteAdom(selectedAdom);
      }
    });
}
