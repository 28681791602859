import { createSelector } from '@reduxjs/toolkit';
import { some, every } from 'lodash';

import {
  getIsFmgMember,
  getIsHaSlave,
  getSysConfig,
} from 'fistore/session/sysConfig/selectors';

export function getAdminProfile(state) {
  return state?.session?.profile;
}

export const getAdminProfileData = createSelector(
  getAdminProfile,
  (prof) => prof?.data
);

const _hasRDPermit = (attr, prof) => {
  if (!attr) return false;
  const permit = prof?.[attr];
  return (
    permit === MACROS.SYS.ADMINPRIV_PERM_READ ||
    permit === MACROS.SYS.ADMINPRIV_PERM_RDWR
  );
};

export const hasRDPermitOn = (attr) =>
  createSelector(getAdminProfileData, (prof) => _hasRDPermit(attr, prof));

export const hasAllRDPermitOn = (...attrs) =>
  createSelector(getAdminProfileData, (prof) => {
    return every(attrs, (attr) => _hasRDPermit(attr, prof));
  });

export const noPermitOn = (...attrs) =>
  createSelector(getAdminProfileData, (prof) => {
    return some(attrs, (attr) => !_hasRDPermit(attr, prof));
  });

const RESTRICTED_ADMIN_TYPE_ATTR_MAP = {
  ips: 'ips_filter',
  app: 'app_filter',
  web: 'web_filter',
};

export const hasRestrictedAdminPermitOn = (attr) => {
  const prop = RESTRICTED_ADMIN_TYPE_ATTR_MAP[attr];
  return createSelector(getAdminProfileData, (prof) => {
    if (!prop) return false;
    const permit = prof?.[prop];
    return permit === 1;
  });
};

export const hasRDWRPermitOn = (
  attr,
  { haCheck = true, fmgFabricCheck = true } = {}
) =>
  createSelector(
    getAdminProfileData,
    getIsHaSlave,
    getIsFmgMember,
    (prof, isSlave, isFmgMember) => {
      /** ----------------------------------------------------------------------------
       * haCheck:
       * set to false when HA Slave can save its own configs, for example, FortiGuard -> Advanced Settings
       *
       * fmgFabricCheck:
       * set to false when FMG Fabric Member can save its own configs
       * mantis #1051492: only allow some actions for fmg fabric member, e.g.
       * dashboard > system info widget > edit hostname / system time
       * system settings > network
       * -------------------------------------------------------------------------- */
      if (!attr || (haCheck && isSlave) || (fmgFabricCheck && isFmgMember)) {
        return false;
      }

      return prof?.[attr] === MACROS.SYS.ADMINPRIV_PERM_RDWR;
    }
  );

export const hasRDWRPermitOnSysSettingConfig = (checks) => {
  return hasRDWRPermitOn('system_setting', checks);
};

export const getIsMaskedAdmin = createSelector(
  getAdminProfileData,
  (data) => !!data?.datamask?.status
);

export const getIsSuperAdmin = createSelector(
  getAdminProfileData,
  (data) => !!data?.super_user_profile
);

export const getIsRegularAdmin = createSelector(
  getAdminProfileData,
  (prof) => prof?.type === MACROS.USER.SYS.ADMIN_PROFILE_TYPE_REGULAR
);

export const getIsAdomScopedAdmin = createSelector(
  getAdminProfileData,
  (data) => !!data?.adom_admin
);

export const getAbleToShowChangePassword = createSelector(
  getIsSuperAdmin,
  getAdminProfileData,
  getSysConfig,
  getIsFmgMember,
  (isSuperUser, adminProfile, sysConfig, isFmgMember) => {
    const showChangePassword = () => {
      return (
        isSuperUser || sysConfig.change_passwd || adminProfile.change_passwd
      );
    };

    // FMG Fabric -> member can not change password
    if (isFmgMember) {
      return false;
    }

    // HA slave can not change password
    const haMode = sysConfig?.ha_mode;
    if (MACROS.SYS.HA_MODE_SLAVE === haMode) {
      return false;
    }

    const userType = sysConfig.user_type;
    if (userType === MACROS.SYS.ADMIN_USER_TYPE_LOCAL) {
      // is local
      // Super_User can change anyone's password,
      // so just let them change their own with top-right shortcut.
      // Other local users need 'change_password' enabled
      // to see the top-right change password shortcut.
      return showChangePassword();
    }

    if (userType === MACROS.SYS.ADMIN_USER_TYPE_PKI_AUTH) {
      // client side certificate
      return sysConfig.two_factor_auth && showChangePassword();
    }

    if (!sysConfig.wildcard) {
      // not local, not pki, it must be remote
      return showChangePassword();
    }

    // It must be remote and wildcar
    return false;
  }
);
