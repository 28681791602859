import { createSelector } from '@reduxjs/toolkit';
import { getSessionAdomOid } from '../../session/adom/selectors';
import { getIsFazSupervisor } from '../../session/sysConfig/selectors';
import { get_devices } from '../../devices/selectors';

const myState = (state) => state.fazSocFabric.device;

export const getSocFabricDevice = createSelector(
  myState,
  (state) => state.devices
);

export const getSocFabricOsTypes = createSelector(
  myState,
  (state) => state.osTypes
);

export const hasDeviceByOsType = (osTypes) =>
  createSelector(
    getSessionAdomOid,
    (state) => {
      if (getIsFazSupervisor(state)) {
        return getSocFabricOsTypes(state);
      }
      const devs = get_devices(state, getSessionAdomOid(state));
      return Object.values(devs?.byId || {}).reduce((res, dev) => {
        res[dev.os_type] = true;
        return res;
      }, []);
    },
    (adomOid, availableOsTypes) => {
      if (!adomOid) return false;
      const res = osTypes.some((osType) => availableOsTypes[osType]);
      return res;
    }
  );
