// Restricted admin
import { fiSession, fiStorePno } from 'fistore';
import { negate, transform } from 'lodash';
import * as React from 'react';
import { suspenseApp } from 'react_components/rc_layout/suspense_pages';

const {
  isFmg,
  getIsRestrictedAdmin,
  hasRDPermitOn,
  inGlobalAdom,
  inBackupAdom,
} = fiSession;
const { inPnoDualPane } = fiStorePno;

const raRoute = (config) =>
  transform(
    config,
    (result, value, appUniKey) => {
      result[appUniKey] = {
        path: `/rstadm/${appUniKey}`,
        ...value,
      };
    },
    {}
  );

const lazyLoadObjectTable = (tableKey) => {
  return {
    reactRouteObject: {
      lazy: async () => {
        const { getLazyObjTable } = await import(
          'react_apps/ra_pno_obj_tables'
        );
        const Table = getLazyObjTable(tableKey);
        return {
          element: suspenseApp(<Table objAppUniKey={tableKey} />),
        };
      },
    },
  };
};

const webFilter = {
  rstadm_webfilter: {
    label: gettext('Web Filter'),
    icon: 'profile',
    showCheckers: [hasRDPermitOn('web_filter')],
    olhTag: 'restrictadmin_prof',
    olhCategory: 'restrictadmin',
    olhFmg:
      'Create a user with "Restricted Admin" profile.  Re-login with that user.',
  },
  rstadm_webfilter_prof: {
    label: gettext('Profiles'),
    ...lazyLoadObjectTable('webprof'),
  },
  rstadm_webfilter_ratingoverride: {
    label: gettext('Rating Overrides'),
    ...lazyLoadObjectTable('webratingoverride'),
  },
  rstadm_webfilter_urlfilter: {
    label: gettext('URL Filter'),
    ...lazyLoadObjectTable('weburlfilter'),
  },
  rstadm_webfilter_contentfilter: {
    label: gettext('Content Filter'),
    ...lazyLoadObjectTable('webcontentfilter'),
  },
  rstadm_webfilter_localcategory: {
    label: gettext('Local Category'),
    ...lazyLoadObjectTable('localcategory'),
  },
};

const ips = {
  rstadm_ips: {
    label: gettext('Intrusion Prevention'),
    icon: 'profile',
    showCheckers: [hasRDPermitOn('ips_filter')],
    olhTag: 'restrictadmin_prof',
    olhCategory: 'restrictadmin',
    olhFmg:
      'Create a user with "Restricted Admin" profile.  Re-login with that user.',
  },
  rstadm_ips_prof: {
    label: gettext('Profiles'),
    ...lazyLoadObjectTable('ipsprof'),
  },
  rstadm_ips_templates: {
    label: gettext('IPS Templates'),
    showCheckers: [negate(inGlobalAdom)],
    reactRouteObject: {
      id: 'rstadm_ips_templates',
      lazy: async () => {
        const { IPSTemplate } = await import(
          'react_apps/ra_pt/ips_template/ips_template'
        );
        return {
          Component: IPSTemplate,
        };
      },
    },
  },
  rstadm_ips_sign: {
    label: gettext('IPS Signatures'),
    ...lazyLoadObjectTable('ipssign'),
  },
  rstadm_ips_diag: {
    label: gettext('IPS Diagnostics'),
    reactRouteObject: {
      lazy: async () => {
        const { Diagnostics } = await import(
          'react_apps/ra_restrict_admin/diagnostics/diagnostics'
        );
        return {
          Component: Diagnostics,
        };
      },
    },
  },
  rstadm_ips_fgdpkg: {
    label: gettext('FortiGuard Package'),
    reactRouteObject: {
      lazy: async () => {
        const { PMReceiveStatus } = await import(
          'react_apps/ra_fortiguard/PackageManagement/ReceiveStatus'
        );
        return {
          Component: PMReceiveStatus,
        };
      },
    },
  },
  rstadm_ips_licserv: {
    label: gettext('License and Services'),
    reactRouteObject: {
      lazy: async () => {
        const { PMServiceStatus } = await import(
          'react_apps/ra_fortiguard/PackageManagement/ServiceStatus'
        );
        return {
          Component: PMServiceStatus,
        };
      },
    },
  },
  rstadm_ips_sslprof: {
    label: gettext('SSL/SSH Inspection'),
    ...lazyLoadObjectTable('sslprof'),
  },
  rstadm_ips_profgrp: {
    label: gettext('Profile Group'),
    ...lazyLoadObjectTable('rstadm_profgrp'),
  },
};

const appfilter = {
  rstadm_appfilter: {
    label: gettext('Application Control'),
    icon: 'profile',
    showCheckers: [hasRDPermitOn('app_filter')],
    olhTag: 'restrictadmin_prof',
    olhCategory: 'restrictadmin',
    olhFmg:
      'Create a user with "Restricted Admin" profile.  Re-login with that user.',
  },
  rstadm_appfilter_prof: {
    label: gettext('Profiles'),
    ...lazyLoadObjectTable('appprof'),
  },
  rstadm_appfilter_sign: {
    label: gettext('Application Signatures'),
    ...lazyLoadObjectTable('appsignature'),
  },
};

export default raRoute({
  rstadm: {
    label: gettext('Restricted Admin Mode'),
    icon: null,
    color: null,
    showCheckers: [isFmg, getIsRestrictedAdmin],
  },
  rstadm_policy: {
    icon: 'policy-objects',
    label: gettext('Policy Packages'),
    showCheckers: [negate(inBackupAdom), negate(inPnoDualPane)],
  },
  ...webFilter,
  ...ips,
  rstadm_hdr_ftr_ips: {
    label: gettext('Header/Footer IPS'),
    icon: 'profile-ips',
    showCheckers: [hasRDPermitOn('ips_filter'), inGlobalAdom],
    ...lazyLoadObjectTable('pno_hdr_ftr_ips'),
  },
  ...appfilter,
  rstadm_taskmonitor: {
    label: gettext('Task Monitor'),
    icon: 'task-manager',
    reactRouteObject: {
      lazy: async () => {
        const { AllTasksMonitor } = await import(
          'react_apps/ra_sys/components/taskMonitor/AllTasksMonitor'
        );
        return {
          Component: AllTasksMonitor,
        };
      },
    },
    olhTag: 'sys_taskmonitor',
    olhCategory: 'sys',
    olhFmg: 'System Settings -> Task Monitor',
  },
});
