import React, { forwardRef, useRef } from 'react';
import { useField, useFormikContextSelector } from 'formik';
import { useValidateFn } from './util/hook';
import { callAll } from './util/util';
import { NwInput, NwIcon } from '@fafm/neowise-core';

export const FmkPassword = forwardRef(
  (
    {
      isEdit, // is open form for edit exsting password
      name,
      'automation-id': propAutoId,
      automationId,
      validate,
      children,
      onNwBlur = () => {},
      onPwdUpdate = () => {},
      onNwChange,
      ...rest
    },
    ref
  ) => {
    validate = useValidateFn(rest, validate);
    const submitCount = useFormikContextSelector((val) => val.submitCount);
    const [{ onBlur, onChange, value, ...restFieldProps }, { error, touched }] =
      useField({ name, validate });
    const updateRef = useRef(false);
    const updated = updateRef.current;
    const setUpdated = (val) => (updateRef.current = val);

    return (
      <NwInput
        value={isEdit && !updated ? '********' : value}
        togglePassword={!isEdit || updated}
        invalid={error && (touched || !!submitCount)}
        onInput={() => (onPwdUpdate(), setUpdated(true))}
        // onNwBlur={onBlur}
        // onNwFocus={evt => evt.target.select()} will be triggered when click the show/hide icon
        type='password'
        clearable
        onBlur={(evt) => (onBlur(evt), onNwBlur())}
        automation-id={propAutoId || automationId}
        {...restFieldProps}
        {...rest}
        ref={ref}
        onChange={callAll(onChange, onNwChange)}
        {...(isEdit &&
          updated && {
            suffix: (
              <NwIcon
                name='warning'
                className='tw-text-warning'
                label={gettext('Changed')}
              />
            ),
          })}
      >
        {children}
      </NwInput>
    );
  }
);
FmkPassword.displayName = 'FmkPassword';
