import { createSelector } from '@reduxjs/toolkit';
import { session_get_adom } from '../utils/session';

export const has_loaded = (obj) => {
  return obj && obj.loaded;
};
function get_workspace(state) {
  return state.workspaceRes;
}
export function get_workspace_save_btn(state) {
  return get_workspace(state).savebtn;
}
export function get_workspace_adoms(state) {
  return get_workspace(state).adoms;
}
export function get_workspace_adom(state, adomOid) {
  if (typeof adomOid === 'undefined') {
    adomOid = session_get_adom(state).oid;
  }
  return get_workspace_adoms(state)?.byId[adomOid];
}

export function get_workspace_pkgs(state) {
  return get_workspace(state).pkgs;
}
export function get_workspace_adom_pkgs(state, adomOid) {
  if (typeof adomOid === 'undefined') {
    adomOid = session_get_adom(state).oid;
  }
  const path = adomOid?.toString();
  return get_workspace_pkgs(state)[path];
}

export function get_workspace_adom_pkg(state, adomOid, pkgOid) {
  return get_workspace_adom_pkgs(state, adomOid)?.byId[pkgOid];
}

export function get_workspace_pkgs_children_status(state) {
  return get_workspace(state).pkgChildrenStatus;
}

export function get_workspace_adom_pkgs_children_status(state, adomOid) {
  if (typeof adomOid === 'undefined') {
    adomOid = session_get_adom(state).oid;
  }
  const path = adomOid?.toString();
  return get_workspace_pkgs_children_status(state)[path];
}
export function get_workspace_adom_pkg_children_status(state, adomOid, pkgOid) {
  return get_workspace_adom_pkgs_children_status(state, adomOid)?.byId[pkgOid];
}
/********************************************************** */
export function get_workspace_policies(state) {
  return get_workspace(state).policies;
}

export function get_workspace_adom_policies(
  state,
  adomOid,
  pkgOid,
  categoryOid
) {
  const path =
    adomOid?.toString() +
    ':' +
    pkgOid.toString() +
    ':' +
    categoryOid.toString();
  return get_workspace_policies(state)[path];
}

export function filter_workspace_adom_policies_categories(
  state,
  adomOid,
  pkgOid
) {
  // eslint-disable-next-line
  //console.log('filter pkg children policy', state, adomOid, pkgOid);
  const path = adomOid?.toString() + ':' + pkgOid.toString();
  const obj = get_workspace_policies(state);
  if (!obj) return null;
  // eslint-disable-next-line
  //console.log(obj);
  // eslint-disable-next-line
  //console.log(Object.keys(obj));

  const categories = Object.keys(obj)
    .filter((x) => x.startsWith(path))
    .map((x) => x.split(':'))
    .map((x) => x[x.length - 1]);
  // eslint-disable-next-line
  //console.log(categories);
  return categories;
}

/********************************************************** */
export function get_workspace_objs(state) {
  return get_workspace(state).objects;
}
export function get_workspace_adom_objs_by_cate_id(
  state,
  adomOid,
  categoryOid
) {
  if (typeof adomOid === 'undefined') {
    adomOid = session_get_adom(state).oid;
  }
  const path = adomOid?.toString() + ':' + categoryOid;
  return get_workspace_objs(state)[path];
}

/********************************************************** */
export function get_workspace_devs(state) {
  return get_workspace(state).devices;
}
export function get_workspace_adom_devs(state, adomOid) {
  if (typeof adomOid === 'undefined') {
    adomOid = session_get_adom(state).oid;
  }
  const path = adomOid?.toString();
  return get_workspace_devs(state)[path];
}

export function get_workspace_adom_one_dev(state, adomOid, devOid) {
  return get_workspace_adom_devs(state, adomOid)?.byId[devOid];
}

// ======= helper selector =====
export const isAdomLockedByMe = createSelector(get_workspace_adom, (data) => {
  return data && !data.isIpsType
    ? data.lock == MACROS.SYS.LOCK_STATE_LOCKED
    : false;
});
