import {
  getPackageList,
  getPolicyBlockList,
  Pkg_backend,
} from 'fistore/pno/api/pkg';
import { escapeHtml } from 'kit-escape';
import { get } from 'lodash';

export { getFullPPkgData, FOLDER_PB_OID };

const FOLDER_PB_OID: number = -2;

export interface Pkg_gui extends Pkg_backend {
  folder?: Pkg_gui;
  path: string;
  displayName: string;
  subobj?: Pkg_gui[];
  pblock?: boolean;
  pblocks?: boolean; //Uses this flag to check if this "folder" is pblocks set.
}

// Get ppkg + pb list and parse for GUI use
// Return a list and map for cache use
type PPKG_Cache = {
  list: Pkg_gui[];
  map: { [key: number]: Pkg_gui };
};
const getFullPPkgData = async (adom: AdomData): Promise<PPKG_Cache> => {
  const [ppkglistResp, pblistResp] = await Promise.all([
    getPackageList({ adom }),
    getPolicyBlockList({ adom }),
  ]);
  const ppkglist = get(ppkglistResp, '[0].data', []);
  const pblist = get(pblistResp, '[0].data', []).map((pb: Pkg_backend) => ({
    ...pb,
    // Treats Policy Block as a special case of Package.
    type: 'pkg',
    pblock: true,
  }));
  const fulllist = [
    ...ppkglist,
    {
      name: 'Policy Blocks',
      displayName: gettext('Policy Blocks'),
      pblocks: true,
      oid: FOLDER_PB_OID,
      type: 'folder',
      subobj: pblist,
    },
  ];
  const cache: PPKG_Cache = {
    list: [],
    map: {},
  };
  cache.list = _parseData(fulllist, null, function mapListNode(parsedNode) {
    cache.map[parsedNode.oid] = parsedNode;
  });
  return cache;
};

/**
 *@description parse package data, add folder attribute to each node
 *@param {object} data - array of package tree node
 *@param {object} [folder] - parent tree node object
 *@param {Function} [forEach] - callback when parsed each node
 */
function _parseData(
  data: Array<Pkg_backend | Pkg_gui>,
  folder?: Pkg_gui | null,
  forEach?: (node: Pkg_gui) => void
): Pkg_gui[] {
  const returnData = [];
  for (let ii = 0, len = data.length; ii < len; ii++) {
    const d = data[ii];
    const folderPath = get(folder, 'path');
    const node: Pkg_gui = {
      ...d,
      path: (folderPath ? `${folderPath}/` : '') + d.name.replace(/\//g, '\\/'),
      displayName: (d as Pkg_gui).displayName || escapeHtml(d.name),
      subobj: undefined,
    };
    if (folder) {
      node.folder = folder;
    }
    returnData.push(node);
    if (d.subobj && d.subobj.length > 0) {
      // Replaces the node's children with shallow copy
      node.subobj = _parseData(d.subobj, node, forEach);
    }
    if (forEach) {
      forEach(node);
    }
  }
  return returnData;
}
