export function saveToLocalFile(filename: string, content: string): void {
  const textToWrite = content.replace(/\n/g, '\r\n');
  const uri =
    'data:application/text;charset=utf-8,' + encodeURIComponent(textToWrite);
  const link = document.createElement('a');

  if (window.navigator && 'msSaveOrOpenBlob' in window.navigator) {
    // Used only for IE
    const blob = new Blob([textToWrite], { type: 'text/plain' });
    (window.navigator as any).msSaveOrOpenBlob(blob, filename);
  } else {
    link.href = uri;
    link.style.visibility = 'hidden';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
