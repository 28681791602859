import { get, isUndefined } from 'lodash';
import { fiAssetsRedux, fiDvmLogDataService, deviceStatus } from 'fi-dvm';

const MINWIDTH_LG = '250px';
const MINWIDTH_MD = '180px';
const MINWIDTH_SM = '120px';

const getDeviceKeys = (rowData) => {
  if (!rowData) return [];

  if (rowData.platform === 'vdom') {
    const connection = get(rowData, '_pData.connection');
    if (
      connection &&
      connection.ha_mode !== MACROS.DVM.DVM_HA_MODE_STANDALONE &&
      connection.ha?.length
    ) {
      return connection.ha.map((d) => ({
        name: rowData.name,
        sn: d.sn,
        platform: 'vdom',
      }));
    }
    return [{ name: rowData.name, sn: rowData.sn, platform: 'vdom' }];
  }

  const connection = rowData.connection;
  if (
    connection &&
    connection.ha_mode !== MACROS.DVM.DVM_HA_MODE_STANDALONE &&
    connection.ha?.length
  ) {
    return connection.ha.map((d) => ({ sn: d.sn }));
  }

  return [{ sn: rowData.sn }];
};

const devVersionParser = function (rec) {
  if (deviceStatus.isVdom(rec.rtype))
    return { css: '', ititle: '', txt: '', _shared_: rec };
  let _txt = '';
  let _css = '';
  let _num = '0000' + rec['_build'];
  if (rec.os_type === MACROS.DVM.DVM_OS_TYPE_LOG) {
    return {
      css: '',
      ititle: '',
      txt: gettext('Standard Syslog'),
      _shared_: rec,
    };
  } else {
    _txt += MACROS.USER.DVM.TYPE_NAME_MAP[rec.os_type] || '';
  }
  if (_txt) {
    if (rec.frmversion >= 0) {
      _txt += ' ' + rec.frmversion;
    }
    if (rec['_patch'] >= 0) {
      _txt += '.' + rec['_patch'];
    }
    if (rec['_build'] > 0 && MACROS.SYS.IMG_TYPE !== MACROS.SYS.PRODUCT_FAZ) {
      _txt += ',build' + _num.substr(_num.length - 4);
    }
    if (rec['_build_type_str']) {
      _txt += ' ' + rec['_build_type_str'];
    }
  } else {
    _txt = rec.firmware;
  }

  let _fwver = rec.frmversion;
  _fwver += rec._patch > 0 ? '.' + rec._patch : '';
  if (rec.model_dev && rec.prefer_img_ver && rec.prefer_img_ver !== _fwver) {
    _txt = gettext('Upgrade %s --> %s').printf([_fwver, rec.prefer_img_ver]);
  } else if (rec.prefer_img_ver && rec.prefer_img_ver !== _fwver) {
    _txt = gettext('Upgrading %s --> %s').printf([_fwver, rec.prefer_img_ver]);
    _css = 'ffg ffg-spinner loading color-green';
  }
  return { css: _css, ititle: '', txt: _txt, _shared_: rec };
};

const controllersSearch = (cellData, matchStr, rowDev) => {
  if (!cellData.data) return false;
  //match assets name
  for (let assetName of Object.keys(cellData.data)) {
    let txt = cellData.data[assetName].val + cellData.data[assetName].label;
    if (txt.toLowerCase().indexOf(matchStr.toLowerCase()) !== -1) return true;
  }
  //match assets content
  const assets = fiAssetsRedux.readAllAssetsPerDev(rowDev?._oData?.oid);
  if (!assets) return false;
  const vdomsIdArray = Object.keys(assets);
  for (let vid of vdomsIdArray) {
    const assetsObjOnVdom = assets[vid];
    for (let name of Object.keys(assetsObjOnVdom)) {
      if (
        cellData.expanded?.[name] &&
        assetsObjOnVdom[name].some(
          (item) =>
            item.name?.toLowerCase()?.indexOf(matchStr.toLowerCase()) >= 0
        )
      ) {
        return true;
      }
    }
  }
  return false;
};

const getLogRateSortValue = (rowData) => {
  const keys = getDeviceKeys(rowData);
  let logrates = keys.map((key) =>
    fiDvmLogDataService.getLogStatsByDevice(key)
  );
  return logrates.reduce((acc, lr) => {
    return acc + (lr ? lr.lograte : 0);
  }, 0);
};

const getLogUsageSortValue = (rowData) => {
  const keys = getDeviceKeys(rowData);
  let usages = keys.map((key) => fiDvmLogDataService.getLogStatsByDevice(key));
  return usages.reduce((acc, usg) => {
    return (
      acc +
      (usg
        ? !isUndefined(usg.totalStorageUsed)
          ? usg.totalStorageUsed
          : usg.storageUsed
        : 0)
    );
  }, 0);
};

export const DVM_colDefs = {
  FAZ_all_fortigate_colDef: [
    {
      field: 'name',
      txt: gettext('Device Name'),
      pinned: true,
      hidden: false,
      width: MINWIDTH_LG,
      parser: 'fazDevNameParser',
    },
    {
      field: 'ip',
      txt: gettext('IP Address'),
      hidden: false,
      width: MINWIDTH_SM,
    },
    {
      field: 'platform',
      txt: gettext('Platform'),
      hidden: false,
      width: MINWIDTH_MD,
    },
    {
      field: 'logs',
      txt: gettext('Logs'),
      hidden: false,
      width: MINWIDTH_MD,
      toSortValue: (rowData) => {
        return rowData['logs']['deviceColor_'] + rowData['logs']['txt'];
      },
    },
    {
      field: 'log_ave_rate',
      txt: gettext('Average Log Rate(Logs/Sec)'),
      hidden: false,
      width: MINWIDTH_LG,
      toSortValue: (rowData) => {
        let val = 0;
        const row = rowData['log_ave_rate']['_shared_'];
        val = getLogRateSortValue(row);
        return val;
      },
    },
    {
      field: 'quota',
      txt: gettext('Disk Quota Usage'),
      hidden: false,
      width: MINWIDTH_MD,
      toSortValue: (rowData) => {
        let val = 0;
        const row = rowData['quota']['_shared_'];
        val = getLogUsageSortValue(row);
        return val;
      },
    },
    //{field: 'connection', txt: gettext('Secure Connection'), hidden: true, minWidth: 85},
    {
      field: 'connection',
      txt: gettext('HA Status'),
      hidden: true,
      width: MINWIDTH_LG,
    },
    {
      field: 'desc',
      txt: gettext('Description'),
      hidden: false,
      tdClass: 'data-inline-edit',
    },
    {
      field: 'version',
      txt: gettext('Firmware Version'),
      hidden: true,
      width: MINWIDTH_LG,
      parser: devVersionParser,
    },
    { field: 'sn', txt: 'SN', hidden: true, width: MINWIDTH_MD },
  ],
  FAZ_unreg_colDef: [
    {
      field: 'name',
      txt: gettext('Device Name'),
      pinned: true,
      hidden: false,
      width: MINWIDTH_LG,
      parser: 'fazDevNameParser',
    },
    {
      field: 'platform',
      txt: gettext('Model'),
      hidden: false,
      width: MINWIDTH_MD,
    },
    {
      field: 'sn',
      txt: gettext('Serial Number'),
      hidden: false,
      width: MINWIDTH_MD,
    },
    {
      field: 'ip',
      txt: gettext('Connecting IP'),
      hidden: false,
      width: MINWIDTH_SM,
    },
  ],
  FMG_data_enc_devs_colDef: [
    {
      field: 'private_key_verify_status',
      txt: gettext('Status'),
      hidden: false,
      width: '80px',
    },
    {
      field: 'name',
      txt: gettext('Device Name'),
      pinned: true,
      hidden: false,
    },
    { field: 'ip', txt: gettext('IP Address'), hidden: false },
    { field: 'platform', txt: gettext('Platform'), hidden: false },
    {
      field: 'private_key_verify',
      txt: gettext('Private Data Encryption Key'),
      width: MINWIDTH_MD,
      hidden: false,
      parser: (d) => ({ txt: 'test', _shared_: d }),
    },
  ],
  FMG_managed_colDef: [
    {
      field: 'name',
      txt: gettext('Device Name'),
      pinned: true,
      hidden: false,
      width: MINWIDTH_LG,
    },
    {
      field: 'sync',
      txt: gettext('Config Status'),
      hidden: false,
      width: MINWIDTH_SM,
    },
    {
      field: 'pp_sync',
      txt: gettext('Policy Package Status'),
      hidden: false,
      width: MINWIDTH_LG,
    },
    // {field: 'cli_prof', txt: gettext('CLI Template Status'), hidden: true, width: MINWIDTH_LG},
    {
      field: 'prof_sync',
      txt: gettext('Provisioning Templates'),
      hidden: false,
      width: MINWIDTH_LG,
    },
    {
      field: 'fwmprof_sync',
      txt: gettext('Firmware Template'),
      hidden: false,
      width: MINWIDTH_LG,
    },
    {
      field: 'firmware_upgrade',
      txt: gettext('Upgrade Status'),
      hidden: false,
      width: MINWIDTH_LG,
    },
    //{field: 'sdwan_prof', txt: gettext('SD-WAN Status'), hidden: false, width: MINWIDTH_LG},
    //{field: 'wifi_prof', txt: gettext('WiFi Status'), hidden: false, width: MINWIDTH_LG},
    //{field: 'fsw_prof', txt: gettext('FortiSwitch Status'), hidden: false, width: MINWIDTH_LG},
    // {field: 'dev_prof', txt: gettext('System Template'), hidden: true, width: MINWIDTH_LG},
    // {field: 'ipsec_prof', txt: gettext('IPSec Template'), hidden: true, width: MINWIDTH_LG},
    {
      field: 'version',
      txt: gettext('Firmware Version'),
      hidden: false,
      width: MINWIDTH_LG,
      parser: devVersionParser,
    },
    {
      field: 'hostname',
      txt: gettext('Host Name'),
      hidden: false,
      width: MINWIDTH_MD,
    },
    {
      field: 'ip',
      txt: gettext('IP Address'),
      hidden: false,
      width: MINWIDTH_SM,
    },
    {
      field: 'platform',
      txt: gettext('Platform'),
      hidden: false,
      width: MINWIDTH_MD,
    },
    {
      field: 'desc',
      txt: gettext('Description'),
      hidden: false,
      tdClass: 'data-inline-edit',
    },
    {
      field: 'connection',
      txt: gettext('HA Status'),
      hidden: true,
      width: MINWIDTH_LG,
    },
    {
      field: 'license',
      txt: gettext('FortiGuard License'),
      hidden: true,
      width: MINWIDTH_MD,
    },
    {
      field: 'manage_mode',
      txt: gettext('Management Mode'),
      hidden: true,
      width: MINWIDTH_LG,
    },
    { field: 'sn', txt: 'SN', hidden: true, width: MINWIDTH_MD },
    {
      field: 'controller_counter',
      txt: gettext('Controllers'),
      hidden: true,
      width: MINWIDTH_MD,
      tdClass: 'dvm_controller_counter',
      fieldSearch: (...args) => controllersSearch(...args),
    },
  ],
  FMG_BKP_managed_colDef: [
    {
      field: 'name',
      txt: gettext('Device Name'),
      pinned: true,
      hidden: false,
      width: MINWIDTH_LG,
    },
    {
      field: 'version',
      txt: gettext('Firmware Version'),
      hidden: false,
      width: MINWIDTH_LG,
      parser: devVersionParser,
    },
    {
      field: 'hostname',
      txt: gettext('Host Name'),
      hidden: false,
      width: MINWIDTH_LG,
    },
    {
      field: 'ip',
      txt: gettext('IP Address'),
      hidden: false,
      width: MINWIDTH_SM,
    },
    {
      field: 'platform',
      txt: gettext('Platform'),
      hidden: false,
      width: MINWIDTH_MD,
    },
    {
      field: 'desc',
      txt: gettext('Description'),
      hidden: false,
      tdClass: 'data-inline-edit',
    },
    {
      field: 'connection',
      txt: gettext('HA Status'),
      hidden: true,
      width: MINWIDTH_LG,
    },
    {
      field: 'license',
      txt: gettext('FortiGuard License'),
      hidden: true,
      width: MINWIDTH_MD,
    },
    {
      field: 'manage_mode',
      txt: gettext('Management Mode'),
      hidden: true,
      width: MINWIDTH_LG,
    },
    { field: 'sn', txt: 'SN', hidden: true, width: MINWIDTH_MD },
    {
      field: 'controller_counter',
      txt: gettext('Controllers'),
      hidden: true,
      width: MINWIDTH_MD,
      tdClass: 'dvm_controller_counter',
      fieldSearch: (...args) => controllersSearch(...args),
    },
  ],
  FMG_managed_faz_colDef: [
    {
      field: 'name',
      txt: gettext('Device Name'),
      pinned: true,
      hidden: false,
      width: MINWIDTH_LG,
    },
    {
      field: 'ip',
      txt: gettext('IP Address'),
      hidden: false,
      width: MINWIDTH_SM,
    },
    {
      field: 'platform',
      txt: gettext('Platform'),
      hidden: false,
      width: MINWIDTH_MD,
    },
    {
      field: 'desc',
      txt: gettext('Description'),
      hidden: false,
      tdClass: 'data-inline-edit',
    },
    {
      field: 'connection',
      txt: gettext('HA Status'),
      hidden: true,
      width: MINWIDTH_LG,
    },
    {
      field: 'version',
      txt: gettext('Firmware Version'),
      hidden: true,
      width: MINWIDTH_LG,
      parser: devVersionParser,
    },
    { field: 'sn', txt: 'SN', hidden: true, width: MINWIDTH_MD },
  ],
  FMG_unreg_colDef: [
    {
      field: 'name',
      txt: gettext('Device Name'),
      pinned: true,
      hidden: false,
      width: MINWIDTH_LG,
    },
    {
      field: 'platform',
      txt: gettext('Model'),
      hidden: false,
      width: MINWIDTH_LG,
    },
    {
      field: 'source',
      txt: gettext('Management Mode'),
      hidden: false,
      width: MINWIDTH_LG,
    },
    {
      field: 'sn',
      txt: gettext('Serial Number'),
      hidden: false,
      width: MINWIDTH_MD,
    },
    {
      field: 'ip',
      txt: gettext('Connecting IP'),
      hidden: false,
      width: MINWIDTH_SM,
    },
    {
      field: 'version',
      txt: gettext('Firmware Version'),
      hidden: false,
      width: MINWIDTH_LG,
      parser: devVersionParser,
    },
  ],
};
