import { useCallback, useEffect, useState } from 'react';
import { fiMessageBox } from 'fi-messagebox';
import { useSelector } from 'react-redux';
import { fiStoreMainFrame, fiSession } from 'fistore';
import { fiFmgHttp } from 'fi-http';
import { saveBlob } from 'fiutil';
import { ProToolkit } from '@fafm/neowise-pro';
import { NwSpinner } from '@fafm/neowise-core';
const { useNotification } = ProToolkit;

export const useHelpItems = () => {
  const platform = fiSession.isFmg() ? 'fortimanager' : 'fortianalyzer';
  const version = `${MACROS.SYS.CONFIG_MAJOR_NUM}.${MACROS.SYS.CONFIG_MINOR_NUM}`;

  const urls = {
    video: `https://video.fortinet.com/products/${platform}/${version}`,
    release: `https://docs.fortinet.com/document/${platform}/${version}.0/release-notes`,
    answer: 'https://answers.fortinet.com',
  };
  const helpUrl = useSelector(fiStoreMainFrame.getHelpUrl);

  const enableTacReport = useSelector(fiSession.getIsSuperAdmin);
  const [loadingReport, setLoadingReport] = useState(false);

  const showHelpUrlError = useCallback(() => {
    fiMessageBox.show(
      gettext(
        'The device is unable to locate online help resources directly.'
      ) +
        '<br/>\n' +
        '<br/>\n' +
        gettext(
          'An error occurred While trying to retrieve the online help URL'
        ),
      'danger',
      null,
      true // WE must do the escape here
    );
  }, [helpUrl.error]);

  const [notice] = useNotification({
    closable: true,
    duration: null,
    maxCount: 1,
  });

  useEffect(() => {
    if (loadingReport) {
      notice.open({
        content: (
          <div>
            <NwSpinner
              style={{ '--indicator-color': 'rgb(var(--nw-color-neutral-0))' }}
              className='tw-pr-2'
            />
            {gettext('Collecting report data...')}
          </div>
        ),
        type: 'neutral',
      });
    } else {
      notice.destroy();
    }
  }, [notice, loadingReport]);

  const downloadTacReport = () => {
    setLoadingReport(true);
    return fiFmgHttp
      .forward({
        method: 'exec',
        params: [
          {
            url: '/sys/tac/report',
          },
        ],
      })
      .then((resp) => {
        if (resp?.[0]?.status?.code !== 0) throw resp?.[0]?.status;

        const blob = new Blob([resp?.[0]?.data?.tac ?? ''], {
          type: 'text/plain',
        });
        saveBlob(blob, 'forticare-debug-report.txt');
        fiMessageBox.show(
          gettext('Downloaded FortiCare Debug Report successfully.'),
          'success'
        );
      })
      .catch((err) => {
        fiMessageBox.show(
          err?.message ?? gettext('Failed to download FortiCare Debug Report.'),
          'danger'
        );
      })
      .finally(() => {
        setLoadingReport(false);
      });
  };

  const getHelpItems = useCallback(() => {
    const helpItems = [];

    if (helpUrl)
      helpItems.push({
        key: 'help',
        iconLibrary: 'fa-solid',
        icon: 'book',
        label: gettext('Relevant Documentation'),
      });

    if (!helpUrl)
      helpItems.push({
        key: 'help-error',
        icon: 'warning',
        label: gettext('Relevant Documentation Unavailable'),
      });

    helpItems.push(
      ...[
        {
          key: 'video',
          iconLibrary: 'fa-solid',
          icon: 'video',
          label: gettext('Video Tutorials'),
        },
        {
          isDivider: true,
        },
        {
          key: 'release',
          iconLibrary: 'fa-solid',
          icon: 'file-alt',
          label: gettext('Release Notes'),
        },
        {
          isDivider: true,
        },
        {
          key: 'answer',
          iconLibrary: 'fa-solid',
          icon: 'comments',
          label: gettext('FortiAnswers'),
        },
      ]
    );

    if (enableTacReport) {
      helpItems.push({
        key: 'report',
        iconLibrary: 'fa-solid',
        icon: 'download',
        label: gettext('FortiCare Debug Report'),
        disabled: loadingReport,
      });
    }

    return helpItems;
  }, [helpUrl, enableTacReport, loadingReport]);

  const onSelectHelpItems = useCallback(
    (item) => {
      const fn = {
        help: () => window.open(helpUrl, '_blank', 'noopener noreferrer'),
        'help-error': showHelpUrlError,
        video: () => window.open(urls.video, '_blank', 'noopener noreferrer'),
        release: () =>
          window.open(urls.release, '_blank', 'noopener noreferrer'),
        answer: () => window.open(urls.answer, '_blank', 'noopener noreferrer'),
        report: downloadTacReport,
      }[item.key];

      if (fn) fn();
    },
    [helpUrl, urls]
  );

  return { getHelpItems, onSelectHelpItems };
};
