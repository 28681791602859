import { openConfirmModal } from 'rc_layout';

export {
  createAction,
  editAction,
  cloneAction,
  deleteAction,
  whereUsedAction,
  importAction,
  exportAction,
  moreDropDown,
};

function createAction({ canWrite, ...rest }) {
  return Object.assign(
    {
      key: 'add',
      label: gettext('Create New'),
      icon: {
        name: 'add',
      },
      disabled: !canWrite,
    },
    rest
  );
}

function editAction({ canWrite, numRows, ...rest }) {
  return Object.assign(
    {
      key: 'edit',
      label: canWrite ? gettext('Edit') : gettext('View'),
      icon: canWrite
        ? {
            name: 'edit',
          }
        : {
            name: 'view',
          },
      disabled: numRows !== 1,
    },
    rest
  );
}

function cloneAction({ canWrite, numRows, ...rest }) {
  return Object.assign(
    {
      key: 'clone',
      label: gettext('Clone'),
      icon: {
        name: 'clone',
      },
      disabled: numRows !== 1 || !canWrite,
    },
    rest
  );
}

function deleteAction({ canWrite, numRows, exec, ...rest }) {
  return Object.assign(
    {
      key: 'delete',
      label: gettext('Delete'),
      icon: {
        name: 'delete',
      },
      disabled: !numRows || !canWrite,
      exec: () => {
        confirmDeleteObjects(numRows)
          .then(exec)
          .catch(() => {});
      },
    },
    rest
  );
}

function whereUsedAction({ numRows, ...rest }) {
  return Object.assign(
    {
      key: 'where_used',
      label: gettext('Where Used'),
      icon: {
        name: 'search',
        library: 'fa-solid',
      },
      disabled: numRows !== 1,
    },
    rest
  );
}

function importAction({ canWrite, ...rest }) {
  return Object.assign(
    {
      key: 'import',
      label: gettext('Import'),
      icon: {
        name: 'import',
      },
      disabled: !canWrite,
    },
    rest
  );
}

function exportAction({ numRows, ...rest }) {
  return Object.assign(
    {
      key: 'export',
      label: gettext('Export'),
      icon: {
        name: 'export',
      },
      disabled: !numRows,
    },
    rest
  );
}

function moreDropDown(children) {
  return {
    key: 'more',
    label: gettext('More'),
    icon: {
      library: 'fa-solid',
      name: 'ellipsis-v',
    },
    items: children,
  };
}

function confirmDeleteObjects(num) {
  return openConfirmModal({
    title: gettext('Confirm Deletion'),
    content: ngettext(
      'Are you sure you want to delete the selected object?',
      'Are you sure you want to delete the selected objects?',
      num
    ),
  });
}
