import {
  Commands,
  ContextType,
  HintsObj,
  SyntaxAttribute,
  SyntaxAttributeTypes,
  SyntaxCategory,
  SyntaxContext,
} from '../types';
import { isUndefined } from 'lodash';

const commandsByContext = {
  noContext: [Commands.config, Commands.get, Commands.show, Commands.exit],

  [ContextType.edit]: [
    // Commands.config, //only if there are subobjs
    Commands.set,
    Commands.unset,
    Commands.select,
    Commands.unselect,
    Commands.append,
    Commands.clear,
    Commands.get,
    Commands.show,
    Commands.next,
    Commands.abort,
    Commands.end,
  ],

  [ContextType.obj]: [
    // Commands.config // if subobjs exist
    Commands.set,
    Commands.unset,
    Commands.get,
    Commands.show,
    Commands.abort,
    Commands.end,
  ],

  [ContextType.table]: [
    Commands.edit,
    Commands.delete,
    Commands.purge,
    Commands.get,
    Commands.show,
    Commands.end,
  ],
};

export class ScriptSuggestionProvider {
  syntaxStructure: Record<string, SyntaxCategory>;
  contextStack: SyntaxContext[];
  hints: HintsObj[];
  constructor(syntaxStructure: Record<string, SyntaxCategory>) {
    this.syntaxStructure = syntaxStructure;
    this.contextStack = [];
    this.hints = [];
  }

  parseHints(hints?: HintsObj[]) {
    //turn hints array into map so it is easy to check hint attr later
    const map: Record<string, HintsObj> = {};
    for (const hint of hints ?? this.hints) {
      map[hint.hint] = hint;
    }
    return map;
  }

  //fully parse script like validation, but only up to current cursor line
  parse(script: string, cursorLine: number, cursorCh: number) {
    this.hints = [];
    this.contextStack = [];
    const lines = script.split('\n').map((line, idx) => {
      if (idx !== cursorLine) return line.trim();
      const _line = line.substring(0, cursorCh);
      return _line;
    });

    if (!lines.length) {
      //completely empty script
      this.getEmptyLineSuggestions();
      return this.parseHints();
    }

    for (let index = 0; index < lines.length; index++) {
      this.hints = [];
      //this assumes only one command per line, the full line contains the command arguments.
      try {
        if (!lines[index].trim()) {
          if (index !== cursorLine) continue;
          //empty line, get suggestions based on current context
          //only on the last line
          this.getEmptyLineSuggestions();
          return this.parseHints();
        }

        const line = lines[index].trim();
        const originalLine = lines[index];

        if (cursorLine === index) {
          if (
            originalLine.includes('#') &&
            originalLine.indexOf('#') < cursorCh
          ) {
            //no suggestions after comment
            return this.parseHints([]);
          }
          const noCommentLine = originalLine.split('#')[0];
          //matches end of line that has whitespace char
          const lineEndsWithSpace = noCommentLine.search(/\s+$/) > 0;
          const trimmedLine = noCommentLine.trim();
          const matches = trimmedLine.match(/(?:[^\s"]+|"[^"]*")+/g);
          if (!matches) throw new Error('Error parsing script');
          const command = matches[0];
          const args = matches.slice(1);
          const isTypingCommand =
            lines[index].substring(0, cursorCh).trimStart() === command;

          this.handleCommand(
            command as Commands,
            args,
            index + 1,
            isTypingCommand,
            lineEndsWithSpace
          );
          return this.parseHints(); //only show hints for current line
        } else {
          const matches = line.match(/(?:[^\s"]+|"[^"]*")+/g);
          if (!matches) throw new Error('Error parsing script');
          const command = matches[0];
          const args = matches.slice(1);
          this.handleCommand(
            command as Commands,
            args,
            index + 1,
            false,
            false
          );
        }
      } catch (e: unknown) {
        if (cursorLine === index) return this.parseHints();
      }
    }

    return this.parseHints();
  }

  makeHints(hints: string[], attrMap?: Record<string, SyntaxAttribute>) {
    if (attrMap) {
      return hints.map((hint) => {
        return {
          hint,
          required: !!attrMap[hint].must,
        };
      });
    } else {
      return hints.map((hint) => {
        return {
          hint,
          required: false,
        };
      });
    }
  }

  getEmptyLineSuggestions() {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      this.hints.push(...this.makeHints(commandsByContext.noContext));
      return;
    }

    const hints = [...commandsByContext[currentContext.type]];
    if (
      currentContext.object.subobj &&
      Object.keys(currentContext.object.subobj).length &&
      currentContext.type !== ContextType.table
    ) {
      hints.unshift(Commands.config);
    }

    this.hints.push(...this.makeHints(hints));
  }

  getCurrentContextAttrKeys() {
    const currentContext = this.getCurrentContext();
    if (!currentContext) return [];

    return Object.keys(currentContext.object.attr || {});
  }

  getCurrentContextSubobjKeys() {
    const currentContext = this.getCurrentContext();
    if (!currentContext) return [];

    return Object.keys(currentContext.object.subobj || {});
  }

  getCurrentContextAttrMap(): Record<string, SyntaxAttribute> {
    const currentContext = this.getCurrentContext();
    if (!currentContext) return {} as Record<string, SyntaxAttribute>;

    return (currentContext.object.attr || {}) as Record<
      string,
      SyntaxAttribute
    >;
  }

  handleCommand(
    command: Commands,
    args: string[],
    lineNumber: number,
    isTypingCommand: boolean,
    lineEndsWithSpace: boolean
  ) {
    // Comprehensive switch-case to handle various command types

    if (isTypingCommand) return this.getEmptyLineSuggestions();

    switch (command) {
      case Commands.config:
        // Enter a new 'config' context
        this.enterConfigContext(args, lineNumber);
        break;
      case Commands.edit:
        // Enter an 'edit' context within a 'config' context
        this.enterEditContext(args, lineNumber);
        break;
      case Commands.set:
        // Add or modify a setting within an 'obj' type context
        this.handleSet(args, lineEndsWithSpace);
        break;
      case Commands.unset:
        // Remove a setting within an 'obj' type context
        this.handleUnset(args, lineEndsWithSpace);
        break;
      case Commands.get:
        // Fetch and display the value of a setting, primarily for verification
        this.handleGet(args);
        break;
      case Commands.show:
        // Display configuration settings within 'config' or 'edit' contexts
        // this.handleShow(lineNumber);
        break;
      case Commands.delete:
        // Remove an entry or object, must be within a 'config' context of type 'table'
        this.handleDelete(args);
        break;
      case Commands.purge:
        // Clear all entries or objects within a context, with specific constraints
        this.handlePurge();
        break;
      case Commands.abort:
        // Exit a configuration session prematurely, without saving changes
        this.exitContext(Commands.abort);
        break;
      case Commands.end:
        this.exitContext(Commands.end);
        break;
      case Commands.next:
        // Proceed to the next entry in 'edit' context or exit the 'edit' context
        this.exitContext(Commands.next);
        break;
      case Commands.select:
        this.handleSelectUnselect(Commands.select, args, lineEndsWithSpace);
        break;
      case Commands.unselect:
        this.handleSelectUnselect(Commands.unselect, args, lineEndsWithSpace);
        break;
      case Commands.append:
        this.handleAppend(args, lineEndsWithSpace);
        break;
      case Commands.clear:
        this.handleClear(args);
        break;
      //realtime commands, no need to handle
      case Commands.exit:
      case Commands.diagnose:
      case Commands.execute:
      case Commands.alias:
      case Commands.sudo:
      case Commands.clone:
      case Commands.move:
      case Commands.rename:
        break;
      default:
        this.getEmptyLineSuggestions();
        if (!Commands[command as Commands]) throw new Error();
    }
  }

  enterEditContext(args: string[], lineNumber: number) {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      throw new Error();
    }
    if (currentContext.object.type !== ContextType.table) {
      throw new Error();
    }

    if (args.length !== 1) {
      throw new Error();
    }

    const entryIdentifier = args[0].replace(/"/g, '');

    this.contextStack.push({
      type: ContextType.edit,
      name: entryIdentifier, // Name of the entry being edited
      object: {
        ...(currentContext.object as SyntaxCategory),
        type: ContextType.edit,
      }, // Clone current object state but mark as edit
      lineNumber, // Storing lineNumber might help with error messages or debugging
    });
  }

  handleSet(args: string[], lineEndsWithSpace: boolean) {
    this.handleSetUnset(Commands.set, args, lineEndsWithSpace);
  }

  handleUnset(args: string[], lineEndsWithSpace: boolean) {
    this.handleSetUnset(Commands.unset, args, lineEndsWithSpace);
  }

  handleGet(args: string[]) {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      throw new Error();
    }
    if (args.length !== 1) {
      const attrKeys = this.getCurrentContextAttrKeys();
      this.hints.push(
        ...this.makeHints(attrKeys, this.getCurrentContextAttrMap())
      );
      throw new Error();
    }
  }

  handleDelete(args: string[]) {
    const currentContext = this.getCurrentContext();
    if (!currentContext || currentContext.object.type !== ContextType.table) {
      throw new Error();
    }
    if (args.length !== 1) {
      throw new Error();
    }
  }

  handlePurge() {
    const currentContext = this.getCurrentContext();
    if (!currentContext || !(currentContext.type === ContextType.table)) {
      throw new Error();
    }
  }

  enterConfigContext(args: string[], lineNumber: number) {
    const contextName = args.join(' ').replace(/"/g, ''); // Remove quotes from the argument

    // Attempt to fetch the current context to determine if we're within an object or subobject
    const currentContext = this.getCurrentContext();

    let newContextObject;
    let overrideType: ContextType | undefined;

    const handlePossibleStaticObject = (
      objectKeys: string[],
      structure: undefined | Record<string, SyntaxCategory>
    ) => {
      const possibleSubObject = objectKeys.find((objKey) =>
        contextName.startsWith(objKey)
      );

      if (possibleSubObject) {
        const contextObject = structure?.[possibleSubObject];
        if (
          contextObject?.static ||
          ['main-class'].includes(possibleSubObject)
        ) {
          //need to hard code some categories that don't have static flag
          newContextObject = contextObject;
          overrideType = ContextType.obj;
          return true;
        }
      }

      return false;
    };

    // If there's a current context, it might be an attempt to access a subobject
    if (currentContext) {
      const subObjectKeys = this.getCurrentContextSubobjKeys();
      if (subObjectKeys.length) {
        this.hints.push(...this.makeHints(subObjectKeys));
      }

      if (
        !handlePossibleStaticObject(
          subObjectKeys,
          (currentContext.object as SyntaxCategory).subobj
        )
      ) {
        //there is a subobj, need to check if the parent obj is a table or obj. If table, need to be in an edit context
        if (currentContext.type === ContextType.table) {
          throw new Error();
        }

        newContextObject = (currentContext.object as SyntaxCategory).subobj?.[
          contextName
        ];
      }
    } else {
      // If there's no current context, then we're attempting to access a top-level object
      const configObjKeys = Object.keys(this.syntaxStructure);
      if (!handlePossibleStaticObject(configObjKeys, this.syntaxStructure)) {
        this.hints.push(...this.makeHints(configObjKeys));

        newContextObject = this.syntaxStructure[contextName];
      }
    }

    // Validate the type of the new context to ensure it's either "obj" or "table"
    if (
      newContextObject?.type !== ContextType.obj &&
      newContextObject?.type !== ContextType.table
    ) {
      throw new Error();
    }

    // Push the new context onto the stack
    this.contextStack.push({
      type: overrideType ? overrideType : newContextObject.type,
      name: contextName,
      object: newContextObject,
      lineNumber,
    });
  }

  getCurrentContext(): SyntaxContext | null {
    return this.contextStack.length > 0
      ? this.contextStack[this.contextStack.length - 1]
      : null;
  }

  // End, Next, and Abort command handling
  exitContext(command: Commands) {
    if (this.contextStack.length === 0) {
      throw new Error();
    }
    // 'next' specifically exits edit contexts
    const contextType =
      command === Commands.next ? ContextType.edit : undefined;
    if (contextType && this.getCurrentContext()?.type !== contextType) {
      throw new Error();
    }

    //end and abort command pops to the outermost config context
    if (command === Commands.end || command === Commands.abort) {
      while (
        this.getCurrentContext()?.type !== ContextType.table &&
        this.getCurrentContext()?.type !== ContextType.obj
      ) {
        this.contextStack.pop();
      }
    }

    this.contextStack.pop(); // Exiting the current context
  }

  handleSelectUnselect(
    command: Commands,
    args: string[],
    lineEndsWithSpace: boolean
  ) {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      throw new Error();
    }
    if ([ContextType.edit, ContextType.obj].includes(currentContext.type)) {
      return this.handleSetUnset(command, args, lineEndsWithSpace);
    } else {
      this.hints = [];
    }
  }

  handleAppend(args: string[], lineEndsWithSpace: boolean) {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      throw new Error();
    }
    if ([ContextType.edit, ContextType.obj].includes(currentContext.type))
      return this.handleSetUnset(Commands.append, args, lineEndsWithSpace);
  }

  handleClear(args: string[]) {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      throw new Error();
    }

    const { attribute } = this.getCommandAndArgs(args, currentContext);

    if (!attribute) {
      this.pushOnlyAllowMultipleAttrHints();
    }
  }

  handleClearAppendCommon(command: Commands, args: string[]) {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      throw new Error();
    }

    const { attribute } = this.getCommandAndArgs(args, currentContext);

    if (!attribute || !attribute.opts) {
      this.pushOnlyAllowMultipleAttrHints();
    }
  }

  //filter only those attr that allow multiple option settings
  //for some commands like clear, append
  pushOnlyAllowMultipleAttrHints() {
    const currentContext = this.getCurrentContext();
    if (!currentContext) return;
    const attrKeys = this.getCurrentContextAttrKeys();
    //only the attributes with opts
    const hintAttrs = attrKeys.filter((attrKey) => {
      const attribute = (currentContext.object as SyntaxCategory).attr?.[
        attrKey
      ];
      if (!attribute) return false;
      const allowMultiple =
        attribute.opts &&
        (!attribute.excluded ||
          attribute.type === SyntaxAttributeTypes.opt_array ||
          (!isUndefined(attribute.max_argv) &&
            (attribute.max_argv === -1 || attribute.max_argv > 1))); //excluded = true means "exclusive"
      return allowMultiple;
    });
    this.hints.push(
      ...this.makeHints(hintAttrs, this.getCurrentContextAttrMap())
    );
  }

  getCommandAndArgs(args: string[], currentContext: SyntaxContext) {
    const settingName = args[0]?.replace(/"/g, ''); // Assuming first arg is the setting name
    const origSettingArgs = args.slice(1);
    const settingValue = args.slice(1).map((arg) => arg.replace(/"/g, '')); // For 'set', remaining args are values
    const attribute = (currentContext.object as SyntaxCategory).attr?.[
      settingName
    ];

    return {
      settingName,
      origSettingArgs,
      settingValue,
      attribute,
    };
  }

  // Utility method for `set` and `unset` command validation and handling
  handleSetUnset(
    command: Commands,
    args: string[],
    lineEndsWithSpace: boolean
  ) {
    const currentContext = this.getCurrentContext();
    if (
      !currentContext ||
      (![ContextType.edit, ContextType.obj].includes(currentContext.type) &&
        ![
          Commands.select,
          Commands.unselect,
          Commands.append,
          Commands.clear,
        ].includes(command))
    ) {
      throw new Error();
    }

    const { settingValue, attribute } = this.getCommandAndArgs(
      args,
      currentContext
    );

    //show hints for attr while no
    if (!attribute || (!lineEndsWithSpace && !settingValue.length)) {
      if (
        [
          Commands.select,
          Commands.unselect,
          Commands.append,
          Commands.clear,
        ].includes(command)
      ) {
        this.pushOnlyAllowMultipleAttrHints();
      } else {
        const attrKeys = this.getCurrentContextAttrKeys();
        this.hints.push(
          ...this.makeHints(attrKeys, this.getCurrentContextAttrMap())
        );
      }

      throw new Error();
    }

    const allowMultiple =
      !attribute.excluded || attribute.type === SyntaxAttributeTypes.opt_array; //excluded = true means "exclusive"
    const optKeys = Object.keys(attribute.opts || {});
    if (command === Commands.unset && args.length > 1) {
      throw new Error();
    } else if (allowMultiple) {
      const filteredOpts = optKeys.filter((opt) => {
        return !args.includes(opt);
      });
      this.hints.push(...this.makeHints(filteredOpts));
    } else if (!settingValue.length) {
      this.hints.push(...this.makeHints(optKeys));
      throw new Error();
    }
  }
}
