import { fiSession, fiStoreAdomLocalConfig } from 'fistore';
import { negate } from 'lodash';
import { batchAddCheckers } from '../util';
const {
  inManageableAdoms,
  inGlobalAdom,
  hasRDPermitOn,
  isFmg,
  inOthersAdom,
  inFpxAdom,
  inBackupAdom,
  isCentralManagement,
} = fiSession;
const { hasSelectedDeviceChecker } = fiStoreAdomLocalConfig;
const mrPRIV = MACROS.USER.DVM;

const fap_map_apps = {
  fap_map: {
    label: gettext('WiFi Maps'),
    path: '/fortiap/map',
  },
  fap_map_gmap: {
    label: gettext('Google Map'),
    // icon: 'globe',
    path: '/fortiap/map/gmap',
  },
  fap_map_floor: {
    label: gettext('Floor Map'),
    // icon: 'building',
    iconLibrary: 'fa-outlined',
    path: '/fortiap/map/floor',
  },
};

const _fap_template_apps = {
  fap_template_ssid: {
    label: gettext('SSIDs'),
    // icon: 'wifi',
    path: '/fortiap/wifitemplates/ssids',
    olhTag: 'fap_ssid',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> SSIDs',
  },

  fap_template_opprofs: {
    label: gettext('Operation Profiles'),
    // icon: 'wifi-bridging',
    path: '/fortiap/wifitemplates',
  },
  fap_template_opprofs_approf: {
    //icon: 'fortiap-alt',
    label: gettext('FortiAP Profiles'),
    path: '/fortiap/wifitemplates/profiles',
    olhTag: 'fap_ssid',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Operation Profiles -> SSIDs',
  },
  fap_template_opprofs_qosprof: {
    //icon: 'service',
    label: gettext('QoS Profiles'),
    path: '/fortiap/wifitemplates/qosprofiles',
    olhTag: 'fap_qos',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Operation Profiles -> QoS Profiles',
  },
  fap_template_opprofs_cfgprof: {
    //icon: 'config-status',
    label: gettext('FortiAP Configuration Profiles'),
    path: '/fortiap/wifitemplates/apcfgprofiles',
    olhTag: 'fap_cfg_prof',
    olhCategory: 'ap',
    olhFmg:
      'AP Manager -> Operation Profiles -> FortiAP Configuration Profiles',
  },
  fap_template_opprofs_arrpprof: {
    //icon: 'wave-square',
    //iconLibrary: 'fa-solid',
    label: gettext('ARRP Profiles'),
    path: '/fortiap/wifitemplates/arrpprofiles',
    olhTag: 'fap_arrp',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Operation Profiles -> ARRP Profiles',
  },

  fap_template_conprofs: {
    label: gettext('Connectivity Profiles'),
    // icon: 'connected',
    path: '/fortiap/wifitemplates',
  },
  fap_template_conprofs_mpskprof: {
    //icon: 'key',
    //iconLibrary: 'fa-solid',
    label: gettext('MPSK Profiles'),
    path: '/fortiap/wifitemplates/mpskprofiles',
    olhTag: 'fap_mpsk',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Connectivity Profiles -> MPSK Profiles',
  },
  fap_template_conprofs_bjrprof: {
    //icon: 'wifi-mesh',
    label: gettext('Bonjour Profiles'),
    path: '/fortiap/wifitemplates/bjrprofiles',
    olhTag: 'fap_bonjour',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Connectivity Profiles -> Bonjour Profiles',
  },
  fap_template_conprofs_btprof: {
    //icon: 'bluetooth',
    //iconLibrary: 'fa-brands',
    label: gettext('Bluetooth Profiles'),
    path: '/fortiap/wifitemplates/bleprofiles',
    olhTag: 'fap_bluetooth',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Connectivity Profiles -> Bluetooth Profiles',
  },

  fap_template_protprof: {
    label: gettext('Protection Profiles'),
    // icon: 'fortiwifi',
    path: '/fortiap/wifitemplates',
  },
  fap_template_protprof_widsprof: {
    //icon: 'search',
    label: gettext('WIDS Profiles'),
    path: '/fortiap/wifitemplates/widsprofiles',
    olhTag: 'fap_wids',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Protection Profiles -> WIDS Profiles',
  },
  fap_template_protprof_l3prof: {
    //icon: 'import',
    label: gettext('L3 Firewall Profiles'),
    path: '/fortiap/wifitemplates/accesscontrol',
    olhTag: 'fap_access_control',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Protection Profiles -> L3 Firewall Profiles',
  },
  fap_template_wifisetting: {
    label: gettext('WiFi Settings'),
    // icon: 'settings',
    path: '/fortiap/wifitemplates/setting',
    olhTag: 'fap_setting',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> WiFi Settings',
  },
};

const fap_template_apps = batchAddCheckers(_fap_template_apps, [
  isCentralManagement('fap'),
]);

const fap_device_template_apps = {
  // used in per-device management content menu
  fap_device_pdm: {
    label: gettext('Managed FortiAPs'),
    icon: 'fortiap-alt',
    path: '/fortiap/devices/list',
    showCheckers: [negate(isCentralManagement('fap'))],
    olhTag: 'fap',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Managed APs',
  },
  fap_device_template_ssid: {
    label: gettext('SSIDs'),
    icon: 'wifi',
    path: '/fortiap/devices/wifitemplates/ssids',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_ssid',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> SSIDs',
  },
  fap_device_template_opprofs: {
    label: gettext('Operation Profiles'),
    icon: 'wifi-bridging',
    path: '/fortiap/devices/wifitemplates',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_op_prof',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Operation Profiles',
  },
  fap_device_template_opprofs_approf: {
    //icon: 'fortiap-alt',
    label: gettext('FortiAP Profiles'),
    path: '/fortiap/devices/wifitemplates/profiles',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_ssid',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> SSIDs',
  },
  fap_device_template_opprofs_qosprof: {
    //icon: 'service',
    label: gettext('QoS Profiles'),
    path: '/fortiap/devices/wifitemplates/qosprofiles',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_qos',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> QoS Profiles',
  },
  fap_device_template_opprofs_cfgprof: {
    //icon: 'config-status',
    label: gettext('FortiAP Configuration Profiles'),
    path: '/fortiap/devices/wifitemplates/apcfgprofiles',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_cfg_prof',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> FortiAP Configuration Profiles',
  },
  fap_device_template_opprofs_arrpprof: {
    //icon: 'wave-square',
    iconLibrary: 'fa-solid',
    label: gettext('ARRP Profiles'),
    path: '/fortiap/devices/wifitemplates/arrpprofiles',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_arrp',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> ARRP Profiles',
  },
  fap_device_template_conprofs: {
    label: gettext('Connectivity Profiles'),
    icon: 'connected',
    path: '/fortiap/devices/wifitemplates',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_connectivity',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Connectivity Profiles',
  },
  fap_device_template_conprofs_mpskprof: {
    //icon: 'key',
    iconLibrary: 'fa-solid',
    label: gettext('MPSK Profiles'),
    path: '/fortiap/devices/wifitemplates/mpskprofiles',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_mpsk',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> MPSK Profiles',
  },
  fap_device_template_conprofs_bjrprof: {
    //icon: 'wifi-mesh',
    label: gettext('Bonjour Profiles'),
    path: '/fortiap/devices/wifitemplates/bjrprofiles',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_bonjour',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Bonjour Profiles',
  },
  fap_device_template_conprofs_btprof: {
    //icon: 'bluetooth',
    iconLibrary: 'fa-brands',
    label: gettext('Bluetooth Profiles'),
    path: '/fortiap/devices/wifitemplates/bleprofiles',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_bluetooth',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Bluetooth Profiles',
  },
  fap_device_template_protprof: {
    label: gettext('Protection Profiles'),
    icon: 'fortiwifi',
    path: '/fortiap/devices/wifitemplates',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_protection_prof',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Protection Profiles',
  },
  fap_device_template_protprof_widsprof: {
    //icon: 'search',
    label: gettext('WIDS Profiles'),
    path: '/fortiap/devices/wifitemplates/widsprofiles',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_wids',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> WIDS Profiles',
  },
  fap_device_template_protprof_l3prof: {
    //icon: 'import',
    label: gettext('L3 Firewall Profiles'),
    path: '/fortiap/devices/wifitemplates/accesscontrol',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_access_control',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> L3 Firewall Profiles',
  },
  fap_device_template_wifisetting: {
    label: gettext('WiFi Settings'),
    icon: 'settings',
    path: '/fortiap/devices/wifitemplates/setting',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fap_setting',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> WiFi Settings',
  },
};

export default {
  fap: {
    type: 'app',
    label: gettext('AP Manager'),
    icon: 'fortiap',
    color: '#1fa254',
    path: '/fortiap',
    showCheckers: [
      isFmg,
      inManageableAdoms,
      negate(inBackupAdom),
      negate(inGlobalAdom),
      negate(inOthersAdom),
      negate(inFpxAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_FORTIAP),
    ],
  },
  fap_device: {
    label: gettext('Managed FortiAPs'),
    path: '/fortiap/centrallist',
    showCheckers: [isCentralManagement('fap')],
    olhTag: 'fap_centrallist',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Managed FortiAPs (Central)',
  },
  fap_device_pdm_root: {
    // used in per-device management main sidebar
    label: gettext('Managed FortiAPs'),
    path: '/fortiap/devices/list',
    showCheckers: [negate(isCentralManagement('fap'))],
    olhTag: 'fap_list',
    olhCategory: 'ap',
    olhFmg: 'AP Manager -> Managed FortiAPs (Per-device)',
  },
  ...fap_map_apps,
  ...fap_template_apps,
  ...fap_device_template_apps,
};
