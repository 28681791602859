import {
  fmPost,
  escapeSlash,
  FMG_POST_URL,
  handleError,
} from '../common/index';

const JSON_API_URL = '/gui/sys/admin/users';

const getUrl = (username) => `${JSON_API_URL}/${escapeSlash(username)}/theme`;

export const setThemeConfig = handleError(
  async (csrfToken, username, data) =>
    await fmPost(csrfToken, null, FMG_POST_URL, {
      url: getUrl(username),
      method: 'post',
      params: data,
    }).then((resp) => resp.result[0])
);
