/*
 * You want to display some translated text with React components in between,
 * for example, if you have this text/component mixed sentence:
 *
 *   When a group contains <NumberInput /> or more log occurrences within <NumberInput /> <SelectTimeUnit />,
 *   trigger an event.
 *
 * The gettext may be written like:
 *
 *   gettext('When a group contains %(count_input)s or more log occurrences within %(time_value_input)s %(time_unit_input)s, trigger an event.')
 *
 * And a translated text might become
 *
 *   "グループに %(time_value_input)s %(time_unit_input)s 以内に
 *   %(count_input)s 以上のログが発生すると、イベントがトリガーされます。"
 *
 * Note how the input positions might change because another language does not
 * necessarily follow the same order.
 *
 *
 * Usage example:
 *
 * Singular translation with simple gettext, but with 3 inputs:
 *
 * const translated = useMemo(() => (
 *   gettext('When a group contains %(count_input)s or more log occurrences within %(time_value_input)s %(time_unit_input)s, trigger an event.')
 * ), []);
 * const components = {
 *   count_input: <input type="number" />,
 *   time_value_input: <input type="number" />,
 *   time_unit_input: <SSelect choices={...} />,
 * };
 *
 * return (<div>
 *   <GettextPrinter
 *     printText={translated}
 *     withComponents={components}
 *   />
 * </div>);
 *
 * */

import React, { useMemo } from 'react';

// internal use, do not export.
class ComponentRenderer {
  constructor(compName) {
    this.compName = compName;
  }
  render(components) {
    return components[this.compName];
  }
}

/*
 * Usage Example:
 *
 * This is a complex case using plural handling ngettext, with two inputs.
 *
 * const [count, setCount] = useState(0);
 * const [name, setName] = useState('');
 * const myText = useMemo(() => {
 *   return ngettext(
 *     'Send %(message_count)s wakeup message to %(server_name)s',
 *     'Send %(message_count)s wakeup messages to %(server_name)s',
 *     count
 *   );
 * }, [count]);
 *
 * <GettextPrinter
 *   printText={myText}
 *   withComponents={{
 *     message_count: (<input type="number" value={count}
 *       onChange={setCount} />),
 *     server_name: (<input type="text" value={name}
 *       onChange={setName}/>),
 *   }}
 * />
 **/
export const GettextPrinter = ({
  printText = '',
  withComponents = {},
  ...rest
}) => {
  const arr = useMemo(() => {
    // must re-create `re` each time, because re can remember internal state
    const re = /%\((?<compName>\w+)\)s/g;
    const result = [];
    let lastIndex = 0;
    let match = null;
    while ((match = re.exec(printText)) !== null) {
      if (match.index > lastIndex) {
        // We have some non-match text we walked passed.
        // so add it before dealing with what regex matched.
        result.push(printText.substring(lastIndex, match.index));
      }
      const compName = match.groups.compName;
      result.push(new ComponentRenderer(compName));
      lastIndex = re.lastIndex;
    }
    if (lastIndex < printText.length) {
      // final match has some leftover text
      result.push(printText.substring(lastIndex, printText.length));
    }
    return result;
  }, [printText]);

  return (
    <>
      {arr.map((row, idx) => {
        if (typeof row === 'string') {
          return <React.Fragment key={idx + row}>{row}</React.Fragment>;
        } else if (row instanceof ComponentRenderer) {
          return (
            <React.Fragment key={idx + row.compName}>
              {row.render({ ...rest, ...withComponents })}
            </React.Fragment>
          );
        }
      })}
    </>
  );
};

export default GettextPrinter;
