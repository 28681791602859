import { fiHttpGet } from 'fi-http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listenerMiddleware } from 'fistore/middlewares';
import { appEnabled } from '../../routing/selectors';
import { fetchSessionAdom } from '../../session/adom/slice';
import { getIsFazSupervisor } from '../../session/sysConfig/selectors';
import { refreshAppTree } from '../../routing/slice';
import { getSocFabricOsTypes } from './selectors';

const initialState = {
  devices: [],
  osTypes: [],
};

const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSocFabricDevices.fulfilled, (state, { payload }) => {
      state.devices = payload;
      let availableOsTypes = state.devices.reduce((res, member) => {
        member.adoms.forEach((adom) => {
          adom.devices.forEach((dev) => {
            res[parseInt(dev.os_type, 10)] = true;
          });
        });
        return res;
      }, []);
      state.osTypes = availableOsTypes;
    });
  },
});

const fetchSocFabricDevices = createAsyncThunk(
  'fazSocFabric/device/FETCH_DEVICES',
  async () => {
    const dvmResp = await fiHttpGet('/p/fabric/socfabric/dvm/');
    return dvmResp?.data || [];
  }
);

// fetch on adom change
listenerMiddleware.startListening({
  actionCreator: fetchSessionAdom.fulfilled,
  effect: async (action, { dispatch, condition, cancelActiveListeners }) => {
    cancelActiveListeners();
    if (
      await condition(
        (_, currState) =>
          getIsFazSupervisor(currState) && appEnabled('fortiview')(currState)
      )
    ) {
      await dispatch(fetchSocFabricDevices());
    }
  },
});

listenerMiddleware.startListening({
  predicate: (action, currState, prevState) => {
    return (
      getIsFazSupervisor(currState) &&
      getSocFabricOsTypes(currState) !== getSocFabricOsTypes(prevState)
    );
  },
  effect: async (action, { dispatch }) => {
    dispatch(refreshAppTree());
  },
});

export { fetchSocFabricDevices };

export default slice.reducer;
