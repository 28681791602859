import { get } from 'lodash';
import * as actions from './actions';

function setDeviceChecksum({ adomOid, dvmChecksum, adomChecksum }, state) {
  const old = get(state, adomOid, {});
  return {
    ...state,
    [adomOid]: {
      oldDvmChecksum: old.dvmChecksum,
      oldAdomChecksum: old.adomChecksum,
      dvmChecksum,
      adomChecksum,
    },
  };
}

export function checksumReducer(state = {}, action) {
  switch (action.type) {
    case actions.setDeviceChecksum.type: {
      return setDeviceChecksum(action.payload, state);
    }
    default:
      return state;
  }
}
