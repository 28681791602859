//import shallowEqual from '../utils/shallowEqual';
//import wrapActionCreators from '../utils/wrapActionCreators';
//import invariant from 'invariant';

//import isPlainObject from 'lodash/isPlainObject';
//import isFunction from 'lodash/isFunction';

const assign = Object.assign;
const defaultMapStateToTarget = () => ({});
const defaultMapDispatchToTarget = (dispatch) => ({ dispatch });

function mapValues(obj, fn) {
  return Object.keys(obj).reduce((result, key) => {
    result[key] = fn(obj[key], key);
    return result;
  }, {});
}
function bindActionCreator(actionCreator, dispatch) {
  return (...args) => dispatch(actionCreator(...args));
}
function bindActionCreators(actionCreators, dispatch) {
  if (typeof actionCreators === 'function') {
    return bindActionCreator(actionCreators, dispatch);
  }

  if (
    typeof actionCreators !== 'object' ||
    actionCreators === null ||
    actionCreators === undefined
  ) {
    throw new Error(
      `bindActionCreators expected an object or a function, instead received ${
        actionCreators === null ? 'null' : typeof actionCreators
      }. ` +
        'Did you write "import ActionCreators from" instead of "import * as ActionCreators from"?'
    );
  }

  return mapValues(actionCreators, (actionCreator) =>
    bindActionCreator(actionCreator, dispatch)
  );
}
export function connector_base(store, isClassType) {
  return (mapStateToTarget, mapDispatchToTarget) => {
    let finalMapStateToTarget = mapStateToTarget || defaultMapStateToTarget;

    const finalMapDispatchToTarget =
      isObject(mapDispatchToTarget) && !isFunction(mapDispatchToTarget)
        ? wrapActionCreators(mapDispatchToTarget)
        : mapDispatchToTarget || defaultMapDispatchToTarget;

    invariant(
      isFunction(finalMapStateToTarget),
      'mapStateToTarget must be a Function. Instead received %s.',
      finalMapStateToTarget
    );

    invariant(
      isObject(finalMapDispatchToTarget) ||
        isFunction(finalMapDispatchToTarget),
      'mapDispatchToTarget must be a plain Object or a Function. Instead received %s.',
      finalMapDispatchToTarget
    );

    let slice = getStateSlice(store.getState(), finalMapStateToTarget, false);
    const isFactory = isFunction(slice);

    if (isFactory) {
      finalMapStateToTarget = slice;
      slice = getStateSlice(store.getState(), finalMapStateToTarget);
    }

    const boundActionCreators = finalMapDispatchToTarget(store.dispatch);

    const conn = (target) => {
      invariant(
        isFunction(target) || isObject(target),
        'The target parameter passed to connect must be a Function or a object.'
      );

      //Initial update
      updateTarget(target, slice, boundActionCreators);

      const unsubscribe = store.subscribe(() => {
        const nextSlice = getStateSlice(
          store.getState(),
          finalMapStateToTarget
        );
        if (!shallowEqual(slice, nextSlice)) {
          const prevSlice = slice;
          // update reference before calling target.
          // the target might dispatch which triggers `store.subscribe` and results in infinite loop.
          slice = nextSlice;
          updateTarget(target, nextSlice, boundActionCreators, prevSlice);
        }
      });
      return unsubscribe;
    };

    const conn_class = (target) => {
      //Initial update
      invariant(
        isFunction(target) && isClass(target),
        'The target parameter passed to connect must be a class.'
      );

      const props = {};
      updateTarget(props, slice, boundActionCreators);

      const obj = new target(props);

      const unsubscribe = store.subscribe(() => {
        const nextSlice = getStateSlice(
          store.getState(),
          finalMapStateToTarget
        );
        if (!shallowEqual(slice, nextSlice)) {
          const nextProps = {};
          updateTarget(nextProps, nextSlice, boundActionCreators, slice);

          const preSlice = slice;
          slice = nextSlice;

          if (!obj.shouldUpdate(nextProps)) return;

          obj.willUpdate(nextProps);
          obj.update(preSlice);
        }
      });

      obj.setUnsubscribe(unsubscribe);

      return obj;
    };
    return (target) => {
      if (isClassType) return conn_class(target);
      else return conn(target);
    };
  };
}

export const connector = (store) => connector_base(store, false);
export const connector_class = (store) => connector_base(store, true);

const invariant = function (condition, format, a, b, c, d, e, f) {
  if (!condition) {
    var error;
    if (format === undefined) {
      error = new Error(
        'Minified exception occurred; use the non-minified dev environment ' +
          'for the full error message and additional helpful warnings.'
      );
    } else {
      var args = [a, b, c, d, e, f];
      var argIndex = 0;
      error = new Error(
        format.replace(/%s/g, function () {
          return args[argIndex++];
        })
      );
      error.name = 'Invariant Violation';
    }

    error.framesToPop = 1; // we don't care about invariant's own frame
    throw error;
  }
};

function isObject(value) {
  var type = typeof value;
  return value != null && (type == 'object' || type == 'function');
}

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
}
function isClass(fn) {
  return isFunction(fn) && fn === fn.prototype.constructor;
}
var fnToString = (fn) => Function.prototype.toString.call(fn);
var objStringValue = fnToString(Object);
function isPlainObject(obj) {
  if (!obj || typeof obj !== 'object') {
    return false;
  }

  var proto =
    typeof obj.constructor === 'function'
      ? Object.getPrototypeOf(obj)
      : Object.prototype;

  if (proto === null) {
    return true;
  }

  var constructor = proto.constructor;

  return (
    typeof constructor === 'function' &&
    constructor instanceof constructor &&
    fnToString(constructor) === objStringValue
  );
}

function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  /* $$hashKey is added by angular when using ng-repeat, we ignore that*/
  var keysA = Object.keys(objA).filter((k) => k !== '$$hashKey');
  var keysB = Object.keys(objB).filter((k) => k !== '$$hashKey');

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var hasOwn = Object.prototype.hasOwnProperty;
  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwn.call(objB, keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

function wrapActionCreators(actionCreators) {
  return (dispatch) => bindActionCreators(actionCreators, dispatch);
}

function updateTarget(target, StateSlice, dispatch, prevStateSlice) {
  if (isFunction(target)) {
    target(StateSlice, dispatch, prevStateSlice);
  } else {
    assign(target, StateSlice, dispatch);
  }
}

function getStateSlice(state, mapStateToScope, shouldReturnObject = true) {
  const slice = mapStateToScope(state);

  if (shouldReturnObject) {
    invariant(
      isPlainObject(slice),
      '`mapStateToScope` must return an object. Instead received %s.',
      slice
    );
  } else {
    invariant(
      isPlainObject(slice) || isFunction(slice),
      '`mapStateToScope` must return an object or a function. Instead received %s.',
      slice
    );
  }

  return slice;
}

export class ObserverBase {
  constructor(props) {
    this.props = props;
  }
  setUnsubscribe(unsub) {
    this.unsubscribe = unsub;
  }
  // eslint-disable-next-line
  shouldUpdate(nextProps) {
    return true;
  }
  willUpdate(nextProps) {
    this.props = nextProps;
  }
  // eslint-disable-next-line
  update(prevProps) {}
  destroy() {
    if (this.unsubscribe) this.unsubscribe();

    this.props = null;
  }
}

export function test_connect(state) {
  class TestUpdate extends ObserverBase {
    constructor(props) {
      super(props);
    }
    update(preProps) {
      // eslint-disable-next-line
      console.log('prev:', preProps);
      // eslint-disable-next-line
      console.log('curr:', this.props);
    }
  }
  // eslint-disable-next-line
  const mapDispatchToProps = (dispath) => {
    return {
      testFn: () => {},
    };
  };

  const mapStateToProps = (state) => {
    return {
      value: state.workspaceResReducer,
    };
  };

  const conn_class = connector_class(state);
  // eslint-disable-next-line
  const obj = conn_class(mapStateToProps, mapDispatchToProps)(TestUpdate);
}
/*
class CounterController extends ObserverBase{
    constructor(props) {
        super(props)

    }
}

const mapDispatchToProps=(dispath)=>{
    return {
        testFn:()=>{

        }
    }
}

const mapStateToProps=(state)=>{
    return {
    value: state.counter
    };
}

const obj=connect_class(mapStateToProps,mapDispatchToProps)(CounterController)
...............
obj.destroy()


const obj={}
const unsubscribe=connect(mapStateToProps,mapDispatchToProps)(obj)

const unsubscribe=connect(mapStateToProps,mapDispatchToProps)((
    StateSlice,
    dispatch,
    prevStateSlice)=>{

})


*/
