import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { escapeSlash, escapeSlashFull } from 'kit/kit-regexp';

const TEMPLATE_URL = '/pm/template/%s/adom/%s';
const TEMPLATE_SCOPE_URL =
  '/pm/template/%(stype)s/adom/%(adom)s/%(templateName)s/scope member';
const TEMPLATE_WIDGET_URL =
  '/pm/config/adom/%(adom)s/template/%(templateSubtype)s/%(template)s/action-list/';
const TEMPLATE_ACTION_LIST_URL =
  '/pm/config/adom/%(adom)s/device/template/action-list/';
const DEFAULT_TEMPLATE_SETTING = {
  [MACROS.USER.DVM.CUSTOM_TEMPLATE_STYPE.IPSEC]: {
    stype: MACROS.USER.DVM.CUSTOM_TEMPLATE_STYPE.IPSEC,
  },
  [MACROS.USER.DVM.CUSTOM_TEMPLATE_STYPE.ROUTER]: {
    stype: MACROS.USER.DVM.CUSTOM_TEMPLATE_STYPE.ROUTER,
  },
  [MACROS.USER.DVM.CUSTOM_TEMPLATE_STYPE.CST]: {
    stype: MACROS.USER.DVM.CUSTOM_TEMPLATE_STYPE.CST,
  },
};

let genDfltTemplateSetting = function (tmplId) {
  if (!tmplId) return {};
  return {
    stype: tmplId,
    widgets: [tmplId],
  };
};

let loadTemplate = function (
  adom = null,
  key = null,
  templateStype = '',
  abortCtrl
) {
  let adomName = null;
  if (!adom) {
    adomName = fiAdom.current().name;
  } else {
    adomName = adom.name ? adom.name : adom;
  }
  let stype = templateStype.split(' ').join('_');
  let url = TEMPLATE_URL.printf([stype, escapeSlashFull(adomName)]);
  url = key ? url + '/' + key : url;

  return fiFmgHttp
    .forward(
      {
        method: 'get',
        params: [
          {
            url: url,
          },
        ],
      },
      false,
      abortCtrl
    )
    .then((resp) => {
      return resp[0].data || [];
    });
};

let setTemplate = function (adom, data, tmplType, cloneName) {
  let adomName = adom.name ? adom.name : adom;
  let method = cloneName ? 'clone' : data._isNew ? 'add' : 'set';
  let stype = tmplType.split(' ').join('_');
  let url = TEMPLATE_URL.printf([stype, escapeSlashFull(adomName)]);
  url = cloneName ? url + '/' + cloneName : url;
  let params = [
    {
      url: url,
      data: {
        name: data.name,
        type: 'template',
        'scope member': data['scope member'] || [],
        'template setting': {
          ...DEFAULT_TEMPLATE_SETTING[stype],
          ...(data['template setting'] || genDfltTemplateSetting(stype)),
        },
      },
    },
  ];
  if (data.description) {
    params[0].data['template setting'].description = data.description;
  }
  return fiFmgHttp.query({
    method: method,
    params: params,
  });
};

const setScopeMember = ({
  adomName,
  tmpl,
  tmplType,
  httpVerb = 'add',
  scopeMembers = [],
}) => {
  const stype = tmpl.stype || tmplType.split(' ').join('_');
  if (!stype || !tmpl || !adomName) {
    return;
  }

  const url = TEMPLATE_SCOPE_URL.printfd({
    stype,
    adom: escapeSlashFull(adomName),
    templateName: escapeSlashFull(tmpl.name),
  });
  const reqObj = {
    method: httpVerb,
    params: [
      {
        url: url,
        data: scopeMembers || [],
      },
    ],
  };
  return fiFmgHttp.query(reqObj);
};

let deleteTemplate = function (adom, tmpl, templateStype) {
  let adomName = adom.name ? adom.name : adom;
  let stype = templateStype.split(' ').join('_');
  let url =
    TEMPLATE_URL.printf([stype, escapeSlashFull(adomName)]) +
    '/' +
    escapeSlash(tmpl.name);

  return fiFmgHttp.query({
    method: 'delete',
    params: [
      {
        url: url,
        data: [tmpl],
      },
    ],
  });
};

let getTemplateActionListSyntax = function (adom) {
  let adomName = adom.name ? adom.name : adom;
  let url = TEMPLATE_ACTION_LIST_URL.printfd({
    adom: escapeSlashFull(adomName),
  });
  return fiFmgHttp
    .forward({
      method: 'get',
      params: [
        {
          url: url,
          option: 'syntax',
        },
      ],
    })
    .then((resp) => {
      let data = resp[0].data || [];
      return data;
    });
};

let getTemplateCategorySyntax = function (adom, tmpl, cates, abortCtrl) {
  let adomName = adom.name ? adom.name : adom;
  let _cates = Array.isArray(cates) ? cates : [cates];
  var req = {
    method: 'get',
    params: _cates.map((cate) => ({
      url:
        'pm/config/adom/' +
        escapeSlashFull(adomName) +
        '/obj' +
        '/' +
        cate.split(' ').join('/'),
      option: 'syntax',
    })),
  };
  return fiFmgHttp.forward(req, undefined, abortCtrl).then(function (resp) {
    let ret = {};
    if (Array.isArray(resp) && resp.length > 1) {
      ret = resp.reduce((result, cur) => {
        return { ...result, ...cur.data };
      }, {});
    } else {
      ret = resp[0].data;
    }
    _cates.forEach((cate) => {
      if (ret.hasOwnProperty(cate)) {
        ret[cate].cate = cate;
      }
    });
    return ret;
  });
};

let getTemplateWidgetData = function (adom, tmplSubtype, tmplName, entryID) {
  let adomName = null;
  if (adom) {
    adomName = adom.name ? adom.name : adom;
  } else {
    adomName = fiAdom.current().name;
  }
  let stype = tmplSubtype.split(' ').join('_');
  let url = TEMPLATE_WIDGET_URL.printfd({
    adom: escapeSlashFull(adomName),
    template: tmplName,
    templateSubtype: stype,
  });
  if (entryID) url += entryID;
  return fiFmgHttp
    .forward({
      method: 'get',
      params: [
        {
          url: url,
        },
      ],
    })
    .then(
      (resp) => {
        return Array.isArray(resp[0].data) ? resp[0].data : [resp[0].data];
      },
      () => {
        return [{}];
      }
    );
};

let setTemplateWidgetData = function (
  adom,
  templateSubtype,
  tmplName,
  widgetData
) {
  let adomName = null;
  if (adom) {
    adomName = adom.name ? adom.name : adom;
  } else {
    adomName = fiAdom.current().name;
  }
  let stype = templateSubtype.split(' ').join('_');
  let url = TEMPLATE_WIDGET_URL.printfd({
    adom: escapeSlashFull(adomName),
    template: tmplName,
    templateSubtype: stype,
  });
  return fiFmgHttp.query({
    method: 'set',
    params: [
      {
        url: url,
        data: Array.isArray(widgetData) ? widgetData : [widgetData],
      },
    ],
  });
};

let delTemplateWidgetData = function (
  adom,
  templateSubtype,
  tmplName,
  entryIDs
) {
  let adomName = null;
  if (adom) {
    adomName = adom.name ? adom.name : adom;
  } else {
    adomName = fiAdom.current().name;
  }
  let stype = templateSubtype.split(' ').join('_');
  let url = TEMPLATE_WIDGET_URL.printfd({
    adom: escapeSlashFull(adomName),
    template: tmplName,
    templateSubtype: stype,
  });
  if (!entryIDs || (Array.isArray(entryIDs) && entryIDs.length === 0)) {
    return Promise.reject(gettext('Entry key is required.'));
  }
  let filter = ['seq', 'in'];
  filter = filter.concat(entryIDs);
  return fiFmgHttp.query({
    method: 'delete',
    params: [
      {
        url: url,
        filter: filter,
        confirm: 1,
      },
    ],
  });
};

const importIPSecTemplate = function (adom, data) {
  let adomName = adom.name || adom;
  return fiFmgHttp.query({
    method: 'clone',
    params: [
      {
        url: `pm/config/device/${data.device}/vdom/${data.vdom}/vpn/ipsec/phase1-interface`,
        data: {
          'new url': `pm/config/adom/${adomName}/template/_ipsec/${data.template}`,
        },
      },
    ],
  });
};

let getTemplateData = function (adom, tmplName, category, templateSubtype) {
  return fiFmgHttp.forward({
    method: 'get',
    params: [
      {
        url:
          '/pm/config/adom/' +
          (adom.name || adom) +
          '/template/' +
          templateSubtype.split(' ').join('_') +
          '/' +
          tmplName +
          '/' +
          category.split(' ').join('/'),
      },
    ],
  });
};

let setTemplateData = function (
  adom,
  tmplName,
  category,
  data,
  templateSubtype
) {
  return fiFmgHttp.query({
    method: 'set',
    params: [
      {
        url:
          '/pm/config/adom/' +
          (adom.name || adom) +
          '/template/' +
          templateSubtype.split(' ').join('_') +
          '/' +
          tmplName +
          '/' +
          category.split(' ').join('/'),
        data: Array.isArray(data) ? data : [data],
      },
    ],
  });
};

let getTemplateSyntax = function (adom, tmplName, category) {
  return fiFmgHttp.forward({
    method: 'get',
    params: [
      {
        url:
          '/pm/config/adom/' +
          (adom.name || adom) +
          '/obj/' +
          category.split(' ').join('/'),
        option: 'syntax',
      },
    ],
  });
};

let getDevMemberList = function (visitedGrpSet, grpMap, grpOid) {
  let ret = [];
  if (!visitedGrpSet.has(grpOid)) {
    visitedGrpSet.add(grpOid);
    let grpData = grpMap[grpOid];
    if (grpData) {
      // first get current group's devMemberList
      ret = [...ret, ...(grpData.devMemberList || [])];
      // then get all nested groups' devMemberList
      if (grpData.grpMemberList && Array.isArray(grpData.grpMemberList)) {
        grpData.grpMemberList.forEach((grp) => {
          ret = [...ret, ...getDevMemberList(visitedGrpSet, grpMap, grp.oid)];
        });
      }
    }
  }
  return ret;
};

export const fiDvmTemplateRequests = {
  loadTemplate,
  setTemplate,
  deleteTemplate,
  getTemplateActionListSyntax,
  getTemplateCategorySyntax,
  getTemplateWidgetData,
  setTemplateWidgetData,
  delTemplateWidgetData,
  getTemplateData,
  setTemplateData,
  getTemplateSyntax,
  getDevMemberList,
  importIPSecTemplate,
  setScopeMember,
};
