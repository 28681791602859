import React from 'react';
import { ReactContentViewContainer } from 'ra_main/main_layout/ReactContentViewContainer';
import { useMenuActions } from './useMenuActions';

export const ContentViewWrapper = () => {
  const { menuItemActions } = useMenuActions();

  return (
    <ReactContentViewContainer
      menuId='fortiview'
      menuItemActions={menuItemActions}
    />
  );
};
