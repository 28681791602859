import { call } from 'redux-saga/effects';
import { merge, isNil } from 'lodash';
import { createAction } from '@reduxjs/toolkit';

const isPromiseAction = (action) =>
  !isNil(action.meta?.promise?.resolvedAction);

export function createPromiseAction(type, prepareFn) {
  const resolvedAction = createAction(`${type}.RESOLVED`);
  const rejectedAction = createAction(`${type}.REJECTED`);
  const creator = createAction(type, (...args) =>
    merge(prepareFn ? prepareFn(...args) : { payload: args[0] }, {
      meta: {
        promise: {
          resolvedAction,
          rejectedAction,
        },
      },
    })
  );
  creator.trigger = creator;
  creator.resolved = resolvedAction;
  creator.rejected = rejectedAction;
  return creator;
}

const verify = (action, method) => {
  if (!isPromiseAction(action))
    throw new Error(
      `saga-promise: ${method}: first argument must be promise trigger action, got ${action}`
    );
};

export function* callPromiseAction(action, executor, ...args) {
  try {
    verify(action, 'call');
    const result = yield call(executor, ...args);
    action.meta?.promise?.resolve?.(result);
  } catch (error) {
    action.meta?.promise?.reject?.(yield error);
  }
}

export function promiseSaga(executor) {
  return function* (action) {
    yield* callPromiseAction(action, executor, action);
  };
}

export const sagaPromiseMiddleWare = (store) => (next) => (action) => {
  if (isPromiseAction(action)) {
    return new Promise((resolve, reject) =>
      next(
        merge(action, {
          meta: {
            promise: {
              resolve: (value) => {
                resolve(value);
                store.dispatch(action.meta?.promise?.resolvedAction?.(value));
              },
              reject: (error) => {
                reject(error);
                store.dispatch(action.meta?.promise?.rejectedAction?.(error));
              },
            },
          },
        })
      )
    );
  }
  return next(action);
};
