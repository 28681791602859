import { dispatch, select } from 'fistore';
import { checkAuth } from 'fistore/auth/slice';
import { getIsLoggedIn } from 'fistore/auth/selectors';

export const checkAuthLoader = async () => {
  const isLoggedIn = select(getIsLoggedIn);
  if (isLoggedIn) return true;
  try {
    return await dispatch(checkAuth()).unwrap();
  } catch (e) {
    return false;
  }
};
