import { ProToolkit } from '@fafm/neowise-pro';
import { fiSysConfig, fiAdom } from 'fi-session';
import { isFunction, isUndefined, isNil } from 'lodash';
import { openConfirmModal, CellSpan, ProLegoTooltipWrapper } from 'rc_layout';
import { findIndexInArrayByKey } from 'kit-array';
import { AdomReference } from './AdomReference';
import { getLastLogReceivedFormatters, getLogStatusObj } from 'ra_dvm_util';
import { DeviceRowIcon, deviceIconFormatter } from 'ra_device_util';
import { fiStore } from 'fistore';
import { fetchSessionAdom } from 'fistore/session/adom/slice';
import { fiDvmLogDataService, fiDvmTableCellParser } from 'fi-dvm';

export const getSysConfig = () => {
  let sysConfig = fiSysConfig.current();
  return {
    ...sysConfig,
    isFMG: fiSysConfig.isFmg(),
    hasFmgFeature: fiSysConfig.hasFmgFeature(),
    hasFazFeature: fiSysConfig.hasFazFeature(),
    isAdomEnabled: fiSysConfig.isAdomEnabled(),
    isAdomAdvancedMode: fiSysConfig.isAdomAdvancedMode(),
    isAdomNormalMode: fiSysConfig.isAdomNormalMode(),
  };
};

export const autoId = (key) => 'sys_all_adoms_edit:' + key;

const _isAdomAdvancedMode = (sysConfig) =>
  sysConfig.adom_mode == MACROS.SYS.ADOM_M_ADVANCED;
const _isAdomNormalMode = (sysConfig) =>
  sysConfig.adom_mode == MACROS.SYS.ADOM_M_NORMAL;

const prepTableData = (parsedVdomData) => {
  let map = {};
  let tableSrc = [];

  // Fold data for nw expandable table
  parsedVdomData.forEach((row) => {
    let key = row.isVdom ? row.devoid : row.oid || row.did;
    if (!map[key])
      map[key] = {
        data: null,
        children: [],
      };
    if (!row.isVdom) {
      map[key].data = row;
    } else {
      map[key].children.push(row);
    }
  });
  Object.keys(map).forEach((key) => {
    let deviceRow = map[key].data;
    deviceRow.children = map[key].children;
    if (!map[key].children.length) delete deviceRow.children;
    tableSrc.push(deviceRow);
  });
  return tableSrc;
};

export const parseVdomData = ({
  allDevices = [],
  currentMembers,
  forSelection,
  tempAddedMembers,
  sysConfig,
  adomName,
  adomOid,
}) => {
  if (_isAdomNormalMode(sysConfig)) {
    let availableDevices = [];
    let currentMemberSet = new Set(
      (currentMembers || []).map(function (x) {
        return x.name.toUpperCase();
      })
    );
    allDevices.forEach(function (ee) {
      if (forSelection && currentMemberSet.has(ee.name.toUpperCase())) {
        return true;
      }
      ee.showBelongToAdom = true;
      ee.uid = `${ee.oid}`;
      if (!isUndefined(adomOid)) {
        const logStats = fiDvmLogDataService.getLogStatsByDevice(ee, adomOid);
        ee.logStatus =
          logStats?.status || MACROS.DVM.LOGSTAT_DEVST_UNKLOGSTAT_DEVST_DOWN;
        ee.logStats = logStats;
      }

      availableDevices.push(ee);
    });
    return availableDevices;
  } else if (_isAdomAdvancedMode(sysConfig)) {
    let allDevicesWithVdoms = [];
    allDevices.forEach(function (e) {
      //one device could be added more than once if its vdoms are in different adoms
      e.isVdom = 0;
      e.groupName = e.name;
      e.uid = `${e.oid}`;
      if (!isUndefined(adomOid)) {
        const logStats = fiDvmLogDataService.getLogStatsByDevice(e, adomOid);
        e.logStatus = logStats?.status || MACROS.DVM.LOGSTAT_DEVST_DOWN;
        e.logStats = logStats;
      }
      if (e.vdom_status) e.showBelongToAdom = false;
      else e.showBelongToAdom = true;
      allDevicesWithVdoms.push(e);
      if (e.vdom_status) {
        (e.vdoms || []).forEach(function (ee) {
          let vdom = {
            sn: e.sn,
            vdom: ee.name,
            timestamp: ee.timestamp,
            logs: ee.logs,
          };
          let logStats;
          if (!isUndefined(adomOid))
            logStats = fiDvmLogDataService.getLogStatsByDevice(e, adomOid);
          const logsObj = isUndefined(adomOid)
            ? {
                logStats: ee.logStats,
                logStatus: ee.logStatus,
              }
            : {
                logStats,
                logStatus: logStats?.status || MACROS.DVM.LOGSTAT_DEVST_DOWN,
              };

          allDevicesWithVdoms.push({
            isVdom: 1,
            uid: `${e.oid}-${ee.oid}`,
            isCurrentMember: e.isCurrentMember,
            name: ee.name,
            oid: ee.oid,
            devname: e.name,
            devoid: e.oid,
            ip: '',
            platform: 'VDOM',
            frmversion: e.frmversion,
            adomname: e.adomname,
            adomOid: e.adomOid,
            groupName: e.name,
            showBelongToAdom: true,
            title: fiDvmTableCellParser.getData(vdom, 'logs').title,
            ...logsObj,
          });
        });
      }
    });

    let results = [];
    if (forSelection) {
      results = mergeSplittedVdoms({
        allDevicesWithVdoms,
        needFilter: true,
        sysConfig,
        adomName,
        currentMembers,
        tempAddedMembers,
      });
    } else {
      results = allDevicesWithVdoms;
    }
    return prepTableData(results);
  }
};

const mergeSplittedVdoms = ({
  allDevicesWithVdoms,
  needFilter,
  sysConfig,
  adomName,
  currentMembers,
  tempAddedMembers,
}) => {
  if (_isAdomNormalMode(sysConfig)) {
    return allDevicesWithVdoms;
  }
  var merged = [];

  function getKey(dev) {
    let adomname = dev.adomname || adomName;
    return '%s_%s'.printf([adomname, dev.groupName]);
  }

  function groupDevices(devices = [], condition) {
    var devByGroup = {};
    devices.forEach(function (dev) {
      let key = getKey(dev);
      if (!(key in devByGroup)) {
        devByGroup[key] = [];
      }
      if (
        (isFunction(condition) && condition(devByGroup, dev)) ||
        isUndefined(condition)
      ) {
        devByGroup[key].push(dev);
      }
    });
    return devByGroup;
  }
  //group all available, current memebers, temp added members by device
  var devicesObj = groupDevices(
    allDevicesWithVdoms,
    function (devByGroup, dev) {
      let key = getKey(dev);
      return (
        findIndexInArrayByKey(devByGroup[key], 'name', dev.name) < 0 ||
        dev.isVdom
      );
    }
  );
  var membersObj = groupDevices(currentMembers);
  var tmpMembersObj = groupDevices(tempAddedMembers);

  for (var group in devicesObj) {
    var devdoms = devicesObj[group];
    for (var jj = 0; jj < devdoms.length; jj++) {
      if (
        Array.isArray(tmpMembersObj[group]) &&
        findIndexInArrayByKey(
          tmpMembersObj[group],
          'name',
          devdoms[jj]['name']
        ) >= 0
      ) {
        devdoms[jj].isSelected = true;
      }
    }
    if (needFilter && group in membersObj) {
      continue;
    }
    merged = merged.concat(devdoms);
  }
  return merged;
};

export const autofazId = (key) => 'sys_all_adoms_edit-storage:' + key;

const _getAddedMembers = (sysConfig, tempAddedMembers) => {
  var addedMembers = [];
  if (_isAdomNormalMode(sysConfig)) {
    tempAddedMembers.forEach((dev) => {
      if (dev.vdom_status) {
        dev.vdoms.forEach((vdom) => {
          addedMembers.push({ name: dev.name, vdom: vdom.name });
        });
      } else {
        addedMembers.push({ name: dev.name, vdom: 'root' });
      }
    });
  } else {
    // advanced mode
    tempAddedMembers.forEach((row) => {
      if (!row.isVdom && !row.vdom_status) {
        addedMembers.push({ name: row.name, vdom: 'root' });
      } else if (row.isVdom) {
        addedMembers.push({ name: row.devname, vdom: row.name });
      }
    });
  }
  return addedMembers;
};

export const preparePostData = (adomRes, sysConfig, tempAddedMembers) => {
  const result = {};
  if (tempAddedMembers && tempAddedMembers.length) {
    result.newmembers = _getAddedMembers(sysConfig, tempAddedMembers);
  }
  result.new_vpn = !adomRes.vpn;
  result.new_quota = adomRes.quota * adomRes.quota_select;
};

const _getFormattedDevName = (
  iconProps,
  className,
  iconTitle,
  devName,
  tooltipProps
) => {
  const _iconClass = 'device-icon ' + className;
  const renderIcon = () => {
    if (tooltipProps) {
      return (
        <ProLegoTooltipWrapper {...tooltipProps} placement='right'>
          <DeviceRowIcon
            iconProps={iconProps}
            className={_iconClass}
          ></DeviceRowIcon>
        </ProLegoTooltipWrapper>
      );
    } else {
      return (
        <DeviceRowIcon
          iconProps={iconProps}
          className={_iconClass}
          title={iconTitle}
        ></DeviceRowIcon>
      );
    }
  };
  return (
    <span className='tw-flex tw-items-center'>
      {renderIcon()}
      <span className='device-name'>{devName}</span>
    </span>
  );
};

export const formatSSAdomDevWithIcon = (node, highlighter) => {
  let devName = '',
    iconProps = {},
    className = '',
    title = '',
    tooltipProps;
  if (node.isVdom) {
    devName = '%s[%s]'.printf([node.devname, node.name]);
    iconProps = { name: 'vdom' };
    title = gettext('VDOM');
  } else {
    devName = node.name;
    const {
      iconProps: _ip,
      className: _cn,
      title: _t,
    } = deviceIconFormatter(node, fiSysConfig.isFmg(), true, node.adomOid);
    iconProps = _ip;
    className = _cn;
    title = _t || gettext('Device');
  }
  if (fiSysConfig.isFaz()) {
    //add logstats tooltip
    tooltipProps = getDeviceStatusTooltipProps(node);
  }
  return _getFormattedDevName(
    iconProps,
    className,
    title,
    highlighter(devName),
    tooltipProps
  );
};

export function getDeviceStatusTooltipProps(rowData) {
  if (!fiSysConfig.isFaz()) return;

  const { logStatusText, css } = getLogStatusObj(rowData?.logStatus, false);
  const { lastLogReceivedComp } = getLastLogReceivedFormatters(
    rowData.logStats
  );

  const entries = [
    {
      label: gettext('Status'),
      value: <CellSpan txt={logStatusText} css={css} />,
    },
    {
      label: gettext('Last Log Time'),
      value: <CellSpan txt={lastLogReceivedComp} />,
      condition: () => !!lastLogReceivedComp,
    },
  ];
  return { entries };
}

/**
 * Handles auto selection logic for device/vdom relations (Nw table)
 *
 * @param {*} newRows Newly selected entries
 * @param {*} dataSet All available entries
 * @param {*} accessor Table accessor (For executing table operations)
 */
export const selectRelatedRowsJsTable = (
  toAdd,
  toRm,
  selectionOrg,
  dataSet
) => {
  let _mapFull = {};
  let _mapSelection = {};
  dataSet.forEach((row) => {
    let key = row.isVdom ? row.devoid : row.oid || row.did;
    if (!_mapFull[key])
      _mapFull[key] = {
        data: null,
        children: [],
      };
    if (!row.isVdom) {
      _mapFull[key].data = row;
    } else {
      _mapFull[key].children.push(row);
    }
  });
  selectionOrg.forEach((row) => {
    let key = row.isVdom ? row.devoid : row.oid || row.did;
    if (!_mapSelection[key])
      _mapSelection[key] = {
        data: null,
        children: [],
      };
    if (!row.isVdom) {
      _mapSelection[key].data = row;
    } else {
      _mapSelection[key].children.push(row);
    }
  });

  toRm.forEach((row) => {
    let key = row.isVdom ? row.devoid : row.oid || row.did;
    if (!row.isVdom) {
      delete _mapSelection[key];
    } else if (_mapSelection[key]) {
      _mapSelection[key].children = _mapSelection[key].children.filter(
        (vd) => vd.uid !== row.uid
      );
      if (!_mapSelection[key].children.length) delete _mapSelection[key];
    }
  });

  toAdd.forEach((row) => {
    let key = row.isVdom ? row.devoid : row.oid || row.did;
    if (!row.isVdom) {
      _mapSelection[key] = {
        data: _mapFull[key].data,
        children: _mapFull[key].children,
      };
    } else {
      if (!_mapSelection[key]) {
        _mapSelection[key] = {
          data: _mapFull[key].data,
          children: [row],
        };
      } else {
        _mapSelection[key].children.push(row);
      }
    }
  });

  let toReturn = [];
  Object.keys(_mapSelection).forEach((key) => {
    let entry = _mapSelection[key];
    toReturn.push(entry.data);
    toReturn = [...toReturn, ...entry.children];
  });
  return toReturn;
};

export const updateAdom = (adomId) => {
  if (adomId.toString() === fiAdom.current().oid?.toString?.()) {
    return fiStore.dispatch(fetchSessionAdom()).unwrap();
  }
  return Promise.resolve();
};

export const flattenNwTableData = (rows) => {
  let res = [];
  rows.forEach((row) => {
    let rowCopy = { ...row };
    if (rowCopy.children) delete rowCopy.children;
    res.push(rowCopy);
    if (row.children) res = res.concat(row.children);
  });
  return res;
};

export const generatePostDataForNewMembers = (
  tempAddedMembers = [],
  sysConfig
) => {
  var addedMembers = [];
  if (sysConfig.isAdomNormalMode) {
    tempAddedMembers.forEach(function (dev) {
      if (dev.vdom_status) {
        (dev.vdoms || []).forEach(function (vdom) {
          addedMembers.push({
            name: dev.name,
            vdom: vdom.name,
            adomOid: dev.adomOid,
          });
        });
      } else {
        addedMembers.push({
          name: dev.name,
          vdom: 'root',
          adomOid: dev.adomOid,
        });
      }
    });
  } else {
    // advanced mode
    tempAddedMembers.forEach(function (row) {
      if (!row.isVdom && !row.vdom_status) {
        addedMembers.push({
          name: row.name,
          vdom: 'root',
          adomOid: row.adomOid,
        });
      } else if (row.isVdom) {
        addedMembers.push({
          name: row.devname,
          vdom: row.name,
          adomOid: row.adomOid,
        });
      }
    });
  }
  return addedMembers;
};

export const countVdomForNewMembers = (tempAddedMembers, sysConfig) => {
  var _newmembers = generatePostDataForNewMembers(tempAddedMembers, sysConfig);
  var _newVdomCountByDevName = {};
  //parse newly added members
  _newmembers.forEach(function (nmem) {
    if (isNil(_newVdomCountByDevName[nmem.name])) {
      _newVdomCountByDevName[nmem.name] = 1;
    } else {
      _newVdomCountByDevName[nmem.name] += 1;
    }
  });
  return _newVdomCountByDevName;
};

const ifNeedDeleteLog = (quota, ratio, adomRes) => {
  var newDbQuota = (quota * ratio) / 100;
  var newFileQuota = (quota * (100 - ratio)) / 100;
  var result = '';
  if (newDbQuota < adomRes['db_used']) {
    result = gettext(
      'The new analytics quota is less than the analytics usage.'
    );
  }
  if (newFileQuota < adomRes['file_used']) {
    if (result === '') {
      result = gettext('The new archive quota is less than the archive usage.');
    } else {
      result = gettext(
        'The new analytics/archive quota are less than the analytics/archive usages.'
      );
    }
  }
  if (result !== '') {
    result += ' ' + gettext('This operation will cause log loss.');
  }
  return result;
};

// for formik validate
export const validateStorageInfo = (adomRes, loadingAvailableQuota) => {
  let errors = {};
  let isFazCloud =
    MACROS.SYS.CONFIG_SYSTEM_FAZ_VM64_VIO_CLOUD ||
    MACROS.SYS.CONFIG_SYSTEM_FAZ_VM64_KVM_CLOUD;
  // skip quota related check for cloud adom user
  // if available disk quota is not ready yet, reject
  if (adomRes.loadingAvailableQuota || loadingAvailableQuota) {
    errors.quota = gettext('Please wait until available disk quota is loaded.');
    return errors;
  }
  //check the disk quota, note this number is in MB
  let quota =
    isNil(adomRes.quota) || adomRes.quota === null
      ? null
      : adomRes.quota * adomRes.quota_select;
  if (quota === null || quota < 0) {
    errors.quota = gettext('Disk quota is not set.');
  }
  if (quota > adomRes.available_quota) {
    errors.quota = gettext('Disk quota is larger than the available disk.');
  }

  if (
    adomRes['db_ratio'] < MACROS.ADOM.DISK_USAGE_DB_PERCENTAGE_MIN ||
    adomRes['db_ratio'] > MACROS.ADOM.DISK_USAGE_DB_PERCENTAGE_MAX
  ) {
    errors.db_ratio = gettext(
      'The analytics ratio should be set between %s% and %s%.'
    ).printf([
      MACROS.ADOM.DISK_USAGE_DB_PERCENTAGE_MIN,
      MACROS.ADOM.DISK_USAGE_DB_PERCENTAGE_MAX,
    ]);
  }

  if (isNil(adomRes.db_days) || adomRes.db_days === null) {
    errors.db_days = gettext('Please set number of days for Log Analysis.');
  }
  if (isFazCloud && adomRes.db_days > MACROS.ADOM.KEEP_DB_ONLINE_MAX_DAYS) {
    errors.db_days = gettext(
      'The max number of days for Log Analysis is %s.'
    ).printf([MACROS.ADOM.KEEP_DB_ONLINE_MAX_DAYS]);
  }
  if (isNil(adomRes.file_days) || adomRes.file_days === null) {
    errors.file_days = gettext('Please set number of days for Log Retention.');
  }
  if (isFazCloud && adomRes.file_days > MACROS.ADOM.KEEP_LOG_OFFLINE_MAX_DAYS) {
    errors.file_days = gettext(
      'The max number of days for Log Retention is %s.'
    ).printf([MACROS.ADOM.KEEP_LOG_OFFLINE_MAX_DAYS]);
  }
  return errors;
};

// for form submit warning
const storageInfoWarning = (resolve, reject, adomRes) => {
  let key = 0;
  const template = (children) => (
    <div key={key++}>
      <nw-icon
        library='fa-solid'
        name='exclamation-triangle'
        class='tw-text-warning tw-mr-1'
      ></nw-icon>
      {children}
    </div>
  );

  const PROC = gettext('Are you sure you want to process?');
  let quota =
    isNil(adomRes.quota) || adomRes.quota === null
      ? null
      : adomRes.quota * adomRes.quota_select;
  let msg = [];
  if (quota === 0) {
    msg.push(template(gettext('ADOM disk quota is set to be unlimited.')));
  } else {
    if (quota < 1000) {
      msg.push(
        template(
          gettext('ADOM disk quota is recommended to be at least 1000 MB.')
        )
      );
    }
    if (ifNeedDeleteLog(quota, adomRes['db_ratio'], adomRes)) {
      msg.push(template(ifNeedDeleteLog(quota, adomRes['db_ratio'], adomRes)));
    }
  }
  if (adomRes.db_days === 0) {
    msg.push(
      template(
        gettext(
          'Keep Logs for Analytics is set to 0. Analytics logs will be kept for unlimited days.'
        )
      )
    );
  }
  if (adomRes.file_days === 0) {
    msg.push(
      template(
        gettext(
          'Keep Logs for Archive is set to 0. Logs will be deleted after rolling. All the logs will be lost after upgrading and rebuilding database.'
        )
      )
    );
  }
  if (msg.length) {
    msg.push(<div key={key}>{PROC}</div>);
    openConfirmModal({
      title: gettext('Warning'),
      content: <>{msg}</>,
    }).then(
      () => {
        resolve({});
      },
      () => {
        reject({});
      }
    );
  } else {
    resolve({});
  }
};

export const vdomCounts = {
  _allVdomCountsByDevName: {},
  _backVdomCountsByDevName: {},
  clear: function () {
    this._allVdomCountsByDevName = {};
    this._backVdomCountsByDevName = {};
  },
};

export const validateBackupSplitVdom = (adomRes, sysConfig) => {
  let errors = {};
  //check backup ADOM device integrity
  if (adomRes.backup === 1) {
    if (
      vdomCounts._allVdomCountsByDevName &&
      Object.keys(vdomCounts._allVdomCountsByDevName).length > 0
    ) {
      // _newmembers only contains newly added scope members
      var _newVdomCountByDevName = countVdomForNewMembers(
        adomRes.newmembers,
        sysConfig
      );
      //parse old members
      var _oldmembers = adomRes.members;
      _oldmembers.forEach(function (omem) {
        if (!isNil(_newVdomCountByDevName[omem.name])) {
          _newVdomCountByDevName[omem.name] += omem.total_vdoms;
        }
      });
      for (var dev in _newVdomCountByDevName) {
        if (
          _newVdomCountByDevName[dev] < vdomCounts._allVdomCountsByDevName[dev]
        ) {
          errors.members = gettext('Cannot split VDOMs to backup ADOM.');
          return errors;
        }
      }
    }
  }

  // check if current ADOM ask for devices from any backup ADOM
  if (
    vdomCounts._backVdomCountsByDevName &&
    Object.keys(vdomCounts._backVdomCountsByDevName).length > 0
  ) {
    var _newVdomCount = countVdomForNewMembers(adomRes.newmembers, sysConfig);
    for (var device in _newVdomCount) {
      if (_newVdomCount[device] < vdomCounts._backVdomCountsByDevName[device]) {
        errors.members = gettext('Cannot split VDOMs to backup ADOM.');
        return errors;
      }
    }
  }
  return errors;
};

export const formCheckPromise = (adomRes, sysConfig) => {
  return new Promise(function (resolve, reject) {
    let msg = '',
      title = '';
    if (adomRes.globaldb) {
      resolve({});
    }

    // popup renaming adom would sync to FAZ warning
    if (
      sysConfig.hasFmgFeature && //for FMG
      fiAdom.isFmgAdomManagingFaz(adomRes) && //for ADOM managing a FAZ
      adomRes.isNew !== 0 && //for editing ADOM
      adomRes.oldName !== adomRes.name
    ) {
      //for changing ADOM name
      msg = gettext(
        'Changing Auto-Sync ADOM name will sync the new ADOM name to the FortiAnalyzer. Are you sure you want to proceed?'
      );
      title = gettext('Changing Auto-Sync ADOM Name');

      openConfirmModal({
        title,
        content: msg,
      }).then(
        function () {
          resolve({});
        },
        function () {
          reject({});
        }
      );
      return;
    }

    // popup storage info warnings if necessary
    if (sysConfig.hasFazFeature) {
      storageInfoWarning(resolve, reject, adomRes);
    } else {
      resolve({});
    }
  });
};

export function displayAdomRef(resp) {
  var data = [];
  resp.forEach(function (r) {
    var obj = {};
    var pWhere = r.url.indexOf('/where used');
    var preIdx = r.url.lastIndexOf('/', pWhere - 1);
    obj.adom = r.url.slice(preIdx + 1, pWhere);
    obj.data = r.data;
    data.push(obj);
  });

  return ProToolkit.openModal(<AdomReference rData={data} />).result;
}

export function validateAdomEdit(values) {
  const _countApprovers = (approvals) => {
    return approvals.reduce((acc, cur) => acc + cur.member.length, 0);
  };
  let sysConfig = getSysConfig();

  let errors = {};
  if (values.globaldb) return errors;

  // Name
  if (!values.name) {
    errors.name = 'This field is required.';
  }
  if (!values.name.match('^[a-zA-Z0-9_-]*$')) {
    errors.name =
      'The name may only contain numbers(0-9), letters(a-z,A-Z) and special characters "-" and "_".';
  }
  if (values.name.length > MACROS.DVM.DVM_NAME_SZ) {
    errors.name = 'Name length exceeds limit';
  }

  // Description
  if (values.description.length > MACROS.DVM.DVM_DESC_SZ) {
    errors.description = 'Description length exceeds limit';
  }

  // Validate workspace approval if necessary
  if (
    values.workspace_mode === 'workflow' &&
    sysConfig.workspace_mode === MACROS.SYS.WORKSPACE_M_PERADOM
  ) {
    if (
      values.approvalData &&
      _countApprovers(values.approvalData.approvalRes.approver) === 0
    ) {
      errors['approval-group'] = gettext(
        'Please create at least one Approval Group.'
      );
    }
  }

  // check storageinfo
  if (sysConfig.hasFazFeature) {
    errors = { ...errors, ...validateStorageInfo(values) };
  }

  // check meta fields
  let metaErrs = [];
  for (var j = 0, len = values.metas.length; j < len; ++j) {
    let meta = values.metas[j];
    if (meta.status && !meta.value && meta.importance) {
      metaErrs[j] = { value: gettext('This meta field is required.') };
    }
  }
  if (metaErrs.length) {
    errors.metas = metaErrs;
  }

  // check backup adom split vdom in advanced mode
  if (sysConfig.isAdomAdvancedMode) {
    // only in ADOM advance mode
    errors = { ...errors, ...validateBackupSplitVdom(values, sysConfig) };
  }

  return errors;
}

const md = MACROS.DVM;
export const ADOM_TYPE_MAP = {
  [md.DVM_RESTRICTED_PRD_FOS]: md.DVM_OS_TYPE_FOS,
  [md.DVM_RESTRICTED_PRD_FOC]: md.DVM_OS_TYPE_FOC,
  [md.DVM_RESTRICTED_PRD_FAZ]: md.DVM_OS_TYPE_FAZ,
  [md.DVM_RESTRICTED_PRD_FML]: md.DVM_OS_TYPE_FML,
  [md.DVM_RESTRICTED_PRD_FSA]: md.DVM_OS_TYPE_FSA,
  [md.DVM_RESTRICTED_PRD_FWB]: md.DVM_OS_TYPE_FWB,
  [md.DVM_RESTRICTED_PRD_FCH]: md.DVM_OS_TYPE_FCH,
  [md.DVM_RESTRICTED_PRD_LOG]: md.DVM_OS_TYPE_LOG,
  [md.DVM_RESTRICTED_PRD_FCT]: md.DVM_OS_TYPE_FCT,
  [md.DVM_RESTRICTED_PRD_FMG]: md.DVM_OS_TYPE_FMG,
  [md.DVM_RESTRICTED_PRD_FSW]: md.DVM_OS_TYPE_FSW,
  [md.DVM_RESTRICTED_PRD_FDD]: md.DVM_OS_TYPE_FDD,
  [md.DVM_RESTRICTED_PRD_FAC]: md.DVM_OS_TYPE_FAC,
  [md.DVM_RESTRICTED_PRD_FNA]: md.DVM_OS_TYPE_FNA,
  [md.DVM_RESTRICTED_PRD_FDC]: md.DVM_OS_TYPE_FDC,
  [md.DVM_RESTRICTED_PRD_FFW]: md.DVM_OS_TYPE_FFW,
  [md.DVM_RESTRICTED_PRD_FWC]: md.DVM_OS_TYPE_FWC,
  [md.DVM_RESTRICTED_PRD_FPX]: md.DVM_OS_TYPE_FPX,
  [md.DVM_RESTRICTED_PRD_FSR]: md.DVM_OS_TYPE_FSR,
  [md.DVM_RESTRICTED_PRD_FSF]: md.DVM_OS_TYPE_FOS, //fabric should follow FOS logic
};
