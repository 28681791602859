import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { fiDevSyntax } from '../syntax/dev_syntax';
import { get } from 'lodash';
import { escapeSlash } from 'kit/kit-regexp';

export const fiDevCfgRequests = {
  global: (cate, abortCtrl) => _wrapper_global(cate, abortCtrl),
  vdom: (cate, abortCtrl) => _wrapper_vdom(cate, abortCtrl),
  device: (dev, vdom, abortCtrl) => _wrapper_dev(dev, vdom, abortCtrl),
  multi_device: _get_all_vdom,
};

export const fiDevInterfaceRequests = {
  sysIntf: fiDevCfgRequests.global('system interface'),
  haIntf: fiDevCfgRequests.global('system ha'),
  virtualSwitch: fiDevCfgRequests.global('system virtual-switch'),
  switchIntf: fiDevCfgRequests.global('system switch-interface'),
  virtualWanLink: fiDevCfgRequests.global('system virtual-wan-link'),
};

function _wrapper_global(cate, abortCtrl) {
  const cate_str = cate;
  return {
    all: (dev, vdom, params) => {
      return _get_global(dev, vdom, cate_str, null, params, abortCtrl);
    },
    get: (dev, vdom, key, params) => {
      return _get_global(dev, vdom, cate_str, key, params, abortCtrl);
    },
    set: (dev, vdom, data) => {
      return _edit_global('set', dev, vdom, cate_str, null, data, abortCtrl);
    },
    setMultiple: (dev, vdom, data) => {
      return _set_multiple(dev, vdom, cate_str, data, abortCtrl);
    },
    add: (dev, vdom, data) => {
      return _edit_global('create', dev, vdom, cate_str, null, data, abortCtrl);
    },
    update: (dev, vdom, key, data) => {
      return _edit_global('update', dev, vdom, cate_str, key, data, abortCtrl);
    },
    del: (dev, vdom, keys) => {
      return _del_global(dev, vdom, cate_str, keys, abortCtrl);
    },
    datasrc: (dev, vdom, attrs, key) => {
      return _globalDS(dev, vdom, cate_str, attrs, key, abortCtrl);
    },
    syntax: (dev) => {
      return fiDevSyntax.get(dev, 'global', cate_str, abortCtrl);
    },
    defaultValues: (dev) => {
      return fiDevSyntax.defaultValues(dev, 'global', cate_str, abortCtrl);
    },
  };
}

function _wrapper_vdom(cate, abortCtrl) {
  const cate_str = cate;
  return {
    all: (dev, vdom, params) => {
      return _get_vdom(dev, vdom, cate_str, null, params, abortCtrl);
    },
    get: (dev, vdom, key, params) => {
      return _get_vdom(dev, vdom, cate_str, key, params, abortCtrl);
    },
    set: (dev, vdom, data) => {
      return _edit_vdom('set', dev, vdom, cate_str, null, data, abortCtrl);
    },
    add: (dev, vdom, data) => {
      return _edit_vdom('create', dev, vdom, cate_str, null, data, abortCtrl);
    },
    update: (dev, vdom, key, data) => {
      return _edit_vdom('update', dev, vdom, cate_str, key, data, abortCtrl);
    },
    del: (dev, vdom, keys, keyAttr) => {
      return _del_vdom(dev, vdom, cate_str, keys, keyAttr, abortCtrl);
    },
    datasrc: (dev, vdom, attrs, key) => {
      return _vdomDS(dev, vdom, cate_str, attrs, key, abortCtrl);
    },
    syntax: (dev, vdom) => {
      return fiDevSyntax.get(dev, vdom, cate_str, abortCtrl);
    },
    defaultValues: (dev, vdom) => {
      return fiDevSyntax.defaultValues(dev, vdom, cate_str, abortCtrl);
    },
  };
}

function _wrapper_dev(devName, vdomName, abortCtrl) {
  const dev = devName;
  const vdom = vdomName;

  return {
    global: (cate) => {
      const cate_str = cate;
      return {
        all: (params) => {
          return _get_global(dev, vdom, cate_str, null, params, abortCtrl);
        },
        get: (key, params) => {
          return _get_global(dev, vdom, cate_str, key, params, abortCtrl);
        },
        set: (data) => {
          return _edit_global(
            'set',
            dev,
            vdom,
            cate_str,
            null,
            data,
            abortCtrl
          );
        },
        setMultiple: (data) => {
          return _set_multiple(dev, vdom, cate_str, data, abortCtrl);
        },
        add: (data) => {
          return _edit_global(
            'create',
            dev,
            vdom,
            cate_str,
            null,
            data,
            abortCtrl
          );
        },
        update: (key, data) => {
          return _edit_global(
            'update',
            dev,
            vdom,
            cate_str,
            key,
            data,
            abortCtrl
          );
        },
        del: (keys) => {
          return _del_global(dev, vdom, cate_str, keys, abortCtrl);
        },
        datasrc: (attrs, key) => {
          return _globalDS(dev, vdom, cate_str, attrs, key, abortCtrl);
        },
        syntax: () => {
          return fiDevSyntax.get(dev, 'global', cate_str, abortCtrl);
        },
        defaultValues: () => {
          return fiDevSyntax.defaultValues(dev, 'global', cate_str, abortCtrl);
        },
      };
    },
    vdom: (cate, abortCtrl) => {
      const cate_str = cate;
      return {
        all: (params) => {
          return _get_vdom(dev, vdom, cate_str, null, params, abortCtrl);
        },
        get: (key, params) => {
          return _get_vdom(dev, vdom, cate_str, key, params, abortCtrl);
        },
        set: (data) => {
          return _edit_vdom('set', dev, vdom, cate_str, null, data, abortCtrl);
        },
        add: (data) => {
          return _edit_vdom('add', dev, vdom, cate_str, null, data, abortCtrl);
        },
        update: (key, data) => {
          return _edit_vdom(
            'update',
            dev,
            vdom,
            cate_str,
            key,
            data,
            abortCtrl
          );
        },
        del: (keys, keyAttr) => {
          return _del_vdom(dev, vdom, cate_str, keys, keyAttr, abortCtrl);
        },
        datasrc: (attrs, key) => {
          return _vdomDS(dev, vdom, cate_str, attrs, key, abortCtrl);
        },
        syntax: () => {
          return fiDevSyntax.get(dev, vdom, cate_str, abortCtrl);
        },
        defaultValues: () => {
          return fiDevSyntax.defaultValues(dev, vdom, cate_str, abortCtrl);
        },
      };
    },
  };
}

// =============== internal functions ===================
/**
 *
 * @param {string} devName
 * @param {string} vdomName
 * @param {string} cate
 * @param {string or object} key the key value of cate or object {attr:'id', val: 2}
 * @param {object} params
 * @param {AbortController} abortCtrl
 */
function _get_global(devName, vdomName, cate, key, params, abortCtrl) {
  params = params || {
    option: [
      'get flags',
      'get used',
      'get devobj mapping',
      'get meta',
      'extra info',
    ],
  };

  if (key) {
    if (typeof key === 'string') {
      params['filter'] = ['name', '==', key];
    } else if (key.attr && key.val) {
      params['filter'] = [key.attr, '==', key.val];
    }
  }

  let req = null;
  if (vdomName == 'global') {
    req = {
      method: 'get',
      params: [
        {
          url: _gen_url_vdom_global(devName, cate),
          ...params,
        },
      ],
    };

    return new Promise((resolve, reject) => {
      fiFmgHttp.forward(req, abortCtrl).then(
        function (resp) {
          resolve(resp[0]);
        },
        function (err) {
          reject(_get_err_status(err.data || err));
        }
      );
    });
  }

  req = {
    url: _gen_url_global(devName, vdomName, cate, key),
    method: 'get',
    params: params,
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      function (resp) {
        resolve(resp[0]);
      },
      function (err) {
        reject(_get_err_status(err));
      }
    );
  });
}

function _del_global(devName, vdomName, cate, keys, abortCtrl) {
  let req = null;

  // current vdom is global
  if (vdomName == 'global') {
    req = {
      method: 'delete',
      params: [
        {
          url: _gen_url_vdom_global(devName, cate),
          confirm: 1,
          filter: keys ? ['name', 'in', keys] : null,
        },
      ],
    };

    return new Promise((resolve, reject) => {
      fiFmgHttp.query(req, abortCtrl).then(
        function (resp) {
          resolve(resp[0]);
        },
        function (err) {
          reject(_get_err_status(err.data || err));
        }
      );
    });
  }

  // current vdom is not global
  req = {
    url: _gen_url_global(devName, vdomName, cate),
    method: 'delete',
  };
  if (keys) {
    req.params = {
      keys: keys,
    };
  }
  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      function (resp) {
        resolve(resp[0]);
      },
      function (err) {
        reject(_get_err_status(err));
      }
    );
  });
}

function _edit_global(act, devName, vdomName, cate, key, data, abortCtrl) {
  let params = { data: data };

  if (key) {
    if (typeof key === 'string') {
      params['filter'] = ['name', '==', key];
    } else if (key.attr && key.val) {
      params['filter'] = [key.attr, '==', key.val];
    }
  }

  let req = null;
  if (vdomName == 'global') {
    req = {
      method: act == 'create' ? 'add' : act,
      params: [
        {
          url: _gen_url_vdom_global(devName, cate),
          ...params,
        },
      ],
    };

    return new Promise((resolve, reject) => {
      fiFmgHttp.query(req, abortCtrl).then(
        function (resp) {
          resolve(resp[0]);
        },
        function (err) {
          reject(_get_err_status(err.data || err));
        }
      );
    });
  }

  req = {
    url: _gen_url_global(devName, vdomName, cate),
    method: act,
    params: params,
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      function (resp) {
        resolve(resp[0]);
      },
      function (err) {
        reject(_get_err_status(err));
      }
    );
  });
}

function _get_err_status(err) {
  const res = get(err, 'result.0') || {
    status: { message: gettext('Response with error.') },
  };
  const data = Array.isArray(res.data) ? res.data[0] : res.data;
  const status = data ? data.status || res.status : res.status;
  return status;
}

function _set_multiple(devName, vdomName, cate, data) {
  if (!Array.isArray(data)) {
    return Promise.reject({
      message: gettext('Data is not Array.'),
    });
  }

  let reqs = [];
  let url = _gen_url_global(devName, vdomName, cate);
  data.forEach((it) => {
    reqs.push({
      url: url,
      method: 'set',
      params: {
        data: it,
      },
    });
  });

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(reqs).then(
      function (resp) {
        resolve(resp);
      },
      function (err) {
        reject(err.result);
      }
    );
  });
}

function _gen_url_global(devName, vdomName, cate) {
  const _vdomName = vdomName || 'root';
  let dev = devName ? escapeSlash(devName) : devName;
  let vdom = _vdomName ? escapeSlash(_vdomName) : _vdomName;
  let adom = fiAdom.current();
  let cate_str = cate.replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, '/'); // Replace space with slash, except for ones in quotes
  cate_str = cate_str.replace(/["']/g, '');
  let url = `/gui/pm/config/global/adom/${adom.name}/device/${dev}/vdom/${vdom}/${cate_str}`;

  return url;
}

function _get_vdom(devName, vdomName, cate, key, params, abortCtrl) {
  params = params || {
    option: [
      'get flags',
      'get used',
      'get devobj mapping',
      'get meta',
      'extra info',
    ],
  };

  if (key) {
    if (typeof key === 'string') {
      params['filter'] = ['name', '==', key];
    } else if (key.attr && key.val) {
      params['filter'] = [key.attr, '==', key.val];
    }
  }

  let req = {
    method: 'get',
    params: [
      {
        url: _gen_url_vdom(devName, vdomName, cate),
        ...params,
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req, abortCtrl).then(
      function (resp) {
        resolve(resp[0]);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

//get same cate for multiple devices
function _get_all_vdom(devices, cate, key, params, abortCtrl) {
  params = params || {
    option: [
      'get flags',
      'get used',
      'get devobj mapping',
      'get meta',
      'extra info',
    ],
  };

  if (key) {
    if (typeof key === 'string') {
      params['filter'] = ['name', '==', key];
    } else if (key.attr && key.val) {
      params['filter'] = [key.attr, '==', key.val];
    }
  }

  let req = {
    method: 'get',
    params: devices.map(({ name, vdom }) => {
      return {
        url: _gen_url_vdom(name, vdom, cate),
      };
    }),
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req, abortCtrl).then(
      function (resp) {
        resolve(resp);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

function _del_vdom(devName, vdomName, cate, keys, keyAttr = 'name', abortCtrl) {
  let req = {
    method: 'delete',
    params: [
      {
        url: _gen_url_vdom(devName, vdomName, cate),
        confirm: 1,
        filter: [keyAttr, 'in', ...keys],
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.query(req, abortCtrl).then(
      function (resp) {
        resolve(resp[0]);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

function _edit_vdom(act, devName, vdomName, cate, key, data, abortCtrl) {
  let params = { data: data };

  if (key && act !== 'set') {
    if (typeof key === 'string') {
      params['filter'] = ['name', '==', key];
    } else if (key.attr && key.val) {
      params['filter'] = [key.attr, '==', key.val];
    }
  }

  let req = {
    method: act,
    params: [
      {
        url: _gen_url_vdom(devName, vdomName, cate),
        ...params,
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.query(req, abortCtrl).then(
      function (resp) {
        resolve(resp[0]);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

function _gen_url_vdom(devName, vdomName, cate) {
  const _vdomName = vdomName || 'root';
  let dev = escapeSlash(devName);
  let vdom = escapeSlash(_vdomName);
  let cate_str = cate.replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, '/'); // Replace space with slash, except for ones in quotes
  cate_str = cate_str.replace(/["']/g, '');
  let url = `pm/config/device/${dev}/vdom/${vdom}/${cate_str}`;

  return url;
}

function _gen_url_vdom_global(devName, cate) {
  let dev = escapeSlash(devName);
  let cate_str = cate.replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, '/'); // Replace space with slash, except for ones in quotes
  cate_str = cate_str.replace(/["']/g, '');
  let url = `pm/config/device/${dev}/global/${cate_str}`;

  return url;
}

/**
 * @param {sting} devName device name string
 * @param {string} vdomName query device global or vdom
 * @param {string} cate category name, e.g. 'firewall address'
 * @param {Array} attrs array of attribute for query
 * @param {string} key optional, only used for child table, which parent table key
 * {
 *   attr: 'member',
 *   fields: ['name'],
 *   current_adom: 'root',
 *   ....
 * }
 * @returns {Promise} return given interface data or list of interface in the vdom
 */
function _vdomDS(devName, vdomName, cate, attrs, key, abortCtrl) {
  const _vdomName = vdomName || 'root';
  let dev = escapeSlash(devName);
  let vdom = escapeSlash(_vdomName);
  let url = `pm/config/device/${dev}/vdom/${vdom}/${cate.replace(/ +/g, '/')}`;
  let params = [];
  attrs = attrs || [];
  attrs = Array.isArray(attrs) ? attrs : [attrs];

  if (key) {
    url += '/' + key.replace(/\//g, '\\/');
  }

  attrs.forEach((it) => {
    let p = Object.assign(it, {
      url: url,
      option: 'datasrc',
    });

    params.push(p);
  });

  let req = {
    method: 'get',
    params: params,
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req, false, abortCtrl).then(
      function (resp) {
        resolve(resp);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

function _globalDS(devName, vdomName, cate, attrs, key, abortCtrl) {
  let dev = escapeSlash(devName);
  let url = `pm/config/device/${dev}/global/${cate.replace(/ +/g, '/')}`;
  let params = [];

  if (key) {
    url += '/' + key.replace(/\//g, '\\/');
  }

  attrs = Array.isArray(attrs) ? attrs : [attrs];

  attrs.forEach((it) => {
    let p = Object.assign(it, {
      url: url,
      option: 'datasrc',
    });

    params.push(p);
  });

  let req = {
    method: 'get',
    params: params,
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req, abortCtrl).then(
      function (resp) {
        //result is an array, may contain many items in the array
        resolve(resp);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

export const loadDvmConfigDataByCategory = async ({
  key,
  reactCfgType,
  configRootDataBag,
}) => {
  const { getSelectedDevice, getVdomName } = configRootDataBag;

  const cateStr = Array.isArray(reactCfgType.cate)
    ? reactCfgType.cate[0]
    : reactCfgType.cate;
  const isGlobalCate = reactCfgType.scope === 'global';
  const device = getSelectedDevice();
  let _vdom = isGlobalCate ? 'global' : getVdomName();
  const fn = fiDevCfgRequests.device(device.name, _vdom, undefined);
  const query = isGlobalCate ? fn.global(cateStr) : fn.vdom(cateStr);
  try {
    const loader = key ? query.get(key) : query.all();
    const resp = await loader;
    return resp.data;
  } catch {
    return [];
  }
};
