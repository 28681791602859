import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { isUndefined } from 'lodash';
import { fiDeviceDataLoader } from './device_data_loader';
import { deviceStatus } from 'fi-dvm';
import { isGlobalDomain, isVdomDomain } from './util';
import { escapeSlash } from 'kit/kit-regexp';

export { createLoadDSObj, getDataSource };

const createLoadDSObj = (cate, attr, attrObj, syntax, childPath) => {
  return {
    prop: attr,
    node: {
      nameArray: childPath
        ? [syntax[cate].cate, ...childPath]
        : [syntax[cate].cate],
      dataSyntax: syntax[cate],
    },
    obj: attrObj,
  };
};

function getDataSource(attr, device, vdom, data, abortCtrl) {
  return new Promise((resolve, reject) => {
    let deviceName = device ? device.name || device : device;
    let vdomName = vdom;
    let attrObj = Array.isArray(attr) ? attr : [attr];
    const currentDev = fiDeviceDataLoader.getDeviceByName(deviceName);
    const isDeviceNoVdom = deviceStatus.isDeviceNoVdom(currentDev?.rtype);

    let _currentAdom = '';
    try {
      _currentAdom = fiAdom.current().name;
    } catch (e) {
      //
    }

    let domain = attr.node ? attr.node.dataSyntax.domain : undefined;
    let url = 'pm/config';
    if (device) {
      url += '/device/' + escapeSlash(deviceName);

      if (!vdomName && isVdomDomain(domain)) {
        url += '/vdom/root';
      } else {
        if (isGlobalDomain(domain)) {
          url += '/global';
        } else {
          //vdom disabled
          if (isDeviceNoVdom) {
            url += '/vdom/root';
          } else {
            url += vdomName ? '/vdom/' + vdomName : '/global';
          }
        }
      }
    } else if (_currentAdom) {
      fiAdom.isGlobalAdom(_currentAdom)
        ? (url += '/global')
        : (url += '/adom/' + _currentAdom);
      url += '/obj';
    } else {
      return Promise.reject(gettext('No Device/ADOM'));
    }

    let params = [];

    // apend attr to url
    let dsObj = attrObj[0];
    let useCustomizedAttr = {
      url: undefined,
      isParentTable:
        ['obj', 'table'].includes(dsObj.node.dataSyntax.type) ||
        !isUndefined(dsObj.node.dataSyntax.fi_subobjField),
    };
    let path = dsObj.node.nameArray[0];
    if (Array.isArray(path)) {
      path = path[0];
    }
    let pathArr = path.split(' ');
    for (let n = 0; n < pathArr.length; n++) {
      url += '/' + pathArr[n];
    }
    for (let k = 1; k < dsObj.node.nameArray.length; k++) {
      if (useCustomizedAttr.isParentTable) {
        k === 1
          ? (useCustomizedAttr.url = dsObj.node.nameArray[k])
          : (useCustomizedAttr.url += '/' + dsObj.node.nameArray[k]);
      } else {
        url += '/' + dsObj.node.nameArray[k];
      }
    }

    const param = {
      url: url,
      option: 'datasrc',
      attr: useCustomizedAttr.url
        ? useCustomizedAttr.url + '/' + dsObj.prop
        : dsObj.prop,
      current_adom: _currentAdom, //server side will return 'global data' if there is adom-data
    };

    if (data) {
      param.data = data;
    }

    params.push(param);

    let req = {
      method: 'get',
      params: params,
    };

    return fiFmgHttp.forward(req, false, abortCtrl).then(
      function (resp) {
        if (resolve) {
          resolve(resp);
        }
      },
      function (err) {
        if (reject) {
          reject(err);
        }
      }
    );
  });
}
