import { fiAdom } from 'fi-session';
import { escapeHtml } from 'kit-escape';
import { formatAdomTime } from 'fi-datetime';
import { isNumber } from 'lodash';

export const _currentAdom = () => fiAdom.current();

export function _hasDirty(data = {}) {
  for (let prop in data) {
    if (data[prop].dirty) {
      return true;
    }
  }
  return false;
}
export function _hasDirtyByMe(data = {}) {
  for (let prop in data) {
    if (data[prop].lock == MACROS.SYS.LOCK_STATE_LOCKED && data[prop].dirty) {
      return true;
    }
  }
  return false;
}
export function _isDirty(data) {
  return data ? !!data.dirty : false;
}
export function _isDirtyByMe(data) {
  return data ? data.lock == MACROS.SYS.LOCK_STATE_LOCKED && data.dirty : false;
}
export function _hasLocked(data = {}) {
  for (let prop in data) {
    if (
      data[prop].lock == MACROS.SYS.LOCK_STATE_LOCKED ||
      data[prop].lock == MACROS.SYS.LOCK_STATE_BY_OTHER
    ) {
      return true;
    }
  }
  return false;
}

export function _hasLockedByMe(data = {}) {
  for (let prop in data) {
    if (data[prop].lock == MACROS.SYS.LOCK_STATE_LOCKED) {
      return true;
    }
  }
  return false;
}

export function _hasLockedByOther(data = {}) {
  for (let prop in data) {
    if (data[prop].lock == MACROS.SYS.LOCK_STATE_BY_OTHER) {
      return true;
    }
  }
  return false;
}
export function _isLockedByMe(data, adom = _currentAdom()) {
  if (adom.workspace_mode === 'disabled') {
    return true;
  }

  return data ? data.lock == MACROS.SYS.LOCK_STATE_LOCKED : false;
}
export function _isLockedByOther(data) {
  return data ? data.lock == MACROS.SYS.LOCK_STATE_BY_OTHER : false;
}
export function _isLocked(data, adom = _currentAdom()) {
  if (adom.workspace_mode === 'disabled') {
    return true;
  }

  return data
    ? data.lock == MACROS.SYS.LOCK_STATE_BY_OTHER ||
        data.lock == MACROS.SYS.LOCK_STATE_LOCKED
    : false;
}

export function _isUnlock(data) {
  return data ? data.lock == MACROS.SYS.LOCK_STATE_UNLOCKED : true;
}

export function _getLockMsg(lock) {
  let txt = '';
  if (lock.lock == MACROS.SYS.LOCK_STATE_LOCKED) {
    txt = gettext('Locked by me at %(time)s').printfd({
      time: getLockTimeStr(lock),
    });
  } else if (lock.lock == MACROS.SYS.LOCK_STATE_BY_OTHER) {
    txt = gettext('Locked by %(admin)s at %(time)s').printfd({
      admin: escapeHtml(lock.lock_user) || '',
      time: getLockTimeStr(lock),
    });
  }
  return txt;
}

export function _selector_many(data, adom) {
  return data && data[adom.oid || adom]
    ? data[adom.oid || adom].byId || {}
    : {};
}

/**
 * for adom objects that are included in workflow db
 * @param {any} props same props from fiWorkspace.bindAdomUpdateFn fisrt argument
 * @param {any} prev same prev from fiWorkspace.bindAdomUpdateFn third argument
 * @param {ayn} adom or current adom
 */
export function dataWillbeDifferent(props, prev, adom = fiAdom.current()) {
  if (!prev) return false; // lock adom will not casue any data difference

  const current = props.get(adom);
  prev = prev.get(adom);

  if (current.isNormalMode(adom)) {
    //is normal workspace
    if (
      prev.isDirty() &&
      prev.isLockedByMe() && //I had some changes
      !current.isLockedByMe() //discarded by me/others
    ) {
      return true;
    }
  } else if (current.isWorkflow(adom)) {
    //is workflow mode
    if (
      (!prev.workflowSession() &&
        current.isLockedByMe() &&
        current.workflowSession()) || //I enter workflow session
      (prev.workflowSession() &&
        prev.isLockedByMe() &&
        !current.workflowSession()) //I leave workflow session
    ) {
      return true;
    }
  }
  return false;
}

export function getLockTimeStr(lock) {
  if (!lock) return '';
  return isNumber(lock.lock_time)
    ? formatAdomTime({
        unix: lock.lock_time,
        format: 'YYYY-MM-DD HH:mm:ss z',
      })
    : lock.lock_time;
}
