import { combineReducers } from 'redux';
import * as deviceSlice from './device/slice';
import * as deviceSelectors from './device/selectors';

export const socFabricReducer = combineReducers({
  device: deviceSlice.default,
});

export const socFabricDuck = {
  device: {
    actions: deviceSlice,
    selectors: deviceSelectors,
  },
};
