import { fiFmgHttp } from 'fi-http';
import { findIndex, get } from 'lodash';
import {
  fiDeviceDBFetcher,
  fiDeviceDataFetcher,
  fiDeviceDataLoader,
} from 'ra_device_util';

// used in AdomEdit
export const getAdomData = (adomId) => {
  return fiFmgHttp
    .post({
      id: 10,
      method: 'get',
      url: `/gui/adoms/${adomId}`,
      params: {
        includeMembers: true,
      },
    })
    .then((resp) => {
      try {
        const result = resp[0];
        if (result.status.code == 0 || result.status.code == 200) {
          return result.data || {};
        } else {
          throw result.status.message;
        }
      } catch (e) {
        return {};
      }
    });
};

export const getAdomTypes = () => {
  return fiFmgHttp
    .post({
      id: 10,
      method: 'get',
      url: '/gui/alladom/types',
      params: {},
    })
    .then((resp) => {
      try {
        const result = resp[0];
        if (result.status.code == 0 || result.status.code == 200) {
          return result.data || [];
        } else {
          throw result.status.message;
        }
      } catch (e) {
        return [];
      }
    });
};

export const getAdomTemplate = () => {
  return fiFmgHttp
    .forward({
      method: 'get',
      params: [
        {
          url: '/dvmdb/adom',
          'object template': 1,
        },
      ],
    })
    .then((resp) => resp?.[0] || {});
};
export const setAdomData = ({ tid, adomRes, isCreateNew }) =>
  fiFmgHttp.post({
    id: tid,
    method: isCreateNew ? 'create' : 'edit',
    url: `/gui/adoms/${adomRes.adomId || adomRes.oid || ''}`,
    params: adomRes,
  });
export const resetAdom = ({ tid, adomRes }) =>
  fiFmgHttp.post({
    id: tid,
    method: 'reset_global',
    url: `/gui/adoms/${adomRes.adomId || adomRes.oid || ''}`,
    params: adomRes,
  });

// used in DeviceSelectPane
export const getAllAdomsDevicesNonCsf = () =>
  fiDeviceDataFetcher.getAllAdomsDevicesNonCsf();

export const getTimezones = () =>
  fiFmgHttp.post({
    method: 'getTimezones',
    url: '/gui/sys/dashboard',
  });

// used in AdomDeciveTable
export const reloadDevices = (adomRes) =>
  fiDeviceDBFetcher.fetchFullDeviceList(adomRes.oid, true);
export const getDevices = (adomId) => fiDeviceDataLoader.getDevices(adomId);

// used in AdomFazEdit
export const getDiskQuota = () => {
  const req = {
    method: 'get',
    url: '/gui/sys/quota',
    params: {},
  };
  return fiFmgHttp.post(req).then((resp) => get(resp, '0.data', {}));
};

export const setAdomUserData = ({ tid, adomRes }) =>
  fiFmgHttp.post({
    id: tid,
    method: 'edit',
    url: `/gui/adom_user/adoms/${adomRes.adomId || adomRes.oid || ''}`,
    params: adomRes,
  });

export const getAdomUserData = ({ adomId, method = 'get' }) =>
  fiFmgHttp
    .post({
      method: method,
      url: `/gui/adom_user/adoms/${adomId || ''}`,
    })
    .then((resp) => {
      return resp?.[0]?.data;
    });

// used in AdomCommonEdit
export const getNextVerStr = (versions, ver, mr) => {
  if (ver == 4 && mr == 3) return '5.0'; //hard code 4.3 upgrade target
  const next = findNext(versions, ver, mr);
  if (!next) return '';
  return next.verlabel + '.' + next.mr;
};

const findNext = function (versions, ver, mr) {
  const foundIdx = findIndex(versions, ['ver', ver]);
  if (foundIdx === -1) return null;
  const verObj = versions[foundIdx];
  const mrs = verObj.mrs;
  const findNextMr = function (mr) {
    const len = mrs.length;
    for (let i = 0; i < len; i++) {
      if (mrs[i] != mr) continue;
      if (i == len - 1) return null;
      return mrs[i + 1];
    }
    return null;
  };

  let nextMr = 0;
  if ((nextMr = findNextMr(mr)) !== null) {
    return { ver: verObj.ver, verlabel: verObj.verlabel, mr: nextMr };
  }

  const nextVerObj = versions[foundIdx + 1];
  if (!nextVerObj) return null;

  return {
    ver: nextVerObj.ver,
    verlabel: nextVerObj.verlabel,
    mr: nextVerObj.mrs[0],
  };
};

// used in AllAdoms
export const upgradeAdoms = (adoms) => {
  return fiFmgHttp.query({
    id: 1,
    method: 'exec',
    params: [
      {
        url: '/dvmdb/_upgrade',
        data: {
          'scope member': adoms.map(({ oid }) => ({ oid: +oid })),
        },
      },
    ],
  });
};

export const getAdomUpgradingInfos = (adoms) =>
  fiFmgHttp
    .forward({
      id: 1,
      method: 'get',
      params: [
        {
          url: '/dvmdb/_upgrade',
          data: {
            'scope member': adoms
              .filter((adom) => !adom.isGroup)
              .map(({ oid }) => ({ oid: +oid })),
          },
        },
      ],
    })
    .then((resp) => resp[0]?.data ?? [])
    .catch(() => []);
