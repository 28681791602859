import { useField } from 'formik';
import { renderIcon } from 'ra-render-util';
import { callAll, getAutoId } from './util/util';
import { isFunction, isUndefined } from 'fiutil';
import { NwIcon } from '@fafm/neowise-core';

export const FmkBtnGroup = ({
  name,
  choices = [],
  'automation-id': propAutoId,
  automationId,
  onChange,
  disabled: propDisabled,
  // eslint-disable-next-line
  ...rest
}) => {
  const [, { value }, { setValue, setTouched }] = useField(name);

  const updateValue = ({ id }) => {
    setTouched(true, false);
    setValue(id);
  };

  const onBtnClick = (clickedOpt) => {
    if (clickedOpt.id !== value) {
      callAll(updateValue, onChange)(clickedOpt);
    }
  };

  function isOptionDisabled(opt) {
    const checkDisabled = opt.disabled || propDisabled;
    if (isUndefined(checkDisabled)) return;
    const disabled = isFunction(checkDisabled)
      ? checkDisabled(opt)
      : checkDisabled;
    if (disabled) {
      return true;
    }
  }

  return (
    <nw-button-group>
      {choices.map((opt) => {
        const { id, text, icon, title, autoId } = opt;
        return (
          <nw-button
            key={id}
            onClick={() => onBtnClick(opt)}
            type={value === id || value?.val === id ? 'primary' : 'default'}
            automation-id={getAutoId(propAutoId || automationId, autoId || id)}
            title={title}
            disabled={isOptionDisabled(opt)}
            {...rest}
          >
            {icon ? renderIcon(icon) : null}
            {text}
          </nw-button>
        );
      })}
    </nw-button-group>
  );
};
FmkBtnGroup.displayName = 'FmkBtnGroup';

export const FmkDisableEnableBtnGroup = (props) => {
  const choices = [
    {
      id: MACROS.PM2CAT.PM2_OPT_ENABLE,
      text: gettext('Enabled'),
      icon: (
        <NwIcon
          name={'enable'}
          className={'tw-mr-1'}
          label={gettext('Enabled')}
          style={{
            color: 'rgb(var(--nw-color-success))',
          }}
        />
      ),
    },
    {
      id: MACROS.PM2CAT.PM2_OPT_DISABLE,
      text: gettext('Disabled'),
      icon: (
        <NwIcon
          name={'disable'}
          className={'tw-mr-1'}
          label={gettext('Disabled')}
          style={{
            color: 'rgb(var(--nw-color-danger))',
          }}
        />
      ),
    },
  ];

  return <FmkBtnGroup choices={choices} {...props} />;
};
