import { fiWorkspace } from 'fi-workspace';
import { openConfirmModal } from 'rc_layout';

export { logoutCheckWorkspace };

const checkWorkspaceBeforeLogout = async ({ allowCancel }) => {
  const resp = await fiWorkspace.checkWorkspaceDirty();
  if (!resp.data || !resp.data.length) return;

  const { dirty, isDirty } = checkIsDirtyByMe({ data: [] });
  if (!isDirty) return;

  return await openWorkspaceLogoutModal({ dirty, allowCancel });
};

const logoutCheckWorkspace = async ({
  allowCancel = true,
  logout = () => {},
}) => {
  if (fiWorkspace.isWorkspaceDisabled()) {
    return logout();
  }

  try {
    await checkWorkspaceBeforeLogout({ allowCancel });
    logout();
  } catch {
    return;
  }
};

const checkIsDirtyByMe = (resp) => {
  let data = resp.data;
  let dirty = { total: 0, byType: {} };

  data.reduce((acc, curr) => {
    if (fiWorkspace.isDirtyByMe(curr)) {
      acc.total++;
      if (!acc.byType[curr.type]) {
        acc.byType[curr.type] = 0;
      }
      acc.byType[curr.type]++;
    }
    return acc;
  }, dirty);

  if (!dirty.total) {
    // if nothing dirty
    return { dirty, isDirty: false };
  }

  return { dirty, isDirty: true };
};

const openWorkspaceLogoutModal = async ({ dirty, allowCancel }) => {
  let buttons = [
    {
      label: gettext('Save and Logout'),
      type: 'primary',
      automationId: 'save_and_logout',
      click: ($opener) => {
        fiWorkspace.saveAll(true);
        return $opener.resolve();
      },
    },
    {
      label: gettext('Discard and Logout'),
      type: 'primary',
      automationId: 'discard_and_logout',
      click: ($opener) => {
        fiWorkspace.unlockAll(true, true);
        return $opener.resolve();
      },
    },
  ];

  if (allowCancel) {
    buttons = [
      ...buttons,
      {
        label: gettext('Cancel'),
        type: 'default',
        automationId: 'cancel',
        click: ($opener) => {
          $opener.resolve();
        },
      },
    ];
  }

  return openConfirmModal({
    title: gettext('Logout Confirmation'),
    content: () => {
      return <WorkSpaceLogoutContent dirty={dirty} />;
    },
    buttons,
  });
};

const WorkSpaceLogoutContent = ({ dirty }) => {
  const unsavedText = gettext(
    'You have %(number)s unsaved changes. Do you want to continue?'
  ).printfd({ number: dirty.total });

  const renderDirty = () => {
    return Object.keys(dirty.byType).map((type) => {
      let text = '';

      if (type === MACROS.SYS.WORKSPACE_DEVICE) {
        text = `${dirty.byType[type]} in Device(s)`;
      } else if (type === MACROS.SYS.WORKSPACE_CATE_OBJECT) {
        text = `${dirty.byType[type]} in Policy Object(s)`;
      } else if (type === MACROS.SYS.WORKSPACE_ADOM) {
        return `${dirty.byType[type]} in ADOM(s)`;
      } else {
        return `${dirty.byType[type]} in Policy Package(s)`;
      }

      return <li key={type}>{text}</li>;
    });
  };

  return (
    <div className={'tw-w-full tw-h-full tw-flex tw-flex-col'}>
      <p>{unsavedText}</p>

      <ul>{renderDirty()}</ul>
    </div>
  );
};
