import { fiStore, fiStoreAdomLocalConfig, fiStoreConnector } from 'fistore';
import * as fp from '@fafm/fp';

//current assets & folder tree view

/**
 * args[0]: stateLice: {folderTree: ...}
 * args[1]: dispatch: {dispatch: disptachFn}
 * args[3]: preStateSlice
 * @param {*} cbFn function passed from caller
 */
const folderUpdateTarget =
  (cbFn) =>
  (...args) =>
    cbFn(...args);

//===================
// Public Interface
//===================
export const fiFoldersRedux = {
  bindFoldersStoreFn: (fn) => {
    //wrap target(StateSlice, dispatch, prevStateSlice); dispatch: {dispatch: func}
    return fiStoreConnector(foldersMapStateToProps)(folderUpdateTarget(fn));
  },
  composeNewFolderTreeState: (getExpandedIds) =>
    fp.compose(
      (preState) => ({
        ...preState,
        expandedIds: getExpandedIds(preState.expandedIds),
      }),
      (currentFoldersPath) => ({
        ...fiFoldersRedux.getFolderTreeStore(),
        currentFoldersPath,
      })
    ),
  getFolderTreeStore: () => {
    //always return a copy of the state to prevent direct modifying from state object
    let slice = fiStoreAdomLocalConfig.getFolderVTree(fiStore.getState());
    return { ...slice };
  },
  updateExpandedIds: (expandedIds) =>
    fiFoldersRedux.dispatchActionTreeStore({
      ...fiFoldersRedux.getFolderTreeStore(),
      expandedIds,
    }),
  getAssestStoreData: () =>
    fiStoreAdomLocalConfig.getCurrentAssest(fiStore.getState()),
  dispatchActionTreeStore: (payload) =>
    fiStore.dispatch(fiStoreAdomLocalConfig.setFolderVTree(payload)),
  dispatchActionAssest: (payload) =>
    fiStore.dispatch(fiStoreAdomLocalConfig.setCurrentAsset(payload)),
  dispatchAsset,
};

function foldersMapStateToProps(state) {
  return {
    folderTree: fiStoreAdomLocalConfig.getFolderVTree(state),
  };
}

function dispatchAsset(key, value) {
  let currentAssetPayload = {}; //always reset
  currentAssetPayload[key] = value;
  //we just modify the state here, the fi_store reducers will be responsible for creating new data object
  fiFoldersRedux.dispatchActionAssest(currentAssetPayload);
}
