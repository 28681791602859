import $ from 'jquery';

export function getFontSize() {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
}

// https://stackoverflow.com/questions/36532307/rem-px-in-javascript
export function convertRemToPx(rem) {
  return rem * getFontSize();
}

export function convertPxToRem(px) {
  return px / getFontSize();
}

export function getGlobalScrollbarInfo(prop) {
  const scrollbarStyles = getComputedStyle(
    document.body,
    '::-webkit-scrollbar'
  );
  return prop ? scrollbarStyles.getPropertyValue(prop) : scrollbarStyles;
}

export function elementHasScrollbar(element) {
  return (
    element instanceof HTMLElement &&
    element.scrollHeight > element.clientHeight
  );
}

// https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font =
    font || getComputedStyle(document.body).getPropertyValue('font');
  const metrics = context.measureText(text);
  return metrics.width;
}

export function getParentContainer(el) {
  if (!el) return null;

  const contentEl = $(el);
  try {
    // Within ProToolkit modal or drawer
    const modal = contentEl.closest('.np-toolkit-modal');
    const drawer = contentEl.closest('.np-toolkit-drawer');
    const parentContainer = modal.length ? modal : drawer;

    return parentContainer.get(0);
  } catch (err) {
    return null;
  }
}
