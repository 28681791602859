import { devicesAssignedPkgAction } from './actions';

import { createReducer, pathReducer } from '../../utils/reducer_util';

const initialState = {
  loaded: false,
  loading: false,
  byId: {},
};

const _assignedPkgReducer = createReducer(
  {
    [devicesAssignedPkgAction.fetch.START]: (state = initialState) => {
      return {
        ...initialState,
        ...state,
        loaded: false,
        loading: true,
      };
    },
    [devicesAssignedPkgAction.fetch.SUCCESS]: (state, action) => {
      return {
        byId: action.payload,
        loaded: true,
        loading: false,
      };
    },
    [devicesAssignedPkgAction.fetch.FAILURE]: (state) => {
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    },
  },
  initialState
);

export const assignedPkgReducer = pathReducer(_assignedPkgReducer);
