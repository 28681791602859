import { fiFmgHttp, getDetailErrorMsg } from 'fi-http';
import { genv4 } from 'fi-uuid';
import { fiAdom } from 'fi-session';
import { fiErrorMessage, fiMessageBox } from 'fi-messagebox';
import { isEmpty, isNumber, isString } from 'lodash';
import { longBitwiseAnd, longBitwiseOr, toLong } from 'kit/kit-number';

export const getAdomObjectMembers = (adom) => {
  return fiFmgHttp
    .forward({
      id: genv4(),
      method: 'get',
      params: [
        {
          url: `/dvmdb/adom/${adom}`,
          option: 'object member',
        },
      ],
    })
    .then((resp) => {
      return resp?.[0]?.data?.['object member'] || [];
    });
};

export const getAllManagedFaz = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/adom/dvm/managedfaz',
    })
    .then((resp) => {
      return resp?.[0]?.data || [];
    });
};

export const getDeviceInfo = (deviceName) => {
  const adom = fiAdom.current().name;
  let params = [
    {
      url: '/dvmdb/adom/' + adom + '/device/' + deviceName,
      option: ['get meta'],
    },
  ];

  return fiFmgHttp.forward({
    id: 1,
    method: 'get',
    params: params,
  });
};

const updateDeviceInfo = (params) => {
  return fiFmgHttp.query({
    id: 1,
    method: 'update',
    params: params,
  });
};

export const reclaimFGFMTunnels = (params) => {
  return fiFmgHttp.query({
    id: 1,
    method: 'exec',
    params,
  });
};

const genUpdateDevInfoParams = (deviceName, deviceData) => {
  const adom = fiAdom.current().name;
  return {
    url: '/dvmdb/adom/' + adom + '/device/' + deviceName,
    data: deviceData,
  };
};

export const genReclaimTunnelParams = (deviceOid) => {
  return {
    url: 'fgfm/del/tunnel',
    data: [
      {
        device: parseInt(deviceOid, 10),
      },
    ],
  };
};

// should only be called with model device
// device obj needs flags
// if enabling, also call API to reclaim FGFM tunnel in order to push config immediately
export const setAutolink = async (devs = [], isDisable = false) => {
  try {
    let updateParams = [];
    let tunnelParams = [];

    for (const dev of devs) {
      let flags = dev.flags;
      if (!isNumber(flags) || !dev.model_dev) continue;

      if (isDisable) {
        flags &= ~MACROS.DVM.DVM_DEV_FLAG_LINKED_TO_MODEL;
      } else {
        flags |= MACROS.DVM.DVM_DEV_FLAG_LINKED_TO_MODEL;
      }

      updateParams.push(genUpdateDevInfoParams(dev.name, { flags }));

      // only need to reclaim tunnels for the devices with tunnel up
      if (
        dev.conn_status === MACROS.DVM.DVM_CONN_STATUS_UP ||
        dev.conn?.status === MACROS.DVM.DVM_CONN_STATUS_UP
      ) {
        tunnelParams.push(genReclaimTunnelParams(dev.oid));
      }
    }

    await updateDeviceInfo(updateParams);

    if (!isDisable && !isEmpty(tunnelParams))
      await reclaimFGFMTunnels(tunnelParams);

    if (isDisable) {
      fiMessageBox.show(
        gettext('Successfully disabled Auto-link'),
        'success',
        5000
      );
    } else {
      fiMessageBox.show(
        gettext('Successfully enabled Auto-link'),
        'success',
        5000
      );
    }
  } catch (e) {
    let message;
    if (isString(e)) message = e;
    else {
      const _m = isDisable
        ? gettext('Error disabling Auto-link')
        : gettext('Error enabling Auto-link');

      message = e?.data?.result?.[0]?.status?.message || _m;
    }
    fiMessageBox.show(message, 'danger', 0);
  }
};

export const setSdwanManagement = async ({ devs = [], isEnable = true }) => {
  try {
    const updateParams = [];

    for (const dev of devs) {
      let flags = dev.flags;
      if (!isNumber(flags)) continue;

      let newFlagsLong = toLong(flags);
      const sdwanManagementFlagLong = toLong(
        MACROS.DVM.DVM_DEV_FLAG_SDWAN_MGMT
      );

      if (isEnable) {
        newFlagsLong = longBitwiseOr(newFlagsLong, sdwanManagementFlagLong);
      } else {
        newFlagsLong = longBitwiseAnd(
          newFlagsLong,
          sdwanManagementFlagLong.not()
        );
      }

      updateParams.push(
        genUpdateDevInfoParams(dev.name, { flags: newFlagsLong.toNumber() })
      );
    }

    await updateDeviceInfo(updateParams);

    if (isEnable) {
      fiMessageBox.show(gettext('Successfully enabled SD-WAN Management'));
      return;
    }

    fiMessageBox.show(gettext('Successfully disabled SD-WAN Management'));
  } catch (err) {
    let message;
    if (isString(err)) message = err;
    else {
      const defaultErrMsg = isEnable
        ? gettext('Error enabling SD-WAN Management')
        : gettext('Error disabling SD-WAN Management');

      message = getDetailErrorMsg(err, defaultErrMsg);
    }
    fiErrorMessage.show(message);
  }
};
