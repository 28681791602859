import postRobot from '@fafm/post-robot';
import { isNil, isPlainObject, isUndefined } from 'lodash';
import { fiSysConfig } from 'fi-session';

const boundListeners = {};

// get target domain, default is to
const _getTargetDomain = (target, iframe) => {
  const ms = MACROS.USER.SYS;
  let domain = fiSysConfig.current().mapserver_url || ms.MAP_SERVER_HOST;
  let childId = ms.GMAP_CHILD_IFRAME;
  if (target === ms.WEB_CONSOLE_HOST) {
    domain = document.location.origin;
    childId = ms.CONSOLE_CHILD_IFRAME;
  }
  return {
    domain,
    window: iframe || document.getElementById(childId)?.contentWindow || window,
  };
};

const callFn = (fnName, data, target, iframeId, iframe) => {
  let child = iframe;
  if (!child) {
    let childId = iframeId || MACROS.USER.SYS.GMAP_CHILD_IFRAME;
    child = document.getElementById(childId)
      ? document.getElementById(childId).contentWindow
      : null;
  }
  if (child) {
    // eslint-disable-next-line
    return postRobot.send(child, fnName, data, _getTargetDomain(target, child));
  }
};

const _getListenerTemplate = () => {
  return {
    handler: null,
    count: 0,
    registeredListeners: {},
  };
};

const _registerPRListener = (fnName, fn, target, iframe) => {
  let unregFn = postRobot.on(
    fnName,
    _getTargetDomain(target, iframe),
    (event, callback) => {
      return fn(event.data, callback);
    }
  );
  return unregFn;
};

const registerListenerFn = (featureName, listener, target, iframe) => {
  if (isUndefined(boundListeners[featureName])) {
    boundListeners[featureName] = _getListenerTemplate();
  }
  if (
    boundListeners[featureName].count === 0 &&
    !boundListeners[featureName].handler
  ) {
    boundListeners[featureName].handler = listener; //save the event handler reference
    // register all the listeners
    if (typeof listener === 'function') {
      boundListeners[featureName].registeredListeners[featureName] =
        _registerPRListener(featureName, listener, target, iframe);
    } else if (typeof listener === 'object') {
      for (let fnName in listener) {
        boundListeners[featureName].registeredListeners[fnName] =
          _registerPRListener(fnName, listener[fnName], target, iframe);
      }
    }
  }
  boundListeners[featureName].count++;
};

function detach(featureName) {
  if (!isNil(boundListeners[featureName])) {
    boundListeners[featureName].count--;
    if (isPlainObject(boundListeners[featureName].handler)) {
      for (let fnName in boundListeners[featureName].registeredListeners) {
        boundListeners[featureName].registeredListeners[fnName].cancel();
      }
      boundListeners[featureName].registeredListeners = {};
      boundListeners[featureName].handler = null;
    }
  }
}

export const postRobotService = {
  callFn: callFn,
  registerListenerFn: registerListenerFn,
  detach: detach,
};
