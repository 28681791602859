import { take, put, call, takeLatest } from 'redux-saga/effects';
import { fetchSessionAdom } from 'fistore/session/adom/slice';
import { callPromiseAction } from '../utils/saga-promise';
import { fetchRemoteFazModuleDisable } from './api';
import * as actions from './actions';
import { runIfAvailable } from '../routing/saga';
import { hasOrAttachedFaz } from 'fistore/session/sysConfig/selectors';
import { appInitDone } from 'fistore/routing/slice';

// only run if has faz feature or has attached faz after system intialized
const fetchFmgRemoteFaz = runIfAvailable(
  hasOrAttachedFaz,
  function* _fetchFmgRemoteFaz() {
    const modResp = yield call(fetchRemoteFazModuleDisable);
    yield put(actions.setRemoteFazModuleDisable(modResp));
  }
);

export function* watchFmgRemoteFaz() {
  yield takeLatest(
    actions.fetchRemoteFazModuleDisable.type,
    function* (action) {
      yield callPromiseAction(action, fetchFmgRemoteFaz);
    }
  );
  // also do it after every adom switch
  // module-disable status may come from remote faz.
  yield takeLatest(fetchSessionAdom.fulfilled.type, fetchFmgRemoteFaz);
  // run it after system init
  yield take(appInitDone);
  yield call(fetchFmgRemoteFaz);
}
