import { fiFmgHttp } from 'fi-http';
import { fiStore, fiSession } from 'fistore';

async function set(hostname) {
  const resp = await fiFmgHttp.query({
    method: 'set',
    params: [
      {
        url: '/cli/global/system/global',
        data: {
          hostname: hostname,
        },
      },
    ],
  });

  fiStore.dispatch(fiSession.setHostname(hostname));

  await setStatus(true);
  return resp?.[0];
}

async function getStatus() {
  const req = {
    method: 'get',
    url: '/gui/hostname_status',
  };
  return fiFmgHttp.post(req).then((resp) => resp?.[0]?.data);
}

/**
 *
 * @param {bool} status
 * @returns Promise
 */
async function setStatus(status) {
  const req = {
    method: 'post',
    url: '/gui/hostname_status',
    params: {
      changed: !!status,
    },
  };
  return fiFmgHttp.post(req);
}

export const fiSysHostname = {
  set,
  getStatus,
  setStatus,
};
