import { fiHttpPost, fiFmgHttp } from 'fi-http';
import { fiMessageBox } from 'fi-messagebox';
import { get } from 'lodash';

const JSON_DVMAPI_URL = MACROS.USER.DEF.URL_DVM_API;

const generateErrorMessageFromResult = (resultArray) => {
  return resultArray.reduce((msg, part) => {
    if (part.status.code !== 0) {
      msg += part.status.message;
    }
    return msg;
  }, '');
};

const showServerError = (prefix, err) => {
  const errMessage = Array.isArray(err?.data?.result)
    ? generateErrorMessageFromResult(err.data.result)
    : err.message;
  fiMessageBox.show(`${prefix}:${errMessage}`, 'danger');
};

export const setFlatuiJsonData = async (
  paramObj,
  showSuccessMsg = true,
  method
) => {
  const jsondataArr = Array.isArray(paramObj) ? paramObj : [paramObj];
  const req = {
    method: method ? method : 'set',
    params: jsondataArr,
  };

  try {
    return fiFmgHttp.query(req).then(function (resp) {
      if (showSuccessMsg) {
        fiMessageBox.show(gettext('Updated successfully'), 'success', 1200);
      }
      return get(resp, '0.data') || get(resp, '0.status');
    });
  } catch (err) {
    // console.error(err);
    if (showSuccessMsg) showServerError(gettext('Failed to update data'), err);
    if (err.errors[0] && err.errors[0].status) {
      return Promise.reject(err.errors[0].status);
    } else if (err.data.result[0] && err.data.result[0].status) {
      return Promise.reject(err.data.result[0].status.message);
    } else {
      return Promise.reject(err.message);
    }
  }
};

export const getFlatuiJsonData = async (paramObj) => {
  const _paramArr = Array.isArray(paramObj) ? paramObj : [paramObj];
  const multipleReq = Array.isArray(paramObj) ? true : false;

  try {
    return fiFmgHttp
      .forward({
        method: 'get',
        params: _paramArr,
      })
      .then((resp) => {
        return multipleReq ? resp : resp?.[0]?.data || resp;
      });
  } catch (err) {
    console.error(err);
    showServerError(gettext('Failed to get data'), err);
    return Promise.reject(err.errors ? err.errors[0].status : err);
  }
};

export const delFlatuiJsonData = async (paramObj, mkeyval) => {
  const arrParam = Array.isArray(paramObj) ? paramObj : [paramObj];

  try {
    return fiHttpPost(JSON_DVMAPI_URL, {
      method: 'delete',
      params: arrParam,
    });
  } catch (err) {
    console.error(err);
    showServerError(gettext('Failed to delete data'), err);
    if (err.errors[0] && err.errors[0].status) {
      return Promise.reject(err.errors[0].status, mkeyval);
    } else if (err.data.result[0] && err.data.result[0].status) {
      return Promise.reject(err.data.result[0].status.message);
    } else {
      return Promise.reject(err.message);
    }
  }
};
