import { fiCache } from 'kit-cache';
import { assign, get, isEmpty, isNil, castArray } from 'lodash';
import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { findPolicyPackage } from './ppkg';

const [readCache, setCache] = ((cacheId) => [
  () => fiCache.performance_cache(cacheId),
  (data) => fiCache.performance_cache(cacheId, data),
])('fi-gui-ppkg-hitcount-cache');

export const cacheHitCount = (pkgoid, data) => {
  setCache(assign(readCache(), { [pkgoid]: data }));
  return getHitCountCache(pkgoid);
};

export const getHitCountCache = (pkgoid) => get(readCache(), [pkgoid]);

export const getHitCountInfo = async (pkgoid) => {
  const cache = get(readCache(), [pkgoid], {});
  // The task info is initially on server side, under package settings
  if (isEmpty(cache)) {
    // copy the task info from server side then
    const pkgSettings = get(
      findPolicyPackage({ oid: pkgoid }),
      'package settings'
    );
    assign(cache, {
      taskid: get(pkgSettings, 'hitc-taskid'),
      timestamp: get(pkgSettings, 'hitc-timestamp'),
    });
    cacheHitCount(pkgoid, cache);
  }
  const { taskid, data, loading } = cache;
  if (!taskid) {
    return { taskid: 0, timestamp: 0, data: {} };
  }
  if (isNil(data) && !loading) {
    cacheHitCount(pkgoid, assign(cache, { loading: true }));
    const resp = await getHitcountResultByTaskId(taskid);
    let dmap = Object.keys(resp).reduce((acc, cur) => {
      let tmp = {};
      resp[cur].forEach((it) => {
        tmp[it.policyid] = it;
      });
      acc[cur] = tmp;
      return acc;
    }, {});
    return cacheHitCount(pkgoid, assign(cache, { data: dmap, loading: false }));
  }
  return cache;
};

export async function getHitcountResultByTaskId(taskid) {
  if (!taskid) {
    throw gettext('Invalid task ID.');
  }
  const req = {
    method: 'exec',
    params: [
      {
        url: 'sys/task/result',
        data: {
          taskid: taskid,
        },
      },
    ],
  };

  const resp = await fiFmgHttp.query(req);
  return get(resp, '[0].data', {});
}

export async function startFetchHitcountTask(pkgObj) {
  let adom = fiAdom.current();
  let req = {
    method: 'exec',
    params: [
      {
        url: 'sys/hitcount',
        data: {
          adom: adom.name,
          adom_oid: adom.oid,
          pkg_oid: pkgObj.oid,
        },
      },
    ],
  };

  const resp = await fiFmgHttp.query(req);
  return get(resp, '[0].data', {});
}

export async function getImplicitPolicyHitCountValue({
  v6 = false,
  showHitCount,
  pkgScopeMember = [{ name: '', vdom: '' }],
}) {
  const value = {
    _byte: 0,
    _first_hit: 0,
    _hitcount: 0,
    _last_hit: 0,
    _pkts: 0,
    _sesscount: 0,
    _first_session: 0,
    _last_session: 0,
  };
  if (showHitCount) {
    const resp = await fiFmgHttp.forward({
      id: 1,
      method: 'get',
      params: [
        {
          url: `/dbcache/${v6 ? '__hitcount6_value' : '__hitcount_value'}/`,
          option: 'scope member',
          filter: ['policyid', '==', '-2'],
          'scope member': castArray(pkgScopeMember).map((m) => ({
            name: m.name || '',
            vdom: m.vdom || '',
          })),
        },
      ],
    });
    castArray(get(resp, '[0].data', [])).forEach((elm) => {
      value['_byte'] += elm['bytes'];
      value['_hitcount'] += elm['hit_count'];
      value['_first_hit'] =
        value['_first_hit'] === 0
          ? elm['first_hit']
          : elm['first_hit'] === 0
          ? value['_first_hit']
          : Math.min(value['_first_hit'], elm['first_hit']);
      value['_last_hit'] = Math.max(value['_last_hit'], elm['last_hit']);
      value['_pkts'] += elm['pkts'];
      value['_sesscount'] += elm['session_count'];
      value['_first_session'] =
        value['_first_session'] === 0
          ? elm['first_sess']
          : elm['first_sess'] === 0
          ? value['_first_session']
          : Math.min(value['_first_session'], elm['first_sess']);
      value['_last_session'] = Math.max(
        value['_last_session'],
        elm['last_sess']
      );
    });
  }
  return value;
}
