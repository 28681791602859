// @ts-ignore
import { isFunction } from 'lodash';
// @ts-ignore
import { wslog, socketLog } from 'chrome_extension_debugger';

import {
  NOTIFY_CHANGED_ACTION,
  NOTIFY_REMOVED_ACTION,
  NOTIFY_ADDED_ACTION,
  NOTIFY_NOTIFY_ACTION,
} from './action_const';

import { getWSServer, getCSRFToken } from './util';
import WSClient from './ws_client';
import Socket from './socket';
import SocketIntf from './socket_intf';
import EventHandler from './event_handler';
import {
  RequestFormat,
  createDispatchRequest,
  appendIdAndMsg,
} from './request_format';
import { Listener } from './event_subject';
import WSRequest from './request';

import CustomerEventHandler from './customer_event_handler';
import { SocketProxy } from './socket_proxy';
interface Action {
  type: string;
  payload?: any;
}

export interface Dispatch {
  (action: Action): void;
}

function createSocket(): SocketIntf | null {
  const url = getWSServer();
  const token = getCSRFToken();
  if (!token) return null;
  const encodeToken = encodeURIComponent(token);
  return new WebSocket(url, [encodeToken]);
}

export class WSProxy {
  _wsClient: WSClient;
  constructor(private eventHandler?: EventHandler) {
    const socket = new Socket(() => {
      const socket = createSocket();
      if (!socket) return null;
      return new SocketProxy(socket, socketLog);
    });
    this._wsClient = new WSClient(socket, wslog);
  }
  connect() {
    this._wsClient.connect();
  }
  disconnect() {
    this._wsClient.disconnect();
  }
  addListener(event: string, listener: Listener) {
    return this._wsClient.eventSubject.addListener(event, listener);
  }
  removeListener(event: string, listener: Listener) {
    this._wsClient.eventSubject.removeListener(event, listener);
  }
  register(dispatch: Dispatch) {
    const onChanged = (data: any) => {
      const next = () =>
        dispatch({ type: NOTIFY_CHANGED_ACTION, payload: data });
      if (this.eventHandler?.changed)
        this.eventHandler?.changed(data, dispatch, next);
      else next();
    };

    const onRemoved = (data: any) => {
      const next = () =>
        dispatch({ type: NOTIFY_REMOVED_ACTION, payload: data });
      if (this.eventHandler?.removed)
        this.eventHandler?.removed(data, dispatch, next);
      else next();
    };

    const onAdded = (data: any) => {
      const next = () => dispatch({ type: NOTIFY_ADDED_ACTION, payload: data });
      if (this.eventHandler?.added)
        this.eventHandler?.added(data, dispatch, next);
      else next();
    };

    const onNotify = (data: any) => {
      dispatch({ type: NOTIFY_NOTIFY_ACTION, payload: data });
    };

    this._wsClient.eventSubject.on('changed', onChanged);

    this._wsClient.eventSubject.on('removed', onRemoved);

    this._wsClient.eventSubject.on('added', onAdded);

    this._wsClient.eventSubject.on('notify', onNotify);

    return () => {
      this._wsClient.eventSubject.removeListener('changed', onChanged);
      this._wsClient.eventSubject.removeListener('removed', onRemoved);
      this._wsClient.eventSubject.removeListener('added', onAdded);
      this._wsClient.eventSubject.removeListener('notify', onNotify);
    };
  }

  createRequest(request: RequestFormat): WSRequest {
    const req = appendIdAndMsg(request);
    return this._wsClient.createRequest(req);
  }

  sendRequest(request: RequestFormat): WSRequest {
    return this.createRequest(request);
  }

  createDispatchRequest(
    params: object,
    requestRef?: (dta: any) => void
  ): WSRequest {
    const req = createDispatchRequest(params);
    if (requestRef && isFunction(requestRef)) {
      requestRef(makeRequestRef(this._wsClient, req.id));
    }

    return this.createRequest(req);
  }

  cancelRequest(requestId: string): void {
    return this._wsClient.cancelRequest(requestId);
  }
}

function makeRequestRef(client: WSClient, requestId: string): any {
  return {
    cancelRequest: () => client.cancelRequest(requestId),
  };
}

let ws_proxy_instance: WSProxy | null = null;
export function get_ws_instance() {
  return ws_proxy_instance;
}

export function get_ws_proxy() {
  if (!ws_proxy_instance) create_ws_proxy();
  return ws_proxy_instance;
}

export function create_ws_proxy() {
  const eventHandler = new CustomerEventHandler();
  ws_proxy_instance = new WSProxy(eventHandler);
  ws_proxy_instance.connect();
}

export function close_ws_proxy() {
  if (ws_proxy_instance) {
    ws_proxy_instance.disconnect();
    ws_proxy_instance = null;
  }
}
