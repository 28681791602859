import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavDropdown } from './NavDropdown';
import { fiStoreTasks } from 'fistore';
import { NwProgressBar, NwSpinner } from '@fafm/neowise-core';
import { isFunction, flatMap, map, pick, filter } from 'lodash';
import taskDefs from './tasks/defs';
import { taskConst } from 'rc_task_monitor';

const LoadingIndicator = (label) => {
  return (
    <div>
      <NwSpinner className='tw-mr-1' />
      {label}
    </div>
  );
};

const task2DdItem = (task) => {
  const title = isFunction(task.title) ? task.title(task.params) : task.title;
  const key = `${task.group}-${task.id}`;
  const exec = taskDefs?.[task.group]?.exec;
  const titleText = taskConst.TaskTextMap[title] || title || '';
  return {
    key,
    label: (
      <div key={key} title={titleText} automation-id={`task-${key}`}>
        <span>{titleText}</span>
        <NwProgressBar percentage={task.percent}>
          {task.percent + '%'}
        </NwProgressBar>
      </div>
    ),
    onSelect: () => {
      exec && exec.call(task, pick(task, ['id', 'params']));
    },
  };
};

export const TaskDropdown = () => {
  const dispatch = useDispatch();
  const totalNum = useSelector(fiStoreTasks.getTotalNum);
  const taskGroups = useSelector(fiStoreTasks.getGroups);
  const ddItems = useMemo(() => {
    // show if any group is available
    const availTasks = filter(map(taskGroups, 'tasks'));
    if (availTasks.length) {
      return flatMap(availTasks, (tasks, index) => {
        const ddItems = map(tasks, task2DdItem);
        if (index === 0) {
          return ddItems;
        }
        ddItems.unshift({ isDivider: true });
        return ddItems;
      });
    }
    // show loader if no available tasks
    return [
      {
        key: 'loading',
        label: LoadingIndicator(gettext('Loading tasks...')),
      },
    ];
  }, [taskGroups]);

  // callbacks
  const onSelect = useCallback((item) => {
    item.onSelect();
  });
  const onShow = useCallback(() => {
    dispatch(fiStoreTasks.startFetcher());
  });
  const onHide = useCallback(() => {
    dispatch(fiStoreTasks.stopFetcher());
  });
  if (!totalNum) return null;

  return (
    <NavDropdown
      icon='top-task'
      title={gettext('GO to Task Monitor')}
      totalNum={totalNum}
      items={ddItems}
      automationId='task-dropdown'
      onSelect={onSelect}
      onShow={onShow}
      onHide={onHide}
    ></NavDropdown>
  );
};

TaskDropdown.displayName = 'TaskDropdown';
