import { useEffect, useState } from 'react';
import { isTaskError } from './task_util';

export const TaskProgressBar = ({
  taskData, // used for both task and task.line. attribute check should work for both
  autoIncNum = 5, // before there is an actually progress, do auto inc so user thinks it is running
  className = undefined,
  style,
  ...rest
}) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (!taskData) return setPercent(0);
    if (taskData.percent) return setPercent(taskData.percent);

    const to = setTimeout(() => {
      setPercent((prev) => {
        return prev < autoIncNum ? prev + 1 : prev;
      });
    }, 500 + percent * 10);

    return () => {
      clearTimeout(to);
    };
  }, [percent, taskData]);

  const percentage = Math.min(100, Math.max(0, percent || 0));
  return (
    <nw-progress-bar
      percentage={percentage}
      class={className}
      style={{
        '--indicator-color':
          taskData && isTaskError(taskData)
            ? 'rgb(var(--nw-color-danger-500))'
            : 'rgb(var(--nw-color-success-500))',
        ...style,
      }}
      {...rest}
    >
      <b>{percentage + '%'}</b>
    </nw-progress-bar>
  );
};
