import { NwProgressBar } from '@fafm/neowise-core';
import { useCallback, useEffect, useRef, useState } from 'react';

/* Due to some CPU intensive Task the requestAnimationFrame might not get called.
This version uses the setTimeout */
export const LoadingBarForCPUIntensiveTask = ({ isLoading, zIndex }) => {
  const running = useRef(false);
  const [progress, setProgress] = useState(0);
  const intervalHandleRef = useRef();

  const start = useCallback(() => {
    if (running.current) return;
    running.current = true;
    intervalHandleRef.current = setInterval(function () {
      setProgress((prog) => prog + (prog > 70 ? 0.1 : 0.02));
    }, 100);
  }, []);

  const stop = useCallback(() => {
    if (!running.current) return;
    running.current = false;
    if (intervalHandleRef.current) {
      clearInterval(intervalHandleRef.current);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      stop();
      setProgress(0);
    } else {
      start();
      return () => stop();
    }
  }, [isLoading]);

  if (!isLoading) {
    return null;
  }

  return (
    <NwProgressBar
      percentage={
        !isLoading ? 100 : (Math.atan(progress) / (Math.PI / 2)) * 100
      }
      style={{
        '--height': '3px',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex,
      }}
    ></NwProgressBar>
  );
};
