import { isEqual, isObject, isUndefined } from 'lodash';
import { isDefined } from 'fiutil';

import { fiAdom } from 'fi-session';

const isGlobal = fiAdom.isGlobalAdom();
const is640after = fiAdom.checkVersion('6.4', '>=');

const utmprofiles_members = [
  'profile-group',
  'av-profile',
  'webfilter-profile',
  'dnsfilter-profile',
  'casi-profile',
  'waf-profile',
  'application-list',
  'ips-sensor',
  'spamfilter-profile',
  'emailfilter-profile',
  'dlp-sensor',
  'icap-profile',
  'voip-profile',
  'mms-profile',
  'gtp-profile',
  'ssl-ssh-profile',
  'profile-protocol-options',
  'casb-profile',
  'virtual-patch-profile',
];

const users_members = ['devices', 'groups', 'users'];

const arr2Str = function (data) {
  if (isUndefined(data) || data === null) {
    return '';
  }
  if (Array.isArray(data)) {
    //Use non-breaking hyphen and space to prevent starting new line when data contains '-' or ' ';
    return data.join(',\n').replace(/-/g, '\u2011').replace(' ', '\u00A0');
  }
  return data.toString();
};

const installArr2Str = function (data) {
  const rs = [];
  if (isUndefined(data)) return '';
  if (Array.isArray(data)) {
    data.forEach(function (mem) {
      rs.push('%s[%s]'.printf([mem.name, mem.vdom]));
    });
    return rs.join(',');
  }
  return '%s[%s]'.printf([data.name, data.vdom]);
};

// ================ Parsers ==================
const normal_parser = function (rec) {
  const result = {};
  if (
    isDefined(rec[this.field]) &&
    rec[this.field] !== null &&
    (isDefined(rec[this.field].n) || isDefined(rec[this.field].o))
  ) {
    result.change_flag = 1;
    if (this.field === 'obj seq') {
      result.n = arr2Str(parseInt(rec[this.field].n) + 1);
      result.o = arr2Str(parseInt(rec[this.field].o) + 1);
    } else {
      result.n = arr2Str(rec[this.field].n);
      result.o = arr2Str(rec[this.field].o);
    }
  } else {
    result.change_flag = 0;
    if (this.field === 'obj seq') {
      result[this.field] = arr2Str(parseInt(rec[this.field]) + 1);
    } else {
      result[this.field] = arr2Str(rec[this.field]);
    }
  }

  return result;
};

const install_on_parser = function (rec) {
  const result = {};
  if (
    isDefined(rec[this.field]) &&
    (isDefined(rec[this.field].n) || isDefined(rec[this.field].o))
  ) {
    result.change_flag = 1;
    result.n = installArr2Str(rec[this.field].n);
    result.o = installArr2Str(rec[this.field].o);
  } else {
    result.change_flag = 0;
    result[this.field] = installArr2Str(rec[this.field]);
  }
  return result;
};

const utmprofiles_parser = function (rec) {
  const result = {};
  const members = this.members;
  let i = 0,
    l = members.length;
  let newUTM = [],
    oldUTM = [];
  for (i = 0; i < l; i++) {
    const mem = members[i];
    if (
      rec[mem] !== null &&
      isDefined(rec[mem]) &&
      isDefined(rec[mem].n) &&
      isDefined(rec[mem].o)
    ) {
      if (rec[mem].n !== null) {
        newUTM = newUTM.concat(rec[mem].n);
      }
      if (rec[mem].o !== null) {
        oldUTM = oldUTM.concat(rec[mem].o);
      }
    } else {
      if (rec[mem] !== null && isDefined(rec[mem])) {
        newUTM = newUTM.concat(rec[mem]);
        oldUTM = oldUTM.concat(rec[mem]);
      }
    }
  }
  if (isEqual(newUTM.sort(), oldUTM.sort())) {
    result.change_flag = 0;
    result[this.field] = newUTM.join(',\n');
  } else {
    result.change_flag = 1;
    result.n = arr2Str(newUTM);
    result.o = arr2Str(oldUTM);
  }
  return result;
};
const users_parser = utmprofiles_parser;

const others_field_parser = function (rec) {
  const result = {};
  const fields = this.displayFields || [];
  let i = 0;
  if (rec['diff_flag'] == 2) {
    let isFieldsChange = false;
    const nval = [],
      oval = [];
    for (i = 0; i < fields.length; i++) {
      const fd = fields[i];
      if (
        isDefined(rec[fd]) &&
        rec[fd] !== null &&
        (isDefined(rec[fd].n) || isDefined(rec[fd].o))
      ) {
        isFieldsChange = true;
        nval.push(fd + ': ' + arr2Str(rec[fd].n));
        oval.push(fd + ': ' + arr2Str(rec[fd].o));
      } else {
        const parsed = arr2Str(rec[fd]);
        nval.push(fd + ': ' + parsed);
        oval.push(fd + ': ' + parsed);
      }
    }
    if (isFieldsChange) {
      result.change_flag = 1;
      result.n = nval.join('\n');
      result.o = oval.join('\n');
    } else {
      result.change_flag = 0;
      result[this.field] = nval.join('\n');
    }
  } else {
    result.change_flag = 0;
    const val = [];
    for (i = 0; i < fields.length; i++) {
      val.push(fields[i] + ': ' + arr2Str(rec[fields[i]]));
    }
    result[this.field] = val.join('\n');
  }
  return result;
};

const gPlySumColDefs = [
  {
    field: 'cate',
    label: gettext('Category'),
    visible: true,
    width: 150,
  },
  {
    field: 'user',
    label: gettext('User'),
    visible: true,
    width: 100,
  },
  {
    field: 'update_time',
    label: gettext('Update Time'),
    visible: true,
    width: 200,
  },
  {
    field: 'change_summary',
    label: gettext('Change Summary'),
    visible: true,
    width: 300,
  },
  {
    field: 'detail',
    label: '',
    visible: true,
    width: 100,
  },
];

const getPackageSummaryColDefs = function (type) {
  return [
    {
      field: 'cate',
      label: gettext('Category'),
      visible: type === 1,
      width: 200,
    },
    {
      field: 'plyPkg',
      label: gettext('Policy Package'),
      visible: type === 2,
      width: 200,
    },
    {
      field: 'install_target',
      label: isGlobal ? gettext('Assign To') : gettext('Install On'),
      visible: type === 2,
      width: 100,
    },
    {
      field: 'user',
      label: gettext('User'),
      visible: true,
      width: 100,
    },
    {
      field: 'update_time',
      label: gettext('Update Time'),
      visible: type === 2,
      width: 200,
    },
    {
      field: 'type',
      label: gettext('Change Summary'),
      visible: type === 2,
      width: 100,
    },
    {
      field: 'change_summary',
      label: gettext('Change Summary'),
      visible: type === 1,
      width: 200,
    },
    {
      field: 'detail',
      label: '',
      visible: true,
      width: 100,
    },
  ];
};

const getObjSummaryColDefs = function (type) {
  return [
    {
      field: 'cate',
      label: gettext('Category'),
      visible: true,
      width: 250,
    },
    {
      field: 'user',
      label: gettext('User'),
      visible: true,
      width: 100,
    },
    {
      field: 'update_time',
      label: gettext('Update Time'),
      visible: type === 2,
      width: 200,
    },
    {
      field: 'change_summary',
      label: gettext('Change Summary'),
      visible: true,
      width: 300,
    },
  ];
};

const getObjDetailColDefs = function (type) {
  return [
    {
      field: 'name',
      label: gettext('Name'),
      width: 200,
      visible: true,
      parser: normal_parser,
    },
    {
      field: 'category_name',
      label: gettext('Category'),
      width: 200,
      visible: true,
      parser: normal_parser,
    },
    {
      field: 'detail',
      label: gettext('Detail'),
      width: type === 2 ? 550 : 400,
      visible: true,
      parser: function (rec) {
        const result = {};
        let count = 0;

        if (rec.diff_flag === 2) {
          const newDetailArr = [],
            oldDetailArr = [];
          Object.entries(rec).forEach(function ([key, val]) {
            if (isObject(val) && isDefined(val.n) && isDefined(val.o)) {
              newDetailArr.push('%s = %s'.printf([key, val.n]));
              oldDetailArr.push('%s = %s'.printf([key, val.o]));
            }
          });
          result.change_flag = 1;
          result.n = newDetailArr;
          result.o = oldDetailArr;
        } else {
          const detailArr = [];
          Object.entries(rec).forEach(function ([key, val]) {
            if (
              key !== 'entries' &&
              key !== 'dynamic_mapping' &&
              key !== 'diff_flag' &&
              key !== 'timestamp' &&
              !isObject(val)
            ) {
              if (count < 5) {
                count++;
              } else {
                return;
              }

              detailArr.push('%s = %s'.printf([key, val]));
            }
          });
          result.change_flag = 0;
          result[this.field] = detailArr;
        }
        return result;
      },
    },
    {
      field: 'update_time',
      label: gettext('Update Time'),
      width: 150,
      visible: type === 2,
      parser: normal_parser,
    },
  ];
};

const COM_COL_SEQ = {
  field: 'obj seq',
  label: gettext('#'),
  width: 100,
  parser: normal_parser,
};
const COM_COL_NAME = {
  field: 'name',
  label: gettext('Name'),
  width: 150,
  parser: normal_parser,
};
const COM_COL_PID = {
  field: 'policyid',
  label: gettext('Policy ID'),
  width: 100,
  parser: normal_parser,
};
const COM_COL_ID = {
  field: 'id',
  label: gettext('Policy ID'),
  width: 100,
  parser: normal_parser,
};
const COM_COL_SRCINTF = {
  field: 'srcintf',
  label: gettext('From'),
  width: 100,
  parser: normal_parser,
};
const COM_COL_DSTINTF = {
  field: 'dstintf',
  label: gettext('To'),
  width: 100,
  parser: normal_parser,
};
const COM_COL_SRCADDR = {
  field: 'srcaddr',
  label: gettext('Source'),
  width: 150,
  parser: normal_parser,
};
const COM_COL_DSTADDR = {
  field: 'dstaddr',
  label: gettext('Destination'),
  width: 150,
  parser: normal_parser,
};
const COM_COL_SERVICE = {
  field: 'service',
  label: gettext('Service'),
  width: 100,
  parser: normal_parser,
};
const COM_COL_SCHEDULE = {
  field: 'schedule',
  label: gettext('Schedule'),
  width: 100,
  parser: normal_parser,
};
const COM_COL_ACTION = {
  field: 'action',
  label: gettext('Action'),
  width: 100,
  parser: normal_parser,
};
const COM_COL_INSTALLON = {
  field: 'scope member',
  label: gettext('Install On'),
  width: 100,
  parser: install_on_parser,
};
const COM_COL_UUID = {
  field: 'others',
  displayFields: ['uuid'],
  label: gettext('Others'),
  width: 100,
  parser: others_field_parser,
};
const COM_COL_SECTION = {
  field: 'global-label',
  label: gettext('Policy Section'),
  width: 100,
  parser: normal_parser,
};
const COM_COL_INTERFACE = {
  field: 'interface',
  label: gettext('Interface'),
  width: 100,
  parser: normal_parser,
};

const policyColDefs = {
  default: [COM_COL_SEQ, COM_COL_PID, COM_COL_NAME],
  fw: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_NAME,
    COM_COL_SRCINTF,
    COM_COL_DSTINTF,
    COM_COL_SRCADDR,
    COM_COL_DSTADDR,
    COM_COL_SCHEDULE,
    COM_COL_SERVICE,
    {
      field: 'users',
      label: gettext('Users'),
      width: 100,
      members: users_members,
      parser: users_parser,
    },
    COM_COL_ACTION,
    {
      field: 'logtraffic',
      label: gettext('Log'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'status',
      label: gettext('Status'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'utmprofiles',
      label: gettext('Security Profiles'),
      width: 100,
      members: utmprofiles_members,
      parser: utmprofiles_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  fwc: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_NAME,
    COM_COL_SRCINTF,
    COM_COL_DSTINTF,
    {
      field: 'srcaddr4',
      label: gettext('IPv4 Source'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'srcaddr6',
      label: gettext('IPv6 Source'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'dstaddr4',
      label: gettext('IPv4 Destination'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'dstaddr6',
      label: gettext('IPv6 Destination'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_SCHEDULE,
    COM_COL_SERVICE,
    {
      field: 'users',
      label: gettext('Users'),
      width: 100,
      members: users_members,
      parser: users_parser,
    },
    COM_COL_ACTION,
    {
      field: 'logtraffic',
      label: gettext('Log'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'status',
      label: gettext('Status'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'utmprofiles',
      label: gettext('Security Profiles'),
      width: 100,
      members: utmprofiles_members,
      parser: utmprofiles_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  fws: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_NAME,
    COM_COL_SRCINTF,
    COM_COL_DSTINTF,
    {
      field: is640after ? 'srcaddr' : 'srcaddr4',
      label: gettext('IPv4 Source'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'srcaddr6',
      label: gettext('IPv6 Source'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: is640after ? 'dstaddr' : 'dstaddr4',
      label: gettext('IPv4 Destination'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'dstaddr6',
      label: gettext('IPv6 Destination'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_SCHEDULE,
    COM_COL_SERVICE,
    COM_COL_ACTION,
    {
      field: 'logtraffic',
      label: gettext('Log'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'status',
      label: gettext('Status'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'utmprofiles',
      label: gettext('Security Profiles'),
      width: 100,
      members: utmprofiles_members,
      parser: utmprofiles_parser,
    },
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  fw6: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_SRCINTF,
    COM_COL_DSTINTF,
    COM_COL_SRCADDR,
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    {
      field: 'users',
      label: gettext('Users'),
      width: 100,
      members: users_members,
      parser: users_parser,
    },
    COM_COL_ACTION,
    {
      field: 'logtraffic',
      label: gettext('Log'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'utmprofiles',
      label: gettext('Profiles'),
      width: 100,
      members: utmprofiles_members,
      parser: utmprofiles_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  mcast: [
    COM_COL_SEQ,
    COM_COL_PID,
    {
      field: 'srcintf',
      label: gettext('Source Interface'),
      width: 150,
      parser: normal_parser,
    },
    COM_COL_SRCADDR,
    {
      field: 'dstintf',
      label: gettext('Destination Interface'),
      width: 150,
      parser: normal_parser,
    },
    COM_COL_DSTADDR,
    {
      field: 'protocol',
      label: gettext('Protocol'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'snat',
      label: gettext('Source NAT'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'dnat',
      label: gettext('Destination NAT'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_ACTION,
    {
      field: 'logtraffic',
      label: gettext('Log'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  intf: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_INTERFACE,
    COM_COL_SRCADDR,
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    {
      field: 'utmprofiles',
      label: gettext('Profiles'),
      width: 100,
      members: utmprofiles_members,
      parser: utmprofiles_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  intf6: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_INTERFACE,
    {
      field: 'srcaddr6',
      label: gettext('Source'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'dstaddr6',
      label: gettext('Destination'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'service6',
      label: gettext('Service'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'utmprofiles',
      label: gettext('Profiles'),
      width: 100,
      members: utmprofiles_members,
      parser: utmprofiles_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  nat64: [
    COM_COL_SEQ,
    COM_COL_PID,
    {
      field: 'srcintf',
      label: gettext('Source Interface'),
      width: 150,
      parser: normal_parser,
    },
    COM_COL_SRCADDR,
    {
      field: 'dstintf',
      label: gettext('Destination Interface'),
      width: 150,
      parser: normal_parser,
    },
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    COM_COL_SCHEDULE,
    COM_COL_ACTION,
    {
      field: 'logtraffic',
      label: gettext('Log'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  dos: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_INTERFACE,
    COM_COL_SRCADDR,
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  dos6: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_INTERFACE,
    COM_COL_SRCADDR,
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  nat46: [
    COM_COL_SEQ,
    COM_COL_PID,
    {
      field: 'srcintf',
      label: gettext('Source Interface'),
      width: 150,
      parser: normal_parser,
    },
    COM_COL_SRCADDR,
    {
      field: 'dstintf',
      label: gettext('Destination Interface'),
      width: 150,
      parser: normal_parser,
    },
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    COM_COL_SCHEDULE,
    COM_COL_ACTION,
    {
      field: 'logtraffic',
      label: gettext('Log'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  hdrfw: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_NAME,
    COM_COL_SRCINTF,
    COM_COL_SRCADDR,
    COM_COL_DSTINTF,
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    COM_COL_SCHEDULE,
    COM_COL_ACTION,
    {
      field: 'rtp-nat',
      label: 'NAT',
      width: 100,
      parser: normal_parser,
    },
    {
      field: '',
      label: gettext('Authentication'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'utmprofiles',
      label: gettext('Profiles'),
      width: 100,
      members: utmprofiles_members,
      parser: utmprofiles_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  ftrfw: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_NAME,
    COM_COL_SRCINTF,
    COM_COL_SRCADDR,
    COM_COL_DSTINTF,
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    COM_COL_SCHEDULE,
    COM_COL_ACTION,
    {
      field: 'rtp-nat',
      label: 'NAT',
      width: 100,
      parser: normal_parser,
    },
    {
      field: '',
      label: gettext('Authentication'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'utmprofiles',
      label: gettext('Profiles'),
      width: 100,
      members: utmprofiles_members,
      parser: utmprofiles_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  proxy: [
    COM_COL_SEQ,
    COM_COL_PID,
    {
      field: 'proxy',
      label: gettext('Proxy'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_SRCADDR,
    {
      field: 'dstintf',
      label: gettext('Destination Interface'),
      width: 150,
      parser: normal_parser,
    },
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    COM_COL_SCHEDULE,
    COM_COL_ACTION,
    {
      field: 'logtraffic',
      label: gettext('Log'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'utmprofiles',
      label: gettext('Profiles'),
      width: 100,
      members: utmprofiles_members,
      parser: utmprofiles_parser,
    },
    COM_COL_SECTION,
    COM_COL_INSTALLON,
    COM_COL_UUID,
  ],
  localin: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_SRCADDR,
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    COM_COL_SCHEDULE,
    {
      field: 'intf',
      label: gettext('Interface'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_ACTION,
    COM_COL_SECTION,
    COM_COL_INSTALLON,
  ],
  localin6: [
    COM_COL_SEQ,
    COM_COL_PID,
    COM_COL_SRCADDR,
    COM_COL_DSTADDR,
    COM_COL_SERVICE,
    COM_COL_SCHEDULE,
    {
      field: 'intf',
      label: gettext('Interface'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_ACTION,
    COM_COL_SECTION,
    COM_COL_INSTALLON,
  ],
  shaping: [
    COM_COL_SEQ,
    COM_COL_ID,
    COM_COL_DSTINTF,
    COM_COL_DSTADDR,
    COM_COL_SRCADDR,
    COM_COL_SERVICE,
    {
      field: 'traffic-shaper',
      label: gettext('Shared Shaper'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'traffic-shaper-reverse',
      label: gettext('Reverse Shaper'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'per-ip-shaper',
      label: gettext('Per-IP Shaper'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'application',
      label: gettext('Application'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'application-group',
      label: gettext('Application Group'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'users',
      label: gettext('Users'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'groups',
      label: gettext('Groups'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'url-category',
      label: gettext('URL Category'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_SECTION,
  ],
  snat: [
    {
      field: 'policyid',
      label: 'NAT ID',
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'status',
      label: gettext('Status'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'orig-addr',
      label: gettext('Original Address'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'orig-port',
      label: gettext('Original Source Port'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'nat-ippool',
      label: gettext('Translated Address'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'nat-port',
      label: gettext('Translated Port'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_SECTION,
  ],
  csnat: [
    {
      field: 'policyid',
      label: 'NAT ID',
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'status',
      label: gettext('Status'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'orig-addr',
      label: gettext('Original Address'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'orig-port',
      label: gettext('Original Source Port'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'nat-ippool',
      label: gettext('Translated Address'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'nat-port',
      label: gettext('Translated Port'),
      width: 100,
      parser: normal_parser,
    },
    COM_COL_SECTION,
  ],
  dnat: [COM_COL_NAME],
  pkgsetting: [
    {
      field: 'central-nat',
      label: gettext('Central NAT'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'inspection-mode',
      label: gettext('Inspection Mode'),
      width: 100,
      parser: function (rec) {
        const result = {};
        if (
          isDefined(rec[this.field]) &&
          rec[this.field] !== null &&
          (isDefined(rec[this.field].n) || isDefined(rec[this.field].o))
        ) {
          result.change_flag = 1;
          result.n =
            rec[this.field].n === MACROS.PM2CAT.PM2_INSPECT_MODE_PROXY
              ? gettext('Proxy')
              : gettext('Flow-based');
          result.o =
            rec[this.field].o === MACROS.PM2CAT.PM2_INSPECT_MODE_PROXY
              ? gettext('Proxy')
              : gettext('Flow-based');
        } else {
          result.change_flag = 0;
          result[this.field] =
            rec[this.field] === MACROS.PM2CAT.PM2_INSPECT_MODE_PROXY
              ? gettext('Proxy')
              : gettext('Flow-based');
        }
        return result;
      },
    },
    {
      field: 'fwpolicy-implicit-log',
      label: gettext('IPv4 policy Implicit Log'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'fwpolicy6-implicit-log',
      label: gettext('IPv6 policy Implicit Log'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'ngfw-mode',
      label: gettext('NGFW Mode'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'ssl-ssh-profile',
      label: gettext('SSL/SSH Inspection'),
      width: 100,
      parser: normal_parser,
    },
  ],
  authrule: [
    COM_COL_NAME,
    {
      field: 'protocol',
      label: gettext('Protocol'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'srcaddr',
      label: gettext('Source Address'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'active-auth-method',
      label: gettext('Authentication Scheme'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'sso-auth-scheme',
      label: gettext('SSO Authentication Scheme'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'ip-based',
      label: gettext('IP-based Authentication'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'comments',
      label: gettext('Comments'),
      width: 100,
      parser: normal_parser,
    },
  ],
  authsetting: [
    {
      field: 'active-auth-scheme',
      label: gettext('Authentication Scheme'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'sso-auth-scheme',
      label: gettext('SSO Authentication Scheme'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'captive-portal',
      label: gettext('Captive Portal Host'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'captive-portal-ip',
      label: gettext('Captive Portal IP Address'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'captive-portal-ip6',
      label: gettext('Captive Portal IPv6 Address'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'captive-portal6',
      label: gettext('IPv6 Captive Portal Host'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'captive-portal-port',
      label: gettext('Captive Portal Port'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'captive-portal-ssl-port',
      label: gettext('Captive Portal SSL Port'),
      width: 100,
      parser: normal_parser,
    },
    {
      field: 'captive-portal-type',
      label: gettext('Captive Portal Type'),
      width: 100,
      parser: normal_parser,
    },
  ],
};
policyColDefs.fw640 = policyColDefs.fw;
policyColDefs.fw760 = policyColDefs.fw;
policyColDefs.hdrfw6 = policyColDefs.hdrfw;
policyColDefs.ftrfw6 = policyColDefs.hdrfw;
policyColDefs.hdrfw640 = policyColDefs.hdrfw;
policyColDefs.ftrfw640 = policyColDefs.ftrfw;
policyColDefs.hdrfw760 = policyColDefs.hdrfw;
policyColDefs.ftrfw760 = policyColDefs.ftrfw;
policyColDefs.hdrshaping = policyColDefs.shaping;
policyColDefs.ftrshaping = policyColDefs.shaping;
policyColDefs.explicitProxy = policyColDefs.proxy;
policyColDefs.vwp = policyColDefs.fw;

export const getGlobalPolicySummaryColDefs = function () {
  return gPlySumColDefs;
};
export { getPackageSummaryColDefs, getObjSummaryColDefs, getObjDetailColDefs };
export const getPolicyColDefs = function () {
  return policyColDefs;
};
