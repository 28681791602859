import goog from '@fafm/goog';

/**
 * get Parses upgrade_to string (GUI uses device type name only e.g. FortiAP)
 * (Firmware template uses platform name e.g. FortiAP-231E)
 * @param {*} param0
 * @returns upgrade_to string (platform_name + ' ' + version)
 */
export function getUpgradeToString({
  currentVersion,
  platformName,
  vulnsArr,
  deviceTypeName,
  allPsirtData,
}) {
  const { byIrNumber: psirts } = allPsirtData;
  let res = '';
  let max_upgrade = '';

  vulnsArr.forEach((irNum) => {
    try {
      const psirt = psirts[irNum];
      const verSuggestions = psirt.products[platformName];
      verSuggestions.forEach((suggestion) => {
        const { maximum_version, minimum_version, upgrade_to } = suggestion;
        const greaterThanCurrVer =
          goog.string.compareVersions(minimum_version, currentVersion) <= 0;
        const lesserThanCurrVer =
          goog.string.compareVersions(maximum_version, currentVersion) >= 0;
        if (greaterThanCurrVer && lesserThanCurrVer) {
          if (!max_upgrade) max_upgrade = upgrade_to;
          else {
            const greaterThanCurrMaxVer =
              goog.string.compareVersions(max_upgrade, upgrade_to) <= 0;
            if (greaterThanCurrMaxVer) max_upgrade = upgrade_to;
          }
          res = deviceTypeName + ' ' + max_upgrade;
        }
      });
    } catch {
      //skip
    }
  });
  return res;
}
