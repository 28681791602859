import { ProToolkit, ProForm } from '@fafm/neowise-pro';
import { useCallback } from 'react'; // eslint-disable-line no-unused-vars
import { OkBtn } from 'rc_layout';

const { Header, Body, Footer } = ProForm;

const REG_ESCAPE = /[.*+?^${}()|[\]\\]/g;

export const highlighter = (txt, searchTerm) => {
  if (!searchTerm || !txt) return txt;
  const searchRegex = new RegExp(
    '(' + searchTerm.replace(REG_ESCAPE, '\\$&') + ')',
    'gi'
  );
  const split = txt.split(searchRegex);
  if (!split || split.length <= 1) return txt;
  return (
    <>
      {split.map((segment, idx) => {
        if (searchRegex.test(segment)) {
          return (
            <span key={idx} className='fi-search-highlight'>
              {segment}
            </span>
          );
        } else {
          return <span key={idx}>{segment}</span>;
        }
      })}
    </>
  );
};

export const MultiValueList = ({ values, searchTerm, title = '', $opener }) => {
  const done = useCallback(() => {
    $opener.reject();
  }, []);
  return (
    <>
      <Header>{title || gettext('Multiple Values')}</Header>
      <Body>
        <div className='tw-flex tw-flex-col tw-h-full'>
          <ul className='tw-flex-grow tw-overflow-y-auto'>
            {values.map((val, idx) => (
              <li key={idx}>{highlighter(val, searchTerm)}</li>
            ))}
          </ul>
        </div>
      </Body>
      <Footer>
        <OkBtn onClick={done}>{gettext('OK')}</OkBtn>
      </Footer>
    </>
  );
};

export const showPlainMultiValueListDrawer = ({
  values,
  searchTerm,
  title = '',
}) => {
  return ProToolkit.openDrawer(
    <MultiValueList searchTerm={searchTerm} values={values} title={title} />,
    { size: 'md' }
  );
};
