import { NwIcon } from '@fafm/neowise-core';
import { LoginButton } from 'react_apps/ra-auth/widgets/LoginButton';

const SAML_ROLE_SP = 1;

export const ssoSp = (url, params) => () => {
  url += '?fallback=' + encodeURIComponent(location.origin + '/ui/login');
  url += '&host=' + encodeURIComponent(location.host);
  const redir = params && params.get('redir');
  if (redir) {
    url += '&redir=' + encodeURIComponent(redir);
  }
  location.assign(url);
};

export const useRegularSSOLogin = ({ loginEnv }) => {
  return {
    getExtraButton({ key, params }) {
      if (loginEnv.sso_role === SAML_ROLE_SP) {
        return (
          <LoginButton
            key={key}
            automation-id='login-with-sso'
            text={gettext('Login with Single Sign-On')}
            onClick={ssoSp(loginEnv.sso_url, params)}
          />
        );
      }
    },
  };
};

export const useFortiCloudSSOLogin = ({ loginEnv }) => {
  return {
    getExtraButton({ key }) {
      if (loginEnv.forticloud_sso_url) {
        return (
          <LoginButton
            key={key}
            automation-id='login-with-forticloud'
            text={gettext('Login with FortiCloud')}
            onClick={ssoSp(loginEnv.forticloud_sso_url)}
            icon={<NwIcon library='fgt-products' name='forticloud' />}
          />
        );
      }
    },
  };
};
