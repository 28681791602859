import { flatui_proxy } from '../../utils';

export const fetchSyntaxAvailability = (adomName, categories = []) => {
  const req = {
    url: '/gui/adom/devices/syntax-availability',
    method: 'checkCategories',
    params: {
      adomName,
      categories,
    },
  };

  return flatui_proxy(req);
};
