import { flatui_proxy } from '../../utils';

export const fetch_remote_faz = (adomOid) => {
  const url = `/gui/adoms/${adomOid}/remotefaz`;
  return flatui_proxy({
    url,
    method: 'get',
    params: {
      fields: ['all'],
    },
  });
};
