import { forwardRef, useRef } from 'react';
import cn from 'classnames';

import { LRSelect } from '@fafm/filego2';
import { isFunction, promiseWhen } from 'fiutil';
import { useValidEffect } from 'rh_util_hooks';

import './LRSelect.less';

// Note: when source is an async function, please pass in a ref and call ref.current.load() when source is ready OR
// resolved the source before passing into and render LRSelect component
export const LRSelect2 = forwardRef(
  ({ className, style, getProps, ...lrSelectProps }, ref) => {
    const containerRef = useRef();
    const lrSelectRef = useRef();

    useValidEffect((isValid) => {
      if (!isValid()) return;
      const initSelect = async () => {
        const props = await getPropsAsync();
        if (!lrSelectRef.current) {
          lrSelectRef.current = new LRSelect(
            containerRef.current.appendChild(document.createElement('div')),
            props
          );
          if (ref) ref.current = lrSelectRef.current;
          lrSelectRef.current.load();
        }
      };

      initSelect();
      return () => {
        if (containerRef.current && containerRef.current.firstChild)
          containerRef.current.removeChild(containerRef.current.firstChild);
      };
    }, []);

    // useValidEffect((isValid) => {
    //   if (!isValid()) return;
    //   if (lrSelectRef.current) lrSelectRef.current.load();
    // }, [lrSelectProps.source]);

    async function getPropsAsync() {
      if (isFunction(getProps)) {
        const props = await promiseWhen(getProps());
        return props;
      }

      const { selected: _selected, ...rest } = lrSelectProps;
      const selected = isFunction(_selected)
        ? await promiseWhen(_selected())
        : _selected;
      return { ...rest, selected };
    }

    return (
      <div
        ref={containerRef}
        style={style}
        className={cn('lrselect-container', className)}
      ></div>
    );
  }
);
LRSelect2.displayName = 'LRSelect2';
