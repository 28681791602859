import { createSelector } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import { get_devices } from 'fistore/devices/devices_vdoms/selectors';
import { getSessionAdomOid } from 'fistore/session/adom/selectors';
import { getLocalStorage } from 'fiutil/storage';

export const getAdomLocalConfigState = (state) => state.adomLocalConfig;

export const getDeviceTree = createSelector(
  getAdomLocalConfigState,
  (adomLocalConfig) => adomLocalConfig.deviceTree || {}
);

export const getDeviceGroupPath = createSelector(
  getDeviceTree,
  (deviceTree) => deviceTree.deviceGroupPath
);

export const getSearchKeyword = createSelector(
  getAdomLocalConfigState,
  (adomLocalConfig) => adomLocalConfig.searchKeyword || ''
);

export const getCurrentAssest = createSelector(
  getAdomLocalConfigState,
  (adomLocalConfig) => adomLocalConfig.currentAsset || {}
);

export const getFolderVTree = createSelector(
  getAdomLocalConfigState,
  (adomLocalConfig) => adomLocalConfig.folderTree || {}
);

export const getDeviceTreeLocalStorageKey = createSelector(
  getSessionAdomOid,
  (adomOid) => {
    const lsKey = `${adomOid}:device_tree:single:tree`;
    return getLocalStorage(lsKey, null);
  }
);

export const hasSelectedDeviceChecker = (state) => {
  const adomOid = getSessionAdomOid(state);
  const devices = get_devices(state, adomOid);
  const deviceTree = getDeviceTree(state);

  // mantis #1073092: if devices are not ready yet in fistore, return true by default.
  // e.g. for pdm ap > ssid, if selected device is not set to fistore yet, the initial check for selected device need to return true in order to show the ssid app,
  // otherwise it will be redirected to dashboard because the ssid app is filtered out by showCheckers. Later when a device node is selected and set in fistore,
  // the menu tree will be refreshed again by refreshAppTree, see static/js/fistore/adomLocalConfig/slice.js, and the show/hide state of the app will updated accordingly
  if (!devices || devices.loading || !devices.loaded) {
    return true;
  }

  // if there is no device in the current adom, simply return false, meaning no device can be selected
  if (devices.loaded) {
    const deviceIds = Object.keys(devices.byId || {});
    if (deviceIds.length === 0) {
      return false;
    }
  }

  // check both local storage and device tree state
  const localStorageVal = getDeviceTreeLocalStorageKey(state);
  return localStorageVal?.selected || hasSelectedDeviceOid(deviceTree);
};

export const getSelectedCsfGroup = createSelector(
  getAdomLocalConfigState,
  (adomLocalConfig) => adomLocalConfig.selectedCsfGroup || {}
);

const hasSelectedDeviceOid = (deviceTree) => {
  return !isNil(deviceTree.deviceOid) && deviceTree.deviceOid !== '';
};

export const getIsFswInitialized = createSelector(
  getAdomLocalConfigState,
  (adomLocalConfig) => adomLocalConfig.isFswInitialized
);

export const getIsFapInitialized = createSelector(
  getAdomLocalConfigState,
  (adomLocalConfig) => adomLocalConfig.isFapInitialized
);
