export const getRemoteFazState = (state) => state.adom.remoteFAZ || {};

export const getRemoteFazList = (state) => getRemoteFazState(state).list || {};

export const getRemoteFazAllIds = (state) => {
  return getRemoteFazList(state).allIds || [];
};

export const getRemoteFazById = (state) => {
  return getRemoteFazList(state).byId || {};
};
