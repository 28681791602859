import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import * as actions from './actions';
import { fetchSyntaxAvailability } from './api';
import { callPromiseAction, getDataFromResponse } from '../../utils';
import { getSessionAdomName } from 'fistore/session/adom/selectors';
import { inFmgNotRestrictedAdmin } from 'fistore/session/sysConfig/selectors';

import { runIfAvailable } from 'fistore/routing/saga';
import { refreshAppTree } from 'fistore/routing/slice';

const categoriesToFetch = ['global/system/lte-modem'];

export function* watchDeviceSyntaxAvailability() {
  yield takeLatest(
    [actions.fetchDeviceSyntaxAvailability.type],
    runIfAvailable(inFmgNotRestrictedAdmin, fetchDeviceSyntaxAvailability)
  );
}

function* fetchDeviceSyntaxAvailability(action) {
  yield delay(500);
  yield callPromiseAction(action, function* () {
    const adomName =
      action.payload?.adomName || (yield select(getSessionAdomName));

    yield put(actions.devicesSyntaxAvailabilityAction.fetch.start());

    const resp = yield call(
      fetchSyntaxAvailability,
      adomName,
      categoriesToFetch
    );

    const data = getDataFromResponse(resp);

    const payload = {
      syntaxMap: data,
    };

    yield put(actions.devicesSyntaxAvailabilityAction.fetch.success(payload));

    return data;
  });
  yield put(refreshAppTree());
}
