import { createSlice, createAction } from '@reduxjs/toolkit';
import { createPromiseAction } from '../utils/saga-promise';

export const fetchDockerStatus = createAction('docker/FETCH_DOCKER_STATUS');
export const enableDockerExtension = createAction(
  'docker/ENABLE_DOCKER_EXTENSION'
);
export const fetchCliDockerStatus = createPromiseAction(
  'docker/GET_CLI_DOCKER_STATUS'
);

const slice = createSlice({
  name: 'docker',
  initialState: {
    dockerStatus: {},
    cliDockerStatus: {},
  },
  reducers: {
    setDockerStatus(state, { payload }) {
      state.dockerStatus = payload;
    },
    startLoadingExtension(state, { payload }) {
      state.dockerStatus[payload] = { status: MACROS.SYS.DOCKER_ST_PULLING };
    },
    setCliDockerStatus(state, { payload }) {
      state.cliDockerStatus = payload;
    },
  },
});

export const { setDockerStatus, startLoadingExtension, setCliDockerStatus } =
  slice.actions;
export default slice.reducer;
