import { isString } from 'lodash';

/**
 * Escapes characters in regular expression string which will be used as Regexp Object
 * e.g. for `new RegExp(str)` and str = 'a (bc)', runs this function to escape the '()'
 */
export function RegExpEscape(str: string = ''): string {
  return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

// escape slash for username in url, used in set user theme request
export function escapeSlash(str: string): string {
  if (!isString(str)) return str;
  return str.replace(/\//g, '\\/');
}

export function escapeBackslash(str: string): string {
  if (!isString(str)) return str;
  return str.replace(/\\/g, '\\\\');
}

// escape both forward slash and backslash
export function escapeSlashFull(str: string): string {
  if (!isString(str)) return str;
  return escapeSlash(escapeBackslash(str));
}
