import { useCallback, useState } from 'react';
import { fiAdom, fiSysConfig } from 'fi-session';
import { useApiRequest } from 'react_hooks/rh_util_hooks';
import { isFunction, isUndefined } from 'lodash';
import { fiDeviceDataFetcher } from 'react_apps/ra_device_util';
import { canDevBeManaged } from '../util_functions/os_type_related';
import goog from '@fafm/goog';

const getDefaultOsType = () => {
  return fiAdom.getDevOsTypeByAdomType(fiAdom.current().type);
};

export const useOsType = (selectedOsType) => {
  const osType = selectedOsType ?? getDefaultOsType();
  return useState(osType);
};

const CONTAINER_FORTIOS = 0;

const getModelByVersion = function (
  ostype,
  firmwareVersion,
  usePreviousMr,
  abortCtrl
) {
  const [ver, mr] = firmwareVersion.split('.');
  return fiDeviceDataFetcher.getModelsByVersions(
    ostype,
    ver,
    mr,
    usePreviousMr,
    abortCtrl
  );
};

export const useAllModelStruct = ({
  osType,
  firmwareVersion,
  callback = undefined,
  usePreviousMr = true,
}) => {
  const defaultValue = { map: {}, list: [] };

  return useApiRequest({
    defaultValue,
    loader: async () => {
      if (isUndefined(osType) || !firmwareVersion) return defaultValue;
      let modelsStruct = { ...defaultValue };

      const allModels = await getModelByVersion(
        osType,
        firmwareVersion,
        usePreviousMr
      );
      modelsStruct = (allModels || []).reduce(
        (acc, model) => {
          if (fiSysConfig.isFmg()) {
            if (
              osType == MACROS.DVM.DVM_OS_TYPE_FOS &&
              model.val === CONTAINER_FORTIOS
            )
              return acc;
          }
          const obj = { id: model.txt, text: model.txt, _oData: model };
          acc.map[model.txt] = { ...obj };
          acc.list.push({ ...obj });
          return acc;
        },
        { map: {}, list: [] }
      );

      return modelsStruct;
    },
    parser: (resp) => {
      isFunction(callback) && callback(resp);
      return resp;
    },
    dependencies: [osType, firmwareVersion],
  });
};

export const getFirmwareVersions = (osType) => {
  const cAdom = fiAdom.current();
  return fiDeviceDataFetcher.getFwVers(osType, cAdom.oid).then((resp) => {
    const fwVers = (resp || []).map(({ ver, mr }) => {
      const key = `${ver}.${mr}`;
      return { id: key, text: key, oData: { ver, mr } };
    });

    goog.array.sortObjectsByKey(fwVers, 'id');
    return fwVers;
  });
};

export const useCurrentFirmware = ({ osType, callback = undefined }) => {
  const adom = fiAdom.current();
  const getFirmwareKey = useCallback((fwVers) => {
    let key = undefined;
    if (canDevBeManaged(osType)) {
      let foundVer = fwVers.find(({ oData }) => {
        const { ver, mr } = oData;
        const cAdomVer = adom.version;
        if (cAdomVer.ver === ver && cAdomVer.mr === mr) {
          return true;
        }

        return false;
      });

      if (foundVer) key = foundVer.id;
    }

    if (!key) {
      const fwVerToUse = fwVers[fwVers.length - 1];
      key = fwVerToUse.id;
    }

    return key;
  }, []);

  return useApiRequest({
    defaultValue: { firmwareVersions: [], currentFirmware: '' },
    loader: () => getFirmwareVersions(osType),
    parser: (fwVers) => {
      const key = getFirmwareKey(fwVers);
      isFunction(callback) && callback({ osType, key });

      return {
        firmwareVersions: fwVers,
        currentFirmware: key,
      };
    },
    dependencies: [getFirmwareKey, osType],
  });
};
