import { useCallback, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import { isArray, isObject, isString, isFunction, isEmpty } from 'lodash';
import cn from 'classnames';

import { OkBtn, CancelBtn, NwProInputRow } from 'rc_layout';
import { useValidEffect, useStateRef, useDimension } from 'rh_util_hooks';
import { useAutoHeightCodemirror } from 'rc_autocomplete_codemirror';
import { fiAdom } from 'fi-session';
import { PageLoading } from 'ra-shared-components';
import { useTabGroup } from 'rc_select';
import { ProForm, ProLego } from '@fafm/neowise-pro';
import {
  fiDeviceDataLoader,
  DeviceRowIcon,
  DeviceFormatter,
} from 'ra_device_util';
import { fiDvmScriptRequests } from 'fi-dvm';

import { useLoadContentDS } from './hooks/useLoadContentDS';
import { ContentViewCodeMirror } from './ContentViewCodeMirror';
import { doDownload, genDefaultFilename, getContentKey } from './util';
import { fiStore } from 'fistore';
import { Provider } from 'react-redux';

import './rc_content_view.less';

const { Header, Body, Section, Footer } = ProForm;
const { SSelect } = ProLego;

export const ContentView = ({
  codemirrorId = 'rc_content_view_cm',
  $opener,
  rDS,
  rMessage,
  rDownload,
  mode = 'cli',
  originalContent,
  contentOnly = false,
  debug = false,
  parseContent,
  parseError,
  getDefaultContent,
  selectedTemplate,
  isDiffView = true,
  showHeader = true,
  emptyMessage = '',
  renderExtraButtons,
  idAttr = 'id',
  textAttr = 'text',
}) => {
  /** ----------------------------------------------------------------------------
   * Props and constants
   * -------------------------------------------------------------------------- */
  // allDevices is of shape [{ name, oid, vdomName, vdomOid }, ...]
  const {
    func,
    allDevices,
    parameter,
    getParam,
    deviceOnly,
    isMultipleDevice,
  } = rDS;
  const {
    title: contentViewTitle,
    filename: contentViewFileName,
    getTitle,
    getFilename,
  } = rMessage;

  const deviceChoices = useMemo(() => {
    return allDevices.map((dev) => {
      return getChoice(dev, deviceOnly);
    });
  }, [allDevices, deviceOnly]);

  /** ----------------------------------------------------------------------------
   * States
   * -------------------------------------------------------------------------- */
  const [script, setScript] = useState(null);
  const [currentDevice, setCurrentDevice] = useState(deviceChoices[0]);
  const [showDiffView, setShowDiffView] = useState(isDiffView);
  const [collapseIdentical, setCollapseIdentical] = useState(false);

  /** ----------------------------------------------------------------------------
   * Refs
   * -------------------------------------------------------------------------- */
  // can use the ref to access codemirror related functions
  // eslint-disable-next-line
  const [, setCmAccessors, cmAccessorRef] = useStateRef({});

  /** ----------------------------------------------------------------------------
   * Hooks
   * -------------------------------------------------------------------------- */
  const { displayData, isLoading, error, fullDisplayData } = useLoadContentDS({
    func,
    originalContent,
    parseError,
    currentDevice,
    parameter,
    getParam,
    parseContent,
    getDefaultContent,
    isMultipleDevice,
    idAttr,
  });

  const { tabStateManager, renderTabGroup } = useTabGroup({
    initTabs: deviceChoices,
    idAttr,
    textAttr,
    currentItem: currentDevice,
    setCurrentItem: setCurrentDevice,
    getTabLoading: (tab) => {
      const tabKey = tab[idAttr];
      if (!isMultipleDevice) {
        return tabKey === currentDevice[idAttr] && isLoading;
      }

      return fullDisplayData && !fullDisplayData[tabKey]?.loaded;
    },
    canRemoveTab: ({ tabs }) => tabs.length > 1,
  });

  // change codemirror height based on pm-body height
  const bodyRef = useRef();
  const deviceSectionRef = useRef();

  const cm =
    isFunction(cmAccessorRef?.current?.getCmInstance) &&
    cmAccessorRef?.current?.getCmInstance();

  const { height: deviceSectionHeight } = useDimension({
    el: deviceSectionRef.current,
  });

  useAutoHeightCodemirror({
    bodyEl: bodyRef.current,
    offset: deviceSectionHeight,
    dependencies: [isLoading, cm],
  });

  useValidEffect(
    async (getIsValid) => {
      if (!selectedTemplate) return;

      const script = await getOriginalContent(selectedTemplate);
      if (getIsValid()) {
        setScript(script);
      }
    },
    [selectedTemplate]
  );

  // only show diff view if there is no error
  const shouldRenderAsDiffView = useCallback(() => {
    return !!isDiffView && !error;
  }, [isDiffView, error]);

  /** ----------------------------------------------------------------------------
   * Event handlers
   * -------------------------------------------------------------------------- */
  const onClose = () => {
    $opener && $opener.reject();
  };

  const onDownload = () => {
    const fileName =
      getContentFileName() ||
      contentViewFileName ||
      genDefaultFilename(currentDevice);
    doDownload(fileName, displayData?.data);
  };

  // for debugging
  if (debug) {
    // eslint-disable-next-line
    console.log('fullDisplayData', fullDisplayData);
    // eslint-disable-next-line
    console.log('displayData', displayData);
    // eslint-disable-next-line
    console.log('isLoading', isLoading);
    // eslint-disable-next-line
    console.log('error', error);
    // eslint-disable-next-line
    console.log('currentDevice', currentDevice);
    // eslint-disable-next-line
    console.log('choices', deviceChoices);
  }

  /** ----------------------------------------------------------------------------
   * Helper functions
   * -------------------------------------------------------------------------- */
  const getContentTitle = () => {
    if (isFunction(getTitle)) {
      const { name, vdomName } = currentDevice || {};
      return getTitle(name, vdomName, currentDevice);
    }

    return contentViewTitle;
  };

  const getContentFileName = () => {
    if (isFunction(getFilename)) {
      const { name, vdomName } = currentDevice || {};
      return getFilename(name, vdomName, currentDevice);
    }

    return contentViewFileName;
  };

  const canDownload = () => {
    if (isFunction(rDownload)) return !!rDownload();
    return rDownload;
  };

  const toggleCollapseIdentical = () => {
    setCollapseIdentical(!collapseIdentical);
  };

  const toggleShowDiffView = () => {
    setShowDiffView(!showDiffView);
  };

  /** ----------------------------------------------------------------------------
   * Rendering
   * -------------------------------------------------------------------------- */
  const renderHeader = () => {
    return (
      <Header>
        <div className='tw-w-full tw-h-full tw-truncate'>
          {getContentTitle()}
        </div>
      </Header>
    );
  };

  const renderContent = () => {
    if (!displayData?.loaded) return <PageLoading />;

    const isDiffView = shouldRenderAsDiffView();

    return (
      <div className='tw-grow rc_content_view-codemirror'>
        <Section>
          <NwProInputRow>
            <ContentViewCodeMirror
              codemirrorId={codemirrorId}
              setCmAccessors={setCmAccessors}
              value={getScriptValue(displayData?.data, emptyMessage)}
              originalScript={originalContent || script}
              mode={mode}
              errors={[error]}
              dependencies={[
                isLoading,
                displayData?.loaded,
                displayData?.data,
                displayData?.error,
                currentDevice?.id,
                isDiffView,
              ]}
              collapseIdentical={collapseIdentical}
              automationId={getAutoId('codemirror')}
              isDiffView={isDiffView}
              showDiffView={showDiffView}
            />
          </NwProInputRow>
        </Section>
      </div>
    );
  };

  const formatChoiceHTML = (choice, highlighter = (text) => text) => {
    const { text, css, iconTitle, iconClass, iconProps, className, title } =
      choice;
    const renderIcon = () => {
      const iconCls = iconClass || css;
      if (isString(iconCls) && !isEmpty(iconCls)) {
        return (
          <span
            className={cn('tw-mr-1', iconCls)}
            title={iconTitle || title}
          ></span>
        );
      }
      return (
        <DeviceRowIcon
          iconProps={iconProps}
          className={className}
          title={iconTitle || title}
        />
      );
    };

    return renderToString(
      <div className='tw-flex tw-flex-wrap tw-items-center tw-w-full tw-h-full'>
        {renderIcon()}
        <span>{highlighter(text)}</span>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <Body
        ref={bodyRef}
        className='tw-flex tw-flex-col tw-h-full tw-p-4 tw-overflow-y-hidden'
      >
        {!contentOnly && (
          <div ref={deviceSectionRef} className='device-select-section'>
            <Section>
              <NwProInputRow label={gettext('Assigned Devices')}>
                <SSelect
                  name='all-devices'
                  value={currentDevice?.id}
                  source={deviceChoices}
                  onSelect={(_, dev) => {
                    setCurrentDevice(dev);
                    tabStateManager.add(dev);
                  }}
                  formatChoiceHTML={formatChoiceHTML}
                  formatSelectedHTML={formatChoiceHTML}
                  automation-id={getAutoId('device-select')}
                  disabled={!displayData?.loaded}
                ></SSelect>
              </NwProInputRow>
              <NwProInputRow>{renderTabGroup()}</NwProInputRow>
            </Section>
          </div>
        )}

        {renderContent()}
      </Body>
    );
  };

  const renderExtraFooterButtons = () => {
    return (
      <>
        {isFunction(renderExtraButtons) &&
          renderExtraButtons(displayData, isLoading)}
        {shouldRenderAsDiffView() && (
          <>
            {showDiffView && (
              <OkBtn onClick={toggleCollapseIdentical}>
                {collapseIdentical
                  ? gettext('Show Full Diff')
                  : gettext('Show Diff Only')}
              </OkBtn>
            )}
            <OkBtn onClick={toggleShowDiffView}>
              {showDiffView
                ? gettext('Show Result View')
                : gettext('Show Diff View')}
            </OkBtn>
          </>
        )}

        {/* Cannot go next/previous diff when collapseIdentical is true */}
        {/* {!collapseIdentical && (
          <>
            <OkBtn onClick={cmAccessorRef.current?.prevDiff}>{gettext('Previous Diff ' + '<')}</OkBtn>
            <OkBtn onClick={cmAccessorRef.current?.nextDiff}>{gettext('Next Diff ' + '>')}</OkBtn>
          </>
        )} */}
      </>
    );
  };

  const renderFooter = () => {
    return (
      <Footer>
        {renderExtraFooterButtons()}
        {canDownload() && (
          <OkBtn onClick={onDownload} disabled={isLoading}>
            {gettext('Download')}
          </OkBtn>
        )}
        <CancelBtn onClick={onClose}>{gettext('Close')}</CancelBtn>
      </Footer>
    );
  };

  return (
    <Provider store={fiStore}>
      {!!showHeader && renderHeader()}
      {renderBody()}
      {renderFooter()}
    </Provider>
  );
};
ContentView.displayName = ContentView;
ContentView.propTypes = {
  /**
   * @attr Data source functions and parameters for loading data via WS
   */
  rDS: PropTypes.shape({
    /**
     * @attr Loader function
     */
    func: PropTypes.func.isRequired,
    /**
     * @attr Parameters used in the loader function
     */
    parameter: PropTypes.array,
    /**
     * @attr Function to get parameters.
     * The function is of shape: ({ name, oid, vdomName, vdomOid }) => [...parameters]
     */
    getParam: PropTypes.func,
    /**
     * @attr All devices that are used for loading (NOTE: only support loading/showing content per device right now)
     * allDevices is of shape [{ name, oid, vdomName, vdomOid }, ...]
     */
    allDevices: PropTypes.array.isRequired,
  }).isRequired,

  /**
   * @attr Strings or functions used for title and download filename
   */
  rMessage: PropTypes.shape({
    /**
     * @attr Title as a string. Either use title or getTitle to generate title.
     */
    title: PropTypes.string,
    /**
     * @attr Filename as a string. Either use fileName or getFilename to generate download filename.
     */
    fileName: PropTypes.string,
    /**
     * @attr Function to get title. Either use title or getTitle to generate title.
     */
    getTitle: PropTypes.func,
    /**
     * @attr Function to get filename. Either use fileName or getFilename to generate download filename.
     */
    getFilename: PropTypes.func,
  }).isRequired,

  /**
   * @attr Function or boolean for enabling download or not
   * The function is of shape: () => boolean
   */
  rDownload: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,

  /**
   * @attr Codemirror mode, e.g. text, html, css, jinja2 etc.
   * @default 'text'
   */
  mode: PropTypes.string,

  /**
   * @attr Content before parsing, e.g. raw CLI template script before injecting actual value for variables
   * @default ''
   */
  originalContent: PropTypes.string,

  /**
   * @attr To render content only, without the sselect
   * @default false
   */
  contentOnly: PropTypes.bool,

  /**
   * @attr To enable logging for debugging
   * @default false
   */
  debug: PropTypes.bool,

  /**
   * @attr Function to parse displayData
   * The function is of shape: (displayData: object) => { displayData: object, error: object }
   */
  parseContent: PropTypes.func,

  /**
   * @attr Function to parse error message
   * The function is of shape: (errMsg: string) => { err: string, reason: string, line: number, code: string (e.g. "error") }
   */
  parseError: PropTypes.func,

  /**
   * @attr Function to get default content to show if no data is returned from websocket.
   * The function is of shape: ({ error, scriptName }) => string
   */
  getDefaultContent: PropTypes.func,
  /**
   * @attr Boolean to show codemirror in diff view or not.
   * @default true
   */
  isDiffView: PropTypes.bool,
  /**
   * @attr Boolean to show modal header or not.
   * @default true
   */
  showHeader: PropTypes.bool,
  /**
   * @attr String to display when content is empty.
   * @default ''
   */
  emptyMessage: PropTypes.string,
};

/** ----------------------------------------------------------------------------
 * Helper functions
 * -------------------------------------------------------------------------- */
function getScriptValue(chunks, emptyMessage = '') {
  if (isString(chunks)) return chunks;
  if (isArray(chunks)) {
    if (chunks.length === 0) return emptyMessage;
    return chunks.reduce((acc, curr) => {
      if (isObject(curr)) {
        const lineTxt = curr.txt || '';
        acc += lineTxt + '\n';
      }
      return acc;
    }, '');
  }
}

function getChoice(deviceObj, deviceOnly) {
  let {
    name,
    oid,
    vdomName,
    vdom_oid,
    vdomOid,
    iconClass,
    iconTitle,
    ...rest
  } = deviceObj;

  vdomOid = vdom_oid || vdomOid;
  const key = getContentKey(deviceObj);

  if (vdomOid === MACROS.DVM.CDB_DEFAULT_GLOBAL_OID) {
    vdomName = 'global';
  }

  const _oData = fiDeviceDataLoader.getDevice(`${oid}`) || {};
  const conn_status = _oData.connection ? _oData.connection.conn : _oData.conn;

  if (!vdomName) {
    const vdom = fiDeviceDataLoader.getDevice(`${oid}-${vdomOid}`);
    vdomName = vdom?.name || 'root';
  }

  const vdomTxt = vdomName && !deviceOnly ? ` [${vdomName}]` : '';
  const txt = `${name}${vdomTxt}`;

  const res = {
    ...rest,
    id: key,
    text: txt,

    // extra data
    name,
    oid,
    vdomName,
    vdomOid,
    iconClass,
    iconTitle,
    _oData,
    conn_status,
  };

  if (!iconClass) {
    const { iconProps, className, title } =
      DeviceFormatter.getDeviceStatusFormatter({
        id: `${deviceObj.oid}/${deviceObj.vdomOid}`,
        _oData,
      });
    Object.assign(res, { iconProps, className, title });
  }

  return res;
}

function getAutoId(name) {
  return `rc_content_view:${name}`;
}

function findTmpl(tmpls, name, key = 'name') {
  return tmpls.find((t) => t[key] === name);
}

async function getOriginalContent(selectedTemplate) {
  const adomName = fiAdom.current()?.name;

  const { name: templateName, isTemplateGrp } = selectedTemplate;
  const tmpls = await fiDvmScriptRequests.cliTemplateApi(adomName, 'get');
  if (!isTemplateGrp) {
    // isTemplateGrp from GUI
    const tmpl = findTmpl(tmpls, templateName);
    return tmpl.script;
  }

  const res = [];
  const tmplGrps = await fiDvmScriptRequests.cliTemplateGrpApi(adomName, 'get');
  const grp = findTmpl(tmplGrps, templateName);
  getTmplContentFromTmplGroup(grp, tmpls, tmplGrps, res);
  return res.join('\n');
}

async function getTmplContentFromTmplGroup(grp, tmpls, tmplGrps, res) {
  const members = grp.member;
  for (const memberName of members) {
    const tmpl = findTmpl(tmpls, memberName);
    const subGrp = findTmpl(tmplGrps, memberName);
    const isTmplGrp = !!subGrp;

    if (isTmplGrp) {
      // is template group
      getTmplContentFromTmplGroup(subGrp, tmpls, tmplGrps, res);
    } else {
      const script = tmpl.script || '';
      res.push(script);
    }
  }
}
