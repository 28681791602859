import { createSelector } from '@reduxjs/toolkit';

export const getDocker = (state) => state?.docker;

export const getDockerStatus = createSelector(
  getDocker,
  (docker) => docker?.dockerStatus
);
export const getCliDockerStatus = createSelector(
  getDocker,
  (docker) => docker?.cliDockerStatus
);

export const hasDockerEnabledAndDownloaded = createSelector(
  getCliDockerStatus,
  getDockerStatus,
  (cliDockerSt, dockerSt) => {
    return (
      cliDockerSt &&
      Object.values(dockerSt).some((app) =>
        [MACROS.SYS.DOCKER_ST_RUNNING, MACROS.SYS.DOCKER_ST_PULLING].includes(
          app.status
        )
      )
    );
  }
);
