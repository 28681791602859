import { isString } from 'lodash';

const UNESCAP_MAP: Record<string, string> = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#39;': "'",
  '&#x27;': "'",
  '&#92;': '\\',
  '&#x2F;': '/',
  '&#10;': '\n', // Newline symbol
  '&#34;': '"', // Double quote
  '&#9;': '\t', // Tab character
  '<br>': '\n', // Line break
  '<br/>': '\n', // Self-closing line break
  '<br />': '\n', // Self-closing line break with space
};

const ESCAPE_MAP: Record<string, string> = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '\\': '&#92;',
  '/': '&#47;',
};

export function escapeHtml(str: any, exceptAmp?: RegExp): string {
  const reg = exceptAmp ? /[<>"'\\]/g : /[&<>"'\\]/g;
  return forceString(str).replace(reg, function (m: string) {
    return ESCAPE_MAP[m];
  });
}

export function isCharCodeAlphaNumerical(code: number): boolean {
  return (
    (48 <= code && code <= 57) ||
    (65 <= code && code <= 90) ||
    (97 <= code && code <= 122)
  );
}

export function escapeHtmlAttr(str: any): string {
  str = forceString(str);
  for (let i = str.length - 1; i >= 0; --i) {
    const charCode = str.charCodeAt(i);
    if (charCode < 256 && !isCharCodeAlphaNumerical(charCode)) {
      const replacement = '&#' + charCode + ';';
      str = str.substring(0, i) + replacement + str.substring(i + 1);
    }
  }
  return str;
}

export function unescapeHtml(str: any, exceptAmp?: RegExp): string {
  const reg = exceptAmp
    ? /(&lt;)|(&gt;)|(&quot;)|(&#39;)|(&#x27;)|(&#92;)|(&#x2F;)|(&#10;)|(&#34;)|(&#9;)|(<br>)|(<br\/>)|(<br \/>)/g
    : /(&amp;)|(&lt;)|(&gt;)|(&quot;)|(&#39;)|(&#x27;)|(&#92;)|(&#x2F;)|(&#10;)|(&#34;)|(&#9;)|(<br>)|(<br\/>)|(<br \/>)/g;
  let prevStr = '';
  do {
    prevStr = str;
    str = forceString(str).replace(reg, function (m) {
      return UNESCAP_MAP[m] || m;
    });
  } while (str !== prevStr);

  return str;
}

export function highlightEscape(
  text: string | number,
  search: string | RegExp
): string {
  if (
    (!isString(search) && !(search instanceof RegExp)) ||
    (isString(search) && search.length === 0)
  ) {
    return escapeHtml(text);
  }

  if (!text && text !== 0) {
    text = '';
  } else if (!isString(text)) {
    // force it to be string, so if we accidentally
    // input an array, it will not cause vulnerability.
    text = '' + text;
  }

  let output = '';
  let nextIndex = 0;
  const regex =
    search instanceof RegExp
      ? search
      : new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
  text.replace(regex, (match: string, ...args: any[]): string => {
    // If the regex you construct contains subgroups (like)(this), String.replace handles parameters incorrectly according to specification.
    // Then we have to get the `offset` via `args`
    const offset = args[args.length - 2];
    // escape non-match substring before this match
    output += escapeHtml(text.substring(nextIndex, offset));

    output +=
      '<span class="fi-search-highlight">' + escapeHtml(match) + '</span>';
    nextIndex = offset + match.length;
    return '';
  });

  if (nextIndex !== text.length) {
    // everything leftover after the last match
    output += escapeHtml(text.substring(nextIndex, text.length));
  }
  return output;
}

export const escapeKey = (key: any) => {
  if (typeof key !== 'string') {
    return key;
  }
  return key.replace(/\\/g, '\\\\').replace(/\//g, '\\/').replace(/"/g, '\\"');
};

// ========== internal functions ============
function forceString(obj: any): string {
  let str: string = obj;
  if ((!obj && obj !== 0) || obj === 'null') {
    str = '';
  } else if (!isString(obj)) {
    // force it to be string, so if we accidentally
    // input an array, it will not cause vulnerability.
    str = '' + obj;
  }
  return str;
}
