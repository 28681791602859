import React, { useRef } from 'react';
import { Formik } from 'formik';
import { getTabAutoId } from '../tab_utils';
import { fiSysHostname } from 'fi-hostname';
import { fiSysConfig } from 'fi-session';
import { useWizard } from 'rc_wizard';
import { RegExpCommon } from 'kit-regexp';

import { NwButton } from '@fafm/neowise-core';
import { FmkInput, FmkErrorSpan } from 'rc_form';
import { NwProSection, NwProInputRow, NwProBody, NwProFooter } from 'rc_layout';
import { setSkippedStep } from '../../modal/utils';

export const SetHostname = ({ sysConfig }) => {
  const getAutoId = getTabAutoId('hostname');
  const product = fiSysConfig.isFmgOnly() ? 'FortiManager' : 'FortiAnalyzer';
  const description = gettext(
    'By default, this %s will use the serial number/model as its hostname. It is strongly recommended to set a descriptive hostname to make this device more identifiable.'
  ).printf([product]);

  const { goToNext } = useWizard();
  const formikRef = useRef(null);

  function submit(values, { setSubmitting }) {
    const errors = formikRef.current.errors;
    if (Object.keys(errors).length > 0) return Promise.reject();

    const submitted = fiSysHostname.set(values.hostname);
    if (submitted) {
      goToNext();
    }
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{
        hostname: sysConfig.hostname,
      }}
      innerRef={formikRef}
      onSubmit={submit}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <NwProBody>
            <NwProSection title={gettext('Specify Hostname')}>
              <p>{description}</p>
              <NwProInputRow label={gettext('Hostname')}>
                <FmkInput
                  name={'hostname'}
                  automationId={getAutoId('hostname')}
                  validate={(val) => {
                    if (!val) return gettext('This field is required.');
                    const containsSymbols =
                      val.search(RegExpCommon.RESTRICT_NAME_INCLUDE_DOT) < 0;
                    if (containsSymbols)
                      return gettext(
                        'Host name cannot contain \\ " > < ( ) \' & ` | ; # white space.'
                      );
                  }}
                />

                <FmkErrorSpan name={'hostname'} />
              </NwProInputRow>
            </NwProSection>
          </NwProBody>
          <NwProFooter>
            <NwButton
              onClick={submitForm}
              disabled={isSubmitting}
              automation-id={getAutoId('next-btn')}
              type={'primary'}
              className={'tw-min-w-32'}
            >
              {gettext('Next') + ' >'}
            </NwButton>
            <NwButton
              onClick={() => {
                setSkippedStep();
                goToNext();
              }}
              automation-id={getAutoId('later-btn')}
              className={'tw-min-w-32'}
              type={'default'}
            >
              {gettext('Later')}
            </NwButton>
          </NwProFooter>
        </>
      )}
    </Formik>
  );
};
