import { isEmpty, escapeRegExp, toString, escape } from 'lodash';

/** Generates a hightlighter that can insert spans.
 * The term is case-insensitive, no fuzzy search.
 */
export const genHighlighter = (term: string) => (text: any) => {
  const _text = toString(text);
  if (isEmpty(_text) || isEmpty(term)) {
    return _text;
  }
  // escape regex syntax
  const searchTerm = new RegExp(escapeRegExp(term), 'gi');
  return _text.replace(searchTerm, (match) => {
    return `<span class="tw-bg-yellow-200 tw-text-black">${escape(
      match
    )}</span>`;
  });
};
