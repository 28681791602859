import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  past: [],
  present: null,
  future: [],
};

const slice = createSlice({
  name: 'dashEditing',
  initialState,
  reducers: {
    undo(state) {
      if (state.past.length === 0) {
        return;
      }
      state.future.unshift(state.present);
      let previous = state.past.splice(state.past.length - 1, 1);
      state.present = previous[0];
    },
    redo(state) {
      if (state.future.length === 0) {
        return;
      }
      state.past.push(state.present);
      let next = state.future.splice(0, 1);
      state.present = next[0];
    },
    add(state, { payload: { gridItems, hint } }) {
      state.past.push(state.present);
      state.present = {
        data: {
          gridItems,
        },
        hint,
      };
    },
    init(state, { payload: { gridItems, hint } }) {
      state.past = [];
      state.present = {
        data: {
          gridItems,
        },
        hint,
      };
      state.future = [];
    },
    update_widget_data_by_name(state, { payload: { data, name, isTemp } }) {
      if (!data || !name) {
        return;
      }
      let widgets = state.present.data.widgets || [];
      let widget = widgets.find((widget) => widget.name === name);
      if (!widget) {
        return;
      }
      if (isTemp) {
        widget.tempData = {
          ...(widget.tempData || {}),
          ...data,
        };
      } else {
        widget.data = {
          ...(widget.data || {}),
          ...data,
        };
      }
    },
  },
});

export const { undo, redo, add, init, update_widget_data_by_name } =
  slice.actions;

export default slice.reducer;
