import { fiWebSocket } from 'fi-websocket';
import $ from 'jquery';

var _handles = {};
var _buffer = {};
var _initialized = false;

export const fiTaskByWebSocket = {
  monitor: _monitor,
  register: _register,
  unregister: _unregister,
  getTaskId: _getTaskId,
};

function _init() {
  if (!_initialized) {
    fiWebSocket.addListener('notify', _sharedHandler);
    _initialized = true;
  }
}

function _getTaskId(data) {
  var tid = data.fields && data.fields.reqid ? data.fields.reqid : data.id;

  return tid;
}

function _sharedHandler(data) {
  var tid = _getTaskId(data);

  if (data.collection !== 'task' || !tid) {
    return;
  }

  var fn = _handles[tid] || _bufferTask;
  if (fn) {
    fn(data);
  }

  // set timer remove this task if task is done
}

function _bufferTask(data) {
  var tid = _getTaskId(data);

  if (!_buffer[tid]) {
    _buffer[tid] = [];
  }

  _buffer[tid].push(data);
}

function _register(tid, fn) {
  _init();

  _handles[tid] = fn;

  // if has bufferred responses, then call the handler
  while (_buffer[tid] && _buffer[tid].length) {
    var resp = _buffer[tid].shift();
    fn(resp);
  }
}

function _unregister(tid) {
  _handles[tid] = null;
  delete _handles[tid];

  setTimeout(() => {
    _buffer[tid] = null;
    delete _buffer[tid];
  }, 1000);
}

function _monitor(tid, oCfg) {
  var cfg = oCfg || {};

  return _silence(tid, cfg);
}

function _silence(tid, oCfg) {
  _init();

  var defer = new $.Deferred();
  var cfg = oCfg || {};
  var handler = cfg.taskHandle || _taskHandle;

  if (cfg.cancel && cfg.cancel.then) {
    cfg.cancel.then(function () {
      _unregister(tid);
      defer.reject({
        status: 'cancelled',
        message: gettext('Task will be still running.'),
      });
    });
  }

  if (tid) {
    _register(tid, handler);
  } else {
    defer.reject({
      status: 'failed',
      message: gettext('Invalid task id'),
    });
  }

  return defer.promise();

  function _taskHandle(data) {
    if (data.collection !== 'task' || tid != _getTaskId(data)) {
      return;
    }

    _init();

    var resp = data.fields || {};

    if (resp.code >= 0) {
      if (resp.percentage >= 100) {
        fiWebSocket.removeListener('notify', _taskHandle);
        defer.resolve({
          status: 'ok',
          data: resp,
        });
      } else {
        defer.notify({
          percent: resp.percentage,
          message: resp.message,
        });
      }
    } else {
      fiWebSocket.removeListener('notify', _taskHandle);
      defer.reject({
        status: 'failed',
        message: resp.message,
      });
    }
  }
}
