import { NwAlert, NwIcon } from '@fafm/neowise-core';

export const AlertText = ({
  iconName,
  iconLabel,
  iconClasses = '',
  alertClasses = '',
  iconProps = {},
  alertType = 'neutral',
  text,
}) => {
  return (
    <div className={alertClasses}>
      <NwAlert
        open
        type={alertType}
        icon={
          <NwIcon
            className={iconClasses}
            name={iconName}
            label={iconLabel}
            {...iconProps}
          />
        }
      >
        <span>{text}</span>
      </NwAlert>
    </div>
  );
};

export const SuccessAlert = (props) => {
  const combined = Object.assign(
    {},
    {
      alertType: 'success',
      iconName: 'enable',
      iconLabel: gettext('Success'),
      iconClasses: 'color-green',
    },
    props
  );
  return <AlertText {...combined} />;
};

export const DangerAlert = (props) => {
  const combined = Object.assign(
    {},
    {
      alertType: 'danger',
      iconName: 'no',
      iconLabel: gettext('Error'),
      iconClasses: 'color-red',
    },
    props
  );
  return <AlertText {...combined} />;
};

export const WarningAlert = (props) => {
  const combined = Object.assign(
    {},
    {
      alertType: 'warning',
      iconName: 'warning',
      iconLabel: gettext('Warning'),
      iconClasses: 'color-orange',
    },
    props
  );
  return <AlertText {...combined} />;
};

export const InfoAlert = (props) => {
  const combined = Object.assign(
    {},
    {
      alertType: 'neutral',
      iconName: 'online-help',
      iconLabel: gettext('Info'),
    },
    props
  );
  return <AlertText {...combined} />;
};
