import { createSelector } from '@reduxjs/toolkit';
import { get, castArray } from 'lodash';

export const getSyntaxState = (state) => get(state, 'adom.adomSyntax');
const _select = (key) =>
  createSelector(getSyntaxState, (syntaxState) => get(syntaxState, key));

export const getSyntax = _select('syntax');
export const getLoadingState = _select('loading');
export const getSupportSyntaxCates = (cates) =>
  createSelector(getSyntaxState, ({ syntax }) => {
    cates = castArray(cates);
    return cates.every((cate) => !!syntax?.[cate]);
  });
