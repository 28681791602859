import { fiFmgHttp /*fiHttpBlobData, fiHttpPost*/ } from 'fi-http';
import { identity, get } from 'lodash';
import { fiAdom } from 'fi-session';
import { getAssignedPPkgList } from 'ra_pno_pkg_util';

const getUrl = (revid) => {
  const adom = fiAdom.current();
  let url = '';
  if (adom.globaldb) {
    url = '/dvmdb/global/revision';
  } else {
    url = `/dvmdb/adom/${adom.name}/revision`;
  }

  if (revid) {
    url += `/${revid}`;
  }

  return url;
};
const all = function (rowdata) {
  const parseData = rowdata
    ? identity
    : function parseData(origData) {
        return JSON.parse(JSON.stringify(origData).replace(/&quot;/g, '\\"'));
      };

  return fiFmgHttp
    .query({
      method: 'get',
      params: [
        {
          url: getUrl(),
          sortings: [
            {
              version: -1,
            },
          ],
        },
      ],
    })
    .then((resp) => {
      return parseData(resp[0]?.data || []);
    });
};

const del = function (keys) {
  let todel = Array.isArray(keys) ? keys : [keys];

  return fiFmgHttp.query({
    method: 'delete',
    params: todel.map((it) => {
      return {
        url: getUrl(it),
      };
    }),
  });
};

const lock = function (keys) {
  let tolock = Array.isArray(keys) ? keys : [keys];
  return fiFmgHttp.query({
    method: 'update',
    params: tolock.map((it) => {
      return {
        url: getUrl(it),
        data: {
          locked: 1,
        },
      };
    }),
  });
};

const unlock = function (keys) {
  let toUnlock = Array.isArray(keys) ? keys : [keys];

  return fiFmgHttp.query({
    method: 'update',
    params: toUnlock.map((it) => {
      return {
        url: getUrl(it),
        data: {
          locked: 0,
        },
      };
    }),
  });
};

const edit = function (data) {
  const key = data.version || null;
  const url = getUrl(key);
  return fiFmgHttp.query({
    method: key ? 'update' : 'add',
    params: [
      {
        url,
        data,
      },
    ],
  });
};

const getSettings = function () {
  return new Promise((resolve, reject) => {
    fiFmgHttp
      .post({
        method: 'get',
        url: '/gui/sys/adom/revision/settings',
      })
      .then(
        (resp) => {
          resolve(resp[0]?.data);
        },
        (err) => {
          reject(err);
        }
      );
  });
};

const updateSettings = function (data) {
  let ndata = Object.assign({}, data);

  return fiFmgHttp.post({
    method: 'update',
    url: '/gui/sys/adom/revision/settings',
    params: ndata,
  });
};

const hasAssigned = async function () {
  const adom = fiAdom.current();

  try {
    const resp = await getAssignedPPkgList(adom);
    const adomPkgs = get(resp.shift(), 'data', []);
    return adomPkgs.length ? true : false;
  } catch (e) {
    return false;
  }
};

const checkRestore = function (data) {
  return new Promise((resolve, reject) => {
    fiFmgHttp
      .query({
        method: 'get',
        params: [
          {
            url: getUrl(data.version),
          },
        ],
      })
      .then((resp) => {
        let cur = resp[0]?.data;
        if (cur) {
          resolve();
          return;
        }
        reject({
          message: gettext('Can not restore to selected version.'),
        });
      });
  });
};

const restore = function (rev) {
  const url = getUrl(rev.version);
  return fiFmgHttp.query({
    method: 'clone',
    params: [
      {
        url,
        data: {
          name: `Restored from ${rev.name}`,
          created_by: rev.created_by,
          desc: rev.desc,
          locked: rev.locked,
        },
      },
    ],
  });
};

export const adomRevRequest = {
  all,
  del,
  lock,
  unlock,
  edit,
  getSettings,
  updateSettings,
  hasAssigned,
  checkRestore,
  restore,
};
