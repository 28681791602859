export class Queue {
  list: any[];
  consumer: any;
  constructor(consumer: any) {
    this.list = [];
    this.consumer = consumer;
  }
  push(message: any) {
    this.list.push(message);
    this.process();
  }
  process() {
    const pump: any = () => {
      if (this.list.length <= 0 || !this.consumer(this.list[0])) return;

      this.list.shift();
      return pump.call(this);
    };

    return pump.call(this);
  }
  empty() {
    this.list = [];
  }
}

export class AsyncMsgQueue {
  list: any[];
  consumer: any;
  running: boolean;
  constructor(consumer: any) {
    this.list = [];
    this.consumer = consumer;
    this.running = false;
  }
  push(message: any) {
    this.list.push(message);
    if (this.running) return;

    setTimeout(() => {
      this.running = true;
      this.process();
      this.running = false;
    }, 0);
  }
  process() {
    const pump: any = () => {
      if (this.list.length <= 0) return;

      this.consumer(this.list[0]);

      this.list.shift();

      return pump.call(this);
    };

    pump.call(this);
  }
  empty() {
    this.list = [];
  }
}
