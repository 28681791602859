import { isString, isNil, toString } from 'lodash';

// sort the string tails number, for example: [port12, port9]=>[port9, port12]
export function compStrValuesWithNumber(
  attr1: string | null | undefined,
  attr2: string | null | undefined
): number {
  attr1 = !isNil(attr1) ? attr1 : '';
  attr2 = !isNil(attr2) ? attr2 : '';

  const num1: number | null = String(attr1).match(/\d+$/g)
    ? parseInt(String(attr1).match(/\d+$/g)![0])
    : null;
  const num2: number | null = String(attr2).match(/\d+$/g)
    ? parseInt(String(attr2).match(/\d+$/g)![0])
    : null;

  let str1 = isString(attr1) ? attr1 : '';
  let str2 = isString(attr2) ? attr2 : '';

  if (num1 !== null && str1.length > 0) {
    str1 = attr1.slice(0, attr1.indexOf(toString(num1)));
  }
  if (num2 !== null && str2.length > 0) {
    str2 = attr2.slice(0, attr2.indexOf(toString(num2)));
  }

  if (str1 === str2) {
    return (num2 ?? 0) - (num1 ?? 0);
  } else {
    return str1 > str2 ? -1 : 1;
  }
}
