import $ from 'jquery';
export const isObject = (val) => {
  return Object.prototype.toString.call(val) === '[object Object]';
};

export const isBlankObject = (val) => {
  return val !== null && typeof val === 'object' && !Object.getPrototypeOf(val);
};

export const isNull = (val) => {
  return Object.prototype.toString.call(val) === '[object Null]';
};

export const isDate = (val) => {
  return Object.prototype.toString.call(val) === '[object Date]';
};

export const isDefined = (val) => typeof val !== 'undefined';
export const isUndefined = (val) => typeof val === 'undefined';

export const isFunction = (val) => typeof val === 'function';
export const isArray = (val) => Array.isArray(val) || val instanceof Array;
export const isString = (val) => typeof val === 'string';
export const isNumber = (val) => typeof val === 'number';
export const isBoolean = (val) => typeof val === 'boolean';

export const isValue = (val) => {
  return val !== undefined && val !== null;
};

export const isWindow = (obj) => {
  return obj && obj.window === obj;
};

const TYPED_ARRAY_REGEXP =
  /^\[object (?:Uint8|Uint8Clamped|Uint16|Uint32|Int8|Int16|Int32|Float32|Float64)Array]$/;
export const isTypedArray = (val) => {
  return (
    val && isNumber(val.length) && TYPED_ARRAY_REGEXP.test(toString.call(val))
  );
};

export const isArrayBuffer = (obj) => {
  return toString.call(obj) === '[object ArrayBuffer]';
};

/**
 * @private
 * @param {*} obj
 * @return {boolean} Returns true if `obj` is an array or array-like object (NodeList, Arguments,
 *                   String ...)
 * code from angularJS:
 *   https://github.com/angular/angular.js/blob/master/src/Angular.js
 */
export const isArrayLike = (obj) => {
  // `null`, `undefined` and `window` are not array-like
  if (obj == null || isWindow(obj)) return false;

  // arrays, strings and jQuery/jqLite objects are array like
  // * jqLite is either the jQuery or jqLite constructor function
  // * we have to check the existence of jqLite first as this method is called
  //   via the forEach method when constructing the jqLite object in the first place
  if (isArray(obj) || isString(obj) || ($ && obj instanceof $)) return true;

  // Support: iOS 8.2 (not reproducible in simulator)
  // "length" in obj used to prevent JIT error (gh-11508)
  const length = 'length' in Object(obj) && obj.length;

  // NodeList objects (with `item` method) and
  // other objects with suitable length characteristics are array-like
  return (
    isNumber(length) &&
    ((length >= 0 && length - 1 in obj) || typeof obj.item === 'function')
  );
};

export const isPromise = (obj) => {
  return typeof obj === 'object' && typeof obj.then === 'function';
};

export const isSet = (val) => val instanceof Set;
