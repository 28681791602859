import { RouteObject } from 'react-router-dom';
import { reduce } from 'lodash';
import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import apps from 'fi-web/fi-routing/all-apps/rstadm';
import { useSelector } from 'react-redux';
import { getSessionAdomData } from 'fistore/session/adom/selectors';

const ROUTES = {
  path: 'rstadm',
  handle: {
    appUniKey: 'rstadm',
  },
  RenderErrorBoundary,
  lazy: async () => {
    const mod = await import(
      '../ra_pno_policy_tables/pno_main_view/RestrictMainView'
    );
    return {
      Component: () => {
        const adom = useSelector(getSessionAdomData);
        const adomOid = adom?.oid;

        React.useEffect(() => {
          import('fi-apps/fi-workspace').then(({ fiWorkspace }) => {
            fiWorkspace.adomStateInit();
          });
        }, []);

        React.useEffect(() => {
          if (adomOid) {
            import('fi-apps/fi-workspace').then(({ fiWorkspace }) => {
              fiWorkspace.pkgStateInit(adom);
            });
          }
        }, [adomOid]);

        return <mod.RestrictMainView />;
      },
    };
  },
  children: reduce(
    apps,
    (
      children,
      { path: appPath, reactRouteObject = { Component: null } },
      appUniKey
    ) => {
      if (appPath) {
        children.push({
          path: appUniKey,
          handle: {
            appUniKey,
          },
          ...reactRouteObject,
        });
      }
      return children;
    },
    [] as RouteObject[]
  ),
} as RouteObject;

export default ROUTES;
