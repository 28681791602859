import React, { useState } from 'react';

import { isFunction, isObject } from 'fiutil';
import PropTypes from 'prop-types';

import { ProLego, ProToolkit } from '@fafm/neowise-pro';
import {
  NwProHeader,
  NwProBody,
  NwProFooter,
  NwProSection,
  OkBtn,
  CancelBtn,
} from 'rc_layout';

const DEFAULT_MODAL_OPTIONS = {
  width: 'lg',
  height: '800px',
};

export const TreeSelect = ({
  value = [],
  source = [],
  disabledIds = [],
  readOnlyIds = [],
  idAttr = 'id',
  textAttr = 'text',
  formatItemHTML = null,
  onChange: onChangeFromProps,
  maxHeight = 250,
}) => {
  const onChange = (ids) => {
    onChangeFromProps(ids);
  };

  const selectProps = {
    value,
    source,
    disabledIds,
    readOnlyIds,
    idAttr,
    textAttr,
    onChange,
    maxHeight,
  };
  if (formatItemHTML) selectProps.formatItemHTML = formatItemHTML;

  return (
    <>
      <ProLego.SearchTree {...selectProps} />
    </>
  );
};

TreeSelect.propTypes = {
  //title for modal/drawer
  title: PropTypes.string,

  //attribute to get id from choices item
  idAttr: PropTypes.string,

  //attribute to get render text from choices item
  textAttr: PropTypes.string,

  //array of choices {id: 123, text: 'abc', oData: {...}}
  //optional attributes:
  //disabled: true if item disabled by default
  //excluded: true if item shows by default but not selectable
  //readOnly: true if item is readOnly
  source: PropTypes.arrayOf(PropTypes.object),

  //array of selected choices (same object type as choices or array of ids)
  initialSelected: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.object])
  ),

  //html formatter (item obj) => html string
  formatItemHTML: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([undefined]),
  ]),

  //callback on select/deselect
  //(selected ids[]) => {}
  onChange: PropTypes.func,
};

const TreeSelectModal = ({
  title = '',
  idAttr = 'id',
  textAttr = 'text',
  source = [],
  selected = [],
  disabledIds = [],
  readOnlyIds = [],
  formatItemHTML = null,
  setSelected = null,
  itemHeight = 30,
  $opener,
}) => {
  const [selectedIds, setSelectedIds] = useState(
    selected.map((row) => {
      if (!isObject(row)) return row;
      return row[idAttr];
    })
  );

  const onOk = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    $opener.resolve({ selected: selectedIds });
  };

  const onChange = (ids) => {
    if (isFunction(setSelected)) setSelected(ids);
    setSelectedIds(ids);
  };

  const selectProps = {
    value: selectedIds,
    source,
    disabledIds,
    readOnlyIds,
    idAttr,
    textAttr,
    onChange,
    itemHeight,
  };
  if (formatItemHTML) selectProps.formatItemHTML = formatItemHTML;

  const renderBody = () => {
    return (
      <NwProSection>
        <ProLego.SearchTree maxHeight={500} {...selectProps} />
      </NwProSection>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <OkBtn onClick={onOk}>{gettext('OK')}</OkBtn>
        <CancelBtn onClick={() => $opener.reject()}>
          {gettext('Cancel')}
        </CancelBtn>
      </>
    );
  };

  return (
    <>
      <NwProHeader>{title}</NwProHeader>
      <NwProBody>{renderBody()}</NwProBody>
      <NwProFooter>{renderFooter()}</NwProFooter>
    </>
  );
};

/***
 * In general use
 * params: {
 *  title: modal title
 *
 *  source: 'array of select choice objects [{id, text}]
 *
 *  selected: array of initially selected choices
 *    either [id strings] or [{id, text}]
 *
 *  formatItemHTML: if require further formatting
 *    (item) => HTML
 * }
 *
 * resolves ({selected: [selected ids]})
 */
export const openTreeSelectModal = ({
  $opener,
  title,
  params,
  modalOptions = DEFAULT_MODAL_OPTIONS,
}) => {
  const numberOfEntries = params.source ? params.source.length : 0;
  const itemHeight = params.itemHeight || 30;
  let modalHeight = numberOfEntries * itemHeight + 300;
  if (modalHeight > 800) modalHeight = 800;
  //fit modal size to component height

  if ($opener) {
    return $opener.open(<TreeSelectModal title={title} {...params} />, {
      height: modalHeight,
    }).result;
  } else {
    return ProToolkit.openModal(<TreeSelectModal title={title} {...params} />, {
      ...modalOptions,
      height: modalHeight,
    }).result;
  }
};
