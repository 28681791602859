import { useEffect, useRef } from 'react';
import { isFunction, isPromise } from 'fiutil';

export { useValidEffect, useAbortEffect, useUpdateEffect, useCombinedEffect };

/**
 * @param {(getIsValid: () => boolean)=>any} effect
 * @param {any[]} deps
 */
function useValidEffect(effect, deps = []) {
  useEffect(() => {
    let stillValid = true;
    const getIsValid = () => stillValid;

    const clean = effect(getIsValid);

    return () => {
      stillValid = false;
      if (isPromise(clean)) {
        clean.then((func) => {
          isFunction(func) && func();
        });
      } else {
        isFunction(clean) && clean();
      }
    };
  }, deps);
}

function useAbortEffect(effect, deps) {
  useEffect(() => {
    const abortCtrl = new AbortController();

    const clean = effect(abortCtrl);

    return () => {
      abortCtrl.abort();
      isFunction(clean) && clean();
    };
  }, deps);
}

function useUpdateEffect(effect, deps) {
  const isUpdateRef = useRef(false);

  useEffect(() => {
    if (isUpdateRef.current) {
      return effect();
    }
    isUpdateRef.current = true;
  }, deps);
}

/** Run an effect only once, no need to pass deps.
 * not in use yet.
 */
// export function useEffectOnce(effect) {
//   const done = useRef(false);
//   useEffect(() => {
//     if (!done.current) {
//       done.current = true;
//       return effect();
//     }
//   }, []);
// }

const isValidState = () => {
  let isValid = true;

  return {
    apply: (_state) => {
      return {
        ..._state,
        getIsValid: () => isValid,
      };
    },
    clean: () => {
      isValid = false;
    },
  };
};
const abortState = () => {
  const abortCtrl = new AbortController();

  return {
    apply: (_state) => {
      return {
        ..._state,
        abortCtrl: abortCtrl,
      };
    },
    clean: () => {
      return abortCtrl.abort();
    },
  };
};

const createEffectState = (stateHooks) => {
  const { effectState, cleanFns } = stateHooks.reduce(
    (_state, stateHook) => {
      const { apply, clean } = stateHook();
      _state.effectState = apply(_state.effectState);

      if (isFunction(clean)) {
        _state.cleanFns.push(clean);
      }

      return _state;
    },
    { effectState: {}, cleanFns: [] }
  );

  const cleanEffectState = () => {
    for (const fn of cleanFns) {
      fn();
    }
  };

  return [effectState, cleanEffectState];
};

function useCombinedEffect(
  effect,
  deps,
  stateHooks = [isValidState, abortState]
) {
  useEffect(() => {
    const [effectState, cleanEffectState] = createEffectState(stateHooks);
    const clean = effect(effectState);

    return () => {
      cleanEffectState();
      if (isPromise(clean)) {
        clean.then((func) => {
          isFunction(func) && func();
        });
      } else {
        isFunction(clean) && clean();
      }
    };
  }, deps);
}
