import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CodeMirror from '@fafm/codemirror';
import '@fafm/codemirror/mode/xml';
import '@fafm/codemirror/mode/css';
import '@fafm/codemirror/mode/javascript';
import '@fafm/codemirror/mode/htmlmixed';
import '@fafm/codemirror/mode/jinja2';
import cn from 'classnames';
import { useSelector } from 'react-redux';

// Components
import { Resizer } from 'react_components/rc_resizer/Resizer';
// CSS
import './index.less';
import { get, isFunction, isNil } from 'lodash';
import { addEscapeKeyboardTrap } from './util';
import {
  CODEMIRROR_CTN_ID,
  IFRAME_CODEMIRROR_BTN_CTN_CLASS,
  IFRAME_CTN_ID,
} from './constant';
import { fiStore, fiStoreUtils, fiStoreTheme } from 'fistore';
import { NwButton } from '@fafm/neowise-core';

const defaultParentDimensions = {
  width: '100%',
  height: '892px',
};

/**
 * Component to display an Iframe and a codemirror instance side by side.
 */
export const IframeCodemirror = (props) => {
  const {
    template,
    setValue,
    reset,
    dimensions = defaultParentDimensions,
    mode = 'htmlmixed',
    resize = true,
    filterContent = (content) => content,
    dependencies = [],
    codemirrorOpts = null,
    escapeKeyboardTrap = null,
    showIframe = true,
    refreshTimeout = 0,
    restoreDefaultBtnTxt = gettext('Restore Defaults'),
    extendIframe,
  } = props;

  const [cmInstance, setCMInstance] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [codemirrorOptsInitialized, setCodemirrorOptsInitialized] =
    useState(false);

  const isDarkTheme = useSelector(fiStoreTheme.getIsDarkTheme);

  const colorNeutral0 = useSelector(
    fiStoreTheme.getThemeVar('color-neutral-0')
  );
  const colorNeutral1000 = useSelector(
    fiStoreTheme.getThemeVar('color-neutral-1000')
  );

  /** ================= Init ===================== */
  // Setup codemirror instance
  useEffect(() => {
    // Prevents multiple code mirror instances from being created
    const cm = configCodeMirror();
    setCMInstance(cm);

    // Handle document changes from CodeMirror
    cm.on('inputRead', onCodemirrorChangeEvent); // paste
    cm.on('change', onCodemirrorChangeEvent);
    cm.refresh();

    return () => {
      cm.off('inputRead', onCodemirrorChangeEvent);
      cm.off('change', onCodemirrorChangeEvent);
      removeCodeMirrorInstance(cm);
    };
  }, []);

  // Add custom events to the codemirror instance, i.e. Tooltip
  useEffect(() => {
    if (
      !codemirrorOpts ||
      !cmInstance ||
      !initialized ||
      codemirrorOptsInitialized
    )
      return;

    const { events } = codemirrorOpts;

    if (events) {
      const eventsToRemove = [];

      for (const event of events) {
        let { target, eventType, func } = event;
        target = typeof target === 'function' ? target() : cmInstance;
        if (target) {
          target.addEventListener(eventType, func);
          eventsToRemove.push(() =>
            target.removeEventListener(eventType, func)
          );
        }
      }

      return () => {
        for (const removeEvent of eventsToRemove) removeEvent();
      };
    }

    setCodemirrorOptsInitialized(true);
  }, [cmInstance, initialized]);

  // initialize frames - set content of iframe/codemirror
  useEffect(() => {
    if (!initialized && template !== undefined && template !== null) {
      setInitialized(true);
      updateFrames(template);

      const unobserve = fiStoreUtils.observeStore(
        fiStore,
        fiStoreTheme.getCurrentThemeName,
        () => {
          updateFrames(template);
        }
      );

      return unobserve;
    }
  }, [template]);

  useEffect(() => {
    const iframe = document.getElementById('templatePreviewFrame');
    const existingStyles =
      iframe?.contentDocument?.getElementsByTagName('style');
    for (const existingStyle of existingStyles) {
      existingStyle.remove();
    }
    const style = getStyleElement();
    iframe?.contentDocument?.head?.appendChild(style);
  }, [colorNeutral0, colorNeutral1000]);

  // Update the iframe based on certain dependencies such as image loading
  useEffect(() => {
    updateFrames(template);
  }, dependencies);

  // setups and initializes the codemirror instance
  const configCodeMirror = () => {
    let textArea = document.getElementById('templateTextarea');
    textArea.value = template || '';
    let cm = cmInstance;
    if (!cm) {
      cm = CodeMirror.fromTextArea(textArea, {
        lineNumbers: true,
        mode: mode,
      });
    }

    // Set mode
    if (cm.getMode()?.name !== mode) {
      cm.setOption('mode', mode);
    }

    // Attach extra keys if defined
    let extraKeysConfig = codemirrorOpts?.extraKeys;
    if (escapeKeyboardTrap) {
      const escapeKeyboardTrapBinding = addEscapeKeyboardTrap(
        escapeKeyboardTrap,
        reset
      );
      if (extraKeysConfig)
        extraKeysConfig = { ...extraKeysConfig, ...escapeKeyboardTrapBinding };
      else extraKeysConfig = escapeKeyboardTrapBinding;
    }

    if (extraKeysConfig) cm.setOption('extraKeys', extraKeysConfig);

    return cm;
  };

  /** ================= Update Functions ===================== */
  const getStyleElement = () => {
    const style = document.createElement('style');
    style.setAttribute('id', 'theme-styles');
    const important = isDarkTheme ? '!important' : '';
    style.textContent = `body { color: ${colorNeutral1000} ${important}; background-color: ${colorNeutral0} ${important};}`;
    return style;
  };

  const updatePreview = (content = null) => {
    const iframe = document.getElementById('templatePreviewFrame');
    const doc = iframe?.contentWindow?.document;
    if (!doc) return;
    doc.open();
    const filteredContent = filterContent(content) || '';
    doc.write(filteredContent);
    if (isFunction(extendIframe)) extendIframe(doc); // Add additional modifiers to iframe if needed
    doc.close();

    iframe?.contentDocument?.head?.appendChild(getStyleElement());
  };

  const updateCodeMirror = (value) => {
    if (cmInstance) {
      cmInstance.setValue(value);
    } else {
      document.querySelector('.CodeMirror')?.CodeMirror?.setValue(value);
    }
  };

  const updateFrames = (val) => {
    if (val) {
      updateCodeMirror(val);
      if (typeof codemirrorOpts?.setup === 'function')
        codemirrorOpts.setup(cmInstance);
      updatePreview(val);
    }
  };

  /** ============= Event Handlers ================ */

  const onCodemirrorChangeEvent = (instance) => {
    const newValue = instance.getValue();
    setValue(newValue);
    updatePreview(newValue);
    // can delay the refresh if the page takes some time to load
    setTimeout(() => {
      instance.refresh();
    }, refreshTimeout);
  };

  const restoreDefaultHandler = async () => {
    let defaultTemplate = '';

    try {
      defaultTemplate = await reset();
    } catch (e) {
      defaultTemplate = template;
    }

    setValue(defaultTemplate);
    updateFrames(defaultTemplate);
    cmInstance.refresh();
  };

  /** ================= Render ==================== */

  return (
    /* Change width/height of div.iframe-codemirror to determine width and height of iframe/Codemirror */
    <div className='iframe-codemirror' style={dimensions}>
      <div className='iframe-codemirror-view'>
        {/* Iframe Section */}
        {showIframe && (
          <>
            <div className='iframe-ctn' id={IFRAME_CTN_ID}>
              <iframe id='templatePreviewFrame'></iframe>
            </div>
            {resize && (
              <Resizer
                parentCtn='.iframe-codemirror'
                rightConfig={{ selector: `#${CODEMIRROR_CTN_ID}` }}
                leftConfig={{
                  selector: `#${IFRAME_CTN_ID}`,
                  iframe: '#templatePreviewFrame',
                }}
              />
            )}
          </>
        )}

        {/* Codemirror Section */}
        <div
          className={cn({
            'codemirror-ctn': showIframe,
            'tw-w-full': !showIframe,
          })}
          id={CODEMIRROR_CTN_ID}
        >
          <textarea id='templateTextarea'></textarea>
        </div>
      </div>

      {/* Button Container */}
      <div className={IFRAME_CODEMIRROR_BTN_CTN_CLASS}>
        {reset && (
          <NwButton type='default' onClick={restoreDefaultHandler}>
            {restoreDefaultBtnTxt}
          </NwButton>
        )}
      </div>
    </div>
  );
};

IframeCodemirror.propTypes = {
  template: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  dimensions: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  mode: PropTypes.string,
  reset: PropTypes.func,
  resize: PropTypes.bool,
  filterContent: PropTypes.func,
  dependencies: PropTypes.array,
  codemirrorOpts: PropTypes.shape({}),
  escapeKeyboardTrap: PropTypes.shape({
    shortcut: PropTypes.oneOfType[(PropTypes.string, PropTypes.null)],
    nextElementSelector:
      PropTypes.oneOfType[(PropTypes.string, PropTypes.null)],
  }),
  // allow to hide the iframe
  showIframe: PropTypes.bool,
  // delay refreshing codemirror after content changes
  refreshTimeout: PropTypes.number,
  restoreDefaultBtnTxt: PropTypes.string,
  extendIframe: PropTypes.func,
};

const removeCodeMirrorInstance = (cmInstance) => {
  if (isNil(cmInstance) || !isFunction(get(cmInstance, 'getWrapperElement'))) {
    return;
  }

  const wrapperElement = cmInstance.getWrapperElement();
  if (isNil(wrapperElement)) {
    return;
  }

  wrapperElement.parentNode.removeChild(wrapperElement);
};
