import React, { useCallback } from 'react';
import { NwIcon, NwIconButton } from '@fafm/neowise-core';
import { fiFmgHttp } from 'fi-http';
import { fiMessageBox } from 'fi-messagebox';
import { fiAdminProfile } from 'fi-session';

const killURL = '/cli/global/exec/killprocess';

const killProcess = (pid) => {
  const req = {
    method: 'exec',
    params: [
      {
        url: killURL,
        data: {
          pid,
        },
      },
    ],
  };
  return fiFmgHttp
    .query(req)
    .then((resp) => {
      if (resp?.[0]?.status?.code) {
        fiMessageBox.show(resp[0].status.message, 'danger');
      } else {
        fiMessageBox.show(gettext('Process killed'), 'success');
      }
    })
    .catch((err) => {
      let errMsg = err?.data?.result?.[0]?.status?.message;
      errMsg && fiMessageBox.show(errMsg, 'danger');
    });
};

export const useCommands = (isPaused, setIsPaused) => {
  const getToolbarItems = useCallback(
    (selectedRows) => {
      let res = [
        {
          key: 'pause',
          render: () => {
            return (
              <NwIconButton
                library='fa-solid'
                name={isPaused ? 'play' : 'pause'}
                onClick={() => {
                  setIsPaused((p) => !p);
                }}
                className='tw-flex tw-items-center'
              ></NwIconButton>
            );
          },
        },
      ];
      if (fiAdminProfile.isSuperAdmin()) {
        res.push({
          key: 'kill-process',
          label: gettext('Kill Process'),
          icon: <NwIcon library='fa-solid' name='times-circle'></NwIcon>,
          disabled: selectedRows?.length !== 1,
          exec: () => {
            let pid = parseInt(selectedRows[0].pid);
            pid && killProcess(pid);
          },
        });
      }
      return res;
    },
    [isPaused]
  );

  const getContextMenuItems = useCallback(
    (record) => {
      let toolBarItems = getToolbarItems(record);
      let res = toolBarItems.find((x) => x.key === 'kill-process');
      return res ? [res] : [];
    },
    [getToolbarItems]
  );

  return [getToolbarItems, getContextMenuItems];
};
