import { createFetchAction, createPromiseAction } from '../../utils';
import { createAction } from '@reduxjs/toolkit';

export const devicesFirmwareAction = {
  ...createFetchAction('devices-firmware'),
};

export const fetchDevicesFirmwareAction = createPromiseAction(
  'devices-firmware/FETCH_DEVICES_FIRMWARE'
);

export const fetchFirmwareManualUpgradeAction = createPromiseAction(
  'devices-firmware/FETCH_FIRMWARE_MANUAL_UPGRADE'
);

export const deleteFirmwareManualUpgradeAction = createPromiseAction(
  'devices-firmware/DELETE_FIRMWARE_MANUAL_UPGRADE'
);

export const setFirmwareManualUpgradeAction = createAction(
  'devices-firmware/SET_FIRMWARE_MANUAL_UPGRADE'
);
