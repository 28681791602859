import { fiSession } from 'fistore';
import { negate } from 'lodash';
const {
  inAdom,
  adomNames: an,
  inFazCollectorMode,
  inGlobalAdom,
  inOthersAdom,
  showFazApps,
  hasRDPermitOn,
} = fiSession;

export default {
  report: {
    label: gettext('Reports'),
    icon: 'reports',
    color: '#b4d56b',
    state: 'adom.default.report.defs.reports',
    reloadState: 'adom.default.report.defs.reports',
    gdprTool: true,
    showCheckers: [
      showFazApps,
      negate(inAdom([an.FortiSwitch])),
      negate(inFazCollectorMode),
      negate(inOthersAdom),
      negate(inGlobalAdom),
      hasRDPermitOn('report_viewer'),
    ],
  },
  report_generated: {
    path: '/report/generated',
    label: gettext('Generated Reports'),
    state: 'adom.default.report.generated',
    olhTag: 'report_generated',
    olhCategory: 'report',
    olhFaz: 'Report -> Generated Reports',
  },
  report_defs: {
    path: '/report/defs',
    label: gettext('Report Definitions'),
    state: 'adom.default.report.defs',
  },
  report_defs_reports: {
    path: '/report/defs/reports',
    label: gettext('All Reports'),
    state: 'adom.default.report.defs.reports',
    olhTag: 'report_reports',
    olhCategory: 'report',
    olhFaz: 'Report -> Report Definitions -> All Reports',
  },
  report_defs_templates: {
    path: '/report/defs/templates',
    label: gettext('Templates'),
    state: 'adom.default.report.defs.templates',
    OlhTag: 'report_templates',
    olhCategory: 'report',
    olhFaz: 'Report -> Report Definitions -> Templates',
  },
  report_defs_charts: {
    path: '/report/defs/charts',
    label: gettext('Chart Library'),
    state: 'adom.default.report.defs.charts',

    olhTag: 'report_charts',
    olhCategory: 'report',
    olhFaz: 'Report -> Report Definitions -> Chart Library',
  },
  report_defs_macros: {
    path: '/report/defs/macros',
    label: gettext('Macro Library'),
    state: 'adom.default.report.defs.macros',
    olhTag: 'report_macros',
    olhCategory: 'report',
    olhFaz: 'Report -> Report Definitions -> Macro Library',
  },
  report_defs_datasets: {
    path: '/report/defs/datasets',
    label: gettext('Datasets'),
    state: 'adom.default.report.defs.datasets',
    olhTag: 'report_datasets',
    olhCategory: 'report',
    olhFaz: 'Report -> Report Definitions -> Datasets',
  },
  report_advanced: {
    path: '/report/adv/',
    label: gettext('Advanced Settings'),
    state: 'adom.default.report.adv',
  },
  report_advanced_calendar: {
    path: '/report/adv/calendar',
    label: gettext('Report Calendar'),
    state: 'adom.default.report.adv.calendar',
    olhTag: 'report_calendar',
    olhCategory: 'report',
    olhFaz: 'Report -> Advanced -> Report Calendar',
  },
  report_advanced_lang: {
    path: '/report/adv/lang',
    label: gettext('Language'),
    state: 'adom.default.report.adv.lang',

    olhTag: 'report_language',
    olhCategory: 'report',
    olhFaz: 'Report -> Advanced -> Languages',
  },
  report_advanced_outputprofiles: {
    path: '/report/adv/outputprofiles',
    label: gettext('Output Profile'),
    state: 'adom.default.report.adv.outputprofiles',
    olhTag: 'report_outputprofiles',
    olhCategory: 'report',
    olhFaz: 'Report -> Advanced -> Output Profile',
  },
};
