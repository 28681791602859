import cn from 'classnames';
import { NwIcon } from '@fafm/neowise-core';

import * as syntaxUtil from './syntax_util';

import * as diffColDef from './diff_col_def';
import { last } from 'lodash';

import * as fiPnOFilter from './filter';
export { DeviceCertificate } from './dvm_certificate';

export const renderObjIcon = (css = {}, { onMouseEnter, style } = {}) => {
  return css.icon ? (
    <NwIcon
      onMouseEnter={onMouseEnter}
      style={style}
      name={css.icon.name}
      className={cn('tw-mr-1', css.icon.classes)}
      title={css.icon.title}
      library={css.icon.library || 'fafm'}
    ></NwIcon>
  ) : null;
};
export const fiSyntaxUtil = syntaxUtil;
export { diffColDef };
export { fiPnOFilter };

const isDivider = (item) => !item?.label;

export const skipInvalidDivider = (items) => {
  const result = items.reduce((result, item) => {
    if (isDivider(item)) {
      // skip double divider
      if (!isDivider(result[result.length - 1])) {
        result.push(item);
      }
    } else {
      // command
      result.push(item);
    }

    return result;
  }, []);

  if (isDivider(result[0])) {
    result.splice(0, 1);
  }

  if (isDivider(result[result.length - 1])) {
    result.splice(result.length - 1, 1);
  }

  return result;
};

const appStack = [];
export const getCurrentPnoApps = () => last(appStack) || {};
export const pushPnoAppStack = (apps) => appStack.push(apps);
export const popPnoAppStack = () => appStack.pop();

export * from './hooks';
export { ERR_MSG_PATH, dRespData, dErrMsg } from './requests';
