import React, { useCallback } from 'react';
import { isFunction } from 'fiutil';
import { sortStringWithNumber } from 'kit-compare';
import { FmkObjSSelect2 } from './FmkObjSSelect2';
import { getInterfaceSelectFormatter } from 'rc_select';
import { fiDvmTableCellParser } from 'fi-dvm';

/**
 * Must return object in the form of
 * {
 *   id: string,
 *   val: string,
 *   text: string,
 *   title: string
 *   ip: string - ip
 * }
 * @param {*} interface
 * @param {*} cellParser
 * @returns Object
 */
const defaultDataGetter = (intf, cellParser) => {
  return Object.assign({}, intf, {
    id: intf?.id || intf.name,
    val: intf?.id || intf.name,
    text: intf.name,
    ititle: cellParser.ititle,
    detail: intf?._detail || '',
    ip: Array.isArray(intf?.ip) ? intf.ip.join('/') : intf.ip || '',
    icon: intf?.icon || cellParser.icon,
  });
};

export const parseInterfaceChoicesSync = (
  data,
  dataGetter = defaultDataGetter
) => {
  let result = [];
  if (!Array.isArray(data)) return result;

  data = sortStringWithNumber(data, 'name');
  result = [...data].map((intf) => {
    const cellParser = fiDvmTableCellParser.getInterfacePortCss(intf);
    return dataGetter(intf, cellParser);
  });

  return result;
};

export const parseInterfaceChoices = async (
  data,
  dataGetter = defaultDataGetter
) => {
  if (!Array.isArray(data)) {
    data = isFunction(data) ? await data() : [];
  }
  return parseInterfaceChoicesSync(data, dataGetter);
};

const useIntfSource = ({ dataGetter, intfData, intfExtra, sortChoices }) => {
  const parseIntfExtra = (data = []) => {
    return data.map((intf) => {
      if (!intf.val) intf.val = intf.id;
      return intf;
    });
  };

  return useCallback(async () => {
    const parsedChoices = await parseInterfaceChoices(intfData, dataGetter);
    let choices = [...parseIntfExtra(intfExtra), ...parsedChoices];
    if (isFunction(sortChoices)) {
      choices = sortChoices(choices);
    }
    return choices;
  }, [intfData, intfExtra]);
};

export const FmkIntfSSelect = ({
  intfData,
  intfExtra = undefined,
  sortChoices = null,
  dataGetter = defaultDataGetter,
  ...rest
}) => {
  const loadIntfSource = useIntfSource({
    dataGetter,
    intfData,
    intfExtra,
    sortChoices,
  });

  return (
    <FmkObjSSelect2
      source={loadIntfSource}
      {...getInterfaceSelectFormatter()}
      {...rest}
    />
  );
};
