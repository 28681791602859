import cn from 'classnames';

import { fiStoreRouting as fr } from 'fistore';
import { ContentMenu } from './content_menu/ContentMenu';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { isFunction } from 'lodash';
import { ContentMenuReloadOutlet } from 'react_apps/ra_main/main_layout/ReloadLocation';

export const ReactContentViewContainer = ({
  menuId,
  className = '',
  allowToggleDirection = true,
  fixLayout = false, // Override layout state
  showContentMenu = () => true,
  ...rest
}) => {
  const currentLayout = useSelector(fr.getCurrentContentMenuLayout);
  const layout = useMemo(() => {
    return fixLayout || currentLayout;
  }, [fixLayout, currentLayout]);

  const outletContext = useOutletContext();

  const menuProps = {
    menuId,
    layout,
    allowToggleDirection,
    ...rest,
  };

  const _showContentMenu = isFunction(showContentMenu)
    ? showContentMenu()
    : true;
  return (
    <div
      className={cn('tw-flex tw-h-full tw-w-full', className, {
        'tw-flex-col': layout === 'horizontal',
      })}
    >
      {_showContentMenu && <ContentMenu {...menuProps} />}
      <div className='tw-w-full tw-h-full tw-overflow-y-auto page-content-wrapper'>
        <ContentMenuReloadOutlet context={outletContext} />
      </div>
    </div>
  );
};
