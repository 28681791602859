import { createSelector } from '@reduxjs/toolkit';
import { findIndex, isUndefined } from 'lodash';
import { propOr } from 'fistore/utils/prop-or';
import {
  getSysTimeZone,
  isFmg,
  isFaz,
  hasFazFeature,
  getAvailableVersionsByOsType,
} from 'fistore/session/sysConfig/selectors';
import { isGlobalAdom } from 'fistore/session/adom/utils';

const mcrD = MACROS.DVM;

export const adomNames = {
  FortiGate: mcrD.DVM_RESTRICTED_PRD_FOS,
  FortiCarrier: mcrD.DVM_RESTRICTED_PRD_FOC,
  FortiAnalyzer: mcrD.DVM_RESTRICTED_PRD_FAZ,
  FortiCache: mcrD.DVM_RESTRICTED_PRD_FCH,
  FortiClient: mcrD.DVM_RESTRICTED_PRD_FCT,
  FortiDDoS: mcrD.DVM_RESTRICTED_PRD_FDD,
  FortiAuthenticator: mcrD.DVM_RESTRICTED_PRD_FAC,
  FortiNAC: mcrD.DVM_RESTRICTED_PRD_FNA,
  FortiMail: mcrD.DVM_RESTRICTED_PRD_FML,
  FortiManager: mcrD.DVM_RESTRICTED_PRD_FMG,
  FortiSandbox: mcrD.DVM_RESTRICTED_PRD_FSA,
  FortiSwitch: mcrD.DVM_RESTRICTED_PRD_FSW,
  FortiWeb: mcrD.DVM_RESTRICTED_PRD_FWB,
  Syslog: mcrD.DVM_RESTRICTED_PRD_LOG,
  SecurityFabric: mcrD.DVM_RESTRICTED_PRD_FSF,
  FortiDeceptor: mcrD.DVM_RESTRICTED_PRD_FDC,
  FortiFirewall: mcrD.DVM_RESTRICTED_PRD_FFW,
  FortiFirewallCarrier: mcrD.DVM_RESTRICTED_PRD_FWC,
  FortiProxy: mcrD.DVM_RESTRICTED_PRD_FPX,
};

export function getSessionAdom(state) {
  return state?.session?.adom;
}

export const getSessionAdomLoaded = createSelector(
  getSessionAdom,
  (adomState) => !adomState.loading && adomState.loaded
);

const _select = (key, defaultVal = undefined) =>
  createSelector(getSessionAdomData, propOr(key, defaultVal));

export const getSessionAdomLoading = createSelector(
  getSessionAdom,
  (adom) => adom?.loading
);

export const getSessionAdomData = createSelector(
  getSessionAdom,
  (adom) => adom?.data
);

export const getSessionAdomType = createSelector(
  getSessionAdomData,
  (data) => data?.type
);

export const hasRemoteFAZ = createSelector(
  getSessionAdomData,
  (data) => (data?.fazoid || 0) > 0
);

export const getSessionAdomName = createSelector(
  getSessionAdomData,
  (data) => data?.displayName || data?.name
);

export const getSessionAdomOid = _select('oid');

export const getAdomTimeZone = _select('timezone');

export const getIsGlobalAdom = createSelector(getSessionAdomData, isGlobalAdom);

export const getIsBackupAdom = createSelector(getSessionAdomData, (adom) => {
  // note the backup could be -1 for adoms that cannot be in backup mode.
  return adom?.backup === 1;
});

export const getIsFabricAdom = _select('is_fabric');
export const getAdomOSType = _select('adom_os_type');

/************************************************************
  ADOM checkers
************************************************************/

export const inAdom = (adomTypes) =>
  createSelector(getSessionAdomData, (adom) => {
    // adom can be null
    if (!adom) return false;
    // current ADOM is Fabric,  Fabric is a combination of all ADOMs, & is always true.
    if (adom.type === adomNames.SecurityFabric) {
      return adomTypes.includes(adomNames.SecurityFabric);
    }
    // Use & because ADOM can be combined.
    // & operator does not support number > 32bit.
    return adomTypes.some((type) => (type & adom.type) === type);
  });

export const inManageableAdoms = inAdom([
  adomNames.SecurityFabric,
  adomNames.FortiGate,
  adomNames.FortiCarrier,
  adomNames.FortiFirewall,
  adomNames.FortiFirewallCarrier,
  adomNames.FortiProxy,
]);

export const checkVersion = (version, op) =>
  createSelector(getSessionAdomData, (adom) => {
    const [ver, mr] = String(version)
      .split('.')
      .map((it) => parseInt(it, 10));
    const intVal = ver * 100 + mr;
    try {
      if (adom.version) {
        var curStr = adom.version.ver * 100 + adom.version.mr;
        switch (op) {
          case '<':
            return curStr < intVal;
          case '<=':
            return curStr <= intVal;
          case '>':
            return curStr > intVal;
          case '>=':
            return curStr >= intVal;
          default:
            return curStr == intVal;
        }
      }
    } catch (err) {
      // we need to know what unexpected is happening
      console.error(err);
    }
    return false;
  });

export const isCentralManagement = (type) =>
  createSelector(getSessionAdomData, (adom) => {
    return adom[type] === 1;
  });

export const inBackupAdom = createSelector(getSessionAdomData, (adom) => {
  return adom.backup === 1;
});

export const inOthersAdom = _select('is_others');

export const inGlobalAdom = _select('globaldb');

export const inFpxAdom = _select('is_fpx');

// Firewall ADOM
export const inFFWAdom = _select('is_ffw');

// FirewallCarrier ADOM
export const inFWCAdom = _select('is_fwc');

export const isRootAdom = createSelector(getSessionAdomOid, (adomOid) => {
  return adomOid === mcrD.CDB_DEFAULT_ROOT_OID;
});

export const hasCsfGroups = createSelector(getSessionAdomData, (adom) => {
  return adom.csf_counts && adom.csf_counts > 0;
});

export const getTimeZoneAuto = createSelector(
  getAdomTimeZone,
  getSysTimeZone,
  (adomTz, sysTz) => adomTz?.file || sysTz?.timezonename
);

// faz checkers
export const showFazApps = createSelector(
  hasFazFeature,
  hasRemoteFAZ,
  (hasFaz, hasRemote) => {
    // In fmg, show the faz-related app icons when
    // 1. faz feature is enabled -- local faz
    // 2. an faz is attached to the adom -- remote faz
    if (isFmg()) {
      return hasFaz || hasRemote;
    }
    return isFaz() || hasFaz;
  }
);

export const getAvailableAdomVersions = createSelector(
  getSessionAdom,
  (adom) => adom?.availableVersions
);

const getNextVerStr = (ver, mr) =>
  createSelector(getAvailableAdomVersions, (versions) => {
    if (ver == 4 && mr == 3) return '5.0'; //hard code 4.3 upgrade target

    const foundIdx = findIndex(versions, ['ver', ver]);
    if (foundIdx === -1) return '';

    const verObj = versions[foundIdx];
    const mrIdx = verObj.mrs.indexOf(mr);
    let nextMr;
    if (mrIdx >= 0) nextMr = verObj.mrs[mrIdx + 1];

    if (nextMr) {
      return `${verObj.verlabel}.${nextMr}`;
    }

    const next = versions[foundIdx + 1];
    return next ? `${next.verlabel}.${next.mr}` : '';
  });

export const getNextVerStrByOsType = ({ ver, mr, osType }) =>
  createSelector(
    getAvailableVersionsByOsType(osType),
    getNextVerStr(ver, mr),
    (avlVers, nextVerStr) => {
      if (ver == 4 && mr == 3) return '5.0'; //hard code 4.3 upgrade target
      if (isUndefined(osType)) return nextVerStr;
      const nextVer = avlVers[findIndex(avlVers, { ver, mr }) + 1];
      return nextVer ? `${nextVer.ver}.${nextVer.mr}` : '';
    }
  );

export const hasMultipleDevType = createSelector(getSessionAdomData, (adom) => {
  return adom?.type === MACROS.DVM.DVM_RESTRICTED_PRD_FSF;
});

export const getIsConfigurableType = createSelector(
  getSessionAdomType,
  (type) => {
    return (
      type === MACROS.DVM.DVM_RESTRICTED_PRD_FOS ||
      type === MACROS.DVM.DVM_RESTRICTED_PRD_FOC ||
      type === MACROS.DVM.DVM_RESTRICTED_PRD_FFW ||
      type === MACROS.DVM.DVM_RESTRICTED_PRD_FWC ||
      type === MACROS.DVM.DVM_RESTRICTED_PRD_FPX ||
      type === MACROS.DVM.DVM_RESTRICTED_PRD_FSF
    );
  }
);

const getWorkspaceMode = _select('workspace_mode');
export const getIsWorkspaceDisabled = createSelector(
  getWorkspaceMode,
  (mode) => mode === 'disabled'
);
export const getIsWorkspaceEnabled = createSelector(
  getWorkspaceMode,
  (mode) => mode !== 'disabled'
);
export const getIsNormalMode = createSelector(
  getWorkspaceMode,
  (mode) => mode === 'workspace'
);
export const getIsWorkflow = createSelector(
  getWorkspaceMode,
  (mode) => mode === 'workflow'
);
