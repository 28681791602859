import { useCallback, useRef } from 'react';
import { castArray, transform } from 'lodash';

import { useApiRequest } from 'rh_util_hooks';

import { getTemplateOptionList } from './utils';

export { useTemplateChoices };

const PT_TYPE = MACROS.USER.DVM.PT_TYPE;

// Currently supported pt types
const SUPPORTED_PT_TYPES = [
  PT_TYPE.TEMPLATE_GROUP,
  PT_TYPE.SYSTEM_TEMPLATE,
  PT_TYPE.CLI_TEMPLATE,
  PT_TYPE.IPSEC_TEMPLATE,
  PT_TYPE.ROUTE_TEMPLATE,
  PT_TYPE.BGP_TEMPLATE,
  PT_TYPE.SDWAN_TEMPLATE,
  PT_TYPE.IPS_TEMPLATE,
];

const getSupportedPromiseMap = (templateTypes) => {
  const typeMap = {};

  for (const ptType of templateTypes) {
    if (!SUPPORTED_PT_TYPES.includes(ptType)) {
      if (MACROS.SYS.CONFIG_DEBUG) {
        console.error(
          `Template type, ${ptType}, is not supported, please double check the spelling.`
        );
      }
      continue;
    }

    typeMap[ptType] = null;
  }

  return typeMap;
};

const useTemplateChoices = ({
  initValue,
  adom,
  dependencies = [],
  templateTypes = SUPPORTED_PT_TYPES,
  filterPreRunCli = true,
}) => {
  const promiseMapRef = useRef(getSupportedPromiseMap(templateTypes));
  const abortCtrlRef = useRef();

  const setTemplatePromise = (ptType) => {
    return getTemplateOptionList(
      ptType,
      adom,
      filterPreRunCli,
      abortCtrlRef.current
    );
  };

  const {
    state: templateChoices,
    setState: setTemplateChoices,
    refresh,
    isLoading,
  } = useApiRequest({
    defaultValue:
      initValue ||
      transform(
        templateTypes,
        (result, type) => {
          result[type] = [];
        },
        {}
      ),
    dependencies: [adom.oid, filterPreRunCli, ...dependencies],
    loader: async ({ abortCtrl }) => {
      abortCtrlRef.current = abortCtrl;
      // always update ALL the loading promises when dependencies change.
      // if you only want to reload one or some of them, use getReloadTemplateByTypes instead
      const choices = await getAllTemplatesChoices();
      return choices;
    },
    onError: (err) => {
      if (MACROS.SYS.CONFIG_DEBUG) console.error(err);
    },
  });

  const getAllTemplatesChoices = useCallback(async () => {
    const keys = Object.keys(promiseMapRef.current);
    const _promises = [];
    for (const key of keys) {
      const templatePromise = setTemplatePromise(key);
      _promises.push(templatePromise);
      promiseMapRef.current[key] = templatePromise;
    }

    const resps = await Promise.allSettled(_promises);

    const choices = transform(resps, (result, resp, index) => {
      const { status, value } = resp;
      const type = keys[index];
      const templatesByType = value || [];

      if (status !== 'fulfilled') {
        result[type] = [];
        if (MACROS.SYS.CONFIG_DEBUG) console.error(resp);
        return;
      }

      result[type] = templatesByType;
    });

    return choices;
  }, []);

  const getLoadTemplateByType = useCallback(
    (ptType) => {
      return promiseMapRef?.current?.[ptType];
    },
    [templateChoices]
  );

  const getReloadTemplateByTypes = useCallback(
    (ptTypes) => {
      return () => {
        const _ptTypes = castArray(ptTypes);
        for (const ptType of _ptTypes) {
          const templatePromise = setTemplatePromise(ptType);
          promiseMapRef.current[ptType] = templatePromise;
          templatePromise.then((resp) => {
            if (resp) {
              setTemplateChoices((prev) => {
                return {
                  ...prev,
                  [ptType]: resp,
                };
              });
            }
          });
        }
      };
    },
    [adom.oid, filterPreRunCli]
  );

  return {
    templateChoices,
    setTemplateChoices,
    isLoading,
    refresh,
    getAllTemplatesChoices,
    getLoadTemplateByType,
    getReloadTemplateByTypes,
  };
};
