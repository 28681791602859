import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { castArray, keyBy } from 'lodash';

import { useField } from 'formik';
import { NwProInputRow } from 'rc_layout';
import { NwIcon, NwButton } from '@fafm/neowise-core';
import { ProLego } from '@fafm/neowise-pro';

import { FmkErrorSpan } from '../formik_layout/FmkErrorSpan';
import { FmkSortableList } from './FmkSortableList';

const { SelectPaneSimple } = ProLego;

const HELPER_TEXT = gettext(
  're-order the members by dragging and dropping the item'
);

export const FmkSelectPaneAndSortableList = (props) => {
  const {
    name,
    label,
    ds,
    getAutoId = () => {},
    renderCustomRow = undefined,
    inputRowProps = {},
    sortableListProps = {},
    selectionPaneOpts = {},
    disabled = false,
    wpermit = true,
    validate,
  } = props;

  /** ----------------------------------------------------------------------------
   * States
   * -------------------------------------------------------------------------- */
  const btnRef = useRef(null);
  const [selectPaneOpen, setSelectPaneOpen] = useState(false);

  /** ----------------------------------------------------------------------------
   * Hooks
   * -------------------------------------------------------------------------- */
  const [, { value }, { setValue, setTouched }] = useField({ name, validate });

  const onSelect = useCallback(
    (newIds, items) => {
      newIds = castArray(newIds);
      const val = value || [];
      const byId = keyBy(items, 'id');
      const filtered = newIds.filter((id) => {
        const item = byId[id];
        return !item.excluded;
      });
      const newItems = Array.from(new Set([...val, ...filtered]));
      setTouched(true, false);
      setValue(newItems);
    },
    [value]
  );

  const onDeselect = useCallback(
    (deselectedIds) => {
      const val = value || [];
      const newItemsSet = new Set(val);
      for (const toDelete of deselectedIds) {
        newItemsSet.delete(toDelete);
      }
      const newItems = Array.from(newItemsSet);
      setTouched(true, false);
      setValue(newItems);
    },
    [value]
  );

  const onClose = useCallback(() => {
    setSelectPaneOpen(false);
  }, []);

  return (
    <>
      {/* Add button */}
      <NwProInputRow
        label={label}
        {...inputRowProps}
        rowProps={{ labelInfoTip: '*' + HELPER_TEXT }}
      >
        <div className={'tw-w-full'}>
          <NwButton
            ref={btnRef}
            className='tw-flex tw-items-center tw-justify-center tw-w-full part-base:tw-border-0'
            onClick={() => {
              setSelectPaneOpen((prev) => !prev);
            }}
            automation-id={getAutoId('add-member-btn')}
            disabled={disabled || !wpermit}
          >
            <NwIcon name='add' label={gettext('Add')} />
          </NwButton>
          <SelectPaneSimple
            refElement={btnRef.current}
            value={value}
            isOpen={selectPaneOpen}
            source={ds}
            childrenAttr='children'
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClose={onClose}
            multipleSelect
            {...selectionPaneOpts}
          />
        </div>

        <div className='tw-w-full tw-mb-3'>
          {value && value.length > 0 && (
            <FmkSortableList
              name={name}
              automationId={getAutoId('member-list')}
              formatEntryFn={renderCustomRow}
              {...sortableListProps}
            />
          )}
        </div>

        <FmkErrorSpan name={name} />
      </NwProInputRow>
    </>
  );
};

FmkSelectPaneAndSortableList.propTypes = {
  /**
   * Data source for fiLego.SelectionPane
   */
  ds: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
};
