import { createPromiseAction } from '../utils';
import { createRecordAction, createFetchAction } from '../utils/action';

export const deviceGroupsAction = {
  ...createFetchAction('devicegroups'),
  ...createRecordAction('devicegroups'),
};
export const fetchDeviceGroupsAction = createPromiseAction(
  'devicegroups/FETCH_DEVICE_GROUPS'
);

export const deviceGroupsMembAction = {
  ...createFetchAction('devicegroupsMemb'),
  ...createRecordAction('devicegroupsMemb'),
};
export const fetchDeviceGroupsMemAction = createPromiseAction(
  'devicegroupsMemb/FETCH_DEVICE_GROUPS_MEM'
);

export const defaultGroupsAction = {
  ...createFetchAction('defaultgroups'),
  ...createRecordAction('defaultgroups'),
};
export const fetchDefaultGroupsAction = createPromiseAction(
  'defaultgroups/FETCH_DEFAULT_GROUPS'
);
