import { getFwmProf } from 'fi-dvm-fwm-prof';
import { fiAdom } from 'fi-session';

export async function getExistingFirmwareTemplates() {
  const existingProfs = await getFwmProf(fiAdom.current());
  return existingProfs;
}

export async function getMapOfAssignedDevicesAndFirmwareTemplates() {
  const existingProfs = await getExistingFirmwareTemplates();

  const deviceNameToProfMap = {};
  existingProfs.forEach((prof) => {
    const members = prof['scope member'];
    if (!members || !members.length) return;

    members.forEach((dev) => {
      deviceNameToProfMap[dev.name] = prof;
    });
  });

  return deviceNameToProfMap;
}
