import { Disposable } from './util';

export default class HistoryAddon extends Disposable {
  constructor() {
    super();

    this.activate = this.activate.bind(this);
    this.startRecord = this.startRecord.bind(this);
    this.stopRecord = this.stopRecord.bind(this);
    this.isRecording = this.isRecording.bind(this);
    this.getHistory = this.getHistory.bind(this);
    this.addCommandListenr = this.addCommandListenr.bind(this);
    this.removeCommandListenr = this.removeCommandListenr.bind(this);

    this._recordComamnd = this._recordComamnd.bind(this);

    this._commandEnterCallbacks = new Set();
    this.addDisposable(() => this._commandEnterCallbacks.clear());
  }

  activate(xterm) {
    this.xterm = xterm;

    this.addDisposable(this._setupKeyHandlers());
  }

  _setupKeyHandlers() {
    return this.xterm.onKey(({ domEvent: { key } }) => {
      if (key === 'Enter' && this._commandEnterCallbacks.size) {
        let cmd;
        const buffer = this.xterm?.buffer?.active;
        if (buffer) {
          const reversedCmdParts = [];
          for (let i = buffer.length - 1; i >= 0 && !cmd; i--) {
            const line = buffer.getLine(i)?.translateToString()?.trim();

            if (!line) continue;

            const promptStart = line.search(/[ )][#$]/);
            if (promptStart > 0) {
              // command start
              const maybeCmd = line.substring(promptStart + 2).trim();
              if (!maybeCmd) {
                break; // press 'enter' to clear console
              }

              reversedCmdParts.push(maybeCmd);
              cmd = reversedCmdParts.reverse().join('');
            } else {
              // command part
              reversedCmdParts.push(line);
            }
          }
          if (cmd) {
            this._commandEnterCallbacks.forEach((callback) => callback(cmd));
          }
        } else {
          console.error('Cannot read from terminal buffer.');
        }
      }
    });
  }

  addCommandListenr(callback) {
    this._commandEnterCallbacks.add(callback);
    return this.removeCommandListenr.bind(this, callback);
  }

  removeCommandListenr(callback) {
    this._commandEnterCallbacks.delete(callback);
  }

  isRecording() {
    return this._commandEnterCallbacks.has(this._recordComamnd);
  }

  startRecord() {
    this.records = [];
    this.addCommandListenr(this._recordComamnd);
  }

  _recordComamnd(cmd) {
    if (cmd.indexOf(this.records[this.records.length - 1]) === 0) {
      this.records[this.records.length - 1] = cmd;
    } else {
      this.records.push(cmd);
    }
  }

  stopRecord(asString = true) {
    const records = this.records || [];
    this.records = null;
    this.removeCommandListenr(this._recordComamnd);

    if (asString) {
      return records.length ? `${records.join('\n')}\n` : '';
    }
    return records;
  }

  getHistory(asString = true) {
    const buffer = this.xterm?.buffer?.active;
    const lines = [];

    if (buffer) {
      for (let i = 0; i < buffer.length; i++) {
        const line = buffer.getLine(i)?.translateToString() || '';
        lines.push(line.replace(/\s+$/, ''));
      }
    }

    if (asString) {
      return lines.join('\n').trim();
    }
    return lines;
  }
}
