import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import { computeTree, treeWalkerMaker } from './tree_walker';
import { DefaultTreeRow } from './tree_row';
import { create_tree_menu } from '../common/menu';
import TreeSearchBox from '../dvm_tree/search_box';
import { TreeNode } from './tree_node';
import { MenuStatus, tree_processKeyDown } from '../common/keyboard';
import { TreeScroll } from '../common/util';
import './tree_view.less';

const SORT_ASC = 1;
const SORT_DESC = -1;
const SORT_NONE = 0;

const make_selected = (key = null, trigClick = false, data = null) => {
  return {
    key: key,
    trigClick: trigClick,
    isLink: data?.isLink || false,
  };
};

const nodeSelector = (selected) => (key) => {
  const is_selected = key === selected.key;
  return {
    isSelected: is_selected,
    trigClickIfNeed: function (callback) {
      if (!is_selected || !selected.trigClick) return;
      selected.trigClick = false;

      if (callback) callback();
    },
  };
};
const same_selected = (s1, s2) => {
  if (s1 === s2) return true;
  if (!s1 || !s2) return false;
  return s1.key === s2.key;
};

const make_sortTreeNodes = (sortCallback) => (sortDir) => (treeNodes) => {
  if (sortDir == SORT_NONE) return treeNodes;
  if (treeNodes.length == 0) return treeNodes;

  return sortCallback(treeNodes, sortDir == SORT_ASC);
};

const make_treeNodeSearcher = (treeNodes) => {
  let prevSearchText;
  let prevSearchResult;
  return (searchText) => {
    if (searchText.length == 0) return treeNodes;
    if (searchText != prevSearchText) {
      prevSearchResult = TreeNode.bySearch(treeNodes, searchText);
      prevSearchText = searchText;
    }
    return prevSearchResult;
  };
};

const computeOpennessState = (selected) => {
  // Open parents of selected key, return map
  if (!selected.key) return null;
  let keyParts = selected.key.split(':');
  return keyParts
    .map((_, i) => {
      return keyParts.slice(0, i + 1).join(':');
    })
    .reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
};

function SortCache(state, sortDir) {
  const getSortCache = () => {
    if (!state.sortCache[sortDir]) {
      state.sortCache[sortDir] = {};
    }
    return state.sortCache[sortDir];
  };

  const save = (treeNodes) => {
    const cache = getSortCache();
    for (let i = 0; i < treeNodes.length; i++) {
      cache[treeNodes[i].objId] = i;
    }
  };

  const get = (treeNodes) => {
    const cache = getSortCache();

    const position = [];
    for (let i = 0; i < treeNodes.length; i++) {
      const objId = treeNodes[i].objId;
      if (!cache.hasOwnProperty(objId)) return [];

      position[cache[objId]] = treeNodes[i];
    }
    return position.filter((x) => x);
  };

  return {
    get,
    save,
  };
}

function make_state_data(param = {}, state) {
  const text = param.hasOwnProperty('searchText')
    ? param['searchText']
    : state.data.searchText;
  const sortDir = param.hasOwnProperty('sortDir')
    ? param['sortDir']
    : state.data.sortDir;
  const records = param.hasOwnProperty('records')
    ? param['records']
    : state.data.records;
  const selected = param.hasOwnProperty('selected')
    ? param['selected']
    : state.data.selected;
  const refresh = param.hasOwnProperty('refresh') ? param['refresh'] : false;
  const treeNodeSearcher = param.hasOwnProperty('treeNodeSearcher')
    ? param['treeNodeSearcher']
    : state.treeNodeSearcher;
  const opennessState = param.hasOwnProperty('opennessState')
    ? param['opennessState']
    : null;

  const { computeTree, treeWalkerMaker, sortTreeNodes } = state;
  const newTreeNodes = treeNodeSearcher(text);

  const sortCache = SortCache(state, sortDir);
  const sortCallback = sortTreeNodes ? sortTreeNodes(sortDir) : null;
  const sortTreeNodesWrap = (treeNodes, isSortAsc) => {
    if (!sortCallback) return treeNodes;

    const cacheRet = sortCache.get(treeNodes);
    if (cacheRet.length > 0) return cacheRet;

    const ret = sortCallback(treeNodes, isSortAsc);
    sortCache.save(ret);

    return ret;
  };
  const treeWalker = treeWalkerMaker(newTreeNodes, sortTreeNodesWrap);

  const newData = computeTree(
    treeWalker,
    {
      records: records,
    },
    {
      refreshNodes: refresh,
      opennessState,
    }
  );

  return {
    ...state.data,
    ...newData,
    searchText: text,
    sortDir: sortDir,
    selected: selected,
  };
}

export class TreeView extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props === state.prevProp) return;

    let newData = {};
    let treeNodeSearcher = state.treeNodeSearcher;

    if (props.sortTreeNodes != state.prevProps.sortTreeNodes) {
      state.sortTreeNodes = make_sortTreeNodes(props.sortTreeNodes);
      state.sortCache = {};
    }

    const isSameSelected = same_selected(
      props.selected,
      state.prevProps.selected
    );

    if (props.treeNodes !== state.prevProps.treeNodes || !isSameSelected) {
      treeNodeSearcher = make_treeNodeSearcher(props.treeNodes);
      newData = make_state_data(
        {
          treeNodeSearcher: treeNodeSearcher,
          refresh: true,
          opennessState: !isSameSelected
            ? computeOpennessState(props.selected)
            : null,
        },
        state
      );
    }

    if (!isSameSelected) {
      newData['selected'] = props.selected;
    }

    if (props.reload !== state.reload) {
      state.reload = props.reload;
    }

    return {
      ...state,
      data: {
        ...state.data,
        ...newData,
      },
      prevProps: props,
      treeNodeSearcher: treeNodeSearcher,
    };
  }
  /*
  {
    data:{},
  }
  */

  constructor(props) {
    super(props);

    this.state = {
      data: {
        records: {},
        order: [],
        selected: make_selected(),

        sortDir: props.sortDir ? props.sortDir : SORT_NONE /*none,asc,desc*/,
        searchText: '',
        reload: false,
      },

      computeTree: computeTree,
      treeWalkerMaker: treeWalkerMaker,
      sortTreeNodes: null,
      treeNodeSearcher: null,
      sortCache: {},

      prevProps: {
        treeNodes: null,
        selected: make_selected(),
      },
    };
    this.setListRef = this.setListRef.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleReload = this.handleReload.bind(this);

    this.menuStatus = new MenuStatus();
    this.menuStatus.setListener((shown, node) => {
      if (!shown && this.props.onMenuHide) {
        this.props.onMenuHide(node);
      }
    });

    this.treeScroll = new TreeScroll(() => this.listRef);
    this.scrollToTreeNode = this.scrollToTreeNode.bind(this);

    this.vtreeRef = React.createRef();
  }
  handleOnKeyDown(e) {
    const { order, records, selected } = this.state.data;
    const currentKey = selected.key;

    const processSelectByKeyboard = (nextItem) => {
      this.scrollToTreeNode(nextItem);

      this.handle({
        selected: make_selected(nextItem, false, records[nextItem].node.data),
        refresh: false,
      });
    };
    const processToggleByKeyboard = (record) => {
      this.handleToggle(record);
    };

    const processEnterByKeyboard = (record) => {
      if (!record.isOpen) {
        this.handleToggle(record);
      }
      this.props.onSelect(record);
    };
    const menuStatus = this.menuStatus;
    tree_processKeyDown({
      menuStatus,
      order,
      records,
      currentKey,
      processSelectByKeyboard,
      processToggleByKeyboard,
      processEnterByKeyboard,
    })(e);
  }
  handle(param = {}) {
    const newData = make_state_data(param, this.state);
    this.setState({ data: newData });

    if (this.props.onStateChange) this.props.onStateChange(newData);
  }
  handleSearch(text) {
    this.handle({ searchText: text, records: [], refresh: true });
  }
  handleSort(sortDir) {
    this.handle({ sortDir });
  }

  setListRef(elm) {
    this.listRef = elm;
  }
  changeState(data) {
    this.setState({ data });

    if (this.props.onStateChange) this.props.onStateChange(data);
  }
  handleToggle(record) {
    record.isOpen = !record.isOpen;
    this.handle({ refresh: record.isOpen });
  }
  handleSelect(record) {
    let refresh = false;
    if (!record.isOpen) {
      record.isOpen = !record.isOpen;
      refresh = true;
    }
    this.vtreeRef && this.vtreeRef.current && this.vtreeRef.current.focus();

    this.handle({
      selected: make_selected(record.node.key, false, record.node.data),
      refresh: refresh,
    });
  }
  handleReload() {
    this.handle({
      selected: make_selected(this.state.prevProps.selected.key),
      refresh: false,
    });
  }

  scrollToTreeNode(nodeKey, align) {
    const { order } = this.state.data;

    const pos = order.indexOf(nodeKey);
    if (pos < 0) return;

    this.treeScroll.adjustScrollbar(pos, align);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reload !== this.state.reload) {
      this.handleReload();
    }
  }

  render() {
    let itemData = {
      data: this.state.data,
      onSelect: (record, params) => {
        this.handleSelect(record);
        if (this.props.onSelect) {
          this.props.onSelect(record, params);
        }
      },
      onToggle: (record) => {
        this.handleToggle(record);
      },
    };
    itemData = {
      ...itemData,
      selector: nodeSelector(this.state.data.selected),
    };
    const itemCount = itemData.data.order.length;

    const { width, height, itemSize, hasSearchSort } = this.props;
    // Do not use full height if itemCount is low
    //const treeHeight = Math.min(itemCount*itemSize, height);
    const children = this.props.children || DefaultTreeRow;

    // Context menu
    const menuBuilder = create_tree_menu(this.props.id + '_contextmenu')(
      this.props.getMenuOps
    );
    const ConnectedMenu = menuBuilder.make_connectedMenu(
      this.props.onMenuClick,
      this.menuStatus
    );

    const nodeMenu = menuBuilder.make_nodeMenu;

    const searchBoxHeight = 40;
    const hiddenHorizontalScrollbar = 10;
    // Do not use full height if itemCount is low
    const treeHeight =
      Math.min(
        itemCount * itemSize, // height for tree items
        height - (hasSearchSort ? searchBoxHeight : 0) // remaining height of full sidebar
      ) + (itemCount < 5 ? hiddenHorizontalScrollbar : 0);

    const onItemsRendered = (data) => {
      this.treeScroll.setItemsRendered(data);
    };

    return (
      <div className='tw-h-full'>
        {hasSearchSort && (
          <TreeSearchBox
            style={{ width: width, padding: '5px' }}
            sortAsc={this.state.data.sortDir == SORT_ASC}
            onSearchText={(searchText) => {
              this.handleSearch(searchText);
            }}
            onSort={({ sortAsc }) => {
              this.handleSort(sortAsc ? SORT_ASC : SORT_DESC);
            }}
            suffix={this.props.searchBoxSuffix}
          ></TreeSearchBox>
        )}

        <div
          className={'vtree'}
          style={{ height: treeHeight, width: width }}
          tabIndex={'0'}
          onKeyDown={(e) => this.handleOnKeyDown(e)}
          ref={this.vtreeRef}
        >
          <FixedSizeList
            className={'node-container'}
            itemSize={itemSize}
            height={treeHeight}
            width={width}
            style={{ overflowX: 'hidden' }}
            itemData={itemData}
            itemCount={itemCount}
            ref={(elm) => this.setListRef(elm)}
            onItemsRendered={onItemsRendered}
          >
            {children(nodeMenu)}
          </FixedSizeList>
        </div>

        <ConnectedMenu />
      </div>
    );
  }
}

TreeView.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  itemSize: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  hasSearchSort: PropTypes.bool,
  sortTreeNodes: PropTypes.func,
  treeNodes: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
};

TreeView.defaultProps = {
  hasSearchSort: false,
  sortTreeNodes: (treeNodes) => treeNodes,
};
