export const getCollection = (state) => (collid) =>
  state.collections[collid] || {};

// const getGroup = (collection) => (groupid) => collection.groups[groupid];

// export const getCollectionGroup = fp.compose(getGroup, getCollection);

// export const getGroupItems = (state, collid, groupid) => {
//   let collection = getCollection(state)(collid);
//   let group = getGroup(state, collid, groupid);
//   return group[groupid] ? Object.keys(group[groupid]).map(itemid => );
// };
