import React, { useRef, useState, useEffect } from 'react';
import { ProForm, ProToolkit, ProTable } from '@fafm/neowise-pro';
import { NwButton, NwProgressBar } from '@fafm/neowise-core';
import { fiTaskByWebSocket } from './task_by_websocket';

const { Header, Body, Footer, Section } = ProForm;

export const TaskMonitorModal = (props) => {
  const { title = '', taskId, $opener, showProgress, loading } = props;

  const [message, setMessage] = useState('');
  const [history, setHistory] = useState([]);
  const [type, setType] = useState('success');
  const [percent, setPercent] = useState(0);
  const dataRef = useRef(null);
  const codeRef = useRef(null);

  useEffect(() => {
    if (taskId) {
      fiTaskByWebSocket.register(taskId, _taskHandle);
    } else if (!loading) {
      setMessage(gettext('Invalid task id'));
    }
  });

  function _getTaskId(data) {
    var tid = data.fields && data.fields.reqid ? data.fields.reqid : data.id;
    return tid;
  }

  function _taskHandle(data) {
    if (data.collection !== 'task' || taskId != _getTaskId(data)) {
      return;
    }

    let resp = data.fields || {};
    setPercent(resp.percentage || 0);
    setMessage(resp.message);
    setHistory((prev) => [
      ...prev,
      { idx: prev.length, percent: resp.percentage, msg: resp.message },
    ]);
    codeRef.current = resp.code;
    dataRef.current = resp;

    if (codeRef.current >= 0) {
      if (resp.percentage >= 100) {
        _close();
      }
    } else {
      fiTaskByWebSocket.unregister(taskId);
      setType('danger');
    }
  }

  function _close() {
    // unregister lisntener
    fiTaskByWebSocket.unregister(taskId);

    if (codeRef.current >= 0) {
      $opener.resolve(dataRef.current);
    } else {
      $opener.reject({
        code: codeRef.current,
        message: message,
      });
    }
  }

  return (
    <>
      <Header>{title}</Header>
      <Body>
        <div className={'tw-flex tw-flex-col'}>
          <span className={'tw-w-full'}>{message}</span>
          <NwProgressBar
            type={type}
            percentage={percent}
            style={{
              '--indicator-color':
                type === 'danger'
                  ? 'rgb(var(--nw-color-danger))'
                  : 'rgb(var(--nw-color-success))',
            }}
          >
            {percent + '%'}
          </NwProgressBar>
          {showProgress ? (
            <Section
              collapsible={true}
              title={gettext('Details')}
              className={'tw-pt-4'}
            >
              <ProTable.SimpleTableView
                tableId={'ws-task-monitor-history'}
                rowKey={'idx'}
                data={history}
                maxHeight={300}
                columns={[
                  {
                    key: 'idx',
                    title: gettext('Index'),
                  },
                  {
                    key: 'percent',
                    title: gettext('Percentage'),
                  },
                  {
                    key: 'msg',
                    title: gettext('Status'),
                  },
                ]}
              />
            </Section>
          ) : null}
        </div>
      </Body>
      <Footer>
        <NwButton
          className={'tw-min-w-32'}
          onClick={() => {
            _close();
          }}
          type={'default'}
        >
          {gettext('Close')}
        </NwButton>
      </Footer>
    </>
  );
};

const DEFAULT_MODAL_OPTIONS = {
  size: 'md',
  minimizable: false,
  maximizable: false,
  height: '240px',
};

export const openTaskByWebSocketMonitor = (
  taskId,
  monitorProps,
  options = DEFAULT_MODAL_OPTIONS
) => {
  const { $opener } = options;
  if ($opener) {
    return $opener.open(
      <TaskMonitorModal taskId={taskId} {...options} />,
      options
    ).result;
  }
  if (monitorProps.inDrawer) {
    return ProToolkit.openDrawer(
      <TaskMonitorModal taskId={taskId} {...monitorProps} />,
      options
    ).result;
  }

  return ProToolkit.openModal(
    <TaskMonitorModal taskId={taskId} {...monitorProps} />,
    options
  ).result;
};
