import React, { useCallback } from 'react';
import { fiStore, fiStoreFabric } from 'fistore';
import { ProForm } from '@fafm/neowise-pro';
import { NwButton } from '@fafm/neowise-core';
import { fiHttpPost } from 'fi-http';
const { Header, Body, Footer } = ProForm;

const getAutoId = (name) => `custom-view-delete-modal:${name.toLowerCase()}`;

export const Delete = ({ $opener, menuItem }) => {
  const deleteCustom = useCallback(async () => {
    const stateParams = menuItem.stateParams;
    const id = stateParams.euid || stateParams.customViewId;
    const resp = await fiHttpPost('/p/fabric/customview/delete/', { uuid: id });
    if (resp && resp.status && resp.status.code === 0) {
      await fiStore.dispatch(fiStoreFabric.fetchFabricCustomMenu());
    }
    $opener.resolve();
  });

  const cancel = useCallback(() => {
    $opener.reject();
  });

  return (
    <>
      <Header>{gettext('Delete Custom View')}</Header>
      <Body>
        <span>
          {gettext('Are you sure you want to delete this Custom View?')}
        </span>
      </Body>
      <Footer>
        <NwButton
          type='primary'
          automation-id={getAutoId('ok-btn')}
          onClick={deleteCustom}
        >
          {gettext('OK')}
        </NwButton>
        <NwButton onClick={cancel} automation-id={getAutoId('cancel-btn')}>
          {gettext('Cancel')}
        </NwButton>
      </Footer>
    </>
  );
};
