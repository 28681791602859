import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLocalStorage, setLocalStorage } from 'fiutil/storage';
import { SELECT_ADOM_REQUIRED_KEY } from 'fistore/routing/slice';
import { ProToolkit } from '@fafm/neowise-pro';
import {
  getSysConfig,
  getAllowChangeAdom,
  getDefaultAdom,
  getIsFmgMember,
} from 'fistore/session/sysConfig/selectors';
import { switchSessionAdom } from 'fistore/session/adom/slice';
import { AdomSelectModal } from 'react_apps/ra_adom_edit/adom_select_modal/AdomSelectModal';

import { StartupWizardModal, getStartupWizardOptions } from '../startup_wizard';
import { ChangePassword } from '../modal/ChangePassword';

export const useShowInitModals = () => {
  const dispatch = useDispatch();
  const sysCfg = useSelector(getSysConfig);
  const isSysCfgLoaded = !!sysCfg;
  const allowChangeAdom = useSelector(getAllowChangeAdom);
  const defaultAdom = useSelector(getDefaultAdom);
  const isFmgMember = useSelector(getIsFmgMember);

  const [initModalsDone, setInitModalsDone] = useState(false);

  // If adom is not selected yet, show all init modals if needed
  const initModalsRequired = getLocalStorage(SELECT_ADOM_REQUIRED_KEY, true);

  useEffect(() => {
    async function init() {
      if (!isSysCfgLoaded || initModalsDone) return;

      const isSuperAdmin = !!sysCfg.user_profile?.super_user_profile;

      if (initModalsRequired) {
        // 1. startup wizard modal
        await checkStartupDialog({
          sysCfg,
          isSuperAdmin,
          isSignIn: initModalsRequired,
        });

        // 2. change password modal
        await checkPassChange({
          sysCfg,
          isSuperAdmin,
          isSignIn: initModalsRequired,
          isFmgMember,
        });

        // 3. adom select modal
        if (allowChangeAdom) {
          await showAdomSelectModal(dispatch);
        } else {
          await dispatch(switchSessionAdom(defaultAdom.oid));
        }

        // when all three modals are finished, set local storage value to false
        setLocalStorage(SELECT_ADOM_REQUIRED_KEY, false);
      }

      setInitModalsDone(true);
    }

    init();
  }, [isSysCfgLoaded, initModalsRequired]);

  return {
    initModalsDone,
  };
};

async function checkStartupDialog({ sysCfg, isSuperAdmin, isSignIn }) {
  // Skip dialog for certain cases
  if (!isSuperAdmin || !isSignIn || sysCfg.have_ftnt_cloud) {
    return;
  }

  const wizardOptions = await getStartupWizardOptions();
  if (wizardOptions.isComplete) {
    return;
  }

  try {
    const res = await ProToolkit.openModal(
      <StartupWizardModal wizardOptions={wizardOptions} />,
      {
        size: 'lg',
        closable: !!MACROS.SYS.CONFIG_DEBUG,
        maximizable: false,
        className: 'np-toolkit-modal startup-wizard-ctn',
      }
    ).result;
    return res;
  } catch (error) {
    return;
  }
}

async function checkPassChange({
  sysCfg,
  isSuperAdmin,
  isSignIn,
  isFmgMember,
}) {
  const warningMsg = {
    pass_exp: gettext(
      'Your password has expired. Please input a new password.'
    ),
    pass_chg: gettext(
      'Your password does not conform to the password policy. Please input a new password.'
    ),
    pass_chg_force: gettext('You are required to change your password.'),
    pass_chg_opt: gettext(
      'This account is using the default password. It is strongly recommended that you change your password.'
    ),
  };

  // check if user need to change password immediately
  // Don't check password if non-local type user
  // Don't check password in Cloud
  // Don't check password for Super_User + default password. Already done in checkStartupDialog()
  // Don't check password for FMG Fabric->memeber
  if (
    (sysCfg.need_change_password || sysCfg.admin_has_default_pwd) &&
    sysCfg.user_type === MACROS.SYS.ADMIN_USER_TYPE_LOCAL &&
    !sysCfg.have_ftnt_cloud &&
    !(isSuperAdmin && sysCfg.admin_has_default_pwd) &&
    !isFmgMember
  ) {
    let infoMessage = warningMsg['pass_chg'];
    let hasDefaultPwd = false;
    let forceChangePwd = true;
    if (
      sysCfg.need_change_password == MACROS.PO.PASSWD_POLICY_CHECK_FORCE_CHG
    ) {
      hasDefaultPwd = sysCfg.admin_has_default_pwd;
      infoMessage = warningMsg['pass_chg_force'];
    } else if (
      sysCfg.need_change_password == MACROS.PO.PASSWD_POLICY_CHECK_EXPIRE
    ) {
      infoMessage = warningMsg['pass_exp'];
    } else if (
      sysCfg.need_change_password == MACROS.PO.PASSWD_POLICY_CHECK_NOT_CONFORM
    ) {
      infoMessage = warningMsg['pass_chg'];
    } else if (sysCfg.admin_has_default_pwd) {
      // need change password should come first before super admin password change
      infoMessage = warningMsg['pass_chg_opt'];
      hasDefaultPwd = true;
      forceChangePwd = false;
      // check if the user has already been signed in
      if (!isSignIn) {
        return; // no need to show this modal
      }
    }

    const opts = {
      username: sysCfg.username,
      showOld: true,
      hideCancel: true,
      hasDefaultPwd: hasDefaultPwd,
      forceChangePwd,
      infoMessage: infoMessage,
    };
    return ProToolkit.openModal(
      <ChangePassword username={opts.username} opts={opts} />,
      {
        width: 'sm',
        height: '350px',
        closable: !opts.forceChangePwd, // If force change pw, then prevent modal from exit when press Esc
      }
    ).result.catch(() => ({}));
  }
}

// open adom select modal if:
// 1. isAdomEnabled = true and
// 2. adom is not selected yet, getLocalStorage(SELECT_ADOM_REQUIRED_KEY, true) = true.
//    E.g. open a browser tab, login but dont select adom. Open another browser tab, adom select modal should show up
const showAdomSelectModal = async (dispatch) => {
  // adom select modal
  const adom = await ProToolkit.openModal(
    <AdomSelectModal allowCancel={false} />,
    {
      size: 'lg',
      closable: false,
      maximizable: false,
    }
  ).result;
  if (adom) {
    await dispatch(switchSessionAdom(adom.oid));
  }
};
