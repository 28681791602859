import { noop } from 'lodash';
import { createCaptureFile, debuggerDidStart } from '../debugger_utils';
import { Timestamped } from '../types';

interface RequestParams {
  id: string; //"mtd-28"
  method: string; //"proxy"
  msg: string; //"method"
  params: {
    method: string; //"post"
    params: string | object; //stringify json
    url: string; //"/cgi-bin/module/flatui_proxy"
  };
}

interface ResponseChunk {
  chunked: number;
  id: string;
  msg: string; //"result"
  result: {
    header?: object; //first chunk eg: {timestamp: 1595001596, totalRecords: 1}
    chunk?: object; // eg: {data: null, id: 0, meta: {…}, status: {…}}
    tail?: object; //last chunk eg: {timestamp: 1595001596, totalRecords: 1}
  };
}

type ResponseError = { placeholder?: never };

type RequestComplete = { placeholder?: never };

type RequestCancel = { placeholder?: never };

export enum LogType {
  Send = 'send',
  Next = 'next',
  Error = 'error',
  Complete = 'complete',
  Cancel = 'cancel',
}

type LogData = {
  metadata: Timestamped<{
    id: string;
    type: LogType;
  }>;
  data?:
    | RequestParams
    | ResponseChunk
    | ResponseError
    | RequestComplete
    | RequestCancel;
};

const logNoop = {
  send: noop,
  next: noop,
  error: noop,
  complete: noop,
  cancel: noop,
};

export type LogWS = {
  send: (requestId: string, data: RequestParams) => void;
  next: (requestId: string, data: ResponseChunk) => void;
  error: (requestId: string, data?: ResponseError) => void;
  complete: (requestId: string, data?: RequestComplete) => void;
  cancel: (requestId: string, data?: RequestCancel) => void;
};
export const wslog: LogWS = { ...logNoop };

export const register = () => {
  return debuggerDidStart(async () => {
    const { send, complete } = await createCaptureFile(
      'websocket-requests.json',
      {
        sortBy: 'metadata.timestamp',
        groupBy: 'metadata.id',
        metadata: {},
      }
    );

    const logWith = (type: LogType) => {
      const log = (requestId: string, data?: LogData['data']) => {
        const result: LogData = {
          metadata: {
            timestamp: Date.now(),
            id: requestId,
            type,
          },
          data,
        };
        send(result);
      };
      return log;
    };

    Object.assign(wslog, {
      send: logWith(LogType.Send),
      next: logWith(LogType.Next),
      error: logWith(LogType.Error),
      complete: logWith(LogType.Complete),
      cancel: logWith(LogType.Cancel),
    });
    return stopCapture;

    function stopCapture() {
      Object.assign(wslog, logNoop);
      complete();
    }
  });
};
