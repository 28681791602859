import { memoFocus, getTableHeight } from './util';
import { ProToolkit } from '@fafm/neowise-pro';

import './modal.less';
export * from './form';
export * from './pageModalForm';
export {
  openConfirmModal,
  ConfirmModal,
  makeConfirmModalRenderFn,
} from './ConfirmModal';
export { openInstallLog, InstallLog } from './InstallLog';
export * from './tooltip';
export * from './cellspan';
export * from './bodySizer';
export * from './AlertText';
const { ErrorBoundary, withErrorBoundary } = ProToolkit;

export { ErrorBoundary, withErrorBoundary, memoFocus, getTableHeight };
export { CopyBtn, CopySnBtn } from './CopyBtn';
