export const SEVERITY = {
  5: gettext('Critical'),
  4: gettext('High'),
  3: gettext('Medium'),
  2: gettext('Low'),
  1: gettext('Informational'),
};

export const SEVERITY_COLORS = {
  5: 'color-msg-red contrast-color-severity-selected',
  4: 'color-msg-orange contrast-color-severity-selected',
  3: 'color-msg-yellow contrast-color-severity-selected',
  2: 'color-msg-green contrast-color-severity-selected',
  1: 'color-msg-blue contrast-color-severity-selected',
};

export const SeverityDiv = ({ risk, children, className }) => {
  const color = SEVERITY_COLORS[risk];
  return (
    <div
      className={
        className ? `${className} ${color}` : `${color} tw-px-4 tw-rounded `
      }
    >
      {children}
    </div>
  );
};

export const getDeviceFirmwareVersion = (device, isModel) => {
  const modelVer =
    device['prefer_img_ver'] ||
    device['_prefer-img-ver'] ||
    device['prefer-img-ver'];
  if (isModel) return modelVer;
  return (
    device.firmware ||
    device['_software-version'] ||
    device['os_version'] ||
    device['_sw_version'] ||
    modelVer
  );
};
