import { fiErrorMessage } from 'fi-messagebox';
import { isFunction } from 'lodash';

export const getDetailErrorMsg = ({
  error,
  showMessageBox = true,
  fallback = undefined,
}) => {
  try {
    error.message = error.data.result[0].status.message;
    if (error.message && showMessageBox) {
      fiErrorMessage.show(error.message);
    }

    return error.message;
  } catch (e) {
    if (isFunction(fallback)) {
      const msg = fallback(e);
      if (msg && showMessageBox) {
        fiErrorMessage.show(msg);
      }

      return msg;
    }
  }
};
