import { useRef } from 'react';

type Callback = (data: any) => void;

export type EventQueue = {
  subscribe: (callback: Callback) => void;
  cleanSubscribers: () => void;
  notify: (data: any) => void;
};

const createEventQueue = (): EventQueue => {
  let queue: Callback[] = [];

  return {
    subscribe: (cb) => queue.push(cb),
    cleanSubscribers: () => (queue = []),
    notify: (data) => {
      queue.forEach((cb) => cb(data));
    },
  };
};

export const useEventQueue = () => {
  const eventQueue = useRef(createEventQueue());
  return () => eventQueue.current;
};
