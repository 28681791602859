import IconColorPicker from 'rc_icon_color_picker';
import { useField } from 'formik';

export const FmkIconColorPicker = ({ name, ...rest }) => {
  const [, { value }, { setValue, setTouched }] = useField(name);

  const updateValue = (newVal) => {
    setTouched(true, false);
    setValue(newVal);
  };

  return <IconColorPicker {...rest} value={value} onChange={updateValue} />;
};
