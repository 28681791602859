import { fiSession } from 'fistore';
import { negate } from 'lodash';
const {
  inManageableAdoms,
  checkVersion,
  inGlobalAdom,
  inOthersAdom,
  inFpxAdom,
  isFmg,
  inBackupAdom,
  hasAllRDPermitOn,
} = fiSession;

const mrPRIV = MACROS.USER.DVM;

const fext_profiles_apps = {
  fext_profiles_prof: {
    label: gettext('Extender Profiles'),
    // icon: 'profile-package',
    path: '/fext/profiles/extender-profile',
    showCheckers: [checkVersion('7.0', '>=')],
    olhTag: 'fext_prof',
    olhCategory: 'fext',
    olhFmg: 'Extender Manager -> Extender Profiles',
  },
  fext_profiles_template: {
    label: gettext('FortiExtender Templates'),
    // icon: 'templates',
    path: '/fext/profiles/template',
    showCheckers: [checkVersion('7.0', '<')],
    olhTag: 'fext_template',
    olhCategory: 'fext',
    olhFmg: 'Extender Manager -> FortiExtender Templates',
  },
  fext_profiles_simprof: {
    label: gettext('SIM Profiles'),
    // icon: 'profile',
    path: '/fext/profiles/sim',
    showCheckers: [checkVersion('7.0', '<')],
    olhTag: 'fext_sim_prof',
    olhCategory: 'fext',
    olhFmg: 'Extender Manager -> SIM Profiles',
  },
  fext_profiles_dataplan: {
    label: gettext('Data Plans'),
    // icon: 'money',
    path: '/fext/profiles/dataplan',
    showCheckers: [checkVersion('6.4', '>=')],
    olhTag: 'fext_data_plan',
    olhCategory: 'fext',
    olhFmg: 'Extender Manager -> Data Plans',
  },
};

export default {
  fext: {
    label: gettext('Extender Manager'),
    icon: 'fortiextender',
    color: '#aa6888',
    showCheckers: [
      isFmg,
      inManageableAdoms,
      hasAllRDPermitOn(
        mrPRIV.ADMINPRIV_DEV_FORTIEXTENDER,
        mrPRIV.ADMINPRIV_DEV_MANAGER
      ),
      negate(inBackupAdom),
      negate(inGlobalAdom),
      negate(inOthersAdom),
      negate(inFpxAdom),
    ],
  },
  fext_device: {
    label: gettext('Managed Extenders'),
    path: '/fext/view/list',
    olhTag: 'fext_list',
    olhCategory: 'fext',
    olhFmg: 'Extender Manager -> Managed Extenders',
  },
  ...fext_profiles_apps,
};
