import { useSelector } from 'react-redux';
import { getHostname } from 'fistore/auth/selectors';

export function LoginFormBase({ customHeader = null, children }) {
  const hostName = useSelector(getHostname);
  return (
    <div className='tw-p-16 tw-bg-neutral-0 tw-rounded-md tw-box-border tw-w-[33rem] tw-max-w-xl tw-min-h-20 tw-border-border tw-border tw-border-solid'>
      {customHeader || (
        <div className='tw-w-full tw-mb-5 tw-text-center tw-text-primary'>
          <h1 className='tw-text-3xl tw-font-bold tw-mb-1'>
            {MACROS.SYS.CONFIG_PROD_NAME}
          </h1>
          {hostName && <h2 className='tw-text-xl tw-my-0'>{hostName}</h2>}
        </div>
      )}
      <div className='tw-text-center'>{children}</div>
    </div>
  );
}
