import { fiStorePno, dispatch } from 'fistore';
import { get } from 'lodash';
import { readCache, setCache, forceReload } from './common';
import { fiAdom } from 'fi-session';
import { fiFmgHttp } from 'fi-http';

// Get ppkgs data from cache if exist, or query from backend
const getPPkgs = async () => {
  const ppkgsCache = readCache();
  if (ppkgsCache) {
    return ppkgsCache;
  }
  const ppkgs = await dispatch(fiStorePno.queryPPkgs()).unwrap();
  return setCache(ppkgs);
};

export const getPPkgList = async () => {
  const ppkgs = await getPPkgs();
  return get(ppkgs, 'list');
};

export const reloadPPkgList = forceReload(getPPkgList);

const ppkgOnly = (list) => list.filter((node) => !node.pblocks);
const pbOnly = (list) =>
  get(
    list.find((node) => node.pblocks),
    'subobj',
    []
  );

export const getListWithPPkgOnly = async (force = false) => {
  const promise = force ? reloadPPkgList() : getPPkgList();
  const list = await promise;
  return ppkgOnly(list);
};

export const getPolicyBlockList = async () => {
  const list = await getPPkgList();
  return pbOnly(list);
};

// Get global-assigned ppkg list
export const getAssignedPPkgList = async (adom) => {
  if (fiAdom.isGlobalAdom(adom)) {
    return [];
  }
  return fiFmgHttp.query({
    method: 'get',
    params: [
      {
        url: `/pm/config/adom/${adom.name}/_adom/options`,
        data: {
          'get count': 1,
        },
      },
    ],
  });
};
