import { Observer, Observable, UnSubCode } from './observable';
import withAOP from './aop';
import type { LogWS } from 'chrome_extension_debugger/captures/ws_request_log';

interface Cache {
  [key: string]: any;
}
export class MethodCollection {
  cache: Cache;
  constructor(private wslog: LogWS) {
    this.cache = {};
    this.process.bind(this);
  }
  process(data: any) {
    const method = this.cache[data.id];
    if (!method) return;

    run_method(method, data, () => {
      this.remove(data.id);
    });
  }
  error(error: string) {
    Object.keys(this.cache).forEach((key) => {
      this.cache[key].error(error);
    });
  }
  remove(requestId: string) {
    delete this.cache[requestId];
  }
  clear() {
    this.cache = {};
  }
  createObserver(
    requestId: string,
    exec: () => void,
    unsub?: (code?: UnSubCode) => void
  ) {
    return new Observable((observer: Observer) => {
      exec();

      this.cache[requestId] = withAOP(observer, [
        {
          next: (data: any) => {
            return {
              before: () => {
                this.wslog.next(requestId, data);
              },
            };
          },
          error: (data: any) => {
            return {
              before: () => this.wslog.error(requestId, data),
            };
          },
          complete: () => {
            return {
              before: () => this.wslog.complete(requestId),
            };
          },
        },
      ]);

      return unsub ? unsub : () => {};
    });
  }
}

class BatchChunk {
  static process(data: any, next: (data: any) => void) {
    const chunk = data?.result?.chunk;
    if (chunk?.data && Array.isArray(chunk.data) && chunk.data.length > 0) {
      const chunkData = chunk.data;
      data.result.chunk = null;
      const strData = JSON.stringify(data);

      chunkData.forEach((x: any) => {
        const copyData = JSON.parse(strData);
        copyData.result.chunk = x;
        next(copyData);
      });
    }
  }
  static is(data: any) {
    return data?.result?.chunk?.batch;
  }
}

function run_method(method: Observer, data: any, afterCompleted: () => void) {
  do {
    //non chunk data
    if (!data.chunked) {
      method.next(data);
      break;
    }

    //chunk data
    if (data.end) break;

    if (BatchChunk.is(data)) {
      BatchChunk.process(data, (x: any) => method.next(x));
    } else {
      method.next(data);
    }

    if (!data.end) return;

    // eslint-disable-next-line
  } while (false);

  method.complete();

  if (afterCompleted) afterCompleted();
}
