import React from 'react';
import { NwProSection, NwProInputRow } from 'rc_layout';
import { FormikConsumer } from 'formik';
import { FmkInput, FmkErrorSpan } from 'rc_form';
import { autoId } from './util';

const AdomMetaFields = ({ isView = false }) => {
  return (
    <>
      <FormikConsumer>
        {({ values, errors }) => {
          return (
            <NwProSection
              title={gettext('Meta Fields')}
              open={Object.keys(errors).some((key) => key.startsWith('metas'))}
              collapsible
            >
              {values.metas.map((meta, index) => (
                <NwProInputRow label={meta.name} key={meta.name}>
                  <FmkInput
                    name={`metas[${index}].value`}
                    maxLength={meta.length}
                    automation-id={autoId(meta.name)}
                    disabled={isView}
                    suffix={
                      <span>
                        {meta.importance
                          ? gettext('Required')
                          : gettext('Optional')}
                      </span>
                    }
                  ></FmkInput>
                  <FmkErrorSpan name={`metas[${index}].value`} />
                </NwProInputRow>
              ))}
            </NwProSection>
          );
        }}
      </FormikConsumer>
    </>
  );
};
AdomMetaFields.displayName = 'AdomMetaFields';
export default AdomMetaFields;
