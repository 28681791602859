export { selectedDvmRow, selectedDevice, unSelectedDevice, selectedDeviceVdom };

let _selectedDevice = null;
let v_selected_row = null;

const selectedDvmRow = () => {
  return v_selected_row;
};

/**
 *@description getter & setter once has input parameter
 *@param {string or object} type - string value of dvm-cfg-obj or object value of current device
 *@return {object} - current selected device
 */
const selectedDevice = (currentDev, vdomName) => {
  if (currentDev) {
    _selectedDevice = currentDev;
  }
  if (vdomName && _selectedDevice && _selectedDevice.vdoms) {
    //set select row...
    vdomName = vdomName || 'root';
    for (var i = 0; i < _selectedDevice.vdoms.length; i++) {
      if (_selectedDevice.vdoms[i].name === vdomName) {
        v_selected_row = _selectedDevice.vdoms[i];
        break;
      }
    }
  }

  if (!v_selected_row) {
    v_selected_row = _selectedDevice;
  }
  return _selectedDevice;
};

const unSelectedDevice = () => {
  _selectedDevice = null;
  v_selected_row = null;
};

const isVdom = (rtype) => {
  const vdom = 3;
  const vdomMgt = 4;
  const vdomWithoutMgt = 5;

  return rtype === vdom || rtype === vdomMgt || rtype === vdomWithoutMgt;
};

const selectedDeviceVdom = function ({ allowNull = false } = {}) {
  let _vdom = '';
  let _selectedRow = selectedDvmRow();
  try {
    if (_selectedRow && isVdom(_selectedRow.rtype)) {
      _vdom = _selectedRow.name;
    } else {
      _vdom = _selectedRow.vdom;
    }
  } catch (e) {
    //
  }
  if (allowNull && !_vdom) return _vdom || null;
  else if (_vdom) return _vdom;
  if (Array.isArray(_selectedRow?.vdoms)) {
    const mgtVdom = _selectedRow?.vdoms.find((vd) => vd.mgtvdom);
    if (mgtVdom) return mgtVdom.name;
  }
  return allowNull ? _vdom || null : _vdom || 'root';
};
