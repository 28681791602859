import { fiTaskByWebSocket } from './task_by_websocket';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TaskDetails } from './TaskDetails';

const THREESEC = 3000;

export const WebTaskMonitor = ({
  taskId,
  inlineBar = false,
  title = '',
  hideBarOnSuccess = false,
  isCollapsed = false,
  success,
  fail,
  notify,
  lineTableProps,
  serverTime = undefined,
  showErrorAlert = undefined,
}) => {
  const [taskData, setTaskData] = useState(null);
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const timeout = useRef(null);
  const buffer = useRef(null);

  useEffect(() => {
    if (taskId) {
      setTaskData(null);
      fiTaskByWebSocket.register(taskId, _taskHandle);
    }

    function _getTaskId(data) {
      var tid = data.fields && data.fields.reqid ? data.fields.reqid : data.id;

      return tid;
    }

    function _taskHandle(data) {
      if (data.collection !== 'task' || taskId != _getTaskId(data)) {
        return;
      }

      const handleTaskData = (data) => {
        const resp = data.fields || {};
        const hasErrLine = (resp?.meta?.num_err || 0) >= 1;
        setTaskData(resp);
        if (resp.percentage >= 100) {
          fiTaskByWebSocket.unregister(taskId);

          if (resp.code >= 0) {
            if (hasErrLine) {
              if (fail) fail(resp);
            } else {
              if (success) success(resp);
            }
          } else {
            if (fail) fail(resp);
          }
        } else {
          if (notify) notify(resp);
        }
      };

      buffer.current = data;

      if (!timeout.current) {
        timeout.current = setTimeout(() => {
          handleTaskData(buffer.current);
          timeout.current = null;
          buffer.current = null;
        }, THREESEC);
      }
    }

    return () => {
      fiTaskByWebSocket.unregister(taskId);
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [taskId]);

  const parsedTaskData = useMemo(() => {
    if (!taskData) return taskData;
    return {
      percent: taskData.percentage,
      state: (() => {
        if (taskData.code === -1 || taskData.meta?.num_err > 0) {
          return MACROS.TASK.TASK_STATE_ERROR;
        }
      })(),
      ...taskData.meta,
    };
  }, [taskData]);

  useEffect(() => {
    const { num_err, num_warn } = taskData?.meta || {};
    if (num_err || num_warn) setCollapsed(true);
  }, [taskData]);

  return (
    <TaskDetails
      taskData={parsedTaskData}
      title={title}
      hide={[!collapsed && 'lines']}
      hideWhenSuccess={[hideBarOnSuccess && 'bar']}
      inlineBar={inlineBar}
      lineTableProps={{
        maxHeight: 440,
        ...lineTableProps,
      }}
      serverTime={serverTime}
      showErrorAlert={showErrorAlert}
    />
  );
};
