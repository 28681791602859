import { fiHttp } from 'fi-http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listenerMiddleware } from 'fistore/middlewares';
import { getAvailableLogs, getViewnameLogtypes } from './selectors';
import { appEnabled } from '../../routing/selectors';
import { fetchSessionAdom } from '../../session/adom/slice';
import { getSessionAdomData } from '../../session/adom/selectors';
import { getIsFazSupervisor } from '../../session/sysConfig/selectors';
import { getDisabledViews, getDisabledDashes } from './helpers';
import { refreshAppTree } from '../../routing/slice';

const initialState = {
  availableLogs: [],
  viewnameLogtypes: {},
  disabledViews: {},
  disabledDashes: {
    ftv: {},
    noc: {},
  },
};

const slice = createSlice({
  name: 'viewLog',
  initialState,
  reducers: {
    setDisabledViews(state, { payload }) {
      state.disabledViews = payload;
    },
    setDisabledDashes(state, { payload }) {
      state.disabledDashes = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableLogs.fulfilled, (state, { payload }) => {
        state.availableLogs = payload;
      })
      .addCase(fetchViewnameLogtypes.fulfilled, (state, { payload }) => {
        state.viewnameLogtypes = payload;
      });
  },
});

const fetchAvailableLogs = createAsyncThunk(
  'soc/viewLog/FETCH_AVAILABLE_LOGS',
  async () => {
    const logsResp = await fiHttp.get('/p/logview/loadLogviewMenu/');
    const rLogs = logsResp?.data?.records;
    return rLogs || [];
  }
);

const fetchViewnameLogtypes = createAsyncThunk(
  'soc/viewLog/FETCH_VIEWNAME_LOGTYPES',
  async () => {
    const reqResp = await fiHttp.get('/p/fortiview/viewnamelogtype/list/');
    const rApi_logtype = reqResp?.data?.data
      ?.filter((x) => x.logs?.required?.length)
      .reduce((res, item) => {
        res[item.name] = item;
        return res;
      }, {});
    return rApi_logtype || {};
  }
);

// fetch on adom change
listenerMiddleware.startListening({
  actionCreator: fetchSessionAdom.fulfilled,
  effect: async (action, { dispatch, condition, cancelActiveListeners }) => {
    cancelActiveListeners();
    if (
      await condition(
        (_, currState) =>
          !getIsFazSupervisor(currState) &&
          appEnabled('fortiview')(currState) &&
          appEnabled('logview')(currState)
      )
    ) {
      await dispatch(fetchAvailableLogs());
      await dispatch(fetchViewnameLogtypes());
    }
  },
});

// update disabledViews and disabledDashes
listenerMiddleware.startListening({
  predicate: (action, currState, prevState) => {
    return (
      getAvailableLogs(currState) !== getAvailableLogs(prevState) ||
      getViewnameLogtypes(currState) !== getViewnameLogtypes(prevState)
    );
  },
  effect: async (action, { dispatch, getState }) => {
    const state = getState();

    const availableLogs = getAvailableLogs(state);
    const viewnameLogtypes = getViewnameLogtypes(state);
    const adom = getSessionAdomData(state);
    const disabledViews = getDisabledViews({
      viewnameLogtypes,
      availableLogs,
      is_fabric: adom.is_fabric,
    });

    dispatch(slice.actions.setDisabledViews(disabledViews));

    const disabledDashes = getDisabledDashes({ disabledViews });

    dispatch(slice.actions.setDisabledDashes(disabledDashes));

    dispatch(refreshAppTree());
  },
});

export { fetchViewnameLogtypes, fetchAvailableLogs };

export default slice.reducer;
