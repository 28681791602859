import { useMemo, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { CONTENT_MENU_RELOAD_KEY } from 'react_apps/ra_main/main_layout/content_menu/ContentMenu';

export function ReloadLocation(Component, reloadKey) {
  return function ReloadLocation(props) {
    const { state } = useLocation();

    const reloadValueRef = useRef();
    // prevent other places call navigate without reload key
    if (state?.[reloadKey]) {
      reloadValueRef.current = state[reloadKey];
    }

    const MemoComp = useMemo(() => {
      // key change will trigger component remount and reload all data
      return <Component key={reloadValueRef.current} {...props} />;
    }, [reloadValueRef.current, props]);

    return MemoComp;
  };
}

export const ContentMenuReloadOutlet = ReloadLocation(
  Outlet,
  CONTENT_MENU_RELOAD_KEY
);
