import { get } from 'lodash';
import { ProForm, ProLego } from '@fafm/neowise-pro';
import { useCallback, useState, useEffect } from 'react';
import { useReload } from 'rh_util_hooks';
import { fiAdom } from 'fi-session';
import { getFwmProf, setFwmProf } from 'fi-dvm-fwm-prof';
import { CancelBtn, OkBtn } from 'rc_layout';

const { Section, Row, Column, Header, Footer, Body } = ProForm;
const { SSelect } = ProLego;

export const FolderFirmwareTemplate = (props) => {
  const [selectedDevices, setSelectedDevices] = useState(
    props.rSelectedDevices.map((dev) => dev._oData)
  );
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [firmwareTemplates, setFirmwareTemplates] = useState([]);
  const [reload, setReload] = useReload();

  useEffect(() => {
    getFwmProf(fiAdom.current()).then((lst) => setFirmwareTemplates(lst));
  }, [reload]);

  const submit = useCallback(() => {
    props.$opener.resolve({
      template: selectedTemplate,
      devices: selectedDevices,
    });
  }, [selectedDevices, selectedTemplate]);

  return (
    <>
      <Header>{gettext('Assign Firmware Template')}</Header>
      <Body>
        <Section>
          <Row label={gettext('Devices')}>
            <Column>
              <SSelect
                source={props.rDevices.map((dev) => ({
                  id: dev.oid,
                  text: dev.name,
                  _oData: dev,
                }))}
                value={selectedDevices.map((dev) => dev.oid)}
                multipleSelect={true}
                onChange={(val, elem) => {
                  setSelectedDevices(elem.map((e) => e._oData));
                }}
              />
            </Column>
          </Row>
          <Row label={gettext('Firmware Template')}>
            <Column>
              <SSelect
                source={firmwareTemplates.map((templ) => ({
                  id: templ.oid,
                  text: templ.name,
                  _oData: templ,
                }))}
                value={selectedTemplate?.oid}
                onChange={(val, elem) => {
                  setSelectedTemplate(elem._oData);
                }}
                createNewCommands={[
                  {
                    icon: 'add',
                    text: gettext('Create New'),
                    exec: async () => {
                      try {
                        const { FirmwareTemplateEditDrawerWrapper } =
                          await import(
                            'react_apps/ra_pt/firmware_template/FirmwareTemplateEdit'
                          );
                        const profileData = await props.$opener.open(
                          <FirmwareTemplateEditDrawerWrapper editParams={{}} />,
                          {
                            size: 'xl',
                          }
                        ).result;
                        await setFwmProf(fiAdom.current(), profileData, null);
                        setReload();
                      } catch (err) {
                        // close drawer
                      }
                    },
                  },
                ]}
                editCommands={[
                  {
                    icon: 'edit',
                    text: gettext('Edit'),
                    exec: async (_, choice) => {
                      try {
                        const { FirmwareTemplateEditDrawerWrapper } =
                          await import(
                            'react_apps/ra_pt/firmware_template/FirmwareTemplateEdit'
                          );
                        const profileData = await props.$opener.open(
                          <FirmwareTemplateEditDrawerWrapper
                            editParams={{
                              name: get(choice, '_oData.name', null),
                            }}
                          />,
                          {
                            size: 'xl',
                          }
                        ).result;
                        await setFwmProf(
                          fiAdom.current(),
                          profileData,
                          profileData.name
                        );
                        setReload();
                      } catch (err) {
                        // close drawer
                      }
                    },
                  },
                ]}
              />
            </Column>
          </Row>
        </Section>
      </Body>
      <Footer>
        <OkBtn onClick={submit}>{gettext('OK')}</OkBtn>
        <CancelBtn onClick={() => props.$opener.reject()}>
          {gettext('Cancel')}
        </CancelBtn>
      </Footer>
    </>
  );
};
