export { isDevSdwanCate };

const isDevSdwanCate = (device) => {
  if (!device) return false;

  const FOS_TYPES = [MACROS.DVM.DVM_OS_TYPE_FOC, MACROS.DVM.DVM_OS_TYPE_FOS];
  if (FOS_TYPES.includes(device.os_type)) {
    const ver = parseInt(device.ver, 10);
    const branch_pt = parseInt(device._branch_pt, 10);
    if (ver > 6) {
      return true;
    } else if (ver === 6) {
      return branch_pt >= MACROS.USER.DVM.SDWAN_DEV_V6_BUILD;
    }
  }

  return false;
};
