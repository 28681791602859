import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

export const getDeviceFirmwares = (state) => state.deviceFirmwares || {};

export const getDeviceFirmwaresMatrix = createSelector(
  getDeviceFirmwares,
  (deviceFirmwares) => {
    return get(deviceFirmwares, 'matrix', {});
  }
);

export const getDeviceFirmwaresMap = createSelector(
  getDeviceFirmwares,
  (deviceFirmwares) => {
    return get(deviceFirmwares, 'fwById', {});
  }
);

export const getFirmwareManualUpgradesMap = createSelector(
  getDeviceFirmwares,
  (deviceFirmwares) => {
    return get(deviceFirmwares, 'manualUpgradesMap', {});
  }
);

export const getFirmwareManualUpgradesByDeviceName = (deviceName) =>
  createSelector(getFirmwareManualUpgradesMap, (manualUpgradesMap) => {
    return get(manualUpgradesMap, [deviceName]);
  });
