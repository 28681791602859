import { ReactContentViewContainer } from 'react_apps/ra_main/main_layout/ReactContentViewContainer';

export default {
  children: [
    {
      path: 'centrallist',
      handle: {
        appUniKey: 'fsw_device',
      },
      lazy: async () => {
        const { FswTableRoot } = await import('./device_table/FswTableRoot');
        return {
          Component: FswTableRoot,
        };
      },
    },
    {
      path: 'templates',
      handle: {
        appUniKey: 'fsw_template',
      },
      children: [
        {
          path: 'switchprofile',
          lazy: async () => {
            const { FswTemplateTable } = await import(
              './profile_list_pages/FswTemplateTable'
            );
            return {
              Component: FswTemplateTable,
            };
          },
          handle: {
            appUniKey: 'fsw_template_swprof',
          },
        },
        {
          path: 'vlandefinition',
          lazy: async () => {
            const { FspVlanTable } = await import(
              './profile_list_pages/FspVlanTable'
            );
            return {
              Component: FspVlanTable,
            };
          },
          handle: {
            appUniKey: 'fsw_template_objs_vlandef',
          },
        },

        {
          path: 'flset',
          lazy: async () => {
            const { FortilinkSettingsTable } = await import(
              './profile_list_pages/FortilinkSettingsTable'
            );
            return {
              Component: FortilinkSettingsTable,
            };
          },
          handle: {
            appUniKey: 'fsw_template_linksett_flset',
          },
        },
        {
          path: 'vdomset',
          lazy: async () => {
            const { FspVdomSettingsTable } = await import(
              './profile_list_pages/FspVdomSettingsTable'
            );
            return {
              Component: FspVdomSettingsTable,
            };
          },
          handle: {
            appUniKey: 'fsw_template_linksett_vdomset',
          },
        },

        {
          handle: {
            appUniKey: 'fsw_port_policies',
          },
          element: <ReactContentViewContainer menuId='fsw_port_policies' />,
          children: [
            {
              path: 'securitypolicy',
              handle: {
                appUniKey: 'fsw_template_objs_secpol',
              },
              lazy: async () => {
                const { SecurityPolicy8021xTable } = await import(
                  './profile_list_pages/SecurityPolicy8021xTable'
                );

                return {
                  Component: SecurityPolicy8021xTable,
                };
              },
            },
            {
              path: 'ddp',
              handle: {
                appUniKey: 'fsw_template_objs_ddpol',
              },
              lazy: async () => {
                let { DynamicPortPolicyTable } = await import(
                  './profile_list_pages/DynamicPortPolicyTable'
                );
                return { Component: DynamicPortPolicyTable };
              },
            },
          ],
        },

        {
          path: 'lldpprofile',
          lazy: async () => {
            const { LldpProfileTable } = await import(
              './profile_list_pages/LldpProfileTable'
            );
            return {
              Component: LldpProfileTable,
            };
          },
          handle: {
            appUniKey: 'fsw_template_objs_lldpprof',
          },
        },

        {
          // path: 'port_policies',
          handle: {
            appUniKey: 'fsw_qos_policies',
          },
          element: <ReactContentViewContainer menuId='fsw_qos_policies' />,
          children: [
            {
              path: 'qospolicy',
              handle: {
                appUniKey: 'fsw_template_objs_qospol',
              },
              lazy: async () => {
                const { QosQosPolicyTable } = await import(
                  './profile_list_pages/QosQosPolicyTable'
                );

                return {
                  Component: QosQosPolicyTable,
                };
              },
            },
            {
              path: 'queuepolicy',
              handle: {
                appUniKey: 'fsw_template_objs_eqpol',
              },
              lazy: async () => {
                let { QosQueuePolicyTable } = await import(
                  './profile_list_pages/QosQueuePolicyTable'
                );
                return { Component: QosQueuePolicyTable };
              },
            },
            {
              path: 'ipdscpmap',
              handle: {
                appUniKey: 'fsw_template_objs_ipdscpmap',
              },
              lazy: async () => {
                let { QosIpDscpMapTable } = await import(
                  './profile_list_pages/QosIpDscpMapTable'
                );
                return { Component: QosIpDscpMapTable };
              },
            },
            {
              path: 'dot1pmap',
              handle: {
                appUniKey: 'fsw_template_objs_dot1pmap',
              },
              lazy: async () => {
                let { QosDot1pMapTable } = await import(
                  './profile_list_pages/QosDot1pMapTable'
                );
                return { Component: QosDot1pMapTable };
              },
            },
          ],
        },

        {
          path: 'command',
          lazy: async () => {
            const { FswCustomCommandTable } = await import(
              './profile_list_pages/FswCustomCommandTable'
            );
            return {
              Component: FswCustomCommandTable,
            };
          },
          handle: {
            appUniKey: 'fsw_template_objs_cuscmd',
          },
        },
        {
          path: 'aclassign',
          lazy: async () => {
            const { SwcAclAssignment } = await import(
              './profile_list_pages/SwcAclAssignment'
            );
            return {
              Component: SwcAclAssignment,
            };
          },
          handle: {
            appUniKey: 'fsw_template_objs_aclassign',
          },
        },
      ],
    },
  ],
};
