import { NwSpinner } from '@fafm/neowise-core';

export { PageLoading };

const SIZE_MAP = {
  sm: 'tw-text-xl',
  md: 'tw-text-3xl',
  lg: 'tw-text-5xl',
};
function PageLoading({ size = 'lg', loading = true }) {
  if (!loading) return null;
  return (
    <div className='tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full tw-bg-neutral-0'>
      <NwSpinner className={SIZE_MAP[size]}></NwSpinner>
    </div>
  );
}
