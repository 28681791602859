import { useEffect } from 'react';

import { fiStore, fiStoreRouting as fr } from 'fistore';
import { fiAdom } from 'fi-session';
import { fiWorkspace } from 'fi-workspace';
import { frGo } from 'fi-routing';

export const useStateChange = (refresh) => {
  /** ----------------------------------------------------------------------------
   * Constants
   * -------------------------------------------------------------------------- */
  const adom = fiAdom.current();

  /** ----------------------------------------------------------------------------
   * Hooks
   * -------------------------------------------------------------------------- */
  // register listeners for workspace changes
  useEffect(() => {
    return fiWorkspace.bindAdomUpdateFn(_callback);
  }, []);

  function _callback(curr, dispatch, prev) {
    const adomLock = fiWorkspace.adomInfo();
    //for adom unlock to lock, need update commands only
    if (adomLock.isLockedByMe()) {
      return;
    } else if (isUnlockByMe(adomLock, adom, prev)) {
      // for adom state change from lock to unlock, need reload.
      if (refresh) refresh();
      else stayAtCurrentState({ reload: true });
    }
  }

  function stayAtCurrentState({ reload = false } = {}) {
    // get state from store directly instead of useSelector + add to dependency array to prevent unnecessary rerenders
    const state = fr.getCurrentState(fiStore.getState());
    const params = state?.params;
    const currState = state?.name;
    if (currState) {
      frGo(currState, params, { reload });
    }
  }
};

/** ----------------------------------------------------------------------------
 * Helper functions
 * -------------------------------------------------------------------------- */
function isUnlockByMe(adomLock, adom, prev) {
  return (
    adomLock.isUnlock() &&
    prev &&
    prev.adoms &&
    prev.adoms.byId &&
    prev.adoms.byId[adom.oid] &&
    prev.adoms.byId[adom.oid].lock
  );
}
