import { useEffect, useState, useCallback } from 'react';
import { isFunction } from 'lodash';

import { useValidEffect } from 'rh_util_hooks';
import { fiStoreTheme } from 'fistore';
import { useSelector } from 'react-redux';

export { useSplitRecords, useHighlightTooltipTrigger };

const useSplitRecords = ({
  dataSource,
  filterDataSource,
  splitFn,
  splitData,
  entryParser,
}) => {
  /** ----------------------------------------------------------------------------
   * States
   * -------------------------------------------------------------------------- */
  const [originalRecords, setOriginalRecords] = useState([]);
  const [displayRecords, setDisplayRecords] = useState([]);
  const [popoverRecords, setPopoverRecords] = useState([]);

  /** ----------------------------------------------------------------------------
   * Hooks
   * -------------------------------------------------------------------------- */
  const parseRecords = useCallback(
    (records) => {
      if (!entryParser) return records;
      try {
        const parsed = records.map(entryParser);
        return parsed;
      } catch (error) {
        return records;
      }
    },
    [entryParser]
  );

  useValidEffect(
    async (getIsValid) => {
      if (splitData) {
        setOriginalRecords(splitData.originalRecords);
        setDisplayRecords(splitData.displayRecords);
        setPopoverRecords(splitData.popoverRecords);
        return;
      }

      // load source
      let source = isFunction(dataSource) ? await dataSource() : dataSource;

      // filter data source
      if (filterDataSource) {
        source = filterDataSource(source);
      }

      // get display records
      const { displayRecords = [], popoverRecords = [] } = splitFn(source);

      if (getIsValid()) {
        setOriginalRecords(parseRecords(source));
        setDisplayRecords(parseRecords(displayRecords));
        setPopoverRecords(parseRecords(popoverRecords));
      }
    },
    [dataSource, splitData]
  );

  return {
    originalRecords,
    displayRecords,
    popoverRecords,
  };
};

const useHighlightTooltipTrigger = ({ tooltipBadgeRef, matchedSearchText }) => {
  const theme = useSelector(fiStoreTheme.getIsDarkTheme) ? 'dark' : 'light';
  // workaround to overwrite the highlight stylings with !important for tooltip part(base)
  useEffect(() => {
    const node = tooltipBadgeRef?.current;
    const shadowRoot = node?.shadowRoot;
    if (!shadowRoot) return;

    const el = shadowRoot.querySelector('[part="base"]');
    if (!el) return;

    if (matchedSearchText) {
      const style = {
        'background-color': 'yellow !important',
        'box-shadow': '0px 0px 0.1em 0px rgba(0,0,0,0.15) !important',
        color:
          theme === 'dark'
            ? 'rgb(var(--nw-color-neutral-0)) !important' // black text
            : 'rgb(var(--nw-color-neutral-1000)) !important', // white text
      };
      el.style = _getStyleString(style); // cant use jquery .css, because it doesnt support !important declarations
      return;
    }

    // reset styles if no match
    const style = {
      'background-color': '',
      'box-shadow': '',
      color: '',
    };
    el.style = _getStyleString(style);
  }, [theme, matchedSearchText]);
};

function _getStyleString(cssObj) {
  let styleStr = '';
  for (const [key, val] of Object.entries(cssObj)) {
    styleStr += `${key}: ${val}; `;
  }
  return styleStr;
}
