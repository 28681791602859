export const REPLACEMENT_MSG_CONSTANTS: {
  [key: string]: string | number;
} = {
  msgtype_admin: gettext('Administration'),
  msgtype_alertmail: gettext('Alert Email'),
  msgtype_auth: gettext('Authentication'),
  msgtype_cerb300: gettext('FortiGuard Web Filtering'),
  msgtype_ec: gettext('FortiClient Control'),
  msgtype_ec_nac: gettext('FortiClient Control'),
  msgtype_ftp: 'FTP',
  msgtype_http: 'HTTP',
  msgtype_im: gettext('IM and P2P'),
  msgtype_mail: gettext('Email'),
  msgtype_mm1: 'MM1',
  msgtype_mm3: 'MM3',
  msgtype_mm4: 'MM4',
  msgtype_mm7: 'MM7',
  msgtype_mms: 'MMS',
  msgtype_nac_quar: gettext('Network Quarantine'),
  msgtype_nntp: 'NNTP',
  msgtype_spam: gettext('Spam'),
  msgtype_sslvpn: 'SSL VPN',
  msgtype_trafficquota: gettext('Traffic Quota Control'),
  msgtype_mail_desc: gettext('Replacement for invalid mail service.'),
  msgtype_http_desc: gettext('Replacement for invalid http service.'),
  msgtype_ftp_desc: gettext('Replacement for invalid ftp service.'),
  msgtype_alertmail_desc: gettext('Replacement for alert email.'),
  msgtype_spam_desc: gettext('Replacement for invalid SMTP service.'),
  msgtype_cerb_desc: gettext('Replacement for URL blocked by category.'),
  msgtype_cerb300_desc: gettext(
    'FortiGuard Web Filtering replacement messages.'
  ),
  msgtype_auth_desc: gettext('Replacement for authentication page.'),
  msgtype_im_desc: gettext('Replacement for blocked IM and P2P.'),
  msgtype_sslvpn_desc: gettext('Replacement for SSL VPN message.'),
  msgtype_nntp_desc: gettext('Replacement for invalid NNTP service.'),
  msgtype_mm1_desc: gettext('MM1 messages.'),
  msgtype_mm3_desc: gettext('MM3 messages.'),
  msgtype_mm4_desc: gettext('MM4 messages.'),
  msgtype_mm7_desc: gettext('MM7 messages.'),
  msgtype_mms_desc: gettext('MMS messages.'),
  msgtype_admin_desc: gettext('Replacement for administration messages.'),
  msgtype_ec_desc: gettext('Replacement for FortiClient control messages.'),
  msgtype_ec_nac_desc: gettext('Replacement for FortiClient NAC messages.'),
  msgtype_nac_quar_desc: gettext('NAC Quarantine replacement messages.'),
  msgtype_trafficquota_desc: gettext(
    'Replacement for traffic quota block messages.'
  ),
  htmlformat: 'HTML',
  noneformat: gettext('None'),
  textformat: gettext('Text'),
  wmlformat: 'WML',
  email_virus_name: gettext('Email virus message'),
  email_block_name: gettext('Email file block message'),
  email_filesize_name: gettext('Email file size block message'),
  partial_name: gettext('Partial email block message'),
  smtp_virus_name: gettext('SMTP virus message'),
  smtp_block_name: gettext('SMTP file block message'),
  smtp_filesize_name: gettext('SMTP file size message'),
  http_virus_name: gettext('Virus message'),
  infcache_block_name: gettext('Infection cache message'),
  http_block_name: gettext('File Block message'),
  http_filesize_name: gettext('Oversized file message'),
  url_block_name: gettext('URL block message'),
  http_client_virus_name: gettext('Client antivirus'),
  http_client_block_name: gettext('Client block'),
  http_client_filesize_name: gettext('Client file size'),
  http_client_bannedword_name: gettext('Client banned word'),
  ftp_dl_infected_name: gettext('Virus message'),
  ftp_dl_blocked_name: gettext('Blocked message'),
  ftp_dl_filesize_name: gettext('Oversized message'),
  alertmail_virus_name: gettext('Virus message'),
  alertmail_block_name: gettext('Block message'),
  alertmail_nids_event_name: gettext('Intrusion message'),
  alertmail_crit_event_name: gettext('Critical event message'),
  alertmail_disk_full_name: gettext('Disk full message'),
  ipblocklist_name: gettext('Email IP'),
  smtp_spam_rbl_name: 'RBL/ORDBL',
  smtp_spam_dnsbl_name: 'DNSBL/ORDBL',
  smtp_spam_helo_name: gettext('HELO/EHLO domain'),
  smtp_spam_emailblack_name: gettext('Email address'),
  smtp_spam_mimeheader_name: gettext('Mime header'),
  reversedns_name: gettext('Reverse DNS block message'),
  smtp_spam_bannedword_name: gettext('Banned word'),
  cat_block_name: gettext('URL block message'),
  http_err_name: gettext('HTTP error message'),
  mm1_retr_conf_block_name: gettext('MM1 retrieve-conf file block message'),
  mm1_retr_conf_bword_name: gettext('MM1 retrieve-conf banned word message'),
  mm1_retr_conf_sis_block_name: gettext(
    'MM1 retrieve-conf Symbian installer file block message'
  ),
  mm1_retr_conf_virus_name: gettext('MM1 retrieve-conf virus message'),
  mm1_send_conf_block_name: gettext('MM1 send-conf file block message'),
  mm1_send_conf_bword_name: gettext('MM1 send-conf banned word message'),
  mm1_send_conf_sis_block_name: gettext(
    'MM1 send-conf Symbian installer file block message'
  ),
  mm1_send_conf_virus_name: gettext('MM1 send-conf virus message'),
  mm1_send_req_block_name: gettext('MM1 send-req file block message'),
  mm1_send_req_bword_name: gettext('MM1 send-req banned word message'),
  mm1_send_req_sis_block_name: gettext(
    'MM1 send-req Symbian installer file block message'
  ),
  mm1_send_req_virus_name: gettext('MM1 send-req virus message'),
  mm1_send_conf_bwl_name: gettext(
    'MM1 send-conf carrier end point filter message'
  ),
  mm1_send_conf_bwl_desc: gettext(
    'Replacement m-send-conf message for MM1 m-send-req messages containing blocked carrier end points.'
  ),
  mm1_send_conf_flood_desc: gettext(
    'Replacement m-send-conf message for MM1 m-send-req messages flagged as flood.'
  ),
  mm1_send_conf_flood_name: gettext('MM1 send-conf flood message'),
  mm1_send_conf_dupe_name: gettext('MM1 send-conf duplicate message'),
  mm1_send_conf_dupe_desc: gettext(
    'Replacement m-send-conf message for MM1 m-send-req messages flagged as duplicate.'
  ),
  mm1_retr_conf_bwl_name: gettext(
    'MM1 retrieve-conf carrier end point filter message'
  ),
  mm1_retr_conf_bwl_desc: gettext(
    'Replacement m-retrieve-conf message for MM1 retrieved messages containing blocked carrier end points.'
  ),
  mm1_retr_conf_flood_name: gettext('MM1 retrieve-conf flood message'),
  mm1_retr_conf_flood_desc: gettext(
    'Replacement m-retrieve-conf message for MM1 retrieved messages flagged as flood.'
  ),
  mm1_retr_conf_dupe_name: gettext('MM1 retrieve-conf duplicate message'),
  mm1_retr_conf_dupe_desc: gettext(
    'Replacement m-retrieve-conf message for MM1 retrieved messages with blocked contents.'
  ),
  mm1_send_req_bwl_name: gettext(
    'MM1 send-req carrier end point filter message'
  ),
  mm1_send_req_bwl_desc: gettext(
    'Replacement m-send-req message for client notification.'
  ),
  mm1_send_req_flood_name: gettext('MM1 send-req flood message'),
  mm1_send_req_flood_desc: gettext(
    'Replacement m-send-req message for client notification.'
  ),
  mm1_send_req_dupe_name: gettext('MM1 send-req duplicate message'),
  mm1_send_req_dupe_desc: gettext(
    'Replacement m-send-req message for client notification.'
  ),
  mm1_send_req_flood_alert_name: gettext(
    'MM1 send request flood alert message'
  ),
  mm1_send_req_flood_alert_desc: gettext('MM1 flood alert message.'),
  mm1_send_req_dupe_alert_name: gettext(
    'MM1 send request duplicate alert message'
  ),
  mm1_send_req_dupe_alert_desc: gettext('MM1 duplicate alert message.'),
  mm3_block_name: gettext('MM3 file block message'),
  mm3_block_notif_name: gettext('MM3 file block notification message'),
  mm3_bword_name: gettext('MM3 banned word message'),
  mm3_bword_notif_name: gettext('MM3 banned word notification message'),
  mm3_sis_block_name: gettext('MM3 Symbian installer file block message'),
  mm3_sis_block_notif_name: gettext(
    'MM3 Symbian installer file block notification message'
  ),
  mm3_virus_name: gettext('MM3 virus message'),
  mm3_virus_notif_name: gettext('MM3 virus notification message'),
  mm3_bwl_name: gettext('MM3 carrier end point filter message'),
  mm3_bwl_desc: gettext(
    'Replacement message for MM3 messages containing blocked carrier end points.'
  ),
  mm3_bwl_notif_name: gettext(
    'MM3 carrier end point filter notification message'
  ),
  mm3_bwl_notif_desc: gettext(
    'MM3 carrier end point filter notification message.'
  ),
  mm3_flood_notif_name: gettext('MM3 flood notification message'),
  mm3_flood_notif_desc: gettext('MM3 flood notification message.'),
  mm3_dupe_notif_name: gettext('MM3 duplicate notification message'),
  mm3_dupe_notif_desc: gettext('MM3 duplicate notification message.'),
  mm3_flood_alert_name: gettext('MM3 flood alert message'),
  mm3_flood_alert_desc: gettext('MM3 flood alert message.'),
  mm3_dupe_alert_name: gettext('MM3 duplicate alert message'),
  mm3_dupe_alert_desc: gettext('MM3 duplicate alert message.'),
  mm4_bwl_name: gettext('MM4 carrier end point filter message'),
  mm4_bwl_desc: gettext(
    'Replacement message for MM4 messages containing blocked carrier end points.'
  ),
  mm4_bwl_notif_name: gettext(
    'MM4 carrier end point filter notification message'
  ),
  mm4_bwl_notif_desc: gettext(
    'MM4 carrier end point filter notification message.'
  ),
  mm4_flood_notif_name: gettext('MM4 flood notification message'),
  mm4_flood_notif_desc: gettext('MM4 flood notification message.'),
  mm4_dupe_notif_name: gettext('MM4 duplicate notification message'),
  mm4_dupe_notif_desc: gettext('MM4 duplicate notification message.'),
  mm4_flood_alert_name: gettext('MM4 flood alert message'),
  mm4_flood_alert_desc: gettext('MM4 flood alert message.'),
  mm4_dupe_alert_name: gettext('MM4 duplicate alert message'),
  mm4_dupe_alert_desc: gettext('MM4 duplicate alert message.'),
  mm4_dupe_name: gettext('MM4 duplicate message'),
  mm4_dupe_desc: gettext(
    'Replacement message for MM4 messages designated as duplicates.'
  ),
  mm4_flood_name: gettext('MM4 flood message'),
  mm4_flood_desc: gettext(
    'Replacement message for MM4 messages designated as flood.'
  ),
  mm4_block_name: gettext('MM4 file block message'),
  mm4_block_notif_name: gettext('MM4 file block notification message'),
  mm4_bword_name: gettext('MM4 banned word message'),
  mm4_bword_notif_name: gettext('MM4 banned word notification message'),
  mm4_sis_block_name: gettext('MM4 Symbian installer file block message'),
  mm4_sis_block_notif_name: gettext(
    'MM4 Symbian installer file block notification message'
  ),
  mm4_virus_name: gettext('MM4 virus message'),
  mm4_virus_notif_name: gettext('MM4 virus notification message'),
  mm7_block_name: gettext('MM7 file block message'),
  mm7_block_notif_name: gettext('MM7 file block notification message'),
  mm7_bword_name: gettext('MM7 banned word message'),
  mm7_bword_notif_name: gettext('MM7 banned word notification message'),
  mm7_sis_block_name: gettext('MM7 Symbian installer file block message'),
  mm7_sis_block_notif_name: gettext(
    'MM7 Symbian installer file block notification message'
  ),
  mm7_virus_name: gettext('MM7 virus message'),
  mm7_virus_notif_name: gettext('MM7 virus notification message'),
  mm7_bwl_name: gettext('MM7 carrier end point filter message'),
  mm7_bwl_desc: gettext(
    'Replacement message for MM7 messages containing blocked carrier end points.'
  ),
  mm7_bwl_notif_name: gettext(
    'MM7 carrier end point filter notification message'
  ),
  mm7_bwl_notif_desc: gettext(
    'MM7 carrier end point filter notification message.'
  ),
  mm7_flood_notif_name: gettext('MM7 flood notification message'),
  mm7_flood_notif_desc: gettext('MM7 flood notification message.'),
  mm7_dupe_notif_name: gettext('MM7 duplicate notification message'),
  mm7_dupe_notif_desc: gettext('MM7 duplicate notification message.'),
  mm7_flood_alert_name: gettext('MM7 flood alert message'),
  mm7_flood_alert_desc: gettext('MM7 flood alert message.'),
  mm7_dupe_alert_name: gettext('MM7 duplicate alert message'),
  mm7_dupe_alert_desc: gettext('MM7 duplicate alert message.'),
  mms_block_file_replace_name: gettext(
    'MMS blocked content replacement message'
  ),
  mms_block_file_replace_desc: gettext(
    'MMS blocked content replacement message.'
  ),
  mm1_retr_conf_block_desc: gettext(
    'Replacement m-retrieve-conf message for retrieved MM1 messages containing blocked files..'
  ),
  mm1_retr_conf_bword_desc: gettext(
    'Replacement m-retrieve-conf message for MM1 retrieved messages containing banned words..'
  ),
  mm1_retr_conf_sis_block_desc: gettext(
    'Replacement m-retrieve-conf message for retrieved MM1 messages containing blocked Symbian installer files..'
  ),
  mm1_retr_conf_virus_desc: gettext(
    'Replacement m-retrieve-conf message for retrieval of infected MM1 messages..'
  ),
  mm1_send_conf_block_desc: gettext(
    'Replacement m-send-conf message for MM1 m-send-req messages containing blocked files..'
  ),
  mm1_send_conf_bword_desc: gettext(
    'Replacement m-send-conf message for MM1 m-send-req messages containing banned words..'
  ),
  mm1_send_conf_sis_block_desc: gettext(
    'Replacement m-send-conf message for MM1 m-send-req messages containing blocked Symbian installer files..'
  ),
  mm1_send_conf_virus_desc: gettext(
    'Replacement m-send-conf message for infected MM1 m-send-req messages..'
  ),
  mm1_send_req_block_desc: gettext(
    'Replacement m-send-req message for client notification..'
  ),
  mm1_send_req_bword_desc: gettext(
    'Replacement m-send-req message for client notification..'
  ),
  mm1_send_req_sis_block_desc: gettext(
    'Replacement m-send-req message for client notification..'
  ),
  mm1_send_req_virus_desc: gettext(
    'Replacement m-send-req message for client notification..'
  ),
  mm3_block_desc: gettext(
    'Replacement message for MM3 messages containing blocked files..'
  ),
  mm3_block_notif_desc: gettext('MM3 block notification message..'),
  mm3_bword_desc: gettext(
    'Replacement message for MM3 messages containing banned words..'
  ),
  mm3_bword_notif_desc: gettext('MM3 banned word notification message..'),
  mm3_sis_block_desc: gettext(
    'Replacement message for MM3 messages containing blocked Symbian installer files..'
  ),
  mm3_sis_block_notif_desc: gettext(
    'MM3 Symbian installer file block notification message..'
  ),
  mm3_virus_desc: gettext(
    'Replacement message for MM3 messages containing viruses..'
  ),
  mm3_virus_notif_desc: gettext('MM3 virus notification message..'),
  mm4_block_desc: gettext(
    'Replacement message for MM4 messages containing blocked files..'
  ),
  mm4_block_notif_desc: gettext('MM4 block notification message..'),
  mm4_bword_desc: gettext(
    'Replacement message for MM4 messages containing banned words..'
  ),
  mm4_bword_notif_desc: gettext('MM4 banned word notification message..'),
  mm4_sis_block_desc: gettext(
    'Replacement message for MM4 messages containing blocked Symbian installer files..'
  ),
  mm4_sis_block_notif_desc: gettext(
    'MM4 Symbian installer file block notification message..'
  ),
  mm4_virus_desc: gettext(
    'Replacement message for MM4 messages containing viruses..'
  ),
  mm4_virus_notif_desc: gettext('MM4 virus notification message..'),
  mm7_block_desc: gettext(
    'Replacement message for MM7 messages containing blocked files..'
  ),
  mm7_block_notif_desc: gettext('MM7 block notification message..'),
  mm7_bword_desc: gettext(
    'Replacement message for MM7 messages containing banned words..'
  ),
  mm7_bword_notif_desc: gettext('MM7 banned word notification message..'),
  mm7_sis_block_desc: gettext(
    'Replacement message for MM7 messages containing blocked Symbian installer files..'
  ),
  mm7_sis_block_notif_desc: gettext(
    'MM7 Symbian installer file block notification message..'
  ),
  mm7_virus_desc: gettext(
    'Replacement message for MM7 messages containing viruses..'
  ),
  mm7_virus_notif_desc: gettext('MM7 virus notification message..'),
  email_virus_desc: gettext('Replacement for infected email attachments.'),
  email_block_desc: gettext('Replacement for blocked email attachments.'),
  email_filesize_desc: gettext('Replacement for oversized email attachments.'),
  partial_desc: gettext('Replacement for fragmented (partial) email.'),
  smtp_virus_desc: gettext(
    'SMTP error message for infected email attachments in splice mode.'
  ),
  smtp_block_desc: gettext(
    'SMTP error message for blocked email attachments in splice mode.'
  ),
  smtp_filesize_desc: gettext(
    'SMTP error message for oversized email attachments in splice mode.'
  ),
  http_virus_desc: gettext('Replacement for infected HTTP downloads.'),
  infcache_block_desc: gettext(
    'Replacement for cached infected HTTP downloads'
  ),
  http_block_desc: gettext('Replacement for blocked HTTP downloads.'),
  http_filesize_desc: gettext('Replacement for oversized HTTP downloads.'),
  url_block_desc: gettext('Replacement for blocked URLs.'),
  http_client_virus_desc: gettext('Replacement for infected HTTP uploads.'),
  http_client_block_desc: gettext('Replacement for blocked HTTP uploads.'),
  http_client_filesize_desc: gettext('Replacement for oversized HTTP uploads.'),
  http_client_bannedword_desc: gettext(
    'Replacement for HTTP uploads containing banned words.'
  ),
  ftp_dl_infected_desc: gettext(
    'Replacement for infected FTP downloads or uploads.'
  ),
  ftp_dl_blocked_desc: gettext(
    'Replacement for blocked FTP downloads or uploads.'
  ),
  ftp_dl_filesize_desc: gettext(
    'Replacement for oversized FTP downloads or uploads.'
  ),
  alertmail_virus_desc: gettext('Alert email for virus incidents.'),
  alertmail_block_desc: gettext('Alert email for block incidents.'),
  alertmail_nids_event_desc: gettext('Alert email for IPS events.'),
  alertmail_crit_event_desc: gettext('Alert email for critical system events.'),
  alertmail_disk_full_desc: gettext('Alert email for disk full event.'),
  ipblocklist_desc: gettext('Replacement for blocked email IP address.'),
  smtp_spam_rbl_desc: gettext(
    'Replacement for blocked email RBL/ORDBL address.'
  ),
  smtp_spam_dnsbl_desc: gettext(
    'Replacement for blocked email DNSBL/ORDBL address.'
  ),
  smtp_spam_helo_desc: gettext(
    'Replacement for invalid SMTP HELO/EHLO domain.'
  ),
  smtp_spam_emailblack_desc: gettext('Replacement for blocked email address.'),
  smtp_spam_mimeheader_desc: gettext('Replacement for invalid mime header.'),
  reversedns_desc: gettext('Replacement for returned email domain.'),
  smtp_spam_bannedword_desc: gettext('Replacement for blocked banned word.'),
  submit_name: gettext('Spam submission message'),
  submit_desc: gettext('False positive spam submission message.'),
  cat_block_desc: gettext('Category block message.'),
  http_err_desc: gettext('HTTP 4xx and 5xx error block message.'),
  email_dlp_name: gettext('Data loss prevention message'),
  email_dlp_desc: gettext('Replacement for data loss prevention downloads.'),
  email_dlp_subject_name: gettext('Email DLP subject'),
  email_dlp_subject_desc: gettext('Subject of data loss prevention messages.'),
  email_dlp_ban_name: gettext('Email DLP ban'),
  email_dlp_ban_desc: gettext(
    'Replacement for banned by data loss prevention.'
  ),
  email_dlp_ban_sender_name: gettext(
    'Sender banned by data loss prevention message'
  ),
  email_dlp_ban_sender_desc: gettext(
    'Replacement for sender banned by data loss prevention.'
  ),
  http_dlp_name: gettext('Data loss prevention message'),
  http_dlp_desc: gettext('Replacement for data loss prevention downloads.'),
  http_dlp_ban_name: gettext('DLP ban message'),
  http_dlp_ban_desc: gettext('Replacement for banned by data loss prevention.'),
  http_post_block_name: gettext('POST block'),
  http_post_block_desc: gettext('Replacement for HTTP POST block.'),
  ftp_dl_dlp_name: gettext('DLP message'),
  ftp_dl_dlp_desc: gettext('Replacement for DLP FTP downloads or uploads.'),
  ftp_dl_dlp_ban_name: gettext('DLP ban message'),
  ftp_dl_dlp_ban_desc: gettext(
    'Replacement for DLP banned FTP downloads or uploads.'
  ),
  nntp_dlp_name: gettext('Data loss prevention message'),
  nntp_dlp_desc: gettext('Replacement for data loss prevention downloads.'),
  nntp_dlp_subject_name: gettext('Subject of data loss prevention message'),
  nntp_dlp_subject_desc: gettext('Subject for data loss prevention messages.'),
  nntp_dlp_ban_name: gettext('Banned by data loss prevention message'),
  nntp_dlp_ban_desc: gettext('Replacement for banned by data loss prevention.'),
  im_dlp_name: gettext('Data loss prevention message'),
  im_dlp_desc: gettext('Replacement for data loss prevention downloads.'),
  im_dlp_ban_name: gettext('Banned by data loss prevention message'),
  im_dlp_ban_desc: gettext('Replacement for banned by data loss prevention.'),
  auth_password_page_name: gettext('Password Expiration Page'),
  auth_password_page_desc: gettext(
    'Replacement HTML for password expiration page'
  ),
  auth_cert_passwd_page_name: gettext('Certificate Password Page'),
  auth_cert_passwd_page_desc: gettext(
    'Replacement HTML for certificate password page'
  ),
  auth_login_page_name: gettext('Login page'),
  auth_login_page_desc: gettext('Replacement for login page.'),
  auth_challenge_page_name: gettext('Login challenge page'),
  auth_challenge_page_desc: gettext('Replacement for login challenge page.'),
  auth_disclaimer_page_1_name: gettext('Disclaimer page'),
  auth_disclaimer_page_1_desc: gettext('Replacement for disclaimer page.'),
  auth_email_failed_page_name: gettext('Email Collection Invalid Email'),
  auth_email_failed_page_desc: gettext(
    'Replacement HTML for email collection page after user enters invalid email'
  ),
  auth_guest_email_page_name: gettext('Guest User Email Template'),
  auth_guest_email_page_desc: gettext(
    'Replacement text for guest-user credentials email message'
  ),
  auth_guest_print_page_name: gettext('Guest User Print Template'),
  auth_guest_print_page_desc: gettext(
    'Replacement for guest-user credentials print out'
  ),
  auth_reject_page_name: gettext('Declined disclaimer page'),
  auth_reject_page_desc: gettext('Replacement for declined disclaimer page.'),
  auth_next_fortitoken_page_name: gettext('Next FortiToken Page'),
  auth_next_fortitoken_page_desc: gettext(
    'Replacement HTML for next FortiToken authentication page'
  ),
  auth_login_failed_page_name: gettext('Login failed page'),
  auth_login_failed_page_desc: gettext('Replacement for login failed page.'),
  auth_keepalive_page_name: gettext('Keepalive page'),
  auth_keepalive_page_desc: gettext('Replacement for keepalive page.'),
  ftgd_block_name: gettext('FortiGuard block page'),
  ftgd_block_desc: gettext(
    'Replacement for FortiGuard Web Filtering block page.'
  ),
  ftgd_ovrd_name: gettext('FortiGuard override page'),
  ftgd_ovrd_desc: gettext(
    'Replacement for FortiGuard Web Filtering override page.'
  ),
  im_file_xfer_block_name: gettext('File block message'),
  im_file_xfer_block_desc: gettext('Instant message sent if file is blocked'),
  im_file_xfer_name_name: gettext('File name block message'),
  im_file_xfer_name_desc: gettext(
    'Instant message sent if file name is blocked'
  ),
  im_file_xfer_infected_name: gettext('Virus message'),
  im_file_xfer_infected_desc: gettext(
    'Instant message sent if file is infected'
  ),
  im_file_xfer_size_name: gettext('Oversized file message'),
  im_file_xfer_size_desc: gettext('Instant message sent if file is too large'),
  im_voice_chat_block_name: gettext('Voice chat block message'),
  im_voice_chat_block_desc: gettext(
    'Instant message sent if voice chat is blocked'
  ),
  im_photo_share_block_name: gettext('Photo share block message'),
  im_photo_share_block_desc: gettext(
    'Instant message sent if photo sharing is blocked'
  ),
  sslvpn_login_name: gettext('SSL VPN login message'),
  hostcheck_error_name: gettext('Host Check Error Message'),
  hostcheck_error_desc: gettext(
    'Replacement text for host check error message'
  ),
  sslvpn_header_name: gettext('SSL VPN header page'),
  sslvpn_header_desc: gettext('Replacement for SSL VPN Header page'),
  sslvpn_limit_name: gettext('SSL VPN limit page'),
  sslvpn_limit_desc: gettext(
    'Replacement for SSL VPN connection limit exceeded page'
  ),
  sslvpn_login_desc: gettext('SSL VPN login message'),
  nntp_dl_infected_desc: gettext(
    'Replacement for infected NNTP downloads or uploads.'
  ),
  nntp_dl_blocked_desc: gettext(
    'Replacement for blocked NNTP downloads or uploads.'
  ),
  nntp_dl_filesize_desc: gettext(
    'Replacement for oversized NNTP downloads or uploads.'
  ),
  nntp_dl_infected_name: gettext('Virus message'),
  nntp_dl_blocked_name: gettext('Blocked message'),
  nntp_dl_filesize_name: gettext('Oversized message'),
  admin_disclaimer_text_name: gettext('Login Disclaimer'),
  admin_disclaimer_text_desc: gettext('Replacement for login disclaimer.'),
  endpt_download_portal_name: gettext('FortiClient Control Download Portal'),
  endpt_download_portal_desc: gettext(
    'Replacement for FortiClient control download portal.'
  ),
  endpt_download_ftcl_name: gettext('FortiClient Download Help'),
  endpt_download_ftcl_desc: gettext(
    'Replacement HTML Fragment for FortiClient Download Help'
  ),
  endpt_download_portal_linux_name: gettext(
    'Endpoint NAC Download Portal (Linux)'
  ),
  endpt_download_portal_linux_desc: gettext(
    'Replacement HTML for endpoint FortiClient download page (Linux)'
  ),
  endpt_ftcl_incompat_name: gettext('FortiClient Incompatible Warning'),
  endpt_ftcl_incompat_desc: gettext(
    'Replacement HTML Fragment for Incompatible FortiClient Warning'
  ),
  endpt_remedy_av_3rdp_name: gettext('FortiClient 3rd-Party AV Remedy'),
  endpt_remedy_av_3rdp_desc: gettext(
    'Replacement HTML Fragment for Compliance Error Remedy of Disabled Third Party AntiVirus'
  ),
  endpt_remedy_ftcl_autofix_name: gettext('FortiClient Auto-Fixable Remedy'),
  endpt_remedy_ftcl_autofix_desc: gettext(
    'Replacement HTML Fragment for Compliance Error Remedy of FortiClient Auto-Fixable Compliance Issue'
  ),
  endpt_remedy_inst_name: gettext('FortiClient Installation Remedy'),
  endpt_remedy_inst_desc: gettext(
    'Replacement HTML Fragment for Compliance Error Remedy of Uninstalled FortiClient'
  ),
  endpt_remedy_os_ver_name: gettext('FortiClient OS Version Remedy'),
  endpt_remedy_os_ver_desc: gettext(
    'Replacement HTML Fragment for Compliance Error Remedy of Non-Compliant Operating System Version'
  ),
  endpt_remedy_reg_name: gettext('FortiClient Registration Remedy'),
  endpt_remedy_reg_desc: gettext(
    'Replacement HTML Fragment for Compliance Error Remedy of Non-Registered FortiClient'
  ),
  endpt_remedy_sig_ids_name: gettext('FortiClient Signature Remedy'),
  endpt_remedy_sig_ids_desc: gettext(
    'Replacement HTML Fragment for Compliance Error Remedy of Unauthorized Client'
  ),
  endpt_remedy_ver_name: gettext('FortiClient Version Remedy'),
  endpt_remedy_ver_desc: gettext(
    'Replacement HTML Fragment for Compliance Error Remedy of Old FortiClient Version'
  ),
  endpt_remedy_vuln_name: gettext('FortiClient Vulnerability Remedy'),
  endpt_remedy_vuln_desc: gettext(
    'Replacement HTML Fragment for Compliance Error Remedy of Vulnerable Client'
  ),
  endpt_warning_portal_linux_name: gettext(
    'Endpoint NAC Warning Portal (Linux)'
  ),
  endpt_warning_portal_linux_desc: gettext(
    'Replacement HTML for endpoint FortiClient warning/download page (Linux)'
  ),
  nac_quar_virus_name: gettext('Virus Message'),
  nac_quar_dos_name: gettext('DoS Message'),
  nac_quar_ips_name: gettext('IPS Message'),
  nac_quar_dlp_name: gettext('DLP Message'),
  nac_quar_virus_desc: gettext(
    'Remediation portal message for virus quarantine.'
  ),
  nac_quar_dos_desc: gettext('Remediation portal message for DoS quarantine.'),
  nac_quar_ips_desc: gettext('Remediation portal message for IPS quarantine.'),
  nac_quar_dlp_desc: gettext('Remediation portal message for DLP quarantine.'),
  err_srv_denied: gettext('Service Denied'),
  err_msg_fmt_corrupt: gettext('Message Format Corrupt'),
  err_snd_addr_unresolv: gettext('Sender Address Unresolved'),
  err_net_prob: gettext('Network Problem'),
  err_content_not_accept: gettext('Content Not Accepted'),
  err_unsupp_msg: gettext('Unsupported Message'),
  err_blankname: gettext('Name cannot be blank'),
  err_legalchar: gettext(
    'The legal characters are numbers(0-9),letters(a-z,A-Z)and special characters - and _'
  ),
  err_maxcomment: gettext(
    'The length of the comment must not exceed 63 characters.'
  ),
  err_blankfile: gettext('File name cannot be blank.'),
  urlfilter_err_name: gettext('Web filter error message'),
  urlfilter_err_desc: gettext('Replacement for HTTP web filter errors'),
  http_contenttypeblock_name: gettext('Content-type block message'),
  http_contenttypeblock_desc: gettext(
    'Replacement for HTTP downloads of banned content-types'
  ),
  ftgd_quota_name: gettext('FortiGuard quota page'),
  ftgd_quota_desc: gettext(
    'Replacement for FortiGuard Web Filtering quota exceeded block page'
  ),
  endpt_recommendation_portal_name: gettext(
    'FortiClient NAC Recommendation Portal'
  ),
  endpt_recommendation_portal_desc: gettext(
    'Replacement for FortiClient NAC recommendation portal'
  ),
  per_ip_shaper_block_name: gettext('Per IP traffic shaper block message'),
  per_ip_shaper_block_desc: gettext(
    'http message if traffic is blocked by per IP shaper'
  ),
  msgtype_webproxy: gettext('Web Proxy'),
  msgtype_webproxy_desc: gettext('Replacement for invalid web proxy service.'),
  deny_name: gettext('Web proxy access denied'),
  deny_desc: gettext('Replacement for web proxy access denied.'),
  user_limit_name: gettext('Web proxy user limit'),
  user_limit_desc: gettext('Replacement for web proxy user limit.'),
  auth_challenge_name: gettext('Web proxy login challenge'),
  auth_challenge_desc: gettext('Replacement for web proxy login notification.'),
  auth_login_fail_name: gettext('Web proxy login fail'),
  auth_login_fail_desc: gettext(
    'Replacement for web proxy login fail message.'
  ),
  auth_authorization_fail_name: gettext('Web proxy authorization fail'),
  auth_authorization_fail_desc: gettext(
    'Replacement for web proxy authorization fail message.'
  ),
  web_proxy_http_err_name: gettext('Web-proxy HTTP error page'),
  web_proxy_http_err_desc: gettext(
    'Replacement HTML for web-proxy HTTP error page'
  ),
  traffic_shaper_block_name: gettext('Traffic shaper block message'),
  traffic_shaper_block_desc: gettext(
    'http message if traffic is blocked by traffic shaper.'
  ),
  msgtype_captivedefault: gettext('Captive Portal'),
  msgtype_captivedefault_desc: gettext(
    'Replacement for captive portal default pages.'
  ),
  msgtype_captivealt1: gettext('Captive Portal Alternative'),
  msgtype_captivealt1_desc: gettext(
    'Replacement for captive portal alternative pages.'
  ),
  msgtype_ftpproxy: gettext('FTP Proxy'),
  msgtype_ftpproxy_desc: gettext('Replacement for invalid FTP Proxy service.'),
  msgtype_utm: gettext('Security'),
  msgtype_utm_desc: gettext('Replacement for security page.'),
  cpa_disclaimer_page_1_name: gettext('Captive portal disclaimer page'),
  cpa_disclaimer_page_1_desc: gettext('Replacement for disclaimer page.'),
  cpa_disclaimer_page_2_name: gettext('Captive portal rejected page'),
  cpa_disclaimer_page_2_desc: gettext(
    'Replacement for declined disclaimer page.'
  ),
  cpa_disclaimer_page_3_name: gettext('Captive portal login page'),
  cpa_disclaimer_page_3_desc: gettext('Replacement for login page.'),
  cpa_reject_page_name: gettext('Captive portal login failed page'),
  cpa_reject_page_desc: gettext('Replacement for login failed page.'),
  cpa_login_page_name: gettext('Captive portal login challenge page'),
  cpa_login_page_desc: gettext('Replacement for login challenge page.'),
  cpa_login_failed_page_name: gettext('Captive portal keepalive page'),
  cpa_login_failed_page_desc: gettext('Replacement for keepalive page.'),
  bannedword_name: gettext('Banned word message'),
  bannedword_desc: gettext(
    'Replacement for HTTP downloads containing banned words.'
  ),
  http_archive_block_name: gettext('Archive block message'),
  http_archive_block_desc: gettext(
    'Replacement for blocked by archive HTTP downloads.'
  ),
  https_invalid_cert_block_name: gettext('Invalid certificate block'),
  https_invalid_cert_block_desc: gettext(
    'Replacement for HTTPS invalid certificate block.'
  ),
  http_client_archive_block_name: gettext('Client archive block'),
  http_client_archive_block_desc: gettext(
    'Replacement for HTTP uploads containing blocked archives.'
  ),
  switching_protocols_block_name: gettext('Switching Protocols Blocked'),
  switching_protocols_block_desc: gettext(
    'Replacement for Switching Protocols Blocked page'
  ),
  pre_admin_disclaimer_text_name: gettext('Pre-login Disclaimer Message'),
  pre_admin_disclaimer_text_desc: gettext(
    'Replacement message for pre-login disclaimer'
  ),
  post_admin_disclaimer_text_name: gettext('Post-login Disclaimer Message'),
  post_admin_disclaimer_text_desc: gettext(
    'Replacement message for post-login disclaimer'
  ),
  ftp_explicit_banner_name: gettext('Explicit Banner Message'),
  ftp_explicit_banner_desc: gettext('Replacement for greeting banner.'),
  ftp_dl_archive_block_name: gettext('Archive block'),
  ftp_dl_archive_block_desc: gettext(
    'Replacement for FTP downloads or uploads blocked because of archive.'
  ),
  auth_portal_page_name: gettext('Portal Page'),
  auth_portal_page_desc: gettext(
    'Replacement HTML for post-authentication portal page'
  ),
  auth_success_msg_name: gettext('Success message'),
  auth_success_msg_desc: gettext('Replacement for success message.'),
  auth_fortitoken_page_name: gettext('FortiToken page'),
  auth_fortitoken_page_desc: gettext('Replacement for FortiToken page.'),
  auth_email_token_page_name: gettext('Email token page'),
  auth_email_token_page_desc: gettext('Replacement for email token page.'),
  ftgd_warning_name: gettext('FortiGuard warning message'),
  ftgd_warning_desc: gettext(
    'Replacement for FortiGuard Web Filtering warning message.'
  ),
  im_video_chat_block_name: gettext('Video chat block message'),
  im_video_chat_block_desc: gettext(
    'Instant message sent if video chat is blocked.'
  ),
  nac_endpt_download_portal_name: gettext(
    'FortiClient NAC Download Portal (Windows)'
  ),
  nac_endpt_download_portal_desc: gettext(
    'Replacement HTML for FortiClient download page (Windows)'
  ),
  endpt_quarantine_portal_name: gettext('FortiClient Quarantine Portal'),
  endpt_quarantine_portal_desc: gettext('FortiClient Quarantine Portal'),
  endpt_warning_portal_name: gettext('Endpoint NAC Warning Portal (Windows)'),
  endpt_warning_portal_desc: gettext(
    'Replacement HTML for endpoint FortiClient warning or download page (Windows)'
  ),
  endpt_warning_portal_mac_name: gettext('Endpoint NAC Warning Portal (Mac)'),
  endpt_warning_portal_mac_desc: gettext(
    'Replacement HTML for endpoint FortiClient warning or download page (Mac)'
  ),
  endpt_download_portal_mac_name: gettext(
    'FortiClient NAC Download Portal (Mac)'
  ),
  endpt_download_portal_mac_desc: gettext(
    'Replacement HTML for FortiClient download page (Mac)'
  ),
  endpt_download_portal_ios_name: gettext(
    'FortiClient NAC Download Portal (iOS)'
  ),
  endpt_download_portal_ios_desc: gettext(
    'Replacement HTML for FortiClient download page (iOS)'
  ),
  endpt_download_portal_aos_name: gettext(
    'FortiClient NAC Download Portal (Android)'
  ),
  endpt_download_portal_aos_desc: gettext(
    'Replacement HTML for FortiClient download page (Android)'
  ),
  endpt_download_portal_other_name: gettext(
    'FortiClient NAC Download Portal (Other)'
  ),
  endpt_download_portal_other_desc: gettext(
    'Replacement HTML for FortiClient download page (Other)'
  ),
  endpt_rmd_block_portal_name: gettext(
    'FortiClient NAC recommendation block page.'
  ),
  endpt_rmd_block_portal_desc: gettext(
    'Replacement for FortiClient NAC recommendation block page.'
  ),
  auth_token_login_failed_page_name: gettext('Two-Factor Login Failed'),
  auth_token_login_failed_page_desc: gettext(
    'Replacement HTML for two-factor authentication failed page'
  ),
  auth_token_login_page_name: gettext('Two-Factor Login Page'),
  auth_token_login_page_desc: gettext(
    'Replacement HTML for two-factor authentication login page'
  ),
  auth_email_harvesting_page_name: gettext('Email Collection'),
  auth_email_harvesting_page_desc: gettext(
    'Replacement HTML for email collection page'
  ),
  auth_sms_token_page_name: gettext('SMS token page'),
  auth_sms_token_page_desc: gettext('Replacement for SMS token page.'),
  endpt_block_portal_name: gettext('FortiClient NAC Block Page'),
  endpt_block_portal_desc: gettext(
    'Replacement for FortiClient NAC block page.'
  ),
  endpt_ec_block_page_name: gettext('FortiClient NAC Feature Block Page'),
  endpt_ec_block_page_desc: gettext(
    'Replacement for FortiClient NAC feature block page.'
  ),
  endpt_rmd_ec_block_page_name: gettext(
    'FortiClient NAC Recommendation Feature Block Page'
  ),
  endpt_rmd_ec_block_page_desc: gettext(
    'Replacement for FortiClient NAC recommendation feature block page.'
  ),
  mm1_send_conf_checksum_name: gettext(
    'MM1 send-conf content checksum block message'
  ),
  mm1_send_conf_checksum_desc: gettext(
    'Replacement m-send-conf message for MM1 m-send-req messages containing content checksum blocked payloads.'
  ),
  mm1_retr_conf_checksum_name: gettext(
    'MM1 retrieve-conf content checksum block message'
  ),
  mm1_retr_conf_checksum_desc: gettext(
    'Replacement m-retrieve-conf message for MM1 retrieved messages containing content checksum blocked payloads.'
  ),
  mm1_send_req_checksum_name: gettext(
    'MM1 send-req content checksum block message'
  ),
  mm1_send_req_checksum_desc: gettext(
    'Replacement m-send-req message for client notification.'
  ),
  mm3_checksum_name: gettext('MM3 content checksum block message'),
  mm3_checksum_desc: gettext(
    'Replacement message for MM3 messages containing content checksum blocked payloads.'
  ),
  mm3_checksum_notif_name: gettext(
    'MM3 content checksum block notification message'
  ),
  mm3_checksum_notif_desc: gettext(
    'MM3 content checksum block notification message.'
  ),
  mm4_checksum_name: gettext('MM4 content checksum block message'),
  mm4_checksum_desc: gettext(
    'Replacement message for MM4 messages containing content checksum blocked payloads.'
  ),
  mm4_checksum_notif_name: gettext(
    'MM4 content checksum block notification message'
  ),
  mm4_checksum_notif_desc: gettext(
    'MM4 content checksum block notification message.'
  ),
  mm7_checksum_name: gettext('MM7 content checksum block message'),
  mm7_checksum_desc: gettext(
    'Replacement message for MM7 messages containing content checksum blocked payloads.'
  ),
  mm7_checksum_notif_name: gettext(
    'MM7 content checksum block notification message'
  ),
  mm7_checksum_notif_desc: gettext(
    'MM7 content checksum block notification message.'
  ),

  auth_block_notification_page_name: gettext('Block Notification Page'),
  auth_block_notification_page_desc: gettext(
    'Replacement HTML for authentication block notification page'
  ),
  device_detection_failure_name: gettext(
    'Device Detection Portal Failure Page'
  ),
  device_detection_failure_desc: gettext(
    'Replacement for device detection portal failure page'
  ),
  nac_quar_admin_name: gettext('Admin Message'),
  nac_quar_admin_desc: gettext(
    'Remediation portal message for quarantine administration'
  ),

  smtp_spam_feip_name: gettext('FortiGuard Block Message'),
  smtp_spam_feip_desc: gettext(
    'Replacement for blocked due to IP blocklist by FortiGuard'
  ),
  smtp_spam_ase_name: gettext('ASE Reported Message'),
  smtp_spam_ase_desc: gettext('Replacement for blocked due to reported by ASE'),

  nac_quar_app_name: gettext('Windows Executable Block Page'),
  nac_quar_app_desc: gettext('Replacement for blocked windows executable'),

  //'utm'
  virus_html_name: gettext('Virus Block Page'),
  virus_html_desc: gettext('Replacement HTML for antivirus block page'),
  virus_text_name: gettext('Virus Block Message'),
  virus_text_desc: gettext('Replacement text for antivirus block message'),
  appblk_html_name: gettext('Application Control Block Page'),
  appblk_html_desc: gettext(
    'Replacement HTML for application control block page'
  ),
  dlp_html_name: gettext('DLP Block Page'),
  dlp_html_desc: gettext('Replacement HTML for DLP block page'),
  dlp_text_name: gettext('DLP Block Message'),
  dlp_text_desc: gettext('Replacement text for DLP block message'),
  client_virus_html_name: gettext('DLP Block Message'),
  client_virus_html_desc: gettext('Replacement HTML for Client Virus Page'),
  exe_text_name: gettext('DLP Block Message'),
  exe_text_desc: gettext('Replacement text for executable block message'),
  ipsblk_html_name: gettext('IPS Block Message'),
  ipsblk_html_desc: gettext('Replacement text for IPS block message'),
  waf_html_name: gettext('WAF Block Message'),
  waf_html_desc: gettext('Replacement HTML for WAF block message'),
  one_page_length: 32768,
};
