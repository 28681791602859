import { forEach, get } from 'lodash';

import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { fiWorkspace } from 'fi-workspace';

import { fiDvmChassisPart } from './chassis_constants';

let _chassisMenu = null;
let _chassisMenuMap = {};

// Public interface
export const fiDvmChassisService = {
  getChassis,
  addChassis,
  editChassis,
  deleteChassis,
  updateChassis,

  initMenu,
  getChassisDefaultMenuItem,
  getMenuItemByKey,

  getChassisData,
  getChassisBladeSlot,
  performActionBladeSlots,

  getThresholdData,
  editThresholdData,

  isWorkspaceAvailable,
};

function _send(method, params) {
  let adom = fiAdom.current();
  let req = {
    method: method,
    url: `/gui/adoms/${adom.oid}/dvm/chassis`,
    params,
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      (resp) => {
        resolve(resp[0] ? resp[0].data : []);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function getChassis(deviceId) {
  return _send('get', { deviceOid: deviceId });
}

function addChassis(params) {
  return _send('add', params);
}

function editChassis(params) {
  return _send('edit', params);
}

function deleteChassis(deviceId) {
  return _send('del', { deviceOid: deviceId });
}

function updateChassis(deviceId) {
  return _send('update', { deviceOid: deviceId });
}

function initMenu(data) {
  // Convert data into format for ProLayout.Menu
  const menuData = [];

  for (let i = 0; i < data.length; i++) {
    const chassis = data[i];

    const chassisData = {
      name: chassis.name,
      oid: chassis.oid,
    };

    const chassisChildren = [];
    for (const partName of Object.values(fiDvmChassisPart)) {
      if (partName == fiDvmChassisPart.PEM && !chassis.has_pem) {
        continue;
      }

      const partKey = chassis.oid + '_' + partName;
      const chassisPart = {
        key: partKey,
        name: partKey,
        label: _getChassisPartLabel(partName),
        data: chassisData,
        part: partName,
      };

      chassisChildren.push(chassisPart);
      _chassisMenuMap[partKey] = chassisPart;
    }

    const chassisItem = {
      key: chassis.name,
      name: chassis.name,
      label: chassis.name,
      data: chassisData,
      items: chassisChildren,
      icon: 'total',
    };

    _chassisMenuMap[chassis.name] = chassisItem;

    menuData.push(chassisItem);
  }

  _chassisMenu = [...menuData];

  return _chassisMenu;
}

function getMenuItemByKey(itemKey) {
  return _chassisMenuMap[itemKey] || null;
}

function getChassisDefaultMenuItem(providedKey = null) {
  if (providedKey && _chassisMenuMap[providedKey]) {
    return _chassisMenuMap[providedKey];
  }
  return _getDefaultMenuItem();
}

function _getDefaultMenuItem() {
  return get(_chassisMenu, '0.items.0', null);
}

function getChassisData(deviceId, partName) {
  if (!deviceId) return Promise.resolve([]);

  // Optional data post-processing
  let processResponse = (data) => {
    return data;
  };

  let adom = fiAdom.current();
  let url = '/gui/adoms/' + adom.oid + '/dvm/chassis/' + deviceId;
  switch (partName) {
    case fiDvmChassisPart.BLADES:
      url = url + '/blades';
      break;

    case fiDvmChassisPart.PEM:
      url = url + '/pem';
      processResponse = _processPemData;
      break;

    case fiDvmChassisPart.PWRFEED:
      url = url + '/pwr-feed';
      break;

    case fiDvmChassisPart.FANTRAY:
      url = url + '/fan-tray';
      processResponse = _processFanTrayData;
      break;

    case fiDvmChassisPart.SHELFMGR:
      url = url + '/shelf-manager';
      processResponse = _processShelfManagerData;
      break;

    case fiDvmChassisPart.SAP:
      url = url + '/sap';
      processResponse = _processSapData;
      break;

    default:
      throw Error('Unsupported chassis part type: ' + partName);
  }

  let req = {
    method: 'get',
    url: url,
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      (resp) => {
        resolve(processResponse(resp[0] ? resp[0].data : []));
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function getChassisBladeSlot(deviceId, slotIndex) {
  let adom = fiAdom.current();
  let req = {
    method: 'get',
    url:
      '/gui/adoms/' +
      adom.oid +
      '/dvm/chassis/' +
      deviceId +
      '/blades/slot/' +
      slotIndex,
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      (resp) => {
        resolve(resp[0] ? resp[0].data : []);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function performActionBladeSlots(action, deviceId, slotIndex) {
  let adom = fiAdom.current();
  let req = {
    method: action,
    url: '/gui/adoms/' + adom.oid + '/dvm/chassis/' + deviceId + '/blades',
    params: {
      slotIndex: slotIndex,
    },
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      (resp) => {
        resolve(resp[0] ? resp[0].data : []);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function getThresholdData(deviceId, sensorData) {
  let adom = fiAdom.current();
  let url =
    '/gui/adoms/' + adom.oid + '/dvm/chassis/' + deviceId + '/threshold';
  let params = {
    slotIndex: sensorData.sensorParent.index,
    sensorType: sensorData.sensor_type,
    sensorIndex: sensorData.sensor_index,
  };

  if (sensorData.sensorParent.partName == fiDvmChassisPart.BLADES) {
    params.fromBlades = 1;
  } else if (sensorData.sensorParent.partName == fiDvmChassisPart.SHELFMGR) {
    params.fromShelfMgr = 1;
  } else if (sensorData.sensorParent.partName == fiDvmChassisPart.SAP) {
    params.fromSap = 1;
  }

  let req = {
    method: 'get',
    url: url,
    params: params,
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      (resp) => {
        resolve(resp[0] ? resp[0].data : []);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function editThresholdData(deviceId, sensorData, thresholdParams) {
  let adom = fiAdom.current();
  let url =
    '/gui/adoms/' + adom.oid + '/dvm/chassis/' + deviceId + '/threshold';
  let params = {
    slotIndex: sensorData.sensorParent.index,
    sensorType: sensorData.sensor_type,
    sensorIndex: sensorData.sensor_index,

    unc: thresholdParams.unc,
    uc: thresholdParams.uc,
    unr: thresholdParams.unr,
    lnc: thresholdParams.lnc,
    lc: thresholdParams.lc,
    lnr: thresholdParams.lnr,
  };

  if (sensorData.sensorParent.partName == fiDvmChassisPart.BLADES) {
    params.fromBlades = 1;
  } else if (sensorData.sensorParent.partName == fiDvmChassisPart.SHELFMGR) {
    params.fromShelfMgr = 1;
  } else if (sensorData.sensorParent.partName == fiDvmChassisPart.SAP) {
    params.fromSap = 1;
  }

  let req = {
    method: 'edit',
    url: url,
    params: params,
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      (resp) => {
        resolve(resp[0] ? resp[0].data : []);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// Returns true if not in workspace mode, or user has the adom locked in WS mode
function isWorkspaceAvailable() {
  let adomLock = fiWorkspace.adomInfo();
  return !fiWorkspace.isWorkspaceEnabled() || adomLock.isLockedByMe();
}

// Private functions
function _processPemData(data) {
  return data;
}

// Each fan tray may have multiple fans and take up several rows
// Structure the data so that it will display correctly with the "rowspan"
function _processFanTrayData(data) {
  return data;
}

function _processShelfManagerData(data) {
  let outData = [];
  forEach(data, (shm) => {
    shm.partName = fiDvmChassisPart.SHELFMGR;

    if (shm.volt_sensors && shm.volt_sensors.length > 0) {
      forEach(shm.volt_sensors, (voltSensor) => {
        voltSensor.sensorParent = shm;
      });
    }

    outData.push(shm);
  });

  return outData;
}

function _processSapData(data) {
  let outData = [];
  forEach(data, (sap) => {
    sap.partName = fiDvmChassisPart.SAP;

    if (sap.temp_sensors && sap.temp_sensors.length > 0) {
      forEach(sap.temp_sensors, (tempSensor) => {
        tempSensor.sensorParent = sap;
      });
    }

    outData.push(sap);
  });

  return outData;
}

function _getChassisPartLabel(partName) {
  switch (partName) {
    case fiDvmChassisPart.BLADES:
      return gettext('Blades');
    case fiDvmChassisPart.PEM:
      return gettext('PEM');
    case fiDvmChassisPart.PWRFEED:
      return gettext('Power Feed');
    case fiDvmChassisPart.FANTRAY:
      return gettext('Fan Tray');
    case fiDvmChassisPart.SHELFMGR:
      return gettext('Shelf Manager');
    case fiDvmChassisPart.SAP:
      return gettext('SAP');
    default:
      throw Error('Unsupported chassis part type: ' + partName);
  }
}
