// these widgets will always query using log dev, even under supervisor mode
export const logdevWidgets = [
  'sdwan_bandwidth',
  'sdwan_performance',
  'sdwan_app_bandwidth',
  'sdwan_app',
  'sdwan_rules_intf',
  'sdwan_alert',
  'sdwan_per_dev_intf_per_stats',
  'sdwan_per_app_performance',
  'sdwan_global_app_performance',
  'sdwan_interfaces',
  'sdwan_per_intf_mos',
  'sdwan_health_check',
];
