import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikConsumer } from 'formik';
import { useApiRequest } from 'rh_util_hooks';
import { useWizard } from 'rc_wizard';
import { fiHttpGet, fiFmgHttp } from 'fi-http';
import { fiMessageBox } from 'fi-messagebox';
import { fiForticareRegistrationService } from 'ra_forticare_util';
import { getFDSMErrorMsg } from 'fi-error-code';
import { NwProInputRow, NwProSection, NwProBody, NwProFooter } from 'rc_layout';
import {
  FmkInput,
  FmkPassword,
  FmkErrorSpan,
  FmkSwitch,
  FmkSSelect2,
} from 'rc_form';
import { NwButton } from '@fafm/neowise-core';
import { useCountries, useReseller } from './forticare_hooks';
import { getTabAutoId } from '../tab_utils';
import { UrlService } from 'fi-url';

export const FortiCareSSO = ({
  useEntitlmentFile,
  sysConfig,
  ssoSettings,
  isForticareRegistered,
  licenseData,
}) => {
  const { countries, forticareConnectionFailed } = useCountries({
    isForticareRegistered,
    useEntitlmentFile,
  });
  const { resellers, getResellers } = useReseller();
  const { state: ipAddress } = useApiRequest({
    loader: () => fiHttpGet(UrlService.UTIL.GET_IP_ADDRESS),
    parser: (resp) => resp.ip,
  });
  const { goToNext } = useWizard();

  const formikRef = useRef(undefined);

  const getAutoId = getTabAutoId('forticare');
  const subProps = {
    useEntitlmentFile,
    forticareConnectionFailed,
    countries,
    resellers,
    getResellers,
    sysConfig,
    ssoSettings,
    getAutoId,
  };

  const [initValues, setInitValues] = useState({
    account_id: '',
    password: '',
    country: '',
    reseller_id: '',
    reseller_name: '',
    'forticloud-sso': 1,
    'server-address': ssoSettings['server-address'] || ipAddress,
  });

  function submit(values, { setSubmitting }) {
    const errors = formikRef.current.errors;
    if (Object.keys(errors).length > 0) {
      return Promise.reject();
    }

    submitForticloudRegistration(values)
      .then(() => {
        submitSsoSettings(values);
      })
      .then(() => {
        goToNext();
      })
      .catch((err) => {
        let errorMsg = getFDSMErrorMsg(err);
        fiMessageBox.show(errorMsg, 'danger');
        setSubmitting(false);
        return Promise.reject(errorMsg);
      });
  }

  function submitForticloudRegistration(data) {
    if (isForticareRegistered || useEntitlmentFile) {
      return Promise.resolve();
    }

    if (forticareConnectionFailed) {
      return Promise.reject(gettext('Failed to connect to FortiCare servers.'));
    }

    const { account_id, password, reseller_id, reseller_name } = data;

    return fiForticareRegistrationService
      .registerExistingUser({
        account_id,
        password,
        reseller_id: parseInt(reseller_id),
        reseller_name,
      })
      .then(() => {
        fiMessageBox.show(
          gettext('FortiCare product registration successful.'),
          'success'
        );
      });
  }

  function submitSsoSettings(data) {
    let _originalSettings = ssoSettings;
    let newSettings = {
      ..._originalSettings,
      'forticloud-sso': data['forticloud-sso'],
      'server-address': data['server-address'],
    };
    return fiFmgHttp.query({
      method: 'set',
      params: [
        {
          url: '/cli/global/system/saml',
          data: newSettings,
        },
      ],
    });
  }

  useEffect(() => {
    setInitValues((prev) => ({
      ...prev,
      'server-address': ssoSettings['server-address'] || ipAddress,
    }));
  }, [ipAddress]);

  useEffect(() => {
    if (useEntitlmentFile && licenseData.account) {
      setInitValues((prev) => ({
        ...prev,
        account_id: licenseData.account,
        password: '******',
      }));
    }
  }, [licenseData, useEntitlmentFile]);

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      innerRef={formikRef}
      onSubmit={submit}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <NwProBody>
            <FortiCareRegister {...subProps} />
            <FortiCareSSOSection {...subProps} />
          </NwProBody>
          <NwProFooter>
            <NwButton
              onClick={submitForm}
              disabled={isSubmitting}
              automation-id={getAutoId('next-btn')}
              className={'tw-min-w-32'}
              type={'primary'}
            >
              {gettext('Next') + ' >'}
            </NwButton>
          </NwProFooter>
        </>
      )}
    </Formik>
  );
};

const FortiCareRegister = ({
  sysConfig,
  getAutoId,
  countries,
  resellers,
  getResellers,
  useEntitlmentFile,
}) => {
  return (
    <FormikConsumer>
      {({ setFieldValue }) => (
        <>
          <NwProSection title={gettext('Register with FortiCare')}>
            <NwProInputRow label={gettext('Serial Number')}>
              <span>{sysConfig?.sn}</span>
            </NwProInputRow>

            <NwProInputRow label={gettext('Account ID/Email')}>
              <FmkInput
                name={'account_id'}
                automationId={getAutoId('account_id')}
                type={useEntitlmentFile ? null : 'email'}
                disabled={useEntitlmentFile}
                required={!useEntitlmentFile}
              />

              <FmkErrorSpan name={'account_id'} />
            </NwProInputRow>

            <NwProInputRow label={gettext('Password')}>
              <FmkPassword
                name={'password'}
                automationId={getAutoId('password')}
                required={!useEntitlmentFile}
                disabled={useEntitlmentFile}
              />

              <FmkErrorSpan name={'password'} />
            </NwProInputRow>

            <NwProInputRow label=' '>
              <div className={'tw-flex tw-justify-between'}>
                <a
                  href='https://support.fortinet.com/Login/CreateAccount.aspx'
                  rel='noreferrer'
                  target='_blank'
                >
                  {gettext('Register')}
                </a>
                <a
                  href='https://support.fortinet.com/Credentials/Account/UserPassword.aspx'
                  rel='noreferrer'
                  target='_blank'
                >
                  {gettext('Forgot your password?')}
                </a>
              </div>
            </NwProInputRow>

            <NwProInputRow label={gettext('Country/Region')}>
              <FmkSSelect2
                name={'country'}
                automationId={getAutoId('country')}
                source={countries}
                onChange={(val) => {
                  setFieldValue('reseller_id', '');
                  getResellers(val);
                }}
                disabled={useEntitlmentFile}
              />
            </NwProInputRow>

            <NwProInputRow label={gettext('Reseller')}>
              <FmkSSelect2
                name={'reseller_id'}
                automationId={getAutoId('reseller_id')}
                source={resellers}
                onChange={(val) => {
                  const reseller = resellers.find(({ id }) => id === val);
                  setFieldValue('reseller_name', reseller.text);
                }}
                disabled={useEntitlmentFile}
              />
            </NwProInputRow>
          </NwProSection>
        </>
      )}
    </FormikConsumer>
  );
};

const FortiCareSSOSection = ({ getAutoId }) => {
  return (
    <FormikConsumer>
      {() => (
        <>
          <NwProSection title={gettext('SSO with FortiCloud')}>
            <RowWithIcon
              label={gettext('FortiCloud Single Sign-on')}
              content={gettext(
                'Enable this option to allow access to this device using FortiCloud Single Sign-On. The FortiCloud account must be registered to this device.'
              )}
            >
              <FmkSwitch
                name={'forticloud-sso'}
                automationId={getAutoId('forticloud-sso')}
              />
            </RowWithIcon>
          </NwProSection>
        </>
      )}
    </FormikConsumer>
  );
};

const RowWithIcon = ({ label, content, children }) => {
  return (
    <NwProInputRow label={label} rowProps={{ labelInfoTip: content }}>
      {children}
    </NwProInputRow>
  );
};
