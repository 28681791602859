import { isUndefined } from 'lodash';
import { fiFmgHttp } from 'fi-http';
import { formatBytes } from 'kit-number';
import { fiSysConfig } from 'fi-session';
import { fiMessageBox } from 'fi-messagebox';
import { openConfirmModal } from 'rc_layout';
import { UrlService } from 'fi-url';
import { ProToolkit } from '@fafm/neowise-pro';
import { DvmIpConflict } from '../../modal/DvmIpConflict';
import { dispatch } from 'fistore';
import { go } from 'fistore/routing/slice';

export default {
  [MACROS.NOTICE.NOTICE_TYPE_HA_CONN]: {
    message: ({ params }) => {
      return gettext('HA Cluster: \n%s').printf([
        params
          .map((ha) => gettext('%s is %s').printf([ha.sn, ha.type]))
          .join(', '),
      ]);
    },
    exec: ({ params }) => {
      var data;
      for (var i = 0; i < params.length; i++) {
        if (params[i].type === 'up') {
          data = params[i];
          break;
        }
      }
      if (isUndefined(data)) data = params[0];
      var msg = '';
      if (data.type === 'up') {
        msg = gettext(
          'Former HA Cluster Unit "%s" has been detected.</p><p>Do you wish to accept and resynchronize this unit with the HA cluster?'
        ).printf([data.sn]);
        openConfirmModal({
          title: gettext('HA Operation'),
          content: msg,
        }).then(function () {
          let reqObj = {
            id: 1,
            method: 'setHAMember',
            url: '/gui/sys/ha',
            params: { sn: data.sn, enable: true },
          };

          fiFmgHttp.post(reqObj).then(
            function () {},
            function (err) {
              // called asynchronously if an error occurs
              // or server returns response with an error status.
              let msg = err?.result?.[0]?.status?.message;
              fiMessageBox.show(
                msg ? msg : gettext('HA member update failed.'),
                'danger'
              );
            }
          );
        });
      } else {
        //When HA is down, clicking the alert info will redirect to SS-HA Config
        //msg = gettext('<p>Unable to contact HA Cluster Unit "%s".</p><p>Do you wish to remove this unit from the HA cluster permanently?</p><p>If not removed, the unit will resync when it becomes available.</p>').printf([data.sn]);
        dispatch(go({ to: '/sys/ha' }));
      }
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_IP_CONFLICT]: {
    message: () => {}, //TODO: find out what this message looks like
    exec: ({ params }) => {
      ProToolkit.openModal(<DvmIpConflict arrDevInfo={params} />, {
        height: '80vh',
      });
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_VM_SIZING]: {
    message: () => {
      return gettext('%s VM requires minimum %s Memory and 4x vCPU.').printf(
        fiSysConfig.isFmg()
          ? ['FortiManager', '16GB']
          : ['FortiAnalyzer', '16GB']
      );
    },
    exec: () => {
      window.open(UrlService.HELP.VM_INSTALL);
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_VM_STORAGE_DISK]: {
    message: ({ params: { disk, storage } }) => {
      return gettext(
        'Hard drive allocated for this VM instance (%s) is less than the licensed VM storage volume (%s).'
      ).printf([
        formatBytes(disk * MACROS.USER.SYS.GB_VS_BYTE_NUM),
        formatBytes(storage * MACROS.USER.SYS.GB_VS_BYTE_NUM),
      ]);
    },
    exec: () => {
      window.open(UrlService.HELP.VM_INSTALL);
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_HTTPS_CERT_EXPIRING]: {
    message: ({ params: { days } }) => {
      return days >= 0
        ? gettext(
            'The HTTPS/Web Server Certificate is expiring in %s days.'
          ).printf([days])
        : gettext('The HTTPS/Web Server Certificate has expired.');
    },
    exec: () => {
      dispatch(go({ to: '/sys/certificate' }));
    },
  },
};
