import fiWebSocketAddon from './fiWebSocketAddon';

export default class fiWebSocketLocalAddon extends fiWebSocketAddon {
  async connect() {
    const result = await this.fiWebSocket.send(
      this._genRequest('connect', {
        type: 'local',
        ...this._terminalDimension,
      })
    );

    this.consoleId = result.consoleId;

    return this.disconnect;
  }
}
