const v = {
  pno_obj_fpx_intf_single: {
    label: gettext('Normalized Interface'),
  },
  pno_obj_fpx_fw: {
    label: gettext('Firewall Objects'),
    // // icon: 'policy-objects',
    section: true,
  },
  pno_obj_fpx_address: {
    label: gettext('Addresses'),
  },
  pno_obj_fpx_app_isc: {
    label: gettext('Internet Service'),
  },
  pno_obj_fpx_network_service: {
    label: gettext('Network Service'),
  },
  pno_obj_fpx_service: {
    label: gettext('Services'),
  },
  pno_obj_fpx_schedule: {
    label: gettext('Schedules'),
  },
  pno_obj_fpx_vip: {
    label: gettext('Virtual IPs'),
  },
  pno_obj_fpx_ippool: {
    label: gettext('IP Pools'),
  },
  pno_obj_fpx_trafficshaper: {
    label: gettext('Traffic Shapers'),
  },
  pno_obj_fpx_shapingprofile: {
    label: gettext('Shaping Profile'),
  },
  pno_obj_fpx_virtualserver: {
    label: gettext('Virtual Servers'),
  },
  pno_obj_fpx_healthmonitor: {
    label: gettext('Health Check'),
  },
  pno_obj_fpx_fwdserver: {
    label: gettext('Web Proxy Forwarding Server'),
  },
  pno_obj_fpx_authscheme: {
    label: gettext('Authentication Scheme'),
  },
  pno_obj_fpx_decryptedTrafficMirror: {
    label: gettext('Decrypted Traffic Mirror'),
  },
  'pno_obj_fpx_ztna-server': {
    label: gettext('ZTNA Server'),
  },
  'pno_obj_fpx_ztna-tag': {
    label: gettext('Security Posture Tag'),
  },
  pno_obj_fpx_utmprofile: {
    label: gettext('Security Profiles'),
    // icon: 'security',
    section: true,
  },
  pno_obj_fpx_avprof: {
    label: gettext('AntiVirus'),
  },
  pno_obj_fpx_wafprof: {
    label: gettext('Web Application Firewall'),
  },
  pno_obj_fpx_webprof: {
    label: gettext('Web Filter'),
  },
  pno_obj_fpx_videoprof: {
    label: gettext('Video Filter'),
  },
  pno_obj_fpx_dnsprof: {
    label: gettext('DNS Filter'),
  },
  pno_obj_fpx_appprof: {
    label: gettext('Application Control'),
  },
  pno_obj_fpx_casbprof: {
    label: gettext('Inline CASB'),
  },
  pno_obj_fpx_ipsprof: {
    label: gettext('Intrusion Prevention'),
  },
  pno_obj_fpx_filefilterprofile: {
    label: gettext('File Filter Profile'),
  },
  pno_obj_fpx_emailfilterprof: {
    label: gettext('Email Filter'),
  },
  pno_obj_fpx_voipprof: {
    label: gettext('VoIP'),
  },
  pno_obj_fpx_proxyopt: {
    label: gettext('Protocol Options'),
  },
  pno_obj_fpx_sslprof: {
    label: gettext('SSL/SSH Inspection'),
  },
  pno_obj_fpx_webratingoverride: {
    label: gettext('Web Rating Overrides'),
  },
  pno_obj_fpx_weburlfilter: {
    label: gettext('Web URL Filter'),
  },
  pno_obj_fpx_webcontentfilter: {
    label: gettext('Web Content Filter'),
  },
  pno_obj_fpx_localcategory: {
    label: gettext('Web Filter Local Category'),
  },
  pno_obj_fpx_youtubechannelfilter: {
    label: gettext('Video YouTube Channel Filter'),
  },
  pno_obj_fpx_appsignature: {
    label: gettext('Application Signatures'),
  },
  pno_obj_fpx_virtualpatchsign: {
    label: gettext('Virtual Patching Signatures'),
  },
  pno_obj_fpx_app_grp: {
    label: gettext('Application Group'),
  },
  pno_obj_fpx_ipssign: {
    label: gettext('IPS Signatures'),
  },
  pno_obj_fpx_emailfilterblockallowlist: {
    label: gettext('Email List'),
  },
  pno_obj_fpx_profgrp: {
    label: gettext('Profile Group'),
  },
  pno_obj_fpx_dlpprof: {
    label: gettext('Data Loss Prevention'),
  },
  pno_obj_fpx_dlpfingerprint: {
    label: gettext('DLP File Pattern'),
  },
  pno_obj_fpx_contentanalyses: {
    label: gettext('Content Analyses'),
    // icon: 'fortigate',
    section: true,
  },
  pno_obj_fpx_imageanalysis: {
    label: gettext('Image Analysis'),
  },
  pno_obj_fpx_icapprof: {
    label: gettext('ICAP Profile'),
  },
  pno_obj_fpx_icapremoteserver: {
    label: gettext('ICAP Remote Server'),
  },
  pno_obj_fpx_icaploadbalance: {
    label: gettext('ICAP Load Balancing'),
  },
  pno_obj_fpx_wanopt: {
    label: gettext('WAN Optimize'),
    // icon: 'wifi',
    section: true,
  },
  pno_obj_fpx_wanoptprof: {
    label: gettext('Profile'),
  },
  pno_obj_fpx_peer: {
    label: gettext('Peer'),
  },
  pno_obj_fpx_authgrp: {
    label: gettext('Authentication Group'),
  },
  pno_obj_fpx_user: {
    label: gettext('User & Authentication'),
    // icon: 'administrators',
    section: true,
  },
  pno_obj_fpx_defineduser: {
    label: gettext('User Definition'),
  },
  pno_obj_fpx_usrgrp: {
    label: gettext('User Groups'),
  },
  pno_obj_fpx_usrdev: {
    label: gettext('Custom Devices & Groups'),
  },
  pno_obj_fpx_sso: {
    label: gettext('Endpoint/Identity'),
  },
  pno_obj_fpx_ldapusr: {
    label: gettext('LDAP Servers'),
  },
  pno_obj_fpx_radiususr: {
    label: gettext('RADIUS Servers'),
  },
  pno_obj_fpx_tacacsusr: {
    label: gettext('TACACS+ Servers'),
  },
  pno_obj_fpx_pop3: {
    label: gettext('POP3 Users'),
  },
  pno_obj_fpx_pki: {
    label: gettext('PKI'),
  },
  pno_obj_fpx_sms: {
    label: gettext('SMS Services'),
  },
  pno_obj_fpx_fortitoken: {
    label: gettext('FortiTokens'),
  },
  pno_obj_fpx_connectors: {
    label: gettext('Security Fabric'),
    section: true,
  },
  pno_obj_fpx_securityfabric: {
    label: gettext('Fabric Connectors'),
    // icon: 'security-fabric',
    section: true,
  },
  'pno_obj_fpx_core-network-security': {
    label: gettext('Core Network Security'),
  },
  pno_obj_fpx_others: {
    label: gettext('Other Fortinet Products'),
  },
  pno_obj_fpx_externalfabric: {
    label: gettext('External Connectors'),
    // icon: 'security-fabric',
    section: true,
  },
  'pno_obj_fpx_sdn-public': {
    label: gettext('Public SDN'),
  },
  'pno_obj_fpx_sdn-private': {
    label: gettext('Private SDN'),
  },
  pno_obj_fpx_externalresource: {
    label: gettext('Threat Feeds'),
  },
  pno_obj_fpx_service_now: {
    label: gettext('Service Now'),
  },
  pno_obj_fpx_faz_conn: {
    label: gettext('FAZ Connectors'),
  },
  pno_obj_fpx_extradynobj: {
    label: gettext('Dynamic Object'),
    // icon: 'ha',
    section: true,
  },
  pno_obj_fpx_loccert: {
    label: gettext('Dynamic Local Certificate'),
  },
  pno_obj_fpx_vpntunnel: {
    label: gettext('Dynamic VPN Tunnel'),
  },
  pno_obj_fpx_advanced: {
    label: gettext('Advanced'),
    // icon: 'advanced-settings',
    section: true,
  },
  pno_obj_fpx_replacemsg: {
    label: gettext('Replacement Message Group'),
  },
  pno_obj_fpx_localcert: {
    label: gettext('Local Certificates'),
  },
  pno_obj_fpx_cacert: {
    label: gettext('CA Certificates'),
  },
  pno_obj_fpx_tags: {
    label: gettext('Tag Management'),
  },
  pno_obj_fpx_fmgvariable: {
    label: gettext('Metadata Variables'),
  },
  pno_obj_fpx_cliopt: {
    label: gettext('CLI Configurations'),
    // icon: 'advanced',
  },
};

export default v;
