import { NwSpinner } from '@fafm/neowise-core';
import cn from 'classnames';

export function Spinner({
  className,
  color = 'rgb(var(--nw-color-neutral-0))',
}) {
  return (
    <NwSpinner
      className={cn('tw-text-6xl', className)}
      style={{
        '--indicator-color': color,
      }}
    />
  );
}
