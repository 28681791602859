import goog from '@fafm/goog';

export function toLong(number: number) {
  return goog.math.Long.fromNumber(Number(number));
}

// We use any for goog module related types for now, will change after goog got removed
export function longBitwiseAnd(long1: any, long2: any) {
  long1 = long1 instanceof goog.math.Long ? long1 : toLong(long1);
  long2 = long2 instanceof goog.math.Long ? long2 : toLong(long2);
  return long1.and(long2);
}

export function longBitwiseOr(long1: any, long2: any) {
  long1 = long1 instanceof goog.math.Long ? long1 : toLong(long1);
  long2 = long2 instanceof goog.math.Long ? long2 : toLong(long2);
  return long1.or(long2);
}

export function longToNumber(long: any) {
  return long instanceof goog.math.Long ? long.toNumber() : long;
}
