// import { fiStore, fiStoreSoc } from 'fistore';
import * as utils from '../utils';
const device = {
  data: [],
  flattenData: [],
  allTypes: {},
  sdwan: [],
  updateTime: { allTypes: null, sdwan: null },
};

export const types = {
  UPDATE: 'SOC/DEVICE/UPDATE', // for current customer selected device
  UPDATEALLTYPES: 'SOC/DEVICE/UPDATEALLTYPES', // for whole devcie tree
  UPDATESDWAN: 'SOC/DEVICE/UPDATESDWAN', // for sdwan device tree
};

// actions
const update = (payload) => {
  return { type: types.UPDATE, payload: payload };
};
const updateAllTypes = (payload) => (dispatch) => {
  dispatch({ type: types.UPDATEALLTYPES, payload: payload });
  return Promise.resolve();
};
const updateSdwan = (payload) => (dispatch) => {
  dispatch({ type: types.UPDATESDWAN, payload: payload });
  return Promise.resolve();
};

export const actions = {
  update: update,
  updateAllTypes: updateAllTypes,
  updateSdwan: updateSdwan,
};

export const reducerCase = {
  [types.UPDATE]: (state, payload) => {
    let updatedState = state;

    if (payload) {
      if (payload.data && payload.data.length) {
        updatedState = utils.update(updatedState, 'data', payload.data);
      }
      if (payload.flattenData && payload.flattenData.length) {
        updatedState = utils.update(
          updatedState,
          'flattenData',
          payload.flattenData
        );
      }
    }
    return updatedState;
  },
  [types.UPDATEALLTYPES]: (state, payload) => {
    let updatedState = state;
    if (payload && Object.keys(payload).length) {
      updatedState = utils.update(
        updatedState,
        'updateTime.allTypes',
        new Date()
      );
      updatedState = utils.update(updatedState, 'allTypes', payload);
    }
    return updatedState;
  },
  [types.UPDATESDWAN]: (state, payload) => {
    let updatedState = state;
    let sdwanData = payload.sdwanData;
    let serverTime = payload.serverTime;

    if (sdwanData) {
      updatedState = utils.update(updatedState, 'sdwan', sdwanData);
    }
    if (serverTime && serverTime.startTime && serverTime.endTime) {
      updatedState = utils.update(updatedState, 'updateTime.sdwan', serverTime);
    }
    return updatedState;
  },
};
export const reducer = (state = device, action) => {
  return reducerCase[action.type]
    ? reducerCase[action.type](state, action.payload)
    : state;
};

// selectors
const myState = (state) => state.soc.device;
const get = (state) => {
  return myState(state);
};

export const service = {
  get,
};
