// the purpose of this module is to set up the post-robot in iframes
// this file will be auto-copied to mapsvr/js/common and used in map iframes
import $ from 'jquery';
import postRobot from '@fafm/post-robot';
// combine library of post-robot with natural window postmessage
// postRobot.CONFIG.LOG_LEVEL = 'error';

let PRService = function ({ type, message, callback, targetWindow }) {
  let _getParentDomain = () => {
    let fullDomain = document.referrer.split('//');
    return fullDomain[0] + '//' + fullDomain[1].split('/')[0];
  };

  let _getParentWindow = () => {
    return window.opener; // this shall not be null
  };

  let _getTarget = () => {
    const defaultOptions = {
      timeout: 30e4,
    };
    let target = {};
    if (childType === PRService.FRAME_TYPE.GOOGLE_MAP_FRAME) {
      target = { domain: _getParentDomain() };
    } else if (childType === PRService.FRAME_TYPE.LOCALHOST_FRAME) {
      target = {
        window: _getParentWindow(),
        domain: document.location.origin,
      };
    } else if (targetWindow) {
      target = {
        window: targetWindow.opener,
        domain: document.location.origin,
      };
    }

    return {
      ...defaultOptions,
      ...target,
    };
  };

  // default is for google map iframes
  let childType = type || PRService.FRAME_TYPE.GOOGLE_MAP_FRAME;

  // listeners are the functions which will be called from the parent
  let registeredListeners = {};

  //get the top referrer
  $(document).ready(() => {
    if (message) {
      callFn(message, '').then(callback || (() => {}));
    }
  });

  function detach() {
    for (let fnName in registeredListeners) {
      registeredListeners[fnName].cancel();
    }
  }

  function registerListenerFn(cfgLtnersObj) {
    for (let fnName in cfgLtnersObj) {
      var lisObj = postRobot.on(
        fnName,
        _getTarget(),
        function (event, callback) {
          return cfgLtnersObj[fnName](event.data, callback);
        }
      );
      registeredListeners[fnName] = lisObj;
    }
  }

  function registerOneTimeListenerFn(cfgLtnersObj) {
    for (let fnName in cfgLtnersObj) {
      postRobot.once(fnName, _getTarget(), function (event, callback) {
        return cfgLtnersObj[fnName](event.data, callback);
      });
    }
  }

  function detachListenerFn(fnName) {
    if (registeredListeners[fnName]) {
      registeredListeners[fnName].cancel();
    }
  }

  function callFn(fnName, data) {
    return postRobot.send(
      window.opener || window.parent,
      fnName,
      data,
      _getTarget()
    );
  }

  function getParentHref() {
    return _getParentDomain();
  }

  return {
    callFn: callFn,
    registerListenerFn: registerListenerFn,
    registerOneTimeListenerFn: registerOneTimeListenerFn,
    detachListenerFn: detachListenerFn,
    getParentHref: getParentHref,
    detach: detach,
  };
};

PRService.FRAME_TYPE = {
  GOOGLE_MAP_FRAME: 'gmap',
  LOCALHOST_FRAME: 'local',
};

export default PRService;
