import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DefaultLoginForm } from './widgets/DefaultLoginForm';
import { LoginPageBase } from './LoginPageBase';
import { FabricLogo } from 'fabric_approval/layout';
import { fromPairs } from 'lodash';

export function FabricApprovalLogin({ route }) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const onLoginSuccess = useCallback(() => {
    const pairs = Array.from(params);
    navigate(route, {
      state: { next: params.get('next'), ...fromPairs(pairs) },
    });
  }, [navigate]);

  const renderLoginForm = useCallback(
    ({ loginEnv }) => {
      return (
        <DefaultLoginForm onLoginSuccess={onLoginSuccess} loginEnv={loginEnv} />
      );
    },
    [onLoginSuccess]
  );

  return (
    <LoginPageBase
      renderLoginForm={renderLoginForm}
      header={<FabricLogo className='tw-mb-4' />}
    />
  );
}
