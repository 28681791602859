import React from 'react';
import { fiSysConfig } from 'fi-session';
const { hasFmgFeature, attr } = fiSysConfig;

const { HA_FAZ_MODE_A_P, HA_FAZ_MODE_A_A } = MACROS.FAZ_SYS;
const FAZ_CFG_MODE_TXT_MAP = {
  [HA_FAZ_MODE_A_P]: ' A-P',
  [HA_FAZ_MODE_A_A]: ' A-A',
};
const haState = () => {
  let cfgModeTxt = '';
  const haCfgMode = attr('ha_cfg_mode'); // faz only: a-p/a-a
  if (haCfgMode) {
    cfgModeTxt = FAZ_CFG_MODE_TXT_MAP[haCfgMode];
  }
  const haMode = attr('ha_mode'); // for faz, this is preferred-role value
  return haMode === MACROS.SYS.HA_MODE_SLAVE
    ? gettext('HA Secondary') + cfgModeTxt
    : haMode === MACROS.SYS.HA_MODE_MASTER
    ? gettext('HA Primary') + cfgModeTxt
    : '';
};

const offlineState = () => {
  return hasFmgFeature() && attr('offline_mode') ? gettext('Offline Mode') : ''; // fmg only!
};

const fazState = () => {
  const fazMode = attr('faz_mode');

  return MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FAZ &&
    fazMode === MACROS.SYS.FAZCORE_SYS_MODE_COLLECTOR
    ? gettext('Collector Mode')
    : '';
};

export const SystemMode = () => {
  const modeText = [haState, fazState, offlineState]
    .map((f) => f())
    .filter(Boolean)
    .join(' ');
  return (
    modeText && (
      <div className='tw-text-warning-600 tw-bg-warning-50 tw-p-1 tw-rounded-sm tw-whitespace-nowrap'>
        {modeText}
      </div>
    )
  );
};
