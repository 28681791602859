import { omit } from 'lodash';

export const make_fetch_reducer = (actionType) => (state, action) => {
  const fetch = actionType.fetch;
  if (!fetch) return state;

  switch (action.type) {
    case fetch.REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case fetch.FAILURE:
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    case fetch.SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        list: action.payload,
      };
  }
  return state;
};

const findInsertPos = (compare) => (low, high) => {
  const binary = (low, high) => {
    if (low <= high) {
      if (compare(high) == 1) return high + 1; //{"type":"after","pos":high+1}
      if (compare(low) == -1) return low; //{"type":"before","pos":low-}

      var mid = Math.ceil((high + low) / 2);
      if (compare(mid) == -1) {
        return binary(low + 1, mid - 1);
      } else {
        return binary(mid + 1, high - 1);
      }
    } else {
      return low;
    }
  };

  return binary(low, high);
};

export const make_record_reducer = (actionType) => (state, action) => {
  const record = actionType.record;
  if (!record) return state;

  const add = () => {
    const { oid, name } = action.payload;
    const { byId, allIds } = state.list;

    const pos = findInsertPos((pos) =>
      name > byId[allIds[pos]].name ? 1 : -1
    )(0, allIds.length - 1);

    return {
      ...state,
      list: {
        byId: {
          ...byId,
          [oid]: action.payload,
        },
        allIds: [...allIds.slice(0, pos), oid.toString(), ...allIds.slice(pos)],
      },
    };
  };

  const change = () => {
    const { oid } = action.payload;
    return {
      ...state,
      list: {
        ...state.list,
        byId: {
          ...state.list.byId,
          [oid]: action.payload,
        },
      },
    };
  };
  const remove = () => {
    const { oid } = action.payload;
    return {
      ...state,
      list: {
        ...state.list,
        byId: omit(state.list.byId, oid.toString()),
        allIds: state.list.allIds.filter(
          (x) => x.toString() !== oid.toString()
        ),
      },
    };
  };

  switch (action.type) {
    case record.ADD: {
      return add();
    }
    case record.CHANGE: {
      return change();
    }
    case record.DELETE: {
      return remove();
    }
  }

  return state;
};
export const make_reducer = (actionType) => (state, action) =>
  [make_fetch_reducer, make_record_reducer]
    .map((x) => x(actionType))
    .reduce((acc, cur) => cur(acc, action), state);

export const initialState = {
  list: {
    allIds: [],
    byId: {},
  },
  loaded: false,
  loading: false,
};
