import { ProToolkit } from '@fafm/neowise-pro';
import { DisclaimerMessage } from './DisclaimerMessage';
import { openConfirmModal } from 'react_components/rc_layout/ConfirmModal';
import { NwIcon } from '@fafm/neowise/react';

export async function showAlertPanel(msg, buttons = ['ok']) {
  return openConfirmModal(
    {
      title: gettext('Attention'),
      content: () => (
        <div className='tw-flex tw-flex-row tw-gap-x-3'>
          <div>
            <NwIcon
              library='fa-solid'
              name='exclamation-circle'
              className='tw-text-8xl'
              style={{ color: 'rgb(var(--nw-color-msg-yellow))' }}
            />
          </div>
          <div>{msg}</div>
        </div>
      ),
      buttons,
    },
    {
      maximizable: false,
      closable: false,
      size: 'md',
      height: 'fit-content',
      className: 'np-toolkit-modal [&::part(overlay)]:tw-bg-primary',
    }
  );
}

export function showDisclaimer(props) {
  return ProToolkit.openModal(<DisclaimerMessage {...props} />, {
    maximizable: false,
    closable: false,
    size: 'md',
    height: 'fit-content',
    className: 'np-toolkit-modal [&::part(overlay)]:tw-bg-primary',
  }).result;
}
