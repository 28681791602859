import { dashApis } from './dashApis';

const checkDashDisabled = ({ disabledViews, apis }) => {
  let reasons = [];
  let isDisabled = apis.length
    ? apis.every((api) => {
        if (disabledViews[api]) {
          reasons = reasons.concat(
            disabledViews[api].map((logtype) => logtype.text || logtype.name)
          );
          return true;
        }
        return false;
      })
    : false;
  let reason = isDisabled ? Array.from(new Set(reasons)).join(', ') : '';
  return {
    isDisabled,
    reason,
  };
};

const objRecursiveSome = (obj, property, fn) => {
  if (Array.isArray(obj[property])) {
    if (obj[property].some(fn)) {
      return true;
    } else {
      return obj[property].some((item) => objRecursiveSome(item, property, fn));
    }
  } else {
    return false;
  }
};

const getMissingLogTypes = ({ view, availableLogs, is_fabric }) => {
  let missingLogTypes = [];
  let required = view?.logs.required || [];
  let devTypeId = required[0]?.['devtype']?.['id'] || 0;
  let logTypes = required[0]?.logtypes || [];
  logTypes.forEach((logType) => {
    if (logType.name.indexOf('fct-') === 0) {
      return;
    }
    const availabeLogs = is_fabric
      ? availableLogs.find((devType) => devType.id == devTypeId)
      : { children: availableLogs };
    if (
      !availabeLogs ||
      !objRecursiveSome(
        availabeLogs,
        'children',
        (log) => log.id === logType.id
      )
    ) {
      missingLogTypes.push(logType);
    }
  });
  return missingLogTypes;
};

export const getDisabledViews = ({
  viewnameLogtypes,
  availableLogs,
  is_fabric,
}) => {
  const disabledViews = Object.keys(viewnameLogtypes).reduce((res, viewKey) => {
    let missingLogTypes = getMissingLogTypes({
      view: viewnameLogtypes[viewKey],
      availableLogs,
      is_fabric,
    });
    if (missingLogTypes.length) {
      res[viewKey] = missingLogTypes;
    }
    return res;
  }, {});
  return disabledViews;
};

export const getDisabledDashes = ({ disabledViews }) => {
  const disabledDashes = {
    ftv: {},
    noc: {},
  };

  for (const [dashId, apis] of Object.entries(dashApis.ftv)) {
    let { isDisabled, reason } = checkDashDisabled({
      apis,
      disabledViews,
    });
    if (isDisabled) {
      disabledDashes.ftv[dashId] = reason;
    }
  }
  for (const [dashId, apis] of Object.entries(dashApis.noc)) {
    let { isDisabled, reason } = checkDashDisabled({
      apis,
      disabledViews,
    });
    if (isDisabled) {
      disabledDashes.noc[dashId] = reason;
    }
  }

  return disabledDashes;
};
