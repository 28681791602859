import { fiFmgHttp } from 'fi-http';
import { isObject } from 'lodash';

export {
  getPackageList,
  getPolicyBlockList,
  getPackage,
  genQueryURL as genPPkgQueryUrl,
};

export interface Pkg_backend {
  name: string;
  oid: number;
  type: 'pkg' | 'folder' | 'pblock';
  'obj ver'?: number;
  'package settings'?: {
    'central-nat': number;
    'consolidated-firewall-mode': number;
    'fwpolicy-implicit-log': number;
    'fwpolicy6-implicit-log': number;
    'hitc-taskid': number;
    'hitc-timestamp': number;
    'ngfw-mode': number;
    'policy-offload-level': number;
  };
  subobj?: Pkg_backend[];
}

type Param_getPackageList = {
  adom: AdomData;
  option?: string[];
  sortings?: any[];
};
/**
 * Get Policy Package list
 */
function getPackageList(
  param: Param_getPackageList | Param_getPackageList[]
): Promise<any> {
  const DEF_PARAM = {
    adom: {},
    option: ['schedule'],
    sortings: [{ name: 1 }],
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    return {
      url: genQueryURL({
        adom: prm.adom,
      }),
      option: prm.option,
      sortings: prm.sortings,
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

type Param_getPolicyBlockList = {
  adom: AdomData;
};
function getPolicyBlockList(
  param: Param_getPolicyBlockList | Param_getPolicyBlockList[]
): Promise<any> {
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    return {
      url: genQueryURL({
        adom: pm.adom,
        isPblock: true,
      }),
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

type Param_getPackage = {
  adom: AdomData;
  path: string;
  isPblock?: boolean;
};
function getPackage(
  param: Param_getPackage | Param_getPackage[]
): Promise<any> {
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    return {
      url: genQueryURL({
        adom: pm.adom,
        pkg: pm.path,
        isPblock: pm.isPblock,
      }),
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

type PkgQueryParam = {
  adom: AdomData;
  pkg?:
    | string
    | {
        name: string;
        path: string;
        pblock?: boolean;
      };
  isPblock?: boolean;
};
function genQueryURL(param: PkgQueryParam): string {
  const pm: PkgQueryParam = { ...param };
  if (!Object.hasOwnProperty.call(param, 'isPblock')) {
    pm.isPblock = isObject(param.pkg) ? param.pkg.pblock : false;
  }
  let url = 'pm/';
  if (pm.isPblock) {
    url += 'pblock';
  } else {
    url += 'pkg';
  }
  url += pm.adom.globaldb ? '/global' : `/adom/${pm.adom.name}`;
  if (pm.pkg) {
    let path;
    if (typeof pm.pkg === 'string') {
      path = pm.pkg;
    } else {
      path = pm.pkg.path || pm.pkg.name;
    }
    if (pm.isPblock) {
      path = getPBlockPath(path);
    }
    url += `/${path}`;
  }
  return url;
}

/**
 * Get the original policy block path.
 * Path of a Policy Block is modified by GUI side, as needed by ppkg tree,
 * "Policy Blocks/" is prepended to the path to generate the tree structure.
 * What this function does is to revert whatever modified the original path.
 * @param {String} path - path of a pblock
 */
function getPBlockPath(path: string): string {
  // For Policy Block's path, it is 'Policy Blocks/pb name' and 'Policy Blocks' is a fake, mock-up folder path, which needs to be removed.
  const matched = path.match(/Policy Blocks\/(.*)/);
  return matched ? matched[1] : path;
}
