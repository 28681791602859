import { isNumber } from 'lodash';
import { callIfMeets, Disposable } from './util';

export default class fiWebSocketAddon extends Disposable {
  constructor({ fiWebSocket, consoleId, initialCommands } = {}) {
    super();

    this.fiWebSocket = fiWebSocket;
    this.consoleId = consoleId ?? CONSOLE_ID.DISCONNECTED;
    this.initialCommands = initialCommands;

    this.isConnected = this.isConnected.bind(this);
    this.isDisconnected = this.isDisconnected.bind(this);
    this.getConsoleId = this.getConsoleId.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.sendData = this.sendData.bind(this);
    this.sendCommand = this.sendCommand.bind(this);

    this._genRequest = this._genRequest.bind(this);
    this._showDisconnect = this._showDisconnect.bind(this);
    this._subscribeToNotify = this._subscribeToNotify.bind(this);
    this._subscribeToResult = this._subscribeToResult.bind(this);
    this.activate = this.activate.bind(this);
    this.updateTerminalDimension = this.updateTerminalDimension.bind(this);
  }

  isConnected() {
    return isNumber(this.consoleId) && this.consoleId > CONSOLE_ID.INITIAL;
  }

  isDisconnected() {
    return !this.isConnected();
  }

  getConsoleId() {
    return this.consoleId;
  }

  async connect() {
    throw 'not implement';
  }

  async disconnect() {
    if (this.isDisconnected()) return;

    await this.fiWebSocket.send(this._genRequest('disconnect'));
  }

  async sendData(content) {
    if (this.isDisconnected()) return;

    return this.fiWebSocket.send(this._genRequest('xmit', { content }));
  }

  sendCommand(command) {
    return this.sendData(command + '\n');
  }

  _genRequest(action, params) {
    return {
      msg: 'method',
      method: 'console',
      params: {
        action,
        consoleId: this.consoleId,
        ...params,
      },
    };
  }

  _showDisconnect(message) {
    this.consoleId = CONSOLE_ID.DISCONNECTED;

    if (this.xterm) {
      this.xterm.write('\r\n');
      message && this.xterm.writeln(message);
      this.xterm.write('Disconnected. Press Enter to start a new session.');
      this.xterm.write('\r\n\r\n');
    }
  }

  async _subscribeToNotify() {
    function onNotify(data) {
      const { type, content } = data.fields;

      switch (type) {
        case FIELDS_TYPE.CONTENT:
          this.xterm.write(content);
          return;
        case FIELDS_TYPE.DISCONNECTED:
          this._showDisconnect();
          return;
      }
    }

    return this.fiWebSocket.addListener(
      'notify',
      callIfMeets(
        this.isConnected,
        (data) => data?.fields?.consoleId === this.consoleId
      )(onNotify.bind(this))
    );
  }

  async _subscribeToResult() {
    function onResult(data) {
      const { code, message } = data.status;
      switch (code) {
        case 0:
          // regular response
          return;
        // case -1: // disconnect
        default:
          this._showDisconnect(message || data.result.content);
          return;
      }
    }

    return this.fiWebSocket.addListener(
      'result',
      callIfMeets(
        this.isConnected,
        (data) => data?.result?.consoleId === this.consoleId
      )(onResult.bind(this))
    );
  }

  _setupKeyHandlers() {
    return this.xterm.onKey(({ domEvent: { key } }) => {
      if (key === 'Enter' && this.isDisconnected()) {
        this.connect();
      }
    });
  }

  async _sendInitialCommands() {
    if (!(Array.isArray(this.initialCommands) && this.initialCommands.length))
      return;

    const commands = this.initialCommands.slice();
    while (commands.length) {
      await this.sendCommand(commands.shift());
    }
  }

  activate(xterm) {
    this.xterm = xterm;
    this.addDisposable(() => (this.xterm = null));
  }

  updateTerminalDimension(dimension) {
    this._terminalDimension = dimension;
    if (this.isDisconnected()) return;

    return this.fiWebSocket.send(
      this._genRequest('resize', this._terminalDimension)
    );
  }

  // xterm xterm-helper-textarea element lineHeight is calculated when cursor positon change (buffer size change)
  // if lineHeight is 0, xterm paste does not work unless user types something
  // so we want to call this function when xterm is open and everything is ready, then lineHeight is calculated correctly
  async afterXtermReady(dimension) {
    if (this.disposed) return;
    this.addDisposable(await this._subscribeToNotify());
    this.addDisposable(await this._subscribeToResult());
    this.updateTerminalDimension(dimension);
    this.addDisposable(await this.connect());
    this.xterm.writeln(MESSAGE_TEXT.CONNECTED);

    this._sendInitialCommands.call(this);

    this.addDisposable(this.xterm.onData((data) => this.sendData(data)));
    this.addDisposable(this._setupKeyHandlers());
  }
}

const CONSOLE_ID = {
  INITIAL: 0,
  DISCONNECTED: -1,
};

const FIELDS_TYPE = {
  CONTENT: 'content',
  DISCONNECTED: 'disconnected',
};

const MESSAGE_TEXT = {
  CONNECTED: 'Connected',
  // DISCONNECTED: 'Connection lost.',
  // HEARTBEAT: '--heartbeat--',
  // NEEDCONNECT: 'Need connect to console.'
};
