import React /*, { useState, useEffect, useCallback }*/ from 'react';
import { /*ProToolkit, */ ProForm } from '@fafm/neowise-pro';
import { NwButton } from '@fafm/neowise-core';

const { Header, Body, Footer, Row } = ProForm;

const LogSustainRate = ({ $opener, sustain }) => {
  return (
    <>
      <Header>{gettext('SQL Database warning')}</Header>
      <Body>
        <Row>
          <div>
            <span>
              {gettext(
                '%s logs were omitted in the last minute from database insert.'
              ).printf([sustain.dbLastMinuteDropped])}
            </span>
          </div>
        </Row>
        <Row>
          <div>
            <span>
              {gettext(
                'Please review log requirements and tune log policies on your FortiGate devices to reduce log volume.'
              )}
            </span>
          </div>
        </Row>
        <Row>
          <div>
            <span>{gettext('Or, upgrade to a larger appliance model.')}</span>
          </div>
        </Row>
      </Body>
      <Footer>
        <NwButton
          onClick={() => {
            $opener.resolve();
          }}
        >
          {gettext('Cancel')}
        </NwButton>
      </Footer>
    </>
  );
};

export default LogSustainRate;
