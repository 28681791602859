import { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import cn from 'classnames';
import { isObject } from 'lodash';
import { ProToolkit } from '@fafm/neowise-pro';

import { useSplitRecords } from './multi_record_cell_hooks';
import { MoreRecordsTooltip } from './MoreRecordsToolTip';
import { Provider } from 'react-redux';
import { fiStore } from 'fistore';

export const DEFAULT_MAX_ROW_NUM = 5;

export const MultiRecordCell = ProToolkit.withErrorBoundary((props) => {
  let {
    dataSource,
    splitData,
    textKey = 'name',
    className = '',
    maxRowNum = DEFAULT_MAX_ROW_NUM,
    filterDataSource = defaultParser,
    splitRecords = defaultSplitRecords,
    cellEntryFormatFn,
    // for text highlight
    highlighter,
    searchText,
    getAutoId,
    // for tooltip
    toolTipClassName,
    toolTipStyle = {},
    entryParser = defaultParser,
    popoverEntryFormatter,
    popoverSearchFn,
  } = props;

  cellEntryFormatFn = cellEntryFormatFn || defaultEntryFormatFn;

  /** ----------------------------------------------------------------------------
   * Hooks
   * -------------------------------------------------------------------------- */
  const { originalRecords, displayRecords, popoverRecords } = useSplitRecords({
    dataSource,
    filterDataSource,
    splitFn: splitRecords(maxRowNum),
    splitData,
    entryParser,
  });

  const canShowToolTip = useMemo(() => {
    return popoverRecords.length > 0 && originalRecords.length > maxRowNum;
  }, [popoverRecords.length, originalRecords.length, maxRowNum]);

  function defaultEntryFormatFn(highlighter) {
    return (entryData) => {
      const text = isObject(entryData) ? entryData[textKey] : entryData;
      return <div key={text}>{highlighter(text)}</div>;
    };
  }

  // Reuse the cellEntryFormatFn by default.
  // Note: If the cellEntryFormatFn uses any hooks inside, they wont be run when converting to HTML by ReactDOMServer.renderToString.
  // In this case, you need to provide a custom popoverEntryFormatter function to format the popover entry data
  const defaultPopoverEntryFormatter = (highlighter) => (entryData) => {
    const Component = cellEntryFormatFn(highlighter)(entryData);
    return ReactDOMServer.renderToString(Component);
  };

  return (
    <Provider store={fiStore}>
      <div className={cn('multi-record-cell-container', className)}>
        {displayRecords.map((item) => {
          return cellEntryFormatFn(highlighter)(item);
        })}

        {canShowToolTip && (
          <MoreRecordsTooltip
            data={popoverRecords}
            popoverEntryFormatter={
              popoverEntryFormatter || defaultPopoverEntryFormatter
            }
            maxRowNum={maxRowNum}
            className={toolTipClassName}
            style={toolTipStyle}
            textKey={textKey}
            searchText={searchText}
            getAutoId={getAutoId}
            highlighter={highlighter}
            popoverSearchFn={popoverSearchFn}
          />
        )}
      </div>
    </Provider>
  );
});

/** ----------------------------------------------------------------------------
 * Helper functions
 * -------------------------------------------------------------------------- */
function defaultParser(data) {
  return data;
}

const defaultSplitRecords = (maxRowNum) => (records) => {
  return {
    displayRecords: records.slice(0, maxRowNum),
    popoverRecords: records.slice(maxRowNum),
  };
};

export const popoverEntryFormatterHelper = (children) => {
  const compt = <div className='tw-truncate'>{children}</div>;
  return ReactDOMServer.renderToString(compt);
};
