/*****************************************
 * Column sorting for all dvm tables...
 *****************************************/
import { deviceStatus } from 'fi-dvm';
import { ApSignalStrength } from 'fi-fap';
import { isFunction, isUndefined } from 'lodash';

let _compValues = function (attr1, attr2) {
  if (attr1 < attr2) return 1;
  else if (attr1 > attr2) return -1;
  return 0;
};

let groupIndex = [];
let devIndex = [];
let isReverse = false;

let getGroupIndex = function (grpname) {
  return groupIndex.indexOf(grpname) + 1;
};

let getParentDevIndex = function (parentname) {
  return devIndex.indexOf(parentname) + 1;
};

let isVdom = function (rec) {
  return !rec.isGroup && deviceStatus.isVdom(rec.rtype);
};
let isDevice = function (rec) {
  return !rec.isGroup && deviceStatus.isDevice(rec.rtype);
};

let groupCompare = function (rec1, rec2) {
  if (rec1.groupName !== rec2.groupName) {
    return -_compValues(
      getGroupIndex(rec1.groupName),
      getGroupIndex(rec2.groupName)
    );
  } else if (rec1.isGroup && !rec2.isGroup) {
    return -1; //-1 means at top
  } else if (!rec1.isGroup && rec2.isGroup) {
    return 1; //1 means at bottom...
  } else {
    return 0;
  }
};

let deviceCompare = function (rec1, rec2) {
  if (rec1.parentDevName !== rec2.parentDevName) {
    return -_compValues(
      getParentDevIndex(rec1.parentDevName),
      getParentDevIndex(rec2.parentDevName)
    );
  } else if (isVdom(rec1) && !isVdom(rec2)) {
    return 1; //-1 means at top
  } else if (!isVdom(rec1) && isVdom(rec2)) {
    return -1; //1 means at bottom...
  } else {
    return 0;
  }
};

let getCellValue = function (d, col) {
  let cell = d[col];
  if (col === 'signal_strength_bar') {
    let v_ = ApSignalStrength.getValue(d[col].txt);
    return v_.per;
  }
  if (isFunction(cell.cmp)) {
    return cell.cmp();
  } else if (!isUndefined(cell.txt)) {
    return cell.txt;
  } else if (!isUndefined(cell.css)) {
    return cell.css;
  }
  return cell;
};

let commonSort = function (col, reverse) {
  return function (aa, bb) {
    let groupCompareResult = groupCompare(aa, bb, reverse);
    if (groupCompareResult !== 0) {
      return groupCompareResult;
    }

    let deviceCompareResult = deviceCompare(aa, bb, reverse);
    if (deviceCompareResult !== 0) {
      return deviceCompareResult;
    }

    let aa_val = getCellValue(aa, col);
    let bb_val = getCellValue(bb, col);
    let valueCompareResult = _compValues(aa_val, bb_val);

    return reverse ? valueCompareResult : -valueCompareResult;
  };
};

let sortFns = {
  name: function (reverse) {
    return commonSort('name', reverse);
  },
};

let sortFunction = function (a, b) {
  if (a === b) {
    return 0;
  }
  if (isReverse) {
    if (a > b) return -1;
    if (a < b) return 1;
  } else {
    if (a > b) return 1;
    if (a < b) return -1;
  }
};

// sortGroup is used in case records having groups, sortDevice is used in case
// records are devices and having vdoms in device.
export function fiDvmTableSort(records, field, reverse, sortGroup, sortDevice) {
  if (!field) return records;
  isReverse = reverse;
  let sortFn = null;

  if (!isUndefined(sortFns[field])) {
    sortFn = sortFns[field](reverse);
  } else {
    sortFn = commonSort(field, reverse);
  }
  groupIndex = [];
  for (let i = 0; i < records.length; i++) {
    if (records[i].isGroup) {
      groupIndex.push(records[i].groupName);
    }
  }
  devIndex = [];
  for (let j = 0; j < records.length; j++) {
    // find out devices
    if (isDevice(records[j])) {
      devIndex.push(records[j].parentDevName);
    }
  }

  if (sortGroup) {
    groupIndex.sort(sortFunction);
  }

  if (sortDevice) {
    devIndex.sort(sortFunction);
  }

  let results = [].concat(records);
  results.sort(sortFn);
  return results;
}
