import { fiSession, fmgRemoteFaz } from 'fistore';
import { negate, overSome } from 'lodash';
const { isSoarDisabled, isSiemDisabled } = fmgRemoteFaz;
const {
  inGlobalAdom,
  hasRDPermitOn,
  hasFazFeature,
  isFaz,
  getIsFazSupervisor,
  hasRemoteFAZ,
  inOthersAdom,
  inFazCollectorMode,
} = fiSession;
import { getIsFabricAdom } from 'fistore/session/adom/selectors';

export default {
  // --------------------------------------------------------------------------

  fortisoc: {
    key: 'fortisoc',
    label: gettext('Incidents & Events'),
    icon: 'fortisoc',
    color: '#38bb9c',
    state: 'adom.default.fortisoc',
    reloadState: 'adom.default.fortisoc',
    showOnlyWhenEnabled: 'soar',
    gdprTool: true,
    showCheckers: [
      overSome([hasFazFeature, hasRemoteFAZ]),
      negate(inFazCollectorMode),
      negate(inOthersAdom),
      negate(inGlobalAdom),
      negate(getIsFazSupervisor),
      hasRDPermitOn('event_management'),
    ],
  },

  fortisoc_dashboards: {
    // now moved into FortiView - Monitors. See app-tree.jsonc.
    key: 'fortisoc_dashboards',
    label: gettext('Incidents & Events'),
    state: 'adom.default.fortisoc.soar.dash',
    showCheckers: [
      isFaz,
      negate(getIsFazSupervisor),
      hasRDPermitOn('event_management'),
    ],
  },
  fortisoc_dashboard_playbooks: {
    path: '/fortisoc/automation/playbooks',
    // icon: 'playbooks',
    label: gettext('Summary'),
    state: 'adom.default.fortisoc.soar.dash.playbooks',
  },
  fortisoc_dashboard_incidents: {
    icon: 'incidents',
    label: gettext('Incidents'),
    state: 'adom.default.fortisoc.soar.dash.incidents',
  },
  fortisoc_dashboard_events: {
    icon: 'event',
    label: gettext('Events'),
    state: 'adom.default.fortisoc.soar.dash.events',
  },
  fortisoc_outbreak_alerts: {
    path: '/fortisoc/soar/outbreakalerts',
    label: gettext('Outbreak Alerts'),
    // state: 'adom.default.fortisoc.soar.outbreakalerts',
  },
  fortisoc_automation: {
    // now moved into "Fabric View". See app-tree.jsonc.
    path: '/fortisoc/automation',
    label: gettext('Automation'),
    state: 'adom.default.fortisoc.soar.automation',
    showCheckers: [
      isFaz,
      negate(getIsFazSupervisor),
      hasRDPermitOn('event_management'),
      negate(isSoarDisabled),
    ],
  },
  fortisoc_automation_connector: {
    path: '/fortisoc/automation/connector',
    // icon: 'connectors',
    label: gettext('Active Connectors'),
    state: 'adom.default.fortisoc.soar.automation.connector',
  },
  fortisoc_automation_playbook: {
    path: '/fortisoc/automation/playbook',
    // icon: 'playbooks',
    label: gettext('Playbook'),
    state: 'adom.default.fortisoc.soar.automation.playbook',
  },
  fortisoc_automation_playbook_monitor: {
    path: '/fortisoc/automation/playbook-monitor',
    // icon: 'monitor',
    label: gettext('Playbook Monitor'),
    state: 'adom.default.fortisoc.soar.automation.playbook-monitor',
  },
  fortisoc_automation_playbook_editor: {
    path: '/fortisoc/automation/playbook-editor',
    label: gettext('Playbook Canvas'),
    state: 'adom.default.fortisoc.soar.automation.playbook.editor',
  },
  event_monitor: {
    noStateCache: true,
    path: '/alert/eventmonitor',

    label: gettext('Event Monitor'),
    state: 'adom.default.alert.eventmonitor',
    reloadState: 'adom.default.alert.eventmonitor',

    olhTag: 'alert',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Event Monitor.',
  },
  alert_event_all: {
    // note: this WILL be replaced by alert/slice.js when update is fulfilled.
    // because insertAppTree is called to load dynamic views and that function
    // requires full data of the modified tree branch.
    // Any change made here needs to be also added to alert/slice.
    olhTag: 'alert_all',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Event Monitor -> All Events.',

    path: '/alert/eventmonitor/all',
    icon: 'event',
    label: gettext('All Events'),
    noStateCache: true,
  },
  alert_event_settings: {
    // note: this WILL be replaced by alert/slice.js when update is fulfilled.
    // Any change made here needs to be also added to alert/slice.
    path: '/alert/eventmonitor/settings',
    icon: 'settings',
    label: gettext('Toggle Views'),

    olhTag: 'alert_settings',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Event Monitor -> Toggle Views.',
  },
  event_handlers: {
    path: '/alert/handlers',
    label: gettext('Event Handlers'),
    state: 'adom.default.alert.handler',

    olhTag: 'alert_triggers',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Handlers.',
  },
  alert_basic_handlers: {
    path: '/alert/handlers/bh',
    icon: 'event-trigger',
    label: gettext('Event Handlers'),

    olhTag: 'alert_basic_triggers',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Handlers -> Basic Handlers.',
  },
  alert_correlation_handlers: {
    path: '/alert/handlers/ch',
    icon: 'correlation-rules',
    label: gettext('Correlation Handlers'),
    state: 'adom.default.alert.handler.correlation-handlers',
    reloadState: 'adom.default.alert.handler.correlation-handlers',

    olhTag: 'alert_correlation_triggers',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Handlers -> Correlation Handlers.',
  },
  alert_notification_profiles: {
    path: '/alert/handlers/np',
    icon: 'output-profile',
    label: gettext('Notification Profiles'),
    state: 'adom.default.alert.handler.notification-profiles',
    reloadState: 'adom.default.alert.handler.notification-profiles',

    olhTag: 'alert_notification_profile',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Handlers -> Notification Profiles.',
  },
  alert_data_selectors: {
    path: '/alert/handlers/ds',
    icon: 'dataset',
    label: gettext('Data Selectors'),
    state: 'adom.default.alert.handler.data-selectors',
    reloadState: 'adom.default.alert.handler.data-selectors',

    olhTag: 'alert_data_selector',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Handlers -> Data Selectors.',
  },
  fortisoc_threat_hunting: {
    path: 'alert/siem',
    label: gettext('Threat Hunting'),
    state: 'adom.default.alert.incident.siem',
    showCheckers: [hasRDPermitOn('log_viewer'), negate(isSiemDisabled)],

    olhTag: 'threat_hunting',
    olhCategory: 'logview',
    olhFaz: 'Log View -> Threat Hunting.',
  },
  'mitre-enterprise': {
    label: 'MITRE ATT&CK®', // Do NOT use gettext. DO NOT remove "MITRE" -- see https://attack.mitre.org/resources/faq/ Legal section
    path: '/mitre/enterprise',
    state: 'adom.default.alert.incident.mitre-enterprise',
    showCheckers: [
      hasRDPermitOn('event_management'),
      negate(getIsFazSupervisor),
    ],
    olhTag: 'mitre_enterprise',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> MITRE ATT&CK®.',
  },
  'mitre-enterprise-attack': {
    label: gettext('Attack'),
    path: '/alert/incident-list/mitre/enterprise/attack',
    state: 'adom.default.alert.incident.mitre-enterprise-attack',
    showCheckers: [hasRDPermitOn('event_management')],

    olhTag: 'mitre_enterprise_attack',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> MITRE ATT&CK® -> Attack.',
  },
  'mitre-enterprise-coverage': {
    label: gettext('Coverage'),
    path: '/alert/incident-list/mitre/enterprise/coverage',
    state: 'adom.default.alert.incident.mitre-enterprise-coverage',
    showCheckers: [hasRDPermitOn('event_management')],

    olhTag: 'mitre_enterprise_attack',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> MITRE ATT&CK® -> Coverage.',
  },
  'mitre-ics': {
    label: 'MITRE ATT&CK® ICS', // Do NOT use gettext. DO NOT remove "MITRE"
    path: '/alert/incident-list/mitre/ics',
    state: 'adom.default.alert.incident.mitre-ics',
    showCheckers: [
      hasRDPermitOn('event_management'),
      negate(getIsFazSupervisor),
    ],

    olhTag: 'mitre_ics',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> MITRE ATT&CK® ICS.',
  },
  'mitre-ics-attack': {
    label: gettext('Attack'),
    state: 'adom.default.alert.incident.mitre-ics-attack',
    path: '/alert/incident-list/mitre/ics/attack',
    showCheckers: [hasRDPermitOn('event_management')],

    olhTag: 'mitre_ics_attack',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> MITRE ATT&CK® ICS -> Attack.',
  },
  'mitre-ics-coverage': {
    label: gettext('Coverage'),
    path: '/alert/incident-list/mitre/ics/coverage',
    state: 'adom.default.alert.incident.mitre-ics-coverage',
    showCheckers: [hasRDPermitOn('event_management')],
  },
  incident: {
    label: gettext('Incidents'),
    state: 'adom.default.alert.incident',
    showCheckers: [hasRDPermitOn('event_management')],

    olhTag: 'mitre_ics_coverage',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> MITRE ATT&CK® ICS -> Coverage.',
  },
  incidents: {
    path: '/alert/incident-list',
    label: gettext('Incidents'),
    state: 'adom.default.alert.incident.allincidents',
    noStateCache: true,
    reloadState: 'adom.default.alert.incident.allincidents',
    stateParams: {
      page: null,
    },

    olhTag: 'alert_allincidents',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Incidents.',
  },
  indicators: {
    path: '/alert/indicator-list',
    label: gettext('Indicators'),
    state: 'adom.default.alert.allindicators',
    noStateCache: true,
    reloadState: 'adom.default.alert.allindicators',
    stateParams: {
      page: null,
    },
  },
  siem_parser: {
    path: '/alert/logparser',
    label: gettext('Log Parsers'),
    state: 'adom.default.alert.incident.logparser',
    showCheckers: [
      hasRDPermitOn('log_viewer'),
      negate(isSiemDisabled),
      getIsFabricAdom,
    ],

    olhTag: 'siem_parser',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Log Parser.',
  },
  assigned_siem_parser: {
    path: '/alert/logparser/assigned',
    label: gettext('Assigned Parsers'),
    state: 'adom.default.alert.incident.logparser.assignlogparser',

    olhTag: 'assigned_siem_parser',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Log Parser -> Assigned Parsers.',
  },
  siem_parser_list: {
    path: '/alert/logparser/list',
    label: gettext('Log Parsers'),
    state: 'adom.default.alert.incident.logparser.logparserlist',

    olhTag: 'siem_parser_list',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Log Parser -> Log Parsers.',
  },
  safeguarding: {
    path: '/alert/safeguarding',
    label: gettext('Safeguarding'),
    state: 'adom.default.alert.safeguarding',
    noStateCache: true,
    reloadState: 'adom.default.alert.safeguarding',
    stateParams: {
      page: null,
    },
  },
  // siem_parser: {
  //   label: gettext('Log Parser'),
  //   state: 'adom.default.alert.incident.logparser',
  //   showCheckers: [hasRDPermitOn('log_viewer'), negate(isSiemDisabled)],
  // },
  // assign_siem_parser: {
  //   label: gettext('Assigned Parsers'),
  //   state: 'adom.default.alert.incident.logparser.assignlogparser',
  // },
  // siem_parser_list: {
  //   label: gettext('Log Parsers'),
  //   state: 'adom.default.alert.incident.logparser.logparserlist',
  // },

  // --------------------------------------------------------------------------

  fortisoc_supv: {
    label: gettext('Incidents & Events'),
    icon: 'event-monitor',
    color: '#38bb9c',
    state: 'adom.default.fortisoc-supv',
    reloadState: 'adom.default.fortisoc-supv',
    gdprTool: true,
    showCheckers: [
      isFaz,
      getIsFazSupervisor,
      hasRDPermitOn('event_management'),
    ],
  },
  fortisoc_supv_groups: {
    path: '/alert-supv/allgroups',
    label: gettext('Fabric Events'),
    gdprTool: true,

    olhTag: 'faz_supervisor_event_groups',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Fabric Events.',
  },
  fortisoc_supv_all: {
    path: '/alert-supv/allgroups/all',
    label: gettext('All Events'),
    gdprTool: true,

    olhTag: 'faz_supervisor_event_groups',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Fabric Events -> All Events.',
  },
  fortisoc_supv_local: {
    path: '/alert-supv/local',
    label: gettext('Local Events'),
    state: 'adom.default.fortisoc-supv.local',
    reloadState: 'adom.default.fortisoc-supv.local',
    gdprTool: true,

    olhTag: 'faz_supervisor_local_event',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Local Events.',
  },
  fortisoc_supv_incidents: {
    path: '/alert-supv/incident',
    label: gettext('Incidents'),
    state: 'adom.default.fortisoc-supv.incident',
    reloadState: 'adom.default.fortisoc-supv.incident',
    noStateCache: true,
    stateParams: {
      page: null,
    },

    olhTag: 'faz_supervisor_incidents',
    olhCategory: 'alert',
    olhFaz: 'Incidents & Events -> Incidents.',
  },
};
