import { fiFmgHttp } from 'fi-http';
import { isString } from 'lodash';

const getUrl = '/um/license/filter';
const updateUrl = '/um/update/license/filter';

const GLOBAL = 'global';

const _fields = {
  AV: 'antivirus',
  IPS: 'ips',
  ANTISPAM: 'antispam',
  WEBFILTER: 'webfiltering',
  VALSCAN: 'valscan',
  OUTBREAK: 'outbreak',
  INDUSTRALDB: 'industraldb',
  FMSS: 'fmss',
};

const _map2noc = {
  [_fields.AV]: 'licav',
  [_fields.IPS]: 'licips',
  [_fields.ANTISPAM]: 'licas',
  [_fields.WEBFILTER]: 'licwf',
  [_fields.FMSS]: 'licfmss',
};

let _filters = {},
  _skipped_license = null;

export {
  GLOBAL,
  getFilterFields,
  getMap2NOC,
  getFilters,
  clearFilters,
  loadFilters,
  setFilters,
  skippedLicense,
};

function getFilterFields() {
  return _fields;
}

function getMap2NOC() {
  return _map2noc;
}

function getFilters(sn) {
  if (isString(sn)) return _filters[sn];
  else return _filters;
}

function clearFilters() {
  _filters = {};
}

function loadFilters() {
  clearFilters();
  let req = {
    id: 1,
    method: 'exec',
    params: [
      {
        url: getUrl,
        data: {},
      },
    ],
  };
  return fiFmgHttp.forward(req).then((resp) => {
    let all_filters = [];
    try {
      all_filters = resp[0].data.license_filter || [];
    } catch (e) {
      all_filters = [];
    }
    all_filters.forEach((f) => {
      if (!f) return;
      _filters[f.serial] = f.type || [];
    });
  });
}

function setFilters(devs, type) {
  if (!Array.isArray(devs)) devs = [devs];
  if (!Array.isArray(type)) type = [type];
  let req = {
    id: 1,
    method: 'exec',
    params: [
      {
        url: updateUrl,
        data: {
          license_filter: _genLicenseFilters(devs, type),
        },
      },
    ],
  };
  return fiFmgHttp.forward(req);
}

function _genLicenseFilters(devs, type) {
  let ret = [];
  if (devs && Array.isArray(devs)) {
    devs.forEach((d) => {
      let obj = {
        serial: d.toString(),
        type: type ? type : [],
      };
      ret.push(obj);
    });
  }
  return ret;
}

function skippedLicense(skipped) {
  if (typeof skipped !== 'undefined') _skipped_license = skipped;
  return _skipped_license;
}
