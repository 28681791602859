import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';
import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import { ReactContentViewContainer } from 'ra_main/main_layout/ReactContentViewContainer';

const ROUTES = {
  path: '/report',
  handle: { appUniKey: 'report' },
  children: [
    getRedirectIndexRoute(),
    {
      path: 'generated',
      handle: { appUniKey: 'report_generated' },
      lazy: () => import('react_apps/ra_report/generated/ReportGeneratedList'),
    },
    {
      path: 'defs',
      RenderErrorBoundary,
      handle: { appUniKey: 'report_defs' },
      element: <ReactContentViewContainer menuId='report_defs' />,
      children: [
        {
          path: 'reports',
          RenderErrorBoundary,
          handle: { appUniKey: 'report_defs_reports' },
          lazy: () => import('./layout/List'),
        },
        {
          path: 'templates',
          RenderErrorBoundary,
          handle: { appUniKey: 'report_defs_templates' },
          lazy: () => import('./template/List'),
        },
        {
          path: 'charts',
          RenderErrorBoundary,
          handle: { appUniKey: 'report_defs_charts' },
          lazy: () => import('./chart/List'),
        },
        {
          path: 'macros',
          RenderErrorBoundary,
          handle: { appUniKey: 'report_defs_macros' },
          lazy: () => import('./macro/List'),
        },
        {
          path: 'datasets',
          RenderErrorBoundary,
          handle: { appUniKey: 'report_defs_datasets' },
          lazy: () => import('./dataset/List'),
        },
      ],
    },
    {
      path: 'adv',
      RenderErrorBoundary,
      handle: {
        appUniKey: 'report_advanced',
      },
      element: <ReactContentViewContainer menuId='report_defs' />,
      children: [
        {
          path: 'calendar',
          RenderErrorBoundary,
          handle: { appUniKey: 'report_advanced_calendar' },
          lazy: () => import('ra_report/ReportCalendar'),
        },
        {
          path: 'lang',
          RenderErrorBoundary,
          handle: { appUniKey: 'report_advanced_lang' },
          lazy: () => import('ra_report/language/ReportLanguageList'),
        },
        {
          path: 'outputprofiles',
          RenderErrorBoundary,
          handle: { appUniKey: 'report_advanced_outputprofiles' },
          lazy: () =>
            import('ra_report/output_profile/ReportOutputProfileList'),
        },
      ],
    },
  ],
};

export default ROUTES;
