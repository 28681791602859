import { fiDvmScriptRequests } from 'fi-apps/fi-dvm/services/script_requests';
import { fiFmgHttp, getResponseData } from 'fi-web/fi-http';
import { fiDvmTemplateRequests } from '../requests/template_requests';
import { fiAdom, fiSysConfig } from 'fi-session';
import { isFunction, transform } from 'lodash';
import { fiDvmBtnStatus } from '../device_commands/dvm_button_status';
import { escapeSlash } from 'kit/kit-regexp';

const PT_TYPES = MACROS.USER.DVM.PT_TYPE;
const TEMPLATE_STYPE = MACROS.USER.DVM.CUSTOM_TEMPLATE_STYPE;

/**
 * Get all templates of a given type and adom
 * @param {*} type e.g. dev_prof
 * @param {{oid: number, name: string}} adom
 * @returns
 */
export const getTemplateOptionList = (
  type,
  adom,
  filterPreRunCli = true,
  abortCtrl = undefined
) => {
  let prom = null;
  const _filterFnIp = (item) =>
    item?.['template setting']?.option !==
    MACROS.PM2CAT.PM2_TEMPLATE_OPT_READONLY;

  switch (type) {
    case PT_TYPES.SYSTEM_TEMPLATE:
      prom = getDevProfList(adom.name, abortCtrl);
      break;
    case PT_TYPES.PRERUN_CLI_TEMPLATE:
      return fiDvmScriptRequests
        .cliTemplateApi(adom.oid, 'get', null, abortCtrl)
        .then((tmplList) => {
          return tmplList.filter((prof) => prof.provision === 1);
        });
    case PT_TYPES.CLI_TEMPLATE:
      // Cli tmpl and cli tmpl group returned together
      return Promise.all([
        fiDvmScriptRequests.cliTemplateApi(adom.name, 'get', null, abortCtrl),
        fiDvmScriptRequests.cliTemplateGrpApi(
          adom.name,
          'get',
          null,
          abortCtrl
        ),
      ]).then(([tmplList, tmpGrpList]) => {
        // filter out pre-run cli template
        const filteredCliTempList = filterPreRunCli
          ? tmplList.filter((prof) => prof?.provision === 0)
          : tmplList;
        const cliTemplatesGroups = tmpGrpList.map((grp) => ({
          ...grp,
          isTemplateGrp: true,
        }));
        const templates = [...cliTemplatesGroups, ...filteredCliTempList];
        return templates.map((prof) => ({
          ...prof,
          id: prof.name,
          text: prof.name,
        }));
      });
    case PT_TYPES.IPSEC_TEMPLATE:
      prom = fiDvmTemplateRequests
        .loadTemplate(adom, null, TEMPLATE_STYPE.IPSEC, abortCtrl)
        .then((resp) => resp.filter(_filterFnIp));
      break;
    case PT_TYPES.ROUTE_TEMPLATE:
      prom = fiDvmTemplateRequests.loadTemplate(
        adom,
        null,
        TEMPLATE_STYPE.ROUTER,
        abortCtrl
      );
      break;
    case PT_TYPES.SDWAN_TEMPLATE:
      prom = getWANTemplates(adom.name, abortCtrl);
      break;
    case PT_TYPES.BGP_TEMPLATE:
      prom = fiDvmTemplateRequests.loadTemplate(
        adom,
        null,
        TEMPLATE_STYPE.BGP,
        abortCtrl
      );
      break;
    case PT_TYPES.IPS_TEMPLATE:
      prom = fiDvmTemplateRequests.loadTemplate(
        adom,
        null,
        TEMPLATE_STYPE.IPS,
        abortCtrl
      );
      break;
    case PT_TYPES.NSX_T_TEMPLATE:
      prom = fiDvmTemplateRequests.loadTemplate(
        adom,
        null,
        TEMPLATE_STYPE.CST,
        abortCtrl
      );
      break;
    case PT_TYPES.SDWAN_OVERLAY:
      prom = fiDvmTemplateRequests.loadTemplate(
        adom,
        null,
        TEMPLATE_STYPE.SDWAN_OVERLAY,
        abortCtrl
      );
      break;
    case PT_TYPES.THREAT_WEIGHT_TEMPLATE:
      prom = getThreatWeightTemplates(adom.name, abortCtrl);
      break;
    case PT_TYPES.CERTIFICATE_TEMPLATE:
      prom = getCertificateTemplates(adom.name, abortCtrl);
      break;
    case PT_TYPES.FABRIC_AUTH_TEMPLATE:
      prom = getFabricAuthTemplates(adom.name, abortCtrl);
      break;
    case PT_TYPES.TEMPLATE_GROUP:
      prom = getTemplateGroupList(adom.name, abortCtrl);
      break;
    default:
      return Promise.resolve([]);
  }
  return prom.then((resp) => {
    return (resp || [])
      .map((prof) => ({ ...prof, id: prof.name, text: prof.name }))
      .sort((a, b) => a.text.localeCompare(b.text));
  });
};

/**
 * Gets device profile list
 * @param {String} adomName - the ADOM name
 * @return {Promise}
 */
export const getDevProfList = function (adom, abortCtrl) {
  const adomName = adom.name || adom;

  const req = {
    method: 'get',
    params: [
      {
        url: 'pm/devprof/adom/' + adomName,
        fields: ['type', 'scope member', 'description', 'enabled options'],
      },
    ],
  };
  return fiFmgHttp.forward(req, false, abortCtrl).then(function (resp) {
    return getResponseData(resp);
  });
};

export async function getTemplateGroupList(adomName, abortCtrl) {
  const req = {
    method: 'get',
    params: [
      {
        url: `pm/tmplgrp/adom/${adomName}`,
      },
    ],
  };
  const resp = await fiFmgHttp.forward(req, false, abortCtrl);
  return getResponseData(resp);
}

export async function getTemplateGroup(adomName, templateGroupName) {
  const req = {
    method: 'get',
    params: [
      {
        url: `pm/tmplgrp/adom/${adomName}/${escapeSlash(templateGroupName)}`,
      },
    ],
  };
  const resp = await fiFmgHttp.forward(req);
  return getResponseData(resp);
}

export async function getThreatWeightTemplates(adomName, abortCtrl) {
  return fiFmgHttp
    .forward(
      {
        method: 'get',
        params: [
          {
            url: `pm/crprof/adom/${adomName}`,
          },
        ],
      },
      false,
      abortCtrl
    )
    .then((resp) => getResponseData(resp));
}

export async function getCertificateTemplates(adomName, abortCtrl) {
  return fiFmgHttp
    .forward(
      {
        method: 'get',
        params: [
          {
            url: `/pm/config/adom/${adomName}/obj/certificate/template`,
          },
        ],
      },
      false,
      abortCtrl
    )
    .then((resp) => getResponseData(resp));
}

export async function getFabricAuthTemplates(adomName, abortCtrl) {
  const req = {
    method: 'get',
    params: [
      {
        url: `pm/config/adom/${adomName}/obj/fmg/fabric/authorization/template`,
      },
    ],
  };
  const resp = await fiFmgHttp.forward(req, false, abortCtrl);
  return getResponseData(resp);
}

export function getWANTemplates(adom, abortCtrl) {
  const adomName = adom?.name ? adom.name : adom;
  return fiFmgHttp
    .query(
      {
        id: 1,
        method: 'get',
        params: [
          {
            url: '/pm/wanprof/adom/' + adomName,
            fields: ['scope member', 'description'],
            sortings: [{ name: 1 }],
          },
        ],
      },
      abortCtrl
    )
    .then(function (resp) {
      return resp[0].data;
    });
}

const allPtTypes = Object.values(PT_TYPES);
export const getAllTemplatesMap = async ({
  adom = fiAdom.current(),
  types = allPtTypes,
  filterByType,
  parserByType,
  filterPreRunCli = true,
  abortCtrl,
} = {}) => {
  const promises = [];
  for (const type of types) {
    if (allPtTypes.includes(type)) {
      promises.push(
        getTemplateOptionList(type, adom, filterPreRunCli, abortCtrl)
      );
    }
  }
  const resps = await Promise.allSettled(promises);
  return transform(
    resps,
    (result, resp, index) => {
      const { status, value } = resp;
      const type = types[index];
      if (status === 'rejected') {
        result[type] = [];
        return;
      }

      let list = value || [];

      if (isFunction(filterByType)) {
        list = filterByType(type, value);
      }

      if (isFunction(parserByType)) {
        list = parserByType(type, value);
      }

      result[type] = list;
    },
    {}
  );
};

export const getDvmToolbarItemWithDisableStatus = (item, selectedRows) => {
  if (fiDvmBtnStatus[item.key]) {
    const sysConfig = fiSysConfig.current();
    let btn = fiDvmBtnStatus[item.key](sysConfig, item);
    btn.process(selectedRows);
  } else {
    item.disabled = item.disabled || false;
  }
  return item;
};
