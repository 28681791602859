import moment from 'moment';

/**
 * Converts "YYYY-MM-DD, HH:mm:ss" to [YYYY, MM, DD, HH, mm, ss]
 * @param {string | Date} date - The date string to convert or a Date object
 * @returns {number[] | string | Date} An array of numbers representing the date components, or the original input if it's not a string
 */
export const dateStrToArr = (date: string | Date): number[] | string | Date => {
  if (typeof date !== 'string') {
    return date;
  }
  const dateObj = new Date(date);
  return [
    dateObj.getFullYear(),
    dateObj.getMonth() + 1,
    dateObj.getDate(),
    dateObj.getHours(),
    dateObj.getMinutes(),
    dateObj.getSeconds(),
  ];
};

/**
 * Formats a Date object to 'yyyy-MM-dd' string
 * @param {Date} d - The Date object to format
 * @returns {string} A string representing the date in 'yyyy-MM-dd' format
 * @throws {Error} If the input is not a valid Date object
 */
export const getCalendarDate = (d: Date): string => {
  if (!(d instanceof Date)) {
    throw new Error('Not a valid Date object.');
  }

  const year = d.getFullYear();
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

/**
 * Formats a timeframe based on two dates
 * @param {[Date, Date]} dateArr - An array containing two Date objects
 * @param {string} [toTime] - Optional string to use instead of 'To' in the output
 * @returns {string} A formatted string representing the timeframe
 */
export const formatTimeframe = (
  dateArr: [Date, Date],
  toTime?: string
): string => {
  const ret: string[] = [];
  let tmp: string[];
  const diff = dateArr[1].getTime() - dateArr[0].getTime();

  if (diff < 24 * 3600000 - 1000) {
    // display hour min sec
    ret.push(dateArr[0].toTimeString().split(' ')[0]);
    if (toTime) {
      ret.push(toTime);
    } else {
      ret.push('To');
      ret.push(dateArr[1].toTimeString().split(' ')[0]);
    }
  } else {
    if (dateArr[1].getFullYear() - dateArr[0].getFullYear() > 0) {
      // display year mon day
      tmp = dateArr[0].toString().split(' ');
      ret.push(tmp[1], tmp[2], tmp[3]);
      if (toTime) {
        ret.push(toTime);
      } else {
        ret.push('To');
        tmp = dateArr[1].toString().split(' ');
        ret.push(tmp[1], tmp[2], tmp[3]);
      }
    } else {
      // same year
      tmp = dateArr[0].toString().split(' ');
      ret.push(tmp[1], tmp[2]);
      if (toTime) {
        ret.push(toTime);
      } else {
        ret.push('To');
        tmp = dateArr[1].toString().split(' ');
        ret.push(tmp[1], tmp[2]);
      }
    }
  }
  return ret.join(' ');
};

/**
 * Format seconds to a compact string
 * @param {number} seconds - The number of seconds to format
 * @returns {string} A compact string representation of the time
 * @example
 * formatSecondsCompact(150) -> "2m 30s"
 * formatSecondsCompact(3661) -> "1h 1m 1s"
 */
export const formatSecondsCompact = (seconds: number): string => {
  if (isNaN(seconds) || seconds < 0) return 'N/A';
  if (seconds < 1) return '<1s';

  const hrs = Math.floor(seconds / 3600);
  seconds %= 3600;
  const min = Math.floor(seconds / 60);
  const sec = Math.floor(seconds % 60);

  const parts: string[] = [];
  if (hrs) parts.push(`${hrs}h`);
  if (min) parts.push(`${min}m`);
  if (sec || !parts.length) parts.push(`${sec}s`);

  return parts.join(' ');
};

/**
 * Formats elapsed time in seconds to a human-readable string
 * @param {number} sec - The number of seconds
 * @returns {string} A formatted string representing the elapsed time
 */
export const elapsedTime = (sec: number): string => {
  if (typeof sec !== 'number' || sec < 1) return gettext('Unknown');

  interface TimeUnit {
    v: number;
    textFn: (num: number) => string;
  }

  const timeUnits: TimeUnit[] = [
    {
      v: 86400,
      textFn: (num) => ngettext('%(num)s day', '%(num)s days', num),
    },
    {
      v: 3600,
      textFn: (num) => ngettext('%(num)s hour', '%(num)s hours', num),
    },
    {
      v: 60,
      textFn: (num) => ngettext('%(num)s minute', '%(num)s minutes', num),
    },
  ];

  let label: string | undefined;

  timeUnits.some(({ v, textFn }) => {
    const num = Math.floor(sec / v);
    if (num) {
      label = textFn(num).printfd({ num });
      return true;
    }
    return false;
  });

  if (label) return label;
  const num = Math.floor(sec);
  return ngettext('%(num)s second', '%(num)s seconds', num).printfd({ num });
};

/**
 * Get a time frame based on a base date, period, and offset
 * @param {string | Date | moment.Moment} dtBase - The base date
 * @param {moment.unitOfTime.DurationConstructor} period - The period unit (e.g., 'days', 'hours')
 * @param {number} offset - The offset value
 * @returns {[string, string]} An array of two formatted date strings
 */
export const getTimeOffsetFrame = (
  dtBase: string | Date | moment.Moment,
  period: moment.unitOfTime.DurationConstructor,
  offset: number
): [string, string] => {
  const STR_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  return [
    moment(dtBase).subtract(offset, period).format(STR_FORMAT),
    moment(dtBase).add(offset, period).format(STR_FORMAT),
  ];
};

/**
 * Format seconds into a human-readable string
 * @param {number} secs - The number of seconds
 * @returns {string} A formatted string representing the time
 */
export const formatTimeUnit = (secs: number): string => {
  if (secs === 1) return gettext('%s Second').printf([secs]);
  if (secs < 60) return gettext('%s Seconds').printf([secs]);
  if (secs === 60) return gettext('%s Minute').printf([secs / 60]);
  if (secs > 60 && secs < 3600)
    return gettext('%s Minutes').printf([secs / 60]);
  if (secs === 3600) return gettext('%s Hour').printf([secs / 3600]);
  return gettext('%s Hours').printf([secs / 3600]);
};

/**
 * Convert seconds to a string representation of days, hours, minutes, and seconds
 * @param {number} sec - The number of seconds to convert
 * @returns {string} A formatted string representing the time in d, h, m, s format
 */
export const seconds2Dhms = (sec: number): string => {
  if (sec === -1) {
    return gettext('N/A');
  }

  const days = Math.floor(sec / 86400);
  const hours = Math.floor((sec - days * 86400) / 3600);
  const minutes = Math.floor((sec - days * 86400 - hours * 3600) / 60);
  const seconds = sec - days * 86400 - hours * 3600 - minutes * 60;

  let time: string[];

  if (days) {
    time = [
      `${days}d`,
      `${hours}h`,
      `${minutes}m`,
      `${seconds < 10 ? '0' + seconds : seconds}s`,
    ];
  } else if (hours) {
    time = [
      `${hours < 10 ? '0' + hours : hours}h`,
      `${minutes < 10 ? '0' + minutes : minutes}m`,
      `${seconds < 10 ? '0' + seconds : seconds}s`,
    ];
  } else if (minutes) {
    time = [
      `${minutes < 10 ? '0' + minutes : minutes}m`,
      `${seconds < 10 ? '0' + seconds : seconds}s`,
    ];
  } else {
    time = [`${seconds}s`];
  }

  return time.join(' ');
};

/**
 * Convert a date string in 'YYYYMMDD' format to a Date object
 * @param {string | null | undefined} str - The date string to convert
 * @returns {Date | null} A Date object representing the input string, or null if input is falsy
 */
export function fiStrToDate(str: string | null | undefined): Date | null {
  if (!str) return null;

  const year = parseInt(str.slice(0, 4), 10);
  const month = parseInt(str.slice(4, 6), 10) - 1; // Months are 0-indexed in JavaScript
  const day = parseInt(str.slice(6, 8), 10);

  return new Date(year, month, day);
}

/**
 * Convert a numeric day of the week to its corresponding string representation
 * @param {number} input - The day of the week (0-6, where 0 is Sunday)
 * @returns {string} The name of the day of the week, or an empty string if input is invalid
 */
export function getWeekdayString(input: number): string {
  switch (input) {
    case 0:
      return gettext('Sunday');
    case 1:
      return gettext('Monday');
    case 2:
      return gettext('Tuesday');
    case 3:
      return gettext('Wednesday');
    case 4:
      return gettext('Thursday');
    case 5:
      return gettext('Friday');
    case 6:
      return gettext('Saturday');
    default:
      return '';
  }
}

/**
 * Format seconds into an array of time units and their respective labels
 * @param {number} seconds - The number of seconds to format
 * @returns {Array<number | string>} An array alternating between time values and their labels
 */
export function formatSeconds(seconds: number): Array<number | string> {
  interface TimeUnit {
    [key: number]: [string, string];
  }

  const units: TimeUnit = {
    86400: [gettext('day'), gettext('days')],
    3600: [gettext('hour'), gettext('hours')],
    60: [gettext('minute'), gettext('minutes')],
    1: [gettext('second'), gettext('seconds')],
  };
  const unitsVal: number[] = [86400, 3600, 60, 1];

  if (!seconds) {
    return [0, units[1][0]];
  }

  const times: Array<number | string> = [];
  let remainder = seconds;

  for (const unit of unitsVal) {
    if (remainder >= unit) {
      const quot = Math.floor(remainder / unit);
      times.push(quot, quot > 1 ? units[unit][1] : units[unit][0]);

      remainder %= unit;
      if (!remainder) {
        break;
      }
    }
  }

  return times;
}
