/** ----------------------------------------------------------------------------
 * Constants
 * -------------------------------------------------------------------------- */
export enum HAMode {
  Standalone = MACROS.DVM.DVM_HA_MODE_STANDALONE,
  ActivePassive = MACROS.DVM.DVM_HA_MODE_AP,
  ActiveActive = MACROS.DVM.DVM_HA_MODE_AA,
  Balance = MACROS.DVM.DVM_HA_MODE_ELBC,
  Dual = MACROS.DVM.DVM_HA_MODE_DUAL,
  FMG = MACROS.DVM.DVM_HA_MODE_FMG,
  AUTOSCALE = MACROS.DVM.DVM_HA_MODE_AUTOSCALE,
}

export const HA_MODE_TEXT = {
  [HAMode.Standalone]: gettext('Stand-Alone'),
  [HAMode.ActivePassive]: gettext('Active-Passive'),
  [HAMode.ActiveActive]: gettext('Active-Active'),
  [HAMode.Balance]: gettext('Balance'),
  [HAMode.Dual]: gettext('Dual'),
  [HAMode.FMG]: gettext('FortiManager HA'),
  [HAMode.AUTOSCALE]: gettext('Auto Scale'),
};

export enum HAUpgradeMode {
  Simultaneous = MACROS.PM2CAT.PM2_HA_UPGRADE_MODE_SIMULTANEOUS,
  Uninterruptible = MACROS.PM2CAT.PM2_HA_UPGRADE_MODE_UNINTERRUPTIBLE,
  LocalOnly = MACROS.PM2CAT.PM2_HA_UPGRADE_MODE_LOCAL_ONLY,
  SecondaryOnly = MACROS.PM2CAT.PM2_HA_UPGRADE_MODE_SECONDARY_ONLY,
}

export const HA_UPGRADE_MODE_TEXT = {
  [HAUpgradeMode.Simultaneous]: gettext('Simultaneous'),
  [HAUpgradeMode.Uninterruptible]: gettext('Uninterruptible'),
  [HAUpgradeMode.LocalOnly]: gettext('Local Only'),
  [HAUpgradeMode.SecondaryOnly]: gettext('Secondary Only'),
};

/** ----------------------------------------------------------------------------
 * Helpers
 * -------------------------------------------------------------------------- */
export function isMVCModeHACluster(ha_upgrade_mode: number) {
  return [HAUpgradeMode.LocalOnly, HAUpgradeMode.SecondaryOnly].includes(
    ha_upgrade_mode
  );
}
