import { fiSysConfig } from 'fi-session';

export const fiLocalStorageId = {
  COLDEF: 'stId_coldefs',
};

const KEY_SEP = ':';
const storageObj = {
  /***********************************************/
  setObject: function (cate, key, value) {
    if (!key || !cate) {
      return false;
    }
    return _setObject(_genKey(cate, key), value);
  },
  delObject: function (cate, key) {
    if (!key || !cate) {
      return false;
    }
    return _setObject(_genKey(cate, key), '');
  },
  updateObject: function (cate, key, attr, value) {
    var tmpObj = _getObject(_genKey(cate, key));
    tmpObj[attr] = value;
    return _setObject(_genKey(cate, key), tmpObj);
  },
  getObject: function (cate, key) {
    return _getObject(_genKey(cate, key));
  },

  /************** following only for columns settings ***********************/
  setCatedef: function (key, value) {
    return storageObj.setObject(fiLocalStorageId.COLDEF, key, value);
  },
  delCatedef: function (key) {
    return storageObj.delObject(fiLocalStorageId.COLDEF, key);
  },
  updateCatedef: function (key, attr, value) {
    return storageObj.updateObject(fiLocalStorageId.COLDEF, key, attr, value);
  },
  getCatedef: function (key) {
    return storageObj.getObject(fiLocalStorageId.COLDEF, key);
  },
  /***********************************************/
};
// only works for json object, not pure string
export const fiLocalStorageService = storageObj;

//========== internal functions ===========
function _getUser() {
  try {
    return fiSysConfig.current().username;
  } catch (e) {
    //
  }
  return '';
}

/**
 *
 * @param strKey
 * @param jsonObj
 * @returns {boolean} true if saved successfully, false otherwise
 */
function _setObject(strKey, jsonObj) {
  const user = _getUser();

  if (!strKey || !user) return false;

  const getItem = window.localStorage.getItem.bind(window.localStorage);
  const setItem = window.localStorage.setItem.bind(window.localStorage);

  let item = getItem(user);
  if (!item) {
    item = {};
  } else {
    item = JSON.parse(item);
  }
  if (jsonObj) {
    item[strKey] = jsonObj;
  } else {
    delete item[strKey];
  }
  setItem(user, JSON.stringify(item));

  return true;
}

/**
 *
 * @param strKey
 * @returns empty object if not exists
 */
function _getObject(strKey) {
  const user = _getUser();

  if (!strKey || !user) return {};

  const getItem = window.localStorage.getItem.bind(window.localStorage);
  let item = getItem(user);
  if (!item) return {};

  item = JSON.parse(item);

  return item[strKey] || {};
}

function _genKey(cate, key) {
  return cate + KEY_SEP + key;
}
