import fiWebSocketAddon from './fiWebSocketAddon';
import { debounce } from 'fiutil';

export { subscribeToWorkspaceChange };

export default class fiWebSocketRemoteAddon extends fiWebSocketAddon {
  constructor({ remoteParam, fiWorkspace, ...rest }) {
    super(rest);

    this.remoteParam = remoteParam;

    if (fiWorkspace) {
      this.addDisposable(
        subscribeToWorkspaceChange({
          fiWorkspace,
          onDeviceLockChange: (isDeviceLockedByMe) => {
            this._isDeviceLockedByMe = isDeviceLockedByMe;

            if (!isDeviceLockedByMe(this.remoteParam.oid)) {
              this.disconnect();
            }
          },
        })
      );
    }
  }

  async connect(remoteParam) {
    if (remoteParam?.ipaddr) {
      this.remoteParam = remoteParam;
    }

    const isNonLockedByMe =
      this._isDeviceLockedByMe &&
      !this._isDeviceLockedByMe(this.remoteParam.oid);
    if (isNonLockedByMe) {
      return this.xterm.writeln(
        'Please lock device before connect to CLI.\r\n'
      );
    }

    const result = await this.fiWebSocket.send(
      this._genRequest('connect', {
        type: 'remote',
        ...this.remoteParam,
        ...this._terminalDimension,
      })
    );

    this.consoleId = result.consoleId;

    return this.disconnect;
  }
}

function subscribeToWorkspaceChange({ fiWorkspace, onDeviceLockChange }) {
  // bindAdomUpdateFn and bindDevUpdateFn both call this function when change from lock device to lock adom.
  // debounce to reduce function call
  const updateDeviceFilter = debounce(() => {
    let filterFn;

    // adom or device is locked by me
    if (!fiWorkspace.adomInfo().isLockedByMe()) {
      filterFn = (dev) => {
        const devInfo = fiWorkspace.devInfo(dev);
        return devInfo.isLockedByMe();
      };
    }

    onDeviceLockChange(filterFn || Boolean);
  }, 500);

  const wsUnregs = [
    fiWorkspace.bindAdomUpdateFn(updateDeviceFilter),
    fiWorkspace.bindDevUpdateFn(updateDeviceFilter),
  ];

  return () => {
    wsUnregs.forEach((unreg) => unreg());
  };
}
