// Util Service, KEEP DEPENDENCY AS LESS AS POSSIBLE
import {
  isArray,
  isNumber,
  isString,
  isNil,
  transform,
  negate,
  get,
  capitalize,
  union,
  forEach,
  has,
  stubTrue,
  invoke,
} from 'lodash';
import { setIn } from 'react_apps/ra-data-util/data_process';
import { fiAdom } from 'fi-session';

export {
  isSingleSelect,
  isTFType,
  isIPMaskType,
  isDSType,
  hasDSRef,
  isMultiIntType,
  isUintType,
  isInteger,
  isMultiStringType,
  isMultiSelect,
  isMultiOptions,
  isPasswordType,
  isCommentType,
  isMetaSupport,
  isMetaAddRef,
  isMetaNoRef,
  isSeqnum,
  isPortRange,
  isMetaSupportSelect,
  matchOpt,
  setOpt,
  findOptKeyByVal,
  getOpts,
  getOptValByKey,
  createSyntaxOpts,
  getSyntaxValue,
  getDefaultValue,
  getSyntaxUrl,
  getMatchedAttrs,
  getMetaSupportSelectFields,
  getInusedFilterFn,
  getAdvOptsFilterFn,
  genGrpMemberMap,
};

function isSingleSelect(attrSyntax: SyntaxAttr): boolean {
  return has(attrSyntax, 'opts') && get(attrSyntax, 'excluded', false);
}

/**
 * True if the syntax is True/False choice.
 */
function isTFType(attrSyntax: SyntaxAttr): boolean {
  return !!(
    attrSyntax.type?.match(/^uint/) &&
    isSingleSelect(attrSyntax) &&
    Object.keys(attrSyntax.opts || {}).length === 2 &&
    has(attrSyntax, 'opts.enable')
  );
}

function isIPMaskType(attrSyntax: SyntaxAttr): boolean {
  return attrSyntax.type === 'ipv4_mask';
}

function isDSType(attrSyntax: SyntaxAttr): boolean {
  return attrSyntax.type === 'datasrc';
}

function isMultiIntType(attrSyntax: SyntaxAttr): boolean {
  return attrSyntax.type === 'multi_int';
}

function isUintType(attrSyntax: SyntaxAttr): boolean {
  return attrSyntax.type?.match(/^uint/) ? true : false;
}

function isMultiStringType(attrSyntax: SyntaxAttr): boolean {
  return attrSyntax.type === 'multi_str';
}

function isInteger(attrSyntax: SyntaxAttr): boolean {
  return attrSyntax.type === 'integer' ? true : false;
}

function isMultiSelect(attrSyntax: SyntaxAttr): boolean {
  return (
    (has(attrSyntax, 'max_argv') && attrSyntax['max_argv'] !== 1) ||
    (isDSType(attrSyntax) && !has(attrSyntax, 'max_argv')) ||
    (has(attrSyntax, 'opts') && !attrSyntax.excluded)
  );
}

function isMultiOptions(attrSyntax: SyntaxAttr): boolean {
  return (
    attrSyntax.type === 'uint32' &&
    has(attrSyntax, 'opts') &&
    attrSyntax.excluded === false
  );
}

function isPasswordType(attrSyntax: SyntaxAttr): boolean {
  return ['password', 'passwd'].includes(attrSyntax.type);
}

function isCommentType(attr: string): boolean {
  return ['comment', 'comments', 'description'].includes(attr);
}

function isMetaSupport(attrSyntax: SyntaxAttr): boolean {
  return !!invoke(attrSyntax, 'flags.includes', 'meta-support');
}

// $SUPPORT_META_ADD_REF in syntax:
// this means if the attr value doesn't start with $, backend will still check datasrc
function isMetaAddRef(attrSyntax: SyntaxAttr): boolean {
  return (
    !!invoke(attrSyntax, 'flags.includes', 'meta-add-ref') ||
    !isMetaNoRef(attrSyntax)
  ); // meta-add-ref is the default
}

// $SUPPORT_META_NO_REF in syntax:
// this means backend won't check datasrc, so user could type in any string, with or without $, or choose from datasrc
function isMetaNoRef(attrSyntax: SyntaxAttr): boolean {
  return !!invoke(attrSyntax, 'flags.includes', 'meta-no-ref');
}

function hasDSRef(attrSyntax: SyntaxAttr): boolean {
  return Boolean(attrSyntax.ref && attrSyntax.ref.length);
}

/**
 * True if the given `value` match any of the syntax `opts`
 * matchOpt(objSyntax, 'type', 'url-list', editValue.type)
 * @param {Object} syntax - Object/Policy syntax
 * @param {String} attr
 * @param {String|String[]} optkey - option key(s)
 * @param {*} value - value to match
 * @returns {Boolean}
 */
function matchOpt(
  syntax: SyntaxVal,
  attr: string,
  optkey: string | string[],
  value: unknown
): boolean {
  if (isArray(optkey)) {
    return optkey.some((key) => matchOpt(syntax, attr, key, value));
  }
  const optVal: unknown = get(syntax, ['attr', attr, 'opts', optkey]);
  return isNil(optVal) ? false : optVal === value;
}

function setOpt(obj: object) {
  return (syntax: SyntaxVal, attr: string, optkeyToSet: string): boolean => {
    const optVal: unknown = get(syntax, ['attr', attr, 'opts', optkeyToSet]);
    return isNil(optVal) ? false : setIn(obj, attr, optVal);
  };
}

function findOptKeyByVal(
  syntax: SyntaxVal,
  attr: string,
  val: unknown
): string | undefined {
  const opts: SyntaxAttr['opts'] = get(syntax, ['attr', attr, 'opts']);
  if (!opts) {
    return;
  }
  return Object.keys(opts).find((optkey) => opts[optkey] === val);
}

function isSeqnum(attrSyntax: SyntaxAttr): boolean {
  return attrSyntax.type === 'seqnum';
}

function isPortRange(attrSyntax: SyntaxAttr): boolean {
  return attrSyntax.type === 'port_range';
}

function isMetaSupportSelect(attrSyntax: SyntaxAttr): boolean {
  const maybeSelect =
    (isDSType(attrSyntax) && hasDSRef(attrSyntax)) ||
    isSingleSelect(attrSyntax) ||
    isMultiSelect(attrSyntax);
  const notMultiSelect = !isMultiStringType(attrSyntax);
  const notTFType = !isTFType(attrSyntax);
  const supportMeta = isMetaSupport(attrSyntax);
  const metaAddRef = isMetaAddRef(attrSyntax);
  return (
    maybeSelect && notMultiSelect && notTFType && supportMeta && metaAddRef
  );
}

function getOpts(syntaxAttrs: SyntaxVal, attr: string): SyntaxAttr['opts'] {
  return get(syntaxAttrs, [attr, 'opts'], {});
}

function getOptValByKey(
  syntaxAttrs: SyntaxVal,
  attr: string,
  optKey: string
): number | undefined {
  const opts = getOpts(syntaxAttrs, attr);
  if (!opts) {
    return;
  }
  return get(opts, optKey) as number;
}

type CreatedSyntaxOpt = {
  id: string | number;
  text: string;
  key: string;
};
function createSyntaxOpts(
  syntaxAttrs: SyntaxVal['attr'],
  attrName: string,
  choicesText: { [key: string]: string } = {
    disable: gettext('Disable'),
    enable: gettext('Enable'),
  },
  _capitalize: boolean = true
): Array<CreatedSyntaxOpt> {
  if (!syntaxAttrs?.[attrName]?.opts) return [];
  return Object.entries(get(syntaxAttrs, [attrName, 'opts']) as object).reduce(
    (acc: CreatedSyntaxOpt[], [key, val]) => {
      const choiceText =
        choicesText[key] ||
        (isString(key) && _capitalize ? capitalize(key) : key);
      if (isNumber(val)) {
        acc.push({ id: val, text: choiceText, key });
      } else if (
        val &&
        typeof val === 'object' &&
        'value' in val &&
        (typeof val.value === 'string' || typeof val.value === 'number')
      ) {
        acc.push({ id: val.value, text: choiceText, key });
      }
      return acc;
    },
    [] as CreatedSyntaxOpt[]
  );
}

/**
 * Gets the default value defined in the given attribute's syntax
 * @return - undefined if the default value is not defined in the syntax
 */
function getDefaultValue(attrSyntax: SyntaxAttr) {
  let defaultVal = attrSyntax.default;
  if (isNil(defaultVal)) {
    return;
  }
  if (isSingleSelect(attrSyntax)) {
    defaultVal = get(attrSyntax, ['opts', defaultVal]);
  }
  return defaultVal;
}

function getSyntaxUrl({
  adom = fiAdom.current(),
  category, // Optional. If omitted, will query for the whole adom syntax
}: {
  adom?: AdomData;
  category?: string;
}): string {
  return `pm/config/${
    fiAdom.isGlobalAdom(adom) ? 'global' : 'adom/' + adom.name
  }/obj${category ? `/${category.replace(/ +/g, '/')}` : ''}`;
}

function getAttrInused(schema: any) {
  return Object.keys(schema.attr || {});
}

function getSubobjAttrInused(schema: any, subobjKey: string) {
  return Object.keys(
    schema[subobjKey]?.attr || schema[subobjKey]?.schema || {}
  );
}

function getAttrFilterFn({
  schema,
  negate: negateFlag = false,
}: {
  schema: any;
  negate?: boolean;
}) {
  function identityPredicate<T>(value: T): boolean {
    return !!value;
  }
  const fn = negateFlag ? negate(identityPredicate) : identityPredicate;

  const attrInused = getAttrInused(schema);

  // for debugging
  // console.log('attrInused', attrInused);

  return ({ attr, subobjKey }: { attr: string; subobjKey: string }) => {
    if (!subobjKey) {
      return fn(attrInused.includes(attr));
    }

    const subobjAttrInused = getSubobjAttrInused(schema, subobjKey);
    // for debugging
    // console.log('subobjAttrInused', subobjAttrInused);
    return fn(subobjAttrInused.includes(attr));
  };
}

function getInusedFilterFn(schema: any) {
  return getAttrFilterFn({ schema });
}

function getAdvOptsFilterFn(schema: any) {
  return getAttrFilterFn({ schema, negate: true });
}

function getMatchedAttrs({
  syntax,
  attrMatch = stubTrue,
  filterFn = stubTrue,
}: {
  syntax: SyntaxVal;
  attrMatch: (attrSyntax: SyntaxAttr) => boolean;
  filterFn: ({ attr, subobjKey }: { attr: any; subobjKey?: string }) => boolean;
}) {
  const parentAttr = syntax.attr;
  const subobj = syntax.subobj || {};

  function getByAttrs({
    attrs,
    subobjKey,
  }: {
    attrs: SyntaxVal['attr'] | undefined;
    subobjKey?: string;
  }) {
    if (isNil(attrs)) {
      return {};
    }
    return transform(
      attrs,
      (
        result: { [attr: string]: SyntaxAttr },
        attrSyntax: SyntaxAttr,
        attr: string
      ) => {
        if (attrMatch(attrSyntax) && filterFn({ attr, subobjKey })) {
          result[attr] = attrSyntax;
        }
      },
      {}
    );
  }

  const matchedParentAttrs = getByAttrs({ attrs: parentAttr });

  const matchedSubobjAttrs = transform(
    subobj || {},
    (result: { [attr: string]: any }, subobjSyntax, subobjKey) => {
      const subobjAttrs = subobjSyntax.attr;
      result[subobjKey] = getByAttrs({ attrs: subobjAttrs, subobjKey });
    },
    {}
  );

  return {
    attr: matchedParentAttrs,
    subobj: matchedSubobjAttrs,
  };
}

/**
 * Get all single/multiple select fields that support meta
 */
function getMetaSupportSelectFields({
  syntax,
  filterFn,
}: {
  syntax: SyntaxVal;
  filterFn: ({ attr, subobjKey }: { attr: any; subobjKey?: string }) => boolean;
}) {
  return getMatchedAttrs({
    syntax,
    attrMatch: isMetaSupportSelect,
    filterFn,
  });
}

const getSyntaxValue = (
  syntaxObj: SyntaxVal,
  prop: string,
  defaultVal = null
) => {
  return get(syntaxObj, ['attr', prop], defaultVal);
};

/**
 * output reversed group-member relationship like
 *{
 *  'firewall address': ['firewall addrgrp']
 *}
 */
const genGrpMemberMap = (adomSyntax: AdomSyntax) => {
  const map: { [member: string]: string[] } = {};
  const getMembers = (objSyntax: AdomSyntax) => {
    const ref = get(objSyntax, 'attr.member.ref');
    if (!ref) {
      return;
    }
    return ref.map((r: any) => r.category);
  };
  const addToMap = (category: string, objSyntax: SyntaxVal) => {
    const members = getMembers(objSyntax);
    if (!members) {
      return;
    }
    members.forEach((member: string) => {
      map[member] = union(map[member], [category]);
    });
    return;
  };
  forEach(adomSyntax, (objSyntax, category) => addToMap(category, objSyntax));
  return map;
};
