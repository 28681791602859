export function saveBlob(blob, fileName) {
  if (navigator && navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    var a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

// multi-system may not have same \r\n sequence, [] caches all combinations.
const needsCsvEscapeRE = /[,"\n\r]/gi;

function escapeCellData(data) {
  data = '' + data;
  if (needsCsvEscapeRE.test(data)) {
    return '"' + data.replace(/"/g, '""') + '"'; // note that /g allows replace more than 1 item.
  } else {
    return data;
  }
}

// Create a csv file in memory and let user
// download it.
// `headers` is an array of column names (string).
// `rows` is an array of data.
// `getCellData` will be called with (row, col_name, idx), where row
// is an element from `rows`, and col_name is an element of `headers`.
// It must return string.
export function saveCSV(headers, rows, getCellData) {
  this.headers = headers;
  this.rows = rows;
  this.getCellData = getCellData;
}
saveCSV.prototype.getCsv = function () {
  var csv = '';
  function addRow(row) {
    if (csv) {
      csv += '\r\n';
    }
    csv += row;
  }
  addRow(
    this.headers
      .map(function (header) {
        return escapeCellData(header);
      }, this)
      .join(',')
  );

  this.rows.forEach(function (row, idx) {
    addRow(
      this.headers
        .map(function (header) {
          return escapeCellData(this.getCellData(row, header, idx));
        }, this)
        .join(',')
    );
  }, this);
  return csv;
};
saveCSV.prototype.getBlob = function () {
  return new Blob([this.getCsv()], { type: 'text/plain' });
};
saveCSV.prototype.saveFile = function (filename) {
  if (!filename) {
    filename = 'csv.csv';
  }
  saveBlob(this.getBlob(), filename);
};
