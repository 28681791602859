import { NwIconProps } from '@fafm/neowise-core';

export { ENABLE_ICON, DISABLE_ICON, ALLOW_ICON, BLOCK_ICON, MONITOR_ICON };

const ENABLE_ICON: NwIconProps = {
  library: 'fafm',
  name: 'enable',
  style: { color: 'rgb(var(--nw-color-success))' },
};

const DISABLE_ICON: NwIconProps = {
  library: 'fafm',
  name: 'no',
  style: { color: 'rgb(var(--nw-color-danger))' },
};

const ALLOW_ICON: NwIconProps = {
  name: 'yes',
  className: 'tw-text-success',
};

const BLOCK_ICON: NwIconProps = {
  name: 'disable',
  className: 'tw-text-danger',
};

const MONITOR_ICON: NwIconProps = {
  name: 'eye-open',
  className: 'tw-text-info',
};
