import { fiWorkspaceRequest, fiWorkspaceDataSelector } from 'fistore';

import * as common_fns from './common';
import {
  _currentAdom,
  _hasLockedByMe,
  _isLockedByMe,
  _isLockedByOther,
  _hasLocked,
  _isUnlock,
  _isLocked,
  _getLockMsg,
} from './util';
import { fiGetCateStrByNum } from 'macros/CategoryIDName';

export const objMapDispatchToProps = (dispatch) => {
  return {
    init: (cates, adom = _currentAdom()) => {
      cates = Array.isArray(cates) ? cates : [cates];
      cates.forEach((it) => {
        fiWorkspaceRequest.load_workspace_objects(
          dispatch,
          adom.oid || adom,
          it
        );
      });
    },
  };
};

export const objMapStateToProps = (cates) => {
  return (state) => {
    let objs = { get: getAllObjLockInfo };

    cates.forEach((it) => {
      objs[it] = fiWorkspaceDataSelector.get_workspace_adom_objs_by_cate_id(
        state,
        _currentAdom().oid,
        it
      );
    });

    return objs;
  };
};

export function getAllObjLockInfo() {
  let objs = {};
  Object.keys(this).forEach((it) => {
    if (it != 'get') {
      objs[it] = this[it];
    }
  });
  return {
    objs: objs,
    hasLocked: _hasObjLocked,
    hasLockedByMe: _hasObjLockedByMe,
    isLockedByMe: _isObjLockedByMe,
    isLockedByOther: _isObjLockedByOther,
    isLocked: _isObjLocked,
    isUnlock: _isObjUnlocked,
    getLockedObjs: _getLockedObjs,
    getObjsLockedByMe: _getObjLockedByMe,
    convertToUrlMap: _convertToUrlMap,
    lockMessage: _getLockMsg,
  };
}

export function getObjLockInfo(key, cate, adom = _currentAdom()) {
  return {
    obj: _selector_one_object(this.objs, key, cate, adom),
    ...common_fns,
    isLockedByMe: _objLockedByMe,
    isLockedByOther: _objLockedByOther,
    isUnlock: _objUnlocked,
    lockMessage: _getLockMsg,
  };
}

function _objLockedByMe() {
  return _isLockedByMe(this.obj);
}
function _objLockedByOther() {
  return _isLockedByOther(this.obj);
}
function _objUnlocked() {
  return _isUnlock(this.obj);
}

function _hasObjLocked() {
  return _hasLocked(this.objs);
}
function _hasObjLockedByMe() {
  return _hasLockedByMe(this.objs);
}
function _isObjLockedByMe(key) {
  return _isLockedByMe(this.objs[key]);
}
function _isObjLockedByOther(key) {
  return _isLockedByMe(this.objs[key]);
}
function _isObjLocked(key) {
  return _isLocked(this.objs[key]);
}
function _isObjUnlocked(key) {
  return _isUnlock(this.objs[key]);
}
function _getLockedObjs() {
  let data = this.objs || {};
  let arr = [];
  for (let prop in data) {
    if (_isLocked(data[prop])) {
      arr.push({
        oid: prop,
        ...data[prop],
      });
    }
  }

  return arr;
}
function _getObjLockedByMe() {
  let data = this.objs || {};
  let arr = [];
  for (let prop in data) {
    if (_isLockedByMe(data[prop])) {
      arr.push({
        oid: prop,
        ...data[prop],
      });
    }
  }

  return arr;
}

function _convertToUrlMap() {
  let data = this.objs || {};
  let urlMap = {};
  for (let prop in data) {
    let cateName = fiGetCateStrByNum(prop) || '';
    let url = cateName.replace(/ +/g, '/') + '/';
    let objsInCate = data[prop] ? data[prop].byId : null;

    if (objsInCate) {
      Object.keys(objsInCate).forEach((it) => {
        urlMap[url + it] = objsInCate[it];
      });
    }
  }

  return urlMap;
}

function _selector_one_object(data, key, cate, adom) {
  let path = (adom.oid || adom) + ':' + cate;
  let onekind = data[path] || {};

  return onekind.byId ? onekind.byId[key] : null;
}
