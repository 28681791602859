import React from 'react';
import cn from 'classnames';

import { NwIcon, NwButton } from '@fafm/neowise-core';

import './widgetIconButton.less';

export const WidgetIconButton = ({
  name,
  label,
  library = 'fafm',
  class: classes,
  ...rest
}) => {
  return (
    <NwButton
      {...rest}
      title={label}
      style={{ width: '1.85rem' }}
      className={cn('widget-icon-button', classes)}
    >
      <NwIcon name={name} label={label} library={library}></NwIcon>
    </NwButton>
  );
};
