import { cloneDeep } from 'lodash';

export { setLastDevCfgItem, getLastDevCfgItem };

let savedDevCfgItem = null;

const setLastDevCfgItem = (cfgitem) => {
  savedDevCfgItem = cloneDeep(cfgitem);
};

const getLastDevCfgItem = () => {
  return cloneDeep(savedDevCfgItem);
};
