export interface Observer {
  next: (data: any) => void;
  complete: () => void;
  error: (err: any) => void;
}

export enum UnSubCode {
  Complete = 'complete',
}

export interface Subscriber {
  (observer: Observer): (code?: UnSubCode) => void;
}

export interface ObservableInf {
  subscribe(observer: Observer): () => void;
}

export class Observable implements ObservableInf {
  constructor(private _subscriber: Subscriber) {}

  subscribe(observer: Observer): () => void {
    const observerWrap = new ObserverWrap(observer, this._subscriber);
    return function () {
      observerWrap.unsubscribe();
    };
  }
}

class ObserverWrap {
  observer: Observer;
  _unsubscribe: (code?: UnSubCode) => void;
  isUnsubed: boolean;
  constructor(
    observer: Observer = {
      next: (/*data*/) => {},
      complete: () => {},
      error: (/*err*/) => {},
    },
    subscriber: Subscriber
  ) {
    this.observer = observer;
    this._unsubscribe = subscriber(this);

    this.isUnsubed = false;
  }
  next(data: any) {
    if (this.isUnsubed || this.observer.next === undefined) return;

    try {
      this.observer.next(data);
    } catch (e) {
      this.unsubscribe();
      throw e;
    }
  }
  error(err: any) {
    if (this.isUnsubed || this.observer.error === undefined) return;

    try {
      this.observer.error(err);
    } catch (e) {
      this.unsubscribe();
      throw e;
    }
    this.unsubscribe();
  }
  complete() {
    if (this.isUnsubed || this.observer.complete === undefined) return;

    try {
      this.observer.complete();
    } catch (e) {
      this.unsubscribe();
      throw e;
    }
    this.unsubscribe(UnSubCode.Complete);
  }
  unsubscribe(code?: UnSubCode) {
    this.isUnsubed = true;
    if (this._unsubscribe) this._unsubscribe(code);
  }
}

class SharedObserver implements Observer {
  _observers: Array<Observer>;
  constructor() {
    this._observers = new Array<Observer>();
  }
  add(observer: Observer) {
    this._observers.push(observer);
  }
  remove(observer: Observer) {
    this._observers = this._observers.filter((x) => x != observer);
  }
  empty() {
    return this._observers.length == 0;
  }
  clear() {
    this._observers = new Array<Observer>();
  }
  next(data: any) {
    this._observers.forEach((x) => x.next(data));
  }
  complete() {
    this._observers.forEach((x) => x.complete());
  }
  error(err: any) {
    this._observers.forEach((x) => x.error(err));
  }
}
export class SharedObservable implements ObservableInf {
  _observer: SharedObserver;
  _subscribed: boolean;
  _unsub: () => void;
  constructor(private observable: Observable) {
    this._subscribed = false;
    this._observer = new SharedObserver();
    this._unsub = () => {};
  }
  subscribe(observer: Observer): () => void {
    this._observer.add(observer);
    if (!this._subscribed) {
      const unsub = this.observable?.subscribe(this._observer);
      this._unsub = () => {
        this._observer.remove(observer);
        if (this._observer.empty()) unsub();
      };
    }
    return () => this._unsub;
  }
  clear() {
    this._observer.clear();
    this._unsub();
    this._unsub = () => {};
  }
}
