/**
 * Accumulate several promises and resolve together once the time reaches.
 */
export const debouncePromise = <T, A extends any[]>(
  fn: (...args: A) => Promise<T>,
  ms: number
): ((...args: A) => Promise<T>) => {
  let timeoutId: NodeJS.Timeout | undefined;
  const pending: Array<{
    resolve: (value: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
  }> = [];

  return (...args: A): Promise<T> =>
    new Promise((resolve, reject) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        const currentPending = [...pending];
        // console.log('debounced:', currentPending.length);
        pending.length = 0;
        try {
          const ret = await fn.apply(this, args);
          currentPending.forEach(({ resolve }) => resolve(ret));
        } catch (err) {
          currentPending.forEach(({ reject }) => reject(err));
        }
      }, ms);
      pending.push({ resolve, reject });
    });
};

export function delay(num: number) {
  return new Promise((resolve) => setTimeout(resolve, num));
}
