import { fiFmgHttp } from 'fi-http';
import { escapeSlash } from 'kit/kit-regexp';
import { castArray, cloneDeep, isPlainObject, set } from 'lodash';

const BASE_URL = 'pm/fwmprof/adom/';

/*
 * Get fwmprof list or specify one object data
 * */
export function getFwmProf(adom, profName) {
  let adomName = adom.name || adom;
  let url = BASE_URL + adomName;
  let param = { url: url };
  if (profName) {
    param.filter = ['name', '==', profName];
  } else {
    param.sorting = [{ name: 1 }];
  }
  return fiFmgHttp
    .forward({
      method: 'get',
      params: [param],
    })
    .then((resp) => {
      let res = resp[0].data;
      if (profName) {
        return res[0];
      }
      return res;
    });
}

export function setFwmProf(adom, data, profName) {
  let adomName = adom.name || adom;
  let url = BASE_URL + adomName;
  let method = 'add';
  if (profName) {
    url += '/' + profName;
    method = 'update';
  }
  return fiFmgHttp.query({
    method: method,
    params: [
      {
        data: data,
        url: url,
      },
    ],
  });
}

export function setFwmProfScopeMembers({
  adom,
  scopeMembers,
  name,
  method = 'set',
}) {
  const adomName = adom.name || adom;
  const url = `${BASE_URL}${escapeSlash(adomName)}/${escapeSlash(
    name
  )}/scope member`;

  const data = castArray(scopeMembers)
    .filter(isPlainObject)
    .map((dev) =>
      dev.isGrp || dev.devMemberList
        ? { name: dev.name }
        : {
            name: dev._deviceName || dev.name,
            vdom: 'root',
          }
    );

  return fiFmgHttp.query({
    method: method,
    params: [
      {
        url,
        data,
      },
    ],
  });
}

export function addFwmProfScopeMembers({ adom, scopeMembers, name }) {
  return setFwmProfScopeMembers({
    adom,
    scopeMembers,
    name,
    method: 'add',
  });
}

export function deleteFwmProfScopeMembers({ adom, scopeMembers, name }) {
  return setFwmProfScopeMembers({
    adom,
    scopeMembers,
    name,
    method: 'delete',
  });
}

export function deleteFwmProf(adom, keys) {
  if (!keys || keys.length === 0) {
    return Promise.reject({ message: 'No entries to delete' });
  }
  let adomName = adom.name || adom;
  let url = BASE_URL + adomName;
  return fiFmgHttp.query({
    method: 'delete',
    params: keys.map((key) => ({
      // 769303: escape forward slash '/'
      url: url + '/' + key.split('/').join('\\/'),
    })),
  });
}

export function removeFwmProfUpgradeSchedule({ adom, data }) {
  const newData = cloneDeep(data);
  const profName = newData.name;
  set(newData, ['settings', 'schedule-type'], MACROS.PM2CAT.PM2_SCHED_NONE);
  set(newData, ['settings', 'schedule-day'], null);
  set(newData, ['settings', 'schedule-start-time'], null);
  set(newData, ['settings', 'schedule-end-time'], null);
  return setFwmProf(adom, newData, profName);
}

export function manualUpgrade(adom, fwmprofName) {
  let req = {
    id: 1,
    method: 'exec',
    params: [
      {
        data: {
          adom: adom.name || adom,
          name: fwmprofName,
        },
        url: 'um/image/template/upgrade',
      },
    ],
  };
  return fiFmgHttp.query(req).then((resp) => resp[0].data);
}

export function upgradePreview(adom, fwmprofName) {
  let req = {
    id: 1,
    method: 'exec',
    params: [
      {
        data: {
          adom: adom.name || adom,
          name: fwmprofName,
        },
        url: 'um/image/template/preview',
      },
    ],
  };
  return fiFmgHttp.query(req).then((resp) => resp[0].data);
}

export function upgradeReport(adom, fwmprofName) {
  let req = {
    id: 1,
    method: 'exec',
    params: [
      {
        data: {
          adom: adom.name || adom,
          name: fwmprofName,
        },
        url: 'um/image/template/report',
      },
    ],
  };
  return fiFmgHttp.query(req).then((resp) => resp[0].data);
}
