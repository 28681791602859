class RegExpFn {
  readonly EMAIL_ADDRESS: RegExp;
  readonly DOMAIN: RegExp;
  readonly IP_HOST: RegExp;
  readonly IP_MASK: RegExp;
  readonly IP_RANGE: RegExp;
  readonly MAC_ADDRESS: RegExp;
  readonly MAC_RANGE: RegExp;
  readonly MAC_MASK: RegExp;
  readonly MAC_MASK_AND_RANGE: RegExp;
  readonly IP_SUBNET: RegExp;
  readonly IP_WILDCARD: RegExp;
  readonly IP_PORT: RegExp;
  readonly INTEGER: RegExp;
  readonly HEX_INTEGER: RegExp;
  readonly NAME: RegExp;
  readonly RESTRICT_NAME: RegExp;
  readonly RESTRICT_NAME_INCLUDE_DOT: RegExp;
  readonly RESTRICT_NAME_INCLUDE_DOT_AT_DOLLAR: RegExp;
  readonly RESTRICT_NAME_INCLUDE_SPACE: RegExp;
  readonly RESTRICT_NAME_INCLUDE_SPACE_SLASH: RegExp;
  readonly XSS: RegExp;
  readonly RANGE_INT: RegExp;
  readonly TIME: RegExp;
  readonly DATE: RegExp;
  readonly IP_RANGE2: RegExp;
  readonly FQDN: RegExp;
  readonly IPv6: RegExp;
  readonly IPv6_RANGE: RegExp;
  readonly IPv6_MASK: RegExp;
  readonly URL: RegExp;

  constructor() {
    // E-mail address (ie. username@host)
    this.EMAIL_ADDRESS =
      /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    // domain (ie. mydomain.com)
    this.DOMAIN = /^([a-zA-Z0-9-])+\.+([a-zA-Z0-9]{2,4})+$/;

    // Host (ie. 1.2.3.4)
    this.IP_HOST =
      /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;

    // Mask (ie. 255.255.255.0)
    this.IP_MASK =
      /^(((255|254|252|248|240|224|192|128|0)\.0\.0\.0)|(255\.(255|254|252|248|240|224|192|128)\.0\.0)|(255\.255\.(255|254|252|248|240|224|192|128)\.0)|(255\.255\.255\.(255|254|252|248|240|224|192|128|0)))$/;

    // Range (IP_HOST-IP_HOST)
    this.IP_RANGE =
      /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(-(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])|-((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])|\/(3[0-2]|[1-2][0-9]|[0-9]))?$/;

    // MAC address (ie. xx:xx:xx:xx:xx:xx)
    this.MAC_ADDRESS =
      /^[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}$/;

    this.MAC_RANGE =
      /^([0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2})([ ]*-[ ]*([0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}))?$/;

    this.MAC_MASK =
      /^[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}$/;

    this.MAC_MASK_AND_RANGE =
      /(^([0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2})([ ]*-[ ]*([0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}))?$)|(^[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}:[0-9a-fA-F\\*]{2}$)/;

    // IP subnet (ie. 172.16.79.19/24 or 172.16.79.19/255.255.255.0)
    this.IP_SUBNET =
      /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\/((((254|252|248|240|224|192|128|0)\.0\.0\.0)|(255\.(254|252|248|240|224|192|128|0)\.0\.0)|(255\.255\.(254|252|248|240|224|192|128|0)\.0)|(255\.255\.255\.(255|254|252|248|240|224|192|128|0))|(3[0-2]|2[0-9]|1[0-9]|[0-9])))$/;

    // IP subnet (ie. 172.16.79.19/15 or 172.16.79.19/1.2.3.0)
    this.IP_WILDCARD =
      /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\/(((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])|(3[0-2]|2[0-9]|1[0-9]|[0-9]))$/;

    // IP & port (ie. 172.16.79.19:65535)
    this.IP_PORT =
      /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]):[0-9]+$/;

    // integer (0-9)
    this.INTEGER = /^[-]?[0-9]+$/;

    // hex integer (0-F/0-f)
    this.HEX_INTEGER = /^[0-9A-Fa-f]+$/;

    // name (no invalid characters)
    this.NAME = /^[^<>#()"']+$/;

    // restrict name (no invalid characters)
    this.RESTRICT_NAME = /^[0-9a-zA-Z_-]+$/;

    this.RESTRICT_NAME_INCLUDE_DOT = /^[0-9.a-zA-Z_-]+$/;

    this.RESTRICT_NAME_INCLUDE_DOT_AT_DOLLAR = /^[0-9.@$a-zA-Z_-]+$/;

    this.RESTRICT_NAME_INCLUDE_SPACE = /^[0-9 a-zA-Z_-]+$/;

    this.RESTRICT_NAME_INCLUDE_SPACE_SLASH = /^[0-9 a-zA-Z_\-/]+$/;

    // cross scripting characters
    this.XSS = /^[^<>()#'"]*$/;

    // integer range
    this.RANGE_INT = /^[0-9]*-[0-9]+$|^([0-9]+-?)$/;

    // time
    this.TIME = /^(([0-1]?[0-9]|[2][0-3]):([0-5]?[0-9]):([0-5]?[0-9]))?$/;

    // date
    this.DATE = /^([0-9]{4}-([0]?[0-9]|[1][0-2])-([0-2]?[0-9]|3[0-1]))?$/;

    // Range2 (ie, 1.2.3.[1-10])
    this.IP_RANGE2 =
      /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}\[(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])-(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\]/;

    // fully qualified domain name
    this.FQDN = /^([a-zA-Z0-9-]{1,63}\.?)+$/;

    // IPv6
    this.IPv6 =
      /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;

    // IPv6 CIDR range
    this.IPv6_RANGE =
      /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*(\/(\d|\d\d|1[0-1]\d|12[0-8]))$/;

    this.IPv6_MASK =
      /^(((ffff:){1,6}(ffff|fffe|fffc|fff8|fff0|ffe0|ffc0|ff80|ff00|fe00|fc00|f800|f000|e000|c000|8000|0{1,4})::)|((ffff:){7}(ffff|fffe|fffc|fff8|fff0|ffe0|ffc0|ff80|ff00|fe00|fc00|f800|f000|e000|c000|8000|0{1,4}))|((ffff|fffe|fffc|fff8|fff0|ffe0|ffc0|ff80|ff00|fe00|fc00|f800|f000|e000|c000|8000)::))$/;

    this.URL =
      /^(?:(ftp|http(s)?):\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;
  }
}

export const RegExpCommon = new RegExpFn();
