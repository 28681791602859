import { dvmConfigRoutes } from './routes_dvm_config';

export const dvmMainRoute = {
  path: 'main',
  lazy: () =>
    import(/* webpackChunkName: "mod-dvm-main" */ 'react_apps/ra_dvm/DvmMain'),
  handle: {
    appUniKey: 'dvm_device_list',
  },
  children: [
    // FMG DVM Routes
    {
      // use this route to redirect to groups/:groupId or other views and make sure appUniKey is set correctly
      path: 'groups',
      handle: {
        appUniKey: 'dvm_device_group_table',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-device-list" */ 'react_apps/ra_dvm/dvm_table/FmgDvmTableMain'
        ),
    },
    {
      path: 'groups/:groupId',
      handle: {
        appUniKey: 'dvm_device_group_table',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-device-list" */ 'react_apps/ra_dvm/dvm_table/FmgDvmTableMain'
        ),
    },

    // DVM Config
    ...dvmConfigRoutes,

    // Other DVM Views
    {
      path: 'ring/:dashId',
      handle: {
        appUniKey: 'dvm_device_ring',
      },
      loader: async () => {
        const { Layout } = await import('react_apps/ra_noc/dashboard/Layout');
        return { Layout };
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-ring-view" */ './other_views/DvmRingView'
        ),
    },
    {
      path: 'folder',
      handle: {
        appUniKey: 'dvm_device_folder',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-folder-view" */ './other_views/DvmFolderView'
        ),
    },
    {
      path: 'map',
      handle: {
        appUniKey: 'dvm_device_map',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-map-view" */ './other_views/DvmMapView'
        ),
    },

    // FAZ DVM Routes
    {
      // use this route to redirect to fazgroups/:groupId or other views and make sure appUniKey is set correctly
      path: 'fazgroups',
      handle: {
        appUniKey: 'fazdvm_group_table',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-device-list" */ 'react_apps/ra_dvm/dvm_table/FazDvmTableMain'
        ),
    },
    {
      path: 'fazgroups/:groupId',
      handle: {
        appUniKey: 'fazdvm_group_table',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-device-list" */ 'react_apps/ra_dvm/dvm_table/FazDvmTableMain'
        ),
    },
    {
      path: 'fazmap',
      handle: {
        appUniKey: 'fazdvm_map',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-map-view" */ './other_views/FazDvmMapView'
        ),
    },
  ],
};
