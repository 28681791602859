import { ProToolkit } from '@fafm/neowise-pro';
import { flow, isArray, isObject, isString, noop } from 'lodash';
import { isValidElement, useEffect } from 'react';
import { NwIcon } from '@fafm/neowise-core';
import './message_box.less';

export enum BoxType {
  success = 'success',
  danger = 'danger',
  info = 'info',
  warning = 'warning',
}
/** use type instead of enum to prevent breaking existing ts usage. */
type MessageType = 'success' | 'danger' | 'info' | 'warning';

export function showSuccessMessage(content: React.ReactNode) {
  return fiMessageBox.show(content, BoxType.success);
}

export function showErrorMessage(
  error: Parameters<typeof fiErrorMessage.show>[0]
) {
  return fiErrorMessage.show(error);
}

export function showWarningMessage(content: React.ReactNode) {
  return fiMessageBox.show(content, BoxType.warning);
}

export function showInfoMessage(content: React.ReactNode) {
  return fiMessageBox.show(content, BoxType.info);
}

export const fiMessageBox = {
  show: _show,
  clearAll: _clearAll,
};

export const fiErrorMessage = {
  show: _showErrorMessage,
};

const emptyMethods = {
  open: () => console.error('No MessageBoxRoot'),
  close: () => console.error('No MessageBoxRoot'),
  destroy: () => console.error('No MessageBoxRoot'),
};

const globalInstance: {
  open: (
    config: Partial<{
      key: React.Key;
      content: React.ReactNode;
      closeIcon: React.ReactNode;
      duration: number | null;
      type: string | null;
      closable: boolean;
      placement: 'top';
    }>
  ) => void;
  close: (key: React.Key) => void;
  destroy: () => void;
} = { ...emptyMethods };

export const MessageBoxRoot: React.FunctionComponent = () => {
  const [methods, context] = ProToolkit.useNotification({
    enableContextHolder: true,
  });

  useEffect(() => {
    Object.assign(globalInstance, methods);
    return () => {
      Object.assign(globalInstance, emptyMethods);
    };
  }, [methods]);

  return context;
};

let uniqueKey = 1;

function _show(
  content: React.ReactNode,
  boxType: MessageType = BoxType.success,
  timeoutMs: number | null = 5000
): VoidFunction {
  if (!content) return noop;

  const selfKey = uniqueKey++;

  globalInstance.open({
    key: selfKey,
    closable: true,
    placement: 'top',
    content: (() => {
      return (
        <div automation-id={`${boxType}-message-box`}>
          <ProToolkit.ErrorBoundary>{content}</ProToolkit.ErrorBoundary>
        </div>
      );
    })(),
    closeIcon: (() => {
      return (
        <div className='tw-contents' automation-id={`${boxType}-message-close`}>
          <NwIcon library='fa-solid' name='times' />
        </div>
      );
    })(),
    type: (() => {
      switch (boxType) {
        case BoxType.success:
          return 'success';
        case BoxType.warning:
          return 'warning';
        case BoxType.danger:
          return 'danger';
        default:
          return 'neutral';
      }
    })(),
    duration: flow(
      function boxTypeToTimeoutMs() {
        switch (boxType) {
          case BoxType.danger:
            return null;
          case BoxType.warning:
            return 5000;
          case BoxType.success:
            return 2000;
          default:
            return timeoutMs;
        }
      },
      function msToDuration(ms: typeof timeoutMs) {
        return typeof ms === 'number' ? ms / 1000 : ms;
      }
    )(),
  });

  return () => {
    globalInstance.close(selfKey);
  };
}

function _clearAll() {
  globalInstance.destroy();
}

type Error = Partial<{ message: unknown }>;
type Errors = Partial<{ errors: unknown }>;
type ExtraErrors = Partial<{ extra_errors: Partial<{ all: unknown }> }>;
type ResponseError = Error & Errors & ExtraErrors;

function renderContentFromError(
  data: string | ResponseError | Array<unknown>,
  options?: { avoidProperties: string[] }
) {
  const defaultOptions: typeof options = {
    avoidProperties: [],
  };

  const condition = Object.assign({}, defaultOptions, options);

  function isBlacklisted(prop: string) {
    return condition.avoidProperties.indexOf(prop) > -1;
  }

  function render(item: unknown): string | null | React.ReactElement {
    if (isString(item)) {
      return item;
    } else if (isArray(item)) {
      return renderArray(item);
    } else if (isObject(item)) {
      return renderObj(item);
    } else {
      return '' + item;
    }
  }

  function renderArray(arr: unknown[]) {
    const children = arr
      .map((value, idx) => {
        const content = render(value);
        return content ? <li key={idx}>{content}</li> : null;
      })
      .filter(Boolean);

    return children.length ? (
      <ul className='tw-mb-0 tw-pl-0'>{children}</ul>
    ) : null;
  }

  function renderObj(obj: object) {
    const children = Object.entries(obj)
      .map(([key, value]) => {
        const content = !isBlacklisted(key) && render(value);
        return content ? (
          <li key={key}>
            {`${key}: `}
            {content}
          </li>
        ) : null;
      })
      .filter(Boolean);

    return children.length ? (
      <ul className='tw-mb-0 tw-pl-0'>{children}</ul>
    ) : null;
  }

  if (isString(data)) {
    return render(data);
  } else if (isArray(data)) {
    return render(data);
  } else if (isObject(data)) {
    if (data.message && !isBlacklisted('message')) {
      return render(data.message);
    }
    if (data.errors && !isBlacklisted('errors')) {
      return render(data.errors);
    }
    if (
      !isBlacklisted('extra_errors') &&
      !isBlacklisted('all') &&
      data.extra_errors &&
      data.extra_errors.all
    ) {
      return render(data.extra_errors.all);
    }
  }
}

function _showErrorMessage(
  content:
    | React.ReactElement<unknown>
    | Parameters<typeof renderContentFromError>[0]
) {
  const result = isValidElement(content)
    ? content
    : renderContentFromError(content);

  if (!result) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('No message');
    }
  }

  return fiMessageBox.show(result, BoxType.danger);
}
