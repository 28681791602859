import { useStateRef } from './useStateRef';

export const useTabs = ({ initTabs, idAttr, maxTabs = 5 }) => {
  const [tabs, setTabs, tabsRef] = useStateRef(initTabs);

  return {
    getAll: () => {
      return tabs;
    },
    getCurrent() {
      // return latest value
      return tabsRef.current;
    },
    get: (id) => {
      return tabs.find((tab) => tab[idAttr] === id);
    },
    add: (newTab) => {
      if (tabs.find((tab) => tab[idAttr] === newTab[idAttr])) {
        return;
      }

      if (tabs.length === maxTabs) {
        setTabs((prev) => {
          return [...prev.slice(1), newTab];
        });
      }

      setTabs((prev) => {
        return [...prev, newTab];
      });

      // return latest value
      return tabsRef.current;
    },
    remove: (id) => {
      setTabs((prev) => {
        return prev.filter((tab) => tab[idAttr] !== id);
      });

      // return latest value
      return tabsRef.current;
    },
    clear: () => {
      setTabs([]);

      // return latest value
      return tabsRef.current;
    },
  };
};
