/*
api for device and policy installation
*/

import { fiWebSocket } from 'fi-websocket';
import { fiFmgHttp } from 'fi-http';
import { fiMessageBox } from 'fi-messagebox';
import $ from 'jquery';

function sendReqToWebsocket(wsReq, method) {
  const defer = $.Deferred();
  const _fdl_status = {};

  fiWebSocket.send(fiWebSocket.genWSRequest(wsReq, null, method)).then(
    function () {
      defer.resolve();
    },
    function (err) {
      defer.reject(err);
    },
    function ({ chunk }) {
      if (chunk) {
        if (chunk.header) {
          // update predicate
          _fdl_status.total = chunk.header.totalRecords || 0;
        } else if (chunk.tail) {
          // update real total
          _fdl_status.total = chunk.tail.totalRecords;
          defer.notify(chunk);
        } else {
          // From Jin Fang: some models can have data in `chunk` instead of `chunk.data`
          const chunkData = chunk.data || chunk || [];
          if (chunkData?.error) {
            fiMessageBox.show(chunkData.error, 'danger');
            defer.reject(chunkData?.error);
            return defer.promise();
          }
          // notify chunk
          defer.notify(chunk);
        }
      }
    }
  );
  return defer.promise();
}
/* parameter devices is object array with attrs "oid" "vdomoids" and if is global
/*    "devices": [{
/*      "oid": 231,
/*      "vdomOids": [3, 101],
/*      "glb": true //device global
/*     }]
*/
//the last paramers is always the device list.
function getDevSettingsPreview(adom_oid, devices) {
  const wsReq = {
    url: '/gui/installation/adoms/%s/config/preview'.printf([adom_oid]),
    method: 'create',
    params: {
      devices: devices,
    },
  };
  return sendReqToWebsocket(wsReq, 'POST');
}
//the last paramers is always the device list.
function getPkgNDevSettingsPreview(adom_oid, pkg_oid, devices) {
  const wsReq = {
    url: '/gui/installation/adoms/%s/pkgs/%s/preview'.printf([
      adom_oid,
      pkg_oid,
    ]),
    method: 'create',
    params: {
      devices: devices,
    },
  };
  return sendReqToWebsocket(wsReq, 'POST');
}

//the last paramers is always the device list.
/**
 * Preview Objects which will be installed to the given devices
 * @param {*} adom_oid
 * @param {Object[]} objects
 * @param {Number} objects.cate - category number of the object
 * @param {String} objects.key - object key
 * @param {*} devices
 * @returns
 */
function getInstallObjsPreview(adom_oid, objects, devices) {
  // devices = [{oid: 1,vdomOids: [3]}]
  const wsReq = {
    url: '/gui/installation/adoms/%s/objects/preview'.printf([adom_oid]),
    method: 'create',
    params: {
      devices: devices,
      objects,
      flags: MACROS.DVM.SECURITY_CONSOLE_INST_F_OBJS_ONLY, // flag for object partial install
    },
  };
  return sendReqToWebsocket(wsReq, 'POST');
}

//the last paramers is always the device list.
function getInstallLog(adom_oid, devices) {
  // devices = [{oid: 1,vdomOids: [3]}]
  return fiFmgHttp
    .post({
      url: '/gui/installation/adoms/%s/log'.printf([adom_oid]),
      method: 'get',
      params: {
        devices: devices,
      },
    })
    .then(function (resp) {
      let data = [];
      try {
        data = resp[0].data;
        // eslint-disable-next-line
      } catch (err) {}
      return data;
    });
}

export const fiDeployUtil = {
  getDevSettingsPreview: getDevSettingsPreview,
  getPkgNDevSettingsPreview: getPkgNDevSettingsPreview,
  getInstallObjsPreview,
  getInstallLog: getInstallLog,
};
