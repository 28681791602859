import React from 'react';
import { useWizard } from 'rc_wizard';
import { fiErrorMessage } from 'fi-messagebox';
import { NwProFooter, OkBtn, CancelBtn } from 'rc_layout';
import { FormikConsumer } from 'formik';

export const Template = ({
  $opener,
  FirmwareTemplateEditDrawerWrapper,
  setNewTemplateData,
  editParams,
}) => {
  const wizardCtx = useWizard();
  const customOnSubmit = async (nData) => {
    try {
      setNewTemplateData(nData);
      wizardCtx.goToNext();
    } catch (e) {
      fiErrorMessage.show(e);
    }
  };

  return (
    <FirmwareTemplateEditDrawerWrapper
      editParams={editParams}
      $opener={$opener}
      closeOnSubmit={false}
      customOnSubmit={customOnSubmit}
      showHeader={false}
      CustomFooter={CustomFirmwareTemplateEditFooter}
    />
  );
};

const CustomFirmwareTemplateEditFooter = ({ _wpermit, handleCancel }) => {
  return (
    <NwProFooter>
      <FormikConsumer>
        {({ submitForm, isSubmitting }) => {
          return _wpermit ? (
            <>
              <OkBtn onClick={submitForm} loading={isSubmitting}>
                {gettext('Next >')}
              </OkBtn>
              <CancelBtn onClick={handleCancel}>{gettext('Cancel')}</CancelBtn>
            </>
          ) : (
            <CancelBtn onClick={handleCancel}>{gettext('Cancel')}</CancelBtn>
          );
        }}
      </FormikConsumer>
    </NwProFooter>
  );
};
