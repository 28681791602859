import React, { cloneElement, isValidElement } from 'react';
import { compose } from '@fafm/fp';
import { node_is_dev, node_is_grp, node_is_vdom } from './node_type';
import { make_highlighter } from '../common/util';
import { NwIcon } from '@fafm/neowise-core';
import { convertRemToPx } from 'fiutil';
import { renderIcon } from 'ra-render-util';
import { castArray, isFunction } from 'lodash';
import cn from 'classnames';
import { ProLego } from '@fafm/neowise-pro';
import { fiDvmTableCellParser } from 'fi-dvm';
import { getLockTimeStr } from 'fi-apps/fi-workspace';

/******************************************* */
const node_key = (record) => record.node.key;
const node_isLeaf = (record) => record.node.isLeaf();
const node_isOpen = (record) => record.isOpen;
const node_deepLevel = (record) => record.node.deepth || 0;
const node_name = (record) => {
  if (!record) return 'node';
  return record.node.getName();
};
const node_showMemberNumber = (record) => {
  return record.node.showMemberNumber && record.node.showMemberNumber();
};
const node_membNumber = (record) => record.node.getMembNumber();
const node_isSelected = (data, key) => data.selected == key;
export const node_indent = (node) => {
  const deepth = node.deepth;
  const baseIndent = 13; // to align with sort button at the top
  const toggleBtnWidth = convertRemToPx(1.25); // toggle button 1rem, padding right 0.25rem
  return baseIndent + deepth * toggleBtnWidth;
};
const node_showToggleIcon = (record) => record.node.showToggleIcon();

export const isNodeDisabled = (node) => {
  if (!node) return false;

  const { deepth, parentNode, getIsDisabled } = node;
  if (deepth > 1 && parentNode && parentNode.getIsDisabled) {
    return parentNode.getIsDisabled();
  }

  return getIsDisabled ? getIsDisabled() : false;
};

const node_getParentNode = (record) => {
  const fn = record.node.getParentNode;
  if (isFunction(fn)) return fn();
};

const getRecord = (data, index) => data.records[data.order[index]];
const getAutoId = (data, key) =>
  key
    .split(':')
    .reduce((acc, cur) => {
      const temp = acc.length === 0 ? cur : acc[acc.length - 1] + ':' + cur;
      acc.push(temp);
      return acc;
    }, [])
    .map((k) => {
      return node_name(data.records[k]);
    })
    .join('-');

export const renderToggleIcon = ({ toggle, isOpen }) => (
  <div
    tabIndex='-1'
    className='tw-mr-1 toggle-button'
    onClick={(e) => {
      e.stopPropagation();
      toggle();
    }}
  >
    <NwIcon name={isOpen ? 'tree-open' : 'tree-collapsed'} />
  </div>
);

//eslint-disable-next-line
export const make_tree_node =
  (rowContent) =>
  ({
    data,
    record,
    highlight,
    style,
    key,
    isLeaf,
    isSelected,
    isOpen,
    indent,
    showToggleIcon,
    onToggle,
    onSelect,
    isDisabled,
  }) => {
    const className = [
      'node',
      isLeaf ? 'leaf' : 'parent',
      isSelected ? 'selected' : '',
      isLeaf || isOpen ? '' : 'collapsed',
      isDisabled ? 'node-disabled' : '',
    ];

    return (
      <div
        tabIndex={'-1'}
        className={className.join(' ')}
        style={style}
        key={key || node_key(record)}
        automation-id={getAutoId(data, key)}
        onClick={(e) => {
          if (isDisabled) return;
          e.preventDefault();
          onSelect();
        }}
      >
        <span className={'indent'} style={{ width: indent }}></span>
        {!isLeaf && showToggleIcon ? (
          renderToggleIcon({ toggle: onToggle, isOpen: isOpen })
        ) : node_is_dev(record) ? (
          // #1036114: placeholder for alignment
          <NwIcon name='empty' />
        ) : null}
        {rowContent(record, highlight)}
      </div>
    );
  };

//eslint-disable-next-line
export const make_node_list =
  (rowContent) =>
  ({
    data,
    record,
    highlight,
    style,
    key,
    isLeaf,
    isSelected,
    isOpen,
    indent,
    onToggle,
    onSelect,
  }) => {
    const className = [
      'node',
      isLeaf ? 'leaf' : 'parent',
      isSelected ? 'selected' : '',
    ];

    return (
      <div
        tabIndex={'-1'}
        className={className.join(' ')}
        style={style}
        key={key}
        automation-id={getAutoId(data, key)}
      >
        <span className={'indent'} style={{ width: indent }}></span>
        {!isLeaf &&
          record.node.type !== 'grp' &&
          renderToggleIcon({ toggle: onToggle, isOpen: isOpen })}
        <div
          onClick={(e) => {
            e.preventDefault();
            onSelect();
          }}
        >
          {rowContent(record, highlight)}
        </div>
      </div>
    );
  };

/********************************************* */
const locker_lockBySelf = (locker) => locker.lock === 1;
const locker_title = (locker) => {
  const user = locker_lockBySelf(locker) ? 'me' : locker.lock_user;
  const time = getLockTimeStr(locker);
  return gettext('Locked by %(user)s at %(time)s').printfd({ user, time });
};
const locker_icon = (locker) => {
  return locker_lockBySelf(locker)
    ? {
        name: 'lock',
        className: 'color-green',
      }
    : { name: 'lock', className: ' color-red' };
};

const renderCNFDeviceIcon = ({ data }) => {
  if (!data?.iscnf) return null;
  return (
    <ProLego.Tooltip
      content={gettext(
        'This device belongs to CNF (Cloud Network Firewall - Fortinet hosted firewall-as-a-service).'
      )}
    >
      {renderIcon({
        name: 'online-help',
        className: 'color-info cnf-device-icon tw-h-full',
      })}
    </ProLego.Tooltip>
  );
};

const renderConnectStatus = ({ key, data }) => {
  let { css, ititle: title } = fiDvmTableCellParser.getDeviceCSS(data);
  return renderIcon(css, { label: title, key: key + '-' + 'connection' });
};

const renderLockStatus = ({ key, getLocker }) => {
  const locker = getLocker();
  if (!locker) return null;

  const iconProps = locker_icon(locker);
  return renderIcon(iconProps, {
    key: key + '-' + 'lock',
    label: locker_title(locker),
  });
};

const renderNodeIcon = (node) => {
  if (node_is_grp(node)) {
    return null;
  } else if (node_is_vdom(node)) {
    return renderIcon({ name: 'vdom', className: 'color-grey' });
  } else if (node_is_dev(node)) {
    return [
      renderLockStatus(node),
      renderCNFDeviceIcon(node),
      renderConnectStatus(node),
    ].filter((data) => !!data);
  }
  return null;
};

const renderNodeType = (node) => {
  const nodeIcon = renderNodeIcon(node);
  if (!nodeIcon) return null;

  const makeNodeIconProps = (iconType, iconProps, needReplace = true) => {
    return {
      class:
        iconType === NwIcon
          ? cn(iconProps?.class, needReplace ? 'node-icon' : '')
          : undefined,
      className:
        iconType === NwIcon
          ? cn(iconProps?.className, needReplace ? 'node-icon' : '')
          : undefined,
    };
  };

  // add node-icon class to each icon component
  return (
    <div className='tw-flex tw-h-full tw-gap-1 tw-mr-1'>
      {castArray(nodeIcon).map((iconCompt, index) => {
        if (!isValidElement(iconCompt)) return null;

        const { type: iconType, props: _iconProps } = iconCompt;
        const key = `${index}-${_iconProps.name || _iconProps.title}`;
        const iconProps = { key, ..._iconProps };
        if (iconType === 'span' && iconProps?.children?.length > 1) {
          return cloneElement(
            iconCompt,
            iconProps,
            iconProps.children.map((child, index) => {
              const { type: cIconType, props: cIconProps } = child;
              return cloneElement(
                child,
                makeNodeIconProps(cIconType, cIconProps, index === 0)
              ); //only replace class for first icon
            })
          );
        }

        return cloneElement(iconCompt, {
          key,
          ...makeNodeIconProps(iconType, iconProps),
        });
      })}
    </div>
  );
};

const renderNodeText = (text, highlight = (it) => it) => {
  return (
    <span className={'node-icon-text'} title={text}>
      {highlight(text)}
    </span>
  );
};

const make_node =
  (children) =>
  ({ index, data, style } = {}) => {
    const record = getRecord(data, index);

    const highlight = make_highlighter(data.searchText);

    //eslint-disable-next-line
    const nodeProps = {
      record: record,
      data: data,
      highlight,
      style: Object.assign({}, style, {
        alignItems: 'center',
        display: 'flex',
      }),
      key: node_key(record),
      isLeaf: node_isLeaf(record),
      showToggleIcon: node_showToggleIcon(record),
      isSelected: node_isSelected(data, node_key(record)),
      isOpen: node_isOpen(record),
      indent: node_indent(record.node),
      deepth: node_deepLevel(record),
      onToggle: () => data.onToggle(record),
      onSelect: () => data.onSelect(record),
      isDisabled: isNodeDisabled(record.node),
      parentNode: node_getParentNode(record),
    };

    return children(nodeProps);
  };

const treeNodeContent = (record, highlight) => {
  return (
    <>
      {renderNodeType(record.node)}
      {renderNodeText(
        node_name(record) +
          (!node_showMemberNumber(record)
            ? ''
            : ' (' + node_membNumber(record) + ')'),
        highlight
      )}
    </>
  );
};

const listNodeContent = (record, highlight) => {
  return (
    <>
      {renderNodeType(record.node)}
      {renderNodeText(
        node_name(record) +
          (node_isLeaf(record) ? '' : ' (' + node_membNumber(record) + ')'),
        highlight
      )}
    </>
  );
};

export const TreeNode = (nodeMenu) =>
  compose(make_node, nodeMenu, make_tree_node)(treeNodeContent);
export const ListNode = (nodeMenu) =>
  compose(make_node, nodeMenu, make_node_list)(listNodeContent);
