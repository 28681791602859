import { isValidElement, cloneElement } from 'react';
import cn from 'classnames';
import { NwIcon } from '@fafm/neowise-core';
import { identity } from 'lodash';

export {
  renderIcon,
  renderLabel,
  renderGreyOutTableRow,
  renderEnabledIcon,
  renderDisabledIcon,
};

function renderIcon(icon, props) {
  if (!icon) return null;

  if (Array.isArray(icon)) {
    // render stacked icons
    return (
      <span className='nw-stack'>
        {icon.map((icn, idx, arr) => {
          if (idx === arr.length - 1) {
            if (!(icn?.className || '').includes('nw-stack-br')) {
              icn.className = cn(icn.className, 'nw-stack-br');
            }
            return <NwIcon key={icn.name} {...icn}></NwIcon>;
          } else {
            return <NwIcon key={icn.name} {...icn} {...props}></NwIcon>;
          }
        })}
      </span>
    );
  }
  if (icon.name) {
    return <NwIcon {...icon} {...props}></NwIcon>;
  } else if (typeof icon === 'string') {
    // deprecated
    return <span className={icon} {...props}></span>;
  } else if (isValidElement(icon)) {
    if (!props) return icon;
    return cloneElement(icon, props);
  }
  return null;
}

function renderLabel(label, props) {
  if (typeof label === 'string') {
    return label;
  } else if (isValidElement(label)) {
    if (!props) return label;
    return cloneElement(label, props);
  }
  return null;
}

function renderGreyOutTableRow(tableCells) {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        opacity: 'var(--nw-disabled-opacity)',
      }}
    >
      {tableCells}
    </div>
  );
}

function renderEnabledIcon({
  label = gettext('Enabled'),
  highlighter = identity,
} = {}) {
  return (
    <div className='tw-flex tw-items-center tw-gap-1'>
      <NwIcon library='fafm' name='enable' className='color-green' />
      {highlighter(label)}
    </div>
  );
}

function renderDisabledIcon({
  label = gettext('Disabled'),
  highlighter = identity,
} = {}) {
  return (
    <div className='tw-flex tw-items-center tw-gap-1'>
      <NwIcon library='fafm' name='disable' className='color-red' />
      {highlighter(label)}
    </div>
  );
}
