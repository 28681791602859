import { useEffect, useMemo, useRef, useState } from 'react';
import fiWebSocketLocalAddon from 'terminal/fiWebSocketLocalAddon';
import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import FitToContainerAddon from 'terminal/fitToContainerAddon';
import fiWebSocketRemoteAddon from 'terminal/fiWebSocketRemoteAddon';
import createTerminmal from 'terminal/terminal';
import prservice from '../../../locallib/prservice/prservice';
import { useValidEffect } from 'react_hooks/rh_util_hooks';
import $ from 'jquery';

const fiPRService = prservice({
  type: 'target',
  targetWindow: window,
});

let clean = () => {};

window.addEventListener('beforeunload', () => {
  fiPRService?.detach();
  clean();
});

const wsServiceDefer = $.Deferred();

// Receive websocket instance from remote window
fiPRService.registerOneTimeListenerFn({
  'console-service': (data) => wsServiceDefer.resolve(data),
});

export const WebConsole = () => {
  const instance = useRef(null);
  const query = useQuery();
  const [fiWebSocket, setFiWebSocket] = useState(null);
  const [remoteParams, setRemoteParams] = useState({});

  useValidEffect(async () => {
    const { fiWebSocket: wsFromPr, remoteParams: remoteParamsFromPr } =
      await wsServiceDefer;
    setFiWebSocket(wsFromPr);
    setRemoteParams(remoteParamsFromPr);
  }, []);

  const allParams = useMemo(() => {
    const _localConnection = isNil(remoteParams?.ipaddr);
    const _consoleId = parseInt(query.get('sid'));
    let websocketAddon = null;

    if (!isNil(fiWebSocket)) {
      websocketAddon = websocketAddon = _localConnection
        ? new DetachedLocalWebsocketAddon({
            consoleId: _consoleId,
            fiWebSocket,
          })
        : new DetachedRemoteWebsocketAddon({
            consoleId: _consoleId,
            fiWebSocket,
            remoteParam: remoteParams,
          });

      clean = websocketAddon.dispose;
    }

    return {
      initialConsoleId: _consoleId,
      websocketAddon,
    };
  }, [fiWebSocket, query]);

  useEffect(() => {
    const { initialConsoleId, websocketAddon } = allParams;
    if (
      !isNil(initialConsoleId) &&
      !isNil(websocketAddon) &&
      !isNil(instance.current)
    ) {
      const addons = {
        fit: new FitToContainerAddon({
          observeEl: instance.current,
        }),
        websocket: websocketAddon,
      };

      const xterm = createTerminmal(
        instance.current,
        Object.values(addons),
        {}
      );

      addons.fit.afterXtermReady();
      addons.websocket.afterXtermReady();

      return () => {
        xterm?.dispose();
      };
    }
  }, [allParams]);

  return <div ref={instance} className={'tw-w-full tw-h-full'} />;
};

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

class DetachedLocalWebsocketAddon extends fiWebSocketLocalAddon {
  constructor(params) {
    super(params);

    this.connect = async () => {
      const cleanup = await super.connect();

      return () => {
        // this detached console lost connection and maybe reconnected
        if (params.consoleId !== this.getConsoleId()) {
          cleanup();
        }
      };
    };
  }
}

class DetachedRemoteWebsocketAddon extends fiWebSocketRemoteAddon {
  constructor(params) {
    super(params);

    this._originalConnect = this.connect.bind(this);

    this.connect = async () => {
      const cleanup = await super.connect(params?.remoteParam);

      return () => {
        // this detached console lost connection and maybe reconnected
        if (params.consoleId !== this.getConsoleId()) {
          cleanup();
        }
      };
    };
  }
}
