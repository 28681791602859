import { useField } from 'formik';
import { getAutoId, callAll } from './util/util';
import { isFunction, isUndefined } from 'fiutil';
import { renderIcon } from 'ra-render-util';

import { NwRadioButtonGroup, NwRadioButton } from '@fafm/neowise-core';
import { useEffect } from 'react';
import { isNil } from 'lodash';

export const FmkRadioBtnGroup = ({
  name,
  choices,
  'automation-id': propAutoId,
  automationId,
  disabled: propDisabled,
  onChange,
  getChecked,
  defaultCheckFirst = false,
  action,
  ...rest
}) => {
  const [, { value }, { setValue, setTouched }] = useField(name);

  const onCheck = ({ id }) => {
    setTouched(true, false);
    setValue(id);
  };

  useEffect(() => {
    if (
      action === 'create' &&
      choices.length &&
      isNil(value) &&
      defaultCheckFirst
    ) {
      setValue(choices[0].id);
    }
  }, []);

  const toDisplayVal = (value) => {
    if (isFunction(getChecked)) {
      return choices.find((opt) => getChecked({ opt, val: opt.id }))?.id;
    }
    return value?.val ?? value;
  };

  function isOptionDisabled(opt) {
    const checkDisabled = opt.disabled || propDisabled;
    if (isUndefined(checkDisabled)) return;
    const disabled = isFunction(checkDisabled)
      ? checkDisabled(opt)
      : checkDisabled;
    if (disabled) {
      return true;
    }
  }

  return (
    <NwRadioButtonGroup
      name={name}
      value={toDisplayVal(value)}
      onChange={(evt, value) => {
        callAll(onCheck, onChange)(choices.find(({ id }) => id === value));
      }}
    >
      {choices.map((opt) => {
        const { id, text, icon, autoId } = opt;

        return (
          <NwRadioButton
            key={id}
            value={id}
            automation-id={getAutoId(propAutoId || automationId, autoId || id)}
            title={opt.title ?? text}
            disabled={isOptionDisabled(opt)}
            {...(icon ? { prefix: renderIcon(icon) } : null)}
            {...rest}
          >
            {text}
          </NwRadioButton>
        );
      })}
    </NwRadioButtonGroup>
  );
};
FmkRadioBtnGroup.displayName = 'FmkRadioBtnGroup';
