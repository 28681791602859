import { isFunction, isNumber, isArray, isNil } from 'lodash';

export const callAll =
  (...fns) =>
  (...args) => {
    fns.forEach((fn) => isFunction(fn) && fn(...args));
  };

export const validateAny = (...validate) => {
  const checks = validate.filter(isFunction);
  if (!checks.length) return;

  return async (...args) => {
    for (let check of checks) {
      const error = await check.apply(null, args);
      if (error) {
        return error;
      }
    }
  };
};

export const getAutoId = (...tokens) => {
  return tokens.join(':');
};

export const getValidateFn = (type, boundaryVal) => {
  switch (type) {
    case 'required':
      return (
        boundaryVal &&
        ((val) => {
          return !isNil(val) &&
            val !== '' && //input box, sselect
            (!isArray(val) || val.length) //mselect
            ? null
            : gettext('This field is required.');
        })
      );
    case 'type':
      return validators.inputType(boundaryVal);
    default: {
      return validators[type]?.(boundaryVal);
    }
  }
};

export const validators = {
  required: (
    isRequired,
    isEmptyValue = (fieldValue) => fieldValue == null || fieldValue === ''
  ) => {
    if (!isRequired) return; // not required, no validation

    return (fieldValue) => {
      return isEmptyValue(fieldValue)
        ? gettext('This field is required.')
        : null;
    };
  },
  min: (minimumValue) => {
    if (!isNumber(minimumValue)) return;

    return (fieldValue) => {
      return !isNumber(fieldValue) || fieldValue >= minimumValue
        ? null
        : gettext('The minimum value is %s.').printf([minimumValue]);
    };
  },
  max: (maximumValue) => {
    if (!isNumber(maximumValue)) return;

    return (fieldValue) => {
      return !isNumber(fieldValue) || fieldValue <= maximumValue
        ? null
        : gettext('The maximum value is %s.').printf([maximumValue]);
    };
  },
  minLength: (minLength) => {
    if (!isNumber(minLength)) return;

    return (fieldValue) => {
      return !isNil(fieldValue) && fieldValue.length >= minLength
        ? null
        : gettext('Please enter at least %s characters.').printf([minLength]);
    };
  },
  maxLength: (maxLength) => {
    if (!isNumber(maxLength)) return;

    return (fieldValue) => {
      return isNil(fieldValue) || fieldValue.length <= maxLength
        ? null
        : gettext('Please enter at most %s characters.').printf([maxLength]);
    };
  },
  inputType: (type) => {
    return typeValidation[type];
  },
  pattern: (regexp) => {
    if (!regexp) return;
    if (!(regexp instanceof RegExp)) {
      regexp = new RegExp(regexp);
    }

    return (fieldValue) => {
      return regexp.test(fieldValue)
        ? null
        : gettext('Value does not match the pattern.');
    };
  },
};

const EMAIL_REGEXP =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
// regex obtained from angular 1.5.5 https://github.com/angular/angular.js/blob/v1.5.5/src/ng/directive/input.js

const typeValidation = {
  email: (fieldValue) => {
    if (!EMAIL_REGEXP.test(fieldValue)) {
      return gettext('Invalid email.');
    }
  },
};

export const createGetFmkField = (prefix) => (field) => {
  if (field.includes('-')) {
    return `${prefix}["${field}"]`;
  }
  return `${prefix}.${field}`;
};
