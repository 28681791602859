import React, { useEffect, useCallback, useState } from 'react';
import { fiStore, fiStoreFabric } from 'fistore';
import { fiHttpGet, fiHttpPost } from 'fi-http';
import { Formik, FormikConsumer } from 'formik';
import { FmkInput, FmkErrorSpan } from 'rc_form';
import { ProForm } from '@fafm/neowise-pro';
import { NwButton, NwSwitch } from '@fafm/neowise-core';
import { useNavigate } from 'react-router-dom';
import { fiSysConfig } from 'fi-session';
const { Header, Body, Footer, Section, Row, Column, Line } = ProForm;

const getAutoId = (name) => `custom-view-saveas-modal:${name.toLowerCase()}`;

export const Saveas = ({ $opener, menuItem }) => {
  const initialValues = {
    name: '',
  };
  const uuid = menuItem?.stateParams?.customViewId;
  const adminName = fiSysConfig.current().admin_user_name || '';
  const [params, setParams] = useState(null);
  const [periodTxt, setPeriodTxt] = useState('');
  const [createPublic, setCreatePublic] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fiHttpGet(`/p/fabric/customview/load/${uuid}/`, {
      params: { admin: adminName },
    }).then((data) => {
      const res = JSON.parse(data.content);
      setParams(res);
      const period =
        typeof res.time.lastN === 'number'
          ? `Last ${res.time.lastN} ${res.time.unitText || res.time.unit}s`
          : res.time.label;
      setPeriodTxt(period);
    });
  }, []);

  const validate = useCallback((values) => {
    const errors = {};
    if (!values['name']) {
      errors.name = gettext('Name is required.');
    }
    return errors;
  }, []);

  const saveAsCustom = (values, setErrors) => {
    const newName = encodeURIComponent(values['name']);
    fiHttpGet('/p/fabric/customview/chkdup/', {
      params: {
        table: 'custom-view',
        name: newName,
      },
    }).then((resp) => {
      if (resp.isDup) {
        setErrors({ name: gettext('Name exist.') });
      } else {
        const viewUsage = menuItem?.stateParams?.viewUsage;
        fiHttpPost('/p/fabric/customview/save/', {
          name: newName,
          'view-usage': viewUsage,
          content: JSON.stringify(params),
          owner: createPublic ? `${adminName}|public` : `${adminName}|private`,
        }).then(
          (data) => {
            $opener.resolve(menuItem);
            fiStore.dispatch(fiStoreFabric.fetchFabricCustomMenu());
            const pathItems = menuItem.path.split('/');
            const prePath = pathItems.slice(0, pathItems.length - 2).join('/');
            navigate(`${prePath}/fabric_customview_${data.id}/${data.id}`, {
              customViewName: data.name,
            });
          },
          (err) => {
            if (err && err.data) {
              let msg;
              if (err.data.msg) {
                msg = err.data.msg;
              } else if (err.data.data) {
                msg = err.data.data.msg;
              }
              if (msg) {
                setErrors({ name: msg });
              }
            }
          }
        );
      }
    });
  };

  const cancel = () => {
    $opener.reject();
  };

  const togglePrivacy = () => {
    setCreatePublic((prevPrivacy) => !prevPrivacy);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, { setErrors }) => saveAsCustom(values, setErrors)}
      validate={validate}
    >
      <Header>{gettext('Save as New Custom View')}</Header>
      <Body>
        <Section>
          <Row label={gettext('Name')}>
            <Column>
              <FmkInput
                name='name'
                required
                automation-id={getAutoId('name')}
              />
              <FmkErrorSpan name='name' />
            </Column>
          </Row>
          <Row label={gettext('Time Period')}>
            <Column>
              <span>{periodTxt}</span>
            </Column>
          </Row>
          {params && params.filters ? (
            <Row label={gettext('Search')}>
              <Column>
                <span>{params.filters}</span>
              </Column>
            </Row>
          ) : null}
          <Row label={gettext('Privacy')}>
            <Column>
              <Line>
                <NwSwitch
                  checked={createPublic}
                  onChange={togglePrivacy}
                  automation-id={getAutoId('privacy')}
                />
                <div>
                  <strong>
                    {createPublic ? gettext('Public') : gettext('Private')}
                  </strong>
                  <div>
                    {createPublic
                      ? gettext('Others can see this Custom View')
                      : gettext('Only you can see this Custom View')}
                  </div>
                </div>
              </Line>
            </Column>
          </Row>
        </Section>
      </Body>
      <Footer>
        <FormikConsumer>
          {(formik) => (
            <NwButton
              type='primary'
              automation-id={getAutoId('ok-btn')}
              onClick={formik.submitForm}
            >
              {gettext('OK')}
            </NwButton>
          )}
        </FormikConsumer>
        <NwButton onClick={cancel} automation-id={getAutoId('cancel-btn')}>
          {gettext('Cancel')}
        </NwButton>
      </Footer>
    </Formik>
  );
};
