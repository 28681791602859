import { castArray } from 'lodash';
import * as actions from './actions';

export const swcReducer = (state = {}, { type, payload }) => {
  const updatePartial = (partial) => ({ ...state, ...partial });

  switch (type) {
    case actions.setDevScope.type:
      return updatePartial({ devScope: castArray(payload).filter(Boolean) });
    default:
      return state;
  }
};
