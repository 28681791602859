import { openFWTemplate } from './firmware_utils';

export const makeUpgradeItem = ({
  preOpen,
  onSuccess,
  onError,
  getSelectedRows,
  $opener,
  extraData,
  isLoading,
}) => {
  return {
    key: 'setup_upgrade',
    label: gettext('Setup Upgrade'),
    icon: 'upgrade',
    disabled: isLoading,
    exec: async () => {
      if (preOpen) await preOpen();
      openFWTemplate({
        selectedRows: getSelectedRows(),
        $opener,
        ...extraData,
      })
        .then(onSuccess)
        .catch(onError);
    },
  };
};
