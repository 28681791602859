/**
 * the follow code refers to:
 * https://github.com/angular/angular.js/blob/master/src/ng/browser.js
 * https://github.com/angular/angular.js/blob/master/src/ng/cookieReader.js
 * https://github.com/angular/angular.js/tree/master/src/ngCookies
 *
 */
const isDefined = (val) => typeof val !== 'undefined';
const isUndefined = (val) => typeof val === 'undefined';

const fiCookieReader = cookieReader();
const fiCookieWriter = cookieWriter();

/**
 * @name fiCookies
 * @description
 *
 * The fiCookies provides a convenient wrapper for reading and writing browser cookies.
 *
 */

export const fiCookies = {
  /**
   * @ngdoc attribute
   * @description
   * default config
   **/
  defaults: {},

  /**
   * @ngdoc method
   * @name fiCookies#get
   *
   * @description
   * Returns the value of given cookie key
   *
   * @param {string} key Id to use for lookup.
   * @returns {string} Raw cookie value.
   */
  get: function (key) {
    return fiCookieReader()[key];
  },

  /**
   * @ngdoc method
   * @name fiCookies#getObject
   *
   * @description
   * Returns the deserialized value of given cookie key
   *
   * @param {string} key Id to use for lookup.
   * @returns {Object} Deserialized cookie value.
   */
  getObject: function (key) {
    var value = this.get(key);
    try {
      return value ? JSON.parse(value) : value;
    } catch (e) {
      // eslint-disable-next-line
      console.warn(e);
      return undefined;
    }
  },

  /**
   * @ngdoc method
   * @name fiCookies#getAll
   *
   * @description
   * Returns a key value object with all the cookies
   *
   * @returns {Object} All cookies
   */
  getAll: function () {
    return fiCookieReader();
  },

  /**
   * @ngdoc method
   * @name fiCookies#put
   *
   * @description
   * Sets a value for given cookie key
   *
   * @param {string} key Id for the `value`.
   * @param {string} value Raw value to be stored.
   * @param {Object=} options Options object.
   *    See {this.defaults}
   */
  put: function (key, value, options) {
    fiCookieWriter(key, value, calcOptions(options));
  },

  /**
   * @ngdoc method
   * @name fiCookies#putObject
   *
   * @description
   * Serializes and sets a value for given cookie key
   *
   * @param {string} key Id for the `value`.
   * @param {Object} value Value to be stored.
   * @param {Object=} options Options object.
   *    See {this.defaults}
   */
  putObject: function (key, value, options) {
    try {
      this.put(key, JSON.stringify(value), options);
    } catch (e) {
      // eslint-disable-next-line
      console.warn(e);
    }
  },

  /**
   * @ngdoc method
   * @name fiCookies#remove
   *
   * @description
   * Remove given cookie
   *
   * @param {string} key Id of the key-value pair to delete.
   * @param {Object=} options Options object.
   *    See {this.defaults}
   */
  remove: function (key, options) {
    fiCookieWriter(key, undefined, calcOptions(options));
  },
};

function calcOptions(options) {
  return options
    ? Object.assign({}, fiCookies.defaults, options)
    : fiCookies.defaults;
}

/**
 * @name cookieReader
 *
 * @description
 * This is a private service for reading cookies used by $http and ngCookies
 *
 * @return {Object} a key/value map of the current cookies
 */
function cookieReader() {
  var rawDocument = document || {};
  var lastCookies = {};
  var lastCookieString = '';

  function safeGetCookie(rawDocument) {
    try {
      return rawDocument.cookie || '';
    } catch (e) {
      return '';
    }
  }

  function safeDecodeURIComponent(str) {
    try {
      return decodeURIComponent(str);
    } catch (e) {
      return str;
    }
  }

  return function () {
    var cookieArray, cookie, i, index, name;
    var currentCookieString = safeGetCookie(rawDocument);

    if (currentCookieString !== lastCookieString) {
      lastCookieString = currentCookieString;
      cookieArray = lastCookieString.split('; ');
      lastCookies = {};

      for (i = 0; i < cookieArray.length; i++) {
        cookie = cookieArray[i];
        index = cookie.indexOf('=');
        if (index > 0) {
          //ignore nameless cookies
          name = safeDecodeURIComponent(cookie.substring(0, index));
          // the first value that is seen for a cookie is the most
          // specific one.  values for the same cookie name that
          // follow are for less specific paths.
          if (isUndefined(lastCookies[name])) {
            lastCookies[name] = safeDecodeURIComponent(
              cookie.substring(index + 1)
            );
          }
        }
      }
    }
    return lastCookies;
  };
}

/**
 * @name cookieWriter
 *
 * @description
 * This is a private service for writing cookies
 *
 * @param {string} name Cookie name
 * @param {string=} value Cookie value (if undefined, cookie will be deleted)
 * @param {Object=} options Object with options that need to be stored for the cookie.
 */
function cookieWriter() {
  var cookiePath = baseHref();
  var rawDocument = document || {};

  function buildCookieString(name, value, options) {
    var path, expires;
    options = options || {};
    expires = options.expires;
    path = isDefined(options.path) ? options.path : cookiePath;
    if (isUndefined(value)) {
      expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
      value = '';
    }
    if (typeof expires === 'string') {
      expires = new Date(expires);
    }

    var str = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    str += path ? ';path=' + path : '';
    str += options.domain ? ';domain=' + options.domain : '';
    str += expires ? ';expires=' + expires.toUTCString() : '';
    str += options.secure ? ';secure' : '';
    str += options.samesite ? ';samesite=' + options.samesite : '';

    // per http://www.ietf.org/rfc/rfc2109.txt browser must allow at minimum:
    // - 300 cookies
    // - 20 cookies per unique domain
    // - 4096 bytes per cookie
    var cookieLength = str.length + 1;
    if (cookieLength > 4096) {
      // eslint-disable-next-line
      console.warn(
        "Cookie '" +
          name +
          "' possibly not set or overflowed because it was too large (" +
          cookieLength +
          ' > 4096 bytes)!'
      );
    }

    return str;
  }

  return function (name, value, options) {
    rawDocument.cookie = buildCookieString(name, value, options);
  };
}

function baseHref() {
  let baseEl = document.getElementsByTagName('base');
  var href = baseEl?.href || null;
  return href ? href.replace(/^(https?:)?\/\/[^/]*/, '') : '';
}
