import { useState } from 'react';
import { getLocalStorage, setLocalStorage } from 'fiutil/storage';

// for use as "use" hook
export function useLocalStorage<TV>(key: string, initialValue: TV) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    return getLocalStorage(key, initialValue as any) as TV;
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: TV | ((prev: TV) => TV)) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    try {
      // Save to local storage
      setLocalStorage(key, valueToStore);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
    // Save state
    setStoredValue(valueToStore);
  };

  return [storedValue, setValue] as [TV, typeof setValue];
}
