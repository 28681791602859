import { fetchSessionAdom } from '../session/adom/slice';
import {
  getAdminUserName,
  getSysConfig,
  getIsFazSupervisor,
} from '../session/sysConfig/selectors';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneDeep, escapeRegExp } from 'lodash';
import { fiHttpGet } from 'fi-http';
import { insertAppTree } from 'fistore/routing/slice';
import { appEnabled, getIsAppInited } from 'fistore/routing/selectors';
import { listenerMiddleware } from 'fistore/middlewares';
import { treeMap } from 'fiutil/tree';
import { getDynamicViews, getIsDynamicMenuApplied } from './selectors';

export const CUSTOM_VIEW_UUID = '43aeb9b2-f6fb-4c79-91d2-c74dc86c390c';
const FAZ_SINGLE_MENU_OVERWRITE_KEY = 'event_monitor';
const FAZ_SUPERVISOR_MENU_OVERWRITE_KEY = 'fortisoc_supv_groups';
const DEFAULT_MENU = {
  path: '/alert/eventmonitor/all',
  name: 'alert_event_all',
  icon: 'event',
  label: gettext('All Events'),
  noStateCache: true,
};
const DEFAULT_MENU_SUPV = {
  path: '/alert-supv/allgroups/all',
  name: 'fortisoc_supv_all',
  icon: 'event',
  label: gettext('All Events'),
  noStateCache: true,
};
const SETTINGS_MENU = {
  name: 'alert_event_settings', // not needed by menu system but needed by alert saga update
  path: '/alert/eventmonitor/settings',
  icon: 'settings',
  label: gettext('Toggle Views'),
  noStateCache: true,
};
const makeSettingsMenu = (isSupv) => {
  if (isSupv) {
    return {
      ...SETTINGS_MENU,
      name: 'alert_supv_event_settings',
      path: '/alert-supv/allgroups/settings',
    };
  } else {
    return SETTINGS_MENU;
  }
};
// SECTION from old api.js since new guidelines sugget avoid using api.js
// ############################ BEGIN api.js ############################

export const DEFAULT_VIEW = {
  // uuid: 'd4655e5c-51c8-4b88-942a-b9279dd5dc32',
  // name: 'Compromised Hosts',
  cols: [
    'subject',
    'eventstatus',
    'eventtype',
    'logcount',
    'severity',
    'firstlogtime',
    'updatetime',
    'extrainfo',
    'triggername',
    'tag',
    'devname',
  ],
  // filters: '',
  // hiddenFilters: 'triggername="Default-Compromised-Host-Detection-by IOC" or triggername="Default-Botnet-Communication-Detection"',
  showAck: false,
  time: {
    lastN: 1,
    startTime: '',
    endTime: '',
    timeType: {
      label: gettext('Last 7 Days'),
      value: 'week',
      seconds: 604800,
    },
    timePeriod: 604800,
  },
  viewType: 'default',
  position: 0,
  device: '',
};

export function patchDefaultView(view) {
  var filters = view.filters;
  view.filters = '';
  view.hiddenFilters = filters;
  Object.assign(view, cloneDeep(DEFAULT_VIEW));
  return view;
}

function patchViewPresentation(view, parent) {
  if (view) {
    view.order = 0;
    view.show = true;
  }
  if (!view || !parent || !parent.viewConfMap) {
    return;
  }
  var viewConf = parent.viewConfMap.get(view.uuid);
  if (viewConf) {
    view.order = viewConf.order;
    view.show = !!viewConf.show;
  }
}

export function dynamicViews(abortCtrl = null, adminName = '') {
  const customViews = fiHttpGet('/p/alert/customview/list/', {
    signal: abortCtrl?.signal,
  });
  const defaultViews = fiHttpGet('/p/alert/defaultview/all/', {
    signal: abortCtrl?.signal,
  });
  return Promise.all([defaultViews, customViews]).then(function (resps) {
    const defaults = resps[0];
    const customs = resps[1];
    const pres = defaults.presentation;
    const parents = defaults.parents;
    const parentMap = new Map();
    var customViewParent;
    parents.forEach(function (parent) {
      parent.children = [];
      parent.isGroup = true;
      parentMap.set(parent.uuid, parent);
      if (parent.uuid === CUSTOM_VIEW_UUID) {
        customViewParent = parent;
      }
    });
    pres.forEach(function (viewConfs) {
      var viewConfMap = new Map();
      (viewConfs?.menu || []).forEach(function (conf) {
        viewConfMap.set(conf.view, conf);
      });
      var parentObj = parentMap.get(viewConfs.parent);
      if (parentObj) {
        parentObj.viewConfMap = viewConfMap;
      }
    });
    defaults.views.forEach(function (view) {
      patchDefaultView(view);
      var parent = parentMap.get(view.parent);
      if (parent) {
        parent.children.push(view);
        patchViewPresentation(view, parent);
      }
    });
    customs.forEach(function (view) {
      let checkRegex = new RegExp(`^${escapeRegExp(adminName)}\\|`);
      let checkRegexEnd = new RegExp('\\|public$');
      if (
        view.owner === '' ||
        checkRegex.test(view.owner) ||
        checkRegexEnd.test(view.owner)
      ) {
        let content = JSON.parse(view.content);
        var parent = parentMap.get(content.parent);
        if (!parent && customViewParent && customViewParent.children) {
          parent = customViewParent;
        }
        parent.children.push(view);
        patchViewPresentation(view, parent);
      }
    });
    return parents;
  });
}
// ############################ END api.js ############################

const initialState = {
  loading: false,
  data: [],
};

export const fetchDynamicViews = createAsyncThunk(
  'alert/FETCH_DYNAMIC_VIEWS',
  async (arg, thunkApi) => {
    const abc = new AbortController();
    const username = getAdminUserName(thunkApi.getState());
    const result = await dynamicViews(abc, username);
    return result;
  }
);
// const createCustomView = createAsyncThunk(
//   'alert/CREATE_CUSTOM_VIEW',
//   async (dash, { rejectWithValue }) => {
//     const resp1 = await fiHttp.post('/p/noc/create_dash/', dash);
//     if (resp1?.data?.status?.code) {
//       return rejectWithValue(resp1.data.status.message);
//     }
//     // some logic in python side, need to call get dash
//     const resp2 = await fiHttp.get('/p/noc/get_dash/', {
//       params: {
//         uuid: resp1.data.data.uuid,
//       },
//     });
//     return resp2.data.dash;
//   }
// );
// const deleteCustomView = createAsyncThunk(
//   'alert/DELETE_CUSTOM_VIEW',
//   async (uuid, { rejectWithValue }) => {
//     const resp = await fiHttp.post('/p/noc/remove_dash/', {
//       uuid: uuid,
//     });
//     if (resp?.data?.status?.code) {
//       return rejectWithValue(resp.data.status.message);
//     }
//     return { uuid };
//   }
// );
// const renameCustomView = createAsyncThunk(
//   'alert/RENAME_CUSTOM_VIEW',
//   async ({ uuid, name, parent }, { rejectWithValue }) => {
//     const resp = fiHttpPost('/p/alert/customview/rename/', {
//       uuid: uuid,
//       name: name,
//       parent: parent,
//     });
//     if (resp?.data?.status?.code) {
//       return rejectWithValue(resp.data.status.message);
//     }
//     return { uuid, name };
//   }
// );

listenerMiddleware.startListening({
  predicate: (action, currState /*, prevState*/) => {
    const ret =
      !getIsDynamicMenuApplied(currState) &&
      getDynamicViews(currState) &&
      getIsAppInited(currState) &&
      getSysConfig(currState);
    return ret;
  },
  effect: async (action, thunkApi) => {
    const currState = thunkApi.getState();
    const isSupv = getIsFazSupervisor(thunkApi.getState());
    const dynamicViews = getDynamicViews(currState);
    const defaultMenuItem = isSupv ? DEFAULT_MENU_SUPV : DEFAULT_MENU;
    const alertApps = {
      [defaultMenuItem.name]: defaultMenuItem,
    };
    let defaultViewCount = 0;
    const alertTree = [defaultMenuItem.name].concat(
      treeMap((node) => node.children)({
        items: dynamicViews,
        postFn: (item, children) => {
          const key = item.uuid;
          const stateParams = {};
          const extra = {};

          let viewType = item.viewType;
          if (children) {
            stateParams.viewGroup = item.uuid;
            if (item.uuid === CUSTOM_VIEW_UUID) {
              viewType = 'custom';
            } else {
              viewType = 'default';
            }
          }

          if (item.uuid) {
            stateParams.appUniKey = item.uuid;
          }
          if (viewType === 'default') {
            extra.state = 'adom.default.alert.eventmonitor.default_view';
            extra.reloadState = extra.state;
            defaultViewCount++; // count even if they're not enabled, so settings can show up
          } else {
            extra.state = 'adom.default.alert.eventmonitor.custom_view';
            extra.reloadState = extra.state;
          }

          // null items should not be counted
          const hasRealChildren = (children || []).filter(
            (child) => child
          ).length;
          if ((!children && !item.show) || (children && !hasRealChildren))
            return null;

          // show the app when it is a parent.
          // previously we have (or !children) condition so that leaves are
          // also added as states/menus. Now we no longer have that layer of
          // menu.
          if (hasRealChildren) {
            const pathBase = isSupv
              ? '/alert-supv/allgroups/'
              : '/alert/eventmonitor/';
            const path =
              pathBase +
              (viewType === 'default' ? 'default' : 'custom') +
              '/' +
              item.uuid;

            alertApps[item.uuid] = {
              path,
              icon:
                typeof item.icon === 'string'
                  ? item.icon.replace('ffg ffg-', '')
                  : item.icon,
              label: item.name,
              stateParams,
              ...extra,
            };
          }
          // if (!children?.length) return key;
          // return [key, children];
          return key; // no longer do the submenu for this layer.
        },
      })
    );
    if (defaultViewCount) {
      const menu = makeSettingsMenu(isSupv);
      alertApps[menu.name] = menu;
      alertTree.push(menu.name);
    }
    thunkApi.dispatch(slice.actions.setIsAppliedToMenu(true));
    const menuKey = isSupv
      ? FAZ_SUPERVISOR_MENU_OVERWRITE_KEY
      : FAZ_SINGLE_MENU_OVERWRITE_KEY;
    const ret = await thunkApi.dispatch(
      insertAppTree({
        key: menuKey,
        subTree: alertTree,
        apps: alertApps,
      })
    );
    return ret;
  },
});

// fetch customview on adom change
listenerMiddleware.startListening({
  actionCreator: fetchSessionAdom.fulfilled,
  effect: async (action, { dispatch, condition, cancelActiveListeners }) => {
    cancelActiveListeners();
    if (
      await condition((_, currState) => appEnabled('event_monitor')(currState))
    ) {
      await dispatch(fetchDynamicViews());
    }
  },
});

const slice = createSlice({
  name: 'dynamicViews',
  initialState,
  reducers: {
    setIsAppliedToMenu(state, action) {
      if (action.payload) {
        state.appliedData = state.data;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDynamicViews.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDynamicViews.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      });
    // .addCase(createCustomView.fulfilled, (state, { payload }) => {
    //   state.data.push(payload);
    // })
    // .addCase(
    //   renameCustomView.fulfilled,
    //   (state, { payload: { uuid, name } }) => {
    //     let dash = state.data.find((x) => x.uuid === uuid);
    //     dash.name = name;
    //   }
    // )
    // .addCase(deleteCustomView.fulfilled, (state, { payload }) => {
    //   state.data = state.data.filter((x) => x.uuid !== payload.uuid);
    // });
  },
});
export default slice.reducer;
