import { useSelector } from 'react-redux';
import { isFmg } from 'fistore/session/sysConfig/selectors';
import { NwIcon } from '@fafm/neowise-core';

export function ProdLogo({ className }: { className: string }) {
  const _isFmg = useSelector(isFmg);
  const logo = _isFmg ? 'fortimanager' : 'fortianalyzer';

  return (
    <NwIcon library='fgt-products' name={logo} className={className}></NwIcon>
  );
}
