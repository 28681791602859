import $ from 'jquery';
import { fiAdom } from 'fi-session';
import { fiFmgHttp } from 'fi-http';
import { fiMessageBox } from 'fi-messagebox';
import { escapeSlash } from 'kit/kit-regexp';

export const genProxyTarget = (devName) => {
  return [
    'adom/%s/device/%s'.printf([fiAdom.current().name, escapeSlash(devName)]),
  ];
};

const getTimeString = (expireTime) => {
  const date = new Date(expireTime * 1000);

  const formatted = date.toDateString();
  // Hours part from the timestamp
  const hours = date.getHours();
  // Minutes part from the timestamp
  const minutes = '0' + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = '0' + date.getSeconds();

  // Will display time in 10:30:23 format
  const formattedTime =
    hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  return formatted + ' ' + formattedTime;
};

/*
 * The function is supposed to query the table list, so the HTTP method to FGT will be "GET"
 */
export const sendProxyPayload = async (
  { payload, deviceName: devName, ver, showError = false },
  abortCtrl
) => {
  const { path, name, action = undefined, ...rest } = payload;
  const resource = {
    path: path,
    name: name,
    action: action || 'select',
    'no-action': payload['no-action'],
    payload: rest,
  };

  try {
    const resp = await fiFmgHttp.proxy(
      genProxyTarget(devName),
      'get',
      resource,
      ver,
      null,
      abortCtrl
    );
    let respArr = resp['0'].data['0'].response || [];
    respArr = Array.isArray(respArr) ? respArr : [respArr];
    let resultArr = [];

    const handleResultArray = (results, typename, respObj) => {
      if (typename === 'dhcp') {
        for (const resultObj of results) {
          if (Array.isArray(resultObj.list) && resultObj.list.length) {
            for (const listObject of resultObj.list) {
              resultArr.push({
                ...listObject,
                intf: resultObj.intf,
              });
            }
          } else if (resultObj) {
            resultArr.push({
              ...resultObj,
              intf: resultObj.interface || resultObj.intf,
              expire: resultObj.expire_time
                ? getTimeString(resultObj.expire_time)
                : '',
            });
          }
        }
      } else {
        for (const resultObj of results) {
          resultArr.push({
            ...resultObj,
            _type: typename,
            _vdom: respObj.vdom,
          });
        }
      }
    };

    const handleResultObject = (results, typename, respObj) => {
      for (const [key, value] of Object.entries(results)) {
        if (typename === 'shaper' || typename === 'per-ip-shaper') {
          if (key === 'data') {
            const parsed = value.map((obj) => {
              return {
                ...obj,
                _vdom: respObj.vdom,
              };
            });

            resultArr = [...resultArr, ...parsed];
            break;
          } else {
            continue;
          }
        } else if (
          (typename === 'email' || typename === 'web') &&
          !value.hasOwnProperty('proto')
        ) {
          resultArr.push({ ...value, proto: key });
        } else {
          resultArr.push({ ...value, _vdom: respObj.vdom });
        }
      }
    };

    for (const respObj of respArr) {
      const typename = respObj.name;
      // payload_responseArr[k] = respObj
      let results = typename === 'app' ? respObj.results.data : respObj.results;
      if (Array.isArray(results)) {
        handleResultArray(results, typename, respObj);
      } else if ($.isPlainObject(results)) {
        handleResultObject(results, typename, respObj);
      }
    }

    return Promise.resolve(resultArr);
  } catch (err) {
    if (showError)
      fiMessageBox.show(gettext('Failed to communicate with device'), 'danger');
    return Promise.reject(err);
  }
};

export const genQueryTableFn = async (
  { path, name, deviceName, vdomName, ver, extraDataObject = {} },
  abortCtrl
) => {
  const proxyPayload = {
    path: path,
    name: name,
    vdom: vdomName,
    ...extraDataObject,
  };

  if (vdomName === MACROS.USER.DVM.CDB_DEFAULT_GLOBAL_NAME) {
    delete proxyPayload['vdom'];
    proxyPayload['vdom'] = '*';
  }

  return sendProxyPayload(
    { payload: proxyPayload, deviceName, vdomName, ver },
    abortCtrl
  );
};
