import { Outlet } from 'react-router-dom';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { fiSession } from 'fistore';

// intend to be used in routes, will force render a new child component on adom switch

export const AdomWatchWrapper = ({ children }) => {
  const adom = useSelector(fiSession.getSessionAdom)?.data || {};
  return <Fragment key={adom.uuid}>{children ?? <Outlet />}</Fragment>;
};
