/** Hooks */
import { useCallback, useState, useMemo } from 'react';
import { usePsirtTableData, useTableViewOptions } from './hooks';

/** Util */
import { getIcon } from './display_utils';
import {
  DVMGROUPS,
  VIEWTYPE_DEVICETYPE,
  VIEWTYPE_FABRIC,
} from '../common/constants';
import { isUndefined } from 'lodash';
import { fiStore } from 'fistore';
import { fiErrorMessage } from 'fi-messagebox';
import { makeUpgradeItem } from '../common/toolbar';

/** Components */
import { NwProHeader, NwProBody, NwProFooter, CancelBtn } from 'rc_layout';
import { ProTable, ProToolkit } from '@fafm/neowise-pro';
import { NwIcon } from '@fafm/neowise-core';
import { PsirtTable } from '../by_device/PsirtTable';
import {
  MultiRecordCell,
  popoverEntryFormatterHelper,
} from 'rc_multi_record_cell';
import { Provider } from 'react-redux';
import {
  SEVERITY,
  SeverityDiv,
  getDeviceFirmwareVersion,
} from '../common/util';
import { irNumberCellRenderer } from '../common/render';

export const AllDevsPsirtTable = (props) => (
  <ProToolkit.ErrorBoundary>
    <Provider store={fiStore}>
      <PsirtDevicesTable {...props} />
    </Provider>
  </ProToolkit.ErrorBoundary>
);

const PsirtDevicesTable = ({ $opener }) => {
  /*********************** Hooks *********************************/
  const {
    tableData,
    loading,
    assetData,
    dvmDevices,
    setTableData,
    psirtData,
    parsedAssetDevices,
    hasFmgVuln,
    parsedDvmDevices,
  } = usePsirtTableData();

  const { viewType, setViewType } = useTableViewOptions({
    setTableData,
    dvmDevices,
    assetData,
    parsedAssetDevices,
    parsedDvmDevices,
  });

  const [loadingFwTemplate, setLoadingFwTemplate] = useState(false);
  const [filterValue, setFilterValue] = useState({});

  /*********************** Hooks *********************************/

  /*********************** Util *********************************/
  const hasFAZ = (selectedRows) => {
    if (selectedRows.length === 0)
      return dvmDevices.some(
        (dev) => dev.os_type === MACROS.DVM.DVM_OS_TYPE_FAZ
      );
    else
      return selectedRows.some(
        (dev) => dev.os_type === MACROS.DVM.DVM_OS_TYPE_FAZ
      );
  };

  const hasFMG = (selectedRows) => {
    if (selectedRows.length === 0) return hasFmgVuln;
    else return selectedRows.some((dev) => dev._isFmg);
  };

  const onBeforeCreateFwTemplate = (selectedRows) => {
    setLoadingFwTemplate(true);

    if (hasFAZ(selectedRows))
      fiErrorMessage.show(
        gettext('%s must be upgraded manually.').printf(['FortiAnalyzer'])
      );
    if (hasFMG(selectedRows))
      fiErrorMessage.show(
        gettext('%s must be upgraded manually.').printf(['FortiManager'])
      );
  };
  /*********************** Util *********************************/

  /*********************** Table Config *********************************/
  const columns = useMemo(
    () => [
      {
        key: 'name',
        title: gettext('Device Name'),
        cellRenderer: ({ rowData, cellData }, highlighter) => {
          const { name, className } = getIcon(rowData);
          return (
            <>
              {rowData.indent && viewType === VIEWTYPE_FABRIC && (
                <span className={'tw-p-2'}></span>
              )}
              <NwIcon name={name} className={className + ' tw-mr-1'} />
              {highlighter(cellData)}
            </>
          );
        },
      },
      {
        key: 'max_severity',
        title: gettext('Severity'),
        width: 100,
        dataGetter: ({ rowData }) => {
          if (rowData.isGroup) return;
          const irNums = rowData['vulns'];
          if (!irNums) return '';
          const highestRisk = getHighestRisk(irNums, psirtData);
          return SEVERITY[highestRisk];
        },
        cellRenderer: ({ rowData }, highlighter) => {
          const irNums = rowData['vulns'];
          if (!irNums) return null;
          const highestRisk = getHighestRisk(irNums, psirtData);
          const text = SEVERITY[highestRisk],
            risk = highestRisk;
          return <SeverityDiv risk={risk}>{highlighter(text)}</SeverityDiv>;
        },
        toSortValue: ({ rowData }) => {
          if (rowData.isGroup) return;
          const irNums = rowData['vulns'];
          if (!irNums) return 1;
          const highestRisk = getHighestRisk(irNums, psirtData);
          return highestRisk;
        },
      },
      {
        key: 'platform',
        title: gettext('Platform'),
        width: 50,
        dataGetter: ({ rowData }) => {
          if (rowData.isGroup) return;
          if (rowData.platform) return rowData.platform;
          if (rowData.deviceType) return rowData.deviceType;
          if (!isUndefined(rowData.os_type))
            return DVMGROUPS[rowData.os_type].name;
          return '';
        },
      },
      {
        key: 'firmversion',
        title: gettext('Firmware Version'),
        dataGetter: ({ rowData }) => {
          if (rowData.isGroup) return;
          return getDeviceFirmwareVersion(rowData, rowData.model_dev);
        },
      },
      {
        key: 'gui_upgrade_to',
        title: gettext('Suggested Upgrade Version'),
        width: 75,
      },
      {
        key: 'vulns',
        title: gettext('Vulnerabilities'),
        cellHeight: ({ cellData }) =>
          Math.min(
            140,
            (cellData?.length || 1) *
              MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_DEFAULT
          ),
        cellRenderer: ({ cellData }, highlighter) => {
          if (!cellData) return null;
          return (
            <VulnerabilitiesCellRenderer
              psirtData={psirtData}
              cellData={cellData}
              highlighter={highlighter}
            />
          );
        },
      },
    ],
    []
  );

  const getToolbarItems = (selectedRows) => [
    makeUpgradeItem({
      $opener,
      extraData: {
        assetData,
        dvmDevices,
      },
      isLoading: loadingFwTemplate,
      getSelectedRows: () => {
        if (selectedRows.length === 0) {
          return tableData.reduce((prev, currGroup) => {
            return [...prev, ...currGroup.children];
          }, []);
        }
        return selectedRows;
      },
      preOpen: () => onBeforeCreateFwTemplate(selectedRows),
      onSuccess: () => {
        setLoadingFwTemplate(false);
      },
      onError: (e) => {
        if (e) fiErrorMessage.show(e);
        else setLoadingFwTemplate(false);
      },
    }),
    {
      key: 'view_vulns',
      label: gettext('View Vulnerabilities'),
      disabled: selectedRows.length !== 1 || !selectedRows[0].vulns,
      icon: 'vulnerability-scan',
      exec: () => {
        if (selectedRows[0].vulns)
          $opener.open(
            <PsirtTable
              device={selectedRows[0]}
              platformKey={selectedRows[0].platformKey}
            />,
            { width: 'lg', height: '80vh' }
          );
      },
    },
  ];

  const getToolbarRightItems = useCallback(() => {
    return [
      {
        key: 'display',
        label: gettext('Display Options'),
        icon: { name: 'top-task', label: gettext('Display Options') },
        type: 'dropdown',
        items: [
          {
            key: 'fabric_view',
            label: gettext('Fabric View'),
            icon: {
              name: viewType === VIEWTYPE_FABRIC ? 'check' : '',
              label: gettext('Fabric view'),
            },
            exec: () => {
              setViewType(VIEWTYPE_FABRIC);
            },
            disabled: viewType === VIEWTYPE_FABRIC,
          },
          {
            key: 'group_by_device_type',
            label: gettext('Device Type View'),
            icon: {
              name: viewType === VIEWTYPE_DEVICETYPE ? 'check' : '',
              label: gettext('Device Type View'),
            },
            exec: () => {
              setViewType(VIEWTYPE_DEVICETYPE);
            },
            disabled: viewType === VIEWTYPE_DEVICETYPE,
          },
        ],
      },
    ];
  }, [viewType]);

  const columnSearch = ProTable.useColumnSearch({
    settings: useMemo(() => {
      if (!tableData.length) return [];

      return {
        name: {
          type: 'text',
        },
        max_severity: {
          type: 'text',
          suggestion: {
            items: Object.entries(SEVERITY).map(([risk, text]) => ({
              id: risk,
              text,
            })),
            labelRenderer: (item) => {
              return <SeverityDiv risk={item.id}>{item.text}</SeverityDiv>;
            },
          },
          isInputSupported: false,
        },
        platform: { type: 'text' },
        firmversion: { type: 'text' },
        gui_upgrade_to: { type: 'text' },
        vulns: { type: 'text' },
      };
    }, [tableData]),
    value: filterValue,
    onChange: (val) => setFilterValue(val),
  });

  /*********************** Table Config *********************************/

  /*********************** Rendering *********************************/
  return (
    <>
      <NwProHeader>{gettext('Vulnerable Devices')}</NwProHeader>
      <NwProBody className='tw-w-full tw-h-full tw-flex tw-flex-col'>
        <VulnStatistics psirtData={psirtData} />
        <ProTable.TableView
          tableId={'psirt_vuln_devs_table'}
          data={tableData}
          rowKey={'key'}
          columns={columns}
          isGroupRow={isGroupRow}
          groupRowRenderer={groupRowRenderer}
          isLoading={loading}
          getToolbarItems={getToolbarItems}
          getContextMenuItems={getToolbarItems}
          getToolbarRightItems={getToolbarRightItems}
          onDoubleClickRow='view_vulns'
          columnSearch={columnSearch}
        />
      </NwProBody>
      <NwProFooter>
        <CancelBtn onClick={() => $opener.reject()}>
          {gettext('Close')}
        </CancelBtn>
      </NwProFooter>
    </>
  );
  /*********************** Rendering *********************************/
};

function getHighestRisk(irNums = [], psirtData) {
  if (!irNums || !irNums.length) return 1;
  const psirts = irNums.map((irnum) => psirtData.byIrNumber[irnum]);
  const highestRisk = psirts.reduce((prev, curr) => {
    return Math.max(prev, parseInt(curr.risk, 10));
  }, 1);
  return highestRisk;
}

const DEVICES_WITH_VULNS = gettext(
  '%s managed devices are identified with security vulnerabilities.'
);

const VulnStatistics = ({ psirtData }) => {
  return (
    <div className='tw-flex tw-flex-col tw-gap-2 tw-pb-2'>
      <NumberOfDevicesMessage psirtData={psirtData} />
      <NumberOfDevices psirtData={psirtData} />
    </div>
  );
};

const NumberOfDevicesMessage = ({ psirtData }) => {
  const numDevices = Object.values(psirtData.numDevicesPerRisk).reduce(
    (prev, curr) => prev + parseInt(curr, 10),
    0
  );
  return (
    <span className='tw-wrap'>{DEVICES_WITH_VULNS.printf([numDevices])}</span>
  );
};

const NumberOfDevices = ({ psirtData }) => {
  const renderVulnNumbers = () => {
    const numberOfVulnsForEachRisk = getNumberOfDevicesPerRisk(
      psirtData.numDevicesPerRisk
    );
    return numberOfVulnsForEachRisk.map(({ key, value, text }) => {
      return (
        <span key={key} className='tw-flex tw-gap-2 tw-items-center '>
          <SeverityDiv
            risk={key}
            className='tw-p-1 tw-px-2 tw-rounded-full tw-text-xs'
            style={{ minWidth: '2rem' }}
          >
            {value}
          </SeverityDiv>
          {text}
        </span>
      );
    });
  };

  return (
    <div className='tw-flex tw-gap-2 tw-items-center tw-flex-wrap'>
      {renderVulnNumbers()}
    </div>
  );
};

const SEVERITY_STRINGS = {
  5: gettext('Critical'),
  4: gettext('High'),
  3: gettext('Medium'),
  2: gettext('Low'),
  1: gettext('Informational'),
};

function getNumberOfDevicesPerRisk(numDevsPerRisk = {}) {
  const riskMap = numDevsPerRisk;

  return Object.entries(riskMap)
    .sort(([key1], [key2]) => key2 - key1)
    .map(([key, value]) => ({
      key,
      value,
      text: SEVERITY_STRINGS[key],
    }));
}

const VulnerabilitiesCellRenderer = ({ cellData, highlighter, psirtData }) => {
  const searchContext = ProTable.useSearchContext();
  const searchText = searchContext?.searchTerm;

  if (!cellData) return;
  return (
    <MultiRecordCell
      textKey='name'
      dataSource={cellData}
      highlighter={highlighter}
      maxRowNum={5}
      getAutoId={(attr) => `${attr}`}
      searchText={searchText}
      popoverEntryFormatter={popoverEntryFormatter(psirtData)}
      cellEntryFormatFn={cellEntryFormatFn(psirtData)}
    />
  );
};

const isGroupRow = ({ rowData }) => {
  return rowData.isGroup;
};

const groupRowRenderer = ({ rowData, numOfChildren }) => {
  return (
    <div>
      {rowData.name}
      {numOfChildren()}
    </div>
  );
};

/***
 * Format for table cell entries
 */
const cellEntryFormatFn = (psirtData) => (highlighter) => {
  return (irNumber) => {
    const psirt = psirtData.byIrNumber[irNumber];
    const risk = psirt.risk;
    return irNumberCellRenderer({ irNumber, risk }, highlighter);
  };
};

const popoverEntryFormatter =
  (psirtData) =>
  (highlighter) =>
  ({ name: irNum }) => {
    return popoverEntryFormatterHelper(
      cellEntryFormatFn(psirtData)(highlighter)(irNum)
    );
  };
