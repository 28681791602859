import { createCaptureFile, debuggerDidStart } from '../debugger_utils';
import { isString, pick, transform } from 'lodash';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { UnknownRecord } from 'chrome_extension_debugger/types';

const logAxios = ({
  instance,
  log,
}: {
  instance: AxiosInstance;
  log: (data: UnknownRecord) => void | Promise<void>;
}) => {
  const meta = Symbol('debugger_meta');

  const transformStringifyObject = (data: Record<keyof any, any>) => {
    return transform(
      data,
      (result, data, key) => {
        if (isString(data)) {
          try {
            result[key] = JSON.parse(data);
            return;
          } catch {
            // not object
          }
        }
        result[key] = data;
      },
      {} as Record<keyof any, any>
    );
  };

  const logResponse = (response: AxiosResponse) => {
    const { data, status, config } = response || {};
    const { method, url, params } = config || {};
    if (!url || params?.debugger === 'ignore') {
      return;
    }
    const logEntry = {
      method,
      url,
      data,
      timestamp: (config as any)[meta].startAt,
      receiveTimestamp: Date.now(),
      statusCode: status || 400,
      config: transformStringifyObject(
        pick(config, ['headers', 'data', 'params'])
      ),
    };
    void log(logEntry);
  };

  // Add a request interceptor
  const requestHandle = instance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      (config as any)[meta] = { startAt: Date.now() };
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error as Error);
    }
  );

  // Add a response interceptor
  const responseHandle = instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      logResponse(response);
      return response;
    },
    function (error) {
      logResponse(error as unknown as AxiosResponse);
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error as Error);
    }
  );

  return () => {
    instance.interceptors.request.eject(requestHandle);
    instance.interceptors.response.eject(responseHandle);
  };
};

export const register = () => {
  return debuggerDidStart(async () => {
    const { fiHttp } = await import('fi-web/fi-http');

    const { send, complete } = await createCaptureFile('http-requests.json', {
      sortBy: 'timestamp',
    });

    const unregisterAxios = logAxios({ instance: fiHttp, log: send });
    return stopCapture;

    function stopCapture() {
      unregisterAxios();
      complete();
    }
  });
};
