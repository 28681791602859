import { createSelector } from '@reduxjs/toolkit';
const getAuth = (state) => state?.auth;

const _select = (key) => createSelector(getAuth, (auth) => auth?.[key]);

export const getLoginEnv = _select('loginEnv');
export const getIsUpgrading = _select('isUpgrading');
export const getIsLoggedIn = createSelector(
  getAuth,
  (auth) => auth?.life?.valid ?? false
);
export const getLife = _select('life');
export const getTimeLeft = createSelector(getLife, (life) => {
  return life?.time_left;
});
export const getIsLoadingEnv = _select('isLoadingEnv');
export const getAuthState = _select('authState');
export const getIgnoreError = _select('ignoreError');

export const getCsrfToken = _select('csrfToken');
export const getActiveTimer = _select('activeTimer');
export const getVmLicense = _select('vmLicense');

export const getRequireVmLic = createSelector(
  getLoginEnv,
  (env) => env?.require_vm_lic
);

export const getHostname = createSelector(getLoginEnv, (env) => env?.host_name);
