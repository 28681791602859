import { fiCache } from 'kit-cache';

export const [readCache, setCache, cleanCache] = ((cacheId) => [
  () => fiCache.performance_cache(cacheId),
  (data) => fiCache.performance_cache(cacheId, data),
  () => fiCache.clean(cacheId),
])('fi-gui-ppkgs-cache');

export const forceReload =
  (req) =>
  async (...rest) => {
    cleanCache();
    return req(...rest);
  };
