import { fiSession } from 'fistore';
import { negate } from 'lodash';
const {
  inManageableAdoms,
  inGlobalAdom,
  hasRDPermitOn,
  isFmg,
  inBackupAdom,
  isCentralManagement,
} = fiSession;

const mrPRIV = MACROS.USER.DVM;

const vpn_ipsec_apps = {
  vpn_ipsec_commcentral: {
    olhTag: 'vpn_enable',
    olhCategory: 'vpn',
    olhFmg: 'VPN Manager -> IPsec VPN(When the ADOM has the VPN disabled)',

    path: '/vpn/ipsec_central',
    label: gettext('IPsec VPN Communities'),
    showCheckers: [negate(isCentralManagement('vpn'))],
  },
  vpn_ipsec_commlist: {
    olhTag: 'vpn_list',
    olhCategory: 'vpn',
    olhFmg: 'VPN Manager -> IPsec VPN(After VPN enabled)',

    path: '/vpn/ipsec_list',
    label: gettext('IPsec VPN Communities'),
    showCheckers: [isCentralManagement('vpn')],
  },
  vpn_ipsec_map: {
    olhTag: 'vpn_map',
    olhCategory: 'vpn',
    olhFmg: 'VPN Manager -> Map View',

    path: '/vpn/ipsec_map',
    label: gettext('IPsec VPN Map'),
  },
};

const vpn_ssl_apps = {
  vpn_ssl_setting: {
    olhTag: 'vpn_ssl',
    olhCategory: 'vpn',
    olhFmg: 'VPN Manager -> SSL VPN -> SSL VPN',

    path: '/vpn/ssl_list',
    label: gettext('SSL VPN Settings'),
  },
  vpn_ssl_ptprof: {
    olhTag: 'vpn_ssl_portal',
    olhCategory: 'vpn',
    olhFmg: 'VPN Manager -> SSL VPN -> Protal Profiles',

    path: '/vpn/ssl_portals',
    label: gettext('SSL VPN Portals'),
  },
  vpn_ssl_monitor: {
    olhTag: 'vpn_ssl_monitor',
    olhCategory: 'vpn',
    olhFmg: 'VPN Manager -> SSL VPN -> Monitor',

    path: '/vpn/ssl_monitor',
    label: gettext('SSL VPN Monitor'),
  },
};

export default {
  vpn: {
    olhTag: 'vpn',
    olhCategory: 'vpn',
    olhFmg: 'VPN Manager',

    label: gettext('VPN Manager'),
    icon: 'vpn-manager',
    color: '#2980b9',
    showCheckers: [
      isFmg,
      inManageableAdoms,
      negate(inBackupAdom),
      negate(inGlobalAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_VPN_MANAGER),
    ],
  },
  ...vpn_ipsec_apps,
  ...vpn_ssl_apps,
};
