import { call, takeLatest, select } from 'redux-saga/effects';
import * as actions from './actions';
import { genAppTree } from 'fistore/routing/utils';
import { fiHttpGet } from 'fi-http';
import { getAdminUserName } from 'fistore/session/sysConfig/selectors';
import { callPromiseAction } from '../utils';
import _ from 'lodash';
import { escapeHtml } from 'kit-escape';

export function* watchFabricChange() {
  yield takeLatest(
    // Should not watch admin profile load, because first profile loading there is no
    // app menu yet.
    actions.fetchFabricCustomMenu.type,
    fetchFabricCustomMenu
  );
}

// append prefix to app fabric app keys
const genUniKey = (fabricId) => 'fabric_' + fabricId;
function getTreeMenu() {
  return fiHttpGet('/p/fabric/customview/list/');
}

function* fetchFabricCustomMenu(action) {
  yield callPromiseAction(action, function* () {
    let resp = yield call(getTreeMenu);
    const objMenuDef = resp.map((item) => ({
      ...item,
      key: genUniKey(`customview_${item.uuid}`),
    }));
    const assetCustomApps = {};
    const assetOtCustomApps = {};
    const identityCustomApps = {};
    const adminUserName = yield select(getAdminUserName);
    let checkRegex = new RegExp(`^${_.escapeRegExp(adminUserName)}\\|`);
    let checkRegexEnd = new RegExp('\\|private$');
    const assetCustomTree = genAppTree((node) => node.items)({
      items: objMenuDef,
      fn: (def) => {
        if (
          def['view-usage'] === 'fabric-identity' ||
          def['view-usage'] === 'fabric-asset-ot' ||
          (def.owner !== '' &&
            !checkRegex.test(def.owner) &&
            checkRegexEnd.test(def.owner))
        ) {
          return null;
        }
        const key = def.key;
        assetCustomApps[key] = fabricDef2App(
          { def },
          'adom.default.fabric.asset.customview',
          `/fabric/asset-index/asset/customview/${key}/${def.uuid}`
        );
        return key;
      },
    });
    const identityCustomTree = genAppTree((node) => node.items)({
      items: objMenuDef,
      fn: (def) => {
        if (
          def['view-usage'] === 'fabric-asset' ||
          def['view-usage'] === 'fabric-asset-ot' ||
          (def.owner !== '' &&
            !checkRegex.test(def.owner) &&
            checkRegexEnd.test(def.owner))
        ) {
          return null;
        }
        const key = def.key;
        identityCustomApps[key] = fabricDef2App(
          { def },
          'adom.default.fabric.identity.customview',
          `/fabric/identity-index/identity/customview/${key}/${def.uuid}`
        );
        return key;
      },
    });
    const assetOtCustomTree = genAppTree((node) => node.items)({
      items: objMenuDef,
      fn: (def) => {
        if (
          def['view-usage'] === 'fabric-asset' ||
          def['view-usage'] === 'fabric-identity' ||
          (def.owner !== '' &&
            !checkRegex.test(def.owner) &&
            checkRegexEnd.test(def.owner))
        ) {
          return null;
        }
        const key = def.key;
        assetOtCustomApps[key] = fabricDef2App(
          { def },
          'adom.default.fabric.asset.ot_customview',
          `/fabric/asset-index/asset/ot/customview/${key}/${def.uuid}`
        );
        return key;
      },
    });
    const resultTree = [
      ['fabric_asset_dashboard', null],
      ['fabric_asset_view', null],
    ];
    const customViewTree = ['fabric_asset_custom', []];
    if (assetCustomTree.length) {
      customViewTree[1].push(['fabric_asset_custom_asset', assetCustomTree]);
    }
    if (assetOtCustomTree.length) {
      customViewTree[1].push([
        'fabric_asset_custom_asset_ot',
        assetOtCustomTree,
      ]);
    }
    if (identityCustomTree.length) {
      customViewTree[1].push([
        'fabric_asset_custom_identity',
        identityCustomTree,
      ]);
    }
    if (customViewTree[1].length) {
      resultTree.push(customViewTree);
    }
    return yield {
      subTree: resultTree,
      apps: Object.assign(
        menuConst,
        assetCustomApps,
        assetOtCustomApps,
        identityCustomApps
      ),
    };
  });
}

/**
 * Convert fabric def to fabric app.
 */
function fabricDef2App({ def }, state, path) {
  return {
    label: escapeHtml(def.name),
    state,
    path,
    stateParams: {
      appUniKey: def.key,
      customViewId: def['uuid'],
      customViewName: def['name'],
      owner: def['owner'],
      periodLastN: def['period-last-n'],
      viewUsage: def['view-usage'],
    },
  };
}

const menuConst = {
  fabric_asset_dashboard: {
    path: '/fabric/asset-index/asset-dashboard',
    label: gettext('Summary'),
    state: 'adom.default.fabric.asset.dashboard',
  },
  fabric_asset_view: {
    path: '/fabric/asset-index/view',
    label: gettext('Asset Identity List'),
    state: 'adom.default.fabric.asset.view',
  },
  fabric_asset_custom: {
    label: gettext('Custom View'),
  },
  fabric_asset_custom_asset: {
    label: gettext('Asset'),
  },
  fabric_asset_custom_asset_ot: {
    label: gettext('Asset') + ' (OT)',
  },
  fabric_asset_custom_identity: {
    label: gettext('Identity'),
  },
};
