export const widgets = {
  device_status: {
    title: {
      text: gettext('Device Status Statistics'),
      desc: gettext(
        'The status of all devices including connectivity, support contracts, licenses, and config status'
      ),
    },
    type: 'device_status',
    autoplayType: 'device_status_rotate',
    components: {
      device_status: 'fi-noc-device-status',
    },
  },
  top_threat_dest: {
    title: {
      text: gettext('Top Threat Destinations'),
      desc: gettext(
        'The highest network traffic including source and destination IP address, threat level, and number of incidents'
      ),
    },
    viewName: 'threatSrcDst',
    type: 'world',
    tableMode: 'lite',
    components: {
      world: 'soc-list-map',
      globe: 'soc-list-globe',
      table: 'soc-list-table',
    },
  },
  fortiview_threatmap: {
    title: {
      text: gettext('Threat Map'),
      desc: gettext('Threats happening right now cross the world'),
    },
    viewName: 'threatmap',
    type: 'threatmap',
    intervalType: 'disabled',
    timePeriodType: 'threat_map',
    components: {
      threatmap: 'fi-noc-threatmap',
    },
  },
  top_threat: {
    title: {
      text: gettext('Top Threats'),
      desc: gettext(
        'The top threats to your network from risk applications, intrusion incidents, malicious web sites, and malware/botnets'
      ),
    },
    viewName: 'threat',
    type: 'bar',
    tableMode: 'lite',
    components: {
      bar: 'soc-list-bar',
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_threat_weight: {
    title: {
      text: gettext('Top Threats by Weight & Count'),
      desc: gettext(
        'The top threats by weight and count to your network from risk applications, intrusion incidents, malicious web sites, and malware/botnets'
      ),
    },
    viewName: 'threat',
    type: 'scatter',
    components: {
      scatter: 'soc-list-scatter',
    },
  },
  virus_timeline: {
    title: {
      text: gettext('Top Virus Incidents over Time'),
      desc: gettext('Local virus incidents in last 1 month'),
    },
    viewName: 'virus-timeline',
    type: 'timeline', // see Type_Top in soc-const.js
    autoplayType: 'timeline',
    intervalType: 'timeline',
    components: {
      timeline: 'soc-virus-timeline',
    },
  },
  fsbp_region: {
    title: {
      text: gettext('Best Practices Overview'),
      desc: gettext(
        'Overview of device best practices across regions of North America, Latin America, EMEA, and APAC'
      ),
    },
    viewName: 'bestPracticesByRegion',
    type: 'fsbp_region',
    components: {
      fsbp_region: 'noc-fsbp-region',
    },
  },
  user_data_flow: {
    title: {
      text: gettext('User Data Flow'),
      desc: gettext(
        'Bandwidth Breakdown of Top User Destination Country/Region or Application Usage'
      ),
    },
    viewName: 'avatarSankey',
    type: 'sankey',
    components: {
      sankey: 'soc-list-sankey',
    },
  },
  top_source_today: {
    title: {
      text: gettext('Top Sources Today'),
      desc: gettext(
        'Near Real-time Network Traffic by Blocked and Allowed Sessions'
      ),
    },
    // viewName:  not fortview view
    type: 'source_today',
    components: {
      source_today: 'fi-noc-top-source-today',
    },
  },
  top_source: {
    title: {
      text: gettext('Top Sources'),
      desc: gettext(
        'The highest network traffic by source IP address and interface, sessions (blocked and allowed), threat score (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'sourceView',
    type: 'bar',
    tableMode: 'lite',
    components: {
      bar: 'soc-list-bar',
      table: 'soc-list-table',
      simple: 'soc-list-simple',
      bubble: 'soc-list-bubble',
    },
  },
  top_source_objects: {
    title: {
      text: gettext('Top Source Addresses'),
      desc: gettext(
        'The highest network traffic by source address objects, sessions (blocked and allowed), threat score (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'sourceUuid',
    type: 'table',
    tableMode: 'lite',
    components: {
      table: 'soc-list-table',
      simple: 'soc-list-simple',
      bubble: 'soc-list-bubble',
    },
  },
  top_country: {
    title: {
      text: gettext('Top Country/Region'),
      desc: gettext(
        'The highest network traffic by country/region, sessions (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'country',
    type: 'treemap',
    tableMode: 'lite',
    components: {
      treemap: 'soc-list-treemap',
      // bubble: "fi-noc-top-country",
      bar: 'soc-list-bar',
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  fortiview_top_destination_historical: {
    title: {
      text: gettext('Traffic Over Time By Sessions'),
      desc: gettext('The historical destinations from recent traffic'),
    },
    viewName: 'destinationView',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  fortiview_policy_hits_historical: {
    title: {
      text: gettext('Policy Hits Over Time by Bandwidth'),
      desc: gettext('The historical policy hits from recent traffic'),
    },
    viewName: 'policyHits',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  fortiview_policy_hits: {
    title: {
      text: gettext('Top Policy Hits'),
      desc: gettext('Top policy hits from recent traffic'),
    },
    viewName: 'policyHits',
    type: 'table',
    tableMode: 'lite',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  fortiview_top_destination: {
    title: {
      text: gettext('Top Destinations'),
      desc: gettext('Top destinations from recent traffic'),
    },
    viewName: 'destinationView',
    type: 'table',
    tableMode: 'lite',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  fortiview_top_website: {
    title: {
      text: gettext('Top Website Domains'),
      desc: gettext('Top website domains from recent traffic'),
    },
    viewName: 'websiteDomain',
    type: 'table',
    tableMode: 'lite',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  fortiview_top_cloud_application: {
    title: {
      text: gettext('Top Cloud Applications'),
      desc: gettext('Top cloud applications from recent traffic'),
    },
    viewName: 'cloudApplication',
    type: 'table',
    tableMode: 'lite',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_application: {
    title: {
      text: gettext('Top Applications'),
      desc: gettext(
        'The top applications used on the network, including application name, risk level, category, sessions (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'app',
    viewTypeNameMap: {
      stackbar: 'appOverTime',
    },
    type: 'bar',
    tableMode: 'lite',
    components: {
      stackbar: 'soc-list-bar',
      bar: 'soc-list-bar',
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  fortiview_top_browsing_user: {
    title: {
      text: gettext('Top Browsing Users'),
      desc: gettext('Top browsing users from recent traffic'),
    },
    viewName: 'browsingUsers',
    type: 'table',
    tableMode: 'lite',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  fortiview_top_cloud_application_historical: {
    title: {
      text: gettext('Cloud Applications Over Time by Sessions'),
      desc: gettext(
        'The historical sessions of Cloud Application used on the network'
      ),
    },
    viewName: 'cloudApplication',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  top_application_historical: {
    title: {
      text: gettext('Top Applications Over Time by Sessions'),
      desc: gettext(
        'The historical sessions of Top Applications used on the network'
      ),
    },
    viewName: 'app',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  fortiview_fct_app: {
    title: {
      text: gettext('Top Applications (FortiClient)'),
      desc: gettext('Top Applications from all endpoints (FortiClient)'),
    },
    viewName: 'fctApp',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  fortiview_top_website_historical: {
    title: {
      text: gettext('Websites Browsing Over Time by Sessions'),
      desc: gettext(
        'The historical websites browsing sessions from recent traffic'
      ),
    },
    viewName: 'websiteDomain',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  fortiview_top_browsing_user_historical: {
    title: {
      text: gettext('Browsing User Over Time by Bandwidth'),
      desc: gettext('The historical browsing users from recent traffic'),
    },
    viewName: 'browsingUsers',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  ioc_user: {
    title: {
      text: gettext('Indicator of Compromise'),
      desc: gettext('Indicator of Compromise'),
    },
    viewName: 'threatDetectSummary',
    timePeriodType: 'ioc',
    intervalType: 'ioc',
    autoplayType: 'ioc',
    type: 'ioc_user',
    components: {
      ioc_user: 'soc-ioc-user',
      table: 'soc-list-table',
    },
  },
  fortiview_fsa_detection: {
    title: {
      text: gettext('FortiSandbox Detection'),
      desc: gettext(
        'FortiSandbox Detection Detail, including scan doc name, source user, destination IP, verdict level, action, service'
      ),
    },
    viewName: 'fsaDetection',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  sandbox_overall: {
    title: {
      text: gettext('FortiSandbox - Scanning Statistics'),
      desc: gettext(
        'The number of files detected by FortiSandbox by type: Malicious, Suspicious, Clean, and Others'
      ),
    },
    viewName: 'fsaVerdict',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  sandbox_user: {
    title: {
      text: gettext('FortiSandbox - Top Malicious & Suspicious File Users'),
      desc: gettext(
        'Users or IP addresses that have the highest number of malicious and suspicious files detected by FortiSandbox'
      ),
    },
    viewName: 'fsaVerdictUser',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  vuln_user: {
    title: {
      text: gettext('User Vulnerabilities Summary'),
      desc: gettext('User Vulnerabilities Summary'),
    },
    viewName: 'vulnscanDev',
    type: 'vuln_user',
    autoplayType: 'vuln_user',
    components: {
      vuln_user: 'soc-vuln-user',
    },
  },
  fct_software: {
    title: {
      text: gettext('FortiClient Software Inventory'),
      desc: gettext('FortiClient Software Inventory'),
    },
    type: 'fct_software',
    components: {
      fct_software: 'soc-fct-software',
    },
  },
  fortiview_all_endpoints: {
    title: {
      text: gettext('All Endpoints'),
      desc: gettext('All endpoints'),
    },
    viewName: 'endpoints',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fortiview_fct_threats: {
    title: {
      text: gettext('Top Threats (FortiClient)'),
      desc: gettext('Top threats from all endpoints (FortiClient)'),
    },
    viewName: 'fctThreat',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  fabric_audit: {
    title: {
      text: gettext('Security Fabric Rating Report'),
      desc: gettext(
        'a report to show the security rating details among connected fabric devices'
      ),
    },
    isCsf: true,
    timeperiodtype: 'disabled',
    type: 'fabric_audit',
    components: {
      fabric_audit: 'soc-fabric-audit',
    },
  },
  fabric_history_compare: {
    title: {
      text: gettext('Security Fabric Score'),
      desc: gettext(
        'current and historical audit scores compared with industry average.'
      ),
    },
    isCsf: true,
    timeperiodtype: 'fabric_history_time_period_type',
    type: 'fabric_history',
    components: {
      fabric_history: 'noc-fabric-history-compare',
    },
  },
  fabric_members: {
    title: {
      text: gettext('Security Fabric Topology'),
      desc: gettext(
        'a map to show the logical structure among connected fabric devices'
      ),
    },
    isCsf: true,
    timeperiodtype: 'disabled',
    type: 'fabric_topology',
    components: {
      fabric_topology: 'soc-fabric-topology',
    },
  },
  top_vpn: {
    title: {
      text: gettext('Top Dialup VPN'),
      desc: gettext(
        'The users accessing the network using SSL or IPsec over a VPN tunnel'
      ),
    },
    viewName: 'sslDialup',
    type: 'world',
    components: {
      world: 'soc-list-map',
      globe: 'soc-list-globe',
      table: 'soc-list-table',
    },
  },
  vpn_site2site: {
    title: {
      text: gettext('VPN Site-to-Site'),
      desc: gettext(
        'The names of VPN tunnels with Internet protocol security (IPsec) that are accessing the network'
      ),
    },
    viewName: 'siteToSite',
    type: 'world',
    components: {
      world: 'soc-list-map',
      globe: 'soc-list-globe',
      table: 'soc-list-table',
    },
  },
  top_ap: {
    title: {
      text: gettext('Authorized APs'),
      desc: gettext(
        'The names of authorized WiFi access points on the network'
      ),
    },
    viewName: 'authorizedAps',
    type: 'world',
    components: {
      world: 'soc-list-map',
      table: 'soc-list-table',
    },
  },
  top_rogue: {
    title: {
      text: gettext('Top Rogue APs'),
      desc: gettext(
        'The top SSID (service set identifiers) of unauthorized WiFi access points on the network'
      ),
    },
    viewName: 'rogue',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
      table: 'soc-list-table',
    },
  },
  top_ssid_historical: {
    title: {
      text: gettext('Top SSID Over Time by Bandwidth'),
      desc: gettext(
        'The historical SSID (service set identifiers) traffic of authorized WiFi access points on the network'
      ),
    },
    viewName: 'authorizedSsids',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  top_ssid: {
    title: {
      text: gettext('Top SSID'),
      desc: gettext(
        'The top SSID (service set identifiers) of authorized WiFi access points on the network'
      ),
    },
    viewName: 'authorizedSsids',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
      table: 'soc-list-table',
    },
  },
  top_wifi_clients: {
    title: {
      text: gettext('WiFi Clients'),
      desc: gettext(
        'The top WiFi access points on the network by bandwidth/sessions.'
      ),
    },
    viewName: 'wifiClients',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  multi_core: {
    title: {
      text: gettext('Multi Core CPU Usage'),
      desc: gettext('The usage status of a multi core CPU'),
    },
    type: 'radar',
    viewName: 'systemMultiCore',
    intervalType: 'systemcpu',
    components: {
      radar: 'soc-system-multi-core',
    },
  },
  lograte_insert_receive: {
    title: {
      text: gettext('Insert Rate vs Receive Rate'),
      desc: gettext(
        'The number of logs received vs the number of logs actively inserted into the database'
      ),
    },
    timePeriodType: 'system',
    intervalType: 'system',
    viewName: 'lograteInsertReceive',
    type: 'historical',
    components: {
      historical: 'soc-system-history-chart2',
    },
  },
  cpu_memory: {
    title: {
      text: gettext('CPU & Memory Usage'),
      desc: gettext('The usage status of CPU and memory'),
    },
    viewName: 'systemCpuMemory',
    timePeriodType: 'systemcpu',
    intervalType: 'systemcpu',
    type: 'historical',
    components: {
      historical: 'soc-system-cpu-memory-chart',
    },
  },
  lograte_receive_forward: {
    title: {
      text: gettext('Receive Rate vs Forwarding Rate'),
      desc: gettext(
        'The number of logs received vs the number of logs forwarded out'
      ),
    },
    viewName: 'lograteReceiveForward',
    timePeriodType: 'system',
    intervalType: 'system',
    type: 'historical',
    components: {
      historical: 'soc-system-history-chart2',
    },
  },
  disk_io: {
    title: {
      text: gettext('Disk I/O'),
      desc: gettext('The disk transaction rate, throughput, or utilization'),
    },
    viewName: 'systemDiskIo',
    timePeriodType: 'system',
    intervalType: 'system',
    type: 'historical',
    components: {
      historical: 'soc-system-history-chart2',
    },
  },
  resource_average: {
    title: {
      text: gettext('Resource Usage Average'),
      desc: gettext(
        'Overview of average resource usage history cross all devices.'
      ),
    },
    viewName: 'resourceUsage',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  resource_peak: {
    title: {
      text: gettext('Resource Usage Peak'),
      desc: gettext(
        'Overview of peak resource usage history cross all devices.'
      ),
    },
    viewName: 'resourcePeak',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fortiview_unauthorized_connections: {
    title: {
      text: gettext('Failed Authentication Attempts'),
      desc: gettext('Top unauthorized connections from recent traffic'),
    },
    viewName: 'unauthorizedConnections',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fortiview_system: {
    title: {
      text: gettext('System Events'),
      desc: gettext('Top system events from recent traffic'),
    },
    viewName: 'systemEvent',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fortiview_admin: {
    title: {
      text: gettext('Admin Logins'),
      desc: gettext('Top admin logins from recent traffic'),
    },
    viewName: 'admin',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  top_threat_fct: {
    title: {
      text: gettext('Top Threats (FortiClient)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fctThreat',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_app_fct: {
    title: {
      text: gettext('Top Applications (FortiClient)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fctApp',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_website_fct: {
    title: {
      text: gettext('Top Websites (FortiClient)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fctWebsite',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_source_fddos: {
    title: {
      text: gettext('Top Sources (FortiDDoS)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fddosSourceView',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  top_destination_fddos: {
    title: {
      text: gettext('Top Destinations (FortiDDoS)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fddosDestinationView',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  top_type_fddos: {
    title: {
      text: gettext('Top Type (FortiDDoS)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fddosTypeView',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  end_point_vul_fct: {
    title: {
      text: gettext('Top Endpoint Vulnerabilities (FortiClient)'),
      desc: gettext(
        'Vulnerability information about FortiClient endpoints including Vulnerability name and CVE ID'
      ),
      font: 'ffg-chart-table',
    },
    viewName: 'fctVulnscanVuln',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },

  end_point_vul_dev_fct: {
    title: {
      text: gettext('Endpoint Devices (FortiClient)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fctVulnscanDev',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  top_endpoints_fct: {
    title: {
      text: gettext('All Endpoints (FortiClient)'),
      desc: gettext('All Endpoints (FortiClient)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fctEndpoints',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  local_threat_prevalence: {
    title: {
      text: gettext('Local Threat Prevalence'),
      desc: gettext('Local threat map of virus, IPS, botnet, and application'),
    },
    viewName: 'localThreatPrevalence',
    type: 'chord',
    timePeriodType: 'daily-threat',
    components: {
      chord: 'soc-local-threat-prevalence-chord',
    },
  },
  sdwan_health_check: {
    isSdWan: true,
    title: {
      text: gettext('Health Check Status'),
      desc: gettext('SD-WAN Health Check Status'),
    },
    viewName: 'sdwanHealthCheck',
    type: 'combinedLine',
    components: {
      combinedLine: 'soc-healthcheck',
    },
  },
  sdwan_bandwidth: {
    isSdWan: true,
    title: {
      text: gettext('SD-WAN Bandwidth Overview'),
      desc: gettext('SD-WAN Bandwidth Overview'),
    },
    viewName: 'sdwanBandwidth',
    type: 'combinedLine',
    components: {
      combinedLine: 'soc-combination-chart',
    },
  },
  sdwan_speed_test_line: {
    isSdWan: true,
    title: {
      text: gettext('Speed Test'),
      desc: gettext('Speed Test'),
    },
    viewName: 'sdwanSpeedTestLine',
    type: 'combinedLine',
    components: {
      combinedLine: 'soc-combination-chart',
      table: 'soc-list-table',
    },
  },
  sdwan_alert: {
    isSdWan: true,
    title: {
      text: gettext('SD-WAN Events'),
      desc: gettext('SD-WAN Events Log Level Distribution'),
    },
    viewName: 'sdwanAlert',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  sdwan_app_bandwidth: {
    isSdWan: true,
    title: {
      text: gettext('Application Bandwidth Utilization'),
      desc: gettext('Application Bandwidth Utilization by Interface'),
    },
    viewName: 'sdwanAppBandwidth',
    type: 'sdwan_app_bandwidth_sankey',
    components: {
      sdwan_app_bandwidth_sankey: 'soc-list-sankey',
      sdwan_app_bandwidth_table: 'soc-list-react-component',
    },
    reactComponent: 'AppBandwidthBaseTable',
  },
  sdwan_per_app_performance: {
    isSdWan: true,
    title: {
      text: gettext('Per-Application Performance'),
      desc: gettext('Per-Application Performance'),
    },
    viewName: 'sdwanPerAppPerformance',
    type: 'line',
    components: {
      line: 'soc-list-line',
    },
  },
  sdwan_global_app_performance: {
    isSdWan: true,
    title: {
      text: gettext('Global-Application Performance'),
      desc: gettext('Global-Application Performance'),
    },
    viewName: 'sdwanGlobalAppPerformance',
    type: 'sdwan_global_app_performance',
    components: {
      sdwan_global_app_performance: 'soc-list-line',
    },
  },
  sdwan_app: {
    isSdWan: true,
    title: {
      text: gettext('SD-WAN Utilization by Application'),
      desc: gettext('SD-WAN Bandwidth Usage by Application'),
    },
    viewName: 'sdwanApp',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  sdwan_performance: {
    isSdWan: true,
    title: {
      text: gettext('SD-WAN Performance Status'),
      desc: gettext('SD-WAN Performance Status Comparison with Interface'),
    },
    viewName: 'sdwanPerformance',
    type: 'scatter',
    noTop: true,
    components: {
      scatter: 'soc-sdwan-performance',
    },
  },
  sdwan_rules_intf: {
    isSdWan: true,
    title: {
      text: gettext('SD-WAN Rules Utilization'),
      desc: gettext('SD-WAN Rules Utilization by Application'),
    },
    viewName: 'sdwanRulesIntf',
    type: 'sankey',
    components: {
      sankey: 'soc-list-sankey',
    },
  },
  sdwan_dev_stats: {
    isSdWan: true,
    title: {
      text: gettext('SD-WAN Health Overview'),
      desc: gettext('SD-WAN Devices status'),
    },
    viewName: 'sdwanDevStats',
    type: 'devstats',
    components: {
      devstats: 'soc-sdwan-dev-stats',
    },
  },
  sdwan_summary_app: {
    isSdWan: true,
    title: {
      text: gettext('Top SD-WAN Applications'),
      desc: gettext('SD-WAN Devices Top Application'),
    },
    viewName: 'sdwanSummaryApp',
    type: 'donut',
    components: {
      donut: 'soc-list-pie',
    },
  },
  sdwan_summary_user: {
    isSdWan: true,
    title: {
      text: gettext('Top SD-WAN Talkers'),
      desc: gettext('SD-WAN Devices Top Talkers'),
    },
    viewName: 'sdwanSummaryUser',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  sdwan_dev_throughout: {
    isSdWan: true,
    title: {
      text: gettext('Top SD-WAN Device Throughput'),
      desc: gettext('Top SD-WAN Device Throughput'),
    },
    viewName: 'sdwanDevThroughout',
    type: 'combinedLine',
    components: {
      combinedLine: 'soc-combination-chart',
    },
  },
  sdwan_dev_intf_per_stats: {
    isSdWan: true,
    title: {
      text: gettext('Top SD-WAN SLA Issues'),
      desc: gettext('Top SD-WAN SLA Issues'),
    },
    viewName: 'sdwanDevIntfPerStats',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  sdwan_per_dev_intf_per_stats: {
    isSdWan: true,
    title: {
      text: gettext('Top SD-WAN SLA Issues'),
      desc: gettext('Top SD-WAN SLA Issues'),
    },
    viewName: 'sdwanPerDevIntfPerStats',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  sdwan_speed_test_by_bandwidth: {
    isSdWan: true,
    title: {
      text: gettext('Speed Test By Bandwidth'),
      desc: gettext('Speed Test By Bandwidth'),
    },
    viewName: 'sdwanSpeedTestByBandwidth',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  sdwan_speed_test_summary: {
    isSdWan: true,
    title: {
      text: gettext('Speed Test Summary'),
      desc: gettext('Speed Test Summary'),
    },
    viewName: 'sdwanSpeedTestSummary',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  sdwan_interfaces: {
    title: {
      text: gettext('SD-WAN Interfaces'),
      desc: gettext('SD-WAN Interfaces'),
    },
    viewName: 'sdwanInterfaces',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  sdwan_per_intf_mos: {
    isSdWan: true,
    title: {
      text: gettext('Audio MOS Score'),
      desc: gettext('SD-WAN Audio MOS Score'),
    },
    viewName: 'sdwanPerIntfMos',
    type: 'line',
    components: {
      line: 'soc-list-line',
    },
  },
  intf_sent_timeline: {
    title: {
      text: gettext('Top Interface of Sent Bit Rate'),
      desc: gettext('Top interfaces that consumes most of bandwidth over time'),
    },
    viewName: 'intfSentTimeline',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  intf_rcvd_timeline: {
    title: {
      text: gettext('Top Interface of Received Bit Rate'),
      desc: gettext('Top interfaces that consumes most of bandwidth over time'),
    },
    viewName: 'intfRcvdTimeline',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  // FortiFirewall
  ffw_user_data_flow: {
    title: {
      text: gettext('User Data Flow (FortiFirewall)'),
      desc: gettext(
        'Bandwidth Breakdown of Top User Destination Country/Region or Application Usage'
      ),
    },
    viewName: 'ffwAvatarSankey',
    type: 'sankey',
    components: {
      sankey: 'soc-list-sankey',
    },
  },
  ffw_top_source: {
    title: {
      text: gettext('Top Sources (FortiFirewall)'),
      desc: gettext(
        'The highest network traffic by source IP address and interface, sessions (blocked and allowed), threat score (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'ffwSourceView',
    type: 'bar',
    tableMode: 'lite',
    components: {
      bar: 'soc-list-bar',
      table: 'soc-list-table',
      simple: 'soc-list-simple',
      bubble: 'soc-list-bubble',
    },
  },
  ffw_top_country: {
    title: {
      text: gettext('Top Country/Region (FortiFirewall)'),
      desc: gettext(
        'The highest network traffic by country/region, sessions (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'ffwCountry',
    type: 'treemap',
    tableMode: 'lite',
    components: {
      treemap: 'soc-list-treemap',
      // bubble: "fi-noc-top-country",
      bar: 'soc-list-bar',
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  ffw_top_destination_historical: {
    title: {
      text: gettext('Traffic Over Time By Sessions (FortiFirewall)'),
      desc: gettext('The historical destinations from recent traffic'),
    },
    viewName: 'ffwDestinationView',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  ffw_policy_hits_historical: {
    title: {
      text: gettext('Policy Hits Over Time by Bandwidth (FortiFirewall)'),
      desc: gettext('The historical policy hits from recent traffic'),
    },
    viewName: 'ffwPolicyHits',
    type: 'historical',
    components: {
      historical: 'soc-list-line',
    },
  },
  ffw_policy_hits: {
    title: {
      text: gettext('Top Policy Hits (FortiFirewall)'),
      desc: gettext('Top policy hits from recent traffic'),
    },
    viewName: 'ffwPolicyHits',
    type: 'table',
    tableMode: 'lite',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  ffw_top_destination: {
    title: {
      text: gettext('Top Destinations (FortiFirewall)'),
      desc: gettext('Top destinations from recent traffic'),
    },
    viewName: 'ffwDestinationView',
    type: 'table',
    tableMode: 'lite',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  ffw_top_vpn: {
    title: {
      text: gettext('Top Dialup VPN (FortiFirewall)'),
      desc: gettext(
        'The users accessing the network using SSL or IPsec over a VPN tunnel'
      ),
    },
    viewName: 'ffwSslDialup',
    type: 'world',
    components: {
      world: 'soc-list-map',
      globe: 'soc-list-globe',
      table: 'soc-list-table',
    },
  },
  ffw_vpn_site2site: {
    title: {
      text: gettext('VPN Site-to-Site (FortiFirewall)'),
      desc: gettext(
        'The names of VPN tunnels with Internet protocol security (IPsec) that are accessing the network'
      ),
    },
    viewName: 'ffwSiteToSite',
    type: 'world',
    components: {
      world: 'soc-list-map',
      globe: 'soc-list-globe',
      table: 'soc-list-table',
    },
  },
  // FortiMail
  fml_received_vs_spam: {
    title: {
      text: gettext('Statistics History'),
      desc: gettext('Statistics History'),
    },
    viewName: 'fmlReceivedVsSpam',
    type: 'line',
    components: {
      line: 'soc-list-line',
    },
  },
  fml_mail_stats: {
    title: {
      text: gettext('Mail Statistics'),
      desc: gettext('Mail Statistics'),
    },
    viewName: 'fmlMailStats',
    type: 'stackbar',
    components: {
      stackbar: 'soc-list-bar',
    },
  },
  fml_top_senders: {
    title: {
      text: gettext('Top Sender by Categories'),
      desc: gettext('Top Sender by Categories'),
    },
    viewName: 'fmlTopSenders',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  fml_top_recipients: {
    title: {
      text: gettext('Top Recipient by Categories'),
      desc: gettext('Top Recipient by Categories'),
    },
    viewName: 'fmlTopRecipients',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  fml_sandbox_outbreak: {
    title: {
      text: gettext('Outbreak Statistics (FortiSandbox)'),
      desc: gettext('Outbreak Statistics (FortiSandbox)'),
    },
    viewName: 'fmlSandboxOutbreak',
    type: 'stackbar',
    // intervalType: 'disabled',
    components: {
      stackbar: 'soc-list-bar',
    },
  },
  fml_threat_stats: {
    title: {
      text: gettext('Threat Statistics'),
      desc: gettext('Threat Statistics'),
    },
    viewName: 'fmlThreatStats',
    type: 'stackbar',
    components: {
      stackbar: 'soc-list-bar',
      fml_threat_stats_pie: 'fml-threat-stats-pie',
    },
  },
  fml_stats_summary: {
    title: {
      text: gettext('Statistics Summary'),
      desc: gettext('Statistics Summary'),
    },
    viewName: 'fmlStatsSummary',
    noTop: true,
    type: 'table',
    components: {
      table: 'soc-list-react-component',
    },
    reactComponent: 'FmlStatsSummaryTable',
  },
  // ztna
  ztna_stats: {
    title: {
      text: gettext('Statistics'),
      desc: gettext('Statistics'),
    },
    viewName: 'ztnaStats',
    noTop: true,
    type: 'donut',
    components: {
      donut: 'soc-list-react-component',
    },
    reactComponent: 'ZtnaStats',
  },
  ztna_policy_overview: {
    title: {
      text: gettext('Policy Overview'),
      desc: gettext('Policy Overview'),
    },
    viewName: 'ztnaPolicyOverview',
    noTop: true,
    type: 'simple',
    components: {
      simple: 'soc-list-react-component',
    },
    reactComponent: 'ZtnaPolicyOverview',
  },
  ztna_user_overview: {
    title: {
      text: gettext('User Overview'),
      desc: gettext('User Overview'),
    },
    viewName: 'ztnaUserOverview',
    noTop: true,
    type: 'simple',
    components: {
      simple: 'soc-list-react-component',
    },
    reactComponent: 'ZtnaUserOverview',
  },
  ztna_device_tags: {
    title: {
      text: gettext('ZTNA Device Tags'),
      desc: gettext('ZTNA Device Tags'),
    },
    viewName: 'ztnaDeviceTags',
    noTop: true,
    type: 'simple',
    components: {
      simple: 'soc-list-react-component',
    },
    reactComponent: 'ZtnaDeviceTags',
  },
  ztna_public_apps_access: {
    title: {
      text: gettext('Public Cloud Business Apps Access'),
      desc: gettext('Public Cloud Business Apps Access'),
    },
    viewName: 'ztnaPublicAppsAccess',
    noTop: true,
    type: 'simple',
    components: {
      simple: 'soc-list-react-component',
    },
    reactComponent: 'ZtnaPublicAppsAccess',
  },
  ztna_connection_attempts: {
    title: {
      text: gettext('Connection Attempts'),
      desc: gettext('Connection Attempts'),
    },
    viewName: 'ztnaConnectionAttempts',
    noTop: true,
    type: 'stackbar',
    components: {
      stackbar: 'soc-list-bar',
    },
  },
  ztna_devices: {
    title: {
      text: gettext('Devices'),
      desc: gettext('Devices'),
    },
    viewName: 'ztnaDevices',
    noTop: true,
    type: 'donut',
    components: {
      donut: 'soc-list-pie',
    },
  },
  ztna_known_devices: {
    title: {
      text: gettext('Known Devices with Failed Posture Check'),
      desc: gettext('Known Devices with Failed Posture Check'),
    },
    viewName: 'ztnaKnownDevices',
    type: 'donut',
    components: {
      donut: 'soc-list-pie',
    },
  },
  ztna_bandwidth_trends: {
    title: {
      text: gettext('Bandwidth Trends'),
      desc: gettext('Bandwidth Trends'),
    },
    viewName: 'ztnaBandwidthTrends',
    noTop: true,
    type: 'line',
    components: {
      line: 'soc-list-line',
    },
  },
  ztna_top_users_by_connections: {
    title: {
      text: gettext('Top Users By Connections'),
      desc: gettext('Top Users By Connections'),
    },
    viewName: 'ztnaTopUsersByConnections',
    type: 'stackbar',
    components: {
      stackbar: 'soc-list-bar',
    },
  },
  ztna_private_apps_access: {
    title: {
      text: gettext('Private Apps Access'),
      desc: gettext('Private Apps Access'),
    },
    viewName: 'ztnaPrivateAppsAccess',
    type: 'stackbar',
    components: {
      stackbar: 'soc-list-bar',
    },
  },
  ztna_users: {
    title: {
      text: gettext('Users'),
      desc: gettext('Users'),
    },
    viewName: 'ztnaUsers',
    noTop: true,
    type: 'stackbar',
    components: {
      stackbar: 'soc-list-bar',
    },
  },
  ztna_apps_access_failure: {
    title: {
      text: gettext('Private & Public Applications Access Failure History'),
      desc: gettext('Private & Public Applications Access Failure History'),
    },
    viewName: 'ztnaAppsAccessFailure',
    type: 'line',
    components: {
      line: 'soc-list-line',
    },
  },
  ztna_casb_apps_access: {
    title: {
      text: gettext('CASB Apps Access'),
      desc: gettext('CASB Apps Access'),
    },
    viewName: 'ztnaCasbAppsAccess',
    noTop: true,
    type: 'simple',
    components: {
      simple: 'soc-list-react-component',
    },
    reactComponent: 'ztnaCasbAppsAccess',
  },
  // IoT
  iot_inventory: {
    title: {
      text: gettext('IoT Inventory'),
      desc: gettext('IoT Inventory'),
    },
    type: 'table',
    components: {
      table: 'soc-iot-inventory',
    },
  },
  // Traffic Shaping
  traffic_shaping_bandwidth: {
    title: {
      text: gettext('Bandwidth'),
      desc: gettext('Bandwidth'),
    },
    viewName: 'trafficShapingBandwidth',
    type: 'line',
    components: {
      line: 'soc-list-line',
    },
  },
  traffic_shaping_top_app: {
    title: {
      text: gettext('Top Applications and Traffic Shaping'),
      desc: gettext('Top Applications and Traffic Shaping'),
    },
    viewName: 'trafficShapingTopApp',
    type: 'stackbar',
    components: {
      stackbar: 'soc-list-bar',
    },
  },
  traffic_shaping_dropped_bytes: {
    title: {
      text: gettext('Dropped Bytes Over Time Per Shaper'),
      desc: gettext('Dropped Bytes Over Time Per Shaper'),
    },
    viewName: 'trafficShapingDroppedBytes',
    type: 'line',
    components: {
      line: 'soc-list-line',
    },
  },
  // FortiProxy
  fpx_top_sources: {
    title: {
      text: gettext('Top Sources (FortiProxy)'),
      desc: gettext('Top Proxy Sources'),
    },
    viewName: 'fpxTopSources',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fpx_top_destinations: {
    title: {
      text: gettext('Top Destinations (FortiProxy)'),
      desc: gettext('Top Proxy Destinations'),
    },
    viewName: 'fpxTopDestinations',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fpx_website_domain: {
    title: {
      text: gettext('Top Website Domains (FortiProxy)'),
      desc: gettext('Top Website Domains'),
    },
    viewName: 'fpxWebsiteDomain',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fpx_top_applications: {
    title: {
      text: gettext('Top Applications (FortiProxy)'),
      desc: gettext('Top Applications'),
    },
    viewName: 'fpxTopApplications',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fpx_top_threats: {
    title: {
      text: gettext('Top Threats (FortiProxy)'),
      desc: gettext('Top Threats'),
    },
    viewName: 'fpxTopThreats',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fpx_threat_src_dst: {
    title: {
      text: gettext('Top Threats Destinations (FortiProxy)'),
      desc: gettext('Top Threats Destinations'),
    },
    viewName: 'fpxThreatSrcDst',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  fpx_top_dlp_events: {
    title: {
      text: gettext('Top DLP Events (FortiProxy)'),
      desc: gettext('Top DLP Events'),
    },
    viewName: 'fpxTopDlpEvents',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  dlp_trends: {
    title: {
      text: gettext('DLP Trends'),
      desc: gettext('DLP Trends in terms of allowed and blocked events'),
    },
    viewName: 'dlpTrends',
    type: 'line',
    components: {
      line: 'soc-list-line',
    },
  },
  dlp_top_dst_countries: {
    title: {
      text: gettext('Top Destination Countries'),
      desc: gettext(
        'Top Destination Countries by DLP Occurrences (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'dlpTopDstCountries',
    type: 'sankey',
    components: {
      sankey: 'soc-list-sankey',
    },
  },
  dlp_top_users: {
    title: {
      text: gettext('Top Users'),
      desc: gettext('Users'),
    },
    viewName: 'dlpTopUsers',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  dlp_top_protocols: {
    title: {
      text: gettext('Top Protocols'),
      desc: gettext('Top Protocols'),
    },
    viewName: 'dlpTopProtocols',
    type: 'bar',
    components: {
      bar: 'soc-list-bar',
    },
  },
  dlp_top_events: {
    title: {
      text: gettext('Top DLP Events'),
      desc: gettext('Top DLP Events'),
    },
    viewName: 'dlpTopEvents',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  dlp_profile_hit: {
    title: {
      text: gettext('Top DLP Profile Hits'),
      desc: gettext('Top DLP Profile Hits'),
    },
    viewName: 'dlpProfileHit',
    type: 'sankey',
    components: {
      sankey: 'soc-list-sankey',
    },
  },
  dlp_top_sensitive_files: {
    title: {
      text: gettext('Sensitive Files being Accessed'),
      desc: gettext('Sensitive Files being Accessed'),
    },
    viewName: 'dlpTopSensitiveFiles',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
};
