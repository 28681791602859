import { get, isNil } from 'lodash';
import { fiFmgHttp } from 'fi-http';

export { getDeviceCfg, setDeviceCfg, requestDeviceCfg };

const deviceConfigMap = {};

const getDeviceCfg = (oid) => {
  if (isNil(oid)) return null;
  return get(deviceConfigMap, oid, null);
};

const setDeviceCfg = (oid, devCfg) => {
  if (!isNil(oid) && !isNil(devCfg)) {
    deviceConfigMap[oid] = devCfg;
  }
};

const requestDeviceCfg = async (dev, vdom = '') => {
  const oid = dev.oid ? dev.oid : dev.name ? dev.name : dev;
  return new Promise((resolve, reject) => {
    fiFmgHttp
      .post({
        method: 'get',
        url: '/gui/adom/dvm/device/supported-cfg',
        params: {
          oid: parseInt(oid, 10),
          vdom,
        },
      })
      .then(
        function (resp) {
          const data = resp?.[0].data || {};
          setDeviceCfg(oid, resp);
          resolve(data);
        },
        function (resp) {
          reject(resp);
        }
      );
  });
};
