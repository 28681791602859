export const getUnregDevicesState = (state) => state.adom.unregDevices || {};

export const getUnregDevicesList = (state) => {
  return getUnregDevicesState(state).list || {};
};

export const getUnregDevicesAllIds = (state) => {
  return getUnregDevicesList(state).allIds || [];
};

export const getUnregDevicesById = (state) => {
  return getUnregDevicesList(state).bId || {};
};
