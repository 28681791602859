import { showDisclaimer } from 'react_apps/ra-auth/modals/alert';

export const genPreBanner = (pendingRef) => async (loginEnv) => {
  if (!loginEnv.pre_login_banner_message) {
    return;
  }
  if (pendingRef.current) {
    // stop others from doing anything for the 2nd run
    return true;
  }

  pendingRef.current = true;
  try {
    await showDisclaimer({
      title: gettext('Login Disclaimer'),
      message: loginEnv.pre_login_banner_message,
      isPrev: true,
      autoId: 'system_pre-disclaimer',
    });
  } catch {
    // user rejects the message
    window.location.reload(true);
  }
  // return undefined so next handler will continue the execution
};
