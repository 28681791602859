import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  forwardRef,
} from 'react';
import { /*ProToolkit, */ ProForm } from '@fafm/neowise-pro';
import { /*NwButton,*/ NwProgressBar, NwSpinner } from '@fafm/neowise-core';
import { Formik } from 'formik';
import { FmkErrorSpan } from 'rc_form';
import { DropUploader, FileLister } from 'rc_drop_uploader';
import { SysDashboardService } from 'fi-dashboard';
import { UploaderService } from 'fi-file';
import { OkBtn, CancelBtn } from 'rc_layout';
import { openConfirmModal } from 'react_components/rc_layout/ConfirmModal';

const { Section, Header, Body, Footer, Row, Column } = ProForm;

const DisplayUsedHist = ({ usedHist }) => (
  <table className='table'>
    <thead>
      <tr>
        <th>{gettext('Date')}</th>
        <th>{gettext('Daily GB')}</th>
      </tr>
    </thead>
    <tbody>
      {usedHist.map((detail) => (
        <tr key={detail.date}>
          <td>{detail.date}</td>
          <td>{detail.used}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const Upload = forwardRef(({ fileChangedCallback, lockCallback }, ref) => {
  // eslint-disable-line
  const [data /*, setData*/] = useState({ filepath: '' });
  const [uploadingFile, setUploadingFile] = useState(null);
  const [progress, setProgress] = useState({ message: '', messageClass: '' });
  const filePondRef = useRef(null);

  useEffect(() => {
    fileChangedCallback(!!ref?.current?.values?.filepath);
  }, [ref?.current?.values?.filepath]);

  const uploader = useMemo(
    () =>
      UploaderService.create({
        url: '/flatui/auth/gui/vmlicense/upload',
        key: 'filepath',
        data: {
          actionevent: 'edit',
          fromAjax: 1,
        },
        onPrgress: (fileInfo) => setUploadingFile(fileInfo),
        onCompleted: (fileInfo, resp) => {
          setUploadingFile(null);
          const content = resp.result ? resp.result[0] : {};
          if (content) {
            if (content.status && !content.status.code) {
              setProgress((prev) => ({
                ...prev,
                message: gettext(
                  'The license file has been uploaded successfully and the system is restarting to verify it. Please wait for a few moments...'
                ),
                messageClass: '',
              }));
              lockCallback(true);
            } else {
              setProgress((prev) => ({
                ...prev,
                message: gettext('Invalid file.'),
                messageClass: 'color-red',
              }));
              fileChangedCallback(false); // disble upload button
              ref.current.setSubmitting(false);
            }
          }
        },
      }),
    []
  );
  const submitForm = async (values) => {
    try {
      await openConfirmModal({
        content: gettext(
          'Uploading a device license will cause the system to reboot. Are you sure you want to proceed?'
        ),
        title: gettext('Confirm System Reboot'),
      });
    } catch {
      return;
    }
    setProgress((prev) => ({
      ...prev,
      message: gettext('Uploading the selected license file...'),
      messageClass: '',
    }));
    uploader.removeAll();
    setUploadingFile(null);
    uploader.addFiles([values.filepath]);
    uploader.start();
  };

  const validate = (values) => {
    const errors = {};
    if (!values.filepath) {
      errors.filepath = gettext('Please choose the license file.');
    }
    return errors;
  };
  const onAddFile = useCallback((e, setFieldValue) => {
    setFieldValue('filepath', e.detail?.file);
    setProgress((prep) => ({
      ...prep,
      message: '',
      messageClass: '',
    }));
  }, []);

  const percent = useMemo(() => {
    if (uploadingFile?.size > 0) {
      return uploadingFile.loaded <= uploadingFile.size
        ? ((uploadingFile.loaded / uploadingFile.size) * 100).toFixed(0)
        : 100;
    }
  }, [uploadingFile?.size]);

  const clearFileListerHandler = useCallback((setFieldValue, setSubmitting) => {
    setFieldValue('filepath', null);
    setProgress((prev) => ({
      ...prev,
      message: '',
      messageClass: '',
    }));
    setSubmitting(false);
    [setProgress];
  });

  return (
    <Formik
      initialValues={data}
      enableReinitialize={true}
      onSubmit={submitForm}
      validate={validate}
      innerRef={ref}
    >
      {({ values, setFieldValue, setSubmitting }) => (
        <>
          <DropUploader
            ref={filePondRef}
            onProcess={(e) => onAddFile(e, setFieldValue)}
          />
          <FmkErrorSpan name='filepath' />
          {values.filepath ? (
            <FileLister
              file={values.filepath}
              clear={() => {
                clearFileListerHandler(setFieldValue, setSubmitting);
              }}
            />
          ) : null}
          {progress.message ? (
            <div className={progress.messageClass}>{progress.message}</div>
          ) : null}
          {uploadingFile?.size > 0 ? (
            <NwProgressBar percentage={percent}>
              {uploadingFile.humanSize}
            </NwProgressBar>
          ) : null}
        </>
      )}
    </Formik>
  );
});

const LogExceeded = ({ $opener, notif }) => {
  const formikRef = useRef(null);
  const [usedHist, setUsedHist] = useState(null);
  useEffect(() => {
    SysDashboardService.loadLicense().then((resp) => {
      setUsedHist(resp?.used_gb_day_history || []);
    });
  }, []);
  const [fileChanged, setFileChanged] = useState(false);
  const [lock, setLock] = useState(false);
  const hasUploadLicense = useMemo(
    () => MACROS.SYS.IS_VM && !MACROS.SYS.HAVE_FTNT_CLOUD,
    []
  );
  return (
    <>
      <Header>{gettext('Daily Log Limit Exceeded')}</Header>
      <Body>
        <Section>
          <Row label=''>
            <Column>
              <div>
                <nw-icon name='exclamation' />
                <strong>
                  {gettext(
                    'You have exceeded your daily logs GB/day licensing limit within the last 7 days.'
                  )}
                </strong>
              </div>
            </Column>
          </Row>
          <Row label={gettext('Licensed GB/Day')}>
            <Column>
              <span>{notif.daily_max} GB</span>
            </Column>
          </Row>
          <Row label={gettext('used GB/Day')}>
            <Column>
              {usedHist ? (
                usedHist?.length ? (
                  <DisplayUsedHist usedHist={usedHist} />
                ) : (
                  gettext('No data.')
                )
              ) : (
                <>
                  <NwSpinner label={gettext('Loading...')} />
                </>
              )}
            </Column>
          </Row>
        </Section>
        {hasUploadLicense ? (
          <Section>
            <Row label={gettext('Upload a New License')}>
              <Column>
                <Upload
                  fileChangedCallback={setFileChanged}
                  lockCallback={setLock}
                  ref={formikRef}
                />
              </Column>
            </Row>
          </Section>
        ) : null}
      </Body>
      <Footer>
        {hasUploadLicense && !lock ? (
          <OkBtn
            name='ok'
            automation-id='btn-ok'
            onClick={formikRef?.current?.submitForm}
            loading={formikRef?.current?.isSubmitting}
            disabled={!fileChanged}
          >
            {gettext('Upload License')}
          </OkBtn>
        ) : null}
        <CancelBtn
          automation-id='btn-close'
          onClick={() => {
            $opener.resolve();
          }}
        >
          {gettext('Close')}
        </CancelBtn>
      </Footer>
    </>
  );
};

export default LogExceeded;
