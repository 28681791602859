import { fiSession } from 'fistore';
import allAppTrees from 'fi-web/fi-routing/app-tree.jsonc';
/******
 * Please add a prefix to all the apps for better grouping. There is no order
 * implication for this config. You also need to add the app's key to the
 * app-tree.jsonc which set the order.
 ******/
import { negate } from 'lodash';

import dashboard from './dashboard';
import dvm from './dvm';

import pno from './pno/pno';

import vpn from './vpn';
import fext from './fext';
import docker from './docker';
import faz from './faz';
import fortiap from './fortiap';
import fortisw from './fortisw';
import report from './report';
import fgd from './fgd';
import sys from './sys';
import fortiview from './fortiview';
import logview from './logview';
import fabric from './fabric';
import rstadm from './rstadm';
import sdwan from './sdwan';
const { inGlobalAdom, getIsFazSupervisor } = fiSession;

// Order is not reserved, use the side_menu/menu.yaml for ordering.
export default function getAllApps() {
  return {
    allApps: {
      ...dashboard,
      ...dvm,
      ...pno,
      ...sdwan,
      ...vpn,
      ...fext,
      ...docker,
      ...fortiap,
      ...fortisw,
      ...fabric,
      ...fgd,
      ...sys,
      ...rstadm,

      // faz items
      ...faz,
      ...report,
      ...fortiview,
      ...logview,
      fortiai: {
        path: '/fortiai',
        hideSubItems: true,
        label: 'FortiAI',
        color: 'rgb(var(--nw-color-info-600))',
        icon: 'fortiai-advisor',
        state: 'adom.default.fortiai',
        reloadState: 'adom.default.fortiai',
        showCheckers: [negate(inGlobalAdom), negate(getIsFazSupervisor)],
      },
    },
    allAppTrees,
  };
}
