import { flatui_proxy } from '../../utils';
import { fiFmgHttp, getResponseData } from 'fi-http';

export const fetch_device_firmware = () => {
  const upgradePathUrl = '/gui/adom/dvm/device/firmware';
  const fwTableUrl = '/gui/adom/dvm/firmware/management';
  const promises = [
    flatui_proxy({ url: upgradePathUrl, method: 'getUpdatePath' }),
    flatui_proxy({ url: fwTableUrl, method: 'loadFirmwareDataGroupByVersion' }),
  ];
  return Promise.all(promises);
};

export const fetch_firmware_upgrade_schedule = async (
  adomName,
  devices = null,
  groups = null
) => {
  const data = {
    adom: adomName,
  };
  if (devices && !Array.isArray(devices)) {
    devices = [devices];
  }
  if (groups && !Array.isArray(groups)) {
    groups = [groups];
  }
  if (devices && devices.length > 0) {
    data.devices = devices.map((dev) => {
      return {
        name: dev.name,
      };
    });
  } else if (groups && groups.length > 0) {
    data.groups = groups.map((group) => {
      return {
        name: group.name,
      };
    });
  }
  const req = {
    method: 'get',
    params: [
      {
        data,
        url: 'um/image/template/list',
      },
    ],
  };
  try {
    const resp = await fiFmgHttp.query(req);
    return getResponseData(resp)?.profile || [];
  } catch (err) {
    if (MACROS.SYS.CONFIG_DEBUG) {
      console.error('fetch_firmware_upgrade_schedule failed', err);
    }
    return [];
  }
};

export const delete_firmware_upgrade_schedule = (
  adomName,
  profileName,
  devices = null
) => {
  const data = {
    adom: adomName,
    name: profileName,
  };
  if (devices && !Array.isArray(devices)) {
    devices = [devices];
  }
  if (devices && devices.length > 0) {
    data.devices = devices.map((dev) => {
      return {
        name: dev.name,
      };
    });
  }
  const reqObj = {
    method: 'exec',
    params: [
      {
        data,
        url: 'um/image/template/cancel',
      },
    ],
  };
  return fiFmgHttp.query(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};
