import { useApiRequest, useValidEffect } from 'react_hooks/rh_util_hooks';
import { fiAdom, fiSysConfig } from 'fi-web/fi-session';
import { fiStrToDate } from 'kit/kit-time';
import { fiSession, fiStore } from 'fistore';
import { getSysMoment } from 'fi-web/fi-datetime';
import { fiWorkspace } from 'fi-apps/fi-workspace';
import { useMemo, useState } from 'react';

export function useAdomList() {
  const {
    state: adomList,
    isLoading: isLoadingAdomList,
    refresh: refreshAdomList,
    setState: setAdomList,
  } = useApiRequest({
    defaultValue: [],
    loader: async () => {
      const adomDetails = await fiAdom.loadAdomSwitchListDetails();
      const adoms = await fiAdom.loadAdomSwitchList();

      return { adoms, adomDetails };
    },

    parser: ({ adoms = [], adomDetails = [] }) => {
      const detailMap = adomDetails.reduce((acc, cur) => {
        acc[cur.oid] = cur;
        return acc;
      }, {});

      adoms.forEach((adom) => {
        let detail = detailMap[adom.oid];
        if (detail) adom.members = detail.members;
      });

      const parsed = parseADOMs(adoms);
      return parsed.filter(Boolean);
    },
  });

  return {
    adomList,
    setAdomList,
    isLoadingAdomList,
    refreshAdomList,
  };
}

export function useCannotDeleteList(adomList = []) {
  return useMemo(() => {
    return adomList
      .filter(Boolean)
      .filter((adom) => adom.predefined)
      .map((adom) => adom.name);
  }, [adomList]);
}

export function useCheckAdomLicense() {
  const [expiredAdomLicenseMessage, setExpiredAdomLicenseMessage] =
    useState('');

  useValidEffect((isValid) => {
    if (!isValid()) return;

    const message = checkAdomLicense();
    setExpiredAdomLicenseMessage(message);
  }, []);

  return expiredAdomLicenseMessage;
}

function parseADOMs(adoms = []) {
  const sysConfig = fiSysConfig.current();
  const isVMSLicense = sysConfig.is_vms_lic;

  // setLoading(true);
  let temp = [];
  let expiredText = '';

  adoms.forEach(function (adom) {
    // canAccessAdom is broken
    // if (canAccessAdom(adom)) {
    temp.push(adom);
    adom.lock = adom.lock || {
      lock: MACROS.SYS.LOCK_STATE_UNLOCKED,
      lock_time: '',
      lock_user: '',
    };
    adom.lock.lock_msg = fiWorkspace.lockMessage(adom.lock);

    if (isVMSLicense) {
      adom.expired =
        (adom.flags & MACROS.ADOM.DVM_ADOM_FLAG_FMGVMS_ISOLATED) === 0 ? 0 : 1;
      if (adom.expired) {
        adom.expiredText = gettext('ADOM license expired');
      }
    } else {
      adom.expiredText = expiredText;
    }
  });

  return temp;
}

function checkAdomLicense() {
  const sysConfig = fiSysConfig.current();
  const isVMSLicense = sysConfig.is_vms_lic;

  const adomLicense = sysConfig.contracts.find((x) => x.type === 'ADOM');

  let expiredText = '';

  if (isVMSLicense && adomLicense) {
    let _expireDay = adomLicense ? adomLicense.time : null; // license time format yyyymmdd
    _expireDay = fiStrToDate(_expireDay);
    const expireLeftDay = fiSession.getSysTimeDiff(_expireDay.getTime())(
      fiStore.getState()
    );
    if (expireLeftDay < 0) {
      expiredText = gettext(
        'ADOM license is expiring in %(expireLeftDay)s days (%(date)s).'
      ).printfd({
        expireLeftDay: expireLeftDay,
        date: getSysMoment(_expireDay).format('YYYY-MM-DD'),
      });
    }

    return expiredText;
  }
}
