import { useRef, useState } from 'react';

import { fiHttp } from 'fi-http';
import { ProForm } from '@fafm/neowise-pro';
import {
  AutocompleteCodeMirror,
  useAutoHeightCodemirror,
} from 'react_components/rc_autocomplete_codemirror';
import { useApiRequest } from 'react_hooks/rh_util_hooks';
import { CancelBtn, OkBtn } from 'react_components/rc_layout';

const { Header, Body, Section, Footer } = ProForm;

// poid here is for ha-slave to get ha-master info
export const ViewInstallLog = ({ $opener, oid, taskId, poid }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const bodyRef = useRef();

  const { state: previewData, isLoading } = useApiRequest({
    defaultValue: '',
    loader: async () => {
      const resp = await download('preview', oid, taskId, poid);
      return resp.data;
    },
  });

  useAutoHeightCodemirror({
    bodyEl: bodyRef.current,
    offset: 0,
    dependencies: [isLoading, previewData],
  });

  const downloadLog = async () => {
    try {
      setIsDownloading(true);
      const resp = await download('download', oid, taskId, poid);
      const text = resp.data;
      const filename = resp.headers['content-disposition'].split('=')[1];
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
      );
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } finally {
      setIsDownloading(false);
    }
  };

  const renderCodemirror = () => {
    return (
      <AutocompleteCodeMirror
        initialValue={previewData}
        dependencies={[previewData]}
        codeMirrorOptions={{
          showResetBtn: false,
          readOnly: true, // disable editing
          autofocus: false,
        }}
        automation-id={'view-install-log'}
      />
    );
  };

  return (
    <>
      <Header>{gettext('View Install Log')}</Header>
      <Body ref={bodyRef}>
        <Section>{renderCodemirror()}</Section>
      </Body>
      <Footer>
        <OkBtn loading={isDownloading} onClick={downloadLog}>
          {gettext('Download')}
        </OkBtn>
        <CancelBtn onClick={() => $opener.reject()}>
          {gettext('Close')}
        </CancelBtn>
      </Footer>
    </>
  );
};

function download(action, oid, taskId, poid) {
  var url = `/gui/deployment/adom/devices/${oid}/tasks/${taskId}/log/${action}`;
  return fiHttp.post(
    '/cgi-bin/module/flatui_proxy',
    {
      url,
      method: 'get',
      params: {
        poid, //when in ha-slave, poid is the ha-master oid.
      },
    },
    {
      params: {
        nocache: Date.now(),
      },
    }
  );
}
