import { ProForm } from '@fafm/neowise-pro';
import { OkBtn, CancelBtn } from 'rc_layout';
import { useCallback } from 'react';

const { Header, Body, Footer } = ProForm;

export function DisclaimerMessage({
  $opener,
  title,
  message,
  autoId,
  showDecline = true,
}) {
  const onDecline = useCallback(() => {
    $opener.reject();
  }, []);

  return (
    <>
      <Header>{title}</Header>
      <Body>
        <p>{gettext('Please read the following agreement carefully.')}</p>
        <div className='tw-break-words tw-text-justify tw-leading-normal tw-whitespace-pre-line'>
          {message}
        </div>
      </Body>
      <Footer>
        <OkBtn
          onClick={() => $opener.resolve()}
          automation-id={autoId + ':accept'}
        >
          {gettext('Accept')}
        </OkBtn>
        {showDecline && (
          <CancelBtn onClick={onDecline} automation-id={autoId + ':decline'}>
            {gettext('Decline')}
          </CancelBtn>
        )}
      </Footer>
    </>
  );
}
