import { fiProxyHttp } from 'fi-http';

export const get = function (showExpired) {
  var params = showExpired
    ? {}
    : {
        expired: 0,
      };
  return fiProxyHttp.get('/gui/adom/um/license', 'get', params);
};
