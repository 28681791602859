import { /*select,*/ put, takeLatest } from 'redux-saga/effects';

import { fetchAuthNeededList } from './api';
import * as actions from './actions';
import { callPromiseAction } from '../../utils';
import { runIfAvailable } from '../../routing/saga';
import { hasFazFeature } from 'fistore/session/sysConfig/selectors';
// import { get_devices } from '../selector';
// import { getSessionAdomOid } from '../../session';

/*
  keep for FAZ auth needed
*/
export function* watchDeviceAuthNeededAction() {
  yield takeLatest(
    actions.fetchDeviceAuthNeededAction.type,
    runIfAvailable(hasFazFeature, deviceAuthNeededNotify)
  );
}

function* deviceAuthNeededNotify(action) {
  yield callPromiseAction(action, function* _devAuthNeeded() {
    // START OF TEST CODE, REMOVE AFTER TESTING
    // const adomOid = yield select(getSessionAdomOid);
    // const allIds = yield select((state) =>
    //   Object.values(get_devices(state, adomOid)?.byId || {})?.map(
    //     (dev) => dev.sn
    //   )
    // );
    // const payload = {
    //   auth_needed_csfroot: {
    //     FGVM02TM23008174: 'fabric',
    //   },
    //   auth_needed_other: allIds,
    // }
    // yield put(
    //   actions.deviceAuthNeededAction.record.set(payload)
    // );
    // return payload;
    // END OF TEST CODE, REMOVE AFTER TESTING

    const authNeededList = yield fetchAuthNeededList();
    if (!authNeededList) return;
    yield put(actions.deviceAuthNeededAction.record.set(authNeededList));
    return authNeededList;
  });
}
