import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import { dispatch, fiStoreFabric } from 'fistore';
import { ReactContentViewContainer } from 'ra_main/main_layout/ReactContentViewContainer';
import { getContextMenuActions } from './customview/customviewContextMenuActions';
import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';
import { withAdomRefresh } from './utils';

export default {
  path: 'fabric',
  id: 'fabric', // for getting route loader data
  handle: {
    appUniKey: 'fabric',
  },
  RenderErrorBoundary,
  children: [
    getRedirectIndexRoute(),
    {
      path: 'physical',
      handle: {
        appUniKey: 'fabric_physicaltopo',
      },
      lazy: async () => {
        let { Topology } = await import(
          /* webpackChunkName: "mod-fabric-physicaltopo" */ './components/Topology/Topology'
        );
        return {
          Component: () => Topology({ rDisplayMode: 'physicalTopology' }),
        };
      },
    },
    {
      path: 'logical',
      handle: {
        appUniKey: 'fabric_logicaltopo',
      },
      lazy: async () => {
        let { Topology } = await import(
          /* webpackChunkName: "mod-fabric-logicaltopo" */ './components/Topology/Topology'
        );
        return {
          Component: () => Topology({ rDisplayMode: 'logicalTopology' }),
        };
      },
    },
    {
      path: 'audit',
      handle: {
        appUniKey: 'fabric_audit',
      },
      lazy: async () => {
        let { SecurityRating } = await import(
          /* webpackChunkName: "mod-fabric-audit" */ './components/SecurityRating/Components/SecurityRating'
        );
        return { Component: SecurityRating };
      },
    },
    {
      path: 'conf',
      children: [
        {
          path: 'connector',
          handle: {
            appUniKey: 'fabric_config_connectors',
          },
          lazy: async () => {
            let { ConnectorsTileTable } = await import(
              /* webpackChunkName: "mod-fabric-config-connectors" */ './components/connectors/ConnectorsTileTable'
            );
            return { Component: ConnectorsTileTable };
          },
        },
        {
          path: 'external_connector',
          handle: {
            appUniKey: 'fabric_config_extconnectors',
          },
          lazy: async () => {
            let { ExtConnectorsTileTable } = await import(
              /* webpackChunkName: "mod-fabric-config-extconnectors" */ './components/connectors/ConnectorsTileTable'
            );
            return { Component: ExtConnectorsTileTable };
          },
        },
        {
          path: 'subnet',
          handle: {
            appUniKey: 'fabric_config_subnets',
          },
          lazy: async () => {
            let { SubnetsTable } = await import(
              /* webpackChunkName: "mod-fabric-config-subnets" */ './components/subnets/SubnetsTable'
            );
            return { Component: SubnetsTable };
          },
        },
      ],
    },
    {
      path: 'cloud_orchestration',
      handle: {
        appUniKey: 'fabric_cloudorchestration',
      },
      element: <ReactContentViewContainer menuId='fabric_cloudorchestration' />,
      children: [
        {
          path: 'main',
          handle: {
            appUniKey: 'fabric_cloudorchestration_main',
          },
          lazy: async () => {
            let { CloudOrchestrationTable } = await import(
              /* webpackChunkName: "mod-fabric-cloudorchestration-main" */ 'react_apps/ra_pno_obj_tables/list_view/cloud_orchestration_table'
            );
            return { Component: CloudOrchestrationTable };
          },
        },
        {
          path: 'connector',
          handle: {
            appUniKey: 'fabric_cloudorchestration_conn',
          },
          lazy: async () => {
            let { CloudOrchestrationAWSConnTable } = await import(
              /* webpackChunkName: "mod-fabric-cloudorchestration-conn" */ 'react_apps/ra_pno_obj_tables/list_view/cloud_orchestration_awsconn_table'
            );
            return { Component: CloudOrchestrationAWSConnTable };
          },
        },
        {
          path: 'templates',
          handle: {
            appUniKey: 'fabric_cloudorchestration_templates',
          },
          lazy: async () => {
            let { CloudOrchestrationAWSTemplatesTable } = await import(
              /* webpackChunkName: "mod-fabric-cloudorchestration-templates" */ 'react_apps/ra_pno_obj_tables/list_view/cloud_orchestration_aws_templates_table'
            );
            return { Component: CloudOrchestrationAWSTemplatesTable };
          },
        },
      ],
    },
    {
      path: 'asset-index',
      handle: {
        appUniKey: 'fabric_asset',
      },
      loader: async () => {
        dispatch(fiStoreFabric.fetchFabricCustomMenu());
        return null;
      },
      element: (
        <ReactContentViewContainer
          menuId='fabric_asset'
          menuItemActions={getContextMenuActions()}
        />
      ),
      children: [
        {
          path: 'asset-dashboard',
          handle: {
            appUniKey: 'fabric_asset_dashboard',
          },
          lazy: async () => {
            let { AssetIdentityDashboard } = await import(
              /* webpackChunkName: "mod-fabric-asset-dashboard" */ './components/dashboards/assetIdentity/AssetIdentityDashboard'
            );
            const WrappedAssetIdentityDashboard = withAdomRefresh(
              AssetIdentityDashboard,
              'fabric_asset_dashboard'
            );
            return { Component: WrappedAssetIdentityDashboard };
          },
        },
        {
          path: 'view/:view?/:id?',
          handle: {
            appUniKey: 'fabric_asset_view',
          },
          lazy: async () => {
            let { AssetView } = await import(
              /* webpackChunkName: "mod-fabric-asset-view" */ './components/assetIdentity/AssetView'
            );
            return { Component: () => AssetView({ activeView: 'asset' }) };
          },
        },
        {
          path: 'asset/customview/:appUniKey/:customViewId',
          handle: {
            appUniKey: function (params = {}) {
              const { appUniKey } = params;
              return appUniKey;
            },
          },
          lazy: async () => {
            let { AssetIdentityTable } = await import(
              /* webpackChunkName: "mod-fabric-asset-custom-asset" */ './components/assetIdentity/AssetIdentityTable'
            );
            return {
              Component: () =>
                AssetIdentityTable({ view: 'asset', activePanel: 'asset' }),
            };
          },
        },
        {
          path: 'asset/ot/customview/:appUniKey/:customViewId',
          handle: {
            appUniKey: function (params = {}) {
              const { appUniKey } = params;
              return appUniKey;
            },
          },
          lazy: async () => {
            let { OtViewContainer } = await import(
              /* webpackChunkName: "mod-fabric-asset-custom-asset-ot" */ './components/assetIdentity/otView/OtViewContainer'
            );
            return { Component: () => OtViewContainer({ view: 'asset' }) };
          },
        },
      ],
    },
    {
      path: 'identity-index',
      handle: {
        appUniKey: 'fabric_identity',
      },
      loader: async () => {
        dispatch(fiStoreFabric.fetchFabricCustomMenu());
        return null;
      },
      element: (
        <ReactContentViewContainer
          menuId='fabric_identity'
          menuItemActions={getContextMenuActions()}
        />
      ),
      children: [
        {
          path: 'identity/customview/:appUniKey/:customViewId',
          handle: {
            appUniKey: function (params = {}) {
              const { appUniKey } = params;
              return appUniKey;
            },
          },
          lazy: async () => {
            let { AssetIdentityTable } = await import(
              /* webpackChunkName: "mod-fabric-asset-custom-identity" */ './components/assetIdentity/AssetIdentityTable'
            );
            return {
              Component: () =>
                AssetIdentityTable({
                  view: 'identity',
                  activePanel: 'identity',
                }),
            };
          },
        },
      ],
    },
  ],
};
