type ConstMap = {
  [key: string | number]: string;
};

export const TaskTextMap: ConstMap = {
  discoverdev: gettext('Discovering device'),
  adddev: gettext('Creating device database'),
  updhacluster: gettext('Retrieving high availability status'),
  waithacluster: gettext('Waiting for HA cluster'),
  converthacluster: gettext('Convert model HA cluster'),
  converthaclusterok: gettext('Convert model HA cluster successful'),
  initconfdb: gettext('Initializing configuration database'),
  loadipssig: gettext('Retrieving the IPS signatures'),
  updintf: gettext('Retrieving interface information'),
  retrieveconf: gettext('Retrieving configuration'),
  retrievehaconf: gettext('Retrieving HA configuration'),
  createrev: gettext('Creating initial configuration file'),
  loaddata: gettext('Retrieving support data'),
  devaddgrpupd: gettext('Updating group membership'),
  devaddsuccess: gettext('Successfully add device'),
  devhaaddsuccess: gettext('Successfully add Model HA Cluster'),
  retrievedevconftitle: gettext('Retrieve device configuration'),
  restrictedprod: gettext('Device type not supported by the ADOM'),
  upddevtitle: gettext('Update Device'),
  changehaseqtitle: gettext('Change HA Device Sequence'),
  changehasequnsupport: gettext(
    'Changing the HA device sequence can only be performed on FortiGate and FortiCarrier devices.'
  ),
  changehaseqoffline: gettext(
    'The HA device sequence cannot be changed while in offline mode.'
  ),
  changehaseqfail: gettext('Failed to change the HA device sequence.'),
  reloaddevconftitle: gettext('Reload Device Configuration'),
  adddevtitle: gettext('Add Device'),
  importdevtitle: gettext('Import Devices'),
  addmultdevtitle: gettext('Add Multiple Devices'),
  adddeldevtitle: gettext('Add/delete Unauthorized Devices'),
  devreplacetitle: gettext('Updating Device Serial Number'),
  autolinktitle: gettext('Autolinking Device'),
  autolinkhatitle: gettext('Setup HA Cluster'),
  deldevtitle: gettext('Delete Device'),
  delmultdevtitle: gettext('Delete Multiple Devices'),
  refreshipssigtitle: gettext('Refresh IPS Signatures'),
  devnotfound: gettext('Device not found.'),
  managedbyhamgmt: gettext(
    'device cannot be managed through ha management interface'
  ),
  managedbyother: gettext(
    'The device is being managed by another FortiManager'
  ),
  fgfmstarterror: gettext(
    'Cannot communicate with remote device (tunnel is down)'
  ),
  fgfmoffline: gettext('Cannot communicate with remote device (offline mode)'),
  fgfmencmismatch: gettext(
    'Cannot communicate with remote device (encryption mismatch)'
  ),
  fgfmcertmismatch: gettext(
    'Cannot communicate with remote device (certificate mismatch)'
  ),
  savingdevinfo: gettext('Saving device information.'),
  updatesuccess: gettext('Successfully updated device information.'),
  updatecancel: gettext('Update canceled.'),
  addcancelled: gettext('Device add canceled.'),
  reloadlockreq: gettext(
    'The device must be locked before it can be reloaded.'
  ),
  reloadfortimail: gettext('Reload is not supported for FortiMail devices.'),
  reloadoffline: gettext('Offline devices cannot be reloaded.'),
  updateoffline: gettext('Offline devices cannot be updated.'),
  unregoffline: gettext('Unauthorized devices cannot be reloaded.'),
  updateelbcsearch: gettext('Searching for ELBC primary'),
  reloadrunning: gettext('This device is already being reloaded.'),
  upddevinfo: gettext('Updating device information.'),
  upddevinfofail: gettext('Failed to update device information.'),
  updsninuse1: gettext('Unable to update'),
  updsninuse2: gettext('.  The serial number is already in use by'),
  retrieveconffail: gettext('Failed to retrieve configuration from device.'),
  checkoutconf: gettext('Checking out configuration.'),
  exportconf: gettext('Exporting configuration.'),
  exportconffail: gettext('Failed to exporting the configuration.'),
  createrevfail: gettext('Failed to create a new revision.'),
  tmpfilefail: gettext('Failed to create temporary file.'),
  checkoutconffail: gettext('Failed to checkout configuration.'),
  reloadconffail: gettext('Failed to reload configuration.'),
  loadipssigfail: gettext('Failed to retrieve the IPS signatures'),
  loaddatafail: gettext('Failed to retrieve the support data'),
  retrievefin: gettext('Retrieve complete.'),
  reloadfin: gettext('Retrieval complete.'),
  reloadcancel: gettext('Reload device canceled.'),
  promoteunreg: gettext('Adding unauthorized device'),
  promoteunregfail: gettext('Failed to add device.'),
  promotesuccess: gettext('Checking device status'),
  fazstatusenabled: gettext('FortiAnalyzer status is enabled'),
  discoversuccess: gettext('Successfully discovered device.'),
  discoverdevfail: gettext('Discovery failed.'),
  allocmodelsnfail: gettext('Unable to allocate a model serial number.'),
  devsnexist1: gettext('A device with serial number'),
  devsnexist2: gettext('already exists.'),
  mgmtidfail: gettext('Unable to assign a device ID.'),
  devnameused: gettext('The device name is already in use'),
  devaddfail: gettext('Unable to create the device.'),
  devadderror: gettext('Device added with errors.'),
  devaddgrpupdfail: gettext('Unable to add device to group.'),
  updhacluster2: gettext('Updating high availability status'),
  updhaclusterfail: gettext('Unable to set HA cluster information.'),
  converthaclusterfail: gettext('Unable to convert model HA cluster.'),
  clustermasterunreg: gettext(
    'Please promote auto scaling group primary member first: '
  ),
  updintffail: gettext('Failed to update interface information.'),
  modelunsupport: gettext(
    'This FortiManager does not support the discovered device model and firmware version combination.'
  ),
  verunsupport: gettext(
    'This FortiManager does not support the discovered firmware version.'
  ),
  getadom: gettext('Retrieve ADOM information'),
  compadom: gettext('Compare ADOM information'),
  syncadom: gettext('Sync ADOM devices'),
  finishadddev: gettext('Finish adding FortiAnalyzer'),
  validation: gettext('Validation FortiAnalyzer'),
  /***************************** Taken from old resource file : (task/en) ****************************************/
  create_grp_done: gettext('Successfully created device group'),
  err_invalid_tid: gettext('Task id is invalid'),
  err_blank_name: gettext('Name can not be blank'),
  import_dev_objs: gettext('Import Wizard'),
  nocharsinname: gettext('No characters in name'),
  badcharsinname: gettext(
    "Bad characters in name: only a-z, A-Z, 0-9, '-' and '_' allowed"
  ),
  fmlmgmtstatupdfail: gettext(
    "Unable to update FortiMail's management status."
  ),
  initconfdbfail: gettext('Unable to initialize the device database.'),
  adommembupdfail: gettext('Failed to update ADOM membership.'),
  devaddcancel: gettext('Device add canceled.'),
  devdbnotfound: gettext('Could not find the device database.'),
  deldev: gettext('Deleting device.'),
  delhaclustfail: gettext('Failed to delete HA cluster members.'),
  cleandevreffail: gettext('Failed to cleanup device references.'),
  deldevfail: gettext('Failed to delete selected devices.'),
  cleandevdata: gettext('Cleaning up device data.'),
  deldevsuccess: gettext('Selected devices are deleted successfully.'),
  'error.internal': gettext('Internal Error.'),
  updatenoperm: gettext('You do not have permission to update the device.'),
  no_perm_devgrp: gettext(
    'You do not have permission to create the device group.'
  ),
  err_devgrp_lock_adom: gettext(
    'Please lock ADOM (%s) to create the device group.'
  ),
  err_devgrp_prompt: gettext('For creating device group in ADOM'),
  err_devgrp_name: gettext('Please input group name'),
  grp_err_devs: gettext('Group Error Devices'),
  licmodeltoohigh1: gettext(
    't2.small is too small for your FortiGate device. Please choose a larger instance type.'
  ),
  licostypeerr1: gettext('This FortiManager does not support the'),
  licostypeerr2: gettext('platform.'),
  ostypeunsupport: gettext(
    'This FortiManager does not support the discovered device.'
  ),
  genliclimit: gettext('Insufficient number of licenses available.'),
  liclimit1: gettext(
    'Insufficient number of licenses. This platform supports maximum'
  ),
  liclimit2: gettext('Devices/VDOMs, has managed'),
  liclimit3: gettext('Devices/VDOMs, but this operation adds'),
  liclimit4: gettext('Devices/VDOMs.'),
  licgenerr: gettext('License check error.'),
  importparseerr1: gettext('Parse error on line'),
  importparseerr2: gettext('character'),
  importopenfail: gettext('Unable to read import file.'),
  importvermismatch: gettext('Unsupported import file version.'),
  importnogrpname: gettext('Missing group name.'),
  importnogrpopmode: gettext('Missing group operation mode.'),
  importnogrptype: gettext('Missing group type.'),
  importnogrpostype: gettext('Missing group firmware type.'),
  importnogrposver: gettext('Missing group firmware version.'),
  importnogrpbuild: gettext('Missing group build number.'),
  importnoendgroup: gettext("Missing 'endgroup' line."),
  importnoip: gettext('Missing IP address.'),
  importbadip: gettext('Invalid IP address.'),
  importnomode: gettext('Missing device management mode.'),
  importbadmode: gettext('Illegal value for device management mode.'),
  importnoadmin: gettext('Missing admin username.'),
  importnosnmp: gettext('Missing SNMP server setting.'),
  importnofds: gettext('Missing FortiGuard Distribution Server setting.'),
  importnofgd: gettext('Missing FortiGuard server setting.'),
  importnofaz: gettext('Missing FortiAnalyzer server setting.'),
  importnosync: gettext('Missing configuration synchronization setting.'),
  importnodiscover: gettext('Missing device discovery setting.'),
  importnomodel: gettext('Missing device model.'),
  importbadmodel: gettext('Unknown device model.'),
  importpasswdmangled: gettext('Device password has been corrupted.'),
  importbadmr: gettext('Invalid MR string.'),
  importnoopmode: gettext('Missing device operation mode.'),
  importnohdflag: gettext("Missing 'has_hard_disk' flag."),
  importnofwver: gettext('Missing firmware version.'),
  importbadfwver: gettext('Unknown firmware version.'),
  importnobuild: gettext('Missing build number.'),
  importnosn: gettext('Missing serial number.'),
  importneeddetails: gettext(
    'Device details must be specified when discovery is not set.'
  ),
  importbadescape: gettext('Invalid escape sequence.'),
  importtruncescape: gettext('Incomplete escape sequence.'),
  importunexpectedeol: gettext('Unexpected end of line.'),
  importbadkw1: gettext('Unrecognized keyword:'),
  importnametoolong: gettext('Name too long.'),
  importbadflag1: gettext('Invalid boolean value:'),
  importbadmetaname1: gettext('No such meta field:'),
  importneedrootadom: gettext('ADOMs cannot be imported in non-root ADOM.'),
  importdevneedroot: gettext(
    'Must be in root ADOM to import device outside current ADOM.'
  ),
  importvdomneedroot: gettext(
    'Must be in root ADOM to import VDOM outside current ADOM.'
  ),
  importadomdisabled: gettext('ADOMs are not enabled on this FortiManager.'),
  importadom: gettext('Importing ADOM'),
  importadomerr: gettext('ADOM import failed.'),
  importadomerrmode1: 'ADOM',
  importadomerrmode2: gettext('already exists but has incompatible mode.'),
  importadomdonewait: gettext('Waiting for devices to be imported.'),
  importadomsuccess: gettext('ADOMs imported successfully.'),
  importcancelerr: gettext('Import canceled due to earlier errors.'),
  importgrp1: gettext('Importing group'),
  importgrp2: gettext('into ADOM'),
  importgrpmemb: gettext('Updating memberships for group'),
  importgrperr: gettext('Groups import failed.'),
  importgrpupderr: gettext('Error while updating existing group'),
  importgrpadderr: gettext('Could not add group'),
  importgrpnoadom: gettext('No such ADOM'),
  importgrpnooid1: gettext('Failed to add VDOM'),
  importgrpnooid2: gettext('in device'),
  importgrpnooid3: gettext('to group'),
  importgrpnooid4: gettext('in ADOM'),
  importgrpsuccess: gettext('Groups imported successfully.'),
  importsubgrp: gettext('Importing subgroups'),
  importsubgrpnotfound: gettext('No such subgroup'),
  importsubgrperr1: gettext('Failed to import subgroup'),
  importsubgrperr2: gettext('to group'),
  importsubgrperr3: gettext('in ADOM'),
  importsubgrptoodeep1: gettext('The group'),
  importsubgrptoodeep2: gettext('is nested more than 2 levels deep.'),
  importvdom: gettext('Importing device VDOMs'),
  importvdomerr: gettext('Error while importing device VDOMs.'),
  importvdomdone: gettext('Done importing VDOMs'),
  importdevicestart: gettext('Started to import the device.'),
  checkdevdel: gettext('Checking if the device can be deleted.'),
  inuseby: gettext('In use by'),
  inadom: gettext('in ADOM'),
  delinuse: gettext('The device is in use and cannot be deleted.'),
  snmismatch: gettext("The device's serial number does not match database."),
  updchstitle: gettext('Chassis update'),
  updchsslottitle: gettext('Chassis slot update'),
  auto_close: gettext('Automatically close when completed'),
  cancelbyuser: gettext('Canceled by user'),
  retrieve_failed: gettext('Retrieve Device Configuration was Failed'),
  reinstall_failed: gettext('Re-Install Device Failed'),
  install: gettext('Install'),
  done: gettext('Done'),
  hasyncinprogress: gettext('HA Sync in Progress'),
  err_tid: gettext('Task ID is invalid.'),
  err_ack: gettext('Failed to acknowledge task.'),
  err_adddev_set_logarray: gettext('Failed to set log group'),
  err_adddev_new_logarray: gettext('Failed to add new log group.'),
  adomflagfailed: gettext('Failed to get ADOM flags'),
  adominbackup: gettext('ADOM in backup mode'),
  adommembership: gettext('Failed to adjust device ADOM membership'),
  devstatus: gettext('Updating device status'),
  devflagfailed: gettext('Failed to update device flags'),
  devmgmtmodefailed: gettext('Failed to update device management mode'),
  devreset: gettext('Performing factory reset'),
  devresetfail: gettext('Failed to factory reset device'),
  devadminfailed: gettext('Failed to update admin/password'),
  devupd: gettext('Updating device'),
  devupdfailed: gettext('Failed to update device'),
  devmgmtdata: gettext('Updating device management data'),
  devmgmtdatafailed: gettext('Failed to update device management data'),
  devinstall: gettext('Installing to real device'),
  linkcontrollers: gettext('Autolinking controllers'),
  toomanypkg: gettext('More than one package is assigned to the device'),
  devinstfailed: gettext('Failed to install to real device'),
  modelupdok: gettext('Updating model device successful'),
  devreplaceok: gettext('Device Serial Number Updated'),
  swmodeerr: gettext('Internal switch mode conflict'),
  // Source texts
  dvm: gettext('Device Manager'),
  sc: gettext('Install Package'),
  installpreview: gettext('Install Preview'),
  GlobalObject: gettext('Copy Global Object'),
  instconfig: gettext('Install Configuration'),
  execscript: gettext('Script Execution'),
  checkpoint: gettext('System checkpoint'),
  importpolicy: gettext('Import Device Policy'),
  policycheck: gettext('Policy Consistency Check'),
  integritycheck: gettext('ADOM Integrity Check'),
  assignment: gettext('Package Assignment'),
  obj_assignment: gettext('Object Assignment'),
  packageclone: gettext('Package Clone'),
  autocert: gettext('Certificate Enrollment'),
  instobjs: gettext('Install Objects'),
  instdev: gettext('Install Device'),
  fwm: gettext('Firmware Manager'),
  pblockclone: gettext('Policy Block Clone'),
  importconfig: gettext('Import configuration'),
};

export const TaskSrcTextMap: ConstMap = {
  [MACROS.TASK.TASK_SRC_DVM]: TaskTextMap['dvm'],
  [MACROS.TASK.TASK_SRC_SC]: TaskTextMap['sc'],

  [MACROS.TASK.TASK_SRC_GLOBAL_OBJ]: TaskTextMap['GlobalObject'],
  [MACROS.TASK.TASK_SRC_DPM_INSTCONF]: TaskTextMap['instconfig'],
  [MACROS.TASK.TASK_SRC_DPM_INSTSCRIPT]: TaskTextMap['execscript'],
  [MACROS.TASK.TASK_SRC_CHKPOINT]: TaskTextMap['checkpoint'],

  [MACROS.TASK.TASK_SRC_IMPORT_OBJS]: TaskTextMap['import_dev_objs'],
  [MACROS.TASK.TASK_SRC_IMPORT_ZONES]: TaskTextMap['import_dev_objs'],

  [MACROS.TASK.TASK_SRC_IMPORT_POLICY]: TaskTextMap['importpolicy'],
  [MACROS.TASK.TASK_SRC_POLICY_CHECK]: TaskTextMap['policycheck'],

  [MACROS.TASK.TASK_SRC_ASSIGNMENT]: TaskTextMap['assignment'],
  [MACROS.TASK.TASK_SRC_OBJ_ASSIGNMENT]: TaskTextMap['obj_assignment'],
  [MACROS.TASK.TASK_SRC_PKG_CLONE]: TaskTextMap['packageclone'],
  [MACROS.TASK.TASK_SRC_AUTO_CERT]: TaskTextMap['autocert'],
  [MACROS.TASK.TASK_SRC_INSTOBJS]: TaskTextMap['instobjs'],
  [MACROS.TASK.TASK_SRC_DPM_INST_PREVIEW]: TaskTextMap['installpreview'],
  [MACROS.TASK.TASK_SRC_INSTDEV]: TaskTextMap['instdev'],
  [MACROS.TASK.TASK_SRC_FWM]: TaskTextMap['fwm'],

  [MACROS.TASK.TASK_SRC_INTEGRITY_CHECK]: TaskTextMap['integritycheck'],
  [MACROS.TASK.TASK_SRC_PBLOCK_CLONE]: TaskTextMap['pblockclone'],
  [MACROS.TASK.TASK_SRC_IMPORT_CONFIG]: TaskTextMap['importconfig'],
};

export const TaskStateTextMap: ConstMap = {
  [MACROS.TASK.TASK_STATE_PEND]: gettext('Pending'),
  [MACROS.TASK.TASK_STATE_RUN]: gettext('Running'),
  [MACROS.TASK.TASK_STATE_CANCELLING]: gettext('Canceling'),
  [MACROS.TASK.TASK_STATE_CANCELLED]: gettext('Canceled'),
  [MACROS.TASK.TASK_STATE_DONE]: gettext('Done'),
  [MACROS.TASK.TASK_STATE_ERROR]: gettext('Error'),
  [MACROS.TASK.TASK_STATE_ABORTING]: gettext('Aborting'),
  [MACROS.TASK.TASK_STATE_ABORTED]: gettext('Aborted'),
  [MACROS.TASK.TASK_STATE_WARN]: gettext('Warning'),
};

export const TaskStateIconMap: ConstMap = {
  [MACROS.TASK.TASK_STATE_PEND]: 'ffg ffg-schedule color-grey',
  [MACROS.TASK.TASK_STATE_CANCELLING]: 'ffg ffg-no color-grey',
  [MACROS.TASK.TASK_STATE_CANCELLED]: 'ffg ffg-no color-grey',
  [MACROS.TASK.TASK_STATE_DONE]: 'ffg ffg-enable color-green',
  [MACROS.TASK.TASK_STATE_ERROR]: 'ffg ffg-no color-red',
  [MACROS.TASK.TASK_STATE_ABORTED]: 'ffg ffg-warning color-red',
  [MACROS.TASK.TASK_STATE_WARN]: 'ffg ffg-warning color-yellow',
};
