import { useState, useRef } from 'react';
import { useApiRequest } from 'rh_util_hooks';
import { getCountryChoices, getResellerChoices } from './forticare_util';

export const useCountries = ({ isForticareRegistered, useEntitlmentFile }) => {
  const [forticareConnectionFailed, setForticareConnectionFailed] =
    useState(false);
  const { state, reload } = useApiRequest({
    loader: getCountryChoices,
    defaultValue: [],
    parser: (choices) => {
      if (!choices || choices.length === 0) {
        setForticareConnectionFailed(true);
      }
      return choices;
    },
    breakEffect: () => {
      return isForticareRegistered || useEntitlmentFile;
    },
  });

  return {
    countries: state,
    reloadCountries: reload,
    forticareConnectionFailed,
  };
};

export const useReseller = () => {
  const [resellers, setResellers] = useState([]);
  const resellerCache = useRef({});
  const getCache = (country) => resellerCache.current[country];
  const setCache = (country, resp) => (resellerCache.current[country] = resp);

  const getResellers = async (country) => {
    if (getCache(country)) return getCache(country);
    let choices = [];

    try {
      const resellerChoices = await getResellerChoices(country);
      choices = resellerChoices;
    } catch (err) {
      console.error(err);
    } finally {
      setCache(country, choices);
      setResellers(choices);
    }
  };

  return {
    resellers,
    getResellers,
  };
};
