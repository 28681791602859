import React, { useState } from 'react';
import { fiHttpGet, fiHttpPost } from 'fi-http';
import { UrlService } from 'fi-url';
import interop from 'interop';
import {
  NwButton,
  NwInput,
  NwTextarea,
  NwAlert,
  NwIcon,
} from '@fafm/neowise-core';
import { ProForm, ProToolkit } from '@fafm/neowise-pro';

import './GDPR.less';
const { Header, Body, Footer, Section, Row, Column } = ProForm;

const GDPRModal = ({ $opener, maskedTxtFromPicker }) => {
  const [maskedTxt, setMaskedTxt] = useState(maskedTxtFromPicker || '');
  const [maskKey, setMaskKey] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [unmaskedTxt, setUnmaskedTxt] = useState('');

  const inputMaskedTxt = (e) => {
    setMaskedTxt(e.target.value || '');
  };

  const inputMaskKey = (e) => {
    setMaskKey(e.target.value || '');
  };

  const unmask = () => {
    setUnmaskedTxt('');
    fiHttpPost('/p/util/privacy/unmask/', {
      key: maskKey,
      text: maskedTxt,
    }).then(
      (resp) => {
        if (typeof resp?.unmasked === 'string') {
          setUnmaskedTxt(resp.unmasked);
        }
        if (resp?.errmsg) {
          setErrMsg(resp.errmsg);
        }
      },
      (err) => {
        try {
          err.errors.message && setErrMsg(err.errors.message);
        } catch (ex) {
          // eslint-disable-line no-unused-vars
          setErrMsg(gettext('Error'));
        }
      }
    );
  };

  return (
    <>
      <Header>{gettext('Unmask Protected Data')}</Header>
      <Body className='gdpr-body'>
        <Section>
          <Row label={gettext('Masked Text')}>
            <Column>
              <NwInput
                name='masked'
                onInput={inputMaskedTxt}
                value={maskedTxt}
              ></NwInput>
            </Column>
          </Row>
          <Row label={gettext('Data Mask Key')}>
            <Column>
              <NwInput
                name='key'
                type='password'
                onInput={inputMaskKey}
                value={maskKey}
              ></NwInput>
            </Column>
          </Row>
          <Row label={gettext('Unmasked Text')}>
            <Column>
              <NwTextarea
                name='unmasked'
                readOnly
                value={unmaskedTxt}
              ></NwTextarea>
            </Column>
          </Row>
          {errMsg ? (
            <Row>
              <Column>
                <NwAlert
                  type='danger'
                  open
                  icon={<NwIcon name='warning'></NwIcon>}
                >
                  {errMsg}
                </NwAlert>
              </Column>
            </Row>
          ) : null}
        </Section>
      </Body>
      <Footer>
        <NwButton type='primary' onClick={unmask}>
          OK
        </NwButton>
        <NwButton
          onClick={() => {
            $opener.reject();
          }}
        >
          Cancel
        </NwButton>
      </Footer>
    </>
  );
};

const openViewerModal = () => {
  return ProToolkit.openModal(<GDPRModal />).result;
};

const startGDPRPicker = () => {
  // order maters! since these containers could exist at the same time, we only want to select one
  const selectors = [
    '.logview-main-container .BaseTable__body',
    '.log-list-container .BaseTable__body',
    '.fortiview-table-container-dd .BaseTable__body',
    '.fortiview-table-container .BaseTable__body',
  ];
  let tableBody;
  for (let selector of selectors) {
    tableBody = document.querySelectorAll(selector);
    if (tableBody.length) {
      break;
    }
  }

  if (!tableBody.length) {
    return;
  }

  let prevCell;
  let targetCell;
  const getCell = (target) => {
    let cell = null;
    if (target.classList.contains('.BaseTable__row-cell')) {
      cell = target;
    } else {
      cell = target.closest('.BaseTable__row-cell');
    }
    return cell;
  };
  const onMove = (evt) => {
    if (![...tableBody].find((x) => x.contains(evt.target))) {
      return;
    }
    let cell = getCell(evt.target);
    if (!cell || cell === targetCell) {
      return;
    }
    targetCell = cell;
    if (prevCell && prevCell !== cell) {
      prevCell.classList.remove('mask-background');
    }
    prevCell = cell;
    cell.classList.add('mask-background');
  };
  const onMousedown = (evt) => {
    if (![...tableBody].find((x) => x.contains(evt.target)) || !targetCell) {
      return;
    }
    targetCell.classList.remove('mask-background');

    // some text is not masked, need to be excluded
    let cloneCell = targetCell.cloneNode(true);
    cloneCell.querySelectorAll('.gdpr-exclude').forEach((x) => x.remove());

    ProToolkit.openModal(
      <GDPRModal maskedTxtFromPicker={cloneCell.innerText} />
    );
    document.removeEventListener('mousemove', onMove);
    document.removeEventListener('mousedown', onMousedown);
  };
  document.removeEventListener('mousemove', onMove);
  document.removeEventListener('mousedown', onMousedown);
  document.addEventListener('mousemove', onMove);
  document.addEventListener('mousedown', onMousedown);
};

var maskFields = (function () {
  var columnTextLoaded = false;
  function keyToFieldName(macroKey) {
    return macroKey.substr('GDMF_IDX_'.length).toLowerCase();
  }
  function loadColumnText() {
    if (columnTextLoaded) {
      return;
    }
    fiHttpGet(UrlService.UTIL.LOG_COLUMNS_TEXT).then(function (resp) {
      var map = resp.col_text_map;
      maskFieldChoices.forEach(function (choice) {
        var text = map[keyToFieldName(choice.id)];
        if (text) {
          choice.text = gettext(text);
        }
      });
      columnTextLoaded = true;
    });
  }

  var forbiddenMaskKeys = new Set(['GDMF_IDX_MAX', 'GDMF_IDX_UNKNOWN']);
  var allfields = Object.keys(interop.FAZ_DATAMASK).reduce(function (ret, key) {
    if (key.startsWith('GDMF_IDX_') && !forbiddenMaskKeys.has(key)) {
      ret.push(key);
    }
    return ret;
  }, []);
  var maskFieldChoices = allfields.map(function (key) {
    return {
      id: key,
      text: keyToFieldName(key),
    };
  });
  return {
    fields: allfields,
    get choices() {
      loadColumnText();
      return maskFieldChoices;
    },
    toBknd: function (fields) {
      return Object.keys(fields).reduce(function (ret, key) {
        var shifter = interop.FAZ_DATAMASK[key];
        var selected = fields[key];
        if (selected && Number.isFinite(shifter) && shifter >= 0) {
          var fieldValue = 1 << shifter;
          ret += fieldValue;
        }
        return ret;
      }, 0);
    },
    fromBknd: function (num) {
      return allfields.reduce(function (ret, key) {
        var shifter = interop.FAZ_DATAMASK[key];
        if (Number.isFinite(shifter) && shifter >= 0) {
          var fieldValue = 1 << shifter;
          ret[key] = !!(fieldValue & num); // bitwise AND.
        }
        return ret;
      }, {});
    },
  };
})();

export const GDPRService = {
  startPicker: startGDPRPicker,
  openViewerModal: openViewerModal,
  maskFields: maskFields,
};
