const DEFAULT_LAST_N = 1;

/*
    - only custom time period object contains start and end times, which is in format 'YYYY-MM-DD HH:mm:ss'
*/
export const TimePeriods = [
  {
    key: '5min',
    label: gettext('Last 5 Minutes'),
    lastN: 5,
    unit: 'min',
  },
  {
    key: '30min',
    label: gettext('Last 30 Minutes'),
    lastN: 30,
    unit: 'min',
  },
  {
    key: '1hour',
    label: gettext('Last 1 Hour'),
    lastN: 1,
    unit: 'hour',
  },
  {
    key: '4hour',
    label: gettext('Last 4 Hours'),
    lastN: 4,
    unit: 'hour',
  },
  {
    key: '12hour',
    label: gettext('Last 12 Hours'),
    lastN: 12,
    unit: 'hour',
  },
  {
    key: '1day',
    label: gettext('Last 1 Day'),
    lastN: 1,
    unit: 'day',
  },
  {
    key: '1week',
    label: gettext('Last 1 Week'),
    lastN: 1,
    unit: 'week',
  },
  {
    key: 'nhour',
    label: gettext('Last N Hours'),
    lastN: DEFAULT_LAST_N,
    unit: 'hour',
  },
  {
    key: 'nday',
    label: gettext('Last N Days'),
    lastN: DEFAULT_LAST_N,
    unit: 'day',
  },
  {
    key: 'nweek',
    label: gettext('Last N Weeks'),
    lastN: DEFAULT_LAST_N,
    unit: 'week',
  },
  {
    key: 'custom',
    label: gettext('Custom...'),
    start: '',
    end: '',
    unit: 'custom',
  },
];
