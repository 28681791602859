/**
 * @typedef {import('react-router-dom').PathMatch} PathMatch
 */
import { negate, isArray, identity, has } from 'lodash';

import { treeMap, treeFind, treeReduce } from 'fiutil/tree';
import { collapseDuplicates } from 'kit/kit-array/array';

/** Gets the key from the app node */
export const getAppKey = (node) => {
  return isArray(node) ? node[0] : node;
};

/** Gets the children array from the app node */
export const getAppChildren = (node) => {
  return isArray(node) && isArray(node[1]) ? node[1] : null;
};

/** check if the app is currently available to navigate */
export const isAppAvailable = (key, appTreeMap) => has(appTreeMap, key);

export const MENU_DIVIDER = '-';

const trimDivider = (children) => {
  return children.filter((child, index) => {
    if (index === 0 || index === children.length - 1) {
      return getAppKey(child) !== MENU_DIVIDER;
    }
    return true;
  });
};

/** generate an app node.
 */
export const genAppNode = (key, children) => {
  if (isArray(children)) {
    return [
      key,
      trimDivider(
        collapseDuplicates((last, node) => {
          return (
            getAppKey(last) === MENU_DIVIDER && getAppKey(node) === MENU_DIVIDER
          );
        }, children)
      ),
    ];
  }
  return [key, null];
};

/** Generate a app tree structure. */
export const genAppTree =
  (childrenGetter) =>
  ({ items, fn, stopFn = null, postFn = genAppNode }) =>
    // Generate the new app tree node. key is whatever returned from the fn.
    treeMap(childrenGetter)({
      items,
      fn,
      postFn: (key, children) => {
        return key === MENU_DIVIDER ? [key, null] : postFn(key, children);
      },
      stopFn,
    });

/** Search in the app tree, find the first mached node  */
export const appTreeSearch = (nodes, fn) =>
  treeFind(getAppChildren)({ items: nodes, fn });

/** Search in the app tree, find the first leave node */
export const appFirstLeaf = (nodes) =>
  appTreeSearch(nodes, negate(getAppChildren));

export const appTreeReduce = ({
  items,
  init,
  fn,
  stopFn,
  postFn = identity,
}) => {
  return treeReduce(getAppChildren)({
    items,
    init,
    fn: (accu, node, parents, level) => {
      const key = getAppKey(node);
      if (key === MENU_DIVIDER) return accu;
      return fn(accu, node, parents, level);
    },
    stopFn,
    postFn,
  });
};

/**
 * Gets the appUniKey from the router match
 * @param { PathMatch } match - router match object
 * @return { string } The appUniKey
 */
export function getAppUniKey(match) {
  return match?.handle?.appUniKey;
}

export function findInMatches(matches, itemGetter, predicate) {
  for (let i = matches.length - 1; i >= 0; i--) {
    const match = matches[i];
    const key = getAppUniKey(match);
    const item = itemGetter(key);
    if (predicate(item)) {
      return item;
    }
  }
}
