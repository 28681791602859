import { useState } from 'react';
import { useValidEffect } from 'rh_util_hooks';
import { useSelector } from 'react-redux';
import { fiStore } from 'fistore';

import { fiAdom } from 'fi-session';
import { fetchAssetData } from '../common/api';
import { VIEWTYPE_DEVICETYPE, VIEWTYPE_FABRIC } from '../common/constants';
import { fiDeviceRedux } from 'ra_device_util';
import { PsirtDeviceGrouper } from '../common/parsers/parser';

import { getDevicesPsirt } from 'fistore/devices';
import { objectArrayToMap } from 'kit/kit-array';

const ASSET_CATES = {
  FAP: 'wireless-controller/wtp',
  FEXT: 'extension-controller/extender',
  FSW: 'fsp/managed-switch',
  FEXT70: 'extender-controller/extender',
};

export const getPsirtTableData = () => {
  const state = fiStore.getState();
  const psirtData = getDevicesPsirt(state);
  const adomName = fiAdom.current().name;
  const adomOid = fiAdom.current().oid;
  const dvmDevices = fiDeviceRedux.getDevicesAsArray(adomOid);
  const promises = [
    fetchAssetData(ASSET_CATES.FAP, adomOid),
    fetchAssetData(ASSET_CATES.FSW, adomOid),
    fetchAssetData(ASSET_CATES.FEXT, adomOid),
    fetchAssetData(ASSET_CATES.FEXT70, adomName),
  ];

  return Promise.allSettled(promises).then((resps) => {
    let fap = resps[0].value;
    let fsw = resps[1].value;
    let fext = resps[2].value || [];
    if (Array.isArray(resps[3].value)) fext.concat(resps[3].value);
    const grouper = new PsirtDeviceGrouper({
      dvmDevices,
      fap,
      fsw,
      fext,
      allPsirtData: psirtData,
    });
    const tableData = grouper.groupDevices();
    const FOSDevices =
      tableData.find((data) => data.name === 'FortiGate')?.children || [];
    const psert_device_set = FOSDevices.reduce((acc, dev) => {
      acc[dev.oid] = dev.vulns;
      return acc;
    }, {});
    return { psert_device_set, psirtData, devices: FOSDevices };
  });
};

/**
 * Gets table data, asset devices, dvm devices
 * @returns psirt table state
 */
export const usePsirtTableData = () => {
  const [tableData, setTableData] = useState([]);
  const [dvmDevices, setDvmDevices] = useState([]);
  const [assetData, setAssetData] = useState({});
  const [loading, setLoading] = useState(true);
  const [parsedDvmDevices, setParsedDvmDevices] = useState([]);
  const [parsedAssetDevices, setParsedAssetDevices] = useState([]);
  const [hasFmgVuln, setHasFmgVuln] = useState(false);

  const psirtData = useSelector((state) => state.adom.devicesPsirt);
  const { oid: adomOid, name: adomName } = fiAdom.current();

  //fetch dvm devs and asset devs once
  useValidEffect(async (isValid) => {
    if (!isValid() || !psirtData) return;
    const dvmDevices = fiDeviceRedux.getDevicesAsArray(adomOid);
    const promises = [
      fetchAssetData(ASSET_CATES.FAP, adomOid),
      fetchAssetData(ASSET_CATES.FSW, adomOid),
      fetchAssetData(ASSET_CATES.FEXT, adomOid),
      fetchAssetData(ASSET_CATES.FEXT70, adomName),
    ];

    Promise.allSettled(promises).then((resps) => {
      let fap = resps[0].value;
      let fsw = resps[1].value;
      let fext = resps[2].value || [];
      if (Array.isArray(resps[3].value)) fext.concat(resps[3].value);
      const assetData = {
        fap,
        fsw,
        fext,
      };

      setDvmDevices(dvmDevices);
      setAssetData(assetData);

      const grouper = new PsirtDeviceGrouper({
        dvmDevices,
        fap,
        fsw,
        fext,
        allPsirtData: psirtData,
      });
      const { tableData, hasFmg } = grouper.groupDevices();
      setHasFmgVuln(hasFmg);
      setParsedDvmDevices(grouper.parsedDvmDevices);
      setParsedAssetDevices({
        fap: grouper.parsedFap,
        fsw: grouper.parsedFsw,
        fext: grouper.parsedFext,
      });
      setTableData(tableData);
      setLoading(false);
    });
  }, []);

  return {
    tableData,
    loading,
    assetData,
    dvmDevices,
    hasFmgVuln,
    setTableData,
    setLoading,
    parsedDvmDevices,
    parsedAssetDevices,
    psirtData,
  };
};

/**
 * Setup view options, like DVM folder view
 * Can group by device type (FGT, FAP, etc.)
 * Or by fabric view (DVM devices with assets as children)
 * @param {*} param0
 * @returns viewtype state
 */
export const useTableViewOptions = ({
  setTableData,
  dvmDevices,
  assetData,
  parsedDvmDevices,
  parsedAssetDevices,
}) => {
  const [viewType, setViewType] = useState(VIEWTYPE_DEVICETYPE);
  const psirtData = useSelector((state) => state.adom.devicesPsirt);

  useValidEffect(
    (isValid) => {
      if (!isValid() || !psirtData) return;

      if (viewType === VIEWTYPE_FABRIC) {
        let displayDevice = [];
        const parsedMap = objectArrayToMap(parsedDvmDevices, 'name');
        const allDevices = parsedDvmDevices.concat(
          dvmDevices.filter((device) => !parsedMap[device.name])
        );
        for (let dev of allDevices) {
          const dev_data = { ...dev, id: dev.oid, serialnum: dev.sn };

          const allFap = parsedAssetDevices.fap
            .filter((fap) =>
              fap['scope member'].some((member) => member.name === dev.name)
            )
            .filter((fap) => fap.vulns);
          const allFsw = parsedAssetDevices.fsw
            .filter((fsw) =>
              fsw['scope member'].some((member) => member.name === dev.name)
            )
            .filter((fsw) => fsw.vulns);
          const allFext = parsedAssetDevices.fext
            .filter((fext) =>
              fext['scope member'].some((member) => member.name === dev.name)
            )
            .filter((fext) => fext.vulns);
          dev_data.children = [...allFap, ...allFsw, ...allFext];
          dev_data.key = dev.name + dev.frmversion;
          displayDevice.push(dev_data);
        }

        displayDevice = displayDevice.filter(
          (dev) => dev.children.length > 0 || !!dev.vulns
        );

        setTableData([...displayDevice]);
        return;
      } else if (viewType === VIEWTYPE_DEVICETYPE) {
        const grouper = new PsirtDeviceGrouper({
          dvmDevices,
          fap: assetData.fap,
          fsw: assetData.fsw,
          fext: assetData.fext,
          allPsirtData: psirtData,
        });
        const { tableData } = grouper.groupDevices();
        setTableData(tableData);
      }
    },
    [viewType, psirtData]
  );

  return {
    viewType,
    setViewType,
  };
};
