import { useField } from 'formik';
import { getAutoId, callAll } from './util/util';
import { isFunction, isUndefined } from 'fiutil';
import { renderIcon } from 'ra-render-util';

import { NwRadioGroup, NwRadio } from '@fafm/neowise-core';

export const FmkRadioGroup = ({
  name,
  choices,
  'automation-id': propAutoId,
  automationId,
  disabled: propDisabled,
  onChange,
  getChecked,
  className = '',
  optClassName = '',
  ...rest
}) => {
  const [, { value }, { setValue, setTouched }] = useField(name);

  const onCheck = ({ id }) => {
    setTouched(true, false);
    setValue(id);
  };

  const toDisplayVal = (value) => {
    if (isFunction(getChecked)) {
      return choices.find((opt) => getChecked({ opt, val: opt.id }))?.id;
    }
    return value?.val ?? value;
  };

  function isOptionDisabled(opt) {
    const checkDisabled = opt.disabled || propDisabled;
    if (isUndefined(checkDisabled)) return;
    const disabled = isFunction(checkDisabled)
      ? checkDisabled(opt)
      : checkDisabled;
    if (disabled) {
      return true;
    }
  }

  return (
    <div className={className}>
      <NwRadioGroup
        automation-id={propAutoId || automationId}
        name={name}
        value={toDisplayVal(value)}
        onChange={(evt, value) => {
          callAll(onCheck, onChange)(choices.find(({ id }) => id === value));
        }}
      >
        {choices.map((opt) => {
          const { id, text, icon, autoId, componentToShowOnCheck } = opt;

          return (
            <div className={optClassName} key={`${name}-${id}`}>
              <NwRadio
                name={`${name}-${id}`}
                value={id}
                automation-id={getAutoId(
                  propAutoId || automationId,
                  autoId || id
                )}
                title={opt.title ?? text}
                disabled={isOptionDisabled(opt)}
                {...rest}
              >
                {icon ? renderIcon(icon) : null}
                {text}
              </NwRadio>
              {toDisplayVal(value) === id && !!componentToShowOnCheck
                ? componentToShowOnCheck
                : null}
            </div>
          );
        })}
      </NwRadioGroup>
    </div>
  );
};
FmkRadioGroup.displayName = 'FmkRadioGroup';
