import { deviceStatus } from './device_status';

export { get };

const isAutoUpdated = (oRec) => {
  return oRec.autoupd === 1 || oRec.autosync === 1;
};

const filterRules = {
  all: function () {
    return true;
  },
  configStatus: {
    synchronized: function (oRec) {
      if (oRec.sync === 1 && !isAutoUpdated(oRec)) {
        return true;
      }
      return false;
    },
    autoUpdate: function (oRec) {
      if (oRec.sync === 1 && isAutoUpdated(oRec)) {
        return true;
      }
      return false;
    },
    outOfSync: function (oRec) {
      if (oRec.sync === 2) {
        return true;
      }
      return false;
    },
    pending: function (oRec) {
      if (oRec.sync === 3) {
        return true;
      }
      return false;
    },
    pendingAutoUpdate: function (oRec) {
      if (oRec.sync === 3 && isAutoUpdated(oRec)) {
        return true;
      }
      return false;
    },
    warning: function (oRec) {
      if (oRec.sync === 4) {
        return true;
      }
      return false;
    },
    pedningAutoPush: function (oRec) {
      if (oRec.sync === 6) {
        return true;
      }
      return false;
    },
    unknown: function (oRec) {
      if (
        oRec.sync !== 1 &&
        oRec.sync !== 2 &&
        oRec.sync !== 3 &&
        oRec.sync !== 4 &&
        oRec.sync !== 6
      ) {
        return true;
      }
      return false;
    },
  },
  pkgStatus: {
    installed: function (oRec) {
      if (oRec.pp_sync && oRec.pp_sync.status === 0) {
        return true;
      }
      return false;
    },
    modified: function (oRec) {
      if (oRec.pp_sync && oRec.pp_sync.status === 1) {
        return true;
      }
      return false;
    },
    imported: function (oRec) {
      if (oRec.pp_sync && oRec.pp_sync.status === 2) {
        return true;
      }
      return false;
    },
    unknown: function (oRec) {
      if (oRec.pp_sync && oRec.pp_sync.status === 3) {
        return true;
      }
      return false;
    },
    pedningAutoPush: function (oRec) {
      if (oRec.pp_sync && oRec.pp_sync.status === 6) {
        return true;
      }
      return false;
    },
    outOfSync: function (oRec) {
      if (oRec.pp_sync && oRec.pp_sync.status === 9) {
        return true;
      }
      return false;
    },
    warning: function (oRec) {
      if (oRec.pp_sync && oRec.pp_sync.status === 10) {
        return true;
      }
      return false;
    },
    neverInstalled: function (oRec) {
      if (
        oRec.pp_sync &&
        oRec.pp_sync.status !== 0 &&
        oRec.pp_sync.status !== 1 &&
        oRec.pp_sync.status !== 2 &&
        oRec.pp_sync.status !== 3 &&
        oRec.pp_sync.status !== 9 &&
        oRec.pp_sync.status !== 10
      ) {
        return true;
      }
      return false;
    },
  },
  connectivity: {
    up: function (oRec) {
      if (oRec.connection && oRec.connection.conn === 1) {
        return true;
      }
      return false;
    },
    down: function (oRec) {
      if (oRec.connection && oRec.connection.conn === 2) {
        return true;
      }
      return false;
    },
    unknown: function (oRec) {
      if (
        oRec.connection &&
        oRec.connection.conn !== 1 &&
        oRec.connection.conn !== 2
      ) {
        return true;
      }
      return false;
    },
  },
  logStatusDown: function (oRec) {
    // if a device with vdom feature disabled or oRec is a vdom
    if (
      deviceStatus.isDeviceNoVdom(oRec.rtype) ||
      deviceStatus.isVdom(oRec.rtype)
    ) {
      return _checkLogStatusDown(oRec, 'logs');
    }

    return false;
  },
  allLogging: function () {
    return true;
  },
};

/* device count for log status down should be per vdom */
function _checkLogStatusDown(rec, logField) {
  const diff = rec.timestamp - rec[logField];
  if (!rec[logField] || diff < 0 || diff >= MACROS.USER.DVM.LOG_DIFF) {
    return true;
  }
  return false;
}

const get = () => {
  return filterRules;
};
