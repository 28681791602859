import { createSlice } from '@reduxjs/toolkit';

const _slice = createSlice({
  name: 'copilot',
  initialState: {
    isOpen: false,
    sendMessageCallback: null,
    aiUserChanged: 0,
  },
  reducers: {
    setLogin: (state, action) => {
      state.login = action.payload;
    },
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setSendMessage: (state, action) => {
      state.sendMessageCallback = action.payload;
    },
    execSendMessage: (state, action) => {
      state.sendMessageCallback?.(action.payload);
    },
    setAiUserChanged: (state) => {
      state.aiUserChanged += 1;
    },
  },
});

export const copilotActions = _slice.actions;
export const copilotReducer = _slice.reducer;
