import { Suspense, useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ProLayout, ProToolkit } from '@fafm/neowise-pro';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsRestrictedAdmin,
  getHostname,
  getSysConfig,
  hasFazFeature,
  isFmg,
} from 'fistore/session/sysConfig/selectors';
import {
  getIsMaskedAdmin,
  getAbleToShowChangePassword,
  hasRDWRPermitOnSysSettingConfig,
} from 'fistore/session/profile/selectors';
import { toggleSidebar } from 'fistore/main-frame/slice';
import { getIsSidebarOpened } from 'fistore/main-frame/selectors';

import RightActions from './RightActions';
import { GDPRService } from 'ra_sys_util';
import { FirmwareUpgrade } from '../modal/FirmwareUpgrade';
import { ChangePassword } from '../modal/ChangePassword';
import { ChangeUserProfile } from '../modal/ChangeUserProfile';
import { useAuth } from 'react_apps/ra-auth/useAuth';
import { logoutCheckWorkspace } from '../modal/WorkSpaceLogout';
import { promptTerminal, Terminal } from 'rc_terminal';
import { ProcessMonitor } from 'rc_process_monitor';
import { getToolbarComponent } from './navbarGetToolbar';
import { fetchSysConfig } from 'fistore/session/sysConfig/slice';
import { noop } from 'lodash';
import { transformBackendFirmwareText } from 'fi-web/fi-api/system_operation';
const { startPicker: startGDPRPicker, openViewerModal } = GDPRService;

export const NavBar = () => {
  const isRstAdm = useSelector(getIsRestrictedAdmin);
  const hostname = useSelector(getHostname);
  const isSidebarOpened = useSelector(getIsSidebarOpened);
  const sysConfig = useSelector(getSysConfig);
  const isRwUser = useSelector(
    hasRDWRPermitOnSysSettingConfig({ haCheck: false })
  );
  const isAbleToChangePassword = useSelector(getAbleToShowChangePassword);
  const isMaskedAdmin = useSelector(getIsMaskedAdmin);
  const _hasFazFeature = useSelector(hasFazFeature);
  const authCtx = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const _isFmg = useSelector(isFmg);

  const listeners = useRef([]);

  const productLogo = () =>
    _isFmg
      ? {
          logoLibrary: 'fgt-products',
          logo: 'fortimanager',
        }
      : {
          logoLibrary: 'fgt-products',
          logo: 'fortianalyzer',
        };

  const headerTitleRender = useCallback(() => {
    const title = isRstAdm ? gettext('Restricted Admin Mode') : hostname || '';
    return (
      <div className='tw-pl-1 tw-truncate' title={title}>
        {title}
      </div>
    );
  }, [hostname, isRstAdm]);

  const onCollapseClick = useCallback(() => {
    dispatch(toggleSidebar());
  }, []);

  const toolbarRender = useMemo(() => {
    const ToolbarComponent = getToolbarComponent(location);
    return <ToolbarComponent />;
  }, [location]);

  const headerContentRender = useCallback(() => {
    return <Suspense>{toolbarRender}</Suspense>;
  }, [toolbarRender]);

  const rightContentRender = useCallback(() => {
    const externalActions = {
      getProduct: () => {
        return {
          name: MACROS.SYS.CONFIG_PROD_NAME,
          build: transformBackendFirmwareText(sysConfig.fmgversion),
        };
      },

      canUpgradeFirmware: () => {
        return isRwUser && !sysConfig.have_docker;
      },
      upgradeFirmware: () => {
        return ProToolkit.openModal(<FirmwareUpgrade />, {
          width: 'lg',
          closable: false,
        });
      },

      canChangePassword: () => {
        return isAbleToChangePassword;
      },
      openChangePwd: () => {
        const modalOpts = {
          width: 'sm',
          height: '350px',
          closeable: false,
        };
        return ProToolkit.openModal(
          <ChangePassword username={sysConfig.username} opts={{}} />,
          modalOpts
        );
      },

      canChangeProfile: () => {
        return isAbleToChangePassword;
      },
      changeProfile: () => {
        ProToolkit.openModal(<ChangeUserProfile />, {
          width: 'lg',
        }).result.then(() => {
          // refresh the avatar.
          dispatch(fetchSysConfig());
        }, noop);
      },

      logout: () => {
        logoutCheckWorkspace({
          allowCancel: true,
          logout: () => authCtx.logout(),
        });
      },

      toggleCLIConsole: () => {
        const listener = promptTerminal(<Terminal />);
        listeners.current.push(listener);
      },
      openProcessMonitor: () => {
        try {
          ProToolkit.openDrawer(<ProcessMonitor />);
        } catch {
          return;
        }
      },

      getLogdbBuilding: () => {
        return {
          hint: gettext('Rebuilt Log Database'),
          details: () => {},
        };
      },
      isDataMasked: () => {
        return isMaskedAdmin && _hasFazFeature;
      },
      isSso: () => {
        return sysConfig['sso_sp_mode'] || sysConfig['sso_idp_mode'];
      },
    };

    return (
      <RightActions
        externalActions={externalActions}
        openViewerModal={openViewerModal}
        startGDPRPicker={startGDPRPicker}
      />
    );
  }, [
    isRwUser,
    sysConfig,
    isAbleToChangePassword,
    isMaskedAdmin,
    _hasFazFeature,
  ]);

  return (
    <ProLayout.Header
      {...productLogo()}
      collapseIcon='bars'
      collapsed={!isSidebarOpened}
      onCollapseClick={onCollapseClick}
      headerTitleRender={headerTitleRender}
      headerContentRender={headerContentRender}
      rightContentRender={rightContentRender}
      showIconWhenCollapsed
    />
  );
};
