import React from 'react';
import { getExistingFirmwareTemplates } from '../util';
import {
  NwProBody,
  NwProFooter,
  NwProInputRow,
  NwProSection,
  NwProLine,
  OkBtn,
  CancelBtn,
} from 'rc_layout';
import { ObjSSelect2 } from 'rc_select';
import { NwRadio } from '@fafm/neowise/react';
import { useWizard } from 'rc_wizard';
import { WizardButtonRenderer } from '../WizardButtonRenderer';

export const ChooseMethod = ({
  setSelectedProf,
  setIsNewProf,
  isNewProf,
  selectedProf,
  $opener,
}) => {
  const wizardCtx = useWizard();

  const selectedTypeValue = isNewProf ? 'new' : 'existing';

  const getRadioChoices = () => [
    {
      key: 'new',
      text: gettext('Create New Firmware Template'),
      value: 'new',
      onClick: () => {
        setIsNewProf(true);
        setSelectedProf(null);
      },
    },
    {
      key: 'existing',
      text: gettext('Use Existing Firmware Template'),
      value: 'existing',
      onClick: () => setIsNewProf(false),
      siblings: () => {
        return (
          <div className='tw-w-48'>
            <ObjSSelect2
              source={getExistingFirmwareTemplates}
              idAttr={'name'}
              textAttr={'name'}
              value={selectedProf?.name}
              onChange={(_, item) => setSelectedProf(item)}
            />
          </div>
        );
      },
    },
  ];

  const buttons = [
    {
      id: 'next',
      text: gettext('Next >'),
      Component: OkBtn,
      props: {
        onClick: () => wizardCtx.goToNext(),
        disabled: !isNewProf && !selectedProf,
      },
    },
    {
      id: 'cancel',
      text: gettext('Cancel'),
      Component: CancelBtn,
      props: {
        onClick: () => $opener.reject(),
      },
    },
  ];

  return (
    <>
      <NwProBody className='tw-w-full tw-h-full'>
        <NwProSection>
          <NwProInputRow
            rowProps={{ labelSize: 'md' }}
            label={gettext('Upgrade Device Firmware By')}
          >
            {getRadioChoices().map((item) => {
              const isChecked = selectedTypeValue === item.value;
              return (
                <React.Fragment key={item.key}>
                  <NwProLine>
                    <NwRadio
                      value={item.value}
                      checked={isChecked}
                      onClick={item.onClick}
                    >
                      {item.text}
                    </NwRadio>
                    {!!item.siblings && isChecked && item.siblings()}
                  </NwProLine>
                </React.Fragment>
              );
            })}
          </NwProInputRow>
        </NwProSection>
      </NwProBody>
      <NwProFooter>
        <WizardButtonRenderer btns={buttons} />
      </NwProFooter>
    </>
  );
};
