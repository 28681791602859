import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { fiHttpGet } from 'fi-http';
import { PageLoading } from '../components';
import { getEuAvatarUrl } from './helpers';
import './UserDisplayPopover.less';
import fiUebaService from './fiUebaService';
import { useFiUserDisplayOptionService } from './fiUserDisplayOptionService';
export const UserDisplayPopover = ({
  name,
  euid,
  epid,
  avatarUrl,
  callBack,
  proxiedServer,
  proxiedAdom,
  isSupervisor,
}) => {
  const [identityDetailPath, setIdentityDetailPath] = useState('');
  const [curUser, setCurUser] = useState({
    name: name,
    picture: avatarUrl,
    social: {},
  });
  const [listData, setListData] = useState(null);
  const [newSocialData, setNewSocialData] = useState(null);
  const [displayStatus, setDisplayStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const fiUserDisplayOptionService = useFiUserDisplayOptionService();

  useEffect(() => {
    setLoading(true);
    fiUserDisplayOptionService
      .getData()
      .then((preference) => {
        let params = {
          euids: [euid],
        };

        proxiedServer && (params.proxiedServer = proxiedServer);
        proxiedAdom && (params.proxiedAdom = proxiedAdom);
        const urlParams = new URLSearchParams(params).toString();
        return fiHttpGet('/p/util/endusers/?' + urlParams).then(({ data }) => {
          setLoading(false);
          if (!avatarUrl && euid) {
            setCurUser({
              ...curUser,
              picture: getEuAvatarUrl(
                euid,
                epid,
                undefined,
                undefined,
                proxiedServer,
                proxiedAdom
              ),
            });
          }
          if (euid) {
            setIdentityDetailPath(
              fiUebaService.getIdentityPath(euid, proxiedServer, proxiedAdom)
            );
          }
          let curU = { ...curUser };
          if (data && data[0]) {
            const dataToUse = data[0];
            if (dataToUse.euname) {
              curU.name = dataToUse.euname;
            }
          }
          let oDisplayStatus = {
            picture: {
              status: true,
            },
            name: {
              status: true,
            },
          };
          if (data && data.length) {
            const socialJson = data[0].socialid;
            if (socialJson.data && socialJson.data.length) {
              curU.name = data[0].euname;
              socialJson.data.forEach((s) => {
                Object.keys(s).forEach((cat) => {
                  curU.social[cat] = s[cat];
                });
              });
              const { dispStatus, liData, newScData } =
                fiUserDisplayOptionService.getFormattedData(preference, curU);
              Object.assign(oDisplayStatus, dispStatus);
              setListData(liData);
              setNewSocialData(newScData);
            }
          }
          setCurUser(curU);
          setDisplayStatus(oDisplayStatus);
        });
      })
      .catch((err) => {
        console.error('fetch user info error', err);
      })
      .finally(() => {
        setLoading(false);
        if (callBack) {
          const promise = callBack();
          if (promise && promise.resolve) {
            promise.resolve(curUser);
          }
        }
      });
  }, [euid]);

  return (
    <div className='fortiview-user-display-popover card profile-header'>
      <div className='body tw-h-full'>
        <div className='row tw-h-full'>
          {loading ? <PageLoading /> : null}
          {displayStatus?.picture?.status ? (
            <div className='col-md-4 col-12 picture tw-h-full'>
              {/* <span className="app-icon-container">
                  <span ng-if="!$ctrl" className="fafm-app-icon {{listData.picture[0].icon}}"></span>
                </span> */}
              <div className='profile-image float-md-right tw-h-full'>
                <img
                  src={
                    curUser.picture ||
                    '/static/images/noc/avatar_placeholder.png'
                  }
                  alt=''
                />
              </div>
            </div>
          ) : null}
          <div
            className={cn('col-12', 'details', {
              'tw-hidden': loading,
              'col-md-8': displayStatus?.picture?.status,
              'col-md-12': !displayStatus?.picture?.status,
            })}
          >
            <div>
              {displayStatus?.name?.status ? (
                <strong>
                  {!isSupervisor ? (
                    <Link
                      to={identityDetailPath}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: curUser.name }}
                      ></span>
                    </Link>
                  ) : null}
                  {isSupervisor ? (
                    <span>
                      <span
                        dangerouslySetInnerHTML={{ __html: curUser.name }}
                      ></span>
                    </span>
                  ) : null}
                  {listData ? (
                    <span>
                      {gettext('From:')} {listData?.name?.[0]?.label}
                    </span>
                  ) : null}
                </strong>
              ) : null}
              {displayStatus?.email?.status && curUser.email ? (
                <p className='email preview-highlighted'>
                  <span className='ffg ffg-email with-color'></span>
                  {curUser.email}
                  <span className='app-icon-container'>
                    <span
                      className={cn('fafm-app-icon', listData?.email[0]?.icon)}
                    ></span>
                  </span>
                </p>
              ) : null}
              {displayStatus?.phoneno?.status && curUser.phoneno ? (
                <p className='phone preview-highlighted'>
                  <span className='ffg ffg-iphone with-color'></span>
                  {curUser.phoneno}
                </p>
              ) : null}
              {displayStatus?.social?.status ? (
                <div className='preview-highlighted'>
                  {(listData?.social || [])
                    .concat(newSocialData || [])
                    .map((social) => {
                      if (curUser?.social?.[social?.guiKey]?.email) {
                        return (
                          <div className='social' key={social?.guiKey}>
                            <span className='app-icon-container'>
                              <span
                                className={cn('fafm-app-icon', social?.icon)}
                              ></span>
                            </span>
                            <span className='social-contact'>
                              {curUser?.social?.[social?.guiKey]?.email}
                            </span>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
