import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserDisplayPopover } from './UserDisplayPopover';
import { PageLoading } from '../components';
import { getEuAvatarUrl } from './helpers';
import { getServerTimeByTimePeriod } from 'fi-datetime';
import { fiHttpGet } from 'fi-http';
import './MultiUebaEnduser.less';
import fiUebaService from './fiUebaService';
const MultiUebaEnduserInner = ({
  epid,
  isValidUebaUser,
  proxiedServer,
  proxiedAdom,
  isSupervisor,
}) => {
  const [allEndusers, setAllEndusers] = useState([]);
  const [endusers, setEndusers] = useState([]);
  const [moreEndusers, setMoreEndusers] = useState([]);
  const [endusersUrl, setEndusersUrl] = useState('');
  const [moreDetailUrl, setMoreDetailUrl] = useState('');
  const [loading, setLoading] = useState(false);

  function convertEnduser(row) {
    let res = row?.user?.map((user) => {
      return {
        ...row,
        endusername: user.euname,
        euid: user.euid,
        avatarSrc:
          row.epid && user.euid
            ? getEuAvatarUrl(
                user.euid,
                row.epid,
                null,
                null,
                proxiedServer,
                proxiedAdom
              )
            : null,
      };
    });

    return res || [];
  }

  useEffect(() => {
    if (!isValidUebaUser) {
      const MAXIMUM_DISPLAY_USER = 5;
      const userCfg = {
        count: 5000,
        search: 'epid=' + epid,
        timePeriod: { unit: 'day', num: 60 },
        ...(proxiedServer ? { proxiedServer: proxiedServer } : {}),
        ...(proxiedAdom ? { proxiedAdom: proxiedAdom } : {}),
      };
      setLoading(true);
      getServerTimeByTimePeriod(userCfg.timePeriod)
        .then((res) => {
          let serverTime = {
            startTime: res.start_time.slice(0, 6).join(','),
            endTime: res.end_time.slice(0, 6).join(','),
          };
          return fiHttpGet('/p/util/endpoints/', {
            params: { ...userCfg, serverTime },
          });
        })
        .then((res) => {
          setLoading(false);
          const allEu = convertEnduser(res.data[0]);
          setAllEndusers(allEu);
          // Only show up to 5 endusers
          let eu = allEu.slice(0, MAXIMUM_DISPLAY_USER);
          setEndusers(eu);
          let euUrl = {};
          eu.map((enduser) => {
            euUrl[enduser.euid] = fiUebaService.getIdentityPath(
              enduser.euid,
              proxiedServer,
              proxiedAdom
            );
          });
          setEndusersUrl(euUrl);
          let moreEu = allEu.slice(MAXIMUM_DISPLAY_USER);
          setMoreEndusers(moreEu);
          const euids = moreEu.map((user) => user.euid).join('_');
          setMoreDetailUrl(
            fiUebaService.getIdentityPath(euids, proxiedServer, proxiedAdom)
          );
        })
        .catch((err) => {
          setLoading(false);
          console.error('fetch enduser error:', err);
        });
    }
  }, [epid, proxiedServer, proxiedAdom]);

  return (
    <div className='fortiview-multi-ueba-container'>
      <div className='eu-container'>
        {loading ? <PageLoading /> : null}
        {endusers.length === 1 ? (
          <UserDisplayPopover
            name={endusers[0].name}
            euid={endusers[0].euid}
            avatarUrl={endusers[0].avatarSrc}
            isSupervisor={isSupervisor}
            proxiedServer={proxiedServer}
            proxiedAdom={proxiedAdom}
          ></UserDisplayPopover>
        ) : null}
        {endusers.length > 1 ? (
          <div className='header'>{gettext('Related User:')}</div>
        ) : null}
        {isSupervisor && allEndusers.length > 1 ? (
          <div className='enduser-list'>
            {allEndusers.map((enduser) => (
              <div className='enduser-link' key={enduser.endusername}>
                {enduser.endusername}
              </div>
            ))}
          </div>
        ) : null}
        {!isSupervisor && endusers.length > 1 ? (
          <div className='enduser-list'>
            {endusers.map((enduser) => (
              <div className='enduser-link' key={enduser.endusername}>
                <img className='avatar' src={enduser.avatarSrc} />
                <Link
                  to={endusersUrl[enduser.euid]}
                  target='_blank'
                  rel='noreferrer'
                >
                  {enduser.endusername}
                </Link>
              </div>
            ))}
            {moreEndusers.length ? (
              <div className='enduser-link oversize'>
                <Link
                  to={moreDetailUrl}
                  target='_blank'
                  className='dot'
                  title={gettext(moreEndusers.length + ' More User')}
                  rel='noreferrer'
                >
                  &hellip;
                </Link>
              </div>
            ) : null}
          </div>
        ) : null}
        {endusers.length === 0 && !loading ? (
          <div className='error'>{gettext('No related user available.')}</div>
        ) : null}
      </div>
    </div>
  );
};

export const MultiUebaEnduser = (props) => {
  if (!props.epid) {
    return (
      <div className='error tw-text-center'>
        {gettext('No related user available.')}
      </div>
    );
  }
  return <MultiUebaEnduserInner {...props} />;
};
