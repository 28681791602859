import { useEffect, useRef, useState } from 'react';
import { isFunction, isUndefined } from 'lodash';

import { NwTabGroup, NwTab } from '@fafm/neowise/react';
import { NwSpinner } from '@fafm/neowise-core';
import { ConditionalComponent } from 'rc_layout';

import { useTabs } from 'rh_util_hooks';

// To use nw-tab-group without nw-tab-panel
export const useTabGroup = ({
  currentItem,
  setCurrentItem,
  initTabs = [],
  idAttr = 'name',
  textAttr = 'text',
  canRemoveTab = () => true,
  getTabDisabled = (item) =>
    !isUndefined(item.disabled) ? item.disabled : false,
  getTabLoading = (item) =>
    (!isUndefined(item.loading) ? item.loading : false) ||
    (!isUndefined(item.loaded) ? !item.loaded : false),
}) => {
  const tabStateManager = useTabs({
    initTabs,
    idAttr,
  });

  const getKey = (item) => item[idAttr];

  const getText = (item) => item[textAttr];

  const renderTabGroup = () => {
    const tabGroupProps = {
      tabStateManager,
      currentItem,
      setCurrentItem,
      getKey,
      getText,
      getTabDisabled,
      getTabLoading,
      canRemoveTab,
    };
    return <TabGroup class='tw-w-full' {...tabGroupProps} />;
  };

  return {
    tabStateManager,
    currentItem,
    setCurrentItem,
    renderTabGroup,
  };
};

const TabGroup = ({
  tabStateManager,
  currentItem,
  setCurrentItem,
  getKey,
  getText,
  getTabDisabled,
  getTabLoading,
  canRemoveTab,
}) => {
  const tabs = tabStateManager.getAll();

  const tabGroupRef = useRef();

  const setActiveTab = (activeTabPanel) => {
    tabGroupRef.current?.show(activeTabPanel || getKey(currentItem));
  };

  const commonTabProps = {
    tabStateManager,
    currentItem,
    setCurrentItem,
    getKey,
    getText,
    getTabDisabled,
    getTabLoading,
  };

  return (
    <NwTabGroup ref={tabGroupRef}>
      {tabs.map((tab, index) => (
        <SingleTab
          key={`tab-${getKey(tab)}`}
          tab={tab}
          setActiveTab={setActiveTab}
          closable={canRemoveTab({ tabs, tab, index })}
          {...commonTabProps}
        />
      ))}
    </NwTabGroup>
  );
};

const SingleTab = ({
  currentItem,
  setCurrentItem,
  tab,
  tabStateManager,
  setActiveTab,
  getKey,
  getText,
  getTabDisabled,
  getTabLoading,
  closable,
}) => {
  /** ----------------------------------------------------------------------------
   * Constants
   * -------------------------------------------------------------------------- */
  const key = getKey(tab);

  const isActive = key === getKey(currentItem);

  const isLoading = isFunction(getTabLoading) && getTabLoading(tab);

  const canClose = !isLoading && closable;

  const isDisabled = isFunction(getTabDisabled) && getTabDisabled(tab);

  /** ----------------------------------------------------------------------------
   * Hooks
   * -------------------------------------------------------------------------- */
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, [isActive]);

  // set the active tab when it is mounted to the dom
  useEffect(() => {
    if (isActive && isMounted) {
      setActiveTab(key);
    }
  }, [isMounted, isActive, currentItem]);

  /** ----------------------------------------------------------------------------
   * Event handlers
   * -------------------------------------------------------------------------- */
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // since onNwClose will invoke onClick,
    // this code is to prevent from calling setCurrentScope again if the tab is removed
    if (tabStateManager.getCurrent()?.find((tab) => getKey(tab) === key)) {
      setCurrentItem(tab);
      setActiveTab(key);
    }
  };

  const onNwClose = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isActive) {
      tabStateManager.remove(key);
      return;
    }

    // #1052088: close tab behavior.
    const currentTabs = tabStateManager.getCurrent();
    const currentIndex = currentTabs.findIndex((tab) => getKey(tab) === key);
    const nextIndex =
      currentIndex === currentTabs.length - 1 ? 0 : currentIndex;

    const newTabs = tabStateManager.remove(key);
    if (newTabs && newTabs.length) {
      const nexTab = newTabs[nextIndex];
      if (!nexTab) return;
      setCurrentItem(nexTab);
      setActiveTab(getKey(nexTab));
    } else {
      setCurrentItem(null);
    }
  };

  /** ----------------------------------------------------------------------------
   * Rendering
   * -------------------------------------------------------------------------- */
  const renderTabContent = () => {
    return (
      <div className='tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full'>
        <ConditionalComponent condition={isLoading}>
          <NwSpinner className='tw-absolute tw-z-10' />
        </ConditionalComponent>
        <span
          style={{
            color: isLoading
              ? 'rgba(var(--nw-color-gray-800) / 60%)'
              : undefined,
          }}
        >
          {getText(tab)}
        </span>
      </div>
    );
  };

  return (
    <NwTab
      slot='nav'
      closable={canClose}
      panel={key} // there is no nw-tab-panel, but use this to set active tab
      active={isActive}
      disabled={isDisabled}
      onClick={onClick}
      onNwClose={onNwClose}
    >
      {renderTabContent()}
    </NwTab>
  );
};
