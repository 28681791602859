import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { isFunction, uniq } from 'lodash';

import {
  getSessionAdomName,
  getSessionAdomOid,
} from 'fistore/session/adom/selectors';
import { appEnabled } from 'fistore/routing/selectors';
import { listenerMiddleware } from 'fistore/middlewares';
import { refreshAppTree } from 'fistore/routing/slice';
import { fetchSessionAdom } from 'fistore/session/adom/slice';
import { NOTIFY_BATCH_CHANGED_ACTION } from 'fi-web/fi-websocket/socket/action_const';
import { fiFmgHttp, getResponseData } from 'fi-web/fi-http';

import { ptDisplayOptsApi } from '../dvm_customize/api';
import { getPtDisplayOptionsByAdomOid } from './selectors';
import type { PTState } from './types';

export interface SetPtDisplayOptionsPayload {
  adomOid: string;
  displayOptions: string[];
}

export interface SetTemplateGroupStatusPayload {
  adomOid: string;
  data: any;
}

export interface FetchPtDisplayOptionsPayload {
  adomOid?: string;
  init?: () => Promise<string[]>;
}

export interface UpdatePtDisplayOptionsPayload {
  displayOpts: string[];
  adomOid?: string;
}

export interface FetchTemplateGroupStatusPayload {
  adomName?: string;
}

// Initial state
const initialState: PTState = {
  displayOptions: {},
  templateGroupStatus: {},
};

const defaultPTs: string[] = [
  'dvm_pt_tmplgrp',
  'dvm_pt_ipsectmpl',
  'dvm_pt_sdwantmpl',
  'dvm_pt_systmpl',
  'dvm_pt_routetmpl',
  'dvm_pt_clitmpl',
];

// Create slice
const _slice = createSlice({
  name: 'dvm/pt',
  initialState,
  reducers: {
    setPtDisplayOptions: (
      state,
      action: PayloadAction<SetPtDisplayOptionsPayload>
    ) => {
      const { adomOid, displayOptions } = action.payload;
      state.displayOptions[adomOid] = displayOptions || [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchTemplateGroupStatus.fulfilled,
      (state, action: PayloadAction<SetTemplateGroupStatusPayload>) => {
        const { adomOid, data } = action.payload;
        state.templateGroupStatus[adomOid] = data || {};
      }
    );
  },
});

export const { setPtDisplayOptions } = _slice.actions;
export default _slice.reducer;

// Thunk for fetching PT display options
export const fetchPtDisplayOptions = createAsyncThunk(
  'dvm/pt/fetchDisplayOptions',
  async (
    payload: FetchPtDisplayOptionsPayload,
    { getState, fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    if (!appEnabled('dvm_pt')(getState())) {
      return rejectWithValue('No permission to provisioning templates.');
    }

    const { adomOid: _adomOid, init } = payload || {};
    const adomOid = _adomOid || getSessionAdomOid(getState());
    const existingDisplayOpts = getPtDisplayOptionsByAdomOid(adomOid);

    if (existingDisplayOpts && existingDisplayOpts.length) {
      return fulfillWithValue(existingDisplayOpts);
    }

    let displayOpts = await ptDisplayOptsApi(adomOid);
    const allDefaultPts = displayOpts
      ? displayOpts.every((pt: string) => defaultPTs.includes(pt))
      : false;

    if (!displayOpts || !displayOpts.length || allDefaultPts) {
      let initPTs = defaultPTs;

      if (isFunction(init)) {
        const nonEmptyPTs = await init();
        initPTs = uniq([...defaultPTs, ...nonEmptyPTs]);
      }

      await ptDisplayOptsApi(adomOid, initPTs);
      displayOpts = initPTs || [];
    }

    dispatch(setPtDisplayOptions({ adomOid, displayOptions: displayOpts }));

    return fulfillWithValue(displayOpts);
  }
);

// Thunk for updating PT display options
export const updatePtDisplayOptions = createAsyncThunk(
  'dvm/pt/updatePtDisplayOptions',
  async (payload: UpdatePtDisplayOptionsPayload, { dispatch, getState }) => {
    const { displayOpts, adomOid: _adomOid } = payload || {};
    if (!displayOpts || !displayOpts.length) return;

    const adomOid = _adomOid || getSessionAdomOid(getState());

    // Save to database
    await ptDisplayOptsApi(adomOid, displayOpts);

    // Save to store
    dispatch(setPtDisplayOptions({ adomOid, displayOptions: displayOpts }));
  }
);

// Thunk for fetching template group status
export const fetchTemplateGroupStatus = createAsyncThunk(
  'dvm/pt/fetchTemplateGroupStatus',
  async (_, { getState, fulfillWithValue }) => {
    const adomName = getSessionAdomName(getState());
    const adomOid = getSessionAdomOid(getState());

    const resp = await fiFmgHttp.forward({
      method: 'get',
      params: [
        {
          url: `pm/config/adom/${adomName}/_package/dirty_info`,
        },
      ],
    });
    const data = getResponseData(resp);
    return fulfillWithValue({ adomOid, data });
  }
);

// Listener middleware for side effects
listenerMiddleware.startListening({
  actionCreator: setPtDisplayOptions,
  effect: async (action, { dispatch, cancelActiveListeners }) => {
    cancelActiveListeners();
    dispatch(refreshAppTree());
  },
});

listenerMiddleware.startListening({
  predicate: (action) => {
    if (fetchSessionAdom.fulfilled.match(action)) {
      return true;
    }

    return (
      action.type === NOTIFY_BATCH_CHANGED_ACTION &&
      action.payload?.collection === 'pkg-status'
    );
  },
  effect: async (action, { dispatch, cancelActiveListeners }) => {
    cancelActiveListeners();

    dispatch(fetchTemplateGroupStatus());
  },
});
