import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { fiStore, observeStore, fiSession } from 'fistore';

import { isFaz, isFmg } from './selectors';
import * as selectors from './selectors';
import * as adminProfile from './admin_profile';

export { isFaz, isFmg };

export function current() {
  return fiSession.getSysConfig(fiStore.getState()) ?? {};
}

export function attr(prop) {
  return current()?.[prop];
}

export function attrs(arr = []) {
  const cfg = current();
  return arr.reduce((acc, cur) => {
    acc[cur] = cfg[cur];
    return acc;
  }, {});
}

export const hasFazFeature = () => selectors.hasFazFeature(current());

export const hasFmgFeature = () => selectors.hasFmgFeature(current());

export const LOCAL_OS_TYPE = isFaz()
  ? MACROS.DVM.DVM_OS_TYPE_FAZ
  : MACROS.DVM.DVM_OS_TYPE_FMG;

export const getLocalDevice = () => {
  const cfg = current();
  return {
    name: cfg.hostname,
    sn: cfg.sn,
    value: cfg.hostname,
    _ostype: LOCAL_OS_TYPE,
    ostype: LOCAL_OS_TYPE,
    icon: 'ffg ffg-device',
  };
};
export const getLocalDvmDevice = () => {
  let ret = getLocalDevice();
  ret.label = `${ret.name} (${gettext('Local')})`;
  return ret;
};

export const isHaSlave = () => selectors.isHaSlave(current());
export const isFazOnly = () => selectors.isFazOnly(current());
export const isFmgOnly = () => selectors.isFmgOnly(current());

export const isAdomEnabled = () => selectors.isAdomEnabled(current());
export const isAdomAdvancedMode = () => selectors.isAdomAdvancedMode(current());
export const isAdomNormalMode = () => selectors.isAdomNormalMode(current());
export const isFazCollectorMode = () => selectors.isFazCollectorMode(current());

export const isFazEnabled = () => selectors.isFazEnabled(current());
export const hasAttachedFaz = () => selectors.hasAttachedFaz(current());
export const socFabricEnabled = () => selectors.socFabricEnabled(current());
export const isFtntCloud = () => selectors.isFtntCloud(current());

export const isVMSLicense = () => selectors.isVMSLicense(current());

export const getAccessibleAdomCount = () =>
  selectors.getAccessibleAdomCount(current());

export const isFazSupervisor = () => selectors.isFazSupervisor(current());
export const isFazMember = () => selectors.isFazMember(current());

export const isFmgSupervisor = () => selectors.isFmgSupervisor(current());
export const isFmgMember = () => selectors.isFmgMember(current());

export const fazSqlDbEnabled = () => selectors.fazSqlDbEnabled(current());

export const hasFazAttached = () => selectors.hasFazAttached(current());
export const isWorkspaceEnabled = () => selectors.isWorkspaceEnabled(current());

export const isRemoteGUIAccessEnabled = () =>
  selectors.isRemoteGUIAccessEnabled(current());

export const isOfflineModeEnabled = () => {
  return hasFmgFeature() && attr('offline_mode') ? true : false;
};

export const getThisFMGVersion = () => {
  // cfg.fmgversion "v6.2.0-build0957 181115 (Interim)"
  let vers = current().fmgversion.split(' ');
  let ver = vers[0].substr(1, vers[0].indexOf('-') - 1);
  let build = vers[0].substr(vers[0].indexOf('build') + 5);
  let bdate = vers[1];
  let type = vers[2];
  return { version: ver, build: build, bdate: bdate, type: type };
};

export const earliest_supported_adom_version = () => {
  return current().supported_adom_vers[0];
};
export const latest_supported_adom_version = () =>
  selectors.latest_supported_adom_version(current());
export const latest_supported_adom_version_by_os_type = (osType) =>
  selectors.latest_supported_adom_version_by_os_type(current())(osType);
export const is_adom_version_supported = (ver, mr) =>
  selectors.is_adom_version_supported(current())(ver, mr);

export const isScriptVisible = () =>
  attr('show_script') &&
  !adminProfile.noPermitOn(MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS);

export const isLogviewSearchAutocmpl = () =>
  current().logview_gui_search_autocmpl;

export const hasValidLicense = (licenseType) => {
  const licenses = current().contracts || [];
  const now = new Date();
  const thisYear = now.getFullYear();
  const thisMonth = now.getMonth() + 1;
  const thisDay = now.getDate();
  return licenses.some((lic) => {
    if (lic.type === licenseType) {
      const expYear = Number(lic.time.substring(0, 4));
      const expMonth = Number(lic.time.substring(4, 6));
      const expDay = Number(lic.time.substring(6, 8));
      if (expYear < thisYear) return false;
      else if (expYear === thisYear) {
        if (expMonth < thisMonth) return false;
        else if (expMonth === thisMonth) {
          if (expDay < thisDay) return false;
        }
      }
      return true; //not expired
    }
  });
};

export const allowChangeAdom = () => {
  return isAdomEnabled() && getAccessibleAdomCount() > 1;
};
export const notAdomUser = () => selectors.notAdomUser(current());
export const isAdomUser = () => selectors.isAdomUser(current());

export const isLoaded = (sysCfg = current()) => {
  return sysCfg && !isEmpty(sysCfg);
};

export const canChangePassword = (sysCfg = current()) => {
  function showChangePassword() {
    return (
      adminProfile.isSuperAdmin() ||
      sysCfg.change_passwd ||
      adminProfile.current().change_passwd
    );
  }
  if (sysCfg.user_type === MACROS.SYS.ADMIN_USER_TYPE_LOCAL) {
    // is local
    // Super_User can change anyone's password,
    // so just let them change their own with top-right shortcut.
    // Other local users need 'change_password' enabled
    // to see the top-right change password shortcut.
    return showChangePassword();
  } else if (sysCfg.user_type === MACROS.SYS.ADMIN_USER_TYPE_PKI_AUTH) {
    // client side certificate
    return sysCfg.two_factor_auth && showChangePassword();
  } else if (!sysCfg.wildcard) {
    // not local, not pki, it must be remote
    return showChangePassword();
  } else {
    // It must be remote and wildcar
    return false;
  }
};

export const getCurAdminCombo = (sysCfg = current()) => {
  // This is to match proj/util/common.py get_cur_admin_combo,
  // which either returns the admin name if it is the same
  // as the user name (login name). Or, if they are different,
  // returns a slash separated value like
  // "wildcard_server_conf_admin_name (actual_login_user_name)"
  const adminname = sysCfg.admin_user_name;
  const username = sysCfg.username;
  if (adminname === username) {
    return adminname;
  } else {
    return '%s (%s)'.printf([adminname, username]);
  }
};

export const isMyself = (adminOrComboName, sysCfg = current()) => {
  // match is_myself in common.py
  return (
    adminOrComboName === sysCfg.admin_user_name ||
    adminOrComboName === getCurAdminCombo()
  );
};

// ============= Init loaded promise ==================
export const isInitLoaded = () => {
  const promise = new Promise((resolve) => {
    let unobserve = null;
    const callback = (sysCfg) => {
      if (isLoaded(sysCfg)) {
        resolve(sysCfg);
        unobserve && unobserve();
      }
    };
    unobserve = observeStore(fiSession.getSysConfig, callback);
  });
  return promise;
};

// ============= React Hooks ==========================
export function useCurrent() {
  const [cfg, setCfg] = useState(current);

  useEffect(() => {
    return observeStore(fiSession.getSysConfig, (nprof) => setCfg(nprof ?? {}));
  }, []);

  return cfg;
}
