import React, { useEffect } from 'react';
import { FixedSizeGrid } from 'react-window';
import { ProContextMenu } from '@fafm/neowise-pro';
import { isFunction } from 'lodash';
const { ContextMenuTrigger } = ProContextMenu;

export const AdomMatrix = (props) => {
  const {
    adoms,
    gridRef,
    selected,
    windowWidth: w,
    windowHeight: h,
    cellWidth: fixedCellWidth,
    cellHeight,
    cellPadding,
    overScan,
    cellFormatter,
    onRendered,
  } = props;

  useEffect(() => {
    if (isFunction(onRendered)) onRendered();
  }, []);

  const windowWidth = w > 0 && Number.isInteger(w) ? w : 200;
  const windowHeight = h > 0 && Number.isInteger(h) ? h : 200;

  const numCols = Math.floor(windowWidth / (fixedCellWidth + cellPadding));
  const numRows = Math.ceil(adoms.length / numCols);
  let _overScan = overScan || 30;
  const cellWidth = windowWidth / numCols;

  const ADOM = ({ rowIndex, columnIndex, data, style }) => {
    let ind = rowIndex * numCols + columnIndex;
    let _selected = false;
    let item = '';
    if (data.adoms[ind]) {
      if (selected) _selected = data.adoms[ind].oid === selected.oid;
      data.adoms[ind].rowInd = rowIndex;
      data.adoms[ind].colInd = columnIndex;
      item = cellFormatter({
        data: data.adoms[ind],
        selected: _selected,
        style: style,
      });
    }
    return (
      <ContextMenuTrigger id='adom_select_ctx_menu'>{item}</ContextMenuTrigger>
    );
  };

  return (
    <FixedSizeGrid
      ref={gridRef}
      overscanRowCount={_overScan}
      width={windowWidth}
      columnCount={numCols}
      columnWidth={cellWidth}
      height={windowHeight}
      rowCount={numRows}
      rowHeight={cellHeight}
      itemData={{ adoms: adoms }}
      style={{ overflowX: 'hidden' }}
    >
      {ADOM}
    </FixedSizeGrid>
  );
};
