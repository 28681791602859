import React, { useState, useEffect, useCallback } from 'react';
import { ProToolkit, ProForm } from '@fafm/neowise-pro';
import { fiStore, fiStoreLogview } from 'fistore';
import { parser } from './postgresUpgradeParser';
import { OkBtn, CancelBtn } from 'rc_layout';

const { Header, Body, Footer, Row } = ProForm;

export const useProgess = (getProgress) => {
  const [data, setData] = useState({ upgrading: false, percent: -1 });
  const callbackFn = useCallback((isMounted) => {
    const progress = getProgress();
    if (isMounted && progress) {
      setData(parser(progress));
    }
    return setTimeout(() => callbackFn(isMounted), 5000);
  }, []);

  useEffect(() => {
    let isMounted = true;
    const getIsMounted = () => isMounted;
    const timer = callbackFn(getIsMounted());
    return () => {
      clearTimeout(timer);
      isMounted = false;
    };
  }, []);
  return [data];
};
export const getPostgresUpgradeDetails = () => {
  const state = fiStore.getState();
  return fiStoreLogview.getPostgresUpgrade(state);
};
const CONFIRM_BTN_TXT = {
  reboot: gettext('Continue to Reboot'),
  shutdown: gettext('Continue to Shutdown'),
};
export const openModal = async (showConfirmBtn = false) => {
  try {
    const result = await ProToolkit.openModal(
      <Details
        getDetails={getPostgresUpgradeDetails}
        showConfirmBtn={showConfirmBtn}
      />,
      {
        size: 'md',
        height: '30vh',
      }
    ).result;
    return result;
  } catch (ex) {
    console.error('rejected: ', ex);
  }
};

const Details = ({ $opener, getDetails, showConfirmBtn }) => {
  const [details] = useProgess(getDetails);
  return (
    <>
      <Header>{details?.hint}</Header>
      <Body>
        <Row>
          <div>
            {!details.upgrading && (
              <span class='ffg ffg-enable color-green'></span>
            )}
            <span>{details?.curPhaseText?.detail}</span>
            <span>{details?.curPhaseText?.remain}</span>
          </div>
        </Row>
        <Row>
          {details.upgrading && (
            <div className='tw-w-full'>
              <nw-progress-bar percentage={details?.percent}>
                {details?.percent}%
              </nw-progress-bar>
            </div>
          )}
        </Row>
        <Row>
          {details?.curPhaseText?.more && (
            <div> {details?.curPhaseText?.more} </div>
          )}
        </Row>
      </Body>
      <Footer>
        {showConfirmBtn && (
          <>
            <OkBtn
              onClick={() => $opener.resolve('confirm')}
              automation-id='ok-modal-ok-btn'
            >
              {CONFIRM_BTN_TXT[showConfirmBtn]}
            </OkBtn>
          </>
        )}
        <CancelBtn
          automation-id='cancel-modal-cancel-btn'
          onClick={() => {
            $opener.reject();
          }}
        >
          {gettext('Close')}
        </CancelBtn>
      </Footer>
    </>
  );
};
