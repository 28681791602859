// IMPORTANT: do not import selectors from adom/profile! Do the other way
// around.

import { createSelector } from '@reduxjs/toolkit';
import { overEvery, overSome, negate, isNil } from 'lodash';
import { propOr } from 'fistore/utils/prop-or';
import macros from 'macros';

const PRODUCT_FMG = MACROS.SYS.PRODUCT_FMG;
const PRODUCT_FAZ = MACROS.SYS.PRODUCT_FAZ;
const AVATAR_DATA_PREFIX = 'data:image/png;base64, ';

const { SOC_FABRIC_CFG_ENABLE, SOC_FABRIC_CFG_ROLE_SUPERVISOR } =
  MACROS.FAZ_CFG_PARAM;

export const getSysConfigRaw = (state) => state?.session?.sysConfig;

export function getSysConfig(state) {
  return state?.session?.sysConfig?.data;
}

const _select = (key, defaultVal = undefined) =>
  createSelector(getSysConfig, propOr(key, defaultVal));

export const hasFazFeature = createSelector(getSysConfig, (cfg) =>
  Boolean(MACROS.SYS.PRODUCT_FAZ & cfg.feature_flag)
);

export const hasFmgFeature = createSelector(getSysConfig, (cfg) =>
  Boolean(MACROS.SYS.PRODUCT_FMG & cfg.feature_flag)
);

export const isFaz = () => MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FAZ;
export const isFmg = () => MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FMG;

export const isFazOnly = createSelector(
  hasFmgFeature,
  (hasFmg) => isFaz() && !hasFmg
);
export const isFmgOnly = createSelector(
  hasFazFeature,
  (hasFaz) => isFmg() && !hasFaz
);

export const getIsFtntCloud = createSelector(getSysConfig, (cfg) =>
  Boolean(cfg.have_ftnt_cloud)
);

export const getIsDockerHost = createSelector(getSysConfig, (cfg) =>
  Boolean(cfg.config_docker_host)
);

// export const inFazSupervisor = (_, state) => cfgSelectors.getIsFazSupervisor(state);
export const getIsFazSupervisor = createSelector(getSysConfig, (cfg) => {
  return (
    cfg?.soc_fabric_status === SOC_FABRIC_CFG_ENABLE &&
    cfg?.soc_fabric_role === SOC_FABRIC_CFG_ROLE_SUPERVISOR
  );
});

export const getIsRestrictedAdmin = createSelector(getSysConfig, (cfg) => {
  return cfg?.profile_type === MACROS.USER.SYS.ADMIN_PROFILE_TYPE_RESTRICTED;
});

export const getSN = _select('sn');

export const getHostname = _select('hostname');
// admin_user_name is for remote wildcard user, NOT the user's login name. In
// that case use the `getUsername`.
export const getAdminUserName = _select('admin_user_name');
export const getAdminAndUserName = createSelector(
  getSysConfig,
  (cfg) => `${cfg?.admin_user_name}(${cfg?.username})`
);

export const getSysTimestamp = createSelector(
  getSysConfigRaw,
  (cfg) => cfg?.sysTimestamp || cfg?.data?.sysTimestamp
);

export const getSysTimeDiff = (endTime) =>
  createSelector(getSysTimestamp, (sysTimestamp) => {
    return endTime > sysTimestamp
      ? Math.ceil((endTime - sysTimestamp * 1000) / 86400000)
      : -1;
  });

export const getSysTimeZone = _select('timezone');

// checkers

export const getIsHaSlave = createSelector(
  getSysConfig,
  (cfg) => cfg?.ha_mode === MACROS.SYS.HA_MODE_SLAVE
);

export const getSearchMode = _select('search_mode');
export const getIsVmTrialLic = _select('is_vm_trial_lic');
export const getHaMode = _select('ha_mode');
export const getHasAttachedFaz = _select('faz_attached');
export const getHasDocker = _select('have_docker');
// export const inDocker = _select('have_docker');
export const getDebug = _select('debug');
export const getIsVmsLic = _select('is_vms_lic');
export const getDeviceViewMode = _select('device_view_mode');
export const getIsFazCollector = createSelector(
  getSysConfig,
  (cfg) => cfg?.faz_mode === MACROS.SYS.FAZCORE_SYS_MODE_COLLECTOR
);
export const getAdomMode = _select('adom_mode');
export const getIsAdomEnabled = _select('adom_status');
export const getIsPlatformVM = _select('is_vm');
export const getUsername = _select('username');
export const getAccessibleAdomCount = _select('accessible_adom_counts');
export const getDefaultAdom = _select('adom');

export const getAllowChangeAdom = createSelector(
  getIsAdomEnabled,
  getAccessibleAdomCount,
  (isAdomEnabled, count) => {
    return isAdomEnabled && count > 1;
  }
);

export const fmgOnly = createSelector(getSysConfig, (sysCfg) => {
  return (
    MACROS.SYS.IMG_TYPE === PRODUCT_FMG && !(sysCfg.feature_flag & PRODUCT_FAZ)
  );
});

export const fazOnly = createSelector(getSysConfig, (sysCfg) => {
  return (
    MACROS.SYS.IMG_TYPE === PRODUCT_FAZ && !(sysCfg.feature_flag & PRODUCT_FMG)
  );
});
export const hasRaidFeature = _select('raid_feature');

export const isFazSqlDbEnabled = createSelector(
  hasFazFeature,
  getSysConfig,
  (hasFaz, cfg) => hasFaz && cfg.sql_status
);

export const inFazCollectorMode = _select('faz_mode');

export const hasOrAttachedFaz = overEvery([
  overSome([hasFazFeature, getHasAttachedFaz]),
  negate(getIsRestrictedAdmin),
]);

export const inFmgNotRestrictedAdmin = overEvery([
  isFmg,
  negate(getIsRestrictedAdmin),
]);

export const getSysDisableModule = _select('disable_module');

const IMG_TYPES = {
  FMG: MACROS.SYS.PRODUCT_FMG,
  FAZ: MACROS.SYS.PRODUCT_FAZ,
};

export const imgType = (type) => () => {
  return MACROS.SYS.IMG_TYPE === IMG_TYPES[type];
};

export const hasFortiMeterFeature = _select('fortimeter_status');
export const getShowScript = _select('show_script');

export const getAvailableVersionsByOsType = (osType) =>
  createSelector(getSysConfig, (sysCfg) => {
    if (sysCfg.supported_adom_vers_by_os_type) {
      //may not have entries for some ostype
      return (
        sysCfg.supported_adom_vers_by_os_type[osType] ??
        sysCfg.supported_adom_vers
      );
    }
    return sysCfg.supported_adom_vers;
  });

export const getIsAdomAdvancedMode = createSelector(
  getAdomMode,
  (mode) => mode === MACROS.SYS.ADOM_M_ADVANCED
);

export const getIsAdomNormalMode = createSelector(
  getAdomMode,
  (mode) => mode === MACROS.SYS.ADOM_M_NORMAL
);

export const getCloneOption = _select('clone_option');

export const getSysTimeout = _select('idle_timeout');

export const getAdomAccess = _select('adom_access');

export const getIsPartialAdmin = createSelector(getSysConfig, (sysCfg) => {
  const device_group = sysCfg.device_group;
  return !isNil(device_group) && device_group !== '';
});

export const getIsPublicNetwork = _select('upm_public_network');

// contracts
export const getContracts = _select('contracts') || [];
export const getLicenseByType = (type) =>
  createSelector(getContracts, (contracts) => {
    return contracts?.find((contract) => contract.type === type);
  });
export const getHasLicense = (type) =>
  createSelector(getLicenseByType(type), (license) => {
    return Boolean(license);
  });

export const getHasAiLicense = getHasLicense('AISN');
export const getHasSaseLicense = getHasLicense('FCEM');

export const getIsAiUser = _select('fortiai');
export const getAvatarSrc = createSelector(_select('avatar'), (avatar) =>
  avatar ? AVATAR_DATA_PREFIX + avatar : ''
);
export const getEmail = _select('email');
export const getPhone = _select('phone');

// FMG Fabric
export const getFmgFabricRole = _select('fmg_fabric_role');

export const getIsFmgSupervisor = createSelector(
  getFmgFabricRole,
  (fmg_fabric_role) => {
    return fmg_fabric_role === MACROS.SYS.FMG_CLUSTER_PRIMARY;
  }
);

export const getIsFmgMember = createSelector(
  getFmgFabricRole,
  (fmg_fabric_role) => {
    return fmg_fabric_role === MACROS.SYS.FMG_CLUSTER_WORKER;
  }
);

export const getIsPerAdminFeatureVisibility = createSelector(
  _select('gui_feature_visibility_mode'),
  (flag) => {
    return flag === macros.SYS.GUI_FEATURE_VISIBILITY_PER_ADMIN;
  }
);
