import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  auth_needed_csfroot: {},
  auth_needed_other: [],
};

export const deviceAuthNeededReducer = createReducer(
  initialState,
  (builder) => {
    builder.addCase(
      actions.deviceAuthNeededAction.record.SET,
      (state, action) => {
        const payload = action.payload;
        if (payload) {
          state.auth_needed_csfroot = payload.auth_needed_csfroot;
          state.auth_needed_other = payload.auth_needed_other;
        }
      }
    );
  }
);
