import { useValidEffect } from 'rh_util_hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const useDevicePsirtData = (firmwareKey) => {
  const [tableData, setTableData] = useState([]);
  const psirtData = useSelector((state) => state.adom.devicesPsirt);

  useValidEffect(
    (isValid) => {
      if (!isValid() || !psirtData) return;

      const { byPlatform, byIrNumber } = psirtData;
      const irNums = byPlatform[firmwareKey];
      const vulns = Object.entries(byIrNumber)
        .map(([, info]) => info)
        .filter(({ ir_number }) => irNums.includes(ir_number));
      setTableData(vulns);
    },
    [psirtData]
  );

  return { tableData, psirtData };
};
