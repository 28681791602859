import { useState, useRef, useCallback, SetStateAction, Dispatch } from 'react';

import { isFunction } from 'lodash';

/**
 * To allow getting the latest value (via ref) while using setState, which usually wont give you current state right away
 * @param {any} initialState initial value for react state
 * @returns [react state, Function, react ref]
 */
export const useStateRef = <T,>(
  initialState: T | (() => T)
): [T, Dispatch<SetStateAction<T>>, React.MutableRefObject<T>] => {
  const [state, setState] = useState<T>(initialState);
  const ref = useRef<T>(state);

  const setStateRef: Dispatch<SetStateAction<T>> = useCallback(
    (setStateCallback) => {
      ref.current = isFunction(setStateCallback)
        ? setStateCallback(ref.current)
        : setStateCallback;
      setState(ref.current);
    },
    []
  );

  return [state, setStateRef, ref];
};
