const platform =
  MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FMG
    ? 'fortimanager'
    : 'fortianalyzer';
const version = `${MACROS.SYS.CONFIG_MAJOR_NUM}.${MACROS.SYS.CONFIG_MINOR_NUM}`;

export const UrlService = {
  getAvatarUrl: function (fctuid, username, devid, format, noPlaceHolder) {
    let url = '/p/util/avatar/%s/%s/'.printf([fctuid, username]);
    const params = [];
    if (devid) {
      params.push('devid=' + encodeURIComponent(devid));
    }
    if (format) {
      params.push('format=' + encodeURIComponent(format));
    }
    if (noPlaceHolder) {
      params.push('noPlaceHolder=1');
    }
    if (params.length) {
      url += '?' + params.join('&');
    }
    return url;
  },
  getEuAvatarUrl: function (
    euid,
    epid,
    format,
    noPlaceHolder,
    proxiedServer,
    proxiedAdom
  ) {
    let url = '/p/util/euavatar/%s/%s/'.printf([euid, epid]);
    const params = [];
    if (format) {
      params.push('format=' + encodeURIComponent(format));
    }
    if (noPlaceHolder) {
      params.push('noPlaceHolder=1');
    }
    if (proxiedServer) {
      params.push('proxiedServer=' + proxiedServer);
    }
    if (proxiedAdom) {
      params.push('proxiedAdom=' + proxiedAdom);
    }
    if (params.length) {
      url += '?' + params.join('&');
    }
    return url;
  },
  openFortinetSupport: function () {
    window.open(this.HELP.SUPPORT);
  },
  HELP: {
    VM_INSTALL: `https://docs.fortinet.com/product/${platform}-private-cloud/${version}`,
    SUPPORT: 'http://support.fortinet.com',
  },
  DEVICE: {
    DEV_BY_NAME: '/p/util/get_device_by_name/',
    LOG_RATE: '/p/logview/device/lograte/data/',
  },
  LOG_VIEW: {
    TREE_MENU: '/p/logview/loadLogviewMenu/',

    CONST: '/p/logview/const/',
    COL_SETTINGS_SUBMIT: '/p/logview/colSettingsSubmit',
    COL_SETTINGS_DEF_SUBMIT: '/p/logview/colSettingsDefSubmit',
    GET_DEF_COL_SETTING: '/p/logview/colSettingDef/get/',
    COOKIES: '/p/logview/cookies/',
    LOG_COLS: '/p/logview/colDefs/',
    LOG_QUERY: '/p/logview/submit/',
    SS_LOG_QUERY: '/p/logview/sseventlog/submit/', // system settings->Event Log
    UTM_LOG_QUERY: '/p/logview/utm/submit/',
    KILL_QUERY: '/p/logview/killRunningTask/',
    ASYNC_DOWNLOAD_LOG: '/p/logview/asyncDownloadLog/',
    LOGFILES_BROWSER_INIT: '/p/logview/browseinit/',
    LOGFILES_BROWSER: '/p/logview/browsefiles/',
    SAVE_DEVICE_COOKIE: '/p/logview/device/submit/',
    ARCHIVE: '/p/logview/getArchive/',
    ARCHIVE_CONTENT: '/p/logview/archiveContent/',
    DOWNLOAD_FILE: '/p/logview/fileDownload/',
    DOWNLOAD_FILE_LOGBROWSE: '/p/logview/fileDownload/logbrowse/',
    SUBJECT_EMAIL_CONTENT: '/p/logview/logSubjectEmailContent/',
    DOWNLOAD_EMAIL_ATTACH: '/p/logview/downloadContent/',
    SAVE_SEARCH_SCOPE: '/p/logview/count_limit/submit/',
    SAVE_COLUMN_WIDTH: '/p/logview/colwidth/save/',
    //CUSTOM_TIMEFRAME: '/p/logview/timeframe/custom/?',
    SAVE_TIMEFRAME: '/p/logview/timeframe/cookie/save/',
    CAL_TIME_RANGE: '/p/logview/timeframe/last_n_time_range/',
    SAVE_ITEMS_PER_PAGE: '/p/logview/items_per_page/submit/',

    GET_SEARCH_CRITERIA: '/p/logview/search/list/',
    SAVE_SEARCH_HISTORY: '/p/logview/search/save/',
    CLEAR_LOG_SEARCH: '/p/logview/search/del/',
    GET_SQL_N_COLUMNS: '/p/logview/sql_cols/get/',
    GET_LOG_DOWNLOAD_MAX_ROWS: '/p/logview/logDownload/maxRows/',
    GET_LOG_TYPES_BY_DEV_ID: '/p/logview/devlogtypes/',

    SAVE_DETAIL_POS: '/p/logview/detailPos/save/',
    SAVE_DETAIL_SIZE: '/p/logview/detailSize/save/',
    LOG_TIME_PERIODS: '/p/logview/time_periods/',

    DATASET_SQL_FETCH: '/p/logview/dataset/sql/fetch/',
    DATASET_SQL_CANCEL: '/p/logview/dataset/sql/cancel/',
    GET_ADOM_LOGTYPES: '/p/logview/loadADOMLogtypes/',
  },
  CUSTOM_VIEW: {
    ALL: '/p/logview/customviews/get/',
    GET: '/p/logview/customview/get/',
    RENAME: '/p/logview/customview/rename/',
    SAVE: '/p/logview/customview/save/',
    DEL: '/p/logview/customview/del/',
    DUPLICATED: '/p/logview/nameDuplicated/check/',
  },
  LOG_FILE_BROWSER: {
    DEL: '/p/logview/logbrowsedeletefile/',
    DOWNLOAD: '/p/logview/downloadLogSubmit/',
    IMPORT: '/p/logview/logfilesimport/',
  },
  LOG_GROUP: {
    ALL: '/p/logview/logDeviceGroups/get/',
    SAVE: '/p/logview/logDeviceGroup/save/',
    EDIT: '/p/logview/logDeviceGroup/edit/',
    DEL: '/p/logview/logDeviceGroup/del/',
  },
  OUTBREAK: {
    LICENSE_STATE: '/p/fortisoc/outbreak/get-license-state/',
    LIST: '/p/fortisoc/outbreak/get-list/',
    GET: '/p/fortisoc/outbreak/get/',
    BROWSE: '/p/fgd/outbreak/list/',
    GET_PDF: '/p/fgd/outbreak/get/pdf/',
    GET_HTML: '/p/fgd/outbreak/get/html/',
    GET_NOTIFICATIONS: '/p/fgd/outbreak_notifications/get/',
    ACK_NOTIFICATION: '/p/fgd/outbreak_notifications/acknowledge/',
  },
  NOC_CUSTOM_WIDGET: {
    DEL: '/p/noc/remove_custom_widget/',
  },
  REPORTS: {
    DATASET_SQL_FETCH: '/p/report/dataset/sql/fetch/',
    DATASET_SQL_CANCEL: '/p/report/dataset/sql/cancel/',
    DATASET_N_CHART_BUILD: '/p/report/chart/build/',
  },
  FLATUI_PROXY: {
    ALL: '/cgi-bin/module/flatui_proxy',
  },
  LOG_FORWARD: {
    LIST: '/p/logforwarding/list/ajax/',
    SAVE: '/p/logforwarding/save/ajax/',
    DEL: '/p/logforwarding/delete/ajax/',
    RUN: '/p/logforwarding/run/ajax/',
    TOPOLOGY: '/p/logforwarding/topology/',
    TOPOLOGY_CONSTS: '/p/logforwarding/topology/consts/',
    LIST_OUTPUT_PLUGIN_PROFILES:
      '/p/logforwarding/log_output_plugin_profiles/list/',
    SET_OUTPUT_PLUGIN_PROFILE:
      '/p/logforwarding/log_output_plugin_profiles/set/',
    ADD_OUTPUT_PLUGIN_PROFILE:
      '/p/logforwarding/log_output_plugin_profiles/add/',
    CHECK_OUTPUT_PROFILE_CONFIG:
      '/p/logforwarding/log_output_plugin_profiles/check/',
    DELETE_OUTPUT_PLUGIN_PROFILES:
      '/p/logforwarding/log_output_plugin_profiles/delete/',
  },
  LOG_FETCH: {
    CONSTS: '/p/logfetcher/consts/',
    CONFIGS: '/p/logfetcher/client/configs/',
    SAVE_CONFIG: '/p/logfetcher/client/config/save/',
    DEL_CONFIG: '/p/logfetcher/client/config/del/',
    CLIENT_SESSIONS: '/p/logfetcher/client/sessions/',
    CLIENT_FILTERS: '/p/logfetcher/client/config/filters/',
    SAVE_CLIENT_FILTERS: '/p/logfetcher/client/config/filters/save/',

    SERVICE_SESSION_APPROVE: '/p/logfetcher/service/session/approve/',
    SERVICE_SESSION_CANCEL: '/p/logfetcher/service/session/cancel/',
    SERVICE_SESSION_DENY: '/p/logfetcher/service/session/deny/',
    SERVICE_SESSION_PAUSE: '/p/logfetcher/service/session/pause/',
    SERVICE_SESSION_RESUME: '/p/logfetcher/service/session/resume/',
    SERVICE_SESSION_REVIEW: '/p/logfetcher/service/session/view/',

    CLIENT_SESSION_CANCEL: '/p/logfetcher/client/session/cancel/',
    CLIENT_SESSION_PAUSE: '/p/logfetcher/client/session/pause/',
    CLIENT_SESSION_RESUME: '/p/logfetcher/client/session/resume/',
    CLIENT_SESSION_RUN: '/p/logfetcher/client/session/run/',

    CLIENT_SYNC_CONF: '/p/logfetcher/client/sync/conf/',

    SERVER_ADOMS: '/p/logfetcher/server/adoms/',
    SERVER_ADOM_DEVICES: '/p/logfetcher/server/adom/devices/',
  },
  LOG_FETCHER: {
    // all requests from clients
    SERVICE_SESSIONS: '/p/logfetcher/service/sessions/',
  },
  SIEM: {
    INTERESTING_FIELDS_SUMMARY: '/p/siem/interesting_fields_summary/',
    INTERESTING_FIELD_TOP_COUNT: '/p/siem/interesting_field_top_count/',
    INTERESTING_FIELD_TOP_STATS: '/p/siem/interesting_field_top_stats/',
    EVENT_HISTOGRAM: '/p/siem/siem_event_histogram/',
  },
  SSO: {
    TEMPLATE_PREVIEW: '/p/sso_idp/template/preview/',
    FABRIC_SP_CONFIG: '/p/util/sso_fabric_config/',
  },
  SOC_FABRIC: {
    DVM: '/p/fabric/socfabric/dvm/',
    DVM_MEMBERS: '/p/fabric/socfabric/dvm/members/',
    STATS: '/p/fabric/socfabric/dvm/stats/',
    MEMBER_GROUPS_ONE: '/p/fabric/socfabric/dvm/member-groups/one/',
    MEMBER_GROUPS_ALL: '/p/fabric/socfabric/dvm/member-groups/list/',
    MEMBER_GROUPS_ADD: '/p/fabric/socfabric/dvm/member-groups/add/',
    MEMBER_GROUPS_EDIT: '/p/fabric/socfabric/dvm/member-groups/update/',
    MEMBER_GROUPS_DELETE: '/p/fabric/socfabric/dvm/member-groups/delete/',
    JOIN: '/p/fabric/socfabric/dvm/join/',
  },
  UTIL: {
    LOG_COLUMNS: '/p/util/log/columns/',
    LOG_COLUMNS_TEXT: '/p/util/log/columns/text/',
    GET_IP_ADDRESS: '/p/util/ip/',
    GET_QUICK_ACCESS: '/p/util/quick_access/',
    IDP_CUSTOM_LOGIN_TEMPLATE: '/p/util/idp_custom_login_template/',
    FORTICLOUD_METADATA: '/p/util/forticloud_metadata/',
    FORTICLOUD_PORTAL_DATA: '/p/util/forticloud_portal_data/',
    FORTICLOUD_LICENSE_INFO: '/p/util/forticloud_license_info/',
    FORTICLOUD_SOC_STATUS: '/p/util/forticloud_soc_status/',
  },
};
