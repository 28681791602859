import { useState, useCallback, useMemo, memo, useRef } from 'react';
import cn from 'classnames';
import { isNil, isString, isFunction, isObject } from 'lodash';

import { useValidEffect } from 'rh_util_hooks';
import { NwBadge } from '@fafm/neowise/react';
import { NwTooltip } from '@fafm/neowise-core';
import { ProLego, ProTable } from '@fafm/neowise-pro';
import { genv4 } from 'fi-uuid';

import { useHighlightTooltipTrigger } from './multi_record_cell_hooks';

import './index.less';
import { renderToString } from 'react-dom/server';

/** ----------------------------------------------------------------------------
 * Constants
 * -------------------------------------------------------------------------- */
const TOOLTIP_DEFAULT_SETTINGS = {
  placement: 'right',
  distance: 3,
  showDelay: 200,
  hideDelay: 600,
  padding: '',
  autoUpdate: {
    ancestorScroll: false,
    ancestorResize: false,
    elementResize: true,
    layoutShift: true,
  },
};

export const MoreRecordsTooltip = ({
  data = [],
  className = '',
  style = {},
  searchText,
  textKey = 'name',
  getAutoId,
  onHide = () => {},
  tooltipConfigs = {},
  popoverEntryFormatter,
  popoverSearchFn,
}) => {
  /** ----------------------------------------------------------------------------
   * Constants
   * -------------------------------------------------------------------------- */
  const numberOfRows = data?.length || 0;

  const { searchTerm } = ProTable?.useSearchContext() || {};
  searchText = searchText || searchTerm;

  /** ----------------------------------------------------------------------------
   * States
   * -------------------------------------------------------------------------- */
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [matchedSearchText, setMatchedSearchText] = useState(false);

  /** ----------------------------------------------------------------------------
   * Hooks
   * -------------------------------------------------------------------------- */
  const tooltipBadgeRef = useRef();
  const entries = useMemo(() => {
    return data.map((entry) => {
      if (isObject(entry)) {
        if (!entry.id) entry.id = genv4();
        return entry;
      }
      return {
        id: entry || genv4(),
        [textKey]: entry,
      };
    });
  }, [data]);

  // get whether there is matched entries in tooltip content
  useValidEffect(
    (getIsValid) => {
      if (!getIsValid()) return;

      if (isNil(searchText) || searchText == '') {
        setMatchedSearchText(false);
        return;
      }

      const matched = entries.some((entry) => {
        const text = entry?.[textKey] || entry;
        if (!isString(text)) return false;
        return text.toUpperCase().includes(searchText.toUpperCase());
      });
      setMatchedSearchText(matched);
    },
    [entries, searchText]
  );

  // highlight tooltip trigger when there is matched entries in tooltip content
  useHighlightTooltipTrigger({ tooltipBadgeRef, matchedSearchText });

  /** ----------------------------------------------------------------------------
   * Event handlers
   * -------------------------------------------------------------------------- */
  const onTooltipShow = () => {
    setTooltipOpened(true);
  };

  const onTooltipHide = () => {
    setTooltipOpened(false);
    if (isFunction(onHide)) onHide();
  };

  /** ----------------------------------------------------------------------------
   * Rendering
   * -------------------------------------------------------------------------- */
  const renderSearchList = () => {
    const comptProps = {
      entries,
      tooltipOpened,
      searchText,
      popoverEntryFormatter,
      textKey,
      popoverSearchFn,
    };
    return <TooltipSearchList {...comptProps} />;
  };

  return (
    <div
      className='more-records-tooltip-ctn'
      automation-id={getAutoId('more-records-tooltip-ctn')}
    >
      <NwTooltip
        {...TOOLTIP_DEFAULT_SETTINGS}
        onShow={onTooltipShow}
        onHide={onTooltipHide}
        automation-id={getAutoId('more-records-tooltip')}
        content={
          <div
            className={cn(
              'tw-p-2 more-records-popover tw-text-base',
              className
            )}
            style={style}
          >
            {renderSearchList()}
          </div>
        }
        {...tooltipConfigs}
      >
        <NwBadge
          ref={tooltipBadgeRef}
          className='more-records-popover-trigger'
          pill
          automation-id={getAutoId('more-records-popover')}
        >{`+ ${numberOfRows} ${gettext('more')}`}</NwBadge>
      </NwTooltip>
    </div>
  );
};

const TooltipSearchList = memo(
  ({
    entries,
    tooltipOpened,
    searchText,
    popoverEntryFormatter,
    textKey,
    popoverSearchFn,
  }) => {
    const defaultPopoverEntryFormatter = useCallback(
      (highlighter) => (entry) => {
        const text = entry[textKey] || '';
        const jsx = (
          <div className='tw-truncate' title={text}>
            <span>{highlighter(text)}</span>
          </div>
        );
        return renderToString(jsx);
      },
      [textKey]
    );

    // need to create highlighter here in case highlighter from ProTable is not updated yet
    const _highlighter = ProTable.searchHiliter(searchText) || ((text) => text);
    const formatEntry = popoverEntryFormatter
      ? popoverEntryFormatter(_highlighter)
      : defaultPopoverEntryFormatter(_highlighter);
    return (
      !!tooltipOpened && (
        <ProLego.SearchList
          name='more-records-tooltip-searchlist'
          source={entries}
          selectMode={'none'}
          searchFn={
            popoverSearchFn || ((item, searcher) => searcher(item[textKey]))
          }
          formatItemHTML={formatEntry}
          showTooltip={false}
          // logger={(st, act) => act.type !== 'WL/HOVER' && console.log(st, act.type, act.payload)}
        />
      )
    );
  }
);
