import { get, isObject, has, find } from 'lodash';
import { fiStorePno, fiStore, fiStoreUtils } from 'fistore';
import { readCache } from './common';
import { fiAdom } from 'fi-session';
import { fiFmgHttp } from 'fi-http';
import {
  genPPkgQueryUrl,
  getPackage as getPackageAPI,
} from 'fistore/pno/api/pkg';
const { flatui_proxy } = fiStoreUtils;

/**
 * Find a policy package by oid or path
 * @param {String|Object} pkg - give oid or specify finding by oid or path by giving an Object: {path: <String>}
 * @returns ppkg data
 */
export const findPolicyPackage = (pkg) =>
  _findPolicyPackage(get(readCache(), 'map'), pkg);

function _findPolicyPackage(ppkgMapCache, pkg) {
  if (!ppkgMapCache) {
    return;
  }
  if (!isObject(pkg) || has(pkg, 'oid')) {
    // Compare by oid
    const oid = get(pkg, 'oid', pkg);
    return ppkgMapCache[oid];
  }
  // Compare by path
  return find(ppkgMapCache, ({ path }) => path === get(pkg, 'path'));
}

const getPPkgFromCache = (cachedPPkg) => {
  const found = _findPolicyPackage(get(readCache(), 'map'), cachedPPkg);
  return found
    ? { ...found, isPblock: found.pblock || found.pblocks || false }
    : undefined;
};

export const getCurrentPPkg = () => {
  return getPPkgFromCache(fiStorePno.getCurrentPPkg(fiStore.getState()));
};

export const findFirstPkg = () => {
  let found;
  get(readCache(), 'list', []).find(function find(pkg) {
    if (pkg.type === 'pkg') {
      found = pkg;
      return true;
    }
    return pkg.subobj?.find(find);
  });
  return found;
};

export function getPackageSyntax(param) {
  const DEF_PARAM = {
    adom: null,
    path: '', // pkg path
    pblock: false,
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    if (!prm.adom) {
      prm.adom = fiAdom.current();
    }
    return {
      url: `pm/config/${
        prm.adom.globaldb ? 'global' : 'adom/' + prm.adom.name
      }/${prm.pblock ? 'pblock' : 'pkg'}/${prm.path}`,
      option: 'syntax',
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

export function getPackagesInOneCate(param, cate) {
  const DEF_PARAM = {
    adom: null,
    path: '', // pkg path
    pblock: false,
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    if (!prm.adom) {
      prm.adom = fiAdom.current();
    }
    return {
      url: `pm/config/${
        prm.adom.globaldb ? 'global' : 'adom/' + prm.adom.name
      }/${prm.pblock ? 'pblock' : 'pkg'}/${prm.path}/${cate.join('/')}`,
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

export const getPackage = getPackageAPI;

/**
 * Set Installation target for package(s)
 * @param {Object|Object[]} param - see `DEF_PARAM` for structure
 */
export function setInstlTarget(param) {
  const DEF_PARAM = {
    adom: {},
    path: '', // pkg path
    isPblock: false,
    'scope member': [
      {
        name: '', // device name
        vdom: '', // vdom name
      },
    ],
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    return {
      url: genPPkgQueryUrl({
        adom: prm.adom,
        pkg: prm.path,
        isPblock: prm.isPblock,
      }),
      data: {
        'scope member': prm['scope member'],
      },
    };
  });
  return fiFmgHttp.query({
    method: 'set',
    params: reqParams,
  });
}

export function getPBlockWhereUsed(param) {
  const DEF_PARAM = {
    adom: {},
    pbKey: '', // pblock name/path
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    return {
      url: genPPkgQueryUrl({
        adom: prm.adom,
        pkg: prm.pbKey,
      }),
      option: ['where_used'],
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

/*
 * Updates the installation schedule
 * @param {String} schdDateTime - the data and time to install, should be in the format of 'yyyy-MM-dd HH:mm'
 * @param {String} pkgPath - path of package
 * @param {Object[]} scopeMember - the installation target.
 * @param {String} scopeMember[].name - the device name
 * @param {String} scopeMember[].vdom - the VDOM name of the device
 * @param {Object} [adom]
 * @param {String} [adomRevName] - The name for the new ADOM revision. If not set in add request, no adom revision will be created during installation.
 * @param {String} [adomRevComment] - The comment for the new ADOM revision. It will be ignored in add request if adom_rev_name is not set.
 * @param {String} [devRevComment] - The comment for the device configuration revision that will be generated during install.
 * @return {Promise}
 */
export async function updateScheduleInstall(
  timestamp,
  pkgPath,
  scopeMember,
  adom,
  adomRevName,
  adomRevComment,
  devRevComment
) {
  const url = genPPkgQueryUrl({ adom });
  const req = {
    id: 1,
    method: 'add',
    params: [
      {
        url: url + '/' + pkgPath + '/schedule',
        data: {
          datetime: timestamp + '',
          scope: scopeMember,
          adom_rev_name: adomRevName,
          adom_rev_comment: adomRevComment,
          dev_rev_comment: devRevComment,
        },
      },
    ],
  };
  return fiFmgHttp.query(req);
}

/**
 * Cancels the schedule installation
 * @param {String} pkgPath - path of package
 * @param {String|Object} [adom]
 * @return {Promise}
 */
export async function cancelScheduleInstall(pkgPath, adom) {
  const url = genPPkgQueryUrl({ adom });
  const req = {
    id: 1,
    method: 'delete',
    params: [
      {
        url: url + '/' + pkgPath + '/schedule',
      },
    ],
  };
  return fiFmgHttp.query(req);
}

export function delPackage(param) {
  const DEF_PARAM = {
    adom: {},
    pkg: null,
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    return {
      url: genPPkgQueryUrl({
        adom: prm.adom,
        pkg: prm.pkg,
      }),
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'delete',
    params: reqParams,
  });
}

export function editPackage(param) {
  const DEF_PARAM = {
    adom: {},
    pkg: null,
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    const reqData = {
      name: prm.pkg.name,
    };
    if (has(prm.pkg, 'package settings')) {
      reqData['package settings'] = prm.pkg['package settings'];
    }
    return {
      url: genPPkgQueryUrl({
        adom: prm.adom,
        pkg: prm.pkg,
      }),
      data: reqData,
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'update',
    params: reqParams,
  });
}

// Moves a given policy package
// params:
//   pkg, the policy package to be cloned
//   name, new policy package name
//   dest, policy folder, where to put the new policy package
export async function movePolicyPackage(pkg, name, dest, adom) {
  adom = adom || fiAdom.current();
  const adomName = fiAdom.isGlobalAdom(adom) ? 'global' : adom.name;
  const url = '/securityconsole/package/move';
  const { path: pkgToMove } = pkg;
  const req = {
    method: 'exec',
    params: [
      {
        url: url,
        data: {
          adom: adomName,
          pkg: pkgToMove,
          dst_parent: dest,
          dst_name: name,
        },
      },
    ],
    id: 1,
  };
  return fiFmgHttp.query(req);
}

// clone a given policy package
// params:
//   pkgtmpl, the policy package to be cloned
//   name, new policy package name
//   dest, policy folder, where to put the new policy package
//   adom
export async function clonePolicyPackage(pkgtmpl, name, dest, adom) {
  const adomName = fiAdom.isGlobalAdom(adom) ? 'global' : adom.name;
  let url = '/securityconsole';
  if (pkgtmpl.pblock) {
    url += '/pblock';
  } else {
    url += '/package';
  }
  url += '/clone';
  const data = {
    adom: adomName,
    dst_name: name,
  };
  if (pkgtmpl.pblock) {
    data.pblock = pkgtmpl.name;
  } else {
    // Found when fixing Bug 409381: when clone policy package, response 'pkg error'
    // thus change pkgToClone from "pkgtmpl.path + '/' + pkgtmpl.name" to "pkgtmpl.path"
    const pkgToClone = pkgtmpl.path;
    data.pkg = pkgToClone;
    data.dst_parent = dest;
  }
  const req = {
    method: 'exec',
    params: [
      {
        url: url,
        data: data,
      },
    ],
    id: 1,
  };
  return fiFmgHttp.query(req);
}

/**
 *@description create a policy package
 *@param {Object} adom
 *@param {object} cfg - out of maintain, this attribute depends on GUI. Search the function for possible use cases.
 *@return {promise} - promise of response from json api
 */
export async function createPolicyPackage(adom, cfg) {
  let url, req, data;
  url = genPPkgQueryUrl({
    adom: adom,
    isPblock: cfg.pblock,
  });
  data = {
    name: cfg.name,
    type: cfg.type,
  };
  if (has(cfg, 'package settings')) {
    data['package settings'] = cfg['package settings'];
  }
  if (!cfg.pblock && cfg.folder && !cfg.folder.isRootNode) {
    data = (function _genPPkgFullPath(data, parent) {
      const folder = {};
      folder.name = parent.name;
      folder.type = 'folder';
      folder.subobj = [data];
      if (!parent.folder) {
        return folder;
      } else {
        return _genPPkgFullPath(folder, parent.folder);
      }
    })(data, cfg.folder);
  }
  req = {
    id: 1,
    method: 'set',
    params: [
      {
        url: url,
        data: [data],
      },
    ],
  };
  return fiFmgHttp.query(req);
}

export function getPackageSettings(param) {
  const DEF_PARAM = {
    adom: null,
    path: '', // pkg path
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    if (!prm.adom) {
      prm.adom = fiAdom.current();
    }
    return {
      url: genPPkgSettingUrl({
        adom: prm.adom,
        pkgPath: prm.path,
      }),
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

export async function setPackageSettings({ adom, pkg, data }) {
  return fiFmgHttp.query({
    id: 1,
    method: 'set',
    params: [
      {
        url: genPPkgSettingUrl({ adom, pkgPath: pkg.path }),
        data,
      },
    ],
  });
}

function genPPkgSettingUrl({ adom, pkgPath }) {
  return `pm/config/${
    fiAdom.isGlobalAdom(adom) ? 'global' : 'adom/' + adom.name
  }/pkg/${pkgPath}/policy/package/settings`;
}

async function queryPkgPath(pkgOid) {
  const cmd = {
    url: `/gui/currentAdom/pkgs/${pkgOid}/path`,
    method: 'get',
  };
  return flatui_proxy(cmd);
}

function parseQuery(response) {
  const result = response['result'];
  return result[0]['data'];
}

export async function getPkgPath(pkgOid) {
  const response = await queryPkgPath(pkgOid);
  const ret = parseQuery(response);
  return ret;
}

export function isPblockDisabled(isPblock, sysCfgPackageAccess, adom, pkgOid) {
  const adomName = adom.globaldb
    ? MACROS.DVM.PM2_GUI_GLOBAL_ADOM_NAME
    : adom.name;
  return (
    isPblock &&
    !(
      sysCfgPackageAccess?.isAllPblocks ||
      sysCfgPackageAccess?.pblocksAccess?.includes(`${adomName}:${pkgOid}`)
    )
  );
}
