export function importAllModules(contextFn) {
  contextFn.keys().forEach(contextFn);
  return contextFn;
}

export function __extends(child, _parent) {
  for (var key in _parent) {
    if (_parent.hasOwnProperty(key)) {
      child[key] = _parent[key];
    }
  }
  function ctor() {
    this.constructor = child;
  }
  ctor.prototype = _parent.prototype;
  child.prototype = new ctor();
  child.__super__ = _parent.prototype;
  return child;
}

export * from './string_util';

export const getAngularService = () => {
  console.error('angular is not supported!');
  return null;
  // const injector = angular.element(document.body).injector();
  // if (!injector || !injector.get) {
  //   throw new Error(`Couldn't find angular injector to get "${name}" service`);
  // }
  //
  // const isGettingMultiService = Array.isArray(name);
  // if (!isGettingMultiService) {
  //   name = [name];
  // }
  //
  // const service = name.map((n) => {
  //   const serv = injector.get(n);
  //   if (!serv) {
  //     throw new Error(`Couldn't find "${n}" angular service`);
  //   }
  //   return serv;
  // });
  //
  // return isGettingMultiService ? service : service[0];
};

/**
 * List all Angular Services for easier maintenance
 * @param {String[]} AngularDependencies
 */
export const $ng_ = (AngularDependencies) => {
  return (AngularServiceName) => {
    if (!AngularDependencies.includes(AngularServiceName)) {
      throw `Add your Angular service ${AngularServiceName} to the dependency list first before use it please`;
    }
    return getAngularService(AngularServiceName);
  };
};

/**
 * Substitute for your beloved $q.when()
 * @param {Sync or Async function | object or value...} val
 * @returns Promise
 */
export const promiseWhen = (val) =>
  new Promise((resolve) => resolve(typeof val === 'function' ? val() : val));

export * from './is_type';
export * from './object_util';
export * from './file_util';
export * from './vtree_util';
export * from './color_util';
export * from './random_num_util';
export * from './ip_util';
export * from './algorithm';
export { fiIdleTimer } from './idle_timer';
export * from './debounce';
export * from './copy';
export * from './foreach';
export * from './domUtil';
export * from './css';
export * from './count_tree_leaves';
export * from './tree';
export * from './selector';
export * from './num_util';
