import { NwInput, NwIcon } from '@fafm/neowise-core';
import cn from 'classnames';
import { useEffect, useRef } from 'react';

export function InputGroup({ className, children }) {
  return (
    <div
      className={cn('tw-my-4 tw-mx-auto tw-max-w-72 tw-relative', className)}
    >
      {children}
    </div>
  );
}

export function LoginInput({
  type,
  placeholder,
  onInput,
  icon,
  isPassword,
  value,
  onKeyUp = null,
  focus = false,
  ...rest
}) {
  const input = useRef(null);
  useEffect(() => {
    focus && input.current?.focus();
  }, [focus]);

  return (
    <NwInput
      type={type}
      value={value}
      size='large'
      placeholder={placeholder}
      onInput={onInput}
      togglePassword={isPassword}
      className='focus:tw-shadow-focus tw-text-sm'
      prefix={icon && <NwIcon name={icon} className='tw-text-base' />}
      onKeyUp={onKeyUp}
      ref={input}
      {...rest}
    />
  );
}
