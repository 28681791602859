export const make_device_filter = (is_fabric_adom) => (device) => {
  const isFAZ = device['os_type'] === MACROS.DVM.DVM_OS_TYPE_FAZ; // is a fortiAnalyzer
  const isModeFMG = device['manage_mode'] & MACROS.DVM.DVM_MGMT_MODE_FMG; // could be managed by FMG
  const isModeFMGFAZ =
    device['manage_mode'] === MACROS.DVM.DVM_MGMT_MODE_FMG_FAZ; // both managed and receiving log
  const isMngFAZ = isModeFMG && isFAZ; // is a managed fortiAnalyzer

  return {
    isLoggingDevice: () => {
      if (device.model_dev) return true; //model_dev should always be in logging group #627729

      // if isModeFMG is false, the device must be logging only
      if (!isModeFMG) return true;

      return !isMngFAZ && isModeFMGFAZ;
    },
    isManagedDevice: () => {
      if (device.model_dev) return true; //model_dev should always be in managed group #627729

      if (is_fabric_adom) {
        return isModeFMGFAZ && !isFAZ;
      }

      return isModeFMG;
    },
    isManagedFAZ: () => {
      if (device.model_dev) return false;

      if (is_fabric_adom) return isModeFMG && isFAZ;
      return isFAZ;
    },
  };
};
