import { fiFmgHttp } from 'fi-http';

export async function fetchDvmChecksum(adomName, isGlobal) {
  const req = {
    id: 0,
    method: 'get',
    params: [
      {
        url: '/dvmdb/adom/' + (isGlobal ? 'global' : adomName),
        fields: ['uuid'],
      },
    ],
  };

  const resp = await fiFmgHttp.forward(req);
  return resp[0]?.data?.uuid || '';
}

export async function fetchAdomChecksum(adomName, isGlobal) {
  const adomUrl = isGlobal ? 'global' : `adom/${adomName}`;
  const req = {
    id: 0,
    method: 'get',
    params: [
      {
        url: `/pm/config/${adomUrl}/obj`,
        option: ['devinfo'],
      },
    ],
  };
  const resp = await fiFmgHttp.forward(req);
  return resp[0]?.data?.uuid || '';
}
