import { getZonePath } from 'fi-web/fi-datetime';
import { isNil } from 'lodash';
import moment from 'moment';

export const formatDate = (date, timeFormat = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return '';
  }
  return moment(date).format(timeFormat);
};

export const getTimeRange = (
  tp,
  timeFormat = 'YYYY-MM-DD HH:mm:ss',
  timeZone = null
) => {
  let start, end;
  if (tp.key === 'custom') {
    start = tp.start;
    end = tp.end;
  } else {
    let _momentObj = timeZone ? moment.tz(timeZone) : moment();
    end = _momentObj.format(timeFormat);
    start = tp.lastN
      ? moment(end).subtract(tp.lastN, tp.unit.charAt(0)).format(timeFormat)
      : moment(end).format(timeFormat);
  }

  return {
    start,
    end,
  };
};

export const isValidDate = (dateStr) => {
  const dateObj = new Date(dateStr);
  let good = !isNaN(dateObj.getDate());
  if (good) {
    const year = dateObj.getFullYear();
    if (year > 9999 || year < 1970) return false; // y10k but we want this.
  }
  return good;
};

export const getTimePeriodInSeconds = (
  selectedPeriod,
  currentTime = Math.floor(Date.now() / 1000)
) => {
  const unitTimeMap = {
    min: 60,
    hour: 3600,
    day: 3600 * 24,
    week: 3600 * 24 * 7,
  };

  if (!isNil(selectedPeriod?.key)) {
    if (selectedPeriod.key === 'custom') {
      const _timeStart = moment(selectedPeriod.start).unix();
      const _timeEnd = moment(selectedPeriod.end).unix();
      return { start: _timeStart, end: _timeEnd };
    } else {
      const { lastN, unit } = selectedPeriod;
      const difference = lastN * unitTimeMap[unit];

      return {
        end: currentTime,
        start: currentTime - difference,
      };
    }
  }

  return { start: 0, end: 0 };
};

export const formatTimeLabel = ({
  timestamp,
  zone,
  format = 'YYYY-MM-DD HH:mm:ss',
}) => {
  const _zone = zone ?? getZonePath();
  const _format = format ?? 'YYYY-MM-DD HH:mm:ss';

  return moment(timestamp).tz(_zone).format(_format);
};
