import goog from '@fafm/goog';
import { compStrValuesWithNumber } from './string';

const RESULT_EQUALS = 0; // if `version1` is equal to `version2`.
const RESULT_LOWER = -1; // if `version1` is lower than `version2`.
const RESULT_HIGHER = 1; // if `version1` is higher than `version2`.

/**
 * Compares two firmware versions.
 * @param version1 Format like: '5.6.7' or '5.6'
 * @param version2 Format like: '5.6.7' or '5.6'
 * @param strOp Comparison operator: '>', '<', '>=', '<=', '==', '!='
 * @param ignorePatch Only check main and major version, ignore patch number (e.g., '5.6' === '5.6.x').
 * @returns boolean indicating the result of comparison according to the operator.
 */
export function compareFirmwares(
  version1: string,
  version2: string,
  strOp: string,
  ignorePatch: boolean
): boolean {
  const _arr1 = version1.split('.');
  const _arr2 = version2.split('.');
  const _temp1 = [];
  const _temp2 = [];
  const _num = ignorePatch ? 2 : 3;

  for (let i = 0; i < _num; i++) {
    _temp1.push(_arr1[i] ?? '0');
    _temp2.push(_arr2[i] ?? '0');
  }
  const _verStr1 = _temp1.join('.');
  const _verStr2 = _temp2.join('.');

  try {
    switch (strOp) {
      case '<':
        return goog.string.compareVersions(_verStr1, _verStr2) === RESULT_LOWER;
      case '<=':
        return (
          goog.string.compareVersions(_verStr1, _verStr2) !== RESULT_HIGHER
        );
      case '>':
        return (
          goog.string.compareVersions(_verStr1, _verStr2) === RESULT_HIGHER
        );
      case '>=':
        return goog.string.compareVersions(_verStr1, _verStr2) !== RESULT_LOWER;
      case '==':
        return (
          goog.string.compareVersions(_verStr1, _verStr2) === RESULT_EQUALS
        );
      case '!=':
        return (
          goog.string.compareVersions(_verStr1, _verStr2) !== RESULT_EQUALS
        );
      default:
        return (
          goog.string.compareVersions(_verStr1, _verStr2) === RESULT_EQUALS
        );
    }
    // eslint-disable-next-line
  } catch (e) {}
  return false;
}

export function sortStringWithNumber<T extends Record<string, any>>(
  data: T[] | T,
  attr: keyof T,
  reverse?: boolean
) {
  if (Array.isArray(data)) {
    data.sort(function (a, b) {
      return reverse
        ? compStrValuesWithNumber(a[attr], b[attr])
        : -compStrValuesWithNumber(a[attr], b[attr]);
    });
  }
  return data;
}
