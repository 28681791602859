import { useMemo, useState } from 'react';
import {
  getTaskLineDetail,
  isTaskDone,
  isTaskPending,
  isInstallType,
  isRunScriptType,
  toFormatLocaleTime,
} from './task_util';
import { ProToolkit, ProTable } from '@fafm/neowise-pro';
import { formatSecondsCompact } from 'kit-time';
import { TaskProgressBar } from './TaskProgressBar';
import { ViewTaskHistory } from './ViewTaskHistory';
import { ViewInstallLog } from './ViewInstallLog';
import { openScriptHistoryModal } from './ViewScriptLog';
import { isArray } from 'lodash';

export const LineTable = ({
  taskData,
  // unix number, use this to calculate task 'time used' if task is not done
  serverTime,
  getAdomNameById,
  ...rest
}) => {
  const $opener = ProToolkit.useOpener();

  //Mutate task.line!!
  const lineHasDevId = useMemo(() => {
    const devIdSet = {};
    // https://pmdb.fortinet.com/ProjectManagement/viewDocument.php?id=23047&doc_type=spec
    // Calvin Che@2023-01-25 11:57:55 pm
    // no log for copy line
    const copyLineName = /\[copy\]/;

    (taskData?.line || []).forEach((l, i) => {
      l._gui_sequence = i + 1;
      l._gui_startTime = getLineStartTime(l);
      const deviceId = l.oid;

      if (deviceId && !copyLineName.test(l.name)) {
        if (devIdSet[deviceId]) {
          if (!l.vdom) {
            devIdSet[deviceId] = l;
          }
        } else {
          devIdSet[deviceId] = l;
        }
      }
    });

    // only enable view log on device row.
    // we dont want to show duplicate install log
    for (const oid in devIdSet) {
      devIdSet[oid]._gui_canViewInstallLog = true;
    }

    return !!Object.keys(devIdSet).length;
  }, [taskData]);

  const coldefs = useMemo(
    () => [
      {
        key: 'seq',
        dataKey: '_gui_sequence',
        title: '#',
        width: 50,
      },
      {
        key: 'name',
        dataKey: 'name',
        title: gettext('Name'),
        dataGetter: ({ rowData }) => {
          let data = rowData.name;
          if (rowData.vdom) {
            data += ' - ' + rowData.vdom;
          }
          return data;
        },
      },
      {
        key: 'time_used',
        title: gettext('Time Used'),
        width: 120,
        dataGetter: ({ rowData }) => {
          const { start_tm, end_tm, _gui_startTime } = rowData; //running task also has end_tm
          if (!start_tm || !_gui_startTime) return -1;
          return (isTaskDone(rowData) ? end_tm : serverTime) - _gui_startTime;
        },
        toSearchString: ({ cellData }) => {
          return formatSecondsCompact(cellData);
        },
        cellRenderer: ({ cellData }, hiliter) => {
          return hiliter(formatSecondsCompact(cellData));
        },
      },
      {
        key: 'start_tm',
        title: gettext('Start Time'),
        toSortValue: ({ rowData }) => rowData.start_tm || 0,
        dataGetter: ({ rowData }) => {
          return toFormatLocaleTime(rowData.start_tm);
        },
        hidden: true,
      },
      {
        key: 'end_tm',
        title: gettext('End Time'),
        toSortValue: ({ rowData }) => rowData.end_tm || 0,
        dataGetter: ({ rowData }) => {
          return toFormatLocaleTime(rowData.end_tm);
        },
        hidden: true,
      },
      {
        key: 'status',
        title: gettext('Status'),
        dataGetter: ({ rowData }) =>
          isTaskDone(rowData) ? getTaskLineDetail(rowData) : undefined,
        cellRenderer: ({ cellData, rowData }, highlighter) => {
          if (cellData !== undefined) {
            return highlighter(cellData);
          } else {
            return (
              <TaskProgressBar
                taskData={rowData}
                autoIncNum={0}
                style={{ width: '200px' }}
              />
            );
          }
        },
      },
    ],
    [serverTime]
  );

  const [historyLineKey, setHistoryLineKey] = useState();
  const openTaskHistory = $opener.useComponent(
    useMemo(() => {
      let line;
      if (
        !historyLineKey ||
        !(line = taskData?.line?.find(
          (line) => line._gui_sequence === historyLineKey
        )) ||
        !isArray(line.history)
      )
        return;
      const { history, start_tm } = line;
      return <ViewTaskHistory history={history} startTm={start_tm} />;
    }, [historyLineKey, taskData?.line])
  );

  const getToolbars = (lines) => {
    // console.log(lines);
    return [
      {
        key: 'view-install-log',
        label: gettext('View Installation Log'),
        icon: {
          name: 'summary',
        },
        hidden: !(isInstallType(taskData) && lineHasDevId),
        disabled: !(lines.length === 1 && lines[0]._gui_canViewInstallLog),
        exec: () => {
          $opener.open(
            <ViewInstallLog
              oid={lines[0].oid}
              taskId={lines[0].taskid || taskData.id}
              poid={lines[0].poid}
            />
          );
        },
      },
      {
        key: 'view-script-history',
        label: gettext('View Script Execution History'),
        icon: {
          name: 'where-used',
        },
        hidden: !isRunScriptType(taskData),
        disabled: lines.length !== 1,
        exec: () => viewRunScript(lines[0], getAdomNameById(taskData.adom)),
      },
      {
        key: 'view-progress-report',
        label: gettext('View Progress Report'),
        icon: {
          name: 'snmp',
        },
        disabled: !(lines.length === 1 && isArray(lines[0].history)),
        exec: () => {
          setHistoryLineKey(lines[0]._gui_sequence);
          openTaskHistory();
        },
      },
    ].filter((menu) => !menu.hidden);
  };

  const getCtxMenus = (lines) => {
    return getToolbars(lines).filter((menu) => !menu.disabled);
  };

  return (
    <ProTable.TableView
      ckColumn={false}
      tableId='task_line_table'
      rowKey='_gui_sequence'
      data={taskData?.line}
      isLoading={!taskData?.line}
      columns={coldefs}
      getToolbarItems={getToolbars}
      getContextMenuItems={getCtxMenus}
      onDoubleClickRow='view-progress-report'
      rowHeight={25}
      {...rest}
    />
  );
};

function getLineStartTime(line) {
  if (isTaskPending(line) && line.percent === 0) {
    return 0;
  }
  return line.start_tm;
}

function isPkgScript(taskData) {
  // mantis 621540
  return (taskData?.detail || '').indexOf('policy package') > 0;
}

const viewRunScript = (line, adomName) => {
  const index = line.name.lastIndexOf('(');
  const scriptName = line.name.substring(index + 1, line.name.lastIndexOf(')'));
  const deviceName = line.name.substring(0, index).trim();

  return openScriptHistoryModal(
    scriptName,
    deviceName,
    adomName,
    isPkgScript(line || {})
  );
};
