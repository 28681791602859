import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';
import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import { ReactContentViewContainer } from 'react_apps/ra_main/main_layout/ReactContentViewContainer';

export default {
  path: 'fortiguard',
  RenderErrorBoundary,
  handle: {
    appUniKey: 'fgd',
  },
  children: [
    getRedirectIndexRoute(),
    {
      path: 'license',
      handle: {
        appUniKey: 'fgd_license',
      },
      lazy: async () => {
        const { LicenseStatus } = await import(
          /* webpackChunkName: "mod-fgd-license" */ './license/license'
        );

        return {
          Component: LicenseStatus,
        };
      },
    },
    {
      handle: {
        appUniKey: 'fgd_pkg',
      },
      element: <ReactContentViewContainer menuId='fgd_pkg' />,
      children: [
        {
          path: 'pkgreceive',
          handle: {
            appUniKey: 'fgd_pkg_receive',
          },
          lazy: async () => {
            const { PMReceiveStatus } = await import(
              './PackageManagement/ReceiveStatus'
            );
            return {
              Component: PMReceiveStatus,
            };
          },
        },
        {
          path: 'pkgservice',
          handle: {
            appUniKey: 'fgd_pkg_service',
          },
          lazy: async () => {
            const { PMServiceStatus } = await import(
              './PackageManagement/ServiceStatus'
            );
            return {
              Component: PMServiceStatus,
            };
          },
        },
      ],
    },

    {
      handle: {
        appUniKey: 'fgd_query',
      },
      element: <ReactContentViewContainer menuId='fgd_query' />,
      children: [
        {
          path: 'queryreceive',
          handle: {
            appUniKey: 'fgd_query_receive',
          },
          lazy: async () => {
            const { SMReceiveStatus } = await import(
              './ServerManagement/ReceiveStatus'
            );
            return {
              Component: SMReceiveStatus,
            };
          },
        },
        {
          path: 'querystatus',
          handle: {
            appUniKey: 'fgd_query_status',
          },
          lazy: async () => {
            const { SMQueryStatus } = await import(
              './ServerManagement/QueryStatus'
            );
            return {
              Component: SMQueryStatus,
            };
          },
        },
      ],
    },

    {
      path: 'firmwares',
      handle: {
        appUniKey: 'fgd_firmware',
      },
      lazy: async () => {
        const { Firmware } = await import('./firmware/firmware');

        return {
          Component: Firmware,
        };
      },
    },

    {
      path: 'externalresource',
      handle: {
        appUniKey: 'fgd_external_resource',
      },
      lazy: async () => {
        const { ExternalResource } = await import(
          './ExternalResource/ExternalResource'
        );

        return {
          Component: ExternalResource,
        };
      },
    },

    {
      path: 'advanced',
      handle: {
        appUniKey: 'fgd_setting',
      },
      lazy: async () => {
        const { AdvancedSettings } = await import(
          './AdvancedSettings/AdvancedSettings'
        );

        return {
          Component: AdvancedSettings,
        };
      },
    },
  ],
};
