import React from 'react';
import { FieldArray, useField } from 'formik';

import { InputRow } from 'rc_layout';
import { isFunction } from 'lodash';
import { NwButton } from '@fafm/neowise-core';

export const FmkMultipleInput = ({
  name,
  validate = null,
  validateOnChange = false,
  items,
  children,
  'automation-id': propAutoId,
  automationId,
  onAdd = ({ arrayHelpers }) => arrayHelpers.push(''),
  addBtnLabel = null,
  labelIndent = false,
  hideAddBtn = false,
  customAddBtn,
}) => {
  // eslint-disable-next-line
  const [, { value }, { setValue, setTouched }] = useField({ name, validate });
  items = items || value || [];

  // can use a custom add btn
  // props provided: ({ onAdd, arrayHelpers, automationId, addBtnLabel, labelIndent })
  const AddBtn = customAddBtn || DefaultAddBtn;

  return (
    <FieldArray name={name} validateOnChange={validateOnChange}>
      {(arrayHelpers) => (
        <>
          {items.map((item, index) => {
            const dataBag = { arrayHelpers, item, index };
            if (isFunction(children)) {
              return children(dataBag);
            }

            return React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, dataBag);
              }
              return child;
            });
          })}
          {!hideAddBtn && (
            <AddBtn
              arrayHelpers={arrayHelpers}
              onAdd={onAdd}
              addBtnLabel={items.length === 0 ? addBtnLabel : null}
              labelIndent={labelIndent}
              automationId={automationId || propAutoId}
            />
          )}
        </>
      )}
    </FieldArray>
  );
};
FmkMultipleInput.displayName = 'FmkMultipleInput';

const DefaultAddBtn = ({
  onAdd,
  arrayHelpers,
  automationId,
  addBtnLabel,
  labelIndent,
}) => {
  return (
    <InputRow label={addBtnLabel} indent={labelIndent}>
      <NwButton
        class='tw-w-full'
        onClick={() => onAdd({ arrayHelpers })}
        automation-id={`${automationId}-add-btn`}
      >
        <nw-icon name='add'></nw-icon>
      </NwButton>
    </InputRow>
  );
};
