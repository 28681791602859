/**
 * Basic view data handle functions
 */
import { fiHttpGet, fiHttpPost } from 'fi-http';
import { logdevWidgets } from './consts';

const FETCH_TIMEOUT = 2000; // 2 SEC
const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

export const run = (url, data) => {
  return fiHttpPost(url, data);
};

export const fetch = async (url, fetchParams, config) => {
  if (!fetchParams.tid) {
    return Promise.resolve({});
  }
  const {
    abortCtrl = new AbortController(),
    fetchDelay = FETCH_TIMEOUT,
    onInterimDataChange,
    getFetchMeta,
  } = config;
  return fiHttpPost(url, fetchParams, {
    signal: abortCtrl.signal,
  }).then((resp) => {
    let meta;
    if (typeof getFetchMeta === 'function') {
      meta = getFetchMeta(resp);
    } else {
      meta = resp?.meta || {};
    }
    if (meta.status !== 0) {
      return resp;
    }
    if (meta.percentage === 100) {
      return resp;
    }
    if (typeof onInterimDataChange === 'function' && resp.data?.length) {
      onInterimDataChange(resp);
    }
    return delay(fetchDelay).then(() => fetch(url, fetchParams, config));
  });
};

export const cancel = async (url, cancelParams) => {
  if (!cancelParams.tid) {
    return Promise.resolve({});
  }
  return await fiHttpPost(url, cancelParams);
};

/**
 * @param {{ run: string; fetch: string; cancel: string; } | string} url
 * @param {object} data
 * @param {{
 *    fetchDelay?: number;
 *    getFetchParams?: Function;
 *    abortCtrl?: AbortController;
 *    onInterimDataChange?: Function;
 *    getFetchMeta?: Function;
 * }} config
 * @returns {Promise<unknown>}
 */
export const getAsyncApiData = async (url, data, config) => {
  const urls =
    typeof url === 'string'
      ? {
          run: url,
          fetch: url,
          cancel: url,
        }
      : url;
  const {
    fetchDelay = FETCH_TIMEOUT,
    getFetchParams,
    abortCtrl = new AbortController(),
  } = config;
  let _cancelParams;
  try {
    const runResp = await run(urls.run, data);
    const tid = runResp?.tid;
    if (tid) {
      let fetchParams;
      if (typeof getFetchParams === 'function') {
        fetchParams = getFetchParams(runResp, data);
      } else {
        fetchParams = {
          ...data,
          tid,
        };
      }
      _cancelParams = { ...fetchParams };
      await delay(fetchDelay);
      return await fetch(urls.fetch, fetchParams, config);
    } else {
      return runResp;
    }
  } catch (error) {
    if (!abortCtrl.signal.aborted) {
      console.error(error);
    }
  } finally {
    if (_cancelParams && urls.cancel) {
      cancel(urls.cancel, _cancelParams);
    }
  }
};

// used by fortiview, will be deprecated soon, please use getAsyncApiData instead
export const getDataByParams = async (
  params,
  abortCtrl = new AbortController()
) => {
  let _cancelParams;
  try {
    let runParams = _getRunParams(params);
    let res = await _run(runParams);
    if (runParams.apiName && res && res.tid) {
      let fetchParams = {
        api: runParams.apiName,
        count: runParams.count,
        ddfltr: runParams.ddfltr,
        search: runParams.search,
        requestId: res.tid,
        sessionId: res['session-id'], // for fortiview ha loading balance
      };
      _cancelParams = { ...fetchParams };
      await delay(FETCH_TIMEOUT);
      let fetchRes = await _fetch({
        ...fetchParams,
        onInterimDataChange: params.onInterimDataChange,
        signal: abortCtrl.signal,
      });
      return fetchRes;
    } else if (res?.code === -32015) {
      // just display "no data" for "-32015, empty device list" error
      // this happens when the widget only supports FortiXXX devices, but only other device types are selected
      return {
        data: [],
        meta: {
          status: 0,
          percentage: 100,
        },
      };
    } else {
      console.warn('getDataByParams, not calling fetch.');
    }
    return res;
  } catch (error) {
    if (_cancelParams) {
      _cancel(_cancelParams);
    }
  }
};

const _cancel = ({ api, requestId, sessionId }) => {
  if (requestId) {
    return fiHttpGet('/p/fortiview/all/cancel/ajax/', {
      params: {
        id: requestId,
        view: api,
        sessionid: sessionId,
      },
    }).then((resp) => resp);
  }
  return;
};

const _getRunParams = (params) => {
  if (params.api && params.serverTime && params.device) {
    return {
      adom: params.adom,
      apiName: params.api,
      startTime: params.serverTime.startTime,
      endTime: params.serverTime.endTime,
      count: params.count || 50,
      device: params.device.value || params.device,
      osType: params.osType,
      logtype: params['logtype'],
      csfname: params.device.isCsf ? params.device.name : '',
      ddfltr: params.ddfltr || {},
      search: params.search || {},
      sort_fields:
        params.sort_fields && params.sort_fields.length
          ? params.sort_fields.reduce(
              (acc, obj) => ({ ...acc, [obj.field]: obj.order }),
              {}
            )
          : {},
      // custom Widget fortiview only
      grpby_fields: params['grpby_fields'] || '',
      agg_fields: params['agg_fields'] || '',
      proxiedServer: params['proxiedServer'],
      proxiedAdom: params['proxiedAdom'],
      isLoggingDev: logdevWidgets.includes(params.widgetName),
    };
  }
  return params;
};

const _run = (params) => {
  return fiHttpPost('/p/fortiview/all/run/ajax/', params);
};

const _fetch = ({
  api,
  count,
  requestId,
  sessionId,
  ddfltr,
  search,
  onInterimDataChange,
  signal,
}) => {
  if (!requestId) {
    return;
  }

  return fiHttpGet('/p/fortiview/all/fetch/ajax/', {
    params: {
      id: requestId,
      view: api,
      sessionid: sessionId,
      count,
      ddfltr,
      search,
    },
    signal,
  }).then((resp) => {
    if (resp?.meta?.status === 0) {
      if (resp.meta.percentage === 100) {
        // success
        _cancel({ api, requestId, sessionId });
        return resp;
      } else {
        if (typeof onInterimDataChange === 'function' && resp.data?.length) {
          onInterimDataChange(resp);
        }
        // continue
        return delay(FETCH_TIMEOUT).then(() =>
          _fetch({
            api,
            count,
            requestId,
            sessionId,
            ddfltr,
            search,
            onInterimDataChange,
            signal,
          })
        );
      }
    } else {
      // error
      _cancel({ api, requestId, sessionId });
      return resp;
    }
  });
};
