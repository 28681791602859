import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { setWith, unset } from 'lodash';
import Moment from 'moment';
import {
  isFmg,
  getSysConfig,
  hasFazFeature,
  getSysTimeDiff,
  getSysConfigRaw,
} from 'fistore/session/sysConfig/selectors';
import { fiStrToDate, getCalendarDate } from 'kit-time';
import { listenerMiddleware } from 'fistore/middlewares';
import { fetchSysConfig } from 'fistore/session/sysConfig/slice';
import { notifyNotifyAction } from 'fistore/utils/action';
import { fetchSessionAdom } from 'fistore/session/adom/slice';
import {
  checkIfShowSaseNotification,
  getShouldShowSaseNotification,
} from 'fistore/devices/sase/slice';
import { getSessionAdomData } from 'fistore/session/adom/selectors';

// actions

/**
 * @property {string} id The id of the notification. Type must be string,
 *                    otherwise it won't match the keys in navbar.
 * @property {Object} params Additional data passed to the notification
 *                           handler.
 *  @
 */
export const setNotification = createAction(
  'notification/SET',
  /**
   * Set or add a new notification if the id does not exist.
   * @param {NotifType} notif {id, params, icon, message}.
   * @param {number} adomOid ADOM oid, if not set, it will be global.
   */
  ({ id, params, adomOid = -1, severity }) => {
    return {
      payload: { id, adomOid, params, severity },
    };
  }
);

/**
 * Remove a notification by id.
 *
 * @param id {integer} The notification id to remove.
 * @param adomOid {integer} ADOM oid. If not set, it will be global.
 *
 */
export const unsetNotification = createAction(
  'notification/UNSET',
  ({ id, adomOid = -1 }) => {
    return {
      payload: { id, adomOid },
    };
  }
);

const _slice = createSlice({
  name: 'notification',
  initialState: {},
  extraReducers: (builder) => {
    /**
     * payload.id must be string and not integer like string ex. '1', otherwise the
     * order will be wrong.
     */
    builder
      .addCase(setNotification, (state, { payload }) => {
        setWith(state, [payload.adomOid, payload.id], payload, Object);
      })
      .addCase(unsetNotification, (state, { payload }) => {
        unset(state, [payload.adomOid, payload.id]);
      });
  },
});

export default _slice.reducer;

// listeners
// for notification
// no need to add adomOid since these notifications are global
listenerMiddleware.startListening({
  actionCreator: fetchSysConfig.fulfilled,
  effect: (action, { dispatch, getState }) => {
    const state = getState();
    const cfg = getSysConfig(state);

    const notifyExpiringLicense = ({ abbrev, type }) => {
      let _lic = (cfg?.contracts || []).find((x) => x.type === abbrev);
      if (!_lic) return;
      // license time format yyyymmdd
      let _expireDay = fiStrToDate(_lic.time);

      const _expireLeftDay = getSysTimeDiff(_expireDay.getTime())(state);
      if (_expireLeftDay <= 30) {
        dispatch(
          setNotification({
            id: type,
            severity: MACROS.NOTICE.NOTICE_LEVEL_ERROR,
            params: {
              days: _expireLeftDay,
              expiringDate: getCalendarDate(_expireDay, 'yyyy-MM-dd'),
            },
          })
        );
      } else {
        dispatch(
          unsetNotification({
            id: type,
          })
        );
      }
    };

    // ADOM license status
    if (cfg.is_vms_lic) {
      notifyExpiringLicense({
        abbrev: 'ADOM',
        type: MACROS.NOTICE.NOTICE_TYPE_ADOM_LICENSE,
      });
    }

    // fortiai entitlement
    notifyExpiringLicense({
      abbrev: 'AISN',
      type: MACROS.NOTICE.NOTICE_TYPE_FORTIAI,
    });

    // https cert expires
    if (cfg.https_cert_expire) {
      const _https_expire_days = Math.floor(
        Moment.duration(
          Moment(cfg.https_cert_expire, 'YYYY-MM-DD HH:mm:ss').diff(Moment())
        ).asDays()
      );
      if (_https_expire_days <= MACROS.USER.SYS.EXPIRING_WARNING_DAYS) {
        dispatch(
          setNotification({
            id: MACROS.NOTICE.NOTICE_TYPE_HTTPS_CERT_EXPIRING,
            severity: MACROS.NOTICE.NOTICE_LEVEL_WARNING,
            params: {
              days: _https_expire_days,
            },
          })
        );
      } else {
        dispatch(
          unsetNotification({
            id: MACROS.NOTICE.NOTICE_TYPE_HTTPS_CERT_EXPIRING,
          })
        );
      }
    }

    // vm cpu/mem size
    if (
      cfg.vmsz_vcpu &&
      (cfg.vmsz_vcpu < MACROS.SYS.VM_MIN_VCPU_NUM || isFmg()
        ? cfg.vmsz_mem < MACROS.SYS.VM_MIN_MEMORY_BYTES_FMG
        : cfg.vmsz_mem < MACROS.SYS.VM_MIN_MEMORY_BYTES_FAZ)
    ) {
      dispatch(
        setNotification({
          id: MACROS.NOTICE.NOTICE_TYPE_VM_SIZING,
          severity: MACROS.NOTICE.NOTICE_LEVEL_ERROR,
        })
      );
    } else {
      dispatch(
        unsetNotification({
          id: MACROS.NOTICE.NOTICE_TYPE_VM_SIZING,
        })
      );
    }

    // faz vm storage size
    const _hasFazFeature = hasFazFeature(state);
    if (
      _hasFazFeature &&
      cfg.storage_warning &&
      cfg.vmsz_storage !== MACROS.SYS.UINT32_MAX &&
      cfg.vmsz_disk < cfg.vmsz_storage
    ) {
      dispatch(
        setNotification({
          id: MACROS.NOTICE.NOTICE_TYPE_VM_STORAGE_DISK,
          severity: MACROS.NOTICE.NOTICE_LEVEL_ERROR,
          params: {
            disk: cfg.vmsz_disk,
            storage: cfg.vmsz_storage,
          },
        })
      );
    } else {
      dispatch(
        unsetNotification({
          id: MACROS.NOTICE.NOTICE_TYPE_VM_STORAGE_DISK,
        })
      );
    }
  },
});

const isNotifSet = (collection) => {
  return collection === MACROS.NOTICE.NAVBAR_NOTICE_COLLECTION_NAME_SET;
};

const isNotifUnset = (collection) => {
  return collection === MACROS.NOTICE.NAVBAR_NOTICE_COLLECTION_NAME_UNSET;
};

listenerMiddleware.startListening({
  // notifications that should be read from polling message from websocket
  actionCreator: notifyNotifyAction,
  effect: ({ payload }, { dispatch }) => {
    const { collection } = payload;
    if (!isNotifSet(collection) && !isNotifUnset(collection)) return;

    const {
      fields: { type: id, adomOid, data: params, severity },
    } = payload;

    if (isNotifSet(payload.collection)) {
      dispatch(setNotification({ id, params, adomOid, severity }));
    }
    if (isNotifUnset(payload.collection)) {
      dispatch(unsetNotification({ id, adomOid }));
    }
  },
});

// outbreak notification
listenerMiddleware.startListening({
  actionCreator: fetchSessionAdom.fulfilled,
  effect: async (action, { dispatch, condition, cancelActiveListeners }) => {
    cancelActiveListeners();
    if (await condition((_, currState) => hasFazFeature(currState))) {
      const resp = await fetch('/p/fgd/outbreak_notifications/get/');
      if (resp.ok) {
        let obj = await resp.json();
        if (obj?.unread_titles?.length) {
          dispatch(
            setNotification({
              id: MACROS.NOTICE.NOTICE_TYPE_UNREAD_OUTBREAK_ALERT,
              params: { count: obj.unread_titles.length },
            })
          );
        }
      }
    }
  },
});

// FortiSASE notification
listenerMiddleware.startListening({
  matcher: isAnyOf(fetchSessionAdom.fulfilled, checkIfShowSaseNotification),
  effect: async (action, { dispatch, getState, condition }) => {
    const unset = () => {
      dispatch(
        unsetNotification({
          id: MACROS.NOTICE.NOTICE_TYPE_FORTISASE_LICENSE_DETECTED,
        })
      );
    };

    if (!isFmg()) return unset();

    await condition(
      (_, currentState) => !getSysConfigRaw(currentState).loading
    );

    const state = getState();
    const currentAdom = getSessionAdomData(state);

    // see comment in getShouldShowSaseNotification for logic to show sase notification
    const showNotification = await dispatch(
      getShouldShowSaseNotification()
    ).unwrap();
    if (!showNotification) return unset();

    dispatch(
      setNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_FORTISASE_LICENSE_DETECTED,
        params: { adom: currentAdom },
      })
    );
  },
});
