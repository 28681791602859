import { fiFmgHttp, getResponseData } from 'fi-http';
import { fiAdom } from 'fi-session';
import { fiStore, fiDevicesAction } from 'fistore';
import Momenttz from 'moment-timezone';
import { getZonePath } from 'fi-datetime';
import { castArray, forEach, get, isString, transform } from 'lodash';

let url = '/gui/adom/dvm/device/firmware';
const UPGRADE_ACTION_URL = 'um/image/upgrade/ext';

let getScheduleInfo = function (id, isGroup) {
  let reqObj = {
    url: url,
    method: 'get',
    params: {
      isGroup: isGroup ? 1 : 0,
      id: parseInt(id),
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

function getHistory({ devices, groups }) {
  const toDevOrDevGrpObjs = (vals) => {
    return transform(castArray(vals), (result, val) => {
      if (!val || !val.name) return;
      if (val) {
        const obj = isString(val) ? { name: val } : val;
        result.push(obj);
      }
    });
  };
  const data = {
    adom: fiAdom.current().name,
    groups: toDevOrDevGrpObjs(groups),
    devices: toDevOrDevGrpObjs(devices),
  };
  const reqObj = {
    method: 'exec',
    params: [
      {
        data,
        url: 'um/image/upgrade/report',
      },
    ],
  };
  return fiFmgHttp.query(reqObj).then((resp) => {
    const reports = get(getResponseData(resp), 'report', []);
    // device upgrade histories
    return reports;
  });
}

let getUpgradePreview = (params) => {
  params.flags = MACROS.DVM.UM_IMAGE_UPGRADE_FLAG_PREVIEW;
  let req = {
    method: 'exec',
    params: [
      {
        data: {
          adom: fiAdom.current().name,
          //create_task: 'enable',
          ...params, // flags, device, image, schedule_time
        },
        url: 'um/image/upgrade',
      },
    ],
  };
  return fiFmgHttp.query(req).then((resp) => resp[0].data);
};

let upgradeFirmware = function (params) {
  let reqObj = {
    url: url,
    method: 'scheduleGrpUpgrade',
    params: params,
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

async function upgradeFirmwareExt(adom, groups, devices, flags, schedule_time) {
  let data = {
    create_task: 'enable',
    adom: adom.name || adom,
    flags: flags || 0,
  };
  // we need to use the devices to schedule the upgrade instead of groups
  // backend cannot delete group upgrade schedule
  if (groups && !schedule_time) {
    data.groups = Array.isArray(groups) ? groups : [groups];
  } else {
    if (devices) {
      data.devices = Array.isArray(devices) ? devices : [devices];
    }
    if (schedule_time) data.schedule_time = schedule_time;
  }
  const resp = await fiFmgHttp.query({
    method: 'exec',
    params: [
      {
        data: data,
        url: UPGRADE_ACTION_URL,
      },
    ],
  });
  const res = resp[0] ? resp[0].data : [];
  if (!Array.isArray(res) && res.taskid === 0 && schedule_time) {
    const newSchedules = await fiStore.dispatch(
      fiDevicesAction.fetchFirmwareManualUpgradeAction({
        devices,
      })
    );
    return {
      ...res,
      newSchedules,
    };
  } else {
    return res;
  }
}

let cancelFirmwareUpgrade = function (id, key, isGroup) {
  let reqObj = {
    url: url,
    method: 'delSchedule',
    params: {
      id: id,
      key: key,
      isGroup: isGroup ? 1 : 0,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

let parseBestUpgradePath = function (arrayData) {
  let objmap = {};
  if (arrayData && Array.isArray(arrayData)) {
    forEach(arrayData, function (current) {
      let attrs = current.split('|');
      let obj = {};
      attrs.map(function (attr) {
        let s = attr.split('=');
        let key = s[0];
        let value = s[1];
        switch (key) {
          case 'FGTPlatform':
            obj.platform = value;
            break;
          case 'FGTCurrVersion':
            obj.curr_ver = value;
            break;
          case 'FGTCurrBuildNum':
            obj.crr_build = parseInt(value);
            break;
          case 'FGTUpgVersion':
            obj.upg_ver = value;
            break;
          case 'FGTUpgBuildNum':
            obj.upg_build = parseInt(value);
            break;
          case 'BaselineVersion':
            obj.baseline_ver = value;
            break;
        }
      });
      if (!objmap[obj.platform]) {
        objmap[obj.platform] = {};
        objmap[obj.platform][obj.curr_ver] = {};
        objmap[obj.platform][obj.curr_ver][obj.crr_build] = [
          {
            upgradeVer: obj.upg_ver,
            upgradeBuild: obj.upg_build,
            baseline: obj.baseline_ver,
          },
        ];
      } else if (!objmap[obj.platform][obj.curr_ver]) {
        objmap[obj.platform][obj.curr_ver] = {};
        objmap[obj.platform][obj.curr_ver][obj.crr_build] = [
          {
            upgradeVer: obj.upg_ver,
            upgradeBuild: obj.upg_build,
            baseline: obj.baseline_ver,
          },
        ];
      } else if (!objmap[obj.platform][obj.curr_ver][obj.crr_build]) {
        objmap[obj.platform][obj.curr_ver][obj.crr_build] = [
          {
            upgradeVer: obj.upg_ver,
            upgradeBuild: obj.upg_build,
            baseline: obj.baseline_ver,
          },
        ];
      } else {
        objmap[obj.platform][obj.curr_ver][obj.crr_build].push({
          upgradeVer: obj.upg_ver,
          upgradeBuild: obj.upg_build,
          baseline: obj.baseline_ver,
        });
      }
    });
  }
  return objmap;
};

let getUpgradeFirmwarePath = function () {
  let reqObj = {
    url: url,
    method: 'getUpdatePath',
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    let content = resp[0] ? resp[0].data : [];
    return parseBestUpgradePath(content);
  });
};

let getDevPlatformAbbr = function (oids) {
  let reqObj = {
    url: url,
    method: 'getDevPlatformAbbr',
    params: {
      oids: oids,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

let getAvaliableFirmwares = function (parameter) {
  let reqObj = {
    url: '/gui/adom/dvm/firmware/management',
    method: parameter.method,
    params: parameter.params,
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

function getInitialParams() {
  let now = Momenttz().tz(getZonePath());
  return {
    date: now,
    retry: 0,
    rtyTimes: MACROS.DVM.DEFAULT_RETRY_INTERVAL,
    rtyIntvl: MACROS.DVM.DEFAULT_RETRY_TIMES,
    altck: MACROS.PM2CAT.PM2_OPT_DISABLE,
    skipck: MACROS.PM2CAT.PM2_OPT_DISABLE,
    fgdimageck: MACROS.PM2CAT.PM2_OPT_DISABLE,
    skipClusterWhenSecondaryDown: MACROS.PM2CAT.PM2_OPT_ENABLE,
    skip_disk_check: MACROS.PM2CAT.PM2_OPT_ENABLE,
    skip_auto_scan_disk: MACROS.PM2CAT.PM2_OPT_ENABLE,
  };
}
function prepUpgradeParams(params) {
  let retObj = {
    action: 'fmwUpgrade',
    availVers: null,
    schck: params.schck,
    retry: params.retry,
    rtyTimes: params.rtyTimes,
    rtyIntvl: params.rtyIntvl,
    altck: params.altck,
    skipck: params.skipck,
    fgdimageck: params.fgdimageck,
    skipClusterWhenSecondaryDown: params.skipClusterWhenSecondaryDown,
  };

  if (params.isGroup) {
    let { firmware, version, build, build_str, officialImage, id, isGroup } =
      params;
    retObj = {
      ...retObj,
      firmware,
      version,
      build,
      build_str,
      officialImage,
      id,
      isGroup,
    };
  } else {
    retObj.isGroup = 0;
    // params.updVers "0#05006000-01630-5.6.6-1809141742"
    let verUpg = params.updVers.split('-');
    retObj.availVers =
      verUpg.length > 3
        ? [verUpg[0], verUpg[1], verUpg[2]].join('-')
        : params.updVers;
  }

  return retObj;
}

function prepUpgradeExtParams(params, verOpt) {
  let groups, devices;
  if (params.isGroup) {
    groups = [
      {
        name: params.groupName,
        image: verOpt.version,
      },
    ];
    devices = params.groupMembers.map((dev) => {
      return {
        name: dev.devname,
        image: verOpt.version,
      };
    });
  } else {
    devices = castArray(params.deviceNames).map((devSN) => {
      let name = devSN;
      if (verOpt.type === 'GA' && !verOpt.image_path) {
        return {
          name,
          image: params.updVers,
        };
      } else {
        return {
          name,
          image: verOpt.version,
        };
      }
    });
  }
  let upgrade_flags = params.altck
    ? MACROS.DVM.UM_IMAGE_UPGRADE_FLAG_BOOL_ALT_PARTITION
    : 0;
  if (params.fgdimageck) {
    upgrade_flags |= MACROS.DVM.UM_IMAGE_UPGRADE_FLAG_FORTIGUARD_IMG;
  }

  if (params.skipck) {
    upgrade_flags |= MACROS.DVM.UM_IMAGE_UPGRADE_FLAG_SKIP_MULTI_STEPS;
  }

  if (params.skipClusterWhenSecondaryDown) {
    upgrade_flags |= MACROS.DVM.UM_IMAGE_UPGRADE_FLAG_SKIP_WHEN_SECONDARY_DOWN;
  }

  if (params.skip_disk_check) {
    upgrade_flags |= MACROS.DVM.UM_IMAGE_UPGRADE_FLAG_SKIP_CHECK_DISK;
  }

  if (params.skip_auto_scan_disk) {
    upgrade_flags |= MACROS.DVM.UM_IMAGE_UPGRADE_FLAG_SKIP_AUTO_SCAN_DISK;
  }

  let schedule_time = null;
  if (params.schck && params.date instanceof Momenttz) {
    schedule_time = params.date.unix();
  }
  return {
    groups: groups,
    devices: devices,
    flags: upgrade_flags,
    schedule_time: schedule_time,
  };
}

function parseBestUpgradePath2(data, { platform, version, build }) {
  const objmap = {};
  data.forEach((current) => {
    const attrs = current.split('|'),
      obj = {};
    attrs.forEach((attr) => {
      const s = attr.split('=');
      const key = s[0],
        value = s[1];
      obj[key] = value;
    });
    if (!objmap[obj['FGTVersion']]) objmap[obj['FGTVersion']] = {};
    const FGTBuild = parseInt(obj['FGTBuildNum']);
    if (!objmap[obj['FGTVersion']][FGTBuild])
      objmap[obj['FGTVersion']][FGTBuild] = {};
    objmap[obj['FGTVersion']][FGTBuild][obj[platform]] = {
      version: obj[version],
      build: parseInt(obj[build]),
    };
  });
  return objmap;
}

function parseUpgradeFirmware(data) {
  const sortFn = (a, b) => {
    if (a.version > b.version) return -1;
    else if (a.version < b.version) return 1;
    else return 0;
  };
  let _officials = [];
  let _specials = [];
  let _result = [];
  data.forEach((ele) => {
    if (ele.type === 'GA') {
      if (ele.image_path) {
        let tmpEle = Object.assign({}, ele);
        let bIndex = tmpEle.version.indexOf('b');
        tmpEle.version =
          tmpEle.version.substring(0, bIndex + 1) +
          '0' +
          tmpEle.version.substring(bIndex + 1);
        tmpEle.is_special = true;
        _specials.push(tmpEle);
      }
      _officials.push(ele);
    } else {
      ele.is_special = true;
      _specials.push(ele);
    }
  });
  if (_specials.length) {
    _result.push({
      seq: -1 * (_result.length + 1),
      isGroup: true,
      txt: `${gettext('Special Images')} (${_specials.length})`,
      children: _specials.sort(sortFn),
    });
  }
  _result.push({
    seq: -1 * (_result.length + 1),
    isGroup: true,
    txt: `${gettext('Official Images')} (${_officials.length})`,
    children: _officials.sort(sortFn),
  });
  return _result;
}

export const fiDvmFirmwareUpgradeService = {
  getScheduleInfo: getScheduleInfo,
  getHistory: getHistory,
  upgradeFirmware: upgradeFirmware,
  upgradeFirmwareExt: upgradeFirmwareExt,
  getUpgradePreview: getUpgradePreview,
  cancelFirmwareUpgrade: cancelFirmwareUpgrade,
  getUpgradeFirmwarePath: getUpgradeFirmwarePath,
  getAvaliableFirmwares: getAvaliableFirmwares,
  getDevPlatformAbbr: getDevPlatformAbbr,
  getInitialParams: getInitialParams,
  prepUpgradeParams: prepUpgradeParams,
  prepUpgradeExtParams: prepUpgradeExtParams,
  parseBestUpgradePath2,
  parseUpgradeFirmware,
};
