import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { syntaxRequest } from './api';
import { getSessionAdomData } from 'fistore/session/adom/selectors';
import { listenerMiddleware } from 'fistore/middlewares';
import { fetchSessionAdom } from 'fistore/session/adom/slice';
import { isFmg } from 'fistore/session/sysConfig/selectors';
import { getSyntax } from './selectors';
import { refreshAppTree } from 'fistore/routing/slice';

export const fetchAdomSyntax = createAsyncThunk(
  'adomSyntax/fetchSyntax',
  async (payload, { getState, fulfillWithValue, dispatch }) => {
    const cached = getSyntax(getState());
    if (cached) {
      return fulfillWithValue(cached);
    }
    return await dispatch(_fetchAdomSyntax()).unwrap();
  }
);

const _fetchAdomSyntax = createAsyncThunk(
  'adomSyntax/_fetchSyntax',
  async (payload, { getState, dispatch }) => {
    const adom = getSessionAdomData(getState());
    const syntax = await syntaxRequest(adom);
    dispatch(slice.actions.setSyntax(syntax));
    dispatch(refreshAppTree());
    return syntax;
  }
);

const initialState = {
  loading: false,
  syntax: null,
};

const slice = createSlice({
  name: 'adomSyntax',
  initialState,
  reducers: {
    setSyntax(state, { payload }) {
      state.syntax = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(_fetchAdomSyntax.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(_fetchAdomSyntax.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(_fetchAdomSyntax.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default slice.reducer;

listenerMiddleware.startListening({
  actionCreator: fetchSessionAdom.fulfilled,
  effect: async (action, { dispatch, cancelActiveListeners, condition }) => {
    cancelActiveListeners();
    if (await condition(() => isFmg())) {
      dispatch(_fetchAdomSyntax());
    }
  },
});
