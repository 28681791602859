import { formatSeconds } from 'kit-time';
import { fiStoreLogview } from 'fistore';
const { isUpgrading: isPostgresUpgrading } = fiStoreLogview;
let phaseText = {};
const DOING = MACROS.SYS.PG_UPG_STAGE_MAX + 1,
  DONE = 999;
phaseText[DOING] = {
  brief: gettext('Postgres Upgrade in progress...'),
  detail: gettext('Postgres Upgrade in progress..., estimated remaining time:'),
  more: gettext("Please don't shutdown system!"),
};
phaseText[DONE] = {
  detail: gettext('Postgres Upgrade was completed.'),
};

export function parser(progress) {
  let txt,
    upgrading = false;
  if (isPostgresUpgrading(progress)) {
    txt = {
      ...phaseText[DOING],
      remain: formatSeconds(progress.remain).join(' '),
    };
    upgrading = true;
  } else {
    txt = { ...phaseText[DONE], remain: '', more: null };
    upgrading = false;
  }

  return {
    ...progress,
    hint: gettext('Postgres Upgrade'),
    curPhaseText: txt,
    upgrading,
  };
}
