import { fiStore } from 'fistore';
import { getAllApps, getAppTreeMap } from 'fistore/routing/selectors';

export const UserDashUniKey = 'fortiview_custom_view';
export const genUserDashPath = (id, isFtv = false) =>
  `/fortiview/${isFtv ? 'ftv' : 'noc'}/custom_view/${id}`;

export const getCurrentUserMenus = () => {
  let state = fiStore.getState();
  const appTreeMap = getAppTreeMap(state);
  let userMenus = appTreeMap[UserDashUniKey];
  const allApps = getAllApps(state);
  return userMenus.map((x) => allApps[x[0]]);
};
