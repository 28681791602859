import { useCallback, useMemo } from 'react';
import {
  NwProHeader,
  NwProBody,
  NwProSection,
  NwProFooter,
  CancelBtn,
  NwProInputRow,
} from 'rc_layout';
import { ProTable } from '@fafm/neowise-pro';
import { isArray } from 'lodash';

const rowKey = 'seq';
const getAutoId = (name) => 'dvm_ip_conflict:' + name;

const STR_IP_CONFLICT = 'ip-conflict';

export const DvmIpConflict = ({ $opener, arrDevInfo }) => {
  const columns = useColumns();

  const dataRequest = useCallback(() => {
    return Promise.resolve(parseData(arrDevInfo, columns));
  }, []);

  const getRowHeight = useCallback((row) => {
    let val = row[STR_IP_CONFLICT] || [];
    return val.length
      ? val.length * MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_24 + 10
      : MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_32;
  }, []);

  const close = () => {
    $opener.reject();
  };

  return (
    <>
      <NwProHeader>{gettext('Interface IP Conflicts')}</NwProHeader>
      <NwProBody className='tw-p-4 np-body tw-grow tw-flex tw-flex-col'>
        <NwProSection>
          <NwProInputRow label=''>
            {gettext(
              'The following devices has interface IP conflicts. To resolve them, you can either change them on FortiManager side then install to device, or review and update directly on FortiGate.'
            )}
          </NwProInputRow>
        </NwProSection>
        <div className='tw-px-4 tw-grow'>
          <ProTable.TableView
            tableId='dvm_ip_conflict_table'
            rowKey={rowKey}
            request={dataRequest}
            columns={columns}
            rowHeight={getRowHeight}
          />
        </div>
      </NwProBody>
      <NwProFooter>
        <CancelBtn onClick={close} automation-id={getAutoId('btn-cancel')}>
          {gettext('Close')}
        </CancelBtn>
      </NwProFooter>
    </>
  );
};

const useColumns = () => {
  return useMemo(() => {
    return [
      {
        key: 'seq',
        dataKey: 'seq',
        title: '#',
        width: parseInt(MACROS.USER.SEQ_COLUMN_WIDTH),
        hidden: true,
      },
      {
        key: 'name',
        dataKey: 'name',
        title: gettext('Device'),
        initialSort: 1,
      },
      {
        key: STR_IP_CONFLICT,
        dataKey: STR_IP_CONFLICT,
        title: gettext('Details'),
        cellRenderer: ({ cellData }, highlighter) => {
          return (
            <div>
              {(cellData || []).map((c) => (
                <div key={c}>{highlighter(c)}</div>
              ))}
            </div>
          );
        },
      },
    ];
  }, []);
};

function parseData(data, cols) {
  var parsedData = [];
  (data || []).forEach(function (d, idx) {
    var rec = {};
    rec.seq = idx + 1;
    rec.oid = d.oid;
    rec.conn = d.conn_status;
    var vdomArr, tmpVdom;
    cols
      .filter((col) => col.key !== rowKey)
      .forEach(function (col) {
        var arrConflictStr = [];
        if (col.key === STR_IP_CONFLICT) {
          vdomArr = d.vdom;
          for (var vi = 0; vi < vdomArr.length; vi++) {
            tmpVdom = vdomArr[vi];
            if (
              tmpVdom.flags === STR_IP_CONFLICT ||
              (isArray(tmpVdom.flags) &&
                tmpVdom.flags.indexOf(STR_IP_CONFLICT) >= 0)
            ) {
              try {
                var arrConflictPair = JSON.parse(tmpVdom.status)[
                  STR_IP_CONFLICT
                ];
                var staticIp = gettext('Static IP');
                var dhcpIp = 'DHCP';
                var str1, str2, str3;
                if (isArray(arrConflictPair)) {
                  for (var ci = 0; ci < arrConflictPair.length; ci++) {
                    if (isArray(arrConflictPair[ci])) {
                      for (var pi = 0; pi < arrConflictPair[ci].length; pi++) {
                        str1 =
                          arrConflictPair[ci][pi].mode === 'dhcp'
                            ? dhcpIp
                            : staticIp;
                        str2 = arrConflictPair[ci][pi].port;
                        str3 = arrConflictPair[ci][pi].ip;
                        arrConflictStr.push(
                          '%s: %s (%s)'.printf([str2, str3, str1])
                        );
                      }
                    }
                  }
                }
              } catch (e) {
                //
              }
            }
          }
          rec[col.key] = arrConflictStr;
        } else {
          rec[col.key] = d[col.key];
        }
      });

    //filter out the modal devices
    if (rec[STR_IP_CONFLICT] && rec[STR_IP_CONFLICT].length > 0) {
      parsedData.push(rec);
    }
  });
  return parsedData;
}
