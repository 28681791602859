import { getAuthState, getTimeLeft } from 'fistore/auth/selectors';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProToolkit, ProForm } from '@fafm/neowise-pro';
import { NwButton, NwSpinner } from '@fafm/neowise-core';
import { resetTimer } from 'fistore/auth/slice';
import { useAuth } from 'react_apps/ra-auth/useAuth';
import { printfd } from 'kit/kit-string/printfd';

const { Header, Body, Footer } = ProForm;

function formatTime(seconds) {
  let sec = seconds % 60;
  let min = Math.floor(seconds / 60);
  min = min && min < 10 ? '0' + min : min;
  sec = sec < 10 ? '0' + sec : sec;
  return min + ':' + sec;
}

function TimeoutWarnModal({ $opener }) {
  const timeLeft = useSelector(getTimeLeft);
  const [countdown, setCountdown] = useState(timeLeft);
  const dispatch = useDispatch();
  const auth = useAuth();

  const extend = useCallback(() => {
    $opener.resolve(dispatch(resetTimer()));
  }, []);

  const logout = useCallback(() => {
    $opener.resolve(auth.logout());
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      return;
    }
    const id = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearTimeout(id);
  }, [countdown]);

  return (
    <>
      <Header>{gettext('Session Timeout')}</Header>
      <Body>
        <div className='tw-text-left'>
          {gettext(
            'The session is about to expire. If you need more time, please click "Extend Session". Otherwise you will be automatically logged out.'
          )}
        </div>
        <div>
          <h1 className='tw-text-center'>{formatTime(countdown)}</h1>
        </div>
      </Body>
      <Footer>
        <NwButton type='primary' onClick={extend}>
          {gettext('Extend Session')}
        </NwButton>
        <NwButton onClick={logout}>{gettext('Logout')}</NwButton>
      </Footer>
    </>
  );
}

function LostConnModal({ $opener }) {
  const authState = useSelector(getAuthState);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setTimer(timer + 1);
    }, 1000);
    return () => clearInterval(id);
  }, [timer]);

  useEffect(() => {
    if (authState === 'Polling') {
      $opener.resolve();
    }
  }, [authState]);

  return (
    <>
      <Header>
        {printfd(gettext('Lost Connection to %(prodName)s'), {
          prodName:
            MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FAZ
              ? 'FortiAnalyzer'
              : 'FortiManager',
        })}
      </Header>
      <Body>
        <div className='tw-text-left'>
          <NwSpinner /> {gettext('Attempting to reconnect...')}
        </div>
        <div>
          <h1 className='tw-text-center'>{formatTime(timer)}</h1>
        </div>
      </Body>
    </>
  );
}

export function useAuthStates() {
  const authState = useSelector(getAuthState);

  useEffect(() => {
    switch (authState) {
      case 'Warn': {
        ProToolkit.openModal(<TimeoutWarnModal />, {
          maximizable: false,
          closable: false,
          size: 'sm',
          height: '20rem',
        });
        break;
      }
      case 'LostConn': {
        ProToolkit.openModal(<LostConnModal />, {
          maximizable: false,
          closable: false,
          size: 'sm',
          height: '20rem',
        });
      }
    }
  }, [authState]);
}
