import { curry } from '@fafm/fp';
import { createAction } from '@reduxjs/toolkit';
import { assign } from 'lodash';
import { SocketActions } from 'fi-websocket';
const {
  NOTIFY_CHANGED_ACTION,
  NOTIFY_REMOVED_ACTION,
  NOTIFY_ADDED_ACTION,
  NOTIFY_NOTIFY_ACTION,
} = SocketActions;

const addTypeToFn = (type, fn) => assign(fn, { type: type });

// Returns a action creator that takes payload and config object.
export function makeConfigAction(type) {
  return addTypeToFn(type, (payload, config) => ({ type, payload, config }));
}

// Regurns an action creator that takes payload, success function and reject
// function.
export function makePromiseAction(type) {
  return addTypeToFn(type, (payload, success, reject) => ({
    type,
    payload,
    success,
    reject,
  }));
}

/**
 * Bind an action to promise in redux-saga. The action creator is from
 * makePromiseAction.
 * For example:
 *
 * bindActionToPromise(fiStore.dispatch, setUserConfig, {...}).then();
 */
export const bindActionToPromise = curry(
  (dispatch, actionCreator, payload = null) => {
    return new Promise((resolve, reject) =>
      dispatch(actionCreator(payload, resolve, reject))
    );
  }
);

/** Returns a compound action creator that also embedded its type and its config action.
 */
export const makeConfigActionGroup = curry((prefix, type) => {
  const prefixType = `${prefix}/${type}`;
  const configType = prefixType + '/CONFIG';
  return Object.assign(createAction(prefixType), {
    $configType: configType,
    $configAction: makeConfigAction(configType),
  });
});

export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    let action = {
      type,
    };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

const _createAction = (opt) => (name) => {
  let _name = name.toUpperCase();
  let obj = {
    REQUEST: `${opt}_${_name}_REQUEST`,
    START: `${opt}_${_name}_START`,
    SUCCESS: `${opt}_${_name}_SUCCESS`,
    FAILURE: `${opt}_${_name}_FAILURE`,
  };

  obj['request'] = makeActionCreator(obj['REQUEST'], 'payload');
  obj['start'] = makeActionCreator(obj['START'], 'payload');
  obj['failure'] = makeActionCreator(obj['FAILURE'], 'payload');
  obj['success'] = makeActionCreator(obj['SUCCESS'], 'payload');

  return obj;
};
/*
function createAction(name) {
  return _createAction('ASYNC')(name);
}
*/
export function createAsyncAction(name, ...args) {
  return args.reduce((prev, arg) => {
    prev[arg.toLowerCase()] = _createAction(arg.toUpperCase())(name);
    return prev;
  }, {});
}

export function createFetchAction(name) {
  return createAsyncAction(name, 'FETCH');
}

export function createRecordAction(name) {
  let obj = {
    record: {
      CHANGE: `RECORD_${name}_CHANGE`,
      DELETE: `RECORD_${name}_DELETE`,
      ADD: `RECORD_${name}_ADD`,
      APPEND: `RECORD_${name}_APPEND`,
      SET: `RECORD_${name}_SET`,
    },
  };
  obj.record['change'] = makeActionCreator(obj.record.CHANGE, 'payload');
  obj.record['delete'] = makeActionCreator(obj.record.DELETE, 'payload');
  obj.record['add'] = makeActionCreator(obj.record.ADD, 'payload');
  obj.record['append'] = makeActionCreator(obj.record.APPEND, 'payload');
  obj.record['set'] = makeActionCreator(obj.record.SET, 'payload');

  return obj;
}

export const notifyChangedAction = createAction(NOTIFY_CHANGED_ACTION);
export const notifyRemovedAction = createAction(NOTIFY_REMOVED_ACTION);
export const notifyAddedAction = createAction(NOTIFY_ADDED_ACTION);
export const notifyNotifyAction = createAction(NOTIFY_NOTIFY_ACTION);
