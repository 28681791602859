import { formatSeconds } from 'kit-time';
import { fiAdom } from 'fi-session';

let phaseText = {},
  FAZ_LOG_REBUILD_DONE = 999;
phaseText[MACROS.SYS.FAZ_LOG_REBUILD_ADOM_ONLY] = {
  brief: gettext('Rebuilding ADOM DB in progress ...'),
  detail: gettext(
    'Rebuilding ADOM DB in progress ..., estimated remaining time:'
  ),
  more: gettext(
    "Log and report features won't be fully available till rebuilding is completed."
  ),
};
phaseText[MACROS.SYS.FAZ_LOG_UPG_PHASE_ADQU] = {
  brief: gettext('Rebuilding DB - Parse log files...'),
  detail: gettext(
    'Step 1: Log Storage Upgrading..., estimated remaining time:'
  ),
};
phaseText[MACROS.SYS.FAZ_LOG_UPG_PHASE_RBDB] = {
  brief: gettext('Rebuilding DB - Build Log DB...'),
  detail: gettext(
    'Step 2: Rebuilding SQL database..., estimated remaining time:'
  ),
  more: phaseText[MACROS.SYS.FAZ_LOG_REBUILD_ADOM_ONLY].more,
};
phaseText[FAZ_LOG_REBUILD_DONE] = {
  detail: gettext('Rebuilding log database was completed.'),
};

export function parser(gLogdb) {
  // faz log db rebuilding
  if (gLogdb && gLogdb.adom === fiAdom.current().name) {
    // ignore if ADOM names does not match!
    let txt,
      rebuilding = false;
    if (
      (gLogdb.percent >= 0 && gLogdb.percent < 100) ||
      (gLogdb.phase < MACROS.SYS.FAZ_LOG_UPG_PHASE_LAST &&
        gLogdb.phase !== MACROS.SYS.FAZ_LOG_REBUILD_ADOM_ONLY)
    ) {
      if ((txt = phaseText[gLogdb.phase])) {
        txt = {
          ...txt,
          ...phaseText[gLogdb.phase],
          remain: formatSeconds(gLogdb.remain).join(' '),
        };
      } else {
        txt = {
          brief: 'unknown phase',
          detail: 'unknown phase',
          remain: '',
        };
      }
      rebuilding = true;
    } else {
      txt = { ...phaseText[FAZ_LOG_REBUILD_DONE], remain: '', more: null };
      rebuilding = false;
    }

    return {
      // ..._this.logdb,
      ...gLogdb,
      hint: gettext('Rebuild Log Database'),
      curPhaseText: txt,
      rebuilding,
    };
  }
}
