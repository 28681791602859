import {
  NwProHeader,
  NwProBody,
  NwProSection,
  NwProInputRow,
  NwProFooter,
  openConfirmModal,
  OkBtn,
  CancelBtn,
} from 'rc_layout';
import { Formik } from 'formik';
import { FmkErrorSpan, FmkPassword } from 'rc_form';
import { useMemo } from 'react';
import { fiSysConfig } from 'fi-session';
import { fiMessageBox } from 'fi-messagebox';
import { UserService } from 'fi-user';
import { useAuth } from 'react_apps/ra-auth/useAuth';

const getAutoId = (...suffix) => 'sys_change_password:' + suffix.join('-');

export const ChangePassword = ({ $opener, username, opts }) => {
  const auth = useAuth();
  const { infoMessage, showOld, hideCancel, hasDefaultPwd, forceChangePwd } =
    useMemo(
      () => ({
        infoMessage: opts.infoMessage || '',
        showOld:
          fiSysConfig.attr('username') === username && opts.showOld !== false,
        hideCancel: opts.hideCancel,
        hasDefaultPwd: opts.hasDefaultPwd,
        forceChangePwd: opts.forceChangePwd,
      }),
      []
    );

  const validate = (values) => {
    const errors = {};
    if (values.newPass0 !== values.newpasswd) {
      errors['newpasswd'] = gettext('Passwords do not match.');
    } else if (hasDefaultPwd && !values.newpasswd) {
      errors['newpasswd'] = gettext('Passwords field is empty.');
    }
    return errors;
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    const checkPasswordLenghPromise = new Promise((resolve, reject) => {
      if (!values.newpasswd || values.newpasswd.length < 6) {
        openConfirmModal({
          title: gettext('Short Password Warning'),
          content: gettext(
            'Your password is shorter than 6 characters! Are you sure you want to continue?'
          ),
        }).then(
          () => resolve(),
          () => reject()
        );
      } else {
        resolve();
      }
    });
    checkPasswordLenghPromise.then(
      () => {
        UserService.changePassword(
          username,
          values.oldpasswd,
          values.newpasswd
        ).then(
          function () {
            $opener.resolve();
            // logout directly if change self password.
            if (showOld) {
              auth.logout();
            }
          },
          function (resp) {
            setErrors(resp?.errors || {});
            setSubmitting(false);
            if (resp?.errors?.user) {
              fiMessageBox.show(resp.errors.user, 'danger');
            }
          }
        );
      },
      () => {
        setSubmitting(false);
      }
    );
  };

  const close = () => {
    $opener.reject();
  };

  const later = () => {
    $opener.resolve();
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <NwProHeader>{gettext('Change Password')}</NwProHeader>
          <NwProBody>
            <NwProSection>
              <div>{infoMessage}</div>
              {showOld ? (
                <NwProInputRow label={gettext('Old Password')}>
                  <FmkPassword
                    name='oldpasswd'
                    automation-id={getAutoId('oldpasswd')}
                  />
                  <FmkErrorSpan name='oldpasswd' />
                </NwProInputRow>
              ) : null}
              <NwProInputRow label={gettext('New Password')}>
                <FmkPassword
                  name='newPass0'
                  automation-id={getAutoId('newPass0')}
                />
                <FmkErrorSpan name='newPass0' />
              </NwProInputRow>
              <NwProInputRow label={gettext('Confirm Password')}>
                <FmkPassword
                  name='newpasswd'
                  automation-id={getAutoId('newpasswd')}
                />
                <FmkErrorSpan name='newpasswd' />
              </NwProInputRow>
            </NwProSection>
          </NwProBody>
          <NwProFooter>
            <OkBtn
              onClick={submitForm}
              automation-id={getAutoId('btn-ok')}
              loading={isSubmitting}
            >
              {gettext('OK')}
            </OkBtn>
            {hideCancel ? null : (
              <CancelBtn
                onClick={close}
                automation-id={getAutoId('btn-cancel')}
              >
                {gettext('Cancel')}
              </CancelBtn>
            )}
            {hasDefaultPwd && !forceChangePwd ? (
              <CancelBtn onClick={later} automation-id={getAutoId('btn-later')}>
                {gettext('Later')}
              </CancelBtn>
            ) : null}
          </NwProFooter>
        </>
      )}
    </Formik>
  );
};
