import { fiCsrfToken } from 'fi-cookies';

import { UiUploader } from './ui-uploader';
import { fiIdleTimer } from 'fiutil/idle_timer';

export const UploaderService = {
  create: function (opts) {
    return new Uploader(opts);
  },
};

function getInfo(file, active = true) {
  let fp = Array.isArray(file) ? file : [file];
  let info = fp.map((it) => {
    return {
      name: it.name,
      humanSize: it.humanSize,
      loaded: it.loaded,
      active,
      size: it.size,
      type: it.type,
      data: it.data,
    };
  });

  return info.length <= 1 ? info[0] || {} : info;
}

/** Manages a file uploading list and provide uploading progress.
 *
 * @constructor
 * @param opts: configuration options:
 * {
 *   url: the url to upload the files to.
 *   data: extra key-value pair to send.
 *   key: the name/key for the file parameter to send to the server.
 *   concurrency: how many files can be uploaded at the same time.
 *   onCompleted: callback function when one file finishes uploading.
 *   onProgress: callback function when one file is in progress.
 *   onCompletedAll: callback function when all files finishes uploading.
 * }
 */
class Uploader {
  constructor(opts) {
    Object.assign(this, {
      uiUploader: new UiUploader(),
      url: opts.url,
      data: opts.data,
      key: opts.key,
      singleRequest: opts.singleRequest || false,
      concurrency: opts.concurrency || 2,
      /**
       * @callback
       * @param {object} file - the file object.
       * @param {object} resp - the server response.
       * @param {number} status - the server response status.
       */
      onCompleted: function (file, resp, status) {
        let jresp = null;
        try {
          jresp = JSON.parse(resp);
        } catch (e) {
          jresp = resp;
        }

        // stop active state here.
        let fileInfo = getInfo(file, false);

        // must create a new object to avoid changing internal states.
        opts.onCompleted && opts.onCompleted(fileInfo, jresp, status);
      },
      onCompletedAll: opts.onCompletedAll || function () {},
      onProgress: function (file) {
        opts.onProgress && opts.onProgress(getInfo(file));

        // keep session alive, by reset idle timer
        if (fiIdleTimer) {
          fiIdleTimer.active();
        }
      },
    });
  }

  addFiles(files, key) {
    // filter empty files. Note: files is a FileList object and does not
    // have Array.prototype.filter method.
    let filtered = [];
    for (var i = 0; i < files.length; i++) {
      if (files[i]) {
        filtered.push(files[i]);
      }
    }
    this.uiUploader.addFiles(filtered, key);
  }

  getFiles() {
    return this.uiUploader.getFiles();
  }

  removeAll() {
    return this.uiUploader.removeAll();
  }

  start() {
    this.abortFn = this.uiUploader.startUpload({
      url: this.url,
      concurrency: this.concurrency,
      onCompleted: this.onCompleted,
      onCompletedAll: this.onCompletedAll,
      onProgress: this.onProgress,
      singleRequest: this.singleRequest || false,
      key: this.key,
      data: {
        ...(this.data || {}),
        csrfmiddlewaretoken: fiCsrfToken(true),
        csrf_token: fiCsrfToken(true),
      },
    });
  }

  abort() {
    if (typeof this.abortFn === 'function') {
      try {
        this.abortFn();
      } catch (ex) {
        // eslint-disable-next-line
        console.error(ex);
      }
      this.abortFn = null;
    }
  }
}
