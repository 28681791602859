import React, { useState, useEffect, useRef } from 'react';
import { fiStore, fiStoreUtils, fiStoreTheme } from 'fistore';
import { ProTable, ProToolkit, ProForm } from '@fafm/neowise-pro';
import { useDataSource } from './useDataSource';
import { useCommands } from './useCommands';
import colDef from './colDef';
import MonitorLineChart from './MonitorLineChart';
// import MonitorPieChart from './MonitorPieChart'; // may need the donut chart later
import { NwRadioButtonGroup, NwRadioButton } from '@fafm/neowise-core';
const { ErrorBoundary } = ProToolkit;
const { Header } = ProForm;

const rowKey = '_rowKey';
const DataInterval = 3; // seconds
const NumLoadsToKeep = Math.floor(60 / DataInterval) + 1;

const LineChartTypes = {
  CPU: 1,
  MemoryPercentage: 2,
  Memory: 3,
  Disk: 4,
};

const LineChartTypeChoices = [
  { id: LineChartTypes.CPU, text: 'CPU' },
  { id: LineChartTypes.MemoryPercentage, text: gettext('Memory (Percentage)') },
  { id: LineChartTypes.Memory, text: gettext('Memory (Bytes)') },
  { id: LineChartTypes.Disk, text: gettext('Disk I/O') },
];

const ProcessMonitor = ({ $opener }) => {
  const tableRef = useRef(null);

  const [lineChartType, setLineChartType] = useState(LineChartTypes.CPU);
  const [isPaused, setIsPaused] = useState(false);
  const [theme, setTheme] = useState();

  useEffect(() => {
    const unsub = fiStoreUtils.observeStore(
      fiStore,
      fiStoreTheme.getIsDarkTheme,
      (isDark) => {
        setTheme(isDark ? 'dark' : 'light');
      }
    );
    return unsub;
  }, []);

  // eslint-disable-next-line
  const [isLoading, summary, data, ioSummaryArr] = useDataSource({
    rowKey,
    NumLoadsToKeep,
    DataInterval,
    isPaused,
  });

  const [getToolbarItems, getContextMenuItems] = useCommands(
    isPaused,
    setIsPaused
  );

  useEffect(() => {
    if (tableRef && lineChartType) {
      let columnKey;
      switch (lineChartType) {
        case LineChartTypes.CPU:
          columnKey = 'cpu_pct';
          break;
        case LineChartTypes.Memory:
          columnKey = 'res';
          break;
        case LineChartTypes.MemoryPercentage:
          columnKey = 'mem_pct';
          break;
        case LineChartTypes.Disk:
          columnKey = 'disk_read';
          break;
        default:
          break;
      }
      tableRef.current.sort.setSortBy({ key: columnKey, order: 'desc' });
    }
  }, [lineChartType]);

  return (
    <ErrorBoundary>
      <Header
        onCloseClick={() => {
          $opener.resolve();
        }}
      >
        {gettext('Process Monitor')}
      </Header>
      <div className='tw-w-full tw-h-full'>
        <div className='tw-flex tw-flex-col tw-h-2/6'>
          <NwRadioButtonGroup
            value={lineChartType}
            onChange={(_, val) => {
              setLineChartType(val);
            }}
          >
            {LineChartTypeChoices.map((x) => (
              <NwRadioButton key={x.id} value={x.id}>
                {x.text}
              </NwRadioButton>
            ))}
          </NwRadioButtonGroup>
          <MonitorLineChart
            data={data}
            NumLoadsToKeep={NumLoadsToKeep}
            DataInterval={DataInterval}
            theme={theme}
            LineChartTypes={LineChartTypes}
            lineChartType={lineChartType}
            ioSummaryArr={ioSummaryArr}
            isLoading={isLoading}
          />
          {/* Display only the line chart for now, may need the donut chart later */}
          {/* <div className="tw-flex">
            <div className="tw-w-8/12 tw-overflow-hidden">
              <NwRadioButtonGroup>
                {LineChartTypeChoices.map((x) => (
                  <NwRadioButton
                    key={x.id}
                    value={x.id}
                    checked={x.id === lineChartType}
                    onClick={() => {
                      setLineChartType(x.id);
                    }}
                  >
                    {x.text}
                  </NwRadioButton>
                ))}
              </NwRadioButtonGroup>
            </div>
            <div className="tw-w-4/12 tw-overflow-hidden tw-text-lg tw-font-semibold tw-text-center">
              {gettext('Memory Usage')}
            </div>
          </div> */}
          {/* <div className="tw-flex tw-flex-1 tw-w-full">
            <div className="tw-w-8/12 tw-h-full tw-overflow-hidden">
              <MonitorLineChart
                data={data}
                NumLoadsToKeep={NumLoadsToKeep}
                DataInterval={DataInterval}
                theme={theme}
                LineChartTypes={LineChartTypes}
                lineChartType={lineChartType}
                ioSummaryArr={ioSummaryArr}
              />
            </div>
            <div className="tw-w-4/12 tw-h-full tw-overflow-hidden">
              <MonitorPieChart data={summary} theme={theme} />
            </div>
          </div> */}
        </div>
        <div className='tw-h-4/6'>
          <ProTable.TableView
            ref={tableRef}
            tableId={'process-monitor'}
            rowKey={rowKey}
            isLoading={isLoading}
            data={data[data.length - 1]}
            columns={colDef}
            getToolbarItems={getToolbarItems}
            getContextMenuItems={getContextMenuItems}
            ckColumn={false}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ProcessMonitor;
