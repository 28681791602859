import { forwardRef, useMemo } from 'react';
import { debounce, isNil } from 'lodash';
import { NwInput, NwIcon } from '@fafm/neowise-core';

export { TextSearch, searchHiliter, getSearchTextFn };

const onInputFocus = (evt) => {
  evt.target.select();
};
const TextSearch = forwardRef(
  (
    {
      onChange, // (string) => any
      autoIdPrefix,
      ...rest
    },
    ref
  ) => {
    const _onChange = useMemo(() => {
      if (typeof onChange !== 'function') return undefined;
      onChange = debounce(onChange, 500);
      return (evt) => onChange(evt.target.value || '');
    }, [onChange]);

    return (
      <NwInput
        {...rest}
        data-focus-type='text-search' // keyboard support attribute, should be at the same level for input
        placeholder={gettext('Search...')}
        automation-id={(autoIdPrefix ? autoIdPrefix + '-' : '') + 'searchInput'}
        onInput={_onChange}
        onFocus={onInputFocus}
        ref={ref}
        suffix={<NwIcon name='search'></NwIcon>}
      ></NwInput>
    );
  }
);

const renderHiliText = (text, i) => {
  return (
    <span className='fi-search-highlight' key={i}>
      {text}
    </span>
  );
};

function isValidSearchValue(val) {
  return typeof val === 'string' || typeof val === 'number';
}

function searchHiliter(term, caseSensitive, wholeWord) {
  return (text) => {
    if (isValidSearchValue(text) && term) {
      text = String(text);
      const escaped = escapeRegExp(term);
      let re = new RegExp(
        wholeWord ? `\\b${escaped}\\b` : escaped,
        'g' + (caseSensitive ? '' : 'i')
      );
      var arr = text.split(re),
        i = 1,
        j = 0;
      var matches = text.match(re) || []; // might be null
      while (i < arr.length) {
        arr.splice(i, 0, renderHiliText(matches[j], j));
        i += 2;
        j++;
      }
      return arr;
    }
    return text;
  };
}

const getSearchTextFn = (searchTerm, caseSensitive, textComparator) => {
  if (!searchTerm) return (d) => !isNil(d);

  let search;
  if (textComparator) {
    search = (value) => textComparator({ searchTerm, caseSensitive, value });
  } else {
    const regex = new RegExp(
      escapeRegExp(searchTerm),
      caseSensitive ? '' : 'i'
    );
    search = (val) => {
      return isValidSearchValue(val) && regex.test(String(val));
    };
  }

  return (val) => {
    if (val !== null && typeof val === 'object') {
      // do search on array or object first level
      for (let i in val) {
        if (search(val[i])) {
          return true;
        }
      }
    } else {
      return search(val);
    }
  };
};

// https://stackoverflow.com/questions/3115150/how-to-escape-regular-expression-special-characters-using-javascript
function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
