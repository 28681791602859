import { useParams, useLocation } from 'react-router-dom';
import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';
import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import { AdomWatchWrapper } from 'ra-shared-components';

const logviewPathDef = {
  path: ':_appUniKey/:devType/:_logtype',
  handle: {
    appUniKey: 'logview_ftntlogs',
  },
  lazy: async () => {
    let { LogviewContainer } = await import('./main/LogviewContainer');
    return {
      Component: () => {
        const { devType, _logtype } = useParams();
        const { state } = useLocation();

        const {
          querySrc,
          timePeriod,
          lastN,
          timeStart,
          timeEnd,
          devList,
          search,
          refreshKey,
        } = state || {};

        const logViewConfig = querySrc
          ? {
              querySrc,
              timePeriod: timePeriod?.value || timePeriod || 'custom',
              lastN,
              timeStart,
              timeEnd,
              devList,
              filter: search,
              isDrilldown: false,
            }
          : undefined;

        return (
          <LogviewContainer
            key={`${devType}-${_logtype}#${refreshKey}`}
            devOsType={devType}
            logType={_logtype.split('-')[0]}
            logSubType={_logtype.split('-')[1]}
            viewConfig={logViewConfig}
          />
        );
      },
    };
  },
};
const LOGVIEW_ROUTES = {
  RenderErrorBoundary,
  Component: AdomWatchWrapper,
  children: [
    {
      path: '/logview',
      lazy: () => import('./LogviewMenuContainer'),
      handle: {
        appUniKey: 'logview',
      },
      children: [
        getRedirectIndexRoute(),
        {
          path: 'threat_hunting',
          handle: {
            appUniKey: 'logview_threat_hunting',
          },
          lazy: () => import('react_apps/ra_siem/SiemInterestingFields'),
        },
        {
          path: 'logs',
          children: [
            {
              ...logviewPathDef,
              handle: {
                appUniKey: function (params = {}) {
                  const { _appUniKey: appUniKey } = params;
                  return appUniKey;
                },
              },
            },
            {
              path: 'ftntlogs',
              lazy: () => import('./container/LogviewFtntLogsContainer'),
              children: [
                {
                  path: 'utm-monitor',
                  children: [
                    {
                      path: ':_appUniKey/:devType/:_logtype',
                      handle: {
                        appUniKey: 'logview_ftntlogs',
                      },
                      lazy: () =>
                        import(
                          './summary_monitors/utm_summary_monitor/UtmSummaryMonitor'
                        ),
                    },
                  ],
                },
                {
                  path: 'e-monitor',
                  children: [
                    {
                      path: ':_appUniKey/:devType/:_logtype',
                      handle: {
                        appUniKey: 'logview_ftntlogs',
                      },
                      lazy: () =>
                        import(
                          './summary_monitors/event_summary_monitor/EventSummaryMonitor'
                        ),
                    },
                  ],
                },
                logviewPathDef,
              ],
            },
          ],
        },
        {
          path: 'customviewlogs',
          // defined in  logview/views.py, prefix added in genUniKey in logview saga
          handle: { appUniKey: 'logview_logs_customview' },
          children: [
            {
              path: ':appUniKey/:devType/:viewName',
              handle: {
                appUniKey: function (params = {}) {
                  const { appUniKey } = params;
                  return appUniKey;
                },
              },
              lazy: async () => {
                let { CustomviewContainer } = await import(
                  './main/CustomviewContainer'
                );
                return {
                  Component: () => {
                    const { viewName } = useParams();
                    return (
                      <CustomviewContainer
                        key={viewName}
                        customviewId={viewName}
                      />
                    );
                  },
                };
              },
            },
          ],
        },
        {
          path: 'logbrowse/:viewName?',
          handle: {
            appUniKey: 'logview_logbrowse',
          },
          lazy: () => import('./log_files_browse/LogFilesBrowse'),
        },
        {
          path: 'loggroups/:viewName?',
          handle: {
            appUniKey: 'logview_loggroups',
          },
          lazy: () => import('./log_group/List'),
        },
      ],
    },
  ],
};

export default LOGVIEW_ROUTES;
