import { isDefined, isObject, isUndefined, isFunction } from 'fiutil';
import { cloneDeep, forEach, isNil, isNumber } from 'lodash';
import { deviceStatus } from 'fi-dvm';

// General helper functions for Smart Table.
// Notice that functions begin with '_' are private and should not be called from outside
export const fiSmartTableHelper = {
  _getValue: function (obj, identifier) {
    const iders = identifier.split('.');
    let value = obj;
    for (let i = 0, l = iders.length; i < l; i++) {
      if (isUndefined(value) || value === null) {
        break;
      }
      value = value[iders[i]];
    }
    return value;
  },

  /**
   * Determines if two rows are the same row
   * @param {Object} row1 - a row to be compared
   * @param {Object} row2 - a row to be compared with
   * @param {String|Array} identifier - the identifier of a row,
   *   used for determining if two rows are the same
   *   If not specified, rows will be compared based on content
   *   It can be a string e.g. 'name' to compare row.name,
   *   'name.firstName' to compare row.name.firstName
   *   OR an array, used when there are multiple identifiers
   * @return {Boolean} true if two rows are the same
   */
  isSameRow: function (row1, row2, identifier) {
    if (!identifier) {
      if (JSON.stringify(row1) === JSON.stringify(row2)) {
        return true;
      }
    } else {
      if (Array.isArray(identifier)) {
        for (let i = 0, l = identifier.length; i < l; i++) {
          if (!this.isSameRow(row1, row2, identifier[i])) {
            return false;
          }
        }
        return true;
      } else {
        const row1_identifier_value = this._getValue(row1, identifier);
        const row2_identifier_value = this._getValue(row2, identifier);
        if (row1_identifier_value === row2_identifier_value) {
          return true;
        }
      }
    }
    return false;
  },

  /**
   * Finds and gets the index of the given row from all rows
   * @param {Object} row - the row needs to be searched
   * @param {Array} allRows - the array of all rows
   * @param {String|Array} rowIdentifier - the identifier of a row,
   *   used for determining if two rows are the same
   *   If not specified, rows will be compared based on content
   *   It can be a string e.g. 'name' to compare row.name,
   *   'name.firstName' to compare row.name.firstName
   *   OR an array, used when there are multiple identifiers
   * @return {Integer} the index of the given row in the array.
   *   -1 if the row is not found in the rows
   */
  indexOfRow: function (row, allRows, rowIdentifier) {
    if (
      isDefined(row.originalIndex) &&
      this.isSameRow(row, allRows[row.originalIndex], rowIdentifier)
    ) {
      return row.originalIndex;
    }
    for (let i = 0, l = allRows.length; i < l; i++) {
      if (this.isSameRow(row, allRows[i], rowIdentifier)) {
        return i;
      }
    }
    return -1;
  },

  /**
   * Gets all the selected rows from the table
   * @param {Array} allRows - all rows from the table
   * @param {Boolean} [toCopy] - true if the row in the
   *   result is a copy from the original row
   *   it is true by default
   * @param {Boolean} [isDefaultSelected] - Should the copied row be selected
   *   True by default
   *   Only works when toCopy is set to true
   * @return {Array} the array of all selected rows
   */
  getSelectedTableRows: function (allRows, toCopy, isDefaultSelected) {
    const result = [];
    toCopy = isDefined(toCopy) ? toCopy : true;
    isDefaultSelected = isDefined(isDefaultSelected) ? isDefaultSelected : true;
    forEach(allRows, function (row, index) {
      if (row && row.isSelected && row.selectable !== false) {
        let new_row;
        if (toCopy) {
          new_row = cloneDeep(row);
          new_row.originalIndex = index;
        } else {
          new_row = row;
        }
        result.push(new_row);
        if (toCopy && !isDefaultSelected) {
          new_row.isSelected = false;
        }
      }
    });
    return result;
  },

  /**
   * Sets selections to the table
   * @param {Array} allRows - all rows from the table
   * @param {Array|Object} rowsToSelect - row(s) need to be selected
   *   could be an array of row OR array of row index
   * @param {Boolean} [isToDeSelect] - to select or de-select those rows.
   *   True to de-select. False by default.
   * @param {String|Array} [rowIdentifier] - the identifier of a row,
   *   used for determining if two rows are the same
   *   If not specified, rows will be compared based on content
   *   It can be a string e.g. 'name' to compare row.name,
   *   'name.firstName' to compare row.name.firstName
   *   OR an array, used when there are multiple identifiers
   */
  setSelectionsToTable: function (
    allRows,
    rowsToSelect,
    isToDeSelect,
    rowIdentifier
  ) {
    const _this = this;
    isToDeSelect = isToDeSelect || false;
    rowsToSelect = Array.isArray(rowsToSelect) ? rowsToSelect : [rowsToSelect];
    forEach(rowsToSelect, function (row) {
      if (isNumber(row)) {
        allRows[row].isSelected = !isToDeSelect;
      } else {
        if (
          isDefined(row.originalIndex) &&
          _this.isSameRow(allRows[row.originalIndex], row, rowIdentifier)
        ) {
          allRows[row.originalIndex].isSelected = !isToDeSelect;
        } else {
          for (let i = 0, l = allRows.length; i < l; i++) {
            if (_this.isSameRow(allRows[i], row, rowIdentifier)) {
              allRows[i].isSelected = !isToDeSelect;
              break;
            }
          }
        }
      }
    });
  },

  /**
   * Selects all table rows
   * @param {Array} allRows - all rows from the table
   * @param {Boolean} toSelect - if to select or de-select a row.
   *   True by default
   */
  selectAll: function (allRows, toSelect) {
    toSelect = toSelect === false ? false : true;
    forEach(allRows, function (row) {
      row.isSelected = toSelect;
    });
  },

  /**
   *  Gets all children of the given row from allRows
   *  @param {Object|Integer} row - the row Object or index
   *  @param {Array} allRows
   *  @param {String|Array} rowIdentifier - the identifier of a row,
   *    used for determining if two rows are the same
   *    If not specified, rows will be compared based on content
   *    It can be a string e.g. 'name' to compare row.name,
   *    'name.firstName' to compare row.name.firstName
   *    OR an array, used when there are multiple identifiers
   *  @return {Array} children of row
   */
  getChildren: function (row, allRows, rowIdentifier) {
    const children = [];
    let index = -1;
    if (isNumber(row)) {
      index = row;
      row = allRows[index];
    } else {
      index = this.indexOfRow(row, allRows, rowIdentifier);
    }
    let _row;
    for (let i = index + 1, len = allRows.length; i < len; i++) {
      _row = allRows[i];
      if (_row._level === row._level) {
        return children;
      } else if (_row._level === row._level + 1) {
        children.push(_row);
      }
    }
    return children;
  },

  /**
   *  Gets the parent of the given row from allRows
   *  @param {Object|Integer} row - the row Object or index
   *  @param {Array} allRows
   *  @param {String|Array} rowIdentifier - the identifier of a row,
   *    used for determining if two rows are the same
   *    If not specified, rows will be compared based on content
   *    It can be a string e.g. 'name' to compare row.name,
   *    'name.firstName' to compare row.name.firstName
   *    OR an array, used when there are multiple identifiers
   *  @return {Object} parent of row, return null if no parent found.
   */
  getParent: function (row, allRows, rowIdentifier) {
    const parent = null;
    let index = -1;
    if (isNumber(row)) {
      index = row;
      row = allRows[index];
    } else {
      index = this.indexOfRow(row, allRows, rowIdentifier);
    }
    if (row._level) {
      // level 0 row has no parent, save the loop
      let _row;
      for (let i = index - 1; i >= 0; i--) {
        _row = allRows[i];
        if (_row._level === row._level - 1) {
          return _row;
        }
      }
    }
    return parent;
  },

  /**
   *  Gets the siblings of the given row from allRows
   *  @param {Object|Integer} row - the row Object or index
   *  @param {Array} allRows
   *  @param {String|Array} rowIdentifier - the identifier of a row,
   *    used for determining if two rows are the same
   *    If not specified, rows will be compared based on content
   *    It can be a string e.g. 'name' to compare row.name,
   *    'name.firstName' to compare row.name.firstName
   *    OR an array, used when there are multiple identifiers
   *  @return {Object} siblings of row.
   */
  getSiblings: function (row, allRows, rowIdentifier) {
    const siblings = [];
    let index = -1;
    if (isNumber(row)) {
      index = row;
      row = allRows[index];
    } else {
      index = this.indexOfRow(row, allRows, rowIdentifier);
    }
    let _row;
    // look up
    for (let i = index - 1; i >= 0; i--) {
      _row = allRows[i];
      if (_row._level === row._level) {
        siblings.push(_row);
      } else if (_row._level === row._level - 1) {
        break;
      }
    }
    // look down
    for (let j = index + 1, len = allRows.length; j < len; j++) {
      _row = allRows[j];
      if (_row._level === row._level) {
        siblings.push(_row);
      } else if (_row._level === row._level - 1) {
        break;
      }
    }
    return siblings;
  },

  /**
   *  Gets the ancestors of the given row from allRows
   *  @param {Object|Integer} row - the row Object or index
   *  @param {Array} allRows
   *  @param {String|Array} rowIdentifier - the identifier of a row,
   *    used for determining if two rows are the same
   *    If not specified, rows will be compared based on content
   *    It can be a string e.g. 'name' to compare row.name,
   *    'name.firstName' to compare row.name.firstName
   *    OR an array, used when there are multiple identifiers
   *  @return {Object} siblings of row.
   */
  getAncestors: function (row, allRows, rowIdentifier) {
    const ancestors = [];
    let index = -1;
    if (isNumber(row)) {
      index = row;
      row = allRows[index];
    } else {
      index = this.indexOfRow(row, allRows, rowIdentifier);
    }
    if (row._level) {
      // level 0 row has no ancestors, save the loop
      let parent = this.getParent(row, allRows, rowIdentifier);
      while (parent) {
        ancestors.push(parent);
        parent = this.getParent(parent, allRows, rowIdentifier);
      }
    }
    return ancestors;
  },

  /**
   *  Gets the descendants of the given row from allRows
   *  @param {Object|Integer} row - the row Object or index
   *  @param {Array} allRows
   *  @param {String|Array} rowIdentifier - the identifier of a row,
   *    used for determining if two rows are the same
   *    If not specified, rows will be compared based on content
   *    It can be a string e.g. 'name' to compare row.name,
   *    'name.firstName' to compare row.name.firstName
   *    OR an array, used when there are multiple identifiers
   *  @return {Object} siblings of row.
   */
  getDescendants: function (row, allRows, rowIdentifier) {
    const descendants = [];
    let index = -1;
    if (isNumber(row)) {
      index = row;
      row = allRows[index];
    } else {
      index = this.indexOfRow(row, allRows, rowIdentifier);
    }
    let _row;
    for (let i = index + 1, len = allRows.length; i < len; i++) {
      _row = allRows[i];
      if (_row._level === row._level) {
        return descendants;
      } else if (_row._level > row._level) {
        descendants.push(_row);
      }
    }
    return descendants;
  },

  _makeAllRowsMap: function (allRows) {
    const datamap = {};
    forEach(allRows, function (data) {
      let key = '';
      if (data._oData) {
        key =
          data._oData['_fiDeviceId'] ||
          data._oData['oid'] ||
          data._oData['name']; //oid is for group
      } else {
        key = data['name'];
      }
      datamap[key] = data;
    });
    return datamap;
  },
  /**
   *  Update table selection after select/de-select a row
   *  @param {Object|Integer} row - the row Object or index
   *  @param {Array} allRows
   *  @param {Boolean} quantifier
   *    if true, universal quantifier.
   *    A parent row is selected when ALL children rows are selected.
   *    A parent row is de-selected when ANY child row is de-selected.
   *    if false, existential quantifier.
   *    A parent row is selected when ANY child row is selected.
   *    A parent row is de-selected when ALL children rows are de-selected.
   *    Default false.
   *  @param {String|Array} rowIdentifier - the identifier of a row,
   *    used for determining if two rows are the same
   *    If not specified, rows will be compared based on content
   *    It can be a string e.g. 'name' to compare row.name,
   *    'name.firstName' to compare row.name.firstName
   *    OR an array, used when there are multiple identifiers
   */
  propagateSelection: function (row, allRows, quantifier, rowIdentifier) {
    if (isNumber(row)) {
      row = allRows[row];
    }
    quantifier = quantifier || false;
    // select/de-select children of row
    const children = this.getChildren(row, allRows, rowIdentifier);
    for (let i = 0; i < children.length; i++) {
      children[i].isSelected = row.isSelected && row.selectable !== false;
    }
    // select/de-select parent of row according to quantifier
    const parent = this.getParent(row, allRows, rowIdentifier);
    if (parent) {
      if ((quantifier && row.isSelected) || (!quantifier && !row.isSelected)) {
        const siblings = this.getSiblings(row, allRows, rowIdentifier);
        for (let j = 0; j < siblings.length; j++) {
          if (siblings[j].isSelected !== row.isSelected) {
            return;
          }
        }
      }
      parent.isSelected = row.isSelected && row.selectable !== false;
    }
  },

  makeAllRowsMap: function (allRows) {
    const _this = this;
    const datamap = {};
    forEach(allRows, function (row) {
      datamap[_this._getKeyOfRow(row)] = row;
    });
    return datamap;
  },
  _getKeyOfRow: function (row) {
    let key = '';
    if (row._oData) {
      key =
        row._oData['_fiDeviceId'] || row._oData['oid'] || row._oData['name']; //oid is for group
    } else {
      key = row['name'];
    }
    return key;
  },
  /**
   *  Sort tables of tree structure.
   *  Rows of different levels are sorted separately.
   *  @param {Array} allRows
   *  @param {String|Array} rowIdentifier - the identifier of a row,
   *    used for determining if two rows are the same
   *    If not specified, rows will be compared based on content
   *    It can be a string e.g. 'name' to compare row.name,
   *    'name.firstName' to compare row.name.firstName
   *    OR an array, used when there are multiple identifiers
   *  @return {Function} SmartTable sortFn.
   */
  sortFn: function (allRows, rowIdentifier) {
    const _this = this;
    const _datamap = _this.makeAllRowsMap(allRows);
    const NO_COMPARE = -100;

    return function (dataset, predicate, reverse, devGrpCfg) {
      const result = [].concat(dataset);
      const _compare = function (a, b) {
        a = isObject(a) ? a.txt : a;
        b = isObject(b) ? b.txt : b;
        if (a < b) {
          return reverse ? 1 : -1;
        } else if (a > b) {
          return reverse ? -1 : 1;
        }
        return 0;
      };
      function _findparent(data) {
        if (!data._oData) return null;
        const pkey = data._oData['_pkey'] || null;
        if (!pkey) {
          return null;
        }
        return _datamap[pkey] || null;
      }
      function _isSameRow(a, b) {
        const key_a = _this._getKeyOfRow(a);
        const key_b = _this._getKeyOfRow(b);
        return key_a === key_b;
      }
      function getCompareValue(rec) {
        return isFunction(devGrpCfg?.toSortValue)
          ? devGrpCfg.toSortValue(rec)
          : rec[predicate];
      }

      /**
       * This function is used to compare device groups, we should not mix device groups and deivces. so all the
       * device groups will be sorted either on top of all records or down the bottom.
       * REFERENCE: Mantis:0447845
       * @param {*} rec1
       * @param {*} rec2
       * @param {*} reverse
       */
      function defaultDevGrpCompare(rec1, rec2) {
        let grpIdentifier1;
        let grpIdentifier2;
        if (isDefined(devGrpCfg) && devGrpCfg.identifier) {
          grpIdentifier1 = rec1[devGrpCfg.identifier];
          grpIdentifier2 = rec2[devGrpCfg.identifier];
        } else {
          // use default identifier
          if (isDefined(rec1._oData) && isDefined(rec2._oData)) {
            // make sure _oData is not null
            grpIdentifier1 = rec1._oData.isGrp;
            grpIdentifier2 = rec2._oData.isGrp;
          } else {
            return NO_COMPARE;
          }
        }

        if (!grpIdentifier1 && !grpIdentifier2) {
          return NO_COMPARE;
        } else if (grpIdentifier1 && !grpIdentifier2) {
          // group always sorted in the beginning
          return -1;
        } else if (!grpIdentifier1 && grpIdentifier2) {
          return 1;
        } else {
          // compare value then
          const valueCompareResult = _compare(
            getCompareValue(rec1),
            getCompareValue(rec2)
          );
          return valueCompareResult;
        }
      }

      const _sort = function (a, b) {
        // compare tow grps first (toppest level)
        let cmpResultGrp = NO_COMPARE;
        if (isDefined(devGrpCfg) && isFunction(devGrpCfg.sortFn)) {
          // the customized fn can be passed to here
          cmpResultGrp = devGrpCfg.sortFn(a, b, reverse);
        } else {
          cmpResultGrp = defaultDevGrpCompare(a, b);
        }
        if (cmpResultGrp !== NO_COMPARE) return cmpResultGrp;

        // now proceed to next level
        const parent_a = _findparent(a, _datamap);
        const parent_b = _findparent(b, _datamap);
        let _compResult = 0;
        if (a._level === b._level) {
          if (parent_a === null && parent_b === null) {
            _compResult = _compare(getCompareValue(a), getCompareValue(b));
            if (_compResult === 0) {
              _compResult = _compare(a['name'], b['name']);
            }
            return _compResult;
          } else if (parent_a === null) {
            return -1;
          } else if (parent_b === null) {
            return 1;
          } else {
            if (_isSameRow(parent_a, parent_b, rowIdentifier)) {
              //handle root vdom sorting
              if (
                deviceStatus.isVdom(a._oData.rtype) &&
                deviceStatus.isVdom(b._oData.rtype)
              ) {
                if (a._oData.name === 'root') return -1;
                if (b._oData.name === 'root') return 1;
              }
              return _compare(getCompareValue(a), getCompareValue(b));
            } else {
              _compResult = _compare(
                getCompareValue(parent_a),
                getCompareValue(parent_b)
              );
              if (_compResult === 0) {
                _compResult = _compare(parent_a['name'], parent_b['name']);
              }
              return _compResult;
            }
          }
        } else if (a._level > b._level) {
          if (isNil(parent_a)) {
            return -1;
          }
          if (_isSameRow(parent_a, b, rowIdentifier)) {
            return 1;
          } else {
            return _sort(parent_a, b);
          }
        } else {
          if (_isSameRow(a, parent_b, rowIdentifier)) {
            return -1;
          } else {
            if (parent_b === null) {
              return 1;
            }
            return _sort(a, parent_b);
          }
        }
      };
      result.sort(_sort);
      return result;
    };
  },

  /**
   * Get the children of a row from a data map.
   * The map should be parsed row objects.
   */
  getChildrenByMap: function (row, datamap, childAttr) {
    const children = [];
    if (row._oData && Array.isArray(row._oData[childAttr])) {
      forEach(row._oData[childAttr], function (child) {
        const key = child['_fiDeviceId'] || child['oid'] || child['name'];
        if (datamap[key]) {
          children.push(datamap[key]);
        }
      });
    }
    return children;
  },
  /**
   *  Search tables of tree structure.
   *  @param {Array} allRows
   *  @param {Array} fields - the searchable fields
   *  @param {String|Array} rowIdentifier - the identifier of a row,
   *    used for determining if two rows are the same
   *    If not specified, rows will be compared based on content
   *    It can be a string e.g. 'name' to compare row.name,
   *    'name.firstName' to compare row.name.firstName
   *    OR an array, used when there are multiple identifiers
   *  @return {Function} SmartTable filterFn.
   */
  filterFn: function (allRows, fields, rowIdentifier, childAttr) {
    const _this = this;
    const _datamap = _this.makeAllRowsMap(allRows);
    const _match = function (row, match) {
      if (!match.length) {
        return true;
      }
      match = match.toLowerCase();
      let field;
      for (let i = 0; i < fields.length; i++) {
        field = fields[i];
        const tomatch =
          (isObject(row[field]) ? row[field].txt : row[field]) || '';
        if (tomatch.toString().toLowerCase().indexOf(match) >= 0) {
          return true;
        }
      }
      return false;
    };
    const _matched = function (row, match) {
      let matched = [];
      if (_match(row, match)) {
        matched = [row].concat(
          _this.getChildrenByMap(row, _datamap, childAttr)
        );
      } else {
        const children = _this.getChildrenByMap(row, _datamap, childAttr);
        let matchedChildren = [];
        for (let i = 0; i < children.length; i++) {
          matchedChildren = matchedChildren.concat(
            _matched(children[i], match)
          );
        }
        // add the row at then end if it has matched children
        if (matchedChildren.length) {
          matched = matched.concat(row, matchedChildren);
        }
      }
      return matched;
    };

    return function (dataset, predicate) {
      const match = predicate['$'] || '';
      let results = [];
      let _row;
      for (let i = 0; i < allRows.length; i++) {
        _row = allRows[i];
        if (_row.isHeader) {
          results.push(_row);
          continue;
        } else {
          results = results.concat(_matched(_row, match));
        }
        i += _this.getChildrenByMap(_row, _datamap, childAttr).length;
      }
      return results;
    };
  },
};
