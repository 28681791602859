import { RenderErrorBoundary } from 'react_apps/components/main/RenderErrorBoundary';
import centralRoutes from './routes_central';
import pdmRoutes from './routes_pdm';
import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';

const otherRoutes = [
  {
    path: 'topology',
    lazy: async () => {
      const { SwitchTopology } = await import(
        './fortiswitch_pages/Topology_View/Topology/SwitchTopology'
      );
      return {
        Component: SwitchTopology,
      };
    },
    handle: {
      appUniKey: 'fsw_topology',
    },
  },
  {
    path: 'faceplate',
    lazy: async () => {
      const { FaceplateTopology } = await import(
        './fortiswitch_pages/Topology_View/Faceplate/SwitchFaceplateTopology'
      );
      return {
        Component: FaceplateTopology,
      };
    },
    handle: {
      appUniKey: 'fsw_faceplate',
    },
  },
];

export default {
  path: 'fortiswitch',
  RenderErrorBoundary,
  handle: {
    appUniKey: 'fsw',
  },
  lazy: async () => {
    const { FswMain } = await import('./FswMain');
    return {
      Component: FswMain,
    };
  },
  children: [
    getRedirectIndexRoute(),
    ...pdmRoutes.children,
    ...centralRoutes.children,
    ...otherRoutes,
  ],
};
