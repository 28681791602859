import { fiHttp } from 'fi-http';
import { get } from 'lodash';

export function buildUrl(url) {
  return function (cmd) {
    return new Promise(function (resolve, reject) {
      fiHttp.post(url, cmd).then(
        (res) => {
          resolve(res?.data);
        },
        (err) => {
          reject(err.response?.data?.result[0]);
        }
      );
    });
  };
}

export function flatui_proxy(cmd) {
  return buildUrl('/cgi-bin/module/flatui_proxy')(cmd);
}

export function flatui_forward(cmd) {
  return buildUrl('/cgi-bin/module/flatui/forward')(cmd);
}

export function flatui_json(cmd) {
  return buildUrl('/cgi-bin/module/flatui/json')(cmd);
}

export function method_get(url, params = {}) {
  return {
    url,
    method: 'get',
    params,
  };
}

export function flatui_proxy_get(url, params = {}) {
  return flatui_proxy(method_get(url, params));
}

export function getDataFromResponse(resp, defaultVal = {}) {
  return get(resp, 'result.0.data', defaultVal);
}

export function getDataFromMultipleResponse(resps) {
  if (!Array.isArray(resps)) return;
  return resps.map((resp) => {
    return getDataFromResponse(resp);
  });
}
