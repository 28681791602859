import { fiAdom } from 'fi-session';
import { fiFmgHttp } from 'fi-http';
import { compareDeviceVer } from 'ra_device_util';

export function getMonitorReqCommon(device, url, adom = null) {
  let _adom = adom || fiAdom.current();
  let _req = {
    method: 'exec',
    params: [
      {
        url: 'sys/proxy/json',
        data: {
          target: [`adom/${_adom.name}/device/${device.name}`],
          action: 'get',
          resource: url,
          timeout: 20,
        },
      },
    ],
  };
  return _req;
}

export function getBandwidthData(
  device,
  vdom,
  _intfName,
  interval,
  abortCtrl,
  adom = null
) {
  let url = '';
  const intfName = encodeURIComponent(_intfName);

  if (compareDeviceVer(device, '7.0', '>=')) {
    url = `/api/v2/monitor/system/traffic-history/interface?interface=${intfName}&time_period=${interval}&vdom=${vdom}`;
  } else {
    url = `/api/v2/monitor/system/traffic-history?interface=${intfName}&time_period=${interval}`;
  }
  let _req = getMonitorReqCommon(device, url, adom);
  return fiFmgHttp.query(_req, abortCtrl).then((data) => {
    let resp = Array.isArray(data) ? data[0] : data;
    let ret = resp.data?.[0]?.['response']?.['results'];
    if (!ret) throw 'No response';
    return ret;
  });
}
