function _update(choiceArr, oldChoice, newChoice) {
  const index = choiceArr.findIndex((choice) => choice.id === oldChoice.id);
  if (index > -1) {
    if (oldChoice.id === newChoice.id) {
      choiceArr[index] = newChoice;
    } else {
      choiceArr.splice(index, 1);
      choiceArr.push(newChoice);
    }
  } else {
    choiceArr.forEach(({ children }) => {
      if (Array.isArray(children)) {
        _update(children, oldChoice, newChoice);
      }
    });
  }
}

// purpose of this helper class is to store the choices of SSelect, so we don't need to pass promises and refetch data
// every time the user create/edit choices.

const DEFAULT_CONFIG = {
  sort: true,
  sortFunc: (a, b) => a.text.toString().localeCompare(b.text.toString()),
};

export class fiSSource {
  constructor(config) {
    const { sort, sortFunc } = (config = { ...config, ...DEFAULT_CONFIG });

    const tis = this;
    this._sourceReady = new Promise((resolve) => (tis._ready = resolve));

    this._ssels = [];
    if (sort) this._sortFunc = sortFunc;
  }

  // init/reload the source array.
  load(choices) {
    this._source = choices;
    this._sort();
    this._ready();
    return this._updateSels().then(() => this);
  }

  // if have multiple SSelect using the same source, need to register them, so them will be updated
  // when user create/edit choices.
  register(sselect) {
    if (this._ssels.includes(sselect)) return;
    this._ssels.push(sselect);
    return () => this.unregister(sselect);
  }

  // unregister SSelect so it won't be update anymore.
  unregister(sselect) {
    this._ssels.splice(this._ssels.indexOf(sselect), 1);
  }

  _updateSels() {
    const loadPromise = [];
    const tis = this;
    this._ssels.forEach((sel) => {
      try {
        // const selected = sel.getSelected();
        loadPromise.push(
          // No need to set the selected again.
          // sel.setConfig({ source: tis._source }).load().then(sel => sel.setSelected(selected))
          sel.setConfig({ source: tis._source }).load()
        );
      } catch (ex) {
        console.error(ex);
      }
    });
    return Promise.all(loadPromise);
  }

  // pass in the edit choice, and the new choice after edited
  update(oldChoice, newChoice) {
    _update(this._source, oldChoice, newChoice);

    this._sort();
    return this._updateSels().then(() => newChoice.id);
  }

  _sort() {
    const sortFunc = this._sortFunc;
    if (typeof sortFunc === 'function') {
      this._source.sort(sortFunc);
      this._source.forEach(({ children }) => {
        if (Array.isArray(children)) {
          children.sort(sortFunc);
        }
      });
    }
  }

  // pass in the new choice after create
  add(newChoice) {
    let parent;
    if (
      this._source.length &&
      Array.isArray(this._source[0].children) &&
      (parent = this._source.find((parent) => parent.cate == newChoice.cate))
    ) {
      parent.children.push(newChoice);
    } else {
      this._source.push(newChoice);
    }

    this._sort();
    return this._updateSels().then(() => newChoice.id);
  }

  // pass to SSelect's constructor config/ Single-Select attr-choices as () => fiSSource.source
  get source() {
    return this._sourceReady.then(() => this._source);
  }
}
