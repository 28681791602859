import { DAY_MAP } from './constant';

export {
  getDateAndTimeStringFromJsDate,
  constructDate,
  constructTime,
  parseDateTimeStringToUnixTime,
  formatLocaleDate,
  getDaysTextFromVal,
};

const getDateAndTimeStringFromJsDate = (dateObj) => {
  const date = constructDate(dateObj);
  const time = constructTime(dateObj);

  return [date, time];
};

const formatDateNumber = (number) => {
  if (`${number}`.length === 1) {
    return `0${number}`;
  }

  return `${number}`;
};

const DATE_DELIMITER = '-';
const TIME_DELIMITER = ':';

const constructDate = (dateObj) => {
  return [
    dateObj.getFullYear(),
    formatDateNumber(dateObj.getMonth() + 1),
    formatDateNumber(dateObj.getDate()),
  ].join(DATE_DELIMITER);
};

const constructTime = (dateObj) => {
  return [
    formatDateNumber(dateObj.getHours()),
    formatDateNumber(dateObj.getMinutes()),
  ].join(TIME_DELIMITER);
};

const parseDateTimeStringToUnixTime = ({
  dateString = '',
  timeString = '',
  toSeconds = false,
}) => {
  const currentDate = dateString || constructDate(new Date(Date.now()));
  const fullDate = new Date(`${currentDate} ${timeString}`);
  const unixTime = fullDate.getTime();
  return toSeconds ? Math.ceil(unixTime / 1000) : unixTime;
};

const formatLocaleDate = (dateObj = new Date()) => {
  const localeDate = dateObj.toLocaleDateString();
  const localeTime = dateObj.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return [localeDate, localeTime];
};

function getDaysTextFromVal(dayVal) {
  return Object.entries(DAY_MAP)
    .filter(([key]) => !!(dayVal & key))
    .map(([, text]) => text);
}
