import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';
import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import { ReactContentViewContainer } from 'ra_main/main_layout/ReactContentViewContainer';
import { useParams } from 'react-router-dom';
// import { ReactContentViewContainer } from 'ra_main/main_layout/ReactContentViewContainer';

const FORTISOC_ROUTES = {
  RenderErrorBoundary,
  path: '/fortisoc',
  handle: {
    appUniKey: 'fortisoc',
  },
  children: [
    getRedirectIndexRoute({ redirectAppKey: 'fortisoc_outbreak_alerts' }),
    {
      path: 'soar',
      children: [
        getRedirectIndexRoute({ redirectAppKey: 'fortisoc_outbreak_alerts' }),
        {
          path: 'outbreak',
          handle: {
            appUniKey: 'fortisoc_soar_outbreak',
          },
          lazy: () => import('./outbreak/OutbreakList'),
        },
        {
          path: 'outbreakalerts',
          handle: {
            appUniKey: 'fortisoc_outbreak_alerts',
          },
          lazy: () => import('./outbreak/OutbreakAlerts/OutbreakList'),
        },
      ],
    },
    {
      path: 'automation',
      handle: {
        appUniKey: 'fortisoc_automation',
      },
      element: <ReactContentViewContainer menuId='fortisoc_automation' />,
      children: [
        {
          path: 'playbooks',
          handle: {
            appUniKey: 'fortisoc_dashboard_playbooks',
          },
          lazy: async () => {
            const { SocDashPlaybooks } = await import(
              /* webpackChunkName: "mod-fabric-automation-playbooks" */ '../ra_fortisoc/dashboards/Playbooks'
            );
            return { Component: SocDashPlaybooks };
          },
        },
        {
          path: 'connector',
          handle: {
            appUniKey: 'fortisoc_automation_connector',
          },
          lazy: async () => {
            const { SoarConnectorList } = await import(
              /* webpackChunkName: "mod-fabric-automation-connector" */ '../ra_fortisoc/connectors/SoarConnectorList'
            );
            return { Component: SoarConnectorList };
          },
        },
        {
          path: 'playbook/:isEdit?',
          handle: {
            appUniKey: 'fortisoc_automation_playbook',
          },
          lazy: async () => {
            const { PlaybookEditor } = await import(
              '../ra_playbook/editor/PlaybookEditor'
            );
            const { PlaybookList } = await import(
              '../ra_playbook/list/PlaybookList'
            );
            return {
              Component: () => {
                const params = useParams();
                if (params.isEdit) {
                  return <PlaybookEditor />;
                } else {
                  return <PlaybookList />;
                }
              },
            };
          },
        },
        {
          path: 'playbook-monitor',
          handle: {
            appUniKey: 'fortisoc_automation_playbook_monitor',
          },
          lazy: async () => {
            const { PlaybookMonitor } = await import(
              /* webpackChunkName: "mod-fabric-automation-playbook-monitor" */ '../ra_playbook/monitor/PlaybookMonitor'
            );
            return { Component: PlaybookMonitor };
          },
        },
      ],
    },
  ],
};

export default FORTISOC_ROUTES;
