import {
  Commands,
  ContextType,
  ErrorObj,
  ErrorSeverity,
  SyntaxAttribute,
  SyntaxAttributeTypes,
  SyntaxCategory,
  SyntaxContext,
} from '../types';
import { fuzzySearchStringArray } from 'kit/kit-fuzzy';
import {
  defaultValidate,
  validationFunctions,
} from './typeValidationFunctions';
import { isString } from 'lodash';

const ERROR_TEMPLATES = {
  OUTSIDE_TABLE: (command: Commands) =>
    `"${command}" command used outside of a table context.`,
  OUTSIDE_CONFIG: (command: Commands) =>
    `"${command}" command used outside of a config context.`,
  OUTSIDE_BOTH: (command: Commands) =>
    `"${command}" command used outside of config, edit, or table context.`,
  OUTSIDE_EDIT_AND_CONFIG: (command: Commands) =>
    `"${command}" command used without a current config or edit context.`,
  UNKNOWN: (command: Commands) => `Unknown command "${command}".`,

  NO_ENTRY_EDIT: (command: Commands) =>
    `"${command}" command expects exactly one argument specifying the entry to edit.`,
  NO_ENTRY_DELETE: (command: Commands) =>
    `"${command}" command expects exactly one argument specifying the entry to delete.`,
  NO_SETTING_NAME: (command: Commands) =>
    `"${command}" command expects exactly one argument specifying the setting name.`,

  NO_SUBOBJECT: (subobjName: string) => (currentCategoryName: string) =>
    `Subobject "${subobjName}" does not exist in the current context of "${currentCategoryName}".`,
  NO_SUBOBJECT_WITH_SUGGESTION:
    (subobjName: string) =>
    (currentCategoryName: string) =>
    (subobjNames: string) =>
      `Subobject "${subobjName}" does not exist in the current context of "${currentCategoryName}". Try one of ${subobjNames}.`,

  SETTING_NOT_FOUND: (settingName: string) => (currentCategoryName: string) =>
    `Setting "${settingName}" not found in the current context of "${currentCategoryName}".`,
};

const VDOM_CATEGORY = 'vdom';
const GLOBAL_CATEGORY = 'global';

export class ScriptValidator {
  syntaxStructure: Record<string, SyntaxCategory>;
  contextStack: SyntaxContext[];
  errors: ErrorObj[];
  script: string;
  handleMetaVariables: boolean;
  handleJinjaBraces: boolean;
  errorObjExtra: Partial<ErrorObj> & { cause?: string };
  originalLine: string;
  allowVdom: boolean;
  lineComment: string = '';
  errorCategories: string[];

  constructor(syntaxStructure: Record<string, SyntaxCategory>) {
    this.syntaxStructure = syntaxStructure;
    this.contextStack = []; // Stack to manage nested contexts
    this.errors = []; // Accumulate any parsing errors
    this.script = '';
    this.handleMetaVariables = false; //for cli template, need to ignore entire line when it has $() meta variables
    this.handleJinjaBraces = false; //for jinja template, ignore entire line when setting has {{ }}
    this.errorObjExtra = {}; //add extra info to error obj when needed
    this.originalLine = '';
    this.allowVdom = false;
    this.errorCategories = [];
  }

  //really only need to get the comment
  private getCommentIndex(str: string) {
    const quotation = {
      mark: '',
    };

    let lastChar = '';
    for (let i = 0; i < str.length; i++) {
      const char = str[i];
      if (char === '"') {
        if (!quotation.mark) quotation.mark = '"';
        else if (quotation.mark === '"') quotation.mark = '';
      } else if (char === "'") {
        if (!quotation.mark) quotation.mark = "'";
        else if (quotation.mark === "'") quotation.mark = '';
      } else if (
        str[i] === '#' &&
        !quotation.mark &&
        (/\s/.test(lastChar) || lastChar === '"' || !lastChar)
      ) {
        return i;
      }
      lastChar = str[i];
    }

    return -1;
  }

  /********************** MAIN PARSE FUNCTION **********************/
  parse(
    script: string,
    handleMetaVariables: boolean = false,
    allowVdom: boolean = false,
    handleJinja: boolean = false
  ) {
    this.errors = [];
    this.contextStack = [];
    this.handleMetaVariables = handleMetaVariables;
    this.handleJinjaBraces = handleJinja;
    this.script = script;
    this.allowVdom = allowVdom;
    this.errorCategories = [];

    let suggestedCloseContext: Partial<{
      e?: Error;
      line?: number;
      message?: string;
    }>;

    //only get first suggested close line
    const setError = (lineNumber: number) => (fromError: Error) => {
      if (!suggestedCloseContext)
        suggestedCloseContext = {
          e: fromError,
          line: lineNumber,
        };
    };

    let numOpenBrackets = 0;

    const shouldParseLine = (line: string) => {
      const hasDoubleBracket = line.includes('{{');
      const hasBracket = line.includes('{%') || line.includes('%}');
      return hasDoubleBracket || (!hasBracket && numOpenBrackets === 0);
    };

    const parseLineBraces = (line: string, index: number) => {
      for (let i = 0; i < line.length - 1; i++) {
        const char1 = line[i],
          char2 = line[i + 1];
        if (char1 + char2 === '{%') {
          numOpenBrackets++;
        } else if (char1 + char2 === '%}') {
          numOpenBrackets--;
        }
      }
      if (numOpenBrackets < 0) {
        this.pushError({
          message: 'Improper Jinja bracket, extra closing bracket',
          line: index + 1,
          originalLine: line,
        });
        numOpenBrackets = 0;
      }
    };

    const quotation = {
      mark: '',
    };

    const getLineArgs = (line: string) => {
      let idx = 0;
      const args = [];
      const quotation = {
        mark: '',
      };

      let tempArg = '';
      while (idx < line.length) {
        const char = line[idx];
        idx++;

        if (char === '"') {
          if (!quotation.mark) quotation.mark = '"';
          else if (quotation.mark === '"') quotation.mark = '';
        } else if (char === "'") {
          if (!quotation.mark) quotation.mark = "'";
          else if (quotation.mark === "'") quotation.mark = '';
        } else if (char === ' ' && !quotation.mark) {
          if (tempArg) {
            args.push(tempArg);
            tempArg = '';
            continue;
          }
        } else if (quotation.mark || (!quotation.mark && char !== ' ')) {
          tempArg += char;
        }
      }

      if (tempArg) args.push(tempArg);

      return args;
    };

    const parseLine = (originalLine: string, index: number) => {
      this.lineComment = '';
      this.originalLine = originalLine;
      let line = originalLine.trim();

      if (handleJinja) {
        parseLineBraces(line, index);
        if (!shouldParseLine(line)) return;
      }

      const commentIdx = this.getCommentIndex(line);
      if (commentIdx >= 0) {
        this.lineComment = ' ' + line.substring(commentIdx);
        line = line.substring(0, commentIdx).trim();
      }
      if (!line) return;
      try {
        this.errorObjExtra = {};
        const [command, ...args] = getLineArgs(line);
        if (!command) throw new Error('Error parsing script');
        this.handleCommand(
          command as Commands,
          args,
          index + 1,
          setError(index + 1)
        );
      } catch (e: unknown) {
        //catch error while parsing = error to display
        if (e instanceof Error) {
          this.pushError({
            message: e.message,
            line: index + 1,
            originalLine,
            ...this.errorObjExtra,
          });
        }
      }
    };

    const baseScriptLines = script.split('\n');

    const tempLine = {
      line: '',
      lineNumber: -1,
    };

    baseScriptLines.forEach((originalLine, idx) => {
      tempLine.line += originalLine;
      if (tempLine.lineNumber === -1) {
        tempLine.lineNumber = idx;
      }

      for (const char of originalLine) {
        if (char === '"') {
          if (!quotation.mark) quotation.mark = '"';
          else if (quotation.mark === '"') quotation.mark = '';
        } else if (char === "'") {
          if (!quotation.mark) quotation.mark = "'";
          else if (quotation.mark === "'") quotation.mark = '';
        }
      }

      if (!quotation.mark) {
        parseLine(tempLine.line.replaceAll('\n', ''), tempLine.lineNumber);
        tempLine.line = '';
        tempLine.lineNumber = -1;
      }
    });

    if (tempLine.line) {
      this.pushError({
        message: 'Unclosed quotation mark.',
        line: tempLine.lineNumber + 1,
      });
      parseLine(tempLine.line.replaceAll('\n', ''), tempLine.lineNumber);
    }

    //if has remaining context stack, there is unclosed context
    //show error on the line of the unclosed context
    if (this.contextStack.length > 0) {
      const currentContext = this.getCurrentContext() as SyntaxContext;
      this.pushError({
        message: 'Error: Unclosed config or edit context(s).',
        line: currentContext?.lineNumber || 1,
      });
    }

    //show error where we THINK the unclosed context could be closed
    //@ts-expect-error suggestedCloseContext will be assigned from some command handler
    if (suggestedCloseContext) {
      this.pushError({
        message:
          'Suggest closing possibly unclosed context with a "next" or "end" command.',
        line: suggestedCloseContext?.line ?? 0,
      });
    } else if (this.contextStack.length > 0) {
      this.pushError({
        message:
          'Suggest closing possibly unclosed context with a "next" or "end" command.',
        line: baseScriptLines.length,
      });
    }

    return this.errors;
  }

  getErrorCategories() {
    return this.errorCategories;
  }

  /********************** UTILITY HELPERS **********************/
  pushError({
    message,
    line,
    cause,
    originalLine,
    correction,
    correctionDisplayText,
    code,
    helpMsg,
    typeMsg,
    optMsg,
    refMsg,
  }: {
    message: string;
    line: number;
    originalLine?: string;
    cause?: string;
    correction?: string;
    correctionDisplayText?: string;
    code?: ErrorSeverity;
    helpMsg?: string;
    typeMsg?: string;
    optMsg?: string;
    refMsg?: string;
  }) {
    let start, end;
    if (cause && originalLine) {
      //find start and end of error by cause string
      const startIdx = originalLine.indexOf(cause);
      start = startIdx >= 0 ? startIdx : 0;
      end = startIdx >= 0 ? startIdx + cause.length : originalLine.length;
    } else if (originalLine) {
      //start and end is full line without whitespace
      start = Math.max(0, originalLine.search(/\S/));
      end = Math.max(originalLine.length, originalLine.search(/\s$/));
    }
    this.errors.push({
      line,
      message,
      correction: correction ? correction + this.lineComment : undefined,
      correctionDisplayText: correctionDisplayText
        ? correctionDisplayText + this.lineComment
        : undefined,
      code,
      start,
      end,
      helpMsg,
      typeMsg,
      optMsg,
      refMsg,
    });
    this.lineComment = '';
  }

  getCurrentContext(): SyntaxContext | null {
    return this.contextStack.length > 0
      ? this.contextStack[this.contextStack.length - 1]
      : null;
  }

  getCurrentCategoryName(): string {
    const currentContext = this.getCurrentContext();
    if (!currentContext) return '';
    const categoryName =
      currentContext.type === ContextType.edit
        ? this.contextStack[this.contextStack.length - 2].name
        : currentContext.name;
    return `${categoryName}`;
  }

  getCurrentRootCategory(): string {
    const rootContext = this.contextStack.find((context) => {
      return (
        context.type === ContextType.obj || context.type === ContextType.table
      );
    });
    return `${rootContext?.name || ''}`;
  }

  //in case of typo when making some setting
  //fuzzy search the possible options, then use the first match
  makeSuggestedCorrection(
    possibleOptions: string[],
    searchTerm: string,
    correctionTemplate: string,
    getCustomCorrection?: (matches: string[]) => string
  ): Partial<ErrorObj> {
    const matches = fuzzySearchStringArray(possibleOptions, searchTerm);
    const correctionStr = getCustomCorrection
      ? getCustomCorrection(matches)
      : matches[0];
    if (correctionStr?.trim?.()) {
      const correction = correctionTemplate.replace(
        '%correction',
        correctionStr.trim()
      );
      const numIndents = this.contextStack.length;
      return {
        correction: '\t'.repeat(numIndents) + correction,
        correctionDisplayText: correction,
        originalLine: this.originalLine,
      };
    }

    return {};
  }

  getCommandAndArgs(args: string[], currentContext: SyntaxContext) {
    const settingName = args[0]?.replace(/"/g, ''); // Assuming first arg is the setting name
    const origSettingArgs = args.slice(1);
    const settingValue = args.slice(1).map((arg) => {
      if (
        (arg.startsWith('"') && arg.endsWith('"')) ||
        (arg.startsWith("'") && arg.endsWith("'"))
      )
        return arg.substring(1, arg.length - 1);
      return arg;
    }); // For 'set', remaining args are values
    const attribute = (currentContext.object as SyntaxCategory).attr?.[
      settingName
    ];

    return {
      settingName,
      origSettingArgs,
      settingValue,
      attribute,
    };
  }

  //Datasource ref categories:
  errorMsgWithRef(attribute: SyntaxAttribute | undefined, errMsg: string) {
    if (Array.isArray(attribute?.ref) && attribute?.ref)
      this.errorObjExtra.refMsg = `${attribute.ref
        .map(({ category }) => {
          return `"${category}"`;
        })
        .join(', ')}`;
    return errMsg;
  }

  //add type for attr
  errorMsgWithType(attribute: SyntaxAttribute | undefined, errMsg: string) {
    if (attribute?.type) this.errorObjExtra.typeMsg = `${attribute.type}`;
    return this.errorMsgWithRef(attribute, errMsg);
  }

  //add help if attr has help
  errorMsgWithHelp(attribute: SyntaxAttribute | undefined, errMsg: string) {
    if (attribute?.help) {
      this.errorObjExtra.helpMsg = `${attribute.help}`;
    }
    return this.errorMsgWithType(attribute, errMsg);
  }

  /********************** COMMAND HANDLERS **********************/

  handleCommand(
    command: Commands,
    args: string[],
    lineNumber: number,
    errorCallback: (e: Error) => void = () => {}
  ) {
    // Comprehensive switch-case to handle various command types
    switch (command) {
      case Commands.config:
        // Enter a new 'config' context
        this.enterConfigContext(args, lineNumber, errorCallback);
        break;
      case Commands.edit:
        // Enter an 'edit' context within a 'config' context
        this.enterEditContext(args, lineNumber, errorCallback);
        break;
      case Commands.set:
        // Add or modify a setting within an 'obj' type context
        this.handleSet(args, lineNumber);
        break;
      case Commands.unset:
        // Remove a setting within an 'obj' type context
        this.handleUnset(args, lineNumber);
        break;
      case Commands.get:
        // Fetch and display the value of a setting, primarily for verification
        this.handleGet(args);
        break;
      case Commands.delete:
        // Remove an entry or object, must be within a 'config' context of type 'table'
        this.handleDelete(args);
        break;
      case Commands.purge:
        // Clear all entries or objects within a context, with specific constraints
        this.handlePurge();
        break;
      case Commands.abort:
        // Exit a configuration session prematurely, without saving changes
        this.exitContext(Commands.abort);
        break;
      case Commands.end:
        this.exitContext(Commands.end);
        break;
      case Commands.next:
        // Proceed to the next entry in 'edit' context or exit the 'edit' context
        this.exitContext(Commands.next);
        break;
      case Commands.select:
        this.handleSelectUnselect(Commands.select, args, lineNumber);
        break;
      case Commands.unselect:
        this.handleSelectUnselect(Commands.unselect, args, lineNumber);
        break;
      case Commands.append:
        this.handleAppend(args, lineNumber);
        break;
      case Commands.clear:
        this.handleClear(args);
        break;
      case Commands.exit:
      case Commands.diagnose:
      case Commands.execute:
      case Commands.alias:
      case Commands.sudo:
      case Commands.clone:
      case Commands.move:
      case Commands.rename:
      case Commands.show:
        break;
      default:
        if (!Commands[command as Commands]) {
          this.errorObjExtra = {
            cause: command,
          };
          throw new Error(ERROR_TEMPLATES.UNKNOWN(command as Commands));
        }
    }
  }

  enterConfigContext(
    args: string[],
    lineNumber: number,
    errorCallback: (e: Error) => void
  ) {
    const throwCloseContextError = (err: Error) => {
      errorCallback(err);
      throw err;
    };

    const contextName = args.join(' ').replace(/"/g, ''); // Remove quotes from the argument

    // Attempt to fetch the current context to determine if we're within an object or subobject
    const currentContext = this.getCurrentContext();

    let newContextObject: SyntaxCategory | undefined;
    let overrideType: ContextType | null = null;

    const handlePossibleStaticObject = (
      objectKeys: string[],
      structure: undefined | Record<string, SyntaxCategory>
    ) => {
      const possibleSubObject = objectKeys.find((objKey) =>
        contextName.startsWith(objKey)
      );

      if (possibleSubObject) {
        const contextObject = structure?.[possibleSubObject];
        if (
          contextObject?.static ||
          ['main-class'].includes(possibleSubObject)
        ) {
          //need to hard code some categories that don't have static flag
          newContextObject = contextObject;
          overrideType = ContextType.obj;
          return true;
        }
      }

      return false;
    };

    const handleNoCurrentContext = () => {
      // If there's no current context, then we're attempting to access a top-level object
      if (!this.syntaxStructure[contextName]) {
        const keys = Object.keys(this.syntaxStructure);

        if (handlePossibleStaticObject(keys, this.syntaxStructure)) {
          return;
        }

        this.errorObjExtra = this.makeSuggestedCorrection(
          keys,
          contextName,
          'config %correction'
        );
        const errorReason = `Category "${contextName}" does not exist as a top-level category.`;
        this.errorObjExtra.cause = contextName;
        this.errorCategories.push(contextName);
        throw new Error(errorReason);
      }
      newContextObject = this.syntaxStructure[contextName];
    };

    const handleHasCurrentContext = () => {
      let errorReason: string = '';
      if (!currentContext) throw new Error('Unexpected validation error');
      if (currentContext.isVdom) {
        return handleNoCurrentContext();
      }
      if (
        !currentContext.object.subobj ||
        !(currentContext.object as SyntaxCategory)?.subobj?.[contextName]
      ) {
        const subObjectKeys = Object.keys(currentContext.object.subobj || {});

        if (
          handlePossibleStaticObject(
            subObjectKeys,
            (currentContext.object as SyntaxCategory).subobj
          )
        ) {
          return;
        }

        if (subObjectKeys.length) {
          const subobjNames = subObjectKeys.join(', ');
          errorReason = ERROR_TEMPLATES.NO_SUBOBJECT_WITH_SUGGESTION(
            contextName
          )(this.getCurrentCategoryName())(subobjNames);
          this.errorObjExtra = this.makeSuggestedCorrection(
            subObjectKeys,
            contextName,
            'config %correction'
          );
        } else {
          errorReason = ERROR_TEMPLATES.NO_SUBOBJECT(contextName)(
            this.getCurrentCategoryName()
          );
        }
        this.errorCategories.push(this.getCurrentRootCategory());
        throwCloseContextError(new Error(errorReason));
      }

      //there is a subobj, need to check if the parent obj is a table or obj. If table, need to be in an edit context
      if (currentContext.type === ContextType.table) {
        this.errorObjExtra = { cause: contextName };
        throw new Error(
          `Cannot config subobject ${contextName} in a table. EDIT an entry first.`
        );
      }

      newContextObject = (currentContext.object as SyntaxCategory).subobj?.[
        contextName
      ];
    };

    // If there's a current context, it might be an attempt to access a subobject
    if (currentContext) {
      //validate if new context name is real category

      if (currentContext.isVdom) {
        handleNoCurrentContext();
      } else {
        handleHasCurrentContext();
      }
    } else {
      if (contextName === VDOM_CATEGORY && this.allowVdom) {
        this.contextStack.push({
          type: ContextType.table,
          name: VDOM_CATEGORY,
          object: this.syntaxStructure,
          lineNumber,
          isVdom: true,
        });
        return;
      } else if (contextName === GLOBAL_CATEGORY && this.allowVdom) {
        this.contextStack.push({
          type: ContextType.obj,
          name: GLOBAL_CATEGORY,
          object: this.syntaxStructure,
          lineNumber,
          isVdom: true,
        });
        return;
      } else {
        handleNoCurrentContext();
      }
    }

    if (!newContextObject) {
      this.errorObjExtra = { cause: contextName };
      this.errorCategories.push(contextName);
      throw new Error(`Unknown category "${contextName}".`);
    }

    // Push the new context onto the stack
    this.contextStack.push({
      type: overrideType ? overrideType : newContextObject.type,
      name: contextName,
      object: newContextObject,
      lineNumber,
    });
  }

  enterEditContext(
    args: string[],
    lineNumber: number,
    errorCallback: (e: Error) => void
  ) {
    const throwCloseContextError = (err: Error, extra: { cause: string }) => {
      this.errorObjExtra = extra;
      errorCallback(err);
      throw err;
    };
    // Check if there's a current context and if it's of the correct type
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      this.errorObjExtra = {
        cause: Commands.edit,
      };
      throw new Error(ERROR_TEMPLATES.OUTSIDE_CONFIG(Commands.edit));
    }

    if (currentContext.type !== ContextType.table) {
      throwCloseContextError(
        new Error(ERROR_TEMPLATES.OUTSIDE_TABLE(Commands.edit)),
        {
          cause: Commands.edit,
        }
      );
    }

    // Edit command typically requires exactly one identifier argument (entry name or number)
    if (args.length !== 1) {
      const jinjaMatches = args.join('').match(/\{\{(.*)\}\}/);
      if (
        this.handleMetaVariables &&
        args.some((arg) => arg.match(/\$\(.*\)/))
      ) {
        //ignore
      } else if (this.handleJinjaBraces && jinjaMatches?.length) {
        //ignore
      } else {
        this.errorObjExtra = {
          cause: Commands.edit,
        };
        throw new Error(ERROR_TEMPLATES.NO_ENTRY_EDIT(Commands.edit));
      }
    }

    const entryIdentifier = args[0].replace(/"/g, '');

    this.contextStack.push({
      type: ContextType.edit,
      name: entryIdentifier,
      object: {
        ...(currentContext.object as SyntaxCategory),
        type: ContextType.edit,
      },
      lineNumber,
      isVdom: currentContext.isVdom,
    });
  }

  // End, Next, and Abort command handling
  exitContext(command: Commands) {
    if (this.contextStack.length === 0) {
      this.errorObjExtra = { cause: command };
      throw new Error(ERROR_TEMPLATES.OUTSIDE_EDIT_AND_CONFIG(command));
    }
    // 'next' specifically exits edit contexts
    const allowedContextTypes: Partial<Record<Commands, ContextType[]>> = {
      [Commands.next]: [ContextType.edit],
      [Commands.abort]: [ContextType.edit, ContextType.obj],
    };

    const allowedContextType: ContextType[] | undefined =
      allowedContextTypes[command];
    if (
      allowedContextType?.length &&
      !allowedContextType.some(
        (type) => type === this.getCurrentContext()?.type
      )
    ) {
      this.errorObjExtra = { cause: command };
      throw new Error(
        `"${command}" command can only be used to exit one of ${allowedContextType.join(
          ', '
        )} context.`
      );
    }

    //end and abort command pops to the outermost config context
    if (command === Commands.end || command === Commands.abort) {
      while (
        this.getCurrentContext()?.type !== ContextType.table &&
        this.getCurrentContext()?.type !== ContextType.obj
      ) {
        this.contextStack.pop();
      }
    }

    this.contextStack.pop(); // Exiting the current context
  }

  handleSet(args: string[], lineNumber: number) {
    this.handleSetUnset(Commands.set, args, lineNumber);
  }

  handleUnset(args: string[], lineNumber: number) {
    this.handleSetUnset(Commands.unset, args, lineNumber);
  }

  handleGet(args: string[]) {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      this.errorObjExtra = {
        cause: Commands.get,
      };
      throw new Error(ERROR_TEMPLATES.OUTSIDE_EDIT_AND_CONFIG(Commands.get));
    }
    if (args.length !== 1) {
      this.errorObjExtra = {
        cause: Commands.get,
      };
      throw new Error(ERROR_TEMPLATES.NO_SETTING_NAME(Commands.get));
    }
  }

  handleDelete(args: string[]) {
    const currentContext = this.getCurrentContext();
    if (!currentContext || currentContext.type !== ContextType.table) {
      this.errorObjExtra = {
        cause: Commands.delete,
      };
      throw new Error(ERROR_TEMPLATES.OUTSIDE_TABLE(Commands.delete));
    }
    if (args.length !== 1) {
      this.errorObjExtra = {
        cause: Commands.delete,
      };
      throw new Error(ERROR_TEMPLATES.NO_ENTRY_DELETE(Commands.delete));
    }
  }

  handlePurge() {
    const currentContext = this.getCurrentContext();
    if (!currentContext || !(currentContext.type === ContextType.table)) {
      this.errorObjExtra = {
        cause: Commands.purge,
      };
      throw new Error(ERROR_TEMPLATES.OUTSIDE_TABLE(Commands.purge));
    }
  }

  handleSelectUnselect(command: Commands, args: string[], lineNumber: number) {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      this.errorObjExtra = { cause: command };
      throw new Error(ERROR_TEMPLATES.OUTSIDE_EDIT_AND_CONFIG(command));
    }

    if ([ContextType.edit, ContextType.obj].includes(currentContext.type)) {
      this.handleClearAppendCommon(command, args);
      return this.handleSetUnset(command, args, lineNumber);
    }
  }

  getSettingValueFromOriginalScriptLine(
    command: Commands,
    settingName: string
  ) {
    const originalLine = this.originalLine;
    const indexOfCommand = this.originalLine.indexOf(command);
    let cleanedLine = originalLine.substring(
      indexOfCommand + command.length + 1
    );
    const indexOfSettingAttr = cleanedLine.indexOf(settingName);
    cleanedLine = cleanedLine.substring(
      indexOfSettingAttr + settingName.length + 1
    );
    return cleanedLine.trim();
  }

  handleAppend(args: string[], lineNumber: number) {
    this.handleClearAppendCommon(Commands.append, args);

    const currentContext = this.getCurrentContext() as SyntaxContext;
    const { settingName, settingValue, attribute } = this.getCommandAndArgs(
      args,
      currentContext
    );

    const failReason = this.validateSetting(
      attribute,
      settingValue,
      lineNumber,
      `append ${settingName}`
    );

    if (!settingValue.length || failReason) {
      //use original line to get only the contents of the setting values
      const settingValuesStr = this.getSettingValueFromOriginalScriptLine(
        Commands.append,
        settingName
      );
      this.errorObjExtra = { cause: settingValuesStr };
      throw new Error(
        this.errorMsgWithHelp(
          attribute,
          `Invalid value for setting "${settingName}". ${
            failReason ? failReason : ''
          }`
        )
      );
    }
  }

  handleClear(args: string[]) {
    this.handleClearAppendCommon(Commands.clear, args);

    const currentContext = this.getCurrentContext() as SyntaxContext;
    const { settingValue } = this.getCommandAndArgs(args, currentContext);

    if (settingValue.length) {
      this.errorObjExtra = {
        cause: Commands.clear,
      };
      throw new Error('"CLEAR" command should not have additional values.');
    }
  }

  handleClearAppendCommon(command: Commands, args: string[]) {
    const currentContext = this.getCurrentContext();
    if (!currentContext) {
      this.errorObjExtra = {
        cause: command,
      };
      throw new Error(ERROR_TEMPLATES.OUTSIDE_EDIT_AND_CONFIG(command));
    }

    const { attribute } = this.getCommandAndArgs(args, currentContext);
    const allowMultiple =
      !attribute?.excluded ||
      attribute?.type === SyntaxAttributeTypes.opt_array; //excluded = true means "exclusive"

    if (!attribute || !attribute.opts || !allowMultiple) {
      this.errorObjExtra = { cause: command };
      throw new Error(
        `"${command}" command can only be used on settings that allow multiple option select.`
      );
    }
  }

  //validate SET/SELECT/UNSELECT/APPEND
  handleSetUnset(command: Commands, args: string[], lineNumber: number) {
    const currentContext = this.getCurrentContext();
    if (
      !currentContext ||
      (![ContextType.edit, ContextType.obj].includes(currentContext.type) &&
        ![
          Commands.select,
          Commands.unselect,
          Commands.append,
          Commands.clear,
        ].includes(command))
    ) {
      this.errorObjExtra = {
        cause: command,
      };
      throw new Error(ERROR_TEMPLATES.OUTSIDE_EDIT_AND_CONFIG(command));
    }
    // Specific logic for set and unset operations
    const { settingName, origSettingArgs, settingValue, attribute } =
      this.getCommandAndArgs(args, currentContext);

    if (!attribute) {
      // Setting does not exist in current context
      if (!settingName) {
        this.errorObjExtra = { cause: command };
        throw new Error(
          `"${command}" command needs an attribute to operate on.`
        );
      }
      //try to correct the incorrect setting name (e.g. SET alis => SET alias)
      const getCustomCorrection = (matches: string[]) => {
        if (
          [
            //these commands only operate on multi-select opt settings
            Commands.select,
            Commands.unselect,
            Commands.append,
            Commands.clear,
          ].includes(command)
        ) {
          matches = matches.filter((attrKey) => {
            const attribute = (currentContext.object as SyntaxCategory).attr?.[
              attrKey
            ];
            if (!attribute) return false;
            const allowMultiple =
              !attribute.excluded ||
              attribute.type === SyntaxAttributeTypes.opt_array; //excluded = true means "exclusive"
            return allowMultiple;
          });
        }

        return matches[0];
      };
      const keys = Object.keys(currentContext.object.attr || {});
      const errSuggestion = this.makeSuggestedCorrection(
        keys,
        settingName,
        `${command} %correction ${origSettingArgs.join(' ')}`,
        getCustomCorrection
      );
      this.errorObjExtra = { cause: settingName, ...errSuggestion };
      throw new Error(
        ERROR_TEMPLATES.SETTING_NOT_FOUND(settingName)(
          this.getCurrentCategoryName()
        )
      );
    }

    if (
      this.handleMetaVariables &&
      settingValue.some((value) => {
        return value.match(/\$\(.*\)/);
      })
    ) {
      return;
    }

    if (this.handleJinjaBraces) {
      const jinjaMatches = settingValue.join('').match(/\{\{(.*)\}\}/);
      if (jinjaMatches?.length) {
        return;
      }
    }

    //for error
    const settingValuesStr = this.getSettingValueFromOriginalScriptLine(
      command,
      settingName
    );

    // For 'set' command
    if (
      [
        Commands.set,
        Commands.select,
        Commands.unselect,
        Commands.append,
      ].includes(command)
    ) {
      const failReason = this.validateSetting(
        attribute,
        settingValue,
        lineNumber,
        `${command} ${settingName}`
      );

      if (failReason) {
        this.errorObjExtra = { ...this.errorObjExtra, cause: settingValuesStr };
        throw new Error(
          this.errorMsgWithHelp(
            attribute,
            `Invalid value for setting "${settingName}". ${
              isString(failReason) ? failReason : ''
            }`
          )
        );
      }
    }

    const opts = Object.keys(attribute.opts || {});
    const allowMultiple =
      !attribute.excluded || attribute.type === SyntaxAttributeTypes.opt_array; //excluded = true means "exclusive"
    if (opts.length && !allowMultiple && args.length - 1 > 1) {
      const choices = opts.join(', ');
      this.errorObjExtra = { cause: settingValuesStr };
      throw new Error(
        this.errorMsgWithHelp(
          attribute,
          `"${settingName}" only allows a single choice from the following: ${choices}`
        )
      );
    }

    if (command === Commands.unset && args.length > 1) {
      this.errorObjExtra = {
        cause: settingValuesStr,
      };
      throw new Error(
        this.errorMsgWithHelp(
          attribute,
          `"${command}" should not have more than one argument.`
        )
      );
    }
  }

  validateSetting(
    attribute: SyntaxAttribute | undefined,
    values: string[],
    lineNumber: number,
    commandStart: string
  ): string | void | boolean {
    // This method can further be expanded to accurately validate setting values against attribute properties
    // For simplicity, let's assume if opts are defined, the value must match one of the opts

    if (!attribute) return 'No such attribute.';

    if (attribute.opts) {
      const optKeys = Object.keys(attribute.opts || {});
      const choices = optKeys.join(', ');

      if (!values.length) {
        this.errorObjExtra.optMsg = choices;
        return true;
      }

      if (
        !values.every(
          (value) =>
            Object.keys(attribute.opts || {})
              .map((str) => `${str}`)
              .includes(`${value}`) ||
            Object.values(attribute.opts || {})
              .map((str) => `${str}`)
              .includes(`${value}`)
        )
      ) {
        const corrected = [];
        for (const value of values) {
          const closestMatches = fuzzySearchStringArray(optKeys, value);
          if (closestMatches[0]) corrected.push(closestMatches[0]);
        }
        if (corrected.length) {
          const correction = `${commandStart} ${corrected.join(' ')}`;
          const numIndents = this.contextStack.length;
          const errCorrectionObj = {
            correction: '\t'.repeat(numIndents) + correction,
            correctionDisplayText: correction,
          };
          this.errorObjExtra = { ...this.errorObjExtra, ...errCorrectionObj };
        }
        this.errorObjExtra.optMsg = choices;
        return true;
      }

      return;
    }

    if (!values.length) return 'Requires at least one argument.';

    //otherwise validate by attr type
    const attrType = attribute.type;
    const validateFunction = validationFunctions[attrType];
    if (!validateFunction) return defaultValidate(attribute, values);

    return validateFunction(attribute, values);
  }
}
