export { selectedTmpl, unSelectTmpl };

let _selectedTmpl = null;

const unSelectTmpl = () => {
  _selectedTmpl = undefined;
};

const selectedTmpl = (currentTmpl) => {
  if (currentTmpl) {
    _selectedTmpl = currentTmpl;
  }
  return _selectedTmpl;
};
