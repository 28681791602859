import {
  NwProHeader,
  NwProBody,
  NwProSection,
  NwProFooter,
  OkBtn,
  CancelBtn,
  NwProInputRow,
  ConditionalComponent,
} from 'react_components/rc_layout';
import { Formik } from 'formik';
import {
  FmkErrorSpan,
  FmkInput,
  FmkRadioBtnGroup,
  FmkSwitch,
} from 'react_components/rc_form';
import { useMemo } from 'react';
import { fiAdom } from 'fi-web/fi-session';

const getAutoId = (...suffix) => 'csv_exporter:' + suffix.join('-');

const allColumnsOpts = [
  { id: MACROS.PM2CAT.PM2_OPT_ENABLE, text: gettext('Export all columns') },
  {
    id: MACROS.PM2CAT.PM2_OPT_DISABLE,
    text: gettext('Export customized columns only'),
  },
];

export const CsvExporter = ({
  $opener,
  fileName,
  data,
  columnDefs,
  context,
  meta,
  isConfirmOnly,
}) => {
  const fmData = useMemo(
    () => ({
      fileName: fileName,
      allColumns: MACROS.PM2CAT.PM2_OPT_ENABLE,
      exportAll: false,
    }),
    []
  );

  const validate = (values) => {
    const errors = {};
    if (!values.fileName)
      errors['fileName'] = gettext('File name is required.');
    return errors;
  };

  const handleSubmit = async (values) => {
    if (isConfirmOnly) return $opener.resolve(values);

    var cols = values.allColumns
      ? columnDefs
      : columnDefs.filter(function (aCol) {
          return !aCol.hidden;
        });
    const csvData =
      values.exportAll && meta.getAllData
        ? (await meta.getAllData()) || []
        : [...data];

    if (meta?.process && !(await meta.process({ csvData }, { values }))) {
      return;
    }

    if (meta?.excel) {
      $opener.resolve(csvData);
      return;
    }

    const blob = csvBlob(csvData, cols, context);
    downloadBlob(blob, values.fileName);
    $opener.resolve();
  };

  const close = () => {
    $opener.reject();
  };

  //allow export all when in per-device mode
  const showExportAll = useMemo(() => {
    if (!meta || !meta.listType) return false;
    const adom = fiAdom.current();
    if (meta.listType === MACROS.USER.DVM.ASSETS_FORTIAP) {
      return !adom.fap;
    } else if (meta.listType === MACROS.USER.DVM.ASSETS_FORTISWITCH) {
      return !adom.fsw;
    }
    return false;
  }, [meta]);

  return (
    <Formik
      initialValues={fmData}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <NwProHeader>
            {meta?.excel
              ? gettext('Export to Excel')
              : gettext('Export to CSV')}
          </NwProHeader>
          <NwProBody>
            <NwProSection>
              {!meta?.excel ? (
                <>
                  <NwProInputRow label={gettext('File Name')}>
                    <FmkInput
                      name='fileName'
                      clearable
                      automation-id={getAutoId('fileName')}
                    />
                    <FmkErrorSpan name='fileName' />
                  </NwProInputRow>
                  <NwProInputRow label={gettext('Options')}>
                    <FmkRadioBtnGroup
                      name='allColumns'
                      choices={allColumnsOpts}
                      automation-id={getAutoId('allColumns')}
                    />
                  </NwProInputRow>

                  {!!showExportAll && (
                    <NwProInputRow label={gettext('Export All Devices')}>
                      <FmkSwitch
                        name='exportAll'
                        automation-id={getAutoId('exportAll')}
                      />
                    </NwProInputRow>
                  )}
                </>
              ) : null}
              {meta?.render ? meta.render(getAutoId) : null}
            </NwProSection>
          </NwProBody>
          <NwProFooter>
            <OkBtn
              onClick={submitForm}
              automation-id={getAutoId('btn-ok')}
              loading={isSubmitting}
            >
              {gettext('OK')}
            </OkBtn>
            <CancelBtn onClick={close} automation-id={getAutoId('btn-cancel')}>
              {gettext('Cancel')}
            </CancelBtn>
          </NwProFooter>
        </>
      )}
    </Formik>
  );
};

export const CsvConfirm = ({
  $opener,
  fileName,
  title,
  meta,
  allowColumnSelection = true,
}) => {
  const fmData = useMemo(() => {
    return {
      fileName: fileName,
      allColumns: MACROS.PM2CAT.PM2_OPT_ENABLE,
      exportAll: false,
    };
  }, []);

  const handleSubmit = (values) => {
    $opener.resolve({
      fileName: values.fileName,
      allColumns: allowColumnSelection
        ? values.allColumns
        : MACROS.PM2CAT.PM2_OPT_ENABLE,
    });
  };
  return (
    <Formik
      initialValues={fmData}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <NwProHeader>{title}</NwProHeader>
          <NwProBody>
            <NwProSection>
              {!meta?.excel ? (
                <>
                  <NwProInputRow label={gettext('File Name')}>
                    <FmkInput
                      name='fileName'
                      clearable
                      automation-id={getAutoId('fileName')}
                    />
                    <FmkErrorSpan name='fileName' />
                  </NwProInputRow>
                  <ConditionalComponent condition={allowColumnSelection}>
                    <NwProInputRow label={gettext('Options')}>
                      <FmkRadioBtnGroup
                        name='allColumns'
                        choices={allColumnsOpts}
                        automation-id={getAutoId('allColumns')}
                      />
                    </NwProInputRow>
                  </ConditionalComponent>
                </>
              ) : null}
            </NwProSection>
          </NwProBody>
          <NwProFooter>
            <OkBtn
              onClick={submitForm}
              automation-id={getAutoId('btn-ok')}
              loading={isSubmitting}
            >
              {gettext('OK')}
            </OkBtn>
            <CancelBtn
              onClick={() => {
                $opener.reject();
              }}
              automation-id={getAutoId('btn-cancel')}
            >
              {gettext('Cancel')}
            </CancelBtn>
          </NwProFooter>
        </>
      )}
    </Formik>
  );
};

function format(val) {
  var ret = val.replace(/"/g, '""');
  var i = ret.length;
  while (i--) {
    var ch = ret.charAt(i);
    if (ch === ',' || ch === '\n') {
      ret = '"' + ret + '"';
      break;
    }
  }
  return ret;
}

// {label: gettext('Name'), key: 'name', toCsvStr: function(item, key, rowIndex, data) }
function work(data, columnDefs, context) {
  return data
    .map(function (item, rowIndex) {
      if (item.isGroup) return;
      return (
        columnDefs
          .map(function (col) {
            var valStr = '';
            if (col.toCsvStr) {
              valStr = col.toCsvStr.call(
                context,
                item,
                col.key,
                rowIndex,
                data,
                col
              );
            } else {
              valStr = item[col.key];
            }
            return format(String(valStr));
          })
          .join(',') + '\n'
      );
    })
    .filter(Boolean);
}

function getHeaders(columnDefs) {
  return (
    columnDefs
      .map(function (col) {
        return format(String(col.label || col.key));
      })
      .join(',') + '\n'
  );
}

function csvBlob(data, columnDefs, context) {
  return new Blob(
    [getHeaders(columnDefs)].concat(work(data, columnDefs, context)),
    { type: 'text/csv;charset=utf-8;' }
  );
}

function downloadBlob(blob, fileName) {
  var linkEl = document.createElement('a');
  linkEl.style.visibility = 'hidden';
  linkEl.setAttribute('download', fileName);
  linkEl.setAttribute('href', URL.createObjectURL(blob));
  document.body.appendChild(linkEl);
  linkEl.click();
  document.body.removeChild(linkEl);
}

const csvConvert = {
  escapeCsvString: format,
  formatTableData: work,
  formatTableHeader: getHeaders,
};

export { csvConvert };
