import { isNil } from 'lodash';

const TXT = {
  devgrp: gettext('Device Group'),
  conn_up: gettext('Connection Up'),
  conn_down: gettext('Connection Down'),
  modeldev: gettext('Model Device'),
};

const fmtStatusIcon = (rowData) => {
  let { css, title } = getStatusIcon(rowData);
  return '<span class="tw-pr-1 ffg ' + css + '" title="' + title + '"></span>';
};

const getStatusIcon = (rowData) => {
  let css = '';
  let title = '';

  if (rowData.devgrp) {
    css = ' ffg-group color-grey';
    title = TXT.devgrp;
  } else {
    let st = -1;
    if (!isNil(rowData.conn_status)) {
      st = rowData.conn_status;
    } else if (rowData.connection) {
      st = rowData.connection.conn;
    }

    switch (st) {
      case 1:
        css = 'ffg-up color-green';
        title = TXT.conn_up;
        break;
      case 2:
        css = 'ffg-down color-orange';
        title = TXT.conn_down;
        break;
      default:
        css = 'ffg-device color-grey';
        title = TXT.modeldev;
        break;
    }
  }
  return { css: css, title: title };
};

const devCollapseEntry = (
  data,
  isExpand,
  preAutoId = '',
  grpMemberMapping = {}
) => {
  let html = '';

  if (data.devgrp) {
    let members = Object.values(grpMemberMapping[data.oid]) || [];
    let memberHtml = '';
    if (isExpand && members.length > 0) {
      members.forEach(function (dev) {
        let iconHtml = fmtStatusIcon(dev?._oData || dev);
        let objs = [];
        if (dev.vdoms && dev.vdoms.length > 0) {
          dev.vdoms.forEach(function (vdom) {
            objs.push({
              ...dev,
              vdom: vdom.name,
            });
          });
        } else {
          objs.push(dev);
        }

        objs.forEach(function (it) {
          memberHtml +=
            '' +
            '<div class="dev-grp-entry" automation-id="' +
            '%s_%s_%s_%s'.printf([
              preAutoId,
              data.name,
              it.name,
              it.vdom || '',
            ]) +
            '">' +
            iconHtml +
            '<span class="entry-text">' +
            it.name +
            (it.vdom ? ' [' + it.vdom + ']' : '') +
            formatGroups(it.groups, 1) +
            '</span>' +
            '</div>';
        });
      });
    }
    html =
      '' +
      '<div class="dev-grp-collapse" attr-idx="' +
      data.idx +
      '">' +
      '<div class="pointer-cursor collapsable not-fire-row-selection-event" automation-id="' +
      '%s_%s'.printf([preAutoId, data.name]) +
      '">' +
      '<span class="tw-pr-1 ffg ' +
      (isExpand ? 'ffg-arrow-down' : 'ffg-arrow-right') +
      '"></span>' +
      fmtStatusIcon(data) +
      '&nbsp;' +
      '<span>' +
      data.name +
      ' (' +
      members.length +
      ')</span>' +
      '</div>' +
      (!isExpand
        ? ''
        : '' +
          '<div class="dev-grp-collapse-container">' +
          memberHtml +
          '</div>') +
      '</div>';
  } else {
    html =
      '<div attr-idx="' +
      data.idx +
      '" automation-id="' +
      '%s:%s'.printf([preAutoId, data.idx]) +
      '">' +
      fmtStatusIcon(data) +
      '&nbsp;' +
      '<span>' +
      data.name +
      '</span>' +
      '</div>';
  }
  return html;
};

const formatGroups = (groups, idxToStart) => {
  if (idxToStart >= groups) return '';
  const SPACER = '&nbsp; &#8594; &nbsp;';
  const slicedGroup = groups.slice(idxToStart, groups.length);

  const content = slicedGroup.reduce((acc, curr, idx) => {
    acc += curr;
    if (idx !== slicedGroup.length - 1) acc += SPACER;
    return acc;
  }, '');

  return content ? ` (${content}) ` : '';
};

export const fiDvmCellFormatterFns = {
  getStatusIcon,
  devCollapseEntry,
  formatGroups,
};
