import { createAction } from '@reduxjs/toolkit';

/**
 * Set the total number of the running tasks.
 *
 * @param {object} { num, group, params }
 */
export const setNum = createAction('task/SET_NUM');

/**
 *  Set the task information.
 *  payload: { id, group, title, percent }
 */
export const setTask = createAction('task/SET_TASK');
export const startFetcher = createAction('task/START_FETCHER');
export const stopFetcher = createAction('task/STOP_FETCHER');
export const fetchTask = createAction('task/FETCH');
