import { useDispatch, useSelector } from 'react-redux';
import { NwProHeader, NwProBody, NwProSection, NwProInputRow } from 'rc_layout';
import { Formik, useFormikContext } from 'formik';
import { FmkErrorSpan, FmkFooter } from 'rc_form';
import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { saveUserTheme } from 'fistore/theme/slice';
import { getUserTheme, getUseGlobalTheme } from 'fistore/theme/selectors';
import { fiSysConfig } from 'fi-session';
import croppie from '@fafm/croppie';
import { DropUploader } from 'rc_drop_uploader';
import { fiMessageBox } from 'fi-messagebox';
import { UserTheme } from './UserTheme';
import $ from 'jquery';
import { saveAvatar } from 'fistore/session/sysConfig/slice';

const getAutoId = (...suffix) => 'sys_change_use_prifile:' + suffix.join('-');

export const ChangeUserProfile = ({ $opener }) => {
  const dispatch = useDispatch();
  const useGlobalTheme = useSelector(getUseGlobalTheme);
  const userTheme = useSelector(getUserTheme);

  const formikRef = useRef();
  const handleSubmit = useCallback((values) => {
    Promise.all([
      values.avatar
        ? dispatch(saveAvatar({ avatar: values.avatar })).unwrap()
        : null,
      dispatch(
        saveUserTheme({
          useGlobalTheme: values.useGlobalTheme,
          userTheme: values.userTheme,
        })
      ),
    ]).then(
      ([resp1]) => {
        $opener.resolve(resp1 ? values.avatar : false);
      },
      (status) => {
        fiMessageBox.show(
          status?.message || gettext('Unknown error.'),
          'danger'
        );
        formikRef.current?.setSubmitting(false);
      }
    );
  }, []);

  const close = useCallback(() => {
    $opener.reject();
  }, []);

  return (
    <Formik
      initialValues={{ useGlobalTheme, userTheme }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      <NwProHeader>{gettext('Change Profile')}</NwProHeader>
      <NwProBody>
        <NwProSection>
          <NwProInputRow label={gettext('Change Avatar')}>
            <AvatarLoader />
            <FmkErrorSpan name='avatar' />
          </NwProInputRow>
          <UserTheme></UserTheme>
        </NwProSection>
      </NwProBody>
      <FmkFooter
        canWrite={true}
        getAutoId={() => getAutoId('buttons-')}
        onCancel={close}
      />
    </Formik>
  );
};

const AVATAR_SIZE = 64;
const AVATAR_CONTAINER_SIZE = 300;
const MAX_PHOTO_SIZE = 10240000; //10M

const AvatarLoader = () => {
  const { setFieldValue, setFieldError, setFieldTouched } = useFormikContext();

  const [photoLoaded, setPhotoLoaded] = useState(false);
  const reader = useMemo(() => new FileReader(), []);
  const croppRef = useRef();

  useEffect(() => {
    const maxAvatarSize = fiSysConfig.current().max_avatar_size - 12; // For FF
    if (!croppRef.current) {
      const cropp = (croppRef.current = createCropp(() => {
        getAvatar(
          maxAvatarSize,
          cropp
        )(1)
          .then((data) => {
            setFieldValue('avatar', data);
          })
          .catch((error) => {
            setFieldError('avatar', error.message);
          });
      }));
      croppRef.current = cropp;
      reader.onload = function (evt) {
        // show the croppie first
        setPhotoLoaded(true);
        cropp.bind({ url: evt.target.result, zoom: 0 });
      };
    }
  }, []);

  const filePondRef = useRef(null);
  // eslint-disable-next-line
  const onAddFile = useCallback((e, setFieldValue) => {
    const file = e.detail?.file;
    // file type
    if (file?.type?.indexOf('image/') !== 0) {
      setFieldTouched('avatar', true);
      setFieldError('avatar', gettext('This is not an image file.'));
      return;
    }
    // check file size
    if (file.size > MAX_PHOTO_SIZE) {
      setFieldTouched('avatar', true);
      setFieldError('avatar', gettext('The file size is too big.'));
      return;
    }
    setPhotoLoaded(false);
    validateImage(file).then(function () {
      reader.readAsDataURL(file);
    });
  }, []);

  return (
    <div>
      <DropUploader
        ref={filePondRef}
        onProcess={(e) => onAddFile(e, setFieldValue)}
      />
      <div className={photoLoaded ? 'tw-block' : 'tw-hidden'}>
        <img id='cropper_avatar' className='tw-hidden' />
      </div>
    </div>
  );
};

const createCropp = (onUpdate) => {
  const croppEl = $('#cropper_avatar').get(0);
  return new croppie(croppEl, {
    viewport: {
      type: 'square',
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    boundary: {
      width: AVATAR_CONTAINER_SIZE,
      height: AVATAR_CONTAINER_SIZE,
    },
    update: onUpdate,
  });
};

const getAvatar = (maxAvatarSize, cropp) =>
  async function _getAvatar(ratio = 1.0) {
    const based64 = await cropp.result({
      type: 'base64',
      format: 'jpeg', //'png',
      size: {
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
      },
      quality: ratio,
    });
    const data = based64.substr(based64.indexOf('base64,') + 7);
    if (data.length > maxAvatarSize) {
      if (ratio <= 0.1) {
        throw new Error('Unable to generate avatar within size limits');
      }
      return await _getAvatar(ratio - 0.1);
    }
    return data;
  };

function validateImage(obj) {
  return new Promise(function (resolve, reject) {
    var URL = window.URL || window.webkitURL;
    var img = new Image();
    img.src = URL.createObjectURL(obj);
    img.onload = function () {
      URL.revokeObjectURL(this.src);
      resolve(obj);
    };
    img.onerror = function () {
      URL.revokeObjectURL(this.src);
      reject(obj);
    };
  });
}
