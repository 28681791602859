import { useCallback, useMemo } from 'react';
import { useValidEffect } from 'rh_util_hooks';
import { fiAdom } from 'fi-session';
import { get, isFunction, isNil, noop } from 'lodash';
import {
  dispatch,
  fiDevicesAction,
  fiDevicesSelector,
  fiSession,
} from 'fistore';
import { useSelector } from 'react-redux';

export const useReduxDeviceList = ({
  adomOid = fiAdom.current().oid,
  reducerFn = noop,
  accumulator,
}) => {
  const getDevicesByAdom = (state) =>
    get(state, ['dvm', 'devices', adomOid], {});
  const devicesReady = useSelector((state) => {
    const devs = getDevicesByAdom(state);
    return devs && devs.loaded && !devs.loading;
  });
  const devices = useSelector((state) =>
    Object.values(getDevicesByAdom(state)?.byId || {})
  );

  useValidEffect(() => {
    if (!devicesReady) {
      dispatch(fiDevicesAction.fetchDevicesAction());
    }
  }, [adomOid]);

  return useCallback(() => {
    if (isFunction(reducerFn) && !isNil(accumulator)) {
      return devices.reduce(reducerFn, accumulator);
    }

    return devices;
  }, [devices]);
};

export const useFortiSaseDeviceList = () => {
  const adomOid = useSelector(fiSession.getSessionAdomOid);
  const loaded = useSelector(fiDevicesSelector.getSaseDevicesLoaded);
  const list = useSelector(fiDevicesSelector.getSaseDevicesList);

  useValidEffect(() => {
    if (!loaded && !list?.length) {
      dispatch(fiDevicesAction.fetchSaseDevices());
    }
  }, [adomOid]);

  return {
    loaded,
    list,
  };
};

export const useVdomsFromRedux = ({ adomOid, deviceOid }) => {
  const path = useMemo(
    () => `dvm.vdoms.${adomOid}-${deviceOid}.byId`,
    [adomOid, deviceOid]
  );

  const vdoms = useSelector((state) => {
    return Object.values(get(state, path) || {});
  });

  return {
    vdoms,
    getVdomByName: (name, attr) => {
      const foundVdom = vdoms.find((vdom) => vdom.name === name);
      if (!foundVdom) return null;
      if (!isNil(attr)) return get(foundVdom, attr);
      return foundVdom;
    },
  };
};
