import {
  fetchRemoteFazModuleDisable,
  setRemoteFazModuleDisable,
} from './actions';

const initialState = {
  loaded: false,
  data: {},
};

function fmgRemoteFazReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case fetchRemoteFazModuleDisable.type:
      return {
        ...state,
        loaded: false,
      };

    case setRemoteFazModuleDisable.type:
      return {
        ...state,
        loaded: true,
        data: payload,
      };

    default:
      return state;
  }
}

export const reducer = fmgRemoteFazReducer;
