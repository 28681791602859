import { fiSysConfig, fiAdminProfile } from 'fi-session';
import { isObject } from 'lodash';
import { NwButton, NwSpinner, NwAlert } from '@fafm/neowise-core';
import { ProForm } from '@fafm/neowise-pro';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { getIsHaSlave } from 'fistore/session/sysConfig/selectors';
import { useWizard } from 'rc_wizard';
import { getTabAutoId } from '../tab_utils';
import { setSkippedStep } from '../../modal/utils';
import React, { useEffect, useState } from 'react';
import {
  ScheduleBackupAsSection,
  setSchedule,
} from '../../../../ra_dashboard/widgets/system_widgets/systemInfo/ScheduleBackup';
import { showErrorMessage } from 'fi-messagebox';
import { setStatus } from './apis';
import { adomRevRequest } from '../../../../ra_pno_services/adom_revision/request';
import {
  RevisionSettingAsSection,
  settingParser,
  valueConvert,
} from '../../../../ra_pno_services/adom_revision/AdomRevisionSetting';
const { Body, Footer } = ProForm;

export const BackupStrategy = ({ scheduleBackup: initialBackup }) => {
  const getAutoId = getTabAutoId('backup_strategy');
  const { goToNext } = useWizard();
  const isHaSlave = useSelector(getIsHaSlave);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (!fiAdminProfile.isSuperAdmin() && !fiSysConfig.hasFmgFeature())
      goToNext();
  }, []);

  const strategies = [
    fiAdminProfile.isSuperAdmin()
      ? {
          key: 'backup',
          useData: () => {
            useEffect(() => {
              setInitialValues((prev) => ({
                ...prev,
                backup: {
                  ...initialBackup,
                  // Linda feedback: set it to enable by default and force admin to set up schedule backup
                  status: MACROS.PM2CAT.PM2_OPT_ENABLE,
                },
              }));
            }, []);
          },
          render: ({ values }) => (
            <ScheduleBackupAsSection
              initialValues={{ backup: initialBackup }}
              values={values}
              autoId={(field) => getAutoId(`backup:${field}`)}
              field={(attr) => `backup.${attr}`}
            />
          ),
          submitValues: ({ backup }) => {
            return setSchedule(backup, initialBackup);
          },
        }
      : null,
    fiSysConfig.hasFmgFeature()
      ? {
          key: 'rev',
          useData: () => {
            useEffect(() => {
              adomRevRequest.getSettings().then((data) => {
                setInitialValues((prev) => ({
                  ...prev,
                  rev: settingParser(data),
                }));
              });
            }, []);
          },
          render: ({ values }) => (
            <RevisionSettingAsSection
              values={values}
              autoId={(field) => getAutoId(`revision:${field}`)}
              field={(attr) => `rev.${attr}`}
            />
          ),
          submitValues: ({ rev }) => {
            return adomRevRequest.updateSettings(valueConvert(rev));
          },
        }
      : null,
  ].filter(isObject);

  strategies.forEach((app) => app.useData());
  const loading = strategies.some((app) => !initialValues[app.key]);

  const handleSubmit = async (values) => {
    try {
      await Promise.all(strategies.map((app) => app.submitValues(values)));
      await setStatus(true);
      goToNext();
    } catch (ex) {
      showErrorMessage(ex);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, values }) => (
        <>
          {loading ? (
            <Body className='tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center'>
              <NwSpinner style={{ fontSize: '4rem', textAlign: 'center' }} />
            </Body>
          ) : (
            <Body>
              {isHaSlave ? (
                <div className='tw-pb-2'>
                  <NwAlert type='caution' open>
                    {gettext(
                      'Backup Strategy cannot be set up on HA Secondary unit. Please complete it on HA Primary.'
                    )}
                  </NwAlert>
                </div>
              ) : null}
              {strategies.map((app) => (
                <React.Fragment key={app.key}>
                  {app.render({ values })}
                </React.Fragment>
              ))}
            </Body>
          )}
          <Footer>
            {!isHaSlave ? (
              <NwButton
                onClick={submitForm}
                loading={loading || isSubmitting}
                automation-id={getAutoId('next-btn')}
                type={'primary'}
                className={'tw-min-w-32'}
              >
                {gettext('Next') + ' >'}
              </NwButton>
            ) : null}
            <NwButton
              onClick={() => {
                setSkippedStep();
                goToNext();
              }}
              automation-id={getAutoId('later-btn')}
              className={'tw-min-w-32'}
              type={'default'}
            >
              {gettext('Later')}
            </NwButton>
          </Footer>
        </>
      )}
    </Formik>
  );
};
