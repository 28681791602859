import goog from '@fafm/goog';

// these constant are goog integer object, it is not integer.
const IP_CLASS_A_START_INT = (function () {
  const ip = new goog.net.Ipv4Address('1.0.0.1');
  return ip.toInteger();
})();
const IP_CLASS_A_END_INT = (function () {
  const ip = new goog.net.Ipv4Address('126.255.255.254');
  return ip.toInteger();
})();
const IP_CLASS_B_START_INT = (function () {
  const ip = new goog.net.Ipv4Address('128.1.0.1');
  return ip.toInteger();
})();
const IP_CLASS_B_END_INT = (function () {
  const ip = new goog.net.Ipv4Address('191.255.225.254');
  return ip.toInteger();
})();
const IP_CLASS_C_START_INT = (function () {
  const ip = new goog.net.Ipv4Address('192.0.1.1');
  return ip.toInteger();
})();
const IP_CLASS_C_END_INT = (function () {
  const ip = new goog.net.Ipv4Address('223.255.255.254');
  return ip.toInteger();
})();
const IP_CLASS_D_START_INT = (function () {
  const ip = new goog.net.Ipv4Address('224.0.0.0');
  return ip.toInteger();
})();
const IP_CLASS_D_END_INT = (function () {
  const ip = new goog.net.Ipv4Address('239.255.255.255');
  return ip.toInteger();
})();
const IP_CLASS_E_START_INT = (function () {
  const ip = new goog.net.Ipv4Address('240.0.0.0');
  return ip.toInteger();
})();
const IP_CLASS_E_END_INT = (function () {
  const ip = new goog.net.Ipv4Address('254.255.255.255');
  return ip.toInteger();
})();
const IP_LOOPBACK_START_INT = (function () {
  const ip = new goog.net.Ipv4Address('127.0.0.0');
  return ip.toInteger();
})();
const IP_LOOPBACK_END_INT = (function () {
  const ip = new goog.net.Ipv4Address('127.255.255.255');
  return ip.toInteger();
})();

const IPClassChecker = {
  A: function (intVal) {
    return (
      intVal.greaterThanOrEqual(IP_CLASS_A_START_INT) &&
      intVal.lessThanOrEqual(IP_CLASS_A_END_INT)
    );
  },
  B: function (intVal) {
    return (
      intVal.greaterThanOrEqual(IP_CLASS_B_START_INT) &&
      intVal.lessThanOrEqual(IP_CLASS_B_END_INT)
    );
  },
  C: function (intVal) {
    return (
      intVal.greaterThanOrEqual(IP_CLASS_C_START_INT) &&
      intVal.lessThanOrEqual(IP_CLASS_C_END_INT)
    );
  },
  D: function (intVal) {
    return (
      intVal.greaterThanOrEqual(IP_CLASS_D_START_INT) &&
      intVal.lessThanOrEqual(IP_CLASS_D_END_INT)
    );
  },
  E: function (intVal) {
    return (
      intVal.greaterThanOrEqual(IP_CLASS_E_START_INT) &&
      intVal.lessThanOrEqual(IP_CLASS_E_END_INT)
    );
  },
  L: function (intVal) {
    return (
      intVal.greaterThanOrEqual(IP_LOOPBACK_START_INT) &&
      intVal.lessThanOrEqual(IP_LOOPBACK_END_INT)
    );
  },
};

/**
 * Checks if a string is a valid IP v4 or v6
 * @param {String} value - string to be checked
 * @return {Ipv4Address|Ipv6Address} IP v4 or v6 object in google closure libary
 */
export function validIP(value) {
  let ip = '';
  try {
    ip = goog.net.IpAddress.fromString(value);
  } catch (e) {
    /**/
  }
  if (!ip) {
    return false;
  }
  return ip;
}

/**
 * Checks if a string is a valid IP v4
 * @param {String} value - string to be checked
 * @return {Ipv4Address|Ipv6Address} IP v4 object in google closure libary
 */
export function validIp4(value) {
  let ip = null;
  try {
    ip = new goog.net.Ipv4Address(value);
  } catch (e) {
    return null;
  }

  if (!ip) {
    return null;
  }
  return ip;
}

/**
 * Checks if a string is a valid IP v6
 * @param {String} value - string to be checked
 * @return {Ipv4Address|Ipv6Address} IP 6 object in google closure libary
 */
export function validIp6(value) {
  let ip = null;
  try {
    ip = new goog.net.Ipv6Address(value);
  } catch (e) {
    return null;
  }
  if (!ip) {
    return null;
  }
  return ip;
}

/**
 * Checks if a string is a valid IPv6 and netmask
 * @param {String} value - string to be checked such as :fe80::6ae3:b5ff:fe92:330e/128 or 2001:db8:0:160::/ffff:ffff:ffff:ffff::
 * @return ip or null
 */
export function validIp6Net(value) {
  let ip = null;
  const reg_INT = new RegExp(/^[0-9]+$/);
  const IPv6_MASK =
    /^(((ffff:){1,6}(ffff|fffe|fffc|fff8|fff0|ffe0|ffc0|ff80|ff00|fe00|fc00|f800|f000|e000|c000|8000|0{1,4})::)|((ffff:){7}(ffff|fffe|fffc|fff8|fff0|ffe0|ffc0|ff80|ff00|fe00|fc00|f800|f000|e000|c000|8000|0{1,4}))|((ffff|fffe|fffc|fff8|fff0|ffe0|ffc0|ff80|ff00|fe00|fc00|f800|f000|e000|c000|8000)::))$/;

  try {
    // remove the length.
    // find the last "/", and only take first part
    let index = value.lastIndexOf('/');
    if (index != -1) {
      const mask = value.substring(index + 1);
      const n = parseInt(mask, 10);
      if (isNaN(n) || !mask.match(reg_INT)) {
        if (!mask.match(IPv6_MASK)) {
          return null;
        }
      } else if (n > 128 || n <= 0) {
        return null;
      }
    } else {
      index = value.length;
    }

    const str = value.substring(0, index);
    ip = new goog.net.Ipv6Address(str);
  } catch (e) {
    return null;
  }
  if (!ip) {
    return null;
  }
  return ip;
}

/**
 * get IP or netmask from IP/netmask string
 * @param {String} value - IP/netmask string
 *
 */
export function getIpNetMask(modelValue) {
  let result_ip = '';
  let result_mask = '';

  if (modelValue) {
    const regexp = new RegExp(/\/(\d+$)/);
    const IP_SUBNET =
      /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\/((((254|252|248|240|224|192|128|0)\.0\.0\.0)|(255\.(254|252|248|240|224|192|128|0)\.0\.0)|(255\.255\.(254|252|248|240|224|192|128|0)\.0)|(255\.255\.255\.(255|254|252|248|240|224|192|128|0))|(3[0-2]|2[0-9]|1[0-9]|[0-9])))$/;

    const isValid = modelValue.match(IP_SUBNET);
    if (isValid) {
      // check if the input match the format '/number'
      const matched = modelValue.match(regexp);
      if (matched) {
        // converts the mask to the format of *.*.*.*
        const ipmask = new goog.net.Ipv4Address('255.255.255.255');
        const mask = ipmask.toInteger();
        const op = mask.shiftRight(matched.pop());
        const newIp = new goog.net.Ipv4Address(op.xor(mask));
        const tmpIpMskStr = newIp.toString();

        result_ip = modelValue.split('/')[0];
        result_mask = tmpIpMskStr;
      } else {
        result_ip = modelValue.split('/')[0];
        result_mask = modelValue.split('/')[1];
      }
    }
  }
  return [result_ip, result_mask];
}

/**
 * Validates if the destination IP is bigger than the source IP
 * @param {String|IPv4Address|IPv6Address} ipFrom - source IP
 * @param {String|IPv4Address|IPv6Address} ipTo - destination IP
 * @return {Boolean} true if the range is valid.
 */
export function validIPRange(ipFrom, ipTo) {
  ipFrom = typeof ipFrom === 'string' ? validIP(ipFrom) : ipFrom;
  ipTo = typeof ipTo === 'string' ? validIP(ipTo) : ipTo;
  if (!ipFrom || !ipTo) {
    return false;
  }
  if (ipFrom.getVersion() !== ipTo.getVersion()) {
    return false;
  }
  if (ipFrom.toInteger().greaterThan(ipTo.toInteger())) {
    return false;
  }
  return true;
}

/**
 * determine if given IPv4 address could be a valid public address.
 * @param {String} IPv4 address
 * @type Bool
 */
export function isPublicIPv4(address) {
  const ip = validIP(address);
  if (!ip || ip.getVersion() !== 4) {
    // not a valid IP v4 address
    return false;
  }
  const IpMask = {};
  IpMask.toDotted = function (addr) {
    const ip_mask = addr.split('/');
    const fields = ip_mask[0].split('.');
    const ip = [];
    for (let ii = 0; ii < fields.length; ii++) {
      ip.push(parseInt(fields[ii]));
    }

    return ip;
  };

  const octets = IpMask.toDotted(address);
  // 0.0.0.0/8 (RFC 1700)
  if (octets[0] === 0) {
    return false;
  }

  // 10.0.0.0/8 (RFC 1918)
  if (octets[0] === 10) {
    return false;
  }

  // 127.0.0.0/8 (RFC 990)
  if (octets[0] === 127) {
    return false;
  }

  // 169.254.0.0/16 (RFC 3927)
  if (octets[0] === 169 && octets[1] === 254) {
    return false;
  }

  // 172.16.0.0/12 (RFC 1918)
  if (octets[0] === 172 && octets[1] >= 16 && octets[1] <= 32) {
    return false;
  }

  // 192.0.0.0/24 (RFC 5736)
  if (octets[0] === 192 && octets[1] === 0 && octets[2] === 0) {
    return false;
  }

  // 192.0.2.0/24 (RFC 5737)
  if (octets[0] === 192 && octets[1] === 0 && octets[2] === 2) {
    return false;
  }

  // 192.168.0.0/16 (RFC 1918)
  if (octets[0] === 192 && octets[1] === 168) {
    return false;
  }

  // 224.0.0.0/4 (RFC 5771)
  if (octets[0] >= 224 && octets[0] <= 239) {
    return false;
  }

  return true;
}

/**
 * convert netmask from number to string input 24 output '255.255.255.0'
 * @param {Number} IPv4 netmask number
 * @return string
 */
export function toNetmaskString(netmask) {
  if (Number.isInteger(parseFloat(netmask))) {
    const number = parseInt(netmask);
    if (typeof number === 'number' && number <= 32 && number >= 0) {
      const arr = [
        '0.0.0.0',
        '128.0.0.0',
        '192.0.0.0',
        '224.0.0.0',
        '240.0.0.0',
        '248.0.0.0',
        '252.0.0.0',
        '254.0.0.0',
        '255.0.0.0',
        '255.128.0.0',
        '255.192.0.0',
        '255.224.0.0',
        '255.240.0.0',
        '255.248.0.0',
        '255.252.0.0',
        '255.254.0.0',
        '255.255.0.0',
        '255.255.128.0',
        '255.255.192.0',
        '255.255.224.0',
        '255.255.240.0',
        '255.255.248.0',
        '255.255.252.0',
        '255.255.254.0',
        '255.255.255.0',
        '255.255.255.128',
        '255.255.255.192',
        '255.255.255.224',
        '255.255.255.240',
        '255.255.255.248',
        '255.255.255.252',
        '255.255.255.254',
        '255.255.255.255',
      ];
      return arr[netmask];
    }
  }
  return null;
}

/**
 * convert netmask from string to number input '255.255.255.0' output 24
 * @param {String} IPv4 netmask string
 * @return number
 */
export function toNetmaskNumber(netmask) {
  if (typeof netmask === 'string') {
    const arr = [
      '0.0.0.0',
      '128.0.0.0',
      '192.0.0.0',
      '224.0.0.0',
      '240.0.0.0',
      '248.0.0.0',
      '252.0.0.0',
      '254.0.0.0',
      '255.0.0.0',
      '255.128.0.0',
      '255.192.0.0',
      '255.224.0.0',
      '255.240.0.0',
      '255.248.0.0',
      '255.252.0.0',
      '255.254.0.0',
      '255.255.0.0',
      '255.255.128.0',
      '255.255.192.0',
      '255.255.224.0',
      '255.255.240.0',
      '255.255.248.0',
      '255.255.252.0',
      '255.255.254.0',
      '255.255.255.0',
      '255.255.255.128',
      '255.255.255.192',
      '255.255.255.224',
      '255.255.255.240',
      '255.255.255.248',
      '255.255.255.252',
      '255.255.255.254',
      '255.255.255.255',
    ];
    for (let i = 0; i < arr.length; ++i) {
      if (arr[i] === netmask) {
        return i;
      }
    }
  }
  return -1;
}

/*
 * generate ip range based on given ip and netmask
 * The range should exclude the IP that passing in
 * @param {String} ip - given IP
 * @param {String} netmask - given netmask
 * @param {Boolean} isIncludeGivenIp - include given IP in IP range or not, default value is false
 * @return array [{start-ip: '', end-ip: ''}, ...]
 */
export function getIpRange(ip, netmask, isIncludeGivenIp = false) {
  let rs = [];
  try {
    let min, max;
    const goog_ip = new goog.net.Ipv4Address(ip);
    const goog_netmask = new goog.net.Ipv4Address(netmask);
    const goog_base = new goog.net.Ipv4Address('0.0.0.1');
    const goog_broadcast = new goog.net.Ipv4Address('255.255.255.255');

    const goog_range_base = new goog.net.Ipv4Address(
      goog_ip.toInteger().and(goog_netmask.toInteger())
    );
    const goog_diff = new goog.net.Ipv4Address(
      goog_broadcast.toInteger().subtract(goog_netmask.toInteger())
    );
    if (goog_diff.toInteger().isZero()) {
      rs.push({
        'start-ip': goog_diff.toString(),
        'end-ip': goog_diff.toString(),
      });
      return rs;
    }

    const range_min = new goog.net.Ipv4Address(
      goog_range_base.toInteger().add(goog_base.toInteger())
    );
    const range_max = new goog.net.Ipv4Address(
      goog_range_base
        .toInteger()
        .add(goog_diff.toInteger().subtract(goog_base.toInteger()))
    );

    if (
      isIncludeGivenIp ||
      goog_ip.toInteger().lessThan(range_min.toInteger()) ||
      goog_ip.toInteger().greaterThan(range_max.toInteger())
    ) {
      rs.push({
        'start-ip': range_min.toString(),
        'end-ip': range_max.toString(),
      });
      return rs;
    } else if (goog_ip.toInteger().equals(range_min.toInteger())) {
      min = new goog.net.Ipv4Address(
        range_min.toInteger().add(goog_base.toInteger())
      );
      rs.push({
        'start-ip': min.toString(),
        'end-ip': range_max.toString(),
      });
      return rs;
    } else if (goog_ip.toInteger().equals(range_max.toInteger())) {
      max = new goog.net.Ipv4Address(
        range_max.toInteger().subtract(goog_base.toInteger())
      );
      rs.push({
        'start-ip': range_min.toString(),
        'end-ip': max.toString(),
      });
      return rs;
    } else {
      min = new goog.net.Ipv4Address(
        goog_ip.toInteger().subtract(goog_base.toInteger())
      );
      max = new goog.net.Ipv4Address(
        goog_ip.toInteger().add(goog_base.toInteger())
      );
      rs = [
        {
          'start-ip': range_min.toString(),
          'end-ip': min.toString(),
        },
        {
          'start-ip': max.toString(),
          'end-ip': range_max.toString(),
        },
      ];
      return rs;
    }
    // eslint-disable-next-line
  } catch (e) {
    // since this function was used in watch
    // it always be triggered during input.
    // and make console messy
    return rs;
  }
}

/**
 *
 * @param {integer} ip integer of IPv4 address
 * @param {string} classIDstr string of class name like: 'ABCDL', default is 'ABC'
 *                            L is reserved for loopback and diagnostic functions.
 *                            D is Reserved for Multicasting
 *                            E is Experimental; used for research
 */
export function isIPClass(ip, classIDstr) {
  const ipObj = validIP(ip);
  if (false == ipObj) {
    return false;
  }
  let tocheck = classIDstr || 'ABC';
  tocheck = tocheck.toUpperCase();

  const intVal = ipObj.toInteger();

  for (let ii = 0; ii < tocheck.length; ii++) {
    const fn = IPClassChecker[tocheck[ii]];
    if (fn && fn(intVal)) {
      return true;
    }
  }

  return false;
}

/**
 * Returns a goog.math.Integer which has binary string only consists of given
 * number of "1"s.
 */
function getNumOnes(num) {
  const Int = goog.math.Integer;
  let sum = Int.ZERO;
  for (let i = 0; i < num; i++) {
    sum = sum.add(Int.ONE.shiftLeft(i));
  }
  return sum;
}

/**
 * Given a string like "192.168.0.0/24" or "2000::/64", convert it to
 * start and end value
 * @param {string} CIDR notation of a network range.
 * @return [{Ipv4Address|Ipv6Address},{Ipv4Address|Ipv6Address}] Inclusive start and
 * end. Also attached as [].start and [].end properties on the array.
 */
export function cidrToRange(cidrStr) {
  const inputArr = cidrStr.split('/').map((x) => x.trim());
  if (inputArr.length !== 2) {
    throw new Error(gettext('Invalid data'));
  }
  const ip = goog.net.IpAddress.fromString(inputArr[0]);
  if (!ip) {
    throw new Error(gettext('Invalid IP address.'));
  }
  let IpClass = goog.net.Ipv6Address;
  if (ip instanceof goog.net.Ipv4Address) {
    IpClass = goog.net.Ipv4Address;
  }
  let mask = parseInt(inputArr[1], 10);
  if ('' + mask != inputArr[1]) {
    mask = NaN;
  }
  const maxBits = IpClass.MAX_NETMASK_LENGTH;
  const obsoleteMask = goog.net.IpAddress.fromString(inputArr[1]);
  // x.x.x.x/y.y.y.y style ipv4 notation
  if (IpClass === goog.net.Ipv4Address && obsoleteMask) {
    // o
    const bits = obsoleteMask.toInteger().getBits(0); // 32 bits per getBits, so for ipv4 we only care about position zero.
    mask = 0;
    let seenZero = false;
    let pos;
    for (pos = 31; pos >= 0; --pos) {
      const bitMask = 1 << pos;
      if (bitMask & bits) {
        if (seenZero) {
          mask = NaN;
          break; // can't be valid mask if there's 1 after any zero.
        } else {
          mask++; // never seen a zero, so this 1 is valid count.
        }
      } else {
        seenZero = true;
      }
    }
  }
  if (mask < 0 || mask > IpClass.MAX_NETMASK_LENGTH || !isFinite(mask)) {
    throw new Error(gettext('Invalid mask'));
  }

  const ipInt = ip.toInteger();
  const start = new IpClass(
    ipInt.and(getNumOnes(mask).shiftLeft(maxBits - mask))
  );
  const end = new IpClass(ipInt.or(getNumOnes(maxBits - mask)));
  const ret = [start, end];
  ret.start = start;
  ret.end = end;
  return ret;
}

// https://tools.ietf.org/html/rfc4291#section-2.5.5.1
export const ipv4CompatibleIpv6AddrStart = goog.net.IpAddress.fromString('::');
export const ipv4CompatibleIpv6AddrEnd =
  goog.net.IpAddress.fromString('::255.255.255.255');
// https://tools.ietf.org/html/rfc4291#section-2.5.5.2
export const ipv4MappedIpv6AddrStart =
  goog.net.IpAddress.fromString('::ffff:0.0.0.0');
export const ipv4MappedIpv6AddrEnd = goog.net.IpAddress.fromString(
  '::ffff:255.255.255.255'
);

export function isIpv4CompatibleIpv6Addr(ipv6addr) {
  if (ipv6addr.getVersion() !== 6) return false;
  const ipv6int = ipv6addr.toInteger();
  return (
    ipv4CompatibleIpv6AddrStart.toInteger().lessThanOrEqual(ipv6int) &&
    ipv4CompatibleIpv6AddrEnd.toInteger().greaterThanOrEqual(ipv6int)
  );
}

function stringToIp(str) {
  if (typeof str === 'string') {
    return goog.net.IpAddress.fromString(str);
  }
  return str;
}

export function isIpv4MappedIpv6Addr(ipv6addr) {
  ipv6addr = stringToIp(ipv6addr);
  if (ipv6addr.getVersion() !== 6) return false;
  const ipv6int = ipv6addr.toInteger();
  return (
    ipv4MappedIpv6AddrStart.toInteger().lessThanOrEqual(ipv6int) &&
    ipv4MappedIpv6AddrEnd.toInteger().greaterThanOrEqual(ipv6int)
  );
}

const ipv4MappedIpv6Mask =
  goog.net.IpAddress.fromString('::255.255.255.255').toInteger();

export function ipv4MappedIpv6AddrToIpv4(ipv6addr) {
  ipv6addr = stringToIp(ipv6addr);
  return new goog.net.Ipv4Address(ipv6addr.toInteger().and(ipv4MappedIpv6Mask));
}

function isInRangeAddrInt(ipInt, ipStartInt, ipEndInt) {
  return (
    ipStartInt.lessThanOrEqual(ipInt) && ipEndInt.greaterThanOrEqual(ipInt)
  );
}

/**
 *
 * @param {goog.net.IpAddress} ip value to check
 * @param {goog.net.IpAddress} start range start, inclusive
 * @param {goog.net.IpAddress} end range end, inclusive
 */
export function ipInRangeAddr(ip, start, end) {
  const allInputAddr = [start, end, ip];
  if (allInputAddr.some((x) => !x)) {
    return false;
  }
  const [startInt, endInt, ipInt] = allInputAddr.map((x) => x.toInteger());
  if (start.getVersion() !== end.getVersion()) {
    return false;
  }
  if (
    (ip.getVersion() === 4 ||
      isIpv4CompatibleIpv6Addr(ip) ||
      isIpv4MappedIpv6Addr(ip)) &&
    isIpv4MappedIpv6Addr(start) &&
    isIpv4MappedIpv6Addr(end)
  ) {
    const ipInt2 = ipv4MappedIpv6AddrToIpv4(ip).toInteger();
    const startInt2 = ipv4MappedIpv6AddrToIpv4(start).toInteger();
    const endInt2 = ipv4MappedIpv6AddrToIpv4(end).toInteger();
    return isInRangeAddrInt(ipInt2, startInt2, endInt2);
  } else if (
    (isIpv4MappedIpv6Addr(ip) || isIpv4CompatibleIpv6Addr(ip)) &&
    start.getVersion() === 4 &&
    end.getVersion() === 4
  ) {
    return isInRangeAddrInt(
      ipv4MappedIpv6AddrToIpv4(ip).toInteger(),
      startInt,
      endInt
    );
  } else if (isIpv4CompatibleIpv6Addr(start) && isIpv4CompatibleIpv6Addr(end)) {
    return isInRangeAddrInt(ipInt, startInt, endInt);
  } else if (ip.getVersion() !== start.getVersion()) {
    return false;
  }
  return isInRangeAddrInt(ipInt, startInt, endInt);
}

export function strToIpRange(rangeStr) {
  let start, end;
  if (typeof rangeStr !== 'string') {
    throw new Error('Invalid IpRange data type');
  }
  if (rangeStr.indexOf('-') > 0) {
    [start, end] = rangeStr
      .split('-')
      .map((x) => x.trim())
      .map(goog.net.IpAddress.fromString);
  } else if (rangeStr.indexOf('/') > 0) {
    try {
      [start, end] = cidrToRange(rangeStr);
      // eslint-disable-next-line
    } catch (ex) {
      return false;
    }
  } else if (goog.net.IpAddress.fromString(rangeStr)) {
    // eslint-disable-next-line
    start = end = goog.net.IpAddress.fromString(rangeStr);
  } else {
    throw new Error('Invalid IpRange data type');
  }
  return [start, end];
}

/**
 *
 * @param {string|goog.net.IpAddress} ip string or instance
 * @param {string|goog.net.IpAddress} Stringip range of start and end addresses, separated by "-", OR, the start goog.net.IpAddress
 * @param {goog.net.IpAddress} Optional end address, if the second parameter is
 * start address instance.
 */
export function ipInRange(ipStr, rangeStr) {
  const ip =
    ipStr instanceof goog.net.IpAddress
      ? ipStr
      : goog.net.IpAddress.fromString(ipStr);
  if (!ip) {
    return false;
  }
  let start, end;
  if (arguments[1] instanceof goog.net.IpAddress) {
    start = arguments[1];
    if (arguments[2] instanceof goog.net.IpAddress) {
      end = arguments[2];
    } else if (!arguments[2]) {
      end = start;
    }
  }

  if (!start && !end) {
    try {
      [start, end] = strToIpRange(rangeStr);
      // eslint-disable-next-line
    } catch (ex) {
      return false;
    }
  }
  return ipInRangeAddr(ip, start, end);
}

export function isSubnetFromRange(start, end) {
  let sIP, eIP;
  try {
    sIP =
      start instanceof goog.net.IpAddress
        ? start
        : new goog.net.Ipv4Address(start);
    eIP =
      end instanceof goog.net.IpAddress ? end : new goog.net.Ipv4Address(end);
  } catch (e) {
    return false;
  }

  const min = sIP.toInteger();
  const max = eIP.toInteger();
  if (max.lessThan(min)) {
    return false;
  }
  if (max.equals(min)) {
    return true;
  }

  const range_sz = max - min;
  const bits_cnt = count_bits(range_sz);
  let mask = 0;

  /* the mask is determined by the size of the range */
  if (bits_cnt == 32) {
    mask = 0;
  } else {
    mask = 0xffffffff << bits_cnt;
  }

  if ((min & mask) != (max & mask)) {
    return false;
  }

  return true;
}

export function isSubnetFromRange6(start, end) {
  let sIP, eIP;
  try {
    sIP =
      start instanceof goog.net.Ipv6Address
        ? start
        : new goog.net.Ipv6Address(start);
    eIP =
      end instanceof goog.net.Ipv6Address ? end : new goog.net.Ipv6Address(end);
  } catch (e) {
    return false;
  }
  const min = sIP.toInteger();
  const max = eIP.toInteger();
  if (max.lessThan(min)) {
    return false;
  }
  if (max.equals(min)) {
    return true;
  }

  // if the length of prefix is different is not a subnet
  const max_sz = count_bits(max.getBits(3));
  const min_sz = count_bits(min.getBits(3));

  if (max_sz != min_sz) {
    return false;
  }

  const MAX_MASK_LEN = goog.net.Ipv6Address.MAX_NETMASK_LENGTH;
  const MAX_ADDRESS = goog.math.Integer.ONE.shiftLeft(MAX_MASK_LEN).subtract(
    goog.math.Integer.ONE
  );

  // find common prefix
  let prefix = MAX_ADDRESS,
    ii = 0;
  for (ii = 0; ii < MAX_MASK_LEN; ii++) {
    prefix = MAX_ADDRESS.shiftRight(ii).not();
    if (!prefix.and(max).equals(prefix.and(min))) {
      break;
    }
  }
  if (ii == 0) return true;
  prefix = MAX_ADDRESS.shiftRight(ii - 1);
  if (prefix.and(min) == 0 && prefix.and(max).equals(prefix)) {
    return true;
  }

  return false;
}

function count_bits(val) {
  let bits_cnt = 0;

  /* the mask is determined by the size of the range */
  while (val > 0) {
    bits_cnt++;
    val >>= 1;
  }

  return bits_cnt;
}

/**
 * Use validation logic from FGT to validate a wildcard value
 * example: Routing Objects -> Access List Rule
 */
export function isValidWildCard(value) {
  const IP_HOST =
    /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;
  const IP_MASK =
    /^(((255|254|252|248|240|224|192|128|0)\.0\.0\.0)|(255\.(255|254|252|248|240|224|192|128)\.0\.0)|(255\.255\.(255|254|252|248|240|224|192|128)\.0)|(255\.255\.255\.(255|254|252|248|240|224|192|128|0)))$/;
  const WILDCARD_MASK =
    /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;
  const IP_AND_SUBNET =
    /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])[/ ]{1}((((254|252|248|240|224|192|128|0)\.0\.0\.0)|(255\.(254|252|248|240|224|192|128|0)\.0\.0)|(255\.255\.(254|252|248|240|224|192|128|0)\.0)|(255\.255\.255\.(255|254|252|248|240|224|192|128|0))|(3[0-2]|2[0-9]|1[0-9]|[0-9])))$/;

  const parts = value.split(' ');
  if (parts.length === 2) {
    return (
      IP_HOST.test(parts[0]) &&
      (IP_MASK.test(parts[1]) || WILDCARD_MASK.test(parts[1]))
    );
  } else {
    return IP_AND_SUBNET.test(value);
  }
}

export function getValidNetMask(netmask) {
  if (!netmask || typeof netmask !== 'string') {
    return false;
  }
  // if netmask is a integer, convert to 255.x.x.x
  if (!isNaN(netmask)) {
    return toNetmaskString(netmask);
  } else {
    if (toNetmaskNumber(netmask) >= 0) {
      return netmask;
    }
  }

  return false;
}

export function isFQDN(value) {
  const FQDN =
    /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-_]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)|(^[a-zA-Z0-9-_]{1,63}$)/;
  return FQDN.test(value);
}
