export const DvmWZDString = {
  unknown: gettext('Unknown'),
  connection_up: gettext('Connection Up'),
  connection_down: gettext('Connection Down'),
  copy_failed: gettext('Copy Failed'),
  cliprof_failed: gettext('CLI Template Failed'),
  skipped: gettext('Skipped'),
  copy_only: gettext('Copy Only'),
  no_change: gettext('No Changes'),
  unknown_error: gettext('Unknown Error'),
  zone_validation_failed: gettext('Zone Validation Failed'),
  policy_validation_failed: gettext('Policy Validation Failed'),
  validation_pass: gettext('OK'),
  install_preview: gettext('Install Preview'),
  device_vdom: gettext('Device/VDOM'),
  task_history: gettext('Task History'),
  Zone_validation: gettext('Zone Validation'),
  purged_policy_types: gettext('Purged Policy Types'),
  no_cmds_to_install: gettext('No commands to be installed.'),
  gb_details: gettext('Details'),
  preview: gettext('Install Preview'),
  diff_preview: gettext('Policy Package Diff'),
  copy_log: gettext('Log'),
  dt_download: gettext('Download'),
  cliprof_script: gettext('CLI Template Errors'),
  vdom_purge_policy: gettext(
    "The following policy types are going to be purged: '%s'."
  ),
  vdom_has_diff_pkg_installed: gettext(
    "A different policy package '%s' was previously installed on this device."
  ),
  plyck_view: gettext('Policy Check Result'),
};

export const fiCliTemplateScriptsType = {
  CLI: MACROS.PM2CAT.PM2_CLIPROF_TYPE_CLI,
  JINJA: MACROS.PM2CAT.PM2_CLIPROF_TYPE_JINJA,
};
