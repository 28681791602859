export {
  // general helper
  tokenize,
  getIn,
  setIn,
  meetAll,
  meetNone,
  noop,
  hasMetaVariable, // test if contains this pattern $(...)
};

function tokenize(path) {
  if (Array.isArray(path)) return path;
  else if (typeof path === 'string') return path.split('.');
  return [];
}

function getIn(obj, path) {
  const tokens = tokenize(path);

  for (let attr of tokens) {
    obj = obj?.[attr];

    if (obj == null) return null;
  }

  return obj;
}

function setIn(obj, path, value) {
  const tokens = tokenize(path);

  while (tokens.length > 1) {
    const attr = tokens.shift();
    obj = obj[attr] || (obj[attr] = {});
  }

  const attr = tokens[0];

  if (attr) {
    obj[attr] = value;
    return true;
  }
  return false;
}

function meetAll(...checkFns) {
  return (tobeChecked) => {
    return checkFns.every((checkFn) => checkFn(tobeChecked));
  };
}

function meetNone(...checkFns) {
  return (tobeChecked) => {
    return !checkFns.some((checkFn) => checkFn(tobeChecked));
  };
}

function noop() {}

function hasMetaVariable(str = '') {
  const reg = /\$\(.+\)/;
  return reg.test(str);
}
