/************************************
 * Time formatter.
 *
 * Usage:
 *
 * const {formatAdomTime} = require('fi-datetime');
 * $scope.timeDisplay = formatAdomTime({unix: 123456789});
 *
 * import {AdomTimeFormatter} from 'fi-datetime';
 * return (<AdomTimeFormatter unix={123456789} />);
 *
 * Advanced:
 *
 *   - You can use js (milliseconds) instead of unix, if need to.
 *   - You can specify format, using momentjs formatter string.
 *
 */
import 'moment-timezone';
import moment from 'moment';
import { fiAdom, fiSysConfig } from 'fi-session';

export const getZonePath = (adom = fiAdom.current()) => {
  let tz = adom?.timezone?.file;
  if (!tz) {
    try {
      tz = fiSysConfig.current().timezone.timezonename; // fallback
    } catch (ex) {
      console.error(ex);
      tz = moment.tz.guess(); // fallback's fallback
    }
  }
  return tz;
};

export const getSysZonePath = () => {
  let tz = null;
  try {
    tz = fiSysConfig.current().timezone.timezonename;
  } catch (ex) {
    console.error(ex);
    tz = moment.tz.guess(); // fallback's fallback
  }

  return tz;
};

// const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
const TZ_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';
const TZ_ABBR_FORMAT = 'YYYY-MM-DD HH:mm:ss z';
const NOTZ_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_OUTPUT_FORMAT = NOTZ_FORMAT;

// The following const and functions are
// used to convert zone path name like "America/Anchorage"
// to human preferred names like "Anchorage".
//
// This is NOT using fistore to avoid dependency.
const ZONE_NAMES = {};
// this should not run immediately after the module load
// TODO: move to fistore
// queueMicrotask(() => {
//   fiHttpGet('/p/util/get_timezone_list/').then(
//     (resp) => {
//       (resp || []).forEach((zone) => {
//         const name =
//           typeof zone.name === 'string'
//             ? zone.name.replace(/^\(.*\)\s+/, '').replace(/[.]$/, '')
//             : '';
//         if (name) {
//           ZONE_NAMES[zone.file] = name;
//         }
//       });
//     },
//     (err) => {
//       console.error('Unable to load timezone names.', err);
//     }
//   );
// });

export const getZoneAbbr = (zone) => {
  const _zone = zone || getZonePath();
  try {
    return moment().tz(_zone).format('z');
  } catch (ex) {
    console.error('Invalid Timezone', zone, ex);
    return _zone;
  }
};

// convert date time string to utc timestamp with adom zone
// if timestring (tstr) is invalid, will return now
// e.g '2023-09-11 08:30:00' to
export const toTimestampWithAdomTZ = ({ tstr, zone }) => {
  const _zone = zone || getZonePath();
  try {
    return moment.tz(tstr, _zone).unix();
  } catch (ex) {
    return moment().unix();
  }
};

const _getZoneName = (path) => {
  return ZONE_NAMES[path] || path;
};

// internal use
const getMomentByZone = ({ unix, js, zone, tstr, format }) => {
  let time, inputFormat;
  if (tstr && format) {
    time = tstr;
    inputFormat = format;
  } else if (typeof js !== 'undefined' && js !== null) {
    time = js;
    inputFormat = 'x';
  } else {
    // unix is defined
    time = unix;
    inputFormat = 'X';
  }
  let m1, m2; // m1: time-only, m2: with zone
  try {
    m1 = time ? moment(time, inputFormat) : moment(); //fallback to now
  } catch (ex) {
    console.error(ex);
    return gettext('Invalid Time');
  }
  try {
    m2 = zone ? m1.tz(zone) : m1;
  } catch (ex) {
    console.error('Invalid Timezone', zone, ex);
    return m1.toISOString(); // in case zone is bad but time is valid, we display the ISO time?
  }
  return m2;
};

// input time are utc time,
// output from mm, will be zoned.
export const getAdomMoment = ({ unix, js, tstr, format } = {}) => {
  const mm = getMomentByZone({ unix, js, zone: getZonePath(), tstr, format });
  return mm.isValid() ? mm : null;
};

export const getAdomMomentByDateObj = (date, adom = fiAdom.current()) => {
  const tz = getZonePath(adom);
  const d = date || new Date();
  return moment(d).tz(tz);
};

// time could any values that accept by moment
// e.g Date obj, iso string, etc
export const getSysMoment = (time, inputFormat) => {
  const tz = getSysZonePath();
  return moment(time, inputFormat).tz(tz);
};

// input time are zoned by adom/given,
// output will become utc
export const getMomentFromZonedTime = ({ unix, js, zone, tstr, format }) => {
  let time, inputFormat;
  const _zone = zone || getZonePath();
  if (tstr && format) {
    time = tstr;
    inputFormat = format;
  } else if (typeof js !== 'undefined' && js !== null) {
    time = js;
    inputFormat = 'x';
  } else {
    // unix is defined
    time = unix;
    inputFormat = 'X';
  }
  let mm;
  try {
    mm = time ? moment.tz(time, inputFormat, _zone) : moment.tz(_zone); //fallback to now
  } catch (ex) {
    console.error(ex);
    return null;
  }

  return mm.isValid() ? mm : null;
};

export const getTimeZoneHint = (zone) => {
  const _zone = zone || getZonePath();
  return (
    <>
      {gettext(
        'Time is presented in timezone: %(timeZone)s (%(tzAbbr)s)'
      ).printfd({
        timeZone: _getZoneName(_zone),
        tzAbbr: getZoneAbbr(_zone),
      })}
    </>
  );
};

export const getZoneInfo = ({ unix, js, zone = null } = {}) => {
  try {
    const _zone = zone || getZonePath();
    const mm = getMomentByZone({ unix, js, zone: _zone });
    if (mm.isValid()) {
      return {
        zone: _zone,
        zoneName: _getZoneName(_zone),
        abbr: getZoneAbbr(_zone),
        addiction: mm.format('Z z'),
        get hint() {
          // zone names may be loaded later, so use a getter
          return getTimeZoneHint(_zone);
        },
      };
    }
  } catch (ex) {
    console.error(ex);
  }
  return { error: gettext('Unknown Timezone') };
};

// @js: this is javascript unix-milliseconds.
// @unix: if js milliseconds is not given, this is linux seconds from 1970 UTC.
// @zone: timezone path/filename that looks like 'America/Los_Angeles'.
// @format: momentjs format.
export const formatTimeByZone = ({
  unix,
  js,
  zone,
  format = DEFAULT_OUTPUT_FORMAT,
}) => {
  const m2 = getMomentByZone({ unix, js, zone });
  if (moment.isMoment(m2) && m2.isValid()) {
    return m2.format(format);
  } else if (typeof m2 === 'string') {
    return m2;
  } else {
    return gettext('Invalid Time');
  }
};

// For use in old angularjs or other tools
export const formatAdomTime = ({
  unix,
  js,
  zone = null,
  showOffset = false,
  showAbbr = false,
  format = DEFAULT_OUTPUT_FORMAT,
}) => {
  if (showOffset && format === DEFAULT_OUTPUT_FORMAT) {
    format = TZ_FORMAT;
  } else if (showAbbr && format === DEFAULT_OUTPUT_FORMAT) {
    format = TZ_ABBR_FORMAT;
  }
  const _zone = zone || getZonePath();
  return formatTimeByZone({ unix, js, format, zone: _zone });
};

export const formatNowAsAdomTime = () => {
  return formatAdomTime({
    unix: Math.ceil(Date.now() / 1000),
  });
};

// Nice to have the zone info in the title,
// especially if default format does not include it.
//
// But if we choose a default time format that includes zone info,
// then this formatter's additional 'Z' title is not needed.
export const AdomTimeFormatter = ({
  unix,
  js,
  format = DEFAULT_OUTPUT_FORMAT,
  // add className if need to
}) => {
  const zone = getZonePath();
  const m2 = getMomentByZone({ unix, js, zone, format }); // should we cache?
  if (moment.isMoment(m2) && m2.isValid()) {
    // Example 'Z z'  =>  '-06:00 MDT'
    return <span title={m2.format('Z z')}>{m2.format(format)}</span>;
  }
  if (typeof m2 === 'string') {
    return <span title={gettext('Timezone is unavailable')}>{m2}</span>;
  }
  return <span>{gettext('Invalid Time')}</span>;
};

// You get a Date object, which comes from browser local clock, but
// converted to ADOM timezone, and the datetime in ADOM timezone is used
// to create the result Date object to show the same apparent value,
// but the offset/timezone info will be wrong, because browser Date
// object only defaults to browser timezone.
//
// Example:
//   browser in Vancouver (-0800 PST),
//   ADOM in Hong Kong (+0800 HKT),
//   browser clock shows 03:00.
//   This gets moment in HKT, = 3 + 8 + 8 = 19:00
//   And returned Date object is 19:00 PST (because browser is in PST)
//                               ^^ apparent value in HKT but object is local
export const getNowDateObjWrongZone = () => {
  const zone = getZonePath();
  const momentNow = moment().tz(zone);
  const arr = momentNow.format('YYYY-MM-DD-HH-mm-ss').split('-');
  arr[1]--; // month is 0 based in Date object
  return new Date(...arr);
};

// was named: formatTimestampWithTimezone
export function formatTimesByLocale(timestamp) {
  if (!timestamp) return '';

  const timeZone = getZonePath();
  const aDate = new Date(timestamp * 1000);
  const options = {
    timeZone,
    timeZoneName: 'short',
  };

  return aDate.toLocaleString(navigator.language, options) + ' ';
}

export function convertSysTimeStrToAdomTime(tstr, informat, outformat) {
  const systz = fiSysConfig.current().timezone.timezonename;
  const mm = getMomentFromZonedTime({ tstr, format: informat, zone: systz });

  if (!mm) return tstr; // fall back to original tstr

  return formatAdomTime({
    unix: mm.unix(),
    format: outformat,
  });
}
