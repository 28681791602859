type PollRequestHookArgs<T> = {
  pollFn: (attempts: number) => Promise<T>;
  onNotify: (resp: T) => boolean;
  init?: () => void;
  maxAttempts?: number;
  timeout?: number;
};

export const runPollRequest = <T = unknown>({
  init,
  pollFn,
  onNotify,
  maxAttempts,
  timeout = 1000,
}: PollRequestHookArgs<T>) => {
  let attempts = 0;
  let promise: null | Promise<T> = null;

  init && init();

  const instance = setInterval(async () => {
    if (promise || (maxAttempts && attempts > maxAttempts)) return;
    attempts += 1;

    promise = pollFn(attempts).then((resp) => {
      promise = null;
      return resp;
    });

    const resp = await promise;
    const isComplete = onNotify(resp);
    if (isComplete) clearInterval(instance);
  }, timeout);

  return () => {
    clearInterval(instance);
  };
};
