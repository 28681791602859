const getStorage =
  (storage) =>
  (key, initValue = null) => {
    try {
      // Get from local storage by key
      const strVal = storage.getItem(key);
      // Parse stored json or if none return initialValue
      return strVal ? JSON.parse(strVal) : initValue;
    } catch (err) {
      if (MACROS.SYS.CONFIG_DEBUG) {
        console.error(`Failed to get '${key}' from storage`, err);
      }
      // If error also return initialValue
      return initValue;
    }
  };

const setStorage = (storage) => (key, val) => {
  try {
    storage.setItem(key, JSON.stringify(val));
  } catch (err) {
    if (MACROS.SYS.CONFIG_DEBUG) {
      console.error(`Failed to set '${key}' to storage`, err);
    }
  }
};

export const getLocalStorage = getStorage(localStorage);
export const setLocalStorage = setStorage(localStorage);

export const getSessionStorage = getStorage(sessionStorage);
export const setSessionStorage = setStorage(sessionStorage);

export const removeLocalStorage = (key) => localStorage.removeItem(key);
