import {
  shouldDebuggerStart,
  createCaptureFile,
  debuggerDidStart,
  getPlatformInfo,
} from 'chrome_extension_debugger';
import { fiSysConfig, fiLogout } from 'fi-session';
import { fiHttp } from 'fi-http';
import { openConfirmModal } from 'rc_layout';
import { get } from 'lodash';

export const initPromptStartCapture = () => {
  async function promptForUserAuth(buttons) {
    return openConfirmModal({
      title: gettext('Capture device request'),
      content: gettext(
        'Received a request for capturing device information and logs. Do you want to continue?'
      ),
      buttons,
    });
  }

  shouldDebuggerStart(async ({ pageReload }) => {
    const buttons = pageReload
      ? [
          {
            label: gettext('Reload and Capture'),
            title: gettext(
              'Use this option to reload the current page, capturing all the necessary requests required to render it.'
            ),
            type: 'primary',
            automationId: 'reload-capture-btn',
            click: ($opener) => {
              const reload = () => {
                location.reload();
              };
              $opener.resolve({ captureAfter: reload });
            },
          },
          {
            label: gettext('Login and Capture'),
            title: gettext(
              'Use this option to capture all requests, including those for the setup wizard and the ADOM selection pane. The GUI will log out and redirect you to the login page. Once you log back in, the debugger will start capturing immediately.'
            ),
            type: 'primary',
            automationId: 'login-capture-btn',
            click: ($opener) => {
              const logout = () => {
                fiLogout();
              };
              $opener.resolve({ captureAfter: logout });
            },
          },
          {
            label: gettext('Cancel'),
            type: 'default',
            automationId: 'cancel-btn',
            click: ($opener) => {
              $opener.reject();
            },
          },
        ]
      : undefined;

    return promptForUserAuth(buttons);
  });

  /// start of dashboard data
  function processDashboardResponse(data) {
    if (get(data, 'data,result.[0].status.code', 0))
      throw data.result.status.message;
    return get(data, 'data.result.[0].data', {});
  }

  function sysRequest(req) {
    return fiHttp.post('/cgi-bin/module/flatui_proxy', req, {
      params: {
        nocache: Date.now(),
        debugger: 'ignore',
      },
    });
  }
  async function getSysInfo() {
    return sysRequest({
      method: 'get',
      url: '/gui/sys/info',
    }).then(processDashboardResponse);
  }

  async function getLicenses() {
    return sysRequest({
      method: 'get',
      url: '/gui/sys/license',
    }).then(processDashboardResponse);
  }

  async function getSysConfig() {
    return fiSysConfig.current();
  }

  debuggerDidStart(async () => {
    const { send, complete } = await createCaptureFile('device-info.json', {
      mergeData: true,
    });

    send({ platform: getPlatformInfo(), configurations: [] });

    const dataRequests = [
      ['Device Information', getSysInfo()],
      ['System Configuration', getSysConfig()],
      ['Licenses', getLicenses()],
    ].map(async ([name, dataPromise]) => {
      try {
        const data = await dataPromise;
        send({ configurations: [{ name, data }] });
      } catch (ex) {
        // no permission?
      }
    });

    Promise.allSettled(dataRequests).then(() => {
      complete();
    });
  });
};
