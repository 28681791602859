import * as goog from '@fafm/goog';
import * as fp from '@fafm/fp';
import { isNil, isUndefined, isFunction, get, castArray } from 'lodash';
import { fiAdom, fiAdminProfile } from 'fi-session';
import { findElementInArrayByKey } from 'kit-array';
import { fiWorkspace } from 'fi-workspace';
import { fiDvmActionsId } from 'fi-actions';
import { DvmInterfaceType } from 'fi-interface';
import {
  selectedDevice,
  deviceStatus,
  fiDvmScriptsType,
  fiDvmRevisionStatus,
  fiDvmFilterRules,
} from 'fi-apps/fi-dvm';
import {
  compareDeviceVer,
  isDevInSplitVdomMode,
  fiDeviceDataLoader,
} from 'ra_device_util';
import {
  canConfigureFGSP,
  isAzureSlbDevGrp,
  isUMSDevGrp,
} from '../util_functions/device_group_related';

/**
 *  DVM Buttons status checking and updating handler.
 *  The button status is decide by current configuration.
 *  Function call fiDvmBtnStatus need pass in current config
 *  because this is only button statue handler, it will not know
 *  when is the best time to get current config. And in most case,
 *  the config can be reused many times by other button handler.
 */

var returnObj = {};
const _PredefinedGroups = [
  MACROS.DVM.DVM_GRP_UNREG_OID,
  MACROS.DVM.DVM_GRP_LOGGING_OID,
  MACROS.DVM.DVM_GRP_MANAGED_OID,
  MACROS.DVM.DVM_GRP_REMOTE_FAZ_OID,
];
const isPredefinedGroup = (row) => {
  return _PredefinedGroups.indexOf(parseInt(row[0].oid || row[0].key)) >= 0;
};
// when dvm is "Display Device/Group tree view"
// selectedRow could be a device group.
// selectedRow could be a interface.(rowData no rtype)
// selectedRow could be a device.
// selectedRow could be a unreg device, in this case no selected device.
// selectedDevice() may returns null, for selectedRow is not always the selected device
// selectedRow could be a vdom
function _getParentDeviceFromSelectRow(selectedRow) {
  let _rowData = Array.isArray(selectedRow) ? selectedRow[0] : selectedRow;
  if (!_rowData || _rowData.isGrp) {
    // no parent device
    return null;
  }
  _rowData = _rowData._oData ? _rowData._oData : _rowData;
  let _dev = !isUndefined(_rowData.rtype) ? _rowData : selectedDevice();

  if (!_dev) {
    // no selected device.
    return false;
  }
  // sometimes, selected row is not device. such as interface.
  if (deviceStatus.isVdom(_dev.rtype)) {
    _dev = _dev._pData;
  }
  return _dev;
}

function devIsLockedByMe(dev) {
  let _dev = _getParentDeviceFromSelectRow(dev);
  if (_dev) {
    let devInfo = fiWorkspace.devInfo(_dev);
    return devInfo.isLockedByMe();
  }
  return false;
}

function _dfltBackupAdomFn() {
  return true;
}

function addVdomBtnStatusFn(selectedRows) {
  if (
    onlyOneRowSelected(selectedRows) &&
    !deviceStatus.isVdom(selectedRows[0].rtype) &&
    hasMgtVdom(selectedRows[0]._oData)
  ) {
    const devData = selectedRows[0]._oData;
    // fpx below 7.2 does not support vdom
    if (
      devData.os_type === MACROS.DVM.DVM_OS_TYPE_FPX &&
      compareDeviceVer(devData, '7.2', '<')
    ) {
      return false;
    }
    return !isDevInSplitVdomMode(selectedRows[0]._oData);
  }
  return false;
}

function onlyOneRowSelected(selectedRows) {
  if (Array.isArray(selectedRows)) {
    return selectedRows.length === 1 && !isUndefined(selectedRows[0]);
  } else {
    return false;
  }
}

function atLeastOneRowSelected(selectedRows) {
  return selectedRows && selectedRows.length > 0;
}
// at least one row is selected, all selected devices have to be non-vdom device.

function multipleNonVdomSelected(selectedRows) {
  if (atLeastOneRowSelected(selectedRows)) {
    for (let i = 0; i < selectedRows.length; ++i) {
      if (deviceStatus.isVdom(selectedRows[i].rtype)) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

function isCsfGroupOnly(selectedRows) {
  let _row = Array.isArray(selectedRows) ? selectedRows[0] : selectedRows;
  if (_row) {
    let _data = _row._oData ? _row._oData : selectedRows[0];
    return _data._isCsfGroup;
  }
  return false;
}

function enableInstallInNormalMode(ret) {
  return (
    (ret.adomLock.isUnlock() &&
      ret.devLock.hasLockedByMe() &&
      !ret.devLock.hasDirty()) ||
    (ret.adomLock.isLockedByMe() && !ret.adomLock.isDirty())
  );
}
// selected row is device
function isDevice(selectedRows) {
  let _row = selectedRows[0]._oData ? selectedRows[0]._oData : selectedRows[0];
  return _row._isDevice || _row.type === 'dev';
}
// selected row is device group
function isDevGroup(rows) {
  let _row = Array.isArray(rows) ? rows[0] : rows;
  if (_row) {
    _row = _row._oData ? _row._oData : _row;
    return _row.isGrp || _row.isGroup || _row.type === 'grp';
  }
  return false;
}

function hasCnfDev(selectedRows) {
  return castArray(selectedRows).some(
    (dev) => dev && (dev.iscnf || get(dev, '_oData.iscnf'))
  );
}

// device has management vdom
function hasMgtVdom(device) {
  if (device.vdom_status) {
    for (var i = 0; i < device.vdoms.length; ++i) {
      if (device.vdoms[i].mgtvdom) {
        /* device with management vdom */
        return true;
      }
    }
  } else {
    /* device without vdom */
    return true;
  }
  return false;
}

function adomIsLockedByMe() {
  const adomLock = fiWorkspace.adomInfo();
  return adomLock.isLockedByMe();
}

function isHADevice(device) {
  return device
    ? device.connection &&
        device.connection.ha_mode !== MACROS.DVM.DVM_HA_MODE_STANDALONE
    : false;
}
function generalHandler(cfg, cmd) {
  /*jshint validthis: true */
  this.isHaSlave = cfg && cfg.ha_mode === MACROS.SYS.HA_MODE_SLAVE;
  this.cmd = cmd;
  this.cfg = cfg;
  this.adomLock = fiWorkspace.adomInfo();
  this.devLock = fiWorkspace.allDevInfo();
}

// default select function isCsfGroupOnly will disable cmd if selected cmd is a csf group
generalHandler.prototype.onSelection = function (selectedRows) {
  //for selection are devices,if selection is a csf group only, default disable cmd
  return isDevGroup(selectedRows)
    ? atLeastOneRowSelected(selectedRows)
    : atLeastOneRowSelected(selectedRows) && !isCsfGroupOnly(selectedRows);
};
/* commonProcess() checks user privilege and btnDisableInNormalMode status, HA status and ADOM status
       this function is general status checking, usually every command uses default checking only.
       If the button need specific checking function, paramter will override the default checking function.
       Only passed all checking, button's disable status is 'false', in another word button is enabled.
       If and only if button is disabled(this.cmd.disabled = true), commonProcess returns immediately.
    */
generalHandler.prototype.commonProcess = function (parameter, selectedRows) {
  if (!this.cfg) {
    this.cmd.disabled = true;
    return;
  }
  if (isNil(parameter)) {
    parameter = {};
  }
  // set button status is enabled by default.
  this.cmd.disabled = false;

  if (!isUndefined(parameter.onSelection)) {
    // Customized onSelection override default onSelection function.
    // for better understand the code, if parameter.onSelection is false, button should be disabled.
    // so need !parameter.onSelection to disable button and interrupt the checking process.
    // if parameter.onSelection is true, continue to check other cases.
    if (!parameter.onSelection) {
      this.cmd.disabled = true;
      return;
    }
  } else if (!this.onSelection(selectedRows)) {
    // default selectedRow checking function.
    this.cmd.disabled = true;
    return;
  }

  let adomLock = fiWorkspace.adomInfo();
  // device manager work flow mode: not in a session, enable to config, expect policy command
  // if in a session, all commands are disabled.
  if (!parameter.skipWorkflow && adomLock.isWorkflow()) {
    if (adomLock.isLockedByMe()) {
      if (!isUndefined(parameter.btnDisableInWorkflow)) {
        if (parameter.btnDisableInWorkflow()) {
          this.cmd.disabled = true;
          return;
        }
      } else {
        // in btnDisableInWorkflow session, device config commands default are disabled if there is workflow session
        this.cmd.disabled = adomLock.workflowSession() ? true : false;
        return;
      }
    } else {
      // not locked by me
      this.cmd.disabled = true;
      return;
    }
  }

  // device lock, btnDisableInNormalMode is custom function of workspace normal mode, include device lock handler.
  if (adomLock.isNormalMode()) {
    if (parameter.btnDisableInNormalMode) {
      if (parameter.btnDisableInNormalMode(selectedRows)) {
        // only handle button disable case
        this.cmd.disabled = true;
        return;
      }
    } else if (!adomLock.isLockedByMe() && !devIsLockedByMe(selectedRows)) {
      // only handle button disable case
      this.cmd.disabled = true;
      return;
    }
  }

  // backup Adom
  if (fiAdom.isBackupAdom()) {
    if (isUndefined(parameter.backupAdom)) {
      this.cmd.disabled = true;
      return;
    } else if (!parameter.backupAdom()) {
      this.cmd.disabled = true;
      return;
    }
  }
  // ha
  if (!isUndefined(parameter.ha)) {
    if (parameter.ha()) {
      this.cmd.disabled = true;
      return;
    }
  } else if (this.isHaSlave) {
    this.cmd.disabled = true;
    return;
  }
  // special privilege check
  if (isFunction(parameter.privilege)) {
    if (parameter.privilege(this.cfg)) {
      this.cmd.disabled = true;
      return;
    }
  } else if (parameter.privilege) {
    // default handler for checking required level(parameter.privilege.value)
    if (!Array.isArray(parameter.privilege)) {
      this.cmd.disabled =
        fiAdminProfile.getPermitOf(parameter.privilege.id) <
        parameter.privilege.value;
    } else {
      for (var i = 0; i < parameter.privilege.length; i++) {
        if (
          parameter.privilege[i].id &&
          fiAdminProfile.getPermitOf(parameter.privilege[i].id) <
            parameter.privilege[i].value
        ) {
          this.cmd.disabled = true;
          return;
        }
      }
    }
  }
  return;
};

generalHandler.prototype.process = function (selectedRows) {
  this.commonProcess(null, selectedRows);
};

returnObj[fiDvmActionsId.unregDevice.add] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = { skipWorkflow: true };
    parameter.onSelection = atLeastOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.unregDevice.del] =
  returnObj[fiDvmActionsId.unregDevice.add];
returnObj[fiDvmActionsId.unregDevice.hide] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // select rows number >1 and no row has 'hidden_status' = 0
    parameter.onSelection =
      atLeastOneRowSelected(selectedRows) &&
      !findElementInArrayByKey(selectedRows, ['_oData', 'hidden_status'], 1);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.device.del] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.onSelection =
      atLeastOneRowSelected(selectedRows) &&
      ((selectedRows) => {
        let _rootVdomSelected = false;
        let _parentDevSelected = false;
        let _selectedParentDevs = [];

        for (let i = 0; i < selectedRows.length; ++i) {
          if (isNil(selectedRows[i]._oData))
            selectedRows[i]._oData = selectedRows[i];
          if (deviceStatus.isDevice(selectedRows[i].rtype)) {
            _selectedParentDevs.push(parseInt(selectedRows[i]._oData.oid));
          } else {
            // selected row is device
            if (
              parseInt(selectedRows[i]._oData.oid) ===
              MACROS.DVM.CDB_DEFAULT_ROOT_OID
            ) {
              _rootVdomSelected = true;
            } else if (
              (selectedRows[i]._oData._pkey ||
                selectedRows[i]._oData._pkey === 0) &&
              isDevInSplitVdomMode(
                fiDeviceDataLoader.getDevice(selectedRows[i]._oData._pkey)
              )
            ) {
              // do further check (the current VDOM selected is not management vdom)
              // when the parent device has split vdom mode, need to disable delete
              // for it must be traffic vdoms
              return false;
            }
            if (
              goog.array.contains(
                _selectedParentDevs,
                parseInt(selectedRows[i]._oData.did)
              )
            ) {
              _parentDevSelected = true;
            }
          }
          if (_rootVdomSelected && !_parentDevSelected) {
            return false;
          }
        }
        return true;
      })(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.device.add] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
      onSelection: true,
      backupAdom: _dfltBackupAdomFn,
      btnDisableInNormalMode: function () {
        // add device has to lock adom.
        let adomLock = fiWorkspace.adomInfo();
        return !adomLock.isLockedByMe();
      },
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.remoteFaz.add] = returnObj[fiDvmActionsId.device.add];

returnObj[fiDvmActionsId.device.edit] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  // edit button enable only relate to selected rows.
  ret.process = function (selectedRows) {
    var _privilege = fiAdminProfile.hasRDPermitOn(
      MACROS.USER.DVM.ADMINPRIV_DEV_OP
    );
    const canEditVdom = () => {
      const rowData = selectedRows[0];
      const isVdom = deviceStatus.isVdom(rowData.rtype);
      if (isVdom) {
        // if vdom, check if parent device has mgt vdom - do not allow edit vdom if mgt vdom is in another adom
        return rowData._oData._pData.hasMgtVdom;
      }
      // not a vdom device, always true
      return true;
    };
    ret.cmd.disabled = !(
      _privilege &&
      onlyOneRowSelected(selectedRows) &&
      !isDevGroup(selectedRows) &&
      !isCsfGroupOnly(selectedRows) &&
      canEditVdom()
    );
  };
  return ret;
};
returnObj[fiDvmActionsId.device.config] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    ret.cmd.disabled = true;
    if (
      onlyOneRowSelected(selectedRows) &&
      !isDevGroup(selectedRows) &&
      !isCsfGroupOnly(selectedRows)
    ) {
      ret.cmd.disabled = fiAdminProfile.noPermitOn(
        MACROS.USER.DVM.ADMINPRIV_DEV_CFG
      );
    }
    return;
  };
  return ret;
};

returnObj[fiDvmActionsId.device.refresh] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var _privilege = fiAdminProfile.hasRDPermitOn(
      MACROS.USER.DVM.ADMINPRIV_DEV_OP
    );
    ret.cmd.disabled = !(
      _privilege &&
      !isDevGroup(selectedRows) &&
      !isCsfGroupOnly(selectedRows)
    );
  };
  return ret;
};

returnObj[fiDvmActionsId.device.addVdom] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      !isDevGroup(selectedRows) &&
      !isCsfGroupOnly(selectedRows) &&
      addVdomBtnStatusFn(selectedRows) &&
      !hasCnfDev(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.device.install.reInstallPolicy] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEPLOY_MANAGEMENT,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection =
      atLeastOneRowSelected(selectedRows) &&
      ((selectedRows) => {
        let filterRules = fiDvmFilterRules.get();
        // find out all global devices in selectedRows. {dev-did:dev-name}
        let _parentDevices = selectedRows.reduce((acc, cur) => {
          let _dev = cur._oData || cur;
          if (deviceStatus.isVdom(_dev.rtype)) {
            acc[_dev.did] = _dev._deviceName;
          }
          return acc;
        }, {});

        for (let i = 0; i < selectedRows.length; ++i) {
          let _dev = selectedRows[i]._oData || selectedRows[i];

          if (
            deviceStatus.isVdom(_dev.rtype) ||
            deviceStatus.isDeviceNoVdom(_dev.rtype)
          ) {
            // A device that PP status is "never install" can not reinstall.
            if (
              filterRules.pkgStatus.neverInstalled(_dev) ||
              filterRules.pkgStatus.imported(_dev) ||
              filterRules.pkgStatus.installed(_dev)
            ) {
              return false;
            }
          } else if (
            deviceStatus.isDeviceHasVdom(_dev.rtype) &&
            !_parentDevices[_dev.oid]
          ) {
            // selected device is device has vdom but no its vdom selected, can not reinstall.
            return false;
          }
        }
        return true;
      })(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.device.install.wizard] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEPLOY_MANAGEMENT,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
      onSelection: true, // install does not require any selected rows
      btnDisableInNormalMode: function () {
        return !enableInstallInNormalMode(ret);
      },
      btnDisableInWorkflow: () => {
        if (ret.adomLock.workflowSession()) return true;
        return !ret.adomLock.isLockedByMe() || ret.adomLock.isDirty();
      },
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.wizard] =
  returnObj[fiDvmActionsId.device.install.wizard];
returnObj[fiDvmActionsId.device.install.config] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEPLOY_MANAGEMENT,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
      btnDisableInNormalMode: function () {
        return !enableInstallInNormalMode(ret);
      },
      btnDisableInWorkflow: () => {
        if (ret.adomLock.workflowSession()) return true;
        return !ret.adomLock.isLockedByMe() || ret.adomLock.isDirty();
      },
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.device.install.diff] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEPLOY_MANAGEMENT,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      ((selectedRows) => {
        var filterRules = fiDvmFilterRules.get();
        if (
          deviceStatus.isVdom(selectedRows[0].rtype) ||
          deviceStatus.isDeviceNoVdom(selectedRows[0].rtype)
        ) {
          if (
            !filterRules.pkgStatus.neverInstalled(
              selectedRows[0]._oData || selectedRows[0]
            )
          ) {
            return true;
          }
        }
        return false;
      })(selectedRows);

    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.install.instpreview] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEPLOY_MANAGEMENT,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
    };
    // install preview should be enabled if select a device which has config change.
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      ((selectedRows) => {
        let _selectedData = selectedRows[0]._oData || selectedRows[0];
        let pkgStatus = fp.path(['pp_sync', 'status'], _selectedData);
        let cfgStatus = _selectedData.sync;
        if (
          cfgStatus !== MACROS.DVM.DVM_COND_PEND_CONF &&
          pkgStatus !== MACROS.PO.PM3_PKG_STATUS_MODIFIED
        ) {
          return false;
        }
        return true;
      })(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.install.importPolicy] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_IMPORT_POLICY,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.btnDisableInNormalMode = function () {
      return !ret.adomLock.isLockedByMe();
    };
    parameter.btnDisableInWorkflow = () => {
      // "import policy" is enabled, under workflow mode in a session.
      return ret.adomLock.workflowSession() ? false : true;
    };
    // define a replace checking function for backup adom
    parameter.backupAdom = function () {
      return fiAdom.checkVersion('6.0', '>=');
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      !isDevGroup(selectedRows) &&
      !isCsfGroupOnly(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.topo] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    if (
      onlyOneRowSelected(selectedRows) &&
      selectedRows[0]._oData._csfGroupName &&
      !selectedRows[0]._oData._auth_needed
    ) {
      ret.cmd.disabled = false;
    } else {
      ret.cmd.disabled = true;
    }
  };
  return ret;
};

returnObj[fiDvmActionsId.device.deviceBlueprint] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true; // to ignore row select check
    parameter.btnDisableInWorkflow = () => {
      return ret.adomLock?.workflowSession() ? false : true;
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.move_to_fmg_member] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true; // to ignore row select check
    parameter.btnDisableInWorkflow = () => {
      return ret.adomLock?.workflowSession() ? false : true;
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.group.move_to_fmg_member] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true; // to ignore row select check
    parameter.btnDisableInWorkflow = () => {
      return ret.adomLock?.workflowSession() ? false : true;
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.importDevList] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    if (
      (MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FMG &&
        ret.cfg.enable_import_export) ||
      !fiAdom.isFazAdomManagedByFmg()
    ) {
      let parameter = {
        privilege: {
          id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
          value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
        },
      };
      parameter.backupAdom = _dfltBackupAdomFn;
      parameter.btnDisableInNormalMode = function () {
        return !ret.adomLock.isLockedByMe();
      };
      parameter.onSelection = true; // onSelection has no effect on this command
      ret.commonProcess(parameter, selectedRows);
    } else {
      ret.cmd.disabled = true;
    }
  };
  return ret;
};
returnObj[fiDvmActionsId.exportDevList] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    if (
      (MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FMG &&
        ret.cfg.enable_import_export) ||
      !fiAdom.isFazAdomManagedByFmg()
    ) {
      let parameter = {
        privilege: {
          id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
          value: MACROS.SYS.ADMINPRIV_PERM_READ,
        },
      };
      parameter.backupAdom = _dfltBackupAdomFn;
      parameter.onSelection = true; // onSelection has no effect on this command
      ret.commonProcess(parameter, selectedRows);
    } else {
      ret.cmd.disabled = true;
    }
  };
  return ret;
};
returnObj[fiDvmActionsId.exportCSV] = returnObj[fiDvmActionsId.exportDevList];
returnObj[fiDvmActionsId.addMultipleDev] =
  returnObj[fiDvmActionsId.importDevList];

returnObj[fiDvmActionsId.folder.create] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInWorkflow = () => false; // create device group is enabled either in session or not in session
    parameter.btnDisableInNormalMode = function () {
      return !ret.adomLock.isLockedByMe();
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.folder.edit] = returnObj[fiDvmActionsId.folder.create];
returnObj[fiDvmActionsId.folder.move] = returnObj[fiDvmActionsId.folder.create];

returnObj[fiDvmActionsId.group.create] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInWorkflow = () => false; // create device group is enabled either in session or not in session
    parameter.btnDisableInNormalMode = function () {
      return !ret.adomLock.isLockedByMe();
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
// edit, del, and create command have the same process
returnObj[fiDvmActionsId.group.del] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // only one row selected and row is non-default device group
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      isDevGroup(selectedRows) &&
      !isPredefinedGroup(selectedRows) &&
      !isAzureSlbDevGrp(selectedRows[0]) &&
      !isUMSDevGrp(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInWorkflow = () => false; // create device group is enabled either in session or not in session
    parameter.btnDisableInNormalMode = function () {
      return !ret.adomLock.isLockedByMe();
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.group.edit] = returnObj[fiDvmActionsId.group.del];

returnObj[fiDvmActionsId.group.firmwareFdsUpgrade] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // only one device selected and has to be group or device. (not vdom)
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      ((selectedRows) => {
        return isDevice(selectedRows) ||
          (isDevGroup(selectedRows) && !isPredefinedGroup(selectedRows))
          ? true
          : false;
      })(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.group.fgspConfig] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // only one row selected and row is non-default device group
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      isDevGroup(selectedRows) &&
      !isPredefinedGroup(selectedRows) &&
      canConfigureFGSP(selectedRows?.[0]);
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInWorkflow = () => false; // create device group is enabled either in session or not in session
    parameter.btnDisableInNormalMode = function () {
      return !ret.adomLock.isLockedByMe();
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.interface.enable] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // selected row is interface, but we need device for devIsLocked function.
    parameter.btnDisableInNormalMode = function () {
      return !ret.adomLock.isLockedByMe() && !devIsLockedByMe(selectedDevice());
    };
    parameter.backupAdom = _dfltBackupAdomFn;
    // at least one row selected, and at least one row is not gui-only interface.
    parameter.onSelection =
      atLeastOneRowSelected(selectedRows) &&
      findElementInArrayByKey(selectedRows, ['data', 'type'], (val) => {
        return !DvmInterfaceType.get(val)['gui-only'];
      });
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.interface.monitor] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // at least one row selected, and at least one row is not gui-only interface.
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.interface.disable] =
  returnObj[fiDvmActionsId.device.interface.enable];

returnObj[fiDvmActionsId.cfgMenus.vdom.create] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    parameter.btnDisableInNormalMode = function () {
      return !ret.adomLock.isLockedByMe() && !devIsLockedByMe(selectedDevice());
    };
    var _currentDevice = selectedDevice();
    if (_currentDevice.vdom_mode === MACROS.PM2CAT.PM2_VDOM_MODE_SPLIT_VDOM) {
      ret.cmd.disabled = true;
    } else {
      ret.commonProcess(parameter, selectedRows);
    }
  };
  return ret;
};

returnObj[fiDvmActionsId.cfgMenus.vdom.delete] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var _currentDevice = selectedDevice();
    if (_currentDevice.vdom_mode === MACROS.PM2CAT.PM2_VDOM_MODE_SPLIT_VDOM) {
      ret.cmd.disabled = true;
    } else {
      let parameter = {
        privilege: {
          id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
          value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
        },
      };
      // at least one row select and no management vdom in selection.
      parameter.onSelection =
        atLeastOneRowSelected(selectedRows) &&
        !findElementInArrayByKey(
          selectedRows,
          ['devName', '_shared_', 'nameObj', 'mgt'],
          1
        );
      ret.commonProcess(parameter, selectedRows);
    }
  };
  return ret;
};

returnObj[fiDvmActionsId.cfgMenus.whereuse] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      !isNil(selectedRows[0].data) &&
      !DvmInterfaceType.get(selectedRows[0].data.type)['gui-only'];
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.cfgMenus.interface.delete] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    // exclude the group headers
    selectedRows = selectedRows.filter((rowData) => !rowData.data.isGroup);

    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // at least one row selected and no row is gui-only and no row is not deletable.
    parameter.onSelection =
      atLeastOneRowSelected(selectedRows) &&
      ((selectedRows) => {
        for (let i = 0; i < selectedRows.length; i++) {
          if (
            DvmInterfaceType.get(selectedRows[i].data.type)['gui-only'] ||
            !selectedRows[i].data.deletable
          ) {
            return false;
          }
          // Only vdomlinks that have access to its pair can be deleted
          if (selectedRows[i].data.type === MACROS.PM2CAT.PM2_INTF_T_PAIR) {
            return !isNil(selectedRows[i].data.vdomPair);
          }
        }
        return true;
      })(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.cfgMenus.VPNPhase1.delete] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = atLeastOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.cfgMenus.VPNManualkey.delete] =
  returnObj[fiDvmActionsId.cfgMenus.VPNPhase1.delete];
returnObj[fiDvmActionsId.cfgMenus.adminProfile.delete] =
  returnObj[fiDvmActionsId.cfgMenus.VPNPhase1.delete];

// only in workspace normal mode, we need check "save" button status because of device lock.
// workflow mode does not have device lock
returnObj[fiDvmActionsId.workspace.save] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    // "Save" button is enabled if device or adom has dirty.
    parameter.btnDisableInNormalMode = () => {
      let adomDirty = fiWorkspace.adomInfo().isDirty();
      let devDirty = fiWorkspace.allDevInfo().hasDirtyByMe();
      return !adomDirty && !devDirty;
    };
    parameter.btnDisableInWorkflow = () => {
      // change device group in workflow mode
      // also need to save
      return !(ret.adomLock.isLockedByMe() && ret.adomLock.isDirty());
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.revision.del] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_REVISION_DELETE,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) && !selectedRows[0].current_rev;
    parameter.backupAdom = _dfltBackupAdomFn;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.revision.viewconfig] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    cmd.disabled =
      !onlyOneRowSelected(selectedRows) ||
      fiAdminProfile.noPermitOn(MACROS.USER.DVM.ADMINPRIV_DEV_CFG);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.revision.viewlog] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let onSelection =
      onlyOneRowSelected(selectedRows) &&
      (selectedRows[0].status === fiDvmRevisionStatus.ABORTED ||
        selectedRows[0].status === fiDvmRevisionStatus.INSTALLED);
    cmd.disabled =
      !onSelection ||
      fiAdminProfile.noPermitOn(MACROS.USER.DVM.ADMINPRIV_DEV_CFG);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.revision.diff] =
  returnObj[fiDvmActionsId.device.revision.viewconfig];
returnObj[fiDvmActionsId.device.revision.download] =
  returnObj[fiDvmActionsId.device.revision.viewconfig];
returnObj[fiDvmActionsId.device.viewDBConfig] =
  returnObj[fiDvmActionsId.device.revision.viewconfig];

returnObj[fiDvmActionsId.device.revision.import] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.revision.rename] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn; // install existing revision and rename revision are allowed in backup adom.
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.revision.install] =
  returnObj[fiDvmActionsId.device.revision.rename];

returnObj[fiDvmActionsId.device.revision.retrieve] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CONFIG_RETRIEVE,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    parameter.backupAdom = _dfltBackupAdomFn;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.revision.revert] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CONFIG_REVERT,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // current revision should not be revert
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) && !selectedRows[0].current_rev;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.views.table] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let isLoggingOrFAZGrp =
      selectedRows[0] &&
      (selectedRows[0].oid === MACROS.DVM.DVM_GRP_REMOTE_FAZ_OID ||
        selectedRows[0].oid === MACROS.DVM.DVM_GRP_LOGGING_OID);
    cmd.disabled =
      isLoggingOrFAZGrp ||
      fiAdminProfile.noPermitOn(MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER);
  };
  return ret;
};

returnObj[fiDvmActionsId.views.map] = returnObj[fiDvmActionsId.views.table];
returnObj[fiDvmActionsId.views.folder] = returnObj[fiDvmActionsId.views.table];
returnObj[fiDvmActionsId.views.ring] = returnObj[fiDvmActionsId.views.table];

returnObj[fiDvmActionsId.device.interface.mapping] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_INTF_MAPPING,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // selected row is interface, but we need device for devIsLocked function.
    parameter.btnDisableInNormalMode = function () {
      return !ret.adomLock.isLockedByMe() && !devIsLockedByMe(selectedDevice());
    };
    parameter.backupAdom = _dfltBackupAdomFn;
    // at least one row selected
    // zone member can not map (removed: #651820 requests to remove this checking)
    // sd-wan interface can not map
    // sd-wan default zone can not map
    parameter.onSelection = ((selectedRows) => {
      if (!onlyOneRowSelected(selectedRows)) return false;
      // if(selectedRows[0].row.groupName==='ui-zone'&&selectedRows[0].data.isMember) return false;
      if (
        selectedRows[0].row.groupName === 'ui-sdwan-zone' &&
        selectedRows[0].data.isMember
      )
        return false;
      if (selectedRows[0].data.type === 'ui-wanlink') return false;
      if (selectedRows[0].data.name === MACROS.USER.DVM.DEFAULT_SDWAN_ZONE)
        return false;
      return true;
    })(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.console] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  // edit button enable only relate to selected rows.
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_TERMINAL_ACCESS,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
      backupAdom: _dfltBackupAdomFn,
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) && !selectedRows[0].model_dev;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.reboot] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  // edit button enable only relate to selected rows.
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
      backupAdom: _dfltBackupAdomFn,
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) && !selectedRows[0].model_dev;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.shutdown] =
  returnObj[fiDvmActionsId.device.reboot];

returnObj[fiDvmActionsId.device.uploadLicense] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  // edit button enable only relate to selected rows.
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    let atLeastOneRow = atLeastOneRowSelected(selectedRows);
    // Check if selected rows do not contain any model devices or vdoms
    let devices = selectedRows.filter((row) => {
      let oData = row._oData || row;
      return (
        !oData.model_dev &&
        !('vid' in oData) &&
        row.platform_str.match(/VM64|ARM64/)
      );
    });
    parameter.onSelection =
      atLeastOneRow && devices.length === selectedRows.length;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.device.firmwareUpdate] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  // edit button enable only relate to selected rows.
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    let atLeastOneRow = atLeastOneRowSelected(selectedRows);
    // Check if selected rows do not contain any model devices or vdoms
    let devices = selectedRows.filter((row) => {
      let oData = row._oData || row;
      return !oData.model_dev && !('vid' in oData);
    });
    parameter.onSelection =
      atLeastOneRow &&
      devices.length === selectedRows.length &&
      !hasCnfDev(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.device.changeFmgSSO] =
  returnObj[fiDvmActionsId.device.reboot];

returnObj[fiDvmActionsId.device.pushSecurityPending] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_FGD_LICENSE_ACCESS,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      cmd.pendingStatus === MACROS.FGD.UM_JSON_INT_PENDING;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.editSystemTime] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.enableVdom] =
  returnObj[fiDvmActionsId.device.editSystemTime];

returnObj[fiDvmActionsId.device.editHostName] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // HA cluster can not change host name
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) && !isHADevice(selectedRows[0]);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.updateDevMetaField] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.scriptTmpl.add] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
      btnDisableInWorkflow: () => {
        let adomLock = fiWorkspace.adomInfo();
        return !adomLock.workflowSession();
      },
    };
    parameter.onSelection = true;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};
returnObj[fiDvmActionsId.scriptTmpl.import] =
  returnObj[fiDvmActionsId.scriptTmpl.add];

// additional permission check such as not in template group, should be defined in controller.
returnObj[fiDvmActionsId.scriptTmpl.del] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
      btnDisableInWorkflow: () => {
        let adomLock = fiWorkspace.adomInfo();
        return !adomLock.workflowSession();
      },
    };
    parameter.onSelection = atLeastOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.scriptTmpl.clone] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
      btnDisableInWorkflow: () => {
        let adomLock = fiWorkspace.adomInfo();
        return !adomLock.workflowSession();
      },
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.scriptTmpl.edit] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
      skipWorkflow: true,
      btnDisableInNormalMode: () => false,
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.scriptTmpl.export] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
      skipWorkflow: true,
      btnDisableInNormalMode: () => false,
    };
    let isTemplateGrp = fp.path(['isTemplateGrp'], selectedRows[0]);
    parameter.onSelection = onlyOneRowSelected(selectedRows) && !isTemplateGrp;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.scriptTmpl.assign] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
      btnDisableInWorkflow: () => {
        let adomLock = fiWorkspace.adomInfo();
        return !adomLock.workflowSession();
      },
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.scriptTmpl.validate] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
      btnDisableInNormalMode: () => false,
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[
  fiDvmActionsId.scriptTmpl.validationResult.toggleMissingMetaFieldDevices
] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
      btnDisableInNormalMode: () => false,
    };
    parameter.onSelection = cmd?.haveValidationResult;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.scriptTmpl.validationResult.previewScript] = function (
  cfg,
  cmd
) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
      skipWorkflow: true,
      btnDisableInNormalMode: () => false,
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.script.add] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInNormalMode = () => {
      return !adomIsLockedByMe();
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.script.edit] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInNormalMode = () => {
      return false;
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.script.del] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = atLeastOneRowSelected(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInNormalMode = () => {
      return !adomIsLockedByMe();
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.script.clone] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInNormalMode = () => {
      return !adomIsLockedByMe();
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.script.import] = returnObj[fiDvmActionsId.script.add];

returnObj[fiDvmActionsId.script.export] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS,
        value: MACROS.SYS.ADMINPRIV_PERM_READ,
      },
    };
    let isScriptGroup = fp.path(['isScriptGroup'], selectedRows[0]);
    parameter.onSelection = onlyOneRowSelected(selectedRows) && !isScriptGroup;
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInNormalMode = () => {
      return !adomIsLockedByMe();
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

// commands used in script page
returnObj[fiDvmActionsId.script.run] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let scriptType = fp.path(['target'], selectedRows[0]);
    let isPkgScript =
      scriptType === fiDvmScriptsType.ScriptRunningTarget.POLICY_PKG;
    let parameter = {
      privilege: [
        {
          id: isPkgScript
            ? MACROS.USER.DVM.ADMINPRIV_ADOM_POLICY_PKG
            : MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
          value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
        },
        {
          id: MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS,
          value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
        },
      ],
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.btnDisableInWorkflow = () => {
      if (isPkgScript) {
        // in session, can only run pkg script.
        return !ret.adomLock.workflowSession();
      } else {
        // out of session, can only run device scripts.
        return ret.adomLock.workflowSession();
      }
    };
    parameter.btnDisableInNormalMode = () => {
      if (adomIsLockedByMe()) {
        return false;
      }
      const hasLockedPkgs = fiWorkspace.allPkgInfo().hasLockedByMe();
      const hasLockedDevs = fiWorkspace.allDevInfo().hasLockedByMe();
      if (!isPkgScript && hasLockedDevs) {
        return false;
      } else if (isPkgScript && hasLockedPkgs) {
        return false;
      }
      return true;
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.script.runGlobal] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: [
        {
          id: MACROS.USER.DVM.ADMINPRIV_ADOM_ACCESS,
          value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
        },
        {
          id: MACROS.USER.DVM.ADMINPRIV_GLOBAL_POLICY_ACCESS,
          value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
        },
        {
          id: MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS,
          value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
        },
      ],
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    parameter.btnDisableInWorkflow = () => {
      // in session, can only run pkg script.
      return !ret.adomLock.workflowSession();
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

// commands permission checking only used in device manager, do not consider command used in policy package case.
returnObj[fiDvmActionsId.script.exec] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    // run script need policy deployment or device config rw.
    let parameter = {
      privilege: [
        {
          id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
          value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
        },
        {
          id: MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS,
          value: MACROS.SYS.ADMINPRIV_PERM_READ,
        },
      ],
    };
    parameter.backupAdom = _dfltBackupAdomFn;
    parameter.onSelection =
      !isDevGroup(selectedRows) &&
      !isCsfGroupOnly(selectedRows) &&
      multipleNonVdomSelected(selectedRows) &&
      !hasCnfDev(selectedRows);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.viewHA] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_CFG,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    // HA cluster can not change host name
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) && isHADevice(selectedRows[0]);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.fwmprof.edit] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = onlyOneRowSelected(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.fwmprof.add] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    parameter.backupAdom = _dfltBackupAdomFn;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.fwmprof.clone] =
  returnObj[fiDvmActionsId.fwmprof.edit];
returnObj[fiDvmActionsId.fwmprof.assign] =
  returnObj[fiDvmActionsId.fwmprof.edit];
returnObj[fiDvmActionsId.fwmprof.preview] =
  returnObj[fiDvmActionsId.fwmprof.edit];
returnObj[fiDvmActionsId.fwmprof.report] =
  returnObj[fiDvmActionsId.fwmprof.edit];
returnObj[fiDvmActionsId.fwmprof.upgrade] = function (cfg, cmd) {
  let ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    let parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      !!selectedRows[0]['scope member'] &&
      selectedRows[0]['scope member'].length > 0;
    parameter.backupAdom = _dfltBackupAdomFn;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.fwmprof.del] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = atLeastOneRowSelected(selectedRows);
    parameter.backupAdom = _dfltBackupAdomFn;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.group.ums_capacity] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      isDevGroup(selectedRows) &&
      isUMSDevGrp(selectedRows[0]);
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj['fos_ui_access'] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_DEV_OP,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection =
      onlyOneRowSelected(selectedRows) &&
      !selectedRows[0].model_dev &&
      !selectedRows[0].iscnf;
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.editVariableMapping] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function () {
    cmd.disabled = false;
  };
  return ret;
};

returnObj[fiDvmActionsId.device.lock] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_PP_DEV_LOCK,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    parameter.skipWorkflow = true;
    parameter.btnDisableInNormalMode = (selectedRows) => {
      if (!onlyOneRowSelected(selectedRows)) return true;
      const device = selectedRows[0];
      if (!deviceStatus.isDevice(device.rtype)) return true;
      if (ret.adomLock.isLocked()) return true;
      let _dev = _getParentDeviceFromSelectRow(device);
      if (!_dev) return true;
      if (ret.devLock.isLockedByMe(_dev.oid)) return true;
      if (ret.devLock.isLockedByOther(_dev.oid) && !ret.adomLock.lockOverride())
        return true;
      return false;
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

returnObj[fiDvmActionsId.device.unlock] = function (cfg, cmd) {
  var ret = new generalHandler(cfg, cmd);
  ret.process = function (selectedRows) {
    var parameter = {
      privilege: {
        id: MACROS.USER.DVM.ADMINPRIV_PP_DEV_LOCK,
        value: MACROS.SYS.ADMINPRIV_PERM_RDWR,
      },
    };
    parameter.onSelection = true;
    parameter.skipWorkflow = true;
    parameter.btnDisableInNormalMode = (selectedRows) => {
      if (!onlyOneRowSelected(selectedRows)) return true;
      const device = selectedRows[0];
      if (!deviceStatus.isDevice(device.rtype)) return true;
      if (ret.adomLock.isLocked()) return true;
      let _dev = _getParentDeviceFromSelectRow(device);
      if (!_dev) return true;
      if (
        ret.devLock.isUnlock(_dev.oid) ||
        ret.devLock.isLockedByOther(_dev.oid)
      )
        return true;
      return false;
    };
    ret.commonProcess(parameter, selectedRows);
  };
  return ret;
};

export const fiDvmBtnStatus = returnObj;
