import { isFunction, map } from 'lodash';

class ApiError extends Error {
  constructor(status) {
    super(status?.message);
    this.code = status?.code;
  }
}

export const apiResults = async (apiFn, ...params) => {
  try {
    const resp = await apiFn(...params);
    return map(resp?.result, 'data');
  } catch (err) {
    const status = map(err?.result, 'status');
    throw new ApiError('API Error', status);
  }
};

export const apiResultFirst = async (apiFn, ...params) => {
  if (!isFunction(apiFn)) {
    throw new Error('apiFn is not function');
  }
  try {
    const resp = await apiFn(...params);
    return resp?.result?.[0]?.data;
  } catch (err) {
    const status = err?.result?.[0]?.status;
    throw new ApiError(status);
  }
};
