import { forEach, isFunction, isNumber, isString, isUndefined } from 'lodash';
import moment from 'moment';
import { renderToString } from 'react-dom/server';

const fmtrOverride = {
  fmgFolderViewList: {
    name: {
      toCsvStr: function (item, key) {
        const context = this || {};
        let retTxt = getValue(item, key);
        if (
          context.fabricView &&
          context.attachments?.includes(item?._oData?.groupName)
        ) {
          return MACROS.USER.SYS.INDENT_4SPACES + retTxt;
        }
        return retTxt;
      },
    },
  },
};

const registeredFormatter = {
  [MACROS.USER.DVM.ASSETS_FORTIAP]: getHtmlCellValue,
  [MACROS.USER.DVM.ASSETS_FORTISWITCH]: getHtmlCellValue,
  [MACROS.USER.DVM.ASSETS_FORTIEXTENDER]: function (
    item,
    key,
    rowIndex,
    data,
    col
  ) {
    const _col = col._oData || {};
    if (_col.isReact) {
      const rowData = item;
      const cellData = _col.dataGetter ? _col.dataGetter({ rowData }) : rowData;
      return renderToString(
        _col.cellRenderer({ cellData, rowData }, (d) => d)
      ).replace(/(<([^>]+)>)|(&nbsp;?)/gi, '');
    }
    if (_col.dataGetter) return _col.dataGetter({ rowData: item });
    const val = getValue(item, key);
    return val ? _sanitizeList(val) : val;
  },
};

const _sanitizeList = (val) => {
  if (typeof val !== 'string') return val;
  return val.replace(/\r?\n|\r/, '');
};

export const fiCSVUtil = {
  getColFormatter: getColFormatter,
  getTimestamp: getTimestamp,
  getTableFormatter: (cid) => {
    return fmtrOverride[cid] || {};
  },
};

function getTimestamp() {
  return moment(new Date()).format('YYYY-MM-DD-HHmmss');
}
/**
 * Gets the Iframe URL from the iframe filter by the given ADOM version
 * @param {String} cid - unique id for earch table to export as csv
 * @param {Set} validColSet - filter to decide which column is shown
 * @return {Array} Array of columns for export
 */
function getColFormatter(cid, allCols) {
  const defCols = fmtrOverride[cid] || {};
  const cols = [];

  if (!allCols) {
    return cols;
  }

  forEach(allCols, function (col) {
    const ckey = col.field || col.key;

    const colDef = {
      ...(defCols[ckey] || {}),
      key: ckey,
      label: col.label || col.txt || col.title,
      hidden: col.hidden,
      getCellHtml: col.getCellHtml || null,
      toCsvStr: registeredFormatter[cid] || col.toCsvStr || getValue,
      _oData: col,
    };
    cols.push(colDef);
  });

  fmtrOverride[cid] = cols;

  return cols;
}

function getValue(item, key, col) {
  let data = item[key];

  if (col) {
    const dataGetter = col.dataGetter || col._oData?.dataGetter;
    if (isFunction(dataGetter)) {
      data = dataGetter({ rowData: item, column: col }) || data;
    }
    const toSearchString = col.toSearchString || col._oData?.toSearchString;
    if (isFunction(toSearchString)) {
      data =
        toSearchString({
          rowData: item,
          column: col,
          cellData: data?.txt ?? data,
        }) || data;
    }
  }

  if (isUndefined(data)) {
    data = item._oData ? item._oData[key] : null;
  }

  if (isUndefined(data) || data === null) {
    return '';
  }

  if (isString(data) || isNumber(data)) {
    return data;
  }

  if (Array.isArray(data)) {
    return data
      .map((val) => {
        if (val == null) return '';
        return val.txt || val.name || val.title || val;
      })
      .join(',');
  }

  return data.txt || data.name || data.title || '';
}

function getHtmlCellValue(item, key, rowIndex, data, col) {
  if (col.getCellHtml)
    return col
      .getCellHtml(item, col._oData)
      .replace(/(<([^>]+)>)|(&nbsp;?)/gi, '');
  let val = getValue(item, key, col);
  return val ? _sanitizeList(val) : val;
}
