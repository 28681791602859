import { isNil, isString } from 'lodash';
import { fiAdom } from 'fi-session';
import { fiDevCfgRequests } from '../requests/device_cfg_requests';
import { fiFmgHttp } from 'fi-http';
import {
  getFlatuiJsonData,
  setFlatuiJsonData,
  delFlatuiJsonData,
} from '../requests/dvm_config_flatui_requests';
import { fiDevSyntax } from '../syntax/dev_syntax';
import { escapeSlash } from 'kit/kit-regexp';

export {
  getSyntax,
  getDefaultInterfaceObj,
  getDataSourceByCategory,
  getDefaultValue,
  setRelatedTableData,
  getRelatedTableData,
  delRelatedTableData,
  disableAllDHCPServer,
  firewallAddressService,
  clearChildTableData,
  loadDataSource,
  getInterfaceDHCPTemplate,
  deleteInterfaceDHCP,
};

const delRelatedTableData = (cateName, filter, device, vdomName, keyAttr) => {
  const devName = isString(device) ? device : device?.name;
  let fn =
    !vdomName || vdomName == 'global'
      ? fiDevCfgRequests.global(cateName)
      : fiDevCfgRequests.vdom(cateName);
  return fn.del(devName, vdomName, filter, keyAttr);
};

const setRelatedTableData = async (cateName, data, device, vdomName) => {
  const devName = isString(device) ? device : device?.name;
  let fn =
    !vdomName || vdomName == 'global'
      ? fiDevCfgRequests.global(cateName)
      : fiDevCfgRequests.vdom(cateName);
  return fn.set(devName, vdomName, data);
};

const getRelatedTableData = async (
  cateName,
  device,
  vdomName,
  filter = null
) => {
  const devName = isString(device) ? device : device?.name;
  let fn =
    !vdomName || vdomName == 'global'
      ? fiDevCfgRequests.global(cateName)
      : fiDevCfgRequests.vdom(cateName);

  // need to filter out dhcp entry by interface
  return fn.all(devName, vdomName, { filter }).then(function (resp) {
    return resp ? resp.data : [];
  });
};

/**
 * Disable all entries that relate to given interfaces in system dhcp server .
 */
const disableAllDHCPServer = async ({ device, vdom, intf }) => {
  if (!isNil(device) && !isNil(vdom) && !isNil(intf)) {
    let _filter = ['interface', '==', intf];
    try {
      const resp = await getRelatedTableData(
        'system dhcp server',
        device,
        vdom,
        _filter
      );
      if (resp && resp.length > 0) {
        let _data = resp.map((x) => {
          return { id: x.id, status: MACROS.PM2CAT.PM2_OPT_DISABLE };
        });

        return setRelatedTableData('system dhcp server', _data, device, vdom);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return Promise.resolve();
};

const getDefaultValue = async (cateName, device, vdomName) => {
  const devName = isString(device) ? device : device?.name;
  return !vdomName || vdomName == 'global'
    ? fiDevCfgRequests.global(cateName).defaultValues(devName)
    : fiDevCfgRequests.vdom(cateName).defaultValues(devName, vdomName);
};

const getDataSourceByCategory = async (cateName, attrName, device, vdom) => {
  let fn =
    !vdom || vdom == 'global'
      ? fiDevCfgRequests.global(cateName)
      : fiDevCfgRequests.vdom(cateName);

  return fn
    .datasrc(device.name, vdom, [
      {
        attr: attrName,
        current_adom: fiAdom.current().name,
      },
    ])
    .then((resp) => {
      return resp[0].data ? resp[0].data : resp;
    });
};

const firewallAddressService = async (
  _dev,
  vdom,
  method,
  data,
  firewallName = ''
) => {
  const urlDomain1 =
    _dev?.vdom_mode && vdom !== 'global' ? 'vdom/' + vdom : 'vdom/root';
  const url = `pm/config/device/${_dev?.name}/${urlDomain1}/firewall/address`;
  method = method.toLowerCase();
  if (method === 'get') {
    return getFlatuiJsonData({ url });
  } else if (method === 'add') {
    return setFlatuiJsonData({ url, data }, false, 'add');
  } else if (method === 'del') {
    return delFlatuiJsonData({
      url,
      confirm: 1,
      filter: ['name', 'in', firewallName],
    });
  }
};

const getDefaultInterfaceObj = (deviceName) => {
  return fiDevCfgRequests
    .global('system interface')
    .defaultValues(deviceName)
    .then((resp) => {
      return {
        ...resp,
        role: MACROS.PM2CAT.PM2_SYS_INTF_ROLE_LAN,
        'device-identification': MACROS.PM2CAT.PM2_OPT_ENABLE,
      };
    });
};

const clearChildTableData = async (device, vdom, params = []) => {
  let recordName = params.record;
  let cateName = params.cate.replace(/ +/g, '/');
  let childName = params.childTable;
  let promise = [];

  params.data.forEach((key) => {
    let _vd = vdom ? vdom : 'global';
    let _p = `${cateName}/${recordName}/${childName}`;
    // Mantis: 0709642 - Don't include key in url when deleting from vdom
    if (_vd === 'global') {
      _p += `/${key}`;
    }
    promise.push(
      fiDevCfgRequests.device(device.name, _vd).global(_p).del([key])
    );
  });

  return Promise.all(promise);
};

const getSyntax = async (cateName, vdomName, device) => {
  try {
    return fiDevSyntax.get(device.name, vdomName, cateName);
  } catch (err) {
    return err.data;
  }
};

const loadDataSource = async (cateName, attr, vdom, device) => {
  let devName = escapeSlash(device.name);
  let params = [];
  attr = Array.isArray(attr) ? attr : [attr];
  attr.forEach((it) => {
    params.push({
      attr: it,
      fields: ['name'],
      current_adom: fiAdom.current().name,
    });
  });

  let fn =
    !vdom || vdom == 'global'
      ? fiDevCfgRequests.global(cateName)
      : fiDevCfgRequests.vdom(cateName);
  return fn.datasrc(devName, vdom, params);
};

function getInterfaceDHCPTemplate(device, vdomName) {
  const _vdomName = vdomName || 'root';
  const url = `pm/config/device/${device.name}/vdom/${_vdomName}/system/dhcp/server`;
  const req = {
    method: 'get',
    params: [
      {
        url,
        'object template': 1,
      },
    ],
  };
  return fiFmgHttp.forward(req);
}

function deleteInterfaceDHCP(dhcpServerId, device, vdomName) {
  const _vdomName = vdomName || 'root';
  const url = `pm/config/device/${device.name}/vdom/${_vdomName}/system/dhcp/server`;
  const req = {
    method: 'delete',
    params: [
      {
        url,
        confirm: 1,
        filter: ['id', 'in', dhcpServerId],
      },
    ],
  };
  return fiFmgHttp.query(req);
}
