import { DefaultTreeRow } from 'react_components/rc_dvm_nav/tree/tree_row';
import {
  treeWrap,
  makeAsyncTree,
} from 'react_components/rc_dvm_nav/dvm_nav/nav_tree';
import { getTreeKey } from '../util';

const nodeCompare = (a, b) => (a.name > b.name ? 1 : -1);
const Tree = treeWrap(makeAsyncTree(DefaultTreeRow), {
  hasSearchSort: true,
  sortTreeNodes: (treeNodes, isSortAsc) => {
    const sortNode = (a, b) =>
      isSortAsc ? nodeCompare(a, b) : nodeCompare(b, a);
    return treeNodes.sort(sortNode);
  },
});

export const FolderSelection = ({ fetchData, setPath, selectedFolder }) => {
  return (
    <div
      style={{
        width: 500,
        height: 300,
      }}
    >
      <Tree
        sortDir={1}
        id={'dvm-folder-view-tree'}
        fetchData={fetchData}
        getAutomationId={(node) => `folder-selection-${node._data.id}`}
        getMenuOps={() => []}
        itemSize={30}
        width={500}
        onNodeClick={({ node }) => {
          setPath(node._data);
        }}
        selected={
          selectedFolder.path
            ? { key: getTreeKey(selectedFolder.path), trigClick: false }
            : undefined
        }
        height={300}
      />
    </div>
  );
};
