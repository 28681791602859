import { fiHttpGet } from 'fi-http';
import { createSlice } from '@reduxjs/toolkit';
import { listenerMiddleware } from 'fistore/middlewares';
import { printfd } from 'kit/kit-string/printfd';
import { getIsPublicNetwork, isFmg } from 'fistore/session/sysConfig/selectors';
import { fetchSysConfig } from 'fistore/session/sysConfig/slice';

export const initialState = {
  isSidebarOpened: true, // whether sidebar is opened.
  sidebarWidth: 250, // default sidebar width
  olhConfig: null,
};

const _slice = createSlice({
  name: 'main-frame',
  initialState,
  reducers: {
    setSidebarWidth(state, { payload }) {
      state.sidebarWidth = payload;
    },
    setOlhConfig(state, { payload }) {
      state.olhConfig = payload;
    },
    toggleSidebar(state) {
      state.isSidebarOpened = !state.isSidebarOpened;
    },
    setMessageBox: {
      reducer: (state, { payload }) => {
        state.messageBox = payload;
      },
      prepare: (content, cls, timeout, isContentSafe) => {
        return {
          payload: {
            content,
            cls,
            timeout,
            isContentSafe,
          },
        };
      },
    },
  },
});

export const { setSidebarWidth, toggleSidebar, setMessageBox, setOlhConfig } =
  _slice.actions;
export default _slice.reducer;

// thunks

const HELP_LINKS_URL = '/p/fgd/helplinks/';
const getDefaultHelpLink = (prod) => {
  return {
    hrefs: {
      help: `https://docs2.fortinet.com/document/${prod}/%(major)s.%(minor)s.%(patch)s/administration-guide`,
      topic: '%(help)s?cshid=%(tag)s',
    },
  };
};

/**
 * Response Format:
 *
 * "help": "http(s)://.../%(major)s.%(minor)s.%(patch)...%(tag)s"
 *
 * format: {
 *   help: "http(s)://.../%(major)s.%(minor)s.%(patch)s/...",
 *   topic: "%(help)s?cshid=%(tag)s"
 * }
 */
listenerMiddleware.startListening({
  actionCreator: fetchSysConfig.fulfilled,
  async effect(action, { dispatch, getState, unsubscribe }) {
    // only run once
    unsubscribe();
    const state = getState();
    const prod = isFmg() ? 'fortimanager' : 'fortianalyzer';
    // Set the config using the default if no or in closed network.
    let resp = getDefaultHelpLink(prod);
    if (getIsPublicNetwork(state)) {
      try {
        resp = await fiHttpGet(HELP_LINKS_URL);
      } catch (err) {
        console.error(err);
      }
    }
    dispatch(
      setOlhConfig({
        help: printfd(resp.hrefs.help, {
          major: MACROS.SYS.CONFIG_MAJOR_NUM,
          minor: MACROS.SYS.CONFIG_MINOR_NUM,
          patch: MACROS.SYS.CONFIG_PATCH_NUM,
        }),
        topic: resp.hrefs.topic,
      })
    );
  },
});
