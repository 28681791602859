import { fiFmgHttp } from 'fi-web/fi-http';
import { get, isArray, isFunction } from 'lodash';
import macros from 'macros';

export {
  dvmGuiOpts,
  getValueTrueItems,
  updateMenuItemStatus,
  // admin setting in GUI DB
  withEscapedAdminName,
  deleteAdminCustomize,
  getAdminCustomize,
  makeUpdateAdminCustomize,
};

// ================ function definitions ========================
function _request<TR>(
  url: string,
  method = 'get',
  params: Record<string, unknown> = {}
) {
  return fiFmgHttp
    .post<TR>({
      url: url,
      method: method,
      params: params,
    })
    .then(function (resp) {
      const res = resp[0];
      return res.data || ({} as TR);
    });
}

type Rec = { oid: number | string };
type RecOid = Rec | Rec['oid'];
const getOid = (data: RecOid): number =>
  +(typeof data === 'object' ? data.oid : data);

type DeviceCustomization = Partial<{ displayOpts: string[] }>;
function dvmGuiOpts(
  adom: RecOid,
  device: RecOid,
  vdom: RecOid | undefined,
  opts?: never
): Promise<DeviceCustomization>;
function dvmGuiOpts(
  adom: RecOid,
  device: RecOid,
  vdom: RecOid | undefined,
  opts: DeviceCustomization
): Promise<void>;
/**
 * getter/setter for display option of device & vdom level
 * if has opt, it will write opts to server side
 */
async function dvmGuiOpts(
  adom: RecOid,
  device: RecOid,
  vdom: RecOid | undefined,
  opts: unknown
) {
  let url = '';
  const adomOid = getOid(adom);
  const devOid = getOid(device);

  if (vdom && +vdom !== macros.DVM.CDB_DEFAULT_GLOBAL_OID) {
    const vid = getOid(vdom);
    url = `/gui/adoms/${adomOid}/devices/${devOid}/vdoms/${vid}/customize`;
  } else {
    url = `/gui/adoms/${adomOid}/devices/${devOid}/customize`;
  }

  type Response = { customize?: DeviceCustomization };
  if (opts) {
    await _request<Response>(url).then((resp) => {
      return _request(url, 'update', {
        customize: {
          ...resp.customize,
          ...opts,
        },
      });
    });
    return;
  }

  return _request<Response>(url).then(
    (resp) => {
      return Array.isArray(resp.customize)
        ? { displayOpts: resp.customize }
        : resp.customize || {};
    },
    (err) => err
  ) as unknown;
}

/*********************/

function getValueTrueItems<
  T extends { id: string; value?: boolean; items?: T[] }
>(devItem: T, arrResult: T['id'][], fnFilter?: (item: T) => boolean) {
  if (devItem) {
    if (isFunction(fnFilter) && fnFilter(devItem)) {
      arrResult.push(devItem.id);
    } else if (devItem.value === true) {
      if (!devItem.items || devItem.items.length === 0) {
        arrResult.push(devItem.id);
      }
    }
    if (Array.isArray(devItem.items) && devItem.items.length > 0) {
      for (let i = 0; i < devItem.items.length; i++) {
        getValueTrueItems(devItem.items[i], arrResult, fnFilter);
      }
      devItem.value = undefined;
    }
  }
}

function updateMenuItemStatus<
  T extends {
    id: string;
    value?: boolean | string;
    readonly?: boolean;
    items?: T[];
    show?: null | ((adom_opt: T['id'][]) => T['value']);
  }
>(devItems: T[], adom_opt?: T['id'][], readonly?: boolean) {
  const toshow = Array.isArray(adom_opt) ? adom_opt : [];
  const items = JSON.parse(JSON.stringify(devItems)) as typeof devItems;
  let item, sub;
  for (let ii = 0; ii < items.length; ii++) {
    item = items[ii];
    if (typeof item.value === 'string') continue;
    item.value = toshow.indexOf(item.id) >= 0;
    item.readonly = readonly || false; //For JK's controll..
    if (item.items) {
      for (let kk = 0, sz = item.items.length; kk < sz; kk++) {
        sub = item.items[kk];
        if (typeof sub.value === 'string') continue;
        const show = get(
          devItems,
          [ii, 'items', kk, 'show'],
          null
        ) as T['show'];
        if (isFunction(show)) {
          sub.value = show(toshow);
          continue;
        }
        sub.value = toshow.indexOf(sub.id) >= 0;
      }
    }
  }
  return items;
}
/*********************/

type DashboardCustomization = Partial<{
  isHiddenList: string[];
  orderList: string[];
  cellsObj: Partial<Record<string, number>>;
}>;

/**
 * AdminCustomizeHandler
 */
type AdminCustomization = DashboardCustomization &
  Partial<{
    showPnoInDualPane: 0 | 1;
    /** Matches: pnoCustomize_{adom oid} display options */
    [pno: `pnoCustomize_ADOM#${number}`]: string[];
    /** Matches: ptDisplayOpts_{adom oid} display options */
    [dvm: `ptDisplayOpts_ADOM#${number}`]: string[];
  }>;

function getAdminCustomize<TF extends keyof AdminCustomization>(
  adminName: string,
  fields: TF[]
) {
  return fiFmgHttp.post<{ customize: Pick<AdminCustomization, TF> }>({
    method: 'get',
    url: `/gui/admin/${adminName}/customize`,
    params: {
      fields: isArray(fields) ? fields : undefined,
    },
  });
}

function setAdminCustomize(
  adminName: string,
  data: Partial<AdminCustomization>
) {
  return fiFmgHttp.post({
    method: 'update',
    url: `/gui/admin/${adminName}/customize`,
    params: {
      fields: Object.keys(data),
      customize: data,
    },
  });
}

function makeUpdateAdminCustomize(adminName: string) {
  return async (customize: Partial<AdminCustomization>) => {
    return setAdminCustomize(adminName, customize);
  };
}

function deleteAdminCustomize(adminNames: string[]) {
  const proms = adminNames.map((n) =>
    fiFmgHttp.post({
      method: 'remove',
      url: `/gui/admin/${n}/customize`,
    })
  );
  return Promise.all(proms);
}

function withEscapedAdminName<TP extends [string | string[], ...unknown[]], TR>(
  request: (...args: TP) => TR
) {
  const result: typeof request = (adminNames, ...rest) => {
    const escaped = (function escapeName(
      name: string | string[]
    ): string | string[] {
      if (Array.isArray(name)) {
        return name.map(escapeName) as string[];
      }
      return name.replaceAll('/', '\\/');
    })(adminNames);
    return request(...([escaped, ...rest] as TP));
  };
  return result;
}

export async function perAdminFeature<TA extends keyof AdminCustomization>(
  attribute: TA
) {
  const state = (await import('fistore')).fiStore.getState();
  const { getAdminUserName, getIsPerAdminFeatureVisibility } = await import(
    'fistore/session/sysConfig/selectors'
  );
  const adminName = getAdminUserName(state);
  const isPerAdmin = getIsPerAdminFeatureVisibility(state);

  type TValue = AdminCustomization[TA];
  return {
    query: async (fallback: () => Promise<TValue>): Promise<TValue> => {
      const perAdminResp = await (isPerAdmin
        ? withEscapedAdminName(getAdminCustomize)(adminName, [attribute])
        : null);

      const perAdminCustom = perAdminResp?.[0]?.data?.customize[attribute];
      if (perAdminCustom != null) {
        return perAdminCustom;
      }

      return fallback();
    },
    update: async (
      opts: TValue,
      fallback: () => Promise<unknown>
    ): Promise<void> => {
      if (isPerAdmin) {
        await withEscapedAdminName(setAdminCustomize)(adminName, {
          [attribute]: opts,
        } as Partial<AdminCustomization>);
      } else {
        await fallback();
      }
    },
  };
}
