import { forwardRef, useEffect } from 'react';
import { postRobotService } from 'fi-google-map';

export const MapComponent = forwardRef(
  ({ mapSvrUrl, listener, id = 'map_iframe', reload }, ref) => {
    useEffect(() => {
      if (listener) {
        postRobotService.registerListenerFn(id, listener);
      }
      return () => postRobotService.detach(id);
    }, [listener, id, reload]);

    return (
      <>
        <iframe
          ref={ref}
          style={{ position: 'relative' }}
          id='map_iframe'
          className='map_view_iframe fi-iframe fap-iframe tw-border-0'
          name='map_iframe'
          src={mapSvrUrl}
          key={reload}
        />
      </>
    );
  }
);
