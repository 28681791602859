import { fiFmgHttp, getResponseData } from 'fi-http';
import { fiLogout } from 'fi-session/logout';
const AVATAR_DATA_PREFIX = 'data:image/png;base64, ';
let currentUserAvatar = { src: '' };
let avatarUpdateCallback = () => {};
const avatarUpdateCallbacks = [];

export const UserService = {
  changePassword: function (username, oldPass, newPass) {
    const user = encodeURIComponent(username);
    return fiFmgHttp
      .post({
        url: `/gui/sys/admin/users/${user}/passwd`,
        method: 'post',
        params: {
          oldpasswd: oldPass,
          newpasswd: newPass,
        },
      })
      .then(
        function () {
          return {};
        },
        function (error) {
          throw error?.result[0]?.data || {};
        }
      );
  },
  logout: function () {
    fiLogout();
    return Promise.resolve();
  },
  AVATAR_DATA_PREFIX: AVATAR_DATA_PREFIX,
  setCurrentUserAvatar: function (src) {
    currentUserAvatar.src = src;
    avatarUpdateCallback();
    avatarUpdateCallbacks.forEach((callback) => callback());
  },
  getCurrentUserAvatarSrc: () => AVATAR_DATA_PREFIX + currentUserAvatar.src,
  subscribeAvatarUpdate: (callback) => {
    avatarUpdateCallback = callback;
    avatarUpdateCallbacks.push(callback);
  },
  currentUserAvatar: currentUserAvatar,
  changeAvatarPerm: function (admin, isSuperAdmin, currentUser) {
    // Note: super admin can change anyone's avatar;
    // otherwise a user can only change his/er own avatar
    if (
      isSuperAdmin ||
      (currentUser && currentUser.length && admin === currentUser)
    ) {
      return true;
    }

    return false;
  },
  adminAvatarList: _adminUsersAvatar,
  adminUsersAvatar: function () {
    return _adminUsersAvatar().then(function (resp) {
      const ret = {};
      resp.forEach(function (user) {
        ret[user.name] = user.avatar;
      });
      return ret;
    });
  },
};

// Note: be careful to call this from GUI since this will expose all the details of all users' setting to client browser!
// function _adminUsers() {
//   return fiFmgHttp
//     .get({
//       req: { url: '/gui/sys/admin/users', method: 'get' },
//     })
//     .then(function (resp) {
//       return getResponseData(resp, []);
//     });
// }

function _adminUsersAvatar() {
  return fiFmgHttp
    .get({
      req: { url: '/gui/sys/admin/users', method: 'getAvatars' },
    })
    .then(function (resp) {
      return getResponseData(resp, []);
    });
}
