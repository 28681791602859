import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { castArray, debounce, isFunction, isString, transform } from 'lodash';
import $ from 'jquery';

// codemirror
import CodeMirror from '@fafm/codemirror';
import '@fafm/codemirror/mode/xml';
import '@fafm/codemirror/mode/css';
import '@fafm/codemirror/mode/javascript';
import '@fafm/codemirror/mode/htmlmixed';
import '@fafm/codemirror/mode/jinja2';
import '@fafm/codemirror/mode/cli';
import '@fafm/codemirror/theme/material-darker.css';
import '@fafm/codemirror/addon/selection/active-line';
import '@fafm/codemirror/addon/lint/lint';
import '@fafm/codemirror/addon/lint/lint.css';
import '@fafm/codemirror/addon/search/search';
import '@fafm/codemirror/addon/search/searchcursor';
import '@fafm/codemirror/addon/search/jump-to-line';
import '@fafm/codemirror/addon/search/matchesonscrollbar';
import '@fafm/codemirror/addon/search/matchesonscrollbar.css';
import '@fafm/codemirror/addon/search/match-highlighter';
import '@fafm/codemirror/addon/dialog/dialog';
import '@fafm/codemirror/addon/dialog/dialog.css';
import '@fafm/codemirror/addon/merge/merge';
import '@fafm/codemirror/addon/merge/merge.css';
import '@fafm/codemirror/addon/hint/show-hint';
import '@fafm/codemirror/addon/hint/show-hint.css';

import { SelectPane } from '@fafm/filego2';
import { getParentContainer } from 'fiutil';
import { fiSSource } from 'fi-ssource';
import { useValidEffect, useDimension, usePrevious } from 'rh_util_hooks';
import { fiStoreTheme } from 'fistore';
import { ShowHideWrapper } from 'rc_layout';
import { NwButton, NwIcon } from '@fafm/neowise-core';
import { ProTable, ProToolkit } from '@fafm/neowise-pro';

import { addEscapeKeyboardTrap } from '../rc_iframe_codemirror';
import {
  getCmMode,
  getLastWordInCodemirror,
  defaultFormatEntryFn,
  defaultMatchFn,
  defaultAdjustSelectPanePos,
  defaultOnSelect,
  validatorFn,
  initMergeView,
  destroyRegularCm,
  focusCm,
} from './autocomplete_codemirror_util';
import { CM_TXT_MAP, CODEMIRROR_MODE, DIFF_VIEW_CM_ID } from './constant';
import './index.less';
import { useSelector } from 'react-redux';
import { ShortcutsModal } from './editor_shortcuts';

const { getIsDarkTheme } = fiStoreTheme;

const noop = () => {};
const CM_CONTAINER_CLS = 'autocomplete-cm-container';
const CM_TEXT_AREA_ID = 'autocomplete-cm';
const SELECT_PANE_CTN_ID = 'select-pane-container';
const CM_SEARCHBAR_ID = 'autocomplete-cm-searchbar';
const NEXT_ELEMENT_SELECTOR = 'div[slot="footer"] > nw-button';

export const AutocompleteCodeMirror = (props) => {
  /** ----------------------------------------------------------------------------
   * Props
   * -------------------------------------------------------------------------- */
  const {
    id = 'autocomplete_codemirror',
    lintTooltipId = null,
    className,
    dataSource = [],
    createCommand = null,
    editCommands = [],
    selectPaneProps = {},
    initialValue = '',
    codeMirrorOptions = {},
    dependencies = [],
    refreshTimeout = 100,
    originalScript,
    isDiffView = false,
    showDiffBtns = false,
    showDiffView,
    setCmAccessors,

    // use matchFn, (matchRegex and closeRex), or (triggerChars and closeChars) to trigger autocomplete
    triggerFn = defaultMatchFn, // triggers for autocompletion, cli: "$" or "$(", jinja2: "{{"
    triggerRegex = null,
    closeRegex = null,
    triggerChars = [],
    closeChars = [],

    // callbacks
    onChange = noop,
    onGutterContextMenu = noop,
    onSelect = defaultOnSelect,
    formatEntryFn = defaultFormatEntryFn,
    newChoiceParser = (val) => val,
    adjustSelectPanePosFn = defaultAdjustSelectPanePos,
    onValidate = null,
    // onValidChange = noop, // TODO: add some linting or validation

    errors,

    autoFormatConfig,

    shortcuts = null,
    readonlyShortcuts = true,

    'automation-id': propAutoId,

    ...rest
  } = props;

  const {
    mode = 'cli',
    extraKeys = {},
    escapeKeyboardTrap = { nextElementSelector: NEXT_ELEMENT_SELECTOR },
    showResetBtn = false,
    resetBtnTxt = CM_TXT_MAP.revert,
    resetFn = null,
    collapseIdentical = false,
    ...otherCodeMirrorOptions
  } = codeMirrorOptions;

  const [ssource] = useState(new fiSSource());

  const cmContainer = `${id}-${CM_CONTAINER_CLS}`;
  const cmTextArea = `${id}-${CM_TEXT_AREA_ID}`;
  const selectPaneId = `${id}-${SELECT_PANE_CTN_ID}`;

  /** ----------------------------------------------------------------------------
   * States
   * -------------------------------------------------------------------------- */
  const [cmInstance, setCmInstance] = useState(null);
  const [leftCmInstance, setLeftCmInstance] = useState(null);
  const [searchText, setSearchText] = useState('');

  // only supports two themes right now: default and material-darker
  const [codemirrorTheme, setCodemirrorTheme] = useState('default');

  const [showSelectPane, setShowSelectPane] = useState(false);

  const previousErrors = usePrevious(errors);

  /** ----------------------------------------------------------------------------
   * Refs
   * -------------------------------------------------------------------------- */
  const selectPaneRef = useRef();
  const selectPaneViewRef = useRef();
  const restoreValRef = useRef(initialValue);
  const containerRef = useRef();
  const cmInstanceRef = useRef();

  const isDarkTheme = useSelector(getIsDarkTheme);

  /** ----------------------------------------------------------------------------
   * Hooks
   * -------------------------------------------------------------------------- */
  const isAutocompleteMode = useMemo(() => {
    return mode && [CODEMIRROR_MODE.cli, CODEMIRROR_MODE.jinja2].includes(mode);
  }, [mode]);

  const findPrevSearchItem = useCallback(
    debounce(() => {
      if (!cmInstance || !searchText || searchText === '') return;
      cmInstance.execCommand('findPrevCustom');
    }, 100),
    [cmInstance, searchText]
  );

  const findNextSearchItem = useCallback(
    debounce(() => {
      if (!cmInstance || !searchText || searchText === '') return;
      cmInstance.execCommand('findNextCustom');
    }, 100),
    [cmInstance, searchText]
  );

  const prevDiff = useCallback(
    debounce(() => {
      if (!cmInstance || !showDiffView) return;
      CodeMirror.commands.goPrevDiff(cmInstance);
      focusCm(cmInstance);
    }, 100),
    [cmInstance]
  );

  const nextDiff = useCallback(
    debounce(() => {
      if (!cmInstance || !showDiffView) return;
      CodeMirror.commands.goNextDiff(cmInstance);
      focusCm(cmInstance);
    }, 100),
    [cmInstance]
  );

  useEffect(() => {
    if (!isFunction(setCmAccessors)) return;

    setCmAccessors({
      getCmInstance: () => {
        return cmInstance;
      },

      // search
      findPrevSearchItem,
      findNextSearchItem,

      // diff view
      prevDiff,
      nextDiff,
    });
  }, [
    cmInstance,
    leftCmInstance,
    codemirrorTheme,
    showDiffView,
    collapseIdentical,
  ]);

  // subscribe to store for the current theme
  useEffect(() => {
    setCodemirrorTheme(isDarkTheme ? 'material-darker' : 'default');
  }, [isDarkTheme]);

  // load data source
  useValidEffect(
    async (getIsValid) => {
      if (!isAutocompleteMode) return;
      let source = isFunction(dataSource) ? await dataSource() : dataSource;

      if (!getIsValid()) return;
      if (formatEntryFn && source) {
        source = source.map((item) => formatEntryFn(item, codeMirrorOptions));
      }
      // sourceRef.current = source;
      ssource.load(source);
      if (selectPaneRef.current) selectPaneRef.current.load();
    },
    [isAutocompleteMode, codeMirrorOptions?.mode]
  );

  // update value based on certain dependencies
  useEffect(() => {
    updateValue(cmInstance, initialValue);
    restoreValRef.current = initialValue;
  }, [...dependencies]);

  // update value based on certain dependencies
  useEffect(() => {
    updateValue(leftCmInstance, originalScript);
  }, [originalScript, ...dependencies]);

  // initialized code mirror
  useEffect(() => {
    let cm = cmInstance;
    let leftCm = null;

    // 1. not diff view 2. diff view but toggle to regular cm
    const isInitRegularCm = !isDiffView || (isDiffView && !showDiffView);

    // extra keys
    const extraKeysConfig = getExtraKeys() || {};

    if (isInitRegularCm) {
      cm = initRegularCm(extraKeysConfig);
    } else {
      // init
      const { editor, left } = initDiffViewCm(extraKeysConfig);
      cm = editor;
      leftCm = left;
    }

    // save codemirror instance to state
    cm && setCmInstance(cm);
    cmInstanceRef.current = cm;
    leftCm && setLeftCmInstance(leftCm);

    // set event listeners
    if (cm) {
      cm.on && cm.on('change', codeMirrorOnChangeHandler);
      delayedRefresh(cm);
      cm.on && cm.on('gutterContextMenu', onGutterContextMenu);
    }

    // add border
    $(`.${cmContainer} .CodeMirror`).each(function () {
      const node = $(this);
      if (node) node.addClass('fi-input-border').addClass('tw-leading-5');
    });

    return () => {
      cm && cm.off && cm.off('change', codeMirrorOnChangeHandler);
      cm && cm.off && cm.off('gutterContextMenu', onGutterContextMenu);
    };
  }, [mode, collapseIdentical, showDiffView, originalScript, initialValue]);

  // set current mode
  useEffect(() => {
    // set current mode
    if (cmInstance && getCmMode(cmInstance) !== mode) {
      cmInstance?.setOption('mode', mode);
    }
  }, [cmInstance, mode]);

  // set current theme and style
  useEffect(() => {
    if (cmInstance) {
      cmInstance?.setOption('theme', codemirrorTheme);
    }
    if (leftCmInstance) {
      leftCmInstance?.setOption('theme', codemirrorTheme);
    }
  }, [cmInstance, leftCmInstance, codemirrorTheme]);

  useEffect(() => {
    if (cmInstance && errors && Array.isArray(errors)) {
      const prev = getErrorMsgs(previousErrors).join('\n');
      const curr = getErrorMsgs(errors).join('\n');
      const isNewError = curr !== prev;
      cmInstance.setOption('lint', {
        errors,
        getAnnotations: (text, options, instance) => {
          return validatorFn({ text, options, instance, isNewError });
        },
        ...otherCodeMirrorOptions.lint,
      });
    }
  }, [cmInstance, errors, previousErrors]);

  useEffect(() => {
    if (!lintTooltipId) return;

    const tooltip = $('.CodeMirror-lint-tooltip');
    if (tooltip.length) {
      tooltip.attr('id', lintTooltipId);
    }
  }, [lintTooltipId, errors]);

  function initRegularCm(extraKeys) {
    // skip if cm is already initialized and not diff view
    // (only diff view can toggle between regular cm and diff cm, meaning cm can be reinitialized)
    if (cmInstanceRef.current && !isDiffView) return cmInstanceRef.current;
    else if (cmInstanceRef.current && isDiffView) {
      destroyRegularCm(cmInstanceRef.current);
    }

    // init cm
    const target = document.querySelector(`#${cmTextArea}`);
    const cm = CodeMirror.fromTextArea(target, {
      lineNumbers: true,
      styleActiveLine: true,
      showMatchesOnScrollbar: true,
      autofocus: true,
      mode,
      value: initialValue,
      extraKeys,
      // search: {
      //   bottom: true,
      // },
      ...otherCodeMirrorOptions,
    });

    // set value
    cm.setValue(initialValue);

    return cm;
  }

  function initDiffViewCm(extraKeys) {
    // remove regular cm (if there is any) before init diff view cm
    if (isDiffView && showDiffView) {
      destroyRegularCm(cmInstanceRef.current);
    }

    if (!isString(originalScript) || !isString(initialValue)) {
      return {
        editor: null,
        left: null,
      };
    }

    const diffViewParams = {
      mode,
      collapseIdentical,
      orig: originalScript,
      preview: initialValue,
      hasError: hasErrors(),
      extraKeys,
      ...otherCodeMirrorOptions,
    };

    // init diff view
    return initMergeView(diffViewParams);
  }

  function getContainer() {
    const parentContainer = getParentContainer(containerRef.current);
    if (!parentContainer) return null;
    const partBase = parentContainer.shadowRoot?.querySelector('[part="base"]');
    return partBase;
  }

  function initSelectPane(instance) {
    if (selectPaneRef.current) return;

    const parentContainer = getContainer();
    const selectPaneView = selectPaneViewRef.current;

    const zIndexVal = (() => {
      try {
        if (parentContainer) {
          const _container = $(parentContainer);
          const val = parseInt(_container.css('z-index'));
          if (!isNaN(val)) return val;
        }
        return null;
      } catch (err) {
        return null;
      }
    })();

    const panel = new SelectPane(selectPaneView, {
      position: 'left', // use position left for easier top, left adjustment using adjustSelectPanePosFn
      strategy: 'absolute',
      zIndex: zIndexVal ? zIndexVal + 500 : 1700, // higher than parent container
      ...selectPaneProps,
      source: async () => {
        return ssource.source;
      },
      formatEntryFn: (opt, term, hiliter) =>
        `<span>${hiliter(opt.text || '')}</span>`,
      multipleSelect: false, // only single selection is allow
      onChange: (id, data) => {
        if (!data) return;
        onSelectOpt(data, instance);
      },
      createNewCommands: [
        {
          icon: 'add',
          text: CM_TXT_MAP.create_new_ds,
          exec: async () => {
            if (!isFunction(createCommand)) return;

            try {
              setShowSelectPane(false);
              const resp = await createCommand();
              const newItem = newChoiceParser(resp);
              const newChoice = formatEntryFn(newItem, codeMirrorOptions);
              return ssource.add(newChoice);
            } finally {
              setShowSelectPane(true);
            }
          },
        },
      ],
      editCommands: editCommands.map((editCommand) => {
        return {
          icon: 'edit',
          text: gettext('Edit'),
          exec: async (id, data) => {
            if (!isFunction(editCommand)) return;
            try {
              setShowSelectPane(false);
              const resp = await editCommand(id);
              const updatedItem = newChoiceParser(resp);
              const newChoice = formatEntryFn(updatedItem, codeMirrorOptions);
              return ssource.update(data, newChoice);
            } finally {
              setShowSelectPane(true);
            }
          },
        };
      }),
    });

    selectPaneRef.current = panel;
    setShowSelectPane(true);

    selectPaneRef.current.load();
  }

  function getExtraKeys() {
    let extraKeysConfig = {
      ...extraKeys,
      // eslint-disable-next-line
      'Ctrl-F': (instance) => {
        // Overwrite the default search dialog keymap from codemirror.
        // Instead of showing the default search dialog, focus the custom search bar
        const searchbarEl = document.getElementById(CM_SEARCHBAR_ID);
        if (searchbarEl?.firstChild?.firstChild?.firstChild?.focus) {
          setTimeout(() => {
            //fix focus due to neowise refactor searchbar
            searchbarEl.firstChild.firstChild.firstChild.focus({
              focusVisible: true,
            });
          }, 0);
        }
      },
      Tab: (instance) => {
        const el = document.querySelector(`#${selectPaneId} nw-input`);
        if (!el) {
          const doc = instance.getDoc();
          if (doc.getSelection()) {
            instance.indentSelection();
          } else {
            const cursor = instance.getCursor();
            doc.replaceRange('\t', cursor);
          }
          return;
        }
        instance.display.input.blur();
        try {
          el.setFocus && el.setFocus();
          el.focus && el.focus();
        } catch (e) {
          // handle exception if needed
        }
      },
      'Shift+Tab': (instance) => {
        const el = document.querySelector(`#${selectPaneId} nw-input`);
        if (!el) {
          const doc = instance.getDoc();
          if (doc.getSelection()) {
            instance.replaceSelection('\t', 'end');
          }
        } else {
          // TODO: put focus on previous cursor position
        }
      },
    };

    if (escapeKeyboardTrap) {
      const escapeKeyboardTrapBinding = addEscapeKeyboardTrap(
        escapeKeyboardTrap,
        resetFn
      );
      if (extraKeysConfig) {
        extraKeysConfig = { ...extraKeysConfig, ...escapeKeyboardTrapBinding };
      } else {
        extraKeysConfig = escapeKeyboardTrapBinding;
      }
    }

    return extraKeysConfig;
  }

  /** ----------------------------------------------------------------------------
   * Event handlers
   * -------------------------------------------------------------------------- */
  const codeMirrorOnChangeHandler = (instance) => {
    const newValue = instance.getValue();
    onChange(newValue);

    if (canTriggerAutoComplete(instance)) {
      // init select pane
      initSelectPane(instance);

      // adjust select pane position
      const { top, left, ...otherStyles } = adjustSelectPanePosFn(instance);
      $(`#${selectPaneId}`).css({
        top,
        left,
        ...otherStyles,
      });

      // add some delay to avoid flickering
      setTimeout(() => {
        // since select-pane-container's position has changed, add a placeholder to the autocomplete-cm-container to fill up some space
        $(`.${cmContainer}`).append(
          '<div id="placeholder" class="tw-absolute tw-hidden"></div>'
        );

        // show the select-pane-container
        $(`#${selectPaneId}`).css({
          display: 'block',
        });

        // open select pane
        openSelectPane();
      }, 100);
    } else {
      // close select pane if it is opened
      if (selectPaneRef.current && selectPaneRef.current.getIsOpen()) {
        openSelectPane(false);
      }
    }

    // refresh codemirror instance to reflect the changes
    delayedRefresh(instance);
    focusCm(instance);
  };

  const restoreDefaultHandler = async () => {
    let defaultValue = '';

    try {
      defaultValue = (await resetFn()) || '';
    } catch (e) {
      defaultValue = restoreValRef.current || '';
    }

    onChange(defaultValue);
    updateValue(cmInstance, defaultValue);
    cmInstance.refresh();
  };

  function canTriggerAutoComplete(instance) {
    try {
      if (!isAutocompleteMode) return false;

      const currentWord = getLastWordInCodemirror(instance);

      // all test the same thing: current word contains trigger characters, but must not contain any closing characters
      const matchFnRes = triggerFn(instance);

      const regexRes =
        triggerRegex &&
        triggerRegex.test(currentWord) &&
        closeRegex &&
        !closeRegex.test(currentWord);

      const charsRes =
        triggerChars.some((char) => currentWord.includes(char)) &&
        closeChars.every((char) => !currentWord.includes(char));

      return matchFnRes || regexRes || charsRes;
    } catch (err) {
      if (MACROS.SYS.CONFIG_DEBUG) {
        console.error('canTriggerAutoComplete failed:', err);
      }
      return false;
    }
  }

  function onSelectOpt(opt, instance) {
    // extract value from selected option
    if (Array.isArray(opt)) {
      opt = opt[0];
    }

    // get fill in text after select a completion item from select pane
    const doc = instance.getDoc();
    const value = onSelect(instance, opt.fillTxt);

    // autocomplete
    const { from, to } = getLastWordPos(instance);
    doc.replaceRange(value, from, to);

    // clear selection and close select pane
    selectPaneRef.current?.setSelected([]);
    openSelectPane(false);
  }

  function openSelectPane(isOpen = true) {
    return debounce(() => {
      const selectPane = selectPaneRef.current;
      if (selectPane) {
        selectPane.toggleOpen(isOpen);
      }
    }, 200)();
  }

  function getLastWordPos(instance) {
    const cursor = instance.getCursor();
    const lineContent = instance.getLine(cursor.line);
    let start = cursor.ch;
    let end = cursor.ch;
    // corrects ignoring trailing whitespaces removal
    while (start && /\w/.test(lineContent.charAt(start - 1))) --start;
    while (end < lineContent.length && /\w/.test(lineContent.charAt(end)))
      ++end;
    return {
      from: CodeMirror.Pos(cursor.line, start),
      to: CodeMirror.Pos(cursor.line, end),
    };
  }

  const onSearchbarKeyDown = (event) => {
    const key = event.key;
    switch (key) {
      case 'Enter': {
        findNextSearchItem();
        break;
      }
      default:
        break;
    }
  };

  const openShortcutsModal = () => {
    try {
      ProToolkit.openModal(
        <ShortcutsModal
          shortcuts={shortcuts ? shortcuts : []}
          isReadonly={readonlyShortcuts}
        />,
        {
          height: '50vh',
        }
      );
    } catch (e) {
      //
    }
  };

  /** ----------------------------------------------------------------------------
   * Helper functions
   * -------------------------------------------------------------------------- */
  function updateValue(instance, value) {
    instance?.setValue && instance.setValue(value);
  }

  const delayedRefresh = useCallback(
    (instance) => {
      setTimeout(() => {
        instance?.refresh && instance.refresh();
      }, refreshTimeout);
    },
    [refreshTimeout]
  );

  const hasErrors = useCallback(() => {
    if (Array.isArray(errors)) {
      return errors.filter(Boolean).length > 0;
    }

    return false;
  }, [errors]);

  const getAutoId = (str) => {
    return `${propAutoId}:${str}`;
  };

  /** ----------------------------------------------------------------------------
   * Render
   * -------------------------------------------------------------------------- */
  return (
    <div
      ref={containerRef}
      id={id}
      className={cn(
        'tw-h-full tw-grow',
        CM_CONTAINER_CLS,
        cmContainer,
        className
      )}
      {...rest}
      automation-id={propAutoId}
    >
      <SelectPanePortal container={document.body}>
        <ShowHideWrapper showCondition={showSelectPane}>
          <div id={selectPaneId} className='tw-absolute tw-hidden'>
            <div ref={selectPaneViewRef}></div>
          </div>
        </ShowHideWrapper>
      </SelectPanePortal>
      <div className='tw-flex tw-items-center tw-mb-1 tw-space-x-1 autocomplete-codemirror-toolbar'>
        {/* Validate button */}
        {onValidate && (
          <NwButton
            onClick={onValidate}
            prefix={<NwIcon name='validate' />}
            automation-id={getAutoId('validate')}
            title={gettext('Validate Script Syntax')}
          >
            {gettext('Validate')}
          </NwButton>
        )}
        {/* Autoformat button*/}
        {autoFormatConfig && (
          <NwButton
            onClick={autoFormatConfig.exec}
            prefix={<NwIcon name='timeline' />}
            automation-id={getAutoId('format')}
            title={gettext('Format Script')}
          >
            {autoFormatConfig.text}
          </NwButton>
        )}
        {/* Restore content button */}
        {(showResetBtn || resetFn) && (
          <NwButton
            onClick={restoreDefaultHandler}
            prefix={<NwIcon name='cancel' />}
            automation-id={getAutoId('reset')}
            title={gettext('Revert All Changes')}
          >
            {resetBtnTxt}
          </NwButton>
        )}

        {/* Shortcuts button */}
        <NwButton
          onClick={() => {
            try {
              openShortcutsModal();
            } catch (e) {
              //
            }
          }}
          prefix={<NwIcon name='shortcut' />}
          automation-id={getAutoId('shortcuts')}
          title={gettext('View Editor Shortcuts')}
        >
          {gettext('Shortcuts')}
        </NwButton>

        {/* Search functions */}
        <ProTable.TextSearch
          id={CM_SEARCHBAR_ID}
          className='tw-grow'
          onKeyDown={onSearchbarKeyDown}
          onChange={(newSearchText) => {
            if (!cmInstance.state.search) cmInstance.state.search = {};
            setSearchText(newSearchText);
            cmInstance.state.search.query = newSearchText;

            // cmInstance.state.onSearchCallback = (cm, query) => {
            //   // eslint-disable-next-line
            //   console.log('searching for:', query);
            // };

            cmInstance.execCommand('findCustom');
          }}
          autoIdPrefix='rc_autocomplete_cm-searchbar'
        />
        <NwButton
          title={CM_TXT_MAP.find_previous}
          onClick={findPrevSearchItem}
          automation-id={getAutoId('find_previous')}
        >
          <NwIcon name='up' />
        </NwButton>
        <NwButton
          title={CM_TXT_MAP.find_next}
          onClick={findNextSearchItem}
          automation-id={getAutoId('find_next')}
        >
          <NwIcon name='down' />
        </NwButton>

        {/* Diff view functions */}
        {!!showDiffView && showDiffBtns && (
          <>
            <NwButton
              title={gettext('Get Previous Diff')}
              onClick={prevDiff}
              automation-id={getAutoId('previous_diff')}
            >
              <NwIcon library='fa-solid' name='chevron-left' />
            </NwButton>
            <NwButton
              title={gettext('Get Next Diff')}
              onClick={nextDiff}
              automation-id={getAutoId('next_diff')}
            >
              <NwIcon library='fa-solid' name='chevron-right' />
            </NwButton>
          </>
        )}
      </div>

      {/* Codemirror instance container */}
      <textarea
        id={cmTextArea}
        className='tw-hidden'
        automation-id={getAutoId(cmTextArea)}
      ></textarea>
      {showDiffView && (
        <div
          id={DIFF_VIEW_CM_ID}
          automation-id={getAutoId(DIFF_VIEW_CM_ID)}
        ></div>
      )}
    </div>
  );
};
AutocompleteCodeMirror.displayName = 'AutocompleteCodeMirror';

AutocompleteCodeMirror.propTypes = {
  /**
   * Data source for the codemirror completion list
   * @default []
   */
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  /**
   * Function to add new entry to the completion list
   * @default null
   */
  createCommand: PropTypes.func,
  /**
   * Initial content for the codemirror instance
   * @default ''
   */
  initialValue: PropTypes.string,
  /**
   * Codemirror config options
   * @default {}
   */
  codeMirrorOptions: PropTypes.object,
  /**
   * List of dependencies to help reinitialize codemirror content
   * @default []
   */
  dependencies: PropTypes.array,
  /**
   * Delay refreshing codemirror after content changes
   * @default 100
   */
  refreshTimeout: PropTypes.number,
  /**
   * Regex to determine when to close the completion list popup
   * @default CLOSE_POPUP_REGEX
   */
  closeCharacters: PropTypes.object,
  /**
   * Callback to make some side effects after codemirror onChange event
   * (newValue: string) => void
   * @default noop
   */
  onChange: PropTypes.func,
  /**
   * Function to format each entry in the completion list
   * (currentWord: string, codeMirrorOptions: object) => void
   * codemirror show-hint addon is expecting at least this object structure:
   * { text: '', displayText: '' }.
   * Other props includes: className, render, hint, from, and to. See the codemirror manual for more details.
   * @default defaultFormatEntryFn
   */
  formatEntryFn: PropTypes.func,
  /**
   * Function to determine when to open the completion list popup (use matchFn or matchRegex)
   * @default defaultMatchFn
   */
  triggerFn: PropTypes.func,
  /**
   * Regex to determine when to open the completion list popup
   * @default null
   */
  triggerRegex: PropTypes.object,
  /**
   * Regex to determine when to close the completion list popup
   * @default null
   */
  closeRegex: PropTypes.object,
  /**
   * Regex to determine when to open the completion list popup
   * @default []
   */
  triggerChars: PropTypes.object,
  /**
   * Regex to determine when to close the completion list popup
   * @default []
   */
  closeChars: PropTypes.object,
  // onValidChange: PropTypes.func,
  /**
   * For content in diff view left pane
   */
  originalScript: PropTypes.string,
  /**
   * Show diff view or not
   * @default false
   */
  isDiffView: PropTypes.bool,
  /**
   * Show goto prev/next diff buttons or not
   * @default false
   */
  showDiffBtns: PropTypes.bool,
  /**
   * Extra props passed to SelectPane component
   */
  selectPaneProps: PropTypes.object,
  /**
   * Parsing function that is used when a choice is add/updated
   */
  newChoiceParser: PropTypes.func,
};

const SelectPanePortal = ({ container, children }) => {
  return createPortal(children, container);
};

// To auto adjust codemirror's height based on its container/toolbar height
export const useAutoHeightCodemirror = ({
  bodyEl,
  toolbarEl: _toolbarEl,
  dependencies = [],
  padding,
  offset,
}) => {
  const PADDING = padding || 30;
  const OFFSET = offset || 10;

  const toolbarEl =
    _toolbarEl || $(bodyEl).find('.autocomplete-codemirror-toolbar').get(0);
  const { height: bodyHeight = 0 } = useDimension({ el: bodyEl });
  const { height: toolbarHeight = 0 } = useDimension({ el: toolbarEl });

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const containerOverflow = useMemo(() => {
    const container =
      bodyEl?.parentElement?.shadowRoot?.querySelector('[part="body"]');
    const scrollHeight = container?.scrollHeight || 0;
    const offsetHeight = container?.offsetHeight || 0;
    return scrollHeight - offsetHeight; // to prevent scrollbar from showing on body
  }, [bodyEl]);

  useEffect(() => {
    if (!bodyEl || !isMounted) return;

    const cmHeight = Math.max(
      bodyHeight - toolbarHeight - PADDING - OFFSET - containerOverflow,
      0
    );
    if (isNaN(cmHeight)) return;

    if ($('.CodeMirror').length) {
      $('.CodeMirror').css({ height: cmHeight, minHeight: cmHeight });
    }
    if ($('.CodeMirror-merge').length) {
      $('.CodeMirror-merge').css({ height: cmHeight, minHeight: cmHeight });
    }
  }, [
    bodyEl,
    bodyHeight,
    toolbarHeight,
    PADDING,
    OFFSET,
    containerOverflow,
    ...dependencies,
  ]);
};

function getErrorMsgs(errors) {
  return transform(
    castArray(errors),
    (result, error) => {
      if (error && isString(error.error)) {
        result.push(error.error);
      }
    },
    []
  );
}
