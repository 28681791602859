import { NwIcon } from '@fafm/neowise-core';
import { isString, assign } from 'lodash';
import cn from 'classnames';
import { ProLegoTooltipWrapper } from './tooltip';

function Text(props) {
  return props.children ?? '';
}

export const CellSpan = function ({
  txt,
  css,
  title,
  indent = 1,
  style = {},
  iconProps = {},
  tooltipProps,
  ...rest
}) {
  if (indent > 1) style = { marginLeft: (indent - 1) * 18 };

  if (css) {
    const icon = isString(css) ? (
      <i
        className={css}
        title={title}
        style={{ marginRight: 2 }}
        {...iconProps}
      ></i>
    ) : Array.isArray(css) ? (
      <span className='nw-stack'>
        {css.map((icon, idx) => {
          if (idx === css.length - 1) {
            return (
              <NwIcon
                key={icon.name}
                {...assign(icon, {
                  className: cn(icon.className, 'nw-stack-br'),
                })}
                title={title}
                {...iconProps}
              ></NwIcon>
            );
          } else {
            return (
              <NwIcon
                key={icon.name}
                {...assign(icon, { className: cn(icon.className, 'tw-mr-1') })}
              ></NwIcon>
            );
          }
        })}
      </span>
    ) : (
      <NwIcon
        {...assign(css, { className: cn(css.className, 'tw-mr-1') })}
        title={title}
        {...iconProps}
      ></NwIcon>
    );
    return (
      <span
        style={style}
        {...rest}
        className={cn('tw-flex tw-items-center', rest.className)}
      >
        {tooltipProps ? (
          <ProLegoTooltipWrapper {...tooltipProps}>
            {icon}
          </ProLegoTooltipWrapper>
        ) : (
          <>{icon}</>
        )}
        <span className={'txt-table'}>
          <Text>{txt}</Text>
        </span>
      </span>
    );
  } else
    return (
      <span style={style} {...rest}>
        <Text>{txt}</Text>
      </span>
    );
};
