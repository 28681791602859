import { useEffect, useRef, useState } from 'react';

export const LoadingBar = ({
  isLoading,
  hasError = false,
  zIndex = 1,
  'automation-id': autoId,
}) => {
  const [percent, updatePercent] = useState(-1);

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isLoading) {
      var stop = tick((tick) => {
        // var fabNew = (1 - 1/fab) * 98;
        tick.lock();
        var per = (196 * Math.atan(tick.time / 1000)) / Math.PI;
        mounted.current && updatePercent(per);
        tick.unlock();
      });

      return () => {
        stop();
        mounted.current && updatePercent(100);
        setTimeout(() => {
          mounted.current && updatePercent(-1);
        }, 600);
      };
    }
  }, [isLoading]);

  return percent < 0 ? null : (
    <nw-progress-bar
      percentage={percent}
      automation-id={autoId}
      style={{
        '--height': '3px',
        '--indicator-color': hasError
          ? 'rgb(var(--nw-color-error))'
          : undefined,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex,
      }}
    ></nw-progress-bar>
  );
};
LoadingBar.displayName = 'LoadingBar';

function tick(fn) {
  var doTick = true;

  var tick = {
    start: null,
    locked: false,
    lock() {
      this.locked = true;
    },
    unlock() {
      this.locked = false;
    },
  };

  function step(timestamp) {
    if (!tick.start) tick.start = timestamp;
    tick.time = timestamp - tick.start;
    if (doTick) {
      fn(tick);
      if (!tick.locked) {
        requestAnimationFrame(step);
      }
    }
  }

  requestAnimationFrame(step);

  return () => {
    doTick = false;
  };
}
