import cn from 'classnames';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from './ra-auth/useAuth';
import { registerIconLibrary } from '@fafm/neowise-core';
import { ProToolkit } from '@fafm/neowise-pro';
// TODO: rewrite filego in react
import { registerIconLibrary as nwRegisterIconLibrary } from '@fafm/neowise';

import iconFafm from '@fafm/neowise-icons/dist/fafm.svg';
import iconFgtStandard from '@fafm/neowise-icons/dist/fgt-standard.svg';
import iconFgtBasic from '@fafm/neowise-icons/dist/fgt-basic.svg';
import iconFgtFeatures from '@fafm/neowise-icons/dist/fgt-features.svg';
import iconFgtProducts from '@fafm/neowise-icons/dist/fgt-products.svg';
import iconFaSolid from '@fafm/neowise-icons/dist/fa-solid.svg';
import iconFaOutlined from '@fafm/neowise-icons/dist/fa-outlined.svg';
import iconFaBrands from '@fafm/neowise-icons/dist/fa-brands.svg';
import iconFafm48px from '@fafm/neowise-icons/dist/fafm-48px.svg';
import iconFgtAppCategory from '@fafm/neowise-icons/dist/fgt-app-category.svg';
import iconFgtThreats from '@fafm/neowise-icons/dist/fgt-threats.svg';
import { MessageBoxRoot } from 'fi_widgets/fi-messagebox/message_box';
import { getLoginEnv } from 'fistore/auth/selectors';

nwRegisterIconLibrary('default', {
  resolver: (name) => `${iconFafm}#${name}`,
});

[
  ['fafm', iconFafm],
  ['fafm-48px', iconFafm48px],
  ['fgt-basic', iconFgtBasic],
  ['fgt-features', iconFgtFeatures],
  ['fgt-standard', iconFgtStandard],
  ['fgt-products', iconFgtProducts],
  ['fa-solid', iconFaSolid],
  ['fa-outlined', iconFaOutlined],
  ['fa-brands', iconFaBrands],
  ['fgt-app-category', iconFgtAppCategory],
  ['fgt-threats', iconFgtThreats],
].forEach(([libName, url]) => {
  registerIconLibrary(libName, {
    src: url,
    isDefault: libName === 'fafm',
  });

  nwRegisterIconLibrary(libName, {
    resolver: (name) => `${url}#${name}`,
  });
});

const mainLayoutClassName = cn(
  'tw-h-screen',
  'tw-flex tw-flex-col',
  'tw-w-full tw-overflow-hidden',
  'tw-bg-no-repeat tw-bg-cover tw-bg-right-bottom tw-bg-body-bg tw-bg-wallpaper'
);

// This is temporary code, TODO: remove after fix
window.addEventListener('error', (err) => {
  if (
    err.message ===
    'ResizeObserver loop completed with undelivered notifications.'
  ) {
    err.stopImmediatePropagation();
    err.stopPropagation();
    err.preventDefault();
    // TODO:
    // https://gitlab.fafmgui.corp.fortinet.com/npm/neowise-pro/-/issues/463
    // need further investigation

    console.error('resize is busy...');
  }
});

if (MACROS.SYS.CONFIG_DEBUG) {
  window.MACROS = MACROS;
}

export function AppRoot() {
  const loginEnv = useSelector(getLoginEnv);
  useEffect(() => {
    const hostName = loginEnv?.host_name;
    document.title = [MACROS.SYS.CONFIG_PROD_NAME, hostName]
      .filter(Boolean)
      .join(': ');
  }, [loginEnv?.host_name]);

  return (
    <AuthProvider>
      <ProToolkit.ModalRoot>
        <div id='id_layout_main' className={mainLayoutClassName}>
          <Outlet />
        </div>
      </ProToolkit.ModalRoot>
      <MessageBoxRoot />
    </AuthProvider>
  );
}
