import { last, reduce } from 'lodash';
import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import pnoApps from 'fi-web/fi-routing/all-apps/pno/pno';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionAdomData } from 'fistore/session/adom/selectors';
import { useMatches } from 'react-router-dom';
import { goApp } from 'fistore/routing/slice';
import {
  load_workspace_adoms,
  load_workspace_pkgs,
} from 'fistore/workspace_res/request';

const PNO_ROUTES = {
  path: 'pno',
  handle: {
    appUniKey: 'pno',
  },
  RenderErrorBoundary,
  lazy: async () => {
    const mod = await import('./pno_main_view/PnoMainView');
    return {
      Component: () => {
        const dispatch = useDispatch();
        const adom = useSelector(getSessionAdomData);
        const adomOid = adom?.oid;
        const match = last(useMatches());
        const handle = match?.handle;

        React.useEffect(() => {
          load_workspace_adoms(dispatch);
        }, []);

        React.useEffect(() => {
          if (adomOid) {
            load_workspace_pkgs(dispatch, adomOid);

            import('react_apps/ra_device_util/device_redux').then(
              ({ fiDeviceRedux }) => {
                const doReload = true;
                fiDeviceRedux.loadAssignedPkgs(adomOid, doReload);
              }
            );
          }
        }, [adomOid]);

        React.useEffect(() => {
          if (!handle || handle.appUniKey === 'pno') {
            dispatch(goApp({ key: 'pno_policy' }));
          }
        }, [handle]);

        return <mod.PnoMainView />;
      },
    };
  },
  children: reduce(
    pnoApps,
    (children, { path: appPath }, appUniKey) => {
      if (appPath) {
        children.push({
          path: appUniKey,
          handle: {
            appUniKey,
          },
          Component: null,
        });
      }
      return children;
    },
    []
  ),
};

export default PNO_ROUTES;
