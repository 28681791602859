import { createPromiseAction } from '../utils/saga-promise';

const actionName = (name) => 'LOGVIEW_' + name;

export const fetchLogviewContentMenu = createPromiseAction(
  actionName('FETCH_LOGVIEW_CONTENT_MENU')
);

export const fetchMemberTopology = createPromiseAction(
  actionName('FETCH_MEMBER_TOPOLOGY')
);
