import React, { useState } from 'react';
import { ProForm } from '@fafm/neowise-pro';
import { isFunction } from 'lodash';
import { FmkErrorSpan } from '../formik_layout/FmkErrorSpan';
import { useFormikContext } from 'formik';

const { Line } = ProForm;

const callAll =
  (...fns) =>
  (...args) => {
    fns.forEach((fn) => isFunction(fn) && fn(...args));
  };

export const withHelpTextOnFocus = (FmkComponent) => {
  return (props) => {
    const [showHelpText, setShowHelpText] = useState(false);

    return (
      <FmkComponent
        {...props}
        helpText={showHelpText ? props?.['help-on-focus'] : undefined}
        onFocus={callAll(
          () => setShowHelpText(true),
          props.onNwFocus,
          props.onFocus
        )}
        onBlur={callAll(
          () => setShowHelpText(false),
          props.onNwBlur,
          props.onBlur
        )}
      />
    );
  };
};

//make help info display like
//http://neowise.fafmgui.corp.fortinet.com/pro-form - Input with Units
export const withHelpInfo = (Component) => {
  return (props) => {
    const formikContext = useFormikContext();

    const { name, helpInfo, showError = true } = props;

    return (
      <>
        <Line>
          <Component class='tw-grow' {...props} />
          <span className='tw-whitespace-nowrap'>{helpInfo}</span>
        </Line>
        {showError && formikContext && name && <FmkErrorSpan name={name} />}
      </>
    );
  };
};
