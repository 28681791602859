// common formatters ONLY !
import { isString } from 'lodash';

/**
 *  Filter to format interface name with alias
 */
export function formatInterfaceName(interfaceObj, name) {
  var _name = name;
  if (interfaceObj && interfaceObj.alias) {
    _name = isString(interfaceObj.name)
      ? interfaceObj.name + ' (' + interfaceObj.alias + ')'
      : _name + ' (' + interfaceObj.alias + ')';
  }
  return _name;
}
