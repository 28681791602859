import { Suspense, useState, useEffect, useRef } from 'react';
import {
  useLoaderData,
  Await,
  useNavigate,
  useLocation,
  useAsyncValue,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsUpgrading } from 'fistore/auth/selectors';
import { Spinner } from './Spinner';
import LoginPageContainer from 'react_apps/ra-auth/widgets/LoginPageContainer';
import { genPreBanner } from 'react_apps/ra-auth/effects/preBanner';
import { genPkiLogin } from 'react_apps/ra-auth/effects/pkiLogin';
import { ssoSp } from 'react_apps/ra-auth/hooks/useSSOLogin';

function LoginPageContent({ renderLoginForm }) {
  const loginEnv = useAsyncValue();
  const isUpgrading = useSelector(getIsUpgrading);
  const navigate = useNavigate();
  const location = useLocation();
  const [renderForm, setRenderForm] = useState(false);
  const preBanner = genPreBanner(useRef(false));
  const pkiLogin = genPkiLogin(() => {
    navigate('/postlogin');
  }, useRef(false));
  const [params] = useSearchParams();

  useEffect(() => {
    if (isUpgrading) {
      navigate('/upgrading', {
        state: { from: location.pathname },
      });
      return;
    }
    if (loginEnv.require_vm_lic) {
      navigate('/newliclogin');
      return;
    }

    // if configured auto redirect to SSO login page, navigate there
    // directly.
    if (loginEnv.sso_sp_auto_redirect && !params.has('force_local')) {
      return ssoSp(loginEnv.sso_url, params)();
    }

    (async () => {
      for (const fn of [preBanner, pkiLogin]) {
        if (await fn(loginEnv)) {
          setRenderForm(false);
          return;
        }
      }
      setRenderForm(true);
    })();
  }, [loginEnv, isUpgrading, params]);

  return renderForm ? renderLoginForm({ loginEnv }) : <Spinner />;
}

export function LoginPageBase({ header = null, renderLoginForm = null }) {
  const { loginEnv } = useLoaderData();

  return (
    <LoginPageContainer>
      {header}
      <Suspense fallback={<Spinner />}>
        <Await resolve={loginEnv}>
          <LoginPageContent renderLoginForm={renderLoginForm} />
        </Await>
      </Suspense>
    </LoginPageContainer>
  );
}
