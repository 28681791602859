import { useCallback, useMemo, useState } from 'react';
import { castArray, get, isFunction, isString } from 'lodash';

import { ProLego } from '@fafm/neowise-pro';
import { useValidEffect } from 'rh_util_hooks';

import { DeviceFormatter } from './DeviceFormatter';
import { canDevBeManaged, getDevice } from './util';
import { fiDeviceDataLoader } from '../device_data_loader';
import { DEFAULT_GRPS } from '../constant';

export { DeviceSSelect, formatDevValue, defaultListParser, getInitSelectedDev };

const DeviceSSelect = ({
  // selected device value in form of "deviceName/vdomName"
  value,
  source,

  // opts
  showGlobal,
  showGlobalIfVdomEnabled,
  hideVdom,
  listParser,
  listFilter,
  formatChoiceHTML,
  formatSelectedHTML,
  searchFn,
  deps = [],
  multipleSelect = false,
  includeDevGrp = false,
  showTooltip = false,
  includeFortiSASE = true,

  // callbacks
  onChange,
  onLoaded,

  ...rest
}) => {
  const [deviceList, setDeviceList] = useState([]);

  const devFormatter = useMemo(() => {
    const { DeviceRow, formatDevHTML, formatSelectedDevHTML, searchFn } =
      DeviceFormatter.getDeviceFormatters({
        extraInfoAttrs: ['ip', 'platform_str'],
        hideVdom,
      });
    return { DeviceRow, formatDevHTML, formatSelectedDevHTML, searchFn };
  }, []);

  useValidEffect(
    async (getIsValid) => {
      if (source) {
        const resp = isFunction(source) ? await source() : source;
        const devices = resp.map((dev) => {
          if (!dev._oData) {
            dev._oData = getDevice(`${dev.oid}`);
          }
          return dev;
        });
        onLoaded?.(devices);
        if (getIsValid()) {
          setDeviceList(devices);
          return;
        }
      }

      const [devResp, devGrpResp, saseDevResp] = await Promise.all([
        fiDeviceDataLoader.getDevices(),
        includeDevGrp ? fiDeviceDataLoader.getDeviceGroups() : null,
        includeFortiSASE ? fiDeviceDataLoader.getSaseDevices() : null,
      ]);

      // init devices
      const devs = (devResp || []).concat(saseDevResp || []);
      let parsed = [];

      // parse devices
      if (listParser) {
        // Customize your parser, by returning {id, text, conn_status} for each list item
        parsed = listParser({
          devices: devs,
          formatId: formatDevValue,
          includeFortiSASE,
        });
      } else {
        parsed = defaultListParser({
          devices: devs,
          hideVdom,
          showGlobal,
          showGlobalIfVdomEnabled,
          includeFortiSASE,
        });
      }

      // filter devices
      const devList = listFilter
        ? listFilter({ parsedDevices: parsed })
        : parsed;

      if (!includeDevGrp) {
        onLoaded?.(devList);
        if (getIsValid()) {
          setDeviceList(devList);
        }
        return;
      }

      // init groups
      const devGrpList = devGrpResp.reduce(function (acc, cur) {
        if (cur.defaultGroup || DEFAULT_GRPS.includes(cur.oid)) {
          return acc;
        }

        acc.push({
          id: formatDevValue({ devName: cur.name }),
          oid: cur.oid,
          name: cur.name,
          text: cur.name,
          isGrp: true,
          _oData: cur,
        });

        return acc;
      }, []);

      const allData = prepareDevNGrpData(devList, devGrpList);
      onLoaded?.(allData);
      if (getIsValid()) {
        setDeviceList(allData);
      }
    },
    [listParser, listFilter, ...deps]
  );

  const onDeviceChange = useCallback(
    (id, item, prevSelected) => {
      if (!item || (!Array.isArray(item) && !item.id)) {
        return onChange(item, prevSelected);
      }

      item = castArray(item);
      const selected = item
        .map((item) => {
          const [dev, vdom] = item.id.split('/');
          return {
            ...item,
            name: dev,
            vdom,
          };
        })
        .filter(Boolean);

      onChange(multipleSelect ? selected : selected[0], prevSelected);
    },
    [onChange]
  );

  const currentDev = useMemo(() => {
    if (multipleSelect) {
      if (!Array.isArray(value)) return [];

      return value.map(getSelectedDev);
    }

    return getSelectedDev(value);
  }, [value]);

  return (
    <ProLego.SSelect
      value={currentDev}
      source={deviceList}
      formatChoiceHTML={formatChoiceHTML || devFormatter.formatDevHTML}
      formatSelectedHTML={
        formatSelectedHTML || devFormatter.formatSelectedDevHTML
      }
      searchFn={searchFn || devFormatter.searchFn}
      onChange={onDeviceChange}
      multipleSelect={multipleSelect}
      showTooltip={showTooltip}
      {...rest}
    ></ProLego.SSelect>
  );
};

const formatDevValue = ({ devName, vdomName }) => {
  if (!vdomName) {
    return devName;
  }
  return `${devName}/${vdomName}`;
};

/**
 * Structure of each device entry
{
  id: 'deviceName/vdomName',
  text: 'deviceName [vdomName]',
  conn_status: 0 or 1,
  oid: device oid,
  vdom_oid: vdom oid,
  useCustomText: 'deviceName [vdomName]', // to use the same content provided in text, otherwise use the text based on device/vdom name
  _oData: the original device object,
}
 */
const defaultListParser = ({
  devices,
  hideVdom,
  showGlobal,
  showGlobalIfVdomEnabled,
  includeFortiSASE,
}) => {
  return devices.reduce(function (acc, cur) {
    // configurable device
    // need to check if device is configurable
    if (
      canDevBeManaged(cur.os_type) === false &&
      !includeFortiSASE &&
      cur.os_type === MACROS.DVM.DVM_OS_TYPE_FSS
    ) {
      return acc;
    }

    const conn = cur.connection ? cur.connection.conn : cur.conn;
    const globalDev = {
      id: formatDevValue({ devName: cur.name, vdomName: 'global' }),
      text: formatDevText(cur.name, 'global'),
      _oData: cur,
      oid: cur.did,
      vdom_oid: `${MACROS.DVM.CDB_DEFAULT_GLOBAL_OID}`,
      isGlobal: true,
      useCustomText: true,
      conn_status: conn,
    };

    // hide vdoms
    if (hideVdom || !cur.vdom_status || !Array.isArray(cur.vdoms)) {
      const parent = {
        id: formatDevValue({ devName: cur.name, vdomName: '' }),
        text: formatDevText(cur.name, null, cur),
        _oData: cur,
        oid: cur.did,
        excluded: true,
        conn_status: conn,
      };

      // device without vdom
      if (showGlobal) {
        parent.children = [globalDev];
        acc.push(parent);
      } else {
        const rootVdom = {
          id: formatDevValue({ devName: cur.name, vdomName: 'root' }),
          text: formatDevText(cur.name, null, cur),
          _oData: cur,
          oid: cur.did,
          vdom_oid: `${MACROS.DVM.CDB_DEFAULT_ROOT_OID}`,
          conn_status: conn,
        };
        acc.push(rootVdom);
      }
    } else {
      // device with vdom, shown as children nodes
      let children = [];

      if (showGlobal || showGlobalIfVdomEnabled) {
        children.push(globalDev);
      }

      children = children.concat(
        cur.vdoms.map((vdom) => ({
          id: formatDevValue({ devName: cur.name, vdomName: vdom.name }),
          text: formatDevText(cur.name, vdom.name),
          _oData: vdom,
          oid: cur.did,
          vdom_oid: vdom.vid,
          conn_status: conn,
        }))
      );

      acc.push({
        id: formatDevValue({ devName: cur.name, vdomName: '' }),
        text: formatDevText(cur.name, null, cur),
        _oData: cur,
        oid: cur.did,
        excluded: true,
        children,
        conn_status: conn,
      });
    }

    return acc;
  }, []);
};

function formatDevText(devName, vdomName, oDev) {
  const name = vdomName ? `${devName} [${vdomName}]` : devName;
  if (oDev) {
    const ip = `IP: ${oDev.ip || 'N/A'}, `;
    const platform_str = oDev.platform_str
      ? `Platform: ${oDev.platform_str}`
      : '';
    return `${name} (${ip}${platform_str})`;
  }

  return name;
}

function getSelectedDev(value) {
  const currentVal = Array.isArray(value) ? value[0] : value;
  if (!currentVal) return null;

  // provide id, return directly
  if (isString(currentVal)) return currentVal;

  // provide object, get id from the device object
  const devId =
    currentVal.id ||
    formatDevValue({
      devName: currentVal.name,
      vdomName: currentVal.vdom,
      isGrp: currentVal.isGrp,
    });
  return devId;
}

function getInitSelectedDev(devList) {
  if (!Array.isArray(devList) || !devList.length) return null;
  const first = devList[0];

  if (first.children && first.children.length) {
    return get(first, 'children.0.id');
  }

  return first.id;
}

function prepareDevNGrpData(devs, devGrps) {
  const allData = [];

  if (devGrps.length) {
    allData.push({
      id: 'group_device_groups',
      text: gettext('Device Groups'),
      isGrp: true,
      excluded: true,
      children: devGrps,
      showIcon: false,
      useCustomText: true,
    });
  }
  if (devs.length) {
    allData.push({
      id: 'group_devices',
      text: gettext('Devices'),
      excluded: true,
      children: devs,
      showIcon: false,
      useCustomText: true,
    });
  }

  return allData;
}
