import { fiHttpPost } from 'fi-web/fi-http/http';
import { apiResultFirst } from 'fistore/utils/api';

export async function postAuth(data, config = {}) {
  try {
    const resp = await fiHttpPost('/cgi-bin/module/flatui_auth', data, config);
    return resp?.result?.[0];
  } catch (err) {
    // Need to throw again so the downstream knows it failed
    throw err?.result?.[0] ?? err;
  }
}

export async function postResetTimer() {
  return apiResultFirst(fiHttpPost, '/cgi-bin/module/flatui_proxy', {
    url: '/gui/session-timer',
    method: 'update',
  });
}
