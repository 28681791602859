import { fiHttp } from 'fi-http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: [],
};

const slice = createSlice({
  name: 'customWidget',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomWidgets.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomWidgets.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(createCustomWidget.fulfilled, (state, { payload }) => {
        state.data.push(payload);
      })
      .addCase(updateCustomWidget.fulfilled, (state, { payload }) => {
        let idx = state.data.findIndex((x) => x.uuid === payload.uuid);
        state.data.splice(idx, 1, payload);
      })
      .addCase(deleteCustomWidget.fulfilled, (state, { payload }) => {
        state.data = state.data.filter((x) => x.uuid !== payload.uuid);
      });
  },
});

const _createOrUpdateCW = async (data, { rejectWithValue }) => {
  const resp = await fiHttp.post('/p/noc/create_custom_widget/', data);
  if (resp?.data?.status?.code) {
    return rejectWithValue(resp.data.status.message);
  }
  return { ...data, uuid: data.uuid || resp.data.data.uuid };
};

const fetchCustomWidgets = createAsyncThunk(
  'soc/customWidget/FETCH_CUSTOM_WIDGETS',
  async ({ signal }) => {
    const resp = await fiHttp.get('/p/noc/get_user_custom_widgets/', {
      signal,
    });
    return resp.data;
  }
);

const createCustomWidget = createAsyncThunk(
  'soc/customWidget/CREATE_CUSTOM_WIDGET',
  _createOrUpdateCW
);

const updateCustomWidget = createAsyncThunk(
  'soc/customWidget/UPDATE_CUSTOM_WIDGET',
  _createOrUpdateCW
);

const deleteCustomWidget = createAsyncThunk(
  'soc/customWidget/DELETE_CUSTOM_WIDGET',
  async (uuid, { rejectWithValue }) => {
    const resp = await fiHttp.post('/p/noc/remove_custom_widget/', {
      uuid: uuid,
    });
    if (resp?.data?.status?.code) {
      return rejectWithValue(resp.data.status.message);
    }
    return { uuid };
  }
);

export {
  fetchCustomWidgets,
  createCustomWidget,
  updateCustomWidget,
  deleteCustomWidget,
};

export default slice.reducer;
