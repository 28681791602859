import * as fp from '@fafm/fp';
import { fiFmgHttp } from 'fi-http';
import { isString } from 'lodash';
import { fiAdom, fiSysConfig } from 'fi-session';
import { fiCollections } from '../cache/log_collection_cache';
import $ from 'jquery';

/**
 * This Service is used to get log stats for each device
 * Data is stored in fiCollection in the client side
 */
const LOG_STATS_COLLID = 'log-stats';
const LOG_ID_ATTR = 'devid';
let realtimeNoLogging = 900; //default 15 min, 6 hr (in seconds)
let uploadNoLogging = 21600;
let remoteFazSystemTimestamp = _getFallbackTimestamp();

// Initialize functions to fetch log data
// This is called only when it is needed
_registerLogCollections();

export const fiDvmLogDataService = {
  // /gui/adoms/${adomOid}/devices/log-stats returns both log rate/usage
  isLogStatsReady,
  isLogStatsReadyPromise,
  getLogStatsByDevice,
  logStatsCollection,
  forceReloadLogCollection,
  reloadLogCollectionIfNeeded,
  getNoLoggingTimeDefs,
  getRemoteFazSystemTimestamp,
};

//
// Log Stats (combined both log rate and log usage)
//

function isLogStatsReady() {
  return logStatsCollection().getStatus() === 'READY';
}

function isLogStatsReadyPromise() {
  return logStatsCollection().isReady();
}

function getLogStatsByDevice(device, adomOid = fiAdom.current().oid) {
  return logStatsCollection({ adomOid }).getItem(getDeviceVdomName(device));
}

function logStatsCollection({ adomOid, forceReload = false } = {}) {
  let aid = adomOid || fiAdom.current().oid;
  return fiCollections.getCollection(aid, LOG_STATS_COLLID, forceReload);
}

function _hasLogCollection() {
  return !!logStatsCollection();
}

function forceReloadLogCollection() {
  logStatsCollection({ forceReload: true });
}

// If log collection exists in fiCollection, do a force reload to get the latest log stats
function reloadLogCollectionIfNeeded() {
  if (_hasLogCollection()) {
    forceReloadLogCollection();
  }
}

function getNoLoggingTimeDefs() {
  return {
    realtimeNoLogging,
    uploadNoLogging,
  };
}

function getRemoteFazSystemTimestamp() {
  return remoteFazSystemTimestamp;
}

function _getFallbackTimestamp() {
  return fiSysConfig.current()?.sysTimestamp ?? Math.floor(Date.now() / 1000);
}

async function loadLogStatsCollection(adomOid) {
  const defer = $.Deferred();
  const aid = adomOid || fiAdom.current().oid;
  const req = {
    method: 'get',
    url: `/gui/adoms/${aid}/devices/log-stats`,
  };

  const resp = await fiFmgHttp.post(req);
  const logRateData = fp.path([0, 'data', 'devs'], resp);
  const newRealtime = fp.path(
    [0, 'data', 'log-interval-dev-no-logging-realtime'],
    resp
  );
  if (isFinite(parseInt(newRealtime, 10))) realtimeNoLogging = newRealtime;
  const newUpload = fp.path(
    [0, 'data', 'log-interval-dev-no-logging-upload'],
    resp
  );
  if (isFinite(parseInt(newUpload, 10))) uploadNoLogging = newUpload;
  const fazTimestamp = fp.path([0, 'data', 'system-timestamp'], resp);
  if (isFinite(parseInt(fazTimestamp, 10)))
    remoteFazSystemTimestamp = fazTimestamp;
  else remoteFazSystemTimestamp = _getFallbackTimestamp();

  if (logRateData) {
    defer.resolve(_parseLogStats(logRateData));
  } else {
    defer.resolve([]);
  }

  return defer.promise();
}

function _parseLogStats(data) {
  // Flatten data structure by de-nesting VDOM data
  if (!Array.isArray(data)) {
    return [];
  }

  const parsed = [];
  for (const devLogStats of data) {
    const vdomsLogStats = _parseVdomLogStats(devLogStats);
    parsed.push(...vdomsLogStats);
  }

  _parseTopDeviceUsage(parsed);

  return parsed;
}

const statusOrder = [
  MACROS.DVM.LOGSTAT_DEVST_UP,
  MACROS.DVM.LOGSTAT_DEVST_FWD,
  MACROS.DVM.LOGSTAT_DEVST_DOWN,
  MACROS.DVM.LOGSTAT_DEVST_HASYNC,
  MACROS.DVM.LOGSTAT_DEVST_UNKNOWN,
];

// for storage usage chart
function _parseTopDeviceUsage(parsed) {
  //collect ha clusters to get total storage for each ha cluster
  const haClusters = {};

  const deviceLevel = parsed.filter((entry) => !entry.vdom);

  //group devices by cluster name (devname)
  for (const device of deviceLevel) {
    if (device['is-ha']) {
      if (!haClusters[device.devname])
        haClusters[device.devname] = { devices: [device], usage: 0 };
      else haClusters[device.devname].devices.push(device);
    } else {
      haClusters[device.devid] = { devices: [device], usage: 0 };
    }
  }

  const haClusterList = Object.values(haClusters);

  //get total usage for each cluster
  haClusterList.forEach((haCluster) => {
    const { devices } = haCluster;
    let totalUsage = 0;
    let maxConnStatus = devices[0]?.status;
    for (const device of devices) {
      totalUsage += device.totalStorageUsed;
      maxConnStatus =
        statusOrder.indexOf(maxConnStatus) < statusOrder.indexOf(device.status)
          ? maxConnStatus
          : device.status;
    }

    devices.forEach((device) => {
      device.status = maxConnStatus;
    });
    haCluster.usage = totalUsage;
  });

  //sort clusters by top usage
  haClusterList.sort((a, b) => {
    return b.usage - a.usage;
  });

  //add top_1, top_2, top_3, other designation to all devices under each cluster
  let idx = 1;
  const topstr = 'top_';
  haClusterList.forEach((haCluster) => {
    const level = idx <= 3 ? topstr + idx++ : 'other';
    haCluster.devices.forEach((logstat) => {
      logstat._diskUsageLevel_ = level;
      if (Array.isArray(logstat.vdoms)) {
        logstat.vdoms.forEach((vd) => {
          vd._diskUsageLevel_ = level;
        });
      }
    });
  });
}

function getTotalUsageFromUsageData(usageData) {
  return (usageData['log-db-size'] ?? 0) + (usageData['log-disk-size'] ?? 0);
}

function _parseVdomLogStats(devLogStats) {
  let parsed = [];
  let latestTimeStamp = 0;
  let totalLogRate = 0;
  const devLogStatsCopy = JSON.parse(JSON.stringify(devLogStats));
  const vdoms = devLogStatsCopy.vdoms;

  let totalStorageUsed = 0;

  if (vdoms && Array.isArray(vdoms)) {
    for (const vdomLogStats of vdoms) {
      let logsTimes = vdomLogStats?.['logstat-info']?.split(',') || [0];
      latestTimeStamp = Math.max(...logsTimes);
      const vdomLogRate = vdomLogStats['lograte'] || 0;
      totalLogRate += vdomLogRate;

      // append these data to vdom log stats
      const attrsToAdd = [
        'encrypted-forwarding',
        'encrypted-logging',
        'logging-mode',
        'status',
      ];
      attrsToAdd.forEach(
        (attr) => (vdomLogStats[attr] = devLogStatsCopy[attr])
      );
      vdomLogStats[LOG_ID_ATTR] =
        devLogStatsCopy[LOG_ID_ATTR] + '-' + vdomLogStats.vdom;
      parsed.push(vdomLogStats);

      const vdomStorageUsed = getTotalUsageFromUsageData(vdomLogStats);
      vdomLogStats.storageUsed = vdomStorageUsed;

      // calculate total quota used by dev
      totalStorageUsed += vdomStorageUsed;
    }

    devLogStatsCopy['last-log-timestamp'] = latestTimeStamp || 0; // append the latest log timestamp to device global
    devLogStatsCopy['lograte'] = totalLogRate; // append the total log rate to device global
  }

  devLogStatsCopy.totalStorageUsed = totalStorageUsed;
  if (devLogStatsCopy['logstat-info']) {
    let logsTimes = devLogStatsCopy['logstat-info']?.split(',') || [0];
    if (logsTimes.length && logsTimes.length > 4) {
      logsTimes = logsTimes.slice(0, 5); //refer to logstat_def.h > logstat_dev_info_string()
    }
    const lastLogTimestamp = Math.max(...logsTimes);
    devLogStatsCopy['last-log-timestamp'] = lastLogTimestamp;
  }

  parsed.push(devLogStatsCopy);

  return parsed;
}

//
// Common functions
//

function getDeviceVdomName(device) {
  if (!device) return;
  if (isString(device)) return device;
  return device.platform === 'vdom'
    ? device['sn'] + '-' + device['name']
    : device['sn'];
}

function _registerLogCollections() {
  fiCollections.registerCollection(LOG_STATS_COLLID, {
    idAttr: LOG_ID_ATTR,
    loadFn: loadLogStatsCollection,
  });
}
