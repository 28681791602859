import neowiseWrapper from '@fafm/neowise-wrapper';
import { forwardRef } from 'react';

export { renderDropdown };
const NwMenu = neowiseWrapper('nw-menu');

function renderDropdown(actions, dropdownProps) {
  return (
    <NavDropdown key='more' actions={actions} dropdownProps={dropdownProps} />
  );
}

const NavDropdown = forwardRef(({ actions, dropdownProps, ...props }, ref) => {
  return (
    <nw-dropdown key='more' class='tw-pl-2'>
      <nw-button slot='trigger' caret {...dropdownProps} {...props} ref={ref}>
        <nw-icon
          aria-hidden='true'
          library='fa-solid'
          name='ellipsis-v'
          class='tw-mr-1'
        ></nw-icon>
        {gettext('More')}
      </nw-button>
      <NwMenu
        onNwSelect={(evt) => {
          const key = evt.detail.item.getAttribute('data-action-key');
          const action = actions.find((action) => action.key === key);
          if (action) {
            action.onClick();
          }
        }}
      >
        {actions.map((action) => {
          // eslint-disable-next-line
          const { key, label, name, src, library, onClick, ...rest } = action;
          return (
            <nw-menu-item key={key} data-action-key={key} {...rest}>
              <nw-icon
                aria-hidden='true'
                name={name}
                src={src}
                library={library}
                class='tw-mr-1'
              ></nw-icon>
              {label}
            </nw-menu-item>
          );
        })}
      </NwMenu>
    </nw-dropdown>
  );
});
