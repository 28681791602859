export const fiBufferService = {
  dragDrop: new buffer(),
  clipboard: new buffer(),
  wizard: new buffer(),
  dvmgroup: new buffer(),
  dvmdev: new buffer(),
};

function buffer() {
  this._data = null;
  this._type = '';
  this._act = '';
  this._src = '';

  this.type = function (t) {
    if (typeof t !== 'undefined') {
      this._type = t;
      return this;
    }

    return this._type;
  };

  this.src = function (s) {
    if (typeof s !== 'undefined') {
      this._src = s;
      return this;
    }

    return this._src;
  };

  this.act = function (a) {
    if (typeof t !== 'undefined') {
      this._act = a;
      return this;
    }

    return this._act;
  };

  this.data = function (d) {
    if (typeof d !== 'undefined') {
      this._data = d;
      return this;
    }

    return this._data;
  };

  this.set = function (d) {
    if (!d) {
      this.clean();
      return this;
    }

    this._data = d.data || null;
    this._type = d.type || '';
    this._act = d.act || '';
    this._src = d.src || '';
    return this;
  };

  this.get = function () {
    return {
      src: this._src,
      act: this._act,
      type: this._type,
      data: this._data,
    };
  };

  this.clean = function () {
    this._data = null;
    this._type = '';
    this._act = '';
    this._src = '';
  };
}
