import { isDevInSplitVdomMode } from 'ra_device_util';
import { fiSmartTableHelper } from './smart_table/smart_table';
import { asyncForEach } from 'kit-array';
import { isFunction, isUndefined } from 'lodash';
import { deviceStatus, fiDvmTableCellParser } from 'fi-dvm';
import $ from 'jquery';

const isDefined = (arg) => !isUndefined(arg);

var rowId = -1; // ID of each row
var ROW_ID_NAME = 'dvmRowId';
var CHILD_ATTR_NAME = 'vdoms';

export const fiDvmTableDataGenerator = {
  /**
   * Generates parsed data for a table with devices and groups
   * @param {Object} dvmArray - the objects with devices array and groups array
   * @param {Array} dvmArray.devices - the devices array
   * @param {Array} dvmArray.groups - the groups array
   * @param {Array} colDefs - the column definitions of the table
   * @param {Function} [callbackEachRow] - callback each time when finishs parsing a row. Take the parsed row as the argument
   * @param {Boolean} [showHeader] - if the group header is shown on the table. True by default.
   * @param {Boolean} [showVdom] - if VDOM level is shown on the table. True by default.
   * @return {promise} the parsed data
   */
  genData: function (
    dvmArray,
    colDefs,
    callbackEachRow,
    showHeader,
    showVdom,
    excludeDevManagementVdom
  ) {
    return parseData(
      dvmArray.devices,
      dvmArray.groups,
      colDefs,
      callbackEachRow,
      isDefined(showHeader) ? showHeader : true,
      isDefined(showVdom) ? showVdom : true,
      isDefined(excludeDevManagementVdom) ? excludeDevManagementVdom : false
    );
  },
  /**
   * Generates the st-options for smart table
   * @param {Array|String} fields - the fields to be searched for while the filter processing
   * @param {String} [rowIdentifier] - the name of the field to identify a row
   */
  genTableOpts: function (fields, rowIdentifier, childAttr) {
    fields = Array.isArray(fields) ? fields : [fields];
    rowIdentifier = isDefined(rowIdentifier) ? rowIdentifier : ROW_ID_NAME;
    childAttr = isDefined(childAttr) ? childAttr : CHILD_ATTR_NAME;

    /***var currentSort = {
            predicate: undefined,
            reverse: undefined
          };***/ //temporary fix bug 513666

    return {
      sortFn: function (dataset, predicate, reverse) {
        return fiSmartTableHelper.sortFn(dataset, rowIdentifier)(
          dataset,
          predicate,
          reverse
        );
        /***if (predicate !== currentSort.predicate || reverse !== currentSort.reverse) {
              currentSort = {
                predicate: predicate,
                reverse: reverse
              };
              return fiSmartTableHelper.sortFn(dataset, rowIdentifier)(dataset, predicate, reverse);
            }
            return dataset;***/ //temporary fix bug 513666
      },
      filterFn: function (dataset, predicateObj) {
        return fiSmartTableHelper.filterFn(
          dataset,
          fields,
          rowIdentifier,
          childAttr
        )(dataset, predicateObj);
      },
    };
  },
};

// function definitions go here
function parseDevPromise(
  devices,
  colDefs,
  callbackEachRow,
  showHeader,
  showVdom,
  excludeDevManagementVdom
) {
  var childVdoms;
  var addedHeader = false;

  return asyncForEach(
    devices,
    function (dev) {
      var parsedDev = [];

      // all device nodes
      if (showHeader && !addedHeader) {
        var tmpRow = {
          label: gettext('All Devices'),
          name: 'all_devices', // used for sorting, notation of parent of all devices levels
          isHeader: true,
          //largeDataRowHeight: 25,
          _level: 0,
          collapsed: false,
        };
        tmpRow[ROW_ID_NAME] = ++rowId;
        parsedDev.push(tmpRow);
        addedHeader = true;
      }
      if (showHeader) {
        dev['_pkey'] = 'all_devices';
      }
      var parentDev = parseDev(
        dev,
        colDefs,
        1,
        callbackEachRow,
        null,
        excludeDevManagementVdom
      );
      parsedDev.push(parentDev);
      childVdoms = dev.vdoms || dev.cd;
      const fortiSASEDev = dev.os_type === MACROS.DVM.DVM_OS_TYPE_FSS;
      if (showVdom && Array.isArray(childVdoms) && !fortiSASEDev) {
        for (var j = 0, vdomLength = childVdoms.length; j < vdomLength; j++) {
          if (
            excludeDevManagementVdom &&
            isDevInSplitVdomMode(dev) &&
            deviceStatus.isMgtVdom(childVdoms[j].rtype)
          )
            continue;
          parsedDev.push(
            parseDev(childVdoms[j], colDefs, 2, callbackEachRow, parentDev)
          );
        }
      }

      return parsedDev;
    },
    { chunksz: 1000 }
  );
}

function parseData(
  devices,
  groups,
  colDefs,
  callbackEachRow,
  showHeader,
  showVdom,
  excludeDevManagementVdom
) {
  var deferred = $.Deferred();

  devices = Array.isArray(devices) ? devices : [];
  groups = Array.isArray(groups) ? groups : [];

  // make parse device data asychronized, response by deferred notify
  var prom = parseDevPromise(
    devices,
    colDefs,
    callbackEachRow,
    showHeader,
    showVdom,
    excludeDevManagementVdom
  );

  prom.promise.then(
    function doneDev(parsedDevices) {
      //done with parsing devices
      if (!groups.length) {
        deferred.resolve(parsedDevices || []);
        return;
      }

      var parsedDevGrp = [];
      var grpColIgn = ['sync', 'pp_sync'];

      // all group nodes
      var tmpRow = {
        label: gettext('All Groups'),
        name: 'all_groups', // used for sorting, notation of parent of all groups
        isHeader: true,
        collapsed: false,
        //largeDataRowHeight: 25,
        _level: 0,
      };
      tmpRow[ROW_ID_NAME] = ++rowId;
      if (showHeader) {
        parsedDevGrp.push(tmpRow);
      }

      for (var ii = 0, ll = groups.length; ii < ll; ii++) {
        var grp = groups[ii];
        var row = {},
          col;
        row[ROW_ID_NAME] = ++rowId;

        for (var jj = 0; jj < colDefs.length; jj++) {
          col = colDefs[jj];
          if (grpColIgn.indexOf(col.field) < 0) {
            row[col.field] = fiDvmTableCellParser.getData(grp, col.field);
          } else {
            row[col.field] = { txt: '', css: '' };
          }
        }
        if (showHeader) {
          grp['_pkey'] = 'all_groups';
        }
        row.txt = grp.name;
        row.name = { txt: grp.name, css: 'ffg ffg-group' };
        row._oData = grp;
        row.isGrp = true;
        row.largeDataRowHeight = 25;
        row._level = 1;

        if (isFunction(callbackEachRow)) {
          callbackEachRow(row);
        }
        parsedDevGrp.push(row);
      }

      deferred.notify(parsedDevGrp);
      deferred.resolve([].concat(parsedDevices, parsedDevGrp));
    },
    null,
    function notifyDev(chunk) {
      deferred.notify(chunk);
    }
  );

  return { promise: deferred.promise(), cancel: prom.cancel };
}

function parseDev(
  data,
  colDefs,
  currentLevel,
  callbackEachRow,
  parentDev,
  excludeDevManagementVdom
) {
  var row = {};

  colDefs.forEach(function (col) {
    row[col.field] = fiDvmTableCellParser.getData(data, col.field);
  });

  currentLevel = isDefined(currentLevel) ? currentLevel : 1;
  row._level = currentLevel;
  //row.largeDataRowHeight = 25;
  row.txt = row.name?.txt;
  row[ROW_ID_NAME] = ++rowId;
  if (parentDev) {
    row.parentDev = parentDev;
    data['_pkey'] = data.did;
  }
  if (!data._fiDeviceId) {
    data._fiDeviceId = data.oid || data.did;
  }
  row._oData = data;

  if (isFunction(callbackEachRow)) {
    callbackEachRow(row, excludeDevManagementVdom);
  }

  return row;
}
