import { redirect } from 'react-router-dom';
import { checkAuthLoader } from './loader';
import { WebConsole } from 'rc_terminal/WebConsole';

export default [
  {
    path: 'webconsole',
    loader: async () => {
      if (await checkAuthLoader()) {
        return null;
      }
      return redirect('/login');
    },
    element: <WebConsole />,
  },
];
