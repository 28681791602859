// Note: duplicated with DEV_TYPE in rh_faz_fabric_hooks/helpers.js
const DEV_TYPE = {
  grp: { icon: 'device-total', color: '#333' },
  ha: { icon: 'ha', color: '#333' },
  vpn: { icon: 'vpn-wzd-device', color: '#333' },
  '0000': { icon: 'advanced', color: '#333' },
  android: { icon: 'android', color: '#a4c649' },
  'android-phone': { icon: 'android', color: '#a4c649' },
  'android-tablet': { icon: 'android', color: '#a4c649' },
  blackberry: { icon: 'blackberry', color: '#333' },
  'blackberry-phone': { icon: 'blackberry', color: '#333' },
  'blackberry-playbook': { icon: 'blackberry', color: '#333' },
  ipad: { icon: 'macos', color: '#909293' },
  iphone: { icon: 'iphone', color: '#909293' },
  mac: { icon: 'macos', color: '#909293' },
  ios: { icon: 'macos', color: '#909293' },
  linux: { icon: 'linux', color: '#333' },
  'linux-pc': { icon: 'linux', color: '#333' },
  windows: { icon: 'windows13', color: '#094ab2' },
  'windows-pc': { icon: 'windows13', color: '#094ab2' },
  'windows-phone': { icon: 'windows13', color: '#094ab2' },
  'router-nat-device': { icon: 'device', color: '#333' },
  'fortinet-device': { icon: 'fortinet-grid', color: 'red' },
  'media-streaming': { icon: 'music', library: 'fa-solid', color: '#a4c649' },
  'gaming-console': { icon: 'device-model', color: '#333' },
  'no-mgt-vdom': { icon: 'vdom', color: '#333' },
  printer: { icon: 'printer', library: 'fafm', color: '#333' },
  'collected-emails': { icon: 'envelope', library: 'fa-solid', color: '#333' },
  'ip-phone': { icon: 'phone-alt', library: 'fa-solid', color: '#333' },
  'other-network-device': { icon: 'device', library: 'fafm', color: '#333' },
};

const OS_REG = [
  { key: 'windows', r: /windows/i },
  { key: 'fortinet-device', r: /(fortinet|fortios|x11)/i },
  { key: 'ios', r: /(mac|ipad|ipod|ios)/i },
  { key: 'iphone', r: /(iphone)/i },
  { key: 'android', r: /android/i },
  { key: 'linux', r: /(linux|x11)/i },
];

// Note: duplicated with fiDevType in rh_faz_fabric_hooks/helpers.js
export function fiDevType(devtype, osname, returnObj = false) {
  function getDevType(key) {
    var osType = null;
    if (key) {
      var type = DEV_TYPE[key];
      if (type) {
        osType = {
          icon: type.icon || '',
          color: type.color || 'transparent',
          text: key,
          library: type?.library || 'fafm',
        };
      }
    }
    return osType;
  }
  var resType = null;
  var formatDevtype = '';
  if (devtype) {
    formatDevtype = devtype
      .trim()
      .toLowerCase()
      .split(/[\s\\]/g)
      .join('-');
    resType = getDevType(formatDevtype);
  }
  // if can not get type from devtype then try from osname
  if (!resType && osname) {
    var matchOs = OS_REG.reduce(function (arr, type) {
      if (type.r.test(osname)) {
        arr.push(type);
      }
      return arr;
    }, []);

    if (matchOs.length > 0) {
      resType = getDevType(matchOs[0].key);
    }
  }

  if (resType) {
    if (returnObj) {
      return resType;
    }
    return (
      <nw-icon
        className='with-color'
        name={`${resType.icon}`}
        library={`${resType.library}`}
        color={`${resType.color}`}
        title={devtype}
      ></nw-icon>
    );
  } else {
    return '';
  }
}

export const getEuAvatarUrl = (
  euid,
  epid,
  format,
  noPlaceHolder,
  proxiedServer,
  proxiedAdom
) => {
  let url = '/p/util/euavatar/%s/%s/'.printf([euid, epid]);
  let params = [];
  if (format) {
    params.push('format=' + encodeURIComponent(format));
  }
  if (noPlaceHolder) {
    params.push('noPlaceHolder=1');
  }
  if (proxiedServer) {
    params.push('proxiedServer=' + proxiedServer);
  }
  if (proxiedAdom) {
    params.push('proxiedAdom=' + proxiedAdom);
  }
  if (params.length) {
    url += '?' + params.join('&');
  }
  return url;
};
