import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import * as actions from './actions';
import * as api from './api';
import { callPromiseAction, getDataFromResponse } from '../../utils';
import { getSessionAdomOid } from 'fistore/session/adom/selectors';
import { inFmgNotRestrictedAdmin } from 'fistore/session/sysConfig/selectors';
import { runIfAvailable } from 'fistore/routing/saga';
import { getDevicesPsirt } from '../selectors';
import {
  setNotification,
  unsetNotification,
} from 'fistore/notifications/slice';
import { SEVERITIES } from 'fistore/notifications/util';

export function* watchDevicesPsirt() {
  yield takeLatest(
    [
      actions.fetchDevicesPsirtAction.type,
      actions.devicesPsirtAction.fetch.REQUEST,
    ],
    runIfAvailable(inFmgNotRestrictedAdmin, fetchPsirt)
  );

  // psirt notification
  yield takeEvery(
    actions.devicesPsirtAction.fetch.SUCCESS,
    updateNotificationForPsirt
  );
}

function* fetchPsirt(action) {
  yield callPromiseAction(action, function* () {
    const adomOid =
      action.payload?.adomOid || (yield select(getSessionAdomOid));

    yield put(actions.devicesPsirtAction.fetch.start());

    yield delay(500);

    const resp = yield call(api.fetch_devices_psirt, adomOid);

    const data = getDataFromResponse(resp);

    yield put(actions.devicesPsirtAction.fetch.success(data));

    return data;
  });
}

const getHighestSeverity = (psirtByIrNumber) => {
  return Object.values(psirtByIrNumber).reduce((prev, psirt) => {
    if (psirt.threat_severity) {
      const severity = psirt.threat_severity.toLowerCase();
      if (SEVERITIES[severity] > prev) return SEVERITIES[severity];
    } else if (psirt.risk) {
      if (psirt.risk > prev) return psirt.risk;
    }
    return prev;
  }, MACROS.NOTICE.NOTICE_LEVEL_INFORMATION);
};

function* updateNotificationForPsirt() {
  const psirtData = yield select(getDevicesPsirt);
  const adomOid = yield select(getSessionAdomOid);

  const { numDevicesPerRisk } = psirtData;
  const numDevices = Object.values(numDevicesPerRisk || {}).reduce(
    (prev, curr) => {
      return prev + parseInt(curr, 10);
    },
    0
  );

  if (numDevices > 0) {
    const notif = {
      id: MACROS.NOTICE.NOTICE_TYPE_PSIRT_VULNERABLE,
      adomOid,
      severity: getHighestSeverity(psirtData.byIrNumber),
      params: { numDevices },
    };
    yield put(setNotification({ ...notif }));
  } else {
    yield put(
      unsetNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_PSIRT_VULNERABLE,
        adomOid,
      })
    );
  }
}
