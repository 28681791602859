import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, head, isNil, omitBy } from 'lodash';

import {
  getDefaultDeviceGroups,
  getDefaultDeviceGroupsAllIds,
} from 'fistore/devGroups/selector';
import { listenerMiddleware } from 'fistore/middlewares';
import { refreshAppTree } from 'fistore/routing/slice';
import { getSessionAdomOid } from 'fistore/session/adom/selectors';
import { switchSessionAdom } from 'fistore/session/adom/slice';
import { getDeviceTree } from 'fistore/adomLocalConfig/selectors';
import {
  get_device,
  get_device_vdoms,
  get_devices,
} from 'fistore/devices/selectors';
import { getIsAppInited } from 'fistore/routing/selectors';

const initialState = {
  isFswInitialized: false,
  isFapInitialized: false,
  deviceTree: {
    deviceGroupPath: '',
    deviceOid: '',
    vdomOid: '',
    deviceName: '',
    vdomName: '',
    vdomEnabled: false,
  },
  folderTree: {
    expandedIds: [],
    currentFoldersPath: [],
    currentDevOid: '',
    searchFolderKeyword: '',
  },
  searchKeyword: '',
  currentAsset: {}, //used in DVM assest view
  selectedCsfGroup: {},
};

const _slice = createSlice({
  name: 'adomLocalConfig',
  initialState,
  reducers: {
    initFswDone: (state) => {
      state.isFswInitialized = true;
    },
    initFapDone: (state) => {
      state.isFapInitialized = true;
    },
    setSearchKeyword: (state, { payload }) => {
      state.searchKeyword = payload;
    },
    setCurrentAsset: (state, { payload }) => {
      state.currentAsset = payload;
    },
    setFolderVTree: (state, { payload }) => {
      state.folderTree = {
        ...state.folderTree,
        ...payload,
      };
    },
    updateDeviceTree: (state, { payload }) => {
      state.deviceTree = {
        ...state.deviceTree,
        ...payload,
      };
    },
    setSelectedCsfGroup: (state, { payload }) => {
      state.selectedCsfGroup = payload;
    },
  },
});

export default _slice.reducer;

export const {
  setSearchKeyword,
  setCurrentAsset,
  setFolderVTree,
  setSelectedCsfGroup,
  initFswDone,
  initFapDone,
} = _slice.actions;

// This is used for internal redux state update. Use setDeviceTree as the action instead
const { updateDeviceTree } = _slice.actions;

export const setDeviceTree = createAsyncThunk(
  'adomLocalConfig/setDeviceTree',
  async (payload, { dispatch, getState }) => {
    const currentState = getState();

    try {
      const newDeviceTree = payload || {};

      const adomOid = getSessionAdomOid(currentState);
      const currentDeviceTree = getDeviceTree(currentState);

      const deviceOid = newDeviceTree.deviceOid;
      let deviceName = newDeviceTree.deviceName;
      const device = get_device(currentState, adomOid, deviceOid);

      let vdomOid = newDeviceTree.vdomOid;
      let vdomName = newDeviceTree.vdomName;
      let vdomEnabled = false;

      // always normalize device tree data, in case the inputted device/vdom data are deviated from the store data
      if (device) {
        deviceName = device._deviceName;
        const vdoms = get_device_vdoms(currentState, adomOid, deviceOid);
        const vdomsById = get(vdoms, 'byId', {});

        vdomEnabled = device.vdom_status;
        // always get vdom by oid due to default vdom is always set to root (e.g. in path params),
        // but vdom might not be enabled (vdomName should be '' instead in this case)
        const vdom = vdomsById[vdomOid];

        // dont return any vdom info if selected a device
        if (vdomEnabled) {
          if (!vdom) {
            vdomName = '';
            vdomOid = '';
          } else {
            vdomName = vdom.name;
            vdomOid = vdom.oid;
          }
        } else {
          vdomName = '';
          vdomOid = '';
        }
      } else {
        deviceName = '';
        vdomName = '';
        vdomEnabled = false;
      }

      Object.assign(newDeviceTree, {
        deviceName,
        vdomName,
        vdomOid,
        vdomEnabled,
      });

      const toUpdate = {
        ...currentDeviceTree,
        // ignore null or undefined values
        ...omitBy(newDeviceTree, isNil),
      };
      if (!toUpdate.deviceGroupPath) {
        const defaultGroupsIds = getDefaultDeviceGroupsAllIds(currentState);
        toUpdate.deviceGroupPath =
          head(defaultGroupsIds) || MACROS.DVM.DVM_GRP_MANAGED_OID;
      }

      dispatch(updateDeviceTree(toUpdate));

      // refresh app tree if selected device changes
      const changed = ['deviceOid', 'vdomOid', 'deviceGroupPath'].some(
        (key) => {
          const currVal = currentDeviceTree[key];
          const newVal = newDeviceTree[key];
          return !isNil(currVal) && !isNil(newVal) && currVal !== newVal;
        }
      );
      if (changed) {
        dispatch(refreshAppTree());
      }
    } catch (error) {
      if (MACROS.SYS.CONFIG_DEBUG) {
        console.error('updateDeviceTree saga failed:', error);
      }
    }
  },
  {
    // execute only when system is initialized
    condition: (_, { getState }) => {
      const currState = getState();
      return (
        getIsAppInited(currState) &&
        get_devices(currState, getSessionAdomOid(currState))?.loaded &&
        getDefaultDeviceGroups(currState)?.loaded
      );
    },
  }
);

listenerMiddleware.startListening({
  actionCreator: switchSessionAdom.fulfilled,
  effect: (action, { dispatch, cancelActiveListeners }) => {
    cancelActiveListeners();
    dispatch(
      updateDeviceTree({
        deviceGroupPath: '',
        deviceOid: '',
        vdomOid: '',
      })
    );
  },
});
