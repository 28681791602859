import AutoSizer from '@fafm/react-virtualized-auto-sizer';
import { convertRemToPx } from 'fiutil';

export const bodySizer = (children) => (
  <div className='tw-w-full tw-h-full'>
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height, width }}>
          {children({
            // Defaults to leave space for ProForm.Section + ProForm.Body padding
            height: (rem = 4) =>
              rem > 0 ? height - convertRemToPx(rem) : height,
          })}
        </div>
      )}
    </AutoSizer>
  </div>
);
