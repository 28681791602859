import { MoveEntry } from './MoveEntry';
import { fiHttpPost } from 'fi-http';

/**
 * get url, entry ID, entries, from parent caller
 * render "Entry ID", radio buttons (before, after), entries dropdown
 *
 * example url: 'pm/config/device/_devicename_/vdom/_vdomname_/router/policy/_id_'
 * entries should be an array of entryKeys
 */
export const MoveTableEntry = ({
  entryId,
  entries,
  deviceName,
  vdomName,
  url,
  callback,
  ...rest
}) => {
  const _url = url
    .replace('_devicename_', deviceName)
    .replace('_vdomname_', vdomName)
    .replace('_id_', entryId);

  const dataBag = {
    entries,
    entryId,
    onSubmit: getMoveTableEntryHandler(_url, callback),
  };
  return <MoveEntry {...dataBag} {...rest} />;
};

/**
 * should take url for the endpoint, entry id, target id, before/after
 * generate vdom/device name or whatever is needed in calling function
 */
const getMoveTableEntryHandler = (url, callback) => {
  return function (targetId, option) {
    const toSend = {
      option,
      target: targetId.toString(),
      url,
    };

    fiHttpPost(MACROS.USER.DEF.URL_DVM_API, {
      method: 'move',
      params: [toSend],
    }).then(() => {
      callback();
    });
  };
};
