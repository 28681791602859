import { useMemo } from 'react';

import { isFunction, promiseWhen } from 'fiutil';
import { CancelBtn } from 'rc_layout';

import { useWizard } from './useWizard';

export const useLastStep = ({
  lastStepKey,
  getAutoId,
  onSubmit,
  displayChecker,
}) => {
  const { jumpTo, activeStepIndex, mostAdvStepIndex, steps } = useWizard();
  lastStepKey = lastStepKey || steps[steps.length - 1];
  const jumpToLastStep = () => jumpTo(lastStepKey);

  /**
   * Show button when:
   * - Create New: user hit the last tab and navigated to another tab
   * - Edit or Clone: show in all tabs except the last tab
   * - Don't show in the last tab
   */
  const shouldButtonDisplay = useMemo(() => {
    const lastIndex = steps.length - 1;
    const isLastTab = activeStepIndex === lastIndex;
    const hitLastTab = mostAdvStepIndex === lastIndex;
    const displayCondition = isFunction(displayChecker)
      ? displayChecker()
      : true;
    return !isLastTab && hitLastTab && displayCondition;
  }, [steps.length, activeStepIndex, mostAdvStepIndex, displayChecker]);

  const onClick = async () => {
    if (isFunction(onSubmit)) {
      const resp = await promiseWhen(onSubmit());
      if (resp?.error) return;
    }
    jumpToLastStep();
  };

  const renderLastStepButton = () => {
    return (
      shouldButtonDisplay && (
        <CancelBtn onClick={onClick} automation-id={getAutoId('last')}>
          {gettext('Last')}
        </CancelBtn>
      )
    );
  };

  return { jumpToLastStep, shouldButtonDisplay, renderLastStepButton };
};
