import { formatBytes, formatBandwidth } from 'kit-number';

const colDef = [
  {
    key: 'pid',
    title: 'PID',
    sortable: true,
    toSortValue: ({ cellData }) => {
      return parseInt(cellData) || 0;
    },
  },
  { key: 'cmd', title: gettext('Command'), sortable: true },
  {
    key: 'cpu_pct',
    title: 'CPU',
    initialSort: -1,
    sortable: true,
    cellRenderer: ({ cellData }, hiliter) =>
      cellData && hiliter(cellData + '%'),
    toSortValue: ({ cellData }) => {
      return parseFloat(cellData) || 0;
    },
  },
  {
    key: 'mem_pct',
    title: gettext('Memory'),
    sortable: true,
    cellRenderer: ({ cellData }, hiliter) =>
      cellData && hiliter(cellData + '%'),
    toSortValue: ({ cellData }) => {
      return parseFloat(cellData) || 0;
    },
  },
  { key: 'state', title: gettext('State'), sortable: true },
  {
    key: 'res',
    title: 'RES',
    sortable: true,
    dataGetter: ({ rowData }) => formatBytes(rowData._res_bytes),
    toSortValue: ({ rowData }) => rowData._res_bytes,
  },
  {
    key: 'virt',
    title: 'VIRT',
    sortable: true,
    dataGetter: ({ rowData }) => formatBytes(rowData._virt_bytes),
    toSortValue: ({ rowData }) => rowData._virt_bytes,
  },
  {
    key: 'disk_read',
    title: gettext('Disk Read'),
    sortable: true,
    dataGetter: ({ rowData }) => formatBandwidth(rowData._disk_read_bytes || 0),
    toSortValue: ({ rowData }) => rowData._disk_read_bytes,
  },
  {
    key: 'disk_write',
    title: gettext('Disk Write'),
    sortable: true,
    dataGetter: ({ rowData }) =>
      formatBandwidth(rowData._disk_write_bytes || 0),
    toSortValue: ({ rowData }) => rowData._disk_write_bytes,
  },
];

export default colDef;
