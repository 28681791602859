import { debounce, keys } from 'lodash';
import { useEffect, useCallback } from 'react';
import { useLoaderData, useNavigation, useNavigate } from 'react-router-dom';

const STORAGE_ID = 'fiNewWindow';
const EXPIRE_MS = 5 * 60 * 1000;
const storage = window.localStorage;

function genv4() {
  // function adapted from https://stackoverflow.com/a/2117523/1003746
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.floor(Math.random() * 16);
    var v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function loadData() {
  var allData;
  try {
    allData = JSON.parse(storage.getItem(STORAGE_ID));
    var toDel = [];
    keys(allData).forEach(function (uuid) {
      var item = allData[uuid];
      if (!item || !item.expiry || item.expiry < Date.now()) {
        toDel.push(uuid);
      }
    });
    toDel.forEach(function (uuid) {
      delete allData[uuid];
    });
    saveData(allData);
    // eslint-disable-next-line
  } catch (ex) {}
  return allData || {};
}

function saveData(allData) {
  storage.setItem(STORAGE_ID, JSON.stringify(allData));
}

function getRedirectObj(uuid) {
  var allData = loadData();
  var data = allData[uuid];
  if (data) {
    delete allData[uuid];
    saveData(allData);
    return {
      path: data.path,
      params: data.params,
    };
  }
}

function storeNewRedirectTarget(path, params) {
  var uuid = genv4(); // self.crypto.randomUUID(); too bad randomUUID only works in real https.
  var thisNewWindow = {
    uuid: uuid,
    path: path,
    params: params,
    expiry: Date.now() + EXPIRE_MS,
  };
  var allData = loadData();
  allData[uuid] = thisNewWindow;
  saveData(allData);
  return uuid;
}

// For simple case when you already know the target path and params,
// so no need to do the window reference trick.
// This function runs window.open for you.
export function openNewWindow(path, params) {
  var uuid = storeNewRedirectTarget(path, params);
  window.open(`/ui/redirect/${uuid}`, '_blank');
}

// For use when new window `win` is opened earlier before path/params are known.
// You must window.open first immediately in response to user action (in its
// event handler), and later when you retrieved path and params,
// call this and pass in the new window's reference as `win` here.
//
// This is necessary because some browser would prevent `window.open` if it's
// not inside a real event handler in response to user action.
export function openInWindow(win, path, params) {
  var uuid = storeNewRedirectTarget(path, params);
  win.location.replace(`/ui/redirect/${uuid}`);
}

// enough... i'm tired of useNavigate. I need to navigate in coldef renderers
// or event handlers without need to refresh/update and maintain same count of
// useNavigate().
//
// Let's use the new window navigate do a same window navigate!
export function frGo2(path, params) {
  var uuid = storeNewRedirectTarget(path, params);
  //console.log('frgo2 internal, we have', path, params, uuid);
  window.open(`/ui/redirect/${uuid}`, '_self');
}

const Redirector = () => {
  const navigate = useNavigate();
  const redirectData = useLoaderData();
  const navigation = useNavigation();

  const navigateDebounced = useCallback(
    // ugly strict mode.
    debounce(navigate, 100),
    [navigate]
  );

  useEffect(() => {
    if (redirectData && navigation.state === 'idle') {
      navigateDebounced(redirectData.path, redirectData.params);
    }
  }, [redirectData, navigation, navigateDebounced]);
  return (
    <div>
      {redirectData ? gettext('Loading...') : gettext('Page Not Found')}
    </div>
  );
};

const ROUTES = {
  path: '/redirect/:uuid',
  loader: ({ params }) => {
    return getRedirectObj(params.uuid) || null;
  },
  element: <Redirector />,
};
export default ROUTES;
