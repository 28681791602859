import { saveToLocalFile } from 'kit-file';
import { formatNowAsAdomTime } from 'fi-web/fi-datetime/formatZonedTime';

export { getContentKey, doDownload, genDefaultFilename };

function getContentKey(deviceObj) {
  if (!deviceObj) return;
  const { oid, vdomOid } = deviceObj;
  return `${oid}-${vdomOid}`;
}

function doDownload(filename, content) {
  if (!content) return;

  const _content = content.reduce((a, b) => {
    return a + b.txt + '\n';
  }, '');
  saveToLocalFile(filename, _content);
}

function genDefaultFilename(deviceObj) {
  const { name: deviceName, vdomName } = deviceObj;
  const vdomTxt = vdomName ? `(${vdomName})` : '';
  return `${formatNowAsAdomTime()}-${deviceName}${vdomTxt}-preview.txt`;
}
