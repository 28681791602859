import { SEVERITY_COLORS } from './util';

export const irNumberCellRenderer = ({ irNumber, risk }, highlighter) => {
  const color = SEVERITY_COLORS[risk];
  return (
    <span key={irNumber} className='tw-flex tw-items-center tw-gap-1'>
      <span
        className={`tw-rounded-full ${color}`}
        style={{ width: '1rem', height: '1rem' }}
      ></span>
      <a
        title={gettext('Details')}
        href={`https://www.fortiguard.com/psirt/${irNumber}`}
        target='_blank'
        rel='noreferrer'
      >
        {highlighter(irNumber)}
      </a>
    </span>
  );
};
