import { fiFmgHttp } from 'fi-http';
import { get } from 'lodash';

const getTemplateCustomizationUrl = (adomOid, templateOid) => {
  return `/gui/adoms/${adomOid}/template/${templateOid}/customize`;
};

export const getTemplateCustomization = async ({ adomOid, oid }) => {
  const url = getTemplateCustomizationUrl(adomOid, oid);
  try {
    const resp = await fiFmgHttp.post({
      url,
      method: 'get',
      params: {},
    });

    return get(resp, '0.data.customize');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setTemplateCustomization = async ({
  adomOid,
  oid,
  customize = {},
}) => {
  const url = getTemplateCustomizationUrl(adomOid, oid);
  try {
    const currCustomize = await getTemplateCustomization({ adomOid, oid });

    return fiFmgHttp.post({
      url,
      method: 'update',
      params: {
        customize: {
          ...currCustomize,
          ...customize,
        },
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
