import { useCallback } from 'react';
import { UploadLicenseForm } from 'react_components/rc_license/UploadLicenseForm';
import { useAuth } from 'react_apps/ra-auth/useAuth';
import { useRestartTimer } from 'react_apps/ra-auth/hooks/useRestartTimer';

// use status code for stability and  translation
const MS = MACROS.SYS;
const statusMsgs = {
  [MS.VM_LIC_STATUS_EXPIRED]: gettext('VM license is expired.'),
  [MS.VM_LIC_STATUS_MGMT_IP_NOMATCH]: gettext(
    'VM license is invalid because system can not find the matched management IP.'
  ),
  [MS.VM_LIC_STATUS_OLD_VERSION]: gettext(
    'VM license is invalid because its version is obsoleted.'
  ),
  [MS.VM_LIC_STATUS_LICENSE_DUPLICATE]: gettext(
    'VM license is invalid because license is duplicated.'
  ),
};

export const UpgradeLicense = ({
  $opener,
  licenseStatus,
  isLicenseValid = true,
}) => {
  const auth = useAuth();
  const lic = licenseStatus?.license;
  const hasUploadPermission = lic?.system_settings_rdwr ?? true;
  const licenseStatusDesc = isLicenseValid
    ? ''
    : statusMsgs[lic?.vmlic_status] ?? gettext('VM license unknown status.');
  const restartWait = useRestartTimer();

  const onCancel = useCallback(() => {
    if (!isLicenseValid) {
      auth.logout();
    }
  }, [isLicenseValid]);

  return (
    <UploadLicenseForm
      $opener={$opener}
      url='/flatui/auth/gui/vmlicense/upload'
      data={{
        actionevent: 'edit',
        fromAjax: 1,
      }}
      onSuccess={restartWait}
      onCancel={onCancel}
      cancelBtnText={isLicenseValid ? gettext('Cancel') : gettext('Logout')}
      hasUploadPermission={hasUploadPermission}
      licenseStatusDesc={licenseStatusDesc}
    />
  );
};
