import { isFunction } from 'lodash';

import { renderIcon } from 'ra-render-util';

const FolderRenderer = ({ node, highlight = (item) => item }) => {
  return (
    <>
      {renderIcon({ name: 'folder', className: 'node-icon tw-mr-1' })}
      <span className={'node-icon-text'} title={node.name}>
        {isFunction(highlight) ? highlight(node._data.name) : node._data.name}
      </span>
    </>
  );
};

export const ROWTYPEDEVICE = 'device';
export const ROWTYPEFOLDER = 'folder';
export const ROWTYPEFORTIAP = 'fortiap';
export const ROWTYPEFORTISWITCH = 'fowtiswitch';
export const ROWTYPEFORTIEXTENDER = 'fortiextender';

const _findSubFolders = (
  folder,
  parentPath,
  pathToNode = {},
  oidToNode = {},
  devToNode = {}
) => {
  let path = [...parentPath, { oid: folder.oid, name: folder.name || '/' }]; //update current folder path
  const pathName = path.map((t) => t.name);
  const pathId = path.map((t) => t.oid);
  let parsedFolder = {
    css: 'ffg-folder',
    oid: folder.oid,
    name: folder.name || '/',
    desc: folder.desc || '',
    devMemberList: [],
    folderMemberList: [],
    contentComponent: FolderRenderer,
    path: pathId,
    id: pathId.join('->'),
    isFolder: true,
    type: 'folder',
    _path_id: pathId.join('->'),
    _name_id: pathName.join('->'),
    pathName: pathName,
    _oData: { ...folder, path: pathName.join('->') },
  };

  if (folder['object member']) {
    parsedFolder.devMemberList = folder['object member'];
    const folderPath = '/' + [...pathName].splice(1).join('/');
    for (let dev of folder['object member']) {
      devToNode[dev.oid] = {
        fname: folder.name,
        fpath: folderPath,
      };
    }
  }

  parsedFolder._oData.devMemberList = parsedFolder.devMemberList;
  //recursive call
  if (folder['subobj']) {
    parsedFolder.children = [];
    for (let i = 0; i < folder['subobj'].length; i++) {
      let childFolder = folder['subobj'][i];
      parsedFolder.children.push(
        _findSubFolders(childFolder, path, pathToNode, oidToNode, devToNode)
      );
    }
    // For angular modals
    parsedFolder.folderMemberList = parsedFolder.children;
    parsedFolder._children = parsedFolder.children;
  }
  pathToNode[path.map((t) => t.oid).join('->')] = parsedFolder;
  oidToNode[folder.oid] = parsedFolder;
  return parsedFolder;
};

export const parseFolderTree = (data, oidToNode = {}) => {
  let rootFolder = data['subobj'] || [];
  const folderTrees = rootFolder.map((f) =>
    _findSubFolders(f, [], {}, oidToNode)
  );
  if (!data?.['object member']?.length) {
    return folderTrees;
  }
  return [
    // Unauthorized Devices
    {
      oid: -7,
      name: gettext('Unassigned Devices (%s)').printf([
        data?.['object member']?.length || 0,
      ]),
      _name_path: 'Unassigned Devices',
      contentComponent: FolderRenderer,
      devMemberList: data['object member'] || [],
      folderMemberList: [],
    },
    ...folderTrees,
  ];
};

export const getFolderWithRoot = (data) => {
  let rootFolder = data;
  let pathToNode = {};
  let devToFolderMap = {};
  let oidToNode = {};
  const folderTree = _findSubFolders(
    rootFolder,
    [],
    pathToNode,
    oidToNode,
    devToFolderMap
  );
  const folderWithRoot = [[folderTree], pathToNode];
  return { folderWithRoot, devToFolderMap, oidToNode };
};

export const getTreeKey = (path) => {
  const temp = [];
  for (let i = 1; i <= path.length; i++) {
    temp.push(path.slice(0, i).join('->'));
  }
  return temp.join(':');
};

export const getIconColor = (state) => {
  const ap_status = state.val;
  switch (ap_status) {
    case MACROS.WTP.PM2_CONN_CONNECTING:
    case MACROS.WTP.PM2_CONN_IMAGE_DOWNLOADING:
    case MACROS.WTP.PM2_CONN_CONNECTED_IMAGE:
    case MACROS.WTP.PM2_CONN_UNKNOWN:
      return 'color-orange';
    case MACROS.WTP.PM2_CONN_CONNECTED:
      return 'color-green';
    default: //MACROS.WTP.PM2_CONN_IDLE:
      return 'color-orange';
  }
};

export const handleDevice = (dev) => ({
  ...dev,
  id: dev.oid,
  serialnum: dev.sn,
});

export const handleFortiAP = (data, dev) => {
  return {
    ...data,
    serialnum: data.serials,
    platform: data._oData?.['wtp-id']?.substring(0, 6),
    rowType: ROWTYPEFORTIAP,
    indent: true,
    id: `fap:${dev.oid}:${data.serials}`,
    iconColor: getIconColor(data.state),
    firmware_os_version: data.os_version || data.osversion,
  };
};

export const handleFortiSwitch = (data, dev) => ({
  ...data,
  serialnum: data.serial_num,
  rowType: ROWTYPEFORTISWITCH,
  indent: true,
  id: `fsw:${dev.oid}:${data.serial_num}`,
  firmware_os_version: data.os_version || data.osversion,
});

export const handleFortiExt = (data, dev) => ({
  ...data,
  serialnum: data.sn,
  rowType: ROWTYPEFORTIEXTENDER,
  platform: data.model,
  indent: true,
  id: `fext:${dev.oid}:${data.sn}`,
  firmware_os_version: data._sw_version,
});

const connection_value = {
  connected: 1,
  down: 2,
  unknown: 0,
};

export const getDeviceIcon = (device) => {
  switch (device.connection.conn) {
    case connection_value.unknown:
      return { icon: 'device', iconColor: 'color-grey' };
    case connection_value.connected:
      if (
        Array.isArray(device.connection.ha) &&
        device.connection.ha.length > 0
      ) {
        if (device && device.model_dev) {
          return { icon: 'device', iconColor: 'color-grey' };
        } else {
          return { icon: 'ha', iconColor: 'color-green' };
        }
      } else {
        // non-ha device Up
        return { icon: 'up', iconColor: 'color-green' };
      }
    case connection_value.down:
      if (
        Array.isArray(device.connection.ha) &&
        device.connection.ha.length > 0
      ) {
        if (device && device.model_dev) {
          return { icon: 'device', iconColor: 'color-grey' };
        } else {
          return { icon: 'ha', iconColor: 'color-orange' };
        }
      } else if (device && device.model_dev) {
        return { icon: 'device', iconColor: 'color-grey' };
      } else {
        // non-ha device Down
        return { icon: 'down', iconColor: 'color-orange' };
      }
    default:
      return { icon: 'up' };
  }
};
