import { fiHttpGet } from 'fi-http';

export function dismissWarningAll(adomName) {
  const warnings = ['db_quota_warning', 'file_quota_warning'];
  let BASE_URL = '/p/fortiview/storage/dismisswarning/';

  warnings.forEach((it) => {
    let url = `${BASE_URL}?params=${JSON.stringify({
      warning: it,
      adom: adomName,
    })}`;
    fiHttpGet(url);
  });
}
