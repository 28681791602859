import { combineReducers } from 'redux';
import sysConfigReducer from './sysConfig/slice';
import profileReducer from './profile/slice';
import sessionAdomReducer from './adom/slice';

export default combineReducers({
  adom: sessionAdomReducer,
  profile: profileReducer,
  sysConfig: sysConfigReducer,
});
