import { get_ws_proxy, Dispatch } from './ws_proxy';
import { isFunction } from 'lodash';

export function getWsProxy() {
  return get_ws_proxy();
}

export const fiSocket = { get_ws_proxy };

export const registerDispatch = (dispatch: Dispatch) => {
  const unregisterWsProxy = get_ws_proxy()?.register(dispatch);

  return () => {
    if (isFunction(unregisterWsProxy)) {
      unregisterWsProxy();
    }
  };
};
