import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useValidEffect } from 'rh_util_hooks';
import { debounce, get, isEmpty, map, some, filter, isNil } from 'lodash';
import { fiAdom, fiSysConfig, fiLogout } from 'fi-session';

import {
  _openTaskModal,
  _isInCannotDelList,
  openEditAdomModal,
  getIcon,
  getText,
  getLockStyle,
  switchToAdom,
  deleteAdom,
} from './util';
import { ProForm, ProLego, ProContextMenu } from '@fafm/neowise-pro';
import { NwBadge } from '@fafm/neowise/react';
import { NwInput, NwIcon, NwButton, NwTooltip } from '@fafm/neowise-core';
import { LoadingBar } from 'rc_toolbar';
import { ConditionalComponent } from 'react_components/rc_layout/form';
import { AdomMatrix } from './adom_matrix';
import './adom_select.less';
import AutoSizer from '@fafm/react-virtualized-auto-sizer';

import {
  highlightEl,
  isSelectedAdomsLockable,
  isSelectedAdomsUnlockable,
} from '../common/utils';

import { fiMessageBox } from 'fi-messagebox';
import { fiWorkspace } from 'fi-workspace';
import { fiStore, fiSession } from 'fistore';
import { UrlService } from 'fi-url';
import { fetchSessionAdom } from 'fistore/session/adom/slice';
import { useAdomList, useCannotDeleteList, useCheckAdomLicense } from './hooks';
import { useUpgradingAdomInfos } from '../common/hook';

const { getAdomAccess, hasRDWRPermitOn } = fiSession;
const { Tooltip } = ProLego;
const { ContextMenu, MenuItem } = ProContextMenu;

import { events } from 'fi_widgets/fi-events';
const { onEnterSpace } = events;
const { Header, Body, Footer } = ProForm;

const AUTOID_PREFIX = 'adom_select_';
const SEARCH_TOOLTIP = gettext(
  'You can search ADOM by ADOM name, ADOM description, device name or IP.'
);
const OVERSCAN = 30;
const CELL_PADDING = 5;
const CELL_HEIGHT = 57;
const CELL_WIDTH =
  fiSysConfig.isFaz() || !fiSysConfig.isWorkspaceEnabled() ? 240 : 270;

export const AdomSelectModal = (props) => {
  const { allowCancel = true, $opener } = props;
  const adomAccess = useSelector(getAdomAccess);
  const {
    adomList: fullAdomList,
    setAdomList,
    isLoadingAdomList,
    refreshAdomList,
  } = useAdomList();
  const expiredAdomLicenseMessage = useCheckAdomLicense();
  const cannotDeleteList = useCannotDeleteList(fullAdomList);
  const [selectedAdom, setSelectedAdom] = useState(null);
  const [, refresh] = useState(false);
  const upgradingAdomInfos = useUpgradingAdomInfos({
    adoms: fullAdomList,
    onTaskDone: refreshAdomList,
  });

  const isRwUser = useSelector(hasRDWRPermitOn('adom_switch'));

  const getContextMenu = useCallback(() => {
    const contextItems = {
      delete: {
        id: 'del',
        label: gettext('Delete'),
        icon: 'delete',
        disabled: function () {
          if (!selectedAdom || upgradingAdomInfos[selectedAdom.oid])
            return true;
          if (fiWorkspace.isWorkspaceEnabled(selectedAdom)) {
            if (_isInCannotDelList(selectedAdom, cannotDeleteList)) {
              return true;
            }
            if (fiSysConfig.isFazOnly()) return false;
            else {
              return selectedAdom['lock'].lock !== MACROS.SYS.LOCK_STATE_LOCKED;
            }
          } else {
            if (_isInCannotDelList(selectedAdom, cannotDeleteList)) {
              return true;
            }
            return !isRwUser;
          }
        },
        exec: function () {
          if (isNil(selectedAdom)) return;
          if (selectedAdom.device_counts !== 0) {
            fiMessageBox.show(
              gettext('Cannot delete non-empty ADOM.'),
              'danger'
            );
            return;
          }
          deleteAdom(selectedAdom).then(
            function (resp) {
              _openTaskModal(
                resp,
                gettext('Deleting Empty ADOM'),
                () => {
                  if (fiAdom.current().oid === selectedAdom.oid) {
                    // log out
                    fiLogout();
                  } else {
                    refreshAdomList();
                  }
                },
                $opener
              );
            },
            function (err) {
              if (err && !err.isCancel) {
                fiMessageBox.show(JSON.stringify(err), 'danger');
              }
            }
          );
        },
      },
      edit: {
        label: gettext('Edit'),
        icon: 'edit',
        disabled: function () {
          if (!selectedAdom || upgradingAdomInfos[selectedAdom.oid])
            return true;
          if (fiWorkspace.isWorkspaceEnabled(selectedAdom)) {
            if (fiSysConfig.isFazOnly()) return !isRwUser;
            else {
              if (selectedAdom['lock'].lock === MACROS.SYS.LOCK_STATE_LOCKED)
                return false;
              else return true;
            }
          } else {
            return !isRwUser;
          }
        },
        exec: function () {
          if (isNil(selectedAdom)) return;
          openEditAdomModal(
            selectedAdom.oid,
            () => {
              if (fiAdom.current().oid === selectedAdom.oid) {
                fiStore.dispatch(fetchSessionAdom());
              }
              refreshAdomList();
            },
            $opener
          );
        },
      },
      lock: {
        id: 'lock',
        label: gettext('Lock'),
        icon: 'lock',
        disabled: function () {
          if (!selectedAdom || upgradingAdomInfos[selectedAdom.oid])
            return true;
          return !isSelectedAdomsLockable([selectedAdom]);
        },
        exec: function () {
          if (isNil(selectedAdom)) return;
          fiWorkspace.adomLock(selectedAdom).then(
            () => {
              fiWorkspace.adomStateInit();
              refreshAdomList();
            },
            () => {
              fiMessageBox.show(
                gettext('Failed to lock ADOM: %s.').printf([selectedAdom.name])
              );
            }
          );
        },
      },
      unlock: {
        id: 'unlock',
        label: gettext('Unlock'),
        icon: 'unlocked',
        disabled: function () {
          if (!selectedAdom || upgradingAdomInfos[selectedAdom.oid])
            return true;
          return !isSelectedAdomsUnlockable([selectedAdom]);
        },
        exec: function () {
          if (isNil(selectedAdom)) return;
          fiWorkspace
            .adomUnlock(
              selectedAdom,
              false,
              Boolean(selectedAdom.lock_override)
            )
            .then(
              () => {
                fiWorkspace.adomStateInit();
                refreshAdomList();
              },
              () => {
                fiMessageBox.show(
                  gettext('Failed to unlock ADOM: %s.').printf([
                    selectedAdom.name,
                  ])
                );
              }
            );
        },
      },
    };

    const ctxMenu = [
      contextItems['edit'],
      contextItems['delete'],
      contextItems['lock'],
      contextItems['unlock'],
    ];

    return isRwUser ? ctxMenu : [];
  }, [selectedAdom, isRwUser, cannotDeleteList]);

  return (
    <>
      <Header>{gettext('Select an ADOM')}</Header>
      <Body className={'np-body tw-p-4 tw-h-full tw-w-full tw-box-border'}>
        <AdomSelect
          setSelectedAdom={setSelectedAdom}
          refresh={refresh}
          isLoadingAdomList={isLoadingAdomList}
          expiredAdomLicenseMessage={expiredAdomLicenseMessage}
          setAdomList={setAdomList}
          adomList={fullAdomList}
          upgradingAdomInfos={upgradingAdomInfos}
          {...props}
        />
      </Body>
      <ConditionalComponent
        condition={adomAccess !== MACROS.ADMIN.ADOM_ACCESS_PER_ADOM_PROF}
      >
        <Footer>
          <NwButton
            disabled={!isRwUser}
            onClick={() => {
              openEditAdomModal('', refreshAdomList, $opener);
            }}
            automation-id={AUTOID_PREFIX + 'create_new_adom'}
            type='default'
          >
            {gettext('Create New ADOM')}
          </NwButton>
          <ConditionalComponent condition={allowCancel}>
            <NwButton
              onClick={() => {
                $opener.reject();
              }}
              automation-id={AUTOID_PREFIX + 'close_adom_select_modal'}
              className={'tw-min-w-32 tw-pl-2'}
              type='default'
            >
              {gettext('Close')}
            </NwButton>
          </ConditionalComponent>
        </Footer>
      </ConditionalComponent>
      <Menu getCtxMenu={getContextMenu} />
    </>
  );
};

const AdomSelect = React.memo((props) => {
  const {
    $opener,
    setSelectedAdom,
    adomList,
    setAdomList,
    isLoadingAdomList,
    expiredAdomLicenseMessage,
    upgradingAdomInfos,
  } = props;

  const gridRef = useRef(null);
  const containerRef = useRef(null);
  const windowWidthRef = useRef(200);

  const [searchTerm, setSearchTerm] = useState('');

  const currentAdom = fiAdom.current();

  //update adom lock state from websocket
  useValidEffect((isValid) => {
    const unreg = [];

    if (isValid()) {
      fiWorkspace.adomStateInit();
      unreg.push(
        fiWorkspace.bindAdomUpdateFn(() => {
          const lockInfo = fiWorkspace.adomInfo();
          const adomLocks = lockInfo.allAdomLocks;

          setAdomList((prev) => {
            const newList = prev.map((adom) => {
              adom.lock = adomLocks[adom.oid] || {
                lock: MACROS.SYS.LOCK_STATE_UNLOCKED,
                lock_time: '',
                lock_user: '',
              };
              adom.lock.lock_msg = lockInfo.lockMessage(adom.lock);
              return adom;
            });

            return newList;
          });
        })
      );
    }

    return () => {
      unreg.forEach((it) => it());
    };
  }, []);

  //init selectedAdom to current adom and scroll to it on first load
  const calculatePosition = useCallback(
    (width) => {
      if (!Array.isArray(adomList)) return 0;

      for (let ii = 0; ii < adomList.length; ii++) {
        const ad = adomList[ii];
        if (ad.name === currentAdom.name) {
          const numCols = Math.floor(width / CELL_WIDTH);
          const rIdx = numCols ? Math.floor(ii / numCols) : 0;
          return rIdx;
        }
      }

      return 0;
    },
    [adomList]
  );

  const scrollToSelectedAdom = useCallback(
    (width) => {
      const rIdx = calculatePosition(width);
      if (gridRef.current && rIdx) {
        gridRef.current.scrollToItem?.({
          columnIndex: 0,
          rowIndex: rIdx,
        });
      }
    },
    [calculatePosition]
  );

  //highlight searched adom terms
  useValidEffect(
    (isValid) => {
      const term = searchTerm;
      if (isEmpty(term)) return;
      const hiliter = highlightEl(term);
      if (isValid()) {
        setTimeout(() => {
          const el = document.querySelector('.adom-select-container');
          el && hiliter(el);
        }, 100);
      }
    },
    [searchTerm]
  );

  const getFilteredAdoms = (adoms) => {
    const filteredAdoms = filter(adoms, function (adom) {
      return some(
        map(
          [
            adom.name,
            adom.description || '',
            adom.type_name,
            getMemberString(adom.members),
          ],
          (text) => text.toUpperCase().indexOf(searchTerm) !== -1
        )
      );
    });
    return filteredAdoms;
  };

  const debouncedSetSearchTerm = useMemo(
    () => debounce((term) => setSearchTerm(term), 500),
    [setSearchTerm]
  );

  const createItem = ({ data: adom, selected, style }) => {
    const lockStyle = getLockStyle({
      adomLock: adom.lock.lock,
      lockBy: adom.lock.lock_user,
      lockTime: adom.lock.lock_time,
      mode: adom.workspace_mode,
      otherLocks: adom.lock.other,
    });
    lockStyle.selected = selected;
    const disabledClass = adom.expired ? 'disabled' : '';
    const _id = `id_adom_${adom.name}`;
    const _class = `adom-select-item tw-box-border ${
      lockStyle.itemClass
    } ${disabledClass}${
      selected ? ' selected fi-focus-300-inner' : 'fi-focus-inner'
    }`;
    const newStyle = {
      ...style,
      left: style.left + 1,
      top: style.top + 2,
      width: style.width - 1,
      height: style.height - 2,
    };
    const adomClick = (e) => {
      if (e.type === 'click') return switchToAdom(adom, currentAdom, $opener);
      else if (e.type === 'contextmenu') setSelectedAdom(adom);
    };
    const keyDown = onEnterSpace(adomClick);

    const _el = (
      <Tooltip
        content={() => {
          const _adomNameText = `${adom.name} (${adom.device_counts || 0})`;

          const _adomDetailsText = adom.expiredText
            ? adom.expiredText
            : adom.description || '';

          return (
            <div id='ad-select-popover'>
              {_adomNameText}
              <div className='tw-max-w-xs tw-break-words'>
                {_adomDetailsText}
              </div>
            </div>
          );
        }}
        hideDelay={0}
      >
        <li
          style={newStyle}
          tabIndex='0'
          adom={adom.name}
          id={_id}
          className={_class}
          onClick={adomClick}
          onContextMenu={adomClick}
          onKeyDown={keyDown}
        >
          {' '}
          {getIcon(lockStyle)} {getText(adom, upgradingAdomInfos[adom.oid])}
        </li>
      </Tooltip>
    );
    return _el;
  };

  function renderSearchBar() {
    return (
      <div
        className={'tw-flex tw-flex-row tw-w-full'}
        style={{
          paddingLeft: '2px',
          paddingBottom: '10px',
        }}
      >
        <div className={'tw-flex-grow'}>
          <NwInput
            onInput={(e) => {
              debouncedSetSearchTerm(`${e.target.value}`.toUpperCase());
            }}
            automation-id={AUTOID_PREFIX + 'searchbar'}
            suffix={<NwIcon name='search'></NwIcon>}
          />
        </div>
        <div className={'tw-self-center tw-px-2'}>
          <NwTooltip placement='top' content={<div>{SEARCH_TOOLTIP}</div>}>
            <NwBadge type='info'></NwBadge>
          </NwTooltip>
        </div>
      </div>
    );
  }

  function renderAdomMatrix() {
    return (
      <div className={'adom-select-container tw-h-full tw-w-full '}>
        <div
          className={'adom-select tw-h-full tw-w-full tw-box-border'}
          ref={containerRef}
        >
          <AutoSizer>
            {({ height, width }) => {
              windowWidthRef.current = width;
              return (
                <AdomMatrix
                  adoms={getFilteredAdoms(adomList)}
                  gridRef={gridRef}
                  selected={currentAdom}
                  windowWidth={width}
                  windowHeight={height}
                  // windowWidth={800}
                  // windowHeight={600}
                  cellWidth={CELL_WIDTH}
                  cellHeight={CELL_HEIGHT}
                  cellPadding={CELL_PADDING}
                  overScan={OVERSCAN}
                  cellFormatter={createItem}
                  onRendered={() => {
                    setTimeout(() => scrollToSelectedAdom(width), 250);
                  }}
                />
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }

  return (
    <>
      <LoadingBar
        isLoading={isLoadingAdomList}
        zIndex={2}
        automation-id={AUTOID_PREFIX + 'loading-bar'}
      />

      <ConditionalComponent showCondition={isLoadingAdomList}>
        {gettext('Loading') + '...'}
      </ConditionalComponent>

      <div
        className={`adom-switcher tw-flex tw-flex-col tw-h-full tw-w-full ${
          isLoadingAdomList ? 'tw-hidden' : ''
        }`}
        style={{ minWidth: '300px' }}
      >
        <ConditionalComponent condition={!!expiredAdomLicenseMessage}>
          <div className='warning-container'>
            <span className='ffg ffg-warning expired-adom-icon'></span>
            <span className='expire-text'>{expiredAdomLicenseMessage}</span>
            <button
              className='expired-adom-btn'
              onClick={() => {
                UrlService.openFortinetSupport();
              }}
            >
              {gettext('Renew License')}
            </button>
          </div>
        </ConditionalComponent>
        {renderSearchBar()}
        {renderAdomMatrix()}
      </div>
    </>
  );
});

const Menu = ({ getCtxMenu }) => {
  const ctx = getCtxMenu() ?? [];
  if (ctx.length <= 0) return;

  return (
    <ContextMenu id='adom_select_ctx_menu'>
      {ctx.map((menuitem) => {
        return (
          <MenuItem
            onClick={menuitem.exec}
            key={menuitem.icon}
            disabled={menuitem.disabled()}
            label={menuitem.label}
            content={() => (
              <>
                <NwIcon name={menuitem.icon} />
                {menuitem.label}
              </>
            )}
          ></MenuItem>
        );
      })}
    </ContextMenu>
  );
};

/*********** UTIL FUNCTIONS **************/

const getMemberString = (members) => {
  if (!members) return '';
  return members.reduce(
    (acc, cur) =>
      acc +
      map(['name', 'desc', 'ip', 'sn'], (key) => get(cur, key, '')).join(',') +
      ',' +
      (cur.vdoms
        ? map(cur.vdoms, (v) => get(v, 'name', '')).join(',') + ','
        : ''),
    ''
  );
};
