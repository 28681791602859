import { fiFmgHttp } from 'fi-http';
import { get } from 'lodash';

export async function fetchTasks() {
  const req = {
    id: 0,
    method: 'get',
    params: [
      {
        url: '/task/task/',
        fields: ['id', 'title', 'state', 'percent', 'src'],
        filter: ['state', '==', MACROS.TASK.TASK_STATE_RUN],
      },
    ],
  };

  const resp = await fiFmgHttp.forward(req);
  return get(resp, [0, 'data'], []);
}
