import { RenderErrorBoundary } from 'react_apps/components/main/RenderErrorBoundary';
import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';

export default {
  path: 'fext',
  RenderErrorBoundary,
  handle: {
    appUniKey: 'fext',
  },
  children: [
    getRedirectIndexRoute(),
    {
      path: 'view',
      lazy: async () => {
        const { FextMain } = await import('./FextMain');
        return {
          Component: FextMain,
        };
      },
      children: [
        {
          path: 'list',
          handle: {
            appUniKey: 'fext_device',
          },
          lazy: async () => {
            const { Extender } = await import('./Extender/Extender');
            return {
              Component: Extender,
            };
          },
        },
      ],
    },

    {
      path: 'profiles',
      handle: {
        appUniKey: 'fext_profiles',
      },
      children: [
        {
          path: 'template',
          handle: {
            appUniKey: 'fext_profiles_template',
          },
          lazy: async () => {
            const { ExtenderTemplate } = await import(
              './ExtenderTemplate/ExtenderTemplate'
            );
            return {
              Component: ExtenderTemplate,
            };
          },
        },

        {
          path: 'sim',
          handle: {
            appUniKey: 'fext_profiles_simprof',
          },
          lazy: async () => {
            const { ExtenderSIMProfile } = await import(
              './ExtenderSIMProfile/ExtenderSIMProfile'
            );
            return {
              Component: ExtenderSIMProfile,
            };
          },
        },

        {
          path: 'dataplan',
          handle: {
            appUniKey: 'fext_profiles_dataplan',
          },
          lazy: async () => {
            const { ExtenderDataplan } = await import(
              './ExtenderDataplan/ExtenderDataplan'
            );
            return {
              Component: ExtenderDataplan,
            };
          },
        },

        {
          path: 'extender-profile',
          handle: {
            appUniKey: 'fext_profiles_prof',
          },
          lazy: async () => {
            const { ExtenderProfile } = await import(
              './ExtenderProfile/ExtenderProfile'
            );
            return {
              Component: ExtenderProfile,
            };
          },
        },
      ],
    },
  ],
};
