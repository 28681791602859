import React, { useCallback, useMemo } from 'react';
import { ProToolkit, ProForm } from '@fafm/neowise-pro';
import { Formik } from 'formik';
import {
  FmkInput,
  FmkErrorSpan,
  FmkRadioBtnGroup,
  fmkFooterButtons,
  FmkSSelect2,
} from 'rc_form';
import { getNocTemplates, getTemplateById } from '../utils/socDash';
import { fiStore, fiStoreSoc } from 'fistore';

const { Header, Body, Footer, Row, Column, Section } = ProForm;
const { ErrorBoundary } = ProToolkit;

const createFromChoices = [
  {
    id: 0,
    text: gettext('Blank'),
  },
  {
    id: 1,
    text: gettext('From Template'),
  },
];
const subTypeChoices = [
  {
    id: MACROS.FAZ_SYNTAX.DASHBOARD_SUB_TYPE_GENERIC,
    text: gettext('Generic'),
  },
  {
    id: MACROS.FAZ_SYNTAX.DASHBOARD_SUB_TYPE_SDWAN,
    text: gettext('SD-WAN Specific'),
  },
  {
    id: MACROS.FAZ_SYNTAX.DASHBOARD_SUB_TYPE_SECURITY_FABRIC,
    text: gettext('Security Fabric Specific'),
  },
];

const autoId = (suffix) => 'fortiview-new-custom-view-' + suffix;

const createUserDash = async (title, dashId, subType) => {
  const newDash = {
    widgets: [],
  };
  if (dashId) {
    const template = getTemplateById(dashId);
    if (template && template.widgets) newDash.widgets = template.widgets;
    newDash.widgets = newDash.widgets.filter((widget) => !widget.defaultHide);
  } else {
    newDash['sub-type'] = subType;
  }
  if (title) {
    newDash['created-from'] = dashId || '';
    newDash.name = title;
    newDash['is-template'] = false; // user dashboard
    const resp = await fiStore
      .dispatch(fiStoreSoc.customView.actions.createCustomView(newDash))
      .unwrap();
    return resp;
  }
  return Promise.resolve();
};

export const CreateDashForm = ({ $opener }) => {
  const onSubmit = useCallback(async (values, form) => {
    form.setSubmitting(true);
    const dashId = values['create-from'] === 0 ? '' : values['dash-id'];
    try {
      const resp = await createUserDash(
        values['name'],
        dashId,
        values['sub-type']
      );
      if (resp) {
        $opener.resolve({ dashId: resp.uuid });
      }
    } catch (error) {
      form.setErrors({ name: error });
    } finally {
      form.setSubmitting(false);
    }
  }, []);

  const initialValues = useMemo(() => {
    return {
      ['create-from']: 0,
      ['sub-type']: MACROS.FAZ_SYNTAX.DASHBOARD_SUB_TYPE_GENERIC,
    };
  }, []);

  const templateChoices = useMemo(() => {
    return getNocTemplates().map((template) => ({
      id: template.uuid,
      text: template.adomName
        ? `${template.name} (${template.adomName})`
        : template.name,
    }));
  }, []);

  const validate = useCallback((values) => {
    const errors = {};
    if (values['create-from'] === 1 && !values['dash-id']) {
      errors['dash-id'] = gettext('Please choose a template.');
    }
    return errors;
  }, []);

  return (
    <ErrorBoundary>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {(fctx) => (
          <>
            <Header
              onCloseClick={() => {
                $opener.resolve();
              }}
            >
              {gettext('New Custom View')}
            </Header>
            <Body>
              <Section>
                <Row label={gettext('Name')} labelRequired>
                  <Column>
                    <FmkInput
                      name='name'
                      automation-id={autoId('name')}
                      required
                    />
                    <FmkErrorSpan name='name' />
                  </Column>
                </Row>
                <Row label={gettext('Create from')}>
                  <Column>
                    <FmkRadioBtnGroup
                      name='create-from'
                      choices={createFromChoices}
                      automation-id={autoId('create-from')}
                    />
                    {fctx.values['create-from'] === 1 ? (
                      <>
                        <FmkSSelect2
                          name='dash-id'
                          source={templateChoices || []}
                          automationId={autoId('dash-id')}
                        />
                        <FmkErrorSpan name='dash-id' />
                      </>
                    ) : null}
                  </Column>
                </Row>
                {fctx.values['create-from'] === 0 ? (
                  <Row label={gettext('Subtype')}>
                    <Column>
                      <FmkRadioBtnGroup
                        name='sub-type'
                        choices={subTypeChoices}
                        automation-id={autoId('sub-type')}
                      />
                    </Column>
                  </Row>
                ) : null}
              </Section>
            </Body>
            <Footer>
              {fmkFooterButtons({
                canWrite: true,
                getAutoId: autoId,
                onCancel: () => {
                  $opener.resolve();
                },
              })}
            </Footer>
          </>
        )}
      </Formik>
    </ErrorBoundary>
  );
};
