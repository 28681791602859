import { escapeHtml } from 'kit/kit-escape';
import { single } from './sort';
import { isString, isNumber } from 'lodash';

// If thresh is given and type is number, it will be used in fuzzysort
// threshold option.
export function fuzzyHighlightEscape(
  text: string | number,
  search: string,
  thresh?: number
) {
  if (!isString(search) || (isString(search) && search.length === 0)) {
    return escapeHtml(text);
  }

  if (!text && text !== 0) {
    text = '';
  } else if (!isString(text)) {
    // force it to be string, so if we accidentally
    // input an array, it will not cause vulnerability.
    text = '' + text;
  }

  const options: Fuzzysort.Options = {};
  if (isNumber(thresh)) {
    options.threshold = thresh;
  }

  const result = single(search, text, options);
  if (!result) return escapeHtml(text);

  const ranges: [number, number][] = [];
  const indexes = result.indexes;
  let sectionStart: number | undefined;

  for (let i = 0; i < indexes.length; ++i) {
    const currentPos: number = indexes[i];
    const nextPos = indexes[i + 1];
    if (sectionStart === undefined) {
      sectionStart = currentPos;
    }
    if (currentPos + 1 !== nextPos || i >= indexes.length) {
      ranges.push([sectionStart, currentPos - sectionStart + 1]);
      sectionStart = nextPos;
    }
  }

  if (sectionStart !== undefined) {
    ranges.push([sectionStart, indexes[indexes.length - 1] - sectionStart]);
  }

  let output = '';
  let nextIndex = 0;
  ranges.forEach(function (range) {
    const [offset, length] = range;
    // escape non-match substring before this match
    output += escapeHtml(text.substring(nextIndex, offset));

    output +=
      '<span class="fi-search-highlight">' +
      escapeHtml(text.substring(offset, length)) +
      '</span>';
    nextIndex = offset + length;
  });

  if (nextIndex !== text.length) {
    // everything leftover after the last match
    output += escapeHtml(text.substring(nextIndex, text.length));
  }
  return output;
}
