import { useReducer, useState } from 'react';
import { useValidEffect } from './useEffects';
import { useReload } from './useReload';
import { fiFmgHttp } from 'fi-http';
import * as fiUUID from 'fi-uuid';

export { useJsonApi, makeRequest };

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
};

const useJsonApi = ({
  request,
  method = 'forward',
  initData,
  parseData = (data) => data,
  dependencies = [],
}) => {
  const [req, setReq] = useState(request);
  const [reload, setReload] = useReload();

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initData,
  });

  useValidEffect(
    (isValid) => {
      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const resp = await fiFmgHttp[method](req);
          if (isValid()) {
            dispatch({
              type: 'FETCH_SUCCESS',
              payload: parseData(resp[0].data),
            });
          }
        } catch (error) {
          if (isValid()) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
        }
      };

      fetchData();
    },
    [reload, req, ...dependencies]
  );

  return { state, setReq, setReload };
};

const makeRequest = (params, method = 'get') => {
  let req = {
    id: fiUUID.gen(),
    method,
  };
  if (params) {
    req.params = Array.isArray(params) ? params : [params];
  }
  return req;
};
