import React, { useRef, useEffect } from 'react';
import $ from 'jquery';
/***
 * options: see https://select2.org/configuration/options-api
 * exampleOptions = {
    data: [{id, text}],
    width: 'resolve',
    multiple: true,
    placeholder: gettext('Click to add ...'),
    closeOnSelect: false,
  }
 *
 *
 * onChange: onchange handler
 *
 * className: additional classnames to select
 *
 * initialValues: optional array of initially selected ids
 */
export const Select2 = ({
  options = null,
  onChange = () => {},
  className = '',
  initialValues = null,
  ...rest
}) => {
  const selectRef = useRef();

  useEffect(() => {
    let ref = null;
    if (selectRef.current) {
      ref = selectRef.current;
      $(selectRef.current).select2(options);
      $(selectRef.current).on('change', function (e) {
        onChange(e);
      });
      if (initialValues) {
        //change initial val
        //notify only select2 using 'change.select2' event so onChange isn't called
        $(selectRef.current)
          .select2('val', initialValues)
          .trigger('change.select2');
      }
    }
    return () => {
      $(ref).off('change');
      $(ref).select2('destroy');
    };
  }, []);

  return (
    <input
      className={'tw-w-full form-control' + className}
      ref={selectRef}
      {...rest}
    />
  );
};
