import { getZonePath } from 'fi-datetime';
import ReactDOM from 'react-dom';
import neowiseWrapper from '@fafm/neowise-wrapper';
import React, { useMemo, useRef, useCallback } from 'react';
import { Formik } from 'formik';
import { Label, FormRowGroup, OkBtn, CancelBtn } from 'rc_layout';
import { FmkErrorSpan } from 'rc_form';
import { isValidDate } from './helpers';
import moment from 'moment';
import './customTimeDialog.less';

const NwDialog = neowiseWrapper('nw-dialog');
const NwDatePicker = neowiseWrapper('nw-date-picker');
const NwTimePicker = neowiseWrapper('nw-time-picker');

const CustomTimeDialog = React.forwardRef(
  (
    {
      customPeriod,
      onAfterSubmit,
      fields = ['start', 'end'],
      useSysTz = false,
    },
    ref
  ) => {
    const formRef = useRef();

    const tz = getZonePath();
    const initialValues = useMemo(() => {
      let startMoment;
      let endMoment;
      if (useSysTz) {
        startMoment = moment.tz(customPeriod.start, tz);
        endMoment = moment.tz(customPeriod.end, tz);
      } else {
        // don't break old behaviour if they didn't add useSysTz
        startMoment = moment(customPeriod.start);
        endMoment = moment(customPeriod.end);
      }
      return {
        start_date: startMoment.format('YYYY-MM-DD'),
        start_time: startMoment.format('HH:mm:ss'),
        end_date: endMoment.format('YYYY-MM-DD'),
        end_time: endMoment.format('HH:mm:ss'),
      };
    }, [customPeriod, tz, useSysTz]);

    const onSubmit = useCallback((values, form) => {
      let start = `${values.start_date} ${values.start_time}`;
      let end = `${values.end_date} ${values.end_time}`;
      if (!isValidDate(start) || !isValidDate(end)) {
        let err = {};
        if (!isValidDate(start)) {
          err.start = gettext('Invalid date input');
        }
        if (!isValidDate(end)) {
          err.end = gettext('Invalid date input');
        }
        form.setErrors(err);
        return;
      }
      if (typeof onAfterSubmit === 'function') {
        const dialog = ref.current;
        dialog.hide();
        onAfterSubmit({
          start,
          end,
        });
      }
    }, []);

    const cancel = useCallback(() => {
      const dialog = ref.current;
      dialog.hide();
    }, []);

    return (
      <NwDialog
        label={gettext('Custom Time Period')}
        class='rc-custom-time-dialog'
        style={{ '--width': '600px' }}
        ref={ref}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          innerRef={formRef}
          onSubmit={onSubmit}
        >
          {(fctx) => (
            <>
              <div className='modal-body'>
                <div style={{ minHeight: '200px' }}>
                  {fields.includes('start') && (
                    <FormRowGroup>
                      <Label>{gettext('Start Time')}</Label>
                      <div className='fi-col-rest'>
                        <NwDatePicker
                          value={fctx.values.start_date}
                          onNwChange={(e) =>
                            fctx.setFieldValue('start_date', e.target.value)
                          }
                          min='1970-01-01'
                          hoist
                        />
                        <NwTimePicker
                          value={fctx.values.start_time}
                          onNwChange={(e) =>
                            fctx.setFieldValue('start_time', e.target.value)
                          }
                          hoist
                          has-second
                          no-ampm
                        />
                        <FmkErrorSpan name='start' />
                      </div>
                    </FormRowGroup>
                  )}
                  <br />
                  {fields.includes('end') && (
                    <FormRowGroup>
                      <Label>{gettext('End Time')}</Label>
                      <div className='fi-col-rest'>
                        <NwDatePicker
                          class='tw-inline-block'
                          value={fctx.values.end_date}
                          onNwChange={(e) =>
                            fctx.setFieldValue('end_date', e.target.value)
                          }
                          min='1970-01-01'
                          hoist
                        />
                        <NwTimePicker
                          class='tw-inline-block'
                          value={fctx.values.end_time}
                          onNwChange={(e) =>
                            fctx.setFieldValue('end_time', e.target.value)
                          }
                          hoist
                          has-second
                          no-ampm
                        />
                        <FmkErrorSpan name='end' />
                      </div>
                    </FormRowGroup>
                  )}
                </div>
              </div>
              <div className='modal-footer'>
                <OkBtn onClick={fctx.submitForm}>{gettext('OK')}</OkBtn>
                <CancelBtn onClick={cancel}>{gettext('Cancel')} </CancelBtn>
              </div>
            </>
          )}
        </Formik>
      </NwDialog>
    );
  }
);

export const useCustomTimeDialog = (props = {}, deps = []) => {
  const dialogRef = useRef();
  return useMemo(() => {
    const portal = ReactDOM.createPortal(
      <CustomTimeDialog ref={dialogRef} {...props} />,
      document.body
    );
    return {
      ref: dialogRef,
      portal: portal,
    };
  }, deps);
};
