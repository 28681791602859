import { isDefined } from 'fiutil';
export const ApSignalStrength = {
  getValue: getValue,
};

const signalStrength = [
  { n: -25, p: 70, per: '100%' },
  { n: -28, p: 67, per: '96%' },
  { n: -31, p: 64, per: '91%' },
  { n: -34, p: 61, per: '87%' },
  { n: -37, p: 58, per: '83%' },
  { n: -40, p: 55, per: '79%' },
  { n: -43, p: 52, per: '74%' },
  { n: -46, p: 49, per: '70%' },
  { n: -49, p: 46, per: '66%' },
  { n: -52, p: 43, per: '61%' },
  { n: -55, p: 40, per: '57%' },
  { n: -58, p: 37, per: '53%' },
  { n: -61, p: 34, per: '49%' },
  { n: -64, p: 31, per: '44%' },
  { n: -67, p: 28, per: '40%' },
  { n: -70, p: 25, per: '36%' },
  { n: -73, p: 22, per: '31%' },
  { n: -76, p: 19, per: '27%' },
  { n: -79, p: 16, per: '23%' },
  { n: -82, p: 13, per: '19%' },
  { n: -85, p: 10, per: '14%' },
  { n: -88, p: 7, per: '10%' },
  { n: -91, p: 4, per: '6%' },
  { n: -94, p: 1, per: '1%' },
];

// get signal strength css
function getValue(dbm) {
  if (!isDefined(dbm)) {
    return {
      val: 0,
      per: 0,
    };
  }

  var attr = dbm < 0 ? 'n' : 'p';
  var ret = {},
    ii,
    len,
    left,
    right;

  if (dbm >= signalStrength[0][attr]) {
    ret = {
      val: dbm,
      per: 100,
    };
  } else if (dbm <= signalStrength[signalStrength.length - 1][attr]) {
    ret = {
      val: dbm,
      per: 1,
    };
  } else {
    for (ii = 0, len = signalStrength.length - 1; ii < len; ii++) {
      left = signalStrength[ii];
      right = signalStrength[ii + 1];

      if (dbm <= left[attr] && dbm > right[attr]) {
        ret = {
          val: dbm,
          per: parseInt(left.per, 10),
        };
        break;
      }
    }
  }

  ret.css = _signalStrengthCSS(ret.per);
  return ret;
}

// get signal strength css
function _signalStrengthCSS(per) {
  var css;
  if (per >= 80) css = 'progress-bar-success';
  else if (per >= 60) css = 'progress-bar-info';
  else if (per >= 40) css = 'progress-bar-warning';
  else css = 'progress-bar-danger';

  return css;
}
