import { useRef } from 'react';
import _ from 'lodash';

export function useInternalState({ initVal = {} } = {}) {
  const instanceRef = useRef(initVal);

  // to differentiate from lodash's get function
  const _getInst = () => {
    return instanceRef.current;
  };

  const _has = (key) => {
    return _.has(_getInst(), key);
  };

  const _getAll = () => {
    return _getInst();
  };

  const _get = (key, defaultVal) => {
    return _.get(_getInst(), key, defaultVal);
  };

  const _set = (val = {}) => {
    Object.assign(_getInst(), {
      ...val,
    });
  };

  const _update = (key, val) => {
    _.set(_getInst(), key, val);
  };

  const _delete = (key) => {
    if (_has(key)) {
      _.set(_getInst(), key, undefined);
    }
  };

  const _clear = () => {
    instanceRef.current = {};
  };

  return {
    has: _has,
    get: _get,
    getAll: _getAll,
    set: _set,
    update: _update,
    delete: _delete,
    clear: _clear,
  };
}
