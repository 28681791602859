import { ProLayout } from '@fafm/neowise-pro';
import { NwButton, NwIcon } from '@fafm/neowise-core';
import { useState, memo, useEffect, useRef } from 'react';
import {
  debuggerDidStart,
  stopDebugger,
  debuggerWillStop,
} from 'chrome_extension_debugger';
import { initPromptStartCapture } from './promptStartCapture';

initPromptStartCapture();

export const ExtensionDebugger = memo(() => {
  /**
   * undefined: not started
   * string: formatted duration
   */
  const [recordDuration, setRecordDuration] = useState(undefined);

  const intervalRef = useRef();

  const stopCapture = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
    setRecordDuration(undefined);
    stopDebugger();
  };

  useEffect(() => {
    function formatDuration(secs) {
      return `${String((secs / 60) << 0).padStart(2, '0')}:${String(
        secs % 60
      ).padStart(2, '0')}`;
    }

    const unregister = debuggerDidStart((startedTime) => {
      intervalRef.current = setInterval(() => {
        setRecordDuration(formatDuration(startedTime++));
      }, 1000);
    });

    const unregister1 = debuggerWillStop(stopCapture);

    return () => {
      unregister();
      unregister1();
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
      // NOTE: do not stop, component unmount when switch ADOM
      // stopCapture();
    };
  }, []);

  return (
    recordDuration !== undefined && (
      <ProLayout.HeaderAction
        onClick={stopCapture}
        automationId='extension-debugger'
      >
        <NwButton className='tw-bg-danger hover:' type='danger'>
          <NwIcon name='video' className='tw-mr-1' />
          {recordDuration}
        </NwButton>
      </ProLayout.HeaderAction>
    )
  );
});
ExtensionDebugger.displayName = 'ExtensionDebugger';
