import React from 'react';
import { escapeRegExp } from 'lodash';
import { ProToolkit } from '@fafm/neowise-pro';
import { fiStore, fiStoreRouting as fr } from 'fistore';
import { Rename } from './Rename';
import { Share } from './Share';
import { Saveas } from './Saveas';
import { Delete } from './Delete';
import { fiAdminProfile, fiSysConfig } from 'fi-session';

const MODAL_OPTIONS = {
  height: '20rem',
};

export const getContextMenuActions = () => {
  const menuItemActions = (menuItem) => {
    const isReadOnly = fiAdminProfile.readOnlyOn('fabric_viewer');
    const adminName = fiSysConfig.current().admin_user_name || '';
    const checkRegex = new RegExp(`^${escapeRegExp(adminName)}\\|`);
    const owner = menuItem?.stateParams?.owner || '';
    // only non-readonly users' customview menu has actions
    if (isReadOnly || !menuItem.key.startsWith('fabric_customview_')) {
      return [];
    }

    const publicNonCreator = [
      {
        key: 'saveas',
        label: gettext('Save As'),
        icon: 'dial-up',
        onClick: async () => {
          await ProToolkit.openModal(
            <Saveas menuItem={menuItem} />,
            MODAL_OPTIONS
          );
        },
      },
    ];

    const menus = [
      {
        key: 'rename',
        label: gettext('Rename'),
        icon: 'copy',
        onClick: async () => {
          await ProToolkit.openModal(
            <Rename menuItem={menuItem} />,
            MODAL_OPTIONS
          );
        },
      },
      {
        key: 'share',
        label: gettext('Share with Others'),
        icon: 'share',
        onClick: async () => {
          await ProToolkit.openModal(
            <Share menuItem={menuItem} />,
            MODAL_OPTIONS
          );
        },
      },
      ...publicNonCreator,
      {
        key: 'delete',
        label: gettext('Delete'),
        icon: 'delete',
        onClick: async () => {
          try {
            await ProToolkit.openModal(
              <Delete menuItem={menuItem} />,
              MODAL_OPTIONS
            ).result;
            if (
              menuItem.key === fr.getCurrentState(fiStore.getState()).appUniKey
            ) {
              await fiStore.dispatch(fr.go('adom.default.fabric.asset.view'));
            }
          } catch {
            // ignore cancel
          }
        },
      },
    ];

    return checkRegex.test(owner) || owner === '' ? menus : publicNonCreator;
  };
  return menuItemActions;
};
