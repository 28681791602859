import { DeviceFormatter, DeviceRowIcon } from 'ra_device_util';
import { escapeHtml } from 'kit-escape';

function getIconTitle(dev) {
  const {
    iconProps: icon,
    className,
    title,
  } = DeviceFormatter.getSimpleStatusIcon(dev);
  return { icon, className, title };
}

export const DvmHFormatter = {
  fmtDevName(dev, hiliter) {
    var { icon, title, className } = getIconTitle(dev);
    return (
      <span className='dev'>
        <DeviceRowIcon iconProps={icon} title={title} className={className} />
        <span title={dev.name}>{hiliter(dev.name)}</span>
      </span>
    );
  },
  fmtDevNameHtml(dev, hiliter) {
    var { icon, title } = getIconTitle(dev);
    const escapedName = escapeHtml(dev.name);
    return `<nw-icon name="${icon.name}" class="${
      icon.className
    }" title="${title}"> </nw-icon> <span>${
      hiliter ? hiliter(escapedName) : escapedName
    }</span>`;
  },
  // fmtFolder(
  //   dev,
  //   devToFolderMap,
  //   hiliter,
  //   folderPathCallback,
  //   folderIconCallback
  // ) {
  //   const title = gettext('Folder');
  //   const folder = devToFolderMap[dev.id + ''] || {};
  //   const folderName = folder.fpath || '';
  //   let uiFolderName = folderName;
  //   let icon = 'ffg-folder folder-color';
  //   if (folderIconCallback) icon = folderIconCallback(folderName, icon);
  //   if (folderPathCallback) uiFolderName = folderPathCallback(folderName);
  //   return h('span.dev', [
  //     h('span', { props: { className: `tw-mr-1 ffg ${icon}`, title } }),
  //     h(
  //       'span',
  //       { props: { title: uiFolderName } },
  //       gettext('In folder: ') + hiliter(uiFolderName)
  //     ),
  //   ]);
  // },
  getIconTitle,
};
