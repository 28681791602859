import { useRef, useCallback } from 'react';
import { isFunction } from 'lodash';

export function useRefCallback(callback) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  return useCallback((...args) => {
    if (isFunction(callbackRef.current)) {
      return callbackRef.current.apply(null, args);
    }
  }, []);
}
