import {
  sdwanMonitorHealthCheckActions,
  sdwanMonitorMembersActions,
  sdwanAvailableInterfacesActions,
  sdwanComputedPhase1Actions,
  sdwanComputedPhase2Actions,
} from './actions';

const appendChunkData = (dispatch, adomOid, data, actionCreator) =>
  dispatch(actionCreator({ adomOid, path: adomOid, data }));
const delegateAction = (dispatch, adomOid, actionCreator) =>
  dispatch(actionCreator({ adomOid, path: adomOid }));

// ========================
// SD-WAN health-check
// ========================
export const sendHealthCheckBatchStart = (dispatch, adomOid) => {
  /**
   * {
   *   type: FETCH_SDWAN-MONITOR-HEALTH-CHECK_REQUEST,
   *   payload: {
   *     adomOid,
   *     path
   *   }
   * }
   */
  // dispatch(sdwanMonitorHealthCheckActions.fetch.start({ adomOid: adomOid, path: adomOid})); //payload
  delegateAction(dispatch, adomOid, sdwanMonitorHealthCheckActions.fetch.start);
};
export const appendHealthCheckData = (dispatch, adomOid, data) =>
  appendChunkData(
    dispatch,
    adomOid,
    data,
    sdwanMonitorHealthCheckActions.record.append
  );
export const sendHealthCheckFetchSuccess = (dispatch, adomOid) =>
  delegateAction(
    dispatch,
    adomOid,
    sdwanMonitorHealthCheckActions.fetch.success
  );
export const clearHealthCheckData = (dispatch, adomOid) =>
  delegateAction(
    dispatch,
    adomOid,
    sdwanMonitorHealthCheckActions.record.delete
  );

// ========================
// SD-WAN Members
// ========================
//FIXME: maybe use default export and combine everything?
export const sendMembersBatchStart = (dispatch, adomOid) =>
  delegateAction(dispatch, adomOid, sdwanMonitorMembersActions.fetch.start);
export const appendMembersData = (dispatch, adomOid, data) =>
  appendChunkData(
    dispatch,
    adomOid,
    data,
    sdwanMonitorMembersActions.record.append
  );
export const sendMembersFetchSuccess = (dispatch, adomOid) =>
  delegateAction(dispatch, adomOid, sdwanMonitorMembersActions.fetch.success);
export const clearMembersData = (dispatch, adomOid) =>
  delegateAction(dispatch, adomOid, sdwanMonitorMembersActions.record.delete);

// ===========================
// SD-WAN Available interface
// ===========================
export const sendAvailableInterfacesBatchStart = (dispatch, adomOid) =>
  delegateAction(
    dispatch,
    adomOid,
    sdwanAvailableInterfacesActions.fetch.start
  );
export const appendAvailableInterfacesData = (dispatch, adomOid, data) =>
  appendChunkData(
    dispatch,
    adomOid,
    data,
    sdwanAvailableInterfacesActions.record.append
  );
export const sendAvailableInterfacesSuccess = (dispatch, adomOid) =>
  delegateAction(
    dispatch,
    adomOid,
    sdwanAvailableInterfacesActions.fetch.success
  );
export const clearAvailableInterfacesData = (dispatch, adomOid) =>
  delegateAction(
    dispatch,
    adomOid,
    sdwanAvailableInterfacesActions.record.delete
  );

// ===========================
// SD-WAN Computed Data
// ===========================
export const sendComputedSDWANDataBatchStart = (dispatch, adomOid) => {
  delegateAction(dispatch, adomOid, sdwanComputedPhase1Actions.fetch.start);
  delegateAction(dispatch, adomOid, sdwanComputedPhase2Actions.fetch.start);
};
export const appendComputedPhase1Data = (dispatch, adomOid, data) =>
  appendChunkData(
    dispatch,
    adomOid,
    data,
    sdwanComputedPhase1Actions.record.append
  );
export const appendComputedPhase2Data = (dispatch, adomOid, data) =>
  appendChunkData(
    dispatch,
    adomOid,
    data,
    sdwanComputedPhase2Actions.record.append
  );
export const sendComputedPhase1DataSuccess = (dispatch, adomOid) =>
  delegateAction(dispatch, adomOid, sdwanComputedPhase1Actions.fetch.success);
export const sendComputedPhase2DataSuccess = (dispatch, adomOid) =>
  delegateAction(dispatch, adomOid, sdwanComputedPhase2Actions.fetch.success);
export const clearComputedSDWANData = (dispatch, adomOid) => {
  delegateAction(dispatch, adomOid, sdwanComputedPhase1Actions.record.delete);
  delegateAction(dispatch, adomOid, sdwanComputedPhase2Actions.record.delete);
};
