import { checkReadOnlyStatus } from 'ra_device_util';
import { fiWorkspace } from 'fi-workspace';
import { useState, useEffect } from 'react';

export const hasWritePermit = (device) => {
  const readOnly = checkReadOnlyStatus({
    cfg_name: MACROS.USER.DVM.ADMINPRIV_DEV_MANAGER,
    supportWorkflow: true,
    ignoreSessionStatus: false,
    device,
  });
  return !readOnly;
};

export const useWritePermit = (device) => {
  const [canWrite, setCanWrite] = useState(hasWritePermit(device));

  useEffect(() => {
    const deReg = fiWorkspace.bindAdomUpdateFn(() => {
      setCanWrite(hasWritePermit(device));
    });
    return () => {
      deReg();
    };
  }, []);

  return canWrite;
};
