/**
 *
 * Present 2 options:
 * 1. Create new firmware template
 * 2. Use existing template
 *
 * 3 pages:
 * 1. choose the 2 options
 * 2. firmware template with either new or existing
 * 3. "The upgrade has been setup successfully"
 */

//HOOKS
import { useState } from 'react';
import { compareFirmwares } from 'kit-compare';

import { objectArrayToMap } from 'kit-array';

// COMPONENTS
import { ChooseMethod } from './pages/ChooseMethod';
import { Success } from './pages/Success';
import { WizardStep, WizardView } from 'rc_wizard';
import { Template } from './pages/Template';
import { ChooseDevicesToAssign } from './pages/ChooseDevicesToAssign';

export const FirmwareTemplateWizard = ({
  newProfData = {},
  FirmwareTemplateEditDrawerWrapper, //need to get this externally using dynamic import
  $opener,
  assignToDevs = [],
  onSubmitFirmwareTemplate = () => {},
}) => {
  const [isNewProf, setIsNewProf] = useState(true);
  const [selectedProf, setSelectedProf] = useState(null);
  const [newTemplateData, setNewTemplateData] = useState(null);
  const [chosenAssignToDevs, setChosenAssignToDevs] = useState([]);

  const tabs = getTabs();

  const generateTabs = () => {
    const mergedExistingAndNewUpgrades = mergeExistingProf(
      selectedProf,
      newProfData
    );
    const tabProps = {
      isNewProf,
      setIsNewProf,
      selectedProf,
      setSelectedProf,
      editParams: mergedExistingAndNewUpgrades,
      $opener,
      assignToDevs,
      FirmwareTemplateEditDrawerWrapper,
      onSubmitFirmwareTemplate,
      newTemplateData,
      setNewTemplateData,
      chosenAssignToDevs,
      setChosenAssignToDevs,
    };

    return tabs.map((tab) => {
      return (
        <WizardStep key={`PSIRT_FIRMWARE_WIZARD:${tab.id}`} step={tab.id}>
          <tab.Component {...tabProps} />
        </WizardStep>
      );
    });
  };

  return (
    <WizardView
      stepSequence={tabs}
      initialStep={FIRMWARE_WIZARD_STEPS.SELECT_EXISTING}
      title={gettext('Setup Upgrade')}
    >
      {generateTabs()}
    </WizardView>
  );
};

const FIRMWARE_WIZARD_STEPS = {
  SELECT_EXISTING: 'SELECT_EXISTING',
  FIRMWARE_TEMPLATE: 'FIRMWARE_TEMPLATE',
  ASSIGN_DEVICES: 'ASSIGN_DEVICES',
  SUCCESS: 'SUCCESS',
};

const getTabs = () => {
  const tabs = [
    {
      id: FIRMWARE_WIZARD_STEPS.SELECT_EXISTING,
      title: gettext('Choose a Method'),
      Component: ChooseMethod,
      text: gettext('Choose a Method'),
    },
    {
      id: FIRMWARE_WIZARD_STEPS.FIRMWARE_TEMPLATE,
      title: gettext('Firmware Template'),
      Component: Template,
      text: gettext('Firmware Template'),
    },
    {
      id: FIRMWARE_WIZARD_STEPS.ASSIGN_DEVICES,
      title: gettext('Choose Devices to Assign'),
      Component: ChooseDevicesToAssign,
      text: gettext('Choose Devices to Assign'),
    },
    {
      id: FIRMWARE_WIZARD_STEPS.SUCCESS,
      title: '',
      Component: Success,
      text: '',
    },
  ];

  return tabs;
};
const FWMPROF_CATEGORY = 'fwmprof setting';

//start with existing and new upgrades
//filter existing by ->

//1. same platform, newer version -> replace
//2. same platform, same version => keep
function mergeExistingProf(existingProf, generatedProf) {
  if (!existingProf) return generatedProf;
  if (!generatedProf) return existingProf;

  const existingUpgrades =
    existingProf[FWMPROF_CATEGORY]['enforced version'] || [];
  // const existingUpgradeMap = objectArrayToMap(existingUpgrades, 'platform');

  const generatedUpgrades =
    generatedProf.initialValues[FWMPROF_CATEGORY]['enforced version'] || [];
  // .filter(upgrade => !existingUpgradeMap[upgrade.platform]);

  const generatedUpgradeMap = objectArrayToMap(generatedUpgrades, 'platform');

  const newUpgrades = existingUpgrades.filter((upgrade) => {
    if (!generatedUpgradeMap[upgrade.platform]) return true;
    const generated = generatedUpgradeMap[upgrade.platform];
    const isGreaterVersion = compareFirmwares(
      generated.version,
      upgrade.version,
      '>'
    );
    if (isGreaterVersion) return false;
    return true;
  });

  const existingUpgradeMap = objectArrayToMap(newUpgrades, 'platform');

  generatedUpgrades.forEach((upgrade) => {
    if (existingUpgradeMap[upgrade.platform]) return;
    newUpgrades.push(upgrade);
  });

  // const upgradesToUse = newUpgrades.concat(existingUpgrades);
  // .filter((version) => !newUpgradesMap[version.platform])
  // .concat(newUpgrades);
  const existingScopeMembers = existingProf['scope member'] || [];

  const newProf = {
    ...generatedProf,
    initialValues: {
      ...generatedProf.initialValues,
      [FWMPROF_CATEGORY]: {
        ...generatedProf.initialValues[FWMPROF_CATEGORY],
        'enforced version': newUpgrades,
      },
      name: existingProf.name,
      'scope member': existingScopeMembers,
    },
    name: existingProf.name,
  };

  // newProf.initialValues[FWMPROF_CATEGORY]['enforced version'] = newUpgrades;

  // newProf.name = existingProf.name;
  // newProf.initialValues.name = existingProf.name;

  // newProf.initialValues['scope member'] = existingScopeMembers;
  // const existingScopeMemberMap = objectArrayToMap(existingScopeMembers, 'name')

  // const newScopeMembers =

  return newProf;
}
