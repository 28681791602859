import { fiHttp } from 'fi-http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSaveData } from './selectors';

const initialState = { data: {} };

const slice = createSlice({
  name: 'currentDash',
  initialState,
  reducers: {
    update(state, { payload }) {
      state.data[payload.uuid] = payload;
    },
    update_dash_data(state, { payload: { dashId, dashData } }) {
      if (!state.data[dashId]?.['dash-data']) {
        return;
      }
      Object.assign(state.data[dashId]['dash-data'], dashData);
    },
    clear(state, { payload: dashId }) {
      delete state.data[dashId];
    },
    update_widgets(state, { payload: { dashId, widgets } }) {
      if (!widgets) {
        return;
      }
      state.data[dashId].widgets = widgets.map((widget) => ({
        name: widget.name,
        grid: widget.grid,
        data: widget.data,
        props: widget.props,
      }));
    },
    update_widget_data_by_name(
      state,
      { payload: { dashId, data, name, isTemp } }
    ) {
      if (!data || !name) {
        return;
      }
      let widgets = state.data[dashId]?.widgets || [];
      let widget = widgets.find((widget) => widget.name === name);
      if (!widget) {
        return;
      }
      if (isTemp) {
        widget.tempData = {
          ...(widget.tempData || {}),
          ...data,
        };
      } else {
        widget.data = {
          ...(widget.data || {}),
          ...data,
        };
      }
    },
  },
});

const fetchDash = createAsyncThunk(
  'soc/currentDash/FETCH_DASH',
  async ({ dashId, signal }) => {
    const resp = await fiHttp.get('/p/noc/get_dash/', {
      params: {
        uuid: dashId,
      },
      signal,
    });
    return resp?.data?.dash;
  }
);

const createDash = createAsyncThunk(
  'soc/currentDash/CREATE_DASH',
  async ({ dash, signal }, { rejectWithValue }) => {
    const resp1 = await fiHttp.post('/p/noc/create_dash/', dash, { signal });
    if (resp1?.data?.status?.code) {
      return rejectWithValue(resp1.data.status.message);
    }
    // some logic in python side, need to call get dash
    const resp2 = await fiHttp.get('/p/noc/get_dash/', {
      params: {
        uuid: resp1.data.data.uuid,
      },
      signal,
    });
    return resp2.data.dash;
  }
);

const saveDash = createAsyncThunk(
  'soc/currentDash/SAVE_DASH',
  async ({ dashId }, { getState }) => {
    let dash = getSaveData({ dashId })(getState());
    const resp = await fiHttp.post('/p/noc/save_dash/', {
      uuid: dash.uuid,
      dash,
    });
    return resp;
  }
);

const {
  update,
  update_dash_data,
  clear,
  update_widgets,
  update_widget_data_by_name,
} = slice.actions;

export {
  fetchDash,
  createDash,
  saveDash,
  update,
  update_dash_data,
  clear,
  update_widgets,
  update_widget_data_by_name,
};

export default slice.reducer;
