import { validIp6 } from 'fiutil';
import { neowiseIpInputField } from './FmkIpV4Input';
import { NwIpV6Input } from '@fafm/neowise-core';

export const FmkIpV6Input = neowiseIpInputField(NwIpV6Input, {
  ipValidate: () => {
    return (value) => {
      if (!value || value === '::/0' || validIp6(value)) return;
      return gettext('Invalid IPv6 address.');
    };
  },
});
FmkIpV6Input.displayName = 'FmkIpV6Input';
