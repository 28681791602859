import { ProLego } from '@fafm/neowise-pro';
import { useEffect, useMemo, useState } from 'react';
import { NwIcon, NwIconButton, NwButton } from '@fafm/neowise-core';
import { ErrorObj } from './types';
import { printfd } from 'kit/kit-string';

import './tooltip.less';

export function ScriptErrorTooltipWrapper({
  errors,
  hoveredLine,
  hoveredLineCoords,
  replaceLineWithSuggestedCorrection,
  tooltipOpen,
  children,
}: any) {
  const errorsForHoveredLine = useMemo(() => {
    if (hoveredLine === -1) return [];

    return errors.filter((error: ErrorObj) => {
      return error.line === hoveredLine + 1;
    });
  }, [errors, hoveredLine]);

  return (
    <ProLego.Tooltip
      placement='left'
      padding={0}
      followCursor={false}
      hideDelay={2000}
      distance={10}
      coords={hoveredLineCoords}
      open={hoveredLineCoords?.x > 0}
      content={
        <ScriptErrorTooltip
          hoveredLine={hoveredLine}
          errorsForHoveredLine={errorsForHoveredLine}
          replaceLineWithSuggestedCorrection={
            replaceLineWithSuggestedCorrection
          }
        />
      }
    >
      <div className='tw-w-full tw-h-full tw-grow'>{children}</div>
    </ProLego.Tooltip>
  );
}

function ScriptErrorTooltip(props: any) {
  const {
    errorsForHoveredLine,
    hoveredLine,
    replaceLineWithSuggestedCorrection,
  } = props;

  const [errorIndex, setErrorIndex] = useState(0);

  useEffect(() => {
    setErrorIndex(0);
  }, [errorsForHoveredLine]);

  if (!errorsForHoveredLine.length) return null;

  return (
    <div
      className='tw-flex tw-flex-col tw-overflow-hidden'
      style={{ width: '450px' }}
    >
      {/* Error header */}
      <ScriptErrorTooltipHeader
        lineNumber={hoveredLine + 1}
        numErrors={errorsForHoveredLine.length}
        errorIndex={errorIndex}
        setErrorIndex={setErrorIndex}
      />
      <ScriptErrorBody
        errors={errorsForHoveredLine}
        errorIndex={errorIndex}
        replaceLineWithSuggestedCorrection={replaceLineWithSuggestedCorrection}
      />
    </div>
  );
}

function ScriptErrorTooltipHeader({
  lineNumber,
  numErrors,
  errorIndex,
  setErrorIndex,
}: {
  lineNumber: number;
  numErrors: number;
  errorIndex: number;
  setErrorIndex: React.Dispatch<React.SetStateAction<number>>;
}) {
  const buttons = () => {
    return numErrors > 1 ? (
      <>
        <NwIconButton
          library='fa-solid'
          name='arrow-alt-circle-left'
          title={gettext('Previous Error')}
          onClick={() => {
            setErrorIndex((prev) =>
              prev === 0 ? numErrors - 1 : (prev - 1) % numErrors
            );
          }}
        />
        <NwIconButton
          library='fa-solid'
          name='arrow-alt-circle-right'
          title={gettext('Next Error')}
          onClick={() => {
            setErrorIndex((prev) => (prev + 1) % numErrors);
          }}
        />
      </>
    ) : (
      <></>
    );
  };

  return (
    <div className='tw-w-full tw-py-2 tw-flex tw-justify-between tw-bg-neutral-100'>
      <div className='tw-flex tw-items-center tw-pl-2'>
        <NwIcon name='no' className='color-red tw-pr-1'></NwIcon>
        <span>
          {printfd(
            ngettext(
              'Error on line %(lineNumber)s',
              'Errors on line %(lineNumber)s',
              numErrors
            ),
            { lineNumber }
          )}
        </span>
      </div>
      <div className='tw-flex tw-items-center tw-pr-2'>
        <div>
          {errorIndex + 1} <b>of</b> {numErrors ?? 1}
        </div>
        {buttons()}
      </div>
    </div>
  );
}

function ScriptErrorBody({
  errors,
  errorIndex,
  replaceLineWithSuggestedCorrection,
}: {
  errors: ErrorObj[];
  errorIndex: number;
  replaceLineWithSuggestedCorrection: () => any;
}) {
  const error = errors[errorIndex];
  if (!error) return <></>;

  const errorDiv = (content?: string) => {
    if (!content) return <></>;

    return (
      <div className='tw-px-3'>
        <b>{content}</b>
      </div>
    );
  };

  const errorExtra = (prefix: string, content?: string) => {
    if (!content) return <></>;

    return (
      <div className='tw-px-3'>
        <b>{prefix}: </b>
        {content}
      </div>
    );
  };
  return (
    <div
      className='tw-flex tw-flex-col tw-w-full tw-gap-1 tw-py-3'
      id='script_codemirror_tooltip_body_text'
      style={{
        fontSize: '0.95rem',
        lineHeight: '1.3',
      }}
    >
      {error.message && errorDiv(error.message)}
      {!error.message &&
        (error.error || error.err) &&
        errorDiv(error.error || error.err)}
      {(error.optMsg ||
        error.helpMsg ||
        error.typeMsg ||
        error.refMsg ||
        error.correctionDisplayText) && (
        <hr className='tw-w-full tw-my-0 color-border tw-px-3'></hr>
      )}
      {error.optMsg && errorExtra(gettext('Use one of'), error.optMsg)}
      {error.helpMsg && errorExtra(gettext('Help'), error.helpMsg)}
      {error.typeMsg && errorExtra(gettext('Type'), error.typeMsg)}
      {error.refMsg &&
        errorExtra(gettext('Data source reference categories'), error.refMsg)}
      {error.correctionDisplayText && (
        <>
          <div className='tw-px-3'>
            <b>{gettext('Suggested correction')}: </b>
            {`"${error.correctionDisplayText}"`}
          </div>
          <NwButton
            onClick={replaceLineWithSuggestedCorrection}
            type='primary'
            className='tw-w-fit tw-px-3'
          >
            {gettext('Use Suggested Correction')}
          </NwButton>
        </>
      )}
    </div>
  );
}
