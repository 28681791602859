import { isObject } from 'lodash';

const CLOUD_STORAGE_CATES = {
  'cloud-storage-connector': {
    id: 'cloud-storage-connector',
    url: '/config/global/system/cloud-storage-connector',
    cate: '/system/cloud-storage-connector',
    label: gettext('Cloud Storage Connector'),
    props: (cate, type) => {
      return { type: type };
    },
    advOpts: {
      inused: [
        'name',
        'description',
        'type',
        'title',
        'status',
        's3-region',
        's3-access-key-id',
        's3-secret-access-key',
        's3-provider',
        'azureblob-account',
        'azureblob-key',
        'googlecloud-location',
        'googlecloud-project-number',
        'googlecloud-service-account-credentials',
      ],
      noshow: (attr, advctrl) => {
        let _prefix = '';
        const _type = parseInt(advctrl.data.type);
        switch (_type) {
          case MACROS.FAZ_SYNTAX.CLOUD_STORAGE_TYPE_S3:
            _prefix = 's3';
            break;
          case MACROS.FAZ_SYNTAX.CLOUD_STORAGE_TYPE_AZURE_BLOB:
            _prefix = 'azureblob';
            break;
          case MACROS.FAZ_SYNTAX.CLOUD_STORAGE_TYPE_GOOGLE_CLOUD:
            _prefix = 'googlecloud';
            break;
          default:
            break;
        }
        return !attr.startsWith(_prefix);
      },
    },
  },
  'cloud-storage-setting': {
    id: 'cloud-storage-setting',
    url: '/config/global/system/cloud-storage-setting',
    cate: '/system/cloud-storage-setting',
    label: gettext('Cloud Storage'),
    advOpts: {
      inused: [
        'connector',
        'description',
        'name',
        'remote-path',
        'schedule',
        'sync-opt',
      ],
    },
  },
};

export const getCloudStorageCate = (cate) => {
  if (isObject(cate)) return cate;
  else return CLOUD_STORAGE_CATES[cate];
};
