import { forEach, isNumber, isUndefined } from 'lodash';
import { fiSmartTableHelper } from './smart_table';

export const dvmSmartTableHelper = {
  /**
   * Decides if the given row is a VDOM or any other child type
   * @param {object} row - the row entry
   * @return {boolean} true if the row is a VDOM or other child type
   */
  isChild: function (row) {
    return row._level >= 2;
  },

  /**
   * Checks if the given row has children
   * @param {Object} row - the row to be checked
   * @return {Boolean} true if this row has children
   */
  hasChildren: function (row) {
    const record = row._oData || row;
    // has vdoms or members
    return record.vdoms?.length || record.members;
  },

  /**
   * Determines if two rows are the same row
   * @param {Object} row1 - a row to be compared
   * @param {Object} row2 - a row to be compared with
   * @return {Boolean} true if two rows have the same content
   *   (when equality is set to true) OR
   *   true if two rows are the same device
   */
  isSameRow: function (row1, row2) {
    return fiSmartTableHelper.isSameRow(row1, row2, [
      '_oData.did',
      '_oData.vid',
    ]);
  },

  /**
   * Finds and gets the index of the given row from all rows
   * @param {Object} row - the row needs to be searched
   * @param {Array} allRows - the array of all rows
   * @return {Integer} the index of the given row in the array.
   *   -1 if the row is not found in the rows
   */
  indexOfRow: function (row, allRows) {
    return fiSmartTableHelper.indexOfRow(row, allRows, [
      '_oData.did',
      '_oData.vid',
    ]);
  },

  /**
   * Gets all the children of the given parent from the original array
   * @param {Object|Integer} parent - the parent in the array,
   *   could be an index of the parent
   * @param {array} allRows - the array that the parent is in
   * @param {string} pkey - the parent key, used for subchildren
   * @return {array} a new array of all children of the given parent
   */
  getChildren: function (parent, allRows, pkey) {
    const result = [];
    let index_parent = -1;
    if (isNumber(parent)) {
      index_parent = parent;
    } else {
      index_parent = this.indexOfRow(parent, allRows);
    }
    for (let i = index_parent + 1, len = allRows.length; i < len; i++) {
      const row = allRows[i];
      if (!this.isChild(row)) {
        // leaves the parent scope
        return result;
      }
      if (pkey && pkey !== row._oData._pkey) {
        return result;
      }
      result.push(row);
    }
    return result;
  },

  /**
   * Gets the parent row by the given child
   * @param {Integer|Object} child - the child. Coule be an index or a row
   * @param {Array} allRows - all rows
   * @return {Object} the parent's row
   */
  getParent: function (child, allRows) {
    return allRows[this.indexOfParent(child, allRows)];
  },

  /**
   * Gets siblings of the given child
   * @param {Object} child - the child needs to search for its siblings
   * @param {Array} allRows - an array of all rows
   * @return {Array} an array of all this child's siblings
   */
  getSiblings: function (child, allRows) {
    const that = this;
    const siblings = [];
    const parentIndex = that.indexOfParent(child, allRows);
    if (parentIndex !== -1) {
      const children = that.getChildren(parentIndex, allRows);
      forEach(children, function (c) {
        if (!that.isSameRow(c, child)) {
          siblings.push(c);
        }
      });
    }
    return siblings;
  },

  /**
   * Gets the index of the given child's parent
   * @param {Integer|Object} child - the child. Coule be an index or a row
   * @param {Array} allRows - all rows
   * @return {Integer} the index of the child's parent.
   *   -1 if the parent can not be found
   */
  indexOfParent: function (child, allRows) {
    let childIndex = -1;
    if (isNumber(child)) {
      childIndex = child;
    } else {
      childIndex = this.indexOfRow(child, allRows);
    }
    for (let i = childIndex - 1; i >= 0; i--) {
      if (this.hasChildren(allRows[i])) {
        return i;
      }
    }
    return -1;
  },

  /**
   * @deprecated For fist-table compatibility, please use getRelatedRows instead.
   * Selects or de-selects rows relating to the given row:
   * If a parent is given, selects/de-selects all its children
   * If a child is given, selects its parent OR
   * de-selects its parent if none of its siblings are selected
   * If the given row is neither parent or child, selects/de-selects itself
   * @param {Object} selectedRow - a row which related rows
   *   will be selected / de-selected
   *   If the row is selected, select its related rows
   *   If the row is de-selected, de-select its related rows
   * @param {Array} allRows - an array of all rows
   * @return {Array} an array of all rows selected during this run
   */
  selectRelatedRows: function (selectedRow, allRows, reverse) {
    reverse = reverse || false;
    if (
      (!reverse && !selectedRow.isSelected) ||
      (reverse && selectedRow.isSelected)
    ) {
      // de-select related rows if the row is de-selected
      return this.deSelectRelatedRows(selectedRow, allRows);
    }
    const selectedRows = [];
    const originalIndex = this.indexOfRow(selectedRow, allRows);
    allRows[originalIndex].isSelected = !reverse; // select itself
    selectedRows.push(allRows[originalIndex]);
    if (!this.hasChildren(selectedRow)) {
      if (this.isChild(selectedRow)) {
        // a vdom, select its parent as well
        const parent = this.getParent(originalIndex, allRows);
        if (parent) {
          parent.isSelected = !reverse;
          selectedRows.push(parent);
        }
      }
    } else {
      // it's a parent, select all its children
      const children = this.getChildren(originalIndex, allRows);
      forEach(children, function (child) {
        child.isSelected = !reverse;
        selectedRows.push(child);
      });
    }
    return selectedRows;
  },

  getRelatedEntries: function (entry, selectedEntriesSet, allEntries) {
    const that = this;
    let result = [entry];
    const isSelfSelected = selectedEntriesSet.has(entry);
    if (!that.isChild(entry)) {
      // The entry is a device
      // Gets all its children (VDOMs) whether it's selected or un-selected
      result = result.concat(that.getChildren(entry, allEntries));
    } else {
      // This entry is a VDOM
      if (isSelfSelected) {
        // If it's selected, get its parent as well
        result = result.concat(that.getParent(entry, allEntries));
      } else {
        // If it's un-selected, get its parent IF all its siblings are not selected
        const siblings = that.getSiblings(entry, allEntries);
        for (let i = 0, l = siblings.length; i < l; i++) {
          if (selectedEntriesSet.has(siblings[i])) {
            return [entry];
          }
        }
        result.push(that.getParent(entry, allEntries));
      }
    }
    return result;
  },

  /**
   * De-selects rows relating to the given row:
   * If a parent is given, de-selects all its children
   * If a child is given, de-selects itself and its parent if
   * none of its siblings are selected
   * If the given row is neither parent or child, de-selects itself
   * @param {Object} selectedRow - a row which related rows
   *   will be de-selected
   * @param {Array} allRows - an array of all rows
   * @return {Array} an array of all rows de-selected during this run
   */
  deSelectRelatedRows: function (selectedRow, allRows) {
    const shouldSelect = selectedRow.isSelected || false;
    const deSelectedRows = [];
    const originalIndex = this.indexOfRow(selectedRow, allRows);
    allRows[originalIndex].isSelected = shouldSelect; // de-select itself
    deSelectedRows.push(allRows[originalIndex]);
    if (this.hasChildren(selectedRow)) {
      // parent. De-select all its children as well
      const children = this.getChildren(originalIndex, allRows);
      forEach(children, function (child) {
        child.isSelected = shouldSelect;
        deSelectedRows.push(child);
      });
    } else {
      if (this.isChild(selectedRow)) {
        // vdom. De-select its parent if none of its siblings are selected
        const parent = this.getParent(originalIndex, allRows);
        if (parent) {
          const siblings = this.getChildren(parent, allRows);
          for (let i = 0, l = siblings.length; i < l; i++) {
            if (siblings[i].isSelected !== shouldSelect) {
              return deSelectedRows;
            }
          }
          parent.isSelected = shouldSelect;
          deSelectedRows.push(parent);
        }
      }
    }
    return deSelectedRows;
  },

  /**
   * Selects related rows for each given row
   * @param {Array} allRows - an array of all rows
   * @param {Array} selectedRows - an array of rows which
   *   related rows need to be selected
   *   It is all selected rows in allRows by default
   * @return {Array} all rows that are selected in this run
   */
  setRelatedSelections: function (allRows, selectedRows) {
    const that = this;
    let allSelectedRows = [];
    let selectedDevices = {};
    selectedRows = !isUndefined(selectedRows) ? selectedRows : allRows;
    forEach(selectedRows, function (row) {
      if (row.isSelected) {
        const did = row._oData.did;
        if (!selectedDevices[did]) {
          if (selectedDevices[did] === false) {
            if (that.isChild(row)) {
              const row_in_allRows = allRows[that.indexOfRow(row, allRows)];
              row_in_allRows.isSelected = true; // select itself
              allSelectedRows.push(row_in_allRows);
            }
          } else {
            if (that.isChild(row)) {
              selectedDevices[did] = false;
            } else {
              selectedDevices[did] = true;
            }
            allSelectedRows = allSelectedRows.concat(
              that.selectRelatedRows(row, allRows)
            );
          }
        }
      }
    });
    return allSelectedRows;
  },

  /**
   * Gets all the selected rows from the table
   * @param {Array} allRows - all rows from the table
   * @param {Boolean} toCopy - true if the row in the result is a copy
   *   from the original row. it is true by default
   * @param {Boolean} isDefaultSelected - Should the row
   *   still be selected after being fetched
   *   True by default
   *   Only works when toCopy is set to true
   * @return {Array} the array of all selected rows
   */
  getSelectedTableRows: function (allRows, toCopy, isDefaultSelected) {
    return fiSmartTableHelper.getSelectedTableRows(
      allRows,
      toCopy,
      isDefaultSelected
    );
  },

  /**
   * Sets selections to the table
   * @param {Array} allRows - all rows from the table
   * @param {Array} rowsToSelect - rows need to be selected
   *   could be an array of row OR
   *   array of row index
   * @param {Boolean} isToDeSelect - to select or de-select those rows.
   *   True to de-select
   */
  setSelectionsToTable: function (allRows, rowsToSelect, isToDeSelect) {
    return fiSmartTableHelper.setSelectionsToTable(
      allRows,
      rowsToSelect,
      isToDeSelect,
      ['_oData.did', '_oData.vid']
    );
  },

  /**
   * Selects all table rows
   * @param {Array} allRows - all rows from the table
   * @param {Boolean} toSelect - if to select or de-select a row.
   *   True by default
   */
  selectAll: function (allRows, toSelect) {
    fiSmartTableHelper.selectAll(allRows, toSelect);
  },
};
