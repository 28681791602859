import React from 'react';
import { redirect, defer } from 'react-router-dom';
import { dispatch } from 'fistore';
import { fetchLoginEnv } from 'fistore/auth/slice';
import { FabricApprovalLogin } from 'react_apps/ra-auth/FabricApprovalLogin';
import { checkAuthLoader } from 'react_apps/ra-auth/loader';

const addOpenerId = (route, requestUrl) => {
  const url = new URL(requestUrl);
  const openerId = url.searchParams.get('opener_id') || '';
  return `${route}?opener_id=${openerId}`;
};

const loginRouteLoader =
  (route) =>
  async ({ request }) => {
    const redirectUrl = addOpenerId(route, request.url);
    if (await checkAuthLoader()) {
      return redirect(redirectUrl);
    }
    return defer({
      loginEnv: dispatch(fetchLoginEnv()).unwrap(),
    });
  };

const nonLoginRouteLoader =
  (route) =>
  async ({ request }) => {
    const redirectUrl = addOpenerId(route, request.url);
    if (!(await checkAuthLoader())) {
      return redirect(redirectUrl);
    }
    return null;
  };

export default [
  {
    path: 'faz-fabric-authorization',
    loader: loginRouteLoader('/faz-fabric-approval'),
    element: <FabricApprovalLogin route='/faz-fabric-approval' />,
  },
  {
    path: 'faz-fabric-approval',
    loader: nonLoginRouteLoader('/faz-fabric-authorization'),
    lazy: async () => {
      const { FazFabricApproval } = await import(
        'fabric_approval/faz_fabric/FazFabricApprovalRoot'
      );
      return {
        Component: FazFabricApproval,
      };
    },
  },
  {
    path: 'fabric-authorization',
    loader: loginRouteLoader('/fabric-approval'),
    element: <FabricApprovalLogin route='/fabric-approval' />,
  },
  {
    path: 'fabric-approval',
    loader: nonLoginRouteLoader('/fabric-authorization'),
    lazy: async () => {
      const { FabricApproval } = await import('fabric_approval/FabricApproval');
      return {
        Component: FabricApproval,
      };
    },
  },
];
