import React, { useEffect, useState } from 'react';
import { ProLego } from '@fafm/neowise-pro';
const { SearchTree } = ProLego;

export const JsonVTree = ({ data: jsonData }) => {
  const [data, setData] = useState(null);
  const parseToTree = (jsonData) => {
    var tree = [];
    var prop, val, node;
    for (prop in jsonData) {
      if (jsonData.hasOwnProperty(prop)) {
        val = jsonData[prop];
        node = {
          excluded: true,
        };
        if (typeof val === 'object' && val) {
          node.text = Array.isArray(val)
            ? '[' + val.length + ']'
            : '{' + Object.keys(val).length + '}';
          node.text = `${prop} : ${node.text}`;
          node.id = node.text;
          node.children = parseToTree(val);
        } else {
          node.text = `${prop} : ${val}`;
          node.id = node.text;
        }
        tree.push(node);
      }
    }
    return tree;
  };

  useEffect(() => {
    if (jsonData) {
      setData(parseToTree(jsonData));
    }
  }, [jsonData]);

  return data ? <SearchTree source={() => data} /> : null;
};
