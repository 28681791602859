import { createSelector } from '@reduxjs/toolkit';

export const getDevicesLicense = (state) => state.deviceLicenses || {};

export const getDevicesLicenseById = createSelector(
  getDevicesLicense,
  (licenses) => {
    return licenses.byId;
  }
);
