import { isPlainObject } from 'lodash';

// Inverts a object's keys and values.
export function invertMap(obj) {
  var newObj = {};
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      newObj[obj[prop]] = prop;
    }
  }
  return newObj;
}

//count object attributes
export function attrCountOfObject(obj) {
  var size = 0,
    key;
  if (obj) {
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
  }
  return size;
}

// get subset of object by attrs
export const extractAttrs = (obj, ...args) => {
  return args.reduce((ret, attr) => {
    if (obj[attr] !== undefined) {
      ret[attr] = obj[attr];
    }
    return ret;
  }, {});
};

export function getValueByPath(obj, path) {
  if (!obj) return;

  const tokens = _tokenize(path);

  for (let attr of tokens) {
    obj = obj[attr];

    if (obj == null) return null;
  }

  return obj;
}

export function setValueByPath(obj, path, value) {
  const tokens = _tokenize(path);

  while (tokens.length > 1) {
    const attr = tokens.shift();
    obj = obj[attr] || (obj[attr] = {});
  }

  const attr = tokens[0];

  if (attr) {
    obj[attr] = value;
    return true;
  }

  return false;
}

function _tokenize(path) {
  if (Array.isArray(path)) return path;
  else if (typeof path === 'string') return path.split('.');
  return [];
}

// Merges two objects and all nested objects recursively
export const recursiveObjectMerge = (mainObject, subObject) => {
  const mergedObject = {
    ...mainObject,
  };
  for (const [key, value] of Object.entries(subObject)) {
    if (!mergedObject[key]) mergedObject[key] = value;
    else if (isPlainObject(mergedObject[key]) && isPlainObject(value)) {
      mergedObject[key] = recursiveObjectMerge(mergedObject[key], value);
    }
  }

  return mergedObject;
};

export const flattenObject = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

export const addToObject = (object, key, val) => {
  if (isPlainObject(object)) {
    if (Reflect.has(object, key)) {
      const currVal = object[key];
      if (Array.isArray(currVal)) {
        currVal.push(val);
      }
    } else {
      object[key] = [val];
    }
  }
};
