import { logfetchTransHelper } from './logfetchTransHelper';
import { merge } from 'lodash';

export const logfetchSessionState = (function () {
  var STATES = {};

  var SERVICE_ID = 'rcvd',
    CLIENT_ID = 'sent';
  var statusFn = {},
    actionFn = {};
  statusFn[SERVICE_ID] = serviceState;
  statusFn[CLIENT_ID] = clientState;

  actionFn[SERVICE_ID] = serviceAction;
  actionFn[CLIENT_ID] = clientAction;
  function _serviceBtnCfg(action) {
    var ret;
    switch (action) {
      case 'review':
        ret = {
          cls: 'btn-primary',
          act: 'review',
          txt: logfetchTransHelper.review,
          type: 'primary',
        };
        break;
      case 'pause':
        ret = {
          cls: 'btn-warning',
          act: 'servicePause',
          txt: logfetchTransHelper.pause,
          type: 'neutral',
        };
        break;
      case 'resume':
        ret = {
          cls: 'btn-primary',
          act: 'serviceResume',
          txt: logfetchTransHelper.resume,
          type: 'neutral',
        };
        break;
      case 'cancel':
        ret = {
          cls: 'btn-cancel',
          act: 'serviceCancel',
          txt: logfetchTransHelper.cancel,
          type: 'danger',
        };
        break;
    }
    return ret;
  }

  function _clientBtnCfg(action) {
    var ret;
    switch (action) {
      case 'pause':
        ret = {
          cls: 'btn-warning',
          act: 'clientPause',
          txt: logfetchTransHelper.pause,
          type: 'neutral',
        };
        break;
      case 'resume':
        ret = {
          cls: 'btn-primary',
          act: 'clientResume',
          txt: logfetchTransHelper.resume,
          type: 'neutral',
        };
        break;
      case 'cancel':
        ret = {
          cls: 'btn-cancel',
          act: 'clientCancel',
          txt: logfetchTransHelper.cancel,
          type: 'danger',
        };
        break;
      case 'delete': // same as cancel except text
        ret = {
          cls: 'btn-cancel',
          act: 'clientCancel',
          txt: logfetchTransHelper.delete,
          type: 'danger',
        };
        break;
    }
    return ret;
  }

  function serviceState(col, row) {
    var ret,
      state = row[col.field];

    if (
      state >= STATES.LOG_FETCH_SESSION_ST_LOGIN &&
      state <= STATES.LOG_FETCH_SESSION_ST_STOPPING
    ) {
      ret = { percent: row['comp-percent'] };
    }

    if (state === STATES.LOG_FETCH_SESSION_ST_NEW) {
      ret = { icon: { name: 'support' }, msg: logfetchTransHelper.waitAppr };
    } else if (state === STATES.LOG_FETCH_SESSION_ST_APPROVED) {
      ret = { icon: { name: 'validate-all' }, msg: logfetchTransHelper.appr };
    } else if (state === STATES.LOG_FETCH_SESSION_ST_STOPPED) {
      ret = {
        icon: { name: 'pause', library: 'fa-solid' },
        msg: logfetchTransHelper.paused,
      };
    } else if (state === STATES.LOG_FETCH_SESSION_ST_DENIED) {
      ret = { icon: { name: 'crl' }, msg: logfetchTransHelper.rejected };
    }

    return ret;
  }

  function clientState(col, row) {
    var ret,
      state = row[col.field];

    if (
      state > STATES.LOG_FETCH_SESSION_ST_LOGIN &&
      state <= STATES.LOG_FETCH_SESSION_ST_STOPPING
    ) {
      ret = { percent: row['comp-percent'] };
    }

    if (
      state === STATES.LOG_FETCH_SESSION_ST_NEW ||
      state === STATES.LOG_FETCH_SESSION_ST_LOGIN
    ) {
      ret = { icon: { name: 'support' }, msg: gettext('Waiting for approval') };
    } else if (state === STATES.LOG_FETCH_SESSION_ST_STOPPED) {
      ret = {
        icon: { name: 'pause', library: 'fa-solid' },
        msg: gettext('Paused'),
      };
    } else if (state === STATES.LOG_FETCH_SESSION_ST_DENIED) {
      ret = { msg: gettext('Rejected'), icon: { name: 'crl' } };
    } else if (state === STATES.LOG_FETCH_SESSION_ST_DONE) {
      ret = {
        msg: gettext('Done'),
        icon: { name: 'thumbs-up', library: 'fa-solid' },
      };
    }

    return ret;
  }

  function init(SESSION_STATE) {
    STATES = merge(STATES, SESSION_STATE);
  }

  function _isDownloading(state) {
    return (
      (STATES.LOG_FETCH_SESSION_ST_LOGIN <= state) &
      (state <= STATES.LOG_FETCH_SESSION_ST_DOWNLOADING)
    );
  }

  function serviceAction(col, row) {
    var btns = [],
      state = row[col.field];
    if (state === STATES.LOG_FETCH_SESSION_ST_NEW) {
      btns.push('review');
    } else if (_isDownloading(state)) {
      btns.push('pause', 'cancel');
    } else if (state === STATES.LOG_FETCH_SESSION_ST_STOPPING) {
      btns.push('cancel');
    } else if (state === STATES.LOG_FETCH_SESSION_ST_STOPPED) {
      btns.push('resume', 'cancel');
    } else if (state === STATES.LOG_FETCH_SESSION_ST_DENIED) {
      btns.push('cancel');
    }
    return [_serviceBtnCfg, btns];
  }

  function clientAction(col, row) {
    var btns,
      state = row[col.field];
    if (
      state === STATES.LOG_FETCH_SESSION_ST_DONE ||
      state === STATES.LOG_FETCH_SESSION_ST_DENIED
    ) {
      btns = ['delete'];
    } else {
      btns = ['cancel'];
      if (_isDownloading(state)) {
        btns.splice(0, 0, 'pause');
      } else if (state === STATES.LOG_FETCH_SESSION_ST_STOPPED) {
        btns.splice(0, 0, 'resume');
      }
    }

    return [_clientBtnCfg, btns];
  }

  return {
    init: init,
    statusFn: statusFn,
    actionFn: actionFn,
    SESSION_STATE: STATES,
    SERVICE_ID: SERVICE_ID,
    CLIENT_ID: CLIENT_ID,
    isLocal: function (sid) {
      return sid === CLIENT_ID;
    },
    localSessionRunning: function (stateCode) {
      return (
        STATES.LOG_FETCH_SESSION_ST_LOGIN <= stateCode &&
        stateCode <= STATES.LOG_FETCH_SESSION_ST_STOPPING
      );
    },
  };
})();
