import { fiFmgHttp } from 'fi-http';
import { fiWebSocket } from 'fi-websocket';
import { fiDvmRevisionContent } from '../constants';
import { isUndefined } from 'lodash';
import $ from 'jquery';

let _fdl_status = {};

function getLoadContentStatus() {
  return _fdl_status;
}

function sendReqToWebstock(wsReq) {
  let data = [];
  let defer = $.Deferred();

  fiWebSocket.send(fiWebSocket.genWSRequest(wsReq)).then(
    function () {
      _fdl_status.total = Array.isArray(data) ? data.length : 0;
      defer.resolve(data);
    },
    function (err) {
      defer.reject(err);
    },
    function ({ chunk }) {
      if (chunk) {
        if (chunk.header) {
          // update predicate
          _fdl_status.total = chunk.header.total || 0;
        } else if (chunk.tail) {
          // update real total
          _fdl_status.total = _fdl_status.had = data.length;
          defer.notify(chunk);
        } else {
          // From Jin Fang: some models can have data in `chunk` instead of `chunk.data`
          let chunkData = chunk.data || chunk || [];
          // update status
          _fdl_status.had = data.length;
          // notify chunk
          data = data.concat(chunkData);
          defer.notify(chunkData);
        }
      }
    }
  );
  return defer.promise();
}
/**
 * Get device revision history list
 * @param
 * @return {Promise}
 */
let getRevisionHistoryById = function (device_id) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/history',
    method: 'get',
    params: {
      deviceId: device_id,
    },
  };

  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0]?.data ? resp[0].data : [];
  });
};

let getDevRevisionContent = function (adom_oid, device_name, revision_num) {
  // /gui/adoms/:adomOid/device/:deviceName/config/revisions/:revisionId/content
  let wsReq = {
    url: 'gui/adoms/%s/device/%s/config/revisions/%s/content'.printf([
      adom_oid,
      device_name,
      revision_num,
    ]),
    method: 'get',
  };
  return sendReqToWebstock(wsReq);
};

let getInstallLog = function (device_id, revision_num) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/content',
    method: 'get',
    params: {
      deviceId: device_id,
      rev: revision_num,
      options: fiDvmRevisionContent.INSTALL_LOG,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

let changeRevision = function (device_id, user, adomid, rev, tag, comment) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/content',
    method: 'set',
    params: {
      deviceId: device_id,
      rev: rev,
      options: fiDvmRevisionContent.CONFIGURATION,
      user: user,
      adomId: adomid,
    },
  };

  if (!isUndefined(tag)) {
    reqObj.params.tag = tag;
  }
  if (!isUndefined(comment)) {
    reqObj.params.comment = comment;
  }
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

let deleteRevision = function (device_id, revision_num, user) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/history',
    method: 'remove',
    params: {
      deviceId: device_id,
      rev: revision_num,
      user: user,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

let revertRevision = function (device_id, revision_num, user) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/content',
    method: 'revert',
    params: {
      deviceId: device_id,
      rev: revision_num,
      user: user,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

let retrieveRevision = function (device_id, adom_id) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/history',
    method: 'retrieve',
    params: {
      deviceId: device_id,
      adomId: adom_id,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

let getRetrieveTaskDetail = function (taskid) {
  let reqObj = {
    method: 'get',
    params: [
      {
        url: 'task/task/' + taskid + '/history',
      },
    ],
  };

  return fiFmgHttp.forward(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

let uploadRevision = function (device_id, user_name, file_content, filepass) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/history',
    method: 'add',
    params: {
      deviceId: device_id,
      user: user_name,
      content: file_content,
      fileSize: file_content.length,
      fileEnd: 0,
    },
  };

  if (!isUndefined(filepass)) {
    reqObj.params.password = filepass;
  }

  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

// downloadRevisionByForm' get file from server , file name return, then download file from server
// this download make sure the downloaded file is exactly the same as the file on server. used for
// device config revision only.
let downloadRevisionByForm = function (
  device,
  rev,
  content_type,
  user,
  password
) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/content',
    method: 'get_download',
    params: {
      deviceId: device.oid,
      deviceName: device.name,
      rev: rev,
      sn: device.sn,
      options: content_type,
      user: user,
      password: password,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    // we need to use post request here instead of download
    return resp ? resp[0].data : null;
  });
};

// downloadFileContent's response, file content returns in response directly.
let downloadFileContent = function (device, rev, content_type, user, password) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/content',
    method: 'get_download',
    params: {
      deviceId: device.oid,
      deviceName: device.name,
      rev: rev,
      sn: device.sn,
      options: content_type,
      user: user,
      password: password,
    },
  };
  return fiFmgHttp
    .download('/cgi-bin/module/flatui_proxy', reqObj)
    .then(function (resp) {
      return resp;
    });
};

let diffRevision = function (deviceId, revision) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/diff',
    method: 'get',
    params: {
      deviceId: deviceId,
      from: revision.version1,
      to: revision.version2,
      options: revision.op,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

let installRevision = function (device_id, adom_id, revision_num, user) {
  let reqObj = {
    url: '/gui/adom/dvm/device/revision/history',
    method: 'install',
    params: {
      deviceId: device_id,
      adomId: adom_id,
      rev: revision_num,
      user: user,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
};

/**
 * Get device config DB content
 * @param adom oid, device name
 * @return config data through web stock
 */
let getConfigDBContent = function (adom_oid, device_oid) {
  let wsReq = {
    url: '/gui/adoms/%s/device/%s/config/db'.printf([adom_oid, device_oid]),
    method: 'get',
  };
  return sendReqToWebstock(wsReq);
};

export const fiDvmRevisionRequests = {
  getRevisionHistoryById: getRevisionHistoryById,
  getDevRevisionContent: getDevRevisionContent,
  getConfigDBContent: getConfigDBContent,
  getInstallLog: getInstallLog,
  changeRevision: changeRevision,
  deleteRevision: deleteRevision,
  revertRevision: revertRevision,
  retrieveRevision: retrieveRevision,
  getRetrieveTaskDetail: getRetrieveTaskDetail,
  uploadRevision: uploadRevision,
  downloadRevisionByForm: downloadRevisionByForm,
  diffRevision: diffRevision,
  installRevision: installRevision,
  getLoadContentStatus: getLoadContentStatus,
  downloadFileContent: downloadFileContent,
};
