import { createReducer } from '@reduxjs/toolkit';

import { devicesPsirtAction } from './actions';

const initialState = {
  loaded: false,
  loading: false,
  byPlatform: {},
  byIrNumber: {},
  numDevicesPerRisk: {},
};

export const devicesPsirtReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(devicesPsirtAction.fetch.START, (state) => {
      state.loaded = false;
      state.loading = true;
    })
    .addCase(devicesPsirtAction.fetch.SUCCESS, (state, action) => {
      state.loaded = true;
      state.loading = false;
      const byPlatform = orderByRisk(
        action.payload.byPlatform,
        action.payload.byIrNumber
      );
      state.byPlatform = byPlatform || {};
      state.byIrNumber = action.payload.byIrNumber || {};
      state.numDevicesPerRisk = action.payload.numDevicesPerRisk;
    });
});

function orderByRisk(byPlatform = {}, byIrNumber = {}) {
  const irNums = Object.values(byPlatform || {});
  irNums.forEach((irNumArray) => {
    irNumArray.sort((a, b) => {
      const risk_a = byIrNumber[a].risk;
      const risk_b = byIrNumber[b].risk;
      return risk_b - risk_a;
    });
  });

  return byPlatform;
}
