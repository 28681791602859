import { castArray, negate, get, overSome, mapValues, pick } from 'lodash';
import {
  checkVersion,
  inFpxAdom,
  inFFWAdom,
  inFWCAdom,
  inGlobalAdom,
} from 'fistore/session/adom/selectors';
import {
  isVwpInDisplayOpts,
  syntaxChecker,
  makeDisplayOptChecker,
} from '../common_checkers';
import { PTYPE_ID as ID } from './policy_type_ids';

const makeCheckers = ({
  syntax,
  version,
  global, // true if can be shown on global adom
}) => {
  return [
    ...(syntax ? [syntaxChecker(castArray(syntax))] : []),
    ...(version ? [checkVersion(...version)] : []),
    ...(global ? [] : [negate(inGlobalAdom)]),
  ];
};

/// Checkers for package
const ngfw = (_, { pkg }) =>
  get(pkg, 'package settings.ngfw-mode') ===
  MACROS.PM2CAT.PM2_OPT_NGFW_POLICY_BASED;

const centralNAT = overSome([
  ngfw,
  (_, { pkg }) => !!get(pkg, 'package settings.central-nat'),
]);
const hyperscale = (_, { pkg }) =>
  get(pkg, 'package settings.policy-offload-level') ===
  MACROS.PM2CAT.PM2_OFFLOAD_LEVEL_FULL_OFFLOAD;
const pblock = (_, { pkg }) => pkg.pblock;

const checkerDefs = {
  [ID.HDR_FW640]: {
    mustShow: true,
    defaultShow: true,
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({
        syntax: 'global header policy',
        global: true,
      }),
      checkVersion('6.4', '>='),
      checkVersion('7.6', '<'),
    ],
  },
  [ID.FTR_FW640]: {
    mustShow: true,
    defaultShow: true,
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({
        syntax: 'global footer policy',
        global: true,
      }),
      checkVersion('6.4', '>='),
      checkVersion('7.6', '<'),
    ],
  },
  [ID.HDR_FW760]: {
    mustShow: true,
    defaultShow: true,
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({
        syntax: 'global header policy',
        version: ['7.6', '>='],
        global: true,
      }),
    ],
  },
  [ID.FTR_FW760]: {
    mustShow: true,
    defaultShow: true,
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({
        syntax: 'global footer policy',
        version: ['7.6', '>='],
        global: true,
      }),
    ],
  },
  [ID.FPX_FW]: {
    mustShow: true,
    defaultShow: true,
    checkers: [inFpxAdom, ...makeCheckers({ syntax: 'firewall policy' })],
  },
  [ID.HDR_SHAPING]: {
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({ syntax: 'global header shaping-policy', global: true }),
    ],
    checkWithPkg: [negate(pblock)],
  },
  [ID.FTR_SHAPING]: {
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({ syntax: 'global footer shaping-policy', global: true }),
    ],
    checkWithPkg: [negate(pblock)],
  },
  [ID.FW640]: {
    mustShow: true,
    defaultShow: true,
    checkers: [
      negate(inFpxAdom),
      syntaxChecker(['firewall policy']),
      checkVersion('6.4', '>='),
      checkVersion('7.6', '<'),
      negate(inGlobalAdom),
    ],
    checkWithPkg: [overSome([checkVersion('7.0', '>='), negate(hyperscale)])],
  },
  [ID.FW760]: {
    mustShow: true,
    defaultShow: true,
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({
        syntax: 'firewall policy',
        version: ['7.6', '>='],
      }),
    ],
  },
  [ID.FWS]: {
    mustShow: true,
    defaultShow: true,
    checkers: [
      ...makeCheckers({ syntax: 'firewall security-policy' }),
      negate(inFFWAdom),
      negate(inFWCAdom),
    ],
    checkWithPkg: [ngfw, negate(hyperscale)],
  },
  [ID.NAT46]: {
    checkers: makeCheckers({
      syntax: 'firewall policy46',
      version: ['7.0', '<'],
    }),
    checkWithPkg: [negate(hyperscale), negate(pblock)],
  },
  [ID.NAT64]: {
    checkers: makeCheckers({
      syntax: 'firewall policy64',
      version: ['7.0', '<'],
    }),
    checkWithPkg: [negate(hyperscale), negate(pblock)],
  },
  [ID.HP640]: {
    mustShow: true,
    defaultShow: true,
    checkers: makeCheckers({
      syntax: 'firewall hyperscale-policy',
      version: ['6.4', '>='],
    }),
    checkWithPkg: [hyperscale],
  },
  [ID.HP]: {
    mustShow: true,
    defaultShow: true,
    checkers: makeCheckers({
      syntax: 'firewall hyperscale-policy',
      version: ['6.2', '<='],
    }),
    checkWithPkg: [hyperscale],
  },
  [ID.HP6]: {
    checkers: makeCheckers({
      syntax: 'firewall hyperscale-policy6',
      version: ['6.2', '<='],
    }),
    checkWithPkg: [hyperscale],
  },
  [ID.HP46]: {
    checkers: makeCheckers({ syntax: 'firewall hyperscale-policy46' }),
    checkWithPkg: [hyperscale, negate(pblock)],
  },
  [ID.HP64]: {
    checkers: makeCheckers({ syntax: 'firewall hyperscale-policy64' }),
    checkWithPkg: [hyperscale, negate(pblock)],
  },
  [ID.ACL]: {
    checkers: makeCheckers({ syntax: 'firewall acl' }),
    checkWithPkg: [hyperscale, negate(pblock)],
  },
  [ID.ACL6]: {
    checkers: makeCheckers({ syntax: 'firewall acl6' }),
    checkWithPkg: [hyperscale, negate(pblock)],
  },
  [ID.PROXY]: {
    checkers: [
      ...makeCheckers({ syntax: 'firewall proxy-policy' }),
      negate(inFFWAdom),
      negate(inFWCAdom),
    ],
    checkWithPkg: [negate(ngfw)],
  },
  [ID.SNAT]: {
    checkers: makeCheckers({ syntax: 'firewall central-nat' }),
    checkWithPkg: [centralNAT, negate(hyperscale), negate(pblock)],
  },
  [ID.CSNAT]: {
    mustShow: true,
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({ syntax: 'firewall central-snat-map' }),
    ],
    checkWithPkg: [centralNAT, negate(hyperscale), negate(pblock)],
  },
  [ID.FPX_CSNAT]: {
    mustShow: true,
    checkers: [
      inFpxAdom,
      ...makeCheckers({ syntax: 'firewall central-snat-map' }),
    ],
    checkWithPkg: [negate(pblock)],
  },
  [ID.DNAT]: {
    mustShow: true,
    defaultShow: true,
    checkers: makeCheckers({ syntax: 'central dnat' }),
    checkWithPkg: [centralNAT, negate(hyperscale), negate(pblock)],
  },
  [ID.DNAT6]: {
    //mustShow: true,mantis: 0984796
    defaultShow: true,
    checkers: makeCheckers({ syntax: 'central dnat6', version: ['6.4', '>='] }),
    checkWithPkg: [centralNAT, negate(hyperscale), negate(pblock)],
  },
  [ID.FPX_PAC]: {
    checkers: [inFpxAdom, ...makeCheckers({ syntax: 'web-proxy pac-policy' })],
    checkWithPkg: [negate(pblock)],
  },
  [ID.DOS]: {
    checkers: makeCheckers({ syntax: 'firewall DoS-policy' }),
    checkWithPkg: [negate(pblock)],
  },
  [ID.DOS6]: {
    checkers: makeCheckers({ syntax: 'firewall DoS-policy6' }),
    checkWithPkg: [negate(pblock)],
  },
  [ID.INTF]: {
    checkers: [
      ...makeCheckers({ syntax: 'firewall interface-policy' }),
      negate(inFFWAdom),
      negate(inFWCAdom),
    ],
    checkWithPkg: [negate(pblock)],
  },
  [ID.INTF6]: {
    checkers: [
      ...makeCheckers({ syntax: 'firewall interface-policy6' }),
      negate(inFFWAdom),
      negate(inFWCAdom),
    ],
    checkWithPkg: [negate(pblock)],
  },
  [ID.MCAST]: {
    checkers: makeCheckers({ syntax: 'firewall multicast-policy' }),
    checkWithPkg: [negate(hyperscale), negate(pblock)],
  },
  [ID.MCAST6]: {
    checkers: makeCheckers({ syntax: 'firewall multicast-policy6' }),
    checkWithPkg: [negate(hyperscale), negate(pblock)],
  },
  [ID.LOCAL_IN]: {
    checkers: makeCheckers({ syntax: 'firewall local-in-policy' }),
    checkWithPkg: [negate(pblock)],
  },
  [ID.LOCAL_IN6]: {
    checkers: makeCheckers({ syntax: 'firewall local-in-policy6' }),
    checkWithPkg: [negate(pblock)],
  },
  [ID.SHAPING]: {
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({ syntax: 'firewall shaping-policy' }),
    ],
    checkWithPkg: [negate(pblock)],
  },
  [ID.FPX_SHAPING]: {
    checkers: [
      inFpxAdom,
      ...makeCheckers({ syntax: 'firewall shaping-policy' }),
    ],
    checkWithPkg: [negate(pblock)],
  },
  [ID.FW640_VWP]: {
    showIfDisplayOpts: isVwpInDisplayOpts,
    checkers: [
      negate(inFpxAdom),
      syntaxChecker(['firewall policy']),
      checkVersion('6.4', '>='),
      checkVersion('7.6', '<'),
    ],
    checkWithPkg: [overSome([checkVersion('7.0', '>='), negate(hyperscale)])],
  },
  [ID.FW760_VWP]: {
    showIfDisplayOpts: isVwpInDisplayOpts,
    checkers: [
      negate(inFpxAdom),
      ...makeCheckers({
        syntax: 'firewall policy',
        version: ['7.6', '>='],
      }),
    ],
  },
  [ID.FWS_VWP]: {
    showIfDisplayOpts: isVwpInDisplayOpts,
    checkers: [
      ...makeCheckers({ syntax: 'firewall security-policy' }),
      negate(inFFWAdom),
      negate(inFWCAdom),
    ],
    checkWithPkg: [ngfw, negate(hyperscale), negate(pblock)],
  },
  [ID.AUTH_RULE]: {
    checkers: makeCheckers({ syntax: 'authentication rule' }),
    checkWithPkg: [negate(hyperscale), negate(pblock)],
  },
  [ID.AUTH_SETTING]: {
    checkers: makeCheckers({ syntax: 'authentication setting' }),
    checkWithPkg: [negate(hyperscale), negate(pblock)],
  },
  [ID.NAC]: {
    checkers: makeCheckers({ syntax: 'user nac-policy' }),
    checkWithPkg: [negate(hyperscale), negate(pblock)],
  },
};

export const pTypeCheckers = mapValues(checkerDefs, (checkerDef, appkey) => ({
  ...pick(checkerDef, [
    'mustShow', // display option use
    'defaultShow', // display option use
  ]),
  checkers: checkerDef.checkers || [() => true],
  checkWithPkg: checkerDef.checkWithPkg || [() => true],
  displayOptChecker: makeDisplayOptChecker(appkey, checkerDef),
}));
