import { fiFmgHttp } from 'fi-http';

export function test(opts) {
  let req = {
    method: 'test',
    url: '/gui/sys/authserver',
    params: opts,
  };
  return fiFmgHttp.post(req);
}
export function getRadiusStatus(status) {
  let result = { status: status };
  if (
    status === MACROS.SYS.AUTH_TEST_UNREACHABLE ||
    status === MACROS.SYS.AUTH_TEST_INVALID_SECRET
  ) {
    result.connection = {
      css: 'ffg ffg-no color-red',
      text:
        status === MACROS.SYS.AUTH_TEST_UNREACHABLE
          ? gettext('Cannot connect to Server')
          : gettext('Incorrect Secret to the Server'),
    };
  } else if (
    status === MACROS.SYS.AUTH_TEST_AUTH_FAIL ||
    status === MACROS.SYS.AUTH_TEST_OK
  ) {
    result.connection = {
      css: 'ffg ffg-enable color-green',
      text: gettext('Successful'),
    };
    if (status === MACROS.SYS.AUTH_TEST_OK) {
      result.authentication = {
        css: 'ffg ffg-enable color-green',
        text: gettext('Successful'),
      };
    } else {
      result.authentication = {
        css: 'ffg ffg-no color-red',
        text: gettext('Invalid Credentials'),
      };
    }
  }
  return result;
}
