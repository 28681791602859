// don't define `children` in child routes
import { RenderErrorBoundary } from 'react_apps/components/main/RenderErrorBoundary';
import { AdomWatchWrapper } from 'ra-shared-components';

export default [
  {
    path: 'fmg_dashboard',
    RenderErrorBoundary,
    lazy: () =>
      import(/* webpackChunkName: "mod-dashboard" */ './GlobalDashboard'),
    handle: {
      appUniKey: 'fmg_dashboard',
    },
  },
  {
    path: 'faz_dashboard',
    RenderErrorBoundary,
    lazy: () =>
      import(/* webpackChunkName: "mod-dashboard" */ './GlobalDashboard'),
    handle: {
      appUniKey: 'faz_dashboard',
    },
  },
  {
    path: 'dashboard/soc',
    RenderErrorBoundary,
    lazy: async () => {
      const { SocOpsDashboard } = await import('./dashboards/soc');
      return { Component: SocOpsDashboard };
    },
    handle: {
      appUniKey: 'dashboard_soc',
    },
  },
  {
    path: 'dashboard/:name',
    RenderErrorBoundary,
    lazy: async () => {
      const { DashContainer } = await import('./dashboards/DashContainer');
      return {
        Component: () => {
          return (
            <AdomWatchWrapper>
              <DashContainer />
            </AdomWatchWrapper>
          );
        },
      };
    },
    handle: {
      appUniKey: (params = {}) => {
        const { name } = params;
        return `dashboard_${name}`;
      },
    },
  },
];
