import goog from '@fafm/goog';
import { getAssetGroups, OSTYPES } from './constants';
import {
  addFirmwareTemplate,
  getDeviceImages,
  setFirmwareTemplate,
} from './api';
import { FirmwareTemplateWizard } from '../firmwareWizard/FirmwareTemplateWizard';
import { getMapOfAssignedDevicesAndFirmwareTemplates } from '../firmwareWizard/util';
import { objectArrayToMap } from 'kit-array';
import { fiAdom } from 'fi-session';
import { setFwmProf } from 'fi-dvm-fwm-prof';
import { fiErrorMessage } from 'fi-messagebox';
import { ProToolkit } from '@fafm/neowise-pro';
import { getDetailErrorMsg } from 'fi-web/fi-http';
/**
 * Returns platform name (e.g. FortiAP-231E) based on device type
 * For use in firmware template
 * @param {*} dev device object
 * @param {*} osType device type
 * @returns Platform name for firmware template
 */
const getPlatformStr = (dev, osType) => {
  switch (osType) {
    case OSTYPES.FAP:
      return `${getAssetGroups()[OSTYPES.FAP].platformName}-${
        dev['_platform-name']
      }`;
    case OSTYPES.FSW:
      return dev.platform;
    case OSTYPES.FEXT:
      return 'FortiExtender-' + dev._sw_version.split('-')[0].substring(3);
    case OSTYPES.FGT:
      return dev.platform;
    default:
      return dev.platform;
  }
};

/**
 * Returns upgrade versions array for use in firmware template
 * Given one array of single type of devices
 * @param {*} devices devices to upgrade
 * @param {*} osType device type (FAP, FSW, FEXT, FGT)
 * @returns Array of upgrade version objects
 */
export const getUpgrades = async (devices = [], osType) => {
  const platformVersionMap = {};
  const devsToUpgrade = devices.filter((dev) => dev.gui_upgrade_to);

  /*
    sort suggested versions by ascending so each platform
    gets latest suggested upgrade version
    we only need latest version because firmware template
    can only set one version per platform
  */
  devsToUpgrade
    .map((dev) => {
      const platformStr = getPlatformStr(dev, osType);
      const [, version] = dev.gui_upgrade_to.split(' ');
      return { platformStr, version };
    })
    .sort((a, b) => goog.string.compareVersions(a.version, b.version))
    .forEach(({ platformStr, version }) => {
      platformVersionMap[platformStr] = version;
    });

  const upgrades = [];

  //suggestedUpgradeToVersion is the latest suggested version for the platform e.g. "7.4.10"
  for (const [platformStr, latestSuggestedVersion] of Object.entries(
    platformVersionMap
  )) {
    try {
      const images = await getDeviceImages(platformStr, osType);
      if (images.length === 0) continue;

      // 1. same version as recommended
      // 2. latest patch version (same major.minor)
      // 3. latest overall version (couldn't find same major.minor)
      // 4. return null (skip this platform)
      const getImage = () => {
        const suggestedImage = images.find(
          (image) =>
            image.version.split('-')[0] === latestSuggestedVersion &&
            image.type === 'GA'
        );
        if (suggestedImage) return suggestedImage;

        const latestPatch = images
          .sort((a, b) => {
            const ver_a = a.version.split('-')[0];
            const ver_b = b.version.split('-')[0];
            return goog.string.compareVersions(ver_b, ver_a); // sort by version descending
          })
          .filter((image) => {
            const [major, minor] = image.version.split('-')[0].split('.');
            const [suggested_major, suggested_minor] =
              latestSuggestedVersion.split('.');

            return major === suggested_major && minor === suggested_minor;
          })[0];
        if (latestPatch) return latestPatch;

        const latestImage = images[0]; //already sorted descending
        if (latestImage) return latestImage;

        return null;
      };

      let suggestedImage = getImage();
      if (!suggestedImage) continue;
      upgrades.push({
        version: suggestedImage.version,
        platform: platformStr,
        product: getAssetGroups()[osType].fwProduct,
        'upgrade-path': 0,
        flags: 0,
        __gui_auto_created: true,
      });
    } catch {
      //skip
    }
  }

  return upgrades;
};

/**
 * For getting unique firmware template name string
 * @returns name string
 */
const getNewTemplateName = () => {
  const currentDateString = new Date().toDateString();
  return 'PSIRT_' + currentDateString.replaceAll(' ', '_').trim();
};

const filterSelectedDevs = (selectedRows = [], devsToCheck = []) => {
  if (selectedRows.length === 0) return devsToCheck;
  return selectedRows.filter((dev) =>
    devsToCheck.some((selected) => selected.oid === dev.oid)
  );
};

/**
 * Parses devices to get upgrade versions for each vulnerable platform
 * returns all platforms upgrades array
 * @param {*} param0
 * @returns Array of all platform upgrades for firmware template
 */
const parseDevsForUpgrades = async ({
  selectedRows,
  dvmDevices,
  assetData,
}) => {
  const dvmDevsToUpgrade = filterSelectedDevs(selectedRows, dvmDevices);
  const fapToUpgrade = filterSelectedDevs(selectedRows, assetData.fap);
  const fswToUpgrade = filterSelectedDevs(selectedRows, assetData.fsw);
  const fextToUpgrade = filterSelectedDevs(selectedRows, assetData.fext);

  const FGT_UpgradeVersions = await getUpgrades(dvmDevsToUpgrade, OSTYPES.FGT);
  const FAP_UpgradeVersions = await getUpgrades(fapToUpgrade, OSTYPES.FAP);
  const FSW_UpgradeVersions = await getUpgrades(fswToUpgrade, OSTYPES.FSW);
  const FEXT_UpgradeVersions = await getUpgrades(fextToUpgrade, OSTYPES.FEXT);

  return [
    ...FGT_UpgradeVersions,
    ...FAP_UpgradeVersions,
    ...FSW_UpgradeVersions,
    ...FEXT_UpgradeVersions,
  ];
};

const FWMPROF_CATEGORY = 'fwmprof setting';

/**
 * Gets upgrade to versions, then opens new firmware template with
 * upgrades prefilled for user
 * @param {*} param0
 * @returns HTTP request promise
 */
export const openFWTemplate = async ({
  selectedRows,
  dvmDevices = [],
  assetData = {},
  $opener = null,
}) => {
  const upgradeVersions = await parseDevsForUpgrades({
    selectedRows,
    dvmDevices,
    assetData,
  });

  const templateName = getNewTemplateName();
  const editParams = {
    name: templateName,
    isClone: false,
    initialValues: {
      name: templateName,
      type: 'fwmprof',
      [FWMPROF_CATEGORY]: {
        'schedule-day': 0,
        'image-source': 0,
        'schedule-end-time': null,
        'schedule-start-time': null,
        'schedule-type': 0,
        'enforced version': upgradeVersions,
      },
    },
  };

  const devsToUpgrade = filterSelectedDevs(selectedRows, dvmDevices);

  //only get real devices with upgrade
  const assignToDevs = devsToUpgrade
    .filter((dev) => dev.gui_upgrade_to)
    .filter((dev) => dev.os_type !== MACROS.DVM.DVM_OS_TYPE_FAZ) //cannot upgrade FAZ
    .map((dev) => ({ name: dev.name, vdom: 'root' }));

  //open wizard for firmware template BUT
  //we need to handle submission ourselves
  const { FirmwareTemplateEditDrawerWrapper } = await import(
    'react_apps/ra_pt/firmware_template/FirmwareTemplateEdit'
  );
  return new Promise((resolve, reject) => {
    // if (!$opener) {
    //   resolve();
    // }
    const openFunction = $opener ? $opener.open : ProToolkit.openDrawer;

    openFunction(
      <FirmwareTemplateWizard
        newProfData={editParams}
        FirmwareTemplateEditDrawerWrapper={FirmwareTemplateEditDrawerWrapper}
        assignToDevs={assignToDevs}
        onSubmitFirmwareTemplate={async (res, isNew, chosenAssignToDevs) => {
          await unassignDevices(chosenAssignToDevs);
          try {
            if (isNew)
              return await addFirmwareTemplate(res, chosenAssignToDevs);
            return await setFirmwareTemplate(res, chosenAssignToDevs);
          } catch (e) {
            const errMsg = getDetailErrorMsg(
              e,
              e?.message || gettext('Failed to save Firmware Template.')
            );
            fiErrorMessage.show(errMsg);
          }
        }}
      />
    )
      .result.then((res) => {
        if (res) {
          resolve(res);
          return;
        }
        resolve();
      })
      .catch(reject);
  });
};

async function unassignDevices(chosenAssignToDevs = []) {
  if (!chosenAssignToDevs.length) return;
  const devicesToUnassign = chosenAssignToDevs.filter(
    (dev) => !!dev.templateName
  );
  const devicesToUnassignMap = objectArrayToMap(devicesToUnassign, 'name');
  const templatesMap = await getMapOfAssignedDevicesAndFirmwareTemplates();

  const templatesToUnassignFromMap = devicesToUnassign.reduce((prev, curr) => {
    const templateName = curr.templateName;

    return {
      ...prev,
      [templateName]: templatesMap[curr.name],
    };
  }, {});

  const templatesToUnassignFrom = Object.values(templatesToUnassignFromMap);

  const templatesToModify = templatesToUnassignFrom.map((template) => {
    const scopeMembers = template['scope member'] || [];
    const newScopeMembers = scopeMembers.filter((device) => {
      return !devicesToUnassignMap[device.name];
    });

    return {
      ...template,
      'scope member': newScopeMembers,
    };
  });

  const adom = fiAdom.current();
  const requests = templatesToModify.map((data) => {
    return setFwmProf(adom, data, data.name);
  });

  try {
    await Promise.all(requests);
  } catch (e) {
    fiErrorMessage.show(e);
    fiErrorMessage.show(gettext('Error unassigning devices'));
  }
}
