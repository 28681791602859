import { useLocation, useNavigate } from 'react-router-dom';
import { fiHttpGet } from 'fi-http';
import { useEffect, useState } from 'react';
import { seconds2Dhms } from 'kit-time';
import { NwProgressBar } from '@fafm/neowise/react';

export const SystemUpgrading = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [upgradeStatus, setUpgradeStatus] = useState({
    _gui_isUpgrading: true,
    percent: 0,
  });

  useEffect(() => {
    const updateUpgradeStatus = () => {
      fiHttpGet('/p/upgrade/').then((status) => {
        let isUpgrading = false,
          remainTime = null;

        if (status.status !== status.all_steps || status.percent < 100) {
          // still upgrading
          isUpgrading = true;
          if (status.remain_time > 0) {
            remainTime = seconds2Dhms(status.remain_time);
          }
        } else if (
          status.migration_status === 2 &&
          status.license_status === 0
        ) {
          // If not upgrading, then check for GUI migration or license is updating.
          clearInterval(timer);
          navigate(location.state?.from || -1);
        }

        setUpgradeStatus({
          ...status,
          _gui_isUpgrading: isUpgrading,
          _gui_remainTime: remainTime,
        });
      });
    };

    const timer = setInterval(updateUpgradeStatus, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    !!upgradeStatus && (
      <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full'>
        <div className='tw-p-16 tw-bg-neutral-0 tw-rounded-md tw-box-border tw-min-w-[30rem] tw-max-w-[35%] tw-min-h-[22.75rem]'>
          <div className='tw-text-3xl tw-text-center tw-w-full'>
            {gettext('Temporarily Unavailable')}
          </div>
          <div className='tw-my-5 tw-leading-8'>
            {gettext(
              'The system is unavailable due to configuration update. Device logs are not accepted at this time.'
            )}
          </div>

          {!upgradeStatus._gui_isUpgrading &&
            upgradeStatus.license_status === 1 && (
              <span>
                {gettext(
                  'License is updating and may reboot automatically ...'
                )}
              </span>
            )}

          {!upgradeStatus._gui_isUpgrading &&
            ((upgradeStatus.migration_status === 1 && (
              <span>{gettext('GUI database migration is in progress...')}</span>
            )) ||
              (upgradeStatus.migration_status === 3 && (
                <span>{gettext('GUI database migration failed.')}</span>
              )))}

          {upgradeStatus._gui_isUpgrading && (
            <>
              {upgradeStatus.all_steps > 1 &&
                ((upgradeStatus.status === 0 && (
                  <div>
                    <i>
                      {gettext('Step 1/')}
                      {upgradeStatus.all_steps}:{' '}
                      {gettext('configuration database update')}
                    </i>
                  </div>
                )) ||
                  (upgradeStatus.status === 1 && (
                    <div>
                      <i>
                        {gettext('Step 2/')}
                        {upgradeStatus.all_steps}:{' '}
                        {gettext('report configuration database update')}
                      </i>
                    </div>
                  )))}

              <NwProgressBar
                percentage={upgradeStatus.percent}
                className='tw-my-5'
                style={{
                  '--height': '20px',
                  '--indicator-color': 'rgb(var(--nw-color-success))',
                }}
              >
                {upgradeStatus.percent}%
              </NwProgressBar>
              <div>
                <span>{gettext('Estimated completion time: ')}</span>
                <span>
                  {upgradeStatus._gui_remainTime ?? gettext('Unknown')}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
};
