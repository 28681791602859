import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { listenerMiddleware } from 'fistore/middlewares';
import { escapeSlash } from 'kit-regexp';
import { fiFmgHttp } from 'fi-http';
import { getUsername } from 'fistore/session/sysConfig/selectors';
import { fetchSysConfig } from 'fistore/session/sysConfig/slice';
import { apiResultFirst } from 'fistore/utils/api';
import { getCurrentTheme } from './selectors';
import { getCurrent } from './utils';
import { fetchLoginEnv } from 'fistore/auth/slice';
import { getLoginEnv } from 'fistore/auth/selectors';

const defaultTheme = MACROS.INTEROP.SYS.GUI_THEME_DEFAULT;

const initialState = {
  user: 0,
  useGlobal: 1,
  global: defaultTheme,

  // preview
  previewStack: [], // for counting the preview times for reverting them.
};

const _slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    previewTheme(state, { payload: previewId }) {
      if (!state.previewStack.length) {
        console.error(
          'please dipatch addStack action after enter preview page.'
        );
      }
      state.previewStack[state.previewStack.length - 1] = previewId;
    },
    pushPreviewStack(state) {
      const previewId = getCurrent(state);
      state.previewStack.push(previewId);
    },
    popPreviewStack(state) {
      state.previewStack.pop();
    },
    setUserTheme(state, { payload: { useGlobalTheme, userTheme } }) {
      state.user = userTheme;
      state.useGlobal = useGlobalTheme;
    },
    setGlobalTheme(state, { payload: themeId }) {
      state.global = themeId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSysConfig.fulfilled,
      (state, { payload: { gui_theme, user_theme, use_global_theme } }) => {
        state.global = gui_theme;
        state.user = user_theme;
        state.useGlobal = use_global_theme;
      }
    );
  },
});

// exports

export default _slice.reducer;

export const {
  previewTheme,
  pushPreviewStack,
  popPreviewStack,
  setUserTheme,
  setGlobalTheme,
} = _slice.actions;

// thunks

const JSON_API_URL = '/gui/sys/admin/users';
export const saveUserTheme = createAsyncThunk(
  'theme/saveUserTheme',
  async ({ useGlobalTheme, userTheme }, { getState, dispatch }) => {
    const username = getUsername(getState());
    const url = `${JSON_API_URL}/${escapeSlash(username)}/theme`;

    try {
      const resp = await apiResultFirst(fiFmgHttp.post, {
        url,
        method: 'post',
        params: {
          use_global_theme: useGlobalTheme,
          user_theme: userTheme,
        },
      });
      dispatch(setUserTheme({ useGlobalTheme, userTheme }));
      return resp;
    } catch (e) {
      console.error(e);
    }
  }
);

// listeners
listenerMiddleware.startListening({
  matcher: isAnyOf(
    previewTheme,
    pushPreviewStack,
    popPreviewStack,
    setUserTheme,
    setGlobalTheme,
    fetchSysConfig.fulfilled
  ),
  effect: async (action, { getState, getOriginalState }) => {
    const theme = getCurrentTheme(getState());
    const oldTheme = getCurrentTheme(getOriginalState());
    if (theme.value !== oldTheme.value) {
      const nwClass =
        (theme.dark ? 'nw-dark' : 'nw-light') +
        (theme.highcontrast ? '-contrast' : '');
      const twClass = theme.dark ? ' tw-dark' : '';
      const themeClass = `flatui np-theme-${theme.name} ${nwClass}${twClass}`;
      document.querySelector('body').className = themeClass;
    }
  },
});

listenerMiddleware.startListening({
  actionCreator: fetchLoginEnv.fulfilled,
  effect: (action, { getState, dispatch }) => {
    const loginEnv = getLoginEnv(getState());
    // Sets the default theme after fetch loginenv.
    // This is useful in case of http error 503. See #1041053.
    dispatch(setGlobalTheme(loginEnv.theme_code));
  },
});
