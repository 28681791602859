import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ProLayout } from '@fafm/neowise-pro';

import { fiStore } from 'fistore';
import { getIsSidebarOpened } from 'fistore/main-frame/selectors';
import { treeMap, getPropAsFn } from 'fiutil';
import { getAppChildren, getAppKey } from 'fistore/routing/utils';
import {
  getAllApps,
  getAppStateCache,
  getAppTreeMap,
  getSideMenuSelected,
  getSideMenuTree,
} from 'fistore/routing/selectors';
import { endLoadingApp, startLoadingApp, goApp } from 'fistore/routing/slice';
import { getSessionAdomLoading } from 'fistore/session/adom/selectors';
import { setSidebarWidth } from 'fistore/main-frame/slice';
import { uniqueId } from 'lodash';

export const SIDE_BAR_RELOAD_KEY = 'sideBarReloadKey';

const MENU_DIVIDER = '-';

const app2SideMenuItem = (state) => (node, children, level) => {
  const key = getAppKey(node);
  if (key === MENU_DIVIDER) return { isDivider: true };
  const app = getAllApps(state)[key];
  const disabled = getPropAsFn(app.disabled, false, app, state);
  return {
    key: app.key,
    label: getPropAsFn(app.label, '', app, state),
    icon: getPropAsFn(app.icon, '', app, state),
    iconLibrary: getPropAsFn(app.iconLibrary, '', app, state),
    isLoading: getPropAsFn(app.isLoading, false, app, state),
    onAppClick: app.onAppClick,
    onMenuExpand: app.onMenuExpand,
    menuItemActions: app.menuItemActions,
    maxHeightMultiplier: 2,
    iconClassName: level === 1 ? 'tw-text-lg' : '',
    style: { color: app.color },
    ...(children ? { items: children } : null),
    disabled,
  };
};

const MainSidebar = () => {
  const dispatch = useDispatch();
  const allApps = useSelector(getAllApps);
  const appTreeMap = useSelector(getAppTreeMap);
  const sidebarOpened = useSelector(getIsSidebarOpened);
  const sideMenuSelected = useSelector(getSideMenuSelected);
  const sideMenuTree = useSelector(getSideMenuTree);
  const isAdomLoading = useSelector(getSessionAdomLoading);

  const apps = useMemo(() => {
    return treeMap(getAppChildren)({
      items: sideMenuTree,
      postFn: app2SideMenuItem(fiStore.getState()),
    });
  }, [sideMenuTree]);

  // menu click handler
  const appStateCache = useSelector(getAppStateCache);
  const menuItemOnClick = useCallback(
    async (item) => {
      if (item.onAppClick) {
        try {
          await item.onAppClick();
        } catch {
          return false;
        }
      }
      const selectedApp = allApps[item.key];
      dispatch(startLoadingApp());
      dispatch(
        goApp({
          key: selectedApp.key,
          opts: { replace: true, state: { [SIDE_BAR_RELOAD_KEY]: uniqueId() } },
        })
      );
      dispatch(endLoadingApp());
    },
    [dispatch, appStateCache, appTreeMap, allApps]
  );

  const onMenuResized = useCallback(
    (newWidth) => {
      dispatch(setSidebarWidth(newWidth));
    },
    [dispatch]
  );

  const onSideMenuExpand = useCallback((menu) => {
    return menu.onMenuExpand?.();
  }, []);

  return (
    <div className='main-sidebar'>
      <ProLayout.Menu
        menuIsLoading={isAdomLoading}
        menuId='nwp-main-sidebar'
        showIconWhenCollapsed
        menuItems={apps}
        selectedMenuItemKey={sideMenuSelected}
        menuItemOnClick={menuItemOnClick}
        menuCollapsed={!sidebarOpened}
        onMenuResized={onMenuResized}
        menuOnExpand={onSideMenuExpand}
        menuItemActions={(item) => {
          return item.menuItemActions || [];
        }}
        isAppSideMenu={true}
      ></ProLayout.Menu>
    </div>
  );
};

MainSidebar.displayName = 'MainSidebar';

export default MainSidebar;
