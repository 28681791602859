import cn from 'classnames';

import { isFunction, promiseWhen, throttle } from 'fiutil';

import { useWizard } from './useWizard';

/**
 * @params
 * e.g.
    {
      steps: [
        { id: 'step1', text: gettext('Step 1') },
        { id: 'step2', text: gettext('Step 2') },
        { id: 'step3', text: gettext('Step 3') },
      ];
    }
 */
export const WizardProgress = ({
  steps,
  isVertical = true,
  onSubmit,
  currentStep,
  currentStepIndex,
}) => {
  /** ----------------------------------------------------------------------------
   * Context
   * -------------------------------------------------------------------------- */
  const wizardAccessor = useWizard();

  /** ----------------------------------------------------------------------------
   * Event handler
   * -------------------------------------------------------------------------- */
  const onJumpTo = throttle(async (stepKey) => {
    if (isFunction(onSubmit)) {
      const resp = await promiseWhen(onSubmit());
      if (resp?.error) return;
    }
    wizardAccessor.jumpTo(stepKey); // prevent jumpTo from being called too quickly
  }, 300);

  /** ----------------------------------------------------------------------------
   * Render functions
   * -------------------------------------------------------------------------- */
  const renderSpacer = () => {
    return isVertical ? (
      <VerticalProgressSpacer />
    ) : (
      <HorizontalProgressSpacer />
    );
  };

  const renderSteps = () => {
    return (
      <div
        className={cn(
          'tw-flex tw-p-4',
          isVertical
            ? 'tw-flex-col tw-space-y-2 tw-w-60'
            : 'tw-space-x-2 tw-w-full'
        )}
      >
        {steps.map((step, index) => {
          const isSelected = step.id === wizardAccessor.activeKey;
          const _text = isFunction(step?.text)
            ? step.text({ currentStep, currentStepIndex })
            : step.text || '';
          return (
            <div
              key={step.id}
              className={cn('tw-flex tw-items-center', {
                'tw-flex-col': isVertical,
              })}
            >
              <div
                role='button'
                onClick={() => onJumpTo(step.id)}
                className='tw-flex tw-flex-col tw-flex-wrap tw-items-center'
                style={{ maxWidth: '200px' }}
              >
                <CurrentProgress index={index} isSelected={isSelected} />
                <span>{_text}</span>
              </div>
              {index < steps.length - 1 && renderSpacer()}
            </div>
          );
        })}
      </div>
    );
  };

  return renderSteps();
};

const CurrentProgress = ({ isSelected, index }) => {
  return (
    <div
      className={cn(
        'tw-border-solid tw-rounded-full tw-m-auto tw-px-5 tw-py-3',
        isSelected ? 'tw-border-primary-300' : 'tw-border-neutral-200'
      )}
    >
      {index + 1}
    </div>
  );
};

const VerticalProgressSpacer = () => {
  return <div className='tw-w-0.5 tw-h-14 tw-bg-neutral-300'></div>;
};

const HorizontalProgressSpacer = () => {
  return <div className='tw-w-14 tw-h-0.5 tw-bg-neutral-300'></div>;
};
