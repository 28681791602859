//import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { identity } from 'lodash';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { fiStore } from 'fistore';
import { Provider } from 'react-redux';

import 'xterm/css/xterm.css';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'jquery-ui/dist/themes/base/theme.css';
import 'jquery-contextmenu/dist/jquery.contextMenu.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '@fafm/c3/c3.css';
import '../../../resource/style/flag_icons.css';
import '@fafm/codemirror/lib/codemirror.css';
import '@fafm/codemirror/theme/twilight.css';
import '@fafm/codemirror/theme/material-darker.css';
import '@fafm/codemirror/addon/merge/merge.css';
import '@fafm/codemirror/addon/hint/show-hint.css';
import '@fafm/codemirror/addon/edit/brackets.css';
import '@fafm/codemirror/addon/lint/lint.css';
import '@fafm/croppie/croppie.css';
import '@fafm/event-drops/dist/style.css';
import '@fafm/slate-report-editor/dist/slate-report-editor.css';

import '../../fonts/flatuifont/css/flatuifont.css';

import '../../less/app/widget/forms.less';
import '../../less/app/themes/widget.less';
import '../../less/app/widget/spinner.less';

import '../../less/app/atom/color.less';

import './App.less';
// themes style, no conflicts and order does not matter
import '@fafm/neowise/themes/nw-common.css';
import '@fafm/neowise-themes/dist/nw-theme.css';
import '@fafm/neowise-themes/dist/nw-theme-ext.css';
import '@fafm/neowise-themes/dist/nw-palette.css';
import '@fafm/neowise-themes/dist/standard.css';
// nw component style, order matters
import '@fafm/neowise-core/neowise-core.css';
import '@fafm/neowise-pro/neowise-pro.css';
import '@fafm/filego2/filego.css';
import '../../less/tailwind.css';

import routes from './AppRoutes';

const router = createBrowserRouter(routes, { basename: '/ui/' });
// Set to global to allow jumping from fiStore
// DON'T use it outside the store effects!
window.__router = router;
// guard for jsi18n loading
window.gettext = window.gettext ?? identity;
window.ngettext = window.ngettext ?? identity;
window.pgettext = window.pgettext ?? identity;
window.npgettext = window.npgettext ?? identity;

if (MACROS.SYS.CONFIG_DEBUG) {
  window.MACROS = MACROS;
}

const reactRoot = ReactDOM.createRoot(document.getElementById('top_content'));
reactRoot.render(
  /*<StrictMode>*/
  <Provider store={fiStore}>
    {/* autosizer needs nonce for CSP style-src, workaround: directly add the css to App.less */}
    {/* only first instance of autosizer to render needs nonce, if not here then may be from neowise */}
    {/* <AutoSizer nonce={nonce}>{() => {}}</AutoSizer> */}
    <RouterProvider router={router} />
  </Provider>
  /*</StrictMode>*/
);
