import { fiLogout } from 'fi-session';
import {
  openConfirmModal,
  makeConfirmModalRenderFn,
} from 'react_components/rc_layout/ConfirmModal';
import { isFunction } from 'lodash';
import { fiMessageBox } from 'fi-messagebox';
import { SysDashboardService } from 'fi-apps/fi-dashboard/sys_dashboard_service';

export const popupConfirmAdomStatus = (cancel, newVal) => {
  var msg = newVal ? (
    gettext(
      'Are you sure that you want to enable administrative domain feature?'
    )
  ) : (
    <>
      {gettext(
        'Are you sure that you want to disable administrative domain feature?'
      )}
      <br />
      {gettext(
        'Note: Can only disable ADOMs if all non-root ADOM are removed.'
      )}
    </>
  );

  return openConfirmModal({
    renderer: makeConfirmModalRenderFn({
      title: gettext('Administrative Domain'),
      content: msg,
      onOkClick: ($opener) => {
        SysDashboardService.toggleADOMFeature(newVal).then(
          // success
          function () {
            $opener.resolve();
            fiLogout();
          },
          // failed
          function (msg) {
            fiMessageBox.show(msg, 'danger');
          }
        );
      },
      onCancelClick: ($opener) => {
        if (isFunction(cancel)) cancel();
        $opener.reject();
      },
    }),
  });
};
