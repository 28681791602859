import { useMemo, Fragment, memo } from 'react';
import { FormikConsumer } from 'formik';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { NwCheckbox } from '@fafm/neowise-core';
import { Label } from 'rc_layout';
import { isNumber, convertRemToPx, getTextWidth } from 'fiutil';
import { get } from 'lodash';

/**
 * Component for list checkboxes into fixed or automatically calculated number of columns.
 * All items should have the following properties:
 * { name: ..., value: ..., text: ...}
 */
export const FmkCheckboxArray = memo(
  ({
    items = [],
    numOfCols = 'auto',
    customOnClick,
    sortBy,
    label,
    customValues,
    customGetCheckboxValue,
    getAutoId,
    'automation-id': autoId,
    automationId,
    style = {},
  }) => {
    const isAutoColumns = numOfCols === 'auto';

    const memoizedSortedItems = useMemo(() => {
      return sortItem(items, sortBy);
    }, [items, sortBy]);

    const getCheckboxValue = (formValues, name, index, initVal) => {
      const val = formValues[name] ?? get(formValues, name, initVal);
      return customValues ? customValues?.[index]?.checked : val;
    };

    const defaultOnClick = (evt, formValues, setFieldValue, name) => {
      // prevent firing up onClick multiple time on a single click event
      evt.preventDefault();
      evt.stopPropagation();

      const currentValue = formValues[name] ?? get(formValues, name);
      setFieldValue(name, !currentValue);
    };

    // to allow having the same width for all checkboxes
    const checkboxStyle = useMemo(() => {
      const checkboxWidth = convertRemToPx(1); // 1rem
      const labelWidth = getLongestLabelWidth(memoizedSortedItems);
      const labelPadding = convertRemToPx(1); // 1rem
      const longestCheckboxWidth = Math.ceil(
        parseFloat(checkboxWidth + labelWidth + labelPadding)
      );
      if (isAutoColumns) {
        return { flexBasis: `${longestCheckboxWidth}px` };
      }

      return {};
    }, [memoizedSortedItems, isAutoColumns]);

    const defaultGetAutoId = (suffix) => {
      const prefix = autoId || automationId;
      return `${prefix}-${suffix}`;
    };

    return (
      <FormikConsumer>
        {({ values: formValues, setFieldValue }) => (
          // Use flex for auto number of columns based on container width and longest checkbox label
          // Use grid for fixed number of columns
          <div
            className={cn({
              'tw-inline-flex tw-flex-wrap': isAutoColumns,
              'tw-grid tw-w-full': !isAutoColumns,
            })}
            style={{
              gridTemplateColumns: !isAutoColumns
                ? `repeat(${Number(numOfCols)}, minmax(0, 1fr))`
                : undefined,
            }}
          >
            {memoizedSortedItems.map((item, index) => {
              const {
                id,
                name: _name,
                text,
                value: initVal,
                automationId: itemAutoId,
                disabled,
              } = item;
              const itemName = _name || id;
              return (
                <Fragment key={index}>
                  {/* Label: only display label for even index item */}
                  {label && index % 2 === 0 && <Label>{!index && label}</Label>}

                  {/* Checkbox */}
                  <NwCheckbox
                    id={itemName}
                    name={itemName}
                    disabled={disabled}
                    checked={
                      customGetCheckboxValue
                        ? customGetCheckboxValue(
                            formValues,
                            itemName,
                            index,
                            initVal
                          )
                        : getCheckboxValue(formValues, itemName, index, initVal)
                    }
                    onChange={(evt) =>
                      customOnClick
                        ? customOnClick(
                            evt,
                            formValues,
                            setFieldValue,
                            index,
                            item
                          )
                        : defaultOnClick(
                            evt,
                            formValues,
                            setFieldValue,
                            itemName
                          )
                    }
                    style={{ ...checkboxStyle, ...style }}
                    automation-id={
                      getAutoId
                        ? getAutoId(itemAutoId)
                        : itemAutoId || defaultGetAutoId(itemName)
                    }
                  >
                    {text}
                  </NwCheckbox>
                </Fragment>
              );
            })}
          </div>
        )}
      </FormikConsumer>
    );
  }
);

FmkCheckboxArray.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string.isRequired,
      value: PropTypes.any,
      automationId: PropTypes.string,
    })
  ).isRequired,
  numOfCols: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
  sortBy: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  /**
   * this is used if the array of objects is nested inside a property in the form model (instead of flattened)
   */
  customValues: PropTypes.array,
  /**
   * custom onClick function that update the form values differently than the default onClick
   */
  customOnClick: PropTypes.func,
  /**
   * Custom function to get checkbox value from formik
   */
  customGetCheckboxValue: PropTypes.func,
  formatAutoId: PropTypes.func,
};

/* ----------------------------------------------------------------------------
 * Handler functions
 * ------------------------------------------------------------------------- */
const sortItem = (items, sortBy) => {
  if (!sortBy) {
    return items;
  }

  const validOptions = ['name', 'value', 'text'];
  if (sortBy && !validOptions.includes(sortBy)) {
    return items;
  }

  const itemsCopy = [...items];
  itemsCopy.sort((item1, item2) => {
    const val1 = item1[sortBy];
    const val2 = item2[sortBy];
    if (isNumber(val1)) return val1 - val2;
    else if (typeof val1 === 'string') {
      return val1.localeCompare(val2);
    }
  });

  return itemsCopy;
};

function getLongestLabelWidth(opts) {
  let longestName = '';

  for (const opt of opts) {
    const text = opt.text;
    if (!text) continue;
    if (text.length > longestName.length) {
      longestName = text;
    }
  }

  return getTextWidth(longestName);
}
