import { fiFmgHttp } from 'fi-http';
import { castArray, flow, isEqual, uniqWith } from 'lodash';

/** response status */
type Status = { code?: number; message: string };
/** json response */
type Response = { data: any; status: Status; id: string; url: string };
/** json error; response from websocket dispatch request */
type ResponseWrapped = { code: number; data: { result: Response[] } };

async function singleUrlQuery(
  request: object,
  abortCtrl?: AbortController
): Promise<any> {
  try {
    const resps: Response[] = await fiFmgHttp.forward(
      request,
      undefined,
      abortCtrl
    );
    return resps[0].data;
  } catch (ex: any) {
    const result = (ex as ResponseWrapped).data.result[0];
    throw result.data?.message ? result.data : result.status;
  }
}

/** adom oid */
type AdomParam = number;
/** external interface */
type Scope = { did: number; vid?: number } | DeviceOidScope;
/** API interface */
type DeviceOidScope = { oid: number; vdom_oid?: number };

function sizeValidator<Fn extends (...args: any) => any[]>(
  fn: Fn,
  message: () => string
) {
  return (...args: Parameters<Fn>) => {
    const result = uniqWith(fn(...args) as ReturnType<Fn>, isEqual);
    if (!result.length) {
      throw { message: message() };
    }
    return result as DeviceOidScope[];
  };
}

function vdomScope(scope: Scope[]): DeviceOidScope[] {
  const result = scope
    // @ts-expect-error backend and GUI scope
    .map(({ oid, vdom_oid, did = oid, vid = vdom_oid }) => ({
      oid: Number(did),
      vdom_oid: vid ? Number(vid) : undefined,
    }))
    .filter(({ oid, vdom_oid }) => oid && (vdom_oid || vdom_oid === undefined));
  return result;
}

function skipGlobalScope(scope: DeviceOidScope[]) {
  return scope.filter(
    ({ vdom_oid }) => vdom_oid !== MACROS.DVM.CDB_DEFAULT_GLOBAL_OID
  );
}

export enum InstallFlag {
  // None = 'none',
  CpAllObjs = 'cp_all_objs',
  Preview = 'preview',
  GenerateRev = 'generate_rev',
  CopyAssignedPkg = 'copy_assigned_pkg',
  Unassign = 'unassign',
  ObjsOnly = 'objs_only',
  AutoLockWs = 'auto_lock_ws',
  CheckPkgSt = 'check_pkg_st',
  CopyOnly = 'copy_only',
}

const installVdomScope = flow(
  sizeValidator(vdomScope, () => gettext('No device to install')),
  skipGlobalScope
);

export async function secCtrlInstallDev(
  params: { adom: AdomParam; scope: Scope[] } & Partial<{
    comment: string;
    flags: InstallFlag[];
  }>
): Promise<{ task: number }> {
  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/install/device',
        data: {
          adom: params.adom,
          dev_rev_comments: params.comment,
          flags: params.flags,
          scope: installVdomScope(params.scope),
        },
      },
    ],
  };

  return singleUrlQuery(req);
}

export async function scheduleInstallPkg(
  params: {
    adom: string;
    pkg: string; // pkg path
    scope: Scope[];
    datetime: number; // timestamp
  } & {
    comment: string;
    /** If not set, no ADOM revision will be created during installation */
    revName: string;
    revComment: string;
  }
): Promise<void> {
  const req = {
    method: 'add',
    params: [
      {
        url: `pm/pkg/adom/${params.adom}/${params.pkg}/schedule`,
        data: {
          adom_rev_name: params.revName,
          adom_rev_comment: params.revComment,
          dev_rev_comment: params.comment,
          datetime: params.datetime + '',
          scope: installVdomScope(params.scope),
        },
      },
    ],
  };
  // no data response
  await singleUrlQuery(req);
}

export async function secCtrlInstallPkg(
  params: {
    adom: AdomParam;
    pkg: number;
    scope: Scope[];
  } & Partial<{
    flags: InstallFlag[];
    comment: string;
    revName: string;
    revComment: string;
  }>
): Promise<{ task: number }> {
  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/install/package',
        data: {
          adom: params.adom,
          pkg: params.pkg,
          flags: params.flags,
          ...(params.flags?.includes(InstallFlag.GenerateRev)
            ? {
                adom_rev_name: params.revName,
                adom_rev_comments: params.revComment,
              }
            : undefined),
          dev_rev_comments: params.comment,
          scope: installVdomScope(params.scope),
        },
      },
    ],
  };

  return singleUrlQuery(req);
}

export async function secCtrlReInstallPkg(
  params: {
    adom: AdomParam;
    target: {
      pkg: number;
      scope: Scope[];
    }[];
  } & Partial<{
    flags: InstallFlag[];
  }>
): Promise<{ task: number }> {
  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/reinstall/package',
        data: {
          adom: params.adom,
          flags: params.flags,
          target: params.target.map(({ pkg, scope }) => ({
            pkg,
            scope: installVdomScope(scope),
          })),
        },
      },
    ],
  };
  return singleUrlQuery(req);
}

/** Commit copy cache for install/reinstall. */
export async function secCtrlCommitInstall(
  params: { adom: AdomParam; scope: Scope[] } & Partial<{
    // comment: string;
    flags: InstallFlag[];
  }>
): Promise<{ task: number }> {
  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/package/commit',
        data: {
          adom: params.adom,
          // dev_rev_comments: params.comment,
          scope: installVdomScope(params.scope),
          flags: params.flags,
        },
      },
    ],
  };
  return singleUrlQuery(req);
}

export async function secCtrlCancelInstall(data: {
  adom: AdomParam;
}): Promise<void> {
  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/package/cancel/install',
        data: {
          adom: data.adom,
        },
      },
    ],
  };
  await singleUrlQuery(req);
}

export enum InstallSummaryStatus {
  /** TUNNEL_DOWN */
  DiffFailed = 'diff_failed',
  /** TUNNEL_UP */
  Ok = 'ok',
  CopyFailed = 'copy_failed',
  CopyOnly = 'copy_only',
  NoCmd = 'no_cmd',
  Unknown = 'unknown',
  CliprofFailed = 'cliprof_failed',
  /** e.g: if root VDOM is cliprof_failed, the other VDOMs are skipped. */
  Skipped = 'skipped',
}

export type InstallSummaryMember = {
  /** device name */
  name: string;
  /** device oid */
  oid: string;
  /** cpp logic: one of the defined status or defaults to `unknown` */
  status: InstallSummaryStatus;
  vdom: Array<{
    name: string;
    oid: string;
    /** purge category number list */
    purge_list?: Array<number>;
    skipped?: 0 | 1;
    status: InstallSummaryStatus;
  }>;
};

export type InstallSummary = {
  /** pkg name: does not include folder path; does not exist if from install device */
  name?: string;
  /** pkg oid: 0 if from install device */
  oid: number;
  /** scope members */
  member: Array<InstallSummaryMember>;
};

/** Get copy result */
export async function secCtrlInstallSummary(): Promise<{
  summary: InstallSummary[];
}> {
  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/install/summary',
      },
    ],
  };
  return singleUrlQuery(req);
}

export enum AdomPkgStatus {
  Imported = 'imported',
  Modified = 'modified',
  Installed = 'installed',
  // ...
}

export type AdomPkgStatusResult = {
  dev: string;
  vdom: string;
  /** pkg oid */
  oid: number;
  /** pkg path, not name */
  pkg: string;
  status: AdomPkgStatus;
};

export async function getAdomPkgStatus(params: {
  adom: string;
}): Promise<AdomPkgStatusResult[]> {
  const req = {
    method: 'get',
    params: [
      {
        url: `/pm/config/adom/${params.adom}/_package/status`,
        type: 'policy',
      },
    ],
  };
  // might be null
  const result = await singleUrlQuery(req);
  return result ?? [];
}

export enum PreviewFlag {
  Json = 'json',
}

export type PreviewResult = {
  /** device name */
  name: string;
  /** device oid */
  oid: number;
  /**
   * Commands to be installed, or error message if install preview failed.
   *
   * - When `secCtrlRunInstallPreview` is executed without the "json" flag,
   * the result will be "=== Preview result ===\n{commands}" upon success.
   *
   * - If the "json" flag is provided,
   * the result will be "=== Preview result ===\n{stringify_array}" upon success.
   */
  result: string;
};

const previewScope = sizeValidator(flow(vdomScope, skipGlobalScope), () =>
  gettext('No device to preview')
);

/** Generate task for preview install commands */
export async function secCtrlInstallPreview(
  params: {
    adom: AdomParam;
    scope: Required<Scope>[];
  } & Partial<{ flags: Array<PreviewFlag> }>
): Promise<{
  task: number;
  // session id(50839) task(1221) username(admin)
  message: string;
}> {
  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/install/preview',
        data: {
          adom: params.adom,
          scope: previewScope(params.scope),
          flags: params.flags,
        },
      },
    ],
  };
  return singleUrlQuery(req);
}

/** Get install commands after the task of `secCtrlInstallPreview` is done */
export async function secCtrlPreviewResult(
  params: {
    adom: AdomParam;
    scope: Required<Scope>[];
  } & Partial<{ flags: Array<PreviewFlag> }>
): Promise<Array<PreviewResult>> {
  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/preview/result',
        data: {
          adom: params.adom,
          scope: previewScope(params.scope),
        },
      },
    ],
  };
  const { message } = await singleUrlQuery(req);
  try {
    const results: PreviewResult[] = JSON.parse(message);

    if (!params.flags?.includes(PreviewFlag.Json)) {
      return results;
    }

    const prefix = /^=== (Preview|No preview) result ===\n/;
    return results.map(({ name, oid, result }) => {
      return {
        name,
        oid,
        result: result.replace(prefix, ''),
      };
    });
  } catch (ex) {
    throw { message: gettext('Failed to parse install preview result') };
  }
}

/**
 * type param:
 * - "copy": copy log
 * - "cliprof": cliprof log
 */
export async function secCtrlCopyResult(params: {
  adom: AdomParam;
  type: 'copy' | 'cliprof';
  scope: Required<Scope>;
}): Promise<{ message: string }> {
  const copyResultScope = sizeValidator(vdomScope, () =>
    gettext('No device to check copy result')
  );
  const { oid, vdom_oid } = copyResultScope(castArray(params.scope))[0];

  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/copy/result',
        data: {
          adom: params.adom,
          type: params.type,
          device: oid,
          vdom: vdom_oid,
        },
      },
    ],
  };
  return singleUrlQuery(req);
}

export type PolicyValidationResult = {
  /** "adom:{adomName}"; "{deviceName}" */
  devname: string;
  results: Array<{
    /** empty string => "root" */
    vdom: string;
    error: string;
    policyid: number;
    /** SC_POL_VAL_WARN_DENY_ALL ... */
    type: number;
  }>;
};

export type ZoneValidationResultZone = {
  /** empty string => "root" */
  vdom: string;
  name: string;
  if_wildcard: 0 | 1;
};

export type ZoneValidationResult = {
  /** "adom:{adomName}"; "{deviceName}" */
  devname: string;
  invalidmap_zone: Array<ZoneValidationResultZone>;
  unmapped_zone: Array<ZoneValidationResultZone>;
};

export async function secCtrlValidationResult(params: {
  adom: AdomParam;
  type: 'policy';
}): Promise<{ policy: PolicyValidationResult[] }>;
export async function secCtrlValidationResult(params: {
  adom: AdomParam;
  type: 'zone';
}): Promise<{ zone: ZoneValidationResult[] }>;
/** Get validation result after copy */
export async function secCtrlValidationResult({
  adom,
  type,
}: {
  adom: AdomParam;
  type: 'policy' | 'zone';
}) {
  const req = {
    method: 'exec',
    params: [
      {
        url: '/securityconsole/validation/result',
        data: {
          adom,
          type,
        },
      },
    ],
  };
  return singleUrlQuery(req);
}

export type ObjLimitSummary = {
  adom: number;
  'group-type': number;
  'scope member': [
    {
      name: string;
      vdom: string;
    }
  ];
};

export async function secCtrlObjLimitSummary(): Promise<{
  summary: ObjLimitSummary[];
}> {
  const req = {
    method: 'exec',
    params: [
      {
        url: 'securityconsole/objlimit/devices',
        data: {},
      },
    ],
  };
  return singleUrlQuery(req);
}

export type ObjLimitDetail = Record<string, { current: number; max: number }>;

export async function secCtrlObjListDetail(params: {
  adom: AdomParam;
  scope: { name: string; vdom: string };
}): Promise<{
  summary: ObjLimitDetail[];
}> {
  const req = {
    method: 'exec',
    params: [
      {
        url: 'securityconsole/objlimit/check',
        data: {
          adom: params.adom,
          scope: params.scope,
        },
      },
    ],
  };
  return singleUrlQuery(req);
}
