import { createReducer } from '@reduxjs/toolkit';

import { devicesLicenseAction } from './actions';

const initialState = {
  loaded: false,
  loading: false,
  byId: {},
};

export const deviceLicensesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(devicesLicenseAction.fetch.START, (state) => {
      state.loaded = false;
      state.loading = true;
    })
    .addCase(devicesLicenseAction.fetch.SUCCESS, (state, action) => {
      state.loaded = true;
      state.loading = false;
      state.byId = action.payload;
    });
});
