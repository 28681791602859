import { isFunction } from 'lodash';

export function applyHooks(hooks = [], initialState = {}) {
  function pushFunction(prev, fn) {
    if (!isFunction(fn)) return prev;
    if (!Array.isArray(prev)) return [fn];
    return prev.concat(fn);
  }

  return hooks.reduce((state, hook) => {
    const { preSubmit, afterSubmit, ...rest } = hook(state) || {};

    return {
      ...state,
      preSubmits: pushFunction(state.preSubmits, preSubmit),
      afterSubmits: pushFunction(state.afterSubmits, afterSubmit),
      ...rest,
    };
  }, initialState);
}
