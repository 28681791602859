import { fiHttpGet, fiHttpPost } from 'fi-http';
import { LOG_FETCH } from './urls';

export const logfetchService = (function () {
  function profiles(args) {
    return fiHttpGet(LOG_FETCH.CONFIGS, args);
  }
  function delProfile(params) {
    return fiHttpPost(LOG_FETCH.DEL_CONFIG, params);
  }
  function saveProfile(params) {
    return fiHttpPost(LOG_FETCH.SAVE_CONFIG, params);
  }
  function clientFilters(params) {
    return fiHttpGet(LOG_FETCH.CLIENT_FILTERS, { params });
  }

  function approveSession(params) {
    return fiHttpGet(LOG_FETCH.SERVICE_SESSION_APPROVE, { params });
  }

  function pauseSession(params) {
    return fiHttpGet(LOG_FETCH.SERVICE_SESSION_PAUSE, { params });
  }

  function resumeSession(params) {
    return fiHttpGet(LOG_FETCH.SERVICE_SESSION_RESUME, { params });
  }

  function denySession(params) {
    return fiHttpGet(LOG_FETCH.SERVICE_SESSION_DENY, { params });
  }

  function cancelSession(params) {
    return fiHttpGet(LOG_FETCH.SERVICE_SESSION_CANCEL, { params });
  }

  function reviewSession(params) {
    return fiHttpGet(LOG_FETCH.SERVICE_SESSION_REVIEW, { params });
  }

  function cancelClientSession(params) {
    return fiHttpGet(LOG_FETCH.CLIENT_SESSION_CANCEL, { params });
  }

  function pauseClientSession(params) {
    return fiHttpGet(LOG_FETCH.CLIENT_SESSION_PAUSE, { params });
  }

  function resumeClientSession(params) {
    return fiHttpGet(LOG_FETCH.CLIENT_SESSION_RESUME, { params });
  }

  function saveFilters(params) {
    return fiHttpPost(LOG_FETCH.SAVE_CLIENT_FILTERS, params);
  }

  function fetch(params) {
    return fiHttpGet(LOG_FETCH.CLIENT_SESSION_RUN, { params });
  }

  function consts() {
    return fiHttpGet(LOG_FETCH.CONSTS);
  }

  function serverAdoms(params) {
    return fiHttpGet(LOG_FETCH.SERVER_ADOMS, { params });
  }

  function serverAdomDevices(params) {
    return fiHttpGet(LOG_FETCH.SERVER_ADOM_DEVICES, { params });
  }

  function syncServerConf(params) {
    return fiHttpGet(LOG_FETCH.CLIENT_SYNC_CONF, { params });
  }

  return {
    configs: profiles,
    delProfile: delProfile,
    saveProfile: saveProfile,
    clientFilters: clientFilters,
    saveFilters: saveFilters,
    fetch: fetch,
    consts: consts,

    // server session
    approveSession: approveSession,
    cancelSession: cancelSession,
    denySession: denySession,
    pauseSession: pauseSession,
    resumeSession: resumeSession,
    reviewSession: reviewSession,

    // // client session
    cancelClientSession: cancelClientSession,
    pauseClientSession: pauseClientSession,
    resumeClientSession: resumeClientSession,

    syncServerConf: syncServerConf,

    // // fetch adoms from server
    serverAdoms: serverAdoms,

    // // fetch devices from server by adom
    serverAdomDevices: serverAdomDevices,
  };
})();
