import { omit } from 'lodash';

import {
  deviceGroupsAction,
  deviceGroupsMembAction,
  defaultGroupsAction,
} from './action';
import { devicesAction, vdomsAction } from '../devices/actions';
import {
  initialState,
  make_fetch_reducer,
  make_record_reducer,
  make_reducer,
} from './reducer_util';

export const deviceGroupsReducer = (state = initialState, action) =>
  make_reducer(deviceGroupsAction)(state, action);

/**************************************************************************** */
const entriesToObject = (entries) =>
  entries.reduce((acc, [key, value]) => ((acc[key] = value), acc), {});

const removeGrpMembGrp = (state, action) => {
  switch (action.type) {
    case deviceGroupsAction.record.DELETE: {
      const { oid } = action.payload; //oid is string

      let changed = false;

      const newValue = (value) => {
        const newMembGrps = value.membGrps.filter((x) => {
          return x.oid != oid;
        });

        if (newMembGrps.length === value.membGrps.length) return value;

        if (!changed) changed = true;
        return {
          ...value,
          membGrps: newMembGrps,
        };
      };
      const newEntries = Object.entries(state.list.byId).map(([key, value]) => [
        key,
        newValue(value),
      ]);

      if (!changed) return state;

      return {
        ...state,
        list: {
          ...state.list,
          byId: entriesToObject(newEntries),
        },
      };
    }
  }
  return state;
};

const removeGrpMembByMembOid = (state, device = 0, vdom = 0) => {
  let changed = false;

  const match = (x) => x.oid == device && (vdom == 0 ? true : x.vdom == vdom);

  const newValue = (value) => {
    const newMembDevs = value.membDevs.filter((x) => !match(x));

    if (newMembDevs.length === value.membDevs.length) return value;

    if (!changed) changed = true;
    return {
      ...value,
      membDevs: newMembDevs,
    };
  };
  const newEntries = Object.entries(state.list.byId).map(([key, value]) => [
    key,
    newValue(value),
  ]);

  if (!changed) return state;

  return {
    ...state,
    list: {
      ...state.list,
      byId: entriesToObject(newEntries),
    },
  };
};
const removeGrpMembDevice = (state, action) => {
  switch (action.type) {
    case vdomsAction.record.DELETE: {
      const { device, vdom } = action.payload.data;

      return removeGrpMembByMembOid(state, device, vdom);
    }
    case devicesAction.record.DELETE: {
      const { id } = action.payload.data; //oid is string

      return removeGrpMembByMembOid(state, id);
    }
  }
  return state;
};

const removeGrpMemb = (state, action) => {
  switch (action.type) {
    case deviceGroupsAction.record.DELETE: {
      const { oid } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          byId: omit(state.list.byId, oid.toString()),
        },
      };
    }
  }
  return state;
};
const grpMembRecordReducer = (state, action) => {
  const add = () => {
    const { grpOid, deviceOid } = action.payload;
    const grp = state.list.byId[grpOid];
    if (!grp) return state;
    return {
      ...state,
      list: {
        ...state.list,
        byId: {
          ...state.list.byId,
          membDevs: [...grp.membDevs, deviceOid],
        },
      },
    };
  };
  switch (action.type) {
    case deviceGroupsMembAction.record.ADD: {
      return add();
    }
  }
  return state;
};
const make_memb_reducer = (actionType) => (state, action) =>
  [make_fetch_reducer, make_record_reducer]
    .map((x) => x(actionType))
    .reduce((acc, cur) => cur(acc, action), state);

export const deviceGroupsMembReducer = (state = initialState, action) =>
  [
    make_memb_reducer(deviceGroupsMembAction),
    removeGrpMemb,
    removeGrpMembGrp,
    removeGrpMembDevice,
    grpMembRecordReducer,
  ].reduce((acc, cur) => cur(acc, action), state);

export const defaultGroupsReducer = (state = initialState, action) =>
  make_reducer(defaultGroupsAction)(state, action);
