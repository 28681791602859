import { dispatch, fiStore } from 'fistore';
import { redirect, defer } from 'react-router-dom';
import { fetchLoginEnv } from 'fistore/auth/slice';

import { LoginPage } from './LoginPage';
import { PostLogin } from './PostLogin';
import { SystemUpgrading } from 'react_apps/ra-auth/SystemUpgrading';
import { checkAuthLoader } from './loader';
import { NewLicLoginPage } from 'react_apps/ra-auth/NewLicLoginPage';
import { getRequireVmLic } from 'fistore/auth/selectors';

export default [
  {
    path: 'login',
    loader: async () => {
      if (await checkAuthLoader()) {
        return redirect('/');
      }
      return defer({
        loginEnv: dispatch(fetchLoginEnv()).unwrap(),
      });
    },
    element: <LoginPage />,
  },
  {
    path: 'postlogin',
    loader: async () => {
      if (await checkAuthLoader()) {
        return null;
      }
      return redirect('/login');
    },
    element: <PostLogin />,
  },
  {
    path: 'upgrading',
    element: <SystemUpgrading />,
  },
  {
    path: 'newliclogin',
    loader: () => {
      const state = fiStore.getState();
      if (!getRequireVmLic(state)) {
        return redirect('/login');
      }
      return null;
    },
    element: <NewLicLoginPage />,
  },
];
