import { fiFmgHttp } from 'fi-http';
import { fiMessageBox } from 'fi-messagebox';
import { capitalize } from 'lodash';

const GUI_FORTICARE_URL = '/gui/forticare';

// Gets string slice between beginStr and endStr
function sliceBySubstring(src, beginStr, endStr, i = 0) {
  let beginIndex = src.indexOf(beginStr, i);
  let endIndex = src.indexOf(endStr, i);
  if (beginIndex < 0 || endIndex < 0) {
    return null;
  }
  return src.slice(beginIndex + beginStr.length, endIndex);
}

// Gets all content surrounded by tag. Returns array of strings.
function getContentByTag(src, tag) {
  const startTag = `<${tag}>`;
  const endTag = `</${tag}>`;
  let res = [];
  let i = 0;
  while (i !== -1) {
    if (i !== 0) {
      i = i + endTag.length; // Add endtag length so the same endtag does not get found
    }
    let slice = sliceBySubstring(src, startTag, endTag, i);
    if (!slice) break;
    res.push(slice);
    i = src.indexOf(endTag, i);
  }
  return res;
}

function forticarePost(method, params) {
  let req = { method, url: GUI_FORTICARE_URL };
  if (params) {
    req = { ...req, params };
  }
  return fiFmgHttp.post(req).then(
    (resp) => {
      if (!resp || !resp[0]) {
        return null;
      }
      return resp[0].data;
    },
    (err) => {
      if (!err || !err[0] || !err[0].data) {
        return Promise.reject(err);
      }
      return Promise.reject(err[0].data);
    }
  );
}

function capitalizeCountry(countryStr = '') {
  const skippedWords = ['d', 's', 'of', 'and', 'the'];
  return countryStr
    .toLowerCase()
    .replace('&amp;', '&')
    .replace(/\w+/g, (l) => (skippedWords.includes(l) ? l : capitalize(l)));
}

function _fetchCountryChoices() {
  return forticarePost('getOrgList')
    .then((resp) => {
      if (!resp) {
        return [];
      }
      // Data looks like this:
      // 2|4|AFGHANISTAN
      // 2|248|ALAND ISLANDS
      // 2|8|ALBANIA
      // 2|12|ALGERIA
      // First token is '2' for countries
      const contentString = getContentByTag(resp, 'OrganizationListResult')[0];
      return !contentString
        ? []
        : contentString
            .split('\n')
            .map((str) => str.split('|'))
            // Filter for countries with '2' in first token
            .filter((tokens) => tokens[0] === '2')
            // Convert into fi-select format
            .map((tokens) => ({
              id: tokens[1],
              text: capitalizeCountry(tokens[2]),
            }));
    })
    .catch((err) => {
      let msg = err?.message;
      if (msg) {
        fiMessageBox.show(msg, 'danger');
      }
      return [];
    });
}

function _fetchResellerChoices(country) {
  return forticarePost('getResellerList', {
    country: parseInt(country),
  }).then((resp) => {
    if (!resp) {
      return [];
    }
    // Data looks like this:
    // <ResellerListResult>-2|↵-1|Unknown↵-3|Other</ResellerListResult>
    const resellerList = getContentByTag(resp, 'ResellerListResult')[0];
    return !resellerList
      ? []
      : resellerList
          .split('\n')
          .map((str) => str.split('|'))
          .map((pair) => ({
            id: pair[0],
            text: pair[1].replace(/&amp;/gi, '&'),
          }));
  });
}

function _registerExistingUser(data) {
  return forticarePost('registerExistingUser', data).then((resp) => {
    let result = getContentByTag(resp, 'Result')[0];
    if (result === 'error') {
      let errorMsgs = getContentByTag(resp, 'Error');
      // Remove the cryptic "Successfully" message from Forticare API when password is wrong
      errorMsgs = errorMsgs.filter((msg) => msg !== 'Successfully');
      return Promise.reject(errorMsgs.join('; '));
    } else if (result === 'ok') {
      return Promise.resolve();
    } else {
      return Promise.reject(gettext('Unknown error.'));
    }
  });
}

// Register VM/VMS contract
function _registerExistingContract(account, password, registrationCode) {
  return forticarePost('registerExistingContract', {
    account,
    password,
    registrationCode,
  });
}

function _loginFgtCloud(data) {
  return forticarePost('loginFgtCloud', data);
}

// get if remote access is enabled/disabled
function _getRemoteAccess() {
  return forticarePost('getRemoteAccess', {});
}

// get if remote access is enabled/disabled
function _setRemoteAccess(enable) {
  return forticarePost('setRemoteAccess', {
    enable,
  });
}

export const fiForticareRegistrationService = {
  fetchCountryChoices: _fetchCountryChoices,
  fetchResellerChoices: _fetchResellerChoices,
  registerExistingUser: _registerExistingUser,
  registerExistingContract: _registerExistingContract,
  loginFgtCloud: _loginFgtCloud,
  getRemoteAccess: _getRemoteAccess,
  setRemoteAccess: _setRemoteAccess,
};
