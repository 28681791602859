import { NwProInputRow, NwProHeader, NwProBody, NwProLine } from 'rc_layout';
import {
  FmkErrorSpan,
  FmkPassword,
  FmkSwitch,
  FmkInput,
  FmkSSelect2,
  FmkTimePicker,
  FmkFooter,
  FmkFieldCheckboxArray,
} from 'rc_form';
import { useEffect, useState } from 'react';
import {
  get,
  isArray,
  isEmpty,
  isNil,
  isNumber,
  map,
  omit,
  transform,
} from 'lodash';
import { fiFmgHttp } from 'fi-http';
import { Formik } from 'formik';
import { ProForm, ProTable, ProToolkit } from '@fafm/neowise-pro';
import { showErrorMessage } from 'fi-messagebox';
import { serverNowMoment, getTimeZoneHint } from 'fi-datetime';
import { fiSysConfig } from 'fi-session';

const { Section, SubSection } = ProForm;

const weekDays = [
  { id: MACROS.SYS.WEEK_DAY_MONDAY, text: gettext('Monday'), isoWeekday: 1 },
  { id: MACROS.SYS.WEEK_DAY_TUESDAY, text: gettext('Tuesday'), isoWeekday: 2 },
  {
    id: MACROS.SYS.WEEK_DAY_WEDNESDAY,
    text: gettext('Wednesday'),
    isoWeekday: 3,
  },
  {
    id: MACROS.SYS.WEEK_DAY_THURSDAY,
    text: gettext('Thursday'),
    isoWeekday: 4,
  },
  { id: MACROS.SYS.WEEK_DAY_FRIDAY, text: gettext('Friday'), isoWeekday: 5 },
  {
    id: MACROS.SYS.WEEK_DAY_SATURDAY,
    text: gettext('Saturday'),
    isoWeekday: 6,
  },
  { id: MACROS.SYS.WEEK_DAY_SUNDAY, text: gettext('Sunday'), isoWeekday: 7 },
];

function template({
  initialValues,
  values,
  autoId,
  field,
  renderFields,
  Section,
}) {
  const initialEnabled =
    get(initialValues, field('status')) === MACROS.PM2CAT.PM2_OPT_ENABLE;
  const scheduleEnabled =
    get(values, field('status')) === MACROS.PM2CAT.PM2_OPT_ENABLE;

  const protocols = [
    { id: MACROS.SYS.BACKUP_PROT_FTP, text: 'FTP' },
    { id: MACROS.SYS.BACKUP_PROT_SCP, text: 'SCP' },
    { id: MACROS.SYS.BACKUP_PROT_SFTP, text: 'SFTP' },
  ];

  // here use system timezone, not adom timezone.
  const sysTimeZoneInfo = getTimeZoneHint(
    fiSysConfig.current().timezone.timezonename
  );
  const fields = scheduleEnabled ? (
    <>
      <Section title={gettext('Backup Configuration File to')}>
        <NwProInputRow label={gettext('Server IP/FQDN')}>
          <FmkInput
            required
            maxLength={63}
            automationId={autoId('server')}
            name={field('server')}
            placeholder={gettext('server name / IP:port')}
          />
          <FmkErrorSpan name={field('server')} />
        </NwProInputRow>
        <NwProInputRow label={gettext('Directory')}>
          <FmkInput
            required
            maxLength={255}
            automationId={autoId('directory')}
            name={field('directory')}
          />
          <FmkErrorSpan name={field('directory')} />
        </NwProInputRow>
        <NwProInputRow label={gettext('Protocol')}>
          <FmkSSelect2
            required
            source={protocols}
            automationId={autoId('protocol')}
            name={field('protocol')}
          />
          <FmkErrorSpan name={field('protocol')} />
        </NwProInputRow>
        <NwProInputRow label={gettext('User Name')}>
          <FmkInput
            maxLength={63}
            automationId={autoId('user')}
            name={field('user')}
          />
          <FmkErrorSpan name={field('user')} />
        </NwProInputRow>
        {get(values, field('protocol')) === MACROS.SYS.BACKUP_PROT_SCP ? (
          <NwProInputRow label={gettext('SSH Certificate')}>
            <FmkSSelect2
              required
              source={sshCerts}
              automationId={autoId('cert')}
              name={field('cert')}
              idAttr='name'
              textAttr='name'
            />
            <FmkErrorSpan name={field('cert')} />
          </NwProInputRow>
        ) : (
          <NwProInputRow label={gettext('Password')}>
            <FmkPassword
              maxLength={127}
              automationId={autoId('passwd')}
              name={field('passwd')}
              isEdit
            />
            <FmkErrorSpan name={field('passwd')} />
          </NwProInputRow>
        )}
      </Section>

      <Section title={gettext('Backup Frequency')}>
        <NwProInputRow label={gettext('Days')}>
          <FmkFieldCheckboxArray
            required
            items={weekDays}
            automationId={autoId('week_days')}
            name={field('week_days')}
          />
          <FmkErrorSpan name={field('week_days')} />
        </NwProInputRow>

        {/* hh:mm:ss */}
        <NwProInputRow label={gettext('Time')}>
          <FmkTimePicker
            required
            has-second
            automationId={autoId('time')}
            name={field('time')}
            hoist
          />
          <FmkErrorSpan name={field('time')} />
        </NwProInputRow>
        <NwProInputRow>
          <NwProLine>
            <div>{sysTimeZoneInfo}</div>
          </NwProLine>
        </NwProInputRow>
      </Section>

      <Section title={gettext('Encryption')}>
        <NwProInputRow label={gettext('Password')}>
          <FmkPassword
            maxLength={63}
            automationId={autoId('crptpasswd')}
            name={field('crptpasswd')}
            isEdit={initialEnabled}
            validate={(value) => {
              if (!value) {
                /**
                 * #0954886: need to force admin to set encrypt password,
                 * but we do not have flag to indicate whether password was previously
                 * set.
                 * so force the admin to update password when change from disabled to enable.
                 */
                if (initialEnabled && value === undefined) {
                  return;
                }
                return gettext('This field is required.');
              }
            }}
          />
          <FmkErrorSpan name={field('crptpasswd')} />
        </NwProInputRow>

        <NwProInputRow label={gettext('Confirm Password')}>
          <FmkPassword
            maxLength={63}
            automationId={autoId('gui_confirm_crptpasswd')}
            name={field('gui_confirm_crptpasswd')}
            validate={(pass = '') => {
              if (pass !== get(values, field('crptpasswd'), '')) {
                return gettext('The passwords do not match.');
              }
            }}
          />
          <FmkErrorSpan name={field('gui_confirm_crptpasswd')} />
        </NwProInputRow>
      </Section>
    </>
  ) : null;

  const statusToggle = (children) => (
    <FmkSwitch
      name={field('status')}
      automationId={autoId('status')}
      trueValue={MACROS.PM2CAT.PM2_OPT_ENABLE}
      falseValue={MACROS.PM2CAT.PM2_OPT_DISABLE}
    >
      {children}
    </FmkSwitch>
  );
  return renderFields({ fields, statusToggle });
}

function ScheduleBackupStandalone(props) {
  const renderFields = ({ statusToggle, fields }) => (
    <>
      <Section>
        <NwProInputRow label={gettext('Enable Schedule Backup')}>
          {statusToggle(null)}
        </NwProInputRow>
      </Section>
      {fields}
    </>
  );
  return template({ ...props, renderFields, Section });
}

export function ScheduleBackupAsSection(props) {
  const renderFields = ({ statusToggle, fields }) => (
    <Section title={statusToggle(gettext('Automatic System Backup'))}>
      {fields}
    </Section>
  );
  return template({ ...props, renderFields, Section: SubSection });
}

export function ScheduleBackup({ tabControl }) {
  const $opener = ProToolkit.useOpener();
  const autoId = (field) => `schedule_backup:${field}`;

  const [initialValues, setInitialValues] = useState();
  useEffect(() => {
    getSchedule().then((backup) => setInitialValues({ backup }));
  }, []);

  const handleSubmit = async ({ backup }) => {
    await setSchedule(backup, initialValues.backup)
      .then((diff) => {
        $opener.resolve(diff);
      })
      .catch((ex) => {
        showErrorMessage(ex);
      });
  };

  const handleClose = () => {
    $opener.reject();
  };

  const dataReady = !!initialValues;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values }) => (
        <>
          <NwProHeader>{gettext('Backup System')}</NwProHeader>
          <NwProBody
            className={
              dataReady ? undefined : 'tw-h-full tw-w-full tw-flex tw-flex-col'
            }
          >
            {tabControl}
            {dataReady ? (
              <ScheduleBackupStandalone
                initialValues={initialValues}
                values={values}
                autoId={autoId}
                field={(attr) => `backup.${attr}`}
              />
            ) : (
              <ProTable.LoadingSpinner loading />
            )}
          </NwProBody>
          <FmkFooter
            canWrite={dataReady}
            getAutoId={autoId}
            onCancel={handleClose}
          />
        </>
      )}
    </Formik>
  );
}
ScheduleBackup.displayName = 'ScheduleBackup';

export async function getSchedule() {
  const resp = await fiFmgHttp.forward({
    method: 'get',
    params: [
      {
        url: 'cli/global/system/backup/all-settings',
      },
    ],
  });
  const data = resp[0].data;

  const values = {
    ...omit(data, 'obj flags'),
    cert: data.cert === '' ? null : data.cert, // SSelect only recognizes null as empty
    week_days: isNumber(data.week_days)
      ? weekDays.filter(({ id }) => data.week_days & id).map(({ id }) => id)
      : [],
    // use `undefined` so if user uses empty string, we still find that they are changed
    crptpasswd: undefined,
    passwd: undefined,
  };

  // console.log({ data, values });
  return values;
}

export async function setSchedule(data, initialValues) {
  let diff = transform(
    initialValues,
    (result, value, key) => {
      if (data[key] !== value) {
        result[key] = data[key];
      }
    },
    {}
  );

  if (isEmpty(diff)) {
    return diff;
  }
  const { week_days, protocol } = diff;
  if (isArray(week_days)) {
    diff.week_days = week_days.reduce((result, day) => {
      return result | day;
    }, 0);
  }
  if (!isNil(protocol) && protocol !== MACROS.SYS.BACKUP_PROT_SCP) {
    diff.cert = ''; // unset
  }

  // console.log(diff);
  try {
    await fiFmgHttp.query({
      method: 'set',
      params: [
        {
          url: 'cli/global/system/backup/all-settings',
          data: diff,
        },
      ],
    });
    return diff;
  } catch (ex) {
    throw ex.data.result[0].status;
  }
}

async function sshCerts() {
  const resp = await fiFmgHttp.forward({
    method: 'get',
    params: [
      {
        url: 'cli/global/system/certificate/ssh',
        fields: ['name'],
      },
    ],
  });
  const data = resp[0].data;
  return data;
}

/**
 *
 * @param {number[]} week_days MACROS.SYS.WEEK_DAY_MONDAY
 * @param {string} time hh:mm:ss
 * @returns {[import('moment').Moment, import('moment').Moment]} [next, serverNow]
 */
export function serverClosestFutureMoment(week_days, time) {
  const [hour, minute, second] = time.split(':');
  const isoByDays = transform(
    weekDays,
    (result, { id, isoWeekday }) => {
      result[id] = isoWeekday;
    },
    {}
  );

  const serverNow = serverNowMoment();
  const sequentialMomentThisWeek = map(week_days, (day) => isoByDays[day])
    .filter(isNumber)
    .sort((a, b) => a - b)
    .map((iso) => {
      return serverNow
        .clone()
        .isoWeekday(iso)
        .set({ hour, minute, second, millisecond: 0 });
    });

  const closetFutureThisWeek = sequentialMomentThisWeek.find((moment) => {
    return moment.unix() > serverNow.unix();
  });

  const result = closetFutureThisWeek
    ? closetFutureThisWeek
    : sequentialMomentThisWeek[0].add(1, 'weeks');

  // console.log(result.format('dddd yyyy-MM-DD HH:mm:ss'));
  // console.log(current.format('dddd yyyy-MM-DD HH:mm:ss'));

  return [result, serverNow];
}
