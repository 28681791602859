import React, { useRef, useMemo, useEffect } from 'react';
import { formatBytes, formatBandwidth } from 'kit-number';
import { getColorPalette } from 'echarts-themes';
import { kbToBytes } from './utils';

const MonitorLineChart = ({
  data,
  NumLoadsToKeep,
  DataInterval,
  theme,
  LineChartTypes,
  lineChartType,
  ioSummaryArr,
  isLoading,
}) => {
  const lineRef = useRef();
  const prevColorMap = useRef({});

  useEffect(() => {
    if (lineRef.current) {
      lineRef.current.showLoading = isLoading;
    }
  }, [isLoading]);

  const chartLabels = useMemo(() => {
    let res = [gettext('Now')];
    let sec = 0;
    while (sec < 60) {
      sec += DataInterval;
      res.unshift(`${sec} seconds ago`);
    }
    res.unshift('');
    return res;
  }, [NumLoadsToKeep]);

  const lineChartData = useMemo(() => {
    if (
      (!ioSummaryArr.length && lineChartType === LineChartTypes.Disk) ||
      (!data.length && lineChartType !== LineChartTypes.Disk)
    ) {
      return undefined;
    }

    let res = [chartLabels];

    if (lineChartType === LineChartTypes.Disk) {
      let read = [gettext('Read')];
      let write = [gettext('Write')];
      ioSummaryArr.forEach((x, idx) => {
        let startIdx = NumLoadsToKeep - ioSummaryArr.length + 1;
        read[startIdx + idx] = kbToBytes(x.total_disk_read);
        write[startIdx + idx] = kbToBytes(x.total_disk_write);
      });
      res.push(read, write);
    } else {
      let dataMap = {};
      data.forEach((d, idx) => {
        d.forEach((process) => {
          if (!dataMap[process.pid]) {
            dataMap[process.pid] = [`${process.cmd} - ${process.pid}`];
          }
          let val = 0;
          if (lineChartType === LineChartTypes.CPU) {
            val = process.cpu_pct;
          } else if (lineChartType === LineChartTypes.MemoryPercentage) {
            val = process.mem_pct;
          } else if (lineChartType === LineChartTypes.Memory) {
            val = process._res_bytes;
          }
          let startIdx = NumLoadsToKeep - data.length + 1;
          dataMap[process.pid][startIdx + idx] = val;
        });
      });
      res = res.concat(Object.values(dataMap));
    }

    return {
      source: res,
    };
  }, [data, lineChartType, chartLabels]);

  const Colors = useMemo(() => {
    return getColorPalette(theme);
  }, [theme]);

  const colorMap = useMemo(() => {
    if (lineChartData) {
      let colorMap = {};
      lineChartData.source.slice(1).forEach((x, idx) => {
        let key = x[0];
        colorMap[key] =
          prevColorMap.current[key] || Colors[idx % Colors.length];
      });
      prevColorMap.current = colorMap;
      return colorMap;
    }
  }, [lineChartData]);

  const chartOptions = useMemo(() => {
    if (!lineChartData) {
      return {};
    }
    return {
      animation: false,
      legend: { show: false },
      grid: {
        top: 20,
        bottom: 40,
        left: 70,
        right: 10,
      },
      tooltip: _getTooltipOption(lineChartType, LineChartTypes),
      color: lineChartData.source.slice(1).map((x) => colorMap[x[0]]),
      yAxis: _getYAxisOption(lineChartType, LineChartTypes),
      xAxis: _getXAxisOption(lineChartData),
      series: _getseriesOption(lineChartData),
    };
  }, [lineChartData, lineChartType, LineChartTypes]);

  useEffect(() => {
    if (lineChartData && lineRef.current) {
      lineRef.current.option = chartOptions;
    }
  }, [lineChartData, lineChartType]);

  return (
    <div className='tw-h-full tw-w-full'>
      <nw-echarts ref={lineRef} theme={theme} notMerge></nw-echarts>
    </div>
  );
};

const _getTooltipOption = (lineChartType, LineChartTypes) => {
  return {
    trigger: 'axis',
    confine: true,
    appendToBody: true,
    order: 'valueDesc',
    formatter: (params) => {
      return params
        .filter((param) => {
          if (lineChartType === LineChartTypes.Disk) {
            return true;
          }
          let val = param.value || 0;
          return !!parseFloat(val);
        })
        .sort((a, b) => {
          let valA = a.value || 0;
          let valB = b.value || 0;
          return valB - valA;
        })
        .reduce((res, param) => {
          let val = param.value || 0;
          let formattedVal = val;
          if (lineChartType === LineChartTypes.CPU) {
            formattedVal = `${val}%`;
          } else if (lineChartType === LineChartTypes.MemoryPercentage) {
            formattedVal = formattedVal = `${val}%`;
          } else if (lineChartType === LineChartTypes.Memory) {
            formattedVal = formatBytes(val);
          } else if (lineChartType === LineChartTypes.Disk) {
            formattedVal = formatBandwidth(val);
          }
          return `${res} <div>${param.marker} ${param.seriesName}: ${formattedVal}</div>`;
        }, `<div>${params[0]?.axisValueLabel || ''}</div>`);
    },
  };
};

const _getXAxisOption = (lineChartData) => {
  return {
    type: 'category',
    gridIndex: 0,
    data: lineChartData.source[0].slice(1),
  };
};

const _getYAxisOption = (lineChartType, LineChartTypes) => {
  return {
    gridIndex: 0,
    max:
      lineChartType === LineChartTypes.CPU ||
      lineChartType === LineChartTypes.MemoryPercentage
        ? 100
        : () => null,
    axisLabel: {
      formatter: (value) => {
        let formattedVal = value;
        if (
          lineChartType === LineChartTypes.CPU ||
          lineChartType === LineChartTypes.MemoryPercentage
        ) {
          formattedVal = value + '%';
        } else if (lineChartType === LineChartTypes.Memory) {
          formattedVal = formatBytes(value);
        } else if (lineChartType === LineChartTypes.Disk) {
          formattedVal = formatBandwidth(value);
        }
        return formattedVal;
      },
    },
  };
};

const _getseriesOption = (lineChartData) => {
  return lineChartData.source.slice(1).map((x) => {
    return {
      name: x[0],
      type: 'line',
      data: x.slice(1),
      showSymbol: false,
      step: false,
      smooth: true,
      xAxisIndex: 0,
      yAxisIndex: 0,
      connectNulls: true,
    };
  });
};

export default MonitorLineChart;
