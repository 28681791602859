import { useRef } from 'react';

export type CacheHookType = {
  view: () => any;
  get: (key: string, defaultValue?: any) => any;
  set: (key: string, value: any) => void;
  clear: () => void;
  delete: (key: string) => void;
};

export const useCache = (): CacheHookType => {
  const cache = useRef<{ [key: string]: any }>({});

  return {
    view: () => cache.current,
    get: (key, defaultValue = null) => cache.current[key] ?? defaultValue,
    set: (key, value) => (cache.current[key] = value),
    clear: () => (cache.current = {}),
    delete: (key) => {
      if (cache.current[key]) delete cache.current[key];
    },
  };
};
