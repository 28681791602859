//  padding leading 0s to number
//  (1).padding0(3)  => "001"
//  (10).pading0(3)  => "010"
//  (100).pading0(3)  => "100"
Number.prototype.padding0 = function (size) {
  var s = String(this);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};
