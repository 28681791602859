import { call, select, take } from 'redux-saga/effects';

import { getAppTreeMap } from './selectors';
import { setAppMenus } from './slice';

export function runIfAvailable(checker, genFn) {
  return function* (...args) {
    // must wait until the tree is ready.
    const appTreeMap = yield select(getAppTreeMap);
    if (!appTreeMap) {
      yield take(setAppMenus.type);
    }
    const state = yield select();
    if (checker(state)) {
      // must return the result of the generator in case it's used by
      // callPromiseAction
      try {
        return yield call(genFn, ...args);
      } catch (e) {
        console.error(`Calling ${genFn.name} was rejected with error ${e}.`);
      }
    }
    // return undefined implicitly if the checker condition is not met.
  };
}
