import React, { useState, useCallback } from 'react';
import { fiHttpGet } from 'fi-http';
import { useValidEffect } from 'rh_util_hooks';
import { fiSysConfig } from 'fi-session';
import { UrlService } from 'fi-url';
import $ from 'jquery';
export const useCloudNav = () => {
  const [sections, setSections] = useState([]);

  const getCloudPortalData = (key) => {
    if (!fiSysConfig.isFtntCloud()) {
      return Promise.resolve([]);
    }
    return fiHttpGet(UrlService.UTIL.FORTICLOUD_PORTAL_DATA, {
      params: { key },
    });
  };

  const processForticloudMetadata = (resp) => {
    let headersData = resp.reduce(
      (data, currItem) => {
        if (!data.headerToApps[currItem.section_header]) {
          data.headerToApps[currItem.section_header] = [];
          data.order.push(currItem.section_header);
        }

        data.headerToApps[currItem.section_header].push(currItem);
        return data;
      },
      {
        headerToApps: {},
        order: [],
      }
    );

    return headersData.order.map((headerName) => ({
      name: headerName,
      items: headersData.headerToApps[headerName],
    }));
  };

  const onClickItem = (item) => {
    // Require cloud post_params to go to other apps
    return getCloudPortalData('post_params')
      .then((resp) => {
        post_to_url(item.url, JSON.stringify(resp));
      })
      .catch(() => {
        // Cannot get params, but post to the URL anyway to redirect user
        post_to_url(item.url, '');
      });
  };

  const post_to_url = (path, post_params) => {
    // Using form action to post to URL
    // This will need change when implement some form action CSP restrictions
    let field = $('<input></input>');
    field.attr('type', 'hidden');
    field.attr('name', 'h_key');
    field.attr('value', post_params);

    let form = $('<form></form>');
    form.attr('method', 'post');
    form.attr('action', path);
    form.attr('dataType', 'json');
    form.append(field);
    $(document.body).append(form);
    form.submit();
  };

  useValidEffect((getIsValid) => {
    const init = async () => {
      try {
        const resp = await getCloudPortalData('portals');
        const _sections = processForticloudMetadata(resp);
        if (getIsValid()) setSections(_sections);
      } catch (error) {
        // do nothing
      }
    };

    init();
  }, []);

  const getCloudNav = useCallback(() => {
    if (!sections.length > 0) {
      return null;
    }

    return (
      <div className='tw-max-h-[calc(100vh-28rem)] tw-min-h-[8rem] tw-overflow-y-auto tw-cursor-default tw-text-sm tw-font-semibold'>
        {sections.map((section) => (
          <div key={section.name}>
            <span className='tw-block tw-text-neutral-800 tw-bg-neutral-100 tw-py-1.5 tw-text-center tw-uppercase tw-text-xs tw-font-semibold'>
              {section.name}
            </span>
            <div className='tw-grid tw-gap-4 tw-grid-cols-4 tw-py-1.5 tw-px-2.5'>
              {section.items.map((item) => (
                <div
                  className='tw-block tw-w-20 tw-py-1 tw-text-center tw-whitespace-normal tw-transition tw-border-2 tw-border-transparent tw-border-solid tw-rounded-md tw-cursor-pointer hover:tw-border-neutral hover:tw-bg-neutral-200 tw-text-neutral-700'
                  key={item.app_id}
                  onClick={() => onClickItem(item)}
                  title={item.description}
                >
                  <img
                    className='tw-w-8 tw-h-8'
                    src={'data:image/svg+xml;base64,' + item.image_content}
                  ></img>
                  <br />
                  {item.display_name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }, [sections, onClickItem]);

  return {
    sections,
    getCloudNav,
  };
};
