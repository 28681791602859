import { fiWorkspaceDataSelector, fiWorkspaceRequest } from 'fistore';

import {
  _currentAdom,
  _hasLocked,
  _hasLockedByMe,
  _isLockedByMe,
  _isLockedByOther,
  _isLocked,
  _isUnlock,
  _isDirtyByMe,
  _hasDirty,
  _hasDirtyByMe,
  _getLockMsg,
  _selector_many,
} from './util';

export const devMapDispatchToProps = (dispatch) => {
  return {
    init: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.load_workspace_devices(
        dispatch,
        adom.oid || adom
      );
    },
    lock: (dev, adom = _currentAdom()) => {
      return fiWorkspaceRequest.lock_workspace_device(
        dispatch,
        adom.oid || adom,
        dev.oid || dev
      );
    },
    unlock: (
      dev,
      adom = _currentAdom(),
      unlock_dirty = false,
      unlock_other = false
    ) => {
      return fiWorkspaceRequest.unlock_workspace_device(
        dispatch,
        adom.oid || adom,
        dev.oid || dev,
        unlock_dirty,
        unlock_other
      );
    },
    save: (dev, adom = _currentAdom(), save_n_unlock = false) => {
      return fiWorkspaceRequest.save_workspace_device(
        dispatch,
        adom.oid || adom,
        dev.oid || dev,
        save_n_unlock
      );
    },
  };
};

export const devMapStateToProps = (state) => {
  // do not put inline functions if may cause shadow compare fail?
  return {
    devs: fiWorkspaceDataSelector.get_workspace_devs(state),
    get: getDevLockInfo,
  };
};

export function getAllDevLockInfo(adom = _currentAdom()) {
  return {
    devs: _selector_many(this.devs, adom) || {},
    hasLocked: _hasDevLocked,
    hasLockedByMe: _hasDevLockedByMe,
    isLockedByMe: _isDevLockedByMe,
    isLockedByOther: _isDevLockedByOther,
    isLocked: _isDevLocked,
    isUnlock: _isDevUnlocked,
    isDirty: _isDevDirty,
    hasDirty: _hasDevDirty,
    hasDirtyByMe: _hasDevDirtyByMe,
    getLockedDevices: _getLockedDevices,
    getDevicesLockedByMe: _getDevicesLockedByMe,
    getDevicesDirtyByMe: _getDevicesDirtyByMe,
    lockMessage: _getLockMsg,
  };
}

export function getDevLockInfo(dev, adom = _currentAdom()) {
  return {
    dev: _selector_one_dev(this.devs, adom, dev) || {},
    isLockedByMe: _devLockedByMe,
    isLockedByOther: _devLockedByOther,
    isLocked: _devLocked,
    isUnlock: _devUnlocked,
    isDirty: _devDirty,
    lockMessage: _getLockMsg,
  };
}

function _hasDevLocked() {
  return _hasLocked(this.devs);
}
function _hasDevLockedByMe() {
  return _hasLockedByMe(this.devs);
}
function _isDevLockedByMe(devOid) {
  return _isLockedByMe(this.devs[devOid]);
}
function _isDevLockedByOther(devOid) {
  return _isLockedByOther(this.devs[devOid]);
}
function _isDevLocked(devOid) {
  return _isLocked(this.devs[devOid]);
}
function _isDevUnlocked(devOid) {
  return _isUnlock(this.devs[devOid]);
}
function _isDevDirty(devOid) {
  return _isDirtyByMe(this.devs[devOid]);
}
function _hasDevDirty() {
  return _hasDirty(this.devs);
}
function _hasDevDirtyByMe() {
  return _hasDirtyByMe(this.devs);
}
function _getLockedDevices() {
  let data = this.devs || {};
  let arr = [];
  for (let prop in data) {
    if (_isLocked(data[prop])) {
      arr.push({
        oid: prop,
        ...data[prop],
      });
    }
  }

  return arr;
}
function _getDevicesLockedByMe() {
  let data = this.devs || {};
  let arr = [];
  for (let prop in data) {
    if (_isLockedByMe(data[prop])) {
      arr.push({
        oid: parseInt(prop, 10),
        ...data[prop],
      });
    }
  }

  return arr;
}
function _getDevicesDirtyByMe() {
  let data = this.devs || {};
  let arr = [];
  for (let prop in data) {
    if (_isDirtyByMe(data[prop])) {
      arr.push({
        oid: parseInt(prop, 10),
        ...data[prop],
      });
    }
  }

  return arr;
}

function _devLockedByMe() {
  return _isLockedByMe(this.dev);
}
function _devLockedByOther() {
  return _isLockedByOther(this.dev);
}
function _devLocked() {
  return _isLocked(this.dev);
}
function _devUnlocked() {
  return _isUnlock(this.dev);
}
function _devDirty() {
  return _isDirtyByMe(this.dev);
}

function _selector_one_dev(data, adom, dev) {
  return (
    _selector_many(data, adom)[dev.oid || dev] || {
      oid: dev.oid,
      name: dev.name || '',
      lock: MACROS.SYS.LOCK_STATE_UNLOCKED,
      dirty: 0,
    }
  );
}
