import { flatui_proxy } from '../workspace_res/base';

export function fetch_device_groups({ adomName }) {
  return flatui_proxy({
    method: 'get',
    url: `/gui/adom/${adomName}/groups/chunk`,
    params: {
      fields: ['base'],
    },
  });
}
export function fetch_device_groups_memb({ adomName }) {
  return flatui_proxy({
    method: 'get',
    url: `/gui/adom/${adomName}/groups/chunk`,
    params: {
      fields: ['memb'],
    },
  });
}
export function fetch_device_group({ adomOid, grpOid }) {
  return flatui_proxy({
    method: 'get',
    url: `/gui/adoms/${adomOid}/groups/${grpOid}`,
    params: {
      fields: ['base'],
    },
  });
}
export function fetch_device_group_memb({ adomOid, grpOid }) {
  return flatui_proxy({
    method: 'get',
    url: `/gui/adoms/${adomOid}/groups/${grpOid}`,
    params: {
      fields: ['memb'],
    },
  });
}
export function fetch_default_groups({ adomOid }) {
  return flatui_proxy({
    method: 'get',
    url: `/gui/adoms/${adomOid}/default-groups`,
    params: {},
  });
}
