import { ProLego } from '@fafm/neowise-pro';
import { useField } from 'formik';
import React, { useCallback, forwardRef } from 'react';
import { callAll, validateAny, validators } from './util/util';

export const FmkSSelect2 = forwardRef(
  (
    {
      name,
      onChange,
      required,
      validate,
      'automation-id': propAutoId,
      automationId,
      ...rest
    },
    ref
  ) => {
    const [, { value }, { setValue, setTouched }] = useField({
      name,
      validate: validateAny(
        validators.required(required, (fieldValue) => {
          if (fieldValue == null) {
            return true;
          }
          if (rest.multipleSelect) {
            return Array.isArray(fieldValue) && !fieldValue.length;
          }
        }),
        validate
      ),
    });

    const setFieldValOnChange = (id) => {
      setTouched(true, false);
      setValue(id);
    };

    const _onChange = useCallback(callAll(setFieldValOnChange, onChange), [
      onChange,
      name,
    ]);

    return (
      <ProLego.SSelect
        name={name}
        value={value?.val ? value.val : value}
        onChange={_onChange}
        ref={ref}
        automationId={propAutoId || automationId}
        {...rest}
      />
    );
  }
);

FmkSSelect2.displayName = 'FmkSSelect2';
