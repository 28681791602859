import * as actions from './actions';

export default function reducer(state = {}, { type, payload }) {
  const mergeState = (partial) => ({ ...state, ...partial });

  switch (type) {
    case actions.setFabricCustomMenu.type:
      return mergeState({ fabricCustomMenu: payload });
    default:
      return state;
  }
}
