import { isFunction } from 'lodash';

type Callback = (...args: any[]) => void;

interface ObserverOptions {
  onSubscribe?: () => void;
  onNotify?: () => void;
  onUnsubscribe?: (key?: string) => void;
}

export const makeObservers = ({
  onSubscribe,
  onNotify,
  onUnsubscribe,
}: ObserverOptions = {}) => {
  let _observers = new Map<string, Callback>();
  return {
    //@deprecated
    //should call necessary functions on adom switch in a useeffect
    subscribe: (callback: Callback, key?: string) => {
      // make sure key is unique to prevent from subscribing the same function
      const observerKey = key || callback.toString();
      _observers.set(observerKey, callback);
      if (isFunction(onSubscribe)) {
        onSubscribe();
      }

      // unsubscribe
      return () => {
        _observers.delete(observerKey);
        if (isFunction(onUnsubscribe)) {
          onUnsubscribe(observerKey);
        }
      };
    },
    notify: async (...args: any[]) => {
      if (!_observers) return;
      if (isFunction(onNotify)) {
        const prom = new Promise((resolve) => resolve(onNotify()));
        await prom;
      }
      _observers.forEach((_observer) => {
        if (isFunction(_observer)) {
          // args are provided to the callback functions in subscribe method
          _observer(...args);
        }
      });
    },
    unsubscribe: () => {
      _observers = new Map();
      if (isFunction(onUnsubscribe)) {
        onUnsubscribe();
      }
    },
  };
};
