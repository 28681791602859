import ReactDOMServer from 'react-dom/server';
import { NwIcon } from '@fafm/neowise-core';

export {
  formatIntfChoice,
  formatIntfChoiceHTML,
  formatIntfSelection,
  formatIntfSelectionHTML,
  getInterfaceSelectFormatter,
};

const getInterfaceText = (
  item = {},
  config = { textAttr: 'text', aliasAttr: 'alias' }
) => {
  const { aliasAttr, textAttr } = config;
  const interfaceAlias = item?.[aliasAttr] || '';
  const interfaceText = item?.[textAttr];
  return interfaceAlias
    ? `${interfaceText} (${interfaceAlias})`
    : interfaceText;
};

function formatIntfChoice(item) {
  return (
    <div className='tw-leading-4 obj-box'>
      <div className='obj-name-fix'>
        <span title={item?.ititle}>
          <NwIcon
            name={item?.icon?.name}
            className={`${item?.icon?.classes} tw-mr-1`}
            library={item?.icon?.library}
          ></NwIcon>
          {getInterfaceText(item)}
        </span>
      </div>

      <div className='obj-detail'>{item?.detail || item?.ip}</div>
    </div>
  );
}

function formatIntfChoiceHTML(...args) {
  return ReactDOMServer.renderToString(formatIntfChoice(...args));
}

function formatIntfSelection(item) {
  return (
    <span className={'tw-flex tw-items-center'}>
      <NwIcon
        name={item?.icon?.name}
        className={`${item?.icon?.classes} tw-mr-1`}
        library={item?.icon?.library}
        label={item?.ititle}
      ></NwIcon>
      {getInterfaceText(item)}
    </span>
  );
}

function formatIntfSelectionHTML(...args) {
  return ReactDOMServer.renderToString(formatIntfSelection(...args));
}

function getInterfaceSelectFormatter() {
  return {
    formatChoiceHTML: formatIntfChoiceHTML,
    formatSelectedHTML: formatIntfSelectionHTML,
    itemHeight: MACROS.USER.SYS.SSELECT_DETAIL_ITEM_HEIGHT,
    searchFn: (item, searcher) => {
      const text = getInterfaceText(item);
      return searcher(text);
    },
  };
}
