import { fiFmgHttp } from 'fi-web/fi-http';
import { get } from 'lodash';

const getRespData = (resp) => get(resp, '0.data', {});

export const getSysInfo = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/info',
    })
    .then(getRespData);
};

export const getSysLicense = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/license',
    })
    .then(getRespData);
};

/**
 * @returns
 * Account id and company of forticare license.
 * This info can also also obtained from sysConfig.forticare_license
 */
export const getFortiCareLicense = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/forticarelicense',
    })
    .then(getRespData);
};

export const getSysFaceplate = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/faceplate',
    })
    .then(getRespData);
};

export const getWidgetList = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/widgets',
      params: {
        reset: false,
      },
    })
    .then(getRespData);
};

export const getWidgetCfg = (type) => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: `/gui/sys/widget/${type}`,
    })
    .then(getRespData);
};

export const saveWidgetCfg = (type, data) => {
  return fiFmgHttp
    .post({
      method: 'post',
      url: `/gui/sys/widget/${type}`,
      params: data,
    })
    .then(getRespData);
};

export const getLicenseKey = (type) => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: `/gui/sys/licensekey/${type}`,
    })
    .then(getRespData);
};

export const saveLicenseKey = (type, data) => {
  return fiFmgHttp
    .post({
      method: 'post',
      url: `/gui/sys/licensekey/${type}`,
      params: data,
    })
    .then(getRespData);
};

export const checkSDNSLicense = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/sdns-file',
    })
    .then(getRespData);
};

export const setFgdServer = (data) => {
  return fiFmgHttp
    .post({
      method: 'post',
      url: '/gui/sys/fgdserver',
      params: data,
    })
    .then(getRespData);
};

export const getSysTimeSettings = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/timesetting',
    })
    .then(getRespData);
};
export const saveSysTimeSettings = (data) => {
  return fiFmgHttp
    .post({
      method: 'post',
      url: '/gui/sys/timesetting',
      params: data,
    })
    .then(getRespData);
};

export const getResourcesRealtime = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/resource/realtime',
    })
    .then(getRespData);
};

export const getResourcesHistory = (params) => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/resource/history',
      params,
    })
    .then(getRespData);
};

export const getCPUCores = () => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/cores',
    })
    .then(getRespData);
};

export const getAlertMessage = (showAll) => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/alertconsolemessage',
      params: {
        showAll: !!showAll,
      },
    })
    .then(getRespData);
};

export const clearAlertMessage = () => {
  return fiFmgHttp
    .post({
      method: 'post',
      url: '/gui/sys/alertconsolemessage',
    })
    .then(getRespData);
};

export const getLogFwdRate = (params) => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/log-fwd-rate',
      params,
    })
    .then(getRespData);
};

export const getLogRecieveMonitor = (params) => {
  return fiFmgHttp
    .post({
      method: 'get',
      url: '/gui/sys/monitor',
      params,
    })
    .then(getRespData);
};
