export {
  fiFAPJsonAPIUrls,
  fiFAPEventNames,
  fiFAPWifiTemplatesTxt,
  fiFAPSubObjSyntax,
};

const fiFAPJsonAPIUrls = {
  dev_dhcp: 'pm/config/device/%s/vdom/%s/system/dhcp/server',
  dhcp: 'pm/config/adom/%s/obj/system/dhcp/server',
  sec_exemp_list: 'pm/config/adom/%s/obj/user/security-exempt-list',
  dev_sec_exemp_list: 'pm/config/device/%s/vdom/%s/user/security-exempt-list',
  user_device_access_list: 'pm/config/adom/%s/obj/user/device-access-list',
  dev_user_device_access_list:
    'pm/config/device/%s/vdom/%s/user/device-access-list',
  user_device: 'pm/config/adom/%s/obj/user/device',
  dev_user_device: 'pm/config/device/%s/vdom/%s/user/device',
  dev_wireless_controller: 'pm/config/device/%s/vdom/%s/wireless-controller/%s',
  dev_glb_wireless_controller:
    'pm/config/device/%s/global/wireless-controller/%s',
  wireless_controller: 'pm/config/adom/%s/obj/wireless-controller/%s',
  mpsk_profile: 'pm/config/adom/%s/obj/wireless-controller/mpsk-profile',
  dev_mpsk_profile:
    'pm/config/device/%s/vdom/%s/wireless-controller/mpsk-profile',
  dev_glb_wireless_controller_glb:
    'pm/config/device/%s/global/wireless-controller/global', //mantis 546204, to check whether 'wtp-share' is enabled
  platformlist:
    'pm/config/adom/%s/_data/attropts/wireless-controller/wtp-profile/platform/type',
  platformlist_by_dev:
    'pm/config/device/%s/_data/attropts/wireless-controller/wtp-profile/platform/type',
  devfapsetting: '/pm/config/device/%s/vdom/%s/wireless-controller/setting',
  devfap: '/pm/config/device/%s/vdom/%s/wireless-controller/wtp',
  fap: '/pm/config/adom/%s/obj/wireless-controller/wtp',
  fapgrp: '/pm/config/adom/%s/obj/wireless-controller/wtp-group',
  devfapgrp: '/pm/config/device/%s/vdom/%s/wireless-controller/wtp-group',
  bands: '/gui/adoms/%s/wirelesscontroller/platforms/%s/bands',
  bands_by_dev:
    '/gui/adoms/%s/device/%s/vdom/%s/wirelesscontroller/platforms/%s/bands',
  bjr_support: '/gui/adoms/%s/wirelesscontroller/platforms/%s/bonjour',
  bjr_support_dev:
    '/gui/adoms/%s/device/%s/vdom/%s/wirelesscontroller/platforms/%s/bonjour',
  fap_types_dev: '/gui/adoms/%s/device/%s/vdom/%s/fortiap/types',
  extract_fortiap_model: '/gui/adoms/%s/device/%s/vdom/%s/fortiap/%s/platform',
  countries_list: '/gui/wirelesscontroller/countries/list',
  upgrade: 'um/image/upgrade/ext',
};

const fiFAPEventNames = {
  apGroupListUpdate: 'FI_FAP_GROUP_LIST_UPDATE',
};

const fiFAPWifiTemplatesTxt = {
  PM2CAT_WIFI_PREFIX: 'wireless-controller',
  GROUP_NAME_SSID: 'SSIDs',
  GROUP_NAME_SSID_GRP: 'SSID Groups',
  always: gettext('Always'),
  vap: 'SSID',
  'vap-group': 'SSID Group',
};

const fiFAPSubObjSyntax = {
  'dhcp-server': 'system dhcp server',
  'security-exempt-list': 'user security-exempt-list',
  'system-interface': 'system interface',
};
