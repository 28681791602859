import { fiHttpPost } from 'fi-http';
import { fiAdom } from 'fi-session';

const JSONAPI_URL = '/cgi-bin/module/flatui/json';
export const esp = function (key) {
  if (typeof key !== 'string') {
    return key;
  }
  return key.replace(/\\/g, '\\\\').replace(/\//g, '\\/').replace(/"/g, '\\"');
};
/*
 * action: {string} the command in json api request
 * url: {string | array} the url(s) in json api request
 * keys: {string | array | ''} the key(s) in the url(s), if '', then refer to the whole table
 * data: {object | array} post data, only used in add/update/set actions
 * cfg: {object | array} json api supplementary params other than url and data
 */
const makeJsonApiPostBody = function (action, url, keys, data, cfg) {
  let req = {
    id: 1,
    method: action,
    params: [],
  };
  let params = [];
  if (typeof keys === 'string' || typeof keys === 'number') {
    keys = [keys];
    data = [data];
  }
  let options = [];
  if (cfg) {
    if (cfg.createTask) {
      req['create task'] = { adom: fiAdom.current().name };
    }
    if (cfg.options) {
      options = Array.isArray(cfg.options) ? cfg.options : [cfg.options];
    }
  }

  if (options && Array.isArray(keys)) {
    keys.forEach((ee, ii) => {
      if (typeof ee === 'string') {
        //encode special chars for json api
        if (!options[ii] || !options[ii].noNeedEscape) {
          keys[ii] = esp(ee);
        } else {
          delete options[ii].noNeedEscape;
        }
      }
    });
  }
  if (keys === undefined || (Array.isArray(keys) && keys.length === 0)) {
    params = [{ url: url, ...(options[0] || {}) }];
  } else if (Array.isArray(keys) && keys.length > 0) {
    keys.forEach((ee, ii) => {
      params.push({
        url: url + (ee === '' ? '' : '/' + ee),
        ...(options[ii] || {}),
      });
    });
  }
  if (Array.isArray(data) && data.length > 0 && params.length === data.length) {
    params.forEach((ee, ii) => {
      ee.data = data[ii];
    });
  }
  req.params = [].concat(params);
  return req;
};
const action = function (action, url, keys, data, cfg) {
  return fiHttpPost(
    JSONAPI_URL,
    makeJsonApiPostBody(action, url, keys, data, cfg)
  ).then(
    (oResp) => {
      if (oResp.data.result.length === 1) {
        return oResp.data.result[0];
      }
      return oResp.data;
    },
    (oErr) => {
      const extract = () => {
        if (!oErr) {
          return { status: { message: gettext('Unknown Error.') } };
        }
        if (!oErr.errors) {
          if (!oErr.data || !oErr.data.result) {
            return { status: { message: gettext('Unknown Error.') } };
          }
          if (oErr.data.result.length === 1) {
            return oErr.data.result[0];
          }
          if (oErr.data.result.length > 1) {
            return oErr.data.result;
          }
          return oErr;
        }
        if (oErr.errors.length === 1) {
          return oErr.errors[0];
        }
        return oErr.errors;
      };
      return Promise.reject(extract());
    }
  );
};
export const get = function (url, keys, cfg = undefined) {
  return action('get', url, keys, undefined, cfg);
};
export const getTemplate = function (url) {
  return action('get', url, '', null, {
    options: { 'object template': 1 },
  });
};
export const getSyntax = function (url) {
  return action('get', url, '', null, { options: { option: 'syntax' } });
};
export const set = function (url, keys, data) {
  return action('set', url, keys, data);
};
export const add = function (url, keys, data, cfg = undefined) {
  return action('add', url, keys, data, cfg);
};
export const update = function (url, keys, data, cfg = undefined) {
  return action('update', url, keys, data, cfg);
};
export const exec = function (url, keys, data) {
  return action('exec', url, keys, data);
};
export const isKeyExist = function (url, keys) {
  return get(url, keys).then(
    (resp) => {
      if (resp.status.code === 0) {
        return 1; //retrieve OK
      }
      return -1;
    },
    (err) => {
      if (err.status.code === -3) {
        return 0; //"object does not exist"
      }
      return -1; //handle other
    }
  );
};
//edit call update if key exists, call add if not
export const edit = function (url, keys, data) {
  return isKeyExist(url, keys).then(function (resp) {
    if (resp === 1) {
      return update(url, keys, data);
    } else if (resp === 0) {
      return add(url, keys, data);
    }
  });
};
export const del = function (url, keys, cfg) {
  return action('delete', url, keys, null, cfg || {});
};

export const assembleErrorMsg = function (err) {
  if (err.status.code === undefined) {
    return err.status.message;
  }
  return (
    gettext('Error Code') + ':' + err.status.code + ' - ' + err.status.message
  );
};
