export const FMG_POST_URL = '/cgi-bin/module/flatui_proxy';

export function escapeSlash(str) {
  return str.replace(/\//g, '\\/');
}

// this same as fi-uuid: web_new/static/js/fi-web/fi-uuid/index.js
export function uuid4() {
  // function adapted from https://stackoverflow.com/a/2117523/1003746
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.floor(Math.random() * 16);
    var v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

async function fetchApi(url, fetchOpts) {
  const resp = await fetch(url, fetchOpts);
  const json = await resp.json();
  if (!resp.ok) {
    throw json;
  }
  return json;
}

/** FMG APIs
 */
export const fmPost = function (fmgCsrf, idGetter, url, data) {
  const id = Object.prototype.hasOwnProperty(data, 'id') ? data.id : uuid4();
  idGetter?.(id);
  return fetchApi(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'XSRF-TOKEN': fmgCsrf,
    },
    body: JSON.stringify({ ...data, id }),
  });
};

/** GET APIs
 */
export const fafmGet = function (url, opts) {
  return fetchApi(url, {
    ...opts,
    method: 'GET',
  });
};

/**
 * Error handle
 * @param {*} fn
 * @returns
 */
export function handleError(fn) {
  return async function (...args) {
    try {
      return await fn(...args);
    } catch (e) {
      const result = e.data.result[0];
      throw `${result.url}: ${result.status.message}`;
    }
  };
}
