import { castArray, last, transform } from 'lodash';

// add checkers to provided app definitions object
export const batchAddCheckers = (apps, checkers) => {
  const checkersToAdd = castArray(checkers);
  const appEntries = Object.entries(apps);
  return appEntries.reduce((acc, app) => {
    const [key, val] = app;
    let newCheckers = [...checkersToAdd];

    if (Array.isArray(val.showCheckers)) {
      newCheckers = [...newCheckers, ...val.showCheckers];
    }

    acc[key] = {
      ...val,
      showCheckers: newCheckers,
    };

    return acc;
  }, {});
};

const defaultGetPath = (app) => {
  return last(app.path?.split('/'));
};

export function getRouteObjectsFromApps({ apps, getPath = defaultGetPath }) {
  return transform(
    apps,
    (result, app, key) => {
      const { path: fullPath, routerConfig = {} } = app;
      if (!fullPath) return; // e.g. feature visibility

      const path = getPath(app);
      if (!path) {
        if (MACROS.SYS.CONFIG_DEBUG)
          console.error('Please provide path for app =', app);
        return;
      }

      const pathConfig = {
        path,
        handle: {
          appUniKey: key,
        },
        ...routerConfig,
      };
      result.push(pathConfig);
    },
    []
  );
}
