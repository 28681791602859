import { isTaskDone, isTaskError } from './task_util';
import { fiAdom } from 'fi-session';

export { useUIshowHide, getCurrentAdomName, autoId };

function useUIshowHide({
  hide,
  hideWhenSuccess,
  hideByDefault = ['lastline'],
  show,
  taskData,
}) {
  const isTaskFinishSuccess =
    taskData && isTaskDone(taskData) && !isTaskError(taskData);

  hide = new Set(hideByDefault.concat(hide));
  hideWhenSuccess = new Set(isTaskFinishSuccess ? hideWhenSuccess : undefined);
  show = new Set(isTaskFinishSuccess ? undefined : show);

  const isUIvisible = (uiName) => {
    return (
      [hide, hideWhenSuccess].every((set) => !set.has(uiName)) ||
      [show].some((set) => set.has(uiName))
    );
  };

  return isUIvisible;
}

function getCurrentAdomName() {
  return fiAdom.current().name;
}

function autoId(id) {
  return 'fi_task_monitor_' + id;
}
