import { CsvExporter } from 'rc_exporter';
import { ProToolkit } from '@fafm/neowise-pro';
import { NwProInputRow, NwProLine } from 'rc_layout';
import { FmkCheckbox } from 'rc_form';
import _, { isNil } from 'lodash';
import { deviceStatus } from 'fi-dvm';

const insertTitleFn =
  (title) =>
  (data = [], index) => {
    if (data.length === index + 1 || data[index + 1].UI_ASSET_TITLE !== title) {
      //insert this title
      data.splice(index + 1, 0, {
        NOT_IN_UI: true,
        UI_ASSET_TITLE: title,
        name: title,
        _isTitle: true,
        _oData: {
          name: title,
        },
        _pData: {},
      });
    }
    return index + 1;
  };

function extracDevicetData(tableEntry) {
  const deviceData = tableEntry._oData ?? tableEntry.data?.data;

  return {
    // angular data ?? react data format
    rtype: tableEntry.rtype ?? deviceData?.rtype,
    deviceData,
    parentData:
      deviceData?._pData ?? tableEntry.pkey
        ? tableEntry.collection?.getByKey(tableEntry.pkey).data.data
        : undefined,
  };
}

// FIXME: dvm logic; not part of csv_exporter
function getAllDevicesFormattedData(tableData = []) {
  let allFtgsOnly = [];
  let allDevices = []; //include VDOMs
  tableData.forEach((d) => {
    const { rtype, deviceData, parentData } = extracDevicetData(d);

    if (deviceStatus.isDevice(rtype)) {
      allFtgsOnly.push(deviceData);
    }
    if (deviceStatus.isDeviceNoVdom(rtype)) {
      allDevices.push({ name: deviceData.name, vdom: 'root' });
    } else if (deviceStatus.isVdom(rtype)) {
      allDevices.push({ name: parentData?.name, vdom: deviceData.name });
    }
  });

  return {
    allFtgsOnly,
    allDevices,
  };
}

// FIXME: dvm logic; not part of csv_exporter
function findConnectRowOfAssetGlobalOnly(data, devName) {
  return data.findIndex((e) => {
    const { rtype, deviceData } = extracDevicetData(e);

    if (isNil(rtype)) return false; //might be ap...
    if (deviceStatus.isDevice(rtype) && devName === deviceData._deviceName) {
      return true;
    }
    return false;
  });
}

// FIXME: dvm logic; not part of csv_exporter
// eslint-disable-next-line
// function findConnectRowOfAsset(data, devName, vdomName, deviceStatus) {
//   return data.findIndex(d => {
//     if(isNil(d.rtype)) return false;//might be ap...
//     if(deviceStatus.isDevice(d.rtype) && devName === d._oData._deviceName) {
//       //find parent dev first
//       //then check vdomName
//       if(deviceStatus.isDeviceNoVdom(d.rtype) || !vdomName) return true;
//     } else if(deviceStatus.isVdom(d.rtype) && devName === d._oData._pData.name && vdomName === d._oData.name) {
//       return true;
//     }
//     return false;
//   });
// }

// FIXME: dvm logic; not part of csv_exporter
function parseAndInsertAsset({
  data,
  parseFn,
  getScopeMember,
  tableRows,
  insertTitle,
}) {
  data.forEach((d) => {
    d._isAsset = true;
    d.NOT_IN_UI = true;
    parseFn(d);
    // eslint-disable-next-line
    const { name, vdom = '' } = getScopeMember(d);
    let index = findConnectRowOfAssetGlobalOnly(tableRows, name);
    if (index >= 0) {
      if (insertTitle) index = insertTitle(tableRows, index);
      //insert after
      tableRows.splice(index + 1, 0, d);
    }
  });
}

function renderAssets(getAutoId) {
  return (
    <NwProInputRow label={gettext('Include Asset')}>
      <NwProLine>
        {[
          { key: 'includeFAP', text: gettext('FortiAP') },
          { key: 'includeFSW', text: gettext('FortiSwitch') },
          { key: 'includeEXT', text: gettext('FortiExtender') },
        ].map((p) => (
          <FmkCheckbox
            key={p.key}
            name={p.key}
            automation-id={getAutoId(p.key)}
          >
            {p.text}
          </FmkCheckbox>
        ))}
      </NwProLine>
    </NwProInputRow>
  );
}

function createProcess(meta) {
  return async ({ csvData }, { values }) => {
    if (meta?.includeAssets && meta?.fiDvmAssetsService) {
      const allAssetsRequest = [];
      const { allFtgsOnly, allDevices } = getAllDevicesFormattedData(csvData);
      if (values.includeFAP)
        allAssetsRequest.push(
          meta.fiDvmAssetsService.getAllAPsAssetsV2(allDevices)
        );
      if (values.includeFSW)
        allAssetsRequest.push(
          meta.fiDvmAssetsService.loadAllSwitchesV2(allFtgsOnly)
        );
      if (values.includeEXT)
        allAssetsRequest.push(
          meta.fiDvmAssetsService.loadFortiExtenders(allFtgsOnly)
        );
      if (allAssetsRequest.length > 0) {
        try {
          const allAssets = await Promise.all(allAssetsRequest);
          if (values.includeFAP) {
            parseAndInsertAsset({
              data: allAssets.shift(),
              parseFn: meta.fiDvmAssetsService.parseOneAPForDVM,
              getScopeMember: (d) => _.get(d._oData, 'scope member.0'),
              tableRows: csvData,
              insertTitle: insertTitleFn('FAP'),
            });
          }

          if (values.includeFSW) {
            parseAndInsertAsset({
              data: allAssets.shift(),
              parseFn: meta.fiDvmAssetsService.parseOneSwitchForDVM,
              getScopeMember: (d) => _.get(d._oData, 'scope member.0'),
              tableRows: csvData,
              insertTitle: insertTitleFn('FSW'),
            });
          }

          if (values.includeEXT) {
            parseAndInsertAsset({
              data: allAssets.shift(),
              parseFn: meta.fiDvmAssetsService.parseOneFortiExtenderForDVM,
              getScopeMember: (d) => _.get(d, 'scope member.0'),
              tableRows: csvData,
              insertTitle: insertTitleFn('FEXT'),
            });
          }
        } catch (e) {
          return false;
        }
      }
    }

    return true;
  };
}

export const fiCSVModal = {
  open: function open(fileName, data, columnDefs, context, meta = {}) {
    if (meta.includeAssets) {
      meta.render = renderAssets;
      meta.process = createProcess(meta); // Return true to continue, otherwise suspend the submit
    }
    return ProToolkit.openModal(
      <CsvExporter
        fileName={fileName}
        data={data}
        columnDefs={columnDefs}
        context={context}
        meta={meta}
      />,
      { width: 'lg', height: '40vh' }
    );
  },
  confirm: function open(fileName) {
    return ProToolkit.openModal(
      <CsvExporter fileName={fileName} isConfirmOnly />,
      { width: 'lg', height: '40vh' }
    ).result;
  },
};
