export function genPwd(size, charSet) {
  var ret = [];
  size = size || 10;
  charSet =
    charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  while (size--) {
    ret.push(charSet.charAt(Math.floor(Math.random() * charSet.length)));
  }
  return ret.join('');
}
