import {
  createFetchAction,
  createPromiseAction,
  createRecordAction,
} from '../../utils';

export const devicesAction = {
  ...createFetchAction('devices'),
  ...createRecordAction('devices'),
};

export const fetchDevicesAction = createPromiseAction('devices/FETCH_DEVICES');

export const fetchSingleDeviceAction = createPromiseAction(
  'devices/FETCH_SINGLE_DEVICES'
);

export const fetchMultipleDeviceAction = createPromiseAction(
  'devices/FETCH_MULTIPLE_DEVICES'
);

export const vdomsAction = {
  ...createRecordAction('vdoms'),
};
