import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

import { getSessionAdomOid } from 'fistore/session/adom/selectors';
import type {
  PTState,
  TemplateGroupStatusList,
  TemplateGroupStatusMap,
} from './types';

// there is no type for RootState yet
type RootState = any;

// Get the PT state from the root state
export const getDvmPtState = (state: RootState): PTState =>
  state.dvm.pt || { displayOptions: {}, templateGroupStatus: {} };

// Get all display options from PT state
export const getPtDisplayOptions = createSelector(
  getDvmPtState,
  (ptState) => (ptState.displayOptions || {}) as PTState['displayOptions']
);

// Get display options by adomOid
export const getPtDisplayOptionsByAdomOid = (adomOid?: number) =>
  createSelector(getPtDisplayOptions, (displayOptions) =>
    adomOid ? displayOptions[adomOid] : undefined
  );

// This selector checks if a specific app's display option is enabled based on the adomOid
// It accepts appKey (string) and defaultShow (boolean)
export const ptDisplayOptChecker = (appKey: string, defaultShow: boolean) =>
  createSelector(
    (state: RootState) => {
      const adomOid = getSessionAdomOid(state);
      return getPtDisplayOptionsByAdomOid(adomOid)(state);
    },
    (displayOpts) => {
      if (!displayOpts || !displayOpts.length) {
        return defaultShow;
      }
      return displayOpts.includes(appKey);
    }
  );

export const getAdomsTemplateGroupStatusData = createSelector(
  getDvmPtState,
  (ptState) => {
    return ptState.templateGroupStatus || {};
  }
);

export const getPerAdomTemplateGroupStatusList = (adomOid: number) =>
  createSelector(
    getAdomsTemplateGroupStatusData,
    (data) => (data[adomOid] || []) as TemplateGroupStatusList
  );

export const getPerAdomTemplateGroupStatusByOid = (adomOid: number) =>
  createSelector(
    getPerAdomTemplateGroupStatusList(adomOid),
    (templateGroupStatus) => {
      return keyBy(templateGroupStatus || [], 'oid') as TemplateGroupStatusMap;
    }
  );
