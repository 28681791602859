import { fiSession, fiStoreAdomLocalConfig } from 'fistore';
import { negate } from 'lodash';
import { batchAddCheckers } from '../util';
const {
  inManageableAdoms,
  checkVersion,
  inGlobalAdom,
  hasRDPermitOn,
  isFmg,
  inOthersAdom,
  inFpxAdom,
  inBackupAdom,
  isCentralManagement,
} = fiSession;
const { hasSelectedDeviceChecker } = fiStoreAdomLocalConfig;
const mrPRIV = MACROS.USER.DVM;

const port_policies = {
  fsw_port_policies: {
    label: gettext('Port Policies'),
  },
  fsw_template_objs_secpol: {
    label: gettext('Security Policies'),
    // icon: 'security',
    path: '/fortiswitch/templates/securitypolicy',
    olhTag: 'fsw_security_policy',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Port Policies -> Security Policies',
  },
  fsw_template_objs_ddpol: {
    label: gettext('Dynamic Port Policies'),
    // icon: 'port',
    path: '/fortiswitch/templates/ddp',
    showCheckers: [checkVersion('7.0', '>=')],
    olhTag: 'fsw_dpp',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Port Policies  -> Dynamic Port Policies',
  },
};

const qos_related = {
  fsw_qos_policies: {
    label: gettext('QoS'),
  },
  fsw_template_objs_qospol: {
    label: gettext('QoS Policies'),
    // icon: 'policy-objects',
    path: '/fortiswitch/templates/qospolicy',
    olhTag: 'fsw_qos_policy',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> QoS -> QoS Policies',
  },
  fsw_template_objs_eqpol: {
    label: gettext('Egress Queue Policies'),
    // icon: 'policy-objects',
    path: '/fortiswitch/templates/queuepolicy',
    olhTag: 'fsw_eqp',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> QoS -> Egress Queue Policies',
  },
  fsw_template_objs_ipdscpmap: {
    label: gettext('IP Precedence/DSCP'),
    // icon: 'policy-objects',
    path: '/fortiswitch/templates/ipdscpmap',
    olhTag: 'fsw_idscp',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> QoS -> IP Precedence/DSCP',
  },
  fsw_template_objs_dot1pmap: {
    label: '802.1P',
    // icon: 'policy-objects',
    path: '/fortiswitch/templates/dot1pmap',
    olhTag: 'fsw_802_1p',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> QoS -> 802.1P',
  },
};

const _fsw_template_apps = {
  fsw_template_swprof: {
    label: gettext('FortiSwitch Templates'),
    // icon: 'fortiswitch-alt',
    iconLibrary: 'fgt-products',
    path: '/fortiswitch/templates/switchprofile',
  },

  fsw_template_objs_vlandef: {
    label: gettext('FortiSwitch VLANs'),
    // icon: 'vlan',
    path: '/fortiswitch/templates/vlandefinition',
    olhTag: 'fsw_vlan',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> FortiSwitch VLANs',
  },

  ...port_policies,

  fsw_template_objs_lldpprof: {
    label: gettext('LLDP Profiles'),
    // icon: 'profile',
    path: '/fortiswitch/templates/lldpprofile',
    olhTag: 'fsw_lldp',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> LLDP Profiles',
  },

  ...qos_related,

  fsw_template_objs_cuscmd: {
    label: gettext('Custom Commands'),
    // icon: 'custom',
    path: '/fortiswitch/templates/command',
    olhTag: 'fsw_custom_command',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Custom Commands',
  },
  fsw_template_objs_aclassign: {
    label: gettext('ACL Assignment'),
    path: '/fortiswitch/templates/aclassign',
    showCheckers: [checkVersion('7.4', '>=')],
    olhTag: 'fsw_acl_assignment',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> ACL Assignment',
  },
  fsw_template_linksett: {
    label: gettext('FortiLink Settings'),
    // icon: 'interface-fortilink',
    iconLibrary: 'fgt-features',
    showCheckers: [checkVersion('7.0', '>=')],
  },
  fsw_template_linksett_flset: {
    label: gettext('FortiLink Settings'),
    // icon: 'aggregate',
    showCheckers: [checkVersion('7.0', '>=')],
    path: '/fortiswitch/templates/flset',
  },
  fsw_template_linksett_vdomset: {
    label: gettext('VDOM Settings'),
    // icon: 'vdom',
    showCheckers: [checkVersion('7.0', '>=')],
    path: '/fortiswitch/templates/vdomset',
  },
};

const fsw_template_apps = batchAddCheckers(_fsw_template_apps, [
  isCentralManagement('fsw'),
]);

const fap_device_template_apps = {
  // used in per-device management content menu
  fsw_device_pdm: {
    label: gettext('Managed FortiSwitches'),
    icon: 'fortiswitch',
    path: '/fortiswitch/devices/list',
    showCheckers: [negate(isCentralManagement('fsw'))],
    olhTag: 'fsw_list',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Managed Switches',
  },

  fsw_device_port_policies: {
    label: gettext('Port Policies'),
    icon: 'port',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_port_policy',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Port Policies',
  },

  fsw_device_template_vlan: {
    label: gettext('VLAN'),
    path: '/fortiswitch/devices/templates/systeminterface',
    icon: 'vlan',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_vlan',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> VLAN',
  },
  fsw_device_template_nacpolicy: {
    label: gettext('NAC Policies'),
    path: '/fortiswitch/devices/templates/nacpolicy',
    icon: 'policy-objects',
    showCheckers: [checkVersion('6.4', '>='), hasSelectedDeviceChecker],
    olhTag: 'fsw_nac_policy',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> NAC Policies',
  },
  fsw_device_template_ddp: {
    label: gettext('Dynamic Port Policies'),
    path: '/fortiswitch/devices/templates/ddp',
    showCheckers: [checkVersion('7.0', '>='), hasSelectedDeviceChecker],
    olhTag: 'fsw_dpp',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Dynamic Port Policies',
  },
  fsw_device_template_securitypolicy: {
    label: gettext('Security Policies'),
    path: '/fortiswitch/devices/templates/securitypolicy',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_security_policy',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Security Policies',
  },
  fsw_device_template_lldpprofile: {
    label: gettext('LLDP Profiles'),
    icon: 'profile',
    path: '/fortiswitch/devices/templates/lldpprofile',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_lldp',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> LLDP Profiles',
  },
  fsw_device_template_qos: {
    label: gettext('QoS'),
    icon: 'policy-objects',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_qos',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> QoS',
  },
  fsw_device_template_qospolicy: {
    label: gettext('QoS Policies'),
    path: '/fortiswitch/devices/templates/qospolicy',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_qos_policy',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> QoS Policies',
  },
  fsw_device_template_queuepolicy: {
    label: gettext('Egress Queue Policies'),
    path: '/fortiswitch/devices/templates/queuepolicy',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_eqp',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Egress Queue Policies',
  },
  fsw_device_template_ipdscpmap: {
    label: gettext('IP Precedence/DSCP'),
    path: '/fortiswitch/devices/templates/ipdscpmap',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_idscp',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> IP Precedence/DSCP',
  },
  fsw_device_template_dot1pmap: {
    label: '802.1P',
    path: '/fortiswitch/devices/templates/dot1pmap',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_802_1p',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> 802.1P',
  },
  fsw_device_template_cuscmd: {
    label: gettext('Custom Commands'),
    path: '/fortiswitch/devices/templates/command',
    icon: 'custom',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_custom_command',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Custom Commands',
  },
  fsw_device_template_aclassign: {
    label: gettext('ACL Assignment'),
    path: '/fortiswitch/devices/templates/aclassign',
    icon: 'task-list',
    iconLibrary: 'fgt-basic',
    showCheckers: [hasSelectedDeviceChecker, checkVersion('7.4', '>=')],
    olhTag: 'fsw_acl_assignment',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> ACL Assignment',
  },
  fsw_device_template_cliconf: {
    label: gettext('CLI Configurations'),
    icon: 'cli',
    path: '/fortiswitch/devices/templates/cliconf',
    showCheckers: [hasSelectedDeviceChecker],
    olhTag: 'fsw_cli_cfg',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> CLI Configurations',
  },
};

export default {
  fsw: {
    label: gettext('FortiSwitch Manager'),
    icon: 'fortiswitch',
    color: '#60bf7e',
    showCheckers: [
      isFmg,
      inManageableAdoms,
      negate(inBackupAdom),
      negate(inGlobalAdom),
      negate(inOthersAdom),
      negate(inFpxAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_FORTISWITCH),
    ],
  },
  fsw_device: {
    label: gettext('Managed FortiSwitches'),
    path: '/fortiswitch/centrallist',
    showCheckers: [isCentralManagement('fsw')],
    olhTag: 'fsw_centrallist',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Managed FortiSwitches (Central)',
  },
  fsw_topology: {
    label: gettext('Topology'),
    path: '/fortiswitch/topology',
    showCheckers: [isFmg],
    olhTag: 'fsw_topology',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Managed FortiSwitches -> List -> Topology',
    // Means it is still enabled, but does not show on system menus.
    isHidden: true,
  },
  fsw_faceplate: {
    label: gettext('Faceplate'),
    path: '/fortiswitch/faceplate',
    showCheckers: [isFmg],
    olhTag: 'fsw_faceplate',
    olhCategory: 'fsw',
    olhFmg:
      'FortiSwitch Manager -> Managed FortiSwitches -> List -> Topology -> Faceplate',
    isHidden: true,
  },
  fsw_device_pdm_root: {
    // used in per-device management main sidebar
    label: gettext('Managed FortiSwitches'),
    path: '/fortiswitch/devices/list',
    showCheckers: [negate(isCentralManagement('fsw'))],
    olhTag: 'fsw_list',
    olhCategory: 'fsw',
    olhFmg: 'FortiSwitch Manager -> Managed FortiSwitches (Per-Device)',
  },
  ...fsw_template_apps,
  ...fap_device_template_apps,
};
