import { useEffect, useRef } from 'react';

export { useValidFunctionWrapper };

function useValidFunctionWrapper(fn, deps) {
  // Ref to determine mounted state
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, deps);

  //wrapper
  return (...args) => {
    if (mountedRef.current) fn(...args);
  };
}
