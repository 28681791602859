import React, { useEffect, useMemo, useRef } from 'react';
import { getLineHistoryFinishedTime, getLineHistoryDetail } from './task_util';
import { formatSecondsCompact } from 'kit/kit-time';
import { ProForm, ProTable, ProToolkit } from '@fafm/neowise-pro';
import { isFunction } from 'lodash';
import { TaskHistory } from './types';
import { CancelBtn } from 'react_components/rc_layout';

const { Header, Body, Footer } = ProForm;

const rowKey = 'index';
const rowHeight = 24;

export const ViewTaskHistory = ({
  history,
  startTm,
  jumpBy,
}: {
  history: TaskHistory[];
  startTm: number;
  jumpBy?: (h: TaskHistory) => boolean;
}) => {
  const tableRef = useRef(null);
  const $opener = ProToolkit.useOpener();

  const data = useMemo(() => {
    const historyData = history || [];
    const parsed = parseHistoryData(historyData, startTm);
    return parsed;
  }, [history]);

  useEffect(() => {
    if (!tableRef.current || !isFunction(jumpBy)) return;

    const rows = data.filter((history) => jumpBy(history));
    if (!rows.length) return;

    setTimeout(() => {
      tableRef.current.scrollTo?.(rows[0][rowKey]);
      tableRef.current.select?.selectRows?.(rows);
    }, 300);
  }, [jumpBy]);

  const colDefs = [
    {
      key: 'name',
      title: gettext('Name'),
    },
    {
      key: 'percent',
      title: gettext('Progress %'),
      width: 100,
      dataGetter: ({ rowData }: { rowData: HistoryRow }) =>
        rowData.percent + '%',
      toSortValue: ({ rowData }: { rowData: HistoryRow }) => rowData.percent,
    },
    {
      key: 'time_used',
      title: gettext('Time Used'),
      width: 120,
      toSortValue: ({ rowData }: { rowData: HistoryRow }) =>
        rowData.time_used_tm,
    },
    {
      key: 'status',
      title: gettext('Status'),
    },
  ];

  return (
    <>
      <Header>{gettext('View Progress Report')}</Header>
      <Body>
        <ProTable.TableView
          tableId='task_history_table'
          ckColumn={false}
          columns={colDefs}
          data={data}
          rowKey={rowKey}
          rowHeight={rowHeight}
          ref={tableRef}
        />
      </Body>
      <Footer>
        <CancelBtn
          onClick={() => $opener.resolve()}
          automation-id={'task_history_table:close'}
        >
          {gettext('Close')}
        </CancelBtn>
      </Footer>
    </>
  );
};

type HistoryRow = TaskHistory & {
  time_used_tm: number;
  time_used: string;
  finishedTime: number;
  status: string;
  index: number;
};

function parseHistoryData(data: TaskHistory[], startTm: number): HistoryRow[] {
  if (!Array.isArray(data) || !data.length) return [];

  let _index = 0;

  const parseHistoryEntry = (
    entry: HistoryRow,
    i: number,
    array: HistoryRow[]
  ) => {
    const finishedTime = getLineHistoryFinishedTime(entry);
    let time_used_tm = -1;

    if (finishedTime) {
      if (i === 0) {
        if (entry.percent <= 1) {
          // if first history's pecentage is less than 1, it indicates the line just starts running
          // so no time used for first history
          time_used_tm = 0;
        } else if (startTm) {
          // if first history' percentage is larger than 1, it indicates the line already started before this history
          // but we do not know the exact time this history starts, so use the line's start timestamp.
          time_used_tm = finishedTime - startTm;
        }
      } else if (array[i - 1].finishedTime) {
        time_used_tm = finishedTime - array[i - 1].finishedTime;
      }
    }

    entry.time_used_tm = time_used_tm;
    entry.time_used = formatSecondsCompact(time_used_tm);
    entry.finishedTime = finishedTime;
    entry.status = getLineHistoryDetail(entry);
    entry.index = _index++;
    return entry;
  };

  return (data as HistoryRow[]).map(parseHistoryEntry);
}
