import goog from '@fafm/goog';
import { fiSysConfig, fiAdom } from 'fi-session';

const adom_isnot_52 = function (adom) {
  if (adom && adom.version.ver === 5) {
    return adom.version.mr !== 2;
  }
  return true;
};

const adom_laterthan_64 = function (adom) {
  if (adom) {
    if (adom.version.ver > 6) return true;
    else if (adom.version.ver < 6) return false;
    else return adom.version.mr >= 4;
  }
  return false;
};

const FILTER_NOT_CNF_DEV = (cfg = {}) => {
  if (cfg.iscnf) return false;
  return true;
};
const fiFGTCfgMenuItems = [
  {
    id: 'dashboard',
    iconLibrary: 'fa-solid',
    icon: 'tachometer-alt',
    name: gettext('Dashboard'),
    hidden: (currentAdom) => {
      return currentAdom.backup === 1;
    },
    items: [
      {
        id: 'dashboard',
        default: true,
        value: true,
        name: gettext('Dashboard'),
      },
    ],
  },
  {
    id: 'backupDash',
    name: gettext('Dashboard'),
    hidden: (currentAdom) => {
      return currentAdom.backup !== 1;
    },
    items: [
      {
        id: 'backupDashboard',
        default: false,
        value: true,
        name: gettext('Dashboard'),
        scope: 'global',
      },
    ],
  },
  {
    id: 'network',
    iconLibrary: 'fa-solid',
    icon: 'arrows-alt',
    name: gettext('Network'),
    filters: [FILTER_NOT_CNF_DEV],
    items: [
      {
        id: 'interface',
        default: true,
        value: true,
        name: gettext('Interfaces'),
        //cate: 'system interface',//to display interface page in vdom
      },
      {
        id: 'dhcpsvr',
        default: false,
        value: false,
        name: gettext('DHCP Servers'),
        cate: 'system dhcp server',
        scope: 'vdom',
        template: 'true',
        show: (toshow) =>
          toshow.indexOf('dhcpsvr') >= 0 || toshow.indexOf('dhcpsvr_all') >= 0,
      },
      {
        id: 'dhcp_reserved_addr',
        value: 'off',
        name: gettext('DHCP Reserved Address'),
        cate: 'system dhcp reserved-address',
        scope: 'global',
      },
      // DNS related
      {
        id: 'dns',
        default: true,
        value: false,
        name: 'DNS',
        cate: 'system dns',
        scope: 'global',
      },
      {
        id: 'ddns',
        default: false,
        value: 'off',
        name: 'DDNS',
        cate: 'system ddns',
        scope: 'global',
      },
      {
        id: 'dnsdb',
        default: false,
        value: false,
        name: gettext('DNS Databases'),
        cate: 'system dns-database',
        scope: 'vdom',
      },
      {
        id: 'dnsserver',
        default: false,
        value: false,
        name: gettext('DNS Service on Interface'),
        cate: 'system dns-server',
        scope: 'vdom',
      },
      {
        id: 'ipam',
        default: true,
        value: true,
        hidden: () => fiAdom.checkVersion('7.0', '<='),
        name: 'IPAM',
        cate: 'system ipam',
        scope: 'global',
      },
      {
        id: 'local_out_routing',
        default: false,
        value: false,
        name: gettext('Local Out Routing'),
        hidden: function (adomcfg, devcfg, fiSysConfig, vdom) {
          return vdom?.vmode === 'tp';
        },
      },
      {
        id: 'webproxyglb',
        value: 'off',
        name: gettext('Web Proxy'),
        cate: 'web-proxy global',
        scope: 'vdom',
      },
      {
        id: 'webproxyexpl',
        default: false,
        value: false,
        name: gettext('Explicit Proxy'),
        cate: 'web-proxy explicit',
        scope: 'vdom',
      },
      {
        id: 'ftpproxyexpl',
        value: 'off',
        name: gettext('Explicit FTP Proxy Options'),
        cate: 'ftp-proxy explicit',
        scope: 'vdom',
      },
      //web-proxy forward-server
      {
        id: 'webproxyfwdsvr',
        value: 'off',
        name: gettext('Web Proxy Forwarding Server'),
        cate: 'web-proxy forward-server',
        scope: 'vdom',
      },
      { isDivider: true },
      {
        id: 'sdwan',
        default: true,
        value: false,
        name: 'SD-WAN',
        cate: ['system sdwan'],
        scope: 'vdom',
        hidden: (adom) => !adom_laterthan_64(adom),
      },
      {
        id: 'wanlink',
        default: true,
        value: false,
        name: 'SD-WAN',
        cate: ['system virtual-wan-link'],
        scope: 'vdom',
        hidden: adom_laterthan_64,
      },
      { isDivider: true },
      {
        id: 'staticroute_all',
        default: true,
        value: true,
        name: gettext('Static Routes'),
        cate: ['router static'],
        scope: 'vdom',
      },
      {
        id: 'staticroute',
        default: true,
        value: 'off',
        name: gettext('Static Route IPv4'),
        cate: ['router static'],
        scope: 'vdom',
      },
      {
        id: 'staticrouteipv6',
        default: true,
        value: 'off',
        name: gettext('Static Route IPv6'),
        cate: ['router static6'],
        scope: 'vdom',
      },
      {
        id: 'policyroute',
        default: false,
        value: false,
        name: gettext('Policy Routes'),
        cate: ['router policy'],
        scope: 'vdom',
      },
      { isDivider: true },
      {
        id: 'rip',
        default: false,
        value: false,
        name: 'RIP',
        cate: ['router rip'],
        scope: 'vdom',
      },
      {
        id: 'ospf',
        default: false,
        value: false,
        name: 'OSPF',
        cate: ['router ospf'],
        scope: 'vdom',
      },
      {
        id: 'bgp',
        default: false,
        value: false,
        name: 'BGP',
        cate: ['router bgp'],
        scope: 'vdom',
      },
      {
        id: 'routing_objects',
        default: false,
        value: false,
        name: gettext('Routing Objects'),
        scope: 'vdom',
      },
      {
        id: 'mcroute',
        default: false,
        value: false,
        name: gettext('Multicast'),
        cate: ['router multicast'],
        scope: 'vdom',
      },
    ],
  },
  // security profile
  {
    id: 'securityprofiles',
    iconLibrary: 'fa-solid',
    icon: 'lock',
    name: gettext('Security Profiles'),
    items: [
      {
        id: 'webfltroverride',
        default: false,
        value: false,
        name: gettext('Web Filter Overrides'),
        cate: ['webfilter override'],
        scope: 'vdom',
      },
    ],
  },
  // VPN related
  {
    id: 'vpn',
    iconLibrary: 'fa-solid',
    icon: 'laptop',
    name: 'VPN',
    filters: [FILTER_NOT_CNF_DEV],
    items: [
      {
        id: 'phase1_all',
        default: true,
        value: false,
        name: gettext('IPsec Phase 1'),
        cate: ['vpn ipsec phase1'],
        scope: 'vdom',
      },
      {
        id: 'phase1',
        value: 'off',
        scope: 'vdom',
      },
      {
        id: 'phase1inf',
        value: 'off',
        scope: 'vdom',
      },
      {
        id: 'phase2_all',
        default: true,
        value: false,
        name: gettext('IPsec Phase 2'),
        cate: ['vpn ipsec phase2'],
        scope: 'vdom',
      },
      {
        id: 'phase2',
        value: 'off',
        scope: 'vdom',
      },
      {
        id: 'phase2inf',
        value: 'off',
        scope: 'vdom',
      },
      {
        id: 'aggregate',
        default: false,
        value: false,
        name: gettext('IPsec Aggregate'),
        cate: ['system ipsec-aggregate'],
        scope: 'vdom',
      },
      {
        id: 'manualkey_all',
        default: false,
        value: false,
        name: gettext('Manual Key'),
        cate: ['vpn ipsec manualkey'],
        scope: 'vdom',
      },
      {
        id: 'manualkey',
        value: 'off',
        scope: 'vdom',
      },
      {
        id: 'manualkeyinf',
        value: 'off',
        scope: 'vdom',
      },
      {
        id: 'concentrator',
        default: false,
        value: false,
        name: gettext('Concentrator'),
        cate: ['vpn ipsec concentrator'],
        scope: 'vdom',
      },
    ],
  },
  {
    id: 'system',
    iconLibrary: 'fa-solid',
    icon: 'cog',
    name: gettext('System'),
    items: [
      {
        id: 'cfgvdom',
        default: true,
        value: false,
        name: gettext('Virtual Domain'),
        scope: 'global',
        filters: [FILTER_NOT_CNF_DEV],
        cond: {
          enable_vdom: true,
        },
      },
      {
        id: 'glbresource',
        default: true,
        value: 'off',
        name: gettext('Global Resources'),
        // cate: 'system resource-limits',
        scope: 'global',
        cond: {
          enable_vdom: true,
        },
      },
      {
        id: 'glbresourcetbl',
        default: true,
        value: false,
        name: gettext('Global Resources'),
        // cate: 'system resource-limits',
        scope: 'global',
        cond: {
          enable_vdom: true,
        },
      },
      { isDivider: true },
      // admin section
      {
        id: 'syspasspol',
        default: false,
        value: 'off',
        name: gettext('Password Policy'),
        cate: 'system password-policy',
        scope: 'global',
      },
      {
        id: 'administrator',
        default: true,
        value: false,
        name: gettext('Administrators'),
        cate: 'system admin',
        scope: 'global',
      },
      {
        id: 'adminprof',
        default: true,
        value: false,
        name: gettext('Admin Profiles'),
        cate: 'system accprofile',
        scope: 'global',
      },
      {
        id: 'adminsettings',
        default: true,
        value: false,
        name: gettext('Settings'),
        cate: 'system global',
        scope: 'global',
      },
      { isDivider: true },
      {
        id: 'hamon',
        value: 'off',
        name: gettext('HA Monitor'),
        cate: 'system ha-monitor',
        scope: 'global',
      },
      {
        id: 'snmp',
        default: true,
        value: true,
        name: 'SNMP',
        cate: 'system snmp sysinfo',
        scope: 'global',
      },
      {
        id: 'sysntp',
        value: 'off',
        name: gettext('Time Settings'),
        cate: 'system ntp',
        scope: 'global',
      },
      {
        id: 'snmp_comm',
        value: 'off',
        name: 'SNMP v1/v2c',
        cate: 'system snmp community',
        scope: 'global',
      },
      {
        id: 'snmp_comm_TMPL',
        value: 'off',
        name: 'SNMP v1/v2c',
        cate: 'system snmp community',
      },
      {
        id: 'snmp_usr',
        value: 'off',
        name: 'SNMP v3',
        cate: 'system snmp user',
        scope: 'global',
      },
      {
        id: 'snmp_usr_TMPL',
        value: 'off',
        name: 'SNMP v3',
        cate: 'system snmp user',
      },
      {
        id: 'replacemsg',
        default: true,
        value: true,
        name: gettext('Replacement Messages'),
        scope: 'global',
      },
      {
        id: 'replacemsg_TMPL',
        value: 'off',
        name: gettext('Replacement Messages'),
        scope: 'global',
      },
      {
        id: 'replacemsg_images',
        value: 'off',
        name: gettext('Replacement Message Images'),
        scope: 'global',
      },
      {
        id: 'replacemsg_mail',
        name: gettext('Replacement Message Mail'),
        cate: 'system replacemsg mail',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_ec',
        name: gettext('Replacement Endpoint Control'),
        cate: 'system replacemsg ec',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_http',
        name: gettext('Replacement Message HTTP'),
        cate: 'system replacemsg http',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_ftp',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg ftp',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_nntp',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg nntp',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_alertmail',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg alertmail',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_fortiguard_wf',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg fortiguard_wf',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_spam',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg spam',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_admin',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg admin',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_auth',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg auth',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_im',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg im',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_sslvpn',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg sslvpn',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_ec',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg ec',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_nac_quar',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg nac-quar',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mms',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mms',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mm1',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mm1',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mm3',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mm3',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mm4',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mm4',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mm7',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mm7',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_traffic_quota',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg traffic-quota',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_webproxy',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg webproxy',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_captive_portal_dflt',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg captive-portal-dflt',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_utm',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg utm',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_ddp',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg device-detection-portal',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsggrp',
        default: false,
        value: false,
        name: gettext('Replacement Message Groups'),
        cate: 'system replacemsg-group',
      },
      {
        id: 'fortiguard',
        default: false,
        value: false,
        name: 'FortiGuard',
        cate: 'system fortiguard',
        scope: 'global',
      },
      {
        id: 'fortiguardudts',
        name: gettext('System Update Schedule'),
        cate: 'system autoupdate schedule',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'fortiguardudtt',
        value: 'off',
        name: gettext('System Update Tunneling'),
        cate: 'system autoupdate tunneling',
        scope: 'global',
      },
      {
        id: 'centralMgt',
        value: 'off',
        name: gettext('System Update Server List'),
        cate: 'system central-management',
        url: 'pm/config/device/%devname%/global/system/central-management',
        scope: 'global',
      },
      { isDivider: true },
      {
        id: 'vpncacert',
        default: false,
        value: false,
        name: gettext('Certificates'),
        cate: 'vpn certificate ca',
        scope: '',
      },
      {
        id: 'certprof_TMPL',
        value: 'off',
        name: gettext('Certificate Template'),
        cate: 'certificate template',
        scope: 'global',
      },
      { isDivider: true },
      {
        id: 'modem',
        default: false,
        value: false,
        name: gettext('Modem'),
        cate: 'system modem',
      },
      {
        id: 'localhostid',
        default: false,
        value: false,
        name: gettext('Local Host ID'),
        cate: 'wanopt settings',
        scope: 'vdom',
      },
      {
        id: 'alertemailsett',
        default: false,
        value: false,
        name: gettext('Alert Email'),
        cate: ['alertemail setting'],
        scope: 'vdom',
      },
      {
        id: 'sysmailsvr',
        name: gettext('Email Server'),
        cate: ['system email-server'],
        scope: 'global',
        value: 'off',
      },
      {
        id: 'devprof_tbl',
        value: 'off',
      },
      {
        id: 'nat64prefix',
        default: false,
        value: 'off',
        name: gettext('NAT64 Prefix'),
        cate: ['system nat64'],
      },
    ],
  },
  {
    id: 'securityfabric',
    icon: 'ffg ffg-fabric-view',
    name: gettext('Security Fabric'),
    items: [
      {
        id: 'csf',
        default: false,
        value: false,
        name: gettext('Fabric Settings'),
        cate: 'system csf',
        hidden: function (adomcfg, devcfg) {
          return devcfg ? devcfg.vdom_enabled : false;
        },
        scope: 'global',
        template: 'true',
      },
      {
        id: 'automation_stitch',
        default: false,
        value: false,
        name: gettext('Automation Stitch'),
        cate: 'system automation-stitch',
        scope: 'global',
      },
      {
        id: 'automation_trigger',
        default: false,
        value: false,
        name: gettext('Automation Trigger'),
        cate: 'system automation-trigger',
        scope: 'global',
      },
      {
        id: 'automation_action',
        default: false,
        value: false,
        name: gettext('Automation Action'),
        cate: 'system automation-action',
        scope: 'global',
      },
      {
        id: 'fortisandbox',
        default: false,
        value: false,
        name: 'FortiSandbox',
        cate: ['system fortisandbox'],
        scope: 'global',
      },
    ],
  },
  // Query related
  {
    id: 'query',
    icon: 'ffg ffg-monitor',
    name: gettext('Monitor'),
    // eslint-disable-next-line
    hidden: (adom, dev, sysConfig) => {
      return (
        goog.string.compareVersions(
          fiSysConfig.getThisFMGVersion().version,
          '6.4'
        ) >= 0
      );
    },
    items: [
      {
        id: 'querydhcp',
        default: false,
        value: false,
        name: 'DHCP',
        cate: ['system dhcp server'],
        scope: 'vdom',
      },
      {
        id: 'queryvpn',
        default: false,
        value: false,
        name: 'IPsec VPN',
        cate: ['vpn ipsec concentrator'],
        scope: 'vdom',
      },
      {
        id: 'queryssvpn',
        default: false,
        value: false,
        name: 'SSL VPN',
        cate: ['vpn ssl settings'],
        scope: 'vdom',
      },
      {
        id: 'querytrafficshaping',
        default: false,
        value: false,
        name: gettext('Traffic Shaping'),
        cate: ['log gui-display'],
        scope: 'vdom',
      },
      {
        id: 'queryuser',
        default: false,
        value: false,
        name: gettext('User'),
        cate: ['user setting'],
        scope: 'vdom',
      },
      {
        id: 'queryforticlient',
        default: false,
        value: false,
        name: gettext('FortiClient'),
        cate: ['user detected-device'],
        scope: 'vdom',
      },
      {
        id: 'querytrafficshapper',
        default: false,
        value: false,
        name: gettext('Traffic Shaper'),
        cate: ['firewall shaper traffic-shaper'],
        scope: 'vdom',
      },
      {
        id: 'queryfortitoken',
        default: false,
        value: false,
        name: 'FortiToken',
        cate: ['user fortitoken'],
        scope: 'vdom',
      },
      {
        //mantis 0306447
        id: 'querywebfilter',
        default: false,
        value: false,
        name: gettext('Web Filter'),
        cate: ['webfilter profile'],
        scope: 'vdom',
        hidden: adom_isnot_52,
      },
      {
        //mantis 0306447
        id: 'queryapplication',
        default: false,
        value: false,
        name: gettext('Application'),
        cate: ['application list'],
        scope: 'vdom',
        hidden: adom_isnot_52,
      },
      {
        //mantis 0306447
        id: 'queryemail',
        default: false,
        value: false,
        name: gettext('Email Filter'),
        cate: ['spamfilter profile'],
        scope: 'vdom',
        hidden: adom_isnot_52,
      },
      {
        id: 'querydlp',
        default: false,
        value: false,
        name: gettext('Archive & Data Leak'),
        cate: ['dlp rule'],
        scope: 'vdom',
      },
      {
        id: 'querywificlient',
        default: false,
        value: false,
        name: gettext('WiFi Clients'),
        cate: ['wireless-controller wtp'],
        scope: 'vdom',
      },
      {
        id: 'queryrogueap',
        default: false,
        value: false,
        name: gettext('Rogue AP'),
        cate: ['wireless-controller ap-status'],
        scope: 'vdom',
      },
      {
        id: 'querylogging',
        default: false,
        value: false,
        name: gettext('Logging'),
        cate: ['log gui'],
        scope: 'vdom',
      },
      {
        id: 'queryrouting',
        default: false,
        value: false,
        name: gettext('Routing'),
        cate: ['router static'],
        scope: 'vdom',
      },
      {
        id: 'querypolicyroute',
        default: false,
        value: false,
        name: gettext('Routing'),
        cate: ['router policy'],
        scope: 'vdom',
      },
    ],
  },
  {
    id: 'detectdev',
    icon: 'ffg ffg-virtual-machine',
    name: gettext('Detected Devices'),
    hidden: () => true,
    items: [
      {
        id: 'detectdevid',
        default: false,
        value: false,
        name: gettext('Detected Devices'),
      },
    ],
  },
  {
    id: 'log',
    iconLibrary: 'fa-solid',
    icon: 'chart-bar',
    name: gettext('Log & Report'),
    items: [
      {
        id: 'logsettings',
        default: true,
        name: gettext('Log Settings'),
        scope: '',
      },
      {
        id: 'logsetgui',
        value: 'off',
        name: gettext('Log Setting GUI Preference'),
        cate: ['log gui-display'],
        scope: 'vdom',
      },
      {
        id: 'logsetreport',
        value: 'off',
        name: gettext('Report Setting'),
        cate: ['report setting'],
        scope: 'vdom',
      },
      {
        id: 'logsetevtflt',
        value: 'off',
        name: gettext('Log Event Filter'),
        cate: ['log eventfilter'],
        scope: 'vdom',
      },
      {
        id: 'logsetmem',
        value: 'off',
        name: gettext('Log Memory'),
        cate: ['log memory setting'],
        scope: 'vdom',
      },
      {
        id: 'logsetmemflt',
        value: 'off',
        name: gettext('Log Memory Filter'),
        cate: ['log memory filter'],
        scope: 'vdom',
      },
      {
        id: 'logsetdisk',
        value: 'off',
        name: gettext('Log Disk'),
        cate: ['log disk setting'],
        scope: 'vdom',
      },
      {
        id: 'logsetdiskflt',
        value: 'off',
        name: gettext('Log Disk Filter'),
        cate: ['log disk filter'],
        scope: 'vdom',
      },
      {
        id: 'logsetfaz',
        value: 'off',
        name: gettext('Log FortiAnalyzer'),
        cate: ['log fortianalyzer setting'],
        scope: 'global',
      },
      {
        id: 'logsetfazod', //override
        value: 'off',
        name: gettext('Log FortiAnalyzer Override'),
        cate: ['log fortianalyzer override-setting'],
        scope: 'vdom',
      },
      {
        id: 'logsetsys',
        value: 'off',
        name: gettext('Log syslogd'),
        cate: ['log syslogd setting'],
        scope: 'global',
      },
      {
        id: 'logsetsysflt',
        value: 'off',
        name: gettext('Log syslogd Filter'),
        cate: ['log syslogd filter'],
        scope: 'global',
      },
      {
        id: 'logsetfgd',
        value: 'off',
        name: gettext('Log FortiGuard'),
        cate: ['log fortiguard setting'],
        scope: 'global',
      },
      {
        id: 'logsetfgdod',
        value: 'off',
        name: gettext('Log FortiGuard Override'),
        cate: ['log fortiguard override-setting'],
        scope: 'vdom',
      },
      {
        id: 'threatweight',
        default: false,
        value: false,
        name: gettext('Threat Weight'),
        cate: ['log threat-weight'],
      },
      {
        id: 'threatweight_tbl',
        value: 'off',
        name: gettext('Threat Weight'),
        cate: ['log threat-weight'],
      },
    ],
  },
  {
    id: 'clionly',
    icon: 'ffg ffg-cli',
    name: gettext('CLI Configurations'),
    filters: [FILTER_NOT_CNF_DEV],
    items: [
      {
        id: 'clionlyid',
        default: true,
        value: false,
        name: gettext('CLI Configurations'),
      },
    ],
  },
];

const fiFPXCfgMenuItems = [
  {
    id: 'dashboard',
    iconLibrary: 'fa-solid',
    icon: 'tachometer-alt',
    name: gettext('Dashboard'),
    hidden: (currentAdom) => {
      return currentAdom.backup === 1;
    },
    items: [
      {
        id: 'dashboard',
        default: true,
        value: true,
        name: gettext('Dashboard'),
      },
    ],
  },
  {
    id: 'backupDash',
    name: gettext('Dashboard'),
    hidden: (currentAdom) => {
      return currentAdom.backup !== 1;
    },
    items: [
      {
        id: 'backupDashboard',
        default: false,
        value: false,
        name: gettext('Dashboard'),
        scope: 'global',
      },
    ],
  },
  {
    id: 'proxysettings',
    icon: 'ffg ffg-settings',
    name: gettext('Proxy Settings'),
    items: [
      {
        id: 'explicitproxy',
        // Show this tab in the display options
        default: false,
        value: true,
        name: gettext('Explicit Proxy'),
        cate: ['web-proxy explicit-proxy'],
        scope: 'vdom',
      },
      {
        id: 'webproxysettings',
        default: true,
        value: true,
        name: gettext('Web Proxy Settings'),
        cate: ['web-proxy global'],
        scope: 'vdom',
      },
      {
        id: 'webproxyprofile',
        // Show this tab in the display options
        default: false,
        value: true,
        name: gettext('Web Proxy Profile'),
        cate: ['web-proxy profile'],
        scope: 'vdom',
      },
      {
        id: 'forwardingsvr',
        default: true,
        value: true,
        name: gettext('Forwarding Server'),
        cate: ['web-proxy forward-server'],
        scope: 'vdom',
      },
      {
        id: 'svrurl',
        default: true,
        value: true,
        name: gettext('Server URL'),
        cate: ['web-proxy url-match'],
        scope: 'vdom',
      },
      {
        id: 'ftpproxy',
        default: true,
        value: true,
        name: gettext('FTP Proxy'),
        cate: ['ftp-proxy explicit'],
        scope: 'vdom',
      },
      {
        id: 'isolatorsvr',
        // Show this tab in the display options
        default: false,
        value: true,
        name: gettext('Isolator Server'),
        cate: ['web-proxy isolator-server'],
        scope: 'vdom',
      },
      {
        id: 'proxyopts',
        // Show this tab in the display options
        default: false,
        value: true,
        name: gettext('Proxy Options'),
        cate: ['firewall profile-protocol-options'],
        scope: 'vdom',
      },
    ],
  },
  {
    id: 'network',
    iconLibrary: 'fa-solid',
    icon: 'arrows-alt',
    name: gettext('Network'),
    items: [
      {
        id: 'interface',
        default: true,
        value: true,
        name: gettext('Interfaces'),
        //cate: 'system interface',//to display interface page in vdom
      },
      {
        id: 'ipam',
        default: true,
        value: true,
        hidden: (adom) => adom.version.ver < 7,
        name: 'IPAM',
        cate: 'system ipam',
        scope: 'global',
      },
      {
        id: 'gretunnel',
        default: true,
        value: true,
        name: gettext('GRE Tunnel'),
        cate: ['system gre-tunnel'],
      },
      // DNS related
      {
        id: 'dns',
        default: true,
        value: false,
        name: 'DNS',
        cate: 'system dns',
        scope: 'global',
      },
      {
        id: 'ddns',
        default: false,
        value: 'off',
        name: 'DDNS',
        cate: 'system ddns',
        scope: 'global',
      },
      {
        id: 'dnsdb',
        default: false,
        value: false,
        name: gettext('DNS Databases'),
        cate: 'system dns-database',
        scope: 'vdom',
      },
      {
        id: 'dnsserver',
        default: false,
        value: false,
        name: gettext('DNS Service on Interface'),
        cate: 'system dns-server',
        scope: 'vdom',
      },
      { isDivider: true },
      {
        id: 'staticroute_all',
        default: true,
        value: true,
        name: gettext('Static Routes'),
        cate: ['router static'],
        scope: 'vdom',
      },
      {
        id: 'staticroute',
        default: true,
        value: 'off',
        name: gettext('Static Route IPv4'),
        cate: ['router static'],
        scope: 'vdom',
      },
      {
        id: 'staticrouteipv6',
        default: true,
        value: 'off',
        name: gettext('Static Route IPv6'),
        cate: ['router static6'],
        scope: 'vdom',
      },
      {
        id: 'policyroute',
        default: false,
        value: false,
        name: gettext('Policy Routes'),
        cate: ['router policy'],
        scope: 'vdom',
      },
    ],
  },
  // security profile
  {
    id: 'securityprofiles',
    iconLibrary: 'fa-solid',
    icon: 'lock',
    name: gettext('Security Profiles'),
    items: [
      {
        id: 'webfltroverride',
        default: false,
        value: false,
        name: gettext('Web Filter Override'),
        cate: ['webfilter override'],
        scope: 'vdom',
      },
    ],
  },
  // WAN Optimize
  {
    id: 'wanopt',
    icon: 'ffg ffg-wan-opt',
    name: gettext('WAN Optimization'),
    items: [
      {
        id: 'wanoptprofile',
        default: false,
        value: false,
        name: gettext('Profiles'),
        cate: ['wanopt profile'],
        scope: 'vdom',
      },
      { isDivider: true },
      {
        id: 'localhostid',
        default: false,
        value: false,
        name: gettext('Local Host ID'),
        cate: 'wanopt settings',
        scope: 'vdom',
      },
      {
        id: 'wanoptpeer',
        default: false,
        value: false,
        name: gettext('Peers'),
        cate: ['wanopt peer'],
        scope: 'vdom',
      },
      {
        id: 'wanoptauthgroup',
        default: false,
        value: false,
        name: gettext('Authentication Group'),
        cate: ['wanopt auth-group'],
        scope: 'vdom',
      },
    ],
  },
  {
    id: 'webcache',
    iconLibrary: 'fa-solid',
    icon: 'database',
    name: gettext('Web Cache'),
    items: [
      {
        id: 'webcachsettings',
        default: true,
        value: true,
        name: gettext('Settings'),
        cate: ['webcache settings'],
        scope: 'vdom',
      },
      {
        id: 'wccpsettings',
        default: true,
        value: true,
        name: gettext('WCCP Settings'),
        cate: ['system wccp'],
        scope: 'vdom',
      },
      {
        id: 'useragent',
        default: true,
        value: true,
        name: gettext('User Agents'),
        cate: ['webcache user-agent'],
        scope: 'vdom',
      },
      {
        id: 'reversecachesvr',
        default: true,
        value: true,
        name: gettext('Reverse Cache Servers'),
        cate: ['webcache reverse-cache-server'],
        scope: 'vdom',
      },
      {
        id: 'prefetchurls',
        default: true,
        value: true,
        name: gettext('Prefetch URLs'),
        cate: ['webcache prefetch'],
        scope: 'vdom',
      },
      {
        id: 'prefetchfile',
        default: true,
        value: true,
        name: gettext('Prefetch Files'),
        cate: ['webcache reverse-cache-prefetch-url'],
        scope: 'vdom',
      },
    ],
  },
  // VPN related
  {
    id: 'vpn',
    iconLibrary: 'fa-solid',
    icon: 'laptop',
    name: 'VPN',
    items: [
      {
        id: 'phase1inf',
        default: true,
        value: false,
        name: gettext('IPsec Phase 1'),
        cate: ['vpn ipsec phase1-interface'],
        scope: 'vdom',
      },
      {
        id: 'phase2inf',
        default: true,
        value: false,
        name: gettext('IPsec Phase 2'),
        cate: ['vpn ipsec phase2-interface'],
        scope: 'vdom',
      },
    ],
  },
  // Query related
  {
    id: 'query',
    icon: 'ffg ffg-monitor',
    name: gettext('Monitor'),
    // eslint-disable-next-line
    hidden: (adom, dev, sysConfig) => {
      return (
        goog.string.compareVersions(
          fiSysConfig.getThisFMGVersion().version,
          '6.4'
        ) >= 0
      );
    },
    items: [
      {
        id: 'querydhcp',
        default: false,
        value: false,
        name: 'DHCP',
        cate: ['system dhcp server'],
        scope: 'vdom',
      },
      {
        id: 'queryvpn',
        default: false,
        value: false,
        name: 'IPsec VPN',
        cate: ['vpn ipsec concentrator'],
        scope: 'vdom',
      },
      {
        id: 'queryssvpn',
        default: false,
        value: false,
        name: 'SSL VPN',
        cate: ['vpn ssl settings'],
        scope: 'vdom',
      },
      {
        id: 'querytrafficshaping',
        default: false,
        value: false,
        name: gettext('Traffic Shaping'),
        cate: ['log gui-display'],
        scope: 'vdom',
      },
      {
        id: 'queryuser',
        default: false,
        value: false,
        name: gettext('User'),
        cate: ['user setting'],
        scope: 'vdom',
      },
      {
        id: 'queryforticlient',
        default: false,
        value: false,
        name: gettext('FortiClient'),
        cate: ['user detected-device'],
        scope: 'vdom',
      },
      {
        id: 'querytrafficshapper',
        default: false,
        value: false,
        name: gettext('Traffic Shaper'),
        cate: ['firewall shaper traffic-shaper'],
        scope: 'vdom',
      },
      {
        id: 'queryfortitoken',
        default: false,
        value: false,
        name: 'FortiToken',
        cate: ['user fortitoken'],
        scope: 'vdom',
      },
      {
        //mantis 0306447
        id: 'querywebfilter',
        default: false,
        value: false,
        name: gettext('Web Filter'),
        cate: ['webfilter profile'],
        scope: 'vdom',
        hidden: adom_isnot_52,
      },
      {
        //mantis 0306447
        id: 'queryapplication',
        default: false,
        value: false,
        name: gettext('Application'),
        cate: ['application list'],
        scope: 'vdom',
        hidden: adom_isnot_52,
      },
      {
        //mantis 0306447
        id: 'queryemail',
        default: false,
        value: false,
        name: gettext('Email Filter'),
        cate: ['spamfilter profile'],
        scope: 'vdom',
        hidden: adom_isnot_52,
      },
      {
        id: 'querydlp',
        default: false,
        value: false,
        name: gettext('Archive & Data Leak'),
        cate: ['dlp rule'],
        scope: 'vdom',
      },
      {
        id: 'querylogging',
        default: false,
        value: false,
        name: gettext('Logging'),
        cate: ['log gui'],
        scope: 'vdom',
      },
      {
        id: 'queryrouting',
        default: false,
        value: false,
        name: gettext('Routing'),
        cate: ['router static'],
        scope: 'vdom',
      },
      {
        id: 'querypolicyroute',
        default: false,
        value: false,
        name: gettext('Routing'),
        cate: ['router policy'],
        scope: 'vdom',
      },
    ],
  },
  {
    id: 'detectdev',
    icon: 'ffg ffg-virtual-machine',
    name: gettext('Detected Devices'),
    hidden: () => true,
    items: [
      {
        id: 'detectdevid',
        default: false,
        value: false,
        name: gettext('Detected Devices'),
      },
    ],
  },
  {
    id: 'system',
    iconLibrary: 'fa-solid',
    icon: 'cog',
    name: gettext('System'),
    items: [
      // virtual Domain
      {
        id: 'cfgvdom',
        default: true,
        value: false,
        name: gettext('Virtual Domain'),
        scope: 'global',
        hidden: (adomcfg, devcfg) => {
          return devcfg
            ? devcfg.ver < 7 || (devcfg.ver == 7 && devcfg.mr < 2) // check device version,
            : adomcfg.version.ver < 7 ||
                (adomcfg.version.ver == 7 && adomcfg.version.mr < 2); // check adom version
        },
      },
      // admin section
      {
        id: 'administrator',
        default: true,
        value: false,
        name: gettext('Administrators'),
        cate: 'system admin',
        scope: 'global',
      },
      {
        id: 'adminprof',
        default: true,
        value: false,
        name: gettext('Admin Profiles'),
        cate: 'system accprofile',
        scope: 'global',
      },
      {
        id: 'adminsettings',
        default: true,
        value: false,
        name: gettext('Settings'),
        cate: 'system global',
        scope: 'global',
      },
      {
        id: 'syspasspol',
        default: false,
        value: 'off',
        name: gettext('Password Policy'),
        cate: 'system password-policy',
        scope: 'global',
      },
      { isDivider: true },
      {
        id: 'hamon',
        value: 'off',
        name: gettext('HA Monitor'),
        cate: 'system ha-monitor',
        scope: 'global',
      },
      {
        id: 'snmp',
        default: true,
        value: true,
        name: 'SNMP',
        cate: 'system snmp sysinfo',
        scope: 'global',
      },
      {
        id: 'sysntp',
        value: 'off',
        name: gettext('Time Settings'),
        cate: 'system ntp',
        scope: 'global',
      },
      {
        id: 'snmp_comm',
        value: 'off',
        name: 'SNMP v1/v2c',
        cate: 'system snmp community',
        scope: 'global',
      },
      {
        id: 'snmp_comm_TMPL',
        value: 'off',
        name: 'SNMP v1/v2c',
        cate: 'system snmp community',
      },
      {
        id: 'snmp_usr',
        value: 'off',
        name: 'SNMP v3',
        cate: 'system snmp user',
        scope: 'global',
      },
      {
        id: 'snmp_usr_TMPL',
        value: 'off',
        name: 'SNMP v3',
        cate: 'system snmp user',
      },
      {
        id: 'certprof_TMPL',
        value: 'off',
        name: gettext('Certificate Template'),
        cate: 'certificate template',
        scope: 'global',
      },
      {
        id: 'replacemsg',
        default: true,
        value: true,
        name: gettext('Replacement Messages'),
        scope: 'global',
      },
      {
        id: 'replacemsg_TMPL',
        value: 'off',
        name: gettext('Replacement Messages'),
        scope: 'global',
      },
      {
        id: 'replacemsg_images',
        value: 'off',
        name: gettext('Replacement Message Images'),
        scope: 'global',
      },
      {
        id: 'replacemsg_mail',
        name: gettext('Replacement Message Mail'),
        cate: 'system replacemsg mail',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_ec',
        name: gettext('Replacement Endpoint Control'),
        cate: 'system replacemsg ec',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_http',
        name: gettext('Replacement Message HTTP'),
        cate: 'system replacemsg http',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_ftp',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg ftp',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_nntp',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg nntp',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_alertmail',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg alertmail',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_fortiguard_wf',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg fortiguard_wf',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_spam',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg spam',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_admin',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg admin',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_auth',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg auth',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_im',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg im',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_sslvpn',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg sslvpn',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_ec',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg ec',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_nac_quar',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg nac-quar',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mms',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mms',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mm1',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mm1',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mm3',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mm3',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mm4',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mm4',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_mm7',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg mm7',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_traffic_quota',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg traffic-quota',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_webproxy',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg webproxy',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_captive_portal_dflt',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg captive-portal-dflt',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_utm',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg utm',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsg_ddp',
        name: gettext('Replacement Messages'),
        cate: 'system replacemsg device-detection-portal',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'replacemsggrp',
        value: false,
        default: false,
        name: gettext('Replacement Message Groups'),
        cate: 'system replacemsg-group',
      },
      {
        id: 'fortiguard',
        default: false,
        value: false,
        name: 'FortiGuard',
        cate: 'system fortiguard',
        scope: 'global',
      },
      {
        id: 'fortiguardudts',
        name: gettext('System Update Schedule'),
        cate: 'system autoupdate schedule',
        scope: 'global',
        value: 'off',
      },
      {
        id: 'fortiguardudtt',
        value: 'off',
        name: gettext('System Update Tunneling'),
        cate: 'system autoupdate tunneling',
        scope: 'global',
      },
      {
        id: 'centralMgt',
        value: 'off',
        name: gettext('System Update Server List'),
        cate: 'system central-management',
        url: 'pm/config/device/%devname%/global/system/central-management',
        scope: 'global',
      },
      { isDivider: true },
      {
        id: 'vpncacert',
        default: false,
        value: false,
        name: gettext('Certificates'),
        cate: 'vpn certificate ca',
        scope: '',
      },
      {
        id: 'alertemailsett',
        default: false,
        value: false,
        name: gettext('Alert Email'),
        cate: ['alertemail setting'],
        scope: 'vdom',
      },
      {
        id: 'sysmailsvr',
        name: gettext('Email Server'),
        cate: ['system email-server'],
        scope: 'global',
        value: 'off',
      },
      {
        id: 'devprof_tbl',
        value: 'off',
      },
      {
        id: 'nat64prefix',
        default: false,
        value: 'off',
        name: gettext('NAT64 Prefix'),
        cate: ['system nat64'],
      },
    ],
  },
  {
    id: 'securityfabric',
    icon: 'ffg ffg-fabric-view',
    name: gettext('Security Fabric'),
    items: [
      {
        id: 'csf',
        default: false,
        value: false,
        name: gettext('Fabric Settings'),
        cate: 'system csf',
        hidden: function (adomcfg, devcfg) {
          return devcfg ? devcfg.vdom_enabled : false;
        },
        scope: 'global',
        template: 'true',
      },
      {
        id: 'automation_stitch',
        default: false,
        value: false,
        name: gettext('Automation Stitch'),
        cate: 'system automation-stitch',
        scope: 'global',
      },
      {
        id: 'fortisandbox',
        default: false,
        value: false,
        name: 'FortiSandbox',
        cate: ['system fortisandbox'],
        scope: 'global',
      },
    ],
  },
  {
    id: 'log',
    iconLibrary: 'fa-solid',
    icon: 'chart-bar',
    name: gettext('Log & Report'),
    items: [
      {
        id: 'logsettings',
        default: true,
        name: gettext('Log Settings'),
        scope: '',
      },
      {
        id: 'logsetgui',
        value: 'off',
        name: gettext('Log Setting GUI Preference'),
        cate: ['log gui-display'],
        scope: 'vdom',
      },
      {
        id: 'logsetreport',
        value: 'off',
        name: gettext('Report Setting'),
        cate: ['report setting'],
        scope: 'vdom',
      },
      {
        id: 'logsetevtflt',
        value: 'off',
        name: gettext('Log Event Filter'),
        cate: ['log eventfilter'],
        scope: 'vdom',
      },
      {
        id: 'logsetmem',
        value: 'off',
        name: gettext('Log Memory'),
        cate: ['log memory setting'],
        scope: 'vdom',
      },
      {
        id: 'logsetmemflt',
        value: 'off',
        name: gettext('Log Memory Filter'),
        cate: ['log memory filter'],
        scope: 'vdom',
      },
      {
        id: 'logsetdisk',
        value: 'off',
        name: gettext('Log Disk'),
        cate: ['log disk setting'],
        scope: 'vdom',
      },
      {
        id: 'logsetdiskflt',
        value: 'off',
        name: gettext('Log Disk Filter'),
        cate: ['log disk filter'],
        scope: 'vdom',
      },
      {
        id: 'logsetfaz',
        value: 'off',
        name: gettext('Log FortiAnalyzer'),
        cate: ['log fortianalyzer setting'],
        scope: 'global',
      },
      {
        id: 'logsetfazod', //override
        value: 'off',
        name: gettext('Log FortiAnalyzer Override'),
        cate: ['log fortianalyzer override-setting'],
        scope: 'vdom',
      },
      {
        id: 'logsetsys',
        value: 'off',
        name: gettext('Log syslogd'),
        cate: ['log syslogd setting'],
        scope: 'global',
      },
      {
        id: 'logsetsysflt',
        value: 'off',
        name: gettext('Log syslogd Filter'),
        cate: ['log syslogd filter'],
        scope: 'global',
      },
      {
        id: 'logsetfgd',
        value: 'off',
        name: gettext('Log FortiGuard'),
        cate: ['log fortiguard setting'],
        scope: 'global',
      },
      {
        id: 'logsetfgdod', //override
        value: 'off',
        name: gettext('Log FortiGuard Override'),
        cate: ['log fortiguard override-setting'],
        scope: 'vdom',
      },
      {
        id: 'threatweight',
        default: false,
        value: false,
        name: gettext('Threat Weight'),
        cate: ['log threat-weight'],
      },
      {
        id: 'threatweight_tbl',
        value: 'off',
        name: gettext('Threat Weight'),
        cate: ['log threat-weight'],
      },
    ],
  },
  {
    id: 'clionly',
    icon: 'ffg ffg-cli',
    name: gettext('CLI Configurations'),
    items: [
      {
        id: 'clionlyid',
        default: true,
        value: false,
        name: gettext('CLI Configurations'),
      },
    ],
  },
];

// WEBPACK_REORG from static/js/app/dvm_config/providers/cfg_menu_service.js
export function getDvmMenuItemConfig(adom = null) {
  adom = adom || fiAdom.current();

  if (adom.is_fpx) {
    return fiFPXCfgMenuItems;
  }

  return fiFGTCfgMenuItems;
}

/**
 *get cd_item from ID
 */
export function getMenuItemByCdId(itmID, adom = null) {
  let ii, iii, len, item;
  adom = adom || fiAdom.current();

  let cfgItems = getDvmMenuItemConfig(adom);

  for (ii = 0, len = cfgItems.length; ii < len; ii++) {
    item = cfgItems[ii] || {};
    if (item.items && item.items.length > 0) {
      for (iii = 0; iii < item.items.length; iii++) {
        if (item.items[iii] && item.items[iii].id === itmID) {
          return item.items[iii];
        }
      }
    }
  }
}
