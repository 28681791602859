import { validIp4 } from 'fiutil';
import { neowiseIpInputField } from './FmkIpV4Input';
import { NwInput } from '@fafm/neowise-core';
import { toArray } from 'kit-array';

const seperators = [',', ' '];

export const FmkIpV4MultiInput = neowiseIpInputField(NwInput, {
  ipValidate: ({ max_argv }) => {
    return (value) => {
      const ips = toArray(value);
      const isValid = ips.every((ip) => {
        return !ip || validIp4(ip);
      });

      if (!isValid) {
        return gettext('Invalid IP address.');
      }

      if (max_argv && ips.length > max_argv) {
        return ngettext(
          'Exceeded max number of %s IP address',
          'Exceeded max number of %s IP addresses',
          max_argv
        ).printf([max_argv]);
      }
    };
  },
  toDisplayStr: (value) => {
    return Array.isArray(value) ? value.join(seperators[0]) : value || '';
  },
  toFormValue: (displayStr) => {
    let ips = toArray(displayStr);

    for (let seperator of seperators) {
      const regex = new RegExp(`\\${seperator}+`);
      ips = ips
        .map((ip) => {
          return ip.split(regex);
        })
        .flat();
    }

    return ips.map((ip) => ip.trim()).filter(Boolean);
  },
});
FmkIpV4MultiInput.displayName = 'FmkIpV4MultiInput';
