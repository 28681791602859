import { openConfirmModal } from 'rc_layout';
// import { fiDvmFolderService } from 'ra_dvm_util';
import { fiDvmFolderService } from '../dvm_folder';
import { fiMessageBox } from 'fi-messagebox';
import { fiWorkspace } from 'fi-workspace';
import { dispatch } from 'fistore';
import { go } from 'fistore/routing/slice';

export function deleteFolder(node) {
  const getPath = (node) => {
    const pathArray = node._oData.path.split('->');
    return '/' + pathArray.slice(1).join('/');
  };

  return new Promise((resolve, reject) => {
    openConfirmModal({
      content: gettext(
        'Are you sure you want to delete the folder "%s"?'
      ).printf([getPath(node)]),
      title: gettext('Confirm Deletion'),
      buttons: ['ok', 'cancel'],
    }).then(function () {
      const delPathArray = node._oData.path.split('->');
      if (
        parseInt(node.oid) === fiDvmFolderService.getUnAssingedDevsFolderId()
      ) {
        fiMessageBox.show(
          gettext('Can not delete the default folder.'),
          'danger',
          0
        );
        reject();
        return;
      }
      const delPath = delPathArray.slice(1);
      fiDvmFolderService
        .dbDecorator()
        .delete(delPath.join('/'))
        .then(
          () => {
            fiMessageBox.show(
              gettext('Successfully deleted the folder') +
                ` "${getPath(node)}".`,
              'success'
            );
            dispatch(go({ to: '/dvm/main/groups' }));
          },
          () => {
            fiMessageBox.show(
              gettext('Failed to delete the folder') + ` "${getPath(node)}".`,
              'danger',
              0
            );
          }
        );
    });
  });
}

export function saveWorkspace() {
  /*
    pure adom save or pure dev save. when we have adom save, all the devs & packges will be on the state of unlocked. Therefore, if we have any locked
    devs, then we can say it is a pure dev save
*/
  let devInfo = fiWorkspace.allDevInfo();
  let dirtyDevs = devInfo.getDevicesDirtyByMe();

  if (dirtyDevs.length) {
    // pure devs save
    fiWorkspace.devSaveMultiple(
      dirtyDevs.map((it) => {
        return { key: it.oid };
      })
    );
  } else {
    // pure adom save
    fiWorkspace.adomSave().then(() => {
      fiMessageBox.show(
        gettext('Configurations are successfully saved.'),
        'success'
      );
    });
  }
}
