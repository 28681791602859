import { neowiseFormikField } from './neowiseFormikField';
import { ProForm } from '@fafm/neowise-pro';
import { FmkErrorSpan } from '../formik_layout/FmkErrorSpan';
const { Row, Column, Line } = ProForm;

export const FmkTimePicker = neowiseFormikField('nw-time-picker');

export const FmkDatePicker = neowiseFormikField('nw-date-picker');

export const FmkDateTimeRow = ({
  label,
  fieldObj,
  rowProps = {},
  dateProps = {},
  timeProps = {},
}) => (
  <Row label={label} {...rowProps}>
    <Column>
      <Line>
        <div className={'tw-flex-1'}>
          <FmkDatePicker name={fieldObj.DATE} {...dateProps} />

          <FmkErrorSpan name={fieldObj.DATE} />
        </div>

        <div className={'tw-flex-1'}>
          <FmkTimePicker name={fieldObj.TIME} {...timeProps} />

          <FmkErrorSpan name={fieldObj.TIME} />
        </div>
      </Line>
    </Column>
  </Row>
);
