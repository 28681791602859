//@ts-ignore
import { fiCookies } from 'fi-cookies';

function wsProtocol() {
  return window.location.protocol === 'https:' ? 'wss:' : 'ws:';

  //return (window.location.protocol() == 'https') ? 'wss' : 'ws';
}

function defaultUrl() {
  // Bug 496713 - Handle IPv6 format returned from IE/Edge, which does not have brackets
  // IE 11 will return Syntax error for IPv6. Microsoft fixed this only in Edge 17134
  // See: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/16830441/
  let hostname = window.location.hostname;
  if (
    hostname.indexOf(':') !== -1 &&
    hostname[0] !== '[' &&
    hostname[hostname.length - 1] !== ']'
  ) {
    hostname = '[' + hostname + ']';
  }
  const port = window.location.port ? ':' + window.location.port : '';

  return wsProtocol() + '//' + hostname + port;
}

export function getWSServer() {
  return defaultUrl() + '/ws3';
}

export function getCSRFToken() {
  return fiCookies.get('HTTP_CSRF_TOKEN');
}
