import { validIp4, getValidNetMask } from 'fiutil';
import { neowiseIpInputField } from './FmkIpV4Input';
import { NwIpV4NetmaskInput } from '@fafm/neowise-core';
import { validateAny } from './util/util';
import { IPv4 } from 'ipaddr.js';
import { isString } from 'lodash';

export const FmkIpV4NetmaskInput = neowiseIpInputField(NwIpV4NetmaskInput, {
  ipValidate: (options) => {
    return validateAny(
      ...[ipV4NetmaskValidator, strictSubnetValidator].map((fn) => fn(options))
    );
  },
  toDisplayStr: (value) => {
    if (Array.isArray(value) && value.length === 2) {
      return value[0] + '/' + value[1];
    }
    return value ? value : '';
  },
  toFormValue: (displayStr, { autoFillEmptyValue, autoFillIpValue }) => {
    if (typeof displayStr === 'string') {
      let trimStr = displayStr.trim();

      if (autoFillEmptyValue && !trimStr) {
        return autoFillIpValue;
      }

      const ip_and_netmask = trimStr ? trimStr.split('/') : [];
      if (!ip_and_netmask[1]) {
        ip_and_netmask[1] = '255.255.255.255';
      } else {
        const validNetmaskValue = getValidNetMask(ip_and_netmask[1]);
        if (validNetmaskValue) {
          ip_and_netmask[1] = validNetmaskValue;
        }
      }
      return ip_and_netmask;
    }
    return Array.isArray(displayStr) ? displayStr : [];
  },
});
FmkIpV4NetmaskInput.displayName = 'FmkIpV4NetmaskInput';

export function ipV4NetmaskValidator({ wildcard }) {
  return (value) => {
    if (!value) return;
    // validate address
    if (typeof value === 'string') {
      value = value.trim().split('/');
    }

    if (!Array.isArray(value) || !value.length || !validIp4(value[0])) {
      return gettext('Invalid IP address.');
    }

    //validate netmask
    if (value[1]) {
      if (wildcard) {
        if (!validIp4(value[1])) {
          return gettext('Invalid wildcard netmask.');
        }
      } else if (!getValidNetMask(value[1])) {
        return gettext('Invalid IP netmask.');
      }
    }
  };
}

export function strictSubnetValidator({ strictPrefix }) {
  if (!strictPrefix) return;

  return (value) => {
    if (!value) return;

    const [ip, mask] = isString(value)
      ? value.split('/').map((s) => s.trim())
      : Array.isArray(value)
      ? value
      : [];

    try {
      const length =
        isString(mask) && IPv4.parse(mask).prefixLengthFromSubnetMask();
      if (!length) return;

      const result = IPv4.networkAddressFromCIDR(`${ip}/${length}`).toString();
      if (result !== ip) {
        return gettext(
          'Bits of the IP address will be truncated by the subnet mask.'
        );
      }
    } catch {
      // invalid mask or ip
      return;
    }
  };
}
