import UF from '@leeoniya/ufuzzy';

const fuzzyOpts: UF.Options = {
  intraMode: 1, //allow one char insertion, removal, substitution error
  interLft: 0,
};
export const ufuzzy = new UF(fuzzyOpts);

export const fuzzySearchStringArray = (
  arrToSearch: string[],
  wordToUse: string,
  configs?: {
    ufuzzyInstance?: UF; // ufuzzy instance with different options than fuzzyOpts above
    threshold?: number; // only sort when this many or fewer results
  }
) => {
  const { ufuzzyInstance = ufuzzy, threshold = 1e3 } = configs || {};

  const needle = wordToUse;
  const idxs = ufuzzyInstance.filter(arrToSearch, needle);
  const results = [];
  if (idxs != null && idxs.length > 0) {
    // sort/rank only when <= 1,000 items
    const infoThresh = threshold;

    if (idxs.length <= infoThresh) {
      const info = ufuzzyInstance.info(idxs, arrToSearch, needle);

      // order is a double-indirection array (a re-order of the passed-in idxs)
      // this allows corresponding info to be grabbed directly by idx, if needed
      const order = ufuzzyInstance.sort(info, arrToSearch, needle);

      // render post-filtered & ordered matches
      for (let i = 0; i < order.length; i++) {
        // using info.idx here instead of idxs because uf.info() may have
        // further reduced the initial idxs based on prefix/suffix rules
        results.push(arrToSearch[info.idx[order[i]]]);
      }
    } else {
      // render pre-filtered but unordered matches
      for (let i = 0; i < idxs.length; i++) {
        results.push(arrToSearch[idxs[i]]);
      }
    }
  }

  return results.sort((a, b) => {
    return (
      Math.abs(a.length - wordToUse.length) -
      Math.abs(b.length - wordToUse.length)
    );
  });
};
