import { unixToLocalString } from 'fi-datetime';

const unixMsToLocalString = (timestamp, format) =>
  unixToLocalString(timestamp / 1000, format);

const getNumberByArrayLength = (length) => {
  let unit = 1;
  switch (true) {
    case length > 100:
      unit = 6;
      break;
    case length > 50 && length < 100:
      unit = 5;
      break;
    case length > 30 && length < 50:
      unit = 4;
      break;
    case length > 30 && length < 20:
      unit = 3;
      break;
    case length < 20 && length > 10:
      unit = 2;
      break;
    default:
      unit = 1;
  }
  return unit;
};

const timeFormatter = {
  min: 'HH:mm:ss',
  hour: 'HH:mm',
  day: 'MMM DD HH:mm',
  month: 'MMM DD HH:mm',
  year: 'YYYY MMM DD',
};

const getTimeFormatter = (timePeriod) => {
  const d1 = new Date(timePeriod.max * 1000);
  const d2 = new Date(timePeriod.min * 1000);
  let diff = (d1.getTime() - d2.getTime()) / 1000 / 60;
  let mins = Math.abs(Math.round(diff));
  let unit = 'hour';
  switch (true) {
    case mins > 525600:
      unit = 'year';
      break;
    case mins > 60 * 24 && mins < 525600:
      unit = 'day';
      break;
    case mins > 60 && mins < 60 * 24:
      unit = 'hour';
      break;
    case mins < 60:
      unit = 'min';
      break;
    default:
      unit = 'hour';
  }
  return timeFormatter[unit];
};

export const fiCombChartUtil = {
  unixToLocalString,
  unixMsToLocalString,
  getTimeFormatter,
  getNumberByArrayLength,
};
