import React from 'react';
import { useFormikContext } from 'formik';
import { WorkflowApprovalMatrixCommon } from '../workspace/WorkflowApprovalMatrix';
import { NwProInputRow } from 'rc_layout';
import { autoId } from './util';
import { useAdminAvatar } from '../common/hook';
import { FmkRadioBtnGroup, FmkSwitch } from 'rc_form';

const AdomWorkspaceEdit = ({ isView }) => {
  const { values } = useFormikContext();
  const getAvatar = useAdminAvatar();
  const onSetValue = (formik, newApproval) => {
    formik.setFieldValue('approvalData.approvalRes', newApproval);
  };

  return (
    <>
      <NwProInputRow label={gettext('Workspace Mode')}>
        <FmkRadioBtnGroup
          name={'workspace_mode'}
          automationId={autoId('workspace')}
          choices={[
            { id: 'disabled', text: gettext('Disable') },
            { id: 'workspace', text: gettext('Workspace') },
            { id: 'workflow', text: gettext('Workflow') },
          ]}
          disabled={isView}
        />
      </NwProInputRow>

      {values.workspace_mode !== 'disabled' && (
        <NwProInputRow label={gettext('Lock Override')}>
          <FmkSwitch
            name='lock_override'
            automation-id={autoId('lock_override')}
            disabled={isView}
          />
        </NwProInputRow>
      )}

      {values.workspace_mode === 'workflow' && values.approvalData && (
        <WorkflowApprovalMatrixCommon
          getAdminAvatar={getAvatar}
          adomName={values.name}
          approval={values.approvalData.approvalRes}
          onSetValue={onSetValue}
          isView={isView}
        />
      )}
    </>
  );
};
AdomWorkspaceEdit.displayName = 'AdomWorkspaceEdit';
export default AdomWorkspaceEdit;
