import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

export const getDvmConfigState = (state: any) =>
  get(state, 'dvm.dvmConfig') || {};

export const getDvmConfigGlobalDisplayOptions = createSelector(
  getDvmConfigState,
  (dvmConfig) => {
    return dvmConfig.globalDisplayOptions || {};
  }
);

export const getDvmConfigGlobalDisplayOptionsByAdomOid = (
  adomOid: string | number
) =>
  createSelector(getDvmConfigGlobalDisplayOptions, (globalDisplayOptions) => {
    return get(globalDisplayOptions, adomOid);
  }) as unknown as (state: any) => string[] | undefined;

export const getDvmConfigMenuLayout = createSelector(
  getDvmConfigState,
  (dvmConfig) => {
    return dvmConfig.dvmConfigMenuLayout;
  }
);
