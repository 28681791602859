import { isString, isFunction } from 'fiutil';

import CodeMirror from '@fafm/codemirror';

import { CODEMIRROR_MODE, DIFF_VIEW_CM_ID } from './constant';

export {
  getCmMode,
  getLastWordInCodemirror,
  getBracketsByMode,
  getAdjustSelectPanePosFn,
  defaultFormatEntryFn,
  defaultMatchFn,
  defaultAdjustSelectPanePos,
  defaultOnSelect,
  validatorFn,
  initMergeView,
  destroyRegularCm,
  focusCm,
};

/** ----------------------------------------------------------------------------
 * Helper functions
 * -------------------------------------------------------------------------- */
function getCmMode(instance) {
  if (!instance) return;
  const mode = instance.getMode()?.name;
  return !!mode && mode !== 'null' ? mode : instance.options?.mode;
}

function getAdjustSelectPanePosFn(instance, offsets) {
  const SCREEN = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const offsetTop = (window.screen.height / SCREEN.height) * offsets.top;
  const offsetLeft = (window.screen.width / SCREEN.width) * offsets.left;
  const { line, ch } = instance.getCursor();
  const { top, left } = instance.charCoords({ line, ch });
  return {
    top: top + offsetTop > SCREEN.height ? SCREEN.height : top + offsetTop,
    left: left + offsetLeft > SCREEN.width ? SCREEN.width : left + offsetLeft,
  };
}

function getLastWordInCodemirror(cmInstance) {
  const { line: currLineNum } = cmInstance.getCursor();
  const currLineContent = cmInstance.getLine(currLineNum);
  if (isString(currLineContent)) {
    const words = currLineContent.split(' ');
    if (words.length === 0) return words;
    return words[words.length - 1];
  }
  return '';
}

function getBracketsByMode(mode) {
  let open = '';
  let close = '';
  switch (mode) {
    case CODEMIRROR_MODE.cli: {
      open = '$(';
      close = ')';
      break;
    }
    case CODEMIRROR_MODE.jinja2: {
      open = '{{';
      close = '}}';
      break;
    }
  }

  return { open, close };
}

/** ----------------------------------------------------------------------------
 * Defaults
 * -------------------------------------------------------------------------- */
// TODO: Make it flexible for other modes
function defaultFormatEntryFn(entry, options) {
  const id = entry?.id || entry?.name || '';
  const { mode } = options;
  const { open, close } = getBracketsByMode(mode);
  const completionText = `${id}${close}`;
  const displayText = `${open}${id}${close}`;
  return {
    id: id,
    fillTxt: completionText,
    text: displayText,
    value: entry.value,
  };
}

// TODO: Make it flexible for other modes
function defaultMatchFn(instance) {
  const currentWord = getLastWordInCodemirror(instance);
  if (!currentWord) return false;

  const firstTwoChars = currentWord.substring(0, 2);
  const len = currentWord.length;
  const lastChar = currentWord[len - 1];
  const lastTwoChars = currentWord.substring(len - 2);

  // have closing characters, dont show completion list
  const mode = getCmMode(instance);
  const { close } = getBracketsByMode(mode);
  if (lastChar === close || lastTwoChars === close) {
    return false;
  }

  const checkOpeningBrackets = (brackets) => {
    const first = brackets[0];
    const last = brackets[brackets.length - 1];
    switch (mode) {
      case CODEMIRROR_MODE.cli: {
        return [first, last].includes('$') || brackets === '$(';
      }
      case CODEMIRROR_MODE.jinja2: {
        return brackets === '{{';
      }
      default: {
        return false;
      }
    }
  };

  const toCheck = [firstTwoChars, lastTwoChars];
  return toCheck.some(checkOpeningBrackets);
}

function defaultAdjustSelectPanePos(instance) {
  return getAdjustSelectPanePosFn(instance, { top: 140, left: 410 });
}

function defaultOnSelect(instance, value) {
  const mode = getCmMode(instance);
  const currentWord = getLastWordInCodemirror(instance);
  let res = value;
  if (mode === CODEMIRROR_MODE.cli) {
    if (!currentWord.includes('(')) {
      res = `(${value}`;
    }
  }

  return res;
}

// eslint-disable-next-line
function validatorFn({ text, options, instance, isNewError }) {
  if (!instance) return;

  const errors = options?.errors || [];
  const output = [];

  for (let i = 0; i < errors.length; i++) {
    const error = errors[i];

    if (error) {
      if (error.line <= 0) {
        if (window.console) {
          window.console.warn(
            'Cannot display error (invalid line ' + error.line + ')',
            error
          );
        }
        continue;
      }

      const start = error.start ?? (error.character ? error.character - 1 : 0);
      const end =
        error.end ??
        (error.character
          ? start + 1
          : instance?.getLine(error.line - 1)?.length || 1);

      const hint = {
        message: error.reason,
        severity: error.code
          ? error.code.startsWith('W')
            ? 'warning'
            : 'error'
          : 'error',
        from: CodeMirror.Pos(error.line - 1, start),
        to: CodeMirror.Pos(error.line - 1, end),
      };

      output.push(hint);

      if (isNewError) {
        const scrollInfo = { line: error.line - 1, ch: start };
        instance.setCursor(scrollInfo);
        instance.scrollIntoView(scrollInfo);
      }
    }
  }

  return output;
}

function initMergeView({
  mode,
  orig,
  preview,
  collapseIdentical = false,
  hasError,
  ...rest
}) {
  const target = document.querySelector(`#${DIFF_VIEW_CM_ID}`);
  target.innerHTML = '';
  const mergeView = CodeMirror.MergeView(target, {
    mode,
    origLeft: hasError ? '' : orig || '', // left panel
    value: preview, // right panel
    readOnly: 'nocursor',
    lineNumbers: true,
    highlightDifferences: true,
    connect: 'align',
    collapseIdentical,
    fixedGutter: false,
    revertButtons: false,
    showCursorWhenSelecting: true,
    ...rest,
    diff: '',
  });
  return {
    left: mergeView.leftOriginal(),
    editor: mergeView.editor(),
  };
}

function destroyRegularCm(instance) {
  if (instance && isFunction(instance.toTextArea)) {
    instance.toTextArea();
  }
}

function focusCm(instance) {
  if (instance && isFunction(instance.focus)) {
    instance.focus();
  }
}
