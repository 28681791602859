import { isFunction } from 'lodash';
import {
  Ack,
  FafmType,
  FafmMessage,
  Context,
  Severity,
  FafmOpenFile,
  FileMessageContent,
  CaptureStatus,
  Platform,
} from './types';
import { checkAck } from './utils';
declare const chrome: {
  runtime: {
    sendMessage: (id: string, message: FafmMessage) => Promise<unknown>;
  };
};

/**
 * Reject to decline this capture request.
 */
type ShouldStartCallback = (options: {
  pageReload: boolean;
}) => Promise<void | Partial<{ captureAfter: VoidFunction }>>;
/**
 * [Optional] return a clean up function which will be called when debugger stops.
 */
type StartCallback = (
  startedSeconds: number
) => StopCallback | void | Promise<StopCallback | void>;
/**
 * Called when debugger stops.
 */
type StopCallback = VoidFunction;

let _fafmChromeExtensionID = '';
let sendExtensionMessageImpl = noAck;

export const _internal = {
  // get extensionID() {
  //   return _fafmChromeExtensionID;
  // },
  set extensionID(id: string) {
    _fafmChromeExtensionID = id;
    sendExtensionMessageImpl = id ? sendMessageImpl : noAck;
  },
  shouldStart: undefined as undefined | ShouldStartCallback,
  captureStatus: undefined as CaptureStatus | undefined,
  startedSeconds: 0,
  didStart: new Set<StartCallback>(),
  willStop: new Set<StopCallback>(),
};

async function noAck(message: FafmMessage) {
  return Promise.resolve({ ack: false });
}

async function sendMessageImpl<T extends { ack: boolean }>(
  message: FafmMessage
) {
  return chrome.runtime.sendMessage(
    _fafmChromeExtensionID,
    message
  ) as Promise<T>;
}

export async function sendExtensionMessage<T = unknown>(
  message: FafmMessage
): Promise<Ack<T> | { ack: false }> {
  return sendExtensionMessageImpl(message);
}

export function log(message: string, severity: Severity = Severity.Info) {
  void sendExtensionMessage({
    messageID: FafmType.Log,
    messageContent: {
      timestamp: Date.now(),
      from: Context.Fafm,
      message,
      severity,
    },
  });
}

export async function createCaptureFile<Data = unknown>(
  fileName: string,
  options?: Omit<FafmOpenFile['messageContent'], 'fileName'>
) {
  await checkAck(
    sendExtensionMessage({
      messageID: FafmType.OpenFile,
      messageContent: {
        fileName,
        ...options,
      },
    })
  );

  type Content = Omit<FileMessageContent<Data>, 'fileName'>;

  const send = async (data?: Content['data'], batch?: Content['batch']) => {
    await sendExtensionMessage({
      messageID: FafmType.WriteFile,
      messageContent: {
        fileName,
        data,
        batch,
      },
    });
  };

  const complete = async (data?: Content['data'], batch?: Content['batch']) => {
    await sendExtensionMessage({
      messageID: FafmType.CloseFile,
      messageContent: {
        fileName,
        data,
        batch,
      },
    });
  };

  return { send, complete };
}

export function shouldDebuggerStart(
  callback: ShouldStartCallback
): VoidFunction {
  if (_internal.shouldStart) {
    return () => {};
  }
  _internal.shouldStart = callback;
  return () => {
    _internal.shouldStart = undefined;
  };
}

export function debuggerDidStart(callback: StartCallback): VoidFunction {
  if (_internal.captureStatus === CaptureStatus.Capturing) {
    void handleDidStartResult(callback(_internal.startedSeconds));
  }
  _internal.didStart.add(callback);
  return () => {
    _internal.didStart.delete(callback);
  };
}

export function debuggerWillStop(callback: StopCallback): VoidFunction {
  _internal.willStop.add(callback);
  return () => {
    _internal.willStop.delete(callback);
  };
}

export async function handleDidStartResult(result: ReturnType<StartCallback>) {
  const willStop = await result;

  if (!isFunction(willStop)) return;
  if (_internal.captureStatus !== CaptureStatus.Capturing) return willStop();

  const unregister = debuggerWillStop(() => {
    unregister();
    willStop();
  });
}

export const getPlatformInfo = (): Platform => {
  return {
    modelName: MACROS.SYS.CONFIG_PROD_NAME,
    major: MACROS.SYS.CONFIG_MAJOR_NUM,
    minor: MACROS.SYS.CONFIG_MINOR_NUM,
    patch: MACROS.SYS.CONFIG_PATCH_NUM,
    build: MACROS.SYS.CONFIG_BUILD_NUMBER || 0,
    version:
      'v' +
      MACROS.SYS.CONFIG_MAJOR_NUM +
      '.' +
      MACROS.SYS.CONFIG_MINOR_NUM +
      '.' +
      MACROS.SYS.CONFIG_PATCH_NUM +
      ' ' +
      MACROS.SYS.CONFIG_BUILD_LABEL +
      ' build' +
      (MACROS.SYS.CONFIG_BUILD_NUMBER
        ? String(MACROS.SYS.CONFIG_BUILD_NUMBER).padStart(4, '0')
        : 'N/A'),
  };
};
