import { ProForm, ProTable } from '@fafm/neowise-pro';
import { Formik } from 'formik';
import {
  FmkInput,
  FmkSwitch,
  fmkFooterButtons,
  FmkErrorSpan,
  FmkRadioBtnGroup,
} from 'rc_form';
import { useMemo } from 'react';
import { adomRevRequest } from './request';
import { get, noop } from 'lodash';
import { useApiRequest } from 'rh_util_hooks';
const { Header, Body, Footer, Row, Column, Section } = ProForm;

export const AdomRevisionSetting = ({ $opener }) => {
  const { state: setting, isLoading: loadingSetting } = useApiRequest({
    loader: async () => {
      /*
      {
        "adom_rev_auto_delete": 1,
        "adom_rev_max_revs": 120,
        "adom_rev_max_days": 30
      }
      */
      const data = await adomRevRequest.getSettings();
      return data;
    },
    defaultValue: {},
    dependencies: [],
  });

  const initialValues = useMemo(
    () => ({ rev: settingParser(setting) }),
    [setting]
  );

  const onSubmit = ({ rev }) => {
    const result = valueConvert(rev);
    adomRevRequest
      .updateSettings(result)
      .then(() => {
        $opener.resolve(result);
      })
      .catch(noop);
  };

  const autoId = (suffix) => `adom_revision_setting:${suffix}`;
  const onCancel = () => $opener.reject();

  if (loadingSetting) {
    return <ProTable.LoadingSpinner loading={true} />;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values }) => (
        <>
          <Header>{gettext('ADOM Revision Settings')}</Header>
          <Body>
            {template({
              values,
              autoId,
              field: (attr) => `rev.${attr}`,
              renderFields: ({ fields, statusToggle }) => (
                <Section>
                  <Row label={gettext('Auto Delete Revision')}>
                    <Column>{statusToggle(null)}</Column>
                  </Row>
                  {fields}
                </Section>
              ),
            })}
          </Body>
          <Footer>
            {fmkFooterButtons({ canWrite: true, getAutoId: autoId, onCancel })}
          </Footer>
        </>
      )}
    </Formik>
  );
};

// include/sysmgr/parser.h /* adom revision */
let DelMethods = {
  Disabled: 0,
  Revisions: 1,
  Days: 2,
};

export function settingParser(setting) {
  const defaultMethod = DelMethods.Days;
  const result = {
    auto_del_enable: true,
    setting: {
      adom_rev_auto_delete: defaultMethod,
      adom_rev_max_revs: 120,
      adom_rev_max_days: 90,
      ...setting,
    },
  };
  if (result.setting.adom_rev_auto_delete === DelMethods.Disabled) {
    result.auto_del_enable = false;
    result.setting.adom_rev_auto_delete = defaultMethod;
  }
  return result;
}

export function valueConvert(values) {
  const { auto_del_enable, setting } = values;
  const result = {
    ...setting,
    adom_rev_auto_delete: !auto_del_enable ? 0 : setting.adom_rev_auto_delete,
  };
  return result;
}

function template({ values, autoId, field, renderFields }) {
  const autoDeleteEnabled = get(values, field('auto_del_enable'));

  const fields = autoDeleteEnabled ? (
    <>
      <Row label={gettext('Delete Method')}>
        <Column>
          <FmkRadioBtnGroup
            name={field('setting.adom_rev_auto_delete')}
            automationId={autoId('adom_rev_auto_delete')}
            choices={[
              { text: gettext('By Days'), id: DelMethods.Days },
              {
                text: gettext('By Revisions'),
                id: DelMethods.Revisions,
              },
            ]}
          />
        </Column>
      </Row>
      {(() => {
        switch (get(values, field('setting.adom_rev_auto_delete'))) {
          case DelMethods.Days: {
            return (
              <Row label={gettext('Max')}>
                <Column>
                  <FmkInput
                    type='number'
                    name={field('setting.adom_rev_max_days')}
                    min={1}
                    max={9999}
                    automationId={autoId('adom_rev_max_days')}
                    suffix={gettext('Days')}
                  ></FmkInput>
                  <FmkErrorSpan name={field('setting.adom_rev_max_days')} />
                </Column>
              </Row>
            );
          }
          case DelMethods.Revisions: {
            return (
              <Row label={gettext('Max')}>
                <Column>
                  <FmkInput
                    type='number'
                    name={field('setting.adom_rev_max_revs')}
                    min={1}
                    max={9999}
                    automationId={autoId('adom_rev_max_revs')}
                    suffix={gettext('Revisions')}
                  ></FmkInput>
                  <FmkErrorSpan name={field('setting.adom_rev_max_revs')} />
                </Column>
              </Row>
            );
          }
        }
      })()}
    </>
  ) : null;

  const statusToggle = (children) => (
    <FmkSwitch
      name={field('auto_del_enable')}
      automationId={autoId('auto_del_enable')}
    >
      {children}
    </FmkSwitch>
  );

  return renderFields({ fields, statusToggle });
}

export function RevisionSettingAsSection(props) {
  const renderFields = ({ statusToggle, fields }) => (
    <Section title={statusToggle(gettext('ADOM Revision'))}>{fields}</Section>
  );
  return template({ ...props, renderFields });
}
