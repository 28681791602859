/* eslint-disable no-unused-vars */

// import { fiStore } from 'fistore';
import { fiHttp } from 'fi-http';
import { createApi } from '@reduxjs/toolkit/query/react';

// The baseQuery given to `createApi` should return value, or promise that
// resolves to a value in form of {data:...} or {error: ...}.
// Note that even in case of query error, it should be a "resolve", not
// "reject". So a reject only handles unexpected situation.
const fazBaseQuery = async (args, queryCacheLifecycleApi, extraOptions) => {
  //eslint-disable-line no-unused-vars
  const { signal, dispatch, getState } = queryCacheLifecycleApi; //eslint-disable-line no-unused-vars
  const method = args.method || 'get';

  return new Promise((resolve) => {
    signal.addEventListener('abort', () => {
      resolve({ error: 'aborted', status: -2 });
    });

    let rawPromise;
    if (method === 'post') {
      rawPromise = fiHttp.post(args.url, args.data, { ...args.cfg, signal });
    } else {
      rawPromise = fiHttp.get(args.url, { ...(args.cfg || {}), signal });
    }
    rawPromise.then(
      (resp) => {
        if (typeof args.unwrap === 'function') {
          resolve(args.unwrap(resp));
          return;
        }
        resolve({
          data: resp.data,
          meta: resp,
          originalArgs: args,
        });
      },
      (err) => {
        // err is the XMLHTTPRequest error object.
        if (args?.errorIsData) {
          try {
            resolve({ error: err.response.data, meta: err });
          } catch (ex) {
            // eslint-disable-line no-unused-vars
            console.error(ex, err);
            resolve({ error: err, meta: err });
          }
        } else {
          resolve({ error: err, meta: err });
        }
      }
    );
  });
};

export const fazRTQ = createApi({
  reducerPath: 'FazRTQ', // where internal states are stored in main store.
  keepUnusedDataFor: 30,
  refetchOnMountOrArgChange: false, // false is default behaviour
  baseQuery: fazBaseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    //eslint-disable-line no-unused-vars
  }),
});

export default fazRTQ;
