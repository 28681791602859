import { ProForm } from '@fafm/neowise-pro';
import { useEffect } from 'react';
import { useRestartTimer } from 'react_apps/ra-auth/hooks/useRestartTimer';
import { Spinner } from 'react_apps/ra-auth/Spinner';

export default function RestartModal({ message }) {
  const restartWait = useRestartTimer();

  useEffect(restartWait, []);

  return (
    <>
      <ProForm.Header>{gettext('Restarting')}</ProForm.Header>
      <ProForm.Body>
        <p>{message}</p>
        <p className='tw-text-center'>
          <Spinner color='rgb(var(--nw-color-primary-500))' />
        </p>
      </ProForm.Body>
    </>
  );
}
