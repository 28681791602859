import { getWsProxy, fiSocket } from 'fi-websocket';
import { flatui_proxy } from '../../utils';

function flatui_proxy_wrap(cmd) {
  return {
    url: '/cgi-bin/module/flatui_proxy',
    method: 'get',
    params: {
      ...cmd,
      dataChunked: {},
    },
  };
}

export const fetch_device_list = (adomOid) => {
  const request = {
    url: `/gui/adoms/${adomOid}/devices/list`,
    method: 'get',
    params: {
      fields: [
        'all',
        {
          vdoms: ['all'],
        },
      ],
    },
  };

  const r = flatui_proxy_wrap(request);

  let proxy = getWsProxy();
  return proxy.createDispatchRequest(r);
};

export const fetch_pkg_list = (cfgs) => {
  const request = {
    url: '/gui/alladom/dvm/details',
    method: 'get',
    params: {
      chunkSize: 200,
      model: cfgs.model,
      config: cfgs.config,
      ppsync: cfgs.ppsync,
    },
  };
  const r = flatui_proxy_wrap(request);
  return fiSocket.get_ws_proxy().createDispatchRequest(r);
};

export const fetch_device = (adomOid, deviceOid, includeVdom = true) => {
  const url = `/gui/adoms/${adomOid}/devices/${deviceOid}/device`;
  return flatui_proxy({
    url,
    method: 'get',
    params: {
      fields: [
        'all',
        {
          vdoms: ['all'],
        },
      ],
      includeVdom,
    },
  });
};

export const fetch_device_bulk = (
  adomOid,
  deviceOids = [],
  includeVdom = true
) => {
  const url = `/gui/adoms/${adomOid}/devices/bulk`;
  return flatui_proxy({
    url,
    method: 'get',
    params: {
      fields: [
        'all',
        {
          vdoms: ['all'],
        },
      ],
      includeVdom,
      deviceOids,
      bulk: true,
    },
  });
};
