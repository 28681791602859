import { fiHttpGet } from 'fi-http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { listenerMiddleware } from 'fistore/middlewares';
import { fetchSessionAdom } from '../session/adom/slice';
import { hasFazFeature } from 'fistore/session/sysConfig/selectors';

const initialState = {
  hasIotEp: false,
};

const slice = createSlice({
  name: 'iot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIotEpCount.fulfilled, (state, { payload }) => {
      state.hasIotEp = !!payload;
    });
  },
});

const fetchIotEpCount = createAsyncThunk(
  'fabric/iot/FETCH_IOT_EP',
  async () => {
    const TimeFormat = 'YYYY-MM-DD HH:mm:ss';
    let startTime = '1970-01-01 00:00:00';
    let endTime = moment().format(TimeFormat);
    const resp = await fiHttpGet('/p/util/ueba_stats/', {
      params: {
        filter: 'category=IOT',
        mode: 'endpoints',
        startTime,
        endTime,
        'stats-item': 'total-count',
      },
    });
    let totalCount = parseInt(resp?.data?.[0]?.['total-count']) || 0;
    return totalCount;
  }
);

// fetch on adom change
listenerMiddleware.startListening({
  actionCreator: fetchSessionAdom.fulfilled,
  effect: async (action, { dispatch, condition, cancelActiveListeners }) => {
    cancelActiveListeners();
    if (await condition((_, currState) => hasFazFeature(currState))) {
      dispatch(fetchIotEpCount());
    }
  },
});

export { fetchIotEpCount };

export default slice.reducer;
