import React, { useEffect, useMemo } from 'react';
import { useApiRequest } from 'rh_util_hooks';
import { getMapOfAssignedDevicesAndFirmwareTemplates } from '../util';

import {
  NwProBody,
  NwProSection,
  NwProInputRow,
  NwProFooter,
  OkBtn,
  CancelBtn,
} from 'rc_layout';
import { ObjSSelect2 } from 'rc_select';
import { NwSpinner } from '@fafm/neowise/react';
import ReactDOMServer from 'react-dom/server';

import { useWizard } from 'rc_wizard';
import { WizardButtonRenderer } from '../WizardButtonRenderer';
import { fiErrorMessage } from 'fi-messagebox';

export const ChooseDevicesToAssign = ({
  assignToDevs = [],
  $opener,
  setChosenAssignToDevs,
  chosenAssignToDevs,
  selectedProf,
  newTemplateData,
  isNewProf,
  onSubmitFirmwareTemplate,
}) => {
  const wizardCtx = useWizard();

  const { state: deviceNameTemplateMap, isLoading } = useApiRequest({
    loader: getMapOfAssignedDevicesAndFirmwareTemplates,
    defaultValue: {},
  });

  const deviceList = useMemo(() => {
    const res = [];
    for (const dev of assignToDevs) {
      if (deviceNameTemplateMap[dev.name]) {
        res.push({
          ...dev,
          templateName: deviceNameTemplateMap[dev.name].name,
        });
      } else {
        res.push({ ...dev });
      }
    }
    return res;
  }, [assignToDevs, deviceNameTemplateMap]);

  useEffect(() => {
    setChosenAssignToDevs(deviceList);
  }, [deviceList]);

  const onSubmit = async () => {
    try {
      await onSubmitFirmwareTemplate(
        newTemplateData,
        isNewProf,
        chosenAssignToDevs
      );
      wizardCtx.goToNext();
    } catch (e) {
      fiErrorMessage.show(e);
    }
  };

  const buttons = [
    {
      id: 'next',
      text: gettext('Next >'),
      Component: OkBtn,
      props: {
        onClick: onSubmit,
      },
    },
    {
      id: 'cancel',
      text: gettext('Cancel'),
      Component: CancelBtn,
      props: {
        onClick: () => $opener.reject(),
      },
    },
  ];

  const chosenNames = chosenAssignToDevs.map((dev) => dev.name);
  const templateName = selectedProf ? selectedProf.name : newTemplateData.name;

  return (
    <>
      <NwProBody>
        <NwProSection
          title={gettext('Assigning Devices to %s').printf([templateName])}
        >
          <NwProInputRow label={gettext('Choose devices to assign')}>
            {isLoading || !Array.isArray(deviceList) ? (
              <NwSpinner />
            ) : (
              <ObjSSelect2
                source={() => deviceList || []}
                idAttr={'name'}
                textAttr={'name'}
                value={chosenNames}
                onChange={(_, selected) => setChosenAssignToDevs(selected)}
                multipleSelect
                formatChoiceHTML={formatChoiceHTML(selectedProf)}
                formatSelectedHTML={formatChoiceHTML(selectedProf)}
              />
            )}
          </NwProInputRow>
        </NwProSection>
      </NwProBody>
      <NwProFooter>
        <WizardButtonRenderer btns={buttons} />
      </NwProFooter>
    </>
  );
};

function formatChoiceHTML(selectedProf) {
  return function (item) {
    if (!item.templateName) return item.name;

    const isSameProf = selectedProf
      ? selectedProf.name === item.templateName
      : false;

    return ReactDOMServer.renderToString(
      <span className='tw-flex tw-items-center'>
        {isSameProf ? (
          ''
        ) : (
          <nw-icon
            title={gettext(
              'This device is already assigned to the template %s\n Assigning it now will remove it from template %s'
            ).printf([item.templateName, item.templateName])}
            name='warning'
            class='color-yellow tw-mr-1'
          />
        )}
        {item.name}
        {isSameProf ? (
          ''
        ) : (
          <span
            class='tw-pl-1'
            style={{ color: 'grey' }}
          >{`[${item.templateName}]`}</span>
        )}
      </span>
    );
  };
}
