export default function withAOP(target: any, aspects: Array<any>) {
  const get = (target: any, prop: any, receiver: any) => {
    if (typeof target[prop] !== 'function') {
      return Reflect.get(target, prop, receiver);
    }

    return async function (...args: Array<any>) {
      const run = (point: string) =>
        aspects.forEach((aspect) => aspect[prop]?.(...args)[point]?.(target));
      run('before');
      const result = Reflect.apply(target[prop], target, args);
      await result;
      run('after');
      return result;
    };
  };

  return new Proxy(target, {
    get,
  });
}
