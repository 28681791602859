import { createSelector } from '@reduxjs/toolkit';
import { getSessionAdomOid } from '../../session/adom/selectors';
export const getDvmChecksums = (state) => state.dvm?.checksums;
export const getDeviceChecksum = createSelector(
  getDvmChecksums,
  getSessionAdomOid,
  (checksums, adomOid) => {
    return checksums?.[adomOid];
  }
);
