import { getCloudStorageCate } from './cloudStorageCates';
import { fiFmgHttp } from 'fi-http';
import { escapeKey } from 'kit-escape';

export const cloudStorageAPI = {
  getCate: _getCate,
  getSyntax: _getSyntax,
  getTemplate: _getTemplate,
  getList: _getList,
  getObject: _getObject,
  setObject: _setObject,
  delObject: _delObject,
  getSyncOptTxt,
  getSyncOptTxtByVal,
};

function _getCate(id) {
  return getCloudStorageCate(id);
}
function _getSyntax(cate) {
  cate = _getCate(cate);
  let req = {
    method: 'get',
    params: [
      {
        url: cate.url,
        option: 'syntax',
      },
    ],
  };
  return fiFmgHttp.forward(req).then((resp) => resp?.[0]?.data?.[cate.cate]);
}

function _getTemplate(cate) {
  cate = _getCate(cate);
  let req = {
    method: 'get',
    params: [
      {
        url: cate.url,
        'object template': 1,
      },
    ],
  };
  return fiFmgHttp.forward(req).then((resp) => resp?.[0]?.data);
}

function _getObject(cate, key) {
  cate = _getCate(cate);
  let req = {
    method: 'get',
    params: [
      {
        url: `${cate.url}/${escapeKey(key)}`,
      },
    ],
  };
  return fiFmgHttp.forward(req).then((resp) => resp?.[0]);
}
function _getList(cate) {
  cate = _getCate(cate);
  let req = {
    method: 'get',
    params: [
      {
        url: cate.url,
      },
    ],
  };
  return fiFmgHttp.forward(req).then((resp) => resp?.[0]);
}
function _setObject(isUpdate, cate, obj) {
  cate = _getCate(cate);
  let req = {
    method: isUpdate ? 'update' : 'add',
    params: [
      {
        url: cate.url,
        data: obj,
      },
    ],
  };
  return fiFmgHttp.query(req).then((resp) => resp?.[0]);
}
function _delObject(cate, key) {
  cate = _getCate(cate);
  let req = {
    method: 'delete',
    params: [
      {
        url: `${cate.url}/${escapeKey(key)}`,
      },
    ],
  };
  return fiFmgHttp.query(req).then((resp) => resp?.[0]);
}

const SYNC_OPT_TXT = {
  'on-schedule': gettext('On Schedule'),
  'on-rolling': gettext('On Rolling'),
};
function getSyncOptTxt(opt) {
  return SYNC_OPT_TXT[opt] || opt;
}

const SYNC_OPT_TXT_BY_VAL = {
  [MACROS.FAZ_SYNTAX.CLOUD_SYNC_ON_SCHEDULE]: gettext('On Schedule'),
  [MACROS.FAZ_SYNTAX.CLOUD_SYNC_ON_ROLLING]: gettext('On Rolling'),
};
function getSyncOptTxtByVal(opt) {
  return SYNC_OPT_TXT_BY_VAL[opt] || opt;
}
