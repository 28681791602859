import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fiStoreTheme } from 'fistore';
import { isNumber, isUndefined } from 'lodash';

const { pushPreviewStack, popPreviewStack } = fiStoreTheme;

const idToConcept = (dbTheme) => {
  const isHighContrast = dbTheme === MACROS.THEMES.GUI_THEME_CONTRAST_DARK;
  return {
    isHighContrast,
    regularTheme:
      isUndefined(dbTheme) || isHighContrast
        ? MACROS.INTEROP.SYS.GUI_THEME_DEFAULT
        : dbTheme,
  };
};

const conceptToId = ({ isHighContrast, regularTheme }) => {
  return isHighContrast ? MACROS.THEMES.GUI_THEME_CONTRAST_DARK : regularTheme;
};
// theme is stored as single value in db
// This adapter turns it into regular theme value, and isHighContrast value
// It also handles theme preview when dbTheme value changes
export const useThemeAdapter = ({
  dbTheme,
  setDbTheme,
  previewCondition = null,
}) => {
  const dispatch = useDispatch();
  const prevRegTheme = useRef();
  // initial value
  // dbTheme is undefined before loading the sys config
  const { regularTheme, isHighContrast } = useMemo(() => {
    return idToConcept(dbTheme);
  }, [dbTheme]);

  useEffect(() => {
    if (!prevRegTheme.current && isNumber(dbTheme)) {
      prevRegTheme.current = regularTheme;
    }
  }, [dbTheme]);

  const setRegularTheme = useCallback((val) => {
    if (val >= 0) {
      const _dbTheme = conceptToId({
        isHighContrast: false,
        regularTheme: val,
      });
      setDbTheme(_dbTheme);
      prevRegTheme.current = val;
    }
  }, []);

  const setIsHighContrast = useCallback((val) => {
    const _dbTheme = conceptToId({
      isHighContrast: val,
      regularTheme: prevRegTheme.current,
    });
    setDbTheme(_dbTheme);
  }, []);

  const doPreview = !previewCondition || previewCondition();

  // setup a new stack for the theme preview
  useEffect(() => {
    if (doPreview) {
      dispatch(pushPreviewStack());
    }
    return () => {
      doPreview && dispatch(popPreviewStack());
    };
  }, [doPreview]);

  return {
    regularTheme,
    setRegularTheme,
    isHighContrast,
    setIsHighContrast,
  };
};
