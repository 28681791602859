/*****************************************
 * this file is auto generated from syntax tool
 * pyparse/gui_gen_category.py
 ***************************************/
const CATE2NUM = {
  'system vdom': 1,
  'system global': 2,
  'system accprofile': 3,
  'system accprofile/fwgrp-permission': 4,
  'system admin': 5,
  'system admin/dashboard': 6,
  'system vdom-link': 7,
  'system interface': 8,
  'user group/match': 9,
  'system interface/secondaryip': 10,
  'system interface/ipv6': 11,
  'system interface/ipv6/ip6-prefix-list': 12,
  'system ha': 13,
  'system ha/secondary-vcluster': 14,
  'system dns': 15,
  'system replacemsg mail': 16,
  'system replacemsg http': 17,
  'system replacemsg ftp': 18,
  'system replacemsg nntp': 19,
  'system replacemsg alertmail': 20,
  'system replacemsg fortiguard-wf': 21,
  'system replacemsg spam': 22,
  'system replacemsg admin': 23,
  'system replacemsg auth': 24,
  'system replacemsg im': 25,
  'system replacemsg sslvpn': 26,
  'system snmp sysinfo': 27,
  'system snmp community': 28,
  'system snmp community/hosts': 29,
  'system autoupdate override': 30,
  'system autoupdate push-update': 31,
  'system autoupdate schedule': 32,
  'system autoupdate tunneling': 33,
  'system autoupdate clientoverride': 34,
  'system fmc': 36,
  'system fm': 37,
  'webfilter ftgd-local-cat': 38,
  'webfilter urlfilter': 47,
  'webfilter urlfilter/entries': 48,
  'webfilter fortiguard': 49,
  'webfilter ftgd-ovrd': 50,
  'webfilter ftgd-local-rating': 51,
  'vpn certificate ca': 52,
  'vpn certificate local': 53,
  'vpn certificate crl': 54,
  'vpn certificate remote': 55,
  'spamfilter bword': 56,
  'spamfilter bword/entries': 57,
  'spamfilter ipbwl': 58,
  'spamfilter ipbwl/entries': 59,
  'spamfilter dnsbl': 60,
  'spamfilter dnsbl/entries': 61,
  'spamfilter emailbwl': 62,
  'spamfilter emailbwl/entries': 63,
  'spamfilter mheader': 64,
  'spamfilter mheader/entries': 65,
  'spamfilter fortishield': 66,
  'spamfilter iptrust': 67,
  'spamfilter iptrust/entries': 68,
  'spamfilter options': 69,
  'system fortiguard': 70,
  'system fortiguard/srv-ovrd-list': 71,
  'imp2p policy': 72,
  'imp2p old-version': 73,
  'imp2p aim-user': 74,
  'imp2p icq-user': 75,
  'imp2p msn-user': 76,
  'imp2p yahoo-user': 77,
  'system fmc-slot': 81,
  'ips custom': 85,
  'ips global': 86,
  'system fortianalyzer': 87,
  'system fortianalyzer2': 88,
  'system fortianalyzer3': 89,
  'log syslogd setting': 90,
  'log syslogd2 setting': 91,
  'log syslogd3 setting': 92,
  'log webtrends setting': 93,
  'log fortianalyzer setting': 94,
  'log fortianalyzer2 setting': 95,
  'log fortianalyzer3 setting': 96,
  'log syslogd filter': 97,
  'log syslogd2 filter': 98,
  'log syslogd3 filter': 99,
  'log webtrends filter': 100,
  'log fortianalyzer filter': 101,
  'log fortianalyzer2 filter': 102,
  'log fortianalyzer3 filter': 103,
  'system alertemail': 115,
  'gui console': 116,
  'system session-helper': 117,
  'system tos-based-priority': 118,
  'system auto-install': 119,
  'system console': 120,
  'system bug-report': 121,
  'antivirus filepattern': 122,
  'antivirus filepattern/entries': 123,
  'antivirus service': 124,
  'antivirus heuristic': 125,
  'antivirus quarantine': 127,
  'antivirus quarfilepattern': 128,
  'system settings': 129,
  'system ipv6-tunnel': 130,
  'system gre-tunnel': 131,
  'system arp-table': 132,
  'system session-ttl': 133,
  'system session-ttl/port': 134,
  'system dhcp server': 135,
  'system dhcp server/exclude-range': 136,
  'system dhcp reserved-address': 137,
  'system zone': 138,
  'firewall address': 140,
  'firewall address6': 141,
  'firewall addrgrp': 142,
  'user fsae': 144,
  'user radius': 145,
  'user ldap': 146,
  'user local': 147,
  'user peer': 148,
  'user peergrp': 149,
  'system object-tag': 150,
  'vpn ssl settings': 151,
  'user group': 152,
  'system elbc': 153,
  'vpn certificate ocsp': 154,
  'vpn ipsec phase1': 155,
  'vpn ipsec phase2': 156,
  'vpn ipsec manualkey': 157,
  'vpn ipsec concentrator': 158,
  'system snmp community/hosts6': 159,
  'vpn ipsec phase1-interface': 160,
  'vpn ipsec phase2-interface': 161,
  'vpn ipsec manualkey-interface': 162,
  'vpn pptp': 163,
  'vpn l2tp': 164,
  'vpn ipsec forticlient': 165,
  'vpn ssl web favorite': 166,
  'firewall service custom': 167,
  'firewall service group': 168,
  'system dhcp6 server': 169,
  'firewall schedule onetime': 170,
  'firewall schedule recurring': 171,
  'firewall ippool': 172,
  'firewall vip': 173,
  'firewall vip/realservers': 174,
  'firewall vipgrp': 175,
  'system dhcp6 server/ip-range': 176,
  'firewall ipmacbinding setting': 177,
  'firewall ipmacbinding table': 178,
  'firewall addrgrp6': 179,
  'system port-pair': 180,
  'firewall policy': 181,
  'system 3g-modem custom': 182,
  'application rule-settings': 183,
  'user fortitoken': 184,
  'user sms-provider': 185,
  'webfilter profile/override': 186,
  'firewall policy6': 187,
  'wireless-controller vap/portal-message-overrides': 188,
  'firewall dnstranslation': 189,
  'firewall multicast-policy': 190,
  'log trafficfilter': 191,
  'log memory setting': 193,
  'log disk setting': 194,
  'log memory filter': 195,
  'log disk filter': 196,
  'alertemail setting': 197,
  'system mac-address-table': 199,
  'router access-list': 200,
  'router access-list/rule': 201,
  'router aspath-list': 202,
  'router aspath-list/rule': 203,
  'router prefix-list': 204,
  'router prefix-list/rule': 205,
  'router key-chain': 206,
  'router key-chain/key': 207,
  'router community-list': 208,
  'router community-list/rule': 209,
  'router route-map': 210,
  'router route-map/rule': 211,
  'webfilter override': 212,
  'webfilter override-user': 213,
  'antivirus profile/ftps': 214,
  'firewall service explicit-web': 215,
  'router rip': 216,
  'router rip/distance': 217,
  'router rip/distribute-list': 218,
  'router rip/neighbor': 219,
  'router rip/network': 220,
  'router rip/offset-list': 221,
  'firewall service group-explicit-web': 222,
  'router rip/redistribute': 223,
  'router rip/interface': 224,
  'router static': 225,
  'router policy': 226,
  'router static6': 227,
  'router ospf': 228,
  'router ospf/area': 229,
  'router ospf/area/range': 230,
  'router ospf/area/virtual-link': 231,
  'router ospf/area/filter-list': 232,
  'router ospf/ospf-interface': 233,
  'router ospf/network': 234,
  'router ospf/neighbor': 235,
  'webfilter profile/ftgd-wf/quota': 236,
  'router ospf/summary-address': 237,
  'router ospf/distribute-list': 238,
  'router ospf/redistribute': 239,
  'router bgp': 240,
  'firewall profile-protocol-options/ftps': 241,
  'router bgp/aggregate-address': 242,
  'router bgp/neighbor': 243,
  'router bgp/network': 244,
  'router bgp/redistribute': 245,
  'router bgp/admin-distance': 246,
  'router multicast': 247,
  'router multicast/pim-sm-global': 248,
  'router multicast/pim-sm-global/rp-address': 249,
  'router multicast/interface': 250,
  'router multicast/interface/join-group': 251,
  'router multicast/interface/igmp': 252,
  'system proxy-arp': 253,
  'firewall service predefined': 254,
  'user adgrp': 255,
  'system interface/wifi-mac-list': 256,
  'system modem': 257,
  'system fortiguard-log': 258,
  'log fortiguard setting': 259,
  'log fortiguard filter': 260,
  'system wireless settings': 261,
  'system aux': 265,
  'system interface/l2tp-client-settings': 266,
  'firewall multicast-address': 269,
  'firewall local-in-policy': 270,
  'firewall policy6/identity-based-policy': 271,
  'firewall local-in-policy6': 272,
  'web-proxy forward-server': 273,
  'ips rule-settings': 274,
  'wireless-controller setting': 275,
  'wireless-controller wtp-profile': 276,
  'wireless-controller wtp-profile/platform': 277,
  'wireless-controller wtp-profile/radio-1': 278,
  'user group/sslvpn-os-check-list': 280,
  'vpn ssl web bookmarks': 281,
  'vpn ssl web bookmarks-group': 282,
  'system accprofile/loggrp-permission': 283,
  'system switch-interface': 284,
  'system session-sync': 285,
  'system session-sync/filter': 286,
  'system fortimanager': 287,
  'ips sensor': 288,
  'ips sensor/filter': 289,
  'ips sensor/override': 290,
  'ips sensor/override/exempt-ip': 291,
  'log custom-field': 292,
  'user tacacs+': 293,
  'user setting': 294,
  'webfilter ftgd-ovrd-user': 295,
  'firewall ldb-monitor': 296,
  'ips decoder': 297,
  'ips decoder/parameter': 298,
  'ips rule': 299,
  'ips DoS': 300,
  'ips DoS/anomaly': 301,
  'log memory global-setting': 303,
  'system alarm': 304,
  'system alarm/groups': 305,
  'system alarm/groups/fw-policy-violations': 306,
  'router auth-path': 307,
  'system npu': 308,
  'system fips-cc': 309,
  'system management-tunnel': 310,
  'system ntp': 311,
  'system ntp/ntpserver': 312,
  'system dhcp server/reserved-address': 313,
  'system sit-tunnel': 314,
  'system amc': 315,
  'system central-management': 316,
  'system wccp': 317,
  'firewall interface-policy': 318,
  'firewall interface-policy6': 319,
  'wireless-controller wtp-profile/radio-2': 320,
  'user fsso': 321,
  'wireless-controller wtp-profile/deny-mac-list': 322,
  'log remote setting': 323,
  'router multicast-flow': 324,
  'router multicast-flow/flows': 325,
  'vpn fips-cc': 326,
  'ftp-proxy explicit': 327,
  'log syslogd filter/exclude-list': 328,
  'wireless-controller vap/mac-filter-list': 329,
  'system ddns': 330,
  'system replacemsg captive-portal-dflt': 331,
  'system replacemsg-group/captive-portal-dflt': 333,
  'dlp filepattern': 335,
  'dlp filepattern/entries': 336,
  'dlp fp-sensitivity': 337,
  'dlp fp-doc-source': 338,
  'dlp sensor/filter': 339,
  'system dns-server': 340,
  'webfilter profile/ftgd-wf/filters': 341,
  'webfilter ftgd-warning': 342,
  'icap server': 343,
  'icap profile': 344,
  'endpoint-control app-detect sig-settings': 345,
  'spamfilter profile/msn-hotmail': 346,
  'spamfilter profile/yahoo-mail': 347,
  'spamfilter profile/gmail': 348,
  'firewall profile-protocol-options/ssl-server': 349,
  'log gui': 350,
  'router ospf6/summary-address': 351,
  'system monitors': 352,
  'dlp settings': 353,
  'system sp': 354,
  'router gwdetect': 355,
  'system npu-cascade-cluster': 356,
  'ips sensor/entries': 357,
  'ips sensor/entries/exempt-ip': 358,
  'system bypass': 359,
  'system dedicated-mgmt': 360,
  'log syslogd filter/exclude-list/fields': 361,
  'log syslogd2 filter/exclude-list': 362,
  'log syslogd2 filter/exclude-list/fields': 363,
  'log syslogd3 filter/exclude-list': 364,
  'log syslogd3 filter/exclude-list/fields': 365,
  'log syslogd override-filter/exclude-list': 366,
  'log syslogd override-filter/exclude-list/fields': 367,
  'log webtrends filter/exclude-list': 368,
  'log webtrends filter/exclude-list/fields': 369,
  'log memory filter/exclude-list': 370,
  'log memory filter/exclude-list/fields': 371,
  'log disk filter/exclude-list': 372,
  'log disk filter/exclude-list/fields': 373,
  'log fortiguard filter/exclude-list': 374,
  'log fortiguard filter/exclude-list/fields': 375,
  'log fortianalyzer filter/exclude-list': 376,
  'log fortianalyzer filter/exclude-list/fields': 377,
  'log fortianalyzer2 filter/exclude-list': 378,
  'log fortianalyzer2 filter/exclude-list/fields': 379,
  'log fortianalyzer3 filter/exclude-list': 380,
  'log fortianalyzer3 filter/exclude-list/fields': 381,
  'log fortianalyzer override-filter/exclude-list': 382,
  'log fortianalyzer override-filter/exclude-list/fields': 383,
  'firewall service category': 384,
  'system stp': 385,
  'system physical-switch': 386,
  'system physical-switch/port': 387,
  'system virtual-switch': 388,
  'system virtual-switch/port': 389,
  'system sms-server': 390,
  'webfilter ips-urlfilter-setting': 391,
  'system fsso-polling': 392,
  'system ha/frup-settings': 393,
  'system replacemsg utm': 394,
  'system replacemsg-group/utm': 395,
  'system replacemsg-group/custom-message': 396,
  'spamfilter bwl': 397,
  'spamfilter bwl/entries': 398,
  'client-reputation profile': 399,
  'client-reputation profile/level': 400,
  'client-reputation profile/ips': 401,
  'client-reputation profile/web': 402,
  'client-reputation profile/geolocation': 403,
  'client-reputation profile/application': 404,
  'vpn certificate ocsp-server': 406,
  'vpn certificate setting': 407,
  'user password-policy': 408,
  'user fsso-polling': 409,
  'user fsso-polling/adgrp': 410,
  'user group/guest': 411,
  'webfilter search-engine': 412,
  'system network-visibility': 413,
  'antivirus profile/mapi': 416,
  'antivirus profile/smb': 417,
  'spamfilter profile/mapi': 418,
  'firewall ippool6': 420,
  'firewall vip6': 421,
  'webfilter ips-urlfilter-cache-setting': 422,
  'firewall vipgrp6': 423,
  'firewall profile-protocol-options/ssh': 425,
  'firewall profile-protocol-options/dns': 426,
  'firewall profile-protocol-options/mapi': 427,
  'firewall identity-based-route': 428,
  'firewall identity-based-route/rule': 429,
  'firewall auth-portal': 430,
  'web-proxy debug-url': 431,
  'firewall ttl-policy': 432,
  'firewall policy64': 433,
  'firewall isf-acl': 434,
  'firewall DoS-policy': 435,
  'firewall DoS-policy/anomaly': 436,
  'firewall sniffer': 437,
  'wireless-controller wids-profile': 438,
  'switch-controller vlan': 439,
  'switch-controller vlan/portal-message-overrides': 440,
  'switch-controller managed-switch': 441,
  'switch-controller managed-switch/ports': 442,
  'log fortiguard override-setting': 443,
  'log setting': 444,
  'log fortiguard override-filter': 445,
  'system email-server': 446,
  'router ripng/distance': 447,
  'router policy6': 448,
  'router bfd': 449,
  'router bfd/neighbor': 450,
  'system nat64': 451,
  'system vdom-radius-server': 452,
  'firewall ip-translation': 453,
  'wanopt profile': 457,
  'wanopt profile/http': 458,
  'wanopt profile/cifs': 459,
  'wanopt profile/mapi': 460,
  'wanopt profile/ftp': 461,
  'wanopt profile/tcp': 462,
  'report dataset/parameters': 463,
  'report chart/drill-down-charts': 464,
  'report layout/body-item/parameters': 465,
  'system ips-urlfilter-dns': 466,
  'system geoip-override': 467,
  'system geoip-override/ip-range': 468,
  'system interface/wifi-networks': 472,
  'system replacemsg device-detection-portal': 473,
  'application custom': 474,
  'user device-category': 475,
  'user device': 476,
  'user device-group': 477,
  'user device-access-list': 478,
  'user device-access-list/device-list': 479,
  'system replacemsg-group/device-detection-portal': 480,
  'firewall sniffer/anomaly': 481,
  'system sp3-port': 482,
  'system server-probe': 483,
  'system probe-response': 484,
  'endpoint-control profile/forticlient-winmac-settings': 485,
  'endpoint-control profile/forticlient-winmac-settings/forticlient-vpn-settings': 486,
  'endpoint-control profile/forticlient-android-settings': 487,
  'endpoint-control profile/forticlient-android-settings/forticlient-vpn-settings': 488,
  'endpoint-control profile/forticlient-ios-settings': 489,
  'ips dbinfo': 491,
  'firewall deep-inspection-options': 492,
  'firewall deep-inspection-options/ssl': 493,
  'firewall deep-inspection-options/https': 494,
  'firewall deep-inspection-options/ftps': 495,
  'firewall deep-inspection-options/imaps': 496,
  'firewall deep-inspection-options/pop3s': 497,
  'firewall deep-inspection-options/smtps': 498,
  'firewall deep-inspection-options/ssh': 499,
  'firewall deep-inspection-options/ssl-server': 500,
  'vpn ssl web portal/mac-addr-check-rule': 501,
  'firewall DoS-policy6': 502,
  'vpn ssl web realm': 503,
  'endpoint-control profile/forticlient-ios-settings/client-vpn-settings': 504,
  'endpoint-control forticlient-registration-sync': 505,
  'endpoint-control client': 506,
  'firewall vip/ssl-cipher-suites': 507,
  'firewall vip64': 508,
  'firewall DoS-policy6/anomaly': 509,
  'firewall vip46': 510,
  'router ospf6/ospf6-interface/neighbor': 511,
  'firewall vipgrp64': 512,
  'firewall vipgrp46': 513,
  'firewall policy46': 514,
  'router multicast6': 515,
  'router multicast6/interface': 516,
  'router multicast6/pim-sm-global': 517,
  'router multicast6/pim-sm-global/rp-address': 518,
  'system ipip-tunnel': 519,
  'endpoint-control registered-forticlient': 520,
  'web-proxy url-match': 521,
  'device-template action': 530,
  'device-template action/attrs': 531,
  'device-template action/attrs/fixed-attrs': 532,
  'device template widget': 533,
  'device template widget/action-list': 534,
  'device template widget/action-list/var-list': 535,
  'device template widget/action-list/dynamic_mapping': 536,
  'device-template action/args': 537,
  'template setting': 550,
  'cloud service template': 551,
  'cloud service template/cst-vdom': 552,
  'cloud service template/cst-vdom/cst-vdom-gintf': 553,
  'cloud service template/cst-vdom/cst-vdom-vwp': 554,
  'cloud service template/cst-srv-chain': 555,
  'cloud service template/cst-srv-chain/cst-srv-chain-index': 556,
  'sdwan overlay': 557,
  'sdwan overlay/nodes': 558,
  'sdwan overlay/nodes/underlay': 559,
  'sdwan overlay/nodes/neighbor': 560,
  'sdwan overlay/nodes/neighbor-group': 561,
  'sdwan overlay/nodes/vrf': 562,
  'dynamic interface': 800,
  'dynamic interface/dynamic_mapping': 801,
  'dynamic interface/platform_mapping': 802,
  'dynamic address': 810,
  'dynamic address/dynamic_addr_mapping': 811,
  'fmg sase-manager settings': 900,
  'fmg sase-manager status': 901,
  'device ha nonhaconf': 1001,
  'fsp packet-capture': 1002,
  'fsp packet-capture/target-mac': 1003,
  'vpnmgr vpntable': 1004,
  'vpnmgr node': 1005,
  'vpnmgr node/protected_subnet': 1006,
  'firewall policy/vpn_src_node': 1007,
  'firewall policy/vpn_dst_node': 1008,
  'system accprofile/utmgrp-permission': 1009,
  'system interface/t1e1-hdlc': 1010,
  'fsp packet-capture/target-ip': 1011,
  'fmg device blueprint': 1012,
  'fmg fabric authorization template': 1013,
  'fmg fabric authorization template/platforms': 1014,
  'fsp packet-capture/target-port': 1015,
  'fsp packet-capture/firewall-sniffer': 1016,
  'fmg device blueprint/vdoms': 1017,
  'system resource-limits': 1018,
  'system vdom-property': 1019,
  'system replacemsg ec': 1020,
  'system replacemsg nac-quar': 1021,
  'system snmp user': 1022,
  'firewall traffic-shaper': 1025,
  'ips settings': 1026,
  'application name': 1027,
  'application list': 1028,
  'application list/entries': 1029,
  'application list/entries/parameters': 1030,
  'dlp rule': 1031,
  'dlp compound': 1032,
  'dlp sensor': 1038,
  'dlp sensor/rule': 1039,
  'dlp sensor/compound-rule': 1040,
  'user ban': 1041,
  'wanopt settings': 1042,
  'wanopt peer': 1043,
  'wanopt auth-group': 1044,
  'wanopt ssl-server': 1045,
  'wanopt rule': 1046,
  'wanopt storage': 1047,
  'router setting': 1053,
  'vpn ssl web portal': 1054,
  'vpn ssl web portal/widget': 1055,
  'vpn ssl web portal/widget/bookmarks': 1056,
  'vpn ssl web portal/os-check-list': 1057,
  'vpn ssl web user': 1058,
  'vpn ssl web user/widget': 1059,
  'vpn ssl web user/widget/bookmarks': 1060,
  'system wireless ap-status': 1061,
  'system dialinsvr': 1062,
  'firewall ssl setting': 1063,
  'firewall policy/identity-based-policy': 1065,
  'web-proxy explicit': 1066,
  'web-proxy global': 1067,
  'user setting/auth-ports': 1068,
  'wanopt webcache': 1071,
  notification: 1073,
  'system gi-gk': 1074,
  'system replacemsg-image': 1075,
  'system replacemsg-group': 1076,
  'system replacemsg-group/mail': 1077,
  'system replacemsg-group/http': 1078,
  'system replacemsg-group/mms': 1079,
  'system replacemsg-group/mm1': 1080,
  'system replacemsg-group/mm3': 1081,
  'system replacemsg-group/mm4': 1082,
  'system replacemsg-group/mm7': 1083,
  'system replacemsg-group/ftp': 1084,
  'system replacemsg-group/nntp': 1085,
  'system replacemsg-group/fortiguard-wf': 1086,
  'system replacemsg-group/spam': 1087,
  'system replacemsg-group/im': 1088,
  'system dynamic-profile': 1089,
  'system carrier-endpoint-translation': 1090,
  'system mem-mgr': 1091,
  'system replacemsg mms': 1092,
  'system replacemsg mm1': 1093,
  'system replacemsg mm3': 1094,
  'system replacemsg mm4': 1095,
  'system replacemsg mm7': 1096,
  'firewall mms-profile': 1097,
  'firewall mms-profile/notification': 1099,
  'system meta': 1100,
  'system meta/sys_meta_fields': 1101,
  'policy package settings': 1103,
  'adom options': 1104,
  'firewall mms-profile/notif-msisdn': 1200,
  'firewall mms-profile/flood': 1201,
  'firewall mms-profile/dupe': 1202,
  'firewall gtp': 1203,
  'firewall gtp/apn': 1204,
  'firewall gtp/imsi': 1205,
  'firewall gtp/policy': 1206,
  'firewall gtp/ie-remove-policy': 1207,
  'firewall gtp/ip-policy': 1208,
  'firewall gtp/noip-policy': 1209,
  'firewall gtp/message-filter': 1210,
  'firewall gtp/ie-validation': 1211,
  'firewall gtp/message-rate-limit': 1212,
  'firewall carrier-endpoint-bwl': 1213,
  'firewall carrier-endpoint-bwl/entries': 1214,
  'firewall carrier-endpoint-ip-filter': 1215,
  'antivirus notification': 1216,
  'antivirus notification/entries': 1217,
  'endpoint-control settings': 1222,
  'vpn ipsec phase1-interface/ipv4-exclude-range': 1304,
  'vpn ipsec phase1-interface/ipv6-exclude-range': 1305,
  'router bgp/aggregate-address6': 1307,
  'router bgp/network6': 1308,
  'router bgp/redistribute6': 1309,
  'vpn ssl web portal/widget/bookmarks/form-data': 1310,
  'vpn ssl web user/widget/bookmarks/form-data': 1314,
  'system replacemsg-group/alertmail': 1315,
  'system replacemsg-group/admin': 1316,
  'system replacemsg-group/auth': 1317,
  'system replacemsg-group/ec': 1318,
  'system replacemsg-group/nac-quar': 1319,
  'system replacemsg-group/traffic-quota': 1320,
  'system replacemsg-group/sslvpn': 1321,
  'system dns-database': 1322,
  'system dns-database/dns-entry': 1323,
  'system password-policy': 1324,
  'system vdom-dns': 1325,
  'system replacemsg traffic-quota': 1326,
  'webfilter content': 1327,
  'webfilter content/entries': 1328,
  'webfilter content-header': 1329,
  'webfilter content-header/entries': 1330,
  'endpoint-control app-detect predefined-signature': 1331,
  'endpoint-control app-detect predefined-category': 1332,
  'endpoint-control app-detect predefined-vendor': 1333,
  'endpoint-control app-detect predefined-group': 1334,
  'endpoint-control app-detect rule-list': 1335,
  'endpoint-control app-detect rule-list/entries': 1336,
  'endpoint-control profile': 1337,
  'firewall schedule group': 1338,
  'log syslogd override-setting': 1340,
  'log syslogd override-filter': 1341,
  'log fortianalyzer override-setting': 1342,
  'log fortianalyzer override-filter': 1343,
  'router access-list6': 1344,
  'router access-list6/rule': 1345,
  'router prefix-list6': 1346,
  'router prefix-list6/rule': 1347,
  'router ripng': 1348,
  'router ripng/distribute-list': 1349,
  'router ripng/neighbor': 1350,
  'router ripng/aggregate-address': 1351,
  'router ripng/offset-list': 1352,
  'router ripng/redistribute': 1354,
  'router ripng/interface': 1355,
  'router ospf6': 1356,
  'router ospf6/area': 1357,
  'router ospf6/area/range': 1358,
  'router ospf6/area/virtual-link': 1359,
  'router ospf6/ospf6-interface': 1360,
  'router ospf6/redistribute': 1362,
  'firewall shaper traffic-shaper': 1364,
  'firewall shaper per-ip-shaper': 1365,
  'vpn ssl web virtual-desktop-app-list': 1367,
  'vpn ssl web virtual-desktop-app-list/apps': 1368,
  'vpn ssl web host-check-software': 1370,
  'vpn ssl web host-check-software/check-item-list': 1371,
  'report dataset': 1373,
  'report dataset/field': 1374,
  'report chart': 1375,
  'report chart/x-series': 1376,
  'report chart/y-series': 1377,
  'report chart/category-series': 1378,
  'report chart/value-series': 1379,
  'report chart/column': 1380,
  'report chart/column/mapping': 1381,
  'report summary': 1382,
  'system chassis-loadbalance': 1383,
  'webfilter cookie-ovrd': 1385,
  'log disk setting/sql-logging': 1386,
  'firewall sniff-interface-policy': 1387,
  'firewall sniff-interface-policy6': 1391,
  'antivirus settings': 1392,
  'wireless-controller global': 1393,
  'wireless-controller global/ac-list': 1394,
  'wireless-controller timers': 1395,
  'wireless-controller vap': 1396,
  'wireless-controller vap-group': 1397,
  'wireless-controller wtp': 1399,
  'wireless-controller wtp/deny-mac-list': 1401,
  'wireless-controller ap-status': 1402,
  'system admin/dashboard-tabs': 1403,
  'system interface/ipv6/ip6-extra-addr': 1404,
  'system interface/vrrp': 1406,
  'system replacemsg-group/webproxy': 1408,
  'system chassis-loadbalance/conf-sync': 1409,
  'system sflow': 1410,
  'system vdom-sflow': 1411,
  'system replacemsg webproxy': 1412,
  'webfilter profile': 1413,
  'webfilter profile/http': 1414,
  'webfilter profile/https': 1415,
  'webfilter profile/web': 1416,
  'webfilter profile/ftgd-wf': 1417,
  'webfilter profile/ftgd-quota': 1419,
  'antivirus profile': 1420,
  'antivirus profile/http': 1421,
  'antivirus profile/ftp': 1422,
  'antivirus profile/imap': 1423,
  'antivirus profile/pop3': 1424,
  'antivirus profile/smtp': 1425,
  'antivirus profile/nntp': 1426,
  'antivirus profile/im': 1427,
  'antivirus profile/nac-quar': 1428,
  'antivirus profile/https': 1429,
  'antivirus profile/imaps': 1430,
  'antivirus profile/pop3s': 1431,
  'antivirus profile/smtps': 1432,
  'spamfilter profile': 1433,
  'spamfilter profile/imap': 1434,
  'spamfilter profile/pop3': 1435,
  'spamfilter profile/smtp': 1436,
  'spamfilter profile/imaps': 1437,
  'spamfilter profile/pop3s': 1438,
  'spamfilter profile/smtps': 1439,
  'firewall profile-protocol-options': 1440,
  'firewall profile-protocol-options/http': 1441,
  'firewall profile-protocol-options/https': 1442,
  'firewall profile-protocol-options/ftp': 1443,
  'firewall profile-protocol-options/imap': 1444,
  'firewall profile-protocol-options/pop3': 1445,
  'firewall profile-protocol-options/smtp': 1446,
  'firewall profile-protocol-options/nntp': 1447,
  'firewall profile-protocol-options/im': 1448,
  'firewall profile-protocol-options/mail-signature': 1449,
  'firewall profile-protocol-options/imaps': 1450,
  'firewall profile-protocol-options/pop3s': 1451,
  'firewall profile-protocol-options/smtps': 1452,
  'firewall profile-group': 1453,
  'log eventfilter': 1454,
  'router isis': 1455,
  'router isis/isis-net': 1456,
  'router isis/isis-interface': 1457,
  'router isis/summary-address': 1458,
  'router isis/redistribute': 1459,
  'system amc-slot': 1460,
  'system storage': 1461,
  'report style': 1462,
  'report layout': 1463,
  'report layout/page': 1464,
  'report layout/page/header': 1465,
  'report layout/page/header/header-item': 1466,
  'report layout/page/footer': 1467,
  'report layout/page/footer/footer-item': 1468,
  'report layout/body-item': 1469,
  'webfilter profile/url-extraction': 1471,
  'antivirus mms-checksum': 1472,
  'antivirus mms-checksum/entries': 1473,
  'global header policy': 1474,
  'global header policy/identity-based-policy': 1475,
  'global footer policy': 1476,
  'global footer policy/identity-based-policy': 1477,
  'system dhcp server/ip-range': 1478,
  'wanopt webcache/cache-exemption-list': 1479,
  'report layout/body-item/list': 1481,
  'voip profile': 1482,
  'voip profile/sip': 1483,
  'voip profile/sccp': 1484,
  'netscan assets': 1485,
  'netscan settings': 1486,
  'firewall central-nat': 1487,
  'report theme': 1490,
  'device dynamic interface': 1491,
  'device dynamic address': 1492,
  'device dynamic address6': 1493,
  'dynamic vip': 1494,
  'dynamic ippool': 1495,
  'device dynamic ippool': 1496,
  'device dynamic vip': 1497,
  'device dynamic vip/realservers': 1498,
  'vpnmgr node/summary_addr': 1499,
  'global header policy6': 1500,
  'global header policy6/identity-based-policy6': 1501,
  'global footer policy6': 1502,
  'global footer policy6/identity-based-policy6': 1503,
  'dynamic certificate local': 1504,
  'device dynamic certificate local': 1505,
  'vpnmgr node/ipv4-exclude-range': 1506,
  'vpnmgr node/ip-range': 1508,
  'dynamic vpntunnel': 1509,
  'device dynamic vpntunnel': 1510,
  'device profile settings': 1511,
  'certificate template': 1512,
  'device dynamic ippool6': 1514,
  'device dynamic vip6': 1516,
  'device dynamic vip64': 1518,
  'device dynamic vip46': 1520,
  'device profile fortianalyzer': 1521,
  'device profile fortiguard': 1522,
  'user radius/accounting-server': 1523,
  'system interface/tdm': 1524,
  'system admin/login-time': 1525,
  'web-proxy forward-server-group': 1526,
  'web-proxy forward-server-group/server-list': 1527,
  'system session-sync/filter/custom-service': 1528,
  'system fortisandbox': 1529,
  'firewall ipv6-eh-filter': 1530,
  'system ipv6-neighbor-cache': 1531,
  'wireless-controller wtp-profile/lan': 1532,
  'wireless-controller wtp/lan': 1533,
  'system np6': 1535,
  'system np6/fp-anomaly-v4': 1536,
  'system np6/fp-anomaly-v6': 1537,
  'device dynamic radius': 1538,
  'device profile fortisandbox': 1539,
  'web-proxy profile/headers': 1540,
  'system netflow': 1541,
  'system vdom-netflow': 1542,
  'extender-controller extender': 1543,
  'vpn ssl web user-bookmark': 1550,
  'vpn ssl web user-bookmark/bookmarks/form-data': 1552,
  'user security-exempt-list': 1553,
  'user security-exempt-list/rule': 1554,
  'log gui-display': 1556,
  'system dscp-based-priority': 1557,
  'system virtual-wan-link': 1558,
  'system virtual-wan-link/members': 1559,
  'web-proxy profile': 1560,
  'user pop3': 1561,
  'vpn ssl web user-bookmark/bookmarks': 1562,
  'firewall ssl-ssh-profile': 1563,
  'firewall ssl-ssh-profile/ssl': 1564,
  'firewall ssl-ssh-profile/https': 1565,
  'firewall ssl-ssh-profile/ftps': 1566,
  'firewall ssl-ssh-profile/imaps': 1567,
  'firewall ssl-ssh-profile/pop3s': 1568,
  'firewall ssl-ssh-profile/smtps': 1569,
  'firewall ssl-ssh-profile/ssh': 1570,
  'firewall ssl-ssh-profile/ssl-exempt': 1571,
  'firewall ssl-ssh-profile/ssl-server': 1572,
  'firewall explicit-proxy-policy': 1573,
  'firewall explicit-proxy-policy/identity-based-policy': 1574,
  'vpn ssl web portal/bookmark-group': 1575,
  'vpn ssl web portal/bookmark-group/bookmarks': 1576,
  'vpn ssl web portal/bookmark-group/bookmarks/form-data': 1577,
  'vpn ssl settings/authentication-rule': 1578,
  'router bgp/neighbor/conditional-advertise': 1579,
  'system virtual-wan-link/service': 1580,
  'system link-monitor': 1581,
  'extender-controller extender/_gsm_profile': 1582,
  'extender-controller extender/_cdma_profile': 1583,
  'system custom-language': 1584,
  'firewall gtp/per-apn-shaper': 1585,
  'firewall gtp/message-rate-limit-v0': 1586,
  'firewall gtp/message-rate-limit-v1': 1587,
  'firewall gtp/message-rate-limit-v2': 1588,
  'wireless-controller wtp-profile/split-tunneling-acl': 1589,
  'wireless-controller wtp/split-tunneling-acl': 1590,
  'router bgp/neighbor-group': 1591,
  'router bgp/neighbor-range': 1592,
  'report setting': 1593,
  'firewall address/dynamic_mapping': 1594,
  'firewall addrgrp/dynamic_mapping': 1595,
  'dynamic certificate local/dynamic_mapping': 1596,
  'dynamic vpntunnel/dynamic_mapping': 1597,
  'firewall vip/dynamic_mapping/realservers': 1598,
  'firewall vip/dynamic_mapping/ssl-cipher-suites': 1599,
  'firewall ippool/dynamic_mapping': 1600,
  'firewall address6/dynamic_mapping': 1601,
  'user radius/dynamic_mapping': 1602,
  'firewall ippool6/dynamic_mapping': 1603,
  'firewall vip6/dynamic_mapping': 1604,
  'firewall vip64/dynamic_mapping': 1605,
  'firewall addrgrp6/dynamic_mapping': 1606,
  'firewall vip46/dynamic_mapping': 1607,
  'firewall vip/dynamic_mapping': 1608,
  'system central-management/server-list': 1609,
  'log threat-weight': 1610,
  'log threat-weight/level': 1611,
  'log threat-weight/ips': 1612,
  'log threat-weight/web': 1613,
  'log threat-weight/geolocation': 1614,
  'log threat-weight/application': 1615,
  'system lte-modem': 1616,
  'system mobile-tunnel': 1617,
  'system mobile-tunnel/network': 1618,
  'system dnp3-proxy': 1619,
  'system core-2': 1620,
  'user ldap/dynamic_mapping': 1621,
  'user tacacs+/dynamic_mapping': 1622,
  'log fortiguard override-filter/exclude-list/fields': 1623,
  'log fortiguard override-filter/exclude-list': 1624,
  'wireless-controller wtp-profile/lbs': 1625,
  'endpoint-control profile/forticlient-winmac-settings/extra-buffer-entries': 1628,
  'system geoip-country': 1629,
  'webfilter categories': 1630,
  'dynamic multicast interface': 1631,
  'dynamic multicast interface/dynamic_mapping': 1632,
  'system interface/ipv6/ip6-delegated-prefix-list': 1633,
  'system ha-monitor': 1634,
  'system virtual-wire-pair': 1635,
  'firewall multicast-address6': 1636,
  'vpn ipsec phase1/ipv4-exclude-range': 1637,
  'vpn ipsec phase1/ipv6-exclude-range': 1638,
  'user radius/dynamic_mapping/accounting-server': 1639,
  'firewall shaping-policy': 1640,
  'firewall multicast-policy6': 1641,
  'firewall central-snat-map': 1642,
  'router ripng/network': 1643,
  'system virtual-wan-link/health-check': 1644,
  'system cluster-sync': 1645,
  'system cluster-sync/session-sync-filter': 1646,
  'system cluster-sync/session-sync-filter/custom-service': 1647,
  'system auto-script': 1648,
  'firewall ssl-server': 1649,
  'firewall vipgrp/dynamic_mapping': 1650,
  'user device/dynamic_mapping': 1651,
  'user device-group/dynamic_mapping': 1652,
  'system vm': 1653,
  'system vm/disk': 1654,
  'system vm/interface': 1655,
  'waf main-class': 1656,
  'waf sub-class': 1657,
  'waf signature': 1658,
  'waf profile': 1659,
  'waf profile/signature': 1660,
  'waf profile/signature/main-class': 1661,
  'waf profile/signature/custom-signature': 1662,
  'waf profile/constraint': 1663,
  'waf profile/constraint/header-length': 1664,
  'waf profile/constraint/content-length': 1665,
  'waf profile/constraint/param-length': 1666,
  'waf profile/constraint/line-length': 1667,
  'waf profile/constraint/url-param-length': 1668,
  'waf profile/constraint/version': 1669,
  'waf profile/constraint/method': 1670,
  'waf profile/constraint/hostname': 1671,
  'waf profile/constraint/malformed': 1672,
  'waf profile/constraint/max-cookie': 1673,
  'waf profile/constraint/max-header-line': 1674,
  'waf profile/constraint/max-url-param': 1675,
  'waf profile/constraint/max-range-segment': 1676,
  'waf profile/constraint/exception': 1677,
  'waf profile/method': 1678,
  'waf profile/method/method-policy': 1679,
  'waf profile/address-list': 1680,
  'waf profile/url-access': 1681,
  'waf profile/url-access/access-pattern': 1682,
  'log syslogd4 setting': 1683,
  'log syslogd4 filter': 1684,
  'system virtual wan-link health-profile': 1688,
  'certificate ca': 1689,
  'certificate local': 1690,
  'dnsfilter urlfilter': 1691,
  'dnsfilter urlfilter/entries': 1692,
  'dnsfilter profile': 1693,
  'dnsfilter profile/urlfilter': 1694,
  'dnsfilter profile/ftgd-dns': 1695,
  'dnsfilter profile/ftgd-dns/filters': 1696,
  'system password-policy-guest-admin': 1697,
  'certificate crl': 1698,
  'vpn ssl web user-group-bookmark': 1699,
  'vpn ssl web user-group-bookmark/bookmarks': 1700,
  'vpn ssl web user-group-bookmark/bookmarks/form-data': 1701,
  'endpoint-control profile/forticlient-winmac-settings/av-scan-exclusions': 1702,
  'firewall explicit-proxy-address': 1703,
  'firewall explicit-proxy-address/header-group': 1704,
  'firewall explicit-proxy-addrgrp': 1705,
  'wireless-controller wtp/radio-1': 1706,
  'wireless-controller wtp/radio-2': 1707,
  'user fsso/dynamic_mapping': 1708,
  'wireless-controller vap/vlan-pool': 1709,
  'web-proxy wisp': 1712,
  'firewall acl': 1713,
  'firewall acl6': 1714,
  'wireless-controller wtp-group': 1715,
  'log null-device setting': 1717,
  'log null-device filter': 1718,
  'application internet-service-custom': 1719,
  'application internet-service-custom/entry': 1720,
  'application internet-service-custom/entry/port-range': 1721,
  'application internet-service-custom/disable-entry': 1722,
  'application internet-service-custom/disable-entry/ip-range': 1723,
  'system nst': 1724,
  'application internet-service': 1725,
  'application internet-service/entry': 1726,
  'application casi profile': 1727,
  'application casi profile/entries': 1728,
  'wireless-controller wtp-group/wtp-list': 1729,
  'log syslogd4 filter/exclude-list': 1730,
  'log syslogd4 filter/exclude-list/fields': 1731,
  'log null-device filter/exclude-list': 1732,
  'log null-device filter/exclude-list/fields': 1733,
  'user krb-keytab': 1734,
  'system interface/managed-device': 1735,
  'system dhcp server/options': 1736,
  'endpoint-control profile/forticlient-winmac-settings/forticlient-operating-system': 1737,
  'endpoint-control profile/forticlient-winmac-settings/forticlient-running-app': 1738,
  'endpoint-control profile/forticlient-winmac-settings/forticlient-registry-entry': 1739,
  'endpoint-control profile/forticlient-winmac-settings/forticlient-own-file': 1740,
  'firewall vip/ssl-server-cipher-suites': 1741,
  'gtp apn': 1742,
  'gtp apngrp': 1743,
  'switch-controller custom-command': 1744,
  'switch-controller managed-switch/switch-stp-settings': 1745,
  'switch-controller managed-switch/custom-command': 1746,
  'switch-controller stp-settings': 1747,
  'switch-controller storm-control': 1748,
  'switch-controller global': 1749,
  'switch-controller mac-sync-settings': 1750,
  'switch-controller switch-log': 1751,
  'system csf': 1752,
  'nsx setting': 1753,
  'nsx profile': 1754,
  'firewall address/list': 1755,
  'firewall address/profile-list': 1756,
  'firewall address6/list': 1757,
  'firewall address6/profile-list': 1758,
  'application categories': 1759,
  'switch-controller switch-profile': 1760,
  'application internet-service-custom-group': 1762,
  'application internet-service-group': 1763,
  'application group': 1764,
  'firewall shaping-profile': 1765,
  'firewall shaping-profile/classes': 1766,
  'wanprof settings': 1768,
  'dynamic virtual-wan-link members': 1769,
  'dynamic virtual-wan-link members/dynamic_mapping': 1770,
  'dynamic virtual-wan-link server': 1771,
  'dynamic virtual-wan-link server/dynamic_mapping': 1772,
  'dynamic virtual-wan-link neighbor': 1773,
  'dynamic virtual-wan-link neighbor/dynamic_mapping': 1774,
  'global header shaping-policy': 1775,
  'global footer shaping-policy': 1776,
  'log syslogd setting/custom-field-name': 1777,
  'log syslogd2 setting/custom-field-name': 1778,
  'log syslogd3 setting/custom-field-name': 1779,
  'log syslogd4 setting/custom-field-name': 1780,
  'log syslogd override-setting/custom-field-name': 1781,
  'log azure-security-center setting': 1782,
  'log azure-security-center setting/custom-field-name': 1783,
  'log azure-security-center2 setting': 1784,
  'log azure-security-center2 setting/custom-field-name': 1785,
  'log azure-security-center filter': 1786,
  'log azure-security-center2 filter': 1787,
  'dynamic input interface': 1792,
  'dynamic input interface/dynamic_mapping': 1793,
  'fsp managed-switch': 1800,
  'fsp vlan': 1801,
  'fsp vlan/interface': 1803,
  'fsp vlan/interface/secondaryip': 1805,
  'fsp vlan/interface/ipv6': 1806,
  'fsp vlan/interface/vrrp': 1808,
  'fsp vlan/dynamic_mapping/interface/vrrp': 1809,
  'fsp vlan/dynamic_mapping/interface/vrrp/proxy-arp': 1810,
  'fsp vlan/dhcp-server': 1811,
  'fsp vlan/dhcp-server/exclude-range': 1813,
  'fsp vlan/dhcp-server/ip-range': 1814,
  'fsp vlan/dhcp-server/reserved-address': 1815,
  'fsp vlan/dhcp-server/options': 1816,
  'fsp vlan/dynamic_mapping': 1818,
  'fsp vlan/dynamic_mapping/interface': 1819,
  'fsp vlan/dynamic_mapping/interface/secondaryip': 1820,
  'fsp vlan/dynamic_mapping/dhcp-server': 1821,
  'fsp vlan/dynamic_mapping/dhcp-server/exclude-range': 1823,
  'fsp vlan/dynamic_mapping/dhcp-server/ip-range': 1824,
  'fsp vlan/dynamic_mapping/dhcp-server/reserved-address': 1825,
  'fsp vlan/dynamic_mapping/dhcp-server/options': 1826,
  'central dnat': 1828,
  'firewall internet-service-custom': 1829,
  'firewall internet-service-custom/entry': 1830,
  'firewall internet-service-custom/entry/port-range': 1831,
  'firewall internet-service-custom/disable-entry': 1832,
  'firewall internet-service-custom/disable-entry/ip-range': 1833,
  'system pppoe-interface': 1834,
  'system vxlan': 1835,
  'firewall vip6/realservers': 1836,
  'firewall vip6/ssl-cipher-suites': 1837,
  'firewall vip6/ssl-server-cipher-suites': 1838,
  'firewall vip64/realservers': 1839,
  'firewall vip46/realservers': 1840,
  'firewall proxy-address': 1841,
  'firewall proxy-address/header-group': 1842,
  'firewall proxy-addrgrp': 1843,
  'firewall proxy-policy': 1844,
  'wireless-controller bonjour-profile': 1845,
  'wireless-controller bonjour-profile/policy-list': 1846,
  'switch-controller 802-1X-settings': 1847,
  'switch-controller security-policy 802-1X': 1848,
  'switch-controller security-policy captive-portal': 1849,
  'switch-controller lldp-settings': 1850,
  'switch-controller lldp-profile': 1851,
  'switch-controller lldp-profile/med-network-policy': 1852,
  'switch-controller lldp-profile/custom-tlvs': 1853,
  'wireless-controller qos-profile': 1854,
  'switch-controller managed-switch/stp-settings': 1855,
  'switch-controller managed-switch/switch-log': 1856,
  'switch-controller managed-switch/storm-control': 1857,
  'switch-controller managed-switch/igmp-snooping': 1858,
  'switch-controller managed-switch/802-1X-settings': 1859,
  'switch-controller switch-group': 1860,
  'switch-controller igmp-snooping': 1861,
  'system ftm-push': 1867,
  'system npu/port-npu-map': 1868,
  'system npu/port-cpu-map': 1869,
  'system np6/hpe': 1870,
  'authentication scheme': 1871,
  'authentication rule': 1872,
  'authentication setting': 1873,
  'system alias': 1874,
  'system datasource': 1875,
  'wireless-controller inter-controller': 1876,
  'wireless-controller inter-controller/inter-controller-peer': 1877,
  'wireless-controller vap/mpsk-key': 1878,
  'dnsfilter domain-filter': 1879,
  'dnsfilter domain-filter/entries': 1880,
  'dnsfilter profile/domain-filter': 1881,
  'aws setting': 1882,
  'system admin/gui-dashboard': 1883,
  'system admin/gui-dashboard/widget': 1884,
  'system ha/ha-mgmt-interfaces': 1885,
  'firewall internet-service': 1886,
  'firewall internet-service/entry': 1887,
  'system sdn-connector': 1888,
  'antivirus profile/outbreak-prevention': 1891,
  'system console-server': 1892,
  'system console-server/entries': 1893,
  'router bfd/multihop-template': 1894,
  'load-balance setting': 1895,
  'load-balance setting/workers': 1896,
  'system ha_old': 1897,
  'system ha_old/secondary-vcluster': 1898,
  'load-balance flow-rule': 1899,
  'fapmap setting': 1900,
  'fapmap setting/wtp info': 1901,
  'cli template': 1902,
  'cli template-group': 1903,
  'global header consolidated policy': 1904,
  'global footer consolidated policy': 1905,
  'fwmprof setting': 1906,
  'fwmprof setting/enforced version': 1907,
  'global ips sensor': 1908,
  'global ips sensor/filter': 1909,
  'global ips sensor/override': 1910,
  'global ips sensor/override/exempt-ip': 1911,
  'global ips sensor/entries': 1912,
  'global ips sensor/entries/exempt-ip': 1913,
  'template group setting': 1914,
  'system api-user': 1920,
  'system api-user/trusthost': 1921,
  'gtp message-filter-v0v1': 1922,
  'gtp message-filter-v2': 1923,
  'gtp ie-white-list': 1924,
  'gtp ie-white-list/entries': 1925,
  'gtp apn-shaper': 1926,
  'wireless-controller ble-profile': 1927,
  'switch-controller qos dot1p-map': 1928,
  'switch-controller qos ip-dscp-map': 1929,
  'switch-controller qos ip-dscp-map/map': 1930,
  'switch-controller qos queue-policy': 1931,
  'switch-controller qos queue-policy/cos-queue': 1932,
  'switch-controller qos qos-policy': 1933,
  'switch-controller quarantine': 1934,
  'switch-controller quarantine/targets': 1935,
  'system np6/fp-anomaly': 1936,
  'wanopt forticache-service': 1937,
  'system admin/gui-dashboard/widget/filters': 1938,
  'fsp vlan/dynamic_mapping/interface/ipv6': 1940,
  'fsp vlan/interface/ipv6/ip6-prefix-list': 1941,
  'fsp vlan/interface/ipv6/ip6-extra-addr': 1942,
  'fsp vlan/interface/ipv6/ip6-delegated-prefix-list': 1943,
  'fsp vlan/interface/ipv6/vrrp6': 1944,
  'fsp vlan/dynamic_mapping/interface/ipv6/ip6-prefix-list': 1945,
  'fsp vlan/dynamic_mapping/interface/ipv6/ip6-extra-addr': 1946,
  'fsp vlan/dynamic_mapping/interface/ipv6/ip6-delegated-prefix-list': 1947,
  'fsp vlan/dynamic_mapping/interface/ipv6/vrrp6': 1948,
  'fsp vlan/interface/vrrp/proxy-arp': 1949,
  'wireless-controller hotspot20 anqp-venue-name': 1950,
  'wireless-controller hotspot20 anqp-venue-name/value-list': 1951,
  'wireless-controller hotspot20 anqp-network-auth-type': 1952,
  'wireless-controller hotspot20 anqp-roaming-consortium': 1953,
  'wireless-controller hotspot20 anqp-roaming-consortium/oi-list': 1954,
  'wireless-controller hotspot20 anqp-nai-realm': 1955,
  'wireless-controller hotspot20 anqp-nai-realm/nai-list': 1956,
  'wireless-controller hotspot20 anqp-nai-realm/nai-list/eap-method': 1957,
  'wireless-controller hotspot20 anqp-nai-realm/nai-list/eap-method/auth-param': 1958,
  'wireless-controller hotspot20 anqp-3gpp-cellular': 1959,
  'wireless-controller hotspot20 anqp-3gpp-cellular/mcc-mnc-list': 1960,
  'wireless-controller hotspot20 anqp-ip-address-type': 1961,
  'wireless-controller hotspot20 h2qp-operator-name': 1962,
  'wireless-controller hotspot20 h2qp-operator-name/value-list': 1963,
  'wireless-controller hotspot20 h2qp-wan-metric': 1964,
  'wireless-controller hotspot20 h2qp-conn-capability': 1965,
  'wireless-controller hotspot20 icon': 1966,
  'wireless-controller hotspot20 icon/icon-list': 1967,
  'wireless-controller hotspot20 h2qp-osu-provider': 1968,
  'wireless-controller hotspot20 h2qp-osu-provider/friendly-name': 1969,
  'wireless-controller hotspot20 h2qp-osu-provider/service-description': 1970,
  'wireless-controller hotspot20 qos-map': 1971,
  'wireless-controller hotspot20 qos-map/dscp-except': 1972,
  'wireless-controller hotspot20 qos-map/dscp-range': 1973,
  'wireless-controller hotspot20 hs-profile': 1974,
  'system affinity-interrupt': 1975,
  'system affinity-packet-redistribution': 1976,
  'load-balance hidden-interface': 1978,
  'user pxgrid': 1979,
  'firewall internet-service-addition': 1980,
  'firewall internet-service-addition/entry': 1981,
  'firewall internet-service-addition/entry/port-range': 1982,
  'firewall internet-service-cat-definition': 1983,
  'firewall internet-service-cat-definition/entry': 1984,
  'cifs domain-controller': 1985,
  'cifs profile': 1986,
  'cifs profile/file-filter': 1987,
  'cifs profile/file-filter/entries': 1988,
  'cifs profile/server-keytab': 1989,
  'vpn ocvpn/overlays': 1990,
  'vpn ocvpn/overlays/subnets': 1991,
  'antivirus profile/cifs': 1992,
  'fsp vdom-settings': 1994,
  'fsp vdom-settings/interface-settings': 1995,
  'user domain-controller': 2000,
  'system object-tagging': 2001,
  'user device/tagging': 2002,
  'user device-group/tagging': 2003,
  'system interface/tagging': 2004,
  'system interface/vrrp/proxy-arp': 2005,
  'system interface/ipv6/vrrp6': 2006,
  'system zone/tagging': 2007,
  'firewall address/tagging': 2008,
  'firewall address6-template': 2009,
  'firewall address6-template/subnet-segment': 2010,
  'firewall address6-template/subnet-segment/values': 2011,
  'firewall address6/tagging': 2012,
  'firewall address6/subnet-segment': 2013,
  'firewall addrgrp/tagging': 2014,
  'firewall addrgrp6/tagging': 2015,
  'firewall wildcard-fqdn custom': 2016,
  'firewall wildcard-fqdn group': 2017,
  'system dhcp6 server/prefix-range': 2018,
  'firewall multicast-address/tagging': 2019,
  'firewall multicast-address6/tagging': 2020,
  'webfilter ips-urlfilter-setting6': 2021,
  'system ips-urlfilter-dns6': 2022,
  'user quarantine': 2023,
  'user quarantine/targets': 2024,
  'vpn ssl web portal/split-dns': 2025,
  'firewall internet-service-group': 2026,
  'firewall internet-service-custom-group': 2027,
  'system external-resource': 2028,
  'antivirus profile/content-disarm': 2029,
  'wanopt content-delivery-network-rule': 2030,
  'wanopt content-delivery-network-rule/rules': 2031,
  'wanopt content-delivery-network-rule/rules/match-entries': 2032,
  'wanopt content-delivery-network-rule/rules/skip-entries': 2033,
  'wanopt content-delivery-network-rule/rules/content-id': 2034,
  'wanopt cache-service': 2035,
  'wanopt cache-service/dst-peer': 2036,
  'wanopt cache-service/src-peer': 2037,
  'system virtual-wan-link/health-check/sla': 2038,
  'system virtual-wan-link/service/sla': 2039,
  'firewall proxy-address/tagging': 2040,
  'firewall proxy-addrgrp/tagging': 2041,
  'switch-controller virtual-port-pool': 2042,
  'switch-controller switch-interface-tag': 2043,
  'switch-controller sflow': 2044,
  'switch-controller network-monitor-settings': 2045,
  'router isis/summary-address6': 2046,
  'router isis/redistribute6': 2047,
  'router bfd6': 2048,
  'router bfd6/neighbor': 2049,
  'system nat64/secondary-prefix': 2050,
  'system vdom-exception': 2051,
  'system csf/trusted-list': 2052,
  'system csf/fabric-device': 2053,
  'system automation-trigger': 2054,
  'system automation-action': 2055,
  'system automation-stitch': 2056,
  'system replacemsg-group/icap': 2057,
  'system replacemsg icap': 2058,
  'ssh-filter profile': 2059,
  'ssh-filter profile/shell-commands': 2060,
  'gtp tunnel-limit': 2061,
  'web-proxy explicit/pac-policy': 2062,
  'firewall shaping-profile/shaping-entries': 2063,
  'wireless-controller utm-profile': 2064,
  'switch-controller managed-switch/mirror': 2065,
  'switch-controller system': 2066,
  'firewall ssh local-key': 2067,
  'firewall ssh local-ca': 2068,
  'firewall ssh setting': 2069,
  'firewall ssh host-key': 2070,
  'webfilter profile/youtube-channel-filter': 2071,
  'system accprofile/netgrp-permission': 2072,
  'system accprofile/sysgrp-permission': 2073,
  'wanopt remote-storage': 2074,
  'vpn ocvpn': 2075,
  'vpn ocvpn/subnets': 2076,
  'endpoint-control forticlient-ems': 2077,
  'system sdn-connector/nic': 2078,
  'system sdn-connector/nic/ip': 2079,
  'system sdn-connector/route-table': 2080,
  'system sdn-connector/route-table/route': 2081,
  'system sdn-connector/external-ip': 2082,
  'system sdn-connector/route': 2083,
  'system automation-destination': 2084,
  'system external-resource/dynamic_mapping': 2085,
  'firewall mms-profile/outbreak-prevention': 2087,
  'firewall profile-protocol-options/cifs': 2093,
  'user quarantine/targets/macs': 2100,
  'system nd-proxy': 2101,
  'system speed-test-server': 2108,
  'system speed-test-server/host': 2109,
  'log fortianalyzer-cloud setting': 2110,
  'log fortianalyzer-cloud override-setting': 2111,
  'log fortianalyzer-cloud filter': 2112,
  'log fortianalyzer-cloud override-filter': 2113,
  'system saml': 2114,
  'system saml/service-providers': 2115,
  'system saml/service-providers/assertion-attributes': 2116,
  'system npu/fp-anomaly': 2117,
  'log threat-weight/malware': 2130,
  'wireless-controller vap/dynamic_mapping': 2140,
  'router bgp/neighbor-range6': 2160,
  'monitoring np6-ipsec-engine': 2161,
  'system auto-scale': 2190,
  'system queue-profile': 2201,
  'system interface/out-queues': 2202,
  'firewall internet-service-extension': 2203,
  'firewall internet-service-extension/entry': 2204,
  'firewall internet-service-extension/entry/port-range': 2205,
  'firewall internet-service-extension/disable-entry': 2206,
  'firewall internet-service-extension/disable-entry/ip-range': 2207,
  'firewall internet-service-definition': 2208,
  'firewall internet-service-definition/entry': 2209,
  'system ipsec-aggregate': 2210,
  'firewall consolidated policy': 2213,
  'switch-controller auto-config policy': 2214,
  'switch-controller auto-config default': 2215,
  'switch-controller auto-config custom': 2216,
  'switch-controller auto-config custom/switch-binding': 2217,
  'switch-controller managed-switch/static-mac': 2218,
  'system ptp': 2219,
  'system admin/gui-dashboard/widget/fortiview-filters': 2220,
  'system npu/sw-np-in-queues': 2221,
  'user domain-controller/extra-server': 2222,
  'switch-controller traffic-policy': 2223,
  'icap profile/icap-headers': 2224,
  'wireless-controller setting/offending-ssid': 2225,
  'system virtual-wan-link/neighbor': 2227,
  'switch-controller managed-switch/snmp-sysinfo': 2228,
  'switch-controller managed-switch/snmp-trap-threshold': 2229,
  'switch-controller managed-switch/snmp-community': 2230,
  'switch-controller managed-switch/snmp-community/hosts': 2231,
  'switch-controller managed-switch/snmp-user': 2232,
  'switch-controller managed-switch/remote-log': 2233,
  'switch-controller snmp-sysinfo': 2234,
  'switch-controller snmp-trap-threshold': 2235,
  'switch-controller snmp-community': 2236,
  'switch-controller snmp-community/hosts': 2237,
  'switch-controller snmp-user': 2238,
  'switch-controller remote-log': 2239,
  'router ospf6/area/virtual-link/ipsec-keys': 2240,
  'router ospf6/area/ipsec-keys': 2241,
  'router ospf6/ospf6-interface/ipsec-keys': 2242,
  'system np6xlite': 2250,
  'system lldp network-policy': 2251,
  'system lldp network-policy/voice': 2252,
  'system lldp network-policy/voice-signaling': 2253,
  'system lldp network-policy/guest': 2254,
  'system lldp network-policy/guest-voice-signaling': 2255,
  'system lldp network-policy/softphone': 2256,
  'system lldp network-policy/video-conferencing': 2257,
  'system lldp network-policy/streaming-video': 2258,
  'system lldp network-policy/video-signaling': 2259,
  'wireless-controller region': 2260,
  'switch-controller location': 2261,
  'switch-controller location/address-civic': 2262,
  'switch-controller location/coordinates': 2263,
  'switch-controller location/elin-number': 2264,
  'switch-controller lldp-profile/med-location-service': 2265,
  'switch-controller global/custom-command': 2266,
  'log syslogd2 override-setting': 2267,
  'log syslogd2 override-setting/custom-field-name': 2268,
  'log syslogd3 override-setting': 2269,
  'log syslogd3 override-setting/custom-field-name': 2270,
  'log syslogd4 override-setting': 2271,
  'log syslogd4 override-setting/custom-field-name': 2272,
  'log syslogd2 override-filter': 2273,
  'log syslogd3 override-filter': 2274,
  'log syslogd4 override-filter': 2275,
  'log fortianalyzer2 override-setting': 2276,
  'log fortianalyzer3 override-setting': 2277,
  'log fortianalyzer2 override-filter': 2278,
  'log fortianalyzer3 override-filter': 2279,
  'system np6xlite/hpe': 2280,
  'system np6xlite/fp-anomaly': 2281,
  'system npu/sw-eh-hash': 2282,
  'webfilter profile/file-filter': 2300,
  'dlp sensitivity': 2301,
  'emailfilter bword': 2302,
  'emailfilter bword/entries': 2303,
  'emailfilter bwl': 2304,
  'emailfilter bwl/entries': 2305,
  'emailfilter mheader': 2306,
  'emailfilter mheader/entries': 2307,
  'emailfilter dnsbl': 2308,
  'emailfilter dnsbl/entries': 2309,
  'emailfilter iptrust': 2310,
  'emailfilter iptrust/entries': 2311,
  'emailfilter profile': 2312,
  'emailfilter profile/file-filter': 2313,
  'emailfilter profile/imap': 2314,
  'emailfilter profile/pop3': 2315,
  'emailfilter profile/smtp': 2316,
  'emailfilter profile/mapi': 2317,
  'emailfilter profile/msn-hotmail': 2318,
  'emailfilter profile/yahoo-mail': 2319,
  'emailfilter profile/gmail': 2320,
  'antivirus filetype': 2321,
  'switch-controller security-policy local-access': 2322,
  'switch-controller flow-tracking': 2323,
  'switch-controller flow-tracking/aggregates': 2324,
  'emailfilter fortishield': 2325,
  'emailfilter options': 2326,
  'switch-controller managed-switch/stp-instance': 2350,
  'switch-controller stp-instance': 2351,
  'user exchange': 2380,
  'webfilter profile/file-filter/entries': 2381,
  'emailfilter profile/file-filter/entries': 2382,
  'wireless-controller address': 2383,
  'wireless-controller addrgrp': 2384,
  'router ospf/area/virtual-link/md5-keys': 2385,
  'router ospf/ospf-interface/md5-keys': 2386,
  'system automation-trigger/fields': 2387,
  'firewall internet-service-extension/disable-entry/port-range': 2410,
  'firewall internet-service-definition/entry/port-range': 2411,
  'wireless-controller log': 2412,
  'switch-controller traffic-sniffer': 2413,
  'switch-controller traffic-sniffer/target-mac': 2414,
  'switch-controller traffic-sniffer/target-ip': 2415,
  'switch-controller traffic-sniffer/target-port': 2416,
  'system isf-queue-profile': 2440,
  'system interface/egress-queues': 2441,
  'application list/default-network-services': 2442,
  'endpoint-control fctems': 2443,
  'switch-controller storm-control-policy': 2444,
  'wireless-controller snmp': 2445,
  'wireless-controller snmp/community': 2446,
  'wireless-controller snmp/community/hosts': 2447,
  'wireless-controller snmp/user': 2448,
  'system npu/isf-np-queues': 2449,
  'system iscsi': 2470,
  'dnsfilter profile/dns-translation': 2500,
  'system remote-admin': 2501,
  'system sso-admin': 2502,
  'system sso-admin/gui-dashboard': 2503,
  'system sso-admin/gui-dashboard/widget': 2504,
  'system sso-admin/gui-dashboard/widget/fortiview-filters': 2505,
  'firewall address6/dynamic_mapping/subnet-segment': 2506,
  'user clearpass': 2507,
  'user nsx': 2508,
  'user flexvm': 2509,
  'ums setting': 2510,
  'firewall gtp/policy-v2': 2530,
  'firewall security-policy': 2560,
  'user saml': 2590,
  'certificate remote': 2591,
  'ssh-filter profile/file-filter': 2592,
  'ssh-filter profile/file-filter/entries': 2593,
  'antivirus profile/ssh': 2594,
  'icap profile/respmod-forward-rules': 2595,
  'firewall traffic-class': 2596,
  'wireless-controller wag-profile': 2597,
  'icap profile/respmod-forward-rules/header-group': 2598,
  'wireless-controller wtp-profile/radio-3': 2620,
  'wireless-controller wtp/radio-3': 2621,
  'user vcenter': 2622,
  'user vcenter/rule': 2623,
  'user nsx/service': 2624,
  'system geneve': 2650,
  'system smc-ntp': 2651,
  'system smc-ntp/ntpserver': 2652,
  'system npu/priority-protocol': 2653,
  'dpdk global': 2680,
  'dpdk cpus': 2681,
  'wireless-controller wtp-profile/radio-4': 2710,
  'wireless-controller wtp/radio-4': 2711,
  'wireless-controller access-control-list': 2712,
  'wireless-controller access-control-list/layer3-ipv4-rules': 2713,
  'wireless-controller access-control-list/layer3-ipv6-rules': 2714,
  'firewall internet-service-append': 2715,
  'system interface/client-options': 2750,
  'webfilter profile/antiphish': 2751,
  'webfilter profile/antiphish/inspection-entries': 2752,
  'webfilter profile/antiphish/custom-patterns': 2753,
  'switch-controller managed-switch/ip-source-guard': 2756,
  'switch-controller managed-switch/ip-source-guard/binding-entry': 2757,
  'system geoip-override/ip6-range': 2758,
  'credential-store domain-controller': 2759,
  'firewall vendor-mac': 2760,
  'switch-controller vlan-policy': 2761,
  'switch-controller device-port-policy': 2762,
  'switch-controller lldp-identity': 2763,
  'switch-controller lldp-device': 2764,
  'router bgp/vrf-leak': 2765,
  'router bgp/vrf-leak/target': 2766,
  'wireless-controller apcfg-profile': 2767,
  'wireless-controller apcfg-profile/command-list': 2768,
  'firewall internet-service-name': 2790,
  'switch-controller port-policy': 2791,
  'switch-controller mac-policy': 2792,
  'switch-controller nac-settings': 2793,
  'user nac-policy': 2794,
  'switch-controller nac-device': 2795,
  'system standalone-cluster': 2796,
  'firewall decrypted-traffic-mirror': 2820,
  'switch-controller initial-config template': 2821,
  'switch-controller initial-config vlans': 2822,
  'application list/entries/parameters/members': 2850,
  'vpn ocvpn/forticlient-access': 2851,
  'vpn ocvpn/forticlient-access/auth-groups': 2852,
  'system npu/hpe': 2880,
  'system npu/ip-reassembly': 2881,
  'system npu-vlink': 2882,
  'system ha/vcluster': 2883,
  'file-filter profile': 2910,
  'file-filter profile/rules': 2911,
  'nsxt setting': 2912,
  'nsxt service-chain': 2913,
  'nsxt service-chain/service-index': 2914,
  'system vne-tunnel': 2920,
  'system sdwan': 2921,
  'system sdwan/zone': 2922,
  'system sdwan/members': 2923,
  'system sdwan/service': 2924,
  'system sdwan/service/sla': 2925,
  'system sdwan/health-check': 2926,
  'system sdwan/health-check/sla': 2927,
  'system sdwan/neighbor': 2928,
  'extender-controller dataplan': 2929,
  'extender-controller extender/controller-report': 2930,
  'extender-controller extender/modem1': 2931,
  'extender-controller extender/modem1/auto-switch': 2932,
  'extender-controller extender/modem2': 2933,
  'extender-controller extender/modem2/auto-switch': 2934,
  'emailfilter profile/other-webmails': 2935,
  'firewall profile-protocol-options/cifs/file-filter': 2936,
  'firewall profile-protocol-options/cifs/file-filter/entries': 2937,
  'firewall profile-protocol-options/cifs/server-keytab': 2938,
  'wireless-controller arrp-profile': 2939,
  'switch-controller ptp settings': 2940,
  'switch-controller ptp policy': 2941,
  'central dnat6': 2942,
  'system sdwan/duplication': 2943,
  'system ips': 2944,
  'wireless-controller mpsk-profile': 2945,
  'wireless-controller mpsk-profile/mpsk-group': 2946,
  'wireless-controller mpsk-profile/mpsk-group/mpsk-key': 2947,
  'firewall ippool_grp': 2948,
  'log npu-server': 2949,
  'log npu-server/server-info': 2950,
  'log npu-server/server-group': 2951,
  'system npu/dos-options': 2952,
  'system npu/port-path-option': 2953,
  'extender-controller sim_profile': 2954,
  'extender-controller sim_profile/auto-switch_profile': 2955,
  'extender-controller extender/modem1/_cdma_profile': 2956,
  'extender-controller extender/modem1/_gsm_profile': 2957,
  'extender-controller extender/modem1/_sim1': 2958,
  'extender-controller extender/modem1/_sim2': 2959,
  'extender-controller extender/modem2/_cdma_profile': 2960,
  'extender-controller extender/modem2/_gsm_profile': 2961,
  'extender-controller extender/modem2/_sim1': 2962,
  'extender-controller extender/modem2/_sim2': 2963,
  'extender-controller template': 2964,
  'ips global/tls-active-probe': 2965,
  'system npu/dsw-dts-profile': 2966,
  'system npu/dsw-queue-dts-profile': 2967,
  'system npu/np-queues': 2968,
  'system npu/np-queues/profile': 2969,
  'system npu/np-queues/ethernet-type': 2970,
  'system npu/np-queues/ip-protocol': 2971,
  'system npu/np-queues/ip-service': 2972,
  'system npu/np-queues/scheduler': 2973,
  'router bgp/vrf': 2974,
  'router bgp/vrf/leak-target': 2975,
  'router bgp/vrf6': 2976,
  'router bgp/vrf6/leak-target': 2977,
  'system replacemsg-group/automation': 2978,
  'videofilter youtube-key': 2979,
  'videofilter youtube-channel-filter': 2980,
  'videofilter youtube-channel-filter/entries': 2981,
  'videofilter profile': 2982,
  'videofilter profile/fortiguard-category': 2983,
  'videofilter profile/fortiguard-category/filters': 2984,
  'system replacemsg automation': 2985,
  'emailfilter block-allow-list': 2986,
  'emailfilter block-allow-list/entries': 2987,
  'vpn ssl client': 2988,
  'gtp ie-allow-list': 2989,
  'gtp ie-allow-list/entries': 2990,
  'log syslogd filter/free-style': 2991,
  'log syslogd2 filter/free-style': 2992,
  'log syslogd3 filter/free-style': 2993,
  'log syslogd4 filter/free-style': 2994,
  'log syslogd override-filter/free-style': 2995,
  'log syslogd2 override-filter/free-style': 2996,
  'log syslogd3 override-filter/free-style': 2997,
  'log syslogd4 override-filter/free-style': 2998,
  'log webtrends filter/free-style': 2999,
  'log memory filter/free-style': 3000,
  'log disk filter/free-style': 3001,
  'log fortiguard filter/free-style': 3002,
  'log fortiguard override-filter/free-style': 3003,
  'log null-device filter/free-style': 3004,
  'log fortianalyzer filter/free-style': 3005,
  'log fortianalyzer2 filter/free-style': 3006,
  'log fortianalyzer3 filter/free-style': 3007,
  'log fortianalyzer-cloud filter/free-style': 3008,
  'log fortianalyzer override-filter/free-style': 3009,
  'log fortianalyzer2 override-filter/free-style': 3010,
  'log fortianalyzer3 override-filter/free-style': 3011,
  'log fortianalyzer-cloud override-filter/free-style': 3012,
  'system federated-upgrade': 3013,
  'system federated-upgrade/node-list': 3014,
  'system ike': 3015,
  'system ike/dh-group-1': 3016,
  'system ike/dh-group-2': 3017,
  'system ike/dh-group-5': 3018,
  'system ike/dh-group-14': 3019,
  'system ike/dh-group-15': 3020,
  'system ike/dh-group-16': 3021,
  'system ike/dh-group-17': 3022,
  'system ike/dh-group-18': 3023,
  'system ike/dh-group-19': 3024,
  'system ike/dh-group-20': 3025,
  'system ike/dh-group-21': 3026,
  'system ike/dh-group-27': 3027,
  'system ike/dh-group-28': 3028,
  'system ike/dh-group-29': 3029,
  'system ike/dh-group-30': 3030,
  'system ike/dh-group-31': 3031,
  'system ike/dh-group-32': 3032,
  'system speed-test-schedule': 3033,
  'system sso-forticloud-admin': 3034,
  'system sso-forticloud-admin/gui-dashboard': 3035,
  'system sso-forticloud-admin/gui-dashboard/widget': 3036,
  'system sso-forticloud-admin/gui-dashboard/widget/fortiview-filters': 3037,
  'log azure-security-center filter/free-style': 3038,
  'log azure-security-center2 filter/free-style': 3039,
  'firewall profile-protocol-options/rtmp': 3040,
  'certificate setting': 3041,
  'web-proxy pac-policy': 3042,
  'web-proxy isolator-server': 3043,
  'image-analyzer profile': 3044,
  'icap remote-server': 3045,
  'icap local-server': 3046,
  'icap local-server/icap-service': 3047,
  'wanopt reverse-cache-prefetch': 3048,
  'wanopt reverse-cache-server': 3049,
  'webcache settings': 3050,
  'webcache user-agent': 3051,
  'webcache reverse-cache-prefetch-url': 3052,
  'webcache reverse-cache-server': 3053,
  'firewall ssl-ssh-profile/dot': 3054,
  'system ha/unicast-peers': 3055,
  'wireless-controller nac-profile': 3056,
  'wireless-controller ssid-policy': 3057,
  'monitoring npu-hpe': 3058,
  'switch-controller fortilink-settings': 3059,
  'switch-controller fortilink-settings/nac-ports': 3060,
  'system npu-setting prp': 3061,
  'switch-controller dynamic-port-policy': 3062,
  'switch-controller dynamic-port-policy/policy': 3063,
  'system acme': 3064,
  'system acme/accounts': 3065,
  'firewall access-proxy-virtual-host': 3066,
  'firewall access-proxy': 3067,
  'firewall access-proxy/api-gateway': 3068,
  'firewall access-proxy/api-gateway/realservers': 3069,
  'firewall access-proxy/api-gateway/ssl-cipher-suites': 3070,
  'firewall access-proxy/server-pubkey-auth-settings': 3071,
  'firewall access-proxy/server-pubkey-auth-settings/cert-extension': 3072,
  'firewall access-proxy/realservers': 3073,
  'system csf/fabric-connector': 3074,
  'user connector': 3075,
  'system npu/tcp-timeout-profile': 3076,
  'system npu/udp-timeout-profile': 3077,
  'firewall hyperscale-policy': 3078,
  'firewall hyperscale-policy6': 3079,
  'firewall hyperscale-policy64': 3080,
  'firewall hyperscale-policy46': 3081,
  'system dns64': 3082,
  'system span-port': 3083,
  'log slbc global-setting': 3084,
  'router bgp/neighbor/conditional-advertise6': 3085,
  'router bfd6/multihop-template': 3086,
  'system interface/dhcp-snooping-server-list': 3087,
  'sctp-filter profile': 3088,
  'sctp-filter profile/ppid-filters': 3089,
  'vpn certificate setting/crl-verification': 3090,
  'user certificate': 3091,
  'gtp rat-timeout-profile': 3092,
  'pfcp message-filter': 3093,
  'firewall pfcp': 3094,
  'wireless-controller wtp-profile/esl-ses-dongle': 3095,
  'router bgp/vrf-leak6': 3096,
  'router bgp/vrf-leak6/target': 3097,
  'system fortiai': 3098,
  'system link-monitor/server-list': 3099,
  'system ptp/server-interface': 3100,
  'firewall access-proxy-ssh-client-cert': 3101,
  'firewall access-proxy-ssh-client-cert/cert-extension': 3102,
  'firewall access-proxy/api-gateway6': 3103,
  'firewall access-proxy/api-gateway6/realservers': 3104,
  'firewall access-proxy/api-gateway6/ssl-cipher-suites': 3105,
  'firewall access-proxy6': 3106,
  'firewall access-proxy6/api-gateway': 3107,
  'firewall access-proxy6/api-gateway/realservers': 3108,
  'firewall access-proxy6/api-gateway/ssl-cipher-suites': 3109,
  'firewall access-proxy6/api-gateway6': 3110,
  'firewall access-proxy6/api-gateway6/realservers': 3111,
  'firewall access-proxy6/api-gateway6/ssl-cipher-suites': 3112,
  'system automation-stitch/actions': 3113,
  'dynamic lte-modem': 3114,
  'dynamic lte-modem/lte-status': 3115,
  'user group/dynamic_mapping': 3116,
  'user group/dynamic_mapping/sslvpn-os-check-list': 3117,
  'user group/dynamic_mapping/match': 3118,
  'user group/dynamic_mapping/guest': 3119,
  'system sdn-connector/gcp-project-list': 3120,
  'system interface/ipv6/dhcp6-iapd-list': 3121,
  'system sdn-connector/forwarding-rule': 3122,
  'extender-controller extender-profile': 3123,
  'extender-controller extender-profile/cellular': 3124,
  'extender-controller extender-profile/cellular/controller-report': 3125,
  'extender-controller extender-profile/cellular/sms-notification': 3126,
  'extender-controller extender-profile/cellular/sms-notification/alert': 3127,
  'extender-controller extender-profile/cellular/sms-notification/receiver': 3128,
  'extender-controller extender-profile/cellular/modem1': 3129,
  'extender-controller extender-profile/cellular/modem1/auto-switch': 3130,
  'extender-controller extender-profile/cellular/modem2': 3131,
  'extender-controller extender-profile/cellular/modem2/auto-switch': 3132,
  'extender-controller extender-profile/lan-extension': 3133,
  'extender-controller extender-profile/lan-extension/backhaul': 3134,
  'extender-controller extender/wan-extension': 3135,
  'voip profile/msrp': 3136,
  'wireless-controller syslog-profile': 3137,
  'switch-controller managed-switch/igmp-snooping/vlans': 3138,
  'log tacacs+accounting setting': 3139,
  'log tacacs+accounting2 setting': 3140,
  'log tacacs+accounting3 setting': 3141,
  'log tacacs+accounting filter': 3142,
  'log tacacs+accounting2 filter': 3143,
  'log tacacs+accounting3 filter': 3144,
  'firewall vip6/dynamic_mapping/realservers': 3145,
  'firewall vip6/dynamic_mapping/ssl-cipher-suites': 3146,
  'vpn ipsec fec/mappings': 3147,
  'wireless-controller hotspot20 anqp-venue-url': 3148,
  'wireless-controller hotspot20 anqp-venue-url/value-list': 3149,
  'wireless-controller hotspot20 h2qp-advice-of-charge': 3150,
  'wireless-controller hotspot20 h2qp-advice-of-charge/aoc-list': 3151,
  'wireless-controller hotspot20 h2qp-advice-of-charge/aoc-list/plan-info': 3152,
  'wireless-controller hotspot20 h2qp-osu-provider-nai': 3153,
  'wireless-controller hotspot20 h2qp-osu-provider-nai/nai-list': 3154,
  'wireless-controller hotspot20 h2qp-terms-and-conditions': 3155,
  'system ipam': 3157,
  'emailfilter fortiguard': 3158,
  'vpn ipsec fec': 3159,
  'switch-controller dsl policy': 3160,
  'log tap-device': 3161,
  'log tap-device/filter': 3162,
  'log tap-device/filter/free-style': 3163,
  'wireless-controller vap/vlan-name': 3164,
  'system sdn-connector/external-account-list': 3165,
  'aws vpce': 3166,
  'system npu/background-sse-scan': 3170,
  'system lte-modem/data-plan': 3171,
  'system fortindr': 3172,
  'system replacemsg custom-message': 3173,
  'fmg variable': 3200,
  'fmg variable/dynamic_mapping': 3201,
  'icap server-group': 3202,
  'icap server-group/server-list': 3203,
  'switch-controller flow-tracking/collectors': 3204,
  'automation setting': 3205,
  'system snmp mib-view': 3206,
  'dlp data-type': 3207,
  'dlp dictionary': 3208,
  'dlp dictionary/entries': 3209,
  'dlp profile': 3210,
  'dlp profile/rule': 3211,
  'dlp sensor/entries': 3212,
  'system npu/sse-ha-scan': 3213,
  'system automation-action/http-headers': 3214,
  'extension-controller dataplan': 3215,
  'extension-controller extender-profile': 3216,
  'extension-controller extender-profile/cellular': 3217,
  'extension-controller extender-profile/cellular/controller-report': 3218,
  'extension-controller extender-profile/cellular/sms-notification': 3219,
  'extension-controller extender-profile/cellular/sms-notification/alert': 3220,
  'extension-controller extender-profile/cellular/sms-notification/receiver': 3221,
  'extension-controller extender-profile/cellular/modem1': 3222,
  'extension-controller extender-profile/cellular/modem1/auto-switch': 3223,
  'extension-controller extender-profile/cellular/modem2': 3224,
  'extension-controller extender-profile/cellular/modem2/auto-switch': 3225,
  'extension-controller extender-profile/lan-extension': 3226,
  'extension-controller extender-profile/lan-extension/backhaul': 3227,
  'extension-controller extender': 3228,
  'extension-controller extender/wan-extension': 3229,
  'extension-controller fortigate-profile': 3230,
  'extension-controller fortigate-profile/lan-extension': 3231,
  'extension-controller fortigate': 3232,
  'firewall internet-service-extension/disable-entry/ip6-range': 3233,
  'system ipam/pools': 3234,
  'system ipam/rules': 3235,
  'firewall global': 3236,
  'system standalone-cluster/cluster-peer': 3237,
  'system standalone-cluster/cluster-peer/session-sync-filter': 3238,
  'system standalone-cluster/cluster-peer/session-sync-filter/custom-service': 3239,
  'firewall network-service-dynamic': 3240,
  'ems-tag datasource': 3241,
  'system global/split-port-mode': 3242,
  'system sso-fortigate-cloud-admin': 3243,
  'system sso-fortigate-cloud-admin/gui-dashboard': 3244,
  'system sso-fortigate-cloud-admin/gui-dashboard/widget': 3245,
  'system sso-fortigate-cloud-admin/gui-dashboard/widget/fortiview-filters': 3246,
  'antivirus exempt-list': 3247,
  'switch-controller managed-switch/dhcp-snooping-static-client': 3248,
  'system device-upgrade': 3249,
  'system fabric-vpn': 3250,
  'system fabric-vpn/overlays': 3251,
  'system fabric-vpn/advertised-subnets': 3252,
  'router extcommunity-list': 3253,
  'router extcommunity-list/rule': 3254,
  'system vin-alarm': 3255,
  'vpn ssl web portal/landing-page': 3256,
  'vpn ssl web portal/landing-page/form-data': 3257,
  'system evpn': 3258,
  'vpn kmip-server': 3259,
  'vpn kmip-server/server-list': 3260,
  'system pcp-server': 3261,
  'system pcp-server/pools': 3262,
  'switch-controller managed-switch/ports/dhcp-snoop-option82-override': 3263,
  'system lte-modem/sim-switch': 3264,
  'system vpce': 3265,
  'user quarantine/targets/macs/msw': 3266,
  'switch-controller acl ingress': 3267,
  'switch-controller acl ingress/action': 3268,
  'switch-controller acl ingress/classifier': 3269,
  'switch-controller acl group': 3270,
  'switch-controller traffic-sniffer/target-mac/msw': 3271,
  'switch-controller traffic-sniffer/target-ip/msw': 3272,
  'system sdn-proxy': 3273,
  'system sdn-connector/compartment-list': 3274,
  'system sdn-connector/oci-region-list': 3275,
  'endpoint-control fctems-override': 3276,
  'system npu/sw-tr-hash': 3277,
  'virtual-patch profile': 3278,
  'virtual-patch profile/exemption': 3279,
  'web-proxy fast-fallback': 3280,
  'rule otvp': 3281,
  'switch-controller managed-switch/route-offload-router': 3282,
  'rule otdt': 3283,
  'firewall vip/quic': 3284,
  'casb saas-application': 3285,
  'casb user-activity': 3286,
  'casb user-activity/match': 3287,
  'casb user-activity/match/rules': 3288,
  'casb user-activity/control-options': 3289,
  'casb user-activity/control-options/operations': 3290,
  'firewall casb-profile': 3291,
  'firewall casb-profile/saas-application': 3292,
  'firewall casb-profile/saas-application/access-rule': 3293,
  'firewall casb-profile/saas-application/custom-control': 3294,
  'firewall casb-profile/saas-application/custom-control/option': 3295,
  'switch-controller ptp profile': 3296,
  'switch-controller ptp interface-policy': 3297,
  'ethernet-oam cfm': 3298,
  'ethernet-oam cfm/service': 3299,
  'firewall access-proxy/api-gateway/quic': 3300,
  'firewall access-proxy/api-gateway6/quic': 3301,
  'firewall access-proxy6/api-gateway/quic': 3302,
  'firewall access-proxy6/api-gateway6/quic': 3303,
  'system speed-test-setting': 3304,
  'casb profile': 3305,
  'casb profile/saas-application': 3306,
  'casb profile/saas-application/access-rule': 3307,
  'casb profile/saas-application/custom-control': 3308,
  'casb profile/saas-application/custom-control/option': 3309,
  'system netflow/exclusion-filters': 3310,
  'system timezone': 3311,
  'system interface/mirroring-filter': 3312,
  'system sflow/collectors': 3313,
  'system vdom-sflow/collectors': 3314,
  'diameter-filter profile': 3315,
  'vpn qkd': 3316,
  'firewall vip/gslb-public-ips': 3317,
  'firewall vip6/quic': 3318,
  'rule fmwp': 3319,
  'system federated-upgrade/known-ha-members': 3320,
  'system device-upgrade/known-ha-members': 3321,
  'system npu/npu-tcam': 3322,
  'system npu/npu-tcam/data': 3323,
  'system npu/npu-tcam/mask': 3324,
  'system npu/npu-tcam/mir-act': 3325,
  'system npu/npu-tcam/pri-act': 3326,
  'system npu/npu-tcam/sact': 3327,
  'system npu/npu-tcam/tact': 3328,
  'system netflow/collectors': 3329,
  'system vdom-netflow/collectors': 3330,
  'system digital-io': 3331,
  'user external-identity-provider': 3332,
  'videofilter keyword': 3333,
  'videofilter keyword/word': 3334,
  'videofilter profile/filters': 3335,
  'dlp exact-data-match': 3336,
  'dlp exact-data-match/columns': 3337,
  'switch-controller managed-switch/vlan': 3338,
  'system 5g-modem': 3339,
  'system 5g-modem/data-plan': 3340,
  'system 5g-modem/modem1': 3341,
  'system 5g-modem/modem1/sim-switch': 3342,
  'system 5g-modem/modem2': 3343,
  'firewall ssl-ssh-profile/ech-outer-sni': 3346,
  'extension-controller extender-vap': 3347,
  'extension-controller extender-profile/wifi': 3348,
  'extension-controller extender-profile/wifi/radio-1': 3349,
  'extension-controller extender-profile/wifi/radio-2': 3350,
  'firewall on-demand-sniffer': 3351,
  'system ipam/pools/exclude': 3352,
  'azure nva': 3353,
  'azure vwan-slb': 3354,
  'azure vwan-slb/permanent-security-rules': 3355,
  'azure vwan-slb/permanent-security-rules/rules': 3356,
  'azure vwan-slb/temporary-security-rules': 3357,
  'azure vwan-slb/temporary-security-rules/rules': 3358,
  'system ssh-config': 3359,
  'system icond': 3360,
  'system npu-post': 3361,
  'system npu-post/port-npu-map': 3362,
  'system npu/icmp-rate-ctrl': 3363,
  'user local/pwd-history': 3364,
  'system interface/ipv6/client-options': 3365,
  'system admin/pwd-history': 3366,
  'system snmp rmon-stat': 3367,
  'system dhcp6 server/options': 3368,
  'system sdwan/health-check-fortiguard': 3369,
  'system sdwan/health-check-fortiguard/sla': 3370,
  'user scim': 3371,
  'extension-controller extender-profile/lan-extension/downlinks': 3372,
  'ztna traffic-forward-proxy': 3373,
  'ztna traffic-forward-proxy/quic': 3374,
  'ztna traffic-forward-proxy/ssl-cipher-suites': 3375,
  'ztna traffic-forward-proxy/ssl-server-cipher-suites': 3376,
  'ztna traffic-forward-proxy-reverse-service': 3377,
  'ztna traffic-forward-proxy-reverse-service/remote-servers': 3378,
  'azure vwan-ingress-public-IPs': 3379,
  'system npu/icmp-error-rate-ctrl': 3380,
  'system vne-interface': 3381,
  'router multicast/pim-sm-global-vrf': 3382,
  'router multicast/pim-sm-global-vrf/rp-address': 3383,
  'system interface/ipv6/ip6-route-list': 3386,
  'system interface/ipv6/ip6-rdnss-list': 3387,
  'system interface/ipv6/ip6-dnssl-list': 3388,
  'extension-controller extender-profile/lan-extension/traffic-split-services': 3389,
  'ztna reverse-connector': 3390,
  'ztna web-proxy': 3391,
  'ztna web-proxy/api-gateway': 3392,
  'ztna web-proxy/api-gateway/quic': 3393,
  'ztna web-proxy/api-gateway/realservers': 3394,
  'ztna web-proxy/api-gateway/ssl-cipher-suites': 3395,
  'ztna web-proxy/api-gateway6': 3396,
  'ztna web-proxy/api-gateway6/quic': 3397,
  'ztna web-proxy/api-gateway6/realservers': 3398,
  'ztna web-proxy/api-gateway6/ssl-cipher-suites': 3399,
  'ztna web-portal': 3400,
  'ztna web-portal-bookmark': 3401,
  'ztna web-portal-bookmark/bookmarks': 3402,
  'system ngfw-settings': 3403,
  'system security-rating settings': 3404,
  'system security-rating controls': 3405,
  'system automation-condition': 3406,
  'system standalone-cluster/monitor-prefix': 3407,
  'load-balance worker-group': 3408,
  'system sdn-vpn': 3409,
  'system health-check-fortiguard': 3410,
  'casb saas-application/output-attributes': 3411,
  'casb saas-application/input-attributes': 3412,
  'casb user-activity/match/tenant-extraction': 3413,
  'casb user-activity/match/tenant-extraction/filters': 3414,
  'casb attribute-match': 3415,
  'casb attribute-match/attribute': 3416,
  'casb profile/saas-application/access-rule/attribute-filter': 3417,
  'casb profile/saas-application/custom-control/attribute-filter': 3418,
  'casb profile/saas-application/advanced-tenant-control': 3419,
  'casb profile/saas-application/advanced-tenant-control/attribute': 3420,
  'web-proxy dynamic-bypass': 4010,
  'system nethsm': 4011,
  'system nethsm/servers': 4012,
  'system nethsm/slots': 4013,
  'system nethsm/hagroups': 4014,
  'web-proxy url-list': 4015,
  'web-proxy url-list/entries': 4016,
  'icap remote-server-group': 4017,
  'icap remote-server-group/server-list': 4018,
  'firewall ssl keyring-list': 4019,
  'firewall ssl-ssh-profile/ssl-client-certificate': 4020,
  'web-proxy explicit-proxy': 4021,
  'user saml/dynamic_mapping': 4022,
  'user json': 4023,
  'extension-controller extender/_modem1': 4024,
  'extension-controller extender/_modem1/_cdma_profile': 4025,
  'extension-controller extender/_modem1/_gsm_profile': 4026,
  'extension-controller extender/_modem1/_sim1': 4027,
  'extension-controller extender/_modem1/_sim2': 4028,
  'extension-controller extender/_modem1/_auto-switch': 4029,
  'extension-controller extender/_modem2': 4030,
  'extension-controller extender/_modem2/_cdma_profile': 4031,
  'extension-controller extender/_modem2/_gsm_profile': 4032,
  'extension-controller extender/_modem2/_sim1': 4033,
  'extension-controller extender/_modem2/_sim2': 4034,
  'extension-controller extender/_modem2/_auto-switch': 4035,
  'webcache prefetch': 4036,
  'firewall ssl default-certificate': 4037,
  'isolator profile': 4038,
  'extender-controller extender/modem': 4039,
  'isolator profile/entries': 4040,
  'extender-controller extender/modem/_cdma_profile': 4041,
  'extender-controller extender/modem/_gsm_profile': 4042,
  'cloud orchestration': 4043,
  'cloud orchest-aws': 4044,
  'cloud orchest-awsconnector': 4045,
  'cloud orchest-awstemplate autoscale-tgw-new-vpc': 4046,
  'cloud orchest-awstemplate autoscale-existing-vpc': 4047,
  'cloud orchest-awstemplate autoscale-new-vpc': 4048,
  'system admin/trusthosts': 4049,
  'system ha-lic': 4050,
  'webfilter url-list': 4051,
  'webfilter url-list/entries': 4052,
  'webfilter domain-list': 4053,
  'webfilter domain-list/entries': 4054,
  'web-proxy implicit-proxy-rule': 4055,
  'web-proxy implicit-proxy-rule/proxy-servers': 4056,
  'web-proxy implicit-proxy-setting': 4057,
  'isolator setting': 4058,
  'firewall profile-protocol-options/proxy-redirect': 4059,
  'web-proxy redirect-profile': 4060,
  'web-proxy redirect-profile/entries': 4061,
  'firewall proxy-address6': 4062,
  'firewall proxy-address6/header-group': 4063,
  'firewall proxy-address6/tagging': 4064,
  'firewall proxy-addrgrp6': 4065,
  'firewall proxy-addrgrp6/tagging': 4066,
  'webfilter ftgd-risk-level': 4067,
  'webfilter profile/ftgd-wf/risk': 4068,
  'webfilter ftgd-local-risk': 4069,
}; // String to Number
const NUM2CATE = {
  1: 'system vdom',
  2: 'system global',
  3: 'system accprofile',
  4: 'system accprofile/fwgrp-permission',
  5: 'system admin',
  6: 'system admin/dashboard',
  7: 'system vdom-link',
  8: 'system interface',
  9: 'user group/match',
  10: 'system interface/secondaryip',
  11: 'system interface/ipv6',
  12: 'system interface/ipv6/ip6-prefix-list',
  13: 'system ha',
  14: 'system ha/secondary-vcluster',
  15: 'system dns',
  16: 'system replacemsg mail',
  17: 'system replacemsg http',
  18: 'system replacemsg ftp',
  19: 'system replacemsg nntp',
  20: 'system replacemsg alertmail',
  21: 'system replacemsg fortiguard-wf',
  22: 'system replacemsg spam',
  23: 'system replacemsg admin',
  24: 'system replacemsg auth',
  25: 'system replacemsg im',
  26: 'system replacemsg sslvpn',
  27: 'system snmp sysinfo',
  28: 'system snmp community',
  29: 'system snmp community/hosts',
  30: 'system autoupdate override',
  31: 'system autoupdate push-update',
  32: 'system autoupdate schedule',
  33: 'system autoupdate tunneling',
  34: 'system autoupdate clientoverride',
  36: 'system fmc',
  37: 'system fm',
  38: 'webfilter ftgd-local-cat',
  47: 'webfilter urlfilter',
  48: 'webfilter urlfilter/entries',
  49: 'webfilter fortiguard',
  50: 'webfilter ftgd-ovrd',
  51: 'webfilter ftgd-local-rating',
  52: 'vpn certificate ca',
  53: 'vpn certificate local',
  54: 'vpn certificate crl',
  55: 'vpn certificate remote',
  56: 'spamfilter bword',
  57: 'spamfilter bword/entries',
  58: 'spamfilter ipbwl',
  59: 'spamfilter ipbwl/entries',
  60: 'spamfilter dnsbl',
  61: 'spamfilter dnsbl/entries',
  62: 'spamfilter emailbwl',
  63: 'spamfilter emailbwl/entries',
  64: 'spamfilter mheader',
  65: 'spamfilter mheader/entries',
  66: 'spamfilter fortishield',
  67: 'spamfilter iptrust',
  68: 'spamfilter iptrust/entries',
  69: 'spamfilter options',
  70: 'system fortiguard',
  71: 'system fortiguard/srv-ovrd-list',
  72: 'imp2p policy',
  73: 'imp2p old-version',
  74: 'imp2p aim-user',
  75: 'imp2p icq-user',
  76: 'imp2p msn-user',
  77: 'imp2p yahoo-user',
  81: 'system fmc-slot',
  85: 'ips custom',
  86: 'ips global',
  87: 'system fortianalyzer',
  88: 'system fortianalyzer2',
  89: 'system fortianalyzer3',
  90: 'log syslogd setting',
  91: 'log syslogd2 setting',
  92: 'log syslogd3 setting',
  93: 'log webtrends setting',
  94: 'log fortianalyzer setting',
  95: 'log fortianalyzer2 setting',
  96: 'log fortianalyzer3 setting',
  97: 'log syslogd filter',
  98: 'log syslogd2 filter',
  99: 'log syslogd3 filter',
  100: 'log webtrends filter',
  101: 'log fortianalyzer filter',
  102: 'log fortianalyzer2 filter',
  103: 'log fortianalyzer3 filter',
  115: 'system alertemail',
  116: 'gui console',
  117: 'system session-helper',
  118: 'system tos-based-priority',
  119: 'system auto-install',
  120: 'system console',
  121: 'system bug-report',
  122: 'antivirus filepattern',
  123: 'antivirus filepattern/entries',
  124: 'antivirus service',
  125: 'antivirus heuristic',
  127: 'antivirus quarantine',
  128: 'antivirus quarfilepattern',
  129: 'system settings',
  130: 'system ipv6-tunnel',
  131: 'system gre-tunnel',
  132: 'system arp-table',
  133: 'system session-ttl',
  134: 'system session-ttl/port',
  135: 'system dhcp server',
  136: 'system dhcp server/exclude-range',
  137: 'system dhcp reserved-address',
  138: 'system zone',
  140: 'firewall address',
  141: 'firewall address6',
  142: 'firewall addrgrp',
  144: 'user fsae',
  145: 'user radius',
  146: 'user ldap',
  147: 'user local',
  148: 'user peer',
  149: 'user peergrp',
  150: 'system object-tag',
  151: 'vpn ssl settings',
  152: 'user group',
  153: 'system elbc',
  154: 'vpn certificate ocsp',
  155: 'vpn ipsec phase1',
  156: 'vpn ipsec phase2',
  157: 'vpn ipsec manualkey',
  158: 'vpn ipsec concentrator',
  159: 'system snmp community/hosts6',
  160: 'vpn ipsec phase1-interface',
  161: 'vpn ipsec phase2-interface',
  162: 'vpn ipsec manualkey-interface',
  163: 'vpn pptp',
  164: 'vpn l2tp',
  165: 'vpn ipsec forticlient',
  166: 'vpn ssl web favorite',
  167: 'firewall service custom',
  168: 'firewall service group',
  169: 'system dhcp6 server',
  170: 'firewall schedule onetime',
  171: 'firewall schedule recurring',
  172: 'firewall ippool',
  173: 'firewall vip',
  174: 'firewall vip/realservers',
  175: 'firewall vipgrp',
  176: 'system dhcp6 server/ip-range',
  177: 'firewall ipmacbinding setting',
  178: 'firewall ipmacbinding table',
  179: 'firewall addrgrp6',
  180: 'system port-pair',
  181: 'firewall policy',
  182: 'system 3g-modem custom',
  183: 'application rule-settings',
  184: 'user fortitoken',
  185: 'user sms-provider',
  186: 'webfilter profile/override',
  187: 'firewall policy6',
  188: 'wireless-controller vap/portal-message-overrides',
  189: 'firewall dnstranslation',
  190: 'firewall multicast-policy',
  191: 'log trafficfilter',
  193: 'log memory setting',
  194: 'log disk setting',
  195: 'log memory filter',
  196: 'log disk filter',
  197: 'alertemail setting',
  199: 'system mac-address-table',
  200: 'router access-list',
  201: 'router access-list/rule',
  202: 'router aspath-list',
  203: 'router aspath-list/rule',
  204: 'router prefix-list',
  205: 'router prefix-list/rule',
  206: 'router key-chain',
  207: 'router key-chain/key',
  208: 'router community-list',
  209: 'router community-list/rule',
  210: 'router route-map',
  211: 'router route-map/rule',
  212: 'webfilter override',
  213: 'webfilter override-user',
  214: 'antivirus profile/ftps',
  215: 'firewall service explicit-web',
  216: 'router rip',
  217: 'router rip/distance',
  218: 'router rip/distribute-list',
  219: 'router rip/neighbor',
  220: 'router rip/network',
  221: 'router rip/offset-list',
  222: 'firewall service group-explicit-web',
  223: 'router rip/redistribute',
  224: 'router rip/interface',
  225: 'router static',
  226: 'router policy',
  227: 'router static6',
  228: 'router ospf',
  229: 'router ospf/area',
  230: 'router ospf/area/range',
  231: 'router ospf/area/virtual-link',
  232: 'router ospf/area/filter-list',
  233: 'router ospf/ospf-interface',
  234: 'router ospf/network',
  235: 'router ospf/neighbor',
  236: 'webfilter profile/ftgd-wf/quota',
  237: 'router ospf/summary-address',
  238: 'router ospf/distribute-list',
  239: 'router ospf/redistribute',
  240: 'router bgp',
  241: 'firewall profile-protocol-options/ftps',
  242: 'router bgp/aggregate-address',
  243: 'router bgp/neighbor',
  244: 'router bgp/network',
  245: 'router bgp/redistribute',
  246: 'router bgp/admin-distance',
  247: 'router multicast',
  248: 'router multicast/pim-sm-global',
  249: 'router multicast/pim-sm-global/rp-address',
  250: 'router multicast/interface',
  251: 'router multicast/interface/join-group',
  252: 'router multicast/interface/igmp',
  253: 'system proxy-arp',
  254: 'firewall service predefined',
  255: 'user adgrp',
  256: 'system interface/wifi-mac-list',
  257: 'system modem',
  258: 'system fortiguard-log',
  259: 'log fortiguard setting',
  260: 'log fortiguard filter',
  261: 'system wireless settings',
  265: 'system aux',
  266: 'system interface/l2tp-client-settings',
  269: 'firewall multicast-address',
  270: 'firewall local-in-policy',
  271: 'firewall policy6/identity-based-policy',
  272: 'firewall local-in-policy6',
  273: 'web-proxy forward-server',
  274: 'ips rule-settings',
  275: 'wireless-controller setting',
  276: 'wireless-controller wtp-profile',
  277: 'wireless-controller wtp-profile/platform',
  278: 'wireless-controller wtp-profile/radio-1',
  280: 'user group/sslvpn-os-check-list',
  281: 'vpn ssl web bookmarks',
  282: 'vpn ssl web bookmarks-group',
  283: 'system accprofile/loggrp-permission',
  284: 'system switch-interface',
  285: 'system session-sync',
  286: 'system session-sync/filter',
  287: 'system fortimanager',
  288: 'ips sensor',
  289: 'ips sensor/filter',
  290: 'ips sensor/override',
  291: 'ips sensor/override/exempt-ip',
  292: 'log custom-field',
  293: 'user tacacs+',
  294: 'user setting',
  295: 'webfilter ftgd-ovrd-user',
  296: 'firewall ldb-monitor',
  297: 'ips decoder',
  298: 'ips decoder/parameter',
  299: 'ips rule',
  300: 'ips DoS',
  301: 'ips DoS/anomaly',
  303: 'log memory global-setting',
  304: 'system alarm',
  305: 'system alarm/groups',
  306: 'system alarm/groups/fw-policy-violations',
  307: 'router auth-path',
  308: 'system npu',
  309: 'system fips-cc',
  310: 'system management-tunnel',
  311: 'system ntp',
  312: 'system ntp/ntpserver',
  313: 'system dhcp server/reserved-address',
  314: 'system sit-tunnel',
  315: 'system amc',
  316: 'system central-management',
  317: 'system wccp',
  318: 'firewall interface-policy',
  319: 'firewall interface-policy6',
  320: 'wireless-controller wtp-profile/radio-2',
  321: 'user fsso',
  322: 'wireless-controller wtp-profile/deny-mac-list',
  323: 'log remote setting',
  324: 'router multicast-flow',
  325: 'router multicast-flow/flows',
  326: 'vpn fips-cc',
  327: 'ftp-proxy explicit',
  328: 'log syslogd filter/exclude-list',
  329: 'wireless-controller vap/mac-filter-list',
  330: 'system ddns',
  331: 'system replacemsg captive-portal-dflt',
  333: 'system replacemsg-group/captive-portal-dflt',
  335: 'dlp filepattern',
  336: 'dlp filepattern/entries',
  337: 'dlp fp-sensitivity',
  338: 'dlp fp-doc-source',
  339: 'dlp sensor/filter',
  340: 'system dns-server',
  341: 'webfilter profile/ftgd-wf/filters',
  342: 'webfilter ftgd-warning',
  343: 'icap server',
  344: 'icap profile',
  345: 'endpoint-control app-detect sig-settings',
  346: 'spamfilter profile/msn-hotmail',
  347: 'spamfilter profile/yahoo-mail',
  348: 'spamfilter profile/gmail',
  349: 'firewall profile-protocol-options/ssl-server',
  350: 'log gui',
  351: 'router ospf6/summary-address',
  352: 'system monitors',
  353: 'dlp settings',
  354: 'system sp',
  355: 'router gwdetect',
  356: 'system npu-cascade-cluster',
  357: 'ips sensor/entries',
  358: 'ips sensor/entries/exempt-ip',
  359: 'system bypass',
  360: 'system dedicated-mgmt',
  361: 'log syslogd filter/exclude-list/fields',
  362: 'log syslogd2 filter/exclude-list',
  363: 'log syslogd2 filter/exclude-list/fields',
  364: 'log syslogd3 filter/exclude-list',
  365: 'log syslogd3 filter/exclude-list/fields',
  366: 'log syslogd override-filter/exclude-list',
  367: 'log syslogd override-filter/exclude-list/fields',
  368: 'log webtrends filter/exclude-list',
  369: 'log webtrends filter/exclude-list/fields',
  370: 'log memory filter/exclude-list',
  371: 'log memory filter/exclude-list/fields',
  372: 'log disk filter/exclude-list',
  373: 'log disk filter/exclude-list/fields',
  374: 'log fortiguard filter/exclude-list',
  375: 'log fortiguard filter/exclude-list/fields',
  376: 'log fortianalyzer filter/exclude-list',
  377: 'log fortianalyzer filter/exclude-list/fields',
  378: 'log fortianalyzer2 filter/exclude-list',
  379: 'log fortianalyzer2 filter/exclude-list/fields',
  380: 'log fortianalyzer3 filter/exclude-list',
  381: 'log fortianalyzer3 filter/exclude-list/fields',
  382: 'log fortianalyzer override-filter/exclude-list',
  383: 'log fortianalyzer override-filter/exclude-list/fields',
  384: 'firewall service category',
  385: 'system stp',
  386: 'system physical-switch',
  387: 'system physical-switch/port',
  388: 'system virtual-switch',
  389: 'system virtual-switch/port',
  390: 'system sms-server',
  391: 'webfilter ips-urlfilter-setting',
  392: 'system fsso-polling',
  393: 'system ha/frup-settings',
  394: 'system replacemsg utm',
  395: 'system replacemsg-group/utm',
  396: 'system replacemsg-group/custom-message',
  397: 'spamfilter bwl',
  398: 'spamfilter bwl/entries',
  399: 'client-reputation profile',
  400: 'client-reputation profile/level',
  401: 'client-reputation profile/ips',
  402: 'client-reputation profile/web',
  403: 'client-reputation profile/geolocation',
  404: 'client-reputation profile/application',
  406: 'vpn certificate ocsp-server',
  407: 'vpn certificate setting',
  408: 'user password-policy',
  409: 'user fsso-polling',
  410: 'user fsso-polling/adgrp',
  411: 'user group/guest',
  412: 'webfilter search-engine',
  413: 'system network-visibility',
  416: 'antivirus profile/mapi',
  417: 'antivirus profile/smb',
  418: 'spamfilter profile/mapi',
  420: 'firewall ippool6',
  421: 'firewall vip6',
  422: 'webfilter ips-urlfilter-cache-setting',
  423: 'firewall vipgrp6',
  425: 'firewall profile-protocol-options/ssh',
  426: 'firewall profile-protocol-options/dns',
  427: 'firewall profile-protocol-options/mapi',
  428: 'firewall identity-based-route',
  429: 'firewall identity-based-route/rule',
  430: 'firewall auth-portal',
  431: 'web-proxy debug-url',
  432: 'firewall ttl-policy',
  433: 'firewall policy64',
  434: 'firewall isf-acl',
  435: 'firewall DoS-policy',
  436: 'firewall DoS-policy/anomaly',
  437: 'firewall sniffer',
  438: 'wireless-controller wids-profile',
  439: 'switch-controller vlan',
  440: 'switch-controller vlan/portal-message-overrides',
  441: 'switch-controller managed-switch',
  442: 'switch-controller managed-switch/ports',
  443: 'log fortiguard override-setting',
  444: 'log setting',
  445: 'log fortiguard override-filter',
  446: 'system email-server',
  447: 'router ripng/distance',
  448: 'router policy6',
  449: 'router bfd',
  450: 'router bfd/neighbor',
  451: 'system nat64',
  452: 'system vdom-radius-server',
  453: 'firewall ip-translation',
  457: 'wanopt profile',
  458: 'wanopt profile/http',
  459: 'wanopt profile/cifs',
  460: 'wanopt profile/mapi',
  461: 'wanopt profile/ftp',
  462: 'wanopt profile/tcp',
  463: 'report dataset/parameters',
  464: 'report chart/drill-down-charts',
  465: 'report layout/body-item/parameters',
  466: 'system ips-urlfilter-dns',
  467: 'system geoip-override',
  468: 'system geoip-override/ip-range',
  472: 'system interface/wifi-networks',
  473: 'system replacemsg device-detection-portal',
  474: 'application custom',
  475: 'user device-category',
  476: 'user device',
  477: 'user device-group',
  478: 'user device-access-list',
  479: 'user device-access-list/device-list',
  480: 'system replacemsg-group/device-detection-portal',
  481: 'firewall sniffer/anomaly',
  482: 'system sp3-port',
  483: 'system server-probe',
  484: 'system probe-response',
  485: 'endpoint-control profile/forticlient-winmac-settings',
  486: 'endpoint-control profile/forticlient-winmac-settings/forticlient-vpn-settings',
  487: 'endpoint-control profile/forticlient-android-settings',
  488: 'endpoint-control profile/forticlient-android-settings/forticlient-vpn-settings',
  489: 'endpoint-control profile/forticlient-ios-settings',
  491: 'ips dbinfo',
  492: 'firewall deep-inspection-options',
  493: 'firewall deep-inspection-options/ssl',
  494: 'firewall deep-inspection-options/https',
  495: 'firewall deep-inspection-options/ftps',
  496: 'firewall deep-inspection-options/imaps',
  497: 'firewall deep-inspection-options/pop3s',
  498: 'firewall deep-inspection-options/smtps',
  499: 'firewall deep-inspection-options/ssh',
  500: 'firewall deep-inspection-options/ssl-server',
  501: 'vpn ssl web portal/mac-addr-check-rule',
  502: 'firewall DoS-policy6',
  503: 'vpn ssl web realm',
  504: 'endpoint-control profile/forticlient-ios-settings/client-vpn-settings',
  505: 'endpoint-control forticlient-registration-sync',
  506: 'endpoint-control client',
  507: 'firewall vip/ssl-cipher-suites',
  508: 'firewall vip64',
  509: 'firewall DoS-policy6/anomaly',
  510: 'firewall vip46',
  511: 'router ospf6/ospf6-interface/neighbor',
  512: 'firewall vipgrp64',
  513: 'firewall vipgrp46',
  514: 'firewall policy46',
  515: 'router multicast6',
  516: 'router multicast6/interface',
  517: 'router multicast6/pim-sm-global',
  518: 'router multicast6/pim-sm-global/rp-address',
  519: 'system ipip-tunnel',
  520: 'endpoint-control registered-forticlient',
  521: 'web-proxy url-match',
  530: 'device-template action',
  531: 'device-template action/attrs',
  532: 'device-template action/attrs/fixed-attrs',
  533: 'device template widget',
  534: 'device template widget/action-list',
  535: 'device template widget/action-list/var-list',
  536: 'device template widget/action-list/dynamic_mapping',
  537: 'device-template action/args',
  550: 'template setting',
  551: 'cloud service template',
  552: 'cloud service template/cst-vdom',
  553: 'cloud service template/cst-vdom/cst-vdom-gintf',
  554: 'cloud service template/cst-vdom/cst-vdom-vwp',
  555: 'cloud service template/cst-srv-chain',
  556: 'cloud service template/cst-srv-chain/cst-srv-chain-index',
  557: 'sdwan overlay',
  558: 'sdwan overlay/nodes',
  559: 'sdwan overlay/nodes/underlay',
  560: 'sdwan overlay/nodes/neighbor',
  561: 'sdwan overlay/nodes/neighbor-group',
  562: 'sdwan overlay/nodes/vrf',
  800: 'dynamic interface',
  801: 'dynamic interface/dynamic_mapping',
  802: 'dynamic interface/platform_mapping',
  810: 'dynamic address',
  811: 'dynamic address/dynamic_addr_mapping',
  900: 'fmg sase-manager settings',
  901: 'fmg sase-manager status',
  1001: 'device ha nonhaconf',
  1002: 'fsp packet-capture',
  1003: 'fsp packet-capture/target-mac',
  1004: 'vpnmgr vpntable',
  1005: 'vpnmgr node',
  1006: 'vpnmgr node/protected_subnet',
  1007: 'firewall policy/vpn_src_node',
  1008: 'firewall policy/vpn_dst_node',
  1009: 'system accprofile/utmgrp-permission',
  1010: 'system interface/t1e1-hdlc',
  1011: 'fsp packet-capture/target-ip',
  1012: 'fmg device blueprint',
  1013: 'fmg fabric authorization template',
  1014: 'fmg fabric authorization template/platforms',
  1015: 'fsp packet-capture/target-port',
  1016: 'fsp packet-capture/firewall-sniffer',
  1017: 'fmg device blueprint/vdoms',
  1018: 'system resource-limits',
  1019: 'system vdom-property',
  1020: 'system replacemsg ec',
  1021: 'system replacemsg nac-quar',
  1022: 'system snmp user',
  1025: 'firewall traffic-shaper',
  1026: 'ips settings',
  1027: 'application name',
  1028: 'application list',
  1029: 'application list/entries',
  1030: 'application list/entries/parameters',
  1031: 'dlp rule',
  1032: 'dlp compound',
  1038: 'dlp sensor',
  1039: 'dlp sensor/rule',
  1040: 'dlp sensor/compound-rule',
  1041: 'user ban',
  1042: 'wanopt settings',
  1043: 'wanopt peer',
  1044: 'wanopt auth-group',
  1045: 'wanopt ssl-server',
  1046: 'wanopt rule',
  1047: 'wanopt storage',
  1053: 'router setting',
  1054: 'vpn ssl web portal',
  1055: 'vpn ssl web portal/widget',
  1056: 'vpn ssl web portal/widget/bookmarks',
  1057: 'vpn ssl web portal/os-check-list',
  1058: 'vpn ssl web user',
  1059: 'vpn ssl web user/widget',
  1060: 'vpn ssl web user/widget/bookmarks',
  1061: 'system wireless ap-status',
  1062: 'system dialinsvr',
  1063: 'firewall ssl setting',
  1065: 'firewall policy/identity-based-policy',
  1066: 'web-proxy explicit',
  1067: 'web-proxy global',
  1068: 'user setting/auth-ports',
  1071: 'wanopt webcache',
  1073: 'notification',
  1074: 'system gi-gk',
  1075: 'system replacemsg-image',
  1076: 'system replacemsg-group',
  1077: 'system replacemsg-group/mail',
  1078: 'system replacemsg-group/http',
  1079: 'system replacemsg-group/mms',
  1080: 'system replacemsg-group/mm1',
  1081: 'system replacemsg-group/mm3',
  1082: 'system replacemsg-group/mm4',
  1083: 'system replacemsg-group/mm7',
  1084: 'system replacemsg-group/ftp',
  1085: 'system replacemsg-group/nntp',
  1086: 'system replacemsg-group/fortiguard-wf',
  1087: 'system replacemsg-group/spam',
  1088: 'system replacemsg-group/im',
  1089: 'system dynamic-profile',
  1090: 'system carrier-endpoint-translation',
  1091: 'system mem-mgr',
  1092: 'system replacemsg mms',
  1093: 'system replacemsg mm1',
  1094: 'system replacemsg mm3',
  1095: 'system replacemsg mm4',
  1096: 'system replacemsg mm7',
  1097: 'firewall mms-profile',
  1099: 'firewall mms-profile/notification',
  1100: 'system meta',
  1101: 'system meta/sys_meta_fields',
  1103: 'policy package settings',
  1104: 'adom options',
  1200: 'firewall mms-profile/notif-msisdn',
  1201: 'firewall mms-profile/flood',
  1202: 'firewall mms-profile/dupe',
  1203: 'firewall gtp',
  1204: 'firewall gtp/apn',
  1205: 'firewall gtp/imsi',
  1206: 'firewall gtp/policy',
  1207: 'firewall gtp/ie-remove-policy',
  1208: 'firewall gtp/ip-policy',
  1209: 'firewall gtp/noip-policy',
  1210: 'firewall gtp/message-filter',
  1211: 'firewall gtp/ie-validation',
  1212: 'firewall gtp/message-rate-limit',
  1213: 'firewall carrier-endpoint-bwl',
  1214: 'firewall carrier-endpoint-bwl/entries',
  1215: 'firewall carrier-endpoint-ip-filter',
  1216: 'antivirus notification',
  1217: 'antivirus notification/entries',
  1222: 'endpoint-control settings',
  1304: 'vpn ipsec phase1-interface/ipv4-exclude-range',
  1305: 'vpn ipsec phase1-interface/ipv6-exclude-range',
  1307: 'router bgp/aggregate-address6',
  1308: 'router bgp/network6',
  1309: 'router bgp/redistribute6',
  1310: 'vpn ssl web portal/widget/bookmarks/form-data',
  1314: 'vpn ssl web user/widget/bookmarks/form-data',
  1315: 'system replacemsg-group/alertmail',
  1316: 'system replacemsg-group/admin',
  1317: 'system replacemsg-group/auth',
  1318: 'system replacemsg-group/ec',
  1319: 'system replacemsg-group/nac-quar',
  1320: 'system replacemsg-group/traffic-quota',
  1321: 'system replacemsg-group/sslvpn',
  1322: 'system dns-database',
  1323: 'system dns-database/dns-entry',
  1324: 'system password-policy',
  1325: 'system vdom-dns',
  1326: 'system replacemsg traffic-quota',
  1327: 'webfilter content',
  1328: 'webfilter content/entries',
  1329: 'webfilter content-header',
  1330: 'webfilter content-header/entries',
  1331: 'endpoint-control app-detect predefined-signature',
  1332: 'endpoint-control app-detect predefined-category',
  1333: 'endpoint-control app-detect predefined-vendor',
  1334: 'endpoint-control app-detect predefined-group',
  1335: 'endpoint-control app-detect rule-list',
  1336: 'endpoint-control app-detect rule-list/entries',
  1337: 'endpoint-control profile',
  1338: 'firewall schedule group',
  1340: 'log syslogd override-setting',
  1341: 'log syslogd override-filter',
  1342: 'log fortianalyzer override-setting',
  1343: 'log fortianalyzer override-filter',
  1344: 'router access-list6',
  1345: 'router access-list6/rule',
  1346: 'router prefix-list6',
  1347: 'router prefix-list6/rule',
  1348: 'router ripng',
  1349: 'router ripng/distribute-list',
  1350: 'router ripng/neighbor',
  1351: 'router ripng/aggregate-address',
  1352: 'router ripng/offset-list',
  1354: 'router ripng/redistribute',
  1355: 'router ripng/interface',
  1356: 'router ospf6',
  1357: 'router ospf6/area',
  1358: 'router ospf6/area/range',
  1359: 'router ospf6/area/virtual-link',
  1360: 'router ospf6/ospf6-interface',
  1362: 'router ospf6/redistribute',
  1364: 'firewall shaper traffic-shaper',
  1365: 'firewall shaper per-ip-shaper',
  1367: 'vpn ssl web virtual-desktop-app-list',
  1368: 'vpn ssl web virtual-desktop-app-list/apps',
  1370: 'vpn ssl web host-check-software',
  1371: 'vpn ssl web host-check-software/check-item-list',
  1373: 'report dataset',
  1374: 'report dataset/field',
  1375: 'report chart',
  1376: 'report chart/x-series',
  1377: 'report chart/y-series',
  1378: 'report chart/category-series',
  1379: 'report chart/value-series',
  1380: 'report chart/column',
  1381: 'report chart/column/mapping',
  1382: 'report summary',
  1383: 'system chassis-loadbalance',
  1385: 'webfilter cookie-ovrd',
  1386: 'log disk setting/sql-logging',
  1387: 'firewall sniff-interface-policy',
  1391: 'firewall sniff-interface-policy6',
  1392: 'antivirus settings',
  1393: 'wireless-controller global',
  1394: 'wireless-controller global/ac-list',
  1395: 'wireless-controller timers',
  1396: 'wireless-controller vap',
  1397: 'wireless-controller vap-group',
  1399: 'wireless-controller wtp',
  1401: 'wireless-controller wtp/deny-mac-list',
  1402: 'wireless-controller ap-status',
  1403: 'system admin/dashboard-tabs',
  1404: 'system interface/ipv6/ip6-extra-addr',
  1406: 'system interface/vrrp',
  1408: 'system replacemsg-group/webproxy',
  1409: 'system chassis-loadbalance/conf-sync',
  1410: 'system sflow',
  1411: 'system vdom-sflow',
  1412: 'system replacemsg webproxy',
  1413: 'webfilter profile',
  1414: 'webfilter profile/http',
  1415: 'webfilter profile/https',
  1416: 'webfilter profile/web',
  1417: 'webfilter profile/ftgd-wf',
  1419: 'webfilter profile/ftgd-quota',
  1420: 'antivirus profile',
  1421: 'antivirus profile/http',
  1422: 'antivirus profile/ftp',
  1423: 'antivirus profile/imap',
  1424: 'antivirus profile/pop3',
  1425: 'antivirus profile/smtp',
  1426: 'antivirus profile/nntp',
  1427: 'antivirus profile/im',
  1428: 'antivirus profile/nac-quar',
  1429: 'antivirus profile/https',
  1430: 'antivirus profile/imaps',
  1431: 'antivirus profile/pop3s',
  1432: 'antivirus profile/smtps',
  1433: 'spamfilter profile',
  1434: 'spamfilter profile/imap',
  1435: 'spamfilter profile/pop3',
  1436: 'spamfilter profile/smtp',
  1437: 'spamfilter profile/imaps',
  1438: 'spamfilter profile/pop3s',
  1439: 'spamfilter profile/smtps',
  1440: 'firewall profile-protocol-options',
  1441: 'firewall profile-protocol-options/http',
  1442: 'firewall profile-protocol-options/https',
  1443: 'firewall profile-protocol-options/ftp',
  1444: 'firewall profile-protocol-options/imap',
  1445: 'firewall profile-protocol-options/pop3',
  1446: 'firewall profile-protocol-options/smtp',
  1447: 'firewall profile-protocol-options/nntp',
  1448: 'firewall profile-protocol-options/im',
  1449: 'firewall profile-protocol-options/mail-signature',
  1450: 'firewall profile-protocol-options/imaps',
  1451: 'firewall profile-protocol-options/pop3s',
  1452: 'firewall profile-protocol-options/smtps',
  1453: 'firewall profile-group',
  1454: 'log eventfilter',
  1455: 'router isis',
  1456: 'router isis/isis-net',
  1457: 'router isis/isis-interface',
  1458: 'router isis/summary-address',
  1459: 'router isis/redistribute',
  1460: 'system amc-slot',
  1461: 'system storage',
  1462: 'report style',
  1463: 'report layout',
  1464: 'report layout/page',
  1465: 'report layout/page/header',
  1466: 'report layout/page/header/header-item',
  1467: 'report layout/page/footer',
  1468: 'report layout/page/footer/footer-item',
  1469: 'report layout/body-item',
  1471: 'webfilter profile/url-extraction',
  1472: 'antivirus mms-checksum',
  1473: 'antivirus mms-checksum/entries',
  1474: 'global header policy',
  1475: 'global header policy/identity-based-policy',
  1476: 'global footer policy',
  1477: 'global footer policy/identity-based-policy',
  1478: 'system dhcp server/ip-range',
  1479: 'wanopt webcache/cache-exemption-list',
  1481: 'report layout/body-item/list',
  1482: 'voip profile',
  1483: 'voip profile/sip',
  1484: 'voip profile/sccp',
  1485: 'netscan assets',
  1486: 'netscan settings',
  1487: 'firewall central-nat',
  1490: 'report theme',
  1491: 'device dynamic interface',
  1492: 'device dynamic address',
  1493: 'device dynamic address6',
  1494: 'dynamic vip',
  1495: 'dynamic ippool',
  1496: 'device dynamic ippool',
  1497: 'device dynamic vip',
  1498: 'device dynamic vip/realservers',
  1499: 'vpnmgr node/summary_addr',
  1500: 'global header policy6',
  1501: 'global header policy6/identity-based-policy6',
  1502: 'global footer policy6',
  1503: 'global footer policy6/identity-based-policy6',
  1504: 'dynamic certificate local',
  1505: 'device dynamic certificate local',
  1506: 'vpnmgr node/ipv4-exclude-range',
  1508: 'vpnmgr node/ip-range',
  1509: 'dynamic vpntunnel',
  1510: 'device dynamic vpntunnel',
  1511: 'device profile settings',
  1512: 'certificate template',
  1514: 'device dynamic ippool6',
  1516: 'device dynamic vip6',
  1518: 'device dynamic vip64',
  1520: 'device dynamic vip46',
  1521: 'device profile fortianalyzer',
  1522: 'device profile fortiguard',
  1523: 'user radius/accounting-server',
  1524: 'system interface/tdm',
  1525: 'system admin/login-time',
  1526: 'web-proxy forward-server-group',
  1527: 'web-proxy forward-server-group/server-list',
  1528: 'system session-sync/filter/custom-service',
  1529: 'system fortisandbox',
  1530: 'firewall ipv6-eh-filter',
  1531: 'system ipv6-neighbor-cache',
  1532: 'wireless-controller wtp-profile/lan',
  1533: 'wireless-controller wtp/lan',
  1535: 'system np6',
  1536: 'system np6/fp-anomaly-v4',
  1537: 'system np6/fp-anomaly-v6',
  1538: 'device dynamic radius',
  1539: 'device profile fortisandbox',
  1540: 'web-proxy profile/headers',
  1541: 'system netflow',
  1542: 'system vdom-netflow',
  1543: 'extender-controller extender',
  1550: 'vpn ssl web user-bookmark',
  1552: 'vpn ssl web user-bookmark/bookmarks/form-data',
  1553: 'user security-exempt-list',
  1554: 'user security-exempt-list/rule',
  1556: 'log gui-display',
  1557: 'system dscp-based-priority',
  1558: 'system virtual-wan-link',
  1559: 'system virtual-wan-link/members',
  1560: 'web-proxy profile',
  1561: 'user pop3',
  1562: 'vpn ssl web user-bookmark/bookmarks',
  1563: 'firewall ssl-ssh-profile',
  1564: 'firewall ssl-ssh-profile/ssl',
  1565: 'firewall ssl-ssh-profile/https',
  1566: 'firewall ssl-ssh-profile/ftps',
  1567: 'firewall ssl-ssh-profile/imaps',
  1568: 'firewall ssl-ssh-profile/pop3s',
  1569: 'firewall ssl-ssh-profile/smtps',
  1570: 'firewall ssl-ssh-profile/ssh',
  1571: 'firewall ssl-ssh-profile/ssl-exempt',
  1572: 'firewall ssl-ssh-profile/ssl-server',
  1573: 'firewall explicit-proxy-policy',
  1574: 'firewall explicit-proxy-policy/identity-based-policy',
  1575: 'vpn ssl web portal/bookmark-group',
  1576: 'vpn ssl web portal/bookmark-group/bookmarks',
  1577: 'vpn ssl web portal/bookmark-group/bookmarks/form-data',
  1578: 'vpn ssl settings/authentication-rule',
  1579: 'router bgp/neighbor/conditional-advertise',
  1580: 'system virtual-wan-link/service',
  1581: 'system link-monitor',
  1582: 'extender-controller extender/_gsm_profile',
  1583: 'extender-controller extender/_cdma_profile',
  1584: 'system custom-language',
  1585: 'firewall gtp/per-apn-shaper',
  1586: 'firewall gtp/message-rate-limit-v0',
  1587: 'firewall gtp/message-rate-limit-v1',
  1588: 'firewall gtp/message-rate-limit-v2',
  1589: 'wireless-controller wtp-profile/split-tunneling-acl',
  1590: 'wireless-controller wtp/split-tunneling-acl',
  1591: 'router bgp/neighbor-group',
  1592: 'router bgp/neighbor-range',
  1593: 'report setting',
  1594: 'firewall address/dynamic_mapping',
  1595: 'firewall addrgrp/dynamic_mapping',
  1596: 'dynamic certificate local/dynamic_mapping',
  1597: 'dynamic vpntunnel/dynamic_mapping',
  1598: 'firewall vip/dynamic_mapping/realservers',
  1599: 'firewall vip/dynamic_mapping/ssl-cipher-suites',
  1600: 'firewall ippool/dynamic_mapping',
  1601: 'firewall address6/dynamic_mapping',
  1602: 'user radius/dynamic_mapping',
  1603: 'firewall ippool6/dynamic_mapping',
  1604: 'firewall vip6/dynamic_mapping',
  1605: 'firewall vip64/dynamic_mapping',
  1606: 'firewall addrgrp6/dynamic_mapping',
  1607: 'firewall vip46/dynamic_mapping',
  1608: 'firewall vip/dynamic_mapping',
  1609: 'system central-management/server-list',
  1610: 'log threat-weight',
  1611: 'log threat-weight/level',
  1612: 'log threat-weight/ips',
  1613: 'log threat-weight/web',
  1614: 'log threat-weight/geolocation',
  1615: 'log threat-weight/application',
  1616: 'system lte-modem',
  1617: 'system mobile-tunnel',
  1618: 'system mobile-tunnel/network',
  1619: 'system dnp3-proxy',
  1620: 'system core-2',
  1621: 'user ldap/dynamic_mapping',
  1622: 'user tacacs+/dynamic_mapping',
  1623: 'log fortiguard override-filter/exclude-list/fields',
  1624: 'log fortiguard override-filter/exclude-list',
  1625: 'wireless-controller wtp-profile/lbs',
  1628: 'endpoint-control profile/forticlient-winmac-settings/extra-buffer-entries',
  1629: 'system geoip-country',
  1630: 'webfilter categories',
  1631: 'dynamic multicast interface',
  1632: 'dynamic multicast interface/dynamic_mapping',
  1633: 'system interface/ipv6/ip6-delegated-prefix-list',
  1634: 'system ha-monitor',
  1635: 'system virtual-wire-pair',
  1636: 'firewall multicast-address6',
  1637: 'vpn ipsec phase1/ipv4-exclude-range',
  1638: 'vpn ipsec phase1/ipv6-exclude-range',
  1639: 'user radius/dynamic_mapping/accounting-server',
  1640: 'firewall shaping-policy',
  1641: 'firewall multicast-policy6',
  1642: 'firewall central-snat-map',
  1643: 'router ripng/network',
  1644: 'system virtual-wan-link/health-check',
  1645: 'system cluster-sync',
  1646: 'system cluster-sync/session-sync-filter',
  1647: 'system cluster-sync/session-sync-filter/custom-service',
  1648: 'system auto-script',
  1649: 'firewall ssl-server',
  1650: 'firewall vipgrp/dynamic_mapping',
  1651: 'user device/dynamic_mapping',
  1652: 'user device-group/dynamic_mapping',
  1653: 'system vm',
  1654: 'system vm/disk',
  1655: 'system vm/interface',
  1656: 'waf main-class',
  1657: 'waf sub-class',
  1658: 'waf signature',
  1659: 'waf profile',
  1660: 'waf profile/signature',
  1661: 'waf profile/signature/main-class',
  1662: 'waf profile/signature/custom-signature',
  1663: 'waf profile/constraint',
  1664: 'waf profile/constraint/header-length',
  1665: 'waf profile/constraint/content-length',
  1666: 'waf profile/constraint/param-length',
  1667: 'waf profile/constraint/line-length',
  1668: 'waf profile/constraint/url-param-length',
  1669: 'waf profile/constraint/version',
  1670: 'waf profile/constraint/method',
  1671: 'waf profile/constraint/hostname',
  1672: 'waf profile/constraint/malformed',
  1673: 'waf profile/constraint/max-cookie',
  1674: 'waf profile/constraint/max-header-line',
  1675: 'waf profile/constraint/max-url-param',
  1676: 'waf profile/constraint/max-range-segment',
  1677: 'waf profile/constraint/exception',
  1678: 'waf profile/method',
  1679: 'waf profile/method/method-policy',
  1680: 'waf profile/address-list',
  1681: 'waf profile/url-access',
  1682: 'waf profile/url-access/access-pattern',
  1683: 'log syslogd4 setting',
  1684: 'log syslogd4 filter',
  1688: 'system virtual wan-link health-profile',
  1689: 'certificate ca',
  1690: 'certificate local',
  1691: 'dnsfilter urlfilter',
  1692: 'dnsfilter urlfilter/entries',
  1693: 'dnsfilter profile',
  1694: 'dnsfilter profile/urlfilter',
  1695: 'dnsfilter profile/ftgd-dns',
  1696: 'dnsfilter profile/ftgd-dns/filters',
  1697: 'system password-policy-guest-admin',
  1698: 'certificate crl',
  1699: 'vpn ssl web user-group-bookmark',
  1700: 'vpn ssl web user-group-bookmark/bookmarks',
  1701: 'vpn ssl web user-group-bookmark/bookmarks/form-data',
  1702: 'endpoint-control profile/forticlient-winmac-settings/av-scan-exclusions',
  1703: 'firewall explicit-proxy-address',
  1704: 'firewall explicit-proxy-address/header-group',
  1705: 'firewall explicit-proxy-addrgrp',
  1706: 'wireless-controller wtp/radio-1',
  1707: 'wireless-controller wtp/radio-2',
  1708: 'user fsso/dynamic_mapping',
  1709: 'wireless-controller vap/vlan-pool',
  1712: 'web-proxy wisp',
  1713: 'firewall acl',
  1714: 'firewall acl6',
  1715: 'wireless-controller wtp-group',
  1717: 'log null-device setting',
  1718: 'log null-device filter',
  1719: 'application internet-service-custom',
  1720: 'application internet-service-custom/entry',
  1721: 'application internet-service-custom/entry/port-range',
  1722: 'application internet-service-custom/disable-entry',
  1723: 'application internet-service-custom/disable-entry/ip-range',
  1724: 'system nst',
  1725: 'application internet-service',
  1726: 'application internet-service/entry',
  1727: 'application casi profile',
  1728: 'application casi profile/entries',
  1729: 'wireless-controller wtp-group/wtp-list',
  1730: 'log syslogd4 filter/exclude-list',
  1731: 'log syslogd4 filter/exclude-list/fields',
  1732: 'log null-device filter/exclude-list',
  1733: 'log null-device filter/exclude-list/fields',
  1734: 'user krb-keytab',
  1735: 'system interface/managed-device',
  1736: 'system dhcp server/options',
  1737: 'endpoint-control profile/forticlient-winmac-settings/forticlient-operating-system',
  1738: 'endpoint-control profile/forticlient-winmac-settings/forticlient-running-app',
  1739: 'endpoint-control profile/forticlient-winmac-settings/forticlient-registry-entry',
  1740: 'endpoint-control profile/forticlient-winmac-settings/forticlient-own-file',
  1741: 'firewall vip/ssl-server-cipher-suites',
  1742: 'gtp apn',
  1743: 'gtp apngrp',
  1744: 'switch-controller custom-command',
  1745: 'switch-controller managed-switch/switch-stp-settings',
  1746: 'switch-controller managed-switch/custom-command',
  1747: 'switch-controller stp-settings',
  1748: 'switch-controller storm-control',
  1749: 'switch-controller global',
  1750: 'switch-controller mac-sync-settings',
  1751: 'switch-controller switch-log',
  1752: 'system csf',
  1753: 'nsx setting',
  1754: 'nsx profile',
  1755: 'firewall address/list',
  1756: 'firewall address/profile-list',
  1757: 'firewall address6/list',
  1758: 'firewall address6/profile-list',
  1759: 'application categories',
  1760: 'switch-controller switch-profile',
  1762: 'application internet-service-custom-group',
  1763: 'application internet-service-group',
  1764: 'application group',
  1765: 'firewall shaping-profile',
  1766: 'firewall shaping-profile/classes',
  1768: 'wanprof settings',
  1769: 'dynamic virtual-wan-link members',
  1770: 'dynamic virtual-wan-link members/dynamic_mapping',
  1771: 'dynamic virtual-wan-link server',
  1772: 'dynamic virtual-wan-link server/dynamic_mapping',
  1773: 'dynamic virtual-wan-link neighbor',
  1774: 'dynamic virtual-wan-link neighbor/dynamic_mapping',
  1775: 'global header shaping-policy',
  1776: 'global footer shaping-policy',
  1777: 'log syslogd setting/custom-field-name',
  1778: 'log syslogd2 setting/custom-field-name',
  1779: 'log syslogd3 setting/custom-field-name',
  1780: 'log syslogd4 setting/custom-field-name',
  1781: 'log syslogd override-setting/custom-field-name',
  1782: 'log azure-security-center setting',
  1783: 'log azure-security-center setting/custom-field-name',
  1784: 'log azure-security-center2 setting',
  1785: 'log azure-security-center2 setting/custom-field-name',
  1786: 'log azure-security-center filter',
  1787: 'log azure-security-center2 filter',
  1792: 'dynamic input interface',
  1793: 'dynamic input interface/dynamic_mapping',
  1800: 'fsp managed-switch',
  1801: 'fsp vlan',
  1803: 'fsp vlan/interface',
  1805: 'fsp vlan/interface/secondaryip',
  1806: 'fsp vlan/interface/ipv6',
  1808: 'fsp vlan/interface/vrrp',
  1809: 'fsp vlan/dynamic_mapping/interface/vrrp',
  1810: 'fsp vlan/dynamic_mapping/interface/vrrp/proxy-arp',
  1811: 'fsp vlan/dhcp-server',
  1813: 'fsp vlan/dhcp-server/exclude-range',
  1814: 'fsp vlan/dhcp-server/ip-range',
  1815: 'fsp vlan/dhcp-server/reserved-address',
  1816: 'fsp vlan/dhcp-server/options',
  1818: 'fsp vlan/dynamic_mapping',
  1819: 'fsp vlan/dynamic_mapping/interface',
  1820: 'fsp vlan/dynamic_mapping/interface/secondaryip',
  1821: 'fsp vlan/dynamic_mapping/dhcp-server',
  1823: 'fsp vlan/dynamic_mapping/dhcp-server/exclude-range',
  1824: 'fsp vlan/dynamic_mapping/dhcp-server/ip-range',
  1825: 'fsp vlan/dynamic_mapping/dhcp-server/reserved-address',
  1826: 'fsp vlan/dynamic_mapping/dhcp-server/options',
  1828: 'central dnat',
  1829: 'firewall internet-service-custom',
  1830: 'firewall internet-service-custom/entry',
  1831: 'firewall internet-service-custom/entry/port-range',
  1832: 'firewall internet-service-custom/disable-entry',
  1833: 'firewall internet-service-custom/disable-entry/ip-range',
  1834: 'system pppoe-interface',
  1835: 'system vxlan',
  1836: 'firewall vip6/realservers',
  1837: 'firewall vip6/ssl-cipher-suites',
  1838: 'firewall vip6/ssl-server-cipher-suites',
  1839: 'firewall vip64/realservers',
  1840: 'firewall vip46/realservers',
  1841: 'firewall proxy-address',
  1842: 'firewall proxy-address/header-group',
  1843: 'firewall proxy-addrgrp',
  1844: 'firewall proxy-policy',
  1845: 'wireless-controller bonjour-profile',
  1846: 'wireless-controller bonjour-profile/policy-list',
  1847: 'switch-controller 802-1X-settings',
  1848: 'switch-controller security-policy 802-1X',
  1849: 'switch-controller security-policy captive-portal',
  1850: 'switch-controller lldp-settings',
  1851: 'switch-controller lldp-profile',
  1852: 'switch-controller lldp-profile/med-network-policy',
  1853: 'switch-controller lldp-profile/custom-tlvs',
  1854: 'wireless-controller qos-profile',
  1855: 'switch-controller managed-switch/stp-settings',
  1856: 'switch-controller managed-switch/switch-log',
  1857: 'switch-controller managed-switch/storm-control',
  1858: 'switch-controller managed-switch/igmp-snooping',
  1859: 'switch-controller managed-switch/802-1X-settings',
  1860: 'switch-controller switch-group',
  1861: 'switch-controller igmp-snooping',
  1867: 'system ftm-push',
  1868: 'system npu/port-npu-map',
  1869: 'system npu/port-cpu-map',
  1870: 'system np6/hpe',
  1871: 'authentication scheme',
  1872: 'authentication rule',
  1873: 'authentication setting',
  1874: 'system alias',
  1875: 'system datasource',
  1876: 'wireless-controller inter-controller',
  1877: 'wireless-controller inter-controller/inter-controller-peer',
  1878: 'wireless-controller vap/mpsk-key',
  1879: 'dnsfilter domain-filter',
  1880: 'dnsfilter domain-filter/entries',
  1881: 'dnsfilter profile/domain-filter',
  1882: 'aws setting',
  1883: 'system admin/gui-dashboard',
  1884: 'system admin/gui-dashboard/widget',
  1885: 'system ha/ha-mgmt-interfaces',
  1886: 'firewall internet-service',
  1887: 'firewall internet-service/entry',
  1888: 'system sdn-connector',
  1891: 'antivirus profile/outbreak-prevention',
  1892: 'system console-server',
  1893: 'system console-server/entries',
  1894: 'router bfd/multihop-template',
  1895: 'load-balance setting',
  1896: 'load-balance setting/workers',
  1897: 'system ha_old',
  1898: 'system ha_old/secondary-vcluster',
  1899: 'load-balance flow-rule',
  1900: 'fapmap setting',
  1901: 'fapmap setting/wtp info',
  1902: 'cli template',
  1903: 'cli template-group',
  1904: 'global header consolidated policy',
  1905: 'global footer consolidated policy',
  1906: 'fwmprof setting',
  1907: 'fwmprof setting/enforced version',
  1908: 'global ips sensor',
  1909: 'global ips sensor/filter',
  1910: 'global ips sensor/override',
  1911: 'global ips sensor/override/exempt-ip',
  1912: 'global ips sensor/entries',
  1913: 'global ips sensor/entries/exempt-ip',
  1914: 'template group setting',
  1920: 'system api-user',
  1921: 'system api-user/trusthost',
  1922: 'gtp message-filter-v0v1',
  1923: 'gtp message-filter-v2',
  1924: 'gtp ie-white-list',
  1925: 'gtp ie-white-list/entries',
  1926: 'gtp apn-shaper',
  1927: 'wireless-controller ble-profile',
  1928: 'switch-controller qos dot1p-map',
  1929: 'switch-controller qos ip-dscp-map',
  1930: 'switch-controller qos ip-dscp-map/map',
  1931: 'switch-controller qos queue-policy',
  1932: 'switch-controller qos queue-policy/cos-queue',
  1933: 'switch-controller qos qos-policy',
  1934: 'switch-controller quarantine',
  1935: 'switch-controller quarantine/targets',
  1936: 'system np6/fp-anomaly',
  1937: 'wanopt forticache-service',
  1938: 'system admin/gui-dashboard/widget/filters',
  1940: 'fsp vlan/dynamic_mapping/interface/ipv6',
  1941: 'fsp vlan/interface/ipv6/ip6-prefix-list',
  1942: 'fsp vlan/interface/ipv6/ip6-extra-addr',
  1943: 'fsp vlan/interface/ipv6/ip6-delegated-prefix-list',
  1944: 'fsp vlan/interface/ipv6/vrrp6',
  1945: 'fsp vlan/dynamic_mapping/interface/ipv6/ip6-prefix-list',
  1946: 'fsp vlan/dynamic_mapping/interface/ipv6/ip6-extra-addr',
  1947: 'fsp vlan/dynamic_mapping/interface/ipv6/ip6-delegated-prefix-list',
  1948: 'fsp vlan/dynamic_mapping/interface/ipv6/vrrp6',
  1949: 'fsp vlan/interface/vrrp/proxy-arp',
  1950: 'wireless-controller hotspot20 anqp-venue-name',
  1951: 'wireless-controller hotspot20 anqp-venue-name/value-list',
  1952: 'wireless-controller hotspot20 anqp-network-auth-type',
  1953: 'wireless-controller hotspot20 anqp-roaming-consortium',
  1954: 'wireless-controller hotspot20 anqp-roaming-consortium/oi-list',
  1955: 'wireless-controller hotspot20 anqp-nai-realm',
  1956: 'wireless-controller hotspot20 anqp-nai-realm/nai-list',
  1957: 'wireless-controller hotspot20 anqp-nai-realm/nai-list/eap-method',
  1958: 'wireless-controller hotspot20 anqp-nai-realm/nai-list/eap-method/auth-param',
  1959: 'wireless-controller hotspot20 anqp-3gpp-cellular',
  1960: 'wireless-controller hotspot20 anqp-3gpp-cellular/mcc-mnc-list',
  1961: 'wireless-controller hotspot20 anqp-ip-address-type',
  1962: 'wireless-controller hotspot20 h2qp-operator-name',
  1963: 'wireless-controller hotspot20 h2qp-operator-name/value-list',
  1964: 'wireless-controller hotspot20 h2qp-wan-metric',
  1965: 'wireless-controller hotspot20 h2qp-conn-capability',
  1966: 'wireless-controller hotspot20 icon',
  1967: 'wireless-controller hotspot20 icon/icon-list',
  1968: 'wireless-controller hotspot20 h2qp-osu-provider',
  1969: 'wireless-controller hotspot20 h2qp-osu-provider/friendly-name',
  1970: 'wireless-controller hotspot20 h2qp-osu-provider/service-description',
  1971: 'wireless-controller hotspot20 qos-map',
  1972: 'wireless-controller hotspot20 qos-map/dscp-except',
  1973: 'wireless-controller hotspot20 qos-map/dscp-range',
  1974: 'wireless-controller hotspot20 hs-profile',
  1975: 'system affinity-interrupt',
  1976: 'system affinity-packet-redistribution',
  1978: 'load-balance hidden-interface',
  1979: 'user pxgrid',
  1980: 'firewall internet-service-addition',
  1981: 'firewall internet-service-addition/entry',
  1982: 'firewall internet-service-addition/entry/port-range',
  1983: 'firewall internet-service-cat-definition',
  1984: 'firewall internet-service-cat-definition/entry',
  1985: 'cifs domain-controller',
  1986: 'cifs profile',
  1987: 'cifs profile/file-filter',
  1988: 'cifs profile/file-filter/entries',
  1989: 'cifs profile/server-keytab',
  1990: 'vpn ocvpn/overlays',
  1991: 'vpn ocvpn/overlays/subnets',
  1992: 'antivirus profile/cifs',
  1994: 'fsp vdom-settings',
  1995: 'fsp vdom-settings/interface-settings',
  2000: 'user domain-controller',
  2001: 'system object-tagging',
  2002: 'user device/tagging',
  2003: 'user device-group/tagging',
  2004: 'system interface/tagging',
  2005: 'system interface/vrrp/proxy-arp',
  2006: 'system interface/ipv6/vrrp6',
  2007: 'system zone/tagging',
  2008: 'firewall address/tagging',
  2009: 'firewall address6-template',
  2010: 'firewall address6-template/subnet-segment',
  2011: 'firewall address6-template/subnet-segment/values',
  2012: 'firewall address6/tagging',
  2013: 'firewall address6/subnet-segment',
  2014: 'firewall addrgrp/tagging',
  2015: 'firewall addrgrp6/tagging',
  2016: 'firewall wildcard-fqdn custom',
  2017: 'firewall wildcard-fqdn group',
  2018: 'system dhcp6 server/prefix-range',
  2019: 'firewall multicast-address/tagging',
  2020: 'firewall multicast-address6/tagging',
  2021: 'webfilter ips-urlfilter-setting6',
  2022: 'system ips-urlfilter-dns6',
  2023: 'user quarantine',
  2024: 'user quarantine/targets',
  2025: 'vpn ssl web portal/split-dns',
  2026: 'firewall internet-service-group',
  2027: 'firewall internet-service-custom-group',
  2028: 'system external-resource',
  2029: 'antivirus profile/content-disarm',
  2030: 'wanopt content-delivery-network-rule',
  2031: 'wanopt content-delivery-network-rule/rules',
  2032: 'wanopt content-delivery-network-rule/rules/match-entries',
  2033: 'wanopt content-delivery-network-rule/rules/skip-entries',
  2034: 'wanopt content-delivery-network-rule/rules/content-id',
  2035: 'wanopt cache-service',
  2036: 'wanopt cache-service/dst-peer',
  2037: 'wanopt cache-service/src-peer',
  2038: 'system virtual-wan-link/health-check/sla',
  2039: 'system virtual-wan-link/service/sla',
  2040: 'firewall proxy-address/tagging',
  2041: 'firewall proxy-addrgrp/tagging',
  2042: 'switch-controller virtual-port-pool',
  2043: 'switch-controller switch-interface-tag',
  2044: 'switch-controller sflow',
  2045: 'switch-controller network-monitor-settings',
  2046: 'router isis/summary-address6',
  2047: 'router isis/redistribute6',
  2048: 'router bfd6',
  2049: 'router bfd6/neighbor',
  2050: 'system nat64/secondary-prefix',
  2051: 'system vdom-exception',
  2052: 'system csf/trusted-list',
  2053: 'system csf/fabric-device',
  2054: 'system automation-trigger',
  2055: 'system automation-action',
  2056: 'system automation-stitch',
  2057: 'system replacemsg-group/icap',
  2058: 'system replacemsg icap',
  2059: 'ssh-filter profile',
  2060: 'ssh-filter profile/shell-commands',
  2061: 'gtp tunnel-limit',
  2062: 'web-proxy explicit/pac-policy',
  2063: 'firewall shaping-profile/shaping-entries',
  2064: 'wireless-controller utm-profile',
  2065: 'switch-controller managed-switch/mirror',
  2066: 'switch-controller system',
  2067: 'firewall ssh local-key',
  2068: 'firewall ssh local-ca',
  2069: 'firewall ssh setting',
  2070: 'firewall ssh host-key',
  2071: 'webfilter profile/youtube-channel-filter',
  2072: 'system accprofile/netgrp-permission',
  2073: 'system accprofile/sysgrp-permission',
  2074: 'wanopt remote-storage',
  2075: 'vpn ocvpn',
  2076: 'vpn ocvpn/subnets',
  2077: 'endpoint-control forticlient-ems',
  2078: 'system sdn-connector/nic',
  2079: 'system sdn-connector/nic/ip',
  2080: 'system sdn-connector/route-table',
  2081: 'system sdn-connector/route-table/route',
  2082: 'system sdn-connector/external-ip',
  2083: 'system sdn-connector/route',
  2084: 'system automation-destination',
  2085: 'system external-resource/dynamic_mapping',
  2087: 'firewall mms-profile/outbreak-prevention',
  2093: 'firewall profile-protocol-options/cifs',
  2100: 'user quarantine/targets/macs',
  2101: 'system nd-proxy',
  2108: 'system speed-test-server',
  2109: 'system speed-test-server/host',
  2110: 'log fortianalyzer-cloud setting',
  2111: 'log fortianalyzer-cloud override-setting',
  2112: 'log fortianalyzer-cloud filter',
  2113: 'log fortianalyzer-cloud override-filter',
  2114: 'system saml',
  2115: 'system saml/service-providers',
  2116: 'system saml/service-providers/assertion-attributes',
  2117: 'system npu/fp-anomaly',
  2130: 'log threat-weight/malware',
  2140: 'wireless-controller vap/dynamic_mapping',
  2160: 'router bgp/neighbor-range6',
  2161: 'monitoring np6-ipsec-engine',
  2190: 'system auto-scale',
  2201: 'system queue-profile',
  2202: 'system interface/out-queues',
  2203: 'firewall internet-service-extension',
  2204: 'firewall internet-service-extension/entry',
  2205: 'firewall internet-service-extension/entry/port-range',
  2206: 'firewall internet-service-extension/disable-entry',
  2207: 'firewall internet-service-extension/disable-entry/ip-range',
  2208: 'firewall internet-service-definition',
  2209: 'firewall internet-service-definition/entry',
  2210: 'system ipsec-aggregate',
  2213: 'firewall consolidated policy',
  2214: 'switch-controller auto-config policy',
  2215: 'switch-controller auto-config default',
  2216: 'switch-controller auto-config custom',
  2217: 'switch-controller auto-config custom/switch-binding',
  2218: 'switch-controller managed-switch/static-mac',
  2219: 'system ptp',
  2220: 'system admin/gui-dashboard/widget/fortiview-filters',
  2221: 'system npu/sw-np-in-queues',
  2222: 'user domain-controller/extra-server',
  2223: 'switch-controller traffic-policy',
  2224: 'icap profile/icap-headers',
  2225: 'wireless-controller setting/offending-ssid',
  2227: 'system virtual-wan-link/neighbor',
  2228: 'switch-controller managed-switch/snmp-sysinfo',
  2229: 'switch-controller managed-switch/snmp-trap-threshold',
  2230: 'switch-controller managed-switch/snmp-community',
  2231: 'switch-controller managed-switch/snmp-community/hosts',
  2232: 'switch-controller managed-switch/snmp-user',
  2233: 'switch-controller managed-switch/remote-log',
  2234: 'switch-controller snmp-sysinfo',
  2235: 'switch-controller snmp-trap-threshold',
  2236: 'switch-controller snmp-community',
  2237: 'switch-controller snmp-community/hosts',
  2238: 'switch-controller snmp-user',
  2239: 'switch-controller remote-log',
  2240: 'router ospf6/area/virtual-link/ipsec-keys',
  2241: 'router ospf6/area/ipsec-keys',
  2242: 'router ospf6/ospf6-interface/ipsec-keys',
  2250: 'system np6xlite',
  2251: 'system lldp network-policy',
  2252: 'system lldp network-policy/voice',
  2253: 'system lldp network-policy/voice-signaling',
  2254: 'system lldp network-policy/guest',
  2255: 'system lldp network-policy/guest-voice-signaling',
  2256: 'system lldp network-policy/softphone',
  2257: 'system lldp network-policy/video-conferencing',
  2258: 'system lldp network-policy/streaming-video',
  2259: 'system lldp network-policy/video-signaling',
  2260: 'wireless-controller region',
  2261: 'switch-controller location',
  2262: 'switch-controller location/address-civic',
  2263: 'switch-controller location/coordinates',
  2264: 'switch-controller location/elin-number',
  2265: 'switch-controller lldp-profile/med-location-service',
  2266: 'switch-controller global/custom-command',
  2267: 'log syslogd2 override-setting',
  2268: 'log syslogd2 override-setting/custom-field-name',
  2269: 'log syslogd3 override-setting',
  2270: 'log syslogd3 override-setting/custom-field-name',
  2271: 'log syslogd4 override-setting',
  2272: 'log syslogd4 override-setting/custom-field-name',
  2273: 'log syslogd2 override-filter',
  2274: 'log syslogd3 override-filter',
  2275: 'log syslogd4 override-filter',
  2276: 'log fortianalyzer2 override-setting',
  2277: 'log fortianalyzer3 override-setting',
  2278: 'log fortianalyzer2 override-filter',
  2279: 'log fortianalyzer3 override-filter',
  2280: 'system np6xlite/hpe',
  2281: 'system np6xlite/fp-anomaly',
  2282: 'system npu/sw-eh-hash',
  2300: 'webfilter profile/file-filter',
  2301: 'dlp sensitivity',
  2302: 'emailfilter bword',
  2303: 'emailfilter bword/entries',
  2304: 'emailfilter bwl',
  2305: 'emailfilter bwl/entries',
  2306: 'emailfilter mheader',
  2307: 'emailfilter mheader/entries',
  2308: 'emailfilter dnsbl',
  2309: 'emailfilter dnsbl/entries',
  2310: 'emailfilter iptrust',
  2311: 'emailfilter iptrust/entries',
  2312: 'emailfilter profile',
  2313: 'emailfilter profile/file-filter',
  2314: 'emailfilter profile/imap',
  2315: 'emailfilter profile/pop3',
  2316: 'emailfilter profile/smtp',
  2317: 'emailfilter profile/mapi',
  2318: 'emailfilter profile/msn-hotmail',
  2319: 'emailfilter profile/yahoo-mail',
  2320: 'emailfilter profile/gmail',
  2321: 'antivirus filetype',
  2322: 'switch-controller security-policy local-access',
  2323: 'switch-controller flow-tracking',
  2324: 'switch-controller flow-tracking/aggregates',
  2325: 'emailfilter fortishield',
  2326: 'emailfilter options',
  2350: 'switch-controller managed-switch/stp-instance',
  2351: 'switch-controller stp-instance',
  2380: 'user exchange',
  2381: 'webfilter profile/file-filter/entries',
  2382: 'emailfilter profile/file-filter/entries',
  2383: 'wireless-controller address',
  2384: 'wireless-controller addrgrp',
  2385: 'router ospf/area/virtual-link/md5-keys',
  2386: 'router ospf/ospf-interface/md5-keys',
  2387: 'system automation-trigger/fields',
  2410: 'firewall internet-service-extension/disable-entry/port-range',
  2411: 'firewall internet-service-definition/entry/port-range',
  2412: 'wireless-controller log',
  2413: 'switch-controller traffic-sniffer',
  2414: 'switch-controller traffic-sniffer/target-mac',
  2415: 'switch-controller traffic-sniffer/target-ip',
  2416: 'switch-controller traffic-sniffer/target-port',
  2440: 'system isf-queue-profile',
  2441: 'system interface/egress-queues',
  2442: 'application list/default-network-services',
  2443: 'endpoint-control fctems',
  2444: 'switch-controller storm-control-policy',
  2445: 'wireless-controller snmp',
  2446: 'wireless-controller snmp/community',
  2447: 'wireless-controller snmp/community/hosts',
  2448: 'wireless-controller snmp/user',
  2449: 'system npu/isf-np-queues',
  2470: 'system iscsi',
  2500: 'dnsfilter profile/dns-translation',
  2501: 'system remote-admin',
  2502: 'system sso-admin',
  2503: 'system sso-admin/gui-dashboard',
  2504: 'system sso-admin/gui-dashboard/widget',
  2505: 'system sso-admin/gui-dashboard/widget/fortiview-filters',
  2506: 'firewall address6/dynamic_mapping/subnet-segment',
  2507: 'user clearpass',
  2508: 'user nsx',
  2509: 'user flexvm',
  2510: 'ums setting',
  2530: 'firewall gtp/policy-v2',
  2560: 'firewall security-policy',
  2590: 'user saml',
  2591: 'certificate remote',
  2592: 'ssh-filter profile/file-filter',
  2593: 'ssh-filter profile/file-filter/entries',
  2594: 'antivirus profile/ssh',
  2595: 'icap profile/respmod-forward-rules',
  2596: 'firewall traffic-class',
  2597: 'wireless-controller wag-profile',
  2598: 'icap profile/respmod-forward-rules/header-group',
  2620: 'wireless-controller wtp-profile/radio-3',
  2621: 'wireless-controller wtp/radio-3',
  2622: 'user vcenter',
  2623: 'user vcenter/rule',
  2624: 'user nsx/service',
  2650: 'system geneve',
  2651: 'system smc-ntp',
  2652: 'system smc-ntp/ntpserver',
  2653: 'system npu/priority-protocol',
  2680: 'dpdk global',
  2681: 'dpdk cpus',
  2710: 'wireless-controller wtp-profile/radio-4',
  2711: 'wireless-controller wtp/radio-4',
  2712: 'wireless-controller access-control-list',
  2713: 'wireless-controller access-control-list/layer3-ipv4-rules',
  2714: 'wireless-controller access-control-list/layer3-ipv6-rules',
  2715: 'firewall internet-service-append',
  2750: 'system interface/client-options',
  2751: 'webfilter profile/antiphish',
  2752: 'webfilter profile/antiphish/inspection-entries',
  2753: 'webfilter profile/antiphish/custom-patterns',
  2756: 'switch-controller managed-switch/ip-source-guard',
  2757: 'switch-controller managed-switch/ip-source-guard/binding-entry',
  2758: 'system geoip-override/ip6-range',
  2759: 'credential-store domain-controller',
  2760: 'firewall vendor-mac',
  2761: 'switch-controller vlan-policy',
  2762: 'switch-controller device-port-policy',
  2763: 'switch-controller lldp-identity',
  2764: 'switch-controller lldp-device',
  2765: 'router bgp/vrf-leak',
  2766: 'router bgp/vrf-leak/target',
  2767: 'wireless-controller apcfg-profile',
  2768: 'wireless-controller apcfg-profile/command-list',
  2790: 'firewall internet-service-name',
  2791: 'switch-controller port-policy',
  2792: 'switch-controller mac-policy',
  2793: 'switch-controller nac-settings',
  2794: 'user nac-policy',
  2795: 'switch-controller nac-device',
  2796: 'system standalone-cluster',
  2820: 'firewall decrypted-traffic-mirror',
  2821: 'switch-controller initial-config template',
  2822: 'switch-controller initial-config vlans',
  2850: 'application list/entries/parameters/members',
  2851: 'vpn ocvpn/forticlient-access',
  2852: 'vpn ocvpn/forticlient-access/auth-groups',
  2880: 'system npu/hpe',
  2881: 'system npu/ip-reassembly',
  2882: 'system npu-vlink',
  2883: 'system ha/vcluster',
  2910: 'file-filter profile',
  2911: 'file-filter profile/rules',
  2912: 'nsxt setting',
  2913: 'nsxt service-chain',
  2914: 'nsxt service-chain/service-index',
  2920: 'system vne-tunnel',
  2921: 'system sdwan',
  2922: 'system sdwan/zone',
  2923: 'system sdwan/members',
  2924: 'system sdwan/service',
  2925: 'system sdwan/service/sla',
  2926: 'system sdwan/health-check',
  2927: 'system sdwan/health-check/sla',
  2928: 'system sdwan/neighbor',
  2929: 'extender-controller dataplan',
  2930: 'extender-controller extender/controller-report',
  2931: 'extender-controller extender/modem1',
  2932: 'extender-controller extender/modem1/auto-switch',
  2933: 'extender-controller extender/modem2',
  2934: 'extender-controller extender/modem2/auto-switch',
  2935: 'emailfilter profile/other-webmails',
  2936: 'firewall profile-protocol-options/cifs/file-filter',
  2937: 'firewall profile-protocol-options/cifs/file-filter/entries',
  2938: 'firewall profile-protocol-options/cifs/server-keytab',
  2939: 'wireless-controller arrp-profile',
  2940: 'switch-controller ptp settings',
  2941: 'switch-controller ptp policy',
  2942: 'central dnat6',
  2943: 'system sdwan/duplication',
  2944: 'system ips',
  2945: 'wireless-controller mpsk-profile',
  2946: 'wireless-controller mpsk-profile/mpsk-group',
  2947: 'wireless-controller mpsk-profile/mpsk-group/mpsk-key',
  2948: 'firewall ippool_grp',
  2949: 'log npu-server',
  2950: 'log npu-server/server-info',
  2951: 'log npu-server/server-group',
  2952: 'system npu/dos-options',
  2953: 'system npu/port-path-option',
  2954: 'extender-controller sim_profile',
  2955: 'extender-controller sim_profile/auto-switch_profile',
  2956: 'extender-controller extender/modem1/_cdma_profile',
  2957: 'extender-controller extender/modem1/_gsm_profile',
  2958: 'extender-controller extender/modem1/_sim1',
  2959: 'extender-controller extender/modem1/_sim2',
  2960: 'extender-controller extender/modem2/_cdma_profile',
  2961: 'extender-controller extender/modem2/_gsm_profile',
  2962: 'extender-controller extender/modem2/_sim1',
  2963: 'extender-controller extender/modem2/_sim2',
  2964: 'extender-controller template',
  2965: 'ips global/tls-active-probe',
  2966: 'system npu/dsw-dts-profile',
  2967: 'system npu/dsw-queue-dts-profile',
  2968: 'system npu/np-queues',
  2969: 'system npu/np-queues/profile',
  2970: 'system npu/np-queues/ethernet-type',
  2971: 'system npu/np-queues/ip-protocol',
  2972: 'system npu/np-queues/ip-service',
  2973: 'system npu/np-queues/scheduler',
  2974: 'router bgp/vrf',
  2975: 'router bgp/vrf/leak-target',
  2976: 'router bgp/vrf6',
  2977: 'router bgp/vrf6/leak-target',
  2978: 'system replacemsg-group/automation',
  2979: 'videofilter youtube-key',
  2980: 'videofilter youtube-channel-filter',
  2981: 'videofilter youtube-channel-filter/entries',
  2982: 'videofilter profile',
  2983: 'videofilter profile/fortiguard-category',
  2984: 'videofilter profile/fortiguard-category/filters',
  2985: 'system replacemsg automation',
  2986: 'emailfilter block-allow-list',
  2987: 'emailfilter block-allow-list/entries',
  2988: 'vpn ssl client',
  2989: 'gtp ie-allow-list',
  2990: 'gtp ie-allow-list/entries',
  2991: 'log syslogd filter/free-style',
  2992: 'log syslogd2 filter/free-style',
  2993: 'log syslogd3 filter/free-style',
  2994: 'log syslogd4 filter/free-style',
  2995: 'log syslogd override-filter/free-style',
  2996: 'log syslogd2 override-filter/free-style',
  2997: 'log syslogd3 override-filter/free-style',
  2998: 'log syslogd4 override-filter/free-style',
  2999: 'log webtrends filter/free-style',
  3000: 'log memory filter/free-style',
  3001: 'log disk filter/free-style',
  3002: 'log fortiguard filter/free-style',
  3003: 'log fortiguard override-filter/free-style',
  3004: 'log null-device filter/free-style',
  3005: 'log fortianalyzer filter/free-style',
  3006: 'log fortianalyzer2 filter/free-style',
  3007: 'log fortianalyzer3 filter/free-style',
  3008: 'log fortianalyzer-cloud filter/free-style',
  3009: 'log fortianalyzer override-filter/free-style',
  3010: 'log fortianalyzer2 override-filter/free-style',
  3011: 'log fortianalyzer3 override-filter/free-style',
  3012: 'log fortianalyzer-cloud override-filter/free-style',
  3013: 'system federated-upgrade',
  3014: 'system federated-upgrade/node-list',
  3015: 'system ike',
  3016: 'system ike/dh-group-1',
  3017: 'system ike/dh-group-2',
  3018: 'system ike/dh-group-5',
  3019: 'system ike/dh-group-14',
  3020: 'system ike/dh-group-15',
  3021: 'system ike/dh-group-16',
  3022: 'system ike/dh-group-17',
  3023: 'system ike/dh-group-18',
  3024: 'system ike/dh-group-19',
  3025: 'system ike/dh-group-20',
  3026: 'system ike/dh-group-21',
  3027: 'system ike/dh-group-27',
  3028: 'system ike/dh-group-28',
  3029: 'system ike/dh-group-29',
  3030: 'system ike/dh-group-30',
  3031: 'system ike/dh-group-31',
  3032: 'system ike/dh-group-32',
  3033: 'system speed-test-schedule',
  3034: 'system sso-forticloud-admin',
  3035: 'system sso-forticloud-admin/gui-dashboard',
  3036: 'system sso-forticloud-admin/gui-dashboard/widget',
  3037: 'system sso-forticloud-admin/gui-dashboard/widget/fortiview-filters',
  3038: 'log azure-security-center filter/free-style',
  3039: 'log azure-security-center2 filter/free-style',
  3040: 'firewall profile-protocol-options/rtmp',
  3041: 'certificate setting',
  3042: 'web-proxy pac-policy',
  3043: 'web-proxy isolator-server',
  3044: 'image-analyzer profile',
  3045: 'icap remote-server',
  3046: 'icap local-server',
  3047: 'icap local-server/icap-service',
  3048: 'wanopt reverse-cache-prefetch',
  3049: 'wanopt reverse-cache-server',
  3050: 'webcache settings',
  3051: 'webcache user-agent',
  3052: 'webcache reverse-cache-prefetch-url',
  3053: 'webcache reverse-cache-server',
  3054: 'firewall ssl-ssh-profile/dot',
  3055: 'system ha/unicast-peers',
  3056: 'wireless-controller nac-profile',
  3057: 'wireless-controller ssid-policy',
  3058: 'monitoring npu-hpe',
  3059: 'switch-controller fortilink-settings',
  3060: 'switch-controller fortilink-settings/nac-ports',
  3061: 'system npu-setting prp',
  3062: 'switch-controller dynamic-port-policy',
  3063: 'switch-controller dynamic-port-policy/policy',
  3064: 'system acme',
  3065: 'system acme/accounts',
  3066: 'firewall access-proxy-virtual-host',
  3067: 'firewall access-proxy',
  3068: 'firewall access-proxy/api-gateway',
  3069: 'firewall access-proxy/api-gateway/realservers',
  3070: 'firewall access-proxy/api-gateway/ssl-cipher-suites',
  3071: 'firewall access-proxy/server-pubkey-auth-settings',
  3072: 'firewall access-proxy/server-pubkey-auth-settings/cert-extension',
  3073: 'firewall access-proxy/realservers',
  3074: 'system csf/fabric-connector',
  3075: 'user connector',
  3076: 'system npu/tcp-timeout-profile',
  3077: 'system npu/udp-timeout-profile',
  3078: 'firewall hyperscale-policy',
  3079: 'firewall hyperscale-policy6',
  3080: 'firewall hyperscale-policy64',
  3081: 'firewall hyperscale-policy46',
  3082: 'system dns64',
  3083: 'system span-port',
  3084: 'log slbc global-setting',
  3085: 'router bgp/neighbor/conditional-advertise6',
  3086: 'router bfd6/multihop-template',
  3087: 'system interface/dhcp-snooping-server-list',
  3088: 'sctp-filter profile',
  3089: 'sctp-filter profile/ppid-filters',
  3090: 'vpn certificate setting/crl-verification',
  3091: 'user certificate',
  3092: 'gtp rat-timeout-profile',
  3093: 'pfcp message-filter',
  3094: 'firewall pfcp',
  3095: 'wireless-controller wtp-profile/esl-ses-dongle',
  3096: 'router bgp/vrf-leak6',
  3097: 'router bgp/vrf-leak6/target',
  3098: 'system fortiai',
  3099: 'system link-monitor/server-list',
  3100: 'system ptp/server-interface',
  3101: 'firewall access-proxy-ssh-client-cert',
  3102: 'firewall access-proxy-ssh-client-cert/cert-extension',
  3103: 'firewall access-proxy/api-gateway6',
  3104: 'firewall access-proxy/api-gateway6/realservers',
  3105: 'firewall access-proxy/api-gateway6/ssl-cipher-suites',
  3106: 'firewall access-proxy6',
  3107: 'firewall access-proxy6/api-gateway',
  3108: 'firewall access-proxy6/api-gateway/realservers',
  3109: 'firewall access-proxy6/api-gateway/ssl-cipher-suites',
  3110: 'firewall access-proxy6/api-gateway6',
  3111: 'firewall access-proxy6/api-gateway6/realservers',
  3112: 'firewall access-proxy6/api-gateway6/ssl-cipher-suites',
  3113: 'system automation-stitch/actions',
  3114: 'dynamic lte-modem',
  3115: 'dynamic lte-modem/lte-status',
  3116: 'user group/dynamic_mapping',
  3117: 'user group/dynamic_mapping/sslvpn-os-check-list',
  3118: 'user group/dynamic_mapping/match',
  3119: 'user group/dynamic_mapping/guest',
  3120: 'system sdn-connector/gcp-project-list',
  3121: 'system interface/ipv6/dhcp6-iapd-list',
  3122: 'system sdn-connector/forwarding-rule',
  3123: 'extender-controller extender-profile',
  3124: 'extender-controller extender-profile/cellular',
  3125: 'extender-controller extender-profile/cellular/controller-report',
  3126: 'extender-controller extender-profile/cellular/sms-notification',
  3127: 'extender-controller extender-profile/cellular/sms-notification/alert',
  3128: 'extender-controller extender-profile/cellular/sms-notification/receiver',
  3129: 'extender-controller extender-profile/cellular/modem1',
  3130: 'extender-controller extender-profile/cellular/modem1/auto-switch',
  3131: 'extender-controller extender-profile/cellular/modem2',
  3132: 'extender-controller extender-profile/cellular/modem2/auto-switch',
  3133: 'extender-controller extender-profile/lan-extension',
  3134: 'extender-controller extender-profile/lan-extension/backhaul',
  3135: 'extender-controller extender/wan-extension',
  3136: 'voip profile/msrp',
  3137: 'wireless-controller syslog-profile',
  3138: 'switch-controller managed-switch/igmp-snooping/vlans',
  3139: 'log tacacs+accounting setting',
  3140: 'log tacacs+accounting2 setting',
  3141: 'log tacacs+accounting3 setting',
  3142: 'log tacacs+accounting filter',
  3143: 'log tacacs+accounting2 filter',
  3144: 'log tacacs+accounting3 filter',
  3145: 'firewall vip6/dynamic_mapping/realservers',
  3146: 'firewall vip6/dynamic_mapping/ssl-cipher-suites',
  3147: 'vpn ipsec fec/mappings',
  3148: 'wireless-controller hotspot20 anqp-venue-url',
  3149: 'wireless-controller hotspot20 anqp-venue-url/value-list',
  3150: 'wireless-controller hotspot20 h2qp-advice-of-charge',
  3151: 'wireless-controller hotspot20 h2qp-advice-of-charge/aoc-list',
  3152: 'wireless-controller hotspot20 h2qp-advice-of-charge/aoc-list/plan-info',
  3153: 'wireless-controller hotspot20 h2qp-osu-provider-nai',
  3154: 'wireless-controller hotspot20 h2qp-osu-provider-nai/nai-list',
  3155: 'wireless-controller hotspot20 h2qp-terms-and-conditions',
  3157: 'system ipam',
  3158: 'emailfilter fortiguard',
  3159: 'vpn ipsec fec',
  3160: 'switch-controller dsl policy',
  3161: 'log tap-device',
  3162: 'log tap-device/filter',
  3163: 'log tap-device/filter/free-style',
  3164: 'wireless-controller vap/vlan-name',
  3165: 'system sdn-connector/external-account-list',
  3166: 'aws vpce',
  3170: 'system npu/background-sse-scan',
  3171: 'system lte-modem/data-plan',
  3172: 'system fortindr',
  3173: 'system replacemsg custom-message',
  3200: 'fmg variable',
  3201: 'fmg variable/dynamic_mapping',
  3202: 'icap server-group',
  3203: 'icap server-group/server-list',
  3204: 'switch-controller flow-tracking/collectors',
  3205: 'automation setting',
  3206: 'system snmp mib-view',
  3207: 'dlp data-type',
  3208: 'dlp dictionary',
  3209: 'dlp dictionary/entries',
  3210: 'dlp profile',
  3211: 'dlp profile/rule',
  3212: 'dlp sensor/entries',
  3213: 'system npu/sse-ha-scan',
  3214: 'system automation-action/http-headers',
  3215: 'extension-controller dataplan',
  3216: 'extension-controller extender-profile',
  3217: 'extension-controller extender-profile/cellular',
  3218: 'extension-controller extender-profile/cellular/controller-report',
  3219: 'extension-controller extender-profile/cellular/sms-notification',
  3220: 'extension-controller extender-profile/cellular/sms-notification/alert',
  3221: 'extension-controller extender-profile/cellular/sms-notification/receiver',
  3222: 'extension-controller extender-profile/cellular/modem1',
  3223: 'extension-controller extender-profile/cellular/modem1/auto-switch',
  3224: 'extension-controller extender-profile/cellular/modem2',
  3225: 'extension-controller extender-profile/cellular/modem2/auto-switch',
  3226: 'extension-controller extender-profile/lan-extension',
  3227: 'extension-controller extender-profile/lan-extension/backhaul',
  3228: 'extension-controller extender',
  3229: 'extension-controller extender/wan-extension',
  3230: 'extension-controller fortigate-profile',
  3231: 'extension-controller fortigate-profile/lan-extension',
  3232: 'extension-controller fortigate',
  3233: 'firewall internet-service-extension/disable-entry/ip6-range',
  3234: 'system ipam/pools',
  3235: 'system ipam/rules',
  3236: 'firewall global',
  3237: 'system standalone-cluster/cluster-peer',
  3238: 'system standalone-cluster/cluster-peer/session-sync-filter',
  3239: 'system standalone-cluster/cluster-peer/session-sync-filter/custom-service',
  3240: 'firewall network-service-dynamic',
  3241: 'ems-tag datasource',
  3242: 'system global/split-port-mode',
  3243: 'system sso-fortigate-cloud-admin',
  3244: 'system sso-fortigate-cloud-admin/gui-dashboard',
  3245: 'system sso-fortigate-cloud-admin/gui-dashboard/widget',
  3246: 'system sso-fortigate-cloud-admin/gui-dashboard/widget/fortiview-filters',
  3247: 'antivirus exempt-list',
  3248: 'switch-controller managed-switch/dhcp-snooping-static-client',
  3249: 'system device-upgrade',
  3250: 'system fabric-vpn',
  3251: 'system fabric-vpn/overlays',
  3252: 'system fabric-vpn/advertised-subnets',
  3253: 'router extcommunity-list',
  3254: 'router extcommunity-list/rule',
  3255: 'system vin-alarm',
  3256: 'vpn ssl web portal/landing-page',
  3257: 'vpn ssl web portal/landing-page/form-data',
  3258: 'system evpn',
  3259: 'vpn kmip-server',
  3260: 'vpn kmip-server/server-list',
  3261: 'system pcp-server',
  3262: 'system pcp-server/pools',
  3263: 'switch-controller managed-switch/ports/dhcp-snoop-option82-override',
  3264: 'system lte-modem/sim-switch',
  3265: 'system vpce',
  3266: 'user quarantine/targets/macs/msw',
  3267: 'switch-controller acl ingress',
  3268: 'switch-controller acl ingress/action',
  3269: 'switch-controller acl ingress/classifier',
  3270: 'switch-controller acl group',
  3271: 'switch-controller traffic-sniffer/target-mac/msw',
  3272: 'switch-controller traffic-sniffer/target-ip/msw',
  3273: 'system sdn-proxy',
  3274: 'system sdn-connector/compartment-list',
  3275: 'system sdn-connector/oci-region-list',
  3276: 'endpoint-control fctems-override',
  3277: 'system npu/sw-tr-hash',
  3278: 'virtual-patch profile',
  3279: 'virtual-patch profile/exemption',
  3280: 'web-proxy fast-fallback',
  3281: 'rule otvp',
  3282: 'switch-controller managed-switch/route-offload-router',
  3283: 'rule otdt',
  3284: 'firewall vip/quic',
  3285: 'casb saas-application',
  3286: 'casb user-activity',
  3287: 'casb user-activity/match',
  3288: 'casb user-activity/match/rules',
  3289: 'casb user-activity/control-options',
  3290: 'casb user-activity/control-options/operations',
  3291: 'firewall casb-profile',
  3292: 'firewall casb-profile/saas-application',
  3293: 'firewall casb-profile/saas-application/access-rule',
  3294: 'firewall casb-profile/saas-application/custom-control',
  3295: 'firewall casb-profile/saas-application/custom-control/option',
  3296: 'switch-controller ptp profile',
  3297: 'switch-controller ptp interface-policy',
  3298: 'ethernet-oam cfm',
  3299: 'ethernet-oam cfm/service',
  3300: 'firewall access-proxy/api-gateway/quic',
  3301: 'firewall access-proxy/api-gateway6/quic',
  3302: 'firewall access-proxy6/api-gateway/quic',
  3303: 'firewall access-proxy6/api-gateway6/quic',
  3304: 'system speed-test-setting',
  3305: 'casb profile',
  3306: 'casb profile/saas-application',
  3307: 'casb profile/saas-application/access-rule',
  3308: 'casb profile/saas-application/custom-control',
  3309: 'casb profile/saas-application/custom-control/option',
  3310: 'system netflow/exclusion-filters',
  3311: 'system timezone',
  3312: 'system interface/mirroring-filter',
  3313: 'system sflow/collectors',
  3314: 'system vdom-sflow/collectors',
  3315: 'diameter-filter profile',
  3316: 'vpn qkd',
  3317: 'firewall vip/gslb-public-ips',
  3318: 'firewall vip6/quic',
  3319: 'rule fmwp',
  3320: 'system federated-upgrade/known-ha-members',
  3321: 'system device-upgrade/known-ha-members',
  3322: 'system npu/npu-tcam',
  3323: 'system npu/npu-tcam/data',
  3324: 'system npu/npu-tcam/mask',
  3325: 'system npu/npu-tcam/mir-act',
  3326: 'system npu/npu-tcam/pri-act',
  3327: 'system npu/npu-tcam/sact',
  3328: 'system npu/npu-tcam/tact',
  3329: 'system netflow/collectors',
  3330: 'system vdom-netflow/collectors',
  3331: 'system digital-io',
  3332: 'user external-identity-provider',
  3333: 'videofilter keyword',
  3334: 'videofilter keyword/word',
  3335: 'videofilter profile/filters',
  3336: 'dlp exact-data-match',
  3337: 'dlp exact-data-match/columns',
  3338: 'switch-controller managed-switch/vlan',
  3339: 'system 5g-modem',
  3340: 'system 5g-modem/data-plan',
  3341: 'system 5g-modem/modem1',
  3342: 'system 5g-modem/modem1/sim-switch',
  3343: 'system 5g-modem/modem2',
  3346: 'firewall ssl-ssh-profile/ech-outer-sni',
  3347: 'extension-controller extender-vap',
  3348: 'extension-controller extender-profile/wifi',
  3349: 'extension-controller extender-profile/wifi/radio-1',
  3350: 'extension-controller extender-profile/wifi/radio-2',
  3351: 'firewall on-demand-sniffer',
  3352: 'system ipam/pools/exclude',
  3353: 'azure nva',
  3354: 'azure vwan-slb',
  3355: 'azure vwan-slb/permanent-security-rules',
  3356: 'azure vwan-slb/permanent-security-rules/rules',
  3357: 'azure vwan-slb/temporary-security-rules',
  3358: 'azure vwan-slb/temporary-security-rules/rules',
  3359: 'system ssh-config',
  3360: 'system icond',
  3361: 'system npu-post',
  3362: 'system npu-post/port-npu-map',
  3363: 'system npu/icmp-rate-ctrl',
  3364: 'user local/pwd-history',
  3365: 'system interface/ipv6/client-options',
  3366: 'system admin/pwd-history',
  3367: 'system snmp rmon-stat',
  3368: 'system dhcp6 server/options',
  3369: 'system sdwan/health-check-fortiguard',
  3370: 'system sdwan/health-check-fortiguard/sla',
  3371: 'user scim',
  3372: 'extension-controller extender-profile/lan-extension/downlinks',
  3373: 'ztna traffic-forward-proxy',
  3374: 'ztna traffic-forward-proxy/quic',
  3375: 'ztna traffic-forward-proxy/ssl-cipher-suites',
  3376: 'ztna traffic-forward-proxy/ssl-server-cipher-suites',
  3377: 'ztna traffic-forward-proxy-reverse-service',
  3378: 'ztna traffic-forward-proxy-reverse-service/remote-servers',
  3379: 'azure vwan-ingress-public-IPs',
  3380: 'system npu/icmp-error-rate-ctrl',
  3381: 'system vne-interface',
  3382: 'router multicast/pim-sm-global-vrf',
  3383: 'router multicast/pim-sm-global-vrf/rp-address',
  3386: 'system interface/ipv6/ip6-route-list',
  3387: 'system interface/ipv6/ip6-rdnss-list',
  3388: 'system interface/ipv6/ip6-dnssl-list',
  3389: 'extension-controller extender-profile/lan-extension/traffic-split-services',
  3390: 'ztna reverse-connector',
  3391: 'ztna web-proxy',
  3392: 'ztna web-proxy/api-gateway',
  3393: 'ztna web-proxy/api-gateway/quic',
  3394: 'ztna web-proxy/api-gateway/realservers',
  3395: 'ztna web-proxy/api-gateway/ssl-cipher-suites',
  3396: 'ztna web-proxy/api-gateway6',
  3397: 'ztna web-proxy/api-gateway6/quic',
  3398: 'ztna web-proxy/api-gateway6/realservers',
  3399: 'ztna web-proxy/api-gateway6/ssl-cipher-suites',
  3400: 'ztna web-portal',
  3401: 'ztna web-portal-bookmark',
  3402: 'ztna web-portal-bookmark/bookmarks',
  3403: 'system ngfw-settings',
  3404: 'system security-rating settings',
  3405: 'system security-rating controls',
  3406: 'system automation-condition',
  3407: 'system standalone-cluster/monitor-prefix',
  3408: 'load-balance worker-group',
  3409: 'system sdn-vpn',
  3410: 'system health-check-fortiguard',
  3411: 'casb saas-application/output-attributes',
  3412: 'casb saas-application/input-attributes',
  3413: 'casb user-activity/match/tenant-extraction',
  3414: 'casb user-activity/match/tenant-extraction/filters',
  3415: 'casb attribute-match',
  3416: 'casb attribute-match/attribute',
  3417: 'casb profile/saas-application/access-rule/attribute-filter',
  3418: 'casb profile/saas-application/custom-control/attribute-filter',
  3419: 'casb profile/saas-application/advanced-tenant-control',
  3420: 'casb profile/saas-application/advanced-tenant-control/attribute',
  4010: 'web-proxy dynamic-bypass',
  4011: 'system nethsm',
  4012: 'system nethsm/servers',
  4013: 'system nethsm/slots',
  4014: 'system nethsm/hagroups',
  4015: 'web-proxy url-list',
  4016: 'web-proxy url-list/entries',
  4017: 'icap remote-server-group',
  4018: 'icap remote-server-group/server-list',
  4019: 'firewall ssl keyring-list',
  4020: 'firewall ssl-ssh-profile/ssl-client-certificate',
  4021: 'web-proxy explicit-proxy',
  4022: 'user saml/dynamic_mapping',
  4023: 'user json',
  4024: 'extension-controller extender/_modem1',
  4025: 'extension-controller extender/_modem1/_cdma_profile',
  4026: 'extension-controller extender/_modem1/_gsm_profile',
  4027: 'extension-controller extender/_modem1/_sim1',
  4028: 'extension-controller extender/_modem1/_sim2',
  4029: 'extension-controller extender/_modem1/_auto-switch',
  4030: 'extension-controller extender/_modem2',
  4031: 'extension-controller extender/_modem2/_cdma_profile',
  4032: 'extension-controller extender/_modem2/_gsm_profile',
  4033: 'extension-controller extender/_modem2/_sim1',
  4034: 'extension-controller extender/_modem2/_sim2',
  4035: 'extension-controller extender/_modem2/_auto-switch',
  4036: 'webcache prefetch',
  4037: 'firewall ssl default-certificate',
  4038: 'isolator profile',
  4039: 'extender-controller extender/modem',
  4040: 'isolator profile/entries',
  4041: 'extender-controller extender/modem/_cdma_profile',
  4042: 'extender-controller extender/modem/_gsm_profile',
  4043: 'cloud orchestration',
  4044: 'cloud orchest-aws',
  4045: 'cloud orchest-awsconnector',
  4046: 'cloud orchest-awstemplate autoscale-tgw-new-vpc',
  4047: 'cloud orchest-awstemplate autoscale-existing-vpc',
  4048: 'cloud orchest-awstemplate autoscale-new-vpc',
  4049: 'system admin/trusthosts',
  4050: 'system ha-lic',
  4051: 'webfilter url-list',
  4052: 'webfilter url-list/entries',
  4053: 'webfilter domain-list',
  4054: 'webfilter domain-list/entries',
  4055: 'web-proxy implicit-proxy-rule',
  4056: 'web-proxy implicit-proxy-rule/proxy-servers',
  4057: 'web-proxy implicit-proxy-setting',
  4058: 'isolator setting',
  4059: 'firewall profile-protocol-options/proxy-redirect',
  4060: 'web-proxy redirect-profile',
  4061: 'web-proxy redirect-profile/entries',
  4062: 'firewall proxy-address6',
  4063: 'firewall proxy-address6/header-group',
  4064: 'firewall proxy-address6/tagging',
  4065: 'firewall proxy-addrgrp6',
  4066: 'firewall proxy-addrgrp6/tagging',
  4067: 'webfilter ftgd-risk-level',
  4068: 'webfilter profile/ftgd-wf/risk',
  4069: 'webfilter ftgd-local-risk',
}; // Number to String
export const fiGetCateNumByStr = (cateStr) => CATE2NUM[cateStr];
export const fiGetCateStrByNum = (cateNum) => NUM2CATE[cateNum];
