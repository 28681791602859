import { createSelector } from '@reduxjs/toolkit';
import { has, isFunction, isUndefined } from 'lodash';
import { getAppKey, appFirstLeaf, getAppUniKey } from './utils';

export const getRouting = (state) => state?.routing;

export const _select = (key) => createSelector(getRouting, (ret) => ret?.[key]);

export const getCurrentState = _select('currentState');
export const getIsLoadingApp = _select('isLoadingApp');
export const getAppStateCache = _select('appStateCache');
export const getIsAdomSwitcherOpen = _select('isAdomSwitcherOpen');
export const getAppInit = _select('init');
export const getIsAppInited = createSelector(
  getAppInit,
  (init) => init === 'done'
);

// apps

export const getAllApps = _select('allApps');
export const getAllAppTree = _select('allAppTree');
// get all App Menu tree without filtered apps.
export const getAppTree = _select('appTree');
export const getAllAppTrees = _select('allAppTrees');
// get current App Menu with filtering the disabled ones.
export const getSideMenuTree = _select('sideMenuTree');
export const getSideMenuMap = _select('sideMenuMap');
export const getContentMenuTree = _select('contentMenuTree');
export const getAppTreeMap = _select('appTreeMap');
export const getCurrentApp = createSelector(
  getCurrentState,
  getAllApps,
  (state, allApps) => allApps[state?.handle?.appUniKey]
);

// get current AppUniKey
export const getCurrentAppKey = createSelector(
  getCurrentState,
  (route) => route?.params?.appUniKey || route?.handle?.appUniKey
);
export const getSideMenuSelected = _select('sideMenuSelected');
export const getContentMenuLayout = _select('contentMenuLayout');
export const getAppPathMap = _select('appPathMap');

export const getAppByKey = (key) => (state) => {
  const allApps = getAllApps(state);
  return allApps?.[key];
};

// get the current content menu layout
export const getCurrentContentMenuLayout = createSelector(
  getContentMenuLayout,
  getSideMenuSelected,
  (cmLayout, selected) => {
    // Default is 'horizontal', cmLayout can be null.
    return cmLayout?.[selected] || 'horizontal';
  }
);

export const getAppAvailable = (key) => (state) => {
  if (!key) return false;

  const app = getAppByKey(key)(state);
  if (!app) return false;

  const treeMap = getAppTreeMap(state);
  const subTree = treeMap[key];
  // app is not shown
  if (isUndefined(subTree)) return false;

  const disabled = isFunction(app.disabled)
    ? app.disabled(app, state)
    : app.disabled;
  // app is disabled
  if (disabled) return false;

  // has children
  if (Array.isArray(subTree)) {
    return subTree.length > 0;
  }
  // no children
  return true;
};

/** Find out the side menu key of an appUniKey (available app).
 *  search from closest parent to the root, find the one in side menu.
 *
 * It can return undefined.
 */
export const getSideMenuByKey = (key) => (state) => {
  if (!key) return;
  const pathMap = getAppPathMap(state);
  const sideMenuMap = getSideMenuMap(state);
  const appPath = pathMap[key];
  return [key].concat(appPath).find((key) => has(sideMenuMap, key));
};

export const getParentKey = (key) =>
  createSelector(getAppPathMap, getSideMenuTree, (pathMap) => {
    if (!key) return;
    const appPath = pathMap[key];
    return appPath[0];
  });

export function getAppKeyFromCached(cachedState) {
  return cachedState?.params?.appUniKey || cachedState?.handle?.appUniKey;
}

function getFirstLeaf(tree, allApps) {
  const key = getAppKey(appFirstLeaf(tree));
  return allApps[key];
}

/** Recursively search for the next available app with specified key
 */
export const findNextApp = (key) => (state) => {
  const appTreeMap = getAppTreeMap(state);
  const appStateCache = getAppStateCache(state);
  const allApps = getAllApps(state);
  const sideMenuTree = getSideMenuTree(state);
  const currentMatches = getCurrentMatches(state) || [];
  const pathMap = getAppPathMap(state);

  /** test an app and its decedents, if ok, return it
   * @param { string } appKey - the app key
   * @return { object | null } app if it's found
   */
  function testFn(appKey) {
    if (!appKey) return null;

    const app = allApps[appKey];
    if (!app) return null;

    // test if app is disabled or not have children
    if (!getAppAvailable(appKey)(state)) return null;

    // recursively test each children
    const subTree = appTreeMap[appKey];
    // no children, not include those children is an empty array, because app
    // like fortiview custom view does not allow to select if there is no
    // custom view.
    if (!subTree) return app;
    // has children
    // test the cached app
    if (!app.noStateCache) {
      const cachedKey = getAppKeyFromCached(appStateCache?.[appKey]);
      // make sure the cached key is one of the children
      if (subTree.some((node) => getAppKey(node) === cachedKey)) {
        const found = cachedKey ? testFn(cachedKey) : null;
        if (found) return found;
      }
    }

    // test children
    for (let i = 0; i < subTree.length; i++) {
      const found = testFn(getAppKey(subTree[i]));
      if (found) return found;
    }

    return null;
  }

  /** Search recursively for the available app.
   * @param {string} testKey - the app to search for
   * @param {string[]} parentKeys - the parent keys of the searching app, used for
   *                             look for upper level
   */
  function searcher(testKey, parentKeys) {
    //  return the app, if not the right one, search the app's parents for the next
    //  one
    const found = testFn(testKey);
    if (found) return found;
    if (parentKeys.length > 0) {
      return searcher(parentKeys.at(-1), parentKeys.slice(0, -1));
    }
    return null;
  }

  // Try app path first, because currentMatches might be null at the
  // beginning.
  let paths = pathMap[key];
  // app path might also not exist, try from matches
  if (!paths?.length) {
    paths = currentMatches.reduce((accu, match, index, arr) => {
      if (index !== arr.length - 1) {
        const _key = getAppUniKey(match);
        if (_key) accu.push(_key);
      }
      return accu;
    }, []);
  }

  return searcher(key, paths) || getFirstLeaf(sideMenuTree, allApps);
};

export const appEnabled = (appKey) =>
  createSelector(getAppTreeMap, (trMap) => {
    return has(trMap, appKey);
  });

export const getRootAppKey = _select('rootAppKey');
export const getRootApp = createSelector(
  getRootAppKey,
  getAllApps,
  (rootKey, allApps) => {
    return allApps?.[rootKey];
  }
);

export const getCurrentMatches = _select('currentMatches');
