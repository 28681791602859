const DEVICE_URL = 'pm/config/device/';

class DeviceCertificate {
  static get_url(deviceName, vdomName, categories, cateSyntax) {
    return gen_url_from_categories(
      deviceName,
      get_device_cate_url(cateSyntax, vdomName),
      categories
    );
  }

  /**
   * If device has no vdom enabled => vpn_certificate_local
   * If device has vdom enabled:
   *   1. device level => certificate_local
   *   2. vdom level => vpn_certificate_local
   * @param {any} device
   * @param {string} vdom
   */
  static get_device_cert_cate(device, vdom) {
    if (vdom) return vpn_certificate_local;
    return certificate_local;
  }

  /**
   * If device has no vdom enabled => GLOBAL
   * If device has vdom enabled:
   *   1. device level => GLOBAL
   *   2. vdom level => VDOM
   * @param {any} device expects device object here
   * @param {string} vdom
   */
  static get_certificate_range(device, vdom) {
    const RANGE = {
      GLOBAL: 0,
      VDOM: 1,
    };
    if (device.vdoms) {
      // device has vdoms
      return vdom ? RANGE.VDOM : RANGE.GLOBAL;
    }
    return RANGE.GLOBAL;
  }
}

const cate_mixin = {
  get_cate() {
    return this.cateStr;
  },
  get_default_syntax() {
    return {
      data: {
        [this.cateStr]: {
          domain: 1,
        },
      },
    };
  },
};

/////////////////////////////////
const vpn_certificate_local = {
  __proto__: cate_mixin,
  cateStr: 'vpn certificate local',
};

const certificate_local = {
  __proto__: cate_mixin,
  cateStr: 'certificate local',
};

///////////////////////////////

function get_device_cate_url(cateSyntax, vdomName) {
  let urlToken;
  if (cateSyntax) {
    // find domain in cateSyntax
    let domain;
    for (let prop in cateSyntax.data) {
      domain = parseInt(cateSyntax.data[prop].domain);
      if (!vdomName && domain) {
        urlToken = 'vdom/root';
      } else {
        urlToken = vdomName ? 'vdom/' + vdomName : 'global';
      }
    }
  } else {
    urlToken = vdomName ? 'vdom/' + vdomName : 'global';
  }
  return urlToken;
}

function gen_url_from_categories(deviceName, urlToken, categories) {
  let _url = DEVICE_URL + deviceName + '/' + urlToken;
  for (let i = 0; i < categories.length; i++) {
    _url += '/' + categories[i];
  }
  return _url;
}

export { DeviceCertificate };
