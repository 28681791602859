import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { head, isFunction } from 'lodash';

import { fiSSource } from 'fi-ssource';
import { FmkObjSSelect2 } from 'rc_form';
import { useApiRequest } from 'rh_util_hooks';
import { ProToolkit } from '@fafm/neowise-pro';
import { NwIcon } from '@fafm/neowise-core';

import { fiDeviceDataLoader } from '../device_data_loader';
import { DEFAULT_GRPS } from '../constant';

export const FmkDeviceGroupSSelect = ({
  name,
  loader,
  filter,
  parser,
  onChange,
  validate,
  disabled = false,
  multipleSelect = false,
  dependencies = [],
  ...rest
}) => {
  const [devGrpSource] = useState(new fiSSource());

  const $opener = ProToolkit.useOpener();

  useApiRequest({
    dependencies,
    loader: async () => {
      const loadFn = loader || getDevGroups;
      const groups = await loadFn();
      return groups;
    },
    parser: (data) => {
      const groups = data;

      // filter data
      const filterFn = isFunction(filter)
        ? filter
        : () => groups.filter(devGrpFilter);
      const filtered = filterFn(groups);

      // parse into sselect choices
      const parserFn = isFunction(parser)
        ? parser
        : () => filtered.map(devGrpParser);
      const parsed = parserFn(filtered);

      devGrpSource.load(parsed);

      return parsed;
    },
  });

  const addDevGrp = async () => {
    const { createDeviceGroup } = await import(
      'react_apps/ra_dvm/actions/dvm_actions'
    );

    return createDeviceGroup([], $opener).then((resp) => {
      resp = Array.isArray(resp) ? head(resp) : resp;
      const choice = devGrpParser({ name: resp.name });
      return devGrpSource.add(choice);
    });
  };

  const editDevGrp = async (_, choice) => {
    const { editDeviceGroup } = await import(
      'react_apps/ra_dvm/actions/dvm_actions'
    );

    return editDeviceGroup(choice.data, $opener).then(() => {
      const newChoice = devGrpParser({ name: choice.id });
      return devGrpSource.update(choice, newChoice);
    });
  };

  return (
    <FmkObjSSelect2
      name={name}
      source={() => devGrpSource.source}
      formatChoiceHTML={formatDeviceGroupChoice}
      formatSelectedHTML={formatSelectedDeviceGroup}
      onChange={onChange}
      validate={validate}
      disabled={disabled}
      createNewCommands={[
        {
          icon: 'add',
          text: gettext('Create New'),
          exec: addDevGrp,
        },
      ]}
      editCommands={[
        {
          icon: 'edit',
          text: gettext('Edit'),
          exec: editDevGrp,
        },
      ]}
      allowClear={!disabled}
      multipleSelect={multipleSelect}
      {...rest}
    />
  );
};

export const DeviceGroupRow = ({ devGrpName, highlighter = (val) => val }) => {
  return (
    <div className='tw-flex tw-items-center tw-w-full tw-h-full'>
      <NwIcon
        name='device-total'
        className='tw-mr-1'
        label={gettext('Device Group')}
      ></NwIcon>
      <div className='tw-w-full tw-truncate'>{highlighter(devGrpName)}</div>
    </div>
  );
};

async function getDevGroups() {
  const groups = await fiDeviceDataLoader.getDeviceGroups();
  return groups;
}

function formatDeviceGroupChoice(item) {
  return ReactDOMServer.renderToString(
    <DeviceGroupRow devGrpName={item.text} />
  );
}

function formatSelectedDeviceGroup(item) {
  return formatDeviceGroupChoice(item);
}

export function devGrpParser(group) {
  return { id: group.name, text: group.name, data: group };
}

export function devGrpFilter(group) {
  if (DEFAULT_GRPS.includes(group.oid) || group.defaultGroup) {
    return false;
  }

  return (
    !group.logging &&
    !group.managed &&
    !group.unreg &&
    group.ostype !== MACROS.DVM.DVM_OS_TYPE_FAZ
  );
}
