import { isFunction } from 'lodash';
import { useEffect, useRef } from 'react';

export * from './utils';

export {
  defer,
  propsCamelToDash,
  memoFocus,
  getElByRefOrFn,
  getTableHeight,
  // hooks
  useNwComponentOpenAnimation,
  useMultipleCallbacks,
};

function defer() {
  let resolve, reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return {
    promise,
    resolve,
    reject,
  };
}

const getElByRefOrFn = (refFn) => {
  if (isFunction(refFn)) {
    return refFn();
  }
  return refFn.current;
};

function propsCamelToDash(props) {
  function camelToDash(attr) {
    return attr.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
  }

  return Object.entries(props).reduce((newProp, [key, val]) => {
    switch (key) {
      case 'className':
        newProp['class'] = val;
        break;
      default:
        newProp[camelToDash(key)] = val;
    }

    return newProp;
  }, {});
}

function memoFocus() {
  const prevFocus = document.activeElement;

  return function restore() {
    prevFocus.isConnected && prevFocus.focus?.();
  };
}

function useNwComponentOpenAnimation({ nwComponent, isOpen, $opener }) {
  const setComponentOpen = (isOpen) => {
    const nwComponentDOM = getElByRefOrFn(nwComponent);
    if (!(nwComponentDOM instanceof HTMLElement)) return;

    nwComponentDOM.open = isOpen;
  };

  useEffect(() => {
    // use setTimeout so the component has open animation on init
    setTimeout(() => {
      setComponentOpen(isOpen);
    });
  }, [isOpen]);

  // close animation before unmount
  $opener.promise.catch(Boolean).finally(() => {
    setComponentOpen(false);
  });

  const onCloseComponent = () => {
    $opener.reject();
  };

  return onCloseComponent;
}

function useMultipleCallbacks() {
  const callbacks = useRef(new Set());

  const registerCallback = (callback) => {
    callbacks.current.add(callback);
    return () => callbacks.current.delete(callback);
  };

  const callCallbacks = (...args) => {
    callbacks.current.forEach((fn) => fn.apply(null, args));
  };

  return [registerCallback, callCallbacks];
}

function getTableHeight({
  numRows = 0,
  rowHeight = MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_24,
  baseHeight = MACROS.USER.SYS.DIV_TABLE.BASE_TABLE_HEIGHT,
}) {
  return numRows * rowHeight + baseHeight;
}
