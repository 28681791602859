import { useCallback, useEffect, useRef } from 'react';
import { isFunction, isNil, transform } from 'lodash';

// @ts-ignore
import { createDeferred } from 'kit-promise';

/** ----------------------------------------------------------------------------
 * TYPES
 * -------------------------------------------------------------------------- */
type SourceKey = unknown;

type ResolvedSource = Array<unknown>;

enum CallbackTypes {
  Init = 'init',
  Cache = 'cache',
  Reload = 'reload',
  Delete = 'delete',
  LoadAll = 'load_all',
  ReloadLoadAll = 'reload_all',
}

type SourceCallback = ({
  type,
  value,
}: {
  type: CallbackTypes;
  value: unknown;
}) => unknown;

type Source = {
  key: SourceKey;
  source: (() => Promise<unknown>) | ResolvedSource; // Can be a function that returns a promise OR an array of choices
  callback?: SourceCallback; // callback to be called when data source is initialed or reloaded
  label?: string;
};

type SourceMap = Map<SourceKey, SharedSource> | null;

type DeferredPromise<T> = {
  promise: Promise<T>;
  resolve: (resp?: T) => any;
  reject: (err?: any) => any;
};

type createResolvedSourceFunctionType = (
  id: string,
  label: string | undefined,
  val: ResolvedSource
) => object;

/** ----------------------------------------------------------------------------
 * CLASSES
 * -------------------------------------------------------------------------- */
export class SharedSourceMap {
  private _sharedSourceMap: SourceMap = null;

  constructor(sources: Array<Source>) {
    this._sharedSourceMap = new Map();
    if (sources) {
      for (const { key, source, callback } of sources) {
        this.register({ key: key as SourceKey, source, callback });
      }
    }
  }

  getSource(key: SourceKey): SharedSource | undefined {
    return this._sharedSourceMap?.get(key);
  }

  setSource(key: SourceKey, val: SharedSource): void {
    this._sharedSourceMap?.set(key, val);
  }

  setSourcePromise({
    key,
    promise,
  }: {
    key: SourceKey;
    promise: Promise<unknown>;
  }) {
    const cachedSource = this.getSource(key);
    if (!cachedSource) return;

    cachedSource.promise = promise;
  }

  register({
    key,
    source,
    callback,
  }: {
    key: SourceKey;
    source: Source['source'];
    callback: Source['callback'];
  }) {
    if (!this._sharedSourceMap) {
      this._sharedSourceMap = new Map();
    }

    const cachedSource = this.getSource(key);
    if (cachedSource) {
      callback && cachedSource.addCallback(callback);
      return;
    }

    const shared = new SharedSource(source, callback);
    this.setSource(key, shared);

    return () => this.clean({ key });
  }

  runCallbacks({
    key,
    type,
    value,
  }: {
    key: SourceKey;
    type: CallbackTypes;
    value: unknown;
  }) {
    if (!this._sharedSourceMap) return;

    // if no key, run all callbacks
    if (!key) {
      this._sharedSourceMap.forEach((source) => {
        source.callbacks.forEach((callback) => {
          isFunction(callback) && callback({ type, value });
        });
      });
      return;
    }

    const cachedSource = this.getSource(key);
    if (!cachedSource || !cachedSource.callbacks.length) return;

    cachedSource.callbacks.forEach((callback) => {
      isFunction(callback) && callback({ type, value });
    });
  }

  async load({ key }: { key: SourceKey }) {
    const cachedSource = this.getSource(key);
    if (!cachedSource) return;

    const isCached = !!cachedSource.promise;
    const callbackType = isCached ? CallbackTypes.Cache : CallbackTypes.Init;
    const resp = await cachedSource.source();
    this.runCallbacks({ key, type: callbackType, value: resp });
    // this.debug({ key });

    return resp;
  }

  async reload({ key }: { key: SourceKey }) {
    const cachedSource = this.getSource(key);
    if (!cachedSource) return;

    cachedSource.promise = null;
    const resp = await cachedSource.source();
    this.runCallbacks({ key, type: CallbackTypes.Reload, value: resp });
    // this.debug({ key });

    return resp;
  }

  async reloadAll() {
    if (!this._sharedSourceMap) return;

    for (const key of Object.keys(this._sharedSourceMap)) {
      this.reload({ key: key as SourceKey });
    }
  }

  clean({ key }: { key: SourceKey }) {
    if (!this._sharedSourceMap) return;

    this.runCallbacks({
      key,
      type: CallbackTypes.Delete,
      value: {
        deleted: key,
        current: new Map(this._sharedSourceMap),
      },
    });
    this._sharedSourceMap.delete(key);

    if (!this._sharedSourceMap.size) {
      this._sharedSourceMap = null;
    }
  }

  cleanAll() {
    if (!this._sharedSourceMap) return;

    for (const key of Object.keys(this._sharedSourceMap)) {
      this.clean({ key });
    }
  }

  debug({ key }: { key?: SourceKey } = {}) {
    if (!this._sharedSourceMap?.size) return;

    if (key) {
      console.error('cached source =', key, this.getSource(key));
      return;
    }

    console.error('cached source =', this._sharedSourceMap);
  }
}

class SharedSource {
  promise: Promise<unknown> | null;
  source: () => Promise<unknown>;
  callbacks: Array<SourceCallback>;

  constructor(
    newSource: Source['source'],
    callback: SourceCallback | undefined
  ) {
    this.promise = null;
    this.source = this.initPromiseFunc(newSource);
    this.callbacks = [];
    if (callback) {
      this.callbacks.push(callback);
    }
  }

  initPromiseFunc(newSource: Source['source']) {
    return async () => {
      if (this.promise) {
        return this.promise;
      }
      const newPromise = isFunction(newSource) ? newSource() : newSource;
      return (this.promise = newPromise as Promise<unknown>);
    };
  }

  addCallback(callback: SourceCallback) {
    this.callbacks.push(callback);
  }
}

/** ----------------------------------------------------------------------------
 * HOOKS
 * -------------------------------------------------------------------------- */
const useCleanupSharedSourceMap = ({
  sharedSourceMap,
  sources,
}: {
  sharedSourceMap: SharedSourceMap;
  sources: Array<Source>;
}) => {
  useEffect(() => {
    return () => {
      for (const { key } of sources) {
        sharedSourceMap.clean({ key });
      }
    };
  }, []);
};

export const useSharedSources = ({
  sharedSourceMap,
  sources,
  sourceLabel,
  createResolvedSource = createDefaultResolvedSource,
}: {
  sharedSourceMap: SharedSourceMap;
  sources: Array<Source>;
  sourceLabel?: string;
  createResolvedSource?: createResolvedSourceFunctionType;
}) => {
  const allSourcePromiseRef = useRef<DeferredPromise<ResolvedSource>>(
    createDeferred() as unknown as DeferredPromise<ResolvedSource>
  );

  // ds loading
  useEffect(() => {
    for (const { key, source, callback } of sources) {
      if (!key) continue;
      sharedSourceMap.register({ key: key as SourceKey, source, callback });
    }
    loadAllSource();
  }, [sources]);

  // cleanup
  useCleanupSharedSourceMap({ sharedSourceMap, sources });

  const getAllSources = useCallback(async (): Promise<ResolvedSource> => {
    const promises = transform<Source, Array<SharedSource['promise']>>(
      sources,
      (result, sourceObj) => {
        if (sourceObj && sourceObj.key) {
          result.push(sharedSourceMap.load({ key: sourceObj.key }));
        }
      },
      []
    );
    const resps = await Promise.allSettled(promises);
    return transform<Source, ResolvedSource>(
      resps as Array<any>,
      (result, resp, index) => {
        const source = sources[index];
        const settledResult =
          (resp as unknown as PromiseSettledResult<Source>) || {};
        if (settledResult.status !== 'fulfilled' || !settledResult.value) {
          return;
        }

        const val = settledResult.value;
        if (!Array.isArray(val)) {
          result.push(val);
          return;
        }

        if (val.length == 1) {
          result.push(val[0]);
          return;
        }

        const multipleSources = val.every((source) => !isNil(source.children));
        if (multipleSources) {
          result.push(...val);
        } else {
          const label = source.label || sourceLabel;
          result.push(
            createResolvedSource(`${index}-${source.key || label}`, label, val)
          );
        }
      },
      []
    );
  }, [sources, sharedSourceMap, sourceLabel, createResolvedSource]);

  const loadAllSource = useCallback(
    async (reload = false): Promise<ResolvedSource> => {
      try {
        const resp = await getAllSources();
        if (reload) {
          // promise can only be fulfilled once > need to create a new promise for reloading
          allSourcePromiseRef.current =
            createDeferred() as unknown as DeferredPromise<ResolvedSource>;
          allSourcePromiseRef.current.resolve(resp);
        } else {
          allSourcePromiseRef.current.resolve(resp);
          sharedSourceMap.runCallbacks({
            key: null,
            type: CallbackTypes.LoadAll,
            value: resp,
          });
        }
        return resp;
      } catch (err) {
        allSourcePromiseRef.current.resolve([]);
        return [];
      }
    },
    [getAllSources]
  );

  const reloadSourceByKey = useCallback(
    async (key: SourceKey): Promise<unknown> => {
      const newVal = await sharedSourceMap.reload({ key });
      const resp = await loadAllSource(true);
      // notify other subscribers to reload source and update UI
      sharedSourceMap.runCallbacks({
        key: null,
        type: CallbackTypes.ReloadLoadAll,
        value: resp,
      });
      return newVal;
    },
    [sharedSourceMap, loadAllSource]
  );

  const reloadAllSources = useCallback(async (): Promise<void> => {
    await sharedSourceMap.reloadAll();
    await loadAllSource(true);
  }, [sharedSourceMap, loadAllSource]);

  const getAllSourcePromise = useCallback((): Promise<unknown> => {
    return allSourcePromiseRef.current.promise;
  }, []);

  return {
    sharedSourceMap,
    getAllSourcePromise,
    loadAllSource,
    reloadSourceByKey,
    reloadAllSources,
    getAllSources,
  };
};

/** ----------------------------------------------------------------------------
 * UTILS
 * -------------------------------------------------------------------------- */
const createDefaultResolvedSource: createResolvedSourceFunctionType = (
  id,
  label,
  val
) => {
  return {
    id: `datasrc-${id}`,
    text: label || gettext('Data Source'),
    excluded: true,
    children: val,
  };
};
