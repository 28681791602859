import React from 'react';
import { ReactContentViewContainer } from 'react_apps/ra_main/main_layout/ReactContentViewContainer';

export default {
  path: 'map',
  element: <ReactContentViewContainer menuId='fap_maps' />,
  handle: {
    appUniKey: 'fap_map',
  },
  children: [
    // Google Map
    {
      path: 'gmap',
      handle: {
        appUniKey: 'fap_map_gmap',
      },
      lazy: () => import('./ap_map/APGoogleMap/FortiAPMap'),
    },
    // Floor Map
    {
      path: 'floor',
      handle: {
        appUniKey: 'fap_map_floor',
      },
      lazy: () => import('./ap_map/ApFloorMap/FloorMapTable'),
    },
    {
      path: 'editfloor',
      handle: {
        appUniKey: 'fap_map_floor',
      },
      lazy: () => import('./ap_map/ApFloorMap/EditFloorMap'),
    },
  ],
};
