import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import { callPromiseAction, getDataFromResponse } from '../../utils';

export function* watchDevicesLicense() {
  yield takeLatest(
    [
      actions.fetchDevicesLicenseAction.type,
      actions.devicesLicenseAction.fetch.REQUEST,
    ],
    fetchLicense
  );
}

function* fetchLicense(action) {
  yield callPromiseAction(action, function* () {
    yield put(actions.devicesLicenseAction.fetch.start());

    const resp = yield call(api.fetch_device_license);

    const data = getDataFromResponse(resp);

    yield put(actions.devicesLicenseAction.fetch.success(data));

    return data;
  });
}
