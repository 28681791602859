import { getCurrentState } from 'fistore/routing/selectors';
import { getAdminProfileData } from 'fistore/session/profile/selectors';
import { isNumber } from 'lodash';
import { fiGetCateStrByNum } from 'macros/CategoryIDName';
import { inPnoDualPane } from './selectors';

export type JumpParams = Partial<{
  /**
   * set this {pkg oid} if jump to a policy package.
   */
  pkgid: number;
  /**
   * set this {category name|number} if jump to certain policy list within a policy package.
   */
  pcate: string | number;
  /**
   * set this {policy mkey} if scroll to and highlight an entry in the policy list.
   */
  pid: string | number;
  /**
   * PNO internal.
   * one policy category can have multiple types like fw and fw_vwp.
   */
  ptype: string;
  /**
   * set this {category name|number} if jump to object list.
   */
  objCate: string | number;
  /**
   * set this {object mkey} if scroll to and highlight an entry in the object list.
   */
  objId: string | number;
  /**
   * PNO internal.
   * appUniKey changes between different ADOMs.
   */
  appUniKey: string;
}>;

type RouteUtils = {
  makeJumpState: (p: JumpParams) => {
    url: string;
    state: ReturnType<typeof stateParser>;
  };
  isPnoApp: (appKey?: string) => boolean;
};

const stateParser = ({ pkgid, pcate, pid, ptype }: JumpParams) => {
  const result = {
    pkgid,
    pcate: isNumber(pcate) ? (fiGetCateStrByNum(pcate) as string) : pcate,
    pid,
    ptype,
  };
  return result;
};

const pnoUtils = (st: any, appPrefix: string, pkgAppKey: string) => {
  const self: RouteUtils = {
    makeJumpState: (params) => {
      const currentAppKey = getCurrentState(st)?.handle?.appUniKey;
      const inPno = self.isPnoApp(currentAppKey);
      const isDualPane = inPnoDualPane(st);

      let { appUniKey } = params;
      const { pkgid } = params;

      if (isDualPane) {
        if (inPno) {
          // in pno, dual pane.
          if (!appUniKey && pkgid) {
            appUniKey = currentAppKey;
          }
        } else {
          // from other module, dual pane.
          if (pkgid) {
            appUniKey = pkgAppKey;
          }
        }
      } else {
        // not dual pane.
        if (pkgid) {
          appUniKey = pkgAppKey;
        }

        if (inPno) {
          // in pno, not dual pane.
        } else {
          // from other module, not dual pane.
        }
      }

      return {
        url: `/${appPrefix}/${appUniKey}`,
        state: stateParser(params),
      };
    },
    isPnoApp: (key) => Boolean(key?.startsWith(appPrefix)),
  };
  return self;
};

export const routeUtils = (st: any): RouteUtils => {
  if (
    getAdminProfileData(st)?.type ===
    MACROS.USER.SYS.ADMIN_PROFILE_TYPE_RESTRICTED
  ) {
    return pnoUtils(st, 'rstadm', 'rstadm_policy');
  }
  return pnoUtils(st, 'pno', 'pno_policy');
};
