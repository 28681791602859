import { useRef } from 'react';

import { makeObservers } from 'kit-observer';

/**
 * To allow subscribing callback functions to be trigger later when notify function is called
 * @returns observer instance
 */
export const useObserver = ({ onSubscribe, onUnsubscribe, onNotify } = {}) => {
  const observsersRef = useRef(
    makeObservers({ onSubscribe, onUnsubscribe, onNotify })
  );

  return observsersRef.current;
};
