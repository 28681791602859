import { forwardRef, useMemo, useState } from 'react';
import { useField, FieldArray } from 'formik';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  closestCenter,
  MeasuringStrategy,
} from '@dnd-kit/core';
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import { isFunction, isPlainObject } from 'lodash';
import cn from 'classnames';
import { NwIconButton } from '@fafm/neowise-core';

import { getAutoId } from './util/util';

// Note: need to make sure each SortableItem has an id, otherwise the list cannot be sorted
const SortableItem = ({
  id,
  data,
  automationId,
  formatEntryFn,
  arrayHelpers,
  getSortableItemStyle,
  handle,
  className,
  useOverlay,
  textAttr,
}) => {
  const { index, opt } = data || {};

  const sortableProps = useSortable({ id, data });
  const {
    attributes,
    // listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = sortableProps;

  let style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (isFunction(getSortableItemStyle)) {
    const extraStyles = getSortableItemStyle({
      ...sortableProps,
      useOverlay,
      handle,
    });
    style = { ...style, ...extraStyles };
  }

  return (
    <div
      // styling
      className={cn(
        {
          'fi-box-shadow-grey': isDragging,
        },
        className
      )}
      style={style}
      automation-id={automationId}
      // dnd props
      ref={setNodeRef}
      {...attributes}
    >
      {formatEntryFn({
        member: opt[textAttr],
        arrayHelpers,
        index,
        dndProps: {
          ...sortableProps,
        },
        handle,
      })}
    </div>
  );
};

const DragOverlayItem = forwardRef(({ render, ...props }, ref) => {
  return (
    <div className='tw-w-full tw-cursor-move' {...props} ref={ref}>
      {render ? render() : null}
    </div>
  );
});

export const FmkSortableList = ({
  name,
  'automation-id': propAutoId,
  automationId,
  formatEntryFn,
  getSortableItemStyle = _getDefaultSortableItemStyle,
  collisionDetection = closestCenter,
  activationConstraint: _activationConstraint = {},
  useOverlay = true,
  handle = false,
  containerStyle = {
    maxHeight: 800,
  },
  idAttr = 'id',
  textAttr = 'text',
}) => {
  const [, { value }, { setValue }] = useField(name);

  // normalize sortable items
  const items = useMemo(() => {
    return (value || []).map((item) => {
      if (!isPlainObject(item)) {
        item = { [idAttr]: item, [textAttr]: item };
      }
      const { [idAttr]: objId, [textAttr]: objText, ...rest } = item;
      const id = objId || objText;
      return {
        id,
        text: objText,
        ...rest,
      };
    });
  }, [value]);

  const [activeItem, setActiveItem] = useState(null);

  const activationConstraint = useMemo(() => {
    const defaults = !handle ? { delay: 0, tolerance: 5 } : {};
    return {
      ...defaults,
      ..._activationConstraint,
    };
  }, [handle, _activationConstraint]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragStart = ({ active }) => {
    setActiveItem(active);
  };

  const onDragEnd = ({ over, active }) => {
    const oldIndex = active?.data?.current?.index;
    const newIndex = over?.data?.current?.index;
    if (oldIndex !== newIndex) {
      const reordered = arrayMove(items, oldIndex, newIndex);
      const ids = (reordered || []).map((item) => item[idAttr]);
      setValue(ids);
      return reordered;
    }

    setActiveItem(null);
  };

  const getFormatMemberFn = () => {
    return (
      formatEntryFn ||
      renderMemberItem({
        getAutoId: (name) => `${automationId}:${name}`,
      })
    );
  };

  const renderSortableItem = ({ opt, index, arrayHelpers, className }) => {
    const { id, text, autoId } = opt;
    const key = `${index}-${text}`;
    const sortableItemId = id || text;
    const itemAutomationId = getAutoId(
      index,
      propAutoId || automationId,
      autoId || id || opt
    );
    return (
      <SortableItem
        key={key}
        id={sortableItemId}
        data={{ index, opt }}
        automationId={itemAutomationId}
        formatEntryFn={getFormatMemberFn()}
        arrayHelpers={arrayHelpers}
        getSortableItemStyle={getSortableItemStyle}
        handle={handle}
        className={className}
        useOverlay={useOverlay}
        textAttr={textAttr}
      />
    );
  };

  const renderOverlay = ({ arrayHelpers }) => {
    if (!activeItem || !useOverlay) return null;

    const render = getFormatMemberFn();
    return (
      <DragOverlay>
        <DragOverlayItem
          render={() => {
            const opt = activeItem.data?.current?.opt;
            const index = activeItem.data?.current?.index;
            return (
              <div
                // styling
                className={'fi-box-shadow-grey'}
              >
                {render({
                  member: opt[textAttr],
                  arrayHelpers,
                  index,
                  dndProps: {},
                  handle,
                })}
              </div>
            );
          }}
        />
      </DragOverlay>
    );
  };

  return (
    <div
      className='tw-w-full tw-overflow-y-auto tw-f-full'
      style={containerStyle}
    >
      <FieldArray name={name}>
        {(arrayHelpers) => (
          <DndContext
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragCancel={() => setActiveItem(null)}
            sensors={sensors}
            autoScroll={{ layoutShiftCompensation: true }}
            collisionDetection={collisionDetection}
            animateLayoutChanges={{ wasDragging: true }}
            measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
              adjustScale={true}
            >
              {items.map((opt, index) =>
                renderSortableItem({ opt, index, arrayHelpers })
              )}
            </SortableContext>

            {renderOverlay({ arrayHelpers })}
          </DndContext>
        )}
      </FieldArray>
    </div>
  );
};
FmkSortableList.displayName = 'FmkSortableList';

FmkSortableList.propTypes = {
  /**
   * Prop name for the sortable list in the formik field
   */
  name: PropTypes.string.isRequired,
  /**
   * Automation id prefix for each list item
   */
  'automation-id': PropTypes.string,
  automationId: PropTypes.string,
  /**
   * Format function for list item
   */
  formatEntryFn: PropTypes.func,
  /**
   * Get stylings for list item
   */
  getSortableItemStyle: PropTypes.func,
};

const renderContent = ({
  member,
  arrayHelpers,
  index,
  dndProps,
  _renderContent,
}) => {
  // custom member content renderer
  if (isFunction(_renderContent)) {
    return _renderContent({
      member,
      arrayHelpers,
      index,
      getAutoId,
      dndProps,
    });
  }

  // default member content renderer
  return <div className='tw-w-full tw-pl-2'>{member}</div>;
};

export function renderMemberItem({
  renderContent: _renderContent,
  getAutoId,
  showDeleteBtn = true,
  buttonProps = {},
  otherActions,
  containerProps = { style: { height: MACROS.USER.SYS.SSELECT_ITEM_HEIGHT } },
} = {}) {
  const renderDelBtn = ({ arrayHelpers, index, member }) => {
    if (!showDeleteBtn) return null;

    return (
      <NwIconButton
        name='close'
        onClick={(event) => {
          event.stopPropagation();
          arrayHelpers.remove(index);
        }}
        label={gettext('Remove')}
        automation-id={getAutoId(`member-${index}-${member}-delete-btn`)}
        {...buttonProps}
      ></NwIconButton>
    );
  };

  const renderDragButton = ({ handle, dndProps, index, member }) => {
    if (!handle) return null;

    return (
      <NwIconButton
        name='drag'
        label={gettext('Re-order')}
        {...(handle ? dndProps?.listeners : {})}
        automation-id={getAutoId(`member-${index}-${member}-drag-handle`)}
        {...buttonProps}
      />
    );
  };

  return ({ member, arrayHelpers, index, dndProps, handle }) => (
    <div
      className={
        'tw-w-full tw-mb-2 tw-flex tw-items-center tw-justify-between fi-input-border'
      }
      {...containerProps}
    >
      {/* Allow dnd for content only, otherwise it will affect the right buttons */}
      <div className='tw-w-full' {...(handle ? {} : dndProps?.listeners)}>
        {renderContent({
          member,
          arrayHelpers,
          index,
          dndProps,
          _renderContent,
        })}
      </div>

      <div className='tw-flex tw-gap-1 tw-mr-1'>
        {isFunction(otherActions) ? otherActions() : null}

        {renderDelBtn({ arrayHelpers, index, member })}

        {renderDragButton({ handle, dndProps, index, member })}
      </div>
    </div>
  );
}

/** ----------------------------------------------------------------------------
 * Defaults
 * -------------------------------------------------------------------------- */
function _getDefaultSortableItemStyle({
  isDragging,
  isOver,
  useOverlay,
  handle,
}) {
  let style = {};
  if (isOver) {
    style.backgroundColor = 'rgb(var(--nw-color-neutral-0))';
  }
  if (isDragging) {
    style.backgroundColor = 'rgb(var(--nw-color-neutral-0))';
    style.color = 'rgb(var(--nw-color-neutral-1000))';
    if (useOverlay) {
      style.opacity = 0;
    }
  }
  if (!handle) {
    style.cursor = 'move';
  }
  return style;
}
