export const dashApis = {
  ftv: {
    threat: ['top-threats'],
    threat_map: [
      // using log view api
    ],
    threat_detect_summary: ['threat-detect-summary'],
    fsa_detection_view: ['fsa-detection'],
    source_hosts: ['top-sources'],
    source_objects: ['srcuuid'],
    destination_ips: ['top-destinations'],
    destination_objects: ['dstuuid'],
    country: ['top-countries'],
    policy_hits: ['policy-hits'],
    create_policy_by_hits: ['policy-uuid'],
    dns_log_view: ['dns-log-view'],
    ztna_servers: ['ztna-servers'],
    app: ['top-applications'],
    cloud_application: ['sidb-top-cloud-applications'],
    cloud_user: ['sidb-cloud-user0'],
    website_domain: ['website-domain'],
    website_category: ['top-websites'],
    browsing_users: ['top-browsing-users'],
    ssl: ['ssl-dialup-ipsec'],
    site: ['site-to-site-ipsec'],
    admin: ['admin-logins'],
    system_events: ['system-events'],
    resource: ['resource-usage', 'resource-usage-peak'],
    unauthorized_connections: ['failed-authentication-attempts'],
    fct_threat: ['top-fct-threats'],
    fct_app: ['top-fct-applications'],
    fct_website: ['top-fct-websites'],
    fddos_source_view: ['fddos-source'],
    fddos_destination_view: ['fddos-destination'],
    fddos_type_view: ['top-type'],
    ffw_source_hosts: ['ffw-top-sources'],
    ffw_source_objects: ['ffw-srcuuid'],
    ffw_destination_ips: ['ffw-top-destinations'],
    ffw_destination_objects: ['ffw-dstuuid'],
    ffw_country: ['ffw-top-countries'],
    ffw_policy_hits: ['ffw-policy-hits'],
    ffw_ssl: ['ffw-ssl-dialup-ipsec'],
    ffw_site: ['ffw-site-to-site-ipsec'],
    ffw_admin: ['ffw-admin-logins'],
    ffw_system_events: ['system-events'],
    ffw_resource: ['ffw-resource-usage', 'ffw-resource-usage-peak'],
    ffw_unauthorized_connections: ['ffw-failed-authentication-attempts'],
    fwb_security_country: ['fwb-security-country'],
    fwb_security_threat: ['fwb-security-threat'],
    fwb_security_policy: ['fwb-security-policy'],
    fwb_traffic_source: ['fwb-traffic-source'],
    fwb_traffic_country: ['fwb-traffic-country'],
  },
  noc: {
    'fmg-dash1': [
      // using fmg dvm
    ],
    'threat-monitor': ['threat-src-dst', 'top-threats', 'top-threats'],
    'l3-traffic-monitor': [
      'top-sources',
      'top-countries',
      'policy-hits',
      'top-destinations',
    ],
    'l7-traffic-monitor': [
      'website-domain',
      'sidb-top-cloud-applications',
      'top-browsing-users',
    ],
    'compromised-hosts': ['threat-detect-summary'],
    'sdwan-dashboard': [
      'sdwan-intf-bandwidth-line',
      'sdwan-intf-sla-status-line',
      'sdwan-rules-intf-view',
      'sdwan-app-view',
      'all-session',
      'sdwan-app-bandwidth-view',
      'sdwan-app-latency-line',
      'sdwan-app-jitter-line',
      'sdwan-app-packetloss-line',
      'sdwan-app-bandwidth-line',
      'sdwan-interface-view',
      'sdwan-intf-mos-line',
      'all-session',
      'sdwan-perf-stats-line',
    ],
    'sdwan-summary': [
      'sdwan-dev-stats',
      'sdwan-dev-intf-perf-stats',
      'sdwan-summary-app-view',
      'sdwan-dev-throughout',
      'sdwan-summary-user-view',
      'sdwan-dev-mos-line',
      'sdwan-speed-test-dev-bandwidth',
      'sdwan-speed-test-summary',
    ],
    'traffic-shaping-monitor': [
      'shaper-intf-traffic-line',
      'shaper-app-view',
      'shaper-dropped-traffic-line',
    ],
    'ztna-dashboard': [
      'ztna-statistics',
      'ztna-line',
      'ztna-users',
      'ztna-devices',
      'ztna-private-app',
      'ztna-public-app',
      'ztna-rules',
      'ztna-app-line',
      'ztna-casb-app',
    ],
    'fortisandbox-detections': [
      'fsa-detection',
      'fsa-verdict',
      'fsa-verdict-user',
    ],
    'fortimail-dashboard': [
      'fml-received-vs-spam',
      'fml-top-senders',
      'fml-top-recipients',
      'fml-threat-stats',
      'fml-threat-stats-size',
      'fml-mail-stats',
      'fml-mail-stats-size',
      'fml-mail-stats-scan',
      'fml-mail-stats-trans',
      'fml-sandbox-outbreak',
      'fml-stats-summary',
    ],
    'fortiproxy-dashboard': [
      'fpx-top-sources',
      'fpx-top-destinations',
      'fpx-website-domain',
      'fpx-threat-src-dst',
      'fpx-top-threats',
      'fpx-top-applications',
      'fpx-top-dlp-events',
    ],
    'endpoint-monitor': [
      'top-fct-vulnerabilities-dev',
      'endpoints',
      'top-fct-threats',
      'top-fct-vulnerabilities-vuln',
    ],
    'fabric-state-of-security': [
      'best-practices-by-region',
      '', // for fabric_audit, fabric_members, fabric_history_compare
    ],
    'vpn-monitor': ['ssl-dialup-ipsec', 'site-to-site-ipsec'],
    'wifi-monitor': [
      'authorized-access-points',
      'rogue-access-points',
      'authorized-ssids',
    ],
    'fct-software-inventory': [
      'software-inventory-total-installed',
      'software-inventory-new-detected',
      'software-inventory-top-installed-apps',
      'software-inventory-top-host-by-apps',
    ],
    iot: ['iot-inventory-stats'],
    'fct-threat-moonitor': ['top-fct-threats'],
    'fct-traffic-moonitor': ['top-fct-applications', 'top-fct-websites'],
    'fct-endpoints-moonitor': ['endpoints'],
    'fddos-traffic-moonitor': ['fddos-source', 'fddos-destination', 'top-type'],
    'ffw-traffic-monitor': [
      'ffw-top-sources',
      'ffw-top-countries',
      'ffw-policy-hits',
      'ffw-top-destinations',
    ],
    'ffw-vpn-monitor': ['ffw-ssl-dialup-ipsec', 'ffw-site-to-site-ipsec'],
    'faz-performance-monitor': [
      // using system dash apis
    ],
    'threat-research-monitor': [
      // using fgd apis
    ],
    'local-threat-research-monitor': ['siem-local-threat'],
    'dlp-dashboard': [
      'dlp-line',
      'dlp-top-dst-countries',
      'dlp-top-user-sessions',
      'dlp-top-user-bytes',
      'dlp-top-protocol-sessions',
      'dlp-top-protocol-bytes',
      'dlp-top-events',
      'dlp-profile-hit-sessions',
      'dlp-profile-hit-bytes',
      'dlp-top-sensitive-files',
    ],
  },
};
