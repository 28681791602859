import { fiFmgHttp } from 'fi-http';
import { getMenuItemByCdId } from '../menu_items_config';
import { deviceStatus } from 'fi-dvm';
import { fiDvmSyntaxUtils } from './dvm_syntax_utils';
import { fiDevSyntax } from './dev_syntax';
import { fiDevCfgRequests } from '../requests/device_cfg_requests';
import { formatInterfaceName } from 'kit-viewdata';

import { fiAdom } from 'fi-session';
import { compareDeviceVer } from 'ra_device_util';
import {
  merge,
  capitalize,
  cloneDeep,
  get,
  isFunction,
  isObject,
  isString,
  isUndefined,
} from 'lodash';
import { escapeSlash } from 'kit/kit-regexp';

const isDefined = (arg) => !isUndefined(arg);

var syntax_cache = {};
var syntax_mgd_cache = {};
//

/**
 *@description helper function to normalize category input
 */
var getCate = function (cate) {
  var ocat = cate || null;
  return ocat ? ocat : { cate: cate };
};

/**
 *@description helper function to add syntax to cache
 */
var addToSyntaxCache = function (adomName, cate, syntax) {
  if (!syntax_cache[adomName]) {
    syntax_cache[adomName] = {};
  }

  syntax_cache[adomName][cate] = syntax;
};

/**
 *@description helper function to add syntax to cache
 */
var getFromSyntaxCache = function (adomName, cate) {
  if (syntax_cache[adomName] && syntax_cache[adomName][cate]) {
    return syntax_cache[adomName][cate];
  }
  return null;
};

var addToMgdSyntaxCache = function (
  adomName,
  devName,
  vdomName,
  cate,
  cfgitemid,
  syntax
) {
  if (!syntax_mgd_cache[adomName]) {
    syntax_mgd_cache[adomName] = {};
  }
  cfgitemid = cfgitemid || '';
  var key = devName + '_' + vdomName + '_' + cate + '_' + cfgitemid;
  syntax_mgd_cache[adomName][key] = syntax;
};

var getFromMgdSyntaxCache = function (
  adomName,
  devName,
  vdomName,
  cate,
  cfgitemid
) {
  cfgitemid = cfgitemid || '';
  var key = devName + '_' + vdomName + '_' + cate + '_' + cfgitemid;
  if (syntax_mgd_cache[adomName] && syntax_mgd_cache[adomName][key]) {
    return syntax_mgd_cache[adomName][key];
  }
  return null;
};

var KW_NOT_SUPPORTED = 'is_not_supported';
var checkAttrSupported = function (attrdef) {
  if (attrdef && attrdef[KW_NOT_SUPPORTED]) {
    return false;
  }
  return true;
};

var cateScopeMap = {};
var getScopeOfType = function (otype) {
  var typeid = otype.id || otype.cate || otype;
  if (typeid) {
    if (cateScopeMap.hasOwnProperty(typeid)) {
      return cateScopeMap[typeid];
    } else {
      var cditem = getMenuItemByCdId(typeid);
      var scopevalue = cditem && cditem.scope ? cditem.scope : false;
      cateScopeMap[typeid] = scopevalue;
      return cateScopeMap[typeid];
    }
  }
  return false;
};

/**
 * Get syntax for System Templates
 */
var getTmplSyntax = function (adom, cate, selTmpl, abortCtrl) {
  return new Promise((resolve, reject) => {
    if (selTmpl && selTmpl.name == 'devprof_tbl') {
      reject(); // no syntax for deviceprof table...
      return;
    }

    const tmplTypes = [
      MACROS.USER.PROF.TYPE_DEV,
      MACROS.USER.PROF.TYPE_CERT,
      MACROS.USER.PROF.TYPE_CR,
    ];

    if (tmplTypes.includes(selTmpl.type)) {
      const ocat = getCate(cate);
      const adomName = adom.name || adom;
      let prof_type = '/devprof/';
      let url = 'pm/config';
      url += '/adom/' + adomName;

      if (selTmpl.type === MACROS.USER.PROF.TYPE_DEV) {
        prof_type = '/devprof/';
        const catestr = Array.isArray(ocat.cate) ? ocat.cate[0] : ocat.cate;
        url +=
          prof_type +
          escapeSlash(selTmpl.name) +
          '/' +
          catestr.replace(/ +/g, '/');
      } else if (selTmpl.type === MACROS.USER.PROF.TYPE_CERT) {
        url += '/obj/certificate/template';
      } else if (selTmpl.type === MACROS.USER.PROF.TYPE_CR) {
        url += '/obj/log/threat-weight/';
      }

      let req = {
        method: 'get',
        params: [
          {
            url: url,
            option: 'syntax',
          },
        ],
      };

      const SYNTAX_KEY = prof_type + ocat.cate;
      let syntax = getFromSyntaxCache(adomName, SYNTAX_KEY); //should be identical to cate ID
      if (syntax) {
        resolve(syntax);
      } else {
        fiFmgHttp.forward(req, abortCtrl).then(
          function (resp) {
            const fetch_syntax_id = ocat.cate;
            syntax = resp[0].data[fetch_syntax_id];
            if (syntax) {
              syntax.cate = ocat.cate;
              addToSyntaxCache(adomName, SYNTAX_KEY, syntax);
            }
            resolve(syntax);
          },
          function (err) {
            reject(err);
          }
        );
      }

      req = undefined;
    } else {
      reject();
    }
  });
};

/**
 *@description get&cache device-specific syntax of given device&category
 *@param {object or string} cate - category object from category service or cate name as string
 *@param {object or string} selDev - current selected device object
 *@param {AbortController} abortCtrl - terminate request
 *@return {promise} - a promise of syntax json obj response from server side
 */
var getDeviceSyntax = function (cate, selDev, vdomName, abortCtrl) {
  let ocat = getCate(cate);
  let devName = escapeSlash(selDev.name);
  let vdom = ocat.scope === 'global' ? 'global' : vdomName;

  return fiDevSyntax.get(devName, vdom, ocat.cate, abortCtrl).then((resp) => {
    let syntax = resp || {};

    // attach category name
    syntax.cate = ocat.cate;

    return syntax;
  });
};

var genDefaultOrder = function (opts) {
  var sortable = [];
  for (var opt in opts) {
    if (!opts.hasOwnProperty(opt)) continue;
    sortable.push([opt, opts[opt]]);
  }
  sortable.sort(function (a, b) {
    if (isDefined(a[1].value) && isDefined(b[1].value)) {
      return a[1].value - b[1].value;
    }
    return a[1] - b[1];
  });
  var result = [];
  for (var i = 0, l = sortable.length; i < l; i++) {
    result.push(sortable[i][0]);
  }
  return result;
};

var genCollection = function (
  n_opts,
  uiShowOptions,
  o_opts,
  attrDef,
  fiSelectFormat,
  controlData
) {
  var excludeValues = uiShowOptions ? uiShowOptions.hide_opts_values : null;
  if (!excludeValues) {
    excludeValues = [];
  }
  var collection = [],
    prop,
    cmpidx = 0,
    dpTxt,
    optValue,
    skipped;
  var opts = o_opts || n_opts;

  if (!opts) return collection;

  var showOptOrders =
    uiShowOptions && uiShowOptions.order
      ? uiShowOptions.order
      : genDefaultOrder(opts);

  //see config_schema_services.js
  //we specify 'hide_opts_values' array to excludes these values
  for (var idx = 0, l = showOptOrders.length; idx < l; idx++) {
    prop = showOptOrders[idx];
    if (!opts.hasOwnProperty(prop)) {
      continue;
    }

    optValue =
      opts[prop] && isDefined(opts[prop].value) ? opts[prop].value : opts[prop]; //opts[prop] is server option value...not obj

    if (Array.isArray(excludeValues) && excludeValues.length > 0) {
      skipped = false;
      for (cmpidx = 0; cmpidx < excludeValues.length; cmpidx++) {
        if (excludeValues[cmpidx] === optValue) {
          skipped = true;
          break;
        }
      }
      if (skipped) {
        continue;
      }
    }

    if (!checkAttrSupported(n_opts[prop])) {
      continue; //skip un-supported attributes...
    }

    var showkey = attrDef && attrDef.showkey;
    if (n_opts[prop] && n_opts[prop].text) {
      dpTxt = n_opts[prop].text + (showkey ? ' (' + prop + ')' : '');
    } else if (opts[prop].text) {
      dpTxt = opts[prop].text + (showkey ? ' (' + prop + ')' : '');
    } else {
      dpTxt = typeof prop === 'string' ? capitalize(prop) : prop;
    }

    var itm = {
      name: dpTxt,
      id: prop,
      val: optValue,
    };
    if (fiSelectFormat) {
      merge(itm, {
        text: dpTxt,
        prop: prop,
        id: '' + optValue,
      });
    }

    collection.push(itm);
  }

  // dynatmically control opts according to some logics
  // eg: dvm_cate_def_adminsettings.js
  if (
    uiShowOptions &&
    isFunction(uiShowOptions.opts_dynamic_process) &&
    controlData
  ) {
    uiShowOptions.opts_dynamic_process(collection, controlData, {
      compareDeviceVer: compareDeviceVer,
    });
  }

  return collection;
};

/**
 * Defaul id is the prop NAME of the attr.opts
 *
 * @param opts
 * @param excludeValues
 */
var genFiCollection = function (opts, excludeValues, useValueAsId) {
  var col = genCollection(opts, excludeValues);
  for (var i = 0; i < col.length; i++) {
    col[i].text = col[i].name || col[i].text;
    if (useValueAsId) {
      col[i].id = '' + (col[i].val || col[i].id); // opts[prop].value
    }
  }
  return col;
};

const handleDataSourceHttpResp = function (resp, attr, syntax) {
  const attrObj = Array.isArray(attr) ? attr : [attr];

  const data = {};

  for (let i = 0; i < attrObj.length; i++) {
    const strName = attrObj[i].refid || attrObj[i].id || attrObj[i];
    if (syntax && syntax.attr) {
      const syntaxDef = getAttrSyntaxDef(syntax, strName);
      let cateKey = getDatasrcMkeys(syntaxDef?.oData || syntaxDef);
      data[strName] = mergeGlobalData2Data(resp[i]['data'], cateKey);
    } else {
      data[strName] = resp[i]['data'];
    }
  }
  return data;
};

const getAttrSyntaxDef = (syntax, strName) => {
  if (get(syntax, ['attr', strName])) {
    return get(syntax, ['attr', strName]);
  } else if (strName.includes('/')) {
    const parts = strName.split('/');
    const attr = parts.pop();
    const syntaxPath = parts.reduce((pathStr, part) => {
      pathStr += `subobj.${part}`;
      return pathStr;
    }, '');

    return get(syntax, [syntaxPath, 'attr', attr]);
  }

  return undefined;
};

/**
 *@description get data for data-source attribute
 */
function getDataSourceHttp(
  attr,
  cate,
  device,
  vdom,
  childcate,
  parentmkey,
  resolve,
  reject
) {
  let _devName = device.name || device;
  let _vdomName = deviceStatus.isDeviceNoVdom(device.rtype)
    ? 'root'
    : vdom.name
    ? vdom.name
    : vdom;

  let devFn = fiDevCfgRequests.device(_devName, _vdomName);
  let cateObj = getCate(cate);

  // find out category name
  let catestr = '';
  if (typeof cateObj.cate === 'string') {
    catestr = cateObj.cate;
  } else if (Array.isArray(cateObj.cate)) {
    if (cateObj.cate.length) {
      catestr = cateObj.cate[0];
    }
  }

  if (!catestr) {
    return Promise.reject({
      message: gettext('Missing category information.'),
    });
  }

  // get datasrc for child table!!!
  if (parentmkey) {
    catestr += '/' + parentmkey;
  }
  if (childcate) {
    catestr += '/' + childcate;
  }

  let _currentAdom = fiAdom.current().name;

  // determine if vdom or global vdom
  let scopevalue = cateObj.scope;
  if (!scopevalue) {
    scopevalue = getScopeOfType(cateObj); //ocate.id is cfgitemid
  }

  // process attributes to query
  let attrs = Array.isArray(attr) ? attr : [attr];
  let attrObj = attrs.map((it) => {
    return {
      attr: it.refid || it.id || it,
      current_adom: _currentAdom,
    };
  });

  let prom = null;
  if (scopevalue == 'vdom') {
    prom = devFn.vdom(catestr).datasrc(attrObj);
  } else {
    //global
    prom = devFn.global(catestr).datasrc(attrObj);
  }

  return prom.then(
    (resp) => {
      if (resolve) {
        resolve(handleDataSourceHttpResp(resp, attrs, cateObj));
      } else {
        return resp;
      }
    },
    (err) => {
      if (reject) {
        reject(err);
      } else {
        return err;
      }
    }
  );
}

/**
 *@description get data for data-source attribute
 */
var getDataSource = function (attr, cate, device, vdom, childcate, parentmkey) {
  return new Promise((resolve, reject) => {
    getDataSourceHttp(
      attr,
      cate,
      device,
      vdom,
      childcate,
      parentmkey,
      resolve,
      reject
    );
  });
};

const dsToDataArray = function (data, attr_name, attr_def) {
  let items = [];
  let arr;

  if (attr_name && attr_def) {
    arr = data[attr_name];
    let dsarr = [];
    let what_ds_to_use =
      attr_def.ds_to && attr_def.ds_to_cate ? attr_def.ds_to_cate : attr_def.ds;

    if (!Array.isArray(what_ds_to_use)) {
      dsarr.push(what_ds_to_use);
    } else {
      dsarr = what_ds_to_use;
    }

    const globalDsArr = dsarr.map((ds) => `${ds}(global)`);

    for (const dsstr of [...dsarr, ...globalDsArr]) {
      if (Array.isArray(arr[dsstr])) {
        const dsItems = arr[dsstr].map((item) => ({
          ...item,
          cate: dsstr,
        }));
        items = items.concat(dsItems);
      }
    }
  }
  return items;
};

const getDsID = function (oData, id_field) {
  let _id = id_field ? oData[id_field] : oData.name || oData.id; //in some cases, mkey is ID...
  return '' + _id;
};

const getDsTEXT = function (oData, text_field) {
  let _text = text_field
    ? oData[text_field]
    : oData.name || oData['obj description']; //in some cases, mkey is ID...
  if (oData.cate === 'system interface') {
    _text = formatInterfaceName(oData, oData.name);
  }
  if (Array.isArray(oData[text_field])) {
    _text = oData[text_field].join(',');
  }
  // prevent the issue that obj description is ""
  if (_text === '') {
    _text = getDsID(oData);
  }
  return '' + _text;
};

var dsToFiSelectArray = function (data, attr_name, attr_def) {
  var prop,
    items = [],
    result = [];
  var arr, ii, len;
  var dsstr;

  if (attr_name && attr_def) {
    arr = data[attr_name];

    var dsarr = [];
    var what_ds_to_use =
      attr_def.ds_to && attr_def.ds_to_cate ? attr_def.ds_to_cate : attr_def.ds;

    if (!Array.isArray(what_ds_to_use)) {
      dsarr.push(what_ds_to_use);
    } else {
      dsarr = what_ds_to_use;
    }

    var _dsmkey = '',
      _dsdpkey = '';
    var _dsUUID,
      _itemsMap = {};
    for (var idxDs = 0; idxDs < dsarr.length; idxDs++) {
      dsstr = dsarr[idxDs];
      _dsmkey =
        Array.isArray(attr_def.dsmkey) &&
        attr_def.dsmkey.length === dsarr.length
          ? attr_def.dsmkey[idxDs]
          : false;
      _dsdpkey =
        Array.isArray(attr_def.dsdpkey) &&
        attr_def.dsdpkey.length === dsarr.length
          ? attr_def.dsdpkey[idxDs]
          : false;

      if (attr_def.allow_empty === true) {
        //Add EMPTY value... for clearing up ds..
        items.push({
          id: emptyDsUiValue,
          text: '--' + gettext('None') + '--',
          _oData: '',
        });
      }

      if (Array.isArray(arr[dsstr])) {
        for (ii = 0, len = arr[dsstr].length; ii < len; ii++) {
          //arr[dsstr][ii].cate = dsstr;
          _dsUUID = getDsID(arr[dsstr][ii], _dsmkey);
          if (!_itemsMap[_dsUUID]) {
            items.push({
              id: _dsUUID,
              text: getDsTEXT(arr[dsstr][ii], _dsdpkey),
              cate: arr[dsstr][ii].cate,
              _oData: arr[dsstr][ii],
            });
            _itemsMap[_dsUUID] = true;
          }
        }
      }
    }
  } else {
    for (prop in data) {
      if (!data.hasOwnProperty(prop)) continue;

      arr = data[prop];
      for (ii = 0, len = arr.length; ii < len; ii++) {
        arr[ii].cate = prop;
        items.push(arr[ii]);
      }
    }
  }

  //grouping
  if (Array.isArray(attr_def.ds_group) && attr_def.ds_group.length > 0) {
    var grp, itm;
    var groupedIDs = {};
    for (var g = 0; g < attr_def.ds_group.length; g++) {
      grp = attr_def.ds_group[g];
      result.push({
        id: grp.id,
        text: grp.text,
        cate: '',
      });
      for (var it = 0; it < items.length; it++) {
        itm = items[it];
        if (!groupedIDs.hasOwnProperty(itm.id)) {
          if (isFunction(grp.filter) && grp.filter(itm)) {
            groupedIDs[itm.id] = true;
            result.push(itm);
          }
        }
      }
    }
  } else {
    result = items;
  }

  //sort datasource drop down values by text.
  if (attr_def && attr_def.ui && attr_def.ui.sorting) {
    var default_sorting = function (a, b) {
      if (a.text && b.text) {
        return a.text.localeCompare(b.text);
      } else if (a.text) {
        return 1;
      } else if (b.text) {
        return -1;
      }
    };
    result.sort(default_sorting);
  }

  return result;
};

/**
 *@description parse getDataSource response to an array
 *@param {object} data - responsed ds set from getDataSource
 *@param attr_def attribute syntax def for this attribute...
 */
var dsToArray = function (data, attr_name, attr_def) {
  var prop,
    items = [];
  var arr, ii, len;
  var dsstr;

  if (attr_name && attr_def) {
    arr = data[attr_name];
    var dsarr = [];
    if (!Array.isArray(attr_def.ds)) {
      dsarr.push(attr_def.ds);
    } else {
      dsarr = attr_def.ds;
    }
    for (var idxDs = 0; idxDs < dsarr.length; idxDs++) {
      dsstr = dsarr[idxDs];
      if (Array.isArray(arr[dsstr])) {
        for (ii = 0, len = arr[dsstr].length; ii < len; ii++) {
          arr[dsstr][ii].cate = dsstr;
          items.push(arr[dsstr][ii]);
        }
      }
    }
  } else {
    for (prop in data) {
      if (!data.hasOwnProperty(prop)) continue;

      arr = data[prop];
      for (ii = 0, len = arr.length; ii < len; ii++) {
        arr[ii].cate = prop;
        items.push(arr[ii]);
      }
    }
  }
  return items;
};

var getNgModelName = function (prop, childobjectId, attrModelId) {
  var oDataName = 'attrRec';

  if (childobjectId) {
    oDataName += "['" + childobjectId + "']"; //childobject data..
  }

  if (attrModelId) {
    return oDataName + "['" + prop + "']" + '.' + attrModelId; //e.g. we actually want to ng-model='parsedData['attrId'].category
  } else {
    return oDataName + "['" + prop + "']";
  }
};

var getDSNgModelName = function (prop, childobjectId, isMS, isPath = false) {
  var oDataName = 'attrRec';

  if (childobjectId) {
    oDataName += "['" + childobjectId + "']"; //childobject data..
  }
  // if the current attr syntax type is multiple selectoin
  if (isMS) {
    return isPath ? oDataName : oDataName + "['" + prop + "']";
  }
  return isPath ? oDataName : oDataName + "['" + prop + "'][0].name"; //'attrRec[\'' + prop + '\'][0]';
};

var getSyntaxName = function (prop, childobjectId, syntaxName) {
  var attrSyntaxName = syntaxName || 'syntaxDef';

  if (childobjectId) {
    attrSyntaxName += ".child['" + childobjectId + "']"; //childobject data..
  }

  attrSyntaxName += ".attr['" + prop + "']";

  return attrSyntaxName;
};

var getAttrDef = function (topCatedef, attrId, childobject) {
  if (isString(childobject) && childobject.length > 0) {
    if (
      topCatedef.child &&
      topCatedef.child[childobject] &&
      topCatedef.child[childobject].attr &&
      topCatedef.child[childobject].attr[attrId]
    ) {
      return topCatedef.child[childobject].attr[attrId];
    }
  } else if (topCatedef.attr && topCatedef.attr[attrId]) {
    return topCatedef.attr[attrId];
  }

  return null;
};

/**
 * This function is used to customize syntax convertion
 * @param {*} topCatedef
 * @param {*} attrId
 */
var customizeAttrDef = function (topCatedef, attrId) {
  var fortiguardSynFn = function () {
    // this func is used for syntax convertion on system/fortiguard page
    if (topCatedef.attr && topCatedef.attr[attrId]) {
      var synxDef = cloneDeep(topCatedef.attr[attrId]);
      synxDef.oData.min = parseInt(synxDef.oData.min / 60);
      synxDef.oData.max = parseInt(synxDef.oData.max / 60);
      return synxDef;
    }
  };

  var configFuncs = {
    'webfilter-cache-ttl': fortiguardSynFn,
    'antispam-cache-ttl': fortiguardSynFn,
  };

  if (isFunction(configFuncs[attrId])) {
    return configFuncs[attrId]();
  }

  return null;
};

var getDisplayText = function (data, cfg) {
  var txt = '';

  if (cfg.txtAttr) {
    txt =
      typeof cfg.txtAttr === 'function'
        ? cfg.txtAttr(data, cfg)
        : data[cfg.txtAttr];
  } else if (cfg.keyAttr) {
    txt =
      typeof cfg.keyAttr === 'function'
        ? cfg.keyAttr(data, cfg)
        : data[cfg.keyAttr];
  } else {
    // default use name
    txt = data.name || '';
  }
  return txt;
};
var getKeyValue = function (data, cfg) {
  if (cfg.keyAttr) {
    return typeof cfg.keyAttr === 'function'
      ? cfg.keyAttr(data, cfg)
      : data[cfg.keyAttr];
  }
  return undefined;
};

var getCSS = function (data, cfg) {
  var css = '';

  if (cfg.css) {
    css = typeof cfg.css === 'function' ? cfg.css(data, cfg) : cfg.css;
  }

  return css;
};

/**
 *@description parse getDataSource response to an array
 *@param {object} data - responsed ds set from getDataSource
 *@param {object} oCfg -
 *```
 * {
 *  keyAttr: function(data, cfg) or string of key attribute
 *  txtAttr: function(data, cfg) or string of display text attribute
 *  css: function(data, cfg) or string of css
 * }
 *```
 */
var dsParser = function (data, oCfg) {
  var cfg = oCfg || { keyAttr: 'name', txtAttr: null, css: null };
  var prop,
    items = [];
  var arr, ii, len;
  for (prop in data) {
    if (!data.hasOwnProperty(prop)) continue;
    arr = data[prop];
    for (ii = 0, len = arr.length; ii < len; ii++) {
      items.push({
        name: getDisplayText(arr[ii], cfg),
        css: getCSS(arr[ii], cfg),
        val: getKeyValue(arr[ii], cfg),
        cate: prop,
        _oData: arr[ii],
      });
    }
  }
  return items;
};

/**
 *@description get cli only attributes by given schema and syntax
 *@param {syntax} syntax - all the attributes define for the object.
 *@param {} inused - in used attrs or schema of give object, which contains attributes used by GUI
 *@param {} ignored - ignored attrs or metafield of give object, which contains attributes used by GUI
 *@return {object} attributes not in used by GUI
 */
var getDefaultAdvancedOpts = function (syntax, inused, ignored) {
  var attrs = syntax.attr || {};
  var sa = inused || {};
  var ign = ignored || {};
  var prop;
  var cliAttrs = {};
  for (prop in attrs) {
    if (!attrs.hasOwnProperty(prop)) continue;
    if (sa[prop]) continue;
    if (ign[prop]) continue;
    if (fiDvmSyntaxUtils.isChildTable(attrs[prop])) continue; //do not show child table in <adv-opt></adv-opt>
    if (fiDvmSyntaxUtils.isChildObject(attrs[prop])) continue;

    if (!checkAttrSupported(attrs[prop])) continue;

    cliAttrs[prop] = attrs[prop];
  }

  return cliAttrs;
};

var emptyDsUiValue = '--none--';
var emptyDsJsonValue = null;

var convertArrayOfOneToObj = function (data, propname) {
  if (
    propname &&
    Array.isArray(data[propname]) &&
    data[propname].length === 1
  ) {
    data[propname] = data[propname][0];
  }
};

function mergeGlobalData2Data(respData, cateKey) {
  class Cate {
    constructor(name, data) {
      name = name.toString();
      if (Cate.isGlobal(name)) {
        this._name = name.substring(0, name.indexOf('(global)'));
        this._data = [];
        this._globalData = data || [];
      } else {
        this._name = name;
        this._data = data || [];
        this._globalData = [];
      }
    }
    static isGlobal(name) {
      return name.indexOf('(global)') >= 0;
    }
    isSameCate(name) {
      if (!isString(name)) return false;
      if (this._name === name) return true;
      let sname = this._name.length <= name.length ? this._name : name;
      let lname = this._name.length > name.length ? this._name : name;
      return lname === sname + '(global)';
    }
    get name() {
      return this._name;
    }
    addData(name, data) {
      if (!this.isSameCate(name)) return;
      if (Cate.isGlobal(name)) {
        this._globalData = data || [];
      } else {
        this._data = data || [];
      }
    }
    getMergedData(key) {
      let ret = [],
        dSet = new Set();
      this._data.forEach((d) => {
        d.inAdom = false;
        dSet.add(d[key]);
        ret.push(d);
      });
      this._globalData.forEach((g) => {
        g.inAdom = true; // flag for gui to know if an obj exists in adom level
        if (!dSet.has(g[key])) {
          dSet.add(g[key]);
          ret.push(g);
        } else {
          let found = ret.find((data) => data[key] === g[key]);
          found.inAdom = true;
        }
      });
      return ret;
    }
  }

  let arr = [],
    ret = {};
  if (!isObject(respData)) return ret;
  for (let prop in respData) {
    if (!respData.hasOwnProperty(prop)) continue;
    let found = false,
      idx;
    for (idx = 0; idx < arr.length; idx++) {
      if (arr[idx].isSameCate(prop)) {
        found = true;
        break;
      }
    }
    if (found) {
      arr[idx].addData(prop, respData[prop]);
    } else {
      arr.push(new Cate(prop, respData[prop]));
    }
  }
  for (let i = 0; i < arr.length; i++) {
    let key;
    if (isObject(cateKey)) {
      key = cateKey[arr[i].name] || 'id';
    } else if (isString(cateKey)) {
      key = cateKey;
    } else {
      key = 'id';
    }
    ret[arr[i].name] = arr[i].getMergedData(key);
  }
  return ret;
}

function getDatasrcMkeys(syntax) {
  let ret = {};
  if (!syntax || !Array.isArray(syntax.ref)) return ret;
  syntax.ref.forEach((r) => {
    ret[r.category] = r.mkey;
  });
  return ret;
}

export const fiDvmSyntaxService = {
  addToMgdSyntaxCache: addToMgdSyntaxCache,
  getFromMgdSyntaxCache: getFromMgdSyntaxCache,
  checkAttrSupported: checkAttrSupported,
  getTmplSyntax: getTmplSyntax,
  getDeviceSyntax: getDeviceSyntax,
  getScopeOfType: getScopeOfType,
  getAttrDef: getAttrDef,
  getSyntaxName: getSyntaxName,
  getNgModelName: getNgModelName,
  getDSNgModelName: getDSNgModelName,
  getDefaultAdvancedOpts: getDefaultAdvancedOpts,
  getDataSourceHttp: getDataSourceHttp,
  handleDataSourceHttpResp: handleDataSourceHttpResp,
  getDataSource: getDataSource,
  dsToDataArray: dsToDataArray, //datasource result to Actual table json data...
  dsToArray: dsToArray,
  dsToFiSelectArray: dsToFiSelectArray,
  dsParser: dsParser,
  emptyDsUiValue: emptyDsUiValue,
  emptyDsJsonValue: emptyDsJsonValue,
  genCollection: genCollection,
  genFiCollection: genFiCollection,
  convertArrayOfOneToObj: convertArrayOfOneToObj,
  customizeAttrDef: customizeAttrDef,
  mergeGlobalData2Data: mergeGlobalData2Data,
  getDatasrcMkeys: getDatasrcMkeys,
};
