import { fiAdom } from 'fi-session';
import { isNil, isFunction, isUndefined } from 'lodash';
import { fiCache } from 'kit-cache';
import { fiDvmActionsId } from 'fi-actions';
import { dispatch, fiDevicesAction } from 'fistore';
import { promiseWhen } from 'fiutil';
import { saveWorkspace } from './command_exec';
import { go, goApp } from 'fistore/routing/slice';

const getCommands = () => {
  let commands = {};
  // let call redefin the execute input parameters
  const defaultExecFunction = function (fn) {
    let _cmd = this;
    if (_cmd) {
      let _input = _cmd.m_input;
      let _callback = _cmd.m_callback;
      let _action = fn;
      if (isFunction(_input)) {
        _input = _input();
      }
      if (_action) {
        promiseWhen(_action.apply(this, _input)).then(function () {
          if (isFunction(_callback)) {
            _callback();
          }
        });
      }
    }
  };

  commands[fiDvmActionsId.workspace.save] = {
    key: fiDvmActionsId.workspace.save,
    label: gettext('Save'),
    icon: 'save',
    css: 'tw-text-neutral-0 tw-bg-danger-600',
    show: false,
    disabled: true,
    exec: function () {
      saveWorkspace();
    },
  };

  commands[fiDvmActionsId.device.install.wizard] = {
    key: fiDvmActionsId.device.install.wizard,
    label: gettext('Install Wizard'),
    icon: 'install',
    show: true,
    disabled: false,
    automation_id: 'Install_Wizard',
    exec: async function () {
      const { openDeviceInstallWizard } = await import(
        'react_apps/ra_dvm/actions/dvm_actions'
      );

      defaultExecFunction.call(this, openDeviceInstallWizard);
    },
  };

  commands[fiDvmActionsId.device.add] = {
    key: fiDvmActionsId.device.add,
    label: gettext('Add Device'),
    icon: 'device',
    show: true,
    disabled: false,
    exec: async function () {
      const { addDevice, openImportPolicyWizard } = await import(
        'react_apps/ra_dvm/actions/dvm_actions'
      );

      let callback = commands[fiDvmActionsId.device.add].m_callback;
      addDevice().then(
        function (newAddedDev) {
          if (!isUndefined(newAddedDev)) {
            // run import policy for the new added device
            openImportPolicyWizard(newAddedDev).finally(function () {
              if (isFunction(callback)) {
                callback(this);
              }
            });
          } else {
            if (isFunction(callback)) {
              callback(this);
            }
          }
        },
        function (dismiss) {
          let reason = (dismiss && dismiss.reason) || '';
          if (reason && reason !== 'purchaseLicense' && reason !== 'cancel') {
            if (isFunction(callback)) {
              callback(this);
            }
          }
        }
      );
    },
  };

  commands[fiDvmActionsId.group.create] = {
    key: fiDvmActionsId.group.create,
    label: gettext('Create New Group'),
    icon: 'add',
    show: true,
    disabled: false,
    exec: async function () {
      const { createDeviceGroup } = await import(
        'react_apps/ra_dvm/actions/dvm_actions'
      );

      defaultExecFunction.call(this, createDeviceGroup);
    },
  };

  commands[fiDvmActionsId.group.edit] = {
    key: fiDvmActionsId.group.edit,
    label: gettext('Edit Group'),
    icon: 'edit',
    show: true,
    disabled: false,
    exec: async function () {
      const { editDeviceGroup } = await import(
        'react_apps/ra_dvm/actions/dvm_actions'
      );

      defaultExecFunction.call(this, editDeviceGroup);
    },
  };

  commands[fiDvmActionsId.group.del] = {
    key: fiDvmActionsId.group.del,
    label: gettext('Delete Group'),
    icon: 'delete',
    show: true,
    disabled: false,
    exec: async function () {
      const { deleteDeviceGroup } = await import(
        'react_apps/ra_dvm/actions/dvm_actions'
      );

      defaultExecFunction.call(this, deleteDeviceGroup);
    },
  };

  commands[fiDvmActionsId.group.fgspConfig] = {
    key: fiDvmActionsId.group.fgspConfig,
    label: gettext('FGSP Configuration'),
    icon: 'ffg ffg-connected',
    show: true,
    disabled: false,
    exec: async function () {
      const { openEditFGSPConfig } = await import(
        'react_apps/ra_dvm/actions/dvm_actions'
      );

      defaultExecFunction.call(this, openEditFGSPConfig);
    },
  };

  commands[fiDvmActionsId.remoteFaz.add] = {
    key: fiDvmActionsId.remoteFaz.add,
    label: gettext('Add FortiAnalyzer'),
    icon: 'ffg ffg-disk',
    show: true,
    disabled: false,
    m_callback: () => {
      dispatch(
        fiDevicesAction.remoteFAZAction.fetch.request({
          adomOid: fiAdom.current().oid,
        })
      );
      fiCache.clean('devgrp:data'); // a new group needs to be displayed, so needs to clearn cache
      dispatch(go({ to: '/dvm/main/groups' }));
    },
    exec: async function () {
      const { openAddFAZWizard } = await import(
        'react_apps/ra_dvm/actions/dvm_actions'
      );

      defaultExecFunction.call(this, openAddFAZWizard);
    },
  };

  commands[fiDvmActionsId.device.deviceBlueprint] = {
    key: fiDvmActionsId.device.deviceBlueprint,
    label: gettext('Device Blueprint'),
    icon: 'ffg ffg-profile',
    show: true,
    disabled: false,
    exec: async function () {
      const { openBlueprintTable } = await import(
        'react_apps/ra_dvm/actions/dvm_actions'
      );
      defaultExecFunction.call(this, openBlueprintTable);
    },
  };

  //Table
  commands[fiDvmActionsId.views.table] = {
    key: fiDvmActionsId.views.table,
    label: gettext('Table View'),
    icon: 'ffg ffg-chart-table',
    show: true,
    disabled: false,
    exec: function () {
      dispatch(goApp({ key: 'dvm_device_group_table' }));
    },
  };

  //Map
  commands[fiDvmActionsId.views.map] = {
    key: fiDvmActionsId.views.map,
    label: gettext('Map View'),
    icon: 'ffg ffg-globe',
    show: true,
    disabled: false,
    exec: function () {
      dispatch(goApp({ key: 'dvm_device_map' }));
    },
  };

  //Folder
  commands[fiDvmActionsId.views.folder] = {
    label: gettext('Folder View'),
    icon: 'ffg ffg-folder color-unset',
    disabled: false,
    exec: () => {
      dispatch(goApp({ key: 'dvm_device_folder' }));
    },
  };

  //Ring
  commands[fiDvmActionsId.views.ring] = {
    label: gettext('Ring View'),
    icon: 'ffg ffg-chart-donut',
    disabled: false,
    exec: () => {
      dispatch(goApp({ key: 'dvm_device_ring' }));
    },
  };

  commands[fiDvmActionsId.group.firmwareFdsUpgrade] = {
    label: gettext('Firmware Upgrade'),
    icon: 'dial-up',
    disabled: false,
  };
  commands[fiDvmActionsId.group.ums_capacity] = {
    label: gettext('Auto-Scale Instance Count'),
    icon: 'fortigate-vm',
    disabled: false,
  };

  return commands;
};

/**
 * Returns a standard fi-toolbar command item with specified input and callback
 * @param cid command id
 * @param inputArr (optional) command arguments as Array...
 * @param callback (optional) command callback.
 */
function getCommandButton(cid, input, callback, show = true) {
  const commands = getCommands();
  const cmd = commands[cid];
  cmd.key = cid;
  cmd.m_input = !isUndefined(input) ? input : commands[cid].m_input;
  cmd.m_callback = !isNil(callback) ? callback : commands[cid].m_callback;
  cmd.show = show;
  return cmd;
}

export const fiCommonCommands = {
  getCommand: getCommandButton,
};
