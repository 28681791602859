import { useState } from 'react';

import { NwInput, NwSwitch, NwButton } from '@fafm/neowise-core';
import {
  InputRow,
  InputColumn,
  SectionLabel,
  OkBtn,
  CancelBtn,
} from 'rc_layout';

import { useWizard } from './useWizard';

export const WizardDebugger = ({ state }) => {
  /** ----------------------------------------------------------------------------
   * Context
   * -------------------------------------------------------------------------- */
  const {
    // states
    title,
    steps,
    error,

    // accessors
    renderHorizontalProgress,
    goToNext,
    goToPrevious,
    getTitle,
    setError,
    clearError,
  } = useWizard();

  /** ----------------------------------------------------------------------------
   * States
   * -------------------------------------------------------------------------- */
  const [toggleDebugger, setToggleDebugger] = useState(false);
  const [toggleFullStates, setToggleFullStates] = useState(false);
  const [toggleProgress, setToggleProgress] = useState(false);

  /** ----------------------------------------------------------------------------
   * Handlers
   * -------------------------------------------------------------------------- */
  const toggle = (setter) => (event) => setter(event.target.checked);

  /** ----------------------------------------------------------------------------
   * Rendering
   * -------------------------------------------------------------------------- */
  const renderStates = () => {
    return (
      <>
        {/* States */}
        <SectionLabel>{gettext('Wizard States')}</SectionLabel>
        <InputRow label={gettext('Title')}>{title}</InputRow>
        <InputRow label={gettext('Formatted title')}>{getTitle()}</InputRow>
        <InputRow label={gettext('Wizard Steps')}>
          {steps.map((stepKey, index) => (
            <div key={stepKey}>
              {index !== 0 && <div className='fi-col-8'></div>}
              {<InputColumn>{stepKey}</InputColumn>}
            </div>
          ))}
        </InputRow>
        <InputRow label={<b>{gettext('Current Step')}</b>}>
          <b>{state.activeStep}</b>
        </InputRow>
        <InputRow label={gettext('Current Step Index')}>
          {state.activeStepIndex}
        </InputRow>
        <InputRow label={<b>{gettext('Furthest Step')}</b>}>
          <b>{state.mostAdvStep}</b>
        </InputRow>
        <InputRow label={gettext('Furthest Step Index')}>
          {state.mostAdvStepIndex}
        </InputRow>
        <InputRow label={gettext('Full Wizard States')}>
          <NwSwitch
            checked={toggleFullStates}
            onChange={toggle(setToggleFullStates)}
          ></NwSwitch>
        </InputRow>
        {toggleFullStates && (
          <pre>{'States: ' + JSON.stringify(state, null, 4)}</pre>
        )}
      </>
    );
  };

  const renderAccessors = () => {
    return (
      <>
        {/* Accessors */}
        <SectionLabel>{gettext('Accessors')}</SectionLabel>
        <InputRow label={gettext('Go to Next')}>
          <OkBtn onClick={() => goToNext()}>{gettext('Next') + ' >'}</OkBtn>
        </InputRow>
        <InputRow label={gettext('Go to Previous')}>
          <CancelBtn onClick={() => goToPrevious()}>
            {'< ' + gettext('Previous')}
          </CancelBtn>
        </InputRow>

        <InputRow label={gettext('Jump to')}>
          <NwSwitch
            checked={toggleProgress}
            onChange={toggle(setToggleProgress)}
          ></NwSwitch>
        </InputRow>
        {toggleProgress && renderHorizontalProgress()}

        <InputRow label={gettext('Set Error')}>
          <NwInput
            value={error}
            onChange={(event) => setError(event.target.value)}
          ></NwInput>
        </InputRow>

        <InputRow label={gettext('Clear Error')}>
          <NwButton onClick={clearError}>{gettext('Clear')}</NwButton>
        </InputRow>
      </>
    );
  };

  const renderContent = () => {
    if (!toggleDebugger) return;

    return (
      <>
        {renderStates()}

        {renderAccessors()}
      </>
    );
  };

  return (
    <div className='tw-w-full tw-p-4 tw-mt-4 fi-ctrl fi-container pm-body'>
      <InputRow label={<b>{gettext('Wizard Debugger')}</b>}>
        <NwSwitch
          checked={toggleDebugger}
          onChange={toggle(setToggleDebugger)}
        ></NwSwitch>
      </InputRow>

      {renderContent()}
    </div>
  );
};
