import React from 'react';
import { ProForm } from '@fafm/neowise-pro';
import { NwButton } from '@fafm/neowise-core';
import { UserDisplayPopover } from './UserDisplayPopover';
import { MultiUebaEnduser } from './MultiUebaEnduser';
import { MultiUebaEndpoint } from './MultiUebaEndpoint';
import { IncidentEndpoint } from './IncidentEndpoint';
const { Header, Body, Footer } = ProForm;

export const ShowUebaInfo = ({ title, data = [], $opener }) => {
  const cancel = () => {
    $opener?.reject();
  };

  return (
    <>
      <Header>{title || gettext('Endpoint Information')}</Header>
      <Body>
        {data.map((row, idx) => (
          <div key={idx}>
            {row.isValidUebaUser ? (
              <UserDisplayPopover
                name={row.name}
                euid={row.euid}
                avatarUrl={row.avatarUrl}
                proxiedServer={row.proxiedServer}
                proxiedAdom={row.adom}
              />
            ) : (
              <MultiUebaEnduser
                epid={row.epid}
                proxiedServer={row.proxiedServer}
                proxiedAdom={row.adom}
              />
            )}
            {row.isValidUebaEndpoint ? (
              <IncidentEndpoint
                epid={row.epid}
                euid={row.euid}
                hideFirstRow={true}
                proxiedServer={row.proxiedServer}
                proxiedAdom={row.adom}
              />
            ) : (
              <MultiUebaEndpoint
                euid={row.euid}
                proxiedServer={row.proxiedServer}
                proxiedAdom={row.adom}
              />
            )}
          </div>
        ))}
      </Body>
      <Footer>
        <NwButton onClick={cancel}>{gettext('Close')}</NwButton>
      </Footer>
    </>
  );
};
