import { fiHttpGet } from 'fi-http';

export async function fetchRemoteFazModuleDisable() {
  try {
    const data = await fiHttpGet('/p/util/mods/');
    return data;
  } catch (err) {
    console.error('unable to get module availability info remotely.');
    // console.error(err);
    return {};
  }
}
