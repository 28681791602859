import Momenttz from 'moment-timezone';
import moment from 'moment';
import { fiSysConfig } from 'fi-session';
import { formatAdomTime, getZonePath } from './formatZonedTime';
import { dateStrToArr } from 'kit-time';
import { fiFmgHttp, fiHttpGet } from 'fi-web/fi-http';

const getServerTimeUrl = '/p/fortiview/get_server_time/';

export function serverNowMoment() {
  return Momenttz().tz(fiSysConfig.current().timezone.timezonename);
}

export const unixToLocalString = (timestamp, format = 'MM-DD HH:mm') => {
  return formatAdomTime({
    unix: timestamp,
    format,
  });
};

export const unixMsToLocalMoment = (timestamp, timezone) => {
  const tz = timezone?.timezonename || getZonePath();
  // Use 'x' formatter so both int and str are the same.
  // If use moment(timestamp) directly, depending on data type,
  // the result would be different.
  // Note that small 'x' means js style (milliseconds).
  return moment.tz(timestamp, 'x', tz);
};

// serverTimeObj specifically refers to an object
// with start_time and end_time arrays. Example:
// {
//   "start_time": [ 2019, 11, 6, 15, 32, 36, 2, 310, 0 ],
//   "end_time": [ 2019, 12, 11, 15, 32, 36, 2, 345, 0 ],
// }
// The returned value will be of the same structure,
// but each member will be a "moment" object.
export const serverTimeObjToMoment = (serverTime) => {
  return {
    start_time: serverTimeToMoment(serverTime.start_time),
    end_time: serverTimeToMoment(serverTime.end_time),
  };
};

// Converts an array like [ 2019, 11, 6, 15, 32, 36, 2, 310, 0 ]
// to moment object.
//
// The behaviour may seem strange for non-existent time if moment is
// running in a timezone with daylight saving changes.
// At time of writing the observed behaviour in pacific time zone is:
// moment([2019,2,10,2,30]).format()  =>  "2019-03-10T03:30:00-07:00"
// moment([2019,10,3,1,30]).format()  =>  "2019-11-03T01:30:00-07:00"
export const serverTimeToMoment = (serverTime) => {
  if (moment.isMoment(serverTime)) return serverTime.clone();
  if (typeof serverTime === 'string') {
    serverTime = serverTime.split(',').map((x) => parseInt(x, 10));
  }
  var time = [].concat(serverTime); // clone avoid polluting the input.
  time[1] = time[1] - 1;
  return moment(time).tz(getZonePath(), true);
};

// Based on known server timezone, estimate server's time
// using browser's RealTimeClock. There may be a difference
// from the real server clock, due to clock inaccuracy.
// A correction may be used.
export const estimatedServerMoment = (correctionMs = 0) => {
  return moment().tz(getZonePath()).add(correctionMs, 'ms');
};

// Send a request to server to ask its time.
// Half of network roundtrip time is added to estimate current server time.
// (Because server response precision is 1 second, net delay is
//  only meaningful if the roundtrip is more than 2 seconds.)
export const getServerMomentRemotely = () => {
  const reqTime = performance.now();
  return fiHttpGet(getServerTimeUrl, {
    params: { unit: 'min', n: 1, zone: getZonePath() },
  }).then(function (resp) {
    const respTime = performance.now();
    const timeArr = resp.end_time.slice(0, 6);
    timeArr[1] -= 1; // convert month to 0 based, for use in JS Date/Moment

    const netDelay = (respTime - reqTime) / 2.0;
    return moment(timeArr)
      .tz(getZonePath(), true /*keep time*/)
      .add(netDelay, 'ms');
  });
};

// ret = serverClockTime - localClockServerTime,
// therefore localClockServerTime + ret => serverClockTime.
// This result can be used to correct estimatedServerMoment.
export const getLocalTimeDiffFromServer = () => {
  return getServerMomentRemotely().then(function (serverMoment) {
    const diff = serverMoment.diff(estimatedServerMoment());
    return diff;
  });
};

export const getServerTimeByTimePeriod = (timePeriod) => {
  if (timePeriod && timePeriod.unit === 'custom') {
    return new Promise((resolve) => {
      resolve({
        start_time: _formatTimeArray(timePeriod.start),
        end_time: _formatTimeArray(timePeriod.end),
      });
    });
  }
  return fiHttpGet(getServerTimeUrl, {
    params: {
      unit: timePeriod.unit,
      n:
        typeof timePeriod.num === 'undefined'
          ? timePeriod.lastN
          : timePeriod.num,
      startTime:
        timePeriod.end && timePeriod.end.length
          ? timePeriod.end.slice(0, 6).join(',')
          : '',
      endTime:
        timePeriod.start && timePeriod.start.length
          ? timePeriod.start.slice(0, 6).join(',')
          : '',
      zone: getZonePath(),
    },
  });
};

export const getFortiGateTimezoneByOid = (deviceOid) => {
  return fiFmgHttp.post({
    method: 'getTimezone',
    url: '/gui/adom/dvm/device/timezone',
    params: {
      deviceOid,
    },
  });
};

//============ internal functions ==============
function _formatTimeArray(time) {
  if (typeof time === 'string') {
    return dateStrToArr(time);
  }
  return time;
}
