import { ProToolkit } from '@fafm/neowise-pro';
import { fiHttpPost } from 'fi-http';
import { UpgradeLicense } from 'react_apps/ra_dashboard/widgets/system_widgets/LicenseInfo/UpgradeLicense';

export const openUpdateLicenseModal = (license, isValid) => {
  return ProToolkit.openModal(
    <UpgradeLicense licenseStatus={license} isLicenseValid={isValid} />,
    { closable: false }
  ).result;
};

export function loadLicense() {
  return fiHttpPost('/cgi-bin/module/flatui_auth', {
    url: '/gui/vmlicense/status',
    method: 'get',
  }).then((resp) => {
    return resp.result[0].data;
  });
}

export function isLicenseValid(license) {
  // Only check license for VMs
  if (!MACROS.SYS.IS_VM || MACROS.SYS.NOVMLICENSE || MACROS.SYS.HAVE_ONDEMAND) {
    return true;
  }

  return (
    [
      MACROS.SYS.VM_LIC_STATUS_EXPIRED,
      MACROS.SYS.VM_LIC_STATUS_MGMT_IP_NOMATCH,
      MACROS.SYS.VM_LIC_STATUS_LICENSE_DUPLICATE,
    ].indexOf(license['vmlic_status']) === -1
  );
}
