import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { ProLayout } from '@fafm/neowise-pro';

import { fiStore } from 'fistore';
import { setContentMenuLayout } from 'fistore/routing/slice';
import {
  getCurrentApp,
  getContentMenuTree,
  getCurrentContentMenuLayout,
  getAllApps,
} from 'fistore/routing/selectors';
import { getAppKey, getAppChildren } from 'fistore/routing/utils';
import { getPropAsFn } from 'fiutil/selector';
import { treeMap } from 'fiutil/tree';
import { useNavigate } from 'react-router-dom';
import { uniqueId } from 'lodash';

const RIGHT_MAX_PADDING = 0;
const MENU_DIVIDER = '-';
export const CONTENT_MENU_RELOAD_KEY = 'contentMenuReloadKey';

const app2ContentMenuItem = (state) => (node, children) => {
  const key = getAppKey(node);
  if (key === MENU_DIVIDER) return { isDivider: true };
  const app = getAllApps(state)[key];
  const disabled = getPropAsFn(app.disabled, false, app, state);
  return {
    key: app.key,
    state: app.state,
    path: app.path,
    stateParams: app.stateParams,
    noParamInherit: app.noParamInherit,
    reloadState: app.reloadState,
    label: getPropAsFn(app.label, ''),
    icon: getPropAsFn(app.icon, ''),
    iconLibrary: getPropAsFn(app.iconLibrary, ''),
    ...(children ? { items: children } : null),
    onClick: app.onClick,
    disabled,
  };
};

export function ContentMenu({
  menuId,
  layout,
  rightMaxPadding = RIGHT_MAX_PADDING,
  onMenuResized,
  allowToggleDirection = false,
  onToggleMenuDirection,
  updateStore = true,
  collapseItemOnResize = true,
  menuItemActions,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentApp = useSelector(getCurrentApp);
  const contentMenuTree = useSelector(getContentMenuTree);
  const contentMenuLayout =
    useSelector(getCurrentContentMenuLayout) || 'horizontal';
  // Don't use useSelector which causes frequent re-render!
  const state = fiStore.getState();

  const items = useMemo(() => {
    return treeMap(getAppChildren)({
      items: contentMenuTree,
      postFn: app2ContentMenuItem(state),
    });
  }, [contentMenuTree]);

  /** ----------------------------------------------------------------------------
   * Event handlers
   * -------------------------------------------------------------------------- */
  const toggleMenuDirection = useCallback(
    (isHorizontal) => {
      onToggleMenuDirection && onToggleMenuDirection(isHorizontal);
      if (updateStore) {
        dispatch(
          setContentMenuLayout(isHorizontal ? 'horizontal' : 'vertical')
        );
      }
    },
    [onToggleMenuDirection, dispatch]
  );

  const contentMenuItemOnClick = useCallback((item) => {
    if (item.path) {
      return navigate(item.path, {
        state: { [CONTENT_MENU_RELOAD_KEY]: uniqueId() },
      });
    }
  }, []);

  if (!items || items.length === 0) return null;
  // currentApp might be undefined because if it's run before
  // trySetCurrentState(which is inside a useEffect)
  return (
    <ProLayout.Menu
      menuId={menuId || 'main-content-menu'}
      menuItems={items}
      menuClassName={cn('main-content-menu')}
      layout={layout || contentMenuLayout}
      selectedMenuItemKey={currentApp?.key}
      onMenuResized={onMenuResized}
      collapseItemOnResize={collapseItemOnResize}
      allowToggleDirection={allowToggleDirection}
      onToggleMenuDirection={toggleMenuDirection}
      rightMaxPadding={rightMaxPadding}
      menuItemOnClick={contentMenuItemOnClick}
      menuFooterRender={() => null}
      hideHMenuIcons={true}
      menuItemActions={menuItemActions}
    />
  );
}
