import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import { SocketActions } from 'fi-websocket';
import { callPromiseAction, getDataFromResponse } from '../../utils';
import { getSessionAdomOid } from 'fistore/session/adom/selectors';
const { NOTIFY_REMOVED_ACTION } = SocketActions;

const makeRemoteFAZAction = makeCollectionAction('device')(processRemoteFAZ);

export function* watchRemoteFAZ() {
  yield takeLatest(
    [actions.fetchRemoteFAZAction.type, actions.remoteFAZAction.fetch.REQUEST],
    fetchRemoteFAZ
  );
  yield takeEvery(NOTIFY_REMOVED_ACTION, makeRemoteFAZAction);
}

export function* fetchRemoteFAZ(action) {
  yield callPromiseAction(action, function* () {
    const adomOid =
      action.payload?.adomOid || (yield select(getSessionAdomOid));

    yield put(actions.remoteFAZAction.fetch.start());

    const resp = yield call(api.fetch_remote_faz, adomOid);

    const data = getDataFromResponse(resp);

    yield put(actions.remoteFAZAction.fetch.success(data));
  });
}

function makeCollectionAction(collection) {
  return (processFn) => {
    return function* (action) {
      if (action.payload.collection !== collection) return;

      yield processFn(action);
    };
  };
}

function* processRemoteFAZ({ type, payload }) {
  const { id } = payload;

  const getMethod = () => (type === NOTIFY_REMOVED_ACTION ? 'delete' : '');

  if (type === NOTIFY_REMOVED_ACTION) {
    yield put(actions.remoteFAZAction.record[getMethod()]({ oid: id }));
  }
}
