import { go } from 'fistore/routing/slice';
import { dispatch } from 'fistore';
const goUrl = (url) => dispatch(go({ to: url }));

export default {
  [MACROS.NOTICE.NOTICE_TYPE_UNREAD_OUTBREAK_ALERT]: {
    message: ({ params: { count } }) => {
      return ngettext(
        '%s new outbreak report',
        '%s new outbreak reports',
        count
      ).printf([count]);
    },
    exec: () => {
      goUrl('/fortisoc/soar/outbreakalerts');
    },
  },
};
