import React, { useCallback } from 'react';
import { FieldArray, useFieldValue } from 'formik';
import PropTypes from 'prop-types';
import { isFunction, isNil } from 'lodash';
import { FormRowGroup } from 'rc_layout';
import { NwIconButton } from '@fafm/neowise-core';

/**
 * Clone children from each row and provide extra props to each child of the row
 */
export const FmkFieldArrayRow = ({ item, index, children, render }) => {
  if (isFunction(render)) {
    return render({ item, index });
  }

  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // Add item and index as props to each child component
      return React.cloneElement(child, { item, index });
    }
    return child;
  });
};

FmkFieldArrayRow.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  index: PropTypes.number.isRequired,
};

export const FmkFieldArray = ({
  // Initial value for a row
  initVal = {},
  // All row data as an array
  items,
  // Name of this array in the formik values object model
  name,
  // Maximum number of items
  maxLength,
  // Automation id for add button
  addBtnAutoId = '%s-add-btn',
  // Automation id for delete button
  delBtnAutoId = '%s-del-btn',
  // Formatter for button automation id
  btnAutoIdFormatter,
  // Show add button
  allowAdd = true,
  getShowAddBtnCondition = defaultShowAddBtnCondition,
  // Show delete button
  allowDelete = true,
  getShowDeleteBtnCondition = defaultDeleteBtnCondition,
  // Extra class names for form row
  className,
  // Disable the add button if diabled
  disabled = false,
  render,
  children,
  showAddNewEntryBtn = false,
  addEntryBtnText = gettext('Click here to add a new entry.'),
}) => {
  const [value] = useFieldValue(name);
  items = items || value;

  const defaultAutoIdFormatter = (autoId, index) => autoId.printf([index]);
  const formatAutoId = useCallback(
    (autoId, index) => {
      return btnAutoIdFormatter
        ? btnAutoIdFormatter(autoId, index)
        : defaultAutoIdFormatter(autoId, index);
    },
    [btnAutoIdFormatter]
  );

  return (
    <FieldArray name={name}>
      {(arrayHelpers) =>
        !items?.length
          ? showAddNewEntryBtn && (
              <nw-button
                automation-id={formatAutoId(addBtnAutoId, 0)}
                onClick={() => arrayHelpers.push(initVal)}
              >
                {addEntryBtnText}
              </nw-button>
            )
          : items.map((item, index) => (
              <FormRowGroup key={index} className={className}>
                {/* Row */}
                <FmkFieldArrayRow item={item} index={index} render={render}>
                  {children}
                </FmkFieldArrayRow>

                {/* Add/delete buttons */}
                <div
                  className='fi-col-2'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {/* Add */}
                  {allowAdd && getShowAddBtnCondition(items, index) && (
                    <NwIconButton
                      name='add'
                      label={gettext('Add')}
                      automation-id={formatAutoId(addBtnAutoId, index)}
                      onClick={() => {
                        (isNil(maxLength) ? true : items.length < maxLength) &&
                          arrayHelpers.push(initVal);
                      }}
                      // disabled the add button after reaching maxLength - 1
                      disabled={
                        disabled ||
                        (!isNil(maxLength) && items.length >= maxLength)
                      }
                    ></NwIconButton>
                  )}
                  {/* Delete */}
                  {allowDelete && getShowDeleteBtnCondition(items, index) && (
                    <NwIconButton
                      name='close'
                      label={gettext('Remove')}
                      automation-id={formatAutoId(delBtnAutoId, index)}
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  )}
                </div>
              </FormRowGroup>
            ))
      }
    </FieldArray>
  );
};

function defaultShowAddBtnCondition(items, index) {
  return index === items.length - 1;
}

function defaultDeleteBtnCondition(items, index) {
  return items.length > 1 || index !== 0;
}

FmkFieldArray.propTypes = {
  initVal: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
  ),
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  addBtnAutoId: PropTypes.string,
  delBtnAutoId: PropTypes.string,
  btnAutoIdFormatter: PropTypes.func,
  allowAdd: PropTypes.bool,
  allowDelete: PropTypes.bool,
};
