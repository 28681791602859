import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import { isFunction } from 'lodash';
import { SelectPane } from '@fafm/filego2';
import { NwInput, NwIcon } from '@fafm/neowise-core';

export { AutocompleteInput };

const AutocompleteInput = forwardRef(
  (
    {
      dataSource, // [string|object] | Promise | Function
      createCommand, // undefined | [] | Function
      editCommands, // undefined | [] | Function
      autoCompleteFormats = [
        {
          trigger: '$',
          prefix: '$(',
          suffix: ')',
        },
        {
          trigger: '{{',
          prefix: '{{',
          // No suffix
          suffix: false,
        },
      ],
      selectPaneProps,
      prefixIconProps = {
        name: 'search-dollar',
        library: 'fa-solid',
        label: gettext('This field supports variable.'),
      },
      onSelectionToggle = null, // trigger event when select pane opens/closes
      children = null,
      onNativeKeydown: onKeydown = undefined,
      suffixView,
      onBeforeOpenPanel,
      value,
      ...inputElProps
    },
    parentRef
  ) => {
    const ref = useRef({
      inputDiv: undefined, // for select pane to attach
      inputEl: undefined,
      inputTarget: undefined, // for getting immediate inputbox value
      selectPane: undefined,
      selectPaneView: undefined,
      inputLastSelectionStart: -1,
    });
    const currentFormat = useRef(autoCompleteFormats[0]);
    const getThis = () => ref.current;

    useImperativeHandle(parentRef, () => ref?.current?.inputEl);

    ref.current.sharedSource = useMaybeSameSource(dataSource, () => {
      getThis().selectPane.load();
    });

    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    // init select pane
    useEffect(() => {
      const selectAttrs = withDefault(
        {
          idAttr: 'id',
        },
        selectPaneProps
      );

      const selectPane = (getThis().selectPane = new SelectPane(
        getThis().selectPaneView,
        {
          searchFn: makeSearchFn(selectAttrs),
          formatChoiceHTML: makeFormatChoiceHTML(currentFormat.current),
          ...selectPaneProps,
          source: toValidSourceFn(
            // do not directly pass source fn so it uses updated callback
            () => getThis().sharedSource.source(),
            selectAttrs
          ),
          refElement: getThis().inputDiv,
          multipleSelect: false,
          createNewCommands: makeReloadWhenResolveExec(
            toSelectPaneCommands(createCommand, {
              icon: 'add',
              text: gettext('Create New'),
            })
          ),
          editCommands: makeReloadWhenResolveExec(
            toSelectPaneCommands(editCommands, {
              icon: 'edit',
              text: gettext('Edit'),
            })
          ),
          onChange: (id) => {
            if (isFunction(onSelectionToggle)) onSelectionToggle(false);
            onSelectOptionId(id);
          },
        }
      ));

      function makeReloadWhenResolveExec(cmds) {
        if (!Array.isArray(cmds)) return;

        const makeLoadAndMark = (exec) => {
          return async (...args) => {
            const maybeId = await exec.apply(null, args);

            getThis().sharedSource.notifyOtherSourceUpdate();

            return maybeId;
          };
        };

        return cmds.map((cmd) => {
          return {
            ...cmd,
            exec: makeLoadAndMark(cmd.exec),
          };
        });
      }

      function onSelectOptionId(id) {
        // set text to input box
        commitAutoCompleteText(id);

        // clear selection since we are doing auto complete not select
        selectPane.setSelected([]);
      }

      selectPane.load();
    }, []);

    const getLastTriggerIndex = (str) => {
      return autoCompleteFormats
        .map((c) => ({ pos: str.lastIndexOf(c.trigger), format: c }))
        .reduce(
          (acc, val) => {
            if (val.pos > acc.pos) {
              return val;
            }
            return acc;
          },
          {
            pos: -1,
            format: null,
          }
        );
    };

    function commitAutoCompleteText(text) {
      const { inputLastSelectionStart, inputEl, inputTarget } = getThis();
      if (!text || inputLastSelectionStart < 1) return;
      const inputBoxCurrentText = String(inputTarget.value || '');

      // get auto complete replace range by cursor position
      const [start, end] = (function getReplaceRangeByCursorPosition(
        text,
        cursorPos
      ) {
        if (!currentFormat.current.suffix) {
          const textBeforeCursor = text.substring(0, cursorPos);
          const lastIndex = textBeforeCursor.lastIndexOf(
            currentFormat.current.trigger
          );
          return [lastIndex, cursorPos];
        }
        const result = [-1, -1];

        // find triggerChar without close tags before cursor
        const textBeforeCursor = text.substring(0, cursorPos);
        const lastMatch = indexOf(
          textBeforeCursor,
          [currentFormat.current.trigger, currentFormat.current.suffix],
          true
        );
        if (lastMatch?.searchString === currentFormat.current.trigger) {
          result[0] = lastMatch.index;
          result[1] = cursorPos;
        }

        // find closeChars after cursor
        const textAfterCursor = text.substring(cursorPos);
        const firstMatch = indexOf(textAfterCursor, [
          currentFormat.current.trigger,
          currentFormat.current.suffix,
        ]);
        if (firstMatch?.searchString === currentFormat.current.suffix) {
          result[1] =
            firstMatch.index + currentFormat.current.suffix.length + cursorPos;
        }

        return result;
      })(inputBoxCurrentText, inputLastSelectionStart);

      if (start < 0) return;

      inputTarget.value =
        inputBoxCurrentText.substring(0, start) +
        `${currentFormat.current.prefix || ''}${text}${
          currentFormat.current.suffix || ''
        }` +
        inputBoxCurrentText.substring(end);
      setInputValue(inputTarget.value);
      inputElProps.onChange?.({ target: inputTarget });
      inputEl.focus();
    }

    function openSelectPane(isOpen = true) {
      const { selectPane } = getThis();
      if (selectPane.getIsOpen() !== isOpen) {
        if (isFunction(onSelectionToggle)) onSelectionToggle(true);
        selectPane.toggleOpen(isOpen);
      }
    }

    function keydownHandleAfterValueChanged(key) {
      if (
        [
          'Tab',
          'CapsLock',
          'Shift',
          'Control',
          'Meta',
          'Alt',
          'ArrowLeft',
          'ArrowRight',
          'ArrowUp',
          'ArrowDown',
        ].includes(key)
      )
        return;
      const { inputEl, inputTarget } = getThis();
      if (!inputEl) return;
      const inputBoxCurrentText = String(inputTarget.value || '');
      const { selectionStart } = inputEl.getSelectionRange(); //cursor position

      getThis().inputLastSelectionStart = selectionStart; // store cursor position for autocomplete to replace

      const strFromTriggerToCursor = (() => {
        const textBeforeCursor = inputBoxCurrentText.substring(
          0,
          selectionStart
        );

        const { pos: triggerIndex, format: currChar } =
          getLastTriggerIndex(textBeforeCursor);
        currentFormat.current = currChar;
        if (triggerIndex < 0) return '';

        return textBeforeCursor.substring(triggerIndex);
      })();

      const isPendingAutoComplete =
        currentFormat.current &&
        (currentFormat.current.suffix
          ? !!strFromTriggerToCursor &&
            !strFromTriggerToCursor.includes(currentFormat.current.suffix)
          : !!strFromTriggerToCursor &&
            strFromTriggerToCursor.endsWith(currentFormat.current.trigger));

      switch (key) {
        case 'Escape':
          openSelectPane(false);
          return;
        default:
          /*
          if have triggerChar before cursor and there is no closing tag
          1. open selectPane
          2. set searched text
        */
          openSelectPane(isPendingAutoComplete);

          if (isPendingAutoComplete) {
            // TODO: triggerSeach(strFromTriggerToCursor.substring(1));
          }
          return;
      }
    }

    // use addEventListener or stopPropagation does not work to prevent drawer from closing for onKeyDown
    const onInputKeyDown = (event) => {
      const { key, target } = event;
      event.stopPropagation();
      getThis().inputTarget = target;

      if (isFunction(onBeforeOpenPanel)) onBeforeOpenPanel(event);
      //timeout so inputbox value is updated when call handler
      setTimeout(() => {
        keydownHandleAfterValueChanged(key);
        if (isFunction(onKeydown)) onKeydown(event);
      }, 50);
    };

    return (
      <div className='tw-grow'>
        <div ref={(ref) => (getThis().inputDiv = ref)}>
          <NwInput
            ref={(ref) => (getThis().inputEl = ref)}
            onKeyDown={onInputKeyDown}
            prefix={<NwIcon {...prefixIconProps} />}
            suffix={suffixView}
            value={inputValue}
            {...inputElProps}
          >
            {children}
          </NwInput>
        </div>
        <div
          ref={(ref) =>
            !getThis().selectPaneView && (getThis().selectPaneView = ref)
          }
        />
      </div>
    );
  }
);
AutocompleteInput.displayName = 'AutocompleteInput';

function withDefault(defaultValues, obj = {}) {
  return Object.keys(defaultValues).reduce(
    (ret, prop) => {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        ret[prop] = obj[prop];
      }
      return ret;
    },
    { ...defaultValues }
  );
}

function toValidSourceFn(dataSource, { idAttr }) {
  return async () => {
    const resolved = await callOrReturn(dataSource);
    return (resolved || []).map((opt) => {
      if (typeof opt === 'string') {
        return {
          [idAttr]: opt,
        };
      }
      return opt;
    });
  };
}

function toSelectPaneCommands(cmd, defaultStruct) {
  if (Array.isArray(cmd)) return cmd;
  if (typeof cmd === 'function') {
    return [
      {
        ...defaultStruct,
        exec: cmd,
      },
    ];
  }
}

function makeFormatChoiceHTML(currentFormat) {
  return (item, hiliter = (text) => text) => {
    return hiliter(
      `${currentFormat.prefix || ''}${item.id}${currentFormat.suffix || ''}`
    );
  };
}

function makeSearchFn({ idAttr }) {
  return (item, searchText) => {
    return [idAttr].some((attr) => {
      return searchText(item[attr]);
    });
  };
}

function indexOf(string, searchStrings, findLastIndex) {
  const existMatches = searchStrings
    .map((searchString) => {
      return {
        index: string[findLastIndex ? 'lastIndexOf' : 'indexOf'](searchString),
        searchString: searchString,
      };
    })
    .filter(({ index }) => {
      return index > -1;
    })
    .sort((a, b) => {
      return a.index - b.index;
    });

  return findLastIndex
    ? existMatches[existMatches.length - 1]
    : existMatches[0];
}

let cachedSourceMap; //fn -> { count, promise, source, callbacks }

function makeSharedSourceForThisUpdate(source, onSourceUpdate) {
  let shared = cachedSourceMap?.get(source);

  if (!shared) {
    if (!cachedSourceMap) {
      cachedSourceMap = new Map();
    }

    shared = {
      // count: 0,
      callbacks: [],
      source: () => {
        return shared.promise || (shared.promise = callOrReturn(source));
      },
      promise: undefined,
    };
    cachedSourceMap.set(source, shared);
  }

  // ++shared.count;
  shared.callbacks.push(onSourceUpdate);

  const notifyOtherSourceUpdate = () => {
    shared.promise = null;
    shared.callbacks.forEach((onChange) => {
      onChange !== onSourceUpdate && onChange();
    });
  };

  const unlinkFromGlobalRef = () => {
    // if (--shared.count === 0) {
    if (cachedSourceMap) {
      cachedSourceMap.delete(source);
      if (!cachedSourceMap.size) {
        cachedSourceMap = null;
      }
    }
  };

  const unsubscribeUpdate = () => {
    const index = shared.callbacks.findIndex((cb) => cb === onSourceUpdate);
    shared.callbacks.splice(index, 1);
  };

  return {
    source: shared.source,
    notifyOtherSourceUpdate,
    unlinkFromGlobalRef,
    unsubscribeUpdate,
  };
}

function useMaybeSameSource(source, onSourceUpdate) {
  const shared = makeSharedSourceForThisUpdate(source, onSourceUpdate);

  useEffect(shared.unlinkFromGlobalRef);

  useEffect(() => shared.unsubscribeUpdate, [source]);

  return shared;
}

function callOrReturn(maybeFn) {
  return typeof maybeFn === 'function' ? maybeFn() : maybeFn;
}
