import React from 'react';
import { ProForm, ProToolkit } from '@fafm/neowise-pro';
import { NwButton } from '@fafm/neowise-core';
import { fiAdom } from 'fi-session';
import { fiFmgHttp } from 'fi-http';
import { fiMessageBox } from 'fi-messagebox';
import { isObject } from 'lodash';

const { Header, Body, Footer } = ProForm;

const errorPattern = /(Failed|Error|failed|ERROR|error|FAILED)/i;

export const ViewScriptLog = ({ log = null, runOnDB, $opener }) => {
  let scriptHist_src = log?.history ?? [];

  scriptHist_src = scriptHist_src.filter((el) => {
    if (el.script_name === log.scriptName) {
      el.content = el.content.replace(/\n/g, '<br />');
      if (errorPattern.test(el.content)) {
        el.content = addErrorStyle(el.content);
      }
      return true;
    }
    return false;
  });

  let title = '';
  if (runOnDB)
    title = gettext('View the log of script %s: running on global DB').printf([
      log.scriptName,
    ]);
  else
    title = gettext('View the log of script %s: running on device %s').printf([
      log.scriptName,
      log.device,
    ]);

  return (
    <>
      <Header>{title}</Header>
      <Body>
        {scriptHist_src.length === 0 && (
          <div>
            <p>-------No records-----------</p>
          </div>
        )}
        {scriptHist_src.length > 0 &&
          scriptHist_src.map((log, idx) => {
            return (
              <div key={idx}>
                <p>-------Executing time: {log.exec_time}-----------</p>
                <p dangerouslySetInnerHTML={{ __html: log.content }}></p>
                <p>----------------End of Log-------------------------</p>
              </div>
            );
          })}
      </Body>
      <Footer>
        <NwButton
          type='default'
          className='tw-ml-2 tw-min-w-32'
          onClick={() => $opener.resolve('cancel')}
        >
          {gettext('Close')}
        </NwButton>
      </Footer>
    </>
  );
};

export function getScriptRunningHistory(devicename, adom) {
  return taskLogFlatproxyApi(devicename, adom);
}

export function openScriptHistoryModal(
  scriptName,
  deviceName,
  adomName,
  isPkgScript,
  logContent,
  openFn = ProToolkit.openModal
) {
  if (isPkgScript) {
    deviceName = '';
  }
  var _promise = logContent
    ? Promise.resolve([logContent])
    : getScriptRunningHistory(deviceName, adomName);

  _promise.then(
    function (resp) {
      if (resp) {
        var templog = {
          scriptName: scriptName,
          device: deviceName,
          history: resp,
        };
        //0621540, call self again with adomName Global if no expected script log entries exist in the specified ADOM.
        if (!fiAdom.isGlobalAdom(adomName) && scriptName != '') {
          if (!resp.some((d) => d.script_name === scriptName))
            return openScriptHistoryModal(
              scriptName,
              deviceName,
              MACROS.DVM.PM2_GUI_GLOBAL_ADOM_NAME,
              isPkgScript,
              logContent
            );
        }
        openFn(<ViewScriptLog log={templog} runOnDB={isPkgScript} />, {
          width: '700px',
        });
      }
      return;
    },
    () => {
      fiMessageBox.show(gettext('Can not find the script history.'), 'danger');
    }
  );
}

// ============ internal function ==============
const addErrorStyle = function (content) {
  //document.
  return "<span class='text-danger'>" + content + '</span>';
};

function taskLogFlatproxyApi(devicename, adom) {
  var adom_name = isObject(adom) ? adom.name : adom;
  var reqObj = {
    url: '/gui/adom/dvm/task',
    method: 'get',
    params: {
      deviceName: devicename,
      adomName: adom_name,
    },
  };
  return fiFmgHttp
    .post(reqObj)
    .then(function (resp) {
      return resp[0].data;
    })
    .catch(() => []);
}
