import { castArray, has, isNil, isString } from 'lodash';
import { deviceStatus } from 'fi-dvm';
import { fiAdminProfile, fiSysConfig } from 'fi-session';
import { fiWorkspace } from 'fi-workspace';
import { fiDeviceDataLoader } from './device_data_loader';

export {
  compareVersions,
  compareDeviceVer,
  checkDeviceFirmware,
  isDevInSplitVdomMode,
  checkReadOnlyStatus,
  checkDevicePlatform,
  checkVdomHasGlobalAccess,
};

const compareVersions = (baseVer, compareVer, strOp) => {
  const genVersionValue = (verStr) => {
    const [ver, mr, patch] = verStr
      .split('.')
      .map((part) => parseInt(part, 10));

    const version = ver * 100 + mr * 10;

    if (!isNil(version) && !isNaN(patch)) {
      return version + patch;
    }

    return version;
  };

  const baseVal = genVersionValue(baseVer);
  const compareVal = genVersionValue(compareVer);

  switch (strOp) {
    case '<':
      return baseVal < compareVal;
    case '<=':
      return baseVal <= compareVal;
    case '>':
      return baseVal > compareVal;
    case '>=':
      return baseVal >= compareVal;
    default:
      return baseVal == compareVal;
  }
};

const compareDeviceVer = (device, strVer, strOp) => {
  if (!device || !isString(strVer)) return false;
  const devVer = `${device.ver}.${device.mr}`;
  return compareVersions(devVer, strVer, strOp);
};

const checkDeviceFirmware = (device, strVer, strOp) => {
  if (!device || !device?.frmversion || !isString(strVer)) return false;
  return compareVersions(device.frmversion, strVer, strOp);
};

const isDevInSplitVdomMode = (device) => {
  if (!device) return false;
  if (compareDeviceVer(device, '6.2', '>='))
    return device.vdom_mode === MACROS.PM2CAT.PM2_VDOM_MODE_SPLIT_VDOM;
  return false;
};

const checkReadOnlyStatus = (
  opts = {
    cfg_name: '',
    supportWorkflow: false,
    ignoreSessionStatus: false,
    device: true,
  }
) => {
  const {
    cfg_name = '',
    device = undefined,
    supportWorkflow = false,
    ignoreSessionStatus = false,
  } = opts;
  const defaultCfg = device
    ? MACROS.USER.DVM.ADMINPRIV_DEV_CFG
    : MACROS.USER.DVM.ADMINPRIV_DEV_PROFILE;
  const cfgname = cfg_name || defaultCfg;
  // could be an array of permissions
  const hasWritePermission = castArray(cfgname).every((name) =>
    fiAdminProfile.hasRDWRPermitOn(name)
  );
  const isHaSlave = fiSysConfig.isHaSlave();
  let readonly = true;
  if (!hasWritePermission) {
    //1: check admin profile
    readonly = true;
  } else {
    if (isHaSlave) {
      // check ha slave
      readonly = true;
    } else if (fiWorkspace.isWorkspaceEnabled()) {
      //2: check workspace
      let devLock = fiWorkspace.allDevInfo();
      let adomLock = fiWorkspace.adomInfo();
      let devOid = device ? device.did || device.oid : 0;
      readonly = !(adomLock.isLockedByMe() || devLock.isLockedByMe(devOid));
      if (!readonly) {
        //3: check workflow session
        if (fiWorkspace.isWorkflow() && !ignoreSessionStatus) {
          let hasVldSessionID = adomLock.workflowSession() ? true : false; // is in session
          readonly = supportWorkflow ? !hasVldSessionID : hasVldSessionID;
        }
      }
    } else {
      readonly = false;
    }
  }
  return readonly;
};

const checkDevicePlatform = (dev, platform) => {
  if (!dev) return false;
  return dev.platform_id === platform;
};

const checkVdomHasGlobalAccess = (device) => {
  if (deviceStatus.isDeviceHasVdom(device.rtype)) {
    if (Array.isArray(device.vdoms)) {
      const currentAdomHasMgtVdom = !isNil(
        device.vdoms.find((vdom) => vdom.mgtvdom)
      );
      return currentAdomHasMgtVdom;
    }
  }
  return true;
};

export const checkSpecificDeviceVersion = ({
  // required
  device: _device,
  strOp, // e.g. >=
  version, // e.g. 7.4
  versionAndPatch, // e.g. 7.4.4
  // optional
  build, // e.g. 2573, if versionAndPatch
}) => {
  if (!_device || !isString(strOp)) return false;

  let deviceData = { ..._device };

  // get full device data from fistore if only device name or oid is provided (e.g. scope member object)
  if (
    !has(deviceData, 'ver') ||
    !has(deviceData, 'mr') ||
    !has(deviceData, '_patch') ||
    !has(deviceData, '_build')
  ) {
    deviceData =
      fiDeviceDataLoader.getDeviceByName(deviceData.name) ||
      fiDeviceDataLoader.getDevice(`${deviceData.oid}`);
  }

  // check version + patch number if it is available
  if (
    !isNil(versionAndPatch) &&
    !isNil(deviceData.ver) &&
    !isNil(deviceData.mr) &&
    !isNil(deviceData._patch) &&
    deviceData._patch >= 0
  ) {
    const devVersionAndPatch = `${deviceData.ver}.${deviceData.mr}.${deviceData._patch}`;
    return compareVersions(devVersionAndPatch, versionAndPatch, strOp);
  }

  // check version + build number if it is available
  if (!isNil(version) && !isNil(build) && !isNil(deviceData._build)) {
    return (
      compareDeviceVer(deviceData, version, strOp) && deviceData._build >= build
    );
  }

  // default
  return compareDeviceVer(deviceData, version, strOp);
};
