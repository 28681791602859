import {
  workspaceAdomsAction,
  workspaceDevicesAction,
  workspacePkgsAction,
  workspacePkgChildrenStatusAction,
  workspacePoliciesAction,
  workspaceObjectsAction,
  SET_WORKSPACE_ADOM,
} from './action';

import {
  workspace_adom_get_lockers,
  workspace_adom_fetch_list,
  workspace_adom_unlock_list,
  workspace_adom_save_list,
  workspace_adom_lock,
  workspace_adom_unlock,
  workspace_adom_save,
  workspace_device_fetch_list,
  workspace_device_lock_list,
  workspace_device_unlock_list,
  workspace_device_save_list,
  workspace_device_lock,
  workspace_device_unlock,
  workspace_device_save,
  workspace_pkg_fetch_list,
  workspace_pkg_lock_list,
  workspace_pkg_unlock_list,
  workspace_pkg_save_list,
  workspace_pkg_lock,
  workspace_pkg_unlock,
  workspace_pkg_save,
  workspace_pkg_cate_unlock,
  workspace_policy_fetch_list,
  workspace_object_fetch_list,
  workspace_object_lock_list,
  workspace_object_unlock_list,
} from './api';
import { getDataFromResponse, store_get_dispatch } from '../utils';

import { debounceBatch } from '../batch';

/************************************************************/
export const load_workspace_adoms = (dispatch) =>
  dispatch((dispatch) =>
    workspace_adom_fetch_list().then((json) => {
      const data = getDataFromResponse(json);
      dispatch(workspaceAdomsAction.fetch.success(data));
      return json;
    })
  );
export const unlock_workspace_adoms = (
  dispatch,
  forceUnlockDirty,
  forceUnlockOther
) =>
  dispatch(() =>
    workspace_adom_unlock_list(forceUnlockDirty, forceUnlockOther)
  );

export const save_workspace_adoms = (dispatch, unlockAfterSave) =>
  dispatch(() => workspace_adom_save_list(unlockAfterSave));

/************************************************************/

export const get_lockers_in_workspace_adom = (dispatch, adomOid) =>
  dispatch(() => workspace_adom_get_lockers(adomOid));

export const lock_workspace_adom = (dispatch, adomOid) =>
  dispatch(() => workspace_adom_lock(adomOid));

export const unlock_workspace_adom = (
  dispatch,
  adomOid,
  forceUnlockDirty = false,
  forceUnlockOther = false
) =>
  dispatch(() =>
    workspace_adom_unlock(adomOid, forceUnlockDirty, forceUnlockOther)
  );

export const save_workspace_adom = (dispatch, adomOid, unlockAfterSave) =>
  dispatch(() => workspace_adom_save(adomOid, unlockAfterSave));
/************************************************************/
//devices
export const load_workspace_devices = (dispatch, adomOid) =>
  dispatch((dispatch) =>
    workspace_device_fetch_list(adomOid).then((json) => {
      dispatch(
        workspaceDevicesAction.fetch.success({
          path: adomOid.toString(),
          data: getDataFromResponse(json),
        })
      );

      return json;
    })
  );

//batch=[{key:"101"}]
export const lock_workspace_devices = (dispatch, adomOid, batch = []) =>
  dispatch(() => workspace_device_lock_list(adomOid, batch));

export const unlock_workspace_devices = (
  dispatch,
  adomOid,
  batch = [],
  forceUnlockDirty,
  forceUnlockOther
) =>
  dispatch(() =>
    workspace_device_unlock_list(
      adomOid,
      batch,
      forceUnlockDirty,
      forceUnlockOther
    )
  );

export const save_workspace_devices = (
  dispatch,
  adomOid,
  batch = [],
  unlockAfterSave
) =>
  dispatch(() => workspace_device_save_list(adomOid, batch, unlockAfterSave));

//device
export const lock_workspace_device = (dispatch, adomOid, deviceOid) =>
  dispatch(() => workspace_device_lock(adomOid, deviceOid));

export const unlock_workspace_device = (
  dispatch,
  adomOid,
  deviceOid,
  forceUnlockDirty,
  forceUnlockOther
) =>
  dispatch(() =>
    workspace_device_unlock(
      adomOid,
      deviceOid,
      forceUnlockDirty,
      forceUnlockOther
    )
  );

export const save_workspace_device = (
  dispatch,
  adomOid,
  deviceOid,
  unlockAfterSave
) => dispatch(() => workspace_device_save(adomOid, deviceOid, unlockAfterSave));
/************************************************************/
//pkgs
//batch [{"key":}]
export const load_workspace_pkgs = (dispatch, adomOid) =>
  dispatch((dispatch) =>
    workspace_pkg_fetch_list(adomOid).then((json) => {
      const data = getDataFromResponse(json);

      const getPkgList = (data) => data['list'] || {};
      const getChildrenStatusList = (data) => data['childrenStatusList'] || {};

      const path = adomOid.toString();

      dispatch(
        workspacePkgsAction.fetch.success({
          path,
          data: getPkgList(data),
        })
      );

      dispatch(
        workspacePkgChildrenStatusAction.fetch.success({
          path,
          data: getChildrenStatusList(data),
        })
      );

      return json;
    })
  );
//batch=[{key:"101"}]
export const lock_workspace_pkgs = (dispatch, adomOid, batch = []) =>
  dispatch(() => workspace_pkg_lock_list(adomOid, batch));

export const unlock_workspace_pkgs = (
  dispatch,
  adomOid,
  batch = [],
  forceUnlockDirty,
  forceUnlockOther
) =>
  dispatch(() =>
    workspace_pkg_unlock_list(
      adomOid,
      batch,
      forceUnlockDirty,
      forceUnlockOther
    )
  );

export const save_workspace_pkgs = (
  dispatch,
  adomOid,
  batch = [],
  unlockAfterSave
) => dispatch(() => workspace_pkg_save_list(adomOid, batch, unlockAfterSave));

//pkg
export const load_workspace_pkg = (dispatch, adomOid, pkgOid) =>
  dispatch((dispatch) =>
    workspace_pkg_fetch_list(adomOid, [{ key: pkgOid.toString() }]).then(
      (json) => {
        const data = getDataFromResponse(json);

        const getPkgList = (data) => data['list'] || {};
        const getChildrenStatusList = (data) =>
          data['childrenStatusList'] || {};

        const path = adomOid.toString();

        const pkgList = getPkgList(data);
        const pkgChildrenList = getChildrenStatusList(data);

        const process = (list, actionObj) => {
          if (list.hasOwnProperty(pkgOid.toString())) {
            //change
            dispatch(
              debounceBatch(
                actionObj.record.change({
                  path,
                  data: {
                    id: pkgOid.toString(),
                    fields: list[pkgOid],
                  },
                })
              )
            );
          } else {
            //remove
            dispatch(
              debounceBatch(
                actionObj.record.delete({
                  path,
                  data: {
                    id: pkgOid.toString(),
                  },
                })
              )
            );
          }
        };

        process(pkgList, workspacePkgsAction);
        process(pkgChildrenList, workspacePkgChildrenStatusAction);

        return json;
      }
    )
  );

export const lock_workspace_pkg = (dispatch, adomOid, pkgOid) =>
  dispatch(() => workspace_pkg_lock(adomOid, pkgOid));

export const unlock_workspace_pkg = (
  dispatch,
  adomOid,
  pkgOid,
  forceUnlockDirty,
  forceUnlockOther
) =>
  dispatch(() =>
    workspace_pkg_unlock(adomOid, pkgOid, forceUnlockDirty, forceUnlockOther)
  );

export const save_workspace_pkg = (
  dispatch,
  adomOid,
  pkgOid,
  unlockAfterSave
) => dispatch(() => workspace_pkg_save(adomOid, pkgOid, unlockAfterSave));
/************************************************************/
export const unlock_workspace_pkg_cate = (dispatch, adomOid, pkgOid, cateOid) =>
  dispatch(() => workspace_pkg_cate_unlock(adomOid, pkgOid, cateOid));
/************************************************************/

export const load_workspace_policies = (
  dispatch,
  adomOid,
  pkgOid,
  categoryOid
) =>
  dispatch((dispatch) =>
    workspace_policy_fetch_list(adomOid, pkgOid, categoryOid).then((json) => {
      const data = getDataFromResponse(json);
      const path = adomOid
        .toString()
        .concat(':', pkgOid.toString(), ':', categoryOid.toString());

      dispatch(
        workspacePoliciesAction.fetch.success({
          path,
          data,
        })
      );

      return json;
    })
  );
/***************************************************************** */
//objects
export const load_workspace_objects = (dispatch, adomOid, cateoryOid) =>
  dispatch((dispatch) =>
    workspace_object_fetch_list(adomOid, cateoryOid).then((json) => {
      dispatch(
        workspaceObjectsAction.fetch.success({
          path: adomOid.toString().concat(':', cateoryOid?.toString()),
          data: getDataFromResponse(json),
        })
      );

      return json;
    })
  );

//batch=[{key:"101"}]
export const lock_workspace_objects = (
  dispatch,
  adomOid,
  cateoryOid,
  batch = []
) => dispatch(() => workspace_object_lock_list(adomOid, cateoryOid, batch));

export const unlock_workspace_objects = (
  dispatch,
  adomOid,
  cateoryOid,
  batch = [],
  forceUnlockDirty,
  forceUnlockOther
) =>
  dispatch(() =>
    workspace_object_unlock_list(
      adomOid,
      cateoryOid,
      batch,
      forceUnlockDirty,
      forceUnlockOther
    )
  );

/**************************************************************************************** */

export const set_workspace_adom = (adomOid, data) => {
  const dispatch = store_get_dispatch();

  dispatch({
    type: SET_WORKSPACE_ADOM,
    payload: { adomOid, data },
  });
};

/************************************/
export const highlight_save_btn = (tof) => {
  const dispatch = store_get_dispatch();
  dispatch({
    type: 'WORKSPACE_SAVE_BTN_HIGHLIGHT_CHANGE',
    payload: {
      highlight: tof,
    },
  });
};
