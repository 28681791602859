import { fiWorkspaceRequest, fiWorkspaceDataSelector } from 'fistore';

import * as common_fns from './common';
import {
  _currentAdom,
  _hasLockedByMe,
  _isLockedByMe,
  _isLockedByOther,
  _hasLockedByOther,
  _isUnlock,
  _isDirty,
  _isDirtyByMe,
  _hasDirty,
  _hasDirtyByMe,
  _getLockMsg,
  _selector_many,
} from './util';

export const pkgMapDispatchToProps = (dispatch) => {
  return {
    init: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.load_workspace_pkgs(dispatch, adom.oid || adom);
    },
    lock: (pkg, adom = _currentAdom()) => {
      return fiWorkspaceRequest.lock_workspace_pkg(
        dispatch,
        adom.oid || adom,
        pkg.oid || pkg
      );
    },
    unlock: (
      pkg,
      adom = _currentAdom(),
      unlock_dirty = false,
      unlock_other = false
    ) => {
      return fiWorkspaceRequest.unlock_workspace_pkg(
        dispatch,
        adom.oid || adom,
        pkg.oid || pkg,
        unlock_dirty,
        unlock_other
      );
    },
    save: (pkg, adom = _currentAdom()) => {
      return fiWorkspaceRequest.save_workspace_pkg(
        dispatch,
        adom.oid || adom,
        pkg.oid || pkg
      );
    },
    get_state: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.get_lockers_in_workspace_adom(
        dispatch,
        adom.oid || adom
      );
    },
  };
};

export const pkgMapStateToProps = (state) => {
  // do not put inline functions if may cause shadow compare fail?
  return {
    pkgs: fiWorkspaceDataSelector.get_workspace_pkgs(state),
    pkgChildrenStatus:
      fiWorkspaceDataSelector.get_workspace_pkgs_children_status(state),
    get: getAllPkgLockInfo,
    getPkg: getPkgLockInfo,
  };
};

export function getAllPkgLockInfo(adom = _currentAdom()) {
  return {
    pkgs: _selector_many(this.pkgs, adom) || {},
    pkgChildrenStatus: _selector_many(this.pkgChildrenStatus, adom) || {},
    ...common_fns,
    isLockedByMe: _isPkgLockedByMe,
    isLockedByOther: _isPkgLockedByOther,
    isUnlock: _isPkgUnlocked,
    isDirty: _isPkgDirty, //dirty by me
    hasDirty: _hasPkgDirty, // dirty by me
    hasLockedByMe: _hasPkgLockedByMe,
    hasLockedByOther: _hasPkgLockedByOther,
    getLockedPkgs: _getLockedPkgs,
    getLockedByMePkgs: _getLockedByMePkgs,
    getPkg: function (pkg) {
      let data = this.pkgs ? this.pkgs[pkg.oid || pkg] : {};
      return { ...data }; // make a copy, prevent modify
    },
    lockMessage: _getLockMsg,
  };
}

export function getPkgLockInfo(pkg, adom = _currentAdom()) {
  return {
    pkg: _selector_one_pkg(this.pkgs, adom, pkg),
    pkgChildrenStatus: _selector_one_pkg(this.pkgChildrenStatus, adom, pkg),
    ...common_fns,
    isLockedByMe: _pkgLockedByMe,
    isLockedByOther: _pkgLockedByOther,
    isUnlock: _pkgUnlocked,
    isDirty: _pkgDirty, //dirty by me
    lockMessage: _getLockMsg,
  };
}

function _isPkgLockedByMe(pkg) {
  return _isLockedByMe(this.pkgs[pkg.oid]);
}
function _isPkgLockedByOther(pkg) {
  return _isLockedByOther(this.pkgs[pkg.oid]);
}
function _isPkgUnlocked(pkg) {
  return _isUnlock(this.pkgs[pkg.oid]);
}
function _isPkgDirty(pkg) {
  return (
    _isDirtyByMe(this.pkgs[pkg.oid]) ||
    _isDirty(this.pkgChildrenStatus[pkg.oid])
  );
}
function _hasPkgDirty() {
  return _hasDirtyByMe(this.pkgs) || _hasDirty(this.pkgChildrenStatus);
}
function _hasPkgLockedByMe() {
  return _hasLockedByMe(this.pkgs);
}
function _hasPkgLockedByOther() {
  return _hasLockedByOther(this.pkgs);
}

function _getLockedPkgs() {
  let data = this.pkgs || {};
  let arr = [];
  for (let prop in data) {
    arr.push({
      oid: prop,
      ...data[prop],
    });
  }
  return arr;
}

function _getLockedByMePkgs() {
  let data = this.pkgs || {};
  let arr = [];
  for (let prop in data) {
    if (_isLockedByMe(data[prop])) {
      arr.push({
        oid: prop,
        ...data[prop],
      });
    }
  }
  return arr;
}

function _pkgLockedByMe() {
  return _isLockedByMe(this.pkg);
}
function _pkgLockedByOther() {
  return _isLockedByOther(this.pkg);
}
function _pkgUnlocked() {
  return _isUnlock(this.pkg);
}
function _pkgDirty() {
  return _isDirtyByMe(this.pkg) || _isDirty(this.pkgChildrenStatus);
}

function _selector_one_pkg(data, adom, pkg) {
  return (
    _selector_many(data, adom)[pkg.oid || pkg] || {
      oid: pkg.oid,
      name: pkg.name || '',
      lock: MACROS.SYS.LOCK_STATE_UNLOCKED,
      dirty: 0,
    }
  );
}
