import { fiStoreRouting, fiSession, fmgRemoteFaz, fiStore } from 'fistore';
import { getDisabledDashes } from 'fistore/soc/viewLog/selectors';
import { hasDeviceByOsType } from 'fistore/fazSocFabric/device/selectors';
import { negate, overSome, overEvery } from 'lodash';
const {
  inAdom,
  adomNames: an,
  inGlobalAdom,
  hasRDPermitOn,
  hasRDWRPermitOn,
  isFmg,
  isFaz,
  getIsFazSupervisor,
  hasCsfGroups,
  hasRemoteFAZ,
  inOthersAdom,
  inFazCollectorMode,
  isFmgOnly,
  showFazApps,
} = fiSession;
const { isFortiviewDisabled } = fmgRemoteFaz;

const fazOrFmgWithRemoteFaz = overSome([
  overEvery([isFmg, hasRemoteFAZ]),
  isFaz,
]);

const isReadWrite = () => {
  const getState = fiStore.getState;
  return hasRDWRPermitOn('log_viewer', {
    haCheck: false,
  })(getState());
};

const fgtSidemenus = {
  fortiview_threats: {
    label: gettext('Threats'),
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
    path: '/fortiview/threats',
    olhTag: 'fortiview_threats',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Threats.',
  },
  fortiview_threats_threat: {
    label: gettext('Top Threats'),
    icon: 'threats',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/threats/threat',
    stateParams: { appUniKey: 'fortiview_threats_threat', dashId: 'threat' },
  },
  fortiview_threats_threat_map: {
    label: gettext('Threat Map'),
    icon: 'map-marked',
    iconLibrary: 'fa-solid',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/threats/threat_map',
    stateParams: {
      appUniKey: 'fortiview_threats_threat_map',
      dashId: 'threat_map',
    },
    showCheckers: [negate(getIsFazSupervisor)],
  },
  fortiview_threats_threat_detect_summary: {
    label: gettext('Indicator of Compromise'),
    icon: 'th-list',
    iconLibrary: 'fa-solid',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/threats/threat_detect_summary',
    stateParams: {
      appUniKey: 'fortiview_threats_threat_detect_summary',
      dashId: 'threat_detect_summary',
    },
  },
  fortiview_threats_fsa_detection_view: {
    label: gettext('FortiSandbox Detection'),
    icon: 'fortisandbox',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/threats/fsa_detection_view',
    stateParams: {
      appUniKey: 'fortiview_threats_fsa_detection_view',
      dashId: 'fsa_detection_view',
    },
  },
  fortiview_traffic: {
    label: gettext('Traffic'),
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
    path: '/fortiview/traffic',
    olhTag: 'fortiview_traffic',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Traffic.',
  },
  fortiview_traffic_source_hosts: {
    label: gettext('Top Sources'),
    icon: 'traffic',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/traffic/source_hosts',
    stateParams: {
      appUniKey: 'fortiview_traffic_source_hosts',
      dashId: 'source_hosts',
    },
  },
  fortiview_traffic_source_objects: {
    label: gettext('Top Source Addresses'),
    icon: 'address',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/traffic/source_objects',
    stateParams: {
      appUniKey: 'fortiview_traffic_source_objects',
      dashId: 'source_objects',
    },
  },
  fortiview_traffic_destination_ips: {
    label: gettext('Top Destinations'),
    icon: 'traffic',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/traffic/destination_ips',
    stateParams: {
      appUniKey: 'fortiview_traffic_destination_ips',
      dashId: 'destination_ips',
    },
  },
  fortiview_traffic_destination_objects: {
    label: gettext('Top Destination Addresses'),
    icon: 'address',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/traffic/destination_objects',
    stateParams: {
      appUniKey: 'fortiview_traffic_destination_objects',
      dashId: 'destination_objects',
    },
  },
  fortiview_traffic_country: {
    label: gettext('Top Country/Region'),
    icon: 'chart-treemap',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/traffic/country',
    stateParams: { appUniKey: 'fortiview_traffic_country', dashId: 'country' },
  },
  fortiview_traffic_policy_hits: {
    label: gettext('Policy Hits'),
    icon: 'policy-objects',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/traffic/policy_hits',
    stateParams: {
      appUniKey: 'fortiview_traffic_policy_hits',
      dashId: 'policy_hits',
    },
    showCheckers: [negate(hasRemoteFAZ)],
  },
  fortiview_traffic_create_policy_by_hits: {
    label: gettext('Policy Hits'),
    icon: 'policy-objects',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/traffic/create_policy_by_hits',
    stateParams: {
      appUniKey: 'fortiview_traffic_create_policy_by_hits',
      dashId: 'create_policy_by_hits',
    },
    showCheckers: [hasRemoteFAZ],
  },
  fortiview_traffic_dns_log_view: {
    label: gettext('DNS Logs'),
    icon: 'external-resource-domain',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/traffic/dns_log_view',
    stateParams: {
      appUniKey: 'fortiview_traffic_dns_log_view',
      dashId: 'dns_log_view',
    },
  },
  fortiview_traffic_ztna_servers: {
    label: gettext('ZTNA Servers'),
    icon: 'ztna',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/traffic/ztna_servers',
    stateParams: {
      appUniKey: 'fortiview_traffic_ztna_servers',
      dashId: 'ztna_servers',
    },
  },
  fortiview_shadow_it: {
    label: gettext('Shadow IT'),
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
    path: '/fortiview/shadow_it',
    olhTag: 'fortiview_shadow_it',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Shadow IT.',
  },
  fortiview_shadow_it_cloud_application: {
    label: gettext('Top Cloud Applications'),
    icon: 'applications',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/shadow_it/cloud_application',
    stateParams: {
      appUniKey: 'fortiview_shadow_it_cloud_application',
      dashId: 'cloud_application',
    },
  },
  fortiview_shadow_it_cloud_user: {
    label: gettext('Top Cloud Users'),
    icon: 'user-alt',
    iconLibrary: 'fa-solid',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/shadow_it/cloud_user',
    stateParams: {
      appUniKey: 'fortiview_shadow_it_cloud_user',
      dashId: 'cloud_user',
    },
  },
  fortiview_application: {
    label: gettext('Applications & Websites'),
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
    path: '/fortiview/application',
    olhTag: 'fortiview_application',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Applications & Websites.',
  },
  fortiview_application_app: {
    label: gettext('Top Applications'),
    icon: 'applications',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/application/app',
    stateParams: { appUniKey: 'fortiview_application_app', dashId: 'app' },
  },
  fortiview_application_website_domain: {
    label: gettext('Top Website Domains'),
    icon: 'external-resource-domain',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/application/website_domain',
    stateParams: {
      appUniKey: 'fortiview_application_website_domain',
      dashId: 'website_domain',
    },
  },
  fortiview_application_website_category: {
    label: gettext('Top Website Categories'),
    icon: 'external-resource-category',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/application/website_category',
    stateParams: {
      appUniKey: 'fortiview_application_website_category',
      dashId: 'website_category',
    },
  },
  fortiview_application_browsing_users: {
    label: gettext('Top Browsing Users'),
    icon: 'user-alt',
    iconLibrary: 'fa-solid',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/application/browsing_users',
    stateParams: {
      appUniKey: 'fortiview_application_browsing_users',
      dashId: 'browsing_users',
    },
  },
  fortiview_vpn: {
    label: gettext('VPN'),
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
    path: '/fortiview/vpn',
    olhTag: 'fortiview_vpn',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> VPN.',
  },
  fortiview_vpn_ssl: {
    label: gettext('SSL & Dialup IPsec'),
    icon: 'ssl',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/vpn/ssl',
    stateParams: { appUniKey: 'fortiview_vpn_ssl', dashId: 'ssl' },
  },
  fortiview_vpn_site: {
    label: gettext('Site-to-Site IPsec'),
    icon: 'vpn-lock',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/vpn/site',
    stateParams: { appUniKey: 'fortiview_vpn_site', dashId: 'site' },
  },
  fortiview_system: {
    label: gettext('System'),
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
    path: '/fortiview/system',
    olhTag: 'fortiview_system',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> System.',
  },
  fortiview_system_admin: {
    label: gettext('Admin Logins'),
    icon: 'sign-in-alt',
    iconLibrary: 'fa-solid',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/system/admin',
    stateParams: { appUniKey: 'fortiview_system_admin', dashId: 'admin' },
  },
  fortiview_system_system_events: {
    label: gettext('System Events'),
    icon: 'syslog',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/system/system_events',
    stateParams: {
      appUniKey: 'fortiview_system_system_events',
      dashId: 'system_events',
    },
  },
  fortiview_system_resource: {
    label: gettext('Resource Usage'),
    icon: 'device-dashboard',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/system/resource',
    stateParams: { appUniKey: 'fortiview_system_resource', dashId: 'resource' },
  },
  fortiview_system_unauthorized_connections: {
    label: gettext('Failed Authentication Attempts'),
    icon: 'authentication-scheme',
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/system/unauthorized_connections',
    stateParams: {
      appUniKey: 'fortiview_system_unauthorized_connections',
      dashId: 'unauthorized_connections',
    },
  },
  fortiview_security: {
    label: gettext('Security'),
    showCheckers: [
      inAdom([an.SecurityFabric]),
      hasDeviceByOsType([MACROS.DVM.DVM_OS_TYPE_FWB]),
    ],
    path: '/fortiview/security',
    olhTag: 'fortiview_security',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Security.',
  },
};

const fctSidemenus = {
  fortiview_fct_threats: {
    label: gettext('FortiClient Threats'),
    showCheckers: [inAdom([an.FortiClient])],
    path: '/fortiview/fct_threats',
    olhTag: 'fortiview_fct_threats',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> FortiClient Threats.',
  },
  fortiview_fct_threats_fct_threat: {
    label: gettext('Top Threats (FortiClient)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fct_threats/fct_threat',
    stateParams: {
      appUniKey: 'fortiview_fct_threats_fct_threat',
      fabricAdomAppKey: 'fortiview_threats_fct_threat',
      fabricAdomPath: '/fortiview/threats/fct_threat',
      dashId: 'fct_threat',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FCT,
      ostype: MACROS.DVM.DVM_OS_TYPE_FCT,
    },
  },
  fortiview_fct_application: {
    label: gettext('FortiClient Applications & Websites'),
    showCheckers: [inAdom([an.FortiClient])],
    path: '/fortiview/fct_application',
    olhTag: 'fortiview_fct_application',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> FortiClient Applications & Websites.',
  },
  fortiview_fct_application_fct_app: {
    label: gettext('Top Applications (FortiClient)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fct_application/fct_app',
    stateParams: {
      appUniKey: 'fortiview_fct_application_fct_app',
      fabricAdomAppKey: 'fortiview_application_fct_app',
      fabricAdomPath: '/fortiview/application/fct_app',
      dashId: 'fct_app',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FCT,
      ostype: MACROS.DVM.DVM_OS_TYPE_FCT,
    },
  },
  fortiview_fct_application_fct_website: {
    label: gettext('Top Websites (FortiClient)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fct_application/fct_website',
    stateParams: {
      appUniKey: 'fortiview_fct_application_fct_website',
      fabricAdomAppKey: 'fortiview_application_fct_website',
      fabricAdomPath: '/fortiview/application/fct_website',
      dashId: 'fct_website',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FCT,
      ostype: MACROS.DVM.DVM_OS_TYPE_FCT,
    },
  },
};

const fddosSidemenus = {
  fortiview_fddos_traffic: {
    label: gettext('FortiDDoS Traffic'),
    showCheckers: [inAdom([an.FortiDDoS])],
    path: '/fortiview/fddos_traffic',
    olhTag: 'fortiview_fddos_traffic',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> FortiDDoS Traffic.',
  },
  fortiview_fddos_traffic_fddos_source_view: {
    label: gettext('Top Sources (FortiDDoS)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fddos_traffic/fddos_source_view',
    stateParams: {
      appUniKey: 'fortiview_fddos_traffic_fddos_source_view',
      fabricAdomAppKey: 'fortiview_traffic_fddos_source_view',
      fabricAdomPath: '/fortiview/traffic/fddos_source_view',
      dashId: 'fddos_source_view',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FDD,
      ostype: MACROS.DVM.DVM_OS_TYPE_FDD,
    },
  },
  fortiview_fddos_traffic_fddos_destination_view: {
    label: gettext('Top Destinations (FortiDDoS)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fddos_traffic/fddos_destination_view',
    stateParams: {
      appUniKey: 'fortiview_fddos_traffic_fddos_destination_view',
      fabricAdomAppKey: 'fortiview_traffic_fddos_destination_view',
      fabricAdomPath: '/fortiview/traffic/fddos_destination_view',
      dashId: 'fddos_destination_view',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FDD,
      ostype: MACROS.DVM.DVM_OS_TYPE_FDD,
    },
  },
  fortiview_fddos_traffic_fddos_type_view: {
    label: gettext('Top Type (FortiDDoS)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fddos_traffic/fddos_type_view',
    stateParams: {
      appUniKey: 'fortiview_fddos_traffic_fddos_type_view',
      fabricAdomAppKey: 'fortiview_traffic_fddos_type_view',
      fabricAdomPath: '/fortiview/traffic/fddos_type_view',
      dashId: 'fddos_type_view',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FDD,
      ostype: MACROS.DVM.DVM_OS_TYPE_FDD,
    },
  },
};

const ffwSidemenus = {
  fortiview_ffw_traffic: {
    label: gettext('FortiFirewall Traffic'),
    showCheckers: [inAdom([an.FortiFirewall])],
    path: '/fortiview/ffw_traffic',
    olhTag: 'fortiview_ffw_traffic',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> FortiFirewall Traffic.',
  },
  fortiview_ffw_traffic_ffw_source_hosts: {
    label: gettext('Top Sources (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_traffic/ffw_source_hosts',
    stateParams: {
      appUniKey: 'fortiview_ffw_traffic_ffw_source_hosts',
      fabricAdomAppKey: 'fortiview_traffic_ffw_source_hosts',
      fabricAdomPath: '/fortiview/traffic/ffw_source_hosts',
      dashId: 'ffw_source_hosts',
    },
  },
  fortiview_ffw_traffic_ffw_source_objects: {
    label: gettext('Top Source Addresses (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_traffic/ffw_source_objects',
    stateParams: {
      appUniKey: 'fortiview_ffw_traffic_ffw_source_objects',
      fabricAdomAppKey: 'fortiview_traffic_ffw_source_objects',
      fabricAdomPath: '/fortiview/traffic/ffw_source_objects',
      dashId: 'ffw_source_objects',
    },
  },
  fortiview_ffw_traffic_ffw_destination_ips: {
    label: gettext('Top Destinations (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_traffic/ffw_destination_ips',
    stateParams: {
      appUniKey: 'fortiview_ffw_traffic_ffw_destination_ips',
      fabricAdomAppKey: 'fortiview_traffic_ffw_destination_ips',
      fabricAdomPath: '/fortiview/traffic/ffw_destination_ips',
      dashId: 'ffw_destination_ips',
    },
  },
  fortiview_ffw_traffic_ffw_destination_objects: {
    label: gettext('Top Destination Addresses (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_traffic/ffw_destination_objects',
    stateParams: {
      appUniKey: 'fortiview_ffw_traffic_ffw_destination_objects',
      fabricAdomAppKey: 'fortiview_traffic_ffw_destination_objects',
      fabricAdomPath: '/fortiview/traffic/ffw_destination_objects',
      dashId: 'ffw_destination_objects',
    },
  },
  fortiview_ffw_traffic_ffw_country: {
    label: gettext('Top Country/Region (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_traffic/ffw_country',
    stateParams: {
      appUniKey: 'fortiview_ffw_traffic_ffw_country',
      fabricAdomAppKey: 'fortiview_traffic_ffw_country',
      fabricAdomPath: '/fortiview/traffic/ffw_country',
      dashId: 'ffw_country',
    },
  },
  fortiview_ffw_traffic_ffw_policy_hits: {
    label: gettext('Policy Hits (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_traffic/ffw_policy_hits',
    stateParams: {
      appUniKey: 'fortiview_ffw_traffic_ffw_policy_hits',
      fabricAdomAppKey: 'fortiview_traffic_ffw_policy_hits',
      fabricAdomPath: '/fortiview/traffic/ffw_policy_hits',
      dashId: 'ffw_policy_hits',
    },
  },
  fortiview_ffw_vpn: {
    label: gettext('FortiFirewall VPN'),
    showCheckers: [inAdom([an.FortiFirewall])],
    path: '/fortiview/ffw_vpn',
    olhTag: 'fortiview_ffw_vpn',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> FortiFirewall VPN.',
  },
  fortiview_ffw_vpn_ffw_ssl: {
    label: gettext('SSL & Dialup IPsec (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_vpn/ffw_ssl',
    stateParams: {
      appUniKey: 'fortiview_ffw_vpn_ffw_ssl',
      fabricAdomAppKey: 'fortiview_vpn_ffw_ssl',
      fabricAdomPath: '/fortiview/vpn/ffw_ssl',
      dashId: 'ffw_ssl',
    },
  },
  fortiview_ffw_vpn_ffw_site: {
    label: gettext('Site-to-Site IPsec (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_vpn/ffw_site',
    stateParams: {
      appUniKey: 'fortiview_ffw_vpn_ffw_site',
      fabricAdomAppKey: 'fortiview_vpn_ffw_site',
      fabricAdomPath: '/fortiview/vpn/ffw_site',
      dashId: 'ffw_site',
    },
  },
  fortiview_ffw_ffw_system: {
    label: gettext('FortiFirewall System'),
    showCheckers: [
      overSome([
        inAdom([an.FortiFirewall]),
        overEvery([
          inAdom([an.SecurityFabric]),
          hasDeviceByOsType([MACROS.DVM.DVM_OS_TYPE_FFW]),
        ]),
      ]),
    ],
    path: '/fortiview/ffw_ffw_system',
    olhTag: 'fortiview_ffw_system',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> FortiFirewall System.',
  },
  fortiview_ffw_ffw_system_ffw_admin: {
    label: gettext('Admin Logins (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_ffw_system/ffw_admin',
    stateParams: {
      appUniKey: 'fortiview_ffw_ffw_system_ffw_admin',
      dashId: 'ffw_admin',
    },
  },
  fortiview_ffw_ffw_system_ffw_system_events: {
    label: gettext('System Events (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_ffw_system/ffw_system_events',
    stateParams: {
      appUniKey: 'fortiview_ffw_ffw_system_ffw_system_events',
      dashId: 'ffw_system_events',
    },
  },
  fortiview_ffw_ffw_system_ffw_unauthorized_connections: {
    label: gettext('Failed Authentication Attempts (FortiFirewall)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/ffw_ffw_system/ffw_unauthorized_connections',
    stateParams: {
      appUniKey: 'fortiview_ffw_ffw_system_ffw_unauthorized_connections',
      dashId: 'ffw_unauthorized_connections',
    },
  },
};

const fwbSidemenus = {
  fortiview_fwb_security: {
    label: gettext('Security (FortiWeb)'),
    showCheckers: [inAdom([an.FortiWeb])],
    path: '/fortiview/fwb_security',
    olhTag: 'fortiview_fwb_security',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Security (FortiWeb).',
  },
  fortiview_fwb_security_fwb_security_country: {
    label: gettext('Countries (FortiWeb)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fwb_security/fwb_security_country',
    stateParams: {
      appUniKey: 'fortiview_fwb_security_fwb_security_country',
      fabricAdomAppKey: 'fortiview_security_fwb_security_country',
      fabricAdomPath: '/fortiview/security/fwb_security_country',
      dashId: 'fwb_security_country',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FWB,
      ostype: MACROS.DVM.DVM_OS_TYPE_FWB,
    },
  },
  fortiview_fwb_security_fwb_security_threat: {
    label: gettext('Threats (FortiWeb)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fwb_security/fwb_security_threat',
    stateParams: {
      appUniKey: 'fortiview_fwb_security_fwb_security_threat',
      fabricAdomAppKey: 'fortiview_security_fwb_security_threat',
      fabricAdomPath: '/fortiview/security/fwb_security_threat',
      dashId: 'fwb_security_threat',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FWB,
      ostype: MACROS.DVM.DVM_OS_TYPE_FWB,
    },
  },
  fortiview_fwb_security_fwb_security_policy: {
    label: gettext('Server Policies (FortiWeb)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fwb_security/fwb_security_policy',
    stateParams: {
      appUniKey: 'fortiview_fwb_security_fwb_security_policy',
      fabricAdomAppKey: 'fortiview_security_fwb_security_policy',
      fabricAdomPath: '/fortiview/security/fwb_security_policy',
      dashId: 'fwb_security_policy',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FWB,
      ostype: MACROS.DVM.DVM_OS_TYPE_FWB,
    },
  },
  fortiview_fwb_traffic: {
    label: gettext('Traffic (FortiWeb)'),
    showCheckers: [inAdom([an.FortiWeb])],
    path: '/fortiview/fwb_traffic',
    olhTag: 'fortiview_fwb_traffic',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Traffic (FortiWeb).',
  },
  fortiview_fwb_traffic_fwb_traffic_source: {
    label: gettext('Sources (FortiWeb)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fwb_traffic/fwb_traffic_source',
    stateParams: {
      appUniKey: 'fortiview_fwb_traffic_fwb_traffic_source',
      fabricAdomAppKey: 'fortiview_traffic_fwb_traffic_source',
      fabricAdomPath: '/fortiview/traffic/fwb_traffic_source',
      dashId: 'fwb_traffic_source',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FWB,
      ostype: MACROS.DVM.DVM_OS_TYPE_FWB,
    },
  },
  fortiview_fwb_traffic_fwb_traffic_country: {
    label: gettext('Countries (FortiWeb)'),
    state: 'adom.default.soc.fortiview.dash',
    path: '/fortiview/fwb_traffic/fwb_traffic_country',
    stateParams: {
      appUniKey: 'fortiview_fwb_traffic_fwb_traffic_country',
      fabricAdomAppKey: 'fortiview_traffic_fwb_traffic_country',
      fabricAdomPath: '/fortiview/traffic/fwb_traffic_country',
      dashId: 'fwb_traffic_country',
      devtype: MACROS.FAZ_SYNTAX.LOGDEV_FWB,
      ostype: MACROS.DVM.DVM_OS_TYPE_FWB,
    },
  },
};

const threatsEventsMenus = {
  fortiview_threats_events: {
    label: gettext('Threats & Events'),
    hideIfNoChildren: true,
    path: '/fortiview/threats_events',
    olhTag: 'noc_threats_events',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Threats & Events.',
  },
  'fortiview_threats_events_threat-monitor': {
    label: gettext('Threats'),
    icon: 'threats',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/threats_events/threat-monitor',
    stateParams: {
      appUniKey: 'fortiview_threats_events_threat-monitor',
      dashId: 'threat-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_threats_events_compromised-hosts': {
    label: gettext('Indicator of Compromise'),
    icon: 'th-list',
    iconLibrary: 'fa-solid',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/threats_events/compromised-hosts',
    stateParams: {
      appUniKey: 'fortiview_threats_events_compromised-hosts',
      dashId: 'compromised-hosts',
    },
    showCheckers: [
      fazOrFmgWithRemoteFaz,
      inAdom([an.SecurityFabric, an.FortiGate]),
    ],
  },
  'fortiview_threats_events_fortisandbox-detections': {
    label: gettext('FortiSandbox Detections'),
    icon: 'fortisandbox',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/threats_events/fortisandbox-detections',
    stateParams: {
      appUniKey: 'fortiview_threats_events_fortisandbox-detections',
      dashId: 'fortisandbox-detections',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_threats_events_local-threat-research-monitor': {
    label: gettext('Local Threat Research'),
    icon: 'third-party-threat-feed',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/threats_events/local-threat-research-monitor',
    stateParams: {
      appUniKey: 'fortiview_threats_events_local-threat-research-monitor',
      dashId: 'local-threat-research-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_threats_events_dlp-dashboard': {
    label: gettext('Data Loss Prevention'),
    icon: 'data-leak-prevention',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/threats_events/dlp-dashboard',
    stateParams: {
      appUniKey: 'fortiview_threats_events_dlp-dashboard',
      dashId: 'dlp-dashboard',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_threats_events_fct-threat-moonitor': {
    label: gettext('Threats (FortiClient)'),
    icon: 'third-party-threat-feed',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/threats_events/fct-threat-moonitor',
    stateParams: {
      appUniKey: 'fortiview_threats_events_fct-threat-moonitor',
      dashId: 'fct-threat-moonitor',
    },
    showCheckers: [
      fazOrFmgWithRemoteFaz,
      inAdom([an.SecurityFabric, an.FortiClient]),
    ],
  },
};
const trafficAnalysisMenus = {
  fortiview_traffic_analysis: {
    label: gettext('Traffic Analysis'),
    hideIfNoChildren: true,
    path: '/fortiview/traffic_analysis',
    olhTag: 'noc_traffic_analysis',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Traffic Analysis.',
  },
  'fortiview_traffic_analysis_l3-traffic-monitor': {
    label: gettext('Traffic'),
    icon: 'traffic',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/l3-traffic-monitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_l3-traffic-monitor',
      dashId: 'l3-traffic-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_traffic_analysis_l7-traffic-monitor': {
    label: gettext('Applications & Websites'),
    icon: 'applications',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/l7-traffic-monitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_l7-traffic-monitor',
      dashId: 'l7-traffic-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_traffic_analysis_ztna-dashboard': {
    label: gettext('ZTNA'),
    icon: 'ztna',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/ztna-dashboard',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_ztna-dashboard',
      dashId: 'ztna-dashboard',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_traffic_analysis_vpn-monitor': {
    label: gettext('VPN'),
    icon: 'vpn-lock',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/vpn-monitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_vpn-monitor',
      dashId: 'vpn-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_traffic_analysis_traffic-shaping-monitor': {
    label: gettext('Traffic Shaping Monitor'),
    icon: 'network-traffic',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/traffic-shaping-monitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_traffic-shaping-monitor',
      dashId: 'traffic-shaping-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_traffic_analysis_endpoint-monitor': {
    label: gettext('Endpoints'),
    icon: 'monitor',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/endpoint-monitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_endpoint-monitor',
      dashId: 'endpoint-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_traffic_analysis_fct-endpoints-moonitor': {
    label: gettext('Endpoints (FortiClient)'),
    icon: 'event-monitor',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/fct-endpoints-moonitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_fct-endpoints-moonitor',
      dashId: 'fct-endpoints-moonitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiClient])],
  },
  'fortiview_traffic_analysis_fddos-traffic-moonitor': {
    label: gettext('Traffic (FortiDDoS)'),
    icon: 'traffic',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/fddos-traffic-moonitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_fddos-traffic-moonitor',
      dashId: 'fddos-traffic-moonitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiDDoS])],
  },
  'fortiview_traffic_analysis_ffw-traffic-monitor': {
    label: gettext('Traffic (FortiFirewall)'),
    icon: 'traffic',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/ffw-traffic-monitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_ffw-traffic-monitor',
      dashId: 'ffw-traffic-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiFirewall])],
  },
  'fortiview_traffic_analysis_fct-traffic-moonitor': {
    label: gettext('Applications & Websites (FortiClient)'),
    icon: 'applications',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/fct-traffic-moonitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_fct-traffic-moonitor',
      dashId: 'fct-traffic-moonitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiClient])],
  },
  'fortiview_traffic_analysis_ffw-vpn-monitor': {
    label: gettext('VPN (FortiFirewall)'),
    icon: 'vpn-lock',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/ffw-vpn-monitor',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_ffw-vpn-monitor',
      dashId: 'ffw-vpn-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiFirewall])],
  },
  'fortiview_traffic_analysis_fct-software-inventory': {
    label: gettext('FortiClient Software Inventory'),
    icon: 'ems-software-inventory',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/fct-software-inventory',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_fct-software-inventory',
      dashId: 'fct-software-inventory',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiClient])],
  },
  'fortiview_traffic_analysis_fortimail-dashboard': {
    label: gettext('FortiMail'),
    icon: 'fortimail',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/fortimail-dashboard',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_fortimail-dashboard',
      dashId: 'fortimail-dashboard',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiMail])],
  },
  'fortiview_traffic_analysis_fortiproxy-dashboard': {
    label: gettext('FortiProxy'),
    icon: 'fortiproxy',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/traffic_analysis/fortiproxy-dashboard',
    stateParams: {
      appUniKey: 'fortiview_traffic_analysis_fortiproxy-dashboard',
      dashId: 'fortiproxy-dashboard',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiProxy])],
  },
};
const sdwanMenus = {
  fortiview_sdwan: {
    label: gettext('SD-WAN'),
    hideIfNoChildren: true,
    path: '/fortiview/sdwan',
    olhTag: 'noc_sdwan',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> SD-WAN.',
  },
  'fortiview_sdwan_sdwan-dashboard': {
    label: gettext('Secure SD-WAN Monitor'),
    icon: 'dashboard',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/sdwan/sdwan-dashboard',
    stateParams: {
      appUniKey: 'fortiview_sdwan_sdwan-dashboard',
      dashId: 'sdwan-dashboard',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_sdwan_sdwan-summary': {
    label: gettext('SD-WAN Summary'),
    icon: 'dashboard',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/sdwan/sdwan-summary',
    stateParams: {
      appUniKey: 'fortiview_sdwan_sdwan-summary',
      dashId: 'sdwan-summary',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
};
const fabricDevicesMenus = {
  fortiview_fabric_devices: {
    label: gettext('Fabric Devices'),
    hideIfNoChildren: true,
    path: '/fortiview/fabric_devices',
    olhTag: 'noc_fabric_devices',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Fabric Devices',
  },
  'fortiview_fabric_devices_wifi-monitor': {
    label: gettext('WiFi'),
    icon: 'wifi',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/fabric_devices/wifi-monitor',
    stateParams: {
      appUniKey: 'fortiview_fabric_devices_wifi-monitor',
      dashId: 'wifi-monitor',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  fortiview_fabric_devices_iot: {
    label: gettext('IoT'),
    icon: 'fortiguard-iot-service',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/fabric_devices/iot',
    stateParams: {
      appUniKey: 'fortiview_fabric_devices_iot',
      dashId: 'iot',
    },
    showCheckers: [inAdom([an.SecurityFabric, an.FortiGate])],
  },
  'fortiview_fabric_devices_fabric-state-of-security': {
    label: gettext('Fabric State of Security'),
    icon: 'fabric-device',
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/fabric_devices/fabric-state-of-security',
    stateParams: {
      appUniKey: 'fortiview_fabric_devices_fabric-state-of-security',
      dashId: 'fabric-state-of-security',
    },
    showCheckers: [hasCsfGroups, inAdom([an.SecurityFabric, an.FortiGate])],
  },
};
const restMenus = {
  'fortiview_fmg-dash1': {
    label: gettext('Device Status'),
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/fmg-dash1',
    stateParams: {
      appUniKey: 'fortiview_fmg-dash1',
      dashId: 'fmg-dash1',
    },
    showCheckers: [isFmg],
    olhTag: 'noc_device_status',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Device Status.',
  },
  'fortiview_faz-performance-monitor': {
    label: gettext('Local System Performance'),
    state: 'adom.default.soc.noc.dash',
    path: '/fortiview/faz-performance-monitor',
    stateParams: {
      appUniKey: 'fortiview_faz-performance-monitor',
      dashId: 'faz-performance-monitor',
    },
    showCheckers: [hasRDPermitOn(MACROS.USER.DVM.ADMINPRIV_SYSTEM_SETTINGS)],
    olhTag: 'noc_performance_monitor',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Local System Performance.',
  },
  fortiview_custom_view: {
    label: gettext('Custom Views'),
    path: '/fortiview/custom_view',
    onAppClick: async () => {
      const UserDashUniKey = 'fortiview_custom_view';
      const appTreeMap = fiStoreRouting.getAppTreeMap(fiStore.getState());
      let userMenus = appTreeMap[UserDashUniKey];
      if (!userMenus?.length) {
        if (!isReadWrite()) {
          return Promise.reject();
        }
        const { dashActions } = await import(
          'react_apps/ra_noc/contentview/dashActions'
        );
        await dashActions.create();
        return Promise.reject();
      }
      return Promise.resolve();
    },
    menuItemActions: [
      {
        key: 'create',
        label: gettext('Create New'),
        icon: 'add',
        onClick: async () => {
          const { dashActions } = await import(
            'react_apps/ra_noc/contentview/dashActions'
          );
          dashActions.create();
        },
        disabled: () => !isReadWrite(),
      },
    ],
    olhTag: 'noc_custom_view',
    olhCategory: 'fortiview',
    olhFaz: 'Fortiview -> Custom View.',
  },
};

const disableOne = (item, disabledMenuMap) => {
  const _disabledMenuMap = item.isNoc
    ? disabledMenuMap.noc
    : disabledMenuMap.ftv;
  return !!_disabledMenuMap[item.stateParams?.dashId];
};

const isDisabled = (app, state) => {
  const allApps = fiStoreRouting.getAllApps(state);
  let disabledMenuMap = getDisabledDashes(state);
  if (!disabledMenuMap) {
    return false;
  }
  let res = false;
  if (app.key === 'fortiview_custom_view') {
    return false;
  }
  // folder
  if (!app.state) {
    let children =
      fiStoreRouting
        .getAppTreeMap(state)
        .fortiview?.find?.((x) => x[0] === app.key)?.[1]
        ?.map((x) => x[0])
        .filter((x) => x) || [];
    res = children.every((x) => disableOne(allApps[x], disabledMenuMap));
  } else {
    res = disableOne(app, disabledMenuMap);
  }
  return res;
};

const makeMenu = (menu, { osType, isNoc = false }) => {
  // for fabric adom only, it will show dashboards from all adoms if device is available
  const fabricAdomSpecialMenuItems = Object.values(menu)
    .filter((x) => x.stateParams?.fabricAdomAppKey)
    .reduce((res, x) => {
      const key = x.stateParams.fabricAdomAppKey;
      res[key] = {
        ...x,
        path: x.stateParams.fabricAdomPath,
        stateParams: { ...x.stateParams, appUniKey: key },
        showCheckers: [
          inAdom([an.SecurityFabric]),
          hasDeviceByOsType([osType]),
        ],
      };
      return res;
    }, {});

  const combinedMenu = { ...menu, ...fabricAdomSpecialMenuItems };

  Object.values(combinedMenu).forEach((x) => {
    x.isNoc = isNoc;
    x.disabled = isDisabled;
    x.path = x.path.replace(
      '/fortiview/',
      `/fortiview/${isNoc ? 'noc' : 'ftv'}/`
    );
  });

  return combinedMenu;
};

const makeAllMenu = () => {
  const combinedMenu = {
    ...makeMenu(fgtSidemenus, { osType: MACROS.DVM.DVM_OS_TYPE_FOS }),
    ...makeMenu(fctSidemenus, {
      osType: MACROS.DVM.DVM_OS_TYPE_FCT,
    }),
    ...makeMenu(fddosSidemenus, {
      osType: MACROS.DVM.DVM_OS_TYPE_FDD,
    }),
    ...makeMenu(ffwSidemenus, {
      osType: MACROS.DVM.DVM_OS_TYPE_FFW,
    }),
    ...makeMenu(fwbSidemenus, {
      osType: MACROS.DVM.DVM_OS_TYPE_FWB,
    }),
    ...makeMenu(threatsEventsMenus, { isNoc: true }),
    ...makeMenu(trafficAnalysisMenus, { isNoc: true }),
    ...makeMenu(sdwanMenus, { isNoc: true }),
    ...makeMenu(fabricDevicesMenus, { isNoc: true }),
    ...makeMenu(restMenus, { isNoc: true }),
  };

  return combinedMenu;
};

export default {
  fortiview: {
    label: gettext('FortiView'),
    icon: 'noc',
    color: '#21908a',
    state: 'adom.default.soc',
    reloadState: 'adom.default.soc',
    gdprTool: true,
    showCheckers: [
      showFazApps,
      // for FMG without FAZ feature enabled, should remove 'SoC' tile for non-FGT/FOC ADOM. (Mantis 0590649)
      overSome([
        negate(isFmgOnly),
        inAdom([
          an.FortiCarrier,
          an.SecurityFabric,
          an.FortiFirewallCarrier,
          an.FortiFirewall,
          an.FortiProxy,
        ]),
      ]),
      // check if Fortiview/NOC-SOC module is disabled by CLI : 504237
      negate(isFortiviewDisabled), // this also considers disable on remote faz.
      // Hide in FAZ collector mode when sql disabled sql_status means sql enabled
      overSome([negate(inFazCollectorMode), fiSession.isFazSqlDbEnabled]),
      negate(
        inAdom([
          an.FortiAnalyzer,
          an.FortiCache,
          an.FortiManager,
          an.FortiSandbox,
          an.FortiAuthenticator,
          an.FortiNAC,
          an.Syslog,
          an.FortiSwitch,
        ])
      ),
      negate(inOthersAdom),
      negate(inGlobalAdom),
      hasRDPermitOn('log_viewer'),
    ],
  },
  ...makeAllMenu(),
};
