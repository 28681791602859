import {
  takeEvery,
  select,
  put,
  call,
  delay,
  takeLeading,
} from 'redux-saga/effects';
import { assign, get, pick } from 'lodash';

import { notifyNotifyAction } from '../utils/action';
import { getNumByGroup, getFetcherStarted } from './selectors';
import * as actions from './actions';
import * as api from './api';

const FETCH_DELAY = 5000;
const GROUP_MONITOR = 'task_monitor';

function* setNum({ payload }) {
  if (payload.collection !== 'task_summary') return;
  const taskNum = get(payload, ['fields', 'num_running']);
  const num = yield select(getNumByGroup(GROUP_MONITOR));
  if (taskNum !== num) {
    yield put(actions.setNum({ num: taskNum, group: GROUP_MONITOR }));
  }
}

function* startFetcher() {
  const fetcherStarted = yield select(getFetcherStarted);
  // const totalNum = yield select(getTotalNum);
  if (fetcherStarted) {
    // send the fetch action for other module to hook on task fetching
    yield put(actions.fetchTask());
    yield delay(FETCH_DELAY);
    yield call(startFetcher);
  }
}

function* fetchTasks() {
  const tasks = yield call(api.fetchTasks);
  for (let i = 0; i < tasks.length; i++) {
    const task = assign(pick(tasks[i], ['id', 'percent', 'title']), {
      group: GROUP_MONITOR,
    });
    yield put(actions.setTask(task));
  }
}

export function* watchTasks() {
  yield takeEvery(notifyNotifyAction.type, setNum);
  yield takeEvery(actions.startFetcher.type, startFetcher);
  // task fetching needs to wait for the first to finish
  yield takeLeading(actions.fetchTask.type, fetchTasks);
}
