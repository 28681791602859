export const getDynamicViews = (state) => {
  return state?.adom?.alert?.dynamicViews?.data || undefined;
};
export const getIsDynamicMenuApplied = (state) => {
  const applied = state?.adom?.alert?.dynamicViews?.appliedData;
  const data = getDynamicViews(state);
  return applied && applied === data;
};
export const getAlertState = (state) => state?.adom?.alert || {};
export const getAlertContentMenu = getDynamicViews;
