import { fiDvmListViewType } from 'fi-dvm';
import { fiSysConfig } from 'fi-session';
import { dispatch } from 'fistore';
import { goApp } from 'fistore/routing/slice';

const fiDvmListViewAppKeys = {
  [fiDvmListViewType.MANAGED_TABLE_VIEW]: 'dvm_device_group_table',
  [fiDvmListViewType.MANAGED_MAP_VIEW]: 'dvm_device_map',
  [fiDvmListViewType.MANAGED_RING_VIEW]: 'dvm_device_ring',
  [fiDvmListViewType.MANAGED_FOLDER_VIEW]: 'dvm_device_folder',
};

const fiFAZDvmListViewAppKeys = {
  [fiDvmListViewType.MANAGED_TABLE_VIEW]: 'fazdvm_group_table',
  [fiDvmListViewType.MANAGED_MAP_VIEW]: 'fazdvm_map',
};

////////////////////////////////////////////////////
function changeView(newView) {
  const states = fiSysConfig.hasFmgFeature()
    ? fiDvmListViewAppKeys
    : fiFAZDvmListViewAppKeys;
  dispatch(goApp({ key: states[newView] }));
}

export const viewCommands = (fiDvmActionsId) => ({
  [fiDvmActionsId.views.table]: {
    key: fiDvmActionsId.views.table,
    label: gettext('Table View'),
    icon: 'chart-table',
    exec: () => changeView(fiDvmListViewType.MANAGED_TABLE_VIEW),
  },
  [fiDvmActionsId.views.map]: {
    key: fiDvmActionsId.views.map,
    label: gettext('Map View'),
    icon: 'globe',
    exec: () => changeView(fiDvmListViewType.MANAGED_MAP_VIEW),
  },
  [fiDvmActionsId.views.ring]: {
    key: fiDvmActionsId.views.ring,
    label: gettext('Ring View'),
    icon: 'chart-donut',
    exec: () => changeView(fiDvmListViewType.MANAGED_RING_VIEW),
  },
  [fiDvmActionsId.views.folder]: {
    key: fiDvmActionsId.views.folder,
    label: gettext('Folder View'),
    icon: 'folder',
    exec: () => changeView(fiDvmListViewType.MANAGED_FOLDER_VIEW),
  },
});
