import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { fiSession } from 'fistore';

import { formatBytes } from 'kit/kit-number';
import { autofazId } from './util';
import { getDiskQuota } from './api';
import { getEffect } from '../common/utils';
import { FmkSSelect2, FmkErrorSpan, FmkInput } from 'react_components/rc_form';
import {
  NwProSection,
  NwProInputRow,
  NwProLine,
} from 'react_components/rc_layout';
import { useFormikContext, FormikConsumer } from 'formik';
import { NwCheckbox } from '@fafm/neowise-core';
import './adomFazEdit.less';
import { isNil, isObject } from 'lodash';

const QUOTA_SELECT_OPTIONS = [
  { id: 1, text: 'MB' },
  { id: MACROS.USER.SYS.GB_VS_MB_NUM, text: 'GB' },
  { id: MACROS.USER.SYS.TB_VS_MB_NUM, text: 'TB' },
];

const makeRatioSelectOptions = (start, end, step) => {
  const options = [];
  for (let i = start; i <= end; i = i + step) {
    options.push({ id: i, text: i + '%' });
  }
  return options;
};

export function AdomFazEdit({ setAvailableQuota = () => {}, isView = false }) {
  //max days
  const init_MAX_DAYS = 89478485;
  const MAX_DAYS = { DB_ONLINE: init_MAX_DAYS, LOG_OFFLINE: init_MAX_DAYS };

  //min days
  const sysConfig = useSelector(fiSession.getSysConfig);
  const init_MIN_DAYS = sysConfig['storage_age_limit'] || 0;
  const MIN_DAYS = { DB_ONLINE: init_MIN_DAYS, LOG_OFFLINE: init_MIN_DAYS };

  const PERIOD_SELECT_OPTIONS = [{ id: 24, text: gettext('Days') }];

  // the following 5 states are for display only
  const { values, setFieldValue } = useFormikContext();
  const [enableEditDbratio, setEnableEditDbratio] = useState(false);
  const [loadingAvailableQuota, setLoadingAvailableQuota] = useState(true);

  const fileRatio = useMemo(() => 100 - values.db_ratio, [values.db_ratio]);

  useEffect(() => {
    const effect = getEffect(() => setLoadingAvailableQuota(false));
    getDiskQuota()
      .then(
        function (resp) {
          let available_quota = parseInt(resp.available_quota);
          if (values.adomId > 0) {
            available_quota += values.old_quota;
          } else if (
            values.quota >= available_quota ||
            available_quota <= 1000
          ) {
            setFieldValue('quota', available_quota);
          }
          // Pass value back to parent
          setAvailableQuota((prev) => {
            if (isNil(prev) || !isObject(prev)) {
              return available_quota;
            }
            return { ...prev, available_quota: parseInt(available_quota) };
          });
          setFieldValue('available_quota', parseInt(available_quota)); // Required if Formik does not reinitialize
        },
        function () {
          setAvailableQuota((prev) => {
            const err = gettext('Not Available');
            if (typeof prev === 'object')
              return { ...prev, available_quota: err };
            return err;
          });
          setFieldValue('available_quota', gettext('Not available.'));
        }
      )
      .finally(() => {
        effect.apply();
      });
    return () => effect.cancel();
  }, [values.old_quota, values.adomId, values.quota]);

  return (
    <>
      <NwProSection title={gettext('Data Policy')}>
        <NwProInputRow
          label={gettext('Keep Logs for Analytics')}
          rowProps={{ labelIndent: 1 }}
        >
          <NwProLine>
            <div className={'tw-w-2/6'}>
              <FmkInput
                name='db_days'
                type='number'
                id='db_days'
                max={MAX_DAYS.DB_ONLINE}
                min={MIN_DAYS.DB_ONLINE}
                disabled={isView}
              />

              <FmkErrorSpan name={'db_days'} />
            </div>

            <FormikConsumer>
              {(formik) => {
                return (
                  <FmkSSelect2
                    name='db_days_select'
                    className='tw-w-2/6'
                    value={
                      formik.values['db_days_select'] ||
                      PERIOD_SELECT_OPTIONS[0].id
                    }
                    source={PERIOD_SELECT_OPTIONS}
                    automationId={autofazId('db_days_select')}
                    disabled={isView}
                  />
                );
              }}
            </FormikConsumer>
          </NwProLine>
        </NwProInputRow>

        <NwProInputRow
          label={gettext('Keep Logs for Archive')}
          rowProps={{ labelIndent: 1 }}
        >
          <NwProLine>
            <div className={'tw-w-2/6'}>
              <FmkInput
                name='file_days'
                type='number'
                automation-id={autofazId('file_days')}
                id='file_days'
                max={MAX_DAYS.LOG_OFFLINE}
                min={MIN_DAYS.DB_ONLINE}
                disabled={isView}
              />

              <FmkErrorSpan name='file_days' />
            </div>

            <FormikConsumer>
              {(formik) => {
                return (
                  <FmkSSelect2
                    className='tw-w-2/6'
                    name='file_days_select'
                    value={
                      formik.values['file_days_select'] ||
                      PERIOD_SELECT_OPTIONS[0].id
                    }
                    source={PERIOD_SELECT_OPTIONS}
                    automationId={autofazId('file_days_select')}
                    disabled={isView}
                  ></FmkSSelect2>
                );
              }}
            </FormikConsumer>
          </NwProLine>
        </NwProInputRow>
      </NwProSection>

      <NwProSection title={gettext('Disk Utilization')}>
        <NwProInputRow
          label={gettext('Allocated')}
          rowProps={{ labelIndent: 1 }}
        >
          <NwProLine>
            <div className={'tw-w-2/6'}>
              <FmkInput
                name='quota'
                type='number'
                automation-id={autofazId('quota')}
                id='quota'
                max={MAX_DAYS.LOG_OFFLINE}
                min='0'
                disabled={isView}
              />
              <FmkErrorSpan name='quota' />
            </div>

            <FmkSSelect2
              name='quota_select'
              className='tw-w-2/6'
              source={QUOTA_SELECT_OPTIONS}
              automationId={autofazId('quota_select')}
              disabled={isView}
            />

            {
              loadingAvailableQuota ? (
                gettext('Loading...')
              ) : (
                <label>
                  {gettext('Maximum Available')}:{' '}
                  {formatBytes(
                    1024 * 1024 * (values.available_quota || 0),
                    1,
                    false,
                    true
                  )}
                </label>
              ) /*{'0.0 KB'}$*/
            }
          </NwProLine>
        </NwProInputRow>

        <NwProInputRow
          label={gettext('Analytics: Archive')}
          rowProps={{ labelIndent: 1 }}
        >
          <NwProLine>
            <FormikConsumer>
              {(state) => {
                return (
                  <div className='tw-w-2/6'>
                    <FmkSSelect2
                      name='db_ratio'
                      value={values.db_ratio}
                      source={makeRatioSelectOptions(5, 95, 5)}
                      onChange={(id) => {
                        state.setFieldValue('db_ratio', parseInt(id));
                      }}
                      automationId={autofazId('db_ratio')}
                      disabled={isView || !enableEditDbratio}
                    />
                  </div>
                );
              }}
            </FormikConsumer>

            <div className={'tw-w-2/6'}>
              <FmkInput
                name='file_ratio'
                value={fileRatio + '%'}
                disabled='true'
              />
            </div>

            <NwCheckbox
              name='enable_edit_dbratio'
              checked={enableEditDbratio}
              onChange={(e) => setEnableEditDbratio(e.target.checked)}
              automation-id={autofazId('enablemodify')}
              disabled={isView}
            >
              {gettext('Modify')}
            </NwCheckbox>
          </NwProLine>
        </NwProInputRow>

        <NwProInputRow
          label={gettext('Alert and Delete When Usage Reaches')}
          rowProps={{ labelIndent: 1 }}
        >
          <div className={'tw-w-2/6'}>
            <FmkSSelect2
              name='alert_threshold'
              value={values.alert_threshold}
              source={makeRatioSelectOptions(50, 100, 5)}
              automationId={autofazId('alert_threshold')}
              disabled={isView}
            />
            <FmkErrorSpan name='alert_threshold' />
          </div>
        </NwProInputRow>

        <NwProInputRow label=' ' rowProps={{ labelIndent: 1 }}>
          <label id={'adom-faz-edit-explaination'}>
            {gettext(
              '*If analytic or archive log usages exceed the configured disk quota before the retention period expires, the oldest logs will be deleted.'
            )}
          </label>
        </NwProInputRow>
      </NwProSection>
    </>
  );
}
