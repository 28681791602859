import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fiSysConfig } from 'fi-session';
import { fiHttpGet } from 'fi-http';
import { fiDevType, getEuAvatarUrl } from './helpers';
import { PageLoading } from '../components';
import './IncidentEndpoint.less';
import fiUebaService from './fiUebaService';

export const IncidentEndpoint = ({
  epText,
  epid,
  euid,
  hideFirstRow,
  proxiedServer,
  proxiedAdom,
}) => {
  let isSupervisor = fiSysConfig.isFazSupervisor();
  const [assetDetailUrl, setAssetDetailUrl] = useState('');
  const [epData, setEpData] = useState({});
  const [euData, setEuData] = useState({});
  const [loading, setLoading] = useState(false);

  const loadData = () => {
    if (epid !== undefined) {
      if (!assetDetailUrl) {
        setAssetDetailUrl(
          fiUebaService.getAssetPath(epid, proxiedServer, proxiedAdom)
        );
      }
      setLoading(true);
      fiUebaService
        .getIncidentEndpoint(epid, epText, proxiedServer, proxiedAdom)
        .then(
          (epData) => {
            setLoading(false);
            setEpData(epData);
          },
          (err) => {
            setLoading(false);
            console.error(err); // eslint-disable-line
          }
        );

      if (!hideFirstRow) {
        setLoading(true);
        fiHttpGet('/p/util/endusers/', {
          params: { euids: [euid], proxiedServer, proxiedAdom },
        }).then(
          function (resp) {
            setLoading(false);
            if (resp.data.data && resp.data.data.length) {
              setEuData(resp.data.data[0]);
            }
          },
          function () {
            setLoading(false);
          }
        );
      }
    }
  };

  useEffect(() => {
    setAssetDetailUrl(
      fiUebaService.getAssetPath(epid, proxiedServer, proxiedAdom)
    );
    loadData();
  }, [proxiedServer, proxiedAdom]);

  useEffect(() => {
    setAssetDetailUrl(
      fiUebaService.getAssetPath(epid, proxiedServer, proxiedAdom)
    );
    loadData();
  }, [epid, proxiedServer, proxiedAdom]);

  const isEuidValid = () => fiUebaService.isEuidValid(euid);
  const isEpidValid = () => fiUebaService.isEpidValid(epid);
  const isVpnEp = () => fiUebaService.isVpnEp(epid);

  return (
    <div className='fortiview-ep-container-wrapper'>
      {epData.devid || epData.epip || epData.epname || euData.euid ? (
        <div className='ep-container'>
          {loading ? <PageLoading /> : null}
          {isEuidValid() && !hideFirstRow ? (
            <div className='ep-row row'>
              <div className='ep-label col-md-4'>
                <img
                  src={getEuAvatarUrl(
                    euData.euid,
                    epData.epid,
                    undefined,
                    undefined,
                    proxiedServer,
                    proxiedAdom
                  )}
                />
              </div>
              <div className='ep-value col-md-8'>{euData.euname}</div>
            </div>
          ) : null}
          {!isEuidValid() && !hideFirstRow && !loading ? (
            <div className='ep-row row invalid-euid'>
              <div className='col-md-12'>
                {gettext('No related user available.')}
              </div>
            </div>
          ) : null}
          <hr />
          <div className='ep-row row'>
            <div className='ep-label col-md-4'>{gettext('Topology')}</div>
            <div className='ep-value col-md-8'>
              <div>
                {epData.devid ? (
                  <span className='ffg ffg-firewall'></span>
                ) : null}
                {epData.devid}
              </div>
              <div className='topology-ep'>
                {epData.devid ? (
                  <span className='ep-dev-type-icon'>
                    {fiDevType('', epData.epdevtype) || '&nbsp;&nbsp;&nbsp;'}
                  </span>
                ) : null}
                {isEpidValid() && !isSupervisor ? (
                  <Link to={assetDetailUrl} target='_blank' rel='noreferrer'>
                    {epData.epname}
                  </Link>
                ) : null}
                {isEpidValid() && isSupervisor ? (
                  <span>{epData.epname}</span>
                ) : null}
                {isVpnEp() ? (
                  <span>{gettext('Untrackable VPN Endpoint')}</span>
                ) : null}
              </div>
            </div>
          </div>
          {epData.intf ? (
            <div className='ep-row row'>
              <div className='ep-label col-md-4'>{gettext('Interface')}</div>
              <div className='ep-value col-md-8'>
                {epData.zone.name} ({epData.zone.interface}: {epData.zone.port})
              </div>
            </div>
          ) : null}
          {epData.mac || epData.epip ? (
            <div className='ep-row row'>
              <div className='ep-label col-md-4'>{gettext('Addresses')}</div>
              <div className='ep-value col-md-8'>
                {epData.mac ? <div>MAC: {epData.mac}</div> : null}
                {epData.epip ? <div>IP: {epData.epip}</div> : null}
              </div>
            </div>
          ) : null}
          {epData.osname ? (
            <div className='ep-row row'>
              <div className='ep-label col-md-4'>
                {gettext('Operating System')}
              </div>
              <div className='ep-value col-md-8'>
                {fiDevType('', epData.osname)} {epData.osname}{' '}
                {epData.osversion}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {!epData.epip &&
      !epData.epname &&
      !epData.devid &&
      !euData.euid &&
      !loading ? (
        <div>
          <div>{gettext('No Data')}</div>
        </div>
      ) : null}
    </div>
  );
};
