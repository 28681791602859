import { useMemo } from 'react';
import { useField } from 'formik';
import { callAll } from './util/util';
import { ObjSSelect2 } from 'rc_select';
import { get } from 'lodash';
import { FmkErrorSpan } from '../formik_layout/FmkErrorSpan';

export const FmkDuoAlgorithmSelect2 = ({
  name,
  onChange,
  opts,
  displayValues = {},
  automationIdList = ['select1', 'select2'],
  ['automation-id']: autoID,
  required,
  validate,
  ...rest
}) => {
  const [, { value }, { setValue, setTouched }] = useField({
    name,
    validate,
  });

  const className = 'tw-flex-1';
  const [Algo1, Algo2] = value?.includes('-')
    ? value.split('-')
    : [value, null];

  const { Algo1Choices, Algo2Choices, fullMap, singleAlgoSet } = useMemo(() => {
    const algo1Map = {};
    const algo2Map = {};
    const fullMap = {};
    const singleAlgoSet = new Set();

    for (const key of Object.keys(opts || {})) {
      const [alg1, alg2] = key.split('-');
      algo1Map[alg1] = { id: alg1, text: displayValues[alg1] || alg1 };

      if (key.includes('-') && alg1 === Algo1) {
        algo2Map[alg2] = { id: alg2, text: displayValues[alg2] || alg2 };
      }

      if (key.includes('-')) {
        if (Reflect.has(fullMap, alg1)) {
          fullMap[alg1].push(alg2);
        } else {
          fullMap[alg1] = [alg2];
        }
      } else if (!key.includes('-')) {
        singleAlgoSet.add(alg1);
      }
    }

    return {
      Algo1Choices: Object.values(algo1Map),
      Algo2Choices: Object.values(algo2Map),
      fullMap,
      singleAlgoSet,
    };
  }, [opts, displayValues, Algo1]);

  const getValue = (val1, val2) => {
    if (val2) {
      return `${val1}-${val2}`;
    }
    return val1;
  };

  const setFieldValOnChange = (isField1) => (_, data) => {
    setTouched(true, false);
    if (isField1) {
      if (singleAlgoSet.has(data.id)) {
        setValue(getValue(data.id));
      } else {
        const _algo2 = Algo2 ? Algo2 : get(fullMap, [data.id, '0']);
        setValue(getValue(data.id, _algo2));
      }
    } else {
      setValue(getValue(Algo1, data.id));
    }
  };

  const _onChange =
    (isField1) =>
    (...args) =>
      callAll(setFieldValOnChange(isField1), onChange)(...args);

  return (
    <>
      <div className={className}>
        <ObjSSelect2
          value={Algo1}
          onChange={_onChange(true)}
          source={() => Object.values(Algo1Choices)}
          automationId={automationIdList[0] || autoID}
          {...rest}
        />
        {required && !Algo1 ? <FmkErrorSpan name={name} /> : null}
      </div>
      <div className={className}>
        <ObjSSelect2
          value={Algo2}
          onChange={_onChange(false)}
          source={() => Object.values(Algo2Choices)}
          automationId={automationIdList[1] || autoID}
          {...rest}
        />
        {required && !Algo2 ? <FmkErrorSpan name={name} /> : null}
      </div>
    </>
  );
};

FmkDuoAlgorithmSelect2.displayName = 'FmkDuoAlgorithmSelect2';
