import { get, isNil, isNumber, isString, isUndefined } from 'lodash';
import { deviceStatus } from '../constants/device_status';
import { DvmInterfaceType } from 'fi-interface';
import * as fiDvmFilterRules from '../constants/device_filter_rules';
import { fiDvmLogDataService } from './log_data_service';
import { formatInterfaceName } from 'kit-viewdata';

var getConnectionCss = function (oRec_connection) {
  let oRec = {};
  //may need extra info by passing the device obj
  if (oRec_connection['_fiDeviceId']) {
    oRec = oRec_connection;
    oRec_connection = oRec.connection;
  } else {
    oRec = { ...oRec_connection };
  }
  if (isUndefined(oRec_connection?.conn)) {
    const connObj = oRec.conn || {};
    const status = get(connObj, 'status', MACROS.DVM.DVM_CONN_STATUS_UNKNOWN);
    oRec_connection = { ...connObj, conn: status };
  }
  let css, ititle, detailsTxt, isWaitingToAutolink;
  /* status for devices in FMG (model dev/ha icon: grey, up: green, down: orange)
   * Device:
   *   model device, use model device icon
   *   regular device tunnel up, use up icon
   *   regular device tunnel down, use down icon
   * HA:
   *   model ha, use model device icon
   *   regular ha tunnel up, use ha green
   *   regular ha tunnel down, use ha orange
   */
  switch (oRec_connection.conn) {
    case deviceStatus.conn.unknown:
      css = { name: '', className: '' };
      if (Array.isArray(oRec_connection.ha) && oRec_connection.ha.length > 0) {
        if (oRec.model_dev) {
          ititle = gettext('Model Device');
          css = { name: 'device', className: 'color-grey' };
        } else {
          ititle = gettext('HA Device');
          css = { name: 'ha', className: 'color-grey' };
        }
      } else {
        css = { name: 'device', className: 'color-grey' };
        ititle = gettext('Model Device');
      }
      break;

    case deviceStatus.conn.connected:
      ititle = gettext('Connection Up');
      if (Array.isArray(oRec_connection.ha) && oRec_connection.ha.length > 0) {
        // HA
        if (oRec.model_dev) {
          // Model HA Cluster
          css = { name: 'device', className: 'color-grey' };
          ititle = gettext('Model Device');
        } else {
          // HA device
          css = { name: 'ha', className: 'color-green' };
          ititle = gettext('HA Device Connection Up');
        }
      } else if (oRec.model_dev) {
        // Model device
        css = { name: 'device', className: 'color-grey' };
        ititle = gettext('Model Device');
      } else {
        // regular device
        css = { name: 'up', className: 'color-green' };
        ititle = gettext('Connection Up');
      }
      break;

    case deviceStatus.conn.down:
      ititle = gettext('Connection Down');
      if (Array.isArray(oRec_connection.ha) && oRec_connection.ha.length > 0) {
        // HA
        if (oRec.model_dev) {
          // Model HA Cluster
          css = { name: 'device', className: 'color-grey' };
          ititle = gettext('Model Device');
        } else {
          // HA device
          css = { name: 'ha', className: 'color-orange' };
          ititle = gettext('HA Device Connection Down');
        }
      } else if (oRec.model_dev) {
        // Model device
        css = { name: 'device', className: 'color-grey' };
        ititle = gettext('Model Device');
      } else {
        css = { name: 'down', className: 'color-orange' };
        ititle = gettext('Connection Down');
      }
      break;

    default:
      css = { name: 'device', className: 'color-grey' };
      ititle = gettext('Connection Unknown');
      break;
  }
  return {
    css: css,
    ititle: ititle,
    detailsTxt: detailsTxt,
    isWaitingToAutolink: isWaitingToAutolink,
  };
};

const RMA_PREFIX = '_RMA_';

// NOTE: update src/web_new/static/js/react_apps/ra_dvm/wizard/swap_device/request.js
// if this function is changed
function isRmaRule(rule) {
  return rule.name.startsWith(RMA_PREFIX);
}

function getRmaStatus(device) {
  return (currentSN) => {
    // { did, seq, type, flags, adm_usr, sn, old_sn }
    const rule = device.onBoardRuleList?.find(
      (rule) => isRmaRule(rule) && rule.old_sn === currentSN
    );

    if (!rule) {
      return;
    }

    const rma = {
      css: { name: 'settings' },
      ititle: gettext(
        'The device replacement will be triggered when "%s" connects.'
      ).printf([rule.sn]),
    };

    return {
      new_sn: rule.sn,
      old_sn: currentSN,
      updateCSS: ({ css, ititle, ...rest }) => {
        return {
          ...rest,
          css: { ...css, ...rma.css },
          ititle: `${rma.ititle}\n\n${ititle}`,
        };
      },
    };
  };
}

function getDeviceCSS(device) {
  const connection = getConnectionCss(device);
  const rma = getRmaStatus(device)(device.sn);

  return rma ? rma.updateCSS(connection) : connection;
}

var getInterfacePortCss = function (oRec_port) {
  var t = DvmInterfaceType.get(oRec_port.type);
  return {
    ...t,
    ititle: t.txt,
    txt: formatInterfaceName(oRec_port, oRec_port.name),
    css: t.css,
    type: t.txt,
  };
};

// mantis 440406, don't show vdom mode in FAZ
var fazDevNameParser = function (oRec) {
  return getName(oRec, 'name', true);
};

var getName = function (oRec, colId, faz) {
  var txt = '',
    css = '';
  var ititle;
  var vmode = oRec.vmode;
  var group = oRec.isGrp;
  var platform = oRec.platform;

  if (vmode || platform == 'vdom') {
    if (faz) {
      // Mantis 440406, vdom mode text
      txt = oRec.name;
      ititle = 'VDOM';
    } else {
      switch (vmode) {
        case 'nat':
          txt = oRec.name + ' [NAT]';
          ititle = 'NAT VDOM';
          break;
        case 'tp':
          txt = oRec.name + ' [' + gettext('Transparent') + ']';
          ititle = gettext('Transparent VDOM');
          break;
        default:
          txt = oRec.name;
          ititle = 'VDOM';
          break;
      }
      // Mantis 304876, remove management vdom text
      if (oRec.rtype === deviceStatus.rtype.vdomMgt) {
        txt += ' (' + gettext('Management') + ')';
        ititle = gettext('Management VDOM');
      }
    }

    css = { name: 'vdom', className: 'color-grey' };
  } else if (group) {
    txt = oRec.name;
    ititle = gettext('Device Group');
    css = { name: 'group', className: 'color-grey' };
  } else {
    txt = oRec.name;
    ititle = gettext('Device');
    if (
      oRec.hasOwnProperty(deviceStatus.field.connectivity) ||
      oRec.hasOwnProperty('conn')
    ) {
      var _css = getConnectionCss(oRec);
      css = _css.css;
      ititle = _css.ititle;
    } else if (oRec['ha_cluster'] && oRec['ha_cluster'].ha) {
      // for unregister device in faz
      css = { name: 'ha', className: 'color-grey' };
      ititle = gettext('HA Device');
    } else {
      css = { name: 'device', className: 'color-grey' };
    }
  }

  return { txt: txt, css: css, _shared_: oRec, ititle: ititle };
};

var getConnectivity = function (oRec) {
  var css,
    txt = '',
    title;
  var _cd = [];
  if (
    isUndefined(oRec.vmode) &&
    !isUndefined(oRec[deviceStatus.field.connectivity])
  ) {
    var _css = getConnectionCss(oRec[deviceStatus.field.connectivity]);
    css = _css.css;
    title = _css.ititle;

    var _ha;
    if (Array.isArray(oRec[deviceStatus.field.connectivity].ha)) {
      for (
        var i = 0;
        i < oRec[deviceStatus.field.connectivity].ha.length;
        i++
      ) {
        _ha = oRec[deviceStatus.field.connectivity].ha[i];
        var cdtitle = '';
        var cd_css = getConnectionCss(_ha);
        if (
          MACROS.SYS.IMG_TYPE == MACROS.SYS.PRODUCT_FMG &&
          !isUndefined(_ha.role)
        ) {
          //for fmg
          cdtitle = '%s (%s)'.printf([
            _ha[deviceStatus.field.deviceName] ||
              _ha[deviceStatus.field.serial_number],
            _ha.role === 1 ? gettext('Primary') : gettext('Secondary'),
          ]);
        } else {
          //for faz
          cdtitle = '%s'.printf([_ha[deviceStatus.field.deviceName]]);
        }
        _cd.push({
          css: cd_css.css,
          ititle: cd_css.ititle,
          txt: cdtitle,
        });
      }
    }
  }
  return {
    css: css,
    title: title,
    txt: txt,
    HAs: [],
    _shared_: oRec,
    ititle: title,
    cd: _cd,
  };
};

var getSN = function (oRec) {
  var css = '',
    txt = '',
    title = '';
  var _cd = [];
  if (
    isUndefined(oRec.vmode) &&
    !isUndefined(oRec[deviceStatus.field.connectivity])
  ) {
    var _ha;
    if (Array.isArray(oRec[deviceStatus.field.connectivity].ha)) {
      for (
        var i = 0;
        i < oRec[deviceStatus.field.connectivity].ha.length;
        i++
      ) {
        _ha = oRec[deviceStatus.field.connectivity].ha[i];
        var cdtitle = '';
        if (
          MACROS.SYS.IMG_TYPE == MACROS.SYS.PRODUCT_FMG &&
          !isUndefined(_ha.role)
        ) {
          //for fmg
          cdtitle = '%s (%s)'.printf([
            _ha[deviceStatus.field.serial_number],
            _ha.role === 1 ? gettext('Primary') : gettext('Secondary'),
          ]);
        } else {
          //for faz
          cdtitle = '%s'.printf([_ha[deviceStatus.field.serial_number]]);
        }
        _cd.push({
          css: '',
          ititle: '',
          txt: cdtitle,
        });
        txt += cdtitle;
      }
    } else {
      txt = oRec[deviceStatus.field.serial_number];
    }
  } else {
    txt = oRec[deviceStatus.field.serial_number];
  }
  return {
    css: css,
    title: title,
    txt: txt,
    SNs: [],
    _shared_: oRec,
    ititle: title,
    cd: _cd,
  };
};

const _PROFILE_NAMES = [
  /*'wifi_prof', 'fsw_prof',*/ 'dev_prof',
  'cli_prof',
  'prerun_cliprof',
  'ipsec_prof',
  'router_prof',
  'sdwan_prof',
  'fext_prof',
  'tmpl_grp',
  'router_bgp',
];
const getAllProfileStatus = (oRec) => {
  let parsedCellArray = [];
  _PROFILE_NAMES.forEach((type) => {
    parsedCellArray.push({ ...getProfileStatus(oRec, type), type });
  });
  return {
    css: '',
    txt: '',
    txtData: parsedCellArray,
    _shared_: oRec,
    ititle: '',
  };
};
var getProfileStatus = function (oRec, type) {
  var css,
    txt,
    title = '';
  var data = oRec[deviceStatus.field.profStatus]
    ? oRec[deviceStatus.field.profStatus][type] || {}
    : {};
  txt = data.name || '';
  if (data.status === MACROS.PO.PM3_PKG_STATUS_INSTALLED) {
    css = 'ffg ffg-yes color-green';
    title = gettext('Synchronized');
  } else if (data.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
    css = 'ffg ffg-warning color-orange';
    title = gettext('Modified');
  } else if (data.status === MACROS.PO.PM3_PKG_STATUS_UNKNOWN) {
    css = 'ffg ffg-unregistered color-grey';
    title = gettext('Unknown');
  }
  return {
    css: css,
    txt: txt,
    title: title,
    _shared_: oRec,
    ititle: title,
  };
};

var getFwmprofStatus = function (oRec) {
  var css,
    txt,
    title = '';
  var data = oRec[deviceStatus.field.fwmprofStatus]
    ? oRec[deviceStatus.field.fwmprofStatus] || {}
    : {};
  txt = data.name || '';
  if (data.status === MACROS.PO.PM3_PKG_STATUS_INSTALLED) {
    css = 'ffg ffg-yes color-green';
    title = gettext('Synchronized');
  } else if (data.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
    css = 'ffg ffg-warning color-orange';
    title = gettext('Modified');
  } else if (data.status === MACROS.PO.PM3_PKG_STATUS_UNKNOWN) {
    css = 'ffg ffg-unregistered color-grey';
    title = gettext('Unknown');
  }
  return {
    css: css,
    txt: txt,
    title: title,
    _shared_: oRec,
    ititle: title,
  };
};

var getCfgStatus = function (oRec) {
  var css,
    txt,
    title = '';
  var rules = fiDvmFilterRules.get().configStatus;

  if (rules.pendingAutoUpdate(oRec)) {
    css = 'ffg ffg-warning color-orange';
    title = txt = gettext('Modified (recent auto-updated)');
  } else if (rules.pending(oRec)) {
    css = 'ffg ffg-warning color-orange';
    title = txt = gettext('Modified');
  } else if (rules.warning(oRec)) {
    css = 'ffg ffg-no color-red';
    title = txt = gettext('Conflict');
  } else if (rules.outOfSync(oRec)) {
    css = 'ffg ffg-no color-red';
    title = txt = gettext('Out of Sync');
  } else if (rules.autoUpdate(oRec)) {
    css = 'ffg ffg-yes color-green';
    title = txt = gettext('Auto-update');
  } else if (rules.synchronized(oRec)) {
    css = 'ffg ffg-yes color-green';
    title = txt = gettext('Synchronized');
  } else if (rules.pedningAutoPush(oRec)) {
    css = 'ffg ffg-warning color-orange';
    title = txt = gettext('Modified');
  } else {
    css = 'ffg ffg-unregistered color-grey';
    title = txt = gettext('Unknown');
  }

  return {
    css: css,
    txt: txt,
    title: title,
    _shared_: oRec,
    ititle: title,
  };
};

var getPkgStatus = function (oRec, colId) {
  var css,
    title = '',
    txt = '';
  var sData = oRec[colId];
  var status = deviceStatus[colId];
  if (isUndefined(sData)) {
    // data not yet loaded, display blank
    return {
      css: '',
      title: '',
      oid: '',
      txt: '',
      _shared_: oRec,
      ititle: '',
    };
  } else if (oRec.rtype !== deviceStatus.rtype.deviceHasVdom) {
    sData = sData || { status: 0, name: '' };
    switch (sData.status) {
      case status.synchronized: //sync
        css = 'ffg ffg-yes color-green';
        title = gettext('Synchronized');
        break;
      case status.modified: // modified
        css = 'ffg ffg-warning color-orange';
        title = gettext('Modified');
        break;
      case status.imported:
        css = 'ffg ffg-yes color-green';
        title = gettext('Imported');
        break;
      case status.unknown:
        css = 'ffg ffg-unregistered color-grey';
        title = gettext('Unknown');
        break;
      case status.outofsync:
        css = 'ffg ffg-no color-red';
        title = gettext('Out of Sync');
        break;
      case status.conflict:
        css = 'ffg ffg-no color-red';
        title = gettext('Conflict');
        break;
      case status.neverInstalled:
        css = 'ffg ffg-warning color-orange';
        title = gettext(
          'No policy imported and/or no policy package installed'
        );
        txt = gettext('Never Installed');
        break;
      case status.autopush:
        css = 'ffg ffg-warning color-orange';
        title = gettext('policy package will be pushed when device connected');
        txt = gettext('Auto Push');
        break;
      default:
        css = 'ffg ffg-unregistered color-grey';
        title = gettext('Unknown:') + sData.status;
        txt = gettext('Unknown');
    }
  } else {
    css = 'ffg ffg-unregistered color-grey';
    title = gettext('Unknown');
    txt = gettext('Unknown');
  }
  return {
    css: css,
    title: title,
    oid: sData.oid,
    txt: sData.name || txt,
    _shared_: oRec,
    ititle: title,
  };
};

var getLicense = function (oRec, colId) {
  var css,
    title = '';
  var sData = oRec[colId];
  var status = deviceStatus.license;

  if (!sData) {
    // vdom does not have this field and keep display empty
    return { txt: '', _shared_: oRec };
  }

  switch (sData.status) {
    case status.valid:
      css = 'ffg ffg-yes color-green';
      title = gettext('Valid');
      break;
    case status.expire_soon:
      css = 'ffg ffg-online-help color-orange';
      title = gettext('Expire Soon: %s').printf([sData.txt]);
      break;
    case status.expired:
      css = 'ffg ffg-no color-red';
      title = gettext('Expired');
      break;
    default:
      css = 'ffg ffg-unregistered color-grey';
      title = gettext('Unknown');
      break;
  }
  return {
    css: css,
    title: title,
    txt: title,
    _shared_: oRec,
    ititle: title,
  };
};

var getLogRate = function (oRec, colId) {
  var sData = oRec[colId];
  return {
    txt: isNil(sData) || sData === -1 ? 'N/A' : sData,
    _shared_: oRec,
  };
};

function getHADevs(rec) {
  return rec[deviceStatus.field.connectivity]?.ha;
}

function getHADevsForVdom(rec) {
  const pData = rec?._pData;
  return pData?.[deviceStatus.field.connectivity]?.ha;
}

const calculateQuotaUsedForHAs = (rec, haDevs) => {
  const isVdom = deviceStatus.isVdom(rec.rtype);
  const vdomName = rec.name;

  const res = [];

  for (const dev of haDevs) {
    const devToGet = { _deviceName: dev.name, sn: dev.sn, ...dev };
    if (isVdom) {
      devToGet.vdom = vdomName; // for _formatQuotaForSingleDev
      devToGet.platform = 'vdom'; // for getLogStatsByDevice
      devToGet.name = vdomName; // for getLogStatsByDevice
    }
    const logStats = fiDvmLogDataService.getLogStatsByDevice(devToGet);
    if (logStats) {
      const used = isVdom ? logStats.storageUsed : logStats.totalStorageUsed;
      res.push({
        dev: devToGet,
        used,
      });
    }
  }

  return res;
};

const calculateQuotaUsed = (defaultUsed) => (rec) => {
  const isVdom = deviceStatus.isVdom(rec.rtype);
  const haDevs = isVdom ? getHADevsForVdom(rec) : getHADevs(rec);
  const isHa = Array.isArray(haDevs) && haDevs.length > 0;

  if (isHa) {
    return calculateQuotaUsedForHAs(rec, haDevs);
  }

  let usageData = fiDvmLogDataService.getLogStatsByDevice(rec);
  if (usageData) {
    return rec._isDevice ? usageData.totalStorageUsed : usageData.storageUsed;
  }

  return defaultUsed;
};

const fn_percentage = function (used, quota) {
  if (quota === 0) return 0; // 0 means unlimited quota
  used = used || 0;
  return quota > 0 ? ((100 * used) / quota).toFixed(1) : 0;
};

var fn_css = function (per) {
  var _css = 'progress-bar-info';
  if (per < 40) {
    _css = 'progress-bar-info';
  } else if (per < 60) {
    _css = 'progress-bar-success';
  } else if (per < 80) {
    _css = 'progress-bar-warning';
  } else if (per < 100) {
    _css = 'progress-bar-danger';
  }
  return _css;
};
var getQuota = function (oRec, colId) {
  var sData = oRec[colId];
  sData = sData || { used: 0 }; //'quota': 1000, IS from adom quota...
  var colObj = {
    quota: sData.quota,
    // used:sData.used,
    calculateQuotaUsed: calculateQuotaUsed(sData.used),
    fnpercentage: fn_percentage,
    css: fn_css,
    _shared_: oRec,
  };
  return colObj;
};
var getControllerCounter = function (oRec, colId) {
  var sData = oRec[colId];
  return {
    expanded: {
      [MACROS.USER.DVM.ASSETS_FORTIEXTENDER_ABBR]: false,
      [MACROS.USER.DVM.ASSETS_FORTIAP_ABBR]: false,
      [MACROS.USER.DVM.ASSETS_FORTISWITCH_ABBR]: false,
    },
    data: sData,
    _shared_: oRec,
  };
};
var getMgtMode = function (oRec) {
  var txt = '';
  switch (oRec['manage_mode']) {
    case MACROS.DVM.DVM_MGMT_MODE_FAZ:
      txt = gettext('Logging Only');
      break;
    case MACROS.DVM.DVM_MGMT_MODE_FMG:
      txt = gettext('FortiManager Management Only');
      break;
    case MACROS.DVM.DVM_MGMT_MODE_FMG_FAZ:
      txt = gettext('Configuration & Logging');
      break;
    case MACROS.DVM.DVM_MGMT_MODE_UNREG:
      txt = gettext('Unauthorized');
      break;
    default:
  }
  return { txt: txt, _shared_: oRec };
};

export const fiDvmTableCellParser = {
  getData: function (oRec, colId) {
    var oData = null;
    var field = deviceStatus.field;
    if (_PROFILE_NAMES.indexOf(colId) >= 0) {
      oData = getProfileStatus(oRec, colId);
      return oData;
    }
    switch (colId) {
      case field.deviceName:
        oData = getName(oRec, colId);
        break;
      case field.cfgStatus:
        oData = getCfgStatus(oRec, colId);
        break;
      case field.profStatus:
        oData = getAllProfileStatus(oRec, colId);
        break;
      case field.fwmprofStatus:
        oData = getFwmprofStatus(oRec, colId);
        break;
      case field.pkgStatus:
        oData = getPkgStatus(oRec, colId);
        break;
      case field.connectivity:
        oData = getConnectivity(oRec, colId);
        break;
      case field.quota:
        oData = getQuota(oRec, colId);
        break;
      case field.license:
        oData = getLicense(oRec, colId);
        break;
      case field.logRate:
        oData = getLogRate(oRec, colId);
        break;
      case field.manage_mode:
        oData = getMgtMode(oRec, colId);
        break;
      case field.controller_counter:
        oData = getControllerCounter(oRec, colId);
        break;
      case field.serial_number:
        oData = getSN(oRec, colId);
        break;
      default:
        oData = oRec[colId] || '';
        if (isNumber(oData) || isString(oData)) {
          oData = { txt: oData, _shared_: oRec };
        } else {
          oData = { txt: JSON.stringify(oData), _shared_: oRec };
        }
        break;
    }
    return oData;
  },
  getConnectionCss: getConnectionCss,
  getRmaStatus,
  getDeviceCSS,
  getInterfacePortCss: getInterfacePortCss,
  getCfgStatus: getCfgStatus,
  getPkgStatus: getPkgStatus,
  getLicense: getLicense,
  fazDevNameParser: fazDevNameParser,

  getName,
  getConnectivity,
  getMgtMode,
  getSN,
};
