import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { SelectPane } from '@fafm/filego2';
import { formatSelectedWithIcon } from 'fi-dev-format';
import { usePrevious } from 'rh_util_hooks';
import { xor } from 'lodash';
const { DEVICE_TYPE_ALL } = MACROS.FAZ_DVM;

const defTreeOpt = {
  source: [
    {
      label: 'All Devices',
      value: 'All_Device',
      type: MACROS.DVM.DVM_OS_TYPE_FOS,
      logviewType: DEVICE_TYPE_ALL,
      sn: -1,
      children: [],
    },
  ],
  // width: '400px', // tree pane width
  // resultStyle: 'prefer_leaves', // prefer_parents|prefer_leaves|default
  resultStyle: 'prefer_parents', // prefer_parents|prefer_leaves|default
  position: 'bottom', // tree pane position
  itemHeight: 20, // tree pane item height
  indentWidth: 15, // level indent width
  idAttr: 'value', // Define id key
  textAttr: 'label', // Define text  key
  childrenAttr: 'children', // Define child node key
  closedAttr: 'closed', // Define closed attribute key
  disabledAttr: 'disabled', // Define disabled attribute key
  maxHeight: 500, // tree pane max height
  value: [],
  flip: false, // Allow covering the trigger element.
  zIndex: 7,
  onchange: () => {},
  multipleSelect: true, //!(props.isCsf || props.isSingle), //Allows selecting multiple treeNodes.
  noDataText: gettext('No Device'), // A tip without data.
  // excludedIds: [-1], // exclued items, here is all device group parents
};

export const DeviceTreeSelect = ({
  treeData,
  selectOnChange,
  initSelectedValues,
  allDeviceValue,
  formatChoice,
  formatDisplay, // for the item display while a single selected on the tree
  formatMultiDisplay, // for the items display while multiple selected on the tree
  treeOpt = {},
  noDataText = gettext('No Device Selected'),
}) => {
  const formatChoiceFn = useMemo(() => {
    return formatChoice || formatSelectedWithIcon;
  }, [formatChoice]);

  const treePaneRef = useRef(null);
  const treeViewRef = useRef(null);
  const [currentSelected, setCurrentSelected] = useState(
    initSelectedValues || []
  );

  const prevSelected = usePrevious(currentSelected);
  useEffect(() => {
    let newVals = initSelectedValues || [];
    let changed = xor(prevSelected, newVals).length;
    if (changed) {
      setCurrentSelected(newVals);
    }
  }, [initSelectedValues, prevSelected]);

  const treeValueMapping = useMemo(
    () =>
      getTreeValueMapping(treeData, formatDisplay || defaultDisplayFormatter),
    [treeData, formatDisplay]
  );

  const onChangeFn = useCallback(
    (selectedIds, newSelected) => {
      if (selectedIds.length === Object.keys(treeValueMapping).length) {
        newSelected = [allDeviceValue || 'All_Device'];
        selectedIds = [allDeviceValue || 'All_Device'];
      }
      setCurrentSelected(selectedIds);
      selectOnChange(newSelected);
    },
    [treeValueMapping, selectOnChange]
  );
  const onChangeFn4SingleMode = useCallback(
    (selectedId, newSelected) => {
      setCurrentSelected([selectedId]);
      selectOnChange([newSelected]);
    },
    [treeValueMapping, selectOnChange]
  );

  useEffect(() => {
    let opts = {
      ...defTreeOpt,
      ...treeOpt,
      source: treeData,
      value: currentSelected,
      // onChange: onChangeFn,
      formatChoiceHTML: formatChoiceFn, //(item, hilit)=>formatChoiceFn(item, '', hilit),
      // [formatChoice ? 'formatChoiceFn' : 'formatChoiceHTML']: formatChoiceFn,
    };
    opts.onChange = opts.multipleSelect ? onChangeFn : onChangeFn4SingleMode;
    if (!treePaneRef.current) {
      treePaneRef.current = new SelectPane(treeViewRef.current, opts);
    } else {
      treePaneRef.current.setConfig(opts);
    }
  }, [
    treeOpt,
    treeData,
    currentSelected,
    onChangeFn,
    formatChoice,
    formatChoiceFn,
  ]);

  // const renderRow = useCallback(
  //   (row, searchTerm) => {
  //     const html = formatChoiceFn(row, searchTerm);
  //     return <span dangerouslySetInnerHTML={{ __html: html }}></span>;
  //   },
  //   [formatChoiceFn]
  // );

  const toggleSelectPanel = useCallback(
    (evt) => {
      if (treePaneRef.current && !evt.isDefaultPrevented()) {
        evt.preventDefault();
        treePaneRef.current.toggleOpen(!treePaneRef.current.getIsOpen());
      }
    },
    [treePaneRef.current]
  );

  const toggleSelectPanelOnKey = useCallback(
    (evt) => {
      if (['Enter', ' ', 'Spacebar'].indexOf(evt.key) >= 0) {
        return toggleSelectPanel(evt);
      }
    },
    [toggleSelectPanel]
  );

  return (
    <div>
      {currentSelected.length === 0 && (
        <nw-button
          type='text'
          className='select-trigger'
          onClick={toggleSelectPanel}
          onKeyUp={toggleSelectPanelOnKey}
          caret
        >
          {noDataText}
        </nw-button>
      )}
      {currentSelected.length === 1 && (
        <nw-button
          type='text'
          className='select-trigger'
          onClick={toggleSelectPanel}
          onKeyUp={toggleSelectPanelOnKey}
          caret
        >
          {treeValueMapping[currentSelected[0]]}
        </nw-button>
      )}
      {currentSelected.length > 1 && (
        <nw-button
          type='text'
          className='select-trigger'
          onClick={toggleSelectPanel}
          onKeyUp={toggleSelectPanelOnKey}
          caret
        >
          {formatMultiDisplay
            ? formatMultiDisplay({ currentSelected })
            : currentSelected.length + ' ' + gettext('Devices Selected')}
        </nw-button>
      )}
      <div ref={treeViewRef} style={{ zIndex: 1 }}></div>
    </div>
  );
};

const getTreeValueMapping = (treeData, formatDisplay) => {
  let valMapping = {};
  const getLeaves = (treeNodes) => {
    treeNodes.forEach((node) => {
      if (node.children) {
        getLeaves(node.children);
      }
      valMapping[node.value] = formatDisplay(node);
    });
  };
  treeData && getLeaves(treeData);
  return valMapping;
};

const defaultDisplayFormatter = (node) => node.label;
