import $ from 'jquery';
import { fiIdleTimer } from 'fiutil/idle_timer';
import { fiLogout } from './logout';
import { fiStore, fiSession } from 'fistore';
const KEEP_ALIVE_INTERVAL = 5000;
const NUM_OF_FAILURE = 5;
const PAYLOAD = JSON.stringify({ url: '/gui/session-live', method: 'get' });

let kaTid = null;
let ignoreConnectionError = false;
let count = 0;
let alertPop = null;
let currentData = {};

export const polling = () => {
  if (kaTid) clearTimeout(kaTid);

  _poll().then(
    function () {
      // repeat polling
      kaTid = setTimeout(polling, KEEP_ALIVE_INTERVAL);
      showAlert(false);
    },
    function () {
      // During firmware upgrade, we keep polling until the server
      // boots up. After reboot, the server should find our invalid
      // login cookie, and send us an http 200 with some string to
      // tell us to open login page.
      kaTid = setTimeout(polling, KEEP_ALIVE_INTERVAL);

      // If ignoring connection errors, then we do not need to broadcast connection-lost event
      if (ignoreConnectionError) {
        return;
      }

      // If NOT ignoring connection errors, then broadcast connection lost event.
      // This will probably display a "Connection Lost" message.
      // When server becomes available, an invalid session should send user to login page.
      if (count > NUM_OF_FAILURE) {
        showAlert(true);
      }
    }
  );
};

export const setIgnoreConnectionError = (tof) => (ignoreConnectionError = tof);

export const ignoreConnectionErrorForSeconds = (seconds = 120) => {
  setIgnoreConnectionError(true);
  setTimeout(function () {
    setIgnoreConnectionError(false);
  }, 1000 * seconds);
};

export const getData = () => currentData;

function _poll() {
  let abort = new AbortController();
  let rmto = setTimeout(() => abort.abort(), KEEP_ALIVE_INTERVAL * 3);

  return fetch('/cgi-bin/module/flatui_auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: PAYLOAD,
    signal: abort.signal,
  })
    .then((response) => {
      clearTimeout(rmto);
      return response.json();
    })
    .then((resp) => {
      count = 0;

      currentData = resp?.result?.[0]?.data || {};
      if (!currentData.valid) {
        // logout
        fiLogout();
      } else {
        fiIdleTimer.check(currentData.time_left || 0);
        fiStore.dispatch(fiSession.setSysTimestamp(currentData.timestamp));
      }
    })
    .catch((err) => {
      if (count <= NUM_OF_FAILURE) {
        count = count + 1;
      }

      // Important. With this, we can handle error
      // when the error is not "logout".
      return Promise.reject(err);
    });
}

function showAlert(connectionLost) {
  if (connectionLost) {
    showMessage();
  } else {
    removeMessage();
  }
}

function showMessage() {
  if (!alertPop) {
    const productName =
      MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FAZ
        ? 'FortiAnalyzer'
        : 'FortiManager';
    const TXT_HDR = gettext('Lost connection to %(productName)s').printfd({
      productName,
    });
    const TXT_MSG = gettext('Attempting to reconnect...');
    const startTime = Date.now();

    let html = `
      <div class="modal-backdrop in tw-z-50"></div>
      <div class="connection-lost-msg tw-z-50 tw-absolute">
      <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">${TXT_HDR} - <span class="connection-lost-eslaped-time">${genElapsedTime(
      startTime
    )}</h3>
      </div>
      <div class="modal-body">
        <span class="ffg ffg-spinner loading"></span> ${TXT_MSG}
      </div>
      </div>
      </div>
    `;

    let msgEl = $(html);
    msgEl.appendTo('body');

    let t = setInterval(() => {
      msgEl
        .find('.connection-lost-eslaped-time')
        .html(genElapsedTime(startTime));
    }, 1000);

    alertPop = function () {
      msgEl.remove();
      t && clearInterval(t);
      alertPop = null;
    };
  }
}

function removeMessage() {
  alertPop && alertPop();
}

function genElapsedTime(startTime) {
  const totalSec = Math.floor((Date.now() - startTime) / 1000);
  const min = Math.floor(totalSec / 60);
  const sec = totalSec % 60;
  return `${min}:${(sec < 10 ? '0' : '') + sec}`;
}
