//import { callbackify } from "util";

import { flatui_proxy } from './base';

//const buildUrl=url=>cmd=>url+cmd

/************ADOM**********************/
/**scope:[{oid:102}] */
const unlock_request = (
  url,
  forceUnlockDirty = false,
  forceUnlockOther = false,
  batch = []
) =>
  flatui_proxy({
    url: url,
    method: 'unlock',
    params: {
      forceUnlockDirty: forceUnlockDirty,
      forceUnlockOther: forceUnlockOther,
      batch: batch,
    },
  });
const save_request = (url, unlockAfterSave = false, batch = []) =>
  flatui_proxy({
    url: url,
    method: 'save',
    params: {
      unlockAfterSave: unlockAfterSave,
      batch: batch,
    },
  });
const lock_request = (url, batch = []) =>
  flatui_proxy({
    url: url,
    method: 'lock',
    params: {
      batch: batch,
    },
  });

const get_request = (url, batch = []) =>
  flatui_proxy({
    url: url,
    method: 'get',
    params: {
      batch: batch,
    },
  });
/************************************************************/
const adoms_url = () => '/gui/workspace/adoms';
export const workspace_adom_fetch_list = () => get_request(adoms_url());

export const workspace_adom_unlock_list = (
  forceUnlockDirty = false,
  forceUnlockOther = false
) => unlock_request(adoms_url(), forceUnlockDirty, forceUnlockOther);

export const workspace_adom_save_list = (unlockAfterSave = false) =>
  save_request(adoms_url(), unlockAfterSave);

/************************************************************/
const adom_url = (adomOid) => `/gui/workspace/adoms/${adomOid}/adom`;
export const workspace_adom_lock = (adomOid) => lock_request(adom_url(adomOid));

const adom_scope_url = (adomOid) => `/gui/workspace/adoms/${adomOid}`;
export const workspace_adom_unlock = (
  adomOid,
  forceUnlockDirty = false,
  forceUnlockOther = false
) =>
  unlock_request(adom_scope_url(adomOid), forceUnlockDirty, forceUnlockOther);

export const workspace_adom_save = (adomOid, unlockAfterSave = false) =>
  save_request(adom_scope_url(adomOid), unlockAfterSave);

export const workspace_adom_get_lockers = (adomOid) =>
  get_request(adom_scope_url(adomOid));

/************************************************************/
const devices_url = (adomOid) => `/gui/workspace/adoms/${adomOid}/devices`;
export const workspace_device_fetch_list = (adomOid) =>
  get_request(devices_url(adomOid));
export const workspace_device_lock_list = (adomOid, batch = []) =>
  lock_request(devices_url(adomOid), batch);
export const workspace_device_unlock_list = (
  adomOid,
  batch = [],
  forceUnlockDirty = false,
  forceUnlockOther = false
) =>
  unlock_request(
    devices_url(adomOid),
    forceUnlockDirty,
    forceUnlockOther,
    batch
  );
export const workspace_device_save_list = (
  adomOid,
  batch = [],
  unlockAfterSave = false
) => save_request(devices_url(adomOid), unlockAfterSave, batch);

const device_url = (adomOid, deviceOid) =>
  `/gui/workspace/adoms/${adomOid}/devices/${deviceOid}/device`;
export const workspace_device_lock = (adomOid, deviceOid) =>
  lock_request(device_url(adomOid, deviceOid));

export const workspace_device_unlock = (
  adomOid,
  deviceOid,
  forceUnlockDirty = false,
  forceUnlockOther = false
) =>
  unlock_request(
    device_url(adomOid, deviceOid),
    forceUnlockDirty,
    forceUnlockOther
  );

export const workspace_device_save = (
  adomOid,
  deviceOid,
  unlockAfterSave = false
) => save_request(device_url(adomOid, deviceOid), unlockAfterSave);

/*******************PKG**********************************/
const pkgs_url = (adomOid) => `/gui/workspace/adoms/${adomOid}/pkgs`;
export const workspace_pkg_fetch_list = (adomOid, batch = []) =>
  get_request(pkgs_url(adomOid), batch);
export const workspace_pkg_lock_list = (adomOid, batch = []) =>
  lock_request(pkgs_url(adomOid), batch);
export const workspace_pkg_unlock_list = (
  adomOid,
  batch = [],
  forceUnlockDirty = false,
  forceUnlockOther = false
) =>
  unlock_request(pkgs_url(adomOid), forceUnlockDirty, forceUnlockOther, batch);
export const workspace_pkg_save_list = (
  adomOid,
  batch = [],
  unlockAfterSave = false
) => save_request(pkgs_url(adomOid), unlockAfterSave, batch);

const pkg_url = (adomOid, oid) =>
  `/gui/workspace/adoms/${adomOid}/pkgs/${oid}/pkg`;
export const workspace_pkg_lock = (adomOid, oid) =>
  lock_request(pkg_url(adomOid, oid));

const pkg_scope_url = (adomOid, oid) =>
  `/gui/workspace/adoms/${adomOid}/pkgs/${oid}`;
export const workspace_pkg_fetch = (adomOid, oid) =>
  get_request(pkg_scope_url(adomOid, oid));

export const workspace_pkg_unlock = (
  adomOid,
  oid,
  forceUnlockDirty = false,
  forceUnlockOther = false
) =>
  unlock_request(
    pkg_scope_url(adomOid, oid),
    forceUnlockDirty,
    forceUnlockOther
  );

export const workspace_pkg_save = (adomOid, oid, unlockAfterSave = false) =>
  save_request(pkg_scope_url(adomOid, oid), unlockAfterSave);

export function workspace_pkg_check_dirty_and_unlock(adomOid, oid) {
  return flatui_proxy([
    {
      url: `/gui/workspace/adoms/${adomOid}/pkgs/${oid}`,
      method: 'hasDirty',
      id: 1,
    },
    {
      url: `/gui/workspace/adoms/${adomOid}/pkgs/${oid}`,
      method: 'unlock',
      depends_on: {
        id: 1,
        condition: 'data==false',
      },
    },
  ]);
}
/*
export function workspace_pkg_save_and_unlock(adomOid, oid) {
  return flatui_proxy(
      [
        {
            "url": `/gui/workspace/adoms/${adomOid}/pkgs/${oid}`,
            "method": "save",
            "id": 1
        },
        {
            "url": `/gui/workspace/adoms/${adomOid}/pkgs/${oid}`,
            "method": "unlock",
            "depends_on": {
                "id": 1
            }
        }
      ]
  )
}
*/
/******************PKG CATEGORY*************************************/
const pkg_category_url = (adomOid, pkgOid, categoryOid) =>
  `/gui/workspace/adoms/${adomOid}/pkgs/${pkgOid}/categories/${categoryOid}`;
export const workspace_pkg_cate_unlock = (adomOid, pkgOid, categoryOid) =>
  unlock_request(pkg_category_url(adomOid, pkgOid, categoryOid), true);

/******************POLICY******************************** */
export function workspace_policy_fetch_list(adomOid, pkgOid, categoryOid) {
  return flatui_proxy({
    url: `/gui/workspace/adoms/${adomOid}/pkgs/${pkgOid}/categories/${categoryOid}/records`,
    method: 'get',
    params: {},
  });
}
/**************************************************************/
const objects_url = (adomOid, categoryOid) =>
  `/gui/workspace/adoms/${adomOid}/object/categories/${categoryOid}/records`;
export const workspace_object_fetch_list = (adomOid, categoryOid) =>
  get_request(objects_url(adomOid, categoryOid));
export const workspace_object_lock_list = (adomOid, categoryOid, batch = []) =>
  lock_request(objects_url(adomOid, categoryOid), batch);
export const workspace_object_unlock_list = (
  adomOid,
  categoryOid,
  batch = [],
  forceUnlockDirty = false,
  forceUnlockOther = false
) =>
  unlock_request(
    objects_url(adomOid, categoryOid),
    forceUnlockDirty,
    forceUnlockOther,
    batch
  );
