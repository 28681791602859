import { createSelector } from '@reduxjs/toolkit';
import { propOr } from '../utils/prop-or';
import { last } from 'lodash';
import { getCurrent } from './utils';

export const getThemeCfg = (state) => {
  return state.theme;
};

const _select = (key, dft = undefined) =>
  createSelector(getThemeCfg, propOr(key, dft));

export const getCurrentTheme = createSelector(
  getThemeCfg,
  ({ useGlobal, global, user, previewStack }) => {
    const themeId =
      last(previewStack) ?? getCurrent({ useGlobal, global, user });
    return MACROS.THEME_LIST[themeId];
  }
);

export const getCurrentThemeName = createSelector(
  getCurrentTheme,
  (theme) => theme.name
);

export const getUserTheme = _select('user');
export const getGlobalTheme = _select('global');
export const getUseGlobalTheme = _select('useGlobal');

export const getIsDarkTheme = createSelector(getCurrentTheme, (theme) => {
  return theme.dark;
});

const _getPalette = (theme) => {
  const palKey = theme.dark ? 'dark' : 'default';
  return MACROS.THEME_PALETTE[palKey];
};

const _getTheme = (theme) => {
  return MACROS.THEME_VARS[theme.name];
};

export const getThemeVarGetter = createSelector(
  getCurrentTheme,
  (theme) => (varName) => {
    return _getTheme(theme)?.[varName];
  }
);

export const getPaletteVarGetter = createSelector(
  getCurrentTheme,
  (theme) => (varName) => {
    return _getPalette(theme)?.[varName];
  }
);

export const getThemeVar = (varName) =>
  createSelector(getCurrentTheme, (theme) => {
    return _getTheme(theme)?.[varName];
  });

export const getThemeVars = (varNames) =>
  createSelector(getCurrentTheme, (theme) => {
    const themeVars = _getTheme(theme);
    return varNames.map((name) => themeVars?.[name]);
  });

export const getPaletteVar = (varName) =>
  createSelector(getCurrentTheme, (theme) => {
    return _getPalette(theme)?.[varName];
  });

export const getPaletteVars = (varNames) =>
  createSelector(getCurrentTheme, (theme) => {
    const palette = _getPalette(theme);
    return varNames.map((name) => palette?.[name]);
  });

export const getAllRegular = () => {
  return MACROS.THEME_LIST.filter((theme) => !theme.highcontrast);
};
