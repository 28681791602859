import {
  isTaskDone,
  isTaskPending,
  isTaskRunning,
  isTaskSucess,
  isTaskUnSucess,
  isTaskWarning,
} from './task_util';
import cn from 'classnames';
import { useState } from 'react';
import { NwButton, NwIcon } from '@fafm/neowise-core';

export const LineStatesCounter = ({ taskData, onSelectFilter, getAutoId }) => {
  const [selectedValue, setSelectedValue] = useState('total');

  if (!taskData) return null;

  const { line, num_done, num_err, num_lines, num_warn } = taskData;
  const { num_pending, num_inprogress, num_completed } = (line || []).reduce(
    (map, l) => {
      map.num_pending += isTaskPending(l);
      map.num_inprogress += isTaskRunning(l);
      map.num_completed += isTaskDone(l);
      return map;
    },
    { num_pending: 0, num_inprogress: 0, num_completed: 0 }
  );

  const sharedProps = {
    selectedValue,
    getAutoId,
    onClick: (e) => {
      let filterFn;
      const selectValue = e.currentTarget.value;

      switch (selectValue) {
        case 'total':
          break;
        case 'success':
          filterFn = isTaskSucess;
          break;
        case 'warning':
          filterFn = isTaskWarning;
          break;
        case 'error':
          filterFn = isTaskUnSucess;
          break;
        case 'pending':
          filterFn = isTaskPending;
          break;
        case 'inprogress':
          filterFn = isTaskRunning;
          break;
        case 'completed':
          filterFn = isTaskDone;
      }
      onSelectFilter(filterFn);
      setSelectedValue(selectValue);
    },
  };

  return (
    <span className='tw-whitespace-nowrap tw-flex tw-gap-2'>
      <StateFilter
        label={`${gettext('Total')}: ${num_completed}/${num_lines || 0}, `}
        value='total'
        {...sharedProps}
      />
      {isTaskDone(taskData) ? (
        <>
          <StateFilter
            icon={{ name: 'enable', className: 'color-green' }}
            label={` ${gettext('Success')}: ${num_done}, `}
            value={'success'}
            {...sharedProps}
          />

          <StateFilter
            icon={{ name: 'warning', className: 'color-yellow' }}
            label={` ${gettext('Warning')}: ${num_warn}, `}
            value={'warning'}
            {...sharedProps}
          />

          <StateFilter
            icon={{ name: 'no', className: 'color-red' }}
            label={` ${gettext('Error')}: ${num_err} `}
            value={'error'}
            {...sharedProps}
          />
        </>
      ) : (
        <>
          <StateFilter
            icon={{ name: 'schedule', className: 'color-grey' }}
            label={` ${gettext('Pending')}: ${num_pending}, `}
            value={'pending'}
            {...sharedProps}
          />

          <StateFilter
            icon={{ name: 'spinner', className: 'color-blue' }}
            label={` ${gettext('In Progress')}: ${num_inprogress}, `}
            value={'inprogress'}
            {...sharedProps}
          />

          <StateFilter
            icon={{ name: 'enable', className: 'color-green' }}
            label={` ${gettext('Completed')}: ${num_completed} `}
            value={'completed'}
            {...sharedProps}
          />
        </>
      )}
    </span>
  );
};

function StateFilter({
  icon,
  label,
  value,
  selectedValue,
  getAutoId,
  ...rest
}) {
  const isChecked = value === selectedValue;

  return (
    <NwButton
      type='text'
      value={value}
      automation-id={getAutoId(`${value}-button`)}
      className='task-state-filter'
      prefix={icon && <NwIcon aria-hidden='true' {...icon} />}
      {...rest}
    >
      <a
        className={cn('tw-cursor-pointer', {
          'tw-font-bold tw-underline': isChecked,
        })}
      >
        {label}
      </a>
    </NwButton>
  );
}
