import { validIp6Net } from 'fiutil';
import { neowiseIpInputField } from './FmkIpV4Input';
import { NwIpV6PrefixInput } from '@fafm/neowise-core';

export const FmkIpV6PrefixInput = neowiseIpInputField(NwIpV6PrefixInput, {
  ipValidate: () => {
    return (value) => {
      if (!value || value === '::/0' || validIp6Net(value)) return;
      return gettext('Invalid IPv6 address.');
    };
  },
});
FmkIpV6PrefixInput.displayName = 'FmkIpV6PrefixInput';
