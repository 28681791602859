import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';

const formatReplacementMessageTitle = (txt, data) => {
  let msgType = data?.rec[0]?.data['msg-type-name'] || '';
  if (msgType) msgType = `-> ${msgType}`;
  return `${txt} ${msgType}`;
};

/**
 * Fetch all replacement message images for a device
 * @param {*} device device object
 * @param {*} vdomName vdom Name
 * @returns an array of all replacement message images
 */
const getReplacementMsgImageList = (device) => {
  let req = {
    method: 'get',
    params: [
      {
        url: `pm/config/device/${device.name}/global/system/replacemsg-image`,
        option: ['get flags', 'get used', 'extra info', 'no loadsub'],
      },
    ],
  };
  return fiFmgHttp.forward(req).then((resp) => resp?.[0]?.data || []);
};

const GLOBAL_URL_PREFIX = '/pm/config/global';
const ADOM_URL_PREFIX = (adomName) => `/pm/config/adom/${adomName}`;

const _generateDefaultImageUrl = (adomName) => {
  const prefix = fiAdom.isGlobalAdom(adomName)
    ? GLOBAL_URL_PREFIX
    : ADOM_URL_PREFIX(adomName);
  return prefix + '/obj/system/replacemsg-image';
};

const getDefaultReplacementMsgImages = async (
  adomName = fiAdom.current().name
) => {
  const req = {
    method: 'get',
    params: [
      {
        url: _generateDefaultImageUrl(adomName),
      },
    ],
  };

  return fiFmgHttp.query(req).then((resp) => {
    return resp[0]?.data;
  });
};

/**
 * Generate the default url to fetch the default template
 * @param {*} adomName str, the name of the adom
 * @param {*} cateUrl i.e: system/replacemsg/alertmail
 * @param {*} msgType i.e: alertmail-crit-event
 * @returns i.e pm/config/adom/root/_system/replacemsg/alertmail/alertmail-crit-event
 */
const _generateDefaultTemplateUrl = (
  cateUrl,
  msgType,
  adomName = fiAdom.current().name
) => {
  let prefix = fiAdom.isGlobalAdom(adomName)
    ? GLOBAL_URL_PREFIX
    : ADOM_URL_PREFIX(adomName);
  let url = prefix + `/_${cateUrl}/${msgType}`;
  return url;
};

/**
 * Gets the default replacement message template for a given replacement msg
 * @param {*} adomName adom name
 * @param {*} cateUrl replacement msg group type i.e system/replacemsg/admin
 * @param {*} msgType replacement msgType i.e disclaimer-page-1
 * @returns a string, the buffer of the default template
 */
const getDefaultReplacementMsgTemplate = (adomName, cateUrl, msgType) => {
  const req = {
    method: 'get',
    params: [
      {
        url: _generateDefaultTemplateUrl(adomName, cateUrl, msgType),
      },
    ],
  };

  return fiFmgHttp.query(req).then((resp) => {
    return resp[0]?.data[0]['msg-types'][0]?.buffer || '';
  });
};

export {
  formatReplacementMessageTitle,
  getReplacementMsgImageList,
  getDefaultReplacementMsgImages,
  getDefaultReplacementMsgTemplate,
};
