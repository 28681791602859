import { isNil } from 'lodash';

/**
 * Filter an entry with the given filter. If the entry is a group, return true if one of its members passed the filter.
 * Return {Function} function returning True/False for each entry
 */
export function filterWithGrp(filterFn, config) {
  config = Object.assign(
    {
      isGrp: (entry) => !!entry.isGrp,
      getMembers: (entry) => entry.members, // each member should be extended with data
      getEntryKey: (entry) => entry.key,
    },
    config
  );
  return function init() {
    let map = {};
    return function fn(entry) {
      if (isNil(entry)) {
        return false; // Some customer db has invalid values
      }
      let result;
      const entryKey = config.getEntryKey(entry);
      if (map.hasOwnProperty(entryKey)) {
        result = map[entryKey];
      } else {
        map[entryKey] = result = filterFn(entry);
        if (!result && config.isGrp(entry)) {
          map[entryKey] = result = (config.getMembers(entry) || []).some((m) =>
            fn(m)
          );
        }
      }
      return result;
    };
  };
}
