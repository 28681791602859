const FAZS = MACROS.FAZ_SYNTAX;
const DVM = MACROS.DVM;
const rgx = /^DVM_RESTRICTED_PRD_.*$/;
const typeExceptions = {
  FOS: 'FGT',
  FOC: 'FGT',
  LOG: 'SYS',
};
const resProdToDisp = {
  FOS: 'FortiGate',
  FOC: 'FortiGarrier',
  FFW: 'FortiFirewall',
  FWC: 'FortiFirewallCarrier',
  FML: 'FortiMail',
  FSW: 'FortiSwitch',
  FCH: 'FortiCache',
  FPX: 'FortiProxy',
  FWB: 'FortiWeb',
  LOG: 'Syslog',
  FCT: 'FortiClient',
  FAZ: 'FortiAnalyzer',
  FMG: 'FortiManager',
  FSA: 'FortiSandbox',
  FDD: 'FortiDDoS',
  FAC: 'FortiAuthenticator',
  FPA: 'FortiPAM',
  FCA: 'FortiCASB',
  FTC: 'FortiToken',
  FRA: 'FortiSRA',
  FAP: 'FortiAP',
  FSF: 'Devices',
};

export const getResProdList = () => {
  return Object.keys(DVM)
    .filter((key) => rgx.test(key))
    .reduce((res, key) => {
      let type = key.split('_').pop();
      let logType = typeExceptions[type] || type;
      return type
        ? {
            ...res,
            [DVM[key]]: {
              logDevType: FAZS[`LOGDEV_${logType}`],
              display: resProdToDisp[type] || type,
              code: type,
            },
          }
        : res;
    }, {});
};

export const getResProdById = (restrictedId) => {
  let resProdList = getResProdList();
  return (
    resProdList[restrictedId] ||
    resProdList[MACROS.DVM.DVM_RESTRICTED_PRD_FOS] ||
    'FortiGate'
  );
};
