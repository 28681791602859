import { useCallback, useEffect, useMemo } from 'react';
import { transform } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import {
  fiSession,
  fiStoreAdomLocalConfig,
  fiDevGroupsAction,
  fiDevicesAction,
  fiDevicesSelector,
} from 'fistore';
import { getIsHaSlave } from 'fistore/session/sysConfig/selectors';
import { getSessionAdomName } from 'fistore/session/adom/selectors';
import { get_device_group_members } from 'fistore/devGroups/selector';

export function useGroupDeviceCollect(adomOid, adomName, groupId) {
  const [devices, vdoms] = useDevices(adomOid);
  const groupMemb = useGroupMember(adomName);

  //{oid:[vd1,vd2]}
  const deviceCollect = useMemo(() => {
    const list = getGrpDeviceList(groupId, groupMemb);
    return getDeviceCollect(list);
  }, [groupMemb, groupId]);

  return { devices, deviceCollect, groupMemb, vdoms };
}

export function useDevListFilterByTreeNode() {
  const adomOid = useSelector(fiSession.getSessionAdomOid);
  const adomName = useSelector(fiSession.getSessionAdomName);
  const deviceTree = useSelector(fiStoreAdomLocalConfig.getDeviceTree);
  const groupId = getLastGroupPath(deviceTree.deviceGroupPath);

  const { deviceCollect, devices, groupMemb, vdoms } = useGroupDeviceCollect(
    adomOid,
    adomName,
    groupId
  );

  const devListFilter = useCallback(
    ({ parsedDevices }) => {
      if (deviceTree.vdomOid && deviceTree.deviceOid) {
        // selected a vdom
        const dev = parsedDevices.find((d) => d.oid === deviceTree.deviceOid);
        dev.children = dev.children.filter(
          (v) => v.vdom_oid === deviceTree.vdomOid
        );
        return [dev];
      } else if (!deviceTree.vdomOid && deviceTree.deviceOid) {
        // selected a device
        const dev = parsedDevices.find((d) => d.oid === deviceTree.deviceOid);
        // if the device is selected from a group, need to filter out vdoms not in the group
        if (
          deviceTree.deviceGroupPath > 0 &&
          deviceCollect &&
          dev.children?.length > 0
        ) {
          const availableVdoms = deviceCollect[deviceTree.deviceOid];
          dev.children = dev.children.filter((vdom) =>
            availableVdoms.includes(parseInt(vdom.vdom_oid))
          );
        }
        return [dev];
      } else {
        // selected a group or all Managed FortiGate (groupId: -3)
        if (
          Object.keys(deviceCollect).length === 0 ||
          deviceTree.deviceGroupPath < 0
        ) {
          return parsedDevices;
        }

        // selected a device group
        const devicesInGroup = Object.keys(deviceCollect);
        // filter out devices not in group
        let filteredDevices = parsedDevices.filter((dev) =>
          devicesInGroup.includes(dev.oid)
        );
        // filter out vdoms not in group
        filteredDevices = filteredDevices.map((dev) => {
          if (dev.children || dev.children?.length > 0) {
            dev.children = dev.children.filter((vdom) => {
              const vdomOid = vdom.vdom_oid;
              return deviceCollect[dev.oid].includes(parseInt(vdomOid));
            });
          }
          return dev;
        });

        return filteredDevices;
      }
    },
    [deviceTree, deviceCollect]
  );

  return { devListFilter, deviceCollect, devices, groupMemb, vdoms };
}

function getLastGroupPath(path) {
  const arr = path.split(':');
  return arr.length > 1 ? arr[arr.length - 1] : path;
}

export function getGrpDeviceList(id, groupMemb) {
  let deviceList = [];
  if (!groupMemb.loaded) return deviceList;

  function addDevice(id) {
    const memb = groupMemb.list.byId[id];
    if (!memb) return;

    //[{oid,vdom}],[{oid}]
    const { membDevs, membGrps } = memb;
    deviceList = [...deviceList, ...membDevs];

    membGrps.forEach(({ oid }) => {
      addDevice(oid);
    });
  }

  addDevice(id);

  return deviceList;
}

function getDeviceCollect(deviceList) {
  const deviceCollect = {};
  function addItem({ oid, vdom }) {
    if (!deviceCollect.hasOwnProperty(oid))
      // use Set to prevent duplicated vdom
      deviceCollect[oid] = new Set([vdom]);
    else {
      deviceCollect[oid].add(vdom);
    }
  }
  deviceList.map(addItem);

  return Object.entries(deviceCollect).reduce((acc, [key, val]) => {
    acc[key] = Array.from(val);
    return acc;
  }, {});
}

function useDevices(adomOid) {
  const dispatch = useDispatch();
  const devices = useSelector((state) =>
    fiDevicesSelector.get_devices(state, adomOid)
  );
  const vdoms = useSelector((state) => fiDevicesSelector.get_all_vdoms(state));

  // mantis #1022637: always do hard reload for HA secondary because it does not always have push notification
  const isHASecondary = useSelector(getIsHaSlave);

  //load device
  useEffect(() => {
    if (!devices || (!devices.loaded && !devices.loading) || isHASecondary)
      dispatch(fiDevicesAction.fetchDevicesAction());
  }, [adomOid]);

  return [devices, vdoms];
}

export function useGroupMember(adomName) {
  const dispatch = useDispatch();
  const groupMemb = useSelector(get_device_group_members);

  useEffect(() => {
    if (!groupMemb || (!groupMemb.loaded && !groupMemb.loading)) {
      dispatch(fiDevGroupsAction.fetchDeviceGroupsMemAction({ adomName }));
    }
  }, [adomName]);

  return groupMemb;
}

/**
 * Create mapping between device+vdom oid and device group oid, e.g.
 * dev_vdoms_by_group_oid:
    {
      "190": [
        "271-3407"
      ],
      "236": [
        "178-3",
        "271-3",
        "271-3407",
        "277-3",
        "283-3"
      ],
      "289": [
        "277-3",
        "283-3"
      ]
    }
  * devgrps_by_dev_vdoms:
    {
      "271-3407": [
        "190",
        "236"
      ],
      "178-3": [
        "236"
      ],
      "271-3": [
        "236"
      ],
      "277-3": [
        "236",
        "289"
      ],
      "283-3": [
        "236",
        "289"
      ]
    }
 */
export function useGroupMemberMapping() {
  const adomName = useSelector(getSessionAdomName);
  const groupMemb = useGroupMember(adomName);
  const groupMembByGroupId = groupMemb?.list?.byId || {};

  // key: device group oid
  // value: device+vdom that belong to the device group
  const dev_vdoms_by_group_oid = useMemo(() => {
    return transform(
      groupMembByGroupId,
      (result, val, groupId) => {
        const deviceList = getGrpDeviceList(groupId, groupMemb) || [];
        result[groupId] = deviceList.map((scopeMember) => {
          const { oid, vdom } = scopeMember || {};
          return `${oid}-${vdom || MACROS.DVM.CDB_DEFAULT_ROOT_OID}`;
        });
      },
      {}
    );
  }, [groupMembByGroupId]);

  // key: device+vdom oid
  // value: device groups that contain the device+vdom as member
  const devgrps_by_dev_vdoms = useMemo(() => {
    const mapping = transform(
      dev_vdoms_by_group_oid,
      (result, deviceList, groupId) => {
        if (!Array.isArray(deviceList)) return;

        for (const dev_vdom_oid of deviceList) {
          if (!result[dev_vdom_oid]) {
            result[dev_vdom_oid] = new Set();
          }

          result[dev_vdom_oid].add(groupId);
        }
      },
      {}
    );

    return transform(
      mapping,
      (result, val, key) => {
        result[key] = Array.from(val);
      },
      {}
    );
  }, [dev_vdoms_by_group_oid]);

  return {
    dev_vdoms_by_group_oid,
    devgrps_by_dev_vdoms,
  };
}

/** ----------------------------------------------------------------------------
 * Get device vdom oids from scope members (could be device or device group), e.g.
 * device group: { name: grp, oid: grpOid }, will get all nested devices in the group
 * device: oid-vdom_oid
 * @returns list of device vdom oids in this format, oid-vdom_oid
 * -------------------------------------------------------------------------- */
export function flattenAssignedDevicesKeys({
  scopeMembers,
  devMap,
  dev_vdoms_by_group_oid,
}) {
  const devVdomsSet = transform(
    scopeMembers,
    (resultSet, scope) => {
      const { oid, vdom_oid } = scope;
      const oData = devMap[oid];
      if (!oData) return;

      const isDevGrp = oData.isGrp;
      if (isDevGrp) {
        const memberKeys = dev_vdoms_by_group_oid[oid];
        if (memberKeys.length) {
          const memberScopeObjs = memberKeys.map((key) => {
            const [oid, vdom_oid] = key.split('-');
            return {
              oid,
              vdom_oid,
            };
          });
          const devs = flattenAssignedDevicesKeys(memberScopeObjs);
          devs.forEach((dev) => resultSet.add(dev));
          return;
        }
      }

      resultSet.add(`${oid}-${vdom_oid || MACROS.DVM.CDB_DEFAULT_ROOT_OID}`);
    },
    new Set()
  );
  return Array.from(devVdomsSet);
}

export function getFlattenedAssignedDevices({
  scopeMembers,
  devMap,
  dev_vdoms_by_group_oid,
}) {
  // remove duplicates
  const devVdomKeys = flattenAssignedDevicesKeys({
    scopeMembers,
    devMap,
    dev_vdoms_by_group_oid,
  });

  // add extra data, sselect id, _oData etc., to scope member
  return transform(
    devVdomKeys,
    (result, key) => {
      const [oid, vdom_oid] = key.split('-');
      const oData = devMap[oid];
      if (!oData) return;

      if (!vdom_oid && MACROS.SYS.CONFIG_DEBUG) {
        console.error('getFlattenedAssignedDevices: Missing vdom_oid', key);
      }

      const name = oData.name;
      const common = {
        name,
        oid: oData.did,
        vdom_oid,
        _oData: oData,
      };
      if (`${vdom_oid}` === `${MACROS.DVM.CDB_DEFAULT_GLOBAL_OID}`) {
        result.push({
          id: `${name}/global`,
          vdom: 'global',
          ...common,
        });
        return;
      }

      let vdom = 'root';
      const vdoms = oData.vdoms;
      if (Array.isArray(vdoms)) {
        const found = vdoms.find((vdom) =>
          [`${vdom.oid}`, `${vdom.vid}`].includes(`${vdom_oid}`)
        );
        if (found) {
          vdom = found.name;
        }
      }
      result.push({
        id: `${name}/${vdom}`,
        vdom,
        ...common,
      });
    },
    []
  );
}
