import { fiDeviceRedux } from '../device_redux';

export function getDevice(did, vid) {
  const device = fiDeviceRedux.getDevice(null, did);
  if (!vid) {
    return device;
  }

  return Array.isArray(device.vdoms)
    ? device.vdoms.filter((vdom) => vdom['_fiDeviceId'] === `${did}-${vid}`)[0]
    : null;
}

export function canDevBeManaged(ostype) {
  return (
    ostype === MACROS.DVM.DVM_OS_TYPE_FOS ||
    ostype === MACROS.DVM.DVM_OS_TYPE_FOC ||
    ostype === MACROS.DVM.DVM_OS_TYPE_FFW ||
    ostype === MACROS.DVM.DVM_OS_TYPE_FWC ||
    ostype === MACROS.DVM.DVM_OS_TYPE_FPX ||
    ostype === MACROS.DVM.DVM_OS_TYPE_FSS
  );
}

export const isLoggingGroup = (oRecMngd) => {
  let result = false;
  if (oRecMngd) {
    if (oRecMngd.logging) {
      result = true;
    } else if (oRecMngd.managed) {
      if (MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FAZ) {
        if (!canDevBeManaged(oRecMngd.ostype)) {
          result = true;
        }
      }
    }
  }
  return result;
};
