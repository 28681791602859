import { fiAdom } from 'fi-session';
import { isNil, isNumber, get as lodashGet, capitalize } from 'lodash';
import { fiFmgHttp } from 'fi-http';

export { get };

const INTF_TYPES = {
  physical: {
    id: MACROS.PM2CAT.PM2_INTF_T_PHYSICAL,
    text: gettext('Physical Interface'),
    txt: gettext('Physical Interface'),
    icon: { name: 'interface', classes: 'color-info' },
  },
  vlan: {
    id: MACROS.PM2CAT.PM2_INTF_T_VLAN,
    text: 'VLAN',
    txt: 'VLAN',
    icon: {
      name: 'vlan-switch',
      library: 'fgt-features',
      classes: 'color-info',
    },
  },
  aggregate: {
    id: MACROS.PM2CAT.PM2_INTF_T_AGGREGATE,
    text: gettext('802.3ad Aggregate'),
    txt: gettext('802.3ad Aggregate'),
    icon: { name: 'aggregate', classes: 'color-info' },
  },
  redundant: {
    id: MACROS.PM2CAT.PM2_INTF_T_REDUNDANT,
    text: gettext('Redundant Interface'),
    txt: gettext('Redundant Interface'),
    icon: { name: 'ports', classes: 'color-info' },
  },
  tunnel: {
    id: MACROS.PM2CAT.PM2_INTF_T_TUNNEL,
    text: gettext('Tunnel'),
    txt: gettext('Tunnel'),
    icon: { name: 'vpn-tunnel', classes: 'color-info' },
  },
  wireless: {
    id: MACROS.PM2CAT.PM2_INTF_T_WIRELESS,
    text: gettext('Wireless'),
    txt: gettext('Wireless'),
    icon: { name: 'wifi', classes: 'color-info' },
  },
  'vdom-link': {
    id: MACROS.PM2CAT.PM2_INTF_T_PAIR,
    text: gettext('VDOM Link'),
    txt: gettext('VDOM Link'),
    icon: { name: 'interface', classes: 'color-info' },
  },
  loopback: {
    id: MACROS.PM2CAT.PM2_INTF_T_LOOPBACK,
    text: gettext('Loopback Interface'),
    txt: gettext('Loopback Interface'),
    icon: { name: 'loopback', classes: 'color-info' },
  },
  switch: {
    id: MACROS.PM2CAT.PM2_INTF_T_SWITCH,
    text: gettext('Software Switch'),
    txt: gettext('Software Switch'),
    icon: { name: 'switch-2', classes: 'color-info' },
  },
  'hard-switch': {
    id: MACROS.PM2CAT.PM2_INTF_T_HARD_SWITCH,
    text: gettext('Hardware Switch'),
    txt: gettext('Hardware Switch'),
    icon: { name: 'switch', classes: 'color-info' },
  },
  hdlc: {
    id: MACROS.PM2CAT.PM2_INTF_T_HDLC,
    text: 'HDLC',
    txt: 'HDLC',
    icon: { name: 'interface', classes: 'color-info' },
  },
  'vap-switch': {
    id: MACROS.PM2CAT.PM2_INTF_T_VAP_SWITCH,
    text: 'WiFi SSID',
    txt: 'WiFi SSID',
    icon: { name: 'wifi-controller', classes: 'color-info' },
  },
  'wl-mesh': {
    id: MACROS.PM2CAT.PM2_INTF_T_WL_MESH,
    text: gettext('VLAN Mesh'),
    txt: gettext('VLAN Mesh'),
    icon: { name: 'mesh', classes: 'color-info' },
  },
  fortilink: {
    id: MACROS.PM2CAT.PM2_INTF_T_FORTILINK,
    text: 'Fortilink',
    txt: 'Fortilink',
    icon: { name: 'interface', classes: 'color-info' },
  },
  'switch-vlan': {
    id: MACROS.PM2CAT.PM2_INTF_T_SWITCH_VLAN,
    text: 'Switch VLAN',
    txt: 'Switch VLAN',
    icon: { name: 'vlan', classes: 'color-info' },
  },
  'fctrl-trunk': {
    id: MACROS.PM2CAT.PM2_INTF_T_FCTRL_TRUNK,
    text: gettext('Trunk'),
    txt: gettext('Trunk'),
    icon: { name: 'ports', classes: 'color-info' },
  },
  tdm: {
    id: MACROS.PM2CAT.PM2_INTF_T_TDM,
    text: 'TDM',
    txt: 'TDM',
    icon: { name: 'interface', classes: 'color-info' },
  },
  'fext-wan': {
    id: MACROS.PM2CAT.PM2_INTF_T_FEXT_WAN,
    text: 'FortiExtender WAN Extension',
    txt: 'FortiExtender WAN Extension',
    icon: { name: 'fortiextender', classes: 'color-info' },
  },
  vxlan: {
    id: MACROS.PM2CAT.PM2_INTF_T_VXLAN,
    text: 'VXLAN',
    txt: 'VXLAN',
    icon: { name: 'vlan', classes: 'color-info' },
  },
  'emac-vlan': {
    id: MACROS.PM2CAT.PM2_INTF_T_EMAC_VLAN,
    text: 'EMAC VLAN',
    txt: 'EMAC VLAN',
    icon: { name: 'vlan', classes: 'color-info' },
  },
  geneve: {
    id: MACROS.PM2CAT.PM2_INTF_T_GENEVE,
    text: 'GENEVE',
    txt: 'GENEVE',
    icon: { name: 'vlan', classes: 'color-info' },
  },
  ssl: {
    id: MACROS.PM2CAT.PM2_INTF_T_SSL,
    text: gettext('SSL-VPN Tunnel'),
    icon: { name: 'vpn-tunnel', classes: 'color-info' },
  },
  pppoe: {
    id: MACROS.PM2CAT.PM2_CAT_SYS_PPPOE_INTERFACE,
    text: gettext('PPPoE Interface'),
    txt: gettext('PPPoE Interface'),
    icon: { name: 'vpn-tunnel', classes: 'color-info' },
  },
  'ui-zone': {
    id: 'ui-zone',
    text: gettext('Zone'),
    txt: gettext('Zone'),
    icon: { name: 'ports', classes: 'color-info' },
  },
  'ui-wanlink': {
    id: 'ui-wanlink',
    text: 'SD-WAN',
    txt: 'SD-WAN',
    icon: { name: 'virtual-wan-link', classes: 'color-info' },
  },
  'ui-sdwan-zone': {
    id: 'ui-sdwan-zone',
    text: gettext('SD-WAN Zone'),
    txt: gettext('SD-WAN Zone'),
    icon: { name: 'virtual-wan-link', classes: 'color-info' },
  },
  'ui-virwire': {
    id: 'ui-virwire',
    text: 'Virtual Wire Pair',
    txt: 'Virtual Wire Pair',
    icon: { name: 'virtual-wire-pair', classes: 'color-info' },
  },
  unknown: {
    id: 'unknown',
    text: gettext('Unknown'),
    txt: gettext('Unknown'),
    icon: { name: 'interface', classes: 'color-info' },
  },
  'single-intf': {
    id: 'single-intf',
    text: gettext('Single Interface'),
    txt: gettext('Single Interface'),
    icon: { name: 'interface', classes: 'color-info' },
  },
  invalid: {
    id: 'invalid',
    text: gettext('Invalid'),
    txt: gettext('Invalid'),
    icon: { name: 'disable', classes: 'color-red' },
  },
  'special-no-icon': {
    id: 'special-no-icon',
    icon: {},
  },
};

const adomInterfaceTypeMap = {};

fiAdom.adomSubscriber.switched(async () => {
  const adom = fiAdom.current();
  const { type_name } = adom;
  const intfTypes = await getAdomInterfaceTypes(adom);
  if (!isNil(intfTypes)) {
    adomInterfaceTypeMap[type_name] = intfTypes;
  }
});

const get = (type) => {
  const adom = fiAdom.current();
  const { type_name } = adom;
  let intfTypes = lodashGet(adomInterfaceTypeMap, type_name);

  if (!intfTypes) {
    intfTypes = INTF_TYPES;
  }

  let ret;

  if (isNumber(type)) {
    // find interface type by id
    for (const opt in intfTypes) {
      if (intfTypes[opt].id === type) {
        ret = intfTypes[opt];
        break;
      }
    }
  } else if (!isNil(type)) {
    ret = intfTypes[type.toLowerCase()];
  }

  if (isNil(ret)) {
    if (INTF_TYPES[type]) {
      ret = INTF_TYPES[type];
    } else {
      ret = INTF_TYPES.unknown;
    }
  }

  return {
    ...ret,
    css: `ffg ffg-${ret.icon.name} ${ret.icon.classes}`,
  };
};

const getAdomInterfaceTypes = (adom) => {
  const req = {
    method: 'get',
    params: [
      {
        url: `pm/config/adom/${adom.name}/obj/system/interface`,
        option: 'syntax',
      },
    ],
  };

  return fiFmgHttp.forward(req).then(function (resp) {
    let result;
    if (!resp || !resp[0].data) {
      result = [];
      return null;
    } else {
      result = resp[0].data;
      const syntax = result['system interface'].attr['type'];
      return setAdomInterfaceTypes(syntax);
    }
  });
};

const setAdomInterfaceTypes = (syntax) => {
  const intfTypes = {};
  let _opts = syntax ? syntax.opts : {};
  for (const opt in _opts) {
    intfTypes[opt] = INTF_TYPES[opt]
      ? INTF_TYPES[opt]
      : {
          id: _opts[opt],
          text: capitalize(opt),
          txt: capitalize(opt),
          icon: { name: 'interface', classes: 'color-blue' },
        };
  }

  return intfTypes;
};
