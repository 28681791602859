import { Ack } from './types';

export async function checkAck<T = unknown>(
  response: Promise<Ack<T> | { ack: false } | undefined>
): Promise<T & { ack: true }> {
  const result = await response;
  if (result?.ack !== true) {
    let message = 'no ack';
    if (process.env.NODE_ENV === 'development') {
      message = JSON.stringify(result);
    }

    throw new Error(message);
  }
  return result as T & { ack: true };
}

const ORIGIN = location.origin || `${location.protocol}//${location.host}`;

export function windowPostMessage<Message>(message: Message) {
  window.postMessage(message, ORIGIN);
}

export function onWindowPostMessage<Message extends { messageID: string }>() {
  type Callback = (m: Message) => void | Promise<void>;
  type Type = Message['messageID'];

  const registers = {} as Partial<Record<Type, { fn: Callback }>>;

  window.addEventListener(
    'message',
    (event) => {
      if (event.origin !== ORIGIN) return;
      const request = event.data as Message | undefined;
      const type = request?.messageID as Type;
      const registered = registers[type];

      if (typeof registered?.fn === 'function') {
        void registered.fn(request!);
      }
    },
    false
  );

  const addCase = (type: Type, fn: Callback) => {
    registers[type] = { fn };
    return build;
  };
  const removeCase = (type: Type) => {
    delete registers[type];
    return build;
  };
  const build = {
    addCase,
    removeCase,
  };
  return build;
}

export function defer<T>() {
  let resolve: (value?: any) => void, reject: (reason?: any) => void;
  const promise: Promise<T> = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return {
    promise,
    //@ts-ignore
    resolve,
    //@ts-ignore
    reject,
  };
}

export async function timeoutResponse<T = unknown>(
  response: Promise<T>,
  ms: number
): Promise<T> {
  const symbol = Symbol('timeout');
  const timer = new Promise((resolve) => {
    self.setTimeout(() => {
      resolve(symbol);
    }, ms);
  });

  const result = await Promise.race([response, timer]);
  if (result === symbol) {
    throw new Error('timeout');
  }

  return result as T;
}

function noSerializableInfo(value: unknown) {
  return (
    value === undefined || // `undefined` is ignored
    typeof value === 'function' || // Functions are ignored
    typeof value === 'symbol' || // Symbols are ignored
    value instanceof Blob || // Blob serializes as {}
    value instanceof File || // File serializes as {}
    value instanceof Error || // Error serializes as {}
    value instanceof Map || // Map serializes as {}
    value instanceof Set || // Set serializes as {}
    value instanceof WeakMap || // WeakMap is not serializable
    value instanceof WeakSet || // WeakSet is not serializable
    (typeof value === 'object' &&
      value !== null && // Custom class instances with no toJSON
      !Array.isArray(value) && // Exclude arrays
      Object.getPrototypeOf(value).constructor !== Object) // Exclude plain objects
  );
}

export const asSerializeInfo = (data: unknown, depth = 0): string => {
  if (depth > 30) {
    return 'stop because of circular';
  }

  if (typeof data === 'string') {
    return data;
  }

  // plain object or array
  if (!noSerializableInfo(data)) {
    try {
      return JSON.stringify(data);
    } catch {
      // failed and fallback to Object.prototype.toString
    }
  }

  // error stack
  if (data instanceof Error) {
    const result: Record<string, string | undefined> = {
      name: data.name,
      message: data.message,
      stack: data.stack,
      cause: asSerializeInfo(data.cause, depth + 1),
    };
    return JSON.stringify(result);
  }

  // log the type
  return Object.prototype.toString.call(data);
};
