import React, { useState, useEffect, useCallback } from 'react';
import { ProToolkit, ProForm } from '@fafm/neowise-pro';
import { NwButton } from '@fafm/neowise-core';
import { fiStore, fiStoreLogview } from 'fistore';
import { parser as parseLogDbRebuilding } from './logdbRebuildingParser';
// import { useSelector } from 'react-redux';
const { Header, Body, Footer, Row } = ProForm;

export const useLogdbRebuidingProgess = (getLogdbBuilding) => {
  const [logdb, setLogdb] = useState({ rebuilding: false });
  const logdbCallback = useCallback((isMounted) => {
    const logdbRebuilding = getLogdbBuilding();
    if (isMounted) {
      setLogdb(parseLogDbRebuilding(logdbRebuilding));
    }
    return setTimeout(() => logdbCallback(isMounted), 5000);
  }, []);

  useEffect(() => {
    let isMounted = true;
    const getIsMounted = () => isMounted;
    const timer = logdbCallback(getIsMounted());
    return () => {
      clearTimeout(timer);
      isMounted = false;
    };
  }, []);
  return [logdb];
};

export const openModal = async (/*getDetails*/) => {
  const getDetails = () => {
    const state = fiStore.getState();
    // console.log(fiSession.getLogDbRebuild(state));
    return fiStoreLogview.getLogDbRebuild(state);
    // return useSelector(fiSession.getLogDbRebuild);
  };
  try {
    await ProToolkit.openModal(<Details getDetails={getDetails} />, {
      size: 'md',
      height: '30vh',
    }).result;
  } catch (ex) {
    console.error('rejected: ', ex);
  }
};

const Details = ({ $opener, getDetails }) => {
  const [details] = useLogdbRebuidingProgess(getDetails);
  return (
    <>
      <Header>{details?.hint}</Header>
      <Body>
        <Row>
          <div>
            {!details.rebuilding && (
              <span class='ffg ffg-enable color-green'></span>
            )}
            <span>{details?.curPhaseText?.detail}</span>
            <span>{details?.curPhaseText?.remain}</span>
          </div>
        </Row>
        <Row>
          {details.rebuilding && (
            <div className='tw-w-full'>
              <nw-progress-bar percentage={details?.percent}>
                {details?.percent}%
              </nw-progress-bar>
            </div>
          )}
        </Row>
        <Row>
          {details?.curPhaseText?.more && (
            <div> {details?.curPhaseText?.more} </div>
          )}
        </Row>
      </Body>
      <Footer>
        <NwButton
          onClick={() => {
            $opener.reject();
          }}
        >
          {gettext('Cancel')}
        </NwButton>
      </Footer>
    </>
  );
};
export default ({ logdb, getDetails }) => {
  return (
    <nw-tooltip
      content={
        logdb.curPhaseText?.remain
          ? gettext('Estimate Remaining') + ': ' + logdb.curPhaseText.remain
          : ''
      }
    >
      <div
        className='nav navbar-nav logdb-rebuilding'
        cursor='pointer'
        onClick={() => openModal(getDetails)}
      >
        <div className='tw-font-bold'>{logdb.curPhaseText.brief} </div>
        <div className='tw-w-52 meter'>
          <nw-progress-bar
            simple
            percentage={logdb.percent}
            style={{ '--indicator-color': 'rgb(var(--nw-color-success))' }}
          ></nw-progress-bar>
        </div>
      </div>
    </nw-tooltip>
  );
};
