import { get, set } from 'lodash';
import { fiFmgHttp } from 'fi-web/fi-http';
import { perAdminFeature } from 'fi-customization';

type Rec = { oid: number };
type RecOid = Rec | Rec['oid'];
const getOid = (data: RecOid): number =>
  +(typeof data === 'object' ? data.oid : data);

/** adom_customize table, dvmCustomize data structure */
type DvmCustomization = Partial<{
  dvmConfigDisplayOpts: string[];
  ptDisplayOpts: string[];
}>;
const DC_DISPLAY_OPTS = 'dvmConfigDisplayOpts';
const PT_DISPLAY_OPTS = 'ptDisplayOpts';

/** ----------------------------------------------------------------------------
 * Public APIs
 * -------------------------------------------------------------------------- */
async function dvmCustomizeApi(
  adom: RecOid,
  opts?: never
): Promise<DvmCustomization['dvmConfigDisplayOpts'] | DvmCustomization>;
async function dvmCustomizeApi(
  adom: RecOid,
  opts: DvmCustomization
): Promise<void>;
async function dvmCustomizeApi(adom: RecOid, opts: unknown) {
  const adomOid = getOid(adom);
  const url = `/gui/adoms/${adomOid}/customize`;

  // Setter
  if (opts) {
    await fiFmgHttp.post({
      url: url,
      method: 'update',
      params: {
        dvmCustomize: opts,
      },
    });
    return;
  }

  // Getter
  const resp = await fiFmgHttp.post({
    url: url,
    method: 'get',
  });
  return get(resp, '0.data.dvmCustomize', {}) as unknown;
}

// Generate functions with signature: (adom: object | number, opts: object) => object | null
// The passed key can be a path if you want to set nested object: e.g. 'customKey.nestedKey' etc.
export const dvmConfigDisplayOptsApi = makeDvmCustomizeApi(DC_DISPLAY_OPTS);
export const ptDisplayOptsApi = withPerAdmin(
  makeDvmCustomizeApi(PT_DISPLAY_OPTS),
  (oid) => `ptDisplayOpts_ADOM#${oid}`
);

/** ----------------------------------------------------------------------------
 * Helper functions
 * -------------------------------------------------------------------------- */

// migrate dvmCustomize to an object if it is still an array
async function getUpdatedDvmCustomize(adom: RecOid) {
  const dvmCustomize = await dvmCustomizeApi(adom);

  if (Array.isArray(dvmCustomize)) {
    const toUpdate = {
      [DC_DISPLAY_OPTS]: dvmCustomize,
    };

    await dvmCustomizeApi(adom, toUpdate);

    return toUpdate;
  }

  return dvmCustomize;
}

function makeDvmCustomizeApi<T extends keyof DvmCustomization>(key: T) {
  async function fn(adom: RecOid, opts?: never): Promise<DvmCustomization[T]>;
  async function fn(adom: RecOid, opts: DvmCustomization[T]): Promise<void>;
  async function fn(adom: RecOid, opts: unknown) {
    const dvmCustomize = await getUpdatedDvmCustomize(adom);

    if (opts) {
      const updated = set({ ...dvmCustomize }, key, opts);
      return dvmCustomizeApi(adom, updated);
    }

    return get(dvmCustomize, key) as unknown;
  }
  return fn;
}

function withPerAdmin<T extends keyof DvmCustomization>(
  fn: ReturnType<typeof makeDvmCustomizeApi<T>>,
  getProp: (adomOid: number) => `ptDisplayOpts_ADOM#${number}`
) {
  return async function (adom, opts) {
    const adomOid = getOid(adom);
    const { update, query } = await perAdminFeature(getProp(adomOid));

    if (opts) {
      await update(opts, () => fn(adomOid, opts));
      return;
    }

    return query(() => fn(adomOid));
  } as typeof fn;
}
