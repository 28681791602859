import TerminalImpl, { getDefaultActions, getDefaultAddons } from './Terminal';
import {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
  createElement,
} from 'react';
import { fiWebSocket } from 'fi-websocket';
import fiWebSocketRemoteAddon, {
  subscribeToWorkspaceChange,
} from '../../terminal/fiWebSocketRemoteAddon';
import { ProToolkit, ProForm, ProLego } from '@fafm/neowise-pro';
import { useUpdateEffect } from 'rh_util_hooks';
import { Formik } from 'formik';
import {
  fmkFooterButtons,
  FmkInput,
  FmkErrorSpan,
  FmkObjSSelect2,
} from 'rc_form';
import { renderDropdown } from './util';
import { fiDeviceDataFetcher, fiDeviceDataLoader } from 'ra_device_util';
import { fiWorkspace } from 'fi-workspace';

const { Header, HeaderActions, Body, Footer, Section, Row, Column } = ProForm;

export { promptRemoteSshSetting };

export default function Terminal({
  $opener,
  deviceName,
  ip,
  sshPort,
  adminName,
}) {
  const instance = useRef({}).current;

  const [selectedRemote, setRemote] = useState(() => ({
    deviceName,
    oid: fiDeviceDataLoader.getDeviceByName(deviceName).oid,
    ip,
    sshPort,
    adminName,
  }));

  // map state to console request param
  const getConnectParam = () => {
    return {
      ipaddr: selectedRemote.ip,
      port: selectedRemote.sshPort,
      user: selectedRemote.adminName,
      // for check permission purpose
      oid: selectedRemote.oid,
    };
  };

  // select different device, reconnect console
  useUpdateEffect(() => {
    instance.reconnectConsole?.(getConnectParam());
  }, [selectedRemote.ip]);

  const [isDeviceLockedByMe, setIsDeviceLockedByMe] = useState(() => Boolean);

  // subscribe to workspace change to check whether user can connect to the selected device
  useEffect(() => {
    return subscribeToWorkspaceChange({
      fiWorkspace,
      onDeviceLockChange: (isDeviceLockedByMe) => {
        setIsDeviceLockedByMe(() => isDeviceLockedByMe);
      },
    });
  }, []);

  const getDevices = useCallback(() => {
    return fiDeviceDataLoader
      .getDevices(undefined, { useRawData: true })
      .then((devs) => {
        return devs.reduce((arr, dev) => {
          if (
            dev.ip &&
            (isDeviceLockedByMe(dev) || dev.name === selectedRemote.deviceName)
          ) {
            let _dev = {
              name: dev.name,
              detail: [dev.ip, dev.realip].filter(Boolean).join(' - '),
            };
            arr.push(_dev);
          }
          return arr;
        }, []);
      });
  }, [isDeviceLockedByMe]);

  const beforeChange = useCallback(async (newId, newItem, oldId) => {
    if (newId) {
      if (newId === oldId) return;
      const { name: deviceName } = newItem;
      setRemote(await promptRemoteSshSetting(deviceName));
    }
  }, []);

  const { formatChoiceHTML, formatSelectedHTML, searchFn } = useMemo(() => {
    const params = {
      textAttr: 'name',
    };
    const formatChoiceHTML = FmkObjSSelect2.makeFormatChoiceHTML(params);
    const formatSelectedHTML = FmkObjSSelect2.makeFormatSelectedHTML(params);
    const searchFn = FmkObjSSelect2.makeSearchFn(params);

    return {
      formatChoiceHTML,
      formatSelectedHTML,
      searchFn,
    };
  }, []);

  const renderHeader = () => {
    const getAutoId = (suffix) => 'terminal:' + suffix;

    const {
      clear_console,
      copy_history,
      record_command,
      download_history,
      // eslint-disable-next-line
      cli_current_page,
      ...rest
    } = getDefaultActions(instance, { getAutoId });

    // override detach action callback to pass remote device console information
    const detachAction = rest.detach;

    if (detachAction) {
      rest.detach = {
        ...rest.detach,
        onClick: () => {
          instance.detachConsole({
            ...getConnectParam(),
          });
        },
      };
    }

    return (
      <Header
        actionSlot={
          <HeaderActions>
            {[
              <div key='remoteSelect' style={{ minWidth: '250px' }}>
                <ProLego.SSelect
                  source={getDevices}
                  value={selectedRemote.deviceName}
                  idAttr='name'
                  textAttr='name'
                  itemHeight={41}
                  beforeChange={beforeChange}
                  automationId={getAutoId('select_remote')}
                  formatChoiceHTML={formatChoiceHTML}
                  formatSelectedHTML={formatSelectedHTML}
                  searchFn={searchFn}
                />
              </div>,
            ].concat(
              [clear_console, copy_history, record_command, download_history]
                .map((icon) => (
                  <nw-icon-button key={icon.key} {...icon}></nw-icon-button>
                ))
                .concat(
                  renderDropdown(Object.values(rest), {
                    'automation-id': getAutoId('more'),
                  })
                )
            )}
          </HeaderActions>
        }
      >
        {gettext('CLI Console of %s').printf([selectedRemote.deviceName])}
      </Header>
    );
  };

  const initAddons = () => {
    return {
      ...getDefaultAddons(instance, { $opener }),
      websocket: new fiWebSocketRemoteAddon({
        fiWorkspace,
        fiWebSocket: fiWebSocket,
        remoteParam: getConnectParam(),
      }),
    };
  };

  return createElement(TerminalImpl, {
    instance,
    initAddons,
    renderHeader,
    $opener,
  });
}

async function promptRemoteSshSetting(deviceName) {
  const [info, setting] = await Promise.all([
    fiDeviceDataFetcher.getDevInfo(deviceName),
    fiDeviceDataFetcher.getCategoryData({
      device: deviceName,
      vdom: 'global',
      cate: 'system global',
    }),
  ]);

  const { name: adminName } = await ProToolkit.openModal(
    <RemoteSshInfo initialValues={{ name: info.adm_usr }} />,
    {
      size: 'md',
      height: '225px',
    }
  ).result;

  return {
    deviceName,
    oid: info.oid,
    ip: info.tunnel_ip || info.ip,
    sshPort: setting['admin-ssh-port'],
    adminName,
  };
}

function RemoteSshInfo({ $opener, initialValues = {} }) {
  const getAutoId = (suffix) => 'remote-ssh-prompt:' + suffix;

  const onSubmit = (values) => {
    $opener.resolve(values);
  };

  const onCancel = () => {
    $opener.reject();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <>
        <Header>{gettext('Connect CLI via SSH')}</Header>
        <Body>
          <Section className='tw-min-w-0 np-section'>
            <Row label={gettext('Admin Name')}>
              <Column>
                <FmkInput
                  name='name'
                  automation-id={getAutoId('name')}
                  required
                />
                <FmkErrorSpan name='name' />
              </Column>
            </Row>
          </Section>
        </Body>
        <Footer>
          {fmkFooterButtons({
            canWrite: true,
            getAutoId,
            onCancel,
          })}
        </Footer>
      </>
    </Formik>
  );
}
