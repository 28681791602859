import { isString } from 'lodash';
import { highlightEscape } from 'kit-escape';
import { csfReg, fiDevgrpRegex, fiFazgrpRegex } from './dev_regex';

export const formatSelected = (node, preferLabel) => {
  if (!node) return '';

  let value = isString(node) ? node : node.value;
  const names = value.split('|');
  let [name, devType, vdom] = names;

  const {
    DEVICE_TYPE_DEV,
    DEVICE_TYPE_GRP,
    DEVICE_TYPE_DGP,
    DEVICE_TYPE_FGP,
    DEVICE_TYPE_ALL,
  } = MACROS.FAZ_DVM;

  if (
    preferLabel ||
    ((names.length === 1 || devType == DEVICE_TYPE_GRP) && node.label)
  ) {
    return node.label;
  } else if (fiDevgrpRegex.test(name)) {
    return name.replace(fiDevgrpRegex, '');
  } else if (
    (devType == DEVICE_TYPE_DGP || devType == DEVICE_TYPE_FGP) &&
    node.label
  ) {
    return node.label;
  } else if (devType == DEVICE_TYPE_GRP) {
    return name;
  } else if (devType == DEVICE_TYPE_ALL) {
    return gettext('All Devices');
  } else {
    if (csfReg.test(name)) {
      name = name.replace(csfReg, '');
    } else if (devType != null && devType != DEVICE_TYPE_DEV) {
      // other types checked already!
      vdom = devType;
    }
    return name + (vdom ? `[${vdom}]` : '');
  }
};

// Convert bar separated device node to a string with icon for display.
// For example, a node {'ha_mode': 1, value: 'FGT00123|0|'} becomes:
// ```
// <span class="device-icon ffg ffg-ha"></span>
// <span class="device-name">FGT00123</span>
// ```
export const formatSelectedWithIcon = (node, searchTerm, preferLabel) => {
  if (!node) return '';
  let devName = formatSelected(node, preferLabel);
  const hilitedName = hilitDevName(devName, searchTerm);
  let value = isString(node) ? node : node.value;
  let [name, devType, vdom] = value.split('|');
  devType -= 0;

  const { DEVICE_TYPE_GRP, DEVICE_TYPE_DGP, DEVICE_TYPE_FGP } = MACROS.FAZ_DVM;

  if (!isString(node)) {
    if (devType === DEVICE_TYPE_GRP) {
      return getFormattedDevName(
        'ffg-device-total',
        gettext('Log Group'),
        hilitedName
      );
    } else if (node['ha_mode']) {
      return getFormattedDevName('ffg-ha', gettext('HA device'), hilitedName);
    } else if (vdom) {
      return getFormattedDevName('ffg-vdom', gettext('VDOM'), hilitedName);
    }
  }

  if (csfReg.test(name)) {
    return getFormattedDevName(
      'ffg-security-fabric',
      gettext('Security Fabric'),
      hilitedName
    );
  }
  if (fiDevgrpRegex.test(name) || devType === DEVICE_TYPE_DGP) {
    // device in event handler does not have 'devgrp:' prefix
    value = name.replace(fiDevgrpRegex, '');
    return getFormattedDevName(
      'ffg-multiple-devices',
      gettext('Device Group'),
      hilitDevName(value, searchTerm)
    );
  }
  if (fiFazgrpRegex.test(name) || devType === DEVICE_TYPE_FGP) {
    // device in event handler does not have 'devgrp:' prefix
    value = name.replace(fiFazgrpRegex, '');
    return getFormattedDevName(
      'ffg-multiple-devices',
      gettext('Fabric Group'),
      hilitDevName(value, searchTerm)
    );
  }
  if (devType === DEVICE_TYPE_GRP) {
    return getFormattedDevName(
      'ffg-device-total',
      gettext('Log Group'),
      hilitedName
    );
  }
  return getFormattedDevName('ffg-device', gettext('Device'), hilitedName);
};

const nameTemplate = '<span class="device-name">%s</span>';
const iconTemplate = '<span class="device-icon ffg %s" title="%s"></span>';
const hilitDevName = (devName, searchTerm) => {
  return highlightEscape(devName, searchTerm);
};

const getFormattedDevNameFn =
  (iconTemplate, nameTemplate) => (iconName, iconTitle, hilitedName) => {
    return (
      iconTemplate.printf([iconName, iconTitle]) +
      nameTemplate.printf([hilitedName])
    );
  };

const getFormattedDevName = getFormattedDevNameFn(iconTemplate, nameTemplate);

/**
 * Given a string like 'FGT010101|0|vdomSomething', convert it to
 * 'FGT0101[vdomSomething]'. If the middle number is 1, try to find
 * it in lookupDict (optional). If the number is 2, show it as
 * 'All Devices'.
 *
 * Alternatively, we also accept a device object like
 * {name: 'xxx', vdom: 'xxx' or null, type: like the middle number}
 */
export const devStringToDisp = (devString, lookupDict) => {
  try {
    let names = [];
    if (typeof devString === 'object') {
      names[0] = devString.name;
      names[1] = devString.type;
      names[2] = devString.vdom;
    } else {
      names = devString.split('|');
    }
    if (names.length === 1 && lookupDict[names[0]]) {
      return lookupDict[names[0]];
    }
    if (names[1] === MACROS.FAZ_DVM.DEVICE_TYPE_GRP) {
      return (
        (lookupDict && (lookupDict[names[0]] || lookupDict[devString])) ||
        names[0] + (names[2] ? '[' + names[2] + ']' : '')
      );
    } else if (names[1] === MACROS.FAZ_DVM.DEVICE_TYPE_ALL) {
      return gettext('All Devices');
    } else if (csfReg.test(names[0])) {
      return names[0].replace(csfReg, '');
    } else if ((names[0] || '').startsWith(MACROS.DVM.FAZ_GRP_PREFIX)) {
      return names[0].substring(MACROS.DVM.FAZ_GRP_PREFIX.length);
    } else {
      return names[0] + (names[2] ? '[' + names[2] + ']' : '');
    }
  } catch (ex) {
    console.error(ex); // guard against silent error inside promise.
    throw ex;
  }
};
