import { ServerTime, TimeUnit } from './types';

const HOUR_MS = 3600000;
const DAY_MS = 86400000;
const WEEK_MS = 604800000;

/**
 * Get start time of previous N time units in the local timezone.
 * @param {string} timeUnit - The time unit: 'hour', 'day', 'week', 'quarter', 'year'
 * @param {number} n - Previous N time unit
 * @param {ServerTime} serverTime - [year, month, date, hour, minute, second] current server time
 * @param {number} weekStart - The day of the week to start on (0 = Sunday, 1 = Monday, etc.)
 * @return {ServerTime} [year, month, date, hour, minute, second] of the start time in the local timezone
 */
export function getStartTime(
  timeUnit: TimeUnit,
  n: number,
  serverTime?: ServerTime,
  weekStart: number = 0
): ServerTime {
  let ret = 0;
  const now = serverTime ? new Date(...serverTime) : new Date();
  const currentTimezone = now.getTimezoneOffset();

  if (timeUnit === 'hour') {
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    ret = now.getTime() - n * HOUR_MS;
  } else if (timeUnit === 'day') {
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    ret = now.getTime() - n * DAY_MS;
  } else if (timeUnit === 'week') {
    const currentDay = now.getDay();
    let dayOffset: number;
    if (currentDay >= weekStart) {
      dayOffset = currentDay - weekStart;
    } else {
      dayOffset = currentDay + 7 - weekStart;
    }
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    ret = now.getTime() - dayOffset * DAY_MS - n * WEEK_MS;
  } else if (timeUnit === 'month') {
    const currentMonth = now.getMonth();
    const y = Math.floor(n / 12);
    const m = n % 12;
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    now.setDate(1);
    now.setFullYear(now.getFullYear() - y);
    if (m <= currentMonth) {
      now.setMonth(currentMonth - m);
    } else {
      now.setMonth(currentMonth + 12 - m);
      now.setFullYear(now.getFullYear() - 1);
    }
    ret = now.getTime();
  } else if (timeUnit === 'quarter') {
    const currentQuarter = Math.floor(now.getMonth() / 3);
    const y = Math.floor(n / 4);
    const q = n % 4;
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    now.setDate(1);
    now.setFullYear(now.getFullYear() - y);
    if (q <= currentQuarter) {
      now.setMonth((currentQuarter - q) * 3);
    } else {
      now.setMonth((currentQuarter + 4 - q) * 3);
      now.setFullYear(now.getFullYear() - 1);
    }
    ret = now.getTime();
  } else if (timeUnit === 'year') {
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    now.setMonth(0);
    now.setDate(1);
    now.setFullYear(now.getFullYear() - n);
    ret = now.getTime();
  }

  const start = new Date(ret);
  const startTimezone = start.getTimezoneOffset();

  // If either start or now is on daylight saving, there's 1 hour difference
  // Only process hour, day and week because these involve hour calculation
  if (
    timeUnit !== 'month' &&
    timeUnit !== 'quarter' &&
    timeUnit !== 'year' &&
    startTimezone !== currentTimezone
  ) {
    start.setHours(
      start.getHours() + Math.floor((startTimezone - currentTimezone) / 60)
    );
  }

  return [
    start.getFullYear(),
    start.getMonth(),
    start.getDate(),
    start.getHours(),
    start.getMinutes(),
    start.getSeconds(),
  ];
}

/**
 * Get end time of previous N time units in the local timezone.
 * @param {string} timeUnit - The time unit: 'hour', 'day', 'week', 'quarter', 'year'
 * @param {number}  n - Previous N time unit
 * @param {ServerTime} serverTime - [year, month, date, hour, minute, second] current server time
 * @param {number} weekStart - The day of the week to start on (0 = Sunday, 1 = Monday, etc.)
 * @return {ServerTime} [year, month, date, hour, minute, second] of the end time in the local timezone
 */
export function getEndTime(
  timeUnit: TimeUnit,
  n: number,
  serverTime?: ServerTime,
  weekStart: number = 0
): ServerTime {
  let ret = 0;
  const now = serverTime ? new Date(...serverTime) : new Date();
  const currentTimezone = now.getTimezoneOffset();

  if (timeUnit === 'hour') {
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    ret = now.getTime() - (n - 1) * HOUR_MS - 1000;
  } else if (timeUnit === 'day') {
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    ret = now.getTime() - (n - 1) * DAY_MS - 1000;
  } else if (timeUnit === 'week') {
    const currentDay = now.getDay();
    let dayOffset: number;
    if (currentDay >= weekStart) {
      dayOffset = currentDay - weekStart;
    } else {
      dayOffset = currentDay + 7 - weekStart;
    }
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    ret = now.getTime() - dayOffset * DAY_MS - (n - 1) * WEEK_MS - 1000;
  } else if (timeUnit === 'month') {
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    now.setDate(1);
    const currentMonth = now.getMonth();
    if (n === 0) {
      if (currentMonth === 11) {
        now.setFullYear(now.getFullYear() + 1);
        now.setMonth(0);
      } else {
        now.setMonth(currentMonth + 1);
      }
      ret = now.getTime() - 1000;
    } else {
      const y = Math.floor((n - 1) / 12);
      const m = (n - 1) % 12;
      now.setFullYear(now.getFullYear() - y);
      if (m <= currentMonth) {
        now.setMonth(currentMonth - m);
      } else {
        now.setMonth(currentMonth + 12 - m);
        now.setFullYear(now.getFullYear() - 1);
      }
      ret = now.getTime() - 1000;
    }
  } else if (timeUnit === 'quarter') {
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    now.setDate(1);
    const currentQuarter = Math.floor(now.getMonth() / 3);
    if (n === 0) {
      if (currentQuarter === 3) {
        now.setFullYear(now.getFullYear() + 1);
        now.setMonth(0);
      } else {
        now.setMonth((currentQuarter + 1) * 3);
      }
      ret = now.getTime() - 1000;
    } else {
      const y = Math.floor((n - 1) / 4);
      const q = (n - 1) % 4;
      now.setFullYear(now.getFullYear() - y);
      if (q <= currentQuarter) {
        now.setMonth((currentQuarter - q) * 3);
      } else {
        now.setMonth((currentQuarter + 4 - q) * 3);
        now.setFullYear(now.getFullYear() - 1);
      }
      ret = now.getTime() - 1000;
    }
  } else if (timeUnit === 'year') {
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    now.setMonth(0);
    now.setDate(1);
    now.setFullYear(now.getFullYear() - n + 1);
    ret = now.getTime() - 1000;
  }

  const end = new Date(ret);
  const endTimezone = end.getTimezoneOffset();

  // If either end or now is on daylight saving, there's 1 hour difference
  // Only process hour, day and week because these involve hour calculation
  if (
    timeUnit !== 'month' &&
    timeUnit !== 'quarter' &&
    timeUnit !== 'year' &&
    endTimezone !== currentTimezone
  ) {
    end.setHours(
      end.getHours() + Math.floor((endTimezone - currentTimezone) / 60)
    );
  }

  return [
    end.getFullYear(),
    end.getMonth(),
    end.getDate(),
    end.getHours(),
    end.getMinutes(),
    end.getSeconds(),
  ];
}
