export const NODE_TYPE_GRP = 'grp';
export const NODE_TYPE_DEV = 'dev';
export const NODE_TYPE_VDOM = 'vdom';

function _node_is_type(record, type) {
  return record.node ? record.node.type === type : record.type === type;
}

export const node_is_dev = (record) => _node_is_type(record, NODE_TYPE_DEV);
export const node_is_grp = (record) => _node_is_type(record, NODE_TYPE_GRP);
export const node_is_vdom = (record) => _node_is_type(record, NODE_TYPE_VDOM);
