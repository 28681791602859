import { useState, useRef, useMemo, useCallback } from 'react';
import { Formik } from 'formik';
import { DropUploader, FileLister } from 'rc_drop_uploader';
import { NwProgressBar, NwSpinner } from '@fafm/neowise-core';
import { ProForm } from '@fafm/neowise-pro';
import {
  OkBtn,
  CancelBtn,
  NwProInputRow,
} from 'react_components/rc_layout/form';
import { FmkErrorSpan } from 'react_components/rc_form/formik_layout/FmkErrorSpan';
import { openConfirmModal } from 'react_components/rc_layout/ConfirmModal';

import { UploaderService } from 'fi-file/uploader';

const { Header, Footer, Body, Section, Row, Column } = ProForm;

export function UploadLicenseForm({
  $opener,
  url,
  data,
  onSuccess,
  onCancel,
  licenseStatusDesc,
  hasUploadPermission = true,
  autoIdPrefix = 'system_license_upgrade',
  cancelBtnText = gettext('Cancel'),
}) {
  const [processing, setProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [message, setMessage] = useState('');
  // const auth = useAuth();
  const initValues = useMemo(() => ({ filePath: null }), []);
  const getAutoId = useCallback(
    (...suffix) => autoIdPrefix + ':' + suffix.join('-'),
    [autoIdPrefix]
  );

  const formikRef = useRef();
  const filePondRef = useRef(null);

  const onAddFile = useCallback((e, setFieldValue) => {
    setFieldValue('filePath', e.detail?.file);
  }, []);

  const [uploadingFile, setUploadingFile] = useState(null);
  const onCancelClick = useCallback(
    (e) => {
      $opener.reject();
      onCancel && onCancel(e);
    },
    [onCancel, $opener]
  );

  const uploader = useMemo(
    () =>
      UploaderService.create({
        url,
        key: 'filepath',
        data,
        onProgress: function (file) {
          setUploadingFile(file);
        },
        onCompleted: function (file, resp /*, status*/) {
          let content = resp.result ? resp.result[0] : {};
          setMessage('');
          if (content) {
            if (content.status && !content.status.code) {
              setMessage(
                <div>
                  <NwSpinner className='tw-mr-1' />
                  {gettext(
                    'The license file has been uploaded successfully and the system is restarting to verify it. Please wait for a few moments...'
                  )}
                </div>
              );
              // System should reboot once applied new license
              // go to login page when system is back online
              onSuccess && onSuccess($opener);
            } else {
              setErrorMsg(gettext('Invalid file.'));
              setProcessing(false);
              formikRef.current?.setSubmitting(false);
              formikRef.current?.setFieldValue('filePath', null);
            }
          }
        },
      }),
    [onSuccess, formikRef.current]
  );

  const validate = (values) => {
    const errors = {};
    if (!values.filePath)
      errors['filePath'] = gettext('Please choose a valid file.');
    return errors;
  };

  const submitForm = async (values) => {
    setProcessing(true);
    try {
      await openConfirmModal({
        content: gettext(
          'Uploading a device license will cause the system to reboot. Are you sure you want to proceed?'
        ),
        title: gettext('Confirm System Reboot'),
      });
    } catch {
      setProcessing(false);
      return;
    }
    setMessage(
      <div>
        <NwSpinner className='tw-mr-1' />
        {gettext('The license is uploading...')}
      </div>
    );
    uploader.removeAll();
    setUploadingFile(null);
    uploader.addFiles([values.filePath]);
    uploader.start();
  };

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={submitForm}
      validate={validate}
      innerRef={formikRef}
    >
      {({ values, setFieldValue, submitForm }) => (
        <>
          <Header>{gettext('Upload Device License')}</Header>
          <Body>
            <Section>
              {hasUploadPermission ? (
                <>
                  {!processing ? (
                    <>
                      {licenseStatusDesc && (
                        <Row>
                          <Column className='tw-text-danger-500'>
                            {licenseStatusDesc}
                          </Column>
                        </Row>
                      )}
                      <NwProInputRow>
                        <DropUploader
                          ref={filePondRef}
                          onProcess={(e) => onAddFile(e, setFieldValue)}
                        />
                        <FmkErrorSpan name='filePath' />
                      </NwProInputRow>
                      <FileLister
                        file={values['filePath']}
                        clear={() => setFieldValue('filePath', null)}
                      />
                      {errorMsg ? (
                        <NwProInputRow>
                          <span className='text-danger'>{errorMsg}</span>
                        </NwProInputRow>
                      ) : null}
                    </>
                  ) : null}
                  {processing ? (
                    <>
                      {uploadingFile ? (
                        <NwProInputRow>
                          <NwProgressBar
                            percentage={(
                              ((uploadingFile.loaded <= uploadingFile.size
                                ? uploadingFile.loaded
                                : uploadingFile.size) /
                                uploadingFile.size) *
                              100
                            ).toFixed(0)}
                          >
                            {uploadingFile.humanSize}
                          </NwProgressBar>
                        </NwProInputRow>
                      ) : null}
                      <NwProInputRow>{message}</NwProInputRow>
                    </>
                  ) : null}
                </>
              ) : (
                <NwProInputRow>
                  {gettext(
                    'Your account does not have permission to upload VM License. Please contact your system administrator.'
                  )}
                </NwProInputRow>
              )}
            </Section>
          </Body>
          {!processing && (
            <Footer>
              {!!hasUploadPermission && (
                <OkBtn
                  onClick={submitForm}
                  automation-id={getAutoId('buttons-ok')}
                >
                  {gettext('OK')}
                </OkBtn>
              )}
              <CancelBtn
                onClick={onCancelClick}
                automation-id={getAutoId('buttons-cancel')}
              >
                {cancelBtnText}
              </CancelBtn>
            </Footer>
          )}
        </>
      )}
    </Formik>
  );
}
