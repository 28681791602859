import { fiFmgHttp } from 'fi-http';
import * as SysJSONAPIService from './SysJSONAPIService';

const URL_MAIL = '/cli/global/system/mail';

export const server = function (key) {
  return SysJSONAPIService.get(URL_MAIL, key);
};
// eslint-disable-next-line
export const serverTemplate = function (key) {
  return SysJSONAPIService.getTemplate(URL_MAIL);
};
export const save = function (data) {
  if (data.actionevent === 'edit') {
    return SysJSONAPIService.update(URL_MAIL, data.id, data);
  } else {
    return SysJSONAPIService.add(URL_MAIL, data.id, data);
  }
};
export const serverList = function () {
  return SysJSONAPIService.get(URL_MAIL);
};
export const test = function (emails, key) {
  return fiFmgHttp
    .post({
      method: 'mailserver',
      url: '/gui/sys/test/server',
      params: {
        key,
        from: emails.from,
        to: emails.to,
      },
    })
    .then((resp) => {
      return resp[0]?.data;
    });
};
