import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { /*filter, get,*/ negate, overEvery } from 'lodash';

import * as actions from './actions';
import * as api from './api';
import { SocketActions } from 'fi-websocket';
import { getSessionAdomOid, isRootAdom } from 'fistore/session/adom/selectors';
import { getIsRestrictedAdmin } from 'fistore/session/sysConfig/selectors';

import { fetchSessionAdom } from 'fistore/session/adom/slice';
import { runIfAvailable } from '../../routing/saga';
import { getUnregDevicesAllIds } from './selectors';
import { debounceBatch } from '../../batch';
//import * as notifActions from '../../notifications/actions';
import { callPromiseAction } from '../../utils';
const {
  NOTIFY_ADDED_ACTION,
  NOTIFY_BATCH_REMOVED_ACTION,
  NOTIFY_CHANGED_ACTION,
  NOTIFY_REMOVED_ACTION,
} = SocketActions;

const makeUnregDevicAction =
  makeCollectionAction('unreg-device')(processUnregDevice);

export function* watchUnregDeviceAction() {
  yield takeEvery(NOTIFY_ADDED_ACTION, makeUnregDevicAction);
  yield takeEvery(NOTIFY_CHANGED_ACTION, makeUnregDevicAction);
  yield takeEvery(NOTIFY_REMOVED_ACTION, makeUnregDevicAction);

  // batched
  yield takeEvery(NOTIFY_BATCH_REMOVED_ACTION, dvmBatchedRemovedNotify);

  yield takeLatest(
    actions.fetchUnregDevicesAction.type,
    function* fetchUnregDeviceFn(action) {
      yield callPromiseAction(action, function* () {
        yield call(fetchUnregDevice);
      });
    }
  );
  yield takeLatest(
    fetchSessionAdom.fulfilled.type,
    runIfAvailable(
      overEvery(negate(getIsRestrictedAdmin), isRootAdom),
      fetchUnregDevice
    )
  );
}

function* fetchUnregDevice() {
  const adomOid = yield select(getSessionAdomOid);
  yield put(actions.unregDevicesAction.fetch.request());
  const ret = yield call(api.fetch_unreg_devices, adomOid);
  const devData = ret.result[0].data;
  // remove the hidden ones
  yield put(actions.unregDevicesAction.fetch.success(devData));

  /* use unreg data in ws for the notification
  const { allIds, byId } = devData;
  const visibleIds = filter(allIds, (id) => !get(byId, [id, 'hidden_status']));
  if (visibleIds.length) {
    // set notification
    yield put(
      notifActions.setNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_DVM_UNREG,
        params: { num: visibleIds.length },
        adomOid,
      })
    );
  }
  */
}

function* processUnregDevice({ type, payload }) {
  const { id, meta } = payload;
  let adom = meta?.adom;

  const getMethod = () =>
    type === NOTIFY_REMOVED_ACTION
      ? 'delete'
      : type === NOTIFY_ADDED_ACTION
      ? 'add'
      : 'change';

  if (type === NOTIFY_REMOVED_ACTION) {
    yield put(actions.unregDevicesAction.record[getMethod()]({ oid: id }));
  } else {
    const data = yield call(api.fetch_unreg_device, adom ? adom : 3, id);

    const grp = data.result[0].data;

    yield put(actions.unregDevicesAction.record[getMethod()](grp));
  }
}

function makeCollectionAction(collection) {
  return (processFn) => {
    return function* (action) {
      if (action.payload.collection !== collection) return;

      yield processFn(action);
    };
  };
}

function* dvmBatchedRemovedNotify(action) {
  const payload = action.payload;
  if (payload.collection !== 'device') return;

  try {
    // notification from server: device_update_notify.cpp > MonitorDelDevice
    const { meta: metas } = payload;
    if (!metas) return;

    const updates = [];

    const unregDevicesIds = yield select((state) => {
      const ids = getUnregDevicesAllIds(state) || [];
      return ids.map((id) => parseInt(id));
    });

    if (!unregDevicesIds || !unregDevicesIds.length) {
      return;
    }

    for (const meta of metas) {
      const deviceOids = meta.deviceOids;

      if (!Array.isArray(deviceOids)) {
        continue;
      }

      for (const oid of deviceOids) {
        const devOid = parseInt(oid);
        if (unregDevicesIds.includes(devOid)) {
          const update = put(
            debounceBatch(
              actions.unregDevicesAction.record.delete({ oid: devOid })
            )
          );
          updates.push(update);
        }
      }
    }

    yield all(updates);
  } catch (err) {
    if (MACROS.SYS.CONFIG_DEBUG) {
      console.error(err);
    }
  }
}
