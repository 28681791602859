import goog from '@fafm/goog';
import { isDefined, isFunction } from 'fiutil';
import { deviceStatus } from 'fi-dvm';
import { isNil } from 'lodash';

/**
 * Parses the records data for the table
 * @param {Array} records - the original records returned by the server
 * @param {Array} colDefs - an array of objects which are the column definitions
 * @param {Object} cellDataParser - parser of the data for each cell
 * @param {Object} options - (Optional) options needed further process
 * @param {Object} options.parentRecord - (Optional) the parent record that these records in the first argument belongs to.
 *                        Typically used for listing VDOM
 * @param {String} options.childrenAttr - (Optional) the attribute of the record indicates where the record's children are in
 * @param {Function} options.attachedFn - (Optional) the function which will be run for each element of records.
 *                        Takes the each element as the argument.
 * @return {Array} the parsed records, suitable for table
 */
export function parseDvmTableData(
  records,
  colDefs,
  cellDataParser,
  options = {}
) {
  const dataTobeParsed = [].concat(records);
  let parsedData = [];
  const groupMap = {};

  dataTobeParsed.forEach((oRec) => {
    if (!oRec) return;

    const rowData = {
      rowHeight: 24,
    };

    if (options.notIncludeCNF && oRec.iscnf) {
      return;
    }

    if (isDefined(options.parentRecord)) {
      oRec.sn = oRec.sn || options.parentRecord.sn;
    }

    let rowKey;

    if (oRec.isGroup === 1) {
      rowData.isSelected = false; // flag for the row selection in the table
      rowData._oData = oRec;
      rowData.cdrows = [];
      Object.assign(rowData, oRec);
      groupMap[oRec.groupName] = rowData;

      rowKey = oRec.groupName; // compatible with js-table
      if (Array.isArray(oRec?.children))
        rowData.children = parseDvmTableData(
          oRec.children,
          colDefs,
          cellDataParser,
          options
        );
      parsedData.push(rowData);
    } else {
      rowData.groupIndex = oRec.groupIndex;
      let oData = null;
      // fill out cells for each row
      colDefs.forEach(function (col, colIndex) {
        // some fields does not exist in vdom, will cause exception
        // Need be fixed, should not use catch exception to advoid errors.
        const key = col.field || col.key;
        try {
          const col_def = colDefs[colIndex];
          if (col_def.parser) {
            if (isFunction(col_def.parser)) {
              // a special column parser defined in somewhere else
              oData = col_def.parser(oRec);
            } else if (cellDataParser[col_def.parser]) {
              // a special parser defined in table_data_parser.js, for a lot dependencies
              oData = cellDataParser[col_def.parser](oRec);
            }
          } else {
            oData = cellDataParser.getData(oRec, key, col_def); //pass colDefs[colIndex] to $scope to eval the values.
          }
        } catch (e) {
          //
        }
        rowData[key] = oData;
        if (oData && oData.css) {
          rowData[key].css = oData.css;
        }
      });

      if (Array.isArray(oRec.children)) {
        rowData.children = parseDvmTableData(
          oRec.children,
          colDefs,
          cellDataParser,
          options
        );
      }

      rowData.rowHeight = getRowHeight(rowData, colDefs);
      rowData._oData = oRec;
      rowData._level = options && options.level ? options.level : 1;

      if (isDefined(options) && isDefined(options.parentRecord)) {
        // this will cause circular reference,
        // circular reference is fine for js, but will fail when do JSON.parse(JSON.stringify)
        // rowData._oData._pData = parentRecord;
        // TODO: ****** better not to modify orignal oData !!!!*****
        let pRec = options.parentRecord;
        rowData._oData._pData = {
          _fiDeviceId: pRec._fiDeviceId,
          name: pRec.name,
          did: pRec.did,
          oid: pRec.oid,
          sn: pRec.sn,
          vernum: pRec.vernum,
          _csfGroupName: pRec._csfGroupName,
          _isCsfRoot: pRec._isCsfRoot,
          model_dev: pRec.model_dev,
          platform_str: pRec.platform,
          platform_id: pRec.platform_id,
          [deviceStatus.field.connectivity]:
            pRec?.[deviceStatus.field.connectivity],
        };
        pRec = null;
      }

      if (isDefined(oRec.rtype)) {
        rowData.rtype = oRec.rtype;
      }

      rowData.isSelected = isDefined(oRec.isSelected) ? oRec.isSelected : false; // flag for the row selection in the table
      let childrenRecords = [];

      if (isDefined(options) && oRec.hasOwnProperty(options.childrenAttr)) {
        // push children to the parsed records
        childrenRecords = parseDvmTableData(
          oRec[options.childrenAttr],
          colDefs,
          cellDataParser,
          {
            parentRecord: oRec,
            childrenAttr: options.childrenAttr,
            attachedFn: options.attachedFn,
            level: options && options.level ? options.level + 1 : 2,
          }
        );
      }

      if (isDefined(options) && oRec.hasOwnProperty(options.childrenAttr2)) {
        childrenRecords = parseDvmTableData(
          oRec[options.childrenAttr2],
          colDefs,
          cellDataParser,
          {
            parentRecord: oRec,
            childrenAttr: options.childrenAttr2,
            childrenAttr2: options.childrenAttr, //csf "member" child also has "vdoms" children
            attachedFn: options.attachedFn,
            level: options && options.level ? options.level + 1 : 2,
          }
        );
      }
      rowKey = oRec._fiDeviceId || oRec.name; // oRec.name is CSF name
      parsedData.push(rowData);
      parsedData = parsedData.concat(childrenRecords);

      if (isDefined(options) && isFunction(options.attachedFn)) {
        options.attachedFn(oRec, rowData, childrenRecords);
      }

      if (oRec.groupName && groupMap[oRec.groupName]) {
        groupMap[oRec.groupName].cdrows.push(rowData); //keep record of child rows.
      }
    }

    if (rowKey !== undefined && !rowData.entryKey) {
      rowData.entryKey = !isNil(oRec.groupName)
        ? `${oRec.groupName}-${rowKey}`
        : rowKey;
    }
  });
  return parsedData;
}

/**
 * @param {Object|Object[]} colDefs - map of array. Map is a lot better.
 */

function getRowHeight(parsedEntry, colDefs) {
  const fieldsToCheck = [
    deviceStatus.field.controller_counter,
    deviceStatus.field.connectivity,
    deviceStatus.field.profStatus,
  ];
  let cellHeight = MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_24;
  fieldsToCheck.forEach(function (field) {
    cellHeight = Math.max(
      _getCellHeight(field, parsedEntry, colDefs),
      cellHeight
    );
  });
  return cellHeight;
}

function _findColDef(field, colDefs) {
  if (Array.isArray(colDefs)) {
    return goog.array.find(colDefs, function (colDef) {
      const key = colDef.field || colDef.key;
      return colDef[key] === field;
    });
  }
  return colDefs[field];
}

function _getControllerHeight(cellData, defaultHeight, padding) {
  if (!cellData.data || !cellData.expanded) return defaultHeight;
  let calculatedHeight = Object.keys(cellData.data).length * 24 + padding; //default header parents
  if (cellData.expanded[MACROS.USER.DVM.ASSETS_FORTIEXTENDER_ABBR]) {
    //TODO: extract fn
    calculatedHeight += (cellData.data?.fex_cnt?.val || 0) * 24 + padding;
  }
  if (cellData.expanded[MACROS.USER.DVM.ASSETS_FORTIAP_ABBR]) {
    calculatedHeight += (cellData.data?.fap_cnt?.val || 0) * 24 + padding;
  }
  if (cellData.expanded[MACROS.USER.DVM.ASSETS_FORTISWITCH_ABBR]) {
    calculatedHeight += (cellData.data?.fsw_cnt?.val || 0) * 24 + padding;
  }
  return calculatedHeight;
}

function _getCellHeight(field, parsedRec, colDefs) {
  const CELL_PADDING = 4; // padding for table cell.
  const colDef = _findColDef(field, colDefs);
  const cellData = parsedRec[field];
  let defheight = MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_24;
  if (!colDef || colDef.hidden || !cellData) {
    return defheight;
  }
  let cellHeight;
  switch (field) {
    case deviceStatus.field.controller_counter:
      cellHeight = _getControllerHeight(cellData, defheight, CELL_PADDING);
      break;
    case deviceStatus.field.connectivity:
      cellHeight = Array.isArray(cellData.cd)
        ? cellData.cd.length * 20 + CELL_PADDING
        : defheight;
      break;
    case deviceStatus.field.profStatus: {
      let validData = cellData.txtData.filter((it) => !!it.txt);
      cellHeight = Array.isArray(validData)
        ? validData.length * 20 + CELL_PADDING
        : defheight;
      break;
    }
    default:
      cellHeight = defheight;
      break;
  }
  return cellHeight;
}
