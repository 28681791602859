/** A state store */
import { assign, omit } from 'lodash';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  listenerMiddleware,
  thunkMiddleware,
  sagaMiddleware,
} from './middlewares';

import { sagaPromiseMiddleWare } from './utils/saga-promise';
import authReducer from './auth/slice';
import * as adomLocalConfigSlice from './adomLocalConfig/slice';
import collections, * as collectionsDuck from './collections';
import { reducer as alertReducer } from './alert';
import { copilotReducer } from './copilot/slice';
import { socReducer as soc, duck as socDuck } from './soc';
import {
  socFabricReducer as fazSocFabric,
  socFabricDuck,
} from './fazSocFabric/fazSocFabric';
import fabricIotReducer from './fabric/slice';
import * as fiStoreUtils from './utils';
import { workspaceResReducer } from './workspace_res/reducer';
import { watchWorkspace } from './workspace_res/saga';
import * as workspaceRequestDuck from './workspace_res/request';
import * as workspaceSelector from './workspace_res/selector';
import notificationReducer from './notifications/slice';
import { reducer as taskReducer } from './tasks/reducers';

import { connector, connector_class, ObserverBase } from './connector';

import { store_set_dispatch } from './utils';
import { createBatchReducer, debounceBatchMiddleware } from './batch';

// theme
import themeReducer from './theme/slice';
import * as themeSlice from './theme/slice';
import * as themeSelectors from './theme/selectors';

// main frame
import * as mainFrameSlice from './main-frame/slice';
import * as mainFrameSelectors from './main-frame/selectors';

// pno
import * as pnoSlice from './pno/slice';
import * as pnoSelectors from './pno/selectors';

// sagas
import { watchLogviewChange } from './logview';
import fabricReducer, { watchFabricChange } from './fabric';
import { watchTasks } from './tasks/saga';

/** ----------------------------------------------------------------------------
 * Devices
 * -------------------------------------------------------------------------- */
// dvm reducers/sagas
import { dvmReducer, deviceRootSaga } from './devices';
// additional reducers
import { deviceFirmwareReducer } from './devices/firmware/reducer';
import { deviceLicensesReducer } from './devices/license/reducer';
import { devicesPsirtReducer } from './devices/psirt/reducer';
import { remoteFAZReducer } from './devices/remote_faz/reducer';
import { unregDevicesReducer } from './devices/unreg_device/reducer';
import { deviceAuthNeededReducer } from './devices/auth_needed/reducer';
import { devicesSyntaxAvailabilityReducer } from './devices/syntax/reducers';

import * as devGroupsAction from './devGroups/action';
import {
  deviceGroupsReducer,
  deviceGroupsMembReducer,
  deviceGroupsSelector,
  defaultGroupsReducer,
  deviceGroupRootSaga,
} from './devGroups';

import sessionReducer from './session/reducer';
import * as adomSlice from './session/adom/slice';
import * as adomSelectors from './session/adom/selectors';
import * as adomUtils from './session/adom/utils';
import * as profileSlice from './session/profile/slice';
import * as profileSelectors from './session/profile/selectors';
import * as sysConfigSlice from './session/sysConfig/slice';
import * as sysConfigSelectors from './session/sysConfig/selectors';
import * as adomLocalConfigSelectors from './adomLocalConfig/selectors';

import * as fmgRemoteFaz from './fmgRemoteFaz/index';
import { actions as vpnActions, reducer as vpnReducer } from './vpn';
import { swcReducer } from './fortiswitch/reducers';

import logviewReducer from './logview/slice';
// ======================== SDWAN ========================
import { sdwanReducer } from './sdwan';
import * as sdwanRequest from './sdwan/requests'; //eslint-disable-line
import * as sdwanApi from './sdwan/api';

import { fazRTQ } from './fazRTQ';

import * as adomSyntaxSlice from './adomSyntax/slice';
import * as adomSyntaxSelector from './adomSyntax/selectors';

import * as routingSelectors from 'fistore/routing/selectors';
import * as routingSlice from 'fistore/routing/slice';
import * as routingUtils from 'fistore/routing/utils';

import { watchDeviceSyntaxAvailability } from './devices/syntax/saga';

import { watchDocker } from './docker/saga';
import * as dockerSlice from './docker/slice';
import * as dockerSelectors from './docker/selectors';
import * as notifSlice from 'fistore/notifications/slice';
import * as notifSelectors from 'fistore/notifications/selectors';
// selectors
export * as fiDevicesSelector from './devices/selectors';
// actions
export * as fiDevicesAction from './devices/actions';
// util
export { make_device_filter } from './devices/util';
export { copilotActions } from './copilot';
export * as copilotSelectors from './copilot/selectors';

export const fiSession = omit(
  assign(
    {},
    adomSlice,
    adomSelectors,
    adomUtils,
    profileSlice,
    profileSelectors,
    sysConfigSlice,
    sysConfigSelectors
  ),
  'default'
);

export const fiStoreAdomLocalConfig = omit(
  assign({}, adomLocalConfigSlice, adomLocalConfigSelectors),
  'default'
);

export const fiStorePno = omit(assign({}, pnoSlice, pnoSelectors), 'default');
export { vpnActions };
export * from './fortiswitch/index';

export const fiStoreTheme = {
  ...themeSlice,
  ...themeSelectors,
};

export const fiStoreMainFrame = {
  ...mainFrameSelectors,
  ...mainFrameSlice,
};

export const fiStoreDocker = {
  ...dockerSlice,
  ...dockerSelectors,
};

const adomReducer = combineReducers({
  alert: alertReducer,
  copilot: copilotReducer,
  deviceGroups: deviceGroupsReducer,
  deviceGroupsMemb: deviceGroupsMembReducer,
  defaultGroups: defaultGroupsReducer,
  unregDevices: unregDevicesReducer,
  remoteFAZ: remoteFAZReducer,
  deviceAuthNeeded: deviceAuthNeededReducer,
  pno: pnoSlice.default,
  vpn: vpnReducer,
  logview: logviewReducer,
  fabric: fabricReducer,
  devicesPsirt: devicesPsirtReducer,
  adomSyntax: adomSyntaxSlice.default,
  fortiswitch: swcReducer,
  deviceSyntax: devicesSyntaxAvailabilityReducer,
  fabricIot: fabricIotReducer,
});

const rootReducer = combineReducers({
  session: sessionReducer,
  fmgRemoteFaz: fmgRemoteFaz.reducer,
  adomLocalConfig: adomLocalConfigSlice.default,
  collections,
  soc,
  fazSocFabric,
  dvm: dvmReducer,
  sdwan: sdwanReducer,
  adom: adomReducer,
  deviceLicenses: deviceLicensesReducer,
  deviceFirmwares: deviceFirmwareReducer,
  workspaceRes: workspaceResReducer,
  [fazRTQ.reducerPath]: fazRTQ.reducer,
  routing: routingSlice.default,
  notifications: notificationReducer,
  tasks: taskReducer,
  auth: authReducer,
  docker: dockerSlice.default,
  theme: themeReducer,
  mainFrame: mainFrameSlice.default,
});

export const fiStore = configureStore({
  reducer: createBatchReducer(rootReducer),
  middleware: [
    listenerMiddleware.middleware,
    thunkMiddleware,
    sagaPromiseMiddleWare,
    sagaMiddleware,
    fazRTQ.middleware,
    debounceBatchMiddleware,
  ],
  // TODO: use the default middlewares
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware()
  //     .prepend(listenerMiddleware.middleware)
  //     .concat([
  //       sagaPromiseMiddleWare,
  //       sagaMiddleware,
  //       fazRTQ.middleware,
  //       debounceBatchMiddleware,
  //     ]);
  //},
  devTools:
    process.env.NODE_ENV === 'development'
      ? {
          name: 'fiStore',
          actionsDenylist: [
            'NOTIFY_NOTIFY_ACTION',
            'auth/checkAuth',
            'auth/setLife',
            'sysConfig/setSysTimestamp',
            'notification/UNSET',
            'notification/SET',
            'FETCH_WORKSPACE-OBJECT_SUCCESS',
          ],
        }
      : false,
});

export const connect = connector(fiStore);
export const connect_class = connector_class(fiStore);
export { ObserverBase };
export { ObserverBase as fiStoreObserverBase };

export const fiStoreCollections = collectionsDuck;
export const fiStoreSoc = socDuck;
export const fiStoreSocFabric = socFabricDuck;
export const fiStoreConnector = connect;
export const fiStoreClassConnector = connect_class;

export const fiStoreRouting = {
  ...routingSelectors,
  ...routingSlice,
  ...routingUtils,
};

export * as fiStoreLogview from './logview';
export * as fiStoreAlert from './alert';
export * as fiStoreFabric from './fabric';

export const fiWorkspaceRequest = workspaceRequestDuck;
export const fiWorkspaceDataSelector = workspaceSelector;
export const fiDeviceGroupsSelector = deviceGroupsSelector;
export const fiSDWANRequest = sdwanRequest;
export const fiSDWANApi = sdwanApi;
export { fmgRemoteFaz };
export const fiDevGroupsAction = devGroupsAction;
export { fazRTQ };

export { fiStoreUtils };

const { fetchAdomSyntax } = adomSyntaxSlice;
export const fiAdomSyntax = {
  fetchAdomSyntax,
  ...adomSyntaxSelector,
};

// shortcut for observing store
export const observeStore = fiStoreUtils.observeStore(fiStore);
export const dispatch = fiStore.dispatch.bind(fiStore);
export const select = (selector) => selector(fiStore.getState());

export const fiStoreNotifications = omit(
  {
    ...notifSlice,
    ...notifSelectors,
  },
  'default'
);

export * as fiStoreTasks from './tasks';

// register_test_workspace(connect_class)
// register_test_devices(connect_class);

// registerDispatch((data) => fiStore.dispatch(data));

store_set_dispatch(fiStore.dispatch);

[
  // adom
  watchDeviceSyntaxAvailability,

  // dvm
  deviceRootSaga,
  deviceGroupRootSaga,

  // workspace
  watchWorkspace,

  // faz
  fmgRemoteFaz.watchFmgRemoteFaz,
  watchLogviewChange,
  watchFabricChange,

  // misc
  watchTasks,
  // navbar notification
  watchDocker,
].forEach((saga) => sagaMiddleware.run(saga));
