import React, {
  useState,
  cloneElement,
  useImperativeHandle,
  forwardRef,
  useMemo,
  isValidElement,
} from 'react';
import { ProForm, ProLego } from '@fafm/neowise-pro';
import './form.less';
import cn from 'classnames';
import { isFunction, isNil, merge } from 'lodash';
import {
  NwSwitch,
  NwIconButton,
  NwButton,
  NwSpinner,
} from '@fafm/neowise-core';
import { NwBadge } from '@fafm/neowise/react';
const { Tooltip } = ProLego;

const {
  Row: ProRow,
  Body: ProBody,
  Column: ProColumn,
  Header: ProHeader,
  Footer: ProFooter,
  Section: ProSection,
  SubSection: ProSubSection,
  Line: ProLine,
  Label: ProLabel,
} = ProForm;

export const PageModal = ({ children, ...rest }) => (
  <div
    className='tw-h-full fi-page-modal embed-responsive container-fluid no-padding'
    {...rest}
  >
    <div className='modal-content'>{children}</div>
  </div>
);

export const Header = ({ children, onCloseClick, ...rest }) => (
  <div className='modal-header' slot='header' {...rest}>
    <span className='text-left'>
      <b>{children}</b>
    </span>
    {onCloseClick && (
      <nw-icon-button
        name='close'
        style={{ float: 'right' }}
        onClick={onCloseClick}
      ></nw-icon-button>
    )}
  </div>
);

export const Body = ({ children, ...rest }) => (
  <div className='tw-h-full modal-body transparent' {...rest}>
    <div className='tw-w-full tw-h-full fi-form form-horizontal'>
      {children}
    </div>
  </div>
);

export const FlexBody = ({ children, ...rest }) => (
  <div className='modal-flex-body flex-column transparent' {...rest}>
    <div className='fi-form form-horizontal'>{children}</div>
  </div>
);

export const Container = ({ children, ...rest }) => (
  <div className='tw-w-full fi-container' {...rest}>
    {children}
  </div>
);

export const ContainerFaz = ({ children, ...rest }) => (
  <div className='tw-h-full fi-container fi-container-faz-wider' {...rest}>
    {children}
  </div>
);

export const FullLineLabel = ({ children, name, className, ...rest }) => {
  const classNames = (className || '') + ' ' + 'control-label fi-col-24';
  return (
    <label className={classNames} htmlFor={name} {...rest}>
      {children}
    </label>
  );
};

export const SectionHeader = ({ children }) => (
  <h2 className='fi-group-header'>{children}</h2>
);

export const Label = ({ children, indent, className, ...rest }) => {
  const classNames =
    (className || '') +
    ' ' +
    (indent ? 'control-label fi-col-7 fi-ml-1' : 'control-label fi-col-8');
  return (
    <label className={classNames} {...rest}>
      {children}
    </label>
  );
};

export const FormRowGroup = ({ children, className }) => (
  <div className={`fi-group fi-form-row ${className || ''}`}>{children}</div>
);

export const InputColumn = ({ children }) => (
  <div className='fi-col-rest'>{children}</div>
);

export const InputRow = ({
  label,
  labelCss,
  indent,
  children,
  hideLabel = false,
}) => {
  return (
    <FormRowGroup>
      {!hideLabel && (
        <Label className={labelCss} indent={indent}>
          {label}
        </Label>
      )}
      <InputColumn>{children}</InputColumn>
    </FormRowGroup>
  );
};

export const NwProInputRow = ({
  label,
  // dont add default indent value, otherwise labels indentation wont work in SubSection
  indent = null,
  rowProps = {},
  columnProps = {},
  children,
  hideEmpty = false,
}) => {
  return hideEmpty && !children ? null : (
    <ProRow label={label} {...rowProps} labelIndent={indent}>
      <ProColumn {...columnProps}>{children}</ProColumn>
    </ProRow>
  );
};

export const NwProRow = ({ children, ...rest }) => {
  return <ProRow {...rest}>{children}</ProRow>;
};

export const NwProColumn = ({ children, ...rest }) => {
  return <ProColumn {...rest}>{children}</ProColumn>;
};

export const NwProLabel = ({ children, ...rest }) => {
  return <ProLabel {...rest}>{children}</ProLabel>;
};

export const NwProHeader = ({ onCloseClick, children }) => {
  return (
    <ProHeader>
      <div className={'tw-flex tw-w-full tw-items-center tw-justify-between'}>
        {children}
        {onCloseClick && (
          <NwIconButton
            name='close'
            class='tw-float-right'
            onClick={onCloseClick}
          ></NwIconButton>
        )}
      </div>
    </ProHeader>
  );
};

export const NwProBody = ({ children, ...rest }) => {
  return <ProBody {...rest}>{children}</ProBody>;
};

export const NwProFooter = ({ children, ...rest }) => {
  return <ProFooter {...rest}>{children}</ProFooter>;
};

export const NwProSection = ({ children, hideEmpty = false, ...rest }) => {
  if (
    !hideEmpty ||
    isValidElement(children) ||
    (Array.isArray(children) && children.some((e) => !!isValidElement(e)))
  ) {
    return <ProSection {...rest}>{children}</ProSection>;
  }
  return null;
};

export const NwProSubSection = ({ children, ...rest }) => {
  return <ProSubSection {...rest}>{children}</ProSubSection>;
};

export const NwProLine = ({ children, ...rest }) => {
  return <ProLine {...rest}>{children}</ProLine>;
};

export const SectionTitle = ({ children, ...rest }) => (
  <div className='fi-group fi-form-row'>
    <div
      className='fi-col-rest control-label auto-padding-margin'
      style={{ fontWeight: 'bold' }}
      {...rest}
    >
      {children}
    </div>
  </div>
);

export const SectionToggleBtn = ({ open }) => (
  <span
    className={`ffg ${!open ? 'ffg-arrow-right' : 'ffg-arrow-down'}`}
  ></span>
);

export const Footer = ({ children, style }) => (
  <div className='modal-footer' slot='footer' style={style}>
    <div className='row no-margin'>
      <div className='text-center form-action-btns'>{children}</div>
    </div>
  </div>
);

export function loadingBtn({ loading, disabled, children, ...rest }) {
  return (
    <NwButton
      disabled={disabled || loading ? true : undefined}
      {...rest}
      prefix={
        !!loading && (
          <NwSpinner
            slot='prefix'
            style={{ '--indicator-color': 'rgb(var(--nw-color-neutral-0))' }}
            className='tw-mr-1'
          ></NwSpinner>
        )
      }
    >
      {children}
    </NwButton>
  );
}

/**
 * @type {import('@fafm/neowise-core').NwButton}
 */
export const OkBtn = ({ class: className = '', ...rest }) =>
  loadingBtn({
    ...rest,
    className: cn('tw-ml-2', 'tw-min-w-32', className, rest.className),
    type: 'primary',
  });

/**
 * @type {import('@fafm/neowise-core').NwButton}
 */
export const CancelBtn = ({ class: className = '', ...rest }) =>
  loadingBtn({
    ...rest,
    className: cn('tw-ml-2', 'tw-min-w-32', className, rest.className),
    type: 'default',
  });

export const Spacer = ({
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
}) => {
  const style = {
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  };
  const styleCopy = { ...style };
  for (const [key, val] of Object.entries(styleCopy)) {
    val ?? delete style[key]; // if val is null or undefined, delete the key
  }
  return <div style={style}></div>;
};

export const HelpTooltip = ({ children, iconProps = {}, ...props }) => (
  <nw-tooltip placement='top-start' {...props}>
    <nw-icon
      name='info-circle'
      library='fa-solid'
      className='tw-ml-1'
      style={{ color: 'rgb(var(--nw-color-info))' }}
      {...iconProps}
    ></nw-icon>
    {children}
  </nw-tooltip>
);

export const ConditionalWrapper = ({ children, condition, wrapper }) =>
  condition ? wrapper(children) : children;

/**
 * Used in Formik Forms. Renders a checkbox to the left of the Formik Component.
 * checked and isChecked is passed into the Formik Component by using function notation or React.cloneElement
 * Example:
 * <CheckBoxRow>
 *  {({checked, setChecked}) => Component} || <Component /> -> checked is passed as a prop
 * </CheckBoxRow>
 */
export const CheckBoxRow = forwardRef((props, ref) => {
  const {
    onChange = null,
    isChecked = false,
    disabled = false,
    value = null,
    field = '',
    text = '',
    rowProps = { labelSize: 'md' },
    checkBoxClass = 'tw-w-8', // 2rem, match with Column indent = 2
    childPlaceholder = '',
    children,
    ...rest
  } = props;

  const [checked, setChecked] = useState(isChecked);

  useImperativeHandle(ref, () => ({
    setCheckboxState: (isChecked) => setChecked(isChecked),
    getCheckboxState: () => checked,
  }));

  const onCheckboxClicked = (event) => {
    event.stopPropagation();
    setChecked(event.target.checked);
    if (isFunction(onChange))
      onChange(event.target.checked, event.target.value);
  };

  const renderChildren = () => {
    return isFunction(children)
      ? children({ checked, setChecked })
      : cloneElement(children, { checked, setChecked });
  };

  return (
    <NwProRow label={text} {...rowProps}>
      <NwProColumn>
        <NwProLine>
          <NwSwitch
            name={`${field}-checkbox`}
            automation-id={`${field}-checkbox`}
            disabled={disabled}
            value={value}
            checked={checked}
            onChange={onCheckboxClicked}
            className={checkBoxClass}
            {...rest}
          ></NwSwitch>

          <ConditionalComponent condition={checked}>
            {renderChildren()}
          </ConditionalComponent>

          <ConditionalComponent
            condition={!checked && !isNil(childPlaceholder)}
          >
            {childPlaceholder}
          </ConditionalComponent>
        </NwProLine>
      </NwProColumn>
    </NwProRow>
  );
});

export const SelectionBlock = ({
  selected,
  onSelect,
  containerCls,
  iconClass,
  children,
}) => {
  return (
    <div
      role='button'
      className={cn('tw-relative', containerCls)}
      onClick={onSelect}
    >
      {children}
      {selected && (
        <nw-icon
          name='yes'
          class={cn(
            'tw-absolute tw-text-neutral-0 tw-bg-success tw-rounded-full tw-p-0.5 tw-right-3 tw-bottom-2',
            iconClass
          )}
        ></nw-icon>
      )}
    </div>
  );
};

export const PageSpinner = ({ isLoading = true, customStyle = {} }) => {
  const style = Object.assign(
    {
      fontSize: '2rem',
    },
    customStyle
  );
  return (
    <>
      {isLoading ? (
        <div className='tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full page-spinner-container'>
          <nw-spinner class='page-spinner' style={style}></nw-spinner>
        </div>
      ) : null}
    </>
  );
};
export const RwFooterButtons = (props) => {
  const defaultBtnProps = {
    submit: { txt: gettext('OK'), disabled: false },
    cancel: { txt: gettext('Cancel') },
    return: { txt: gettext('Close') },
  };
  const {
    isRwUser,
    isSubmitting = false,
    onSubmit,
    onCancel,
    getAutoId = (val) => val,
    btnProps,
  } = props;

  const mergedBtnProps = merge({}, defaultBtnProps, btnProps);
  const {
    submit: { txt: submitTxt, disabled: submitDisabled, ...submitRest },
    cancel: { txt: cancelTxt, ...cancelRest },
    return: { txt: returnTxt, ...returnRest },
  } = mergedBtnProps;

  return (
    <>
      {isRwUser ? (
        <>
          <OkBtn
            onClick={onSubmit}
            automation-id={getAutoId('btn-ok')}
            loading={isSubmitting}
            disabled={submitDisabled}
            {...submitRest}
          >
            {submitTxt}
          </OkBtn>
          <CancelBtn
            onClick={onCancel}
            automation-id={getAutoId('btn-cancel')}
            {...cancelRest}
          >
            {cancelTxt}
          </CancelBtn>
        </>
      ) : (
        <CancelBtn
          onClick={onCancel}
          automation-id={getAutoId('btn-return')}
          {...returnRest}
        >
          {returnTxt}
        </CancelBtn>
      )}
    </>
  );
};

export const RwApplyButton = (props) => {
  const {
    isRwUser,
    onSubmit,
    isSubmitting = false,
    getAutoId = () => {},
    isDisabled = false,
    ...rest
  } = props;

  return (
    <>
      {isRwUser ? (
        <OkBtn
          onClick={onSubmit}
          automation-id={getAutoId('btn-ok')}
          loading={isSubmitting}
          disabled={isDisabled}
          {...rest}
        >
          {gettext('Apply')}
        </OkBtn>
      ) : null}
    </>
  );
};

export const SectionLabel = ({ label, children }) => {
  return (
    <div className='tw-items-center tw-w-full tw-py-1 tw-pl-4 tw-mt-5 tw-mb-2.5 tw-font-bold tw-rounded tw-text-neutral-1000 tw-bg-neutral-100'>
      <div className='tw-font-bold'>{label}</div>
      {children}
    </div>
  );
};

export const ShowHideWrapper = ({ children, showCondition, className }) => {
  return (
    <div
      className={cn({ 'tw-hidden': !showCondition }) + ' ' + (className || '')}
    >
      {children}
    </div>
  );
};

export const HelpIcon = ({
  className = '',
  indent = false,
  tooltipContent = '',
}) => {
  return (
    <Tooltip content={tooltipContent}>
      <NwBadge className={cn(className, { 'tw-mr-1': indent })} type={'info'} />
    </Tooltip>
  );
};

export const ConditionalComponent = ({ condition, children }) => {
  const render = isFunction(condition) ? condition() : condition;
  return <>{render ? <>{children}</> : null}</>;
};

export const TernaryComponent = ({
  condition,
  trueComponent,
  falseComponent,
}) => {
  const toRender = isFunction(condition) ? condition : condition;
  return toRender ? trueComponent() : falseComponent();
};

export const ToggleableProSection = ({
  title,
  SwitchCompt,
  renderSwitch,
  children,
  inputRowProps,
  isSubSection = false,
  ...rest
}) => {
  const Section = useMemo(() => {
    return isSubSection ? ProSubSection : ProSection;
  }, [isSubSection]);

  // FIXME: Row label only allows string value, so we need to update this when neowise-pro have solution for using custom component for Row label
  const renderTitle = () => {
    const _label = isSubSection ? (
      <span className='tw-font-bold'>{title}</span>
    ) : (
      title
    );

    return (
      <NwProInputRow label={_label} {...inputRowProps}>
        {isFunction(renderSwitch) ? renderSwitch() : SwitchCompt}
      </NwProInputRow>
    );
  };

  return (
    <Section title={renderTitle()} {...rest}>
      {children}
    </Section>
  );
};

export const TooltipLabel = ({ className = '', children }) => {
  return <label className={`tw-text-neutral ${className}`}>{children}</label>;
};

/**
 * Renders a grid container that can be used for ProLego.Tooltip
 * By default, there are two columns, modify gridTemplateColumns to adjust.
 */
export const GridTooltipContainer = ({
  containerClass = '',
  gridStyles = {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gap: '0.25rem 1rem',
  },
  style = {},
  children,
}) => {
  return (
    <div
      className={`tw-grid ${containerClass}`}
      style={{ ...gridStyles, ...style }}
    >
      {children}
    </div>
  );
};
