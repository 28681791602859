import { useField, useFormikContext } from 'formik';
import { AutocompleteInput } from 'rc_autocomplete_input';

export const FmkAutoCompleteInput = ({
  name,
  'automation-id': propAutoId,
  automationId,
  validate,
  dataSource,
  createCommand,
  editCommands,
  children,
  ...rest
}) => {
  const { submitCount } = useFormikContext();
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
    name,
    validate,
  });
  return (
    <>
      <AutocompleteInput
        name={name}
        value={value}
        invalid={error && (touched || !!submitCount)}
        dataSource={dataSource}
        onChange={(evt) => {
          setTouched(true, false);
          setValue(evt.target.value);
        }}
        automation-id={propAutoId || automationId}
        createCommand={createCommand}
        editCommands={editCommands}
        {...rest}
      >
        {children}
      </AutocompleteInput>
    </>
  );
};
