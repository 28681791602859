import { useRef } from 'react';

import { isFunction } from 'lodash';

type UpdatedRef = (val: any) => React.RefObject<any>;

export const useUpdatedRef: UpdatedRef = (val) => {
  const ref = useRef(null);
  ref.current = isFunction(val) ? val(ref.current) : val;
  return ref;
};
