import goog from '@fafm/goog';
import { attrCountOfObject } from 'fiutil';

const ATTR_IP_TYPES = [
  'ip4addr',
  'ip4addrmask',
  'ipv4_mask',
  'ip4addrmask2',
  'ip4',
  'multiple_ipv4',
  'ip6addr',
  'ip6prefix',
  'multiple_ipv6',
];

const isDateTime = function (attr) {
  if (attr.type == 'date_time') {
    return true;
  }
  return false;
};

const isSimpleEnableDisableOpts = function (attr) {
  if (attr && attr.opts) {
    const opts = attr.opts;
    if (opts && attrCountOfObject(opts) == 2) {
      for (const testOpt in opts) {
        if (!opts.hasOwnProperty(testOpt)) continue;
        if (testOpt !== 'enable' && testOpt !== 'disable') {
          return false;
        }
      }
      return true;
    }
  }
  return false;
};

const isIpNetMask = function (attr) {
  if (attr) {
    return (
      (attr.type && ATTR_IP_TYPES.indexOf(attr.type) >= 0) ||
      (attr.subtype && ATTR_IP_TYPES.indexOf(attr.subtype) >= 0)
    );
  }
  return false;
};

/**
 *@description test if given attribute is data source type
 *@param {object} attr - the attributes definition in syntax
 *@return {boolean} true of false
 */
const isDataSource = function (attr) {
  if (attr && (attr.type == 'datasrc' || attr.ref || attr.ds)) {
    return true;
  }
  return false;
};

/**
 *@description test if given attribute is integer type
 *@param {object} attr - the attributes definition in syntax
 *@return {boolean} true of false
 */
const isIntegerType = function (attr) {
  if (
    attr.type == 'uint32' ||
    attr.type == 'uint8' ||
    attr.type == 'uint16' ||
    attr.type == 'multi_int' ||
    attr.type == 'uint64'
  ) {
    return true;
  }

  return false;
};

/**
 *@description test if given attribute is string type
 *@param {object} attr - the attributes definition in syntax
 *@return {boolean} true of false
 */
const isStringType = function (attr) {
  if (attr.type == 'varchar' || attr.type === 'string') {
    return true;
  }

  return false;
};

/**
 *@description test if given attribute can be presentate as a checkbox
 *@param {object} attr - the attributes definition in syntax
 *@return {boolean} true of false
 */
const isCheckbox = function (attr) {
  if (isIntegerType(attr) && attr.opts && !attr.excluded) {
    return true;
  }

  return false;
};

const stringEndsWith = function (str, suffix) {
  if (!str) {
    return false;
  }
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
};

const isIpV6 = function (attr) {
  if (isIpNetMask(attr)) {
    if (
      (attr.type && attr.type.indexOf('6') > 0) ||
      (attr.subtype && attr.subtype.indexOf('6') > 0)
    ) {
      return true;
    }
  }

  return false;
};

const isIpWithNetMask = function (attr) {
  if (isIpNetMask(attr)) {
    if (
      stringEndsWith(attr.type, 'mask') ||
      stringEndsWith(attr.type, 'mask2') ||
      stringEndsWith(attr.type, 'prefix') ||
      stringEndsWith(attr.subtype, 'mask') ||
      stringEndsWith(attr.subtype, 'mask2') ||
      stringEndsWith(attr.subtype, 'prefix')
    ) {
      return true;
    }

    if (
      stringEndsWith(attr.type, 'addr') ||
      stringEndsWith(attr.subtype, 'addr')
    ) {
      return false;
    }
  }

  return false;
};

const isUserTime = function (attr) {
  if (attr.type == 'user' && attr.subtype === 'time') {
    return true;
  }
  return false;
};

const isUserTimeWithoutSeconds = function (attr) {
  if (
    attr.type == 'user' &&
    attr.subtype === 'time' &&
    attr.noseconds === 'true'
  ) {
    return true;
  }
  return false;
};

const isUserDate = function (attr) {
  if (attr.type == 'user' && attr.subtype === 'date') {
    return true;
  }
  return false;
};

const isText = function (attr) {
  if (isStringType(attr)) {
    return true;
  }
  return false;
};

const isMultiInt = function (attr) {
  if (attr.type === 'multiple_int' || attr.type === 'multi_int') {
    return true;
  }
  return false;
};

const isTextArea = function (attr) {
  return (
    (attr.type == 'string' && attr.max && attr.max > 1000) ||
    (attr.type === 'user' &&
      Array.isArray(attr.flags) &&
      goog.array.find(attr.flags, (e) => e === 'isstring'))
  );
};

// this is mainly used for cfgmng feature
const isTable = function (type) {
  return type === 'table';
};

const isSeq = function (attr) {
  if (attr.type === 'seq' || attr.type === 'seqnum') {
    return true;
  }

  return false;
};

const isChildTable = function (attr) {
  if (attr.type == 'childtable') {
    return true;
  }
  return false;
};

const isChildObject = function (attr) {
  if (attr.type === 'childobject') {
    return true;
  }
  return false;
};

const isMultiString = function (attr) {
  if (attr.type === 'multiple_str' || attr.type === 'multi_str') {
    return true;
  }
  return false;
};

const isMultipleValues = function (attr) {
  const types = [
    'multiple_str',
    'multi_str',
    'multiple_ipv4',
    'multi_ipv4',
    'multiple_ipv6',
    'multi_ipv6',
    'multi_dstsrc_port_range',
  ];
  if (attr && types.includes(attr.type)) {
    return true;
  }
  return false;
};

/**
 *@description test if given attribute can be presentated as a selection
 *@param {object} attr - the attributes definition in syntax
 *@return {boolean} true of false
 */
const isSingleOption = function (attr) {
  if (attr.opts && attr.excluded) {
    return true;
  }

  return false;
};

const isMultipleOption = function (attr) {
  if (attr.opts && attr.excluded === false) {
    return true;
  }

  return false;
};

const isBoolean = function (attr) {
  if (attr.type === 'boolean') {
    return true;
  }

  return false;
};

export const fiDvmSyntaxUtils = {
  isSimpleEnableDisableOpts: isSimpleEnableDisableOpts,
  isDateTime: isDateTime,
  isDataSource: isDataSource,
  isIpNetMask: isIpNetMask,
  isIntegerType: isIntegerType,
  isCheckbox: isCheckbox,
  isIpV6: isIpV6,
  isIpWithNetMask: isIpWithNetMask,
  isText: isText,
  isSeq: isSeq,
  isUserTime: isUserTime,
  isUserTimeWithoutSeconds: isUserTimeWithoutSeconds,
  isUserDate: isUserDate,
  isMultiString: isMultiString,
  isMultipleValues: isMultipleValues,
  isChildTable: isChildTable,
  isChildObject: isChildObject,
  isSingleOption: isSingleOption,
  isMultipleOption: isMultipleOption,
  isBoolean: isBoolean,
  isMultiInt: isMultiInt,
  isTextArea: isTextArea,
  isTable: isTable,
};
