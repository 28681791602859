import { isEmpty, isString } from 'lodash';
import { DeviceRowIcon } from 'ra_device_util';
import { make_highlighter } from '../common/util';
import { node_indent, renderToggleIcon } from '../dvm_tree/node_comp';

//eslint-disable-next-line
export const treeRowCompMaker =
  ({ renderIndent, nodeMenu }) =>
  (Node) =>
  ({
    index,
    data, // tslint:disable-line:naming-convention
    style,
    isScrolling,
  } = {}) => {
    const { data: treeData, onSelect, onToggle, selector } = data;

    const record = treeData.records[treeData.order[index]];
    const { isSelected, trigClickIfNeed } = selector(record.node.key);
    trigClickIfNeed(() => {
      setTimeout(() => {
        onSelect && onSelect(record, { source: 'trigClick' });
      }, 1);
    });

    const highlight = make_highlighter(treeData.searchText);

    return nodeMenu(Node)({
      ...record,
      select: () => onSelect(record),
      toggle: () => onToggle(record),
      highlight,
      isSelected,
      style: Object.assign({}, style, {
        alignItems: 'center',
        display: 'flex',
      }),
      renderIndent,
      isScrolling,
    });
  };

export const treeRenderIndent = (node) => {
  const indent = node_indent(node);
  return <span className={'indent'} style={{ width: indent }}></span>;
};

export const DefaultTreeRow = (nodeMenu) =>
  treeRowCompMaker({ renderIndent: treeRenderIndent, nodeMenu })(TreeNodeComp);

export const DefaultListRow = (nodeMenu) =>
  treeRowCompMaker({ renderIndent: treeRenderIndent, nodeMenu })(TreeNodeComp);

/*********************************************************** */
//eslint-disable-next-line
export const NodeContent = ({ node, highlight = (it) => it }) => {
  const renderIcon = () => {
    if (isString(node.iconClass) && !isEmpty(node.iconClass)) {
      return (
        <span
          className={'node-icon tw-mr-1 ' + node.iconClass}
          title={node.iconTitle || ''}
        ></span>
      );
    }
    if (!node.iconProps && !node.className) {
      return null;
    }
    return (
      <DeviceRowIcon
        iconProps={node.iconProps}
        className={node.className}
        title={node.iconTitle}
      />
    );
  };

  return (
    <>
      {renderIcon()}
      <span className={'node-icon-text'} title={node.name}>
        {highlight(node.name)}
      </span>
    </>
  );
};

//eslint-disable-next-line
export function TreeNodeComp({
  node = {},
  highlight = (it) => it,
  isOpen = false,
  toggle = () => {},
  select = () => {},
  isSelected = false,
  renderIndent = () => {},
  style = '',
  className: cn = '',
}) {
  const className = [
    'node',
    node.isLeaf ? 'leaf' : 'parent',
    isSelected ? 'selected' : '',
    node.isLeaf || isOpen ? '' : 'collapsed',
    node.disabled ? 'disabled' : '',
  ];
  //const indent=node_indent(data)
  //eslint-disable-next-line
  const ContentComponent = node.contentComponent || NodeContent;
  return (
    <div
      tabIndex={'-1'}
      className={className.join(' ') + ` ${cn}`}
      style={style}
      key={node.key}
      automation-id={node.automationId}
      onClick={(e) => {
        e.preventDefault();
        // need to stop popup event which will trigger text on right content view
        e.stopPropagation();
        !node.disabled && select();
      }}
    >
      {renderIndent(node)}
      {!node.isLeaf && renderToggleIcon({ toggle: toggle, isOpen })}
      <ContentComponent node={node} highlight={highlight}></ContentComponent>
    </div>
  );
}
