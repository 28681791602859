import { combineReducers } from 'redux';
import { omit } from 'lodash';

import {
  workspaceAdomsAction,
  workspaceDevicesAction,
  workspacePkgsAction,
  workspacePkgChildrenStatusAction,
  workspaceObjectsAction,
} from './action';

import { changeRecordByPath, removeRecordByPath } from '../utils/record_action';

import { workspace_policies_reducer } from './policy_reducer';

/*
{
    "3":{
        loaded:true,
        byId:{

        }
    }
}
*/

function loadRecords(action) {
  return {
    loaded: true,
    byId: action.payload,
  };
}

function changeRecord(action, state) {
  if (!state || !state.loaded) return state;
  const { id, fields } = action.payload;

  const byId = {
    ...state.byId,
    [id]: fields,
  };

  return {
    ...state,
    byId,
  };
}

export function removeRecord(action, state) {
  if (!state || !state.loaded) return state;

  const { id } = action.payload;

  const oldById = state.byId || {};
  const newById = omit(oldById, id);

  return {
    ...state,
    byId: newById,
  };
}

export function loadRecordsByPath(action, state) {
  const { path, data } = action.payload;

  if (!path) return state;

  return {
    ...state,
    [path]: {
      loaded: true,
      byId: data,
    },
  };
}

export function workspace_adoms_reducer(
  state = {
    loaded: false,
    byId: {},
  },
  action
) {
  switch (action.type) {
    case workspaceAdomsAction.fetch.SUCCESS: {
      return loadRecords(action);
    }
    case workspaceAdomsAction.record.CHANGE: {
      return changeRecord(action, state);
    }
    case workspaceAdomsAction.record.DELETE: {
      return removeRecord(action, state);
    }
    default: {
      return state;
    }
  }
}

/*
{
    //adom oid
    "3":{
        //device oid
        loaded:true,
        byId:{
            "12":{

            }
        }
    }
}
*/
export function workspace_devices_reducer(state = {}, action) {
  switch (action.type) {
    case workspaceDevicesAction.fetch.SUCCESS: {
      return loadRecordsByPath(action, state);
    }
    case workspaceDevicesAction.record.CHANGE: {
      return changeRecordByPath(action, state);
    }
    case workspaceDevicesAction.record.DELETE: {
      return removeRecordByPath(action, state);
    }
    default: {
      return state;
    }
  }
}

/*
{
    //adom oid
    "3":{
        //pkg oid
        loaded:true,
        byId:{
            "12":{

            }
        }
    }
}
*/

export function workspace_pkgs_reducer(state = {}, action) {
  switch (action.type) {
    case workspacePkgsAction.fetch.SUCCESS: {
      return loadRecordsByPath(action, state);
    }
    case workspacePkgsAction.record.CHANGE: {
      return changeRecordByPath(action, state);
    }
    case workspacePkgsAction.record.DELETE: {
      return removeRecordByPath(action, state);
    }
    default: {
      return state;
    }
  }
}

/*
{
    //adom oid
    "3":{
        //pkg oid
        loaded:true,
        byId:{
            "12":{
                 locked:true,
                 dirty:true
            }
        }
    }
}
*/
function workspace_pkg_children_status_reducer(state = {}, action) {
  switch (action.type) {
    case workspacePkgChildrenStatusAction.fetch.SUCCESS: {
      return loadRecordsByPath(action, state);
    }
    case workspacePkgChildrenStatusAction.record.CHANGE: {
      return changeRecordByPath(action, state);
    }
    case workspacePkgChildrenStatusAction.record.DELETE: {
      return removeRecordByPath(action, state);
    }
    default: {
      return state;
    }
  }
}
export function workspace_objects_reducer(state = {}, action) {
  switch (action.type) {
    case workspaceObjectsAction.fetch.SUCCESS: {
      return loadRecordsByPath(action, state);
    }
    case workspaceObjectsAction.record.CHANGE: {
      return changeRecordByPath(action, state);
    }
    case workspaceObjectsAction.record.DELETE: {
      return removeRecordByPath(action, state);
    }
    default: {
      return state;
    }
  }
}

export function workspace_save_btn_highlight_reducer(
  state = { highlight: false },
  action
) {
  switch (action.type) {
    case 'WORKSPACE_SAVE_BTN_HIGHLIGHT_CHANGE': {
      return {
        ...state,
        highlight: action.payload.highlight ? true : false,
      };
    }
    default: {
      return state;
    }
  }
}
export const workspaceResReducer = combineReducers({
  adoms: workspace_adoms_reducer,
  devices: workspace_devices_reducer,
  pkgs: workspace_pkgs_reducer,
  pkgChildrenStatus: workspace_pkg_children_status_reducer,
  policies: workspace_policies_reducer,
  objects: workspace_objects_reducer,
  savebtn: workspace_save_btn_highlight_reducer,
});

/*
{
    "adomOid":0,
    "pkgOid":0,
    "deviceOid":0,
    "categoryOid":0,
}
*/
