import { ProToolkit } from '@fafm/neowise-pro';
import { fiAdom } from 'fi-session';
import { AllDevsPsirtTable } from '../../psirt';
import { OutOfSyncDevice } from '../../modal/OutOfSyncDevice';
import { fiDvmStateService } from 'fi-apps/fi-dvm';
import { openEditAdomModal, AdomSelectModal } from 'react_apps/ra_adom_edit';
import { fiStore, dispatch } from 'fistore/index';
import { fetchSessionAdom } from 'fistore/session/adom/slice';
import { go } from 'fistore/routing/slice';
import { flatui_proxy } from 'fistore/utils';
import { createElement } from 'react';
import { getCurrentState } from 'fistore/routing/selectors';
import { SIDE_BAR_RELOAD_KEY } from 'react_apps/ra_main/main_layout/MainSidebar';
import { uniqueId } from 'lodash';
import { getSessionAdomData } from 'fistore/session/adom/selectors';

const _getCSF = (params) => {
  return (Array.isArray(params) ? params[0] : params) || {};
};
export default {
  [MACROS.NOTICE.NOTICE_TYPE_DVM_UNREG]: {
    /**
     * @type {string | function(Object):void }
     */
    message: ({ params: { num } }) =>
      ngettext(
        '%s unauthorized device.',
        '%s unauthorized devices.',
        num
      ).printf([num]),
    exec: function () {
      fiDvmStateService.goDvmUnregisteredGroup();
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_DVM_OUTOFSYNC]: {
    message: ({ params: { num } }) =>
      ngettext(
        '%s device configuration is out of synchronization.',
        '%s devices configuration are out of synchronization.',
        num
      ).printf([num]),
    exec: () => {
      ProToolkit.openModal(<OutOfSyncDevice />, { width: 'lg' });
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_CSF_FORMATION]: {
    message: ({ params }) => {
      let csf = _getCSF(params);
      if (csf.state === MACROS.USER.SYS.UNREG_DEV) {
        return gettext(
          'Security Fabric device unauthorized: "%s" need to be promoted to the ADOM "%s" for the security fabric "%s".'
        ).printf([csf.detail.replace(/,/g, ', '), csf.adom, csf.csf]);
      } else if (csf.state === MACROS.USER.SYS.DIFF_ADOM) {
        return gettext(
          'Security Fabric device in other ADOM: "%s" need to be moved to the ADOM "%s" for the security fabric "%s".'
        ).printf([csf.detail.replace(/,/g, ', '), csf.adom, csf.csf]);
      }
    },
    exec: ({ params }) => {
      let csf = _getCSF(params);
      if (csf.state === MACROS.USER.SYS.UNREG_DEV) {
        fiDvmStateService.goDvmUnregisteredGroup();
      } else if (csf.state === MACROS.USER.SYS.DIFF_ADOM) {
        openEditAdomModal(
          fiAdom.current().oid,
          () => {
            fiStore.dispatch(fetchSessionAdom());
          },
          { open: ProToolkit.openModal }
        );
      }
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_FABRIC_PENDING_MEMBER]: {
    message: ({ params }) => {
      const count = params?.length || 1;
      return ngettext(
        '%(count)s pending Fabric join request.',
        '%(count)s pending Fabric join requests.',
        count
      ).printfd({ count });
    },
    exec: () => {
      dispatch(go({ to: '/sys/fabric/settings' }));
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_PSIRT_VULNERABLE]: {
    message: ({ params: { numDevices } }) => {
      return gettext(
        '%s managed devices are identified with security vulnerabilities. Firmware upgrade is recommended.'
      ).printf([numDevices]);
    },
    exec: () => {
      ProToolkit.openDrawer(<AllDevsPsirtTable />, { width: 'xl' });
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_FORTISASE_LICENSE_DETECTED]: {
    message: () => {
      return gettext(
        'FortiSASE management license detected. Click to select an ADOM and create connector.'
      );
    },
    exec: async ({ params }) => {
      const adom = params?.adom || getSessionAdomData(fiStore.getState());

      const selectedAdom = await ProToolkit.openModal(
        <AdomSelectModal allowCancel={true} />,
        {
          size: 'lg',
          closable: true,
          maximizable: false,
        }
      ).result;
      if (!selectedAdom) return;

      // switch adom
      if (adom.oid !== selectedAdom.oid) {
        // can't use switchSessionAdom thunk here because it will trigger page reload
        await flatui_proxy({
          url: '/gui/session/adom',
          method: 'change',
          params: {
            oid: selectedAdom.oid,
          },
        });
        await dispatch(fetchSessionAdom());
      }

      const { getEditPage } = await import(
        'react_apps/ra_pno_obj_edit_pages/index'
      );
      const Component = await getEditPage('fmg sase-manager settings');
      if (!Component) return;

      await ProToolkit.openModal(
        createElement(Component, {
          action: 'edit',
          editObjData: {
            statusToUpdate: true,
          },
          editObjFlags: {
            showApplyNRefresh: false,
          },
        }),
        {
          size: 'xl',
        }
      ).result;

      // force reload current route
      const currentState = getCurrentState(fiStore.getState());
      await dispatch(
        go({
          to: currentState.pathname,
          opts: { replace: true, state: { [SIDE_BAR_RELOAD_KEY]: uniqueId() } },
        })
      );
    },
  },
};
