import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { escapeSlash } from 'kit/kit-regexp';
import { isNumber } from 'lodash';

function getDevDynamicMapping(adomName, devName, vdomName) {
  let url = 'pm/config/adom/' + adomName + '/obj/dynamic/interface';
  let req = {
    id: 10,
    method: 'get',
    params: [
      {
        url: url,
        'subfetch filter': 1,
        'sub fetch': {
          dynamic_mapping: {
            'scope member': [
              {
                name: devName,
                vdom: vdomName,
              },
            ],
          },
        },
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (err) {
        reject(err.errors[0].status);
      }
    );
  });
}

function getAdomDynamicMapping(adomName) {
  let url = 'pm/config/adom/' + adomName + '/obj/dynamic/interface';
  let req = {
    id: 10,
    method: 'get',
    params: [
      {
        url: url,
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (err) {
        reject(err.errors[0].status);
      }
    );
  });
}

// dynamic mapping ADOM level API, devices can be array
// parameter = {adom:'root', device:[{name:'FGT200D', vdom:'root', interface:['port1']}], mapping:'dynintf'}
function genAdomUrl(parameter) {
  let _adom = parameter.adom ? parameter.adom : fiAdom.current().name;
  let _mapping = parameter.mapping ? parameter.mapping : null;
  let _devs = parameter.device;
  let _url = null;
  if (_devs && _adom && _mapping) {
    if (Array.isArray(_devs)) {
      _url = '/gui/adom/%s/dynamic-interface/%s/mapping-batch'.printf([
        _adom,
        _mapping,
      ]);
    } else {
      _url = '/gui/adom/%s/dynamic-interface/%s/mapping'.printf([
        _adom,
        _mapping,
      ]);
    }
  }
  return _url;
}

function makeParameter(devices) {
  let parameter = [];
  if (Array.isArray(devices)) {
    for (let i = 0; i < devices.length; i++) {
      let _obj = {
        deviceName: devices[i].name,
        vdomName: devices[i].vdom,
        interface: devices[i].interface,
      };
      if (isNumber(devices[i].intrazoneDeny)) {
        _obj.intrazoneDeny = devices[i].intrazoneDeny;
      }
      parameter.push(_obj);
    }
  } else {
    parameter = {
      deviceName: devices.name,
      vdomName: devices.vdom,
      interface: devices.interface,
    };
    if (isNumber(devices.intrazoneDeny)) {
      parameter.intrazoneDeny = devices.intrazoneDeny;
    }
  }
  return parameter;
}

function setAdomDynamicMapping(parameter) {
  let request = {
    method: 'set',
    params: makeParameter(parameter.device),
    url: genAdomUrl(parameter),
  };
  return _sendRequest(request);
}

function addAdomDynamicMapping(parameter) {
  let request = {
    method: 'add',
    params: makeParameter(parameter.device),
    url: genAdomUrl(parameter),
  };
  return _sendRequest(request);
}

function delAdomDynamicMapping(parameter) {
  let request = {
    method: 'delete',
    params: makeParameter(parameter.device),
    url: genAdomUrl(parameter),
  };
  return _sendRequest(request);
}

// dynamic mapping device level API, only for a given device
// parameter = {adom:'root', device:'FGT200D', vdom:'root', interface:'port1', mapping:'dynintf'}
function genDevUrl(parameter) {
  let _adom = parameter.adom ? parameter.adom : fiAdom.current().name;
  let _devName =
    parameter.device && parameter.device.name
      ? parameter.device.name
      : parameter.device;
  let _intf = parameter.interface;
  let _vdom = parameter.vdom ? parameter.vdom : 'global';

  if (_adom && _devName && _intf && _vdom) {
    return '/gui/adom/%s/device/%s/vdom/%s/interface/%s/mapping'.printf([
      _adom,
      _devName,
      _vdom,
      escapeSlash(_intf),
    ]);
  } else {
    return null;
  }
}
function getDevInterfaceMapping(parameter) {
  let request = {
    method: 'get',
    url: genDevUrl(parameter),
  };
  return _sendRequest(request);
}

function setDevInterfaceMapping(parameter) {
  let request = {
    method: 'set',
    params: {
      dynamicInterface: parameter.mapping,
    },
    url: genDevUrl(parameter),
  };
  return _sendRequest(request);
}

// Using same api as in pno per Yongyu's request for mantis 986818
function genDynMappingUrl(parameter) {
  var _adom = parameter.adom ? parameter.adom : fiAdom.current().name;
  const mapping = parameter.mapping;

  if (_adom && mapping) {
    const url =
      'pm/config/adom/%s/obj/dynamic/interface/%s/dynamic_mapping'.printf([
        _adom,
        escapeSlash(mapping),
      ]);
    return url;
  } else {
    return null;
  }
}

function setDevInterfaceDynamicMapping(parameter) {
  var _devName =
    parameter.device && parameter.device.name
      ? parameter.device.name
      : parameter.device;
  var _vdom = parameter.vdom ? parameter.vdom : 'root';
  var request = {
    method: 'set',
    params: [
      {
        url: genDynMappingUrl(parameter),
        data: {
          _scope: [
            {
              name: _devName,
              vdom: _vdom,
            },
          ],
          'local-intf': [parameter.interface],
        },
      },
    ],
  };
  return fiFmgHttp.query(request);
}

function delDevInterfaceMapping(parameter) {
  let request = {
    method: 'delete',
    params: {
      dynamicInterface: parameter.mapping,
    },
    url: genDevUrl(parameter),
  };
  return _sendRequest(request);
}

// dynamic mapping device level API, only for a given device vdom
// parameter = {adom:'root', device:'FGT200D', vdom:'root'}
function genDevVdomUrl(parameter) {
  const _adom = parameter.adom ? parameter.adom : fiAdom.current().name;
  const _devName = parameter.device;
  const _vdom = parameter.vdom ? parameter.vdom : 'root';
  if (!_adom || !_devName || !_vdom) return null;

  return '/gui/adom/%s/device/%s/vdom/%s/interface/mapping'.printf([
    _adom,
    _devName,
    _vdom,
  ]);
}

// Get all mappings of specific device
function getDevVdomMapping(parameter) {
  const url = genDevVdomUrl(parameter);
  const request = {
    method: 'get',
    url,
  };
  return _sendRequest(request);
}

// Delete selected mappings or all mappings of specific device
// parameter.member is undefined means delete all mapping of the device
function delDevVdomMapping(parameter) {
  const _member = parameter.member ? parameter.member : null;

  // parameter.member can not be empty array
  if (Array.isArray(parameter.member) && parameter.member.length === 0) {
    return Promise.resolve();
  }
  const url = genDevVdomUrl(parameter);
  const request = {
    method: 'delete',
    params: _member.map(function (m) {
      return { name: m };
    }),
    url,
  };
  return _sendRequest(request);
}

function _sendRequest(request) {
  return new Promise((resolve, reject) => {
    if (request.url) {
      fiFmgHttp.post(request).then(
        function (resp) {
          if (resp && resp.result) {
            resolve(resp.result[0]);
          } else {
            resolve(resp[0]);
          }
        },
        function (err) {
          reject(err.result[0].status);
        }
      );
    } else {
      reject(gettext('Invalid parameter'));
    }
  });
}

export const fiDeviceDynamicMappingUtil = {
  getDevDynamicMapping: getDevDynamicMapping, // all dynamic mapping of selected device
  addAdomDynamicMapping: addAdomDynamicMapping,
  setAdomDynamicMapping: setAdomDynamicMapping,
  delAdomDynamicMapping: delAdomDynamicMapping,
  getDevInterfaceMapping: getDevInterfaceMapping, // all dynamic mapping of selected interface
  setDevInterfaceMapping: setDevInterfaceMapping,
  delDevInterfaceMapping: delDevInterfaceMapping,
  setDevInterfaceDynamicMapping,
  getDevVdomMapping: getDevVdomMapping,
  delDevVdomMapping: delDevVdomMapping,
  getAdomDynamicMapping: getAdomDynamicMapping,
};
