import { all, fork } from 'redux-saga/effects';

import { watchDevGroupAction, watchDevGroupMembAction } from './saga';

export {
  deviceGroupsReducer,
  deviceGroupsMembReducer,
  defaultGroupsReducer,
} from './reducer';
export * as deviceGroupsSelector from './selector';

const sagaWatchers = [watchDevGroupAction, watchDevGroupMembAction];
export function* deviceGroupRootSaga() {
  yield all(sagaWatchers.map(fork));
}
