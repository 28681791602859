export {
  fiDvmListViewType,
  fiDvmDeviceMgtType,
  fiDvmRevisionContent,
  fiFirmwareUpgradeAction,
  fiTaskStatus,
  fiDvmScriptsType,
  fiDvmScriptsRunOptions,
  fiDvmRevisionStatus,
  NO_PROTOCOL_DETECT_MODE,
};

const NO_PROTOCOL_DETECT_MODE = new Set([
  MACROS.PM2CAT.PM2_SDWAN_HEALTH_CHECK_DETECT_MODE_PASSIVE,
  MACROS.PM2CAT.PM2_SDWAN_HEALTH_CHECK_DETECT_MODE_REMOTE,
  MACROS.PM2CAT.PM2_SDWAN_HEALTH_CHECK_DETECT_MODE_AGENT_BASED,
]);

const fiDvmListViewType = {
  UNREGISTERED: 'fi_dvm_unregistered_devices_view',
  MANAGED: 'fi_dvm_managed_devices_view',
  LOGGING: 'fi_dvm_logging_devices_view',
  REMOTE_FAZ: 'fi_dvm_remote_faz_view',
  MANAGED_TABLE_VIEW: 'fi_dvm_managed_table_view',
  MANAGED_MAP_VIEW: 'fi_dvm_managed_map_view',
  MANAGED_PRIVATE_DATA_ENC_DEVS: 'fi_dvm_managed_private_data_enc_devs',
  MANAGED_FOLDER_VIEW: 'fi_dvm_managed_assets_view',
  MANAGED_RING_VIEW: 'fi_dvm_managed_ring_view',
};

const fiDvmDeviceMgtType = {
  UNREGISTERED: '0',
  MANAGED: '1',
  LOGGING: '2',
  MANAGED_LOGGING: '3',
};

const fiDvmRevisionContent = {
  INSTALL_LOG: 1,
  CONFIGURATION: 2,
  FACTORY_SETTING: 3,
};

const fiFirmwareUpgradeAction = {
  UNKNOWN: 0, // upgrade firmware but not on upgrade path, not recommend
  DOWNGRADE: 1, // downgrade firware not recommend
  UPGRADE_RECOMMEND: 2, // upgrade firmware the recommend version on current upgrade path
  UPGRADE_ON_PATH: 3, // upgrade firmware and on the upgrade path but not the next upgrade version
};

const fiTaskStatus = {
  TASK_STATE_PENDING: MACROS.TASK.TASK_STATE_PEND,
  TASK_STATE_RUN: MACROS.TASK.TASK_STATE_RUN,
  TASK_STATE_CANCELLING: MACROS.TASK.TASK_STATE_CANCELLING,
  TASK_STATE_CANCELLED: MACROS.TASK.TASK_STATE_CANCELLED,
  TASK_STATE_DONE: MACROS.TASK.TASK_STATE_DONE,
  TASK_STATE_ERROR: MACROS.TASK.TASK_STATE_ERROR,
  TASK_STATE_ABORTING: MACROS.TASK.TASK_STATE_ABORTING,
  TASK_STATE_ABORTED: MACROS.TASK.TASK_STATE_ABORTED,
  TASK_STATE_WARN: MACROS.TASK.TASK_STATE_WARN,
  TASK_STATE_CONT: MACROS.TASK.TASK_STATE_CONT,
  TASK_STATE_QUEUED: MACROS.TASK.TASK_STATE_QUEUED,
};

const runTarget = {
  DEVICE_DB: MACROS.DVM.DVM_SCRIPT_TARGET_DEVICE_DB,
  REMOTE_CLI: MACROS.DVM.DVM_SCRIPT_TARGET_DEVICE_REMOTE,
  POLICY_PKG: MACROS.DVM.DVM_SCRIPT_TARGET_GLOBAL,
};

const fiDvmScriptsRunOptions = [
  { id: runTarget.DEVICE_DB, text: gettext('Device Database') },
  {
    id: runTarget.REMOTE_CLI,
    text: gettext('Remote FortiGate Directly (via CLI)'),
  },
  {
    id: runTarget.POLICY_PKG,
    text: gettext('Policy Package or ADOM Database'),
  },
];

const fiDvmScriptsType = {
  ScriptType: {
    CLI: 1,
    TCL: 2,
    GROUP: 3,
  },
  ScriptScheduleType: {
    NONE: 0,
    AUTO: 1,
    ONE_TIME: 2,
    DAILY: 3,
    WEEKLY: 4,
    MONTHLY: 5,
  },
  ScriptRunningTarget: runTarget,
};

const fiDvmRevisionStatus = {
  NONE: MACROS.DVM.DM_ST_NONE,
  UNKNOWN: MACROS.DVM.DM_ST_UNKNOWN,
  CHECKED_IN: MACROS.DVM.DM_ST_CHECKED_IN,
  INPROGRESS: MACROS.DVM.DM_ST_INPROGRESS,
  INSTALLED: MACROS.DVM.DM_ST_INSTALLED,
  ABORTED: MACROS.DVM.DM_ST_ABORTED,
  SCHED: MACROS.DVM.DM_ST_SCHED,
  RETRY: MACROS.DVM.DM_ST_RETRY,
  CANCELED: MACROS.DVM.DM_ST_CANCELED,
  PENDING: MACROS.DVM.DM_ST_PENDING,
  RETRIEVED: MACROS.DVM.DM_ST_RETRIEVED,
  CHANGED_CONF: MACROS.DVM.DM_ST_CHANGED_CONF,
  SYNC_FAIL: MACROS.DVM.DM_ST_SYNC_FAIL,
  TIMEOUT: MACROS.DVM.DM_ST_TIMEOUT,
  REV_REVERT: MACROS.DVM.DM_ST_REV_REVERT,
  AUTO_UPDATED: MACROS.DVM.DM_ST_AUTO_UPDATED,
  MAX: MACROS.DVM.DM_ST_MAX,
};
