import { isUndefined } from 'fiutil';
import { OSTYPES } from '../common/constants';
import { DeviceFormatter } from 'ra_device_util';

export const getIcon = (rowData) => {
  const type = rowData.osType;
  let res = { name: '', className: '' };
  try {
    if (!isUndefined(rowData.os_type)) {
      const iconRes = DeviceFormatter.getSimpleStatusIcon(rowData);
      res = iconRes.iconProps;
    } else if (type === OSTYPES.FEXT) {
      res.name = 'fortiextender';
    } else if (
      [OSTYPES.FAP, OSTYPES.FAPU, OSTYPES.FAPC, OSTYPES.FAPS].includes(type)
    ) {
      res.name = 'fortiap';
      res.className = rowData.iconColor;
    } else if (type === OSTYPES.FSW) {
      res.name = 'fortiswitch';
    }
  } catch (e) {
    //
  }
  return res;
};
