import { createSelector } from '@reduxjs/toolkit';

export const getCopilot = (state) => {
  return state?.adom?.copilot;
};

export const getCopilotIsOpen = createSelector(
  getCopilot,
  (copilotState) => copilotState.isOpen || null
);

export const getCopilotLogin = createSelector(
  getCopilot,
  (copilotState) => copilotState?.login || null
);

export const getIsAiUserChanged = createSelector(
  getCopilot,
  (copilotState) => copilotState?.aiUserChanged || 0
);
