import { useField, useFormikContextSelector } from 'formik';
import React from 'react';
import { NwInput } from '@fafm/neowise-core';
import { useValidateFn } from './util/hook';
import { callAll, validateAny } from './util/util';
import { validIp6 } from 'fiutil';
import { isArray, isString } from 'lodash';

//value should be array [ip1, ip2]
export const FmkIp6Range = ({
  name,
  'automation-id': propAutoId,
  automationId,
  validate,
  ...rest
}) => {
  validate = useValidateFn(rest, validate);
  const submitCount = useFormikContextSelector((val) => val.submitCount);
  const [
    { onBlur, onChange, ...restFiledProps },
    { error, touched },
    { setTouched },
  ] = useField({
    name,
    validate: validateAny(validate, validateIPv6Range),
    type: rest.type,
  });

  const parser = (e) => {
    if (isString(e.target.value))
      return e.target.value?.split('-')?.map((v) => v.trim()) || [];
    else return e.target.value;
  };

  const parsedOnChange = (e) => {
    const parsed = parser(e);
    onChange(parsed);
  };
  const parsedOnBlur = (e) => {
    const parsed = parser(e);
    onBlur(parsed);
    setTouched(true);
  };

  const valueParser = (val) => {
    if (isString(val)) return val;
    if (isArray(val)) return val.join('-');
    return val;
  };
  return (
    <NwInput
      invalid={error && (touched || !!submitCount)}
      automation-id={propAutoId || automationId}
      {...restFiledProps}
      {...rest}
      value={valueParser(restFiledProps.value)}
      //mantis 0882491
      //breaks onNwInput, instead directly use nw component
      onlyReflectOnChange
      onChange={callAll(parsedOnChange, rest.onNwChange)}
      onBlur={callAll(parsedOnBlur, rest.onNwBlur)}
      placeholder='::-::'
    />
  );
};

const validateIPv6Range = (value) => {
  if (!value || !value.length) return;
  if (value.length !== 2) return gettext('Invalid IP range');

  for (let ip of value) {
    if (!validIp6(ip)) return gettext('Invalid IP range');
  }
};
