import { get, has } from 'lodash';

export const ERR_MSG_PATH = [
  'data.result[0].status.message',
  'errors[0].status.message',
];

// Destruct data and error message from response
export const dRespData =
  (queryFn) =>
  async (...queryFnParams) => {
    const resp = await dErrMsg(queryFn)(...queryFnParams);
    return get(resp, '[0].data');
  };

// Destruct error message from response
export const dErrMsg =
  (queryFn) =>
  async (...queryFnParams) => {
    try {
      return await queryFn(...queryFnParams);
    } catch (respErr) {
      throw get(
        respErr,
        ERR_MSG_PATH.find((path) => has(respErr, path)),
        respErr
      );
    }
  };
