import React from 'react';
import { redirect } from 'react-router-dom';
import { AppRoot } from './AppRoot';
import ErrorPage from './ErrorPage';
import { MainLayout } from './ra_main/main_layout/MainLayout';
import { getLocalStorage } from 'fiutil/storage';
import { IndexPage } from './ra_main/IndexPage';
import routeDashboard from 'react_apps/ra_dashboard/routes';
import routeFortiAi from 'react_apps/ra_ai_shared/frontpage/routes';
import routeSys from 'react_apps/ra_sys/routes';
import routeNoc from 'react_apps/ra_noc/routes';
import routeLogview from 'react_apps/ra_logview/routes';
import routeAlert from 'react_apps/ra_alert/routes';
import routeFortisoc from 'react_apps/ra_fortisoc/routes';
import routeReport from 'react_apps/ra_report/routes';
import routePno from 'react_apps/ra_pno_policy_tables/routes';
import routeRedirect from 'react_apps/redirect';
import routeFabric from 'react_apps/ra_fabric/routes';
import routeDvm from 'react_apps/ra_dvm/routes';
import routeFgd from 'react_apps/ra_fortiguard/routes';
import routeFsw from 'react_apps/ra_fortiswitch/routes';
import routeFap from 'react_apps/ra_fortiap/routes';
import routeFext from 'react_apps/ra_fext/routes';
import routeVpn from 'react_apps/ra_vpn/routes';
import routeRstadm from 'react_apps/ra_restrict_admin/routes';
import routeExtensions from 'react_apps/ra_docker/routes';
import FabricApprovalRoutes from './ra-auth/FabricApprovalRoutes';
import loginRoutes from './ra-auth/LoginRoutes';
import sdwanRoutes from './ra_sdwan/routes';
import terminalRoutes from './ra-auth/TerminalRoutes';
import { checkAuthLoader } from 'react_apps/ra-auth/loader';
import { registerDebuggerExtension } from 'chrome_extension_debugger/debugger';

export default [
  {
    element: <AppRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '*',
        loader: () => {
          return redirect('/');
        },
      },
      routeRedirect,
      {
        path: '/',
        loader: async () => {
          if (await checkAuthLoader()) {
            // this won't slow down the loader unless user does "Start Capture and Reload Page"
            await registerDebuggerExtension();
            // SSO or fmg cloud login goes directly to the home, need to do post
            // login work in these cases
            if (getLocalStorage('justLogin', true)) {
              return redirect('/postlogin');
            }
            return null;
          }
          return redirect('/login');
        },
        element: <MainLayout />,
        children: [
          // index page in ReactRouter cannot have children
          {
            index: true,
            element: <IndexPage />,
          },
          ...routeDashboard,
          routeDvm,
          routeSys,
          routeNoc,
          routeLogview,
          routeAlert,
          routeFortisoc,
          routeReport,
          routeFabric,
          routePno,
          routeFgd,
          routeFsw,
          routeFap,
          routeFext,
          routeVpn,
          routeRstadm,
          routeExtensions,
          routeFortiAi,
          sdwanRoutes,
        ],
      },
      ...loginRoutes,
      ...FabricApprovalRoutes,
      ...terminalRoutes,
    ],
  },
];
