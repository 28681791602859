import { go } from 'fistore/routing/slice';
import { dispatch } from 'fistore';
const goUrl = (url) => dispatch(go({ to: url }));

export default {
  [MACROS.NOTICE.NOTICE_TYPE_SOC_FABRIC_MEMBER_NOT_IN_SYNC]: {
    message: ({ params: { memberSns } }) => {
      if (memberSns.length > 1) {
        return gettext('%s FortiAnalyzer Fabric Members are offline.').printf([
          memberSns.length,
        ]);
      } else {
        return gettext('FortiAnalyzer Fabric Member %s is offline.').printf([
          memberSns[0],
        ]);
      }
    },
    exec: () => {
      goUrl('/sys/fabric/settings');
    },
  },
};
