import EventSubject from './event_subject';
const heartbeatMsg = '--heartbeat--';

export default class Heartbeat {
  missedHeartbeats: number;
  heartbeatInterval: any;
  _eventSubject: EventSubject;
  constructor() {
    this.missedHeartbeats = 0;
    this.heartbeatInterval = null;
    this._eventSubject = new EventSubject();
  }
  get eventSubject() {
    return this._eventSubject;
  }
  start(task: any) {
    if (this.heartbeatInterval != null) return;

    this.heartbeatInterval = setInterval(() => {
      try {
        this.missedHeartbeats++;
        if (this.missedHeartbeats >= 3) {
          throw new Error('Too many missed heartbeats.');
        }
        task();
      } catch (e) {
        this.clear();

        this._eventSubject.publish('maxMissed');
      }
    }, 1000 * 30);
  }
  resetCount() {
    this.missedHeartbeats = 0;
  }
  clear() {
    clearInterval(this.heartbeatInterval);
    this.heartbeatInterval = null;
  }
  match(msg: string): boolean {
    return msg === heartbeatMsg;
  }
  msg() {
    return heartbeatMsg;
  }
}
