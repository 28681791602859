import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { flatui_proxy_get } from 'fistore/utils/jsonapi';
import { apiResultFirst } from 'fistore/utils/api';
import { fetchSysConfig } from 'fistore/session/sysConfig/slice';

const initialState = {
  data: null,
  loading: false,
  status: null,
};

const _slice = createSlice({
  name: 'session/profile',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminProfile.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(fetchAdminProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(fetchAdminProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = payload;
      })
      .addCase(fetchSysConfig.fulfilled, (state, { payload }) => {
        // assign system config user profile after load
        state.data = payload?.user_profile;
      });
  },
});

export default _slice.reducer;

// thunks
export const fetchAdminProfile = createAsyncThunk(
  'session/adom/fetchAdminProfile',
  async (adomOid) => {
    if (!adomOid) {
      throw new Error('adomOid is missing!');
    }
    return await apiResultFirst(
      flatui_proxy_get,
      `/gui/adoms/${adomOid}/profile`
    );
  }
);
