import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listenerMiddleware } from 'fistore/middlewares';
import {
  castArray,
  cloneDeep,
  first,
  get,
  head,
  isNil,
  keyBy,
  setWith,
  transform,
  unset,
} from 'lodash';

import { SocketActions } from 'fi-websocket';
import {
  checkVersion,
  getSessionAdomData,
  getSessionAdomName,
  isCentralManagement,
} from 'fistore/session/adom/selectors';
import { switchSessionAdom } from 'fistore/session/adom/slice';
import { getSyntax } from 'fistore/adomSyntax/selectors';
import { fiFmgHttp, getResponseData } from 'fi-web/fi-http';
import { getDataNeedReload } from 'fistore/utils';

import { getDevicesAssets, getDevicesAssetsData } from './selectors';

const { NOTIFY_REMOVED_ACTION, NOTIFY_CHANGED_ACTION, NOTIFY_ADDED_ACTION } =
  SocketActions;

const getAssetsConfig = (currentState) => {
  const adomSyntax = getSyntax(currentState);
  const config = {
    [MACROS.USER.DVM.ASSETS_FORTIAP_ABBR]: {
      category: 'wireless-controller wtp',
      thunk: getSingleFap,
    },
    [MACROS.USER.DVM.ASSETS_FORTISWITCH_ABBR]: {
      category: 'fsp managed-switch',
      thunk: getSingleFsw,
    },
    [MACROS.USER.DVM.ASSETS_FORTIEXTENDER_ABBR]: {
      category: checkVersion('7.2', '>=')(currentState)
        ? 'extension-controller extender'
        : 'extender-controller extender',
      thunk: getSingleFext,
    },
  };

  return transform(
    config,
    (result, val, key) => {
      const { category } = val;

      // add extra configs
      const mkey = adomSyntax[category]?.mkey;
      const isCentral = isCentralManagement(key)(currentState);
      result[key] = {
        ...val,
        mkey,
        isCentral,
      };
    },
    {}
  );
};

/** ----------------------------------------------------------------------------
 * Reducer
 * -------------------------------------------------------------------------- */
const initialState = {
  data: {},
  loading: false,
  loaded: false,
};

const _slice = createSlice({
  name: 'dvm/assets',
  initialState,
  reducers: {
    startFetchingAssets(state) {
      state.loaded = false;
      state.loading = true;
    },
    resetAssets(state) {
      state.data = {};
      state.loaded = false;
      state.loading = false;
    },
    updateOneAssetType(state, { payload }) {
      const { data, assetType } = payload || {};
      state.data.byId = data.byId;
      state.data[assetType] = data[assetType];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssets.fulfilled, (state, { payload }) => {
        const { data } = payload || {};
        state.loading = false;
        state.loaded = true;
        state.data = data;
      })
      .addCase(fetchAssets.rejected, (state) => {
        state.loading = false;
        state.loaded = false;
        state.data = {};
      });
  },
});

/** ----------------------------------------------------------------------------
 * Exports
 * -------------------------------------------------------------------------- */
export const { startFetchingAssets, updateOneAssetType, resetAssets } =
  _slice.actions;

export default _slice.reducer;

/** ----------------------------------------------------------------------------
 * Thunks
 * -------------------------------------------------------------------------- */
export const fetchAssets = createAsyncThunk(
  'dvm/assets/fetchAssets',
  async (payload, { getState, dispatch }) => {
    const { forceReload } = payload || {};
    const currentAssets = getDevicesAssets(getState());
    if (!getDataNeedReload(currentAssets) && !forceReload) {
      return {
        data: currentAssets.data || {},
      };
    }

    dispatch(startFetchingAssets());

    // Hard to move assets request to the store because the loader and parses are all in different modules
    const { fiDvmAssetsService } = await import(
      'react_apps/ra_fap_fsw_fext/dvm_assets_service'
    );
    const data = await fiDvmAssetsService.loadAllAssets();

    return { data };
  }
);

export const getSingleFap = createAsyncThunk(
  'dvm/assets/loadSingleFap',
  async (payload, { getState }) => {
    const { objkey, scopeMember, moreParams } = payload || {};

    const state = getState();
    const adom = getSessionAdomData(state);
    const assetsConfig = getAssetsConfig(state);

    const { category, isCentral } =
      assetsConfig[MACROS.USER.DVM.ASSETS_FORTIAP_ABBR];

    const data = await getAssetObject({
      adom,
      category,
      objkey,
      scopeMember,
      isCentral,
      opts: moreParams,
    });

    const { fiFAPParserService } = await import(
      'react_apps/ra_fortiap_util/services/ap_parser_service'
    );
    const parseData = fiFAPParserService.parseData(data)?.list || [];
    return first(parseData);
  }
);

export const getSingleFsw = createAsyncThunk(
  'dvm/assets/loadSingleFsw',
  async (payload, { getState }) => {
    const { objkey, scopeMember, moreParams } = payload || {};

    const state = getState();
    const adom = getSessionAdomData(state);
    const assetsConfig = getAssetsConfig(state);

    const { category, isCentral } =
      assetsConfig[MACROS.USER.DVM.ASSETS_FORTISWITCH_ABBR];

    const data = await getAssetObject({
      adom,
      category,
      objkey,
      scopeMember,
      isCentral,
      opts: moreParams,
    });

    const { parseData } = await import(
      'react_apps/ra_fap_fsw_fext/switch_list_service'
    );
    const parsedData = parseData(data)?.data || {};
    return first(parsedData);
  }
);

export const getSingleFext = createAsyncThunk(
  'dvm/assets/loadSingleFext',
  async (payload, { getState }) => {
    const { objkey, scopeMember, moreParams } = payload || {};

    const state = getState();
    const adom = getSessionAdomData(state);
    const assetsConfig = getAssetsConfig(state);
    const { category } =
      assetsConfig[MACROS.USER.DVM.ASSETS_FORTIEXTENDER_ABBR];

    const data = await getAssetObject({
      adom,
      category,
      isCentral: true,
      objkey,
      scopeMember,
      opts: moreParams,
    });

    return data;
  }
);

const updatePerDevAssets = createAsyncThunk(
  'dvm/assets/updatePerDevAssets',
  async (payload, { getState, dispatch }) => {
    const { assetType, records, action, scopeMember } = payload || {};

    const currentAssets = getDevicesAssetsData(getState());
    const assetsConfig = getAssetsConfig(getState());

    const { mkey } = assetsConfig[assetType];

    // need to make a deep copy since currentAssets is read only
    const newAssets = cloneDeep(currentAssets);

    // only support updating one record at a time at the moment (no batching), length of records is always 1
    for (const record of castArray(records)) {
      const dev = scopeMember || getScopeMember(record);

      if (isNil(record) || isNil(dev.oid)) continue;

      let recordKey =
        record[mkey] || get(record, ['_oData', mkey]) || record.assetId;

      if (!recordKey) {
        if (assetType === MACROS.USER.DVM.ASSETS_FORTIAP_ABBR) {
          recordKey = record.serials;
        } else if (assetType === MACROS.USER.DVM.ASSETS_FORTISWITCH_ABBR) {
          recordKey = record.sn;
        } else if (assetType === MACROS.USER.DVM.ASSETS_FORTIEXTENDER_ABBR) {
          recordKey = record.id || record.sn;
        }
      }

      if (!recordKey) {
        throw new Error(`Can not find asset record key for record ${record}`);
      }

      const perAssetMapKey = [assetType, getFullAssetKey(dev, recordKey)];
      const perDevVdomAssetMapKey = [
        'byId',
        `${dev.oid}`,
        `${dev.vdom_oid || MACROS.DVM.CDB_DEFAULT_ROOT_OID}`,
        assetType,
        recordKey,
      ];

      if ([NOTIFY_ADDED_ACTION, NOTIFY_CHANGED_ACTION].includes(action)) {
        setWith(newAssets, perDevVdomAssetMapKey, record, Object);
        setWith(newAssets, perAssetMapKey, record, Object);
      } else if (action === NOTIFY_REMOVED_ACTION) {
        unset(newAssets, perDevVdomAssetMapKey);
        unset(newAssets, perAssetMapKey);
      }
    }

    dispatch(updateOneAssetType({ assetType, data: newAssets }));
  }
);

// TODO: No need to save these data to fistore for now, unless we need to share them across different modules
export const fetchAssetStatusDb = createAsyncThunk(
  'dvm/assets/fetchAssetStatusDb',
  async (payload, { getState }) => {
    const state = getState();

    const { adomName: _adomName, type, scopeMember } = payload || {};
    const adomName = _adomName || getSessionAdomName(state);

    let url = `/pm/config/adom/${adomName}/_controller/status`;
    if (type) {
      url += `/${type}`;
    }
    const resp = await fiFmgHttp.forward({
      method: 'get',
      params: [
        {
          url,
          'scope member': scopeMember
            ? castArray(scopeMember)
            : [
                {
                  name: MACROS.USER.DVM.DEFAULT_ALL_FGT_SCOPE_NAME,
                },
              ],
        },
      ],
    });
    const data = getResponseData(resp);

    if (data && data.length > 0) {
      return data.map((record) => {
        let statusData = record.data;
        if (statusData) {
          statusData = JSON.parse(statusData);
        }
        return {
          ...record,
          data: statusData,
        };
      });
    }

    return data;
  }
);

export const fetchFapStatus = createAsyncThunk(
  'dvm/assets/fetchFapStatus',
  async (payload, { dispatch }) => {
    const resp = await dispatch(
      fetchAssetStatusDb({ ...payload, type: 'fap' })
    ).unwrap();
    return resp;
  }
);

export const fetchFswStatus = createAsyncThunk(
  'dvm/assets/fetchFswStatus',
  async (payload, { dispatch }) => {
    const resp = await dispatch(
      fetchAssetStatusDb({ ...payload, type: 'fsw' })
    ).unwrap();
    return resp;
  }
);

export const fetchFextStatus = createAsyncThunk(
  'dvm/assets/fetchFextStatus',
  async (payload, { dispatch }) => {
    const resp = await dispatch(
      fetchAssetStatusDb({ ...payload, type: 'fex' })
    ).unwrap();
    return resp;
  }
);

export const fetchLteModemStatus = createAsyncThunk(
  'dvm/assets/fetchLteModemStatus',
  async (payload, { dispatch }) => {
    const resp = await dispatch(
      fetchAssetStatusDb({ ...payload, type: 'lte' })
    ).unwrap();
    return resp;
  }
);

export const loadAndParseAssetStatusData = createAsyncThunk(
  'dvm/assets/loadAndParseAssetStatusData',
  async (payload, { dispatch }) => {
    const {
      // required
      type,
      assetList,
      getSN,

      // optional
      getScopeMember = (asset) => head(asset['scope member']),
    } = payload || {};

    const assetListMap = keyBy(assetList, (asset) => {
      return getFullAssetKey(getScopeMember(asset), getSN(asset));
    });

    const resp = await dispatch(fetchAssetStatusDb({ type })).unwrap();

    const statusDataMap = keyBy(resp, (record) => {
      return getFullAssetKey(
        { name: record.dev, vdom: record.vdom },
        record.sn
      );
    });

    const updatedAssetListMap = transform(
      assetListMap,
      (result, val, key) => {
        const statusData = statusDataMap[key];
        if (!statusData || !statusData.data) {
          result[key] = val;
          return;
        }

        result[key] = {
          ...val,
          statusData: statusData.data,
        };
      },
      {}
    );

    const updatedAssetList = Object.values(updatedAssetListMap);

    return {
      assetListMap,
      statusDataMap,
      updatedAssetList,
    };
  }
);

/** ----------------------------------------------------------------------------
 * Listeners
 * -------------------------------------------------------------------------- */
listenerMiddleware.startListening({
  predicate: ({ type, payload }) => {
    return (
      payload &&
      payload.collection === 'device_assets' &&
      [
        NOTIFY_ADDED_ACTION,
        NOTIFY_CHANGED_ACTION,
        NOTIFY_REMOVED_ACTION,
      ].includes(type)
    );
  },
  effect: async (action, { dispatch, getState }) => {
    const { type, payload } = action;
    const { meta } = payload || {};
    const { assetId, assetType, data, scopeMember } = meta || {};

    const assetsConfig = getAssetsConfig(getState());
    const config = assetsConfig[assetType];
    if (!config) {
      throw new Error(`Invalid asset type: ${assetType}`);
    }

    const { mkey, thunk } = config;

    // make sure to get objkey
    let objkey = assetId;
    if (!objkey && data) {
      if (assetType === MACROS.USER.DVM.ASSETS_FORTIAP_ABBR) {
        objkey = data[mkey];
      } else if (assetType === MACROS.USER.DVM.ASSETS_FORTISWITCH_ABBR) {
        objkey = data[mkey] || data.sn;
      } else if (assetType === MACROS.USER.DVM.ASSETS_FORTIEXTENDER_ABBR) {
        objkey = data[mkey] || data.id || data.sn;
      }
    }
    if (!objkey) {
      throw new Error(`No objkey found. action = ${action}`);
    }

    let recordData = data;
    if (type !== NOTIFY_REMOVED_ACTION) {
      // for add/update, need to get new object data and update store
      const data = await dispatch(thunk({ objkey, scopeMember })).unwrap();
      if (data) {
        recordData = data;
      }
    } else {
      // remove record from store based on assetId and scope member
      recordData = { assetId };
    }

    // update store
    await dispatch(
      updatePerDevAssets({
        assetType,
        records: recordData,
        action: type,
        scopeMember,
      })
    );
  },
});

listenerMiddleware.startListening({
  actionCreator: switchSessionAdom.fulfilled,
  effect: async (_, { dispatch }) => {
    dispatch(resetAssets());
  },
});

/** ----------------------------------------------------------------------------
 * Utils
 * -------------------------------------------------------------------------- */
export const deviceAssetsUtil = {
  getAssetObject,
  genEntryKey: getFullAssetKey,
  getScopeMember,
};

async function getAssetObject({
  adom,
  category,
  objkey,
  isCentral,
  scopeMember,
  opts: _opts,
}) {
  const opts = {
    'scope member': scopeMember,
    ..._opts,
  };

  if (!isCentral) {
    const dev = Array.isArray(scopeMember) ? first(scopeMember) : scopeMember;
    opts.devName = dev.name;
    opts.vdomName = dev.vdom;
  }

  const { getObject } = await import('react_apps/ra_pno_obj_util/others');
  const data = await getObject(adom, category, objkey, opts);

  // for data parsing
  if (scopeMember) {
    Object.assign(data, {
      'scope member': castArray(scopeMember),
    });
  }

  return data;
}

function getScopeMember(rec) {
  const dev = get(rec, 'scope member') || get(rec, ['_oData', 'scope member']);
  return Array.isArray(dev) ? first(dev) : dev;
}

// different devices can use the same SN to create assets, so need to make key more unique
export function getFullAssetKey(dev, key) {
  return [dev.name, dev.vdom, key].join('::');
}
