import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  deviceGroupsAction,
  deviceGroupsMembAction,
  defaultGroupsAction,
  fetchDeviceGroupsAction,
  fetchDeviceGroupsMemAction,
  fetchDefaultGroupsAction,
} from './action';
import {
  NOTIFY_ADDED_ACTION,
  NOTIFY_CHANGED_ACTION,
  NOTIFY_REMOVED_ACTION,
} from 'fi-web/fi-websocket/socket/action_const';
import {
  fetch_device_groups,
  fetch_device_groups_memb,
  fetch_device_group,
  fetch_device_group_memb,
  fetch_default_groups,
} from './api';
import { callPromiseAction, getDataFromResponse } from '../utils';
import {
  getSessionAdomName,
  getSessionAdomOid,
} from 'fistore/session/adom/selectors';

import { switchSessionAdom } from 'fistore/session/adom/slice';

export function* watchDevGroupAction() {
  yield takeLatest(fetchDeviceGroupsAction.type, fetchDeviceGroupList);
  yield takeLatest(fetchDeviceGroupsMemAction.type, fetchDeviceGroupMemList);
  yield takeLatest(fetchDefaultGroupsAction.type, fetchDefaultGroupList);

  yield takeEvery(NOTIFY_ADDED_ACTION, makeGrpAction);
  yield takeEvery(NOTIFY_CHANGED_ACTION, makeGrpAction);
  yield takeEvery(NOTIFY_REMOVED_ACTION, makeGrpAction);

  // reload device groups info after switching adom
  yield takeEvery(
    switchSessionAdom.fulfilled.type,
    function* deviceGroupsOnSwitchedAdomDone() {
      yield all([
        put(fetchDeviceGroupsAction()),
        put(fetchDeviceGroupsMemAction()),
        put(fetchDefaultGroupsAction()),
      ]);
    }
  );
}

export function* watchDevGroupMembAction() {
  yield takeEvery(NOTIFY_CHANGED_ACTION, makeGrpMembAction);
}

function* fetchDeviceGroupList(action) {
  yield callPromiseAction(action, function* () {
    const adomName =
      action.payload?.adomName || (yield select(getSessionAdomName));
    yield put(deviceGroupsAction.fetch.request());
    const resp = yield call(fetch_device_groups, { adomName });
    const data = getDataFromResponse(resp);
    yield put(deviceGroupsAction.fetch.success(data));
  });
}

function* fetchDeviceGroupMemList(action) {
  yield callPromiseAction(action, function* () {
    const adomName =
      action.payload?.adomName || (yield select(getSessionAdomName));
    yield put(deviceGroupsMembAction.fetch.request());
    const resp = yield call(fetch_device_groups_memb, { adomName });
    const data = getDataFromResponse(resp);
    yield put(deviceGroupsMembAction.fetch.success(data));
  });
}

function* fetchDefaultGroupList(action) {
  yield callPromiseAction(action, function* () {
    const adomOid =
      action.payload?.adomOid || (yield select(getSessionAdomOid));
    yield put(defaultGroupsAction.fetch.request());
    const resp = yield call(fetch_default_groups, { adomOid });
    const data = getDataFromResponse(resp);
    yield put(defaultGroupsAction.fetch.success(data));
  });
}

/***************************** */
function* processGrp({ type, payload }) {
  const {
    id,
    meta: { adom },
  } = payload;

  const getMethod = () =>
    type === NOTIFY_REMOVED_ACTION
      ? 'delete'
      : type === NOTIFY_ADDED_ACTION
      ? 'add'
      : 'change';

  if (type === NOTIFY_REMOVED_ACTION) {
    yield put(deviceGroupsAction.record[getMethod()]({ oid: id }));
  } else {
    const resp = yield call(fetch_device_group, { adomOid: adom, grpOid: id });
    const grp = getDataFromResponse(resp);
    yield put(deviceGroupsAction.record[getMethod()](grp));
  }
}

const makeCollectionAction = (collection) => (processFn) =>
  function* (action) {
    if (action.payload.collection !== collection) return;

    yield processFn(action);
  };

const makeGrpAction = makeCollectionAction('device_group')(processGrp);

function* processGrpMemb({ type, payload }) {
  if (type !== NOTIFY_CHANGED_ACTION) return;

  const {
    id,
    meta: { adom },
  } = payload;
  const resp = yield call(fetch_device_group_memb, {
    adomOid: adom,
    grpOid: id,
  });
  const grp = getDataFromResponse(resp);
  yield put(deviceGroupsMembAction.record['change'](grp));
}
const makeGrpMembAction =
  makeCollectionAction('device_group_memb')(processGrpMemb);
