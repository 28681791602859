import React, {
  useState,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { isFunction } from 'lodash';
import { useWizard } from 'rc_wizard';
import {
  NwButton,
  NwCheckbox,
  NwIconButton,
  NwProgressBar,
} from '@fafm/neowise-core';
import { ProForm } from '@fafm/neowise-pro';
import { getTabAutoId } from '../tab_utils';
import { getContractInfo } from '../../modal/utils';

import { DropUploader } from 'rc_drop_uploader';
import { fiMessageBox } from 'fi-messagebox';
import { UploaderService } from 'fi-file';
const { Body, Footer, Row, Column, Section } = ProForm;

export const WelcomeTab = ({ tab, setUseEntitlementFile, setLicenseData }) => {
  const getAutoId = getTabAutoId('welcome');

  const { goToNext } = useWizard();
  const filePondRef = useRef();

  const [btnLoading, setBtnLoading] = useState(false);

  return (
    <>
      <Body>
        <div className='tab-title'>{tab.title}</div>
        <p>{tab.description}</p>
        <ol className='step-list'>
          {tab.stepTabs.map((stepTab) => (
            <li className='step-list-item' key={stepTab.id}>
              <span>{stepTab.text}</span>
              {stepTab.isComplete ? (
                <span className='ffg ffg-yes pull-right checkmark-icon-color'></span>
              ) : (
                <></>
              )}
              {stepTab.supportEntitlementUpload && !stepTab.isComplete ? (
                <EntitlementUploadComponent
                  autoId={getAutoId('entitlement-upload')}
                  filePondRef={filePondRef}
                  goToNext={goToNext}
                  setUseEntitlementFile={setUseEntitlementFile}
                  setBtnLoading={setBtnLoading}
                  setLicenseData={setLicenseData}
                />
              ) : (
                <></>
              )}
            </li>
          ))}
        </ol>
      </Body>
      <Footer>
        <NwButton
          onClick={() => {
            if (isFunction(filePondRef.current?.startUpload)) {
              filePondRef.current.startUpload();
            } else {
              goToNext();
            }
          }}
          loading={btnLoading}
          automation-id={getAutoId('begin-btn')}
          className={'tw-min-w-32'}
          type={'primary'}
        >
          {gettext('Begin')}
        </NwButton>
      </Footer>
    </>
  );
};

function EntitlementUploadComponent({
  autoId,
  filePondRef,
  goToNext,
  setUseEntitlementFile,
  setBtnLoading,
  setLicenseData,
}) {
  const [uploadEnabled, setUploadEnabled] = React.useState(false);

  return (
    <>
      <div className='tw-py-2'>
        <NwCheckbox
          name='enable_entitlement_upload'
          checked={uploadEnabled}
          onChange={(e) => setUploadEnabled(e.target.checked)}
          automation-id={`${autoId}:enable`}
        >
          {gettext('Import the Entitlement File')}
        </NwCheckbox>
      </div>
      {uploadEnabled ? (
        <UploadWidget
          ref={filePondRef}
          goToNext={goToNext}
          setUseEntitlementFile={setUseEntitlementFile}
          setBtnLoading={setBtnLoading}
          setLicenseData={setLicenseData}
          getAutoId={(attr) => `${autoId}:${attr}`}
        />
      ) : (
        <></>
      )}
    </>
  );
}

const UploadWidget = forwardRef(
  (
    {
      goToNext,
      setUseEntitlementFile,
      setBtnLoading,
      setLicenseData,
      getAutoId,
    },
    ref
  ) => {
    const [step, setStep] = useState(0); // 0: uploading, 1: uploaded, 2: sending to backend
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(null);
    const [uploaded, setUploaded] = useState(false);
    const [message, setMessage] = useState(null);

    const uploader = useMemo(
      () =>
        UploaderService.create({
          url: '/flatui/api/gui/adom/um/package/upload',
          key: 'filepath',
          data: {
            packettype: 'fgtkey',
          },
          onProgress: function (info) {
            setUploading(info);
          },
          onCompleted: async function (file, content) {
            setUploading(null);
            const status = content?.result?.[0]?.status || {
              message: gettext('Failed to upload file or invalid image.'),
            };
            const failMsg = gettext(
              'Registration Failed. Please check your entitlement file and try again.'
            );
            if (status.code === MACROS.USER.RESPOSE_STATUS.OK) {
              setBtnLoading(true);
              setUploaded(true);
              const msg = gettext(
                'The license has been uploaded successfully.'
              );
              setMessage(<div className='color-green'>{msg}</div>);
              try {
                setMessage(
                  <div className='color-green'>
                    {gettext(
                      'Updating license. Please hold on for a few seconds.'
                    )}
                  </div>
                );
                // get license status
                const licenseData = await tryFetchLicense();
                if (!licenseData?.account) {
                  throw new Error();
                }
                fiMessageBox.show(
                  gettext('Registration completed.'),
                  'success'
                );
                setUseEntitlementFile(true);
                setLicenseData(licenseData);
                goToNext();
              } catch (e) {
                setFile(null);
                setStep(0);
                setMessage(
                  <div className='color-red'>{e?.message || failMsg}</div>
                );
              }
            } else {
              setFile(null);
              setStep(0);
              setMessage(
                <div className='color-red'>{`${status.message}`}</div>
              );
            }
            setBtnLoading(false);
          },
        }),
      []
    );

    async function tryFetchLicense() {
      const MAX_RETRY = 10;
      let retry = 0;
      const wait = async (time) =>
        new Promise((resolve) => setTimeout(resolve, time * 1000));

      await wait(5);
      // try fetching license data.
      // getContractInfo() will fail if fgd server is still restarting.
      while (retry < MAX_RETRY) {
        let license;
        try {
          license = await getContractInfo();
        } catch {
          license = null;
        }
        if (license) return license;
        else {
          retry += 1;
          await wait(2);
        }
      }
      throw new Error();
    }

    const onAddFile = (event) => {
      setFile(event.detail?.file);
      setStep(1);
    };

    const startUpload = () => {
      if (uploaded) return;
      if (!file) {
        setMessage(
          <div className='color-red'>{`${gettext(
            'Please choose a valid file.'
          )}`}</div>
        );
        return;
      } else {
        uploader.removeAll();
        setUploading(null);
        uploader.addFiles([file]);
        setStep(2);
        setMessage(<>{gettext('Uploading the selected image file ...')}</>);
        uploader.start();
      }
    };

    useImperativeHandle(ref, () => ({
      startUpload: startUpload,
    }));

    return (
      <Section>
        {step === 0 ? (
          <Row>
            <Column>
              <DropUploader
                ref={ref}
                onProcess={(event) => onAddFile(event)}
                onNwUpdate={() => setMessage(null)}
              />
            </Column>
          </Row>
        ) : null}
        {step === 1 && file ? (
          <Row>
            <Column>
              <table className='table'>
                <thead>
                  <tr>
                    <th>{gettext('File')}</th>
                    <th style={{ width: '30px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{file.name}</td>
                    <td>
                      <NwIconButton
                        automation-id={getAutoId('remove')}
                        name='delete'
                        label={gettext('Remove')}
                        onClick={() => {
                          setFile(null);
                          setStep(0);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Column>
          </Row>
        ) : null}
        {message}
        {uploading && (
          <Row>
            <Column>
              <NwProgressBar
                percentage={(
                  ((uploading.loaded <= uploading.size
                    ? uploading.loaded
                    : uploading.size) /
                    uploading.size) *
                  100
                ).toFixed(0)}
              >
                {uploading.humanSize}
              </NwProgressBar>
            </Column>
          </Row>
        )}
      </Section>
    );
  }
);
