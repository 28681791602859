/*
record:{
  isOpen:false,
  data:TreeNode,
  toggle,
  select

}
*/
/*
const shouldUpdateRecords =({
  opennessState,
  useDefaultOpenness = false,
}) => !!opennessState || useDefaultOpenness;

const updateRecord = (
  record,
  recordId,
  {opennessState, useDefaultOpenness = false},
) => {

  if(useDefaultOpenness)
    record.isOpen =  record.data.isOpenByDefault
  else if(opennessState && opennessState[recordId]){
    record.isOpen =  opennessState[recordId]
    delete opennessState[recordId]
  }
};
*/
const updateRecordOnNewData = (
  record,
  { useDefaultOpenness = false, opennessState }
) => {
  if (useDefaultOpenness) {
    record.isOpen = record.node.isOpenByDefault;
  } else if (opennessState) {
    if (opennessState.hasOwnProperty(record.node.key)) {
      record.isOpen = opennessState[record.node.key];
      delete opennessState[record.node.key];
    }
  }
};

function createRecord(data, { opennessState }) {
  const isOpen = () => {
    if (opennessState) {
      if (opennessState.hasOwnProperty(data.key)) {
        const ret = opennessState[data.key];
        delete opennessState[data.key];
        return ret;
      } else {
        return false;
      }
    }
    return data.isOpenByDefault || false;
  };

  const record = {
    node: data,
    isOpen: isOpen(),
    /*toggle:function(){
      onToggle(record)
    },
    select(){
      onSelect(record)
    }
    */
  };
  //if(opennessState && opennessState[data.key]) delete opennessState[data.key]

  return record;
}
//refreshNodes=false,useDefaultOpenness=false,opennessState=null
export function computeTree(treeWalker, { records }, options = {}) {
  const order = [];
  //const records = {...records};
  const iter = treeWalker(options.refreshNodes || false);

  /*
  if (shouldUpdateRecords(options)) {
    for (const key in records) {
      updateRecord(records[key], key, options);
    }
  }
  */
  let isPreviousOpened = false;

  //eslint-disable-next-line
  /*
  record:{
    nestingLevel,
    isOpen,
    node
  } */
  //eslint-disable-next-line
  while (true) {
    const { done, value } = iter.next(isPreviousOpened);

    if (done || !value) {
      break;
    }

    let key;

    if (typeof value === 'string' || typeof value === 'symbol') {
      key = value;
    } else {
      key = value.key;
      const record = records[key];

      if (!record) {
        records[key] = createRecord(value, options);
      } else {
        record.node = value;
        updateRecordOnNewData(record, options);
      }
    }

    if (records[key]) {
      order.push(key);
      isPreviousOpened = records[key].isOpen;
    }
  }

  return {
    order,
    records,
  };
}

function pushStack(stack, items) {
  for (let i = items.length - 1; i >= 0; i--) {
    stack.push(items[i]);
  }
}

export const treeWalkerMaker = (treeNodes, sortNodes = (nodes) => nodes) =>
  function* (refresh) {
    sortNodes = sortNodes ? sortNodes : (nodes) => nodes;

    const stack = [];

    const items = [treeNodes].flat();
    pushStack(stack, sortNodes(items));

    // Walk through the tree until we have no nodes available.
    while (stack.length !== 0) {
      const node = stack.pop();

      const isOpened = yield refresh ? node : node.key;

      if (!isOpened) continue;

      pushStack(stack, sortNodes(node.children));
    }
  };
