import * as fp from '@fafm/fp';
import { fiStore, fiStoreUtils, fiStoreCollections } from 'fistore';
import { isUndefined } from 'lodash';
import $ from 'jquery';

/**
  fiCollections is an Angular adapter for using the client-side cache implemented with Redux.

  Features:
  - loading chunked data from websocket
  - data is only loaded when requested. You do not call "load" manually
  - chunked reading of cache items
  - always returns a copy of original cache data
  - cache expensive item-filtering operations by storing the results in "groups" (e.g. default device groups)

  Usage:
    fiCollections.registerCollection('sushi', {
      idAttr: 'name',
      loadFn: loadAllSushi
    });

    let sushiCollection = fiCollections.getCollection(adomOid, 'sushi');
    sushiCollection.getFullList().then(() => {
      // Loading done
    }, null, chunk => {
      // display sushi chunk
      render(chunk);
    });

  */
const actions = fiStoreCollections.actions;
const selectors = _getSelectors(fiStore.getState);

let _loadFns = {};
let _loadByIdFns = {};
// let _getItemRefsFns = {};
let _groupsConfig = {};
let _collectionIdAttrs = {};

// Interface
export const fiCollections = {
  getCollection,
  registerCollection,
  registerGroup,
  setMeta,

  // cancelLoad,  // TODO: Cancels all load promises (e.g. if switch ADOM)
};

// Implementations

/**
 * Gets main collection interface
 * Starts loading the collection if not already loaded
 * @param {*} adomoid
 * @param {string} collid
 */
function getCollection(adomoid, collid, forceReload = false) {
  let collection = selectors.getCollection(adomoid, collid);
  if (
    !collection ||
    collection.status === 'NOT_LOADED' ||
    (collection.status !== 'LOADING' && forceReload)
  ) {
    _loadCollection(adomoid, collid);
  }

  // All data retrieved is a copy, can only modify data through fiStore
  return {
    isReady: isReady(adomoid, collid),
    getStatus: () => selectors.getCollection(adomoid, collid).status,
    getFullList: (opts) => _readCollectionByChunks(adomoid, collid, opts),
    getItem: getItem(adomoid, collid),
    getItemsByIds: getItemsByIds(adomoid, collid),
    getGroupItems: getGroupItems(adomoid, collid),
    reloadCollection: () => getCollection(adomoid, collid, true),
    reloadItemById: reloadItemById(adomoid, collid),
    // getRawData is NOT recommended
    getRawData: getRawData(adomoid, collid),
  };
}

/**
 * Registers a loading function for a collection.
 * The loading function is called when items is first requested from its collection.
 * @param {string} collid The collection ID
 * @param {string} config.idAttr Id of the primary key of the collection
 * @param {function(adomoid): Promise} config.loadFn Returns collection items in a Promise.
 *    Supports notify() to return collection items
 * @param {function(adomoid, itemid): Promise} config.loadByIdFn (optional)
 *    Given itemId, returns a single item or an array of items in a Promise
 */
function registerCollection(collid, config) {
  if (typeof config.loadFn === 'function') {
    _loadFns[collid] = config.loadFn;
  }

  if (typeof config.loadByIdFn === 'function') {
    _loadByIdFns[collid] = config.loadByIdFn;
  }

  if (config.idAttr) {
    _collectionIdAttrs[collid] = config.idAttr;
  }
}

/**
 * # Note: Not implemented/not required... yet
 * Registers a function which does the following:
 * Given an item in the collection, returns all foreign ids referenced by this item,
 * and all the foreign ids which reference this item
 * @param {string} collid The collection ID
 * @param {function(Item): Refs} getItemRefsFn Returns Refs object for Item in this format:
 * {
 *    refs: {
 *      <collid>: {<itemid>: true}
 *    },
 *    ref_by: {
 *      <collid>: {<itemid>: true}
 *    }
 * }
 */
// function registerGetItemRefs(collid, getItemRefsFn) {
//   if (typeof(getItemRefsFn) === 'function') {
//     _getItemRefsFns[collid] = getItemRefsFn;
//   }
// }

/**
 * Register a function that returns true if the item belongs to the registered group
 * A group is a subset of the collection items.
 * Groups are updated dynamically based on the filter, as items are loaded
 * Groups are good for caching the filtered list of items, to avoid having to run the filter multiple times
 * @param {string} collid
 * @param {string} groupid
 * @param {function(object): bool} config.filterFn Function that returns true if the Object passes the filter
 */
function registerGroup(collid, groupid, config) {
  if (!_groupsConfig[collid]) {
    _groupsConfig[collid] = {};
  }
  _groupsConfig[collid][groupid] = config;
}

/**
 * Store any kind of metadata about this collection
 * @param {string} collid
 * @param {*} meta
 */
function setMeta(adomoid, collid, meta) {
  fiStore.dispatch(actions.setMeta({ adomoid, collid, meta }));
}

/**
 * Returns a promise that resolves when collection is ready
 * @returns {Promise}
 */
function isReady(adomoid, collid) {
  return function _isReady() {
    let defer = $.Deferred();
    let collection = selectors.getCollection(adomoid, collid);
    if (collection && collection.status === 'READY') {
      defer.resolve();
    } else {
      let unsubscribe = _watchCollection(
        adomoid,
        collid,
        (newCollection = {}) => {
          if (newCollection.status === 'READY') {
            defer.resolve();
            unsubscribe();
          }
        }
      );
    }

    return defer.promise();
  };
}

/**
 * Returns copy of item in collection
 * @param {string} itemid
 * @returns {object}
 */
function getItem(adomoid, collid) {
  return function _getItem(itemid) {
    let collection = selectors.getCollection(adomoid, collid);
    let item = collection.items[itemid];
    return item ? deepCopy(item) : null;
  };
}

/**
 * Returns items of a group
 * @param {string} groupid
 * @returns {Promise} see _readCollectionByChunks
 */
function getGroupItems(adomoid, collid) {
  return function _getGroupItems(groupid, opts) {
    const getItemId = selectors.getItemId(adomoid, collid);
    return _readCollectionByChunks(adomoid, collid, {
      ...opts,
      filterFn: (collection) => (item) => {
        // Return true if the itemid exist in the group
        let group = collection.groups[groupid];
        return group && !isUndefined(group[getItemId(item)]);
      },
    });
  };
}

/**
 * Returns items of the given itemids
 * @param {array} itemids Array of itemids
 * @returns {Promise} see _readCollectionByChunks
 */
function getItemsByIds(adomoid, collid) {
  return function _getItemsByIds(itemids, opts) {
    const getItemId = selectors.getItemId(adomoid, collid);
    let itemidsMap = itemids.reduce((acc, cur) => {
      acc[cur] = 1;
      return acc;
    }, {});

    return _readCollectionByChunks(adomoid, collid, {
      ...opts,
      filterFn: () => (item) => {
        return !isUndefined(itemidsMap[getItemId(item)]);
      },
    });
  };
}

/**
 * Use the registered "loadById()" to update a single item in a collection
 * @param {string} itemid
 */
function reloadItemById(adomoid, collid) {
  return function _reloadItemById(itemid) {
    const loadById = _loadByIdFns[collid];
    if (!loadById || typeof loadById !== 'function') {
      return Promise.reject(
        `loadById for collection '${collid}' is not a function!`
      );
    }

    return loadById(adomoid, itemid).then((resp) => {
      // Do not interrupt collection loading
      if (selectors.getCollection(adomoid, collid).status === 'LOADING') {
        return;
      }

      let items = Array.isArray(resp) ? resp : [resp];
      fiStore.dispatch(actions.updateItems({ adomoid, collid, items: items }));
      _updateItemsGroups(adomoid, collid, items);
      return resp;
    });
  };
}

/**
 * Returns any data currently in the collection
 * @returns {Array}
 */
function getRawData(adomoid, collid) {
  return function _getRawData() {
    let collection = selectors.getCollection(adomoid, collid);
    return collection.itemids.map((itemid) => collection.items[itemid]);
  };
}

/**
 * Loads the collection if not yet loaded
 * @param {string} collid
 */
function _loadCollection(adomoid, collid) {
  const loadFn = _loadFns[collid];
  if (!loadFn || typeof loadFn !== 'function') {
    return Promise.reject(
      `loadFn for collection '${collid}' is not a function!`
    );
  }

  setMeta(adomoid, collid, { idAttr: _collectionIdAttrs[collid] });
  fiStore.dispatch(actions.startLoad({ adomoid, collid }));

  loadFn(adomoid).then(
    (items) => {
      if (items && items.length > 0) {
        fiStore.dispatch(actions.addItems({ adomoid, collid, items }));
        _addItemsToGroups(adomoid, collid, items);
      }
      fiStore.dispatch(actions.endLoad({ adomoid, collid }));
    },
    (error) => {
      fiStore.dispatch(actions.setError({ adomoid, collid, error }));
    },
    (items) => {
      if (items && items.length > 0) {
        fiStore.dispatch(actions.addItems({ adomoid, collid, items }));
        _addItemsToGroups(adomoid, collid, items);
      }
    }
  );
} // end _loadCollection()

function _addItemsToGroups(adomoid, collid, items) {
  if (!_groupsConfig[collid]) {
    return;
  }

  let groupids = Object.keys(_groupsConfig[collid]);
  groupids.forEach((groupid) => {
    // Add new items to group if it passes the group filter
    let groupFilterFn = _groupsConfig[collid][groupid].filter;
    if (typeof groupFilterFn === 'function') {
      let filteredItemIds = items
        .filter(groupFilterFn)
        .map(selectors.getItemId(adomoid, collid));

      if (filteredItemIds.length > 0) {
        fiStore.dispatch(
          actions.updateGroup({
            adomoid,
            collid,
            groupid,
            itemids: filteredItemIds,
          })
        );
      }
    }
  });
}

// Also removes itemids from groups if they no longer belong
function _updateItemsGroups(adomoid, collid, items) {
  if (!_groupsConfig[collid]) {
    return;
  }

  let groupids = Object.keys(_groupsConfig[collid]);
  groupids.forEach((groupid) => {
    let groupFilterFn = _groupsConfig[collid][groupid].filter;
    if (typeof groupFilterFn === 'function') {
      // See which items pass group filter
      let filteredItemIds = items
        .filter(groupFilterFn)
        .map(selectors.getItemId(adomoid, collid));

      // Remove itemids from this group if they do not pass
      let itemidsToRemove = items
        .map(selectors.getItemId(adomoid, collid))
        .filter((itemid) => filteredItemIds.indexOf(itemid) < 0);

      if (filteredItemIds.length > 0 || itemidsToRemove.length > 0) {
        fiStore.dispatch(
          actions.updateGroup({
            adomoid,
            collid,
            groupid,
            itemids: filteredItemIds,
            itemidsToRemove,
          })
        );
      }
    }
  });
}

/**
 * Subscribe to state changes in the specified collection
 * @param {string} collid
 * @param {function(newState, oldState): None} callback
 * @return {function} Unsubscribe watch callback
 */
function _watchCollection(adomoid, collid, callback) {
  return fiStoreUtils.observeStore(
    fiStore,
    (state) => fp.path(['collections', adomoid, collid], state),
    callback
  );
}

// Private
function _getSelectors(getStateFn) {
  const getAdomCollections = (adomoid) =>
    getStateFn().collections[adomoid] || {};
  const getCollection = (adomoid, collid) =>
    getAdomCollections(adomoid)[collid];
  const getGroup = (collection) => (groupid) =>
    collection ? collection.groups[groupid] : null;

  // (adomoid, collid) => groupid -> group
  const getCollectionGroup = fp.compose(getGroup, getCollection);

  // (adomoid, collid) -> meta
  const getMeta = fp.compose(fp.prop('meta'), getCollection);

  // (adomoid, collid) => item -> itemid
  const getItemId = fp.compose((meta) => fp.prop(meta.idAttr), getMeta);

  return {
    getCollection,
    getGroup,
    getCollectionGroup,
    getItemId,
  };
}

/**
 * Reads collection and sends notify as chunks are ready.
 * All data is returned as a copy to prevent modification.
 * @param {string} collid
 * @param {number} chunkSize Max size of each chunk
 * @param {number} readInterval Smallest amount of time between each notify (in milliseconds)
 * @param {function} customOpts.filterFn (collection) => (item) -> bool
 *    Function that takes the current collection and item. Should returns true if item passes filter.
 * @returns {Promise} Notifies when each item chunk is ready. Resolves with all copied items.
 */
function _readCollectionByChunks(adomoid, collid, customOpts) {
  let defer = $.Deferred();
  let opts = {
    firstChunkSize: 200, // Smaller first chunk for faster initial load
    chunkSize: 5000,
    readInterval: 10,
    filterFn: null,
    promCancel: null,
    useRawData: false,
  };

  if (customOpts) {
    opts = { ...opts, ...customOpts };
  }

  let isFirstChunk = true;
  let numRead = 0;
  let allDataCopy = [];

  let intervalId = setInterval(read, opts.readInterval);

  // Handle cancel read operation
  if (opts.promCancel && opts.promCancel.then) {
    opts.promCancel.then(() => {
      clearInterval(intervalId);
      defer.reject();
    });
  }

  const copyItemFn = opts.useRawData ? (object) => object : deepCopy;

  return defer.promise();

  function read() {
    let collection = selectors.getCollection(adomoid, collid);
    if (collection.status === 'ERROR') {
      clearInterval(intervalId);
      defer.reject();
      return;
    }

    let collectionSize = collection.itemids.length;
    let nextChunkEnd = isFirstChunk
      ? numRead + opts.firstChunkSize
      : numRead + opts.chunkSize;

    // readEnd should be the smaller of numRead+chunkSize, or collectionSize
    let readEnd = nextChunkEnd < collectionSize ? nextChunkEnd : collectionSize;

    if (numRead < readEnd) {
      // splice entries
      copyAndNotify(
        collection.itemids
          .slice(numRead, readEnd)
          .map((itemid) => collection.items[itemid]),
        collection
      );

      numRead = readEnd;
      isFirstChunk = false;
    } else if (collection.status === 'READY') {
      // else if collection is READY, then read the rest of the data
      clearInterval(intervalId);
      copyAndNotify(
        collection.itemids
          .slice(numRead)
          .map((itemid) => collection.items[itemid]),
        collection
      );
      defer.resolve(allDataCopy);
    }
    // otherwise skip and wait
  } // END read()

  function copyAndNotify(items, collection) {
    let itemsCopy;
    if (typeof opts.filterFn === 'function') {
      itemsCopy = copyItemFn(items.filter(opts.filterFn(collection)));
    } else {
      itemsCopy = copyItemFn(items);
    }
    if (itemsCopy.length > 0) {
      allDataCopy.push(...itemsCopy);
      defer.notify(itemsCopy);
    }
  }
} // END _readCollectionByChunks()

function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}
