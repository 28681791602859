import {
  select,
  all,
  put,
  call,
  takeEvery,
  takeLatest,
  putResolve,
} from 'redux-saga/effects';
import { get, isNil, negate } from 'lodash';
import { promiseSaga } from 'fistore/utils/saga-promise';

// API
import * as api from './api';

// actions
import {
  devicesAction,
  fetchDevicesAction,
  fetchMultipleDeviceAction,
  fetchSingleDeviceAction,
  vdomsAction,
} from './actions';
import { fetchDevicesAssignedPkgAction } from '../assigned_pkg/actions';
import { fazDelNotificationAction } from '../faz_del/actions';
import { fetchDeviceChecksum } from '../checksums/actions';
import { SocketActions } from 'fi-websocket';
import { fetchDeviceGroupsMemAction } from '../../devGroups/action';
import { fetchDevicesPsirtAction } from '../psirt/actions';
import { fetchRemoteFAZAction } from '../remote_faz/actions';

// selectors
import {
  find_paths_included_device,
  find_paths_included_vdom,
  get_device,
  get_devices,
} from './selectors';
import { getDeviceChecksum } from '../checksums/selectors';

// requests

// helpers
import { batchActions, debounceBatch } from '../../batch';
import {
  callPromiseAction,
  getDataFromResponse,
  store_get_dispatch,
} from '../../utils';

// session
import {
  getIsFabricAdom,
  getAdomOSType,
  getSessionAdomOid,
} from 'fistore/session/adom/selectors';
import {
  inFmgNotRestrictedAdmin,
  getIsRestrictedAdmin,
} from 'fistore/session/sysConfig/selectors';
import {
  fetchSessionAdom,
  switchSessionAdom,
} from 'fistore/session/adom/slice';

// routing
import { runIfAvailable } from '../../routing/saga';
import { refreshAppTree } from 'fistore/routing/slice';
import { isNumberString } from 'kit-number';
const {
  NOTIFY_REMOVED_ACTION,
  NOTIFY_CHANGED_ACTION,
  NOTIFY_ADDED_ACTION,
  NOTIFY_BATCH_ADDED_ACTION,
  NOTIFY_BATCH_REMOVED_ACTION,
  NOTIFY_BATCH_CHANGED_ACTION,
} = SocketActions;

export function* watchDeviceAction() {
  yield takeLatest(fetchDevicesAction.type, promiseSaga(fetchDevicesList));
  yield takeLatest(fetchSingleDeviceAction.type, fetchSingleDevice);
  yield takeLatest(fetchMultipleDeviceAction.type, fetchMultipleDevice);
  yield takeEvery(NOTIFY_REMOVED_ACTION, dvmRemovedNotify);
  yield takeEvery(NOTIFY_CHANGED_ACTION, dvmChangedNotify);
  yield takeEvery(NOTIFY_ADDED_ACTION, dvmAddedNotify);

  // batched
  yield takeEvery(NOTIFY_BATCH_REMOVED_ACTION, dvmBatchedRemovedNotify);
  yield takeEvery(NOTIFY_BATCH_ADDED_ACTION, dvmBatchedAddedNotify);
  yield takeEvery(NOTIFY_BATCH_CHANGED_ACTION, dvmBatchedChangedNotify);

  // reload psirt data
  yield takeLatest(
    switchSessionAdom.fulfilled.type,
    runIfAvailable(inFmgNotRestrictedAdmin, function* () {
      yield call(reloadPsirtData);
    })
  );

  // Every time the adom is fetched, check device checksums and reload if
  // necessary
  yield takeLatest(
    fetchSessionAdom.fulfilled.type,
    runIfAvailable(negate(getIsRestrictedAdmin), checkFetchDevices)
  );

  yield takeLatest(
    [
      devicesAction.fetch.SUCCESS,
      NOTIFY_REMOVED_ACTION,
      NOTIFY_ADDED_ACTION,
      NOTIFY_BATCH_REMOVED_ACTION,
      NOTIFY_BATCH_ADDED_ACTION,
    ],
    function* () {
      yield put(refreshAppTree());
    }
  );
}

function* checkFetchDevices() {
  yield putResolve(fetchDeviceChecksum());
  const checksum = yield select(getDeviceChecksum);
  if (
    !checksum ||
    checksum.dvmChecksum !== checksum.oldDvmChecksum ||
    checksum.adomChecksum !== checksum.oldAdomChecksum
  ) {
    const adomOid = yield select(getSessionAdomOid);
    yield put(fetchDevicesAction({ adomOid }));
  }
}

const make_encodePath =
  (adomOid) =>
  (payload = {}) => {
    return {
      path: adomOid,
      data: payload,
    };
  };
const make_deviceChunkProcessor =
  (deviceOp) => (recordActionType) => (adomOid) => (data) => {
    const vdomEncodePath = (deviceOid, payload = {}) => {
      return {
        path: adomOid + '-' + deviceOid,
        data: payload,
      };
    };

    const encodePath = make_encodePath(adomOid);

    const actions = [];
    const vdomPayloads = [];

    //const devVdoms={}

    data.forEach((dev) => {
      if (typeof dev.vdoms !== 'undefined') {
        const vdoms = {
          byId: {},
          allIds: [],
        };

        dev.vdoms.forEach((vdom) => {
          vdoms.byId[vdom.oid] = vdom;
          vdoms.allIds.push(vdom.oid);
        });

        const data = vdomEncodePath(dev.oid, vdoms);
        vdomPayloads.push(data);

        //devVdoms[dev.oid]=vdoms
        //actions.push(vdomsAction.record.set(payload))

        delete dev.vdoms;
      }

      deviceOp.add(dev);
    });

    //actions.push(vdomsAction.record.append(encodePath(devVdoms)))

    actions.push(
      devicesAction.record[recordActionType](encodePath(deviceOp.get()))
    );
    actions.push(vdomsAction.record.append(vdomPayloads));

    return actions;
  };
function* dvmAddedNotify(action) {
  yield deviceAddedNotify(action);
  yield vdomAddedNotify(action);
}

function* dvmBatchedAddedNotify(action) {
  yield deviceBatchedAddedNotify(action);
}

function* vdomAddedNotify(action) {
  if (action.payload.collection !== 'vdom') return;
  //const state=yield select()
  //re-fetch device
  const {
    meta: { adom, deviceOid },
  } = action.payload;
  const adomOid = adom;

  const ret = yield call(api.fetch_device, adomOid, deviceOid);
  const data = ret.result[0].data;

  /*
  let actionList=[]
  const currentDevice=get_device(state,adomOid,deviceOid)
  if(currentDevice.vdom_status!==data.vdom_status){
    actionList.append(devicesAction.record.change(data))
  }
  */

  const deviceOp = {
    data: [],
    add: function (dev) {
      this.data.push(dev);
    },
    get: function () {
      return this.data;
    },
  };

  const actions = make_deviceChunkProcessor(deviceOp)('change')(adomOid)([
    data,
  ]);
  if (actions && actions.length > 0) {
    yield put(batchActions(actions));
  }
}

function* process_remote_faz(adomOid, device) {
  const state = yield select();

  function isRemoteFaz(state, device) {
    /*is_not_fab_adom && adom_is_not_faz && device is FAZ*/
    if (
      getIsFabricAdom(state) ||
      getAdomOSType(state) === MACROS.DVM.DVM_OS_TYPE_FAZ
    )
      return false;

    return device.os_type === MACROS.DVM.DVM_OS_TYPE_FAZ;
  }

  if (device.os_type === MACROS.DVM.DVM_OS_TYPE_FAZ) {
    // reload adom, which will refresh app tree, so faz apps will show correctly
    yield put(fetchSessionAdom());
  }

  if (!isRemoteFaz(state, device)) return false;

  yield put(fetchRemoteFAZAction({ adomOid }));

  return true;
}

function* deviceAddedNotify(action) {
  if (action.payload.collection !== 'device') return;

  const {
    payload: { id, meta },
  } = action;
  const groups = action.payload.fields ? action.payload.fields.groups : null;

  const adomOid = meta.adom;

  yield addDevice(id, adomOid, groups);

  //yield reloadPsirtData();
}

function* deviceBatchedAddedNotify(action) {
  if (action.payload.collection !== 'device') return;

  const {
    payload: { id: ids, meta: metas, fields },
  } = action;
  const updates = [];

  try {
    // notification from task_monitor.cpp > method_task_monitor add_multi_devices task
    for (const meta of metas) {
      const { adom, devices } = meta;

      if (!Array.isArray(devices)) {
        continue;
      }

      for (const device of devices) {
        if (!device) continue;
        const { oid, groups } = device;
        if (!isNil(adom) && !isNil(oid)) {
          updates.push(addDevice(oid, adom, groups));
        }
      }
    }

    // notification from dvm_event.cpp > send_add_device_event
    ids.forEach((id, i) => {
      const adomOid = get(metas, [i, 'adom']);
      const groups = get(fields, [i, 'groups']);
      if (!isNil(adomOid) && !isNil(id) && isNumberString(id)) {
        updates.push(addDevice(id, adomOid, groups));
      }
    });

    yield all(updates);

    //yield reloadPsirtData();
  } catch (err) {
    if (MACROS.SYS.CONFIG_DEBUG) {
      console.error(err);
    }
  }
}

function* addDevice(id, adomOid, groups) {
  const ret = yield call(api.fetch_device, adomOid, parseInt(id));
  const data = ret.result[0].data;

  //if device is remote faz
  const retFAZ = yield process_remote_faz(adomOid, data);
  if (retFAZ) return;

  const deviceOp = {
    data: [],
    add: function (dev) {
      this.data.push(dev);
    },
    get: function () {
      return this.data;
    },
  };

  const actions = make_deviceChunkProcessor(deviceOp)('add')(adomOid)([data]);

  if (groups && groups.length > 0) {
    yield put(fetchDeviceGroupsMemAction());
  }

  if (actions && actions.length > 0) {
    yield put(batchActions(actions));
  }
}

function* pkgChangedNotify(payload) {
  if (payload.collection !== 'pkg-status') return;

  const adomOid = payload.meta.adom;
  yield put(fetchDevicesAssignedPkgAction({ adomOid }));
}

function* batchedPkgChangedNotify(payload) {
  if (payload.collection !== 'pkg-status') return;

  yield put(fetchDevicesAssignedPkgAction());
}

function* dvmChangedNotify(action) {
  yield deviceChangedNotify(action.payload);
  yield fazDelChangedNotify(action.payload);
  yield pkgChangedNotify(action.payload);
}

function* dvmBatchedChangedNotify(action) {
  yield deviceBatchedChangedNotify(action.payload);
  yield batchedPkgChangedNotify(action.payload);
}

function* deviceRemovedNotify(payload) {
  if (payload.collection !== 'device') return;

  yield removeDevice(payload.path, payload.id);
  //yield reloadPsirtData();
}

function* dvmRemovedNotify(action) {
  yield deviceRemovedNotify(action.payload);
  yield vdomRemovedNotify(action.payload);
}

function* dvmBatchedRemovedNotify(action) {
  yield deviceBatchedRemovedNotify(action.payload);
}

function* deviceBatchedRemovedNotify(payload) {
  if (payload.collection !== 'device') return;

  try {
    // notification from server: device_update_notify.cpp > MonitorDelDevice
    const { meta: metas } = payload;
    if (!metas) return;

    const updates = [];

    for (const meta of metas) {
      const deviceOids = meta.deviceOids;

      if (Array.isArray(deviceOids)) {
        for (const devOid of deviceOids) {
          updates.push(removeDevice(payload.path, devOid));
        }
      }

      // notification from dvm_event.cpp > send_del_device_event
      if (meta.deviceOid) {
        updates.push(removeDevice(payload.path, meta.deviceOid));
      }
    }

    yield all(updates);

    //yield reloadPsirtData();
  } catch (err) {
    if (MACROS.SYS.CONFIG_DEBUG) {
      console.error(err);
    }
  }
}

function* removeDevice(path, id) {
  let paths;
  if (path) {
    paths = Array.isArray(path) ? path : [path];
  } else {
    paths = yield select(find_paths_included_device, id);
  }
  if (!paths || paths.length === 0) return;

  for (let i = 0; i < paths.length; i++) {
    const content = {
      path: paths[i],
      data: {
        id: id,
      },
    };

    yield put(debounceBatch(devicesAction.record.delete(content)));
  }

  yield removeVdoms(paths, id);
}

export function* reloadPsirtData(adomOid) {
  yield put(fetchDevicesPsirtAction({ adomOid }));
}

function* removeVdoms(paths, deviceOid, vdomOid = -1) {
  for (let i = 0; i < paths.length; i++) {
    const content = {
      path: paths[i] + '-' + deviceOid,
      data: {
        id: vdomOid,
        device: deviceOid,
        vdom: parseInt(vdomOid),
      },
    };
    if (vdomOid === -1) content.data = {};

    yield put(debounceBatch(vdomsAction.record.delete(content)));
  }
}

function* vdomRemovedNotify(payload) {
  if (payload.collection !== 'vdom') return;

  const deviceOid = payload.meta.deviceOid;
  const vdomOid = payload.meta.vdomOid || payload.id;

  const paths = yield select(find_paths_included_vdom, deviceOid, vdomOid);
  if (!paths || paths.length === 0) return;

  yield removeVdoms(paths, deviceOid, vdomOid);
}

function* deviceChangedNotify(payload) {
  if (payload.collection !== 'device') return;
  const state = yield select();
  const sesAdomOid = yield select(getSessionAdomOid);

  const adomOid = payload.meta.adom || sesAdomOid;
  const deviceOid = payload.meta.deviceOid;

  if (!get_device(state, adomOid, deviceOid)) return;

  const response = yield call(api.fetch_device, adomOid, deviceOid, true);

  const device = response.result[0].data;

  //if device is remote faz
  const retFAZ = yield process_remote_faz(adomOid, device);
  if (retFAZ) return;

  const actions = [];

  if (typeof device.vdoms !== 'undefined') {
    const vdomContent = {
      path: adomOid + '-' + deviceOid,
      data: device.vdoms,
    };
    actions.push(vdomsAction.record.change(vdomContent));
    delete device.vdoms;
  }

  const deviceContent = {
    path: adomOid.toString(),
    data: {
      id: deviceOid.toString(),
      data: device,
    },
  };
  actions.push(devicesAction.record.change(deviceContent));

  yield put(batchActions(actions));
  //yield reloadPsirtData();
}

function* deviceBatchedChangedNotify(payload) {
  if (payload.collection !== 'device') return;
  const state = yield select();
  const sesAdomOid = yield select(getSessionAdomOid);

  const adomOid = payload.meta?.[0]?.adom || sesAdomOid;
  const actions = [];

  try {
    const deviceOids = [];

    for (const meta of payload.meta) {
      const deviceOid = meta.deviceOid;
      const adomOid = meta.adom;
      if (!get_device(state, adomOid, deviceOid)) continue;
      deviceOids.push(parseInt(deviceOid, 10));
    }

    //first fetch batch, then process each
    const response = yield call(
      api.fetch_device_bulk,
      adomOid,
      deviceOids,
      true
    );
    const devices = response.result?.[0]?.data || [];

    for (const device of devices) {
      //if device is remote faz
      const retFAZ = yield process_remote_faz(adomOid, device);
      if (retFAZ) return;

      const deviceOid = device.oid;

      if (typeof device.vdoms !== 'undefined') {
        const vdomContent = {
          path: adomOid + '-' + deviceOid,
          data: device.vdoms,
        };
        actions.push(vdomsAction.record.change(vdomContent));
        delete device.vdoms;
      }

      const deviceContent = {
        path: adomOid.toString(),
        data: {
          id: deviceOid.toString(),
          data: device,
        },
      };
      actions.push(devicesAction.record.change(deviceContent));
    }
  } catch (e) {
    console.error('error in batched device change saga', e);
  }

  yield put(batchActions(actions));
  //yield reloadPsirtData();
}

function* fazDelChangedNotify(payload) {
  if (payload.collection !== 'fazcmd/sync/dvmdb') return;
  yield put(
    fazDelNotificationAction.record.change({
      timestamp: payload?.fields?.timestamp || 0,
      dellist: payload?.fields?.dellist || [],
      adomName: payload?.fields?.adomName || '',
    })
  );
}

class AsyncQueue {
  constructor(consumer) {
    this.list = [];
    this.consumer = consumer;
    this.running = false;
  }
  push(message) {
    this.list.push(message);
    if (this.running) return;

    setTimeout(() => {
      this.running = true;
      this.process();
      this.running = false;
    }, 0);
  }
  process() {
    function pump() {
      if (this.list.length <= 0) return;

      this.consumer(this.list[0]);

      this.list.shift();

      return pump.call(this);
    }

    pump.call(this);
  }
  empty() {
    this.list = [];
  }
}

let deviceLoadTimer = null;
const set_device_load_timer = () => {
  deviceLoadTimer = setTimeout(() => {
    deviceLoadTimer = null;
  }, 1000 * 8);
};
const clear_device_load_timer = () => {
  if (deviceLoadTimer) {
    clearTimeout(deviceLoadTimer);
    deviceLoadTimer = null;
  }
};

function* fetchDevicesList(action) {
  const adomOid = yield select(getSessionAdomOid);
  const devices = yield select((state) => get_devices(state, adomOid));
  if (devices?.loading) return;

  yield call(loadDeviceList, action, adomOid);
}

function loadDeviceList(action, _adomOid) {
  const dispatch = store_get_dispatch();
  const payload = action.payload || {};
  const adomOid = payload.adomOid || _adomOid;
  const encodePath = make_encodePath(adomOid);

  const deviceChunkProcessor = (data) => {
    const deviceOp = {
      byId: {},
      allIds: [],
      add: function (dev) {
        this.byId[dev.oid] = dev;
        this.allIds.push(dev.oid);
      },
      get: function () {
        return {
          byId: this.byId,
          allIds: this.allIds,
        };
      },
    };

    return make_deviceChunkProcessor(deviceOp)('append')(adomOid)(data);
  };
  const devicesProcessor = {
    header: (totalRecords) => [
      devicesAction.record.set(encodePath({ total: totalRecords })),
    ],
    data: (chunk) => deviceChunkProcessor(chunk.data),
    tail: (totalRecords) => [
      devicesAction.record.set(encodePath({ total: totalRecords })),
    ],
  };

  const createChunkProcessor = (processor) => (obj) => {
    if (typeof obj.header !== 'undefined') {
      if (typeof obj.header.totalRecords !== 'undefined') {
        const totalRecords = obj.header.totalRecords;
        return processor.header(totalRecords);
      }
    } else if (typeof obj.tail !== 'undefined') {
      if (typeof obj.tail.totalRecords !== 'undefined') {
        const totalRecords = obj.tail.totalRecords;
        return processor.tail(totalRecords);
      }
    } else if (typeof obj.chunk !== 'undefined') {
      const r = processor.data(obj.chunk);

      return r;
    }
    return null;
  };

  const process = createChunkProcessor(devicesProcessor);

  const next = (data) => {
    const actions = process(data.result);
    if (actions) {
      dispatch(batchActions(actions));
    }
  };
  const complete = () => {
    dispatch(devicesAction.fetch.success(encodePath()));
    clear_device_load_timer();
  };

  const que = new AsyncQueue((data) => {
    if (data === '--end--') complete();
    else next(data);
  });

  if (deviceLoadTimer) return;
  set_device_load_timer();
  dispatch(devicesAction.fetch.start(encodePath()));

  return api.fetch_device_list(adomOid).subscribe({
    next: (data) => {
      que.push(data);
    },
    complete: () => {
      que.push('--end--');
    },
    error: () => {
      que.push('--end--');
    },
  });
}

function* handleUpdateSingleDevice(adomOid, device) {
  //if device is remote faz
  const retFAZ = yield process_remote_faz(adomOid, device);
  if (retFAZ) return;

  const deviceOid = device.oid;

  const actions = [];

  if (!isNil(device.vdoms)) {
    const vdomContent = {
      path: adomOid + '-' + deviceOid,
      data: device.vdoms,
    };
    actions.push(vdomsAction.record.change(vdomContent));
    delete device.vdoms;
  }

  const deviceContent = {
    path: adomOid.toString(),
    data: {
      id: deviceOid.toString(),
      data: device,
    },
  };

  actions.push(devicesAction.record.change(deviceContent));

  return actions;
}

function* fetchSingleDevice(action) {
  return yield callPromiseAction(action, function* () {
    const payload = action.payload || {};
    const adomOid = payload.adomOid || (yield select(getSessionAdomOid));
    const deviceOid = payload.deviceOid;

    const resp = yield api.fetch_device(adomOid, deviceOid);
    const device = getDataFromResponse(resp);

    const actions = yield handleUpdateSingleDevice(adomOid, device);
    yield put(batchActions(actions));
  });
}

function* fetchMultipleDevice(action) {
  return yield callPromiseAction(action, function* () {
    const payload = action.payload || {};
    const adomOid = payload.adomOid || (yield select(getSessionAdomOid));
    const deviceOids = (payload.deviceOids || [])
      .map((oid) => parseInt(oid, 10))
      .filter((oid) => !isNaN(oid));

    const resp = yield call(api.fetch_device_bulk, adomOid, deviceOids, true);
    const devices = getDataFromResponse(resp);

    const actions = [];

    for (const device of devices) {
      const _actions = yield handleUpdateSingleDevice(adomOid, device);
      actions.push(..._actions);
    }

    yield put(batchActions(actions));
  });
}
