import { transform } from 'lodash';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getSessionAdomName } from 'fistore/session/adom/selectors';
import * as actions from './actions';
import * as api from './api';
import { callPromiseAction, getDataFromMultipleResponse } from '../../utils';

export function* watchDevicesFirmware() {
  yield takeLatest(actions.fetchDevicesFirmwareAction.type, fetchFirmware);
  yield takeLatest(
    actions.fetchFirmwareManualUpgradeAction.type,
    fetchFirmwareManualUpgrade
  );
  yield takeLatest(
    actions.deleteFirmwareManualUpgradeAction.type,
    deleteFirmwareManualUpgrade
  );
}

function* fetchFirmware(action) {
  yield callPromiseAction(action, function* () {
    yield put(actions.devicesFirmwareAction.fetch.start());

    // fetch firmware data
    const resp = yield call(api.fetch_device_firmware);
    // parse
    const [matrix, firmwareDataByVersion] = getDataFromMultipleResponse(resp);
    const fwById = getFwById(firmwareDataByVersion);
    const payload = {
      matrix,
      fwById,
    };
    // fetch manual upgrade schedule and write to store
    yield call(fetchFirmwareManualUpgrade, action);

    yield put(actions.devicesFirmwareAction.fetch.success(payload));

    return yield payload;
  });
}

function getFwById(firmwareData) {
  return transform(
    Array.isArray(firmwareData) ? firmwareData : [],
    (result, rec) => {
      if (rec.devoid) {
        result[rec.devoid] = rec;
      }
    },
    {}
  );
}

function* fetchFirmwareManualUpgrade(action) {
  yield callPromiseAction(action, function* () {
    const {
      adomName: _adomName,
      devices = null,
      groups = null,
    } = action.payload || {};
    const adomName = _adomName || (yield select(getSessionAdomName));
    const resp = yield call(
      api.fetch_firmware_upgrade_schedule,
      adomName,
      devices,
      groups
    );
    const manualUpgradesMap = resp.reduce((acc, deviceProfile) => {
      const { name, profile } = deviceProfile;
      acc[name] =
        Array.isArray(profile) && profile.length > 0
          ? profile.filter((p) => p.type === 'manual_once')
          : [];
      return acc;
    }, {});
    yield put(
      actions.setFirmwareManualUpgradeAction({ manualUpgradesMap, devices })
    );
    return manualUpgradesMap;
  });
}

function* deleteFirmwareManualUpgrade(action) {
  yield callPromiseAction(action, function* () {
    const {
      adomName: _adomName,
      profileName,
      devices = null,
    } = action.payload || {};
    const adomName = _adomName || (yield select(getSessionAdomName));
    const resp = yield call(
      api.delete_firmware_upgrade_schedule,
      adomName,
      profileName,
      devices
    );
    if (resp?.status === 'success') {
      yield put(
        actions.fetchFirmwareManualUpgradeAction({ adomName, devices })
      );
    }
    return resp;
  });
}
