import { identity } from 'lodash';

export const APP_TREE_ROOT = Symbol('root');

export const appTreeExtractor = (node) => {
  const nodeIsArray = Array.isArray(node);
  const key = nodeIsArray ? node[0] : node;
  const children = nodeIsArray && node[1]?.length ? node[1] : null;
  return [key, children];
};

/**
 * Map recursively over a tree
 */
export const treeMap =
  (childrenGetter) =>
  ({ fn = identity, items, postFn, stopFn = null }) => {
    const traverse = (level, path) => (node) => {
      const parents = (node === APP_TREE_ROOT ? [] : [node]).concat(path);
      const mapChildren = (xs) =>
        xs.map(traverse(level + 1, parents)).filter(Boolean);
      // special key from the root.
      if (node === APP_TREE_ROOT) {
        return Array.isArray(items) ? mapChildren(items) : [];
      }
      const newNode = fn(node, path, level);
      // Stop mapping the tree node if fn returns falsy
      if (!newNode) return null;
      const children = childrenGetter(node);
      // if no children or stopFn returns true, stop traversing.
      const newChildren =
        !children || (stopFn && stopFn(node, level))
          ? null
          : mapChildren(children);
      return postFn(newNode, newChildren, level);
    };
    return traverse(0, [])(APP_TREE_ROOT);
  };

/**
 * Reduce over a tree.
 */
export const treeReduce =
  (childrenGetter) =>
  ({ fn, init, items, stopFn, postFn = identity }) => {
    const traverse = (level, path) => (accu, node) => {
      if (node === APP_TREE_ROOT) {
        return Array.isArray(items)
          ? items.reduce(traverse(level + 1, []), init)
          : init;
      }
      const parents = [node].concat(path);
      const newAccu = fn(accu, node, parents, level);
      const children = childrenGetter(node);
      if (!children || !children.length || (stopFn && stopFn(node, level))) {
        return newAccu;
      }
      return postFn(children.reduce(traverse(level + 1, parents), newAccu));
    };
    return traverse(0, [])(init, APP_TREE_ROOT);
  };

export const treeInsert =
  (childrenGetter) =>
  ({ items, subTree, matchFn, fn, postFn, stopFn }) => {
    const _treeMap = treeMap(childrenGetter);
    return _treeMap({
      items,
      fn: identity,
      postFn: (node, children, level) => {
        const newChildren = matchFn(node)
          ? _treeMap({
              items: subTree,
              fn,
              postFn,
              stopFn,
            })
          : children;
        return postFn(node, newChildren, level);
      },
      stopFn,
    });
  };

export const treeFind =
  (childrenGetter) =>
  ({ fn, items }) => {
    const traverse = (arr) => {
      if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; i++) {
          const node = arr[i];
          if (fn(node)) return [true, node];
          const result = traverse(childrenGetter(node));
          if (result[0]) return result;
        }
      }
      return [false, undefined];
    };
    return traverse(items)[1];
  };
