import { fiSession, fiStoreSocFabric, fiStoreFabric } from 'fistore';
import { negate, overSome } from 'lodash';
const { hasDeviceByOsType } = fiStoreSocFabric.device.selectors;
const {
  inAdom,
  adomNames: an,
  inGlobalAdom,
  hasRDPermitOn,
  hasFazFeature,
  hasRemoteFAZ,
  isFmg,
  isFaz,
  getIsFazSupervisor,
} = fiSession;

const mrPRIV = MACROS.USER.DVM;
const hasIotEp = fiStoreFabric.getHasIotEp;

export default {
  fmg_dashboard: {
    path: '/fmg_dashboard',
    icon: 'tachometer-alt',
    iconLibrary: 'fa-solid',
    label: gettext('Dashboard'),
    showCheckers: [
      negate(inGlobalAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
      isFmg,
    ],
  },
  faz_dashboards: {
    label: gettext('Dashboards'),
    icon: 'tachometer-alt',
    iconLibrary: 'fa-solid',
    hideIfNoChildren: true,
    showCheckers: [isFaz],
  },
  faz_dashboard: {
    path: '/faz_dashboard',
    label: gettext('Status'),
    showCheckers: [
      isFaz,
      negate(inGlobalAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
  },
  dashboard_iot: {
    label: gettext('IoT Dashboard'),
    path: '/dashboard/iot',
    showCheckers: [
      isFaz,
      inAdom([an.SecurityFabric, an.FortiGate]),
      hasRDPermitOn('log_viewer'),
      hasRDPermitOn('event_management'),
      hasIotEp,
    ],
  },
  dashboard_email_metrics: {
    label: gettext('Email Metrics'),
    path: '/dashboard/email_metrics',
    showCheckers: [
      isFaz,
      inAdom([an.SecurityFabric, an.FortiMail]),
      hasRDPermitOn('log_viewer'),
      hasDeviceByOsType([MACROS.DVM.DVM_OS_TYPE_FML]),
    ],
  },
  dashboard_soc: {
    label: gettext('SOC Dashboard'),
    path: '/dashboard/soc',
    showCheckers: [
      isFaz,
      negate(inGlobalAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
  },
  dashboard_ems: {
    label: gettext('Endpoint Vulnerability'),
    path: '/dashboard/ems',
    showCheckers: [
      overSome([hasFazFeature, hasRemoteFAZ]),
      inAdom([an.SecurityFabric, an.FortiClient]),
      hasRDPermitOn('log_viewer'),
      negate(getIsFazSupervisor),
    ],
  },
};
