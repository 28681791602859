import React, { useState } from 'react';
import { processKeyEnter } from '../common/keyboard';

//import { renderIcon } from 'ra-render-util';
import { NwIcon, NwInput, NwTooltip, NwIconButton } from '@fafm/neowise-core';

const TreeSearchBox = ({ onSearchText, onSort, sortAsc, style, suffix }) => {
  const [sortDir, setSortDir] = useState(sortAsc);

  const handleSort = () => {
    setSortDir(!sortDir);
    onSort({ sortAsc: !sortDir });
  };

  const handleOnKeyDown = (e) => {
    processKeyEnter(handleSort)(e);
  };

  const sortIcon = sortDir ? 'sort-amount-down' : 'sort-amount-down-alt';

  return (
    <>
      <div
        className={'dvm-tree-searchbox tw-flex tw-items-center'}
        style={{
          ...(style || {}),
          boxSizing: 'border-box',
        }}
      >
        <div>
          <NwTooltip content={<div>{gettext('Alphabetical Order')}</div>}>
            <NwIconButton
              library='fa-solid'
              name={sortIcon}
              onClick={handleSort}
              onKeyDown={handleOnKeyDown}
              label={gettext('Alphabetical Order')}
            ></NwIconButton>
          </NwTooltip>
        </div>
        <div className='tw-grow'>
          <NwInput
            type={'text'}
            className={'dvm-search'}
            placeholder={gettext('Search...')}
            onChange={(event) => onSearchText(event.target.value)}
            suffix={<NwIcon name='search'></NwIcon>}
          ></NwInput>
        </div>
        {!!suffix && suffix}
      </div>
    </>
  );
};

export default TreeSearchBox;
