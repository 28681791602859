import { findIndexInArrayByKey } from 'kit-array';
import { fiStore, fiAdomSyntax, fiStoreAdomLocalConfig } from 'fistore';
import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { send_device_proxy_data } from 'ra_device_util';
import { selectedTmpl } from 'fi-dvm';
import { isFunction } from 'lodash';
const { fetchAdomSyntax } = fiAdomSyntax;

const createTreeNode = (obj, parent) => {
  const treeNode = {};
  // for automation team: gui-team-test-request/-/issues/576
  treeNode['id'] = parent.length
    ? parent.join('->') + '->' + obj.nodeName
    : obj.nodeName;
  treeNode['children'] = [];
  treeNode['fiParent'] = parent;
  treeNode['name'] = obj.nodeName;
  return treeNode;
};

export const parseSyntaxObject = (syntaxObj, filterFn) => {
  const parsedTreeData = [];

  // loop through each key in oData
  for (const prop in syntaxObj) {
    if (isFunction(filterFn) && !filterFn(prop, syntaxObj)) continue; // filter unwanted objects
    const treeNodeArr = prop.split(' ');
    let currentFmlArr = parsedTreeData; // for children purpose
    let parentArr = [];
    for (let i = 0; i < treeNodeArr.length; i++) {
      const nodeName = treeNodeArr[i];
      // search if nodeName is in current family array (same level)
      const index = findIndexInArrayByKey(currentFmlArr, 'name', nodeName);
      if (index < 0) {
        // create a new family record
        const nodeObj = createTreeNode(
          {
            nodeName: nodeName,
          },
          parentArr
        );
        currentFmlArr.push(nodeObj);
        currentFmlArr = nodeObj.children;
      } else {
        // already existed
        const familyNode = currentFmlArr[index];
        currentFmlArr = familyNode.children;
      }
      const parentArrCpy = [...parentArr];
      //cloneDeep(parentArr, parentArrCpy);
      parentArr = parentArrCpy;
      parentArr.push(nodeName);
    }
  }
  return parsedTreeData;
};

const loadDeviceSyntaxTree = (filterFn, device) => {
  if (!device) {
    const deviceTree = fiStoreAdomLocalConfig.getDeviceTree(fiStore.getState());
    const vdom = !deviceTree.vdomName ? null : deviceTree.vdomName;
    device = {
      devName: deviceTree.deviceName,
      vdomName: vdom,
    };
  }
  const syntax = true;
  return send_device_proxy_data(device.devName, device.vdomName, syntax).then(
    function (oData) {
      return parseSyntaxObject(oData.data, filterFn);
    }
  );
};

const loadAdomSyntaxTree = async (filterFn) => {
  return fiStore
    .dispatch(fetchAdomSyntax())
    .unwrap()
    .then((syntax) => {
      return parseSyntaxObject(syntax, filterFn);
    });
};

const loadSysTemplTree = (filterFn, tmpl) => {
  const _selectedTmpl = tmpl || selectedTmpl();
  return getPrvtmpCLITree(_selectedTmpl).then((treeSyntax) => {
    return parseSyntaxObject(treeSyntax, filterFn);
  });
};

const cli_cached_data = {
  adomName: '',
  oDataSyntax: null,
};

const getPrvtmpCLITree = function () {
  const adomName = fiAdom.current().name;
  if (adomName === cli_cached_data.adomName)
    return Promise.resolve(cli_cached_data.oDataSyntax).then((res) => res);
  const req = {
    method: 'get',
    params: [
      {
        url: `pm/config/adom/${adomName}/devprof`,
        option: 'syntax',
      },
    ],
  };
  return fiFmgHttp.forward(req).then(function (resp) {
    // cache response data
    cli_cached_data.adomName = adomName;
    cli_cached_data.oDataSyntax = resp[0].data;
    return resp[0].data;
  });
};

export const fiSyntaxTreeUtil = {
  loadDeviceSyntaxTree,
  loadAdomSyntaxTree,
  loadSysTemplTree,
  getPrvtmpCLITree,
};
