import { mapValues } from 'lodash';
import cn from 'classnames';
import license from './license';
import sys from './sys';
import dvm from './dvm';
import log from './log';
import outbreak from './outbreak';
import socfabric from './socfabric';

const CLASS_BY_SERVERITY = {
  [MACROS.NOTICE.NOTICE_LEVEL_CRITICAL]: 'tw-text-danger',
  [MACROS.NOTICE.NOTICE_LEVEL_ERROR]: 'tw-text-warning',
  [MACROS.NOTICE.NOTICE_LEVEL_WARNING]: 'tw-text-warning-200',
  [MACROS.NOTICE.NOTICE_LEVEL_NOTIFICATION]: 'tw-text-info',
  [MACROS.NOTICE.NOTICE_LEVEL_INFORMATION]: 'tw-text-success',
};

const attachGroup = (group, notifs) => {
  return mapValues(notifs, (notif) => ({ ...notif, group }));
};

const notifs = {
  ...attachGroup('license', license),
  ...attachGroup('sys', sys),
  ...attachGroup('dvm', dvm),
  ...attachGroup('log', log),
  ...attachGroup('outbreak', outbreak),
  ...attachGroup('socfabric', socfabric),
};

/**
 * @typedef {Object} NotifType
 * @property {string=} group The group id for grouping the messages together and
 *                          separated by a divider.
 * @property {string} icon Icon name
 * @property {string} iconLib Icon library name
 * @property {string} iconClass Icon class
 * @property {string|function} message The content of the notification.
 * @property {function} exec The action to execute when notification is clicked
 * @property {number} adomOid The ADOM oid.
 * @property {number} severity The notification severity
 */
export default mapValues(notifs, (notif) => {
  return {
    ...notif,
    icon: 'exclamation-circle',
    iconLib: 'fa-solid',
    iconClass: ({ severity }) =>
      cn(
        'tw-mr-2',
        CLASS_BY_SERVERITY[severity || MACROS.NOTICE.NOTICE_LEVEL_NOTIFICATION]
      ),
  };
});
