import { useEffect, useState } from 'react';
import cn from 'classnames';

// From VMenu in ProLayout
const resizer = ({
  eventPos,
  nodeSize,
  className,
}: {
  eventPos: (e: React.MouseEvent<HTMLElement> | MouseEvent) => number;
  nodeSize: (e: HTMLElement) => number;
  className: (p: { resizable: boolean }) => Record<string, unknown>;
}) => {
  return function <TE extends HTMLElement>(
    containerRef: React.MutableRefObject<TE | null>,
    {
      initSize,
      size: inSize,
      min,
      max,
      onDrag,
      onDragStop,
      resizable = true,
    }: {
      initSize?: number;
      size?: number;
      min: number;
      max: number;
      onDrag?: (e: MouseEvent, size: number) => void;
      onDragStop?: (e: MouseEvent, size: number) => void;
      resizable?: boolean;
    }
  ) {
    const getLimitedSize = (size: number = 0) =>
      Math.min(Math.max(min, size), max);

    const [size, setSize] = useState(() => getLimitedSize(initSize));
    useEffect(() => {
      if (inSize != null && inSize !== size) {
        setSize(getLimitedSize(inSize));
      }
    }, [inSize]);

    const [resizerActive, setResizerActive] = useState(false);

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!resizable) return;

      const el = containerRef?.current;
      if (!el) return;

      setResizerActive(true);
      el.style.transitionDuration = '0ms';
      const startPos = eventPos(e);
      const startSize = size;

      const doDrag = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const newSize = startSize + (eventPos(e) - startPos);
        const limitedSize = getLimitedSize(newSize);
        onDrag && onDrag(e, limitedSize);
        setSize(limitedSize);
      };

      const stopDrag = (e: MouseEvent) => {
        setResizerActive(false);
        if (el) {
          el.style.transitionDuration = '400ms';
          const newSize = nodeSize(el);
          onDragStop && onDragStop(e, newSize);
        }
        document.documentElement.removeEventListener(
          'mousemove',
          doDrag,
          false
        );
        document.documentElement.removeEventListener(
          'mouseup',
          stopDrag,
          false
        );
      };

      document.documentElement.addEventListener('mousemove', doDrag, false);
      document.documentElement.addEventListener('mouseup', stopDrag, false);
    };

    const renderResizeHandle = () => {
      return (
        <div
          className={cn(
            'tw-absolute tw-select-none',
            {
              'tw-bg-primary': resizerActive,
            },
            className({ resizable })
          )}
          onMouseDown={onMouseDown}
        ></div>
      );
    };

    return {
      size,
      resizerActive,
      onMouseDown,
      renderResizeHandle,
    };
  };
};

export const useHeightResizer = resizer({
  eventPos: (e) => e.clientY,
  nodeSize: (el) => parseFloat(window.getComputedStyle(el).height),
  className: ({ resizable }) => ({
    'tw-w-full tw-bottom-0 tw-left-0': true,
    'tw-h-1 tw-cursor-ns-resize': resizable,
  }),
});

export const useWidthResizer = resizer({
  eventPos: (e) => e.clientX,
  nodeSize: (el) => parseFloat(window.getComputedStyle(el).width),
  className: ({ resizable }) => ({
    'tw-h-full tw-right-0 tw-top-0': true,
    'tw-w-1 tw-cursor-ew-resize': resizable,
  }),
});
