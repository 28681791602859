// don't define `children` in child routes
import { RenderErrorBoundary } from 'react_apps/components/main/RenderErrorBoundary';

export default {
  path: 'fortiai',
  RenderErrorBoundary,
  lazy: () =>
    import(
      /* webpackChunkName: "mod-dashboard" */ 'react_apps/ra_ai_shared/frontpage/FortiAiCoverPage'
    ),
  handle: {
    appUniKey: 'fortiai',
  },
};
