import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setIgnoreError, heartbeat } from 'fistore/auth/slice';

// the default time needed to wait before system start rejecting requests
const SYS_RESTART_TIMER = 10000;

export function useRestartTimer(timer = SYS_RESTART_TIMER) {
  const dispatch = useDispatch();
  return useCallback(() => {
    // do not return a promise here, might be used in useEffect
    // the server will redirect automatically because heatbeat will fail
    dispatch(setIgnoreError());
    setTimeout(() => {
      // This is used in new VM license upload.
      // Use the heartbeat to detect system restart.
      // In other cases, if heartbeat is already started, it won't start twice.
      dispatch(heartbeat());
    }, timer);
  }, [dispatch]);
}
