import React, { useCallback } from 'react';
import { fiStore, fiStoreFabric } from 'fistore';
import { fiHttpGet, fiHttpPost } from 'fi-http';
import { Formik, FormikConsumer } from 'formik';
import { FmkInput, FmkErrorSpan } from 'rc_form';
import { ProForm } from '@fafm/neowise-pro';
import { NwButton } from '@fafm/neowise-core';
const { Header, Body, Footer, Section, Row, Column } = ProForm;

const getAutoId = (name) => `custom-view-rename-modal:${name.toLowerCase()}`;

export const Rename = ({ $opener, menuItem }) => {
  const initialValues = {
    name: menuItem?.stateParams?.customViewName || '',
  };
  const validate = useCallback((values) => {
    let errors = {};
    if (!values['name']) {
      errors.name = gettext('Name is required.');
    }
    return errors;
  }, []);

  const renameCustom = (values, setErrors) => {
    let newName = encodeURIComponent(values['name']);
    fiHttpGet('/p/fabric/customview/chkdup/', {
      params: {
        table: 'custom-view',
        name: newName,
      },
    }).then((resp) => {
      if (resp.isDup) {
        setErrors({ name: gettext('Name exist.') });
      } else {
        fiHttpPost('/p/fabric/customview/rename/', {
          view_json: {
            uuid: menuItem?.stateParams?.customViewId,
            name: newName,
          },
        }).then(
          (resp) => {
            if (
              resp &&
              resp.data &&
              ((resp.data.status && resp.data.status.code === 0) ||
                (resp.status && resp.status.code === 0))
            ) {
              fiStore.dispatch(fiStoreFabric.fetchFabricCustomMenu());
              $opener.resolve();
            }
          },
          (err) => {
            if (err && err.data) {
              let msg;
              if (err.data.msg) {
                msg = err.data.msg;
              } else if (err.data.data) {
                msg = err.data.data.msg;
              }
              if (msg) {
                setErrors({ name: msg });
              }
            }
          }
        );
      }
    });
  };

  const cancel = () => {
    $opener.reject();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, { setErrors }) => renameCustom(values, setErrors)}
      validate={validate}
    >
      <Header>{gettext('Rename Custom View')}</Header>
      <Body>
        <Section>
          <Row label={gettext('Name')}>
            <Column>
              <FmkInput
                automation-id={getAutoId('name')}
                name='name'
                required
              />
              <FmkErrorSpan name='name' />
            </Column>
          </Row>
        </Section>
      </Body>
      <Footer>
        <FormikConsumer>
          {(formik) => (
            <NwButton
              type='primary'
              automation-id={getAutoId('ok-btn')}
              onClick={formik.submitForm}
            >
              {gettext('OK')}
            </NwButton>
          )}
        </FormikConsumer>
        <NwButton onClick={cancel} automation-id={getAutoId('cancel-btn')}>
          {gettext('Cancel')}
        </NwButton>
      </Footer>
    </Formik>
  );
};
