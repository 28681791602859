import { useField, useFormikContextSelector } from 'formik';
import {
  validIp4,
  validIp6,
  toNetmaskString,
  toNetmaskNumber,
  validIp6Net,
  validIP,
} from 'fiutil';
import { callAll, validateAny } from './util/util';
import { useValidateFn } from './util/hook';

import { NwInput } from '@fafm/neowise-core';
import {
  ipV4NetmaskValidator,
  strictSubnetValidator,
} from './FmkIpV4NetmaskInput';

/**
 * @deprecated Use FmkIpV4Input | FmkIpV6Input | FmkIpV4NetmaskInput | FmkIpV6PrefixInput | FmkIpV4V6Input | FmkIpV4MultiInput | FmkNetmaskInput | FmkIpV4NetmaskSubnetInput
 */
/**
 * @param {{
 * type: 'ipv4' | 'ipv6' | 'ipv4-netmask' | 'ipv6-netmask' | 'ip' | 'multi_ipv4'
 * validate: undefined|(value:string)=>void|string|Promise<string>
 * autoFillEmptyValue: boolean
 * }} param0
 */
export const FmkIpInput = ({
  type,
  name,
  'automation-id': propAutoId,
  automationId,
  validate,
  autoFillEmptyValue = true, // auto set field value if input box is empty and user unfocus this input
  autoFillIpValue = ['0.0.0.0', '0.0.0.0'],
  children,
  wildcard, // wildcard mode enabled if true
  max_argv = undefined,
  ...rest
}) => {
  const generalValidate = useValidateFn(rest);
  const typeFns = getIpTypeFns(
    type,
    autoFillEmptyValue,
    autoFillIpValue,
    wildcard,
    max_argv
  );

  const submitCount = useFormikContextSelector((val) => val.submitCount);
  const [, { value, error, touched }, { setValue, setTouched }] = useField({
    name,
    validate: validateAny(validate, typeFns.validate, generalValidate),
  });

  const onBlur = (evt) => {
    const value = typeFns.toFormValue(evt.target.value);
    setTouched(true, false);
    setValue(value);
  };

  return (
    <NwInput
      name={name}
      value={typeFns.toDisplayStr(value)}
      invalid={error && (touched || !!submitCount)}
      automation-id={propAutoId || automationId}
      {...rest}
      onChange={callAll(onBlur, rest.onNwChange)}
      onlyReflectOnChange
    >
      {children}
    </NwInput>
  );
};

export function getIpTypeFns(
  type,
  autoFillEmptyValue,
  autoFillIpValue,
  wildcard,
  max_argv,
  strictPrefix
) {
  switch (type) {
    case 'ipv4':
      return {
        validate: (value) => {
          if (!value || validIp4(value)) return;
          return gettext('Invalid IP address.');
        },
        toDisplayStr: doNothing,
        toFormValue: doNothing,
      };
    case 'ipv6':
      return {
        validate: (value) => {
          if (!value || value === '::/0' || validIp6(value)) return;
          return gettext('Invalid IPv6 address.');
        },
        toDisplayStr: doNothing,
        toFormValue: doNothing,
      };
    case 'ipv4-mask-only':
      return {
        validate: (value) => {
          if (!value || getValidNetMask(value)) return;
          return gettext('Invalid IP netmask.');
        },
        toDisplayStr: doNothing,
        toFormValue: (displayStr) => {
          if (typeof displayStr === 'string') {
            let trimStr = displayStr.trim();
            if (autoFillEmptyValue && !trimStr) {
              return autoFillIpValue;
            }
            const validNetmaskValue = getValidNetMask(trimStr);
            return validNetmaskValue || displayStr;
          }
          return displayStr;
        },
      };
    case 'ipv4-netmask':
      return {
        // [address, netmask]
        validate: validateAny(
          ...[ipV4NetmaskValidator, strictSubnetValidator].map((fn) =>
            fn({ wildcard, strictPrefix })
          )
        ),
        toDisplayStr: (value) => {
          if (Array.isArray(value) && value.length === 2) {
            return value[0] + '/' + value[1];
          }
          return value ? value : '';
        },
        toFormValue: (displayStr) => {
          if (typeof displayStr === 'string') {
            let trimStr = displayStr.trim();

            if (autoFillEmptyValue && !trimStr) {
              return autoFillIpValue;
            }

            const ip_and_netmask = trimStr ? trimStr.split('/') : [];
            if (!ip_and_netmask[1]) {
              ip_and_netmask[1] = '255.255.255.255';
            } else {
              const validNetmaskValue = getValidNetMask(ip_and_netmask[1]);
              if (validNetmaskValue) {
                ip_and_netmask[1] = validNetmaskValue;
              }
            }
            return ip_and_netmask;
          }
          return Array.isArray(displayStr) ? displayStr : [];
        },
      };
    case 'ipv6-netmask':
      return {
        validate: (value) => {
          if (!value || value === '::/0' || validIp6Net(value)) return;
          return gettext('Invalid IPv6 address.');
        },
        toDisplayStr: doNothing,
        toFormValue: doNothing,
      };
    case 'ip':
      return {
        validate: (value) => {
          if (!value || value === '::/0' || validIP(value)) return;
          return gettext('Invalid IP address.');
        },
        toDisplayStr: doNothing,
        toFormValue: doNothing,
      };
    case 'netmask':
      return {
        validate: (value) => {
          if (!getValidNetMask(value)) return gettext('Invalid IP netmask.');
        },
        toDisplayStr: doNothing,
        toFormValue: doNothing,
      };
    case 'multi_ipv4': {
      const seperators = [',', ' '];

      return {
        validate: (value) => {
          const ips = toArray(value);
          const isValid = ips.every((ip) => {
            return !ip || validIp4(ip);
          });

          if (!isValid) {
            return gettext('Invalid IP address.');
          }

          if (max_argv && ips.length > max_argv) {
            return ngettext(
              'Exceeded max number of %s IP address',
              'Exceeded max number of %s IP addresses',
              max_argv
            ).printf([max_argv]);
          }
        },
        toDisplayStr: (value) => {
          return Array.isArray(value) ? value.join(seperators[0]) : value || '';
        },
        toFormValue: (value) => {
          let ips = toArray(value);

          for (let seperator of seperators) {
            const regex = new RegExp(`\\${seperator}+`);
            ips = ips
              .map((ip) => {
                return ip.split(regex);
              })
              .flat();
          }

          return ips.map((ip) => ip.trim()).filter(Boolean);
        },
      };
    }
    default:
      return {
        validate: undefined,
        toDisplayStr: doNothing,
        toFormValue: doNothing,
      };
  }
}

function toArray(value) {
  return Array.isArray(value) ? value : [value];
}

function doNothing(val) {
  return val || '';
}

function getValidNetMask(netmask) {
  if (!netmask || typeof netmask !== 'string') {
    return false;
  }
  // if netmask is a integer, convert to 255.x.x.x
  if (!isNaN(netmask)) {
    return toNetmaskString(netmask);
  } else {
    if (toNetmaskNumber(netmask) >= 0) {
      return netmask;
    }
  }

  return false;
}
