import { createPromiseAction } from '../utils/saga-promise';
import { createAction } from '@reduxjs/toolkit';

const actionName = (name) => 'FABRIC_' + name;

export const fetchFabricCustomMenu = createPromiseAction(
  actionName('FETCH_FABRIC_CUSTOM_MENU')
);

export const setFabricCustomMenu = createAction(
  actionName('SET_FABRIC_CUSTOM_MENU')
);
