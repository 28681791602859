import React, { useEffect, useMemo } from 'react';
import { ProDashboard } from '@fafm/neowise-pro';
import { NwIconButton } from '@fafm/neowise-core';

export const useWidgetRefreshBtn = (onRefreshClick) => {
  const { setWidgetToolbarItems } = ProDashboard.useDashWidget();
  const getWidgetToolbarItems = useMemo(() => {
    return [
      {
        key: 'refresh',
        render: () => (
          <div
            className='tw-flex tw-items-center tw-h-full'
            onClick={onRefreshClick}
          >
            <NwIconButton
              library='fafm'
              name='refresh'
              label={gettext('refresh')}
            ></NwIconButton>
          </div>
        ),
      },
    ];
  }, [onRefreshClick]);

  useEffect(() => {
    setWidgetToolbarItems(getWidgetToolbarItems);
  }, [getWidgetToolbarItems]);
};

export const useWidgetSettingBtn = (onSettingBtnClick) => {
  const { setOnSettingsClickFn } = ProDashboard.useDashWidget();

  useEffect(() => {
    if (typeof onSettingBtnClick === 'function') {
      setOnSettingsClickFn(() => onSettingBtnClick);
    }
  }, [onSettingBtnClick]);
};

export const useWidgetToolbarItems = (toolbarItems = []) => {
  const { setWidgetToolbarItems } = ProDashboard.useDashWidget();
  useEffect(() => {
    setWidgetToolbarItems(toolbarItems);
  }, [toolbarItems]);
};

export const useWidgetLoading = ({ isLoading, dependencies = [] }) => {
  const { setIsLoading } = ProDashboard.useDashWidget();
  useEffect(() => {
    if (isLoading) setIsLoading(true);
    else setIsLoading(false);
  }, [isLoading, ...dependencies]);
};
