export const isVMModel = (model) => {
  const VM_PLATFORM = /VM64|ARM64/;
  return VM_PLATFORM.test(model);
};

export const getFirmwareImageTypeByOpt = (opt) => {
  const MATURE_BIT = MACROS.DVM.DVM_OPT_IMG_TYPE_MATURE;
  const FEATURE_BIT = MACROS.DVM.DVM_OPT_IMG_TYPE_FEATURE;

  const testBit = (val, bitValue) => {
    return (val & bitValue) !== 0;
  };

  if (testBit(opt, MATURE_BIT)) return 'M';
  else if (testBit(opt, FEATURE_BIT)) return 'F';
  return 'NA';
};

export const getFirmwareSuffixByType = (type = '') => {
  const firmwareTypeMap = {
    NA: '',
    F: gettext('Feature'),
    M: gettext('Mature'),
  };

  return firmwareTypeMap[type] ? `(${firmwareTypeMap[type]})` : '';
};

export const getFirmwareWithType = (firmwareStr, firmwareType) => {
  if (!firmwareStr) return '';
  return `${firmwareStr} ${getFirmwareSuffixByType(firmwareType)}`;
};

export const getFirmwareWithTypeByOpt = (firmwareStr, opt) => {
  const suffix = getFirmwareSuffixByType(getFirmwareImageTypeByOpt(opt));
  return `${firmwareStr} ${suffix}`;
};
