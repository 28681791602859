import { pick, isNumber, last, isNil } from 'lodash';

import { isFaz } from 'fi-web/fi-session/sys_config';
import { dispatch, fiStore, fiStoreAdomLocalConfig } from 'fistore';
import { go } from 'fistore/routing/slice';
import { ProToolkit } from '@fafm/neowise-pro';

export const DVM_DEVICE_LIST_STATE = '/dvm/main/groups';
export const FAZ_DVM_DEVICE_LIST_STATE = '/dvm/main/fazgroups';
export const DVM_CHASSIS_STATE = '/dvm/chassis';
const DVM_CONFIG_STATE = '/dvm/main/cfg';

function goToDefaultGroup(grps) {
  if (!grps || !Array.isArray(grps)) {
    throw 'no groups';
  }
  const hasManagedGroup = grps.some(
    (grp) => grp.oid === MACROS.DVM.DVM_GRP_MANAGED_OID
  );
  const groupId = hasManagedGroup
    ? MACROS.DVM.DVM_GRP_MANAGED_OID
    : grps[0]?.oid || MACROS.DVM.DVM_GRP_MANAGED_OID;
  return goDvmDeviceListByGroup({ groupId: groupId });
}

function goDvmDeviceList(options) {
  const deviceGroupPath = getDeviceTreeState()?.deviceGroupPath;
  const params = deviceGroupPath
    ? deviceGroupPath
    : { groupId: `${MACROS.DVM.DVM_GRP_MANAGED_OID}` }; // Use default if $state.params is does not have groupId
  return goDvmDeviceListByGroup(params, options);
}

function goSdwanNetworkDeviceList(options) {
  const to = '/sdwan/network/devices';
  return dispatch(go({ to, opts: options }));
}

function goDvmManagedGroup(options) {
  return goDvmDeviceListByGroup(
    { groupId: MACROS.DVM.DVM_GRP_MANAGED_OID },
    options
  );
}

function goDvmUnregisteredGroup(options) {
  return goDvmDeviceListByGroup(
    { groupId: MACROS.DVM.DVM_GRP_UNREG_OID },
    options
  );
}

function getDeviceTreeState() {
  return fiStoreAdomLocalConfig.getDeviceTree(fiStore.getState());
}

function updateDeviceTreeState(params) {
  const picked = pick(params, [
    'deviceGroupPath',
    'deviceOid',
    'vdomOid',
    'deviceName',
    'vdomName',
  ]);
  fiStore.dispatch(fiStoreAdomLocalConfig.setDeviceTree(picked));
}

function makeHandlePrevDeviceState() {
  const prevDeviceState = getDeviceTreeState();

  return {
    revert: () => {
      updateDeviceTreeState(prevDeviceState);
    },
  };
}

async function goDvmDeviceListByGroup(params, options) {
  const handle = makeHandlePrevDeviceState();
  const state = isFaz() ? FAZ_DVM_DEVICE_LIST_STATE : DVM_DEVICE_LIST_STATE;
  try {
    const groupId = params.groupId || MACROS.DVM.DVM_GRP_MANAGED_OID;
    updateDeviceTreeState({
      deviceGroupPath: `${groupId}`,
      deviceOid: '',
      vdomOid: '',
    });
    const groupPath = getDevGrpId(groupId);
    const to = `${state}/${groupPath}`;
    dispatch(go({ to, opts: options }));
  } catch (error) {
    handle.revert();
  }
}

async function goDvmConfig(params, options, config) {
  const handle = makeHandlePrevDeviceState();
  const {
    groupId,
    deviceName,
    deviceId,
    vdomName,
    vdomId,
    cfggroup,
    cfgitem,
    sdwan,
  } = params;
  const { updateStore = true } = config || {};
  try {
    if (updateStore) {
      updateDeviceTreeState({
        deviceGroupPath: groupId,
        deviceOid: deviceId,
        vdomOid: vdomId,
        deviceName,
        vdomName,
      });
    }
    if (!deviceId) {
      if (MACROS.SYS.CONFIG_DEBUG) {
        console.error('fiDvmStateService.goDvmConfig: Missing device oid');
      }
      return;
    }

    /** ----------------------------------------------------------------------------
     * TODO: Update path:
     * update vdomName to reflect if it is for
     * - vdom enabled: global or actual vdom name (right now both global and root show as root in path)
     * - vdom disabled: root
     * -------------------------------------------------------------------------- */
    if (sdwan) {
      const { DvmConfigMain } = await import(
        'react_apps/ra_dvm_config/config_main/DvmConfigMain'
      );
      return ProToolkit.openDrawer(<DvmConfigMain params={params} />, {
        size: 'full',
      }).result;
    }

    let path = `${DVM_CONFIG_STATE}/${deviceId}/${vdomName || 'root'}`;
    if (cfggroup && cfgitem) {
      path = `${path}/${cfggroup}/${cfgitem}`;
    }
    dispatch(go({ to: path, opts: options }));
  } catch (error) {
    console.error(error);
    // revert to previous device state
    handle.revert();
  }
}

function goDvmChassisDefault(options) {
  return dispatch(go({ to: DVM_CHASSIS_STATE, opts: options }));
}

export function getDevGrpId(idstr) {
  if (isNil(idstr)) {
    return null;
  }
  idstr = isNumber(idstr) ? `${idstr}` : idstr;

  try {
    // Get last part of idstr
    const lastPart = last(idstr.split(':'));
    let grpId = parseInt(lastPart);
    grpId = isNaN(grpId) ? idstr : grpId;
    return grpId;
  } catch (error) {
    return null;
  }
}

export function parseDeviceTreeNode(node) {
  const { type, key, data } = node;
  const deviceTreeState = {
    deviceGroupPath: '',
    deviceOid: '',
    vdomOid: '',
    deviceName: '',
    vdomName: '',
  };

  const ids = key.split(':');
  if (type === 'grp') {
    deviceTreeState.deviceGroupPath = key;
  } else if (type === 'dev') {
    // Second last id is the device group id
    deviceTreeState.deviceGroupPath = ids.slice(0, -1).join(':');
    deviceTreeState.deviceOid = last(ids);
    deviceTreeState.deviceName = data.name || data._deviceName;
  } else if (type === 'vdom') {
    // Third last id is the device group id
    deviceTreeState.deviceGroupPath = ids.slice(0, -2).join(':');
    deviceTreeState.deviceOid = ids[ids.length - 2];
    deviceTreeState.vdomOid = last(ids);
    deviceTreeState.deviceName = data._deviceName;
    deviceTreeState.vdomName = data.vdom || data.name;
  }

  return deviceTreeState;
}

export const fiDvmStateService = {
  goDvmDeviceList,
  goDvmManagedGroup,
  goDvmUnregisteredGroup,
  goDvmDeviceListByGroup,
  goDvmChassisDefault,
  goToDefaultGroup,
  getDeviceTreeState,
  updateDeviceTreeState,
  goDvmConfig,
  goSdwanNetworkDeviceList,
};
