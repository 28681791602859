import { castArray, isNil, isUndefined, noop, forEach } from 'lodash';
import { getNextVerStr, upgradeAdoms } from '../adom_edit/api';
import { ADOM_TYPE_MAP } from '../adom_edit/util';
import { fiSysConfig, fiAdminProfile } from 'fi-session';
import { fiWorkspace } from 'fi-workspace';
import { openConfirmModal } from 'rc_layout';
import { fiMessageBox } from 'fi-messagebox';
import { openTaskModal } from 'rc_task_monitor';
import { genHighlighter } from 'kit-escape';

class Effect {
  constructor(cb) {
    this.shouldRun = true;
    this.callback = cb;
  }
  apply(...args) {
    if (this.shouldRun) {
      this.callback(...args);
    }
  }
  cancel() {
    this.shouldRun = false;
  }
}
export const getEffect = (cb) => {
  return new Effect(cb);
};

// Helper Functions
export const getLatest3Versions = (versions, onlyText = true) => {
  const nver = versions.length;
  const slicedVersions = versions.slice(nver > 3 ? nver - 3 : 0);
  return onlyText ? slicedVersions.map((v) => v.text) : slicedVersions;
};

export const initSupportedVersions = (sysConfig, version) => {
  // supported_adom_vers is ordered in asend, from earliest to latest
  const versions = (sysConfig.supported_adom_vers || []).map(({ ver, mr }) => ({
    ver,
    mr,
    text: `${ver}.${mr}`,
  }));

  if (isNil(version)) return versions;

  //add support old versions in Edit mode
  const found = versions.find(
    (v) => v.ver === version.ver && v.mr === version.mr
  );
  if (!found) {
    versions.unshift({
      ...version,
      text: `${version.ver}.${version.mr}`,
    });
  }
  return versions;
};

export const restrictedProdTypeToOsType = (restrictPrdType) =>
  ADOM_TYPE_MAP[restrictPrdType];

export const getAvailableVersionsByOsType = (sysConfig, osType) => {
  if (sysConfig.supported_adom_vers_by_os_type) {
    //may not have entries for some ostype
    return (
      sysConfig.supported_adom_vers_by_os_type[osType] ??
      sysConfig.supported_adom_vers
    );
  }
  return sysConfig.supported_adom_vers;
};

export const getNextVerStrByOsType = ({ ver, mr, osType, sysConfig }) => {
  if (ver == 4 && mr == 3) return '5.0'; //hard code 4.3 upgrade target
  if (isUndefined(osType)) return getNextVerStr(ver, mr);

  const availableVersions = getAvailableVersionsByOsType(sysConfig, osType);
  const currVerIdx = availableVersions.findIndex(
    (version) => version.ver === ver && version.mr === mr
  );
  const nextVer = availableVersions[currVerIdx + 1];
  if (!nextVer) return '';
  return `${nextVer.ver}.${nextVer.mr}`;
};

const canToggleAdomStatus = (selections, sysConfig) => {
  const _selections = castArray(selections).filter(Boolean);

  for (const adom of _selections) {
    const adomWsMode = adom.workspace_mode || adom._oData.workspace_mode;

    // workspace disabled, no adom_lock permission, or is ha slave
    // => not allow to change adom lock
    if (
      adomWsMode === 'disabled' ||
      !fiAdminProfile.hasRDWRPermitOn('adom_lock') ||
      fiSysConfig.isHaSlave(sysConfig)
    ) {
      return false;
    }
  }

  return true;
};

export const isSelectedAdomsLockToggleable = (
  selections,
  sysConfig = fiSysConfig.current()
) => {
  if (!selections.length) return false;
  const canChange = canToggleAdomStatus(selections, sysConfig);
  if (!canChange) return false;

  const canLock = castArray(selections).every((adom) => {
    const lock = adom.lock || adom.getData?.('lock') || {};
    return (
      lock.lock == MACROS.SYS.LOCK_STATE_UNLOCKED ||
      lock.lock == MACROS.SYS.LOCK_STATE_LOCKED ||
      !!adom.lock_override
    );
  }); //for locking multiple
  //can lock if locked by me (will be ignored), or unlocked, or allow override

  return canLock;
};

const canChangeAdomLockStatus = (selections, sysConfig) => {
  const _selections = castArray(selections).filter(Boolean);
  if (_selections.length !== 1) return null;

  const selectedAdom = _selections[0];
  const adomWsMode =
    selectedAdom.workspace_mode || selectedAdom._oData.workspace_mode;

  // workspace disabled, no adom_lock permission, or is ha slave
  // => not allow to change adom lock
  if (
    adomWsMode === 'disabled' ||
    !fiAdminProfile.hasRDWRPermitOn('adom_lock') ||
    fiSysConfig.isHaSlave(sysConfig)
  ) {
    return null;
  }

  return selectedAdom;
};

export const isSelectedAdomsLockable = (
  selections,
  sysConfig = fiSysConfig.current()
) => {
  const adom = canChangeAdomLockStatus(selections, sysConfig);
  if (!adom) return false;

  const lock = adom.lock || adom.getData?.('lock') || {};

  // case 0: current is unlock
  if (lock.lock == MACROS.SYS.LOCK_STATE_UNLOCKED) {
    return true;
  }
  // case 1: current is locked by me
  if (lock.lock == MACROS.SYS.LOCK_STATE_LOCKED) {
    return false;
  }

  // case 2: current is locked by other, and lock-preempt is enabled
  return !!adom.lock_override;
};

export const isSelectedAdomsUnlockable = (
  selections,
  sysConfig = fiSysConfig.current()
) => {
  const adom = canChangeAdomLockStatus(selections, sysConfig);
  if (!adom) return false;

  const lock = adom.lock || adom.getData?.('lock') || {};

  // case 0: current is unlocked
  if (lock.lock == MACROS.SYS.LOCK_STATE_UNLOCKED) {
    return false;
  }

  // case 1: current is locked by me
  if (lock.lock == MACROS.SYS.LOCK_STATE_LOCKED) {
    return true;
  }

  // case 2: current is locked by others
  return !!adom.lock_override;
};

const getDirtyAdoms = (adoms) => {
  if (fiWorkspace.isWorkspaceDisabled()) return [];

  const dirtyAdoms = adoms.reduce((dirty, adom) => {
    const adomInfo = fiWorkspace.adomInfo(adom);
    if (adomInfo.hasDirty()) dirty.push(adom.name);
    return dirty;
  }, []);

  if (dirtyAdoms.length) {
    const msg = ngettext(
      'ADOM %s has unsaved changes.',
      'ADOMs %s have unsaved changes.',
      dirtyAdoms.length
    ).printf([dirtyAdoms.join(', ')]);

    fiMessageBox.show(msg, 'danger');
  }

  return dirtyAdoms;
};

export const upgradeSelectedAdoms = (selections, callback = noop) => {
  const _selections = castArray(selections);

  if (getDirtyAdoms(_selections).length > 0) return;

  const {
    version: { ver, mr },
    _gui_nextVer: nextVer,
  } = _selections[0];
  if (
    _selections.some(
      ({ version, _gui_nextVer }) =>
        version.ver !== ver || version.mr !== mr || _gui_nextVer !== nextVer
    )
  ) {
    fiMessageBox.show(gettext('ADOMs are not in the same version'), 'danger');
    return;
  }

  const adomNames = _selections.map((adom) => `"${adom.name}"`);
  const title = gettext('Upgrade ADOM');
  const content = ngettext(
    'Do you want to upgrade ADOM %(names)s from version %(origVer)s to %(newVer)s?',
    'Do you want to upgrade ADOMs %(names)s from version %(origVer)s to %(newVer)s?',
    adomNames.length
  ).printfd({
    names: adomNames.join(', '),
    origVer: `${ver}.${mr}`,
    newVer: nextVer,
  });

  return openConfirmModal({
    title,
    content,
  }).then(() =>
    upgradeAdoms(_selections)
      .then(async (resp) => {
        if (resp[0].status.code === 0) {
          const respData = resp[0].data;
          const taskid = Array.isArray(respData)
            ? respData?.find((data) => data.task)?.task
            : respData.task;
          if (taskid) {
            return openTaskModal({
              title,
              taskid,
              onTaskDone: callback,
              autoCloseOnSuccess: true,
            }).catch(callback);
          } else {
            return Promise.reject({ message: resp[0].data?.[0].message });
          }
        } else {
          return Promise.reject({ message: resp[0].status.message });
        }
      })
      .catch((err) => {
        const msg =
          err?.data?.result?.[0]?.status?.message ??
          gettext('Failed to upgrade ADOM.');
        fiMessageBox.show(msg, 'danger');
        return Promise.reject();
      })
  );
};

export function highlightEl(term) {
  const hiliter = genHighlighter(term);
  // this fn changes the element
  return function traveler(el) {
    if (el?.children?.length) {
      forEach(el.children, traveler);
      return;
    }
    const newText = hiliter(el.textContent);
    el.innerHTML = newText;
  };
}
