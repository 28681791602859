import { useRef, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';

export function useDeepEqual(value) {
  const ref = useRef([]);

  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepEqualEffect(effect, deps) {
  return useEffect(effect, useDeepEqual(deps));
}

export function useDeepEqualCallback(callback, deps) {
  return useCallback(callback, useDeepEqual(deps));
}

export function useDeepEqualMemo(factory, deps) {
  return useMemo(factory, useDeepEqual(deps));
}
