import {
  createFetchAction,
  createPromiseAction,
  createRecordAction,
} from '../../utils';

export const remoteFAZAction = {
  ...createFetchAction('remotefaz'),
  ...createRecordAction('remotefaz'),
};

export const fetchRemoteFAZAction = createPromiseAction(
  'remotefaz/FETCH_REMOTE_FAZ'
);
