import { createSelector } from '@reduxjs/toolkit';
import { hasRemoteFAZ } from '../session/adom/selectors';
import { getSysDisableModule } from '../session/sysConfig/selectors';

export function getDisableModule(state) {
  return state?.fmgRemoteFaz?.data?.global?.['disable-module'];
}

const FEATURE_TO_MASK = {
  'fortiview-noc': MACROS.SYS.DIS_MODULE_FVNOC,
  siem: MACROS.SYS.DIS_MODULE_SIEM,
  soar: MACROS.SYS.DIS_MODULE_SOAR,
  'ot-view': MACROS.SYS.DIS_MODULE_OTVIEW,
};

if (MACROS.SYS.CONFIG_DEBUG) {
  const total = Object.keys(FEATURE_TO_MASK).reduce(function (ret, key) {
    return ret + (FEATURE_TO_MASK[key] || 0);
  }, 0);
  if (total !== MACROS.SYS.DIS_MODULE_ALL) {
    // If you see this error then we are missing some Module Disable
    // implementation in GUI.
    console.error('MACROS.SYS.DIS_MODULE_ALL does not match know values.');
  }
}

export function isModuleDisabled(state, modName) {
  const storedValue = getDisableModule(state);
  if (!Number.isFinite(storedValue)) return true; // we don't know, so assume it's disabled.
  const mask = FEATURE_TO_MASK[modName];
  if (typeof mask === 'undefined') return true; // unknown mod, assume it's disabled.
  return !!(storedValue & mask); // !! as boolean.
}

// this is VM license, but raw data called it contract...
export function getAllContracts(state) {
  return state?.fmgRemoteFaz?.data?.contracts?.contract;
  // array, example:
  // [{
  //   "account": "x@example.com",
  //   "company": "Example",
  //   "industry": "Technology",
  //   "rawdata":
  //   "AccountID=x@example.com|Industry=Technology|Company=Example|UserID=111",
  //   "serial": "FAZ-VMTMxxxxxxxx"
  // }]
}

export function getAllFeatures(state) {
  return state?.fmgRemoteFaz?.data?.features;
  // obj, with mask, raw, validDict, validList.
}

export function isFeatureLicenseValid(state, featureCode) {
  return getAllFeatures(state)?.validDict?.[featureCode];
}

// This is the "feature" license, where a valid vm may or may not have.
export function getAllLicenses(state) {
  return state?.fmgRemoteFaz?.data?.features?.raw?.data;
  // array, example:
  // [
  //   { "license-name": "PBDS", "license-state": "valid", "expiration": "2023-09-29", },
  //   { "license-name": "SCPC", "license-state": "N/A", "expiration": "N/A" },
  //   { "license-name": "SOAR", "license-state": "valid", "expiration": "2023-09-29", },
  //   { "license-name": "FOAS", "license-state": "N/A", "expiration": "N/A" },
  //   { "license-name": "ISSS", "license-state": "N/A", "expiration": "N/A" },
  //   { "license-name": "FGSA", "license-state": "N/A", "expiration": "N/A" }
  // ]
}

export function getLicense(state, licenseName) {
  const arr = getAllLicenses(state);
  if (Array.isArray(arr)) {
    return arr.find((x) => x?.['license-name'] === licenseName);
  }
}

export const makeCheckerisModuleDisabled = (name) =>
  createSelector(getSysDisableModule, getDisableModule, (sysDm, rmtDm) => {
    let isLocal = !hasRemoteFAZ;
    const dm = isLocal ? sysDm : rmtDm;
    const mask = FEATURE_TO_MASK[name];
    if (typeof mask === 'undefined') {
      return true;
    } else {
      return !!(dm & mask);
    }
  });

export const isSoarDisabled = makeCheckerisModuleDisabled('soar');
export const isSiemDisabled = makeCheckerisModuleDisabled('siem');
export const isFortiviewDisabled = makeCheckerisModuleDisabled('fortiview-noc');
