import React from 'react';
import { useSupportedAdomVersions } from '../common/hook';

// Components
import { Formik } from 'formik';
import {
  NwProHeader,
  NwProBody,
  NwProSection,
  NwProInputRow,
  NwProFooter,
  OkBtn,
  CancelBtn,
} from 'rc_layout';
import { FmkRadioBtnGroup } from 'rc_form';

// Utils
import { fiSysConfig } from 'fi-session';
import * as fiUUID from 'fi-uuid';
import { fiMessageBox } from 'fi-messagebox';
import { getLatest3Versions } from '../common/utils';
import { resetAdom } from '../adom_edit/api';
import { openTaskByWebSocketMonitor } from 'rc_task_monitor';

const getAutoId = (field) => `reset_adom:${field}`;
const getFirmwareText = (version) => {
  if (!version) return '';
  if (version.text) return version.text;
  const { ver, mr } = version;
  return [ver, mr].join('.');
};

export const ResetAdom = ({
  $opener,
  title = gettext('Reset Global ADOM'),
  adom = {},
}) => {
  const [versions] = useSupportedAdomVersions({
    sysConfig: fiSysConfig.current(),
    version: adom.version,
    parser: (supportedVersions) => {
      return getLatest3Versions(supportedVersions, false).map((ver) => ({
        ...ver,
        id: ver.text,
      }));
    },
  });

  return (
    <Formik
      initialValues={{ ...adom, _version: getFirmwareText(adom?.version) }}
      onSubmit={async (values) => {
        try {
          const tid = fiUUID.gen();
          const resp = resetAdom({ tid, adomRes: values });
          await openTaskByWebSocketMonitor(tid, {
            title: gettext('Resetting Global ADOM'),
          });
          $opener.resolve(resp);
        } catch (err) {
          console.error(err);
          fiMessageBox.show(
            gettext('Successfully reset global ADOM', 'danger')
          );
        }
      }}
      enableReinitialize
    >
      {({ submitForm, setFieldValue, isSubmitting }) => (
        <>
          <NwProHeader>{title}</NwProHeader>

          <NwProBody>
            <NwProSection>
              <NwProInputRow label={gettext('Version')}>
                <FmkRadioBtnGroup
                  name={'_version'}
                  automationId={getAutoId('version')}
                  choices={versions}
                  onChange={(opt) => {
                    setFieldValue('version', opt);
                  }}
                />
              </NwProInputRow>
            </NwProSection>
          </NwProBody>

          <NwProFooter>
            <OkBtn
              automationId={getAutoId('ok_btn')}
              onClick={() => submitForm()}
              loading={isSubmitting}
            >
              {gettext('OK')}
            </OkBtn>

            <CancelBtn
              automationId={getAutoId('cancel_btn')}
              onClick={() => $opener.reject()}
            >
              {gettext('Cancel')}
            </CancelBtn>
          </NwProFooter>
        </>
      )}
    </Formik>
  );
};
