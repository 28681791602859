export function getDevTypeNameByOsType(ostype, singular = true) {
  let name = '';
  switch (ostype) {
    case MACROS.DVM.DVM_OS_TYPE_FOS:
      name = singular ? 'FortiGate' : 'FortiGates';
      break;
    case MACROS.DVM.DVM_OS_TYPE_FOC:
      name = singular ? 'FortiCarrier' : 'FortiCarriers';
      break;
    case MACROS.DVM.DVM_OS_TYPE_FFW:
      name = singular ? 'FortiFirewall' : 'FortiFirewalls';
      break;
    case MACROS.DVM.DVM_OS_TYPE_FWC:
      name = singular ? 'FortiFirewallCarrier' : 'FortiFirewallCarriers';
      break;
    case MACROS.DVM.DVM_OS_TYPE_FPX:
      name = singular ? 'FortiProxy' : 'FortiProxys';
      break;
    default:
      name = singular ? gettext('Device') : gettext('Devices');
      break;
  }
  return name;
}
