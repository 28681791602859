const themeColors = MACROS.CHART_THEMES['dark'];
const green = themeColors['bar-green'];
const red = themeColors['bar-red'];
const blue = themeColors['bar-blue'];
const lightblue = themeColors['bar-lightblue'];
const yellow = themeColors['bar-yellow'];
const orange = themeColors['bar-orange'];
const bytesRec = blue; // bytes received
const bytesSent = lightblue; // bytes sent

export const FtvColor = {
  critical: red,
  high: orange,
  medium: yellow,
  low: green,
  info: lightblue,
  elevated: blue,
  in: bytesRec,
  out: bytesSent,
  block: yellow,
  pass: blue,
  green: green,
  red: red,
  blue: blue,
  lightblue: lightblue,
  yellow: yellow,
  orange: orange,
};

export const theme = {
  LIGHT: 'faz-noc-light',
  BLUE: 'faz-noc-blue',
  DARK: 'faz-noc-dark',
};

// ['noraml','IOC', 'System', 'disabled'] when 'disabled', controll by widget itself
export const Time_Periods = {
  normal: {
    opts: [
      { num: 5, unit: 'min', value: '5min' },
      { num: 30, unit: 'min', value: '30min' },
      { num: 1, unit: 'hour', value: '1hour' },
      { num: 4, unit: 'hour', value: '4hour' },
      { num: 12, unit: 'hour', value: '12hour' },
      { num: 1, unit: 'day', value: '1day' },
      { num: 1, unit: 'week', value: '1week' },
      { lastN: 1, unit: 'hour', value: 'nhour' },
      { lastN: 1, unit: 'day', value: 'nday' },
      { lastN: 1, unit: 'week', value: 'nweek' },
      { start: '', end: '', unit: 'custom', value: 'custom' },
    ],
    default: { num: 1, unit: 'hour', value: '1hour' },
  },
  disabled: {
    opts: [],
    default: {},
  },
  ioc: {
    opts: [
      { num: 0, unit: 'day', value: '0day' },
      { num: -1, unit: 'day', value: '-1day' },
      { num: -2, unit: 'day', value: '-2day' },
      { num: -3, unit: 'day', value: '-3day' },
      { num: -4, unit: 'day', value: '-4day' },
      { num: -5, unit: 'day', value: '-5day' },
      { num: -6, unit: 'day', value: '-6day' },
      { num: -7, unit: 'day', value: '-7day' },
      { num: 7, unit: 'day', value: '7day' },
      { num: 30, unit: 'day', value: '30day' },
      { lastN: 1, unit: 'day', value: 'nday' },
      { start: '', end: '', unit: 'custom', value: 'custom' },
    ],
    default: { num: 0, unit: 'day', value: '0day' },
  },
  'daily-threat': {
    opts: [
      { num: 0, unit: 'day', value: '0day' },
      { num: -1, unit: 'day', value: '-1day' },
      { num: -2, unit: 'day', value: '-2day' },
      { num: -3, unit: 'day', value: '-3day' },
      { num: -4, unit: 'day', value: '-4day' },
      { num: -5, unit: 'day', value: '-5day' },
      { num: -6, unit: 'day', value: '-6day' },
      { num: -7, unit: 'day', value: '-7day' },
    ],
    default: { num: 0, unit: 'day', value: '0day' },
  },
  system: {
    opts: [
      { num: 1, unit: 'hour', value: '1hour' },
      { num: 8, unit: 'hour', value: '8hour' },
      { num: 24, unit: 'hour', value: '24hour' },
    ],
    default: { num: 1, unit: 'hour', value: '1hour' },
  },
  systemcpu: {
    opts: [
      { num: 10, unit: 'min', value: MACROS.SYS.SYSRES_10MIN },
      { num: 1, unit: 'hour', value: MACROS.SYS.SYSRES_HOUR },
      { num: 24, unit: 'hour', value: MACROS.SYS.SYSRES_24HOUR },
    ],
    default: { num: 10, unit: 'min', value: MACROS.SYS.SYSRES_10MIN },
  },
  fabric_history_time_period_type: {
    opts: [
      { num: 1, unit: 'hour', value: '1hour' },
      { num: 4, unit: 'hour', value: '4hour' },
      { num: 12, unit: 'hour', value: '12hour' },
      { num: 1, unit: 'day', value: '1day' },
      { num: 1, unit: 'week', value: '1week' },
      { lastN: 1, unit: 'hour', value: 'nhour' },
      { lastN: 1, unit: 'day', value: 'nday' },
      { lastN: 1, unit: 'week', value: 'nweek' },
      { start: '', end: '', unit: 'custom', value: 'custom' },
    ],
    default: { num: 1, unit: 'week', value: '1week' },
  },
  get threat_map() {
    let realTime = { unit: 'realtime', value: 'realtime' };
    return {
      opts: [realTime, ...this.normal.opts],
      default: realTime,
    };
  },
};
export const Type_Top = {
  table: {
    opts: [
      { id: 100, text: 100, value: 100 },
      { id: 500, text: 500, value: 500 },
      { id: 1000, text: 1000, value: 1000 },
    ],
    default: { id: 100, text: 100, value: 100 },
  },
  simple: {
    opts: [
      { id: 100, text: 100, value: 100 },
      { id: 500, text: 500, value: 500 },
      { id: 1000, text: 1000, value: 1000 },
    ],
    default: { id: 100, text: 100, value: 100 },
  },
  ioc_user: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 10, text: 10, value: 10 },
  },
  vuln_user: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 10, text: 10, value: 10 },
  },
  stackbar: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 5, text: 5, value: 5 },
  },
  pie: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 5, text: 5, value: 5 },
  },
  donut: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 5, text: 5, value: 5 },
  },
  bar: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 10, text: 10, value: 10 },
  },
  bubble: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 10, text: 10, value: 10 },
  },
  world: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 20, text: 20, value: 20 },
      { id: 50, text: 50, value: 50 },
      { id: 100, text: 100, value: 100 },
    ],
    default: { id: 50, text: 50, value: 50 },
  },
  globe: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 20, text: 20, value: 20 },
      { id: 50, text: 50, value: 50 },
      { id: 100, text: 100, value: 100 },
    ],
    default: { id: 50, text: 50, value: 50 },
  },
  treemap: {
    opts: [
      { id: 9, text: 9, value: 9 },
      { id: 12, text: 12, value: 12 },
      { id: 15, text: 15, value: 15 },
      { id: 27, text: 27, value: 27 },
    ],
    default: { id: 12, text: 12, value: 12 },
  },
  scatter: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 10, text: 10, value: 10 },
  },
  fct_software: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 20, text: 20, value: 20 },
      { id: 50, text: 50, value: 50 },
    ],
    default: { id: 10, text: 10, value: 10 },
  },
  sankey: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 10, text: 10, value: 10 },
  },
  source_today: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
    ],
    default: { id: 10, text: 10, value: 10 },
  },
  // threatPrevalence: {
  chord: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
    ],
    default: { id: 5, text: 5, value: 5 },
  },
  geo: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
    ],
    default: { id: 5, text: 5, value: 5 },
  },
  threat_prevalence_table: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
    ],
    default: { id: 5, text: 5, value: 5 },
  },
  timeline: {
    opts: [
      { id: 10, text: 10, value: 10 },
      { id: 15, text: 15, value: 15 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 10, text: 10, value: 10 },
  },
  sdwan_app_bandwidth_table: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 5, text: 5, value: 5 },
    // if isKeepTop is set to true, the selected top value will not change to default value
    // that means the selected top will be same as current selectedTop
    // when the types options are matched
    isKeepTop: true,
  },
  sdwan_app_bandwidth_sankey: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 5, text: 5, value: 5 },
    isKeepTop: true,
  },
  sdwan_global_app_performance: {
    opts: [
      { id: 5, text: 5, value: 5 },
      { id: 10, text: 10, value: 10 },
      { id: 20, text: 20, value: 20 },
    ],
    default: { id: 5, text: 5, value: 5 },
    isKeepTop: true,
  },
};
export const RefreshInterval = {
  normal: {
    opts: [
      { id: '1m', text: gettext('Every 1 Minute'), value: 1 * 60 },
      { id: '3m', text: gettext('Every 3 Minutes'), value: 3 * 60 },
      { id: '5m', text: gettext('Every 5 Minutes'), value: 5 * 60 },
      { id: '10m', text: gettext('Every 10 Minutes'), value: 10 * 60 },
      { id: '15m', text: gettext('Every 15 Minutes'), value: 15 * 60 },
      { id: '30m', text: gettext('Every 30 Minutes'), value: 30 * 60 },
    ],
    default: { id: '5m', text: gettext('Every 5 Minutes'), value: 5 * 60 },
  },
  ioc: {
    opts: [
      { id: '30m', text: gettext('Every 30 Minutes'), value: 30 * 60 },
      { id: '1h', text: gettext('Every 1 hour'), value: 60 * 60 },
    ],
    default: { id: '30m', text: gettext('Every 30 Minutes'), value: 30 * 60 },
  },
  fct_software: {
    opts: [
      { id: '1d', text: gettext('Every 1 day'), value: 24 * 60 * 60 },
      { id: '2d', text: gettext('Every 2 days'), value: 2 * 24 * 60 * 60 },
      { id: '1w', text: gettext('Every 1 week'), value: 7 * 24 * 60 * 60 },
    ],
    default: { id: '1d', text: gettext('Every 1 day'), value: 24 * 60 * 60 },
  },
  system: {
    opts: [
      { id: '30s', text: gettext('Every 30 Seconds'), value: 30 },
      { id: '1m', text: gettext('Every 1 Minute'), value: 1 * 60 },
      { id: '2m', text: gettext('Every 2 Minutes'), value: 2 * 60 },
      { id: '3m', text: gettext('Every 3 Minutes'), value: 3 * 60 },
      { id: '4m', text: gettext('Every 4 Minutes'), value: 4 * 60 },
    ],
    default: { id: '30s', text: gettext('Every 30 Seconds'), value: 30 },
  },
  systemcpu: {
    opts: [
      { id: '5s', text: gettext('Every 5 Seconds'), value: 5 },
      { id: '10s', text: gettext('Every 10 Seconds'), value: 10 },
      { id: '30s', text: gettext('Every 30 Seconds'), value: 30 },
      { id: '1m', text: gettext('Every 1 Minute'), value: 1 * 60 },
      { id: '2m', text: gettext('Every 2 Minutes'), value: 2 * 60 },
      { id: '3m', text: gettext('Every 3 Minutes'), value: 3 * 60 },
    ],
    default: { id: '30s', text: gettext('Every 30 Seconds'), value: 30 },
  },
  timeline: {
    opts: [{ id: '12h', text: gettext('Every 12 hours'), value: 12 * 60 * 60 }],
    default: {
      id: '12h',
      text: gettext('Every 12 hours'),
      value: 12 * 60 * 60,
    },
  },
  disabled: {},
};
export const AutoplayInterval = {
  ioc: {
    opts: [
      { id: '10s', text: gettext('Every 10 Seconds'), value: 10 },
      { id: '20s', text: gettext('Every 20 Seconds'), value: 20 },
      { id: '30s', text: gettext('Every 30 Seconds'), value: 30 },
      { id: '1m', text: gettext('Every 1 Minute'), value: 1 * 60 },
      { id: '2m', text: gettext('Every 2 Minutes'), value: 2 * 60 },
      { id: '5m', text: gettext('Every 5 Minutes'), value: 5 * 60 },
    ],
    default: { id: '20s', text: gettext('Every 20 Seconds'), value: 20 },
  },
  vuln_user: {
    opts: [
      { id: '10s', text: gettext('Every 10 Seconds'), value: 10 },
      { id: '20s', text: gettext('Every 20 Seconds'), value: 20 },
      { id: '30s', text: gettext('Every 30 Seconds'), value: 30 },
      { id: '1m', text: gettext('Every 1 Minute'), value: 1 * 60 },
      { id: '2m', text: gettext('Every 2 Minutes'), value: 2 * 60 },
      { id: '5m', text: gettext('Every 5 Minutes'), value: 5 * 60 },
    ],
    default: { id: '20s', text: gettext('Every 20 Seconds'), value: 20 },
  },
  timeline: {
    opts: [
      { id: '10s', text: gettext('Every 10 Seconds'), value: 10 },
      { id: '20s', text: gettext('Every 20 Seconds'), value: 20 },
      { id: '30s', text: gettext('Every 30 Seconds'), value: 30 },
      { id: '1m', text: gettext('Every 1 Minute'), value: 1 * 60 },
      { id: '2m', text: gettext('Every 2 Minutes'), value: 2 * 60 },
      { id: '5m', text: gettext('Every 5 Minutes'), value: 5 * 60 },
    ],
    default: { id: '20s', text: gettext('Every 20 Seconds'), value: 20 },
  },
  device_status_rotate: {
    opts: [
      { id: '5s', text: gettext('Every 5 Seconds'), value: 5 },
      { id: '15s', text: gettext('Every 15 Seconds'), value: 15 },
      { id: '30s', text: gettext('Every 30 Seconds'), value: 30 },
    ],
    default: { id: '5s', text: gettext('Every 5 Seconds'), value: 5 },
  },
};
