import * as React from 'react';
import { ProForm } from '@fafm/neowise-pro';
import { NwSpinner } from '@fafm/neowise-core';

export const fillSpinner = (
  <div className='tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center'>
    <NwSpinner style={{ fontSize: '3rem' }}></NwSpinner>
  </div>
);

export const suspenseForm = (lazyNode: React.ReactNode) => {
  return (
    <React.Suspense
      fallback={
        <>
          <ProForm.Header>{gettext('Loading Module')}</ProForm.Header>
          <ProForm.Body>{fillSpinner}</ProForm.Body>
        </>
      }
    >
      {lazyNode}
    </React.Suspense>
  );
};

export const suspenseApp = (lazyNode: React.ReactNode) => {
  return <React.Suspense fallback={fillSpinner}>{lazyNode}</React.Suspense>;
};
