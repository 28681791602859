import { noop } from 'lodash';
import { createCaptureFile, debuggerDidStart } from '../debugger_utils';
import type { SocketCallbacks } from 'fi-web/fi-websocket/socket/socket_proxy';
import { UnknownRecord } from 'chrome_extension_debugger/types';
import { asSerializeInfo } from 'chrome_extension_debugger/utils';

export type LogSocket = SocketCallbacks;

const logNoop = {
  onopen: noop,
  onclose: noop,
  onerror: noop,
  onmessage: noop,
  send: noop,
  close: noop,
};

export const socketLog: LogSocket = { ...logNoop };

export const register = () => {
  return debuggerDidStart(async () => {
    const { send, complete } = await createCaptureFile('socket-events.json');

    const sendImpl = (data: {
      type: string;
      data?: string | UnknownRecord;
    }) => {
      send({ timestamp: Date.now(), ...data });
    };

    Object.assign(socketLog, {
      onopen: () => {
        sendImpl({
          type: 'onopen',
        });
      },
      onclose: (event) => {
        sendImpl({
          type: 'onclose',
          data: {
            code: event.code,
            reason: event.reason,
            wasClean: event.wasClean,
          },
        });
      },
      onerror: (event) => {
        sendImpl({
          type: 'onerror',
          data: {
            message: event.message,
            error: asSerializeInfo(event.error),
          },
        });
      },
      onmessage: (message) => {
        sendImpl({
          type: 'onmessage',
          data: asSerializeInfo(message.data),
        });
      },
      send: (data) => {
        sendImpl({
          type: 'send',
          data: asSerializeInfo(data),
        });
      },
      close: (code, reason) => {
        sendImpl({
          type: 'close',
          data:
            (code ?? reason) != null
              ? {
                  code,
                  reason,
                }
              : undefined,
        });
      },
    } as LogSocket);

    return stopCapture;

    function stopCapture() {
      Object.assign(socketLog, logNoop);
      complete();
    }
  });
};
