import ReactDOMServer from 'react-dom/server';
import cn from 'classnames';
import Parser from 'html-react-parser';
import { isDefined } from 'fiutil';
import { isNil, isString } from 'lodash';

import { getDevice } from './util';
import { fiDvmLogDataService, deviceStatus } from 'fi-dvm';
import { NwIcon } from '@fafm/neowise-core';

export const DEV_STATUS_CONST = {
  txt: {
    device: gettext('Device'),
    devgrp: gettext('Device Group'),
    conn_up: gettext('Connection Up'),
    conn_down: gettext('Connection Down'),
    model_dev: gettext('Model Device'),
    model_dev_up: gettext('Model Device Connection Up'),
    model_dev_down: gettext('Model Device Connection Down'),
    ha: gettext('HA Device'),
    model_ha: gettext('Model HA Cluster'),
    model_ha_up: gettext('Model HA Cluster Connection Up'),
    model_ha_down: gettext('Model HA Cluster Connection Down'),
    autolink_disabled: gettext('Auto-link Disabled'),
    waiting_for_autolink: gettext('Waiting All Secondaries to Auto-Link'),
  },
  icon: {
    devgrp: {
      name: 'group',
    },
    vdom: {
      name: 'vdom',
    },
    conn_up: {
      name: 'up',
      className: 'color-green',
    },
    conn_down: {
      name: 'down',
      className: 'color-orange',
    },
    model_dev: {
      name: 'device',
      className: 'color-grey',
    },
    model_dev_up: [
      { name: 'device', className: 'color-grey' },
      { name: 'up', className: 'color-green' },
    ],
    model_dev_down: [
      { name: 'device', className: 'color-grey' },
      { name: 'down', className: 'color-orange' },
    ],
    ha: {
      name: 'ha',
    },
    ha_up: {
      name: 'ha',
      className: 'color-green',
    },
    ha_down: {
      name: 'ha',
      className: 'color-orange',
    },
    ha_unknown: {
      name: 'ha',
      className: 'color-grey',
    },
    model_ha_up: [
      { name: 'ha', className: 'color-grey' },
      { name: 'up', className: 'color-green' },
    ],
    model_ha_down: [
      { name: 'ha', className: 'color-grey' },
      { name: 'down', className: 'color-orange' },
    ],
    modified: {
      name: 'warning',
    },
    disconnected: {
      name: 'disconnected',
    },
    logging: {
      up: { name: 'up', className: 'color-green' },
      forwarded: { name: 'share', className: 'color-grey' },
      hasync: { name: 'refresh', className: 'color-grey' },
      unknown: { name: 'unregistered', className: 'color-grey' },
      down: { name: 'down', className: 'color-red' },
    },
  },
  colors: {
    grey: 'color-grey',
    red: 'color-red',
    orange: 'color-orange',
    green: 'color-green',
    yellow: 'color-yellow',
  },
};

export class DeviceFormatter {
  static getConst() {
    return DEV_STATUS_CONST;
  }

  /**
   * To get icon and title based on connectivity
   * @param {object} devData {
   *  isGrp: boolean,
   *  conn_status: number,
   *  connection: object
   * }
   * @returns {
   *  iconProps: object
   *  className: string,
   *  title: string
   * }
   */
  static getSimpleStatusIcon(devData, conStatus) {
    let iconProps = DEV_STATUS_CONST.icon.model_dev;
    let className = '';
    let title = DEV_STATUS_CONST.txt.model_dev;

    if (!devData) {
      return {
        iconProps,
        className,
        title,
      };
    }

    const isVdom =
      devData.platform == 'vdom' ||
      (!isNil(devData?._isDevice) && !devData?._isDevice) ||
      (!isNil(devData._oData?._isDevice) && !devData._oData?._isDevice);

    if (devData.isGrp || devData.devgrp) {
      iconProps = DEV_STATUS_CONST.icon.devgrp;
      className = DEV_STATUS_CONST.colors.grey;
      title = DEV_STATUS_CONST.txt.devgrp;
      return { iconProps, className, title };
    } else if (isVdom) {
      const vdomInfo = this.getVdomInfo({
        devName: devData._deviceName,
        vdom_mode: devData.vdom_mode,
        rtype: devData.rtype,
      });
      return vdomInfo;
    } else {
      if (!devData._oData) {
        devData._oData = getDevice(devData.oid, devData.vdom_oid);
      }
      return this.getConnectionIcons(
        devData,
        conStatus ?? devData?.conn_status ?? undefined
      );
    }
  }

  /**
   * To get icon and title device data (e.g. connectivity, HA etc.).
   * This formatter is similar to the device table where vdom and HA rows are formatted
   * @param {object} devData
   *   - device -> data structure comes from redux store, use the "connection" attr, load from c api
   *   - conn_status -> attr comes from json api /dvmdb/adom/root/device/deviceabc
   * @returns {
   *  iconProps: object
   *  className: string,
   *  title: string
   * }
   */
  static getConnectionIcons(device, conn_status) {
    // model device on default
    let iconProps = DEV_STATUS_CONST.icon.model_dev;
    let className = DEV_STATUS_CONST.colors.grey;
    let title = DEV_STATUS_CONST.txt.model_dev;
    const dev = device?._oData || device || {};
    const connection = dev?.connection;
    const isHa = () =>
      Array.isArray(connection?.ha) && connection.ha.length > 0;
    const isModel = () => !!dev.model_dev;

    const connectivity_status = connection?.conn || conn_status;
    if (!connectivity_status) {
      return {
        iconProps: isHa() ? DEV_STATUS_CONST.icon.model_dev : iconProps,
        className,
        title: isHa() ? DEV_STATUS_CONST.txt.model_dev : title,
      };
    }

    switch (connectivity_status) {
      case deviceStatus.conn.unknown:
        if (isHa()) {
          iconProps = DEV_STATUS_CONST.icon.ha;
          className = DEV_STATUS_CONST.colors.grey;
          if (isModel()) {
            iconProps = DEV_STATUS_CONST.icon.model_dev;
            title = DEV_STATUS_CONST.txt.model_dev;
          } else {
            title = DEV_STATUS_CONST.txt.ha;
          }
        } else {
          iconProps = DEV_STATUS_CONST.icon.model_dev;
          className = DEV_STATUS_CONST.colors.grey;
          title = DEV_STATUS_CONST.txt.model_dev;
        }
        break;
      case deviceStatus.conn.connected:
        title = DEV_STATUS_CONST.txt.conn_up;
        if (isHa()) {
          if (isModel()) {
            iconProps = DEV_STATUS_CONST.icon.model_dev;
            title = DEV_STATUS_CONST.txt.model_dev;
          } else {
            iconProps = DEV_STATUS_CONST.icon.ha_up;
            className = DEV_STATUS_CONST.colors.green;
          }
        } else if (
          isModel()
          //&& dev.manage_mode === MACROS.DVM.DVM_MGMT_MODE_UNREG
        ) {
          // pending status device (is_model && conn_up && is_unreg)
          iconProps = DEV_STATUS_CONST.icon.model_dev;
          title = DEV_STATUS_CONST.txt.model_dev;
        } else {
          iconProps = DEV_STATUS_CONST.icon.conn_up;
          className = DEV_STATUS_CONST.colors.green;
        }
        break;
      case deviceStatus.conn.down:
        title = DEV_STATUS_CONST.txt.conn_down;
        if (isHa()) {
          if (isModel()) {
            iconProps = DEV_STATUS_CONST.icon.model_dev;
            title = DEV_STATUS_CONST.txt.model_dev;
          } else {
            iconProps = DEV_STATUS_CONST.icon.ha_down;
            className = DEV_STATUS_CONST.colors.orange;
          }
        } else if (isModel()) {
          iconProps = DEV_STATUS_CONST.icon.model_dev;
          title = DEV_STATUS_CONST.txt.model_dev;
        } else {
          // non-ha device Down
          iconProps = DEV_STATUS_CONST.icon.conn_down;
          className = DEV_STATUS_CONST.colors.orange;
        }
        if (connection && connection.time) {
          title += '\n' + gettext('Last Checked on:') + ' ' + connection.time;
        }
        break;
      default:
        iconProps = DEV_STATUS_CONST.icon.model_dev;
        className = DEV_STATUS_CONST.colors.grey;
        title = DEV_STATUS_CONST.txt.device;
        break;
    }
    return {
      iconProps,
      className,
      title,
    };
  }

  static getVdomTxt(devName, vdom_mode, rtype) {
    let txt = 'VDOM';
    let title = 'VDOM';
    // device
    switch (vdom_mode) {
      case 'nat':
        txt = devName + ' [NAT]';
        title = 'NAT VDOM';
        break;
      case 'tp':
        txt = devName + ' [' + gettext('Transparent') + ']';
        title = gettext('Transparent VDOM');
        break;
      default:
        txt = devName;
        title = 'VDOM';
        break;
    }

    // Mantis 304876, remove management vdom text
    if (rtype === deviceStatus.rtype.vdomMgt) {
      txt += ' (' + gettext('Management') + ')';
      title = gettext('Management VDOM');
    }

    return {
      txt,
      title,
    };
  }

  static getDeviceExtraInfo(oDev, attrs) {
    const ip = `IP: ${oDev.ip || 'N/A'}`;
    const platform_str = oDev.platform_str
      ? gettext('Platform') + ': ' + oDev.platform_str
      : null;

    const info = {
      ip,
      platform_str,
    };

    const toShow = attrs
      ? attrs.map((attr) => info[attr]).filter(Boolean)
      : Object.values(info);

    return toShow.length > 0 ? toShow.join(', ') : null;
  }

  static getDefaultDeviceFormatters({ txt, title, _oData } = {}) {
    return {
      iconProps: DEV_STATUS_CONST.icon.model_dev,
      className: '',
      txt: txt || DEV_STATUS_CONST.txt.model_dev,
      title: title || txt,
      _oData,
    };
  }

  static getSimpleDeviceStatusFormatter(oDev) {
    const { text, conn_status, _oData, showIcon } = oDev;
    if (!showIcon) {
      return {
        txt: text,
        selectedTxt: text,
        title: text,
      };
    }

    const res = this.getSimpleStatusIcon({ ...oDev, ..._oData, conn_status });
    return {
      ...res,
      txt: text,
      selectedTxt: text,
    };
  }

  static getVdomIconProps() {
    const iconProps = DEV_STATUS_CONST.icon.vdom;
    const className = DEV_STATUS_CONST.colors.grey;
    return {
      iconProps,
      className,
    };
  }

  static getVdomInfo({ devName, vdom_mode, rtype }) {
    const isFaz = MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FAZ;
    const vdomName = devName;
    let txt = '';
    let title = '';
    // vdom
    if (isFaz) {
      txt = vdomName;
      title = 'VDOM';
    } else {
      const vdomTxt = this.getVdomTxt(vdomName, vdom_mode, rtype);
      txt = vdomTxt.txt;
      title = vdomTxt.title;
    }
    return {
      ...this.getVdomIconProps(),
      txt,
      title,
    };
  }

  /**
   * get device icon/text/title
   * options:
   * - extraInfoAttrs: to add extra info to each choice's text (right now it supports platform and IP)
   * - hideVdom: hide vdom name in selected device text
   * - useSimpleFormatter: show icon and title based on connectivity
   * @returns including iconProps, className, txt, selectedTxt, and title
   */
  static getDeviceStatusFormatter(
    oDev,
    // formatter options
    options = {}
  ) {
    const {
      extraInfoAttrs = null,
      hideVdom = false,
      useSimpleFormatter = false,
    } = options;

    let iconProps = DEV_STATUS_CONST.icon.model_dev;
    let className = '';
    let txt = DEV_STATUS_CONST.txt.model_dev;
    let title = '';
    const defaultVals = {
      iconProps,
      className,
      txt,
      title,
    };

    if (!oDev) {
      return defaultVals;
    }

    if (useSimpleFormatter) {
      return this.getSimpleDeviceStatusFormatter(oDev);
    }

    // add extra device data from store if there is any
    if (!oDev._oData) {
      const devData = getDevice(oDev.oid, oDev.vdom_oid);

      // can find device object, use simple device status formatter instead
      if (!devData) {
        return this.getSimpleDeviceStatusFormatter(oDev);
      }

      oDev._oData = devData;
    }

    // device choice object
    const {
      id,
      text,
      selectedText,
      useCustomText,
      isGlobal,
      vdom_oid,
      _oData,
    } = oDev;

    // extra device info from device data in store
    const {
      vdom_mode,
      isGrp,
      platform,
      name: devName,
      _isDevice,
      rtype,
    } = _oData;

    const isVdom = isGrp ? false : platform == 'vdom' || !_isDevice;
    const vdomDisabled =
      !isVdom &&
      (!_oData.vdoms || _oData.vdoms?.length === 0 || _oData.vdom_status === 0);
    txt = devName;

    if (isGrp) {
      // device group
      txt = devName;
      title = DEV_STATUS_CONST.txt.devgrp;
      iconProps = DEV_STATUS_CONST.icon.devgrp;
      className = DEV_STATUS_CONST.colors.grey;
    } else if (isVdom) {
      const vdomInfo = this.getVdomInfo({ devName, vdom_mode, rtype });
      txt = vdomInfo.txt;
      title = vdomInfo.title;
      iconProps = vdomInfo.iconProps;
      className = vdomInfo.className;
    } else {
      txt = devName;
      title = DEV_STATUS_CONST.txt.device;
      if (deviceStatus.field.connectivity in _oData) {
        const connIconStatus = this.getConnectionIcons(
          _oData,
          oDev.conn_status
        );
        iconProps = connIconStatus.iconProps;
        className = connIconStatus.className;
        title = connIconStatus.title;
      } else if (_oData['ha_cluster'] && _oData['ha_cluster']?.ha) {
        // for unregister device in faz
        iconProps = DEV_STATUS_CONST.icon.ha;
        title = DEV_STATUS_CONST.txt.ha;
      } else {
        iconProps = DEV_STATUS_CONST.icon.model_dev;
      }

      if (extraInfoAttrs) {
        const extraInfo = this.getDeviceExtraInfo(_oData, extraInfoAttrs);
        if (extraInfo) txt += ` (${extraInfo})`;
      }
    }

    // choice text
    function getChoiceTxt() {
      // use provided text if there is any
      return useCustomText ? selectedText || text || txt : txt;
    }

    // selected dev text
    function getSelectedTxt() {
      if (useCustomText) {
        // use provided text if there is any
        return text || txt;
      }

      let selectedTxt = text;
      if (
        isGlobal ||
        (vdom_oid &&
          vdom_oid.toString() === `${MACROS.DVM.CDB_DEFAULT_GLOBAL_OID}`) ||
        (id && id.split('/')?.[1] === 'global')
      ) {
        selectedTxt = text || `${devName} [global]`;
      } else if (id && isVdom) {
        let [_deviceName, _vdomName] = id.split('/');
        _deviceName = _deviceName || _oData['_deviceName'];
        _vdomName = _vdomName || devName;
        selectedTxt = `${_deviceName} [${_vdomName}]`;
      } else if (!useCustomText && (vdomDisabled || hideVdom)) {
        selectedTxt = devName;
      }

      return selectedTxt;
    }

    return {
      iconProps,
      className,
      txt: getChoiceTxt(),
      selectedTxt: getSelectedTxt(),
      title,
    };
  }

  static getDeviceFormatters(options) {
    const formatDevHTML = (oDev, highlighter = (text) => text) => {
      const {
        iconProps,
        className,
        txt = '',
        title,
      } = DeviceFormatter.getDeviceStatusFormatter(oDev, options);
      const indent = oDev?._oData?.platform === 'vdom';
      const props = {
        iconProps,
        className,
        txt,
        title,
        highlighter,
        style: { marginLeft: indent ? 18 : 0 },

        showIcon: oDev.showIcon,
        indent,
      };
      return ReactDOMServer.renderToString(<DeviceRow {...props} />);
    };

    const formatSelectedDevHTML = (oDev, highlighter = (text) => text) => {
      const { iconProps, className, title, selectedTxt } =
        DeviceFormatter.getDeviceStatusFormatter(oDev, options);
      const props = {
        iconProps,
        className,
        txt: selectedTxt,
        title,
        highlighter,
      };
      return ReactDOMServer.renderToString(<DeviceRow {...props} />);
    };

    const searchFn = (oDev, searcher) => {
      const { txt } = DeviceFormatter.getDeviceStatusFormatter(oDev, options);
      return searcher(txt);
    };

    return {
      formatDevHTML,
      formatSelectedDevHTML,
      searchFn,
    };
  }
}

export function deviceIconFormatter(
  member,
  isFMG,
  formatVdom = false,
  adomOid
) {
  let iconProps, className, title;
  if (!member) {
    return {
      iconProps: {},
      className,
      title,
    };
  }
  if (isFMG) {
    let conStatus =
      isDefined(member.conn) && isDefined(member.conn.status)
        ? member.conn.status
        : undefined;
    if (formatVdom && member.isVdom) {
      iconProps = { name: 'vdom', className: 'list-name-indent' };
    } else {
      member.model_dev = member.model_dev ?? member.is_model_dev;
      const {
        iconProps: _iconProps,
        className: _className,
        title: _t,
      } = DeviceFormatter.getSimpleStatusIcon(member, conStatus);
      iconProps = _iconProps;
      className = _className;
      title = _t;
    }
  } else {
    let logStatus;
    if (isDefined(member.logStatus)) {
      logStatus = member.logStatus;
    } else {
      const logStats = fiDvmLogDataService.getLogStatsByDevice(member, adomOid);
      logStatus = logStats?.status || MACROS.DVM.LOGSTAT_DEVST_DOWN;
    }

    if (member.isVdom) {
      iconProps = { name: 'vdom', className: 'list-name-indent' };
    } else {
      if (logStatus === MACROS.DVM.LOGSTAT_DEVST_UP) {
        iconProps = DEV_STATUS_CONST.icon.logging.up;
      } else if (logStatus === MACROS.DVM.LOGSTAT_DEVST_FWD) {
        iconProps = DEV_STATUS_CONST.icon.logging.forwarded;
      } else if (logStatus === MACROS.DVM.LOGSTAT_DEVST_HASYNC) {
        iconProps = DEV_STATUS_CONST.icon.logging.hasync;
      } else if (logStatus === MACROS.DVM.LOGSTAT_DEVST_UNKNOWN) {
        iconProps = DEV_STATUS_CONST.icon.logging.unknown;
      } else {
        iconProps = DEV_STATUS_CONST.icon.logging.down;
      }
    }
  }

  return {
    iconProps,
    className,
    title,
  };
}

export const DeviceRowIcon = ({ iconProps, txt, title, className }) => {
  return Array.isArray(iconProps) ? (
    <span className='tw-pr-1 nw-stack'>
      {iconProps.map((icon, idx, arr) => {
        if (idx === arr.length - 1) {
          if (!(icon?.className || '').includes('nw-stack-br')) {
            icon.className = cn(icon.className, 'nw-stack-br');
          }
          //return <NwIcon key={icon.name} {...icon} /> // not work, need to use nw-icon
          return (
            <NwIcon
              key={icon.name}
              name={icon.name}
              className={icon.className}
              label={title || txt}
            />
          );
        } else {
          //return <NwIcon key={icon.name} {...icon} title={title || txt} />
          return (
            <NwIcon
              key={icon.name}
              name={icon.name}
              className={icon.className}
              label={title || txt}
            />
          );
        }
      })}
    </span>
  ) : (
    <NwIcon
      {...iconProps}
      className={cn('tw-mr-1 tw-shrink-0', iconProps?.className ?? className)}
      label={title || txt}
    />
  );
};

export const DeviceRow = ({
  iconProps,
  className,
  txt,
  title,
  highlighter,
  showIcon = true,
  style,
}) => {
  txt = txt || '';
  const highlightedTxt = highlighter ? highlighter(txt) : txt;
  return (
    <div className={cn('tw-flex tw-items-center')} style={style}>
      {showIcon ? (
        <DeviceRowIcon
          iconProps={iconProps}
          txt={txt}
          title={title}
          className={className}
        />
      ) : null}
      <div className='tw-truncate' title={txt}>
        {isString(highlightedTxt) ? Parser(highlightedTxt) : highlightedTxt}
      </div>
    </div>
  );
};

export const DefaultDeviceRowIcon = ({ device = null }) => {
  if (!device) return null;

  let iconProps = DeviceFormatter.getSimpleStatusIcon(device);
  return <DeviceRowIcon {...iconProps} />;
};
