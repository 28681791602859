import RemoteTerminal, { promptRemoteSshSetting } from './RemoteTerminal';
import Terminal from './LocalTerminal';
import { ProToolkit } from '@fafm/neowise-pro';

function promptTerminal(terminalCompt) {
  ProToolkit.openModal(terminalCompt, {
    minimizable: true,
    className: 'fi_borderless_terminal np-toolkit-modal',
    size: 'xl',
  });
}

export { Terminal, RemoteTerminal, promptRemoteSshSetting, promptTerminal };
