import { fiSession } from 'fistore';
import { negate, overSome } from 'lodash';
const {
  inGlobalAdom,
  hasRDPermitOn,
  hasFazFeature,
  isFmg,
  isFaz,
  getIsFazSupervisor,
  getHasDocker,
  hasRaidFeature,
  isFazSqlDbEnabled,
  getIsFtntCloud,
  getIsAdomScopedAdmin,
} = fiSession;
const mrPRIV = MACROS.USER.DVM;
const OS_TYPE =
  MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FAZ
    ? MACROS.DVM.DVM_OS_TYPE_FAZ
    : MACROS.DVM.DVM_OS_TYPE_FMG;

const task_monitor = {
  path: '/sys/taskmonitor',
  label: gettext('Task Monitor'),
};

export default {
  sys: {
    label: gettext('System Settings'),
    icon: 'system-settings',
    color: '#a186a7',
    showCheckers: [negate(inGlobalAdom)],
    hideIfNoChildren: true,
  },
  sys_supfabric: {
    label: gettext('Fabric Management'),
    //icon: 'forticasb',
    showCheckers: [
      negate(getIsFtntCloud),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
  },
  sys_supfabric_settings: {
    path: '/sys/fabric/settings',
    label: gettext('Fabric Settings'),
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS)],
    olhTag: 'sys_fabric',
    olhCategory: 'sys',
    olhText: 'System Settings -> FortiAnalyzer Fabric',
  },
  sys_fazsupfabric_groups: {
    path: '/sys/fabric/groups',
    label: gettext('Fabric Groups'),
    showCheckers: [
      isFaz,
      negate(getIsFtntCloud),
      getIsFazSupervisor,
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_fabric',
    olhCategory: 'sys',
    olhText: 'System Settings -> FortiAnalyzer Fabric',
  },
  sys_fazsupfabric_connectors: {
    path: '/sys/fabric/connectors',
    label: gettext('Fabric Connectors'),
    showCheckers: [
      isFaz,
      negate(getIsFtntCloud),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_fabric',
    olhCategory: 'sys',
    olhText: 'System Settings -> FortiAnalyzer Fabric',
  },
  sys_logtopology: {
    path: '/sys/logtopology',
    label: gettext('Logging Topology'),
    // icon: 'address-multicast', // ffg-aggregate',
    showCheckers: [
      hasFazFeature,
      negate(getIsFtntCloud),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_logtopology',
    olhCategory: 'sys',
    olhText: 'System Settings -> Logging Topology',
  },
  sys_alladoms: {
    path: '/sys/alladoms',
    label: gettext('ADOMs'),
    // icon: 'alladoms',
    showCheckers: [
      negate(getIsFtntCloud),
      hasRDPermitOn(mrPRIV.ADMINPRIV_ADOM_ACCESS),
      negate(getIsFazSupervisor),
    ],
    olhTag: 'sys_alladoms',
    olhCategory: 'sys',
    olhText: 'System Settings -> All ADOMs',
  },
  sys_raid: {
    path: '/sys/raid',
    label: gettext('RAID Management'),
    //icon: 'device-total',
    showCheckers: [
      hasRaidFeature,
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_raid',
    olhCategory: 'sys',
    olhText: 'System Settings -> RAID(Must have RAID)',
  },
  sys_network_setting: {
    path: '/sys/network',
    label: gettext('Network'),
    showCheckers: [
      negate(getHasDocker),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_network_interface',
    olhCategory: 'sys',
    olhText: 'System Settings -> Network',
  },
  sys_ha: {
    path: '/sys/ha',
    label: 'HA',
    // icon: 'ha',
    showCheckers: [
      negate(getIsFtntCloud),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_ha',
    olhCategory: 'sys',
    olhText: 'System Settings -> HA',
  },
  sys_admin_user: {
    path: '/sys/administrator',
    label: gettext('Administrators'),
    showCheckers: [
      overSome([
        hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
        getIsAdomScopedAdmin,
      ]),
    ],
    olhTag: 'sys_admin',
    olhCategory: 'sys',
    olhText: 'System Settings -> Admin -> Administrators',
  },
  sys_admin_profile: {
    path: '/sys/profile',
    label: gettext('Admin Profiles'),
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS)],
    olhTag: 'sys_profile',
    olhCategory: 'sys',
    olhText: 'System Settings -> Admin -> Profile',
  },
  sys_admin_workspace: {
    path: '/sys/workspace',
    label: gettext('Workspace'),
    showCheckers: [isFmg, hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS)],
    olhTag: 'sys_workspace',
    olhCategory: 'sys',
    olhText: 'System Settings -> Admin -> Workspace',
  },
  sys_admin_authserver: {
    path: '/sys/authserver',
    label: gettext('Remote Authentication Server'),
    showCheckers: [
      negate(getIsFtntCloud),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_authserver',
    olhCategory: 'sys',
    olhText: 'System Settings -> Admin -> Remote Authentication Server',
  },
  sys_admin_setting: {
    path: '/sys/adminSettings',
    label: gettext('Settings'),
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS)],
    olhTag: 'sys_adminsettings',
    olhCategory: 'sys',
    olhText: 'System Settings -> Admin -> Admin Settings',
  },
  sys_admin_sso: {
    path: '/sys/sso_settings',
    label: gettext('SAML SSO'),
    showCheckers: [
      negate(getIsFtntCloud),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_sso',
    olhCategory: 'sys',
    olhText: 'System Settings -> SAML SSO',
  },
  sys_cert: {
    path: '/sys/certificate',
    label: gettext('Certificates'),
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS)],
    // icon: 'certificate',
    olhTag: 'sys_localcertificate',
    olhCategory: 'sys',
    olhText: 'System Settings -> Certificates -> Local Certificates',
  },
  sys_logforwarding: {
    path: '/sys/logforwardlist',
    label: gettext('Log Forwarding'),
    // icon: 'traffic',
    showCheckers: [
      isFaz,
      negate(getIsFtntCloud),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_logforward',
    olhCategory: 'sys',
    olhText: 'System Settings -> Log Forwarding',
  },
  sys_logfetch: {
    path: '/sys/logfetch',
    label: gettext('Log Fetch'),
    // icon: 'log-array',
    showCheckers: [
      hasFazFeature,
      negate(getIsFtntCloud),
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_logfetch',
    olhCategory: 'sys',
    olhText: 'System Settings -> Fetcher Management',
  },
  sys_eventlog: {
    path: '/sys/eventlog/elog',
    label: gettext('Event Logs'),
    // icon: 'eventlog',
    stateParams: {
      key: 'elog',
    },
    showCheckers: [
      negate(isFazSqlDbEnabled),
      overSome([
        hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
        getIsAdomScopedAdmin,
      ]),
    ],
    olhTag: 'sys_eventlog',
    olhCategory: 'sys',
    olhFmg: 'System Settings -> Event Log(in file)',
  },
  sys_dbeventlog: {
    path: `/sys/dbeventlog/${OS_TYPE}/_local_events_/${MACROS.FAZ_SYNTAX.LOGTYPE_EXT_LOCAL_EVENT}`,
    label: gettext('Event Logs'),
    // icon: 'eventlog',
    // Note: refer to get_log_view_menu function in logview/views.py
    stateParams: {
      viewName: '_local_events_',
      _logtype: MACROS.FAZ_SYNTAX.LOGTYPE_EXT_LOCAL_EVENT + '',
      devType: OS_TYPE,
    },
    showCheckers: [
      isFazSqlDbEnabled,
      overSome([
        hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
        getIsAdomScopedAdmin,
      ]),
    ],
    olhTag: 'sys_eventlog_hist',
    olhCategory: 'sys',
    olhText: 'System Settings -> Event Log(in db)',
  },
  sys_task_fmg: {
    ...task_monitor,
    showCheckers: [
      isFmg,
      overSome([
        hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
        getIsAdomScopedAdmin,
      ]),
    ],
    olhTag: 'sys_taskmonitor',
    olhCategory: 'sys',
    olhFmg: 'System Settings -> Task Monitor',
  },
  // for trunk faz ONLY. cloud uses different key
  sys_task_faz: {
    ...task_monitor,
    showCheckers: [
      isFaz,
      negate(getIsFtntCloud),
      overSome([
        hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
        getIsAdomScopedAdmin,
      ]),
    ],
    olhTag: 'sys_taskmonitor',
    olhCategory: 'sys',
    olhFaz: 'System Settings -> Task Monitor',
  },
  sys_adv: {
    label: gettext('Advanced'),
    hideIfNoChildren: true,
    // icon: 'advanced-settings',
  },
  sys_adv_mail: {
    path: '/sys/advmailserver',
    label: gettext('Mail Server'),
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS)],
    olhTag: 'sys_mailserver',
    olhCategory: 'sys',
    olhText: 'System Settings -> Advanced -> Mail Server',
  },
  sys_adv_syslog: {
    path: '/sys/advsyslogserver',
    label: gettext('Syslog Server'),
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS)],
    olhTag: 'sys_logserver',
    olhCategory: 'sys',
    olhText: 'System Settings -> Advanced -> Syslog Server',
  },
  sys_adv_meta: {
    path: '/sys/advmetafields',
    label: gettext('Meta Fields'),
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS)],
    olhTag: 'sys_metafields',
    olhCategory: 'sys',
    olhText: 'System Settings -> Advanced -> Meta Fields',
  },
  sys_adv_logsetting: {
    path: '/sys/devicelogsettings',
    label: gettext('Device Log Settings'),
    showCheckers: [
      hasFazFeature,
      hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS),
    ],
    olhTag: 'sys_devicelogsettings',
    olhCategory: 'sys',
    olhText: 'System Settings -> Advanced -> Device Log Settings',
  },
  sys_adv_setting: {
    path: '/sys/advancedsettings',
    label: gettext('Misc Settings'),
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_SYSTEM_SETTINGS)],
    olhTag: 'sys_advancedsettings',
    olhCategory: 'sys',
    olhText: 'System Settings -> Advanced -> Advanced Settings',
  },
};
