import { WizardContext } from './WizardContext';
import {
  cloneElement,
  useCallback,
  useReducer,
  useMemo,
  useState,
  useRef,
} from 'react';
import { WizardError } from './WizardError';
import { WizardProgress } from './WizardProgress';
import { isFunction } from 'lodash';
import { useWizardProgressBar } from './WizardProgressBar';
import { ProForm, ProToolkit } from '@fafm/neowise-pro';
import cn from 'classnames';

import './rc_wizard.less';
import { WizardDebugger } from './WizardDebugger';

const isPromise = (obj) => {
  return typeof obj === 'object' && typeof obj.then === 'function';
};
const spinnerCss = {
  fontSize: '2rem',
  '--strokeWidth': '6px',
  height: '100%',
  marginLeft: '50%',
  alignItems: 'center',
};
const getInitialState = (
  initialStep = '0',
  stepSequence = [{ id: '0', text: gettext('Step 1') }],
  title
) => {
  return {
    title,
    activeStep: initialStep,
    activeStepIndex: 0,
    mostAdvStep: initialStep,
    mostAdvStepIndex: 0,
    percent: stepSequence ? 100 / (stepSequence.length + 1) : 0,
    hasNext: true,
    hasPrevious: false,
    lastPayload: {},
    error: '',
    isLoading: false,
  };
};

const WIZARD_ACTIONS = {
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  JUMP_TO: 'JUMP_TO',
  SET_TITLE: 'SET_TITLE',
};

export const WIZARD_HEADER_CLS = 'rc-wizard-header-container';

const makeReducer = (stepSequence, stepReducer) => {
  if (stepReducer) {
    return stepReducer;
  } else if (stepSequence) {
    const reducer = (prevState, action) => {
      const steps = stepSequence.map((stepObj) => stepObj.id);
      const index = steps.indexOf(prevState.activeStep);
      const mostAdvStepIndex = prevState.mostAdvStepIndex;
      const { type, payload } = action;
      let st = prevState;
      switch (type) {
        case WIZARD_ACTIONS.SET_TITLE: {
          return {
            ...prevState,
            title: action.payload,
          };
        }
        case WIZARD_ACTIONS.NEXT:
          if (index < steps.length - 1) {
            const newIndex = index + 1;
            const newStep = steps[newIndex];
            const newMostAdvIndex =
              newIndex > mostAdvStepIndex ? newIndex : mostAdvStepIndex;
            const newMostAdvStep = steps[newMostAdvIndex];
            return {
              ...prevState,
              activeStep: newStep,
              activeStepIndex: newIndex,
              mostAdvStep: newMostAdvStep,
              mostAdvStepIndex: newMostAdvIndex,
              percent: ((index + 2) / steps.length) * 100,
              hasPrevious: true,
              // if at the second last step, go to next will reach the last step (no more next step)
              hasNext: index < steps.length - 2,
              lastPayload: payload,
            };
          } else {
            return {
              ...prevState,
              activeStep: steps[index],
              activeStepIndex: index,
              mostAdvStep: steps[index],
              mostAdvStepIndex: mostAdvStepIndex,
              percent: 100,
              hasPrevious: true,
              hasNext: false,
              lastPayload: payload,
            };
          }
        case WIZARD_ACTIONS.PREVIOUS:
          if (index >= 1) {
            const newIndex = index - 1;
            return {
              ...prevState,
              activeStep: steps[newIndex],
              activeStepIndex: newIndex,
              percent: (index / steps.length) * 100,
              // if at the second step, go to previous will go back to the first step (no more previous step)
              hasPrevious: index > 1,
              hasNext: true,
              lastPayload: payload,
            };
          } else {
            return {
              ...prevState,
              activeStep: steps[index],
              activeStepIndex: index,
              percent: (1 / steps.length) * 100,
              hasPrevious: false,
              hasNext: true,
              lastPayload: payload,
            };
          }
        case WIZARD_ACTIONS.ERROR:
          st = {
            ...prevState,
            error: payload,
          };
          return st;
        case WIZARD_ACTIONS.LOADING:
          return {
            ...prevState,
            isLoading: payload,
          };
        case WIZARD_ACTIONS.JUMP_TO: {
          const key = payload.jumpToKey;
          const jumpToIndex = steps.indexOf(key);
          const newActiveStep = steps[jumpToIndex];
          const newMostAdvIndex =
            jumpToIndex > mostAdvStepIndex ? jumpToIndex : mostAdvStepIndex;
          const newMostAdvStep = steps[newMostAdvIndex];
          if (jumpToIndex === -1) return prevState;

          const jumpToStep = jumpToIndex + 1;
          return {
            ...prevState,
            activeStep: newActiveStep,
            activeStepIndex: jumpToIndex,
            mostAdvStep: newMostAdvStep,
            mostAdvStepIndex: newMostAdvIndex,
            percent: (jumpToStep / steps.length) * 100,
            hasPrevious: jumpToIndex > 0,
            hasNext: jumpToIndex < steps.length - 1,
            lastPayload: payload,
          };
        }
        default:
          return prevState;
      }
    };
    return reducer;
  } else {
    throw 'Please provide a stepReducer or stepSequence';
  }
};

export const WizardView = ProToolkit.withErrorBoundary(
  ({
    children,
    stepReducer,
    stepSequence,
    initialStep,
    title,
    errorBlockClass = 'tw-p-2',
    isInModal = true,
    useCustomHeader,
    debug = false,
  }) => {
    const [state, dispatch] = useReducer(
      makeReducer(stepSequence, stepReducer),
      getInitialState(initialStep, stepSequence, title)
    );
    const steps = useMemo(
      () => stepSequence.map((stepObj) => stepObj.id),
      [stepSequence]
    );
    const isDebug = debug && process.env.NODE_ENV === 'development';
    const goToNext = (payload) => {
      clearError();
      if (isPromise(payload)) {
        setIsLoading(true);
        payload
          .then((payloadAsync) => {
            dispatch({
              type: WIZARD_ACTIONS.NEXT,
              payload: { ...payloadAsync },
            });
          })
          .finally(() => setIsLoading(false));
      } else {
        dispatch({
          type: WIZARD_ACTIONS.NEXT,
          payload: { ...payload },
        });
      }
    };
    const goToPrevious = (payload) => {
      clearError();
      if (isPromise(payload)) {
        setIsLoading(true);
        payload
          .then((payloadAsync) => {
            dispatch({
              type: WIZARD_ACTIONS.PREVIOUS,
              payload: { ...payloadAsync },
            });
          })
          .finally(() => setIsLoading(false));
      } else {
        dispatch({
          type: WIZARD_ACTIONS.PREVIOUS,
          payload: { ...payload },
        });
      }
    };
    const setIsLoading = useCallback(
      (loading) => {
        dispatch({ type: WIZARD_ACTIONS.LOADING, payload: loading });
      },
      [dispatch]
    );
    const setError = (err) => {
      dispatch({ type: WIZARD_ACTIONS.ERROR, payload: err });
    };
    const clearError = () => {
      dispatch({ type: WIZARD_ACTIONS.ERROR, payload: '' });
    };
    const hasError = () => {
      return state.error !== '';
    };

    const jumpTo = (jumpToKey) => {
      dispatch({ type: WIZARD_ACTIONS.JUMP_TO, payload: { jumpToKey } });
    };

    const setTitle = (newTitle) => {
      dispatch({ type: WIZARD_ACTIONS.SET_TITLE, payload: newTitle });
    };

    const useVerticalProgress = (onSubmit) => {
      if (!stepSequence) return null;
      const { activeStep } = state;
      const { index, currentStep } = getWizardStepByKey(activeStep);
      return (
        <WizardProgress
          steps={stepSequence}
          onSubmit={onSubmit}
          currentStepIndex={index}
          currentStep={currentStep}
        />
      );
    };

    const renderHorizontalProgress = (onSubmit) => {
      if (!stepSequence) return null;
      const { activeStep } = state;
      const { index, currentStep } = getWizardStepByKey(activeStep);
      return (
        <WizardProgress
          steps={stepSequence}
          isVertical={false}
          onSubmit={onSubmit}
          currentStepIndex={index}
          currentStep={currentStep}
        />
      );
    };

    // can create your own progress component
    const useCustomProgressComponent = ({ component }) => {
      if (!stepSequence || !component) return null;
      const checkIsSelected = (step) => step.id === state.activeKey;
      return cloneElement(component, {
        activeKey: state.activeKey,
        jumpTo,
        checkIsSelected,
      });
    };

    const useSimpleProgressBar = () => {
      return (
        <nw-progress-bar
          percentage={state.percent}
          automation-id={'a'}
          style={{
            '--height': '3px',
            width: '100%',
          }}
        ></nw-progress-bar>
      );
    };

    const getWizardStepByKey = (key) => {
      if (!key) key = state.activeStep;
      const index = steps.findIndex((step) => step === key);
      const currentStep = stepSequence[index];
      return { index, currentStep };
    };

    const getTitle = () => {
      const { activeStep } = state;
      const { index, currentStep } = getWizardStepByKey(activeStep);

      const currentStepNum = index + 1;
      const totalSteps = stepSequence.length;
      const stepTitle = isFunction(currentStep?.text)
        ? currentStep.text({ currentStep, currentStepIndex: index })
        : currentStep?.text || '';
      const titleText = stepTitle
        ? `${title} - ${stepTitle}`
        : state.title || title;
      const progressText = `${currentStepNum}/${totalSteps}`;

      return `${titleText} (${progressText})`;
    };

    const useWizardDebugger = () => {
      if (!isDebug) return;
      return <WizardDebugger state={state} />;
    };

    const headerRef = useRef();

    const [headerHeight, setHeaderHeight] = useState(0);
    ProToolkit.useResizeObserver(headerRef, (observerEntry) => {
      const el = observerEntry.target;
      setHeaderHeight(el.offsetHeight);
    });

    const accessor = {
      // states
      activeKey: state.activeStep,
      activeStepIndex: state.activeStepIndex,
      mostAdvStepIndex: state.mostAdvStepIndex,
      error: state.error,
      percent: state.percent,
      hasNext: state.hasNext,
      hasPrevious: state.hasPrevious,
      isLoading: state.isLoading,
      lastPayload: state.lastPayload,
      title: state.title,
      stepSequence,
      steps,
      isInModal,
      headerHeight,
      headerRef,

      // functions (TODO: extract some helper functions to hooks in separate files)
      setTitle,
      goToNext,
      goToPrevious,
      setError,
      clearError,
      hasError,
      jumpTo,
      useVerticalProgress,
      renderHorizontalProgress,
      useCustomProgressComponent,
      useSimpleProgressBar,
      useWizardProgressBar,
      getTitle,
      getWizardStepByKey,
      useWizardDebugger,
    };
    const content = (
      <>
        {state.error && (
          <WizardError
            className={errorBlockClass}
            errorText={state.error}
          ></WizardError>
        )}
        {children}
      </>
    );

    return (
      <WizardContext.Provider value={accessor}>
        {/* Wizard Header */}
        {isFunction(useCustomHeader) ? (
          useCustomHeader({ className: WIZARD_HEADER_CLS })
        ) : (
          // FIXME: Remove this container when all WizardView user are converted to ProForm
          <div
            ref={headerRef}
            slot='header'
            className={cn(
              'tw-flex tw-flex-col tw-w-full tw-group',
              WIZARD_HEADER_CLS
            )}
          >
            <ProForm.Header>{getTitle()}</ProForm.Header>
            {useWizardProgressBar(accessor)}
          </div>
        )}

        {/* Wizard Debugger */}
        {useWizardDebugger()}

        {/* Wizard Content */}
        {state.isLoading ? (
          <nw-spinner style={spinnerCss}></nw-spinner>
        ) : (
          content
        )}
      </WizardContext.Provider>
    );
  }
);
