import { useEffect, useRef, useState } from 'react';
import { colorName } from './color_name';
import { ProLego } from '@fafm/neowise-pro';
import cn from 'classnames';
import './color_picker.less';
import { NwIcon, NwButton } from '@fafm/neowise-core';
const { Popover } = ProLego;

export { colorName };
export default function IconColorPicker({
  icon = { name: 'square' },
  colorRange = [1, 32],
  value = colorRange[0],
  onChange: propOnChange,
  'automation-id': autoId,
}) {
  const [minColor, maxColor] = colorRange;
  const toValidValue = boundedValue(minColor, maxColor);
  value = toValidValue(value);

  const popoverRef = useRef(null);
  const onSelect = (newValue) => {
    popoverRef.current?.setOpen(false);
    if (value !== newValue) {
      propOnChange(newValue);
    }
  };

  return (
    <div className='tw-flex tw-items-center tw-gap-1'>
      <NwIcon
        {...icon}
        automation-id={`${autoId}:icon`}
        className={`clr-opt-${value}`}
        label={colorName[`bg-clr-opt-${value}`]}
        style={{ width: '1.5rem', height: '1.5rem' }}
      />

      <Popover
        padding=''
        ref={popoverRef}
        content={() => (
          <ColorPane
            value={value}
            onSelect={onSelect}
            minColor={minColor}
            maxColor={maxColor}
            automationId={autoId}
          />
        )}
      >
        <NwButton type='secondary' automation-id={`${autoId}:trigger`}>
          {gettext('Change')}
        </NwButton>
      </Popover>
    </div>
  );
}

export const ColorPane = ({
  value,
  onSelect,
  minColor = 1,
  maxColor = 32,
  automationId,
}) => {
  const toValidValue = boundedValue(minColor, maxColor);

  const [activeValue, _] = useState(() => toValidValue(value));
  const setActiveValue = (value) => _(toValidValue(value));

  const listRef = useRef(null);
  // read it here on purpose, so this component wont take over the focus on mount
  const listNode = listRef.current;
  useEffect(() => {
    if (!listNode) return;
    const node = Array.from(listNode.children).find(
      (node) => node.value === activeValue
    );
    node?.focus();
  }, [activeValue]);

  const selectValue = (newValue) => {
    newValue = toValidValue(newValue);
    setActiveValue(newValue);
    onSelect(newValue);
  };

  const ifSelectable = (handler) => (event) => {
    const { target } = event;
    if (target.tagName !== 'LI') return;

    const stopDefault = () => {
      event.stopPropagation();
      event.preventDefault();
    };

    handler(event, stopDefault);
  };

  const handleListKeyDown = (event, stopDefault) => {
    const { target, key } = event;
    if (key == 'Enter') {
      selectValue(target.value);
      stopDefault();
    } else if (key == 'ArrowUp' || key == 'ArrowLeft') {
      setActiveValue((target.previousElementSibling || target).value);
      stopDefault();
    } else if (key == 'ArrowDown' || key == 'ArrowRight') {
      setActiveValue((target.nextElementSibling || target).value);
      stopDefault();
    }
  };

  const handleListKeyUp = (event, stopDefault) => {
    const { target, key } = event;
    // handle space case in keyup or popover is triggered again after select
    if (key == ' ') {
      selectValue(target.value);
      stopDefault();
    }
  };

  return (
    <div style={{ maxWidth: '19rem' }}>
      <ul
        className='tw-list-none tw-p-2 tw-m-0 tw-flex tw-flex-wrap tw-items-center tw-justify-center'
        onKeyDown={ifSelectable(handleListKeyDown)}
        onKeyUp={ifSelectable(handleListKeyUp)}
        ref={listRef}
      >
        {Array.from(
          { length: maxColor - minColor + 1 },
          (_, i) => i + minColor
        ).map((colorValue) => {
          const colorClass = 'bg-clr-opt-' + colorValue;
          const selected = value === colorValue;
          return (
            <li
              key={colorValue}
              role='button'
              value={colorValue}
              onClick={() => selectValue(colorValue)}
              className={cn('tw-m-0.5', {
                'colorPicker__color-selected': selected,
              })}
              automation-id={automationId + ':' + colorValue}
              tabIndex={activeValue === colorValue ? 0 : -1}
              title={colorName[colorClass]}
            >
              <div className={`tw-h-6 tw-w-6 tw-m-1 ${colorClass}`}></div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const boundedValue = (min, max) => (value) =>
  Math.max(min, Math.min(value, max)) || min;
