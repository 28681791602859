import { fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { setFwmProf } from 'fi-dvm-fwm-prof';
import { objectArrayToMap } from 'kit-array';
import { fguardFMWImageService } from 'ra_fortiguard_util';

/**
 * Fetches one cate of asset devices (FAP, FSW, FEXT)
 * @param {string} cate
 * @param {number} adomOid
 * @returns devices[]
 */
export function fetchAssetData(cate, adom) {
  return fiFmgHttp
    .forward({
      method: 'get',
      params: [
        {
          url: `pm/config/adom/${adom}/obj/${cate}`,
          'scope member': [
            {
              name: 'All_FortiGate',
            },
          ],
        },
      ],
    })
    .then((resp) => {
      if (Array.isArray(resp)) {
        return resp.reduce((pre, cur) => pre.concat(cur.data || []), []);
      }
      return resp.data || [];
    })
    .catch(() => {
      return [];
    });
}

export function addFirmwareTemplate(templateData, assignToDevs) {
  const adom = fiAdom.current();
  const req = {
    method: 'add',
    params: [
      {
        data: {
          ...templateData,
          'scope member': assignToDevs,
        },
        url: `pm/fwmprof/adom/${adom.name}`,
      },
    ],
  };

  return fiFmgHttp.query(req);
}
function mergeObjectArraysByKey(arr1 = [], arr2 = [], key) {
  if (!arr1.length) return arr2;
  if (!arr2.length) return arr1;

  const res = [...arr1];

  const mapOfArr1 = objectArrayToMap(arr1, key);

  arr2.forEach((obj) => {
    const thisKey = obj[key];
    if (!mapOfArr1[thisKey]) res.push(obj);
  });

  return res;
}

export function setFirmwareTemplate(templateData, assignToDevs) {
  const adom = fiAdom.current();
  const scopeMembers = mergeObjectArraysByKey(
    templateData['scope member'],
    assignToDevs,
    'name'
  );
  const data = {
    ...templateData,
    'scope member': scopeMembers,
  };
  return setFwmProf(adom, data, templateData.name);
}

/**
 * Gets all valid images for a given platform
 * @param {*} platformStr e.g. 'FortiAP-231E'
 * @param {*} productStr e.g. 'FAP', 'FSW', 'FEXT', or 'FGT'
 * @returns array of image version objects
 */
export const getDeviceImages = async (platformStr, productStr) => {
  let versionListResp = await fguardFMWImageService.getAllVersionsList(
    platformStr,
    productStr
  );
  return versionListResp?.[0]?.versions || [];
};
