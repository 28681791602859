import { fiAdom, fiSysConfig } from 'fi-session';
import { cloneDeep, forEach } from 'lodash';
import { fiDvmListViewType } from 'fi-dvm';
import { DVM_colDefs } from './DVM_colDefs';

/**
 * Gets column definition for FortiAnalyzer
 */
const getFAZ = function (viewType) {
  switch (viewType) {
    case fiDvmListViewType.UNREGISTERED:
      return DVM_colDefs.FAZ_unreg_colDef;
    case fiDvmListViewType.MANAGED:
      return DVM_colDefs.FAZ_all_fortigate_colDef;
    default:
      return DVM_colDefs.FAZ_all_fortigate_colDef;
  }
};

/**
 * Gets column definition for FortiManager
 */
const getFMG = function (viewType) {
  var isBkpAdom = fiAdom.isBackupAdom();

  switch (viewType) {
    case fiDvmListViewType.UNREGISTERED:
      return DVM_colDefs.FMG_unreg_colDef;
    case fiDvmListViewType.MANAGED:
      return isBkpAdom
        ? DVM_colDefs.FMG_BKP_managed_colDef
        : DVM_colDefs.FMG_managed_colDef;
    case fiDvmListViewType.REMOTE_FAZ:
      return DVM_colDefs.FMG_managed_faz_colDef;
    case fiDvmListViewType.LOGGING:
      return DVM_colDefs.FAZ_all_fortigate_colDef;
    case fiDvmListViewType.MANAGED_PRIVATE_DATA_ENC_DEVS:
      return DVM_colDefs.FMG_data_enc_devs_colDef;
    default:
      return DVM_colDefs.FMG_managed_colDef;
  }
};

function attachMetaFields(colDefs, metaFields) {
  const cols = colDefs;
  forEach(metaFields, function (meta) {
    if (meta.status) {
      cols.push({
        field: 'meta_' + meta.name,
        txt: meta.name,
        hidden: meta.importance === MACROS.SYS.METADATA_IMPORTANCE_OPTIONAL,
        minWidth: 80,
        width: 150,
        metaFieldType: meta._type,
        metaFieldImportance: meta.importance,
        metaFieldLength: meta.length,
      });
    }
  });
  return cols;
}
const getColDefKey = function (viewType) {
  return 'col_def_' + viewType;
};

export const fiDvmColDefs = {
  get: function (viewType, metaFields) {
    let result_coldef = [];
    if (fiSysConfig.hasFmgFeature()) {
      result_coldef = cloneDeep(getFMG(viewType));
    } else {
      result_coldef = cloneDeep(getFAZ(viewType));
    }
    if (
      metaFields &&
      (viewType === fiDvmListViewType.MANAGED ||
        viewType === fiDvmListViewType.LOGGING)
    ) {
      result_coldef = attachMetaFields(result_coldef, metaFields);
    }
    return result_coldef;
  },
  getColDefKey,
};
