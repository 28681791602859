import { RenderErrorBoundary } from 'react_apps/components/main/RenderErrorBoundary';
import { dispatch } from 'fistore';
import { fetchDockerStatus } from 'fistore/docker/slice';

export default {
  path: 'extension',
  RenderErrorBoundary,
  handle: {
    appUniKey: 'docker',
  },

  children: [
    {
      path: 'dashboard',
      handle: {
        appUniKey: 'docker',
      },
      lazy: async () => {
        const { ExtensionsTileViewRoot } = await import('./ExtensionsTileView');
        return {
          Component: ExtensionsTileViewRoot,
        };
      },
      loader: () => {
        dispatch(fetchDockerStatus());
        return null;
      },
    },
    {
      path: 'app/:extName',
      handle: {
        appUniKey: 'docker',
      },
      lazy: async () => {
        const { ExtensionView } = await import('./ExtensionFrame');
        return {
          Component: ExtensionView,
        };
      },
    },
  ],
};
