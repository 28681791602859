import { useCallback, useState } from 'react';
import { InputGroup, LoginInput } from './InputGroup';
import { showIfTruthy } from 'react_apps/ra-auth/utils';
import { isEmpty } from 'lodash';
import { LoginButton } from 'react_apps/ra-auth/widgets/LoginButton';

export function DefaultInputs({
  failureMessage,
  initUsername = '',
  initPassword = '',
  onLogin,
  showChallenge,
  disableLogin = false,
  disableUsername = false,
}) {
  const [username, setUsername] = useState(initUsername);
  const [password, setPassword] = useState(initPassword);
  const [challengeInput, setChallengeInput] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const onUsernameInput = useCallback((e) => {
    setUsername(e.target.value);
  }, []);

  const onPasswordInput = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const onChallengeInput = useCallback(
    (e) => {
      showChallenge && setChallengeInput(e.target.value);
    },
    [showChallenge]
  );

  const onLoginClick = useCallback(async () => {
    if (isLoggingIn) return;
    setIsLoggingIn(true);
    try {
      await onLogin({ username, password, challengeInput });
    } finally {
      setIsLoggingIn(false);
    }
  }, [onLogin, isLoggingIn, username, password, challengeInput]);

  const onInputKeyUp = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onLoginClick();
      }
    },
    [onLoginClick]
  );

  const isFailure = !isEmpty(failureMessage);

  return (
    <>
      <InputGroup className={showIfTruthy(isFailure && !isLoggingIn)}>
        <span className='tw-text-sm tw-text-danger'>{failureMessage}</span>
      </InputGroup>
      <InputGroup>
        <LoginInput
          placeholder={gettext('Username')}
          icon='administrator'
          value={username}
          onInput={onUsernameInput}
          onKeyUp={onInputKeyUp}
          disabled={
            isLoggingIn || showChallenge || disableLogin || disableUsername
          }
          autoFocus
          automation-id='username'
        ></LoginInput>
      </InputGroup>
      <InputGroup>
        <LoginInput
          type='password'
          togglePassword={false}
          placeholder={gettext('Password')}
          icon='lock'
          isPassword
          value={password}
          onInput={onPasswordInput}
          onKeyUp={onInputKeyUp}
          disabled={isLoggingIn || showChallenge || disableLogin}
          automation-id='password'
        ></LoginInput>
      </InputGroup>
      <InputGroup className={showIfTruthy(showChallenge)}>
        <LoginInput
          type='password'
          togglePassword={false}
          placeholder={gettext('Token Code')}
          icon='fortitoken'
          value={challengeInput}
          onInput={onChallengeInput}
          onKeyUp={onInputKeyUp}
          disabled={isLoggingIn}
          automation-id='token-code'
          focus={showChallenge}
        ></LoginInput>
      </InputGroup>
      <InputGroup>
        <LoginButton
          type='primary'
          onClick={onLoginClick}
          disabled={isLoggingIn || disableLogin}
          automation-id='login-btn'
          loading={isLoggingIn}
          text={gettext('Login')}
        />
      </InputGroup>
    </>
  );
}
