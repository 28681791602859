import { debuggerDidStart, createCaptureFile } from '../debugger_utils';
import { ResourceType, Timestamped } from '../types';
import { get } from 'lodash';

type ResourceResponse = {
  cpu: { percent: number }[];
  cpu_avg: number;
  disk: number;
  memory: number;
};

export const register = () => {
  return debuggerDidStart(async () => {
    const { fiHttp } = await import('fi-web/fi-http');

    function getResources() {
      return fiHttp
        .post(
          '/cgi-bin/module/flatui_proxy',
          {
            method: 'get',
            url: '/gui/sys/resource/realtime',
          },
          {
            params: {
              nocache: Date.now(),
              debugger: 'ignore',
            },
          }
        )
        .then((resp: any) => {
          const data: ResourceResponse = get(resp, 'data.result.[0].data', {});

          return data
            ? [
                Array.isArray(data.cpu)
                  ? data.cpu.map((core) => core.percent)
                  : [data.cpu],
                [data.memory],
              ]
            : [];
        });
    }

    return sampleResourceByPolling(
      [
        {
          name: 'device-cpu.resource.json',
          metadata: {
            type: ResourceType.Percentage,
            name: 'Device CPU',
          },
        },
        {
          name: 'device-memory.resource.json',
          metadata: {
            type: ResourceType.Bytes,
            name: 'Device Memory',
          },
        },
      ],
      getResources
    );
  });
};

async function sampleResourceByPolling(
  files: {
    name: string;
    metadata: { type: ResourceType; name: string };
  }[],
  query: () => Promise<number[][]>
) {
  const fileCaptures = await Promise.all(
    files.map(({ name: fileName, metadata }) => {
      return createCaptureFile<Timestamped<{ value: number[] }>>(fileName, {
        metadata,
        spreadMetadata: true,
      });
    })
  );

  const stopCapture = () => {
    if (sampleTimeout && sampleTimeout !== true) {
      clearTimeout(sampleTimeout);
      sampleTimeout = false;
    }
    fileCaptures.map(({ complete }) => complete());
  };

  let sampleTimeout: number | boolean = true;

  async function sampleImpl() {
    const result = await query();
    const timestamp = Date.now();

    await Promise.all(
      fileCaptures.map(({ send }, i) => {
        send({
          timestamp,
          value: result[i],
        });
      })
    );

    if (sampleTimeout) {
      sampleTimeout = window.setTimeout(sample, 1000);
    }
  }

  function sample() {
    sampleImpl().catch(stopCapture);
  }

  sample();

  return stopCapture;
}
