const deviceCodes = MACROS.USER.PLATFORMS;
let dvmProdToOsType = {};
let dvmPlatformOptList = [];
let dvmPlatformNameMap = {};

let getPlatformName = (code) => {
  let rootName = MACROS.DVM[`DVM_ADOM_${code}_ROOT_NAME`];
  if (rootName === MACROS.DVM.DVM_ADOM_FTS_ROOT_NAME) {
    // Unmanaged_Devices
    return MACROS.USER.PLATFORM_BY_ABBREV[code] || code;
  }
  return rootName || code;
};
deviceCodes.forEach(function (code) {
  dvmProdToOsType[MACROS.DVM['DVM_RESTRICTED_PRD_' + code]] =
    MACROS.DVM['DVM_OS_TYPE_' + code];
});

deviceCodes.forEach((code) => {
  dvmPlatformOptList.push({
    text:
      MACROS.DVM[`DVM_OS_TYPE_${code}`] === MACROS.DVM.DVM_OS_TYPE_FOS
        ? 'FortiGate'
        : getPlatformName(code),
    id: MACROS.DVM[`DVM_OS_TYPE_${code}`],
  });
});

deviceCodes.forEach((code) => {
  dvmPlatformNameMap[code] =
    MACROS.DVM[`DVM_OS_TYPE_${code}`] === MACROS.DVM.DVM_OS_TYPE_FOS
      ? 'FortiGate'
      : getPlatformName(code);
});

export const fiDvmprodToOstype = (dvmprod) => {
  return dvmProdToOsType[dvmprod];
};

export const fiDvmPlatformOptList = () => dvmPlatformOptList;

export const fiDvmPlatformNameMap = () => dvmPlatformNameMap;
