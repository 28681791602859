var __next_objid = 1;
function next_obj_id() {
  return __next_objid++;
}

export class TreeNode {
  constructor(
    data,
    {
      isOpenByDefault = false,
      iconClass = '',
      iconTitle = '',
      contentComponent = null,
      iconProps,
      className,
      disabled,
    } = {}
  ) {
    this._data = data;
    this._children = [];

    this._isOpenByDefault = isOpenByDefault;
    this._iconClass = iconClass;
    this._iconTitle = iconTitle || data?.title;
    this._iconProps = iconProps;
    this._className = className;
    this._disabled = disabled;

    this._contentComponent = contentComponent;
    this._pnode = null;
    this._obj_id = next_obj_id();
    this._automationId = '';
    this._menuRef = null;
    //this._key=   (pTreeNode?pTreeNode.key+':':'')+this._data.id
    //this._deepth= pTreeNode?pTreeNode.deepth+1:0
  }
  clone() {
    const node = new TreeNode();
    node._data = this._data;
    node._children = this._children;

    node._isOpenByDefault = this._isOpenByDefault;
    node._iconClass = this._iconClass;
    node._iconTitle = this._iconTitle;
    node._contentComponent = this._contentComponent;
    node._pnode = this._pnode;
    node._obj_id = this._obj_id;
    // M748721: Keep original autoID when cloning node
    node._automationId = this._automationId;
    node._menuRef = this._menuRef;

    return node;
  }
  get objId() {
    return this._obj_id;
  }
  set parent(pnode) {
    this._pnode = pnode;
  }
  get parent() {
    return this._pnode;
  }
  get data() {
    return this._data;
  }
  get id() {
    return this._data.id || this._data.oid;
  }
  get name() {
    return this._data.name;
  }
  get isLeaf() {
    return this.data._isLeaf ?? this._children.length == 0;
  }
  get children() {
    return this._children;
  }

  addChild(node) {
    node.parent = this;
    this._children.push(node);
    return this;
  }
  get key() {
    if (!this._key) {
      this._key = (this._pnode ? this._pnode.key + ':' : '') + this.id;
    }
    return this._key;
  }
  get deepth() {
    if (typeof this._deepth === 'undefined')
      this._deepth = this._pnode ? this._pnode.deepth + 1 : 0;

    return this._deepth;
  }
  set isOpenByDefault(flag) {
    this._isOpenByDefault = flag;
  }
  get isOpenByDefault() {
    return this._isOpenByDefault;
  }
  set iconClass(icon) {
    this._iconClass = icon;
  }
  get iconClass() {
    return this._iconClass || '';
  }
  set iconTitle(txt) {
    this._iconTitle = txt;
  }
  get iconTitle() {
    return this._iconTitle || '';
  }
  set iconProps(iconProps) {
    this._iconProps = iconProps;
  }
  get iconProps() {
    return this._iconProps;
  }
  set className(className) {
    this._className = className;
  }
  get className() {
    return this._className;
  }
  set disabled(disabled) {
    this._disabled = disabled;
  }
  get disabled() {
    return this._disabled;
  }
  /*
      component: string or function
  */
  set contentComponent(component) {
    this._contentComponent = component;
  }
  get contentComponent() {
    return this._contentComponent;
  }
  set automationId(id) {
    this._automationId = id;
  }
  get automationId() {
    return this._automationId || this.key;
  }
  get menuRef() {
    return this._menuRef;
  }
  set menuRef(ref) {
    this._menuRef = ref;
  }
  static fromStatic(data, selected, getAutomationId) {
    let nodes = buildFromStatic(data);
    nodes = expandNodeToSelected(nodes, selected);
    nodes = setNodesAutomationIds(nodes, getAutomationId);
    return nodes;
  }
  static bySearch(treeNode, text) {
    return filterBySearchTreeNode(treeNode, text.toLowerCase());
  }
}

export function createTreeNode(...rest) {
  return new TreeNode(...rest);
}

export function buildFromStatic(data) {
  if (!Array.isArray(data)) {
    const node = new TreeNode(data);
    if (data.isOpenByDefault) node.isOpenByDefault = true;
    if (data.iconClass) node.iconClass = data.iconClass;
    if (data.iconTitle) node.iconTitle = data.iconTitle;
    if (data.contentComponent) node.contentComponent = data.contentComponent;
    if (data.iconProps) node.iconProps = data.iconProps;
    if (data.className) node.className = data.className;
    if (data.disabled) node.disabled = data.disabled;

    if (data.children && data.children.length > 0) {
      data.children
        .map((x) => buildFromStatic(x))
        .forEach((x) => node.addChild(x));
    }
    return node;
  }
  return data.map((x) => buildFromStatic(x));
}

function expandNodeToSelected(nodes, selectedKey) {
  const checkNode = (node) => {
    if (node.key === selectedKey) {
      setParentNodesOpen(node);
      return true;
    }
    if (node.children.length) {
      return node.children.some(checkNode);
    }
  };
  nodes.some(checkNode);
  return nodes;
}

function setParentNodesOpen(node) {
  if (node._pnode) {
    node._pnode.isOpenByDefault = true;
    setParentNodesOpen(node._pnode);
  }
}

function setNodesAutomationIds(nodes, getAutomationId) {
  const setNodeAutomationId = (node) => {
    node.automationId = getAutomationId(node) || '';
    node.children?.forEach?.(setNodeAutomationId);
  };

  nodes.forEach(setNodeAutomationId);
  return nodes;
}

export function filterBySearchTreeNode(treeNode, text) {
  if (Array.isArray(treeNode)) {
    return treeNode
      .map((n) => filterBySearchTreeNode(n, text))
      .filter((x) => x);
  } else {
    if (treeNode.name.toLowerCase().indexOf(text) > -1) {
      return treeNode;
    }

    const newChildren = filterBySearchTreeNode(treeNode.children, text);
    if (newChildren.length == 0) return null;

    const newNode = treeNode.clone();
    newNode._children = newChildren;
    newNode._isOpenByDefault = true;
    newNode._children.forEach((n) => (n.parent = newNode));

    return newNode;
  }
}
