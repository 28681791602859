import { useField } from 'formik';
import { isFunction } from 'lodash';

import { DeviceSSelect } from './DeviceSSelect';

export const FmkDeviceSSelect = ({
  name,
  validate,
  'automation-id': propAutoId,
  automationId,
  onChange,
  callAllOnChange = false,
  ...rest
}) => {
  const [{ value }, , { setValue, setTouched }] = useField({
    name,
    validate,
  });
  return (
    <DeviceSSelect
      name={name}
      value={value}
      onChange={(dev) => {
        setTouched(true, false);
        if (isFunction(onChange)) {
          onChange(dev, setValue);
          if (callAllOnChange) {
            setValue(dev);
          }
        } else {
          setValue(dev);
        }
      }}
      automation-id={propAutoId || automationId}
      {...rest}
    />
  );
};
