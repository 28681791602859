import { useState, useCallback, lazy, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { NwRadioGroup, NwRadio, NwIcon, NwIpV4Input } from '@fafm/neowise-core';
import { ProToolkit } from '@fafm/neowise-pro';

import { LoginFormBase } from 'react_apps/ra-auth/widgets/LoginFormBase';
import { InputGroup, LoginInput } from 'react_apps/ra-auth/widgets//InputGroup';
import { OrLine } from 'react_apps/ra-auth/widgets/OrLine';
import { EulaModal } from 'react_apps/ra-auth/modals/EulaModal';
import { showIfTruthy } from './utils';
import { LoginButton } from './widgets/LoginButton';
import {
  forticloudGetTrialLicense,
  forticloudActivateLicense,
} from 'fistore/auth/slice';
import RestartModal from 'react_apps/ra-auth/modals/RestartModal';
import LoginPageContainer from 'react_apps/ra-auth/widgets/LoginPageContainer';
import { UploadLicenseForm } from 'react_components/rc_license/UploadLicenseForm';
import { useNavigate } from 'react-router-dom';
import { useRestartTimer } from 'react_apps/ra-auth/hooks/useRestartTimer';

const onInput = (setter) => (e) => {
  setter(e.target.value);
};

const TrialEulaHtml = lazy(() => import('./modals/TrialEulaHtml'));
const FullEulaHtml = lazy(() => import('./modals/FullEulaHtml'));

export function NewLicLoginPage() {
  const dispatch = useDispatch();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [regCode, setRegCode] = useState('');
  const [ipAddr, setIpAddr] = useState('');
  const ipAddrRef = useRef(null);
  const [licenseType, setLicenseType] = useState('trial');
  const [failureMessage, setFailureMessage] = useState('');
  const navigate = useNavigate();
  const [isInvalid, setIsInvalid] = useState(false);
  const waitRestart = useRestartTimer();

  useEffect(() => {
    const invalid =
      isEmpty(username) ||
      isEmpty(password) ||
      (licenseType !== 'trial' &&
        (isEmpty(regCode) || !ipAddrRef.current?.checkValidity()));
    setIsInvalid(invalid);
  }, [username, password, licenseType, regCode, ipAddr]);

  const uploadLicense = useCallback((e) => {
    e.preventDefault();
    return ProToolkit.openModal(
      <UploadLicenseForm
        url='/flatui/auth/gui/vmlicense/initialupload'
        doLogout={false}
        onSuccess={waitRestart}
        autoIdPrefix='license_upload'
      />,
      { closable: false }
    ).result;
  }, []);

  const isActivateLicense = licenseType === 'activateLicense';

  // shows a waiting dialog until the system restarts
  const openRestartModal = (message) => {
    return ProToolkit.openModal(<RestartModal message={message} />, {
      size: 'md',
      maximizable: false,
      closable: false,
    }).result;
  };

  const openEulaModal = (modalEl) => {
    return ProToolkit.openModal(modalEl, {
      size: 'lg',
      maximizable: false,
    }).result;
  };

  const onLoginClick = useCallback(async () => {
    if (isLoggingIn) return;
    setIsLoggingIn(true);
    setFailureMessage('');

    if (licenseType === 'trial') {
      try {
        await openEulaModal(
          <EulaModal
            EulaHtml={TrialEulaHtml}
            header={gettext('Free Trial License Agreement')}
          />
        );
        await dispatch(
          forticloudGetTrialLicense({ username, password })
        ).unwrap();

        await openRestartModal(
          gettext(
            'You have logged in successfully to FortiCloud. The system is restarting to apply the trial license. Please wait for a few moments.'
          )
        );
        navigate('/login');
      } catch (err) {
        setIsLoggingIn(false);
        err && setFailureMessage(err?.status?.message);
      }
      return;
    }

    if (licenseType === 'activateLicense') {
      try {
        await openEulaModal(
          <EulaModal
            EulaHtml={FullEulaHtml}
            header={gettext('Free Trial License Agreement')}
          />
        );
        await dispatch(
          forticloudActivateLicense({ username, password, regCode, ipAddr })
        ).unwrap();
      } catch (err) {
        setIsLoggingIn(false);
        err && setFailureMessage(err?.status?.message);
      }
    }
  }, [isLoggingIn, username, password, licenseType, regCode, ipAddr, dispatch]);

  // click login if hit Enter key
  const onInputKeyUp = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onLoginClick();
      }
    },
    [onLoginClick]
  );

  const onLicenseTypeChange = useCallback(
    (e, val) => {
      setLicenseType(val);
    },
    [licenseType]
  );

  return (
    <LoginPageContainer>
      <LoginFormBase>
        <div className='tw-text-left'>
          {gettext(
            'This product requires a valid license. You could log in to FortiCloud to activate your purchased license, or use a free trial license.'
          )}
        </div>{' '}
        <InputGroup className={showIfTruthy(!isLoggingIn)}>
          <span className='tw-text-sm tw-text-danger'>{failureMessage}</span>{' '}
        </InputGroup>
        <InputGroup>
          <LoginInput
            placeholder={gettext('Account ID/Email')}
            icon='administrator'
            value={username}
            required
            onInput={onInput(setUsername)}
            onKeyUp={onInputKeyUp}
            disabled={isLoggingIn}
            autoFocus
            automation-id='username'
          ></LoginInput>
        </InputGroup>
        <InputGroup>
          <LoginInput
            type='password'
            togglePassword={false}
            placeholder={gettext('Password')}
            icon='lock'
            isPassword
            required
            value={password}
            onInput={onInput(setPassword)}
            onKeyUp={onInputKeyUp}
            disabled={isLoggingIn}
            automation-id='password'
          ></LoginInput>
        </InputGroup>
        <InputGroup className='tw-text-left'>
          <NwRadioGroup
            value={licenseType}
            onChange={onLicenseTypeChange}
            automation-id='license-type-radio-options'
          >
            <NwRadio value='trial' className='tw-mb-2'>
              {gettext('Free Trial')}
            </NwRadio>
            <NwRadio value='activateLicense'>
              {gettext('Activate License')}
            </NwRadio>
          </NwRadioGroup>
        </InputGroup>
        <InputGroup className={showIfTruthy(isActivateLicense)}>
          <LoginInput
            placeholder={gettext('Registration Code')}
            value={regCode}
            onInput={onInput(setRegCode)}
            onKeyUp={onInputKeyUp}
            automation-id='registration-num-input'
            required={isActivateLicense}
          ></LoginInput>
        </InputGroup>
        <InputGroup className={showIfTruthy(isActivateLicense)}>
          <NwIpV4Input
            size='large'
            placeholder={gettext('IP Address')}
            value={ipAddr}
            onInput={onInput(setIpAddr)}
            onKeyUp={onInputKeyUp}
            ref={ipAddrRef}
            required={isActivateLicense}
            automation-id='ip-address-input'
            disableCIDR={true}
          ></NwIpV4Input>
        </InputGroup>
        <InputGroup>
          <LoginButton
            automation-id='login-btn'
            className='focus:tw-shadow-focus'
            type='primary'
            icon={<NwIcon library='fgt-products' name='forticloud' />}
            text={gettext('Login with FortiCloud')}
            loading={isLoggingIn}
            onClick={onLoginClick}
            disabled={isInvalid}
          />
        </InputGroup>
        <OrLine />
        <InputGroup>
          <LoginButton
            icon={<NwIcon library='fgt-products' name='forticloud' />}
            text={gettext('Register with FortiCloud')}
            href='https://support.fortinet.com/Login/CreateAccount.aspx'
            target='_blank'
          />
        </InputGroup>
        <InputGroup>
          <a href='#' onClick={uploadLicense}>
            {gettext('Upload License')}
          </a>
        </InputGroup>
      </LoginFormBase>
    </LoginPageContainer>
  );
}
