import { RenderErrorBoundary } from 'react_apps/components/main/RenderErrorBoundary';
import { fiFmgHttp } from 'fi-web/fi-http/fmg_json_api';
import { isFmg } from 'fistore/session/sysConfig/selectors';
import { get } from 'lodash';
import { ReactContentViewContainer } from 'react_apps/ra_main/main_layout/ReactContentViewContainer';
import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';

const getTaskmonitorRoute = (isAdvanced) => {
  // task monitior is under advanced menu for faz, but not for fmg
  if ((isFmg() && isAdvanced) || (!isFmg() && !isAdvanced)) return [];

  return [
    {
      path: 'taskmonitor',
      handle: {
        appUniKey: isFmg() ? 'sys_task_fmg' : 'sys_task_faz',
      },
      lazy: async () => {
        let { AllTasksMonitor } = await import(
          /* webpackChunkName: "mod-sys-taskmonitor" */ './components/taskMonitor/AllTasksMonitor'
        );
        return { Component: AllTasksMonitor };
      },
    },
  ];
};

export default {
  path: 'sys',
  RenderErrorBoundary,
  handle: {
    appUniKey: 'sys',
  },
  children: [
    getRedirectIndexRoute(),
    {
      path: 'alladoms',
      handle: {
        appUniKey: 'sys_alladoms',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-sys-alladoms" */ './components/alladoms/AllAdoms'
        ),
    },
    {
      path: 'administrator',
      handle: {
        appUniKey: 'sys_admin_user',
        cli: 'config system admin user',
      },
      loader: async () => {
        try {
          const resp = await fiFmgHttp.query({
            method: 'get',
            params: [
              {
                url: '/cli/global/system/metadata/admins',
              },
            ],
          });
          const data = resp?.[0].data ?? [];
          return { rMetaFields: data.filter((dat) => dat.status) };
        } catch {
          return { rMetaFields: [] };
        }
      },
      lazy: async () => {
        let { Administrator } = await import(
          /* webpackChunkName: "mod-sys-administrator" */ './components/Administrator/Administrator'
        );
        return { Component: Administrator };
      },
    },
    {
      path: 'profile',
      handle: {
        appUniKey: 'sys_admin_profile',
        cli: 'config system admin profile',
      },
      lazy: async () => {
        let { Profile } = await import(
          /* webpackChunkName: "mod-sys-profile" */ './components/profile/Profile'
        );
        return { Component: Profile };
      },
    },
    {
      path: 'authserver',
      handle: {
        appUniKey: 'sys_admin_authserver',
        cli: 'config system admin',
      },
      lazy: async () => {
        let { RemoteAuthServer } = await import(
          /* webpackChunkName: "mod-sys-profile" */ './components/remoteAuthServer/RemoteAuthServer'
        );
        return { Component: RemoteAuthServer };
      },
    },
    {
      path: 'sso_settings',
      handle: {
        appUniKey: 'sys_admin_sso',
        cli: 'config system saml',
      },
      lazy: async () => {
        let { SsoSettings } = await import(
          /* webpackChunkName: "mod-sys-sso" */ './components/ssoSettings/SsoSettings'
        );
        return { Component: SsoSettings };
      },
    },
    {
      path: 'adminSettings',
      handle: {
        appUniKey: 'sys_admin_setting',
        cli: 'config system admin setting',
      },
      lazy: async () => {
        let { AdminSettings } = await import(
          /* webpackChunkName: "mod-sys-setting" */ './components/AdminSettings/AdminSettings'
        );
        return { Component: AdminSettings };
      },
    },
    {
      path: 'ha',
      handle: {
        appUniKey: 'sys_ha',
        cli: 'config system ha',
      },
      lazy: async () => {
        if (isFmg()) {
          let { FiSysHa } = await import(
            /* webpackChunkName: "mod-sys-ha" */ './components/ha/FiSysHa'
          );
          return { Component: FiSysHa };
        } else {
          let { FiFazSysHa } = await import(
            /* webpackChunkName: "mod-sys-faz-ha" */ './components/ha/FiFazSysHa'
          );
          return { Component: FiFazSysHa };
        }
      },
    },
    {
      path: 'network',
      handle: {
        appUniKey: 'sys_network_setting',
        cli: 'config system interface',
      },
      lazy: async () => {
        let { Network } = await import(
          /* webpackChunkName: "mod-sys-network" */ './components/network/Network'
        );
        return { Component: Network };
      },
    },
    {
      path: 'raid',
      handle: {
        appUniKey: 'sys_raid',
      },
      loader: async () => {
        try {
          const resp = await fiFmgHttp.post({
            url: '/gui/sys/raid',
            method: 'getDiskCount',
          });
          const count = get(resp, '0.data.disk_count') || 0;
          return { rRaidDiskCount: count };
        } catch {
          return { rRaidDiskCount: 0 };
        }
      },
      lazy: async () => {
        let { RaidManagement } = await import(
          /* webpackChunkName: "mod-sys-raid" */ './components/raid/RaidManagement'
        );
        return { Component: RaidManagement };
      },
    },
    ...getTaskmonitorRoute(false),
    {
      path: 'eventlog/:key?',
      handle: {
        appUniKey: 'sys_eventlog',
        cli: 'config system locallog setting',
      },
      lazy: async () => {
        let { LocalEventLog } = await import(
          /* webpackChunkName: "mod-sys-eventlog" */ './components/eventlog/LocalEventLog'
        );
        return { Component: LocalEventLog };
      },
    },
    {
      path: 'fgdmanagementlog',
      handle: {
        appUniKey: 'sys_eventlog',
        cli: 'config system locallog setting',
      },
      lazy: async () => {
        let { FgdManagementLog } = await import(
          /* webpackChunkName: "mod-sys-fgdmanagementlog" */ './components/eventlog/fileLog/FgdManagementLog'
        );
        return { Component: FgdManagementLog };
      },
    },
    {
      path: 'fdsupdatelog',
      handle: {
        appUniKey: 'sys_eventlog',
        cli: 'config system locallog setting',
      },
      lazy: async () => {
        let { FdsUpdateLog } = await import(
          /* webpackChunkName: "mod-sys-fdsupdatelog" */ './components/eventlog/fileLog/FdsUpdateLog'
        );
        return { Component: FdsUpdateLog };
      },
    },
    {
      path: 'eventloghist',
      handle: {
        appUniKey: 'sys_eventlog',
        cli: 'config system locallog setting',
      },
      lazy: async () => {
        let { HistLogList } = await import(
          /* webpackChunkName: "mod-sys-eventloghist" */ './components/eventlog/fileLog/HistLogList'
        );
        return { Component: HistLogList };
      },
    },
    {
      path: 'dbeventlog/:devType/:viewName/:_logtype',
      handle: {
        appUniKey: 'sys_dbeventlog',
      },
      lazy: async () => {
        let { LocalEventLog } = await import(
          /* webpackChunkName: "mod-sys-dbeventlog" */ './components/eventlog/LocalEventLog'
        );
        return {
          Component: () => LocalEventLog({ MODULE_PERM_KEY: 'system_setting' }),
        };
      },
    },
    {
      path: 'certificate',
      handle: {
        appUniKey: 'sys_cert',
        cli: 'config system certificate local',
      },
      lazy: async () => {
        let { Certificate } = await import(
          /* webpackChunkName: "mod-sys-certificate" */ './components/certificate/Certificate'
        );
        return { Component: Certificate };
      },
    },
    {
      handle: {
        appUniKey: 'sys_adv',
      },
      element: <ReactContentViewContainer menuId='sys_adv' />,
      children: [
        {
          path: 'workspace',
          handle: {
            appUniKey: 'sys_admin_workspace',
            cli: 'config system global',
          },
          lazy: async () => {
            let { EditWorkspace } = await import(
              /* webpackChunkName: "mod-sys-workspace" */ './components/workspace/EditWorkspace'
            );
            return { Component: EditWorkspace };
          },
        },
        {
          path: 'logforwardlist',
          handle: {
            appUniKey: 'sys_logforwarding',
            cli: 'config system log-forward',
          },
          lazy: async () => {
            let { LogForwardingMain } = await import(
              /* webpackChunkName: "mod-sys-logforwarding" */ './components/logForwarding/LogForwardingMain'
            );
            return { Component: LogForwardingMain };
          },
        },
        {
          path: 'logtopology',
          handle: {
            appUniKey: 'sys_logtopology',
          },
          lazy: async () => {
            let { LoggingTopology } = await import(
              /* webpackChunkName: "mod-sys-logtopology" */ './components/logTopology/LoggingTopology'
            );
            return { Component: LoggingTopology };
          },
        },
        {
          path: 'devicelogsettings',
          handle: {
            appUniKey: 'sys_adv_logsetting',
            cli: 'config system log settings',
          },
          lazy: async () => {
            let { DeviceLogSettings } = await import(
              /* webpackChunkName: "mod-sys-adv-logsetting" */ './components/advanced/deviceLogSettings/DeviceLogSettings'
            );
            return { Component: DeviceLogSettings };
          },
        },
        {
          path: 'advmailserver',
          handle: {
            appUniKey: 'sys_adv_mail',
            cli: 'config system mail',
          },
          lazy: async () => {
            let { MailServer } = await import(
              /* webpackChunkName: "mod-sys-adv-mail" */ './components/advanced/mailServer/MailServer'
            );
            return { Component: MailServer };
          },
        },
        {
          path: 'advsyslogserver',
          handle: {
            appUniKey: 'sys_adv_syslog',
            cli: 'config system syslog',
          },
          lazy: async () => {
            let { SyslogServer } = await import(
              /* webpackChunkName: "mod-sys-adv-syslog" */ './components/advanced/syslogServer/SyslogServer'
            );
            return { Component: SyslogServer };
          },
        },
        {
          path: 'advmetafields',
          handle: {
            appUniKey: 'sys_adv_meta',
            cli: 'config system metadata admins',
          },
          lazy: async () => {
            let { MetaFields } = await import(
              /* webpackChunkName: "mod-sys-adv-meta" */ './components/advanced/metaFields/MetaFields'
            );
            return { Component: MetaFields };
          },
        },
        {
          path: 'logfetch',
          handle: {
            appUniKey: 'sys_logfetch',
            cli: 'config system log-fetch client-profile',
          },
          lazy: async () => {
            let { LogFetcher } = await import(
              /* webpackChunkName: "mod-sys-adv-logfetch" */ './components/logfetcher/LogFetcher'
            );
            return { Component: LogFetcher };
          },
        },
        {
          path: 'advancedsettings',
          handle: {
            appUniKey: 'sys_adv_setting',
            cli: 'config system global',
          },
          lazy: async () => {
            let { AdvancedSettings } = await import(
              /* webpackChunkName: "mod-sys-adv-setting" */ './components/advanced/AdvancedSettings/AdvancedSettings'
            );
            return { Component: AdvancedSettings };
          },
        },
        ...getTaskmonitorRoute(true),
      ],
    },
    {
      path: 'fabric',
      handle: {
        appUniKey: 'sys_supfabric',
      },
      element: <ReactContentViewContainer menuId='sys_supfabric' />,
      children: [
        {
          path: 'settings',
          handle: {
            appUniKey: 'sys_supfabric_settings',
            cli: isFmg() ? 'config system csf' : 'config system soc-fabric',
          },
          lazy: async () => {
            let { FazFabricSettings } = await import(
              /* webpackChunkName: "mod-sys-supfabric-settings" */ './components/fazFabric/fabricSettings/index'
            );
            return { Component: FazFabricSettings };
          },
        },
        {
          path: 'groups',
          handle: {
            appUniKey: 'sys_fazsupfabric_groups',
            cli: isFmg() ? 'config system csf' : 'config system soc-fabric',
          },
          lazy: async () => {
            let { FabricGroup } = await import(
              /* webpackChunkName: "mod-sys-fazsupfabric-groups" */ './components/fazFabric/fabricGroups/FabricGroup'
            );
            return { Component: FabricGroup };
          },
        },
        {
          path: 'connectors',
          handle: {
            appUniKey: 'sys_fazsupfabric_connectors',
          },
          lazy: async () => {
            let { FazFabricConnectors } = await import(
              /* webpackChunkName: "mod-sys-fazsupfabric-connectors" */ 'react_apps/ra_fabric/components/connectors/ConnectorsTileTable'
            );
            return { Component: FazFabricConnectors };
          },
        },
      ],
    },
  ],
};
