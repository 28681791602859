import SocketIntf from './socket_intf';

type MessageData = string | ArrayBufferLike | Blob | ArrayBufferView;

export interface SocketCallbacks {
  onopen: (event: Event) => void;
  onclose: (event: CloseEvent) => void;
  onerror: (event: ErrorEvent) => void;
  onmessage: (event: MessageEvent<MessageData>) => void;
  send: (data: MessageData) => void;
  close: (code?: number, reason?: string) => void;
}

export class SocketProxy {
  private socket: SocketIntf;
  private callbacks: SocketCallbacks;

  constructor(socket: SocketIntf, callbacks: SocketCallbacks) {
    this.socket = socket;
    this.callbacks = callbacks;

    // Proxying 'onopen' event
    this.socket.onopen = (event) => {
      callbacks.onopen(event);

      if (this.onopen) {
        this.onopen(event);
      }
    };

    // Proxying 'onclose' event
    this.socket.onclose = (event) => {
      callbacks.onclose(event);

      if (this.onclose) {
        this.onclose(event);
      }
    };

    // Proxying 'onerror' event
    this.socket.onerror = (event) => {
      callbacks.onerror(event);

      if (this.onerror) {
        this.onerror(event);
      }
    };

    // Proxying 'onmessage' event
    this.socket.onmessage = (event) => {
      callbacks.onmessage(event);

      if (this.onmessage) {
        this.onmessage(event);
      }
    };
  }

  // Proxying 'send' method
  send: SocketIntf['send'] = (data) => {
    this.callbacks.send(data);

    this.socket.send(data);
  };

  // Proxying 'close' method
  close: SocketIntf['close'] = () => {
    this.callbacks.close();

    this.socket.close();
  };

  // Custom event handlers
  onopen: SocketIntf['onopen'] | null = null;
  onclose: SocketIntf['onclose'] | null = null;
  onerror: SocketIntf['onerror'] | null = null;
  onmessage: SocketIntf['onmessage'] | null = null;
}
