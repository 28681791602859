import { omit } from 'lodash';

function actionRecordByPath(action, state, fn) {
  const { path, data } = action.payload;
  if (!path) return state;

  const oldState = state[path];
  if (!oldState || !oldState.loaded) return state;

  const newById = fn(oldState, data);

  return {
    ...state,
    [path]: {
      ...oldState,
      byId: newById,
    },
  };
}

export function changeRecordByPath(action, state) {
  return actionRecordByPath(action, state, (oldState, data) => {
    let state = {
      ...oldState.byId,
    };
    if (data.hasOwnProperty('id')) {
      state[data.id] = data.fields;
    }
    return state;
  });
}
export function removeRecordByPath(action, state) {
  const { path, data } = action.payload;
  if (!path) return state;
  if (!data || !data.hasOwnProperty('id')) {
    return omit(state, path);
  }

  return actionRecordByPath(action, state, (oldState, data) =>
    omit(oldState.byId, data.id)
  );
}
