import { createSelector } from '@reduxjs/toolkit';

export const getPnoState = (state) => state?.adom?.pno;

const _select = (key) => createSelector(getPnoState, (pno) => pno?.[key]);

export const getCurrentPPkg = _select('currentPPkg');
export const getDualPaneMode = _select('dualPaneMode');

export const getPnoDisplayOpts = _select('pnoDisplayOpts');
export const getCurrentPType = _select('currentPType');
export const getNoShowPBlockMsg = _select('noShowPBlockMsg');

export const inPnoDualPane = createSelector(
  getDualPaneMode,
  (mode) => mode === 'dualPaneMode'
);
