import {
  BTN_PRIMARY,
  DEFAULT_ESCAPE_KB_TRAP_SHORTCUT,
  RESET_BUTTON_SELECTOR,
} from './constant';

export { addEscapeKeyboardTrap };

const addEscapeKeyboardTrap = (escapeKeyboardTrapObj, resetFn) => {
  let { shortcut, nextElementSelector } = escapeKeyboardTrapObj;
  shortcut = shortcut || DEFAULT_ESCAPE_KB_TRAP_SHORTCUT;
  nextElementSelector = nextElementSelector || RESET_BUTTON_SELECTOR;

  if (nextElementSelector === RESET_BUTTON_SELECTOR && !resetFn) {
    nextElementSelector = BTN_PRIMARY;
  }

  return {
    [shortcut]: (editor) => {
      editor.display.input.blur();
      try {
        const el = document.querySelector(nextElementSelector);
        if (typeof el.setFocus === 'function') el.setFocus();
        // neowise components
        else el.focus(); // htmlNode
      } catch (e) {
        // handle exception if needed
      }
    },
  };
};
