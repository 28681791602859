import React from 'react';
import {
  NwProHeader,
  NwProBody,
  NwProSection,
  NwProInputRow,
  NwProFooter,
  OkBtn,
  CancelBtn,
} from 'rc_layout';
import { Formik } from 'formik';
import { FmkRadioBtnGroup, FmkSSelect2, FmkErrorSpan } from 'rc_form';
import { NwInput } from '@fafm/neowise-core';

export const MoveEntry = (props) => {
  const { entries, onSubmit, $opener, entryId, identifier, keyLabel } = props;

  //if entryId is first in array, initial target is second, otherwise previous entry
  const initialTarget =
    entries.length === 1
      ? entryId
      : entries.findIndex((e) => entryId === e[identifier]) === 0
      ? entries[1]
      : entries[entries.findIndex((e) => entryId === e[identifier]) - 1];

  const initialValues = {
    toMove: entryId,
    target: initialTarget,
    option: 'before',
  };

  const onCancel = () => {
    $opener.reject();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values.target, values.option);
        $opener.resolve();
      }}
      validate={validate}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <NwProHeader>{gettext('Move Entry')}</NwProHeader>
          <NwProBody>
            <NwProSection>
              <NwProInputRow label={keyLabel}>
                <NwInput value={entryId} disabled />
              </NwProInputRow>
              <NwProInputRow label={gettext('Position')}>
                <FmkRadioBtnGroup
                  name={'option'}
                  choices={[
                    { text: 'Before', id: 'before' },
                    { text: 'After', id: 'after' },
                  ]}
                />
              </NwProInputRow>
              <NwProInputRow label={keyLabel}>
                <FmkSSelect2
                  name={'target'}
                  source={() => {
                    return entries.map((entry) => {
                      return {
                        text: entry[identifier].toString(),
                        id: entry[identifier],
                      };
                    });
                  }}
                />
                <FmkErrorSpan name={'target'} />
              </NwProInputRow>
            </NwProSection>
          </NwProBody>
          <NwProFooter>
            <OkBtn name='ok' onClick={submitForm} loading={isSubmitting}>
              {gettext('OK')}
            </OkBtn>
            <CancelBtn onClick={onCancel}>{gettext('Cancel')}</CancelBtn>
          </NwProFooter>
        </>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (values.toMove === values.target) {
    errors.target = gettext('Please choose a different entry as target');
  }
  return errors;
};
