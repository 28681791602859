import { fiFmgHttp, fiProxyHttp, getResponseData } from 'fi-http';

export function get(view_type, product) {
  return fiProxyHttp.get('/gui/adom/um/firmwareimage', 'get', {
    viewType: view_type,
    viewProduct: product,
  });
}
/**
 * Get all firmware images from fortiguard and locally
 * @param {*} platform string, platform
 * @param {*} product string, product
 */
export function getAllVersionsList(platform, product) {
  return Promise.all([
    getVersionList(platform, product),
    getLocalImagesList(),
  ]).then((resp) => {
    let [versionListResp, localImagesResp] = resp;
    const localImages = getResponseData(localImagesResp)?.image_list || [];
    const versionList = getResponseData(versionListResp)?.version_list || [];

    const cache = {};

    for (const local of localImages) {
      const { platform } = local;
      let versionRef = null;
      if (cache[platform]) versionRef = cache[platform];
      else {
        let ref = versionList.find((ver) => ver.platform === platform);
        versionRef = ref;
        cache[platform] = ref;
      }

      if (versionRef) versionRef?.versions?.push(local);
    }

    return versionList;
  });
}

/**
 * Helper function of getVersionList
 * Used to filter irrelevent platform based on given platforms and then return array of available versions of given platforms
 * Returns array of versions [{type: 'GA/SPECIAL', version: '3.5.0-b0272'}]
 * @param {object} response: server reponse of getVersionList function
 * @param {arrray|string} platforms: a collection of platforms of specific product (FMG, FAP, FSW, FEXT)
 * @return {array} result: array of versions [{type: 'GA/SPECIAL', version: '3.5.0-b0272'}]
 */
export function getVersionList(platform, product) {
  let req = {
    id: 1,
    method: 'exec',
    params: [
      {
        data: {
          platform: platform,
          product: product,
        },
        url: 'um/image/version/list',
      },
    ],
  };
  return fiFmgHttp.query(req);
}

function getLocalImagesList(product) {
  let req = {
    id: 1,
    method: 'exec',
    params: [
      {
        data: {
          product: product || '',
        },
        url: 'um/image/list',
      },
    ],
  };
  return fiFmgHttp.query(req);
}

export function getVersionListByPlatforms(response, platforms) {
  if (!platforms) return [];
  let platforms_set = new Set(
    Array.isArray(platforms) ? platforms : [platforms]
  );
  let platform_versions = response[0].data?.version_list.filter((verObj) =>
    platforms_set.has(verObj.platform)
  );
  if (platforms_set.size == 1) {
    // identical platform
    return platform_versions?.[0]?.versions || [];
  } else {
    // have more than 2 platforms, only return GA builds that available for all the given platforms
    let version_verObj_map = {}; // map : {obj: version_obj, count: number}
    platform_versions.forEach((platVer) => {
      platVer.versions
        .filter((ver) => ver.type === 'GA') // only get GA builds
        .forEach((ver) => {
          if (version_verObj_map.hasOwnProperty(ver.version)) {
            version_verObj_map[ver.version].count++;
          } else {
            version_verObj_map[ver.version] = {
              obj: ver,
              count: 1,
            };
          }
        });
    });
    return Object.values(version_verObj_map)
      .filter((verObj) => verObj.count === platforms_set.size)
      .map((verObj) => verObj.obj);
  }
}

export function getVersionListOld(platform, product) {
  return fiProxyHttp.get('/gui/adom/um/firmwareimage/version', 'get', {
    platform: platform,
    product: product,
  });
}

export function download({ version, platform }) {
  return fiFmgHttp.query({
    id: 1,
    method: 'exec',
    params: [
      {
        data: {
          create_task: 'enable',
          platform,
          version,
        },
        url: 'um/image/download',
      },
    ],
  });
}
export function getImportedImagesList() {
  return fiProxyHttp.get('/gui/adom/um/firmwareimportimage', 'get', {
    sort: 'product',
    dir: 'asc',
    startIndex: 0,
    results: 50000000,
  });
}

export function deleteImportedImages(filenames) {
  return fiProxyHttp.post('/gui/adom/um/firmwareimportimage', 'del', {
    key: filenames.sort().reverse().join('#'),
  });
}

export function getPlatformVersionTextMap() {
  return getVersionList(null, null).then((resp) => {
    let allVersionList = resp[0]?.data?.version_list || [];
    const PRODUCTS = ['FGT', 'FAP', 'FSW', 'FXT'];

    const _append0ToBuildNumberHead = (versionStr) => {
      if (!versionStr) return '';
      const [verNumStr, buildNumStr] = versionStr.split('-');
      let _buildNumStr = '0' + buildNumStr.substr(1);
      return `${verNumStr}-b${_buildNumStr}`;
    };

    let rs = {};
    allVersionList.forEach((listItem) => {
      const { platform, product, versions } = listItem;
      if (PRODUCTS.indexOf(product) === -1) return;
      let keyPrefix = `${platform}`;
      versions.forEach((versionItem) => {
        const { type, version, image_path } = versionItem;
        if (type === 'GA') {
          rs[`${keyPrefix}:${version}`] = version;
        }
        if (image_path) {
          rs[`${keyPrefix}:${_append0ToBuildNumberHead(version)}`] =
            image_path.substr(image_path.lastIndexOf('/') + 1);
        }
      });
    });
    return rs;
  });
}

// constants
export const VIEW_TYPE_MANAGED = 0;
export const VIEW_TYPE_ALL = 1;
export const PRODUCT_FGT = 'FGT';
export const PRODUCT_FAZ = 'FAZ';
export const PRODUCT_FMG = 'FMG';
export const PRODUCT_FAP = 'FAP';
export const PRODUCT_FEXT = 'FXT';
export const PRODUCT_FSW = 'FSW';
export const PRODUCT_FPX = 'FPX';
