import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import './resizer.less';

const defaultConfig = {
  selector: '',
  minWidth: 0,
  iframe: null, // Iframe requires special condition to prevent iframe from eating document events
};

const defaultHConfig = {
  selector: '',
  minHeight: 0,
  iframe: null, // Iframe requires special condition to prevent iframe from eating document events
};

const IFRAME_DIV_COVER_CLASS = 'resizer-iframe-cover';

const DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
};

const DEFAULT_MIN_WIDTH = 0;
const DEFAULT_MIN_HEIGHT = 0;

/**
 * Component used to resize two containers within a parent container. Parent container must have a width defined as
 * resizing is based off % width. See IframeCodemirror.jsx for example.
 * If a one of the two containers contains an iframe, specifiy the selector for the iframe in the config.
 */
export const Resizer = ({
  parentCtn,
  rightConfig = defaultConfig,
  leftConfig = defaultConfig,
  styling,
  topConfig = defaultHConfig,
  bottomConfig = defaultHConfig,
  isHorizontal = false,
}) => {
  const resizerRef = useRef(null);
  const previousResizerX = useRef(null);
  const previousResizerY = useRef(null);

  useEffect(() => {
    if (resizerRef.current) {
      _resetDimesions(isHorizontal);
      resizerRef.current.addEventListener(
        'mousedown',
        resizerOnMouseDownHandler
      );
      // save current as ref.current can become null before returning
      const refCurr = resizerRef.current;
      return () => {
        refCurr.removeEventListener('mousedown', resizerOnMouseDownHandler);
        window.removeEventListener('mouseup', stopResize);
      };
    }
  }, [isHorizontal]);

  /**
   * Covers the iframe with a div so that the iframe does not eat the events from the main frame
   * @param {*} remove bool, whether to create or remove the cover
   */
  const coverIframeWithDiv = (remove = false) => {
    if (remove) {
      for (const node of $(`.${IFRAME_DIV_COVER_CLASS}`)) {
        node.remove();
      }
    } else {
      [rightConfig, leftConfig, topConfig, bottomConfig]
        .filter((cfg) => cfg && cfg.iframe)
        .map((opts) => {
          if (opts.iframe) {
            const cover = `<div class=${IFRAME_DIV_COVER_CLASS} />`;
            $(opts.selector).prepend(cover);
          }
        });
    }
  };

  /**
   * bind events on the containers when resizer element is clicked.
   * @param {*} evt event object
   */
  const resizerOnMouseDownHandler = (evt) => {
    evt.preventDefault();
    $(parentCtn).on('mousemove', resize);
    window.addEventListener('mouseup', stopResize);
    coverIframeWithDiv();
  };

  const _getNodeOpts = () => {
    const rightNodeOpts = {
      node: $(`${rightConfig?.selector}`),
      minWidth: rightConfig?.minWidth || DEFAULT_MIN_WIDTH,
      iframe: rightConfig?.iframe,
    };

    const leftNodeOpts = {
      node: $(`${leftConfig?.selector}`),
      minWidth: leftConfig?.minWidth || DEFAULT_MIN_WIDTH,
      iframe: leftConfig?.iframe,
    };

    const topNodeOpts = {
      node: $(`${topConfig?.selector}`),
      minHeight: topConfig?.minHeight || DEFAULT_MIN_HEIGHT,
      iframe: topConfig?.iframe,
    };

    const bottomNodeOpts = {
      node: $(`${bottomConfig?.selector}`),
      minHeight: bottomConfig?.minHeight || DEFAULT_MIN_HEIGHT,
      iframe: bottomConfig?.iframe,
    };

    return {
      rightNodeOpts,
      leftNodeOpts,
      topNodeOpts,
      bottomNodeOpts,
    };
  };

  const resize = (evt) => {
    evt.preventDefault();
    const { rightNodeOpts, leftNodeOpts, topNodeOpts, bottomNodeOpts } =
      _getNodeOpts();
    // Only resize if nodes are found
    if (rightNodeOpts.node && leftNodeOpts.node) {
      const mouseX = evt?.pageX; // Location of the mouse on the X plane
      let direction =
        mouseX < previousResizerX.current ? DIRECTION.LEFT : DIRECTION.RIGHT;

      modifyDimensions(direction, mouseX, rightNodeOpts, leftNodeOpts);
      previousResizerX.current = $(
        resizerRef.current
      )[0].getBoundingClientRect().x;
    }
    if (topNodeOpts.node && bottomNodeOpts.node) {
      const mouseY = evt?.pageY; // Location of the mouse on the X plane
      let direction =
        mouseY < previousResizerY.current ? DIRECTION.TOP : DIRECTION.BOTTOM;

      modifyHDimensions(direction, mouseY, topNodeOpts, bottomNodeOpts);
      previousResizerY.current = $(
        resizerRef.current
      )[0].getBoundingClientRect().y;
    }
  };

  const _resetDimesions = (isHorizontal) => {
    const { rightNodeOpts, leftNodeOpts, topNodeOpts, bottomNodeOpts } =
      _getNodeOpts();
    if (isHorizontal) {
      topNodeOpts?.node?.css('width', '');
      bottomNodeOpts?.node?.css('width', '');
    } else {
      rightNodeOpts?.node?.css('height', '');
      leftNodeOpts?.node?.css('height', '');
    }
  };

  /**
   * Main logic to calculate the resizing on mousemove. Will resize the containers based off percent width.
   * If minWidth is set in the configurations, it will not resize smaller than the minimum width.
   * @param {*} direction DIRECTION ENUM
   * @param {*} mouseX int
   * @param {*} rightNodeOpts { node: JQuery Node, minWidth: int }
   * @param {*} leftNodeOpts { node: JQuery Node, minWidth: int }
   */
  const modifyDimensions = (direction, mouseX, rightNodeOpts, leftNodeOpts) => {
    const [growingNodeOpts, shrinkingNodeOpts] =
      direction === DIRECTION.LEFT
        ? [rightNodeOpts, leftNodeOpts]
        : [leftNodeOpts, rightNodeOpts];
    const diff = Math.abs(mouseX - previousResizerX.current);
    const growingNodeWidth = growingNodeOpts?.node.width();
    const shrinkingNodeWidth = shrinkingNodeOpts?.node.width();

    const applyChanges = shrinkingNodeWidth - diff > shrinkingNodeOpts.minWidth;

    if (applyChanges) {
      // Apply width changes
      let parentCtnWidth = $(parentCtn).width() - $(resizerRef.current).width();
      let newGrowingNodeWidth =
        ((growingNodeWidth + diff) / parentCtnWidth) * 100;
      let newShrinkingNodeWidth =
        ((shrinkingNodeWidth - diff) / parentCtnWidth) * 100;

      growingNodeOpts?.node?.css('width', `${newGrowingNodeWidth}%`);
      shrinkingNodeOpts?.node?.css('width', `${newShrinkingNodeWidth}%`);
    }
  };

  /**
   * Main logic to calculate the resizing on mousemove. Will resize the containers based off percent height.
   * If minHeight is set in the configurations, it will not resize smaller than the minimum height.
   * @param {*} direction DIRECTION ENUM
   * @param {*} mouseY int
   * @param {*} bottomNodeOpts { node: JQuery Node, minHeight: int }
   * @param {*} topNodeOpts { node: JQuery Node, minHeight: int }
   */
  const modifyHDimensions = (
    direction,
    mouseY,
    topNodeOpts,
    bottomNodeOpts
  ) => {
    const [growingNodeOpts, shrinkingNodeOpts] =
      direction === DIRECTION.TOP
        ? [bottomNodeOpts, topNodeOpts]
        : [topNodeOpts, bottomNodeOpts];
    const diff = Math.abs(mouseY - previousResizerY.current);
    const growingNodeHeight = growingNodeOpts?.node.height();
    const shrinkingNodeHeight = shrinkingNodeOpts?.node.height();

    const applyChanges =
      shrinkingNodeHeight - diff > shrinkingNodeOpts.minHeight;

    if (applyChanges) {
      // Apply width changes
      let parentCtnHeight =
        $(parentCtn).height() - $(resizerRef.current).height();
      let newGrowingNodeHeight =
        ((growingNodeHeight + diff) / parentCtnHeight) * 100;
      let newShrinkingNodeHeight =
        ((shrinkingNodeHeight - diff) / parentCtnHeight) * 100;

      growingNodeOpts?.node?.css('height', `${newGrowingNodeHeight}%`);
      shrinkingNodeOpts?.node?.css('height', `${newShrinkingNodeHeight}%`);
    }
  };

  /**
   * On mouseup, remove all event handling set by mousedown
   */
  const stopResize = () => {
    $(parentCtn).off('mousemove', resize);
    coverIframeWithDiv(true);
  };

  return (
    <div
      ref={resizerRef}
      style={styling}
      className={`resizer-container ${
        isHorizontal ? 'horizontal' : 'vertical'
      }`}
    />
  );
};

Resizer.propTypes = {
  parentCtn: PropTypes.string.isRequired,
  rightConfig: PropTypes.shape({
    selector: PropTypes.string.isRequired,
    iframe: PropTypes.string,
  }),
  leftConfig: PropTypes.shape({
    selector: PropTypes.string.isRequired,
    iframe: PropTypes.string,
  }),
  topConfig: PropTypes.shape({
    selector: PropTypes.string.isRequired,
    iframe: PropTypes.string,
  }),
  bottomConfig: PropTypes.shape({
    selector: PropTypes.string.isRequired,
    iframe: PropTypes.string,
  }),
  styling: PropTypes.shape({}),
  isHorizontal: PropTypes.bool,
};
