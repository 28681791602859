import React, { useEffect, useState } from 'react';
import { fiStore, fiStoreFabric } from 'fistore';
import { fiHttpGet, fiHttpPost } from 'fi-http';
import { ProForm } from '@fafm/neowise-pro';
import { NwButton, NwSwitch } from '@fafm/neowise-core';
import { useNavigate } from 'react-router-dom';
import { fiSysConfig } from 'fi-session';
const { Header, Body, Footer, Section, Row, Column, Line } = ProForm;

const getAutoId = (name) => `custom-view-share-modal:${name.toLowerCase()}`;

export const Share = ({ $opener, menuItem }) => {
  const uuid = menuItem?.stateParams?.customViewId;
  const adminName = fiSysConfig.current().admin_user_name || '';
  const checkRegexEnd = new RegExp('\\|public$');
  const [params, setParams] = useState(null);
  const [owner, setOwner] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fiHttpGet(`/p/fabric/customview/load/${uuid}/`, {
      params: { admin: adminName },
    }).then((customView) => {
      const res = JSON.parse(customView.content);
      setParams(res);
      setOwner(customView.owner);
      setIsPublic(checkRegexEnd.test(customView.owner) ? true : false);
    });
  }, []);

  const shareCustom = () => {
    const viewUsage = menuItem?.stateParams?.viewUsage;
    const newOwner = isPublic ? `${adminName}|public` : `${adminName}|private`;
    fiHttpPost('/p/fabric/customview/save/', {
      name: menuItem?.stateParams?.customViewName,
      uuid: uuid,
      'view-usage': viewUsage,
      content: JSON.stringify(params),
      owner: owner.includes(adminName) || owner === '' ? newOwner : owner,
    }).then(
      (data) => {
        $opener.resolve(menuItem);
        fiStore.dispatch(fiStoreFabric.fetchFabricCustomMenu());
        const pathItems = menuItem.path.split('/');
        const prePath = pathItems.slice(0, pathItems.length - 2).join('/');
        navigate(`${prePath}/fabric_customview_${data.id}/${data.id}`, {
          customViewName: data.name,
        });
      },
      (err) => {
        if (err && err.data) {
          let msg;
          if (err.data.msg) {
            msg = err.data.msg;
          } else if (err.data.data) {
            msg = err.data.data.msg;
          }
          if (msg) {
            console.error(msg);
          }
        }
      }
    );
  };

  const cancel = () => {
    $opener.reject();
  };

  const togglePrivacy = () => {
    setIsPublic((prevPrivacy) => !prevPrivacy);
  };

  return (
    <>
      <Header>{gettext('Share this Custom View')}</Header>
      <Body>
        <Section>
          <Row label={gettext('Privacy')}>
            <Column>
              <Line>
                <NwSwitch
                  automation-id={getAutoId('privacy')}
                  checked={isPublic}
                  onChange={togglePrivacy}
                />
                <div>
                  <strong>
                    {isPublic ? gettext('Public') : gettext('Private')}
                  </strong>
                  <div>
                    {isPublic
                      ? gettext('Others can see this Custom View')
                      : gettext('Only you can see this Custom View')}
                  </div>
                </div>
              </Line>
            </Column>
          </Row>
        </Section>
      </Body>
      <Footer>
        <NwButton
          type='primary'
          onClick={shareCustom}
          automation-id={getAutoId('ok-btn')}
        >
          {gettext('OK')}
        </NwButton>
        <NwButton onClick={cancel} automation-id={getAutoId('cancel-btn')}>
          {gettext('Cancel')}
        </NwButton>
      </Footer>
    </>
  );
};
