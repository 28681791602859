import { fiAdom } from 'fi-session';
import { roundToPlaces } from 'kit-number';

/***
 * For custom formatting of donut chart tooltip
 *
 * optional config:
 * nameLabel: label for category name, default is "Value"
 * countLabel: label for count, default is "Count"
 *
 * nameLabelFormatter(): if need to customize name label
 * countLabelFormatter(): if need to customize count label
 *
 * nameFormatter(): optional format function for category name
 * countFormatter(): optional format function for count
 *
 * all fns take arg data: {datum: {count, key, color}, totalCount}
 */
export const makeDonutChartTooltip = (config) => {
  const {
    nameLabel = gettext('Value'),
    nameFormatter = defaultNameFormatter,
    countLabel = gettext('Count'),
    countFormatter = defaultCountFormatter,
  } = config;

  const {
    nameLabelFormatter = () => nameLabel,
    countLabelFormatter = () => countLabel,
  } = config;

  return ({ data }) => {
    const name = nameFormatter(data);
    const count = countFormatter(data);

    const nameLabelDisplay = nameLabelFormatter(data);
    const countLabelDisplay = countLabelFormatter(data);
    return (
      <table>
        <tbody>
          {[
            [nameLabelDisplay, name],
            [countLabelDisplay, count],
          ].map(([label, value]) => (
            <tr key={label}>
              <td className='tw-pr-2 tw-text-left tw-text-neutral-500'>
                {label}
              </td>
              <td className='tw-text-left'>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
};

const defaultCountFormatter = (data) => {
  const count = data?.datum?.count;
  const totalCount = data.totalCount;
  const percent = Math.round((count * 100) / totalCount);
  return `${count} (${percent}%)`;
};

const defaultNameFormatter = (data) => {
  const name = data?.datum?.key;
  return name || '';
};

export function getDiskQuotaUnit(numberInMB = fiAdom.current().quota) {
  let chartLabel = 'MB';
  if (numberInMB >= MACROS.USER.SYS.TB_VS_MB_NUM) {
    chartLabel = 'TB';
  } else if (numberInMB >= MACROS.USER.SYS.GB_VS_MB_NUM) {
    chartLabel = 'GB';
  }
  return chartLabel;
}

export function getDiskQuotaNumber(numberInMB = fiAdom.current().quota || 0) {
  let result = numberInMB;
  if (numberInMB >= MACROS.USER.SYS.TB_VS_MB_NUM) {
    result = result / MACROS.USER.SYS.TB_VS_MB_NUM;
  } else if (numberInMB >= MACROS.USER.SYS.GB_VS_MB_NUM) {
    result = result / MACROS.USER.SYS.GB_VS_MB_NUM;
  }
  return roundToPlaces(result, 1);
}
