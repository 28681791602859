import {
  FafmType,
  FafmMessage,
  FafmResponseExtensionID,
  CaptureStatus,
  Severity,
  FafmInitCapture,
} from './types';
import {
  checkAck,
  windowPostMessage,
  onWindowPostMessage,
  defer,
  timeoutResponse,
} from './utils';
import {
  _internal,
  handleDidStartResult,
  log,
  sendExtensionMessage,
  getPlatformInfo,
} from './debugger_utils';
import { isFunction, isString } from 'lodash';
import { register as registerHttpCapture } from './captures/http_log';
import { register as registerJsCapture } from './captures/js_log';
import { register as registerResourceCapture } from './captures/resource_log';
import { register as registerWsRequestCapture } from './captures/ws_request_log';
import { register as registerSocketEventCapture } from './captures/socket_log';

type ReloadCaptureOptions = { fileName: string };
const RELOAD_AND_CAPTURE_KEY = '__fafm_debugger_reload_capture_options__';

const sendContentScriptMessage = windowPostMessage<FafmMessage>;
const onContentScriptMessage = onWindowPostMessage<FafmMessage>;
const MAX_RECORD_SEC = 10 * 60;

export async function registerDebuggerExtension() {
  if (_internal.captureStatus !== undefined) return;
  _internal.captureStatus = CaptureStatus.NotRegistered;

  // register all captures
  const registerDeferred = defer();
  registerHttpCapture();
  registerJsCapture();
  registerResourceCapture();
  registerWsRequestCapture();
  registerSocketEventCapture();

  onContentScriptMessage()
    .addCase(FafmType.ResponseExtensionID, async (message) => {
      if (_internal.captureStatus !== CaptureStatus.NotRegistered) return;

      _internal.extensionID = (
        message as FafmResponseExtensionID
      ).messageContent.id;

      const response = sendExtensionMessage({
        messageID: FafmType.Register,
        messageContent: {
          platform: getPlatformInfo(),
          debugSupportedDaemons: [],
          supportPageReload: true,
        },
      });

      try {
        await checkAck(response);
        _internal.captureStatus = CaptureStatus.Registered;
        registerDeferred.resolve();
      } catch (ex) {
        _internal.extensionID = '';
        registerDeferred.reject();
      }
    })
    .addCase(FafmType.InitCapture, async (message) => {
      if (_internal.captureStatus !== CaptureStatus.Registered) return;

      const {
        messageContent: { fileName, pageReload },
      } = message as FafmInitCapture;

      try {
        const handles = await _internal.shouldStart?.({ pageReload });

        void (isFunction(handles?.captureAfter)
          ? captureAfterReload(handles.captureAfter)
          : startDebugger(fileName));
      } catch (ex) {
        // reject capture
        void sendExtensionMessage({
          messageID: FafmType.StartCapture,
          messageContent: {
            ack: false,
          },
        });
      }

      function captureAfterReload(reload: VoidFunction) {
        const options: ReloadCaptureOptions = { fileName };
        localStorage.setItem(RELOAD_AND_CAPTURE_KEY, JSON.stringify(options));
        reload();
      }
    })
    .addCase(FafmType.StopCapture, stopDebugger);

  sendContentScriptMessage({
    messageID: FafmType.RequestExtensionID,
    messageContent: {},
  });

  return safelyCheckAutoStartCapture(registerDeferred.promise);
}

async function safelyCheckAutoStartCapture(registerPromise: Promise<unknown>) {
  const unsafeString = localStorage.getItem(RELOAD_AND_CAPTURE_KEY);
  if (!unsafeString) return;
  localStorage.removeItem(RELOAD_AND_CAPTURE_KEY);

  try {
    const unsafeOptions: ReloadCaptureOptions = JSON.parse(unsafeString);
    if (!isString(unsafeOptions.fileName)) {
      return;
    }
    const { fileName } = unsafeOptions;

    const startCapture = registerPromise.then(() => startDebugger(fileName));
    await timeoutResponse(startCapture, 5000);
  } catch (ex) {
    // ignore wrong reload capture options
  }
}

async function startDebugger(fileName: string) {
  if (_internal.captureStatus !== CaptureStatus.Registered) return;

  try {
    const response = sendExtensionMessage({
      messageID: FafmType.StartCapture,
      messageContent: {
        ack: true,
        fileName,
      },
    });
    await checkAck(response);
    await startFafmCaptures();
  } catch (ex) {
    // force ex to string, also works for Error.
    log('Failed to start debugger: ' + (ex as string), Severity.Error);
  }
}

let timerInterval: undefined | number;

async function startFafmCaptures() {
  if (_internal.captureStatus !== CaptureStatus.Registered) return;
  _internal.captureStatus = CaptureStatus.Capturing;

  // time the capture duration, call stop on max time
  timerInterval = window.setInterval(() => {
    if (++_internal.startedSeconds > MAX_RECORD_SEC) {
      stopDebugger();
    }
  }, 1000);

  await Promise.allSettled(
    Array.from(_internal.didStart).map((f) =>
      handleDidStartResult(f(_internal.startedSeconds))
    )
  );
}

export async function stopDebugger() {
  if (_internal.captureStatus !== CaptureStatus.Capturing) return;
  _internal.captureStatus = CaptureStatus.Registered;

  log('Stopping capture');
  clearInterval(timerInterval);
  timerInterval = undefined;
  _internal.startedSeconds = 0;

  await Promise.allSettled(Array.from(_internal.willStop).map((f) => f()));

  sendExtensionMessage({
    messageID: FafmType.StopCapture,
    messageContent: {},
  });
}
