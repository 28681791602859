import { omit } from 'lodash';

import { devicesAction, vdomsAction } from './actions';
import { createReducer, pathReducer } from '../../utils/reducer_util';

/*
{
    devices:{
        '3':{
            byId:{
                '101':{

                },
                '102 :{

                }
            },
            allIds:['101','102']
        }
    },
    vdoms:{
        '3-101':{
            byId:{
              '3':{
                  'name':'vdom1'
              },
              '103':{
                  'name':'vdom2'
              }
            },
            allIds:['3','103']
        },
        '3-102':{
            byId:{
              '3':{
                  'name':'vdom3'
              },
              '112':{
                  'name':'vdom112'
              }
            },
            allIds:['3','112']
        }
    }
}
*/

const deviceInitialState = {
  loaded: false,
  loading: false,
  estimateTotal: 0,
  byId: {},
  allIds: [],
};

const deleteRecord = (state, action) => {
  try {
    if (typeof action.payload.id === 'undefined') return null;

    const { id } = action.payload;

    const oldById = state.byId || {};
    const newById = omit(oldById, id);

    const newAllIds = state.allIds.filter(
      (oid) => oid.toString() !== id.toString()
    );

    return {
      ...state,
      byId: newById,
      allIds: newAllIds,
    };
  } catch (e) {
    return state;
  }
};

const findInsertPos = (compare) => (low, high) => {
  const binary = (low, high) => {
    if (low <= high) {
      if (compare(high) == 1) return high + 1; //{"type":"after","pos":high+1}
      if (compare(low) == -1) return low; //{"type":"before","pos":low-}

      var mid = Math.ceil((high + low) / 2);
      if (compare(mid) == -1) {
        return binary(low + 1, mid - 1);
      } else {
        return binary(mid + 1, high - 1);
      }
    } else {
      return low;
    }
  };

  return binary(low, high);
};
const devicesEntityReducer = createReducer({
  [devicesAction.fetch.START]: () => {
    return {
      ...deviceInitialState,
      loaded: false,
      loading: true,
    };
  },
  [devicesAction.fetch.SUCCESS]: (state) => {
    return {
      ...state,
      loaded: true,
      loading: false,
    };
  },
  [devicesAction.record.SET]: (state, action) => {
    if (!state.loading && !state.loaded) return null;

    const { total } = action.payload;

    return {
      ...state,
      estimateTotal: total,
    };
  },
  [devicesAction.record.APPEND]: (state, action) => {
    if (!state.loading && !state.loaded) return null;

    const { byId, allIds } = action.payload;

    const newState = {
      ...state,
      byId: {
        ...state.byId,
        ...byId,
      },
      allIds: [...state.allIds, ...allIds],
    };

    return newState;
  },
  [devicesAction.record.ADD]: (state, action) => {
    if (!state.loading && !state.loaded) return null;

    // eslint-disable-next-line
    const [data, ...rest] = action.payload;

    const name = data.name;
    const id = data.oid;

    const { byId, allIds } = state;

    const pos = findInsertPos((x) => (name > byId[allIds[x]].name ? 1 : -1))(
      0,
      allIds.length - 1
    );

    return {
      ...state,
      byId: {
        ...byId,
        [id]: data,
      },
      allIds: [...allIds.slice(0, pos), id.toString(), ...allIds.slice(pos)],
    };
  },
  [devicesAction.record.CHANGE]: (state, action) => {
    if (!state.loading && !state.loaded) return null;

    //console.log(action)
    // eslint-disable-next-line
    //const { id, data } = action.payload

    const build = (dev) => {
      if (dev.data && dev.id) {
        return dev;
      }
      return {
        id: dev.oid.toString(),
        data: dev,
      };
    };

    const obj = [action.payload]
      .flat()
      .map(build)
      .filter(({ id }) => (state.byId[id] ? true : false))
      .reduce((prev, cur) => {
        prev[cur.id] = cur.data;
        return prev;
      }, {});

    if (Object.keys(obj).length == 0) return state;

    const newState = {
      ...state,
      byId: {
        ...state.byId,
        ...obj,
      },
    };
    //newState.byId[action.payload.id] = action.payload.data

    return newState;
  },
  [devicesAction.record.DELETE]: (state, action) => {
    if (!state || !state.loaded) return state;
    return deleteRecord(state, action);

    //return removeRecordByPath(action,state)
  },
});

export const devicesReducer = pathReducer(devicesEntityReducer);

const vdomsEntityReducer = createReducer({
  [devicesAction.fetch.START]: () => {
    return {};
  },
  [vdomsAction.record.APPEND]: (state, action) => {
    const newState = {
      ...state,
      ...action.payload,
    };
    return newState;
  },
  [vdomsAction.record.CHANGE]: (state, action) => {
    if (!action.payload) return state;

    const newState = action.payload.reduce(
      (vdomsState, currVdom) => {
        vdomsState.byId[currVdom.oid] = currVdom;
        vdomsState.allIds.push(currVdom.oid);
        return vdomsState;
      },
      {
        byId: {},
        allIds: [],
      }
    );

    return newState;
  },
  [vdomsAction.record.DELETE]: (state, action) => {
    return deleteRecord(state, action);
  },
});

export const vdomsReducer = pathReducer(vdomsEntityReducer);
