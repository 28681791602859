import cn from 'classnames';
import { LineTable } from './TaskLineTable';
import { LastLineDetail } from './TaskLastLineDetail';
import { TaskProgressBar } from './TaskProgressBar';
import { LineStatesCounter } from './LineStatesCounter';
import { useEffect, useState } from 'react';
import { getCurrentAdomName, autoId, useUIshowHide } from './util';
import { TaskStateLink } from './TaskStateLink';
import { NwSwitch } from '@fafm/neowise-core';

export function TaskDetails({
  taskData,
  // unix number, use this to calculate task 'time used' if task is not done
  serverTime,
  // usually we see task of the current adom.
  getAdomNameById = getCurrentAdomName,

  // display control
  title,
  // ['bar', 'text','lines', 'all']
  // line table is only being toggled hidden initially
  hide = [],
  hideWhenSuccess = [],
  //will be hide when task is done
  // ['lastline'],
  show = [],
  className,
  inlineBar = false,
  showErrorAlert = true,

  onToggleLineTable,
  // number in px, use container height if not set
  lineTableProps,
}) {
  const isUIvisible = useUIshowHide({ hide, hideWhenSuccess, show, taskData });

  const [lineFilterFn, setLineFilterFn] = useState();

  const isLineTableVisibleBaseOnProps = isUIvisible('lines');

  const [isLineTableVisible, setLineTableVisiblity] = useState(
    isLineTableVisibleBaseOnProps
  );
  // maybe line table is hideWhenSuccess
  useEffect(() => {
    setLineTableVisiblity(isLineTableVisibleBaseOnProps);
  }, [isLineTableVisibleBaseOnProps]);
  // schedule line table show hide callback
  useEffect(() => {
    onToggleLineTable?.({ collapsed: !isLineTableVisible });
  }, [isLineTableVisible]);

  const toggleLineTable = () => {
    setLineTableVisiblity((prev) => !prev);
  };

  const hasLines = Array.isArray(taskData?.line) && !!taskData.line.length;

  return (
    isUIvisible('all') && (
      <div
        className={cn(
          className,
          'tw-flex tw-flex-col tw-gap-1 fi-task-details',
          {
            'tw-h-full': !lineTableProps?.maxHeight,
          }
        )}
      >
        {isUIvisible('lastline') && (
          <LastLineDetail
            taskData={taskData}
            automation-id={autoId('last-line')}
          />
        )}
        {inlineBar ? <TaskStateLink taskData={taskData} /> : null}

        <div
          className={cn(
            'tw-flex tw-gap-1',
            !inlineBar ? 'tw-flex-col' : 'tw-items-center'
          )}
        >
          {isUIvisible('bar') && (
            <TaskProgressBar
              taskData={taskData}
              className={cn('tw-grow', inlineBar ? 'tw-order-4' : 'tw-order-1')}
              automation-id={autoId('current-progress')}
            />
          )}

          {!inlineBar && showErrorAlert ? (
            <TaskStateLink
              taskData={taskData}
              className='tw-order-2 tw-block'
            />
          ) : null}

          {isUIvisible('text') && (
            <div
              className='tw-flex tw-items-center tw-order-3 tw-gap-1'
              style={{
                // #1076217
                padding: '0.5rem 1rem 0.25rem',
              }}
            >
              {!!title && (
                <span className='tw-whitespace-nowrap'>
                  <strong automation-id={autoId('title')}>{title}</strong>
                </span>
              )}

              <LineStatesCounter
                taskData={taskData}
                onSelectFilter={(fn) => setLineFilterFn(() => fn)}
                getAutoId={autoId}
              />

              {hasLines && (
                <>
                  {/* push toggle button to the right most */}
                  <div className='tw-grow'></div>
                  <NwSwitch
                    checked={isLineTableVisible}
                    onChange={toggleLineTable}
                    automation-id={autoId('collapsed-button')}
                  >
                    {gettext('Show Details')}
                  </NwSwitch>
                </>
              )}
            </div>
          )}
        </div>

        {hasLines && isLineTableVisible && (
          <LineTable
            taskData={taskData}
            serverTime={serverTime}
            getAdomNameById={getAdomNameById}
            filterFn={lineFilterFn}
            {...lineTableProps}
          />
        )}
      </div>
    )
  );
}
