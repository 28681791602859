import { fiHttpGet } from 'fi-http';
import { escapeHtml } from 'kit-escape';
import CoreViewDataSrv from './fiCoreViewDataSrv';

// const BASE_URL = '/p/app/#!/adom/default/fabric/asset-index/view';
const BASE_URL = '/fabric/asset-index/view';

function fetchData(userCfg) {
  let params = { ...userCfg };
  const fiCoreViewDataSrv = new CoreViewDataSrv();
  return fiCoreViewDataSrv
    .getServerTimeByTimePeriod(userCfg.timePeriod)
    .then((res) => {
      params.serverTime = _formatServerTime(res.data);
      return fiCoreViewDataSrv.getDataByParams(params);
    });
}

function getIdentityPath(euid, proxiedServer, proxiedAdom) {
  let ret = `${BASE_URL}/identity/${euid}?multiUeba=true`;
  if (proxiedServer) {
    ret += '&proxiedServer=' + proxiedServer;
  }
  if (proxiedAdom) {
    ret += '&proxiedAdom=' + proxiedAdom;
  }
  return ret;
}

function getAssetPath(epid, proxiedServer, proxiedAdom) {
  let ret = `${BASE_URL}/asset/${epid}?multiUeba=true`;
  if (proxiedServer) {
    ret += '&proxiedServer=' + proxiedServer;
  }
  if (proxiedAdom) {
    ret += '&proxiedAdom=' + proxiedAdom;
  }
  return ret;
}
function _formatServerTime(serverTime) {
  return {
    startTime: serverTime.start_time.slice(0, 6).join(','),
    endTime: serverTime.end_time.slice(0, 6).join(','),
  };
}

function getIncidentEndpoint(epid, epText, proxiedServer, proxiedAdom) {
  const params = { epids: `[${epid}]`, proxiedServer, proxiedAdom };
  return fiHttpGet('/p/util/endpoints/', { params: params }).then(function (
    resp
  ) {
    let epData = {};
    if (resp.data && resp.data.length) {
      epData = resp.data[0];
    } else if (epText && typeof epText === 'string') {
      // No EP data in resp, use epText to create epData.
      const ipMatch = /^\s*([^ (]*)/.exec(epText);
      if (ipMatch) {
        epData.epip = ipMatch[1];
      }
      const epMatch = /\(([^)]*)\)\s*$/.exec(epText);
      if (epMatch) {
        epData.epname = epMatch[1];
      }
    }
    return epData;
  });
}
function isEuidValid(euid) {
  return euid > MACROS.FAZEPEU.EPEU_ID_RESERVED_MAX;
}
function isEpidValid(epid) {
  return epid > MACROS.FAZEPEU.EPEU_ID_RESERVED_MAX;
}
function isVpnEp(epid) {
  let vpnEpid = 104;
  if (typeof MACROS.FAZEPEU.EPEU_ID_UNTRACK_VPN_IP !== 'undefined') {
    vpnEpid = MACROS.FAZEPEU.EPEU_ID_UNTRACK_VPN_IP;
  }
  return epid == vpnEpid; // intentionally using "==" for possible string vs number.
}
// function getEmsConnectors() {
//   return new Source(
//     fiHttpRespData(fiHttp.get('/p/util/connectors/ems/')).then((resp) => {
//       return (
//         (resp.data &&
//           resp.data.reduce((accu, curr) => {
//             accu.push({
//               ...curr,
//               id: curr.uuid,
//               text: curr.name,
//             });
//             return accu;
//           }, [])) ||
//         []
//       );
//     }),
//     { textKey: 'id' }
//   );
// }
function emsConnectorFormatter(row) {
  return `<span class='name'>${escapeHtml(row.name)}</span>`;
}
export default {
  fetchData,
  getIdentityPath,
  getAssetPath,
  getIncidentEndpoint,
  isEuidValid,
  isEpidValid,
  isVpnEp,
  // getEmsConnectors,
  emsConnectorFormatter,
};
