export interface RequestFormat {
  id: string;
  msg: string;
  method: string;
  params: any;
}

let _request_seq = 10;

function generateRequestId() {
  return 'mtd-' + _request_seq++;
}
export function createDispatchRequest(params: object): RequestFormat {
  return {
    id: generateRequestId(),
    msg: 'method',
    method: 'dispatch',
    params,
  };
}

export function appendIdAndMsg(request: RequestFormat): RequestFormat {
  if (request.id && request.msg) return request;
  const newReq = {
    ...request,
  };
  if (!newReq.id) newReq.id = generateRequestId();
  if (!newReq.msg) newReq.msg = 'method';

  return newReq;
}

/*
{
    "msg": "method",
    "id": "mtd-10",
    "method": "dispatch",
    "params": {
        "url": "/cgi-bin/module/flatui_proxy",
        "method": "get",
        "params": {
            "url": "/gui/adoms/3/devices/list",
            "method": "get",
            "params": {
                "fields": ["all", {
                    "vdoms": ["all"]
                }]
            },
            "dataChunked": {}
        }
    }
}
*/
