export default class ReConnect {
  reconnectTimer: any;
  reconnectMilliSeconds: number;
  reconnectCount: number;
  constructor(
    private connect: () => void,
    private publish: (msg: string) => void
  ) {
    this.reconnectTimer = null;
    this.reconnectMilliSeconds = 1000;
    this.reconnectCount = 0;
  }
  retry() {
    if (this.reconnectTimer) return;

    this.reconnectTimer = setTimeout(() => {
      //this.socket.open();
      this.connect();

      this.reconnectTimer = null;
      this.reconnectCount++;
      if (this.reconnectCount > 3) {
        this.reconnectCount = 0;
        //clear
        this.publish('connect:error');
      }
      if (this.reconnectMilliSeconds > 1000 * 60) return;
      this.reconnectMilliSeconds += 1000 * 2;
    }, this.reconnectMilliSeconds);
  }
  clear() {
    if (this.reconnectTimer) clearTimeout(this.reconnectTimer);

    this.reconnectTimer = null;
    this.reconnectMilliSeconds = 1000;
    this.reconnectCount = 0;
  }
}
