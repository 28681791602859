import { ProToolkit } from '@fafm/neowise-pro';
import { ContentView } from 'rc_content_view';
import React from 'react';
import { openConfirmModal } from 'rc_layout';
import { escapeHtml } from 'kit-escape';
import $ from 'jquery';

import { fiWorkspace } from 'fi-workspace';
import { fiAdom } from 'fi-session';
import {
  fiDeviceRedux,
  fiDeviceDataLoader,
  fiDeviceDataFetcher,
  fiDeployUtil,
} from 'ra_device_util';
import { deviceStatus, fiDvmFilterRules } from 'fi-dvm';
import { forEach, isUndefined } from 'lodash';
import {
  InstallFlag,
  secCtrlReInstallPkg,
} from 'react_apps/ra_device_util/security_console_install_utils';

/**
 *  Re-install packages...
 */

let devIdObjs, insync_msg, allinsync, someinsync, insync_promise;
/*
     Input device list and return device names that locked by other users
     only in case of workspace mode and adom not locked.
     devOids only contains device oid (no vdom oid)
  */
function findDevLockedByOthers(devOids) {
  // check devices lock under workspace mode
  let devs = [];
  let adomLock = fiWorkspace.adomInfo();
  let devLock = fiWorkspace.allDevInfo();
  if (fiWorkspace.isWorkspaceEnabled() && adomLock.isUnlock()) {
    forEach(devOids, function (oid) {
      let _dev = fiDeviceDataLoader.getDevice(parseInt(oid));
      // vdom device can not be locked
      if (!_dev || deviceStatus.isVdom(_dev.rtype)) {
        return;
      }
      if (devLock.isLockedByOther(parseInt(oid))) {
        devs.push(_dev.name);
      }
    });
  }
  return devs;
}

function devLockedByOtherErrorMsg(devLockedByOthers) {
  let str =
    gettext('Devices below are currently locked by other administrators.') +
    '</br>';
  forEach(devLockedByOthers, function (devName) {
    str += "<li> '" + escapeHtml(devName) + "'</li>";
  });
  return str;
}

function CheckPolicyPackage(selectedDevRows, selectedPkgId) {
  // need reset pkg_promise for get pkg scope every time
  let pkg_promise = $.Deferred();
  // reinstall by select policy package
  if (!selectedDevRows && selectedPkgId) {
    fiDeviceDataFetcher.checkPkgQinstall(selectedPkgId).then(
      function (true_or_false) {
        if (true_or_false) {
          fiDeviceDataFetcher.checkPKGSync(selectedPkgId).then(
            function (resp_ckpkgsync) {
              //{ "status": "ok", "sync": 1 }
              if (resp_ckpkgsync && resp_ckpkgsync.status === 'ok') {
                allinsync =
                  resp_ckpkgsync.sync === 1 || resp_ckpkgsync.sync === '1';
                someinsync =
                  resp_ckpkgsync.sync === 2 || resp_ckpkgsync.sync === '2';
                //get Dev ID Objs ANYWAY
                fiDeviceDataFetcher.getPkgScope(selectedPkgId).then(
                  function (resp_getscope) {
                    //{ "status": "ok", "data": [ { "devOid": 194, "vdomOid": 3 } ] }
                    if (resp_getscope && resp_getscope.status === 'ok') {
                      if (Array.isArray(resp_getscope.data)) {
                        devIdObjs = resp_getscope.data.map(
                          ({ devOid, vdomOid }) => ({
                            did: devOid,
                            vid: vdomOid,
                            pkgOid: selectedPkgId,
                          })
                        );
                        let devLockedByOthers = findDevLockedByOthers(
                          devIdObjs.map(function (a) {
                            return a.did;
                          })
                        );
                        if (devLockedByOthers.length > 0) {
                          pkg_promise.reject(
                            devLockedByOtherErrorMsg(devLockedByOthers)
                          );
                        }
                        pkg_promise.resolve();
                      } else {
                        devIdObjs = [];
                        pkg_promise.reject();
                      }
                    } else {
                      //invalid resp_getscope...
                      pkg_promise.reject();
                    }
                  },
                  function (errmsg) {
                    openConfirmModal({
                      content: errmsg
                        ? errmsg
                        : gettext('Failed to get package scope.'),
                      title: gettext('Re-install Package Failed'),
                      buttons: ['ok'],
                    }).then(function () {
                      pkg_promise.reject();
                    });
                  }
                );
              }
            },
            function () {
              openConfirmModal({
                content: gettext('Failed to check package.'),
                title: gettext('Re-install Package Failed'),
                buttons: ['ok'],
              }).then(function () {
                pkg_promise.reject();
              });
            }
          );
        } else {
          openConfirmModal({
            content: gettext(
              'The selected package has never been installed to device.'
            ),
            title: gettext('Re-install Package Failed'),
            buttons: ['ok'],
          }).then(function () {
            pkg_promise.reject();
          });
        }
      },
      function (err_q_install) {
        openConfirmModal({
          content: err_q_install.msg,
          title: gettext('Re-install Package Failed'),
          buttons: ['ok'],
        }).then(function () {
          pkg_promise.reject();
        });
      }
    );
  }
  // reinstall policy package by select devices
  else {
    allinsync = true;
    someinsync = false;
    ////////////// Get [ {did: 1, vid: 2}, {did: 3, vid: 4} ] /////////
    devIdObjs = [];
    let fltrules = fiDvmFilterRules.get();
    forEach(selectedDevRows, function (dev) {
      let oData = dev._oData;
      const ppSync = oData.pp_sync;
      if (!ppSync) return;

      if (!isUndefined(ppSync.status)) {
        allinsync = allinsync && fltrules.pkgStatus.installed(oData);
        someinsync = someinsync || fltrules.pkgStatus.installed(oData);
      }
      let push_data = {
        did: oData.did,
        pkgOid: ppSync.oid,
      };
      if (!isUndefined(oData.vid)) {
        push_data.vid = oData.vid;
      } else {
        push_data.vid = MACROS.DVM.CDB_DEFAULT_ROOT_OID;
      }
      devIdObjs.push(push_data);
    });
    pkg_promise.resolve('check_sync');
  }
  return pkg_promise.promise();
}

function doReinstallPol(selectedDevRows, selectedPkgId, selectedPkgName, adom) {
  let _title = selectedPkgName
    ? gettext('Re-install Policy Package') + ' - ' + selectedPkgName
    : gettext('Re-install Policy Package');

  return new Promise((resolve, reject) => {
    CheckPolicyPackage(selectedDevRows, selectedPkgId, selectedPkgName).then(
      function () {
        if (devIdObjs.length === 0) {
          openConfirmModal({
            title: gettext('Re-install Package Failed'),
            content: gettext('Nothing to re-install.'),
            buttons: ['ok'],
          }).then(
            () => {
              reject();
            },
            () => {
              reject();
            }
          );
          return;
        }

        insync_promise = $.Deferred();
        let msg = '';
        if (allinsync || someinsync) {
          if (!insync_msg) {
            insync_msg = allinsync
              ? gettext(
                  'Selected devices/VDOMs are in synchronized status. Would you like to re-install them?'
                )
              : gettext(
                  'Some devices/VDOMs are in synchronized status. Would you like to re-install them?'
                );
          }

          msg = insync_msg;
        } else {
          msg = gettext('Are you sure you want to re-install the policy?');
        }

        openConfirmModal({
          title: _title,
          content: msg,
        }).then(
          () => {
            insync_promise.resolve();
          },
          () => {
            insync_promise.reject();
            reject();
          }
        );

        insync_promise.promise().then(function () {
          const pkgOidScopesMap = devIdObjs.reduce(
            (result, { pkgOid, did, vid }) => {
              const oid = parseInt(pkgOid);
              if (!oid) return result;
              let scopes = result.get(oid);
              if (!scopes) {
                scopes = [];
                result.set(oid, scopes);
              }
              scopes.push({ did, vid });
              return result;
            },
            new Map()
          );

          secCtrlReInstallPkg({
            adom: adom.oid,
            target: Array.from(pkgOidScopesMap.entries()).map(
              ([oid, scope]) => ({
                pkg: oid,
                scope,
              })
            ),
            flags: [InstallFlag.Preview],
          }).then(
            function ({ task }) {
              resolve({
                tid: task,
                devIdObjs: devIdObjs,
                selectedPkgName: selectedPkgName,
              });
            },
            function (resp) {
              openConfirmModal({
                content: resp?.message
                  ? resp.message
                  : gettext('Failed to create a task.'),
                title: gettext('Re-install Package Failed'),
                buttons: ['ok'],
              }).then(function () {
                reject();
              });
            }
          );
        });
      },
      function (errMsg) {
        //show error.
        openConfirmModal({
          content: errMsg ? errMsg : gettext('Failed to check package.'),
          title: gettext('Re-install Package Failed'),
          buttons: ['ok'],
        }).then(function () {
          reject();
        });
      }
    );
  });
}

function openDevPreviewDialog(
  devId,
  devName,
  openFn = ProToolkit.openModal,
  vdomOids = null
) {
  const props = {
    rDS: (() => {
      // func: get device change preview only or get pkg&device change preview.
      // parameter of function [adom.oid, devs];
      // content view support multiple devices preview, device previews are split into pages.
      // currentPageDevices: devices in current page. if null or undefined, means only one device.
      // restDevs: if null or undefined, means only one device. devices not in current page used for paging calculation.
      const device = fiDeviceRedux.getDevice(
        fiAdom.current().oid,
        parseInt(devId)
      );
      let _vdoms = device?.vdoms || [];
      const allVdoms = vdomOids || [
        MACROS.DVM.CDB_DEFAULT_GLOBAL_OID,
        MACROS.DVM.CDB_DEFAULT_ROOT_OID,
        ..._vdoms.map((vd) => parseInt(vd.oid)),
      ];
      let _dev = {
        oid: parseInt(devId),
        name: devName,
        glb: true,
        vdomOids: allVdoms,
      };
      let _parm = [fiAdom.current().oid, [_dev]];

      return {
        func: fiDeployUtil.getDevSettingsPreview,
        parameter: _parm,
        allDevices: [
          {
            // Only show root vdom
            name: devName,
            oid: parseInt(devId),
            vdomName: 'root',
            vdomOid: 3,
          },
        ],
      };
    })(),
    rMessage: {
      title: gettext('Install Preview of %s').printf([devName]),
      filename: `${devName}-preview.txt`,
    },
    rDownload: true,
    isDiffView: false,
  };
  return openFn(<ContentView {...props} />, { width: '60vw' });
}

export const fiDvmInstallService = {
  doReinstallPol: doReinstallPol,
  openDevPreviewDialog: openDevPreviewDialog,
  findDevLockedByOthers: findDevLockedByOthers,
};
