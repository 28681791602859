import { printfd } from 'kit/kit-string';

export const DEFAULT_VALIDATION_SHORTCUTS = [
  {
    keys: '?',
    description: gettext('Show the autocomplete options panel.'),
  },
  {
    keys: printfd(
      gettext('%(keyboardKey)s (While autocomplete panel is open)'),
      { keyboardKey: 'Tab' }
    ),
    description: gettext(
      'Scroll through autocomplete options while the panel is open.'
    ),
  },
  {
    keys: printfd(
      gettext('%(keyboardKey)s (While autocomplete panel is open)'),
      { keyboardKey: 'Enter' }
    ),
    description: gettext('Use the selected autocomplete option.'),
  },
];
