import { fiFmgHttp, getResponseData } from 'fi-http';
import { fiAdom } from 'fi-session';
import { castArray, forEach } from 'lodash';
import { fiMessageBox } from 'fi-messagebox';
import { isArray, isObject } from 'fiutil';
import { openConfirmModal } from 'rc_layout';
import { fiDvmScriptsType } from '../constants';
import { fiWebSocket } from 'fi-websocket';
import { escapeSlash, escapeSlashFull } from 'kit/kit-regexp';
import $ from 'jquery';

function sendReqToWebsocket(wsReq, notifyError = false) {
  const defer = $.Deferred();
  const _fdl_status = {};

  fiWebSocket.send(fiWebSocket.genWSRequest(wsReq)).then(
    function () {
      defer.resolve();
    },
    function (err) {
      defer.reject(err);
    },
    function ({ chunk }) {
      if (chunk) {
        if (chunk.header) {
          // update predicate
          _fdl_status.total = chunk.header.totalRecords || 0;
        } else if (chunk.tail) {
          // update real total
          _fdl_status.total = chunk.tail.totalRecords;
          defer.notify(chunk);
        } else {
          // From Jin Fang: some models can have data in `chunk` instead of `chunk.data`
          const chunkData = chunk.data || chunk || [];
          if (chunkData?.error) {
            if (notifyError) {
              defer.notify(chunkData);
            } else {
              fiMessageBox.show(chunkData.error, 'danger');
              defer.reject(chunkData?.error);
              return defer.promise();
            }
            return;
          }
          // notify chunk
          defer.notify(chunkData);
        }
      }
    }
  );
  return defer.promise();
}

/**
 * Get script running on policy package, global config
 * @param
 * @return {Promise}
 */
const getScriptGlobal = function () {
  const reqObj = {
    method: 'get',
    params: [
      {
        url: '/dvmdb/global/script',
        fields: [
          'name',
          'type',
          'target',
          'desc',
          'modification_time',
          'schedule',
        ],
      },
    ],
  };

  return fiFmgHttp.forward(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};

const getScriptGrpObj = function (adom, name) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const reqObj = {
    method: 'get',
    params: [
      {
        url: '/dvmdb/adom/' + adom_name + '/script/' + escapeSlash(name),
        option: 'object member',
      },
    ],
  };

  return fiFmgHttp.forward(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};

const getScriptObj = function (adom, name) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const url = adom.globaldb
    ? '/dvmdb/global/script/' + escapeSlash(name)
    : '/dvmdb/adom/' + adom_name + '/script/' + escapeSlash(name);
  const reqObj = {
    method: 'get',
    params: [
      {
        url: url,
      },
    ],
  };

  return fiFmgHttp.forward(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};
/**
 * Get script related configrations
 * @param
 * @return {Promise}
 */
const getScriptConfig = function () {
  const reqObj = {
    method: 'get',
    params: [
      {
        url: '/cli/global/system/admin/setting',
      },
    ],
  };

  return fiFmgHttp.forward(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};
/**
 * This API is used for DVM CLI Template feature
 * @param {Object} adom the adom object
 * @param {string} http_verb the http method
 * @param {Object} data data that will be posted to the backend
 */
const cliTemplateApi = function (adom, http_verb, data, abortCtrl) {
  let queryFn = fiFmgHttp.query;
  let queryParams = [];

  let reqObj = {
    method: http_verb,
    params: [
      {
        url: `/pm/config/adom/${adom}/obj/cli/template`,
      },
    ],
  };

  if (http_verb === 'get') {
    queryFn = fiFmgHttp.forward;
    reqObj.params[0]['option'] = ['scope member', 'extra info'];
    if (data?.name) {
      reqObj.params[0].url += `/${escapeSlashFull(data.name)}`;
    }
    queryParams = [reqObj, false, abortCtrl];
  } else if (http_verb === 'add' || http_verb === 'update') {
    reqObj.params[0].data = data;
    queryParams = [reqObj, abortCtrl];
  } else if (http_verb === 'delete') {
    let __filter = ['name', 'in'];
    forEach(data, function (template_name) {
      __filter.push(template_name);
    });
    reqObj.params[0].confirm = 1;
    reqObj.params[0].filter = __filter;
    queryParams = [reqObj, abortCtrl];
  }

  return queryFn(...queryParams).then((resp) => {
    return getResponseData(resp);
  });
};

function _cliTemplateAssign({
  adomName,
  httpVerb = 'add',
  tmplName,
  scopeMembers = [],
  isGroup = false,
}) {
  if (!adomName || !tmplName) {
    return;
  }

  const tmplPath = isGroup ? 'template-group' : 'template';
  const reqObj = {
    method: httpVerb,
    params: [
      {
        url: `/pm/config/adom/${adomName}/obj/cli/${tmplPath}/${escapeSlashFull(
          tmplName
        )}/scope member`,
        data: scopeMembers || [],
      },
    ],
  };
  let queryFn = httpVerb !== 'get' ? fiFmgHttp.query : fiFmgHttp.forward;
  return queryFn(reqObj).then((resp) => {
    return getResponseData(resp);
  });
}

function cliTemplateApiAssign({ adomName, httpVerb, tmplName, scopeMembers }) {
  return _cliTemplateAssign({
    adomName,
    httpVerb,
    tmplName,
    scopeMembers,
    isGroup: false,
  });
}

function cliTemplateGrpApiAssign({
  adomName,
  httpVerb,
  tmplName,
  scopeMembers,
}) {
  return _cliTemplateAssign({
    adomName,
    httpVerb,
    tmplName,
    scopeMembers,
    isGroup: true,
  });
}

const cliTemplateGrpApi = function (adom, http_verb, data, abortCtrl) {
  let queryFn = fiFmgHttp.query;
  let queryParams = [];
  let reqObj = {
    method: http_verb,
    params: [
      {
        url: `/pm/config/adom/${adom}/obj/cli/template-group`,
      },
    ],
  };

  if (http_verb === 'get') {
    queryFn = fiFmgHttp.forward;
    reqObj.params[0]['option'] = ['scope member', 'extra info'];
    if (data?.name) {
      reqObj.params[0].url += `/${escapeSlashFull(data.name)}`;
    }
    queryParams = [reqObj, false, abortCtrl];
  }

  if (http_verb === 'add' || http_verb === 'update') {
    reqObj.params[0].data = data;
    queryParams = [reqObj, abortCtrl];
  }

  if (http_verb === 'delete') {
    let __filter = ['name', 'in'];
    forEach(data, function (template_name) {
      __filter.push(template_name);
    });
    reqObj.params[0].confirm = 1;
    reqObj.params[0].filter = __filter;
    queryParams = [reqObj, abortCtrl];
  }

  return queryFn(...queryParams).then((resp) => {
    return getResponseData(resp);
  });
};

const processCliTemplateQuery = (http_verb, data) => {
  let reqObj = {
    method: http_verb,
    params: [{}],
  };

  if (http_verb === 'get') {
    reqObj.params[0]['option'] = ['scope member'];
  }

  if (http_verb === 'add' || http_verb === 'update') {
    reqObj.params[0].data = data;
  }

  if (http_verb === 'delete') {
    let __filter = ['name', 'in'];
    forEach(data, function ({ name }) {
      __filter.push(name);
    });
    reqObj.params[0].confirm = 1;
    reqObj.params[0].filter = __filter;
  }

  return reqObj;
};

/**
 * Handles batch queries from both cli templates and cli template groups
 * @param {*} adom
 * @param {*} http_verb
 * @param {*} data
 */
const batchCliTemplateApi = async function (adom, http_verb, data) {
  const templateGroupEndpoint = 'template-group';
  const templateEndpoint = 'template';
  const generateUrl = (endpoint) =>
    `/pm/config/adom/${adom}/obj/cli/${endpoint}`;
  const cliTemplateScripts = data.filter(({ isTemplateGrp }) => !isTemplateGrp);
  const cliTemplateScriptGroups = data.filter(
    ({ isTemplateGrp }) => isTemplateGrp
  );

  try {
    let result = [];

    let queryFn = http_verb != 'get' ? fiFmgHttp.query : fiFmgHttp.forward;
    // process group first, so for delete action, will get more chance to success
    if (cliTemplateScriptGroups.length > 0) {
      const groupQuery = processCliTemplateQuery(
        http_verb,
        cliTemplateScriptGroups
      );
      groupQuery.params[0].url = generateUrl(templateGroupEndpoint);
      result.push(await queryFn(groupQuery));
    }

    if (cliTemplateScripts.length > 0) {
      const scriptQuery = processCliTemplateQuery(
        http_verb,
        cliTemplateScripts
      );
      scriptQuery.params[0].url = generateUrl(templateEndpoint);
      result.push(await queryFn(scriptQuery));
    }

    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

// public
/**
 * Get script list all items with type CLI(1)
 * @param {adom} adom of the script list
 * @return {Promise}
 */
const getScriptList = function (adom, abortCtrl) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const url = adom.globaldb
    ? '/dvmdb/global/script/'
    : '/dvmdb/adom/' + adom_name + '/script/';
  const filter = [
    'type',
    'in',
    fiDvmScriptsType.ScriptType.CLI,
    fiDvmScriptsType.ScriptType.TCL,
  ];
  const reqObj = {
    method: 'get',
    params: [
      {
        url: url,
        filter: filter,
        fields: [
          'name',
          'type',
          'target',
          'desc',
          'modification_time',
          'schedule',
        ],
      },
    ],
  };

  return fiFmgHttp.forward(reqObj, false, abortCtrl).then(function (resp) {
    return getResponseData(resp);
  });
};

/**
 * Get script object
 * @param {adom, script_name} return selected script object.
 * @return {Promise}
 */
const getCLIScriptList = function (adom, abortCtrl) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const url = adom.globaldb
    ? '/dvmdb/global/script/'
    : '/dvmdb/adom/' + adom_name + '/script/';
  const filter = ['type', 'in', fiDvmScriptsType.ScriptType.CLI];
  const reqObj = {
    method: 'get',
    params: [
      {
        url: url,
        filter: filter,
        fields: [
          'name',
          'type',
          'target',
          'desc',
          'modification_time',
          'schedule',
        ],
      },
    ],
  };

  return fiFmgHttp.forward(reqObj, false, abortCtrl).then(function (resp) {
    return getResponseData(resp);
  });
};

/**
 * Add a new script object
 * @param {adom, script_object} return selected script object.
 * @return {Promise}
 */
const addNewScriptObject = function (adom, script_object) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const url = adom.globaldb
    ? '/dvmdb/global/script/'
    : '/dvmdb/adom/' + adom_name + '/script/';
  const reqObj = {
    method: 'add',
    params: [
      {
        url: url,
        data: script_object,
      },
    ],
  };

  return fiFmgHttp.query(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};

/**
 * Add a new script schedule
 * @param {adom, script object}.
 * @return {Promise}
 */
const setScriptSchedule = function (adom, script_object) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const url = adom.globaldb
    ? '/dvmdb/global/script/' +
      escapeSlash(script_object.name) +
      '/script_schedule'
    : '/dvmdb/adom/' +
      adom_name +
      '/script/' +
      escapeSlash(script_object.name) +
      '/script_schedule';
  const reqObj = {
    method: 'set',
    params: [
      {
        url: url,
        data: script_object.script_schedule,
      },
    ],
  };
  return fiFmgHttp.query(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};

/**
 * Update a script object
 * @param {adom, script_object} return selected script object.
 * @return {Promise}
 */
const updateScriptObject = function (adom, script_object) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const url = adom.globaldb
    ? '/dvmdb/global/script/'
    : '/dvmdb/adom/' + adom_name + '/script/';
  const reqObj = {
    method: 'update',
    params: [
      {
        url: url,
        data: script_object,
      },
    ],
  };

  return fiFmgHttp.query(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};
/**
 * delete script objcts
 * @param {adom, script} return
 * @return {Promise}
 */
const deleteScriptObjs = function (adom, script_objects) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const filter = ['name', 'in'];
  forEach(script_objects, function (script) {
    filter.push(script);
  });
  const url = adom.globaldb
    ? '/dvmdb/global/script/'
    : '/dvmdb/adom/' + adom_name + '/script/';
  const reqObj = {
    method: 'delete',
    params: [
      {
        url: url,
        confirm: 1,
        filter: filter,
      },
    ],
  };

  return fiFmgHttp.query(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};

/**
 * delete script schedule
 * @param {adom, script object}.
 * @return {Promise}
 */
const deleteScriptSchedule = function (adom, script_name, schedule_name) {
  const getReqUrl = (sch) => {
    const adom_name = isObject(adom) ? adom.name : adom;
    return adom.globaldb
      ? '/dvmdb/global/script/' +
          escapeSlash(script_name) +
          '/script_schedule/' +
          escapeSlash(sch)
      : '/dvmdb/adom/' +
          adom_name +
          '/script/' +
          escapeSlash(script_name) +
          '/script_schedule/' +
          escapeSlash(sch);
  };

  const getReqObj = () => {
    schedule_name = Array.isArray(schedule_name)
      ? schedule_name
      : [schedule_name];
    const params = schedule_name.map((sch) => ({
      url: getReqUrl(sch),
      confirm: 1,
    }));
    return {
      method: 'delete',
      params: params,
    };
  };

  const req = getReqObj();
  return fiFmgHttp.query(req).then(function (resp) {
    return getResponseData(resp);
  });
};

/**
 * Get script group list
 * @param {adom, groupName} return script group list.
 * @return {Promise}
 */
const getScriptGroupList = function (adom, abortCtrl) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const filter = ['type', '==', fiDvmScriptsType.ScriptType.GROUP];
  const reqObj = {
    method: 'get',
    params: [
      {
        url: '/dvmdb/adom/' + adom_name + '/script',
        filter: filter,
        option: 'object member',
      },
    ],
  };

  return fiFmgHttp.forward(reqObj, false, abortCtrl).then(function (resp) {
    return getResponseData(resp);
  });
};

/**
 * Run script list
 * @param {adom, parameter_object} return script list.
 * @return {Promise}
 */
const executeScript = function (object) {
  const reqObj = {
    method: 'exec',
    params: [
      {
        url: 'deployment/install/script',
        data: object,
      },
    ],
  };

  return fiFmgHttp.query(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};

const getDeviceGroupList = function (adom) {
  // In this case, we filter out default group
  const adom_name = isObject(adom) ? adom.name : adom;
  const filter = ['type', '==', 0];
  const reqObj = {
    method: 'get',
    params: [
      {
        url: 'dvmdb/adom/' + adom_name + '/group',
        filter: filter,
        option: 'object member',
      },
    ],
  };
  return fiFmgHttp.forward(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};
/**
 * Get device list by script device filter
 * @param {adom, parameter_object} return script list.
 * @return {Promise}
 */
const getDeviceList = function (filter_obj, adom) {
  // filter out unregister device and logging only devices
  const adom_name = isObject(adom) ? adom.name : adom;
  let filter = [];
  if (filter_obj) {
    filter = filter.concat(filter_obj);
  }

  const reqObj = {
    method: 'get',
    params: [
      {
        url: 'dvmdb/adom/' + adom_name + '/device',
        fields: ['name', 'ip', 'platform_str', 'conn_status', 'mgmt_mode'],
        filter: filter,
      },
    ],
  };

  return fiFmgHttp.forward(reqObj).then(function (resp) {
    // only type of managed FGT can be operated by script
    const devs = [];
    forEach(getResponseData(resp), function (dev) {
      if (
        dev['mgmt_mode'] === MACROS.DVM.DVM_MGMT_MODE_FMG_FAZ ||
        dev['mgmt_mode'] === MACROS.DVM.DVM_MGMT_MODE_FMG
      ) {
        devs.push(dev);
      }
    });
    return devs;
  });
};

const getTaskLine = function (taskid) {
  const reqObj = {
    method: 'get',
    params: [
      {
        url: 'task/task/' + taskid + '/line',
      },
    ],
  };

  return fiFmgHttp.forward(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
};

const taskLogFlatproxyApi = function (devicename, adom, abortCtrl) {
  const adom_name = isObject(adom) ? adom.name : adom;
  const reqObj = {
    url: '/gui/adom/dvm/task',
    method: 'get',
    params: {
      deviceName: devicename,
      adomName: adom_name,
    },
  };
  return fiFmgHttp.post(reqObj, abortCtrl).then(function (resp) {
    return getResponseData(resp);
  });
};

const getScriptRunningHistory = function (devicename, adom, abortCtrl) {
  return taskLogFlatproxyApi(devicename, adom, abortCtrl);
};

const findCLITemplateAssignedToDev = function (member_arr, adom, abortCtrl) {
  let _associated = {};
  let _templateIndex = -1;

  // get CLI templates and CLI templates group
  return Promise.all([
    cliTemplateApi(adom, 'get', null, abortCtrl),
    cliTemplateGrpApi(adom, 'get', null, abortCtrl),
  ]).then((data) => {
    if (Array.isArray(data[0]) && Array.isArray(data[1])) {
      let tmp_tmpgrp_combined = data[0].concat(data[1]);
      for (let m = 0; m < tmp_tmpgrp_combined.length; m++) {
        if (Array.isArray(tmp_tmpgrp_combined[m]['scope member'])) {
          for (
            let n = 0;
            n < tmp_tmpgrp_combined[m]['scope member'].length;
            n++
          ) {
            for (let k = 0; k < member_arr.length; k++) {
              if (
                tmp_tmpgrp_combined[m]['scope member'][n].name ===
                  member_arr[k].name &&
                tmp_tmpgrp_combined[m]['scope member'][n].vdom ===
                  member_arr[k].vdom
              ) {
                _templateIndex = m;
                if (!_associated[tmp_tmpgrp_combined[m].name]) {
                  _associated[tmp_tmpgrp_combined[m].name] = Object.assign(
                    {},
                    tmp_tmpgrp_combined[m],
                    {
                      toDel: [],
                    }
                  );
                }
                _associated[tmp_tmpgrp_combined[m].name].toDel.push(
                  member_arr[k]
                );
              }
            }
          }
        }
      }
      return {
        tmplList: data[0],
        tmpGrpList: data[1],
        tmpl: tmp_tmpgrp_combined[_templateIndex] || null,
        associated: _associated,
      };
    }
  });
};

async function cancelSchedule(sname, schedule, callback) {
  try {
    await openConfirmModal(
      {
        title: gettext('Delete Script Schedule'),
        content: gettext(
          'The schedule will be deleted. Are you sure you to want to continue?'
        ),
        buttons: ['ok', 'cancel'],
      },
      { size: 'md', height: '30vh' }
    );
    try {
      await doCancel(sname, schedule, callback);
    } catch (error) {
      const errMsg = error?.data?.result?.[0]?.status?.message;
      fiMessageBox.show(
        errMsg || gettext('Failed to cancel schedule'),
        'danger'
      );
    }
  } catch (error) {
    // close confirm modal
  }
}

async function doCancel(sname, schedule, callback) {
  const schedule_names = schedule.map((sch) => sch.name);
  await deleteScriptSchedule(fiAdom.current().name, sname, schedule_names);
  callback();
}

async function validateCliTemplate(adom, templateName, scope) {
  const adomOid = isObject(adom) ? adom?.oid : adom;
  const param = {
    url: '/securityconsole/cliprof/check',
    data: {
      adom: adomOid,
      cliprof: templateName,
    },
  };
  if (scope) {
    if (!isArray(scope)) scope = [scope];
    param.data.scope = scope;
  }
  const req = {
    id: 1,
    method: 'exec',
    params: [param],
  };
  const resp = await fiFmgHttp.query(req);
  return resp?.[0]?.data;
}

async function getCliTemplateValidationResult(adom, templateName) {
  const adomOid = isObject(adom) ? adom?.oid : adom;
  const req = {
    url: '/gui/cliprof/validation',
    method: 'validate',
    params: {
      adomOid: parseInt(adomOid, 10),
      templateName,
    },
  };
  const resp = await fiFmgHttp.post(req);
  return resp?.[0]?.data;
}

async function cleanupCliTemplateValidationResult(adomOid, templateName) {
  const req = {
    url: '/gui/cliprof/validation',
    method: 'del',
    params: {
      adomOid: parseInt(adomOid, 10),
      templateName,
    },
  };
  const resp = await fiFmgHttp.post(req);
  return resp?.[0]?.data;
}

function previewValidationCliTemplate(adomOid, templateName, devices) {
  const allDevs = castArray(devices)
    .filter(Boolean)
    .map((dev) => {
      return {
        deviceOid: dev.deviceOid || dev.oid,
        vdomOid: dev.vdomOid || dev.vdom_oid,
      };
    });
  if (devices.length === 0) return;
  const req = {
    url: '/gui/cliprof/validation',
    method: 'getValidationPreview',
    params: {
      adomOid: parseInt(adomOid, 10),
      templateName,
      devices: allDevs,
    },
  };
  return sendReqToWebsocket(req, true);
}

/**
 * Get used or missing fmg variables in scripts (CLI or Jinja)
 * @param {object[]} scripts array of script obj of structure [{ type: number (0 or 1), script: string }]
 * @returns array of fmg variables used (or missing) in each script
 */
async function getScriptsFmgVariables(scripts) {
  const params = isArray(scripts) ? scripts : [scripts];
  const req = {
    url: '/gui/cliprof/validation',
    method: 'saveValidate',
    params,
  };
  const resp = await fiFmgHttp.post(req);
  return resp?.[0]?.data;
}

async function runScriptOnDevice(scriptName, adomOid, deviceOid, vdomOid) {
  const reqObj = {
    url: '/gui/cliprof/execute',
    method: 'runScript',
    params: {
      templateName: scriptName,
      deviceOid: deviceOid,
      vdomOid: vdomOid,
      adomOid: adomOid,
    },
  };

  return fiFmgHttp.post(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
}

async function batchRunScriptOnDevice(data) {
  // Data should contain templateName, deviceOid, vdomOid, adomOid
  const reqObj = {
    url: '/gui/cliprof/execute',
    method: 'runScriptBatch',
    params: {
      data: data,
    },
  };

  return fiFmgHttp.post(reqObj).then(function (resp) {
    return getResponseData(resp);
  });
}

// TODO: when convert this service to react,
// please refactor the functions in this service,
// currently implementation, it contains many duplicated code.
export const fiDvmScriptRequests = {
  cancelSchedule,
  getScriptGlobal,
  getScriptObj,
  getScriptConfig,
  getScriptList,
  getCLIScriptList,
  addNewScriptObject,
  setScriptSchedule,
  updateScriptObject,
  deleteScriptObjs,
  deleteScriptSchedule,
  getScriptGroupList,
  executeScript,
  getDeviceList,
  getTaskLine,
  getScriptGrpObj,
  getDeviceGroupList,
  getScriptRunningHistory,
  cliTemplateApi,
  cliTemplateGrpApi,
  batchCliTemplateApi,
  findCLITemplateAssignedToDev,
  validateCliTemplate,
  getCliTemplateValidationResult,
  cleanupCliTemplateValidationResult,
  previewValidationCliTemplate,
  getScriptsFmgVariables,
  runScriptOnDevice,
  batchRunScriptOnDevice,
  cliTemplateApiAssign,
  cliTemplateGrpApiAssign,
};
