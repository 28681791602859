import { isNil, noop } from 'lodash';
import { ProForm } from '@fafm/neowise-pro';
import { NwIconButton } from '@fafm/neowise-core';
import './FileLister.less';

const { Row, Column } = ProForm;

const defaultAutoIdGetter = (...suffix) => 'file_lister:' + suffix.join('-');

export const FileLister = ({
  file = null,
  clear = noop,
  getAutoId = defaultAutoIdGetter,
  columnProps = {},
}) => {
  if (isNil(file)) return null;

  return (
    <Row>
      <Column {...columnProps}>
        <table className='fileTable'>
          <thead>
            <tr>
              <th>{gettext('File')}</th>
              <th style={{ width: '30px' }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{file?.name}</td>
              <td>
                <NwIconButton
                  automation-id={getAutoId('remove')}
                  name='delete'
                  label={gettext('Remove')}
                  onClick={() => {
                    clear();
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Column>
    </Row>
  );
};
