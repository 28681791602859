import { UrlService } from 'fi-url';
import {
  openUpdateLicenseModal,
  loadLicense,
  isLicenseValid,
} from '../../modal/UpdateLicense';

export default {
  [MACROS.NOTICE.NOTICE_TYPE_VM_LICENSE]: {
    message: ({ params: { days, hours, duplicate_license } }) => {
      return duplicate_license
        ? gettext(
            'Duplicate License has been found! Your VM license will expire in %s hours. (Grace Time: 7 days)'
          ).printf([hours])
        : gettext('Your VM license will expire in %s days.').printf([days]);
    },
    exec: async () => {
      const license = await loadLicense();
      const isValid = isLicenseValid(license);
      openUpdateLicenseModal(license, isValid);
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_ADOM_LICENSE]: {
    message: ({ params: { days, expiringDate } }) => {
      return days > 0
        ? gettext('ADOM license will expire in %s days.').printf([days])
        : gettext('ADOM license expired on %s.').printf([expiringDate]);
    },
    exec: () => {
      UrlService.openFortinetSupport();
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_VM_METER_BALANCE]: {
    message: ({ params: { balance } }) => {
      return balance <= 0
        ? gettext(
            'Your VM Meter Balance is running low. (Current Balance: %s)'
          ).printf([balance])
        : gettext(
            'Your VM Meter Balance is used up. (Current Balance: %s)'
          ).printf([balance]);
    },
    exec: () => {
      window.open(UrlService.HELP.SUPPORT);
    },
  },

  [MACROS.NOTICE.NOTICE_TYPE_VM_METER_LICENSE]: {
    message: ({ params: { days } }) => {
      return days === -1
        ? gettext(
            'Your VM Meter Service has been frozen because license expires.'
          )
        : gettext('Your VM Meter license will expire in %s days.').printf([
            days,
          ]);
    },
    exec: () => {
      window.open(UrlService.HELP.SUPPORT);
    },
  },
};
