import { OSTYPES, getAssetGroups, getDvmGroups } from '../constants';
import { AssetDeviceParser } from './parseAssetDev';
import { DvmDeviceParser } from './parseDvmDev';
import { fiSysConfig } from 'fi-session';
import { getUpgradeToString } from './parse_util';

export class PsirtDeviceParser {
  constructor({ device = {}, osType, allPsirtData }) {
    this.device = device;
    this.osType = osType;
    this.allPsirtData = allPsirtData;
  }

  _preParse() {
    this.device._gui_os_type = this.osType;
  }

  parse() {
    this._preParse();
    let parser;
    switch (this.osType) {
      case OSTYPES.FGT:
        parser = new DvmDeviceParser({
          device: this.device,
          allPsirtData: this.allPsirtData,
        });
        break;
      default:
        parser = new AssetDeviceParser({
          device: this.device,
          allPsirtData: this.allPsirtData,
        });
    }

    return parser.parse();
  }
}

export class PsirtDeviceGrouper {
  constructor({
    allPsirtData,
    dvmDevices = [],
    fap = [],
    fsw = [],
    fext = [],
  }) {
    this.allPsirtData = allPsirtData;
    this.dvmDevices = dvmDevices;
    this.fap = fap;
    this.fsw = fsw;
    this.fext = fext;
    this.parsedDvmDevices = [];
    this.parsedFap = [];
    this.parsedFsw = [];
    this.parsedFext = [];
  }

  _postParseGroups(groupsObj) {
    return Object.entries(groupsObj)
      .map(([, value]) => {
        value.key = value.platformName;
        value.isGroup = true;
        return value;
      })
      .filter((group) => group.children.length > 0);
  }

  _shouldSkipDvmDevice(device) {
    const mgmtMode = device['manage_mode'];
    return mgmtMode === MACROS.DVM.DVM_MGMT_MODE_FAZ;
  }

  _getParsedDvmDeviceGroups() {
    const dvmGroups = getDvmGroups();
    for (const device of this.dvmDevices) {
      if (this._shouldSkipDvmDevice(device)) continue;
      const parser = new PsirtDeviceParser({
        device,
        osType: OSTYPES.FGT,
        allPsirtData: this.allPsirtData,
      });
      const parsed = parser.parse();
      if (!parsed) continue;
      this.parsedDvmDevices.push(parsed);
      dvmGroups[parsed.os_type].children.push(parsed);
    }

    return this._postParseGroups(dvmGroups);
  }

  _getParsedAssetDeviceGroups() {
    const assetGroups = getAssetGroups();
    const doParse = (assets, osType, callback) => {
      for (const device of assets) {
        const parser = new PsirtDeviceParser({
          device,
          osType,
          allPsirtData: this.allPsirtData,
        });
        const parsed = parser.parse();
        if (!parsed) continue;
        assetGroups[parsed._gui_os_type].children.push(parsed);
        callback(parsed);
      }
    };
    doParse(this.fap, OSTYPES.FAP, (parsed) => this.parsedFap.push(parsed));
    doParse(this.fap, OSTYPES.FAPS, (parsed) => this.parsedFap.push(parsed));
    doParse(this.fap, OSTYPES.FAPC, (parsed) => this.parsedFap.push(parsed));
    doParse(this.fap, OSTYPES.FAPU, (parsed) => this.parsedFap.push(parsed));
    doParse(this.fsw, OSTYPES.FSW, (parsed) => this.parsedFsw.push(parsed));
    doParse(this.fext, OSTYPES.FEXT, (parsed) => this.parsedFext.push(parsed));

    return this._postParseGroups(assetGroups);
  }

  _getThisFmgGroup() {
    const dvmGroups = getDvmGroups();
    const fmgGroup = dvmGroups[MACROS.DVM.DVM_OS_TYPE_FMG];

    const { byPlatform: irNumsByPlatform } = this.allPsirtData;
    let fmgPlatformStr = fmgGroup.platformName;
    const currentVersion = [
      MACROS.SYS.CONFIG_MAJOR_NUM,
      MACROS.SYS.CONFIG_MINOR_NUM,
      MACROS.SYS.CONFIG_PATCH_NUM,
    ].join('.');
    fmgPlatformStr += '_' + currentVersion;

    const sysconfig = fiSysConfig.current();

    const vulnsArr = irNumsByPlatform[fmgPlatformStr];
    if (!Array.isArray(vulnsArr) || vulnsArr.length === 0) return [];
    fmgGroup.children.push({
      name:
        (sysconfig.hostname || sysconfig.sn) +
        ' ' +
        gettext('(This FortiManager)'),
      firmware: sysconfig.fmgversion,
      vulns: vulnsArr,
      key: fmgPlatformStr,
      platformKey: fmgPlatformStr,
      deviceType: fmgGroup.name,
      gui_upgrade_to: getUpgradeToString({
        currentVersion,
        platformName: fmgGroup.platformName,
        vulnsArr: vulnsArr,
        deviceTypeName: fmgGroup.name,
        allPsirtData: this.allPsirtData,
      }),
      _isFmg: true,
    });
    fmgGroup.isGroup = true;

    return [fmgGroup];
  }

  groupDevices() {
    const dvmGroups = this._getParsedDvmDeviceGroups();
    const assetGroups = this._getParsedAssetDeviceGroups();
    const thisFmgGroup = this._getThisFmgGroup();
    const hasFmg = thisFmgGroup.length;
    return {
      tableData: [...dvmGroups, ...assetGroups, ...thisFmgGroup],
      hasFmg,
    };
  }
}
