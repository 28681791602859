import React, { useEffect, useRef, useState } from 'react';
import { TreeView } from '../tree/tree_view';
import { TreeNode } from '../tree/tree_node';
import { useValidEffect } from 'rh_util_hooks';
import { isFunction } from 'fiutil';
import { fiAdom } from 'fi-session';

// Wraps treeview to make it handle 'fetchData' prop, which is a function that should return the tree data
export const makeAsyncTree = (rowComp) => {
  const AsyncTree = (props) => {
    const { fetchData, selected, getAutomationId, setRefToParent, ...rest } =
      props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [treeNodes, setTreeNodes] = useState([]);

    useValidEffect(async () => {
      await fetchDataAsync();
    }, [fetchData, selected?.key, getAutomationId]);

    async function fetchDataAsync() {
      if (!isFunction(fetchData)) return;

      try {
        const data = await fetchData();
        if (!data) return;

        setIsLoaded(true);
        setTreeNodes(TreeNode.fromStatic(data, selected?.key, getAutomationId));
      } catch (err) {
        console.error(err);
        setIsLoaded(true);
      }
    }

    const refCallback = (treeInstance) => {
      const params = treeInstance
        ? {
            reload: fetchDataAsync,
            scrollToTreeNode: treeInstance.scrollToTreeNode,
          }
        : null;
      setRefToParent?.(params);
    };

    const getSelected = () => {
      return selected || { key: treeNodes?.[0]?.key, trigClick: true };
    };

    return (
      <TreeView
        {...rest}
        isLoaded={isLoaded}
        treeNodes={treeNodes}
        selected={getSelected()}
        ref={refCallback}
      >
        {rowComp}
      </TreeView>
    );
  };
  AsyncTree.displayName = 'AsyncTree';

  return AsyncTree;
};

export class LocalStorage {
  constructor(id) {
    this._id = id;
  }
  load() {
    const value = window.localStorage.getItem(this._id);
    //eslint-disable-next-line
    // console.log('load',this._id,value)

    if (value) {
      return JSON.parse(value);
    }
    return null;
  }
  save(obj) {
    const value = JSON.stringify(obj);
    //eslint-disable-next-line
    // console.log('save',this._id,value)
    window.localStorage.setItem(this._id, value);
  }
}

//eslint-disable-next-line
export const treeStateStorage = (WrappedComponent) => {
  const TreeWithLocalStorage = (props) => {
    const { id, selected, ...rest } = props;
    const adom = fiAdom.current();

    const [selectedKey, setSelectedKey] = useState(selected);
    const localStorageRef = useRef(makeLocalStorage());
    const getLocalStorageRef = () => localStorageRef.current;

    useEffect(() => {
      const value = getLocalStorageRef().load();
      const newSelectedKey = selected?.key
        ? selected
        : value
        ? { key: value.selected.key, trigClick: true }
        : null;
      setSelectedKey(newSelectedKey);
    }, [selected]);

    function makeLocalStorage() {
      const key = `${adom.oid}-${id}:tree`;
      return new LocalStorage(key);
    }

    function onStateChange(newState) {
      // Do not save if it directs to another page
      if (newState.selected.isLink) return;

      const newSelected = {
        selected: newState.selected,
      };
      getLocalStorageRef().save(newSelected);
    }

    return (
      <WrappedComponent
        {...rest}
        selected={selectedKey}
        onStateChange={onStateChange}
      ></WrappedComponent>
    );
  };
  TreeWithLocalStorage.displayName = 'TreeWithLocalStorage';

  return TreeWithLocalStorage;
};

//eslint-disable-next-line
export const treeWrap = (
  WrappedComponent,
  {
    sortDir = 0, //default is no sort, 1 is ASC, -1 Desc
    hasSearchSort = false,
    sortTreeNodes = (treeNodes /*,isSortAsc*/) => treeNodes,
  } = {}
) => {
  const TreeWrapComponent = (props) => {
    const { onNodeClick, ...rest } = props;

    const [reload, setReload] = useState(false);

    const reloadTree = () => {
      setReload((prev) => !prev);
    };

    return (
      <WrappedComponent
        {...rest}
        sortDir={sortDir}
        hasSearchSort={hasSearchSort}
        sortTreeNodes={sortTreeNodes}
        onSelect={(node, params) => {
          onNodeClick(node, reloadTree, params);
        }}
        reload={reload}
      ></WrappedComponent>
    );
  };
  TreeWrapComponent.displayName = 'TreeWrapComponent';

  return TreeWrapComponent;
};
