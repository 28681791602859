import { flatui_proxy, getDataFromResponse } from '../../utils';

export const fetch_device_assigned_pkg = (adomOid) => {
  const url = `/gui/adoms/${adomOid}/devices/assignedpkgs`;
  return flatui_proxy({
    url,
    method: 'get',
  });
};

export const fetch_device_assigned_prerun_cliprof = (adomOid) => {
  const url = `/gui/adoms/${adomOid}/devices/prerunCliprof`;
  return flatui_proxy({
    url,
    method: 'get',
  });
};

export const fetch_assignpkg_and_preruncli = async (adomOid) => {
  const promises = [
    fetch_device_assigned_pkg(adomOid),
    fetch_device_assigned_prerun_cliprof(adomOid),
  ];

  const resps = await Promise.all(promises);
  const pkgData = getDataFromResponse(resps[0]) || {};
  const prerunCliProfData = getDataFromResponse(resps[1]) || [];

  for (const [key, value] of Object.entries(prerunCliProfData)) {
    if (key in pkgData) {
      // for global devices
      pkgData[key] = { ...pkgData[key], ...value };
    } else {
      // for vdom-disabled device, search with vdomOid = 3
      const deviceOid = value.deviceOid;
      const oid = `${deviceOid}-${MACROS.DVM.CDB_DEFAULT_ROOT_OID}`;
      if (pkgData[oid]) {
        pkgData[oid] = { ...pkgData[oid], ...value };
      } else {
        pkgData[oid] = value;
      }
    }
  }

  return { pkgData, resps };
};
