import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isFunction, isNil } from 'lodash';

import { getSessionAdomData } from 'fistore/session/adom/selectors';

import { usePrevious } from './usePrevious';

export const useOnAdomSwitch = (callback, deps = []) => {
  const adom = useSelector(getSessionAdomData);
  const prevAdom = usePrevious(adom);

  const adomOid = adom?.oid;
  const prevAdomOid = prevAdom?.oid;

  useEffect(() => {
    if (!isNil(adomOid) && !isNil(prevAdomOid) && adomOid !== prevAdomOid) {
      if (isFunction(callback)) {
        callback(prevAdom, adom);
      }
    }
  }, [adomOid, prevAdomOid, ...deps]);
};
