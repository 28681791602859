import { fiFmgHttp, fiProxyHttp } from 'fi-http';
import { fiAdminProfile } from 'fi-session';
import { isNil } from 'lodash';
import { formatBytes } from 'kit-number';

export const fguardPMSrvStatusService = {
  get: function () {
    return fiProxyHttp.get('/gui/adom/um/package/service', 'get', {
      results: 50000000,
    });
  },
  getOne: function (sn) {
    return fiProxyHttp.get('/gui/adom/um/package/service', 'getOne', {
      results: 50000000,
      sn,
    });
  },
  push: function (device_names) {
    if (fiAdminProfile.isRestrictedAdmin()) {
      return fiFmgHttp.forward({
        method: 'exec',
        params: [
          {
            url: '/um/device/push',
            data: {
              serial: device_names[0] || '',
              command: 'update-ips',
            },
          },
        ],
      });
    } else {
      return fiProxyHttp.post('/gui/adom/um/package/service', 'pushPending', {
        keys: device_names.join(','),
      });
    }
  },
  pushAll: function () {
    return fiProxyHttp.post('/gui/adom/um/package/service', 'pushAllPending');
  },
};

export const fguardPMRcvStatusService = {
  get: function (showUsedOnly) {
    return fiProxyHttp.get('/gui/adom/um/package/receive', 'get', {
      showUsedOnly: showUsedOnly ? 1 : 0,
      results: 50000000,
    });
  },
  versions: function (key) {
    return fiProxyHttp.get('/gui/adom/um/package/version', 'get', {
      key: key,
    });
  },
  changeVersion: function (key, version) {
    return fiProxyHttp.post('/gui/adom/um/package/version', 'change', {
      key: key,
      version: version,
    });
  },
};

const TABLE_NAME = {
  product: 'product',
  package: 'package',
};
//FIXME: change this after Tommy add MACROS
// eslint-disable-next-line
const PRODUCT_FLAG = 1 << 0;
// eslint-disable-next-line
const OBJECT_FLAG = 1 << 1;

const getUniqueKey = {
  [TABLE_NAME.product]: (obj) => {
    //'product short name' + '-version', product short name same as current FGT/FML/FSA/..., version is major.minor.
    return `${obj.productKey}-${obj.version}`; //version might be null
  },
  [TABLE_NAME.package]: (obj) => {
    //package just use objId
    //be aware of that this key is only used for backend, for GUI it should be combine product and key
    //For example: FMG and FTG might have same objid key but they belong to different products
    return `${obj.objid}`;
  },
};

// eslint-disable-next-line
const setPriority = ({ flags = 0, objectList = [], productList = [] }) => {
  return fiProxyHttp.post('/gui/adom/um/download/priority', 'set', {
    flags,
    // objectList: [],
    // productList: [],
    objectList,
    productList,
  });
};

const updateToServer = async ({
  flags,
  objectList,
  productList,
  newObjectList = [],
  newProductList = [],
}) => {
  try {
    return await setPriority({
      flags,
      objectList: [...objectList, ...newObjectList],
      productList: [...productList, ...newProductList],
    });
  } catch {
    throw 'APIERROR';
  }
};

function convertFDSTime(timeInFileName) {
  //	1508310416 -> 2015-08-31 04:16:00
  // [0123456789]
  let str = timeInFileName;
  str =
    '20' +
    str.slice(0, 2) +
    '-' +
    str.slice(2, 4) +
    '-' +
    str.slice(4, 6) +
    ' ' +
    str.slice(6, 8) +
    ':' +
    str.slice(8) +
    ':00';
  return str;
}

function versionString(full_ver) {
  const toStr = (major, minor) => {
    return major + '.' + `${minor}`.padStart(5, '0');
  };
  let major = (full_ver >> 16) & 0x0000ffff;
  let minor = full_ver & 0x0000ffff;
  return toStr(major, minor);
}

function parseOnePkg(pkg, { product = '' }) {
  let ret = {};
  let obj_fmgi = pkg.obj_fmgi || {};
  //name
  if (obj_fmgi.ext_desc) {
    ret.name = obj_fmgi.subtype
      ? `${obj_fmgi.ext_desc} (${obj_fmgi.subtype})`
      : obj_fmgi.ext_desc;
  } else {
    ret.name = pkg.objid;
  }

  //product
  ret.product = product;

  //version
  ret.version = obj_fmgi.version || '';

  //service
  ret.service = obj_fmgi.service || '';

  //type_r_name
  ret.type_r_name = pkg.objid;

  //aver_r
  if (!isNil(pkg.latest_version) && pkg.latest_verdate) {
    ret.aver_r =
      versionString(pkg.latest_version) +
      ` (${convertFDSTime(pkg.latest_verdate)})`;
  } else {
    ret.aver_r = 'NA';
  }

  //size
  if (!isNil(pkg.latest_versize) && pkg.latest_versize > 0) {
    ret.size = formatBytes(pkg.latest_versize, 2);
  } else {
    ret.size = 'NA';
  }
  ret._oData = pkg;
  ret.objid = pkg.objid;
  return ret;
}

export const fguardDownloadPriorityService = {
  get: function () {
    return fiProxyHttp.get('/gui/adom/um/download/priority', 'get', {});
  },
  getEnabledPackages: function () {
    return fiFmgHttp
      .query({
        id: 1,
        method: 'get',
        params: [
          {
            data: {},
            url: 'um/som/list',
          },
        ],
      })
      .then(
        function (resp) {
          return resp[0]?.data?.['som_list'] || {};
        },
        function () {
          return {};
        }
      );
  },
  getAllTableNames: () => TABLE_NAME,
  updateToServer,
  parseOnePkg,
  getUniqueKey,
};
