import { useDispatch } from 'react-redux';
import { useValidEffect } from './useEffects';
import { isFunction } from 'lodash';
import { go } from 'fistore/routing/slice';

/*
 * rules: an array of rule functions that take ruleInput as input and return a string to navigate to or void

 * ruleInput: any input you wish to call every rule with
 * - NOTE: ruleInput needs a member {__isReady: true} to signal all dependencies are loaded
 * 
 * extraDependencies: any extra dependencies to trigger checking the rule list again
 *
 * navigates on first matched rule and ignores the remaining rules
 */
export function useNavigateRules({
  rules = [],
  ruleInput = {},
  extraDependencies = [],
}) {
  const dispatch = useDispatch();

  useValidEffect(
    async (isValid) => {
      if (!isValid()) return;
      if (!ruleInput.__isReady) return; //wait for all rule inputs to be ready

      for (const rule of rules) {
        if (!isFunction(rule))
          throw 'Invalid navigate rule. Rule must be a function.';
        const newRoute = rule(ruleInput);
        if (newRoute) return dispatch(go({ to: newRoute }));
      }
    },
    [rules, ...extraDependencies, ruleInput]
  );
}
