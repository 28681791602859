import { ProLego } from '@fafm/neowise-pro';
import React, { forwardRef, useMemo } from 'react';
import cn from 'classnames';
import { escapeHtml } from 'kit-escape';
import { isNil } from 'lodash';
import { renderToString } from 'react-dom/server';

export const ObjSSelect2 = forwardRef((props, ref) => {
  const { textAttr = 'text', multipleSelect } = props;
  const detailAttr = 'detail';
  const { 'automation-id': propAutoId, automationId } = props;

  const formatSelectedHTML = useMemo(
    () => makeFormatSelectedHTML(props),
    [textAttr]
  );

  const formatChoiceHTML = useMemo(
    () => makeFormatChoiceHTML(props),
    [formatSelectedHTML, detailAttr]
  );

  const searchFn = useMemo(() => makeSearchFn(props), [textAttr, detailAttr]);

  return (
    <ProLego.SSelect
      ref={ref}
      formatChoiceHTML={formatChoiceHTML}
      formatSelectedHTML={
        multipleSelect ? formatChoiceHTML : formatSelectedHTML
      }
      searchFn={searchFn}
      {...props}
      automationId={propAutoId || automationId}
    />
  );
});
ObjSSelect2.displayName = 'ObjSSelect2';
ObjSSelect2.makeFormatSelectedHTML = makeFormatSelectedHTML;
ObjSSelect2.makeFormatChoiceHTML = makeFormatChoiceHTML;
ObjSSelect2.makeSearchFn = makeSearchFn;

function makeFormatSelectedHTML({ textAttr = 'text' }) {
  return (item, hiliter = (text) => text) => {
    if (!item) return null;

    const text = escapeHtml(item[textAttr]);
    if (item.children)
      return `<div class="obj-name">${text} (${item.children.length})</div>`;

    let iconString = '';
    if (item.icon) {
      if (typeof item.icon === 'function') {
        iconString = item.icon(item);
      } else if (React.isValidElement(item.icon)) {
        iconString = renderToString(item.icon);
      } else {
        iconString = ((iconProps) =>
          iconProps ? `<nw-icon ${iconProps}></nw-icon>` : '')(
          makeNwIconPropString(item.icon)
        );
      }
    }

    return (
      '<div class="obj-name tw-flex tw-items-center">' +
      iconString +
      `<span ${iconString ? 'class="icon-text" ' : ''} title="${text}">${
        hiliter?.(text) || text
      }</span>` +
      '</div>'
    );
  };
}

function makeFormatChoiceHTML(props) {
  const { detailAttr = 'detail' } = props;
  const formatSelectedHTML = makeFormatSelectedHTML(props);

  return (item, hiliter = (text) => text) => {
    if (!item) return null;

    const detailText = escapeHtml(item[detailAttr]);
    return (
      '<div class="obj-box">' +
      formatSelectedHTML(item, hiliter) +
      ((item?.[detailAttr] &&
        `<div class="obj-detail" title="${detailText}">${hiliter(
          detailText
        )}</div>`) ||
        '') +
      '</div>'
    );
  };
}

function makeSearchFn({ textAttr = 'text', detailAttr = 'detail' }) {
  return (data, search) => {
    return search(data[textAttr]) || search(data[detailAttr]);
  };
}

function makeNwIconPropString(prop) {
  if (!prop) return;
  const { classes, className, ...others } = prop;

  return Object.keys(others).reduce((result, propKey) => {
    switch (propKey) {
      case 'name':
      case 'library':
      case 'style':
      case 'title':
        return `${result} ${propKey}="${
          isNil(others[propKey]) ? '' : others[propKey]
        }"`;
    }
    return result;
  }, `class="${cn('tw-mr-1', classes, className)}"`);
}
