import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mergePayload } from 'fistore/utils/reducer';
import { getMaskableLogfields } from './selectors';
import { fiHttpGet } from 'fi-http';

const _slice = createSlice({
  name: 'logView',
  initialState: {},
  reducers: {
    setLogviewContentMenu: mergePayload('logviewContentMenu'),
    setLogDbRebuild: mergePayload('logDbRebuild'),
    setLogFetcher: mergePayload('logFetcher'),
    setLogviewCustomViewer: mergePayload('logviewCustomViewer'),
    // used by rename, share, save, saveAs,... actions on custom viewer
    setLogviewCustomViewerActionParams: mergePayload(
      'logviewCustomViewerActionParams'
    ),
    setMaskableLogfields: mergePayload('maskableLogfields'),
    setPostgresUpgrade: mergePayload('postgresUpgrade'),
  },
});

export const fetchMaskableLogfields = createAsyncThunk(
  'logView/FETCH_MASKABLE_LOGFIELDS',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const fields = getMaskableLogfields(state);
    if (Object.keys(fields).length) {
      return fields;
    }
    const resp = await fiHttpGet('/p/logview/maskableLogfields/');
    dispatch(_slice.actions.setMaskableLogfields(resp));
    return resp;
  }
);

export default _slice.reducer;

export const {
  setLogviewContentMenu,
  setLogDbRebuild,
  setLogFetcher,
  setLogviewCustomViewer,
  setLogviewCustomViewerActionParams,
  setPostgresUpgrade,
} = _slice.actions;
