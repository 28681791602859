import {
  NwProHeader,
  NwProBody,
  NwProSection,
  NwProFooter,
  CancelBtn,
  NwProInputRow,
  openConfirmModal,
} from 'rc_layout';
import { ProTable } from '@fafm/neowise-pro';
import { useMemo, useCallback } from 'react';
import { fiHttpPost, fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { NwIcon } from '@fafm/neowise-core';
import { useReload } from 'rh_util_hooks';
import { fiMessageBox } from 'fi-messagebox';
import { fiWorkspace } from 'fi-workspace';
import { taskUtil } from 'rc_task_monitor';
import { fiStore, fiSession } from 'fistore';
import { get } from 'lodash';

const getAutoId = (name) => 'out_of_sync_device:' + name;

const rowKey = 'seq';
const DEVICE_CONFIG_REVISION_URL = '/gui/adom/dvm/device/revision/%s';

export const OutOfSyncDevice = ({ $opener }) => {
  const [count, reload] = useReload();

  const columns = useColumns();

  const { getToolbarItems } = useToolbar(reload);

  const dataRequest = useCallback(async () => {
    try {
      // get data from server
      const req = {
        id: 1,
        method: 'get',
        params: [
          {
            url: '/dvmdb/adom/' + fiAdom.current().name + '/device',
            filter: [['mgmt_mode', '!=', 0], '&&', ['conf_status', '=', 2]],
            loadsub: 0,
          },
        ],
      };

      const resp = await fiFmgHttp.forward(req);
      let seq = 1;
      return (resp[0].data || [])
        .filter((d) => (d.flags & MACROS.DVM.DVM_DEV_FLAG_IS_MODEL) === 0)
        .map((d) => {
          d.seq = seq++;
          return d;
        });
    } catch {
      return [];
    }
  }, [count]);

  const close = () => {
    $opener.reject();
  };

  return (
    <>
      <NwProHeader>{gettext('Out of Sync Device')}</NwProHeader>
      <NwProBody className='tw-h-full tw-p-4 tw-overflow-y-auto np-body tw-grow'>
        <NwProSection>
          <NwProInputRow label=''>
            <div>
              {gettext(
                'The following FortiGate configurations have been remotely modified. Please review their diffs and choose to accept or revert the modifications.'
              )}
            </div>
          </NwProInputRow>
        </NwProSection>
        <div className='tw-px-4' style={{ height: 'calc(100% - 200px)' }}>
          <ProTable.TableView
            tableId='outOfSyncDeviceTable'
            rowKey={rowKey}
            request={dataRequest}
            columns={columns}
            getToolbarItems={getToolbarItems}
            getContextMenuItems={getToolbarItems}
          />
        </div>
        <NwProSection>
          <NwProInputRow label=''>
            <div>
              *
              {gettext(
                'When accepting remote changes, all local configurations will be replaced by remote configs.'
              )}
            </div>
            <div>
              *
              {gettext(
                'When reverting, the FortiGate will be reset to the latest revision.'
              )}
            </div>
          </NwProInputRow>
        </NwProSection>
      </NwProBody>
      <NwProFooter>
        <CancelBtn onClick={close} automation-id={getAutoId('btn-cancel')}>
          {gettext('Close')}
        </CancelBtn>
      </NwProFooter>
    </>
  );
};

const useColumns = () => {
  const columns = useMemo(() => {
    const cols = [
      {
        key: 'seq',
        dataKey: 'seq',
        title: '#',
        width: parseInt(MACROS.USER.SEQ_COLUMN_WIDTH),
        hidden: true,
      },
      {
        key: 'name',
        dataKey: 'name',
        title: gettext('Name'),
        cellRenderer: ({ cellData, rowData }, highlighter) => {
          let name = 'device',
            classes = 'color-grey';
          if (rowData.conn_status === 1) {
            name = 'up';
            classes = 'color-green';
          } else if (rowData.conn_status === 2) {
            name = 'down';
            classes = 'color-orange';
          }
          return (
            <div>
              <NwIcon name={name} className={`tw-mr-1 ${classes}`}></NwIcon>
              {highlighter(cellData)}
            </div>
          );
        },
      },
      {
        key: 'platform_str',
        dataKey: 'platform_str',
        title: gettext('Platform'),
      },
      {
        key: 'ver',
        dataKey: 'ver',
        title: gettext('Version'),
        dataGetter: ({ rowData }) => `${rowData.os_ver}.${rowData.mr}`,
      },
      {
        key: 'sn',
        dataKey: 'sn',
        title: 'SN',
      },
      {
        key: 'ip',
        dataKey: 'ip',
        title: 'IP',
      },
    ];

    return cols;
  }, []);

  return columns;
};

const useToolbar = (reload) => {
  const getToolbarItems = useCallback((selectedRows) => {
    return [
      {
        key: 'retrieve',
        icon: { name: 'import', label: gettext('Retrieve') },
        label: gettext('Retrieve'),
        disabled:
          !fiWorkspace.adomInfo().isLockedByMe() || !selectedRows.length,
        exec: () => {
          retrieve(selectedRows, reload);
        },
      },
      {
        key: 'revert',
        icon: { name: 'cancel', label: gettext('Revert') },
        label: gettext('Revert'),
        disabled:
          !fiWorkspace.adomInfo().isLockedByMe() || !selectedRows.length,
        exec: () => {
          revert(selectedRows, reload);
        },
      },
      {
        key: 'diff',
        icon: { name: 'view', label: gettext('View Diff') },
        label: gettext('View Diff'),
        disabled: selectedRows.length !== 1,
        exec: () => {
          diff(selectedRows);
        },
      },
    ];
  }, []);

  return { getToolbarItems };
};

function retrieve(selectedRows, reload) {
  const oids = selectedRows.map((r) => ({ oid: r.oid }));
  if (oids.legnth <= 0) return;
  fiStore.dispatch(fiSession.fetchSysConfig()).then(function (cfg) {
    const request = {
      id: 1,
      method: 'exec',
      params: [
        {
          data: {
            adom: fiAdom.current().name,
            flags: ['create_task', 'nonblocking'],
            'reload-dev-member-list': oids,
            tag: 'Retrieved by ' + cfg.username + ' from notification center',
          },
          url: 'dvm/cmd/reload/dev-list',
        },
      ],
    };

    fiHttpPost(MACROS.USER.DEF.URL_FLATUI_API, request).then(
      function (resp) {
        if (resp.data && resp.data.result && resp.data.result[0].data) {
          pollingTask(resp.data.result[0].data.taskid, reload);
          // TODO: Show task monitor modal instead of just message box.
          fiMessageBox.show(
            gettext('A task is created to retrieve the device configurations.'),
            'success'
          );
        } else {
          fiMessageBox.show(
            gettext('Failed to retrieve the device configurations.'),
            'danger'
          );
        }
      },
      function () {
        fiMessageBox.show(
          gettext('Failed to retrieve the device configurations.'),
          'danger'
        );
      }
    );
  });
}

function revert(selectedRows, reload) {
  // const oids = selectedRows.reduce((accu, cur) => {
  //   if (accu.length) accu += ',';
  //   accu += cur.oid;
  //   return accu;
  // }, '');
  if (selectedRows.legnth <= 0) return;
  openConfirmModal({
    title: gettext('Revert Configurations'),
    content: gettext(
      'The selected FortiGates will be reset to the latest revision, are you sure you want to continue?'
    ),
  }).then(
    function () {
      fiFmgHttp
        .post({
          method: 'post',
          url: '/gui/deployment/adom/devices/install/latestrev',
          params: {
            oids: selectedRows.map((it) => it.oid),
          },
        })
        .then(
          function (resp) {
            const data = get(resp, '0.data', {});
            if (data.taskid) {
              pollingTask(data.taskid, reload);
              // TODO: Show task monitor modal instead of just message box.
              fiMessageBox.show(
                gettext(
                  'A task is created to revert the device configurations.'
                ),
                'success'
              );
            } else {
              fiMessageBox.show(
                gettext('Failed to revert the device configurations.'),
                'danger'
              );
            }
          },
          function () {
            fiMessageBox.show(
              gettext('Failed to revert the device configurations.'),
              'danger'
            );
          }
        );
    },
    function () {}
  );
}

function pollingTask(tid, reload) {
  taskUtil
    .wait4Task({
      taskId: tid,
    })
    .promise.then(
      function () {
        reload();
      },
      function () {
        fiMessageBox.show(
          gettext('Failed to run the newly created task.'),
          'danger'
        );
      }
    );
}

async function diff(selectedRows) {
  const oid = selectedRows[0].oid;
  const checkDiffPromise = (async function () {
    const revisionHistoryResp = await fiHttpPost(
      MACROS.USER.DEF.URL_FLATUI_PROXY_API,
      {
        url: DEVICE_CONFIG_REVISION_URL.printf(['history']),
        method: 'get',
        params: {
          deviceId: oid,
        },
      }
    );

    const revisionList = revisionHistoryResp?.result?.[0]?.data;
    const latestRevision = revisionList.find((rev) => rev.current_rev === 1);

    return new Promise(function (resolve, reject) {
      fiHttpPost(MACROS.USER.DEF.URL_FLATUI_PROXY_API, {
        url: DEVICE_CONFIG_REVISION_URL.printf(['diff']),
        method: 'get',
        params: {
          deviceId: oid,
          from: 0, //any unsigned int
          to: latestRevision.rev || 0, //latest installed revision
          options: 0,
          current_config: true, //opt for current remote config
        },
      }).then(
        function (resp) {
          if (resp && resp.result?.[0]?.status?.code === 0) {
            resolve(resp.result[0].data);
          } else {
            reject({
              msg: resp.msg,
            });
          }
        },
        function (err) {
          reject({
            msg: err,
          });
        }
      );
    });
  })();
  const { viewRevisionDiff } = await import(
    'react_apps/ra_dvm/actions/dvm_actions'
  );
  viewRevisionDiff(selectedRows[0], checkDiffPromise);
}
