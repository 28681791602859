// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait = 150, immediate = false) {
  var timeout;
  return function () {
    const context = this,
      args = arguments;
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = 0;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (callNow) func.apply(context, args);
  };
}

// Throttles the execution of a function.
//
// Arguments
//
// - fn: The function to throttle.
// - ms:  How many miliseconds.
// - context: The context of the throttling function.
// @exeLast {Boolean} Whether to execute the last call. Default: true.
export function throttle(fn, ms = 150, exeLast = true, context) {
  let timerId = 0;
  let last = Date.now();

  function wrapper() {
    const args = arguments;
    const now = Date.now();
    const elapsed = now - last;
    const _this = context || this;

    // Always clear timer if fn is called
    timerId && clearTimeout(timerId);

    function exec() {
      last = now;
      fn.apply(_this, args);
    }

    if (elapsed > ms) {
      // if at the defined interval, execute immediately.
      exec();
    } else if (exeLast) {
      // schedule the last call
      timerId = setTimeout(exec, ms);
    }
  }
  wrapper.stop = function () {
    timerId && clearTimeout(timerId);
  };
  return wrapper;
}
