import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import { dvmMainRoute } from './routes_dvm_main';
import { ptRoute } from './routes_pt';
import { monitorsRoute } from './routes_dvm_monitors';
import { chassisRoutes } from './routes_chassis';
import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';

export default {
  path: 'dvm',
  RenderErrorBoundary,
  handle: {
    appUniKey: 'dvm',
  },
  children: [
    getRedirectIndexRoute(),
    /**
     * /dvm/main
     */
    dvmMainRoute,

    /**
     * /dvm/scripts
     */
    getScriptRoute(),

    /**
     * /dvm/firmwaretemplate
     */
    getFirmwareTemplateRoute(),

    /**
     * /dvm/prvtmpl
     */
    ptRoute,

    /**
     * /dvm/monitors
     */
    monitorsRoute,

    /**
     * /dvm/chassis
     */
    chassisRoutes,

    /**
     * /dvm/fortimeter
     */
    getFortiMeterRoute(),
  ],
};

function getScriptRoute() {
  return {
    path: 'scripts',
    handle: {
      appUniKey: 'dvm_scripts',
    },
    lazy: async () =>
      import(/* webpackChunkName: "mod-dvm-scripts" */ './scripts/Scripts'),
  };
}

function getFirmwareTemplateRoute() {
  return {
    path: 'firmwaretemplate',
    handle: {
      appUniKey: 'dvm_firmware_template',
    },
    lazy: async () =>
      import(
        /* webpackChunkName: "mod-dvm-firmware" */ 'react_apps/ra_pt/firmware_template/FirmwareTemplate'
      ),
  };
}

function getFortiMeterRoute() {
  return {
    path: 'fortimeter',
    handle: {
      appUniKey: 'dvm_fortimeter',
    },
    lazy: () =>
      import(
        /* webpackChunkName: "mod-dvm-fortimeter" */ './fortimeter/FortiMeter'
      ),
  };
}
