import { ProForm, ProTable } from '@fafm/neowise-pro';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { CancelBtn, OkBtn } from 'rc_layout';
import { NwIcon, NwTooltip } from '@fafm/neowise-core';

const { Header, Footer, Body } = ProForm;

export const AdomReference = ({ rData, $opener }) => {
  const autoID = (suffix) => `adom_reference:${suffix}`;
  const handleSubmit = () => $opener.resolve();
  const onCancel = () => $opener.reject();

  const [, /*totalRecords*/ refData] = useMemo(() => {
    const CODE = {
      CMDB: 'cmdb',
      DVMDB: 'dvmdb',
      PROVIDER: 'provider',
    };
    const dbmap = {};
    dbmap[CODE.CMDB] = gettext('System Settings');
    dbmap[CODE.DVMDB] = gettext('Device Manager');
    dbmap[CODE.PROVIDER] = gettext('Global Database');
    const dbid = [CODE.CMDB, CODE.DVMDB, CODE.PROVIDER];

    let total_records = 0; // all references rows number that used for calcuating the proper modal height
    const oData = cloneDeep(rData);

    function parseData() {
      const refData = [];
      total_records = 0;
      let i, j, k, io, jo, ko, dbarr, idx, parsed;
      for (i = 0; i < oData.length; i++) {
        idx = 1;
        io = oData[i];
        parsed = {
          adom: io.adom,
          refs: [],
        };
        if (io.data) {
          for (j = 0; j < dbid.length; j++) {
            jo = dbid[j];
            dbarr = io.data[jo];
            if (dbarr) {
              for (k = 0; k < dbarr.length; k++) {
                ko = dbarr[k];
                if (
                  !(
                    jo === CODE.DVMDB &&
                    ko.mapping_name === MACROS.USER.DVM.DVM_MAPPING_NAME &&
                    ko.mkey === MACROS.USER.DVM.DEFAULT_ALL_FGT_SCOPE_NAME
                  )
                ) {
                  parsed.refs.push({
                    idx: idx++,
                    mapping_name: ko.mapping_name + ' (' + dbmap[jo] + ')',
                    mkey: ko.mkey,
                    adom: io.adom,
                    'last use': ko['last use'],
                  });
                  total_records++;
                }
              }
            }
          }
        }
        if (parsed.refs.length > 0) {
          parsed.msg = ngettext(
            "ADOM '%(adom)s' is being referenced by the following object.",
            "ADOM '%(adom)s' is being referenced by the following objects.",
            parsed.refs.length
          ).printfd({ adom: parsed.adom });
          refData.push(parsed);
        }
      }

      return refData;
    }

    return [total_records, parseData()];
  }, [rData]);

  const columns = useMemo(() => {
    return [
      {
        key: 'idx',
        title: gettext('#'),
      },
      {
        key: 'mapping_name',
        title: gettext('Object Name'),
      },
      {
        key: 'mkey',
        title: gettext('Object Key'),
      },
      {
        key: 'last use',
        title: gettext('Single Object'),
        dataGetter: ({ rowData }) => {
          return rowData['last use'] ? gettext('Yes') : gettext('No');
        },
        cellRenderer: ({ cellData, rowData }) => {
          const adom = rowData.adom;
          const name = rowData.mkey;
          return rowData['last use'] ? (
            <div className='tw-flex'>
              <NwTooltip
                content={
                  <div className='tw-break-words tw-whitespace-normal'>
                    {gettext(
                      "Deleting ADOM '%s' will cause user '%s' to be deleted as well."
                    ).printf([adom, name])}
                  </div>
                }
              >
                <NwIcon
                  name={'warning'}
                  className={'color-yellow tw-mr-1'}
                  aria-hidden={true}
                ></NwIcon>
              </NwTooltip>
              {cellData}
            </div>
          ) : (
            cellData
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <Header>
        {ngettext(
          'ADOM Reference Detected',
          'ADOM References Detected',
          refData.length
        )}
      </Header>
      <Body>
        {refData.map((row) => {
          return (
            <div key={row.adom}>
              <div>{row.msg}</div>
              <div className='tw-border tw-border-solid tw-border-border'>
                <ProTable.SimpleTableView
                  tableId={autoID('table')}
                  columns={columns}
                  data={row.refs}
                  ckColumn={false}
                  columnSetting={{ render: null }}
                  footerHeight={0}
                  maxHeight={350}
                  rowKey={'idx'}
                  rowHeight={32}
                />
              </div>
            </div>
          );
        })}
      </Body>
      <Footer>
        <strong>
          {ngettext(
            'Force to delete this ADOM would result in reference clean up.',
            'Force to delete these ADOMs would result in reference clean up.',
            refData.length
          )}
        </strong>
        <OkBtn automation-id={autoID('btn-delete')} onClick={handleSubmit}>
          {gettext('Delete Anyway')}
        </OkBtn>
        <CancelBtn onClick={onCancel} automation-id={autoID('btn-cancel')}>
          {gettext('Cancel')}
        </CancelBtn>
      </Footer>
    </>
  );
};
