import { useSelector } from 'react-redux';
import { fiSession, fiStoreNotifications } from 'fistore';
import { NwIcon } from '@fafm/neowise-core';
import { useCallback, useMemo } from 'react';
import {
  flatMap,
  groupBy,
  size,
  values,
  mapValues,
  pick,
  isFunction,
} from 'lodash';
import notifDefs from './notifications';
import { NavDropdown } from './NavDropdown';

// map notification items to dropdown items.
const notif2DdItem = (notif) => {
  // make consistent params in the callbacks.
  const callbackObj = pick(notif, ['params', 'adomOid', 'id', 'severity']);
  return {
    key: notif.id,
    label: (
      <div key={notif.id} className='tw-flex'>
        {/** put icon in div to preserve icon size when text wraps **/}
        <div className='tw-flex tw-h-7 tw-items-center'>
          <NwIcon
            name={notif.icon}
            library={notif.iconLib}
            className={
              isFunction(notif.iconClass)
                ? notif.iconClass(callbackObj)
                : notif.iconClass
            }
          ></NwIcon>
        </div>
        {/** wrap text when it's too long **/}
        <div className='tw-flex tw-whitespace-pre-wrap tw-leading-7'>
          {notif.message?.(callbackObj) || notif.message}
        </div>
      </div>
    ),
    onSelect: () => {
      notif.exec && notif.exec(callbackObj);
    },
  };
};

export const NotifDropdown = () => {
  const adomOid = useSelector(fiSession.getSessionAdomOid);
  const isRestrictedAdmin = useSelector(fiSession.getIsRestrictedAdmin);
  const notifGlobal = useSelector(fiStoreNotifications.getByAdomOid());
  const notifAdom = useSelector(fiStoreNotifications.getByAdomOid(adomOid));
  const onSelect = useCallback((item) => {
    item.onSelect();
  });
  // merge notifs together, if conflict, use adom one
  const notifs = { ...notifGlobal, ...notifAdom };
  const totalNum = size(notifs);
  // make the dropdown list of items
  const ddItems = useMemo(() => {
    if (!totalNum) return null;
    const mergedNotifs = mapValues(notifs, (notif, key) => ({
      ...notif,
      ...notifDefs[key],
    }));

    // values function changes index to number
    const groups = values(groupBy(mergedNotifs, 'group'));

    // merge the groups, insert divider between them
    return flatMap(groups, (arr, index) => {
      const ddItems = arr.map(notif2DdItem);
      if (index === 0) {
        return ddItems;
      }
      ddItems.unshift({ isDivider: true });
      return ddItems;
    });
  }, [notifAdom, notifGlobal]);

  // don't display this in restricted admin or no items.
  if (isRestrictedAdmin || !totalNum) {
    return null;
  }

  return (
    <NavDropdown
      icon='bell'
      title={gettext('Notification')}
      totalNum={totalNum}
      items={ddItems}
      automationId='notification-dropdown'
      onSelect={onSelect}
    ></NavDropdown>
  );
};

NotifDropdown.displayName = 'NotifDropdown';
