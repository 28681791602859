import { fiStore, fiStoreRouting as fr } from 'fistore';
import $ from 'jquery';

export { getCurrentMenuLayout, onToggleMenuDirectionCallback };

function getCurrentMenuLayout() {
  return fr.getCurrentContentMenuLayout(fiStore.getState());
}

function onToggleMenuDirectionCallback(
  containerEl,
  isHorizontal,
  updateStoreCallback = defaultUpdateStoreCallback
) {
  const el = $(containerEl);
  if (!el.length) return;

  const flexDirection = isHorizontal ? 'column' : 'row';
  el.css({ display: 'flex', flexDirection: flexDirection });

  if (updateStoreCallback) {
    updateStoreCallback(isHorizontal);
  }
}

function defaultUpdateStoreCallback(isHorizontal) {
  fiStore.dispatch(
    fr.setContentMenuLayout(isHorizontal ? 'horizontal' : 'vertical')
  );
}
