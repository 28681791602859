import React from 'react';
import { NwProBody, NwProFooter, CancelBtn } from 'rc_layout';
import { WizardButtonRenderer } from '../WizardButtonRenderer';

export const Success = ({ $opener }) => {
  const buttons = [
    {
      id: 'close',
      text: gettext('Close'),
      Component: CancelBtn,
      props: {
        onClick: () => $opener.resolve(),
      },
    },
  ];

  return (
    <>
      <NwProBody className='tw-w-full tw-h-full'>
        {gettext('The upgrade has been setup successfully.')}
      </NwProBody>
      <NwProFooter>
        <WizardButtonRenderer btns={buttons} />
      </NwProFooter>
    </>
  );
};
