import { NwUploader } from '@fafm/neowise/react';
import { Formik } from 'formik';
import { fmkFooterButtons, FmkTextArea } from 'rc_form';
import { ProForm } from '@fafm/neowise-pro';
const { Header, Body, Footer } = ProForm;

export default function ConsoleScript({ $opener }) {
  const onDropFile = (setScript) => (evt) => {
    const { file, load, error } = evt.detail;
    if (file instanceof File) {
      file
        .text()
        .then((content) => {
          setScript(content);
        })
        .then(load)
        .then(() => {
          evt.target.pond.removeFiles();
        })
        .catch(error);
    }
  };

  const getAutoId = (suffix) => 'console-script:' + suffix;

  const onSubmit = (values) => {
    $opener.resolve(values);
  };

  const onCancel = () => {
    $opener.reject();
  };

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {({ setFieldValue }) => (
        <>
          <Header>{gettext('Run Script from File')}</Header>
          <Body>
            <NwUploader
              onNwProcess={onDropFile((content) =>
                setFieldValue('content', content)
              )}
              automation-id={getAutoId('uploader')}
            />
            <FmkTextArea
              name='content'
              automation-id={getAutoId('content')}
              rows='16'
            />
          </Body>
          <Footer>
            {fmkFooterButtons({
              canWrite: true,
              getAutoId,
              onCancel,
            })}
          </Footer>
        </>
      )}
    </Formik>
  );
}
