import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { NwProInputRow } from 'rc_layout';
import { FmkRadioBtnGroup } from 'rc_form';
import { fiStoreTheme } from 'fistore';
import { NwRadioImage } from '@fafm/neowise/react';
import { NwSwitch } from '@fafm/neowise-core';
import { useDispatch, useSelector } from 'react-redux';
import { useThemeAdapter } from 'rh_theme_hooks';

const { previewTheme, getAllRegular, getGlobalTheme } = fiStoreTheme;

const ThemeModeOpts = [
  { id: 1, text: gettext('Use Global Theme') },
  { id: 0, text: gettext('Use Own Theme') },
];

const getAutoId = (...suffix) => 'sys_change_use_prifile:' + suffix.join('-');

export function UserTheme() {
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const allRegularThemes = useSelector(getAllRegular);
  const globalTheme = useSelector(getGlobalTheme);

  const useGlobalTheme = values.useGlobalTheme;

  const { regularTheme, setRegularTheme, isHighContrast, setIsHighContrast } =
    useThemeAdapter({
      dbTheme: values.userTheme,
      setDbTheme: (val) => setFieldValue('userTheme', val),
    });

  const onSelectTheme = useCallback(
    (theme) => (e) => {
      if (e.target.checked) {
        setRegularTheme(theme.value);
      }
    },
    []
  );

  useEffect(() => {
    dispatch(previewTheme(useGlobalTheme ? globalTheme : values.userTheme));
  }, [useGlobalTheme, values.userTheme]);
  return (
    <>
      <NwProInputRow label={gettext('Theme Mode')}>
        <FmkRadioBtnGroup
          name='useGlobalTheme'
          choices={ThemeModeOpts}
          automation-id={getAutoId('useGlobalTheme')}
        />
      </NwProInputRow>
      {!values.useGlobalTheme && (
        <>
          <NwProInputRow label={gettext('High Contrast Theme')}>
            <NwSwitch
              name='isHighContrast'
              checked={isHighContrast}
              onChange={(e) => {
                setIsHighContrast(e.target.checked);
              }}
              automation-id={getAutoId('isHighContrast')}
            />
          </NwProInputRow>
          {!isHighContrast ? (
            <NwProInputRow label={gettext('Other Themes')}>
              <div
                className={
                  'tw-w-full tw-flex-1 tw-flex-row tw-gap-2 tw-flex-wrap'
                }
              >
                {allRegularThemes.map((theme) => (
                  <NwRadioImage
                    onClick={onSelectTheme(theme)}
                    checked={theme.value === regularTheme}
                    key={theme.id}
                    value={theme.value}
                    name='theme'
                    option-label={gettext(theme.label)}
                    image-src={theme.wallpaper}
                    bkg-color={theme.bgcolor}
                    automation-id={getAutoId('theme:' + theme.name)}
                  ></NwRadioImage>
                ))}
              </div>
            </NwProInputRow>
          ) : null}
        </>
      )}
    </>
  );
}
