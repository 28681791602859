import { useField } from 'formik';
import { useValidateFn } from './util/hook';
import { useRefCallback } from 'rh_util_hooks';
import { callAll, validateAny } from './util/util';
import { compose } from '@fafm/fp';
import { NwIpRange } from '@fafm/neowise-core';

export const FmkIpRange = ({
  // required fields
  name,
  // eslint-disable-next-line
  'automation-id': autoId,

  parser = (v) => v, // form value to ip range component format (array)
  convert = (v) => v, // ip range component value to form value
  // optional fields
  onChange: propOnChange,
  validate,
  ...rest
}) => {
  const generalValidate = useValidateFn({ ...rest });

  const [, { value }, { setValue, setTouched }] = useField({
    name,
    validate: validateAny(validate, generalValidate),
  });

  const onChange = useRefCallback(
    compose(
      callAll((value) => {
        setValue(value);
        setTouched(true, false);
      }, propOnChange),
      convert
    )
  );

  return (
    <NwIpRange
      value={parser(value)}
      onChange={onChange}
      version='4'
      automation-id={autoId}
      {...rest}
    />
  );
};
