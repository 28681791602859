import { fiAdom, fiSysConfig } from 'fi-session';
import { fiFmgHttp } from 'fi-http';
import { fiBufferService } from 'fi-buffer';

export const DEV_GRP_TYPES = {
  normal: MACROS.DVM.DVM_GRP_T_NORMAL,
  default: MACROS.DVM.DVM_GRP_T_DEFAULT,
  auto: MACROS.DVM.DVM_GRP_T_AUTO,
  cluster: MACROS.DVM.DVM_GRP_T_CLUSTER,
};

// FGSP configuration is only supported in FMG
export const canConfigureFGSP = (devGrp) => {
  const type = devGrp?.type;
  return fiSysConfig.isFmg() && type === DEV_GRP_TYPES.cluster;
};

export const getSelectedDevGrp = () => {
  return fiBufferService.dvmgroup.data();
};

export const configureFGSP = async ({ adom, selectedGrp, config }) => {
  if (!selectedGrp) {
    selectedGrp = getSelectedDevGrp();
  }
  adom = adom || fiAdom.current();
  const param = {
    url: `pm/config/adom/${adom.name}/_generate/fgsp/config`,
    data: config,
  };
  const req = {
    method: 'exec',
    params: [param],
  };

  const resp = await fiFmgHttp.query(req);
  return resp?.[0]?.data;
};

/** #1053866: Azure vWAN SLB group */
export const isAzureSlbDevGrp = (group) => {
  return (
    group.type === MACROS.DVM.DVM_GRP_T_CLUSTER &&
    group.cluster_type === MACROS.DVM.DVM_GRP_CLUSTER_T_VWAN
  );
};

/** UMS group */
export const isUMSDevGrp = (group) => {
  return (
    group.type === MACROS.DVM.DVM_GRP_T_CLUSTER &&
    (group.cluster_type === MACROS.DVM.DVM_GRP_CLUSTER_T_UMS_AWS ||
      group.cluster_type === MACROS.DVM.DVM_GRP_CLUSTER_T_UMS_AZURE ||
      group.cluster_type === MACROS.DVM.DVM_GRP_CLUSTER_T_UMS_GCP)
  );
};
