import React from 'react';
import { fiSession } from 'fistore';
export function memory() {
  const cache = new Map();
  return (fn) => (arg) => {
    if (cache.has(arg)) return cache.get(arg);
    const ret = fn(arg);
    cache[arg] = ret;
    return ret;
  };
}

//eslint-disable-next-line
export function reverseArray(input) {
  var ret = [];
  for (var i = input.length - 1; i >= 0; i--) {
    ret.push(input[i]);
  }
  return ret;
}

export const KEYS = {
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  ENTER: 'Enter',
  SHIFT: 'Shift',
};

export class Items {
  constructor(items, currItem, getKey = (o) => o, supportLoop = false) {
    this.items = items;
    this.currItem = currItem;
    //this.equal=equal
    this.getKey = getKey;
    this.supportLoop = supportLoop;
  }
  getCurrentItemPos() {
    if (!this.currItem) return -1;
    for (let i = 0; i < this.items.length; i++) {
      if (this.getKey(this.items[i]) === this.currItem) return i;
    }
    return -1;
  }
  getNextItem(delta) {
    if (!this.currItem) {
      this.currItem = this.getKey(this.items[0]);
    }

    const pos = this.getCurrentItemPos();
    let nextPos = pos + delta;

    if (this.supportLoop) {
      if (nextPos < 0) nextPos = this.items.length - 1;
      else if (nextPos >= this.items.length) nextPos = 0;
    }

    if (nextPos < 0 || nextPos >= this.items.length) return null;

    return this.items[nextPos];
  }
}

export class MenuStatus {
  constructor() {
    this.hasMenuShown = false;
    this.listener = null;
  }
  hasShown() {
    return this.hasMenuShown;
  }
  show(node) {
    this.hasMenuShown = true;
    if (this.listener) this.listener(this.hasMenuShown, node);
  }
  hide(node) {
    this.hasMenuShown = false;
    if (this.listener) this.listener(this.hasMenuShown, node);
  }
  setListener(listener) {
    this.listener = listener;
  }
}

export const tree_processKeyDown =
  ({
    menuStatus,
    order,
    records,
    currentKey,
    processSelectByKeyboard,
    processToggleByKeyboard,
    processEnterByKeyboard,
  }) =>
  (e) => {
    const { LEFT, RIGHT, UP, DOWN, ENTER, SHIFT } = KEYS;
    let hasMenuShown = false;
    if (menuStatus) {
      hasMenuShown = menuStatus.hasShown();
    }
    if (hasMenuShown) {
      if (e.key == UP || e.key == DOWN || e.key == ENTER) {
        e.preventDefault();
        e.stopPropagation();
      }

      return;
    }

    const items = new Items(order, currentKey);
    if (e.key == UP || e.key == DOWN) {
      e.preventDefault();
      e.stopPropagation();

      const isUp = e.key == UP;
      const nextItem = items.getNextItem(isUp ? -1 : 1);
      if (!nextItem) return;

      processSelectByKeyboard(nextItem);
    } else if (e.key == RIGHT || e.key == LEFT) {
      e.preventDefault();
      e.stopPropagation();

      if (!currentKey) return;
      const record = records[currentKey];
      if (!record) return;

      const condition = e.key == RIGHT ? record.isOpen : !record.isOpen;

      if (condition) {
        processToggleByKeyboard(record);
      }
    } else if (e.key == SHIFT) {
      e.preventDefault();
      e.stopPropagation();

      if (!currentKey) return;
      const record = records[currentKey];
      if (!record) return;

      const pos = items.getCurrentItemPos();
      if (pos < 0) return;

      const extraY = pos * 30;
      if (record.node.menuRef) {
        const elem = record.node.menuRef.elem;
        let rect = elem.getBoundingClientRect();
        e.clientX = rect.x + rect.width;
        e.clientY = rect.y + extraY;
        if (record.node.menuRef) {
          record.node.menuRef.handleContextClick(e);
        }
      }
    } else if (e.key == ENTER) {
      e.preventDefault();
      e.stopPropagation();

      if (!currentKey) return;

      const record = records[currentKey];
      if (record) {
        processEnterByKeyboard(record);
      }
    }
  };

export class TreeScroll {
  constructor(getTreeRef) {
    this.getTreeRef = getTreeRef;
  }
  setItemsRendered(data) {
    this.itemsRendered = data;
  }
  adjustScrollbar(itemPos, align = 'smart') {
    if (!this.itemsRendered) return;

    // const {visibleStartIndex, visibleStopIndex}=this.itemsRendered
    const ref = this.getTreeRef();
    if (!ref) return;
    ref.scrollToItem(itemPos, align);

    // if(itemPos>visibleStopIndex){
    //   //this.listRef.current.
    //   ref.scrollToItem(itemPos,'end')
    // }else if(itemPos<visibleStartIndex){
    //   ref.scrollToItem(itemPos,'start')
    // }
  }
}

export const make_highlighter = (searchText) => {
  let lowerCaseSearchText = searchText.toLowerCase();

  return !lowerCaseSearchText
    ? (str) => str
    : (str) => {
        let lstr = str.toLowerCase();
        let idx = lstr.indexOf(lowerCaseSearchText);
        if (idx < 0) {
          return str;
        }

        return (
          <>
            {str.substr(0, idx)}
            <span className='fi-search-highlight'>
              {str.substr(idx, lowerCaseSearchText.length)}
            </span>
            {str.substr(idx + lowerCaseSearchText.length)}
          </>
        );
      };
};

export const getGroupLabel = (adom) => (data) => {
  let label = DEFAULT_GRP_LABEL[data.name];
  if (fiSession.isFaz() && data.oid === MACROS.DVM.DVM_GRP_MANAGED_OID) {
    if (adom.is_fabric) {
      label = DEFAULT_GRP_LABEL.fabric_devices;
    } else if (adom.is_fosfoc || adom.is_ffw || adom.is_fwc || adom.is_fpx) {
      label = DEFAULT_GRP_LABEL[data.name.replace('Managed', 'Logging')]; // for managing adom in faz, use logging label
    }
  }
  if (!label) {
    label = data.name.replace('_', ' ');
  }
  return label;
};

const DEFAULT_GRP_LABEL = {
  fabric_devices: gettext('All Logging Devices'),
  Managed_FortiGate: gettext('Managed FortiGate'),
  Managed_FortiSASE: gettext('Managed FortiSASE'),
  Logging_FortiGate: gettext('Logging FortiGate'),
  Unauthorized_Devices: gettext('Unauthorized Devices'),
  Managed_FortiAnalzyer: gettext('Managed FortiAnalyzer'),
  Managed_FortiCarrier: gettext('Managed FortiCarrier'),
  Logging_FortiCarrier: gettext('Logging FortiCarrier'),
  Managed_FortiFirewall: gettext('Managed FortiFirewall'),
  Logging_FortiFirewall: gettext('Logging FortiFirewall'),
  Managed_FortiFirewallCarrier: gettext('Managed FortiFirewallCarrier'),
  Logging_FortiFirewallCarrier: gettext('Logging FortiFirewallCarrier'),
  Managed_FortiProxy: gettext('Managed FortiProxy'),
  Logging_FortiProxy: gettext('Logging FortiProxy'),
};

export const DEV_STATUS_CONST = {
  txt: {
    device: gettext('Device'),
    devgrp: gettext('Device Group'),
    conn_up: gettext('Connection Up'),
    conn_down: gettext('Connection Down'),
    model_dev: gettext('Model Device'),
    model_dev_up: gettext('Model Device Connection Up'),
    model_dev_down: gettext('Model Device Connection Down'),
    ha: gettext('HA Device'),
    model_ha: gettext('Model HA Cluster'),
    model_ha_up: gettext('Model HA Cluster Connection Up'),
    model_ha_down: gettext('Model HA Cluster Connection Down'),
    autolink_disabled: gettext('Auto-link Disabled'),
    waiting_for_autolink: gettext('Waiting All Secondaries to Auto-Link'),
  },
  icon: {
    devgrp: {
      name: 'group',
    },
    vdom: {
      name: 'vdom',
    },
    conn_up: {
      name: 'up',
    },
    conn_down: {
      name: 'down',
    },
    model_dev: {
      name: 'device',
    },
    model_dev_up: [
      { name: 'device', className: 'color-grey' },
      { name: 'up', className: 'color-green' },
    ],
    model_dev_down: [
      { name: 'device', className: 'color-grey' },
      { name: 'down', className: 'color-orange' },
    ],
    ha: {
      name: 'ha',
    },
    model_ha_up: [
      { name: 'ha', className: 'color-grey' },
      { name: 'up', className: 'color-green' },
    ],
    model_ha_down: [
      { name: 'ha', className: 'color-grey' },
      { name: 'down', className: 'color-green' },
    ],
    modified: {
      name: 'warning',
    },
    disconnected: {
      name: 'disconnected',
    },
  },
  colors: {
    grey: 'color-grey',
    red: 'color-red',
    orange: 'color-orange',
    green: 'color-green',
    yellow: 'color-yellow',
  },
};

export const isRootAdom = (adom) => {
  return adom?.name === 'root';
};
