import { fiSession, fiStorePno, dispatch } from 'fistore';
import { negate, overEvery, overSome } from 'lodash';

import objs from './obj';
import fpx_objs from './fpx_obj';
import { objAppCheckers } from './app_checkers/obj_app_checkers';
import { fetchAdomSyntax } from 'fistore/adomSyntax/slice';
import { fetchPnoDisplayOpts } from 'fistore/pno/slice';
const {
  inManageableAdoms,
  inBackupAdom,
  inOthersAdom,
  inGlobalAdom,
  inFpxAdom,
  hasRDPermitOn,
  isFmg,
} = fiSession;
const { inPnoDualPane } = fiStorePno;

const mrPRIV = MACROS.USER.DVM;
const extractObjAppUniKey = (appUniKey) => {
  // extract the string after these prefixes
  const [, , objAppUniKey] =
    appUniKey.match(
      /^(dual_pane_pno_obj_fpx_|pno_obj_fpx_|dual_pane_pno_obj_|pno_obj_)(.+)/
    ) || [];
  return objAppUniKey;
};

const batchObjs = (objAppDefs, { fpx } = {}) => {
  const batched = {};
  const getAppKey = (appkey) => appkey;
  Object.entries(objAppDefs).forEach(([defKey, def]) => {
    // Fit to App Menu format
    const appkey = getAppKey(defKey);
    const objAppKey = extractObjAppUniKey(appkey);
    const { displayOptChecker, checkers: appCheckers } =
      objAppCheckers[objAppKey] || {};
    batched[appkey] = {
      label: def.label,
      icon: def.icon,
      hideIfNoChildren: def.section,
      // olh-loader: disable
      olhTag: def.olhTag,
      // olh-loader: disable
      olhCategory: def.olhCategory,
      // olh-loader: disable
      olhFmg: def.olhFmg,
      // olh-loader: disable
      olhFaz: def.olhFaz,
      // olh-loader: disable
      olhText: def.olhText,
      // olh-loader: disable
      olhGlbAdom: def.olhGlbAdom,
      showCheckers: [
        fpx ? inFpxAdom : negate(inFpxAdom),
        def.notBackupAdom ? negate(inBackupAdom) : () => true,
        overSome([
          () => def.section,
          overEvery([
            displayOptChecker || (() => true),
            ...(appCheckers || []),
            ...(def.showCheckers || []),
          ]),
        ]),
      ],
      ...(def.section
        ? {}
        : {
            path: `/pno/${appkey}`,
          }),
    };
  });
  return batched;
};

const pnoState = {
  olhTag: 'pno',
  olhCategory: 'pno',
  olhText: '"Policy & Objects"',

  label: gettext('Policy & Objects'),
  icon: 'policy-objects',
  color: '#70808f',
  showCheckers: [
    isFmg,
    negate(inOthersAdom),
    overSome([
      overEvery([
        inManageableAdoms,
        hasRDPermitOn(mrPRIV.ADMINPRIV_ADOM_POLICY_PKG),
      ]),
      overEvery([
        inGlobalAdom,
        hasRDPermitOn(mrPRIV.ADMINPRIV_GLOBAL_POLICY_PK),
      ]),
    ]),
  ],
};

export default {
  pno: {
    ...pnoState,
    path: '/pno',
    showCheckers: [...pnoState.showCheckers],
    hideSubItems: inPnoDualPane,
    loadSubTree: async () => {
      await Promise.all([
        dispatch(fetchAdomSyntax()),
        dispatch(fetchPnoDisplayOpts()),
      ]);
    },
  },
  pno_policy: {
    olhTag: 'pno_pkg',
    olhCategory: 'pno',
    olhFmg: '"Policy & Objects" -> "Policy Packages"',

    path: '/pno/pno_policy',
    label: gettext('Policy Packages'),
    // state: 'adom.default.pno.main.app',
    // stateParams: {
    //   appUniKey: 'pno_policy',
    // },
    showCheckers: [negate(inBackupAdom), negate(inPnoDualPane)],
  },
  pno_hdr_ftr_ips: {
    olhTag: 'pno_pkg',
    olhCategory: 'pno',
    olhGlbAdom: '"Policy & Objects" -> "Policy Packages (Global ADOM)"',

    path: '/pno/pno_hdr_ftr_ips',
    label: gettext('Header/Footer IPS'),
    showCheckers: [
      // negate(inPnoDualPane),
      inGlobalAdom,
      hasRDPermitOn(mrPRIV.ADMINPRIV_IPS_OBJ_CFG),
    ],
  },
  ...batchObjs(objs),
  ...batchObjs(fpx_objs, { fpx: true }),
};
