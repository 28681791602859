import { createRecordAction, createAsyncAction } from '../utils/action';

function createWorkspaceAction(name) {
  return createAsyncAction(name, 'FETCH', 'LOCK', 'UNLOCK', 'SAVE');
}

export const workspaceAdomsAction = {
  ...createWorkspaceAction('workspace-adom'),
  ...createRecordAction('workspace-adom'),
};
export const workspaceDevicesAction = {
  ...createWorkspaceAction('workspace-device'),
  ...createRecordAction('workspace-device'),
};

export const workspacePkgsAction = {
  ...createWorkspaceAction('workspace-pkg'),
  ...createRecordAction('workspace-pkg'),
};
export const workspacePkgChildrenStatusAction = {
  ...createWorkspaceAction('workspace-pkg-children-status'),
  ...createRecordAction('workspace-pkg-children-status'),
};
export const workspacePoliciesAction = {
  ...createWorkspaceAction('workspace-policy'),
  ...createRecordAction('workspace-policy'),
};
export const workspaceObjectsAction = {
  ...createWorkspaceAction('workspace-object'),
  ...createRecordAction('workspace-object'),
};

export const SET_WORKSPACE_ADOM = 'SET_WORKSPACE_ADOM';
