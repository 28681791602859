import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';

import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import centralModeRoutes from './routes_ap_central';
import perDeviceModeRoutes from './routes_ap_pdm';
import mapRoutes from './routes_ap_map';

export default {
  path: 'fortiap',
  RenderErrorBoundary,
  handle: {
    appUniKey: 'fap',
  },
  lazy: () => import('./FapMain'),
  children: [
    getRedirectIndexRoute(),
    ...centralModeRoutes,
    ...perDeviceModeRoutes,
    mapRoutes,
  ],
};
