import { fiFmgHttp } from 'fi-http';

export { getTimeZoneOptions };

const getTimeZoneOptions = () => {
  return fiFmgHttp
    .post({
      url: '/gui/sys/dashboard',
      method: 'getTimezones',
    })
    .then((resp) => resp[0]?.data?.timezones || []);
};
