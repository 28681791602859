import { fiFmgHttp } from 'fi-http';
import { fiDeviceDataLoader } from './device_data_loader';
import { deviceStatus } from 'fi-dvm';
import { isGlobalDomain, isVdomDomain } from './util';
import { escapeSlash } from 'kit/kit-regexp';
import { isNil } from 'lodash';

export {
  update_device_proxy_data,
  delete_device_proxy_data,
  send_device_proxy_data,
  genDvmConfigUrl,
};

const DEVICE_URL = 'pm/config/device/';

const update_device_proxy_data = (
  deviceName,
  vdomName,
  categories,
  data,
  newData,
  cateSyntax
) => {
  const currentDev = fiDeviceDataLoader.getDeviceByName(deviceName);
  const isDeviceNoVdom = deviceStatus.isDeviceNoVdom(currentDev?.rtype);

  let urlToken;
  let params = {};
  let url;
  urlToken = checkDomainNGetUrlToken({
    cateSyntax,
    vdomName,
    isDeviceNoVdom,
  });

  url = _genUrlFromCategories(deviceName, urlToken, categories);
  params = Object.assign(params, {
    url: url,
  });
  if (newData) {
    params = Object.assign(params, {
      data: newData,
    });
  } else {
    params = Object.assign(params, {
      data: data,
    });
  }
  let req = {
    method: newData ? 'add' : 'update',
    params: [params],
  };

  return fiFmgHttp.query(req).then(
    function (response) {
      return response[0];
    },
    function (error) {
      return error;
    }
  );
};

const delete_device_proxy_data = (
  deviceName,
  vdomName,
  categories,
  data,
  mKey,
  cateSyntax
) => {
  const currentDev = fiDeviceDataLoader.getDeviceByName(deviceName);
  const isDeviceNoVdom = deviceStatus.isDeviceNoVdom(currentDev?.rtype);

  let urlToken;
  let params = {};
  let url;
  let filter = [];
  urlToken = checkDomainNGetUrlToken({
    cateSyntax,
    vdomName,
    isDeviceNoVdom,
  });

  url = _genUrlFromCategories(deviceName, urlToken, categories);
  params = Object.assign(params, {
    url: url,
  });
  params = Object.assign(params, {
    confirm: 1,
  });
  filter.push(mKey);
  filter.push('in');
  for (let i = 0; i < data.length; i++) {
    filter.push(data[i]);
  }
  params = Object.assign(params, {
    filter: filter,
  });
  let req = {
    method: 'delete',
    params: [params],
  };
  // error should not be caught here, otherwise the caller will not get error in catch/reject method.
  return fiFmgHttp.query(req).then(function (response) {
    return response[0];
  });
};

const send_device_proxy_data = (
  deviceName,
  vdomName,
  syntax,
  attribute,
  categories,
  cateSyntax,
  verbose = false
) => {
  const currentDev = fiDeviceDataLoader.getDeviceByName(deviceName);
  const isDeviceNoVdom = deviceStatus.isDeviceNoVdom(currentDev?.rtype);

  // notice: we need to check cateSyntax here.
  // if the domain is 1, then it is global level; if it is 2 then it is vdom level; if it is 3 then its is both device and vdom level.
  let urlToken;
  // when vdom has long name it could longname/shortname format
  // need to remove shortname
  vdomName = vdomName ? vdomName.split(/\//)?.[0] : null;
  urlToken = checkDomainNGetUrlToken({
    cateSyntax,
    vdomName,
    isDeviceNoVdom,
  });

  let params = {};
  let url;
  deviceName = escapeSlash(deviceName);
  if (syntax && !attribute) {
    // only syntax
    params = Object.assign(params, {
      option: 'syntax',
    });
    url = DEVICE_URL + deviceName + '/' + urlToken + '/_syntax/cli_only';
  } else if (syntax && attribute && categories) {
    // get attribute
    params = Object.assign(params, {
      option: 'syntax',
    });
    url = _genUrlFromCategories(deviceName, urlToken, categories);
  } else {
    // !syntax and query data
    url = _genUrlFromCategories(deviceName, urlToken, categories);
  }
  params = Object.assign(params, {
    url: url,
  });
  let req = {
    method: 'get',
    params: [params],
    verbose: verbose ? 1 : undefined,
  };
  return fiFmgHttp.forward(req).then(function (response) {
    return response[0];
  });
};

const replaceFields = (obj1, obj2, fields) => {
  for (let field of fields) {
    obj1[field] = obj2[field];
  }
};

export const getMergedVerboseDeviceData = async (
  deviceName,
  vdomName,
  syntax,
  attribute,
  categories,
  cateSyntax,
  verboseFields
) => {
  if (verboseFields.length > 0) {
    return Promise.all([
      send_device_proxy_data(
        deviceName,
        vdomName,
        syntax,
        attribute,
        categories,
        cateSyntax
      ),
      send_device_proxy_data(
        deviceName,
        vdomName,
        syntax,
        attribute,
        categories,
        cateSyntax,
        true
      ),
    ]).then(function ([flatui_getdata, verboseData]) {
      if (Array.isArray(flatui_getdata.data)) {
        for (let i in flatui_getdata.data) {
          replaceFields(
            flatui_getdata.data[i],
            verboseData.data[i],
            verboseFields
          );
        }
      } else {
        replaceFields(flatui_getdata.data, verboseData.data, verboseFields);
      }
      return flatui_getdata;
    });
  } else {
    return send_device_proxy_data(
      deviceName,
      vdomName,
      syntax,
      attribute,
      categories,
      cateSyntax
    );
  }
};

function checkDomainNGetUrlToken({ cateSyntax, vdomName, isDeviceNoVdom }) {
  let urlToken = '';

  if (!cateSyntax) {
    if (isDeviceNoVdom) {
      urlToken = 'vdom/root';
    } else {
      if (vdomName === 'global' || !vdomName) {
        urlToken = 'global';
      } else {
        urlToken = vdomName ? 'vdom/' + vdomName : 'global';
      }
    }
    return urlToken;
  }

  // find domain in cateSyntax
  let domain;
  for (const prop in cateSyntax.data) {
    domain = parseInt(cateSyntax.data[prop].domain);

    // no vdom name but is vdom only domain
    if (!vdomName && isVdomDomain(domain)) {
      urlToken = 'vdom/root';
    } else {
      // global only or double domain
      if (isGlobalDomain(domain)) {
        urlToken = 'global';
      } else {
        // vdom disabled
        if (isDeviceNoVdom) {
          urlToken = 'vdom/root';
        } else {
          // vdom OR global dev
          urlToken = vdomName ? 'vdom/' + vdomName : 'global';
        }
      }
    }
  }

  return urlToken;
}

function _genUrlFromCategories(deviceName, urlToken, categories) {
  let _url = DEVICE_URL + deviceName + '/' + urlToken;
  for (let i = 0; i < categories.length; i++) {
    _url += '/' + categories[i];
  }
  return _url;
}

const genDvmConfigUrl = ({ device, vdomName, syntax }) => {
  const isDeviceNoVdom = deviceStatus.isDeviceNoVdom(device?.rtype);

  const urlToken = checkDomainNGetUrlToken({
    cateSyntax: {
      data: {
        prop: syntax,
      },
    },
    vdomName,
    isDeviceNoVdom,
  });

  const categories = !isNil(syntax?.path)
    ? syntax.path.split(' ')
    : syntax.cate?.split(' ') || '';
  return _genUrlFromCategories(device?.name, urlToken, categories);
};
