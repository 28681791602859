import React from 'react';
import { isFunction, isString } from 'fiutil';
import { fiMessageBox } from 'fi-messagebox';
import { saveToLocalFile } from 'kit-file';

// Components
import { ProToolkit } from '@fafm/neowise-pro';
import {
  NwProHeader,
  NwProBody,
  NwProSection,
  NwProInputRow,
  NwProFooter,
  OkBtn,
  CancelBtn,
  ConditionalComponent,
} from './form';

/**
 * Open Install Log
 * @param {title}: string, title of the modal
 * @param {fileContent}: string | function | Component, content to render
 * @param {getAutoId}: func, returns the automation id of the button
 * @param {getDownloadData}: func, () => ({ data: '', filename: '' })
 */
export const InstallLog = ({
  $opener,
  title = gettext('View Install Log'),
  fileContent = '',
  getAutoId = (field) => `install_log:${field}`,
  getDownloadData = undefined,
}) => {
  const renderContent = () => {
    if (isString(fileContent)) {
      return (
        <pre>
          <div dangerouslySetInnerHTML={{ __html: fileContent }} />
        </pre>
      );
    } else if (isFunction(fileContent)) return fileContent();
    return null;
  };

  return (
    <>
      <NwProHeader>{title}</NwProHeader>
      <NwProBody>
        <NwProSection>
          <NwProInputRow>{renderContent()}</NwProInputRow>
        </NwProSection>
      </NwProBody>

      <NwProFooter>
        <ConditionalComponent condition={!!getDownloadData}>
          <OkBtn
            automationId={getAutoId('download_btn')}
            onClick={async () => {
              try {
                const { data, filename } = await getDownloadData();
                return saveToLocalFile(filename, data);
              } catch (err) {
                console.error(err);
                fiMessageBox.show(gettext('Open log failed'), 'danger');
              }
            }}
          >
            {gettext('Download')}
          </OkBtn>
        </ConditionalComponent>

        <CancelBtn
          automationId={getAutoId('close_btn')}
          onClick={() => $opener.reject()}
        >
          {gettext('Close')}
        </CancelBtn>
      </NwProFooter>
    </>
  );
};

export const openInstallLog = ({
  openFn = ProToolkit.openModal,
  options = {},
  props = {},
}) => {
  return openFn(<InstallLog {...props} />, options);
};
