import { fiFmgHttp } from 'fi-http';
import { fiSysConfig } from 'fi-web/fi-session';
import { isString, negate } from 'lodash';
import { openConfirmModal } from 'react_components/rc_layout/ConfirmModal';

export { reboot, shutdown, restore, upgrade, format, checkimage };

const reboot = (reason, type) => {
  return fiFmgHttp.post({
    url: '/gui/sys/operation',
    method: 'reboot',
    params: {
      reason: reason,
      type: type,
    },
  });
};

const shutdown = (reason) => {
  return fiFmgHttp.post({
    url: '/gui/sys/operation',
    method: 'shutdown',
    params: {
      reason: reason,
    },
  });
};

const restore = () => {
  return fiFmgHttp.post({
    url: '/gui/sys/operation',
    method: 'restore',
  });
};

const upgrade = () => {
  return fiFmgHttp.post({
    url: '/gui/sys/operation',
    method: 'upgrade',
  });
};

const format = () => {
  return fiFmgHttp.post({
    url: '/gui/sys/operation',
    method: 'reset',
    params: {
      factory: 1,
    },
  });
};

const checkimage = () => {
  return fiFmgHttp.post({
    url: '/gui/sys/operation',
    method: 'checkimage',
  });
};

/**
 * throw { status?: number; message?: string } or resolve to continue.
 */
export const validateUploadedImage = async () => {
  const response = await checkimage().catch(() => {});

  const { status, upgrade_info } = response?.[0].data || {
    status: MACROS.SYS.UPG_ERR,
  };

  const statusMessage = (message) => {
    return {
      status,
      message,
    };
  };

  switch (status) {
    case MACROS.SYS.UPG_OK: {
      return promptFirmwareWarnings(upgrade_info);
    }
    case MACROS.SYS.UPG_ERR_INVALID:
      throw statusMessage(gettext('Invalid image type'));
    case MACROS.SYS.UPG_ERR_TOO_BIG:
      throw statusMessage(gettext('New image is too big'));
    case MACROS.SYS.UPG_ERR_INVALID_SIG:
      throw statusMessage(gettext('Firmware signature verification failed'));
    case MACROS.SYS.UPG_ERR_FIPS_INVALID_SIG:
      throw statusMessage(
        gettext('FIPS firmware signature verification failed')
      );
    case MACROS.SYS.UPG_ERR_INVALID_SQLDB_ST:
      throw statusMessage(
        gettext(
          'Unable to upgrade to the new version. Remote SQL is no longer supported'
        )
      );
    case MACROS.SYS.UPG_ERR:
    default:
      throw statusMessage(gettext('Unknown error'));
  }
};

const promptFirmwareWarnings = async ({
  needs_license_check,
  // warn_admin_pwd_reset,
  warn_downgrade,
  warn_maturity,
  // warn_new_partition,

  // uploaded image info
  major,
  minor,
  patch,
  build,
  // branchpt,
  date,
  label,
}) => {
  const statusMessage = (message) => {
    return {
      status: MACROS.SYS.UPG_ERR,
      message,
    };
  };

  // ipsilo vm does not have license
  if (process.env.NODE_ENV !== 'development') {
    if (needs_license_check && !fiSysConfig.hasValidLicense('FMWR')) {
      // PMDB 23964
      const productName = fiSysConfig.isFmg()
        ? 'FortiManager'
        : 'FortiAnalyzer';
      throw statusMessage(
        gettext(
          'This %(productName)s does not have a valid Firmware Contract for upgrading/downgrading to another major/minor version.'
        ).printfd({ productName })
      );
    }
  }

  const targetBuild = getGuiFirmwareText({
    major,
    minor,
    patch,
    build,
    date,
    label,
  });

  const messages = [
    warn_maturity &&
      gettext(
        'The Mature level of the currently installed firmware version is different than the Feature level of the firmware version selected. Use extra caution, as the Feature release may contain changes which impact production environments.'
      ),
    warn_downgrade &&
      gettext(
        'Downgrading image from  %(currentBuild)s to %(downgradeBuild)s. Configuration may be lost in a downgrading.'
      ).printfd({
        currentBuild: getGuiFirmwareText({
          major: MACROS.SYS.CONFIG_MAJOR_NUM,
          minor: MACROS.SYS.CONFIG_MINOR_NUM,
          patch: MACROS.SYS.CONFIG_PATCH_NUM,
          build: MACROS.SYS.CONFIG_BUILD_NUMBER,
          date: MACROS.SYS.CONFIG_BUILD_TIME,
          label: MACROS.SYS.CONFIG_BUILD_LABEL,
        }),
        downgradeBuild: targetBuild,
      }),
  ].filter(isString);

  const result = {
    downgrading: warn_downgrade ? true : false,
    targetBuild,
  };

  if (!messages.length) {
    return result;
  }

  return openConfirmModal({
    title: warn_downgrade
      ? gettext('Confirm Downgrade')
      : gettext('Confirm Upgrade'),
    content: (
      <div className='tw-flex tw-flex-col tw-gap-4 tw-p-2'>
        {messages.map((msg, i) => {
          return <div key={i}>{msg}</div>;
        })}
      </div>
    ),
  })
    .then(() => {
      return result;
    })
    .catch(() => {
      // rejected by user.
      throw { message: 'firmware update aborted' };
    });
};

const getGuiFirmwareText = ({
  major,
  minor,
  patch,
  build,
  // date,
  label,
}) => {
  function pad(num, size) {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  build = build ? pad(build, 4) : 'N/A';
  label = getGuiFirmwareLabel(label);

  return [
    `v${major}.${minor}.${patch}`,
    `build${build}`,
    // date, do not show date (PMDB #27974)
    label ? `(${label})` : '',
  ]
    .filter(Boolean)
    .join(' ');
};

const getGuiFirmwareLabel = (label) => {
  if (!label || !isString(label)) {
    return '';
  }

  let labels = label
    .trim()
    .replace(/^\(|\)$/g, '')
    .split('.')
    .map((label) => {
      if (label === 'M') {
        return 'Mature';
      }
      if (label === 'F') {
        return 'Feature';
      }
      return label;
    })
    .filter(Boolean);

  if (labels.length > 1) {
    const maturity = new Set(['Mature', 'Feature']);
    const hasMaturityFlag = maturity.has.bind(maturity);

    const keepMaturity =
      labels.some(hasMaturityFlag) &&
      ['GA'].some((label) => {
        if (labels.includes(label)) {
          labels = labels.filter((l) => l !== label);
          return true;
        }
      });

    if (!keepMaturity) {
      labels = labels.filter(negate(hasMaturityFlag));
    }
  }

  return labels.length ? labels.join('.') : '';
};

const getBackendFirmwareInfo = (text) => {
  const regex = /v(\d+)\.(\d+)\.(\d+)-build(\d+)(?: (\d+))?(?: \(([^)]+)\))?/;
  const match = text.match(regex);

  return {
    major: parseInt(match[1], 10),
    minor: parseInt(match[2], 10),
    patch: parseInt(match[3], 10),
    build: parseInt(match[4], 10),
    date: match[5] || '',
    label: match[6] || '',
  };
};

export const transformBackendFirmwareText = (text) => {
  try {
    return getGuiFirmwareText(getBackendFirmwareInfo(text));
  } catch (ex) {
    return text;
  }
};
