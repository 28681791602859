import { fiHttp } from 'fi-http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listenerMiddleware } from 'fistore/middlewares';
import { getCustomViews, getCustomViewMenu } from './selectors';
import { genAppNode } from '../../routing/utils';
import { insertAppTree } from '../../routing/slice';
import { appEnabled } from '../../routing/selectors';
import { fetchSessionAdom } from '../../session/adom/slice';

const initialState = {
  loading: false,
  data: [],
};

const slice = createSlice({
  name: 'customView',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomViews.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomViews.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(createCustomView.fulfilled, (state, { payload }) => {
        state.data.push(payload);
      })
      .addCase(
        renameCustomView.fulfilled,
        (state, { payload: { uuid, name } }) => {
          let dash = state.data.find((x) => x.uuid === uuid);
          dash.name = name;
        }
      )
      .addCase(deleteCustomView.fulfilled, (state, { payload }) => {
        state.data = state.data.filter((x) => x.uuid !== payload.uuid);
      });
  },
});

const fetchCustomViews = createAsyncThunk(
  'soc/customView/FETCH_CUSTOM_VIEWS',
  async () => {
    const resp = await fiHttp.get('/p/noc/get_user_dashes/');
    return resp.data;
  }
);

const createCustomView = createAsyncThunk(
  'soc/customView/CREATE_CUSTOM_VIEW',
  async (dash, { rejectWithValue }) => {
    const resp1 = await fiHttp.post('/p/noc/create_dash/', dash);
    if (resp1?.data?.status?.code) {
      return rejectWithValue(resp1.data.status.message);
    }
    // some logic in python side, need to call get dash
    const resp2 = await fiHttp.get('/p/noc/get_dash/', {
      params: {
        uuid: resp1.data.data.uuid,
      },
    });
    return resp2.data.dash;
  }
);

const renameCustomView = createAsyncThunk(
  'soc/customView/RENAME_CUSTOM_VIEW',
  async ({ dashId: uuid, name }, { rejectWithValue }) => {
    const resp = await fiHttp.post('/p/noc/rename_dash/', {
      uuid,
      name,
    });
    if (resp?.data?.status?.code) {
      return rejectWithValue(resp.data.status.message);
    }
    return { uuid, name };
  }
);

const deleteCustomView = createAsyncThunk(
  'soc/customView/DELETE_CUSTOM_VIEW',
  async (uuid, { rejectWithValue }) => {
    const resp = await fiHttp.post('/p/noc/remove_dash/', {
      uuid: uuid,
    });
    if (resp?.data?.status?.code) {
      return rejectWithValue(resp.data.status.message);
    }
    return { uuid };
  }
);

// fetch customview on adom change
listenerMiddleware.startListening({
  actionCreator: fetchSessionAdom.fulfilled,
  effect: async (action, { dispatch, condition, cancelActiveListeners }) => {
    cancelActiveListeners();
    if (await condition((_, currState) => appEnabled('fortiview')(currState))) {
      await dispatch(fetchCustomViews());
    }
  },
});

// when customview data changed, update side menu
listenerMiddleware.startListening({
  predicate: (action, currState, prevState) => {
    return getCustomViews(currState) !== getCustomViews(prevState);
  },
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const userMenuItems = getCustomViewMenu(state);

    const apps = userMenuItems.reduce((res, item) => {
      res[item.stateParams.appUniKey] = item;
      return res;
    }, {});

    const subTree = Object.keys(apps).map((key) => genAppNode(key, null));
    await dispatch(
      insertAppTree({ key: 'fortiview_custom_view', subTree, apps })
    );
  },
});

export {
  fetchCustomViews,
  createCustomView,
  renameCustomView,
  deleteCustomView,
};

export default slice.reducer;
