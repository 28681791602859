import { ProToolkit } from '@fafm/neowise-pro';
import { dispatch } from 'fistore';
import { go } from 'fistore/routing/slice';
import LogSustainRate from './LogSustainRate';
import LogExceeded from './LogExceeded';

const goUrl = (url) => dispatch(go({ to: url }));

export default {
  [MACROS.NOTICE.NOTICE_TYPE_LOG_FETCHER_REQUESTS]: {
    message: ({ params }) => {
      return ngettext(
        '%s log fetcher request.',
        '%s log fetcher requests.',
        params.num
      ).printf([params.num]);
    },
    exec: () => goUrl('/sys/logfetch'),
  },
  [MACROS.NOTICE.NOTICE_TYPE_FABRIC_PENDING_MEMBER]: {
    message: ({ params }) => {
      return ngettext(
        '%s pending FortiAnalyzer Fabric join request.',
        '%s pending FortiAnalyzer Fabric join requests.',
        params.fabricPendingNum
      ).printf([params.fabricPendingNum]);
    },
    exec: () => goUrl('/sys/fabric/settings'),
  },
  [MACROS.NOTICE.NOTICE_TYPE_LOG_SUSTAIN_RATE]: {
    message: (/*{params}*/) => {
      return gettext('Max SQL insert limit has been reached');
    },
    exec: ({ params }) => {
      ProToolkit.openModal(<LogSustainRate sustain={params.logSustainRate} />, {
        height: '20rem',
      });
    },
  },
  [MACROS.NOTICE.NOTICE_TYPE_LOG_STORAGE_STATICS_DB]: {
    message: ({ params }) => {
      return gettext('Analytic is using %s% of allocate disk space.').printf([
        params?.percent,
      ]);
    },
    exec: () => goUrl('/sys/alladoms'),
  },
  [MACROS.NOTICE.NOTICE_TYPE_LOG_STORAGE_STATICS_FILE]: {
    message: ({ params }) => {
      return gettext('Archive is using %s% of allocate disk space.').printf([
        params?.percent,
      ]);
    },
    exec: () => goUrl('/sys/alladoms'),
  },
  [MACROS.NOTICE.NOTICE_TYPE_LOG_INFO]: {
    message: () => {
      return gettext(
        'Your daily logs GB/day limit is exceeded within the last 7 days.'
      );
    },
    exec: async ({ params }) => {
      ProToolkit.openModal(<LogExceeded notif={params} />, { height: '52rem' });
    },
  },
};
