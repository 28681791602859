import cn from 'classnames';
import { NwButton } from '@fafm/neowise-core';

export function LoginButton({
  className,
  text,
  onClick = null,
  icon = null,
  ...props
}) {
  return (
    <NwButton
      className={cn(
        'tw-w-full tw-mt-2 tw-text-neutral-1000 focus:tw-shadow-focus',
        className
      )}
      onClick={onClick}
      prefix={icon}
      size='large'
      type='default'
      {...props}
    >
      {text}
    </NwButton>
  );
}
