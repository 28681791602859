import { useCallback } from 'react';
import { useFieldValue } from 'formik';

import { ToggleableProSection } from 'rc_layout';

import { FmkSwitch } from './FmkSwitch';

export const FmkToggleableProSection = ({
  name,
  automationId,
  'automation-id': propAutoId,
  switchProps = {},
  children,
  ...rest
}) => {
  const renderSwitch = useCallback(() => {
    const autoId = automationId || propAutoId || name;
    return <FmkSwitch name={name} automationId={autoId} {...switchProps} />;
  }, [name, automationId, propAutoId, switchProps]);

  const [checked] = useFieldValue(name);
  return (
    <ToggleableProSection renderSwitch={renderSwitch} {...rest}>
      {!!checked && children}
    </ToggleableProSection>
  );
};
FmkToggleableProSection.displayName = 'FmkToggleableProSection';
