import { createAction } from '@reduxjs/toolkit';

export const INIT_COLLECTION = 'collections/INIT_COLLECTION';
export const initCollection = createAction(INIT_COLLECTION);

export const SET_META = 'collections/SET_META';
export const setMeta = createAction(SET_META);

export const START_LOAD = 'collections/START_LOAD';
export const startLoad = createAction(START_LOAD);

export const END_LOAD = 'collections/END_LOAD';
export const endLoad = createAction(END_LOAD);

export const CANCEL_LOAD = 'collections/CANCEL_LOAD';
export const cancelLoad = createAction(CANCEL_LOAD);

export const ADD_ITEMS = 'collections/ADD_ITEMS';
export const addItems = createAction(ADD_ITEMS);

export const UPDATE_ITEMS = 'collections/UPDATE_ITEMS';
export const updateItems = createAction(UPDATE_ITEMS);

export const DELETE_ITEMS = 'collections/DELETE_ITEMS';
export const deleteItems = createAction(DELETE_ITEMS);

export const SET_REFS = 'collections/SET_REFS';
export const setRefs = createAction(SET_REFS);

export const UPDATE_GROUP = 'collections/UPDATE_GROUP';
export const updateGroup = createAction(UPDATE_GROUP);

export const SET_ERROR = 'collections/SET_ERROR';
export const setError = createAction(SET_ERROR);
