import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fiSysConfig } from 'fi-session';
import { PageLoading } from '../components';
import { IncidentEndpoint } from './IncidentEndpoint';
import { getServerTimeByTimePeriod } from 'fi-datetime';
import { fiHttpGet } from 'fi-http';
import './IncidentEndpoint.less';
import fiUebaService from './fiUebaService';

export const MultiUebaEndpoint = ({
  euid,
  isWatched,
  proxiedServer,
  proxiedAdom,
}) => {
  const [originalData, setOriginalData] = useState([]);
  const [endpoints, setEndpoints] = useState([]);
  const [moreEndpoints, setMoreEndpoints] = useState([]);
  const [loading, setLoading] = useState(false);

  let isSupervisor = fiSysConfig.isFazSupervisor();
  let userCfg = {
    count: 5000,
    search: 'euid=' + euid,
    timePeriod: { unit: 'day', num: 60 },
    proxiedServer: proxiedServer,
    proxiedAdom: proxiedAdom,
  };
  const getDetailUrl = (epid) => {
    return fiUebaService.getAssetPath(epid, proxiedServer, proxiedAdom);
  };
  const getMoreDetailUrl = (endpoints) => {
    const epids = endpoints.map((ep) => ep.epid).join('_');
    return fiUebaService.getAssetPath(epids, proxiedServer, proxiedAdom);
  };
  const MAXIMUM_DISPLAY_ENDPOINT = 5;

  const fetch = () => {
    setLoading(true);
    getServerTimeByTimePeriod(userCfg.timePeriod)
      .then((res) => {
        let serverTime = {
          startTime: res.start_time.slice(0, 6).join(','),
          endTime: res.end_time.slice(0, 6).join(','),
        };
        return fiHttpGet('/p/util/endusers/', {
          params: { ...userCfg, serverTime },
        });
      })
      .then((data) => {
        setLoading(false);
        setOriginalData(data.data[0]?.endpoint || []);
        setEndpoints(
          data.data[0]?.endpoint.slice(0, MAXIMUM_DISPLAY_ENDPOINT) || []
        );
        setMoreEndpoints(
          data.data[0]?.endpoint.slice(MAXIMUM_DISPLAY_ENDPOINT) || []
        );
      })
      .catch((err) => {
        console.error('fetch endpoints error:', err);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (isWatched) {
      fetch();
    }
  }, [euid]);

  return euid ? (
    <div className='fortiview-multi-ueba-container'>
      <div className='ep-container'>
        {loading ? <PageLoading /> : null}
        {endpoints.length === 1 ? (
          <IncidentEndpoint
            epid='endpoints[0].epid'
            euid='endpoints[0].euid'
            hideFirstRow
          />
        ) : null}
        {endpoints.length > 1 ? (
          <div className='header'>{gettext('Related Endpoint:')}</div>
        ) : null}
        {isSupervisor && originalData.length > 1 ? (
          <div className='endpoint-list'>
            {originalData.map((ep) => (
              <div className='endpoint-link' key={ep.epname}>
                {ep.epname}
              </div>
            ))}
          </div>
        ) : null}
        {endpoints.length > 1 && !isSupervisor ? (
          <div className='endpoint-list'>
            {endpoints.map((ep) => (
              <div className='endpoint-link' key={ep.epname}>
                <Link
                  to={getDetailUrl(ep.epid)}
                  target='_blank'
                  rel='noreferrer'
                >
                  {ep.epname}
                </Link>
              </div>
            ))}
            {moreEndpoints.length ? (
              <div className='endpoint-link oversize'>
                <div className='dot'></div>
                <Link
                  to={getMoreDetailUrl(moreEndpoints)}
                  target='_blank'
                  rel='noreferrer'
                >
                  {moreEndpoints.length} {gettext(' More')}&hellip;
                </Link>
              </div>
            ) : null}
          </div>
        ) : null}
        {endpoints.length === 0 && !loading ? (
          <div className='error'>
            {gettext('No Related Endpoint Available.')}
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};
