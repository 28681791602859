import {
  createFetchAction,
  createPromiseAction,
  createRecordAction,
} from '../../utils';

export const unregDevicesAction = {
  ...createFetchAction('unreg-devices'),
  ...createRecordAction('unreg-devices'),
};

export const fetchUnregDevicesAction = createPromiseAction(
  'unreg-devices/FETCH_UNREG_DEVICES'
);
