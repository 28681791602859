import { Outlet, useLocation, useMatches } from 'react-router-dom';
import { fiFmgHttp } from 'fi-web/fi-http/fmg_json_api';
import {
  useEffect,
  useMemo,
  useRef,
  createElement,
  lazy,
  Suspense,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, get, noop } from 'lodash';
import cn from 'classnames';

import { NwSpinner, NwIcon } from '@fafm/neowise-core';
import { initApp, trySetCurrentState } from 'fistore/routing/slice';
import { getIsLoggedIn, getVmLicense } from 'fistore/auth/selectors';
import getAllAppTree from 'fi-web/fi-routing/all-apps';
import { registerDispatch } from 'fi-web/fi-websocket/socket/proxy';
import MainSidebar, { SIDE_BAR_RELOAD_KEY } from './MainSidebar';
import { SecurityStatusInfo } from '../security_status/SecurityStatusInfo';
import { NavBar } from '../navbar/NavBar';
import { getIsAppInited, getIsLoadingApp } from 'fistore/routing/selectors';
import fiCache from 'kit/kit-cache/fiCache';
import { ProToolkit, ProForm } from '@fafm/neowise-pro';
import { getSessionAdomOid } from 'fistore/session/adom/selectors';
import {
  getIsFmgMember,
  getSysConfig,
  isFmg,
} from 'fistore/session/sysConfig/selectors';
import { setActive, RESET_DEBOUNCE } from 'fistore/auth/slice';
import { load_workspace_adoms } from 'fistore/workspace_res/request';

import { useShowInitModals } from './useInitModals';
import { useAuthStates } from 'react_apps/ra_main/main_layout/useAuthStates';
import { ReloadLocation } from 'react_apps/ra_main/main_layout/ReloadLocation';
import { openUpdateLicenseModal } from 'react_apps/ra_main/modal/UpdateLicense';
import { useAuth } from 'react_apps/ra-auth/useAuth';
import { getCurrentTheme } from 'fistore/theme/selectors';
import AIDrawer from 'react_apps/ra_ai_shared/AIDrawer';
import { showAlertPanel } from 'react_apps/ra-auth/modals/alert';

const { Header } = ProForm;

function LoadingSpinner() {
  return (
    <div className='tw-flex tw-items-center tw-justify-center tw-grow'>
      <NwSpinner
        className='tw-text-6xl'
        style={{
          '--indicator-color': 'rgb(var(--nw-color-neutral-0))',
        }}
      />
    </div>
  );
}

const fortiAiIcon = (
  <svg style={{ display: 'none' }}>
    <symbol
      viewBox='0 0 16 16'
      id='nwi_fafm_fortiai-advisor'
      fill='currentColor'
    >
      <svg
        className='faz-advisor-logo'
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        viewBox='7.5 7.5 85 85'
        style={{
          shapeRendering: 'geometricPrecision',
          textRendering: 'geometricPrecision',
          imageRendering: 'optimizeQuality',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        }}
      >
        <g>
          <path d='M 8.5,12.5 C 16.6858,11.5096 19.5192,14.8429 17,22.5C 16,23.1667 15,23.8333 14,24.5C 13.3333,30.1667 13.3333,35.8333 14,41.5C 22.9254,29.9056 34.7587,25.239 49.5,27.5C 64.2413,25.239 76.0746,29.9056 85,41.5C 85.6667,35.8333 85.6667,30.1667 85,24.5C 80.0191,21.2311 79.5191,17.3978 83.5,13C 91.677,11.5072 94.5103,14.6738 92,22.5C 91,23.1667 90,23.8333 89,24.5C 88.3333,31.8333 88.3333,39.1667 89,46.5C 95.4085,50.7116 97.0751,56.3783 94,63.5C 92.2015,65.9725 89.8682,67.6392 87,68.5C 82.9181,77.5837 76.0848,83.417 66.5,86C 55.1667,86.6667 43.8333,86.6667 32.5,86C 22.9152,83.417 16.0819,77.5837 12,68.5C 3.18735,64.4474 1.18735,58.114 6,49.5C 7.15445,48.2567 8.48779,47.2567 10,46.5C 10.6667,39.1667 10.6667,31.8333 10,24.5C 4.74642,21.0237 4.24642,17.0237 8.5,12.5 Z M 35.5,43.5 C 44.8393,43.3335 54.1726,43.5002 63.5,44C 73.1884,48.9243 75.3551,56.0909 70,65.5C 64.1521,70.6684 57.6521,74.6684 50.5,77.5C 50.5,75.1667 50.5,72.8333 50.5,70.5C 45.4889,70.6659 40.4889,70.4993 35.5,70C 26.4814,66.2958 23.6481,59.7958 27,50.5C 29.2571,47.3117 32.0904,44.9783 35.5,43.5 Z' />
        </g>
        <g>
          <path d='M 35.5,52.5 C 43.4863,51.998 45.4863,55.1647 41.5,62C 33.8046,62.4434 31.8046,59.2767 35.5,52.5 Z' />
        </g>
        <g>
          <path d='M 56.5,52.5 C 61.1554,51.4159 64.1554,53.0825 65.5,57.5C 62.9452,63.2444 59.4452,63.9111 55,59.5C 54.3911,56.9348 54.8911,54.6015 56.5,52.5 Z' />
        </g>
      </svg>
    </symbol>
  </svg>
);

const MainLayoutOutlet = ReloadLocation(Outlet, SIDE_BAR_RELOAD_KEY);

function MainContent() {
  const isLoadingApp = useSelector(getIsLoadingApp);
  const isAppInited = useSelector(getIsAppInited);

  // check isAppInited before rendering Outlet so that each app's main view doesn't have to check for adom is loaded etc.
  if (isLoadingApp || !isAppInited) {
    return <LoadingSpinner></LoadingSpinner>;
  }
  return (
    <div className='main-content tw-h-full tw-w-full'>
      <MainLayoutOutlet />
    </div>
  );
}

function registerKeepAlive(dispatch) {
  const keepAlive = debounce(() => {
    dispatch(setActive());
  }, RESET_DEBOUNCE);

  const events = ['mousemove', 'keydown'];
  events.forEach((evt) => {
    document.addEventListener(evt, keepAlive);
  });

  return () => {
    events.forEach((evt) => {
      document.removeEventListener(evt, keepAlive);
    });
  };
}

export function MainLayout() {
  const dispatch = useDispatch();

  const matches = useMatches();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isAppInited = useSelector(getIsAppInited);
  const sysCfgLoaded = Boolean(useSelector(getSysConfig));
  const adomOid = useSelector(getSessionAdomOid);
  const [securityCode, setSecurityCode] = useState({
    code: 0,
  });
  const vmLicense = useSelector(getVmLicense);
  const hasInfoHeader = securityCode.code !== 0;
  const auth = useAuth();

  const currentTheme = useSelector(getCurrentTheme);
  const isDarkContrast = Boolean(
    currentTheme.dark && currentTheme.highcontrast
  );

  const isFmgMember = useSelector(getIsFmgMember);

  useEffect(() => {
    fiFmgHttp
      .post({
        url: '/gui/security-check',
        method: 'get',
      })
      .then((resp) => {
        let data = get(resp, '0.data', {});
        setSecurityCode(data);
      });
  }, []);

  // general setup
  useEffect(() => {
    // register mouse/keyboard events
    const unregKeepAlive = registerKeepAlive(dispatch);

    // init ficache
    fiCache.init();
    // init load workspace
    load_workspace_adoms(dispatch);
    // set up dev tool
    if (process.env.NODE_ENV === 'development') {
      window.fdev = function () {
        if (isLoggedIn) {
          openFDevTool();
        }
      };
    }
    return unregKeepAlive;
  }, []);

  useAuthStates();

  useEffect(() => {
    if (isLoggedIn) {
      const unregister = registerDispatch(dispatch);
      dispatch(initApp(getAllAppTree()));
      return () => {
        unregister();
      };
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isAppInited) {
      dispatch(trySetCurrentState({ matches }));
    }
  }, [isAppInited, adomOid, matches]);

  // show Startup Wizard > Change Password > Adom Select modals if needed
  const { initModalsDone } = useShowInitModals();

  const useDrawerHooks = useMemo(() => {
    return [useOpenerAutoUpdate];
  }, []);

  useEffect(() => {
    if (vmLicense && !vmLicense.valid) {
      openUpdateLicenseModal(vmLicense, false).then(noop, () => {
        auth.logout();
      });
    }
  }, [vmLicense?.valid]);

  const shouldShowFmgMemberAlert = isFmg() && isFmgMember && initModalsDone;
  useEffect(() => {
    if (shouldShowFmgMemberAlert) {
      showAlertPanel(
        gettext(
          'This FortiManager is operated as a Fabric Member unit. Most changes to the configuration database can only be made on the Fabric Supervisor unit, and then those changes are synchronized to the Fabric Member unit.'
        )
      );
    }
  }, [shouldShowFmgMemberAlert]);

  // render part

  if (!sysCfgLoaded || !vmLicense?.valid) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  if (!initModalsDone) {
    return <ProToolkit.DrawerRoot />;
  }

  return (
    <>
      <a
        href=''
        className='tw-bg-neutral-0 tw-text-neutral-1000 tw-sr-only focus:tw-not-sr-only focus:tw-fixed focus:tw-left-0 focus:tw-top-0 focus:tw-p-2 focus:tw-m-1'
        onClick={skipToMainContent}
      >
        {gettext('Skip to main content')}
      </a>
      {fortiAiIcon}
      <NavBar />
      <div
        // need `tw-min-h-0` class because the side bar size might be larger than the available size (https://www.w3.org/TR/css-flexbox/#min-size-auto)
        className='tw-flex tw-w-full tw-h-full tw-min-h-0 tw-grow'
      >
        <MainSidebar></MainSidebar>
        {/* need `tw-min-w-0` class because the outlet content (e.g. table) size might be larger than the available size (https://www.w3.org/TR/css-flexbox/#min-size-auto) */}
        <div
          className={cn(
            'tw-flex tw-flex-col tw-w-full tw-h-full tw-min-w-0 tw-grow',
            {
              ['tw-border tw-border-border tw-border-solid tw-border-l-0 tw-border-b-0 tw-border-r-0']:
                isDarkContrast && !hasInfoHeader,
            }
          )}
        >
          {hasInfoHeader ? (
            <>
              <div
                className='tw-relative tw-w-full tw-h-8'
                style={{
                  color: 'rgb(var(--nw-color-on-navbar))',
                  backgroundColor: 'rgb(var(--nw-color-navbar))',
                }}
              >
                <SecurityStatusInfo status={securityCode} />
              </div>
              <div
                className='tw-w-full'
                style={{
                  height: 'calc(100% - 2rem)',
                }}
              >
                <MainContent></MainContent>
              </div>
            </>
          ) : (
            <MainContent></MainContent>
          )}

          {/* DrawerRoot will update the parent container's position to `relative` */}
          <AIDrawer />

          <ProToolkit.DrawerRoot useHooks={useDrawerHooks} />
        </div>
      </div>
      <div
        id='root-full-screen-container'
        className='tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[var(--nw-z-index-dialog)] tw-pointer-events-none'
      />
    </>
  );
}

const useOpenerAutoUpdate = ({ $opener }) => {
  const nextSlice = [useLocation(), useSelector(getSessionAdomOid)];
  const prevSliceRef = useRef(nextSlice);

  useEffect(() => {
    if (prevSliceRef.current.every((v, i) => v === nextSlice[i])) return;
    prevSliceRef.current = nextSlice;

    if ($opener.minimizable) {
      $opener.minimize();
    } else {
      // close and unmount drawer on state change.
      try {
        $opener.reject();
      } catch (ex) {
        console.error(ex);
      }
    }
  }, nextSlice);
};

const openFDevTool = async () => {
  ProToolkit.openDrawer(
    createElement(() => {
      return (
        <>
          <Header>
            <NwIcon
              library='fa-solid'
              name='tools'
              className='tw-pr-1'
            ></NwIcon>
            fDev Tools
          </Header>
          <Suspense
            fallback={
              <div className='tw-grid tw-h-full'>
                <NwSpinner className='tw-text-5xl tw-place-self-center'></NwSpinner>
              </div>
            }
          >
            <FdevCompt></FdevCompt>
          </Suspense>
        </>
      );
    }),
    { minimizable: true }
  );
};

const FdevCompt = lazy(() => import('react_apps/ra_fdevtool/IndexPane'));

const skipToMainContent = (e) => {
  e.preventDefault();
  const mainContentElement = document.querySelector('.main-content');
  const elementToFocus =
    mainContentElement.querySelector('.page-content-wrapper') ||
    mainContentElement;
  if (elementToFocus) {
    const _tabIndex = elementToFocus.tabIndex;
    elementToFocus.tabIndex = 0;
    elementToFocus.focus();
    elementToFocus.tabIndex = _tabIndex;
  }
};
