import { isNumber } from 'lodash';
import { fiFmgHttp } from 'fi-web/fi-http';
import { perAdminFeature } from 'fi-customization';

export async function pnoDisplayOpts(
  adom: { oid: number } | number,
  opts?: never
): Promise<string[]>;
export async function pnoDisplayOpts(
  adom: { oid: number } | number,
  opts: string[]
): Promise<void>;
export async function pnoDisplayOpts(
  adom: { oid: number } | number,
  opts: unknown
) {
  const adomOid = typeof adom === 'object' ? adom.oid : adom;
  if (!isNumber(adomOid)) return [];
  const url = `/gui/adoms/${adomOid}/customize`;
  const { update, query } = await perAdminFeature(
    `pnoCustomize_ADOM#${adomOid}`
  );

  if (opts) {
    // Setter
    await update(opts as string[], () =>
      fiFmgHttp.post({
        url: url,
        method: 'update',
        params: { pnoCustomize: opts },
      })
    );
    return;
  }

  // Getter
  return query(() =>
    fiFmgHttp
      .post<{ pnoCustomize?: string[] }>({
        url: url,
        method: 'get',
      })
      .then((resp) => resp[0]?.data?.pnoCustomize)
  ).then((resp) => resp || []) as unknown;
}
