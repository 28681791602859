import { getWsProxy } from 'fi-websocket';

let flatui_proxy_wrap = (cmd) => {
  return {
    url: '/cgi-bin/module/flatui_proxy',
    method: 'get',
    params: {
      ...cmd,
      dataChunked: {},
    },
  };
};

export const fetch_sdwan_computed_data = (adom, singleDev) => {
  const request = {
    url: `/gui/adom/${adom.name}/group/All_FortiGate/sdwan`,
    method: 'get',
    params: {},
  };
  if (singleDev) {
    const deviceName = Array.isArray(singleDev) ? singleDev[0] : singleDev;
    request.url = `/gui/adom/${adom.name}/device/${deviceName}/sdwan`;
  }

  const r = flatui_proxy_wrap(request);

  let proxy = getWsProxy();
  return proxy.createDispatchRequest(r);
};
