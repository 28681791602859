import Mousetrap from 'mousetrap';
import keyMap from './keymap.yaml';

const findKey = (name, context) => keyMap.contexts[context][name].key;

const contextStash = (() => {
  const stash = [];
  return {
    push: (obj) => stash.push(obj),
    pop: () => stash.pop(),
    forEach: stash.forEach.bind(stash),
    currentContext: () => stash.slice(-1)?.[0] || 'default',
  };
})();

const registerBindings = ([context, bindings]) =>
  Object.entries(bindings).forEach(([name, fn]) => {
    Mousetrap.bind(findKey(name, context), fn);
  });

/** Enter a context with a bunch of bindings.
 * bindings: an pair of [name, fn].
 */
export function enter(context, bindings, reset = false) {
  if (reset) Mousetrap.reset();
  const info = [context, bindings];
  contextStash.push(info);
  registerBindings(info);
}

export function exit() {
  contextStash.pop();
  Mousetrap.reset();
  contextStash.forEach(registerBindings);
}

export function getShortcuts(context = 'default') {
  return keyMap.contexts[context];
}

export function getShortcutsByCategory(category, context = 'default') {
  const scMap = keyMap.contexts[context];
  return Object.values(scMap).filter((sc) => sc.category === category);
}

export function getCategories() {
  return keyMap.categories;
}

export function triggerCommand(name, context) {
  if (!context) context = contextStash.currentContext();
  const key = findKey(name, context);
  Mousetrap.trigger(key);
}

export function triggerKey(key) {
  Mousetrap.trigger(key);
}
