import { createContext, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { logout as logoutAction } from 'fistore/auth/slice';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const dispatch = useDispatch();

  // call this function to sign out logged in user
  const value = useMemo(
    () => ({
      async logout() {
        await dispatch(logoutAction());
      },
    }),
    [dispatch]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
