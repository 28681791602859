export const KEYS = {
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  ENTER: 'Enter',
  SHIFT: 'Shift',
  SPACE: ' ',
};

export class Items {
  constructor(items, currItem, getKey = (o) => o, supportLoop = false) {
    this.items = items;
    this.currItem = currItem;
    //this.equal=equal
    this.getKey = getKey;
    this.supportLoop = supportLoop;
  }
  getCurrentItemPos() {
    if (!this.currItem) return -1;
    for (let i = 0; i < this.items.length; i++) {
      if (this.getKey(this.items[i]) === this.currItem) return i;
    }
    return -1;
  }
  getNextItem(delta) {
    if (!this.currItem) {
      this.currItem = this.getKey(this.items[0]);
    }

    const pos = this.getCurrentItemPos();
    let nextPos = pos + delta;

    if (this.supportLoop) {
      if (nextPos < 0) nextPos = this.items.length - 1;
      else if (nextPos >= this.items.length) nextPos = 0;
    }

    if (nextPos < 0 || nextPos >= this.items.length) return null;

    return this.items[nextPos];
  }
}

export class MenuStatus {
  constructor() {
    this.hasMenuShown = false;
    this.listener = null;
  }
  hasShown() {
    return this.hasMenuShown;
  }
  show(node) {
    this.hasMenuShown = true;
    if (this.listener) this.listener(this.hasMenuShown, node);
  }
  hide(node) {
    this.hasMenuShown = false;
    if (this.listener) this.listener(this.hasMenuShown, node);
  }
  setListener(listener) {
    this.listener = listener;
  }
}
export const processKeyEnter = (fn) => (e) => {
  const { ENTER } = KEYS;
  if (e.key == ENTER) {
    e.preventDefault();
    e.stopPropagation();

    fn(e);
  }
};
export const tree_processKeyDown =
  ({
    menuStatus,
    order,
    records,
    currentKey,
    processSelectByKeyboard,
    processToggleByKeyboard,
    processEnterByKeyboard,
  }) =>
  (e) => {
    const { LEFT, RIGHT, UP, DOWN, ENTER, SHIFT, SPACE } = KEYS;
    let hasMenuShown = false;
    if (menuStatus) {
      hasMenuShown = menuStatus.hasShown();
    }
    if (hasMenuShown) {
      if (e.key == UP || e.key == DOWN || e.key == ENTER) {
        e.preventDefault();
        e.stopPropagation();
      }

      return;
    }

    const items = new Items(order, currentKey);
    if (e.key == UP || e.key == DOWN) {
      e.preventDefault();
      e.stopPropagation();

      const isUp = e.key == UP;
      const nextItem = items.getNextItem(isUp ? -1 : 1);
      if (!nextItem) return;

      processSelectByKeyboard(nextItem);
    } else if (e.key == RIGHT || e.key == LEFT) {
      e.preventDefault();
      e.stopPropagation();

      if (!currentKey) return;
      const record = records[currentKey];
      if (!record) return;

      const condition = e.key == LEFT ? record.isOpen : !record.isOpen;

      if (condition) {
        processToggleByKeyboard(record);
      }
    } else if (e.key == SHIFT) {
      e.preventDefault();
      e.stopPropagation();

      if (!currentKey) return;
      const record = records[currentKey];
      if (!record) return;

      const pos = items.getCurrentItemPos();
      if (pos < 0) return;

      const extraY = pos * 30;
      if (record.node.menuRef) {
        const elem = record.node.menuRef.elem;
        let rect = elem.getBoundingClientRect();
        e.clientX = rect.x + rect.width;
        e.clientY = rect.y + extraY;
        if (record.node.menuRef) {
          record.node.menuRef.handleContextClick(e);
        }
      }
    } else if (e.key == ENTER || e.key == SPACE) {
      e.preventDefault();
      e.stopPropagation();

      if (!currentKey) return;

      const record = records[currentKey];
      if (record) {
        processEnterByKeyboard(record);
      }
    }
  };
