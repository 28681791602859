const {
  SOC_FABRIC_CFG_ENABLE,
  SOC_FABRIC_CFG_ROLE_SUPERVISOR,
  SOC_FABRIC_CFG_ROLE_MEMBER,
} = MACROS.FAZ_CFG_PARAM;

export const hasFazFeature = (sysCfg) =>
  Boolean(MACROS.SYS.PRODUCT_FAZ & sysCfg.feature_flag);
export const hasFmgFeature = (sysCfg) =>
  Boolean(MACROS.SYS.PRODUCT_FMG & sysCfg.feature_flag);

export const isFaz = () => MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FAZ;
export const isFmg = () => MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FMG;
export const isFazOnly = (sysCfg) => isFaz() && !hasFmgFeature(sysCfg);
export const isFmgOnly = (sysCfg) => isFmg() && !hasFazFeature(sysCfg);

export const isHaSlave = (sysCfg) =>
  sysCfg.ha_mode === MACROS.SYS.HA_MODE_SLAVE;

export const isAdomEnabled = (sysCfg) => Boolean(sysCfg.adom_status);
export const isAdomAdvancedMode = (sysCfg) =>
  sysCfg.adom_mode === MACROS.SYS.ADOM_M_ADVANCED;
export const isAdomNormalMode = (sysCfg) =>
  sysCfg.adom_mode === MACROS.SYS.ADOM_M_NORMAL;
export const isFazCollectorMode = (sysCfg) =>
  sysCfg.faz_mode === MACROS.SYS.FAZCORE_SYS_MODE_COLLECTOR;

export const isFazEnabled = (sysCfg) => Boolean(sysCfg.faz_status);
export const hasAttachedFaz = (sysCfg) => Boolean(sysCfg.faz_attached);
export const socFabricEnabled = (sysCfg) =>
  sysCfg.soc_fabric_status === SOC_FABRIC_CFG_ENABLE;
export const isFtntCloud = (sysCfg) => Boolean(sysCfg.have_ftnt_cloud);
export const isVMSLicense = (sysCfg) => Boolean(sysCfg.is_vms_lic);
export const adomAccess = (sysCfg) => sysCfg.adom_access;
export const notAdomUser = (sysCfg) =>
  sysCfg.adom_access === MACROS.ADMIN.ADOM_ACCESS_ALL;
export const isAdomUser = (sysCfg) =>
  sysCfg.adom_access !== MACROS.ADMIN.ADOM_ACCESS_ALL;

export const getAccessibleAdomCount = (sysCfg) => {
  // the value of accessible_adom_counts, is not a exact number,
  // to improve the performance, server only count upto 3 puls global adom accessibility.
  return sysCfg.accessible_adom_counts;
};

export const isFazSupervisor = (sysCfg) => {
  return (
    sysCfg.soc_fabric_status === SOC_FABRIC_CFG_ENABLE &&
    sysCfg.soc_fabric_role === SOC_FABRIC_CFG_ROLE_SUPERVISOR
  );
};
export const isFazMember = (sysCfg) => {
  return (
    sysCfg.soc_fabric_status === SOC_FABRIC_CFG_ENABLE &&
    sysCfg.soc_fabric_role === SOC_FABRIC_CFG_ROLE_MEMBER
  );
};

export const isFmgSupervisor = (sysCfg) => {
  return sysCfg.fmg_fabric_role === MACROS.SYS.FMG_CLUSTER_PRIMARY;
};
export const isFmgMember = (sysCfg) => {
  return sysCfg.fmg_fabric_role === MACROS.SYS.FMG_CLUSTER_WORKER;
};

export const fazSqlDbEnabled = (sysCfg) => {
  return hasFazFeature(sysCfg) && sysCfg.sql_status;
};

export const hasFazAttached = (sysCfg) => Boolean(sysCfg.faz_attached);
export const isWorkspaceEnabled = (sysCfg) =>
  sysCfg.workspace_mode !== 'disabled';

export const isRemoteGUIAccessEnabled = (sysCfg) =>
  sysCfg.fgt_gui_proxy !== 0 && sysCfg.fgt_gui_proxy_port !== 0;
export const latest_supported_adom_version = (sysCfg) => {
  const vers = sysCfg.supported_adom_vers;
  return vers[vers.length - 1];
};
export const latest_supported_adom_version_by_os_type =
  (sysCfg) => (osType) => {
    const versions = sysCfg.supported_adom_vers_by_os_type[osType];
    if (!versions) return;
    return versions[versions.length - 1];
  };
export const is_adom_version_supported = (sysCfg) => (ver, mr) => {
  return sysCfg.supported_adom_vers.find((it) => {
    return it.ver == ver && it.mr == mr;
  });
};

// adom
export const isFazAdomManagedByFmg = (adom) => {
  return !!(adom.flags & MACROS.ADOM.DVM_ADOM_FLAG_IS_AUTOSYNC);
};

// profile
export const noPermitOn = (attr) => (profile) => {
  if (!attr || !profile) return true;
  return (
    !profile.hasOwnProperty(attr) ||
    profile[attr] == MACROS.SYS.ADMINPRIV_PERM_NONE
  );
};

export const hasRDPermitOn = (attr) => (profile) => {
  return (
    profile[attr] === MACROS.SYS.ADMINPRIV_PERM_READ ||
    profile[attr] == MACROS.SYS.ADMINPRIV_PERM_RDWR
  );
};
