import React, { useCallback, useMemo } from 'react';
import { ProToolkit, ProForm } from '@fafm/neowise-pro';
import { Formik } from 'formik';
import { FmkInput, FmkErrorSpan, fmkFooterButtons } from 'rc_form';
import { fiStore, fiStoreSoc } from 'fistore';

const { Header, Body, Footer, Row, Column, Section } = ProForm;
const { ErrorBoundary } = ProToolkit;

const autoId = (suffix) => 'fortiview-rename-custom-view-' + suffix;

export const RenameDashForm = ({ $opener, dashId, name }) => {
  const onSubmit = useCallback(
    async (values, form) => {
      form.setSubmitting(true);
      try {
        const resp = await fiStore
          .dispatch(
            fiStoreSoc.customView.actions.renameCustomView({
              dashId,
              name: values['name'],
            })
          )
          .unwrap();
        $opener.resolve(resp);
      } catch (error) {
        form.setErrors({ name: error });
      } finally {
        form.setSubmitting(false);
      }
    },
    [dashId]
  );

  const initialValues = useMemo(() => {
    return {
      name,
    };
  }, [name]);

  return (
    <ErrorBoundary>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <>
            <Header
              onCloseClick={() => {
                $opener.resolve();
              }}
            >
              {gettext('Rename Custom View')}
            </Header>
            <Body>
              <Section>
                <Row label={gettext('Name')} labelRequired>
                  <Column>
                    <FmkInput
                      name='name'
                      automation-id={autoId('name')}
                      required
                    />
                    <FmkErrorSpan name='name' />
                  </Column>
                </Row>
              </Section>
            </Body>
            <Footer>
              {fmkFooterButtons({
                canWrite: true,
                getAutoId: autoId,
                onCancel: () => {
                  $opener.resolve();
                },
              })}
            </Footer>
          </>
        )}
      </Formik>
    </ErrorBoundary>
  );
};
