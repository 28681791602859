import { ProForm, ProLego } from '@fafm/neowise-pro';
import { useEffect, useMemo, useState } from 'react';
import { CancelBtn, OkBtn } from 'rc_layout';

import { NwIcon, NwInput } from '@fafm/neowise-core';

import { NwTextarea, NwButton, NwSwitch } from '@fafm/neowise/react';

import { fiAdom } from 'fi-session';
import { genv4 } from 'fi-uuid';
import { fiFmgHttp } from 'fi-http';

import { FolderSelection } from './FolderSelection';
import { getFolderWithRoot } from '../util';
import { fiMessageBox } from 'fi-messagebox';
import { LRSelectReact } from 'rc_select';
import { searchHiliter } from 'rc_toolbar';
import { fiDeviceDataLoader } from 'ra_device_util';
import { get } from 'lodash';
import { DvmHFormatter } from '../../formatting/dvmHFormatter';
const { Section, Row, Column, Header, Footer, Body } = ProForm;

export const ACTION_CREATE = 'CREATE';
export const ACTION_EDIT = 'EDIT';
export const ACTION_MOVE = 'MOVE';

function getFolderAtPath(requestObject, pathId) {
  let temp = requestObject;
  for (let id of pathId) {
    const filtered = temp.subobj.filter((obj) => obj.oid === id);
    if (filtered.length > 0) {
      temp = filtered[0];
    } else {
      throw Error(gettext('Cannot find the path'));
    }
  }
  return temp;
}

const addFolder = ({
  requestObject,
  currentFolder,
  name,
  description,
  deviceInFolder,
}) => {
  const pathId = currentFolder.path.slice(1) || [];
  let temp = getFolderAtPath(requestObject, pathId);
  if (!temp.subobj) {
    temp.subobj = [];
  }
  if (temp.subobj.filter((fol) => fol.name === name).length > 0) {
    throw Error(gettext('Name already exists in folder'));
  }
  temp.subobj.push({
    desc: description,
    name: name,
    'object member': deviceInFolder.map((dev) => ({
      name: dev.name,
      vdom: 'global',
    })),
  });
  return requestObject;
};

const editFolder = ({
  requestObject,
  currentFolder,
  name,
  description,
  deviceInFolder,
}) => {
  const pathId = currentFolder.path.slice(1) || [];
  let temp = getFolderAtPath(requestObject, pathId);
  temp.name = name;
  temp.desc = description;
  temp['object member'] = deviceInFolder.map((dev) => ({
    name: dev.name,
    vdom: 'global',
  }));
  return requestObject;
};

const moveFolder = ({ requestObject, currentFolder, name, targetFolder }) => {
  const pathId = currentFolder.path.slice(1) || [];
  let temp = getFolderAtPath(requestObject, pathId);
  const parentPathId =
    targetFolder.path.slice(1, targetFolder.path.length - 1) || [];
  let target_parent = getFolderAtPath(requestObject, parentPathId);
  const targetPathId = targetFolder.path.slice(1) || [];
  let target = getFolderAtPath(requestObject, targetPathId);

  let moveInSubFolder = true;

  if (pathId < targetPathId) {
    moveInSubFolder = false;
  } else {
    for (let i = 0; i < targetPathId.length; i++) {
      let srcPath = targetPathId[i];
      let dest = pathId[i];
      if (srcPath !== dest) {
        moveInSubFolder = false;
      }
    }
  }
  if (moveInSubFolder) {
    throw Error(gettext('Can not move a folder to its subfolder.'));
  }

  if (
    !target_parent.subobj ||
    target_parent.subobj.filter((fol) => fol.oid === targetFolder.oid)
      .length === 0
  ) {
    throw Error(gettext('Did not find target Folder'));
  }
  if (!temp.subobj) {
    temp.subobj = [];
  }
  if (temp.subobj.filter((fol) => fol.name === name).length > 0) {
    throw Error(gettext('Name already exists in folder'));
  }
  temp.subobj.push(target);

  target_parent.subobj = target_parent.subobj.filter(
    (fol) => fol.oid !== targetFolder.oid
  );
  return requestObject;
};

const handleActions = (action, params) => {
  if (action.action === ACTION_CREATE) {
    return addFolder(params);
  }
  if (action.action === ACTION_EDIT) {
    return editFolder(params);
  }
  if (action.action === ACTION_MOVE) {
    return moveFolder(params);
  }
};

export const EditFolderModal = (props) => {
  const { selectedOid, requestObject, actionContext } = props;

  const [result, setResult] = useState({});
  const { folderWithRoot, devToFolderMap, oidToNode } = result;
  const [folderLoaded, setFolderLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    const result = getFolderWithRoot(requestObject);
    setResult(result);
    setFolderLoaded(true);
  }, []);

  const [selectedFolder, setSelectedFolder] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [targetFolder, setTargetFolder] = useState({});
  const [initialDeviceInFolder, setInitialDeviceInFolder] = useState([]);
  const [deviceInFolder, setDeviceInFolder] = useState([]);
  const [unassignedOnly, setUnassignedOnly] = useState(true);

  const [openFolderSelection, setOpenFolderSelection] = useState(false);

  useEffect(() => {
    if (Object.values(result).length !== 0) {
      setSelectedFolder(oidToNode[selectedOid] || folderWithRoot[0][0]);
      setTargetFolder(oidToNode[selectedOid] || folderWithRoot[0][0]);
    }
  }, [result]);

  useEffect(() => {
    if (Object.values(targetFolder).length !== 0) {
      setName(actionContext.action === ACTION_CREATE ? '' : targetFolder.name);
      setDescription(
        actionContext.action === ACTION_CREATE ? '' : targetFolder.desc
      );
      setDeviceInFolder(
        actionContext.action === ACTION_EDIT
          ? targetFolder.devMemberList.map((dev) => ({ id: dev.oid, ...dev }))
          : []
      );
      setInitialDeviceInFolder(
        actionContext.action === ACTION_EDIT
          ? targetFolder.devMemberList.map((dev) => ({ id: dev.oid, ...dev }))
          : []
      );
      setLoaded(true);
    }
  }, [targetFolder, actionContext]);

  const formatFunction = (dev, term) => {
    function fmtDetail(dev) {
      return gettext('IP: %s, Platform: %s').printf(
        [dev.ip, dev.platform].map((txt) => {
          return txt || 'N/A';
        })
      );
    }

    let hiliter = searchHiliter(term, false);
    let name = DvmHFormatter.fmtDevName(dev, hiliter);
    let path = devToFolderMap[dev.oid]?.fpath;
    if (!path || path === '/') {
      path = gettext('Unassigned Devices');
    }
    let content = (
      <div>
        {name} (
        <span>
          <NwIcon className='tw-px-1' name='folder'></NwIcon>
        </span>
        {gettext('In Folder: %s').printf([path])})
        <div className='detail'>{hiliter(fmtDetail(dev))}</div>
      </div>
    );
    return <div className='dev'>{content}</div>;
  };

  const onSubmit = async () => {
    const adom = fiAdom.current();
    if (name.length === 0) {
      return;
    }
    try {
      setIsSubmitting(true);
      const folder = handleActions(actionContext, {
        requestObject,
        currentFolder: selectedFolder,
        name,
        description,
        deviceInFolder,
        targetFolder,
      });
      if (!folder) {
        return;
      }
      await fiFmgHttp.query({
        id: genv4(),
        method: 'set',
        params: [
          {
            url: `/dvmdb/adom/${adom.name}/folder`,
            data: folder,
          },
        ],
      });
      props.$opener.resolve();
    } catch (e) {
      fiMessageBox.show(e.message, 'danger');
    } finally {
      setIsSubmitting(false);
    }
  };

  const devicesIncludesInLRSelect = useMemo(() => {
    if (!unassignedOnly) {
      return fiDeviceDataLoader.getManagedDevices();
    }
    return fiDeviceDataLoader.getManagedDevices().then((devs) => {
      const oids = initialDeviceInFolder.map((d) => `${d.oid}`);
      return devs.filter((dev) => {
        const path = get(devToFolderMap, [dev.oid, 'fpath']);
        return !path || path === '/' || oids.includes(dev.oid);
      });
    });
  }, [initialDeviceInFolder, devToFolderMap, unassignedOnly]);

  return (
    <>
      <Header>
        {actionContext.action === ACTION_CREATE
          ? gettext('Create New Folder')
          : gettext('Edit Folder')}
      </Header>
      <Body>
        <Section>
          <Row label={gettext('Name')}>
            <Column>
              <NwInput
                onChange={({ target }) => {
                  setName(target.value);
                }}
                value={name}
                disabled={actionContext.action === ACTION_MOVE}
              />
            </Column>
          </Row>
          <Row label={gettext('Description')}>
            <Column>
              <NwTextarea
                value={description}
                onNwChange={({ target }) => {
                  setDescription(target.value);
                }}
                disabled={actionContext.action === ACTION_MOVE}
              />
            </Column>
          </Row>
          {actionContext.action !== ACTION_EDIT && (
            <Row label={gettext('In Folder')}>
              <Column>
                <NwInput
                  readonly
                  value={
                    '/' + (selectedFolder.pathName?.slice(1) || []).join('/')
                  }
                  disabled
                />
                <ProLego.Popover
                  open={openFolderSelection}
                  style={{ '--max-width': 700 }}
                  placement={'bottom'}
                  content={
                    <div
                      slot={'body'}
                      className='scrollable-tooltip'
                      style={{
                        width: 520,
                        height: 350,
                        overflowY: 'scroll',
                      }}
                    >
                      {result.folderWithRoot && (
                        <FolderSelection
                          fetchData={() => folderWithRoot[0]}
                          selectedFolder={selectedFolder}
                          setPath={setSelectedFolder}
                        />
                      )}
                      <Footer>
                        <div className={'tw-flex-1'}></div>
                        <CancelBtn
                          onClick={() => setOpenFolderSelection(false)}
                        >
                          {gettext('Close')}
                        </CancelBtn>
                      </Footer>
                    </div>
                  }
                >
                  <NwButton
                    onClick={() => setOpenFolderSelection((prev) => !prev)}
                  >
                    {gettext('Select a Folder')}
                  </NwButton>
                </ProLego.Popover>
              </Column>
            </Row>
          )}
          {actionContext.action !== ACTION_MOVE && (
            <Row label={gettext('Assigned Devices')}>
              {/*<AssignDevices deviceInFolder={deviceInFolder} setDeviceInFolder={setDeviceInFolder}*/}
              {/*  devToFolderMap={devToFolderMap} />*/}
              <Column>
                {(actionContext.action !== ACTION_EDIT || loaded) &&
                  folderLoaded && (
                    <NwSwitch
                      checked={unassignedOnly}
                      onNwChange={() => setUnassignedOnly(!unassignedOnly)}
                    >
                      {' '}
                      {gettext('Unassigned Device only')}
                    </NwSwitch>
                  )}
                {(actionContext.action !== ACTION_EDIT || loaded) &&
                  folderLoaded && (
                    <LRSelectReact
                      source={devicesIncludesInLRSelect}
                      idAttr={'oid'}
                      textAttr={'name'}
                      itemHeight={60}
                      height={'400px'}
                      itemRenderer={formatFunction}
                      selected={deviceInFolder.map((dev) => `${dev.oid}`)}
                      onChange={(items) => {
                        setDeviceInFolder(items);
                      }}
                    />
                  )}
              </Column>
            </Row>
          )}
        </Section>
      </Body>
      <Footer>
        <OkBtn onClick={onSubmit} loading={isSubmitting}>
          {gettext('OK')}
        </OkBtn>
        <CancelBtn
          onClick={() => {
            props.$opener.reject();
          }}
        >
          {gettext('Close')}
        </CancelBtn>
      </Footer>
    </>
  );
};
