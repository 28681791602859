import React from 'react';
import { ProContextMenu } from '@fafm/neowise-pro';
import { flowRight, isFunction } from 'lodash';
import { NwIcon } from '@fafm/neowise-core';

const {
  ContextMenu,
  MenuItem,
  MenuDivider,
  ContextMenuTrigger,
  useMenuTriggerData,
} = ProContextMenu;

const make_getContextMenuOpts =
  (getMenuOps = () => []) =>
  (/*onMenuClick*/) =>
  (node) => {
    return getMenuOps(node);
  };

const make_dynamicMenu = (id, menuStatus) => (menuOps) => (props) => {
  const { trigger } = props;
  const node = trigger?.node;
  const isNodeDisabled =
    node && isFunction(node.getIsDisabled) ? node.getIsDisabled() : false;

  const makeMenuItem = (itemCmd, i) => {
    if (!itemCmd.label) {
      return <MenuDivider key={'menu_div' + i} />;
    }

    return (
      <MenuItem
        disabled={itemCmd.disabled}
        key={'menu:' + itemCmd.key}
        onClick={() => {
          itemCmd.exec(trigger.node);
        }}
        label={itemCmd.label}
        content={() => (
          <>
            <NwIcon
              name={itemCmd.icon || ''}
              library={itemCmd.iconLibrary}
              className={itemCmd.css}
              key={'menu_icon:' + itemCmd.key}
            ></NwIcon>
            {itemCmd.label}
          </>
        )}
      ></MenuItem>
    );
  };

  const makeMenuItems = (items, i) => {
    if (!Array.isArray(items)) {
      return makeMenuItem(items, i);
    }

    return items.map(makeMenuItems);
  };

  const menuOptions = trigger && !isNodeDisabled ? menuOps(trigger.node) : [];

  return (
    <ContextMenu
      id={id}
      onHide={() => {
        if (menuStatus) menuStatus.hide(trigger ? trigger.node : null);
      }}
      onShow={() => {
        if (menuStatus) menuStatus.show(trigger ? trigger.node : null);
      }}
    >
      {trigger && menuOptions?.map(makeMenuItems)}
    </ContextMenu>
  );
};

const tree_menu_builder = (id, getContextMenuOpts) => {
  const make_nodeMenu = (children) => (nodeProps) => {
    const node = nodeProps.record ? nodeProps.record.node : nodeProps.node;
    return (
      <ContextMenuTrigger
        id={id}
        // ref={(elem) => (node.menuRef = elem)}
        data={{ node }}
      >
        {children(nodeProps)}
      </ContextMenuTrigger>
    );
  };

  const make_connectedMenu = (onMenuClick, menuStatus) =>
    flowRight(
      connectMenu(id),
      make_dynamicMenu(id, menuStatus),
      getContextMenuOpts
    )(onMenuClick);

  return {
    make_nodeMenu,
    make_connectedMenu,
  };
};

const connectMenu = (id) => (Component) => (props) => {
  const { trigger } = useMenuTriggerData({ id });
  return (
    <Component
      {...props}
      trigger={
        trigger
          ? {
              ...trigger.data,
              target: trigger.target,
            }
          : null
      }
    />
  );
};

export const create_tree_menu = (id) => (getMenuOps) =>
  tree_menu_builder(id, make_getContextMenuOpts(getMenuOps));
