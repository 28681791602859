import { fiFmgHttp } from 'fi-http';
import { deviceStatus } from 'fi-dvm';

export const getDvmMonitorService = () => {
  const _monitor = (adom, device) => async (params, method) => {
    // model offline device no need to send json proxy request
    if (!MACROS.SYS.CONFIG_DEBUG && !deviceStatus.isDeviceOnline(device)) {
      return Promise.resolve([]);
    }

    const target = ['adom/%s/device/%s'.printf([adom.name, device.name])];
    let getProm = () =>
      fiFmgHttp.proxy(
        target,
        method || 'get',
        {
          'api type': 'monitor',
          ...params,
        },
        'v2',
        { timeout: 20 }
      );
    return getProm().then(
      (resp) => {
        return resp?.[0]?.data?.[0]?.response?.results || [];
      },
      () => {
        return [];
      }
    );
  };
  return {
    monitor: _monitor,
  };
};
