import React from 'react';

import { NwProBody, NwProFooter } from 'rc_layout';
import { NwButton } from '@fafm/neowise-core';
import { getTabAutoId } from '../tab_utils';

export const EndTab = ({ tab, $opener }) => {
  const getAutoId = getTabAutoId('end');

  return (
    <>
      <NwProBody>
        <div className='tab-title'>{tab.getTitle()}</div>
        <p>{tab.getDescription()}</p>
      </NwProBody>
      <NwProFooter>
        <NwButton
          onClick={() => {
            $opener.resolve();
          }}
          automation-id={getAutoId('finish-btn')}
          className={'tw-min-w-32'}
          type={'primary'}
        >
          {gettext('Finish')}
        </NwButton>
      </NwProFooter>
    </>
  );
};
