import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { pnoDisplayOpts } from './api/display_opts';
import { listenerMiddleware } from '../middlewares';
import { fetchSessionAdom } from '../session/adom/slice';
import { getSessionAdomData } from '../session/adom/selectors';
import {
  appEnabled,
  getCurrentMatches,
  getCurrentState,
} from '../routing/selectors';
import { refreshAppTree, go, trySetCurrentState } from '../routing/slice';
import { getAdminUserName, getSysConfig } from '../session/sysConfig/selectors';
import {
  withEscapedAdminName,
  makeUpdateAdminCustomize,
  getAdminCustomize,
} from 'fi-customization';
import { getPnoDisplayOpts } from './selectors';
import { getFullPPkgData } from './pkg';
import { overSome, get, isNil, last } from 'lodash';
import { routeUtils } from './utils';
import { getAppUniKey } from 'fistore/routing/utils';
import { setLocalStorage } from 'fiutil/storage';

const initialState = {
  pnoDisplayOpts: [],
  dualPaneMode: 'dockToRight',
  jumpToPolicy: null,
};

const slice = createSlice({
  name: 'pno',
  initialState,
  reducers: {
    setCurrentPPkg: (state, { payload }) => {
      state.currentPPkg = payload;
    },
    setCurrentPType: (state, { payload }) => {
      state.currentPType = payload;
    },
    setPnoDisplayOpts: (state, { payload }) => {
      state.pnoDisplayOpts = payload;
    },
    setDualPaneMode: (state, { payload }) => {
      state.dualPaneMode = payload; // Either `dualPaneMode` or `dockToRight`
    },
    jumpingToPolicy: (state, { payload }) => {
      state.jumpToPolicy = payload;
    },
    jumpedToPolicy: (state) => {
      state.jumpToPolicy = null;
    },
    setNoShowPBlockMsg: (state, { payload }) => {
      state.noShowPBlockMsg = payload;
    },
  },
});

export const {
  setPnoDisplayOpts,
  setDualPaneMode,
  setCurrentPPkg,
  setCurrentPType,
  setNoShowPBlockMsg,
  jumpedToPolicy,
  jumpingToPolicy,
} = slice.actions;

export default slice.reducer;

export const fetchPnoDisplayOpts = createAsyncThunk(
  'pno/FETCH_PNO_DISPLAY_OPTS',
  async (_, { getState, fulfillWithValue, dispatch }) => {
    const cached = getPnoDisplayOpts(getState());
    if (cached) {
      return fulfillWithValue(cached);
    }
    return await dispatch(_fetchPnoDisplayOpts());
  }
);

const _fetchPnoDisplayOpts = createAsyncThunk(
  'pno/_FETCH_PNO_DISPLAY_OPTS',
  async (_, { getState, dispatch }) => {
    const dispopts = await pnoDisplayOpts(getSessionAdomData(getState()));
    dispatch(setPnoDisplayOpts(dispopts));
  }
);

const fetchPnoDualPaneMode = createAsyncThunk(
  'pno/FETCH_PNO_DUAL_PANE_MODE',
  async (_, { getState, dispatch }) => {
    const adminName = getAdminUserName(getState());
    const resp = await withEscapedAdminName(getAdminCustomize)(adminName);
    let mode = get(resp, '[0].data.customize.showPnoInDualPane');
    if (isNil(mode)) {
      const sysConfig = getSysConfig(getState());
      mode = get(sysConfig, 'show_dualpane');
    }
    dispatch(setDualPaneMode(mode ? 'dualPaneMode' : 'dockToRight'));
  }
);

export const queryPPkgs = createAsyncThunk(
  'pno/QUERY_POLICY_PACKAGES',
  async (_, { getState }) => {
    const adom = getSessionAdomData(getState());
    const fullPPkgData = await getFullPPkgData(adom);
    return fullPPkgData;
  }
);

/**
 * `dispatch(pnoGo(jumpParams))` to navigate PNO apps.
 * @type {import('@reduxjs/toolkit').AsyncThunk<unknown, import('./utils').JumpParams, unknown>}
 */
export const pnoGo = createAsyncThunk(
  'pno/PNO_GO',
  async (payload, { getState, dispatch }) => {
    const st = getState();
    const adomOid = getSessionAdomData(st).oid;

    const { makeJumpState } = routeUtils(st);
    const jump = makeJumpState(payload);

    /**
     * Save to local storage instead of router state,
     * because the router state might be flushed if some components navigate again before the pkg data is ready.
     */
    if (jump.state.pkgid) {
      setLocalStorage(`${adomOid}-pno_policy_tree:tree`, jump.state);
    }

    // navigate to the correct page.
    await dispatch(
      go({ to: jump.url, opts: { state: { pnoGoState: jump.state } } })
    );

    // jump to policy entry.
    if (jump.state.pid) {
      // timeout in case we are already in the same route
      const arbitraryTimeoutForRouteUpdate = 150;
      return new Promise((resolve) => {
        setTimeout(() => {
          dispatch(slice.actions.jumpingToPolicy(jump.state));
          resolve();
        }, arbitraryTimeoutForRouteUpdate);
      });
    }
  }
);

/**
 * The route state is revalidated in MainLayout.jsx when either the ADOM or URL changes.
 * An ADOM change also triggers our display checker asynchronous calls,
 * so it is better to revalidate the state again after these asynchronous calls finish.
 */
const revalidateSelectedPnoApp = async (dispatch, state) => {
  const { isPnoApp } = routeUtils(state);
  const currentMatches = getCurrentMatches(state);

  if (isPnoApp(getAppUniKey(last(currentMatches)))) {
    dispatch(trySetCurrentState({ matches: currentMatches }));
  }
};

listenerMiddleware.startListening({
  actionCreator: fetchSessionAdom.fulfilled,
  effect: async (
    action,
    { dispatch, condition, cancelActiveListeners, fork }
  ) => {
    cancelActiveListeners();
    fork(async () => {
      if (
        await condition((_, currState) =>
          appEnabled('rstadm_policy')(currState)
        )
      ) {
        dispatch(_fetchPnoDisplayOpts());
      }
    });
    if (
      await condition((_, currState) =>
        overSome(appEnabled('pno'), appEnabled('dual_pno'))(currState)
      )
    ) {
      dispatch(_fetchPnoDisplayOpts());
      dispatch(fetchPnoDualPaneMode());
    }
  },
});

listenerMiddleware.startListening({
  predicate: (action, currentState, prevState) => {
    return (
      setPnoDisplayOpts.match(action) &&
      getPnoDisplayOpts(currentState) !== getPnoDisplayOpts(prevState)
    );
  },
  effect: async (action, { dispatch, getState }) => {
    await dispatch(refreshAppTree());
    revalidateSelectedPnoApp(dispatch, getState());
  },
});

export const updateDualPaneMode = createAction('pno/UPDATE_DUAL_PANE_MODE');

listenerMiddleware.startListening({
  actionCreator: updateDualPaneMode,
  effect: async ({ payload }, { getState, dispatch, fork }) => {
    const isDualpane = payload === 'dockToRight' ? 0 : 1;
    fork(async () => {
      const adminName = getAdminUserName(getState());
      const updateFn = withEscapedAdminName(makeUpdateAdminCustomize)(
        adminName
      );
      await updateFn({
        showPnoInDualPane: isDualpane,
      });
    });
    dispatch(setDualPaneMode(payload));
    await dispatch(refreshAppTree());
    // 2. jump to corresponding apps
    const pkgAppKey = 'pno_policy';
    const stateAfterRefresh = getState();
    const currentAppState = getCurrentState(stateAfterRefresh);
    const currentAppKey = get(currentAppState, 'handle.appUniKey');

    const newAppKey = isDualpane ? currentAppKey : pkgAppKey;
    dispatch(pnoGo({ appUniKey: newAppKey }));
  },
});
