import { fiAdom } from 'fi-session';

var FAZS = MACROS.FAZ_SYNTAX;
const DVM = MACROS.DVM;
const ALL_SHORT_CODES = [
  'FAC',
  'FAZ',
  'FCH',
  'FCT',
  'FDD',
  'FMG',
  'FML',
  'FOC',
  'FOS',
  'FFW',
  'FWC',
  'FPX',
  'FSA',
  'FSF',
  'FSW',
  'FWB',
  'LOG',
  'FNA',
  'FDC',
  'FAI',
  'FAD',
  'FSR',
  'FTS',
  'FIS',
  'FED',
  'FPA',
  'FCA',
  'FTC',
  'FRA',
  'FAP',
  // note that 'SYS' in LOGDEV_SYS does not match 'LOG' as in DVM.DVM_OS_TYPE_LOG.
];
const ALL_SHORT_CODE_SET = new Set(ALL_SHORT_CODES);
const OS_TYPE_IGNORED = new Set(['INVALID', 'SIM']);
const foundResProdIds = new Set();
const foundOsTypes = new Set();

// Note: dev short code has "FGT" but
// does not have "FOC" or "FSS".
var _logDevTypeToDevShortCode = {};
var _devShortCodeTologDevType = {};

// Note: os short code includes "FOC", "FOC", "FSS",
// but does not have "FGT".
var _resProdToOsShortCode = {};
var _osShortCodeToResProd = {};

Object.keys(FAZS).forEach(function (key) {
  if (key.indexOf('LOGDEV_') === 0) {
    let shortCode = key.substr('LOGDEV_'.length);
    let logDevType = FAZS[key];
    _devShortCodeTologDevType[shortCode] = logDevType;
    _logDevTypeToDevShortCode[logDevType] = shortCode;
  }
});
Object.keys(DVM).forEach(function (key) {
  if (key.indexOf('DVM_RESTRICTED_PRD_') === 0) {
    let shortCode = key.substr('DVM_RESTRICTED_PRD_'.length);
    if (!ALL_SHORT_CODE_SET.has(shortCode)) {
      console.error(`-- missing short code for ${shortCode}.`); // eslint-disable-line no-console
    }
    let resProd = DVM[key];
    foundResProdIds.add(resProd);
    _resProdToOsShortCode[resProd] = shortCode;
    _osShortCodeToResProd[shortCode] = resProd;
  }
  if (key.indexOf('DVM_OS_TYPE_') === 0 && !OS_TYPE_IGNORED.has(key)) {
    foundOsTypes.add(DVM[key]);
  }
});

Object.keys(FAZS)
  .filter(function (key) {
    return key && key.indexOf('LOGDEV_') === 0;
  })
  .forEach(function (key) {
    var shortCode = key.substr('LOGDEV_'.length);
    var logDevType = FAZS[key];
    _devShortCodeTologDevType[shortCode] = logDevType;
    _logDevTypeToDevShortCode[logDevType] = shortCode;
  });

var _resProdToDisp = {
  [DVM.DVM_RESTRICTED_PRD_FOS]: 'FortiGate',
  [DVM.DVM_RESTRICTED_PRD_FOC]: 'FortiCarrier',
  [DVM.DVM_RESTRICTED_PRD_FAZ]: 'FortiAnalyzer',
  [DVM.DVM_RESTRICTED_PRD_FML]: 'FortiMail',
  [DVM.DVM_RESTRICTED_PRD_FSW]: 'FortiSwitch',
  [DVM.DVM_RESTRICTED_PRD_FWB]: 'FortiWeb',
  [DVM.DVM_RESTRICTED_PRD_FCH]: 'FortiCache',
  [DVM.DVM_RESTRICTED_PRD_LOG]: 'Syslog',
  [DVM.DVM_RESTRICTED_PRD_FCT]: 'FortiClient',
  [DVM.DVM_RESTRICTED_PRD_FMG]: 'FortiManager',
  [DVM.DVM_RESTRICTED_PRD_FSA]: 'FortiSandbox',
  [DVM.DVM_RESTRICTED_PRD_FDD]: 'FortiDDoS',
  [DVM.DVM_RESTRICTED_PRD_FAC]: 'FortiAuthenticator',
  [DVM.DVM_RESTRICTED_PRD_FNA]: 'FortiNAC', // "FNA"
  [DVM.DVM_RESTRICTED_PRD_FDC]: 'FortiDeceptor', // "FDC"
  [DVM.DVM_RESTRICTED_PRD_FFW]: 'FortiFirewall', //FFW
  [DVM.DVM_RESTRICTED_PRD_FWC]: 'FortiFirewallCarrier', //FWC
  [DVM.DVM_RESTRICTED_PRD_FPX]: 'FortiProxy',
  [DVM.DVM_RESTRICTED_PRD_FAI]: 'FortiNDR',
  [DVM.DVM_RESTRICTED_PRD_FSR]: 'FortiSOAR',
  [DVM.DVM_RESTRICTED_PRD_FSF]: 'Fabric',
  [DVM.DVM_RESTRICTED_PRD_FAD]: 'FortiADC',
  [DVM.DVM_RESTRICTED_PRD_FTS]: 'FortiTester',
  [DVM.DVM_RESTRICTED_PRD_FIS]: 'FortiIsolator',
  [DVM.DVM_RESTRICTED_PRD_FED]: 'FortiEDR',
  [DVM.DVM_RESTRICTED_PRD_FPA]: 'FortiPAM',
  [DVM.DVM_RESTRICTED_PRD_FCA]: 'FortiCASB',
  [DVM.DVM_RESTRICTED_PRD_FTC]: 'FortiToken',
  [DVM.DVM_RESTRICTED_PRD_FRA]: 'FortiSRA',
  [DVM.DVM_RESTRICTED_PRD_FAP]: 'FortiAP',
};

(function () {
  // Check and warn about missing name definition.
  const found = new Set(foundResProdIds); // clone
  Object.keys(_resProdToDisp).forEach((key) => {
    key = parseInt(key);
    found.delete(key);
  });
  if (found.size) {
    console.error(`-- missing display value for ${Array.from(found)}.`); // eslint-disable-line no-console
  }
})();

var _dispToResProd = Object.keys(_resProdToDisp).reduce((ret, resProd) => {
  ret[_resProdToDisp[resProd]] = parseInt(resProd);
  return ret;
}, {}); // reversed dictionary

// Follow proj/util/proxy/__init__.py
var _resProdToLogDevType = {};
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FOS] = FAZS.LOGDEV_FGT;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FOC] = FAZS.LOGDEV_FGT;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FML] = FAZS.LOGDEV_FML;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FCH] = FAZS.LOGDEV_FCH;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FWB] = FAZS.LOGDEV_FWB;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_LOG] = FAZS.LOGDEV_SYS;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FCT] = FAZS.LOGDEV_FCT;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FAZ] = FAZS.LOGDEV_FAZ;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FSA] = FAZS.LOGDEV_FSA;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FMG] = FAZS.LOGDEV_FMG;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FDD] = FAZS.LOGDEV_FDD;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FAC] = FAZS.LOGDEV_FAC;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FNA] = FAZS.LOGDEV_FNA;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FDC] = FAZS.LOGDEV_FDC;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FFW] = FAZS.LOGDEV_FFW;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FWC] = FAZS.LOGDEV_FWC;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FPX] = FAZS.LOGDEV_FPX;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FAI] = FAZS.LOGDEV_FAI;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FSR] = FAZS.LOGDEV_FSR;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FAD] = FAZS.LOGDEV_FAD;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FSF] = FAZS.LOGDEV_FSF;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FIS] = FAZS.LOGDEV_FIS;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FED] = FAZS.LOGDEV_FED;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FPA] = FAZS.LOGDEV_FPA;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FCA] = FAZS.LOGDEV_FCA;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FTC] = FAZS.LOGDEV_FTC;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FRA] = FAZS.LOGDEV_FRA;
_resProdToLogDevType[DVM.DVM_RESTRICTED_PRD_FAP] = FAZS.LOGDEV_FAP;

var _logDevTypeToResProd = {};
Object.keys(_resProdToLogDevType).forEach(function (resProd) {
  const int_resPord = parseInt(resProd);
  if (int_resPord === DVM.DVM_RESTRICTED_PRD_FOC) {
    return; //skip FOC due to conflict with FOS. We will use FOS.
  }
  _logDevTypeToResProd[_resProdToLogDevType[resProd]] = int_resPord;
});

const _osTypeToResProd = ALL_SHORT_CODES.reduce((map, shortCode) => {
  const osType = DVM[`DVM_OS_TYPE_${shortCode}`];
  if (typeof osType !== 'undefined') {
    // does not include FSS
    map[osType] = DVM[`DVM_RESTRICTED_PRD_${shortCode}`];
  }
  return map;
}, {});

const _logDevTypeToOsType = ALL_SHORT_CODES.reduce((map, shortCode) => {
  const logDevType = FAZS[`LOGDEV_${shortCode}`];
  if (typeof logDevType !== 'undefined') {
    map[logDevType] = DVM[`DVM_OS_TYPE_${shortCode}`];
  }
  return map;
}, {});
// FGT <-> FOS not covered
_logDevTypeToOsType[FAZS.LOGDEV_FGT] = DVM.DVM_OS_TYPE_FOS;
_logDevTypeToOsType[FAZS.LOGDEV_SYS] = DVM.DVM_OS_TYPE_LOG; // short codes don't match...(SysLog type);

let resProdToOsTypesMapCache = null;

export const logDevTypeToDevShortCode = function (input) {
  return _logDevTypeToDevShortCode[input];
};
export const logDevTypeToResProd = function (input) {
  return _logDevTypeToResProd[input];
};
export const logDevTypeToDisp = function (input) {
  return _resProdToDisp[_logDevTypeToResProd[input]];
};
export const devShortCodeTologDevType = function (input) {
  return _devShortCodeTologDevType[input];
};
export const resProdToOsShortCode = function (input) {
  return _resProdToOsShortCode[input];
};
export const resProdToDisp = function (input) {
  return _resProdToDisp[input];
};
export const resProdToLogDevType = function (input) {
  return _resProdToLogDevType[input];
};
export const osShortCodeToLogDevType = function (input) {
  return _resProdToLogDevType[_osShortCodeToResProd[input]];
};
export const osShortCodeToResProd = function (input) {
  return _osShortCodeToResProd[input];
};
export const osTypeToResProd = function (input) {
  return _osTypeToResProd[input];
};
export const logDevTypeToOsType = function (input) {
  return _logDevTypeToOsType[input];
};
export const resProdDispToDvmOsType = function (input) {
  const resProd = _dispToResProd[input];
  const osShortCode = _resProdToOsShortCode[resProd] || input; // in case of SIM there is no numerical code we have to use the input (which should be "SIM") directly.
  const osType = DVM[`DVM_OS_TYPE_${osShortCode}`];
  return osType;
};

// When canUseCache a cached copy may be used. Otherwise always generate new result.
// Guarding against possible mutable use.
// Only immutable usage should set canUseCache true.
export const resProdToOsTypesMap = function (canUseCache) {
  // This may be 1-to-N so result is alrways array.
  if (canUseCache && resProdToOsTypesMapCache) return resProdToOsTypesMapCache;
  const FSF_OS_TYPES = Array.from(foundOsTypes);
  let ret = {
    [MACROS.DVM.DVM_RESTRICTED_PRD_FSF]: FSF_OS_TYPES,
  };
  ALL_SHORT_CODES.forEach((code) => {
    if (code === 'FSF') return; // continue;
    let key = DVM['DVM_RESTRICTED_PRD_' + code];
    let val = DVM['DVM_OS_TYPE_' + code];
    if (key !== undefined && val !== undefined) {
      ret[key] = [val];
    }
  });
  if (canUseCache && !resProdToOsTypesMapCache) {
    resProdToOsTypesMapCache = ret;
  }
  return ret;
};
export const osTypes = function () {
  return new Set(foundOsTypes);
};
export const resProds = function () {
  return new Set(foundResProdIds);
};

export const getDefaultAllDeviceGroupName = () => {
  const adom = fiAdom.current();
  const VALID_TYPES = [
    'FOS',
    'FOC',
    'FAZ',
    'FML',
    'FWB',
    'FCH',
    'FPX',
    'LOG',
    'FCT',
    'FMG',
    'FSA',
    'FDD',
    'FAC',
    'FDC',
    'FAD',
  ];

  const shortCode = resProdToOsShortCode(adom.type);
  let name = resProdToDisp(adom.type);
  if (!(VALID_TYPES.includes(shortCode) && name)) {
    name = resProdToDisp(MACROS.DVM.DVM_RESTRICTED_PRD_FOS);
  }
  return `All_${name}`;
};

export const OS_TYPE_N_DEV_NAME_MAP = {
  [MACROS.DVM.DVM_OS_TYPE_FOS]: {
    value: 'All_FortiGate',
    full: gettext('All FortiGate'),
    abbr: gettext('FortiGate'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FSW]: {
    value: 'All_FortiSwitch',
    full: gettext('All FortiSwitch'),
    abbr: gettext('FortiSwitch'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FOC]: {
    value: 'All_FortiCarrier',
    full: gettext('All FortiCarrier'),
    abbr: gettext('FortiCarrier'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FFW]: {
    value: 'All_FortiFirewall',
    full: gettext('All FortiFirewall'),
    abbr: gettext('FortiFirewall'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FWC]: {
    value: 'All_FortiFirewallCarrier',
    full: gettext('All FortiFirewallCarrier'),
    abbr: gettext('FortiFirewallCarrier'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FPX]: {
    value: 'All_FortiProxy',
    full: gettext('All FortiProxy'),
    abbr: gettext('FortiProxy'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FML]: {
    value: 'All_FortiMail',
    full: gettext('All FortiMail'),
    abbr: gettext('FortiMail'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FAZ]: {
    value: 'All_FortiAnalyzer',
    full: gettext('All FortiAnalyzer'),
    abbr: gettext('FortiAnalyzer'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FWB]: {
    value: 'All_FortiWeb',
    full: gettext('All FortiWeb'),
    abbr: gettext('FortiWeb'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FCH]: {
    value: 'All_FortiCache',
    full: gettext('All FortiCache'),
    abbr: gettext('FortiCache'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FCT]: {
    value: 'All_FortiClient',
    full: gettext('All FortiClient'),
    abbr: gettext('FortiClient'),
  },
  [MACROS.DVM.DVM_OS_TYPE_LOG]: {
    value: 'All_Syslog',
    full: gettext('All Syslog'),
    abbr: gettext('Syslog'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FMG]: {
    value: 'All_FortiManager',
    full: gettext('All FortiManager'),
    abbr: gettext('FortiManager'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FSA]: {
    value: 'All_FortiSandbox',
    full: gettext('All FortiSandbox'),
    abbr: gettext('FortiSandbox'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FDD]: {
    value: 'All_FortiDDoS',
    full: gettext('All FortiDDoS'),
    abbr: gettext('FortiDDoS'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FAC]: {
    value: 'All_FortiAuthenticator',
    full: gettext('All FortiAuthenticator'),
    abbr: gettext('FortiAuthenticator'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FNA]: {
    value: 'All_FortiNAC',
    full: gettext('All FortiNAC'),
    abbr: gettext('FortiNAC'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FDC]: {
    value: 'All_FortiDeceptor',
    full: gettext('All FortiDeceptor'),
    abbr: gettext('FortiDeceptor'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FAD]: {
    value: 'All_FortiADC',
    full: gettext('All FortiADC'),
    abbr: gettext('FortiADC'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FAI]: {
    value: 'All_FortiNDR',
    full: gettext('All FortiNDR'),
    abbr: gettext('FortiNDR'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FSR]: {
    value: 'All_FortiSOAR',
    full: gettext('All FortiSOAR'),
    abbr: gettext('FortiSOAR'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FIS]: {
    value: 'All_FortiIsolator',
    full: gettext('All FortiIsolator'),
    abbr: gettext('FortiIsolator'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FED]: {
    value: 'All_FortiEDR',
    full: gettext('All FortiEDR'),
    abbr: gettext('FortiEDR'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FPA]: {
    value: 'All_FortiPAM',
    full: gettext('All FortiPAM'),
    abbr: gettext('FortiPAM'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FTC]: {
    value: 'All_FortiToken',
    full: gettext('All FortiToken'),
    abbr: gettext('FortiToken'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FCA]: {
    value: 'All_FortiCASB',
    full: gettext('All FortiCASB'),
    abbr: gettext('FortiCASB'),
  },
  [MACROS.DVM.DVM_OS_TYPE_SIM]: {
    value: 'All_Fabric',
    full: gettext('All Fabric'),
    abbr: gettext('Fabric'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FRA]: {
    value: 'All_FortiSRA',
    full: gettext('All FortiSRA'),
    abbr: gettext('FortiSRA'),
  },
  [MACROS.DVM.DVM_OS_TYPE_FAP]: {
    value: 'All_FortiAP',
    full: gettext('All FortiAP'),
    abbr: gettext('FortiAP'),
  },
};

export const ALL_DEVICE_CONSTS = {
  All_Device: gettext('All Device'),
  All_FortiGate: gettext('All FortiGate'),
  All_FortiSwitch: gettext('All FortiSwitch'),
  All_FortiCarrier: gettext('All FortiCarrier'),
  All_FortiMail: gettext('All FortiMail'),
  All_FortiWeb: gettext('All FortiWeb'),
  All_FortiCache: gettext('All FortiCache'),
  All_FortiProxy: gettext('All FortiProxy'),
  All_FortiManager: gettext('All FortiManager'),
  All_Syslog: gettext('All Syslog'),
  All_FortiSandbox: gettext('All FortiSandbox'),
  All_FortiClient: gettext('All FortiClient'),
  All_FortiAnalyzer: gettext('All FortiAnalyzer'),
  All_FortiDDoS: gettext('All FortiDDoS'),
  All_FortiAuthenticator: gettext('All FortiAuthenticator'),
  All_FortiNAC: gettext('All FortiNAC'),
  All_FortiDeceptor: gettext('All FortiDeceptor'),
  All_FortiADC: gettext('All FortiADC'),
  All_FortiFirewall: gettext('All FortiFirewall'),
  All_FortiNDR: gettext('All FortiNDR'),
  All_FortiSOAR: gettext('All FortiSOAR'),
  All_FortiIsolator: gettext('All FortiIsolator'),
  All_FortiEDR: gettext('All FortiEDR'),
  All_FortiPAM: gettext('All FortiPAM'),
  All_FortiAP: gettext('All FortiAP'),
  All_FortiToken: gettext('All FortiToken'),
  All_FortiCASB: gettext('All FortiCASB'),
};
