import { DVMGROUPS } from '../constants';
import { getUpgradeToString } from './parse_util';

export class DvmDeviceParser {
  constructor({ device, allPsirtData }) {
    this.device = device;
    this.allPsirtData = allPsirtData;
  }

  _shouldParseDvmDevice() {
    const deviceGroup = DVMGROUPS[this.device.os_type];
    const { vulnsArr } = this._getDvmDeviceMetadata(deviceGroup);
    return !!deviceGroup && Array.isArray(vulnsArr) && vulnsArr.length > 0;
  }

  _getDvmDeviceMetadata(deviceGroup) {
    const { platformName, name: deviceTypeName } = deviceGroup;
    const { byPlatform: irNumsByPlatform } = this.allPsirtData;

    let currentVersion = '';
    if (this.device.model_dev)
      currentVersion = this.device['prefer_img_ver'].split('-')[0];
    else currentVersion = this.device.frmversion + '.' + this.device._patch;

    const platformKey = platformName + '_' + currentVersion;
    const vulnsArr = irNumsByPlatform[platformKey];

    return {
      vulnsArr,
      currentVersion,
      platformKey,
      deviceTypeName,
      platformName,
    };
  }

  parse() {
    if (!this._shouldParseDvmDevice()) return;
    const parsedDev = { ...this.device };
    const deviceGroup = DVMGROUPS[this.device.os_type];
    const {
      vulnsArr,
      currentVersion,
      platformKey,
      deviceTypeName,
      platformName,
    } = this._getDvmDeviceMetadata(deviceGroup);

    parsedDev.vulns = vulnsArr;
    parsedDev.key = parsedDev.name + parsedDev.frmversion;
    parsedDev.platformKey = platformKey;
    parsedDev.deviceType = deviceTypeName;

    parsedDev.gui_upgrade_to = getUpgradeToString({
      currentVersion,
      platformName,
      vulnsArr,
      deviceTypeName,
      allPsirtData: this.allPsirtData,
    });

    return parsedDev;
  }
}
