export const widgets = {
  threatmap: {
    title: {
      text: gettext('Threat Map'),
      font: 'ffg-address-geo',
      desc: gettext('Threats happening right now cross the world'),
    },
    viewName: 'threatmap',
    type: 'threatmap',
    timePeriodType: 'threat_map',
    components: {
      threatmap: 'fi-noc-threatmap',
    },
  },
  top_threat: {
    title: {
      text: gettext('Top Threats'),
      font: 'ffg-chart-library',
      desc: gettext(
        'The top threats to your network from risk applications, intrusion incidents, malicious web sites, and malware/botnets'
      ),
    },
    viewName: 'threat',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bar: 'soc-list-bar',
      bubble: 'soc-list-bubble',
    },
  },
  ioc_user: {
    title: {
      text: gettext('Indicator of Compromise'),
      font: 'ffg-chart-library',
      desc: gettext(
        'The top threats to your network from risk applications, intrusion incidents, malicious web sites, and malware/botnets'
      ),
    },
    viewName: 'threatDetectSummary',
    timePeriodType: 'ioc',
    type: 'table',
    components: {
      table: 'soc-list-table',
      ioc_user: 'soc-ioc-user',
      bubble: 'soc-list-bubble',
    },
  },
  fsa_detection: {
    title: {
      text: gettext('FortiSandbox Detection'),
      font: 'ffg-chart-table',
      desc: gettext(
        'FortiSandbox Detection Detail, including scan doc name, source user, destination IP, verdict level, action, service'
      ),
    },
    viewName: 'fsaDetection',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  top_source: {
    title: {
      text: gettext('Top Sources'),
      font: 'ffg-chart-library',
      desc: gettext(
        'The highest network traffic by source IP address and interface, sessions (blocked and allowed), threat score (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'sourceView',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bar: 'soc-list-bar',
      simple: 'soc-list-simple',
      bubble: 'soc-list-bubble',
    },
  },
  top_source_objects: {
    title: {
      text: gettext('Top Source Addresses'),
      font: 'ffg-chart-library',
      desc: gettext(
        'The highest network traffic by source address objects, sessions (blocked and allowed), threat score (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'sourceUuid',
    type: 'table',
    components: {
      table: 'soc-list-table',
      simple: 'soc-list-simple',
      bubble: 'soc-list-bubble',
    },
  },
  top_country: {
    title: {
      text: gettext('Top Country/Region'),
      font: 'ffg-chart-library',
    },
    viewName: 'country',
    type: 'table',
    components: {
      table: 'soc-list-table',
      treemap: 'soc-list-treemap',
      bar: 'soc-list-bar',
      bubble: 'soc-list-bubble',
    },
  },
  top_destination: {
    title: {
      text: gettext('Top Destinations'),
      font: 'ffg-chart-table',
    },
    viewName: 'destinationView',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bar: 'soc-list-bar',
      bubble: 'soc-list-bubble',
    },
  },
  top_destination_objects: {
    title: {
      text: gettext('Top Destination Addresses'),
      desc: gettext('Top destination Address Objects from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'destinationUuid',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  policy_hits: {
    title: {
      text: gettext('Policy Hits'),
      font: 'ffg-chart-table',
    },
    viewName: 'policyHits',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  create_policy_by_hits: {
    title: {
      text: gettext('Policy Hits'),
      font: 'ffg-chart-table',
    },
    viewName: 'createPolicyByHits',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  dns_logs: {
    title: {
      text: gettext('DNS Logs'),
      desc: gettext('Top DNS logs from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'dnsLogView',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_application: {
    title: {
      text: gettext('Top Applications'),
      font: 'ffg-chart-library',
    },
    viewName: 'app',
    viewTypeNameMap: {
      stackbar: 'appOverTime',
    },
    type: 'table',
    components: {
      stackbar: 'soc-list-bar',
      bar: 'soc-list-bar',
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_cloud_application: {
    title: {
      text: gettext('Top Cloud Applications'),
      desc: gettext('Top cloud applications from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'cloudApplication',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_cloud_user: {
    title: {
      text: gettext('Top Cloud Users'),
      desc: gettext('Top cloud users from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'topCloudUser',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_website: {
    title: {
      text: gettext('Top Website Domains'),
      desc: gettext('Top website domains from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'websiteDomain',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_website_category: {
    title: {
      text: gettext('Top Website Categories'),
      desc: gettext('Top website categories from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'websiteCategory',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_browsing_user: {
    title: {
      text: gettext('Top Browsing Users'),
      desc: gettext('Top browsing users from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'browsingUsers',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_vpn: {
    title: {
      text: gettext('SSL & Dialup IPsec'),
      desc: gettext(
        'The users accessing the network using SSL or IPsec over a VPN tunnel'
      ),
      font: 'ffg-address-geo',
    },
    viewName: 'sslDialup',
    type: 'table',
    components: {
      table: 'soc-list-table',
      world: 'soc-list-map',
      globe: 'soc-list-globe',
    },
  },
  vpn_site2site: {
    title: {
      text: gettext('Site-to-Site IPsec'),
      desc: gettext(
        'The names of VPN tunnels with Internet protocol security (IPsec) that are accessing the network'
      ),
      font: 'ffg-address-geo',
    },
    viewName: 'siteToSite',
    type: 'table',
    components: {
      table: 'soc-list-table',
      world: 'soc-list-map',
      globe: 'soc-list-globe',
    },
  },
  admin: {
    title: {
      text: gettext('Admin Logins'),
      desc: gettext('Top admin logins from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'admin',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  system_events: {
    title: {
      text: gettext('System Events'),
      desc: gettext('Top system events from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'systemEvent',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  resource_average: {
    title: {
      text: gettext('Resource Usage Average'),
      desc: gettext(
        'Overview of average resource usage history cross all devices.'
      ),
      font: 'ffg-chart-table',
    },
    viewName: 'resourceUsage',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  resource_peak: {
    title: {
      text: gettext('Resource Usage Peak'),
      desc: gettext(
        'Overview of peak resource usage history cross all devices.'
      ),
      font: 'ffg-chart-table',
    },
    viewName: 'resourcePeak',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  unauthorized_connections: {
    title: {
      text: gettext('Failed Authentication Attempts'),
      desc: gettext('Top unauthorized connections from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'unauthorizedConnections',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  source_dns_view: {
    title: {
      text: gettext('Source'),
      font: 'ffg-chart-table',
    },
    viewName: 'source-dns-view',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  destination_dns_view: {
    title: {
      text: gettext('Destination'),
      font: 'ffg-chart-table',
    },
    viewName: 'destinationDnsView',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  country_dns_view: {
    title: {
      text: gettext('Country/Region'),
      font: 'ffg-chart-table',
    },
    viewName: 'countryDnsView',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },

  ssl_dialup_session: {
    title: {
      text: gettext('Session'),
      font: 'ffg-chart-table',
    },
    viewName: 'sslDialupSession',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  top_threat_fct: {
    title: {
      text: gettext('Top Threats (FortiClient)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fctThreat',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_app_fct: {
    title: {
      text: gettext('Top Applications (FortiClient)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fctApp',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_website_fct: {
    title: {
      text: gettext('Top Websites (FortiClient)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fctWebsite',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_source_fddos: {
    title: {
      text: gettext('Top Sources (FortiDDoS)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fddosSourceView',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  top_destination_fddos: {
    title: {
      text: gettext('Top Destinations (FortiDDoS)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fddosDestinationView',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  top_type_fddos: {
    title: {
      text: gettext('Top Type (FortiDDoS)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fddosTypeView',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  // for FortiFirewall
  top_source_ffw: {
    title: {
      text: gettext('Top Sources (FortiFirewall)'),
      font: 'ffg-chart-library',
      desc: gettext(
        'The highest network traffic by source IP address and interface, sessions (blocked and allowed), threat score (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'ffwSourceView',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bar: 'soc-list-bar',
      simple: 'soc-list-simple',
      bubble: 'soc-list-bubble',
    },
  },
  top_source_objects_ffw: {
    title: {
      text: gettext('Top Source Addresses (FortiFirewall)'),
      font: 'ffg-chart-library',
      desc: gettext(
        'The highest network traffic by source address objects, sessions (blocked and allowed), threat score (blocked and allowed), and bytes (sent and received)'
      ),
    },
    viewName: 'ffwSourceUuid',
    type: 'table',
    components: {
      table: 'soc-list-table',
      simple: 'soc-list-simple',
      bubble: 'soc-list-bubble',
    },
  },
  top_country_ffw: {
    title: {
      text: gettext('Top Country/Region (FortiFirewall)'),
      font: 'ffg-chart-library',
    },
    viewName: 'ffwCountry',
    type: 'table',
    components: {
      table: 'soc-list-table',
      treemap: 'soc-list-treemap',
      bar: 'soc-list-bar',
      bubble: 'soc-list-bubble',
    },
  },
  top_destination_ffw: {
    title: {
      text: gettext('Top Destinations (FortiFirewall)'),
      font: 'ffg-chart-table',
    },
    viewName: 'ffwDestinationView',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bar: 'soc-list-bar',
      bubble: 'soc-list-bubble',
    },
  },
  top_destination_objects_ffw: {
    title: {
      text: gettext('Top Destination Addresses (FortiFirewall)'),
      desc: gettext('Top destination Address Objects from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'ffwDestinationUuid',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  policy_hits_ffw: {
    title: {
      text: gettext('Policy Hits (FortiFirewall)'),
      font: 'ffg-chart-table',
    },
    viewName: 'ffwPolicyHits',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
    },
  },
  top_vpn_ffw: {
    title: {
      text: gettext('SSL & Dialup IPsec (FortiFirewall)'),
      desc: gettext(
        'The users accessing the network using SSL or IPsec over a VPN tunnel'
      ),
      font: 'ffg-address-geo',
    },
    viewName: 'ffwSslDialup',
    type: 'table',
    components: {
      table: 'soc-list-table',
      world: 'soc-list-map',
      globe: 'soc-list-globe',
    },
  },
  vpn_site2site_ffw: {
    title: {
      text: gettext('Site-to-Site IPsec (FortiFirewall)'),
      desc: gettext(
        'The names of VPN tunnels with Internet protocol security (IPsec) that are accessing the network'
      ),
      font: 'ffg-address-geo',
    },
    viewName: 'ffwSiteToSite',
    type: 'table',
    components: {
      table: 'soc-list-table',
      world: 'soc-list-map',
      globe: 'soc-list-globe',
    },
  },
  admin_ffw: {
    title: {
      text: gettext('Admin Logins (FortiFirewall)'),
      desc: gettext('Top admin logins from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'ffwAdmin',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  system_events_ffw: {
    title: {
      text: gettext('System Events (FortiFirewall)'),
      desc: gettext('Top system events from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'ffwSystemEvent',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  resource_average_ffw: {
    title: {
      text: gettext('Resource Usage Average (FortiFirewall)'),
      desc: gettext(
        'Overview of average resource usage history cross all devices.'
      ),
      font: 'ffg-chart-table',
    },
    viewName: 'ffwResourceUsage',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  resource_peak_ffw: {
    title: {
      text: gettext('Resource Usage Peak (FortiFirewall)'),
      desc: gettext(
        'Overview of peak resource usage history cross all devices.'
      ),
      font: 'ffg-chart-table',
    },
    viewName: 'ffwResourcePeak',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  unauthorized_connections_ffw: {
    title: {
      text: gettext('Failed Authentication Attempts (FortiFirewall)'),
      desc: gettext('Top unauthorized connections from recent traffic'),
      font: 'ffg-chart-table',
    },
    viewName: 'ffwUnauthorizedConnections',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
  // for FortiWeb
  fwb_security_country: {
    title: {
      text: gettext('Countries (FortiWeb)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fwbSecurityCountry',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
      bar: 'soc-list-bar',
      treemap: 'soc-list-treemap',
    },
  },
  fwb_security_threat: {
    title: {
      text: gettext('Threats (FortiWeb)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fwbSecurityThreat',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
      bar: 'soc-list-bar',
    },
  },
  fwb_security_policy: {
    title: {
      text: gettext('Server Policies (FortiWeb)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fwbSecurityPolicy',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
      bar: 'soc-list-bar',
    },
  },
  fwb_traffic_source: {
    title: {
      text: gettext('Sources (FortiWeb)'),
      font: 'ffg-chart-table',
      bubble: 'soc-list-bubble',
    },
    viewName: 'fwbTrafficSource',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
      bar: 'soc-list-bar',
    },
  },
  fwb_traffic_country: {
    title: {
      text: gettext('Countries (FortiWeb)'),
      font: 'ffg-chart-table',
    },
    viewName: 'fwbTrafficCountry',
    type: 'table',
    components: {
      table: 'soc-list-table',
      bubble: 'soc-list-bubble',
      bar: 'soc-list-bar',
      treemap: 'soc-list-treemap',
    },
  },
  drilldown: {
    type: 'table',
    components: {
      table: 'soc-drilldown',
      bubble: 'soc-list-bubble',
      sandbox: 'soc-sandbox',
      sandboxScan: 'SandboxScan',
      resource: 'soc-resource',
      healthcheck: 'soc-healthcheck',
      sdwanPerformance: 'soc-sdwan-performance',
    },
  },
  // ZTNA
  ztna_servers_by_bytes: {
    title: {
      text: gettext('ZTNA Servers'),
      desc: gettext('ZTNA Servers by Bytes'),
    },
    viewName: 'ztnaServersByBytes',
    type: 'table',
    components: {
      table: 'soc-list-table',
    },
  },
};
