import cn from 'classnames';
export function OrLine() {
  return (
    <div
      className={cn(
        'tw-mx-auto tw-max-w-72 tw-overflow-hidden tw-text-center tw-text-neutral-500',
        'before:tw-bg-neutral-300 before:tw-inline-block before:tw-h-px before:tw-relative before:tw-align-middle before:tw-w-1/2 before:tw-content-[""] before:-tw-ml-[50%] before:tw-right-2',
        'after:tw-bg-neutral-300 after:tw-inline-block after:tw-h-px after:tw-relative after:tw-align-middle after:tw-w-1/2 after:tw-content-[""] after:-tw-mr-[50%] after:tw-left-2'
      )}
    >
      {gettext('OR')}
    </div>
  );
}
