import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { fiSession } from 'fistore';
import { NwTreeView } from '@fafm/neowise-core';

export const withAdomRefresh = (WrappedComponent, appUniKey = '') => {
  return (props) => {
    const adom = useSelector(fiSession.getSessionAdom)?.data || null;
    const [refreshKey, setRefresh] = useState(false);
    useEffect(() => {
      setRefresh((prevVal) => !prevVal);
    }, [adom]);
    return <WrappedComponent {...props} key={`${appUniKey}-${refreshKey}`} />;
  };
};

const Row =
  (titleRender) =>
  ({ data }) => {
    if (titleRender) {
      return titleRender(data);
    }
    return (
      <div>
        {data.icon} {data.title ?? '---'}
      </div>
    );
  };

/*
  This is to replace the RC Tree with NwTree Component
 */
export const RCTreeToNwTreeViewAdapter = ({
  treeData,
  defaultExpandAll = true,
  height = undefined,
  fieldNames = { title: 'title', key: 'key', children: 'children' },
  itemHeight = 30,
  maxHeight = 400,
  titleRender = undefined,
  ...props
}) => {
  const [convertedTree, nodeCount] = useMemo(() => {
    let count = 0;
    const cvt = (elem) => {
      count++;
      return {
        ...elem,
        title: elem[fieldNames.title],
        id: elem[fieldNames.key],
        children: (elem[fieldNames.children] ?? []).map(cvt),
      };
    };
    if (!Array.isArray(treeData)) {
      return [[], 0];
    }
    const result = (treeData ?? []).map(cvt);
    return [result, count];
  }, [treeData]);

  const _height = useMemo(() => {
    if (height) return height;
    if (defaultExpandAll) {
      return Math.min(nodeCount * itemHeight + 4, maxHeight);
    }
    return undefined;
  }, [height, nodeCount, itemHeight, defaultExpandAll]);

  return (
    <NwTreeView
      expandAction='click'
      defaultExpandAll={defaultExpandAll}
      height={_height}
      itemHeight={itemHeight}
      source={convertedTree}
      {...props}
    >
      {Row(titleRender)}
    </NwTreeView>
  );
};
