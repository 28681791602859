import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { RouteObject, useMatches } from 'react-router-dom';
import { findLast, get, isFunction } from 'lodash';

import { goApp } from 'fistore/routing/slice';

type RedirectRouteComponentProps = {
  findMatch?: (
    matches: ReturnType<typeof useMatches>
  ) => ReturnType<typeof useMatches>[0];
  redirectAppKey?: string;
};

export const getRedirectIndexRoute = ({
  findMatch,
  redirectAppKey,
}: RedirectRouteComponentProps = {}): RouteObject => {
  return {
    index: true,
    Component: () => (
      <RedirectRouteComponent
        findMatch={findMatch}
        redirectAppKey={redirectAppKey}
      />
    ),
  };
};

/** ----------------------------------------------------------------------------
 * This component is for redirecting to the next app if the current matching route
 * object doesn't render a component (there is no lazy, Component, or element)
 * -------------------------------------------------------------------------- */
export const RedirectRouteComponent: FC<RedirectRouteComponentProps> = ({
  findMatch,
  redirectAppKey,
}) => {
  const dispatch = useDispatch();
  const matches = useMatches();
  const match = useMemo(() => {
    if (isFunction(findMatch)) {
      return findMatch(matches);
    }

    // by default, find a route that has appUniKey
    return findLast(matches, (match) => {
      const handle = match.handle as { appUniKey?: string };
      return !!handle?.appUniKey;
    });
  }, [findMatch, matches]);

  useEffect(() => {
    if (redirectAppKey || match) {
      const toApp = redirectAppKey || get(match, 'handle.appUniKey');
      if (toApp) {
        dispatch(goApp({ key: toApp }));
      }
    }
  }, []);

  return null;
};
