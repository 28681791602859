import { call, select, takeLatest, all, put } from 'redux-saga/effects';
import { callPromiseAction } from '../../utils';
import * as api from './api';
import * as actions from './actions';
import {
  getSessionAdomData,
  getIsGlobalAdom,
} from 'fistore/session/adom/selectors';

function* fetchDeviceChecksumSaga() {
  const adom = yield select(getSessionAdomData);
  const isGlobal = yield select(getIsGlobalAdom);
  try {
    const [dvmChecksum, adomChecksum] = yield all([
      call(api.fetchDvmChecksum, adom.name, isGlobal),
      call(api.fetchAdomChecksum, adom.name, isGlobal),
    ]);
    yield put(
      actions.setDeviceChecksum({
        adomOid: adom.oid,
        dvmChecksum,
        adomChecksum,
      })
    );
  } catch (e) {
    // TODO: Restricted_User will get this, ignore
  }
}

export function* watchDvmChecksum() {
  // fetch checksum after adom fetch
  // yield takeLatest(fetchSessionAdom.fulfilled.type, fetchDeviceChecksumSaga);
  yield takeLatest(actions.fetchDeviceChecksum.type, function* (action) {
    yield callPromiseAction(action, fetchDeviceChecksumSaga);
  });
}
