import { fiHttpGet, fiHttpPost, fiFmgHttp } from 'fi-http';
import { fiAdom } from 'fi-session';
import { escapeSlash, escapeSlashFull } from 'kit/kit-regexp';
import { merge, isUndefined, isObject, isEmpty, get } from 'lodash';

const INSTALL_DEV_IMMEDIATELY = 0;
const INSTALL_DEV_SCHEDULE = 1;

export const fiDeviceDataFetcher = {
  checkPkgQinstall: checkPkgQinstall,
  checkPKGSync: checkPKGSync,
  countPolicyBlock: countPolicyBlock,
  delCsfGroup: _delCsfGroup,
  discoverDev: discoverDev,
  execCfgInstl: execCfgInstl,
  getAdomInfo: getAdomInfo,
  //get Adom Log quota
  getAdomStorageInfo: getAdomStorageInfo,
  getAllAdomsDevicesNonCsf: _getAllAdomsDevicesNonCsf,
  getBuildAndBranchpt: getBuildAndBranchpt,
  getChassisGrp: getChassisGrp,
  //csf groups
  getCsfGroups: _getCsfGroups,
  getDevAllVdoms: getDevAllVdoms,
  getDevByDevId: getDevByDevId,
  getDeviceVdoms,
  getDevGrpByJSON: getDevGrpByJSON,
  getDevicesIPConflict: getDevicesIPConflict,
  getDevInfo: getDevInfo,
  getDevListByGrp: getDevListByGrp,
  getDevSessionList: getDevSessionList,
  getDevVdom: getDevVdom,
  getDevSyntax: getDevSyntax,
  getDvmdbDeviceConflictInfo: getDvmdbDeviceConflictInfo,
  //gui-proxy resolve provider
  getDvmServiceData: getDvmServiceData,
  getFwVers: getFwVers,
  getManagedDeviceNumber: getManagedDeviceNumber,
  getModelsBySN: getModelsBySN,
  getModelsByVersions: getModelsByVersions,
  getPBlockList: getPBlockList,
  getPkgList: getPkgList,
  getPkgScope: getPkgScope,
  getCategoryData: getCategoryData,
  getModelHaSlaves: getModelHaSlaves,
  clonePkg: clonePkg,
  instDevDevList: instDevDevList,
  importUserDevice: importUserDevice,
  addToUserDeviceGroup: addToUserDeviceGroup,
  getUserDeviceGroups: getUserDeviceGroups,
  prepPkgInstl: prepPkgInstl,
  updateDeviceDesc: updateDeviceDesc,
  //set pkg for model dev
  assignModelDevPkg: assignModelDevPkg,
  downloadFileGUI: downloadFileGUI,
};

// eslint-disable-next-line
function downloadFileGUI(filename, text, config = {}) {
  // Create a binary representation of the plain-text input.
  const blob = new Blob(
    [text], // Blob parts.
    {
      type: 'text/plain;charset=utf-8',
    }
  );
  const downloadUrl = URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.style.display = 'none';
  //element.setAttribute('href', 'data:text/plain;charset=utf-8,', + encodeURIComponent(text));
  element.setAttribute('href', downloadUrl);
  element.setAttribute('download', filename);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

function assignModelDevPkg(devName, pkg) {
  const adom = fiAdom.current();
  const url = '/gui/adom/%s/device/%s/operation'.printf([adom.name, devName]);
  return new Promise((resolve, reject) => {
    fiFmgHttp
      .post({
        id: 1,
        url: url,
        method: 'assignDevPkg',
        params: {
          pkgoid: pkg.oid,
          adomoid: adom.oid,
        },
      })
      .then(
        function (resp) {
          resolve(resp);
        },
        function (err) {
          // NOTE: fiFmgHttp will always reject here, because response data for "assignDevPkg" is not valid JSON
          if (isUndefined(err)) {
            resolve();
          } else {
            reject(err);
          }
        }
      );
  });
}

function getDevSessionList(device) {
  // api/v2/monitor/firewall/session/select?count=200
  const adom = fiAdom.current();
  const target = [];
  if (device) {
    target.push('adom/%s/device/%s'.printf([adom.name, device.name]));
  }
  return fiFmgHttp
    .proxy(target, 'get', {
      'api type': 'monitor',
      path: 'firewall',
      name: 'session',
      payload: [{ count: 1000 }],
    })
    .then(
      function (resp) {
        try {
          return resp[0].data[0].response.results.details;
        } catch (e) {
          return [];
        }
      },
      function () {
        return [];
      }
    );
}

function getDevVdom(
  deviceOid,
  adomOid,
  vdomFilter_name = null,
  vdomFilter_op = null
) {
  const param = {
    deviceId: parseInt(deviceOid),
    adom: adomOid,
    sort: 'name',
    dir: 'asc',
    startIndex: 0,
    results: -1,
  };
  if (!isUndefined(vdomFilter_name) && !isUndefined(vdomFilter_op)) {
    param['vdomFilter_name'] = vdomFilter_name;
    param['vdomFilter_op'] = vdomFilter_op;
  }
  return new Promise((resolve, reject) => {
    fiFmgHttp
      .post({
        id: 1,
        url: '/gui/adom/dvm/vdomain',
        method: 'get',
        params: param,
      })
      .then(
        function (resp) {
          resolve(resp[0].data);
        },
        function (err) {
          console.error(err);
          reject(err);
        }
      );
  });
}

function getDevSyntax(abortCtrl) {
  return fiFmgHttp
    .forward(
      {
        id: 1,
        method: 'get',
        params: [
          {
            url: `/dvmdb/adom/${fiAdom.current().name}/device`,
            option: 'syntax',
          },
        ],
      },
      false,
      abortCtrl
    )
    .then((resp) => resp[0].data);
}

function getModelHaSlaves(device, onlySlave) {
  return fiFmgHttp
    .post({
      url: '/gui/adom/dvm/device/ha',
      method: 'getSlaves',
      params: {
        name: device,
        slaveOnly: onlySlave ? 1 : 0,
      },
    })
    .then((resp) => resp[0].data);
}

function getCategoryData(
  { device, cate, vdom, fields, model, version },
  abortCtrl
) {
  let _device = device ? `device/${device}` : '',
    _vdom = vdom === 'global' ? vdom : `vdom/${vdom}`,
    _cate = cate.replace(/ +/g, '/');
  if (!_device && model) {
    const [ver, mr] = version.split('.');
    _device = `devicetemplate/${model}/version/${ver * 100}/mr/${mr}`;
  }
  const url = `/pm/config/${_device}/${_vdom}/${_cate}`;

  const request = {
    method: 'get',
    params: [
      {
        url: url,
      },
    ],
  };
  if (fields) {
    request.params[0].fields = fields;
  }

  return (
    fiFmgHttp
      .forward(request, abortCtrl)
      .then(function (resp) {
        let data = [];
        try {
          data = resp[0].data;
          // eslint-disable-next-line
        } catch (err) {}
        return data;
      })
      // eslint-disable-next-line
      .catch(function (err) {
        return []; //need to return empty dataset
      })
  );
}
/**
 * fetch device all vdoms incldues vdom is not in current adom
 */
function getDevAllVdoms(deviceName) {
  return getCategoryData({
    device: deviceName,
    vdom: 'global',
    cate: 'system vdom-property',
  });
}

function getDvmServiceData(resp) {
  if (resp && resp[0] && resp[0].data) {
    if (Array.isArray(resp[0].data)) {
      return {
        records: resp[0].data || [],
        timestamp: resp[0].timestamp,
      };
    } else if (resp[0].data.records) {
      return resp[0].data;
    }
  }
  return {
    records: [],
    timestamp: 0,
  };
}

function extractErrorMsg(error) {
  try {
    if (error.errors && Array.isArray(error)) {
      return error.errors[0].status;
    }
    if (error.data.result[0]) {
      return error.data.result[0].status;
    }
  } catch (e) {
    // eslint-disable-line no-unused-vars
  }
  return '';
}

function getDevGrpByJSON(adom, groupName) {
  const adomName = adom.name || adom;
  let reqUrl = '/dvmdb/adom/' + escapeSlash(adomName) + '/group';
  if (!isUndefined(groupName)) {
    reqUrl += '/';
    reqUrl += groupName;
  }

  const req = {
    id: 1,
    method: 'get',
    params: [
      {
        url: reqUrl,
        option: ['object member', 'get meta'],
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (err) {
        reject(extractErrorMsg(err));
      }
    );
  });
}

/**
 * Gets chassis groups of the chassis Adom
 * @return {Promise}
 */
function getChassisGrp() {
  return new Promise((resolve, reject) => {
    const adom = fiAdom.current();
    if (!adom.is_others) {
      resolve([]);
    }

    const req = {
      method: 'getlist',
      url: `/gui/adoms/${adom.oid}/dvm/chassis`,
    };

    fiFmgHttp.post(req).then(
      (resp) => {
        resolve(resp[0] ? resp[0].data : []);
      },
      (resp) => {
        reject(resp);
      }
    );
  });
}

function getRevsionAndScheduleObj(revision, schedule, desc) {
  let pkg_inst_req = {};

  if (revision && revision.enable) {
    pkg_inst_req = merge(pkg_inst_req, {
      //REVISION
      revision_desc: revision.desc,
      revision_enable: revision.enable ? 1 : 0,
      instType: INSTALL_DEV_IMMEDIATELY,
    });
  }

  if (revision) {
    pkg_inst_req['revision_name'] = revision.name; //'pkg_001_2015-11-7-10-49-28',
  }

  if (desc) {
    pkg_inst_req['desc'] = desc;
  }

  if (schedule && schedule.enable) {
    pkg_inst_req = merge(pkg_inst_req, {
      //SCHEDULE
      instDate: schedule.instDate, //'11/7/2015',
      instTime: schedule.instTime, //'10:11:00',
      instType: INSTALL_DEV_SCHEDULE,
    });
  }
  return pkg_inst_req;
}

/**
 * Update device desc
 * @param {Array} devIds - IDs of the devices which need to be installed
 * @return {Promise}
 */
function updateDeviceDesc(adom, deviceName, name, desc) {
  let reqUrl =
    '/dvmdb/adom/' + escapeSlash(adom) + '/device/' + escapeSlash(deviceName);

  if (name) {
    reqUrl += '/vdom/' + name;
  }

  return new Promise((resolve, reject) => {
    fiHttpPost(MACROS.USER.DEF.URL_FLATUI_API, {
      method: 'get',
      params: [
        {
          url: reqUrl,
        },
      ],
    }).then(
      function (resp) {
        const newData = resp.data.result[0].data;
        if (!name) {
          newData.desc = desc;
        } else {
          newData.comment = desc;
          newData.comments = desc;
        }
        fiHttpPost(MACROS.USER.DEF.URL_FLATUI_API, {
          method: 'update',
          params: [
            {
              url: reqUrl,
              data: newData,
            },
          ],
        }).then(
          function (resp) {
            resolve(resp.data.result[0].data);
          },
          function (resp) {
            reject(resp);
          }
        );
      },
      function (resp) {
        reject(resp);
      }
    );
  });
}

/**
 * Make preview or Installs policy and objects
 * @param {Array} devIds - an array of objects that has two fields: did and vid
 * @param {Integer|String} pkgId - the policy package ID
 * @param {installAction} used for telling install PKG == 0 or interface Policy only == 2
 * @param revision revision option
 * @param schedule schedule option
 * @param desc install comment
 * @return {Promise}
 */
function makePlcNobjInstlParameter(
  devIds,
  pkgId,
  installAction,
  revision,
  schedule,
  desc,
  flag
) {
  const instlDevIds = [];
  devIds.forEach(function (devId) {
    let devIdStr = '' + devId.did;
    // Install policy package, install device formate is 'did-vid', if device no vdom, use 0 for vid
    if (!isUndefined(devId.vid)) {
      devIdStr += '-' + devId.vid;
    } else {
      devIdStr += '-0';
    }
    instlDevIds.push(devIdStr);
  });
  const extra = getRevsionAndScheduleObj(revision, schedule);

  return {
    pkgOid: pkgId,
    installDevIds: instlDevIds.toString(),
    desc: desc,
    flags: flag ? flag : 0,
    ...extra,
  };
}

function getDevByDevId(deviceId) {
  return new Promise((resolve, reject) => {
    fiFmgHttp
      .post({
        method: 'get',
        url: '/gui/adom/dvm/device',
        params: {
          deviceId: parseInt(deviceId, 10),
          deviceAttrs: ['all'],
          vdomAttrs: ['all'],
        },
      })
      .then(
        function (resp) {
          resolve(resp[0].data);
        },
        function (err) {
          reject(err);
        }
      );
  });
}

/**
 * Prepares to install policy packages
 * @param {Array} devIds - an array of objects that has two fields: did and vid
 * @param {Integer|String} pkgId - the policy package ID
 * @return {Promise}
 */
function prepPkgInstl(
  tid,
  devIds,
  pkgId,
  instlAction,
  revision,
  schedule,
  desc,
  flag
) {
  const req = {
    id: tid,
    url: '/gui/adom/installation/pkg/install',
    method: 'processPreview',
    params: makePlcNobjInstlParameter(
      devIds,
      pkgId,
      instlAction,
      revision,
      schedule,
      desc,
      flag
    ),
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      (resp) => {
        resolve(resp[0] ? resp[0].data : []);
      },
      (resp) => {
        reject(resp);
      }
    );
  });
}

function addStorageQuotaToDev(devlistresp) {
  if (
    devlistresp &&
    devlistresp.usage &&
    Array.isArray(devlistresp.usage.data.dev_log_sz)
  ) {
    let devobj, strobj, vdobj, i, j, k;

    if (devlistresp.usage) {
      for (i = 0; i < devlistresp.usage.data.dev_log_sz.length; i++) {
        strobj = devlistresp.usage.data.dev_log_sz[i];
        for (j = 0; j < devlistresp.records.length; j++) {
          devobj = devlistresp.records[j];
          if (devobj.sn === strobj.devid) {
            if (devobj.vdoms) {
              for (k = 0; k < devobj.vdoms.length; k++) {
                vdobj = devobj.vdoms[k];
                if (vdobj.name === strobj.vd) {
                  vdobj._usage = strobj;
                }
              }
            } else {
              devobj._usage = strobj;
            }
          }
        }
      }
    }
  }
}

/**
     * Gets the device list by the given group ID
     * @param {String | Number} groupId - the id of the device group, can be number or string
     * @param {Object} rAdom - the object of the current ADOM
     * @param {bool} nestGroup - true:results contains nestGroup and device
     *                           false:device only.(default)
     * @param {bool} nestGrpDev - only become effective if nestGroup is true
                                  true: results contains devices (including nestGroups' devices)
     * @return {Promise}
     */
function getDevListByGrp(groupId, rAdom, nestGroup, nestGrpDev) {
  const newpost = {
    data: {
      method: 'get',
      url: '/gui/adom/dvm/group/devices-csf',
      params: {
        deviceAttrs: ['all'],
        vdomAttrs: ['all'],
        nestGroup: nestGroup ? 1 : 0,
        nestGrpDev: nestGrpDev ? 1 : 0,
      },
    },
  };

  return new Promise((resolve, reject) => {
    groupId = parseInt(groupId) || -1;
    newpost.data.params.oid = groupId;

    fiFmgHttp.post(newpost.data).then(
      function (resp) {
        const data = getDvmServiceData(resp);
        addStorageQuotaToDev(data);
        resolve(data);
      },
      function (err) {
        reject(err);
      }
    );
  });
}

function discoverDev(adomName, adm_usr, adm_pass, ip, cluster_worker = null) {
  const discoverReq = {
    method: 'exec',
    params: [
      {
        url: 'dvm/cmd/discover/device',
        data: {
          adom: adomName,
          device: {
            adm_pass: adm_pass,
            adm_usr: adm_usr,
            ip: ip,
            cluster_worker: cluster_worker,
          },
        },
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.query(discoverReq).then(
      function (resp) {
        resolve(resp[0].data.device);
      },
      function (err) {
        reject(err.data.result[0].status);
      }
    );
  });
}

/**
 * Gets a device Information
 * @param {String} devName - device name
 * @return {Promise}
 */
function getDevInfo(devName) {
  const req = {
    method: 'get',
    params: [
      {
        url:
          '/dvmdb/adom/' +
          fiAdom.current().name +
          '/device/' +
          escapeSlash(devName),
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (err) {
        reject(err.data.result[0].status);
      }
    );
  });
}

/**
 * Gets policy packages list
 * notice, this api does not support global ADOM
 * @param {String} adom - the ADOM
 * @param {AbortController} abortCtrl
 * @return {Promise}
 */
function getPkgList(adom, abortCtrl) {
  const adomName = isObject(adom) ? adom.name : adom;
  let reqUrl = '';

  if (isObject(adom) && adom.globaldb) {
    reqUrl = 'pm/pkg/global';
  } else {
    reqUrl = 'pm/pkg/adom/' + escapeSlash(adomName);
  }

  const req = {
    method: 'get',
    params: [
      {
        url: reqUrl,
        fields: ['oid', 'type', 'scope member'],
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.query(req, abortCtrl).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (resp) {
        reject(extractErrorMsg(resp));
      }
    );
  });
}

/**
 * Gets policy block list
 * notice, this api does not support global ADOM
 * @param {String} adom - the ADOM
 * @param {String} pkgPath - if specified, we ge the INFO of this specific pkg...
 * @return {Promise}
 */
function getPBlockList(adom, pkgPath) {
  const adomName = isObject(adom) ? adom.name : adom;
  let reqUrl = '';

  if (isObject(adom) && adom.globaldb) {
    reqUrl = 'pm/pblock/global';
  } else {
    reqUrl = 'pm/pblock/adom/' + escapeSlash(adomName);
  }

  if (pkgPath) {
    reqUrl += '/' + pkgPath;
  }

  const req = {
    method: 'get',
    params: [
      {
        url: reqUrl,
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.query(req).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (resp) {
        reject(extractErrorMsg(resp));
      }
    );
  });
}

function countPolicyBlock(adom) {
  const adomName = isObject(adom) ? adom.name : adom;
  let reqUrl = '';

  if (isObject(adom) && adom.globaldb) {
    reqUrl = 'pm/pblock/global';
  } else {
    reqUrl = 'pm/pblock/adom/' + escapeSlash(adomName);
  }

  const req = {
    method: 'get',
    params: [
      {
        url: reqUrl,
        option: 'count',
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.query(req).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (resp) {
        reject(extractErrorMsg(resp));
      }
    );
  });
}

/**
 * Clone a profile devprof/ecprof/crprof/wanprof
 * @param {Object} data - the payload, like {name: 'cloneName'}
 */
function clonePkg({ adom, pkgType, srcName, data }) {
  const adomName = adom.name || adom;
  const req = {
    method: 'clone',
    params: [
      {
        url: `pm/${pkgType}/adom/${escapeSlashFull(adomName)}/${srcName}`,
        data: data,
      },
    ],
  };
  return fiFmgHttp.query(req).then(function (resp) {
    return resp[0].data;
  });
}

/**
 * Install dev config on the fly
 * @param {Array} devIds - an array of objects that has two fields: did and vid
 * @return {Promise}
 */
function execCfgInstl(devIds) {
  //for dvm -> quick install
  const instlDevIds = [];
  devIds.forEach(function (devId) {
    let devIdStr = '' + devId.did;
    // Install device setting, install device formate is 'did-vid', if device no vdom, use 1 means global
    if (!isUndefined(devId.vid)) {
      devIdStr += '-' + devId.vid;
    } else {
      devIdStr += '-1';
    }
    instlDevIds.push(devIdStr);
  });

  const req = {
    url: '/gui/adom/installation/config',
    method: 'processInstall',
    params: {
      installDevIds: instlDevIds.toString(),
    },
  };
  return new Promise((resolve, reject) => {
    fiFmgHttp.post(req).then(
      (resp) => {
        resolve(resp[0] ? resp[0].data : []);
      },
      (resp) => {
        reject(resp);
      }
    );
  });
}

function _getPkgOid(pkg) {
  const thePkg = Array.isArray(pkg) ? pkg[0] : pkg;
  return thePkg.oid || thePkg;
}

function checkPKGSync(pkg) {
  return new Promise((resolve, reject) => {
    fiFmgHttp
      .post({
        url: '/gui/adom/pkg/scope',
        method: 'getSyncStatus',
        params: {
          pkgOid: _getPkgOid(pkg),
        },
      })
      .then(
        function (resp) {
          if (resp && resp[0]) {
            resolve(resp[0].data);
          } else {
            resolve({});
          }
        },
        function (err) {
          reject(err);
        }
      );
  });
}

function checkPkgQinstall(pkg) {
  return new Promise((resolve, reject) => {
    return fiFmgHttp
      .post({
        url: '/gui/adom/pkg/scope',
        method: 'getHasMember',
        params: {
          pkgOid: _getPkgOid(pkg),
        },
      })
      .then(
        function (resp) {
          if (resp && resp[0] && resp[0].data && resp[0].data.status === 'ok') {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        function () {
          reject();
        }
      );
  });
}

function getPkgScope(pkg) {
  return new Promise((resolve, reject) => {
    return fiFmgHttp
      .post({
        url: '/gui/adom/pkg/scope',
        method: 'get',
        params: {
          pkgOid: _getPkgOid(pkg),
        },
      })
      .then(
        function (resp) {
          if (resp && resp[0]) {
            resolve(resp[0].data);
          } else {
            resolve({});
          }
        },
        function (err) {
          reject(err);
        }
      );
  });
}

/**
 * ******************************   install wizard ***************************
 */

function instDevDevList() {
  return new Promise((resolve, reject) => {
    fiFmgHttp
      .post({
        url: '/gui/adom/dvm/device_group/modified',
        method: 'get',
      })
      .then(function (resp) {
        resolve(resp[0].data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

/**
 * Gets all supported firmware versions
 */
function getFwVers(devType, adomOid) {
  adomOid = adomOid || fiAdom.current().oid;

  return new Promise(function (resolve, reject) {
    fiFmgHttp
      .post({
        method: 'get',
        url: '/gui/adom/dvm/device/firmware-vers',
        params: {
          devType: devType,
          adomOid: adomOid,
        },
      })
      .then(
        function (resp) {
          const data = resp?.[0].data || {};
          resolve(data);
        },
        function (rsp) {
          reject(rsp);
        }
      );
  });
}

//http://localhost:8008//cgi-bin/module/devicemanager/frame/AdomStorageInfoList?action=get_adomlist

function getAdomStorageInfo() {
  return new Promise(function (resolve, reject) {
    fiHttpGet('/cgi-bin/module/devicemanager/frame/AdomStorageInfoList', {
      params: {
        action: 'get_adomlist',
      },
    }).then(
      function (data) {
        resolve(data);
      },
      function (resp) {
        reject(resp);
      }
    );
  });
}

function getAdomInfo(adomName) {
  return new Promise((resolve, reject) => {
    fiHttpGet(MACROS.USER.DEF.URL_DVM_API, {
      params: {
        req: {
          method: 'get',
          //'option': 'object member', //Get VDOM members
          params: [
            {
              url: '/dvmdb/adom/' + escapeSlash(adomName),
            },
          ],
        },
      },
    }).then(
      function (resp) {
        resolve(resp.data.result[0].data);
      },
      function (resp) {
        reject(extractErrorMsg(resp));
      }
    );
  });
}

/**
 * Gets build and branchpt by the given dev info
 * @param os_type  e.g 4 ,NOT 'faz'
 * @param platform_str
 * @param os_ver  e.g. 500
 * @param mr e.g. 0 or 2 or 4
 * @returns {*}
 */
function getBuildAndBranchpt(os_type, platform_str, os_ver, mr) {
  return new Promise(function (resolve, reject) {
    fiFmgHttp
      .post({
        method: 'get',
        url: '/gui/adom/dvm/device/build-branchpt',
        params: {
          os_type: os_type,
          platform_str: platform_str,
          os_ver: os_ver,
          mr: mr,
        },
      })
      .then(
        function (resp) {
          const data = resp?.[0].data || [];
          resolve(data);
        },
        function (rsp) {
          reject(rsp);
        }
      );
  });
}

/**
 * Gets models by the given SN
 */
function getModelsBySN(kt, sn, pk) {
  return new Promise(function (resolve, reject) {
    fiFmgHttp
      .post({
        method: 'get',
        url: '/gui/adom/dvm/device/model-by-sn',
        params: {
          sn: kt === MACROS.USER.DVM.KTYPE_SN ? sn : pk,
        },
      })
      .then(
        function (resp) {
          const data = resp?.[0].data || [];
          resolve(data);
        },
        function (rsp) {
          reject(rsp);
        }
      );
  });
}

/**
 * Gets models by the given dev hw versions
 * @param devType
 * @param devVer
 * @param devMr
 * @param usePreviousMr get models for 1 minor revision version under devMr (default true)
 * @returns {*}
 */
function getModelsByVersions(
  devType,
  devVer,
  devMr,
  usePreviousMr = true,
  abortCtrl
) {
  return new Promise(function (resolve, reject) {
    fiFmgHttp
      .post(
        {
          method: 'get',
          url: '/gui/adom/dvm/device/model-by-ver',
          params: {
            devType: devType,
            devVer: devVer,
            devMr: devMr,
            usePreviousMr: usePreviousMr,
          },
        },
        abortCtrl
      )
      .then(
        function (resp) {
          const data = resp?.[0].data || {};
          resolve(data);
        },
        function (rsp) {
          reject(rsp);
        }
      );
  });
}

/**
 * Get Conflict msg from resp
 *
 * Currently used to check IP-conflict....
 *
 * @param arrDevInfo  JSON response from dvmdb request.
 * @param devName  filter by device name
 * @param vdomName filter by vdom name.
 * @returns {Obj}  Object msg...
 *
 */
function getDvmdbDeviceConflictInfo(arrDevInfo, devName, vdomName) {
  const STR_IP_CONFLICT = 'ip-conflict';
  let tmpVdom;
  let devcount = 0;
  let ippaircount = 0;
  let msgarr = [];
  if (Array.isArray(arrDevInfo)) {
    for (let di = 0; di < arrDevInfo.length; di++) {
      if (!devName || arrDevInfo[di].name === devName) {
        const vdomArr = arrDevInfo[di].vdom;
        if (Array.isArray(vdomArr)) {
          const devIpConflicts = [];
          for (let vi = 0; vi < vdomArr.length; vi++) {
            tmpVdom = vdomArr[vi];
            if (!vdomName || tmpVdom.name === vdomName) {
              if (
                tmpVdom.flags === STR_IP_CONFLICT ||
                (Array.isArray(tmpVdom.flags) &&
                  tmpVdom.flags.indexOf(STR_IP_CONFLICT) >= 0)
              ) {
                try {
                  const arrConflictPair = JSON.parse(tmpVdom.status)[
                    STR_IP_CONFLICT
                  ];
                  const staticIp = gettext('IP address on the interface');
                  const dhcpIp = gettext(
                    'DHCP address received by the interface'
                  );
                  const warningTemplate = gettext(
                    "The %s '%s' conflicts with the %s '%s'."
                  );
                  var str1, str2, str3, str4;
                  if (Array.isArray(arrConflictPair)) {
                    ippaircount += arrConflictPair.length;
                    for (let ci = 0; ci < arrConflictPair.length; ci++) {
                      if (
                        Array.isArray(arrConflictPair[ci]) &&
                        arrConflictPair[ci].length === 2
                      ) {
                        str1 =
                          arrConflictPair[ci][0].mode === 'dhcp'
                            ? dhcpIp
                            : staticIp;
                        str2 = arrConflictPair[ci][0].port;
                        str3 =
                          arrConflictPair[ci][1].mode === 'dhcp'
                            ? dhcpIp
                            : staticIp;
                        str4 = arrConflictPair[ci][1].port;
                        devIpConflicts.push(
                          warningTemplate.printf([str1, str2, str3, str4])
                        );
                      }
                    }
                  }
                } catch (e) {
                  //
                }
              }
            }
          }

          if (devIpConflicts.length > 0) {
            devcount++;
            if (!devName) {
              msgarr.push(
                gettext("In device '%s':").printf([arrDevInfo[di].name])
              );
            }
            msgarr = msgarr.concat(devIpConflicts);
          }
        }
      }
    }
    return {
      msgarr: msgarr,
      devcount: devcount,
      ippaircount: ippaircount,
      arrDevInfo: arrDevInfo,
    };
  }
}

/**
 * Get the managed device count under all adoms
 *
 * Currently used to check faz_mng_license when fmg_mode is enabled
 *
 * @returns {*}
 */
function getManagedDeviceNumber() {
  return fiFmgHttp
    .post({
      url: '/gui/alladom/managed-device-count',
      method: 'get',
    })
    .then((resp) => {
      const result = resp?.result?.[0];
      if (result && (result.status.code == 0 || result.status.code == 200)) {
        return result.data;
      } else {
        throw (
          result?.status?.message || 'error when query managed device count'
        );
      }
    });
}

function getDeviceVdoms(dev) {
  // this api will return all vdoms
  // even the vdom in other adom, (when it is on adom advanced mode)
  return fiFmgHttp
    .forward({
      method: 'get',
      params: [
        {
          url: `/dvmdb/device/${dev.name}`,
          fields: ['name', 'vdom', 'os_type', 'ip', 'sn', 'flags'],
        },
      ],
    })
    .then(
      (resp) => {
        const data = get(resp, ['0', 'data']) || [];
        return data.flags & MACROS.DVM.DVM_DEV_FLAG_VDOM_ENABLED
          ? data.vdom
          : [];
      },
      () => []
    );
}

/* get all devices for all ADOMs, except devices in csf groups*/
function _getAllAdomsDevicesNonCsf() {
  const alldevsPromise = fiFmgHttp.post({
    url: '/gui/alladom/dvm/devices/base',
    method: 'get',
  });

  return Promise.all([alldevsPromise, _getCsfGroups()]).then(function ([
    _alldevs,
    csfgrps,
  ]) {
    const alldevs = _alldevs?.[0] ? _alldevs[0].data : {};
    const csfs = csfgrps;
    for (const adom in alldevs) {
      if (alldevs[adom].is_autosync) {
        delete alldevs[adom];
        continue;
      }
      const members = alldevs[adom].members;
      if (members) {
        const _members = [];
        for (let i = 0; i < members.length; i++) {
          if (!csfs.memberIds.has(members[i].oid)) {
            _members.push(members[i]);
          }
        }
        alldevs[adom].members = _members;
      }
    }
    return alldevs;
  });
}
/* get csf groups of the current ADOM*/
function _getCsfGroups() {
  const reqObj = {
    url: '/gui/adom/dvm/csf/group',
    method: 'get',
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    const _csf = {
      _groups: resp[0].data || {}, // raw data from the server
      grpNameByDeviceId: {},
      memberIds: new Set(),
      rootIds: new Set(),
    };
    (
      (_csf._groups && !isEmpty(_csf._groups) && Object.values(_csf._groups)) ||
      []
    ).forEach(function (grp) {
      (grp.memberIds || []).forEach(function (id) {
        _csf.memberIds.add(id);
        _csf.grpNameByDeviceId[id] = grp.name;
      });
      _csf.rootIds.add(grp.rootId);
    });
    return _csf;
  });
}
/* delete a csf group of the current ADOM*/
function _delCsfGroup(csfGroupName) {
  const reqObj = {
    url: '/gui/adom/dvm/csf/group',
    method: 'del',
    params: {
      csfGroupName: csfGroupName,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp && resp[0] ? resp[0].data : [];
  });
}

/**
 * Get devices ip conflict
 * filter by deviceName and vdomName
 * if deviceName and vdomName undefined ,get conflict in deviceNames
 * @returns {*}
 */

function requestDevicesIPConflict(deviceNames) {
  let devices = deviceNames;
  if (!Array.isArray(deviceNames)) devices = [deviceNames];

  return new Promise((resolve, reject) => {
    fiFmgHttp
      .post({
        id: 1,
        url: '/gui/dvm/devices/ip_conflict',
        method: 'get',
        params: {
          deviceNames: devices || [],
        },
      })
      .then(
        function (resp) {
          resolve(resp);
        },
        function (err) {
          reject(err);
        }
      );
  });
}

function getUserDeviceGroups() {
  return new Promise(function (resolve) {
    fiFmgHttp
      .forward({
        method: 'get',
        params: [
          {
            url:
              'pm/config/adom/' +
              fiAdom.current().name +
              '/obj/user/device-group',
            option: [
              'get used',
              'get flags',
              'get devobj mapping',
              'get meta',
              'extra info',
            ],
          },
        ],
      })
      .then(
        function (resp) {
          resolve(resp[0].data || []);
        },
        function () {
          resolve([]);
        }
      );
  });
}
/**
 * Fetches data from device groups (policy use)
 * @return {Promise}
 */
function importUserDevice(postData, adom) {
  return new Promise((resolve, reject) => {
    fiFmgHttp
      .query({
        method: 'add',
        params: [
          {
            url: `pm/config/adom/${adom}/obj/user/device`,
            data: postData,
          },
        ],
      })
      .then(
        function (resp) {
          resolve(resp[0].data || []);
        },
        function (e) {
          try {
            reject(e.data.result[0].status.message);
          } catch (e) {
            e.message;
          }
        }
      );
  });
}

function addToUserDeviceGroup(groups, adom) {
  return new Promise((resolve, reject) => {
    const _parameters = groups.map((g) => {
      return {
        url: `pm/config/adom/${adom}/obj/user/device-group/${g.id}`,
        data: { name: g.id, member: g.member },
      };
    });
    fiFmgHttp
      .query({
        method: 'set',
        params: _parameters,
      })
      .then(
        function (resp) {
          resolve(resp[0].data || []);
        },
        function (e) {
          try {
            reject(e.data.result[0].status.message);
          } catch (e) {
            e.message;
          }
        }
      );
  });
}

function getDeviceIPConflictInfo(ipConflictInfo) {
  const staticIp = gettext('IP address on the interface');
  const dhcpIp = gettext('DHCP address received by the interface');
  const warningTemplate = gettext("The %s '%s' conflicts with the %s '%s'.");

  const data = ipConflictInfo;

  let msgarr = [];

  data.forEach(function (elem) {
    if (!elem['ip-conflict']) return;
    const devIpConflicts = [];
    const name = elem['deviceName'];

    elem['ip-conflict'].forEach(function (obj) {
      if (obj.length != 2) return;

      const str1 = obj[0].mode === 'dhcp' ? dhcpIp : staticIp;
      const str2 = obj[0].port;
      const str3 = obj[1].mode === 'dhcp' ? dhcpIp : staticIp;
      const str4 = obj[1].port;

      devIpConflicts.push(warningTemplate.printf([str1, str2, str3, str4]));
    });

    if (devIpConflicts.length === 0) return;

    if (!name) {
      msgarr.push(gettext("In device '%s':").printf([name]));
    }
    msgarr = msgarr.concat(devIpConflicts);
  });
  return {
    msgarr: msgarr,
  };
}

function getDevicesIPConflict(deviceNames) {
  return new Promise((resolve, reject) => {
    requestDevicesIPConflict(deviceNames).then(
      function (resp) {
        const ipConflictInfo = resp[0].data;
        resolve({
          getMessageArr: function () {
            const msgobj = getDeviceIPConflictInfo(ipConflictInfo);
            return msgobj ? msgobj.msgarr : null;
          },
        });
      },
      function (err) {
        reject(err[0].status);
      }
    );
  });
}
