import { fiSession, fiStore, fiStoreFabric } from 'fistore';
import { negate, overSome, overEvery } from 'lodash';
const {
  inAdom,
  inGlobalAdom,
  adomNames,
  hasRDPermitOn,
  hasFazFeature,
  hasFmgFeature,
  isFmg,
  isFaz,
  getIsFazSupervisor,
  hasCsfGroups,
  inManageableAdoms,
} = fiSession;

const mrPRIV = MACROS.USER.DVM;

const getShowConfig = overSome([
  overEvery([isFaz, hasFmgFeature]),
  overEvery([isFmg, hasCsfGroups]),
]);

const getShowSecurityRating = overSome([
  overEvery([isFaz, hasFmgFeature]),
  isFmg,
]);

export default {
  fabric: {
    label: gettext('Fabric View'),
    icon: 'fabric-view',
    color: '#5fa3d9',
    state: 'adom.default.fabric',
    reloadState: 'adom.default.fabric',
    imgType: 'FMG',
    showInFazSupervisor: false,
    showCheckers: [
      inManageableAdoms,
      negate(inAdom([adomNames.FortiNAC])),
      negate(inGlobalAdom),
      negate(getIsFazSupervisor),
      hasRDPermitOn(mrPRIV.ADMINPRIV_FABRIC_VIEW), // for profile permission
    ],
  },
  fabric_physicaltopo: {
    path: '/fabric/physical',
    label: gettext('Physical Topology'),
    state: 'adom.default.fabric.physical',
    showCheckers: [getShowConfig],
  },
  fabric_logicaltopo: {
    path: '/fabric/logical',
    label: gettext('Logical Topology'),
    state: 'adom.default.fabric.logical',
    showCheckers: [getShowConfig],
  },
  fabric_audit: {
    path: '/fabric/audit',
    label: gettext('Security Rating'),
    state: 'adom.default.fabric.audit',
    showCheckers: [getShowSecurityRating],
  },
  fabric_cloudorchestration: {
    label: gettext('Cloud Orchestration'),
    state: 'adom.default.fabric.cloud_orchestration',
    showCheckers: [isFmg],
  },
  fabric_cloudorchestration_main: {
    path: '/fabric/cloud_orchestration/main',
    label: gettext('Cloud Orchestration'),
    state: 'adom.default.fabric.cloud_orchestration.main',
  },
  fabric_cloudorchestration_conn: {
    path: '/fabric/cloud_orchestration/connector',
    label: gettext('Cloud Connectors'),
    state: 'adom.default.fabric.cloud_orchestration.conn',
  },
  fabric_cloudorchestration_templates: {
    path: '/fabric/cloud_orchestration/templates',
    label: gettext('Cloud Deployment Templates'),
    state: 'adom.default.fabric.cloud_orchestration.templates',
  },
  fabric_config_connectors: {
    path: '/fabric/conf/connector',
    label: gettext('Fabric Connectors'),
    // icon: 'security-fabric',
    state: 'adom.default.fabric.config.connector',
    showCheckers: [isFmg],
  },
  fabric_config_extconnectors: {
    path: '/fabric/conf/external_connector',
    label: gettext('External Connectors'),
    // icon: 'security-fabric',
    state: 'adom.default.fabric.config.external_connector',
    showCheckers: [isFmg],
  },
  fabric_config_subnets: {
    path: '/fabric/conf/subnet',
    label: gettext('Subnets'),
    // icon: 'network',
    state: 'adom.default.fabric.config.subnets',
    showCheckers: [hasFazFeature],
  },
  fabric_asset: {
    path: '/fabric/asset-index',
    label: gettext('Asset Identity Center'),
    state: 'adom.default.fabric.asset',
    showCheckers: [hasFazFeature],
    loadSubTree: async () => {
      return fiStore.dispatch(fiStoreFabric.fetchFabricCustomMenu());
    },
  },
  fabric_asset_dashboard: {
    path: '/fabric/asset-index/asset-dashboard',
    label: gettext('Summary'),
    state: 'adom.default.fabric.asset.dashboard',
  },
  fabric_asset_view: {
    path: '/fabric/asset-index/view',
    label: gettext('Asset Identity List'),
    state: 'adom.default.fabric.asset.view',
  },
  fabric_asset_custom: {
    label: gettext('Custom View'),
  },
  fabric_asset_custom_asset: {
    label: gettext('Asset'),
  },
  fabric_asset_custom_asset_ot: {
    label: gettext('Asset') + ' (OT)',
  },
  fabric_asset_custom_identity: {
    label: gettext('Identity'),
  },
};
