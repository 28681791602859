//eslint-disable-next-line
import React, { useCallback, useRef, useState } from 'react';

import { convertRemToPx } from 'fiutil';
import { openConfirmModal } from 'rc_layout';
import { fiFmgHttp } from 'fi-http';
import {
  treeWrap,
  makeAsyncTree,
} from 'react_components/rc_dvm_nav/dvm_nav/nav_tree';
import { DefaultTreeRow } from 'react_components/rc_dvm_nav/tree/tree_row';
import { genv4 } from 'fi-uuid';
import { fiSysConfig } from 'fi-session';
import { getTreeKey } from './util';
import {
  ACTION_CREATE,
  ACTION_EDIT,
  ACTION_MOVE,
  EditFolderModal,
} from './modals/EditFolder';
import { ProToolkit } from '@fafm/neowise-pro';
import { NwButton, NwIcon } from '@fafm/neowise-core';

import { dispatch } from 'fistore';
import { go } from 'fistore/routing/slice';

import { useWidthResizer, useLocalStorage } from 'rh_util_hooks';
import { fiDvmActionsId } from 'fi-actions';
import { fiDvmBtnStatus } from '../device_commands/dvm_button_status';
import { useSelector } from 'react-redux';
import { getSessionAdomData } from 'fistore/session/adom/selectors';

const MIN_WIDTH = convertRemToPx(16.5);
const MAX_WIDTH = window.innerWidth / 2; // 50vw
const scrollbarWidth = 2;

const nodeCompare = (a, b) => (a.name > b.name ? 1 : -1);
const Tree = treeWrap(makeAsyncTree(DefaultTreeRow), {
  sortDir: 1,
  hasSearchSort: true,
  sortTreeNodes: (treeNodes, isSortAsc) => {
    const sortNode = (a, b) =>
      isSortAsc ? nodeCompare(a, b) : nodeCompare(b, a);
    return treeNodes.sort(sortNode);
  },
});

const updateCommandStatus = (cmds, selectedRows) => {
  const sysConfig = fiSysConfig.current();

  for (const opt of cmds) {
    const btnConfig = fiDvmBtnStatus[opt.key];
    if (btnConfig) {
      const btn = btnConfig(sysConfig, opt);
      btn.process(selectedRows);
      opt.disabled = btn.cmd.disabled || opt.getDisabled(selectedRows[0]);
    }
  }

  return cmds;
};

export const FolderTree = ({
  rawFolder,
  reload,
  folderTree,
  selectedFolderPath: selectedPath,
  setSelectedFolderPath,
}) => {
  const folderTreeRef = useRef();
  const [width, setWidth] = useState(MIN_WIDTH);
  const [height, setHeight] = useState(928);

  ProToolkit.useResizeObserver(folderTreeRef, (entry) => {
    if (!entry) return;
    const rect = entry.contentRect;
    setWidth(Math.max(rect?.width || 0, MIN_WIDTH));
    setHeight(Math.max(rect?.height || 0));
  });

  const [storedWidth, setStoredWidth] = useLocalStorage(
    'dvm-folder-view-tree',
    MIN_WIDTH
  );
  const { size: currentWidth, renderResizeHandle } = useWidthResizer(
    folderTreeRef,
    {
      initSize: Math.max(storedWidth, MIN_WIDTH),
      min: MIN_WIDTH,
      max: MAX_WIDTH,
      onDragStop: (e, newWidth) => {
        if (newWidth) {
          e.preventDefault();
          e.stopPropagation();
          setStoredWidth(newWidth);
        }
      },
    }
  );

  function treeNodeClick(newNode) {
    dispatch(
      go({
        to: '/dvm/main/folder',
        opts: {
          state: {
            folder: newNode.path,
          },
        },
      })
    );
    setSelectedFolderPath(newNode.path);
  }
  const fetchData = useCallback(() => {
    return folderTree;
  }, [folderTree]);

  const createFolder = useCallback(
    (node = {}) => {
      const modalInstance = ProToolkit.openModal(
        <EditFolderModal
          selectedOid={node?._data?.oid}
          requestObject={rawFolder}
          actionContext={{ action: ACTION_CREATE }}
        />,
        {
          size: 'lg',
        }
      );
      modalInstance.result.then(() => {
        reload();
      });
    },
    [rawFolder]
  );
  const adom = useSelector(getSessionAdomData);

  const getMenuOpts = useCallback(
    (node) => {
      const opts = [
        {
          key: fiDvmActionsId.folder.create,
          label: gettext('Create New Folder'),
          icon: 'ffg ffg-add',
          show: true,
          disabled: false,
          getDisabled: () => false,
          exec: createFolder,
        },
        {
          key: fiDvmActionsId.folder.edit,
          label: gettext('Edit'),
          icon: 'ffg ffg-edit',
          show: true,
          disabled: false,
          getDisabled: (node) => !node._data.pathName,
          exec: function (node = {}) {
            const modalInstance = ProToolkit.openModal(
              <EditFolderModal
                selectedOid={node._data.oid}
                requestObject={rawFolder}
                actionContext={{ action: ACTION_EDIT }}
              />,
              {
                size: 'lg',
              }
            );
            modalInstance.result.then(() => {
              reload();
            });
          },
        },
        {
          key: fiDvmActionsId.folder.del,
          label: gettext('Delete'),
          icon: 'ffg ffg-delete',
          show: true,
          disabled: false,
          getDisabled: (node) => !node._data.pathName,
          exec: function (node = {}) {
            const folder = node._data;
            openConfirmModal({
              content: gettext(
                'Are you sure you want to remove the folder "/%s"?'
              ).printf([folder.pathName.join('/')]),
              title: gettext('Remove Folder'),
            }).then(() => {
              fiFmgHttp
                .forward({
                  id: genv4(),
                  method: 'delete',
                  params: [
                    {
                      url: `/dvmdb/adom/${
                        adom.name
                      }/folder/${folder.pathName.join('/')}`,
                    },
                  ],
                })
                .then(() => {
                  reload();
                });
            });
          },
        },
        {
          key: fiDvmActionsId.folder.move,
          label: gettext('Move'),
          icon: 'ffg ffg-output-profile',
          show: true,
          disabled: false,
          getDisabled: (node) => !node._data.pathName,
          exec: function (node = {}) {
            const modalInstance = ProToolkit.openModal(
              <EditFolderModal
                selectedOid={node._data.oid}
                requestObject={rawFolder}
                actionContext={{ action: ACTION_MOVE }}
              />,
              {
                size: 'lg',
              }
            );
            modalInstance.result.then(() => {
              reload();
            });
          },
        },
      ];

      return updateCommandStatus(opts, [node]);
    },
    [createFolder, rawFolder, adom]
  );

  return (
    <div
      className='tw-relative tw-flex tw-h-full tw-border-t-0 tw-border-b-0 tw-border-l-0 tw-border-r tw-border-solid tw-border-neutral-300'
      style={{ width: `${currentWidth}px` }}
      ref={folderTreeRef}
    >
      <Tree
        id={'dvm-folder-view-tree'}
        fetchData={fetchData}
        getMenuOps={getMenuOpts}
        getAutomationId={(node) => `folder-tree-${node._data.id}`}
        itemSize={30}
        width={Math.max(width, currentWidth) - scrollbarWidth}
        onNodeClick={({ node }, reloadTree, params) =>
          treeNodeClick(node._data, params)
        }
        selected={
          selectedPath
            ? { key: getTreeKey(selectedPath), trigClick: false }
            : undefined
        }
        height={height}
        searchBoxSuffix={
          <NwButton className={'search-box-add'} onClick={createFolder}>
            <NwIcon name={'add'} library={'fafm'} />
          </NwButton>
        }
      />
      {renderResizeHandle()}
    </div>
  );
};
