import { widgets as nocWidgets } from '../consts/noc/widget-const';
import { widgets as ftvWidgets } from '../consts/ftv/widget-const';
import {
  Type_Top,
  Time_Periods,
  RefreshInterval,
  AutoplayInterval,
} from '../consts/soc-const.js';
import { fiStore, fiStoreSoc, fiSession } from 'fistore';

const getWidgetProps = (widget) => {
  return {
    ...widget,
    timePeriodType:
      widget.timePeriodType && Time_Periods[widget.timePeriodType]
        ? widget.timePeriodType
        : 'normal',
    typeTop: widget.components
      ? Object.keys(widget.components).reduce((res, type) => {
          return Type_Top[type] && !widget.noTop
            ? { ...res, [type]: Type_Top[type] }
            : res;
        }, {})
      : {},
    isCsf: widget.isCsf ? true : false,
    isDyConfig: widget.isDyConfig,
    isDynamic: widget.isDynamic,
    autoplayInterval: widget.autoplayType
      ? AutoplayInterval[widget.autoplayType]
      : null,
  };
};

export const getNocWidgetByName = (name) => {
  let widget = nocWidgets[name] || {};
  let props = getWidgetProps(widget);
  return {
    ...props,
    refreshInterval:
      RefreshInterval[widget.intervalType] || RefreshInterval['normal'],
  };
};

export const getFtvWidgetByName = (name) => {
  let widget = ftvWidgets[name] || {};
  let props = getWidgetProps(widget);
  return {
    ...props,
    isFtv: true,
    refreshInterval: RefreshInterval['disabled'],
  };
};

export const getDDwidget = () => {
  return ftvWidgets.drilldown || {};
};

export const getCustomWidgetProps = (uuid) => {
  const state = fiStore.getState();
  let widget = fiStoreSoc.customWidget.selectors.getCustomWidget(uuid)(state);

  if (widget) {
    let type = widget.config.chartType;
    return {
      ...widget,
      isDataset: true,
      // intervalType: 'normal',
      refreshInterval: RefreshInterval.normal,
      typeTop: Type_Top[widget.config.chartType] || {},
      timePeriodType: 'normal', // custom widget has its own time frame settings in future???
      widgetName: widget.uuid,
      type,
      components: {
        [type]: 'soc-custom-widget',
      },
      title: {
        text: widget.name,
        desc: widget.uuid,
      },
    };
  } else {
    return null;
  }
};

export const filterWidgetsByFeature = (widgets = []) => {
  const state = fiStore.getState();
  return (
    widgets.filter((widget) => {
      if (
        fiSession.getIsFazSupervisor(state) &&
        widget.name === 'fortiview_threatmap'
      ) {
        return false;
      }
      if (widget.adoms) {
        let adomType = fiSession.getSessionAdomData(state).type;
        return widget.adoms.includes(adomType);
      }
      return true;
    }) || []
  );
};
