// Generic tools like DFS, BFS, set intersection, etc.

import { assign } from 'lodash';

const SKIP_DESCENDANTS = {};
const SKIP_ALL = {};

export const fiAlgorithm = (function () {
  function makeDfs(getChildren) {
    function dfs(node, preFn, afterFn, par) {
      if (!Array.isArray(node)) {
        return;
      }
      var i, child;
      for (i = 0; i < node.length; i++) {
        child = node[i];
        var preResult = preFn && preFn(child, par);

        if (preResult !== SKIP_DESCENDANTS && preResult !== SKIP_ALL) {
          dfs(getChildren(child), preFn, afterFn, child);
        }
        if (preResult !== SKIP_ALL) {
          afterFn && afterFn(child, par);
        }
      }
    }
    return dfs;
  }
  function makeBfs(getChildren) {
    function bfs(node, visitFn) {
      if (!Array.isArray(node)) {
        return;
      }
      const queue = [].concat(node);
      var child;
      while (queue.length) {
        child = queue.shift();
        const preResult = visitFn && visitFn(child);
        if (preResult !== SKIP_DESCENDANTS && preResult !== SKIP_ALL) {
          const children = getChildren(child) || [];
          children.forEach((node) => {
            queue.push(node);
          });
        }
      }
    }
    return bfs;
  }

  // Using the "AnnotatedDFS" described by
  // http://www.cs.yale.edu/homes/aspnes/pinewiki/DepthFirstSearch.html
  // where we internally give each node a "clock start" and "clock end"
  // then any edge found to be visiting "back" means we have a cycle.
  function isDAG(startNodes, getChildren, getId) {
    let dfs = makeDfs(getChildren);
    let clock = 0;
    let discoveryTime = new Map();
    let finishTime = new Map();
    let edges = [];

    // First, simple dfs, we visit every node exactly once, and
    // annotate them with this particular walk through's "clock" record.
    dfs(
      startNodes,
      (node) => {
        let nodeId = getId(node);
        let children = getChildren(node) || [];
        children.forEach((child) => {
          // Not the parent -> node edge we
          // use in this particular traversal.
          // Here we collect __all__ possible edges:
          edges.push([nodeId, getId(child)]);
        });
        if (discoveryTime.get(nodeId) >= 0) {
          return SKIP_ALL;
        }
        discoveryTime.set(nodeId, clock);
        clock++;
      },
      (node) => {
        let nodeId = getId(node);
        // in post-visit function, record the end clock
        if (finishTime.get(nodeId) === undefined) {
          finishTime.set(nodeId, clock);
          clock++;
        }
      }
    );

    // Then, after the dfs we have the start/end clocks of each node in 1
    // particular traversal. Now we check all edges to see if anyone walks
    // "back up" against known order.
    let isCyclic = edges.some(([parentId, nodeId]) => {
      let parentStart = discoveryTime.get(parentId) || 0;
      let nodeStart = discoveryTime.get(nodeId) || 0;
      let parentEnd = finishTime.get(parentId) || 0;
      let nodeEnd = finishTime.get(nodeId) || 0;
      if (parentStart > nodeStart && parentEnd < nodeEnd) {
        // parend is a descendant of node, this is a back-edge.
        return true;
      }
    });

    return !isCyclic;
  }

  return {
    SKIP_DESCENDANTS: SKIP_DESCENDANTS,
    SKIP_ALL: SKIP_ALL,

    // basically visit every node in a directed graph and check it is acyclic.
    isDAG: isDAG,

    // Make a depth first search function.
    // You provide a getChildren(node) function which returns
    //   an array if node has children, else undefined.
    // The returned dfs function takes arguments:
    // ```node, preFn, afterFn, parent```
    // Initial call to dfs only requires node. See example for defaultDfs.
    makeDfs: makeDfs,

    // Make a beadth first search function.
    // Similar to makeDfs but you only have one visitFn instead of pre and
    // post visit functions.
    makeBfs: makeBfs,

    // Default depth search.
    // It assumes node.children is children array.
    //
    // The preFn can return fiAlgorithm.SKIP_DESCENDANTS if the children
    // of current node should not be visited. If such value is returned,
    // afterFn is called immediately following preFn.
    // fiAlgorithm.SKIP_ALL will skip descendants AND the postVisit call.
    //
    // Example:
    // ```
    // var data = [
    //   { name: '1', children: [{name: '2'}]},
    //   { name: '3', children: [{name: '4'}]},
    // ];
    // fiAlgorithm.defaultDfs(data, function(x, parentOfX) {
    //  someOtherArray.push(x.name);
    //  if (x.doNotVisitChildren) {
    //    return fiAlgorithm.SKIP_DESCENDANTS;
    //  }
    // }, function(x, parentOfX) {}, null);
    // ```
    defaultDfs: makeDfs(function (node) {
      if (node && Array.isArray(node.children)) {
        return node.children;
      }
    }),

    setIntersection: function (setA, setB) {
      if (setA.size > setB.size) {
        var tmp = setA;
        setA = setB;
        setB = tmp;
      }

      var intersection = new Set();
      setA.forEach(function (a) {
        if (setB.has(a)) {
          intersection.add(a);
        }
      });
      return intersection;
    },

    setSubtract: function (setA, setB) {
      var diff = new Set(setA);
      setB &&
        setB.forEach(function (b) {
          diff.delete(b);
        });
      return diff;
    },

    // General pairing function that can watch two value changes.
    // number only
    pairing: function (k1, k2) {
      return ((k1 + k2) * (k1 + k2 + 1)) / 2 + k2;
    },
    // Slice array into chuncks. If padLast is true, it will fill the last
    // row with nulls.
    sliceArray: function (arr, chunk, padLast) {
      var i, j;
      var ret = [];
      for (i = 0, j = arr.length; i < j; i += chunk) {
        ret.push(arr.slice(i, i + chunk));
      }
      if (padLast && ret.length) {
        var last = ret[ret.length - 1];
        var padNum = chunk - last.length;
        for (var n = 0; n < padNum; n++) {
          last.push(null);
        }
      }
      return ret;
    },
    makeTreeSortComparator: function (compareSameLevelFn, opts) {
      opts = assign(
        {
          isFolder: function (item) {
            return item.type === 'folder';
          },
          parentAttr: 'parent',
        },
        opts
      );
      // Returns a set of item and its all ancestors,
      // added in bottom to top order (including null or undefined as root).
      // The output can be converted to an array which has
      // null as its last element, and item as first element.
      function getAncestors(item) {
        var ancestors = new Set();
        ancestors.add(item);
        while (item) {
          ancestors.add(item[opts.parentAttr]);
          item = item[opts.parentAttr];
        }
        return ancestors;
      }
      return function compareRows(inputX, inputY) {
        if (inputX === inputY) {
          return 0;
        }
        if (typeof inputX === 'undefined') {
          return -1;
        }
        if (typeof inputY === 'undefined') {
          return 1;
        }
        if (inputX[opts.parentAttr] === inputY) {
          // child always comes after parent.
          return 1;
        }
        if (inputY[opts.parentAttr] === inputX) {
          // child always comes after parent.
          return -1;
        }
        if (inputX[opts.parentAttr] === inputY[opts.parentAttr]) {
          return compareSameLevelFn(inputX, inputY);
        }

        // now, inputX and inputY are guaranteed
        // not to be at same level, AND,
        // not to have parent-child relationship.

        var ancestorsX = getAncestors(inputX);
        if (ancestorsX.has(inputY)) {
          return 1; // Y comes first.
        }
        var ancestorsY = getAncestors(inputY);
        if (ancestorsY.has(inputX)) {
          return -1; // X comes first.
        }

        // Partly a Lowest Common Ancestor algorithm,
        // but we are interested in the 2 siblings x, y
        // who share the lowest common ancestor.
        var xList = Array.from(ancestorsX);
        var yList = Array.from(ancestorsY);
        var x, y;
        do {
          x = xList.pop();
          y = yList.pop();
        } while (x === y && (xList.length || yList.length));
        return compareRows(x, y);
      };
    },
  };
})();
